import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import { handlePostRequest } from '../../../../services/PostTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
// import { useDispatch } from 'react-redux';

function AgentCommissionFee() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-save');
    const [globalFilter, setGlobalFilter] = useState('');
    const [agentCommissionData, setAgentCommissionData] = useState([]);

    const validationSchema = Yup.object().shape({
        heirarchyLevel: Yup.string().required('This Field is required.'),
        seqNo: Yup.string().required('This Field is required'),
        commission: Yup.string().required('This Field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            heirarchyLevel: '',
            seqNo: '',
            commission: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            // const res = await dispatch(handlePostRequest(data, '/microbank_zmiles_config/zmiles/getTierSearch', true, true));
            // if (res?.responsecode === 1) {
            //     formik.resetForm();
            //     setAgentCommissionData(res?.data);
            // }
            if (data !== null) {
                setAgentCommissionData([...agentCommissionData, data]);
                formik.resetForm();
            }

            setloading(false);
            setloadingIcon('pi pi-save');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const header = (
        <div className="Global__Header">
            <h5 className="m-0">Agent Commission Share List</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const handledCommissionShare = async () => {
        let data = agentCommissionData;
        // const res = await dispatch(handlePostRequest(data, '/microbank_zmiles_config/zmiles/getTierSearch', true, true));
    };

    const handledDelete = (rowData) => {
        const newData = agentCommissionData.filter((data) => {
            return data.seqNo !== rowData.seqNo;
        });
        setAgentCommissionData(newData);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mr-2" onClick={() => handledDelete(rowData)} />
            </div>
        );
    };

    return (
        <>
            {/* Agent Commission Form */}
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>Agent Commission Share</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="heirarchyLevel" className={classNames({ 'p-error': isFormFieldValid('heirarchyLevel') }, 'Label__Text')}>
                                    Heirarchy Level
                                </label>
                                <InputText
                                    type="number"
                                    id="heirarchyLevel"
                                    placeholder="Enter Share"
                                    name="heirarchyLevel"
                                    value={formik?.values?.heirarchyLevel || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('heirarchyLevel') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('heirarchyLevel')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="seqNo" className={classNames({ 'p-error': isFormFieldValid('seqNo') }, 'Label__Text')}>
                                    Seq No
                                </label>
                                <InputText
                                    id="seqNo"
                                    placeholder="Enter Seq No"
                                    name="seqNo"
                                    value={formik?.values?.seqNo || ''}
                                    onChange={formik.handleChange}
                                    autoComplete="off"
                                    className={classNames({ 'p-invalid': isFormFieldValid('seqNo') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('seqNo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="commission" className={classNames({ 'p-error': isFormFieldValid('commission') }, 'Label__Text')}>
                                    Commission
                                </label>
                                <InputText
                                    type="number"
                                    id="commission"
                                    placeholder="Enter Commission"
                                    name="commission"
                                    max="100"
                                    autoComplete="off"
                                    value={formik?.values?.commission || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('commission') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('commission')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn__Center">
                        <Button label="Save" disabled={loading} icon={loadingIcon || ''} iconPos="right" className="Btn__Dark" />
                    </div>
                </form>
            </div>

            {/* Agent Commission DataTable */}

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            globalFilter={globalFilter}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={agentCommissionData}
                        >
                            <Column field="heirarchyLevel" header="Heirarchy Level" />
                            <Column field="seqNo" header="Seq No" />
                            <Column field="commission" header="Commission" />
                            <Column header="Action" body={actionBodyTemplate} />
                        </DataTable>
                    </div>
                </div>
            </div>
            <div className="Down__Btn__Center">
                <Button style={{ fontSize: '12px' }} label="Add Commission Share" onClick={handledCommissionShare} icon={loadingIcon || ''} iconPos="right" className="Btn__Dark" />
            </div>
        </>
    );
}

export default AgentCommissionFee;

import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const EmailUsed = ({ emailUsedCount }) => {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        const countArr = emailUsedCount?.map((item) => item?.parameterValue) || [];
        const labelArr = emailUsedCount?.map((item) => item?.parameterName) || [];

        const data = {
            labels: labelArr,
            datasets: [
                {
                    data: countArr,
                    backgroundColor: ['#14a353', '#e74f4f'],
                    hoverBackgroundColor: ['#14a353', '#e74f4f']
                }
            ]
        };

        setChartData(data);
    }, [emailUsedCount]);

    const options = {
        plugins: {
            datalabels: {
                color: '#fff',
                formatter: (value, context) => {
                    const label = context.chart.data.labels[context.dataIndex];
                    return `${value}%`;
                }
            }
        }
    };

    return (
        <div className="col-12 md:col-4">
            <div className="card Card__Round">
                <div className="card p-d-flex p-jc-center">
                    <h5>Email Used- Yes/No</h5>
                    <Chart type="doughnut" data={chartData} options={options} plugins={[ChartDataLabels]} className="w-full" style={{ height: '35rem' }} />
                </div>
            </div>
        </div>
    );
};

export default EmailUsed;

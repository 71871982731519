import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { toast } from 'react-toastify';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';

import { useNavigate } from 'react-router-dom';
import { handlePostRequest } from '../../../services/PostTemplate';

import React, { useState, useRef, useEffect } from 'react';

import { handleGetRequest } from '../../../services/GetTemplate';

import { Dropdown } from 'primereact/dropdown';
import * as yup from 'yup';
import classNames from 'classnames';
import DynamicOrganizationChartComponent from '../../../components/DynamicComponent/DynamicOrganizationChartComponent';
import DynamicDialog from '../../../components/DynamicComponent/DynamicModal';
import { useDispatch } from 'react-redux';
import { handleRequest } from '../../../utils/HandleRequest/handleRequest';
import * as Yup from 'yup';
import DynamicDialogFiles from '../../../components/DynamicComponent/DynamicDialogFiles';
import { useLocation } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';

import { DataTable } from 'primereact/datatable';

import { Column } from 'primereact/column';
// import { handleRequest } from '../../../utils/HandleRequest/handleRequest';

function EditCheckerRuleEngine() {
    const [activeRule, setActiveRule] = useState();
    let { search } = useLocation();
    const [historyData, setHistoryData] = useState();
    const [loading, setloading] = useState(false);
    const [ruleNameValue, setRuleNameValue] = useState('');
    const [ruleString, setRuleString] = useState('');
    const [expandedDialog, setExpandedDialog] = useState(false);
    const [getEditData, setGetEditData] = useState([]);
    const [depthCounter, setDepthCounter] = useState(0);
    const [parentRule, setParentRule] = useState('');
    const [domainValue, setDomainValue] = useState();
    const [expandedValue, setExpandedValue] = useState({});
    const [ruleResult, setRuleResult] = useState({});
    const [isEdit, setIsEditRule] = useState(true);

    const [isAdd, setIsAdd] = useState(false);
    const [isExpanded, setIsExpanded] = useState(true);
    const [userLov, setUserLov] = useState([]);
    const [fieldLov, setFieldLov] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedValues, setSelectedValues] = useState('');
    const [selectedExpandValues, setSelectedExpandNode] = useState('');
    const [useCaseError, setUseCaseError] = useState({});
    const [isAccept, setIsAccept] = useState(false);
    const [dataTree, setDataTree] = useState([]);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [selectedNode, setSelectedNode] = useState(null);

    const [statusLovData, setStatusLovData] = useState([]);

    const query = new URLSearchParams(search);

    const tableName = query.get('tableName');
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');

    const onNodeClick = (node) => {
        setSelectedNode(node);
        setDialogVisible(true);

        setSelectedValues('');
    };
    const onExpandedClick = (node) => {
        setSelectedExpandNode(node);
        setExpandedDialog(true);
    };
    const onRemove = (selectedNode) => {
        // Define a function to remove children of a node
        const removeChildren = (node) => {
            if (node.children) {
                node.children.forEach((child) => {
                    child.isAdd = false;

                    removeChildren(child);
                });
            }
        };

        // Remove children of the selectedNode
        removeChildren(selectedNode);

        // Determine the new label based on the color
        const newLabel = selectedNode.color === 'red' ? 'false' : 'true';

        // Update the selectedNode with the new label and no children
        selectedNode.label = newLabel;
        selectedNode.children = [];
        selectedNode.isAdd = false;
        selectedNode.isRemove = false;
        // Update the state with the modified data tree
        const newDataTree = [...dataTree];
        setDataTree(newDataTree);
        if (selectedNode === dataTree[0]) {
            setDataTree([]);
            setRuleNameValue('');
        }

        // Update the rule string and result
        const jsonString = JSON.stringify(newDataTree);
        setRuleString(jsonString);

        const resultRule = convertTreeToString(newDataTree);
        setRuleResult(resultRule);
    };
    function convertTreeToString(tree) {
        function traverse(node) {
            const childResults = [];
            let hasTrue = false;
            let hasFalse = false;

            if (node?.children) {
                for (const child of node.children) {
                    const childResult = traverse(child);
                    childResults.push(childResult);

                    if (childResult.includes('true')) {
                        hasTrue = true;
                    } else if (childResult.includes('false')) {
                        hasFalse = true;
                    }
                }

                let result = `${node.label}`;
                if (hasTrue && hasFalse) {
                    const reversedArray = childResults.reverse();

                    // Convert the reversed elements back to an array
                    const newArray = reversedArray.map((element) => element);
                    result += `$?$${newArray.join('$:$')}$#_${node.counter}`;
                } else if (hasTrue && childResults[0] == 'true') {
                    result += `$?$${childResults.join('$:$')}$:$false$#_${node.counter}`;
                } else if (hasFalse && childResults[0] == 'false') {
                    result += `$?$true$:$${childResults.join('$:$')}$#_${node.counter}`;
                } else if (hasTrue && childResults[0] != 'true' && childResults[1] == 'true') {
                    result += `$?$true$:$${childResults[0]}$#_${node.counter}`;
                } else if (hasTrue && childResults[0] != 'true' && childResults[1] != 'true') {
                    result += `$?$${childResults[1]}$:$${childResults[0]}$#_${node.counter}`;
                } else if (hasTrue && childResults[0] != 'true' && childResults[1] == 'false') {
                    result += `$?$${childResults[0]}$:$false$#_${node.counter}`;
                }

                if (hasTrue || hasFalse) {
                    return `@_${node.counter}$${result}`;
                } else {
                    return `${result}`;
                }
            }

            return `@_${node.counter}${node.label}`;
        }

        return `${tree.map((node) => traverse(node)).join('')}`;
    }

    function formatValuesNumber(value) {
        if (typeof value === 'string' || typeof value === 'number') {
            const isNumber = /^\d+$/.test(value); // Check if value is a number
            const isAlphabetic = /^[A-Za-z]+$/.test(value); // Check if value is alphabetic

            if (isAlphabetic || isNumber) {
                return `[[${value}]]`;
            } else {
                // Handle other cases here if needed
                return `[[${value}]]`; // Return value as is for other types
            }
        } else {
            // Handle other types as needed
            return `[[${value}]]`; // Return value as is for other types
        }
    }
    function formatValues(value) {
        // if (typeof value === 'string' || typeof value === 'number') {
        //     const isNumber = /^\d+$/.test(value);
        //     const isAlphabetic = /^[A-Za-z]+$/.test(value);

        //     if (isAlphabetic || isNumber) {
        //         return `{{${value}}}`;
        //     } else {
        //         return `{{${value}}}`; // Return value as is for other types
        //     }
        // } else
        {
            // Handle other types as needed

            return `{{${value}}}`; // Return value as is for other types
        }
    }

    const handleParentChildRelation = (formik) => {
        let newValue = '';

        // if (formik.values.values) {
        //     if (formik.values.selectedValues === 'dynamicValue') {
        //         newValue = formatValues(formik.values.values);
        //     } else {
        //         newValue = formatValuesNumber(formik.values.values);
        //     }
        // } else {
        //     toast.warn('Please select dynamic value or static value');
        // }

        if (formik.values.selectedValues === 'staticValues' || formik.values.selectedValues === 'staticValue') {
            newValue = formatValuesNumber(formik.values.values);
        } else if (formik.values.selectedValues === 'dynamicValues' || formik.values.selectedValues === 'dynamicValue') {
            newValue = formatValues(formik.values.values1);
        }

        const newData = {
            fieldType: formik.values.fieldType,
            logicalOperator: formik.values.logicalOperator,
            values: newValue
        };

        const schema = yup.object().shape({
            fieldType: yup.string().required('This Field is Required.'),

            logicalOperator: yup.string().required('This Field is Required.'),
            values: yup.string().required('This Field is Required.')
        });

        schema
            .validate(newData, { abortEarly: false }) // Abort early ensures that all errors are collected
            .then(() => {
                // Validation passed

                let result = fieldLov?.filter((result) => {
                    if (result.lovId == formik.values.fieldType) {
                        return result?.code;
                    }
                })[0];

                // Reset the depth counter

                if (selectedNode.isRoot) {
                    // Update the root node with the form values
                    setIsAdd(false);
                    let perviousNode = selectedNode;
                    selectedNode.label = `${result?.code}${formik.values.logicalOperator}${formik.values.values}`;

                    selectedNode.fieldType = formik.values.fieldType;
                    selectedNode.logicalOperator = formik.values.logicalOperator;
                    selectedNode.values = formik.values.values;
                    selectedNode.isAdd = true;
                    selectedNode.isExpanded = false;
                    selectedNode.counter = depthCounter;
                    selectedNode.selectedValues = selectedValues;
                    setDepthCounter(depthCounter + 1);
                    setParentRule(selectedNode.label);

                    if (selectedNode.children.length > 0 && selectedNode?.children[0]?.label != 'false' && selectedNode?.children[1]?.label != 'true') {
                        selectedNode.children = selectedNode.children
                            .map((child, index) => {
                                if (index === 0 && child.isLeftChild === true) {
                                    return child;
                                } else if (index === 1 && child.isRightChild === true) {
                                    return child;
                                }
                                // If the conditions are not met, return the original child.
                                return null;
                            })
                            .filter(Boolean);
                        selectedNode.children = [selectedNode.children[0], selectedNode.children[1]];
                    } else {
                        if (selectedNode.children && selectedNode.children.length > 0) {
                            selectedNode.children.map((result) => {
                                if (result?.isLeftChild === false) {
                                    const leftChild = generateRuleNode('false', '', '', true, false);
                                    leftChild.color = 'red';
                                    selectedNode.children = [leftChild, result];
                                } else if (result?.isLeftChild === true) {
                                    const rightChild = generateRuleNode('true', '', '', false, true);
                                    rightChild.color = 'green';
                                    selectedNode.children = [rightChild, result];
                                }
                            });
                        } else {
                            const leftChild = generateRuleNode('false', '', '', true, false);
                            leftChild.color = 'red';

                            // Create a right child node with "true" label and green color
                            const rightChild = generateRuleNode('true', '', '', false, true);
                            rightChild.color = 'green';

                            selectedNode.children = [leftChild, rightChild];
                        }
                    }

                    setDataTree([selectedNode]);
                } else {
                    setIsAdd(false);

                    // Update the root node's label with the new form value
                    const { fieldType, logicalOperator, values } = formik.values;

                    // Create a new parent node if both "isTrue" and "isFalse" are selected
                    let result = fieldLov?.filter((result) => {
                        if (result.lovId == fieldType) {
                            return result?.code;
                        }
                    })[0];
                    let perviousNode = selectedNode;
                    selectedNode.label = `${result?.code}${logicalOperator}${values}`;

                    selectedNode.fieldType = formik.values.fieldType;
                    selectedNode.logicalOperator = formik.values.logicalOperator;
                    selectedNode.values = formik.values.values;
                    selectedNode.isAdd = true;
                    selectedNode.isExpanded = false;
                    selectedNode.isRemove = false;
                    selectedNode.counter = depthCounter;
                    setDepthCounter(depthCounter + 1);
                    selectedNode.selectedValues = selectedValues;

                    if (selectedNode?.children[0]?.label != 'false' && selectedNode?.children[1]?.label != 'true' && selectedNode?.children[0]?.isLeftChild == true && selectedNode?.children[1]?.isRightChild == true) {
                        selectedNode.children = [selectedNode.children[0], selectedNode.children[1]];
                    } else {
                        // Create a left child node with "false" label and red color

                        // Check if children already exist, and update them
                        if (selectedNode.children && selectedNode.children.length > 0) {
                            selectedNode.children = selectedNode.children
                                .map((child, index) => {
                                    if (index === 0 && child.isLeftChild === true) {
                                        return child;
                                    } else if (index === 1 && child.isRightChild === true) {
                                        return child;
                                    }
                                    // If the conditions are not met, return the original child.
                                    return null;
                                })
                                .filter(Boolean);
                            selectedNode.children.map((result) => {
                                if (result?.isLeftChild === false) {
                                    const rightChild = generateRuleNode('true', '', '', false, true);
                                    rightChild.color = 'green';
                                    selectedNode.children = [rightChild, result];
                                } else if (result?.isLeftChild === true) {
                                    const leftChild = generateRuleNode('false', '', '', true, false);
                                    leftChild.color = 'red';

                                    selectedNode.children = [leftChild, result];
                                }
                            });
                        } else {
                            const leftChild = generateRuleNode('false', '', '', true, false);
                            leftChild.color = 'red';

                            // Create a right child node with "true" label and green color
                            const rightChild = generateRuleNode('true', '', '', false, true);
                            rightChild.color = 'green';

                            selectedNode.children = [leftChild, rightChild];
                        }
                    }
                    setDepthCounter(depthCounter + 1);
                    // Simulate form values'

                    //
                    setDataTree([...dataTree, selectedNode]);
                }

                setDataTree([...dataTree]);

                const jsonString = JSON.stringify(dataTree);
                setRuleString(jsonString);

                const resultRule = convertTreeToString(dataTree);

                setRuleResult(resultRule);

                // Clear form values or reset as needed
                formik?.setFieldValue('fieldType', '');
                formik?.setFieldValue('logicalOperator', '');
                formik?.setFieldValue('values', '');

                setUseCaseError({ fieldType: '', logicalOperator: '', values: '' });

                // Reset isTrue and isFalse

                const newDataFields = Object.keys(newData);
                const touchedFields = newDataFields.reduce((acc, field) => {
                    acc[field] = true;
                    return acc;
                }, {});

                formik.setTouched(
                    {
                        ...formik.touched,
                        ...touchedFields
                    },
                    true
                );

                setDialogVisible(false);
                setSelectedValues('');
                formik.setFieldValue('fieldType', '');
                formik.setFieldValue('logicalOperator', '');
                formik.setFieldValue('values', '');
            })
            .catch((error) => {
                // Validation failed

                const errors = {};
                error?.inner?.forEach((validationError) => {
                    errors[validationError.path] = validationError.message;
                });

                formik.setErrors(errors);
                setUseCaseError(errors);
                const newDataFields = Object.keys(newData);
                const touchedFields = newDataFields.reduce((acc, field) => {
                    acc[field] = true;
                    return acc;
                }, {});

                formik.setTouched(
                    {
                        ...formik.touched,
                        ...touchedFields
                    },
                    true
                );
            });
    };

    function updateDataTreeWithSelectedNode(selectedNode, formik) {
        let newValue = '';

        // if (formik.values.values) {
        //     if (selectedValues === 'dynamicValue') {
        //         newValue = formatValues(formik.values.values);
        //     } else {
        //         newValue = formatValuesNumber(formik.values.values);
        //     }
        // } else {
        //     toast.warn('Please select dynamic value or static value');
        // }

        if (formik.values.selectedValues === 'staticValues' || formik.values.selectedValues === 'staticValue') {
            newValue = formatValuesNumber(formik.values.values);
        } else if (formik.values.selectedValues === 'dynamicValues' || formik.values.selectedValues === 'dynamicValue') {
            newValue = formatValues(formik.values.values1);
        }

        const newData = {
            fieldType: formik.values.fieldType,
            logicalOperator: formik.values.logicalOperator,
            values: newValue
        };

        const schema = yup.object().shape({
            fieldType: yup.string().required('This Field is Required.'),
            // conditionOperator:yup.string().required('This Field is Required.'),
            logicalOperator: yup.string().required('This Field is Required.'),
            values: yup.string().required('This Field is Required.')
        });

        schema
            .validate(newData, { abortEarly: false }) // Abort early ensures that all errors are collected
            .then(() => {
                if (selectedNode.label !== 'true' && selectedNode.label !== 'false') {
                    let result = fieldLov?.filter((result) => {
                        if (result.lovId == formik.values.fieldType) {
                            return result?.code;
                        }
                    })[0];

                    if (selectedNode.label.includes('AND')) {
                        const parts = selectedNode.label.split('AND');

                        // Concatenate the new values with the existing label
                        selectedNode.label = `${result?.code}${formik.values.logicalOperator}${formik.values.values}  AND ${parts.slice(1).join('AND')}`;
                    } else {
                        selectedNode.label = `${result?.code}${formik.values.logicalOperator}${formik.values.values}`;
                    }

                    selectedNode.fieldType = formik.values.fieldType;
                    selectedNode.logicalOperator = formik.values.logicalOperator;
                    selectedNode.values = formik.values.values;

                    setDataTree([...dataTree, selectedNode]);
                    const jsonString = JSON.stringify(dataTree);
                    setRuleString(jsonString);

                    const resultRule = convertTreeToString(dataTree);

                    setRuleResult(resultRule);

                    setExpandedDialog(false);
                    setDialogVisible(false);
                } else {
                    handleParentChildRelation(formik);
                }
            })
            .catch((error) => {
                // Validation failed

                const errors = {};
                error?.inner?.forEach((validationError) => {
                    errors[validationError.path] = validationError.message;
                });

                formik.setErrors(errors);
                const newDataFields = Object.keys(newData);
                const touchedFields = newDataFields.reduce((acc, field) => {
                    acc[field] = true;
                    return acc;
                }, {});

                formik.setTouched(
                    {
                        ...formik.touched,
                        ...touchedFields
                    },
                    true
                );
            });
    }

    const ExpandRule = (formik) => {
        let newValue = '';

        // if (formik.values.values) {
        //     if (selectedValues === 'dynamicValue') {
        //         newValue = formatValues(formik.values.values);
        //     } else {
        //         newValue = formatValuesNumber(formik.values.values);
        //     }
        // } else {
        //     toast.warn('Please select dynamic value or static value');
        // }

        if (formik.values.selectedValues === 'staticValues' || formik.values.selectedValues === 'staticValue') {
            newValue = formatValuesNumber(formik.values.values);
        } else if (formik.values.selectedValues === 'dynamicValues' || formik.values.selectedValues === 'dynamicValue') {
            newValue = formatValues(formik.values.values1);
        }

        const newData = {
            fieldType: formik.values.fieldType,
            logicalOperator: formik.values.logicalOperator,
            values: newValue
        };

        const schema = yup.object().shape({
            fieldType: yup.string().required('This Field is Required.'),
            logicalOperator: yup.string().required('This Field is Required.'),
            values: yup.string().required('This Field is Required.')
        });

        schema
            .validate(newData, { abortEarly: false }) // Abort early ensures that all errors are collected
            .then(() => {
                setExpandedValue({ logicalOperator: formik.values.logicalOperator, conditon: selectedOption, fieldType: formik.values.fieldType, values: formik.values.values });
                let result = fieldLov?.filter((result) => {
                    if (result.lovId == formik.values.fieldType) {
                        return result?.code;
                    }
                })[0];

                if (selectedExpandValues && selectedExpandValues.isRoot) {
                    selectedExpandValues.label = `${selectedExpandValues.label} AND  ${result?.code} ${formik.values.logicalOperator}  ${formik.values.values}`;

                    setDataTree([selectedExpandValues]);
                } else {
                    selectedExpandValues.label = `${selectedExpandValues.label} AND  ${result?.code} ${formik.values.logicalOperator}  ${formik.values.values}`;

                    if (selectedExpandValues?.children[0]?.label != 'false' && selectedExpandValues?.children[1]?.label != 'true' && selectedExpandValues?.children[0]?.isLeftChild == true && selectedExpandValues?.children[1]?.isRightChild == true) {
                        selectedExpandValues.children = [selectedExpandValues.children[0], selectedExpandValues.children[1]];
                    } else {
                        if (selectedExpandValues.children && selectedExpandValues.children.length > 0) {
                            selectedExpandValues.children = selectedExpandValues.children
                                .map((child, index) => {
                                    if (index === 0 && child.isLeftChild === true) {
                                        return child;
                                    } else if (index === 1 && child.isRightChild === true) {
                                        return child;
                                    }
                                    // If the conditions are not met, return the original child.
                                    return null;
                                })
                                .filter(Boolean);
                            selectedExpandValues.children.map((result) => {
                                if (result.isLeftChild === false) {
                                    const leftChild = generateRuleNode('false', '', '', false, true);
                                    leftChild.color = 'red';
                                    selectedExpandValues.children = [leftChild, result];
                                } else if (result.isLeftChild === true) {
                                    const rightChild = generateRuleNode('true', '', '', true, false);
                                    rightChild.color = 'green';
                                    selectedExpandValues.children = [rightChild, result];
                                }
                            });
                        } else {
                            const leftChild = generateRuleNode('false', '', '', true, false);
                            leftChild.color = 'red';

                            // Create a right child node with "true" label and green color
                            const rightChild = generateRuleNode('true', '', '', false, true);
                            rightChild.color = 'green';

                            // If no children exist, set the children
                            selectedExpandValues.children = [leftChild, rightChild];
                        }
                    }
                    setDataTree([...dataTree]);
                }

                const jsonString = JSON.stringify(dataTree);
                setRuleString(jsonString);

                const resultRule = convertTreeToString(dataTree);

                setRuleResult(resultRule);

                setSelectedOption('');
                setExpandedDialog(false);
                formik.setFieldValue('fieldType', '');
                formik.setFieldValue('logicalOperator', '');
                formik.setFieldValue('values', '');
            })
            .catch((error) => {
                // Validation failed

                const errors = {};
                error.inner.forEach((validationError) => {
                    errors[validationError.path] = validationError.message;
                });

                formik.setErrors(errors);
                const newDataFields = Object.keys(newData);
                const touchedFields = newDataFields.reduce((acc, field) => {
                    acc[field] = true;
                    return acc;
                }, {});
                setUseCaseError(errors);
                formik.setTouched(
                    {
                        ...formik.touched,
                        ...touchedFields
                    },
                    true
                );
            });
    };
    function generateRuleNode(fieldType, logicalOperator, values, isLeftChild, isRightChild) {
        let color = isLeftChild ? 'red' : 'green';

        return {
            label: `${fieldType}${logicalOperator}${values}`,
            expanded: false,
            data: 'ar',
            isParent: true,
            isEdit: false,
            isExpanded: true,
            logicalOperator: logicalOperator,
            fieldType: fieldType,
            counter: depthCounter + 1,
            selectedValues: selectedValues,
            isRoot: false,
            isLeftChild: isLeftChild,
            isRightChild: isRightChild,
            color: color, // Set the color based on isLeftChild
            children: [],
            isRemove: true
        };
    }

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const validationSchema = Yup.object({
        action: Yup.string().required('Action should not be empty'),
        checkerComments: Yup.string().required('This Field is required').notOneOf([''], 'This Field should not be empty').max(500, 'Maximum 500 characters needed')
    });
    const handleInputChange = (e) => {
        let { name, value } = e.target;

        if (name === 'domainName') {
            name = 'domainName';
            formik.handleChange(e);
        } else if (name === 'ruleName') {
            name = 'ruleName';
            formik.handleChange(e);
        }
        // Create a new object with the updated error for the specific field
        const updatedErrors = { ...useCaseError, [name]: '' };
        setUseCaseError(updatedErrors);

        //
    };
    const handleAddRule = (formik) => {
        const newData = {
            ruleName: formik.values.ruleName,
            domainName: formik.values.domainName
        };
        const schema = yup.object().shape({
            ruleName: yup
                .string()
                .matches(/^[a-zA-Z\s]+[0-9]*$/, 'Only characters are allowed')
                .required('This Field is Required.'),
            domainName: yup
                .string()

                .required('This Field is Required.')
        });

        schema
            .validate(newData, { abortEarly: false }) // Abort early ensures that all errors are collected
            .then(() => {
                getFieldData(userLov.filter((result) => result.lovId == formik.values.domainName)[0]);
                setDomainValue(formik.values.domainName);
                // setDataTree([]);
                setRuleString('');
                if (dataTree.length == 0) {
                    let array = [
                        {
                            label: formik.values.ruleName,
                            expanded: false,
                            data: 'ar',
                            isParent: true,
                            isRoot: true,
                            isExpanded: true,
                            isAdd: false,
                            children: []
                        }
                    ];
                    setDataTree(array);
                    const jsonString = JSON.stringify(array);
                    setRuleString(jsonString);
                }

                setRuleNameValue(formik.values.ruleName);

                toast.success('Rule Name update Successfully');

                const newDataFields = Object.keys(newData);
                const touchedFields = newDataFields.reduce((acc, field) => {
                    acc[field] = true;
                    return acc;
                }, {});

                formik.setTouched(
                    {
                        ...formik.touched,
                        ...touchedFields
                    },
                    true
                );
            })
            .catch((error) => {
                // Validation failed

                const errors = {};
                error.inner.forEach((validationError) => {
                    errors[validationError.path] = validationError.message;
                });
                formik.setErrors(errors);
                const newDataFields = Object.keys(newData);
                const touchedFields = newDataFields.reduce((acc, field) => {
                    acc[field] = true;
                    return acc;
                }, {});

                formik.setTouched(
                    {
                        ...formik.touched,
                        ...touchedFields
                    },
                    true
                );

                //  setUseCaseError(errors);
            });
    };
    const handleReset = () => {
        // e.preventDefault();

        // formik?.resetForm();
        formik?.setFieldValue('ruleName', '');
        setRuleNameValue('');
        formik.resetForm();
    };
    const formik = useFormik({
        initialValues: {
            domainName: '',
            ruleName: '',
            action: '',
            checkerComments: ''
        },
        validationSchema,

        onSubmit: async (data) => {
            setloading(true);
            let requestType = 'POST';
            let apiUrl = '/ruleengine/v1/rule/ruleCheckerAction';
            let additionalInformation = [
                {
                    infoKey: '',
                    infoValue: ''
                }
            ];

            let domainId = formik.values.domainName;

            let mcActionRequest = {
                mcRequestId: mcRequestId,
                mcPeindingRequestId: mcPeindingRequestId,
                checkerId: 1,
                checkerComments: formik?.values.checkerComments,
                action: formik?.values.action,
                updatedIndex: null
            };
            // edit rule
            let { checkerComments, action, Add, ConditionAdd, domainName, ruleCode, addRule, conditionOperator, fieldType, logicalOperator, values, id, ...rest } = formik.values;

            let payload = { ruleDTO: { ...rest, active: activeRule ? activeRule : 'Y', domainId: domainId, id: +refTableId, rule: ruleResult, ruleString: ruleString }, mcActionRequest: mcActionRequest };

            let res = await handleRequest(payload, requestType, apiUrl, additionalInformation, dispatch, true, false);

            if (res) {
                navigate('/requestchecker');
            }

            setloading(false);
        }
    });

    const validationSchema1 = Yup.object({
        fieldType: Yup.string().required('This Field is required'),
        logicalOperator: yup.string().required('This Field is Required.'),
        values: yup.string().required('This Field is Required.')
    });

    const getFieldData = async (fieldData) => {
        if (fieldData) {
            const resp = await handleGetRequest(`/configuration/v1/lookups/table/column/view/${fieldData?.lovId}`);
            setFieldLov(resp.payLoad);
        }
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getalldomain/TBL_DOMAIN:*');
        setUserLov(resp?.payLoad);
    };

    function setExpandedToTrue(node) {
        if (Array.isArray(node)) {
            return node.map(setExpandedToTrue);
        } else if (typeof node === 'object' && node !== null) {
            const newNode = { ...node }; // Create a shallow copy of the object
            if (newNode.hasOwnProperty('expanded')) {
                newNode.expanded = true;
                newNode.disabled = true;
            }
            for (const key in newNode) {
                if (newNode.hasOwnProperty(key)) {
                    newNode[key] = setExpandedToTrue(newNode[key]);
                }
            }
            return newNode;
        }
        return node;
    }

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = res?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        getUserLov();
        getStatusListData();
    }, []);

    const getDatabyId = async (apiUrl, requestMCType) => {
        // getRoleWiseModule
        // const res = await handleGetRequest(`/re/v1/domain/${refTableId}`);
        let requestType = 'POST';

        let additionalInformation = [
            {
                infoKey: '',
                infoValue: ''
            }
        ];

        let payLoad;
        if (requestMCType === 'I') {
            payLoad = { id: refTableId };
            let res = await handleRequest(payLoad, requestType, apiUrl, additionalInformation, dispatch, false, true);

            let { ruleName, code, rule, domainId, ruleString } = res.payLoad;
            setRuleResult(rule);

            getFieldData(userLov.filter((result) => result.lovId == domainId)[0]);

            formik?.setFieldValue('domainName', domainId.toString());
            formik?.setFieldValue('ruleName', ruleName);
            formik?.setFieldValue('ruleCode', code);

            let newArray = JSON.parse(ruleString);
            setRuleString(ruleString);

            let newTree = setExpandedToTrue(newArray);

            setDataTree(newTree);

            setRuleNameValue(newArray);
        } else if (requestMCType === 'U') {
            // getDatabyId();
            payLoad = { id: mcRequestId };
            let res = await handleRequest(payLoad, requestType, apiUrl, additionalInformation, dispatch, false, true);

            let { ruleName, code, rule, domainId, ruleString, active } = res.payLoad;
            setRuleResult(rule);
            setActiveRule(active);

            getFieldData(userLov.filter((result) => result.lovId == domainId)[0]);

            formik?.setFieldValue('domainName', domainId.toString());
            formik?.setFieldValue('ruleName', ruleName);
            formik?.setFieldValue('ruleCode', code);

            let newArray = JSON.parse(ruleString);
            setRuleString(ruleString);

            let newTree = setExpandedToTrue(newArray);

            setDataTree(newTree);

            setRuleNameValue(newArray);
        }
    };
    const getAuthorizationHistoryData = async () => {
        const resp = await handleGetRequest(`/workflow/v1/management/getActionAuthorizationHistory/${refTableId}/${tableName}`);
        setHistoryData(resp?.payLoad);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId('/ruleengine/v1/rule/', requestType);
        } else if (requestType === 'U') {
            getDatabyId('/ruleengine/v1/rule/checker/', requestType);
        }
        getAuthorizationHistoryData();
    }, []);

    // Function to validate a specific field on change

    return (
        <>
            {/* <Toast ref={toast} />
            <ConfirmPopup style={{ marginTop: '30px' }} /> */}
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <div className="title__header">EDIT DETAIL</div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="domain" className={classNames({ 'p-error': isFormFieldValid('domainName') }, 'Label__Text')}>
                                    Domain Name
                                </label>
                                <Dropdown
                                    id="domain"
                                    placeholder="Select Domain"
                                    options={userLov}
                                    optionLabel="name"
                                    name="domainName"
                                    optionValue="lovId"
                                    disabled
                                    value={formik?.values?.domainName || ''}
                                    onChange={handleInputChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {useCaseError.domainName !== '' ? <small className="p-error">{useCaseError.domainName}</small> : getFormErrorMessage('domainName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="domain" className={classNames({ 'p-error': isFormFieldValid('ruleName') }, 'Label__Text')}>
                                    Rule Name
                                </label>

                                <InputText
                                    maxLength={100}
                                    id="ruleId"
                                    name="ruleName"
                                    disabled
                                    value={formik?.values?.ruleName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={handleInputChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('ruleName') }, 'Input__Round')}
                                />
                                {useCaseError.ruleName !== '' ? <small className="p-error">{useCaseError.ruleName}</small> : getFormErrorMessage('ruleName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="ruleCodeId" className={classNames({ 'p-error': isFormFieldValid('ruleCodeId') }, 'Label__Text')}>
                                    Rule Code
                                </label>

                                <InputText
                                    disabled
                                    maxLength={100}
                                    id="ruleCode"
                                    name="ruleCode"
                                    value={formik?.values?.ruleCode?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('ruleCode') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('ruleCodeId')}
                            </div>
                        </div>

                        <div className="Usergroup__SearchBtn p-field col-12 pl-32" style={{ paddingLeft: '43%' }}>
                            <Button type="button" label="Update" disabled onClick={() => handleAddRule(formik)} className="Btn__Dark__Search" />
                        </div>
                    </div>
                </form>
                <>
                    {ruleNameValue && dataTree.length > 0 ? (
                        <>
                            <div>
                                {' '}
                                <div className="title__header mt-2">Rules DESCRIPTION</div>
                                <DynamicOrganizationChartComponent
                                    isEdit={isEdit}
                                    isEditChecker={true}
                                    data={dataTree}
                                    isExpanded={isExpanded}
                                    isAdd={isAdd}
                                    ruleName={ruleNameValue}
                                    onNodeClick={onNodeClick}
                                    onExpandedClick={onExpandedClick}
                                    onRemove={onRemove}
                                />
                                <DynamicDialogFiles
                                    visible={dialogVisible}
                                    header="Edit Rule"
                                    onHide={() => setDialogVisible(false)}
                                    node={selectedNode}
                                    setUseCaseError={setUseCaseError}
                                    fieldLov={fieldLov}
                                    updateDataTreeWithSelectedNode={updateDataTreeWithSelectedNode}
                                    isEdit={true}
                                    expand={false}
                                    errors={useCaseError}
                                    expandButton={false}
                                    userLov={userLov}
                                    handleParentChildRelation={handleParentChildRelation}
                                    validationSchema={validationSchema1}
                                ></DynamicDialogFiles>
                                <DynamicDialogFiles
                                    visible={expandedDialog}
                                    header="Expand Rule"
                                    onHide={() => setExpandedDialog(false)}
                                    node={selectedNode}
                                    expandButton={true}
                                    setUseCaseError={setUseCaseError}
                                    fieldLov={fieldLov}
                                    expand={true}
                                    errors={useCaseError}
                                    userLov={userLov}
                                    ExpandRule={ExpandRule}
                                    validationSchema={validationSchema1}
                                ></DynamicDialogFiles>
                            </div>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                            Action
                                            <span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="action"
                                            placeholder="Select Action"
                                            options={statusLovData}
                                            optionLabel="name"
                                            name="action"
                                            optionValue="lovId"
                                            value={formik.values.action || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('action')}
                                    </div>
                                </div>

                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                            Comments
                                            <span className="Label__Required">*</span>
                                        </label>
                                        <InputTextarea
                                            maxLength={500}
                                            rows={5}
                                            cols={30}
                                            id="checkerComments"
                                            name="checkerComments"
                                            value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                        />

                                        {getFormErrorMessage('checkerComments')}
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="Down__Btn my-5">
                                    <Button type="submit" label="Update" className="Btn__Dark" />

                                    <Button disabled={loading} onClick={() => navigate('/requestChecker')} label="Cancel" className="Btn__Transparent" />
                                </div>
                            </form>
                        </>
                    ) : (
                        <></>
                    )}
                    <div className="grid">
                        <div className="col-12">
                            <div className="card">
                                <DataTable
                                    paginator
                                    rows={5}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    className="datatable-responsive"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                                    emptyMessage="No List found."
                                    header="Action Authorization History"
                                    responsiveLayout="scroll"
                                    value={historyData}
                                >
                                    <Column field="escalationLevel" header="Excalation Level" />
                                    <Column field="checkedBy" header="Checked By" />
                                    <Column field="checkedOn" header="Checked On" />
                                    <Column field="authorizerComments" header="Authorizor Comments" />
                                    <Column field="intimationOn" header="Intimation On" />
                                    <Column field="intimationTo" header="Intimation To" />
                                    <Column field="authorizationStatus" header="Authorization Status" />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </>
            </div>
        </>
    );
}

export default EditCheckerRuleEngine;

import React, { useEffect, useState } from 'react';
import GenericComponent from '../Component/GenericComponent';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { handlePostRequest } from '../../../services/PostTemplate';

const ViewDynamicRule = () => {
    const [viewData, setViewData] = useState();

    const dispatch = useDispatch();
    const location = useLocation();

    const { rowData } = location.state || {};

    const getRecordById = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    id: rowData?.ruleFieldId
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/ruleengine/v1/rule/rulefield/'));
        setViewData(res?.payLoad);
    };

    useEffect(() => {
        getRecordById();
    }, []); // eslint-disable-line
    return (
        <React.Fragment>
            <GenericComponent type="view" viewData={viewData} />
        </React.Fragment>
    );
};

export default ViewDynamicRule;

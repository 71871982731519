import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DefaultComponent from './DefaultComponent';
import { handleGetRequest } from '../../../../services/GetTemplate';

function MakerApis({ type }) {
    const location = useLocation();
    const [selectedRole] = useState(location?.state?.rowData || '');
    const [roleId] = useState(location?.state?.rowData?.userId || '');
    const [getByIdData, setGetByIdData] = useState([]);
    const [getByRoleId, setGetByRoleId] = useState([]);
    const [addRoleDropdown, setAddRoleDropdown] = useState([]);
    const [allMenuList, setAllMenuList] = useState([]);

    // add page
    const funcAddRoleDropdown = async () => {
        const resp = await handleGetRequest(`/account/v1/customeraccounts/getAllCxRoles`);
        setAddRoleDropdown(resp?.payLoad);
    };

    // add page
    const funcAllMenuList = async () => {
        const resp = await handleGetRequest(`/account/v1/customeraccounts/getAllMenuTabs`);
        setAllMenuList(resp?.payLoad);
    };

    // edit and view page
    const funcGetByIdData = async () => {
        const resp = await handleGetRequest(`/account/v1/customeraccounts/getCxEditTabsByRoleId/${roleId}`);
        setGetByRoleId(resp?.payLoad);
    };

    useEffect(() => {
        if (type === 'add') {
            funcAddRoleDropdown();
            funcAllMenuList();
        }
    }, [type]);

    useEffect(() => {
        setGetByIdData({ selectedRole: selectedRole, getByRoleId: getByRoleId, addRoleDropdown: addRoleDropdown, allMenuList: allMenuList });
    }, [selectedRole, getByRoleId, addRoleDropdown, allMenuList]);

    useEffect(() => {
        if (location?.state?.rowData) {
            funcGetByIdData();
        }
    }, [location?.state?.rowData]);

    return (
        <React.Fragment>
            <DefaultComponent type={type} getByIdData={getByIdData} />
        </React.Fragment>
    );
}
export default MakerApis;

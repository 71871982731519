import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { handlePostRequest } from '../../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../../services/GetTemplate';
import classNames from 'classnames';
import base64 from 'base64-js';
import * as Yup from 'yup';
import { loadingAction } from '../../../../../redux/actions/loadingAction';
import { toast } from 'react-toastify';
import { baseURL } from '../../../../../Config';
import ScrollMover from '../../../../../components/ScrollMover';
import { Dialog } from 'primereact/dialog';
import RemarksDailog from '../../../../CX-1/AgentAccountsManagement/Components/RemarksDailog';
import AuthorizationHistoryTable from '../../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import SecuredFileChange from '../../../../../components/SecuredFileChange';

function ViewRetailer({ apiData, isEditable, docData }) {
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [loading, setloading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [kycFields, setKycFields] = useState([]);
    const [pricingProfile, setPricingProfile] = useState([]);
    const [commissionProfile, setCommissionProfile] = useState([]);
    const [commissionHierarchy, setCommissionHierarchy] = useState([]);
    const [limitProfile, setLimitProfile] = useState([]);
    const [productCatalog, setProductcatalog] = useState([]);
    const [employeeName, setEmployeeName] = useState('');
    const [channelOption, setChannelOption] = useState([]);
    const [parent, setParent] = useState([]);
    const [apiParentId, setApiParentId] = useState('');
    const [apiGetParentId, setApiGetParentId] = useState('');
    const [searchBtnDisable, setSearchBtnDisable] = useState(true);
    const kfsRef = useRef(null);
    const searchEmpId = useRef(null);
    const searchParentData = useRef(null);
    const [empIdData, setEmpIdData] = useState([]);

    const digitalSigningRef = useRef(null);
    const [kfsInput, setKfsInput] = useState('');
    const [kfsInputFile, setKfsInputFile] = useState('');
    const [kfsInputBase64, setKfsInputBase64] = useState('');
    const [kfsLink, setKfsLink] = useState('');
    const [digitalSigningInput, setDigitalSigningInput] = useState('');
    const [digitalSigningFile, setDigitalSigningFile] = useState('');
    const [digitalSigningInputBase64, setDigitalSigningInputBase64] = useState('');
    const [digitalSigningLink, setDigitalSigningLink] = useState('');
    const [kfsFileError, setKfsFileError] = useState('');
    const [dsFileError, setDsFileError] = useState('');
    const [useCaseError, setUseCaseError] = useState({
        empId: null
    });
    const [tblKycSetDetails, setTblKycSetDetails] = useState([]);
    const [accountStatus, setAccountStatus] = useState();

    const getaccountStatus = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getAccountStatus/LKP_ACCOUNT_STATUS:*');
        setAccountStatus(res?.payLoad);
    };

    useEffect(() => {
        getaccountStatus();
    }, []);

    const input1 = useRef(null);
    const input2 = useRef(null);
    const input3 = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const lovId = apiData?.lkpAccountClassification?.accountClassificationId;
    const name = apiData?.lkpAccountClassification?.accountClassificationName;
    const accountClassificationId = lovId;
    const kycFixed = ['cnic', 'mobileNumber', 'cnicIssuanceDate'];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button type="button" disabled={!isEditable} onClick={handleDelete} tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" className="p-button-rounded p-button-danger" />
            </div>
        );
    };

    function getMaxRegexLength(regex) {
        // Remove leading and trailing slashes from the regular expression.
        const regexPattern = regex.toString().slice(1, -1);

        // Use a regular expression to find the maximum length.
        const lengthRegex = /\{(\d+)(,(\d+))?\}/g;
        let maxLength = 0;

        let match;
        while ((match = lengthRegex.exec(regexPattern)) !== null) {
            const min = parseInt(match[1], 10);
            const max = match[3] ? parseInt(match[3], 10) : min;
            if (max > maxLength) {
                maxLength = max;
            }
        }

        return maxLength;
    }

    //validations
    const validationSchema = Yup.object({
        pricingProfileId: Yup.string().required('This Field is required'),
        commissionId: Yup.string().required('This Field is required'),
        commissionHierarchyId: Yup.string().required('This Field is required'),
        transLimitId: Yup.string().required('This Field is required'),
        // parentAgentId: Yup.string().required('Select any of these'),
        channelIdList: Yup.array().required('This Field is required').min(1, 'This Field is required'),
        empId: Yup.string().required('This Field is required'),
        hierarchyName: Yup.string().required('This Field is required'),
        productCatalogId: Yup.string().required('This Field is required'),
        chequeBook: Yup.boolean(),
        debitCard: Yup.boolean(),
        kfsInput: Yup.string().required('This Field is required'),
        digitalSigningInput: Yup.string().when('chequeBook', {
            is: true,
            then: Yup.string().required('This Field is required'),
            otherwise: Yup.string().notRequired()
        }),
        noOfLeaves: Yup.string().when('chequeBook', {
            is: true,
            then: Yup.string().required('This Field is required'),
            otherwise: Yup.string().notRequired()
        }),
        debitCardName: Yup.string().when('debitCard', {
            is: true,
            then: Yup.string()
                .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
                .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
                .required('This Field is required'),
            otherwise: Yup.string().notRequired()
        }),
        debitCardAddress: Yup.string().when('debitCard', {
            is: true,
            then: Yup.string().required('This Field is required'),
            otherwise: Yup.string().notRequired()
        }),
        ...tblKycSetDetails.reduce((fieldValidations, result) => {
            const attributeName = result?.tblKycAttribute?.attributeName;
            const regexExpression = result?.tblKycAttribute;
            const date = result?.tblKycAttribute?.attributeName.toLowerCase().includes('date');
            const regExp = new RegExp(regexExpression.tblValidator.argumentValue);
            const maxLength = getMaxRegexLength(regExp);

            if (kycFixed?.includes(result?.tblKycAttribute?.attributeName) || (result.isMandatory === 'Y' && result?.isActive === 'Y')) {
                // Validate that the field is not empty
                fieldValidations[attributeName] = Yup.string().test('dynamicValidation', 'Invalid Format', function (value) {
                    // Check if a value is entered and apply regular expression validation
                    if (value) {
                        if (value.length > maxLength && !date) {
                            if (maxLength > 0) {
                                return this.createError({
                                    path: this.path,
                                    message: `Value exceeds the maximum length of ${maxLength}`
                                });
                            }
                        }

                        return regExp.test(value);
                    } else {
                        // If no value is entered and the field is mandatory, show it as required
                        if (kycFixed?.includes(result?.tblKycAttribute?.attributeName) || result.isMandatory === 'Y') {
                            return this.createError({
                                path: this.path,
                                message: 'This Field is required'
                            });
                        }

                        // If no value is entered and the field is not mandatory, no error is shown.
                        return true;
                    }
                });
            } else if (result?.isActive === 'Y') {
                // If the item is not mandatory but active, only apply the regex validation
                fieldValidations[attributeName] = Yup.string().test('dynamicValidation', 'Invalid Format', function (value) {
                    if (value) {
                        // Check if value is not empty
                        if (value.length > maxLength && !date) {
                            if (maxLength > 0) {
                                return this.createError({
                                    path: this.path,
                                    message: `Value exceeds the maximum length of ${maxLength}`
                                });
                            }
                        }

                        return regExp.test(value);
                    }
                    // If the value is empty, no error is shown.
                    return true;
                });
            }

            return fieldValidations;
        }, {})
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            pricingProfileId: '',
            commissionId: '',
            commissionHierarchyId: '',
            transLimitId: '',
            parentAgentId: '',
            parentMobileNumber: '',
            ParentCnic: '',
            channelIdList: [],
            empId: '',
            hierarchyName: '',
            productCatalogId: '',
            chequeBook: '',
            debitCard: '',
            noOfLeaves: '',
            debitCardAddress: '',
            debitCardName: '',
            atmWaiver: 'N',
            chequeWaiver: 'N',
            kfsInput: '',
            digitalSigningInput: '',
            addressInfos: [
                {
                    addressId: '',
                    fullAddress: ''
                }
            ],
            accountNumber: '',
            accountTitle: ''
        },

        onSubmit: async (data, actions) => {
            setloading(true);

            const arrayOfChannelIds = formik?.values?.channelIdList?.map((item) => {
                return parseInt(item?.lovId, 10) || 0;
            });
            if (!parent || parent?.length === 0 || parent == null) {
                return toast.warn('Please search parent data');
            }
            data['debitCard'] = formik?.values?.debitCard ? 'Y' : 'N';
            data['chequeBook'] = formik?.values?.chequeBook ? 'Y' : 'N';
            data['empId'] = empIdData;
            data['commissionHierarchy'] = data.commissionHierarchyId;

            delete data['birthPlace'];
            delete data['ecib'];
            delete data['plus30'];
            delete data['plus60'];
            delete data['plus90'];
            delete data['plus120'];
            delete data['plus150'];
            delete data['plus180'];

            data['parentAgentId'] = parent?.agentId?.toString();
            const nonRequiredFields = [];
            kycFields[0]?.tblKycSetDetails?.forEach((item) => {
                if (kycFixed?.includes(item?.tblKycAttribute?.attributeName) && item?.isActive === 'Y') {
                    const attributeName = item?.tblKycAttribute?.attributeName;
                    data[attributeName] = formik.values[item?.tblKycAttribute?.attributeName];
                } else {
                    if (formik.values[item?.tblKycAttribute?.attributeName] && item?.isActive === 'Y') {
                        nonRequiredFields.push({
                            infoKey: item?.tblKycAttribute?.attributeName,
                            infoValue: formik.values[item?.tblKycAttribute?.attributeName]
                        });
                    }
                    // delete data[item?.tblKycAttribute?.attributeName];
                }
            });
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data,
                        agentId: apiData?.agentId,
                        accountClassificationName: name,
                        contact: false,
                        channelIdList: arrayOfChannelIds
                    },
                    additionalInformation: nonRequiredFields,
                    checkSum: ''
                }
            };
            kycFields[0]?.tblKycSetDetails?.forEach((item) => {
                if (kycFixed?.includes(item?.tblKycAttribute?.attributeName) && item?.isActive === 'Y') {
                } else {
                    delete newData?.data?.payLoad[item?.tblKycAttribute?.attributeName];
                }
            });
            delete newData?.data?.payLoad?.commissionHierarchyId;
            delete newData?.data?.payLoad?.kfsInput;
            delete newData?.data?.payLoad?.digitalSigningInput;
            delete newData?.data?.payLoad?.hierarchyName;
            delete newData?.data?.payLoad?.ParentCnic;
            delete newData?.data?.payLoad?.parentMobileNumber;

            formik.setFieldValue('parentAgentId', '');
            formik.setFieldValue('ParentCnic', '');
            formik.setFieldValue('parentMobileNumber', '');

            formik.setFieldValue('debitCard', data['debitCard'] === 'Y' ? true : false);
            formik.setFieldValue('chequeBook', data['chequeBook'] === 'Y' ? true : false);
            const res = await dispatch(handlePostRequest(newData, '/account/v1/agentaccount/updateagentaccount', true, false, 'accountonboarding'));
            if (res?.message.includes('successfully') || res?.message.includes('Success')) {
                addImages();
            } else {
            }
            setloading(false);
        }
    });

    useEffect(() => {
        if (apiData) {
            formik.setFieldValue('pricingProfileId', apiData?.tblPricingProfile?.pricingProfileId?.toString() || '');
            formik.setFieldValue('commissionId', apiData?.tblCommissionProfile?.commissionProfileId?.toString() || '');
            formik.setFieldValue('commissionHierarchyId', apiData?.tblCommissionHierarchy?.commissionHierarchyId?.toString() || '');
            formik.setFieldValue('transLimitId', apiData?.tblTransLimit?.transLimitId?.toString() || '');
            formik.setFieldValue('hierarchyName', apiData?.tblSalesHierarchy?.hierarchyName || '');
            formik.setFieldValue('empId', apiData?.tblSalesForce?.employeeId || '');
            setEmpIdData(apiData?.tblSalesForce?.employeeId || '');
            formik.setFieldValue('productCatalogId', apiData?.tblAgentChannelList[0]?.tblProductCatalog?.productCatalogId?.toString() || '');
            formik.setFieldValue('chequeBook', apiData?.chequeBook === 'Y');
            formik.setFieldValue('debitCard', apiData?.debitCard === 'Y');
            formik.setFieldValue('noOfLeaves', apiData?.noOfLeaves || '');
            formik.setFieldValue('debitCardAddress', apiData?.debitCardAddress || '');
            formik.setFieldValue('debitCardName', apiData?.debitCardName || '');
            formik.setFieldValue('chequeWaiver', apiData?.chequeWaiver || '');
            formik.setFieldValue('atmWaiver', apiData?.atmWaiver || '');
            setApiParentId(apiData?.parentAgentId);
            setApiGetParentId(apiData?.parentAgentId);
            formik.setFieldValue('accountNumber', apiData?.accountNumber);
            formik.setFieldValue('addressInfos', apiData?.addressInfos);
            formik.setFieldValue('accountTitle', apiData?.accountTitle);
            formik.setFieldValue('plus30', apiData?.tblEcibResponse?.plus30);
            formik.setFieldValue('plus60', apiData?.tblEcibResponse?.plus60);
            formik.setFieldValue('plus90', apiData?.tblEcibResponse?.plus90);
            formik.setFieldValue('plus120', apiData?.tblEcibResponse?.plus120);
            formik.setFieldValue('plus150', apiData?.tblEcibResponse?.plus150);
            formik.setFieldValue('plus180', apiData?.tblEcibResponse?.plus180);
            formik.setFieldValue('birthPlace', apiData?.birthPlace);
        }
    }, [apiData]);
    useEffect(() => {
        if (apiGetParentId) {
            getParentData();
        }
    }, [apiGetParentId]);
    useEffect(() => {
        if (kycFields && apiData?.kycView) {
            kycFields[0]?.tblKycSetDetails?.forEach((item) => {
                formik?.setFieldValue(item?.tblKycAttribute?.attributeName, JSON?.parse(apiData?.kycView)[item?.tblKycAttribute?.attributeName] || '');
            });
        }
    }, [kycFields, apiData?.kycView]);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    //cheque book leaves dropdown
    const chequeBookLeaves = [
        { name: '25', value: '25' },
        { name: '50', value: '50' },
        { name: '100', value: '100' },
        { name: '250', value: '250' }
    ];

    //channel dropdown options API
    const getChannel = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchannels/LKP_CHANNEL:*');
        setChannelOption(res?.payLoad);
    };

    const panelFooterTemplate = () => {
        const length = formik.values.channelIdList ? formik.values.channelIdList.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    //commission profile dropdown options API
    const getCommissionProfile = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getagentdatabyaccounttype/TBL_COMMISSION_PROFILE/${accountClassificationId}`);
        setCommissionProfile(res?.payLoad);
    };

    //pricing profile dropdown options API
    const getPricingProfile = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getagentdatabyaccounttype/TBL_PRICING_PROFILE/${accountClassificationId}`);
        setPricingProfile(res?.payLoad);
    };

    //limit profile dropdown options API
    const getLimitProfile = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getagentdatabyaccounttype/TBL_TRANS_LIMIT/${accountClassificationId}`);
        setLimitProfile(res?.payLoad);
    };

    //product catalog dropdown options API
    const getProductCatalog = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/productCatalog/TBL_PRODUCT_CATALOG:*');
        setProductcatalog(res?.payLoad);
    };

    //commission Hierarchy dropdown options API
    const getCommissionHierarchy = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getagentdatabyaccounttype/TBL_COMMISSION_HIERARCHY');
        setCommissionHierarchy(res?.payLoad);
    };

    //search on the basis of employee ID
    const searchEmployeeId = async () => {
        setUseCaseError({ empId: null });
        if (formik.values.empId === '') {
            setUseCaseError({ empId: 'This field is required' });
        } else {
            formik.setFieldValue('hierarchyName', '');
            const id = formik.values.empId;
            if (id) {
                dispatch(loadingAction(true));
                const res = await handleGetRequest(`/account/v1/sales/getemployeebyid/${id}`);
                if (res == undefined || res) setTimeout(() => dispatch(loadingAction(false)), 1000);
                if (res?.payLoad?.length) {
                    setEmpIdData(res?.payLoad[0]?.employeeId);
                    formik.setFieldValue('hierarchyName', res?.payLoad[0]?.salesHierarchyName);
                } else {
                    formik.setFieldValue('hierarchyName', '');
                }
            }
        }
    };

    const getKycFields = async () => {
        const res = await handleGetRequest(`/account/v1/agentaccount/getkycbyclassificationname/${name}`);
        setKycFields(res?.payLoad);
        let tblkyc = res?.payLoad[0].tblKycSetDetails;
        setTblKycSetDetails(tblkyc);
    };
    //get parent data
    const getParentData = async () => {
        const newdata = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    accountClassificationName: name,
                    cnic: formik.values.ParentCnic,
                    mobileNumber: formik.values.parentMobileNumber,
                    agentId: apiParentId ?? formik?.values?.parentAgentId
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newdata, '/account/v1/agentaccount/getparentagent'));
        setParent(res?.payLoad);
        formik.setFieldValue('parentAgentId', '');
        formik.setFieldValue('ParentCnic', '');
        formik.setFieldValue('parentMobileNumber', '');
        // if (res?.payLoad) {
        //     input1.current.value = res?.payLoad?.cnic;
        //     input2.current.value = res?.payLoad?.agentPhoneNo;
        //     formik.setFieldValue('ParentCnic', res?.payLoad?.cnic);
        //     formik.setFieldValue('parentMobileNumber', res?.payLoad?.agentPhoneNo);
        // }
    };

    //empty parent data table
    const handleDelete = () => {
        setParent([]);
        if (input1.current) {
            input1.current.value = '';
        }
        if (input2.current) {
            input2.current.value = '';
        }
        if (input3.current) {
            input3.current.value = '';
        }
    };
    // useEffect(() => {
    //     getParentData();
    // }, [formik.values.parentAgentId]);

    useEffect(() => {
        if (apiData) {
            getCommissionProfile();
            getCommissionHierarchy();
            getLimitProfile();
            getPricingProfile();
            getKycFields();
            getProductCatalog();
            getChannel();
            const extractedChannelIds = apiData?.tblAgentChannelList
                ?.filter((entry) => entry?.isActive === 'Y')
                .map((entry) => ({
                    lovId: entry?.lkpChannel?.channelId?.toString()
                }));
            formik.setFieldValue('channelIdList', extractedChannelIds);
        }
    }, [apiData]);

    const handleKfsInput = () => {
        kfsRef.current.click();
    };

    const handleDigitalSigningInput = () => {
        digitalSigningRef.current.click();
    };

    const kfsChange = (event) => {
        setKfsInputFile('');
        formik.setFieldValue('kfsInput', '');
        setKfsFileError('');
        const selectedFile = event.target.files[0];
        const fileTypes = ['pdf', 'image', 'document', 'msword'];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setKfsFileError(error);
        } else if (newFile) {
            setKfsLink(URL.createObjectURL(selectedFile));
            setKfsInput(selectedFile.name);
            formik.setFieldValue('kfsInput', selectedFile.name);
            setKfsInputFile(selectedFile);
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                setKfsInputBase64(base64Data);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const digitalSigningChange = (event) => {
        setDigitalSigningFile('');
        formik.setFieldValue('digitalSigningInput', '');
        setDsFileError('');
        const selectedFile = event.target.files[0];
        const fileTypes = ['pdf', 'image', 'document', 'msword'];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setDsFileError(error);
        } else if (newFile) {
            setDigitalSigningLink(URL.createObjectURL(selectedFile));
            setDigitalSigningInput(selectedFile.name);
            formik.setFieldValue('digitalSigningInput', selectedFile.name);
            setDigitalSigningFile(selectedFile);
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                setDigitalSigningInputBase64(base64Data);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const addImages = async () => {
        const originalFileName = kfsInputFile?.name;
        const fileExtension = originalFileName?.split('.')?.pop();
        const newFileNameWithoutExtension = 'KEY_FACT';
        const newFileName = `${newFileNameWithoutExtension}.${fileExtension}`;
        const KEY_FACT = new File([kfsInputFile], newFileName);
        const originalFileName2 = digitalSigningFile?.name;
        const fileExtension2 = originalFileName2?.split('.')?.pop();
        const newFileNameWithoutExtension2 = 'DIGITAL_SIGNING';
        const newFileName2 = `${newFileNameWithoutExtension2}.${fileExtension2}`;
        const DIGITAL_SIGNING = new File([digitalSigningFile], newFileName2);
        const documentsRequest = {
            data: {
                security: {
                    userName: 'string',
                    password: 'string',
                    securityToken: 'string'
                },
                account: {
                    msidn: 'string',
                    iban: 'string',
                    bban: 'string',
                    pan: 'string',
                    currency: 'string'
                },
                channel: 'string',
                terminal: 'string',
                reterivalReferenceNumber: 'string',
                payLoad: {
                    agentId: apiData?.agentId,
                    update: true,
                    accountClassificationName: name,
                    levelName: kycFields[0]?.accountLevel || ''
                },
                additionalInformation: [
                    {
                        infoKey: 'string',
                        infoValue: 'string'
                    }
                ],
                checkSum: 'string'
            }
        };
        const formData = new FormData();
        formData.append('documentsRequest', JSON.stringify(documentsRequest));
        formData.append('files', KEY_FACT);
        formData.append('files', DIGITAL_SIGNING);
        const res = await dispatch(handlePostRequest(formData, '/documents/v1/documents/uploadagentdocs', true, true));
    };

    const createFileFromBase64 = (apiResponse, type) => {
        const base64Data = apiResponse?.docBase64;
        const fileExtension = apiResponse?.documentExt;
        if (base64Data && fileExtension) {
            const binaryData = base64.toByteArray(base64Data);
            const blob = new Blob([binaryData], { type: 'application/octet-stream' });
            const fileName = `${type}${fileExtension}`;
            const file = new File([blob], fileName, {
                type: 'application/octet-stream'
            });

            if (type === 'KEY_FACT') {
                setKfsInput(fileName);
                formik.setFieldValue('kfsInput', fileName);
                setKfsInputFile(file);
                setKfsInputBase64(base64Data);
            } else if (type === 'DIGITAL_SIGNING') {
                setDigitalSigningInput(fileName);
                formik.setFieldValue('digitalSigningInput', fileName);
                setDigitalSigningFile(file);
                setDigitalSigningInputBase64(base64Data);
            }
        }
    };

    useEffect(() => {
        if (docData?.length > 0) {
            var KEY_FACT = docData?.filter((item) => item?.lkpDocumentType?.documentTypeDescr === 'KEY_FACT')[0];
            var DIGITAL_SIGNING = docData?.filter((item) => item?.lkpDocumentType?.documentTypeDescr === 'DIGITAL_SIGNING')[0];
            if (KEY_FACT) {
                createFileFromBase64(KEY_FACT, 'KEY_FACT');
                setKfsLink(`${baseURL}/document/${docData[0]?.originalPath?.split('/documents/')[1]}`);
            }
            if (DIGITAL_SIGNING) {
                createFileFromBase64(DIGITAL_SIGNING, 'DIGITAL_SIGNING');
                setDigitalSigningLink(`${baseURL}/document/${docData[1]?.originalPath?.split('/documents/')[1]}`);
            }
        }
    }, [docData]);

    function convertToTitleCase(inputString) {
        const words = inputString.split(/(?=[A-Z])/);
        const titleCaseString = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

        return titleCaseString;
    }

    const handleInputChange = (e) => {
        const inputValue = e.target.value.replace(/[^a-zA-Z\s]+/g, '').toUpperCase();
        formik.setFieldValue('debitCardName', inputValue);
    };
    // const handleInputChangeNumber = (e, type) => {
    //     let newValue = e.target.value;
    //     if (type?.toLowerCase()?.includes('number')) {
    //         if (/^[0-9]*$/.test(newValue)) {
    //             if (newValue.length === 0) {
    //                 newValue = '0';
    //             } else if (newValue.charAt(0) !== '0') {
    //                 newValue = newValue.substring(1);
    //             }
    //         }
    //         formik.setFieldValue(type, newValue);
    //     } else {
    //         formik.setFieldValue(type, e.target.value);
    //     }
    // };

    // const handleKeyPress = (event) => {
    //     if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
    //         // Prevent the up and down arrow keys from changing the value
    //         event.preventDefault();
    //     }
    // };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const handleEmployeeIdChange = (e) => {
        // Ensure it's a numeric value and limit to 4 characters
        const inputText = e.target.value.replace(/[^0-9]/g, '').slice(0, 7);
        formik.setFieldValue('empId', inputText);
    };

    const handleParentAgentId = (e) => {
        // Ensure it's a numeric value and limit to 4 characters
        const inputText = e.target.value.replace(/[^0-9]/g, '').slice(0, 7);
        formik.setFieldValue('parentAgentId', inputText);
        setApiParentId(inputText);
    };

    const handleParentCnic = (e) => {
        // Ensure it's a numeric value and limit to 4 characters
        const inputText = e.target.value.replace(/[^0-9]/g, '').slice(0, 13);
        formik.setFieldValue('ParentCnic', inputText);
    };

    const handleParentNumber = (e) => {
        // Ensure it's a numeric value and limit to 4 characters
        const inputText = e.target.value.replace(/[^0-9]/g, '').slice(0, 11);
        formik.setFieldValue('parentMobileNumber', inputText);
    };

    useEffect(() => {
        if (formik.values.parentAgentId || formik.values.ParentCnic || formik.values.parentMobileNumber) {
            setSearchBtnDisable(false);
        } else if (!isEditable) {
            setSearchBtnDisable(true);
        } else {
            setSearchBtnDisable(true);
        }
    }, [formik.values.parentAgentId, formik.values.ParentCnic, formik.values.parentMobileNumber]);

    const remarksValue = formik.values.remarks;
    const statusValue = formik.values.accountStatus;
    const [remarksDialog, setRemarksDialog] = useState(false);

    const dialogData = (data) => {
        formik.setFieldValue('accountStatus', data?.accountStatus);
        formik.setFieldValue('remarks', data?.remarks);
    };

    const handleRemarksDialog = (e) => {
        e.preventDefault();
        setRemarksDialog(true);
    };

    const onHideRemarksDialog = () => {
        setRemarksDialog(false);
    };
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (e.target.name === 'empId') {
                searchEmpId.current.click();
            }
            if (e.target.name === 'parentAgentId' || e.target.name === 'ParentCnic' || e.target.name === 'parentMobileNumber') {
                searchParentData.current.click();
            }
            e.preventDefault();
        }
    };

    return (
        <>
            <Dialog visible={remarksDialog} style={{ width: '30vw' }} onHide={onHideRemarksDialog}>
                <RemarksDailog dialogData={dialogData} onHideRemarksDialog={onHideRemarksDialog} remarksValue={remarksValue} statusValue={statusValue} />
            </Dialog>
            <div className="my-3">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {kycFields && (
                        <div className="custom-card p-3 my-3">
                            <div className="m-3 Form__Header Full__Width">
                                <p>RETAILER KYC SETS</p>
                            </div>
                            <div className="p-fluid p-formgrid grid mb-2 mx-3">
                                {kycFields[0]?.tblKycSetDetails?.map((item, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            {item?.isActive === 'Y' && (
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field withoutArrow">
                                                        <label htmlFor={item?.tblKycAttribute?.attributeName} className={classNames({ 'p-error': isFormFieldValid(item?.lovId) }, 'Label__Text')}>
                                                            {convertToTitleCase(item?.tblKycAttribute?.attributeName)}
                                                            {(kycFixed?.includes(item?.tblKycAttribute?.attributeName) || item?.isMandatory === 'Y') && <span className="Label__Required">*</span>}
                                                        </label>
                                                        <InputText
                                                            id={item?.tblKycAttribute?.attributeName}
                                                            name={item?.tblKycAttribute?.attributeName}
                                                            value={formik.values[item?.tblKycAttribute?.attributeName] || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(item?.tblKycAttribute?.attributeName) }, 'Input__Round')}
                                                            disabled={kycFixed?.includes(item?.tblKycAttribute?.attributeName) || item?.tblKycAttribute?.isEditable === 'N' || !isEditable}
                                                            type={item?.tblKycAttribute?.attributeName.toLowerCase().includes('date') ? 'date' : 'text'}
                                                            max={item?.tblKycAttribute?.attributeName.toLowerCase().includes('date') ? previousDate : null}
                                                        />

                                                        {getFormErrorMessage(item?.tblKycAttribute?.attributeName)}
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    <div className="p-fluid p-formgrid grid mb-3">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="pricingProfileId" className="Label__Text">
                                    Pricing Profile
                                </label>
                                <Dropdown
                                    id="pricingProfileId"
                                    placeholder="Select Pricing Profile"
                                    options={pricingProfile}
                                    optionLabel="name"
                                    name="pricingProfileId"
                                    optionValue="lovId"
                                    value={formik.values.pricingProfileId || ''}
                                    disabled={!isEditable}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('pricingProfileId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="commissionId" className="Label__Text">
                                    Commission Profile
                                </label>
                                <Dropdown
                                    id="commissionId"
                                    placeholder="Select Commission Profile"
                                    options={commissionProfile}
                                    optionLabel="name"
                                    name="commissionId"
                                    optionValue="lovId"
                                    value={formik.values.commissionId || ''}
                                    disabled={!isEditable}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('commissionId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('commissionId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="commissionHierarchyId" className="Label__Text">
                                    Commission Hierarchy<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="commissionHierarchyId"
                                    placeholder="Select Commission Hierarchy"
                                    options={commissionHierarchy}
                                    optionLabel="name"
                                    name="commissionHierarchyId"
                                    optionValue="lovId"
                                    disabled={!isEditable}
                                    value={formik.values.commissionHierarchyId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('commissionHierarchyId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('commissionHierarchyId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="transLimitId" className="Label__Text">
                                    Limit Profile
                                </label>
                                <Dropdown
                                    id="transLimitId"
                                    placeholder="Select Limit Profile"
                                    options={limitProfile}
                                    optionLabel="name"
                                    name="transLimitId"
                                    optionValue="lovId"
                                    value={formik.values.transLimitId || ''}
                                    disabled={!isEditable}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transLimitId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('transLimitId')}
                            </div>
                        </div>
                    </div>
                    <div className="custom-card p-3 my-3">
                        <div className="m-3 Form__Header Full__Width">
                            <p>PARENT</p>
                        </div>
                        <div className="p-fluid p-formgrid grid mb-2 mx-3">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field withoutArrow">
                                    <label htmlFor="parentAgentId" className="Label__Text">
                                        Agent ID
                                    </label>
                                    <InputText
                                        id="parentAgentId"
                                        name="parentAgentId"
                                        value={formik.values.parentAgentId}
                                        disabled={!isEditable}
                                        onChange={(e) => {
                                            handleParentAgentId(e);
                                        }}
                                        className="Input__Round"
                                        ref={input3}
                                        onKeyDown={(e) => handleKeyPress(e)}
                                    />
                                    {/* {getFormErrorMessage('parentAgentId')} */}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field withoutArrow">
                                    <label htmlFor="ParentCnic" className="Label__Text">
                                        CNIC
                                    </label>
                                    <InputText
                                        id="ParentCnic"
                                        name="ParentCnic"
                                        value={formik.values.ParentCnic}
                                        disabled={!isEditable}
                                        onChange={(e) => {
                                            handleParentCnic(e);
                                        }}
                                        className="Input__Round"
                                        ref={input1}
                                        onKeyDown={(e) => handleKeyPress(e)}
                                    />
                                    {/* {getFormErrorMessage('ParentCnic')} */}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="parentMobileNumber" className="Label__Text">
                                        Mobile Number
                                    </label>
                                    <InputText
                                        id="parentMobileNumber"
                                        name="parentMobileNumber"
                                        value={formik.values.parentMobileNumber}
                                        disabled={!isEditable}
                                        onChange={(e) => {
                                            handleParentNumber(e);
                                        }}
                                        className="Input__Round"
                                        ref={input2}
                                        onKeyDown={(e) => handleKeyPress(e)}
                                    />
                                    {/* {getFormErrorMessage('parentMobileNumber')} */}
                                </div>
                            </div>
                        </div>
                        <div className="Usergroup__SearchBtn p-field col-12 md:col-12 mb-3 pb-3 flex justify-content-center ">
                            <Button label="Search" type="button" ref={searchParentData} onClick={() => getParentData()} className="Btn__Dark__Search mr-4" disabled={searchBtnDisable} />
                        </div>
                        <div className="grid mx-3">
                            <div className="col-12">
                                <div className="card">
                                    <DataTable className="datatable-responsive" value={parent ? (Array.isArray(parent) ? parent : [parent]) : ''} emptyMessage="No List found." responsiveLayout="scroll">
                                        <Column body={(item) => (item?.franchiseName ? item?.franchiseName : item?.agentName)} header="Name" />
                                        <Column field="cnic" header="CNIC" />
                                        <Column field="agentPhoneNo" header="Mobile Number" />
                                        <Column field="agentId" header="Agent ID" />
                                        <Column header="Action" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-3">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="channelIdList" className="Label__Text">
                                    Channel<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelIdList') }, 'Dropdown__Round')}
                                    id="channelIdList"
                                    options={channelOption}
                                    name="channelIdList"
                                    placeholder="Select Channel"
                                    optionLabel="name"
                                    optionValue="lovId"
                                    panelFooterTemplate={panelFooterTemplate}
                                    filter
                                    value={formik?.values?.channelIdList?.map((trans) => trans.lovId)}
                                    disabled={!isEditable}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            'channelIdList',
                                            e.value.map((lovId) => ({
                                                lovId
                                            }))
                                        );
                                    }}
                                />
                                {getFormErrorMessage('channelIdList')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="empId" className="Label__Text">
                                    Employee ID<span className="Label__Required">*</span>
                                </label>
                                <div className="flex withoutArrow">
                                    <InputText
                                        id="empId"
                                        name="empId"
                                        value={formik.values.empId}
                                        placeholder="Enter Employee ID"
                                        onChange={(e) => {
                                            setUseCaseError({ empId: null });
                                            handleEmployeeIdChange(e);
                                            if (!e.target.value) {
                                                formik.setFieldValue('hierarchyName', '');
                                            }
                                        }}
                                        disabled={!isEditable}
                                        type="number"
                                        className={classNames({ 'p-invalid': useCaseError.empId || isFormFieldValid('empId') }, 'Input__Round')}
                                        maxLength={20}
                                        onKeyDown={(e) => handleKeyPress(e)}
                                    />
                                    {isEditable && (
                                        <Button icon={loadingIcon || ''} ref={searchEmpId} onClick={() => searchEmployeeId()} type="button" className="Btn__Dark border-circle" style={{ height: '35px', width: '35px', marginLeft: '1rem' }} />
                                    )}
                                </div>
                                {useCaseError.empId ? <small className="p-error">{formik?.errors?.empId}</small> : getFormErrorMessage('empId')}
                            </div>
                        </div>
                    </div>
                    <div className="custom-card p-3 my-3">
                        <div className="m-3 Form__Header Full__Width">
                            <p>EMPLOYEE ID</p>
                        </div>
                        <div className="p-fluid p-formgrid grid mb-2 mx-3">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="hierarchyName" className="Label__Text">
                                        Sales Hierarchy Name
                                    </label>
                                    <InputText
                                        id="hierarchyName"
                                        name="hierarchyName"
                                        onChange={formik.handleChange}
                                        value={formik.values.hierarchyName}
                                        disabled={true}
                                        className={classNames({ 'p-invalid': isFormFieldValid('hierarchyName') }, 'Input__Round')}
                                        maxLength={200}
                                    />
                                    {getFormErrorMessage('hierarchyName')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-3">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productCatalogId" className="Label__Text">
                                    Product catalog
                                </label>
                                <Dropdown
                                    id="productCatalogId"
                                    placeholder="Select Product Catalog"
                                    options={productCatalog}
                                    optionLabel="name"
                                    name="productCatalogId"
                                    optionValue="lovId"
                                    value={formik.values.productCatalogId || ''}
                                    disabled={!isEditable}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productCatalogId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('productCatalogId')}
                            </div>
                        </div>
                        {/* <div className="p-field col-12 md:col-4 flex align-items-end mb-2 pt-3 pb-3">
                            <div className="p-field">
                                <Checkbox
                                    inputId="chequeBook"
                                    name="chequeBook"
                                    checked={formik.values.chequeBook}
                                    // disabled={!isEditable}
                                    disabled
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        formik.setFieldValue('noOfLeaves', '');
                                        setDigitalSigningFile('');
                                        formik.setFieldValue('digitalSigningInput', '');
                                        setDigitalSigningInput('');
                                        setDigitalSigningInputBase64('');
                                        setDigitalSigningLink('');
                                    }}
                                />
                                <label htmlFor="chequeBook" className="Label__Text">
                                    Cheque Book
                                </label>
                            </div>
                            <div className="p-field ml-3">
                                <Checkbox
                                    inputId="debitCard"
                                    name="debitCard"
                                    checked={formik.values.debitCard}
                                    // disabled={!isEditable}
                                    disabled
                                    onChange={(e) => {
                                        formik.handleChange(e);

                                        formik.setFieldValue('debitCardName', '');

                                        formik.setFieldValue('debitCardAddress', '');
                                    }}
                                />
                                <label htmlFor="debitCard" className="Label__Text">
                                    ATM/Debit Card
                                </label>
                            </div>
                        </div> */}
                    </div>
                    {/* {formik.values.chequeBook || formik.values.debitCard ? (
                        <div className="custom-card p-3 my-3">
                            <div className="p-fluid p-formgrid grid mb-2 mx-3">
                                {formik.values.chequeBook && (
                                    <>
                                        <div className="m-3 Form__Header Full__Width">
                                            <p>CHEQUE BOOK DETAILS</p>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="noOfLeaves" className="Label__Text">
                                                    No of leaves<span className="Label__Required">*</span>
                                                </label>
                                                <Dropdown
                                                    id="noOfLeaves"
                                                    options={chequeBookLeaves}
                                                    name="noOfLeaves"
                                                    placeholder="Select Number of Leaves"
                                                    optionLabel="name"
                                                    optionValue="value"
                                                    value={formik.values.noOfLeaves}
                                                    onChange={formik.handleChange}
                                                    disabled={!isEditable}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('noOfLeaves') }, 'Dropdown__Round')}
                                                />
                                                {getFormErrorMessage('noOfLeaves')}
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="channelName" className="Label__Text">
                                                    Waiver
                                                </label>
                                                <div className="flex">
                                                    <div className="mt-2 mr-3">
                                                        <RadioButton inputId="chequeApplicable" name="chequeWaiver" value="Y" disabled={!isEditable} onChange={formik.handleChange} checked={formik.values.chequeWaiver === 'Y'} />
                                                        <label htmlFor="chequeApplicable" className="Label__Text">
                                                            Applicable
                                                        </label>
                                                    </div>
                                                    <div className="mt-2">
                                                        <RadioButton inputId="chequeNotApplicable" name="chequeWaiver" value="N" disabled={!isEditable} onChange={formik.handleChange} checked={formik.values.chequeWaiver === 'N'} />
                                                        <label htmlFor="chequeNotApplicable" className="Label__Text">
                                                            Not Applicable
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {formik.values.debitCard && (
                                    <>
                                        <div className="m-3 Form__Header Full__Width">
                                            <p>ATM / DEBIR CARD DEATILS</p>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="debitCardName" className="Label__Text">
                                                    Name<span className="Label__Required">*</span>
                                                </label>
                                                <InputText
                                                    id="debitCardName"
                                                    name="debitCardName"
                                                    placeholder="Enter Name"
                                                    value={formik.values.debitCardName}
                                                    disabled={!isEditable}
                                                    onInput={handleInputChange}
                                                    maxLength={200}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('debitCardName') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('debitCardName')}
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="debitCardAddress" className="Label__Text">
                                                    Postal Address<span className="Label__Required">*</span>
                                                </label>
                                                <InputText
                                                    id="debitCardAddress"
                                                    name="debitCardAddress"
                                                    placeholder="Enter Postal Address"
                                                    value={formik.values.debitCardAddress}
                                                    disabled={!isEditable}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('debitCardAddress') }, 'Input__Round')}
                                                    maxLength={200}
                                                />
                                                {getFormErrorMessage('debitCardAddress')}
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="channelName" className="Label__Text">
                                                    Waiver
                                                </label>
                                                <div className="flex">
                                                    <div className="mt-2 mr-3">
                                                        <RadioButton inputId="applicable" name="atmWaiver" value="Y" disabled={!isEditable} onChange={formik.handleChange} checked={formik.values.atmWaiver === 'Y'} />
                                                        <label htmlFor="applicable" className="Label__Text">
                                                            Applicable
                                                        </label>
                                                    </div>
                                                    <div className="mt-2">
                                                        <RadioButton inputId="notApplicable" name="atmWaiver" value="N" disabled={!isEditable} onChange={formik.handleChange} checked={formik.values.atmWaiver === 'N'} />
                                                        <label htmlFor="notApplicable" className="Label__Text">
                                                            Not Applicable
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )} */}
                    <div className="p-fluid p-formgrid grid mb-3">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <div className="mb-2">
                                    <label className={classNames({ 'p-error': isFormFieldValid('kfsInput') }, 'Label__Text')}>
                                        Key Fact Statement <span className="Label__Required">*</span>
                                    </label>
                                    <div className="file-input-container w-100" onClick={handleKfsInput}>
                                        <input type="file" accept="image/*, .pdf, .doc, .docx" style={{ display: 'none' }} ref={kfsRef} onChange={kfsChange} disabled={!isEditable} />
                                        <input
                                            type="text"
                                            className={classNames({ 'p-inputtext p-component p-invalid': isFormFieldValid('kfsInput') }, 'file-input-field Input__Round')}
                                            placeholder="Choose a file..."
                                            value={formik.values.kfsInput}
                                            readOnly
                                            disabled={!isEditable}
                                        />
                                        <span className="file-upload-icon">
                                            <i className="pi pi-paperclip" />
                                        </span>
                                    </div>
                                    {kfsFileError ? <small className="p-error">{kfsFileError}</small> : getFormErrorMessage('kfsInput')}{' '}
                                </div>
                            </div>
                            {docData && docData?.length > 0 && (
                                <React.Fragment>
                                    {docData[0] && (
                                        <div className="Down__Btn">
                                            <Button
                                                type="button"
                                                label="View Document"
                                                className="Btn__Dark"
                                                onClick={() => {
                                                    window.open(kfsLink, '_blank');
                                                }}
                                                disabled={!formik?.values?.kfsInput}
                                            />
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <div className="mb-2">
                                    <label className={classNames({ 'p-error': isFormFieldValid('digitalSigningInput') }, 'Label__Text')}>
                                        Digital Signing
                                        {formik?.values?.chequeBook && <span className="Label__Required">*</span>}
                                    </label>
                                    <div className="file-input-container w-100" onClick={handleDigitalSigningInput}>
                                        <input type="file" accept="image/*, .pdf, .doc, .docx" style={{ display: 'none' }} ref={digitalSigningRef} onChange={digitalSigningChange} disabled={!isEditable} />
                                        <input
                                            type="text"
                                            className={classNames({ 'p-inputtext p-component p-invalid': isFormFieldValid('digitalSigningInput') }, 'file-input-field Input__Round')}
                                            placeholder="Choose a file..."
                                            value={formik.values.digitalSigningInput}
                                            readOnly
                                            disabled={!isEditable}
                                        />
                                        <span className="file-upload-icon">
                                            <i className="pi pi-paperclip" />
                                        </span>
                                    </div>
                                    {dsFileError ? <small className="p-error">{dsFileError}</small> : getFormErrorMessage('digitalSigningInput')}
                                </div>
                            </div>
                            {docData && docData?.length > 0 && (
                                <React.Fragment>
                                    {docData[1] && (
                                        <div className="Down__Btn">
                                            <Button
                                                type="button"
                                                label="View Document"
                                                className="Btn__Dark"
                                                onClick={() => {
                                                    window.open(digitalSigningLink, '_blank');
                                                }}
                                                disabled={!formik?.values?.digitalSigningInput}
                                            />
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountTitle" className={classNames({ 'p-error': isFormFieldValid('accountTitle') }, 'Label__Text')}>
                                    Account Title <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    id="accountTitle"
                                    name="accountTitle"
                                    placeholder="Enter Account Title"
                                    value={formik.values.accountTitle || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountTitle') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('accountTitle')}
                            </div>
                        </div>
                        {formik.values.addressInfos?.map((item, index) => {
                            return (
                                <div className="p-field col-12 md:col-4 pt-3 pb-3" key={index}>
                                    <div className="p-field">
                                        <label htmlFor={`addressInfos.${index}.fullAddress`} className={classNames({ 'p-error': isFormFieldValid(`addressInfos.${index}.fullAddress`) }, 'Label__Text')}>
                                            Address <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            disabled
                                            id={`addressInfos.${index}.fullAddress`}
                                            name={`addressInfos.${index}.fullAddress`}
                                            placeholder="Enter Adds"
                                            value={item?.fullAddress || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid(`addressInfos.${index}.fullAddress`) }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage(`addressInfos.${index}.fullAddress`)}
                                    </div>
                                </div>
                            );
                        })}

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountNumber" className={classNames({ 'p-error': isFormFieldValid('accountNumber') }, 'Label__Text')}>
                                    Account Number <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="accountNumber"
                                    name="accountNumber"
                                    placeholder="Select Account Number"
                                    value={formik.values.accountNumber || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountNumber') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('accountNumber')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="plus30" className={classNames({ 'p-error': isFormFieldValid('plus30') }, 'Label__Text')}>
                                    ECIB Plus 30 <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="plus30"
                                    name="plus30"
                                    placeholder="Select ECIB Response"
                                    value={formik.values.plus30 || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('plus30') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('plus30')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="plus60" className={classNames({ 'p-error': isFormFieldValid('plus60') }, 'Label__Text')}>
                                    ECIB Plus 60 <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="plus60"
                                    name="plus60"
                                    placeholder="Select ECIB Response"
                                    value={formik.values.plus60 || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('plus60') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('plus60')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="plus90" className={classNames({ 'p-error': isFormFieldValid('plus90') }, 'Label__Text')}>
                                    ECIB Plus 90 <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="plus90"
                                    name="plus90"
                                    placeholder="Select ECIB Response"
                                    value={formik.values.plus90 || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('plus90') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('plus90')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="plus120" className={classNames({ 'p-error': isFormFieldValid('plus120') }, 'Label__Text')}>
                                    ECIB Plus 120 <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="plus120"
                                    name="plus120"
                                    placeholder="Select ECIB Response"
                                    value={formik.values.plus120 || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('plus120') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('plus120')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="plus150" className={classNames({ 'p-error': isFormFieldValid('plus150') }, 'Label__Text')}>
                                    ECIB Plus 150 <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="plus150"
                                    name="plus150"
                                    placeholder="Select ECIB Response"
                                    value={formik.values.plus150 || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('plus150') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('plus150')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="plus180" className={classNames({ 'p-error': isFormFieldValid('plus180') }, 'Label__Text')}>
                                    ECIB Plus 180 <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="plus180"
                                    name="plus180"
                                    placeholder="Select ECIB Response"
                                    value={formik.values.plus180 || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('plus180') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('plus180')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="birthPlace" className={classNames({ 'p-error': isFormFieldValid('birthPlace') }, 'Label__Text')}>
                                    Place of birth <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="birthPlace"
                                    name="birthPlace"
                                    placeholder="Enter place of birth"
                                    value={formik.values.birthPlace || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('birthPlace') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('birthPlace')}
                            </div>
                        </div>
                    </div>
                    {!isEditable ? (
                        <React.Fragment>
                            <div className="Down__Btn my-5">
                                <Button type="button" label="Okay" className="Btn__Dark" onClick={() => navigate('/accountonboarding')} />
                            </div>
                            <AuthorizationHistoryTable refTableId={apiData?.agentId} tableName={'TBL_AGENT'} />
                        </React.Fragment>
                    ) : (
                        <div className="Down__Btn">
                            <Button type="submit" label="Update" className="Btn__Dark" onClick={() => ScrollMover()} />
                            <Button type="button" onClick={() => navigate('/accountonboarding')} label="Cancel" className="Btn__Transparent" />
                        </div>
                    )}
                </form>
            </div>
        </>
    );
}

export default ViewRetailer;

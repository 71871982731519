import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../services/PostTemplate';
import { handleGetRequest } from '../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { baseURL } from '../../Config';
import { InputTextarea } from 'primereact/inputtextarea';
import SecuredFileChange from '../../components/SecuredFileChange';

function EditSegments() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [businessTypeData, setBusinessTypeData] = useState([]);
    const [reasonData, setReasonData] = useState([]);
    const [kamData, setKamData] = useState([]);
    const proofOfProfessionRef = useRef(null);
    const [proofOfProfessionFileName, setProofOfProfessionFileName] = useState('');
    const [proofOfprofessionBase64, setProofOfprofessionBase64] = useState('');
    const [discrepantData, setDiscrepantData] = useState([]);
    const [fileSizeError, setFileSizeError] = useState('');
    const [fileLink, setFileLink] = useState('');

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const segmentId = query.get('segmentId');

    const getDatabyId = async () => {
        const resp = await handleGetRequest(`/account/v1/segment/getSegmentById/${segmentId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    const value = keyData[key] === null ? '' : keyData[key];
                    formik?.setFieldValue(key, value);
                }
            });
            setProofOfprofessionBase64(keyData?.base64content);
            setProofOfProfessionFileName(resp?.payLoad?.uploadAgreement?.slice(34));

            setFileLink(`${baseURL}/document/${resp?.payLoad?.uploadAgreement?.split('/documents/')[1]}`);
            formik.setFieldValue('uploadAgreement', '');
            if (keyData?.lkpBusinessType !== null) {
                formik.setFieldValue('businessTypeId', keyData?.lkpBusinessType?.businessTypeId?.toString());
            }
            if (keyData?.tblSalesRoleDetail !== null) {
                formik.setFieldValue('salesRoleDetailId', keyData?.tblSalesRoleDetail?.salesRoleDetailId?.toString());
            }
            if (keyData?.lkpRegion !== null) {
                formik.setFieldValue('regionId', keyData?.lkpRegion?.regionId?.toString());
            }

            formik.setFieldValue('salaryStatus', keyData?.salaryStatus === 'Y' ? true : false);
            formik.setFieldValue('segmentName', keyData?.segmentName?.split('-')[0]);
        }
    };

    useEffect(() => {
        getDatabyId();
    }, []); // eslint-disable-line

    const getBusinessTypeData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getbusinesstypes/LKP_BUSINESS_TYPE:*');
        setBusinessTypeData(res?.payLoad);
    };

    const getReasonData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getallregions/LKP_REGION:*');
        setReasonData(resp?.payLoad);
    };

    useEffect(() => {
        getBusinessTypeData();
        getReasonData();
    }, []);

    // const splitCnicFrontFileName = discrepantData?.cnicFrontPic ? discrepantData?.cnicFrontPic.split('/').pop() : '';
    const splitProofOfProfessionFileName = discrepantData?.proofOfProfessionPic ? discrepantData?.proofOfProfessionPic.split('/').pop() : '';
    const handleProofOfProfessionClick = () => {
        proofOfProfessionRef.current.click();
    };

    const handleProofOfProfessionChange = (event) => {
        setFileSizeError('');
        setProofOfProfessionFileName('');
        const selectedFile = event.target.files[0];
        const fileTypes = ['pdf'];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setFileSizeError(error);
        } else if (newFile) {
            setFileLink(URL.createObjectURL(selectedFile));
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                setProofOfProfessionFileName(selectedFile.name); // Update the selected file name
                setProofOfprofessionBase64(base64Data?.split(',')[1]); // Update the base64 data
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const validationSchema = Yup.object().shape({
        clientEmail: Yup.string()
            .matches(/^[A-Za-z0-9._%+-]+@([A-Za-z0-9.-]+\.)+[A-Za-z]{2,4}$/, 'Please enter valid email')
            .nullable(),
        //         uploadAgreement: Yup.mixed().test('fileSize', 'File size should not be more than 10MB', (value) => {
        //     if (!value) return true;
        //     return value.size <= 10 * 1024 * 1024;
        //   }),
        segmentName: Yup.string().required('This field is required')
        //            segmentName:Yup.string().trim()
        //   .matches(/^[a-zA-Z0-9]+(\s[a-zA-Z0-9]+)*$/, 'Invalid input'),

        // clientPocName: Yup.string().matches(/^[a-zA-Z0-9\s]+$/, 'Only alphanumeric characters are allowed').nullable(),
    });

    function validateCompanyAddress(value) {
        const specialCharsRegex = /^[!@#$%^&*()\-_+=\[\]{}|\\:|;"'_<>,.?/~\s]*$/;

        if (value === null || value === undefined) {
            return value;
        }

        const specialCharsCount = (value.match(/[!@#$%^&*()\-+=\[\]{}|\\:;"'<>,.?/~]/g) || []).length;

        return !specialCharsRegex.test(value) && specialCharsCount <= 3;
    }

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            businessTypeId: '',
            segmentName: '',
            salesRoleDetailId: '',
            regionId: '',
            agreementSigningDate: '',
            uploadAgreement: '',
            clientPocName: '',
            clientMobileNo: '',
            clientEmail: '',
            companyAddress: '',
            segmentDescr: '',
            salaryStatus: false
        },
        validate: (data) => {
            let errors = {};

            if (data?.clientEmail) {
                if (!data?.clientEmail.includes('.com')) {
                    errors.clientEmail = 'Invalid Email';
                }
            }

            if (data?.companyAddress) {
                if (!validateCompanyAddress(data?.companyAddress)) {
                    errors.companyAddress = 'Company Address must contain at least one non-special character and at most 3 special characters';
                }
            }
            if (data?.segmentName) {
                if (!validateCompanyAddress(data?.segmentName)) {
                    errors.segmentName = 'Client profile name must contain at least one non-special character and at most 3 special characters';
                }
            }
            if (data?.clientPocName) {
                if (!validateCompanyAddress(data?.clientPocName)) {
                    errors.clientPocName = 'Client POC name must contain at least one non-special character and at most 3 special characters';
                }
            }
            if (data?.businessTypeId.includes('4')) {
                if (data?.salaryStatus === false) {
                    errors.salaryStatus = 'This field is required';
                }
                if (data?.segmentDescr === '' || data?.segmentDescr === null) {
                    errors.segmentDescr = 'This field is required';
                }
                if (data?.segmentName === '' || data?.segmentName === null) {
                    errors.segmentName = 'This field is required';
                }
                if (data?.salesRoleDetailId === '' || data?.salesRoleDetailId === null) {
                    errors.salesRoleDetailId = 'This field is required';
                }
                if (data?.regionId === '' || data?.regionId === null) {
                    errors.regionId = 'This field is required';
                }
                if (data?.agreementSigningDate === '' || data?.agreementSigningDate === null) {
                    errors.agreementSigningDate = 'This field is required';
                }
                if (!proofOfprofessionBase64) {
                    errors.uploadAgreement = 'This field is required';
                }
            }
            if (data?.businessTypeId === '6') {
                if (data?.segmentDescr === '' || data?.segmentDescr === null) {
                    errors.segmentDescr = 'This field is required';
                }
                if (data?.segmentName === '' || data?.segmentName === null) {
                    errors.segmentName = 'This field is required';
                }

                if (data?.regionId === '' || data?.regionId === null) {
                    errors.regionId = 'This field is required';
                }
            }

            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            const businessTypeObj = businessTypeData?.find((item) => item.lovId === data.businessTypeId);

            data['segmentName'] = `${formik?.values?.segmentName}-${businessTypeObj?.name}`;

            data['segmentId'] = segmentId;
            data['salaryStatus'] = formik.values.salaryStatus === true ? 'Y' : 'N';
            data['uploadAgreement'] = proofOfprofessionBase64;
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(newData, '/account/v1/segments/modifysegment', true, false, 'segment'));
            if (res?.responseCode === '010000') {
                //     formik.resetForm();
            } else {
                const originalSegmentName = data['segmentName']?.split('-')[0];
                formik.setFieldValue('segmentName', originalSegmentName);
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const getKamData = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getkamByBusinessType/${formik.values.businessTypeId}`);
        setKamData(resp?.payLoad);
    };

    useEffect(() => {
        if (formik.values.businessTypeId !== '' || formik.values.businessType !== undefined) {
            getKamData();
        }
    }, [formik.values.businessTypeId]); // eslint-disable-line

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>EDIT DETAILS</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="businessTypeId" className={classNames({ 'p-error': isFormFieldValid('businessTypeId') }, 'Label__Text')}>
                                    Business Type
                                </label>
                                <Dropdown
                                    id="businessTypeId"
                                    placeholder="Select Business Type"
                                    options={businessTypeData}
                                    optionLabel="name"
                                    name="businessTypeId"
                                    optionValue="lovId"
                                    value={formik.values.businessTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('businessTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('businessTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="segmentName" className={classNames({ 'p-error': isFormFieldValid('segmentName') }, 'Label__Text')}>
                                    Client Profile Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="segmentName"
                                    placeholder="Enter profile name"
                                    name="segmentName"
                                    value={formik?.values?.segmentName?.replace(/\s\s+/g, ' ').replace(/-.*/, '') || ''}
                                    // value={formik?.values?.segmentName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('segmentName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('segmentName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="salesRoleDetailId" className={classNames({ 'p-error': isFormFieldValid('salesRoleDetailId') }, 'Label__Text')}>
                                    KAM {formik.values.businessTypeId === '4' ? <span className="Label__Required">*</span> : null}
                                </label>
                                <Dropdown
                                    id="salesRoleDetailId"
                                    placeholder="Select Kam"
                                    options={kamData}
                                    optionLabel="name"
                                    name="salesRoleDetailId"
                                    optionValue="lovId"
                                    value={formik.values.salesRoleDetailId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('salesRoleDetailId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('salesRoleDetailId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="regionId" className={classNames({ 'p-error': isFormFieldValid('regionId') }, 'Label__Text')}>
                                    Region {formik.values.businessTypeId === '4' ? <span className="Label__Required">*</span> : formik.values.businessTypeId === '6' ? <span className="Label__Required">*</span> : null}
                                </label>
                                <Dropdown
                                    id="regionId"
                                    placeholder="Select Region"
                                    options={reasonData}
                                    optionLabel="name"
                                    name="regionId"
                                    optionValue="lovId"
                                    value={formik.values.regionId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regionId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('regionId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="agreementSigningDate" className={classNames({ 'p-error': isFormFieldValid('agreementSigningDate') }, 'Label__Text')}>
                                    Agreement Signing date {formik.values.businessTypeId === '4' ? <span className="Label__Required">*</span> : null}
                                </label>
                                <InputText
                                    max={previousDate}
                                    id="agreementSigningDate"
                                    placeholder="Enter date"
                                    name="agreementSigningDate"
                                    type="date"
                                    value={formik?.values?.agreementSigningDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('agreementSigningDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('agreementSigningDate')}
                            </div>
                        </div>
                        {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="uploadAgreement" className={classNames({ 'p-error': isFormFieldValid('uploadAgreement') }, 'Label__Text')}>
                                    Upload Agreement {
                                        formik.values.businessTypeId==='4'? <span className="Label__Required">*</span> :null
                                    }
                                </label>
                                <InputText
                                    id="uploadAgreement"
                                    placeholder="Please Upload Agreement"
                                    name="uploadAgreement"
                                    type="file"
                                    value={formik?.values?.uploadAgreement}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('uploadAgreement') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('uploadAgreement')}
                            </div>
                        </div> */}
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Upload Agreement {formik.values.businessTypeId === '4' ? <span className="Label__Required">*</span> : null}</label>
                                <div className="file-input-container w-100" onClick={handleProofOfProfessionClick}>
                                    <input type="file" style={{ display: 'none' }} ref={proofOfProfessionRef} onChange={handleProofOfProfessionChange} accept=".pdf" />
                                    <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={proofOfProfessionFileName ? proofOfProfessionFileName : splitProofOfProfessionFileName} readOnly />
                                    <span className="file-upload-icon">
                                        <i className="pi pi-paperclip" />
                                    </span>
                                </div>
                                {/* {getFormErrorMessage('uploadAgreement')} */}
                                {fileSizeError ? <small className="p-error">{fileSizeError}</small> : getFormErrorMessage('uploadAgreement')}
                            </div>
                            {proofOfProfessionFileName && (
                                <div className="Down__Btn mt-3">
                                    <Button
                                        type="button"
                                        label="View Document"
                                        className="Btn__Dark"
                                        onClick={() => {
                                            window.open(fileLink, '_blank');
                                        }}
                                        disabled={!proofOfProfessionFileName}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="clientPocName" className={classNames({ 'p-error': isFormFieldValid('clientPocName') }, 'Label__Text')}>
                                    Client POC Name
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="clientPocName"
                                    placeholder="Enter Poc Name"
                                    name="clientPocName"
                                    value={formik?.values?.clientPocName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('clientPocName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('clientPocName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="clientMobileNo" className={classNames({ 'p-error': isFormFieldValid('clientMobileNo') }, 'Label__Text')}>
                                    Client Mobile Number
                                </label>
                                <InputText
                                    maxLength={11}
                                    id="clientMobileNo"
                                    placeholder="Enter Mobile No"
                                    name="clientMobileNo"
                                    value={formik?.values?.clientMobileNo?.replace(/\D/g, '') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('clientMobileNo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('clientMobileNo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="clientEmail" className={classNames({ 'p-error': isFormFieldValid('clientEmail') }, 'Label__Text')}>
                                    Client Email
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="clientEmail"
                                    placeholder="Enter client clientEmail"
                                    name="clientEmail"
                                    type="email"
                                    value={formik?.values?.clientEmail?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('clientEmail') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('clientEmail')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="companyAddress" className={classNames({ 'p-error': isFormFieldValid('companyAddress') }, 'Label__Text')}>
                                    Company Address
                                </label>
                                <InputTextarea
                                    maxLength={500}
                                    id="companyAddress"
                                    placeholder="Enter company address"
                                    name="companyAddress"
                                    value={formik?.values?.companyAddress?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('companyAddress') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('companyAddress')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="salaryStatus" className={classNames({ 'p-error': isFormFieldValid('salaryStatus') }, 'Label__Text')}>
                                    Salary Status {formik.values.businessTypeId === '4' ? <span className="Label__Required">*</span> : null}
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="salaryStatus" name="salaryStatus" value={formik?.values?.salaryStatus} checked={formik?.values?.salaryStatus} onChange={formik.handleChange} style={{ marginTop: '10px' }} />
                                    <label htmlFor="salaryStatus" className={classNames({ 'p-error': isFormFieldValid('salaryStatus') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        active
                                    </label>
                                </div>
                                {getFormErrorMessage('salaryStatus')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="segmentDescr" className={classNames({ 'p-error': isFormFieldValid('segmentDescr') }, 'Label__Text')}>
                                    Description {formik.values.businessTypeId === '6' ? <span className="Label__Required">*</span> : formik.values.businessTypeId === '4' ? <span className="Label__Required">*</span> : null}
                                </label>
                                <InputText
                                    maxLength={500}
                                    id="segmentDescr"
                                    placeholder="Enter Description"
                                    name="segmentDescr"
                                    value={formik?.values?.segmentDescr?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('segmentDescr') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('segmentDescr')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} label="Update" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/segment')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default EditSegments;

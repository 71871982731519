import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { useRef } from 'react';
import SecuredFileChange from '../../../../components/SecuredFileChange';
import ColorPicker from 'react-pick-color';

function AddSecondSubCategory() {
    const [mainData, setMainData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [subData, setSubData] = useState([]);
    const [categoryIconError, setCategoryIconError] = useState('');
    const [showColorPicker, setShowColorPicker] = useState({ textColor: false, bgColor: false });

    const secondSubCategoryRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        cmsSecondSubCategoryName: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .required('This field is required'),
       
        displayNameUrdu: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/, 'Only alphanumeric characters are allowed')
            .required('This field is required'),

        secondSubCategoryNumber: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[0-9]*$/, 'Only Numbers are allowed')
            .required('This field is required'),

        cmsSubCategoryId: Yup.string().required('This field is required'),
        secondSubCategoryIcon: Yup.string().required('This field is required'),
        secondSubCategorySeq: Yup.string().required('This field is required'),

        cmsMainCategoryId: Yup.string().required('This field is required'),
        cmsTagLabel: Yup.string().when('addCheck', {
            is: true,
            then: Yup.string().required('This field is required')
        }),
        cmsTagTextColor: Yup.string().when('addCheck', {
            is: true,
            then: Yup.string().required('This field is required')
        }),
        cmsTagTextBgColor: Yup.string().when('addCheck', {
            is: true,
            then: Yup.string().required('This field is required')
        }),
        tagExpiryDate: Yup.string().when('addCheck', {
            is: true,
            then: Yup.string().required('This field is required')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cmsSecondSubCategoryName: '',
            displayNameEnglist: '',
            displayNameUrdu: '',
            cmsMainCategoryId: '',
            cmsSubCategoryId: '',
            secondSubCategoryNumber: '',
            secondSubCategoryIcon: '',
            secondSubCategorySeq: '',
            addCheck: false,
            cmsTagLabel: '',
            cmsTagTextColor: '',
            tagExpiryDate:'',
            cmsTagTextBgColor: '',
            isHide: 'Y',
            copyEngToUrdu: false
        },

        onSubmit: async (data) => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        cmsSecondSubCategoryName: data?.cmsSecondSubCategoryName,
                        // displayNameEnglist: data?.displayNameEnglist,
                        displayNameUrdu: data?.displayNameUrdu,
                        cmsMainCategoryId: data?.cmsMainCategoryId,
                        cmsSubCategoryId: data?.cmsSubCategoryId,
                        secondSubCategoryNumber: data?.secondSubCategoryNumber,
                        secondSubCategorySeq: data?.secondSubCategorySeq,
                        cmsTagLabel: data?.addCheck ? data?.cmsTagLabel : '',
                        cmsTagTextColor: data?.addCheck ? data?.cmsTagTextColor : '',
                        tagExpiryDate: data?.tagExpiryDate,

                        cmsTagTextBgColor: data?.addCheck ? data?.cmsTagTextBgColor : '',
                        isHide: data?.isHide
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const formData = new FormData();
            formData.append('request', JSON.stringify(newData));
            if (selectedFile) {
                formData.append('file', selectedFile);
            }
            await dispatch(handlePostRequest(formData, '/cms/v1/cms/savesecondsubcategory', true, false, 'secondsubcategory'));
        }
    });

    const getCategoryDataLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcategorydatalov');
        setMainData(res?.payLoad);
    };

    const getSubCategoryDataLov = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getsubcategorydatalov/${formik?.values?.cmsMainCategoryId}`);
        setSubData(res?.payLoad);
    };

    useEffect(() => {
        getCategoryDataLov();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (formik?.values?.cmsMainCategoryId != '') {
            getSubCategoryDataLov();
        }
    }, [formik.values?.cmsMainCategoryId]); // eslint-disable-line


    const handledSecondSubCategoryIcon = () => {
        secondSubCategoryRef.current.click();
    };

    const handleSecondSubCategoryChange = (event) => {
        setCategoryIconError('');
        formik.setFieldValue('secondSubCategoryIcon', '');
        setSelectedFile(null);
        const selectedFile = event.target.files[0];
        const fileTypes = ['image'];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setCategoryIconError(error);
            setSelectedFile(null);
        } else if (newFile) {
            setSelectedFile(selectedFile);
            formik.setFieldValue('secondSubCategoryIcon', selectedFile.name);

            // setSecondSubCategoryIconFileName(selectedFile?.name);
        }
    };
    const getContrastColor = (hexColor) => {
        const r = parseInt(hexColor.substr(1, 2), 16);
        const g = parseInt(hexColor.substr(3, 2), 16);
        const b = parseInt(hexColor.substr(5, 2), 16);
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    };
    const handleAddTagChange = (e) => {
        formik.handleChange(e);
        if (!e.target.checked) {
            formik.setFieldValue('cmsTagLabel', '');
            formik.setFieldValue('cmsTagTextColor', '#FFFFFF');
            formik.setFieldValue('cmsTagTextBgColor', '#FFFFFF');
        }
    };
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>ADD NEW</h2>
                        </div>
                      
                       
                      
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field" >
                              
                            <label htmlFor="cmsSecondSubCategoryName" className={classNames({ 'p-error': isFormFieldValid('cmsSecondSubCategoryName') }, 'Label__Text')}>
                                    2nd Sub Category Name <span className="Label__Required">*</span>
                                  
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="cmsSecondSubCategoryName"
                                    placeholder="Enter 2nd Sub Category Name"
                                    name="cmsSecondSubCategoryName"
                                    value={formik?.values?.cmsSecondSubCategoryName || ''}
                                
                                      onChange={(e) => {
                                        formik.handleChange(e);
                                        if (formik.values.copyEngToUrdu) {
                                            formik.setFieldValue('displayNameUrdu', e.target.value);
                                        }
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsSecondSubCategoryName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('cmsSecondSubCategoryName')}
                            </div>
                        </div>
                     

                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                            <div className="p-field-checkbox flex justify-content-between">
                                   
                                   <label htmlFor="cmsSecondSubCategoryName" className={classNames({ 'p-error': isFormFieldValid('cmsSecondSubCategoryName') }, 'Label__Text')}>
                                   Display Name (Urdu)<span className="Label__Required">*</span>
                                     
                                   </label>
                                 <div className='flex align-items-center ml-auto'>
                                   <label htmlFor="cmsSecondSubCategoryName" className={classNames({ 'p-error': isFormFieldValid('cmsSecondSubCategoryName') }, 'Label__Text')}>
                                      Same AS (English)
                                     
                                   </label>
                                   </div>
                                   <Checkbox
                                       inputId="copyEngToUrdu"
                                       name="copyEngToUrdu"
                                       checked={formik.values.copyEngToUrdu}
                                       onChange={(e) => {
                                           formik.setFieldValue('copyEngToUrdu', e.checked);
                                           if (e.checked) {
                                               formik.setFieldValue('displayNameUrdu', formik.values.cmsSecondSubCategoryName);
                                           }
                                       }}
                                   />
                                 
                               </div>
                                <InputText
                                    maxLength={100}
                                    id="displayNameUrdu"
                                    placeholder="Enter Display Name In Urdu"
                                    name="displayNameUrdu"
                                    value={formik?.values?.displayNameUrdu || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('displayNameUrdu') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('displayNameUrdu')}
                            </div>
                        </div>
                       

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsMainCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsMainCategoryId') }, 'Label__Text')}>
                                    Main Category<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="cmsMainCategoryId"
                                    placeholder="Select Main Category"
                                    options={mainData}
                                    optionLabel="name"
                                    name="cmsMainCategoryId"
                                    optionValue="lovId"
                                    value={formik.values.cmsMainCategoryId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsMainCategoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsMainCategoryId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsSubCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsSubCategoryId') }, 'Label__Text')}>
                                    Sub Category<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="cmsSubCategoryId"
                                    placeholder="Select Sub Category"
                                    options={subData}
                                    optionLabel="name"
                                    name="cmsSubCategoryId"
                                    optionValue="lovId"
                                    value={formik.values.cmsSubCategoryId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsSubCategoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsSubCategoryId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="secondSubCategoryNumber" className={classNames({ 'p-error': isFormFieldValid('secondSubCategoryNumber') }, 'Label__Text')}>
                                    2nd Sub Category Number<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="secondSubCategoryNumber"
                                    placeholder="Enter 2nd Sub Category Number"
                                    name="secondSubCategoryNumber"
                                    keyfilter={'int'}
                                    value={formik?.values?.secondSubCategoryNumber || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('secondSubCategoryNumber') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('secondSubCategoryNumber')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <div className="mb-2">
                                    <label className={classNames({ 'p-error': isFormFieldValid('secondSubCategoryIcon') }, 'Label__Text')}>
                                        2nd Sub Category Icon
                                        <span className="Label__Required">*</span>
                                    </label>
                                    <div className="file-input-container w-100" onClick={handledSecondSubCategoryIcon}>
                                        <input type="file" accept="image/*" style={{ display: 'none' }} ref={secondSubCategoryRef} onChange={handleSecondSubCategoryChange} />
                                        <input
                                            type="text"
                                            className={classNames({ 'p-inputtext p-component p-invalid': isFormFieldValid('secondSubCategoryIcon') }, 'file-input-field Input__Round')}
                                            placeholder="Choose a file..."
                                            value={formik?.values?.secondSubCategoryIcon}
                                            readOnly
                                        />
                                        <span className="file-upload-icon">
                                            <i className="pi pi-paperclip" />
                                        </span>
                                    </div>
                                    {categoryIconError ? <small className="p-error">{categoryIconError}</small> : getFormErrorMessage('secondSubCategoryIcon')}
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="secondSubCategorySeq" className={classNames({ 'p-error': isFormFieldValid('secondSubCategorySeq') }, 'Label__Text')}>
                                  Priority <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="secondSubCategorySeq"
                                    keyfilter="int"
                                    placeholder="Enter Priority"
                                    name="secondSubCategorySeq"
                                    value={formik?.values?.secondSubCategorySeq || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('secondSubCategorySeq') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('secondSubCategorySeq')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-1 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="addCheck" className={classNames({ 'p-error': isFormFieldValid('addCheck') }, 'Label__Text')}>
                                    Add Tag
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="addCheck" name="addCheck" checked={formik.values.addCheck} onChange={handleAddTagChange} style={{ marginTop: '10px' }} />
                                </div>
                                {getFormErrorMessage('addCheck')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsTagLabel" className={classNames({ 'p-error': isFormFieldValid('cmsTagLabel') }, 'Label__Text')}>
                                    Tag Label
                                </label>

                                <InputText
                                    disabled={!formik?.values?.addCheck}
                                    maxLength={100}
                                    id="cmsTagLabel"
                                    placeholder="Enter Tag Label"
                                    name="cmsTagLabel"
                                    value={formik?.values?.cmsTagLabel || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagLabel') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('cmsTagLabel')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field relative">
                                <label htmlFor="cmsTagTextColor" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextColor') }, 'Label__Text')}>
                                    Tag Text Color
                                </label>
                                <InputText
                                    value={formik?.values?.cmsTagTextColor || '#FFFFFF'}
                                    onChange={(e) => {
                                         formik.setFieldValue('cmsTagTextColor', e.target.value);
                                    }}
                                    onClick={() => setShowColorPicker({ textColor: true, bgColor: false })}
                                    disabled={!formik?.values?.addCheck}
                                    readOnly
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextColor') }, 'Input__Round')}
                                    style={{
                                        backgroundColor: formik?.values?.cmsTagTextColor || '#FFFFFF',
                                        color: getContrastColor(formik?.values?.cmsTagTextColor || '#FFFFFF')
                                    }}
                                />
                                {getFormErrorMessage('cmsTagTextColor')}
                                {showColorPicker?.textColor && (
                                    <div className="colorPickerForInput">
                                        <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                        <ColorPicker color={formik?.values?.cmsTagTextColor || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagTextColor', e?.hex)} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsTagTextBgColor" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextBgColor') }, 'Label__Text')}>
                                    Tag Background Color
                                </label>
                                <InputText
                                    value={formik?.values?.cmsTagTextBgColor || '#FFFFFF'}
                                    onChange={(e) => {
                                        formik.setFieldValue('cmsTagTextBgColor', e.target.value);
                                    }}
                                    onClick={() => setShowColorPicker({ textColor: false, bgColor: true })}
                                    disabled={!formik?.values?.addCheck}
                                    readOnly
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextBgColor') }, 'Input__Round')}
                                    style={{
                                        backgroundColor: formik?.values?.cmsTagTextBgColor || '#FFFFFF',
                                        color: getContrastColor(formik?.values?.cmsTagTextBgColor || '#FFFFFF')
                                    }}
                                />
                                {getFormErrorMessage('cmsTagTextBgColor')}
                                {showColorPicker?.bgColor && (
                                    <div className="colorPickerForInput">
                                        <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                        <ColorPicker color={formik?.values?.cmsTagTextBgColor || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagTextBgColor', e?.hex)} />
                                    </div>
                                )}
                            </div>
                            
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="tagExpiryDate" className={classNames({ 'p-error': isFormFieldValid('tagExpiryDate') }, 'Label__Text')}>
                                    Tag Expiry Date
                                </label>
                                <InputText
                                    type="date"
                                    id="tagExpiryDate"
                                    min={previousDate}
                                    name="tagExpiryDate"
                                    value={formik?.values?.tagExpiryDate || ''}
                                    onChange={formik.handleChange}
                                    disabled={!formik?.values?.addCheck}
                                    className={classNames({ 'p-invalid': isFormFieldValid('tagExpiryDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('tagExpiryDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-2 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isHide" className={classNames({ 'p-error': isFormFieldValid('isHide') }, 'Label__Text')}>
                                    Status
                                </label>
                                <div className="flex ml-2">
                                    <div className="mt-1 mr-5">
                                        <Checkbox inputId="show" name="isHide" value="Y" checked={formik?.values?.isHide === 'Y'} onChange={(e) => formik.setFieldValue('isHide', 'Y')} style={{ marginTop: '10px' }} />
                                        <label htmlFor="show" className={classNames({ 'p-error': isFormFieldValid('show') }, 'Label__Text')}>
                                            Show
                                        </label>
                                    </div>
                                    <div className="mt-1">
                                        <Checkbox inputId="hide" name="isHide" value="N" checked={formik?.values?.isHide === 'N'} onChange={(e) => formik.setFieldValue('isHide', 'N')} style={{ marginTop: '10px' }} />
                                        <label htmlFor="hide" className={classNames({ 'p-error': isFormFieldValid('hide') }, 'Label__Text')}>
                                            Hide
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="submit" label="Submit" className="Btn__Dark" />
                        <Button onClick={() => navigate('/secondsubcategory')} type="button" label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddSecondSubCategory;

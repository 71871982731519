import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useDispatch } from 'react-redux';

function AddNewChannel() {
    const [loading, setloading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //formik
    const formik = useFormik({
        initialValues: {
            channelName: '',
            channelDescr: ''
        },
        // validateOnChange: true,
        validate: (data) => {
            let errors = {};

            //channel Name validation
            if (!data?.channelName) {
                errors.channelName = 'This Field is required';
            } else if (data?.channelName === '') {
                errors.channelName = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.channelName)) {
                errors.channelName = 'Leading and trailing space is not allowed';
            } else if (!/^[a-zA-Z](?:[a-zA-Z0-9\s]*-?[a-zA-Z0-9\s]*)?$/.test(data?.channelName)) {
                errors.channelName = 'Invalid Channel Name format';
            }

            //Channel Description validation
            if (!data?.channelDescr) {
                errors.channelDescr = 'This Field is required';
            } else if (data?.channelDescr === '') {
                errors.channelDescr = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.channelDescr)) {
                errors.channelDescr = 'Leading and trailing space is not allowed';
            }
            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            //API call to create new channel
            const res = await dispatch(handlePostRequest(newData, '/productcatalog/v1/channels/createchannel', true, false, 'channel'));

            setloading(false);
        }
    });

    // Function to validate a specific field on change
    const validateFieldOnChange = (name, value) => {
        formik.setFieldTouched(name);
        formik.setFieldValue(name, value);
        formik.validateField(name);
    };

    //Functions to handle validations error

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>ADD NEW</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="channelName" className="Label__Text">
                                    Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="channelName"
                                    name="channelName"
                                    value={formik.values.channelName}
                                    onChange={(e) => {
                                        validateFieldOnChange('channelName', e.target.value);
                                    }}
                                    className="Input__Round"
                                    maxLength={50}
                                />
                                {getFormErrorMessage('channelName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="channelDescr" className="Label__Text">
                                    Description<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="channelDescr"
                                    name="channelDescr"
                                    value={formik.values.channelDescr}
                                    onChange={(e) => {
                                        validateFieldOnChange('channelDescr', e.target.value);
                                    }}
                                    className="Input__Round"
                                    maxLength={200}
                                />
                                {getFormErrorMessage('channelDescr')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Submit" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/channel')} type="button" label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddNewChannel;

import * as Yup from 'yup';

const SlabWiseHybirdInitialValues = {
    accountClassificationId: '',
    commissionProfileName: '',
    feeTypeCode: 'CH',
    whtCalculationType: '',
    commissionGlAccountId: '',
    whtGlAccountId: '',
    disbursementMethodName: '',
    disbursementTime: null,
    startDate: '',
    endDate: '',
    commissionSlabRequests: [
        {
            startSlab: '',
            endSlab: '',
            commissionAmount: null,
            commissionPercentage: null,
            commissionTypeCode: ''
        }
    ],
    products: []
};

const validationSchema = Yup.object().shape({
    accountClassificationId: Yup.mixed().required('This field is required'),
    commissionProfileName: Yup.string()
        .required('This field is required')
        .matches(/^[a-zA-Z0-9\s]*$/, 'Only alpha numeric characters are allowed')
        .max(100, 'Maximum 100 digits allowed'),
    whtCalculationType: Yup.mixed().required('This field is required'),
    commissionGlAccountId: Yup.mixed().required('This field is required'),
    whtGlAccountId: Yup.mixed().required('This field is required'),
    disbursementMethodName: Yup.mixed().required('This field is required'),
    startDate: Yup.date().required('This field is required').nullable(),
    endDate: Yup.date().when('startDate', {
        is: (startDate) => !!startDate,
        then: Yup.date().required('End Date is required').min(Yup.ref('startDate'), 'End Date has to be more than Start Date')
    }),
    commissionSlabRequests: Yup.array().of(
        Yup.object().shape({
            startSlab: Yup.number()
                .required('This field is required')
                .test('Is positive?', 'The number must be greater than 0', (value) => value > 0)
                .nullable(),
            endSlab: Yup.number()
                .transform((value, originalValue) => {
                    if (isNaN(originalValue)) {
                        return null;
                    }
                    return value;
                })
                .required('This field is required')
                .test('Is positive?', 'The number must be greater than 0', (value) => value > 0)
                .when('startSlab', {
                    is: (startSlab) => !!startSlab,
                    then: Yup.number().min(Yup.ref('startSlab'), 'End Slab has to be more than Start Slab').nullable()
                })
                .nullable(),
            commissionTypeCode: Yup.mixed().required('This field is required').nullable(),
            commissionAmount: Yup.number()
                .when('commissionTypeCode', {
                    is: (commissionTypeCode) => commissionTypeCode === 'FF' && commissionTypeCode !== 'PF',
                    then: Yup.number().required('This field is required').nullable()
                })
                .nullable(),
            commissionPercentage: Yup.number()
                .when('commissionTypeCode', {
                    is: (commissionTypeCode) => commissionTypeCode === 'PF' && commissionTypeCode !== 'FF',
                    then: Yup.number()
                        .required('This field is required')
                        .test('valid-commission', 'Commission must be between 0 and 100', function (value) {
                            return value >= 0 && value <= 100;
                        })
                        .nullable()
                })
                .nullable()

            // commissionAmount: Yup.number().when('commissionTypeCode', {
            //     is: (value) => value === 'FF',
            //     then: Yup.number()
            //         .required('This field is required.')
            //         .test('is-positive', 'The number must be greater than 0', (value) => value > 0)
            //         .test('less-than-or-equal-to-100', 'Value must be less than or equal to 100', (value) => {
            //             if (!value) {
            //                 return false;
            //             }
            //             const numericValue = parseFloat(value);
            //             return numericValue <= 100;
            //         })
            //         .nullable()
            // }),
            // commissionPercentage: Yup.number().when('commissionTypeCode', {
            //     is: (value) => value === 'PF',
            //     then: Yup.number()
            //         .required('This field is required.')
            //         .test('is-positive', 'The number must be greater than 0', (value) => value > 0)
            //         .test('less-than-or-equal-to-100', 'Value must be less than or equal to 100', (value) => {
            //             if (!value) {
            //                 return false;
            //             }
            //             const numericValue = parseFloat(value);
            //             return numericValue <= 100;
            //         })
            //         .nullable()
            // })
        })
    )
});

export { SlabWiseHybirdInitialValues, validationSchema };

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { classNames } from 'primereact/utils';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useDispatch } from 'react-redux';

function AddNewProductCatalog() {
    const [loading, setloading] = useState(false);
    const [listOfTransaction, setListOfTransaction] = useState([]);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    //list of transactions API call
    const getTransactionListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setListOfTransaction(res?.payLoad);
    };

    useEffect(() => {
        getTransactionListData();
    }, []);

    const formik = useFormik({
        initialValues: {
            productCatalogName: '',
            productCatalogDescription: '',
            transactionList: []
        },
        validate: (data) => {
            let errors = {};
            ////////////////////////productCatalogName/////////////////////

            if (!data?.productCatalogName) {
                errors.productCatalogName = 'This Field is required';
            } else if (data?.productCatalogName === '') {
                errors.productCatalogName = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.productCatalogName)) {
                errors.productCatalogName = 'Leading and trailing space is not allowed';
            } else if (!/^[a-zA-Z](?:[a-zA-Z0-9\s]*-?[a-zA-Z0-9\s]*)?$/.test(data?.productCatalogName)) {
                errors.productCatalogName = 'Invalid Channel Name format';
            }

            ////////////////////////productCatalogDescription/////////////////////

            if (!data?.productCatalogDescription) {
                errors.productCatalogDescription = 'This Field is required';
            } else if (data?.productCatalogDescription === '') {
                errors.productCatalogDescription = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.productCatalogDescription)) {
                errors.productCatalogDescription = 'Leading and trailing space is not allowed';
            }

            ////////////////////////transactionList/////////////////////

            if (data?.transactionList.length === 0) {
                errors.transactionList = 'This Dropdown should not be empty';
            }
            return errors;
        },
        onSubmit: async (data, actions) => {
            setloading(true);
            const arrayOfTransactionIds = formik.values.transactionList.map((item) => item?.lovId);
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        productCatalogName: data.productCatalogName,
                        productCatalogDescription: data.productCatalogDescription,
                        transactionList: arrayOfTransactionIds
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            //add new product catalog API call
            const res = await dispatch(handlePostRequest(newData, '/productcatalog/v1/products/createproduct', true, false, "productcatalog"));
            // if (res.responseCode === '120000') {
            //     actions.resetForm();
            // } else {
            // }
        }
    });

    const panelFooterTemplate = () => {
        const length = formik.values.transactionList ? formik.values.transactionList.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    // Function to validate a specific field on change
    const validateFieldOnChange = (name, value) => {
        formik.setFieldTouched(name);
        formik.setFieldValue(name, value);
        formik.validateField(name);
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>ADD NEW</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productCatalogName" className="Label__Text">
                                    Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="productCatalogName"
                                    name="productCatalogName"
                                    placeholder="Enter Name"
                                    value={formik.values.productCatalogName}
                                    onChange={(e) => {
                                        validateFieldOnChange('productCatalogName', e.target.value);
                                    }}
                                    className="Input__Round"
                                    maxLength={50}
                                />
                                {getFormErrorMessage('productCatalogName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productCatalogDescription" className="Label__Text">
                                    Description<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="productCatalogDescription"
                                    name="productCatalogDescription"
                                    value={formik.values.productCatalogDescription}
                                    onChange={(e) => {
                                        validateFieldOnChange('productCatalogDescription', e.target.value);
                                    }}
                                    placeholder="Enter Discription"
                                    className="Input__Round"
                                    maxLength={200}
                                />
                                {getFormErrorMessage('productCatalogDescription')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="transactionList" className="Label__Text">
                                    List of transactions<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="transactionList"
                                    placeholder="Select Transaction List"
                                    options={listOfTransaction}
                                    optionLabel="name"
                                    name="transactionList"
                                    optionValue="lovId"
                                    // display="chip"
                                    filter
                                    panelFooterTemplate={panelFooterTemplate}
                                    value={formik.values.transactionList.map((trans) => trans.lovId || '')}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            'transactionList',
                                            e.value.map((lovId) => ({
                                                lovId
                                            }))
                                        );
                                    }}
                                    className="Dropdown__Round"
                                />
                                {getFormErrorMessage('transactionList')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Submit" className="Btn__Dark" />
                        <Button onClick={() => navigate('/productcatalog')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddNewProductCatalog;

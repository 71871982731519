import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

const ViewNadra = () => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    const navigate = useNavigate();

    const location = useLocation();
    const { rowData } = location.state || {};
 
    const tableName = 'TBL_CMS_NADRA_CONFIG';
    const formik = useFormik({
        initialValues: {
            attempts: '',
            bvsLimit: '',
            idLimit: '',
            blockTime: '',
            attemptsTime: '',
            isTemporaryBlock: '',
            isPermanentBlock: ''
        }
    });

    const getRequestTypeData = async () => {
        setloading(true);
        const res = await handleGetRequest(`/cms/v1/cms/getnadraconfigbyid/${rowData?.cmsNadraConfigId}`, true);
    
        const keyData = res?.payLoad;
        formik.setFieldValue('attempts', keyData?.attempts);
        formik.setFieldValue('attemptsTime', keyData?.attemptsTime);
        formik.setFieldValue('isTemporaryBlock', keyData?.isTemporaryBlock);
        formik.setFieldValue('isPermanentBlock', keyData?.isPermanentBlock );
        formik.setFieldValue('bvsLimit', keyData?.bvsLimit);
        formik.setFieldValue('idLimit', keyData?.idLimit);
        formik.setFieldValue('blockTime', keyData?.blockTime);
        setloading(false);
    };

    useEffect(() => {
        getRequestTypeData();
    }, []);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>VIEW DETAILS</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="bvsLimit" className={classNames({ 'p-error': isFormFieldValid('bvsLimit') }, 'Label__Text')}>
                                    Bvs Limit
                                    <span className="Label__Required">*</span>
                                </label>

                                <InputText
                                    maxLength={100}
                                    id="bvsLimit"
                                    placeholder="Enter bvs Limit"
                                    name="bvsLimit"
                                    disabled
                                    value={formik?.values?.bvsLimit}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('bvsLimit') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('bvsLimit')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="idLimit" className={classNames({ 'p-error': isFormFieldValid('idLimit') }, 'Label__Text')}>
                                    ID Limit
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    type="text"
                                    id="email"
                                    disabled
                                    placeholder="Enter Id Limit"
                                    name="idLimit"
                                    value={formik?.values?.idLimit}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('idLimit') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('idLimit')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="attempts" className={classNames({ 'p-error': isFormFieldValid('attempts') }, 'Label__Text')}>
                                    Attempts
                                    <span className="Label__Required">*</span>
                                </label>

                                <InputText
                                    maxLength={100}
                                    type="text"
                                    disabled
                                    id="attempts"
                                    placeholder="Enter attempts"
                                    name="attempts"
                                    value={formik?.values?.attempts}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('attempts') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('attempts')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="attemptsTime" className={classNames({ 'p-error': isFormFieldValid('attemptsTime') }, 'Label__Text')}>
                                    Enter Time
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    disabled
                                    type="text"
                                    id="attemptsTime"
                                    placeholder="EEnter Time In Minutes"
                                    name="attemptsTime"
                                    value={formik?.values?.attemptsTime}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('attemptsTime') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('attemptsTime')}
                            </div>
                        </div>

                        <div className="Form__Header Full__Width">
                            <h1>Breach Limits</h1>
                        </div>
                       

                        <div className="p-field col-12 md:col-8 pt-3 pb-3 flex flex-row justify-content-space-between">
                        <div className="p-field col-12 md:col-4 pt-5 pb-3">
                                <div className="p-field">
                                    <div className="field-checkbox">
                                        <Checkbox
                                            inputId="isTemporaryBlock"
                                            name="isTemporaryBlock"
                                            checked={formik.values.isTemporaryBlock === 'Y'}
                                            onChange={(e) => {
                                                const newValue = e.checked ? 'Y' : 'N';
                                                formik.setFieldValue('isTemporaryBlock', newValue);
                                                if (e.checked) {
                                                    formik.setFieldValue('isPermanentBlock', 'N');
                                                }
                                            }}
                                            style={{ marginTop: '10px' }}
                                            disabled
                                        />
                                        <label htmlFor="isTemporaryBlock" className={classNames({ 'p-error': isFormFieldValid('isTemporaryBlock') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                            Temporary Block
                                        </label>
                                    </div>
                                    {getFormErrorMessage('isTemporaryBlock')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4">
                            <label htmlFor="blockTime" className={classNames({ 'p-error': isFormFieldValid('blockTime') }, 'Label__Text')}>
                                Time
                                <span className="Label__Required">*</span>
                            </label>
                            <InputText
                                maxLength={100}
                                type="text"
                                id="blockTime"
                                placeholder="Enter Time In Minutes"
                                name="blockTime"
                                disabled
                                value={formik?.values?.blockTime}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('blockTime') }, 'Input__Round')}
                            />
                            {getFormErrorMessage('blockTime')}
                        </div>
                      
                       
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>
                      
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <div className="field-checkbox">
                                        <Checkbox
                                            inputId="isPermanentBlock"
                                            name="isPermanentBlock"
                                            checked={formik.values.isPermanentBlock === 'Y'}
                                            onChange={(e) => {
                                                const newValue = e.checked ? 'Y' : 'N';
                                                formik.setFieldValue('isPermanentBlock', newValue);
                                                if (e.checked) {
                                                    formik.setFieldValue('isTemporaryBlock', 'N');
                                                }
                                            }}
                                            style={{ marginTop: '10px' }}
                                            disabled
                                        />
                                        <label htmlFor="isPermanentBlock" className={classNames({ 'p-error': isFormFieldValid('isPermanentBlock') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                            Permanent Block
                                        </label>
                                    </div>
                                    {getFormErrorMessage('isPermanentBlock')}
                                </div>
                            </div>
                    </div>

                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon || ''} onClick={() => navigate('/nadra')} type="submit" label="OK" className="Btn__Dark" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={rowData.cmsNadraConfigId} tableName={tableName} />

        </>
    );
};

export default ViewNadra;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { RadioButton } from 'primereact/radiobutton';
import { MultiSelect } from 'primereact/multiselect';
import { InputTextarea } from 'primereact/inputtextarea';
import * as Yup from 'yup';
import { Checkbox } from 'primereact/checkbox';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import { Sidebar } from 'primereact/sidebar';
import PricingSlabWiseFixedOldNewValues from './PricingSlabWiseFixedOldNewValues';

function EditPricingSlabWiseFixedChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [allLovs, setAllLovs] = useState([]);
    const [sharingAccount, setSharingAccount] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [requestedDataById, setRequestedDataByID] = useState();
    const [oldNewData, setOldNewData] = useState([]);
    const [highlightedKeys, setHighlightedKeys] = useState([]);
    const [pricingSlabWiseFixedData, setPricingSlabWiseFixedData] = useState();
    const [displayDialog, setDisplayDialog] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const tableName = query.get('tableName');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        getStatusLov();
    }, []); // eslint-disable-line

    const getPricingFixedbyId = async () => {
        const response = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getfeeprofilebyid/${refTableId}`);
        const keyData = response?.payLoad;

        const productsValues = keyData?.tblPricingProducts?.map((values) => {
            return values?.tblProduct?.productId?.toString();
        });

        const partnerData = keyData?.incomeSharingRequests?.map((value) => {
            return {
                pricingIncomeSharingId: value?.pricingIncomeSharingId,
                sharingType: value?.sharingType,
                incomeGlAccountId: value?.incomeGlAccountId?.toString(),
                sharingDetails: value?.sharingDetails,
                sharingPercentage: value?.sharingPercentage?.toString(),
                taxStatus: value?.taxStatus,
                whtGlAccountId: value?.whtGlAccountId?.toString()
            };
        });

        formik.setFieldValue('accountClassificationId', keyData?.lkpAccountClassification?.accountClassificationId?.toString());
        formik.setFieldValue('segmentId', keyData?.lkpSegment?.segmentId?.toString());
        formik.setFieldValue('pricingProfileName', keyData?.pricingProfileName);
        formik.setFieldValue('channelId', keyData?.lkpChannel?.channelId?.toString());
        formik.setFieldValue('chargingParty', keyData?.lkpPricingChargingParty?.pricingChargingPartyCode);
        formik.setFieldValue('startDate', keyData?.startDate?.slice(0, 10));
        formik.setFieldValue('endDate', keyData?.endDate?.slice(0, 10));
        formik.setFieldValue('feeCalculationType', keyData?.feeCalculationType);
        formik.setFieldValue('taxCalculationType', keyData?.taxCalculationType);
        formik.setFieldValue('incomeGlAccountId', keyData?.tblAccount1?.accountId?.toString());
        formik.setFieldValue('tblPricingVelocities', keyData?.tblPricingVelocities);
        formik.setFieldValue('tblPricingIncomeSharings', partnerData);
        formik.setFieldValue('noOfPartners', keyData?.noOfPartners);
        formik.setFieldValue('payeeGlAccountId', keyData?.tblAccount2?.accountId?.toString());
        // formik.setFieldValue('pricingIncomeSharingId', keyData?.incomeSharingRequests[0]?.pricingIncomeSharingId);
        formik.setFieldValue('tblPricingSlabs', keyData?.tblPricingSlabs);

        if (productsValues && productsValues.length > 0) {
            formik.setFieldValue('tblProduct', productsValues);
        }
        if (keyData?.velocity === 'N') {
            formik.setFieldValue('velocity', 'N');
        } else {
            formik.setFieldValue('velocity', 'Y');
        }
        if (keyData?.incomeSharing === 'N') {
            formik.setFieldValue('incomeSharing', 'N');
        } else {
            formik.setFieldValue('incomeSharing', 'Y');
        }
    };

    const getPricingFixedbyIdU = async () => {
        const response = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getPricingProfileCheckerById/${mcRequestId}`);
        const keyData = response?.payLoad;

        const productsValues = keyData?.tblProduct?.map((values) => {
            return values?.productId?.toString();
        });

        const partnerData = keyData?.tblPricingIncomeSharings?.map((value) => {
            return {
                pricingIncomeSharingId: value?.pricingIncomeSharingId,
                sharingType: value?.sharingType,
                incomeGlAccountId: value?.incomeGlAccountId?.toString(),
                sharingDetails: value?.sharingDetails,
                sharingPercentage: value?.sharingPercentage?.toString(),
                taxStatus: value?.taxStatus,
                whtGlAccountId: value?.whtGlAccountId?.toString()
            };
        });

        formik.setFieldValue('accountClassificationId', keyData?.accountClassificationId?.toString());
        formik.setFieldValue('segmentId', keyData?.segmentId?.toString());
        formik.setFieldValue('pricingProfileName', keyData?.pricingProfileName);
        formik.setFieldValue('channelId', keyData?.channelId?.toString());
        formik.setFieldValue('chargingParty', keyData?.chargingParty);
        formik.setFieldValue('startDate', keyData?.startDate?.slice(0, 10));
        formik.setFieldValue('endDate', keyData?.endDate?.slice(0, 10));
        formik.setFieldValue('feeCalculationType', keyData?.feeCalculationType);
        formik.setFieldValue('taxCalculationType', keyData?.taxCalculationType);
        formik.setFieldValue('incomeGlAccountId', keyData?.incomeGlAccountId?.toString());
        formik.setFieldValue('tblPricingVelocities', keyData?.tblPricingVelocities);
        formik.setFieldValue('tblPricingIncomeSharings', partnerData);
        formik.setFieldValue('noOfPartners', keyData?.noOfPartners);
        formik.setFieldValue('payeeGlAccountId', keyData?.payeeGlAccountId?.toString());
        formik.setFieldValue('tblPricingSlabs', keyData?.tblPricingSlabs);

        if (keyData?.tblPricingIncomeSharings !== null) {
            formik.setFieldValue('pricingIncomeSharingId', keyData?.tblPricingIncomeSharings[0]?.pricingIncomeSharingId);
        }

        if (productsValues && productsValues.length > 0) {
            formik.setFieldValue('tblProduct', productsValues);
        }
        if (keyData?.velocity === 'N') {
            formik.setFieldValue('velocity', 'N');
        } else {
            formik.setFieldValue('velocity', 'Y');
        }
        if (keyData?.incomeSharing === 'N') {
            formik.setFieldValue('incomeSharing', 'N');
        } else {
            formik.setFieldValue('incomeSharing', 'Y');
        }
    };

    const getMcRequestDataByIdU = async () => {
        setloading(true);

        const res = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getPricingProfileCheckerById/${mcRequestId}`);
        const keyData = res?.payLoad;

        setloading(false);

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
        }
        setRequestedDataByID(res?.payLoad);
    };

    const getRequestedPricingSlabWiseFixedData = async () => {
        const response = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getfeeprofilebyid/${requestedDataById?.pricingProfileId}`);
        const keyData = response?.payLoad;

        const productsValues = keyData?.tblPricingProducts?.map((values) => {
            return values?.tblProduct?.productId?.toString();
        });

        const partnerData = keyData?.incomeSharingRequests?.map((value) => {
            return {
                pricingIncomeSharingId: value?.pricingIncomeSharingId,
                sharingType: value?.sharingType,
                incomeGlAccountId: value?.incomeGlAccountId?.toString(),
                sharingDetails: value?.sharingDetails,
                sharingPercentage: value?.sharingPercentage?.toString(),
                taxStatus: value?.taxStatus,
                whtGlAccountId: value?.whtGlAccountId?.toString()
            };
        });

        formik.setFieldValue('accountClassificationId', keyData?.lkpAccountClassification?.accountClassificationId?.toString());
        formik.setFieldValue('segmentId', keyData?.lkpSegment?.segmentId?.toString());
        formik.setFieldValue('pricingProfileName', keyData?.pricingProfileName);
        formik.setFieldValue('channelId', keyData?.lkpChannel?.channelId?.toString());
        formik.setFieldValue('chargingParty', keyData?.lkpPricingChargingParty?.pricingChargingPartyCode);
        formik.setFieldValue('startDate', keyData?.startDate?.slice(0, 10));
        formik.setFieldValue('endDate', keyData?.endDate?.slice(0, 10));
        formik.setFieldValue('feeCalculationType', keyData?.feeCalculationType);
        formik.setFieldValue('taxCalculationType', keyData?.taxCalculationType);
        formik.setFieldValue('incomeGlAccountId', keyData?.tblAccount1?.accountId?.toString());
        formik.setFieldValue('tblPricingVelocities', keyData?.tblPricingVelocities);
        formik.setFieldValue('tblPricingIncomeSharings', partnerData);
        formik.setFieldValue('noOfPartners', keyData?.noOfPartners);
        formik.setFieldValue('payeeGlAccountId', keyData?.tblAccount2?.accountId?.toString());
        // formik.setFieldValue('pricingIncomeSharingId', keyData?.incomeSharingRequests[0]?.pricingIncomeSharingId);
        formik.setFieldValue('tblPricingSlabs', keyData?.tblPricingSlabs);

        if (productsValues && productsValues.length > 0) {
            formik.setFieldValue('tblProduct', productsValues);
        }
        if (keyData?.velocity === 'N') {
            formik.setFieldValue('velocity', 'N');
        } else {
            formik.setFieldValue('velocity', 'Y');
        }
        if (keyData?.incomeSharing === 'N') {
            formik.setFieldValue('incomeSharing', 'N');
        } else {
            formik.setFieldValue('incomeSharing', 'Y');
        }
    };

    useEffect(() => {
        if (requestType === 'I') {
            getPricingFixedbyId();
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getPricingFixedbyIdU();
        } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
            getMcRequestDataByIdU();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        if (requestTypeDescr === 'Enable/Disable Request' && requestType === 'U' && requestedDataById?.pricingProfileId !== undefined) {
            getRequestedPricingSlabWiseFixedData();
        }
    }, [requestedDataById?.pricingProfileId]); // eslint-disable-line

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1); // Subtract 1 day
    //const previousDate = currentDate.toISOString().split('T')[0];

    const validationSchema = Yup.object().shape({
        checkerComments: Yup.string().required('This Field is required.'),
        action: Yup.string().required('This Field is required.')
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            accountClassificationId: '',
            segmentId: '',
            pricingProfileName: '',
            channelId: '',
            chargingParty: '',
            taxCalculationType: '',
            feeCalculationType: '',
            startDate: '',
            endDate: '',
            feeTypeCode: 'SF',
            incomeGlAccountId: '',
            velocity: 'N',
            incomeSharing: 'N',
            tblProduct: [],
            payeeGlAccountId: '',
            feeAmount: '',
            tblPricingSlabs: [
                {
                    pricingSlabId: '',
                    startSlab: '',
                    endSlab: '',
                    feeAmount: ''
                }
            ],
            pricingSlabId: '',
            pricingProfileId: refTableId,
            tblPricingVelocities: [
                {
                    noOfFreeTrxn: '',
                    frequency: '',
                    velocityRule: '',
                    velocityAmount: '',
                    chargeOnDifferential: 'No'
                }
            ],
            tblPricingIncomeSharings: [
                {
                    sharingType: '',
                    incomeGlAccountId: '',
                    sharingDetails: '',
                    sharingPercentage: '',
                    taxStatus: '',
                    whtGlAccountId: '',
                    pricingIncomeSharingId: ''
                }
            ],
            action: '',
            checkerComments: '',
            isActive: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            formik.setFieldValue('tblProduct', data?.tblProduct);

            if (data.velocity === 'N') {
                delete data['tblPricingVelocities'];
            }
            if (data.incomeSharing === 'N') {
                delete data['tblPricingIncomeSharings'];
            }
            data['payeeGlAccountId'] = data?.payeeGlAccountId;

            data['tblPricingSlabs'] = formik.values.tblPricingSlabs?.map((item) => ({
                pricingSlabId: item?.pricingSlabId,
                startSlab: item?.startSlab,
                endSlab: item?.endSlab,
                feeAmount: item?.feeAmount
            }));

            const productArray = data?.tblProduct.map((prdctData) => {
                return {
                    productId: prdctData
                };
            });

            data['tblProduct'] = productArray;

            if (data?.velocity === 'Y') {
                data?.tblPricingVelocities?.forEach((item) => {
                    delete item.lastupdatedate;
                    delete item.lastupdateuser;
                    delete item.updateindex;
                    delete item.createdate;
                    delete item.createuser;
                    delete item.isActive;
                });
            }
            if (data?.incomeSharing === 'Y') {
                data['tblPricingIncomeSharings'] = data?.tblPricingIncomeSharings?.map((items) => {
                    return {
                        pricingIncomeSharingId: items?.pricingIncomeSharingId,
                        sharingType: items?.sharingType,
                        incomeGlAccountId: items?.incomeGlAccountId,
                        sharingDetails: items?.sharingDetails,
                        sharingPercentage: items?.sharingPercentage,
                        taxStatus: items?.taxStatus,
                        whtGlAccountId: items?.whtGlAccountId
                    };
                });
            }

            data['isActive'] = requestedDataById?.isActive;
            if (requestTypeDescr !== 'Enable/Disable Request') {
                delete data['isActive'];
            }

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        pricingProfileRequest: {
                            pricingProfileId: refTableId,
                            pricingProfileName: data['pricingProfileName'] || '',
                            accountClassificationId: data['accountClassificationId'] || '',
                            segmentId: data['segmentId'] || '',
                            channelId: data['channelId'] || '',
                            chargingParty: data['chargingParty'] || '',
                            payeeGlAccountId: data['payeeGlAccountId'] || '',
                            startDate: data['startDate'] || '',
                            endDate: data['endDate'] || '',
                            feeCalculationType: data['feeCalculationType'] || '',
                            taxCalculationType: data['taxCalculationType'] || '',
                            feeTypeCode: data['feeTypeCode'] || 'SF',
                            velocity: data['velocity'] || '',
                            tblPricingVelocities: data['tblPricingVelocities'],
                            incomeSharing: data['incomeSharing'] || '',
                            noOfPartners: data['noOfPartners'] || '',
                            tblPricingIncomeSharings: data['tblPricingIncomeSharings'],
                            tblProduct: data['tblProduct'],
                            incomeGlAccountId: data['incomeGlAccountId'] || '',
                            tblPricingSlabs: data['tblPricingSlabs'],
                            isActive: data['isActive']
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            await dispatch(handlePostRequest(newData, '/pricingprofile/v1/pricingprofile/pricingprofileCheckerAction', true, false, 'requestchecker'));
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const addVelocity = () => {
        formik.setFieldValue('tblPricingVelocities', [
            ...formik.values.tblPricingVelocities,
            {
                sharingType: '',
                incomeGlAccountId: '',
                sharingDetails: '',
                sharingPercentage: '',
                taxStatus: '',
                whtGlAccountId: ''
            }
        ]);
    };

    const removeCard = (index) => {
        if (index > 0) {
            const updatedData = [...formik.values.tblPricingVelocities];
            updatedData.splice(index, 1);
            formik.setFieldValue('tblPricingVelocities', updatedData);
        }
        if (index === 0) {
            formik.setFieldValue('velocity', 'N');
            const updatedData = [...formik.values.tblPricingVelocities];
            formik.setFieldValue('tblPricingVelocities', updatedData);
        }
    };

    const addPartner = () => {
        formik.setFieldValue('tblPricingIncomeSharings', [
            ...formik.values.tblPricingIncomeSharings,
            {
                sharingType: '',
                incomeGlAccountId: '',
                sharingDetails: '',
                sharingPercentage: '',
                taxStatus: '',
                whtGlAccountId: ''
            }
        ]);
    };
    const removePartnerCard = (index) => {
        if (index > 1) {
            const updatedData = [...formik.values.tblPricingIncomeSharings];
            updatedData.splice(index - 1, 1);
            formik.setFieldValue('tblPricingIncomeSharings', updatedData);
        }
        if (index === 1) {
            formik.setFieldValue('incomeSharing', 'N');
            const updatedData = [...formik.values.tblPricingIncomeSharings];
            formik.setFieldValue('tblPricingIncomeSharings', updatedData);
        }
    };

    const getAllLovsForPricing = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getfeeprofiledata');
        setAllLovs(resp?.payLoad);
    };

    useEffect(() => {
        getAllLovsForPricing();
    }, []);
    const accountTypeLov = allLovs?.accountype?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });

    const segmentLov = allLovs?.segment?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });

    const channelLov = allLovs?.channel?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });
    const transactionsLov = allLovs?.transactions?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });

    const chargingPartyLov = allLovs?.chargingParty?.map((data) => {
        return {
            lovId: data?.code,
            name: data?.name
        };
    });

    const glJsonLov = allLovs?.glAccounts?.map((data) => {
        return {
            lovId: data?.accountId,
            name: `${data?.accountNo}-${data?.accountTitle}`,
            accountTitle: data?.accountTitle
        };
    });

    const feeTypeJson = [
        {
            value: 'I',
            label: 'Inclusive'
        },
        {
            value: 'E',
            label: 'Exclusive'
        }
    ];
    const taxTypeJson = [
        {
            value: 'I',
            label: 'Inclusive'
        },
        {
            value: 'E',
            label: 'Exclusive'
        }
    ];

    const frequencyJson = [
        {
            value: 'D',
            label: 'Daily'
        },
        {
            value: 'M',
            label: 'Monthly'
        },
        {
            value: 'Y',
            label: 'Yearly'
        }
    ];

    const sharingTypeJson = [
        {
            value: 'F',
            label: 'Fixed'
        },
        {
            value: 'P',
            label: 'Percentage'
        }
    ];
    const taxStatusJson = [
        {
            value: 'Y',
            label: 'Filer'
        },
        {
            value: 'N',
            label: 'Non-Filer'
        }
    ];

    const addSlabWiseFixed = () => {
        formik.setFieldValue('tblPricingSlabs', [
            ...formik.values.tblPricingSlabs,
            {
                startSlab: '',
                endSlab: '',
                feeAmount: ''
            }
        ]);
    };

    const removeSlabWiseFixed = (index) => {
        if (index > 0) {
            const updatedData = [...formik.values.tblPricingSlabs];
            updatedData.splice(index, 1);
            formik.setFieldValue('tblPricingSlabs', updatedData);
        }
        if (index === 0) {
            const updatedData = [...formik.values.tblPricingSlabs];
            updatedData.splice(index, 1);
            formik.setFieldValue('tblPricingSlabs', updatedData);
        }
    };
    const getOldNewData = async () => {
        const resp = await handleGetRequest(`/workflow/v1/management/compareValues/${mcRequestId}`);
        setOldNewData(resp?.payLoad);
    };

    useEffect(() => {
        if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getOldNewData();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        const keys = oldNewData?.map((item) => item.key);
        setHighlightedKeys(keys);
    }, [oldNewData]); // eslint-disable-line

    const HandledOldValue = async (e) => {
        e.preventDefault();
        setDisplayDialog(true);
        const response = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getfeeprofilebyid/${refTableId}`);

        setPricingSlabWiseFixedData(response?.payLoad);
    };

    const onHideProductCatalog = () => {
        setDisplayDialog(false);
    };

    return (
        <>
            <Sidebar header="View Product Catalog" position="right" style={{ width: '80vw' }} visible={displayDialog} onHide={() => setDisplayDialog(false)}>
                <PricingSlabWiseFixedOldNewValues onHideProductCatalog={onHideProductCatalog} pricingSlabWiseFixedData={pricingSlabWiseFixedData} />
            </Sidebar>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>EDIT DETAILS</h1>
                    {requestType === 'U' && <Button label="View Old Details" className="Btn__Dark__OldValue" onClick={HandledOldValue} />}
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                    Account Type
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="accountClassificationId"
                                    placeholder="Select Account Type"
                                    options={accountTypeLov}
                                    optionLabel="name"
                                    name="accountClassificationId"
                                    optionValue="lovId"
                                    value={formik.values.accountClassificationId || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('accountClassificationId')
                                            ? classNames({ 'p-invalid': isFormFieldValid('accountClassificationId') }, 'Dropdown__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('accountClassificationId') }, 'Dropdown__Round')
                                    }
                                />

                                {getFormErrorMessage('accountClassificationId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="segmentId" className={classNames({ 'p-error': isFormFieldValid('segmentId') }, 'Label__Text')}>
                                    Segment
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="segmentId"
                                    placeholder="Select User Type"
                                    options={segmentLov}
                                    optionLabel="name"
                                    name="segmentId"
                                    optionValue="lovId"
                                    value={formik.values.segmentId || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('segmentId')
                                            ? classNames({ 'p-invalid': isFormFieldValid('segmentId') }, 'Dropdown__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('segmentId') }, 'Dropdown__Round')
                                    }
                                />

                                {getFormErrorMessage('segmentId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="pricingProfileName" className={classNames({ 'p-error': isFormFieldValid('pricingProfileName') }, 'Label__Text')}>
                                    Pricing Profile Name
                                </label>
                                <InputText
                                    disabled={true}
                                    id="pricingProfileName"
                                    placeholder="Enter Pricing Profile Name"
                                    name="pricingProfileName"
                                    value={formik?.values?.pricingProfileName || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('pricingProfileName')
                                            ? classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round')
                                    }
                                />
                                {getFormErrorMessage('pricingProfileName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="channelId" className={classNames({ 'p-error': isFormFieldValid('channelId') }, 'Label__Text')}>
                                    Channel
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="channelId"
                                    placeholder="Select Partner Group"
                                    options={channelLov}
                                    optionLabel="name"
                                    name="channelId"
                                    optionValue="lovId"
                                    value={formik.values.channelId || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('channelId')
                                            ? classNames({ 'p-invalid': isFormFieldValid('channelId') }, 'Dropdown__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('channelId') }, 'Dropdown__Round')
                                    }
                                />

                                {getFormErrorMessage('channelId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="transaction" className={classNames({ 'p-error': isFormFieldValid('transaction') }, 'Label__Text')}>
                                    Transaction
                                </label>
                                <MultiSelect
                                    // disabled={true}
                                    id="tblProduct"
                                    placeholder="Select Transaction"
                                    options={transactionsLov}
                                    optionLabel="name"
                                    name="tblProduct"
                                    optionValue="lovId"
                                    value={formik.values.tblProduct || ''}
                                    // onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('tblProduct')
                                            ? classNames({ 'p-invalid': isFormFieldValid('tblProduct') }, 'Dropdown__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('tblProduct') }, 'Dropdown__Round')
                                    }
                                />

                                {getFormErrorMessage('transaction')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="chargingParty" className={classNames({ 'p-error': isFormFieldValid('chargingParty') }, 'Label__Text')}>
                                    Charging Party
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="chargingParty"
                                    placeholder="Select Charging Party"
                                    options={chargingPartyLov}
                                    optionLabel="name"
                                    name="chargingParty"
                                    optionValue="lovId"
                                    value={formik.values.chargingParty || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('chargingParty')
                                            ? classNames({ 'p-invalid': isFormFieldValid('chargingParty') }, 'Dropdown__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('chargingParty') }, 'Dropdown__Round')
                                    }
                                />

                                {getFormErrorMessage('chargingParty')}
                            </div>
                        </div>
                        {formik.values.chargingParty === 'P' ? (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="payeeGlAccountId" className={classNames({ 'p-error': isFormFieldValid('payeeGlAccountId') }, 'Label__Text')}>
                                        Payee GL Account
                                    </label>
                                    <Dropdown
                                        disabled={true}
                                        id="payeeGlAccountId"
                                        placeholder="Select Charging Party"
                                        options={glJsonLov}
                                        optionLabel="name"
                                        filter
                                        name="payeeGlAccountId"
                                        optionValue="lovId"
                                        value={formik.values.payeeGlAccountId || ''}
                                        onChange={formik.handleChange}
                                        className={
                                            highlightedKeys?.includes('payeeGlAccountId')
                                                ? classNames({ 'p-invalid': isFormFieldValid('payeeGlAccountId') }, 'Dropdown__Round__Highlighted')
                                                : classNames({ 'p-invalid': isFormFieldValid('payeeGlAccountId') }, 'Dropdown__Round')
                                        }
                                    />

                                    {getFormErrorMessage('payeeGlAccountId')}
                                </div>
                            </div>
                        ) : null}
                        <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') }, 'Label__Text')}>
                                    Start Date
                                </label>
                                <InputText
                                    disabled={true}
                                    id="startDate"
                                    placeholder="Enter startDate"
                                    name="startDate"
                                    type="date"
                                    value={formik?.values?.startDate || ''}
                                    onChange={formik.handleChange}
                                    // className={classNames({ 'p-invalid': isFormFieldValid('startDate') }, 'Input__Round')}
                                    className={
                                        highlightedKeys?.includes('startDate') ? classNames({ 'p-invalid': isFormFieldValid('startDate') }, 'Input__Round__Highlighted') : classNames({ 'p-invalid': isFormFieldValid('startDate') }, 'Input__Round')
                                    }
                                />
                                {getFormErrorMessage('startDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') }, 'Label__Text')}>
                                    End Date
                                </label>
                                <InputText
                                    disabled={true}
                                    id="endDate"
                                    placeholder="Enter endDate"
                                    name="endDate"
                                    type="date"
                                    value={formik?.values?.endDate || ''}
                                    onChange={formik.handleChange}
                                    // className={classNames({ 'p-invalid': isFormFieldValid('endDate') }, 'Input__Round')}
                                    className={highlightedKeys?.includes('endDate') ? classNames({ 'p-invalid': isFormFieldValid('endDate') }, 'Input__Round__Highlighted') : classNames({ 'p-invalid': isFormFieldValid('endDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('endDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="feeCalculationType" className={classNames({ 'p-error': isFormFieldValid('feeCalculationType') }, 'Label__Text')}>
                                    Type of Fee Calculation
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="feeCalculationType"
                                    placeholder="Select Type of Fee Calculation"
                                    options={feeTypeJson}
                                    optionLabel="label"
                                    name="feeCalculationType"
                                    optionValue="value"
                                    value={formik.values.feeCalculationType || ''}
                                    onChange={formik.handleChange}
                                    // className={classNames({ 'p-invalid': isFormFieldValid('feeCalculationType') }, 'Dropdown__Round')}
                                    className={
                                        highlightedKeys?.includes('feeCalculationType')
                                            ? classNames({ 'p-invalid': isFormFieldValid('feeCalculationType') }, 'Dropdown__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('feeCalculationType') }, 'Dropdown__Round')
                                    }
                                />

                                {getFormErrorMessage('feeCalculationType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="taxCalculationType" className={classNames({ 'p-error': isFormFieldValid('taxCalculationType') }, 'Label__Text')}>
                                    Type of Tax Calculation
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="taxCalculationType"
                                    placeholder="Select Type of Tax Calculation"
                                    options={taxTypeJson}
                                    optionLabel="label"
                                    name="taxCalculationType"
                                    optionValue="value"
                                    value={formik.values.taxCalculationType || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('taxCalculationType')
                                            ? classNames({ 'p-invalid': isFormFieldValid('taxCalculationType') }, 'Dropdown__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('taxCalculationType') }, 'Dropdown__Round')
                                    }
                                />

                                {getFormErrorMessage('taxCalculationType')}
                            </div>
                        </div>

                        <div style={{ backgroundColor: '#edf0f5', padding: '2rem' }} className=" col-12 card">
                            <div>
                                <div className="Form__Header">
                                    <h1>Slab Wise Fixed</h1>
                                </div>
                                <div className="grid">
                                    {formik?.values?.tblPricingSlabs?.map((id, index) => (
                                        <div
                                            key={`id-${index}`}
                                            style={
                                                highlightedKeys?.includes('tblPricingSlabs')
                                                    ? {
                                                          height: 'auto',
                                                          paddingTop: '5px',
                                                          paddingLeft: '5px',
                                                          border: '1px solid red',
                                                          borderTopLeftRadius: '5px',
                                                          borderTopRightRadius: '5px',
                                                          borderBottomLeftRadius: '5px',
                                                          borderBottomRightRadius: '5px'
                                                      }
                                                    : { height: 'auto', backgroundColor: '#FFFFFF', padding: '3rem' }
                                            }
                                            className="col-12 card"
                                        >
                                            <div className="grid">
                                                <div className="col-3">
                                                    <div className="p-field pt-3 pb-3">
                                                        <label htmlFor={`startSlab${index}`} className={classNames({ 'p-error': isFormFieldValid(`[tblPricingSlabs${index}].startSlab`) }, 'Label__Text')}>
                                                            Start Slab <span className="Label__Required">*</span>
                                                        </label>
                                                        <InputText
                                                            disabled
                                                            maxLength={15}
                                                            id={`startSlab${index}`}
                                                            placeholder="Enter Start Slab "
                                                            name={`tblPricingSlabs[${index}].startSlab`}
                                                            value={id.startSlab || ''}
                                                            keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingSlabs[${index}].startSlab`) }, 'Input__Round')}
                                                        />
                                                        <small className="p-error">{formik.touched.tblPricingSlabs?.[index]?.startSlab && formik.errors.tblPricingSlabs?.[index]?.startSlab}</small>
                                                    </div>
                                                </div>

                                                <div className="col-3">
                                                    <div className="p-field pt-3 pb-3">
                                                        <label htmlFor={`endSlab${index}`} className={classNames({ 'p-error': isFormFieldValid(`[slabWiseFixed${index}].endSlab`) }, 'Label__Text')}>
                                                            End Slab <span className="Label__Required">*</span>
                                                        </label>
                                                        <InputText
                                                            disabled
                                                            maxLength={15}
                                                            id={`endSlab${index}`}
                                                            placeholder="Enter Start Slab "
                                                            name={`tblPricingSlabs[${index}].endSlab`}
                                                            value={id.endSlab || ''}
                                                            keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingSlabs[${index}].endSlab`) }, 'Input__Round')}
                                                        />
                                                        <small className="p-error">{formik.touched.tblPricingSlabs?.[index]?.endSlab && formik.errors.tblPricingSlabs?.[index]?.endSlab}</small>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="p-field pt-3 pb-3">
                                                        <label htmlFor={`feeAmount${index}`} className={classNames({ 'p-error': isFormFieldValid(`[slabWiseFixed${index}].feeAmount`) }, 'Label__Text')}>
                                                            Fee <span className="Label__Required">*</span>
                                                        </label>
                                                        <InputText
                                                            disabled
                                                            maxLength={15}
                                                            id={`feeAmount${index}`}
                                                            placeholder="Enter Start Slab "
                                                            name={`tblPricingSlabs[${index}].feeAmount`}
                                                            value={id.feeAmount || ''}
                                                            keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingSlabs[${index}].feeAmount`) }, 'Input__Round')}
                                                        />
                                                        <small className="p-error">{formik.touched.tblPricingSlabs?.[index]?.feeAmount && formik.errors.tblPricingSlabs?.[index]?.feeAmount}</small>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="flex flex-column align-items-center justify-content-center">
                                                        <Button
                                                            icon="pi pi-plus"
                                                            className="p-button-rounded"
                                                            aria-label="Filter"
                                                            disabled
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                addSlabWiseFixed();
                                                            }}
                                                        />
                                                        <Button
                                                            disabled
                                                            icon="pi pi-minus"
                                                            className="p-button-rounded mt-2 p-button-danger"
                                                            aria-label="Filter"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                removeSlabWiseFixed(index);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    <div className="col-12 lg:col-6 xl:col-4 md:col-4 flex">
                                        <div className="flex flex-column justify-content-center gap-2">
                                            <div
                                                className="flex flex-column"
                                                style={
                                                    highlightedKeys?.includes('tblPricingVelocities')
                                                        ? {
                                                              height: 'auto',
                                                              paddingTop: '5px',
                                                              paddingLeft: '5px',
                                                              border: '1px solid red',
                                                              borderTopLeftRadius: '5px',
                                                              borderTopRightRadius: '5px',
                                                              borderBottomLeftRadius: '5px',
                                                              borderBottomRightRadius: '5px'
                                                          }
                                                        : { height: 'auto', padding: '2rem' }
                                                }
                                            >
                                                <div className="">
                                                    <label htmlFor="velocity" className={classNames({ 'p-error': isFormFieldValid('velocity') }, 'Label__Text')}>
                                                        Add Velocity
                                                    </label>
                                                </div>
                                                <div className="flex">
                                                    <div className="flex mr-3">
                                                        <RadioButton inputId="velocity" disabled={true} name="velocity" value="N" onChange={formik.handleChange} checked={formik.values.velocity === 'N'} />
                                                        <label htmlFor="velocity" className="ml-2">
                                                            No
                                                        </label>
                                                    </div>
                                                    <div style={{ marginLeft: '10rem' }} className="flex mr-3">
                                                        <RadioButton inputId="velocity" disabled={true} name="velocity" value="Y" onChange={formik.handleChange} checked={formik.values.velocity === 'Y'} />
                                                        <label htmlFor="velocity" className="ml-2">
                                                            Yes
                                                        </label>
                                                    </div>
                                                </div>
                                                {getFormErrorMessage('isSearchable')}
                                            </div>
                                        </div>
                                    </div>
                                    {formik?.values?.velocity === 'Y' && (
                                        <>
                                            <>
                                                {formik?.values?.tblPricingVelocities?.map((velocityData, index) => {
                                                    return (
                                                        <div key={`id-${index}`} className="col-12 card">
                                                            <div
                                                                className="grid"
                                                                style={
                                                                    highlightedKeys?.includes('tblPricingVelocities')
                                                                        ? {
                                                                              height: 'auto',
                                                                              paddingTop: '5px',
                                                                              paddingLeft: '5px',
                                                                              border: '1px solid red',
                                                                              borderTopLeftRadius: '5px',
                                                                              borderTopRightRadius: '5px',
                                                                              borderBottomLeftRadius: '5px',
                                                                              borderBottomRightRadius: '5px'
                                                                          }
                                                                        : { height: 'auto', padding: '2rem' }
                                                                }
                                                            >
                                                                <div className=" grid col-11">
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`velocityRule${index}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingVelocities[${index}].velocityRule`) }, 'Label__Text')}>
                                                                                Add Velocity Rule
                                                                            </label>
                                                                            <InputText
                                                                                disabled={true}
                                                                                id={`velocityRule${index}`}
                                                                                placeholder="Enter Velocity Rule"
                                                                                name={`tblPricingVelocities[${index}].velocityRule`}
                                                                                value={velocityData.velocityRule || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingVelocities[${index}].velocityRule`) }, 'Input__Round')}
                                                                            />
                                                                            {getFormErrorMessage(`tblPricingVelocities[${index}].velocityRule`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`frequency${index}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingVelocities[${index}].frequency`) }, 'Label__Text')}>
                                                                                Enter Frequency
                                                                            </label>
                                                                            <Dropdown
                                                                                disabled={true}
                                                                                id={`frequency${index}`}
                                                                                placeholder="Select Type of Fee "
                                                                                options={frequencyJson}
                                                                                optionLabel="label"
                                                                                name={`tblPricingVelocities[${index}].frequency`}
                                                                                optionValue="value"
                                                                                value={velocityData.frequency || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingVelocities[${index}].frequency`) }, 'Dropdown__Round')}
                                                                            />

                                                                            {getFormErrorMessage(`tblPricingVelocities[${index}].frequency`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`velocityAmount${index}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingVelocities[${index}].velocityAmount`) }, 'Label__Text')}>
                                                                                Velocity Amount
                                                                            </label>
                                                                            <InputText
                                                                                disabled={true}
                                                                                id={`velocityAmount${index}`}
                                                                                placeholder="Enter velocity amount"
                                                                                name={`tblPricingVelocities[${index}].velocityAmount`}
                                                                                value={velocityData.velocityAmount || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingVelocities[${index}].velocityAmount`) }, 'Input__Round')}
                                                                            />

                                                                            {getFormErrorMessage(`tblPricingVelocities[${index}].velocityAmount`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`noOfFreeTrxn${index}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingVelocities[${index}].noOfFreeTrxn`) }, 'Label__Text')}>
                                                                                Enter Number of Free Trx
                                                                            </label>
                                                                            <InputText
                                                                                disabled={true}
                                                                                id={`noOfFreeTrxn${index}`}
                                                                                placeholder="Enter Number of Free Trx"
                                                                                name={`tblPricingVelocities[${index}].noOfFreeTrxn`}
                                                                                value={velocityData.noOfFreeTrxn || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingVelocities[${index}].noOfFreeTrxn`) }, 'Input__Round')}
                                                                            />
                                                                            {getFormErrorMessage(`tblPricingVelocities[${index}].noOfFreeTrxn`)}
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3 mt-5">
                                                                        <div className="flex flex-column gap-2">
                                                                            <div className="flex align-items-center">
                                                                                <label htmlFor="chargeOnDifferential" className={classNames({ 'p-error': isFormFieldValid('chargeOnDifferential') }, 'Label__Text')}>
                                                                                    Charge on Differential
                                                                                </label>
                                                                                <div className="flex align-items-center mr-3">
                                                                                    <RadioButton
                                                                                        disabled={true}
                                                                                        inputId={`tblPricingVelocities${index}_n`}
                                                                                        name={`tblPricingVelocities[${index}].chargeOnDifferential`}
                                                                                        value="N"
                                                                                        onChange={formik.handleChange}
                                                                                        checked={velocityData.chargeOnDifferential === 'N'}
                                                                                    />
                                                                                    <label htmlFor="chargeOnDifferential" className="ml-2">
                                                                                        No
                                                                                    </label>
                                                                                </div>
                                                                                <div className="flex align-items-center mr-3">
                                                                                    <RadioButton
                                                                                        disabled={true}
                                                                                        inputId={`tblPricingVelocities[${index}]_y`}
                                                                                        name={`tblPricingVelocities[${index}].chargeOnDifferential`}
                                                                                        value="Y"
                                                                                        onChange={formik.handleChange}
                                                                                        checked={velocityData.chargeOnDifferential === 'Y'}
                                                                                    />
                                                                                    <label htmlFor="chargeOnDifferential" className="ml-2">
                                                                                        Yes
                                                                                    </label>
                                                                                </div>

                                                                                {getFormErrorMessage('chargeOnDifferential')}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-column  align-items-center justify-content-center col-1">
                                                                    <Button
                                                                        icon="pi pi-plus"
                                                                        className="p-button-rounded"
                                                                        aria-label="Filter"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            addVelocity();
                                                                        }}
                                                                        disabled={true}
                                                                    />
                                                                    <Button
                                                                        icon="pi pi-minus"
                                                                        className="p-button-rounded mt-2 p-button-danger"
                                                                        aria-label="Filter"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            removeCard(index);
                                                                        }}
                                                                        disabled={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </>
                                        </>
                                    )}
                                    <div className="col-12 lg:col-6 xl:col-4 md:col-4 flex">
                                        <div className="flex flex-column justify-content-center gap-2">
                                            <div
                                                className="flex flex-column"
                                                style={
                                                    highlightedKeys?.includes('tblPricingIncomeSharings')
                                                        ? {
                                                              height: 'auto',
                                                              paddingTop: '5px',
                                                              paddingLeft: '5px',
                                                              border: '1px solid red',
                                                              borderTopLeftRadius: '5px',
                                                              borderTopRightRadius: '5px',
                                                              borderBottomLeftRadius: '5px',
                                                              borderBottomRightRadius: '5px'
                                                          }
                                                        : { height: 'auto', padding: '2rem' }
                                                }
                                            >
                                                <div>
                                                    <label htmlFor="incomeSharing" className={classNames({ 'p-error': isFormFieldValid('incomeSharing') }, 'Label__Text')}>
                                                        Income Sharing
                                                    </label>
                                                </div>
                                                <div className="flex">
                                                    <div className="flex mr-3">
                                                        <RadioButton inputId="incomeSharing" disabled={true} name="incomeSharing" value="N" onChange={formik.handleChange} checked={formik.values.incomeSharing === 'N'} />
                                                        <label htmlFor="incomeSharing" className="ml-2">
                                                            No
                                                        </label>
                                                    </div>
                                                    <div style={{ marginLeft: '10rem' }} className="flex mr-3">
                                                        <RadioButton inputId="incomeSharing" disabled={true} name="incomeSharing" value="Y" onChange={formik.handleChange} checked={formik.values.incomeSharing === 'Y'} />
                                                        <label htmlFor="incomeSharing" className="ml-2">
                                                            Yes
                                                        </label>
                                                    </div>
                                                </div>

                                                {getFormErrorMessage('isSearchable')}
                                            </div>
                                        </div>
                                    </div>
                                    {formik.values.incomeSharing === 'Y' && (
                                        <>
                                            <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor="noOfPartners" className={classNames({ 'p-error': isFormFieldValid('noOfPartners') }, 'Label__Text')}>
                                                        Number of Partners
                                                    </label>
                                                    <InputText
                                                        disabled={true}
                                                        id="noOfPartners"
                                                        placeholder="Select Partners "
                                                        name="noOfPartners"
                                                        value={formik.values.noOfPartners || ''}
                                                        onChange={formik.handleChange}
                                                        className={
                                                            highlightedKeys?.includes('noOfPartners')
                                                                ? classNames({ 'p-invalid': isFormFieldValid('noOfPartners') }, 'Input__Round__Highlighted')
                                                                : classNames({ 'p-invalid': isFormFieldValid('noOfPartners') }, 'Input__Round')
                                                        }
                                                    />

                                                    {getFormErrorMessage('noOfPartners')}
                                                </div>
                                            </div>
                                            {formik?.values?.tblPricingIncomeSharings?.map((partnerData, index) => {
                                                index = index + 1;
                                                return (
                                                    <div key={`id-${index - 1}`} className="col-12">
                                                        <div
                                                            // style={{ padding: '2rem' }}
                                                            className="col-12 card"
                                                            style={
                                                                highlightedKeys?.includes('tblPricingIncomeSharings')
                                                                    ? {
                                                                          height: 'auto',
                                                                          paddingTop: '5px',
                                                                          paddingLeft: '5px',
                                                                          border: '1px solid red',
                                                                          borderTopLeftRadius: '5px',
                                                                          borderTopRightRadius: '5px',
                                                                          borderBottomLeftRadius: '5px',
                                                                          borderBottomRightRadius: '5px'
                                                                      }
                                                                    : { height: 'auto', padding: '2rem' }
                                                            }
                                                        >
                                                            <div className="Form__Header">
                                                                <h1>Sharing {index}</h1>
                                                            </div>

                                                            <div className="grid">
                                                                <div className="grid col-11">
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`sharingType${index - 1}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingType`) }, 'Label__Text')}>
                                                                                Sharing Type
                                                                            </label>
                                                                            <Dropdown
                                                                                disabled={true}
                                                                                id={`sharingType${index - 1}`}
                                                                                placeholder="Select Type of Fee "
                                                                                options={sharingTypeJson}
                                                                                optionLabel="label"
                                                                                name={`tblPricingIncomeSharings[${index - 1}].sharingType`}
                                                                                optionValue="value"
                                                                                value={partnerData.sharingType || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingType`) }, 'Dropdown__Round')}
                                                                            />

                                                                            {getFormErrorMessage(`tblPricingIncomeSharings[${index - 1}].sharingType`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label
                                                                                htmlFor={`incomeGlAccountId${index - 1}`}
                                                                                className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].incomeGlAccountId`) }, 'Label__Text')}
                                                                            >
                                                                                Income GL
                                                                            </label>
                                                                            <Dropdown
                                                                                disabled={true}
                                                                                id={`incomeGlAccountId${index - 1}`}
                                                                                placeholder="Select Income GL"
                                                                                options={glJsonLov}
                                                                                optionLabel="name"
                                                                                filter
                                                                                name={`tblPricingIncomeSharings[${index - 1}].incomeGlAccountId`}
                                                                                optionValue="lovId"
                                                                                value={partnerData.incomeGlAccountId || ''}
                                                                                onChange={(e) => {
                                                                                    formik.handleChange(e);
                                                                                    const selectedValue = e.value;
                                                                                    const selectedAccount = glJsonLov.find((item) => item.lovId === selectedValue);
                                                                                    const updatedSharingAccount = [...sharingAccount];
                                                                                    updatedSharingAccount[index - 1] = selectedAccount ? selectedAccount.accountTitle : '';
                                                                                    setSharingAccount(updatedSharingAccount);
                                                                                }}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].incomeGlAccountId`) }, 'Dropdown__Round')}
                                                                            />

                                                                            {getFormErrorMessage(`tblPricingIncomeSharings[${index - 1}].incomegl`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`sharingDetails${index - 1}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingDetails`) }, 'Label__Text')}>
                                                                                Sharing Details
                                                                            </label>
                                                                            <InputText
                                                                                disabled={true}
                                                                                id={`sharingDetails${index - 1}`}
                                                                                placeholder="Select Income GL"
                                                                                name={`tblPricingIncomeSharings[${index - 1}].sharingDetails`}
                                                                                value={(partnerData.sharingDetails = sharingAccount[index - 1] || partnerData.sharingDetails || '')}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingDetails`) }, 'Input__Round')}
                                                                            />
                                                                            {getFormErrorMessage(`tblPricingIncomeSharings[${index - 1}].sharingDetails`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label
                                                                                htmlFor={`sharingPercentage${index - 1}`}
                                                                                className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingPercentage`) }, 'Label__Text')}
                                                                            >
                                                                                Fee
                                                                            </label>
                                                                            <InputText
                                                                                disabled={true}
                                                                                id={`sharingPercentage${index - 1}`}
                                                                                placeholder="Enter Fee"
                                                                                name={`tblPricingIncomeSharings[${index - 1}].sharingPercentage`}
                                                                                value={partnerData.sharingPercentage || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingPercentage`) }, 'Input__Round')}
                                                                            />
                                                                            {getFormErrorMessage(`tblPricingIncomeSharings[${index - 1}].sharingPercentage`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`taxStatus${index - 1}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].taxStatus`) }, 'Label__Text')}>
                                                                                Tax Status
                                                                            </label>
                                                                            <Dropdown
                                                                                disabled={true}
                                                                                id={`taxStatus${index - 1}`}
                                                                                placeholder="Select Type of Tax Status "
                                                                                options={taxStatusJson}
                                                                                optionLabel="label"
                                                                                name={`tblPricingIncomeSharings[${index - 1}].taxStatus`}
                                                                                optionValue="value"
                                                                                value={partnerData.taxStatus || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].taxStatus`) }, 'Dropdown__Round')}
                                                                            />

                                                                            {getFormErrorMessage(`tblPricingIncomeSharings[${index - 1}].taxStatus`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`whtGlAccountId${index - 1}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].whtGlAccountId`) }, 'Label__Text')}>
                                                                                WHT GL
                                                                            </label>
                                                                            <Dropdown
                                                                                disabled={true}
                                                                                id={`whtGlAccountId${index - 1}`}
                                                                                placeholder="Select Type of WHT GL"
                                                                                options={glJsonLov}
                                                                                optionLabel="name"
                                                                                filter
                                                                                name={`tblPricingIncomeSharings[${index - 1}].whtGlAccountId`}
                                                                                optionValue="lovId"
                                                                                value={partnerData.whtGlAccountId || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].whtGlAccountId`) }, 'Dropdown__Round')}
                                                                            />

                                                                            {getFormErrorMessage(`tblPricingIncomeSharings[${index - 1}].whtGlAccountId`)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-column  align-items-center justify-content-center col-1">
                                                                    <Button
                                                                        icon="pi pi-plus"
                                                                        className="p-button-rounded"
                                                                        aria-label="Filter"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            addPartner();
                                                                        }}
                                                                        disabled={true}
                                                                    />
                                                                    <Button
                                                                        icon="pi pi-minus"
                                                                        className="p-button-rounded mt-2 p-button-danger"
                                                                        aria-label="Filter"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            removePartnerCard(index);
                                                                        }}
                                                                        disabled={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    )}

                                    {formik.values.incomeSharing === 'Y' ? null : (
                                        <>
                                            <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor="incomeGlAccountId" className={classNames({ 'p-error': isFormFieldValid('incomeGlAccountId') }, 'Label__Text')}>
                                                        Income GL
                                                    </label>
                                                    <Dropdown
                                                        disabled={true}
                                                        id="incomeGlAccountId"
                                                        placeholder="Select Income GL"
                                                        options={glJsonLov}
                                                        optionLabel="name"
                                                        filter
                                                        name="incomeGlAccountId"
                                                        optionValue="lovId"
                                                        value={formik.values.incomeGlAccountId || ''}
                                                        onChange={formik.handleChange}
                                                        className={
                                                            highlightedKeys?.includes('incomeGlAccountId')
                                                                ? classNames({ 'p-invalid': isFormFieldValid('incomeGlAccountId') }, 'Dropdown__Round__Highlighted')
                                                                : classNames({ 'p-invalid': isFormFieldValid('incomeGlAccountId') }, 'Dropdown__Round')
                                                        }
                                                    />

                                                    {getFormErrorMessage('incomeGlAccountId')}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                    Is Active
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isActive" disabled={true} name="isActive" value={requestedDataById?.isActive === 'Y' ? true : false} checked={requestedDataById?.isActive === 'Y' ? true : false} onChange={formik.handleChange} />
                                </div>
                                {getFormErrorMessage('isActive')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    optionDisabled={(option) => option?.name === 'Pending'}
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    maxLength={200}
                                    rows={5}
                                    cols={30}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label="Update" disabled={loading} loadingIcon={loadingIcon} className="Btn__Dark" />
                        <Button onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default EditPricingSlabWiseFixedChecker;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { Dropdown } from 'primereact/dropdown';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function EditCRPModelChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');
    const [statusLovData, setStatusLovData] = useState([]);
    const [mcRequestData, setMcRequestData] = useState();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        getStatusLov();
    }, []);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getDatabyId = async () => {
        const resp = await handleGetRequest(`/riskprofile/v1/riskprofile/model/get/${refTableId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            formik.setFieldValue('crpModelCode', keyData?.crpModelCode);
            formik.setFieldValue('crpModelName', keyData?.crpModelName);
            formik.setFieldValue('crpModelDescription', keyData?.crpModelDescr);

            const CTDropdownData = keyData?.tblRpRiskFactors;

            if (CTDropdownData) {
                CTDropdownData.forEach((value) => {
                    // Customer Type

                    // Get Section Weigtage Customer Type
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'CU') {
                        formik.setFieldValue('customerType', value?.weigtage);
                    }

                    // Get Customer Type Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'CT') {
                        formik.setFieldValue('customerTypeSubSection', value?.riskFactorScore);
                        formik.setFieldValue('customerTypeRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Occupation Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'OC') {
                        formik.setFieldValue('occupationSubSection', value?.riskFactorScore);
                        formik.setFieldValue('occupationRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Is Beneficial Owner Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'IB') {
                        formik.setFieldValue('beneficialCustomerSubSection', value?.riskFactorScore);
                        formik.setFieldValue('beneficialCustomerRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Source of Funds Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'SF') {
                        formik.setFieldValue('sourceOfFundSubSection', value?.riskFactorScore);
                        formik.setFieldValue('sourceOfFundRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Is adverse media Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'IS') {
                        formik.setFieldValue('adverseMediaSubSection', value?.riskFactorScore);
                        formik.setFieldValue('adverseMediaRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Purpose of A/C Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'PA') {
                        formik.setFieldValue('purposeOfAcSubSection', value?.riskFactorScore);
                        formik.setFieldValue('purposeOfAcRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Is Customer the director Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'CCT') {
                        formik.setFieldValue('customerTheDirectorSubSection', value?.riskFactorScore);
                        formik.setFieldValue('customerTheDirectorRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Other Nationalities/residencies Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'ON') {
                        formik.setFieldValue('nationalitiesSubSection', value?.riskFactorScore);
                        formik.setFieldValue('nationalitiesRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Is customer is PEP? Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'PEP') {
                        formik.setFieldValue('customerPepSubSection', value?.riskFactorScore);
                        formik.setFieldValue('customerPepRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Product And Services Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'PS') {
                        formik.setFieldValue('productsAndServices', value?.riskFactorScore);
                        formik.setFieldValue('sectionWeigtage', value?.weigtage);
                        formik.setFieldValue('isActiveproductsAndServices', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Channels Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'CH') {
                        formik.setFieldValue('channel', value?.riskFactorScore);
                        formik.setFieldValue('channelWeigtage', value?.weigtage);
                        formik.setFieldValue('isActivechannel', value?.overrideRule === 'N' ? false : true);
                    }

                    // Geographies (Both Local and International
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'GE') {
                        formik.setFieldValue('geographiesWeigtage', value?.weigtage);
                    }

                    // Get Trading /counter parties Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'LC') {
                        formik.setFieldValue('tradingRiskFactoryType', value?.riskFactorScore);
                        formik.setFieldValue('isActivetradingRisk', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Permanent Address Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'PTA') {
                        formik.setFieldValue('permanentAddressFactoryType', value?.riskFactorScore);
                        formik.setFieldValue('isActivepermanentAddress', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Mailing Address/geo-location Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'MA') {
                        formik.setFieldValue('mailingAddressFactoryType', value?.riskFactorScore);
                        formik.setFieldValue('isActivemailingAddress', value?.overrideRule === 'N' ? false : true);
                    }

                    // Anticipated Transactions Profile
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'ATP') {
                        formik.setFieldValue('transctionProfileWeigtage', value?.weigtage);
                    }

                    // Get Expected aggregate Debit amount per Month Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'DM') {
                        formik.setFieldValue('aggregateDebitAmount', value?.riskFactorScore);
                        formik.setFieldValue('isActiveAggregateDebitAmount', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Expected aggregate Credit amount per Month Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'CM') {
                        formik.setFieldValue('aggregateCreditAmount', value?.riskFactorScore);
                        formik.setFieldValue('isActiveAggregateCreditAmount', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Expected Debit count per Month Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'DC') {
                        formik.setFieldValue('debitAmount', value?.riskFactorScore);
                        formik.setFieldValue('isActiveDebitAmount', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Expected Credit Count per Month Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'CC') {
                        formik.setFieldValue('creditAmount', value?.riskFactorScore);
                        formik.setFieldValue('isActivecreditAmount', value?.overrideRule === 'N' ? false : true);
                    }
                });
            }
            // formik.setFieldValue('salaryStatus', keyData?.salaryStatus === 'Y' ? true : false);
        }
    };

    const getMcRequestDataById = async () => {
        const resp = await handleGetRequest(`/riskprofile/v1/crpmodel/getcrpmodelmcrequestbyid/${mcRequestId}`);
        const keyData = resp?.payLoad;
        setMcRequestData(keyData);

        if (keyData !== null && typeof keyData !== 'undefined') {
            formik.setFieldValue('crpModelCode', keyData?.crpModelCode);
            formik.setFieldValue('crpModelName', keyData?.crpModelName);
            formik.setFieldValue('crpModelDescription', keyData?.crpModelDescription);

            const CTDropdownData = keyData?.rpFactorDetailRequestList;

            if (CTDropdownData) {
                CTDropdownData.forEach((value) => {
                    // Customer Type

                    // Get Section Weigtage Customer Type
                    if (value?.riskFactorCode === 'CU') {
                        formik.setFieldValue('customerType', value?.total);
                    }

                    // Get Customer Type Data
                    if (value?.riskFactorCode === 'CT') {
                        formik.setFieldValue('customerTypeSubSection', value?.subSectionWeightage);
                        formik.setFieldValue('customerTypeRiskOverRideRule', value?.riskOverRideRule);
                    }

                    // Get Occupation Data
                    if (value?.riskFactorCode === 'OC') {
                        formik.setFieldValue('occupationSubSection', value?.subSectionWeightage);
                        formik.setFieldValue('occupationRiskOverRideRule', value?.riskOverRideRule);
                    }

                    // Get Is Beneficial Owner Data
                    if (value?.riskFactorCode === 'IB') {
                        formik.setFieldValue('beneficialCustomerSubSection', value?.subSectionWeightage);
                        formik.setFieldValue('beneficialCustomerRiskOverRideRule', value?.riskOverRideRule);
                    }

                    // Get Source of Funds Data
                    if (value?.riskFactorCode === 'SF') {
                        formik.setFieldValue('sourceOfFundSubSection', value?.subSectionWeightage);
                        formik.setFieldValue('sourceOfFundRiskOverRideRule', value?.riskOverRideRule);
                    }

                    // Get Is adverse media Data
                    if (value?.riskFactorCode === 'IS') {
                        formik.setFieldValue('adverseMediaSubSection', value?.subSectionWeightage);
                        formik.setFieldValue('adverseMediaRiskOverRideRule', value?.riskOverRideRule);
                    }

                    // Get Purpose of A/C Data
                    if (value?.riskFactorCode === 'PA') {
                        formik.setFieldValue('purposeOfAcSubSection', value?.subSectionWeightage);
                        formik.setFieldValue('purposeOfAcRiskOverRideRule', value?.riskOverRideRule);
                    }

                    // Get Is Customer the director Data
                    if (value?.riskFactorCode === 'CCT') {
                        formik.setFieldValue('customerTheDirectorSubSection', value?.subSectionWeightage);
                        formik.setFieldValue('customerTheDirectorRiskOverRideRule', value?.riskOverRideRule);
                    }

                    // Get Other Nationalities/residencies Data
                    if (value?.riskFactorCode === 'ON') {
                        formik.setFieldValue('nationalitiesSubSection', value?.subSectionWeightage);
                        formik.setFieldValue('nationalitiesRiskOverRideRule', value?.riskOverRideRule);
                    }

                    // Get Is customer is PEP? Data
                    if (value?.riskFactorCode === 'PEP') {
                        formik.setFieldValue('customerPepSubSection', value?.subSectionWeightage);
                        formik.setFieldValue('customerPepRiskOverRideRule', value?.riskOverRideRule);
                    }

                    // Get Product And Services Data
                    if (value?.riskFactorCode === 'PS') {
                        formik.setFieldValue('productsAndServices', value?.subSectionWeightage);
                        formik.setFieldValue('sectionWeigtage', value?.total);
                        formik.setFieldValue('isActiveproductsAndServices', value?.riskOverRideRule);
                    }

                    // Get Channels Data
                    if (value?.riskFactorCode === 'CH') {
                        formik.setFieldValue('channel', value?.subSectionWeightage);
                        formik.setFieldValue('channelWeigtage', value?.total);
                        formik.setFieldValue('isActivechannel', value?.riskOverRideRule);
                    }

                    // Geographies (Both Local and International
                    if (value?.riskFactorCode === 'GE') {
                        formik.setFieldValue('geographiesWeigtage', value?.total);
                    }

                    // Get Trading /counter parties Data
                    if (value?.riskFactorCode === 'LC') {
                        formik.setFieldValue('tradingRiskFactoryType', value?.subSectionWeightage);
                        formik.setFieldValue('isActivetradingRisk', value?.riskOverRideRule);
                    }

                    // Get Permanent Address Data
                    if (value?.riskFactorCode === 'PTA') {
                        formik.setFieldValue('permanentAddressFactoryType', value?.subSectionWeightage);
                        formik.setFieldValue('isActivepermanentAddress', value?.riskOverRideRule);
                    }

                    // Get Mailing Address/geo-location Data
                    if (value?.riskFactorCode === 'MA') {
                        formik.setFieldValue('mailingAddressFactoryType', value?.subSectionWeightage);
                        formik.setFieldValue('isActivemailingAddress', value?.riskOverRideRule);
                    }

                    // Anticipated Transactions Profile
                    if (value?.riskFactorCode === 'ATP') {
                        formik.setFieldValue('transctionProfileWeigtage', value?.total);
                    }

                    // Get Expected aggregate Debit amount per Month Data
                    if (value?.riskFactorCode === 'DM') {
                        formik.setFieldValue('aggregateDebitAmount', value?.subSectionWeightage);
                        formik.setFieldValue('isActiveAggregateDebitAmount', value?.riskOverRideRule);
                    }

                    // Get Expected aggregate Credit amount per Month Data
                    if (value?.riskFactorCode === 'CM') {
                        formik.setFieldValue('aggregateCreditAmount', value?.subSectionWeightage);
                        formik.setFieldValue('isActiveAggregateCreditAmount', value?.riskOverRideRule);
                    }

                    // Get Expected Debit count per Month Data
                    if (value?.riskFactorCode === 'DC') {
                        formik.setFieldValue('debitAmount', value?.subSectionWeightage);
                        formik.setFieldValue('isActiveDebitAmount', value?.riskOverRideRule);
                    }

                    // Get Expected Credit Count per Month Data
                    if (value?.riskFactorCode === 'CC') {
                        formik.setFieldValue('creditAmount', value?.subSectionWeightage);
                        formik.setFieldValue('isActivecreditAmount', value?.riskOverRideRule);
                    }
                });
            }
            // formik.setFieldValue('salaryStatus', keyData?.salaryStatus === 'Y' ? true : false);
        }
    };

    const getDatabyIdU = async () => {
        const resp = await handleGetRequest(`/riskprofile/v1/riskprofile/model/get/${refTableId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            formik.setFieldValue('crpModelCode', keyData?.crpModelCode);
            formik.setFieldValue('crpModelName', keyData?.crpModelName);
            formik.setFieldValue('crpModelDescription', keyData?.crpModelDescr);

            const CTDropdownData = keyData?.tblRpRiskFactors;

            if (CTDropdownData) {
                CTDropdownData.forEach((value) => {
                    // Customer Type

                    // Get Section Weigtage Customer Type
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'CU') {
                        formik.setFieldValue('customerType', value?.weigtage);
                    }

                    // Get Customer Type Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'CT') {
                        formik.setFieldValue('customerTypeSubSection', value?.riskFactorScore);
                        formik.setFieldValue('customerTypeRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Occupation Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'OC') {
                        formik.setFieldValue('occupationSubSection', value?.riskFactorScore);
                        formik.setFieldValue('occupationRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Is Beneficial Owner Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'IB') {
                        formik.setFieldValue('beneficialCustomerSubSection', value?.riskFactorScore);
                        formik.setFieldValue('beneficialCustomerRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Source of Funds Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'SF') {
                        formik.setFieldValue('sourceOfFundSubSection', value?.riskFactorScore);
                        formik.setFieldValue('sourceOfFundRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Is adverse media Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'IS') {
                        formik.setFieldValue('adverseMediaSubSection', value?.riskFactorScore);
                        formik.setFieldValue('adverseMediaRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Purpose of A/C Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'PA') {
                        formik.setFieldValue('purposeOfAcSubSection', value?.riskFactorScore);
                        formik.setFieldValue('purposeOfAcRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Is Customer the director Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'CCT') {
                        formik.setFieldValue('customerTheDirectorSubSection', value?.riskFactorScore);
                        formik.setFieldValue('customerTheDirectorRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Other Nationalities/residencies Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'ON') {
                        formik.setFieldValue('nationalitiesSubSection', value?.riskFactorScore);
                        formik.setFieldValue('nationalitiesRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Is customer is PEP? Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'PEP') {
                        formik.setFieldValue('customerPepSubSection', value?.riskFactorScore);
                        formik.setFieldValue('customerPepRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Product And Services Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'PS') {
                        formik.setFieldValue('productsAndServices', value?.riskFactorScore);
                        formik.setFieldValue('sectionWeigtage', value?.weigtage);
                        formik.setFieldValue('isActiveproductsAndServices', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Channels Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'CH') {
                        formik.setFieldValue('channel', value?.riskFactorScore);
                        formik.setFieldValue('channelWeigtage', value?.weigtage);
                        formik.setFieldValue('isActivechannel', value?.overrideRule === 'N' ? false : true);
                    }

                    // Geographies (Both Local and International
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'GE') {
                        formik.setFieldValue('geographiesWeigtage', value?.weigtage);
                    }

                    // Get Trading /counter parties Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'LC') {
                        formik.setFieldValue('tradingRiskFactoryType', value?.riskFactorScore);
                        formik.setFieldValue('isActivetradingRisk', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Permanent Address Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'PTA') {
                        formik.setFieldValue('permanentAddressFactoryType', value?.riskFactorScore);
                        formik.setFieldValue('isActivepermanentAddress', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Mailing Address/geo-location Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'MA') {
                        formik.setFieldValue('mailingAddressFactoryType', value?.riskFactorScore);
                        formik.setFieldValue('isActivemailingAddress', value?.overrideRule === 'N' ? false : true);
                    }

                    // Anticipated Transactions Profile
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'ATP') {
                        formik.setFieldValue('transctionProfileWeigtage', value?.weigtage);
                    }

                    // Get Expected aggregate Debit amount per Month Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'DM') {
                        formik.setFieldValue('aggregateDebitAmount', value?.riskFactorScore);
                        formik.setFieldValue('isActiveAggregateDebitAmount', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Expected aggregate Credit amount per Month Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'CM') {
                        formik.setFieldValue('aggregateCreditAmount', value?.riskFactorScore);
                        formik.setFieldValue('isActiveAggregateCreditAmount', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Expected Debit count per Month Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'DC') {
                        formik.setFieldValue('debitAmount', value?.riskFactorScore);
                        formik.setFieldValue('isActiveDebitAmount', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Expected Credit Count per Month Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'CC') {
                        formik.setFieldValue('creditAmount', value?.riskFactorScore);
                        formik.setFieldValue('isActivecreditAmount', value?.overrideRule === 'N' ? false : true);
                    }
                });
            }
            // formik.setFieldValue('salaryStatus', keyData?.salaryStatus === 'Y' ? true : false);
        }
    };

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getMcRequestDataById();
        } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
            getDatabyIdU();
            getMcRequestDataById();
        }
    }, []); // eslint-disable-line

    const riskCategoryCriteria = [
        {
            riskScore: '>0 & <= 5',
            rating: 'low',
            inTerms: 'Less than or Equal to 50%'
        },
        {
            riskScore: '>5 & <= 7',
            rating: 'medium',
            inTerms: 'Above 50% Less than or Equal to 70%'
        },
        {
            riskScore: '>7 & <= 10',
            rating: 'high',
            inTerms: 'Above 70%'
        }
    ];

    const formik = useFormik({
        initialValues: {
            crpModelCode: '',
            crpModelName: '',
            crpModelDescription: '',
            customerTypeSubSection: '',
            occupationSubSection: '',
            beneficialCustomerSubSection: '',
            sourceOfFundSubSection: '',
            purposeOfAcSubSection: '',
            adverseMediaSubSection: '',
            customerTheDirectorSubSection: '',
            nationalitiesSubSection: '',
            customerPepSubSection: '',
            customerType: '',
            customerTypeRiskOverRideRule: false,
            beneficialCustomerRiskOverRideRule: false,
            occupationRiskOverRideRule: false,
            sourceOfFundRiskOverRideRule: false,
            purposeOfAcRiskOverRideRule: false,
            adverseMediaRiskOverRideRule: false,
            customerTheDirectorRiskOverRideRule: false,
            nationalitiesRiskOverRideRule: false,
            customerPepRiskOverRideRule: false,
            productsAndServices: '',
            isActiveproductsAndServices: false,
            channel: '',
            isActivechannel: false,
            tradingRiskFactoryType: '',
            mailingAddressFactoryType: '',
            permanentAddressFactoryType: '',
            geographiesWeigtage: '',
            isActivetradingRisk: false,
            isActivepermanentAddress: false,
            isActivemailingAddress: false,
            aggregateDebitAmount: '',
            aggregateCreditAmount: '',
            debitAmount: '',
            creditAmount: '',
            transctionProfileWeigtage: '',
            isActiveAggregateDebitAmount: false,
            isActiveAggregateCreditAmount: false,
            isActiveDebitAmount: false,
            isActivecreditAmount: false,
            action: '',
            checkerComments: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newDataI = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        rpFactorRequest: {
                            crpModelId: parseInt(refTableId),
                            isActive: 'Y'
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const newDataU = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        rpFactorRequest: mcRequestData,
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(requestType === 'I' ? newDataI : newDataU, '/riskprofile/v1/crpmodel/crpmodelValidatorCheckerAction', true, false, 'requestchecker'));
            if (res?.responseCode === '170000') {
                formik.resetForm();
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const sumOfCustomerTypeFields =
        parseFloat(formik?.values?.customerTypeSubSection) +
        parseFloat(formik?.values?.occupationSubSection) +
        parseFloat(formik?.values?.beneficialCustomerSubSection) +
        parseFloat(formik?.values?.sourceOfFundSubSection) +
        parseFloat(formik?.values?.purposeOfAcSubSection) +
        parseFloat(formik?.values?.adverseMediaSubSection) +
        parseFloat(formik?.values?.customerPepSubSection) +
        parseFloat(formik?.values?.nationalitiesSubSection) +
        parseFloat(formik?.values?.customerTheDirectorSubSection);

    const sumOfGeographies = parseFloat(formik?.values?.tradingRiskFactoryType) + parseFloat(formik?.values?.permanentAddressFactoryType) + parseFloat(formik?.values?.mailingAddressFactoryType);

    const sumOfTransactionProfile = parseFloat(formik?.values?.aggregateDebitAmount) + parseFloat(formik?.values?.aggregateCreditAmount) + parseFloat(formik?.values?.debitAmount) + parseFloat(formik?.values?.creditAmount);
    const productsAndServicesSubSection = parseFloat(formik?.values?.productsAndServices || 0);
    const channelSubSection = parseFloat(formik?.values?.channel || 0);

    const totalOfAllFields = sumOfCustomerTypeFields + sumOfGeographies + sumOfTransactionProfile + productsAndServicesSubSection + channelSubSection;

    const sumOfSectionWeigtage =
        parseFloat(formik?.values?.customerType || 0) +
        parseFloat(formik?.values?.sectionWeigtage || 0) +
        parseFloat(formik?.values?.channelWeigtage || 0) +
        parseFloat(formik?.values?.geographiesWeigtage || 0) +
        parseFloat(formik?.values?.transctionProfileWeigtage || 0);

    const handleRatingBodyTemplate = (rowData) => {
        const textColor = rowData?.rating === 'low' ? 'rgb(20, 163, 139)' : rowData?.rating === 'medium' ? 'rgb(242, 172, 87)' : rowData?.rating === 'high' ? 'red' : null;
        return <p style={{ color: textColor, textTransform: 'capitalize' }}>{rowData?.rating}</p>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>EDIT DETAIL</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="crpModelCode" className={classNames({ 'p-error': isFormFieldValid('crpModelCode') }, 'Label__Text')}>
                                    CRP Model Code
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="crpModelCode"
                                    placeholder="Enter CRP Code"
                                    name="crpModelCode"
                                    maxLength={100}
                                    value={formik?.values?.crpModelCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('crpModelCode') }, 'Input__Round')}
                                    disabled
                                />
                                {getFormErrorMessage('crpModelCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="crpModelName" className={classNames({ 'p-error': isFormFieldValid('crpModelName') }, 'Label__Text')}>
                                    CRP Model Name
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="crpModelName"
                                    placeholder="Enter Name"
                                    name="crpModelName"
                                    maxLength={100}
                                    value={formik?.values?.crpModelName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('crpModelName') }, 'Input__Round')}
                                    disabled
                                />
                                {getFormErrorMessage('crpModelName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="crpModelDescription" className={classNames({ 'p-error': isFormFieldValid('crpModelDescription') }, 'Label__Text')}>
                                    Description
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    rows={5}
                                    cols={30}
                                    id="crpModelDescription"
                                    name="crpModelDescription"
                                    value={formik?.values?.crpModelDescription || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('crpModelDescription') }, 'TextArea__Round')}
                                    disabled
                                />

                                {getFormErrorMessage('crpModelDescription')}
                            </div>
                        </div>
                    </div>

                    {/* Customer Type */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h2 style={{ color: '#fff', marginLeft: '10px' }}>CUSTOMER TYPE</h2>
                    </div>
                    <div className="Crp__Model__Fields" style={{ background: '#f8f8f8' }}>
                        <div className="grid Crp__Model__Heading">
                            <div class="col-3" style={{ fontWeight: '700' }}>
                                RISK FACTOR TYPE
                            </div>
                            <div class="col-3" style={{ fontWeight: '700' }}>
                                SUB SECTION WEIGHTAGE
                            </div>
                            <div class="col-3" style={{ fontWeight: '700' }}>
                                SECTION WEIGHTAGE
                            </div>
                            <div class="col-3" style={{ fontWeight: '700' }}>
                                RISK OVER-RIDE RULE
                            </div>
                        </div>
                        <div class="grid nested-grid">
                            <div class="col-6">
                                <div class="grid">
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Customer Type`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="customerTypeSubSection"
                                                    placeholder="Enter Description"
                                                    name="customerTypeSubSection"
                                                    maxLength={100}
                                                    value={formik?.values?.customerTypeSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('customerTypeSubSection') }, 'Input__Round')}
                                                    disabled
                                                />
                                                {getFormErrorMessage('customerTypeSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-3 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Occupation`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="occupationSubSection"
                                                    placeholder="Enter Description"
                                                    name="occupationSubSection"
                                                    maxLength={100}
                                                    value={formik?.values?.occupationSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('occupationSubSection') }, 'Input__Round')}
                                                    disabled
                                                />
                                                {getFormErrorMessage('occupationSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Is Beneficial owner other than customer (yes / no)`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="beneficialCustomerSubSection"
                                                    placeholder="Enter Description"
                                                    name="beneficialCustomerSubSection"
                                                    maxLength={100}
                                                    value={formik?.values?.beneficialCustomerSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('beneficialCustomerSubSection') }, 'Input__Round')}
                                                    disabled
                                                />
                                                {getFormErrorMessage('beneficialCustomerSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Source of funds`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="sourceOfFundSubSection"
                                                    placeholder="Enter Description"
                                                    name="sourceOfFundSubSection"
                                                    maxLength={100}
                                                    value={formik?.values?.sourceOfFundSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('sourceOfFundSubSection') }, 'Input__Round')}
                                                    disabled
                                                />
                                                {getFormErrorMessage('sourceOfFundSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Purpose of A/C`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="purposeOfAcSubSection"
                                                    placeholder="Enter Description"
                                                    name="purposeOfAcSubSection"
                                                    maxLength={100}
                                                    value={formik?.values?.purposeOfAcSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('purposeOfAcSubSection') }, 'Input__Round')}
                                                    disabled
                                                />
                                                {getFormErrorMessage('purposeOfAcSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Is Adverse media /sanction list match/ internal risk assessment/LEAs inquiry found? (yes or no) `}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="adverseMediaSubSection"
                                                    placeholder="Enter Description"
                                                    name="adverseMediaSubSection"
                                                    maxLength={100}
                                                    value={formik?.values?.adverseMediaSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('adverseMediaSubSection') }, 'Input__Round')}
                                                    disabled
                                                />
                                                {getFormErrorMessage('adverseMediaSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">
                                                    {`Is customer  the director/ member of governing body/ trustee of NGO/ NPO/ charities/ clubs/ trusts/ societies/ Associations/ Exchange Co/ other high risk segment (yes / no)`}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="customerTheDirectorSubSection"
                                                    placeholder="Enter Description"
                                                    name="customerTheDirectorSubSection"
                                                    maxLength={100}
                                                    value={formik?.values?.customerTheDirectorSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('customerTheDirectorSubSection') }, 'Input__Round')}
                                                    disabled
                                                />
                                                {getFormErrorMessage('customerTheDirectorSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Other nationalities/ residencies (yes / no)`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="nationalitiesSubSection"
                                                    placeholder="Enter Description"
                                                    name="nationalitiesSubSection"
                                                    maxLength={100}
                                                    value={formik?.values?.nationalitiesSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('nationalitiesSubSection') }, 'Input__Round')}
                                                    disabled
                                                />
                                                {getFormErrorMessage('nationalitiesSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Is customer a PEP  (if PEP selected as Yes then LOV  selection is mandatory)`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="customerPepSubSection"
                                                    placeholder="Enter Description"
                                                    name="customerPepSubSection"
                                                    maxLength={100}
                                                    value={formik?.values?.customerPepSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('customerPepSubSection') }, 'Input__Round')}
                                                    disabled
                                                />
                                                {getFormErrorMessage('customerPepSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                    <div className="p-field">
                                        <InputTextarea
                                            maxLength={500}
                                            rows={32}
                                            cols={30}
                                            id="customerType"
                                            name="customerType"
                                            autoResize
                                            value={formik?.values?.customerType || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('customerType') }, 'TextArea__Round Crp__Model__Textarea_Size')}
                                            disabled
                                        />

                                        {getFormErrorMessage('customerType')}
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 ">
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="customerTypeRiskOverRideRule"
                                                name="customerTypeRiskOverRideRule"
                                                value={formik.values.customerTypeRiskOverRideRule}
                                                checked={formik.values.customerTypeRiskOverRideRule}
                                                onChange={formik.handleChange}
                                                disabled
                                            />
                                        </div>
                                        <label htmlFor="customerTypeRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('customerTypeRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('customerTypeRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="occupationRiskOverRideRule"
                                                name="occupationRiskOverRideRule"
                                                value={formik.values.occupationRiskOverRideRule}
                                                checked={formik.values.occupationRiskOverRideRule}
                                                onChange={formik.handleChange}
                                                disabled
                                            />
                                        </div>
                                        <label htmlFor="occupationRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('occupationRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('occupationRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="beneficialCustomerRiskOverRideRule"
                                                name="beneficialCustomerRiskOverRideRule"
                                                value={formik.values.beneficialCustomerRiskOverRideRule}
                                                checked={formik.values.beneficialCustomerRiskOverRideRule}
                                                onChange={formik.handleChange}
                                                disabled
                                            />
                                        </div>
                                        <label htmlFor="beneficialCustomerRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('beneficialCustomerRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('beneficialCustomerRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="sourceOfFundRiskOverRideRule"
                                                name="sourceOfFundRiskOverRideRule"
                                                value={formik.values.sourceOfFundRiskOverRideRule}
                                                checked={formik.values.sourceOfFundRiskOverRideRule}
                                                onChange={formik.handleChange}
                                                disabled
                                            />
                                        </div>
                                        <label htmlFor="sourceOfFundRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('sourceOfFundRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('sourceOfFundRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="purposeOfAcRiskOverRideRule"
                                                name="purposeOfAcRiskOverRideRule"
                                                value={formik.values.purposeOfAcRiskOverRideRule}
                                                checked={formik.values.purposeOfAcRiskOverRideRule}
                                                onChange={formik.handleChange}
                                                disabled
                                            />
                                        </div>
                                        <label htmlFor="purposeOfAcRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('purposeOfAcRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('purposeOfAcRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="adverseMediaRiskOverRideRule"
                                                name="adverseMediaRiskOverRideRule"
                                                value={formik.values.adverseMediaRiskOverRideRule}
                                                checked={formik.values.adverseMediaRiskOverRideRule}
                                                onChange={formik.handleChange}
                                                disabled
                                            />
                                        </div>
                                        <label htmlFor="adverseMediaRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('adverseMediaRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('adverseMediaRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="customerTheDirectorRiskOverRideRule"
                                                name="customerTheDirectorRiskOverRideRule"
                                                value={formik.values.customerTheDirectorRiskOverRideRule}
                                                checked={formik.values.customerTheDirectorRiskOverRideRule}
                                                onChange={formik.handleChange}
                                                disabled
                                            />
                                        </div>
                                        <label htmlFor="customerTheDirectorRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('customerTheDirectorRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('customerTheDirectorRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="nationalitiesRiskOverRideRule"
                                                name="nationalitiesRiskOverRideRule"
                                                value={formik.values.nationalitiesRiskOverRideRule}
                                                checked={formik.values.nationalitiesRiskOverRideRule}
                                                onChange={formik.handleChange}
                                                disabled
                                            />
                                        </div>
                                        <label htmlFor="nationalitiesRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('nationalitiesRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('nationalitiesRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="customerPepRiskOverRideRule"
                                                name="customerPepRiskOverRideRule"
                                                value={formik.values.customerPepRiskOverRideRule}
                                                checked={formik.values.customerPepRiskOverRideRule}
                                                onChange={formik.handleChange}
                                                disabled
                                            />
                                        </div>
                                        <label htmlFor="customerPepRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('customerPepRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('customerPepRiskOverRideRule')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div class="col-12 flex">
                            <div class="col-3">
                                <h4>Total</h4>
                            </div>
                            <div class="col-3">
                                <h4>{isNaN(sumOfCustomerTypeFields) ? ' ' : sumOfCustomerTypeFields?.toFixed(1)}</h4>
                            </div>
                            <div class="col-6"></div>
                        </div>
                    </div>

                    {/* Prodcuts and Services */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h2 style={{ color: '#fff', marginLeft: '10px' }}>PRODUCTS AND SERVICES</h2>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5 Crp__Model__Fields" style={{ background: '#f8f8f8' }}>
                        <div className="p-field col-12 md:col-3 flex align-items-center justify-content-left">
                            <div className="p-field">
                                <p className="Label__Text">{`Products and Services`}</p>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                            <div className="p-field">
                                <InputText
                                    id="productsAndServices"
                                    placeholder="Enter Description"
                                    name="productsAndServices"
                                    maxLength={100}
                                    value={formik?.values?.productsAndServices || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productsAndServices') }, 'Input__Round')}
                                    disabled
                                />
                                {getFormErrorMessage('productsAndServices')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                            <div className="p-field">
                                <InputTextarea
                                    maxLength={500}
                                    rows={1}
                                    cols={30}
                                    id="sectionWeigtage"
                                    name="sectionWeigtage"
                                    autoResize
                                    value={formik?.values?.sectionWeigtage || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('sectionWeigtage') }, 'TextArea__Round Crp__Model__Textarea')}
                                    disabled
                                />

                                {getFormErrorMessage('sectionWeigtage')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                            <div className="p-field flex">
                                <div className="field-checkbox">
                                    <Checkbox
                                        inputId="isActiveproductsAndServices"
                                        name="isActiveproductsAndServices"
                                        value={formik.values.isActiveproductsAndServices}
                                        checked={formik.values.isActiveproductsAndServices}
                                        onChange={formik.handleChange}
                                        disabled
                                    />
                                </div>
                                <label htmlFor="isActiveproductsAndServices" className={classNames({ 'p-error': isFormFieldValid('isActiveproductsAndServices') }, 'Label__Text')}>
                                    Yes
                                </label>
                                {getFormErrorMessage('isActiveproductsAndServices')}
                            </div>
                        </div>
                    </div>

                    {/* Channels */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h2 style={{ color: '#fff', marginLeft: '10px' }}>CHANNELS</h2>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5 Crp__Model__Fields" style={{ background: '#f8f8f8' }}>
                        <div className="p-field col-12 md:col-3 flex align-items-center justify-content-left">
                            <div className="p-field">
                                <p className="Label__Text">{`Channels`}</p>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                            <div className="p-field">
                                <InputText
                                    id="channel"
                                    placeholder="Enter Description"
                                    name="channel"
                                    maxLength={100}
                                    value={formik?.values?.channel || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channel') }, 'Input__Round')}
                                    disabled
                                />
                                {getFormErrorMessage('channel')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                            <div className="p-field">
                                <InputTextarea
                                    maxLength={500}
                                    rows={1}
                                    cols={30}
                                    id="C"
                                    name="channelWeigtage"
                                    autoResize
                                    value={formik?.values?.channelWeigtage || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelWeigtage') }, 'TextArea__Round Crp__Model__Textarea')}
                                    disabled
                                />

                                {getFormErrorMessage('channelWeigtage')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                            <div className="p-field flex">
                                <div className="field-checkbox">
                                    <Checkbox inputId="isActivechannel" name="isActivechannel" value={formik.values.isActivechannel} checked={formik.values.isActivechannel} onChange={formik.handleChange} disabled />
                                </div>
                                <label htmlFor="isActivechannel" className={classNames({ 'p-error': isFormFieldValid('isActivechannel') }, 'Label__Text')}>
                                    Yes
                                </label>
                                {getFormErrorMessage('isActivechannel')}
                            </div>
                        </div>
                    </div>

                    {/* Geographies both local and international */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h2 style={{ color: '#fff', marginLeft: '10px' }}>GEOGRAPHIES: BOTH LOCAL AND INTERNATIONAL</h2>
                    </div>
                    <div className="Crp__Model__Fields" style={{ background: '#f8f8f8' }}>
                        <div className="grid Crp__Model__Heading">
                            <div class="col-3" style={{ fontWeight: '700' }}>
                                RISK FACTOR TYPE
                            </div>
                            <div class="col-3" style={{ fontWeight: '700' }}>
                                SUB SECTION WEIGHTAGE
                            </div>
                            <div class="col-3" style={{ fontWeight: '700' }}>
                                SECTION WEIGHTAGE
                            </div>
                            <div class="col-3" style={{ fontWeight: '700' }}>
                                RISK OBER RIDE RULE
                            </div>
                        </div>
                        <div class="grid nested-grid" style={{ background: '#f8f8f8' }}>
                            <div class="col-6">
                                <div class="grid">
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Trading / counter parties countries: Pakistan or other country`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="tradingRiskFactoryType"
                                                    placeholder="Enter Description"
                                                    name="tradingRiskFactoryType"
                                                    maxLength={100}
                                                    value={formik?.values?.tradingRiskFactoryType || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('tradingRiskFactoryType') }, 'Input__Round')}
                                                    disabled
                                                />
                                                {getFormErrorMessage('tradingRiskFactoryType')}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Permanent Address`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="permanentAddressFactoryType"
                                                    placeholder="Enter Description"
                                                    name="permanentAddressFactoryType"
                                                    maxLength={100}
                                                    value={formik?.values?.permanentAddressFactoryType || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('permanentAddressFactoryType') }, 'Input__Round')}
                                                    disabled
                                                />
                                                {getFormErrorMessage('permanentAddressFactoryType')}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Mailing Address/ Geo-Location`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="mailingAddressFactoryType"
                                                    placeholder="Enter Description"
                                                    name="mailingAddressFactoryType"
                                                    maxLength={100}
                                                    value={formik?.values?.mailingAddressFactoryType || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('mailingAddressFactoryType') }, 'Input__Round')}
                                                    disabled
                                                />
                                                {getFormErrorMessage('mailingAddressFactoryType')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                    <div className="p-field">
                                        <InputTextarea
                                            maxLength={3}
                                            rows={8}
                                            cols={30}
                                            id="geographiesWeigtage"
                                            name="geographiesWeigtage"
                                            autoResize
                                            value={formik?.values?.geographiesWeigtage || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('geographiesWeigtage') }, 'TextArea__Round Crp__Model__Textarea Crp__Model__Geographies__TextArea')}
                                            disabled
                                        />

                                        {getFormErrorMessage('geographiesWeigtage')}
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox inputId="isActivetradingRisk" name="isActivetradingRisk" value={formik.values.isActivetradingRisk} checked={formik.values.isActivetradingRisk} onChange={formik.handleChange} disabled />
                                        </div>
                                        <label htmlFor="isActivetradingRisk" className={classNames({ 'p-error': isFormFieldValid('isActivetradingRisk') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('isActivetradingRisk')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="isActivepermanentAddress"
                                                name="isActivepermanentAddress"
                                                value={formik.values.isActivepermanentAddress}
                                                checked={formik.values.isActivepermanentAddress}
                                                onChange={formik.handleChange}
                                                disabled
                                            />
                                        </div>
                                        <label htmlFor="isActivepermanentAddress" className={classNames({ 'p-error': isFormFieldValid('isActivepermanentAddress') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('isActivepermanentAddress')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="isActivemailingAddress"
                                                name="isActivemailingAddress"
                                                value={formik.values.isActivemailingAddress}
                                                checked={formik.values.isActivemailingAddress}
                                                onChange={formik.handleChange}
                                                disabled
                                            />
                                        </div>
                                        <label htmlFor="isActivemailingAddress" className={classNames({ 'p-error': isFormFieldValid('isActivemailingAddress') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('isActivemailingAddress')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div class="col-12 flex">
                            <div class="col-3">
                                <h4>Total</h4>
                            </div>
                            <div class="col-3">
                                <h4>{isNaN(sumOfGeographies) ? ' ' : sumOfGeographies?.toFixed(1)}</h4>
                            </div>
                            <div class="col-6"></div>
                        </div>
                    </div>

                    {/* Anticipated Transactions Profile */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h2 style={{ color: '#fff', marginLeft: '10px' }}>ANTICIPATED TRANSACTIONS PROFILE</h2>
                    </div>
                    <div className="Crp__Model__Fields" style={{ background: '#f8f8f8' }}>
                        <div className="grid Crp__Model__Heading">
                            <div class="col-3" style={{ fontWeight: '700' }}>
                                RISK FACTOR TYPE
                            </div>
                            <div class="col-3" style={{ fontWeight: '700' }}>
                                SUB SECTION WEIGHTAGE
                            </div>
                            <div class="col-3" style={{ fontWeight: '700' }}>
                                SECTION WEIGHTAGE
                            </div>
                            <div class="col-3" style={{ fontWeight: '700' }}>
                                RISK OBER RIDE RULE
                            </div>
                        </div>
                        <div class="grid nested-grid" style={{ background: '#f8f8f8' }}>
                            <div class="col-6">
                                <div class="grid">
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Expected aggregate Debit amount per Month`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="aggregateDebitAmount"
                                                    placeholder="Enter Description"
                                                    name="aggregateDebitAmount"
                                                    maxLength={100}
                                                    value={formik?.values?.aggregateDebitAmount || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('aggregateDebitAmount') }, 'Input__Round')}
                                                    disabled
                                                />
                                                {getFormErrorMessage('aggregateDebitAmount')}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Expected aggregate Credit amount per Month`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="aggregateCreditAmount"
                                                    placeholder="Enter Description"
                                                    name="aggregateCreditAmount"
                                                    maxLength={100}
                                                    value={formik?.values?.aggregateCreditAmount || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('aggregateCreditAmount') }, 'Input__Round')}
                                                    disabled
                                                />
                                                {getFormErrorMessage('aggregateCreditAmount')}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Expected Debit Count per month`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="debitAmount"
                                                    placeholder="Enter Description"
                                                    name="debitAmount"
                                                    maxLength={100}
                                                    value={formik?.values?.debitAmount || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('debitAmount') }, 'Input__Round')}
                                                    disabled
                                                />
                                                {getFormErrorMessage('debitAmount')}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Expected Credit Count per Month`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="creditAmount"
                                                    placeholder="Enter Description"
                                                    name="creditAmount"
                                                    maxLength={100}
                                                    value={formik?.values?.creditAmount || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('creditAmount') }, 'Input__Round')}
                                                    disabled
                                                />
                                                {getFormErrorMessage('creditAmount')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                    <div className="p-field">
                                        <InputTextarea
                                            maxLength={3}
                                            rows={12}
                                            cols={30}
                                            id="transctionProfileWeigtage"
                                            name="transctionProfileWeigtage"
                                            autoResize
                                            value={formik?.values?.transctionProfileWeigtage || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('transctionProfileWeigtage') }, 'TextArea__Round Crp__Model__Textarea Crp__Model__Transactions__TextArea')}
                                            disabled
                                        />

                                        {getFormErrorMessage('transctionProfileWeigtage')}
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="isActiveAggregateDebitAmount"
                                                name="isActiveAggregateDebitAmount"
                                                value={formik.values.isActiveAggregateDebitAmount}
                                                checked={formik.values.isActiveAggregateDebitAmount}
                                                onChange={formik.handleChange}
                                                disabled
                                            />
                                        </div>
                                        <label htmlFor="isActiveAggregateDebitAmount" className={classNames({ 'p-error': isFormFieldValid('isActiveAggregateDebitAmount') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('isActiveAggregateDebitAmount')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="isActiveAggregateCreditAmount"
                                                name="isActiveAggregateCreditAmount"
                                                value={formik.values.isActiveAggregateCreditAmount}
                                                checked={formik.values.isActiveAggregateCreditAmount}
                                                onChange={formik.handleChange}
                                                disabled
                                            />
                                        </div>
                                        <label htmlFor="isActiveAggregateCreditAmount" className={classNames({ 'p-error': isFormFieldValid('isActiveAggregateCreditAmount') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('isActiveAggregateCreditAmount')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox inputId="isActiveDebitAmount" name="isActiveDebitAmount" value={formik.values.isActiveDebitAmount} checked={formik.values.isActiveDebitAmount} onChange={formik.handleChange} disabled />
                                        </div>
                                        <label htmlFor="isActiveDebitAmount" className={classNames({ 'p-error': isFormFieldValid('isActiveDebitAmount') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('isActiveDebitAmount')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox inputId="isActivecreditAmount" name="isActivecreditAmount" value={formik.values.isActivecreditAmount} checked={formik.values.isActivecreditAmount} onChange={formik.handleChange} disabled />
                                        </div>
                                        <label htmlFor="isActivecreditAmount" className={classNames({ 'p-error': isFormFieldValid('isActivecreditAmount') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('isActivecreditAmount')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div class="col-12 flex">
                            <div class="col-3">
                                <h4>Total</h4>
                            </div>
                            <div class="col-3">
                                <h4>{isNaN(sumOfTransactionProfile) ? ' ' : sumOfTransactionProfile?.toFixed(1)}</h4>
                            </div>
                            <div class="col-6"></div>
                        </div>
                    </div>

                    {/* TOTAL RISK SCORE */}

                    <div className="Form__Header" style={{ background: '#979c9b', borderRadius: '5px', paddingTop: '12px' }}>
                        <div className="p-fluid p-formgrid grid w-full">
                            <div className="p-field col-12 md:col-3 flex align-items-center">
                                <h2 style={{ color: '#fff', margin: '0px 0px 0px 10px' }}>TOTAL RISK SCORE</h2>
                            </div>
                            <div className="p-field col-12 md:col-3 flex align-items-center">
                                <p style={{ color: 'white' }}>{totalOfAllFields || 0}</p>
                            </div>
                            <div className="p-field col-12 md:col-3 flex align-items-center">
                                <p style={{ color: 'white' }}>{sumOfSectionWeigtage + '%'}</p>
                            </div>
                            <div className="p-field col-12 md:col-3 flex align-items-center"></div>
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-3 flex align-items-center">
                        <h5 style={{ color: '#979c9b', margin: '0px 0px 0px 10px' }}>Risk Category Criteria</h5>
                    </div>

                    <div className="grid">
                        <div className="col-12">
                            <div className="card" style={{ background: '#f8f8f8' }}>
                                <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={riskCategoryCriteria}>
                                    <Column field="riskScore" header="Risk Score" style={{ width: '33%' }} />
                                    <Column header="Rating" style={{ width: '33%' }} body={(rowData) => handleRatingBodyTemplate(rowData)} />
                                    <Column field="inTerms" header="InTerms of % of Risk Scores" style={{ width: '33%' }} />
                                </DataTable>
                            </div>
                        </div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    rows={5}
                                    cols={30}
                                    maxLength={200}
                                    autoResize
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button disabled={((!formik.values.action || !formik.values.checkerComments) && true) || loading} loadingIcon={loadingIcon || ''} label="Update" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default EditCRPModelChecker;

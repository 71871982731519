import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import classNames from 'classnames';
import base64 from 'base64-js';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { loadingAction } from '../../../../redux/actions/loadingAction';
import DynamicKYCFields from '../DynamicKYC/DynamicKYCFields';
import { baseURL } from '../../../../Config';
import ScrollMover from '../../../../components/ScrollMover';
import { Dialog } from 'primereact/dialog';
import AccountStatementDialog from './AccountStatementDialog';
import CloseAccountDialog from './CloseAccountDialog';
import AccountSettelmentDialog from './AccountSettelmentDialog';
import RemarksDailog from './RemarksDailog';

function EditViewRetailer({ apiData, isEditable, docData }) {
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [loading, setloading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [kycFields, setKycFields] = useState([]);
    const [pricingProfile, setPricingProfile] = useState([]);
    // const [commissionProfile, setCommissionProfile] = useState([]);
    // const [commissionHierarchy, setCommissionHierarchy] = useState([]);
    const [limitProfile, setLimitProfile] = useState([]);
    const [productCatalog, setProductcatalog] = useState([]);
    const [employeeName, setEmployeeName] = useState('');
    const [channelOption, setChannelOption] = useState([]);
    const [parent, setParent] = useState([]);
    const [apiParentId, setApiParentId] = useState('');
    const [apiGetParentId, setApiGetParentId] = useState('');
    const [searchBtnDisable, setSearchBtnDisable] = useState(true);
    const kfsRef = useRef(null);
    const [empIdData, setEmpIdData] = useState([]);

    const digitalSigningRef = useRef(null);
    const [kfsInput, setKfsInput] = useState('');
    const [kfsInputFile, setKfsInputFile] = useState('');
    const [kfsInputBase64, setKfsInputBase64] = useState('');
    const [kfsLink, setKfsLink] = useState('');
    const [digitalSigningInput, setDigitalSigningInput] = useState('');
    const [digitalSigningFile, setDigitalSigningFile] = useState('');
    const [digitalSigningInputBase64, setDigitalSigningInputBase64] = useState('');
    const [digitalSigningLink, setDigitalSigningLink] = useState('');
    const [kfsFileError, setKfsFileError] = useState('');
    const [dsFileError, setDsFileError] = useState('');
    const [useCaseError, setUseCaseError] = useState({
        empId: null
    });
    const [tblKycSetDetails, setTblKycSetDetails] = useState([]);
    const [show, setShow] = useState(false);
    const [showBtn, setShowBtn] = useState(true);
    const [accountStatus, setAccontStatus] = useState();
    const [accountStatementDialogOpen, setAccountStatementDialogOpen] = useState(false);
    const [accountSettleDialogOpen, setAccountSettleDialogOpen] = useState(false);
    const [closeAccountDialog, setCloseAccountDialog] = useState(false);

    const input1 = useRef(null);
    const input2 = useRef(null);
    const input3 = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const lovId = apiData?.lkpAccountClassification?.accountClassificationId;
    const name = apiData?.lkpAccountClassification?.accountClassificationName;
    const accountClassificationId = lovId;
    const kycFixed = ['cnic', 'mobileNumber', 'cnicIssuanceDate'];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button type="button" disabled onClick={handleDelete} tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" className="p-button-rounded p-button-danger" />
            </div>
        );
    };

    function getMaxRegexLength(regex) {
        // Remove leading and trailing slashes from the regular expression.
        const regexPattern = regex.toString().slice(1, -1);

        // Use a regular expression to find the maximum length.
        const lengthRegex = /\{(\d+)(,(\d+))?\}/g;
        let maxLength = 0;

        let match;
        while ((match = lengthRegex.exec(regexPattern)) !== null) {
            const min = parseInt(match[1], 10);
            const max = match[3] ? parseInt(match[3], 10) : min;
            if (max > maxLength) {
                maxLength = max;
            }
        }

        return maxLength;
    }

    //validations
    const validationSchema = Yup.object({
        pricingProfileId: Yup.string().required('This Field is required'),
        // commissionId: Yup.string().required('This Field is required'),
        // commissionHierarchyId: Yup.string().required('This Field is required'),
        transLimitId: Yup.string().required('This Field is required'),
        // parentAgentId: Yup.string().required('Select any of these'),
        channelIdList: Yup.array().required('This Field is required').min(1, 'This Field is required'),
        empId: Yup.string().required('This Field is required'),
        hierarchyName: Yup.string().required('This Field is required'),
        productCatalogId: Yup.string().required('This Field is required'),
        chequeBook: Yup.boolean(),
        debitCard: Yup.boolean(),
        kfsInput: Yup.string().required('This Field is required'),
        digitalSigningInput: Yup.string().when('chequeBook', {
            is: true,
            then: Yup.string().required('This Field is required'),
            otherwise: Yup.string().notRequired()
        }),
        noOfLeaves: Yup.string().when('chequeBook', {
            is: true,
            then: Yup.string().required('This Field is required'),
            otherwise: Yup.string().notRequired()
        }),
        debitCardName: Yup.string().when('debitCard', {
            is: true,
            then: Yup.string()
                .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
                .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
                .required('This Field is required'),
            otherwise: Yup.string().notRequired()
        }),
        debitCardAddress: Yup.string().when('debitCard', {
            is: true,
            then: Yup.string().required('This Field is required'),
            otherwise: Yup.string().notRequired()
        }),
        ...tblKycSetDetails.reduce((fieldValidations, result) => {
            const attributeName = result?.tblKycAttribute?.attributeName;
            const regexExpression = result?.tblKycAttribute;
            const date = result?.tblKycAttribute?.attributeName.toLowerCase().includes('date');
            const regExp = new RegExp(regexExpression.tblValidator.argumentValue);
            const maxLength = getMaxRegexLength(regExp);

            if (kycFixed?.includes(result?.tblKycAttribute?.attributeName) || (result.isMandatory === 'Y' && result?.isActive === 'Y')) {
                // Validate that the field is not empty
                fieldValidations[attributeName] = Yup.string().test('dynamicValidation', 'Invalid Format', function (value) {
                    // Check if a value is entered and apply regular expression validation
                    if (value) {
                        if (value.length > maxLength && !date) {
                            if (maxLength > 0) {
                                return this.createError({
                                    path: this.path,
                                    message: `Value exceeds the maximum length of ${maxLength}`
                                });
                            }
                        }

                        return regExp.test(value);
                    } else {
                        // If no value is entered and the field is mandatory, show it as required
                        if (kycFixed?.includes(result?.tblKycAttribute?.attributeName) || result.isMandatory === 'Y') {
                            return this.createError({
                                path: this.path,
                                message: 'This Field is required'
                            });
                        }

                        // If no value is entered and the field is not mandatory, no error is shown.
                        return true;
                    }
                });
            } else if (result?.isActive === 'Y') {
                // If the item is not mandatory but active, only apply the regex validation
                fieldValidations[attributeName] = Yup.string().test('dynamicValidation', 'Invalid Format', function (value) {
                    if (value) {
                        // Check if value is not empty
                        if (value.length > maxLength && !date) {
                            if (maxLength > 0) {
                                return this.createError({
                                    path: this.path,
                                    message: `Value exceeds the maximum length of ${maxLength}`
                                });
                            }
                        }

                        return regExp.test(value);
                    }
                    // If the value is empty, no error is shown.
                    return true;
                });
            }

            return fieldValidations;
        }, {})
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            pricingProfileId: '',
            // commissionId: '',
            // commissionHierarchyId: '',
            transLimitId: '',
            parentAgentId: '',
            parentMobileNumber: '',
            ParentCnic: '',
            channelIdList: [],
            empId: '',
            hierarchyName: '',
            productCatalogId: '',
            chequeBook: '',
            debitCard: '',
            noOfLeaves: '',
            debitCardAddress: '',
            debitCardName: '',
            atmWaiver: 'N',
            chequeWaiver: 'N',
            bvs: '',
            kfsInput: '',
            digitalSigningInput: '',
            motherName: '',
            fatherName: '',
            addressInfos: [
                {
                    addressId: '',
                    fullAddress: ''
                }
            ],
            accountStatus: '',
            accountNumber: '',
            accountTitle: '',
            remarks: ''
        },

        onSubmit: async (data, actions) => {
            setloading(true);
            delete data['ecib'];
            delete data['crpScore'];
            delete data['crpRating'];
            delete data['birthPlace'];

            const arrayOfChannelIds = formik?.values?.channelIdList?.map((item) => {
                return parseInt(item?.lovId, 10) || 0;
            });
            if (!parent || parent?.length === 0 || parent == null) {
                return toast.warn('Please search parent data');
            }
            data['debitCard'] = formik?.values?.debitCard ? 'Y' : 'N';
            data['chequeBook'] = formik?.values?.chequeBook ? 'Y' : 'N';
            data['empId'] = empIdData;
            // data['commissionHierarchy'] = data.commissionHierarchyId;

            data['parentAgentId'] = parent?.agentId?.toString();
            const nonRequiredFields = [];
            kycFields[0]?.tblKycSetDetails?.forEach((item) => {
                if (!kycFixed?.includes(item?.tblKycAttribute?.attributeName) && item?.isActive === 'Y') {
                    nonRequiredFields.push({
                        infoKey: item?.tblKycAttribute?.attributeName,
                        infoValue: formik.values[item?.tblKycAttribute?.attributeName]
                    });
                }
            });
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data,
                        agentId: apiData?.agentId,
                        accountClassificationName: name,
                        contact: false,
                        channelIdList: arrayOfChannelIds
                    },
                    additionalInformation: nonRequiredFields,
                    checkSum: ''
                }
            };
            kycFields[0]?.tblKycSetDetails?.forEach((item) => {
                delete newData?.data?.payLoad[item?.tblKycAttribute?.attributeName];
            });
            // delete newData?.data?.payLoad?.commissionHierarchyId;
            delete newData?.data?.payLoad?.kfsInput;
            delete newData?.data?.payLoad?.digitalSigningInput;
            delete newData?.data?.payLoad?.hierarchyName;
            delete newData?.data?.payLoad?.ParentCnic;
            delete newData?.data?.payLoad?.parentMobileNumber;
            delete newData?.data?.payLoad?.parentAgentId;
            delete newData?.data?.payLoad?.noOfLeaves;
            delete newData?.data?.payLoad?.debitCardName;
            delete newData?.data?.payLoad?.debitCardAddress;
            delete newData?.data?.payLoad?.debitCard;
            // delete newData?.data?.payLoad?.commissionId;
            // delete newData?.data?.payLoad?.commissionHierarchyId;
            delete newData?.data?.payLoad?.chequeWaiver;
            delete newData?.data?.payLoad?.chequeBook;
            delete newData?.data?.payLoad?.atmWaiver;
            delete newData?.data?.payLoad?.bvs;

            formik.setFieldValue('parentAgentId', '');
            formik.setFieldValue('ParentCnic', '');
            formik.setFieldValue('parentMobileNumber', '');

            formik.setFieldValue('debitCard', data['debitCard'] === 'Y' ? true : false);
            formik.setFieldValue('chequeBook', data['chequeBook'] === 'Y' ? true : false);
            const res = await dispatch(handlePostRequest(newData, '/account/v1/customeraccounts/agentmaintenance', true, false, 'accountsmanagement'));
            if (res?.message.includes('successfully')) {
                addImages();
            } else {
            }
            setloading(false);
        }
    });

    useEffect(() => {
        if (apiData) {
            formik.setFieldValue('accountStatus', apiData?.accountStatus?.toString());
            formik.setFieldValue('ecib', apiData?.ecib);
            formik.setFieldValue('motherName', apiData?.motherName);
            formik.setFieldValue('accountNumber', apiData?.accountNumber);
            formik.setFieldValue('addressInfos', apiData?.addressInfos);
            formik.setFieldValue('accountTitle', apiData?.accountTitle);
            formik.setFieldValue('crpRating', apiData?.crpRating);
            formik.setFieldValue('crpScore', apiData?.crpScore);
            formik.setFieldValue('pricingProfileId', apiData?.tblPricingProfile?.pricingProfileId?.toString() || '');
            // formik.setFieldValue('commissionId', apiData?.tblCommissionProfile?.commissionProfileId?.toString() || '');
            // formik.setFieldValue('commissionHierarchyId', apiData?.tblCommissionHierarchy?.commissionHierarchyId?.toString() || '');
            formik.setFieldValue('transLimitId', apiData?.tblTransLimit?.transLimitId?.toString() || '');
            formik.setFieldValue('hierarchyName', apiData?.tblSalesHierarchy?.hierarchyName || '');
            formik.setFieldValue('empId', apiData?.tblSalesForce?.employeeId || '');
            setEmpIdData(apiData?.tblSalesForce?.employeeId || '');
            formik.setFieldValue('productCatalogId', apiData?.tblAgentChannelList[0]?.tblProductCatalog?.productCatalogId?.toString() || '');
            formik.setFieldValue('chequeBook', apiData?.chequeBook === 'Y');
            formik.setFieldValue('debitCard', apiData?.debitCard === 'Y');
            formik.setFieldValue('noOfLeaves', apiData?.noOfLeaves || '');
            formik.setFieldValue('debitCardAddress', apiData?.debitCardAddress || '');
            formik.setFieldValue('debitCardName', apiData?.debitCardName || '');
            formik.setFieldValue('chequeWaiver', apiData?.chequeWaiver || '');
            formik.setFieldValue('atmWaiver', apiData?.atmWaiver || '');
            formik.setFieldValue('birthPlace', apiData?.birthPlace || '');
            formik.setFieldValue('bvs', (apiData?.bvs === 'N' || apiData?.bvs == null ? 'N' : 'Y') || '');

            // formik.setFieldValue('parentAgentId', apiData?.parentAgentId);
            setApiParentId(apiData?.parentAgentId);
            setApiGetParentId(apiData?.parentAgentId);
        }
    }, [apiData]);
    useEffect(() => {
        if (apiGetParentId) {
            getParentData();
        }
    }, [apiGetParentId]);
    useEffect(() => {
        if (kycFields && apiData?.kycView) {
            kycFields[0]?.tblKycSetDetails?.forEach((item) => {
                formik?.setFieldValue(item?.tblKycAttribute?.attributeName, JSON?.parse(apiData?.kycView)[item?.tblKycAttribute?.attributeName] || '');
            });
        }
    }, [kycFields, apiData?.kycView]);

    //cheque book leaves dropdown
    const chequeBookLeaves = [
        { name: '25', value: '25' },
        { name: '50', value: '50' },
        { name: '100', value: '100' },
        { name: '250', value: '250' }
    ];

    //channel dropdown options API
    const getChannel = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchannels/LKP_CHANNEL:*');
        setChannelOption(res?.payLoad);
    };

    const panelFooterTemplate = () => {
        const length = formik.values.channelIdList ? formik.values.channelIdList.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    //commission profile dropdown options API
    // const getCommissionProfile = async () => {
    //     const res = await handleGetRequest(`/configuration/v1/lookups/getagentdatabyaccounttype/TBL_COMMISSION_PROFILE/${accountClassificationId}`);
    //     setCommissionProfile(res?.payLoad);
    // };

    //pricing profile dropdown options API
    const getPricingProfile = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getagentdatabyaccounttype/TBL_PRICING_PROFILE/${accountClassificationId}`);
        setPricingProfile(res?.payLoad);
    };

    //limit profile dropdown options API
    const getLimitProfile = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getagentdatabyaccounttype/TBL_TRANS_LIMIT/${accountClassificationId}`);
        setLimitProfile(res?.payLoad);
    };

    //product catalog dropdown options API
    const getProductCatalog = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/productCatalog/TBL_PRODUCT_CATALOG:*');
        setProductcatalog(res?.payLoad);
    };

    //commission Hierarchy dropdown options API
    // const getCommissionHierarchy = async () => {
    //     const res = await handleGetRequest('/configuration/v1/lookups/getagentdatabyaccounttype/TBL_COMMISSION_HIERARCHY');
    //     setCommissionHierarchy(res?.payLoad);
    // };

    //search on the basis of employee ID
    const searchEmployeeId = async () => {
        setUseCaseError({ empId: null });
        if (formik.values.empId === '') {
            setUseCaseError({ empId: 'This field is required' });
        } else {
            formik.setFieldValue('hierarchyName', '');
            const id = formik.values.empId;
            if (id) {
                dispatch(loadingAction(true));
                const res = await handleGetRequest(`/account/v1/sales/getemployeebyid/${id}`);
                if (res == undefined || res) setTimeout(() => dispatch(loadingAction(false)), 1000);
                if (res?.payLoad?.length) {
                    setEmpIdData(res?.payLoad[0]?.employeeId);
                    formik.setFieldValue('hierarchyName', res?.payLoad[0]?.salesHierarchyName);
                } else {
                    formik.setFieldValue('hierarchyName', '');
                }
            }
        }
    };

    const getKycFields = async () => {
        const res = await handleGetRequest(`/account/v1/agentaccount/getkycbyclassificationname/${name}`);
        setKycFields(res?.payLoad);
        let tblkyc = res?.payLoad[0].tblKycSetDetails;
        setTblKycSetDetails(tblkyc);
    };
    //get parent data
    const getParentData = async () => {
        const newdata = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    accountClassificationName: name,
                    cnic: formik.values.ParentCnic,
                    mobileNumber: formik.values.parentMobileNumber,
                    agentId: apiParentId ?? formik?.values?.parentAgentId
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newdata, '/account/v1/agentaccount/getparentagent'));
        setParent(res?.payLoad);
        formik.setFieldValue('parentAgentId', '');
        formik.setFieldValue('ParentCnic', '');
        formik.setFieldValue('parentMobileNumber', '');
    };

    //empty parent data table
    const handleDelete = () => {
        setParent([]);
        if (input1.current) {
            input1.current.value = '';
        }
        if (input2.current) {
            input2.current.value = '';
        }
        if (input3.current) {
            input3.current.value = '';
        }
    };

    useEffect(() => {
        if (apiData) {
            // getCommissionProfile();
            // getCommissionHierarchy();
            getLimitProfile();
            getPricingProfile();
            getKycFields();
            getProductCatalog();
            getChannel();
            const extractedChannelIds = apiData?.tblAgentChannelList
                ?.filter((entry) => entry?.isActive === 'Y')
                .map((entry) => ({
                    lovId: entry?.lkpChannel?.channelId?.toString()
                }));
            formik.setFieldValue('channelIdList', extractedChannelIds);
        }
    }, [apiData]);

    const handleKfsInput = () => {
        kfsRef.current.click();
    };

    const handleDigitalSigningInput = () => {
        digitalSigningRef.current.click();
    };

    const kfsChange = (event) => {
        setKfsInputFile('');
        formik.setFieldValue('kfsInput', '');
        setKfsFileError('');
        const selectedFile = event.target.files[0];

        if (selectedFile?.size > 10 * 1024 * 1024) {
            return setKfsFileError('File size should be less than 10mb');
        } else if (selectedFile) {
            setKfsLink(URL.createObjectURL(selectedFile));
            setKfsInput(selectedFile.name);
            formik.setFieldValue('kfsInput', selectedFile.name);
            setKfsInputFile(selectedFile);
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                setKfsInputBase64(base64Data);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const digitalSigningChange = (event) => {
        setDigitalSigningFile('');
        formik.setFieldValue('digitalSigningInput', '');
        setDsFileError('');
        const selectedFile = event.target.files[0];

        if (selectedFile?.size > 10 * 1024 * 1024) {
            return setDsFileError('File size should be less than 10mb');
        } else if (selectedFile) {
            setDigitalSigningLink(URL.createObjectURL(selectedFile));
            setDigitalSigningInput(selectedFile.name);
            formik.setFieldValue('digitalSigningInput', selectedFile.name);
            setDigitalSigningFile(selectedFile);
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                setDigitalSigningInputBase64(base64Data);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const addImages = async () => {
        const originalFileName = kfsInputFile?.name;
        const fileExtension = originalFileName?.split('.')?.pop();
        const newFileNameWithoutExtension = 'KEY_FACT';
        const newFileName = `${newFileNameWithoutExtension}.${fileExtension}`;
        const KEY_FACT = new File([kfsInputFile], newFileName);
        const originalFileName2 = digitalSigningFile?.name;
        const fileExtension2 = originalFileName2?.split('.')?.pop();
        const newFileNameWithoutExtension2 = 'DIGITAL_SIGNING';
        const newFileName2 = `${newFileNameWithoutExtension2}.${fileExtension2}`;
        const DIGITAL_SIGNING = new File([digitalSigningFile], newFileName2);
        const documentsRequest = {
            data: {
                security: {
                    userName: 'string',
                    password: 'string',
                    securityToken: 'string'
                },
                account: {
                    msidn: 'string',
                    iban: 'string',
                    bban: 'string',
                    pan: 'string',
                    currency: 'string'
                },
                channel: 'string',
                terminal: 'string',
                reterivalReferenceNumber: 'string',
                payLoad: {
                    cnic: apiData?.cnic
                },
                additionalInformation: [
                    {
                        infoKey: 'string',
                        infoValue: 'string'
                    }
                ],
                checkSum: 'string'
            }
        };
        const formData = new FormData();
        formData.append('documentsRequest', JSON.stringify(documentsRequest));
        formData.append('files', KEY_FACT);
        formData.append('files', DIGITAL_SIGNING);
        // const res = await dispatch(handlePostRequest(formData, '/documents/v1/documents/uploadagentdocs', true, true));
    };

    const createFileFromBase64 = (apiResponse, type) => {
        const base64Data = apiResponse?.docBase64;
        const fileExtension = apiResponse?.documentExt;
        if (base64Data && fileExtension) {
            const binaryData = base64.toByteArray(base64Data);
            const blob = new Blob([binaryData], { type: 'application/octet-stream' });
            const fileName = `${type}${fileExtension}`;
            const file = new File([blob], fileName, {
                type: 'application/octet-stream'
            });

            if (type === 'KEY_FACT') {
                setKfsInput(fileName);
                formik.setFieldValue('kfsInput', fileName);
                setKfsInputFile(file);
                setKfsInputBase64(base64Data);
            } else if (type === 'DIGITAL_SIGNING') {
                setDigitalSigningInput(fileName);
                formik.setFieldValue('digitalSigningInput', fileName);
                setDigitalSigningFile(file);
                setDigitalSigningInputBase64(base64Data);
            }
        }
    };

    useEffect(() => {
        if (docData?.length > 0) {
            var KEY_FACT = docData?.filter((item) => item?.lkpDocumentType?.documentTypeDescr === 'KEY_FACT')[0];
            var DIGITAL_SIGNING = docData?.filter((item) => item?.lkpDocumentType?.documentTypeDescr === 'DIGITAL_SIGNING')[0];
            if (KEY_FACT) {
                createFileFromBase64(KEY_FACT, 'KEY_FACT');
                setKfsLink(`${baseURL}/document/${docData[0]?.documentPath.split('/documents/')[1]}`);
            }
            if (DIGITAL_SIGNING) {
                createFileFromBase64(DIGITAL_SIGNING, 'DIGITAL_SIGNING');
                setDigitalSigningLink(`${baseURL}/document/${docData[1]?.documentPath.split('/documents/')[1]}`);
            }
        }
    }, [docData]);

    function convertToTitleCase(inputString) {
        const words = inputString.split(/(?=[A-Z])/);
        const titleCaseString = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

        return titleCaseString;
    }

    const handleInputChange = (e) => {
        const inputValue = e.target.value.replace(/[^a-zA-Z\s]+/g, '').toUpperCase();
        formik.setFieldValue('debitCardName', inputValue);
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const handleEmployeeIdChange = (e) => {
        // Ensure it's a numeric value and limit to 4 characters
        const inputText = e.target.value.replace(/[^0-9]/g, '').slice(0, 7);
        formik.setFieldValue('empId', inputText);
    };

    const handleParentAgentId = (e) => {
        // Ensure it's a numeric value and limit to 4 characters
        const inputText = e.target.value.replace(/[^0-9]/g, '').slice(0, 7);
        formik.setFieldValue('parentAgentId', inputText);
        setApiParentId(inputText);
    };

    const handleParentCnic = (e) => {
        // Ensure it's a numeric value and limit to 4 characters
        const inputText = e.target.value.replace(/[^0-9]/g, '').slice(0, 13);
        formik.setFieldValue('ParentCnic', inputText);
    };

    const handleParentNumber = (e) => {
        // Ensure it's a numeric value and limit to 4 characters
        const inputText = e.target.value.replace(/[^0-9]/g, '').slice(0, 11);
        formik.setFieldValue('parentMobileNumber', inputText);
    };

    useEffect(() => {
        if (formik.values.parentAgentId || formik.values.ParentCnic || formik.values.parentMobileNumber) {
            setSearchBtnDisable(false);
        } else if (!isEditable) {
            setSearchBtnDisable(true);
        } else {
            setSearchBtnDisable(true);
        }
    }, [formik.values.parentAgentId, formik.values.ParentCnic, formik.values.parentMobileNumber]);

    const onHide = () => {
        setShow(true);
        setShowBtn(false);
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getaccountStatus = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getAccountStatus/LKP_ACCOUNT_STATUS:*');
        setAccontStatus(res?.payLoad);
    };

    useEffect(() => {
        getaccountStatus();
    }, []);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };
    const handleSetleAccount = () => {
        setAccountSettleDialogOpen(true);
    };
    const onHidesettleAccount = () => {
        setAccountSettleDialogOpen(false);
    };
    const handleCloseAccount = () => {
        setCloseAccountDialog(true);
    };
    const onHideCloseAccount = () => {
        setCloseAccountDialog(false);
    };

    const handleAccountStatement = () => {
        setAccountStatementDialogOpen(true);
    };
    const onHideAccountStatement = () => {
        setAccountStatementDialogOpen(false);
    };

    const handleGeneratePin = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    agentId: apiData?.agentId
                },
                additionalInformation: [],
                checkSum: ''
            }
        };

        setloading(true);
        const response = await dispatch(handlePostRequest(newData, '/account/v1/agentaccount/regenerateloginpin', true, false, 'accountsmanagement'));
        setloading(false);
    };

    const [remarksDialog, setRemarksDialog] = useState(false);

    const handleRemarksDialog = (e) => {
        e.preventDefault();
        setRemarksDialog(true);
    };

    const dialogData = (data) => {
        formik.setFieldValue('accountStatus', data?.accountStatus);
        formik.setFieldValue('remarks', data?.remarks);
    };
    const onHideRemarksDialog = () => {
        setRemarksDialog(false);
    };
    const remarksValue = formik.values.remarks;
    const statusValue = formik.values.accountStatus;

    return (
        <>
            <Dialog resizable={false} draggable={false} visible={remarksDialog} style={{ width: '30vw' }} onHide={onHideRemarksDialog}>
                <RemarksDailog dialogData={dialogData} onHideRemarksDialog={onHideRemarksDialog} remarksValue={remarksValue} statusValue={statusValue} />
            </Dialog>
            <Dialog resizable={false} draggable={false} visible={accountStatementDialogOpen} onHide={() => setAccountStatementDialogOpen(false)} header="Account Statement" style={{ width: '30vw' }}>
                <AccountStatementDialog onHideAccountStatement={onHideAccountStatement} cnic={apiData?.cnic} mobileNo={apiData?.agentPhoneNo} agentId={apiData?.agentId} accountNumber={apiData?.accountNumber} />
            </Dialog>
            <Dialog resizable={false} draggable={false} visible={closeAccountDialog} onHide={() => setCloseAccountDialog(false)} header="Close Account" style={{ width: '30vw' }}>
                <CloseAccountDialog onHideCloseAccount={onHideCloseAccount} agentId={apiData?.agentId} accountNo={apiData?.accountNumber} />
            </Dialog>
            <Dialog resizable={false} draggable={false} visible={accountSettleDialogOpen} onHide={() => setAccountStatementDialogOpen(false)} header="Account Settelment" style={{ width: '30vw' }}>
                <AccountSettelmentDialog onHidesettleAccount={onHidesettleAccount} agentId={apiData?.agentId} accountNo={apiData?.accountNumber} />
            </Dialog>
            <div className="my-3">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="custom-card p-3">
                        <div className="m-3 Form__Header Full__Width">
                            <p>RETAILER KYC SETS</p>
                        </div>
                        {kycFields && <DynamicKYCFields kycFields={kycFields} formik={formik} kycFixed={kycFixed} isEditable={isEditable} getFormErrorMessage={getFormErrorMessage} />}
                    </div>
                    {showBtn && isEditable && (
                        <div className="text-center mt-2 mb-2 flex justify-content-center">
                            <Button label="View more" type="button" className="view-button p-button-rounded" onClick={() => onHide()} />
                        </div>
                    )}

                    {(!isEditable || show) && (
                        <>
                            <div className="p-fluid p-formgrid grid mb-3">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="pricingProfileId" className="Label__Text">
                                            Pricing Profile
                                        </label>
                                        <Dropdown
                                            id="pricingProfileId"
                                            placeholder="Select Pricing Profile"
                                            options={pricingProfile}
                                            optionLabel="name"
                                            name="pricingProfileId"
                                            optionValue="lovId"
                                            value={formik.values.pricingProfileId || ''}
                                            disabled={!isEditable}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileId') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('pricingProfileId')}
                                    </div>
                                </div>
                                {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="commissionId" className="Label__Text">
                                            Commission Profile
                                        </label>
                                        <Dropdown
                                            id="commissionId"
                                            placeholder="Select Account Classification"
                                            options={commissionProfile}
                                            optionLabel="name"
                                            name="commissionId"
                                            optionValue="lovId"
                                            value={formik.values.commissionId || ''}
                                            disabled
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('commissionId') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('commissionId')}
                                    </div>
                                </div> */}
                                {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="commissionHierarchyId" className="Label__Text">
                                            Commission Hierarchy<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="commissionHierarchyId"
                                            placeholder="Select Commission Hierarchy"
                                            options={commissionHierarchy}
                                            optionLabel="name"
                                            name="commissionHierarchyId"
                                            optionValue="lovId"
                                            disabled
                                            value={formik.values.commissionHierarchyId || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('commissionHierarchyId') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('commissionHierarchyId')}
                                    </div>
                                </div> */}
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="transLimitId" className="Label__Text">
                                            Limit Profile
                                        </label>
                                        <Dropdown
                                            id="transLimitId"
                                            placeholder="Select Limit Profile"
                                            options={limitProfile}
                                            optionLabel="name"
                                            name="transLimitId"
                                            optionValue="lovId"
                                            value={formik.values.transLimitId || ''}
                                            disabled={!isEditable}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('transLimitId') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('transLimitId')}
                                    </div>
                                </div>
                            </div>
                            <div className="custom-card p-3 my-3">
                                <div className="m-3 Form__Header Full__Width">
                                    <p>PARENT</p>
                                </div>
                                <div className="p-fluid p-formgrid grid mb-2 mx-3">
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field withoutArrow">
                                            <label htmlFor="parentAgentId" className="Label__Text">
                                                Agent ID
                                            </label>
                                            <InputText
                                                id="parentAgentId"
                                                name="parentAgentId"
                                                value={formik.values.parentAgentId}
                                                disabled
                                                onChange={(e) => {
                                                    handleParentAgentId(e);
                                                }}
                                                className="Input__Round"
                                                ref={input3}
                                            />
                                            {/* {getFormErrorMessage('parentAgentId')} */}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field withoutArrow">
                                            <label htmlFor="ParentCnic" className="Label__Text">
                                                CNIC
                                            </label>
                                            <InputText
                                                id="ParentCnic"
                                                name="ParentCnic"
                                                value={formik.values.ParentCnic}
                                                disabled
                                                onChange={(e) => {
                                                    handleParentCnic(e);
                                                }}
                                                className="Input__Round"
                                                ref={input1}
                                            />
                                            {/* {getFormErrorMessage('ParentCnic')} */}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="parentMobileNumber" className="Label__Text">
                                                Mobile Number
                                            </label>
                                            <InputText
                                                id="parentMobileNumber"
                                                name="parentMobileNumber"
                                                value={formik.values.parentMobileNumber}
                                                disabled
                                                onChange={(e) => {
                                                    handleParentNumber(e);
                                                }}
                                                className="Input__Round"
                                                ref={input2}
                                            />
                                            {/* {getFormErrorMessage('parentMobileNumber')} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="Usergroup__SearchBtn p-field col-12 md:col-12 mb-3 pb-3 flex justify-content-center ">
                                    <Button label="Search" type="button" onClick={() => getParentData()} className="Btn__Dark__Search mr-4" disabled={searchBtnDisable} />
                                </div>
                                <div className="grid mx-3">
                                    <div className="col-12">
                                        <div className="card">
                                            <DataTable className="datatable-responsive" value={parent ? (Array.isArray(parent) ? parent : [parent]) : ''} emptyMessage="No List found." responsiveLayout="scroll">
                                                <Column body={(item) => (item?.franchiseName ? item?.franchiseName : item?.agentName)} header="Name" />
                                                <Column field="cnic" header="CNIC" />
                                                <Column field="agentPhoneNo" header="Mobile Number" />
                                                <Column field="agentId" header="Agent ID" />
                                                <Column header="Action" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid grid mb-3">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="channelIdList" className="Label__Text">
                                            Channel<span className="Label__Required">*</span>
                                        </label>
                                        <MultiSelect
                                            className={classNames({ 'p-invalid': isFormFieldValid('channelIdList') }, 'Dropdown__Round')}
                                            id="channelIdList"
                                            options={channelOption}
                                            name="channelIdList"
                                            optionLabel="name"
                                            optionValue="lovId"
                                            panelFooterTemplate={panelFooterTemplate}
                                            filter
                                            value={formik?.values?.channelIdList?.map((trans) => trans.lovId)}
                                            disabled={!isEditable}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    'channelIdList',
                                                    e.value.map((lovId) => ({
                                                        lovId
                                                    }))
                                                );
                                            }}
                                        />
                                        {getFormErrorMessage('channelIdList')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="empId" className="Label__Text">
                                            Employee ID<span className="Label__Required">*</span>
                                        </label>
                                        <div className="flex withoutArrow">
                                            <InputText
                                                id="empId"
                                                name="empId"
                                                value={formik.values.empId}
                                                onChange={(e) => {
                                                    setUseCaseError({ empId: null });
                                                    handleEmployeeIdChange(e);
                                                    if (!e.target.value) {
                                                        formik.setFieldValue('hierarchyName', '');
                                                    }
                                                }}
                                                disabled={!isEditable}
                                                type="number"
                                                className={classNames({ 'p-invalid': useCaseError.empId || isFormFieldValid('empId') }, 'Input__Round')}
                                                maxLength={20}
                                            />
                                            {isEditable && <Button icon={loadingIcon || ''} onClick={() => searchEmployeeId()} type="button" className="Btn__Dark border-circle" style={{ height: '35px', width: '35px', marginLeft: '1rem' }} />}
                                        </div>
                                        {useCaseError.empId ? <small className="p-error">{formik?.errors?.empId}</small> : getFormErrorMessage('empId')}
                                    </div>
                                </div>
                            </div>
                            <div className="custom-card p-3 my-3">
                                <div className="m-3 Form__Header Full__Width">
                                    <p>EMPLOYEE ID</p>
                                </div>
                                <div className="p-fluid p-formgrid grid mb-2 mx-3">
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="hierarchyName" className="Label__Text">
                                                Sales Hierarchy Name
                                            </label>
                                            <InputText
                                                id="hierarchyName"
                                                name="hierarchyName"
                                                onChange={formik.handleChange}
                                                value={formik.values.hierarchyName}
                                                disabled
                                                className={classNames({ 'p-invalid': isFormFieldValid('hierarchyName') }, 'Input__Round')}
                                                maxLength={200}
                                            />
                                            {getFormErrorMessage('hierarchyName')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid grid mb-3">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="productCatalogId" className="Label__Text">
                                            Product catalog
                                        </label>
                                        <Dropdown
                                            id="productCatalogId"
                                            placeholder="Select Product Catalog"
                                            options={productCatalog}
                                            optionLabel="name"
                                            name="productCatalogId"
                                            optionValue="lovId"
                                            value={formik.values.productCatalogId || ''}
                                            disabled={!isEditable}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('productCatalogId') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('productCatalogId')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 flex align-items-end mb-2 pt-3 pb-3">
                                    <div className="p-field">
                                        <Checkbox
                                            inputId="chequeBook"
                                            name="chequeBook"
                                            checked={formik.values.chequeBook}
                                            disabled
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                formik.setFieldValue('noOfLeaves', '');
                                                setDigitalSigningFile('');
                                                formik.setFieldValue('digitalSigningInput', '');
                                                setDigitalSigningInput('');
                                                setDigitalSigningInputBase64('');
                                                setDigitalSigningLink('');
                                            }}
                                        />
                                        <label htmlFor="chequeBook" className="Label__Text">
                                            Cheque Book
                                        </label>
                                    </div>
                                    <div className="p-field ml-3">
                                        <Checkbox
                                            inputId="debitCard"
                                            name="debitCard"
                                            checked={formik.values.debitCard}
                                            disabled
                                            onChange={(e) => {
                                                formik.handleChange(e);

                                                formik.setFieldValue('debitCardName', '');

                                                formik.setFieldValue('debitCardAddress', '');
                                            }}
                                        />
                                        <label htmlFor="debitCard" className="Label__Text">
                                            ATM/Debit Card
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {formik.values.chequeBook || formik.values.debitCard ? (
                                <div className="custom-card p-3 my-3">
                                    <div className="p-fluid p-formgrid grid mb-2 mx-3">
                                        {formik.values.chequeBook && (
                                            <>
                                                <div className="m-3 Form__Header Full__Width">
                                                    <p>CHEQUE BOOK DETAILS</p>
                                                </div>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor="noOfLeaves" className="Label__Text">
                                                            No of leaves<span className="Label__Required">*</span>
                                                        </label>
                                                        <Dropdown
                                                            id="noOfLeaves"
                                                            options={chequeBookLeaves}
                                                            name="noOfLeaves"
                                                            placeholder="Select Number of Leaves"
                                                            optionLabel="name"
                                                            optionValue="value"
                                                            value={formik.values.noOfLeaves}
                                                            onChange={formik.handleChange}
                                                            disabled
                                                            className={classNames({ 'p-invalid': isFormFieldValid('noOfLeaves') }, 'Dropdown__Round')}
                                                        />
                                                        {getFormErrorMessage('noOfLeaves')}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor="channelName" className="Label__Text">
                                                            Waiver
                                                        </label>
                                                        <div className="flex">
                                                            <div className="mt-2 mr-3">
                                                                <RadioButton inputId="chequeApplicable" name="chequeWaiver" value="Y" disabled onChange={formik.handleChange} checked={formik.values.chequeWaiver === 'Y'} />
                                                                <label htmlFor="chequeApplicable" className="Label__Text">
                                                                    Applicable
                                                                </label>
                                                            </div>
                                                            <div className="mt-2">
                                                                <RadioButton inputId="chequeNotApplicable" name="chequeWaiver" value="N" disabled onChange={formik.handleChange} checked={formik.values.chequeWaiver === 'N'} />
                                                                <label htmlFor="chequeNotApplicable" className="Label__Text">
                                                                    Not Applicable
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {formik.values.debitCard && (
                                            <>
                                                <div className="m-3 Form__Header Full__Width">
                                                    <p>ATM / DEBIR CARD DEATILS</p>
                                                </div>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor="debitCardName" className="Label__Text">
                                                            Name<span className="Label__Required">*</span>
                                                        </label>
                                                        <InputText
                                                            id="debitCardName"
                                                            name="debitCardName"
                                                            value={formik.values.debitCardName}
                                                            disabled
                                                            onInput={handleInputChange}
                                                            maxLength={200}
                                                            className={classNames({ 'p-invalid': isFormFieldValid('debitCardName') }, 'Input__Round')}
                                                        />
                                                        {getFormErrorMessage('debitCardName')}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor="debitCardAddress" className="Label__Text">
                                                            Postal Address<span className="Label__Required">*</span>
                                                        </label>
                                                        <InputText
                                                            id="debitCardAddress"
                                                            name="debitCardAddress"
                                                            value={formik.values.debitCardAddress}
                                                            disabled
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid('debitCardAddress') }, 'Input__Round')}
                                                            maxLength={200}
                                                        />
                                                        {getFormErrorMessage('debitCardAddress')}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor="channelName" className="Label__Text">
                                                            Waiver
                                                        </label>
                                                        <div className="flex">
                                                            <div className="mt-2 mr-3">
                                                                <RadioButton inputId="applicable" name="atmWaiver" value="Y" disabled onChange={formik.handleChange} checked={formik.values.atmWaiver === 'Y'} />
                                                                <label htmlFor="applicable" className="Label__Text">
                                                                    Applicable
                                                                </label>
                                                            </div>
                                                            <div className="mt-2">
                                                                <RadioButton inputId="notApplicable" name="atmWaiver" value="N" disabled onChange={formik.handleChange} checked={formik.values.atmWaiver === 'N'} />
                                                                <label htmlFor="notApplicable" className="Label__Text">
                                                                    Not Applicable
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            <div className="p-fluid p-formgrid grid mb-3">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="complianceLink" className={classNames({ 'p-error': isFormFieldValid('complianceLink') }, 'Label__Text')}>
                                            Compliance Link Screening <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="complianceLink"
                                            placeholder="Select Compliance Link Screening"
                                            name="complianceLink"
                                            value={apiData?.clsVerified === 'Y' ? 'Yes' : apiData?.clsVerified === 'N' ? 'No' : apiData?.clsVerified === null ? 'null' : apiData?.clsVerified || ''}
                                            onChange={formik.handleChange}
                                            disabled
                                            className={classNames({ 'p-invalid': isFormFieldValid('complianceLink') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('complianceLink')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="blackListing" className={classNames({ 'p-error': isFormFieldValid('blackListing') }, 'Label__Text')}>
                                            Blacklisting Agent Checks <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="blackListing"
                                            placeholder="N"
                                            name="blackListing"
                                            // value={formik.values.blackListing || ''}
                                            // onChange={formik.handleChange}
                                            disabled
                                            className={classNames({ 'p-invalid': isFormFieldValid('blackListing') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('blackListing')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="ecib" className={classNames({ 'p-error': isFormFieldValid('ecib') }, 'Label__Text')}>
                                            ECIB Score<span className="Label__Required">*</span>
                                        </label>
                                        <InputText id="ecib" placeholder="Mock" name="ecib" value={formik.values.ecib || ''} onChange={formik.handleChange} disabled className={classNames({ 'p-invalid': isFormFieldValid('ecib') }, 'Input__Round')} />
                                        {getFormErrorMessage('ecib')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="bvs" className={classNames({ 'p-error': isFormFieldValid('bvs') }, 'Label__Text')}>
                                            Biometric Verification <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="bvs"
                                            name="bvs"
                                            placeholder="Select Parent Name"
                                            value={formik.values.bvs}
                                            onChange={formik.handleChange}
                                            disabled
                                            className={classNames({ 'p-invalid': isFormFieldValid('bvs') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('bvs')}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid grid mb-3">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <div className="mb-2">
                                            <label className={classNames({ 'p-error': isFormFieldValid('kfsInput') }, 'Label__Text')}>
                                                Key Fact Statement <span className="Label__Required">*</span>
                                            </label>
                                            <div className="file-input-container w-100" onClick={handleKfsInput}>
                                                <input type="file" accept="image/*, .pdf, .doc, .docx, .xls, .xlsx, .csv" style={{ display: 'none' }} ref={kfsRef} onChange={kfsChange} disabled />
                                                <input
                                                    type="text"
                                                    className={classNames({ 'p-inputtext p-component p-invalid': isFormFieldValid('kfsInput') }, 'file-input-field Input__Round')}
                                                    placeholder="Choose a file..."
                                                    value={formik.values.kfsInput}
                                                    readOnly
                                                    disabled
                                                />
                                                <span className="file-upload-icon">
                                                    <i className="pi pi-paperclip" />
                                                </span>
                                            </div>
                                            {kfsFileError ? <small className="p-error">{kfsFileError}</small> : getFormErrorMessage('kfsInput')}{' '}
                                        </div>
                                    </div>
                                    {docData && docData?.length > 0 && (
                                        <React.Fragment>
                                            {docData[0] && (
                                                <div className="Down__Btn">
                                                    <Button
                                                        type="button"
                                                        label="View Document"
                                                        className="Btn__Dark"
                                                        onClick={() => {
                                                            window.open(kfsLink, '_blank');
                                                        }}
                                                        disabled={!formik?.values?.kfsInput}
                                                    />
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <div className="mb-2">
                                            <label className={classNames({ 'p-error': isFormFieldValid('digitalSigningInput') }, 'Label__Text')}>
                                                Digital Signing
                                                {formik?.values?.chequeBook && <span className="Label__Required">*</span>}
                                            </label>
                                            <div className="file-input-container w-100" onClick={handleDigitalSigningInput}>
                                                <input type="file" accept="image/*, .pdf, .doc, .docx, .xls, .xlsx, .csv" style={{ display: 'none' }} ref={digitalSigningRef} onChange={digitalSigningChange} disabled />
                                                <input
                                                    type="text"
                                                    className={classNames({ 'p-inputtext p-component p-invalid': isFormFieldValid('digitalSigningInput') }, 'file-input-field Input__Round')}
                                                    placeholder="Choose a file..."
                                                    value={formik.values.digitalSigningInput}
                                                    readOnly
                                                    disabled
                                                />
                                                <span className="file-upload-icon">
                                                    <i className="pi pi-paperclip" />
                                                </span>
                                            </div>
                                            {dsFileError ? <small className="p-error">{dsFileError}</small> : getFormErrorMessage('digitalSigningInput')}
                                        </div>
                                    </div>
                                    {docData && docData?.length > 0 && (
                                        <React.Fragment>
                                            {docData[1] && (
                                                <div className="Down__Btn">
                                                    <Button
                                                        type="button"
                                                        label="View Document"
                                                        className="Btn__Dark"
                                                        onClick={() => {
                                                            window.open(digitalSigningLink, '_blank');
                                                        }}
                                                        disabled={!formik?.values?.digitalSigningInput}
                                                    />
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="accountTitle" className={classNames({ 'p-error': isFormFieldValid('accountTitle') }, 'Label__Text')}>
                                            Account Title <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            disabled
                                            id="accountTitle"
                                            name="accountTitle"
                                            placeholder="Enter Account Title"
                                            value={formik.values.accountTitle || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('accountTitle') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('accountTitle')}
                                    </div>
                                </div>
                                {formik.values.addressInfos?.map((item, index) => {
                                    return (
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3" key={index}>
                                            <div className="p-field">
                                                <label htmlFor={`addressInfos.${index}.fullAddress`} className={classNames({ 'p-error': isFormFieldValid(`addressInfos.${index}.fullAddress`) }, 'Label__Text')}>
                                                    Address <span className="Label__Required">*</span>
                                                </label>
                                                <InputText
                                                    disabled
                                                    id={`addressInfos.${index}.fullAddress`}
                                                    name={`addressInfos.${index}.fullAddress`}
                                                    placeholder="Enter Adds"
                                                    value={item?.fullAddress || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid(`addressInfos.${index}.fullAddress`) }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage(`addressInfos.${index}.fullAddress`)}
                                            </div>
                                        </div>
                                    );
                                })}

                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="fatherName" className={classNames({ 'p-error': isFormFieldValid('fatherName') }, 'Label__Text')}>
                                            Father Name <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            disabled
                                            id="fatherName"
                                            name="fatherName"
                                            placeholder="Enter Father Name"
                                            value={formik.values.fatherName || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('fatherName') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('fatherName')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="motherName" className={classNames({ 'p-error': isFormFieldValid('motherName') }, 'Label__Text')}>
                                            Mother Name <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            disabled
                                            id="motherName"
                                            name="motherName"
                                            placeholder="Enter Mother Name"
                                            value={formik.values.motherName || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('motherName') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('motherName')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="accountStatus" className={classNames({ 'p-error': isFormFieldValid('accountStatus') }, 'Label__Text')}>
                                            Status <span className="Label__Required">*</span>
                                        </label>
                                        <div className="flex withoutArrow">
                                            <Dropdown
                                                disabled
                                                id="accountStatus"
                                                placeholder="Select Status"
                                                options={accountStatus}
                                                optionLabel="accountStatusName"
                                                name="accountStatus"
                                                optionValue="accountStatusId"
                                                value={formik.values.accountStatus || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('accountStatus') }, 'Dropdown__Round')}
                                            />
                                            {getFormErrorMessage('accountStatus')}
                                            <Button
                                                icon="pi pi-pencil"
                                                onClick={(e) => handleRemarksDialog(e)}
                                                className="Btn__Dark border-circle"
                                                style={{
                                                    height: '32px',
                                                    width: '35px',
                                                    marginLeft: '1rem'
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="accountNumber" className={classNames({ 'p-error': isFormFieldValid('accountNumber') }, 'Label__Text')}>
                                            Account Number <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="accountNumber"
                                            name="accountNumber"
                                            placeholder="Select Account Number"
                                            value={formik.values.accountNumber || ''}
                                            onChange={formik.handleChange}
                                            disabled
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('accountNumber') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('accountNumber')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="remarks" className={classNames({ 'p-error': isFormFieldValid('remarks') }, 'Label__Text')}>
                                            Remarks <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="remarks"
                                            name="remarks"
                                            placeholder="Enter Remarks"
                                            value={formik.values.remarks || ''}
                                            onChange={formik.handleChange}
                                            disabled
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('remarks') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('remarks')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="crpScore" className={classNames({ 'p-error': isFormFieldValid('crpScore') }, 'Label__Text')}>
                                            CRP Score <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="crpScore"
                                            name="crpScore"
                                            placeholder="Please Enter Crp Score"
                                            value={formik.values.crpScore || ''}
                                            onChange={formik.handleChange}
                                            disabled
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('crpScore') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('crpScore')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="crpRating" className={classNames({ 'p-error': isFormFieldValid('crpRating') }, 'Label__Text')}>
                                            CRP Rating <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="crpRating"
                                            name="crpRating"
                                            placeholder="Please Enter Crp Rating"
                                            value={formik.values.crpRating || ''}
                                            onChange={formik.handleChange}
                                            disabled
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('crpRating') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('crpRating')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="birthPlace" className={classNames({ 'p-error': isFormFieldValid('birthPlace') }, 'Label__Text')}>
                                            Place of Birth <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="birthPlace"
                                            name="birthPlace"
                                            placeholder="Place of Birth"
                                            value={formik.values.birthPlace || ''}
                                            onChange={formik.handleChange}
                                            disabled
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('birthPlace') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('birthPlace')}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="p-fluid p-formgrid grid mb-3">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <div className="mb-2">
                                            <label className="Label__Text">
                                                Key Fact Statement <span className="Label__Required">*</span>
                                            </label>
                                            <div className="file-input-container w-100" onClick={handleKfsInput}>
                                                <input type="file" accept="image/*, .pdf, .doc, .docx, .xls, .xlsx, .csv" style={{ display: 'none' }} ref={kfsRef} onChange={kfsChange} disabled />
                                                <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={formik.values.kfsInput} readOnly disabled />
                                                <span className="file-upload-icon">
                                                    <i className="pi pi-paperclip" />
                                                </span>
                                            </div>
                                            {kfsFileError ? <small className="p-error">{kfsFileError}</small> : getFormErrorMessage('kfsInput')}
                                        </div>
                                    </div>
                                    {docData && docData?.length > 0 && (
                                        <React.Fragment>
                                            {docData[0] && (
                                                <div className="Down__Btn">
                                                    <Button
                                                        type="button"
                                                        label="View Document"
                                                        className="Btn__Dark"
                                                        onClick={() => {
                                                            window.open(kfsLink, '_blank');
                                                        }}
                                                        disabled={!formik?.values?.kfsInput}
                                                    />
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <div className="mb-2">
                                            <label className="Label__Text">
                                                Digital Signing
                                                {formik?.values?.chequeBook && <span className="Label__Required">*</span>}
                                            </label>
                                            <div className="file-input-container w-100" onClick={handleDigitalSigningInput}>
                                                <input type="file" accept="image/*, .pdf, .doc, .docx, .xls, .xlsx, .csv" style={{ display: 'none' }} ref={digitalSigningRef} onChange={digitalSigningChange} disabled />
                                                <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={formik.values.digitalSigningInput} readOnly disabled />
                                                <span className="file-upload-icon">
                                                    <i className="pi pi-paperclip" />
                                                </span>
                                            </div>
                                            {dsFileError ? <small className="p-error">{dsFileError}</small> : getFormErrorMessage('digitalSigningInput')}
                                        </div>
                                    </div>
                                    {docData && docData?.length > 0 && (
                                        <React.Fragment>
                                            {docData[1] && (
                                                <div className="Down__Btn">
                                                    <Button
                                                        type="button"
                                                        label="View Document"
                                                        className="Btn__Dark"
                                                        onClick={() => {
                                                            window.open(digitalSigningLink, '_blank');
                                                        }}
                                                        disabled={!formik?.values?.digitalSigningInput}
                                                    />
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            </div> */}

                            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 justify-content-center pt-4 gap-4" style={{ marginBottom: '30px' }}>
                                <div
                                    className={accountStatus?.accountStatusName === 'CLOSED' ? 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons bg_dim' : 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons'}
                                    onClick={apiData?.accountStatus === 4 ? null : !isEditable ? null : handleCloseAccount}
                                >
                                    <div>Close Account</div>
                                </div>
                                <div
                                    className={accountStatus?.accountStatusName === 'CLOSED' ? 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons bg_dim' : 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons'}
                                    onClick={apiData?.accountStatus === 4 ? null : !isEditable ? null : handleSetleAccount}
                                >
                                    <div>Close Settle Account</div>
                                </div>
                                <div
                                    className={accountStatus?.accountStatusName === 'CLOSED' ? 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons bg_dim' : 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons'}
                                    onClick={apiData?.accountStatus === 4 ? null : isEditable ? handleGeneratePin : null}
                                >
                                    <div>Regenerate Login Pin</div>
                                </div>
                                <div
                                    className={accountStatus?.accountStatusName === 'CLOSED' ? 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons bg_dim' : 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons'}
                                    onClick={apiData?.accountStatus === 4 ? null : !isEditable ? null : handleAccountStatement}
                                >
                                    <div>Account Statement</div>
                                </div>
                            </div>
                            {!isEditable ? (
                                <div className="Down__Btn my-5">
                                    <Button type="button" label="Okay" className="Btn__Dark" onClick={() => navigate('/accountsmanagement')} />
                                </div>
                            ) : (
                                <div className="Down__Btn">
                                    {apiData?.accountStatus === 4 ? (
                                        <Button type="submit" label="Ok" className="Btn__Dark" onClick={() => navigate('/accountsmanagement')} />
                                    ) : (
                                        <Button type="submit" label="Update" className="Btn__Dark" onClick={() => ScrollMover()} />
                                    )}

                                    <Button type="button" onClick={() => navigate('/accountsmanagement')} label="Cancel" className="Btn__Transparent" />
                                </div>
                            )}
                        </>
                    )}
                </form>
            </div>
        </>
    );
}

export default EditViewRetailer;

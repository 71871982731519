import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { toast } from 'react-toastify';
import { InputNumber } from 'primereact/inputnumber';
import { baseURL, serverURL } from '../../../../Config';
import SecuredFileChange from '../../../../components/SecuredFileChange';

function AddTargetBaseSelectionCampaign({ activeIndex, handleStepClick, compaignIdData, campaignData, getCompaignId }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    const [cnicFile, setCnicFile] = useState('');
    const [mSISDNFile, setMSISDNFile] = useState('');
    const [isMsisdnFileChanged, setIsMsisdnFileChanged] = useState(false);
    const [agentTypeLov, setAgentTypeLov] = useState([]);
    const [accountTypeLov, setAccountTypeLov] = useState([]);
    const [transactionsLov, setTransactionsLov] = useState([]);
    const [segmentLov, setSegmentLov] = useState([]);

    const [mSFilePathData, setMSFilePathData] = useState('');
    const [cnicFilePathData, setCnicFilePathData] = useState('');
    const [isCnicFileChanged, setIsCnicFileChanged] = useState(false);
    ///////////////////////////////////////////////
    const [kfsFileError, setKfsFileError] = useState('');
    const [cnicFileError, setCnicFileError] = useState('');

    const dispatch = useDispatch();
    const msdRef = useRef(null);
    const cnicRef = useRef(null);

    const getAgentTypeLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getdatalov/TYPE');

        const payload = resp?.payLoad || [];

        // Filter and set for code "C" Segments
        const filteredSegments = payload?.filter((item) => item.code === 'C');
        setSegmentLov(filteredSegments);

        const filteredOthers = payload.filter((item) => item.code !== 'C');
        setAgentTypeLov(filteredOthers);
    };

    const getAccountTypeLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getAccountStatus/LKP_ACCOUNT_STATUS:*');
        const resFilteredArray = res?.payLoad.filter(
            (item) => item?.accountStatusName === 'SUSPENDED' || item?.accountStatusName === 'ACTIVE' || item?.accountStatusName === 'CLOSED' || item?.accountStatusName === 'DORMANT' || item?.accountStatusName === 'BACKOFFICE BLOCKED'
        );
        setAccountTypeLov(resFilteredArray);
    };

    const getTransactionsLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setTransactionsLov(res?.payLoad);
    };

    useEffect(() => {
        getAgentTypeLov();
        getAccountTypeLov();
        getTransactionsLov();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (campaignData) {
            formik.setFieldValue('tbsType', campaignData?.tbsType?.toString());

            if (campaignData?.tbsType === 'C') {
                const agentCustomerTypeList = campaignData?.agentCustomerType || [];

                const selectedAgentCustomerType = agentCustomerTypeList
                    .filter((item) => !item.includes(':C')) // Filter out items that do not contain ":C"
                    .map((item) => {
                        return { lovId: item };
                    });

                const segmentType = agentCustomerTypeList
                    .filter((item) => item.includes(':C')) // Filter out items that contain ":C"
                    .map((item) => {
                        return { lovId: item };
                    });

                const selectedAccountStatus = campaignData?.accountStatus?.map((item) => {
                    return { accountStatusId: item.toString() };
                });

                const selectedTbsTransaction = campaignData?.tbsTransaction?.map((item) => {
                    return { lovId: item.toString() };
                });

                formik.setFieldValue('agentCustomerTypeList', selectedAgentCustomerType);
                formik.setFieldValue('segment', segmentType);
                formik.setFieldValue('accountStatusList', selectedAccountStatus);
                formik.setFieldValue('tbsTransactionTypeIds', selectedTbsTransaction);

                formik.setFieldValue('registrationConsideration', campaignData?.tbsRegistrationDateConsideration?.toString());
                if (campaignData?.tbsRegistrationStartDate != null) {
                    formik.setFieldValue('registrationStartDate', new Date(campaignData?.tbsRegistrationStartDate).toISOString().slice(0, 16));
                }
                if (campaignData?.tbsRegistrationDateConsideration != null) {
                    formik.setFieldValue('isRegistration', true);
                    formik.setFieldValue('registrationEndDate', new Date(campaignData?.tbsRegistrationEndDate).toISOString().slice(0, 16));
                } else {
                    formik.setFieldValue('isRegistration', false);
                }

                formik.setFieldValue('activityConsideration', campaignData?.tbsLastActivityDateConsideration?.toString());
                if (campaignData?.tbsLastActivityStartDate != null) {
                    formik.setFieldValue('activityStartDate', new Date(campaignData?.tbsLastActivityStartDate).toISOString().slice(0, 16));
                }
                if (campaignData?.tbsLastActivityDateConsideration !== null) {
                    formik.setFieldValue('isActivity', true);
                    formik.setFieldValue('activityEndDate', new Date(campaignData?.tbsLastActivityEndDate).toISOString().slice(0, 16));
                } else {
                    formik.setFieldValue('isActivity', false);
                }

                if (campaignData?.tbsTransaction.length > 0) {
                    formik.setFieldValue('isTransaction', true);
                    /////////////////
                    formik.setFieldValue('transConsideration', campaignData?.tbsLastTransDateConsideration?.toString());
                    if (campaignData?.tbsLastTransStartDate != null) {
                        formik.setFieldValue('transStartDate', new Date(campaignData?.tbsLastTransStartDate).toISOString().slice(0, 16));
                    }
                    if (campaignData?.tbsLastTransDateConsideration !== null) {
                        formik.setFieldValue('transEndDate', new Date(campaignData?.tbsLastTransEndDate).toISOString().slice(0, 16));
                    }
                    /////////////////
                } else {
                    formik.setFieldValue('isTransaction', false);
                }

                if (campaignData?.tbsExclMsisdnFilename) {
                    formik.setFieldValue('isExcludeNumber', true);
                    // formik.setFieldValue('mobileNoFile', campaignData?.tbsExclMsisdnFilename);
                    let fileName = '';
                    if (campaignData?.tbsExclMsisdnFilename) {
                        const filePath = campaignData?.tbsExclMsisdnFilename;
                        // Use the split method to split the string by "/"
                        const parts = filePath.split('/');

                        // Get the last part of the array, which is the filename
                        fileName = parts[parts.length - 1];
                        const filedata = `${baseURL}${filePath}`;
                        const updatedPath = new File([filedata], fileName);
                        setMSFilePathData(updatedPath);
                    }
                    formik.setFieldValue('mobileNoFile', fileName);
                }

                if (campaignData?.tbsExclCnicFilename) {
                    formik.setFieldValue('isExcludeCnic', true);
                    // formik.setFieldValue('mobileNoFile', campaignData?.tbsExclMsisdnFilename);
                    let fileName = '';
                    if (campaignData?.tbsExclCnicFilename) {
                        const filePath = campaignData?.tbsExclCnicFilename;
                        // Use the split method to split the string by "/"
                        const parts = filePath.split('/');

                        // Get the last part of the array, which is the filename
                        fileName = parts[parts.length - 1];
                        const filedata = `${baseURL}${filePath}`;
                        const updatedPath = new File([filedata], fileName);
                        setCnicFilePathData(updatedPath);
                    }
                    formik.setFieldValue('cnicFile', fileName);
                }

                formik.setFieldValue('targetBaseCount', campaignData?.tbsCount);
            } else {
                let fileName = '';
                if (campaignData?.tbsWhitelistedFilename) {
                    const filePath = campaignData?.tbsWhitelistedFilename;
                    // Use the split method to split the string by "/"
                    const parts = filePath.split('/');

                    // Get the last part of the array, which is the filename
                    fileName = parts[parts.length - 1];
                    const filedata = `${baseURL}${filePath}`;
                    const updatedPath = new File([filedata], fileName);
                    setMSFilePathData(updatedPath);
                }
                formik.setFieldValue('mobileNoFile', fileName);

                const segmentTypeList = campaignData?.agentCustomerType || [];

                const segmentType = segmentTypeList
                    .filter((item) => item.includes(':C')) // Filter out items that contain ":C"
                    .map((item) => {
                        return { lovId: item };
                    });
                formik.setFieldValue('segment', segmentType);
            }
        }
    }, []); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            mobileNoFile: '',
            cnicFile: '',
            tbsType: '',
            agentCustomerTypeList: [],
            accountStatusList: [],
            segment: [],

            isRegistration: false,
            registrationConsideration: '',
            registrationStartDate: '',
            registrationEndDate: '',

            isActivity: false,
            activityConsideration: '',
            activityStartDate: '',
            activityEndDate: '',

            isTransaction: false,
            transConsideration: '',
            transStartDate: '',
            transEndDate: '',
            tbsTransactionTypeIds: [],

            isExcludeNumber: false,
            isExcludeCnic: false
        },

        validate: (data) => {
            let errors = {};
            if (!data.tbsType) {
                errors.tbsType = 'This field is required';
            }
            if (data?.tbsType === '' || data?.tbsType === null) {
                errors.tbsType = 'This field is required';
            } else if (data?.tbsType === 'C') {
                if (data?.agentCustomerTypeList.length === 0) {
                    errors.agentCustomerTypeList = 'This field is required';
                }

                // if (data?.segment.length === 0) {
                //     errors.segment = 'This field is required';
                // }

                if (data?.accountStatusList.length === 0) {
                    errors.accountStatusList = 'This field is required';
                }

                if (data?.isRegistration === true) {
                    if (data?.registrationConsideration === '') {
                        errors.registrationConsideration = 'This field is required';
                    }

                    if (data?.registrationStartDate === '') {
                        errors.registrationStartDate = 'This field is required';
                    }

                    if (data?.registrationConsideration === 'D' && data?.registrationEndDate === '') {
                        errors.registrationEndDate = 'This field is required';
                    } else if (data?.registrationConsideration === 'D') {
                        if (data?.registrationStartDate >= data?.registrationEndDate) {
                            errors.registrationEndDate = 'End date is required and must be greater than start date';
                        }
                    }
                }

                if (data?.isActivity === true) {
                    if (data?.activityConsideration === '') {
                        errors.activityConsideration = 'This field is required';
                    }

                    if (data?.activityStartDate === '') {
                        errors.activityStartDate = 'This field is required';
                    }

                    if (data?.activityConsideration === 'D' && data?.activityEndDate === '') {
                        errors.activityEndDate = 'This field is required';
                    } else if (data?.activityConsideration === 'D') {
                        if (!data?.activityStartDate) {
                            errors.activityStartDate = 'This field is required';
                        }
                        if (!data?.activityEndDate) {
                            errors.activityEndDate = 'This field is required';
                        } else if (data?.activityConsideration === 'D') {
                            if (data?.activityStartDate >= data?.activityEndDate) {
                                errors.activityEndDate = 'End date must be greater than start date';
                            }
                        }
                    }
                }

                if (data?.isTransaction === true) {
                    ///////////////
                    if (data?.transConsideration === '') {
                        errors.transConsideration = 'This field is required';
                    }

                    if (data?.transStartDate === '') {
                        errors.transStartDate = 'This field is required';
                    }

                    if (data?.transConsideration === 'D' && data?.transEndDate === '') {
                        errors.transEndDate = 'This field is required';
                    } else if (data?.transConsideration === 'D') {
                        if (!data?.transStartDate) {
                            errors.transStartDate = 'This field is required';
                        }
                        if (!data?.transEndDate) {
                            errors.transEndDate = 'This field is required';
                        } else if (data?.transConsideration === 'D') {
                            if (data?.transStartDate >= data?.transEndDate) {
                                errors.transEndDate = 'End date must be greater than start date';
                            }
                        }
                    }
                    //////////////
                    if (data?.tbsTransactionTypeIds.length === 0) {
                        errors.tbsTransactionTypeIds = 'This field is required';
                    }
                }
                if (data.isExcludeNumber) {
                    if (!data.mobileNoFile) {
                        errors.mobileNoFile = 'This field is required';
                    }
                }
                if (data.isExcludeCnic) {
                    if (!data.cnicFile) {
                        errors.cnicFile = 'This field is required';
                    }
                }
            } else if (data?.tbsType === 'W') {
                if (!data.mobileNoFile && data?.segment.length === 0) {
                    errors.mobileNoFile = 'Either Mobile No File or Segment is required';
                    errors.segment = 'Either Segment or Mobile No File is required';
                }
            }
            return errors;
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            if (data?.tbsType === 'C') {
                const arrayOfAgentCustomerTypeIds = formik?.values?.agentCustomerTypeList.map((item) => item?.lovId);
                const arrayOfSegmentsIds = formik?.values?.segment?.map((item) => item?.lovId);

                data['agentCustomerTypeList'] = arrayOfAgentCustomerTypeIds.concat(arrayOfSegmentsIds);
                delete data['segment'];

                const arrayOfTransactionIds = formik?.values?.tbsTransactionTypeIds.map((item) => parseInt(item?.lovId, 10));
                data['tbsTransactionTypeIds'] = arrayOfTransactionIds;

                const arrayOfAccountStatusIds = formik?.values?.accountStatusList.map((item) => parseInt(item?.accountStatusId, 10));
                data['accountStatusList'] = arrayOfAccountStatusIds;

                if (data?.registrationStartDate != '') {
                    data['registrationStartDate'] = formatDate(data?.registrationStartDate);
                    data['registrationEndDate'] = formatDate(data?.registrationEndDate);
                }

                if (data?.activityStartDate != '') {
                    data['activityStartDate'] = formatDate(data?.activityStartDate);
                    data['activityEndDate'] = formatDate(data?.activityEndDate);
                }

                if (data?.transStartDate != '') {
                    data['transStartDate'] = formatDate(data?.transStartDate);
                    data['transEndDate'] = formatDate(data?.transEndDate);
                }
            }

            if (data?.tbsType === 'C') {
                if (data['isRegistration'] === false) {
                    data['registrationConsideration'] = '';
                    data['registrationStartDate'] = '';
                    data['registrationEndDate'] = '';
                }
                if (data['isActivity'] === false) {
                    data['activityConsideration'] = '';
                    data['activityStartDate'] = '';
                    data['activityEndDate'] = '';
                }

                if (data['isTransaction'] === false) {
                    data['tbsTransactionTypeIds'] = [];
                    data['transConsideration'] = '';
                    data['transStartDate'] = '';
                    data['transEndDate'] = '';
                }

                if (formik?.values?.registrationConsideration !== 'D') {
                    data['registrationEndDate'] = '';
                }

                if (formik?.values?.activityConsideration !== 'D') {
                    data['activityEndDate'] = '';
                }

                if (formik?.values?.transConsideration !== 'D') {
                    data['transEndDate'] = '';
                }
            }

            const arrayOfSegmentsIds = formik?.values?.segment?.map((item) => item?.lovId);
            if (data?.tbsType === 'W') {
                data['agentCustomerTypeList'] = arrayOfSegmentsIds;
                delete data['segment'];
            }

            const dataForWhitelist = {
                campaignId: compaignIdData || campaignData?.campaignId,
                stepNo: '1',
                tbsType: data?.tbsType,
                agentCustomerTypeList: arrayOfSegmentsIds
            };

            if (data?.tbsType === 'C') {
                delete data['mobileNoFile'];
                delete data['cnicFile'];
            }

            const dataForCustomized = {
                campaignId: compaignIdData || campaignData?.campaignId,
                stepNo: '1',
                ...data
            };

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data?.tbsType === 'W' ? dataForWhitelist : dataForCustomized,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            if (compaignIdData === 'undefined') {
                toast.warn('Please Add Properly from Step 1 CampaignId is Undefined');
            } else if (data?.tbsType === 'W') {
                delete data['mobileNoFile'];

                const formData = new FormData();
                formData.append('request', JSON.stringify(newData));
                isMsisdnFileChanged && formData.append('mobileNoFile', mSISDNFile);

                if (parseInt(campaignData?.currentStep) >= 1) {
                    const res = await dispatch(handlePostRequest(formData, '/customerloyalitypromotions/v1/customerloyalitypromotion/editwhitelistedcustomer', true, false));
                    if (res?.responseCode === '150000') {
                        formik.resetForm();
                        handleStepClick(activeIndex + 1);
                        getCompaignId(res?.payLoad?.campaignId);
                    }
                } else {
                    const res = await dispatch(handlePostRequest(formData, '/customerloyalitypromotions/v1/customerloyalitypromotion/whitelistedcustomer', true, false));
                    if (res?.responseCode === '150000') {
                        formik.resetForm();
                        handleStepClick(activeIndex + 1);
                    }
                }
                setloading(false);
                setloadingIcon('pi pi-search');
            } else if (data?.tbsType === 'C') {
                const formData = new FormData();
                formData.append('request', JSON.stringify(newData));
                if (data?.isExcludeCnic === true) {
                    isCnicFileChanged && formData.append('cnicFile', cnicFile);
                }
                if (data?.isExcludeNumber === true) {
                    isMsisdnFileChanged && formData.append('mobileNoFile', mSISDNFile);
                }

                if (parseInt(campaignData?.currentStep) >= 1) {
                    const res = await dispatch(handlePostRequest(formData, '/customerloyalitypromotions/v1/customerloyalitypromotion/editcampaign', true, false));
                    if (res?.responseCode === '150000') {
                        formik.resetForm();
                        getCompaignId(res?.payLoad?.campaignId);
                        handleStepClick(activeIndex + 1);
                    }
                } else {
                    const res = await dispatch(handlePostRequest(formData, '/customerloyalitypromotions/v1/customerloyalitypromotion/savecampaign', true, false));
                    if (res?.responseCode === '150000') {
                        formik.resetForm();
                        handleStepClick(activeIndex + 1);
                    } else {
                        const agentCustomerTypeList = formik?.values?.agentCustomerTypeList || [];

                        const selectedAgentCustomerType = agentCustomerTypeList
                            .filter((item) => !item.includes(':C')) // Filter out items that do not contain ":C"
                            .map((item) => {
                                return { lovId: item };
                            });

                        const segmentType = agentCustomerTypeList
                            .filter((item) => item.includes(':C')) // Filter out items that contain ":C"
                            .map((item) => {
                                return { lovId: item };
                            });

                        const selectedAccountStatus = formik?.values?.accountStatusList?.map((item) => {
                            return { accountStatusId: item.toString() };
                        });

                        const selectedTbsTransaction = formik?.values?.tbsTransactionTypeIds?.map((item) => {
                            return { lovId: item.toString() };
                        });

                        formik.setFieldValue('agentCustomerTypeList', selectedAgentCustomerType);
                        formik.setFieldValue('segment', segmentType);
                        formik.setFieldValue('accountStatusList', selectedAccountStatus);
                        formik.setFieldValue('tbsTransactionTypeIds', selectedTbsTransaction);

                        formik.setFieldValue('registrationStartDate', dateBackToLocal(formik?.values?.registrationStartDate));
                        formik.setFieldValue('registrationEndDate', dateBackToLocal(formik?.values?.registrationEndDate));

                        formik.setFieldValue('activityStartDate', dateBackToLocal(formik?.values?.activityStartDate));
                        formik.setFieldValue('activityEndDate', dateBackToLocal(formik?.values?.activityEndDate));

                        formik.setFieldValue('tranStartDate', dateBackToLocal(formik?.values?.transStartDate));
                        formik.setFieldValue('transEndDate', dateBackToLocal(formik?.values?.transEndDate));
                    }
                }

                setloading(false);
                setloadingIcon('pi pi-search');
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const tbsTypeLov = [
        {
            name: 'Whitelisted',
            lovId: 'W'
        },
        {
            name: 'Customized',
            lovId: 'C'
        }
    ];

    // const handleFileChangeForMSISDN = (event) => {
    //     const file = event.target.files[0];
    //     formik.setFieldValue('mobileNoFile', file);
    //     setMSISDNFile(file);
    // };

    // const handleFileChangeForCnic = (event) => {
    //     const file = event.target.files[0];
    //     formik.setFieldValue('cnicFile', file);
    //     setCnicFile(file);
    // };

    const panelAgentCustomerFooterTemplate = () => {
        const length = formik?.values?.agentCustomerTypeList ? formik?.values?.agentCustomerTypeList.length : 0;

        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    const panelSegmentsFooterTemplate = () => {
        const length = formik?.values?.segment ? formik?.values?.segment.length : 0;

        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    const panelAccountStatusFooterTemplate = () => {
        const length = formik?.values?.accountStatusList ? formik?.values?.accountStatusList.length : 0;

        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    const panelTransactionsFooterTemplate = () => {
        const length = formik?.values?.tbsTransactionTypeIds ? formik?.values?.tbsTransactionTypeIds.length : 0;

        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    const dateOfConsideration = [
        {
            name: 'Before',
            lovId: 'B'
        },
        {
            name: 'After',
            lovId: 'A'
        },
        {
            name: 'During',
            lovId: 'D'
        }
    ];

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours from 24-hour to 12-hour format
        const formattedHours = hours % 12 || 12;

        const formattedDate = `${day}-${month}-${year}, ${formattedHours}:${minutes} ${ampm}`;
        return formattedDate;
    };

    const getCurrentDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const day = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    const dateBackToLocal = (inputDateString) => {
        const dateParts = inputDateString.split(/[-,: ]+/);

        // Get parts of the date
        const day = dateParts[0];
        const month = dateParts[1];
        const year = dateParts[2];
        const hours = dateParts[3];
        const minutes = dateParts[4];
        const period = dateParts[5].toUpperCase(); // AM or PM

        // Adjust hours based on the period
        const adjustedHours = period === 'PM' && hours < 12 ? parseInt(hours, 10) + 12 : hours;

        // Create the formatted date string
        return `${year}-${month}-${day}T${adjustedHours}:${minutes}`;
    };

    const handleMSDINInput = () => {
        msdRef.current.click();
    };

    const handleCnicInput = () => {
        cnicRef.current.click();
    };

    const msisdnChange = (event) => {
        formik.setFieldValue('mobileNoFile', '');
        setKfsFileError('');
        setMSISDNFile('');
        const selectedFile = event.target.files[0];
        const fileTypes = ['csv'];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setKfsFileError(error);
        } else if (newFile) {
            formik.setFieldValue('mobileNoFile', selectedFile?.name);
            setMSISDNFile(selectedFile);
            setIsMsisdnFileChanged(true);
        }
    };

    const cnicChange = (event) => {
        formik.setFieldValue('cnicFile', '');
        setCnicFileError('');
        setCnicFile('');
        const selectedFile = event.target.files[0];
        const fileTypes = ['csv'];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setCnicFileError(error);
        } else if (newFile) {
            formik.setFieldValue('cnicFile', selectedFile?.name);
            setCnicFile(selectedFile);
            setIsCnicFileChanged(true);
        }
    };

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>Target Base Selection</h1>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik?.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="tbsType" className={classNames({ 'p-error': isFormFieldValid('tbsType') }, 'Label__Text')}>
                                    Selection Type <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="tbsType"
                                    placeholder="Select Target base Type"
                                    options={tbsTypeLov}
                                    optionLabel="name"
                                    name="tbsType"
                                    optionValue="lovId"
                                    value={formik?.values?.tbsType || ''}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        formik.setFieldValue('mobileNoFile', '');
                                        setKfsFileError('');
                                        setMSISDNFile('');
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldValid('tbsType') }, 'Dropdown__Round')}
                                />
                                {formik.touched.tbsType && formik.errors.tbsType && <small className="p-error">{formik.errors.tbsType}</small>}
                            </div>
                        </div>
                        {formik?.values?.tbsType === 'W' && (
                            <>
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <div className="mb-2">
                                            <label htmlFor="mobileNoFile" className={'Label__Text'}>
                                                Upload List Of MSISDN
                                                <a download={true} href={`${serverURL}/document/mobile.csv`} target="_blank" className="Download__Link">
                                                    - DownLoad Sample File
                                                </a>
                                            </label>
                                            <div className="file-input-container w-100" onClick={handleMSDINInput}>
                                                <input type="file" accept=".csv" style={{ display: 'none' }} ref={msdRef} onChange={msisdnChange} />
                                                <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={formik?.values?.mobileNoFile || ''} name="mobileNoFile" readOnly />
                                                <span className="file-upload-icon">
                                                    <i className="pi pi-paperclip" />
                                                </span>
                                            </div>
                                            {kfsFileError ? <small className="p-error">{kfsFileError}</small> : getFormErrorMessage('mobileNoFile')}
                                            {/* <small className="p-error">{formik.errors.mobileNoFile}</small> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="segment" className={classNames({ 'p-error': isFormFieldValid('segment') }, 'Label__Text')}>
                                            Segment
                                            {/* <span className="Label__Required">*</span> */}
                                        </label>
                                        <MultiSelect
                                            id="segment"
                                            placeholder="Select Segment"
                                            options={segmentLov}
                                            optionLabel="name"
                                            name="segment"
                                            optionValue="lovId"
                                            filter
                                            panelFooterTemplate={panelSegmentsFooterTemplate}
                                            value={formik?.values?.segment?.map((segmentType) => segmentType?.lovId || [])}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    'segment',
                                                    e?.value?.map((lovId) => ({
                                                        lovId: lovId
                                                    }))
                                                );
                                            }}
                                            className={classNames({ 'p-invalid': isFormFieldValid('segment') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('segment')}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {formik?.values?.tbsType === 'C' && (
                        <>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="agentCustomerTypeList" className={classNames({ 'p-error': isFormFieldValid('agentCustomerTypeList') }, 'Label__Text')}>
                                            Select Agent/Customer Type<span className="Label__Required">*</span>
                                        </label>
                                        <MultiSelect
                                            id="agentCustomerTypeList"
                                            placeholder="Select Agent/Customer Type"
                                            options={agentTypeLov}
                                            optionLabel="name"
                                            name="agentCustomerTypeList"
                                            optionValue="lovId"
                                            filter
                                            panelFooterTemplate={panelAgentCustomerFooterTemplate}
                                            value={formik?.values?.agentCustomerTypeList?.map((agentType) => agentType?.lovId || [])}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    'agentCustomerTypeList',
                                                    e?.value?.map((lovId) => ({
                                                        lovId: lovId
                                                    }))
                                                );
                                            }}
                                            className={classNames({ 'p-invalid': isFormFieldValid('agentCustomerTypeList') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('agentCustomerTypeList')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="accountStatusList" className={classNames({ 'p-error': isFormFieldValid('accountStatusList') }, 'Label__Text')}>
                                            Select Account Status<span className="Label__Required">*</span>
                                        </label>
                                        <MultiSelect
                                            id="accountStatusList"
                                            placeholder="Select Account Status"
                                            options={accountTypeLov}
                                            optionLabel="accountStatusName"
                                            name="accountStatusList"
                                            optionValue="accountStatusId"
                                            filter
                                            panelFooterTemplate={panelAccountStatusFooterTemplate}
                                            value={formik?.values?.accountStatusList.map((agentType) => agentType.accountStatusId || [])}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    'accountStatusList',
                                                    e.value.map((accountStatusId) => ({
                                                        accountStatusId
                                                    }))
                                                );
                                            }}
                                            className={classNames({ 'p-invalid': isFormFieldValid('accountStatusList') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('accountStatusList')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="segment" className={classNames({ 'p-error': isFormFieldValid('segment') }, 'Label__Text')}>
                                            Segment
                                            {/* <span className="Label__Required">*</span> */}
                                        </label>
                                        <MultiSelect
                                            id="segment"
                                            placeholder="Select Segment"
                                            options={segmentLov}
                                            optionLabel="name"
                                            name="segment"
                                            optionValue="lovId"
                                            filter
                                            panelFooterTemplate={panelSegmentsFooterTemplate}
                                            value={formik?.values?.segment?.map((segmentType) => segmentType?.lovId || [])}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    'segment',
                                                    e?.value?.map((lovId) => ({
                                                        lovId: lovId
                                                    }))
                                                );
                                            }}
                                            className={classNames({ 'p-invalid': isFormFieldValid('segment') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('segment')}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {formik?.values?.tbsType === 'C' && (
                        <>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-3 pt-3 flex align-items-end">
                                    <div className="p-field">
                                        <div className="field-checkbox">
                                            <Checkbox inputId="isRegistration" name="isRegistration" value={formik?.values?.isRegistration} checked={formik?.values?.isRegistration} onChange={formik.handleChange} style={{ marginTop: '10px' }} />
                                            <label htmlFor="isRegistration" className={classNames({ 'p-error': isFormFieldValid('isRegistration') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                                Registration Date
                                            </label>
                                        </div>
                                        {getFormErrorMessage('isRegistration')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-3">
                                    <div className="p-field">
                                        <label htmlFor="registrationConsideration" className={classNames({ 'p-error': isFormFieldValid('registrationConsideration') }, 'Label__Text')}>
                                            Date of Consideration
                                        </label>
                                        <Dropdown
                                            id="registrationConsideration"
                                            placeholder="Select Date of Consideration"
                                            options={dateOfConsideration}
                                            optionLabel="name"
                                            name="registrationConsideration"
                                            optionValue="lovId"
                                            disabled={formik?.values?.isRegistration === true ? false : true}
                                            value={formik?.values?.registrationConsideration || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('registrationConsideration') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('registrationConsideration')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="registrationStartDate" className={classNames({ 'p-error': isFormFieldValid('registrationStartDate') }, 'Label__Text')}>
                                            {formik?.values?.isRegistration && formik?.values?.registrationConsideration === 'D' ? 'Start Date & Time' : 'Date & Time'}
                                        </label>
                                        <InputText
                                            id="registrationStartDate"
                                            placeholder="Enter Date"
                                            name="registrationStartDate"
                                            type="datetime-local"
                                            // min={formik.values.registrationConsideration === 'A' ? getCurrentDateTime() : undefined}
                                            // max={formik.values.registrationConsideration === 'B' ? getCurrentDateTime() : undefined}
                                            disabled={formik?.values?.isRegistration === true ? false : true}
                                            value={formik?.values?.registrationStartDate || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('registrationStartDate') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('registrationStartDate')}
                                    </div>
                                </div>
                                {formik?.values?.registrationConsideration === 'D' && formik?.values?.isRegistration && (
                                    <>
                                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="registrationEndDate" className={classNames({ 'p-error': isFormFieldValid('registrationEndDate') }, 'Label__Text')}>
                                                    End Date & Time
                                                </label>
                                                <InputText
                                                    id="registrationEndDate"
                                                    placeholder="Enter End Date"
                                                    name="registrationEndDate"
                                                    type="datetime-local"
                                                    disabled={formik?.values?.isRegistration === true ? false : true}
                                                    value={formik?.values?.registrationEndDate || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('registrationEndDate') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('registrationEndDate')}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-3 pt-3 flex align-items-end">
                                    <div className="p-field">
                                        <div className="field-checkbox">
                                            <Checkbox inputId="isActivity" name="isActivity" value={formik?.values?.isActivity} checked={formik?.values?.isActivity} onChange={formik.handleChange} style={{ marginTop: '10px' }} />
                                            <label htmlFor="isActivity" className={classNames({ 'p-error': isFormFieldValid('isActivity') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                                Last Activity Date
                                            </label>
                                        </div>
                                        {getFormErrorMessage('isActivity')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-3">
                                    <div className="p-field">
                                        <label htmlFor="activityConsideration" className={classNames({ 'p-error': isFormFieldValid('activityConsideration') }, 'Label__Text')}>
                                            Date of Consideration
                                        </label>
                                        <Dropdown
                                            id="activityConsideration"
                                            placeholder="Select Date of Consideration"
                                            options={dateOfConsideration}
                                            optionLabel="name"
                                            name="activityConsideration"
                                            optionValue="lovId"
                                            disabled={formik?.values?.isActivity === true ? false : true}
                                            value={formik?.values?.activityConsideration || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('activityConsideration') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('activityConsideration')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="activityStartDate" className={classNames({ 'p-error': isFormFieldValid('activityStartDate') }, 'Label__Text')}>
                                            {formik?.values?.isActivity && formik?.values?.activityConsideration === 'D' ? 'Start Date & Time' : 'Date & Time'}
                                        </label>
                                        <InputText
                                            id="activityStartDate"
                                            placeholder="Enter Date"
                                            name="activityStartDate"
                                            type="datetime-local"
                                            // min={formik.values.activityConsideration === 'A' ? getCurrentDateTime() : undefined}
                                            // max={formik.values.activityConsideration === 'B' ? getCurrentDateTime() : undefined}
                                            disabled={formik?.values?.isActivity === true ? false : true}
                                            value={formik?.values?.activityStartDate || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('activityStartDate') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('activityStartDate')}
                                    </div>
                                </div>
                                {formik?.values?.activityConsideration === 'D' && formik?.values?.isActivity && (
                                    <>
                                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="activityEndDate" className={classNames({ 'p-error': isFormFieldValid('activityEndDate') }, 'Label__Text')}>
                                                    End Date & Time
                                                </label>
                                                <InputText
                                                    id="activityEndDate"
                                                    placeholder="Enter Date"
                                                    name="activityEndDate"
                                                    type="datetime-local"
                                                    disabled={formik?.values?.isActivity === true ? false : true}
                                                    value={formik?.values?.activityEndDate || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('activityEndDate') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('activityEndDate')}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-3 pt-3 flex align-items-end">
                                    <div className="p-field">
                                        <div className="field-checkbox">
                                            <Checkbox inputId="isTransaction" name="isTransaction" value={formik?.values?.isTransaction} checked={formik?.values?.isTransaction} onChange={formik.handleChange} style={{ marginTop: '10px' }} />
                                            <label htmlFor="isTransaction" className={classNames({ 'p-error': isFormFieldValid('isTransaction') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                                Transaction Type
                                            </label>
                                        </div>
                                        {getFormErrorMessage('isTransaction')}
                                    </div>
                                </div>
                                {/* ///////////////////////////////// */}
                                <div className="p-field col-12 md:col-3 pt-3">
                                    <div className="p-field">
                                        <label htmlFor="transConsideration" className={classNames({ 'p-error': isFormFieldValid('transConsideration') }, 'Label__Text')}>
                                            Date of Consideration
                                        </label>
                                        <Dropdown
                                            id="transConsideration"
                                            placeholder="Select Date of Consideration"
                                            options={dateOfConsideration}
                                            optionLabel="name"
                                            name="transConsideration"
                                            optionValue="lovId"
                                            // disabled={formik?.values?.isActivity === true ? false : true}
                                            disabled={formik?.values?.isTransaction === true ? false : true}
                                            value={formik?.values?.transConsideration || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('transConsideration') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('transConsideration')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="transStartDate" className={classNames({ 'p-error': isFormFieldValid('transStartDate') }, 'Label__Text')}>
                                            {formik?.values?.isTransaction && formik?.values?.transConsideration === 'D' ? 'Start Date & Time' : 'Date & Time'}
                                        </label>
                                        <InputText
                                            id="transStartDate"
                                            placeholder="Enter Date"
                                            name="transStartDate"
                                            type="datetime-local"
                                            // min={formik.values.activityConsideration === 'A' ? getCurrentDateTime() : undefined}
                                            // max={formik.values.activityConsideration === 'B' ? getCurrentDateTime() : undefined}
                                            disabled={formik?.values?.isTransaction === true ? false : true}
                                            value={formik?.values?.transStartDate || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('transStartDate') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('transStartDate')}
                                    </div>
                                </div>
                                {formik?.values?.transConsideration === 'D' && formik?.values?.isTransaction && (
                                    <>
                                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="transEndDate" className={classNames({ 'p-error': isFormFieldValid('transEndDate') }, 'Label__Text')}>
                                                    End Date & Time
                                                </label>
                                                <InputText
                                                    id="transEndDate"
                                                    placeholder="Enter Date"
                                                    name="transEndDate"
                                                    type="datetime-local"
                                                    disabled={formik?.values?.isTransaction === true ? false : true}
                                                    value={formik?.values?.transEndDate || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('transEndDate') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('transEndDate')}
                                            </div>
                                        </div>
                                    </>
                                )}
                                {/* ///////////////////////////////// */}
                            </div>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="tbsTransactionTypeIds" className={classNames({ 'p-error': isFormFieldValid('tbsTransactionTypeIds') }, 'Label__Text')}>
                                            Select Transactions<span className="Label__Required">*</span>
                                        </label>
                                        <MultiSelect
                                            id="tbsTransactionTypeIds"
                                            placeholder="Select Transactions Type"
                                            options={transactionsLov}
                                            optionLabel="name"
                                            name="tbsTransactionTypeIds"
                                            optionValue="lovId"
                                            filter
                                            disabled={formik?.values?.isTransaction === true ? false : true}
                                            panelFooterTemplate={panelTransactionsFooterTemplate}
                                            value={formik?.values?.tbsTransactionTypeIds.map((trans) => trans.lovId || '')}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    'tbsTransactionTypeIds',
                                                    e.value.map((lovId) => ({
                                                        lovId
                                                    }))
                                                );
                                            }}
                                            className={classNames({ 'p-invalid': isFormFieldValid('tbsTransactionTypeIds') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('tbsTransactionTypeIds')}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="mobileNoFile" className={classNames({ 'p-error': isFormFieldValid('mobileNoFile') }, 'Label__Text')}>
                                            Exclude MSISDN{' '}
                                            <a download={true} href={`${serverURL}/document/mobile.csv`} className="Download__Link">
                                                - DownLoad Sample File
                                            </a>
                                        </label>
                                        <div style={{ display: 'flex' }}>
                                            <Checkbox
                                                inputId="isExcludeNumber"
                                                name="isExcludeNumber"
                                                value={formik?.values?.isExcludeNumber}
                                                checked={formik?.values?.isExcludeNumber}
                                                onChange={formik.handleChange}
                                                style={{ marginTop: '10px', marginRight: '20px' }}
                                            />
                                            <div className="p-field col-12 md:col-10">
                                                <div className="p-field">
                                                    <div className="mb-2">
                                                        {/* <label htmlFor="mobileNoFile" className={'Label__Text'}>
                                                            Upload List Of MSISDN{' '}
                                                            <a download={true} href={`${serverURL}/document/mobile.csv`}  target='_blank' className="Download__Link">
                                                                - DownLoad Sample File
                                                            </a>
                                                        </label> */}
                                                        <div className="file-input-container w-100" onClick={handleMSDINInput}>
                                                            <input type="file" accept=".csv" style={{ display: 'none' }} ref={msdRef} onChange={msisdnChange} disabled={formik?.values?.isExcludeNumber === false ? true : false} />
                                                            <input
                                                                type="text"
                                                                className="file-input-field Input__Round"
                                                                placeholder="Choose a file..."
                                                                value={formik?.values?.mobileNoFile || ''}
                                                                name="mobileNoFile"
                                                                readOnly
                                                                disabled={formik?.values?.isExcludeNumber === false ? true : false}
                                                            />
                                                            <span className="file-upload-icon">
                                                                <i className="pi pi-paperclip" />
                                                            </span>
                                                        </div>
                                                        {kfsFileError ? <small className="p-error">{kfsFileError}</small> : getFormErrorMessage('mobileNoFile')}
                                                        {/* <small className="p-error">{formik.errors.mobileNoFile}</small> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="cnicFile" className={classNames({ 'p-error': isFormFieldValid('cnicFile') }, 'Label__Text')}>
                                            Exclude CNIC{' '}
                                            <a download={true} href={`${serverURL}/document/cnic.csv`} className="Download__Link">
                                                - DownLoad Sample File
                                            </a>
                                        </label>
                                        <div style={{ display: 'flex' }}>
                                            <Checkbox
                                                inputId="isExcludeCnic"
                                                name="isExcludeCnic"
                                                value={formik?.values?.isExcludeCnic}
                                                checked={formik?.values?.isExcludeCnic}
                                                onChange={formik.handleChange}
                                                style={{ marginTop: '10px', marginRight: '20px' }}
                                            />
                                            <div className="p-field col-12 md:col-10">
                                                <div className="p-field">
                                                    <div className="mb-2">
                                                        <div className="file-input-container w-100" onClick={handleCnicInput}>
                                                            <input type="file" accept=".csv" style={{ display: 'none' }} ref={cnicRef} onChange={cnicChange} disabled={formik?.values?.isExcludeCnic === true ? false : true} />
                                                            <input
                                                                type="text"
                                                                className="file-input-field Input__Round"
                                                                placeholder="Choose a file..."
                                                                value={formik?.values?.cnicFile || ''}
                                                                name="cnicFile"
                                                                readOnly
                                                                disabled={formik?.values?.isExcludeCnic === true ? false : true}
                                                            />
                                                            <span className="file-upload-icon">
                                                                <i className="pi pi-paperclip" />
                                                            </span>
                                                        </div>
                                                        {cnicFileError ? <small className="p-error">{cnicFileError}</small> : getFormErrorMessage('cnicFile')}
                                                        {/* <small className="p-error">{formik.errors.cnicFile}</small> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="targetBaseCount" className={classNames({ 'p-error': isFormFieldValid('targetBaseCount') }, 'Label__Text')}>
                                            Target Base Count
                                        </label>
                                    </div>
                                    <InputNumber
                                        disabled={true}
                                        inputId="targetBaseCount"
                                        name="targetBaseCount"
                                        // maxLength={9}
                                        placeholder="Enter target base count"
                                        value={formik?.values?.targetBaseCount || ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('targetBaseCount') }, 'Input_Number')}
                                    />
                                    {getFormErrorMessage('targetBaseCount')}
                                </div>
                            </div>
                        </>
                    )}
                    <div className="Down__Btn">
                        <Button type="submit" label={parseInt(campaignData?.currentStep) >= 1 ? 'Edit' : 'Save'} className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default AddTargetBaseSelectionCampaign;

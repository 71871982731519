import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Steps } from 'primereact/steps';
import AddProduct from './Components/Product/AddProduct';
import AddPricing from './Components/Pricing/AddPricing';
import AddRepaymentMethod from './Components/RepaymentMethod/AddRepaymentMethod';
import AddDocument from './Components/Document/AddDocument';
import TermsAndConditions from './Components/TermsandConditions/TermsAndConditions';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useLocation } from 'react-router-dom';
import { loadingAction } from '../../../redux/actions/loadingAction';

function AddLendingManagement() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [activeIndex, setActiveIndex] = useState(0);
    const [allStepsComplete, setAllStepsComplete] = useState(false);
    const [lendingIdData, setLendingIdData] = useState();
    const [lendingData, setLendingData] = useState();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const statelmsProductId = location.state?.lmsProductId;

    useEffect(() => {
        if (statelmsProductId) {
            getLmsProductDataByIdInCaseOfDraft();
        }
    }, []); // eslint-disable-line

    const getLmsProductDataByIdInCaseOfDraft = async () => {
        dispatch(loadingAction(true));
        const response = await handleGetRequest(`/lending/v1/lending/getlmsproductbyid/${statelmsProductId}`);
        setLendingData(response?.payLoad);
        setActiveIndex(response?.payLoad?.currentStep);
        setLendingIdData(response?.payLoad?.lmsProductId);
        dispatch(loadingAction(false));
    };

    useEffect(() => {
        if (lendingIdData) {
            getLendingById();
        }
    }, [activeIndex]); // eslint-disable-line

    const getLendingById = async () => {
        dispatch(loadingAction(true));
        const response = await handleGetRequest(`/lending/v1/lending/getlmsproductbyid/${lendingIdData}`);
        setLendingData(response?.payLoad);
        dispatch(loadingAction(false));
    };

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['lmsProductId'] = lendingIdData;

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/lending/v1/lending/createnewproduct', true, false, 'lendingmanagement'));
            if (res?.responseCode === process.env.REACT_APP_LENDING_SUCCESS_CODE) {
                formik.resetForm();
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const items = [
        {
            label: 'Product',
            command: (e) => handleStepClick(e.index),
            disabled: lendingData?.currentStep >= 0 ? false : true
        },
        {
            label: 'Pricing',
            command: (e) => handleStepClick(e.index),
            disabled: lendingData?.currentStep >= 1 ? false : true
        },
        {
            label: 'Repayment Methods',
            command: (e) => handleStepClick(e.index),
            disabled: lendingData?.currentStep >= 2 ? false : true
        },
        {
            label: 'Document',
            command: (e) => handleStepClick(e.index),
            disabled: lendingData?.currentStep >= 3 ? false : true
        },
        {
            label: 'Terms & Condition',
            command: (e) => handleStepClick(e.index),
            disabled: lendingData?.currentStep >= 4 ? false : true
        }
    ];

    const handleStepClick = (index) => {
        setActiveIndex(index);
    };

    const getLendingId = (data) => {
        setLendingIdData(data);
    };

    return (
        <>
            <div className="card Card__Round">
                <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} style={{ margin: '50px 0px', padding: '50px 0px' }} className="CustomerRisk__Steps" />

                {activeIndex === 0 && <AddProduct activeIndex={activeIndex} handleStepClick={handleStepClick} getLendingId={getLendingId} lendingData={lendingData} />}
                {activeIndex === 1 && <AddPricing activeIndex={activeIndex} handleStepClick={handleStepClick} lendingData={lendingData} lendingIdData={lendingIdData} getLendingId={getLendingId} />}
                {activeIndex === 2 && <AddRepaymentMethod activeIndex={activeIndex} handleStepClick={handleStepClick} lendingData={lendingData} lendingIdData={lendingIdData} getLendingId={getLendingId} />}
                {activeIndex === 3 && <AddDocument activeIndex={activeIndex} handleStepClick={handleStepClick} lendingData={lendingData} lendingIdData={lendingIdData} getLendingId={getLendingId} />}
                {activeIndex === 4 && <TermsAndConditions activeIndex={activeIndex} handleStepClick={handleStepClick} lendingData={lendingData} lendingIdData={lendingIdData} setAllStepsComplete={setAllStepsComplete} getLendingId={getLendingId} />}

                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {activeIndex === 5 && allStepsComplete && (
                        <div className="Down__Btn">
                            <Button disabled={loading} label="Submit" className="Btn__Dark" />
                            <Button disabled={loading} onClick={() => navigate('/lendingmanagement')} label="Cancel" className="Btn__Transparent" />
                        </div>
                    )}
                </form>
            </div>
        </>
    );
}
export default AddLendingManagement;

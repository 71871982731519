import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import ActiveInActiveDialog from '../../../../components/ActiveInActiveDialog/ActiveInActiveDialog';

function OtpScreen() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [statusData, setStatusData] = useState([]);
    const [userLovData, setUserLovData] = useState([]);
    const [otpData, setOtpData] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);
    const [switchData, setSwitchData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [useCaseData, setUseCaseData] = useState([]);
    const [osTypeData, setOsTypeData] = useState([]);
    const [deviceData, setDeviceData] = useState([]);
    const [otpTypeData, SetOtpTypeData] = useState([]);
    const [segmentsData, setSegmentsData] = useState([]);

    const dataTableRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        }),
        otpTimer: Yup.number()
            .nullable()
            .typeError('Input must be a number')

            // .max(24, 'Input must be less than or equal to 24 hours')

            .test('is-one-decimal', 'Input must have only one digit after decimal point', (value) => {
                // If the field is empty, it's valid (unless required).
                if (!value) return true;
                // Check if the value matches the pattern for one decimal place.
                return /^\d+(\.\d)?$/.test(value);
            }),
        timeHours: Yup.number()
            .nullable()
            .typeError('Input must be a number')

            // .max(24, 'Input must be less than or equal to 24 hours')

            .test('is-one-decimal', 'Input must have only one digit after decimal point', (value) => {
                // If the field is empty, it's valid (unless required).
                if (!value) return true;
                // Check if the value matches the pattern for one decimal place.
                return /^\d+(\.\d)?$/.test(value);
            })
    });

    const getStatusLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusData(res?.payLoad);
    };
    const getUserLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLovData(res?.payLoad);
    };
    const fetchTypeOptions = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_OTP_TYPE');

        setTypeOptions(response?.payLoad);
    };
    const getUseCaseData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_USECASE');
        setUseCaseData(res?.payLoad);
    };
    const getOsTypeData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_OS_TYPE');
        setOsTypeData(resp?.payLoad);
    };
    const getCmsDeviceData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_DEVICES');
        setDeviceData(res?.payLoad);
    };
    const getOtpTypeData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_OTP_TYPE');
        SetOtpTypeData(resp?.payLoad);
    };
    const getSegmentsData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getsegments/LKP_SEGMENT:*');
        setSegmentsData(res?.payLoad);
    };
    useEffect(() => {
        getSegmentsData();
        getStatusLov();
        getOsTypeData();
        getOtpTypeData();
        getCmsDeviceData();
        getUserLov();
        fetchTypeOptions();
        getUseCaseData();
    }, []); // eslint-disable-line

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            osDeviceId: '',
            osTypeId: '',
            useCaseId: '',
            attempts: '',
            otpTimer: '',
            otpTypeId: '',
            otpFetchAndroid: '',
            timeHours: '',
            otpFetch: '',
            otpFetchIos: '',
            otpLength: '',
            statusId: '2',
            breachLimit: '',
            createDate: '',
            temporaryBlock: '',
            permanentBlock: '',
            createdBy: '',
            segmentId: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: { ...data },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            delete newData?.data?.payLoad?.otpFetch;
            delete newData?.data?.payLoad?.breachLimit;

            const res = await dispatch(handlePostRequest(newData, '/cms/v1/cms/searchallotpconfig', false, true, 'otp'));

            if (res?.responseCode === '220000') {
                setOtpData(res?.payLoad);
                formik.resetForm();
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const EditUserHandled = (rowData) => {
        navigate('/editOtp', { state: { rowData } });
    };

    const ViewUserHandled = (rowData) => {
        navigate('/viewOtp', { state: { rowData } });
    };

    const addNewUserGroup = () => {
        navigate('/addOtp');
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div>
                    <InputSwitch
                        className="mr-3"
                        checked={rowData.isActive === 'Y'}
                        disabled={['Pending', 'Rejected'].includes(rowData?.statusName) ? true : false}
                        onChange={() => {
                            setSwitchData(rowData);
                            setShowModal(true);
                        }}
                    />
                    <Button
                        tooltip="Edit"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: 'top' }}
                        disabled={['Pending', 'Rejected'].includes(rowData?.statusName) || (rowData?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                        onClick={() => EditUserHandled(rowData)}
                        className="p-button-rounded p-button-warning mr-3"
                    />
                    <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => ViewUserHandled(rowData)} className="p-button-rounded p-button-primary" />
                </div>
            </>
        );
    };

    const handleSwitchChange = async (rowData) => {
        const updatedData = otpData?.map((item) => {
            if (item?.cmsOtpConfigId === switchData?.cmsOtpConfigId) {
                const isActive = item?.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setOtpData(updatedData);
        let isactive = '';
        if (switchData?.isActive === 'Y') {
            isactive = 'N';
        } else if (switchData?.isActive === 'N') {
            isactive = 'Y';
        }

        const previousValue = switchData?.isActive;
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    id: switchData?.cmsOtpConfigId,
                    isActive: isactive
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newData, '/cms/v1/cms/inactiveotpconfig', true, false));
        if (res?.responseCode == '220000') {
            const revertedData = otpData?.map((item) => {
                if (item.cmsOtpConfigId == switchData?.cmsOtpConfigId) {
                    const isActive = isactive;
                    return { ...item, isActive };
                }
                return item;
            });
            setOtpData(revertedData);
        } else {
            const revertedData = otpData?.map((item) => {
                if (item.cmsOtpConfigId == switchData?.cmsOtpConfigId) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setOtpData(revertedData);
        }
    };
    const handleShowModal = () => {
        setShowModal(false);
    };

    const props = {
        showModal,
        handleShowModal,
        handleSwitchChange
    };

    const otpFetchData = [
        { label: 'OTP Fetch Android ', value: 'otpFetchAndroid' },
        { label: 'OTP Fetch Ios ', value: 'otpFetchIos' }
    ];
    const breachLimitsData = [
        { label: 'Temporary Block ', value: 'temporaryBlock' },
        { label: 'Permanent Block ', value: 'permanentBlock' }
    ];
    const otpFetchTemplate = (rowData) => {
        if (rowData.otpFetchIso === 'Y') {
            return 'OTP Fetch iOS';
        } else if (rowData.otpFetchAndroid === 'Y') {
            return 'OTP Fetch Android';
        } else {
            return '';
        }
    };
    const breachLimitTemplate = (rowData) => {
        if (rowData.isTemporaryBlock === 'Y') {
            return 'Temporary Block';
        } else if (rowData.isPermanentBlock === 'Y') {
            return 'Permanent Block';
        } else {
            return '';
        }
    };
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const resetHandle = (e) => {
        e.preventDefault();
        formik.resetForm();
    };
    const statusBodyTemplate = (rowData) => {
        const statusName = statusData?.find((option) => option.lovId == rowData.statusId)?.name;
        const statusColor = statusName === 'Approved' ? '#14A38B' : statusName === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{statusName}</b>
            </p>
        );
    };
    return (
        <>
            <ActiveInActiveDialog {...props} />
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={() => addNewUserGroup()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>

            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="useCaseId" className={classNames({ 'p-error': isFormFieldValid('useCaseId') }, 'Label__Text')}>
                                    Concerned Use Case
                                </label>

                                <Dropdown
                                    id="useCaseId"
                                    placeholder="Select Concerned Use Case"
                                    options={useCaseData}
                                    optionLabel="name"
                                    name="useCaseId"
                                    optionValue="lovId"
                                    value={formik.values.useCaseId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('useCaseId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('useCaseId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="segmentId" className={classNames({ 'p-error': isFormFieldValid('segmentId') }, 'Label__Text')}>
                                    Segment
                                </label>
                                <Dropdown
                                    id="segmentId"
                                    placeholder="Select Segment"
                                    options={segmentsData}
                                    optionLabel="name"
                                    name="segmentId"
                                    optionValue="lovId"
                                    value={formik.values.segmentId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('segmentId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('segmentId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="osTypeId" className={classNames({ 'p-error': isFormFieldValid('osTypeId') }, 'Label__Text')}>
                                    OS Type
                                </label>
                                <Dropdown
                                    id="osTypeId"
                                    placeholder="Select Os Type"
                                    options={osTypeData}
                                    optionLabel="name"
                                    name="osTypeId"
                                    optionValue="lovId"
                                    value={formik.values.osTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('osTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('osTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="otpFetch" className={classNames({ 'p-error': isFormFieldValid('otpFetch') }, 'Label__Text')}>
                                    OTP Fetch
                                </label>

                                <Dropdown
                                    id="otpFetch"
                                    options={otpFetchData}
                                    optionLabel="label"
                                    name="otpFetch"
                                    placeholder="select Value"
                                    optionValue="value"
                                    value={formik?.values?.otpFetch || ''}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        formik.handleChange(e);

                                        if (value === 'otpFetchAndroid') {
                                            formik.setFieldValue('otpFetchAndroid', 'Y');
                                            formik.setFieldValue('otpFetchIos', 'N');
                                        } else if (value === 'otpFetchIos') {
                                            formik.setFieldValue('otpFetchAndroid', 'N');
                                            formik.setFieldValue('otpFetchIos', 'Y');
                                        }
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldValid('otpFetchAndroid') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('otpFetchAndroid')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="osDeviceId" className={classNames({ 'p-error': isFormFieldValid('osDeviceId') }, 'Label__Text')}>
                                    Device
                                </label>
                                <Dropdown
                                    id="osDeviceId"
                                    placeholder="Select Device"
                                    options={deviceData}
                                    optionLabel="name"
                                    optionValue="lovId"
                                    name="osDeviceId"
                                    value={formik.values.osDeviceId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('osDeviceId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('osDeviceId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="otpTypeId" className={classNames({ 'p-error': isFormFieldValid('otpTypeId') }, 'Label__Text')}>
                                    OTP Type
                                </label>
                                <Dropdown
                                    id="otpTypeId"
                                    placeholder="Select Otp Type"
                                    options={otpTypeData}
                                    optionLabel="name"
                                    name="otpTypeId"
                                    optionValue="lovId"
                                    value={formik.values.otpTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('otpTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('otpTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="otpLength" className={classNames({ 'p-error': isFormFieldValid('otpLength') }, 'Label__Text')}>
                                    OTP Length
                                </label>
                                <InputText
                                    maxLength={16}
                                    id="otpLength"
                                    placeholder="Enter OTP Length"
                                    name="otpLength"
                                    value={formik?.values?.otpLength?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('otpLength') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('otpLength')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="attempts" className={classNames({ 'p-error': isFormFieldValid('attempts') }, 'Label__Text')}>
                                    OTP Attempts
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="attempts"
                                    keyfilter="int"
                                    placeholder="Enter Attempts"
                                    name="attempts"
                                    value={formik?.values?.attempts?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('attempts') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('attempts')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="otpTimer" className={classNames({ 'p-error': isFormFieldValid('otpTimer') }, 'Label__Text')}>
                                    OTP Timer
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="otpTimer"
                                    placeholder="Enter Time In Minutes"
                                    name="otpTimer"
                                    value={formik?.values?.otpTimer?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('otpTimer') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('otpTimer')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createDate" className={classNames({ 'p-error': isFormFieldValid('createDate') }, 'Label__Text')}>
                                    Created on
                                </label>
                                <InputText
                                    id="createDate"
                                    type="date"
                                    name="createDate"
                                    max={previousDate}
                                    value={formik?.values?.createDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createDate') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('createDate')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdById"
                                    placeholder="Select Created By"
                                    options={userLovData}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="statusId" className={classNames({ 'p-error': isFormFieldValid('statusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select Status"
                                    options={statusData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="breachLimit" className={classNames({ 'p-error': isFormFieldValid('breachLimit') }, 'Label__Text')}>
                                    Breach Limits
                                </label>

                                <Dropdown
                                    id="breachLimit"
                                    options={breachLimitsData}
                                    optionLabel="label"
                                    placeholder="select Value"
                                    name="breachLimit"
                                    optionValue="value"
                                    value={formik.values.breachLimit || ''}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        formik.handleChange(e);

                                        if (value === 'permanentBlock') {
                                            formik.setFieldValue('permanentBlock', 'Y');
                                            formik.setFieldValue('temporaryBlock', 'N');
                                        } else if (value === 'temporaryBlock') {
                                            formik.setFieldValue('permanentBlock', 'N');
                                            formik.setFieldValue('temporaryBlock', 'Y');
                                        }
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldValid('breachLimit') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('breachLimit')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <label htmlFor="timeHours" className={classNames({ 'p-error': isFormFieldValid('timeHours') }, 'Label__Text')}>
                                Time
                            </label>
                            <InputText
                                maxLength={100}
                                type="text"
                                id="timeHours"
                                placeholder="Enter Time In Minutes"
                                name="timeHours"
                                value={formik?.values?.timeHours}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('timeHours') }, 'Input__Round')}
                            />
                            {getFormErrorMessage('timeHours')}
                        </div>
                        <div className="Down__Btn">
                            <Button disabled={loading} loadingIcon={loadingIcon} label="Search" className="Btn__Dark" />
                            <Button disabled={loading} label="Reset" onClick={(e) => resetHandle(e)} className="Btn__Transparent" />
                        </div>
                    </div>
                </form>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            globalFilterFields={['accountLevelName', 'createuser', 'createdate', 'lkpStatus.statusName']}
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={otpData}
                        >
                            <Column field="usecaseName" header="Concerned Use Case" />
                            {/* <Column field="device" header="Segment" /> */}
                            {/* <Column field="cmsOtpSegmentId" header="Segment" body={(rowData) => segmentsData.find((option) => rowData?.tblCmsOtpSegments?.[0]?.segmentId == option.segmentId)?.name} /> */}
                            <Column field="osTypeName" header="OS Type" />
                            <Column field="otpFetchIso" header="OTP Fetch" body={otpFetchTemplate} />
                            <Column field="device" header="Device" />
                            <Column field="otpLength" header="OTP Length" />
                            <Column field="otpTypeName" header=" OTP Type" />
                            <Column field="otpAttempts" header="OTP Attempts" />
                            <Column field="timeHours" header="Time Minutes" />
                            <Column field="isTemporaryBlock" header="Breach Limits" body={breachLimitTemplate} />
                            <Column header="Created On" body={createdDateAndTimeBodyTemplate} />
                            <Column field="createdBy" header="Created By" />
                            <Column header="Status" body={statusBodyTemplate} />
                            <Column header="Actions" body={actionBodyTemplate} />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
export default OtpScreen;

import React from 'react';
import themeLight from '../../../../assets/cms/themeLight.png';
import themeDark from '../../../../assets/cms/themeDark.png';

function MobileView({ theme }) {
    return (
        <div className="cmsMobileView__main relative">
            {theme === 'light' && <img src={themeLight} className="w-100 h-100" alt="themeLight" />}
            {theme === 'dark' && <img src={themeDark} className="w-100 h-100" alt="themeDark" />}
        </div>
    );
}

export default MobileView;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';

function AddCreateCampaignManagement({ activeIndex, handleStepClick, getCompaignId, campaignData }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [frequencyPeriodLov, setFrequencyPeriodLov] = useState([]);

    const dispatch = useDispatch();

    const getFrequencyPeriodLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getdatalov/LKP_FREQUENCY_PERIOD');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.lovId !== '4');
        setFrequencyPeriodLov(updatedArrayWithoutPending);
    };

    useEffect(() => {
        getFrequencyPeriodLov();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (campaignData) {
            formik.setFieldValue('campaignBudget', campaignData?.budget);
            formik.setFieldValue('campaignName', campaignData?.campaignName);
            formik.setFieldValue('startDateTime', new Date(campaignData?.startDate).toISOString().slice(0, 16));
            formik.setFieldValue('endDateTime', new Date(campaignData?.endDate).toISOString().slice(0, 16));
            if (campaignData?.amount != undefined || campaignData?.amount != null) {
                formik.setFieldValue('isSubLimits', true);
                formik.setFieldValue('frequencyPeriodId', campaignData?.lkpFrequencyPeriod?.frequencyPeriodId?.toString());
                formik.setFieldValue('amount', campaignData?.amount);
            } else {
                formik.setFieldValue('isSubLimits', false);
            }
        }
    }, [campaignData]); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            campaignName: '',
            startDateTime: '',
            endDateTime: '',
            campaignBudget: '',
            isSubLimits: false,
            frequencyPeriodId: '',
            amount: ''
        },

        validate: (data) => {
            let errors = {};
            if (data?.campaignName === '' || !/^[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/.test(data?.campaignName)) {
                errors.campaignName = 'This field is required and can be alhpanumeric value only';
            }
            if (!data?.campaignBudget) {
                errors.campaignBudget = 'Please enter campaign budget';
            }
            if (data?.campaignBudget < 0) {
                errors.campaignBudget = 'Invalid Campaign Budget';
            }
            if (data?.startDateTime === '') {
                errors.startDateTime = 'Start date and time is required';
            }
            if (data?.endDateTime === '') {
                errors.endDateTime = 'End date and time is required';
            } else {
                const startDate = new Date(data.startDateTime);
                const endDate = new Date(data.endDateTime);
                if (startDate.getTime() === endDate.getTime() && endDate.getHours() === startDate.getHours()) {
                    errors.endDateTime = 'Start time and End time cant be same';
                }

                if (endDate.getTime() < startDate.getTime()) {
                    errors.endDateTime = 'End date should be equal to or greater than the start date';
                }
            }

            if (data?.isSubLimits === true) {
                if (data?.frequencyPeriodId === '') {
                    errors.frequencyPeriodId = 'This field is required';
                }
                if (data?.amount === null || data?.amount === '' || data?.amount === 0 || !/^[0-9]*$/.test(data.amount)) {
                    errors.amount = 'Please enter campaign amount';
                }
                if (data?.amount < 0) {
                    errors.amount = 'Invalid Campaign Amount';
                }
                if (parseInt(data?.amount) > parseInt(data?.campaignBudget)) {
                    errors.amount = 'Amount must be less than or equal to the campaign budget';
                }
            }
            return errors;
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const unformatStartDateTime = data?.startDateTime ? data?.startDateTime : '';
            const unformatEndDateTime = data?.endDateTime ? data?.endDateTime : '';

            data['startDateTime'] = formatDate(data?.startDateTime);
            data['endDateTime'] = formatDate(data?.endDateTime);
            data['stepNo'] = '0';

            data['amount'] = data?.amount?.toString();
            data['campaignBudget'] = data?.campaignBudget?.toString();

            if (data.isSubLimits === false) {
                delete data['amount'];
                delete data['frequencyPeriodId'];
            }

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            if (parseInt(campaignData?.currentStep) >= 0) {
                data['campaignId'] = campaignData?.campaignId;
                const formData = new FormData();
                formData.append('request', JSON.stringify(newData));

                const res = await dispatch(handlePostRequest(formData, '/customerloyalitypromotions/v1/customerloyalitypromotion/editcampaign', true, false));
                if (res?.responseCode === '150000') {
                    formik.resetForm();
                    getCompaignId(res?.payLoad?.campaignId);
                    handleStepClick(activeIndex + 1);
                }
            } else {
                const formData = new FormData();
                formData.append('request', JSON.stringify(newData));

                const res = await dispatch(handlePostRequest(formData, '/customerloyalitypromotions/v1/customerloyalitypromotion/savecampaign', true, false));
                if (res?.responseCode === '150000') {
                    formik.resetForm();
                    getCompaignId(res?.payLoad?.campaignId);
                    handleStepClick(activeIndex + 1);
                } else {
                    formik.setFieldValue('startDateTime', unformatStartDateTime);
                    formik.setFieldValue('endDateTime', unformatEndDateTime);
                }
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);

        const day = date.getDate()?.toString().padStart(2, '0');
        const month = (date.getMonth() + 1)?.toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours()?.toString().padStart(2, '0');
        const minutes = date.getMinutes()?.toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours from 24-hour to 12-hour format
        const formattedHours = hours % 12 || 12;

        const formattedDate = `${day}-${month}-${year}, ${formattedHours}:${minutes} ${ampm}`;
        return formattedDate;
    };

    const getCurrentDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1)?.toString().padStart(2, '0');
        const day = now.getDate()?.toString().padStart(2, '0');
        const hours = now.getHours()?.toString().padStart(2, '0');
        const minutes = now.getMinutes()?.toString().padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>Campaign Details</h1>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="campaignName" className={classNames({ 'p-error': isFormFieldValid('campaignName') }, 'Label__Text')}>
                                    Campaign Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="campaignName"
                                    placeholder="Enter Campaign Name"
                                    name="campaignName"
                                    maxLength={50}
                                    value={formik?.values?.campaignName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('campaignName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('campaignName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="startDateTime" className={classNames({ 'p-error': isFormFieldValid('startDateTime') }, 'Label__Text')}>
                                    Campaign Start Date & Time <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="startDateTime"
                                    placeholder="Enter Campaign Start Date & Time"
                                    name="startDateTime"
                                    type="datetime-local"
                                    min={getCurrentDateTime()}
                                    value={formik?.values?.startDateTime || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('startDateTime') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('startDateTime')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="endDateTime" className={classNames({ 'p-error': isFormFieldValid('endDateTime') }, 'Label__Text')}>
                                    Campaign End Date & Time <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="endDateTime"
                                    placeholder="Enter Campaign End Date & Time"
                                    name="endDateTime"
                                    type="datetime-local"
                                    min={getCurrentDateTime()}
                                    value={formik?.values?.endDateTime || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('endDateTime') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('endDateTime')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="campaignBudget" className={classNames({ 'p-error': isFormFieldValid('campaignBudget') }, 'Label__Text')}>
                                    Campaign Budget
                                </label>
                            </div>
                            <InputNumber
                                inputId="campaignBudget"
                                name="campaignBudget"
                                maxLength={9}
                                placeholder="Enter Campaign Budget"
                                value={formik?.values?.campaignBudget || ''}
                                onValueChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('campaignBudget') }, 'Input_Number')}
                            />
                            {getFormErrorMessage('campaignBudget')}
                        </div>
                        <div className="p-field col-12 md:col-4 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="isSubLimits" name="isSubLimits" value={formik?.values?.isSubLimits} checked={formik?.values?.isSubLimits} onChange={formik.handleChange} style={{ marginTop: '10px' }} />
                                    <label htmlFor="isSubLimits" className={classNames({ 'p-error': isFormFieldValid('isSubLimits') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Define Sub Limits
                                    </label>
                                </div>
                                {getFormErrorMessage('noLimits')}
                            </div>
                        </div>
                        {formik?.values?.isSubLimits && (
                            <>
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="frequencyPeriodId" className={classNames({ 'p-error': isFormFieldValid('frequencyPeriodId') }, 'Label__Text')}>
                                            Limit Duration
                                        </label>
                                        <Dropdown
                                            id="frequencyPeriodId"
                                            placeholder="Select Limit Duration"
                                            options={frequencyPeriodLov}
                                            optionLabel="name"
                                            name="frequencyPeriodId"
                                            optionValue="lovId"
                                            value={formik?.values?.frequencyPeriodId || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('frequencyPeriodId') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('frequencyPeriodId')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="amount" className={classNames({ 'p-error': isFormFieldValid('amount') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                    </div>
                                    <InputNumber
                                        inputId="amount"
                                        name="amount"
                                        maxLength={9}
                                        placeholder="Enter Campaign Amount"
                                        value={formik?.values?.amount || ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('amount') }, 'Input_Number')}
                                    />
                                    {getFormErrorMessage('amount')}
                                </div>
                            </>
                        )}
                    </div>
                    <div className="Down__Btn">
                        <Button label={parseInt(campaignData?.currentStep) >= 0 ? 'Edit' : 'Save'} className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default AddCreateCampaignManagement;

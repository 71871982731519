import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';

function AddCardPricingProfile() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [applicationType, setApplicationType] = useState([]);
    const [segment, setSegment] = useState([]);
    const [franchise, setFranchise] = useState([]);
    const [cardType, setCardType] = useState([]);
    const [feeType, setFeeType] = useState([]);
    const [accountType, setAccountType] = useState([]);
    const [waiverData, setWaiverData] = useState([]);
    const [cardPricingData, setCardPricingData] = useState([]);

    const [salaryTransactionTypeData, setSalaryTransactionTypeData] = useState([]);
    const [searchSuccess, setSearchSuccess] = useState(false);
    const [recovery, setRecovery] = useState([]);
    const [instanceRequest, setInstanceRequest] = useState([]);

    const [installmentPlan, setInstallmentPlan] = useState([]);

    const validationSchema = Yup.object({
        agentId: Yup.string().required('This Field is required.'),
        amount: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[0-9]*$/, 'Only Numbers are allowed')
            .required('This Field is required'),
        chargingProfile: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            //.matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
            .required('This Field is required'),
        dcPricingProfileName: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
            .required('This Field is required'),
        salaryTransactionType: Yup.string().required('This Field is required.'),

        chargingProfileType: Yup.string().required('This Field is required.'),
        lkpAccountClassificationId: Yup.string().required('This Field is required.'),
        lkpAccountLevelId: Yup.string().required('This Field is required.'),
        lkpAccountLevelId: Yup.string().required('This Field is required.'),
        lkpDcFeeTypeId: Yup.string().required('This Field is required.'),

        dcCardProductType: Yup.string().required('This Field is required.'),
        lkpDcCardTypeId: Yup.string().required('This Field is required.'),
        lkpDcInstanceRequestId: Yup.string().required('This Field is required.'),
        lkpDcRecoveryId: Yup.string().required('This Field is required.'),

        lkpSegmentId: Yup.string().required('This Field is required.'),
        tblDcInstallmentPlanId: Yup.string().required('This Field is required.'),

        lkpDcWaiverId: Yup.string().when('waiver', {
            is: true,
            then: Yup.string().required('This Field is required.'),
            otherwise: Yup.string()
        }),
        waiver: Yup.boolean()
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            //

            agentId: '',
            amount: '',
            chargingProfile: '',
            dcPricingProfileName: '',
            salaryTransactionType: '',
            waiver: '',
            chargingProfileType: '',
            lkpAccountClassificationId: '',
            lkpAccountLevelId: '',
            dcCardProductType: '',
            lkpDcCardTypeId: '',
            lkpDcFeeTypeId: '',
            lkpDcInstanceRequestId: '',
            lkpDcRecoveryId: '',
            lkpDcWaiverId: '',
            lkpSegmentId: '',
            tblDcInstallmentPlanId: ''
        },

        onSubmit: async (data) => {
            data.waiver = data.waiver ? 'Y' : 'N';
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(newData, '/debitcardmanagement/v1/cards/createcardpricingprofile', true, false, 'cardpricingprofile'));
            if (res?.responseCode === '220000') {
                setCardPricingData(res?.payLoad);
            }

            formik.setFieldValue('waiver', data.waiver === 'Y' ? true : false);
            setloading(false);
            setloadingIcon('pi pi-search');

            // dataTableRef.current.reset();
        }
    });

    const getcardpricingdata = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcardpricingdata');

        setCardType(res?.payLoad?.cardType);
        setApplicationType(res?.payLoad?.applicationType);
        setSegment(res?.payLoad?.segment);
        setFranchise(res?.payLoad?.franchise);
        setAccountType(res?.payLoad?.accountType);
        setFeeType(res?.payLoad?.feeType);
        setRecovery(res?.payLoad?.recovery);
        setInstanceRequest(res?.payLoad?.instanceRequest);
        setInstallmentPlan(res?.payLoad?.installmentPlan);
        setWaiverData(res?.payLoad?.waiver);
    };

    useEffect(() => {
        getcardpricingdata();
    }, []);

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const cardProductType = [
        { label: 'VISA ', value: 'VISA' },
        { label: 'Master Card ', value: 'Master Card' },
        { label: 'PayPak ', value: 'PayPak' },
        { label: 'Virtual Debit Card ', value: 'Virtual Debit Card' }
    ];
    const dcPricingProfileNameData = [
        { label: 'Wallet ', value: 'W' },
        { label: 'Core ', value: 'C' }
    ];

    const search = async () => {
        try {
            const data = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        transactionType: formik.values.salaryTransactionType
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const response = await dispatch(handlePostRequest(data, '/debitcardmanagement/v1/cards/searchtransactiontype/${formik.values.salaryTransactionType}', true, true));

            setSalaryTransactionTypeData(response?.payload);
            setSearchSuccess(response?.responseCode === '220000');
        } catch (error) {
            setSearchSuccess(false);
        }
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>ADD NEW</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="dcPricingProfileName" className={classNames({ 'p-error': isFormFieldValid('dcPricingProfileName') }, 'Label__Text')}>
                                    Profile Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={50}
                                    minLength={3}
                                    id="dcPricingProfileName"
                                    name="dcPricingProfileName"
                                    value={formik?.values?.dcPricingProfileName?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dcPricingProfileName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dcPricingProfileName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lkpDcCardTypeId" className={classNames({ 'p-error': isFormFieldValid('lkpDcCardTypeId') }, 'Label__Text')}>
                                    Card Type<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="lkpDcCardTypeId"
                                    options={cardType}
                                    optionLabel="name"
                                    name="lkpDcCardTypeId"
                                    optionValue="lovId"
                                    value={formik.values.lkpDcCardTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpDcCardTypeId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('lkpDcCardTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="dcCardProductType" className={classNames({ 'p-error': isFormFieldValid('dcCardProductType') }, 'Label__Text')}>
                                    Card Product Type<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="dcCardProductType"
                                    options={cardProductType}
                                    optionLabel="label"
                                    optionValue="value"
                                    name="dcCardProductType"
                                    value={formik.values.dcCardProductType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dcCardProductType') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('dcCardProductType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lkpAccountClassificationId" className={classNames({ 'p-error': isFormFieldValid('lkpAccountClassificationId') }, 'Label__Text')}>
                                    Application Type<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="lkpAccountClassificationId"
                                    options={applicationType}
                                    optionLabel="name"
                                    name="lkpAccountClassificationId"
                                    optionValue="lovId"
                                    value={formik.values.lkpAccountClassificationId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpAccountClassificationId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('lkpAccountClassificationId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lkpSegmentId" className={classNames({ 'p-error': isFormFieldValid('lkpSegmentId') }, 'Label__Text')}>
                                    Segment<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="lkpSegmentId"
                                    options={segment}
                                    optionLabel="name"
                                    name="lkpSegmentId"
                                    optionValue="lovId"
                                    value={formik.values.lkpSegmentId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpSegmentId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('lkpSegmentId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="agentId" className={classNames({ 'p-error': isFormFieldValid('agentId') }, 'Label__Text')}>
                                    Franchise<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="agentId"
                                    options={franchise}
                                    optionLabel="name"
                                    name="agentId"
                                    optionValue="lovId"
                                    value={formik.values.agentId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('agentId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('agentId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lkpAccountLevelId" className={classNames({ 'p-error': isFormFieldValid('lkpAccountLevelId') }, 'Label__Text')}>
                                    Account Type<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="lkpAccountLevelId"
                                    options={accountType}
                                    optionLabel="name"
                                    name="lkpAccountLevelId"
                                    optionValue="lovId"
                                    value={formik.values.lkpAccountLevelId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpAccountLevelId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('lkpAccountLevelId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="chargingProfileType" className={classNames({ 'p-error': isFormFieldValid('chargingProfileType') }, 'Label__Text')}>
                                    Charging Profile Type<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="chargingProfileType"
                                    options={dcPricingProfileNameData}
                                    optionLabel="label"
                                    name="chargingProfileType"
                                    optionValue="value"
                                    value={formik.values.chargingProfileType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('chargingProfileType') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('chargingProfileType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="chargingProfile" className={classNames({ 'p-error': isFormFieldValid('chargingProfile') }, 'Label__Text')}>
                                    Charging Profile Account<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={50}
                                    minLength={3}
                                    keyfilter="num"
                                    id="chargingProfile"
                                    name="chargingProfile"
                                    value={formik?.values?.chargingProfile?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('chargingProfile') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('chargingProfile')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lkpDcFeeTypeId" className={classNames({ 'p-error': isFormFieldValid('lkpDcFeeTypeId') }, 'Label__Text')}>
                                    Fee Type<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="lkpDcFeeTypeId"
                                    options={feeType}
                                    optionLabel="name"
                                    name="lkpDcFeeTypeId"
                                    optionValue="lovId"
                                    value={formik.values.lkpDcFeeTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpDcFeeTypeId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('lkpDcFeeTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="amount" className={classNames({ 'p-error': isFormFieldValid('amount') }, 'Label__Text')}>
                                    Amount<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={50}
                                    minLength={3}
                                    id="amount"
                                    keyfilter="num"
                                    name="amount"
                                    value={formik?.values?.amount?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('amount') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('amount')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lkpDcInstanceRequestId" className={classNames({ 'p-error': isFormFieldValid('lkpDcInstanceRequestId') }, 'Label__Text')}>
                                    Instance select request generation or salary credit (Pre or Post)<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="lkpDcInstanceRequestId"
                                    options={instanceRequest}
                                    optionLabel="name"
                                    name="lkpDcInstanceRequestId"
                                    optionValue="lovId"
                                    value={formik.values.lkpDcInstanceRequestId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpDcInstanceRequestId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('lkpDcInstanceRequestId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 ">
                            <div className="field-checkbox">
                                <div className="mr-2 flex flex-column col-1">
                                    <label htmlFor="waiver" className={classNames({ 'p-error': isFormFieldValid('waiver') }, 'Label__Text')} style={{}}>
                                        Waiver
                                    </label>
                                    <Checkbox inputId="waiver" name="waiver" value={formik?.values?.waiver} checked={formik?.values?.waiver} onChange={formik.handleChange} style={{ marginTop: '10px' }} />
                                </div>
                                <div className="p-field col-12 md:col-11 pt-3">
                                    <div className="p-field">
                                        <label htmlFor="lkpDcWaiverId" className={classNames({ 'p-error': isFormFieldValid('lkpDcWaiverId') }, 'Label__Text')}></label>
                                        <Dropdown
                                            id="lkpDcWaiverId"
                                            placeholder="Select one option"
                                            options={waiverData}
                                            optionLabel="name"
                                            name="lkpDcWaiverId"
                                            optionValue="lovId"
                                            value={formik.values.lkpDcWaiverId || ''}
                                            onChange={formik.handleChange}
                                            disabled={!formik.values.waiver}
                                            className={classNames({ 'p-invalid': isFormFieldValid('lkpDcWaiverId') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('lkpDcWaiverId')}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-2 pt-4">
                            <div className="p-field">
                                <label htmlFor="salaryTransactionType" className={classNames({ 'p-error': isFormFieldValid('salaryTransactionType') }, 'Label__Text')}>
                                    Salary Transaction Type<span className="Label__Required">*</span>
                                </label>

                                <InputText
                                    id="salaryTransactionType"
                                    placeholder=""
                                    name="salaryTransactionType"
                                    value={formik?.values?.salaryTransactionType || ''}
                                    onChange={formik.handleChange}
                                    disabled={searchSuccess} // Disable salaryTransactionType field if searchSuccess is true
                                    className={classNames({ 'p-invalid': isFormFieldValid('salaryTransactionType') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('salaryTransactionType')}
                            </div>
                        </div>

                        <div className="p-field  md:col-2  mt-5">
                            <div className="Down__Btn__Configuration">
                                <Button type="button" label="Search" className="Btn__Add" onClick={() => search()} />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-4 ">
                            <div className="p-field">
                                <label htmlFor="lkpDcRecoveryId" className={classNames({ 'p-error': isFormFieldValid('lkpDcRecoveryId') }, 'Label__Text')}>
                                    Recovery<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="lkpDcRecoveryId"
                                    options={recovery}
                                    optionLabel="name"
                                    name="lkpDcRecoveryId"
                                    optionValue="lovId"
                                    value={formik.values.lkpDcRecoveryId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpDcRecoveryId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('lkpDcRecoveryId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="tblDcInstallmentPlanId" className={classNames({ 'p-error': isFormFieldValid('tblDcInstallmentPlanId') }, 'Label__Text')}>
                                    Installment Plan<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="tblDcInstallmentPlanId"
                                    options={installmentPlan}
                                    optionLabel="name"
                                    name="tblDcInstallmentPlanId"
                                    optionValue="lovId"
                                    value={formik.values.tblDcInstallmentPlanId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('tblDcInstallmentPlanId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('tblDcInstallmentPlanId')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        {/* <Button disabled={loading} label="Submit" type="submit" className="Btn__Dark" /> */}
                        <Button disabled={!searchSuccess || loading} label="Submit" type="submit" className="Btn__Dark" />
                        <Button onClick={() => navigate('/cardpricingprofile')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default AddCardPricingProfile;

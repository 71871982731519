import React, { useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import { SearchButton, ResetButton } from '../../../components/SearchButton';

function Reporting() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    // const [showModal, setShowModal] = useState(false);

    const dataTableRef = useRef(null);
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({});

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            domain: '',
            createdBy: '',
            createdOn: '',
            statusId: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            // const newData = {
            //     data: {
            //         security: {
            //             userName: '',
            //             password: '',
            //             securityToken: ''
            //         },
            //         account: {
            //             msidn: '',
            //             iban: '',
            //             bban: '',
            //             pan: '',
            //             currency: ''
            //         },
            //         channel: '',
            //         terminal: '',
            //         reterivalReferenceNumber: '',
            //         payLoad: data,
            //         additionalInformation: [
            //             {
            //                 infoKey: '',
            //                 infoValue: ''
            //             }
            //         ],
            //         checkSum: ''
            //     }
            // };

            dataTableRef.current.reset();

            setloading(false);
            setloadingIcon('pi pi-search');

            dataTableRef.current.reset();
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                {/* <h5 className="m-0">Type of Account List</h5> */}
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    {/* <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." /> */}
                </span>
            </div>
        );
    };

    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.lkpStatus?.statusDescr === 'Approved' ? '#14A38B' : rowData?.lkpStatus?.statusDescr === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.lkpStatus?.statusDescr}</b>
            </p>
        );
    };
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    //const previousDate = currentDate.toISOString().split('T')[0];

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    {
                        <>
                            <InputSwitch
                                checked={rowData.isActive === 'Y'}
                                disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                                // onChange={() => {
                                //     setShowModal(true);
                                //     //setCommissionProfileIdData(rowData?.commissionProfileId);
                                //     //setIsActiveData(rowData?.isActive);
                                // }}
                            />
                            <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => navigate('/viewreporting')} className="p-button-rounded p-button-primary" />
                        </>
                    }
                </div>
            </>
        );
    };

    const header = renderHeader();

    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    // const handleShowModal = () => {
    //     setShowModal(false);
    // };

    // const props = {
    //     showModal,
    //     handleShowModal
    //     // handleSwitchChange
    // };

    const reportingDataTable = [
        { id: 1, domain: 'Domain 1', createdBy: 'createdBy', createdOn: 'createdOn', statusId: 'status' },
        { id: 2, domain: 'Domain 2', createdBy: 'createdBy', createdOn: 'createdOn', statusId: 'status' },
        { id: 3, domain: 'Domain 3', createdBy: 'createdBy', createdOn: 'createdOn', statusId: 'status' }
    ];
    return (
        <>
            {/* <ActiveInActiveDialog {...props} /> */}
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="domain" className={classNames({ 'p-error': isFormFieldValid('domain') }, 'Label__Text')}>
                                    Domain
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="domain"
                                    placeholder="Enter Domain"
                                    name="domain"
                                    value={formik?.values?.domain?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('domain') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('domain')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={[]}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdOn" className={classNames({ 'p-error': isFormFieldValid('createdOn') }, 'Label__Text')}>
                                    Created On
                                </label>
                                <InputText
                                    id="createdOn"
                                    placeholder=""
                                    name="createdOn"
                                    type="date"
                                    // max={currentDate}
                                    value={formik?.values?.createdOn?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdOn') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('createdOn')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="statusId" className={classNames({ 'p-error': isFormFieldValid('statusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select Status"
                                    options={[]}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <SearchButton />
                        <ResetButton onClick={handleReset} />
                    </div>
                </form>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            globalFilterFields={['accountLevelName', 'createuser', 'createdate', 'lkpStatus.statusName']}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={reportingDataTable}
                        >
                            <Column field="domain" header="Domain" />
                            <Column field="createdBy" header="CreatedBy" />
                            <Column field="createdOn" header="Created On" />
                            <Column body={statusBodyTemplate} header="Status" />
                            <Column header="Action" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Reporting;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { MultiSelect } from 'primereact/multiselect';
import { InputTextarea } from 'primereact/inputtextarea';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { Checkbox } from 'primereact/checkbox';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewSlabWiseFixedChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [allLovs, setAllLovs] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [requestedDataById, setRequestedDataByID] = useState();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1); // Subtract 1 day
    // const previousDate = currentDate.toISOString().split('T')[0];

    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const tableName = query.get('tableName');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const checkerComments = query.get('checkerComments');

    const getAllLovsForCommission = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcommissionprofiledata');
        setAllLovs(resp?.payLoad);
    };

    useEffect(() => {
        getAllLovsForCommission();
    }, []);

    const getDatabyId = async () => {
        const resp = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getcommissionprofilebyid/${refTableId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik?.setFieldValue(key, keyData[key]);
                }
            });
            const multiselectDataProduct = keyData?.tblCommissionProducts?.map((item) => {
                return item?.tblProduct?.productId.toString(); // Convert to string
            });
            formik.setFieldValue('disbursementMethodName', keyData?.lkpDisbursementMethod?.disbursementMethodName?.toString());
            formik.setFieldValue('accountClassificationId', keyData?.lkpAccountClassification?.accountClassificationId?.toString());
            formik.setFieldValue('commissionGlAccountId', keyData?.tblAccount1.accountId.toString());
            formik.setFieldValue('whtGlAccountId', keyData?.tblAccount2.accountId.toString());
            formik.setFieldValue('commissionSlabRequests', keyData?.tblCommissionSlabs);
            formik.setFieldValue('startDate', keyData?.startDate?.slice(0, 10));
            formik.setFieldValue('endDate', keyData?.endDate?.slice(0, 10));

            if (multiselectDataProduct && multiselectDataProduct.length > 0) {
                formik.setFieldValue('products', multiselectDataProduct);
            }
        }
    };
    const getMcRequestDataById = async () => {
        setloading(true);

        const res = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getCommissionProfileCheckerById/${mcRequestId}`);
        const keyData = res?.payLoad;

        setloading(false);

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });

            const multiselectDataProduct = keyData?.products?.map((item) => {
                return item?.productId.toString(); // Convert to string
            });

            formik.setFieldValue('disbursementMethodName', keyData?.disbursementMethodName?.toString());
            formik.setFieldValue('accountClassificationId', keyData?.accountClassificationId?.toString());
            formik.setFieldValue('commissionGlAccountId', keyData?.commissionGlAccountId?.toString());
            formik.setFieldValue('whtGlAccountId', keyData?.whtGlAccountId?.toString());
            formik.setFieldValue('commissionSlabRequests', keyData?.commissionSlabRequests);
            formik.setFieldValue('startDate', keyData?.startDate?.slice(0, 10));
            formik.setFieldValue('endDate', keyData?.endDate?.slice(0, 10));

            if (multiselectDataProduct && multiselectDataProduct.length > 0) {
                formik.setFieldValue('products', multiselectDataProduct);
            }
        }
    };

    const getMcRequestDataByIdU = async () => {
        setloading(true);

        const res = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getCommissionProfileCheckerById/${mcRequestId}`);
        const keyData = res?.payLoad;

        setloading(false);

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
        }
        setRequestedDataByID(res?.payLoad);
    };

    const getRequestedSlabWiseFixedData = async () => {
        const resp = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getcommissionprofilebyid/${requestedDataById?.commissionProfileId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik?.setFieldValue(key, keyData[key]);
                }
            });

            const multiselectDataProduct = keyData?.tblCommissionProducts?.map((item) => {
                return item?.tblProduct?.productId.toString(); // Convert to string
            });
            formik.setFieldValue('disbursementMethodName', keyData?.lkpDisbursementMethod?.disbursementMethodName?.toString());
            formik.setFieldValue('accountClassificationId', keyData?.lkpAccountClassification?.accountClassificationId?.toString());
            formik.setFieldValue('commissionGlAccountId', keyData?.tblAccount1.accountId.toString());
            formik.setFieldValue('whtGlAccountId', keyData?.tblAccount2.accountId.toString());
            formik.setFieldValue('commissionSlabRequests', keyData?.tblCommissionSlabs);
            formik.setFieldValue('startDate', keyData?.startDate?.slice(0, 10));
            formik.setFieldValue('endDate', keyData?.endDate?.slice(0, 10));

            if (multiselectDataProduct && multiselectDataProduct.length > 0) {
                formik.setFieldValue('products', multiselectDataProduct);
            }
        }
    };

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getMcRequestDataById();
        } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
            getMcRequestDataByIdU();
        }
    }, []);

    useEffect(() => {
        if (requestTypeDescr === 'Enable/Disable Request' && requestType === 'U' && requestedDataById?.commissionProfileId !== undefined) {
            getRequestedSlabWiseFixedData();
        }
    }, [requestedDataById?.commissionProfileId]);

    const accounttype = allLovs?.accounttype?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });
    const transactions = allLovs?.transactions?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });
    const glAccounts = allLovs?.glAccounts?.map((data) => {
        return {
            accountId: data?.accountId,
            accountNo: `${data?.accountNo}-${data?.accountTitle}`
        };
    });
    const disbursementMethod = allLovs?.disbursementMethod?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });

    const validationSchema = Yup.object().shape({
        action: Yup.mixed().required('This field is required.'),
        checkerComments: Yup.mixed().required('This field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            commissionProfileId: refTableId,
            accountClassificationId: '',
            commissionProfileName: '',
            products: [],
            commissionAmount: '',
            disbursementMethodName: '',
            disbursementTime: '',
            whtCalculationType: '',
            startDate: '',
            endDate: '',
            commissionSlabRequests: [
                {
                    commissionSlabId: '',
                    startSlab: '',
                    endSlab: '',
                    commissionAmount: ''
                }
            ],
            action: '',
            checkerComments: '',
            isActive: false
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const addSlabWiseFixed = () => {
        formik.setFieldValue('commissionSlabRequests', [
            ...formik.values.commissionSlabRequests,
            {
                startSlab: '',
                endSlab: '',
                commissionAmount: ''
            }
        ]);
    };
    const removeSlabWiseFixed = (index) => {
        const updatedData = [...formik.values.commissionSlabRequests];
        updatedData.splice(index, 1);
        formik.setFieldValue('commissionSlabRequests', updatedData);
    };

    const WhtOption = [
        { name: 'INCLUSIVE', value: 'I' },
        { name: 'EXCLUSIVE', value: 'E' }
    ];

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>VIEW DETAIL</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                    Account Type
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="accountClassificationId"
                                    placeholder="Select Account Type"
                                    options={accounttype}
                                    optionLabel="name"
                                    name="accountClassificationId"
                                    optionValue="lovId"
                                    value={formik.values.accountClassificationId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountClassificationId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('accountClassificationId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="profilename" className={classNames({ 'p-error': isFormFieldValid('profilename') }, 'Label__Text')}>
                                    Profile Name <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="commissionProfileName"
                                    placeholder="Enter Profile Name"
                                    name="commissionProfileName"
                                    value={formik?.values?.commissionProfileName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('commissionProfileName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('commissionProfileName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="products" className={classNames({ 'p-error': isFormFieldValid('products') }, 'Label__Text')}>
                                    Transactions
                                </label>
                                <MultiSelect
                                    filter
                                    // disabled={true}
                                    id="products"
                                    placeholder="Select Transactions"
                                    options={transactions}
                                    optionLabel="name"
                                    name="products"
                                    optionValue="lovId"
                                    value={formik.values.products || []}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('products') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('tblApiSegments')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="whtCalculationType" className={classNames({ 'p-error': isFormFieldValid('whtCalculationType') }, 'Label__Text')}>
                                    Type of WHT Calculation
                                    <span className="Label__Required"></span>
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="whtCalculationType"
                                    placeholder="Select Type of WHT Calculation"
                                    options={WhtOption}
                                    optionLabel="name"
                                    name="whtCalculationType"
                                    optionValue="value"
                                    value={formik.values.whtCalculationType}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('whtCalculationType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('whtCalculationType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3">
                            <div className="p-field">
                                <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') }, 'Label__Text')}>
                                    Start Date
                                </label>
                                <InputText
                                    disabled={true}
                                    id="startDate"
                                    placeholder=""
                                    name="startDate"
                                    type="date"
                                    value={formik?.values?.startDate?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('startDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('startDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3">
                            <div className="p-field">
                                <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') }, 'Label__Text')}>
                                    End Date
                                </label>
                                <InputText
                                    disabled={true}
                                    id="endDate"
                                    placeholder=""
                                    name="endDate"
                                    type="date"
                                    value={formik?.values?.endDate?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('endDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('endDate')}
                            </div>
                        </div>
                        <>
                            <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card">
                                <div className="Form__Header">
                                    <h1>SLAB WISE FIXED </h1>
                                </div>
                                {formik?.values?.commissionSlabRequests?.map((id, index) => (
                                    <div key={`id-${index}`} style={{ backgroundColor: '#FFFFFF', padding: '3rem' }} className="card">
                                        <div className="grid">
                                            <div className="col-3">
                                                <div className="p-field pt-3 pb-3">
                                                    <label htmlFor={`startSlab${index}`} className={classNames({ 'p-error': isFormFieldValid(`[commissionSlabRequests${index}].startSlab`) }, 'Label__Text')}>
                                                        Start Slab <span className="Label__Required"></span>
                                                    </label>
                                                    <InputText
                                                        disabled={true}
                                                        id={`startSlab${index}`}
                                                        placeholder="Enter Start Slab"
                                                        name={`commissionSlabRequests[${index}].startSlab`}
                                                        value={id.startSlab || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`commissionSlabRequests[${index}].startSlab`) }, 'Input__Round')}
                                                    />
                                                    {getFormErrorMessage(`commissionSlabRequests[${index}].startSlab`)}
                                                </div>
                                            </div>

                                            <div className="col-3">
                                                <div className="p-field pt-3 pb-3">
                                                    <label htmlFor={`endSlab${index}`} className={classNames({ 'p-error': isFormFieldValid(`[commissionSlabRequests${index}].endSlab`) }, 'Label__Text')}>
                                                        End Slab <span className="Label__Required"></span>
                                                    </label>
                                                    <InputText
                                                        disabled={true}
                                                        id={`endslab${index}`}
                                                        placeholder="Enter End Slab "
                                                        name={`commissionSlabRequests[${index}].endSlab`}
                                                        value={id.endSlab || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`commissionSlabRequests[${index}].endSlab`) }, 'Input__Round')}
                                                    />
                                                    {getFormErrorMessage(`commissionSlabRequests[${index}].endSlab`)}
                                                </div>
                                            </div>

                                            <div className="col-3">
                                                <div className="p-field pt-3 pb-3">
                                                    <label htmlFor={`commissionAmount${index}`} className={classNames({ 'p-error': isFormFieldValid(`[commissionSlabRequests${index}].commissionAmount`) }, 'Label__Text')}>
                                                        Fee <span className="Label__Required"></span>
                                                    </label>
                                                    <InputText
                                                        disabled={true}
                                                        id={`commissionAmount${index}`}
                                                        placeholder="Enter Fee"
                                                        name={`commissionSlabRequests[${index}].commissionAmount`}
                                                        value={id.commissionAmount || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`commissionSlabRequests[${index}].commissionAmount`) }, 'Input__Round')}
                                                    />
                                                    {getFormErrorMessage(`commissionSlabRequests[${index}].commissionAmount`)}
                                                </div>
                                            </div>

                                            <div className="col-3">
                                                <div className="flex flex-column align-items-center justify-content-center">
                                                    <Button
                                                        disabled={true}
                                                        icon="pi pi-plus"
                                                        className="p-button-rounded"
                                                        aria-label="Filter"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            addSlabWiseFixed();
                                                        }}
                                                    />
                                                    <Button
                                                        disabled={true}
                                                        icon="pi pi-minus"
                                                        className="p-button-rounded mt-2 p-button-danger"
                                                        aria-label="Filter"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            removeSlabWiseFixed(index);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="flex flex-row">
                                    <div className="col-6 md:col-3">
                                        <div className="p-field pt-3 pb-3">
                                            <label htmlFor="commissionGlAccountId" className={classNames({ 'p-error': isFormFieldValid('commissionGlAccountId') }, 'Label__Text')}>
                                                Select Commission GL
                                                <span className="Label__Required"></span>
                                            </label>
                                            <Dropdown
                                                disabled={true}
                                                id="commissionGlAccountId"
                                                placeholder="Select Commission GL"
                                                options={glAccounts}
                                                optionLabel="accountNo"
                                                name="commissionGlAccountId"
                                                filter
                                                optionValue="accountId"
                                                value={formik.values.commissionGlAccountId || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('commissionGlAccountId') }, 'Dropdown__Round')}
                                            />

                                            {getFormErrorMessage('commissionGlAccountId')}
                                        </div>
                                    </div>
                                    <div className="col-6 md:col-3">
                                        <div className="p-field pt-3 pb-3">
                                            <label htmlFor="whtGlAccountId" className={classNames({ 'p-error': isFormFieldValid('whtGlAccountId') }, 'Label__Text')}>
                                                Select WHT GL
                                                <span className="Label__Required"></span>
                                            </label>
                                            <Dropdown
                                                disabled={true}
                                                id="whtGlAccountId"
                                                placeholder="Select WHT GL"
                                                options={glAccounts}
                                                optionLabel="accountNo"
                                                name="whtGlAccountId"
                                                filter
                                                optionValue="accountId"
                                                value={formik.values.whtGlAccountId || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('whtGlAccountId') }, 'Dropdown__Round')}
                                            />

                                            {getFormErrorMessage('whtGlAccountId')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="disbursementMethodName" className={classNames({ 'p-error': isFormFieldValid('disbursementMethodName') }, 'Label__Text')}>
                                    Select Disbursement Method
                                    <span className="Label__Required"></span>
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="disbursementMethodName"
                                    placeholder="Select Disbursement Method"
                                    options={disbursementMethod}
                                    optionLabel="name"
                                    name="disbursementMethodName"
                                    optionValue="name"
                                    value={formik.values.disbursementMethodName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('disbursementMethodName') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('disbursementMethodName')}
                            </div>
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                Is Active
                            </label>
                            <div className="field-checkbox">
                                <Checkbox inputId="isActive" disabled={true} name="isActive" value={formik?.values?.isActive} checked={formik?.values?.isActive} onChange={formik.handleChange} />
                            </div>
                            {getFormErrorMessage('isActive')}
                        </div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    disabled={true}
                                    rows={5}
                                    cols={30}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={checkerComments}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} label="Okay" onClick={() => navigate('/requestchecker')} className="Btn__Dark" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default ViewSlabWiseFixedChecker;

import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function BulkDisbursementView() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const location = useLocation();
    const [batchData, setBatchData] = useState();
    const [segmentData, setSegmentData] = useState();
    const { state } = location;
    const tableName = 'TBL_BULK_OPR_FILE';
    const queryParams = new URLSearchParams(location.search);
    const batchProcessingStatus = queryParams.get('batchProcessingStatus');
    const dt = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formik = useFormik({
        validationSchema: null,
        initialValues: {
            services: '',
            product: '',
            dateTo: '',
            batchNumber: '',
            dateFrom: '',
            updatedBy: '',
            createdBy: '',
            statusId: ''
        },

        onSubmit: async () => {}
    });

    const handleSubmit = async () => {
        setloading(true);
        setloadingIcon('pi pi-spin pi-spinner');
        const payLoad = {
            bulkOprFileId: batchData?.bulkOprFileId,
            batchType: 'BDA',
            fileStatusId: batchData?.fileStatusId?.fileStatusId
        };
        const data = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        try {
            const res = await dispatch(handlePostRequest(data, '/batchprocessing/v1/batchprocessing/submit', true, false, 'disbursementFile'));
        } catch (error) {}
        setloading(false);
        setloadingIcon('pi pi-search');
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());

    const getBatchInformation = async () => {
        const res = await handleGetRequest(`/batchprocessing/v1/batchprocessing/getbatchbyid/${state.batchNo}`);

        setBatchData(res.payLoad);
    };

    const getBatchRecordDetails = async () => {
        const resp = await handleGetRequest(`/batchprocessing/v1/batchprocessing/getfilerecordbyid/${state?.bulkOprFileId}`);
        setSegmentData(resp?.payLoad);
    };
    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    useEffect(() => {
        getBatchRecordDetails();
        getBatchInformation();
    }, [location]);
    const exportCSV = () => {
        dt.current.exportCSV();
    };
    return (
        <>
            <div className="Top__Btn Top__Btn__Border">
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <div>
                        <img src="/csv.png" alt="CSV" style={{ width: '70%', height: '60%' }} onClick={exportCSV} />
                    </div>
                </div>
            </div>
            <div className="card Card__Round">
                <div className="Form__Header">{batchProcessingStatus === 'Ready for Submit' ? <h1>DISBURSEMENT BATCH DETAILS / Edit</h1> : <h1>DISBURSEMENT BATCH DETAILS / View</h1>}</div>
                <div className="Batch_Details_Card flex justify-content-center align-items-center ">
                    <div className="col-12 md:col-5 sm:col-12 flex md:flex-row flex-column">
                        <div className="col-12 md:col-4 sm:col-12">
                            <p className="line-height-1">Batch ID</p>
                            <p className="line-height-1 text-blue-700 font-bold">{batchData?.batchId}</p>
                        </div>
                        <div className="col-12 md:col-4 sm:col-12">
                            <p className="line-height-1">Date</p>
                            <p className="line-height-1">{createdDateAndTimeBodyTemplate(batchData)}</p>
                        </div>
                        <div className="col-12 md:col-4 sm:col-12">
                            <p className="line-height-1">Batch Status</p>
                            <p className="line-height-1" style={{ color: batchData?.fileStatusId?.fileStatusName === 'Ready for Submit' ? 'orange' : 'green' }}>
                                {batchData?.fileStatusId?.fileStatusName}
                            </p>
                        </div>
                    </div>
                    <div className="col-12 md:col-7 sm:col-12 flex md:flex-row flex-column">
                        <div className="col-12 md:col-4 sm:col-12">
                            <div className="flex  p-4 border-round justify-content-center align-items-center gap-3 card_lime">
                                <p className="mb-0 ">Valid Records</p> <p className="text-green-500 font-bold text-2xl">{batchData?.validRecords}</p>
                            </div>
                        </div>
                        <div className="col-12 md:col-4 sm:col-12">
                            <div className="flex  p-4 border-round fixed__height card_lime justify-content-center align-items-center gap-3 card_rose">
                                <p className="mb-0 ">Invalid Records</p> <p className="text-pink-600 font-bold text-2xl">{batchData?.invalidRecords}</p>
                            </div>
                        </div>
                        <div className="col-12 md:col-4 sm:col-12">
                            <div className="flex bg-cyan-100 p-4 border-round fixed__height justify-content-center align-items-center gap-3 card_sky">
                                <p className="mb-0 ">Total Records</p> <p className="text-blue-500 font-bold text-2xl">{batchData?.totalRecords}</p>
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                </div>
                <div className="Form__Header mt-5">
                    <h1>DISBURSEMENT FILE SUMMARY DETAILS </h1>
                </div>

                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <DataTable
                                ref={dt}
                                paginator
                                rows={5}
                                rowsPerPageOptions={[5, 10, 25]}
                                className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} "
                                emptyMessage="No List found."
                                responsiveLayout="scroll"
                                value={segmentData}
                            >
                                <Column field="serialNo" header="Emp/Reg No" />
                                <Column field="name" header="Name" />
                                <Column field="registrationNo" header="Customer ID" />
                                <Column field="cnic" header="CNIC" />
                                <Column field="mobileNo" header="Mobile No" />
                                <Column field="Emp/RegNo" header="Product" />
                                <Column field="status" header="A/C Creation Status" />
                                <Column field="chequeNo" header="Cheque No" />
                                <Column field="amount" header="Amount" />
                                <Column field="status" header="Payment Date" />
                                <Column field="batchId" header="Batch Number" />
                                <Column field="transactionId" header="Transaction ID" />
                                <Column field="status" header="Uploaded By" />
                                <Column field="validationStatus" header="Status" />
                            </DataTable>
                        </div>
                    </div>

                    <div className="Usergroup__SearchBtn p-field flex flex-row  justify-content-center pt-2 col-12" style={{ textAlign: 'center' }}>
                        {batchData?.fileStatusId?.fileStatusName === 'Ready for Submit' ? (
                            <div className="Down__Btn">
                                <Button disabled={loading} loadingIcon={loadingIcon} label="Submit" onClick={handleSubmit} className="Btn__Dark" />
                                <Button disabled={loading} type="button" onClick={() => navigate('/disbursementFile')} label="Cancel" className="Btn__Transparent" />
                            </div>
                        ) : (
                            <div className="Down__Btn">
                                <Button disabled={loading} label="Okay" onClick={() => navigate('/disbursementFile')} className="Btn__Dark" />
                            </div>
                        )}
                    </div>
                </div>
                {/* </form> */}
            </div>

            {batchData?.fileStatusId?.fileStatusName !== 'Ready for Submit' && <AuthorizationHistoryTable refTableId={state?.bulkOprFileId} tableName={tableName} />}
        </>
    );
}
export default BulkDisbursementView;

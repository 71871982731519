import React from 'react'
import { Button } from 'primereact/button';
import { baseURL } from '../../../Config';

function ImageDataModalChecker({ merchantData, onHideDialog, cnicFrontActive, cnicBackActive, selfieActive, proofOfBusinessActive }) {
  return (
    <>
            {cnicFrontActive && <img alt="image" style={{ height: '200px', width: '30vw' }} src={`${baseURL}${merchantData[0]?.fieldValue}`} />}
            {cnicBackActive && <img alt="image" style={{ height: '200px', width: '30vw' }} src={`${baseURL}${merchantData[1]?.fieldValue}`} />}
            {selfieActive && <img alt="image" style={{ height: '200px', width: '30vw' }} src={`${baseURL}${merchantData[2]?.fieldValue}`} />}
            {proofOfBusinessActive && <img alt="image" style={{ height: '200px', width: '30vw' }} src={`${baseURL}${merchantData[3]?.fieldValue}`} />}
            <div className="Down__Btn">
                <Button label="Ok" className="Btn__Add" onClick={onHideDialog} />
            </div>
        </>
  )
}

export default ImageDataModalChecker
import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handlePostRequest } from '../../../services/PostTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import AddKycSetsDialog from './AddKycSetsDialog';
import EditKycSetsDialog from './EditKycSetsDialog';
import ViewKycSetsDialog from './ViewKycSetsDialog';
import { handleGetRequest } from '../../../services/GetTemplate';
import { FilterMatchMode } from 'primereact/api';
import ActiveInActiveDialog from '../../../components/ActiveInActiveDialog/ActiveInActiveDialog';

function KycSets() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [kycSetsItems, setKycSetsItems] = useState([]);
    const [kycSetsRowData, setKycSetsRowData] = useState();
    const [kycSetsViewRowData, setKycSetsViewRowData] = useState();

    const [addKycSetsDialog, setAddKycSetsDialog] = useState(false);
    const [viewKycSetsDialog, setViewKycSetsDialog] = useState(false);
    const [editKycSetsDialog, setEditKycSetsDialog] = useState(false);

    const [clientTypeLovData, setClientTypeLovData] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        kycSetName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [isActiveData, setIsActiveData] = useState();
    const [kycSetIdData, setKycSetIdData] = useState();

    const dataTableRef = useRef(null);

    const dispatch = useDispatch();

    const handledClicked = () => {
        setAddKycSetsDialog(true);
    };

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            kycSetName: '',
            clientRoleType: '',
            dateFrom: '',
            dateTo: '',
            status: '2',
            createdBy: '',
            updatedBy: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['accountClassificationId'] = data?.clientRoleType;
            delete data['clientRoleType'];

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            dataTableRef.current.reset();

            const res = await dispatch(handlePostRequest(newData, '/account/v1/kycsets/getallkycsets', true, true));
            if (res?.responseCode === '010000') {
                setKycSetsItems(res?.payLoad);
            } else {
                setKycSetsItems([]);
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ''));
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">KYC Sets List</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const header = renderHeader();

    const EditKycSet = (rowData) => {
        setEditKycSetsDialog(true);
        setKycSetsRowData(rowData);
    };

    const ViewKycSet = (rowData) => {
        setViewKycSetsDialog(true);
        setViewKycSetsDialog(true);
        setKycSetsViewRowData(rowData);
    };

    const handleSwitchChange = async () => {
        const updatedData = kycSetsItems.map((item) => {
            if (item?.kycSetHeadId === kycSetIdData) {
                const isActive = item?.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setKycSetsItems(updatedData);
        let isactive = '';
        if (isActiveData === 'Y') {
            isactive = 'N';
        } else if (isActiveData === 'N') {
            isactive = 'Y';
        }
        const previousValue = isActiveData;

        const kycSetDetailsWithMandatory = kycSetsRowData?.tblKycSetDetails?.map((item) => ({
            kycAttributesId: item?.tblKycAttribute?.kycAttributesId,
            isMandatory: item?.isMandatory
        }));

        const data = {
            isActive: isactive,
            kycSetHeadId: kycSetIdData,

            kycSetName: kycSetsRowData?.kycSetName,
            accountClassificationId: parseInt(kycSetsRowData?.lkpAccountClassification?.accountClassificationId),
            tblKycSetDetails: kycSetDetailsWithMandatory
        };

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/account/v1/kycsets/inactiveKycSets', true, false));
        if (res?.responseCode !== '010000') {
            const revertedData = kycSetsItems.map((item) => {
                if (item.kycSetHeadId === kycSetIdData) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setKycSetsItems(revertedData);
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <InputSwitch
                        checked={rowData.isActive === 'Y'}
                        // disabled={true}
                        // this code is disabled for now after discussing with Solution-architecture team
                        disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                        // onChange={() => handleSwitchChange(rowData?.kycSetHeadId, rowData?.isActive, rowData)}
                        onChange={() => {
                            setShowModal(true);
                            setKycSetIdData(rowData.kycSetHeadId);
                            setIsActiveData(rowData?.isActive);
                            setKycSetsRowData(rowData);
                        }}
                    />
                    <Button
                        tooltip="Edit"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: 'top' }}
                        // disabled={true}
                        // this code is disabled for now after discussing with Solution-architecture team
                        disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) || (rowData?.lkpStatus?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                        onClick={() => EditKycSet(rowData)}
                        className="p-button-rounded p-button-primary"
                    />
                    <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => ViewKycSet(rowData)} className="p-button-rounded" style={{ backgroundColor: '#F2AC57', border: 'none' }} />
                </div>
            </>
        );
    };

    const onHideAddKycSetsDialog = () => {
        setAddKycSetsDialog(false);
    };

    const onHideEditKycSetsDialog = () => {
        setEditKycSetsDialog(false);
    };

    const onHideViewKycSetDialog = () => {
        setViewKycSetsDialog(false);
    };

    const getClientTypeLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getclientroles/LKP_ACCOUNT_CLASSIFICATION:*');
        setClientTypeLovData(resp?.payLoad);
    };

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(resp?.payLoad);
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };

    useEffect(() => {
        getStatusLov();
        getUserLov();
        getClientTypeLov();
    }, []); // eslint-disable-line

    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const currentDate = new Date()?.toISOString()?.split('T')[0];

    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.lkpStatus?.statusDescr === 'Approved' ? '#14A38B' : rowData?.lkpStatus?.statusDescr === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.lkpStatus?.statusDescr}</b>
            </p>
        );
    };

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const handleShowModal = () => {
        setShowModal(false);
    };

    const props = {
        showModal,
        handleShowModal,
        handleSwitchChange
    };

    return (
        <>
            <ActiveInActiveDialog {...props} />

            <Dialog visible={addKycSetsDialog} resizable={false} blockScroll draggable={false} header={'Add KYC Sets'} style={{ width: '850px' }} onHide={() => setAddKycSetsDialog(false)}>
                <AddKycSetsDialog onHideAddKycSetsDialog={onHideAddKycSetsDialog} />
            </Dialog>
            <Dialog visible={editKycSetsDialog} resizable={false} blockScroll draggable={false} header={'Update KYC Sets'} style={{ width: '850px' }} onHide={() => setEditKycSetsDialog(false)}>
                <EditKycSetsDialog onHideEditKycSetsDialog={onHideEditKycSetsDialog} kycSetsRowData={kycSetsRowData} />
            </Dialog>
            <Dialog visible={viewKycSetsDialog} resizable={false} blockScroll draggable={false} header={'View KYC Set'} style={{ width: '850px' }} onHide={() => setViewKycSetsDialog(false)}>
                <ViewKycSetsDialog onHideViewKycSetDialog={onHideViewKycSetDialog} kycSetsViewRowData={kycSetsViewRowData} />
            </Dialog>

            <div className="Top__Btn">
                <div>
                    <Button label="Add New" onClick={handledClicked} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="kycSetName" className={classNames({ 'p-error': isFormFieldValid('kycSetName') }, 'Label__Text')}>
                                    KYC Sets Name
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="kycSetName"
                                    name="kycSetName"
                                    placeholder="Enter KYC Sets Name"
                                    value={formik?.values?.kycSetName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('kycSetName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('kycSetName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="clientRoleType" className={classNames({ 'p-error': isFormFieldValid('clientRoleType') }, 'Label__Text')}>
                                    Client Role Type
                                    {/* <span className="Label__Required">*</span> */}
                                </label>
                                <Dropdown
                                    id="clientRoleType"
                                    placeholder="Select Client Role Type"
                                    options={clientTypeLovData}
                                    optionLabel="name"
                                    name="clientRoleType"
                                    optionValue="lovId"
                                    value={formik.values.clientRoleType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('clientRoleType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('clientRoleType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('status') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="status"
                                    placeholder="Select Status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="status"
                                    optionValue="lovId"
                                    value={formik.values.status || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('status') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('status')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                    {/* <span className="Label__Required">*</span> */}
                                </label>
                                <InputText
                                    id="dateFrom"
                                    type="date"
                                    name="dateFrom"
                                    value={formik?.values?.dateFrom || ''}
                                    max={currentDate}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                    {/* <span className="Label__Required">*</span> */}
                                </label>
                                <InputText
                                    id="dateTo"
                                    type="date"
                                    name="dateTo"
                                    value={formik?.values?.dateTo || ''}
                                    max={currentDate}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Updated By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div>
                        <div className="Usergroup__SearchBtn p-field col-12 md:col-8">
                            <Button disabled={loading} loadingIcon={loadingIcon || ''} icon={loadingIcon} iconPos="left" label="Search" className="Btn__Dark__Search" />
                            <Button label="Reset" iconPos="right" className="Btn__Transparent__Reset" onClick={(e) => handleReset(e)} />
                        </div>
                    </div>
                </form>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            filters={filters}
                            globalFilterFields={['kycSetName', 'lkpAccountClassification.accountClassificationDescr', 'createdate', 'createdBy', 'updatedBy', 'lkpStatus.statusDescr']}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={kycSetsItems}
                        >
                            <Column field="kycSetName" header="kyc Set Name" />
                            <Column field="lkpAccountClassification.accountClassificationDescr" header="Client Role Type" />
                            <Column header="Created Date & Time" body={createdDateAndTimeBodyTemplate} />
                            <Column field="createdBy" header="Created By" />
                            <Column field="updatedBy" header="Updated By" />
                            <Column header="Status" body={statusBodyTemplate} />
                            <Column header="Actions" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
export default KycSets;

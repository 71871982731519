import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ChannelCheckerView() {
    const [loading, setloading] = useState(false);
    const [iState, setIState] = useState([]);
    const [uState, setUState] = useState([]);
    const navigate = useNavigate();

    //get all data from the url
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    //get Data by table Id
    const getDatabyId = async () => {
        setloading(true);
        const resp = await handleGetRequest(`/productcatalog/v1/channels/getchannelbyid/${refTableId}`);
        const keyData = resp?.payLoad;
        setloading(false);
        setIState(keyData);
    };

    //Get data by mc request Id
    const getMcRequestDataById = async () => {
        setloading(true);
        const res = await handleGetRequest(`/productcatalog/v1/channels/getchannelbymcrequestid/${mcRequestId}`);
        const keyData = res?.payLoad;
        setloading(false);
        setUState(keyData);
    };

    //calling getMcRequestDataById getDatabyId on page load
    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, []);
    
    return (
        <>
            <div className="card Card__Round">
                <form className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>View Detail</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="channelName" className="Label__Text">
                                    Name<span className="Label__Required">*</span>
                                </label>
                                <InputText id="channelName" name="channelName" placeholder={(iState && iState?.channelName) || (uState && uState?.channelName)} className="Input__Round" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="channelDescr" className="Label__Text">
                                    Description<span className="Label__Required">*</span>
                                </label>
                                <InputText id="channelDescr" name="channelDescr" placeholder={(iState && iState?.channelDescr) || (uState && uState?.channelDescr)} className="Input__Round" disabled />
                            </div>
                        </div>
                    </div>
                </form>

                <div className="Down__Btn">
                    <Button onClick={() => navigate('/requestchecker')} type="button" label="Okay" className="Btn__Dark" />
                </div>
            </div>

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default ChannelCheckerView;

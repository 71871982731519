import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';

const CostCenterOldNewValues = ({ costCenterRowData }) => {
    const [mcReqData, setMcReqData] = useState([]);

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const requestTypeDescr = query.get('requestTypeDescr');

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('Action should not be empty'),
        checkerComments: Yup.string().required('This Field is required').notOneOf([''], 'This Field should not be empty').max(500, 'Maximum 500 characters needed')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            lkpCostCenterName: '',
            lkpCostCenterCode: '',
            lkpCostCenterDescription: '',
            coaCode: '',
            action: '',
            checkerComments: ''
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getCostCenterById = async () => {
        if (costCenterRowData !== null && typeof costCenterRowData !== 'undefined') {
            formik.setFieldValue('lkpCostCenterName', costCenterRowData?.costCenterName);
            formik.setFieldValue('lkpCostCenterCode', costCenterRowData?.costCenterCode);
            formik.setFieldValue('lkpCostCenterDescription', costCenterRowData?.costCenterDescr);
            formik.setFieldValue('coaCode', costCenterRowData?.coaCode);
        }
    };

    useEffect(() => {
        getCostCenterById();
    }, [costCenterRowData]); // eslint-disable-line

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>View Details</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Cost Center Name</label>
                                <InputText
                                    id="lkpCostCenterName"
                                    value={formik.values.lkpCostCenterName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpCostCenterName') }, 'Input__Round')}
                                    maxLength={30}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpCostCenterName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Cost Center Code</label>
                                <InputText
                                    id="lkpCostCenterCode"
                                    value={formik.values.lkpCostCenterCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpCostCenterCode') }, 'Input__Round')}
                                    maxLength={30}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpCostCenterCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Cost Center Description</label>
                                <InputTextarea
                                    maxLength={500}
                                    rows={5}
                                    cols={30}
                                    id="lkpCostCenterDescription"
                                    value={formik.values.lkpCostCenterDescription || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpCostCenterDescription') }, 'Input__Round')}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpCostCenterDescription')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Coa Code</label>
                                <InputText id="coaCode" value={formik.values.coaCode || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('coaCode') }, 'Input__Round')} maxLength={30} disabled={true} />
                                {getFormErrorMessage('coaCode')}
                            </div>
                        </div>

                        {requestType === 'U' && requestTypeDescr === 'Enable/Disable Request' && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label className="Label__Text">Is Active</label>
                                    <Checkbox checked={mcReqData?.isActive === 'Y'} disabled className="w-full" />
                                </div>
                            </div>
                        )}
                    </div>
                </form>
            </div>
        </>
    );
};

export default CostCenterOldNewValues;

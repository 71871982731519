import React from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ChannelView() {
    const navigate = useNavigate();
    const location = useLocation();

    //getting row data using useLocation hook
    const { rowData } = location.state || {};
    const tableName = 'LKP_CHANNEL';

    return (
        <>
            <div className="card Card__Round">
                <form className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>View Detail</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="businessTypeId" className="Label__Text">
                                    Name<span className="Label__Required">*</span>
                                </label>
                                <InputText id="businessTypeId" name="businessTypeId" placeholder={rowData.channelName} className="Input__Round" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="segmentName" className="Label__Text">
                                    Description<span className="Label__Required">*</span>
                                </label>
                                <InputText id="segmentName" placeholder={rowData.channelDescr} name="segmentName" className="Input__Round" disabled />
                            </div>
                        </div>
                    </div>
                </form>

                <div className="Down__Btn">
                    <Button onClick={() => navigate('/channel')} type="button" label="Okay" className="Btn__Dark" />
                </div>
            </div>

            <AuthorizationHistoryTable refTableId={rowData.channelId} tableName={tableName} />
        </>
    );
}

export default ChannelView;

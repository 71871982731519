import { useFormik } from 'formik';
import React, { useState } from 'react';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

function AddaggregatorConfiguration() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('This field is required'),
        businessAddress: Yup.string().required('This field is required'),
        email: Yup.string().required('This field is required'),
        contactNo: Yup.string().required('This field is required')
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: '',
            businessAddress: '',
            email: '',
            contactNo: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            await dispatch(handlePostRequest(newData, '/lending/v1/lending/addaggregatorconfig', true, false, '/aggregatorconfigurations'));

            setloading(false);
            setloadingIcon('');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>ADD NEW AGGREGATOR CONFIGURATION</h2>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                    Aggregator Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="name"
                                    placeholder="Enter Aggregator name"
                                    name="name"
                                    value={formik.values.name || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('discount') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('name')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="businessAddress" className={classNames({ 'p-error': isFormFieldValid('businessAddress') }, 'Label__Text')}>
                                    Business Address<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="businessAddress"
                                    placeholder="Enter business address"
                                    name="businessAddress"
                                    value={formik.values.businessAddress || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('discount') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('businessAddress')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') }, 'Label__Text')}>
                                    Email<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    type="email"
                                    id="email"
                                    placeholder="Enter email"
                                    name="email"
                                    value={formik.values.email || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('discount') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('email')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="contactNo" className={classNames({ 'p-error': isFormFieldValid('contactNo') }, 'Label__Text')}>
                                    Contact Number<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    keyfilter="int"
                                    maxLength={11}
                                    id="contactNo"
                                    placeholder="Enter Contact Number"
                                    name="contactNo"
                                    value={formik.values.contactNo || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('discount') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('contactNo')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" loadingIcon={loadingIcon || ''} label="Submit" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/aggregatorconfigurations')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddaggregatorConfiguration;

import { Chart } from 'primereact/chart';
import React, { useEffect, useState } from 'react'

const BlockedSuspendedBlacklistedDormancyRevival = ({ bsbDormancyRevival }) => {

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {

        const dateArr = bsbDormancyRevival && bsbDormancyRevival.map((bsbdr) => { return bsbdr?.date.split(" ")[0] })
        const countArr = bsbDormancyRevival && bsbDormancyRevival.map((bsbdr) => { return bsbdr?.countArr })
        const channelArr = bsbDormancyRevival && bsbDormancyRevival.map((bsbdr) => { return bsbdr?.channelName })

        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: dateArr,
            datasets: [
                {
                    type: 'bar',
                    label: 'Channel',
                    backgroundColor: "rgb(200,246,247)",
                    data: countArr
                },
                {
                    type: 'bar',
                    label: 'Count',
                    backgroundColor: "rgb(216,239,170)",
                    data: channelArr
                },

            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [bsbDormancyRevival]);

    return (
        <div className="col-12 md:col-4">
            <div className="card Card__Round">
                <div className="cardBarCharts">
                    <h5>Blocked/Suspended/Blacklisted/Dormancy Revival</h5>
                    <div className="pt-3">
                        <Chart type="bar" data={chartData} options={chartOptions} className="w-full" height='35rem' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlockedSuspendedBlacklistedDormancyRevival
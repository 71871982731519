import React, { useEffect, useState } from 'react'
import { Chart } from 'primereact/chart';

const ExaustedBreachedLimitCustomer = ({ exaustedBreachedLC }) => {

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {

        const eblcArr = exaustedBreachedLC && exaustedBreachedLC.flatMap((eblc) => [].concat(eblc?.count, eblc?.amount));

        const data = {
            labels: ['Count', 'Amount'],
            datasets: [
                {
                    data: eblcArr,
                    backgroundColor: [
                        "rgb(240,192,31)",
                        "rgb(79,165,231)"
                    ],
                    hoverBackgroundColor: [
                        "rgb(240,192,31)",
                        "rgb(79,165,231)"
                    ]
                }
            ]
        };
        const options = {
            cutout: '60%'
        };

        setChartData(data);
        setChartOptions(options);
    }, [exaustedBreachedLC]);

    return (
        <div className="col-12 md:col-3">
            <div className="card Card__Round">
                <div className="cardBarCharts">
                    <h5>Exausted/Breached Limit Customer</h5>
                    <div className="pt-3">
                        <Chart type="doughnut" data={chartData} options={chartOptions} className="w-full" height='35rem' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExaustedBreachedLimitCustomer
import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handlePostRequest } from '../../services/PostTemplate';
import { handleGetRequest } from '../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';



function useCaseManagement() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [useCaseData, setUseCaseData] = useState([]);
    const [userLov, setUserLov] = useState([]);
  
  
   



    const validationSchema = Yup.object().shape({

        dateFrom: Yup.date().nullable(),

        dateTo: Yup.date().when('dateFrom', {

            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')

        })

    });

    const dt = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formik = useFormik({
        validationSchema,
        initialValues: {
            useCaseName: '',
            dateFrom: '',
            dateTo: '',
            updatedBy: '',
            createdBy: '',
            statusId: ''
        },
        validate: (data) => {

            let errors = {};

            if (data.dateTo && !data.dateFrom) {

                errors.dateFrom = 'Date From is required when Date To is provided';

            }

 

            if (data.dateFrom && data.dateTo && data.dateFrom > data.dateTo) {

                errors.dateTo = 'Date To must be greater than or equal to Date From';

            }

            return errors;

        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ]
                    // checkSum: hash
                }
            };

            dt.current.reset();

          

            const res = await dispatch(handlePostRequest(newData, '/mfs_user_management/v1/management/getallusecases', true, true));
            if (res?.responseCode === '030000') {
                // formik.resetForm();
                setUseCaseData(res?.payLoad);
            } else {
                setUseCaseData([]);
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };

    useEffect(() => {
   
        getUserLov();
    }, []);// eslint-disable-line




    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">Use Case Management</h5>
                {/* <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span> */}
            </div>
        );
    };

    const header = renderHeader();
    const handleSwitchChange = async (id, isActiveData, rowData) => {
        // rowData.isActive == 'Y' ? 'N' : 'Y'
        const updatedData = useCaseData.map((item) => {
            if (item.mcConfigId === id) {
                const isActive = item.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setUseCaseData(updatedData);
        let isactive = '';
        if (isActiveData === 'Y') {
            isactive = 'N';
        } else if (isActiveData === 'N') {
            isactive = 'Y';
        }
        const previousValue = isActiveData;
        const data = {
            isActive: isactive,
            mcConfigId: id,
          
        };
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

        const res = await dispatch(handlePostRequest(newData, '/mfs_user_management/v1/management/inactiveusecase', true, true));
        if (res?.responseCode !== '030000') {
            const revertedData = useCaseData.map((item) => {
                if (item.mcConfigId === id) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setUseCaseData(revertedData);
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                <InputSwitch checked={rowData.isActive === 'Y'}  onChange={() => handleSwitchChange(rowData.mcConfigId, rowData.isActive, rowData)} />
                    <Button
                        tooltip="Edit"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: 'top' }}
                        onClick={() => editNewUseCaseManagement(rowData)}
                        disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) || rowData?.isActive === 'N' ? true : false}
                        // onClick={() => EditUserGroupHandled(rowData)}
                        className="p-button-rounded p-button-primary"
                    />
                    <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => viewNewUseCaseManagement(rowData)} className="p-button-rounded p-button-warning" />
                </div>
            </>
        );
    };

    const addNewUseCaseManagement = () => {
        navigate('/addusecase');
    };
    const editNewUseCaseManagement = (rowData) => {
        navigate(`/editusecase?mcConfigId=${rowData?.mcConfigId}`);
    };
    const viewNewUseCaseManagement = (rowData) => {
        navigate(`/viewusecase?mcConfigId=${rowData?.mcConfigId}`);
    };

  
 
   
    const resetHandle = (e) => {
        e.preventDefault();
        formik.resetForm();
    };
    
    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return (
            <p>
                {formattedDateTime}
            </p>
        );
    }


    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];
    return (
        <>
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={() => addNewUseCaseManagement()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
                {/* <div>
                    <Button icon="pi pi-file-pdf" label="PDF" tooltipOptions={{ position: 'top' }} tooltip="PDF" className="p-button-warning mr-2" data-pr-tooltip="PDF" onClick={exportPDF} />
                    <Button icon="pi pi-file-excel" label="Excel" tooltipOptions={{ position: 'top' }} tooltip="CSV" className="p-button-success p-button-info mr-2" onClick={exportCSV} />
                </div> */}
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="useCaseName" className={classNames({ 'p-error': isFormFieldValid('useCaseName') }, 'Label__Text')}>
                                    Use Case Name
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="useCaseName"
                                    placeholder="Enter use Case Name"
                                    name="useCaseName"
                                    value={formik?.values?.useCaseName?.replace(/\s\s+/g, '')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('useCaseName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('useCaseName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText
                                    id="dateFrom"
                                    type="date"
                                    max={previousDate}
                                    name="dateFrom"
                                    value={formik?.values?.dateFrom}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText id="dateTo" type="date" max={previousDate} name="dateTo" value={formik?.values?.dateTo} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')} />

                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                     
                        <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} label="Search" className="Btn__Dark" />
                        <Button disabled={loading} label="Reset" onClick={(e) => resetHandle(e)} className="Btn__Transparent" />
                    </div>
                    </div>
                </form>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dt}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} "
                            header={header}
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={useCaseData}
                        >
                            <Column field="configName" header="Use Case Name" />
                            <Column field="configDescription" header="Description" />
                            <Column header="Created Date & Time" body={createdDateAndTimeBodyTemplate}/>
                            <Column field="createdBy" header="Created By" />
                            <Column header="Action" body={actionBodyTemplate} />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
export default useCaseManagement;

import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import ActiveInActiveDialog from '../../../components/ActiveInActiveDialog/ActiveInActiveDialog';
import { Paginator } from 'primereact/paginator';
import { PaginationCall, PaginationTemplate } from '../../../components/Pagination';

function CRPModel() {
    // const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [crpModel, setCrpModel] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [customFirst1, setCustomFirst1] = useState(0);
    const [customRows1, setCustomRows1] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [render, setRender] = useState(false);

    const [crpModelIdData, setCrpModelIdData] = useState();
    const [isActiveData, setIsActiveData] = useState();
    const dataTableRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(resp?.payLoad);
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };

    useEffect(() => {
        getStatusLov();
        getUserLov();
    }, []);

    const validationSchema = Yup.object().shape({
        fromDate: Yup.date().nullable(),
        toDate: Yup.date().when('fromDate', {
            is: (fromDate) => !!fromDate,
            then: Yup.date().required('Date To is required').min(Yup.ref('fromDate'), 'Date To has to be more than Date From')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            modelName: '',
            fromDate: '',
            toDate: '',
            createdBy: '',
            updatedBy: '',
            statusId: '2',
            rowStart: customFirst1,
            rowEnd: customRows1 + customFirst1
        },
        validate: (data) => {
            let errors = {};
            if (data.toDate && !data.fromDate) {
                errors.fromDate = 'Date From is required when Date To is provided';
            }

            if (data.fromDate && data.toDate && data.fromDate > data.toDate) {
                errors.toDate = 'Date To must be greater than or equal to Date From';
            }
            return errors;
        },
        onSubmit: async (data) => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            dataTableRef.current.reset();

            const res = await dispatch(handlePostRequest(newData, '/riskprofile/v1/crpmodel/getallcrp', true, true));
            if (res?.responseCode === '170000') {
                setCrpModel(res?.payLoad);
            } else {
                setCrpModel([]);
            }

            // setloading(false);
            setloadingIcon('pi pi-search');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const EditRiskProfileHandled = (rowData) => {
        navigate(`/editcrpmodel?crpModelId=${rowData?.crpModelId}`);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <InputSwitch
                        checked={rowData.isActive === 'Y'}
                        // disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                        disabled={['1', '3'].includes((rowData?.statusId).toString()) ? true : false}
                        onChange={() => {
                            setShowModal(true);
                            setCrpModelIdData(rowData?.crpModelId);
                            setIsActiveData(rowData?.isActive);
                        }}
                    />
                    <Button
                        tooltip="Edit"
                        icon="pi pi-pencil"
                        // disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) || (rowData?.lkpStatus?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                        disabled={rowData?.statusId === 3 || rowData?.statusId === 1 || (rowData?.statusId === 2 && rowData?.isActive === 'N') ? true : false}
                        tooltipOptions={{ position: 'top' }}
                        onClick={() => EditRiskProfileHandled(rowData)}
                        className="p-button-rounded p-button-primary"
                    />
                    <Button
                        tooltip="View Details"
                        tooltipOptions={{ position: 'top' }}
                        icon="pi pi-eye"
                        onClick={() => navigate(`/viewcrpmodel?crpModelId=${rowData?.crpModelId}`)}
                        className="p-button-rounded"
                        style={{ backgroundColor: '#F2AC57', border: 'none' }}
                    />
                </div>
            </>
        );
    };
    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const CreateDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const statusTemplate = (rowData) => {
        const filteredStatus = statusLovData.filter((item) => item.lovId == rowData?.statusId);
        const statusColor = filteredStatus[0]?.name === 'Approved' ? '#14A38B' : filteredStatus[0]?.name === 'Pending' ? '#F2AC57' : '#FF7171';

        return (
            <p style={{ color: statusColor }}>
                <b>{filteredStatus[0]?.name}</b>
            </p>
        );
    };

    const handleSwitchChange = async () => {
        let commissionTypeName = '';
        const updatedData = crpModel.map((item) => {
            if (item.crpModelId === crpModelIdData) {
                commissionTypeName = item?.lkpFeeType?.feeTypeName;
                const isActive = item.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setCrpModel(updatedData);
        let isactive = '';
        if (isActiveData === 'Y') {
            isactive = 'N';
        } else if (isActiveData === 'N') {
            isactive = 'Y';
        }

        const previousValue = isActiveData;

        const data = {
            isActive: isactive,
            crpModelId: crpModelIdData
        };

        if (commissionTypeName === 'Commission Hierarchy') {
            data.commissionHierarchyId = crpModelIdData;
            delete data.crpModelId;
        }

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        let res = '';
        if (commissionTypeName === 'Commission Hierarchy') {
            // res = await dispatch(handlePostRequest(newData, '/pricingprofile/v1/commissionprofile/inactivecommissionhierarchy', true));
        } else {
            res = await dispatch(handlePostRequest(newData, '/riskprofile/v1/crpmodel/inactivecrpmodel', true, false, '/crpmodel'));
        }
        if (res?.responseCode !== '010000') {
            const revertedData = crpModel.map((item) => {
                if (item.crpModelId === crpModelIdData) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setCrpModel(revertedData);
        }
    };
    const handleShowModal = () => {
        setShowModal(false);
    };

    const props = {
        showModal,
        handleShowModal,
        handleSwitchChange
    };

    const onCustomPageChange1 = (event) => {
        setCustomFirst1(event.first);
        setCustomRows1(event.rows);
        setCurrentPage(event.page + 1);
        setRender(true);
    };

    const RequestData = {
        modelName: formik.values.modelName,
        fromDate: formik.values.fromDate,
        toDate: formik.values.toDate,
        statusId: formik.values.statusId,
        createdBy: formik.values.createdBy,
        updatedBy: formik.values.updatedBy,
        rowStart: customFirst1,
        rowEnd: customRows1 + customFirst1
    };

    return (
        <>
            <ActiveInActiveDialog {...props} />
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={() => navigate('/addcrpmodel')} className="Btn__Add__" icon="pi pi-plus" iconPos="left" style={{ width: 'auto' }} />
                </div>
            </div>
            <PaginationCall
                render={render}
                customFirst1={customFirst1}
                customRows1={customRows1}
                currentPage={currentPage}
                dataTableRef={dataTableRef}
                setData={setCrpModel}
                RequestData={RequestData}
                url={'/riskprofile/v1/crpmodel/getallcrp'}
                responseCode={'170000'}
            />
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="modelName" className={classNames({ 'p-error': isFormFieldValid('modelName') }, 'Label__Text')}>
                                    CRP Model Name
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="modelName"
                                    placeholder="Enter CRP Model Name"
                                    name="modelName"
                                    value={formik?.values?.modelName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('modelName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('modelName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="fromDate" className={classNames({ 'p-error': isFormFieldValid('fromDate') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText
                                    max={previousDate}
                                    id="fromDate"
                                    placeholder=""
                                    name="fromDate"
                                    type="date"
                                    value={formik?.values?.fromDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('fromDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('fromDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="toDate" className={classNames({ 'p-error': isFormFieldValid('toDate') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText
                                    max={previousDate}
                                    id="toDate"
                                    placeholder=""
                                    name="toDate"
                                    type="date"
                                    value={formik?.values?.toDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('toDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('toDate')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="statusId" className={classNames({ 'p-error': isFormFieldValid('statusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select Status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Updated By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label="Search" icon={loadingIcon || ''} iconPos="right" className="Btn__Dark" />
                        <Button label="Reset" iconPos="right" className="Btn__Dark" onClick={(e) => handleReset(e)} />
                    </div>
                </form>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            className="datatable-responsive"
                            globalFilterFields={['channelName', 'createdBy', 'updatedBy', 'createdate', 'lastupdatedate', 'lkpStatus.statusName']}
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={crpModel}
                        >
                            <Column field="crpModelName" header="CRP Model Name" />
                            <Column field="createdBy" header="Created By" />
                            <Column header="Created Date & Time" body={CreateDateAndTimeBodyTemplate} />
                            <Column header="Status" body={statusTemplate} />
                            <Column header="Actions" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                        <Paginator template={PaginationTemplate(false)} first={customFirst1} rows={customRows1} totalRecords={crpModel?.[0]?.totalCount} onPageChange={onCustomPageChange1}></Paginator>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CRPModel;

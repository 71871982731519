import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { InputTextarea } from 'primereact/inputtextarea';
import RoleTable from '../../Components/roleTable';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function EditSalesRolesChecker() {
    const [saleRoleData, setSaleRoleData] = useState([]);
    const [roleName, setRoleName] = useState('');
    const [saleStructureData, setSaleStructureData] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [roles, setRoles] = useState([]);
    const [newPayLoad, setNewPayLoad] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('This Field is required.'),
        checkerComments: Yup.string().required('This Field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            salesRoleId: '',
            businessTypeId: '',
            regionId: '',
            salesStructureId: '',
            action: '',
            checkerComments: ''
        },

        onSubmit: async (data) => {
            data['isActive'] = data?.isActive ? 'Y' : 'N';
            if (requestTypeDescr !== 'Enable/Disable Request') {
                delete data['isActive'];
            }
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        saleRoleRequest: {
                            isActive: data['isActive'],
                            salesRoleId: formik.values.salesRoleId,
                            businessTypeId: formik.values.businessTypeId,
                            regionId: formik.values.regionId,
                            salesStructureId: formik.values.salesStructureId,
                            tblSalesRoleDetails: newPayLoad
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: ''
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/account/v1/sales/salesRoleCheckerAction', true, false, 'requestchecker'));
            if (res?.responseCode === '030000') {
                formik.resetForm();
            }
        }
    });

    const getDatabyId = async () => {
        // getRoleWiseModule
        const resp = await handleGetRequest(`/account/v1/sales/getSalesRoleById/${refTableId}`);
        const keyData = resp?.payLoad;

        formik.setFieldValue('salesRoleId', keyData?.salesRoleId?.toString());
        formik.setFieldValue('businessTypeId', keyData?.businessTypeId?.toString());
        formik.setFieldValue('regionId', keyData?.regionId?.toString());
        formik.setFieldValue('salesStructureId', keyData?.salesStructureId?.toString());
        let newRole = [];
        setRoles(newRole);
        if (keyData?.tblSalesRoleDetails.length > 0) {
            keyData?.tblSalesRoleDetails?.map((item) => {
                const role = {
                    roleName: item.roleName,
                    createdate: item.createdate,
                    salesRoleDetailId: item.salesRoleDetailId
                };
                newRole.push(role);
            });
            setRoles([...roles, ...newRole]);
        }
    };

    const getMcRequestDataById = async () => {
        const resp = await handleGetRequest(`/account/v1/sales/getSalesRoleUpdateCheckerById/${mcRequestId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
        }
        formik.setFieldValue('salesRoleId', keyData?.salesRoleId?.toString());
        formik.setFieldValue('businessTypeId', keyData?.businessTypeId?.toString());
        formik.setFieldValue('regionId', keyData?.regionId?.toString());
        formik.setFieldValue('salesStructureId', keyData?.salesStructureId?.toString());
        formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
        let newRole = [];
        setRoles([]);
        if (keyData?.tblSalesRoleDetails.length > 0) {
            if (requestTypeDescr === 'Enable/Disable Request') {
                keyData?.tblSalesRoleDetails?.map((item) => {
                    if (item.isActive === 'Y') {
                        const role = {
                            roleName: item.roleName,
                            createdate: item.createdate,
                            salesRoleDetailId: item.salesRoleDetailId
                        };
                        newRole.push(role);
                    }
                });
                setRoles([...roles, ...newRole]);
            } else {
                keyData?.tblSalesRoleDetails?.map((item) => {
                    const role = {
                        roleName: item.roleName,
                        createdate: item.createdate,
                        salesRoleDetailId: item.salesRoleDetailId
                    };
                    newRole.push(role);
                });
                setRoles([...roles, ...newRole]);
            }
        }
    };

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };

    const getSaleRoleData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getsalerolesdata');
        // if (resp?.responseCode === '010000') {
        setSaleRoleData(resp?.payLoad);
        // }
    };

    const getSaleStructureData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getSaleStructureList/LKP_SALES_STRUCTURE:*');
        // if (resp?.responseCode === '010000') {
        setSaleStructureData(resp?.payLoad);
        // }
    };

    useEffect(() => {
        getStatusLov();
        getSaleRoleData();
        getSaleStructureData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (roles?.length > 0) {
            const updatedPayload = roles.map((item) => {
                const updatedItem = { ...item };
                delete updatedItem.creationDateTime;
                return updatedItem;
            });
            setNewPayLoad(updatedPayload);
        }
    }, [roles]); // eslint-disable-line react-hooks/exhaustive-deps

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>EDIT DETAIL</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="businessTypeId" className={classNames({ 'p-error': isFormFieldValid('businessType') }, 'Label__Text')}>
                                    Business Type
                                </label>
                                <Dropdown
                                    id="businessTypeId"
                                    placeholder="Select Business Type"
                                    options={saleRoleData?.bussinessList}
                                    optionLabel="name"
                                    name="businessTypeId"
                                    optionValue="lovId"
                                    value={formik.values.businessTypeId || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('businessTypeId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('businessTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="regionId" className={classNames({ 'p-error': isFormFieldValid('selectRegion') }, 'Label__Text')}>
                                    Region
                                </label>
                                <Dropdown
                                    id="regionId"
                                    placeholder="Select Region"
                                    options={saleRoleData?.regionList}
                                    optionLabel="name"
                                    name="regionId"
                                    optionValue="lovId"
                                    value={formik.values.regionId || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('businessTypeId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('regionId')}
                            </div>
                        </div>

                        {/* {formik.values.businessTypeId == '5' && (
                            <> */}
                        {formik.values.salesStructureId && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="salesStructureId" className={classNames({ 'p-error': isFormFieldValid('salesStructureId') }, 'Label__Text')}>
                                        Sales Structure
                                    </label>
                                    <Dropdown
                                        id="salesStructureId"
                                        placeholder="Select sale Structure"
                                        options={saleStructureData}
                                        optionLabel="name"
                                        name="salesStructureId"
                                        optionValue="lovId"
                                        value={formik.values.salesStructureId || ''}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('salesStructureId') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('salesStructureId')}
                                </div>
                            </div>
                        )}
                        {/* </>
                        )} */}
                    </div>
                    {roles.length > 0 && <RoleTable roles={roles} readOnlyMode />}
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    maxLength={500}
                                    rows={5}
                                    cols={30}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label="Update" className="Btn__Dark" />
                        <Button type="button" onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default EditSalesRolesChecker;

import React from 'react';

function PresentState({ oldNewData }) {
    return (
        <>
            <div className="p-field col-12 md:col-12">
                <table className="Checker__Data__Table">
                    <thead>
                        <tr>
                            <th colSpan="1" className="Checker__PresentTable__Head">
                                <b>Field Name</b>
                            </th>
                            <th colSpan="1" className="Checker__PresentTable__Head">
                                <b>Old Values</b>
                            </th>
                            <th colSpan="1" className="Checker__PresentTable__Head">
                                <b>New Values</b>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {oldNewData?.map((value, index) => {
                            return (
                                <tr key={index}>
                                    <td className="Checker__Table__Data">
                                        <b>{value?.key}</b>
                                    </td>
                                    <td className="Checker__Table__Data">
                                        <p>{value?.oldValue}</p>
                                    </td>
                                    <td className="Checker__Table__Data">
                                        <p>{value?.newValue}</p>
                                    </td>
                                </tr>
                            );
                        })}

                        {/* <tr>
                            <td className="Checker__Table__Data">
                                <b>Tax Name</b>
                            </td>
                            <td className="Checker__Table__Data">
                                <b>{uTypeData?.taxTypeName}</b>
                            </td>
                        </tr>
                        <tr>
                            <td className="Checker__Table__Data">
                                <b>Applicability</b>
                            </td>
                            <td className="Checker__Table__Data">
                                <b>{uTypeData?.applicableOn === 'I' ? 'Apply on Fee Inclusive' : uTypeData?.applicableOn === 'E' ? 'Apply on Fee Exclusive' : uTypeData?.applicableOn === 'C' ? 'Apply on Commision' : 'Apply on Transaction Amount'}</b>
                            </td>
                        </tr>
                        <tr>
                            <td className="Checker__Table__Data">
                                <b>Description</b>
                            </td>
                            <td className="Checker__Table__Data">
                                <b>{uTypeData?.taxTypeDescr}</b>
                            </td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default PresentState;

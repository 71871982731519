import React from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

const EditEmail = ({ onAccountEmailChange, formikValuees, onHide }) => {
    const validationSchema = Yup.object().shape({
        email: Yup.string().email().required('This Field is required.'),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            email: formikValuees?.email ? formikValuees?.email : "",
        },

        onSubmit: async () => {
            onAccountEmailChange({ email: formik.values.email });
            onHide();
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className='Card__Round'>

                    <div className="p-fluid p-formgrid grid">
                        <div className="p-field col-12">
                            <label className='Label__Text'>
                                Enter new email
                            </label>
                            <InputText
                                id="email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('email') }, 'Input__Round')}
                                keyfilter={/^[a-zA-Z0-9_.@]*$/}
                            />
                            {getFormErrorMessage("email")}
                        </div>
                    </div>

                    <div className='Down__Btn mt-4'>
                        <Button label="Confirm" iconPos="right" className="Btn__Dark" type="submit" />
                        <Button label="Cancel" iconPos="right" className="Btn__cancel" type="button" onClick={onHide} />
                    </div>

                </div>
            </form>
        </>
    )
}

export default EditEmail
import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { Button } from 'primereact/button';

const ViewAppVersionFromMcView = ({ rowData, onHide }) => {
    const [appVersion, setAppVersion] = useState([]);

    const getAppVersionById = async () => {
        const response = await handleGetRequest(`/account/v1/customeraccounts/getDataByAppVersionName/${rowData?.customerId || rowData?.[0]?.customerId}`);
        if (response?.responseCode === '010000') {
            setAppVersion(response?.payLoad);
        }
    };

    useEffect(() => {
        getAppVersionById();
    }, []);

    return (
        <>
            <div className="Card__Round">
                <div className="mb-2">
                    <label className="Label__Text">App Version</label>
                    <InputText value={appVersion?.appVersionName ?? ''} className="Input__Round" disabled />
                </div>

                <div className="mb-2">
                    <label className="Label__Text">Handset Model</label>
                    <InputText value={appVersion?.deviceModel ?? ''} className="Input__Round" disabled />
                </div>

                <div className="mb-2">
                    <label className="Label__Text">Operating System</label>
                    <InputText value={appVersion?.deviceType ?? ''} className="Input__Round" disabled />
                </div>

                <div className="mb-2">
                    <label className="Label__Text">Last Screen Visual</label>
                    <InputText value="" className="Input__Round" disabled />
                </div>

                <div className="mb-2">
                    <label className="Label__Text">Last Error Visibility</label>
                    <InputText value={appVersion?.errors ?? ''} className="Input__Round" disabled />
                </div>

                <div className="mb-2">
                    <label className="Label__Text">Date of download</label>
                    <InputText value="" className="Input__Round" disabled />
                </div>

                <div className="mb-2">
                    <label className="Label__Text">Current Version</label>
                    <InputText value={appVersion?.osVersion ?? ''} className="Input__Round" disabled />
                </div>

                <div className="mb-2">
                    <label className="Label__Text">Rooted Device</label>
                    <InputText value="" className="Input__Round" disabled />
                </div>

                <div className="mb-2">
                    <label className="Label__Text">Whitelisted Device</label>
                    <div className="flex align-items-center">
                        <InputText value="-" className="Input__Round mr-2" disabled />
                    </div>
                </div>

                <div className="mb-2">
                    <label className="Label__Text">App Simulator</label>
                    <InputText value="" className="Input__Round" disabled />
                </div>

                <div className="mb-2">
                    <label className="Label__Text">Terms & Condition</label>
                    <InputText value="" className="Input__Round" disabled />
                </div>

                <div className="Down__Btn mt-4">
                    <Button label="Cancel" iconPos="right" className="Btn__cancel" type="button" onClick={onHide} />
                </div>
            </div>
        </>
    );
};

export default ViewAppVersionFromMcView;

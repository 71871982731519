import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useRef } from 'react';
import ColorPicker from 'react-pick-color';
import { InputTextarea } from 'primereact/inputtextarea';
import { useLocation } from 'react-router-dom';
import MobileView from './mobileView';
import { baseURL } from '../../../Config';
import SecuredFileChange from '../../../components/SecuredFileChange';

function EditCompany() {
    const [showColorPicker, setShowColorPicker] = useState({ textColor: false, bgColor: false });
    const [glId, setGlId] = useState([]);
    const [parserHead, setParserHead] = useState([]);
    const [uBPType, setUbpType] = useState([]);
    const location = useLocation();
    const { rowData } = location.state || {};

    const [maincategoryLov, setMainCategoryLov] = useState([]);
    const [subCategoryLov, setSubCategoryLov] = useState([]);
    const [secondSubCategoryLov, setSecondSubCategoryLov] = useState([]);

    const [bankName, setBankName] = useState([]);
    const [companyType, setCompanyType] = useState([]);
    const [iconLink, setIconLink] = useState('');
    const [helpImageIcon, setHelpImageIcon] = useState();
    const [defaultHelpImageIcon, setDefaultHelpImageIcon] = useState();
    const [defaultCompanyIcon, setDefaultCompanyIcon] = useState();
    const [selectedFile, setSelectedFile] = useState(null);
    const [categoryIconError, setCategoryIconError] = useState('');

    const [selectedHelpImage, setSelectedHelpImage] = useState(null);

    const [companyIcon, setCompanyIcon] = useState();
    const [selectedCompanyIcon, setSelectedCompanyIcon] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const companyIconRef = useRef(null);
    const helpimageRef = useRef(null);

    const validationSchema = Yup.object().shape({
        cmsMainCategoryId: Yup.string().required('This field is required'),
        nameEnglish: Yup.string().required('This field is required'),
        icon: Yup.string().required('This field is required'),
        cmsSecondSubCategoryId: Yup.string().required('2nd Sub Category is required'),
        cmsBankId: Yup.string().when('cmsSecondSubCategoryId', {
            is: (value) => secondSubCategoryLov.some(
                (option) => option.name === 'Donations' && option.lovId === value
            ),
            then: Yup.string().required('This field is required'),
        }),
        bankAccountNo: Yup.string().when('cmsSecondSubCategoryId', {
            is: (value) => secondSubCategoryLov.some(
                (option) => option.name === 'Donations' && option.lovId === value
            ),
            then: Yup.string().required('This field is required'),
        }),
        cmsUbpTypeId: Yup.string().when('cmsSecondSubCategoryId', {
            is: (value) => secondSubCategoryLov.some(
                (option) => 
                    (option.name === 'Education' || option.name === 'Utility Bill Payment') 
                    && option.lovId === value
            ),
            then: Yup.string().required('This field is required'),
        }),
        nameUrdu: Yup.string().required('This field is required'),
        priority: Yup.string().required('This field is required'),
        productId: Yup.string().required('This field is required'),
        prefix: Yup.string().required('This field is required'),
        cmsCompanyTypeId: Yup.string().required('This field is required'),
        cmsParserHeadId: Yup.string().when('cmsCompanyTypeId', {
            is: (value) => companyType.some(
                (option) => option.name === 'Offline' && option.lovId === value
            ),
            then: Yup.string().required('This field is required'),
        }),
    
        gracePeriod: Yup.string().required('This field is required'),
        backdatedTransactionPeriod: Yup.string().required('This field is required'),
        billBlockPeriod: Yup.string().required('This field is required'),
        voidTransactionPeriod: Yup.string().required('This field is required'),
        cmsTagLabel: Yup.string().when('addTag', {
            is: true,
            then: Yup.string().required('This field is required')
        }),
        cmsTagTextColor: Yup.string().when('addTag', {
            is: true,
            then: Yup.string().required('This field is required')
        }),
        cmsTagBgColor: Yup.string().when('addTag', {
            is: true,
            then: Yup.string().required('This field is required')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cmsCompanyId: '',
            cmsMainCategoryId: '',
            cmsSubCategoryId: '',
            cmsSecondSubCategoryId: '',
            nameEnglish: '',
            nameUrdu: '',
            priority: '',
            productId: '',
            prefix: '',
            copyEngToUrdu:false,
            cmsCompanyTypeId: '',
            partialPaymentAllowed: '',
            description: '',
            cmsUbpTypeId: '',
            addTag: true,
            cmsTagLabel: '',
            cmsTagTextColor: '',
            cmsTagBgColor: '',
            expiryDate: '',
            inputField: '',
            helpText: '',
            ticker: false,
            tickerText: '',
            bankAccountNo: '',
            cmsBankId: '',

            // glAccountId: '',
            // glAccount: '',
            // glAccountName: '',
            cmsParserHeadId: '',
            billBlockPeriod: '',
            voidTransactionPeriod: '',
            backdatedTransactionPeriod: '',
            gracePeriod: '',
            icon: '',
            helpImage: '',
            isActive: ''
        },

        onSubmit: async (data) => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        cmsCompanyId: data?.cmsCompanyId,
                        cmsMainCategoryId: data?.cmsMainCategoryId,
                        cmsSubCategoryId: data?.cmsSubCategoryId,
                        cmsSecondSubCategoryId: data?.cmsSecondSubCategoryId,
                        nameEnglish: data?.nameEnglish,
                        nameUrdu: data?.nameUrdu,
                        priority: data?.priority,
                        productId: data?.productId,
                        prefix: data?.prefix,
                        cmsCompanyTypeId: data?.cmsCompanyTypeId,
                        partialPaymentAllowed: data?.partialPaymentAllowed,
                        description: data?.description,
                        cmsTagLabel: data?.addTag ? data?.cmsTagLabel : '',
                        cmsTagTextColor: data?.addTag ? data?.cmsTagTextColor : '',
                        cmsTagBgColor: data?.addTag ? data?.cmsTagBgColor : '',
                        expiryDate: data?.expiryDate,
                        inputField: data?.inputField,
                        helpText: data?.helpText,
                        ticker: data?.ticker ? 'Y' : 'N',
                        tickerText: data?.tickerText,
                        icon: defaultCompanyIcon,
                        helpImage: defaultHelpImageIcon,
                        bankAccountNo: data?.bankAccountNo,
                        cmsBankId: data?.cmsBankId,
                        // glAccountId: data?.glAccountId,
                        // glAccountName: data?.glAccountName,
                        cmsParserHeadId: data?.cmsParserHeadId,
                        billBlockPeriod: data?.billBlockPeriod,
                        voidTransactionPeriod: data?.voidTransactionPeriod,
                        backdatedTransactionPeriod: data?.backdatedTransactionPeriod,
                        gracePeriod: data?.gracePeriod,
                        // isActive: data?.isActive,
                        isActive: null
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            let formData = new FormData();

            if (selectedFile) {
                delete newData?.data?.payLoad?.icon;
                formData.append('comp_icon', selectedFile);
            }
            if (selectedHelpImage) {
                delete newData?.data?.payLoad?.helpImage;
                formData.append('help_img', selectedHelpImage);
            }
            formData.append('request', JSON.stringify(newData));

            await dispatch(handlePostRequest(formData, '/cms/v1/cms/updatecompany', true, false, 'company'));
        }
    });

    const getMainCategoryDataLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcategorydatalov');
        setMainCategoryLov(res?.payLoad);
    };

    const getSubCategoryDataLov = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getsubcategorydatalov/${formik?.values?.cmsMainCategoryId}`);
        setSubCategoryLov(res?.payLoad);
    };

    const getSecSubCategoryDataLov = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getsecondsubcategorydatalov/${formik.values.cmsMainCategoryId}/${formik.values.cmsSubCategoryId}`);
        setSecondSubCategoryLov(res?.payLoad);
    };

    const getGlId = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getGlAccounts/TBL_ACCOUNT:*');
        setGlId(res?.payLoad);
    };
    const getParserHead = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_PARSER_HEAD');
        setParserHead(res?.payLoad);
    };
    const getUBPType = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/LKP_CMS_UBP_TYPE');
        setUbpType(res?.payLoad);
    };

    const getBankName = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getTblCMSBank/TBL_CMS_BANK:*');
        setBankName(res?.payLoad);
    };
    const getCompanyType = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_COMPANY_TYPE');
        setCompanyType(res?.payLoad);
    };
    const getCompany = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/getcompanybyid/${rowData?.cmsCompanyId}`);
        const keyData = res?.payLoad;
        formik.setFieldValue('cmsCompanyId', keyData?.cmsCompanyId || '');
        formik.setFieldValue('cmsMainCategoryId', keyData?.cmsMainCategoryId?.toString() || '');
        formik.setFieldValue('cmsSubCategoryId', keyData?.cmsSubCategoryId?.toString() || '');
        formik.setFieldValue('cmsSecondSubCategoryId', keyData?.cmsSecondSubCategoryId?.toString() || '');
        formik.setFieldValue('cmsBankId', keyData?.cmsBankId?.toString() || '');
        formik.setFieldValue('bankAccountNo', keyData?.bankAccountNo || '');
        formik.setFieldValue('nameEnglish', keyData?.nameEnglish || '');
        formik.setFieldValue('nameUrdu', keyData?.nameUrdu || '');
        formik.setFieldValue('priority', keyData?.priority || '');
        formik.setFieldValue('productId', keyData?.productId || '');
        formik.setFieldValue('prefix', keyData?.prefix || '');
        formik.setFieldValue('cmsCompanyTypeId', keyData?.cmsCompanyTypeId?.toString() || '');
        formik.setFieldValue('partialPaymentAllowed', keyData?.partialPaymentAllowed || '');
        formik.setFieldValue('addTag', keyData?.cmsTagLabel || keyData?.cmsTagTextColor || keyData?.cmsTagBgColor ? true : false);
        formik.setFieldValue('cmsTagLabel', keyData?.cmsTagLabel?.toString() || '');
        formik.setFieldValue('cmsTagTextColor', keyData?.cmsTagTextColor?.toString() || '');
        formik.setFieldValue('cmsParserHeadId', keyData?.cmsParserHeadId?.toString() || '');

        // formik.setFieldValue('glAccountId', keyData?.glAccountId?.toString() || '');
        formik.setFieldValue('cmsTagBgColor', keyData?.cmsTagBgColor?.toString() || '');

        // formik.setFieldValue('glAccountName', keyData?.glAccountName || '');
        formik.setFieldValue('cmsUbpTypeId', keyData?.cmsUbpTypeId?.toString() || '');

        formik.setFieldValue('billBlockPeriod', keyData?.billBlockPeriod || '');
        formik.setFieldValue('gracePeriod', keyData?.gracePeriod || '');
        formik.setFieldValue('backdatedTransactionPeriod', keyData?.backdatedTransactionPeriod || '');
        formik.setFieldValue('voidTransactionPeriod', keyData?.voidTransactionPeriod || '');

        const correctedDate = (keyData?.expiryDate && formattedDate(keyData?.expiryDate)) || '';

        formik.setFieldValue('expiryDate', correctedDate || '');
        formik.setFieldValue('inputField', keyData?.inputField || '');
        formik.setFieldValue('description', keyData?.description || '');
        formik.setFieldValue('helpText', keyData?.helpText || '');
        formik.setFieldValue('ticker', keyData?.ticker === 'Y' ? true : false);
        formik.setFieldValue('tickerText', keyData?.tickerText || '');
        formik.setFieldValue('isActive', keyData?.isActive || '');

        setIconLink(`${baseURL}/document/${keyData?.icon?.split('/documents/')[1]}`);
        // setIconLink(`${baseURL}/document/${keyData?.icon?.substring(keyData?.icon?.indexOf('/') + 1)}`);
        setDefaultCompanyIcon(keyData?.icon);
        const companyPathParts = (keyData?.icon && keyData?.icon?.split('/')) || '';
        const company = (companyPathParts && companyPathParts[companyPathParts?.length - 1]) || '';
        formik.setFieldValue('icon', company || '');
        // setCompanyIcon(company);
        setDefaultHelpImageIcon(keyData?.helpImage);
        const helpPathParts = (keyData?.helpImage && keyData?.helpImage?.split('/')) || '';
        const help = (helpPathParts && helpPathParts[helpPathParts?.length - 1]) || '';

        formik.setFieldValue('helpImage', help || '');
        setHelpImageIcon(help);
    };

    useEffect(() => {
        getCompany();
        getGlId();
        getParserHead();
        getUBPType();
        getMainCategoryDataLov();
        getBankName();
        getCompanyType();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (formik?.values?.cmsMainCategoryId) getSubCategoryDataLov();
        if (formik?.values?.cmsSubCategoryId) getSecSubCategoryDataLov();
    }, [formik?.values?.cmsMainCategoryId, formik?.values?.cmsSubCategoryId]); // eslint-disable-line react-hooks/exhaustive-deps

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());

    function formattedDate(originalDate) {
        const createDate = new Date(originalDate);

        const year = createDate.getFullYear();
        const month = (createDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const day = createDate.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const handledHelpImageIcon = () => {
        helpimageRef.current.click();
    };

    const handleHelpImageChange = (event) => {
        const selectedHelpImage = event.target.files[0];

        if (!selectedHelpImage) {
            formik.setFieldError('files', 'Please choose a file.');
            setSelectedHelpImage('');
        }
        if (selectedHelpImage) {
            setSelectedHelpImage(selectedHelpImage);
            setHelpImageIcon(selectedHelpImage?.name);
        }
    };

    const handledCompanyIcon = () => {
        companyIconRef.current.click();
    };

    const handleCompanyIconChange = (event) => {
        setCategoryIconError('');
        formik.setFieldValue('icon', '');
        setIconLink('');
        setSelectedFile(null);
        const selectedFile = event.target.files[0];
        const fileTypes = ['image'];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setCategoryIconError(error);
            setSelectedFile(null);
        } else if (newFile) {
            setSelectedFile(selectedFile);
            formik.setFieldValue('icon', selectedFile.name);
            setIconLink(URL.createObjectURL(selectedFile));
        }
    };
    const getContrastColor = (hexColor) => {
        const r = parseInt(hexColor.substr(1, 2), 16);
        const g = parseInt(hexColor.substr(3, 2), 16);
        const b = parseInt(hexColor.substr(5, 2), 16);
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    };
    const handleAddTagChange = (e) => {
        formik.handleChange(e);
        if (!e.target.checked) {
            formik.setFieldValue('cmsTagLabel', '');
            formik.setFieldValue('cmsTagTextColor', '#FFFFFF');
            formik.setFieldValue('cmsTagBgColor', '#FFFFFF');
        }
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="Form__Header Full__Width">
                        <h2>EDIT DETAILS</h2>
                    </div>
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-fluid p-formgrid grid mb-5 col-12 md:col-9 ">
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsMainCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsMainCategoryId') }, 'Label__Text')}>
                                        Main Category <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="cmsMainCategoryId"
                                        placeholder="Select Main Category"
                                        options={maincategoryLov}
                                        optionLabel="name"
                                        name="cmsMainCategoryId"
                                        optionValue="lovId"
                                        value={formik.values.cmsMainCategoryId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsMainCategoryId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('cmsMainCategoryId')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsSubCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsSubCategoryId') }, 'Label__Text')}>
                                        Sub Category
                                    </label>
                                    <Dropdown
                                        id="cmsSubCategoryId"
                                        placeholder="Select Sub Category"
                                        options={subCategoryLov}
                                        optionLabel="name"
                                        name="cmsSubCategoryId"
                                        optionValue="lovId"
                                        value={formik.values.cmsSubCategoryId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsSubCategoryId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('cmsSubCategoryId')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsSecondSubCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsSecondSubCategoryId') }, 'Label__Text')}>
                                        2nd Sub Category
                                    </label>
                                    <Dropdown
                                        id="cmsSecondSubCategoryId"
                                        placeholder="Select 2nd Sub Category"
                                        options={secondSubCategoryLov}
                                        optionLabel="name"
                                        name="cmsSecondSubCategoryId"
                                        optionValue="lovId"
                                        value={formik.values.cmsSecondSubCategoryId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsSecondSubCategoryId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('cmsSecondSubCategoryId')}
                                </div>
                            </div>
                            {secondSubCategoryLov.some((option) => option.name === 'Utility Bill Payment' && option.lovId === formik.values.cmsSecondSubCategoryId) ? (
                                <>
                                    <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                        <div className="p-field">
                                            <label htmlFor="cmsCompanyId" className={classNames({ 'p-error': isFormFieldValid('cmsCompanyId') }, 'Label__Text')}>
                                                Type<span className="Label__Required">*</span>
                                            </label>
                                            <Dropdown
                                                id="cmsCompanyId"
                                                placeholder="Select Bank Name"
                                                options={companyType}
                                                optionLabel="name"
                                                name="cmsCompanyId"
                                                optionValue="lovId"
                                                value={formik.values.cmsCompanyId || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsCompanyId') }, 'Dropdown__Round')}
                                            />
                                            {getFormErrorMessage('cmsCompanyId')}
                                        </div>
                                    </div>
                                </>
                            ) : null}
                            {secondSubCategoryLov.some((option) => option.name === 'Education' && option.lovId === formik.values.cmsSecondSubCategoryId) ? (
                                <>
                                    <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                        <div className="p-field">
                                            <label htmlFor="cmsUbpTypeId" className={classNames({ 'p-error': isFormFieldValid('cmsUbpTypeId') }, 'Label__Text')}>
                                                Type<span className="Label__Required">*</span>
                                            </label>
                                            <Dropdown
                                                id="cmsUbpTypeId"
                                                placeholder="Select Bank Name"
                                                options={uBPType}
                                                optionLabel="name"
                                                name="cmsUbpTypeId"
                                                optionValue="lovId"
                                                value={formik.values.cmsUbpTypeId || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsUbpTypeId') }, 'Dropdown__Round')}
                                            />
                                            {getFormErrorMessage('cmsUbpTypeId')}
                                        </div>
                                    </div>
                                </>
                            ) : null}
                            {/* {formik.values.cmsSecondSubCategoryId && formik.values.cmsSecondSubCategoryId !== '181' ? (
                                <>
                                    <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                        <div className="p-field">
                                            <label htmlFor="glAccountId" className={classNames({ 'p-error': isFormFieldValid('glAccountId') }, 'Label__Text')}>
                                                GL ID
                                            </label>

                                            <Dropdown
                                                id="glAccountId"
                                                filter
                                                placeholder="Select Gl ID"
                                                options={glId}
                                                optionLabel="accountNo"
                                                name="glAccountId"
                                                optionValue="accountId"
                                                value={formik.values.glAccountId}
                                                // onChange={formik.handleChange}
                                                onChange={(e) => {
                                                    formik.handleChange(e);

                                                    // formik.setFieldValue("glAccountId",e.target.value.accountId)
                                                    const glData = glId?.find((value) => value?.accountId === e.target.value && value?.accountTitle);

                                                    formik.setFieldValue('glAccountName', glData?.accountTitle);
                                                }}
                                                className={classNames({ 'p-invalid': isFormFieldValid('glAccountId') }, 'Dropdown__Round')}
                                            />
                                            {getFormErrorMessage('glAccountId')}
                                        </div>
                                    </div>

                                    <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                        <div className="p-field">
                                            <label htmlFor="glAccountName" className={classNames({ 'p-error': isFormFieldValid('glAccountName') }, 'Label__Text')}>
                                                GL Name
                                            </label>
                                            <InputText
                                                id="glAccountName"
                                                placeholder="Enter Account Number"
                                                type="text"
                                                disabled
                                                name="glAccountName"
                                                value={formik?.values?.glAccountName}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('glAccountName') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('glAccountName')}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )} */}
                            {secondSubCategoryLov.some((option) => option.name === 'Donations' && option.lovId === formik.values.cmsSecondSubCategoryId) ? (
                                <>
                                    <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                        <div className="p-field">
                                            <label htmlFor="cmsBankId" className={classNames({ 'p-error': isFormFieldValid('cmsBankId') }, 'Label__Text')}>
                                                Bank Name<span className="Label__Required">*</span>
                                            </label>
                                            <Dropdown
                                                id="cmsBankId"
                                                placeholder="Select Bank Name"
                                                options={bankName}
                                                optionLabel="name"
                                                name="cmsBankId"
                                                optionValue="lovId"
                                                value={formik.values.cmsBankId || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsBankId') }, 'Dropdown__Round')}
                                            />
                                            {getFormErrorMessage('cmsBankId')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                        <div className="p-field">
                                            <label htmlFor="bankAccountNo" className={classNames({ 'p-error': isFormFieldValid('bankAccountNo') }, 'Label__Text')}>
                                                Account No <span className="Label__Required">*</span>
                                            </label>
                                            <InputText
                                                id="bankAccountNo"
                                                placeholder="Enter Account Number"
                                                type="text"
                                                name="bankAccountNo"
                                                value={formik?.values?.bankAccountNo}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('bankAccountNo') }, 'Input__Round')}
                                            />
                                            {getFormErrorMessage('bankAccountNo')}
                                        </div>
                                    </div>
                                </>
                            ) : null}

<div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="nameEnglish" className={classNames({ 'p-error': isFormFieldValid('nameEnglish') }, 'Label__Text')}>
                                        Display Name (English) <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="nameEnglish"
                                        placeholder="Enter Display Name (English)"
                                        name="nameEnglish"
                                        value={formik?.values?.nameEnglish}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            if (formik.values.copyEngToUrdu) {
                                                formik.setFieldValue('nameUrdu', e.target.value);
                                            }
                                        }}
                                        className={classNames({ 'p-invalid': isFormFieldValid('nameEnglish') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('nameEnglish')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <div className="p-field-checkbox flex justify-content-between">
                                        <label htmlFor="nameUrdu" className={classNames({ 'p-error': isFormFieldValid('nameUrdu') }, 'Label__Text')}>
                                            Display Name (Urdu)<span className="Label__Required">*</span>
                                        </label>
                                        <div className="flex align-items-center ml-auto">
                                            <label htmlFor="nameUrdu" className={classNames({ 'p-error': isFormFieldValid('nameUrdu') }, 'Label__Text')}>
                                                Same AS (English)
                                            </label>
                                        </div>
                                        <Checkbox
                                            inputId="copyEngToUrdu"
                                            name="copyEngToUrdu"
                                            checked={formik.values.copyEngToUrdu}
                                            onChange={(e) => {
                                                formik.setFieldValue('copyEngToUrdu', e.checked);
                                                if (e.checked) {
                                                    formik.setFieldValue('nameUrdu', formik.values.nameEnglish);
                                                }
                                            }}
                                        />
                                    </div>
                                    <InputText
                                        maxLength={100}
                                        id="nameUrdu"
                                        placeholder="Enter Display Name In Urdu"
                                        name="nameUrdu"
                                        value={formik?.values?.nameUrdu || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('nameUrdu') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('nameUrdu')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="priority" className={classNames({ 'p-error': isFormFieldValid('priority') }, 'Label__Text')}>
                                        Priority <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="priority"
                                        keyfilter="int"
                                        placeholder="Enter priority"
                                        name="priority"
                                        value={formik?.values?.priority}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('priority') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('priority')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field">
                                    <div className="mb-2">
                                        <label className={classNames({ 'p-error': isFormFieldValid('icon') }, 'Label__Text')}>
                                            Category Icon<span className="Label__Required">*</span>
                                        </label>
                                        <div className="file-input-container w-100" onClick={handledCompanyIcon}>
                                            <input type="file" accept="image/*" style={{ display: 'none' }} ref={companyIconRef} onChange={handleCompanyIconChange} />
                                            <input
                                                type="text"
                                                className={classNames({ 'p-inputtext p-component p-invalid': isFormFieldValid('icon') }, 'file-input-field Input__Round')}
                                                placeholder="Choose a file..."
                                                value={formik?.values?.icon}
                                                readOnly
                                            />
                                            <span className="file-upload-icon">
                                                <i className="pi pi-paperclip" />
                                            </span>
                                        </div>
                                        {categoryIconError ? <small className="p-error">{categoryIconError}</small> : getFormErrorMessage('icon')}
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="productId" className={classNames({ 'p-error': isFormFieldValid('productId') }, 'Label__Text')}>
                                        Product ID <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="productId"
                                        placeholder="Enter Product ID "
                                        name="productId"
                                        value={formik?.values?.productId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('productId') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('productId')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsCompanyTypeId" className={classNames({ 'p-error': isFormFieldValid('cmsCompanyTypeId') }, 'Label__Text')}>
                                        Company Type<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="cmsCompanyTypeId"
                                        placeholder="Select Company Type"
                                        options={companyType}
                                        optionLabel="name"
                                        name="cmsCompanyTypeId"
                                        optionValue="lovId"
                                        value={formik?.values?.cmsCompanyTypeId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames(
                                            {
                                                'p-invalid': isFormFieldValid('cmsCompanyTypeId')
                                            },
                                            'Dropdown__Round'
                                        )}
                                    />
                                    {getFormErrorMessage('cmsCompanyTypeId')}
                                </div>
                            </div>
                            {companyType.some((option) => option.name === 'Offline' && option.lovId === formik.values.cmsCompanyTypeId) ? (
                                <>
                                    <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                        <div className="p-field">
                                            <label htmlFor="cmsParserHeadId" className={classNames({ 'p-error': isFormFieldValid('cmsParserHeadId') }, 'Label__Text')}>
                                                Parser Head<span className="Label__Required">*</span>
                                            </label>

                                            <Dropdown
                                                id="cmsParserHeadId"
                                                placeholder="Select Parser Head"
                                                options={parserHead}
                                                optionLabel="name"
                                                name="cmsParserHeadId"
                                                optionValue="lovId"
                                                value={formik?.values?.cmsParserHeadId || ''}
                                                onChange={formik.handleChange}
                                                className={classNames(
                                                    {
                                                        'p-invalid': isFormFieldValid('cmsParserHeadId')
                                                    },
                                                    'Dropdown__Round'
                                                )}
                                            />

                                            {getFormErrorMessage('cmsParserHeadId')}
                                        </div>
                                    </div>
                                </>
                            ) : null}
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="billBlockPeriod" className={classNames({ 'p-error': isFormFieldValid('billBlockPeriod') }, 'Label__Text')}>
                                        Bill Block - Period Days<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="billBlockPeriod"
                                        placeholder="Enter Account Number"
                                        type="text"
                                        name="billBlockPeriod"
                                        value={formik?.values?.billBlockPeriod}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('billBlockPeriod') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('billBlockPeriod')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="partialPayment" className={classNames({ 'p-error': isFormFieldValid('channelName') }, 'Label__Text')}>
                                        Allow Partial Payment
                                    </label>

                                    <div className="flex pl-1">
                                        <div className="mt-2 mr-8">
                                            <RadioButton inputId="Yes" name="partialPaymentAllowed" value="Y" onChange={formik.handleChange} checked={formik.values.partialPaymentAllowed === 'Y'} />

                                            <label htmlFor="yes" className={classNames({ 'p-error': isFormFieldValid('Yes') }, 'Label__Text')}>
                                                Yes
                                            </label>
                                        </div>

                                        <div className="mt-2">
                                            <RadioButton inputId="no" name="partialPaymentAllowed" value="N" onChange={formik.handleChange} checked={formik.values.partialPaymentAllowed === 'N'} />

                                            <label htmlFor="no" className={classNames({ 'p-error': isFormFieldValid('no') }, 'Label__Text')}>
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="voidTransactionPeriod" className={classNames({ 'p-error': isFormFieldValid('voidTransactionPeriod') }, 'Label__Text')}>
                                        Void Transaction Period<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="voidTransactionPeriod"
                                        placeholder="Enter Account Number"
                                        type="text"
                                        name="voidTransactionPeriod"
                                        value={formik?.values?.voidTransactionPeriod}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('voidTransactionPeriod') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('voidTransactionPeriod')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="backdatedTransactionPeriod" className={classNames({ 'p-error': isFormFieldValid('backdatedTransactionPeriod') }, 'Label__Text')}>
                                        Back Dated Transaction Period Minutes<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="backdatedTransactionPeriod"
                                        placeholder="Enter Account Number"
                                        type="text"
                                        name="backdatedTransactionPeriod"
                                        value={formik?.values?.backdatedTransactionPeriod}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('backdatedTransactionPeriod') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('backdatedTransactionPeriod')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="gracePeriod" className={classNames({ 'p-error': isFormFieldValid('gracePeriod') }, 'Label__Text')}>
                                        Grace Period Minutes<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="gracePeriod"
                                        placeholder="Enter Account Number"
                                        type="text"
                                        name="gracePeriod"
                                        value={formik?.values?.gracePeriod}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('gracePeriod') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('gracePeriod')}
                                </div>
                            </div>

                            <div className="card Card_Bg_Change col-12 md:col-12">
                                <h6 className="secondary-color">
                                    <b>TAG</b>
                                </h6>
                                <span className="Label__Required">*</span>
                                <div className="p-field col-12 md:col-6 pt-3 pb-3 flex align-items-end justify-content-start">
                                    <div className="field-checkbox flex mb-2 p-field-addbanner-width ">
                                        <div className="mr-5">
                                            <Checkbox inputId="addTag" name="addTag" checked={formik.values.addTag} onChange={handleAddTagChange} style={{ marginTop: '10px' }}   disabled/>
                                            <label
                                                htmlFor="addTag"
                                                className={classNames(
                                                    {
                                                        'p-error': isFormFieldValid('addTag')
                                                    },
                                                    'Label__Text'
                                                )}
                                                style={{ marginTop: '10px' }}
                                              
                                            >
                                                Add Tag<span className="Label__Required">*</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-fluid p-formgrid grid mb-5">
                                    <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                        <div className="p-field">
                                            <label htmlFor="cmsTagLabel" className={classNames({ 'p-error': isFormFieldValid('cmsTagLabel') }, 'Label__Text')}>
                                                Tag Label
                                            </label>

                                            <InputText
                                                disabled={!formik?.values?.addTag}
                                                maxLength={100}
                                                id="cmsTagLabel"
                                                placeholder="Enter Tag Label"
                                                name="cmsTagLabel"
                                                value={formik?.values?.cmsTagLabel || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagLabel') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('cmsTagLabel')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                        <div className="p-field relative">
                                            <label htmlFor="cmsTagTextColor" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextColor') }, 'Label__Text')}>
                                                Tag Text Color
                                            </label>
                                            <InputText
                                                value={formik?.values?.cmsTagTextColor || '#FFFFFF'}
                                                onChange={(e) => {
                                                    formik.setFieldValue('cmsTagTextColor', e.target.value);
                                                }}
                                                onClick={() => setShowColorPicker({ textColor: true, bgColor: false })}
                                                disabled={!formik?.values?.addTag}
                                                readOnly
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextColor') }, 'Input__Round')}
                                                style={{
                                                    backgroundColor: formik?.values?.cmsTagTextColor || '#FFFFFF',
                                                    color: getContrastColor(formik?.values?.cmsTagTextColor || '#FFFFFF')
                                                }}
                                            />
                                            {getFormErrorMessage('cmsTagTextColor')}
                                            {showColorPicker?.textColor && (
                                                <div className="colorPickerForInput">
                                                    <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                                    <ColorPicker color={formik?.values?.cmsTagTextColor || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagTextColor', e?.hex)} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                        <div className="p-field">
                                            <label htmlFor="cmsTagBgColor" className={classNames({ 'p-error': isFormFieldValid('cmsTagBgColor') }, 'Label__Text')}>
                                                Tag Background Color
                                            </label>
                                            <InputText
                                                value={formik?.values?.cmsTagBgColor || '#FFFFFF'}
                                                onChange={(e) => {
                                                    formik.setFieldValue('cmsTagBgColor', e.target.value);
                                                }}
                                                onClick={() => setShowColorPicker({ textColor: false, bgColor: true })}
                                                disabled={!formik?.values?.addTag}
                                                readOnly
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagBgColor') }, 'Input__Round')}
                                                style={{
                                                    backgroundColor: formik?.values?.cmsTagBgColor || '#FFFFFF',
                                                    color: getContrastColor(formik?.values?.cmsTagBgColor || '#FFFFFF')
                                                }}
                                            />
                                            {getFormErrorMessage('cmsTagBgColor')}
                                            {showColorPicker?.bgColor && (
                                                <div className="colorPickerForInput">
                                                    <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                                    <ColorPicker color={formik?.values?.cmsTagBgColor || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagBgColor', e?.hex)} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="expiryDate" className={classNames({ 'p-error': isFormFieldValid('expiryDate') }, 'Label__Text')}>
                                        Expiry Date
                                    </label>
                                    <InputText id="expiryDate" type="date" name="expiryDate" value={formik?.values?.expiryDate} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('expiryDate') }, 'Input__Round')} />

                                    {getFormErrorMessage('expiryDate')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="inputField" className={classNames({ 'p-error': isFormFieldValid('inputField') }, 'Label__Text')}>
                                        Input Field
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="inputField"
                                        placeholder="Enter Input Field "
                                        name="inputField"
                                        value={formik?.values?.inputField || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('inputField') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('inputField')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-12 w-full pt-3 pb-3 p-field-addbanner-width">
                                <div className="desc_Text_Area">
                                    <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') }, 'Label__Text')}>
                                        Description
                                    </label>
                                    <InputTextarea
                                        placeholder="-"
                                        inputid="description"
                                        name="description"
                                        rows={4}
                                        cols={30}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('description') }, 'Input__Round')}
                                        value={formik?.values?.description}
                                    />
                                    {getFormErrorMessage('description')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field">
                                    <div className="mb-2">
                                        <label className="Label__Text">
                                            Help Image<span className="Label__Required">*</span>
                                        </label>
                                        <div className="file-input-container w-100" onClick={handledHelpImageIcon}>
                                            <input type="file" style={{ display: 'none' }} ref={helpimageRef} onChange={handleHelpImageChange} accept="image/*" />
                                            <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={helpImageIcon} readOnly />
                                            <span className="file-upload-icon">
                                                <i className="pi pi-paperclip" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="helpText" className={classNames({ 'p-error': isFormFieldValid('helpText') }, 'Label__Text')}>
                                        Help Text
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="helpText"
                                        placeholder="Enter Help Text"
                                        name="helpText"
                                        value={formik?.values?.helpText || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('helpText') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('helpText')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-2 pt-2 pb-2 flex align-items-start justify-content-start flex-column gap-2 ">
                                <label htmlFor="ticker" className={classNames({ 'p-error': isFormFieldValid('enablePartialPayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                    ticker
                                </label>
                                <Checkbox
                                    inputId="ticker"
                                    name="ticker"
                                    checked={formik?.values?.ticker}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        formik.setFieldValue('tickerText', '');
                                    }}
                                    style={{ marginTop: '10px' }}
                                />{' '}
                            </div>
                            {formik?.values?.ticker && (
                                <div className="p-field col-12 md:col-10 pt-3 pb-3 p-field-ticker-text-width">
                                    <div className="p-field">
                                        <label htmlFor="tickerText" className={classNames({ 'p-error': isFormFieldValid('tickerText') }, 'Label__Text')}>
                                            Ticker Text
                                        </label>
                                        <InputText
                                            maxLength={100}
                                            id="tickerText"
                                            placeholder="Enter Ticker Text "
                                            name="tickerText"
                                            value={formik?.values?.tickerText || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('tickerText') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('tickerText')}
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="p-field col-12 md:col-3 pt-3 pb-3 col-4 flex align-items-start justify-content-center">
                            <MobileView icon={iconLink} title={formik?.values?.nameEnglish} />
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Update" className="Btn__Dark" />
                        <Button onClick={() => navigate('/company')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default EditCompany;

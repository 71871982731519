import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { FilterMatchMode } from 'primereact/api';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import ActiveInActiveDialog from '../../../../components/ActiveInActiveDialog/ActiveInActiveDialog';
import { MultiSelect } from 'primereact/multiselect';

function DisableSms() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dataTableRef = useRef(null);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [showModal, setShowModal] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [switchData, setSwitchData] = useState([]);
    const [segmentData, setSegmentData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [subCategoryData, setSubCategoryData] = useState([]);
    const [secSubCategoryData, setSecSubCategoryData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        smsText: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        smsTitle: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        categoryName: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        subCategoryName: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        secondSubCategoryName: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        createdate: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        dateFrom: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        dateTo: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        createdBy: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        updatedBy: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        status: { value: '', matchMode: FilterMatchMode.EQUALS }
    });

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            smsText: '',
            smsTitle: '',
            mainCategoryId: '',
            subCategoryId: '',
            secondSubCategoryId: '',
            createdBy: '',
            createDate: '',
            statusId: '2'
        },

        onSubmit: async (data) => {
            setloadingIcon('pi pi-spin pi-spinner');
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            dataTableRef.current.reset();
            const res = await dispatch(handlePostRequest(newData, '/cms/v1/cms/searchallsmsconfig', true, true));
            const updatedArray = res?.payLoad?.map((item) => {
                statusLovData?.map((status) => {
                    if (item?.statusId?.toString() === status?.lovId) {
                        item['statusName'] = status?.name;
                    }
                });
                return { ...item, lastUpdatedOn: item?.lastUpdatedOn || 'N/A', lastUpdateUser: item?.lastUpdateUser || 'N/A' };
            });
            setSearchData(updatedArray);
            setloadingIcon('pi pi-search');
        }
    });

    const getSegmentsData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getsegments/LKP_SEGMENT:*');
        setSegmentData(res?.payLoad);
    };

    const getCategoryData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcategorydatalov');
        setCategoryData(res?.payLoad);
    };

    const getSubCategoryData = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getsubcategorydatalov/${formik?.values?.mainCategoryId}`);
        setSubCategoryData(res?.payLoad);
    };

    const getSecSubCategoryData = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getsecondsubcategorydatalov/${formik.values.mainCategoryId}/${formik.values.subCategoryId}`);
        setSecSubCategoryData(res?.payLoad);
    };

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(res?.payLoad);
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">Disable SMS Configuration</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const header = () => renderHeader();

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const handleSwitchChange = async () => {
        const updatedData = searchData?.map((item) => {
            if (item?.cmsSmsConfigId === switchData?.cmsSmsConfigId) {
                const isActive = item?.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setSearchData(updatedData);
        let isactive = '';
        if (switchData?.isActive === 'Y') {
            isactive = 'N';
        } else if (switchData?.isActive === 'N') {
            isactive = 'Y';
        }
        const previousValue = switchData?.isActive;

        const data = {
            isActive: isactive,
            id: switchData?.cmsSmsConfigId
            // smsTitle: switchData?.smsTitle,
            // smsContent: switchData?.smsContent,
            // productId: switchData?.productId,
            // tblCmsSmsConfigSegments: switchData?.tblCmsSmsConfigSegments?.map((item) => {
            //     return {
            //         segmentId: parseInt(item?.segmentId, 10) || 0,
            //         cmsSmsConfigSegmentId: item?.cmsSmsConfigSegmentId || 0
            //     };
            // }),
            // cmsMainCategoryId: switchData?.cmsMainCategoryId,
            // cmsSubCategoryId: switchData?.cmsSubCategoryId,
            // cmsSecondSubCategoryId: switchData?.cmsSecondSubCategoryId
        };

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newData, '/cms/v1/cms/inactivesmsconfig', true, false));
        if (res?.responseCode == '010000') {
            const revertedData = searchData?.map((item) => {
                if (item.cmsSmsConfigId == switchData?.cmsSmsConfigId) {
                    const isActive = isactive;
                    return { ...item, isActive };
                }
                return item;
            });
            setSearchData(revertedData);
        } else {
            const revertedData = searchData?.map((item) => {
                if (item.cmsSmsConfigId == switchData?.cmsSmsConfigId) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setSearchData(revertedData);
        }
    };

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }
        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <InputSwitch
                        checked={rowData.isActive === 'Y'}
                        disabled={['Pending', 'Rejected'].includes(rowData?.statusName) ? true : false}
                        onChange={() => {
                            setSwitchData(rowData);
                            setShowModal(true);
                        }}
                    />
                    <Button
                        tooltip="Edit Detail"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: 'top' }}
                        disabled={['Pending', 'Rejected'].includes(rowData?.statusName) || (rowData?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                        onClick={() => navigate(`/editdisablesms`, { state: { rowData } })}
                        className="p-button-rounded p-button-primary"
                    />
                    <Button
                        tooltip="View Detail"
                        tooltipOptions={{ position: 'top' }}
                        // disabled={['Pending', 'Rejected'].includes(rowData?.statusName) || (rowData?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                        icon="pi pi-eye"
                        onClick={() => navigate(`/viewdisablesms`, { state: { rowData } })}
                        className="p-button-rounded"
                        style={{ backgroundColor: '#F2AC57', border: 'none' }}
                    />
                </div>
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.statusName === 'Approved' ? '#14A38B' : rowData?.statusName === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.statusName}</b>
            </p>
        );
    };

    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
        setSubCategoryData([]);
        setSecSubCategoryData([]);
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const handleShowModal = () => {
        setShowModal(false);
    };

    const props = {
        showModal,
        handleShowModal,
        handleSwitchChange
    };

    useEffect(() => {
        if (formik?.values?.mainCategoryId != '') {
            getSubCategoryData();
        }
        if (formik?.values?.subCategoryId != '') {
            getSecSubCategoryData();
        }
    }, [formik.values.mainCategoryId, formik.values.subCategoryId]);

    useEffect(() => {
        getSegmentsData();
        getCategoryData();
        getStatusListData();
        getUserLov();
    }, []);

    return (
        <>
            <ActiveInActiveDialog {...props} />
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={() => navigate('/adddisablesms')} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="smsTitle" className={classNames({ 'p-error': isFormFieldValid('smsTitle') }, 'Label__Text')}>
                                    SMS Title
                                </label>
                                <InputText
                                    placeholder="Enter SMS Title"
                                    id="smsTitle"
                                    name="smsTitle"
                                    value={formik.values.smsTitle || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('smsTitle') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('smsTitle')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="smsText" className={classNames({ 'p-error': isFormFieldValid('smsText') }, 'Label__Text')}>
                                    SMS Text
                                </label>
                                <InputText
                                    placeholder="Enter SMS Text"
                                    id="smsText"
                                    name="smsText"
                                    value={formik.values.smsText || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('smsText') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('smsText')}
                            </div>
                        </div>
                        {/* <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="productId" className={classNames({ 'p-error': isFormFieldValid('productId') }, 'Label__Text')}>
                                    Product ID
                                </label>
                                <InputText
                                    placeholder="Enter Product ID"
                                    id="productId"
                                    name="productId"
                                    value={formik?.values?.productId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productId') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('productId')}
                            </div>
                        </div> */}
                        {/* <div className="p-field col-12 md:col-4">
                            <div className="p-field customMultiselect">
                                <label htmlFor="tblCmsSmsConfigSegments" className={classNames({ 'p-error': isFormFieldValid('tblCmsSmsConfigSegments') }, 'Label__Text')}>
                                    Segments
                                </label>
                                <MultiSelect
                                    id="tblCmsSmsConfigSegments"
                                    placeholder="Select Segments"
                                    options={segmentData}
                                    optionLabel="name"
                                    display="chip"
                                    name="tblCmsSmsConfigSegments"
                                    optionValue="lovId"
                                    value={formik?.values?.tblCmsSmsConfigSegments?.map((segment) => segment.lovId || '')}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            'tblCmsSmsConfigSegments',
                                            e?.value?.map((lovId) => ({
                                                lovId: lovId
                                            }))
                                        );
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldValid('tblCmsSmsConfigSegments') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('tblCmsSmsConfigSegments')}
                            </div>
                        </div> */}
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="mainCategoryId" className={classNames({ 'p-error': isFormFieldValid('mainCategoryId') }, 'Label__Text')}>
                                    Category
                                </label>
                                <Dropdown
                                    id="mainCategoryId"
                                    placeholder="Select Category"
                                    options={categoryData}
                                    optionLabel="name"
                                    name="mainCategoryId"
                                    optionValue="lovId"
                                    value={formik.values.mainCategoryId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('mainCategoryId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('mainCategoryId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="subCategoryId" className={classNames({ 'p-error': isFormFieldValid('subCategoryId') }, 'Label__Text')}>
                                    Sub Category
                                </label>
                                <Dropdown
                                    id="subCategoryId"
                                    placeholder="Select Sub Category"
                                    options={subCategoryData}
                                    optionLabel="name"
                                    name="subCategoryId"
                                    optionValue="lovId"
                                    value={formik.values.subCategoryId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('subCategoryId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('subCategoryId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="secondSubCategoryId" className={classNames({ 'p-error': isFormFieldValid('secondSubCategoryId') }, 'Label__Text')}>
                                    2<sup>nd</sup> Sub Category
                                </label>
                                <Dropdown
                                    id="secondSubCategoryId"
                                    placeholder="Select Second Sub Category"
                                    options={secSubCategoryData}
                                    optionLabel="name"
                                    name="secondSubCategoryId"
                                    optionValue="lovId"
                                    value={formik.values.secondSubCategoryId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('secondSubCategoryId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('secondSubCategoryId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createDate" className={classNames({ 'p-error': isFormFieldValid('createDate') }, 'Label__Text')}>
                                    Created On
                                </label>
                                <InputText id="createDate" type="date" name="createDate" value={formik?.values?.createDate} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('createDate') }, 'Input__Round')} />
                                {getFormErrorMessage('createDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Name"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik?.values?.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        {/* <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText
                                    id="dateFrom"
                                    type="date"
                                    max={previousDate}
                                    name="dateFrom"
                                    value={formik?.values?.dateFrom?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div> */}
                        {/* <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText
                                    id="dateTo"
                                    type="date"
                                    max={previousDate}
                                    name="dateTo"
                                    value={formik?.values?.dateTo?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div> */}
                        {/* <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Name"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div> */}
                        {/* <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Name"
                                    options={userLov}
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div> */}
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="statusId" className={classNames({ 'p-error': isFormFieldValid('statusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select Status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label="Search" disabled={!Object.values(formik.values).some((value) => value !== null && value !== '')} icon={loadingIcon || ''} iconPos="left" className="Btn__Dark" />
                        <Button type="button" label="Reset" iconPos="right" className="Btn__Transparent" onClick={(e) => handleReset(e)} />
                    </div>
                </form>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            filters={filters}
                            globalFilterFields={['smsText', 'smsTitle', 'categoryName', 'subCategoryName', 'secondSubCategoryName', 'createdate', 'dateFrom', 'dateTo', 'createdBy', 'updatedBy', 'statusName']}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={searchData}
                        >
                            <Column field="smsTitle" header="SMS Title" />
                            <Column field="smsContent" header="SMS Text" />
                            <Column field="categoryName" header="Category" />
                            <Column field="subCategoryName" header="Sub Category" />
                            <Column field="secondSubCategoryName" header="2nd Sub Category" />
                            <Column field="createdBy" header="Created By" />
                            <Column field="updatedBy" header="Updated By" />
                            <Column body={createdDateAndTimeBodyTemplate} header="Created Date & Time" />
                            <Column header="Status" body={statusBodyTemplate} />
                            <Column header="Action" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
export default DisableSms;

// import React, { useState, useRef, useEffect } from 'react';
// import { Button } from 'primereact/button';
// import { useFormik } from 'formik';
// import classNames from 'classnames';
// import * as Yup from 'yup';
// import { InputText } from 'primereact/inputtext';
// import { Checkbox } from 'primereact/checkbox';
// import { useDispatch } from 'react-redux';
// import { Dropdown } from 'primereact/dropdown';
// import { useNavigate } from 'react-router-dom';
// import { DataTable } from 'primereact/datatable';
// import { Column } from 'primereact/column';
// import { useLocation } from 'react-router-dom';

// function ViewBulletLoanChecker({ activeIndex, handleStepClick, lendingData }) {
//     const [loading, setloading] = useState(false);
//     const [loadingIcon, setloadingIcon] = useState('pi pi-search');
//     const [frequencyPeriodLov, setFrequencyPeriodLov] = useState([]);
//     const [fieldTableData, setFieldTableData] = useState([]);

//     const [buttons, setButtons] = useState([
//         { sequenceName: 'Principal Amount', sequence: 1 },
//         { sequenceName: 'Markup', sequence: 2 },
//         { sequenceName: 'Fees', sequence: 3 }
//     ]);

//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     // Getting params from URL using hash router
//     let { search } = useLocation();
//     const query = new URLSearchParams(search);

//     const requestType = query.get('requestType');
//     const requestTypeDescr = query.get('requestTypeDescr');

//     useEffect(() => {
//         if (requestType === 'I' || (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request')) {
//             if (lendingData) {
//                 formik.setFieldValue('numberofDays', lendingData?.tblLmsRepaymentMethods[0]?.noOfDays);

//                 formik.setFieldValue('gracePeriod', lendingData?.tblLmsRepaymentMethods[0]?.gracePeriod === 'Y' ? true : false);
//                 // formik.setFieldValue('pricingId', lendingData?.tblLmsRepaymentMethods[0]?.tblPricingProfile?.pricingProfileId.toString());

//                 formik.setFieldValue('noOfDaysGracePeriod', lendingData?.tblLmsRepaymentMethods[0]?.noOfGraceDays);

//                 formik.setFieldValue('downPayment', lendingData?.tblLmsRepaymentMethods[0]?.downPayment === 'Y' ? true : false);
//                 formik.setFieldValue('downPaymentCharges', lendingData?.tblLmsRepaymentMethods[0]?.downPaymentCharges);

//                 formik.setFieldValue('latePayment', lendingData?.tblLmsRepaymentMethods[0]?.latePayment);
//                 formik.setFieldValue('latePaymentCharges', lendingData?.tblLmsRepaymentMethods[0]?.latePaymentCharges);

//                 if (lendingData?.tblLmsRepaymentMethods[0]?.tblLmsRepaymentSequences) {
//                     setButtons(lendingData?.tblLmsRepaymentMethods[0]?.tblLmsRepaymentSequences);
//                 }
//             }
//         } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
//             if (lendingData[2]) {
//                 formik.setFieldValue('numberofDays', lendingData[2]?.noOfDays);

//                 formik.setFieldValue('gracePeriod', lendingData[2]?.gracePeriod === true ? true : false);
//                 // formik.setFieldValue('pricingId', lendingData[2]?.tblLmsRepaymentMethods[0]?.tblPricingProfile?.pricingProfileId.toString());

//                 formik.setFieldValue('noOfDaysGracePeriod', lendingData[2]?.noOfDaysGracePeriod);

//                 formik.setFieldValue('downPayment', lendingData[2]?.downPayment === true ? true : false);
//                 formik.setFieldValue('downPaymentCharges', lendingData[2]?.downPaymentCharges);

//                 formik.setFieldValue('latePayment', lendingData[2]?.pricing);
//                 formik.setFieldValue('latePaymentCharges', lendingData[2]?.charges);

//                 if (lendingData[2]?.repaymentSeq) {
//                     const transformedArray = Object.entries(lendingData[2]?.repaymentSeq).map(([sequenceName, sequence]) => ({
//                         sequenceName,
//                         sequence
//                     }));
//                     setButtons(transformedArray);
//                 }
//             }
//         }
//     }, []);

//     const formik = useFormik({
//         initialValues: {
//             // campaignName: "",
//             // startDateTime: "",
//             // endDateTime: "",
//             // campaignBudget: "",
//             // isSubLimits: false,
//             // frequencyPeriodId: "",
//             // amount: ""
//         },

//         onSubmit: async (data) => {
//             setloading(true);
//             setloadingIcon('pi pi-spin pi-spinner');
//             const newData = {
//                 data: {
//                     security: {
//                         userName: '',
//                         password: '',
//                         securityToken: ''
//                     },
//                     account: {
//                         msidn: '',
//                         iban: '',
//                         bban: '',
//                         pan: '',
//                         currency: ''
//                     },
//                     channel: '',
//                     terminal: '',
//                     reterivalReferenceNumber: '',
//                     payLoad: data,
//                     additionalInformation: [
//                         {
//                             infoKey: '',
//                             infoValue: ''
//                         }
//                     ],
//                     checkSum: ''
//                 }
//             };

//             handleStepClick(activeIndex + 1);
//             setloading(false);
//             setloadingIcon('pi pi-search');
//         }
//     });

//     const handledDeleteFieldsData = (e, rowData) => {
//         e.preventDefault();
//         const newFilterData = fieldTableData.filter((value) => value.fieldName !== rowData.fieldName);
//         setFieldTableData(newFilterData);
//     };
//     const actionFieldsBodyTemplate = (rowData) => {
//         return (
//             <>
//                 <div className="actions">
//                     <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteFieldsData(e, rowData)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
//                 </div>
//             </>
//         );
//     };

//     const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

//     const getFormErrorMessage = (name) => {
//         return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
//     };

//     const handleDragStart = (e, label) => {
//         e.dataTransfer.setData('text/plain', label);
//     };

//     const handleDragOver = (e) => {
//         e.preventDefault();
//     };

//     const handleDrop = (e, targetLabel) => {
//         e.preventDefault();

//         const draggedLabel = e.dataTransfer.getData('text/plain');
//         const updatedButtons = [...buttons];

//         const draggedButton = updatedButtons.find((button) => button.sequenceName === draggedLabel);
//         const targetButton = updatedButtons.find((button) => button.sequenceName === targetLabel);

//         if (draggedButton && targetButton) {
//             // Swap the sequence numbers
//             const tempSequence = draggedButton.sequence;
//             draggedButton.sequence = targetButton.sequence;
//             targetButton.sequence = tempSequence;

//             // Sort buttons based on sequence
//             updatedButtons.sort((a, b) => a.sequence - b.sequence);

//             setButtons(updatedButtons);
//         }
//     };

//     const downPaymentLov = [
//         {
//             name: 'Yes',
//             lovId: true
//         },
//         {
//             name: 'No',
//             lovId: false
//         }
//     ];

//     const pricingLov = [
//         {
//             name: 'Principal Account',
//             lovId: '1'
//         },
//         {
//             name: 'Markup',
//             lovId: '2'
//         },
//         {
//             name: 'Fees',
//             lovId: '3'
//         }
//     ];

//     return (
//         <>
//             <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
//                 <h1 style={{ color: '#fff', marginLeft: '10px' }}>REPAYMENT METHOD/ VIEW DETAILS</h1>
//             </div>
//             <div className="card Card__Round">
//                 <div className="Form__Header">
//                     <h1>LOAN TENURE </h1>
//                 </div>
//                 <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
//                     <div className="p-fluid p-formgrid grid mb-5">
//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <label htmlFor="numberofDays" className={classNames({ 'p-error': isFormFieldValid('numberofDays') }, 'Label__Text')}>
//                                     Number of Days <span className="Label__Required"></span>
//                                 </label>
//                                 <InputText
//                                     id="numberofDays"
//                                     placeholder="Enter Number of Days"
//                                     name="numberofDays"
//                                     value={formik?.values?.numberofDays || ''}
//                                     disabled={true}
//                                     onChange={formik.handleChange}
//                                     className={classNames({ 'p-invalid': isFormFieldValid('numberofDays') }, 'Input__Round')}
//                                 />
//                                 {getFormErrorMessage('numberofDays')}
//                             </div>
//                         </div>
//                         <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
//                             <div className="p-field">
//                                 <div className="field-checkbox">
//                                     <Checkbox inputId="gracePeriod" disabled name="gracePeriod" value={formik?.values?.gracePeriod} checked={formik?.values?.gracePeriod} onChange={formik.handleChange} style={{ marginTop: '10px' }} />
//                                     <label htmlFor="gracePeriod" className={classNames({ 'p-error': isFormFieldValid('gracePeriod') }, 'Label__Text')} style={{ marginTop: '10px' }}>
//                                         Grace Period
//                                     </label>
//                                 </div>
//                                 {getFormErrorMessage('gracePeriod')}
//                             </div>
//                         </div>

//                         {formik?.values?.gracePeriod === true && (
//                             <div style={{ backgroundColor: '#edf0f5', padding: '2rem' }} className=" col-12 card">
//                                 <div className="Form__Header">
//                                     <h1>GRACE PERIOD </h1>
//                                 </div>
//                                 <div className="grid">
//                                     <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                                         <div className="p-field">
//                                             <label htmlFor="noOfDaysGracePeriod" className={classNames({ 'p-error': isFormFieldValid('noOfDaysGracePeriod') }, 'Label__Text')}>
//                                                 Number of Days <span className="Label__Required"></span>
//                                             </label>
//                                             <InputText
//                                                 id="noOfDaysGracePeriod"
//                                                 placeholder="Enter Number of Days"
//                                                 name="noOfDaysGracePeriod"
//                                                 value={formik?.values?.noOfDaysGracePeriod || ''}
//                                                 disabled={true}
//                                                 onChange={formik.handleChange}
//                                                 className={classNames({ 'p-invalid': isFormFieldValid('noOfDaysGracePeriod') }, 'Input__Round')}
//                                             />
//                                             {getFormErrorMessage('noOfDaysGracePeriod')}
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         )}
//                         <div className="Form__Header">
//                             <h1>LATE PAYMENT </h1>
//                         </div>
//                     </div>

//                     <div className="grid">
//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <label htmlFor="latePayment" className={classNames({ 'p-error': isFormFieldValid('latePayment') }, 'Label__Text')}>
//                                     Pricing<span className="Label__Required"></span>
//                                 </label>
//                                 <Dropdown
//                                     id="latePayment"
//                                     placeholder="Select Late Payment"
//                                     options={pricingLov}
//                                     optionLabel="name"
//                                     name="latePayment"
//                                     filter
//                                     optionValue="lovId"
//                                     value={formik?.values?.latePayment || ''}
//                                     disabled={true}
//                                     onChange={formik.handleChange}
//                                     className={classNames({ 'p-invalid': isFormFieldValid('latePayment') }, 'Dropdown__Round')}
//                                 />

//                                 {getFormErrorMessage('latePayment')}
//                             </div>
//                         </div>

//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <label htmlFor="latePaymentCharges" className={classNames({ 'p-error': isFormFieldValid('latePaymentCharges') }, 'Label__Text')}>
//                                     Charges <span className="Label__Required"></span>
//                                 </label>
//                                 <InputText
//                                     id="latePaymentCharges"
//                                     placeholder="Enter Late Payment Charges"
//                                     name="latePaymentCharges"
//                                     value={formik?.values?.latePaymentCharges || ''}
//                                     disabled={true}
//                                     onChange={formik.handleChange}
//                                     className={classNames({ 'p-invalid': isFormFieldValid('latePaymentCharges') }, 'Input__Round')}
//                                 />
//                                 {getFormErrorMessage('latePaymentCharges')}
//                             </div>
//                         </div>
//                     </div>
//                     <div className="Form__Header">
//                         <h1>DOWN PAYMENT </h1>
//                     </div>
//                     <div className="grid">
//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <label htmlFor="downPayment" className={classNames({ 'p-error': isFormFieldValid('downPayment') }, 'Label__Text')}>
//                                     Select Down Payment<span className="Label__Required"></span>
//                                 </label>
//                                 <Dropdown
//                                     id="downPayment"
//                                     placeholder="Select Down Payment"
//                                     options={downPaymentLov}
//                                     optionLabel="name"
//                                     name="downPayment"
//                                     filter
//                                     optionValue="lovId"
//                                     value={formik?.values?.downPayment || ''}
//                                     disabled={true}
//                                     onChange={formik.handleChange}
//                                     className={classNames({ 'p-invalid': isFormFieldValid('downPayment') }, 'Dropdown__Round')}
//                                 />

//                                 {getFormErrorMessage('downPayment')}
//                             </div>
//                         </div>
//                         {formik?.values?.downPayment === true && (
//                             <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                                 <div className="p-field">
//                                     <label htmlFor="downPaymentCharges" className={classNames({ 'p-error': isFormFieldValid('downPaymentCharges') }, 'Label__Text')}>
//                                         Charges <span className="Label__Required"></span>
//                                     </label>
//                                     <InputText
//                                         id="downPaymentCharges"
//                                         placeholder="Enter Down Payment Charges"
//                                         name="downPaymentCharges"
//                                         value={formik?.values?.downPaymentCharges || ''}
//                                         disabled={true}
//                                         onChange={formik.handleChange}
//                                         className={classNames({ 'p-invalid': isFormFieldValid('downPaymentCharges') }, 'Input__Round')}
//                                     />
//                                     {getFormErrorMessage('downPaymentCharges')}
//                                 </div>
//                             </div>
//                         )}
//                         {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <label htmlFor="charges" className={classNames({ 'p-error': isFormFieldValid('charges') }, 'Label__Text')}>
//                                     Charges <span className="Label__Required"></span>
//                                 </label>
//                                 <InputText
//                                     id="charges"
//                                     placeholder="Enter Charges"
//                                     name="charges"
//                                     value={formik?.values?.charges || ''}
//                                     onChange={formik.handleChange}
//                                     className={classNames({ 'p-invalid': isFormFieldValid('charges') }, 'Input__Round')}
//                                 />
//                                 {getFormErrorMessage('charges')}
//                             </div>
//                         </div> */}
//                     </div>
//                     <div className="Form__Header">
//                         <h1>DEFINE REPAYMENT SEQUENCE </h1>
//                     </div>
//                     <div className="Centre_Aligned_Buttons" onDragOver={handleDragOver}>
//                         {buttons.map((button) => (
//                             <Button
//                                 key={button.sequenceName}
//                                 className="DraggableButton Light_Button"
//                                 label={`${button.sequenceName}`}
//                                 disabled
//                                 // draggable
//                                 // onDragStart={(e) => handleDragStart(e, button.sequenceName)}
//                                 // onDrop={(e) => handleDrop(e, button.sequenceName)}
//                             />
//                         ))}
//                     </div>
//                     {/* <div className="Centre_Aligned_Buttons">
//                         <Button className="Light_Button" disabled label="Principal Amount" style={{ cursor: "grabbing" }} onClick={(e) => handlePricipalAmount()} />
//                         <Button className="DarkGray_Button" disabled label="Markup" style={{ cursor: "grabbing" }} onClick={(e) => handleMarkup(e)} />
//                         <Button className="DarkGray_Button" disabled label="Fees" style={{ cursor: "grabbing" }} onClick={(e) => handleFee(e)} />
//                     </div> */}
//                     {/* <div className="Down__Btn">
//                         <Button disabled={loading} style={{ margin: "20px" }} label={parseInt(lendingData?.currentStep) >= 2 ? "Edit" : "Save"} className="Btn__Dark" />
//                     </div> */}
//                 </form>
//             </div>
//         </>
//     );
// }
// export default ViewBulletLoanChecker;



import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';
import { handleGetRequest } from '../../../../../../services/GetTemplate';
import { useLocation } from 'react-router';

function ViewBulletLoanChecker({ activeIndex, handleStepClick, lendingData }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [frequencyPeriodData, setFrequencyPeriodData] = useState([]);

    /////////////////////////////////////////
    const [bulletLoanTableData, setBulletLoanTableData] = useState([]);
    const [bulletLoanData, setBulletLoanData] = useState({
        noOfDays: '',
        markupValue: '',
        apr: '',

        gracePeriod: '',
        noOfDaysGracePeriod: '',

        latePayment: '',
        latePaymentType: '',
        latePaymentCharges: '',
        latePaymentOn: '',

        frequencyId: '',

        earlyPayment: '',
        earlyPaymentType: '',
        earlyPaymentCharges: '',
        earlyPaymentOn: '',

        downPayment: '',
        downPaymentCharges: ''
    });
    const [bulletLoanError, setBulletLoanError] = useState({
        noOfDays: '',
        markupValue: '',
        apr: '',

        gracePeriod: '',
        noOfDaysGracePeriod: '',

        latePayment: '',
        latePaymentType: '',
        latePaymentCharges: '',
        latePaymentOn: '',

        frequencyId: '',

        earlyPayment: '',
        earlyPaymentType: '',
        earlyPaymentCharges: '',
        earlyPaymentOn: '',

        downPayment: '',
        downPaymentCharges: ''
    });
    //////////////////////////////

    const [buttons, setButtons] = useState([
        { label: 'Principal Amount', sequence: 1 },
        { label: 'Markup', sequence: 2 },
        { label: 'Fees', sequence: 3 }
    ]);

    const dispatch = useDispatch();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const requestType = query.get('requestType');
    const requestTypeDescr = query.get('requestTypeDescr');

    useEffect(() => {
        if (requestType === 'I' || (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request')) {
            let bulletLoan = [];
            if (lendingData) {
                if(lendingData?.tblLmsRepaymentMethods?.length > 0) {
                    bulletLoan = lendingData?.tblLmsRepaymentMethods?.filter((itm) => itm?.isActive === "Y")?.map((item) => {
                        return {
                            noOfDays: item?.noOfDays?.toString(),
                            markupValue: item?.markupValue,
                            apr: item?.apr,

                            gracePeriod: item?.gracePeriod === "Y" ? true : false,
                            noOfDaysGracePeriod: item?.noOfGraceDays,

                            latePayment: item?.latePayment === "Y" ? true : false,
                            latePaymentType: item?.latePaymentType?.toString(),
                            latePaymentCharges: item?.latePaymentCharges,
                            latePaymentOn: item?.latePaymentOn?.toString(),

                            earlyPayment: item?.earlyPayment === "Y" ? true : false,
                            earlyPaymentType: item?.earlyPaymentType?.toString(),
                            earlyPaymentCharges: item?.earlyPaymentCharges,
                            earlyPaymentOn: item?.earlyPaymentOn?.toString(),

                            frequencyId: item?.lkpFrequencyPeriod?.frequencyPeriodId?.toString(),

                            downPayment: item?.downPayment === "Y" ? "Y" : "N",
                            downPaymentCharges: item?.downPaymentCharges,
                        }
                    })
                    setBulletLoanData(bulletLoan[0]);
                }

                let pA = [];
                Object.keys(lendingData?.tblLmsRepaymentMethods[0]).forEach((key) => {
                    if (key === 'seqPrincipalAmount') {
                        pA.push({ label: key, value: lendingData?.tblLmsRepaymentMethods[0][key] });
                    }

                    if (key === 'seqMarkup') {
                        pA.push({ label: key, value: lendingData?.tblLmsRepaymentMethods[0][key] });
                    }

                    if (key === 'seqLateFee') {
                        pA.push({ label: key, value: lendingData?.tblLmsRepaymentMethods[0][key] });
                    }
                });

                const updatedDefinePayment = pA?.map(item => {
                    return {
                        label: item.label.replace('seq', ''),
                        sequence: item.value
                    };
                });

                updatedDefinePayment?.sort((a, b) => a.sequence - b.sequence);
                setButtons(updatedDefinePayment);
            }
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            let bulletLoan = [];
            if (lendingData[2]) {
                if(lendingData[2]?.tblLmsRepaymentMethods?.length > 0) {
                    bulletLoan = lendingData[2]?.tblLmsRepaymentMethods?.filter((itm) => itm?.isActive === "Y")?.map((item) => {
                        return {
                            noOfDays: item?.noOfDays?.toString(),
                            markupValue: item?.markupValue,
                            apr: item?.apr,

                            gracePeriod: item?.gracePeriod === "Y" ? true : false,
                            noOfDaysGracePeriod: item?.noOfGraceDays,

                            latePayment: item?.latePayment === "Y" ? true : false,
                            latePaymentType: item?.latePaymentType?.toString(),
                            latePaymentCharges: item?.latePaymentCharges,
                            latePaymentOn: item?.latePaymentOn?.toString(),

                            earlyPayment: item?.earlyPayment === "Y" ? true : false,
                            earlyPaymentType: item?.earlyPaymentType?.toString(),
                            earlyPaymentCharges: item?.earlyPaymentCharges,
                            earlyPaymentOn: item?.earlyPaymentOn?.toString(),

                            frequencyId: item?.lkpFrequencyPeriod?.frequencyPeriodId?.toString(),

                            downPayment: item?.downPayment === "Y" ? "Y" : "N",
                            downPaymentCharges: item?.downPaymentCharges,
                        }
                    })
                    setBulletLoanData(bulletLoan[0]);
                }

                let pA = [];
                Object.keys(lendingData[2]?.tblLmsRepaymentMethods[0]).forEach((key) => {
                    if (key === 'seqPrincipalAmount') {
                        pA.push({ label: key, value: lendingData[2]?.tblLmsRepaymentMethods[0][key] });
                    }

                    if (key === 'seqMarkup') {
                        pA.push({ label: key, value: lendingData[2]?.tblLmsRepaymentMethods[0][key] });
                    }

                    if (key === 'seqLateFee') {
                        pA.push({ label: key, value: lendingData[2]?.tblLmsRepaymentMethods[0][key] });
                    }
                });

                const updatedDefinePayment = pA?.map(item => {
                    return {
                        label: item.label.replace('seq', ''),
                        sequence: item.value
                    };
                });

                updatedDefinePayment?.sort((a, b) => a.sequence - b.sequence);
                setButtons(updatedDefinePayment);
            }
        }
    }, [lendingData]);

    const getFrequencyPeriodData = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getdatalov/LKP_FREQUENCY_PERIOD');
        setFrequencyPeriodData(response?.payLoad);
    };

    useEffect(() => {
        getFrequencyPeriodData();
    }, []);

    useEffect(() => {
        let bulletLoan = [];
        if (lendingData) {

            if(lendingData?.tblLmsRepaymentMethods?.length > 0) {
                bulletLoan = lendingData?.tblLmsRepaymentMethods?.filter((itm) => itm?.isActive === "Y")?.map((item) => {
                    return {
                        noOfDays: item?.noOfDays?.toString(),
                        markupValue: item?.markupValue,
                        apr: item?.apr,

                        gracePeriod: item?.gracePeriod === "Y" ? true : false,
                        noOfDaysGracePeriod: item?.noOfGraceDays,

                        latePayment: item?.latePayment === "Y" ? true : false,
                        latePaymentType: item?.latePaymentType?.toString(),
                        latePaymentCharges: item?.latePaymentCharges,
                        latePaymentOn: item?.latePaymentOn?.toString(),

                        earlyPayment: item?.earlyPayment === "Y" ? true : false,
                        earlyPaymentType: item?.earlyPaymentType?.toString(),
                        earlyPaymentCharges: item?.earlyPaymentCharges,
                        earlyPaymentOn: item?.earlyPaymentOn?.toString(),

                        frequencyId: item?.lkpFrequencyPeriod?.frequencyPeriodId?.toString(),

                        downPayment: item?.downPayment === "Y" ? "Y" : "N",
                        downPaymentCharges: item?.downPaymentCharges,
                    }
                })
                setBulletLoanData(bulletLoan[0]);
            }

            let pA = [];
            Object.keys(lendingData?.tblLmsRepaymentMethods[0]).forEach((key) => {
                if (key === 'seqPrincipalAmount') {
                    pA.push({ label: key, value: lendingData?.tblLmsRepaymentMethods[0][key] });
                }

                if (key === 'seqMarkup') {
                    pA.push({ label: key, value: lendingData?.tblLmsRepaymentMethods[0][key] });
                }

                if (key === 'seqLateFee') {
                    pA.push({ label: key, value: lendingData?.tblLmsRepaymentMethods[0][key] });
                }
            });

            const updatedDefinePayment = pA?.map(item => {
                return {
                    label: item.label.replace('seq', ''),
                    sequence: item.value
                };
            });

            updatedDefinePayment?.sort((a, b) => a.sequence - b.sequence);
            setButtons(updatedDefinePayment);

        }
    }, [lendingData]);

    const validationSchema = Yup.object().shape({});

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {},

        validate: (data) => {},

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handlePricipalAmount = (e) => {
        e.preventDefault();
    };
    const handleMarkup = (e) => {
        e.preventDefault();
    };
    const handleFee = (e) => {
        e.preventDefault();
    };

    const handleDragStart = (e, label) => {
        e.dataTransfer.setData('text/plain', label);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e, targetLabel) => {
        e.preventDefault();

        const draggedLabel = e.dataTransfer.getData('text/plain');
        const updatedButtons = [...buttons];

        const draggedButton = updatedButtons.find((button) => button.label === draggedLabel);
        const targetButton = updatedButtons.find((button) => button.label === targetLabel);

        if (draggedButton && targetButton) {
            // Swap the sequence numbers
            const tempSequence = draggedButton.sequence;
            draggedButton.sequence = targetButton.sequence;
            targetButton.sequence = tempSequence;

            // Sort buttons based on sequence
            updatedButtons.sort((a, b) => a.sequence - b.sequence);

            setButtons(updatedButtons);
        }
    };

    const downPaymentLov = [
        {
            name: 'Yes',
            lovId: 'Y'
        },
        {
            name: 'No',
            lovId: 'N'
        }
    ];

    // const frequencyBodyTemplate = (rowData) => {
    //     const singleFrequency = frequencyPeriodData?.filter((item) => item?.lovId == rowData?.frequencyId);
    //     return <p>{singleFrequency[0]?.name}</p>;
    // };

    //////////////////////////new UI code /////////////////////
    const loanTenureNOD = [
        { name: '90 Days', lovId: '90' },
        { name: '180 Days', lovId: '180' },
        { name: '270 Days', lovId: '270' },
        { name: '360 Days', lovId: '360' }
    ];

    const typeLov = [
        { name: 'FIXED', lovId: 'F' },
        { name: 'PERCENTAGE', lovId: 'P' }
    ];

    const paymentOnLov = [
        { name: 'Outstanding Principal', lovId: 'O' },
        { name: 'Markup', lovId: 'M' }
    ];

    /////////////////////////////////////////////////////////////
    const actionBodyTemplate = (rowData, rowIndex) => {
        return (
            <>
                <Button tooltip="Edit" tooltipOptions={{ position: 'top' }} icon="pi pi-pencil" onClick={(e) => handledEditData(e, rowData, rowIndex)} className="p-button-rounded p-button-primary" style={{ border: 'none' }} />
                <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteData(e, rowData, rowIndex)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
            </>
        );
    };

    const handledDeleteData = (e, rowData, rowIndex) => {
        e.preventDefault();
        const newFilterData = bulletLoanTableData?.filter((value, index) => index !== rowIndex?.rowIndex);
        setBulletLoanTableData(newFilterData);
    };

    const handledEditData = (e, rowData, rowIndex) => {
        e.preventDefault();
        const newFilterData = bulletLoanTableData?.filter((value, index) => index !== rowIndex?.rowIndex);
        setBulletLoanData(newFilterData);
    };

    const handledAdd = (e) => {
        // e.preventDefault();

        const errors = {
            noOfDays: '',
            markupValue: '',
            apr: '',

            gracePeriod: '',
            noOfDaysGracePeriod: '',

            latePayment: '',
            latePaymentType: '',
            latePaymentCharges: '',
            latePaymentOn: '',

            frequencyId: '',

            earlyPayment: '',
            earlyPaymentType: '',
            earlyPaymentCharges: '',
            earlyPaymentOn: '',

            downPayment: '',
            downPaymentCharges: '',

            seqPrincipalAmount: '',
            seqMarkup: '',
            seqLateFee: ''
        };

        if (bulletLoanData?.noOfDays === '') {
            errors.noOfDays = 'This field is required.';
        }

        if (bulletLoanData?.markupValue === '') {
            errors.markupValue = 'This field is required.';
        }

        if (bulletLoanData?.apr === '') {
            errors.apr = 'This field is required.';
        }

        if (bulletLoanData?.gracePeriod === true) {
            if (bulletLoanData?.noOfDaysGracePeriod === '') {
                errors.noOfDaysGracePeriod = 'This field is required';
            }
        }

        if (bulletLoanData?.latePayment === true) {
            if (bulletLoanData?.latePaymentType === '') {
                errors.latePaymentType = 'This field is required';
            }

            if (bulletLoanData?.latePaymentCharges === '') {
                errors.latePaymentCharges = 'This field is required';
            }

            if (bulletLoanData?.latePaymentOn === '') {
                errors.latePaymentOn = 'This field is required';
            }
        }

        if (bulletLoanData?.frequencyId === '') {
            errors.frequencyId = 'This field is required';
        }

        if (bulletLoanData?.earlyPayment === true) {
            if (bulletLoanData?.earlyPaymentType === '') {
                errors.earlyPaymentType = 'This field is required';
            }

            if (bulletLoanData?.earlyPaymentCharges === '') {
                errors.earlyPaymentCharges = 'This field is required';
            }

            if (bulletLoanData?.earlyPaymentOn === '') {
                errors.earlyPaymentOn = 'This field is required';
            }
        }

        if (bulletLoanData?.downPayment === 'Y') {
            if (bulletLoanData?.downPaymentCharges === '') {
                errors.downPaymentCharges = 'This field is required';
            }
        }

        setBulletLoanError(errors);

        const sequenceObject = buttons?.reduce((result, item) => {
            const label = item.label.replace(/\s+/g, ''); // Remove spaces from label
            const seqKey = `seq${label}`;
            result[seqKey] = item.sequence;
            return result;
        }, {});

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            const newBulletLoanTableData = {
                noOfDays: bulletLoanData?.noOfDays,
                markupValue: bulletLoanData?.markupValue,
                apr: bulletLoanData?.apr,

                gracePeriod: bulletLoanData?.gracePeriod,
                noOfDaysGracePeriod: bulletLoanData?.noOfDaysGracePeriod,

                latePayment: bulletLoanData?.latePayment,
                latePaymentType: bulletLoanData?.latePaymentType,
                latePaymentCharges: bulletLoanData?.latePaymentCharges,
                latePaymentOn: bulletLoanData?.latePaymentOn,

                frequencyId: bulletLoanData?.frequencyId || '',

                earlyPayment: bulletLoanData?.earlyPayment,
                earlyPaymentType: bulletLoanData?.earlyPaymentType,
                earlyPaymentCharges: bulletLoanData?.earlyPaymentCharges,
                earlyPaymentOn: bulletLoanData?.earlyPaymentOn,

                downPayment: bulletLoanData?.downPayment === 'Y' ? true : false,
                downPaymentCharges: bulletLoanData?.downPaymentCharges,

                repaymentTypeCode: lendingData?.lkpLmsFacilityType?.lmsFacilityTypeCode
            };

            const combinedObject = { ...newBulletLoanTableData, ...sequenceObject };
            setBulletLoanTableData([...bulletLoanTableData, combinedObject]);

            // setBulletLoanData({
            //     noOfDays: '',
            //     markupValue: '',
            //     apr: '',

            //     gracePeriod: '',
            //     noOfDaysGracePeriod: '',

            //     latePayment: '',
            //     latePaymentType: '',
            //     latePaymentCharges: '',
            //     latePaymentOn: '',

            //     frequencyId: '',

            //     earlyPayment: '',
            //     earlyPaymentType: '',
            //     earlyPaymentCharges: '',
            //     earlyPaymentOn: '',

            //     downPayment: '',
            //     downPaymentCharges: ''
            // });

            setButtons([
                { label: 'Principal Amount', sequence: 1 },
                { label: 'Markup', sequence: 2 },
                { label: 'Fees', sequence: 3 }
            ]);
        }
    };

    const handledData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        const checked = e.target.checked;
        const type = e.target.type;

        setBulletLoanData({ ...bulletLoanData, [name]: type === 'checkbox' ? checked : value });

        // Clear the error message for the field being edited
        setBulletLoanError({
            ...bulletLoanError,
            [name]: ''
        });
    };
    //////////////////////////////////////////////////////////

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>REPAYMENT METHOD/ADD NEW</h1>
            </div>
            <div className="card Card__Round">
                <div className="Form__Header flex align-items-center justify-content-between">
                    <h1>LOAN TENURE </h1>
                    {/* <Button
                        icon="pi pi-plus"
                        className="p-button-rounded"
                        aria-label="Filter"
                        tooltip="Add New Repayment"
                        onClick={(e) => handledAdd(e)}
                    /> */}
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="noOfDays" className={classNames({ 'p-error': isFormFieldValid('noOfDays') }, 'Label__Text')}>
                                    No. Of Days<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="noOfDays"
                                    placeholder="Select No. Of Days"
                                    options={loanTenureNOD}
                                    optionLabel="name"
                                    name="noOfDays"
                                    filter
                                    optionValue="lovId"
                                    disabled={true}
                                    value={bulletLoanData?.noOfDays || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('noOfDays') }, 'Dropdown__Round')}
                                />
                                <small className="p-error">{bulletLoanError?.noOfDays}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="markupValue" className={classNames({ 'p-error': isFormFieldValid('markupValue') }, 'Label__Text')}>
                                    Markup value <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={4}
                                    id="markupValue"
                                    placeholder="Enter Markup Value"
                                    name="markupValue"
                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                    disabled={true}
                                    value={bulletLoanData?.markupValue || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('markupValue') }, 'Input__Round')}
                                />
                                <small className="p-error">{bulletLoanError?.markupValue}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="apr" className={classNames({ 'p-error': isFormFieldValid('apr') }, 'Label__Text')}>
                                    APR <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="apr"
                                    placeholder="Enter APR"
                                    name="apr"
                                    maxLength={4}
                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                    value={bulletLoanData?.apr || ''}
                                    onChange={handledData}
                                    disabled={true}
                                    className={classNames({ 'p-invalid': isFormFieldValid('apr') }, 'Input__Round')}
                                />
                                <small className="p-error">{bulletLoanError?.apr}</small>
                            </div>
                        </div>
                    </div>
                    <div className="Form__Header col-12">
                        <h1>GRACE PERIOD </h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="gracePeriod" name="gracePeriod" value={bulletLoanData?.gracePeriod || ''} checked={bulletLoanData?.gracePeriod} onChange={handledData} style={{ marginTop: '10px' }} disabled/>
                                    <label htmlFor="gracePeriod" className={classNames({ 'p-error': isFormFieldValid('gracePeriod') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Grace Period
                                    </label>
                                </div>
                                <small className="p-error">{bulletLoanError?.gracePeriod}</small>
                            </div>
                        </div>
                        {bulletLoanData?.gracePeriod === true && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="noOfDaysGracePeriod" className={classNames({ 'p-error': isFormFieldValid('noOfDaysGracePeriod') }, 'Label__Text')}>
                                        Number of Days <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={4}
                                        id="noOfDaysGracePeriod"
                                        placeholder="Enter Number of Days"
                                        name="noOfDaysGracePeriod"
                                        keyfilter={'int'}
                                        disabled={true}
                                        value={bulletLoanData?.noOfDaysGracePeriod || ''}
                                        onChange={handledData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('noOfDaysGracePeriod') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{bulletLoanError?.noOfDaysGracePeriod}</small>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="Form__Header col-12">
                        <h1>LATE PAYMENT </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="latePayment" name="latePayment" value={bulletLoanData?.latePayment || ''} checked={bulletLoanData?.latePayment} onChange={handledData} style={{ marginTop: '10px' }} disabled/>
                                    <label htmlFor="latePayment" className={classNames({ 'p-error': isFormFieldValid('latePayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Late payment
                                    </label>
                                </div>
                                <small className="p-error">{bulletLoanError?.latePayment}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        {bulletLoanData?.latePayment === true && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentType" className={classNames({ 'p-error': isFormFieldValid('latePaymentType') }, 'Label__Text')}>
                                            Type<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="latePaymentType"
                                            placeholder="Select Type"
                                            options={typeLov}
                                            optionLabel="name"
                                            name="latePaymentType"
                                            filter
                                            optionValue="lovId"
                                            disabled={true}
                                            value={bulletLoanData?.latePaymentType || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('latePaymentType') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{bulletLoanError?.latePaymentType}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentCharges" className={classNames({ 'p-error': isFormFieldValid('latePaymentCharges') }, 'Label__Text')}>
                                            Charges <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="latePaymentCharges"
                                            placeholder="Enter charges"
                                            name="latePaymentCharges"
                                            maxLength={8}
                                            disabled={true}
                                            keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                            value={bulletLoanData?.latePaymentCharges || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('latePaymentCharges') }, 'Input__Round')}
                                        />
                                        <small className="p-error">{bulletLoanError?.latePaymentCharges}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentOn" className={classNames({ 'p-error': isFormFieldValid('latePaymentOn') }, 'Label__Text')}>
                                            Late Payment On<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="latePaymentOn"
                                            placeholder="Select Late Payment On"
                                            options={paymentOnLov}
                                            optionLabel="name"
                                            name="latePaymentOn"
                                            filter
                                            disabled={true}
                                            optionValue="lovId"
                                            value={bulletLoanData?.latePaymentOn || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('latePaymentOn') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{bulletLoanError?.latePaymentOn}</small>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {/* //////////////////////////////////////////////////// */}
                    <div className="Form__Header col-12">
                        <h1>REPAYMENT SCHEDULE </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="frequencyId" className={classNames({ 'p-error': isFormFieldValid('frequencyId') }, 'Label__Text')}>
                                    Frequency Of Payment<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="frequencyId"
                                    placeholder="Select Frequency Of Payment"
                                    options={frequencyPeriodData}
                                    optionLabel="name"
                                    name="frequencyId"
                                    filter
                                    disabled={true}
                                    optionValue="lovId"
                                    value={bulletLoanData?.frequencyId || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('frequencyId') }, 'Dropdown__Round')}
                                />
                                <small className="p-error">{bulletLoanError?.frequencyId}</small>
                            </div>
                        </div>
                    </div>
                    {/* ///////////////////////////////////////////////////// */}

                    <div className="Form__Header col-12">
                        <h1>ALLOW EARLY REPAYMENT </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="earlyPayment" name="earlyPayment" value={bulletLoanData?.earlyPayment || ''} checked={bulletLoanData?.earlyPayment} onChange={handledData} style={{ marginTop: '10px' }} disabled/>
                                    <label htmlFor="earlyPayment" className={classNames({ 'p-error': isFormFieldValid('earlyPayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Early Payment
                                    </label>
                                </div>
                                <small className="p-error">{bulletLoanError?.earlyPayment}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        {bulletLoanData?.earlyPayment === true && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="earlyPaymentType" className={classNames({ 'p-error': isFormFieldValid('earlyPaymentType') }, 'Label__Text')}>
                                            Type<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="earlyPaymentType"
                                            placeholder="Select Type"
                                            options={typeLov}
                                            optionLabel="name"
                                            name="earlyPaymentType"
                                            filter
                                            disabled={true}
                                            optionValue="lovId"
                                            value={bulletLoanData?.earlyPaymentType || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('earlyPaymentType') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{bulletLoanError?.earlyPaymentType}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="earlyPaymentCharges" className={classNames({ 'p-error': isFormFieldValid('earlyPaymentCharges') }, 'Label__Text')}>
                                            Charges <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="earlyPaymentCharges"
                                            placeholder="Enter charges"
                                            name="earlyPaymentCharges"
                                            maxLength={8}
                                            disabled={true}
                                            keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                            value={bulletLoanData?.earlyPaymentCharges || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('earlyPaymentCharges') }, 'Input__Round')}
                                        />
                                        <small className="p-error">{bulletLoanError?.earlyPaymentCharges}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="earlyPaymentOn" className={classNames({ 'p-error': isFormFieldValid('earlyPaymentOn') }, 'Label__Text')}>
                                            Early Payment On<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="earlyPaymentOn"
                                            placeholder="Select Early Payment On"
                                            options={paymentOnLov}
                                            optionLabel="name"
                                            name="earlyPaymentOn"
                                            filter
                                            disabled={true}
                                            optionValue="lovId"
                                            value={bulletLoanData?.earlyPaymentOn || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('earlyPaymentOn') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{bulletLoanError?.earlyPaymentOn}</small>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="Form__Header col-12">
                        <h1>DOWN PAYMENT </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="downPayment" className={classNames({ 'p-error': isFormFieldValid('downPayment') }, 'Label__Text')}>
                                    Select Down Payment<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="downPayment"
                                    placeholder="Select Down Payment"
                                    options={downPaymentLov}
                                    optionLabel="name"
                                    name="downPayment"
                                    disabled={true}
                                    filter
                                    optionValue="lovId"
                                    value={bulletLoanData?.downPayment || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('downPayment') }, 'Dropdown__Round')}
                                />
                                <small className="p-error">{bulletLoanError?.downPayment}</small>
                            </div>
                        </div>
                        {bulletLoanData?.downPayment === 'Y' && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="downPaymentCharges" className={classNames({ 'p-error': isFormFieldValid('downPaymentCharges') }, 'Label__Text')}>
                                        Charges <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="downPaymentCharges"
                                        placeholder="Enter Down Payment Charges"
                                        name="downPaymentCharges"
                                        disabled={true}
                                        maxLength={8}
                                        keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                        value={bulletLoanData?.downPaymentCharges || ''}
                                        onChange={handledData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('downPaymentCharges') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{bulletLoanError?.downPaymentCharges}</small>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="Form__Header">
                        <h1>DEFINE REPAYMENT SEQUENCE </h1>
                    </div>
                    <div className="Centre_Aligned_Buttons" onDragOver={handleDragOver}>
                        {buttons?.map((button) => (
                            <Button
                                key={button.label}
                                className="DraggableButton Light_Button"
                                onClick={(e) => e.preventDefault()}
                                label={`${button.label}`}
                                disabled
                                // draggable
                                // onDragStart={(e) => handleDragStart(e, button.label)}
                                // onDrop={(e) => handleDrop(e, button.label)}
                            />
                        ))}
                    </div>
                    {/* <div className="grid">
                        <div className="col-12">
                            <div className="card mt-6">
                                <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={bulletLoanTableData}>
                                    <Column field="noOfDays" header="No. Of Days" />
                                    <Column field="markupValue" header="markup value" />
                                    <Column field="apr" header="APR" />
                                    <Column field="status" header="Status" />
                                    <Column header="Actions" body={actionBodyTemplate} style={{ textAlign: 'center' }} className="Table__ActionHeading__CentredAlign" />
                                </DataTable>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} style={{ margin: '20px' }} onClick={() => handledAdd()} label={lendingData?.currentStep > 2 ? 'Edit' : 'Save'} className="Btn__Dark" />
                    </div> */}
                </form>
            </div>
        </>
    );
}
export default ViewBulletLoanChecker;
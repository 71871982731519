import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import SlabWiseHybridOldNewValues from './SlabWiseHybridOldNewValues';
import { Sidebar } from 'primereact/sidebar';

function EditSlabWiseHybridChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [statusLovData, setStatusLovData] = useState([]);
    const [allLovs, setAllLovs] = useState([]);
    const [requestedDataById, setRequestedDataByID] = useState();
    const [transactions, setTransactions] = useState([]);

    const [oldNewData, setOldNewData] = useState([]);
    const [highlightedKeys, setHighlightedKeys] = useState([]);
    const [slabWiseHybridRowData, setSlabWiseHybridRowData] = useState();
    const [displayDialog, setDisplayDialog] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1); // Subtract 1 day

    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };

    const getAllLovsForCommission = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcommissionprofiledata');
        setAllLovs(resp?.payLoad);
        setTransactions(resp?.payLoad?.transactions);
    };

    useEffect(() => {
        getStatusLov();
        getAllLovsForCommission();
    }, []); // eslint-disable-line

    const getDatabyId = async () => {
        const resp = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getcommissionprofilebyid/${refTableId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik?.setFieldValue(key, keyData[key]);
                }
            });

            const multiselectDataProduct = keyData?.tblCommissionProducts?.map((item) => {
                return item?.tblProduct?.productId.toString(); // Convert to string
            });

            const commissionSlabRequestsData = keyData?.tblCommissionSlabs?.map((item) => {
                return {
                    commissionSlabId: item?.commissionSlabId,
                    startSlab: item?.startSlab,
                    endSlab: item?.endSlab,
                    commissionAmount: item?.commissionAmount,
                    commissionPercentage: item?.commissionPercentage,
                    commissionTypeCode: item?.lkpFeeType?.feeTypeCode
                }; // Convert to string
            });

            formik.setFieldValue('disbursementMethodName', keyData?.lkpDisbursementMethod?.disbursementMethodName?.toString());
            formik.setFieldValue('accountClassificationId', keyData?.lkpAccountClassification?.accountClassificationId?.toString());
            formik.setFieldValue('commissionGlAccountId', keyData?.tblAccount1?.accountId?.toString());
            formik.setFieldValue('whtGlAccountId', keyData?.tblAccount2?.accountId?.toString());
            formik.setFieldValue('commissionSlabRequests', commissionSlabRequestsData);
            formik.setFieldValue('startDate', keyData?.startDate?.slice(0, 10));
            formik.setFieldValue('endDate', keyData?.endDate?.slice(0, 10));

            if (multiselectDataProduct && multiselectDataProduct.length > 0) {
                formik.setFieldValue('products', multiselectDataProduct);
            }
        }
    };

    const getMcRequestDataById = async () => {
        setloading(true);

        const res = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getCommissionProfileCheckerById/${mcRequestId}`);
        const keyData = res?.payLoad;

        setloading(false);

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });

            const multiselectDataProduct = keyData?.products?.map((item) => {
                return item?.productId.toString(); // Convert to string
            });

            const commissionSlabRequestsData = keyData?.commissionSlabRequests?.map((item) => {
                return {
                    commissionSlabId: item?.commissionSlabId,
                    startSlab: item?.startSlab,
                    endSlab: item?.endSlab,
                    commissionAmount: item?.commissionAmount,
                    commissionPercentage: item?.commissionPercentage,
                    commissionTypeCode: item?.commissionTypeCode
                }; // Convert to string
            });

            formik.setFieldValue('disbursementMethodName', keyData?.disbursementMethodName?.toString());
            formik.setFieldValue('accountClassificationId', keyData?.accountClassificationId?.toString());
            formik.setFieldValue('commissionGlAccountId', keyData?.commissionGlAccountId?.toString());
            formik.setFieldValue('whtGlAccountId', keyData?.whtGlAccountId?.toString());
            formik.setFieldValue('commissionSlabRequests', commissionSlabRequestsData);
            formik.setFieldValue('startDate', keyData?.startDate?.slice(0, 10));
            formik.setFieldValue('endDate', keyData?.endDate?.slice(0, 10));

            if (multiselectDataProduct && multiselectDataProduct.length > 0) {
                formik.setFieldValue('products', multiselectDataProduct);
            }
        }
    };

    const getMcRequestDataByIdU = async () => {
        setloading(true);

        const res = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getCommissionProfileCheckerById/${mcRequestId}`);
        const keyData = res?.payLoad;

        setloading(false);

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
        }
        setRequestedDataByID(res?.payLoad);
    };

    const getRequestedSlabWiseHybridData = async () => {
        const resp = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getcommissionprofilebyid/${requestedDataById?.commissionProfileId}`);
        const keyData = resp?.payLoad;
        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik?.setFieldValue(key, keyData[key]);
                }
            });

            const multiselectDataProduct = keyData?.tblCommissionProducts?.map((item) => {
                return item?.tblProduct?.productId.toString(); // Convert to string
            });

            const commissionSlabRequestsData = keyData?.tblCommissionSlabs?.map((item) => {
                return {
                    commissionSlabId: item?.commissionSlabId,
                    startSlab: item?.startSlab,
                    endSlab: item?.endSlab,
                    commissionAmount: item?.commissionAmount,
                    commissionPercentage: item?.commissionPercentage,
                    commissionTypeCode: item?.lkpFeeType?.feeTypeCode
                }; // Convert to string
            });

            formik.setFieldValue('disbursementMethodName', keyData?.lkpDisbursementMethod?.disbursementMethodName?.toString());
            formik.setFieldValue('accountClassificationId', keyData?.lkpAccountClassification?.accountClassificationId?.toString());
            formik.setFieldValue('commissionGlAccountId', keyData?.tblAccount1?.accountId?.toString());
            formik.setFieldValue('whtGlAccountId', keyData?.tblAccount2?.accountId?.toString());
            formik.setFieldValue('commissionSlabRequests', commissionSlabRequestsData);
            formik.setFieldValue('startDate', keyData?.startDate?.slice(0, 10));
            formik.setFieldValue('endDate', keyData?.endDate?.slice(0, 10));

            if (multiselectDataProduct && multiselectDataProduct.length > 0) {
                formik.setFieldValue('products', multiselectDataProduct);
            }
        }
    };

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getMcRequestDataById();
        } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
            getMcRequestDataByIdU();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        if (requestTypeDescr === 'Enable/Disable Request' && requestType === 'U' && requestedDataById?.commissionProfileId !== undefined) {
            getRequestedSlabWiseHybridData();
        }
    }, [requestedDataById?.commissionProfileId]); // eslint-disable-line

    const accounttype = allLovs?.accounttype?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });
    const glAccounts = allLovs?.glAccounts?.map((data) => {
        return {
            accountId: data?.accountId,
            accountNo: `${data?.accountNo}-${data?.accountTitle}`
        };
    });
    const disbursementMethod = allLovs?.disbursementMethod?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });
    const WhtOption = [
        { name: 'INCLUSIVE', value: 'I' },
        { name: 'EXCLUSIVE', value: 'E' }
    ];

    const validationSchema = Yup.object().shape({
        action: Yup.mixed().required('This field is required.'),
        checkerComments: Yup.mixed().required('This field is required.')
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            accountClassificationId: '',
            commissionProfileName: '',
            feeTypeCode: 'SH',
            whtCalculationType: '',
            commissionGlAccountId: '',
            whtGlAccountId: '',
            disbursementMethodName: '',
            disbursementTime: '',
            startDate: '',
            endDate: '',
            commissionSlabRequests: [
                {
                    commissionSlabId: '',
                    startSlab: '',
                    endSlab: '',
                    commissionAmount: '',
                    commissionPercentage: '',
                    commissionTypeCode: ''
                }
            ],
            products: [],
            action: '',
            checkerComments: '',
            isActive: false
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            data['commissionProfileId'] = refTableId;
            data['feeTypeCode'] = 'SH';

            const productData = data?.products?.map((value) => value);
            formik.setFieldValue('products', productData);

            data['commissionSlabRequests'] = formik.values.commissionSlabRequests?.map((item) => ({
                commissionSlabId: item?.commissionSlabId,
                startSlab: item?.startSlab,
                endSlab: item?.endSlab,
                commissionAmount: item?.commissionAmount,
                commissionPercentage: item?.commissionPercentage,
                commissionTypeCode: item?.commissionTypeCode
            }));
            data['disbursementTime'] = '';

            data['isActive'] = requestedDataById?.isActive;
            if (requestTypeDescr !== 'Enable/Disable Request') {
                delete data['isActive'];
            }

            data['products'] = data.products?.map((productId) => ({
                productId: productId
            }));
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        commissionProfileRequest: {
                            accountClassificationId: data['accountClassificationId'],
                            commissionAmount: data['commissionAmount'],
                            commissionGlAccountId: data['commissionGlAccountId'],
                            commissionProfileId: data['commissionProfileId'],
                            commissionProfileName: data['commissionProfileName'],
                            disbursementMethodName: data['disbursementMethodName'],
                            disbursementTime: data['disbursementTime'],
                            endDate: data['endDate'],
                            feeTypeCode: data['feeTypeCode'],
                            products: data['products'],
                            startDate: data['startDate'],
                            whtCalculationType: data['whtCalculationType'],
                            whtGlAccountId: data['whtGlAccountId'],
                            isActive: data['isActive'],
                            commissionSlabRequests: data['commissionSlabRequests']
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/pricingprofile/v1/pricingprofile/commissionprofileCheckerAction', true, false, 'requestchecker'));
            if (res?.responseCode === '110000') {
                formik.resetForm();
            }
            setloading(false);
            setloadingIcon('pi pi-save');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const addSlabHybird = () => {
        formik.setFieldValue('commissionSlabRequests', [
            ...formik.values.commissionSlabRequests,
            {
                startSlab: '',
                endSlab: '',
                commissionAmount: '',
                commissionPercentage: '',
                commissionTypeCode: ''
            }
        ]);
    };
    const removeSlabHybird = (index) => {
        const updatedData = [...formik.values.commissionSlabRequests];
        updatedData.splice(index, 1);
        formik.setFieldValue('commissionSlabRequests', updatedData);
    };
    const SelectCommissionType = [
        { name: 'Percentage', value: 'PF' },
        { name: 'Fixed Fee', value: 'FF' }
    ];

    const getOldNewData = async () => {
        const resp = await handleGetRequest(`/workflow/v1/management/compareValues/${mcRequestId}`);
        setOldNewData(resp?.payLoad);
    };

    useEffect(() => {
        if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getOldNewData();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        const keys = oldNewData?.map((item) => item.key);
        setHighlightedKeys(keys);
    }, [oldNewData]); // eslint-disable-line

    const HandledOldValue = async (e) => {
        e.preventDefault();
        setDisplayDialog(true);
        const response = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getcommissionprofilebyid/${refTableId}`);
        setSlabWiseHybridRowData(response?.payLoad);
    };

    return (
        <>
            <Sidebar header="View Slab Wise Hybrid" position="right" style={{ width: '80vw' }} visible={displayDialog} onHide={() => setDisplayDialog(false)}>
                <SlabWiseHybridOldNewValues slabWiseHybridRowData={slabWiseHybridRowData} />
            </Sidebar>

            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>EDIT DETAILS</h1>
                    {requestType === 'U' && <Button label="View Old Details" className="Btn__Dark__OldValue" onClick={HandledOldValue} />}
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                    Account Type
                                </label>
                                <Dropdown
                                    id="accountClassificationId"
                                    placeholder="Select Account Type"
                                    options={accounttype}
                                    optionLabel="name"
                                    name="accountClassificationId"
                                    optionValue="lovId"
                                    value={formik.values.accountClassificationId || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('accountClassificationId')
                                            ? classNames({ 'p-invalid': isFormFieldValid('accountClassificationId') }, 'Dropdown__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('accountClassificationId') }, 'Dropdown__Round')
                                    }
                                    disabled={true}
                                />

                                {getFormErrorMessage('accountClassificationId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="commissionProfileName" className={classNames({ 'p-error': isFormFieldValid('commissionProfileName') }, 'Label__Text')}>
                                    Profile Name <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    id="commissionProfileName"
                                    placeholder="Enter Profile Name"
                                    name="commissionProfileName"
                                    value={formik?.values?.commissionProfileName || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('commissionProfileName')
                                            ? classNames({ 'p-invalid': isFormFieldValid('commissionProfileName') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('commissionProfileName') }, 'Input__Round')
                                    }
                                    disabled={true}
                                />
                                {getFormErrorMessage('commissionProfileName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="products" className={classNames({ 'p-error': isFormFieldValid('products') }, 'Label__Text')}>
                                    Transactions
                                </label>
                                <MultiSelect
                                    filter
                                    // disabled={true}
                                    id="products"
                                    placeholder="Select Transaction"
                                    options={transactions}
                                    optionLabel="name"
                                    name="products"
                                    optionValue="lovId"
                                    value={formik.values.products || []}
                                    className={
                                        highlightedKeys?.includes('products') ? classNames({ 'p-invalid': isFormFieldValid('products') }, 'Dropdown__Round__Highlighted') : classNames({ 'p-invalid': isFormFieldValid('products') }, 'Dropdown__Round')
                                    }
                                />
                                {getFormErrorMessage('products')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="whtCalculationType" className={classNames({ 'p-error': isFormFieldValid('whtCalculationType') }, 'Label__Text')}>
                                    Type of WHT Calculation
                                    <span className="Label__Required"></span>
                                </label>
                                <Dropdown
                                    id="whtCalculationType"
                                    placeholder="Select Type of WHT Calculation"
                                    options={WhtOption}
                                    optionLabel="name"
                                    name="whtCalculationType"
                                    optionValue="value"
                                    value={formik.values.whtCalculationType || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('whtCalculationType')
                                            ? classNames({ 'p-invalid': isFormFieldValid('whtCalculationType') }, 'Dropdown__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('whtCalculationType') }, 'Dropdown__Round')
                                    }
                                    disabled={true}
                                />

                                {getFormErrorMessage('whtCalculationType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor={'startDate'} className={classNames({ 'p-error': isFormFieldValid('startDate') }, 'Label__Text')}>
                                    Start Date
                                </label>
                                <InputText
                                    type="date"
                                    id="startDate"
                                    name="startDate"
                                    value={formik.values.startDate || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('startDate') ? classNames({ 'p-invalid': isFormFieldValid('startDate') }, 'Input__Round__Highlighted') : classNames({ 'p-invalid': isFormFieldValid('startDate') }, 'Input__Round')
                                    }
                                    disabled={true}
                                />
                                {getFormErrorMessage('startDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor={'endDate'} className={classNames({ 'p-error': isFormFieldValid('endDate') }, 'Label__Text')}>
                                    End Date
                                </label>
                                <InputText
                                    type="date"
                                    id="endDate"
                                    name="endDate"
                                    value={formik.values.endDate || ''}
                                    onChange={formik.handleChange}
                                    className={highlightedKeys?.includes('endDate') ? classNames({ 'p-invalid': isFormFieldValid('endDate') }, 'Input__Round__Highlighted') : classNames({ 'p-invalid': isFormFieldValid('endDate') }, 'Input__Round')}
                                    disabled={true}
                                />
                                {getFormErrorMessage('endDate')}
                            </div>
                        </div>
                        <>
                            <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card">
                                <div className="Form__Header">
                                    <h1>SLAB HYBRID FIXED & PERCENTAGE </h1>
                                </div>
                                {formik?.values?.commissionSlabRequests?.map((id, index) => (
                                    <div
                                        key={`id-${index}`}
                                        style={
                                            highlightedKeys?.includes('commissionSlabRequests')
                                                ? {
                                                      height: 'auto',
                                                      paddingTop: '5px',
                                                      paddingLeft: '5px',
                                                      border: '1px solid red',
                                                      borderTopLeftRadius: '5px',
                                                      borderTopRightRadius: '5px',
                                                      borderBottomLeftRadius: '5px',
                                                      borderBottomRightRadius: '5px'
                                                  }
                                                : { height: 'auto', backgroundColor: '#FFFFFF', padding: '3rem' }
                                        }
                                        className=" col-12 card"
                                    >
                                        <div className="grid"></div>
                                        <div className="grid">
                                            <div className="grid col-11">
                                                <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor={`startSlab${index}`} className={classNames({ 'p-error': isFormFieldValid(`[commissionSlabRequests${index}].startSlab`) }, 'Label__Text')}>
                                                            Start Slab <span className="Label__Required"></span>
                                                        </label>
                                                        <InputText
                                                            id={`startSlab${index}`}
                                                            placeholder="Enter Start Slab"
                                                            name={`commissionSlabRequests[${index}].startSlab`}
                                                            value={id.startSlab || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`commissionSlabRequests[${index}].startSlab`) }, 'Input__Round')}
                                                            disabled={true}
                                                        />
                                                        {getFormErrorMessage(`commissionSlabRequests[${index}].startSlab`)}
                                                    </div>
                                                </div>

                                                <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor={`endSlab${index}`} className={classNames({ 'p-error': isFormFieldValid(`[commissionSlabRequests${index}].endSlab`) }, 'Label__Text')}>
                                                            End Slab <span className="Label__Required"></span>
                                                        </label>
                                                        <InputText
                                                            id={`endSlab${index}`}
                                                            placeholder="Enter End Slab"
                                                            name={`commissionSlabRequests[${index}].endSlab`}
                                                            value={id.endSlab || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`commissionSlabRequests[${index}].endSlab`) }, 'Input__Round')}
                                                            disabled={true}
                                                        />
                                                        {getFormErrorMessage(`commissionSlabRequests[${index}].endSlab`)}
                                                    </div>
                                                </div>

                                                <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor={`commissionTypeCode${index}`} className={classNames({ 'p-error': isFormFieldValid(`[commissionSlabRequests${index}].commissionTypeCode`) }, 'Label__Text')}>
                                                            Slab Commission Type
                                                            <span className="Label__Required"></span>
                                                        </label>
                                                        <Dropdown
                                                            id={`commissionTypeCode${index}`}
                                                            placeholder="Select Commission Type"
                                                            options={SelectCommissionType}
                                                            optionLabel="name"
                                                            name={`commissionSlabRequests[${index}].commissionTypeCode`}
                                                            optionValue="value"
                                                            value={formik.values.commissionSlabRequests[index].commissionTypeCode || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`commissionSlabRequests[${index}].commissionTypeCode`) }, 'Dropdown__Round')}
                                                            disabled={true}
                                                        />
                                                        {getFormErrorMessage(`commissionSlabRequests[${index}].commissionTypeCode`)}
                                                    </div>
                                                </div>
                                                {formik.values.commissionSlabRequests[index].commissionTypeCode === 'PF' && (
                                                    <>
                                                        <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                            <div className="p-field">
                                                                <label htmlFor={`commissionPercentage${index}`} className={classNames({ 'p-error': isFormFieldValid(`[commissionSlabRequests${index}].commissionPercentage`) }, 'Label__Text')}>
                                                                    Enter Percentage <span className="Label__Required"></span>
                                                                </label>
                                                                <InputText
                                                                    id={`commissionPercentage${index}`}
                                                                    placeholder="Enter Percentage"
                                                                    name={`commissionSlabRequests[${index}].commissionPercentage`}
                                                                    value={id.commissionPercentage || ''}
                                                                    onChange={formik.handleChange}
                                                                    className={classNames({ 'p-invalid': isFormFieldValid(`commissionSlabRequests[${index}].commissionPercentage`) }, 'Input__Round')}
                                                                    disabled={true}
                                                                />
                                                                {getFormErrorMessage(`commissionSlabRequests[${index}].commissionPercentage`)}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {formik.values.commissionSlabRequests[index].commissionTypeCode === 'FF' && (
                                                    <>
                                                        <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                            <div className="p-field">
                                                                <label htmlFor={`commissionAmount${index}`} className={classNames({ 'p-error': isFormFieldValid(`[commissionSlabRequests${index}].commissionAmount`) }, 'Label__Text')}>
                                                                    Enter Fee <span className="Label__Required"></span>
                                                                </label>
                                                                <InputText
                                                                    id={`commissionAmount${index}`}
                                                                    placeholder="Enter Fee"
                                                                    name={`commissionSlabRequests[${index}].commissionAmount`}
                                                                    value={id.commissionAmount || ''}
                                                                    onChange={formik.handleChange}
                                                                    className={classNames({ 'p-invalid': isFormFieldValid(`commissionSlabRequests[${index}].commissionAmount`) }, 'Input__Round')}
                                                                    disabled={true}
                                                                />
                                                                {getFormErrorMessage(`commissionSlabRequests[${index}].commissionAmount`)}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-1">
                                                <div className="flex flex-column align-items-center justify-content-center">
                                                    <Button
                                                        disabled={true}
                                                        icon="pi pi-plus"
                                                        className="p-button-rounded"
                                                        aria-label="Filter"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            addSlabHybird();
                                                        }}
                                                    />
                                                    <Button
                                                        disabled={true}
                                                        icon="pi pi-minus"
                                                        className="p-button-rounded mt-2 p-button-danger"
                                                        aria-label="Filter"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            removeSlabHybird(index);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="flex flex-row">
                                    <div className="col-6 md:col-3">
                                        <div className="p-field pt-3 pb-3">
                                            <label htmlFor="commissionGlAccountId" className={classNames({ 'p-error': isFormFieldValid('commissionGlAccountId') }, 'Label__Text')}>
                                                Select Commission GL
                                                <span className="Label__Required"></span>
                                            </label>
                                            <Dropdown
                                                id="commissionGlAccountId"
                                                placeholder="Select Commission GL"
                                                options={glAccounts}
                                                optionLabel="accountNo"
                                                name="commissionGlAccountId"
                                                filter
                                                optionValue="accountId"
                                                value={formik.values.commissionGlAccountId || ''}
                                                onChange={formik.handleChange}
                                                className={
                                                    highlightedKeys?.includes('commissionGlAccountId')
                                                        ? classNames({ 'p-invalid': isFormFieldValid('commissionGlAccountId') }, 'Dropdown__Round__Highlighted')
                                                        : classNames({ 'p-invalid': isFormFieldValid('commissionGlAccountId') }, 'Dropdown__Round')
                                                }
                                                disabled={true}
                                            />
                                            {getFormErrorMessage('commissionGlAccountId')}
                                        </div>
                                    </div>
                                    <div className="col-6 md:col-3">
                                        <div className="p-field pt-3 pb-3">
                                            <label htmlFor="whtGlAccountId" className={classNames({ 'p-error': isFormFieldValid('whtGlAccountId') }, 'Label__Text')}>
                                                Select WHT GL
                                                <span className="Label__Required"></span>
                                            </label>
                                            <Dropdown
                                                id="whtGlAccountId"
                                                placeholder="Select WHT GL"
                                                options={glAccounts}
                                                optionLabel="accountNo"
                                                name="whtGlAccountId"
                                                optionValue="accountId"
                                                filter
                                                value={formik.values.whtGlAccountId || ''}
                                                onChange={formik.handleChange}
                                                className={
                                                    highlightedKeys?.includes('whtGlAccountId')
                                                        ? classNames({ 'p-invalid': isFormFieldValid('whtGlAccountId') }, 'Dropdown__Round__Highlighted')
                                                        : classNames({ 'p-invalid': isFormFieldValid('whtGlAccountId') }, 'Dropdown__Round')
                                                }
                                                disabled={true}
                                            />
                                            {getFormErrorMessage('whtGlAccountId')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="disbursementMethodName" className={classNames({ 'p-error': isFormFieldValid('disbursementMethodName') }, 'Label__Text')}>
                                    Select Disbursement Method
                                    <span className="Label__Required"></span>
                                </label>
                                <Dropdown
                                    id="disbursementMethodName"
                                    placeholder=" Select Disbursement Method"
                                    options={disbursementMethod}
                                    optionLabel="name"
                                    name="disbursementMethodName"
                                    optionValue="name"
                                    value={formik.values.disbursementMethodName || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('disbursementMethodName')
                                            ? classNames({ 'p-invalid': isFormFieldValid('disbursementMethodName') }, 'Dropdown__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('disbursementMethodName') }, 'Dropdown__Round')
                                    }
                                    disabled={true}
                                />
                                {getFormErrorMessage('disbursementMethodName')}
                            </div>
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                Is Active
                            </label>
                            <div className="field-checkbox">
                                <Checkbox inputId="isActive" disabled={true} name="isActive" value={requestedDataById?.isActive === 'Y' ? true : false} checked={requestedDataById?.isActive === 'Y' ? true : false} onChange={formik.handleChange} />
                            </div>
                            {getFormErrorMessage('isActive')}
                        </div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>
                        </div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    rows={5}
                                    cols={30}
                                    maxLength={200}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button disabled={loading} label="Update" className="Btn__Dark" />
                        <Button onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default EditSlabWiseHybridChecker;

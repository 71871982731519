import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';
import { InputTextarea } from 'primereact/inputtextarea';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function EditOtpChecker() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [osTypeData, setOsTypeData] = useState([]);
    const [useCaseData, setUseCaseData] = useState([]);
    const [otpTypeData, setOtpTypeData] = useState([]);
    const [segmentsData, setSegmentsData] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [deviceData, setDeviceData] = useState([]);

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const checkerComments = query.get('checkerComments');
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const getOtpTypeData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_OTP_TYPE');
        setOtpTypeData(resp?.payLoad);
    };
    const getOsTypeData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_OS_TYPE');
        setOsTypeData(resp?.payLoad);
    };
    const getSegmentsData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getsegments/LKP_SEGMENT:*');
        const updated = res?.payLoad?.map((item) => ({
            ...item,
            cmsOtpSegmentId: item.lovId
        }));
        setSegmentsData(updated);
    };
    const getCmsDeviceData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_DEVICES');
        setDeviceData(res?.payLoad);
    };
    const getUseCaseData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_USECASE');
        setUseCaseData(resp?.payLoad);
    };

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        getUseCaseData();
        getCmsDeviceData();
        getSegmentsData();
        getOtpTypeData();
        getOsTypeData();
        getStatusLov();
    }, []); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('This Field is required.'),
        checkerComments: Yup.string().required('This Field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cmsOtpConfigId: '',
            cmsUsecaseId: '',

            tblCmsOtpSegments: [
                {
                    cmsOtpSegmentId: '',
                    segmentId: ''
                }
            ],

            cmsOsTypeId: '',
            otpFetchAndroid: '',
            otpFetchIso: '',
            device: '',
            otpLength: '',
            cmsOtpTypeId: '',
            otpAttempts: '',
            otpTimer: '',
            timeHours: '',
            isTemporaryBlock: '',
            blockTime: '',
            isPermanentBlock: '',
            isActive: '',
            createdate: '',
            action: '',
            checkerComments: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const addPayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        objectBody: {
                            cmsOtpConfigId: formik.values.cmsOtpConfigId,
                            isActive: requestType === 'I' ? (formik?.values?.action == '2' ? 'Y' : 'N') : formik?.values?.isActive
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: ''
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const updatePayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        objectBody: {
                            cmsOtpConfigId: data?.cmsOtpConfigId,
                            cmsUsecaseId: data?.cmsUsecaseId,
                            cmsOsTypeId: data?.cmsOsTypeId,
                            otpFetchAndroid: data?.otpFetchAndroid,
                            otpFetchIso: data?.otpFetchIso,
                            device: data?.device,
                            otpLength: data?.otpLength,
                            cmsOtpTypeId: data?.cmsOtpTypeId,
                            otpAttempts: data?.otpAttempts,
                            otpTimer: data?.otpTimer,
                            timeHours: data?.timeHours,
                            isTemporaryBlock: data?.isTemporaryBlock,
                            isPermanentBlock: data?.isPermanentBlock,
                            blockTime: data?.blockTime,
                            tblCmsOtpSegments: formik?.values?.tblCmsOtpSegments?.map((item) => {
                                return {
                                    segmentId: parseInt(item?.lovId),
                                    cmsOtpSegmentId: item?.cmsOtpSegmentId
                                };
                            })
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: data?.checkerComments,
                            action: data?.action,
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const enableDisablePayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        objectBody: {
                            cmsOtpConfigId: data?.cmsOtpConfigId,
                            cmsUsecaseId: data?.cmsUsecaseId,
                            cmsOsTypeId: data?.cmsOsTypeId,
                            otpFetchAndroid: data?.otpFetchAndroid,
                            otpFetchIso: data?.otpFetchIso,
                            device: data?.device,
                            otpLength: data?.otpLength,
                            cmsOtpTypeId: data?.cmsOtpTypeId,
                            otpAttempts: data?.otpAttempts,
                            otpTimer: data?.otpTimer,
                            timeHours: data?.timeHours,
                            isTemporaryBlock: data?.isTemporaryBlock,
                            isPermanentBlock: data?.isPermanentBlock,
                            blockTime: data?.blockTime,
                            tblCmsOtpSegments: formik?.values?.tblCmsOtpSegments?.map((item) => {
                                return {
                                    segmentId: parseInt(item?.lovId),
                                    cmsOtpSegmentId: item?.cmsOtpSegmentId
                                };
                            }),
                            isActive: data?.isActive
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: data?.checkerComments,
                            action: data?.action,
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            if (requestType === 'I') {
                await dispatch(handlePostRequest(addPayload, '/cms/v1/cms/otpconfigvalidatorcheckeraction', true, false, 'requestchecker'));
            } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
                await dispatch(handlePostRequest(updatePayload, '/cms/v1/cms/otpconfigvalidatorcheckeraction', true, false, 'requestchecker'));
            } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
                await dispatch(handlePostRequest(enableDisablePayload, '/cms/v1/cms/otpconfigvalidatorcheckeraction', true, false, 'requestchecker'));
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const getOtpData = async () => {
        setloading(true);
        const res = await handleGetRequest(`/cms/v1/cms/getotpconfigbyid/${refTableId}`, false);
        const keyData = res?.payLoad;
        if (keyData) {
            formik.setFieldValue('cmsOtpConfigId', keyData?.cmsOtpConfigId?.toString());
            formik.setFieldValue('cmsUsecaseId', keyData?.cmsUsecaseId?.toString());
            formik.setFieldValue('otpAttempts', keyData?.otpAttempts?.toString());
            formik.setFieldValue('timeHours', keyData?.timeHours?.toString());
            formik.setFieldValue('otpLength', keyData?.otpLength?.toString());
            formik.setFieldValue('otpTimer', keyData?.otpTimer?.toString());
            formik.setFieldValue('device', keyData?.device?.toString());
            formik.setFieldValue('cmsOsTypeId', keyData?.cmsOsTypeId?.toString());
            formik.setFieldValue('cmsOtpTypeId', keyData?.cmsOtpTypeId?.toString());
            formik.setFieldValue('isActive', keyData?.isActive);
            formik.setFieldValue('otpFetchIso', keyData?.otpFetchIso);
            formik.setFieldValue('otpFetchAndroid', keyData?.otpFetchAndroid);
            formik.setFieldValue('isTemporaryBlock', keyData?.isTemporaryBlock);
            formik.setFieldValue('isPermanentBlock', keyData?.isPermanentBlock);
            formik.setFieldValue('blockTime', keyData?.blockTime);
            const segmentIds = keyData?.tblCmsOtpSegments?.map((item) => ({
                lovId: item?.segmentId?.toString()
            }));
            formik.setFieldValue('tblCmsOtpSegments', segmentIds || []);
        }
    };

    const getMcRequestDataById = async () => {
        setloading(true);
        const res = await handleGetRequest(`/cms/v1/cms/getmcrequestbyid/${mcRequestId}`);
        const unParsedkeyData = res?.payLoad?.updateJson;
        const keyData = JSON.parse(unParsedkeyData);
        if (keyData) {
            formik.setFieldValue('cmsOtpConfigId', keyData?.cmsOtpConfigId?.toString());
            formik.setFieldValue('cmsUsecaseId', keyData?.cmsUsecaseId?.toString());
            formik.setFieldValue('otpAttempts', keyData?.otpAttempts?.toString());
            formik.setFieldValue('timeHours', keyData?.timeHours?.toString());
            formik.setFieldValue('otpLength', keyData?.otpLength?.toString());
            formik.setFieldValue('otpTimer', keyData?.otpTimer?.toString());
            formik.setFieldValue('device', keyData?.device?.toString());
            formik.setFieldValue('cmsOtpTypeId', keyData?.cmsOtpTypeId?.toString());
            formik.setFieldValue('cmsOsTypeId', keyData?.cmsOsTypeId?.toString());
            const correctEndDate = (keyData?.tagExpiryDate && formattedDate(keyData?.tagExpiryDate)) || '';
            formik.setFieldValue('tagExpiryDate', correctEndDate);
            formik.setFieldValue('isActive', keyData?.isActive);
            formik.setFieldValue('otpFetchIso', keyData?.otpFetchIso);
            formik.setFieldValue('otpFetchAndroid', keyData?.otpFetchAndroid);
            formik.setFieldValue('isTemporaryBlock', keyData?.isTemporaryBlock);
            formik.setFieldValue('isPermanentBlock', keyData?.isPermanentBlock);
            formik.setFieldValue('blockTime', keyData?.blockTime);
            const segmentIds = keyData?.tblCmsOtpSegments?.map((item) => ({
                lovId: item?.segmentId?.toString()
            }));
            formik.setFieldValue('tblCmsOtpSegments', segmentIds || []);
        }

        setloading(false);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getOtpData();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, [requestType]); // eslint-disable-line

    function formattedDate(originalDate) {
        const createDate = new Date(originalDate);

        const year = createDate.getFullYear();
        const month = (createDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const day = createDate.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>EDIT DETAIL</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="cmsUsecaseId" className={classNames({ 'p-error': isFormFieldValid('cmsUsecaseId') }, 'Label__Text')}>
                                    Concerned Use Case
                                    <span className="Label__Required">*</span>
                                </label>

                                <Dropdown
                                    id="cmsUsecaseId"
                                    placeholder=""
                                    options={useCaseData}
                                    optionLabel="name"
                                    name="cmsUsecaseId"
                                    optionValue="lovId"
                                    value={formik.values.cmsUsecaseId || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('type') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsUsecaseId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field customMultiselect">
                                <label htmlFor="tblCmsOtpSegments" className={classNames({ 'p-error': isFormFieldValid('tblCmsOtpSegments') }, 'Label__Text')}>
                                    Segments<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="tblCmsOtpSegments"
                                    placeholder="Select Segments"
                                    options={segmentsData}
                                    optionLabel="name"
                                    display="chip"
                                    name="tblCmsOtpSegments"
                                    optionValue="lovId"
                                    value={formik?.values?.tblCmsOtpSegments?.map((segment) => segment.lovId || '')}
                                    className={classNames({ 'p-invalid': isFormFieldValid('tblCmsOtpSegments') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('tblCmsOtpSegments')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="cmsOsTypeId" className={classNames({ 'p-error': isFormFieldValid('cmsOsTypeId') }, 'Label__Text')}>
                                    OS Type
                                </label>
                                <Dropdown
                                    id="cmsOsTypeId"
                                    placeholder="Select Os Type"
                                    options={osTypeData}
                                    optionLabel="name"
                                    name="cmsOsTypeId"
                                    optionValue="lovId"
                                    value={formik.values.cmsOsTypeId || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsOsTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsOsTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <label htmlFor="otpFetch" className={classNames({ 'p-error': isFormFieldValid('otpFetch') }, 'Label__Text')}>
                                OTP Fetch
                            </label>
                            <div className="p-field flex justify-start">
                                <div className="p-field col-12 md:col-4  flex flex-row  justify-content-space-between">
                                    <div className="p-field">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="otpFetchAndroid"
                                                name="otpFetchAndroid"
                                                value={formik?.values?.otpFetchAndroid}
                                                checked={formik.values.otpFetchAndroid === 'Y'}
                                                onChange={(e) => {
                                                    const newValue = e.checked ? 'Y' : 'N';
                                                    formik.setFieldValue('otpFetchAndroid', newValue);
                                                    if (e.checked) {
                                                        formik.setFieldValue('otpFetchIso', 'N');
                                                    }
                                                }}
                                                style={{ marginTop: '10px' }}
                                                disabled
                                            />
                                            <label htmlFor="otpFetchAndroid" className={classNames({ 'p-error': isFormFieldValid('otpFetchAndroid') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                                Android
                                            </label>
                                        </div>

                                        {getFormErrorMessage('isPermanentBlock')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-2 ">
                                    <div className="p-field">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="otpFetchIso"
                                                name="otpFetchIso"
                                                value={formik?.values?.otpFetchIso}
                                                checked={formik.values.otpFetchIso === 'Y'}
                                                onChange={(e) => {
                                                    const newValue = e.checked ? 'Y' : 'N';
                                                    formik.setFieldValue('otpFetchIso', newValue);
                                                    if (e.checked) {
                                                        formik.setFieldValue('otpFetchAndroid', 'N');
                                                    }
                                                }}
                                                style={{ marginTop: '10px' }}
                                                disabled
                                            />
                                            <label htmlFor="otpFetchIso" className={classNames({ 'p-error': isFormFieldValid('otpFetchIso') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                                Ios
                                            </label>
                                        </div>
                                        {getFormErrorMessage('otpFetchIso')}
                                    </div>
                                </div>
                                {getFormErrorMessage('isIos')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('status') }, 'Label__Text')}>
                                    Device
                                </label>
                                <span className="Label__Required">*</span>
                                <Dropdown
                                    id="device"
                                    placeholder="Select Device"
                                    options={deviceData}
                                    optionLabel="name"
                                    optionValue="lovId"
                                    name="device"
                                    value={formik.values.device || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('status') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('status')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="otpLength" className={classNames({ 'p-error': isFormFieldValid('otpLength') }, 'Label__Text')}>
                                    OTP Length
                                </label>
                                <span className="Label__Required">*</span>
                                <InputText
                                    maxLength={100}
                                    id="otpLength"
                                    placeholder="Enter OTP Length"
                                    name="otpLength"
                                    value={formik?.values?.otpLength || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('otpLength') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('otpLength')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="cmsOtpTypeId" className={classNames({ 'p-error': isFormFieldValid('cmsOtpTypeId') }, 'Label__Text')}>
                                    OTP Type
                                </label>
                                <span className="Label__Required">*</span>
                                <Dropdown
                                    id="cmsOtpTypeId"
                                    placeholder="Select Otp Type"
                                    options={otpTypeData}
                                    optionLabel="name"
                                    name="cmsOtpTypeId"
                                    optionValue="lovId"
                                    value={formik.values.cmsOtpTypeId || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsOtpTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsOtpTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="attempts" className={classNames({ 'p-error': isFormFieldValid('attempts') }, 'Label__Text')}>
                                    OTP Attempts
                                </label>
                                <span className="Label__Required">*</span>
                                <InputText
                                    maxLength={100}
                                    id="otpAttempts"
                                    placeholder="Enter Attempts"
                                    name="otpAttempts"
                                    value={formik?.values?.otpAttempts || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('attempts') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('attempts')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="otpTimer" className={classNames({ 'p-error': isFormFieldValid('otpTimer') }, 'Label__Text')}>
                                    OTP Timer
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="otpTimer"
                                    placeholder="Enter Time In Minutes"
                                    name="otpTimer"
                                    value={formik?.values?.otpTimer || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('otpTimer') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('otpTimer')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <label htmlFor="timeHours" className={classNames({ 'p-error': isFormFieldValid('email') }, 'Label__Text')}>
                                Time
                                <span className="Label__Required">*</span>
                            </label>
                            <InputText
                                maxLength={100}
                                type="text"
                                id="timeHours"
                                placeholder="Enter Time In Minutes"
                                name="timeHours"
                                value={formik?.values?.timeHours}
                                onChange={formik.handleChange}
                                disabled
                                className={classNames({ 'p-invalid': isFormFieldValid('timeHours') }, 'Input__Round')}
                            />
                            {getFormErrorMessage('timeHours')}
                        </div>
                        <div className="Form__Header Full__Width">
                            <h2>BREACH RULE</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-5 pb-3">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox
                                        inputId="isTemporaryBlock"
                                        name="isTemporaryBlock"
                                        checked={formik.values.isTemporaryBlock === 'Y'}
                                        onChange={(e) => {
                                            const newValue = e.checked ? 'Y' : 'N';
                                            formik.setFieldValue('isTemporaryBlock', newValue);
                                            if (e.checked) {
                                                formik.setFieldValue('isPermanentBlock', 'N');
                                            }
                                        }}
                                        style={{ marginTop: '10px' }}
                                        disabled
                                    />

                                    <label htmlFor="isTemporaryBlock" className={classNames({ 'p-error': isFormFieldValid('isTemporaryBlock') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Temporary Block
                                    </label>
                                </div>
                                {getFormErrorMessage('isTemporaryBlock')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <label htmlFor="blockTime" className={classNames({ 'p-error': isFormFieldValid('blockTime') }, 'Label__Text')}>
                                Time
                                <span className="Label__Required">*</span>
                            </label>
                            <InputText
                                maxLength={100}
                                type="text"
                                id="blockTime"
                                placeholder="Enter Time In Minutes"
                                name="blockTime"
                                value={formik?.values?.blockTime}
                                onChange={formik.handleChange}
                                disabled
                                className={classNames({ 'p-invalid': isFormFieldValid('blockTime') }, 'Input__Round')}
                            />
                            {getFormErrorMessage('blockTime')}
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>
                        {/* <div className="p-field col-12 md:col-4 pt-3 pb-3"></div> */}

                        <div className="p-field col-12 md:col-2 pt-3 pb-3">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox
                                        inputId="isPermanentBlock"
                                        name="isPermanentBlock"
                                        checked={formik.values.isPermanentBlock === 'Y'}
                                        onChange={(e) => {
                                            const newValue = e.checked ? 'Y' : 'N';
                                            formik.setFieldValue('isPermanentBlock', newValue);
                                            if (e.checked) {
                                                formik.setFieldValue('isTemporaryBlock', 'N');
                                            }
                                        }}
                                        style={{ marginTop: '10px' }}
                                        disabled
                                    />
                                    <label htmlFor="isPermanentBlock" className={classNames({ 'p-error': isFormFieldValid('isPermanentBlock') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Permanent Block
                                    </label>
                                </div>
                                {getFormErrorMessage('isPermanentBlock')}
                            </div>
                        </div>
                    </div>

                    <div className="card Card__Bg__Change col-12 md:col-12 flex">
                        <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Select Action <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    value={formik?.values?.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                            <div className="desc_Text_Area">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    placeholder="-"
                                    inputid="checkerComments"
                                    name="checkerComments"
                                    rows={4}
                                    cols={30}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'Input__Round')}
                                    value={formik?.values?.checkerComments}
                                />
                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="submit" label="Update" className="Btn__Dark" />
                        <Button onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default EditOtpChecker;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../services/PostTemplate';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';

function AddTaxTypeDialog({ onHideTaxType, taxTypeRowData, editable, setGetTaxProfileData }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-check');

    const dispatch = useDispatch();
    
    useEffect(() => {
        if (editable) {
            const rowData = JSON?.parse(JSON?.stringify(taxTypeRowData));
            const keys = Object?.keys(rowData);
            keys.forEach((item) => {
                formik.setFieldValue(`${item}`, rowData[item]);
            });
        }
    }, [taxTypeRowData]);// eslint-disable-line
    useEffect(() => {
        if (!editable) {
            formik.resetForm();
        }
    }, [editable]);// eslint-disable-line

    const applicabledropDown = [
        {
            value: 'I',
            label: 'Apply on Fee Inclusive'
        },
        {
            value: 'E',
            label: 'Apply on Fee Exclusive'
        },
        {
            value: 'C',
            label: 'Apply on Commision'
        },
        {
            value: 'T',
            label: 'Apply on Transaction Amount'
        }
    ];

    const validationSchema = Yup.object().shape({
        applicableOn: Yup.string().required('This Field is required.'),
        taxTypeCode: Yup.string().required('This Field is required.').test('valid-input', 'Only alphanumeric values are allowed', value => {
        return value && /^[a-zA-Z0-9\s]*[a-zA-Z0-9]$/.test(value);
    }),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            taxTypeCode: '',
            taxTypeName: '',
            applicableOn: '',
            taxTypeDescr: ''
        },
        validate: (data) => {
            let errors = {}
            ////////////////////////taxTypeName/////////////////////
            if (!data?.taxTypeName) {
                errors.taxTypeName = "This Field is required";
            } else if (data?.taxTypeName === "") {
                errors.taxTypeName = "This Field should not be empty";
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.taxTypeName)) {
                errors.taxTypeName = "Leading and trailing space is not allowed";
            } else if (!/^[a-zA-Z](?:[a-zA-Z0-9\s]*-?[a-zA-Z0-9\s]*)?$/.test(data?.taxTypeName)) {
                errors.taxTypeName = "Invalid Tax Type Name format";
            }
            return errors
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            if (editable) {
                data['taxTypeId'] = taxTypeRowData?.taxTypeId;
                delete data['createdate'];
                delete data['createuser'];
                delete data['isActive'];
                delete data['lastupdatedate'];
                delete data['lastupdateuser'];
                delete data['updateindex'];
                delete data['filterType'];
                delete data['lkpStatus'];
                delete data['createdBy']
                delete data['updatedBy']

                const res = await dispatch(handlePostRequest(newData, '/tax/v1/updateTaxType', true, false, 'taxprofilemaintenance'));
                if (res?.responseCode === '040000') {
                    formik.resetForm();
                    onHideTaxType();
                    setGetTaxProfileData('');
                }
            } else {
                const res = await dispatch(handlePostRequest(newData, '/tax/v1/createnewtaxtype', true, false, 'taxprofilemaintenance'));
                if (res?.responseCode === '040000') {
                    formik.resetForm();
                    onHideTaxType();
                    setGetTaxProfileData('');
                }
            }

            setloading(false);
            setloadingIcon('pi pi-save');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleCancel = (e) => {
        e.preventDefault();
        onHideTaxType();
        formik.resetForm();
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header"></div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="taxTypeCode" className={classNames({ 'p-error': isFormFieldValid('taxTypeCode') }, 'Label__Text')}>
                                    Tax Code<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={20}
                                    id="taxTypeCode"
                                    placeholder="Enter Tax Code"
                                    name="taxTypeCode"
                                    value={formik?.values?.taxTypeCode?.toString() || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('taxTypeCode') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('taxTypeCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="taxTypeName" className={classNames({ 'p-error': isFormFieldValid('taxTypeName') }, 'Label__Text')}>
                                    Tax Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={20}
                                    id="taxTypeName"
                                    placeholder="Enter Tax Name"
                                    name="taxTypeName"
                                    value={formik?.values?.taxTypeName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('taxTypeName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('taxTypeName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="applicableOn" className={classNames({ 'p-error': isFormFieldValid('applicableOn') }, 'Label__Text')}>
                                    Applicability<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="applicableOn"
                                    placeholder="Select Applicability"
                                    options={applicabledropDown}
                                    optionLabel="label"
                                    name="applicableOn"
                                    optionValue="value"
                                    value={formik.values.applicableOn || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('applicableOn') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('applicableOn')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="taxTypeDescr" className={classNames({ 'p-error': isFormFieldValid('taxTypeDescr') }, 'Label__Text')}>
                                    Description
                                </label>
                                <InputTextarea
                                    maxLength={500}
                                    autoResize="false"
                                    id="taxTypeDescr"
                                    placeholder="Enter Description"
                                    name="taxTypeDescr"
                                    value={formik?.values?.taxTypeDescr?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('taxTypeDescr') }, 'Input__Round')}
                                    rows={3}
                                    cols={30}
                                />
                                {getFormErrorMessage('taxTypeDescr')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" disabled={loading} loadingIcon={loadingIcon} label={editable ? 'Update' : 'Submit'} className="Btn__Add" />
                        <Button label="Cancel" className="Btn__cancel" onClick={(e) => handleCancel(e)} />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddTaxTypeDialog;

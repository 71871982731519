import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Steps } from 'primereact/steps';
import { useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../services/GetTemplate';
import ViewCreateCampaign from './Components/CreateCampaign/viewCampaign';
import ViewTargetBaseSelection from './Components/TargetBaseSelection/ViewTargetBase';
import ViewQualifyingCriteria from './Components/QualifyingCriteria/ViewQualifyingCriteria';
import ViewRewardConfiguration from './Components/RewardConfigurations/ViewRewardConfigurations';
import ViewCustomerCommunication from './Components/CustomerCommunication/ViewCustomerCommunication';
import AuthorizationHistoryTable from '../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewCampaignManagement() {
    const [activeIndex, setActiveIndex] = useState(1);
    const [campaignData, setCampaignData] = useState([]);

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const campaignId = query.get('campaignId');
    const tableName = 'TBL_CAMPAIGN';

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getCampaignById = async () => {
        const response = await handleGetRequest(`/customerloyalitypromotions/v1/customerloyalitypromotion/getcampaignbyid/${campaignId}`);
        setCampaignData(response?.payLoad);
        setActiveIndex(parseInt(response?.payLoad?.currentStep));
    };

    useEffect(() => {
        getCampaignById();
    }, []); // eslint-disable-line

    const formik = useFormik({
        initialValues: {}
    });

    const items = [
        {
            label: 'Add Campaign',
            command: (e) => handleStepClick(e.index)
            // disabled: parseInt(campaignData?.currentStep) >= 0 ? false : true
        },
        {
            label: 'Target Base Selection',
            command: (e) => handleStepClick(e.index)
            // disabled: parseInt(campaignData?.currentStep) >= 1 ? false : true
        },
        {
            label: 'Qualifying criteria',
            command: (e) => handleStepClick(e.index)
            // disabled: parseInt(campaignData?.currentStep) >= 2 ? false : true
        },
        {
            label: 'Reward Configurations',
            command: (e) => handleStepClick(e.index)
            // disabled: parseInt(campaignData?.currentStep) >= 3 ? false : true
        },
        {
            label: 'Customer Communication',
            command: (e) => handleStepClick(e.index)
            // disabled: parseInt(campaignData?.currentStep) >= 4 ? false : true
        }
    ];

    const handleStepClick = (index) => {
        setActiveIndex(index);
    };

    return (
        <>
            <div className="card Card__Round">
                <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} style={{ margin: '50px 0px', padding: '50px 0px' }} className="Campaign__Steps" />
                {/* <div className="Form__Header">
                    <h1>VIEW CAMPAIGN</h1>
                </div> */}
                {activeIndex === 0 && <ViewCreateCampaign campaignData={campaignData} />}
                {activeIndex === 1 && <ViewTargetBaseSelection campaignData={campaignData} />}
                {activeIndex === 2 && <ViewQualifyingCriteria campaignData={campaignData} />}
                {activeIndex === 3 && <ViewRewardConfiguration campaignData={campaignData} />}
                {activeIndex === 4 && <ViewCustomerCommunication campaignData={campaignData} />}

                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {activeIndex >= 4 && (
                        <div className="Down__Btn">
                            <Button label="Okay" className="Btn__Dark" onClick={() => navigate('/campaignmanagement')} />
                        </div>
                    )}
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={campaignId} tableName={tableName} />
        </>
    );
}
export default ViewCampaignManagement;

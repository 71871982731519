import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Steps } from 'primereact/steps';
import EditProduct from './Components/Product/EditProduct';
import EditPricing from './Components/Pricing/EditPricing';
import EditRepaymentMethod from './Components/RepaymentMethod/EditRepaymentMethod';
import EditDocument from './Components/Document/EditDocument';
import EditTermsAndConditions from './Components/TermsandConditions/EditTermsAndConditions';
import { useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import { loadingAction } from '../../../redux/actions/loadingAction';

function EditLendingManagement() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [activeIndex, setActiveIndex] = useState(0);
    const [allStepsComplete, setAllStepsComplete] = useState(false);
    const [lendingIdData, setLendingIdData] = useState();
    const [lendingData, setLendingData] = useState();

    const [stepData1, setStepData1] = useState();
    const [stepData2, setStepData2] = useState();
    const [stepData3, setStepData3] = useState();
    const [stepData4, setStepData4] = useState();
    const [stepData5, setStepData5] = useState();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const lmsProductId = query.get('lmsProductId');

    const getLmsProductById = async () => {
        dispatch(loadingAction(true));
        const response = await handleGetRequest(`/lending/v1/lending/getlmsproductbyid/${lmsProductId}`);
        setLendingData(response?.payLoad);
        setLendingIdData(lmsProductId);
        dispatch(loadingAction(false));
    };

    useEffect(() => {
        getLmsProductById();
    }, []); // eslint-disable-line

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            let step5Data;

            step5Data = {
                lmsProductId: stepData5?.lmsProductId,
                currentStep: stepData5?.currentStep,
                language: stepData5?.language,
                textType: stepData5?.textType,
                tncText: stepData5?.tncText
            };

            const arrayStep = [stepData1, stepData2, stepData3, stepData4, step5Data]?.filter((item) => {
                return item !== undefined;
            });

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: arrayStep,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const formData = new FormData();

            if (stepData5?.textType === 'F') {
                if (stepData5?.file) {
                    formData.append('file', stepData5?.file);
                }
            }

            const blob = new Blob([JSON.stringify(newData)], { type: 'application/json' });
            formData.append('request', blob);
            // formData.append('request', JSON.stringify(newData));

            const res = await dispatch(handlePostRequest(formData, '/lending/v1/lending/updateproduct', true, false, ''));
            if (res?.responseCode === process.env.REACT_APP_LENDING_SUCCESS_CODE) {
                formik.resetForm();
                navigate('/lendingmanagement');
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    // const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    // const getFormErrorMessage = (name) => {
    //     return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    // };

    const items = [
        {
            label: 'Product',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(activeIndex) >= 0 ? false : true
        },
        {
            label: 'Pricing',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(activeIndex) >= 1 ? false : true
        },
        {
            label: 'Repayment Methods',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(activeIndex) >= 2 ? false : true
        },
        {
            label: 'Document',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(activeIndex) >= 3 ? false : true
        },
        {
            label: 'Terms & Condition',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(activeIndex) >= 4 ? false : true
        }
    ];

    const handleStepClick = (index) => {
        setActiveIndex(index);
    };

    return (
        <>
            <div className="card Card__Round">
                <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} style={{ margin: '50px 0px', padding: '50px 0px' }} className="CustomerRisk__Steps" />

                {activeIndex === 0 && <EditProduct activeIndex={activeIndex} handleStepClick={handleStepClick} lendingData={lendingData} setStepData1={setStepData1} lendingIdData={lendingIdData} />}
                {activeIndex === 1 && <EditPricing activeIndex={activeIndex} handleStepClick={handleStepClick} lendingData={lendingData} setStepData2={setStepData2} lendingIdData={lendingIdData} />}
                {activeIndex === 2 && <EditRepaymentMethod activeIndex={activeIndex} handleStepClick={handleStepClick} lendingData={lendingData} setStepData3={setStepData3} lendingIdData={lendingIdData} stepData1={stepData1} />}
                {activeIndex === 3 && <EditDocument activeIndex={activeIndex} handleStepClick={handleStepClick} lendingData={lendingData} setStepData4={setStepData4} lendingIdData={lendingIdData} />}
                {activeIndex === 4 && (
                    <EditTermsAndConditions activeIndex={activeIndex} handleStepClick={handleStepClick} lendingData={lendingData} setStepData5={setStepData5} lendingIdData={lendingIdData} setAllStepsComplete={setAllStepsComplete} />
                )}

                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {activeIndex === 5 && allStepsComplete && (
                        <div className="Down__Btn">
                            <Button disabled={loading} loadingIcon={loadingIcon} label="Update" className="Btn__Dark" />
                            <Button disabled={loading} onClick={() => navigate('/lendingmanagement')} label="Cancel" className="Btn__Transparent" />
                        </div>
                    )}
                </form>
            </div>
        </>
    );
}
export default EditLendingManagement;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { InputTextarea } from 'primereact/inputtextarea';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewAgentMateBankChecker() {
    const [bankImage, setBankImage] = useState(null);
    const navigate = useNavigate();
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const tableName = query.get('tableName');

    const formik = useFormik({
        initialValues: {
            cmsBankId: '',
            bankName: '',
            bankIcon: '',
            bankCode: '',
            priority: '',
            displayNameEnglish: '',
            displayNameUrdu: '',
            bankImd: '',
            status: '',
            addTag: false,
            cmsTagLabelId: '',
            cmsTagTextColorId: '',
            cmsTagBgColorId: '',
            expiryDate: '',
            description: '',
            isActive: '',
            isHide: ''
        }
    });

    const getDataById = async () => {
        const res = await handleGetRequest(`/agentmatecms/v1/agentmatecms/getbankbyid/${refTableId}`);
        const keydata = res?.payLoad;
        formik.setFieldValue('cmsBankId', keydata?.cmsBankId || '');
        formik.setFieldValue('bankName', keydata?.bankName || '');
        formik.setFieldValue('bankCode', keydata?.bankCode || '');
        formik.setFieldValue('bankIcon', keydata?.bankIcon || '');
        formik.setFieldValue('bankCode', keydata?.bankCode || '');
        formik.setFieldValue('priority', keydata?.priority || '');
        formik.setFieldValue('displayNameEnglish', keydata?.displayNameEnglish || '');
        formik.setFieldValue('displayNameUrdu', keydata?.displayNameUrdu || '');
        formik.setFieldValue('bankImd', keydata?.bankImd || '');
        formik.setFieldValue('addTag', keydata?.cmsTagLabelId || keydata?.cmsTagTextColorId || keydata?.cmsTagBgColorId ? true : false);
        formik.setFieldValue('cmsTagLabelId', keydata?.cmsTagLabelId || '');
        formik.setFieldValue('cmsTagTextColorId', keydata?.cmsTagTextColorId || '');
        formik.setFieldValue('cmsTagBgColorId', keydata?.cmsTagBgColorId || '');
        const dateFormated = formatedDate(keydata?.expiryDate || '');
        formik.setFieldValue('expiryDate', dateFormated || '');
        formik.setFieldValue('isHide', keydata?.isHide || '');
        formik.setFieldValue('description', keydata?.description || '');

        const bankIconPath = (keydata?.bankIcon && keydata?.bankIcon?.split('/')) || '';
        const bank = (bankIconPath && bankIconPath[bankIconPath?.length - 1]) || '';
        setBankImage(bank);
    };
    const getDataByMCId = async () => {
        const res = await handleGetRequest(`/agentmatecms/v1/agentmatecms/getbankmcrequestbyid/${mcRequestId}`);
        const keydata = res?.payLoad;
        formik.setFieldValue('cmsBankId', keydata?.cmsBankId || '');
        formik.setFieldValue('bankName', keydata?.bankName || '');
        formik.setFieldValue('bankCode', keydata?.bankCode || '');
        formik.setFieldValue('bankIcon', keydata?.bankIcon || '');
        formik.setFieldValue('bankCode', keydata?.bankCode || '');
        formik.setFieldValue('priority', keydata?.priority || '');
        formik.setFieldValue('displayNameEnglish', keydata?.displayNameEnglish || '');
        formik.setFieldValue('displayNameUrdu', keydata?.displayNameUrdu || '');
        formik.setFieldValue('bankImd', keydata?.bankImd || '');
        formik.setFieldValue('addTag', keydata?.cmsTagLabelId || keydata?.cmsTagTextColorId || keydata?.cmsTagBgColorId ? true : false);
        formik.setFieldValue('cmsTagLabelId', keydata?.cmsTagLabelId || '');
        formik.setFieldValue('cmsTagTextColorId', keydata?.cmsTagTextColorId || '');
        formik.setFieldValue('cmsTagBgColorId', keydata?.cmsTagBgColorId || '');
        const dateFormated = formatedDate(keydata?.expiryDate || '');
        formik.setFieldValue('expiryDate', dateFormated || '');
        formik.setFieldValue('description', keydata?.description || '');
        formik.setFieldValue('isHide', keydata?.isHide || '');
        formik.setFieldValue('isActive', keydata?.isActive || '');

        const bankIconPath = (keydata?.bankIcon && keydata?.bankIcon?.split('/')) || '';
        const bank = (bankIconPath && bankIconPath[bankIconPath?.length - 1]) || '';
        setBankImage(bank);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getDataById();
        } else if (requestType === 'U') {
            getDataByMCId();
        }
    }, [requestType]); // eslint-disable-line

    function formatedDate(originalDate) {
        const createDate = new Date(originalDate);

        const year = createDate.getFullYear();
        const month = (createDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const day = createDate.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const getContrastColor = (hexColor) => {
        const r = parseInt(hexColor.substr(1, 2), 16);
        const g = parseInt(hexColor.substr(3, 2), 16);
        const b = parseInt(hexColor.substr(5, 2), 16);
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    };
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="Form__Header Full__Width">
                        <h2>VIEW DETAIL</h2>
                    </div>
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-fluid p-formgrid grid mb-5 col-12 md:col-12 ">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                <div className="p-field">
                                    <label htmlFor="bankName" className={classNames({ 'p-error': isFormFieldValid('bankName') }, 'Label__Text')}>
                                        Bank Name <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="bankName"
                                        placeholder="Enter name"
                                        name="bankName"
                                        value={formik?.values?.bankName || ''}
                                        onChange={formik.handleChange}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('bankName') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('bankName')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <div className="mb-2">
                                        <label className="Label__Text">
                                            Upload Image<span className="Label__Required">*</span>
                                        </label>
                                        <div className="file-input-container w-100">
                                            <input type="file" style={{ display: 'none' }} disabled accept="image/*" />
                                            <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={bankImage} readOnly />
                                            <span className="file-upload-icon">
                                                <i className="pi pi-paperclip" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                <div className="p-field">
                                    <label htmlFor="bankCode" className={classNames({ 'p-error': isFormFieldValid('bankCode') }, 'Label__Text')}>
                                        Bank Code <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="bankCode"
                                        placeholder="Enter Bank Code"
                                        name="bankCode"
                                        value={formik?.values?.bankCode || ''}
                                        onChange={formik.handleChange}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('bankCode') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('bankCode')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                <div className="p-field">
                                    <label htmlFor="priority" className={classNames({ 'p-error': isFormFieldValid('priority') }, 'Label__Text')}>
                                        Priority <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="priority"
                                        placeholder="Enter Priority"
                                        name="priority"
                                        value={formik?.values?.priority || ''}
                                        onChange={formik.handleChange}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('priority') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('priority')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                <div className="p-field">
                                    <label htmlFor="displayNameEnglish" className={classNames({ 'p-error': isFormFieldValid('displayNameEnglish') }, 'Label__Text')}>
                                        Display Name (English) <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="displayNameEnglish"
                                        placeholder="Enter Display Name (English)"
                                        name="displayNameEnglish"
                                        value={formik?.values?.displayNameEnglish}
                                        onChange={formik.handleChange}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('displayNameEnglish') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('displayNameEnglish')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                <div className="p-field">
                                    <label htmlFor="displayNameUrdu" className={classNames({ 'p-error': isFormFieldValid('displayNameUrdu') }, 'Label__Text')}>
                                        Display Name (Urdu)<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="displayNameUrdu"
                                        placeholder="Enter Display Name (Urdu) "
                                        name="displayNameUrdu"
                                        value={formik?.values?.displayNameUrdu}
                                        onChange={formik.handleChange}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('displayNameUrdu') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('displayNameUrdu')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                <div className="p-field">
                                    <label htmlFor="bankImd" className={classNames({ 'p-error': isFormFieldValid('bankImd') }, 'Label__Text')}>
                                        Bank IMD <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="bankImd"
                                        placeholder="Enter Bank IMD "
                                        name="bankImd"
                                        value={formik?.values?.bankImd}
                                        onChange={formik.handleChange}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('bankImd') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('bankImd')}
                                </div>
                            </div>

                            <div className="card Card__Bg__Change col-12 md:col-12">
                                <h6 className="secondary-color">
                                    <b>TAG</b>
                                </h6>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3 flex align-items-end justify-content-start">
                                    <div className="field-checkbox flex mb-2  ">
                                        <div className="mr-5">
                                            <Checkbox disabled inputId="addTag" name="addTag" checked={formik?.values?.addTag} onChange={formik.handleChange} style={{ marginTop: '10px' }} />
                                            <label htmlFor="addTag" className={classNames({ 'p-error': isFormFieldValid('enablePartialPayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                                Add Tag
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-fluid p-formgrid grid mb-5">
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                        <div className="p-field">
                                            <label htmlFor="cmsTagLabelId" className={classNames({ 'p-error': isFormFieldValid('cmsTagLabelId') }, 'Label__Text')}>
                                                Tag Label
                                            </label>
                                            <InputText
                                                id="cmsTagLabelId"
                                                placeholder="Enter Tag Label"
                                                name="cmsTagLabelId"
                                                disabled
                                                value={formik?.values?.cmsTagLabelId || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagLabelId') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('cmsTagLabelId')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                        <div className="p-field">
                                            <label htmlFor="cmsTagTextColorId" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextColorId') }, 'Label__Text')}>
                                                Tag Text Color
                                            </label>
                                            <InputText
                                                value={formik?.values?.cmsTagTextColorId || '#FFFFFF'}
                                                onChange={(e) => {
                                                    formik.setFieldValue('cmsTagTextColorId', e.target.value);
                                                }}
                                                disabled
                                                readOnly
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextColorId') }, 'Input__Round')}
                                                style={{
                                                    backgroundColor: formik?.values?.cmsTagTextColorId || '#FFFFFF',
                                                    color: getContrastColor(formik?.values?.cmsTagTextColorId || '#FFFFFF')
                                                }}
                                            />

                                            {getFormErrorMessage('cmsTagTextColorId')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                        <div className="p-field">
                                            <label htmlFor="cmsTagBgColorId" className={classNames({ 'p-error': isFormFieldValid('cmsTagBgColorId') }, 'Label__Text')}>
                                                Tag Background Color
                                            </label>
                                            <InputText
                                                value={formik?.values?.cmsTagBgColorId || '#FFFFFF'}
                                                onChange={(e) => {
                                                    formik.setFieldValue('cmsTagBgColorId', e.target.value);
                                                }}
                                                disabled
                                                readOnly
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagBgColorId') }, 'Input__Round')}
                                                style={{
                                                    backgroundColor: formik?.values?.cmsTagBgColorId || '#FFFFFF',
                                                    color: getContrastColor(formik?.values?.cmsTagBgColorId || '#FFFFFF')
                                                }}
                                            />

                                            {getFormErrorMessage('cmsTagBgColorId')}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                <div className="p-field">
                                    <label htmlFor="expiryDate" className={classNames({ 'p-error': isFormFieldValid('expiryDate') }, 'Label__Text')}>
                                        Expiry Date
                                    </label>
                                    <InputText
                                        id="expiryDate"
                                        type="date"
                                        name="expiryDate"
                                        value={formik?.values?.expiryDate}
                                        onChange={formik.handleChange}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('expiryDate') }, 'Input__Round')}
                                    />

                                    {getFormErrorMessage('expiryDate')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="isHide" className={classNames({ 'p-error': isFormFieldValid('isHide') }, 'Label__Text')}>
                                        Status
                                    </label>
                                    <div className="flex ml-2">
                                        <div className="mt-1 mr-5">
                                            <Checkbox inputId="show" name="isHide" value="Y" checked={formik?.values?.isHide === 'Y'} onChange={(e) => formik.setFieldValue('isHide', 'Y')} disabled style={{ marginTop: '10px' }} />
                                            <label htmlFor="show" className={classNames({ 'p-error': isFormFieldValid('show') }, 'Label__Text')}>
                                                Show
                                            </label>
                                        </div>
                                        <div className="mt-1">
                                            <Checkbox inputId="hide" name="isHide" value="N" checked={formik?.values?.isHide === 'N'} onChange={(e) => formik.setFieldValue('isHide', 'N')} disabled style={{ marginTop: '10px' }} />
                                            <label htmlFor="hide" className={classNames({ 'p-error': isFormFieldValid('hide') }, 'Label__Text')}>
                                                Hide
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-12 w-full pt-3 pb-3 ">
                                <div className="desc_Text_Area">
                                    <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') }, 'Label__Text')}>
                                        Description
                                    </label>
                                    <InputTextarea
                                        placeholder="-"
                                        inputid="description"
                                        name="description"
                                        rows={4}
                                        cols={30}
                                        onChange={formik.handleChange}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('description') }, 'Input__Round')}
                                        value={formik?.values?.description}
                                    />
                                    {getFormErrorMessage('description')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="button" onClick={() => navigate('/requestchecker')} label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default ViewAgentMateBankChecker;

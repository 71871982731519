import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useNavigate } from 'react-router-dom';

const AddCreateSalesForce = () => {
    const [loading, setloading] = useState(false);
    const [salesStructureData, setSalesStructureData] = useState('');
    const [employeeRoleList, setEmployeeRoleList] = useState([]);
    const dataTableRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object({
        salesStructureId: Yup.string().required('This Field is required'),
        salesRoleId: Yup.string().required('This Field is required'),
        salesRoleDetailId: Yup.string().required('This Field is required'),
        employeeId: Yup.string()
            .matches(/^\d{4}$/, 'It must be exactly 4 digits')
            .required('This Field is required'),
        employeeName: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
            .required('This Field is required'),
        essDepartment: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[A-Za-z0-9 ]*$/, 'Special characters are not allowed')
            .matches(/^[a-zA-Z](?:[a-zA-Z0-9\s]*-?[a-zA-Z0-9\s]*)?$/, 'Invalid department name')
            .required('This Field is required'),
        employeeCnic: Yup.string()
            .matches(/^\d{13}$/, 'It must be exactly 13 digits')
            .required('This Field is required'),
        employeeMobileNo: Yup.string()
            .matches(/^\d{11}$/, 'It must be exactly 11 digits')
            .required('This Field is required'),
        employeeEmail: Yup.string().required('This Field is required').email('Invalid email format')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            salesStructureId: '',
            salesRoleDetailId: '',
            salesRoleId: '',
            employeeCnic: '',
            employeeId: '',
            employeeMobileNo: '',
            employeeEmail: '',
            employeeName: '',
            essDepartment: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            await dispatch(handlePostRequest(newData, '/account/v1/sales/createsaleforce', true, false, 'createsalesforce'));
            setloading(false);
            dataTableRef.current.reset();
        }
    });

    const getSaleStructureData = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getSaleStructureList/LKP_SALES_STRUCTURE:*`);
        setSalesStructureData(res?.payLoad);
    };

    const getEmployeeRoleList = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getEmployeeRoleList/${formik.values?.salesStructureId}`);
        const filteredList = resp?.payLoad.filter((item) => item.isActive === 'Y');
        setEmployeeRoleList(filteredList);
    };

    useEffect(() => {
        if (formik?.values?.salesStructureId) {
            getEmployeeRoleList();
        }
    }, [formik?.values?.salesStructureId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (formik?.values?.salesRoleDetailId) {
            const getSaleRoleID = employeeRoleList.filter((item) => item.lovId === formik?.values?.salesRoleDetailId);
            formik.setFieldValue('salesRoleId', getSaleRoleID[0]?.saleRoleId);
        }
    }, [formik?.values?.salesRoleDetailId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getSaleStructureData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>ADD NEW</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="salesStructureId" className={classNames({ 'p-error': isFormFieldValid('salesStructureId') }, 'Label__Text')}>
                                    Sale Structure <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="salesStructureId"
                                    placeholder="Select Sale Structure"
                                    options={salesStructureData}
                                    optionLabel="name"
                                    name="salesStructureId"
                                    optionValue="lovId"
                                    value={formik.values.salesStructureId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('salesStructureId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('salesStructureId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="salesRoleDetailId" className={classNames({ 'p-error': isFormFieldValid('salesRoleDetailId') }, 'Label__Text')}>
                                    Sale Role <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    filter
                                    // showClear
                                    filterBy="name"
                                    id="salesRoleDetailId"
                                    placeholder="Select Sale Role"
                                    options={employeeRoleList}
                                    optionLabel="name"
                                    name="salesRoleDetailId"
                                    optionValue="lovId"
                                    value={formik.values.salesRoleDetailId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('salesRoleDetailId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('salesRoleDetailId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="employeeName" className={classNames({ 'p-error': isFormFieldValid('employeeName') }, 'Label__Text')}>
                                    Employee Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={50}
                                    minLength={3}
                                    placeholder={'Enter Employee Name'}
                                    id="employeeName"
                                    name="employeeName"
                                    value={formik?.values?.employeeName}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('employeeName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('employeeName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="employeeId" className={classNames({ 'p-error': isFormFieldValid('employeeId') }, 'Label__Text')}>
                                    Employee Id <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={4}
                                    placeholder="Enter Employee Id"
                                    id="employeeId"
                                    name="employeeId"
                                    value={formik?.values?.employeeId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('employeeId') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('employeeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="essDepartment" className={classNames({ 'p-error': isFormFieldValid('essDepartment') }, 'Label__Text')}>
                                    Ess Department <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={50}
                                    minLength={3}
                                    placeholder={'Enter Ess Department'}
                                    id="essDepartment"
                                    name="essDepartment"
                                    value={formik?.values?.essDepartment}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('essDepartment') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('essDepartment')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="employeeCnic" className={classNames({ 'p-error': isFormFieldValid('employeeCnic') }, 'Label__Text')}>
                                    Employee CNIC Number <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={13}
                                    placeholder="Enter Employee CNIC Number"
                                    id="employeeCnic"
                                    name="employeeCnic"
                                    value={formik?.values?.employeeCnic}
                                    // onChange={formik.handleChange}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('employeeCnic') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('employeeCnic')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="employeeMobileNo" className={classNames({ 'p-error': isFormFieldValid('employeeMobileNo') }, 'Label__Text')}>
                                    Employee Mobile Number <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={11}
                                    placeholder="Enter Employee Mobile Number"
                                    id="employeeMobileNo"
                                    name="employeeMobileNo"
                                    value={formik?.values?.employeeMobileNo}
                                    // onChange={formik.handleChange}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('employeeMobileNo') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('employeeMobileNo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="employeeEmail" className={classNames({ 'p-error': isFormFieldValid('employeeEmail') }, 'Label__Text')}>
                                    Employee Email Address <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    placeholder="Enter Employee Email Address"
                                    id="employeeEmail"
                                    name="employeeEmail"
                                    value={formik?.values?.employeeEmail}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('employeeEmail') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('employeeEmail')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" disabled={loading} label="Submit" className="Btn__Dark" />
                        <Button type="button" onClick={() => navigate('/createsalesforce')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
};
export default AddCreateSalesForce;

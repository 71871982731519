import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from 'primereact/multiselect';
import { handleGetRequest } from '../../../services/GetTemplate';
import { useLocation, useNavigate } from 'react-router-dom';

function RiskDashboardCheckerView() {
    const [customerTypeOptions, setCustomerTypeOptions] = useState([]);
    const [dsAgentOptions, setDsAgentOptions] = useState([]);
    const [listOfChannel, setListOfChannel] = useState([]);
    const [listOfStatus, setListOfStatus] = useState([]);
    const [bankName, setBankName] = useState([]);
    const [companyName, setCompanyName] = useState([]);
    const [accountLevelOptions, setAccountLevelOptions] = useState([]);
    const [cityLocation, setCityLocation] = useState([]);
    const [lookUps, setlookUps] = useState([]);
    const [listOfProducts, setListOfProducts] = useState([]);
    const [mcRequestData, setMcRequestData] = useState([]);
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const getCustomerType = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getAccountClassification/LKP_ACCOUNT_CLASSIFICATION:*');
        setCustomerTypeOptions(res?.payLoad);
    };
    const getAccountLevel = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getaccounttypes/LKP_ACCOUNT_LEVEL:*');
        setAccountLevelOptions(res?.payLoad);
    };
    const getCityLocation = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcitieslov/LKP_CITY:*');
        setCityLocation(res?.payLoad);
    };
    const dropdowns = async () => {
        const res = await handleGetRequest('/account/v1/riskdashboard/view');
        setlookUps(res?.payLoad);
    };
    //list of transactions API call
    const getTransactionListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setListOfProducts(res?.payLoad);
    };

    const getstatusData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getAccountStatus/LKP_ACCOUNT_STATUS:*');
        setListOfStatus(res?.payLoad);
    };
    const getChannelData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchannels/LKP_CHANNEL:*');
        setListOfChannel(res?.payLoad);
    };
    const getBankName = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getTblCMSBank/TBL_CMS_BANK:*');
        setBankName(res?.payLoad);
    };
    const getCompanyName = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getTblCMSCompany/TBL_CMS_COMPANY:*');
        setCompanyName(res?.payLoad);
    };

    useEffect(() => {
        let arrayofwords = [];
        customerTypeOptions?.map((item) => {
            if (item.code === 'FRN' || item.code === 'RTL' || item.code === 'HND' || item.code === 'CPT') {
                arrayofwords.push(item);
            }
        });
        setDsAgentOptions(arrayofwords);
    }, [customerTypeOptions]);

    useEffect(() => {
        getCustomerType();
        getAccountLevel();
        getCityLocation();
        dropdowns();
        getTransactionListData();
        getstatusData();
        getChannelData();
        getBankName();
        getCompanyName();
    }, []);

    //get Data by table Id
    const getDatabyId = async () => {
        const res = await handleGetRequest(`/account/v1/riskdashboard/getById/${refTableId}`);

        const channelMultiselect = res?.payLoad?.tblRiskDashboardDs.filter((item) => item.dsType === 'C');
        const channelVar = channelMultiselect.length > 0 ? channelMultiselect.map((item) => item.dsName.toString()) : null;

        const accountMultiselect = res?.payLoad?.tblRiskDashboardDs.filter((item) => item.dsType === 'S');
        const accountVar = accountMultiselect.length > 0 ? accountMultiselect.map((item) => item.dsName.toString()) : null;

        const agentMultiselect = res?.payLoad?.tblRiskDashboardDs.filter((item) => item.dsType === 'R');
        const agentmultiple = agentMultiselect.length > 0 ? agentMultiselect.map((item) => item.dsName.toString()) : null;

        const agentItem = res?.payLoad?.tblRiskDashboardDs?.find((item) => item?.dsType === 'A');
        const agentVar = agentItem ? agentItem.dsName : null;

        const locationItem = res?.payLoad?.tblRiskDashboardDs?.find((item) => item?.dsType === 'L');
        const locationVar = locationItem ? locationItem.dsName?.toString() : null;

        const manufacturerItem = res?.payLoad?.tblRiskDashboardDs?.find((item) => item?.dsType === 'M');
        const manufacturerVar = manufacturerItem ? manufacturerItem.dsName : null;

        formik.setFieldValue('customerTypeId', res?.payLoad?.accountClassificationId?.accountClassificationId.toString());
        formik.setFieldValue('age', res?.payLoad?.age);
        formik.setFieldValue('gender', res?.payLoad?.gender);
        formik.setFieldValue('fromDate', res?.payLoad?.fromDate);
        formik.setFieldValue('toDate', res?.payLoad?.toDate);
        formik.setFieldValue('accountLevelId', res?.payLoad?.accountLevelId?.accountLevelId.toString());
        formik.setFieldValue('accountStatusId', res?.payLoad?.accountStatusId?.accountStatusId.toString());

        //registration section
        formik.setFieldValue('registrationSection', res?.payLoad?.regChannelId?.channelId != null ? true : false);
        formik.setFieldValue('regChannelId', res?.payLoad?.regChannelId?.channelId.toString());
        formik.setFieldValue('registrationCityLocation', res?.payLoad?.regCityId?.cityId.toString());
        formik.setFieldValue('oSType', res?.payLoad?.regOsType);

        //retailer and customer transaction section
        formik.setFieldValue('rCtSection', res?.payLoad?.transIbftBankId?.cmsBankId != null ? true : false);
        formik.setFieldValue('transIBFTBankId', res?.payLoad?.transIbftBankId?.cmsBankId.toString());
        formik.setFieldValue('transIBFTAmount', res?.payLoad?.transIbftAmount);
        formik.setFieldValue('transP2PAmount', res?.payLoad?.transP2pAmount);
        formik.setFieldValue('transBalanceBrackets', res?.payLoad?.transBalanceBrackets);
        formik.setFieldValue('cashInOutCheckbox', res?.payLoad?.transCashInoutAmount != null ? true : false);
        formik.setFieldValue('eLoadAmountCheckbox', res?.payLoad?.transEloadAmount != null ? true : false);
        formik.setFieldValue('eLoadTelcoCheckbox', res?.payLoad?.transEloadCompany != null ? true : false);
        formik.setFieldValue('transCashInOutAmount', res?.payLoad?.transCashInoutAmount);
        formik.setFieldValue('transELoadAmount', res?.payLoad?.transEloadAmount);
        formik.setFieldValue('transELoadCompany', res?.payLoad?.transEloadCompany);
        formik.setFieldValue('transRCProductId', res?.payLoad?.transRcProductId?.productId.toString());
        formik.setFieldValue('transRCWHTax', res?.payLoad?.transRcWhtax);
        formik.setFieldValue('transRCAmount', res?.payLoad?.transRcAmount);
        formik.setFieldValue('transUBPCompanyId', res?.payLoad?.transUbpCompany);
        formik.setFieldValue('transUBPAmount', res?.payLoad?.transUbpAmount);

        //risk and business section
        formik.setFieldValue('riskandBusinessSection', res?.payLoad?.riskandBusinessSection);
        formik.setFieldValue('rBSOTMTohfa', res?.payLoad?.rbsOtmTohfa === 'Y' ? true : false);
        formik.setFieldValue('rBSOTMIBFT', res?.payLoad?.rbsOtmIbft === 'Y' ? true : false);
        formik.setFieldValue('rBSOTMP2P', res?.payLoad?.rbsOtmP2p === 'Y' ? true : false);
        formik.setFieldValue('rBSMTOTohfa', res?.payLoad?.rbsMtoTohfa === 'Y' ? true : false);
        formik.setFieldValue('rBSMTOIBFT', res?.payLoad?.rbsMtoIbft === 'Y' ? true : false);
        formik.setFieldValue('rBSMTOP2P', res?.payLoad?.rbsMtoP2p === 'Y' ? true : false);
        formik.setFieldValue('ubpCountCheckbox', res?.payLoad?.rbsUbpCount != null ? true : false);
        formik.setFieldValue('rBSUBPCount', res?.payLoad?.rbsUbpCount);
        formik.setFieldValue('ubpAmountCheckbox', res?.payLoad?.rbsUbpAmount != null ? true : false);
        formik.setFieldValue('rBSUBPAmount', res?.payLoad?.rbsUbpAmount);
        formik.setFieldValue('exhaustedAmountCheckbox', res?.payLoad?.rbsLimitBreachAmount != null ? true : false);
        formik.setFieldValue('rBSLimitBreachAmount', res?.payLoad?.rbsLimitBreachAmount);
        formik.setFieldValue('blockedAccountCheckbox', accountVar != null ? true : false);
        formik.setFieldValue('rBSAccountStatus', accountVar);
        formik.setFieldValue('blockedChannelCheckbox', channelVar != null ? true : false);
        formik.setFieldValue('rBSAccountStatusRevChannel', channelVar);
        formik.setFieldValue('blockedAgentCheckbox', agentmultiple != null ? true : false);
        formik.setFieldValue('rBSAccountStatusRevAgent', agentmultiple);

        //device section
        formik.setFieldValue('deviceSection', agentVar != null ? true : false);
        formik.setFieldValue('deviceAgent', agentVar);
        formik.setFieldValue('deviceLocation', locationVar);
        formik.setFieldValue('devicemanufacture', manufacturerVar);
    };

    //Get data by mc request Id
    const getMcRequestDataById = async () => {
        const res = await handleGetRequest(`/account/v1/agentaccount/getriskdashboardbymcrequestid/${mcRequestId}`);
        setMcRequestData(res?.payLoad);

        formik.setFieldValue('customerTypeId', res?.payLoad?.customerTypeId?.toString());
        formik.setFieldValue('age', res?.payLoad?.age);
        formik.setFieldValue('gender', res?.payLoad?.gender);
        formik.setFieldValue('fromDate', res?.payLoad?.fromDate);
        formik.setFieldValue('toDate', res?.payLoad?.toDate);
        formik.setFieldValue('accountLevelId', res?.payLoad?.accountLevelId?.toString());
        formik.setFieldValue('accountStatusId', res?.payLoad?.accountStatusId?.toString());

        //registration section
        formik.setFieldValue('registrationSection', res?.payLoad?.registerationSection);
        formik.setFieldValue('regChannelId', res?.payLoad?.regChannelId?.toString());
        formik.setFieldValue('registrationCityLocation', res?.payLoad?.regCityLocationId?.toString());
        formik.setFieldValue('oSType', res?.payLoad?.oSType);

        //retailer and customer transaction section
        formik.setFieldValue('rCtSection', res?.payLoad?.rCtSection);
        formik.setFieldValue('transIBFTBankId', res?.payLoad?.transIBFTBankId?.toString());
        formik.setFieldValue('transIBFTAmount', res?.payLoad?.transIBFTAmount);
        formik.setFieldValue('transP2PAmount', res?.payLoad?.transP2PAmount);
        formik.setFieldValue('transBalanceBrackets', res?.payLoad?.transBalanceBrackets);
        formik.setFieldValue('cashInOutCheckbox', res?.payLoad?.transCashInOutAmountCheck);
        formik.setFieldValue('eLoadAmountCheckbox', res?.payLoad?.transELoadAmountCheck);
        formik.setFieldValue('eLoadTelcoCheckbox', res?.payLoad?.transELoadCompanyCheck);
        formik.setFieldValue('transCashInOutAmount', res?.payLoad?.transCashInOutAmount);
        formik.setFieldValue('transELoadAmount', res?.payLoad?.transELoadAmount);
        formik.setFieldValue('transELoadCompany', res?.payLoad?.transELoadCompany);
        formik.setFieldValue('transRCProductId', res?.payLoad?.transRCProductId?.toString());
        formik.setFieldValue('transRCWHTax', res?.payLoad?.transRCWHTax);
        formik.setFieldValue('transRCAmount', res?.payLoad?.transRCAmount);
        formik.setFieldValue('transUBPCompanyId', res?.payLoad?.transUBPCompany);
        formik.setFieldValue('transUBPAmount', res?.payLoad?.transUBPAmount);

        //risk and business section
        formik.setFieldValue('riskandBusinessSection', res?.payLoad?.rbSection);
        formik.setFieldValue('rBSOTMTohfa', res?.payLoad?.rBSOTMTohfa === 'true' ? true : false);
        formik.setFieldValue('rBSOTMIBFT', res?.payLoad?.rBSOTMIBFT === 'true' ? true : false);
        formik.setFieldValue('rBSOTMP2P', res?.payLoad?.rBSOTMP2P === 'true' ? true : false);
        formik.setFieldValue('rBSMTOTohfa', res?.payLoad?.rBSMTOTohfa === 'true' ? true : false);
        formik.setFieldValue('rBSMTOIBFT', res?.payLoad?.rBSMTOIBFT === 'true' ? true : false);
        formik.setFieldValue('rBSMTOP2P', res?.payLoad?.rBSMTOP2P === 'true' ? true : false);
        formik.setFieldValue('ubpCountCheckbox', res?.payLoad?.rBSUBPCountCheck);
        formik.setFieldValue('rBSUBPCount', res?.payLoad?.rBSUBPCount);
        formik.setFieldValue('ubpAmountCheckbox', res?.payLoad?.rBSUBPAmountCheck);
        formik.setFieldValue('rBSUBPAmount', res?.payLoad?.rBSUBPAmount);
        formik.setFieldValue('exhaustedAmountCheckbox', res?.payLoad?.rBSLimitBreachAmountCheck);
        formik.setFieldValue('rBSLimitBreachAmount', res?.payLoad?.rBSLimitBreachAmount);
        formik.setFieldValue('blockedAccountCheckbox', res?.payLoad?.rbsAccountStatus != null ? true : false);
        formik.setFieldValue('rBSAccountStatus', res?.payLoad?.rbsAccountStatus);
        formik.setFieldValue('blockedChannelCheckbox', res?.payLoad?.rbsAccountStatusRevChannel != null ? true : false);
        formik.setFieldValue('rBSAccountStatusRevChannel', res?.payLoad?.rbsAccountStatusRevChannel);
        formik.setFieldValue('blockedAgentCheckbox', res?.payLoad?.rbsAccountStatusRevAgent != null ? true : false);
        formik.setFieldValue('rBSAccountStatusRevAgent', res?.payLoad?.rbsAccountStatusRevAgent);

        //device section
        formik.setFieldValue('deviceSection', res?.payLoad?.deviceSection);
        formik.setFieldValue('deviceAgent', res?.payLoad?.dsAgent);
        formik.setFieldValue('deviceLocation', res?.payLoad?.dsLocation);
        formik.setFieldValue('devicemanufacture', res?.payLoad?.dsDevicemanufacture);
    };

    //calling getMcRequestDataById and getDatabyId on page load
    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            customerTypeId: '',
            age: '',
            gender: '',
            fromDate: '',
            toDate: '',
            accountLevelId: '',
            accountStatusId: '',
            registrationSection: false,
            regChannelId: '',
            registrationCityLocation: '',
            oSType: '',
            rCtSection: false,
            transIBFTBankId: '',
            transIBFTAmount: '',
            transP2PAmount: '',
            transBalanceBrackets: '',
            cashInOutCheckbox: false,
            eLoadAmountCheckbox: false,
            eLoadTelcoCheckbox: false,
            transCashInOutAmount: '',
            transELoadAmount: '',
            transELoadCompany: '',
            transRCProductId: '',
            transRCWHTax: '',
            transRCAmount: '',
            transUBPCompanyId: '',
            transUBPAmount: '',
            riskandBusinessSection: false,
            rBSOTMTohfa: false,
            rBSOTMIBFT: false,
            rBSOTMP2P: false,
            rBSMTOTohfa: false,
            rBSMTOIBFT: false,
            rBSMTOP2P: false,
            ubpCountCheckbox: false,
            rBSUBPCount: '',
            ubpAmountCheckbox: false,
            rBSUBPAmount: '',
            exhaustedAmountCheckbox: false,
            blockedAccountCheckbox: false,
            blockedChannelCheckbox: false,
            blockedAgentCheckbox: false,
            deviceSection: false,
            rBSLimitBreachAmount: '',
            rBSAccountStatus: '',
            rBSAccountStatusRevChannel: '',
            rBSAccountStatusRevAgent: '',
            deviceAgent: '',
            deviceLocation: '',
            devicemanufacture: '',
            action: '',
            checkerComments: ''
        }
    });

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const resetHandle = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    return (
        <>
            <div className="card Card__Round">
                <form className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="customerTypeId" className={classNames({ 'p-error': isFormFieldValid('customerTypeId') }, 'Label__Text')}>
                                    Customer Type
                                </label>
                                <Dropdown
                                    id="customerTypeId"
                                    placeholder="Select"
                                    options={customerTypeOptions}
                                    optionLabel="name"
                                    name="customerTypeId"
                                    optionValue="lovId"
                                    value={formik.values.customerTypeId}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="age" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                    Age
                                </label>
                                <InputText id="age" name="age" value={formik?.values?.age || ''} onChange={formik.handleChange} className="Dropdown__Round" aria-required disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="gender" className={classNames({ 'p-error': isFormFieldValid('gender') }, 'Label__Text')}>
                                    Gender
                                </label>
                                <Dropdown id="gender" placeholder="Select" options={lookUps?.gender} name="gender" value={formik.values.gender} onChange={formik.handleChange} className="Dropdown__Round" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="fromDate" className={classNames({ 'p-error': isFormFieldValid('fromDate') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText
                                    max={previousDate}
                                    id="fromDate"
                                    placeholder=""
                                    name="fromDate"
                                    type="date"
                                    value={formik?.values?.fromDate?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('fromDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('fromDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="toDate" className={classNames({ 'p-error': isFormFieldValid('toDate') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText
                                    max={previousDate}
                                    id="toDate"
                                    placeholder=""
                                    name="toDate"
                                    type="date"
                                    value={formik?.values?.toDate?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('toDate') }, 'Input__Round')}
                                    disabled
                                />
                                {getFormErrorMessage('toDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountLevelId" className={classNames({ 'p-error': isFormFieldValid('accountLevelId') }, 'Label__Text')}>
                                    Account Level
                                </label>
                                <Dropdown
                                    id="accountLevelId"
                                    placeholder="Select"
                                    options={accountLevelOptions}
                                    optionLabel="name"
                                    name="accountLevelId"
                                    optionValue="lovId"
                                    value={formik.values.accountLevelId}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountStatusId" className={classNames({ 'p-error': isFormFieldValid('accountStatusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="accountStatusId"
                                    placeholder="Select accountStatusId"
                                    options={listOfStatus}
                                    optionLabel="accountStatusName"
                                    name="accountStatusId"
                                    optionValue="accountStatusId"
                                    value={formik.values.accountStatusId || ''}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div class="title__header ">
                            REGISTRATION SECTION
                            <span>
                                <Checkbox id="registrationSection" name="registrationSection" checked={formik?.values?.registrationSection} onChange={formik.handleChange} disabled />
                            </span>
                        </div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="regChannelId" className={classNames({ 'p-error': isFormFieldValid('regChannelId') }, 'Label__Text')}>
                                    Registration Channel
                                </label>
                                <Dropdown
                                    id="regChannelId"
                                    placeholder="Select"
                                    options={listOfChannel}
                                    optionLabel="name"
                                    name="regChannelId"
                                    optionValue="lovId"
                                    value={formik.values.regChannelId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regChannelId') }, 'Dropdown__Round')}
                                    disabled
                                />

                                {getFormErrorMessage('regChannelId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="registrationCityLocation" className={classNames({ 'p-error': isFormFieldValid('registrationCityLocation') }, 'Label__Text')}>
                                    Registration City Location
                                </label>
                                <Dropdown
                                    id="registrationCityLocation"
                                    placeholder="Select"
                                    options={cityLocation}
                                    optionLabel="name"
                                    name="registrationCityLocation"
                                    optionValue="lovId"
                                    value={formik.values.registrationCityLocation}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('registrationCityLocation') }, 'Dropdown__Round')}
                                    disabled
                                />

                                {getFormErrorMessage('registrationCityLocation')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="oSType" className={classNames({ 'p-error': isFormFieldValid('oSType') }, 'Label__Text')}>
                                    OS Type
                                </label>
                                <Dropdown
                                    id="oSType"
                                    placeholder="Select"
                                    options={lookUps?.oSType}
                                    name="oSType"
                                    value={formik.values.oSType}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('oSType') }, 'Dropdown__Round')}
                                    disabled
                                />

                                {getFormErrorMessage('oSType')}
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div class="title__header ">
                            RETAILER AND CUSTOMER TRANSACTION SECTION
                            <span>
                                <Checkbox id="rCtSection" name="rCtSection" checked={formik?.values?.rCtSection} onChange={formik.handleChange} disabled />
                            </span>
                        </div>
                    </div>
                    <div className="Form__Header mt-5">
                        <h6>IBFT IN/OUT</h6>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transIBFTBankId" className={classNames({ 'p-error': isFormFieldValid('transIBFTBankId') }, 'Label__Text')}>
                                    Bank Name
                                </label>
                                <Dropdown
                                    id="transIBFTBankId"
                                    placeholder="Select"
                                    options={bankName}
                                    optionLabel="name"
                                    name="transIBFTBankId"
                                    optionValue="lovId"
                                    value={formik.values.transIBFTBankId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transIBFTBankId') }, 'Dropdown__Round')}
                                    disabled
                                />

                                {getFormErrorMessage('transIBFTBankId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transIBFTAmount" className={classNames({ 'p-error': isFormFieldValid('transIBFTAmount') }, 'Label__Text')}>
                                    Amount
                                </label>
                                <InputText
                                    id="transIBFTAmount"
                                    name="transIBFTAmount"
                                    value={formik?.values?.transIBFTAmount || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transIBFTAmount') }, 'Input__Round')}
                                    disabled
                                    aria-required
                                />
                                {getFormErrorMessage('transIBFTAmount')}
                            </div>
                        </div>
                    </div>

                    <div className="Form__Header mt-5">
                        <h6>P2P</h6>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transP2PAmount" className={classNames({ 'p-error': isFormFieldValid('transP2PAmount') }, 'Label__Text')}>
                                    Amount
                                </label>
                                <InputText
                                    id="transP2PAmount"
                                    name="transP2PAmount"
                                    value={formik?.values?.transP2PAmount || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transP2PAmount') }, 'Input__Round')}
                                    disabled
                                    aria-required
                                />
                                {getFormErrorMessage('transP2PAmount')}
                            </div>
                        </div>
                    </div>

                    <div className="Form__Header mt-5">
                        <h6>BALANCE BRACKETS</h6>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transBalanceBrackets" className={classNames({ 'p-error': isFormFieldValid('transBalanceBrackets') }, 'Label__Text')}>
                                    Select Balance Brackets
                                </label>
                                <Dropdown
                                    id="transBalanceBrackets"
                                    placeholder="Select"
                                    options={lookUps?.balanceBrackets}
                                    name="transBalanceBrackets"
                                    value={formik.values.transBalanceBrackets}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transBalanceBrackets') }, 'Dropdown__Round')}
                                    disabled
                                />

                                {getFormErrorMessage('transBalanceBrackets')}
                            </div>
                        </div>
                    </div>

                    <div className="Form__Header mt-5">
                        <h6>CASH IN/OUT</h6>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transCashInOutAmount" className={classNames('Label__Text', { 'p-error': isFormFieldValid('transCashInOutAmount') })}>
                                    Amount
                                </label>
                                <div className="p-fluid p-grid grid CheckboxAndInput">
                                    <div className="col-12 md:col-1 mt-1">
                                        <Checkbox id="cashInOutCheckbox" name="cashInOutCheckbox" checked={formik?.values?.cashInOutCheckbox} onChange={formik.handleChange} disabled />
                                    </div>
                                    <div className="col-12 md:col-11">
                                        <InputText
                                            id="transCashInOutAmount"
                                            name="transCashInOutAmount"
                                            value={formik?.values?.transCashInOutAmount || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('transCashInOutAmount') }, 'Input__Round')}
                                            disabled
                                            aria-required
                                        />
                                    </div>
                                </div>
                                {getFormErrorMessage('transCashInOutAmount')}
                            </div>
                        </div>
                    </div>

                    <div className="Form__Header mt-5">
                        <h6>E-LOAD</h6>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transELoadAmount" className={classNames('Label__Text', { 'p-error': isFormFieldValid('transELoadAmount') })}>
                                    Amount
                                </label>
                                <div className="p-fluid p-grid grid CheckboxAndInput">
                                    <div className="col-12 md:col-1 mt-1">
                                        <Checkbox id="eLoadAmountCheckbox" name="eLoadAmountCheckbox" checked={formik?.values?.eLoadAmountCheckbox || false} onChange={formik.handleChange} ddisabled />
                                    </div>
                                    <div className="col-12 md:col-11">
                                        <InputText
                                            id="transELoadAmount"
                                            name="transELoadAmount"
                                            value={formik?.values?.transELoadAmount || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('transELoadAmount') }, 'Input__Round')}
                                            disabled
                                            aria-required
                                        />
                                    </div>
                                </div>
                                {getFormErrorMessage('transELoadAmount')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transELoadCompany" className={classNames({ 'p-error': isFormFieldValid('transELoadCompany') }, 'Label__Text')}>
                                    Telco
                                </label>
                                <div className="p-fluid p-grid grid CheckboxAndInput">
                                    <div className="col-12 md:col-1 mt-1">
                                        <Checkbox id="eLoadTelcoCheckbox" name="eLoadTelcoCheckbox" checked={formik?.values?.eLoadTelcoCheckbox || false} onChange={formik.handleChange} disabled />
                                    </div>
                                    <div className="col-12 md:col-11">
                                        <Dropdown
                                            id="transELoadCompany"
                                            placeholder="Select"
                                            options={companyName}
                                            optionLabel="name"
                                            name="transELoadCompany"
                                            optionValue="lovId"
                                            value={formik.values.transELoadCompany}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('transELoadCompany') }, 'Dropdown__Round')}
                                            disabled
                                        />
                                    </div>
                                </div>
                                {getFormErrorMessage('transELoadCompany')}
                            </div>
                        </div>
                    </div>

                    <div className="Form__Header mt-5">
                        <h6>RETAILER COMISSION</h6>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transRCProductId" className={classNames({ 'p-error': isFormFieldValid('transRCProductId') }, 'Label__Text')}>
                                    Product
                                </label>
                                <Dropdown
                                    id="transRCProductId"
                                    placeholder="Select"
                                    options={listOfProducts}
                                    optionLabel="name"
                                    name="transRCProductId"
                                    optionValue="lovId"
                                    value={formik?.values?.transRCProductId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transRCProductId') }, 'Dropdown__Round')}
                                    disabled
                                />

                                {getFormErrorMessage('transRCProductId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transRCWHTax" className={classNames({ 'p-error': isFormFieldValid('transRCWHTax') }, 'Label__Text')}>
                                    With Holding Tax
                                </label>
                                <InputText
                                    id="transRCWHTax"
                                    name="transRCWHTax"
                                    value={formik?.values?.transRCWHTax || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transRCWHTax') }, 'Input__Round')}
                                    disabled
                                    aria-required
                                />
                                {getFormErrorMessage('transRCWHTax')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transRCAmount" className={classNames({ 'p-error': isFormFieldValid('transRCAmount') }, 'Label__Text')}>
                                    Amount
                                </label>
                                <InputText
                                    id="transRCAmount"
                                    name="transRCAmount"
                                    value={formik?.values?.transRCAmount || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transRCAmount') }, 'Input__Round')}
                                    disabled
                                    aria-required
                                />
                                {getFormErrorMessage('transRCAmount')}
                            </div>
                        </div>
                    </div>

                    <div className="Form__Header mt-5">
                        <h6>UBP</h6>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transUBPCompanyId" className={classNames({ 'p-error': isFormFieldValid('transUBPCompanyId') }, 'Label__Text')}>
                                    DISCO
                                </label>
                                <Dropdown
                                    id="transUBPCompanyId"
                                    placeholder="Select"
                                    options={companyName}
                                    optionLabel="name"
                                    name="transUBPCompanyId"
                                    optionValue="lovId"
                                    value={formik.values.transUBPCompanyId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transUBPCompanyId') }, 'Dropdown__Round')}
                                    disabled
                                />

                                {getFormErrorMessage('transUBPCompanyId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transUBPAmount" className={classNames({ 'p-error': isFormFieldValid('transUBPAmount') }, 'Label__Text')}>
                                    Amount
                                </label>
                                <InputText
                                    id="transUBPAmount"
                                    name="transUBPAmount"
                                    value={formik?.values?.transUBPAmount || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transUBPAmount') }, 'Input__Round')}
                                    disabled
                                    aria-required
                                />
                                {getFormErrorMessage('transUBPAmount')}
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div class="title__header ">
                            RISK AND BUSINESS SECTION
                            <span>
                                <Checkbox id="riskandBusinessSection" name="riskandBusinessSection" checked={formik?.values?.riskandBusinessSection} onChange={formik.handleChange} disabled />
                            </span>
                        </div>
                    </div>
                    <div className="Form__Header mt-5">
                        <h6>1 TO MANY ACCOUNT FUND TRANSFER</h6>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-2">
                            <div className="p-field-checkbox">
                                <Checkbox inputId="rBSOTMTohfa" name="rBSOTMTohfa" checked={formik?.values?.rBSOTMTohfa} onChange={formik.handleChange} disabled />
                                <label htmlFor="rBSOTMTohfa">Tohfa</label>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-2">
                            <div className="p-field-checkbox">
                                <Checkbox inputId="rBSOTMIBFT" name="rBSOTMIBFT" checked={formik?.values?.rBSOTMIBFT} onChange={formik.handleChange} disabled />
                                <label htmlFor="rBSOTMIBFT">IBFT</label>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-2">
                            <div className="p-field-checkbox">
                                <Checkbox inputId="rBSOTMP2P" name="rBSOTMP2P" checked={formik?.values?.rBSOTMP2P} onChange={formik.handleChange} disabled />
                                <label htmlFor="rBSOTMP2P">P2P</label>
                            </div>
                        </div>
                    </div>

                    <div className="Form__Header mt-5">
                        <h6>MANY TO 1 ACCOUNT FUND TRANSFER</h6>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-2">
                            <div className="p-field-checkbox">
                                <Checkbox inputId="rBSMTOTohfa" name="rBSMTOTohfa" checked={formik?.values?.rBSMTOTohfa} onChange={formik.handleChange} disabled />
                                <label htmlFor="rBSMTOTohfa">Tohfa</label>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-2">
                            <div className="p-field-checkbox">
                                <Checkbox inputId="rBSMTOIBFT" name="rBSMTOIBFT" checked={formik?.values?.rBSMTOIBFT} onChange={formik.handleChange} disabled />
                                <label htmlFor="rBSMTOIBFT">IBFT</label>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-2">
                            <div className="p-field-checkbox">
                                <Checkbox inputId="rBSMTOP2P" name="rBSMTOP2P" checked={formik?.values?.rBSMTOP2P} onChange={formik.handleChange} disabled />
                                <label htmlFor="rBSMTOP2P">P2P</label>
                            </div>
                        </div>
                    </div>

                    <div className="Form__Header mt-5">
                        <h6>CUSTOMER UBP's</h6>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="rBSUBPCount" className={classNames('Label__Text', { 'p-error': isFormFieldValid('rBSUBPCount') })}>
                                    Total Count
                                </label>
                                <div className="p-fluid p-grid grid CheckboxAndInput">
                                    <div className="col-12 md:col-1 mt-1">
                                        <Checkbox id="ubpCountCheckbox" name="ubpCountCheckbox" checked={formik?.values?.ubpCountCheckbox} onChange={formik.handleChange} disabled />
                                    </div>
                                    <div className="col-12 md:col-11">
                                        <InputText
                                            id="rBSUBPCount"
                                            name="rBSUBPCount"
                                            value={formik?.values?.rBSUBPCount || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rBSUBPCount') }, 'Input__Round')}
                                            aria-required
                                            disabled
                                        />
                                    </div>
                                </div>
                                {getFormErrorMessage('rBSUBPCount')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="rBSUBPAmount" className={classNames('Label__Text', { 'p-error': isFormFieldValid('rBSUBPAmount') })}>
                                    Total Amount
                                </label>
                                <div className="p-fluid p-grid grid CheckboxAndInput">
                                    <div className="col-12 md:col-1 mt-1">
                                        <Checkbox id="ubpAmountCheckbox" name="ubpAmountCheckbox" checked={formik?.values?.ubpAmountCheckbox} onChange={formik.handleChange} disabled />
                                    </div>
                                    <div className="col-12 md:col-11">
                                        <InputText
                                            id="rBSUBPAmount"
                                            name="rBSUBPAmount"
                                            value={formik?.values?.rBSUBPAmount || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rBSUBPAmount') }, 'Input__Round')}
                                            aria-required
                                            disabled
                                        />
                                    </div>
                                </div>
                                {getFormErrorMessage('rBSUBPAmount')}
                            </div>
                        </div>
                    </div>
                    <div className="Form__Header mt-5">
                        <h6>EXHAUSTED / BREACHED LIMIT CUSTOMER</h6>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="rBSLimitBreachAmount" className={classNames({ 'p-error': isFormFieldValid('rBSLimitBreachAmount') }, 'Label__Text')}>
                                    Amount
                                </label>
                                <div className="p-fluid p-grid grid CheckboxAndInput">
                                    <div className="col-12 md:col-1 mt-1">
                                        <Checkbox id="exhaustedAmountCheckbox" name="exhaustedAmountCheckbox" checked={formik?.values?.exhaustedAmountCheckbox || false} onChange={formik.handleChange} disabled />
                                    </div>
                                    <div className="col-12 md:col-11">
                                        <Dropdown
                                            id="rBSLimitBreachAmount"
                                            placeholder="Select"
                                            options={lookUps?.rBSLimitBreachAmount}
                                            name="rBSLimitBreachAmount"
                                            value={formik.values.rBSLimitBreachAmount}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rBSLimitBreachAmount') }, 'Dropdown__Round')}
                                            disabled
                                        />
                                    </div>
                                </div>
                                {getFormErrorMessage('rBSLimitBreachAmount')}
                            </div>
                        </div>
                    </div>
                    <div className="Form__Header mt-5">
                        <h6>BLOCKED / SUSPENDED / BLACKLISTED / DORMANT ACCOUNT</h6>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="rBSAccountStatus" className={classNames({ 'p-error': isFormFieldValid('rBSAccountStatus') }, 'Label__Text')}>
                                    Account
                                </label>

                                <div className="p-fluid p-grid grid CheckboxAndInput">
                                    <div className="col-12 md:col-1 mt-1">
                                        <Checkbox id="blockedAccountCheckbox" name="blockedAccountCheckbox" checked={formik?.values?.blockedAccountCheckbox} onChange={formik.handleChange} disabled />
                                    </div>
                                    <div className="col-12 md:col-11">
                                        <MultiSelect
                                            id="rBSAccountStatus"
                                            placeholder="Select"
                                            options={listOfStatus}
                                            optionLabel="accountStatusName"
                                            name="rBSAccountStatus"
                                            optionValue="accountStatusId"
                                            value={formik.values.rBSAccountStatus}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rBSAccountStatus') }, 'Dropdown__Round')}
                                            maxSelectedLabels={3}
                                            disabled
                                        />
                                    </div>
                                </div>
                                {getFormErrorMessage('rBSAccountStatus')}
                            </div>
                        </div>
                    </div>
                    <div className="Form__Header mt-5">
                        <h6>BLOCKED / SUSPENDED / BLACKLISTED / DORMANCY REVIVAL</h6>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="rBSAccountStatusRevChannel" className={classNames({ 'p-error': isFormFieldValid('rBSAccountStatusRevChannel') }, 'Label__Text')}>
                                    Channel
                                </label>
                                <div className="p-fluid p-grid grid CheckboxAndInput">
                                    <div className="col-12 md:col-1 mt-1">
                                        <Checkbox id="blockedChannelCheckbox" name="blockedChannelCheckbox" checked={formik?.values?.blockedChannelCheckbox} onChange={formik.handleChange} disabled />
                                    </div>
                                    <div className="col-12 md:col-11">
                                        <MultiSelect
                                            id="rBSAccountStatusRevChannel"
                                            placeholder="Select"
                                            options={listOfChannel}
                                            optionLabel="name"
                                            name="rBSAccountStatusRevChannel"
                                            optionValue="lovId"
                                            value={formik.values.rBSAccountStatusRevChannel}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rBSAccountStatusRevChannel') }, 'Dropdown__Round')}
                                            maxSelectedLabels={3}
                                            disabled
                                        />
                                    </div>
                                </div>
                                {getFormErrorMessage('rBSAccountStatusRevChannel')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="rBSAccountStatusRevAgent" className={classNames({ 'p-error': isFormFieldValid('rBSAccountStatusRevAgent') }, 'Label__Text')}>
                                    Agent
                                </label>
                                <div className="p-fluid p-grid grid CheckboxAndInput">
                                    <div className="col-12 md:col-1 mt-1">
                                        <Checkbox id="blockedAgentCheckbox" name="blockedAgentCheckbox" checked={formik?.values?.blockedAgentCheckbox} onChange={formik.handleChange} disabled />
                                    </div>
                                    <div className="col-12 md:col-11">
                                        <MultiSelect
                                            id="rBSAccountStatusRevAgent"
                                            placeholder="Select"
                                            options={lookUps?.rBSAccountStatusRevAgent}
                                            name="rBSAccountStatusRevAgent"
                                            value={formik.values.rBSAccountStatusRevAgent}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rBSAccountStatusRevAgent') }, 'Dropdown__Round')}
                                            maxSelectedLabels={3}
                                            disabled
                                        />
                                    </div>
                                </div>
                                {getFormErrorMessage('rBSAccountStatusRevAgent')}
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div class="title__header ">
                            DEVICE SECTION
                            <span>
                                <Checkbox id="deviceSection" name="deviceSection" checked={formik?.values?.deviceSection} onChange={formik.handleChange} disabled />
                            </span>
                        </div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="deviceAgent" className={classNames({ 'p-error': isFormFieldValid('deviceAgent') }, 'Label__Text')}>
                                    Agent/Retailer
                                </label>
                                <Dropdown
                                    id="deviceAgent"
                                    placeholder="Select"
                                    options={dsAgentOptions}
                                    optionLabel="name"
                                    name="deviceAgent"
                                    optionValue="lovId"
                                    value={formik.values.deviceAgent}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('deviceAgent') }, 'Dropdown__Round')}
                                    disabled
                                />

                                {getFormErrorMessage('deviceAgent')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="deviceLocation" className={classNames({ 'p-error': isFormFieldValid('deviceLocation') }, 'Label__Text')}>
                                    Device Location
                                </label>
                                <Dropdown
                                    id="deviceLocation"
                                    placeholder="Select"
                                    options={cityLocation}
                                    optionLabel="name"
                                    name="deviceLocation"
                                    optionValue="lovId"
                                    value={formik.values.deviceLocation}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('deviceLocation') }, 'Dropdown__Round')}
                                    disabled
                                />

                                {getFormErrorMessage('deviceLocation')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="devicemanufacture" className={classNames({ 'p-error': isFormFieldValid('devicemanufacture') }, 'Label__Text')}>
                                    Device Manufacturer
                                </label>
                                <Dropdown
                                    id="devicemanufacture"
                                    placeholder="Select"
                                    options={lookUps?.dSDeviceManufacturer}
                                    name="devicemanufacture"
                                    value={formik.values.devicemanufacture}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('devicemanufacture') }, 'Dropdown__Round')}
                                    disabled
                                />

                                {getFormErrorMessage('devicemanufacture')}
                            </div>
                        </div>
                    </div>
                </form>
                <div className="Down__Btn">
                    <Button onClick={() => navigate('/requestchecker')} type="button" label="Okay" className="Btn__Dark" />
                </div>
            </div>
        </>
    );
}
export default RiskDashboardCheckerView;

import React, { useState, useEffect } from 'react';
import { handleGetRequest } from '../../../services/GetTemplate';
import AtmDeliveryTCR from '../Components/RiskAndBusinessSection/AtmDeliveryTCR';
import ManyToOneAccFT from '../Components/RiskAndBusinessSection/ManyToOneAccFT';
import CustomerUbps from '../Components/RiskAndBusinessSection/CustomerUbps';
import OneToManyAccFT from '../Components/RiskAndBusinessSection/OneToManyAccFT';
import BlockedSuspendedBlacklistedDormantAcc from '../Components/RiskAndBusinessSection/BlockedSuspendedBlacklistedDormantAcc';
import ExaustedBreachedLimitCustomer from '../Components/RiskAndBusinessSection/ExaustedBreachedLimitCustomer';
import BlockedSuspendedBlacklistedDormancyRevival from '../Components/RiskAndBusinessSection/BlockedSuspendedBlacklistedDormancyRevival';

function RiskAndBusinessSection() {

    const [riskAndBusinessSectionData, setRiskAndBusinessSectionData] = useState([]);

    const getRiskAndBusinessSectionData = async () => {
        const res = await handleGetRequest('/account/v1/riskdashboard/rbsgraph');
        setRiskAndBusinessSectionData(res?.payLoad);
    }

    useEffect(() => {
        getRiskAndBusinessSectionData()
    }, [])

    console.log('riskAndBusinessSectionData', riskAndBusinessSectionData)

    return (
        <>
            <div className="grid">

                {/* <AtmDeliveryTCR /> */}
                <ManyToOneAccFT manyToOneAccFT={riskAndBusinessSectionData?.manyToOneAccountFoundTransfer} />
                <CustomerUbps customerUbps={riskAndBusinessSectionData?.customerUbpsData} />
                <OneToManyAccFT oneToManyAccFT={riskAndBusinessSectionData?.oneToManyFoundTransferData} />
                <ExaustedBreachedLimitCustomer exaustedBreachedLC={riskAndBusinessSectionData?.ebLimitCustomerData} />
                <BlockedSuspendedBlacklistedDormancyRevival bsbDormancyRevival={riskAndBusinessSectionData?.dormancyRevivalData} />
                <BlockedSuspendedBlacklistedDormantAcc bsbDormantAccounts={riskAndBusinessSectionData?.dormancyAccountsData} />

            </div>
        </>
    );
}
export default RiskAndBusinessSection;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';

function EditError() {
    const [useCaseData, setUseCaseData] = useState([]);
    const [errorResp, setErorResp] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const { rowData } = location.state || {};

    const getUseCase = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_USECASE');
        setUseCaseData(resp?.payLoad);
    };

    const getErorResp = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/RESPONSE_MESSAGE_TYPE');
        setErorResp(resp?.payLoad);
    };

    useEffect(() => {
        getErorResp();
        getUseCase();
    }, []);

    const validationSchema = Yup.object().shape({
        responseMessageCode: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            // .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
            .required('This field is required'),
            cmsUsecaseId: Yup.string()
            .required('This field is required'),
        responseMessageDescr: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
            .required('This field is required'),
            responseMessageUrduDesc: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
            .required('This field is required'),
            responseMessageTitle: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
            .required('This field is required'),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            responseMessageId: '',
            responseMessageCode: '',
            responseMessageDescr: '',
            responseMessageTypeId: '',
            responseMessageTitle: '',
            cmsUsecaseId: '',
            responseMessageUrduDesc: ''
        },

        onSubmit: async (data) => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            await dispatch(handlePostRequest(newData, '/cms/v1/cms/updateerror', true, false, 'errormessages'));
        }
    });

    const getMainCategoryData = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/geterrorbyid/${rowData?.responseMessageId}`);
        const keyData = res?.payLoad;
        formik.setFieldValue('responseMessageId', keyData?.responseMessageId);
        formik.setFieldValue('responseMessageUrduDesc', keyData?.responseMessageUrduDesc);
        formik.setFieldValue('responseMessageCode', keyData?.responseMessageCode);
        formik.setFieldValue('responseMessageDescr', keyData?.responseMessageDescr);
        formik.setFieldValue('responseMessageTitle', keyData?.responseMessageTitle || '');
        formik.setFieldValue('cmsUsecaseId', keyData?.cmsUsecaseId?.toString());
        formik.setFieldValue('responseMessageTypeId', keyData?.responseMessageTypeId?.toString());
    };

    useEffect(() => {
        getMainCategoryData();
    }, [rowData]);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>EDIT DETAIL</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="responseMessageCode" className={classNames({ 'p-error': isFormFieldValid('responseMessageCode') }, 'Label__Text')}>
                                    Error Code <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="responseMessageCode"
                                    placeholder="Enter Error Code"
                                    name="responseMessageCode"
                                    value={formik?.values?.responseMessageCode || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('responseMessageCode') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('responseMessageCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="responseMessageTypeId" className={classNames({ 'p-error': isFormFieldValid('responseMessageTypeId') }, 'Label__Text')}>
                                    Error Category
                                </label>
                                <Dropdown
                                    id="responseMessageTypeId"
                                    placeholder="Enter Error Category"
                                    options={errorResp}
                                    optionLabel="name"
                                    name="responseMessageTypeId"
                                    optionValue="lovId"
                                    value={formik.values.responseMessageTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('responseMessageTypeId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('responseMessageTypeId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="responseMessageDescr" className={classNames({ 'p-error': isFormFieldValid('responseMessageDescr') }, 'Label__Text')}>
                                    Error Details <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="responseMessageDescr"
                                    placeholder="Enter Error Details"
                                    name="responseMessageDescr"
                                    value={formik?.values?.responseMessageDescr || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('responseMessageDescr') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('responseMessageDescr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="responseMessageUrduDesc" className={classNames({ 'p-error': isFormFieldValid('responseMessageUrduDesc') }, 'Label__Text')}>
                                    Urdu Description<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="responseMessageUrduDesc"
                                    placeholder="Enter  Details"
                                    name="responseMessageUrduDesc"
                                    value={formik?.values?.responseMessageUrduDesc || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('responseMessageUrduDesc') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('responseMessageUrduDesc')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="responseMessageTitle" className={classNames({ 'p-error': isFormFieldValid('responseMessageTitle') }, 'Label__Text')}>
                                   English  Description<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="responseMessageTitle"
                                    placeholder="Enter  Details"
                                    name="responseMessageTitle"
                                    value={formik?.values?.responseMessageTitle || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('responseMessageTitle') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('responseMessageTitle')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsUsecaseId" className={classNames({ 'p-error': isFormFieldValid('cmsUsecaseId') }, 'Label__Text')}>
                                    Use Case<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="cmsUsecaseId"
                                    placeholder="Select Use Case"
                                    options={useCaseData}
                                    optionLabel="name"
                                    name="cmsUsecaseId"
                                    optionValue="lovId"
                                    value={formik.values.cmsUsecaseId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsUsecaseId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsUsecaseId')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="submit" label="Update" className="Btn__Dark" />
                        <Button onClick={() => navigate('/errormessages')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default EditError;

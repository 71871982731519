import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { MultiSelect } from 'primereact/multiselect';
import { InputNumber } from 'primereact/inputnumber';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

function UpdateRewardConfigurationChecker({ compaignIdData, campaignData }) {
    const [rewardConfigurationLov, setRewardConfigurationLov] = useState([]);
    const [frequencyPeriodLov, setFrequencyPeriodLov] = useState([]);
    const [incentiveTypeLov, setIncentiveTypeLov] = useState([]);
    // const [glIdData, setGlIdData] = useState('');
    const [promoCode, setPromoCode] = useState();

    const [loadingIconCashback, setloadingIconCashback] = useState('');
    const [loadingCashback, setloadingCashback] = useState(false);

    const dispatch = useDispatch();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const requestType = query.get('requestType');

    const getRewardConfigurationLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getdatalov/LKP_CAMPAIGN_REWARD_TYPE');
        setRewardConfigurationLov(resp?.payLoad);
    };

    const getFrequencyPeriodLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getdatalov/LKP_FREQUENCY_PERIOD');
        setFrequencyPeriodLov(resp?.payLoad);
    };

    const getIncentiveTypeLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getdatalov/LKP_CAMPAIGN_INCENTIVE_TYPE');
        setIncentiveTypeLov(resp?.payLoad);
    };

    // const getGlId = async () => {
    //     setloadingCashback(true);
    //     setloadingIconCashback('pi pi-spin pi-spinner');
    //     const data = {
    //         accountNumber: ''
    //     };

    //     data['accountNumber'] = formik.values.glId;

    //     const newData = {
    //         data: {
    //             security: {
    //                 userName: '',
    //                 password: '',
    //                 securityToken: ''
    //             },
    //             account: {
    //                 msidn: '',
    //                 iban: '',
    //                 bban: '',
    //                 pan: '',
    //                 currency: ''
    //             },
    //             channel: '',
    //             terminal: '',
    //             reterivalReferenceNumber: '',
    //             payLoad: data,
    //             additionalInformation: [
    //                 {
    //                     infoKey: '',
    //                     infoValue: ''
    //                 }
    //             ],
    //             checkSum: ''
    //         }
    //     };

    //     const res = await dispatch(handlePostRequest(newData, '/account/v1/fetchglbyaccountno', true, false));
    //     if (res?.responseCode === '010000') {
    //         setGlIdData(res?.payLoad);
    //     }
    //     setloadingIconCashback('');
    //     setloadingCashback(false);
    // };

    const generatePromoCode = async () => {
        const data = {
            cashBackType: '',
            rcAmount: '',
            campaignId: '',
            rcMaxDiscountAmount: ''
        };

        data['campaignId'] = compaignIdData;
        data['cashBackType'] = formik?.values?.cashBackType;
        data['rcAmount'] = formik?.values?.rcAmount;
        data['rcMaxDiscountAmount'] = formik?.values?.rcMaxDiscountAmount;

        if (data['rcMaxDiscountAmount'] !== '' || data['rcMaxDiscountAmount'] !== null) {
            data['rcAmount'] = '';
        }

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/customerloyalitypromotions/v1/customerloyalitypromotion/generatepromocode', true, false));
        if (res?.responseCode === '150000') {
            setPromoCode(res?.payLoad?.rcPromoCode);
            // formik.resetForm();
        }
    };

    useEffect(() => {
        getRewardConfigurationLov();
        getFrequencyPeriodLov();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (requestType === 'I') {
            if (campaignData) {
                formik.setFieldValue('rewardTypeCode', campaignData?.lkpCampaignRewardType?.campaignRewardTypeCode?.toString());

                // formik.setFieldValue('rcAmount', campaignData?.budget);

                if (campaignData?.rcCashbackType === 'P') {
                    formik.setFieldValue('rcAmount', campaignData?.rcPercentage);
                } else if (campaignData?.rcAmount === null) {
                    formik.setFieldValue('rcAmount', campaignData?.budget);
                } else {
                    formik.setFieldValue('rcAmount', campaignData?.rcAmount);
                }

                const campaignIncentiveTypeIds = campaignData?.tblCampaignRcIncentiveTypes?.filter((item) => item.isActive === 'Y')?.map((item) => item.lkpCampaignIncentiveType.campaignIncentiveTypeId.toString());

                formik.setFieldValue('rcIncentives', campaignIncentiveTypeIds);

                // formik.setFieldValue('cashBackType', campaignData?.rcCashbackType?.cashbackTypeCode?.toString());

                formik.setFieldValue('rcPromoDisc', campaignData?.rcDescription);

                formik.setFieldValue('rcFrequency', campaignData?.rcFrequency?.toString());
                formik.setFieldValue('rcFrequencyPeriodCode', campaignData?.lkpRCFrequencyPeriod?.frequencyPeriodCode?.toString());

                formik.setFieldValue('rcMaxDiscountAmount', campaignData?.rcMaxCashbackAmount);
                formik.setFieldValue('rcPercentage', campaignData?.rcPercentage);
                // formik.setFieldValue('rcPromoCode', campaignData?.rcPromoCode);

                setPromoCode(campaignData?.rcPromoCode);

                formik.setFieldValue('glId', campaignData?.tblAccount?.accountNo);
                formik.setFieldValue('glName', campaignData?.tblAccount?.accountTitle);
                formik.setFieldValue('availableBalance', campaignData?.tblAccount?.availableBalance);

                formik.setFieldValue('cashBackType', campaignData?.rcCashbackType);

                formik.setFieldValue('rcFrequencyMonth', campaignData?.rcFrequencyMonth?.slice(1, -1).split(', '));
                formik.setFieldValue('rcFrequencyDay', campaignData?.rcFrequencyDay?.slice(1, -1).split(', '));
                formik.setFieldValue('rcFrequencyDate', campaignData?.rcFrequencyDate?.slice(1, -1).split(', '));

                formik.setFieldValue('voucherTitle', campaignData?.voucherTitle);
                formik.setFieldValue('expiryDateTime', new Date(campaignData?.voucherExpiryDate).toISOString().slice(0, 16));
                formik.setFieldValue('voucherDescription', campaignData?.voucherDescr);
                formik.setFieldValue(
                    'frequencyRcTimeList',
                    campaignData?.tblCampaignRcTimes?.filter((item) => item?.isActive === 'Y')
                );
            }
        } else if (requestType === 'U') {
            if (campaignData) {
                formik.setFieldValue('rewardTypeCode', campaignData[3]?.rewardTypeCode?.toString());

                formik.setFieldValue('rcAmount', campaignData[3]?.campaignBudget);

                // formik.setFieldValue('rcIncentiveCode', campaignData[3]?.rcIncentiveCode?.toString());
                const campaignIncentiveTypeIds = campaignData[3]?.rcIncentives?.map((item) => item?.toString());

                formik.setFieldValue('rcIncentives', campaignIncentiveTypeIds);

                formik.setFieldValue('cashBackType', campaignData[3]?.cashBackType);

                formik.setFieldValue('rcPromoDisc', campaignData[3]?.rcPromoDisc);
                formik.setFieldValue('rcAmount', campaignData[3]?.rcAmount);
                formik.setFieldValue('rcMaxDiscountAmount', campaignData[3]?.rcMaxDiscountAmount);

                formik.setFieldValue('rcFrequency', campaignData[3]?.rcFrequency?.toString());
                formik.setFieldValue('rcFrequencyPeriodCode', campaignData[3]?.rcFrequencyPeriodCode?.toString());

                formik.setFieldValue('glId', campaignData[3]?.glId);
                formik.setFieldValue('glName', campaignData[3]?.glName);
                formik.setFieldValue('availableBalance', campaignData[3]?.glBalance);

                formik.setFieldValue('rcMaxDiscountAmount', campaignData[3]?.rcMaxDiscountAmount);
                formik.setFieldValue('rcPercentage', campaignData[3]?.rcPercentage);
                // formik.setFieldValue('rcPromoCode', campaignData[3]?.rcPromoCode);
                setPromoCode(campaignData[3]?.rcPromoCode);

                formik.setFieldValue('rcFrequencyMonth', campaignData[3]?.rcFrequencyMonth);
                formik.setFieldValue('rcFrequencyDay', campaignData[3]?.rcFrequencyDay);
                formik.setFieldValue('rcFrequencyDate', campaignData[3]?.rcFrequencyDate);
                formik.setFieldValue('frequencyRcTimeList', campaignData[3]?.frequencyRcTimeList);

                // Function to convert and format the date
                function formatDateString(dateString) {
                    if (!dateString) {
                        // Handle the case where dateString is undefined or null
                        return '';
                    }
                    const [datePart, timePart] = dateString?.split(', '); // Separate date and time parts
                    const [day, month, year] = datePart?.split('-')?.map(Number);
                    const [time, ampm] = timePart?.split(' ');
                    let [hours, minutes] = time?.split(':')?.map(Number);

                    // Adjust hours for PM
                    if (ampm === 'PM' && hours < 12) {
                        hours += 12;
                    }

                    // Format the date as ISO 8601 string
                    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
                }

                // function formatDateString(dateString) {
                //     if (!dateString) {
                //         // Handle the case where dateString is undefined or null
                //         return '';
                //     }
                //     const dateTimeParts = dateString.split(', ');
                //     if (dateTimeParts.length !== 2) {
                //         // Handle the case where the date and time parts are not properly separated
                //         return '';
                //     }
                //     const [datePart, timePart] = dateTimeParts;
                //     const dateParts = datePart.split('-').map(Number);
                //     if (dateParts.length !== 3) {
                //         // Handle the case where the date part does not have exactly three components
                //         return '';
                //     }
                //     const [day, month, year] = dateParts;
                //     const timeParts = timePart.split(' ');
                //     if (timeParts.length !== 2) {
                //         // Handle the case where the time and AM/PM parts are not properly separated
                //         return '';
                //     }
                //     const [time, ampm] = timeParts;
                //     const timeComponents = time.split(':').map(Number);
                //     if (timeComponents.length !== 2) {
                //         // Handle the case where the time part does not have exactly two components
                //         return '';
                //     }
                //     let [hours, minutes] = timeComponents;
                //     // Adjust hours for PM
                //     if (ampm === 'PM' && hours < 12) {
                //         hours += 12;
                //     }
                //     // Format the date as ISO 8601 string
                //     return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
                // }

                formik.setFieldValue('voucherTitle', campaignData[3]?.voucherTitle);

                const expiryDateTimeFormat = formatDateString(campaignData[3]?.expiryDateTime);
                formik.setFieldValue('expiryDateTime', expiryDateTimeFormat);

                formik.setFieldValue('voucherDescription', campaignData[3]?.voucherDescription);
            }
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            rewardTypeCode: '',
            rcAmount: '',
            rcFrequencyPeriodCode: '',
            rcFrequency: '',
            // rcPercentage: '',

            availableBalance: '',
            glName: '',
            glId: '',
            cashBackType: '',
            rcPromoDisc: '',
            rcMaxDiscountAmount: '',

            rcIncentives: [],

            rcFrequencyMonth: '',
            rcFrequencyDay: '',
            rcFrequencyDate: '',

            voucherTitle: '',
            expiryDateTime: '',
            voucherDescription: '',

            frequencyRcTimeList: [
                {
                    startTime: '',
                    endTime: ''
                }
            ]
        }
    });

    useEffect(() => {
        if (formik?.values?.rewardTypeCode === '40') {
            getIncentiveTypeLov();
        }
    }, [formik?.values?.rewardTypeCode]); // eslint-disable-line

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const addfrequencyRcTimeList = () => {
        formik.setFieldValue('frequencyRcTimeList', [
            ...formik.values.frequencyRcTimeList,
            {
                startTime: '',
                endTime: ''
            }
        ]);
    };

    const removefrequencyRcTimeList = (index) => {
        if (index > 0) {
            const updatedData = [...formik.values.frequencyRcTimeList];
            updatedData.splice(index, 1);
            formik.setFieldValue('frequencyRcTimeList', updatedData);
        }
        if (index === 0) {
            const updatedData = [...formik.values.frequencyRcTimeList];
            formik.setFieldValue('frequencyRcTimeList', updatedData);
        }
    };

    const cashbackTypeLov = [
        {
            name: 'Fixed Amount',
            lovId: 'F'
        },
        {
            name: 'Percentage Amount',
            lovId: 'P'
        }
    ];

    const discountTypeLov = [
        {
            name: 'Fixed Amount',
            lovId: 'F'
        },
        {
            name: 'Percentage Amount',
            lovId: 'P'
        }
    ];

    const weekDays = [
        { name: 'Monday', lovId: 'Monday' },
        { name: 'Tuesday', lovId: 'Tuesday' },
        { name: 'Wednesday', lovId: 'Wednesday' },
        { name: 'Thursday', lovId: 'Thursday' },
        { name: 'Friday', lovId: 'Friday' },
        { name: 'Saturday', lovId: 'Saturday' },
        { name: 'Sunday', lovId: 'Sunday' }
    ];

    const Dates = [
        { name: '1', lovId: '1' },
        { name: '2', lovId: '2' },
        { name: '3', lovId: '3' },
        { name: '4', lovId: '4' },
        { name: '5', lovId: '5' },
        { name: '6', lovId: '6' },
        { name: '7', lovId: '7' },
        { name: '8', lovId: '8' },
        { name: '9', lovId: '9' },
        { name: '10', lovId: '10' },
        { name: '11', lovId: '11' },
        { name: '12', lovId: '12' },
        { name: '13', lovId: '13' },
        { name: '14', lovId: '14' },
        { name: '15', lovId: '15' },
        { name: '16', lovId: '16' },
        { name: '17', lovId: '17' },
        { name: '18', lovId: '18' },
        { name: '19', lovId: '19' },
        { name: '20', lovId: '20' },
        { name: '21', lovId: '21' },
        { name: '22', lovId: '22' },
        { name: '23', lovId: '23' },
        { name: '24', lovId: '24' },
        { name: '25', lovId: '25' },
        { name: '26', lovId: '26' },
        { name: '27', lovId: '27' },
        { name: '28', lovId: '28' },
        { name: '29', lovId: '29' },
        { name: '30', lovId: '30' },
        { name: '31', lovId: '31' }
    ];

    const monthsLov = [
        { name: 'January', lovId: 'January' },
        { name: 'February', lovId: 'February' },
        { name: 'March', lovId: 'March' },
        { name: 'April', lovId: 'April' },
        { name: 'May', lovId: 'May' },
        { name: 'June', lovId: 'June' },
        { name: 'July', lovId: 'July' },
        { name: 'August', lovId: 'August' },
        { name: 'September', lovId: 'September' },
        { name: 'October', lovId: 'October' },
        { name: 'November', lovId: 'November' },
        { name: 'December', lovId: 'December' }
    ];

    const getCurrentDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const day = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>Reward Configurations</h1>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="rewardTypeCode" className={classNames({ 'p-error': isFormFieldValid('rewardTypeCode') }, 'Label__Text')}>
                                    Reward Type<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    disabled
                                    id="rewardTypeCode"
                                    placeholder="Select Reward Type"
                                    options={rewardConfigurationLov}
                                    optionLabel="name"
                                    name="rewardTypeCode"
                                    optionValue="code"
                                    value={formik?.values?.rewardTypeCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('rewardTypeCode') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('rewardTypeCode')}
                            </div>
                        </div>
                    </div>
                    {formik?.values?.rewardTypeCode === '10' && (
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-2 pb-2">
                                <div className="flex">
                                    <div className="col-8">
                                        <label htmlFor="glId" className={classNames({ 'p-error': isFormFieldValid('glId') }, 'Label__Text')}>
                                            GL Id<span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="glId"
                                            placeholder="Enter GL Id"
                                            name="glId"
                                            disabled
                                            value={formik?.values?.glId || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('glId') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('glId')}
                                    </div>
                                    <div className="col-4 Fetch__Btn">
                                        <Button
                                            disabled={loadingCashback}
                                            icon={loadingIconCashback || ''}
                                            label="Fetch"
                                            className="Btn__Fetch"
                                            // onClick={(e) => {
                                            //     e.preventDefault();
                                            //     getGlId();
                                            // }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="glName" className={classNames({ 'p-error': isFormFieldValid('glName') }, 'Label__Text')}>
                                        GL Name
                                    </label>
                                    <InputText
                                        id="glName"
                                        placeholder="Enter GL Name"
                                        name="glName"
                                        disabled={true}
                                        value={formik?.values?.glName}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('glName') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('glName')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="availableBalance" className={classNames({ 'p-error': isFormFieldValid('availableBalance') }, 'Label__Text')}>
                                        Available Balance
                                    </label>
                                </div>
                                <InputNumber
                                    inputId="availableBalance"
                                    name="availableBalance"
                                    maxLength={9}
                                    placeholder="Enter Available Balanace"
                                    disabled={true}
                                    value={formik?.values?.availableBalance}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('availableBalance') }, 'Input_Number')}
                                />
                                {getFormErrorMessage('availableBalance')}
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="cashBackType" className={classNames({ 'p-error': isFormFieldValid('cashBackType') }, 'Label__Text')}>
                                        Cashback Type<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        disabled
                                        id="cashBackType"
                                        placeholder="Select Cashback Type"
                                        options={cashbackTypeLov}
                                        optionLabel="name"
                                        name="cashBackType"
                                        optionValue="lovId"
                                        value={formik?.values?.cashBackType || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cashBackType') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('cashBackType')}
                                </div>
                            </div>
                            {formik?.values?.cashBackType === 'F' && (
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="rcAmount" className={classNames({ 'p-error': isFormFieldValid('rcAmount') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                    </div>
                                    <InputNumber
                                        disabled
                                        inputId="rcAmount"
                                        name="rcAmount"
                                        maxLength={9}
                                        placeholder="Enter Amount"
                                        value={formik?.values?.rcAmount || ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('rcAmount') }, 'Input_Number')}
                                    />
                                    {getFormErrorMessage('rcAmount')}
                                </div>
                            )}
                            {formik?.values?.cashBackType === 'P' && (
                                <>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="rcAmount" className={classNames({ 'p-error': isFormFieldValid('rcAmount') }, 'Label__Text')}>
                                                Discount Percentage
                                            </label>
                                        </div>
                                        <InputNumber
                                            disabled
                                            inputId="rcAmount"
                                            name="rcAmount"
                                            maxLength={9}
                                            placeholder="Enter Amount"
                                            value={formik?.values?.rcAmount || ''}
                                            onValueChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rcAmount') }, 'Input_Number')}
                                        />
                                        {getFormErrorMessage('rcAmount')}
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="rcMaxDiscountAmount" className={classNames({ 'p-error': isFormFieldValid('rcMaxDiscountAmount') }, 'Label__Text')}>
                                                Maximum Discount Amount
                                            </label>
                                            <InputText
                                                disabled
                                                id="rcMaxDiscountAmount"
                                                placeholder="Enter Maximum Discount Amount"
                                                name="rcMaxDiscountAmount"
                                                value={formik?.values?.rcMaxDiscountAmount || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('rcMaxDiscountAmount') }, 'Input__Round')}
                                            />
                                            {getFormErrorMessage('rcMaxDiscountAmount')}
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="rcFrequencyPeriodCode" className={classNames({ 'p-error': isFormFieldValid('rcFrequencyPeriodCode') }, 'Label__Text')}>
                                        Frequency Period
                                    </label>
                                    <Dropdown
                                        disabled
                                        id="rcFrequencyPeriodCode"
                                        placeholder="Select Frequency Period"
                                        options={frequencyPeriodLov}
                                        optionLabel="name"
                                        name="rcFrequencyPeriodCode"
                                        optionValue="code"
                                        value={formik?.values?.rcFrequencyPeriodCode || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('rcFrequencyPeriodCode') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('rcFrequencyPeriodCode')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="rcFrequency" className={classNames({ 'p-error': isFormFieldValid('rcFrequency') }, 'Label__Text')}>
                                        Frequency
                                    </label>
                                </div>
                                <InputNumber
                                    disabled
                                    inputId="rcFrequency"
                                    name="rcFrequency"
                                    maxLength={9}
                                    placeholder="Enter Frequency"
                                    value={formik?.values?.rcFrequency || ''}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('rcFrequency') }, 'Input_Number')}
                                />
                                {getFormErrorMessage('rcFrequency')}
                            </div>
                        </div>
                    )}

                    {formik?.values?.rewardTypeCode === '20' && (
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-2 pb-2">
                                <div className="flex">
                                    <div className="col-8">
                                        <label htmlFor="glId" className={classNames({ 'p-error': isFormFieldValid('glId') }, 'Label__Text')}>
                                            GL Id<span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="glId"
                                            disabled
                                            placeholder="Enter GL Id"
                                            name="glId"
                                            value={formik?.values?.glId || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('glId') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('glId')}
                                    </div>
                                    <div className="col-4 Fetch__Btn">
                                        <Button
                                            disabled
                                            label="Fetch"
                                            className="Btn__Fetch"
                                            // onClick={(e) => {
                                            //     e.preventDefault();
                                            //     getGlId();
                                            // }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="glName" className={classNames({ 'p-error': isFormFieldValid('glName') }, 'Label__Text')}>
                                        GL Name
                                    </label>
                                    <InputText
                                        id="glName"
                                        placeholder="Enter GL Name"
                                        name="glName"
                                        disabled={true}
                                        // value={glIdData?.accountTitle || ''}
                                        value={formik?.values?.glName}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('glName') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('glName')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="availableBalance" className={classNames({ 'p-error': isFormFieldValid('availableBalance') }, 'Label__Text')}>
                                        Available Balance
                                    </label>
                                </div>
                                <InputNumber
                                    inputId="availableBalance"
                                    name="availableBalance"
                                    maxLength={9}
                                    placeholder="Enter Available Balance"
                                    disabled={true}
                                    // value={glIdData?.actualBalance || ''}
                                    value={formik?.values?.availableBalance}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('availableBalance') }, 'Input_Number')}
                                />
                                {getFormErrorMessage('availableBalance')}
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="cashBackType" className={classNames({ 'p-error': isFormFieldValid('cashBackType') }, 'Label__Text')}>
                                        Discount Type
                                    </label>
                                    <Dropdown
                                        disabled
                                        id="cashBackType"
                                        placeholder="Select Cashback Type"
                                        options={discountTypeLov}
                                        optionLabel="name"
                                        name="cashBackType"
                                        optionValue="lovId"
                                        value={formik?.values?.cashBackType || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cashBackType') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('cashBackType')}
                                </div>
                            </div>
                            {formik?.values?.cashBackType === 'F' && (
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="rcAmount" className={classNames({ 'p-error': isFormFieldValid('rcAmount') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                    </div>
                                    <InputNumber
                                        disabled
                                        inputId="rcAmount"
                                        name="rcAmount"
                                        maxLength={9}
                                        placeholder="Enter Amount"
                                        value={formik?.values?.rcAmount || ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('rcAmount') }, 'Input_Number')}
                                    />
                                    {getFormErrorMessage('rcAmount')}
                                </div>
                            )}
                            {formik?.values?.cashBackType === 'P' && (
                                <>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="rcAmount" className={classNames({ 'p-error': isFormFieldValid('rcAmount') }, 'Label__Text')}>
                                                Discount Percentage
                                            </label>
                                        </div>
                                        <InputNumber
                                            disabled
                                            inputId="rcAmount"
                                            name="rcAmount"
                                            maxLength={9}
                                            placeholder="Enter Amount"
                                            value={formik?.values?.rcAmount || ''}
                                            onValueChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rcAmount') }, 'Input_Number')}
                                        />
                                        {getFormErrorMessage('rcAmount')}
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="rcMaxDiscountAmount" className={classNames({ 'p-error': isFormFieldValid('rcMaxDiscountAmount') }, 'Label__Text')}>
                                                Maximum Discount Amount
                                            </label>
                                            <InputText
                                                disabled
                                                id="rcMaxDiscountAmount"
                                                placeholder="Enter Maximum Discount Amount"
                                                name="rcMaxDiscountAmount"
                                                value={formik?.values?.rcMaxDiscountAmount || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('rcMaxDiscountAmount') }, 'Input__Round')}
                                            />
                                            {getFormErrorMessage('rcMaxDiscountAmount')}
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="rcFrequencyPeriodCode" className={classNames({ 'p-error': isFormFieldValid('rcFrequencyPeriodCode') }, 'Label__Text')}>
                                        Frequency Period
                                    </label>
                                    <Dropdown
                                        disabled
                                        id="rcFrequencyPeriodCode"
                                        placeholder="Select Frequency Period"
                                        options={frequencyPeriodLov}
                                        optionLabel="name"
                                        name="rcFrequencyPeriodCode"
                                        optionValue="code"
                                        value={formik?.values?.rcFrequencyPeriodCode || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('rcFrequencyPeriodCode') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('rcFrequencyPeriodCode')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="rcFrequency" className={classNames({ 'p-error': isFormFieldValid('rcFrequency') }, 'Label__Text')}>
                                        Frequency
                                    </label>
                                </div>
                                <InputNumber
                                    disabled
                                    inputId="rcFrequency"
                                    name="rcFrequency"
                                    maxLength={9}
                                    placeholder="Enter Frequency"
                                    value={formik?.values?.rcFrequency || ''}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('rcFrequency') }, 'Input_Number')}
                                />
                                {getFormErrorMessage('rcFrequency')}
                            </div>
                        </div>
                    )}
                    {formik?.values?.rewardTypeCode === '30' && (
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="rcPromoDisc" className={classNames({ 'p-error': isFormFieldValid('rcPromoDisc') }, 'Label__Text')}>
                                        Description
                                    </label>
                                    <InputText
                                        disabled
                                        id="rcPromoDisc"
                                        placeholder="Enter Description"
                                        name="rcPromoDisc"
                                        value={formik?.values?.rcPromoDisc || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('rcPromoDisc') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('rcPromoDisc')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="cashBackType" className={classNames({ 'p-error': isFormFieldValid('cashBackType') }, 'Label__Text')}>
                                        Discount Type
                                    </label>
                                    <Dropdown
                                        disabled
                                        id="cashBackType"
                                        placeholder="Select Discount Type"
                                        options={discountTypeLov}
                                        optionLabel="name"
                                        name="cashBackType"
                                        optionValue="lovId"
                                        value={formik?.values?.cashBackType || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cashBackType') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('cashBackType')}
                                </div>
                            </div>
                            {formik?.values?.cashBackType === 'F' && (
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="rcAmount" className={classNames({ 'p-error': isFormFieldValid('rcAmount') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                    </div>
                                    <InputNumber
                                        disabled
                                        inputId="rcAmount"
                                        name="rcAmount"
                                        maxLength={9}
                                        placeholder="Enter Amount"
                                        value={formik?.values?.rcAmount || ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('rcAmount') }, 'Input_Number')}
                                    />
                                    {getFormErrorMessage('rcAmount')}
                                </div>
                            )}
                            {formik?.values?.cashBackType === 'P' && (
                                <>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="rcAmount" className={classNames({ 'p-error': isFormFieldValid('rcAmount') }, 'Label__Text')}>
                                                Discount Percentage
                                            </label>
                                        </div>
                                        <InputNumber
                                            disabled
                                            inputId="rcAmount"
                                            name="rcAmount"
                                            maxLength={9}
                                            placeholder="Enter Amount"
                                            value={formik?.values?.rcAmount || ''}
                                            onValueChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rcAmount') }, 'Input_Number')}
                                        />
                                        {getFormErrorMessage('rcAmount')}
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="rcMaxDiscountAmount" className={classNames({ 'p-error': isFormFieldValid('rcMaxDiscountAmount') }, 'Label__Text')}>
                                                Maximum Discount Amount
                                            </label>
                                            <InputText
                                                disabled
                                                id="rcMaxDiscountAmount"
                                                placeholder="Enter Maximum Discount Amount"
                                                name="rcMaxDiscountAmount"
                                                value={formik?.values?.rcMaxDiscountAmount || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('rcMaxDiscountAmount') }, 'Input__Round')}
                                            />
                                            {getFormErrorMessage('rcMaxDiscountAmount')}
                                        </div>
                                    </div>
                                </>
                            )}
                            {promoCode && (
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="promoCode" className={classNames({ 'p-error': isFormFieldValid('promoCode') }, 'Label__Text')}>
                                            Promo Code
                                        </label>
                                        <InputText id="promoCode" name="promoCode" disabled={true} value={promoCode || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('promoCode') }, 'Input__Round')} />
                                        {getFormErrorMessage('promoCode')}
                                    </div>
                                </div>
                            )}
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="">
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            generatePromoCode();
                                        }}
                                        disabled
                                        // disabled={promoCode ? true : false}
                                        label="Generate Promo Code"
                                        className="Btn__PromoCode"
                                    />
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="rcFrequencyPeriodCode" className={classNames({ 'p-error': isFormFieldValid('rcFrequencyPeriodCode') }, 'Label__Text')}>
                                        Frequency Period
                                    </label>
                                    <Dropdown
                                        disabled
                                        id="rcFrequencyPeriodCode"
                                        placeholder="Select Frequency Period"
                                        options={frequencyPeriodLov}
                                        optionLabel="name"
                                        name="rcFrequencyPeriodCode"
                                        optionValue="code"
                                        value={formik?.values?.rcFrequencyPeriodCode || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('rcFrequencyPeriodCode') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('rcFrequencyPeriodCode')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="rcFrequency" className={classNames({ 'p-error': isFormFieldValid('rcFrequency') }, 'Label__Text')}>
                                        Frequency
                                    </label>
                                </div>
                                <InputNumber
                                    disabled
                                    inputId="rcFrequency"
                                    name="rcFrequency"
                                    maxLength={9}
                                    placeholder="Enter Frequency"
                                    value={formik?.values?.rcFrequency || ''}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('rcFrequency') }, 'Input_Number')}
                                />
                                {getFormErrorMessage('rcFrequency')}
                            </div>
                        </div>
                    )}
                    {formik?.values?.rewardTypeCode === '40' && (
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="rcIncentives" className={classNames({ 'p-error': isFormFieldValid('rcIncentives') }, 'Label__Text')}>
                                        Select Incentive <span className="Label__Required">*</span>
                                    </label>
                                    <MultiSelect
                                        id="rcIncentives"
                                        placeholder="Select Incentive"
                                        options={incentiveTypeLov}
                                        optionLabel="name"
                                        name="rcIncentives"
                                        optionValue="lovId"
                                        // panelFooterTemplate={panelTransactionsTypeFooterTemplate}
                                        // value={formik?.values?.rcIncentives.map((trans) => trans.lovId || '')}
                                        // onChange={(e) => {
                                        //     formik.setFieldValue(
                                        //         'rcIncentives',
                                        //         e.value.map((lovId) => ({
                                        //             lovId
                                        //         }))
                                        //     );
                                        // }}
                                        value={formik?.values?.rcIncentives || ''}
                                        // onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('rcIncentives') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('rcIncentives')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="rcAmount" className={classNames({ 'p-error': isFormFieldValid('rcAmount') }, 'Label__Text')}>
                                        Amount
                                    </label>
                                </div>
                                <InputNumber
                                    disabled
                                    inputId="rcAmount"
                                    name="rcAmount"
                                    maxLength={9}
                                    placeholder="Enter Amount"
                                    value={formik?.values?.rcAmount || ''}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('rcAmount') }, 'Input_Number')}
                                />
                                {getFormErrorMessage('rcAmount')}
                            </div>
                            {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="rcIncentiveCode" className={classNames({ 'p-error': isFormFieldValid('rcIncentiveCode') }, 'Label__Text')}>
                                            Select Incentive
                                        </label>
                                        <Dropdown
                                            id="rcIncentiveCode"
                                            placeholder="Select Incentive"
                                            options={incentiveTypeLov}
                                            optionLabel="name"
                                            name="rcIncentiveCode"
                                            optionValue="code"
                                            value={formik?.values?.rcIncentiveCode || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rcIncentiveCode') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('rcIncentiveCode')}
                                    </div>
                                </div> */}

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="rcFrequencyPeriodCode" className={classNames({ 'p-error': isFormFieldValid('rcFrequencyPeriodCode') }, 'Label__Text')}>
                                        Frequency Period
                                    </label>
                                    <Dropdown
                                        disabled
                                        id="rcFrequencyPeriodCode"
                                        placeholder="Select Frequency Period"
                                        options={frequencyPeriodLov}
                                        optionLabel="name"
                                        name="rcFrequencyPeriodCode"
                                        optionValue="code"
                                        value={formik?.values?.rcFrequencyPeriodCode || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('rcFrequencyPeriodCode') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('rcFrequencyPeriodCode')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="rcFrequency" className={classNames({ 'p-error': isFormFieldValid('rcFrequency') }, 'Label__Text')}>
                                        Frequency
                                    </label>
                                </div>
                                <InputNumber
                                    disabled
                                    inputId="rcFrequency"
                                    name="rcFrequency"
                                    maxLength={9}
                                    placeholder="Enter Frequency"
                                    value={formik?.values?.rcFrequency || ''}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('rcFrequency') }, 'Input_Number')}
                                />
                                {getFormErrorMessage('rcFrequency')}
                            </div>
                        </div>
                    )}
                    {formik?.values?.rewardTypeCode === '50' && (
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-2 pb-2">
                                <div className="flex">
                                    <div className="col-8">
                                        <label htmlFor="glId" className={classNames({ 'p-error': isFormFieldValid('glId') }, 'Label__Text')}>
                                            GL Id<span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            disabled
                                            id="glId"
                                            placeholder="Enter GL Id"
                                            name="glId"
                                            value={formik?.values?.glId || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('glId') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('glId')}
                                    </div>
                                    <div className="col-4 Fetch__Btn">
                                        <Button
                                            disabled={loadingCashback}
                                            icon={loadingIconCashback || ''}
                                            label="Fetch"
                                            className="Btn__Fetch"
                                            // onClick={(e) => {
                                            //     e.preventDefault();
                                            //     getGlId();
                                            // }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="glName" className={classNames({ 'p-error': isFormFieldValid('glName') }, 'Label__Text')}>
                                        GL Name
                                    </label>
                                    <InputText
                                        id="glName"
                                        placeholder="Enter GL Name"
                                        name="glName"
                                        disabled={true}
                                        // value={glIdData?.accountTitle || ''}
                                        value={formik?.values?.glName}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('glName') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('glName')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="availableBalance" className={classNames({ 'p-error': isFormFieldValid('availableBalance') }, 'Label__Text')}>
                                        Available Balance
                                    </label>
                                </div>
                                <InputNumber
                                    inputId="availableBalance"
                                    name="availableBalance"
                                    maxLength={9}
                                    placeholder="Enter Available Balanace"
                                    disabled={true}
                                    // value={glIdData?.actualBalance || ''}
                                    value={formik?.values?.availableBalance}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('availableBalance') }, 'Input_Number')}
                                />
                                {getFormErrorMessage('availableBalance')}
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="voucherTitle" className={classNames({ 'p-error': isFormFieldValid('voucherTitle') }, 'Label__Text')}>
                                        Voucher Title
                                    </label>
                                    <InputText
                                        disabled
                                        id="voucherTitle"
                                        placeholder="Enter Voucher Title"
                                        name="voucherTitle"
                                        value={formik?.values?.voucherTitle || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('voucherTitle') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('voucherTitle')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="expiryDateTime" className={classNames({ 'p-error': isFormFieldValid('expiryDateTime') }, 'Label__Text')}>
                                        Expiry Date & Time <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        disabled
                                        id="expiryDateTime"
                                        placeholder="Enter Campaign Start Date & Time"
                                        name="expiryDateTime"
                                        type="datetime-local"
                                        min={getCurrentDateTime()}
                                        value={formik?.values?.expiryDateTime || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('expiryDateTime') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('expiryDateTime')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="voucherDescription" className={classNames({ 'p-error': isFormFieldValid('voucherDescription') }, 'Label__Text')}>
                                        Voucher Description
                                    </label>
                                    <InputText
                                        disabled
                                        id="voucherDescription"
                                        placeholder="Enter Voucher Description"
                                        name="voucherDescription"
                                        value={formik?.values?.voucherDescription || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('voucherDescription') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('voucherDescription')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="cashBackType" className={classNames({ 'p-error': isFormFieldValid('cashBackType') }, 'Label__Text')}>
                                        Discount Type<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        disabled
                                        id="cashBackType"
                                        placeholder="Select Discount Type"
                                        options={cashbackTypeLov}
                                        optionLabel="name"
                                        name="cashBackType"
                                        optionValue="lovId"
                                        value={formik?.values?.cashBackType || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cashBackType') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('cashBackType')}
                                </div>
                            </div>
                            {formik?.values?.cashBackType === 'F' && (
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="rcAmount" className={classNames({ 'p-error': isFormFieldValid('rcAmount') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                    </div>
                                    <InputNumber
                                        disabled
                                        inputId="rcAmount"
                                        name="rcAmount"
                                        maxLength={9}
                                        placeholder="Enter Amount"
                                        value={formik?.values?.rcAmount || ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('rcAmount') }, 'Input_Number')}
                                    />
                                    {getFormErrorMessage('rcAmount')}
                                </div>
                            )}
                            {formik?.values?.cashBackType === 'P' && (
                                <>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="rcAmount" className={classNames({ 'p-error': isFormFieldValid('rcAmount') }, 'Label__Text')}>
                                                Discount Percentage
                                            </label>
                                        </div>
                                        <InputNumber
                                            disabled
                                            inputId="rcAmount"
                                            name="rcAmount"
                                            maxLength={9}
                                            placeholder="Enter Amount"
                                            value={formik?.values?.rcAmount || ''}
                                            onValueChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rcAmount') }, 'Input_Number')}
                                        />
                                        {getFormErrorMessage('rcAmount')}
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="rcMaxDiscountAmount" className={classNames({ 'p-error': isFormFieldValid('rcMaxDiscountAmount') }, 'Label__Text')}>
                                                Maximum Discount Amount
                                            </label>
                                            <InputText
                                                disabled
                                                id="rcMaxDiscountAmount"
                                                placeholder="Enter Maximum Discount Amount"
                                                name="rcMaxDiscountAmount"
                                                value={formik?.values?.rcMaxDiscountAmount || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('rcMaxDiscountAmount') }, 'Input__Round')}
                                            />
                                            {getFormErrorMessage('rcMaxDiscountAmount')}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                    {formik?.values?.rcFrequencyPeriodCode === '10' && (
                        <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card flex">
                            <div className="p-fluid p-formgrid grid mb-5 w-100">
                                {formik?.values?.frequencyRcTimeList?.map((startEndTimeDaily, index) => {
                                    return (
                                        <div className="col-12 md:col-6 flex-direction-col md:flex" key={`rcd-${index}`}>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor={`frequencyRcTimeList[${index}].startTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyRcTimeList[${index}].startTime`) }, 'Label__Text')}>
                                                        Start Time
                                                    </label>
                                                    <InputText
                                                        disabled
                                                        id={`frequencyRcTimeList[${index}].startTime`}
                                                        type="time"
                                                        name={`frequencyRcTimeList[${index}].startTime`}
                                                        value={startEndTimeDaily?.startTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`frequencyRcTimeList[${index}].startTime`) }, 'Input__Round')}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    {getFormErrorMessage(`frequencyRcTimeList[${index}].startTime`)}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor={`frequencyRcTimeList[${index}].endTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyRcTimeList[${index}].endTime`) }, 'Label__Text')}>
                                                        End Time
                                                    </label>
                                                    <InputText
                                                        disabled
                                                        id={`frequencyRcTimeList[${index}].endTime`}
                                                        type="time"
                                                        name={`frequencyRcTimeList[${index}].endTime`}
                                                        value={startEndTimeDaily?.endTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`frequencyRcTimeList[${index}].endTime`) }, 'Input__Round')}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    {getFormErrorMessage(`frequencyRcTimeList[${index}].endTime`)}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-2 flex align-items-center pt-3 pb-3">
                                                <Button
                                                    disabled
                                                    icon="pi pi-plus"
                                                    className="p-button-rounded mt-2"
                                                    aria-label="Filter"
                                                    tooltip="Add New Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addfrequencyRcTimeList();
                                                    }}
                                                />
                                                <Button
                                                    disabled
                                                    icon="pi pi-minus"
                                                    className="p-button-rounded mt-2 p-button-danger"
                                                    aria-label="Filter"
                                                    tooltip="Remove Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        removefrequencyRcTimeList(index);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    {formik?.values?.rcFrequencyPeriodCode === '20' && (
                        <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card flex">
                            <div className="p-fluid p-formgrid grid mb-5 w-100">
                                {formik?.values?.frequencyRcTimeList?.map((startEndTimeWeekly, index) => {
                                    return (
                                        <div className="col-12 md:col-6 flex-direction-col md:flex" key={`rcw-${index}`}>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor={`frequencyRcTimeList[${index}].startTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyRcTimeList[${index}].startTime`) }, 'Label__Text')}>
                                                        Start Time
                                                    </label>
                                                    <InputText
                                                        disabled
                                                        id={`frequencyRcTimeList[${index}].startTime`}
                                                        type="time"
                                                        name={`frequencyRcTimeList[${index}].startTime`}
                                                        value={startEndTimeWeekly?.startTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`frequencyRcTimeList[${index}].startTime`) }, 'Input__Round')}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    {getFormErrorMessage(`frequencyRcTimeList[${index}].startTime`)}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor={`frequencyRcTimeList[${index}].endTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyRcTimeList[${index}].endTime`) }, 'Label__Text')}>
                                                        End Time
                                                    </label>
                                                    <InputText
                                                        disabled
                                                        id={`frequencyRcTimeList[${index}].endTime`}
                                                        type="time"
                                                        name={`frequencyRcTimeList[${index}].endTime`}
                                                        value={startEndTimeWeekly?.endTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`frequencyRcTimeList[${index}].endTime`) }, 'Input__Round')}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    {getFormErrorMessage(`frequencyRcTimeList[${index}].endTime`)}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-2 flex align-items-center pt-3 pb-3">
                                                <Button
                                                    disabled
                                                    icon="pi pi-plus"
                                                    className="p-button-rounded mt-2"
                                                    aria-label="Filter"
                                                    tooltip="Add New Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addfrequencyRcTimeList();
                                                    }}
                                                />
                                                <Button
                                                    disabled
                                                    icon="pi pi-minus"
                                                    className="p-button-rounded mt-2 p-button-danger"
                                                    aria-label="Filter"
                                                    tooltip="Remove Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        removefrequencyRcTimeList(index);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="rcFrequencyDay" className={classNames({ 'p-error': isFormFieldValid('rcFrequencyDay') }, 'Label__Text')}>
                                            Days
                                        </label>
                                        <MultiSelect
                                            id="rcFrequencyDay"
                                            placeholder="Select Days"
                                            options={weekDays}
                                            optionLabel="name"
                                            name="rcFrequencyDay"
                                            optionValue="lovId"
                                            value={formik.values.rcFrequencyDay || ''}
                                            // onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rcFrequencyDay') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('rcFrequencyDay')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {formik?.values?.rcFrequencyPeriodCode === '30' && (
                        <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card flex">
                            <div className="p-fluid p-formgrid grid mb-5 w-100">
                                {formik?.values?.frequencyRcTimeList?.map((startEndTimeMonthly, index) => {
                                    return (
                                        <div className="col-12 md:col-6 flex-direction-col md:flex" key={`rcm-${index}`}>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor={`frequencyRcTimeList[${index}].startTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyRcTimeList[${index}].startTime`) }, 'Label__Text')}>
                                                        Start Time
                                                    </label>
                                                    <InputText
                                                        disabled
                                                        id={`frequencyRcTimeList[${index}].startTime`}
                                                        type="time"
                                                        name={`frequencyRcTimeList[${index}].startTime`}
                                                        value={startEndTimeMonthly?.startTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`frequencyRcTimeList[${index}].startTime`) }, 'Input__Round')}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    {getFormErrorMessage(`frequencyRcTimeList[${index}].startTime`)}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor={`frequencyRcTimeList[${index}].endTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyRcTimeList[${index}].endTime`) }, 'Label__Text')}>
                                                        End Time
                                                    </label>
                                                    <InputText
                                                        disabled
                                                        id={`frequencyRcTimeList[${index}].endTime`}
                                                        type="time"
                                                        name={`frequencyRcTimeList[${index}].endTime`}
                                                        value={startEndTimeMonthly?.endTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`frequencyRcTimeList[${index}].endTime`) }, 'Input__Round')}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    {getFormErrorMessage(`frequencyRcTimeList[${index}].endTime`)}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-2 flex align-items-center pt-3 pb-3">
                                                <Button
                                                    disabled
                                                    icon="pi pi-plus"
                                                    className="p-button-rounded mt-2"
                                                    aria-label="Filter"
                                                    tooltip="Add New Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addfrequencyRcTimeList();
                                                    }}
                                                />
                                                <Button
                                                    disabled
                                                    icon="pi pi-minus"
                                                    className="p-button-rounded mt-2 p-button-danger"
                                                    aria-label="Filter"
                                                    tooltip="Remove Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        removefrequencyRcTimeList(index);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="rcFrequencyDate" className={classNames({ 'p-error': isFormFieldValid('rcFrequencyDate') }, 'Label__Text')}>
                                            Date Of Month
                                        </label>
                                        <MultiSelect
                                            id="rcFrequencyDate"
                                            placeholder="Select Dates"
                                            options={Dates}
                                            optionLabel="name"
                                            name="rcFrequencyDate"
                                            optionValue="lovId"
                                            value={formik.values.rcFrequencyDate || ''}
                                            // onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rcFrequencyDate') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('rcFrequencyDate')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {formik?.values?.rcFrequencyPeriodCode === '40' && (
                        <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card flex">
                            <div className="p-fluid p-formgrid grid mb-5 w-100">
                                <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="rcFrequencyMonth" className={classNames({ 'p-error': isFormFieldValid('rcFrequencyMonth') }, 'Label__Text')}>
                                            Month
                                        </label>
                                        <MultiSelect
                                            id="rcFrequencyMonth"
                                            placeholder="Select Dates"
                                            options={monthsLov}
                                            optionLabel="name"
                                            name="rcFrequencyMonth"
                                            optionValue="lovId"
                                            value={formik.values.rcFrequencyMonth || ''}
                                            // onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rcFrequencyMonth') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('rcFrequencyMonth')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="rcFrequencyDate" className={classNames({ 'p-error': isFormFieldValid('rcFrequencyDate') }, 'Label__Text')}>
                                            Date Of Month
                                        </label>
                                        <MultiSelect
                                            id="rcFrequencyDate"
                                            placeholder="Select Dates"
                                            options={Dates}
                                            optionLabel="name"
                                            name="rcFrequencyDate"
                                            optionValue="lovId"
                                            value={formik.values.rcFrequencyDate || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rcFrequencyDate') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('rcFrequencyDate')}
                                    </div>
                                </div>
                                {formik?.values?.frequencyRcTimeList?.map((startEndTimeMonthly, index) => {
                                    return (
                                        <div className="col-12 md:col-4 flex-direction-col md:flex" key={`rcy-${index}`}>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor={`frequencyRcTimeList[${index}].startTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyRcTimeList[${index}].startTime`) }, 'Label__Text')}>
                                                        Start Time
                                                    </label>
                                                    <InputText
                                                        disabled
                                                        id={`frequencyRcTimeList[${index}].startTime`}
                                                        type="time"
                                                        name={`frequencyRcTimeList[${index}].startTime`}
                                                        value={startEndTimeMonthly?.startTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`frequencyRcTimeList[${index}].startTime`) }, 'Input__Round')}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    {getFormErrorMessage(`frequencyRcTimeList[${index}].startTime`)}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor={`frequencyRcTimeList[${index}].endTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyRcTimeList[${index}].endTime`) }, 'Label__Text')}>
                                                        End Time
                                                    </label>
                                                    <InputText
                                                        disabled
                                                        id={`frequencyRcTimeList[${index}].endTime`}
                                                        type="time"
                                                        name={`frequencyRcTimeList[${index}].endTime`}
                                                        value={startEndTimeMonthly?.endTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`frequencyRcTimeList[${index}].endTime`) }, 'Input__Round')}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    {getFormErrorMessage(`frequencyRcTimeList[${index}].endTime`)}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4 flex align-items-center justify-content-evenly pt-3 pb-3">
                                                <Button
                                                    disabled
                                                    icon="pi pi-plus"
                                                    className="p-button-rounded mt-2"
                                                    aria-label="Filter"
                                                    tooltip="Add New Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addfrequencyRcTimeList();
                                                    }}
                                                />
                                                <Button
                                                    disabled
                                                    icon="pi pi-minus"
                                                    className="p-button-rounded mt-2 p-button-danger"
                                                    aria-label="Filter"
                                                    tooltip="Remove Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        removefrequencyRcTimeList(index);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </form>
            </div>
        </>
    );
}
export default UpdateRewardConfigurationChecker;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewSalesForce() {
    const [kamData, setKamData] = useState([]);
    const [salesStructureData, setSalesStructureData] = useState([]);
    const navigate = useNavigate();
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const id = query.get('id');

    const formik = useFormik({
        initialValues: {
            salesStructureId: '',
            salesRoleDetailId: '',
            employeeName: '',
            employeeId: '',
            essDepartment: '',
            employeeCnic: '',
            employeeMobileNo: '',
            employeeEmail: ''
        }
    });

    const getForceData = async () => {
        const res = await handleGetRequest(`/account/v1/sales/getSalesForceById/${id}`);
        const keyData = res?.payLoad;
        if (keyData) {
            formik.setFieldValue('employeeId', keyData?.employeeId);
            formik.setFieldValue('salesRoleDetailId', keyData?.salesRoleDetailId.toString());
            formik.setFieldValue('employeeName', keyData?.employeeName);
            formik.setFieldValue('essDepartment', keyData?.essDepartment);
            formik.setFieldValue('employeeCnic', keyData?.employeeCnic);
            formik.setFieldValue('employeeMobileNo', keyData?.employeeMobileNo);
            formik.setFieldValue('employeeEmail', keyData?.employeeEmail);
            formik.setFieldValue('salesStructureId', keyData?.salesStructureId.toString());
        }
    };

    useEffect(() => {
        if (id) {
            getForceData();
        }
    }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

    const getSaleStructureData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getSaleStructureList/LKP_SALES_STRUCTURE:*');
        setSalesStructureData(res?.payLoad);
    };
    const getKamData = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getEmployeeRoleList/${formik.values?.salesStructureId}`);
        const filteredList = resp?.payLoad.filter((item) => item.isActive === 'Y');
        setKamData(filteredList);
    };

    useEffect(() => {
        if (formik?.values?.salesStructureId) {
            getKamData();
        }
    }, [formik?.values?.salesStructureId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getSaleStructureData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>VIEW DETAIL</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="salesStructureId" className={classNames({ 'p-error': isFormFieldValid('salesStructureId') }, 'Label__Text')}>
                                    Sale Structure <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="salesStructureId"
                                    placeholder="Select Sale Structure"
                                    options={salesStructureData}
                                    optionLabel="name"
                                    name="salesStructureId"
                                    optionValue="lovId"
                                    disabled
                                    value={formik.values.salesStructureId || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('salesStructureId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('salesStructureId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="salesRoleDetailId" className={classNames({ 'p-error': isFormFieldValid('salesRoleDetailId') }, 'Label__Text')}>
                                    Sales Role <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="salesRoleDetailId"
                                    placeholder="Select Sale Role"
                                    options={kamData}
                                    optionLabel="name"
                                    name="salesRoleDetailId"
                                    optionValue="lovId"
                                    disabled
                                    value={formik.values.salesRoleDetailId || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('salesRoleDetailId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('salesRoleDetailId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="employeeName" className={classNames({ 'p-error': isFormFieldValid('employeeName') }, 'Label__Text')}>
                                    Employee Name <span className="Label__Required">*</span>
                                </label>

                                <InputText id="employeeName" name="employeeName" disabled value={formik?.values?.employeeName} className={classNames({ 'p-invalid': isFormFieldValid('employeeName') }, 'Input__Round')} />

                                {getFormErrorMessage('employeeName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="employeeId" className={classNames({ 'p-error': isFormFieldValid('employeeId') }, 'Label__Text')}>
                                    Employee Id <span className="Label__Required">*</span>
                                </label>

                                <InputText id="employeeId" name="employeeId" disabled value={formik?.values?.employeeId} className={classNames({ 'p-invalid': isFormFieldValid('employeeId') }, 'Input__Round')} />

                                {getFormErrorMessage('employeeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="essDepartment" className={classNames({ 'p-error': isFormFieldValid('essDepartment') }, 'Label__Text')}>
                                    Ess Department <span className="Label__Required">*</span>
                                </label>

                                <InputText id="essDepartment" name="essDepartment" disabled value={formik?.values?.essDepartment} className={classNames({ 'p-invalid': isFormFieldValid('essDepartment') }, 'Input__Round')} />

                                {getFormErrorMessage('essDepartment')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="employeeCnic" className={classNames({ 'p-error': isFormFieldValid('employeeCnic') }, 'Label__Text')}>
                                    Employee CNIC Number <span className="Label__Required">*</span>
                                </label>

                                <InputText id="employeeCnic" name="employeeCnic" disabled value={formik?.values?.employeeCnic} className={classNames({ 'p-invalid': isFormFieldValid('employeeCnic') }, 'Input__Round')} />

                                {getFormErrorMessage('employeeCnic')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="employeeMobileNo" className={classNames({ 'p-error': isFormFieldValid('employeeMobileNo') }, 'Label__Text')}>
                                    Employee Mobile Number <span className="Label__Required">*</span>
                                </label>

                                <InputText id="employeeMobileNo" name="employeeMobileNo" disabled value={formik?.values?.employeeMobileNo} className={classNames({ 'p-invalid': isFormFieldValid('employeeMobileNo') }, 'Input__Round')} />

                                {getFormErrorMessage('employeeMobileNo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="employeeEmail" className={classNames({ 'p-error': isFormFieldValid('employeeEmail') }, 'Label__Text')}>
                                    Employee Email Address <span className="Label__Required">*</span>
                                </label>

                                <InputText id="employeeEmail" name="employeeEmail" disabled value={formik?.values?.employeeEmail} className={classNames({ 'p-invalid': isFormFieldValid('employeeEmail') }, 'Input__Round')} />

                                {getFormErrorMessage('employeeEmail')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button onClick={() => navigate('/createsalesforce')} label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={id} tableName={'TBL_FORCE'} />
        </>
    );
}
export default ViewSalesForce;

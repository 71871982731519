import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { Checkbox } from 'primereact/checkbox';

const MrpOldNewValues = ({ mrpRowData }) => {
    const [mcReqData, setMcReqData] = useState([]);

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const requestTypeDescr = query.get('requestTypeDescr');

    const formik = useFormik({
        initialValues: {
            lkpMrpCodeName: '',
            lkpMrpCodeCode: '',
            lkpMrpCodeDescription: '',
            coaCode: '',
            action: '',
            checkerComments: ''
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getCostCenterById = async () => {
        if (mrpRowData !== null && typeof mrpRowData !== 'undefined') {
            Object.keys(mrpRowData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, mrpRowData[key]);
                }
            });
            formik.setFieldValue('lkpMrpCodeName', mrpRowData?.mrpCodeName);
            formik.setFieldValue('lkpMrpCodeCode', mrpRowData?.mrpCodeCode);
            formik.setFieldValue('lkpMrpCodeDescription', mrpRowData?.mrpCodeDescr);
            formik.setFieldValue('coaCode', mrpRowData?.coaCode);
        }
    };

    useEffect(() => {
        getCostCenterById();
    }, [mrpRowData]); // eslint-disable-line

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>Old Details</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">MRP Name</label>
                                <InputText
                                    id="lkpMrpCodeName"
                                    value={formik.values.lkpMrpCodeName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpMrpCodeName') }, 'Input__Round')}
                                    maxLength={30}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpMrpCodeName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">MRP Code</label>
                                <InputText
                                    id="lkpMrpCodeCode"
                                    value={formik.values.lkpMrpCodeCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpMrpCodeCode') }, 'Input__Round')}
                                    maxLength={30}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpMrpCodeCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">MRP Description</label>
                                <InputText
                                    id="lkpMrpCodeDescription"
                                    value={formik.values.lkpMrpCodeDescription || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpMrpCodeDescription') }, 'Input__Round')}
                                    maxLength={199}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpMrpCodeDescription')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Coa Code</label>
                                <InputText id="coaCode" value={formik.values.coaCode || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('coaCode') }, 'Input__Round')} maxLength={30} disabled={true} />
                                {getFormErrorMessage('coaCode')}
                            </div>
                        </div>
                        {requestType === 'U' && requestTypeDescr === 'Enable/Disable Request' && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label className="Label__Text">Is Active</label>
                                    <Checkbox checked={mcReqData?.isActive === 'Y'} disabled className="w-full" />
                                </div>
                            </div>
                        )}
                    </div>
                </form>
            </div>
        </>
    );
};

export default MrpOldNewValues;

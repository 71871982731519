import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import ChartOfAccountOldNewValues from './ChartOfAccountOldNewValues';
import { Sidebar } from 'primereact/sidebar';

const EditCheckerChartofAccounts = () => {
    const [statusLovData, setStatusLovData] = useState([]);
    const [organizationLov, setOrganizationLov] = useState([]);
    const [locationLov, setLocationLov] = useState([]);
    const [costCenterLov, setCostCenterLov] = useState([]);
    const [categoryLov, setCategoryLov] = useState([]);
    const [subCategoryLov, setSubCategoryLov] = useState([]);
    const [currencyLov, setCurrencyLov] = useState([]);
    const [mrpLov, setMrpLov] = useState([]);
    const [mcReqData, setMcReqData] = useState([]);
    const [createData, setCreateData] = useState([]);

    const [oldNewData, setOldNewData] = useState([]);
    const [highlightedKeys, setHighlightedKeys] = useState([]);
    const [chartOfAccountRowData, setChartOfAccountRowData] = useState();
    const [displayDialog, setDisplayDialog] = useState(false);

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const tableName = query.get('tableName');
    const requestTypeDescr = query.get('requestTypeDescr');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('Action should not be empty'),
        checkerComments: Yup.string().required('This Field is required').notOneOf([''], 'This Field should not be empty').max(500, 'Maximum 500 characters needed')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            lkpOrganization: '',
            lkpBranch: '',
            lkpCostCenter: '',
            lkpGlType: '',
            lkpSubCategory: '',
            lkpCurrency: '',
            lkpMrpCode: ''
        },

        onSubmit: async (data) => {
            const coaPayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        chartOfAccountRequest:
                            requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request'
                                ? mcReqData
                                : requestType === 'U' && requestTypeDescr === 'Enable/Disable Request'
                                ? { isActive: mcReqData?.isActive, glCodeCombinationID: refTableId }
                                : { glCodeCombinationID: refTableId, isActive: 'Y' },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: 1
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(coaPayload, '/account/v1/chartofaccount/chartofaccountcheckeraction', true, false));
            if (res?.responseCode === '010000') {
                navigate(-1);
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    // Segment LOVs

    const getOrganizationLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_ORGANIZATION');
        setOrganizationLov(res?.payLoad);
    };

    const getLocationLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_BRANCH');
        setLocationLov(res?.payLoad);
    };

    const getCostCenterLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_COST_CENTER');
        setCostCenterLov(res?.payLoad);
    };

    const getCategoryLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_GL_TYPE');
        setCategoryLov(res?.payLoad);
    };

    const getSubCategoryLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_SUB_CATEGORY');
        setSubCategoryLov(res?.payLoad);
    };

    const getCurrencyLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_CURRENCY');
        setCurrencyLov(res?.payLoad);
    };

    const getMrpLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_MRP_CODE');
        setMrpLov(res?.payLoad);
    };

    const getCostCenterById = async () => {
        if (refTableId) {
            const resp = await handleGetRequest(`/account/v1/chartofaccount/getchartofaccountbyid/${refTableId}`);
            const keyData = resp?.payLoad;
            if (keyData !== null && typeof keyData !== 'undefined') {
                setCreateData(keyData);
            }
        }
    };

    const getCostCenterMcRequestDataById = async () => {
        if (mcRequestId) {
            const resp = await handleGetRequest(`/account/v1/chartofaccount/getchartofaccountbymcrequestid/${mcRequestId}`);
            const keyData = resp?.payLoad;
            if (keyData !== null && typeof keyData !== 'undefined') {
                setMcReqData(keyData);
            }
        }
    };

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = res?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        getStatusListData();
        getOrganizationLov();
        getLocationLov();
        getCostCenterLov();
        getCategoryLov();
        getSubCategoryLov();
        getCurrencyLov();
        getMrpLov();
        if (requestType === 'I') {
            getCostCenterById();
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getCostCenterMcRequestDataById();
        } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
            getCostCenterById();
            getCostCenterMcRequestDataById();
        }
    }, []);

    useEffect(() => {
        if (requestType === 'I') {
            const orgLovId = organizationLov.find((item) => item?.code === createData?.glSegmentCode1);
            formik.setFieldValue('lkpOrganization', orgLovId && orgLovId?.lovId);
            const locLovId = locationLov.find((item) => item?.code === createData?.glSegmentCode2);
            formik.setFieldValue('lkpBranch', locLovId && locLovId?.lovId);
            const costCenterLovId = costCenterLov.find((item) => item?.code === createData?.glSegmentCode3);
            formik.setFieldValue('lkpCostCenter', costCenterLovId && costCenterLovId?.lovId);
            const categoryLovId = categoryLov.find((item) => item?.code === createData?.glSegmentCode4);
            formik.setFieldValue('lkpGlType', categoryLovId && categoryLovId?.lovId);
            const subCategoryLovId = subCategoryLov.find((item) => item?.code === createData?.glSegmentCode5);
            formik.setFieldValue('lkpSubCategory', subCategoryLovId && subCategoryLovId?.lovId);
            const currencyLovId = currencyLov.find((item) => item?.code === createData?.glSegmentCode6);
            formik.setFieldValue('lkpCurrency', currencyLovId && currencyLovId?.lovId);
            const mrpLovId = mrpLov.find((item) => item?.code === createData?.glSegmentCode7);
            formik.setFieldValue('lkpMrpCode', mrpLovId && mrpLovId?.lovId);
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            const orgLovId = organizationLov.find((item) => item?.lovId == mcReqData?.lkpOrganization?.organizationId);
            formik.setFieldValue('lkpOrganization', orgLovId && orgLovId?.lovId);
            const locLovId = locationLov.find((item) => item?.lovId == mcReqData?.lkpBranch?.branchId);
            formik.setFieldValue('lkpBranch', locLovId && locLovId?.lovId);
            const costCenterLovId = costCenterLov.find((item) => item?.lovId == mcReqData?.lkpCostCenter?.costCenterId);
            formik.setFieldValue('lkpCostCenter', costCenterLovId && costCenterLovId?.lovId);
            const categoryLovId = categoryLov.find((item) => item?.lovId == mcReqData?.lkpGlType?.glTypeId);
            formik.setFieldValue('lkpGlType', categoryLovId && categoryLovId?.lovId);
            const subCategoryLovId = subCategoryLov.find((item) => item?.lovId == mcReqData?.lkpSubCategory?.subCategoryId);
            formik.setFieldValue('lkpSubCategory', subCategoryLovId && subCategoryLovId?.lovId);
            const currencyLovId = currencyLov.find((item) => item?.lovId == mcReqData?.lkpCurrency?.currencyId);
            formik.setFieldValue('lkpCurrency', currencyLovId && currencyLovId?.lovId);
            const mrpLovId = mrpLov.find((item) => item?.lovId == mcReqData?.lkpMrpCode?.mrpCodeId);
            formik.setFieldValue('lkpMrpCode', mrpLovId && mrpLovId?.lovId);
        } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
            const orgLovId = organizationLov.find((item) => item?.code === createData?.glSegmentCode1);
            formik.setFieldValue('lkpOrganization', orgLovId && orgLovId?.lovId);
            const locLovId = locationLov.find((item) => item?.code === createData?.glSegmentCode2);
            formik.setFieldValue('lkpBranch', locLovId && locLovId?.lovId);
            const costCenterLovId = costCenterLov.find((item) => item?.code === createData?.glSegmentCode3);
            formik.setFieldValue('lkpCostCenter', costCenterLovId && costCenterLovId?.lovId);
            const categoryLovId = categoryLov.find((item) => item?.code === createData?.glSegmentCode4);
            formik.setFieldValue('lkpGlType', categoryLovId && categoryLovId?.lovId);
            const subCategoryLovId = subCategoryLov.find((item) => item?.code === createData?.glSegmentCode5);
            formik.setFieldValue('lkpSubCategory', subCategoryLovId && subCategoryLovId?.lovId);
            const currencyLovId = currencyLov.find((item) => item?.code === createData?.glSegmentCode6);
            formik.setFieldValue('lkpCurrency', currencyLovId && currencyLovId?.lovId);
            const mrpLovId = mrpLov.find((item) => item?.code === createData?.glSegmentCode7);
            formik.setFieldValue('lkpMrpCode', mrpLovId && mrpLovId?.lovId);
        }
    }, [requestType, requestTypeDescr, createData, mcReqData, organizationLov, locationLov, costCenterLov, categoryLov, subCategoryLov, currencyLov, mrpLov]); // eslint-disable-line

    const getOldNewData = async () => {
        const resp = await handleGetRequest(`/workflow/v1/management/compareValues/${mcRequestId}`);
        setOldNewData(resp?.payLoad);
    };

    useEffect(() => {
        if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getOldNewData();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        const keys = oldNewData?.map((item) => item.key);
        setHighlightedKeys(keys);
    }, [oldNewData]); // eslint-disable-line

    const HandledOldValue = async (e) => {
        e.preventDefault();
        setDisplayDialog(true);
        const resp = await handleGetRequest(`/account/v1/chartofaccount/getchartofaccountbyid/${refTableId}`);
        setChartOfAccountRowData(resp?.payLoad);
    };

    return (
        <>
            <Sidebar header="View Category" position="right" style={{ width: '50vw' }} visible={displayDialog} onHide={() => setDisplayDialog(false)}>
                <ChartOfAccountOldNewValues chartOfAccountRowData={chartOfAccountRowData} />
            </Sidebar>

            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>Edit Details</h2>
                            {requestType === 'U' && <Button label="View Old Details" className="Btn__Dark__OldValue" onClick={HandledOldValue} />}
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className="Label__Text">Organization</label>
                            <Dropdown
                                id="lkpOrganization"
                                placeholder="Select Filter"
                                options={organizationLov}
                                optionLabel="name"
                                optionValue="lovId"
                                value={formik.values.lkpOrganization ?? ''}
                                onChange={formik.handleChange}
                                className={
                                    highlightedKeys?.includes('lkpOrganization')
                                        ? classNames({ 'p-invalid': isFormFieldValid('lkpOrganization') }, 'Dropdown__Round__Highlighted')
                                        : classNames({ 'p-invalid': isFormFieldValid('lkpOrganization') }, 'Dropdown__Round')
                                }
                                disabled={true}
                            />
                            {getFormErrorMessage('lkpOrganization')}
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className="Label__Text">Location</label>
                            <Dropdown
                                id="lkpBranch"
                                placeholder="Select Filter"
                                options={locationLov}
                                optionLabel="name"
                                optionValue="lovId"
                                value={formik.values.lkpBranch ?? ''}
                                onChange={formik.handleChange}
                                className={
                                    highlightedKeys?.includes('lkpBranch') ? classNames({ 'p-invalid': isFormFieldValid('lkpBranch') }, 'Dropdown__Round__Highlighted') : classNames({ 'p-invalid': isFormFieldValid('lkpBranch') }, 'Dropdown__Round')
                                }
                                disabled={true}
                            />
                            {getFormErrorMessage('lkpBranch')}
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className="Label__Text">Cost Center</label>
                            <Dropdown
                                id="lkpCostCenter"
                                placeholder="Select Filter"
                                options={costCenterLov}
                                optionLabel="name"
                                optionValue="lovId"
                                value={formik.values.lkpCostCenter ?? ''}
                                onChange={formik.handleChange}
                                className={
                                    highlightedKeys?.includes('lkpCostCenter')
                                        ? classNames({ 'p-invalid': isFormFieldValid('lkpCostCenter') }, 'Dropdown__Round__Highlighted')
                                        : classNames({ 'p-invalid': isFormFieldValid('lkpCostCenter') }, 'Dropdown__Round')
                                }
                                disabled={true}
                            />
                            {getFormErrorMessage('lkpCostCenter')}
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className="Label__Text">Category</label>
                            <Dropdown
                                id="lkpGlType"
                                placeholder="Select Filter"
                                options={categoryLov}
                                optionLabel="name"
                                optionValue="lovId"
                                value={formik.values.lkpGlType ?? ''}
                                onChange={formik.handleChange}
                                className={
                                    highlightedKeys?.includes('lkpGlType') ? classNames({ 'p-invalid': isFormFieldValid('lkpGlType') }, 'Dropdown__Round__Highlighted') : classNames({ 'p-invalid': isFormFieldValid('lkpGlType') }, 'Dropdown__Round')
                                }
                                disabled={true}
                            />
                            {getFormErrorMessage('lkpGlType')}
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className="Label__Text">Sub Category</label>
                            <Dropdown
                                id="lkpSubCategory"
                                placeholder="Select Filter"
                                options={subCategoryLov}
                                optionLabel="name"
                                optionValue="lovId"
                                value={formik.values.lkpSubCategory ?? ''}
                                onChange={formik.handleChange}
                                className={
                                    highlightedKeys?.includes('lkpSubCategory')
                                        ? classNames({ 'p-invalid': isFormFieldValid('lkpSubCategory') }, 'Dropdown__Round__Highlighted')
                                        : classNames({ 'p-invalid': isFormFieldValid('lkpSubCategory') }, 'Dropdown__Round')
                                }
                                disabled={true}
                            />
                            {getFormErrorMessage('lkpSubCategory')}
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className="Label__Text">Currency</label>
                            <Dropdown
                                id="lkpCurrency"
                                placeholder="Select Filter"
                                options={currencyLov}
                                optionLabel="name"
                                optionValue="lovId"
                                value={formik.values.lkpCurrency ?? ''}
                                onChange={formik.handleChange}
                                className={
                                    highlightedKeys?.includes('lkpCurrency')
                                        ? classNames({ 'p-invalid': isFormFieldValid('lkpCurrency') }, 'Dropdown__Round__Highlighted')
                                        : classNames({ 'p-invalid': isFormFieldValid('lkpCurrency') }, 'Dropdown__Round')
                                }
                                disabled={true}
                            />

                            {getFormErrorMessage('lkpCurrency')}
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className="Label__Text">MRP Code</label>
                            <Dropdown
                                id="lkpMrpCode"
                                placeholder="Select Filter"
                                options={mrpLov}
                                optionLabel="name"
                                optionValue="lovId"
                                value={formik.values.lkpMrpCode ?? ''}
                                onChange={formik.handleChange}
                                className={
                                    highlightedKeys?.includes('lkpMrpCode') ? classNames({ 'p-invalid': isFormFieldValid('lkpMrpCode') }, 'Dropdown__Round__Highlighted') : classNames({ 'p-invalid': isFormFieldValid('lkpMrpCode') }, 'Dropdown__Round')
                                }
                                disabled={true}
                            />
                            {getFormErrorMessage('lkpMrpCode')}
                        </div>

                        {requestType === 'U' && requestTypeDescr === 'Enable/Disable Request' && (
                            <div className="p-field col-12 md:col-3">
                                <div className="p-field">
                                    <label className="Label__Text">Is Active</label>
                                    <Checkbox checked={mcReqData?.isActive === 'Y'} disabled className="w-full" />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="custom-card">
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>

                            <div className="p-field col-12 md:col-8">
                                <div className="p-field">
                                    <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                        Comments
                                        <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        rows={5}
                                        cols={30}
                                        id="checkerComments"
                                        name="checkerComments"
                                        value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'Input__Round')}
                                        maxLength={500}
                                    />

                                    {getFormErrorMessage('checkerComments')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Update" className="Btn__Dark" />
                        <Button onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" type="button" />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
};

export default EditCheckerChartofAccounts;

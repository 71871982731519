import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

const ViewSalesHierarchyChecker = () => {
    const [tableData, setTableData] = useState([]);
    const [saleStructureData, setSaleStructureData] = useState([{}]);
    const [employeeRoleList, setEmployeeRoleList] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [tblSalesForcesData, setTblSalesForcesData] = useState([]);
    const [parentObject, setParentObject] = useState([]);
    const [childObject, setChildObject] = useState([]);
    const [finalArray, setFinalArray] = useState([]);
    const [finalResult, setFinalResult] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        }),
        salesHierarchyId: Yup.string().required('Sales Hierarchy is required'),
        salesStructureId: Yup.string().required('Sales Structure is required'),
        hierarchyName: Yup.string().required('Hierarchy Name is required'),
        action: Yup.string().required('Action is required'),
        checkerComments: Yup.string().required('Comments is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            salesHierarchyId: '',
            salesStructureId: '',
            hierarchyName: '',
            action: '',
            checkerComments: ''
        },

        onSubmit: async (data) => {
            data['isActive'] = data?.isActive ? 'Y' : 'N';
            if (requestTypeDescr !== 'Enable/Disable Request') {
                delete data['isActive'];
            }
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        hierarchyRequest: {
                            salesHierarchyId: formik.values.salesHierarchyId,
                            salesStructureId: formik.values.salesStructureId,
                            hierarchyName: formik.values.hierarchyName,
                            tblSalesHierarchyDetails: tblSalesForcesData
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: ''
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/account/v1/sales/salesHirarchyCheckerAction', true, false, 'requestchecker'));
            if (res?.responseCode === '030000') {
                formik.resetForm();
            }
        }
    });

    const getDatabyId = async () => {
        // getRoleWiseModule
        const resp = await handleGetRequest(`/account/v1/sales/saleHierarchyById/${refTableId}`);
        const keyData = resp?.payLoad;
        setTableData(keyData);
        formik.setFieldValue('salesHierarchyId', keyData?.salesHierarchyId);
        formik.setFieldValue('hierarchyName', keyData?.hierarchyName);
        formik.setFieldValue('salesStructureId', keyData?.salesStructureId.toString());
    };

    const getMcRequestDataById = async () => {
        const resp = await handleGetRequest(`/account/v1/sales/getSalesHierarchyUpdateCheckerById/${mcRequestId}`);
        const keyData = resp?.payLoad;
        setTableData(keyData);
        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
        }
        formik.setFieldValue('salesHierarchyId', keyData?.salesHierarchyId);
        formik.setFieldValue('hierarchyName', keyData?.hierarchyName);
        formik.setFieldValue('salesStructureId', keyData?.salesStructureId.toString());
        formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
    };

    const getSaleStructureData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getsaleshierarchiesdata/LKP_SALES_STRUCTURE:*');
        setSaleStructureData(resp?.payLoad);
    };

    const getEmployeeRoleList = async () => {
        setEmployeeRoleList([]);
        const resp = await handleGetRequest(`/account/v1/sales/getSaleRoleAndSaleForceAgainstSaleStructureId/${formik.values?.salesStructureId}`);
        setEmployeeRoleList(resp?.payLoad);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
        getSaleStructureData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (formik?.values?.salesStructureId) {
            getEmployeeRoleList();
        }
    }, [formik?.values?.salesStructureId]); // eslint-disable-line react-hooks/exhaustive-deps

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        const updatedEmployeeRoleList = finalResult.map((item, key) => {
            const selectedOption = selectedOptions.find((option) => option.fieldKey === key);
            if (selectedOption) {
                item.selectedValue = selectedOption.value;
            }
            return item;
        });

        setFinalResult(updatedEmployeeRoleList);

        const newPayLoad = selectedOptions.map((option) => ({
            salesForceId: option.salesForceId,
            salesRoleDetailId: option.salesRoleDetailId,
            salesHierarchyDetailId: option.salesHierarchyDetailId
        }));

        setTblSalesForcesData(newPayLoad);
    }, [selectedOptions]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOptionChange = (key, selectedValue, salesForceId, salesRoleDetailId, newSalesHierarchyDetailId) => {
        const existingOptionIndex = selectedOptions.findIndex((option) => option.salesRoleDetailId === salesRoleDetailId);

        if (existingOptionIndex !== -1) {
            const updatedSelectedOptions = [...selectedOptions];
            updatedSelectedOptions[existingOptionIndex] = {
                fieldKey: key,
                value: selectedValue,
                salesForceId: salesForceId,
                salesRoleDetailId: salesRoleDetailId,
                salesHierarchyDetailId: newSalesHierarchyDetailId
            };
            setSelectedOptions(updatedSelectedOptions);
        } else {
            const newSelectedOption = {
                fieldKey: key,
                value: selectedValue,
                salesForceId: salesForceId,
                salesRoleDetailId: salesRoleDetailId,
                salesHierarchyDetailId: newSalesHierarchyDetailId
            };
            setSelectedOptions((prevSelectedOptions) => [...prevSelectedOptions, newSelectedOption]);
        }
    };

    useEffect(() => {
        if (employeeRoleList && tableData) {
            const matchingEntries = tableData?.tblSalesHierarchyDetails?.map((row1) => {
                const salesRoleDetailId = row1?.salesRoleDetailId;
                const matchingEntry = employeeRoleList?.find((entry) => entry?.salesRoleDetailId == salesRoleDetailId);
                return matchingEntry;
            });
            setParentObject(matchingEntries);
        }
    }, [employeeRoleList, tableData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (parentObject?.length && tableData) {
            const matchingEntries = tableData?.tblSalesHierarchyDetails?.flatMap((row1, index1) => {
                const salesHierarchyDetailId = row1?.salesHierarchyDetailId;
                const salesForceId = row1?.salesForceId;
                const newEntries = parentObject[index1]?.tblSalesForces?.filter((entry) => entry?.salesForceId === salesForceId);

                // Add salesHierarchyDetailId to each entry in newEntries
                newEntries?.forEach((entry) => {
                    entry.newSalesHierarchyDetailId = salesHierarchyDetailId;
                });

                return newEntries;
            });
            setChildObject(matchingEntries);
        }
    }, [parentObject, tableData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (childObject && childObject[0] && childObject?.length > 0 && parentObject && parentObject[0] && parentObject?.length > 0) {
            const mappedchildObject = childObject?.map((child, key) => {
                child.newSalesRoleDetailId = parentObject[key]?.salesRoleDetailId;
                return child;
            });

            setFinalArray(mappedchildObject);
        }
    }, [childObject, parentObject]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (employeeRoleList.length && finalArray.length) {
            employeeRoleList?.map((item, key) => {
                const matchingItem = finalArray && finalArray?.find((secondItem) => secondItem.newSalesRoleDetailId === item.salesRoleDetailId);
                let valueResult = null;
                let payloadValue = null;
                if (matchingItem !== undefined) {
                    valueResult = {
                        label: matchingItem?.employeeName,
                        salesForceId: matchingItem?.salesForceId,
                        salesRoleDetailId: item.salesRoleDetailId,
                        newSalesHierarchyDetailId: matchingItem?.newSalesHierarchyDetailId
                    };
                    item.selectedValue = valueResult;
                    payloadValue = {
                        fieldKey: key,
                        value: valueResult,
                        salesForceId: matchingItem?.salesForceId,
                        salesRoleDetailId: item.salesRoleDetailId,
                        salesHierarchyDetailId: matchingItem?.newSalesHierarchyDetailId
                    };

                    setSelectedOptions((prevSelectedOptions) => [...prevSelectedOptions, payloadValue]);
                }
            });
        }
        setFinalResult(employeeRoleList);
    }, [employeeRoleList, finalArray]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>VIEW DETAIL</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('saleStructure') }, 'Label__Text')}>
                                    Hierarchy Name
                                </label>
                                <InputText
                                    id="hierarchyName"
                                    name="hierarchyName"
                                    value={formik?.values?.hierarchyName}
                                    disabled
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('employeeId') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('employeeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('saleStructure') }, 'Label__Text')}>
                                    Sale Structure
                                </label>
                                <Dropdown
                                    id="lovId"
                                    options={saleStructureData}
                                    optionLabel="name"
                                    name="salesStructureId"
                                    optionValue="lovId"
                                    value={formik.values.salesStructureId || ''}
                                    disabled
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('saleStructure')}
                            </div>
                        </div>
                    </div>
                    <div className="Form__Header">
                        <h1>ADD SALES HIERARCHY</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        {finalResult?.map((item, key) => {
                            return (
                                <React.Fragment key={key}>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor={item.roleName} className={classNames({ 'p-error': isFormFieldValid('saleStructure') }, 'Label__Text')}>
                                                {item?.roleName}
                                            </label>
                                            <Dropdown
                                                inputId={`employeeName-${key}`}
                                                name={`employeeName-${key}`}
                                                value={item.selectedValue}
                                                options={item.tblSalesForces.map((inner) => ({
                                                    label: inner.employeeName,
                                                    salesForceId: inner.salesForceId,
                                                    salesRoleDetailId: item.salesRoleDetailId,
                                                    newSalesHierarchyDetailId: inner.newSalesHierarchyDetailId
                                                }))}
                                                disabled
                                                onChange={(e) => {
                                                    handleOptionChange(key, e.value, e.value.salesForceId, item.salesRoleDetailId, item.newSalesHierarchyDetailId); // Call the function on user interaction (onChange event)
                                                }}
                                                className={classNames({ 'p-invalid': isFormFieldValid('saleStructure') }, 'Dropdown__Round')}
                                            />
                                            {getFormErrorMessage('saleStructure')}
                                        </div>
                                    </div>
                                </React.Fragment>
                            );
                        })}
                    </div>
                    <div className="Down__Btn">
                        <Button label="Okay" className="Btn__Dark" onClick={() => navigate('/requestchecker')} />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
};
export default ViewSalesHierarchyChecker;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewAgentMateSubCategoryChecker() {
    const [mainData, setMainData] = useState([]);
    const [subCategoryIconFileName, setSubCategoryIconFileName] = useState();

    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const tableName = query.get('tableName');

    const getCategoryDataLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getagentmatecategorydatalov');
        setMainData(res?.payLoad);
    };

    useEffect(() => {
        getCategoryDataLov();
    }, []); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            cmsSubCategoryId: '',
            cmsSubCategoryName: '',
            displayNameEnglist: '',
            displayNameUrdu: '',
            cmsMainCategoryId: '',
            subCategoryNumber: '',
            subCategoryIcon: '',
            subCategorySeq: '',
            addCheck: false,
            cmsTagLabelId: '',
            cmsTagTextColorId: '',
            cmsTagTextBgColorId: '',
            tagExpiryDate: '',
            isHide: '',
            isActive: ''
        }
    });

    const getSubCatDetailsById = async () => {
        const res = await handleGetRequest(`/agentmatecms/v1/agentmatecms/getsubcategorybyid/${refTableId}`);
        const productDetails = res?.payLoad;

        if (productDetails) {
            formik.setFieldValue('cmsSubCategoryId', productDetails?.cmsSubCategoryId || '');
            formik.setFieldValue('cmsSubCategoryName', productDetails?.cmsSubCategoryName || '');
            formik.setFieldValue('displayNameEnglist', productDetails?.displayNameEnglist || '');
            formik.setFieldValue('displayNameUrdu', productDetails?.displayNameUrdu || '');
            formik.setFieldValue('subCategoryNumber', productDetails?.subCategoryNumber || '');
            formik.setFieldValue('subCategoryIcon', productDetails?.subCategoryIcon || '');
            formik.setFieldValue('subCategorySeq', productDetails?.subCategorySeq || '');
            formik.setFieldValue('cmsMainCategoryId', productDetails?.cmsMainCategoryId?.toString() || '');
            formik.setFieldValue('addCheck', productDetails?.cmsTagLabelId || productDetails?.cmsTagTextColorId || productDetails?.cmsTagTextBgColorId ? true : false);
            formik.setFieldValue('cmsTagLabelId', productDetails?.cmsTagLabelId || '');
            formik.setFieldValue('cmsTagTextColorId', productDetails?.cmsTagTextColorId || '');
            formik.setFieldValue('cmsTagTextBgColorId', productDetails?.cmsTagTextBgColorId || '');
            const correctEndDate = formattedDate(productDetails?.tagExpiryDate || '');
            formik.setFieldValue('tagExpiryDate', correctEndDate || '');
            formik.setFieldValue('isHide', productDetails?.isHide || '');
            formik.setFieldValue('isActive', productDetails?.isActive || '');
            formik.setFieldValue('subCategoryIcon', productDetails?.subCategoryIcon);
            const pathParts = productDetails?.subCategoryIcon?.split('/');
            const subCategoryIcon = pathParts[pathParts?.length - 1];
            setSubCategoryIconFileName(subCategoryIcon);
        }
    };

    const getMcRequestDataById = async () => {
        const res = await handleGetRequest(`/agentmatecms/v1/agentmatecms/getmcrequestbyid/${mcRequestId}`);
        const unParsedkeyData = res?.payLoad?.updateJson;
        const keyData = JSON.parse(unParsedkeyData);

        if (keyData) {
            formik.setFieldValue('cmsSubCategoryId', keyData?.cmsSubCategoryId || '');
            formik.setFieldValue('cmsSubCategoryName', keyData?.cmsSubCategoryName || '');
            formik.setFieldValue('displayNameEnglist', keyData?.displayNameEnglist || '');
            formik.setFieldValue('displayNameUrdu', keyData?.displayNameUrdu || '');
            formik.setFieldValue('subCategoryNumber', keyData?.subCategoryNumber || '');
            formik.setFieldValue('subCategoryIcon', keyData?.subCategoryIcon || '');
            formik.setFieldValue('subCategorySeq', keyData?.subCategorySeq || '');
            formik.setFieldValue('addCheck', keyData?.cmsTagLabelId || keyData?.cmsTagTextColorId || keyData?.cmsTagTextBgColorId ? true : false);
            formik.setFieldValue('cmsMainCategoryId', keyData?.cmsMainCategoryId?.toString() || '');
            formik.setFieldValue('cmsTagLabelId', keyData?.cmsTagLabelId || '');
            formik.setFieldValue('cmsTagTextColorId', keyData?.cmsTagTextColorId || '');
            formik.setFieldValue('cmsTagTextBgColorId', keyData?.cmsTagTextBgColorId || '');
            const correctEndDate = formattedDate(keyData?.tagExpiryDate || '');
            formik.setFieldValue('tagExpiryDate', correctEndDate || '');
            formik.setFieldValue('isHide', keyData?.isHide || '');
            formik.setFieldValue('isActive', keyData?.isActive || '');
            const pathParts = keyData?.subCategoryIcon?.split('/');
            const subCategoryIcon = pathParts ? pathParts[pathParts?.length - 1] : '';

            setSubCategoryIconFileName(subCategoryIcon);
        }
    };

    useEffect(() => {
        if (requestType === 'I') {
            getSubCatDetailsById();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, [requestType]); // eslint-disable-line

    function formattedDate(originalDate) {
        const createDate = new Date(originalDate);

        const year = createDate.getFullYear();
        const month = (createDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const day = createDate.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const getContrastColor = (hexColor) => {
        const r = parseInt(hexColor.substr(1, 2), 16);
        const g = parseInt(hexColor.substr(3, 2), 16);
        const b = parseInt(hexColor.substr(5, 2), 16);
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    };
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>VIEW DETAIL</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsSubCategoryName" className={classNames({ 'p-error': isFormFieldValid('cmsSubCategoryName') }, 'Label__Text')}>
                                    Sub Category Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="cmsSubCategoryName"
                                    placeholder="Enter Sub category"
                                    name="cmsSubCategoryName"
                                    value={formik?.values?.cmsSubCategoryName?.replace(/\s\s+/g, ' ') || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsSubCategoryName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('cmsSubCategoryName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="displayNameEnglist" className={classNames({ 'p-error': isFormFieldValid('displayNameEnglist') }, 'Label__Text')}>
                                    Display Name (English)<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="displayNameEnglist"
                                    placeholder="Enter display name"
                                    name="displayNameEnglist"
                                    value={formik?.values?.displayNameEnglist?.replace(/\s\s+/g, ' ') || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('displayNameEnglist') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('displayNameEnglist')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="displayNameUrdu" className={classNames({ 'p-error': isFormFieldValid('displayNameUrdu') }, 'Label__Text')}>
                                    Display Name (Urdu)<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="displayNameUrdu"
                                    placeholder="Enter display name"
                                    name="displayNameUrdu"
                                    value={formik?.values?.displayNameUrdu?.replace(/\s\s+/g, ' ') || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('displayNameUrdu') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('displayNameUrdu')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsMainCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsMainCategoryId') }, 'Label__Text')}>
                                    Main Category
                                </label>
                                <Dropdown
                                    id="cmsMainCategoryId"
                                    placeholder="Select Main Category"
                                    options={mainData}
                                    optionLabel="name"
                                    name="cmsMainCategoryId"
                                    optionValue="lovId"
                                    value={formik.values.cmsMainCategoryId || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsMainCategoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsMainCategoryId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="subCategoryNumber" className={classNames({ 'p-error': isFormFieldValid('subcmsMainCategoryId') }, 'Label__Text')}>
                                    Sub Category Number <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="subCategoryNumber"
                                    placeholder="Enter no"
                                    name="subCategoryNumber"
                                    value={formik?.values?.subCategoryNumber || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('subCategoryNumber') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('subCategoryNumber')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <div className="mb-2">
                                    <label className="Label__Text">Sub Category Icon</label>
                                    <div className="file-input-container w-100">
                                        <input type="file" style={{ display: 'none' }} accept="image/*" />
                                        <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={subCategoryIconFileName} readOnly />
                                        <span className="file-upload-icon">
                                            <i className="pi pi-paperclip" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="subCategorySeq" className={classNames({ 'p-error': isFormFieldValid('subCategorySeq') }, 'Label__Text')}>
                                    Category Sequence
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="subCategorySeq"
                                    placeholder="Enter Category Sequence"
                                    name="subCategorySeq"
                                    value={formik?.values?.subCategorySeq?.replace(/\s\s+/g, ' ') || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('subCategorySeq') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('subCategorySeq')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-1 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="addCheck" className={classNames({ 'p-error': isFormFieldValid('addCheck') }, 'Label__Text')}>
                                    Add Tag
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="addCheck" name="addCheck" checked={formik?.values?.addCheck} onChange={formik.handleChange} disabled style={{ marginTop: '10px' }} />{' '}
                                </div>
                                {getFormErrorMessage('addCheck')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsTagLabelId" className={classNames({ 'p-error': isFormFieldValid('cmsTagLabelId') }, 'Label__Text')}>
                                    Tag Label
                                </label>
                                <InputText
                                    id="cmsTagLabelId"
                                    placeholder="Enter Tag Label"
                                    name="cmsTagLabelId"
                                    disabled
                                    value={formik?.values?.cmsTagLabelId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagLabelId') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('cmsTagLabelId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsTagTextColorId" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextColorId') }, 'Label__Text')}>
                                    Tag Text Color
                                </label>
                                <InputText
                                    value={formik?.values?.cmsTagTextColorId || '#FFFFFF'}
                                    onChange={(e) => {
                                        formik.setFieldValue('cmsTagTextColorId', e.target.value);
                                    }}
                                    disabled
                                    readOnly
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextColorId') }, 'Input__Round')}
                                    style={{
                                        backgroundColor: formik?.values?.cmsTagTextColorId || '#FFFFFF',
                                        color: getContrastColor(formik?.values?.cmsTagTextColorId || '#FFFFFF')
                                    }}
                                />

                                {getFormErrorMessage('cmsTagTextColorId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsTagTextBgColorId" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextBgColorId') }, 'Label__Text')}>
                                    Tag Back Ground Color
                                </label>
                                <InputText
                                    value={formik?.values?.cmsTagTextBgColorId || '#FFFFFF'}
                                    onChange={(e) => {
                                        formik.setFieldValue('cmsTagTextBgColorId', e.target.value);
                                    }}
                                    disabled
                                    readOnly
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextBgColorId') }, 'Input__Round')}
                                    style={{
                                        backgroundColor: formik?.values?.cmsTagTextBgColorId || '#FFFFFF',
                                        color: getContrastColor(formik?.values?.cmsTagTextBgColorId || '#FFFFFF')
                                    }}
                                />

                                {getFormErrorMessage('cmsTagTextBgColorId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="tagExpiryDate" className={classNames({ 'p-error': isFormFieldValid('tagExpiryDate') }, 'Label__Text')}>
                                    Tag Expiry Date
                                </label>
                                <InputText type="date" id="tagExpiryDate" name="tagExpiryDate" value={formik?.values?.tagExpiryDate || ''} disabled className={classNames({ 'p-invalid': isFormFieldValid('tagExpiryDate') }, 'Input__Round')} />
                                {getFormErrorMessage('tagExpiryDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-2 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isHide" className={classNames({ 'p-error': isFormFieldValid('isHide') }, 'Label__Text')}>
                                    Status
                                </label>
                                <div className="flex ml-2">
                                    <div className="mt-1 mr-5">
                                        <Checkbox inputId="show" name="isHide" value="Y" checked={formik?.values?.isHide === 'Y'} onChange={(e) => formik.setFieldValue('isHide', 'Y')} disabled style={{ marginTop: '10px' }} />
                                        <label htmlFor="show" className={classNames({ 'p-error': isFormFieldValid('show') }, 'Label__Text')}>
                                            Show
                                        </label>
                                    </div>
                                    <div className="mt-1">
                                        <Checkbox inputId="hide" name="isHide" value="N" checked={formik?.values?.isHide === 'N'} onChange={(e) => formik.setFieldValue('isHide', 'N')} disabled style={{ marginTop: '10px' }} />
                                        <label htmlFor="hide" className={classNames({ 'p-error': isFormFieldValid('hide') }, 'Label__Text')}>
                                            Hide
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="button" label="Okay" onClick={() => navigate('/requestchecker')} className="Btn__Dark" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default ViewAgentMateSubCategoryChecker;

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { Dropdown } from 'primereact/dropdown';

const ChartOfAccountOldNewValues = ({ chartOfAccountRowData }) => {
    const [organizationLov, setOrganizationLov] = useState([]);
    const [locationLov, setLocationLov] = useState([]);
    const [costCenterLov, setCostCenterLov] = useState([]);
    const [categoryLov, setCategoryLov] = useState([]);
    const [subCategoryLov, setSubCategoryLov] = useState([]);
    const [currencyLov, setCurrencyLov] = useState([]);
    const [mrpLov, setMrpLov] = useState([]);

    const formik = useFormik({
        initialValues: {
            lkpOrganization: '',
            lkpBranch: '',
            lkpCostCenter: '',
            lkpGlType: '',
            lkpSubCategory: '',
            lkpCurrency: '',
            lkpMrpCode: ''
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    // Segment LOVs

    const getOrganizationLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_ORGANIZATION');
        setOrganizationLov(res?.payLoad);
    };

    const getLocationLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_BRANCH');
        setLocationLov(res?.payLoad);
    };

    const getCostCenterLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_COST_CENTER');
        setCostCenterLov(res?.payLoad);
    };

    const getCategoryLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_GL_TYPE');
        setCategoryLov(res?.payLoad);
    };

    const getSubCategoryLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_SUB_CATEGORY');
        setSubCategoryLov(res?.payLoad);
    };

    const getCurrencyLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_CURRENCY');
        setCurrencyLov(res?.payLoad);
    };

    const getMrpLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_MRP_CODE');
        setMrpLov(res?.payLoad);
    };

    useEffect(() => {
        getOrganizationLov();
        getLocationLov();
        getCostCenterLov();
        getCategoryLov();
        getSubCategoryLov();
        getCurrencyLov();
        getMrpLov();
    }, []); // eslint-disable-line

    useEffect(() => {
        const orgLovId = organizationLov.find((item) => item?.code === chartOfAccountRowData?.glSegmentCode1);
        formik.setFieldValue('lkpOrganization', orgLovId && orgLovId?.lovId);
        const locLovId = locationLov.find((item) => item?.code === chartOfAccountRowData?.glSegmentCode2);
        formik.setFieldValue('lkpBranch', locLovId && locLovId?.lovId);
        const costCenterLovId = costCenterLov.find((item) => item?.code === chartOfAccountRowData?.glSegmentCode3);
        formik.setFieldValue('lkpCostCenter', costCenterLovId && costCenterLovId?.lovId);
        const categoryLovId = categoryLov.find((item) => item?.code === chartOfAccountRowData?.glSegmentCode4);
        formik.setFieldValue('lkpGlType', categoryLovId && categoryLovId?.lovId);
        const subCategoryLovId = subCategoryLov.find((item) => item?.code === chartOfAccountRowData?.glSegmentCode5);
        formik.setFieldValue('lkpSubCategory', subCategoryLovId && subCategoryLovId?.lovId);
        const currencyLovId = currencyLov.find((item) => item?.code === chartOfAccountRowData?.glSegmentCode6);
        formik.setFieldValue('lkpCurrency', currencyLovId && currencyLovId?.lovId);
        const mrpLovId = mrpLov.find((item) => item?.code === chartOfAccountRowData?.glSegmentCode7);
        formik.setFieldValue('lkpMrpCode', mrpLovId && mrpLovId?.lovId);
    }, [chartOfAccountRowData, organizationLov, locationLov, costCenterLov, categoryLov, subCategoryLov, currencyLov, mrpLov]); // eslint-disable-line

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>Old Details</h2>
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className="Label__Text">Organization</label>
                            <Dropdown
                                disabled
                                id="lkpOrganization"
                                placeholder="Select Filter"
                                options={organizationLov}
                                optionLabel="name"
                                optionValue="lovId"
                                value={formik.values.lkpOrganization ?? ''}
                                onChange={formik.handleChange}
                                className="Dropdown__Round"
                            />
                            {getFormErrorMessage('lkpOrganization')}
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className="Label__Text">Location</label>
                            <Dropdown disabled id="lkpBranch" placeholder="Select Filter" options={locationLov} optionLabel="name" optionValue="lovId" value={formik.values.lkpBranch ?? ''} onChange={formik.handleChange} className="Dropdown__Round" />
                            {getFormErrorMessage('lkpBranch')}
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className="Label__Text">Cost Center</label>
                            <Dropdown
                                disabled
                                id="lkpCostCenter"
                                placeholder="Select Filter"
                                options={costCenterLov}
                                optionLabel="name"
                                optionValue="lovId"
                                value={formik.values.lkpCostCenter ?? ''}
                                onChange={formik.handleChange}
                                className="Dropdown__Round"
                            />
                            {getFormErrorMessage('lkpCostCenter')}
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className="Label__Text">Category</label>
                            <Dropdown disabled id="lkpGlType" placeholder="Select Filter" options={categoryLov} optionLabel="name" optionValue="lovId" value={formik.values.lkpGlType ?? ''} onChange={formik.handleChange} className="Dropdown__Round" />
                            {getFormErrorMessage('lkpGlType')}
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className="Label__Text">Sub Category</label>
                            <Dropdown
                                disabled
                                id="lkpSubCategory"
                                placeholder="Select Filter"
                                options={subCategoryLov}
                                optionLabel="name"
                                optionValue="lovId"
                                value={formik.values.lkpSubCategory ?? ''}
                                onChange={formik.handleChange}
                                className="Dropdown__Round"
                            />
                            {getFormErrorMessage('lkpSubCategory')}
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className="Label__Text">Currency</label>
                            <Dropdown
                                disabled
                                id="lkpCurrency"
                                placeholder="Select Filter"
                                options={currencyLov}
                                optionLabel="name"
                                optionValue="lovId"
                                value={formik.values.lkpCurrency ?? ''}
                                onChange={formik.handleChange}
                                className="Dropdown__Round"
                            />

                            {getFormErrorMessage('lkpCurrency')}
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className="Label__Text">MRP Code</label>
                            <Dropdown disabled id="lkpMrpCode" placeholder="Select Filter" options={mrpLov} optionLabel="name" optionValue="lovId" value={formik.values.lkpMrpCode ?? ''} onChange={formik.handleChange} className="Dropdown__Round" />
                            {getFormErrorMessage('lkpMrpCode')}
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default ChartOfAccountOldNewValues;

import React, { useState, useEffect } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import * as Yup from 'yup';
import classNames from 'classnames';

const ViewModule = ({ onViewHide, showModuleData }) => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-save');

    useEffect(() => {
        if (showModuleData !== undefined || showModuleData !== null) {
            formik.setFieldValue('moduleDescr', showModuleData.moduleDescr);
        }
    }, [showModuleData]); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        moduleDescr: Yup.string().required('This field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            moduleDescr: ''
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            setloading(false);
            setloadingIcon('pi pi-save');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="moduleDescr" className={classNames({ 'p-error': isFormFieldValid('moduleDescr') }, 'Label__Text')}>
                                Description
                            </label>
                            <InputTextarea
                                placeholder="Add Description"
                                id="moduleDescr"
                                name="moduleDescr"
                                value={formik?.values?.moduleDescr?.replace(/\s\s+/g, '') || ''}
                                rows={5}
                                cols={10}
                                disabled={true}
                                autoResize="false"
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('moduleDescr') }, 'TextArea__Round')}
                            />
                            {getFormErrorMessage('moduleDescr')}
                        </div>
                    </div>
                    <div className="Down__Btn__End">
                        <Button disabled={loading} loadingIcon={loadingIcon} label={'Okay'} onClick={() => onViewHide()} className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
};

export default ViewModule;

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';

function EditTransactionProfilingChecker({ responseId, riskProfileData, handledAllStepsComplete }) {

    const [amountTransactionProfile, setAmountTransactionProfile] = useState();
    const [countTransactionProfile, setCountTransactionProfile] = useState();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const requestType = query.get('requestType');
    const requestTypeDescr = query.get('requestTypeDescr');

    const dispatch = useDispatch();

    const getTransactionProfileLov = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getcrpdata/LKP_RP_TRANS_PROFILE');
        const amountLov = response.payLoad?.filter((item) => item.name.includes('Million'));
        const countLov = response.payLoad?.filter((item) => !item.name.includes('Million'));
        setAmountTransactionProfile(amountLov);
        setCountTransactionProfile(countLov);
    };
    useEffect(() => {
        getTransactionProfileLov();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (requestType === 'I' || (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request')) {
            if (riskProfileData) {
                const CTDropdownData = riskProfileData?.tblRpCrpDetails;

                if (CTDropdownData) {
                    const CMHighId = [];
                    const CMMediumId = [];
                    const CMLowId = [];

                    const DMHighId = [];
                    const DMMediumId = [];
                    const DMLowId = [];

                    const CCHighId = [];
                    const CCMediumId = [];
                    const CCLowId = [];

                    const DCHighId = [];
                    const DCMediumId = [];
                    const DCLowId = [];

                    CTDropdownData.forEach((value) => {
                        const referenceIdToString = value.refId?.toString();

                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'CM') {
                            // GET Expected Monthly Credit Amount Data
                            if (value?.rpRating === 'H') {
                                CMHighId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyCrHighRisk', CMHighId);
                                formik.setFieldValue('expectedMonthlyCrOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                CMMediumId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyCrMediumRisk', CMMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                CMLowId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyCrLowRisk', CMLowId);
                            }
                        }
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'DM') {
                            // GET Expected Monthly Debit Amount Data
                            if (value?.rpRating === 'H') {
                                DMHighId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyDrHighRisk', DMHighId);
                                formik.setFieldValue('expectedMonthlyDrOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                DMMediumId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyDrMediumRisk', DMMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                DMLowId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyDrLowRisk', DMLowId);
                            }
                        }
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'CC') {
                            // GET Expected Monthly Credit Count Data
                            if (value?.rpRating === 'H') {
                                CCHighId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyCrCountHighRisk', CCHighId);
                                formik.setFieldValue('expectedMonthlyCrCountOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                CCMediumId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyCrCountMediumRisk', CCMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                CCLowId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyCrCountLowRisk', CCLowId);
                            }
                        }
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'DC') {
                            // GET Expected Monthly Debit Count Data
                            if (value?.rpRating === 'H') {
                                DCHighId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyDrCountHighRisk', DCHighId);
                                formik.setFieldValue('expectedMonthlyDrCountOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                DCMediumId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyDrCountMediumRisk', DCMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                DCLowId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyDrCountLowRisk', DCLowId);
                            }
                        }
                    });
                }
            }
        } else if (requestType === 'U') {
            // GET Expected Monthly Debit Amount Data
            formik.setFieldValue(
                'expectedMonthlyDrHighRisk',
                riskProfileData[3]?.reqMap[0]?.DM?.highRisk?.map((value) => value?.toString())
            );
            formik.setFieldValue(
                'expectedMonthlyDrMediumRisk',
                riskProfileData[3]?.reqMap[0]?.DM?.medRisk?.map((value) => value?.toString())
            );
            formik.setFieldValue(
                'expectedMonthlyDrLowRisk',
                riskProfileData[3]?.reqMap[0]?.DM?.lowRisk?.map((value) => value?.toString())
            );
            formik.setFieldValue('expectedMonthlyDrOverRideRule', riskProfileData[3]?.reqMap[0]?.DM?.overRideRule === true ? 'true' : 'false');

            // GET Expected Monthly Credit Amount Data
            formik.setFieldValue(
                'expectedMonthlyCrHighRisk',
                riskProfileData[3]?.reqMap[1]?.CM?.highRisk?.map((value) => value?.toString())
            );
            formik.setFieldValue(
                'expectedMonthlyCrMediumRisk',
                riskProfileData[3]?.reqMap[1]?.CM?.medRisk?.map((value) => value?.toString())
            );
            formik.setFieldValue(
                'expectedMonthlyCrLowRisk',
                riskProfileData[3]?.reqMap[1]?.CM?.lowRisk?.map((value) => value?.toString())
            );
            formik.setFieldValue('expectedMonthlyCrOverRideRule', riskProfileData[3]?.reqMap[1]?.CM?.overRideRule === true ? 'true' : 'false');

            // GET Expected Monthly Debit Count Data
            formik.setFieldValue(
                'expectedMonthlyDrCountHighRisk',
                riskProfileData[3]?.reqMap[2]?.DC?.highRisk?.map((value) => value?.toString())
            );
            formik.setFieldValue(
                'expectedMonthlyDrCountMediumRisk',
                riskProfileData[3]?.reqMap[2]?.DC?.medRisk?.map((value) => value?.toString())
            );
            formik.setFieldValue(
                'expectedMonthlyDrCountLowRisk',
                riskProfileData[3]?.reqMap[2]?.DC?.lowRisk?.map((value) => value?.toString())
            );
            formik.setFieldValue('expectedMonthlyDrCountOverRideRule', riskProfileData[3]?.reqMap[2]?.DC?.overRideRule === true ? 'true' : 'false');

            // GET Expected Monthly Credit Count Data
            formik.setFieldValue(
                'expectedMonthlyCrCountHighRisk',
                riskProfileData[3]?.reqMap[3]?.CC?.highRisk?.map((value) => value?.toString())
            );
            formik.setFieldValue(
                'expectedMonthlyCrCountMediumRisk',
                riskProfileData[3]?.reqMap[3]?.CC?.medRisk?.map((value) => value?.toString())
            );
            formik.setFieldValue(
                'expectedMonthlyCrCountLowRisk',
                riskProfileData[3]?.reqMap[3]?.CC?.lowRisk?.map((value) => value?.toString())
            );
            formik.setFieldValue('expectedMonthlyCrCountOverRideRule', riskProfileData[3]?.reqMap[3]?.CC?.overRideRule === true ? 'true' : 'false');
        }
    }, [riskProfileData]); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            stepNo: 4,
            crpId: responseId,
            expectedMonthlyCrHighRisk: [],
            expectedMonthlyCrMediumRisk: [],
            expectedMonthlyCrLowRisk: [],
            expectedMonthlyCrOverRideRule: 'false',
            expectedMonthlyDrHighRisk: [],
            expectedMonthlyDrMediumRisk: [],
            expectedMonthlyDrLowRisk: [],
            expectedMonthlyDrOverRideRule: 'false',
            expectedMonthlyCrCountHighRisk: [],
            expectedMonthlyCrCountMediumRisk: [],
            expectedMonthlyCrCountLowRisk: [],
            expectedMonthlyCrCountOverRideRule: 'false',
            expectedMonthlyDrCountHighRisk: [],
            expectedMonthlyDrCountMediumRisk: [],
            expectedMonthlyDrCountLowRisk: [],
            expectedMonthlyDrCountOverRideRule: 'false'
        },

        onSubmit: async (data) => {
            data['reqMap'] = [
                {
                    DM: {
                        highRisk: data?.expectedMonthlyDrHighRisk,
                        medRisk: [data?.expectedMonthlyDrMediumRisk],
                        lowRisk: [data?.expectedMonthlyDrLowRisk],
                        overRideRule: data?.expectedMonthlyDrOverRideRule
                    }
                },
                {
                    CM: {
                        highRisk: data?.expectedMonthlyCrHighRisk,
                        medRisk: data?.expectedMonthlyCrMediumRisk,
                        lowRisk: data?.expectedMonthlyCrLowRisk,
                        overRideRule: data?.expectedMonthlyCrOverRideRule
                    }
                },
                {
                    DC: {
                        highRisk: data?.expectedMonthlyDrCountHighRisk,
                        medRisk: [data?.expectedMonthlyDrCountMediumRisk],
                        lowRisk: [data?.expectedMonthlyDrCountLowRisk],
                        overRideRule: data?.expectedMonthlyDrCountOverRideRule
                    }
                },
                {
                    CC: {
                        highRisk: data?.expectedMonthlyCrCountHighRisk,
                        medRisk: [data?.expectedMonthlyCrCountMediumRisk],
                        lowRisk: [data?.expectedMonthlyCrCountLowRisk],
                        overRideRule: data?.expectedMonthlyCrCountOverRideRule
                    }
                }
            ];

            delete data['expectedMonthlyDrHighRisk'];
            delete data['expectedMonthlyDrMediumRisk'];
            delete data['expectedMonthlyDrLowRisk'];
            delete data['expectedMonthlyDrOverRideRule'];
            delete data['expectedMonthlyCrHighRisk'];
            delete data['expectedMonthlyCrMediumRisk'];
            delete data['expectedMonthlyCrLowRisk'];
            delete data['expectedMonthlyCrOverRideRule'];
            delete data['expectedMonthlyDrCountHighRisk'];
            delete data['expectedMonthlyDrCountMediumRisk'];
            delete data['expectedMonthlyDrCountLowRisk'];
            delete data['expectedMonthlyDrCountOverRideRule'];
            delete data['expectedMonthlyCrCountHighRisk'];
            delete data['expectedMonthlyCrCountMediumRisk'];
            delete data['expectedMonthlyCrCountLowRisk'];
            delete data['expectedMonthlyCrCountOverRideRule'];

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            handledAllStepsComplete(true);
            await dispatch(handlePostRequest(newData, '/riskprofile/v1/crp/saveupdatecrp', true, false));
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {/* EXPECTED MONTHLY CREDIT AMOUNT */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>EXPECTED MONTHLY CREDIT AMOUNT</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyCrHighRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyCrHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyCrHighRisk"
                                    placeholder="Select High Risk"
                                    options={amountTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyCrHighRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyCrHighRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyCrHighRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('expectedMonthlyCrHighRisk')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyCrMediumRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyCrMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyCrMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={amountTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyCrMediumRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyCrMediumRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyCrMediumRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                                {getFormErrorMessage('expectedMonthlyCrMediumRisk')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyCrLowRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyCrLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyCrLowRisk"
                                    placeholder="Select Low Risk"
                                    options={amountTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyCrLowRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyCrLowRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyCrLowRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('expectedMonthlyCrLowRisk')}
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="expectedMonthlyCrOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="expectedMonthlyCrOverRideRule" disabled name="expectedMonthlyCrOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.expectedMonthlyCrOverRideRule === 'false'} />
                                    <label htmlFor="expectedMonthlyCrOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="expectedMonthlyCrOverRideRule" disabled name="expectedMonthlyCrOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.expectedMonthlyCrOverRideRule === 'true'} />
                                    <label htmlFor="expectedMonthlyCrOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* EXPECTED MONTHLY DEBIT AMOUNT */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>EXPECTED MONTHLY DEBIT AMOUNT</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyDrHighRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyDrHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyDrHighRisk"
                                    placeholder="Select High Risk"
                                    options={amountTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyDrHighRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyDrHighRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyDrHighRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('expectedMonthlyDrHighRisk')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyDrMediumRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyDrMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyDrMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={amountTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyDrMediumRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyDrMediumRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyDrMediumRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('expectedMonthlyDrMediumRisk')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyDrLowRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyDrLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyDrLowRisk"
                                    placeholder="Select Low Risk"
                                    options={amountTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyDrLowRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyDrLowRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyDrLowRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('expectedMonthlyDrLowRisk')}
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="expectedMonthlyDrOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="expectedMonthlyDrOverRideRule" disabled name="expectedMonthlyDrOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.expectedMonthlyDrOverRideRule === 'false'} />
                                    <label htmlFor="expectedMonthlyDrOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="expectedMonthlyDrOverRideRule" disabled name="expectedMonthlyDrOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.expectedMonthlyDrOverRideRule === 'true'} />
                                    <label htmlFor="expectedMonthlyDrOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* EXPECTED MONTHLY CREDIT COUNT */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>EXPECTED MONTHLY CREDIT COUNT</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyCrCountHighRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyCrCountHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyCrCountHighRisk"
                                    placeholder="Select High Risk"
                                    options={countTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyCrCountHighRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyCrCountHighRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyCrCountHighRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('expectedMonthlyCrCountHighRisk')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyCrCountMediumRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyCrCountMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyCrCountMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={countTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyCrCountMediumRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyCrCountMediumRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyCrCountMediumRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('expectedMonthlyCrCountMediumRisk')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyCrCountLowRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyCrCountLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyCrCountLowRisk"
                                    placeholder="Select Low Risk"
                                    options={countTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyCrCountLowRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyCrCountLowRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyCrCountLowRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('expectedMonthlyCrCountLowRisk')}
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="expectedMonthlyCrCountOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton
                                        inputId="expectedMonthlyCrCountOverRideRule"
                                        disabled
                                        name="expectedMonthlyCrCountOverRideRule"
                                        value={'false'}
                                        onChange={formik.handleChange}
                                        checked={formik.values.expectedMonthlyCrCountOverRideRule === 'false'}
                                    />
                                    <label htmlFor="expectedMonthlyCrCountOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton
                                        inputId="expectedMonthlyCrCountOverRideRule"
                                        disabled
                                        name="expectedMonthlyCrCountOverRideRule"
                                        value={'true'}
                                        onChange={formik.handleChange}
                                        checked={formik.values.expectedMonthlyCrCountOverRideRule === 'true'}
                                    />
                                    <label htmlFor="expectedMonthlyCrCountOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* EXPECTED MONTHLY DEBIT COUNT */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>EXPECTED MONTHLY DEBIT COUNT</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyDrCountHighRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyDrCountHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyDrCountHighRisk"
                                    placeholder="Select High Risk"
                                    options={countTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyDrCountHighRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyDrCountHighRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyDrCountHighRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('expectedMonthlyDrCountHighRisk')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyDrCountMediumRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyDrCountMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyDrCountMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={countTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyDrCountMediumRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyDrCountMediumRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyDrCountMediumRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('expectedMonthlyDrCountMediumRisk')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyDrCountLowRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyDrCountLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyDrCountLowRisk"
                                    placeholder="Select Low Risk"
                                    options={countTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyDrCountLowRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyDrCountLowRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyDrCountLowRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('expectedMonthlyDrCountLowRisk')}
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="expectedMonthlyDrCountOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton
                                        inputId="expectedMonthlyDrCountOverRideRule"
                                        disabled
                                        name="expectedMonthlyDrCountOverRideRule"
                                        value={'false'}
                                        onChange={formik.handleChange}
                                        checked={formik.values.expectedMonthlyDrCountOverRideRule === 'false'}
                                    />
                                    <label htmlFor="expectedMonthlyDrCountOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton
                                        inputId="expectedMonthlyDrCountOverRideRule"
                                        disabled
                                        name="expectedMonthlyDrCountOverRideRule"
                                        value={'true'}
                                        onChange={formik.handleChange}
                                        checked={formik.values.expectedMonthlyDrCountOverRideRule === 'true'}
                                    />
                                    <label htmlFor="expectedMonthlyDrCountOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
export default EditTransactionProfilingChecker;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';

function EditCreateReg() {
    const [loading, setloading] = useState(false);
    const [districtLov, setDistrictLov] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const id = query.get('id');

    const validationSchema = Yup.object({
        regionName: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
            .required('This Field is required'),
        districtId: Yup.string().required('This Field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            regionId: id,
            regionName: '',
            districtId: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            await dispatch(handlePostRequest(newData, '/account/v1/sales/updatesalesregion', true, false, 'createregion'));
            setloading(false);
        }
    });

    const getRegionData = async () => {
        setloading(true);
        const res = await handleGetRequest(`/account/v1/sales/getRegionById/${id}`);
        const keyData = res?.payLoad;
        if (keyData) {
            formik.setFieldValue('regionName', keyData?.regionName);
            formik.setFieldValue('districtId', keyData?.districtId.toString());
        }
        setloading(false);
    };

    useEffect(() => {
        if (id) {
            getRegionData();
        }
    }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

    const getDistrictData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getAllDistrict/LKP_DISTRICT:*');
        setDistrictLov(res?.payLoad);
    };

    useEffect(() => {
        getDistrictData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>EDIT DETAIL</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="regionName" className={classNames({ 'p-error': isFormFieldValid('regionName') }, 'Label__Text')}>
                                    Region Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={50}
                                    minLength={3}
                                    placeholder={'Enter Region Name'}
                                    id="regionName"
                                    name="regionName"
                                    value={formik?.values?.regionName}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regionName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('regionName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="districtId" className={classNames({ 'p-error': isFormFieldValid('districtId') }, 'Label__Text')}>
                                    District <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="districtId"
                                    placeholder="Select District"
                                    options={districtLov}
                                    optionLabel="name"
                                    name="districtId"
                                    optionValue="lovId"
                                    value={formik.values.districtId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('districtId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('districtId')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} label="Update" className="Btn__Dark" />
                        <Button onClick={() => navigate('/createregion')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default EditCreateReg;

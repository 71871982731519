import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import DebitCard from '../Components/DebitCard/DebitCard';
import VirtualCard from '../Components/VirtualCard/VirtualCard';

function ZindagiCardDetails({ type }) {
    const [virtualCardData, setVirtualCardData] = useState();
    const [loading, setloading] = useState(true);
    const [disableBtn, setDisableBtn] = useState(true);
    const [zindigiData, setZindigiData] = useState();

    const location = useLocation();
    const { rowData } = location.state || {};

    const getCardStatusData = async () => {
        try {
            // const resp = await handleGetRequest(`/debitcardmanagement/v1/vcards/getcustomervirtualcarddetails/586`);
            const resp = await handleGetRequest(`/debitcardmanagement/v1/vcards/getcustomervirtualcarddetails/${localStorage.getItem('accountId')}`);
            if (resp?.responseCode === '220000') {
                setVirtualCardData(resp?.payLoad);
                setloading(false);
                setDisableBtn(false);
            }
        } catch (error) {
        } finally {
            setloading(false);
        }
    };

    useEffect(() => {
        if (rowData) {
            localStorage.setItem('accountId', rowData?.accountId);
            localStorage.setItem('zindigiRowData', JSON.stringify(rowData));
        }
    }, [rowData]);

    useEffect(() => {
        if (localStorage.getItem('zindigiRowData')) {
            setZindigiData(JSON.parse(localStorage.getItem('zindigiRowData')));
        }
        if (localStorage.getItem('accountId')) {
            getCardStatusData();
        }
    }, [localStorage]);

    return (
        <>
            <div className="grid">
                <div className="col-12 md:col-6">
                    <div className="card" style={{ background: '#f8f8f8' }}>
                        <DebitCard type={type} />
                    </div>
                </div>
                <div className="col-12 md:col-6">
                    <div
                        className="card"
                        style={{
                            background: '#f8f8f8'
                            // filter: virtualCardData ? 'blur(0px)' : 'blur(5px)'
                        }}
                    >
                        <VirtualCard type={type} virtualCardData={virtualCardData} rowData={rowData ? rowData : zindigiData} loading={loading} disableBtn={disableBtn} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ZindagiCardDetails;

import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { FilterMatchMode } from 'primereact/api';
import * as Yup from 'yup';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import ActiveInActiveDialog from '../../../components/ActiveInActiveDialog/ActiveInActiveDialog';
import HistoryDialog from './HistoryDialog';
import { Dialog } from 'primereact/dialog';

const SAF = () => {
    const [statusLovData, setStatusLovData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [switchConfigData, setSwitchConfigData] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [switchData, setSwitchData] = useState([]);
    const [showHistoryDialog, setShowHistoryDialog] = useState(false);
    const [showModalRetry, setShowModalRetry] = useState(false);

    const dataTableRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //Datatables filters criteria
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        rrn: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ''));
    };

    //YUP validation Schema
    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,

            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    //Formik
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            rrn: '',
            stan: '',
            transactionCode: '',
            dateTo: '',
            dateFrom: '',
            status: '',
            productName: 'abc'
        },
        validate: (data) => {
            let errors = {};
            if (data.dateTo && !data.dateFrom) {
                errors.dateFrom = 'Date From is required when Date To is provided';
            }

            if (data.dateFrom && data.dateTo && data.dateFrom > data.dateTo) {
                errors.dateTo = 'Date To must be greater than or equal to Date From';
            }
            return errors;
        },

        onSubmit: async (data) => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            dataTableRef.current.reset();
            setSwitchConfigData('');

            //API call to get all channels
            const res = await dispatch(handlePostRequest(newData, '/saf-consumer/v1/getallsafdata', true, true));
            if (res?.responseCode === '410000') {
                setSwitchConfigData(res?.payLoad);
            }
        }
    });

    //Function to Reset the Form
    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    //Rendering Data table header
    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">Existing Details</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const header = () => renderHeader();

    const handleSwitchChange = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    rrn: switchData.rrn,
                    stan: switchData.stan
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        await dispatch(handlePostRequest(newData, '/transaction/v1/transactions/reversal', true, false));
    };

    const handleSwitchChangeRetry = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    rrn: switchData.rrn,
                    stan: switchData.stan,
                    transactionAmount: switchData.transactionamount,
                    cnic: '',
                    merchantType: switchData.merchanttype,
                    pointOfEntry: '',
                    authIdResponse: switchData.authidresponse,
                    cardAcceptorTerminalId: switchData.cardacceptorterminalid,
                    cardAcceptorIdentificationCode: switchData.cardacceptoridentificationcode,
                    cardAcceptorNameAndLocation: switchData.cardacceptornameandlocation,
                    purposeOfPayment: switchData.purposeofpayment,
                    accountNo1: switchData.accountno1,
                    accountNo2: switchData.accountno2,
                    accountTitle: switchData.accounttitle,
                    senderName: switchData.sendername,
                    toBankImd: switchData.tobankimd,
                    networkIdentifier: switchData.networkidentifier,
                    beneficaryId: switchData.beneficaryid,
                    currencyCode: switchData.currencycode,
                    transactionDateTime: switchData.transactiondatetime,
                    accountBankName: switchData.accountbankname,
                    accountBranchName: switchData.accountbranchname,
                    senderId: switchData.senderid,
                    pan: switchData.pan
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        await dispatch(handlePostRequest(newData, '/processor/v1/1linkoutgoingprocessor/advice', true, false));
    };

    //Actions Buttons in Datatable
    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions pl-8">
                    <Button
                        label="History"
                        onClick={() => {
                            setShowHistoryDialog(true);
                            setSwitchData(rowData);
                        }}
                        style={{ borderRadius: '5px', backgroundColor: '#509793', border: 'none', boxShadow: 'none', width: '30%' }}
                        className="p-button-primary"
                    />
                    <Button
                        label="Retry"
                        onClick={() => {
                            setSwitchData(rowData);
                            setShowModalRetry(true);
                        }}
                        style={{ borderRadius: '5px', backgroundColor: '#535965', border: 'none', boxShadow: 'none', width: '30%' }}
                    />
                    <Button
                        label="Reverse"
                        onClick={() => {
                            setSwitchData(rowData);
                            setShowModal(true);
                        }}
                        style={{ borderRadius: '5px', color: '#fff', backgroundColor: '#BB3232', border: 'none', boxShadow: 'none', width: '30%' }}
                    />
                </div>
            </>
        );
    };

    //status color function
    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.statusDesc === 'Approved' ? '#14A38B' : rowData?.statusDesc === 'Pending' ? '#F2AC57' : '#FF7171';

        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.statusDesc}</b>
            </p>
        );
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    //API call to get status list data
    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(res?.payLoad);
    };

    //API call to get User List
    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };

    //calling getUserLov getStatusListData on page load
    useEffect(() => {
        getStatusListData();
        getUserLov();
    }, []); // eslint-disable-line

    //Functions to handle validations error
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const handleShowModal = () => {
        setShowModal(false);
    };
    const handleShowModalRetry = () => {
        setShowModalRetry(false);
    };
    const props = {
        showModal,
        handleShowModal,
        handleSwitchChange
    };

    const onHideHistoryDialog = () => {
        setShowHistoryDialog(false);
    };

    const retryProps = {
        showModal: showModalRetry,
        handleShowModal: handleShowModalRetry,
        handleSwitchChange: handleSwitchChangeRetry,
        isRetry: true
    };

    return (
        <>
            <ActiveInActiveDialog {...retryProps} />

            <ActiveInActiveDialog {...props} />

            <Dialog resizable={false} header="History" visible={showHistoryDialog} blockScroll style={{ width: '70vw' }} onHide={() => setShowHistoryDialog(false)} draggable={false}>
                <HistoryDialog onHideHistoryDialog={onHideHistoryDialog} switchData={switchData} />
            </Dialog>

            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="rrn" className={classNames({ 'p-error': isFormFieldValid('rrn') }, 'Label__Text')}>
                                    RRN
                                </label>
                                <InputText maxLength={100} id="rrn" name="rrn" value={formik?.values?.rrn} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('rrn') }, 'Input__Round')} />
                                {getFormErrorMessage('rrn')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="stan" className={classNames({ 'p-error': isFormFieldValid('stan') }, 'Label__Text')}>
                                    STAN
                                </label>
                                <InputText maxLength={100} id="stan" name="stan" value={formik?.values?.stan} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('stan') }, 'Input__Round')} />
                                {getFormErrorMessage('stan')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transactionCode" className={classNames({ 'p-error': isFormFieldValid('transactionCode') }, 'Label__Text')}>
                                    Transaction ID
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="transactionCode"
                                    name="transactionCode"
                                    value={formik?.values?.transactionCode}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transactionCode') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('transactionCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    From Date
                                </label>

                                <InputText
                                    max={previousDate}
                                    id="dateFrom"
                                    placeholder=""
                                    name="dateFrom"
                                    type="date"
                                    value={formik?.values?.dateFrom?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    To Date
                                </label>

                                <InputText
                                    max={previousDate}
                                    id="dateTo"
                                    placeholder=""
                                    name="dateTo"
                                    type="date"
                                    value={formik?.values?.dateTo?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pb-3">
                            <div className="p-field">
                                <label htmlFor="productName" className={classNames({ 'p-error': isFormFieldValid('productName') }, 'Label__Text')}>
                                    Product Name
                                </label>
                                <Dropdown
                                    id="productName"
                                    placeholder="Select Created By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="productName"
                                    optionValue="lovId"
                                    value={formik.values.productName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productName') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('productName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('status') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="status"
                                    placeholder="Select Status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="status"
                                    optionValue="lovId"
                                    value={formik.values.status || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('status') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('status')}
                            </div>
                        </div>
                        <div className="Down__Btn mt-4">
                            <Button label="Search" iconPos="right" className="Btn__Dark" />
                            <Button label="Reset" iconPos="right" className="Btn__Transparent" onClick={(e) => handleReset(e)} />
                        </div>
                    </div>
                </form>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            filters={filters}
                            // globalFilterFields={['rrn', 'productName', 'updatedBy', 'createDate', 'lastupdatedate', 'socket.stan']}
                            header={header}
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={switchConfigData}
                        >
                            <Column field="transactiondatetime" header="Trx Time" />
                            <Column field="transactionamount" header="Amount" />
                            <Column field="stan" header="STAN" />
                            <Column field="rrn" header="RRN" />
                            <Column field="productname" header="Product Name" />
                            <Column field="transactionCode" header="Transaction ID" />
                            <Column body={statusBodyTemplate} header="Status" />
                            <Column header="Action" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};
export default SAF;

import { Button } from 'primereact/button';
import React from 'react';

const Sonic = () => {
    const handledSonic = () => {
        window.open('https://sonic.pk/cod/login', '_blank');
    };
    const handledM3Tech = () => {
        window.open('https://rmmc.m3tech.com.pk/', '_blank');
    };
    const handledCRM = () => {
        window.open('https://crm365.jsbl.com/JSBL', '_blank');
    };
    return (
        <>
            <div className="grid col-12 h-25rem mt-8 flex justify-content-center align-items-center flex-row gap-5">
                <div className="edit-ac-buttons mb-5 h-10rem" onClick={handledSonic}>
                    <div>Go to SONIC</div>
                </div>
                <div className="edit-ac-buttons mb-5 h-10rem" onClick={handledM3Tech}>
                    <div>Go to M3 TECH</div>
                </div>
                <div className="edit-ac-buttons h-10rem" onClick={handledCRM} style={{ whiteSpace: 'nowrap', marginTop: '-27px' }}>
                    <div>Go to Complaints (CRM)</div>
                </div>
            </div>
        </>
    );
};

export default Sonic;

import React, { useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { handlePostRequest } from '../../../../services/PostTemplate'
import { useDispatch } from 'react-redux'
import { baseURL } from '../../../../Config'

const EditPetroPocketStatement = ({ rowData, onHide }) => {

    const [loading, setLoading] = useState(false)
    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")

    const dispatch = useDispatch()

    const handleStatement = async (type) => {
        try {
            let body = {
                "data": {
                    "security": {
                        "userName": "",
                        "password": "",
                        "securityToken": ""
                    },
                    "account": {
                        "msidn": "",
                        "iban": "",
                        "bban": "",
                        "pan": "",
                        "currency": ""
                    },
                    "channel": "",
                    "terminal": "",
                    "reterivalReferenceNumber": "",
                    "payLoad": {
                        "email": rowData?.email,
                        "cnic": rowData?.cnic,
                        "accountNumber": rowData?.petroAccountNumber,
                        "mobileNumber": rowData?.mobileNo,
                        "fromDate": fromDate,
                        "toDate": toDate,
                        "customerId": rowData?.customerId
                    },
                    "additionalInformation": [
                        {
                            "infoKey": "",
                            "infoValue": ""
                        }
                    ],
                    "checkSum": ""
                }
            }

            if (type === "send") {
                // Send request
                await dispatch(handlePostRequest(body, '/account/v1/petroaccount/sendpetroaccountstatment', false, false));
            } else {
                // Download statement
                const res = await dispatch(handlePostRequest(body, '/account/v1/petroaccount/downloadpetrostatment', false, false));

                if (res?.responseCode === '010000') {
                    const path = res?.payLoad?.path.split('/opt/wildfly/standalone/documents/accountStatement/')[1];
                    window.open(`${baseURL}/document/accountStatement/${path}`, '_blank');
                } else {
                    console.error("Error: Invalid response code", res);
                }
            }

            // Hide modal or whatever UI element
            onHide();
        } catch (error) {
            console.error("An error occurred while processing the statement:", error);
            // Optionally, show an error message to the user
            // showErrorNotification('Failed to process the statement. Please try again later.');
        }
    }


    return (
        <div className='Card__Round'>
            <div className="p-fluid p-formgrid grid">
                <div className="p-field col-12">
                    <label className='Label__Text'>Email</label>
                    <InputText
                        value={rowData?.email}
                        className='Input__Round'
                        disabled
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid grid">
                <div className="p-field col-12">
                    <label className='Label__Text'>From</label>
                    <InputText
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                        className='Input__Round'
                        type='date'
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid grid">
                <div className="p-field col-12">
                    <label className='Label__Text'>To</label>
                    <InputText
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                        className='Input__Round'
                        type='date'
                    />
                </div>
            </div>

            <div className="Down__Btn">
                <Button label="Send" type="submit" className="Btn__Dark" onClick={() => handleStatement("send")} />
                <Button label="Download" className="Btn__Dark" type="button" onClick={() => handleStatement("download")} loading={loading} />
                <Button
                    label="Cancel"
                    onClick={(e) => {
                        e.preventDefault();
                        onHide(false);
                    }}
                    // disabled={loading}
                    className="Btn__Transparent"
                />
            </div>

        </div>
    )
}

export default EditPetroPocketStatement
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import * as Yup from 'yup';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function EditCreditScoringEngineChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [selectedType, setSelectedType] = useState([]);
    const [categoryTableData, setCategoryTableData] = useState([]);
    const [categoryData, setCategoryData] = useState({
        categoryName: '',
        weightInPercentage: '',
        weightScoreValue: '',
        creditScoringSubCategoryRequests: []
    });
    const [categoryError, setCategoryError] = useState({
        categoryName: '',
        weightInPercentage: '',
        weightScoreValue: ''
    });

    const [subCategoryTableData, setSubCategoryTableData] = useState([]);

    const [subCategoryData, setSubCategoryData] = useState({
        subCategoryName: '',
        weightInPercentage: '',
        weightScoreValue: '',
        maxValue: '',
        isMandatory: '',
        categoryName: '',
        creditScoringNameRequests: []
    });

    const [subCategoryError, setSubCategoryError] = useState({
        subCategoryName: '',
        weightInPercentage: '',
        weightScoreValue: '',
        maxValue: '',
        isMandatory: '',
        categoryName: ''
    });

    const [creditScoringNameData, setCreditScoringNameData] = useState({ name: '', score: '', subCategoryName: '' });
    const [creditScoringNameError, setCreditScoringNameError] = useState({ name: '', score: '', subCategoryName: '' });

    const [creditScoringNameTableData, setCreditScoringNameTableData] = useState([]);

    const [statusLovData, setStatusLovData] = useState([]);
    const [mcRequestIdData, setMcRequestIdData] = useState({});

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const subCategoriesArrayData = [];
    const cseNamesArrayData = [];

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    const getLmsCseTypeLOV = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/lending/LKP_LMS_CSE_TYPE');
        setSelectedType(res?.payLoad);
    };

    const getCreditScoringEngineById = async () => {
        const resp = await handleGetRequest(`/lending/v1/lending/getscorecardbyid/${refTableId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik?.setFieldValue(key, keyData[key]);
                }
            });

            formik.setFieldValue('lmsCseCardName', keyData?.lmsCseCardName);
            formik.setFieldValue('lmsCseTypeId', keyData?.lkpLmsCseType?.lmsCseTypeId.toString());

            const getTblLmsCseCardCategories = keyData.tblLmsCseCardCategories;

            const cardCategories = getTblLmsCseCardCategories
                ?.filter((category) => category.isActive === 'Y')
                ?.map((category) => {
                    const updatedCategory = { ...category };

                    // Update keys in the category object
                    updatedCategory.weightInPercentage = updatedCategory.weightPercentage;
                    updatedCategory.weightScoreValue = updatedCategory.weightScore;

                    return updatedCategory;
                });

            if (cardCategories) {
                cardCategories.forEach((value) => {
                    const categoryName = value.categoryName;
                    // subCategoriesArrayData.push(value?.tblLmsCseCardSubCategories);
                    if (value.tblLmsCseCardSubCategories) {
                        value.tblLmsCseCardSubCategories
                            ?.filter((category) => category.isActive === 'Y')
                            ?.forEach((singleSubCategory) => {
                                subCategoriesArrayData.push({
                                    categoryName,
                                    weightInPercentage: singleSubCategory.weightPercentage,
                                    weightScoreValue: singleSubCategory.weightScore,
                                    ...singleSubCategory
                                });
                            });
                    }
                });
            }

            setCategoryTableData(cardCategories);
            setSubCategoryTableData(subCategoriesArrayData?.flat());
        }
    };

    const getMcRequestDataById = async () => {
        const resp = await handleGetRequest(`/lending/v1/lending/getmcrequestbyid/${mcRequestId}`);
        const keyData = resp?.payLoad;

        setMcRequestIdData(keyData);

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik?.setFieldValue(key, keyData[key]);
                }
            });

            formik.setFieldValue('lmsCseCardName', keyData?.lmsCseCardName);
            formik.setFieldValue('lmsCseTypeId', keyData?.lmsCseTypeId.toString());

            const getTblLmsCseCardCategories = keyData.creditScoringCategoryRequests;

            const cardCategories = getTblLmsCseCardCategories.map((category) => {
                const updatedCategory = { ...category };

                // // Update keys in the category object
                // updatedCategory.weightInPercentage = updatedCategory.weightPercentage;
                // updatedCategory.weightScoreValue = updatedCategory.weightScore;

                return updatedCategory;
            });

            if (cardCategories) {
                cardCategories.forEach((value) => {
                    const categoryName = value.categoryName;
                    // subCategoriesArrayData.push(value?.tblLmsCseCardSubCategories);
                    if (value.creditScoringSubCategoryRequests) {
                        value.creditScoringSubCategoryRequests.forEach((singleSubCategory) => {
                            subCategoriesArrayData.push({
                                categoryName,
                                // weightInPercentage: singleSubCategory.weightPercentage,
                                // weightScoreValue: singleSubCategory.weightScore,
                                ...singleSubCategory
                            });
                        });
                    }
                });
            }

            setCategoryTableData(cardCategories);
            setSubCategoryTableData(subCategoriesArrayData?.flat());
        }
    };

    const getMcRequestDataByIdU = async () => {
        const resp = await handleGetRequest(`/lending/v1/lending/getmcrequestbyid/${mcRequestId}`);
        const keyData = resp?.payLoad;

        setMcRequestIdData(keyData);
    };

    useEffect(() => {
        getLmsCseTypeLOV();
        getStatusLov();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (requestType === 'I') {
            subCategoryTableData
                ?.filter((category) => category.isActive === 'Y')
                ?.forEach((item) => {
                    const subCategoryName = item.subCategoryName;
                    // cseNamesArrayData.push(item?.tblLmsCseNames);
                    if (item.creditScoringNameRequests || item.tblLmsCseNames) {
                        (item.creditScoringNameRequests || item.tblLmsCseNames).forEach((cseName) => {
                            cseNamesArrayData.push({
                                subCategoryName,
                                ...cseName
                            });
                        });
                    }
                });

            setCreditScoringNameTableData(cseNamesArrayData?.flat());
        } else {
            subCategoryTableData?.forEach((item) => {
                const subCategoryName = item.subCategoryName;
                // cseNamesArrayData.push(item?.tblLmsCseNames);
                if (item.creditScoringNameRequests || item.tblLmsCseNames) {
                    (item.creditScoringNameRequests || item.tblLmsCseNames).forEach((cseName) => {
                        cseNamesArrayData.push({
                            subCategoryName,
                            ...cseName
                        });
                    });
                }
            });

            setCreditScoringNameTableData(cseNamesArrayData?.flat());
        }
    }, [subCategoryTableData]); // eslint-disable-line

    useEffect(() => {
        if (requestType === 'I') {
            getCreditScoringEngineById();
        } else if (requestType === 'U') {
            if (requestTypeDescr === 'Enable/Disable Request') {
                getCreditScoringEngineById();
                getMcRequestDataByIdU();
            } else {
                getMcRequestDataById();
            }
        }
    }, []); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        action: Yup.mixed().required('This field is required.'),
        checkerComments: Yup.mixed().required('This field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            lmsCseTypeId: '',
            lmsCseCardName: '',
            checkerComments: '',
            action: ''
        },

        validate: (data) => {
            let errors = {};

            if (!data.lmsCseTypeId) {
                errors.lmsCseTypeId = 'This Field is required.';
            }

            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newDataForICase = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        creditScoringRequest: {
                            lmsCseCardId: refTableId,
                            requestType: requestType,
                            isActive: 'Y'
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const newDataForUCase = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        creditScoringRequest: mcRequestIdData,
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(requestType === 'I' ? newDataForICase : newDataForUCase, '/lending/v1/lending/scorecardcheckeraction', true, false, 'requestchecker'));
            if (res?.responseCode === '190000') {
                formik.resetForm();
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handledDeleteTransClassData = (e, rowData) => {
        e.preventDefault();
        const newFilterData = categoryTableData.filter((value) => value.id !== rowData.id);
        setCategoryTableData(newFilterData);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button tooltip="Delete" disabled tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteTransClassData(e, rowData)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
                </div>
            </>
        );
    };

    const handledFieldInputcategoryData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        // const checked = e.target.checked;
        // const type = e.target.type;

        setCategoryData({ ...categoryData, [name]: value });

        // Clear the error message for the field being edited
        setCategoryError({
            ...categoryError,
            [name]: ''
        });
    };

    const handledCategoryAdd = (e) => {
        e.preventDefault();

        const errors = {
            categoryName: '',
            weightInPercentage: '',
            weightScoreValue: ''
        };

        if (categoryData.categoryName === '') {
            errors.categoryName = 'This field is required.';
        }

        if (categoryData.weightInPercentage === '') {
            errors.weightInPercentage = 'This field is required.';
        }

        if (categoryData.weightScoreValue === '') {
            errors.weightScoreValue = 'This field is required.';
        }

        // Check for duplicate CategoryName
        if (categoryTableData.some((item) => item.categoryName === categoryData.categoryName)) {
            errors.categoryName = 'This Category is already in use.';
        }

        setCategoryError(errors);

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            const newCategoryTableData = {
                categoryName: categoryData.categoryName,
                weightInPercentage: categoryData.weightInPercentage,
                weightScoreValue: categoryData.weightScoreValue
            };
            setCategoryTableData([...categoryTableData, newCategoryTableData]);

            setCategoryData({ categoryName: '', weightInPercentage: '', weightScoreValue: '' });
        }
    };

    const handledSubCategoryAdd = (e) => {
        e.preventDefault();

        const errors = {
            subCategoryName: '',
            weightInPercentage: '',
            weightScoreValue: '',
            maxValue: '',
            isMandatory: '',
            categoryName: ''
        };

        if (subCategoryData.subCategoryName === '') {
            errors.subCategoryName = 'This field is required.';
        }

        if (subCategoryData.weightInPercentage === '') {
            errors.weightInPercentage = 'This field is required.';
        }

        if (subCategoryData.weightScoreValue === '') {
            errors.weightScoreValue = 'This field is required.';
        }

        // Check for duplicate Sub CategoryName
        if (subCategoryTableData.some((item) => item.subCategoryName === subCategoryData.subCategoryName)) {
            errors.subCategoryName = 'This Sub Category Name is already in use.';
        }

        setSubCategoryError(errors);

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            const sunCategoryObjData = {
                subCategoryName: subCategoryData.subCategoryName,
                weightInPercentage: subCategoryData.weightInPercentage,
                weightScoreValue: subCategoryData.weightScoreValue,
                maxValue: subCategoryData.maxValue,
                isMandatory: subCategoryData.isMandatory,
                categoryName: subCategoryData.categoryName
            };
            setSubCategoryTableData([...subCategoryTableData, sunCategoryObjData]);

            setSubCategoryData({ subCategoryName: '', weightInPercentage: '', weightScoreValue: '', maxValue: '', isMandatory: '', categoryName: '' });
        }
    };

    const handledFieldInputSubCategoryData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        const checked = e.target.checked;
        const type = e.target.type;

        setSubCategoryData({ ...subCategoryData, [name]: type === 'checkbox' ? checked : value });

        // Clear the error message for the field being edited
        setSubCategoryError({
            ...subCategoryError,
            [name]: ''
        });
    };

    const handledDeleteSubCategoryData = (e, rowData) => {
        e.preventDefault();
        const newFilterData = subCategoryTableData.filter((item) => item.subCategoryName !== rowData?.subCategoryName);
        setSubCategoryTableData(newFilterData);
    };

    const actionSubCategoryBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button tooltip="Delete" disabled tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteSubCategoryData(e, rowData)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
                </div>
            </>
        );
    };

    const handledFieldsDataAdd = (e) => {
        e.preventDefault();

        const errors = {
            name: '',
            score: ''
        };

        if (creditScoringNameData.name === '') {
            errors.name = 'This field is required.';
        } else if (!/^[^\s]+(\s[^\s]+)*$/.test(creditScoringNameData?.name)) {
            errors.name = 'Leading and trailing space is not allowed';
        } else if (!/^[A-Za-z]+$/.test(creditScoringNameData.name)) {
            errors.name = 'Field Name contain only alphabets.';
        }

        if (creditScoringNameData.name !== '') {
            if (creditScoringNameData.score === '') {
                errors.score = 'This field is required.';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(creditScoringNameData?.score)) {
                errors.score = 'Leading and trailing space is not allowed';
            } else if (!/^\d+$/.test(creditScoringNameData.score)) {
                errors.score = 'Field Name contain only numeric values.';
            }
        }

        // Check for duplicate fieldName
        if (creditScoringNameTableData.some((item) => item.name === creditScoringNameData.name)) {
            errors.name = 'This field name is already in use.';
        }

        setCreditScoringNameError(errors);

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            const fieldsObjData = {
                name: creditScoringNameData.name,
                score: creditScoringNameData.score,
                subCategoryName: creditScoringNameData.subCategoryName
            };
            setCreditScoringNameTableData([...creditScoringNameTableData, fieldsObjData]);

            setCreditScoringNameData({ name: '', score: '', subCategoryName: '' });
        }
    };

    const handledFieldInputData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        setCreditScoringNameData({ ...creditScoringNameData, [name]: value });

        // Clear the error message for the field being edited
        setCreditScoringNameError({
            ...creditScoringNameError,
            [name]: ''
        });
    };

    const handledDeleteCreditScoringNameData = (e, rowData) => {
        e.preventDefault();
        const newFilterData = creditScoringNameTableData.filter((value) => value.name !== rowData.name);
        setCreditScoringNameTableData(newFilterData);
    };

    const actionFieldsBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button tooltip="Delete" disabled tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteCreditScoringNameData(e, rowData)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
                </div>
            </>
        );
    };

    const SelectMandatory = [
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' }
    ];

    const sumCategoryWeightInPercentage = categoryTableData.reduce((sum, item) => sum + parseInt(item.weightInPercentage, 10), 0);
    // const sumSubCategoryWeightInPercentage = subCategoryTableData.reduce((sum, item) => sum + parseInt(item.weightInPercentage, 10), 0);

    const totalWeightScore = sumCategoryWeightInPercentage;

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>EDIT CREDIT SCORING ENGINE CHECKER</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lmsCseCardName" className={classNames({ 'p-error': isFormFieldValid('lmsCseCardName') }, 'Label__Text')}>
                                    Credit Score Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="lmsCseCardName"
                                    placeholder="Enter Credit Score Name"
                                    name="lmsCseCardName"
                                    maxLength={30}
                                    disabled
                                    value={formik?.values?.lmsCseCardName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lmsCseCardName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('lmsCseCardName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lmsCseTypeId" className={classNames({ 'p-error': isFormFieldValid('lmsCseTypeId') }, 'Label__Text')}>
                                    Credit Score Type <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    maxLength={100}
                                    id="lmsCseTypeId"
                                    options={selectedType}
                                    placeholder="Select Type"
                                    name="lmsCseTypeId"
                                    optionLabel="name"
                                    optionValue="lovId"
                                    disabled
                                    value={formik?.values?.lmsCseTypeId?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lmsCseTypeId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('lmsCseTypeId')}
                            </div>
                        </div>
                    </div>
                    <div className="card pt-3" style={{ background: '#f8f8f8' }}>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="Form__Header Full__Width flex justify-content-between">
                                <h2>CREDIT SCORE</h2>
                                <span>{`TOTAL WEIGHT: ${totalWeightScore}`}</span>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="categoryName" className={classNames({ 'p-error': isFormFieldValid('categoryName') }, 'Label__Text')}>
                                        Category Name<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="categoryName"
                                        placeholder="Enter Category Name"
                                        name="categoryName"
                                        maxLength={5}
                                        disabled={true}
                                        value={categoryData.categoryName?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={handledFieldInputcategoryData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('categoryName') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{categoryError.categoryName}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="weightInPercentage" className={classNames({ 'p-error': isFormFieldValid('weightInPercentage') }, 'Label__Text')}>
                                        Weight in %<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="weightInPercentage"
                                        placeholder="Enter Weight In Percentage"
                                        name="weightInPercentage"
                                        maxLength={5}
                                        disabled={true}
                                        value={categoryData.weightInPercentage?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={handledFieldInputcategoryData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('id') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{categoryError.weightInPercentage}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="weightScoreValue" className={classNames({ 'p-error': isFormFieldValid('weightScoreValue') }, 'Label__Text')}>
                                        Weight Score Value<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="weightScoreValue"
                                        placeholder="Enter Weight Score Value"
                                        name="weightScoreValue"
                                        maxLength={5}
                                        disabled={true}
                                        value={categoryData.weightScoreValue?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={handledFieldInputcategoryData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('weightScoreValue') }, 'Input__Round')}
                                    />

                                    <small className="p-error">{categoryError.weightScoreValue}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 mr-5 mt-4">
                                <div className="Down__Btn__Configuration">
                                    <Button type="submit" disabled loadingIcon={loadingIcon} label="Add" onClick={handledCategoryAdd} className="Btn__Add" />
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12">
                                <div className="card">
                                    <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={categoryTableData}>
                                        <Column field="categoryName" header="Category Name" />
                                        <Column field="weightInPercentage" header="Weight In %" />
                                        <Column field="weightScoreValue" header="Weight Score Value" />
                                        <Column header="Actions" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* form and data table account type */}

                    <div className="card pt-3" style={{ background: '#f8f8f8' }}>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="subCategoryName" className={classNames({ 'p-error': isFormFieldValid('subCategoryName') }, 'Label__Text')}>
                                        Sub Category Name<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="subCategoryName"
                                        placeholder="Enter Sub Category Name"
                                        name="subCategoryName"
                                        disabled={true}
                                        value={subCategoryData.subCategoryName || ''}
                                        onChange={handledFieldInputSubCategoryData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('subCategoryName') }, 'Input__Round')}
                                    />

                                    <small className="p-error">{subCategoryError.subCategoryName}</small>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="weightInPercentage" className={classNames({ 'p-error': isFormFieldValid('weightInPercentage') }, 'Label__Text')}>
                                        Weight In %<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="weightInPercentage"
                                        placeholder="Enter Weight In Percentage"
                                        name="weightInPercentage"
                                        disabled={true}
                                        value={subCategoryData.weightInPercentage || ''}
                                        onChange={handledFieldInputSubCategoryData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('weightInPercentage') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{subCategoryError.weightInPercentage}</small>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="weightScoreValue" className={classNames({ 'p-error': isFormFieldValid('weightScoreValue') }, 'Label__Text')}>
                                        Weight Score Value<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="weightScoreValue"
                                        placeholder="Enter Weight Score value"
                                        name="weightScoreValue"
                                        disabled={true}
                                        value={subCategoryData?.weightScoreValue || ''}
                                        onChange={handledFieldInputSubCategoryData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('weightScoreValue') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{subCategoryError.weightScoreValue}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="maxValue" className={classNames({ 'p-error': isFormFieldValid('maxValue') }, 'Label__Text')}>
                                        Max Value<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="maxValue"
                                        placeholder="Enter Max. value"
                                        name="maxValue"
                                        disabled={true}
                                        value={subCategoryData?.maxValue || ''}
                                        onChange={handledFieldInputSubCategoryData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('maxValue') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{subCategoryError.maxValue}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="isMandatory" className={classNames({ 'p-error': isFormFieldValid('isMandatory') }, 'Label__Text')}>
                                        Mandatory<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        maxLength={100}
                                        id="isMandatory"
                                        options={SelectMandatory}
                                        placeholder="Select Mandatory"
                                        name="isMandatory"
                                        optionLabel="label"
                                        optionValue="value"
                                        disabled
                                        value={subCategoryData?.isMandatory || ''}
                                        onChange={handledFieldInputSubCategoryData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('isMandatory') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('isMandatory')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="categoryName" className={classNames({ 'p-error': isFormFieldValid('categoryName') }, 'Label__Text')}>
                                        Category Name<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        maxLength={100}
                                        id="categoryName"
                                        options={categoryTableData}
                                        placeholder="Select Category Name"
                                        name="categoryName"
                                        optionLabel="categoryName"
                                        optionValue="categoryName"
                                        disabled
                                        value={subCategoryData?.categoryName || ''}
                                        onChange={handledFieldInputSubCategoryData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('categoryName') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('categoryName')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-2 mr-5 mt-4">
                                <div className="Down__Btn__Configuration">
                                    <Button type="submit" disabled label="Add" onClick={handledSubCategoryAdd} className="Btn__Add" />
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12">
                                <div className="card">
                                    <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={subCategoryTableData}>
                                        <Column header="Sub Category Name" field="subCategoryName" />
                                        <Column header="Weight In %" field="weightInPercentage" />
                                        <Column header="Weight Score Value" field="weightScoreValue" />
                                        <Column header="Max. Value" field="maxValue" />
                                        <Column header="Mandatory" field="isMandatory" />
                                        <Column header="Category Name" field="categoryName" />
                                        <Column header="Actions" body={actionSubCategoryBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Form and data table Fields related */}

                    <div className="card pt-3" style={{ background: '#f8f8f8' }}>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                        Name
                                        <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="name"
                                        placeholder="Enter Score Name"
                                        name="name"
                                        disabled={true}
                                        value={creditScoringNameData.name || ''}
                                        onChange={handledFieldInputData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{creditScoringNameError.name}</small>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="score" className={classNames({ 'p-error': isFormFieldValid('score') }, 'Label__Text')}>
                                        Value
                                        <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="score"
                                        placeholder="Enter Score Value"
                                        name="score"
                                        disabled={true}
                                        value={creditScoringNameData.score || ''}
                                        onChange={handledFieldInputData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('score') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{creditScoringNameError.score}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="subCategoryName" className={classNames({ 'p-error': isFormFieldValid('subCategoryName') }, 'Label__Text')}>
                                        Sub Category Name<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        maxLength={100}
                                        id="subCategoryName"
                                        options={subCategoryTableData}
                                        placeholder="Select Sub Category Name"
                                        name="subCategoryName"
                                        optionLabel="subCategoryName"
                                        optionValue="subCategoryName"
                                        disabled
                                        value={creditScoringNameData?.subCategoryName || ''}
                                        onChange={handledFieldInputData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('subCategoryName') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('subCategoryName')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-2 mr-5 mt-4">
                                <div className="Down__Btn__Configuration">
                                    <Button type="submit" disabled label="Add" onClick={(e) => handledFieldsDataAdd(e)} className="Btn__Add" />
                                </div>
                            </div>
                        </div>

                        <div className="grid">
                            <div className="col-12">
                                <div className="card">
                                    <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={creditScoringNameTableData}>
                                        <Column field="name" header="Name" />
                                        <Column field="score" header="Value" />
                                        <Column field="subCategoryName" header="Sub Category Name" />
                                        <Column header="Actions" body={actionFieldsBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 mt-2">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('action')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    autoResize="false"
                                    rows={5}
                                    cols={30}
                                    maxLength={500}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') === 'null' ? '' : formik?.values?.checkerComments}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />
                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="submit" label="Update" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default EditCreditScoringEngineChecker;

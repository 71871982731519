import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../services/PostTemplate';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../services/GetTemplate';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

function DynamicRuleCheckerEdit() {
    const [loading, setloading] = useState(false);
    const [statusLovData, setStatusLovData] = useState([]);
    const [historyData, setHistoryData] = useState([]);
    const [addData, setAddData] = useState([]);
    const [editData, setEditData] = useState([]);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    //formik
    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            action: '',
            checkerComments: ''
        },

        onSubmit: async (data) => {
            setloading(true);

            const addPayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ruleFieldDTO: {
                            name: addData?.name,
                            description: addData?.description,
                            id: addData?.ruleFieldId,
                            active: data?.action === '2' ? 'Y' : 'N'
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: 18,
                            checkerComments: data?.checkerComments,
                            action: data?.action,
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const updatePayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ruleFieldDTO: {
                            name: editData?.name,
                            description: editData?.description,
                            id: editData?.id,
                            active: editData?.active
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: 18,
                            checkerComments: data?.checkerComments,
                            action: data?.action,
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const enableDisablePayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ruleFieldDTO: {
                            name: addData?.name,
                            description: addData?.description,
                            id: addData?.ruleFieldId,
                            active: editData?.active
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: 18,
                            checkerComments: data?.checkerComments,
                            action: data?.action,
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            //API call for checker action
            if (requestType === 'I') {
                await dispatch(handlePostRequest(addPayload, '/ruleengine/v1/rule/rulefield/ruleFieldCheckerAction', true, false, 'requestchecker'));
            } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
                await dispatch(handlePostRequest(updatePayload, '/ruleengine/v1/rule/rulefield/ruleFieldCheckerAction', true, false, 'requestchecker'));
            } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
                await dispatch(handlePostRequest(enableDisablePayload, '/ruleengine/v1/rule/rulefield/ruleFieldCheckerAction', true, false, 'requestchecker'));
            }
        }
    });

    //get Data by table Id
    const getDatabyId = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    id: refTableId
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/ruleengine/v1/rule/rulefield/'));
        setAddData(res?.payLoad);

        formik.setFieldValue('name', res?.payLoad?.name);
        formik.setFieldValue('description', res?.payLoad?.description);
    };

    //Get data by mc request Id
    const getMcRequestDataById = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    id: mcRequestId
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/ruleengine/v1/rule/rulefield/checker'));
        setEditData(res?.payLoad);
        if (requestTypeDescr !== 'Enable/Disable Request') {
            formik.setFieldValue('name', res?.payLoad?.name);
            formik.setFieldValue('description', res?.payLoad?.description);
        }
    };

    //get status list
    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = res?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    //Function to get the authorization history
    const getAuthorizationHistoryData = async () => {
        const resp = await handleGetRequest(`/workflow/v1/management/getActionAuthorizationHistory/${refTableId}/${tableName}`);
        setHistoryData(resp?.payLoad);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getMcRequestDataById();
        } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
            getDatabyId();
            getMcRequestDataById();
        }
        getStatusListData();
        getAuthorizationHistoryData();
    }, []); // eslint-disable-line

    //Functions to handle validations error
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid">
                        <div className="Form__Header Full__Width">
                            <h2>Edit Detail</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="name" className="Label__Text">
                                    Name<span className="Label__Required">*</span>
                                </label>
                                <InputText id="name" name="name" placeholder="Please Enter Channel Name" value={formik.values.name} onChange={formik.handleChange} className="Input__Round" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="description" className="Label__Text">
                                    Description<span className="Label__Required">*</span>
                                </label>
                                <InputText id="description" name="description" placeholder="Please Enter Channel Description" value={formik.values.description} onChange={formik.handleChange} className="Input__Round" disabled />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="p-fluid p-formgrid grid ">
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="action" className="Label__Text">
                                        Action <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="action"
                                        placeholder="Select Action"
                                        options={statusLovData}
                                        optionLabel="name"
                                        name="action"
                                        optionValue="lovId"
                                        value={formik.values.action || ''}
                                        onChange={formik.handleChange}
                                        className="Dropdown__Round"
                                    />

                                    {getFormErrorMessage('action')}
                                </div>
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid grid">
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="checkerComments" className="Label__Text">
                                        Comments
                                        <span className="Label__Required">*</span>
                                    </label>
                                    <InputTextarea
                                        autoResize="false"
                                        rows={5}
                                        cols={30}
                                        maxLength={500}
                                        id="checkerComments"
                                        name="checkerComments"
                                        value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ')}
                                        onChange={formik.handleChange}
                                        className="TextArea__Round"
                                    />

                                    {getFormErrorMessage('checkerComments')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} type="submit" label="Update" className="Btn__Dark" />
                        <Button onClick={() => navigate('/requestchecker')} type="button" label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            emptyMessage="No List found."
                            header="Action Authorization History"
                            responsiveLayout="scroll"
                            value={historyData}
                        >
                            <Column field="escalationLevel" header="Excalation Level" />
                            <Column field="checkedBy" header="Checked By" />
                            <Column field="checkedOn" header="Checked On" />
                            <Column field="authorizerComments" header="Authorizor Comments" />
                            <Column field="intimationOn" header="Intimation On" />
                            <Column field="intimationTo" header="Intimation To" />
                            <Column field="authorizationStatus" header="Authorization Status" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DynamicRuleCheckerEdit;

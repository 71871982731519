import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

const UltraDualNationalityFields = ({ rowData, userTabsFieldData }) => {
    const lowerCasedCountryName = rowData?.countryName.toLowerCase();

    const checkFieldPermissions = (fieldName) => {
        const field = userTabsFieldData.find((f) => f.fieldName === fieldName);
        return {
            viewAllowed: field?.viewAllowed === 'Y',
            updateAllowed: field?.updateAllowed === 'Y'
        };
    };

    const countryNameFieldPermissions = checkFieldPermissions('countryName');
    const greenCardHolderFieldPermissions = checkFieldPermissions('greenCardHolder');
    const usBornFieldPermissions = checkFieldPermissions('usBorn');
    const usTelNoFieldPermissions = checkFieldPermissions('usTelNo');
    const usSignAuthFieldPermissions = checkFieldPermissions('usSignAuth');
    const usLinksFieldPermissions = checkFieldPermissions('usLinks');
    const fullAddressFieldPermissions = checkFieldPermissions('fullAddress');
    const mailingAddressFieldPermissions = checkFieldPermissions('mailingAddress');
    const federalTaxClassificationNameFieldPermissions = checkFieldPermissions('federalTaxClassificationName');
    const taxIdentificationNumberFieldPermissions = checkFieldPermissions('taxIdentificationNumber');
    const jurisdictionOfTaxResidenceNameFieldPermissions = checkFieldPermissions('jurisdictionOfTaxResidenceName');
    const residenceAddressFieldPermissions = checkFieldPermissions('residenceAddress');
    const birthPlaceFieldPermissions = checkFieldPermissions('birthPlace');

    return (
        <>
            <div className="p-fluid p-formgrid grid">
                {countryNameFieldPermissions.viewAllowed && (
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Country</label>
                            <Dropdown placeholder={rowData?.countryName ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                        </div>
                    </div>
                )}
                {lowerCasedCountryName === 'us' ? (
                    <>
                        {greenCardHolderFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">US Citizen/ US Resident/ or Holding a U.S Permanent Resident Card (Green Card)</label>
                                    <Dropdown placeholder={rowData?.greenCardHolder === 'Y' ? 'Yes' : rowData?.greenCardHolder === 'N' ? 'No' : ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                </div>
                            </div>
                        )}
                        {usBornFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Born in US</label>
                                    <Dropdown placeholder={rowData?.usBorn === 'Y' ? 'Yes' : rowData?.usBorn === 'N' ? 'No' : ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                </div>
                            </div>
                        )}
                        {usTelNoFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">US Telephone Number or Address</label>
                                    <Dropdown placeholder={rowData?.usTelNo === 'Y' ? 'Yes' : rowData?.usTelNo} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                </div>
                            </div>
                        )}
                        {usSignAuthFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Assigning Signatory Authority/ Mandate to a Person with US Address</label>
                                    <Dropdown placeholder={rowData?.usSignAuth === 'Y' ? 'Yes' : rowData?.usSignAuth === 'N' ? 'No' : ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                </div>
                            </div>
                        )}
                        {usLinksFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">U.S. Links</label>
                                    <Dropdown placeholder={rowData?.usLinks === 'Y' ? 'Yes' : rowData?.usLinks === 'N' ? 'No' : ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                </div>
                            </div>
                        )}
                        {rowData?.greenCardHolder === 'N' && rowData?.usBorn === 'N' ? (
                            ''
                        ) : (
                            <>
                                {rowData?.usTelNo !== 'Y' ? (
                                    <>
                                        {fullAddressFieldPermissions.viewAllowed && (
                                            <div className="p-field col-12 md:col-4">
                                                <div className="p-field">
                                                    <label className="Label__Text">Permanent Address</label>
                                                    <Dropdown placeholder={rowData?.fullAddress ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                                </div>
                                            </div>
                                        )}
                                        {mailingAddressFieldPermissions.viewAllowed && (
                                            <div className="p-field col-12 md:col-4">
                                                <div className="p-field">
                                                    <label className="Label__Text">Mailing Address</label>
                                                    <InputText placeholder={rowData?.mailingAddress ?? ''} className="Input__Round mr-2" disabled />
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {federalTaxClassificationNameFieldPermissions.viewAllowed && (
                                            <div className="p-field col-12 md:col-4">
                                                <div className="p-field">
                                                    <label className="Label__Text">Federal tax Classification</label>
                                                    <Dropdown placeholder={rowData?.federalTaxClassificationName === 'Y' ? 'Yes' : rowData?.federalTaxClassificationName === 'N' ? 'No' : ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                                </div>
                                            </div>
                                        )}
                                        {fullAddressFieldPermissions.viewAllowed && (
                                            <div className="p-field col-12 md:col-4">
                                                <div className="p-field">
                                                    <label className="Label__Text">Address</label>
                                                    <InputText placeholder={rowData?.fullAddress ?? ''} className="Input__Round mr-2" disabled />
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}

                                {taxIdentificationNumberFieldPermissions.viewAllowed && (
                                    <div className="p-field col-12 md:col-4">
                                        <div className="p-field">
                                            <label className="Label__Text">Tax Identification Number</label>
                                            <InputText placeholder={rowData?.taxIdentificationNumber ?? ''} className="Input__Round mr-2" disabled />
                                        </div>
                                    </div>
                                )}
                                {rowData?.usTelNo !== 'Y' ? (
                                    <div className="p-field col-12 md:col-4">
                                        <div className="p-field">
                                            <label className="Label__Text">Reference Number</label>
                                            <InputText placeholder="" className="Input__Round mr-2" disabled />
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {jurisdictionOfTaxResidenceNameFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Country/Jurisdiction of Tax Residence</label>
                                    <Dropdown placeholder={rowData?.jurisdictionOfTaxResidenceName ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                </div>
                            </div>
                        )}
                        {taxIdentificationNumberFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Tax Identification Number</label>
                                    <InputText placeholder={rowData?.taxIdentificationNumber ?? ''} className="Input__Round mr-2" disabled />
                                </div>
                            </div>
                        )}
                        {residenceAddressFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Residence Address</label>
                                    <InputText placeholder={rowData?.residenceAddress ?? ''} className="Input__Round mr-2" disabled />
                                </div>
                            </div>
                        )}
                        {birthPlaceFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Country of Birth</label>
                                    <Dropdown placeholder={rowData?.birthPlace ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default UltraDualNationalityFields;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';

function TypeOfAccountOldNewValues({ typeOfAccountRowData }) {
    const [accountClassificationData, setAccountClassificationData] = useState([]);
    const [kycSetsData, setKycSetsData] = useState([]);

    useEffect(() => {
        if (typeOfAccountRowData !== null && typeof typeOfAccountRowData !== 'undefined' && typeof typeOfAccountRowData !== undefined) {
            if (typeOfAccountRowData !== null && typeof typeOfAccountRowData !== 'undefined') {
                Object.keys(typeOfAccountRowData).forEach((key) => {
                    if (formik.initialValues.hasOwnProperty(key)) {
                        formik.setFieldValue(key, typeOfAccountRowData[key]);
                    }
                });

                formik.setFieldValue('kycSetHeadId', typeOfAccountRowData?.tblKycSetHead?.kycSetHeadId?.toString());
                formik.setFieldValue('lkpAccountClassificationId', typeOfAccountRowData?.lkpAccountClassification?.accountClassificationId?.toString());
            }
        }
    }, [typeOfAccountRowData]); // eslint-disable-line

    const getAccountClassificationData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getclientroles/LKP_ACCOUNT_CLASSIFICATION:*');
        setAccountClassificationData(res?.payLoad);
    };

    useEffect(() => {
        getAccountClassificationData();
    }, []); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        accountLevelName: Yup.string().required('This field is required.')
    });

    const formik = useFormik({
        validationSchema,
        initialValues: {
            lkpAccountClassificationId: '',
            accountLevelName: '',
            kycSetHeadId: '',
            dailyTransLimitDr: '',
            dailyAmtLimitDr: '',
            monthlyTransLimitDr: '',
            monthlyAmtLimitDr: '',
            yearlyTransLimitDr: '',
            yearlyAmtLimitDr: '',
            dailyTransLimitCr: '',
            dailyAmtLimitCr: '',
            monthlyTransLimitCr: '',
            monthlyAmtLimitCr: '',
            yearlyTransLimitCr: '',
            yearlyAmtLimitCr: '',
            maxAmtLimit: ''
        },

        onSubmit: async (data) => {}
    });

    const getUserLov = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getkycsets/TBL_KYC_SET_HEAD:*/${formik.values.lkpAccountClassificationId}`);
        setKycSetsData(resp?.payLoad);
    };

    useEffect(() => {
        if (formik.values.lkpAccountClassificationId !== '') {
            getUserLov();
        }
    }, [formik.values.lkpAccountClassificationId]); // eslint-disable-line

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>VIEW DETAILS</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lkpAccountClassificationId" className={classNames({ 'p-error': isFormFieldValid('lkpAccountClassificationId') }, 'Label__Text')}>
                                    Select Type Of Account
                                </label>
                                <Dropdown
                                    id="lkpAccountClassificationId"
                                    placeholder="Select Type Of Account"
                                    options={accountClassificationData}
                                    optionLabel="name"
                                    name="lkpAccountClassificationId"
                                    optionValue="lovId"
                                    disabled={true}
                                    value={formik.values.lkpAccountClassificationId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpAccountClassificationId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('lkpAccountClassificationId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountLevelName" className={classNames({ 'p-error': isFormFieldValid('accountLevelName') }, 'Label__Text')}>
                                    Name Of Account
                                </label>
                                <InputText
                                    id="accountLevelName"
                                    placeholder="Enter Name Of Account"
                                    name="accountLevelName"
                                    disabled={true}
                                    value={formik?.values?.accountLevelName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountLevelName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('accountLevelName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="kycSetHeadId" className={classNames({ 'p-error': isFormFieldValid('kycSetHeadId') }, 'Label__Text')}>
                                    Select KYC Set
                                </label>
                                <Dropdown
                                    id="kycSetHeadId"
                                    placeholder="Select KYC Set"
                                    options={kycSetsData}
                                    optionLabel="name"
                                    name="kycSetHeadId"
                                    optionValue="lovId"
                                    disabled={true}
                                    value={formik.values.kycSetHeadId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('kycSetHeadId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('kycSetHeadId')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-6 pt-3 pb-3">
                            <h3>Debit</h3>
                            <div className="Debit__Heading">
                                <div>
                                    <p>Particular</p>
                                </div>
                                <div>
                                    <p>Count</p>
                                </div>
                                <div>
                                    <p>Amount</p>
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Daily</p>
                                </div>
                                <div>
                                    <InputNumber inputId="dailyTransLimitDr" name="dailyTransLimitDr" disabled={true} value={formik?.values?.dailyTransLimitDr || ''} onValueChange={formik.handleChange} />
                                </div>
                                <div>
                                    <InputNumber inputId="dailyAmtLimitDr" name="dailyAmtLimitDr" disabled={true} value={formik?.values?.dailyAmtLimitDr || ''} onValueChange={formik.handleChange} />
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Monthly</p>
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="monthlyTransLimitDr"
                                        name="monthlyTransLimitDr"
                                        disabled={true}
                                        value={formik?.values?.monthlyTransLimitDr || ''}
                                        onValueChange={formik.handleChange}
                                        // className={classNames({ 'p-error': isFormFieldValid('monthlyTransLimitDr') }, 'Input__Round__DC')}
                                    />
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="monthlyAmtLimitDr"
                                        name="monthlyAmtLimitDr"
                                        disabled={true}
                                        value={formik?.values?.monthlyAmtLimitDr || ''}
                                        onValueChange={formik.handleChange}
                                        // className={classNames({ 'p-invalid': isFormFieldValid('monthlyAmtLimitDr') }, 'Input__Round__DC')}
                                    />
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Yearly</p>
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="yearlyTransLimitDr"
                                        name="yearlyTransLimitDr"
                                        disabled={true}
                                        value={formik?.values?.yearlyTransLimitDr || ''}
                                        onValueChange={formik.handleChange}
                                        // className={classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitDr') }, 'Input__Round__DC')}
                                    />
                                </div>
                                <div>
                                    <InputNumber inputId="yearlyAmtLimitDr" name="yearlyAmtLimitDr" disabled={true} value={formik?.values?.yearlyAmtLimitDr || ''} onValueChange={formik.handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-6 pt-3 pb-3">
                            <h3>Credit</h3>
                            <div className="Debit__Heading">
                                <div>
                                    <p>Particular</p>
                                </div>
                                <div>
                                    <p>Count</p>
                                </div>
                                <div>
                                    <p>Amount</p>
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Daily</p>
                                </div>
                                <div>
                                    <InputNumber inputId="dailyTransLimitCr" name="dailyTransLimitCr" disabled={true} value={formik?.values?.dailyTransLimitCr || ''} onValueChange={formik.handleChange} />
                                </div>
                                <div>
                                    <InputNumber inputId="dailyAmtLimitCr" name="dailyAmtLimitCr" disabled={true} value={formik?.values?.dailyAmtLimitCr || ''} onValueChange={formik.handleChange} />
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Monthly</p>
                                </div>
                                <div>
                                    <InputNumber inputId="monthlyTransLimitCr" name="monthlyTransLimitCr" disabled={true} value={formik?.values?.monthlyTransLimitCr || ''} onValueChange={formik.handleChange} />
                                </div>
                                <div>
                                    <InputNumber inputId="monthlyAmtLimitCr" name="monthlyAmtLimitCr" disabled={true} value={formik?.values?.monthlyAmtLimitCr || ''} onValueChange={formik.handleChange} />
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Yearly</p>
                                </div>
                                <div>
                                    <InputNumber inputId="yearlyTransLimitCr" name="yearlyTransLimitCr" disabled={true} value={formik?.values?.yearlyTransLimitCr || ''} onValueChange={formik.handleChange} />
                                </div>
                                <div>
                                    <InputNumber inputId="yearlyAmtLimitCr" name="yearlyAmtLimitCr" disabled={true} value={formik?.values?.yearlyAmtLimitCr || ''} onValueChange={formik.handleChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {(formik.values.lkpAccountClassificationId === '2' || formik.values.lkpAccountClassificationId === '3') && (
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="maxAmtLimit">
                                    <label htmlFor="maxAmtLimit" className={classNames({ 'p-error': isFormFieldValid('maxAmtLimit') }, 'Label__Text__MaxBal')}>
                                        Maximum Balance
                                    </label>
                                    <InputNumber disabled={true} inputId="maxAmtLimit" placeholder="Enter Maximum Balance" name="maxAmtLimit" maxLength={9} value={formik?.values?.maxAmtLimit || ''} onValueChange={formik.handleChange} />
                                    {getFormErrorMessage('maxAmtLimit')}
                                </div>
                            </div>
                        </div>
                    )}
                    {/* <div className="Down__Btn">
                        <Button onClick={() => navigate('/typeofaccount')} label="Okay" className="Btn__Dark" />
                    </div> */}
                </form>
            </div>
        </>
    );
}
export default TypeOfAccountOldNewValues;

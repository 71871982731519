import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../../../services/PostTemplate';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handleGetRequest } from '../../../../../services/GetTemplate';
import { MultiSelect } from 'primereact/multiselect';
import classNames from 'classnames';
import * as Yup from 'yup';
import { loadingAction } from '../../../../../redux/actions/loadingAction';
import { toast } from 'react-toastify';
import ScrollMover from '../../../../../components/ScrollMover';

function AddHandler({ parentData, viewOnly }) {
    const [loading, setloading] = useState(false);
    const [limitProfile, setLimitProfile] = useState([]);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [employeeName, setEmployeeName] = useState('');
    const [channelOption, setChannelOption] = useState([]);
    const [productCatalog, setProductCatalog] = useState([]);
    const [parent, setParent] = useState([]);
    const [kycFields, setKycFields] = useState([]);
    const [dynamicKyc, setDynamicKyc] = useState([]);
    const [useCaseError, setUseCaseError] = useState({
        empId: null
    });
    const [tblKycSetDetails, setTblKycSetDetails] = useState([]);
    const [empIdData, setEmpIdData] = useState([]);
    const [searchBtnDisable, setSearchBtnDisable] = useState(true);

    const input1 = useRef(null);
    const input2 = useRef(null);
    const input3 = useRef(null);
    const input4 = useRef(null);
    const searchEmpId = useRef(null);
    const searchParentData = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const kycFixed = ['cnic', 'mobileNumber', 'cnicIssuanceDate'];

    const accountClassificationId = parentData?.lovId;

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (e.target.name === 'empId') {
                searchEmpId.current.click();
            }
            if (e.target.name === 'parentAgentId' || e.target.name === 'ParentCnic' || e.target.name === 'parentMobileNumber' || e.target.name === 'parentNtn') {
                searchParentData.current.click();
            }
            e.preventDefault();
        }
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button type="button" onClick={handleDelete} tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" className="p-button-rounded p-button-danger" />
            </div>
        );
    };
    const agentid = parent?.agentId;

    function getMaxRegexLength(regex) {
        // Remove leading and trailing slashes from the regular expression.
        const regexPattern = regex.toString().slice(1, -1);

        // Use a regular expression to find the maximum length.
        const lengthRegex = /\{(\d+)(,(\d+))?\}/g;
        let maxLength = 0;

        let match;
        while ((match = lengthRegex.exec(regexPattern)) !== null) {
            const min = parseInt(match[1], 10);
            const max = match[3] ? parseInt(match[3], 10) : min;
            if (max > maxLength) {
                maxLength = max;
            }
        }

        return maxLength;
    }

    const initialValues = {
        transLimitId: '',
        parentAgentId: '',
        parentMobileNumber: '',
        hierarchyName: '',
        ParentCnic: '',
        parentNtn: '',
        channelIdList: [],
        empId: '',
        productCatalogId: ''
    };

    //validations
    const validationSchema = Yup.object({
        transLimitId: Yup.string().required('This Field is required'),
        // parentAgentId: Yup.string().required('Select any of these'),
        channelIdList: Yup.array().required('This Field is required').min(1, 'This Field is required'),
        empId: Yup.string().required('This Field is required'),
        hierarchyName: Yup.string().required('This Field is required'),
        productCatalogId: Yup.string().required('This Field is required'),
        ...tblKycSetDetails.reduce((fieldValidations, result) => {
            const attributeName = result?.tblKycAttribute?.attributeName;
            const regexExpression = result?.tblKycAttribute;
            const date = result?.tblKycAttribute?.attributeName.toLowerCase().includes('date');
            const regExp = new RegExp(regexExpression.tblValidator.argumentValue);
            const maxLength = getMaxRegexLength(regExp);

            if (kycFixed?.includes(result?.tblKycAttribute?.attributeName) || (result.isMandatory === 'Y' && result?.isActive === 'Y')) {
                // Validate that the field is not empty
                fieldValidations[attributeName] = Yup.string().test('dynamicValidation', 'Invalid Format', function (value) {
                    // Check if a value is entered and apply regular expression validation
                    if (value) {
                        if (value.length > maxLength && !date) {
                            if (maxLength > 0) {
                                return this.createError({
                                    path: this.path,
                                    message: `Value exceeds the maximum length of ${maxLength}`
                                });
                            }
                        }

                        return regExp.test(value);
                    } else {
                        // If no value is entered and the field is mandatory, show it as required
                        if (kycFixed?.includes(result?.tblKycAttribute?.attributeName) || result.isMandatory === 'Y') {
                            return this.createError({
                                path: this.path,
                                message: 'This Field is required'
                            });
                        }

                        // If no value is entered and the field is not mandatory, no error is shown.
                        return true;
                    }
                });
            } else if (result?.isActive === 'Y') {
                // If the item is not mandatory but active, only apply the regex validation
                fieldValidations[attributeName] = Yup.string().test('dynamicValidation', 'Invalid Format', function (value) {
                    if (value) {
                        // Check if value is not empty
                        if (value.length > maxLength && !date) {
                            if (maxLength > 0) {
                                return this.createError({
                                    path: this.path,
                                    message: `Value exceeds the maximum length of ${maxLength}`
                                });
                            }
                        }

                        return regExp.test(value);
                    }
                    // If the value is empty, no error is shown.
                    return true;
                });
            }

            return fieldValidations;
        }, {})
    });
    const formik = useFormik({
        validationSchema: validationSchema,

        initialValues: initialValues,

        onSubmit: async (data, actions) => {
            setloading(true);

            const arrayOfChannelIds = formik?.values?.channelIdList?.map((item) => {
                return parseInt(item?.lovId, 10) || 0;
            });
            if (!parent || parent?.length === 0 || parent == null) {
                return toast.warn('Please search parent data');
            }
            const nonRequiredFields = [];
            data['empId'] = empIdData;
            data['parentAgentId'] = parent?.agentId;
            kycFields[0]?.tblKycSetDetails?.forEach((item) => {
                if (kycFixed?.includes(item?.tblKycAttribute?.attributeName) && item?.isActive === 'Y') {
                    const attributeName = item?.tblKycAttribute?.attributeName;
                    data[attributeName] = formik.values[item?.tblKycAttribute?.attributeName];
                } else {
                    if (formik.values[item?.tblKycAttribute?.attributeName] && item?.isActive === 'Y') {
                        nonRequiredFields.push({
                            infoKey: item?.tblKycAttribute?.attributeName,
                            infoValue: formik.values[item?.tblKycAttribute?.attributeName]
                        });
                    }
                }
            });

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data,
                        accountClassificationName: parentData?.name,
                        contact: false,
                        channelIdList: arrayOfChannelIds
                    },
                    additionalInformation: nonRequiredFields,
                    checkSum: ''
                }
            };
            kycFields[0]?.tblKycSetDetails?.forEach((item) => {
                if (kycFixed?.includes(item?.tblKycAttribute?.attributeName) && item?.isActive === 'Y') {
                } else {
                    delete newData?.data?.payLoad[item?.tblKycAttribute?.attributeName];
                }
            });
            delete newData?.data?.payLoad?.hierarchyName;
            delete newData?.data?.payLoad?.ParentCnic;
            delete newData?.data?.payLoad?.parentMobileNumber;
            delete newData?.data?.payLoad?.parentNtn;

            formik.setFieldValue('parentAgentId', '');
            formik.setFieldValue('ParentCnic', '');
            formik.setFieldValue('parentMobileNumber', '');
            formik.setFieldValue('parentNtn', '');

            const res = await dispatch(handlePostRequest(newData, '/account/v1/agentaccount/createagentaccount', true, false, 'accountonboarding'));
            if (res.responseCode === '010000') {
            } else {
            }
            setloading(false);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    // const getKycFields = async () => {
    //     const res = await handleGetRequest(`/account/v1/agentaccount/getkycbyclassificationname/${parentData?.name}`);
    //     setKycFields(res?.payLoad);
    // };

    useEffect(() => {
        const getKycFields = async () => {
            const res = await handleGetRequest(`/account/v1/agentaccount/getkycbyclassificationname/${parentData.name}`);

            // setKycSetHeadId(res.payLoad[0].kycSetHeadId);
            let tblkyc = res?.payLoad[0].tblKycSetDetails;

            let dynamicFields = {};
            tblkyc?.map((result) => {
                dynamicFields[result?.tblKycAttribute?.attributeName] = '';
            });
            // setDynamicFields([dynamicFields])

            const dynamicInitialValues = {
                ...initialValues,
                ...dynamicFields
            };

            // Set the initial values for the form
            formik.setValues(dynamicInitialValues);
            setDynamicKyc(tblkyc);

            setKycFields(res.payLoad);
            setTblKycSetDetails(tblkyc);
        };
        getKycFields();
    }, []);

    //get parent data
    const handleParentData = async () => {
        const newdata = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    accountClassificationName: parentData.name,
                    cnic: formik.values.ParentCnic,
                    mobileNumber: formik.values.parentMobileNumber,
                    ntn: formik.values.parentNtn,
                    agentId: formik.values.parentAgentId
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newdata, '/account/v1/agentaccount/getparentagent', true, true));
        if (res?.payLoad) {
            setParent(res?.payLoad);
        }
        formik.setFieldValue('parentAgentId', '');
        formik.setFieldValue('ParentCnic', '');
        formik.setFieldValue('parentMobileNumber', '');
        formik.setFieldValue('parentNtn', '');
    };

    //empty parent data table
    const handleDelete = () => {
        setParent([]);
        if (input1.current) {
            input1.current.value = '';
        }
        if (input2.current) {
            input2.current.value = '';
        }
        if (input3.current) {
            input3.current.value = '';
        }
        if (input4.current) {
            input4.current.value = '';
        }
    };

    const panelFooterTemplate = () => {
        const length = formik.values.channelIdList ? formik.values.channelIdList.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    //search on the basis of employee ID
    const searchEmployeeId = async () => {
        setUseCaseError({ empId: null });
        if (formik.values.empId === '') {
            setUseCaseError({ empId: 'This field is required' });
        } else {
            formik.setFieldValue('hierarchyName', '');
            const id = formik.values.empId;
            if (id) {
                dispatch(loadingAction(true));
                const res = await handleGetRequest(`/account/v1/sales/getemployeebyid/${id}`);
                if (res == undefined || res) setTimeout(() => dispatch(loadingAction(false)), 1000);
                if (res?.payLoad?.length) {
                    setEmpIdData(res?.payLoad[0]?.employeeId);
                    formik.setFieldValue('hierarchyName', res?.payLoad[0]?.salesHierarchyName);
                } else {
                    formik.setFieldValue('hierarchyName', '');
                }
            }
        }
    };

    //limit profile dropdown options API
    const getLimitProfile = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getagentdatabyaccounttype/TBL_TRANS_LIMIT/${accountClassificationId}`);
        setLimitProfile(res?.payLoad);
    };

    //product catalog dropdown options API
    const getProductCatalog = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/productCatalog/TBL_PRODUCT_CATALOG:*');
        setProductCatalog(res?.payLoad);
    };

    //channel dropdown options API
    const getChannel = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchannels/LKP_CHANNEL:*');
        setChannelOption(res?.payLoad);
    };

    useEffect(() => {
        // getKycFields();
        getLimitProfile();
    }, [parentData]);

    useEffect(() => {
        getProductCatalog();
        getChannel();
    }, []);

    useEffect(() => {
        if (formik.values.parentAgentId || formik.values.ParentCnic || formik.values.parentMobileNumber || formik.values.parentNtn) {
            setSearchBtnDisable(false);
        } else {
            setSearchBtnDisable(true);
        }
    }, [formik.values.parentAgentId, formik.values.ParentCnic, formik.values.parentMobileNumber]);

    function convertToTitleCase(inputString) {
        const words = inputString.split(/(?=[A-Z])/);
        const titleCaseString = words?.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

        return titleCaseString;
    }

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const handleEmployeeIdChange = (e) => {
        // Ensure it's a numeric value and limit to 4 characters
        const inputText = e.target.value.replace(/[^0-9]/g, '').slice(0, 7);
        formik.setFieldValue('empId', inputText);
    };

    const handleParentAgentId = (e) => {
        // Ensure it's a numeric value and limit to 4 characters
        const inputText = e.target.value.replace(/[^0-9]/g, '').slice(0, 7);
        formik.setFieldValue('parentAgentId', inputText);
    };

    const handleParentCnic = (e) => {
        // Ensure it's a numeric value and limit to 4 characters
        const inputText = e.target.value.replace(/[^0-9]/g, '').slice(0, 13);
        formik.setFieldValue('ParentCnic', inputText);
    };

    const handleParentNumber = (e) => {
        // Ensure it's a numeric value and limit to 4 characters
        const inputText = e.target.value.replace(/[^0-9]/g, '').slice(0, 11);
        formik.setFieldValue('parentMobileNumber', inputText);
    };
    const handleParentNtn = (e) => {
        // Ensure it's a numeric value and limit to 4 characters
        const inputText = e.target.value.replace(/[^0-9]/g, '').slice(0, 7);
        formik.setFieldValue('parentNtn', inputText);
    };

    return (
        <>
            <div className="my-3">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {kycFields && (
                        <div className="custom-card p-3 my-3">
                            <div className="m-3 Form__Header Full__Width">
                                <p>HANDLER KYC SETS</p>
                            </div>
                            <div className="p-fluid p-formgrid grid mb-2 mx-3">
                                {kycFields[0]?.tblKycSetDetails?.map((item, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            {item?.isActive === 'Y' && (
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field withoutArrow">
                                                        <label htmlFor={item?.tblKycAttribute?.attributeName} className={classNames({ 'p-error': isFormFieldValid(item?.lovId) }, 'Label__Text')}>
                                                            {convertToTitleCase(item?.tblKycAttribute?.attributeName)}
                                                            {(kycFixed?.includes(item?.tblKycAttribute?.attributeName) || item?.isMandatory === 'Y') && <span className="Label__Required">*</span>}
                                                        </label>
                                                        <InputText
                                                            id={item?.tblKycAttribute?.attributeName}
                                                            name={item?.tblKycAttribute?.attributeName}
                                                            value={formik.values[item?.tblKycAttribute?.attributeName]}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(item?.tblKycAttribute?.attributeName) }, 'Input__Round')}
                                                            type={item?.tblKycAttribute?.attributeName.toLowerCase().includes('date') ? 'date' : 'text'}
                                                            max={item?.tblKycAttribute?.attributeName.toLowerCase().includes('date') ? previousDate : null}
                                                        />
                                                        {getFormErrorMessage(item?.tblKycAttribute?.attributeName)}
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    <div className="custom-card mb-5">
                        <div className="mx-5 mt-0 Form__Header Full__Width">
                            <p>PARENT</p>
                        </div>
                        <div className="p-fluid p-formgrid grid mb-5 mx-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="ParentCnic" className="Label__Text">
                                        CNIC
                                    </label>
                                    <InputText
                                        id="ParentCnic"
                                        name="ParentCnic"
                                        value={formik.values.ParentCnic}
                                        onChange={(e) => {
                                            handleParentCnic(e);
                                        }}
                                        className="Input__Round"
                                        ref={input1}
                                        onKeyDown={(e) => handleKeyPress(e)}
                                    />
                                    {getFormErrorMessage('ParentCnic')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="parentMobileNumber" className="Label__Text">
                                        Mobile Number
                                    </label>
                                    <InputText
                                        id="parentMobileNumber"
                                        name="parentMobileNumber"
                                        value={formik.values.parentMobileNumber}
                                        onChange={(e) => {
                                            handleParentNumber(e);
                                        }}
                                        className="Input__Round"
                                        ref={input2}
                                        onKeyDown={(e) => handleKeyPress(e)}
                                    />
                                    {getFormErrorMessage('parentMobileNumber')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="parentNtn" className="Label__Text">
                                        NTN
                                    </label>
                                    <InputText
                                        id="parentNtn"
                                        name="parentNtn"
                                        value={formik.values.parentNtn}
                                        onChange={(e) => {
                                            handleParentNtn(e);
                                        }}
                                        className="Input__Round"
                                        ref={input3}
                                        onKeyDown={(e) => handleKeyPress(e)}
                                    />
                                    {getFormErrorMessage('parentNtn')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="parentAgentId" className="Label__Text">
                                        Agent ID
                                    </label>
                                    <InputText
                                        id="parentAgentId"
                                        name="parentAgentId"
                                        value={formik.values.parentAgentId}
                                        onChange={(e) => {
                                            handleParentAgentId(e);
                                        }}
                                        className="Input__Round"
                                        ref={input4}
                                        onKeyDown={(e) => handleKeyPress(e)}
                                    />
                                    {/* {getFormErrorMessage('parentAgentId')} */}
                                </div>
                            </div>
                            <div className="Usergroup__SearchBtn p-field col-12 md:col-4 pt-3 pb-3 ">
                                <Button label="Search" type="button" ref={searchParentData} onClick={() => handleParentData()} className="Btn__Dark__Search" disabled={searchBtnDisable} />
                            </div>
                        </div>
                        <div className="grid mx-5">
                            <div className="col-12">
                                <div className="card">
                                    <DataTable className="datatable-responsive" value={parent ? (Array.isArray(parent) ? parent : [parent]) : ''} emptyMessage="No List found." responsiveLayout="scroll">
                                        <Column body={(item) => (item?.franchiseName ? item?.franchiseName : item?.agentName)} header="Name" />
                                        <Column field="cnic" header="CNIC" />
                                        <Column field="ntn" header="NTN" />
                                        <Column field="agentPhoneNo" header="Mobile Number" />
                                        <Column field="agentId" header="Agent ID" />
                                        <Column header="Action" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="transLimitId" className="Label__Text">
                                    Limit Profile<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="transLimitId"
                                    placeholder="Select Limit Profile"
                                    options={limitProfile}
                                    optionLabel="name"
                                    name="transLimitId"
                                    optionValue="lovId"
                                    value={formik.values.transLimitId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transLimitId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('transLimitId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="channelList" className="Label__Text">
                                    Channel<span className="Label__Required">*</span>
                                </label>

                                <MultiSelect
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelIdList') }, 'Dropdown__Round')}
                                    id="channelIdList"
                                    options={channelOption}
                                    placeholder="Select Channel"
                                    name="channelIdList"
                                    optionLabel="name"
                                    optionValue="lovId"
                                    panelFooterTemplate={panelFooterTemplate}
                                    filter
                                    value={formik.values.channelIdList && formik.values.channelIdList?.map((trans) => trans.lovId)}
                                    onChange={
                                        channelOption &&
                                        ((e) => {
                                            formik.setFieldValue(
                                                'channelIdList',
                                                e.value.map((lovId) => ({
                                                    lovId
                                                }))
                                            );
                                        })
                                    }
                                />
                                {getFormErrorMessage('channelIdList')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="empId" className="Label__Text">
                                    Employee ID<span className="Label__Required">*</span>
                                </label>
                                <div className="flex">
                                    <InputText
                                        id="empId"
                                        name="empId"
                                        placeholder="Enter Employee ID"
                                        value={formik.values.empId}
                                        onChange={(e) => {
                                            setUseCaseError({ empId: null });
                                            handleEmployeeIdChange(e);
                                            if (!e.target.value) {
                                                formik.setFieldValue('hierarchyName', '');
                                            }
                                        }}
                                        className={classNames({ 'p-invalid': useCaseError.empId || isFormFieldValid('empId') }, 'Input__Round')}
                                        maxLength={20}
                                        onKeyDown={(e) => handleKeyPress(e)}
                                    />
                                    <Button icon={loadingIcon || ''} ref={searchEmpId} onClick={() => searchEmployeeId()} type="button" className="Btn__Dark border-circle" style={{ height: '35px', width: '35px', marginLeft: '1rem' }} />
                                </div>
                                {useCaseError.empId ? <small className="p-error">{formik?.errors?.empId}</small> : getFormErrorMessage('empId')}
                            </div>
                        </div>
                    </div>
                    <div className="custom-card mb-5">
                        <div className="mx-5 mt-0 Form__Header Full__Width">
                            <p>EMPLOYEE ID</p>
                        </div>
                        <div className="p-fluid p-formgrid grid mb-5 mx-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="hierarchyName" className="Label__Text">
                                        Sales Hierarchy Name
                                    </label>
                                    <InputText id="hierarchyName" name="hierarchyName" disabled value={formik.values.hierarchyName} className={classNames({ 'p-invalid': isFormFieldValid('hierarchyName') }, 'Input__Round')} maxLength={30} />
                                    {getFormErrorMessage('hierarchyName')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productCatalogId" className="Label__Text">
                                    Product catalog<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="productCatalogId"
                                    placeholder="Select Product Catalog"
                                    options={productCatalog}
                                    optionLabel="name"
                                    name="productCatalogId"
                                    optionValue="lovId"
                                    value={formik.values.productCatalogId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productCatalogId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('productCatalogId')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn my-5">
                        <Button type="submit" label="Submit" className="Btn__Dark" onClick={() => ScrollMover()} />
                        <Button disabled={loading} onClick={() => navigate('/accountonboarding')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddHandler;

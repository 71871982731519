import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handlePostRequest } from '../../../services/PostTemplate';
import { RadioButton } from 'primereact/radiobutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handleGetRequest } from '../../../services/GetTemplate';
import { Dropdown } from 'primereact/dropdown';
import { toast } from 'react-toastify';

function AddParser() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [columnData, setColumnData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [accountTypeData, setAccountTypeData] = useState({
        sequence: '',
        columnName: '',
        columnType: '',
        minLength: '',
        maxLength: '',
        fixedLength: true,
        isMandatory: '',
        isDefault: ''
    });
    const [accountTypeError, setAccountTypeError] = useState({
        sequence: '',
        columnName: '',
        columnType: '',
        minLength: '',
        maxLength: '',
        fixedLength: '',
        isMandatory: '',
        isDefault: ''
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        code: Yup.string().required('This field is required'),
        description: Yup.string().required('This field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            code: '',
            description: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        code: data?.code,
                        description: data?.description,
                        tblCmsParserDetails: tableData
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            if (tableData.length <= 0) {
                toast.warning('Please  fill Parser Head Details ');
            } else {
                await dispatch(handlePostRequest(newData, '/cms/v1/cms/saveparser', true, false, 'parser'));
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });
    const removeRow = (rowData) => {
        const updatedTableData = tableData?.filter((item) => item !== rowData);

        setTableData(updatedTableData);
    };
    const handleAdd = (e) => {
        e.preventDefault();

        const errors = {
            sequence: '',
            columnName: '',
            columnType: '',
            minLength: '',
            maxLength: '',
            isMandatory: ''
        };

        if (accountTypeData.sequence === '') {
            errors.sequence = 'This field is required.';
        }
        if (accountTypeData.columnName === '') {
            errors.columnName = 'This field is required.';
        }
        if (accountTypeData.columnType === '') {
            errors.columnType = 'This field is required.';
        }
        if (accountTypeData.minLength === '') {
            errors.minLength = 'This field is required.';
        }
        if (accountTypeData.maxLength === '') {
            errors.maxLength = 'This field is required.';
        }

        if (accountTypeData.isMandatory === '') {
            errors.isMandatory = 'This field is required.';
        }
        if (accountTypeData.minLength && accountTypeData.maxLength && Number(accountTypeData.maxLength) < Number(accountTypeData.minLength)) {
            errors.maxLength = 'Max length should be greater than or equal to Min length.';
        }

        setAccountTypeError(errors);

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            const accountTypeObjData = {
                sequence: accountTypeData.sequence,
                columnName: accountTypeData?.columnName,
                columnType: accountTypeData?.columnType,
                minLength: accountTypeData?.minLength,
                maxLength: accountTypeData?.maxLength,
                fixedLength: accountTypeData.fixedLength ? 'Y' : 'N',
                isMandatory: accountTypeData?.isMandatory,
                isDefault: accountTypeData.isDefault ? 'Y' : 'N'
            };
            setTableData([...tableData, accountTypeObjData]);

            setAccountTypeData({ sequence: '', columnName: '', columnType: '', minLength: '', maxLength: '', isMandatory: '' });
        }
    };

    const handleOnchange = (e) => {
        e.preventDefault();

        const name = e.target.name;
        const value = e.target.value;
        const checked = e.target.checked;
        const type = e.target.type;

        let updatedData = { ...accountTypeData, [name]: type === 'checkbox' ? checked : value };

        // Check which checkbox was clicked and set the other checkbox's state accordingly
        if (name === 'fixedLength' && type === 'checkbox') {
            updatedData = { ...updatedData, isDefault: !checked };
        } else if (name === 'isDefault' && type === 'checkbox') {
            updatedData = { ...updatedData, fixedLength: !checked };
        }

        setAccountTypeData(updatedData);

        // Clear the error message for the field being edited
        setAccountTypeError({
            ...accountTypeError,
            [name]: ''
        });
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const actionTemplate = (rowData, rowIndex) => {
        return (
            <div>
                <Button tooltip="Edit" icon="pi pi-trash" tooltipOptions={{ position: 'top' }} type="button" onClick={() => removeRow(rowData)} className="p-button-rounded p-button-warning mr-3" />
            </div>
        );
    };

    const getColumnTypeLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_COLUMN_TYPE');
        setColumnData(res?.payLoad);
    };

    useEffect(() => {
        getColumnTypeLov();
    }, []); // eslint-disable-line
    const columnTypeBodyTemplate = (rowData) => {
        if (rowData) {
            return <div>{rowData?.columnType == 1 ? 'STRING' : rowData?.columnType == 2 ? 'Number' : ''}</div>;
        }
    };
    const mandatoryBodyTemplate = (rowData) => {
        if (rowData) {
            return <div>{rowData?.isMandatory == 'Y' ? 'YES' : rowData?.isMandatory == 'N' ? 'No' : ''}</div>;
        }
    };
    const lengthBodyTemplate = (rowData) => {
        if (rowData) {
            // return <div>{rowData?.isDefault == 'Y' ? 'Is Default' : rowData?.isDefault == 'N' ? 'Fixed' : ''}</div>;
            return <p>{rowData?.isDefault == 'Y' ? 'Is Default' : 'Fixed' && rowData.fixedLength == 'Y' ? 'Fixed' : 'Is Default'}</p>;
        }
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="Form__Header Full__Width">
                        <h2>ADD NEW</h2>
                    </div>
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-fluid p-formgrid grid mb-5 col-12 md:col-12 ">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="code" className={classNames({ 'p-error': isFormFieldValid('code') }, 'Label__Text')}>
                                        Code<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="code"
                                        placeholder="Enter Voucher Code"
                                        name="code"
                                        value={formik?.values?.code || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('code') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('code')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="desc_Text_Area">
                                    <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') }, 'Label__Text')}>
                                        Description<span className="Label__Required">*</span>
                                    </label>
                                    <InputTextarea
                                        placeholder="-"
                                        inputid="description"
                                        name="description"
                                        rows={4}
                                        cols={30}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('description') }, 'Input__Round')}
                                        value={formik?.values?.description}
                                    />
                                    {getFormErrorMessage('description')}
                                </div>
                            </div>

                            <div className="card Card__Bg__Change col-12 md:col-12 px-3">
                                <h6 className="secondary-color pt-1">
                                    <b>PARSER HEAD DETAIL</b>
                                    <span className="Label__Required">*</span>
                                </h6>
                                <div className="flex">
                                    <div className="p-fluid p-formgrid grid mb-5 col-10 md:col-10 ">
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="columnName" className={classNames({ 'p-error': isFormFieldValid('columnName') }, 'Label__Text')}>
                                                    Column Name
                                                </label>
                                                <InputText
                                                    maxLength={100}
                                                    placeholder="Enter Column Name"
                                                    id="columnName"
                                                    name="columnName"
                                                    value={accountTypeData?.columnName || ''}
                                                    onChange={handleOnchange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('columnName') }, 'Input__Round')}
                                                />

                                                <small className="p-error">{accountTypeError.columnName}</small>
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="columnType" className={classNames({ 'p-error': isFormFieldValid('typeOfColumn') }, 'Label__Text')}>
                                                    Column Type
                                                </label>
                                                <Dropdown
                                                    id="columnType"
                                                    name="columnType"
                                                    placeholder="Select Sequence"
                                                    options={columnData}
                                                    optionLabel="name"
                                                    optionValue="lovId"
                                                    value={accountTypeData?.columnType || ''}
                                                    onChange={handleOnchange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('columnType') }, 'Dropdown__Round')}
                                                />

                                                <small className="p-error">{accountTypeError.columnType}</small>
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="minLength" className={classNames({ 'p-error': isFormFieldValid('minLength') }, 'Label__Text')}>
                                                    Min Length
                                                </label>
                                                <InputText
                                                    placeholder="Enter Min Length"
                                                    maxLength={100}
                                                    id="minLength"
                                                    name="minLength"
                                                    value={accountTypeData?.minLength || ''}
                                                    onChange={handleOnchange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('minLength') }, 'Input__Round')}
                                                />
                                                {/* {getFormErrorMessage('minLength')} */}
                                                <small className="p-error">{accountTypeError.minLength}</small>
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                            <div className="p-field">
                                                <label htmlFor="maxLength" className={classNames({ 'p-error': isFormFieldValid('maxLength') }, 'Label__Text')}>
                                                    Max Length
                                                </label>
                                                <InputText
                                                    placeholder="Enter max length"
                                                    maxLength={100}
                                                    id="maxLength"
                                                    name="maxLength"
                                                    value={accountTypeData?.maxLength || ''}
                                                    onChange={handleOnchange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('maxLength') }, 'Input__Round')}
                                                />

                                                <small className="p-error">{accountTypeError.maxLength}</small>
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                            <div className="p-field">
                                                <label htmlFor="sequence" className={classNames({ 'p-error': isFormFieldValid('sequence') }, 'Label__Text')}>
                                                    Sequence
                                                </label>
                                                <InputText
                                                    placeholder="Enter sequence"
                                                    maxLength={100}
                                                    id="sequence"
                                                    name="sequence"
                                                    value={accountTypeData?.sequence || ''}
                                                    onChange={handleOnchange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('sequence') }, 'Input__Round')}
                                                />

                                                <small className="p-error">{accountTypeError.sequence}</small>
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="isMandatory" className={classNames({ 'p-error': isFormFieldValid('isMandatory') }, 'Label__Text')}>
                                                    Mandatory
                                                </label>
                                                <div className="flex">
                                                    <div className="mt-2 mr-5">
                                                        <RadioButton inputId="Yes" value="Y" name="isMandatory" onChange={handleOnchange} checked={accountTypeData.isMandatory === 'Y'} />
                                                        <label htmlFor="yes" className={classNames({ 'p-error': isFormFieldValid('Yes') }, 'Label__Text')}>
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div className="mt-2">
                                                        <RadioButton inputId="no" name="isMandatory" value="N" onChange={handleOnchange} checked={accountTypeData.isMandatory === 'N'} />
                                                        <label htmlFor="no" className={classNames({ 'p-error': isFormFieldValid('no') }, 'Label__Text')}>
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                                <small className="p-error">{accountTypeError.isMandatory}</small>
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="isDefault" className={classNames({ 'p-error': isFormFieldValid('isDefault') }, 'Label__Text')}>
                                                    Length
                                                </label>
                                                <div className="flex">
                                                    <div className="mt-2 mr-5">
                                                        <Checkbox inputId="fixedLength" name="fixedLength" checked={accountTypeData.fixedLength} onChange={handleOnchange} style={{ marginTop: '10px' }} />
                                                        <label htmlFor="fixedLength" className={classNames({ 'p-error': isFormFieldValid('fixedLength') }, 'Label__Text')}>
                                                            Fixed
                                                        </label>
                                                    </div>
                                                    <div className="mt-2">
                                                        <Checkbox inputId="isDefault" name="isDefault" checked={accountTypeData.isDefault} onChange={handleOnchange} style={{ marginTop: '10px' }} />
                                                        <label htmlFor="isDefault" className={classNames({ 'p-error': isFormFieldValid('isDefault') }, 'Label__Text')}>
                                                            Is Default
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-2 md:col-2 flex flex-column align-items-center justify-content-center mb-3">
                                        <button className="addition_Button" type="button" onClick={handleAdd}>
                                            +
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className="card">
                                <DataTable
                                    paginator
                                    rows={5}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    className="datatable-responsive"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                                    globalFilterFields={['validatorName', 'validatorType', 'createdate', 'createdBy', 'updatedBy', 'lkpStatus.statusDescr']}
                                    emptyMessage="No List found."
                                    responsiveLayout="scroll"
                                    value={tableData}
                                >
                                    <Column field="columnName" header="Column Name" />

                                    <Column header="Column Type" body={columnTypeBodyTemplate} />

                                    <Column field="minLength" header="Min Length" />
                                    <Column field="maxLength" header="Max Length" />
                                    <Column field="sequence" header="Sequence" />
                                    <Column header="Mandatory" body={mandatoryBodyTemplate} />
                                    <Column header="Length" body={lengthBodyTemplate} />

                                    <Column body={actionTemplate} header="Action" />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Submit" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/parser')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddParser;

import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import { baseURL } from '../../../Config';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import { Sidebar } from 'primereact/sidebar';
import SegmentOldNewValues from './SegmentOldNewValues';

function EditCheckerSegment() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [businessTypeData, setBusinessTypeData] = useState([]);
    const [reasonData, setReasonData] = useState([]);
    const [kamData, setKamData] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const proofOfProfessionRef = useRef(null);
    const [proofOfProfessionFileName, setProofOfProfessionFileName] = useState('');
    const [proofOfprofessionBase64, setProofOfprofessionBase64] = useState('');
    const [discrepantData, setDiscrepantData] = useState([]);
    const [base64, setbase64] = useState();
    const [fileLink, setFileLink] = useState();

    const [oldNewData, setOldNewData] = useState([]);
    const [highlightedKeys, setHighlightedKeys] = useState([]);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [segmentRowData, setSegmentRowData] = useState();

    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const getDatabyId = async () => {
        const resp = await handleGetRequest(`/account/v1/segment/getSegmentById/${refTableId}`);
        const keyData = resp?.payLoad;
        setbase64(resp?.payLoad?.base64content);
        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    const value = keyData[key] === null ? '' : keyData[key];
                    formik?.setFieldValue(key, value);
                }
            });
            setProofOfProfessionFileName(keyData?.uploadAgreement?.slice(34));
            setFileLink(`${baseURL}/document/${resp?.payLoad?.uploadAgreement?.split('/documents/')[1]}`);
            formik.setFieldValue('businessTypeId', keyData?.lkpBusinessType?.businessTypeId?.toString());
            formik.setFieldValue('salesRoleDetailId', keyData?.tblSalesRoleDetail?.salesRoleDetailId?.toString());
            formik.setFieldValue('regionId', keyData?.lkpRegion?.regionId?.toString());
            formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
            formik.setFieldValue('salaryStatus', keyData?.salaryStatus === 'Y' || keyData?.salaryStatus === 'true' ? true : false);
        }
    };

    const getMcRequestDataById = async () => {
        setloading(true);
        const res = await handleGetRequest(`/account/v1/segment/getSegmentsUpdateCheckerById/${mcRequestId}`);
        const keyData = res?.payLoad;
        setloading(false);
        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    const value = keyData[key] === null ? '' : keyData[key];
                    formik?.setFieldValue(key, value);
                }
            });
            // const date = keyData?.agreementSigningDate ? new Date(keyData?.agreementSigningDate) : '';
            // const formattedDate = keyData?.agreementSigningDate ? date?.toString().split('T')[0] : '';
            // formik.setFieldValue('agreementSigningDate', formattedDate);
            formik.setFieldValue('businessTypeId', keyData?.businessTypeId?.toString());
            formik.setFieldValue('salesRoleDetailId', keyData?.salesRoleDetailId?.toString());
            formik.setFieldValue('regionId', keyData?.regionId?.toString());
            formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
            formik.setFieldValue('salaryStatus', keyData?.salaryStatus === 'Y' || keyData?.salaryStatus === 'true' ? true : false);
            if (keyData?.uploadAgreement && keyData?.uploadAgreement?.includes('documents')) {
                setProofOfProfessionFileName('Agreement.pdf');
                setFileLink(`${baseURL}/document/${res?.payLoad?.uploadAgreement?.split('/documents/')[1]}`);
            } else if (keyData?.uploadAgreement) {
                setProofOfProfessionFileName('Agreement.pdf');
                createFileFromBase64(keyData?.uploadAgreement);
            }
            if (keyData?.base64content) {
                createFileFromBase64(keyData?.base64content);
            }
        }
    };

    const createFileFromBase64 = (base64String) => {
        const linkSource = `${base64String}`;
        const byteCharacters = atob(linkSource);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const objectURL = URL.createObjectURL(blob);
        setFileLink(objectURL);
    };

    const getByBothIds = async () => {
        const res = await handleGetRequest(`/account/v1/segment/getSegmentsUpdateCheckerById/${mcRequestId}`);
        const resp = await handleGetRequest(`/account/v1/segment/getSegmentById/${refTableId}`);
        const getByMcRequest = res?.payLoad;
        const getById = resp?.payLoad;
        if (getById !== null && typeof getById !== 'undefined') {
            Object.keys(getById).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    if (key !== 'uploadAgreement' || key !== 'isActive') {
                        formik?.setFieldValue(key, getById[key]);
                    }
                }
            });
            setbase64(getById?.base64content);
            formik.setFieldValue('businessTypeId', getById?.lkpBusinessType?.businessTypeId?.toString());
            formik.setFieldValue('salesRoleDetailId', getById?.tblSalesRoleDetail?.salesRoleDetailId?.toString());
            formik.setFieldValue('regionId', getById?.lkpRegion?.regionId?.toString());
            formik.setFieldValue('salaryStatus', getById?.salaryStatus === 'Y' || getById?.salaryStatus === 'true' ? true : false);
            setProofOfProfessionFileName('Agreement.pdf');
            createFileFromBase64(getById?.base64content);
        }
        if (getByMcRequest !== null && typeof getByMcRequest !== 'undefined') {
            formik.setFieldValue('isActive', getByMcRequest?.isActive === 'Y' ? true : false);
        }
    };

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestTypeDescr === 'Enable/Disable Request') {
            getByBothIds();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, []); // eslint-disable-line

    const getBusinessTypeData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getbusinesstypes/LKP_BUSINESS_TYPE:*');
        setBusinessTypeData(res?.payLoad);
    };

    const getReasonData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getallregions/LKP_REGION:*');
        setReasonData(resp?.payLoad);
    };

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        getBusinessTypeData();
        getReasonData();
        getStatusLov();
    }, []); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        checkerComments: Yup.string().required('This Field is required.'),
        action: Yup.string().required('This Field is required.')
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            businessTypeId: '',
            segmentName: '',
            salesRoleDetailId: '',
            regionId: '',
            agreementSigningDate: '',
            uploadAgreement: '',
            clientPocName: '',
            clientMobileNo: '',
            clientEmail: '',
            companyAddress: '',
            isActive: false,
            segmentDescr: '',
            checkerComments: '',
            action: '',
            salaryStatus: false
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['isActive'] = data?.isActive ? 'Y' : 'N';
            if (requestTypeDescr !== 'Enable/Disable Request') {
                delete data['isActive'];
            }

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        segmentRequest: {
                            businessTypeId: data?.businessTypeId,
                            segmentName: data?.segmentName,
                            salesRoleDetailId: data?.salesRoleDetailId,
                            regionId: data?.regionId,
                            agreementSigningDate: data?.agreementSigningDate,
                            uploadAgreement: requestTypeDescr === 'Enable/Disable Request' ? base64 : requestType === 'U' ? data?.uploadAgreement : base64,
                            clientPocName: data?.clientPocName,
                            clientMobileNo: data?.clientMobileNo,
                            clientEmail: data?.clientEmail,
                            companyAddress: data?.companyAddress,
                            isActive: data['isActive'],
                            segmentDescr: data?.segmentDescr,
                            segmentId: refTableId,
                            salaryStatus: data?.salaryStatus === false ? 'N' : 'Y'
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(newData, '/account/v1/segment/segmentCheckerAction', true, false, 'requestchecker'));
            if (res?.responseCode === '0000') {
                formik.resetForm();
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const splitCnicFrontFileName = discrepantData?.cnicFrontPic ? discrepantData?.cnicFrontPic.split('/').pop() : '';
    const splitProofOfProfessionFileName = discrepantData?.proofOfProfessionPic ? discrepantData?.proofOfProfessionPic.split('/').pop() : '';
    const handleProofOfProfessionClick = () => {
        proofOfProfessionRef.current.click();
    };

    const handleProofOfProfessionChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            setProofOfProfessionFileName(selectedFile.name); // Update the selected file name
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                setProofOfprofessionBase64(base64Data);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const getKamData = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getkamByBusinessType/${formik.values.businessTypeId}`);
        setKamData(resp?.payLoad);
    };

    useEffect(() => {
        if (formik.values.businessTypeId !== '' || formik.values.businessType !== undefined) {
            getKamData();
        }
    }, [formik.values.businessTypeId]); // eslint-disable-line

    const getOldNewData = async () => {
        const resp = await handleGetRequest(`/workflow/v1/management/compareValues/${mcRequestId}`);
        setOldNewData(resp?.payLoad);
    };

    useEffect(() => {
        if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getOldNewData();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        const keys = oldNewData?.map((item) => item.key);
        setHighlightedKeys(keys);
    }, [oldNewData]); // eslint-disable-line

    const HandledOldValue = async (e) => {
        e.preventDefault();
        setDisplayDialog(true);
        const resp = await handleGetRequest(`/account/v1/segment/getSegmentById/${refTableId}`);
        setSegmentRowData(resp?.payLoad);
    };

    return (
        <>
            <Sidebar header="View Segment" position="right" style={{ width: '70vw' }} visible={displayDialog} onHide={() => setDisplayDialog(false)}>
                <SegmentOldNewValues segmentRowData={segmentRowData} />
            </Sidebar>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>EDIT DETAILS</h2>
                            {requestType === 'U' && <Button label="View Old Details" className="Btn__Dark__OldValue" onClick={HandledOldValue} />}
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="businessTypeId" className={classNames({ 'p-error': isFormFieldValid('businessTypeId') }, 'Label__Text')}>
                                    Business Type
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="businessTypeId"
                                    placeholder="Select Business Type"
                                    options={businessTypeData}
                                    optionLabel="name"
                                    name="businessTypeId"
                                    optionValue="lovId"
                                    value={formik.values.businessTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('businessTypeId')
                                            ? classNames({ 'p-invalid': isFormFieldValid('businessTypeId') }, 'Dropdown__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('businessTypeId') }, 'Dropdown__Round')
                                    }
                                />

                                {getFormErrorMessage('businessTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="segmentName" className={classNames({ 'p-error': isFormFieldValid('segmentName') }, 'Label__Text')}>
                                    Client Profile Name
                                </label>
                                <InputText
                                    disabled={true}
                                    id="segmentName"
                                    placeholder="Enter profile name"
                                    name="segmentName"
                                    value={formik?.values?.segmentName || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('segmentName')
                                            ? classNames({ 'p-invalid': isFormFieldValid('segmentName') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('segmentName') }, 'Input__Round')
                                    }
                                />
                                {getFormErrorMessage('segmentName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="salesRoleDetailId" className={classNames({ 'p-error': isFormFieldValid('salesRoleDetailId') }, 'Label__Text')}>
                                    KAM
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="salesRoleDetailId"
                                    placeholder="Select Kam"
                                    options={kamData}
                                    optionLabel="name"
                                    name="salesRoleDetailId"
                                    optionValue="lovId"
                                    value={formik.values.salesRoleDetailId || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('salesRoleDetailId')
                                            ? classNames({ 'p-invalid': isFormFieldValid('salesRoleDetailId') }, 'Dropdown__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('salesRoleDetailId') }, 'Dropdown__Round')
                                    }
                                />

                                {getFormErrorMessage('salesRoleDetailId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="regionId" className={classNames({ 'p-error': isFormFieldValid('regionId') }, 'Label__Text')}>
                                    Region
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="regionId"
                                    placeholder="Select Region"
                                    options={reasonData}
                                    optionLabel="name"
                                    name="regionId"
                                    optionValue="lovId"
                                    value={formik.values.regionId || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('regionId') ? classNames({ 'p-invalid': isFormFieldValid('regionId') }, 'Dropdown__Round__Highlighted') : classNames({ 'p-invalid': isFormFieldValid('regionId') }, 'Dropdown__Round')
                                    }
                                />

                                {getFormErrorMessage('regionId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="agreementSigningDate" className={classNames({ 'p-error': isFormFieldValid('agreementSigningDate') }, 'Label__Text')}>
                                    Agreement Signing date
                                </label>
                                <InputText
                                    disabled={true}
                                    id="agreementSigningDate"
                                    placeholder="Enter date"
                                    name="agreementSigningDate"
                                    type="date"
                                    value={formik?.values?.agreementSigningDate || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('agreementSigningDate')
                                            ? classNames({ 'p-invalid': isFormFieldValid('agreementSigningDate') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('agreementSigningDate') }, 'Input__Round')
                                    }
                                />
                                {getFormErrorMessage('agreementSigningDate')}
                            </div>
                        </div>
                        {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="uploadAgreement" className={classNames({ 'p-error': isFormFieldValid('uploadAgreement') }, 'Label__Text')}>
                                    Upload Agreement
                                </label>
                                <InputText
                                    disabled={true}
                                    id="uploadAgreement"
                                    placeholder="Please Upload Agreement"
                                    name="uploadAgreement"
                                    type="file"
                                    // value={formik.values.uploadAgreement || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('uploadAgreement') }, 'Input__Round')}
                                />
                                 <div style={{border:'1px solid black'}}>{formik.values.uploadAgreement}</div>
                                {getFormErrorMessage('uploadAgreement')}
                            </div>
                        </div> */}
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Upload Agreement</label>
                                <div className="file-input-container w-100" onClick={handleProofOfProfessionClick}>
                                    <input type="file" style={{ display: 'none' }} ref={proofOfProfessionRef} onChange={handleProofOfProfessionChange} accept=".pdf" disabled={true} />
                                    <input
                                        type="text"
                                        // className="file-input-field Input__Round"
                                        className={
                                            highlightedKeys?.includes('uploadAgreement')
                                                ? classNames({ 'p-invalid': isFormFieldValid('uploadAgreement') }, 'file-input-field Input__Round__Highlighted')
                                                : classNames({ 'p-invalid': isFormFieldValid('uploadAgreement') }, 'Input__Round')
                                        }
                                        placeholder="Choose a file..."
                                        value={proofOfProfessionFileName ? proofOfProfessionFileName : splitProofOfProfessionFileName}
                                        readOnly
                                        disabled={true}
                                    />
                                    <span className="file-upload-icon">
                                        <i className="pi pi-paperclip" />
                                    </span>
                                </div>
                            </div>
                            {proofOfProfessionFileName && (
                                <div className="Down__Btn mt-3">
                                    <Button
                                        type="button"
                                        label="View Document"
                                        className="Btn__Dark"
                                        onClick={() => {
                                            window.open(fileLink, '_blank');
                                        }}
                                        disabled={!proofOfProfessionFileName}
                                    />
                                </div>
                            )}
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="clientPocName" className={classNames({ 'p-error': isFormFieldValid('clientPocName') }, 'Label__Text')}>
                                    Client POC Name
                                </label>
                                <InputText
                                    disabled={true}
                                    id="clientPocName"
                                    placeholder="Enter Poc Name"
                                    name="clientPocName"
                                    value={formik?.values?.clientPocName || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('clientPocName')
                                            ? classNames({ 'p-invalid': isFormFieldValid('clientPocName') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('clientPocName') }, 'Input__Round')
                                    }
                                />
                                {getFormErrorMessage('clientPocName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="clientMobileNo" className={classNames({ 'p-error': isFormFieldValid('clientMobileNo') }, 'Label__Text')}>
                                    Client Mobile Number
                                </label>
                                <InputText
                                    disabled={true}
                                    id="clientMobileNo"
                                    placeholder="Enter Mobile No"
                                    name="clientMobileNo"
                                    value={formik?.values?.clientMobileNo?.replace(/\D/g, '') || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('clientMobileNo')
                                            ? classNames({ 'p-invalid': isFormFieldValid('clientMobileNo') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('clientMobileNo') }, 'Input__Round')
                                    }
                                />
                                {getFormErrorMessage('clientMobileNo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="clientEmail" className={classNames({ 'p-error': isFormFieldValid('clientEmail') }, 'Label__Text')}>
                                    Client Email
                                </label>
                                <InputText
                                    disabled={true}
                                    id="clientEmail"
                                    placeholder="Enter client clientEmail"
                                    name="clientEmail"
                                    type="email"
                                    value={formik?.values?.clientEmail || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('clientEmail')
                                            ? classNames({ 'p-invalid': isFormFieldValid('clientEmail') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('clientEmail') }, 'Input__Round')
                                    }
                                />
                                {getFormErrorMessage('clientEmail')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="companyAddress" className={classNames({ 'p-error': isFormFieldValid('companyAddress') }, 'Label__Text')}>
                                    Company Address
                                </label>
                                <InputTextarea
                                    disabled={true}
                                    id="companyAddress"
                                    placeholder="Enter company address"
                                    name="companyAddress"
                                    value={formik?.values?.companyAddress || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('companyAddress')
                                            ? classNames({ 'p-invalid': isFormFieldValid('companyAddress') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('companyAddress') }, 'Input__Round')
                                    }
                                />
                                {getFormErrorMessage('companyAddress')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="salaryStatus" className={classNames({ 'p-error': isFormFieldValid('salaryStatus') }, 'Label__Text')}>
                                    Salary Status
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="salaryStatus" disabled={true} name="salaryStatus" value={formik?.values?.salaryStatus} checked={formik?.values?.salaryStatus} onChange={formik.handleChange} />
                                    <label htmlFor="salaryStatus" className={classNames({ 'p-error': isFormFieldValid('salaryStatus') }, 'Label__Text')}>
                                        active
                                    </label>
                                </div>
                                {getFormErrorMessage('salaryStatus')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                    Is Active
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox
                                        inputId="isActive"
                                        disabled={true}
                                        name="isActive"
                                        value={formik?.values?.isActive}
                                        onChange={formik.handleChange}
                                        checked={
                                            formik.values.isActive === false && requestTypeDescr === 'Enable/Disable Request' ? true : formik.values.isActive === true && requestTypeDescr === 'Enable/Disable Request' ? false : formik.values.isActive
                                        }
                                    />
                                    <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                        active
                                    </label>
                                </div>
                                {getFormErrorMessage('isActive')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="segmentDescr" className={classNames({ 'p-error': isFormFieldValid('segmentDescr') }, 'Label__Text')}>
                                    Description
                                </label>
                                <InputText
                                    disabled={true}
                                    id="segmentDescr"
                                    placeholder="Enter Description"
                                    name="segmentDescr"
                                    value={formik?.values?.segmentDescr || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('segmentDescr') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('segmentDescr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="requestTypeDescr" className={classNames({ 'p-error': isFormFieldValid('requestTypeDescr') }, 'Label__Text')}>
                                    Request Type Description
                                </label>
                                <InputText
                                    disabled={true}
                                    id="requestTypeDescr"
                                    placeholder="Enter Name Of Account"
                                    name="requestTypeDescr"
                                    value={
                                        formik?.values?.isActive === false && requestTypeDescr === 'Enable/Disable Request'
                                            ? 'Inactive Request'
                                            : formik?.values?.isActive === true && requestTypeDescr === 'Enable/Disable Request'
                                            ? 'Active Request'
                                            : requestTypeDescr
                                    }
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('requestTypeDescr') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('requestTypeDescr')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    optionDisabled={(option) => option?.name === 'Pending'}
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    rows={5}
                                    cols={30}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} label="Update" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default EditCheckerSegment;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Formik, useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { MultiSelect } from 'primereact/multiselect';
import { FixedCommissionInitialValues, validationSchema } from '../Components/FixedCommissionInitialValues';
import { handlePostRequest } from '../../../services/PostTemplate';
import { InputNumber } from 'primereact/inputnumber';

function EditFixedCom() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [allLovs, setAllLovs] = useState([]);
    const [commissionData, setCommissionData] = useState([]);
    const [transactions, setTransactions] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate()); // Subtract 1 day
    const previousDate = currentDate.toISOString().split('T')[0];

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const commissionId = query.get('commissionId');

    const getDatabyId = async () => {
        const res = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getcommissionprofilebyid/${commissionId}`);
        const keyData = res?.payLoad;
        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik?.setFieldValue(key, keyData[key]);
                }
            });
            setCommissionData(res?.payLoad);
            const multiselectDataProduct = keyData?.tblCommissionProducts?.map((item) => {
                return item?.tblProduct?.productId.toString(); // Convert to string
            });
            formik.setFieldValue('commissionAmount', keyData?.commissionAmount?.toString());
            formik.setFieldValue('disbursementMethodName', keyData?.lkpDisbursementMethod?.disbursementMethodName?.toString());
            formik.setFieldValue('accountClassificationId', keyData?.lkpAccountClassification?.accountClassificationId.toString());
            formik.setFieldValue('commissionGlAccountId', keyData?.tblAccount1.accountId.toString());
            formik.setFieldValue('whtGlAccountId', keyData?.tblAccount2.accountId.toString());
            formik.setFieldValue('startDate', keyData?.startDate?.slice(0, 10));
            formik.setFieldValue('endDate', keyData?.endDate?.slice(0, 10));

            if (multiselectDataProduct && multiselectDataProduct.length > 0) {
                formik.setFieldValue('products', multiselectDataProduct);
            }
        }
    };
    useEffect(() => {
        getDatabyId();
    }, []);

    const getAllLovsForCommission = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcommissionprofiledata');
        setAllLovs(resp?.payLoad);
        setTransactions(resp?.payLoad?.transactions);
    };

    useEffect(() => {
        getAllLovsForCommission();
    }, []); // eslint-disable-line

    const accounttype = allLovs?.accounttype?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });
    const glAccounts = allLovs?.glAccounts?.map((data) => {
        return {
            accountId: data?.accountId,
            accountNo: `${data?.accountNo}-${data?.accountTitle}`
        };
    });
    const disbursementMethod = allLovs?.disbursementMethod?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: FixedCommissionInitialValues,
        validate: (data) => {
            let errors = {};

            if (data?.products.length === 0) {
                errors.products = 'This field is required';
            }

            if (!data?.commissionProfileName) {
                errors.commissionProfileName = 'This field is required';
            } else if (data?.commissionProfileName === '') {
                errors.commissionProfileName = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.commissionProfileName)) {
                errors.commissionProfileName = 'Leading and trailing space is not allowed';
            } else if (!/^[a-zA-Z](?:[a-zA-Z0-9\s]*-?[a-zA-Z0-9\s]*)?$/.test(data?.commissionProfileName)) {
                errors.commissionProfileName = 'Only alpha numeric characters are allowed';
            }

            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            formik.setFieldValue('commissionAmount', data?.commissionAmount);

            data['commissionProfileId'] = commissionId;
            data['feeTypeCode'] = 'FF';

            // formik.setFieldValue('products', data?.products);
            // data['products'] = data?.products?.map((productId) => ({
            //     productId: productId
            // }));

            const productData = data?.products?.map((value) => value);

            formik.setFieldValue('products', productData);

            data['products'] = formik.values.products?.map((productId) => ({
                productId
            }));
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(newData, '/pricingprofile/v1/pricingprofile/updatecommission', true, false, 'commission'));
            if (res?.responseCode === '111001') {
                formik.resetForm();
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const WhtOption = [
        { name: 'INCLUSIVE', value: 'I' },
        { name: 'EXCLUSIVE', value: 'E' }
    ];

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>EDIT DETAIL</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                    Account Type<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="accountClassificationId"
                                    placeholder="Select Account Type"
                                    options={accounttype}
                                    optionLabel="name"
                                    name="accountClassificationId"
                                    optionValue="lovId"
                                    value={formik.values.accountClassificationId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountClassificationId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('accountClassificationId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="commissionProfileName" className={classNames({ 'p-error': isFormFieldValid('commissionProfileName') }, 'Label__Text')}>
                                    Profile Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="commissionProfileName"
                                    placeholder="Enter Profile Name"
                                    name="commissionProfileName"
                                    value={formik?.values?.commissionProfileName?.replace(/\s\s+/g, ' ')?.toString() || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('commissionProfileName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('commissionProfileName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="products" className={classNames({ 'p-error': isFormFieldValid('products') }, 'Label__Text')}>
                                    Transactions <span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    filter
                                    id="products"
                                    placeholder="Select Transaction"
                                    options={transactions}
                                    optionLabel="name"
                                    name="products"
                                    optionValue="lovId"
                                    value={formik.values.products || []}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('products') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('products')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="whtCalculationType" className={classNames({ 'p-error': isFormFieldValid('whtCalculationType') }, 'Label__Text')}>
                                    Type of WHT Calculation
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="whtCalculationType"
                                    placeholder="Select Type of Fee Calculation"
                                    options={WhtOption}
                                    optionLabel="name"
                                    name="whtCalculationType"
                                    optionValue="value"
                                    value={formik.values.whtCalculationType}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('whtCalculationType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('whtCalculationType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3">
                            <div className="p-field">
                                <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') }, 'Label__Text')}>
                                    Start Date <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    min={previousDate}
                                    max="9999-12-31"
                                    id="startDate"
                                    placeholder=""
                                    name="startDate"
                                    type="date"
                                    value={formik?.values?.startDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('startDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('startDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3">
                            <div className="p-field">
                                <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') }, 'Label__Text')}>
                                    End Date <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    min={previousDate}
                                    max="9999-12-31"
                                    id="endDate"
                                    placeholder=""
                                    name="endDate"
                                    type="date"
                                    // max={currentDate}
                                    value={formik?.values?.endDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('endDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('endDate')}
                            </div>
                        </div>
                        <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card">
                            <div className="Form__Header">
                                <h1>FIXED COMMISSION</h1>
                            </div>
                            <div style={{ backgroundColor: '#FFFFFF', padding: '3rem' }} className=" col-12 card">
                                <div className="grid">
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="commissionAmount" className={classNames({ 'p-error': isFormFieldValid('commissionAmount') }, 'Label__Text')}>
                                                Enter Commission Fee <span className="Label__Required">*</span>
                                            </label>
                                            {/* <InputNumber
                                                min={0}
                                                maxLength={15}
                                                maxFractionDigits={15}
                                                inputId="commissionAmount"
                                                placeholder="Enter Commission Fee"
                                                name="commissionAmount"
                                                value={formik?.values?.commissionAmount || ''}
                                                onValueChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('commissionAmount') }, 'Input_Number')}
                                            /> */}
                                            <InputText
                                                type="text"
                                                keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                maxLength={15}
                                                id="commissionAmount"
                                                placeholder="Enter Commission Fee"
                                                name="commissionAmount"
                                                value={formik.values.commissionAmount || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('commissionAmount') }, 'Input__Round')}
                                            />
                                            {getFormErrorMessage('commissionAmount')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="col-6 md:col-3">
                                    <div className="p-field pt-3 pb-3">
                                        <label htmlFor="commissionGlAccountId" className={classNames({ 'p-error': isFormFieldValid('commissionGlAccountId') }, 'Label__Text')}>
                                            Select Commission GL
                                            <span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="commissionGlAccountId"
                                            placeholder="Select Commission GL"
                                            options={glAccounts}
                                            optionLabel="accountNo"
                                            name="commissionGlAccountId"
                                            filter
                                            optionValue="accountId"
                                            value={formik.values.commissionGlAccountId || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('commissionGlAccountId') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('commissionGlAccountId')}
                                    </div>
                                </div>
                                <div className="col-6 md:col-3">
                                    <div className="p-field pt-3 pb-3">
                                        <label htmlFor="whtGlAccountId" className={classNames({ 'p-error': isFormFieldValid('whtGlAccountId') }, 'Label__Text')}>
                                            Select WHT GL
                                            <span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="whtGlAccountId"
                                            placeholder="Select WHT GL"
                                            options={glAccounts}
                                            optionLabel="accountNo"
                                            name="whtGlAccountId"
                                            filter
                                            optionValue="accountId"
                                            value={formik.values.whtGlAccountId || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('whtGlAccountId') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('whtGlAccountId')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="disbursementMethodName" className={classNames({ 'p-error': isFormFieldValid('disbursementMethodName') }, 'Label__Text')}>
                                    Select Disbursement Method
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="disbursementMethodName"
                                    placeholder="Select Disbursement Method"
                                    options={disbursementMethod}
                                    optionLabel="name"
                                    name="disbursementMethodName"
                                    optionValue="name"
                                    value={formik.values.disbursementMethodName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('disbursementMethodName') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('disbursementMethodName')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} label="Update" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/commission')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default EditFixedCom;

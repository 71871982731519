import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';

import { MultiSelect } from 'primereact/multiselect';

function AddOtp() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [useCaseData, setUseCaseData] = useState([]);
    const [otpTypeData, SetOtpTypeData] = useState([]);
    const [segmentsData, setSegmentsData] = useState([]);
    const [osTypeData, setOsTypeData] = useState([]);
    const [deviceData, setDeviceData] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        cmsUsecaseId: Yup.string().required('This field is required'),
        tblCmsOtpSegments: Yup.array().required('This field is required').min(1, 'This field is required'),
        // timeHours: Yup.string().required('This field is required'),
        // blockTime: Yup.string().required('This field is required')
        device: Yup.string().required('This field is required'),
        otpLength: Yup.string().required('This field is required'),
        cmsOtpTypeId: Yup.string().required('This field is required'),
        otpAttempts: Yup.string().required('This field is required'),
        blockTime: Yup.number()
            .nullable()
            .when('isTemporaryBlock', {
                is: true,
                then: Yup.number()
                    .typeError('Input must be a number')
                    // .max(24, 'Input must be less than or equal to 24 hours')

                    .transform((value, originalValue) =>
                        /^\d+(\.\d)?$/.test(originalValue) ? parseFloat(originalValue) : value
                    )

                    .required('This field is required')
                    .test(
                        'is-one-decimal',
                        'Input must have only one digit after decimal point',
                        value => /^\d+(\.\d)?$/.test(value)
                    ),
            }),
        otpTimer: Yup.number()
            .nullable()
            .typeError('Input must be a number')

            // .max(24, 'Input must be less than or equal to 24 hours')

            .test('is-one-decimal', 'Input must have only one digit after decimal point', (value) => {
                // If the field is empty, it's valid (unless required).
                if (!value) return true;
                // Check if the value matches the pattern for one decimal place.
                return /^\d+(\.\d)?$/.test(value);
            }),

        timeHours: Yup.number()
            .nullable()
            .typeError('Input must be a number')

            // .max(24, 'Input must be less than or equal to 24 hours')


            .transform((value, originalValue) => (/^\d+(\.\d)?$/.test(originalValue) ? parseFloat(originalValue) : value))
            .required('This field is required')
            .test('is-one-decimal', 'Input must have only one digit after decimal point', (value) => /^\d+(\.\d)?$/.test(value)),

    });

    const getUseCaseData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_USECASE');
        setUseCaseData(res?.payLoad);
    };

    const getOsTypeData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_OS_TYPE');
        setOsTypeData(resp?.payLoad);
    };

    const getOtpTypeData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_OTP_TYPE');
        SetOtpTypeData(resp?.payLoad);
    };

    const getSegmentsData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getsegments/LKP_SEGMENT:*');
        setSegmentsData(res?.payLoad);
    };
    const getCmsDeviceData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_DEVICES');
        setDeviceData(res?.payLoad);
    };
    useEffect(() => {
        getUseCaseData();
        getCmsDeviceData();
        getOtpTypeData();
        getOsTypeData();
        getSegmentsData();
    }, []); // eslint-disable-line

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cmsUsecaseId: '',
            cmsOsTypeId: '',
            otpFetchAndroid: true,
            otpFetchIso: false,
            device: '',
            otpLength: '',
            cmsOtpTypeId: '',
            otpAttempts: '',
            otpTimer: '',
            timeHours: '',
            isTemporaryBlock: true,
            blockTime: '',
            isPermanentBlock: false,
            isActive: '',
            tblCmsOtpSegments: []
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        cmsUsecaseId: data.cmsUsecaseId,
                        cmsOsTypeId: data.cmsOsTypeId,
                        otpFetchAndroid: data.otpFetchAndroid === true ? 'Y' : 'N',
                        otpFetchIso: data.otpFetchIso === true ? 'Y' : 'N',
                        device: data.device,
                        otpLength: data.otpLength,
                        cmsOtpTypeId: data.cmsOtpTypeId,
                        otpAttempts: data.otpAttempts,
                        otpTimer: data.otpTimer,
                        timeHours: data.timeHours,
                        isTemporaryBlock: formik?.values?.isTemporaryBlock === true ? 'Y' : 'N',
                        isPermanentBlock: formik?.values?.isPermanentBlock === true ? 'Y' : 'N',
                        blockTime: data.blockTime,
                        tblCmsOtpSegments: formik?.values?.tblCmsOtpSegments?.map((item) => {
                            return {
                                segmentId: parseInt(item?.lovId)
                            };
                        }),
                        isActive: data?.isActive
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            dispatch(handlePostRequest(newData, `/cms/v1/cms/saveotpconfig/`, false, false, 'otp'));
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>ADD NEW</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="cmsUsecaseId" className={classNames({ 'p-error': isFormFieldValid('cmsUsecaseId') }, 'Label__Text')}>
                                    Concerned Use Case
                                    <span className="Label__Required">*</span>
                                </label>

                                <Dropdown
                                    id="cmsUsecaseId"
                                    placeholder="Select Concerned Use Case"
                                    options={useCaseData}
                                    optionLabel="name"
                                    name="cmsUsecaseId"
                                    optionValue="lovId"
                                    value={formik.values.cmsUsecaseId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('type') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsUsecaseId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field customMultiselect">
                                <label htmlFor="tblCmsOtpSegments" className={classNames({ 'p-error': isFormFieldValid('tblCmsOtpSegments') }, 'Label__Text')}>
                                    Segments
                                    <span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="tblCmsOtpSegments"
                                    placeholder="Select Segments"
                                    options={segmentsData}
                                    optionLabel="name"
                                    display="chip"
                                    name="tblCmsOtpSegments"
                                    optionValue="lovId"
                                    value={formik?.values?.tblCmsOtpSegments?.map((segment) => segment.lovId || '')}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            'tblCmsOtpSegments',
                                            e?.value?.map((lovId) => ({
                                                lovId: lovId
                                            }))
                                        );
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldValid('tblCmsOtpSegments') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('tblCmsOtpSegments')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="cmsOsTypeId" className={classNames({ 'p-error': isFormFieldValid('cmsOsTypeId') }, 'Label__Text')}>
                                    OS Type
                                </label>
                                <Dropdown
                                    id="cmsOsTypeId"
                                    placeholder="Select Os Type"
                                    options={osTypeData}
                                    optionLabel="name"
                                    name="cmsOsTypeId"
                                    optionValue="lovId"
                                    value={formik.values.cmsOsTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsOsTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsOsTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <label htmlFor="otpFetch" className={classNames({ 'p-error': isFormFieldValid('otpFetch') }, 'Label__Text')}>
                                OTP Fetch
                            </label>
                            <div className="p-field flex justify-start">
                                <div className="p-field col-12 md:col-4  flex flex-row  justify-content-space-between">
                                    <div className="p-field">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="otpFetchAndroid"
                                                name="otpFetchAndroid"
                                                value={formik?.values?.otpFetchAndroid}
                                                checked={formik.values.otpFetchAndroid}
                                                // onChange={(e) => {
                                                //     const newValue = e.checked ? 'Y' : 'N';
                                                //     formik.setFieldValue('otpFetchAndroid', newValue);
                                                //     if (e.checked) {
                                                //         formik.setFieldValue('otpFetchIso', 'N');
                                                //     }
                                                // }}
                                                onChange={() => {
                                                    formik.setFieldValue('otpFetchAndroid', !formik?.values?.otpFetchAndroid);
                                                    formik.setFieldValue('otpFetchIso', !formik?.values?.otpFetchIso);
                                                }}
                                                style={{ marginTop: '10px' }}
                                            />
                                            <label htmlFor="otpFetchAndroid" className={classNames({ 'p-error': isFormFieldValid('otpFetchAndroid') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                                Android
                                            </label>
                                        </div>

                                        {getFormErrorMessage('isPermanentBlock')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-2 ">
                                    <div className="p-field">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="otpFetchIso"
                                                name="otpFetchIso"
                                                value={formik?.values?.otpFetchIso}
                                                checked={formik.values.otpFetchIso}
                                                // onChange={(e) => {
                                                //     const newValue = e.checked ? 'Y' : 'N';
                                                //     formik.setFieldValue('otpFetchIso', newValue);
                                                //     if (e.checked) {
                                                //         formik.setFieldValue('otpFetchAndroid', 'N');
                                                //     }
                                                // }}
                                                onChange={() => {
                                                    formik.setFieldValue('otpFetchIso', !formik?.values?.otpFetchIso);
                                                    formik.setFieldValue('otpFetchAndroid', !formik?.values?.otpFetchAndroid);
                                                }}
                                                style={{ marginTop: '10px' }}
                                            />
                                            <label htmlFor="otpFetchIso" className={classNames({ 'p-error': isFormFieldValid('otpFetchIso') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                                Ios
                                            </label>
                                        </div>
                                        {getFormErrorMessage('otpFetchIso')}
                                    </div>
                                </div>
                                {getFormErrorMessage('isIos')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="device" className={classNames({ 'p-error': isFormFieldValid('device') }, 'Label__Text')}>
                                    Device<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="device"
                                    placeholder="Select Device"
                                    options={deviceData}
                                    optionLabel="name"
                                    optionValue="lovId"
                                    name="device"
                                    value={formik.values.device || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('device') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('device')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="otpLength" className={classNames({ 'p-error': isFormFieldValid('otpLength') }, 'Label__Text')}>
                                    OTP Length<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={16}
                                    id="otpLength"
                                    keyfilter='int'
                                    placeholder="Enter OTP Length"
                                    name="otpLength"
                                    value={formik?.values?.otpLength?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('otpLength') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('otpLength')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="cmsOtpTypeId" className={classNames({ 'p-error': isFormFieldValid('cmsOtpTypeId') }, 'Label__Text')}>
                                    OTP Type <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="cmsOtpTypeId"
                                    placeholder="Select Otp Type"
                                    options={otpTypeData}
                                    optionLabel="name"
                                    name="cmsOtpTypeId"
                                    optionValue="lovId"
                                    value={formik.values.cmsOtpTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsOtpTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsOtpTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="otpAttempts" className={classNames({ 'p-error': isFormFieldValid('otpAttempts') }, 'Label__Text')}>
                                    OTP Attempts<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="otpAttempts"
                                    keyfilter='int'
                                    placeholder="Enter Attempts"
                                    name="otpAttempts"
                                    value={formik?.values?.otpAttempts?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('otpAttempts') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('otpAttempts')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="otpTimer" className={classNames({ 'p-error': isFormFieldValid('otpTimer') }, 'Label__Text')}>
                                    OTP Timer
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="otpTimer"
keyfilter='int'
                                    placeholder="Enter Time In Minutes"
                                    name="otpTimer"
                                    value={formik?.values?.otpTimer?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('otpTimer') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('otpTimer')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <label htmlFor="timeHours" className={classNames({ 'p-error': isFormFieldValid('timeHours') }, 'Label__Text')}>
                                Time 
                                <span className="Label__Required">*</span>
                            </label>
                            <InputText
                                maxLength={100}
                                type="text"
                                id="timeHours"

keyfilter='int'
                                placeholder="Enter Time In Minutes"
                                name="timeHours"
                                value={formik?.values?.timeHours}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('timeHours') }, 'Input__Round')}
                            />
                            {getFormErrorMessage('timeHours')}
                        </div>

                        <div className="Form__Header Full__Width">
                            <h2>BREACH RULE</h2>
                        </div>
                        <div className="p-field col-12 md:col-8 pt-3 pb-3 flex flex-row justify-content-space-between">
                        <div className="p-field col-12 md:col-4">
                            <div className="field-checkbox flex mb-2">
                                <Checkbox
                                    inputId="isTemporaryBlock"
                                    name="isTemporaryBlock"
                                    value={formik?.values?.isTemporaryBlock}
                                    checked={formik?.values?.isTemporaryBlock}
                             
                                    onChange={() => {
                                        formik.setFieldValue('isTemporaryBlock', !formik?.values?.isTemporaryBlock);
                                        formik.setFieldValue('isPermanentBlock', !formik?.values?.isPermanentBlock);
                                        formik.setFieldValue('blockTime', '');
                                    }}
                                    style={{ marginTop: '10px' }}
                                />
                                <label htmlFor="isTemporaryBlock" className={classNames({ 'p-error': isFormFieldValid('isTemporaryBlock') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                    Temporary Block
                                </label>
                            </div>
                        </div>
                            <div className="p-field col-12 md:col-4">
                            <label htmlFor="blockTime" className={classNames({ 'p-error': isFormFieldValid('blockTime') }, 'Label__Text')}>
                                Time
                                <span className="Label__Required">*</span>
                            </label>
                            <InputText
                                maxLength={100}
                                type="text"
                                id="blockTime"
                                placeholder="Enter Time In Minutes"

                                name="blockTime"
                                keyfilter='int'

                                value={formik?.values?.blockTime}
                                disabled={formik.values.isPermanentBlock}

                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('blockTime') }, 'Input__Round')}
                            />
                            {getFormErrorMessage('blockTime')}
                        </div>
                      
                       
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>
                      
                        <div className="p-field col-12 md:col-4">
                            <div className="field-checkbox flex mb-2">
                                <Checkbox
                                    inputId="isPermanentBlock"
                                    name="isPermanentBlock"
                                    value={formik?.values?.isPermanentBlock}
                                    checked={formik?.values?.isPermanentBlock}
                              
                                    onChange={() => {
                                        formik.setFieldValue('isTemporaryBlock', !formik?.values?.isTemporaryBlock);
                                        formik.setFieldValue('isPermanentBlock', !formik?.values?.isPermanentBlock);
                                        formik.setFieldValue('blockTime', '');
                                    }}
                                    style={{ marginTop: '10px' }}
                                />
                                <label htmlFor="isPermanentBlock" className={classNames({ 'p-error': isFormFieldValid('isPermanentBlock') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                    Permanent Block
                                </label>
                            </div>
                        </div>

                    </div>

                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon || ''} type="submit" label="Submit" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/Otp')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default AddOtp;

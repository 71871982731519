import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handlePostRequest } from '../../services/PostTemplate';
import { handleGetRequest } from '../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import AddTaxTypeDialog from './AddTaxTypeDialog';
import AddRegimeLevelDialog from './AddRegimeLevelDialog';
import { FilterMatchMode } from 'primereact/api';
import ViewTaxTypeDialog from './ViewTaxTypeDialog';
import ViewRegimeLevelDialog from './ViewRegimeLevelDialog';
import ActiveInActiveDialog from '../../components/ActiveInActiveDialog/ActiveInActiveDialog';
import { Paginator } from 'primereact/paginator';
import { ResetButton, SearchButton } from '../../components/SearchButton';
import { PaginationCall, PaginationTemplate } from '../../components/Pagination';

function TaxProfile() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [editable, setEditable] = useState(false);
    const [displayModalTaxType, setDisplayModalTaxType] = useState(false);
    const [displayModalViewTaxType, setDisplayModalViewTaxType] = useState(false);
    const [displayModalRegimeLevel, setDisplayModalRegimeLevel] = useState(false);
    const [displayModalRegimeLevelDialog, setDisplayModalRegimeLevelDialog] = useState(false);
    const [taxTypeRowData, setTaxTypeRowData] = useState();
    const [statusLovData, setStatusLovData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [getTaxProfileData, setGetTaxProfileData] = useState();
    const [regimeLevelRowData, setRegimeLevelRowData] = useState();
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        taxRegimeName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [showModal, setShowModal] = useState(false);

    const [idData, setIdData] = useState();
    const [isActiveData, setIsActiveData] = useState();
    const [switchRowData, setSwitchRowData] = useState();
    const [filterTypeData, setFilterTypeData] = useState();

    const [customFirst1, setCustomFirst1] = useState(0);
    const [customRows1, setCustomRows1] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [render, setRender] = useState(false);

    const dataTableRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(res?.payLoad);
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };

    useEffect(() => {
        getStatusListData();
        getUserLov();
    }, []);

    const getFilterTypeData = [{ description: 'Tax Type' }, { description: 'Regime Level' }, { description: 'New Profile' }];

    const validationSchema = Yup.object().shape({
        filterType: Yup.mixed().required('This field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            taxRegimeName: '',
            filterType: '',
            createdBy: '',
            updatedBy: '',
            dateFrom: '',
            dateTo: '',
            // createDate: '',
            statusId: '2',
            rowStart: customFirst1,
            rowEnd: customRows1 + customFirst1
        },
        validate: (data) => {
            let errors = {};
            if (data.dateTo && !data.dateFrom) {
                errors.dateFrom = 'Date From is required when Date To is provided';
            }

            if (data.dateFrom && data.dateTo && data.dateFrom > data.dateTo) {
                errors.dateTo = 'Date To must be greater than or equal to Date From';
            }
            return errors;
        },
        onSubmit: async (data) => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            dataTableRef.current.reset();

            setGetTaxProfileData('');

            const res = await dispatch(handlePostRequest(newData, '/tax/v1/getalltaxprofiles', true, true));
            if (res?.responseCode === '040000') {
                setGetTaxProfileData(res?.payLoad);
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">Tax Profile List</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const header = () => renderHeader();

    const EditTaxProfileHandled = (rowData) => {
        // let slabId = rowData?.tblTaxRates[0]?.tblTaxSlabs[0]?.taxSlabId;

        // if (rowData?.tblTaxRates[0]?.taxRateType==="F" || rowData?.tblTaxRates[0]?.taxRateType==="P") {
        //      navigate(`/edittaxprofile?reqID=${rowData?.taxRegimeId}`);
        // setEditable(true);
        // }
        // else {
        //     navigate(`/edittaxprofile?reqID=${rowData?.taxRegimeId}&slabId=${slabId}`)
        // }
        if (rowData && rowData.tblTaxRates && rowData.tblTaxRates[0]) {
            let slabId = rowData.tblTaxRates[0].tblTaxSlabs && rowData.tblTaxRates[0].tblTaxSlabs[0] ? rowData.tblTaxRates[0]?.tblTaxSlabs[0]?.taxSlabId : null;

            if (rowData.tblTaxRates[0].taxRateType === 'F' || rowData.tblTaxRates[0].taxRateType === 'P') {
                navigate(`/edittaxprofile?reqID=${rowData.taxRegimeId}`);
                setEditable(true);
            } else {
                navigate(`/edittaxprofile?reqID=${rowData.taxRegimeId}&slabId=${slabId}`);
            }
        }
    };

    const ViewTaxTypeData = (rowData) => {
        setDisplayModalViewTaxType(true);
        setTaxTypeRowData(rowData);
    };
    const viewRegimeLevelData = (rowData) => {
        setDisplayModalRegimeLevelDialog(true);
        setRegimeLevelRowData(rowData);
    };

    const EditTaxTypeHandled = (rowData) => {
        setEditable(true);
        setDisplayModalTaxType(true);
        setTaxTypeRowData(rowData);
    };

    const EditRegimeLevelHandled = (rowData) => {
        setEditable(true);
        setDisplayModalRegimeLevel(true);
        setRegimeLevelRowData(rowData);
    };

    const handleSwitchChangeTaxRegime = async () => {
        const updatedData = getTaxProfileData.map((item) => {
            if (item.taxRegimeId === idData) {
                const isActive = item.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setGetTaxProfileData(updatedData);
        let isactive = '';
        if (isActiveData === 'Y') {
            isactive = 'N';
        } else if (isActiveData === 'N') {
            isactive = 'Y';
        }
        const previousValue = isActiveData;
        const data = {
            isActive: isactive,
            taxRegimeId: idData
        };
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/tax/v1/inactivetaxprofile', true, true));
        if (res?.responseCode !== '040000') {
            const revertedData = getTaxProfileData.map((item) => {
                if (item.taxRegimeId === idData) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setGetTaxProfileData(revertedData);
        }
    };

    const handleSwitchChangeRegimeLevel = async () => {
        const updatedData = getTaxProfileData.map((item) => {
            if (item.regimeLevelId === idData) {
                const isActive = item.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setGetTaxProfileData(updatedData);
        let isactive = '';
        if (isActiveData === 'Y') {
            isactive = 'N';
        } else if (isActiveData === 'N') {
            isactive = 'Y';
        }
        const previousValue = isActiveData;
        const data = {
            isActive: isactive,
            regimeLevelId: idData,
            regimeCategory: switchRowData?.lkpRegimeCategory?.regimeCategoryId,
            regimeLevelCode: switchRowData?.regimeLevelCode,
            regimeLevelName: switchRowData?.regimeLevelName,
            regimeLevelDescr: switchRowData?.regimeLevelDescr
        };
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/tax/v1/inactiveRegimeLevel', true, true));
        if (res?.responseCode !== '040000') {
            const revertedData = getTaxProfileData.map((item) => {
                if (item.regimeLevelId === idData) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setGetTaxProfileData(revertedData);
        }
    };

    const handleSwitchChangeTaxType = async () => {
        const updatedData = getTaxProfileData.map((item) => {
            if (item.taxTypeId === idData) {
                const isActive = item.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setGetTaxProfileData(updatedData);
        let isactive = '';
        if (isActiveData === 'Y') {
            isactive = 'N';
        } else if (isActiveData === 'N') {
            isactive = 'Y';
        }
        const previousValue = isActiveData;
        const data = {
            isActive: isactive,
            taxTypeId: idData,
            taxTypeCode: switchRowData?.taxTypeCode,
            taxTypeName: switchRowData?.taxTypeName,
            applicableOn: switchRowData?.applicableOn,
            taxTypeDescr: switchRowData?.taxTypeDescr
        };
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/tax/v1/inactiveTaxType', true, true));
        if (res?.responseCode !== '040000') {
            const revertedData = getTaxProfileData.map((item) => {
                if (item.taxTypeId === idData) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setGetTaxProfileData(revertedData);
        }
    };

    const actionBodyTemplate = (rowData) => {
        setFilterTypeData(rowData?.filterType);
        return (
            <>
                <div className="actions">
                    {rowData?.filterType === 'Tax Type' ? (
                        <InputSwitch
                            checked={rowData.isActive === 'Y'}
                            disabled={['Pending', 'Rejected', 'Assign Back'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                            onChange={() => {
                                setShowModal(true);
                                // handleSwitchChangeTaxType(rowData.taxTypeId, rowData.isActive, rowData);
                                setIdData(rowData?.taxTypeId);
                                setIsActiveData(rowData?.isActive);
                                setSwitchRowData(rowData);
                            }}
                        />
                    ) : rowData?.filterType === 'Regime Level' ? (
                        <InputSwitch
                            checked={rowData.isActive === 'Y'}
                            disabled={['Pending', 'Rejected', 'Assign Back'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                            onChange={() => {
                                setShowModal(true);
                                // handleSwitchChangeRegimeLevel(rowData.regimeLevelId, rowData.isActive, rowData);
                                setIdData(rowData?.regimeLevelId);
                                setIsActiveData(rowData?.isActive);
                                setSwitchRowData(rowData);
                            }}
                        />
                    ) : (
                        <InputSwitch
                            checked={rowData.isActive === 'Y'}
                            disabled={['Pending', 'Rejected', 'Assign Back'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                            onChange={() => {
                                setShowModal(true);
                                // handleSwitchChangeTaxRegime(rowData.taxRegimeId, rowData.isActive, rowData);
                                setIdData(rowData?.taxRegimeId);
                                setIsActiveData(rowData?.isActive);
                                setSwitchRowData(rowData);
                            }}
                        />
                    )}

                    {rowData?.filterType === 'Tax Type' ? (
                        <Button
                            tooltip="Edit"
                            icon="pi pi-pencil"
                            tooltipOptions={{ position: 'top' }}
                            disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) || (rowData?.lkpStatus?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                            onClick={() => EditTaxTypeHandled(rowData)}
                            className="p-button-rounded p-button-primary"
                        />
                    ) : rowData?.filterType === 'Regime Level' ? (
                        <Button
                            tooltip="Edit"
                            icon="pi pi-pencil"
                            tooltipOptions={{ position: 'top' }}
                            disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) || (rowData?.lkpStatus?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                            onClick={() => EditRegimeLevelHandled(rowData)}
                            className="p-button-rounded p-button-primary"
                        />
                    ) : (
                        <Button
                            tooltip="Edit"
                            icon="pi pi-pencil"
                            tooltipOptions={{ position: 'top' }}
                            disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) || (rowData?.lkpStatus?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                            onClick={() => EditTaxProfileHandled(rowData)}
                            className="p-button-rounded p-button-primary"
                        />
                    )}
                    {rowData?.filterType === 'Tax Type' ? (
                        <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => ViewTaxTypeData(rowData)} className="p-button-rounded" style={{ backgroundColor: '#F2AC57', border: 'none' }} />
                    ) : rowData?.filterType === 'Regime Level' ? (
                        <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => viewRegimeLevelData(rowData)} className="p-button-rounded" style={{ backgroundColor: '#F2AC57', border: 'none' }} />
                    ) : (
                        <Button
                            tooltip="View Details"
                            tooltipOptions={{ position: 'top' }}
                            icon="pi pi-eye"
                            onClick={() => navigate(`/viewtaxprofile?reqId=${rowData?.taxRegimeId}`)}
                            className="p-button-rounded"
                            style={{ backgroundColor: '#F2AC57', border: 'none' }}
                        />
                    )}
                </div>
            </>
        );
    };

    const addNewTaxProfile = () => {
        navigate('/addtaxprofile');
    };

    const addNewTaxType = () => {
        setDisplayModalTaxType(true);
        setEditable(false);
    };

    const addNewRegimeLevel = () => {
        setDisplayModalRegimeLevel(true);
        setEditable(false);
    };

    const onHideTaxType = () => {
        setDisplayModalTaxType(false);
    };

    const onHideRegimeLevel = () => {
        setDisplayModalRegimeLevel(false);
    };
    const onHideViewTaxType = () => {
        setDisplayModalViewTaxType(false);
    };
    const onHideViewRegimeLevelDialog = () => {
        setDisplayModalRegimeLevelDialog(false);
    };

    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const taxProfileNameTemplate = (rowData) => {
        return <div>{rowData?.regimeLevelName ? rowData?.regimeLevelName : rowData?.taxTypeName ? rowData?.taxTypeName : rowData?.taxProfileName}</div>;
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.lkpStatus?.statusDescr === 'Approved' ? '#14A38B' : rowData?.lkpStatus?.statusDescr === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.lkpStatus?.statusDescr}</b>
            </p>
        );
    };
    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const handleShowModal = () => {
        setShowModal(false);
    };

    const taxTypeProps = {
        showModal,
        handleShowModal,
        handleSwitchChange: handleSwitchChangeTaxType
    };

    const regimeLevelProps = {
        showModal,
        handleShowModal,
        handleSwitchChange: handleSwitchChangeRegimeLevel
    };

    const taxRegimeProps = {
        showModal,
        handleShowModal,
        handleSwitchChange: handleSwitchChangeTaxRegime
    };

    const onCustomPageChange1 = (event) => {
        setCustomFirst1(event.first);
        setCustomRows1(event.rows);
        setCurrentPage(event.page + 1);
        setRender(true);
    };

    const RequestData = {
        taxRegimeName: formik.values.taxRegimeName,
        filterType: formik.values.filterType,
        statusId: formik.values.statusId,
        dateFrom: formik.values.dateFrom,
        dateTo: formik.values.dateTo,
        createdBy: formik.values.createdBy,
        updatedBy: formik.values.updatedBy,
        rowStart: customFirst1,
        rowEnd: customRows1 + customFirst1
    };

    return (
        <>
            {filterTypeData === 'Tax Type' ? <ActiveInActiveDialog {...taxTypeProps} /> : filterTypeData === 'Regime Level' ? <ActiveInActiveDialog {...regimeLevelProps} /> : <ActiveInActiveDialog {...taxRegimeProps} />}

            <Dialog resizable={false} header={editable ? 'UPDATE NEW TAX TYPE PROFILE' : 'ADD NEW TAX TYPE PROFILE'} visible={displayModalTaxType} blockScroll style={{ width: '30vw' }} onHide={() => setDisplayModalTaxType(false)} draggable={false}>
                <AddTaxTypeDialog onHideTaxType={onHideTaxType} taxTypeRowData={taxTypeRowData} editable={editable} setGetTaxProfileData={setGetTaxProfileData} />
            </Dialog>
            <Dialog resizable={false} header="View Tax Type" visible={displayModalViewTaxType} style={{ width: '30vw' }} blockScroll onHide={() => setDisplayModalViewTaxType(false)} draggable={false}>
                <ViewTaxTypeDialog onHideViewTaxType={onHideViewTaxType} taxTypeRowData={taxTypeRowData} />
            </Dialog>
            <Dialog resizable={false} header={editable ? 'Edit Details' : 'Add Regime Level'} blockScroll visible={displayModalRegimeLevel} style={{ width: '30vw' }} onHide={() => setDisplayModalRegimeLevel(false)} draggable={false}>
                <AddRegimeLevelDialog onHideRegimeLevel={onHideRegimeLevel} regimeLevelRowData={regimeLevelRowData} editable={editable} setGetTaxProfileData={setGetTaxProfileData} />
            </Dialog>
            <Dialog resizable={false} header="View Regime Level" visible={displayModalRegimeLevelDialog} blockScroll style={{ width: '30vw' }} onHide={() => setDisplayModalRegimeLevelDialog(false)} draggable={false}>
                <ViewRegimeLevelDialog onHideViewRegimeLevelDialog={onHideViewRegimeLevelDialog} visible={displayModalRegimeLevelDialog} regimeLevelRowData={regimeLevelRowData} />
            </Dialog>

            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Tax Type" onClick={() => addNewTaxType()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                    <Button label="Regime Level" onClick={() => addNewRegimeLevel()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                    <Button label="New Profile" onClick={() => addNewTaxProfile()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>
            <PaginationCall
                render={render}
                customFirst1={customFirst1}
                customRows1={customRows1}
                currentPage={currentPage}
                dataTableRef={dataTableRef}
                setData={setGetTaxProfileData}
                RequestData={RequestData}
                url={'/tax/v1/getalltaxprofiles'}
                responseCode={'040000'}
            />
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>Tax Profile</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="taxRegimeName" className={classNames({ 'p-error': isFormFieldValid('taxRegimeName') }, 'Label__Text')}>
                                    Name
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="taxRegimeName"
                                    placeholder="Enter Name"
                                    name="taxRegimeName"
                                    value={formik?.values?.taxRegimeName?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('taxRegimeName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('taxRegimeName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="filterType" className={classNames({ 'p-error': isFormFieldValid('filterType') }, 'Label__Text')}>
                                    Filter Type<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="filterType"
                                    placeholder="Select Filter Type"
                                    options={getFilterTypeData}
                                    optionLabel="description"
                                    name="filterType"
                                    optionValue="description"
                                    value={formik.values.filterType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('filterType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('filterType')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="statusId" className={classNames({ 'p-error': isFormFieldValid('statusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select Status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText
                                    max={previousDate}
                                    id="dateFrom"
                                    type="date"
                                    name="dateFrom"
                                    value={formik?.values?.dateFrom}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText max={previousDate} id="dateTo" type="date" name="dateTo" value={formik?.values?.dateTo} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')} />

                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Updated By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <SearchButton />
                        <ResetButton onClick={handleReset} />
                    </div>
                </form>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            className="datatable-responsive"
                            globalFilterFields={['taxRegimeName', 'taxTypeName', 'regimeLevelName', 'createuser', 'lastupdateuser', 'createdate', 'filterType', 'lkpStatus.statusName']}
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={getTaxProfileData}
                        >
                            <Column body={taxProfileNameTemplate} header="Tax Profile Name" />
                            <Column field="createdBy" header="Created By" />
                            <Column field="updatedBy" header="Updated By" />
                            <Column body={createdDateAndTimeBodyTemplate} header="Created Date & Time" />
                            <Column field="filterType" header="Filter Type" />
                            <Column header="Status" body={statusBodyTemplate} />
                            <Column header="Actions" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                        <Paginator template={PaginationTemplate(false)} first={customFirst1} rows={customRows1} totalRecords={getTaxProfileData?.[0]?.count} onPageChange={onCustomPageChange1}></Paginator>
                    </div>
                </div>
            </div>
        </>
    );
}
export default TaxProfile;

import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import ActiveInActiveDialog from '../../../components/ActiveInActiveDialog/ActiveInActiveDialog';

const Error = () => {
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [statusData, setStatusData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [switchData, setSwitchData] = useState([]);
    const [errorData, setErrorData] = useState([]);
    const [userLovData, setUserLovData] = useState([]);
    const [useCaseData, setUseCaseData] = useState([]);
    const [errorCat, setErrorCat] = useState([]);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            moduleId: '',
            usecaseId: '',
            messageTypeId: '',
            messageCode: '',
            messageTitle: '',
            messageDesc: '',
            statusId: '2'
        },
        onSubmit: async (data) => {
            setloadingIcon('pi pi-spin pi-spinner');

            const jsonObj = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },

                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },

                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',

                    payLoad: {
                        ...data
                    },

                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],

                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(jsonObj, '/cms/v1/cms/searchallerrors', true, true));
            if (res?.responseCode === '220000') {
                setErrorData(res?.payLoad);
                formik.resetForm();
            }
            setloadingIcon('pi pi-search');
        }
    });

    const editHandler = (rowData) => {
        navigate('/editerror', { state: { rowData } });
    };
    const viewHandler = (rowData) => {
        navigate('/viewerror', { state: { rowData } });
    };

    const handleSwitchChange = async (rowData) => {
        const updatedData = errorData?.map((item) => {
            if (item?.responseMessageId === switchData?.responseMessageId) {
                const isActive = item?.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setErrorData(updatedData);
        let isactive = '';
        if (switchData?.isActive === 'Y') {
            isactive = 'N';
        } else if (switchData?.isActive === 'N') {
            isactive = 'Y';
        }

        const previousValue = switchData?.isActive;
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    id: switchData?.responseMessageId,
                    isActive: isactive
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newData, '/cms/v1/cms/inactiveerror', true, false));
        if (res?.responseCode == '220000') {
            const revertedData = errorData?.map((item) => {
                if (item.responseMessageId == switchData?.responseMessageId) {
                    const isActive = isactive;
                    return { ...item, isActive };
                }
                return item;
            });
            setErrorData(revertedData);
        } else {
            const revertedData = errorData?.map((item) => {
                if (item.responseMessageId == switchData?.responseMessageId) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setErrorData(revertedData);
        }
    };

    const handleShowModal = () => {
        setShowModal(false);
    };

    const props = {
        showModal,
        handleShowModal,
        handleSwitchChange
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="actions">
                <InputSwitch
                    className="mr-3"
                    checked={rowData.isActive === 'Y'}
                    disabled={['Pending', 'Rejected'].includes(rowData?.statusName) ? true : false}
                    onChange={() => {
                        setSwitchData(rowData);
                        setShowModal(true);
                    }}
                />
                <Button
                    tooltip="Edit"
                    icon="pi pi-pencil"
                    tooltipOptions={{ position: 'top' }}
                    disabled={['Pending', 'Rejected'].includes(rowData?.statusName) || (rowData?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                    onClick={() => editHandler(rowData)}
                    className="p-button-rounded p-button-warning mr-3"
                />
                <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => viewHandler(rowData)} className="p-button-rounded p-button-primary" />
            </div>
        );
    };

    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.statusName === 'Approved' ? '#14A38B' : rowData?.statusName === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.statusName}</b>
            </p>
        );
    };

    const getStatusLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusData(res?.payLoad);
    };
    const getUseCase = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_USECASE');
        setUseCaseData(resp?.payLoad);
    };
    const getUserLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLovData(res?.payLoad);
    };

    const getErrorCat = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/RESPONSE_MESSAGE_TYPE');
        setErrorCat(res?.payLoad);
    };

    useEffect(() => {
        getErrorCat();
        getUseCase();
        getStatusLov();
        getUserLov();
    }, []);

    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };
    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };
    return (
        <>
            <ActiveInActiveDialog {...props} />
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                       
                        <div className="p-field col-12 md:col-4 ">
                            <div className="p-field">
                                <label htmlFor="usecaseId" className={classNames({ 'p-error': isFormFieldValid('usecaseId') }, 'Label__Text')}>
                                    Use Case
                                </label>
                                <Dropdown
                                    id="usecaseId"
                                    placeholder="Enter Use Case"
                                    options={useCaseData}
                                    optionLabel="name"
                                    name="usecaseId"
                                    optionValue="lovId"
                                    value={formik.values.usecaseId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('usecaseId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('usecaseId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Error Code</label>
                                <InputText
                                    id="messageCode"
                                    name="messageCode"
                                    placeholder="Enter Error Code"
                                    value={formik.values.messageCode}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('messageCode') }, 'Input__Round')}
                                    keyfilter="int"
                                />
                                {getFormErrorMessage('messageCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="messageTypeId" className={classNames({ 'p-error': isFormFieldValid('messageTypeId') }, 'Label__Text')}>
                                    Error Category
                                </label>
                                <Dropdown
                                    id="messageTypeId"
                                    placeholder="Select Error Category"
                                    options={errorCat}
                                    optionLabel="name"
                                    name="messageTypeId"
                                    optionValue="lovId"
                                    value={formik.values.messageTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('messageTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('messageTypeId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Error Title</label>
                                <InputText
                                    placeholder="Enter Error Title"
                                    id="messageTitle"
                                    name="messageTitle"
                                    value={formik.values.messageTitle}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('messageTitle') }, 'Input__Round')}
                                    
                                />
                                {getFormErrorMessage('messageTitle')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Error Description</label>
                                <InputText
                                    placeholder="Enter Error Description"
                                    id="messageDesc"
                                    name="messageDesc"
                                    value={formik.values.messageDesc}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('messageDesc') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('messageDesc')}
                            </div>
                        </div>

                       

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="statusId" className={classNames({ 'p-error': isFormFieldValid('statusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select Status"
                                    options={statusData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label="Search" disabled={!Object.values(formik.values).some((value) => value !== null && value !== '')} icon={loadingIcon || ''} iconPos="left" className="Btn__Dark" />
                        <Button type="button" label="Reset" iconPos="right" className="Btn__Transparent" onClick={(e) => handleReset(e)} />
                    </div>
                </form>
            </div>
            <div />

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <div>
                            <h4>Existing Details</h4>
                        </div>
                        <DataTable
                            // ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            globalFilterFields={['validatorName', 'validatorType', 'createdate', 'createdBy', 'updatedBy', 'lkpStatus.statusDescr']}
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={errorData}
                        >
                            <Column field="responseMessageTypeId" header="Error Category" body={(rowData) => errorCat.find((option) => option.lovId == rowData.responseMessageTypeId)?.name} />
                            <Column field="responseMessageCode" header="Error Code" />
                            <Column field="responseMessageDescr" header="Error Detail" />
                            <Column field="responseMessageDescr" header="English Description" />
                            <Column field="responseMessageUrduDesc" header="Urdu Description" />
                            <Column field="usecaseName" header="Use Case" />

                            <Column header="Created Date & Time" body={createdDateAndTimeBodyTemplate} />

                            <Column field="createdBy" header="Created By" />
                            <Column header="statusName" body={statusBodyTemplate} />
                            <Column body={actionTemplate} header="Action" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Error;



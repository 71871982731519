import React, { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown'
import { handleGetRequest } from '../../../../services/GetTemplate';
import { Button } from 'primereact/button';

const ViewBvs = ({ rowData, onHide, userTabsFieldData, type }) => {

    const [bvsData, setBvsData] = useState([])

    const bvsOptions = [{ channelName: rowData?.channelName || rowData?.[0]?.channelName }]

    const getBvsDetailsById = async () => {
        const response = await handleGetRequest(`/account/v1/customeraccounts/getDataByBVS/${rowData?.channelId || rowData?.[0]?.channelId}`);
        if (response?.responseCode === "010000") {
            setBvsData(response?.payLoad)
        }
    }

    useEffect(() => {
        getBvsDetailsById()
    }, []);

    const checkFieldPermissions = (fieldName) => {
        const field = type !== "fromChecker" && userTabsFieldData.find(f => f.fieldName === fieldName);
        return {
            viewAllowed: field?.viewAllowed === "Y",
            updateAllowed: field?.updateAllowed === "Y"
        };
    };

    const channelNamePermissions = checkFieldPermissions("channelName");

    return (
        <>
            <div className='Card__Round'>

                {type !== "fromChecker" ?
                    channelNamePermissions.viewAllowed && (
                        <div className='field-loader'>
                            <label className='Label__Text'>
                                Source
                            </label>
                            <Dropdown
                                options={bvsOptions}
                                optionLabel="channelName"
                                optionValue='channelName'
                                value={bvsData?.channelName ?? ""}
                                className='Dropdown__Round mr-2'
                                placeholder="--Select--"
                                disabled
                            />
                        </div>
                    ) :
                    <div className='field-loader'>
                        <label className='Label__Text'>
                            Source
                        </label>
                        <Dropdown
                            options={bvsOptions}
                            optionLabel="channelName"
                            optionValue='channelName'
                            value={bvsData?.channelName ?? ""}
                            className='Dropdown__Round mr-2'
                            placeholder="--Select--"
                            disabled
                        />
                    </div>
                }
                <div className='Down__Btn mt-4'>
                    <Button label="Cancel" iconPos="right" className="Btn__cancel" type="button" onClick={onHide} />
                </div>

            </div>
        </>
    )
}

export default ViewBvs
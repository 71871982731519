import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import { Sidebar } from 'primereact/sidebar';
import TaxTypeOldNewValuesView from './TaxTypeOldNewValuesView';

function EditTaxTypeChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [statusLovData, setStatusLovData] = useState([]);
    const [oldNewData, setOldNewData] = useState([]);
    const [highlightedKeys, setHighlightedKeys] = useState([]);
    const [taxTypeRowData, setTaxTypeRowData] = useState([]);
    const [displayDialog, setDisplayDialog] = useState(false);

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const getDatabyId = async () => {
        const resp = await handleGetRequest(`/tax/v1/getTaxTypeById/${refTableId}`);
        const keyData = resp?.payLoad;
        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik?.setFieldValue(key, keyData[key]);
                }
            });
            formik.setFieldValue('applicableOn', keyData?.applicableOn);
            formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
        }
    };

    const getMcRequestDataById = async () => {
        setloading(true);

        const res = await handleGetRequest(`/tax/v1/getTaxTypeByRequestId/${mcRequestId}`);
        const keyData = res?.payLoad;

        setloading(false);

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            formik.setFieldValue('applicableOn', keyData?.applicableOn);
            formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
        }

        const resp = await handleGetRequest(`/tax/v1/getTaxTypeById/${refTableId}`);
        const oldKeyData = resp?.payLoad;
    };

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, []); // eslint-disable-line

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        getStatusLov();
    }, []); // eslint-disable-line

    const getOldNewData = async () => {
        const resp = await handleGetRequest(`/workflow/v1/management/compareValues/${mcRequestId}`);
        setOldNewData(resp?.payLoad);
    };

    useEffect(() => {
        if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getOldNewData();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        const keys = oldNewData?.map((item) => item.key);
        setHighlightedKeys(keys);
    }, [oldNewData]); // eslint-disable-line

    const applicabledropDown = [
        {
            value: 'I',
            label: 'Apply on Fee Inclusive'
        },
        {
            value: 'E',
            label: 'Apply on Fee Exclusive'
        },
        {
            value: 'C',
            label: 'Apply on Commision'
        },
        {
            value: 'T',
            label: 'Apply on Transaction Amount'
        }
    ];

    const validationSchema = Yup.object().shape({
        checkerComments: Yup.string().required('This Field is required.'),
        action: Yup.string().required('This Field is required.')
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            taxTypeCode: '',
            taxTypeName: '',
            applicableOn: '',
            taxTypeDescr: '',
            checkerComments: '',
            action: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['isActive'] = data?.isActive ? 'Y' : 'N';
            if (requestTypeDescr !== 'Enable/Disable Request') {
                delete data['isActive'];
            }

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        taxTypeRequest: {
                            isActive: data['isActive'],
                            taxTypeCode: data?.taxTypeCode,
                            taxTypeName: data?.taxTypeName,
                            applicableOn: data?.applicableOn,
                            taxTypeDescr: data?.taxTypeDescr,
                            taxTypeId: refTableId
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(newData, '/tax/v1/taxTypeCheckerAction', true, false, 'requestchecker'));
            if (res?.responseCode === '040000') {
                formik.resetForm();
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const HandledOldValue = async (e) => {
        e.preventDefault();
        setDisplayDialog(true);
        const resp = await handleGetRequest(`/tax/v1/getTaxTypeById/${refTableId}`);
        setTaxTypeRowData(resp?.payLoad);
    };

    return (
        <>
            <Sidebar header="View Tax Type" position="right" style={{ width: '50vw' }} visible={displayDialog} onHide={() => setDisplayDialog(false)}>
                <TaxTypeOldNewValuesView taxTypeRowData={taxTypeRowData} />
            </Sidebar>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>EDIT TAX TYPE CHECKER</h2>
                            {requestType === 'U' && <Button label="View Old Details" className="Btn__Dark__OldValue" onClick={HandledOldValue} />}
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="taxTypeCode" className={classNames({ 'p-error': isFormFieldValid('taxTypeCode') }, 'Label__Text')}>
                                    Tax Code
                                </label>
                                <InputText
                                    disabled
                                    id="taxTypeCode"
                                    placeholder="Enter Tax Code"
                                    name="taxTypeCode"
                                    value={formik?.values?.taxTypeCode?.trim()?.toString() || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('taxTypeCode')
                                            ? classNames({ 'p-invalid': isFormFieldValid('taxTypeCode') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('taxTypeCode') }, 'Input__Round')
                                    }
                                />

                                {getFormErrorMessage('taxTypeCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="taxTypeName" className={classNames({ 'p-error': isFormFieldValid('taxTypeName') }, 'Label__Text')}>
                                    Tax Name
                                </label>
                                <InputText
                                    disabled
                                    id="taxTypeName"
                                    placeholder="Enter Tax Name"
                                    name="taxTypeName"
                                    value={formik?.values?.taxTypeName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('taxTypeName')
                                            ? classNames({ 'p-invalid': isFormFieldValid('taxTypeCode') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('taxTypeCode') }, 'Input__Round')
                                    }
                                />

                                {getFormErrorMessage('taxTypeName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="applicableOn" className={classNames({ 'p-error': isFormFieldValid('applicableOn') }, 'Label__Text')}>
                                    Applicability
                                </label>
                                <Dropdown
                                    disabled
                                    id="applicableOn"
                                    placeholder="Select Applicability"
                                    options={applicabledropDown}
                                    optionLabel="label"
                                    name="applicableOn"
                                    optionValue="value"
                                    value={formik.values.applicableOn || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('applicableOn')
                                            ? classNames({ 'p-invalid': isFormFieldValid('applicableOn') }, 'Dropdown__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('applicableOn') }, 'Dropdown__Round')
                                    }
                                    // className={classNames({ 'p-invalid': isFormFieldValid('applicableOn') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('applicableOn')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="taxTypeDescr" className={classNames({ 'p-error': isFormFieldValid('taxTypeDescr') }, 'Label__Text')}>
                                    Description
                                </label>
                                <InputTextarea
                                    disabled
                                    autoResize="false"
                                    id="taxTypeDescr"
                                    placeholder="Enter Description"
                                    name="taxTypeDescr"
                                    value={formik?.values?.taxTypeDescr?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('taxTypeDescr')
                                            ? classNames({ 'p-invalid': isFormFieldValid('taxTypeCode') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('taxTypeCode') }, 'Input__Round')
                                    }
                                    rows={3}
                                    cols={30}
                                />
                                {getFormErrorMessage('taxTypeDescr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                    isActive
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox disabled={true} inputId="isActive" name="isActive" value={formik?.values?.isActive} checked={formik?.values?.isActive} onChange={formik.handleChange} />
                                </div>
                                {getFormErrorMessage('isActive')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="requestTypeDescr" className={classNames({ 'p-error': isFormFieldValid('requestTypeDescr') }, 'Label__Text')}>
                                    Request Type Description
                                </label>
                                <InputText
                                    disabled={true}
                                    id="requestTypeDescr"
                                    name="requestTypeDescr"
                                    value={requestTypeDescr}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('requestTypeDescr') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('requestTypeDescr')}
                            </div>
                        </div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    optionDisabled={(option) => option?.name === 'Pending'}
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    autoResize={true}
                                    rows={5}
                                    cols={30}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} label="Update" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            {/* {oldNewData && requestTypeDescr === 'Update Request' && (
                <div className="card Card__Round">
                    <div className="Checker__Data_Detail">
                        <div className="Form__Header Full__Width">
                            <h2>UPDATED INFO DETAIL</h2>
                        </div>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <PresentState oldNewData={oldNewData} />
                        </div>
                    </div>
                </div>
            )} */}

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default EditTaxTypeChecker;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { PickList } from 'primereact/picklist';
import { useLocation, useNavigate } from 'react-router';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import { Sidebar } from 'primereact/sidebar';
import TransactionLimitOldNewValues from './TransactionLimitOldNewValues';

function EditTransactionwiseLimitChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');
    const [source, setSource] = useState([]);
    const [target, setTarget] = useState([]);
    const [accountLevelData, setAccountLevelData] = useState([]);
    const [productPickList, setProductPickList] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [isPickListDisabled] = useState(true);

    const [oldNewData, setOldNewData] = useState([]);
    const [highlightedKeys, setHighlightedKeys] = useState([]);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [transactionLimitRowData, setTransactionLimitRowData] = useState();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const getAccLevelLov = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getaccounttypes/LKP_ACCOUNT_LEVEL:*');
        setAccountLevelData(response?.payLoad);
    };

    const getDatabyId = async () => {
        const resp = await handleGetRequest(`/limits/v1/limitsprofiles/getTransLimitsById/${refTableId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
            formik.setFieldValue('lkpAccountLevelId', keyData?.lkpAccountLevel?.accountLevelId?.toString());

            if (keyData?.dailyTransLimitDr === null || keyData?.dailyTransLimitDr === '') {
                formik.setFieldValue('activeDailyTransLimitDr', true);
            }
            if (keyData?.monthlyTransLimitDr === null || keyData?.monthlyTransLimitDr === '') {
                formik.setFieldValue('activeMonthlyTransLimitDr', true);
            }
            if (keyData?.yearlyTransLimitDr === null || keyData?.yearlyTransLimitDr === '') {
                formik.setFieldValue('activeYearlyTransLimitDr', true);
            }
            if (keyData?.dailyTransLimitCr === null || keyData?.dailyTransLimitCr === '') {
                formik.setFieldValue('activeDailyTransLimitCr', true);
            }
            if (keyData?.monthlyTransLimitCr === null || keyData?.monthlyTransLimitCr === '') {
                formik.setFieldValue('activeMonthlyTransLimitCr', true);
            }
            if (keyData?.yearlyTransLimitCr === null || keyData?.yearlyTransLimitCr === '') {
                formik.setFieldValue('activeYearlyTransLimitCr', true);
            }

            setSource(
                resp.payLoad.tblTransLimitDetails?.sort((a, b) => {
                    if (a.tblProduct.productName?.toLowerCase() > b.tblProduct.productName?.toLowerCase()) {
                        return 1;
                    }
                    if (a.tblProduct.productName?.toLowerCase() < b.tblProduct.productName?.toLowerCase()) {
                        return -1;
                    }
                    return 0;
                })
            );
            setTarget(
                resp?.payLoad?.tblProduct?.sort((a, b) => {
                    if (a.productName?.toLowerCase() > b.productName?.toLowerCase()) {
                        return 1;
                    }
                    if (a.productName?.toLowerCase() < b.productName?.toLowerCase()) {
                        return -1;
                    }
                    return 0;
                })
            );
            // setAccountLevelData([resp.payLoad.lkpAccountLevel]);
        }
    };

    const getMcRequestDataById = async () => {
        const resp = await handleGetRequest(`/limits/v1/limitsprofiles/getTransLimitsUpdateCheckerById/${mcRequestId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
            formik.setFieldValue('lkpAccountLevelId', keyData?.lkpAccountLevelId?.toString());

            if (keyData?.dailyTransLimitDr === null || keyData?.dailyTransLimitDr === '') {
                formik.setFieldValue('activeDailyTransLimitDr', true);
            }
            if (keyData?.monthlyTransLimitDr === null || keyData?.monthlyTransLimitDr === '') {
                formik.setFieldValue('activeMonthlyTransLimitDr', true);
            }
            if (keyData?.yearlyTransLimitDr === null || keyData?.yearlyTransLimitDr === '') {
                formik.setFieldValue('activeYearlyTransLimitDr', true);
            }
            if (keyData?.dailyTransLimitCr === null || keyData?.dailyTransLimitCr === '') {
                formik.setFieldValue('activeDailyTransLimitCr', true);
            }
            if (keyData?.monthlyTransLimitCr === null || keyData?.monthlyTransLimitCr === '') {
                formik.setFieldValue('activeMonthlyTransLimitCr', true);
            }
            if (keyData?.yearlyTransLimitCr === null || keyData?.yearlyTransLimitCr === '') {
                formik.setFieldValue('activeYearlyTransLimitCr', true);
            }

            setSource(
                resp.payLoad.tblTransLimitDetails?.sort((a, b) => {
                    if (a.tblProduct.productName?.toLowerCase() > b.tblProduct.productName?.toLowerCase()) {
                        return 1;
                    }
                    if (a.tblProduct.productName?.toLowerCase() < b.tblProduct.productName?.toLowerCase()) {
                        return -1;
                    }
                    return 0;
                })
            );
            setTarget(
                resp?.payLoad?.tblProduct?.sort((a, b) => {
                    if (a.productName?.toLowerCase() > b.productName?.toLowerCase()) {
                        return 1;
                    }
                    if (a.productName?.toLowerCase() < b.productName?.toLowerCase()) {
                        return -1;
                    }
                    return 0;
                })
            );
        }
    };

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
        getStatusLov();
        getAccLevelLov();
    }, []); // eslint-disable-line

    const unselectPickListData = source?.map((item) => ({
        tblProductId: item.tblProduct.productId
    }));

    const onChange = (event) => {
        const pickListIDs = event.source.map((item) => {
            return item;
        });
        const pickListIdsData = pickListIDs.map((item) => ({
            tblProductId: item.tblProduct.productId
        }));

        setProductPickList(pickListIdsData);
        setSource(event.source);
        setTarget(event.target);
    };

    const getOldNewData = async () => {
        const resp = await handleGetRequest(`/workflow/v1/management/compareValues/${mcRequestId}`);
        setOldNewData(resp?.payLoad);
    };

    useEffect(() => {
        if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getOldNewData();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        const keys = oldNewData?.map((item) => item.key);
        setHighlightedKeys(keys);
    }, [oldNewData]); // eslint-disable-line

    const sourceItemTemplate = (item) => {
        return (
            <div className="product-item">
                <div className="product-list-detail">
                    <h5 className="mb-2">{item.tblProduct.productName}</h5>
                </div>
            </div>
        );
    };

    const targetItemTemplate = (item) => {
        return (
            <div className="product-item">
                <div className="product-list-detail">
                    <h5 className="mb-2">{item.productName}</h5>
                </div>
            </div>
        );
    };

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('This field is required.'),
        checkerComments: Yup.string().required('This field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            limitProfileName: '',
            lkpAccountLevelId: '',
            activeDailyTransLimitDr: false,
            dailyTransLimitDr: '',
            dailyAmtLimitDr: '',
            activeMonthlyTransLimitDr: false,
            monthlyTransLimitDr: '',
            monthlyAmtLimitDr: '',
            activeYearlyTransLimitDr: false,
            yearlyTransLimitDr: '',
            yearlyAmtLimitDr: '',
            activeDailyTransLimitCr: false,
            dailyTransLimitCr: '',
            dailyAmtLimitCr: '',
            activeMonthlyTransLimitCr: false,
            monthlyTransLimitCr: '',
            monthlyAmtLimitCr: '',
            activeYearlyTransLimitCr: false,
            yearlyTransLimitCr: '',
            yearlyAmtLimitCr: '',
            action: '',
            checkerComments: '',
            isActive: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['isActive'] = data?.isActive ? 'Y' : 'N';
            if (requestTypeDescr !== 'Enable/Disable Request') {
                delete data['isActive'];
            }

            delete data['activeDailyTransLimitDr'];
            delete data['activeMonthlyTransLimitDr'];
            delete data['activeYearlyTransLimitDr'];
            delete data['activeDailyTransLimitCr'];
            delete data['activeMonthlyTransLimitCr'];
            delete data['activeYearlyTransLimitCr'];

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        tblTransLimitRequest: {
                            limitProfileName: data['limitProfileName'],
                            lkpAccountLevelId: data['lkpAccountLevelId'],
                            dailyTransLimitDr: data['dailyTransLimitDr'],
                            dailyAmtLimitDr: data['dailyAmtLimitDr'],
                            monthlyTransLimitDr: data['monthlyTransLimitDr'],
                            monthlyAmtLimitDr: data['monthlyAmtLimitDr'],
                            yearlyTransLimitDr: data['yearlyTransLimitDr'],
                            yearlyAmtLimitDr: data['yearlyAmtLimitDr'],
                            dailyTransLimitCr: data['dailyTransLimitCr'],
                            dailyAmtLimitCr: data['dailyAmtLimitCr'],
                            monthlyTransLimitCr: data['monthlyTransLimitCr'],
                            monthlyAmtLimitCr: data['monthlyAmtLimitCr'],
                            yearlyTransLimitCr: data['yearlyTransLimitCr'],
                            yearlyAmtLimitCr: data['yearlyAmtLimitCr'],
                            transLimitId: refTableId,
                            excludeLimit: '',
                            isActive: data['isActive']
                        },
                        tblTransLimitDetails: productPickList.length > 0 ? productPickList : unselectPickListData,
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/limits/v1/limitsprofiles/transactionwiselimitprofileCheckerAction', true, false, 'requestchecker'));
            if (res?.responseCode === '020000') {
                formik.resetForm();
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const HandledOldValue = async (e) => {
        e.preventDefault();
        setDisplayDialog(true);
        const resp = await handleGetRequest(`/limits/v1/limitsprofiles/getTransLimitsById/${refTableId}`);
        setTransactionLimitRowData(resp?.payLoad);
    };

    return (
        <>
            <Sidebar header="View Channel" position="right" style={{ width: '70vw' }} visible={displayDialog} onHide={() => setDisplayDialog(false)}>
                <TransactionLimitOldNewValues transactionLimitRowData={transactionLimitRowData} />
            </Sidebar>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>EDIT DETAILS</h2>
                            {requestType === 'U' && <Button label="View Old Details" className="Btn__Dark__OldValue" onClick={HandledOldValue} />}
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="limitProfileName" className={classNames({ 'p-error': isFormFieldValid('limitProfileName') }, 'Label__Text')}>
                                    Limit Profile <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="limitProfileName"
                                    placeholder=""
                                    name="limitProfileName"
                                    value={formik?.values?.limitProfileName?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('limitProfileName')
                                            ? classNames({ 'p-invalid': isFormFieldValid('limitProfileName') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('limitProfileName') }, 'Input__Round')
                                    }
                                />
                                {getFormErrorMessage('limitProfileName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="lkpAccountLevelId" className={classNames({ 'p-error': isFormFieldValid('lkpAccountLevelId') }, 'Label__Text')}>
                                    Limit <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="lkpAccountLevelId"
                                    placeholder="Select Account Level"
                                    options={accountLevelData}
                                    optionLabel="name"
                                    name="lkpAccountLevelId"
                                    optionValue="lovId"
                                    value={formik.values.lkpAccountLevelId || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('lkpAccountLevelId')
                                            ? classNames({ 'p-invalid': isFormFieldValid('lkpAccountLevelId') }, 'Dropdown__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('lkpAccountLevelId') }, 'Dropdown__Round')
                                    }
                                />

                                {getFormErrorMessage('lkpAccountLevelId')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <PickList
                                source={source}
                                target={target}
                                className={isPickListDisabled ? 'picklist-disabled' : ''}
                                disabled={isPickListDisabled}
                                // itemTemplate={itemTemplate}
                                sourceItemTemplate={sourceItemTemplate}
                                targetItemTemplate={targetItemTemplate}
                                sourceHeader="Include"
                                targetHeader="Exclude"
                                sourceStyle={highlightedKeys?.includes('tblProductId') ? { height: 'auto', border: '1px solid red', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' } : { height: 'auto' }}
                                targetStyle={{ height: 'auto' }}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="cards_section___">
                        <div className="Debit_card">
                            <p className="card_heading__">Debit</p>
                            <div className="ca_rds">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__ mt-1">
                                        <p>Daily</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox
                                                    inputId="activeDailyTransLimitDr"
                                                    disabled={true}
                                                    name="activeDailyTransLimitDr"
                                                    value={formik.values.activeDailyTransLimitDr}
                                                    checked={formik.values.activeDailyTransLimitDr}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                            <p htmlFor="dailyTransLimitDr" className={classNames({ 'p-error': isFormFieldValid('dailyTransLimitDr') }, 'Label__Text')}>
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>
                                        <InputText
                                            disabled={true}
                                            id="dailyTransLimitDr"
                                            placeholder=""
                                            name="dailyTransLimitDr"
                                            value={formik?.values?.dailyTransLimitDr || ''}
                                            onChange={formik.handleChange}
                                            className={
                                                highlightedKeys?.includes('dailyTransLimitDr')
                                                    ? classNames({ 'p-invalid': isFormFieldValid('dailyTransLimitDr') }, 'Input__Round__Highlighted')
                                                    : classNames({ 'p-invalid': isFormFieldValid('dailyTransLimitDr') }, 'Input__Round')
                                            }
                                        />
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="dailyAmtLimitDr" className={classNames({ 'p-error': isFormFieldValid('dailyAmtLimitDr') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber
                                            disabled={true}
                                            inputIdid="dailyAmtLimitDr"
                                            placeholder="25000"
                                            name="dailyAmtLimitDr"
                                            value={formik?.values?.dailyAmtLimitDr || ''}
                                            onValueChange={formik.handleChange}
                                            className={highlightedKeys?.includes('dailyAmtLimitDr') ? 'Input__Number__Highlighted' : ''}
                                        />
                                        {getFormErrorMessage('dailyAmtLimitDr')}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__ mt-1">
                                        <p>Monthly</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    inputId="activeMonthlyTransLimitDr"
                                                    name="activeMonthlyTransLimitDr"
                                                    value={formik.values.activeMonthlyTransLimitDr}
                                                    checked={formik.values.activeMonthlyTransLimitDr}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                            <p htmlFor="monthlyTransLimitDr" className={classNames({ 'p-error': isFormFieldValid('monthlyTransLimitDr') }, 'Label__Text')}>
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>
                                        <InputText
                                            disabled={true}
                                            id="monthlyTransLimitDr"
                                            placeholder=""
                                            name="monthlyTransLimitDr"
                                            value={formik?.values?.monthlyTransLimitDr || ''}
                                            onChange={formik.handleChange}
                                            className={
                                                highlightedKeys?.includes('monthlyTransLimitDr')
                                                    ? classNames({ 'p-invalid': isFormFieldValid('monthlyTransLimitDr') }, 'Input__Round__Highlighted')
                                                    : classNames({ 'p-invalid': isFormFieldValid('monthlyTransLimitDr') }, 'Input__Round')
                                            }
                                        />
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="monthlyAmtLimitDr" className={classNames({ 'p-error': isFormFieldValid('monthlyAmtLimitDr') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber
                                            disabled={true}
                                            inputId="monthlyAmtLimitDr"
                                            placeholder="25000"
                                            name="monthlyAmtLimitDr"
                                            value={formik?.values?.monthlyAmtLimitDr || ''}
                                            onValueChange={formik.handleChange}
                                            className={highlightedKeys?.includes('monthlyAmtLimitDr') ? 'Input__Number__Highlighted' : ''}
                                        />
                                        {getFormErrorMessage('monthlyAmtLimitDr')}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__ mt-1">
                                        <p>Yearly</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    inputId="activeYearlyTransLimitDr"
                                                    name="activeYearlyTransLimitDr"
                                                    value={formik.values.activeYearlyTransLimitDr || ''}
                                                    checked={formik.values.activeYearlyTransLimitDr}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                            <p htmlFor="yearlyTransLimitDr" className={classNames({ 'p-error': isFormFieldValid('yearlyTransLimitDr') }, 'Label__Text')}>
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>
                                        <InputText
                                            disabled={true}
                                            id="yearlyTransLimitDr"
                                            placeholder=""
                                            name="yearlyTransLimitDr"
                                            value={formik?.values?.yearlyTransLimitDr || ''}
                                            onChange={formik.handleChange}
                                            className={
                                                highlightedKeys?.includes('yearlyTransLimitDr')
                                                    ? classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitDr') }, 'Input__Round__Highlighted')
                                                    : classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitDr') }, 'Input__Round')
                                            }
                                        />
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber
                                            disabled={true}
                                            inputId="yearlyAmtLimitDr"
                                            placeholder="25000"
                                            name="yearlyAmtLimitDr"
                                            value={formik?.values?.yearlyAmtLimitDr || ''}
                                            onValueChange={formik.handleChange}
                                            className={highlightedKeys?.includes('yearlyAmtLimitDr') ? 'Input__Number__Highlighted' : ''}
                                        />
                                        {getFormErrorMessage('yearlyAmtLimitDr')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="credit_card">
                            <p className="card_heading__">Credit</p>
                            <div className="ca_rds">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__ mt-1">
                                        <p>Daily</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    inputId="activeDailyTransLimitCr"
                                                    name="activeDailyTransLimitCr"
                                                    value={formik.values.activeDailyTransLimitCr || ''}
                                                    checked={formik.values.activeDailyTransLimitCr}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                            <p htmlFor="dailyAmtLimitCr" className={classNames({ 'p-error': isFormFieldValid('dailyAmtLimitCr') }, 'Label__Text')}>
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>
                                        <InputText
                                            disabled={true}
                                            id="dailyTransLimitCr"
                                            placeholder=""
                                            name="dailyTransLimitCr"
                                            value={formik?.values?.dailyTransLimitCr || ''}
                                            onChange={formik.handleChange}
                                            className={
                                                highlightedKeys?.includes('dailyTransLimitCr')
                                                    ? classNames({ 'p-invalid': isFormFieldValid('dailyTransLimitCr') }, 'Input__Round__Highlighted')
                                                    : classNames({ 'p-invalid': isFormFieldValid('dailyTransLimitCr') }, 'Input__Round')
                                            }
                                        />
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber
                                            disabled={true}
                                            inputId="dailyAmtLimitCr"
                                            placeholder="25000"
                                            name="dailyAmtLimitCr"
                                            value={formik?.values?.dailyAmtLimitCr || ''}
                                            onValueChange={formik.handleChange}
                                            className={highlightedKeys?.includes('dailyAmtLimitCr') ? 'Input__Number__Highlighted' : ''}
                                        />
                                        {getFormErrorMessage('dailyAmtLimitCr')}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__ mt-1">
                                        <p>Monthly</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    inputId="activeMonthlyTransLimitCr"
                                                    name="activeMonthlyTransLimitCr"
                                                    value={formik.values.activeMonthlyTransLimitCr || ''}
                                                    checked={formik.values.activeMonthlyTransLimitCr}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                            <p htmlFor="monthlyTransLimitCr" className={classNames({ 'p-error': isFormFieldValid('monthlyTransLimitCr') }, 'Label__Text')}>
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>
                                        <InputText
                                            disabled={true}
                                            id="monthlyTransLimitCr"
                                            placeholder=""
                                            name="monthlyTransLimitCr"
                                            value={formik?.values?.monthlyTransLimitCr || ''}
                                            onChange={formik.handleChange}
                                            className={
                                                highlightedKeys?.includes('monthlyTransLimitCr')
                                                    ? classNames({ 'p-invalid': isFormFieldValid('monthlyTransLimitCr') }, 'Input__Round__Highlighted')
                                                    : classNames({ 'p-invalid': isFormFieldValid('monthlyTransLimitCr') }, 'Input__Round')
                                            }
                                        />
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="monthlyAmtLimitCr" className={classNames({ 'p-error': isFormFieldValid('monthlyAmtLimitCr') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber
                                            disabled={true}
                                            inputId="monthlyAmtLimitCr"
                                            placeholder="25000"
                                            name="monthlyAmtLimitCr"
                                            value={formik?.values?.monthlyAmtLimitCr || ''}
                                            onValueChange={formik.handleChange}
                                            className={highlightedKeys?.includes('monthlyAmtLimitCr') ? 'Input__Number__Highlighted' : ''}
                                        />
                                        {getFormErrorMessage('monthlyAmtLimitCr')}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__ mt-1">
                                        <p>Yearly</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    inputId="activeYearlyTransLimitCr"
                                                    name="activeYearlyTransLimitCr"
                                                    value={formik.values.activeYearlyTransLimitCr || ''}
                                                    checked={formik.values.activeYearlyTransLimitCr}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                            <p htmlFor="yearlyTransLimitCr" className={classNames({ 'p-error': isFormFieldValid('yearlyTransLimitCr') }, 'Label__Text')}>
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>
                                        <InputText
                                            disabled={true}
                                            id="yearlyTransLimitCr"
                                            placeholder=""
                                            name="yearlyTransLimitCr"
                                            value={formik?.values?.yearlyTransLimitCr || ''}
                                            onChange={formik.handleChange}
                                            className={
                                                highlightedKeys?.includes('yearlyTransLimitCr')
                                                    ? classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitCr') }, 'Input__Round__Highlighted')
                                                    : classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitCr') }, 'Input__Round')
                                            }
                                        />
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="yearlyAmtLimitCr" className={classNames({ 'p-error': isFormFieldValid('yearlyAmtLimitCr') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber
                                            disabled={true}
                                            inputId="yearlyAmtLimitCr"
                                            placeholder="25000"
                                            name="yearlyAmtLimitCr"
                                            value={formik?.values?.yearlyAmtLimitCr || ''}
                                            onValueChange={formik.handleChange}
                                            className={highlightedKeys?.includes('yearlyAmtLimitCr') ? 'Input__Number__Highlighted' : ''}
                                        />
                                        {getFormErrorMessage('yearlyAmtLimitCr')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                isActive
                            </label>
                            <div className="field-checkbox">
                                <Checkbox disabled={true} inputId="isActive" name="isActive" value={formik?.values?.isActive} checked={formik?.values?.isActive} onChange={formik.handleChange} />
                            </div>
                            {getFormErrorMessage('isActive')}
                        </div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    autoResize="false"
                                    rows={5}
                                    cols={30}
                                    maxLength={500}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} label="Update" className="Btn__Dark" />
                        <Button disabled={loading} loadingIcon={loadingIcon} onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default EditTransactionwiseLimitChecker;

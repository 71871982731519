import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import SubCategoryOldNewValues from './SubCategoryOldNewValues';
import { Sidebar } from 'primereact/sidebar';

const EditCheckerSubCategoryCoa = () => {
    const [statusLovData, setStatusLovData] = useState([]);
    const [mcReqData, setMcReqData] = useState([]);

    const [oldNewData, setOldNewData] = useState([]);
    const [highlightedKeys, setHighlightedKeys] = useState([]);
    const [subCategoryRowData, setSubCategoryRowData] = useState();
    const [displayDialog, setDisplayDialog] = useState(false);

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const tableName = query.get('tableName');
    const requestTypeDescr = query.get('requestTypeDescr');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('Action should not be empty'),
        checkerComments: Yup.string().required('This Field is required').notOneOf([''], 'This Field should not be empty').max(500, 'Maximum 500 characters needed')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            lkpSubCategoryName: '',
            lkpSubCategoryCode: '',
            lkpSubCategoryDescription: '',
            coaCode: '',
            action: '',
            checkerComments: ''
        },

        onSubmit: async (data) => {
            const coaPayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        lkpSubCategoryDTO:
                            requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request'
                                ? mcReqData
                                : requestType === 'U' && requestTypeDescr === 'Enable/Disable Request'
                                ? { isActive: mcReqData?.isActive, lkpSubCategoryId: refTableId }
                                : { lkpSubCategoryId: refTableId, isActive: 'Y' },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: 1
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(coaPayload, '/account/v1/chartofaccount/lkpsubcategorycheckeraction', true, false));
            if (res?.responseCode === '010000') {
                navigate(-1);
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getCostCenterById = async () => {
        const resp = await handleGetRequest(`/account/v1/chartofaccount/getlkpsubcategorybyid/${refTableId}`);
        const keyData = resp?.payLoad;
        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            formik.setFieldValue('lkpSubCategoryName', keyData?.subCategoryName);
            formik.setFieldValue('lkpSubCategoryCode', keyData?.subCategoryCode);
            formik.setFieldValue('lkpSubCategoryDescription', keyData?.subCategoryDescr);
            formik.setFieldValue('coaCode', keyData?.coaCode);
        }
    };

    const getCostCenterMcRequestDataById = async () => {
        const resp = await handleGetRequest(`/account/v1/chartofaccount/getlkpsubcategorybymcrequestid/${mcRequestId}`);
        const keyData = resp?.payLoad;
        if (keyData !== null && typeof keyData !== 'undefined') {
            setMcReqData(keyData);
            if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
                formik.setFieldValue('lkpSubCategoryName', keyData?.lkpSubCategoryName);
                formik.setFieldValue('lkpSubCategoryCode', keyData?.lkpSubCategoryCode);
                formik.setFieldValue('lkpSubCategoryDescription', keyData?.lkpSubCategoryDescription);
                formik.setFieldValue('coaCode', keyData?.coaCode);
            }
        }
    };

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = res?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getCostCenterById();
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getCostCenterMcRequestDataById();
        } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
            getCostCenterById();
            getCostCenterMcRequestDataById();
        }
        getStatusListData();
    }, []); // eslint-disable-line

    const getOldNewData = async () => {
        const resp = await handleGetRequest(`/workflow/v1/management/compareValues/${mcRequestId}`);
        setOldNewData(resp?.payLoad);
    };

    useEffect(() => {
        if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getOldNewData();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        const keys = oldNewData?.map((item) => item.key);
        setHighlightedKeys(keys);
    }, [oldNewData]); // eslint-disable-line

    const HandledOldValue = async (e) => {
        e.preventDefault();
        setDisplayDialog(true);
        const resp = await handleGetRequest(`/account/v1/chartofaccount/getlkporganizationbyid/${refTableId}`);
        setSubCategoryRowData(resp?.payLoad);
    };

    return (
        <>
            <Sidebar header="View Sub Category" position="right" style={{ width: '50vw' }} visible={displayDialog} onHide={() => setDisplayDialog(false)}>
                <SubCategoryOldNewValues subCategoryRowData={subCategoryRowData} />
            </Sidebar>

            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>Edit Details</h2>
                            {requestType === 'U' && <Button label="View Old Details" className="Btn__Dark__OldValue" onClick={HandledOldValue} />}
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Sub Category Name</label>
                                <InputText
                                    id="lkpSubCategoryName"
                                    value={formik.values.lkpSubCategoryName || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('lkpSubCategoryName')
                                            ? classNames({ 'p-invalid': isFormFieldValid('lkpSubCategoryName') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('lkpSubCategoryName') }, 'Input__Round')
                                    }
                                    maxLength={30}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpSubCategoryName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Sub Category Code</label>
                                <InputText
                                    id="lkpSubCategoryCode"
                                    value={formik.values.lkpSubCategoryCode || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('lkpSubCategoryCode')
                                            ? classNames({ 'p-invalid': isFormFieldValid('lkpSubCategoryCode') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('lkpSubCategoryCode') }, 'Input__Round')
                                    }
                                    maxLength={30}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpSubCategoryCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Sub Category Description</label>
                                <InputText
                                    id="lkpSubCategoryDescription"
                                    value={formik.values.lkpSubCategoryDescription || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('lkpSubCategoryDescription')
                                            ? classNames({ 'p-invalid': isFormFieldValid('lkpSubCategoryDescription') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('lkpSubCategoryDescription') }, 'Input__Round')
                                    }
                                    maxLength={199}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpSubCategoryDescription')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Coa Code</label>
                                <InputText
                                    id="coaCode"
                                    value={formik.values.coaCode || ''}
                                    onChange={formik.handleChange}
                                    className={highlightedKeys?.includes('coaCode') ? classNames({ 'p-invalid': isFormFieldValid('coaCode') }, 'Input__Round__Highlighted') : classNames({ 'p-invalid': isFormFieldValid('coaCode') }, 'Input__Round')}
                                    maxLength={30}
                                    disabled={true}
                                />
                                {getFormErrorMessage('coaCode')}
                            </div>
                        </div>

                        {requestType === 'U' && requestTypeDescr === 'Enable/Disable Request' && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label className="Label__Text">Is Active</label>
                                    <Checkbox checked={mcReqData?.isActive === 'Y'} disabled className="w-full" />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="custom-card">
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>

                            <div className="p-field col-12 md:col-8">
                                <div className="p-field">
                                    <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                        Comments
                                        <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        rows={5}
                                        cols={30}
                                        id="checkerComments"
                                        name="checkerComments"
                                        value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'Input__Round')}
                                        maxLength={500}
                                    />

                                    {getFormErrorMessage('checkerComments')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Update" className="Btn__Dark" />
                        <Button onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" type="button" />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
};

export default EditCheckerSubCategoryCoa;

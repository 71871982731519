import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import { Paginator } from 'primereact/paginator';
import { SearchButton, ResetButton } from '../../../components/SearchButton';
import { PaginationCall, PaginationTemplate } from '../../../components/Pagination';

function LoanOutstandingReport() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [loanOutstandingData, setLoanOutstandingData] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [productId, setProductId] = useState([]);
    const [customFirst1, setCustomFirst1] = useState(0);
    const [customRows1, setCustomRows1] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const [render, setRender] = useState(false);
    const [showDownload, setShowDownload] = useState(false);

    const dataTableRef = useRef(null);

    const dispatch = useDispatch();

    const convertArrayToCSV = (dataArray) => {
        const header = Object.keys(dataArray[0]).join(',') + '\n';
        const csvData = dataArray.map((item) => Object.values(item).join(',')).join('\n');
        return header + csvData;
    };

    const downloadCSV = (dataArray, fileName) => {
        const csvContent = convertArrayToCSV(dataArray);

        const blob = new Blob([csvContent], { type: 'text/csv' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    };

    const handleDownload = () => {
        // Function to convert camelCase to spaced format
        const convertToSpacedFormat = (str) => {
            return str
                .replace(/([A-Z])/g, ' $1')
                .replace(/^./, (match) => match.toUpperCase())
                .trim();
        };

        // Map and convert keys to spaced format
        const dataWithoutTotalCount = loanOutstandingData?.map((item) => {
            const { totalCount, ...rest } = item;

            const convertedObject = {};
            for (const key in rest) {
                convertedObject[convertToSpacedFormat(key)] = rest[key];
            }

            return convertedObject;
        });

        downloadCSV(dataWithoutTotalCount, 'data.csv');
    };

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/lending/LKP_LMS_STATUS');
        const filteredData = res?.payLoad?.filter((item) => item.code === 'PR' || item.code === 'OD' || item.code === 'DB' || item.code === 'RE');
        setStatusLovData(filteredData);
    };

    const getProductIds = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/lending/TBL_LMS_PRODUCT');
        setProductId(res?.payLoad);
    };
    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };

    useEffect(() => {
        getStatusListData();
        getUserLov();
        getProductIds();
    }, []);

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            lmsProductId: '',
            lmsStatusId: '',
            dateFrom: '',
            dateTo: '',
            rowStart: '0',
            rowEnd: '25'
        },
        validate: (data) => {
            let errors = {};
            if (data.dateTo && !data.dateFrom) {
                errors.dateFrom = 'Date From is required when Date To is provided';
            }

            if (data.dateFrom && data.dateTo && data.dateFrom > data.dateTo) {
                errors.dateTo = 'Date To must be greater than or equal to Date From';
            }
            return errors;
        },
        onSubmit: async (data) => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            dataTableRef.current.reset();

            const res = await dispatch(handlePostRequest(newData, '/lending/v1/lending/getloanoutstandingreport', true, true));
            if (res?.responseCode === process.env.REACT_APP_LENDING_SUCCESS_CODE) {
                setLoanOutstandingData(res?.payLoad);
                setShowDownload(true);
            } else {
                setLoanOutstandingData([]);
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const statusTemplate = (rowData) => {
        const statusColor = rowData?.currentStatusOfLoan === 'Approved' ? '#14A38B' : rowData?.currentStatusOfLoan === 'Pending' ? '#F2AC57' : '#FF7171';

        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.currentStatusOfLoan}</b>
            </p>
        );
    };

    const onCustomPageChange1 = (event) => {
        setCustomFirst1(event.first);
        setCustomRows1(event.rows);
        setCurrentPage(event.page + 1);
        setRender(true);
    };

    const RequestData = {
        lmsProductId: formik.values.lmsProductId,
        lmsStatusId: formik.values.lmsStatusId,
        dateFrom: formik.values.dateFrom,
        dateTo: formik.values.dateTo,
        rowStart: customFirst1,
        rowEnd: customRows1 + customFirst1
    };

    return (
        <>
            <PaginationCall
                render={render}
                customFirst1={customFirst1}
                customRows1={customRows1}
                currentPage={currentPage}
                dataTableRef={dataTableRef}
                setData={setLoanOutstandingData}
                RequestData={RequestData}
                url={'/lending/v1/lending/getloanoutstandingreport'}
                responseCode={process.env.REACT_APP_LENDING_SUCCESS_CODE}
            />
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="lmsProductId" className={classNames({ 'p-error': isFormFieldValid('lmsProductId') }, 'Label__Text')}>
                                    Product Name
                                </label>
                                <Dropdown
                                    id="lmsProductId"
                                    placeholder="Select Product"
                                    options={productId}
                                    optionLabel="name"
                                    name="lmsProductId"
                                    optionValue="lovId"
                                    value={formik.values.lmsProductId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lmsProductId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('lmsProductId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText type="date" id="dateFrom" name="dateFrom" value={formik?.values?.dateFrom} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')} />
                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText type="date" id="dateTo" name="dateTo" value={formik?.values?.dateTo} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')} />
                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="lmsStatusId" className={classNames({ 'p-error': isFormFieldValid('lmsStatusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="lmsStatusId"
                                    placeholder="Select Status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="lmsStatusId"
                                    optionValue="name"
                                    value={formik.values.lmsStatusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lmsStatusId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('lmsStatusId')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <SearchButton />
                        <ResetButton onClick={handleReset} />
                        {showDownload && <Button disabled={loading} label="Download Excel" className="Btn__Dark" onClick={handleDownload} />}
                    </div>
                </form>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable ref={dataTableRef} className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={loanOutstandingData}>
                            <Column field="loanId" header="Loan No." />
                            <Column field="productName" header="Product Name" />
                            <Column field="customerName" header="Name" />
                            <Column field="cnic" header="CNIC" />
                            <Column field="mobileNo" header="Mobile No." />
                            <Column field="loanAmount" header="Loan Amount" />
                            <Column field="outstandingMarkup" header="Outstanding Markup" />
                            <Column field="outstandingPrincipal" header="Outstanding Principal" />
                            <Column header="Status" body={statusTemplate} />
                        </DataTable>
                        <Paginator template={PaginationTemplate(true)} first={customFirst1} rows={customRows1} totalRecords={loanOutstandingData?.[0]?.totalCount} onPageChange={onCustomPageChange1}></Paginator>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LoanOutstandingReport;

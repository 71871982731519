import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { baseURL } from '../../../../../Config';

function EditTermsAndConditions({ activeIndex, handleStepClick, lendingData, lendingIdData, setStepData5, setAllStepsComplete }) {
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [languageUrdu, setLanguageUrdu] = useState('');
    const [languageEnglish, setLanguageEnglish] = useState('');
    // const [initialInput, setInitialInput] = useState(false);

    // const [termFile, setTermFile] = useState('');

    const [tncFile, setTncFile] = useState('');
    const [tncFilePathData, setTncFilePathData] = useState('');
    const [fileSizeError, setFileSizeError] = useState('');

    ///////////////////////////////////////////////

    const tncFileRef = useRef(null);

    // const dispatch = useDispatch();
    // const navigate = useNavigate();

    useEffect(() => {
        if (lendingData && lendingData?.tblLmsTncs && lendingData?.tblLmsTncs[0]) {
            formik.setFieldValue('tncText', lendingData?.tblLmsTncs[0]?.tncTextbox);
            formik.setFieldValue('textType', lendingData?.tblLmsTncs[0]?.describeTnc);
            if (lendingData?.tblLmsTncs[0]?.language === 'E') {
                setLanguageUrdu('');
                setLanguageEnglish('E');
            } else {
                setLanguageUrdu('U');
                setLanguageEnglish('');
            }

            if (lendingData?.tblLmsTncs[0]?.describeTnc === 'F') {
                // lendingData?.tblLmsTncs[0]?.filePath
                let fileName = '';
                if (lendingData?.tblLmsTncs[0]?.filePath) {
                    const filePath = lendingData?.tblLmsTncs[0]?.filePath;
                    const modifiedPath = filePath.substring(0, filePath.indexOf("documents/") + "documents".length);
                    // Use the split method to split the string by "/"
                    const parts = modifiedPath.split('/');

                    // Get the last part of the array, which is the filename
                    fileName = parts[parts.length - 1];
                    const filedata = `${baseURL}${modifiedPath}/${lendingData?.tblLmsTncs[0]?.fileName}`;
                    const updatedPath = new File([filedata], lendingData?.tblLmsTncs[0]?.fileName);
                    setTncFilePathData(updatedPath);
                }
                formik.setFieldValue('file', lendingData?.tblLmsTncs[0]?.fileName);
            }
        }
    }, [lendingData]); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            language: '',
            textType: '',
            tncText: ''
        },

        validate: (data) => {
            let errors = {};

            if (data?.textType === '') {
                errors.textType = 'this field is required';
            }

            if (data?.textType === 'T') {
                if (data?.tncText === '') {
                    errors.tncText = 'this field is required';
                }
            }

            return errors;
        },

        onSubmit: async (data) => {
            setloadingIcon('pi pi-spin pi-spinner');

            delete data['file'];

            const termsData = {
                lmsProductId: lendingIdData,
                currentStep: 5,
                language: languageUrdu ? languageUrdu : languageEnglish,
                textType: data?.textType,
                tncText: data?.tncText,
                file: tncFile || tncFilePathData
            };

            if (!fileSizeError || fileSizeError === null || fileSizeError === '') {
                setStepData5(termsData);
                handleStepClick(activeIndex + 1);
                setAllStepsComplete(true);
            }

            // const newData = {
            //     data: {
            //         security: {
            //             userName: '',
            //             password: '',
            //             securityToken: ''
            //         },
            //         account: {
            //             msidn: '',
            //             iban: '',
            //             bban: '',
            //             pan: '',
            //             currency: ''
            //         },
            //         channel: '',
            //         terminal: '',
            //         reterivalReferenceNumber: '',
            //         payLoad: termsData,
            //         additionalInformation: [
            //             {
            //                 infoKey: '',
            //                 infoValue: ''
            //             }
            //         ],
            //         checkSum: ''
            //     }
            // };

            // const formData = new FormData();
            // formData.append('request', JSON.stringify(newData));
            // if(formik.values.textType === 'F') {
            //     formData.append("file", termFile);
            // }

            // const res = await dispatch(handlePostRequest(formData, '/lending/v1/lending/savelmsproduct', true, false));
            // if (res?.responseCode === '190000') {
            //     formik.resetForm();
            //     handleStepClick(activeIndex + 1);
            //     setAllStepsComplete(true);
            // }

            setloadingIcon('');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const termsLov = [
        {
            label: 'Text',
            value: 'T'
        },
        {
            label: 'File',
            value: 'F'
        }
    ];

    const handleLanguageForU = () => {
        setLanguageUrdu('U');
        setLanguageEnglish('');
    };

    const handleLanguageForE = () => {
        setLanguageUrdu('');
        setLanguageEnglish('E');
    };

    // const handleFileChange = (event) => {
    //     const file = event.target.files[0];
    //     formik.setFieldValue('file', file);
    //     setTermFile(file);
    // };

    const handleTncFileInput = () => {
        tncFileRef.current.click();
    };

    const tncFileChange = (event) => {
        const file = event.target.files[0];
        formik.setFieldValue('file', file?.name);
        if (file?.size > 2 * 1024 * 1024) {
            setFileSizeError('File size should be less than 2mb');
        } else {
            setFileSizeError(null);
        }
        setTncFile(file);
    };

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>TERMS & CONDITIONS/ADD NEW</h1>
            </div>
            <div className="Centre_Aligned_Buttons">
                <Button id="language" name="language" value={formik.values.language} className={languageEnglish ? 'Light_Button' : 'DarkGray_Button'} label="English" onClick={handleLanguageForE} />
                {getFormErrorMessage('language')}
                <Button className={languageUrdu ? 'Light_Button' : 'DarkGray_Button'} label="Urdu" onClick={handleLanguageForU} />
            </div>
            <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                <div className="card Card__Round">
                    <>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="textType" className="Label__Text">
                                        Describe Terms and Condition as<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="textType"
                                        placeholder="Select"
                                        name="textType"
                                        options={termsLov}
                                        value={formik.values.textType || ''}
                                        onChange={formik.handleChange}
                                        optionLabel="label"
                                        optionValue="value"
                                        className="Dropdown__Round"
                                    />
                                    {getFormErrorMessage('textType')}
                                </div>
                            </div>
                            {formik.values.textType === 'T' ? (
                                <div className="p-field col-12 md:col-8 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="tncText" className="Label__Text">
                                            Text<span className="Label__Required">*</span>
                                        </label>
                                        <InputTextarea
                                            autoResize
                                            rows={5}
                                            cols={30}
                                            id="tncText"
                                            placeholder="Property Address, make and model of a vehicle"
                                            value={formik.values.tncText || ''}
                                            onChange={formik.handleChange}
                                            name="tncText"
                                            className="Input__Round"
                                        />
                                        {getFormErrorMessage('tncText')}
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        {formik.values.textType === 'F' ? (
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <div className="mb-2">
                                            <label htmlFor="file" className={'Label__Text'}>
                                                Upload File
                                            </label>
                                            <div className="file-input-container w-100" onClick={handleTncFileInput}>
                                                <input type="file" accept=".pdf,.doc,.docx" style={{ display: 'none' }} ref={tncFileRef} onChange={tncFileChange} />
                                                <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={formik?.values?.file || ''} name="file" readOnly />
                                                <span className="file-upload-icon">
                                                    <i className="pi pi-paperclip" />
                                                </span>
                                            </div>
                                            {fileSizeError ? <small className="p-error">{fileSizeError}</small> : getFormErrorMessage('file')}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="file" className={'Label__Text'}>
                                            Upload File <a download={true} href='' className='Download__Link'>- DownLoad Sample File</a>
                                        </label>
                                        <InputText
                                            id="file"
                                            placeholder="Please Upload File"
                                            name="file"
                                            type="file"
                                            // accept=".csv"
                                            onChange={handleFileChange}
                                            // className={classNames({ 'p-invalid': isFormFieldValid('file') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('file')}
                                    </div>
                                </div> */}
                                {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="name" className="Label__Text">
                                            Describe Terms and Condition as
                                            <span>
                                                - <a>Download sample file</a>
                                            </span>
                                        </label>
                                        <div style={{ display: 'flex' }}>
                                            <InputText id="name" placeholder="Enter  Name" name="name" maxLength={100}  optionLabel="label" optionValue="value" className="Dropdown__Round" />
                                            <Button label="Choose File" className="Btn__Dark" style={{ borderRadius: '5px', width: '150px' }} />
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        ) : null}
                    </>

                    {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
                                <Button label="Submit" className="Btn__Dark" style={{ borderRadius: '5px', width: '100px' }} />
                                <Button label="Close" className="Black__Btn" style={{ borderRadius: '5px', width: '100px' }} />
                            </div>
                        </div>
                    </div> */}
                    <div className="Down__Btn">
                        <Button type="submit" loadingIcon={loadingIcon} style={{ margin: '20px' }} label={lendingData?.currentStep > 4 ? 'Next' : 'Save'} className="Btn__Dark" />
                    </div>
                </div>
            </form>
        </>
    );
}

export default EditTermsAndConditions;

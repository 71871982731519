import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import * as Yup from 'yup';
import { FilterMatchMode } from 'primereact/api';
import { Paginator } from 'primereact/paginator';
import { ResetButton, SearchButton } from '../../../components/SearchButton';
import { PaginationCall, PaginationTemplate } from '../../../components/Pagination';

const ProductCatalog = () => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [statusLovData, setStatusLovData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [productCatalogData, setProductCatalogData] = useState([]);

    const [customFirst1, setCustomFirst1] = useState(0);
    const [customRows1, setCustomRows1] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [render, setRender] = useState(false);

    const dataTableRef = useRef(null);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        channelName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(res?.payLoad);
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };

    useEffect(() => {
        getStatusListData();
        getUserLov();
    }, []);

    const validationSchema = Yup.object().shape({
        fromDate: Yup.date().nullable(),

        toDate: Yup.date().when('fromDate', {
            is: (fromDate) => !!fromDate,

            then: Yup.date().required('Date To is required').min(Yup.ref('fromDate'), 'Date To has to be more than Date From')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            productCatalogName: '',
            toDate: '',
            fromDate: '',
            statusId: '2',
            createdBy: '',
            updatedBy: '',
            rowStart: customFirst1,
            rowEnd: customRows1 + customFirst1
        },
        validate: (data) => {
            let errors = {};
            if (data.toDate && !data.fromDate) {
                errors.fromDate = 'Date From is required when Date To is provided';
            }

            if (data.fromDate && data.toDate && data.fromDate > data.toDate) {
                errors.toDate = 'Date To must be greater than or equal to Date From';
            }
            return errors;
        },

        onSubmit: async (data) => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            dataTableRef.current.reset();
            setProductCatalogData('');

            const res = await dispatch(handlePostRequest(newData, '/productcatalog/v1/products/getallproduct', true, true));
            if (res?.responseCode === '120000') {
                setProductCatalogData(res?.payLoad);
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });
    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
        setProductCatalogData('');
    };
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0 ">Create Product Catalog</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const header = () => renderHeader();
    const handleSwitchChange = async (id, isActiveData, rowData) => {
        const updatedData = productCatalogData?.map((item) => {
            if (item?.productCatalogId === id) {
                const isActive = item?.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setProductCatalogData(updatedData);
        let isactive = '';
        if (isActiveData === 'Y') {
            isactive = false;
        } else if (isActiveData === 'N') {
            isactive = true;
        }
        const previousValue = isActiveData;

        const data = {
            active: isactive,
            productCatalogId: rowData.productCatalogId
        };

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newData, '/productcatalog/v1/products/inactiveproduct', true, false));
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ''));
    };

    const EditChannel = (rowData) => {
        navigate(`/editproductcatalog?productCatalogId=${rowData?.productCatalogId}`, { state: { rowData } });
    };

    const handleView = (rowData) => {
        navigate(`/viewproductcatalog?productCatalogId=${rowData?.productCatalogId}`, {
            state: { rowData }
        });
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <InputSwitch
                        checked={rowData.isActive === 'Y' ? true : false}
                        disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                        onChange={() => handleSwitchChange(rowData?.productCatalogId, rowData?.isActive, rowData)}
                    />
                    <Button
                        tooltip="Edit"
                        icon="pi pi-pencil"
                        disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) || (rowData?.lkpStatus?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                        tooltipOptions={{ position: 'top' }}
                        onClick={() => EditChannel(rowData)}
                        className="p-button-rounded p-button-primary"
                    />
                    <Button tooltip="View Details" onClick={() => handleView(rowData)} tooltipOptions={{ position: 'top' }} icon="pi pi-eye" className="p-button-rounded" style={{ backgroundColor: '#F2AC57', border: 'none' }} />
                </div>
            </>
        );
    };
    const addProductcatalog = () => {
        navigate('/addproductcatalog');
    };

    //status color function
    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.lkpStatus?.statusName === 'Approved' ? '#14A38B' : rowData?.lkpStatus?.statusName === 'Pending' ? '#F2AC57' : '#FF7171';

        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.lkpStatus?.statusName}</b>
            </p>
        );
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];
    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };
    const updatedDateAndTimeBodyTemplate = (rowData) => {
        if (rowData?.lastupdatedate) {
            const isoDate = rowData?.lastupdatedate;
            const date = new Date(isoDate);

            const mm = String(date.getMonth() + 1).padStart(2, '0');
            const dd = String(date.getDate()).padStart(2, '0');
            const yyyy = date.getFullYear();
            let hours = date.getHours();
            const minutes = String(date.getMinutes()).padStart(2, '0');

            let ampm = 'AM';
            if (hours >= 12) {
                ampm = 'PM';
                hours -= 12;
            }
            if (hours === 0) {
                hours = 12;
            }

            const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
            return <p>{formattedDateTime}</p>;
        }
    };

    const onCustomPageChange1 = (event) => {
        setCustomFirst1(event.first);
        setCustomRows1(event.rows);
        setCurrentPage(event.page + 1);
        setRender(true);
    };

    const RequestData = {
        productCatalogName: formik.values.productCatalogName,
        fromDate: formik.values.fromDate,
        toDate: formik.values.toDate,
        statusId: formik.values.statusId,
        createdBy: formik.values.createdBy,
        updatedBy: formik.values.updatedBy,
        rowStart: customFirst1,
        rowEnd: customRows1 + customFirst1
    };

    return (
        <>
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={() => addProductcatalog()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>
            <PaginationCall
                render={render}
                customFirst1={customFirst1}
                customRows1={customRows1}
                currentPage={currentPage}
                dataTableRef={dataTableRef}
                setData={setProductCatalogData}
                RequestData={RequestData}
                url={'/productcatalog/v1/products/getallproduct'}
                responseCode={'120000'}
            />
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="productCatalogName" className={classNames({ 'p-error': isFormFieldValid('productCatalogName') }, 'Label__Text')}>
                                    Name
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="productCatalogName"
                                    name="productCatalogName"
                                    value={formik?.values?.productCatalogName?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productCatalogName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('productCatalogName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pb-3">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Updated By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="fromDate" className={classNames({ 'p-error': isFormFieldValid('fromDate') }, 'Label__Text')}>
                                    Date From
                                </label>

                                <InputText
                                    max={previousDate}
                                    id="fromDate"
                                    placeholder=""
                                    name="fromDate"
                                    type="date"
                                    value={formik?.values?.fromDate?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('fromDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('fromDate')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="toDate" className={classNames({ 'p-error': isFormFieldValid('toDate') }, 'Label__Text')}>
                                    Date To
                                </label>

                                <InputText
                                    max={previousDate}
                                    id="toDate"
                                    placeholder=""
                                    name="toDate"
                                    type="date"
                                    value={formik?.values?.toDate?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('toDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('toDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="statusId" className={classNames({ 'p-error': isFormFieldValid('statusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select Status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>
                        <div className="Down__Btn mt-4">
                            <SearchButton />
                            <ResetButton onClick={handleReset} />
                        </div>
                    </div>
                </form>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            className="datatable-responsive"
                            globalFilterFields={['productCatalogName', 'createdBy', 'updatedBy', 'createdate', 'lastupdatedate', 'lkpStatus.statusName']}
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={productCatalogData}
                        >
                            <Column field="productCatalogName" header="Name" />
                            <Column field="createdBy" header="Created By" />
                            <Column field="updatedBy" header="Updated By" />
                            <Column body={createdDateAndTimeBodyTemplate} header="Created Date & Time" />
                            <Column body={updatedDateAndTimeBodyTemplate} header="Updated Date & Time" />
                            <Column body={statusBodyTemplate} header="Status" />
                            <Column header="Action" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                        <Paginator template={PaginationTemplate(false)} first={customFirst1} rows={customRows1} totalRecords={productCatalogData?.[0]?.totalCount} onPageChange={onCustomPageChange1}></Paginator>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ProductCatalog;

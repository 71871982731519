import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';

import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';

function ViewLoanAccountChecker({ }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');


    const dispatch = useDispatch();
    const navigate = useNavigate();




    const validationSchema = Yup.object().shape({

    });


    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {



        },



        onSubmit: async (data) => {

            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['startDateTime'] = formatDate(data?.startDateTime);
            data['endDateTime'] = formatDate(data?.endDateTime);
            data['stepNo'] = "0";

            if (data.isSubLimits === false) {
                delete data['amount'];
                delete data['frequencyPeriodId'];
            }

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            setloading(false);
            setloadingIcon('pi pi-search');

        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours from 24-hour to 12-hour format
        const formattedHours = hours % 12 || 12;

        const formattedDate = `${day}-${month}-${year}, ${formattedHours}:${minutes} ${ampm}`;
        return formattedDate;
    }




    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>Z-CONNECT CUSTOMER KYC VIEW DETAIL </h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="customerName" className={classNames({ 'p-error': isFormFieldValid('customerName') }, 'Label__Text')}>
                                    Customer Name <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    id="customerName"
                                    placeholder="Enter Customer Name"
                                    name="customerName"
                                    maxLength={100}
                                    value={formik?.values?.customerName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('customerName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('customerName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="mobileNumber" className={classNames({ 'p-error': isFormFieldValid('mobileNumber') }, 'Label__Text')}>
                                    Mobile Number <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    id="mobileNumber"
                                    placeholder="Enter Mobile Number"
                                    name="mobileNumber"
                                    value={formik?.values?.mobileNumber || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('mobileNumber') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('mobileNumber')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cnic" className={classNames({ 'p-error': isFormFieldValid('cnic') }, 'Label__Text')}>
                                    CNIC <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    id="cnic"
                                    placeholder="Enter CNIC"
                                    name="cnic"
                                    value={formik?.values?.cnic || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cnic') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('cnic')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') }, 'Label__Text')}>
                                    Email <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    id="email"
                                    placeholder="Enter Email"
                                    name="email"
                                    value={formik?.values?.email || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('email') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('email')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="segment" className={classNames({ 'p-error': isFormFieldValid('segment') }, 'Label__Text')}>
                                    Segment <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    id="segment"
                                    placeholder="Enter Segment"
                                    name="segment"
                                    value={formik?.values?.segment || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('segment') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('segment')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="activationDate" className={classNames({ 'p-error': isFormFieldValid('activationDate') }, 'Label__Text')}>
                                    Activation Date<span className="Label__Required"></span>
                                </label>
                                <InputText
                                    id="activationDate"
                                    placeholder=""
                                    name="activationDate"
                                    type='Date'
                                    value={formik?.values?.activationDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('activationDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('activationDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="loanId" className={classNames({ 'p-error': isFormFieldValid('loanId') }, 'Label__Text')}>
                                    Loan ID <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    id="loanId"
                                    placeholder="Enter Loan ID"
                                    name="loanId"
                                    value={formik?.values?.loanId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('loanId') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('loanId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="loanAmount" className={classNames({ 'p-error': isFormFieldValid('loanAmount') }, 'Label__Text')}>
                                    Loan Amount <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    id="loanAmount"
                                    placeholder="Enter Loan Amount"
                                    name="loanAmount"
                                    value={formik?.values?.loanAmount || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('loanAmount') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('loanAmount')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productName" className={classNames({ 'p-error': isFormFieldValid('productName') }, 'Label__Text')}>
                                    Product Name <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    id="productName"
                                    placeholder="Enter Product Name"
                                    name="productName"
                                    value={formik?.values?.productName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('productName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="loanTenure" className={classNames({ 'p-error': isFormFieldValid('loanTenure') }, 'Label__Text')}>
                                    Loan Tenure
                                </label>
                                <Dropdown
                                    id="loanTenure"
                                    placeholder="Select Loan Tenure"
                                    //options={frequencyPeriodLov}
                                    optionLabel="name"
                                    name="loanTenure"
                                    optionValue="lovId"
                                    value={formik?.values?.loanTenure || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('loanTenure') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('loanTenure')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountStatus" className={classNames({ 'p-error': isFormFieldValid('accountStatus') }, 'Label__Text')}>
                                    Account Status
                                </label>
                                <Dropdown
                                    id="accountStatus"
                                    placeholder="Select Account Status"
                                    //options={frequencyPeriodLov}
                                    optionLabel="name"
                                    name="accountStatus"
                                    optionValue="lovId"
                                    value={formik?.values?.accountStatus || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountStatus') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('accountStatus')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 ">
                            <div className="p-field">
                                <label htmlFor="issuanceDate" className={classNames({ 'p-error': isFormFieldValid('issuanceDate') }, 'Label__Text')}>
                                    Issuance Date<span className="Label__Required"></span>
                                </label>
                                <InputText
                                    id="issuanceDate"
                                    placeholder=""
                                    name="issuanceDate"
                                    type='Date'
                                    value={formik?.values?.issuanceDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('issuanceDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('issuanceDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="pricing" className={classNames({ 'p-error': isFormFieldValid('pricing') }, 'Label__Text')}>
                                    Pricing
                                </label>
                                <Dropdown
                                    id="pricing"
                                    placeholder="Select Pricing"
                                    //options={frequencyPeriodLov}
                                    optionLabel="name"
                                    name="pricing"
                                    optionValue="lovId"
                                    value={formik?.values?.pricing || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pricing') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('pricing')}
                            </div>
                        </div>

                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} label="View Loan Book" className="Btn__Dark" onClick={() => navigate('/viewloanbook')} />
                        <Button disabled={loading} label="View Active Loan" className="Btn__Transparent" onClick={() => navigate('/viewactiveloan')} />
                        <Button disabled={loading} label="Issue NOC" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default ViewLoanAccountChecker;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';

const VirtualCard = ({ virtualCardData, rowData, loading, disableBtn, type }) => {
    const channelBlockingRes = virtualCardData?.channelResponse?.channelBlockingResponse;
    const indLimits = virtualCardData?.indLimits?.[0];
    const participantDTO = virtualCardData?.participantDTO;
    const virtualCardinfo = virtualCardData?.virtualCardInfo;
    const [maskedCnicNumber, setMaskedCnicNumber] = useState('');

    const navigate = useNavigate();

    const mobileNo = rowData?.mobileNo;
    const cnic = rowData?.cnic;
    const mailingAddress = rowData?.mailingAddress;
    const channelName = rowData?.channelName;
    const segmentName = rowData?.segmentName;
    const email = rowData?.email;
    const acccountId = rowData?.accountId;

    const maskCnicNumber = (CnicNumber) => {
        if (!CnicNumber) return '';
        const length = CnicNumber.length;
        if (length <= 6) return CnicNumber;
        const first4 = CnicNumber.slice(0, 5);
        const last = CnicNumber.slice(length - 1, length);
        const maskedSection = 'x'.repeat(length - 6);
        return `${first4}-${maskedSection}-${last}`;
    };

    useEffect(() => {
        setMaskedCnicNumber(maskCnicNumber(cnic));
    }, [cnic]);

    return (
        <>
            <div className="pt-2">
                <h6 className="secondary-color">
                    <b>VIRTUAL CARD</b>
                </h6>
            </div>

            <Divider />

            <div className="p-fluid p-formgrid grid mb-5">
                <div className="p-field col-12 md:col-6 pt-3 pb-3">
                    <div className="p-field">
                        <label className="Label__Text">Name</label>
                        <InputText value={participantDTO ? `${participantDTO?.clientDirectoryFirstName} ${participantDTO?.clientDirectoryLastName}` : ''} className="Input__Round" readOnly />
                    </div>
                </div>
                <div className="p-field col-12 md:col-6 pt-3 pb-3">
                    <div className="p-field">
                        <label className="Label__Text">Mobile Number</label>
                        <InputText value={rowData?.mobileNo} className="Input__Round" readOnly />
                    </div>
                </div>
                <div className="p-field col-12 md:col-6 pt-3 pb-3">
                    <div className="p-field">
                        <label className="Label__Text">CNIC</label>
                        {/* <InputText value={rowData?.cnic} className="Input__Round" readOnly /> */}
                        <InputText value={maskedCnicNumber ?? ''} className="Input__Round" readOnly />
                    </div>
                </div>
                <div className="p-field col-12 md:col-12 pt-3 pb-3">
                    <div className="p-field">
                        <label className="Label__Text">Permanent Address</label>
                        <InputText value={rowData?.fullAddress} className="Input__Round" readOnly />
                    </div>
                </div>
                <div className="p-field col-12 md:col-12 pt-3 pb-3">
                    <div className="p-field">
                        <label className="Label__Text">Mailing Address</label>
                        <InputText value={virtualCardinfo?.address} className="Input__Round" readOnly />
                    </div>
                </div>
                <div className="p-field col-12 md:col-6 pt-3 pb-3">
                    <div className="p-field">
                        <label className="Label__Text">Channel</label>
                        <Dropdown
                            value={channelName}
                            options={[{ label: channelName, value: channelName }]} // Options with label and value
                            placeholder="Select a Channel"
                            className="Dropdown__Round"
                            disabled
                        />
                    </div>
                </div>
                <div className="p-field col-12 md:col-6 pt-3 pb-3">
                    <div className="p-field">
                        <label className="Label__Text">Product</label>
                        <Dropdown value={virtualCardinfo?.productName} options={[{ label: virtualCardinfo?.productName, value: virtualCardinfo?.productName }]} className="Dropdown__Round" disabled />
                    </div>
                </div>
            </div>

            <div className="Down__Btn">
                <Button
                    label="View More"
                    onClick={() => {
                        if (type === 'ViewPage') {
                            navigate('/viewvirtualcarddetails', { state: { virtualCardData, rowData, mobileNo, cnic, mailingAddress, channelName, segmentName, email, acccountId, type } });
                        } else {
                            navigate('/editvirtualcarddetails', { state: { virtualCardData, rowData, mobileNo, cnic, mailingAddress, channelName, segmentName, email, acccountId, type } });
                        }
                    }}
                    className="Btn__Dark"
                    loading={loading}
                    type="button"
                    disabled={disableBtn}
                />
            </div>
        </>
    );
};

export default VirtualCard;

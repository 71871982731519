import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';

import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useRef } from 'react';

import { InputTextarea } from 'primereact/inputtextarea';
import ColorPicker from 'react-pick-color';
import MobileView from './mobileView';
import { Dropdown } from 'primereact/dropdown';

function EditTheme() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const themeRef = useRef(null);
    const [themeIconFileName, setThemeIconFileName] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showColorPicker, setShowColorPicker] = useState({ textColor: false, bgColor: false });
    const [themeColorList, setThemeColorList] = useState([]);

    const location = useLocation();
    const { rowData } = location.state || {};

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setThemeColorList([
            { lovId: 'light', name: 'Light' },
            { lovId: 'dark', name: 'Dark' }
        ]);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const validationSchema = Yup.object().shape({
        themeTitle: Yup.string().required('This field is required'),
        themeCode: Yup.string().required('This field is required'),
      
        nameUrdu: Yup.string().required('This field is required'),
        themeColor: Yup.string().required('This field is required'),
        priority: Yup.string().required('This field is required'),
        cmsTagLabel: Yup.string().when('addTag', {
            is: true,
            then: Yup.string().required('This field is required')
        }),
        cmsTagTextColor: Yup.string().when('addTag', {
            is: true,
            then: Yup.string().required('This field is required')
        }),
        cmsTagBgColor: Yup.string().when('addTag', {
            is: true,
            then: Yup.string().required('This field is required')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cmsThemeId: '',
            themeTitle: '',
            themeCode: '',
            nameEnglish: '',
            nameUrdu: '',
            themeColor: '',
            copyEngToUrdu:'',
            priority: '',
            themeIcon: '',
            addTag: true,
            cmsTagLabel: '',
            cmsTagTextColor: '',
            cmsTagBgColor: '',
            expiryDate: '',
            description: '',
            isHide: '',
            isActive: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        cmsThemeId: data?.cmsThemeId,
                        themeTitle: data?.themeTitle,
                        themeCode: data?.themeCode,
                        nameEnglish: data?.nameEnglish,
                        nameUrdu: data?.nameUrdu,
                        themeColor: data?.themeColor,
                        priority: data?.priority,
                        themeIcon: themeIconFileName,
                        cmsTagLabel: data?.addTag ? data?.cmsTagLabel : '',
                        cmsTagTextColor: data?.addTag ? data?.cmsTagTextColor : '',
                        cmsTagBgColor: data?.addTag ? data?.cmsTagBgColor : '',
                        expiryDate: data?.expiryDate,
                        description: data?.description,
                        isHide: data?.isHide,
                        // isActive: data?.isActive,
                        isActive: null
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const formData = new FormData();
            if (selectedFile) {
                delete newData?.data?.payLoad?.themeIcon;
                formData.append('file', selectedFile);
            }
            formData.append('request', JSON.stringify(newData));
            await dispatch(handlePostRequest(formData, '/cms/v1/cms/updatetheme', true, false, 'theme'));

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const getThemeById = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/getthemebyid/${rowData?.cmsThemeId}`);
        const productDetails = res?.payLoad;
        formik.setFieldValue('cmsThemeId', productDetails?.cmsThemeId || '');
        formik.setFieldValue('themeTitle', productDetails?.themeTitle || '');
        formik.setFieldValue('themeCode', productDetails?.themeCode || '');
        formik.setFieldValue('nameEnglish', productDetails?.nameEnglish || '');
        formik.setFieldValue('nameUrdu', productDetails?.nameUrdu || '');
        formik.setFieldValue('priority', productDetails?.priority || '');
        formik.setFieldValue('themeColor', productDetails?.themeColor || '');
        // formik.setFieldValue('addTag', productDetails?.addTag || '');

        formik.setFieldValue('addTag', productDetails?.cmsTagLabel || productDetails?.cmsTagTextColor || productDetails?.cmsTagTextBgColor ? true : false || '');
        formik.setFieldValue('cmsTagLabel', productDetails?.cmsTagLabel || '');
        formik.setFieldValue('cmsTagTextColor', productDetails?.cmsTagTextColor || '');
        formik.setFieldValue('cmsTagBgColor', productDetails?.cmsTagBgColor || '');
        formik.setFieldValue('themeIcon', productDetails?.themeIcon || '');
        const correct = productDetails?.expiryDate ? formattedDate(productDetails?.expiryDate) : '';
        formik.setFieldValue('expiryDate', correct || '');

        formik.setFieldValue('isHide', productDetails?.isHide || '');
        formik.setFieldValue('isActive', productDetails?.isActive || '');
        formik.setFieldValue('description', productDetails?.description || '');
        const pathParts = productDetails?.themeIcon && productDetails?.themeIcon?.split('/');
        const themeIcon = pathParts && pathParts[pathParts?.length - 1];
        setThemeIconFileName(themeIcon);
    };
    useEffect(() => {
        getThemeById();
    }, [rowData]); // eslint-disable-line

    function formattedDate(originalDate) {
        const createDate = new Date(originalDate);

        const year = createDate.getFullYear();
        const month = (createDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const day = createDate.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const handledThemeIcon = () => {
        themeRef.current.click();
    };

    const handleThemeChange = (event) => {
        const selectedFile = event.target.files[0];

        if (!selectedFile) {
            formik.setFieldError('files', 'Please choose a file.');
            setSelectedFile('');
        }

        if (selectedFile) {
            setSelectedFile(selectedFile);
            setThemeIconFileName(selectedFile?.name);
        }
    };
    const getContrastColor = (hexColor) => {
        const r = parseInt(hexColor.substr(1, 2), 16);
        const g = parseInt(hexColor.substr(3, 2), 16);
        const b = parseInt(hexColor.substr(5, 2), 16);
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    };
    const handleAddTagChange = (e) => {
        formik.handleChange(e);
        if (!e.target.checked) {
            formik.setFieldValue('cmsTagLabel', '');
            formik.setFieldValue('cmsTagTextColor', '#FFFFFF');
            formik.setFieldValue('cmsTagBgColor', '#FFFFFF');
        }
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="Form__Header Full__Width">
                        <h2>EDIT DETAILS</h2>
                    </div>
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-fluid p-formgrid grid mb-5 col-12 md:col-9 ">
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="themeTitle" className={classNames({ 'p-error': isFormFieldValid('themeTitle') }, 'Label__Text')}>
                                        Theme Title<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="themeTitle"
                                        placeholder="Enter Theme Title"
                                        type="text"
                                        name="themeTitle"
                                        value={formik?.values?.themeTitle}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            if (formik.values.copyEngToUrdu) {
                                                formik.setFieldValue('nameUrdu', e.target.value);
                                            }
                                        }}
                                        className={classNames({ 'p-invalid': isFormFieldValid('themeTitle') }, 'Input__Round')}
                                    />

                                    {getFormErrorMessage('themeTitle')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="themeCode" className={classNames({ 'p-error': isFormFieldValid('themeCode') }, 'Label__Text')}>
                                        Theme Code<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="themeCode"
                                        placeholder="Enter Theme Code"
                                        type="text"
                                        name="themeCode"
                                        value={formik?.values?.themeCode}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('themeCode') }, 'Input__Round')}
                                    />

                                    {getFormErrorMessage('themeCode')}
                                </div>
                            </div>
                  

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <div className="p-field-checkbox flex justify-content-between">
                                        <label htmlFor="nameUrdu" className={classNames({ 'p-error': isFormFieldValid('nameUrdu') }, 'Label__Text')}>
                                            Display Name (Urdu)<span className="Label__Required">*</span>
                                        </label>
                                        <div className="flex align-items-center ml-auto">
                                            <label htmlFor="nameUrdu" className={classNames({ 'p-error': isFormFieldValid('nameUrdu') }, 'Label__Text')}>
                                                Same AS (English)
                                            </label>
                                        </div>
                                        <Checkbox
                                            inputId="copyEngToUrdu"
                                            name="copyEngToUrdu"
                                            checked={formik.values.copyEngToUrdu}
                                            onChange={(e) => {
                                                formik.setFieldValue('copyEngToUrdu', e.checked);
                                                if (e.checked) {
                                                    formik.setFieldValue('nameUrdu', formik.values.themeTitle);
                                                }
                                            }}
                                        />
                                    </div>
                                    <InputText
                                        maxLength={100}
                                        id="nameUrdu"
                                        placeholder="Enter Display Name In Urdu"
                                        name="nameUrdu"
                                        value={formik?.values?.nameUrdu || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('nameUrdu') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('nameUrdu')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="themeColor" className={classNames({ 'p-error': isFormFieldValid('themeColor') }, 'Label__Text')}>
                                        Theme Color<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="themeColor"
                                        placeholder="Select Theme Color"
                                        name="themeColor"
                                        value={formik?.values?.themeColor || ''}
                                        options={themeColorList}
                                        optionLabel="name"
                                        optionValue="lovId"
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('themeColor') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('themeColor')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="priority" className={classNames({ 'p-error': isFormFieldValid('priority') }, 'Label__Text')}>
                                        Priority <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="priority"
                                        keyfilter="int"
                                        placeholder="Enter Priority"
                                        name="priority"
                                        value={formik?.values?.priority || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('priority') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('priority')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <div className="mb-2">
                                        <label className="Label__Text">
                                            Theme Icon<span className="Label__Required">*</span>
                                        </label>
                                        <div className="file-input-container w-100" onClick={handledThemeIcon}>
                                            <input type="file" style={{ display: 'none' }} ref={themeRef} onChange={handleThemeChange} accept="image/*" />
                                            <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={themeIconFileName} readOnly />
                                            <span className="file-upload-icon">
                                                <i className="pi pi-paperclip" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card Card__Bg__Change col-12 md:col-12">
                                <h6 className="secondary-color">
                                    <b>TAG</b>
                                </h6>
                                <div className="p-field col-12 md:col-6 pt-3 pb-3 flex align-items-end justify-content-start">
                                    <div className="field-checkbox flex mb-2 p-field-addbanner-width ">
                                        <div className="mr-5">
                                            <Checkbox inputId="addTag" name="addTag" checked={formik.values.addTag} onChange={handleAddTagChange} style={{ marginTop: '10px' }} disabled/>
                                            <label htmlFor="addTag" className={classNames({ 'p-error': isFormFieldValid('enablePartialPayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                                Add Tag
                                            </label>
                                            <span className="Label__Required">*</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-fluid p-formgrid grid mb-5">
                                    <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                        <div className="p-field">
                                            <label htmlFor="cmsTagLabel" className={classNames({ 'p-error': isFormFieldValid('cmsTagLabel') }, 'Label__Text')}>
                                                Tag Label
                                            </label>

                                            <InputText
                                                maxLength={100}
                                                id="cmsTagLabel"
                                                placeholder="Enter Tag Label"
                                                name="cmsTagLabel"
                                                value={formik?.values?.cmsTagLabel || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagLabel') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('cmsTagLabel')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                        <div className="p-field relative">
                                            <label htmlFor="cmsTagTextColor" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextColor') }, 'Label__Text')}>
                                                Tag Text Color
                                            </label>
                                            <InputText
                                                value={formik?.values?.cmsTagTextColor || '#FFFFFF'}
                                                onChange={(e) => {
                                                    formik.setFieldValue('cmsTagTextColor', e.target.value);
                                                }}
                                                onClick={() => setShowColorPicker({ textColor: true, bgColor: false })}
                                                disabled={!formik?.values?.addTag}
                                                readOnly
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextColor') }, 'Input__Round')}
                                                style={{
                                                    backgroundColor: formik?.values?.cmsTagTextColor || '#FFFFFF',
                                                    color: getContrastColor(formik?.values?.cmsTagTextColor || '#FFFFFF')
                                                }}
                                            />
                                            {getFormErrorMessage('cmsTagTextColor')}
                                            {showColorPicker?.textColor && (
                                                <div className="colorPickerForInput">
                                                    <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                                    <ColorPicker color={formik?.values?.cmsTagTextColor || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagTextColor', e?.hex)} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                        <div className="p-field">
                                            <label htmlFor="cmsTagBgColor" className={classNames({ 'p-error': isFormFieldValid('cmsTagBgColor') }, 'Label__Text')}>
                                                Tag Background Color
                                            </label>
                                            <InputText
                                                value={formik?.values?.cmsTagBgColor || '#FFFFFF'}
                                                onChange={(e) => {
                                                    formik.setFieldValue('cmsTagBgColor', e.target.value);
                                                }}
                                                onClick={() => setShowColorPicker({ textColor: false, bgColor: true })}
                                                disabled={!formik?.values?.addTag}
                                                readOnly
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagBgColor') }, 'Input__Round')}
                                                style={{
                                                    backgroundColor: formik?.values?.cmsTagBgColor || '#FFFFFF',
                                                    color: getContrastColor(formik?.values?.cmsTagBgColor || '#FFFFFF')
                                                }}
                                            />
                                            {getFormErrorMessage('cmsTagBgColor')}
                                            {showColorPicker?.bgColor && (
                                                <div className="colorPickerForInput">
                                                    <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                                    <ColorPicker color={formik?.values?.cmsTagBgColor || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagBgColor', e?.hex)} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="expiryDate" className={classNames({ 'p-error': isFormFieldValid('expiryDate') }, 'Label__Text')}>
                                        Expiry Date
                                    </label>
                                    <InputText id="expiryDate" type="date" name="expiryDate" value={formik?.values?.expiryDate} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('expiryDate') }, 'Input__Round')} />

                                    {getFormErrorMessage('expiryDate')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="isHide" className={classNames({ 'p-error': isFormFieldValid('isHide') }, 'Label__Text')}>
                                        Status
                                    </label>
                                    <div className="flex ml-2">
                                        <div className="mt-1 mr-5">
                                            <Checkbox inputId="show" name="isHide" value="Y" checked={formik?.values?.isHide === 'Y'} onChange={(e) => formik.setFieldValue('isHide', 'Y')} style={{ marginTop: '10px' }} />
                                            <label htmlFor="show" className={classNames({ 'p-error': isFormFieldValid('show') }, 'Label__Text')}>
                                                Show
                                            </label>
                                        </div>
                                        <div className="mt-1">
                                            <Checkbox inputId="hide" name="isHide" value="N" checked={formik?.values?.isHide === 'N'} onChange={(e) => formik.setFieldValue('isHide', 'N')} style={{ marginTop: '10px' }} />
                                            <label htmlFor="hide" className={classNames({ 'p-error': isFormFieldValid('hide') }, 'Label__Text')}>
                                                Hide
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-12 w-full pt-3 pb-3 p-field-addbanner-width">
                                <div className="desc_Text_Area">
                                    <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') }, 'Label__Text')}>
                                        Description
                                    </label>
                                    <InputTextarea
                                        placeholder="-"
                                        inputid="description"
                                        name="description"
                                        rows={4}
                                        cols={30}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('description') }, 'Input__Round')}
                                        value={formik?.values?.description}
                                    />
                                    {getFormErrorMessage('description')}
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-3 pt-3 pb-3 col-4 flex align-items-start justify-content-center">
                            <MobileView theme={formik?.values?.themeColor || 'light'} />
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" loadingIcon={loadingIcon || ''} label="Update" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/theme')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default EditTheme;

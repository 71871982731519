import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useRef ,useLocation} from 'react';


function ViewManageUpload() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [compnanyName, setCompnanyName] = useState([]);

    const [productIconFileName, setProductIconFileName] = useState();
    const [productIconBase64, setProductIconBase64] = useState('');
 

    const productIconRef = useRef(null);

    const getCompnanyName = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_COMPANY_TYPE');
        setCompnanyName(resp?.payLoad);
    };
    useEffect(() => {
        getCompnanyName();
    }, []);

    const validationSchema = Yup.object().shape({
        clientEmail: Yup.string().matches('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$', 'Please enter valid email'),
        segmentName: Yup.string().required('This field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            bankName: '',
            uploadImage: '',
            bankCode:'',
            priority:'',
            DisplayNameEnglish:'',
            DisplayNameUrdu:'',
            bankIMD:'',
            status: '',
            addTag: false,
            tagLabel: '',
            tagTextColor: '',
            tagBackgroundColor: '',
            expiryDate: '',
            description: '',
       
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(newData, '/account/v1/segments/createsegment', true, false, 'segment'));
            if (res?.responseCode === '010000') {
                formik.resetForm();
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

   
    // Product Icon file code
    const handledProductIconClick = () => {
        productIconRef.current.click();
    };

    const handleCnicChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            setProductIconFileName(selectedFile.name); // Update the selected file name
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                setProductIconBase64(base64Data);
             
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="Form__Header Full__Width">
                        <h2>VIEW DETAILS</h2>
                    </div>
                    {/* <div className="p-fluid p-formgrid grid "> */}
                        <div className="p-fluid p-formgrid grid mb-5 col-12 md:col-12 ">
                            
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="title" className={classNames({ 'p-error': isFormFieldValid('title') }, 'Label__Text')}>
                                        Title<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="title"
                                        placeholder="Enter Title"
                                        name="title"
                                        value={formik?.values?.title?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('title') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('title')}
                                </div>
                            </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <label htmlFor="companyName" className={classNames({ 'p-error': isFormFieldValid('companyName') }, 'Label__Text')}>
                                        Company Name<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="companyName"
                                        placeholder="Select Company Name"
                                        options={compnanyName}
                                        optionLabel="name"
                                        name="companyName"
                                        optionValue="lovId"
                                        value={formik.values.companyName || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('companyName') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('companyName')}                             
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">                         
                                   <div className="mb-2">
                                        <label className="Label__Text">Upload File<span className="Label__Required">*</span></label>
                                        <div className="file-upload-container w-100 Input__Round_Upload flex file-input-field-upload flex justify-content-between"
                                        onClick={handledProductIconClick}>
                                            
                                            <input type="file" style={{ display: 'none' }} ref={productIconRef} onChange={handleCnicChange} accept="image/*" />
                                            {/* <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={productIconFileName} readOnly /> */}
                                            
                                            {productIconFileName?  productIconFileName:"Choose a file..."}
                                            <button label="Choose File" className="Btn__Add__Upload border-round-md" iconPos="left" style={{ width: 'auto' }}>
                                                Choose File
                                            </button>
                                        </div>                               
                                </div>
                            </div>

  
                        </div>

                        {/* <div className="p-field col-12 md:col-3 pt-3 pb-3 col-4 flex align-items-start justify-content-center">
                            <img src={phone} />
                        </div>
                    </div> */}
                    <div className="Down__Btn">
                        <Button type="submit" loadingIcon={loadingIcon || ''}  onClick={() => navigate('/manageuploadfile')} label="Okay" className="Btn__Dark" />
                        </div>
                </form>
            </div>
           
        </>
    );
}

export default ViewManageUpload;

import React from 'react';
import AddRunningFinance from './AddRunningFinance';
import AddTermLoan from './AddTermLoan';
import AddBulletLoan from './AddBulletLoan';

function AddRepaymentMethod({ activeIndex, handleStepClick, lendingData, lendingIdData, getLendingId }) {
    return (
        <>
            {
                lendingData?.lkpLmsFacilityType?.lmsFacilityTypeCode === 'BL' ? <AddBulletLoan handleStepClick={handleStepClick} activeIndex={activeIndex} lendingIdData={lendingIdData} lendingData={lendingData} getLendingId={getLendingId}/>
                : lendingData?.lkpLmsFacilityType?.lmsFacilityTypeCode === 'TL' ? <AddTermLoan handleStepClick={handleStepClick} activeIndex={activeIndex} lendingIdData={lendingIdData} lendingData={lendingData} getLendingId={getLendingId}/>
                : lendingData?.lkpLmsFacilityType?.lmsFacilityTypeCode === 'RF' ? <AddRunningFinance handleStepClick={handleStepClick} activeIndex={activeIndex} lendingIdData={lendingIdData} lendingData={lendingData} getLendingId={getLendingId}/>
                : null
            }

        </>
    );
}
export default AddRepaymentMethod;

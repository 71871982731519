import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { PickList } from 'primereact/picklist';
import { InputNumber } from 'primereact/inputnumber';

function TransactionLimitOldNewValues({ transactionLimitRowData }) {
    const [source, setSource] = useState([]);
    const [target, setTarget] = useState([]);
    const [accountLevelData, setAccountLevelData] = useState([]);
    const [productPickList, setProductPickList] = useState([]);
    const [isPickListDisabled] = useState(true);

    const getAccLevelLov = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getaccounttypes/LKP_ACCOUNT_LEVEL:*');
        setAccountLevelData(response?.payLoad);
    };

    useEffect(() => {
        if (transactionLimitRowData !== null && typeof transactionLimitRowData !== 'undefined' && typeof transactionLimitRowData !== undefined) {
            Object.keys(transactionLimitRowData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, transactionLimitRowData[key]);
                }
            });
            formik.setFieldValue('isActive', transactionLimitRowData?.isActive === 'Y' ? true : false);
            formik.setFieldValue('lkpAccountLevelId', transactionLimitRowData?.lkpAccountLevel?.accountLevelId?.toString());

            if (transactionLimitRowData?.dailyTransLimitDr === null || transactionLimitRowData?.dailyTransLimitDr === '') {
                formik.setFieldValue('activeDailyTransLimitDr', true);
            }
            if (transactionLimitRowData?.monthlyTransLimitDr === null || transactionLimitRowData?.monthlyTransLimitDr === '') {
                formik.setFieldValue('activeMonthlyTransLimitDr', true);
            }
            if (transactionLimitRowData?.yearlyTransLimitDr === null || transactionLimitRowData?.yearlyTransLimitDr === '') {
                formik.setFieldValue('activeYearlyTransLimitDr', true);
            }
            if (transactionLimitRowData?.dailyTransLimitCr === null || transactionLimitRowData?.dailyTransLimitCr === '') {
                formik.setFieldValue('activeDailyTransLimitCr', true);
            }
            if (transactionLimitRowData?.monthlyTransLimitCr === null || transactionLimitRowData?.monthlyTransLimitCr === '') {
                formik.setFieldValue('activeMonthlyTransLimitCr', true);
            }
            if (transactionLimitRowData?.yearlyTransLimitCr === null || transactionLimitRowData?.yearlyTransLimitCr === '') {
                formik.setFieldValue('activeYearlyTransLimitCr', true);
            }

            setSource(
                transactionLimitRowData?.tblTransLimitDetails?.sort((a, b) => {
                    if (a.tblProduct.productName?.toLowerCase() > b.tblProduct.productName?.toLowerCase()) {
                        return 1;
                    }
                    if (a.tblProduct.productName?.toLowerCase() < b.tblProduct.productName?.toLowerCase()) {
                        return -1;
                    }
                    return 0;
                })
            );
            setTarget(
                transactionLimitRowData?.tblProduct?.sort((a, b) => {
                    if (a.productName?.toLowerCase() > b.productName?.toLowerCase()) {
                        return 1;
                    }
                    if (a.productName?.toLowerCase() < b.productName?.toLowerCase()) {
                        return -1;
                    }
                    return 0;
                })
            );
        }
    }, [transactionLimitRowData]);

    useEffect(() => {
        getAccLevelLov();
    }, []); // eslint-disable-line

    const unselectPickListData = source?.map((item) => ({
        tblProductId: item.tblProduct.productId
    }));

    const onChange = (event) => {
        const pickListIDs = event.source.map((item) => {
            return item;
        });
        const pickListIdsData = pickListIDs.map((item) => ({
            tblProductId: item.tblProduct.productId
        }));

        setProductPickList(pickListIdsData);
        setSource(event.source);
        setTarget(event.target);
    };

    const sourceItemTemplate = (item) => {
        return (
            <div className="product-item">
                <div className="product-list-detail">
                    <h5 className="mb-2">{item.tblProduct.productName}</h5>
                </div>
            </div>
        );
    };

    const targetItemTemplate = (item) => {
        return (
            <div className="product-item">
                <div className="product-list-detail">
                    <h5 className="mb-2">{item.productName}</h5>
                </div>
            </div>
        );
    };

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('This field is required.'),
        checkerComments: Yup.string().required('This field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            limitProfileName: '',
            lkpAccountLevelId: '',
            activeDailyTransLimitDr: false,
            dailyTransLimitDr: '',
            dailyAmtLimitDr: '',
            activeMonthlyTransLimitDr: false,
            monthlyTransLimitDr: '',
            monthlyAmtLimitDr: '',
            activeYearlyTransLimitDr: false,
            yearlyTransLimitDr: '',
            yearlyAmtLimitDr: '',
            activeDailyTransLimitCr: false,
            dailyTransLimitCr: '',
            dailyAmtLimitCr: '',
            activeMonthlyTransLimitCr: false,
            monthlyTransLimitCr: '',
            monthlyAmtLimitCr: '',
            activeYearlyTransLimitCr: false,
            yearlyTransLimitCr: '',
            yearlyAmtLimitCr: ''
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>View Transaction Wise Limit</h2>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="limitProfileName" className={classNames({ 'p-error': isFormFieldValid('limitProfileName') }, 'Label__Text')}>
                                    Limit Profile <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="limitProfileName"
                                    placeholder=""
                                    name="limitProfileName"
                                    value={formik?.values?.limitProfileName?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('limitProfileName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('limitProfileName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="lkpAccountLevelId" className={classNames({ 'p-error': isFormFieldValid('lkpAccountLevelId') }, 'Label__Text')}>
                                    Limit <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="lkpAccountLevelId"
                                    placeholder="Select Account Level"
                                    options={accountLevelData}
                                    optionLabel="name"
                                    name="lkpAccountLevelId"
                                    optionValue="lovId"
                                    value={formik.values.lkpAccountLevelId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpAccountLevelId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('lkpAccountLevelId')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <PickList
                                source={source}
                                target={target}
                                className={isPickListDisabled ? 'picklist-disabled' : ''}
                                disabled={isPickListDisabled}
                                // itemTemplate={itemTemplate}
                                sourceItemTemplate={sourceItemTemplate}
                                targetItemTemplate={targetItemTemplate}
                                sourceHeader="Include"
                                targetHeader="Exclude"
                                sourceStyle={{ height: 'auto' }}
                                targetStyle={{ height: 'auto' }}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="cards_section___">
                        <div className="Debit_card">
                            <p className="card_heading__">Debit</p>
                            <div className="ca_rds">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__ mt-1">
                                        <p>Daily</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox
                                                    inputId="activeDailyTransLimitDr"
                                                    disabled={true}
                                                    name="activeDailyTransLimitDr"
                                                    value={formik.values.activeDailyTransLimitDr}
                                                    checked={formik.values.activeDailyTransLimitDr}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                            <p htmlFor="dailyTransLimitDr" className={classNames({ 'p-error': isFormFieldValid('dailyTransLimitDr') }, 'Label__Text')}>
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>
                                        <InputText
                                            disabled={true}
                                            id="dailyTransLimitDr"
                                            placeholder=""
                                            name="dailyTransLimitDr"
                                            value={formik?.values?.dailyTransLimitDr || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('dailyTransLimitDr') }, 'Input__Round')}
                                        />
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="dailyAmtLimitDr" className={classNames({ 'p-error': isFormFieldValid('dailyAmtLimitDr') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber disabled={true} inputIdid="dailyAmtLimitDr" placeholder="25000" name="dailyAmtLimitDr" value={formik?.values?.dailyAmtLimitDr || ''} onValueChange={formik.handleChange} />
                                        {getFormErrorMessage('dailyAmtLimitDr')}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__ mt-1">
                                        <p>Monthly</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    inputId="activeMonthlyTransLimitDr"
                                                    name="activeMonthlyTransLimitDr"
                                                    value={formik.values.activeMonthlyTransLimitDr}
                                                    checked={formik.values.activeMonthlyTransLimitDr}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                            <p htmlFor="monthlyTransLimitDr" className={classNames({ 'p-error': isFormFieldValid('monthlyTransLimitDr') }, 'Label__Text')}>
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>
                                        <InputText
                                            disabled={true}
                                            id="monthlyTransLimitDr"
                                            placeholder=""
                                            name="monthlyTransLimitDr"
                                            value={formik?.values?.monthlyTransLimitDr || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('monthlyTransLimitDr') }, 'Input__Round')}
                                        />
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="monthlyAmtLimitDr" className={classNames({ 'p-error': isFormFieldValid('monthlyAmtLimitDr') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber disabled={true} inputId="monthlyAmtLimitDr" placeholder="25000" name="monthlyAmtLimitDr" value={formik?.values?.monthlyAmtLimitDr || ''} onValueChange={formik.handleChange} />
                                        {getFormErrorMessage('monthlyAmtLimitDr')}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__ mt-1">
                                        <p>Yearly</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    inputId="activeYearlyTransLimitDr"
                                                    name="activeYearlyTransLimitDr"
                                                    value={formik.values.activeYearlyTransLimitDr || ''}
                                                    checked={formik.values.activeYearlyTransLimitDr}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                            <p htmlFor="yearlyTransLimitDr" className={classNames({ 'p-error': isFormFieldValid('yearlyTransLimitDr') }, 'Label__Text')}>
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>
                                        <InputText
                                            disabled={true}
                                            id="yearlyTransLimitDr"
                                            placeholder=""
                                            name="yearlyTransLimitDr"
                                            value={formik?.values?.yearlyTransLimitDr || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitDr') }, 'Input__Round')}
                                        />
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber disabled={true} inputId="yearlyAmtLimitDr" placeholder="25000" name="yearlyAmtLimitDr" value={formik?.values?.yearlyAmtLimitDr || ''} onValueChange={formik.handleChange} />
                                        {getFormErrorMessage('yearlyAmtLimitDr')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="credit_card">
                            <p className="card_heading__">Credit</p>
                            <div className="ca_rds">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__ mt-1">
                                        <p>Daily</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    inputId="activeDailyTransLimitCr"
                                                    name="activeDailyTransLimitCr"
                                                    value={formik.values.activeDailyTransLimitCr || ''}
                                                    checked={formik.values.activeDailyTransLimitCr}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                            <p htmlFor="dailyAmtLimitCr" className={classNames({ 'p-error': isFormFieldValid('dailyAmtLimitCr') }, 'Label__Text')}>
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>
                                        <InputText
                                            disabled={true}
                                            id="dailyTransLimitCr"
                                            placeholder=""
                                            name="dailyTransLimitCr"
                                            value={formik?.values?.dailyTransLimitCr || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('dailyTransLimitCr') }, 'Input__Round')}
                                        />
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber disabled={true} inputId="dailyAmtLimitCr" placeholder="25000" name="dailyAmtLimitCr" value={formik?.values?.dailyAmtLimitCr || ''} onValueChange={formik.handleChange} />
                                        {getFormErrorMessage('dailyAmtLimitCr')}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__ mt-1">
                                        <p>Monthly</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    inputId="activeMonthlyTransLimitCr"
                                                    name="activeMonthlyTransLimitCr"
                                                    value={formik.values.activeMonthlyTransLimitCr || ''}
                                                    checked={formik.values.activeMonthlyTransLimitCr}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                            <p htmlFor="monthlyTransLimitCr" className={classNames({ 'p-error': isFormFieldValid('monthlyTransLimitCr') }, 'Label__Text')}>
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>
                                        <InputText
                                            disabled={true}
                                            id="monthlyTransLimitCr"
                                            placeholder=""
                                            name="monthlyTransLimitCr"
                                            value={formik?.values?.monthlyTransLimitCr || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('monthlyTransLimitCr') }, 'Input__Round')}
                                        />
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="monthlyAmtLimitCr" className={classNames({ 'p-error': isFormFieldValid('monthlyAmtLimitCr') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber disabled={true} inputId="monthlyAmtLimitCr" placeholder="25000" name="monthlyAmtLimitCr" value={formik?.values?.monthlyAmtLimitCr || ''} onValueChange={formik.handleChange} />
                                        {getFormErrorMessage('monthlyAmtLimitCr')}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__ mt-1">
                                        <p>Yearly</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    inputId="activeYearlyTransLimitCr"
                                                    name="activeYearlyTransLimitCr"
                                                    value={formik.values.activeYearlyTransLimitCr || ''}
                                                    checked={formik.values.activeYearlyTransLimitCr}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                            <p htmlFor="yearlyTransLimitCr" className={classNames({ 'p-error': isFormFieldValid('yearlyTransLimitCr') }, 'Label__Text')}>
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>
                                        <InputText
                                            disabled={true}
                                            id="yearlyTransLimitCr"
                                            placeholder=""
                                            name="yearlyTransLimitCr"
                                            value={formik?.values?.yearlyTransLimitCr || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitCr') }, 'Input__Round')}
                                        />
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="yearlyAmtLimitCr" className={classNames({ 'p-error': isFormFieldValid('yearlyAmtLimitCr') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber disabled={true} inputId="yearlyAmtLimitCr" placeholder="25000" name="yearlyAmtLimitCr" value={formik?.values?.yearlyAmtLimitCr || ''} onValueChange={formik.handleChange} />
                                        {getFormErrorMessage('yearlyAmtLimitCr')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default TransactionLimitOldNewValues;

import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import { handleGetRequest } from '../../../services/GetTemplate';
import EmailUsed from '../Components/RegistrationSection/EmailUsed';
import Disbursment from '../Components/RegistrationSection/Disbursment';
import RegistrationChannel from '../Components/RegistrationSection/RegistrationChannel';
import RegistrationCityByLocation from '../Components/RegistrationSection/RegistrationCityByLocation';
import ChannelForOnboardingRetailer from '../Components/RegistrationSection/ChannelForOnboardingRetailer';
import OsType from '../Components/RegistrationSection/OsType';
import NumberOfAccounts from '../Components/RegistrationSection/NumberOfAccounts';
import getPercentageValue from '../Components/GetPercentage';

function RegistrationSection() {
    const [countEmailUsed, setCountEmailUsed] = useState([]);
    const [countDisbursment, setCountDisbursment] = useState([]);
    const [countRegisterationChannel, setCountRegisterationChannel] = useState([]);
    const [countOnboardingRetailerChannel, setCountOnboardingRetailerChannel] = useState([]);
    const [countCityWiseRegisteration, setCountCityWiseRegisteration] = useState([]);
    const [countOsTypeWiseRegisteration, setCountOsTypeWiseRegisteration] = useState([]);
    const [countNumberOfAccounts, setCountNumberOfAccounts] = useState([]);

    const getEmailUsedCount = async () => {
        const res = await handleGetRequest('/account/v1/riskdashboard/getemaildata');
        setCountEmailUsed(getPercentageValue(res?.payLoad));
    };

    const getDisbursmentCount = async () => {
        const res = await handleGetRequest('/account/v1/riskdashboard/getdisbursementdata');
        setCountDisbursment(getPercentageValue(res?.payLoad));
    };

    const getRegisterationChannelCount = async () => {
        const res = await handleGetRequest('/account/v1/riskdashboard/getregistrationbychanneldata');
        setCountRegisterationChannel(res?.payLoad);
    };

    const getOnboardingRetailerChannelCount = async () => {
        const res = await handleGetRequest('/account/v1/riskdashboard/getchannelforonboardingretail');
        setCountOnboardingRetailerChannel(res?.payLoad);
    };

    const getCityWiseRegisterationCount = async () => {
        const res = await handleGetRequest('/account/v1/riskdashboard/getregistrarionlocationdata');
        setCountCityWiseRegisteration(res?.payLoad);
    };

    const getOsTypeWiseRegisterationCount = async () => {
        const res = await handleGetRequest('/account/v1/riskdashboard/getostypedata');
        setCountOsTypeWiseRegisteration(res?.payLoad);
    };

    const getNumberOfAccountsCount = async () => {
        const res = await handleGetRequest('/account/v1/riskdashboard/getaccountdata');
        setCountNumberOfAccounts(res?.payLoad);
    };

    useEffect(() => {
        getEmailUsedCount();
        getDisbursmentCount();
        getRegisterationChannelCount();
        getOnboardingRetailerChannelCount();
        getCityWiseRegisterationCount();
        getOsTypeWiseRegisterationCount();
        getNumberOfAccountsCount();
    }, []);

    return (
        <>
            <div className="title__header mb-4 uppercase">Registration Section</div>
            <div className="grid">
                <EmailUsed emailUsedCount={countEmailUsed} />
                <Disbursment accountDisbursmentTypeCount={countDisbursment} />
                <RegistrationChannel registerationChannelData={countRegisterationChannel} />
                <ChannelForOnboardingRetailer onboardingRetailerChannelData={countOnboardingRetailerChannel} />
                <RegistrationCityByLocation cityWiseRegisteration={countCityWiseRegisteration} />
                <OsType osTypeWiseRegisteration={countOsTypeWiseRegisteration} />
                <NumberOfAccounts numberOfAccounts={countNumberOfAccounts} />
            </div>
        </>
    );
}
export default RegistrationSection;

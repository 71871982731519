import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewRegimeLevelChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [regimeCategorLov, setregimeCategorLov] = useState();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const getDatabyId = async () => {
        const resp = await handleGetRequest(`/tax/v1/getRegimeLevelById/${refTableId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik?.setFieldValue(key, keyData[key]);
                }
            });

            formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
            formik.setFieldValue('regimeCategory', keyData?.lkpRegimeCategory?.regimeCategoryId.toString());
        }
    };
    const getRegimeCategoryLov = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getRegimeCategory/LKP_REGIME_CATEGORY:*');
        setregimeCategorLov(response?.payLoad);
    };
    useEffect(() => {
        getRegimeCategoryLov();
    }, []);

    const getMcRequestDataById = async () => {
        setloading(true);

        const res = await handleGetRequest(`/tax/v1/getRegimeLevelByRequestId/${mcRequestId}`);
        const keyData = res?.payLoad;

        setloading(false);

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });

            formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
        }
    };

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, []); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        checkerComments: Yup.string().required('This Field is required.'),
        action: Yup.string().required('This Field is required.')
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            regimeCategory: '',
            regimeLevelCode: '',
            regimeLevelName: '',
            regimeLevelDescr: '',
            checkerComments: '',
            action: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['isActive'] = data?.isActive ? 'Y' : 'N';
            if (requestTypeDescr !== 'Enable/Disable Request') {
                delete data['isActive'];
            }

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        regimeLevelRequest: {
                            isActive: data['isActive'],
                            regimeCategory: data?.regimeCategory,
                            regimeLevelCode: data?.regimeLevelCode,
                            regimeLevelName: data?.regimeLevelName,
                            regimeLevelDescr: data?.regimeLevelDescr,
                            regimeLevelId: refTableId
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(newData, '/tax/v1/taxRegimeLevelCheckerAction', true, false, 'requestchecker'));
            if (res?.responseCode === '0000') {
                formik.resetForm();
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>VIEW REGIME LEVEL CHECKER</h2>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="regimeCategory" className={classNames({ 'p-error': isFormFieldValid('regimeCategory') }, 'Label__Text')}>
                                    Regime Category
                                </label>
                                <Dropdown
                                    disabled
                                    id="regimeCategory"
                                    placeholder="Select Regime Category"
                                    options={regimeCategorLov}
                                    optionLabel="name"
                                    name="regimeCategory"
                                    optionValue="lovId"
                                    value={formik.values.regimeCategory || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regimeCategory') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('regimeCategory')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="regimeLevelCode" className={classNames({ 'p-error': isFormFieldValid('regimeLevelCode') }, 'Label__Text')}>
                                    Regime Code<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    id="regimeLevelCode"
                                    placeholder="Enter Regime Code"
                                    name="regimeLevelCode"
                                    value={formik?.values?.regimeLevelCode?.trim()?.toString() || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regimeLevelCode') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('regimeLevelCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="regimeLevelName" className={classNames({ 'p-error': isFormFieldValid('regimeLevelName') }, 'Label__Text')}>
                                    Regime Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    id="regimeLevelName"
                                    placeholder="Enter Regime Name"
                                    name="regimeLevelName"
                                    value={formik?.values?.regimeLevelName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regimeLevelName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('regimeLevelName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="regimeLevelDescr" className={classNames({ 'p-error': isFormFieldValid('regimeLevelDescr') }, 'Label__Text')}>
                                    Description
                                </label>
                                <InputTextarea
                                    disabled
                                    autoResize="false"
                                    id="regimeLevelDescr"
                                    placeholder="Enter Description..."
                                    name="regimeLevelDescr"
                                    value={formik?.values?.regimeLevelDescr?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regimeLevelDescr') }, 'Input__Round')}
                                    rows={3}
                                    cols={30}
                                />
                                {getFormErrorMessage('regimeLevelDescr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                    isActive
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox disabled={true} inputId="isActive" name="isActive" value={formik?.values?.isActive} checked={formik?.values?.isActive} onChange={formik.handleChange} />
                                </div>
                                {getFormErrorMessage('isActive')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="requestTypeDescr" className={classNames({ 'p-error': isFormFieldValid('requestTypeDescr') }, 'Label__Text')}>
                                    Request Type Description
                                </label>
                                <InputText
                                    disabled={true}
                                    id="requestTypeDescr"
                                    name="requestTypeDescr"
                                    value={requestTypeDescr}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('requestTypeDescr') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('requestTypeDescr')}
                            </div>
                        </div>
                    </div>

                    {/* <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                </label>
                                <InputTextarea
                                    autoResize={true}
                                    rows={5}
                                    cols={30}
                                    disabled
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={checkerComments || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div> */}
                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} onClick={() => navigate('/requestchecker')} label="Ok" className="Btn__Dark" />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default ViewRegimeLevelChecker;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../services/PostTemplate';
import { handleGetRequest } from '../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';

function AddUserManagement() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [userTypeLovData, setUserTypeLovData] = useState([]);
    const [partnerLovData, setPartnerLovData] = useState([]);
    const [partnerGroupLovData, setPartnerGroupLovData] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1); // Subtract 1 day
    const previousDate = currentDate.toISOString().split('T')[0];

    const validationSchema = Yup.object().shape({
        userTypeId: Yup.mixed().required('This field is required.'),
        partner: Yup.mixed().required('This field is required.'),
        partnerGroup: Yup.mixed().required('This field is required.'),
        employeeId: Yup.string().required('Employee ID is required').max(7, 'Max length 7 allowed').matches('^[0-9]*$', 'Please enter numeric data'),
        firstName: Yup.string()
            .required('This field is required')
            .min(3, 'field must be at least 3 characters long')
            .max(50, 'field cannot exceed 50 characters')
            .matches(/^[a-zA-Z]+$/, 'field can only contain alphabets'),
        lastName: Yup.string()
            .required('This field is required')
            .min(3, 'field must be at least 3 characters long')
            .max(50, 'field cannot exceed 50 characters')
            .matches(/^[a-zA-Z]+$/, 'field can only contain alphabets'),
        userName: Yup.string()
            .required('This field is required')
            .min(3, 'field must be at least 3 characters long')
            .max(50, 'field cannot exceed 50 characters')
            .matches(/^[a-zA-Z]+$/, 'field can only contain alphabets'),
        // password: Yup.string()
        //     .required('This field is required')
        //     .min(8, 'field must be at least 8 characters long')
        //     .max(50, 'field cannot exceed 50 characters')
        //     .matches(/^[a-zA-Z0-9!@#$%^&*()]+$/, 'field can only contain alphanumeric characters and special characters: !@#$%^&*()'),
        // confirmPassword: Yup.string()
        //     .required('This field is required.')
        //     .oneOf([Yup.ref('password')], 'Passwords must match.'),
        email: Yup.string().required('This field is required').email('Invalid email format'),
        mobileNo: Yup.string().matches(/^\d{11}$/, 'field must be exactly 11 digits'),
        isActive: Yup.boolean().oneOf([true], 'Checkbox must be checked').required('Checkbox is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            authentication: '',
            userTypeId: '',
            partner: '',
            partnerGroup: '',
            employeeId: '',
            firstName: '',
            lastName: '',
            userName: '',
            password: '11111111@email.com',
            confirmPassword: '11111111@email.com',
            email: '',
            dob: '',
            mobileNo: '',
            isActive: false
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['isActive'] = data?.isActive === true ? 'Y' : 'N';
            data['authentication'] = '';

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/mfs_user_management/v1/management/createuser', true, false, 'usermanagement'));
            if (res?.responseCode === '030000') {
                formik.resetForm();
            } else {
                formik.setFieldValue('isActive', data?.isActive === 'Y' ? true : false);
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getUserTypeLov = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getappusertypes/LKP_APP_USER_TYPE:*`);
        setUserTypeLovData(res?.payLoad);
    };

    const getPartnerLov = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getpartners/${formik.values.userTypeId}`);
        setPartnerLovData(resp?.payLoad);
    };

    const getPartnerGroupLov = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getpartnerGroup/${formik.values.partner}`);
        setPartnerGroupLovData(resp?.payLoad);
    };

    useEffect(() => {
        getUserTypeLov();
    }, []);

    useEffect(() => {
        if (['', null, undefined].includes(formik?.values?.userTypeId)) {
            return;
        } else {
            getPartnerLov();
        }
    }, [formik?.values?.userTypeId]); // eslint-disable-line

    useEffect(() => {
        if (['', null, undefined].includes(formik?.values?.partner)) {
            return;
        } else {
            getPartnerGroupLov();
        }
    }, [formik?.values?.partner]); // eslint-disable-line

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>ADD NEW</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="authentication" className={classNames({ 'p-error': isFormFieldValid('authentication') }, 'Label__Text')}>
                                    Authentication
                                </label>
                                <Dropdown
                                    id="authentication"
                                    placeholder="Select Authentication"
                                    options={[]}
                                    optionLabel="description"
                                    name="authentication"
                                    optionValue="description"
                                    value={formik.values.authentication || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('authentication') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('authentication')}
                            </div>
                        </div> */}
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="userTypeId" className={classNames({ 'p-error': isFormFieldValid('userTypeId') }, 'Label__Text')}>
                                    User Type<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="userTypeId"
                                    placeholder="Select User Type"
                                    options={userTypeLovData}
                                    optionLabel="name"
                                    name="userTypeId"
                                    optionValue="lovId"
                                    value={formik.values.userTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('userTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('userTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="partner" className={classNames({ 'p-error': isFormFieldValid('partner') }, 'Label__Text')}>
                                    Partner<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="partner"
                                    placeholder="Select Partner"
                                    options={partnerLovData}
                                    optionLabel="name"
                                    name="partner"
                                    optionValue="lovId"
                                    value={formik.values.partner || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('partner') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('partner')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="partnerGroup" className={classNames({ 'p-error': isFormFieldValid('partnerGroup') }, 'Label__Text')}>
                                    Partner Group
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="partnerGroup"
                                    placeholder="Select Partner Group"
                                    options={partnerGroupLovData}
                                    optionLabel="code"
                                    name="partnerGroup"
                                    optionValue="lovId"
                                    value={formik.values.partnerGroup || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('partnerGroup') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('partnerGroup')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="employeeId" className={classNames({ 'p-error': isFormFieldValid('employeeId') }, 'Label__Text')}>
                                    Employee ID <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="employeeId"
                                    placeholder="Enter Employee ID"
                                    name="employeeId"
                                    value={formik?.values?.employeeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('employeeId') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('employeeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="firstName" className={classNames({ 'p-error': isFormFieldValid('firstName') }, 'Label__Text')}>
                                    First Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="firstName"
                                    placeholder="Enter First Name"
                                    name="firstName"
                                    value={formik?.values?.firstName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('firstName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('firstName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lastName" className={classNames({ 'p-error': isFormFieldValid('lastName') }, 'Label__Text')}>
                                    Last Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="lastName"
                                    placeholder="Enter Last Name"
                                    name="lastName"
                                    value={formik?.values?.lastName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lastName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('lastName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="userName" className={classNames({ 'p-error': isFormFieldValid('userName') }, 'Label__Text')}>
                                    User Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="userName"
                                    placeholder="Enter User Name"
                                    name="userName"
                                    value={formik?.values?.userName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('userName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('userName')}
                            </div>
                        </div>
                        {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="password" className={classNames({ 'p-error': isFormFieldValid('password') }, 'Label__Text')}>
                                    Password<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="password"
                                    placeholder="Enter Password"
                                    name="password"
                                    type="password"
                                    value={formik?.values?.password || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('password') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('password')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="confirmPassword" className={classNames({ 'p-error': isFormFieldValid('confirmPassword') }, 'Label__Text')}>
                                    Confirm Password<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="confirmPassword"
                                    placeholder="Enter Confirm Password"
                                    name="confirmPassword"
                                    type="password"
                                    value={formik?.values?.confirmPassword || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('confirmPassword') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('confirmPassword')}
                            </div>
                        </div> */}
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') }, 'Label__Text')}>
                                    Email<span className="Label__Required">*</span>
                                </label>
                                <InputText id="email" placeholder="Enter email" name="email" value={formik?.values?.email || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('email') }, 'Input__Round')} />
                                {getFormErrorMessage('email')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dob" className={classNames({ 'p-error': isFormFieldValid('dob') }, 'Label__Text')}>
                                    DOB
                                </label>
                                <InputText id="dob" type="date" max={previousDate} name="dob" value={formik?.values?.dob || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dob') }, 'Input__Round')} />

                                {getFormErrorMessage('dob')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="mobileNo" className={classNames({ 'p-error': isFormFieldValid('mobileNo') }, 'Label__Text')}>
                                    Mobile No.
                                </label>
                                <InputText
                                    id="mobileNo"
                                    placeholder="Enter Mobile No."
                                    name="mobileNo"
                                    value={formik?.values?.mobileNo || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('mobileNo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('mobileNo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                    Active <span className="Label__Required">*</span>
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isActive" name="isActive" value={formik.values.isActive} checked={formik.values.isActive} onChange={formik.handleChange} />
                                </div>
                                {getFormErrorMessage('isActive')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} label="Submit" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/usermanagement')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default AddUserManagement;

import React from 'react';
import T24AccountLinking from '../Components/T24AccountLink';

function AddT24AccountLinking() {
    return (
        <React.Fragment>
            <T24AccountLinking type="add" />
        </React.Fragment>
    );
}
export default AddT24AccountLinking;

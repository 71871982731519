import React from 'react'

const DebitCredit = ({ rowData }) => {
    return (
        <>
            <div className='grid mt-5'>

                <div className='col-12 md:col-6'>

                    <div>
                        <h5>Debit</h5>
                    </div>

                    <div className='hra_table'>
                        <table>
                            <tr>
                                <th className='dim-heading'>Particular</th>
                                <th className='dim-heading'>Count</th>
                                <th className='dim-heading'>Amount</th>
                            </tr>
                            <tr>
                                <td>Daily</td>
                                <td>{rowData?.dailyDebitCount}</td>
                                <td>{rowData?.hraDailyDebitConsumed}</td>
                            </tr>
                            <tr>
                                <td>Monthly</td>
                                <td>{rowData?.monthlyDebitCount}</td>
                                <td>{rowData?.monthlyDebitConsumed}</td>
                            </tr>
                            <tr>
                                <td>Yearly</td>
                                <td>{rowData?.yearlyDebitCount}</td>
                                <td>{rowData?.hraYearlyDebitConsumed}</td>
                            </tr>
                        </table>
                    </div>

                </div>

                <div className='col-12 md:col-6'>

                    <div>
                        <h5>Credit</h5>
                    </div>

                    <div className='hra_table'>
                        <table>
                            <tr>
                                <th className='dim-heading'>Particular</th>
                                <th className='dim-heading'>Count</th>
                                <th className='dim-heading'>Amount</th>
                            </tr>
                            <tr>
                                <td>Daily</td>
                                <td>{rowData?.dailyCreditCount}</td>
                                <td>{rowData?.dailyCreditConsumed}</td>
                            </tr>
                            <tr>
                                <td>Monthly</td>
                                <td>{rowData?.monthlyCreditCount}</td>
                                <td>{rowData?.hraMonthlyCreditConsumed}</td>
                            </tr>
                            <tr>
                                <td>Yearly</td>
                                <td>{rowData?.yearlyCreditCount}</td>
                                <td>{rowData?.yearlyCreditConsumed}</td>
                            </tr>
                        </table>
                    </div>

                </div>

            </div>
        </>
    )
}

export default DebitCredit
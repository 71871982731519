import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../services/PostTemplate';

function ChannelEdit() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    //getting rowData through useLocation
    const { rowData } = location.state || {};

    //formik
    const formik = useFormik({
        initialValues: {
            channelId: '',
            channelDescr: ''
        },
        validate: (data) => {
            let errors = {};

            //channel description Validation schema
            if (!data?.channelDescr) {
                errors.channelDescr = 'This Field is required';
            } else if (data?.channelDescr === '') {
                errors.channelDescr = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.channelDescr)) {
                errors.channelDescr = 'Leading and trailing space is not allowed';
            }
            return errors;
        },
        onSubmit: async (data) => {
            // setloading(true);
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            //API call to update channel
            await dispatch(handlePostRequest(newData, '/productcatalog/v1/channels/updatechannel', true, false, 'channel'));
        }
    });

    //mapping initial values of formik
    const mapValues = () => {
        if (rowData) {
            formik.setFieldValue('channelId', rowData?.channelId);
            formik.setFieldValue('channelDescr', rowData?.channelDescr);
        }
    };
    useEffect(() => {
        mapValues();
    }, []);

    //Functions to handle validations error
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    // Function to validate a specific field on change
    const validateFieldOnChange = (name, value) => {
        formik.setFieldTouched(name);
        formik.setFieldValue(name, value);
        formik.validateField(name);
    };
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>Edit Detail</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="channelname" className="Label__Text">
                                    Name<span className="Label__Required">*</span>
                                </label>
                                <InputText id="channelname" name="channelname" placeholder={rowData.channelName} className="Input__Round" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="channelDescr" className="Label__Text">
                                    Description<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="channelDescr"
                                    name="channelDescr"
                                    value={formik.values.channelDescr}
                                    onChange={(e) => {
                                        validateFieldOnChange('channelDescr', e.target.value);
                                    }}
                                    className="Input__Round"
                                    maxLength={200}
                                />
                                {getFormErrorMessage('channelDescr')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Update" className="Btn__Dark" disabled={!formik.values.channelDescr ? true : false} />
                        <Button onClick={() => navigate('/channel')} label="Cancel" type="button" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default ChannelEdit;

import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../services/PostTemplate';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

const UpdatePassword = () => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        currPass: Yup.string().required('This field is required'),
        newPass: Yup.string()
            .required('This field is required')
            .min(8, 'field must be at least 8 characters long')
            .max(50, 'field cannot exceed 50 characters')
            .matches(/^[a-zA-Z0-9!@#$%^&*()]+$/, 'field can only contain alphanumeric characters and special characters: !@#$%^&*()'),
        confirmPassword: Yup.string()
            .required('This field is required.')
            .oneOf([Yup.ref('newPass')], 'Passwords must match.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            currPass: '',
            newPass: '',
            confirmPassword: ''
        },
        // validate: (data) => {
        //     let errors = {};

        //     if (!data.currPass) {
        //         errors.currPass = 'Current Password is required.';
        //     }
        //     if (!data.newPass) {
        //         errors.newPass = 'New Password is required.';
        //     }
        //     if (!data.confirmPassword) {
        //         errors.confirmPassword = 'Confirm New Password is required.';
        //     }
        //     if (data.newPass !== data.confirmPassword) {
        //         errors.confirmPassword = 'Passwords are not matched';
        //     }

        //     return errors;
        // },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            delete data['confirmPassword'];
            data['userId'] = sessionStorage.getItem('userId');
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/mfs_user_management/v1/management/resetpassword', true, true));
            if (res?.responseCode === '0000') {
                sessionStorage.setItem('isNewUser', 'N');
                formik.resetForm();
                navigate('/VerifyOtp');
            }
            setloading(false);
            setloadingIcon('pi pi-save');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <div className="Forget__Body">
                <div className="Container__ChangePassword" id="container">
                    <div className="form-container sign-in-container">
                        <form action="#" className="Login__Form text-left" onSubmit={formik.handleSubmit}>
                            <div className="p-mb-4 mt-3">
                                <h3 className="forgetHeading">UPDATE YOUR PASSWORD</h3>
                            </div>
                            <div className="p-mt-4 mb-5 Card__Round">
                                <div className="p-field p-text-left">
                                    <label htmlFor="currPass" className={classNames({ 'p-error': isFormFieldValid('currPass') }, 'Label__Text')}>
                                        Current Password
                                        <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="currPass"
                                        className={classNames({ 'p-invalid': isFormFieldValid('currPass') }, 'Forget__Input')}
                                        name="currPass"
                                        value={formik.values.currPass || ''}
                                        placeholder="Enter New Password"
                                        onChange={formik.handleChange}
                                        type="password"
                                    />
                                    {getFormErrorMessage('currPass')}
                                </div>
                                <div className="p-field p-text-left mt-5">
                                    <label htmlFor="newPass" className={classNames({ 'p-error': isFormFieldValid('newPass') }, 'Label__Text')}>
                                        New Password
                                        <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="newPass"
                                        className={classNames({ 'p-invalid': isFormFieldValid('newPass') }, 'Forget__Input')}
                                        name="newPass"
                                        value={formik.values.newPass || ''}
                                        placeholder="Enter New Password"
                                        onChange={formik.handleChange}
                                        type="password"
                                    />
                                    {getFormErrorMessage('newPass')}
                                </div>
                                <div className="p-field p-text-lef mt-5">
                                    <label htmlFor="confirmPassword" className={classNames({ 'p-error': isFormFieldValid('confirmPass') }, 'Label__Text')}>
                                        Confirm Password
                                        <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        className={classNames({ 'p-invalid': isFormFieldValid('confirmPassword') }, 'Forget__Input')}
                                        name="confirmPassword"
                                        placeholder="Enter Confirm Password"
                                        value={formik.values.confirmPassword || ''}
                                        onChange={formik.handleChange}
                                        type="password"
                                    />
                                    {getFormErrorMessage('confirmPassword')}
                                </div>

                                <div className="p-mt-4">
                                    <Button className="Login__Button" label="Submit" icon={loadingIcon || ''} iconPos="right" disabled={loading} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpdatePassword;

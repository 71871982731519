import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

function EditViewSwitchConfiguration() {
    const [clientData, setClientData] = useState();

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { rowData } = location.state || {};

    const socketTypeOptions = [
        {
            name: 'Server',
            value: 'S'
        },
        {
            name: 'Client',
            value: 'C'
        }
    ];
    const statusOptions = [
        {
            name: 'Active',
            value: 'Y'
        },
        {
            name: 'Inactive',
            value: 'N'
        }
    ];
    const alphanumericWithSpacesRegex = /^[a-zA-Z0-9\/\-_]+(?: [a-zA-Z0-9\/\-_]+)*$/;
    const ipAddressRegex = /^[0-9.]+$/;

    const validationSchema = Yup.object().shape({
        // clientCode: Yup.string().required('Client Code is required').matches(alphanumericWithSpacesRegex, 'Client Code must be alphanumeric and should not contain spaces'),
        socketName: Yup.string().required('Socket Name is required').matches(alphanumericWithSpacesRegex, 'Socket Name must be alphanumeric and should not contain spaces'),
        clientName: Yup.string().required('Client Name is required').matches(alphanumericWithSpacesRegex, 'Client Name must be alphanumeric and should not contain spaces'),
        socketIp: Yup.string().required('Socket IP is required').matches(ipAddressRegex, 'Please enter a valid IP Address'),
        socketUrl: Yup.string().matches(/^(http:\/\/|https:\/\/).+$/, 'Please enter a valid URL starting with "http://" or "https://"')
    });
    const formik = useFormik({
        validationSchema,
        initialValues: {
            clientCode: '',
            clientName: '',
            safRetries: '',
            safRetriesTime: '',
            socketName: '',
            socketIp: '',
            socketPort: '',
            socketType: '',
            socketUrl: '',
            socketStatus: '',
            queueExpiryTime: '',
            queueSize: '',
            queueStatus: ''
        },

        onSubmit: async (data) => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',

                    payLoad: {
                        client: { id: rowData?.clientId, clientCode: data?.clientCode, clientName: data?.clientName, active: clientData?.isActive },
                        safConfig: { id: rowData?.safConfigId, safEntries: data?.safRetries, safEntriesTime: data?.safRetriesTime },
                        socket: { id: rowData?.socketId, name: data?.socketName, ip: data?.socketIp, port: data?.socketPort, type: data?.socketType, url: data?.socketUrl, status: data?.socketStatus },
                        queue: { id: rowData?.messageBufferQueueId, expiryTime: data?.queueExpiryTime, size: data?.queueSize, status: data?.queueStatus }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            await dispatch(handlePostRequest(newData, '/communication-service/client/update', true, false, 'switchconfiguration'));
        }
    });

    const mapValues = () => {
        formik.setFieldValue('clientCode', clientData?.clientCode);
        formik.setFieldValue('clientName', clientData?.clientName);
        formik.setFieldValue('safRetries', clientData?.safConfig?.safEntries);
        formik.setFieldValue('safRetriesTime', clientData?.safConfig?.safEntriesTime);
        formik.setFieldValue('socketName', clientData?.socket?.socketName);
        formik.setFieldValue('socketIp', clientData?.socket?.socketIp);
        formik.setFieldValue('socketPort', clientData?.socket?.socketPort);
        formik.setFieldValue('socketType', clientData?.socket?.socketType);
        formik.setFieldValue('socketUrl', clientData?.socket?.socketUrl != null ? clientData?.socket?.socketUrl : '');
        formik.setFieldValue('socketStatus', clientData?.socket?.isActive);
        formik.setFieldValue('queueExpiryTime', clientData?.queue?.queueExpityTime);
        formik.setFieldValue('queueSize', clientData?.queue?.queueLimitSize);
        formik.setFieldValue('queueStatus', clientData?.queue?.isActive);
    };

    useEffect(() => {
        if (clientData) {
            mapValues();
        }
    }, [clientData]); // eslint-disable-line

    const getRecordById = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    client: { id: rowData?.clientId }
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newData, '/communication-service/client/', true, true));
        setClientData(res?.payLoad);
    };

    useEffect(() => {
        if (rowData) {
            getRecordById();
        }
    }, [rowData]); // eslint-disable-line

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round riskDashboardPage">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">ADD CLIENT</div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="clientCode" className={classNames({ 'p-error': isFormFieldValid('clientCode') }, 'Label__Text')}>
                                    Client Code
                                </label>
                                <InputText
                                    maxLength={10}
                                    keyfilter="int"
                                    id="clientCode"
                                    name="clientCode"
                                    placeholder="Enter Client Code"
                                    value={formik?.values?.clientCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('clientCode') }, 'Input__Round')}
                                    aria-required
                                />
                                {getFormErrorMessage('clientCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="clientName" className={classNames({ 'p-error': isFormFieldValid('clientName') }, 'Label__Text')}>
                                    Client Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={30}
                                    id="clientName"
                                    name="clientName"
                                    placeholder="Enter Client Name"
                                    value={formik?.values?.clientName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('clientName') }, 'Input__Round')}
                                    aria-required
                                />
                                {getFormErrorMessage('clientName')}
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">SAF RETRIES</div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="safRetries" className={classNames({ 'p-error': isFormFieldValid('safRetries') }, 'Label__Text')}>
                                    SAF Retries
                                </label>
                                <InputText
                                    maxLength={2}
                                    id="safRetries"
                                    name="safRetries"
                                    keyfilter="int"
                                    placeholder="Enter SAF Retries"
                                    value={formik?.values?.safRetries || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('safRetries') }, 'Input__Round')}
                                    aria-required
                                />
                                {getFormErrorMessage('safRetries')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="safRetriesTime" className={classNames({ 'p-error': isFormFieldValid('safRetriesTime') }, 'Label__Text')}>
                                    SAF Retries Time (in milli's)
                                </label>
                                <InputText
                                    maxLength={10}
                                    id="safRetriesTime"
                                    name="safRetriesTime"
                                    keyfilter="int"
                                    placeholder="Enter SAF Retries Time"
                                    value={formik?.values?.safRetriesTime || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('safRetriesTime') }, 'Input__Round')}
                                    aria-required
                                />
                                {getFormErrorMessage('safRetriesTime')}
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">SOCKET CONFIGURATION</div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketName" className={classNames({ 'p-error': isFormFieldValid('socketName') }, 'Label__Text')}>
                                    Socket Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={10}
                                    id="socketName"
                                    name="socketName"
                                    placeholder="Enter Socket Name"
                                    value={formik?.values?.socketName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('socketName') }, 'Input__Round')}
                                    aria-required
                                />
                                {getFormErrorMessage('socketName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketIp" className={classNames({ 'p-error': isFormFieldValid('socketIp') }, 'Label__Text')}>
                                    Socket IP <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={15}
                                    id="socketIp"
                                    name="socketIp"
                                    placeholder="Enter IP Address"
                                    value={formik?.values?.socketIp || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('socketIp') }, 'Input__Round')}
                                    aria-required
                                />
                                {getFormErrorMessage('socketIp')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketPort" className={classNames({ 'p-error': isFormFieldValid('socketPort') }, 'Label__Text')}>
                                    Port
                                </label>
                                <InputText
                                    maxLength={4}
                                    id="socketPort"
                                    placeholder="Enter Port Number"
                                    keyfilter="int"
                                    value={formik.values.socketPort}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('socketPort') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('socketPort')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketType" className={classNames({ 'p-error': isFormFieldValid('socketType') }, 'Label__Text')}>
                                    Socket Type
                                </label>
                                <Dropdown
                                    id="socketType"
                                    placeholder="Select Socket Type"
                                    options={socketTypeOptions}
                                    optionLabel="name"
                                    optionValue="value"
                                    name="socketType"
                                    value={formik.values.socketType}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('socketType') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('socketType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-8">
                            <div className="p-field">
                                <label htmlFor="socketUrl" className={classNames({ 'p-error': isFormFieldValid('socketUrl') }, 'Label__Text')}>
                                    Socket URL
                                </label>
                                <InputText
                                    maxLength={50}
                                    id="socketUrl"
                                    name="socketUrl"
                                    placeholder="Enter Socket URL"
                                    value={formik?.values?.socketUrl || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('socketUrl') }, 'Input__Round')}
                                    aria-required
                                />
                                {getFormErrorMessage('socketUrl')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketStatus" className={classNames({ 'p-error': isFormFieldValid('socketStatus') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="socketStatus"
                                    placeholder="Select Status"
                                    options={statusOptions}
                                    optionLabel="name"
                                    optionValue="value"
                                    name="socketStatus"
                                    value={formik.values.socketStatus}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('socketStatus') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('socketStatus')}
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">QUEUE CONFIGURATION</div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="queueExpiryTime" className={classNames({ 'p-error': isFormFieldValid('queueExpiryTime') }, 'Label__Text')}>
                                    Expiry Time
                                </label>

                                <InputText
                                    // maxLength={38}
                                    id="queueExpiryTime"
                                    name="queueExpiryTime"
                                    type="time"
                                    // keyfilter="int"
                                    placeholder="Enter Expiry Time"
                                    value={formik?.values?.queueExpiryTime || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('queueExpiryTime') }, 'Input__Round')}
                                    aria-required
                                />
                                {getFormErrorMessage('queueExpiryTime')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="queueSize" className={classNames({ 'p-error': isFormFieldValid('queueSize') }, 'Label__Text')}>
                                    Queue Size
                                </label>
                                <InputText
                                    maxLength={2}
                                    id="queueSize"
                                    name="queueSize"
                                    keyfilter="int"
                                    placeholder="Enter Queue Size"
                                    value={formik?.values?.queueSize || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('queueSize') }, 'Input__Round')}
                                    aria-required
                                />
                                {getFormErrorMessage('queueSize')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="queueStatus" className={classNames({ 'p-error': isFormFieldValid('queueStatus') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="queueStatus"
                                    placeholder="Select Status"
                                    options={statusOptions}
                                    optionLabel="name"
                                    optionValue="value"
                                    name="queueStatus"
                                    value={formik.values.queueStatus}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('queueStatus') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('queueStatus')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Submit" className="Btn__Dark" />
                        <Button
                            onClick={() => {
                                navigate('/switchconfiguration');
                            }}
                            label="Cancel"
                            className="Btn__Transparent"
                        />
                    </div>
                </form>
            </div>
        </>
    );
}
export default EditViewSwitchConfiguration;

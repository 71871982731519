import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useRef } from 'react';
import SecuredFileChange from '../../../components/SecuredFileChange';
import ColorPicker from 'react-pick-color';

function AddNewProduct() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [showColorPicker, setShowColorPicker] = useState({ textColor: false, bgColor: false });

    const [mediaElement, setMediaElement] = useState([]);
    const [btnType, setBtnType] = useState([]);
    const [mainData, setMainData] = useState([]);
    const [subData, setSubData] = useState([]);
    const [secSubData, setSecSubData] = useState([]);
    const [categoryIconError, setCategoryIconError] = useState('');

    const [selectedFile, setSelectedFile] = useState(null);
    const [mediaElementType, setMediaElementType] = useState(null);
    const [fieldType, setFieldType] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

 
    const productRef = useRef(null);

    const validationSchema = Yup.object().shape({
        productId: Yup.string().required('This field is required'),
        priority: Yup.string().required('This field is required'),
        cmsMainCategoryId: Yup.string().required('This field is required'),
        cmsSubCategoryId: Yup.string().required('This field is required'),
        tagExpiryDate: Yup.string().required('This field is required'),

        cmsSecondSubCategoryId: Yup.string().required('This field is required'),
        partialPaymentEn: Yup.string().required('This field is required'),

        cmsProductName: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/, 'Only alphanumeric characters are allowed')

            .required('This field is required'),
        // displayNameEnglish: Yup.string()
        //     .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
        //     .matches(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/, 'Only alphanumeric characters and spaces are allowed')

        //     .required('This field is required'),
        productIcon: Yup.string().required('This field is required'),

        displayNameUrdu: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .required('This field is required'),
            cmsTagLabel: Yup.string().when('addCheck', {
            is: true,
            then: Yup.string().required('This field is required')
        }),
        cmsTagTextColor: Yup.string().when('addCheck', {
            is: true,
            then: Yup.string().required('This field is required')
        }),
        cmsTagTextBgColor: Yup.string().when('addCheck', {
            is: true,
            then: Yup.string().required('This field is required')
        }),
        cmsMediaElementId: Yup.mixed().required('This field is required.').nullable(),
        tblCmsProductFields: Yup.array().when('cmsMediaElementId', (cmsMediaElementId, schema) => {
            if (cmsMediaElementId == 1) {
                return Yup.array().of(
                    Yup.object().shape({
                        cmsMediaFieldTypeId: Yup.mixed().required('This field is required').nullable(),
                        cmsMediaFieldTypeTitle: Yup.string().required('This field is required').nullable(),
                        fieldCharacterLimit: Yup.string().required('This field is required').nullable(),
                        fieldPlaceholderValue: Yup.string().required('This field is required').nullable()
                        // fieldMandatory: Yup.string().required('This field is required').nullable(),
                    })
                );
            } else if (cmsMediaElementId == 2) {
                return Yup.array().of(
                    Yup.object().shape({
                        cmsMediaButtonTypeId: Yup.mixed().required('This field is required').nullable(),
                        cmsMediaButtonTypeText: Yup.string().required('This field is required').nullable()
                    })
                );
            } else {
                return schema;
            }
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cmsProductId: '',
            cmsProductName: '',
            // displayNameEnglish: '',
            displayNameUrdu: '',
            productId: '',
            priority: '',
            productIcon: '',
            addCheck: false,
            cmsTagLabel: '',
            cmsTagTextColor: '',
            cmsTagTextBgColor: '',
            tagExpiryDate: '',
            cmsMainCategoryId: '',
            copyEngToUrdu:false,

            cmsSubCategoryId: '',
            cmsSecondSubCategoryId: '',
            partialPaymentEn: '',
            iconFileName: '',
            cmsMediaElementId: '',
            isHide: 'Y',
            tblCmsProductFields: [
                {
                    cmsProductFieldsId: '',
                    cmsMediaElementId: '',
                    cmsMediaButtonTypeId: '',
                    cmsMediaButtonTypeText: '',
                    cmsMediaFieldTypeId: '',
                    cmsMediaFieldTypeTitle: '',
                    fieldCharacterLimit: '',
                    fieldPlaceholderValue: '',
                    fieldMandatory: ''
                }
            ]
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const addPayload = {
                data: {
                    security: {
                        userName: 'string',
                        password: 'string',
                        securityToken: 'string'
                    },
                    account: {
                        msidn: 'string',
                        iban: 'string',
                        bban: 'string',
                        pan: 'string',
                        currency: 'string'
                    },
                    channel: 'string',
                    terminal: 'string',
                    reterivalReferenceNumber: 'string',
                    payLoad: {
                        cmsProductId: data?.cmsProductId,
                        cmsProductName: data?.cmsProductName,
                        // displayNameEnglish: data?.displayNameEnglish,
                        displayNameUrdu: data?.displayNameUrdu,
                        productId: data?.productId,
                        priority: data?.priority,
                        productIcon: data?.productIcon,
                        cmsTagLabel: data?.cmsTagLabel,
                        cmsTagTextColor: data?.cmsTagTextColor,
                        cmsTagTextBgColor: data?.cmsTagTextBgColor,
                        tagExpiryDate: data?.tagExpiryDate,
                        cmsMainCategoryId: data?.cmsMainCategoryId,
                        cmsSubCategoryId: data?.cmsSubCategoryId,
                        cmsSecondSubCategoryId: data?.cmsSecondSubCategoryId,
                        partialPaymentEn: data?.partialPaymentEn,
                        iconFileName: data?.iconFileName,
                        isHide: data?.isHide,
                        // cmsMediaElementId: data?.cmsMediaElementId,
                        tblCmsProductFields: data?.tblCmsProductFields
                    },
                    additionalInformation: [
                        {
                            infoKey: 'string',
                            infoValue: 'string'
                        }
                    ],
                    checkSum: 'string'
                }
            };

            const formData = new FormData();
            formData.append('request', JSON.stringify(addPayload));
            if (selectedFile) {
                formData.append('file', selectedFile);
            }

            dispatch(handlePostRequest(formData, '/cms/v1/cms/saveproduct', true, false, 'product'));

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const getProductName = async () => {
        if (formik?.values?.productId) {
            const res = await handleGetRequest(`/cms/v1/cms/getproduct/${formik?.values?.productId}`, true);
            const productName = res?.payLoad;
            formik.setFieldValue('cmsProductName', productName?.productName);
        }
    };

    // const getPriority = async () => {
    //     const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_PRIORITY');
    //     setPriority(resp?.payLoad);
    // };
    const getMediaButtonElementId = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_MEDIA_ELEMENT');
        setMediaElementType(resp?.payLoad);
    };
    const getFieldType = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_MEDIA_FIELD_TYPE');
        setFieldType(resp?.payLoad);
    };

  


   

    const getMediaElement = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_MEDIA_ELEMENT');
        setMediaElement(resp?.payLoad);
    };

    const getBtnType = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_MEDIA_BUTTON_TYPE');
        setBtnType(resp?.payLoad);
    };

    const getCategoryDataLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcategorydatalov');
        setMainData(res?.payLoad);
    };

    const getSubCategoryDataLov = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getsubcategorydatalov/${formik?.values?.cmsMainCategoryId}`);
        setSubData(res?.payLoad);
    };

    const getSecSubCategoryDataLov = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getsecondsubcategorydatalov/${formik.values.cmsMainCategoryId}/${formik.values.cmsSubCategoryId}`);
        setSecSubData(res?.payLoad);
    };

    useEffect(() => {
 
    
        
       
        getMediaElement();
        getBtnType();
        getCategoryDataLov();
        getMediaButtonElementId();
        getFieldType();
        if (formik?.values?.cmsMainCategoryId) {
            getSubCategoryDataLov();
        }
        if (formik?.values?.cmsMainCategoryId && formik?.values?.cmsSubCategoryId) {
            getSecSubCategoryDataLov();
        }
    }, [formik?.values?.cmsMainCategoryId, formik?.values?.cmsSubCategoryId]);


    const handledProductIcon = () => {
        productRef.current.click();
    };

    const handleProductIconChange = (event) => {
        setCategoryIconError('');
        formik.setFieldValue('productIcon', '');
        setSelectedFile(null);
        const selectedFile = event.target.files[0];
        const fileTypes = ['image'];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setCategoryIconError(error);
            setSelectedFile(null);
        } else if (newFile) {
            setSelectedFile(selectedFile);
            formik.setFieldValue('productIcon', selectedFile.name);
        }
    };
    const handleAddButton = () => {
        const newButtons = [
            ...formik.values.tblCmsProductFields,
            {
                cmsProductFieldsId: '',
                cmsMediaElementId: formik.values.cmsMediaElementId,
                cmsMediaButtonTypeId: '',
                cmsMediaButtonTypeText: '',
                cmsMediaFieldTypeId: '',
                cmsMediaFieldTypeTitle: '',
                fieldCharacterLimit: '',
                fieldPlaceholderValue: '',
                fieldMandatory: ''
            }
        ];

        formik.setValues({
            ...formik.values,
            tblCmsProductFields: newButtons
        });
    };

    const handleRemoveButton = (index) => {
        const updatedDetails = [...formik.values.tblCmsProductFields];

        updatedDetails.splice(index, 1);
        formik.setValues({
            ...formik.values,
            tblCmsProductFields: updatedDetails
        });
    };
    const getContrastColor = (hexColor) => {
        const r = parseInt(hexColor.substr(1, 2), 16);
        const g = parseInt(hexColor.substr(3, 2), 16);
        const b = parseInt(hexColor.substr(5, 2), 16);
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    };
    const handleAddTagChange = (e) => {
        formik.handleChange(e);
        if (!e.target.checked) {
            formik.setFieldValue('cmsTagLabel', '');
            formik.setFieldValue('cmsTagTextColor', '#FFFFFF');
            formik.setFieldValue('cmsTagTextBgColor', '#FFFFFF');
        }
    };
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header Full__Width">
                    <h2>ADD NEW</h2>
                </div>

                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productId" className={classNames({ 'p-error': isFormFieldValid('productId') }, 'Label__Text')}>
                                    Product ID <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="productId"
                                    keyfilter="int"
                                    placeholder="Enter product ID"
                                    name="productId"
                                    value={formik?.values?.productId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productId') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('productId')}
                            </div>
                        </div>
                        <dvi className="p-field col-12 md:col-8 pt-5 pb-3">
                            <div className="Down__Btn justify-content-start mt-2">
                                <Button type="button" label="Get" onClick={() => getProductName()} className="Btn__Dark" />
                            </div>
                        </dvi>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsProductName" className={classNames({ 'p-error': isFormFieldValid('cmsProductName') }, 'Label__Text')}>
                                    Product Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="cmsProductName"
                                    placeholder="Enter product name"
                                    name="cmsProductName"
                                    value={formik?.values?.cmsProductName || ''}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        if (formik.values.copyEngToUrdu) {
                                            formik.setFieldValue('displayNameUrdu', e.target.value);
                                        }
                                    }}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsProductName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('cmsProductName')}
                            </div>
                        </div>
                      
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                <div className="p-field-checkbox flex justify-content-between">
                                   
                                   <label htmlFor="nameUrdu" className={classNames({ 'p-error': isFormFieldValid('nameUrdu') }, 'Label__Text')}>
                                   Display Name (Urdu)<span className="Label__Required">*</span>
                                     
                                   </label>
                                 <div className='flex align-items-center ml-auto'>
                                   <label htmlFor="nameUrdu" className={classNames({ 'p-error': isFormFieldValid('nameUrdu') }, 'Label__Text')}>
                                      Same AS (English)
                                     
                                   </label>
                                   </div>
                                   <Checkbox
                                       inputId="copyEngToUrdu"
                                       name="copyEngToUrdu"
                                       checked={formik.values.copyEngToUrdu}
                                       onChange={(e) => {
                                           formik.setFieldValue('copyEngToUrdu', e.checked);
                                           if (e.checked) {
                                               formik.setFieldValue('displayNameUrdu', formik.values.cmsProductName);
                                           }
                                       }}
                                   />
                                 
                               </div>
                                   
                                    <InputText
                                        maxLength={100}
                                        id="displayNameUrdu"
                                        placeholder="Enter Display Name (Urdu) "
                                        name="displayNameUrdu"
                                        value={formik?.values?.displayNameUrdu || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('displayNameUrdu') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('displayNameUrdu')}
                                </div>
                            </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="priority" className={classNames({ 'p-error': isFormFieldValid('priority') }, 'Label__Text')}>
                                    Product Priority<span className="Label__Required">*</span>
                                </label>
                              
                                <InputText
                                    maxLength={100}
                                    id="priority"
                                    placeholder=""
                                    keyfilter='int'

                                    name="priority"
                                    value={formik?.values?.priority}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('priority') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('priority')}
                            </div>
                        </div>
                        {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <div className="mb-2">
                                    <label className={classNames({ 'p-error': isFormFieldValid('kfsInput') }, 'Label__Text')}>
                                        Product Icon 
                                    </label>
                                    <div className="file-upload-container w-100 Input__Round_Upload flex file-input-field-upload flex justify-content-between" onClick={handledProductIcon}>
                                        <input type="file" style={{ display: 'none' }} ref={productIconRef} onChange={handleProductIconChange} accept="image/*" />
                                        {productIcon ? productIcon : 'Choose a file...'}
                                        <button type="button" label="Choose File" className="Btn__Add__Upload border-round-md" style={{ width: 'auto' }}>
                                            Choose File
                                        </button>
                                    </div>
                                </div>
                               
                                {getFormErrorMessage('productIcon')}
                            </div>
                        </div> */}
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <div className="mb-2">
                                    <label className={classNames({ 'p-error': isFormFieldValid('productIcon') }, 'Label__Text')}>
                                        Product Icon
                                        <span className="Label__Required">*</span>
                                    </label>
                                    <div className="file-input-container w-100" onClick={handledProductIcon}>
                                        <input type="file" accept="image/*" style={{ display: 'none' }} ref={productRef} onChange={handleProductIconChange} />
                                        <input
                                            type="text"
                                            className={classNames({ 'p-inputtext p-component p-invalid': isFormFieldValid('productIcon') }, 'file-input-field Input__Round')}
                                            placeholder="Choose a file..."
                                            value={formik?.values?.productIcon}
                                            readOnly
                                        />
                                        <span className="file-upload-icon">
                                            <i className="pi pi-paperclip" />
                                        </span>
                                    </div>
                                    {categoryIconError ? <small className="p-error">{categoryIconError}</small> : getFormErrorMessage('productIcon')}
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>
                        <div className="p-field col-12 md:col-1 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="addCheck" className={classNames({ 'p-error': isFormFieldValid('addCheck') }, 'Label__Text')}>
                                    Add Tag
                                </label>
                                <div className="field-checkbox">
                                <Checkbox
                                inputId="addCheck"
                                name="addCheck"
                                checked={formik.values.addCheck}
                                onChange={handleAddTagChange}
                                style={{ marginTop: '10px' }}
                            />
                                </div>
                                {getFormErrorMessage('addCheck')}
                            </div>
                        </div>
                       
                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsTagLabel" className={classNames({ 'p-error': isFormFieldValid('cmsTagLabel') }, 'Label__Text')}>
                                    Tag Label
                                </label>
                             
                                <InputText
                                    maxLength={100}
                                    id="cmsTagLabel"
                                    placeholder="Enter Tag Label"
                                    name="cmsTagLabel"
                                    disabled={!formik?.values?.addCheck}

                                    value={formik?.values?.cmsTagLabel || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagLabel') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('cmsTagLabel')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field relative">
                                <label htmlFor="cmsTagTextColor" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextColor') }, 'Label__Text')}>
                                    Tag Text Color
                                </label>
                                <InputText
                                    value={formik?.values?.cmsTagTextColor || '#FFFFFF'}
                                    onChange={(e) => {
                                        formik.setFieldValue('cmsTagTextColor', e.target.value);
                                    }}
                                    onClick={() => setShowColorPicker({ textColor: true, bgColor: false })}
                                    disabled={!formik?.values?.addCheck}
                                    readOnly
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextColor') }, 'Input__Round')}
                                    style={{
                                        backgroundColor: formik?.values?.cmsTagTextColor || '#FFFFFF',
                                        color: getContrastColor(formik?.values?.cmsTagTextColor || '#FFFFFF')
                                    }}
                                />
                                {getFormErrorMessage('cmsTagTextColor')}
                                {showColorPicker?.textColor && (
                                    <div className="colorPickerForInput">
                                        <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                        <ColorPicker color={formik?.values?.cmsTagTextColor || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagTextColor', e?.hex)} />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                            <div className="p-field">
                                <label htmlFor="cmsTagTextBgColor" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextBgColor') }, 'Label__Text')}>
                                    Tag Background Color
                                </label>
                                <InputText
                                    value={formik?.values?.cmsTagTextBgColor || '#FFFFFF'}
                                    onChange={(e) => {
                                        formik.setFieldValue('cmsTagTextBgColor', e.target.value);
                                    }}
                                    onClick={() => setShowColorPicker({ textColor: false, bgColor: true })}
                                    disabled={!formik?.values?.addCheck}
                                    readOnly
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextBgColor') }, 'Input__Round')}
                                    style={{
                                        backgroundColor: formik?.values?.cmsTagTextBgColor || '#FFFFFF',
                                        color: getContrastColor(formik?.values?.cmsTagTextBgColor || '#FFFFFF')
                                    }}
                                />
                                {getFormErrorMessage('cmsTagTextBgColor')}
                                {showColorPicker?.bgColor && (
                                    <div className="colorPickerForInput">
                                        <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                        <ColorPicker color={formik?.values?.cmsTagTextBgColor || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagTextBgColor', e?.hex)} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="tagExpiryDate" className={classNames({ 'p-error': isFormFieldValid('tagExpiryDate') }, 'Label__Text')}>
                                    Expiry Date <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    type="date"
                                    id="tagExpiryDate"
                                    min={previousDate}

                                    name="tagExpiryDate"
                                    value={formik?.values?.tagExpiryDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('tagExpiryDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('tagExpiryDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsMainCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsMainCategoryId') }, 'Label__Text')}>
                                    Category<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="cmsMainCategoryId"
                                    placeholder="Select Category"
                                    options={mainData}
                                    optionLabel="name"
                                    name="cmsMainCategoryId"
                                    optionValue="lovId"
                                    value={formik.values.cmsMainCategoryId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsMainCategoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsMainCategoryId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsSubCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsSubCategoryId') }, 'Label__Text')}>
                                    Sub Category<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="cmsSubCategoryId"
                                    placeholder="Select Sub Category"
                                    options={subData}
                                    optionLabel="name"
                                    name="cmsSubCategoryId"
                                    optionValue="lovId"
                                    value={formik.values.cmsSubCategoryId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsSubCategoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsSubCategoryId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="cmsSecondSubCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsSecondSubCategoryId') }, 'Label__Text')}>
                                    2nd Sub Category<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="cmsSecondSubCategoryId"
                                    placeholder="Select 2nd Sub Category"
                                    options={secSubData}
                                    optionLabel="name"
                                    name="cmsSecondSubCategoryId"
                                    optionValue="lovId"
                                    value={formik.values.cmsSecondSubCategoryId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsSecondSubCategoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsSecondSubCategoryId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="partialPaymentEn" className={classNames({ 'p-error': isFormFieldValid('partialPaymentEn') }, 'Label__Text')}>
                                    Enable Partial Payment <span className="Label__Required">*</span>
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox
                                        inputId="partialPaymentEn"
                                        name="partialPaymentEn"
                                        value={formik?.values?.partialPaymentEn}
                                        checked={formik?.values?.partialPaymentEn === 'Y'}
                                        onChange={(e) => formik.setFieldValue('partialPaymentEn', e.target.checked ? 'Y' : 'N')}
                                        style={{ marginTop: '10px' }}
                                    />

                                    <label htmlFor="partialPaymentEn" className={classNames({ 'p-error': isFormFieldValid('partialPaymentEn') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        active
                                    </label>
                                </div>
                                {getFormErrorMessage('partialPaymentEn')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-2 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isHide" className={classNames({ 'p-error': isFormFieldValid('isHide') }, 'Label__Text')}>
                                    Status
                                </label>
                                <div className="flex ml-2">
                                    <div className="mt-0 mr-5">
                                        <Checkbox inputId="show" name="isHide" value="Y" checked={formik?.values?.isHide === 'Y'} onChange={(e) => formik.setFieldValue('isHide', 'Y')} style={{ marginTop: '10px' }} />
                                        <label htmlFor="show" className={classNames({ 'p-error': isFormFieldValid('show') }, 'Label__Text')}>
                                            Show
                                        </label>
                                    </div>
                                    <div className="mt-0">
                                        <Checkbox inputId="hide" name="isHide" value="N" checked={formik?.values?.isHide === 'N'} onChange={(e) => formik.setFieldValue('isHide', 'N')} style={{ marginTop: '10px' }} />
                                        <label htmlFor="hide" className={classNames({ 'p-error': isFormFieldValid('hide') }, 'Label__Text')}>
                                            Hide
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsMediaElementId" className={classNames({ 'p-error': isFormFieldValid('cmsMediaElementId') }, 'Label__Text')}>
                                    Add Media Elements<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="cmsMediaElementId"
                                    placeholder="Select Media Elements"
                                    options={mediaElementType}
                                    optionLabel="name"
                                    name="cmsMediaElementId"
                                    optionValue="lovId"
                                    value={formik.values.cmsMediaElementId || ''}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        formik.setFieldValue('tblCmsProductFields', [
                                            {
                                                cmsProductFieldsId: '',
                                                cmsMediaElementId: e.target.value,
                                                cmsMediaButtonTypeId: '',
                                                cmsMediaButtonTypeText: '',
                                                cmsMediaFieldTypeId: '',
                                                cmsMediaFieldTypeTitle: '',
                                                fieldCharacterLimit: '',
                                                fieldPlaceholderValue: '',
                                                fieldMandatory: ''
                                            }
                                        ]);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsMediaElementId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsMediaElementId')}
                            </div>
                        </div>
                    </div>

                    {formik.values.cmsMediaElementId == '2' &&
                        formik?.values?.tblCmsProductFields?.map((_, index) => {
                            return (
                                <div className="card Card__Bg__Change col-12 md:col-12 px-3" key={index}>
                                    <h6 className="secondary-color pt-1">
                                        <b>Button {index + 1}</b>
                                    </h6>
                                    <div className="flex">
                                        <div className="p-fluid p-formgrid grid mb-5 col-10 md:col-10">
                                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                                <div className="p-field">
                                                    <label htmlFor={`tblCmsProductFields.${index}.cmsMediaButtonTypeId`} className={classNames({ 'p-error': isFormFieldValid(`tblCmsProductFields.${index}.cmsMediaButtonTypeId`) }, 'Label__Text')}>
                                                        Select Button <span className="Label__Required">*</span>
                                                    </label>
                                                    <Dropdown
                                                        id={`tblCmsProductFields${index}.cmsMediaButtonTypeId`}
                                                        placeholder="Select Type Of Button"
                                                        name={`tblCmsProductFields.${index}.cmsMediaButtonTypeId`}
                                                        optionLabel="name"
                                                        options={btnType}
                                                        optionValue="lovId"
                                                        value={formik.values.tblCmsProductFields[index]?.cmsMediaButtonTypeId || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames(
                                                            {
                                                                'p-invalid': formik.touched.tblCmsProductFields?.[index]?.cmsMediaButtonTypeId && formik.errors.tblCmsProductFields?.[index]?.cmsMediaButtonTypeId
                                                            },
                                                            'Dropdown__Round'
                                                        )}
                                                    />
                                                    <small className="p-error">{formik.touched.tblCmsProductFields?.[index]?.cmsMediaButtonTypeId && formik.errors.tblCmsProductFields?.[index]?.cmsMediaButtonTypeId}</small>
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                                <div className="p-field">
                                                    <label htmlFor={`tblCmsProductFields.${index}.cmsMediaButtonTypeText`} className={classNames({ 'p-error': isFormFieldValid(`tblCmsProductFields.${index}.cmsMediaButtonTypeText`) }, 'Label__Text')}>
                                                        Text<span className="Label__Required">*</span>
                                                    </label>
                                                    <InputText
                                                        maxLength={100}
                                                        id={`tblCmsProductFields.${index}.cmsMediaButtonTypeText`}
                                                        placeholder="Enter Action Button Text"
                                                        name={`tblCmsProductFields.${index}.cmsMediaButtonTypeText`}
                                                        value={formik.values.tblCmsProductFields[index]?.cmsMediaButtonTypeText || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames(
                                                            {
                                                                'p-invalid': formik.touched.tblCmsProductFields?.[index]?.cmsMediaButtonTypeText && formik.errors.tblCmsProductFields?.[index]?.cmsMediaButtonTypeText
                                                            },
                                                            'Input__Round'
                                                        )}
                                                    />

                                                    <small className="p-error">{formik.touched.tblCmsProductFields?.[index]?.cmsMediaButtonTypeText && formik.errors.tblCmsProductFields?.[index]?.cmsMediaButtonTypeText}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2 md:col-2 flex flex-column align-items-center justify-content-center mb-3">
                                            <button type="button" className="addition_Button" onClick={() => handleAddButton(index)}>
                                                +
                                            </button>
                                            <button type="button" className="reduction_Button" onClick={() => handleRemoveButton(index)}>
                                                -
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                    {formik.values.cmsMediaElementId == '1' &&
                        formik?.values?.tblCmsProductFields?.map((_, index) => {
                            return (
                                <div className="card Card__Bg__Change col-12 md:col-12 px-3" key={index}>
                                    <h6 className="secondary-color pt-1">
                                        <b>Fields {index + 1}</b>
                                    </h6>
                                    <div className="flex">
                                        <div className="p-fluid p-formgrid grid mb-5 col-10 md:col-10">
                                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                                <div className="p-field">
                                                    <label htmlFor={`tblCmsProductFields.${index}.cmsMediaFieldTypeId`} className={classNames({ 'p-error': isFormFieldValid(`tblCmsProductFields.${index}.cmsMediaFieldTypeId`) }, 'Label__Text')}>
                                                        Select Field
                                                    </label>
                                                    <Dropdown
                                                        id={`tblCmsProductFields${index}.cmsMediaFieldTypeId`}
                                                        placeholder="Select Type Of Fields"
                                                        name={`tblCmsProductFields.${index}.cmsMediaFieldTypeId`}
                                                        optionLabel="name"
                                                        options={fieldType}
                                                        optionValue="lovId"
                                                        value={formik.values.tblCmsProductFields[index]?.cmsMediaFieldTypeId || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames(
                                                            {
                                                                'p-invalid': formik.touched.tblCmsProductFields?.[index]?.cmsMediaFieldTypeId && formik.errors.tblCmsProductFields?.[index]?.cmsMediaFieldTypeId
                                                            },
                                                            'Dropdown__Round'
                                                        )}
                                                    />
                                                    {/* {formik.touched.tblCmsProductFields?.[index]?.cmsMediaFieldTypeId && formik.errors.tblCmsProductFields?.[index]?.cmsMediaFieldTypeId && (
                                                        <small className="p-error">{formik.errors.tblCmsProductFields?.[index]?.cmsMediaFieldTypeId}</small>
                                                    )} */}
                                                    <small className="p-error">{formik.touched.tblCmsProductFields?.[index]?.cmsMediaFieldTypeId && formik.errors.tblCmsProductFields?.[index]?.cmsMediaFieldTypeId}</small>
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                                <div className="p-field">
                                                    <label htmlFor={`tblCmsProductFields.${index}.cmsMediaFieldTypeTitle`} className={classNames({ 'p-error': isFormFieldValid(`tblCmsProductFields.${index}.cmsMediaFieldTypeTitle`) }, 'Label__Text')}>
                                                        Title
                                                    </label>
                                                    <InputText
                                                        maxLength={100}
                                                        id={`tblCmsProductFields.${index}.cmsMediaFieldTypeTitle`}
                                                        placeholder="Enter Action Field Text"
                                                        name={`tblCmsProductFields.${index}.cmsMediaFieldTypeTitle`}
                                                        value={formik.values.tblCmsProductFields[index]?.cmsMediaFieldTypeTitle || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames(
                                                            {
                                                                'p-invalid': formik.touched.tblCmsProductFields?.[index]?.cmsMediaFieldTypeTitle && formik.errors.tblCmsProductFields?.[index]?.cmsMediaFieldTypeTitle
                                                            },
                                                            'Input__Round'
                                                        )}
                                                    />
                                                    <small className="p-error">{formik.touched.tblCmsProductFields?.[index]?.cmsMediaFieldTypeTitle && formik.errors.tblCmsProductFields?.[index]?.cmsMediaFieldTypeTitle}</small>
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                                <div className="p-field">
                                                    <label htmlFor={`tblCmsProductFields.${index}.fieldCharacterLimit`} className={classNames({ 'p-error': isFormFieldValid(`tblCmsProductFields.${index}.fieldCharacterLimit`) }, 'Label__Text')}>
                                                        Character Limit
                                                    </label>
                                                    <InputText
                                                        maxLength={100}
                                                        id={`tblCmsProductFields.${index}.fieldCharacterLimit`}
                                                        placeholder="Enter Action Field Text"
                                                        name={`tblCmsProductFields.${index}.fieldCharacterLimit`}
                                                        value={formik.values.tblCmsProductFields[index]?.fieldCharacterLimit || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames(
                                                            {
                                                                'p-invalid': formik.touched.tblCmsProductFields?.[index]?.fieldCharacterLimit && formik.errors.tblCmsProductFields?.[index]?.fieldCharacterLimit
                                                            },
                                                            'Input__Round'
                                                        )}
                                                    />

                                                    <small className="p-error">{formik.touched.tblCmsProductFields?.[index]?.fieldCharacterLimit && formik.errors.tblCmsProductFields?.[index]?.fieldCharacterLimit}</small>
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                                <div className="p-field">
                                                    <label htmlFor={`tblCmsProductFields.${index}.fieldPlaceholderValue`} className={classNames({ 'p-error': isFormFieldValid(`tblCmsProductFields.${index}.fieldPlaceholderValue`) }, 'Label__Text')}>
                                                        Placeholder Value
                                                    </label>
                                                    <InputText
                                                        maxLength={100}
                                                        id={`tblCmsProductFields.${index}.fieldPlaceholderValue`}
                                                        placeholder="Enter Action Field Text"
                                                        name={`tblCmsProductFields.${index}.fieldPlaceholderValue`}
                                                        value={formik.values.tblCmsProductFields[index]?.fieldPlaceholderValue || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames(
                                                            {
                                                                'p-invalid': formik.touched.tblCmsProductFields?.[index]?.fieldPlaceholderValue && formik.errors.tblCmsProductFields?.[index]?.fieldPlaceholderValue
                                                            },
                                                            'Input__Round'
                                                        )}
                                                    />

                                                    <small className="p-error">{formik.touched.tblCmsProductFields?.[index]?.fieldPlaceholderValue && formik.errors.tblCmsProductFields?.[index]?.fieldPlaceholderValue}</small>
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor="fieldMandatory" className={classNames({ 'p-error': isFormFieldValid('fieldMandatory') }, 'Label__Text')}></label>
                                                    <div className="field-checkbox">
                                                        <Checkbox
                                                            inputId="fieldMandatory"
                                                            name="fieldMandatory"
                                                            value={formik?.values?.fieldMandatory}
                                                            checked={formik?.values?.fieldMandatory === 'Y'}
                                                            onChange={(e) => formik.setFieldValue('fieldMandatory', e.target.checked ? 'Y' : 'N')}
                                                            style={{ marginTop: '10px' }}
                                                        />

                                                        <label htmlFor="fieldMandatory" className={classNames({ 'p-error': isFormFieldValid('fieldMandatory') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                                            Mandatory
                                                        </label>
                                                    </div>
                                                    <small className="p-error">{formik.touched.tblCmsProductFields?.[index]?.fieldMandatory && formik.errors.tblCmsProductFields?.[index]?.fieldMandatory}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2 md:col-2 flex flex-column align-items-center justify-content-center mb-3">
                                            <button type="button" className="addition_Button" onClick={() => handleAddButton(index)}>
                                                +
                                            </button>
                                            <button type="button" className="reduction_Button" onClick={() => handleRemoveButton(index)}>
                                                -
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                    <div className="Down__Btn">
                        <Button type="submit" label="Submit" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/product')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddNewProduct;

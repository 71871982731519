import React, { useState } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { handlePostRequest } from '../../../services/PostTemplate';
import { Checkbox } from 'primereact/checkbox';
import { useEffect } from 'react';

const TierDialog = ({ onHideTier, dialogData, editable }) => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-save');

    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        tier: Yup.string().required('This field is required.').nullable(),
        tierRange: Yup.string().required('This field is required.').nullable()
    });

    useEffect(() => {
        formik.setFieldValue('tier', dialogData?.tier);
        formik.setFieldValue('tierRange', dialogData?.maxTierRange);
        formik.setFieldValue('multiplier', dialogData?.multiplier);
        formik.setFieldValue('isActive', dialogData?.isActive === 'Y' ? 'true' : 'false');
    }, [dialogData]); // eslint-disable-line

    useEffect(() => {
        if (!editable) {
            formik.resetForm();
        }
    }, [editable]); // eslint-disable-line

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            tier: '',
            tierRange: '',
            multiplier: '',
            isActive: 'N',
            statusId: 0,
            zmilesTierId: dialogData?.zmilesTierId
        },

        onSubmit: async (data) => {
            if (!editable) {
                delete data['zmilesTierId'];
            }
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            if (editable) {
                const res = await dispatch(handlePostRequest(newData, `/zconfig/v1/zmiles/updateTier`, true, false));
                if (res?.responseCode === '330000') {
                    formik.resetForm();
                    onHideTier();
                }
                setloading(false);
                setloadingIcon('pi pi-save');
            } else {
                const res = await dispatch(handlePostRequest(newData, `/zconfig/v1/zmiles/saveTier`, true, false));
                if (res?.responseCode === '330000') {
                    formik.resetForm();
                    onHideTier();
                }
                setloading(false);
                setloadingIcon('pi pi-save');
            }
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="tier" className={classNames({ 'p-error': isFormFieldValid('tier') }, 'Label__Text')}>
                                    Tier<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={25}
                                    id="tier"
                                    placeholder="Enter Tier"
                                    name="tier"
                                    value={formik?.values?.tier || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('tier') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('tier')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="tierRange" className={classNames({ 'p-error': isFormFieldValid('tierRange') }, 'Label__Text')}>
                                    Max Tier Range<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={9}
                                    id="tierRange"
                                    placeholder="Enter Max Tier Range"
                                    name="tierRange"
                                    value={formik?.values?.tierRange || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('tierRange') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('tierRange')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="multiplier" className={classNames({ 'p-error': isFormFieldValid('multiplier') }, 'Label__Text')}>
                                    Multiplier
                                </label>
                                <InputText
                                    maxLength={9}
                                    id="multiplier"
                                    placeholder="Enter Multiplier"
                                    name="multiplier"
                                    value={formik?.values?.multiplier || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('multiplier') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('multiplier')}
                            </div>
                        </div>
                        {/* <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="isActive" className={classNames({ "p-error": isFormFieldValid("isActive") }, "Label__Text")}>
                                    Is Active
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isActive" name="isActive" value={formik.values.isActive} checked={formik.values.isActive } onChange={formik.handleChange} />
                                </div>
                                {getFormErrorMessage("isActive")}
                            </div>
                        </div> */}
                    </div>

                    <div className="Down__Btn">
                        <Button type="submit" label={editable ? 'Update Tier' : 'Add Tier'} className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
};

export default TierDialog;

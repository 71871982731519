import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DefaultComponent from './DefaultComponent';
import { handleGetRequest } from '../../../../services/GetTemplate';

function MakerApis({ type }) {
    const location = useLocation();
    const [cxStatusConfigId] = useState(location?.state || '');
    const [getByIdData, setGetByIdData] = useState([]);
    const [getByRoleId, setGetByRoleId] = useState([]);
    const [roleDropdown, setRoleDropdown] = useState([]);
    const [statusDropdown, setStatusDropdown] = useState([]);

    const funcRoleDropdown = async () => {
        const resp = await handleGetRequest(`/account/v1/customeraccounts/getAllCxRoles`);
        setRoleDropdown(resp?.payLoad);
    };

    const funcStatusDropdown = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getAccountStatus/LKP_ACCOUNT_STATUS:*`);
        setStatusDropdown(resp?.payLoad);
    };

    // edit and view page
    const funcGetByIdData = async () => {
        const resp = await handleGetRequest(`/account/v1/customeraccounts/getCxStatusConfigDetailIdById/${cxStatusConfigId}`);
        setGetByRoleId(resp?.payLoad);
    };

    useEffect(() => {
        funcRoleDropdown();
        funcStatusDropdown();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setGetByIdData({ getByRoleId: getByRoleId[0], roleDropdown: roleDropdown, statusDropdown: statusDropdown });
    }, [getByRoleId, roleDropdown, statusDropdown]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (location?.state) {
            funcGetByIdData();
        }
    }, [location?.state]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <DefaultComponent type={type} getByIdData={getByIdData} />
        </React.Fragment>
    );
}
export default MakerApis;

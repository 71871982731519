import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import classNames from 'classnames';
import * as Yup from 'yup';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewkycAttribute({ onHideViewAttributeDialog, viewKycAttributeRowData, kycAttributeViewRowData }) {
    const [loading, setloading] = useState(false);
    const [validatorLov, setValidatorLov] = useState([]);

    const kycAttributeId = parseInt(kycAttributeViewRowData);
    const tableName = 'TBL_KYC_ATTRIBUTES';

    const getKycAttributeData = async () => {
        setloading(true);

        const res = await handleGetRequest(`/account/v1/kycattribute/getKycAttributeById/${kycAttributeId}`);
        const keyData = res?.payLoad;

        setloading(false);

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            formik.setFieldValue('isUnique', keyData?.isUnique === 'Y' ? true : false);
            formik.setFieldValue('isSearchable', keyData?.isSearchable === 'Y' ? true : false);
            formik.setFieldValue('isEditable', keyData?.isEditable === 'Y' ? true : false);
            formik.setFieldValue('validatorId', keyData?.tblValidator?.validatorId.toString());
        }
    };

    const getValidatorLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getvalidator/TBL_VALIDATOR:*');
        setValidatorLov(resp?.payLoad);
    };

    useEffect(() => {
        getValidatorLov();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (viewKycAttributeRowData !== undefined || viewKycAttributeRowData !== null) {
            getKycAttributeData();
        }
    }, [viewKycAttributeRowData]); // eslint-disable-line

    const handleCancel = (e) => {
        e.preventDefault();
        onHideViewAttributeDialog();
    };

    const validationSchema = Yup.object().shape({
        attributeName: Yup.string().required('This Field is required.'),
        validator: Yup.string().required('This Field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            attributeName: '',
            attributeCode: '',
            validatorId: '',
            isEditable: '',
            isSearchable: '',
            isUnique: ''
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="attributeName" className={classNames({ 'p-error': isFormFieldValid('attributeName') }, 'Label__Text')}>
                                    Attribute Name
                                </label>
                                <InputText
                                    id="attributeName"
                                    placeholder="Enter Attribute Name"
                                    name="attributeName"
                                    disabled={true}
                                    value={formik?.values?.attributeName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('attributeName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('attributeName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="validatorId" className={classNames({ 'p-error': isFormFieldValid('validatorId') }, 'Label__Text')}>
                                    Validator 
                                </label>
                                <Dropdown
                                    id="validatorId"
                                    placeholder="Select a value"
                                    options={validatorLov}
                                    optionLabel="name"
                                    name="validatorId"
                                    optionValue="lovId"
                                    disabled={true}
                                    value={formik.values.validatorId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('validatorId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('validatorId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="attributeCode" className={classNames({ 'p-error': isFormFieldValid('attributeCode') }, 'Label__Text')}>
                                    Attribute Code
                                </label>
                                <InputText
                                    id="attributeCode"
                                    placeholder="Enter Attribute Code"
                                    name="attributeCode"
                                    disabled={true}
                                    value={formik?.values?.attributeCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('attributeCode') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('attributeCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="isSearchable" className={classNames({ 'p-error': isFormFieldValid('isSearchable') }, 'Label__Text')}>
                                    Searchable
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isSearchable" name="isSearchable" value={formik.values.isSearchable} checked={formik.values.isSearchable} onChange={formik.handleChange} disabled />
                                </div>
                                {getFormErrorMessage('isSearchable')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="isEditable" className={classNames({ 'p-error': isFormFieldValid('isEditable') }, 'Label__Text')}>
                                    Editable
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isEditable" name="isEditable" value={formik.values.isEditable} checked={formik.values.isEditable} onChange={formik.handleChange} disabled />
                                </div>
                                {getFormErrorMessage('isEditable')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="isUnique" className={classNames({ 'p-error': isFormFieldValid('isUnique') }, 'Label__Text')}>
                                    Unique
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isUnique" name="isUnique" value={formik.values.isUnique} checked={formik.values.isUnique} onChange={formik.handleChange} disabled />
                                </div>
                                {getFormErrorMessage('isUnique')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" disabled={loading} label={'Okay'} className="Btn__Add" onClick={(e) => handleCancel(e)} />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={kycAttributeId} tableName={tableName} />
        </>
    );
}

export default ViewkycAttribute;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';
import { handleGetRequest } from '../../services/GetTemplate';
import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from 'primereact/multiselect';
import { handlePostRequest } from '../../services/PostTemplate';
import * as Yup from 'yup';

function RiskDashboard() {
    const [customerTypeOptions, setCustomerTypeOptions] = useState([]);
    const [dsAgentOptions, setDsAgentOptions] = useState([]);
    const [listOfChannel, setListOfChannel] = useState([]);
    const [listOfStatus, setListOfStatus] = useState([]);
    const [bankName, setBankName] = useState([]);
    const [companyName, setCompanyName] = useState([]);
    const [accountLevelOptions, setAccountLevelOptions] = useState([]);
    const [cityLocation, setCityLocation] = useState([]);
    const [lookUps, setlookUps] = useState([]);
    const [listOfProducts, setListOfProducts] = useState([]);
    const [previousData, setPreviousData] = useState(null);

    const dispatch = useDispatch();

    const getCustomerType = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getAccountClassification/LKP_ACCOUNT_CLASSIFICATION:*');
        setCustomerTypeOptions(res?.payLoad);
    };
    const getAccountLevel = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getaccounttypes/LKP_ACCOUNT_LEVEL:*');
        setAccountLevelOptions(res?.payLoad);
    };
    const getCityLocation = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcitieslov/LKP_CITY:*');
        setCityLocation(res?.payLoad);
    };
    const dropdowns = async () => {
        const res = await handleGetRequest('/account/v1/riskdashboard/view');
        setlookUps(res?.payLoad);
    };
    //list of transactions API call
    const getTransactionListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setListOfProducts(res?.payLoad);
    };

    const getstatusData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getAccountStatus/LKP_ACCOUNT_STATUS:*');
        setListOfStatus(res?.payLoad);
    };
    const getChannelData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchannels/LKP_CHANNEL:*');
        setListOfChannel(res?.payLoad);
    };
    const getBankName = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getTblCMSBank/TBL_CMS_BANK:*');
        setBankName(res?.payLoad);
    };
    const getCompanyName = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getTblCMSCompany/TBL_CMS_COMPANY:*');
        setCompanyName(res?.payLoad);
    };

    const getPreviousData = async () => {
        const res = await handleGetRequest('/account/v1/riskdashboard/get');
            setPreviousData(res?.payLoad || []);
    };

    useEffect(() => {
        if (previousData?.fromDate !== null) {
            mapValues();
        }
    }, [previousData]);

    const mapValues = () => {
        let accountMultiselect = previousData?.rBSAccountStatus.length > 0 ? previousData?.rBSAccountStatus?.map((item) => item?.name?.toString()) : [];
        let channelMultiselect = previousData?.rBSAccountStatusRevChannel.length > 0 ? previousData?.rBSAccountStatusRevChannel?.map((item) => item?.name?.toString()) : [];
        let agentMultiselect = previousData?.rBSAccountStatusRevAgent.length > 0 ? previousData?.rBSAccountStatusRevAgent?.map((item) => item?.name) : [];

        let agentVar = previousData?.dSAgent?.[0]?.name;
        let locationVar = previousData?.dSLocation?.[0]?.name;
        let manufacturerVar = previousData?.dSDevicemanufacture?.[0]?.name;

        formik.setFieldValue('customerTypeId', previousData?.customerTypeId?.id != null ? previousData?.customerTypeId?.id.toString() : '');
        formik.setFieldValue('age', previousData?.age != null ? previousData?.age : '');
        formik.setFieldValue('gender', previousData?.gender != null ? previousData?.gender : '');
        formik.setFieldValue('fromDate', previousData != null ? previousData?.fromDate : '');
        formik.setFieldValue('toDate', previousData?.toDate != null ? previousData?.toDate : '');
        formik.setFieldValue('accountLevelId', previousData?.accountLevelId?.id != null ? previousData?.accountLevelId?.id?.toString() : '');
        formik.setFieldValue('accountStatusId', previousData?.accountStatusId?.id != null ? previousData?.accountStatusId?.id?.toString() : '');

        //registration section values
        formik.setFieldValue('registrationSection', previousData?.regChannelId != null ? true : false);
        formik.setFieldValue('regChannelId', previousData?.regChannelId != null ? previousData?.regChannelId?.id?.toString() : '');
        formik.setFieldValue('registrationCityLocation', previousData?.regCityLocationId != null ? previousData?.regCityLocationId?.id?.toString() : '');
        formik.setFieldValue('oSType', previousData?.oSType != null ? previousData?.oSType : '');

        //retailer and customer transaction section
        formik.setFieldValue('rCtSection', previousData?.transIBFTBankId != null ? true : false);
        formik.setFieldValue('transIBFTBankId', previousData?.transIBFTBankId != null ? previousData?.transIBFTBankId?.id?.toString() : '');
        formik.setFieldValue('transIBFTAmount', previousData?.transIBFTAmount != null ? previousData?.transIBFTAmount : '');
        formik.setFieldValue('transP2PAmount', previousData?.transP2PAmount != null ? previousData?.transP2PAmount : '');
        formik.setFieldValue('transBalanceBrackets', previousData?.transBalanceBrackets != null ? previousData?.transBalanceBrackets : '');
        formik.setFieldValue('cashInOutCheckbox', previousData?.transCashInOutAmount != null ? true : false);
        formik.setFieldValue('eLoadAmountCheckbox', previousData?.transELoadAmount != null ? true : false);
        formik.setFieldValue('eLoadTelcoCheckbox', previousData?.transELoadCompany != null ? true : false);
        formik.setFieldValue('transCashInOutAmount', previousData?.transCashInOutAmount != null ? previousData?.transCashInOutAmount : '');
        formik.setFieldValue('transELoadAmount', previousData?.transELoadAmount != null ? previousData?.transELoadAmount : '');
        formik.setFieldValue('transELoadCompany', previousData?.transELoadCompany != null ? previousData?.transELoadCompany : '');
        formik.setFieldValue('transRCProductId', previousData?.transRCProductId != null ? previousData?.transRCProductId?.id?.toString() : '');
        formik.setFieldValue('transRCWHTax', previousData?.transRCWHTax != null ? previousData?.transRCWHTax : '');
        formik.setFieldValue('transRCAmount', previousData?.transRCAmount != null ? previousData?.transRCAmount : '');
        formik.setFieldValue('transUBPCompanyId', previousData?.transUBPCompany != null ? previousData?.transUBPCompany : '');
        formik.setFieldValue('transUBPAmount', previousData?.transUBPAmount != null ? previousData?.transUBPAmount : '');

        //risk and business section
        formik.setFieldValue('riskandBusinessSection', previousData?.rbSection === 'Y' ? true : false);
        formik.setFieldValue('rBSOTMTohfa', previousData?.rBSOTMTohfa === 'Y' ? true : false);
        formik.setFieldValue('rBSOTMIBFT', previousData?.rBSOTMIBFT === 'Y' ? true : false);
        formik.setFieldValue('rBSOTMP2P', previousData?.rBSOTMP2P === 'Y' ? true : false);
        formik.setFieldValue('rBSMTOTohfa', previousData?.rBSMTOTohfa === 'Y' ? true : false);
        formik.setFieldValue('rBSMTOIBFT', previousData?.rBSMTOIBFT === 'Y' ? true : false);
        formik.setFieldValue('rBSMTOP2P', previousData?.rBSMTOP2P === 'Y' ? true : false);
        formik.setFieldValue('ubpCountCheckbox', previousData?.rBSUBPCount != null ? true : false);
        formik.setFieldValue('rBSUBPCount', previousData?.rBSUBPCount != null ? previousData?.rBSUBPCount : '');
        formik.setFieldValue('ubpAmountCheckbox', previousData?.rBSUBPAmount != null ? true : false);
        formik.setFieldValue('rBSUBPAmount', previousData?.rBSUBPAmount != null ? previousData?.rBSUBPAmount : '');
        formik.setFieldValue('exhaustedAmountCheckbox', previousData?.rBSLimitBreachAmount != null ? true : false);
        formik.setFieldValue('rBSLimitBreachAmount', previousData?.rBSLimitBreachAmount != null ? previousData?.rBSLimitBreachAmount : '');
        formik.setFieldValue('blockedAccountCheckbox', accountMultiselect?.length > 0 ? true : false);
        formik.setFieldValue('rBSAccountStatus', accountMultiselect ? accountMultiselect : []);
        formik.setFieldValue('blockedChannelCheckbox', channelMultiselect?.length > 0 ? true : false);
        formik.setFieldValue('rBSAccountStatusRevChannel', channelMultiselect ? channelMultiselect : []);
        formik.setFieldValue('blockedAgentCheckbox', agentMultiselect?.length > 0 ? true : false);
        formik.setFieldValue('rBSAccountStatusRevAgent', agentMultiselect ? agentMultiselect : []);

        //device section
        formik.setFieldValue('deviceSection', agentVar != null ? true : false);
        formik.setFieldValue('deviceAgent', agentVar != null ? agentVar : '');
        formik.setFieldValue('deviceLocation', locationVar != null ? locationVar : '');
        formik.setFieldValue('devicemanufacture', manufacturerVar != null ? manufacturerVar : '');
    };

    useEffect(() => {
        let arrayofwords = [];
        customerTypeOptions?.map((item) => {
            if (item.code === 'FRN' || item.code === 'RTL' || item.code === 'HND' || item.code === 'CPT') {
                arrayofwords.push(item);
            }
        });
        setDsAgentOptions(arrayofwords);
    }, [customerTypeOptions]);

    useEffect(() => {
        getCustomerType();
        getAccountLevel();
        getCityLocation();
        dropdowns();
        getTransactionListData();
        getstatusData();
        getChannelData();
        getBankName();
        getCompanyName();
        getPreviousData();
    }, []);

    //YUP validation Schema
    const validationSchema = Yup.object().shape({
        fromDate: Yup.date().required('From date is required'),
        toDate: Yup.date().required('Date To is required'),
        age: Yup.string().matches(/^[0-9]+$/, 'Please enter only digits'),

        //registration section validations
        registrationSection: Yup.boolean(),

        regChannelId: Yup.string().when('registrationSection', {
            is: true,
            then: Yup.string().required('Registration Channel is required')
        }),

        registrationCityLocation: Yup.string().when('registrationSection', {
            is: true,
            then: Yup.string().required('Registration City Location is required')
        }),

        oSType: Yup.string().when('registrationSection', {
            is: true,
            then: Yup.string().required('OS Type is required')
        }),

        //retailer commission transaction section validations
        rCtSection: Yup.boolean(),
        transIBFTBankId: Yup.string().when('rCtSection', {
            is: true,
            then: Yup.string().required('Bank name is required')
        }),

        transIBFTAmount: Yup.number().when('rCtSection', {
            is: true,
            then: Yup.number().nullable().required('Amount is required')
        }),

        transP2PAmount: Yup.string().when('rCtSection', {
            is: true,
            then: Yup.string().nullable().required('Amount is required')
        }),
        transBalanceBrackets: Yup.string().when('rCtSection', {
            is: true,
            then: Yup.string().nullable().required('Balance Brackets is required')
        }),

        transRCProductId: Yup.string().when('rCtSection', {
            is: true,
            then: Yup.string().required('Product is required')
        }),
        transCashInOutAmount: Yup.string().when('cashInOutCheckbox', {
            is: true,
            then: Yup.string().nullable().required('Amount is required')
        }),
        transELoadAmount: Yup.string().when('eLoadAmountCheckbox', {
            is: true,
            then: Yup.string().nullable().required('Amount is required')
        }),
        transELoadCompany: Yup.string()
            .nullable()
            .when('eLoadTelcoCheckbox', {
                is: true,
                then: Yup.string().required('Telco is required')
            }),

        transRCWHTax: Yup.string()
            .nullable()
            .when('rCtSection', {
                is: true,
                then: Yup.string().required('WHT is required')
            }),
        transRCAmount: Yup.string()
            .nullable()
            .when('rCtSection', {
                is: true,
                then: Yup.string().required('Amount is required')
            }),

        transUBPCompanyId: Yup.string()
            .nullable()
            .when('rCtSection', {
                is: true,
                then: Yup.string().required('Disco is required')
            }),

        transUBPAmount: Yup.string()
            .nullable()
            .when('rCtSection', {
                is: true,
                then: Yup.string().required('Amount is required')
            }),

        //risk and business section validations
        rBSUBPCount: Yup.string().when('ubpCountCheckbox', {
            is: true,
            then: Yup.string().nullable().required('Amount is required')
        }),

        rBSUBPAmount: Yup.string().when('ubpAmountCheckbox', {
            is: true,
            then: Yup.string().required('Total amount is required')
        }),
        rBSLimitBreachAmount: Yup.string().when('exhaustedAmountCheckbox', {
            is: true,
            then: Yup.string().required('Amount is required')
        }),

        rBSAccountStatus: Yup.array().when('blockedAccountCheckbox', {
            is: true,
            then: () => Yup.array().min(1, 'At least one account is required').of(Yup.string().required('Account is required'))
        }),

        rBSAccountStatusRevChannel: Yup.array().when('blockedChannelCheckbox', {
            is: true,
            then: () => Yup.array().min(1, 'At least one channel is required').of(Yup.string().required('Account is required'))
        }),

        rBSAccountStatusRevAgent: Yup.array().when('blockedAgentCheckbox', {
            is: true,
            then: () => Yup.array().min(1, 'At least one Agent is required').of(Yup.string().required('Account is required'))
        }),

        //device section validations
        deviceSection: Yup.boolean(),

        deviceAgent: Yup.string().when('deviceSection', {
            is: true,
            then: Yup.string().required('Agent is required')
        }),

        deviceLocation: Yup.string().when('deviceSection', {
            is: true,
            then: Yup.string().required('Device Location is required')
        }),

        devicemanufacture: Yup.string().when('deviceSection', {
            is: true,
            then: Yup.string().required('Device Manufacturer is required')
        })
    });

    const formik = useFormik({
        validationSchema,
        initialValues: {
            customerTypeId: '',
            age: '',
            gender: '',
            fromDate: '',
            toDate: '',
            accountLevelId: '',
            accountStatusId: '',
            registrationSection: false,
            regChannelId: '',
            registrationCityLocation: '',
            oSType: '',
            rCtSection: false,
            transIBFTBankId: '',
            transIBFTAmount: '',
            transP2PAmount: '',
            transBalanceBrackets: '',
            cashInOutCheckbox: false,
            eLoadAmountCheckbox: false,
            eLoadTelcoCheckbox: false,
            transCashInOutAmount: '',
            transELoadAmount: '',
            transELoadCompany: '',
            transRCProductId: '',
            transRCWHTax: '',
            transRCAmount: '',
            transUBPCompanyId: '',
            transUBPAmount: '',
            riskandBusinessSection: false,
            rBSOTMTohfa: false,
            rBSOTMIBFT: false,
            rBSOTMP2P: false,
            rBSMTOTohfa: false,
            rBSMTOIBFT: false,
            rBSMTOP2P: false,
            ubpCountCheckbox: false,
            rBSUBPCount: '',
            ubpAmountCheckbox: false,
            rBSUBPAmount: '',
            exhaustedAmountCheckbox: false,
            blockedAccountCheckbox: false,
            blockedChannelCheckbox: false,
            blockedAgentCheckbox: false,
            deviceSection: false,
            rBSLimitBreachAmount: '',
            rBSAccountStatus: [],
            rBSAccountStatusRevChannel: [],
            rBSAccountStatusRevAgent: [],
            deviceAgent: '',
            deviceLocation: '',
            devicemanufacture: ''
        },

        onSubmit: async (data) => {
            const selectedDeviceAgentOption = dsAgentOptions.find((option) => option.lovId === data?.deviceAgent);
            const selectedDeviceLocationOption = cityLocation.find((option) => option.lovId === data?.deviceLocation);
            const addPayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        transCashInOutAmountCheck: data?.cashInOutCheckbox,
                        transELoadAmountCheck: data?.eLoadAmountCheckbox,
                        transELoadCompanyCheck: data?.eLoadTelcoCheckbox,
                        rBSUBPCountCheck: data?.ubpCountCheckbox,
                        rBSUBPAmountCheck: data?.ubpAmountCheckbox,
                        rBSLimitBreachAmountCheck: data?.exhaustedAmountCheckbox,
                        rBSAccountStatusCheck: data?.blockedAccountCheckbox,
                        rBSAccountStatusRevChannelCheck: data?.blockedChannelCheckbox,
                        rBSAccountStatusRevAgentCheck: data?.blockedAgentCheckbox,
                        registerationSection: data?.registrationSection,
                        rCtSection: data?.rCtSection,
                        rbSection: data?.riskandBusinessSection,
                        deviceSection: data?.deviceSection,
                        customerTypeId: data?.customerTypeId,
                        gender: data?.gender,
                        age: data?.age,
                        fromDate: data?.fromDate,
                        toDate: data?.toDate,
                        accountLevelId: data?.accountLevelId,
                        accountStatusId: data?.accountStatusId,
                        regChannelId: data?.regChannelId,
                        regCityLocationId: data?.registrationCityLocation,
                        oSType: data?.oSType,
                        transIBFTBankId: data?.transIBFTBankId,
                        transIBFTAmount: data?.transIBFTAmount,
                        transP2PAmount: data?.transP2PAmount,
                        transBalanceBrackets: data?.transBalanceBrackets,
                        transCashInOutAmount: data?.transCashInOutAmount,
                        transELoadAmount: data?.transELoadAmount,
                        transELoadCompany: data?.transELoadCompany,
                        transRCProductId: data?.transRCProductId,
                        transRCWHTax: data?.transRCWHTax,
                        transRCAmount: data?.transRCAmount,
                        transUBPCompany: data?.transUBPCompanyId,
                        transUBPAmount: data?.transUBPAmount,
                        rBSOTMTohfa: data?.rBSOTMTohfa,
                        rBSOTMIBFT: data?.rBSOTMIBFT,
                        rBSOTMP2P: data?.rBSOTMP2P,
                        rBSMTOTohfa: data?.rBSMTOTohfa,
                        rBSMTOIBFT: data?.rBSMTOIBFT,
                        rBSMTOP2P: data?.rBSMTOP2P,
                        rBSUBPCount: data?.rBSUBPCount,
                        rBSUBPAmount: data?.rBSUBPAmount,
                        rBSLimitBreachAmount: data?.rBSLimitBreachAmount,
                        rBSAccountStatus: data?.rBSAccountStatus?.toString(),
                        rBSAccountStatusRevChannel: data?.rBSAccountStatusRevChannel?.toString(),
                        rBSAccountStatusRevAgent: data?.rBSAccountStatusRevAgent?.toString(),
                        dsAgent: [
                            {
                                id: selectedDeviceAgentOption?.lovId || '',
                                name: selectedDeviceAgentOption?.name || ''
                            }
                        ],
                        dsLocation: [
                            {
                                id: selectedDeviceLocationOption?.lovId || '',
                                name: selectedDeviceLocationOption?.name || ''
                            }
                        ],
                        dsDevicemanufacture: [
                            {
                                name: data?.devicemanufacture
                            }
                        ]
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const updatePayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        transCashInOutAmountCheck: data?.rCtSection && data?.cashInOutCheckbox,
                        transELoadAmountCheck: data?.rCtSection && data?.eLoadAmountCheckbox,
                        transELoadCompanyCheck: data?.rCtSection && data?.eLoadTelcoCheckbox,
                        rBSUBPCountCheck: data?.ubpCountCheckbox && data?.riskandBusinessSection,
                        rBSUBPAmountCheck: data?.ubpAmountCheckbox && data?.riskandBusinessSection,
                        rBSLimitBreachAmountCheck: data?.exhaustedAmountCheckbox && data?.riskandBusinessSection,
                        rBSAccountStatusCheck: data?.riskandBusinessSection && data?.blockedAccountCheckbox,
                        rBSAccountStatusRevChannelCheck: data?.riskandBusinessSection && data?.blockedChannelCheckbox,
                        rBSAccountStatusRevAgentCheck: data?.riskandBusinessSection && data?.blockedAgentCheckbox,
                        registerationSection: data?.registrationSection,
                        rCtSection: data?.rCtSection,
                        rbSection: data?.riskandBusinessSection,
                        deviceSection: data?.deviceSection,
                        customerTypeId: data?.customerTypeId,
                        gender: data?.gender,
                        age: data?.age,
                        fromDate: data?.fromDate,
                        toDate: data?.toDate,
                        accountLevelId: data?.accountLevelId,
                        accountStatusId: data?.accountStatusId,
                        regChannelId: data?.registrationSection ? data?.regChannelId : '',
                        regCityLocationId: data?.registrationSection ? data?.registrationCityLocation : '',
                        oSType: data?.registrationSection ? data?.oSType : '',
                        transIBFTBankId: data?.rCtSection ? data?.transIBFTBankId : '',
                        transIBFTAmount: data?.rCtSection ? data?.transIBFTAmount : '',
                        transP2PAmount: data?.rCtSection ? data?.transP2PAmount : '',
                        transBalanceBrackets: data?.rCtSection ? data?.transBalanceBrackets : '',
                        transCashInOutAmount: data?.rCtSection && data?.cashInOutCheckbox ? data?.transCashInOutAmount : '',
                        transELoadAmount: data?.rCtSection && data?.eLoadAmountCheckbox ? data?.transELoadAmount : '',
                        transELoadCompany: data?.rCtSection && data?.eLoadTelcoCheckbox ? data?.transELoadCompany : '',
                        transRCProductId: data?.rCtSection ? data?.transRCProductId : '',
                        transRCWHTax: data?.rCtSection ? data?.transRCWHTax : '',
                        transRCAmount: data?.rCtSection ? data?.transRCAmount : '',
                        transUBPCompany: data?.rCtSection ? data?.transUBPCompanyId : '',
                        transUBPAmount: data?.rCtSection ? data?.transUBPAmount : '',

                        rBSOTMTohfa: data?.rBSOTMTohfa && data?.riskandBusinessSection,
                        rBSOTMIBFT: data?.rBSOTMIBFT && data?.riskandBusinessSection,
                        rBSOTMP2P: data?.rBSOTMP2P && data?.riskandBusinessSection,
                        rBSMTOTohfa: data?.rBSMTOTohfa && data?.riskandBusinessSection,
                        rBSMTOIBFT: data?.rBSMTOIBFT && data?.riskandBusinessSection,
                        rBSMTOP2P: data?.rBSMTOP2P && data?.riskandBusinessSection,

                        rBSUBPCount: data?.ubpCountCheckbox && data?.riskandBusinessSection ? data?.rBSUBPCount : '',

                        rBSUBPAmount: data?.ubpAmountCheckbox && data?.riskandBusinessSection ? data?.rBSUBPAmount.toString() : '',

                        rBSLimitBreachAmount: data?.exhaustedAmountCheckbox && data?.riskandBusinessSection ? data?.rBSLimitBreachAmount : '',

                        rbsAccountStatus: data?.blockedAccountCheckbox && data?.riskandBusinessSection ? (data?.blockedAccountCheckbox ? data?.rBSAccountStatus : []) : [],
                        rbsAccountStatusRevChannel: data?.blockedChannelCheckbox && data?.riskandBusinessSection ? (data?.blockedChannelCheckbox ? data?.rBSAccountStatusRevChannel : []) : [],
                        rbsAccountStatusRevAgent: data?.blockedAgentCheckbox && data?.riskandBusinessSection ? (data?.blockedAgentCheckbox ? data?.rBSAccountStatusRevAgent : []) : [],
                        dsAgent: data?.deviceSection ? data?.deviceAgent : '',
                        dsLocation: data?.deviceSection ? data?.deviceLocation : '',
                        dsDevicemanufacture: data?.deviceSection ? data?.devicemanufacture : ''
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            if (previousData?.fromDate != null) {
                await dispatch(handlePostRequest(updatePayload, '/account/v1/riskdashboard/update', true, false));
            } else {
                await dispatch(handlePostRequest(addPayload, '/account/v1/riskdashboard/save', true, false));
            }
        }
    });

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const resetHandle = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    return (
        <>
            <div className="card Card__Round riskDashboardPage">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="customerTypeId" className={classNames({ 'p-error': isFormFieldValid('customerTypeId') }, 'Label__Text')}>
                                    Customer Type
                                </label>
                                <Dropdown
                                    id="customerTypeId"
                                    placeholder="Select Customer Type"
                                    options={customerTypeOptions}
                                    optionLabel="name"
                                    name="customerTypeId"
                                    optionValue="lovId"
                                    value={formik.values.customerTypeId}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round"
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="age" className={classNames({ 'p-error': isFormFieldValid('age') }, 'Label__Text')}>
                                    Age
                                </label>
                                <InputText
                                    maxLength={3}
                                    id="age"
                                    name="age"
                                    placeholder="Enter Age"
                                    value={formik?.values?.age || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('age') }, 'Input__Round')}
                                    aria-required
                                />
                                {getFormErrorMessage('age')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="gender" className={classNames({ 'p-error': isFormFieldValid('gender') }, 'Label__Text')}>
                                    Gender
                                </label>
                                <Dropdown id="gender" placeholder="Select Gender" options={lookUps?.gender} name="gender" value={formik.values.gender} onChange={formik.handleChange} className="Dropdown__Round" />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="fromDate" className={classNames({ 'p-error': isFormFieldValid('fromDate') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText
                                    max={previousDate}
                                    id="fromDate"
                                    placeholder=""
                                    name="fromDate"
                                    type="date"
                                    value={formik?.values?.fromDate?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('fromDate') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('fromDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="toDate" className={classNames({ 'p-error': isFormFieldValid('toDate') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText
                                    max={previousDate}
                                    id="toDate"
                                    placeholder=""
                                    name="toDate"
                                    type="date"
                                    value={formik?.values?.toDate?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('toDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('toDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountLevelId" className={classNames({ 'p-error': isFormFieldValid('accountLevelId') }, 'Label__Text')}>
                                    Account Level
                                </label>
                                <Dropdown
                                    id="accountLevelId"
                                    placeholder="Select Account Level"
                                    options={accountLevelOptions}
                                    optionLabel="name"
                                    name="accountLevelId"
                                    optionValue="lovId"
                                    value={formik.values.accountLevelId}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round"
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountStatusId" className={classNames({ 'p-error': isFormFieldValid('accountStatusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="accountStatusId"
                                    placeholder="Select Status"
                                    options={listOfStatus}
                                    optionLabel="accountStatusName"
                                    name="accountStatusId"
                                    optionValue="accountStatusId"
                                    value={formik.values.accountStatusId || ''}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">
                            REGISTRATION SECTION
                            <span>
                                <Checkbox id="registrationSection" name="registrationSection" checked={formik?.values?.registrationSection} onChange={formik.handleChange} />
                            </span>
                        </div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="regChannelId" className={classNames({ 'p-error': isFormFieldValid('regChannelId') }, 'Label__Text')}>
                                    Registration Channel
                                </label>
                                <Dropdown
                                    id="regChannelId"
                                    placeholder="Select Registration Channel"
                                    options={listOfChannel}
                                    optionLabel="name"
                                    name="regChannelId"
                                    optionValue="lovId"
                                    value={formik.values.regChannelId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regChannelId') }, 'Dropdown__Round')}
                                    disabled={!formik.values.registrationSection}
                                />

                                {getFormErrorMessage('regChannelId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="registrationCityLocation" className={classNames({ 'p-error': isFormFieldValid('registrationCityLocation') }, 'Label__Text')}>
                                    Registration City Location
                                </label>
                                <Dropdown
                                    id="registrationCityLocation"
                                    placeholder="Select Registration City"
                                    options={cityLocation}
                                    optionLabel="name"
                                    name="registrationCityLocation"
                                    optionValue="lovId"
                                    value={formik.values.registrationCityLocation}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('registrationCityLocation') }, 'Dropdown__Round')}
                                    disabled={!formik.values.registrationSection}
                                />

                                {getFormErrorMessage('registrationCityLocation')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="oSType" className={classNames({ 'p-error': isFormFieldValid('oSType') }, 'Label__Text')}>
                                    OS Type
                                </label>
                                <Dropdown
                                    id="oSType"
                                    placeholder="Select OS Type"
                                    options={lookUps?.oSType}
                                    name="oSType"
                                    value={formik.values.oSType}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('oSType') }, 'Dropdown__Round')}
                                    disabled={!formik.values.registrationSection}
                                />

                                {getFormErrorMessage('oSType')}
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">
                            RETAILER AND CUSTOMER TRANSACTION SECTION
                            <span>
                                <Checkbox id="rCtSection" name="rCtSection" checked={formik?.values?.rCtSection} onChange={formik.handleChange} />
                            </span>
                        </div>
                    </div>
                    <div className="Form__Header mt-5">
                        <h6>IBFT IN/OUT</h6>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transIBFTBankId" className={classNames({ 'p-error': isFormFieldValid('transIBFTBankId') }, 'Label__Text')}>
                                    Bank Name
                                </label>
                                <Dropdown
                                    id="transIBFTBankId"
                                    placeholder="Select Bank Name"
                                    options={bankName}
                                    optionLabel="name"
                                    name="transIBFTBankId"
                                    optionValue="lovId"
                                    value={formik.values.transIBFTBankId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transIBFTBankId') }, 'Dropdown__Round')}
                                    disabled={!formik.values.rCtSection}
                                />

                                {getFormErrorMessage('transIBFTBankId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transIBFTAmount" className={classNames({ 'p-error': isFormFieldValid('transIBFTAmount') }, 'Label__Text')}>
                                    Amount
                                </label>
                                <InputText
                                    id="transIBFTAmount"
                                    name="transIBFTAmount"
                                    placeholder="Enter Amount"
                                    type="text"
                                    keyfilter="int"
                                    value={formik?.values?.transIBFTAmount || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transIBFTAmount') }, 'Input__Round')}
                                    disabled={!formik.values.rCtSection}
                                    aria-required
                                />
                                {getFormErrorMessage('transIBFTAmount')}
                            </div>
                        </div>
                    </div>

                    <div className="Form__Header mt-5">
                        <h6>P2P</h6>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transP2PAmount" className={classNames({ 'p-error': isFormFieldValid('transP2PAmount') }, 'Label__Text')}>
                                    Amount
                                </label>
                                <InputText
                                    id="transP2PAmount"
                                    name="transP2PAmount"
                                    placeholder="Enter Amount"
                                    value={formik?.values?.transP2PAmount || ''}
                                    keyfilter="int"
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transP2PAmount') }, 'Input__Round')}
                                    disabled={!formik.values.rCtSection}
                                    aria-required
                                />
                                {getFormErrorMessage('transP2PAmount')}
                            </div>
                        </div>
                    </div>

                    <div className="Form__Header mt-5">
                        <h6>BALANCE BRACKETS</h6>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transBalanceBrackets" className={classNames({ 'p-error': isFormFieldValid('transBalanceBrackets') }, 'Label__Text')}>
                                    Select Balance Brackets
                                </label>
                                <Dropdown
                                    id="transBalanceBrackets"
                                    placeholder="Select Balance Brackets"
                                    options={lookUps?.balanceBrackets}
                                    name="transBalanceBrackets"
                                    value={formik.values.transBalanceBrackets}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transBalanceBrackets') }, 'Dropdown__Round')}
                                    disabled={!formik.values.rCtSection}
                                />

                                {getFormErrorMessage('transBalanceBrackets')}
                            </div>
                        </div>
                    </div>

                    <div className="Form__Header mt-5">
                        <h6>CASH IN/OUT</h6>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transCashInOutAmount" className={classNames('Label__Text ', { 'p-error': isFormFieldValid('transCashInOutAmount') })}>
                                    Amount
                                </label>
                                <div className="p-fluid p-grid grid CheckboxAndInput">
                                    <div className="col-12 md:col-1 mt-1">
                                        <Checkbox id="cashInOutCheckbox" name="cashInOutCheckbox" checked={formik?.values?.cashInOutCheckbox} onChange={formik.handleChange} disabled={!formik.values.rCtSection} />
                                    </div>
                                    <div className="col-12 md:col-11">
                                        <InputText
                                            id="transCashInOutAmount"
                                            name="transCashInOutAmount"
                                            placeholder="Enter Amount"
                                            keyfilter="int"
                                            value={formik?.values?.transCashInOutAmount || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('transCashInOutAmount') }, 'Input__Round')}
                                            disabled={!formik.values.cashInOutCheckbox}
                                            aria-required
                                        />
                                    </div>
                                </div>
                                {getFormErrorMessage('transCashInOutAmount')}
                            </div>
                        </div>
                    </div>

                    <div className="Form__Header mt-5">
                        <h6>E-LOAD</h6>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transELoadAmount" className={classNames('Label__Text', { 'p-error': isFormFieldValid('transELoadAmount') })}>
                                    Amount
                                </label>
                                <div className="p-fluid p-grid grid CheckboxAndInput">
                                    <div className="col-12 md:col-1 mt-1">
                                        <Checkbox id="eLoadAmountCheckbox" name="eLoadAmountCheckbox" checked={formik?.values?.eLoadAmountCheckbox || false} onChange={formik.handleChange} disabled={!formik.values.rCtSection} />
                                    </div>
                                    <div className="col-12 md:col-11">
                                        <InputText
                                            id="transELoadAmount"
                                            name="transELoadAmount"
                                            keyfilter="int"
                                            placeholder="Enter Amount"
                                            value={formik?.values?.transELoadAmount || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('transELoadAmount') }, 'Input__Round')}
                                            disabled={!formik.values.eLoadAmountCheckbox}
                                            aria-required
                                        />
                                    </div>
                                </div>
                                {getFormErrorMessage('transELoadAmount')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transELoadCompany" className={classNames({ 'p-error': isFormFieldValid('transELoadCompany') }, 'Label__Text')}>
                                    Telco
                                </label>
                                <div className="p-fluid p-grid grid CheckboxAndInput">
                                    <div className="col-12 md:col-1 mt-1">
                                        <Checkbox id="eLoadTelcoCheckbox" name="eLoadTelcoCheckbox" checked={formik?.values?.eLoadTelcoCheckbox || false} onChange={formik.handleChange} disabled={!formik.values.rCtSection} />
                                    </div>
                                    <div className="col-12 md:col-11">
                                        <Dropdown
                                            id="transELoadCompany"
                                            placeholder="Select Company"
                                            options={companyName}
                                            optionLabel="name"
                                            name="transELoadCompany"
                                            optionValue="lovId"
                                            value={formik.values.transELoadCompany}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('transELoadCompany') }, 'Dropdown__Round')}
                                            disabled={!formik.values.eLoadTelcoCheckbox}
                                        />
                                    </div>
                                </div>
                                {getFormErrorMessage('transELoadCompany')}
                            </div>
                        </div>
                    </div>

                    <div className="Form__Header mt-5">
                        <h6>RETAILER COMISSION</h6>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transRCProductId" className={classNames({ 'p-error': isFormFieldValid('transRCProductId') }, 'Label__Text')}>
                                    Product
                                </label>
                                <Dropdown
                                    id="transRCProductId"
                                    placeholder="Select Product"
                                    options={listOfProducts}
                                    optionLabel="name"
                                    name="transRCProductId"
                                    optionValue="lovId"
                                    value={formik?.values?.transRCProductId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transRCProductId') }, 'Dropdown__Round')}
                                    disabled={!formik.values.rCtSection}
                                />

                                {getFormErrorMessage('transRCProductId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transRCWHTax" className={classNames({ 'p-error': isFormFieldValid('transRCWHTax') }, 'Label__Text')}>
                                    With Holding Tax
                                </label>
                                <InputText
                                    id="transRCWHTax"
                                    name="transRCWHTax"
                                    keyfilter="int"
                                    placeholder="Enter WHT"
                                    value={formik?.values?.transRCWHTax || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transRCWHTax') }, 'Input__Round')}
                                    disabled={!formik.values.rCtSection}
                                    aria-required
                                />
                                {getFormErrorMessage('transRCWHTax')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transRCAmount" className={classNames({ 'p-error': isFormFieldValid('transRCAmount') }, 'Label__Text')}>
                                    Amount
                                </label>
                                <InputText
                                    id="transRCAmount"
                                    name="transRCAmount"
                                    keyfilter="int"
                                    placeholder="Enter Amount"
                                    value={formik?.values?.transRCAmount || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transRCAmount') }, 'Input__Round')}
                                    disabled={!formik.values.rCtSection}
                                    aria-required
                                />
                                {getFormErrorMessage('transRCAmount')}
                            </div>
                        </div>
                    </div>

                    <div className="Form__Header mt-5">
                        <h6>UBP</h6>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transUBPCompanyId" className={classNames({ 'p-error': isFormFieldValid('transUBPCompanyId') }, 'Label__Text')}>
                                    DISCO
                                </label>
                                <Dropdown
                                    id="transUBPCompanyId"
                                    placeholder="Select Company"
                                    options={companyName}
                                    optionLabel="name"
                                    name="transUBPCompanyId"
                                    optionValue="lovId"
                                    value={formik.values.transUBPCompanyId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transUBPCompanyId') }, 'Dropdown__Round')}
                                    disabled={!formik.values.rCtSection}
                                />

                                {getFormErrorMessage('transUBPCompanyId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transUBPAmount" className={classNames({ 'p-error': isFormFieldValid('transUBPAmount') }, 'Label__Text')}>
                                    Amount
                                </label>
                                <InputText
                                    id="transUBPAmount"
                                    name="transUBPAmount"
                                    keyfilter="int"
                                    placeholder="Enter Amount"
                                    value={formik?.values?.transUBPAmount || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transUBPAmount') }, 'Input__Round')}
                                    disabled={!formik.values.rCtSection}
                                    aria-required
                                />
                                {getFormErrorMessage('transUBPAmount')}
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">
                            RISK AND BUSINESS SECTION
                            <span>
                                <Checkbox id="riskandBusinessSection" name="riskandBusinessSection" checked={formik?.values?.riskandBusinessSection} onChange={formik.handleChange} />
                            </span>
                        </div>
                    </div>
                    <div className="Form__Header mt-5">
                        <h6>1 TO MANY ACCOUNT FUND TRANSFER</h6>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-2">
                            <div className="p-field-checkbox">
                                <Checkbox inputId="rBSOTMTohfa" name="rBSOTMTohfa" checked={formik?.values?.rBSOTMTohfa} onChange={formik.handleChange} disabled={!formik.values.riskandBusinessSection} className="mr-2" />
                                <label htmlFor="rBSOTMTohfa">Tohfa</label>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-2">
                            <div className="p-field-checkbox">
                                <Checkbox inputId="rBSOTMIBFT" name="rBSOTMIBFT" checked={formik?.values?.rBSOTMIBFT} onChange={formik.handleChange} disabled={!formik.values.riskandBusinessSection} className="mr-2" />
                                <label htmlFor="rBSOTMIBFT">IBFT</label>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-2">
                            <div className="p-field-checkbox">
                                <Checkbox inputId="rBSOTMP2P" name="rBSOTMP2P" checked={formik?.values?.rBSOTMP2P} onChange={formik.handleChange} disabled={!formik.values.riskandBusinessSection} className="mr-2" />
                                <label htmlFor="rBSOTMP2P">P2P</label>
                            </div>
                        </div>
                    </div>

                    <div className="Form__Header mt-5">
                        <h6>MANY TO 1 ACCOUNT FUND TRANSFER</h6>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-2">
                            <div className="p-field-checkbox">
                                <Checkbox inputId="rBSMTOTohfa" name="rBSMTOTohfa" checked={formik?.values?.rBSMTOTohfa} onChange={formik.handleChange} disabled={!formik.values.riskandBusinessSection} className="mr-2" />
                                <label htmlFor="rBSMTOTohfa">Tohfa</label>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-2">
                            <div className="p-field-checkbox">
                                <Checkbox inputId="rBSMTOIBFT" name="rBSMTOIBFT" checked={formik?.values?.rBSMTOIBFT} onChange={formik.handleChange} disabled={!formik.values.riskandBusinessSection} className="mr-2" />
                                <label htmlFor="rBSMTOIBFT">IBFT</label>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-2">
                            <div className="p-field-checkbox">
                                <Checkbox inputId="rBSMTOP2P" name="rBSMTOP2P" checked={formik?.values?.rBSMTOP2P} onChange={formik.handleChange} disabled={!formik.values.riskandBusinessSection} className="mr-2" />
                                <label htmlFor="rBSMTOP2P">P2P</label>
                            </div>
                        </div>
                    </div>

                    <div className="Form__Header mt-5">
                        <h6>CUSTOMER UBP's</h6>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="rBSUBPCount" className={classNames('Label__Text', { 'p-error': isFormFieldValid('rBSUBPCount') })}>
                                    Total Count
                                </label>
                                <div className="p-fluid p-grid grid CheckboxAndInput">
                                    <div className="col-12 md:col-1 mt-1">
                                        <Checkbox id="ubpCountCheckbox" name="ubpCountCheckbox" checked={formik?.values?.ubpCountCheckbox} onChange={formik.handleChange} disabled={!formik.values.riskandBusinessSection} />
                                    </div>
                                    <div className="col-12 md:col-11">
                                        <InputText
                                            id="rBSUBPCount"
                                            name="rBSUBPCount"
                                            keyfilter="int"
                                            placeholder="Enter Total Count"
                                            value={formik?.values?.rBSUBPCount || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rBSUBPCount') }, 'Input__Round')}
                                            aria-required
                                            disabled={!formik.values.ubpCountCheckbox}
                                        />
                                    </div>
                                </div>
                                {getFormErrorMessage('rBSUBPCount')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="rBSUBPAmount" className={classNames('Label__Text', { 'p-error': isFormFieldValid('rBSUBPAmount') })}>
                                    Total Amount
                                </label>
                                <div className="p-fluid p-grid grid CheckboxAndInput">
                                    <div className="col-12 md:col-1 mt-1">
                                        <Checkbox id="ubpAmountCheckbox" name="ubpAmountCheckbox" checked={formik?.values?.ubpAmountCheckbox} onChange={formik.handleChange} disabled={!formik.values.riskandBusinessSection} />
                                    </div>
                                    <div className="col-12 md:col-11">
                                        <InputText
                                            id="rBSUBPAmount"
                                            name="rBSUBPAmount"
                                            keyfilter="int"
                                            placeholder="Enter Total Amount"
                                            value={formik?.values?.rBSUBPAmount || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rBSUBPAmount') }, 'Input__Round')}
                                            aria-required
                                            disabled={!formik.values.ubpAmountCheckbox}
                                        />
                                    </div>
                                </div>
                                {getFormErrorMessage('rBSUBPAmount')}
                            </div>
                        </div>
                    </div>
                    <div className="Form__Header mt-5">
                        <h6>EXHAUSTED / BREACHED LIMIT CUSTOMER</h6>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="rBSLimitBreachAmount" className={classNames({ 'p-error': isFormFieldValid('rBSLimitBreachAmount') }, 'Label__Text')}>
                                    Amount
                                </label>
                                <div className="p-fluid p-grid grid CheckboxAndInput">
                                    <div className="col-12 md:col-1 mt-1">
                                        <Checkbox
                                            id="exhaustedAmountCheckbox"
                                            name="exhaustedAmountCheckbox"
                                            checked={formik?.values?.exhaustedAmountCheckbox || false}
                                            onChange={formik.handleChange}
                                            disabled={!formik.values.riskandBusinessSection}
                                        />
                                    </div>
                                    <div className="col-12 md:col-11">
                                        <Dropdown
                                            id="rBSLimitBreachAmount"
                                            placeholder="Select Amount"
                                            options={lookUps?.rBSLimitBreachAmount}
                                            name="rBSLimitBreachAmount"
                                            value={formik.values.rBSLimitBreachAmount}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rBSLimitBreachAmount') }, 'Dropdown__Round')}
                                            disabled={!formik.values.exhaustedAmountCheckbox}
                                        />
                                    </div>
                                </div>
                                {getFormErrorMessage('rBSLimitBreachAmount')}
                            </div>
                        </div>
                    </div>
                    <div className="Form__Header mt-5">
                        <h6>BLOCKED / SUSPENDED / BLACKLISTED / DORMANT ACCOUNT</h6>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="rBSAccountStatus" className={classNames({ 'p-error': isFormFieldValid('rBSAccountStatus') }, 'Label__Text')}>
                                    Account
                                </label>

                                <div className="p-fluid p-grid grid CheckboxAndInput">
                                    <div className="col-12 md:col-1 mt-1">
                                        <Checkbox id="blockedAccountCheckbox" name="blockedAccountCheckbox" checked={formik?.values?.blockedAccountCheckbox} onChange={formik.handleChange} disabled={!formik.values.riskandBusinessSection} />
                                    </div>
                                    <div className="col-12 md:col-11">
                                        <MultiSelect
                                            id="rBSAccountStatus"
                                            placeholder="Select Account"
                                            type="text"
                                            options={listOfStatus}
                                            optionLabel="accountStatusName"
                                            name="rBSAccountStatus"
                                            optionValue="accountStatusId"
                                            value={formik.values.rBSAccountStatus}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rBSAccountStatus') }, 'Dropdown__Round')}
                                            maxSelectedLabels={3}
                                            disabled={!formik.values.blockedAccountCheckbox}
                                        />
                                    </div>
                                </div>

                                {getFormErrorMessage('rBSAccountStatus')}
                            </div>
                        </div>
                    </div>
                    <div className="Form__Header mt-5">
                        <h6>BLOCKED / SUSPENDED / BLACKLISTED / DORMANCY REVIVAL</h6>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="rBSAccountStatusRevChannel" className={classNames({ 'p-error': isFormFieldValid('rBSAccountStatusRevChannel') }, 'Label__Text')}>
                                    Channel
                                </label>
                                <div className="p-fluid p-grid grid CheckboxAndInput">
                                    <div className="col-12 md:col-1 mt-1">
                                        <Checkbox id="blockedChannelCheckbox" name="blockedChannelCheckbox" checked={formik?.values?.blockedChannelCheckbox} onChange={formik.handleChange} disabled={!formik.values.riskandBusinessSection} />
                                    </div>
                                    <div className="col-12 md:col-11">
                                        <MultiSelect
                                            id="rBSAccountStatusRevChannel"
                                            placeholder="Select Channel"
                                            options={listOfChannel}
                                            optionLabel="name"
                                            name="rBSAccountStatusRevChannel"
                                            optionValue="lovId"
                                            value={formik.values.rBSAccountStatusRevChannel}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rBSAccountStatusRevChannel') }, 'Dropdown__Round')}
                                            maxSelectedLabels={3}
                                            disabled={!formik.values.blockedChannelCheckbox}
                                        />
                                    </div>
                                </div>
                                {getFormErrorMessage('rBSAccountStatusRevChannel')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="rBSAccountStatusRevAgent" className={classNames({ 'p-error': isFormFieldValid('rBSAccountStatusRevAgent') }, 'Label__Text')}>
                                    Agent
                                </label>
                                <div className="p-fluid p-grid grid CheckboxAndInput">
                                    <div className="col-12 md:col-1 mt-1">
                                        <Checkbox id="blockedAgentCheckbox" name="blockedAgentCheckbox" checked={formik?.values?.blockedAgentCheckbox} onChange={formik.handleChange} disabled={!formik.values.riskandBusinessSection} />
                                    </div>
                                    <div className="col-12 md:col-11">
                                        <MultiSelect
                                            id="rBSAccountStatusRevAgent"
                                            placeholder="Select Agent"
                                            options={lookUps?.rBSAccountStatusRevAgent}
                                            name="rBSAccountStatusRevAgent"
                                            value={formik.values.rBSAccountStatusRevAgent}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rBSAccountStatusRevAgent') }, 'Dropdown__Round')}
                                            maxSelectedLabels={3}
                                            disabled={!formik.values.blockedAgentCheckbox}
                                        />
                                    </div>
                                </div>
                                {getFormErrorMessage('rBSAccountStatusRevAgent')}
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">
                            DEVICE SECTION
                            <span>
                                <Checkbox id="deviceSection" name="deviceSection" checked={formik?.values?.deviceSection} onChange={formik.handleChange} />
                            </span>
                        </div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="deviceAgent" className={classNames({ 'p-error': isFormFieldValid('deviceAgent') }, 'Label__Text')}>
                                    Agent/Retailer
                                </label>
                                <Dropdown
                                    id="deviceAgent"
                                    placeholder="Select Agent"
                                    options={dsAgentOptions}
                                    optionLabel="name"
                                    name="deviceAgent"
                                    optionValue="lovId"
                                    value={formik.values.deviceAgent}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('deviceAgent') }, 'Dropdown__Round')}
                                    disabled={!formik.values.deviceSection}
                                />

                                {getFormErrorMessage('deviceAgent')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="deviceLocation" className={classNames({ 'p-error': isFormFieldValid('deviceLocation') }, 'Label__Text')}>
                                    Device Location
                                </label>
                                <Dropdown
                                    id="deviceLocation"
                                    placeholder="Select Device Location"
                                    options={cityLocation}
                                    optionLabel="name"
                                    name="deviceLocation"
                                    optionValue="lovId"
                                    value={formik.values.deviceLocation}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('deviceLocation') }, 'Dropdown__Round')}
                                    disabled={!formik.values.deviceSection}
                                />

                                {getFormErrorMessage('deviceLocation')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="devicemanufacture" className={classNames({ 'p-error': isFormFieldValid('devicemanufacture') }, 'Label__Text')}>
                                    Device Manufacturer
                                </label>
                                <Dropdown
                                    id="devicemanufacture"
                                    placeholder="Select Device Manufacturer"
                                    options={lookUps?.dSDeviceManufacturer}
                                    name="devicemanufacture"
                                    value={formik.values.devicemanufacture}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('devicemanufacture') }, 'Dropdown__Round')}
                                    disabled={!formik.values.deviceSection}
                                />

                                {getFormErrorMessage('devicemanufacture')}
                            </div>
                        </div>
                    </div>

                    <div className="Usergroup__SearchBtn p-field col-12  flex justify-content-center">
                        <Button label="Submit" type="submit" className="Btn__Dark__Search" />
                        <Button type="button" label="Reset" onClick={(e) => resetHandle(e)} className="Btn__Dark__Search" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default RiskDashboard;

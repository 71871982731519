import { baseURL } from '../Config';
import axios from 'axios';
import { toast } from 'react-toastify';

export const handleGetForNotificationRequest = async (url, isShowToast = false) => {
    try {
        const response = await axios.get(`${baseURL + url}`, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        });
        if (isShowToast) toast.success(response?.data?.messages || response?.data?.message);
        return response.data;
    } catch (error) {
        if (error?.response?.status === 500 && isShowToast) {
            setTimeout(() => {
                toast.error({
                    render: error?.response?.data?.message || error?.response?.data?.message || error?.response?.data?.error || 'Something went wrong !!',
                    type: 'error',
                    isLoading: false,
                    autoClose: 3000
                });
            }, 100);
        } else {
            if (isShowToast) {
                setTimeout(() => {
                    toast.error({
                        render: error?.response?.data?.message || error?.response?.data?.message || error?.response?.data?.error || 'Something went wrong !!',
                        type: 'warn',
                        isLoading: false,
                        autoClose: 3000
                    });
                }, 100);
            }
        }
    }
};

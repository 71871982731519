import React, { useState } from 'react'
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { Divider } from 'primereact/divider';

const Campaigns = () => {

    const [disabled, setDisabled] = useState(true);

    const validationSchema = Yup.object().shape({
        accountNumber: Yup.number().notRequired('This Field is notRequired.'),
        mobileNumber: Yup.number().notRequired('This Field is notRequired.'),
        cnic: Yup.number().notRequired('This Field is notRequired.'),
        segment: Yup.string().notRequired('This Field is notRequired.'),
        product: Yup.string().notRequired('This Field is notRequired.'),
        dateFrom: Yup.string().notRequired('This Field is notRequired.'),
        dateTo: Yup.string().notRequired('This Field is notRequired.'),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            accountNumber: '',
            mobileNumber: '',
            cnic: '',
            segment: '',
            product: '',
            dateFrom: '',
            dateTo: '',
        },
        onSubmit: async (data) => {

        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleReset = (e) => {
        e.preventDefault();
        // formik.resetForm();
    };

    return (
        <>

            <div className='pt-2'>
                <h6 className='secondary-color'><b>CAMPAIGNS</b></h6>
            </div>

            <Divider />

            <form className="p-fluid p-mt-2">
                <div className="p-fluid p-formgrid grid mb-5">

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Account ID</label>
                            <InputText
                                id="accountNumber"
                                name="accountNumber"
                                value={formik.values.accountNumber}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('accountNumber') }, 'Input__Round')}
                                keyfilter="int"
                            />
                            {getFormErrorMessage('accountNumber')}
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Mobile No.</label>
                            <InputText
                                id="mobileNumber"
                                name="mobileNumber"
                                value={formik.values.mobileNumber}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('mobileNumber') }, 'Input__Round')}
                                keyfilter="int"
                                maxLength={11}
                            />
                            {getFormErrorMessage('mobileNumber')}
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">CNIC</label>
                            <InputText
                                id="cnic"
                                name="cnic"
                                value={formik.values.cnic}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('cnic') }, 'Input__Round')}
                                keyfilter="int"
                                maxLength={13}
                            />
                            {getFormErrorMessage('cnic')}
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className='Label__Text'>
                                Segment
                            </label>
                            <Dropdown
                                id="segment"
                                name="segment"
                                placeholder="Select Filter"
                                // options={statusData}
                                optionLabel="segment"
                                optionValue="lovId"
                                // value={formik.values.statusId || ''}
                                // onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('segment') }, 'Dropdown__Round')}
                            />

                            {getFormErrorMessage('segment')}
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className='Label__Text'>
                                Product
                            </label>
                            <Dropdown
                                id="product"
                                name="product"
                                placeholder="Select Filter"
                                // options={statusData}
                                optionLabel="name"
                                optionValue="lovId"
                                // value={formik.values.statusId || ''}
                                // onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('product') }, 'Dropdown__Round')}
                            />
                            {getFormErrorMessage('product')}
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className='Label__Text'>
                                Date From
                            </label>
                            <InputText
                                id="dateFrom"
                                type="date"
                                name="dateFrom"
                                value={formik?.values?.dateFrom}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                            />
                            {getFormErrorMessage('dateFrom')}
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className='Label__Text'>
                                Date To
                            </label>
                            <InputText
                                id="dateTo"
                                type="date"
                                name="dateTo"
                                value={formik?.values?.dateTo}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')}
                            />
                            {getFormErrorMessage('dateTo')}
                        </div>
                    </div>

                </div>

                <div className="p-field col-12 flex justify-content-center">
                    <div className="p-field">
                        <div className="Usergroup__SearchBtn">
                            <Button label="Search" disabled={disabled} iconPos="right" className="Btn__Dark__Search" />
                            <Button label="Reset" onClick={(e) => handleReset(e)} className="Btn__Dark__Search" />
                        </div>
                    </div>
                </div>

            </form>

            <div className="pt-5 p-datatable-sm custom-border">
                <div>
                    <h4>Existing Detail:</h4>
                </div>
                <div className="custom-datatable">
                    <DataTable
                        // header={header}
                        // value={manageAccountsData}
                        emptyMessage="No data found."
                        scrollable
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25]}
                    >
                        <Column field="mobileNo" header="Sr No." />
                        <Column field="fullName" header="List of Campaign" />
                        <Column field="accountLevelName" header="Qualified" />
                        <Column field="accountOpeningDate" header="Availed" />
                        <Column field="cnic" header="Transaction Amount" />
                        <Column field="email" header="Transaction ID" />
                        <Column field="email" header="Transaction Type" />
                        <Column field="email" header="Eligibility Criteria" />
                        <Column field="email" header="Date & Time" />
                        <Column field="email" header="Remarks" />
                        {/* <Column body={statusBodyTemplate} header="Status" />
        <Column className="Table__ActionHeading__CentredAlign" body={actionTemplate} header="Actions" /> */}
                    </DataTable>
                </div>
            </div>
        </>

    )
}

export default Campaigns
import { Divider } from 'primereact/divider'
import React from 'react'

const Transaction = ({ rowData }) => {
    return (
        <>
            <div className='mt-6'>
                <h5 className="secondary-color">
                    <b>TRANSACTION</b>
                </h5>
                <Divider />
            </div>

            <div className='hra_table'>
                <table>
                    <tr>
                        <th className='dim-heading'>Transaction Id</th>
                        <th className='dim-heading'>Organization Name</th>
                        <th className='dim-heading'>Location</th>
                        <th className='dim-heading'>Amount</th>
                    </tr>
                    <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                </table>
            </div>
        </>
    )
}

export default Transaction
import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as Yup from 'yup';
import { InputTextarea } from 'primereact/inputtextarea';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function EditAggregatorChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [dataById, setDatabyId] = useState();
    const [aggregatorLov, setAggregatorLov] = useState();
    // const [aggregatorTypeData, setAggregatorTypeData] = useState({
    //     productId: '',
    //     varientId: '',
    //     discount: ''
    // });
    const [merchantData, setMerchantData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPendingRequest = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const validationSchema = Yup.object().shape({
        checkerComments: Yup.string().required('This Field is required.'),
        action: Yup.string().required('This Field is required.')
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            action: '',
            checkerComments: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        aggregatorRequest: {
                            aggregatorHeadId: refTableId,
                            isActive: 'Y',
                            checkerRequestType: requestType
                        },
                        mcActionRequest: {
                            checkerComments: data?.checkerComments,
                            action: data?.action,
                            updatedIndex: data?.updatedIndex,
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPendingRequest,
                            checkerId: '1'
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const newDataU = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        aggregatorRequest: dataById,
                        mcActionRequest: {
                            checkerComments: data?.checkerComments,
                            action: data?.action,
                            updatedIndex: data?.updatedIndex,
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPendingRequest,
                            checkerId: '1'
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            await dispatch(handlePostRequest(requestType === 'I' ? newData : newDataU, '/lending/v1/lending/aggregatorcheckeraction', true, false, 'requestchecker'));
            setloading(false);
            setloadingIcon('');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const productTypeLovData = [{ name: 'name', lovId: 1 }];

    const varientTypeLovData = [{ name: 'varientName', lovId: 1 }];

    const getDatabyId = async () => {
        const response = await handleGetRequest(`/lending/v1/lending/getaggregatorbyid/${refTableId}`);

        formik.setFieldValue('aggregator', response?.payLoad?.tblLmsAggregator?.lmsAggregatorId.toString());

        const getProductData = response?.payLoad?.tblLmsAgrProducts?.map((item) => {
            return {
                discount: item?.discount,
                productName: item?.tblLmsProduct?.lmsProductName,
                varientName: item?.tblLmsVariant?.variantName
            };
        });
        setProductData(getProductData);

        const getMerchantName = response?.payLoad?.tblLmsAgrMerchants?.map((item) => {
            return { merchantName: item?.tblMerchant?.businessName };
        });
        setMerchantData(getMerchantName);
    };

    const getDatabyIdU = async () => {
        const response = await handleGetRequest(`/lending/v1/lending/getmcrequestaggregatorbyid/${mcRequestId}`);
        setDatabyId(response?.payLoad);

        formik.setFieldValue('aggregator', response?.payLoad?.aggregatorId.toString());

        const getProductData = response?.payLoad?.aggregatorProductRequestList?.map((item) => {
            return {
                discount: item?.discount,
                productName: item?.productId,
                varientName: item?.varientId
            };
        });
        setProductData(getProductData);

        const getMerchantName = response?.payLoad?.aggregatorMerchantRequests?.map((item) => {
            return { merchantName: item?.merchantId };
        });
        setMerchantData(getMerchantName);
    };

    const getDatabyIdUIsActive = async () => {
        const response = await handleGetRequest(`/lending/v1/lending/getmcrequestaggregatorbyid/${mcRequestId}`);
        setDatabyId(response?.payLoad);
    };

    const getAggregatorLov = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/lending/TBL_LMS_AGGREGATOR');
        setAggregatorLov(response?.payLoad);
    };
    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getDatabyIdU();
        } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
            getDatabyId();
            getDatabyIdUIsActive();
        }

        getAggregatorLov();
        getStatusLov();
    }, []); // eslint-disable-line

    const actionAggregatorBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button disabled={true} tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
                </div>
            </>
        );
    };
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>Edit Detail</h2>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="aggregator" className={classNames({ 'p-error': isFormFieldValid('aggregator') }, 'Label__Text')}>
                                    Aggregator
                                </label>
                                <Dropdown
                                    id="aggregator"
                                    placeholder="Select Aggregator"
                                    options={aggregatorLov}
                                    optionLabel="name"
                                    name="aggregator"
                                    optionValue="lovId"
                                    value={formik.values.aggregator || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('aggregator') }, 'Dropdown__Round')}
                                    disabled={true}
                                />
                                {getFormErrorMessage('aggregator')}
                            </div>
                        </div>
                    </div>

                    <div className="card pt-3" style={{ background: '#f8f8f8' }}>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="productId" className={classNames({ 'p-error': isFormFieldValid('productId') }, 'Label__Text')}>
                                        Product <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="productId"
                                        placeholder="Select Product"
                                        options={productTypeLovData}
                                        optionLabel="name"
                                        name="productId"
                                        optionValue="lovId"
                                        value={formik.values.productId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('productId') }, 'Dropdown__Round')}
                                        disabled={true}
                                    />

                                    <small className="p-error">{formik.touched.productId && formik.errors.productId}</small>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="varient" className={classNames({ 'p-error': isFormFieldValid('varient') }, 'Label__Text')}>
                                        Varient <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="varient"
                                        placeholder="Select Varient"
                                        options={varientTypeLovData}
                                        optionLabel="name"
                                        name="varient"
                                        optionValue="lovId"
                                        value={formik.values.varient || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('varient') }, 'Dropdown__Round')}
                                        disabled={true}
                                    />

                                    <small className="p-error">{formik.touched.varient && formik.errors.varient}</small>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="discount" className={classNames({ 'p-error': isFormFieldValid('discount') }, 'Label__Text')}>
                                        Discount<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="discount"
                                        placeholder="Enter Discount"
                                        name="discount"
                                        value={formik.values?.discount?.trim()?.toString() || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('discount') }, 'Input__Round')}
                                        disabled={true}
                                    />
                                    <small className="p-error">{formik.touched.discount && formik.errors.discount}</small>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-2 mr-5 mt-4">
                                <div className="Down__Btn__Configuration">
                                    <Button type="submit" label="Add" className="Btn__Add" disabled={true} />
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12">
                                <div className="card">
                                    <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={productData}>
                                        <Column header="Product" field="productName" />
                                        <Column header="Varient" field="varientName" />
                                        <Column header="Discount" field="discount" />
                                        <Column header="Actions" body={actionAggregatorBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid">
                        <div className="col-12">
                            <div className="card">
                                <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={merchantData}>
                                    <Column header="Merchant Name" field="merchantName" />
                                    <Column header="Actions" body={actionAggregatorBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                                </DataTable>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                            Action
                                            <span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="action"
                                            placeholder="Select Action"
                                            options={statusLovData}
                                            optionLabel="name"
                                            name="action"
                                            optionValue="lovId"
                                            optionDisabled={(option) => option?.name === 'Pending'}
                                            value={formik.values.action || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('action')}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-12">
                                    <div className="p-field">
                                        <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                            Comments
                                            <span className="Label__Required">*</span>
                                        </label>
                                        <InputTextarea
                                            maxLength={200}
                                            autoResize={false}
                                            rows={5}
                                            cols={30}
                                            id="checkerComments"
                                            name="checkerComments"
                                            value={formik?.values?.checkerComments || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                        />

                                        {getFormErrorMessage('checkerComments')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Submit" className="Btn__Dark" />
                        <Button disabled={loading} loadingIcon={loadingIcon || ''} onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default EditAggregatorChecker;

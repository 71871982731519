import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { InputTextarea } from 'primereact/inputtextarea';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewPointFactorChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-save');
    const [accountTypeData, setAccountTypeData] = useState([]);
    const [segmentData, setSegmentData] = useState([]);
    const [transactionTypeData, setTransactionTypeData] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);

    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const tableName = query.get('tableName');
    const reqType = query.get('requestType');
    const mcReqId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');

    const navigate = useNavigate();

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        getStatusLov();
    }, []); // eslint-disable-line

    const getAccountTypeData = async () => {
        const res = await handleGetRequest('/configuration/v1/accountype/getAllAccounttypes');
        setAccountTypeData(res?.payLoad);
    };

    const getSegmentData = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getsegments/LKP_SEGMENT:*`);
        setSegmentData(res?.payLoad);
    };
    const getTransactionTypeData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setTransactionTypeData(res?.payLoad);
    };

    const getDataById = async () => {
        const response = await handleGetRequest(`/zconfig/v1/zmiles/getPointsFactor/${refTableId}`);

        formik.setFieldValue('pointsFactor', response?.payLoad?.pointsFactor);
        formik.setFieldValue('pointsFactorName', response?.payLoad?.pointsFactorName);
        formik.setFieldValue('dateFrom', response?.payLoad?.dateFrom);
        formik.setFieldValue('dateTo', response?.payLoad?.dateTo);
        formik.setFieldValue('expiry', response?.payLoad?.expiry);

        formik.setFieldValue('accType', response?.payLoad?.lkpAccountLevel?.accountLevelId);
        formik.setFieldValue('zmilesPointsFactorId', response?.payLoad?.zmilesPointsFactorId);

        const transTypeData = response?.payLoad?.tblZmilesPointsFactorProducts?.map((value) => {
            return value?.productId?.toString();
        });
        const segmentData = response?.payLoad?.tblZmilesPointsFactorSegments?.map((value) => {
            return value?.segmentId?.toString();
        });

        formik.setFieldValue('transType', transTypeData);
        formik.setFieldValue('segmentId', segmentData);
    };

    const getDataByIdUType = async () => {
        const response = await handleGetRequest(`/zconfig/v1/zmiles/getPointFactorUpdateCheckerById/${mcReqId}`);

        formik.setFieldValue('pointsFactor', response?.payLoad?.pointsFactor);
        formik.setFieldValue('pointsFactorName', response?.payLoad?.pointsFactorName);
        formik.setFieldValue('dateFrom', response?.payLoad?.dateFrom?.slice(0, 10));
        formik.setFieldValue('dateTo', response?.payLoad?.dateTo?.slice(0, 10));
        formik.setFieldValue('expiry', response?.payLoad?.expiry);

        formik.setFieldValue('accType', response?.payLoad?.lkpAccountLevel?.accountLevelId);
        formik.setFieldValue('zmilesPointsFactorId', response?.payLoad?.zmilesPointsFactorId);

        const transTypeData = response?.payLoad?.tblZmilesPointsFactorProducts?.map((value) => {
            return value?.productId?.toString();
        });
        const segmentData = response?.payLoad?.tblZmilesPointsFactorSegments?.map((value) => {
            return value?.segmentId?.toString();
        });

        formik.setFieldValue('transType', transTypeData);
        formik.setFieldValue('segmentId', segmentData);
    };

    useEffect(() => {
        getAccountTypeData();
        getSegmentData();
        getTransactionTypeData();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (reqType === 'I') {
            getDataById();
        } else if (reqType === 'U') {
            getDataByIdUType();
        }
    }, []); // eslint-disable-line

    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('This field is required.'),
        checkerComments: Yup.string().required('This field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            pointsFactorName: '',
            pointsFactor: '',
            accType: '',
            segmentId: [],
            transType: [],
            dateFrom: '',
            dateTo: '',
            expiry: '',
            isActive: '',
            action: '',
            checkerComments: '',
            zmilesPointsFactorId: null
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            data['appUserId'] = '1';

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        zmilesPointsFactorRequeste: {
                            isActive: 'Y',
                            statusId: 0,
                            dateFrom: data['dateFrom'],
                            dateTo: data['dateTo'],
                            pointsFactor: parseInt(data['pointsFactor']),
                            pointsFactorName: data['pointsFactorName'],
                            transType: data['transType'],
                            expiry: parseInt(data['expiry']),
                            segmentId: data['segmentId'],
                            accType: parseInt(data?.accType),
                            zmilesPointsFactorId: data['zmilesPointsFactorId']
                        },
                        mcActionRequest: {
                            mcRequestId: mcReqId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(newData, `/zconfig/v1/zmiles/ViewPointFactorCheckerAction`, true, false));
            if (res?.responseCode === '330000') {
                formik.resetForm();
                navigate('/requestchecker');
            }
            setloading(false);
            setloadingIcon('pi pi-save');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="pointsFactorName" className={classNames({ 'p-error': isFormFieldValid('pointsFactorName') }, 'Label__Text')}>
                                    Point Factor Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    id="pointsFactorName"
                                    placeholder="Enter Point Factor"
                                    name="pointsFactorName"
                                    value={formik?.values?.pointsFactorName}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pointsFactorName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('pointsFactorName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="pointsFactor" className={classNames({ 'p-error': isFormFieldValid('pointsFactor') }, 'Label__Text')}>
                                    Point Factor <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    id="pointsFactor"
                                    placeholder="Enter Point Factor"
                                    name="pointsFactor"
                                    value={formik?.values?.pointsFactor}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pointsFactor') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('pointsFactor')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accType" className={classNames({ 'p-error': isFormFieldValid('accType') }, 'Label__Text')}>
                                    Customer Account Type
                                </label>
                                <Dropdown
                                    disabled
                                    id="accType"
                                    placeholder="Select Account Type"
                                    options={accountTypeData}
                                    optionLabel="accountLevelName"
                                    name="accType"
                                    optionValue="accountLevelId"
                                    value={formik?.values?.accType}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accType') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('accType')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="segmentId" className={classNames({ 'p-error': isFormFieldValid('segmentId') }, 'Label__Text')}>
                                    Segment
                                </label>
                                <MultiSelect
                                    id="segmentId"
                                    placeholder="Select Segment"
                                    options={segmentData}
                                    optionLabel="name"
                                    name="segmentId"
                                    optionValue="lovId"
                                    value={formik?.values?.segmentId}
                                    className={classNames({ 'p-invalid': isFormFieldValid('segmentId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('segmentId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transType" className={classNames({ 'p-error': isFormFieldValid('transType') }, 'Label__Text')}>
                                    Transaction Type<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="transType"
                                    placeholder="Select Transaction Type"
                                    options={transactionTypeData}
                                    optionLabel="name"
                                    name="transType"
                                    optionValue="lovId"
                                    value={formik.values.transType}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('transType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From<span className="Label__Required">*</span>
                                </label>
                                <InputText disabled id="dateFrom" type="date" name="dateFrom" value={formik?.values?.dateFrom} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')} />

                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText disabled id="dateTo" type="date" name="dateTo" value={formik?.values?.dateTo} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')} />

                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expiry" className={classNames({ 'p-error': isFormFieldValid('expiry') }, 'Label__Text')}>
                                    Expiry<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    id="expiry"
                                    placeholder="Enter Expiry"
                                    name="expiry"
                                    value={formik?.values?.expiry}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expiry') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('expiry')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                </label>
                                <InputTextarea
                                    maxLength={200}
                                    rows={5}
                                    cols={30}
                                    disabled={true}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="OK" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default ViewPointFactorChecker;

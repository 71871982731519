import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { handlePostRequest } from '../../../services/PostTemplate';
import classNames from 'classnames';

function GenericComponent({ type, viewData, editData }) {
    const [loading, setloading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const alphanumericWithoutSpacesRegex = /^[a-zA-Z0-9\/\-_]+$/;

    const EditValidationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required').matches(alphanumericWithoutSpacesRegex, 'Name must be alphanumeric and should not contain spaces'),

        description: Yup.string().matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
    });

    const EditInitialValues = {
        name: '',
        description: ''
    };

    useEffect(() => {
        if (type === 'view') {
            formik.setFieldValue('name', viewData?.name);
            formik.setFieldValue('description', viewData?.description);
        } else if (type === 'edit') {
            formik.setFieldValue('name', editData?.name);
            formik.setFieldValue('description', editData?.description);
        }
    }, [type, viewData, editData]); // eslint-disable-line

    //formik
    const formik = useFormik({
        validationSchema: type === 'add' || type === 'edit' ? EditValidationSchema : null,
        initialValues: type === 'add' || type === 'edit' || type === 'view' ? EditInitialValues : null,

        onSubmit: async (data) => {
            setloading(true);
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad:
                        type === 'add'
                            ? {
                                  name: formik?.values?.name,
                                  description: formik?.values?.description
                              }
                            : type === 'edit'
                            ? {
                                  id: editData?.ruleFieldId,
                                  name: formik?.values?.name,
                                  description: formik?.values?.description
                              }
                            : null,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            if (type === 'add') {
                await dispatch(handlePostRequest(newData, '/ruleengine/v1/rule/rulefield/savedynamicrulesmodels', true, false, 'rulefield'));
            }
            if (type === 'edit') {
                await dispatch(handlePostRequest(newData, '/ruleengine/v1/rule/rulefield/updatedynamicrulesmodels', true, false, 'rulefield'));
            }

            setloading(false);
        }
    });

    //Functions to handle validations error

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>{type === 'add' ? 'ADD NEW' : type === 'edit' || type === 'checkerEdit' ? 'EDIT DETAILS' : type === 'view' || type === 'checkerView' ? 'VIEW DETAILS' : null}</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="name" className="Label__Text">
                                    Name/Key<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="name"
                                    name="name"
                                    value={formik.values.name || ''}
                                    onChange={formik.handleChange}
                                    disabled={type !== 'add' && type !== 'edit'}
                                    className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('name')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="description" className="Label__Text">
                                    Description
                                </label>
                                <InputText
                                    id="description"
                                    name="description"
                                    value={formik.values.description || ''}
                                    disabled={type !== 'add' && type !== 'edit'}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('description') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('description')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        {type === 'add' && (
                            <React.Fragment>
                                <Button type="submit" label="Submit" className="Btn__Dark" />
                                <Button disabled={loading} onClick={() => navigate('/rulefield')} type="button" label="Cancel" className="Btn__Transparent" />
                            </React.Fragment>
                        )}
                        {type === 'edit' && (
                            <React.Fragment>
                                <Button type="submit" label="Update" className="Btn__Dark" />
                                <Button disabled={loading} onClick={() => navigate('/rulefield')} type="button" label="Cancel" className="Btn__Transparent" />
                            </React.Fragment>
                        )}
                        {type === 'view' && (
                            <React.Fragment>
                                <Button type="button" label="Okay" className="Btn__Dark" onClick={() => navigate('/rulefield')} />
                            </React.Fragment>
                        )}
                    </div>
                </form>
            </div>
        </>
    );
}

export default GenericComponent;

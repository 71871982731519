import React from 'react';
import cmsRedirectSplash from '../../../../assets/cms/cmsRedirectSplash.png';

function MobileView({ icon, title }) {
    return (
        <div className="cmsMobileView__main relative">
            <div className="cmsMobileView__redirectSplash">
                {title && <span className="cmsMobileView__title">{title}</span>}
                {icon && <img src={icon} className="cmsMobileView__icon" alt="icon" />}
            </div>
            <img src={cmsRedirectSplash} className="blurMobile w-100 h-100" alt="cmsRedirectSplash" />
        </div>
    );
}

export default MobileView;

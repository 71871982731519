import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { handlePostRequest } from '../../../../../services/PostTemplate';
import AuthorizationHistoryTable from '../../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
// import { handleGetRequest } from '../../../../../services/GetTemplate';

function DefaultComponent({ type, urlData, statusLovData, getByIdData, getByMCRequestIdData }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setloading] = useState(false);
    const [dataId, setDataId] = useState('');

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const EditInitialValues = {
        attempts: '',
        attemptsTime: '',
        isTemporaryBlock: true,
        isPermanentBlock: false,
        blockTime: ''
    };

    const EditValidationSchema = Yup.object().shape({
        attempts: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .required('This field is required'),
        attemptsTime: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            // .matches(/^(0?[1-9]|1[0-9]|2[0-4])$/, 'Input must be a number between 1 and 24')
            .required('This field is required'),
        blockTime: Yup.string()
            .when('isTemporaryBlock', {
                is: true,
                then: Yup.string().required('This field is required'),
                otherwise: Yup.string().notRequired()
            })
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
        // .matches(/^(0?[1-9]|1[0-9]|2[0-4])$/, 'Input must be a number between 1 and 24')
        // .required('This field is required')
    });

    const checkerValidationSchema = Yup.object().shape({
        action: Yup.string().required('This field is required'),
        checkerComments: Yup.string().required('This field is required')
    });

    const formik = useFormik({
        validationSchema: type === 'edit' || type === 'add' ? EditValidationSchema : type === 'checkerEdit' ? checkerValidationSchema : null,
        initialValues: type === 'edit' || type === 'view' || type === 'add' ? EditInitialValues : type === 'checkerEdit' || type === 'checkerView' ? { ...EditInitialValues, action: '', checkerComments: '' } : null,

        onSubmit: async (e) => {
            setloading(true);
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad:
                        type === 'add'
                            ? {
                                  attempts: formik?.values?.attempts,
                                  attemptsTime: formik?.values?.attemptsTime,
                                  isTemporaryBlock: formik?.values?.isTemporaryBlock === true ? 'Y' : 'N',
                                  isPermanentBlock: formik?.values?.isPermanentBlock === true ? 'Y' : 'N',
                                  blockTime: formik?.values?.blockTime,
                                  isActive: 'Y'
                              }
                            : type === 'edit'
                            ? {
                                  cmsPmdConfigId: getByIdData?.cmsPmdConfigId,
                                  attempts: formik?.values?.attempts,
                                  attemptsTime: formik?.values?.attemptsTime,
                                  isTemporaryBlock: formik?.values?.isTemporaryBlock === true ? 'Y' : 'N',
                                  isPermanentBlock: formik?.values?.isPermanentBlock === true ? 'Y' : 'N',
                                  blockTime: formik?.values?.blockTime,
                                  //   isActive: getByIdData?.isActive
                                  isActive: null
                              }
                            : type === 'checkerEdit'
                            ? {
                                  objectBody: {
                                      cmsPmdConfigId: dataId,
                                      attempts: formik?.values?.attempts,
                                      attemptsTime: formik?.values?.attemptsTime,
                                      isTemporaryBlock: formik?.values?.isTemporaryBlock === true ? 'Y' : 'N',
                                      isPermanentBlock: formik?.values?.isPermanentBlock === true ? 'Y' : 'N',
                                      blockTime: formik?.values?.blockTime,
                                      isActive: getByMCRequestIdData?.isActive
                                  },
                                  mcActionRequest: {
                                      mcRequestId: urlData?.mcRequestId,
                                      mcPeindingRequestId: urlData?.mcPeindingRequestId,
                                      checkerComments: formik?.values?.checkerComments,
                                      checkerId: 1,
                                      action: formik?.values?.action,
                                      updatedIndex: 1
                                  }
                              }
                            : null,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            if (type === 'add') {
                await dispatch(handlePostRequest(newData, '/cms/v1/cms/savepmdconfig', true, false, 'pmd'));
            }
            if (type === 'edit') {
                await dispatch(handlePostRequest(newData, '/cms/v1/cms/updatepmdconfig', true, false, 'pmd'));
            }
            if (type === 'checkerEdit') {
                await dispatch(handlePostRequest(newData, '/cms/v1/cms/pmdconfigvalidatorcheckeraction', true, false, 'requestchecker'));
            }
            setloading(false);
        }
    });

    useEffect(() => {
        if ((type === 'edit' || type === 'view') && getByIdData) {
            formik.setFieldValue('attempts', getByIdData?.attempts);
            formik.setFieldValue('attemptsTime', getByIdData?.attemptsTime);
            formik.setFieldValue('isTemporaryBlock', getByIdData?.isTemporaryBlock === 'Y' ? true : false);
            formik.setFieldValue('isPermanentBlock', getByIdData?.isPermanentBlock === 'Y' ? true : false);
            formik.setFieldValue('blockTime', getByIdData?.blockTime);
        }
    }, [getByIdData]);

    useEffect(() => {
        if ((type === 'checkerEdit' || type === 'checkerView') && (getByMCRequestIdData || getByIdData)) {
            if (urlData?.requestType === 'U') {
                setDataId(getByMCRequestIdData?.cmsPmdConfigId);
                formik.setFieldValue('attempts', getByMCRequestIdData?.attempts);
                formik.setFieldValue('attemptsTime', getByMCRequestIdData?.attemptsTime);
                formik.setFieldValue('isTemporaryBlock', getByMCRequestIdData?.isTemporaryBlock === 'Y' ? true : false);
                formik.setFieldValue('isPermanentBlock', getByMCRequestIdData?.isPermanentBlock === 'Y' ? true : false);
                formik.setFieldValue('blockTime', getByMCRequestIdData?.blockTime);
            } else if (urlData?.requestType === 'I') {
                setDataId(getByIdData?.cmsPmdConfigId);
                formik.setFieldValue('attempts', getByIdData?.attempts);
                formik.setFieldValue('attemptsTime', getByIdData?.attemptsTime);
                formik.setFieldValue('isTemporaryBlock', getByIdData?.isTemporaryBlock === 'Y' ? true : false);
                formik.setFieldValue('isPermanentBlock', getByIdData?.isPermanentBlock === 'Y' ? true : false);
                formik.setFieldValue('blockTime', getByIdData?.blockTime);
            }
        }
    }, [type, getByMCRequestIdData, getByIdData]);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h5 className="uppercase">{type === 'add' ? 'Add New' : type === 'edit' || type === 'checkerEdit' ? 'Edit Detail' : type === 'view' || type === 'checkerView' ? 'View Detail' : null}</h5>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="attempts" className={classNames({ 'p-error': isFormFieldValid('attempts') }, 'Label__Text')}>
                                    Attempts
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={30}
                                    keyfilter={'int'}
                                    placeholder="Enter Attempts"
                                    id="attempts"
                                    name="attempts"
                                    value={formik?.values?.attempts || ''}
                                    onChange={formik.handleChange}
                                    disabled={type !== 'add' && type !== 'edit'}
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('attempts') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('attempts')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="attemptsTime" className={classNames({ 'p-error': isFormFieldValid('attemptsTime') }, 'Label__Text')}>
                                    Time
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={30}
                                    keyfilter={'int'}
                                    // type="time"
                                    placeholder="Enter Time"
                                    id="attemptsTime"
                                    name="attemptsTime"
                                    value={formik?.values?.attemptsTime || ''}
                                    onChange={formik.handleChange}
                                    disabled={type !== 'add' && type !== 'edit'}
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('attemptsTime') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('attemptsTime')}
                            </div>
                        </div>
                    </div>
                    <div className="Form__Header mt-4">
                        <h5 className="uppercase">Breach Rule</h5>
                    </div>
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-field col-12 md:col-4">
                            <div className="field-checkbox flex mb-2">
                                <Checkbox
                                    inputId="isTemporaryBlock"
                                    name="isTemporaryBlock"
                                    value={formik?.values?.isTemporaryBlock}
                                    checked={formik?.values?.isTemporaryBlock}
                                    disabled={type !== 'add' && type !== 'edit'}
                                    onChange={() => {
                                        formik.setFieldValue('isTemporaryBlock', !formik?.values?.isTemporaryBlock);
                                        formik.setFieldValue('isPermanentBlock', !formik?.values?.isPermanentBlock);
                                        formik.setFieldValue('blockTime', '');
                                    }}
                                    style={{ marginTop: '10px' }}
                                />
                                <label htmlFor="isTemporaryBlock" className={classNames({ 'p-error': isFormFieldValid('isTemporaryBlock') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                    Temporary Block
                                </label>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="blockTime" className={classNames({ 'p-error': isFormFieldValid('blockTime') }, 'Label__Text')}>
                                    Time (minutes)
                                    {formik?.values?.isTemporaryBlock && <span className="Label__Required">*</span>}
                                    {/* <span className="Label__Required">*</span> */}
                                </label>
                                <InputText
                                    maxLength={30}
                                    keyfilter={/^\d+$/}
                                    // type="time"
                                    placeholder="Enter Block Time"
                                    id="blockTime"
                                    name="blockTime"
                                    value={formik?.values?.blockTime || ''}
                                    onChange={formik.handleChange}
                                    disabled={(type !== 'add' && type !== 'edit') || !formik?.values?.isTemporaryBlock}
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('blockTime') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('blockTime')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-field col-12 md:col-4">
                            <div className="field-checkbox flex mb-2">
                                <Checkbox
                                    inputId="isPermanentBlock"
                                    name="isPermanentBlock"
                                    value={formik?.values?.isPermanentBlock}
                                    checked={formik?.values?.isPermanentBlock}
                                    disabled={type !== 'add' && type !== 'edit'}
                                    onChange={() => {
                                        formik.setFieldValue('isPermanentBlock', !formik?.values?.isPermanentBlock);
                                        formik.setFieldValue('isTemporaryBlock', !formik?.values?.isTemporaryBlock);
                                        formik.setFieldValue('blockTime', '');
                                    }}
                                    style={{ marginTop: '10px' }}
                                />
                                <label htmlFor="isPermanentBlock" className={classNames({ 'p-error': isFormFieldValid('isPermanentBlock') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                    Permanent Block
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn py-3">
                        {type === 'add' && (
                            <React.Fragment>
                                <Button className="Btn__Dark" disabled={loading} type="submit" label={'Submit'} />
                                <Button type="button" onClick={() => navigate(-1)} label="Cancel" className="Btn__Transparent" />
                            </React.Fragment>
                        )}
                        {type === 'edit' && (
                            <React.Fragment>
                                <Button className="Btn__Dark" disabled={loading} type="submit" label={'Update'} />
                                <Button type="button" onClick={() => navigate(-1)} label="Cancel" className="Btn__Transparent" />
                            </React.Fragment>
                        )}
                        {type === 'view' && <Button type="button" onClick={() => navigate(-1)} label="Okay" className="Btn__Dark" />}
                    </div>

                    {type === 'view' && getByIdData?.cmsPmdConfigId && <AuthorizationHistoryTable refTableId={getByIdData?.cmsPmdConfigId} tableName={'TBL_CMS_PMD_CONFIG'} />}

                    {/* Checker Action */}
                    {type == 'checkerEdit' && (
                        <React.Fragment>
                            <div className="card Card__Bg__Change col-12 md:col-12 flex">
                                <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                    <div className="p-field">
                                        <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                            Select Action <span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="action"
                                            placeholder="Select Action"
                                            options={statusLovData}
                                            optionLabel="name"
                                            name="action"
                                            optionValue="lovId"
                                            value={formik?.values?.action || ''}
                                            onChange={formik.handleChange}
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('action')}
                                    </div>
                                </div>

                                <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                    <div className="desc_Text_Area">
                                        <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                            Comments <span className="Label__Required">*</span>
                                        </label>
                                        <InputTextarea
                                            placeholder="-"
                                            inputid="checkerComments"
                                            name="checkerComments"
                                            rows={4}
                                            cols={30}
                                            onChange={formik.handleChange}
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'Input__Round')}
                                            value={formik?.values?.checkerComments}
                                        />
                                        {getFormErrorMessage('checkerComments')}
                                    </div>
                                </div>
                            </div>
                            <div className="Down__Btn py-3">
                                <Button disabled={loading} label="Update" className="Btn__Dark" />
                                <Button type="button" onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                            </div>
                            {urlData?.refTableId && urlData?.tableName && <AuthorizationHistoryTable refTableId={urlData?.refTableId} tableName={urlData?.tableName} />}
                        </React.Fragment>
                    )}
                    {type == 'checkerView' && (
                        <React.Fragment>
                            <div className="Down__Btn">
                                <Button type="button" onClick={() => navigate('/requestchecker')} label="Okay" className="Btn__Dark" />
                            </div>

                            {/* Action Authorization History */}

                            {urlData?.refTableId && urlData?.tableName && <AuthorizationHistoryTable refTableId={urlData?.refTableId} tableName={urlData?.tableName} />}
                        </React.Fragment>
                    )}
                </form>
            </div>
        </>
    );
}
export default DefaultComponent;

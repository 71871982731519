import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import OTPInput from 'react-otp-input';
import moment from 'moment';
import { handlePostRequest } from '../../services/PostTemplate';
import './../forgetpassword/Forget.scss';
import OtpSvg from '../../assets/images/otp.svg';

const VerifyOtp = () => {
    const [otp, setOtp] = useState('');
    const [otpId, setOtpId] = useState('');
    const [remainingTime, setRemainingTime] = useState(0);
    const [loginAttempts, setLoginAttempts] = useState(parseInt(sessionStorage.getItem('loginAttempts')) || 0);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fullUserDetails = sessionStorage.getItem('fullUserDetails');
    const userDetails = fullUserDetails ? JSON?.parse(fullUserDetails) : '';
    let expiryTime = userDetails ? userDetails?.expiryTime : '';

    const expiryTiming = () => {
        if (expiryTime > 0) {
            const internalTime = sessionStorage.getItem('expiryTime') * 1000;
            const endTime = Date.now() + internalTime;
            const timer = setInterval(() => {
                const timeLeft = endTime - Date.now();
                setRemainingTime(Math.max(0, Math.round(timeLeft / 1000)));
                sessionStorage.setItem('expiryTime', Math.max(0, Math.round(timeLeft / 1000)));
                if (timeLeft <= 0) {
                    clearInterval(timer);
                    setRemainingTime(0);
                }
            }, 1000);
        }
    };

    useEffect(() => {
        if (expiryTime && expiryTime > 0) {
            expiryTiming();
            setTimeout(() => {}, remainingTime);
        } else {
        }
    }, [expiryTime, remainingTime]); // eslint-disable-line react-hooks/exhaustive-deps

    const formik = useFormik({
        initialValues: {
            email: '',
            otp: ''
        },

        onSubmit: async () => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        email: userDetails?.tblUser?.email,
                        otpTypeCode: 'LGN',
                        otpId: otpId ? otpId : userDetails?.otpId,
                        otpPin: otp
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/mfs_user_management/v1/management/verifyotp', true, true));

            formik.resetForm();
            if (res?.responseCode === '0000') {
                sessionStorage.setItem('isNewUser', userDetails?.tblAppUser?.passwordUpdateFlag);
                sessionStorage.setItem('token', userDetails?.token);
                sessionStorage.setItem('expiryTime', '0');
                navigate('/');
                window.location.reload();
            }
        }
    });

    const resendOtpCode = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    email: userDetails?.tblUser?.email,
                    otpTypeCode: 'LGN'
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newData, '/mfs_user_management/v1/management/generateotp', true, true));

        formik.resetForm();
        if (res?.responseCode === '0000') {
            const updatedAttempts = loginAttempts + 1;
            setLoginAttempts(updatedAttempts);
            sessionStorage.setItem('loginAttempts', updatedAttempts.toString());
            setOtpId(res?.payLoad?.otpId);
            const newTime = moment(res?.payLoad?.effectiveTo) - moment(res?.payLoad?.effectiveFrom);
            // sessionStorage.setItem('expiryTime', res?.payLoad?.expiryTime);
            sessionStorage.setItem('expiryTime', newTime / 1000);
            expiryTime = newTime / 1000;
            expiryTiming();
        }
    };

    return (
        <div className="login-body">
            {/* <div className="login-image">
                <img src={bgImage} alt="atlantis" />
            </div> */}
            <div className="login-panel p-fluid centered__login">
                <form onSubmit={formik.handleSubmit} className="">
                    <div className="flex flex-column">
                        <div className="flex flex-column align-items-center justify-content-center mt-5 logo-container">
                            <p className="Signin__Heading mb-0">Verify OTP</p>
                            <img src={OtpSvg} alt="otp" className="my-3" />
                            <p className="Signin__Heading mb-0">Please Check Your Email</p>
                            <p className="text-white">We have sent a verification code to {userDetails?.tblUser?.email}</p>
                        </div>
                        <div className="verifyOTP">
                            <div className="otpContainer mt-4">
                                <OTPInput shouldAutoFocus value={otp} onChange={setOtp} numInputs={4} otpType="number" renderInput={(props) => <input {...props} />} />
                            </div>
                        </div>
                        <div className="flex justify-content-center align-items-center">
                            <p className="text-white mb-0">Didn't get the code?</p>
                            <Button
                                type="button"
                                className={`text-yellow-500 remiaingTimeButton ${remainingTime ? 'cursor-wait' : loginAttempts === 5 && remainingTime <= 0 ? 'disabledButtonNow' : 'cursor-pointer'}`}
                                onClick={() => {
                                    if (!remainingTime && loginAttempts !== 5) {
                                        resendOtpCode();
                                    }
                                }}
                                tooltip={loginAttempts === 5 && remainingTime <= 0 ? 'You have reached the maximum number of attempts' : remainingTime ? 'Please wait for the timer to expire' : ''}
                                tooltipOptions={{ position: 'top' }}
                            >
                                <u>Click to resend</u>
                            </Button>
                            {remainingTime > 0 && <p className={`clockTimer text-white`}>{remainingTime}</p>}
                        </div>
                        <div className="button-container mb-5 pb-3">
                            <Button type="submit" disabled={!otp ? true : false} label="Submit" className="Login__Button" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default VerifyOtp;

// import React, { useState, useRef, useEffect } from 'react';
// import { Button } from 'primereact/button';
// import { useFormik } from 'formik';
// import classNames from 'classnames';
// import * as Yup from 'yup';
// import { InputText } from 'primereact/inputtext';
// import { Checkbox } from 'primereact/checkbox';
// import { useDispatch } from 'react-redux';
// import { Dropdown } from 'primereact/dropdown';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { DataTable } from 'primereact/datatable';
// import { Column } from 'primereact/column';
// import { handleGetRequest } from '../../../../../../services/GetTemplate';

// function ViewTermLoanChecker({ activeIndex, handleStepClick, lendingData }) {
//     const [loading, setloading] = useState(false);
//     const [loadingIcon, setloadingIcon] = useState('pi pi-search');
//     //const [frequencyPeriodLov, setFrequencyPeriodLov] = useState([]);
//     const [fieldTableData, setFieldTableData] = useState([]);
//     const [frequencyPeriodData, setFrequencyPeriodData] = useState([]);
//     const [buttons, setButtons] = useState([
//         { label: 'Principal Amount', sequence: 1 },
//         { label: 'Markup', sequence: 2 },
//         { label: 'Fees', sequence: 3 }
//     ]);
//     const [valueBasedTableData, setValueBasedTableData] = useState([]);
//     const [valueBasedData, setValueBasedData] = useState({
//         frequencyId: '',
//         dueDate: ''
//     });

//     // const dispatch = useDispatch();
//     // const navigate = useNavigate();

//     let { search } = useLocation();
//     const query = new URLSearchParams(search);

//     const requestType = query.get('requestType');
//     const requestTypeDescr = query.get('requestTypeDescr');

//     useEffect(() => {
//         if (requestType === 'I' || (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request')) {
//             if (lendingData) {
//                 formik.setFieldValue('noOfDays', lendingData?.tblLmsRepaymentMethods[0]?.noOfDays);
//                 if (lendingData?.tblLmsRepaymentMethods?.length !== 0) {
//                     formik.setFieldValue('latePayment', true);
//                 } else {
//                     formik.setFieldValue('latePayment', false);
//                 }
//                 formik.setFieldValue('latePaymentFrequencyId', lendingData?.tblLmsRepaymentMethods[0]?.latePayment);
//                 formik.setFieldValue('frequencyPeriodName', lendingData?.tblLmsRepaymentMethods[0]?.lkpFrequencyPeriodLatePayment?.frequencyPeriodId.toString());
//                 formik.setFieldValue('pricing', lendingData?.tblLmsRepaymentMethods[0]?.latePayment);
//                 formik.setFieldValue('earlyPayment', lendingData?.tblLmsRepaymentMethods[0]?.earlyRepayment);
//                 formik.setFieldValue('earlyPaymentCharges', lendingData?.tblLmsRepaymentMethods[0]?.earlyRepaymentCharges);

//                 const extractedData = lendingData?.tblLmsRepaymentMethods[0]?.tblLmsRepaymentSchedules?.map((item) => ({
//                     dueDate: item.dueDate,
//                     frequencyId: item.lkpFrequencyPeriod.frequencyPeriodId.toString()
//                 }));
//                 setValueBasedTableData(extractedData);

//                 formik.setFieldValue('gracePeriod', lendingData?.tblLmsRepaymentMethods[0]?.gracePeriod === 'Y' ? true : false);

//                 formik.setFieldValue('noOfDaysGracePeriod', lendingData?.tblLmsRepaymentMethods[0]?.noOfGraceDays);

//                 if (lendingData) {
//                     if (lendingData?.tblLmsRepaymentMethods) {
//                         setValueBasedData(lendingData?.tblLmsRepaymentMethods);
//                     }
//                 }
//                 formik.setFieldValue('pricing', lendingData?.tblLmsRepaymentMethods[0]?.latePayment);
//                 formik.setFieldValue('charges', lendingData?.tblLmsRepaymentMethods[0]?.latePaymentCharges);

//                 formik.setFieldValue('downPayment', lendingData?.tblLmsRepaymentMethods[0]?.downPayment === 'Y' ? true : false);
//                 formik.setFieldValue('downPaymentCharges', lendingData?.tblLmsRepaymentMethods[0]?.downPaymentCharges);

//                 if (lendingData?.tblLmsRepaymentMethods[0]?.tblLmsRepaymentSequences) {
//                     const modifiedArray = lendingData?.tblLmsRepaymentMethods[0]?.tblLmsRepaymentSequences?.map((item) => {
//                         const { sequenceName, ...rest } = item;
//                         return { ...rest, label: sequenceName };
//                     });
//                     setButtons(modifiedArray);
//                 }
//             }
//         } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
//             if (lendingData[2]) {
//                 formik.setFieldValue('noOfDays', lendingData[2]?.noOfDays);
//                 formik.setFieldValue('gracePeriod', lendingData[2]?.gracePeriod === true ? true : false);
//                 formik.setFieldValue('noOfDaysGracePeriod', lendingData[2]?.noOfDaysGracePeriod);
//                 formik.setFieldValue('latePayment', lendingData[2]?.latePayment);
//                 formik.setFieldValue('latePaymentFrequencyId', lendingData[2]?.latePaymentFrequencyId.toString());
//                 formik.setFieldValue('pricing', lendingData[2]?.pricing.toString());
//                 formik.setFieldValue('charges', lendingData[2]?.charges);
//                 const extractedData = lendingData[2]?.lmsProductScheduleRequests?.map((item) => ({
//                     dueDate: item.dueDate,
//                     frequencyId: item.frequencyId.toString()
//                 }));
//                 setValueBasedTableData(extractedData);
//                 formik.setFieldValue('earlyPayment', lendingData[2]?.earlyPayment);
//                 formik.setFieldValue('earlyPaymentCharges', lendingData[2]?.earlyPaymentCharges);
//                 formik.setFieldValue('downPayment', lendingData[2]?.downPayment);
//                 formik.setFieldValue('downPaymentCharges', lendingData[2]?.downPaymentCharges);
//             }
//         }
//     }, [lendingData]);

//     const validationSchema = Yup.object().shape({
//         // campaignName: Yup.mixed().required('This field is required.'),
//     });

//     const formik = useFormik({
//         validationSchema: validationSchema,
//         initialValues: {
//             frequencyPeriodName: '',
//             noOfDays: '',
//             noOfDaysGracePeriod: '',
//             gracePeriod: ''
//         },

//         // validate: (data) => {
//         //     let errors = {};
//         //     return errors;
//         // },

//         onSubmit: async (data) => {
//             setloading(true);
//             setloadingIcon('pi pi-spin pi-spinner');

//             handleStepClick(activeIndex + 1);

//             setloading(false);
//             setloadingIcon('pi pi-search');
//         }
//     });

//     const handledDeleteFieldsData = (e, rowData) => {
//         e.preventDefault();
//         const newFilterData = fieldTableData.filter((value) => value.fieldName !== rowData.fieldName);
//         setFieldTableData(newFilterData);
//     };
//     const actionFieldsBodyTemplate = (rowData) => {
//         return (
//             <>
//                 <div className="actions">
//                     <Button tooltip="Delete" disabled tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteFieldsData(e, rowData)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
//                 </div>
//             </>
//         );
//     };
//     const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

//     const getFormErrorMessage = (name) => {
//         return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
//     };

//     const handleDragStart = (e, label) => {
//         e.dataTransfer.setData('text/plain', label);
//     };

//     const handleDragOver = (e) => {
//         e.preventDefault();
//     };

//     const handleDrop = (e, targetLabel) => {
//         e.preventDefault();

//         const draggedLabel = e.dataTransfer.getData('text/plain');
//         const updatedButtons = [...buttons];

//         const draggedButton = updatedButtons.find((button) => button.label === draggedLabel);
//         const targetButton = updatedButtons.find((button) => button.label === targetLabel);

//         if (draggedButton && targetButton) {
//             // Swap the sequence numbers
//             const tempSequence = draggedButton.sequence;
//             draggedButton.sequence = targetButton.sequence;
//             targetButton.sequence = tempSequence;

//             // Sort buttons based on sequence
//             updatedButtons.sort((a, b) => a.sequence - b.sequence);

//             setButtons(updatedButtons);
//         }
//     };

//     const handledValueBasedAdd = (e) => {
//         e.preventDefault();
//         const errors = {
//             frequencyId: '',
//             dueDate: ''
//         };
//         if (Object.values(errors).every((error) => error === '')) {
//             const newValueBasedTableData = {
//                 frequencyId: valueBasedData?.frequencyId,
//                 dueDate: valueBasedData?.dueDate
//             };
//             setValueBasedTableData([...valueBasedTableData, newValueBasedTableData]);
//             setValueBasedData({
//                 frequencyId: '',
//                 dueDate: ''
//             });
//         }
//     };

//     const handledValueBasedData = (e) => {
//         e.preventDefault();
//         const name = e.target.name;
//         const value = e.target.value;

//         setValueBasedData({ ...valueBasedData, [name]: value });
//     };

//     const getFrequencyPeriodData = async () => {
//         const response = await handleGetRequest('/configuration/v1/lookups/getdatalov/LKP_FREQUENCY_PERIOD');
//         setFrequencyPeriodData(response?.payLoad);
//     };

//     useEffect(() => {
//         getFrequencyPeriodData();
//     }, []);

//     const downPaymentLov = [
//         {
//             name: 'Yes',
//             lovId: true
//         },
//         {
//             name: 'No',
//             lovId: false
//         }
//     ];

//     const earlyRepaymentLov = [
//         {
//             name: 'Define type',
//             lovId: 'C'
//         },
//         {
//             name: 'Percentage',
//             lovId: 'P'
//         }
//     ];
//     const pricingLov = [
//         {
//             name: 'Principal Account',
//             lovId: '1'
//         },
//         {
//             name: 'Markup',
//             lovId: '2'
//         },
//         {
//             name: 'Fees',
//             lovId: '3'
//         }
//     ];

//     const frequencyBodyTemplate = (rowData) => {
//         const singleFrequency = frequencyPeriodData?.filter((item) => item?.lovId === rowData?.frequencyId);
//         return <p>{singleFrequency[0]?.name}</p>;
//     };

//     return (
//         <>
//             <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
//                 <h1 style={{ color: '#fff', marginLeft: '10px' }}>REPAYMENT METHOD/ VIEW DETAILS</h1>
//             </div>
//             <div className="card Card__Round">
//                 <div className="Form__Header">
//                     <h1>LOAN TENURE </h1>
//                 </div>
//                 <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
//                     <div className="p-fluid p-formgrid grid mb-5">
//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <label htmlFor="noOfDays" className={classNames({ 'p-error': isFormFieldValid('noOfDays') }, 'Label__Text')}>
//                                     Number of Days <span className="Label__Required"></span>
//                                 </label>
//                                 <InputText
//                                     id="noOfDays"
//                                     placeholder="Enter Number of Days"
//                                     name="noOfDays"
//                                     disabled={true}
//                                     value={formik?.values?.noOfDays || ''}
//                                     onChange={formik.handleChange}
//                                     className={classNames({ 'p-invalid': isFormFieldValid('noOfDays') }, 'Input__Round')}
//                                 />
//                                 {getFormErrorMessage('noOfDays')}
//                             </div>
//                         </div>
//                         <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
//                             <div className="p-field">
//                                 <div className="field-checkbox">
//                                     <Checkbox inputId="gracePeriod" disabled={true} name="gracePeriod" value={formik?.values?.gracePeriod} checked={formik?.values?.gracePeriod} onChange={formik.handleChange} style={{ marginTop: '10px' }} />
//                                     <label htmlFor="gracePeriod" className={classNames({ 'p-error': isFormFieldValid('gracePeriod') }, 'Label__Text')} style={{ marginTop: '10px' }}>
//                                         Grace Period
//                                     </label>
//                                 </div>
//                                 {getFormErrorMessage('gracePeriod')}
//                             </div>
//                         </div>
//                         {formik?.values?.gracePeriod === true && (
//                             <div style={{ backgroundColor: '#edf0f5', padding: '2rem' }} className=" col-12 card">
//                                 <div className="Form__Header">
//                                     <h1>GRACE PERIOD </h1>
//                                 </div>
//                                 <div className="grid">
//                                     <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                                         <div className="p-field">
//                                             <label htmlFor="noOfDaysGracePeriod" className={classNames({ 'p-error': isFormFieldValid('noOfDaysGracePeriod') }, 'Label__Text')}>
//                                                 Number of Days <span className="Label__Required"></span>
//                                             </label>
//                                             <InputText
//                                                 id="noOfDaysGracePeriod"
//                                                 placeholder="Enter Number of Days"
//                                                 name="noOfDaysGracePeriod"
//                                                 disabled={true}
//                                                 value={formik?.values?.noOfDaysGracePeriod || ''}
//                                                 onChange={formik.handleChange}
//                                                 className={classNames({ 'p-invalid': isFormFieldValid('noOfDaysGracePeriod') }, 'Input__Round')}
//                                             />
//                                             {getFormErrorMessage('noOfDaysGracePeriod')}
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         )}

//                         <div className="Form__Header col-12">
//                             <h1>LATE PAYMENT </h1>
//                         </div>
//                     </div>

//                     <div className="p-field">
//                         <div className="field-checkbox">
//                             <Checkbox inputId="latePayment" disabled={true} name="latePayment" value={formik?.values?.latePayment} checked={formik?.values?.latePayment} onChange={formik.handleChange} style={{ marginTop: '10px' }} />
//                             <label htmlFor="latePayment" className={classNames({ 'p-error': isFormFieldValid('latePayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
//                                 Late Payment
//                             </label>
//                         </div>
//                         {getFormErrorMessage('latePayment')}
//                     </div>
//                     {formik?.values?.latePayment === true && (
//                         <div className="grid">
//                             <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                                 <div className="p-field">
//                                     <label htmlFor="latePaymentFrequencyId" className={classNames({ 'p-error': isFormFieldValid('latePaymentFrequencyId') }, 'Label__Text')}>
//                                         Late Payment Frequency<span className="Label__Required"></span>
//                                     </label>
//                                     <Dropdown
//                                         id="latePaymentFrequencyId"
//                                         placeholder="Select Frequency of Payment"
//                                         options={frequencyPeriodData}
//                                         optionLabel="name"
//                                         disabled={true}
//                                         name="latePaymentFrequencyId"
//                                         filter
//                                         optionValue="lovId"
//                                         value={formik?.values?.latePaymentFrequencyId || ''}
//                                         onChange={formik.handleChange}
//                                         className={classNames({ 'p-invalid': isFormFieldValid('latePaymentFrequencyId') }, 'Dropdown__Round')}
//                                     />

//                                     {getFormErrorMessage('latePaymentFrequencyId')}
//                                 </div>
//                             </div>
//                             <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                                 <div className="p-field">
//                                     <label htmlFor="pricing" className={classNames({ 'p-error': isFormFieldValid('pricing') }, 'Label__Text')}>
//                                         Late Payment<span className="Label__Required"></span>
//                                     </label>
//                                     <Dropdown
//                                         id="pricing"
//                                         placeholder="Select Late Payment"
//                                         options={pricingLov}
//                                         optionLabel="name"
//                                         disabled={true}
//                                         name="pricing"
//                                         filter
//                                         optionValue="lovId"
//                                         value={formik?.values?.pricing || ''}
//                                         onChange={formik.handleChange}
//                                         className={classNames({ 'p-invalid': isFormFieldValid('pricing') }, 'Dropdown__Round')}
//                                     />

//                                     {getFormErrorMessage('pricing')}
//                                 </div>
//                             </div>

//                             <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                                 <div className="p-field">
//                                     <label htmlFor="charges" className={classNames({ 'p-error': isFormFieldValid('charges') }, 'Label__Text')}>
//                                         Charges <span className="Label__Required"></span>
//                                     </label>
//                                     <InputText
//                                         id="charges"
//                                         placeholder="Enter charges"
//                                         name="charges"
//                                         disabled={true}
//                                         value={formik?.values?.charges || ''}
//                                         onChange={formik.handleChange}
//                                         className={classNames({ 'p-invalid': isFormFieldValid('charges') }, 'Input__Round')}
//                                     />
//                                     {getFormErrorMessage('charges')}
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                     <div className="Form__Header">
//                         <h1>REPAYMENT SCHEDULE </h1>
//                     </div>
//                     <div className="grid">
//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <label htmlFor="frequencyPeriodId" className={classNames({ 'p-error': isFormFieldValid('frequencyPeriodId') }, 'Label__Text')}>
//                                     Frequency of Payment<span className="Label__Required"></span>
//                                 </label>
//                                 <Dropdown
//                                     id="frequencyPeriodId"
//                                     placeholder="Select Frequency of Payment"
//                                     options={frequencyPeriodData}
//                                     optionLabel="name"
//                                     name="frequencyPeriodId"
//                                     filter
//                                     disabled={true}
//                                     optionValue="lovId"
//                                     value={valueBasedData?.frequencyPeriodId || ''}
//                                     onChange={handledValueBasedData}
//                                     className={classNames({ 'p-invalid': isFormFieldValid('frequencyPeriodId') }, 'Dropdown__Round')}
//                                 />

//                                 {getFormErrorMessage('frequencyPeriodId')}
//                             </div>
//                         </div>
//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <label htmlFor="dueDate" className={classNames({ 'p-error': isFormFieldValid('dueDate') }, 'Label__Text')}>
//                                     Due Date <span className="Label__Required"></span>
//                                 </label>
//                                 <InputText
//                                     id="dueDate"
//                                     placeholder="Enter Number of Days"
//                                     type="Date"
//                                     name="dueDate"
//                                     disabled={true}
//                                     value={valueBasedData?.dueDate || ''}
//                                     onChange={handledValueBasedData}
//                                     className={classNames({ 'p-invalid': isFormFieldValid('dueDate') }, 'Input__Round')}
//                                 />
//                                 {getFormErrorMessage('dueDate')}
//                             </div>
//                         </div>
//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <div className="Down__Btn" style={{ justifyContent: 'flex-start' }}>
//                                     <Button disabled label="Add" className="Btn__Dark" style={{ marginTop: '15px' }} onClick={handledValueBasedAdd} />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="grid">
//                         <div className="col-12">
//                             <div className="card">
//                                 <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={valueBasedTableData}>
//                                     <Column body={frequencyBodyTemplate} header="Frequency of Payment" />
//                                     <Column field="dueDate" header="Due Date" />
//                                     <Column header="Actions" body={actionFieldsBodyTemplate} className="Table__ActionHeading__CentredAlign" />
//                                 </DataTable>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="Form__Header">
//                         <h1>ALLOW EARLY REPAYMENT </h1>
//                     </div>
//                     <div className="grid">
//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <label htmlFor="earlyPayment" className={classNames({ 'p-error': isFormFieldValid('earlyPayment') }, 'Label__Text')}>
//                                     Early Repayment<span className="Label__Required"></span>
//                                 </label>
//                                 <Dropdown
//                                     id="earlyPayment"
//                                     placeholder="Select Early Repayment"
//                                     options={earlyRepaymentLov}
//                                     optionLabel="name"
//                                     name="earlyPayment"
//                                     disabled={true}
//                                     filter
//                                     optionValue="lovId"
//                                     value={formik?.values?.earlyPayment || ''}
//                                     onChange={formik.handleChange}
//                                     className={classNames({ 'p-invalid': isFormFieldValid('earlyPayment') }, 'Dropdown__Round')}
//                                 />

//                                 {getFormErrorMessage('earlyPayment')}
//                             </div>
//                         </div>
//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <label htmlFor="earlyPaymentCharges" className={classNames({ 'p-error': isFormFieldValid('earlyPaymentCharges') }, 'Label__Text')}>
//                                     Charges <span className="Label__Required"></span>
//                                 </label>
//                                 <InputText
//                                     id="earlyPaymentCharges"
//                                     placeholder="Enter Charges"
//                                     name="earlyPaymentCharges"
//                                     disabled={true}
//                                     value={formik?.values?.earlyPaymentCharges || ''}
//                                     onChange={formik.handleChange}
//                                     className={classNames({ 'p-invalid': isFormFieldValid('earlyPaymentCharges') }, 'Input__Round')}
//                                 />
//                                 {getFormErrorMessage('earlyPaymentCharges')}
//                             </div>
//                         </div>
//                     </div>
//                     <div className="Form__Header">
//                         <h1>DOWN PAYMENT </h1>
//                     </div>
//                     <div className="grid">
//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <label htmlFor="downPayment" className={classNames({ 'p-error': isFormFieldValid('downPayment') }, 'Label__Text')}>
//                                     Select Down Payment<span className="Label__Required"></span>
//                                 </label>
//                                 <Dropdown
//                                     id="downPayment"
//                                     placeholder="Select Down Payment"
//                                     options={downPaymentLov}
//                                     optionLabel="name"
//                                     name="downPayment"
//                                     disabled={true}
//                                     filter
//                                     optionValue="lovId"
//                                     value={formik?.values?.downPayment || ''}
//                                     onChange={formik.handleChange}
//                                     className={classNames({ 'p-invalid': isFormFieldValid('downPayment') }, 'Dropdown__Round')}
//                                 />

//                                 {getFormErrorMessage('downPayment')}
//                             </div>
//                         </div>
//                         {formik?.values?.downPayment === true && (
//                             <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                                 <div className="p-field">
//                                     <label htmlFor="downPaymentCharges" className={classNames({ 'p-error': isFormFieldValid('downPaymentCharges') }, 'Label__Text')}>
//                                         Charges <span className="Label__Required"></span>
//                                     </label>
//                                     <InputText
//                                         id="downPaymentCharges"
//                                         placeholder="Enter downPaymentCharges"
//                                         name="downPaymentCharges"
//                                         disabled={true}
//                                         value={formik?.values?.downPaymentCharges || ''}
//                                         onChange={formik.handleChange}
//                                         className={classNames({ 'p-invalid': isFormFieldValid('downPaymentCharges') }, 'Input__Round')}
//                                     />
//                                     {getFormErrorMessage('downPaymentCharges')}
//                                 </div>
//                             </div>
//                         )}
//                     </div>

//                     <div className="Form__Header">
//                         <h1>DEFINE REPAYMENT SEQUENCE </h1>
//                     </div>
//                     {/* <div className="Centre_Aligned_Buttons">
//                         <Button className="Light_Button" label="Principal Amount" style={{ cursor: "grabbing" }} onClick={(e) => handlePricipalAmount()} />
//                         <Button className="DarkGray_Button" label="Markup" style={{ cursor: "grabbing" }} onClick={(e) => handleMarkup(e)} />
//                         <Button className="DarkGray_Button" label="Fees" style={{ cursor: "grabbing" }} onClick={(e) => handleFee(e)} />
//                     </div> */}
//                     <div className="Centre_Aligned_Buttons" onDragOver={handleDragOver}>
//                         {buttons.map((button) => (
//                             <Button key={button.label} className="DraggableButton Light_Button" label={`${button.label}`} draggable disabled={true} onDragStart={(e) => handleDragStart(e, button.label)} onDrop={(e) => handleDrop(e, button.label)} />
//                         ))}
//                     </div>
//                     {/* <div className="Down__Btn">
//                         <Button disabled={loading} loadingIcon={loadingIcon} style={{ margin: "20px" }} label={parseInt(lendingData?.currentStep) >= 2 ? "Edit" : "Save"} className="Btn__Dark" />
//                     </div> */}
//                 </form>
//             </div>
//         </>
//     );
// }
// export default ViewTermLoanChecker;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useDispatch } from 'react-redux';
import { handleGetRequest } from '../../../../../../services/GetTemplate';
import { useLocation } from 'react-router';

function ViewTermLoanChecker({ activeIndex, handleStepClick, lendingData }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [frequencyPeriodData, setFrequencyPeriodData] = useState([]);

    /////////////////////////////////////////
    const [termLoanTableData, setTermLoanTableData] = useState([]);
    const [termLoanData, setTermLoanData] = useState({
        noOfDays: '',
        markupValue: '',
        apr: '',
        salaryMultiplier: '',

        gracePeriod: '',
        noOfDaysGracePeriod: '',

        latePayment: '',
        latePaymentType: '',
        latePaymentCharges: '',
        latePaymentOn: '',

        frequencyId: '',

        earlyPayment: '',
        earlyPaymentType: '',
        earlyPaymentCharges: '',
        earlyPaymentOn: '',

        downPayment: '',
        downPaymentCharges: ''
    });
    const [termLoanError, setTermLoanError] = useState({
        noOfDays: '',
        markupValue: '',
        apr: '',
        salaryMultiplier: '',

        gracePeriod: '',
        noOfDaysGracePeriod: '',

        latePayment: '',
        latePaymentType: '',
        latePaymentCharges: '',
        latePaymentOn: '',

        frequencyId: '',

        earlyPayment: '',
        earlyPaymentType: '',
        earlyPaymentCharges: '',
        earlyPaymentOn: '',

        downPayment: '',
        downPaymentCharges: ''
    });
    //////////////////////////////

    const [buttons, setButtons] = useState([
        { label: 'Principal Amount', sequence: 1 },
        { label: 'Markup', sequence: 2 },
        { label: 'Fees', sequence: 3 }
    ]);

    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const requestType = query.get('requestType');
    const requestTypeDescr = query.get('requestTypeDescr');

    useEffect(() => {
        if (requestType === 'I' || (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request')) {
            if (lendingData) {
                setTermLoanTableData(lendingData?.tblLmsRepaymentMethods?.filter((value) => value?.isActive === 'Y'));
            }
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            if (lendingData[2]) {
                setTermLoanTableData(lendingData[2]?.lmsProductRepaymentRequests);
            }
        }
    }, [lendingData]); // eslint-disable-line

    const getFrequencyPeriodData = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getdatalov/LKP_FREQUENCY_PERIOD');
        setFrequencyPeriodData(response?.payLoad);
    };

    useEffect(() => {
        getFrequencyPeriodData();
    }, []); // eslint-disable-line

    const validationSchema = Yup.object().shape({});

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {},

        validate: (data) => {},

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    // const handleDragStart = (e, label) => {
    //     e.dataTransfer.setData('text/plain', label);
    // };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    // const handleDrop = (e, targetLabel) => {
    //     e.preventDefault();

    //     const draggedLabel = e.dataTransfer.getData('text/plain');
    //     const updatedButtons = [...buttons];

    //     const draggedButton = updatedButtons.find((button) => button.label === draggedLabel);
    //     const targetButton = updatedButtons.find((button) => button.label === targetLabel);

    //     if (draggedButton && targetButton) {
    //         // Swap the sequence numbers
    //         const tempSequence = draggedButton.sequence;
    //         draggedButton.sequence = targetButton.sequence;
    //         targetButton.sequence = tempSequence;

    //         // Sort buttons based on sequence
    //         updatedButtons.sort((a, b) => a.sequence - b.sequence);

    //         setButtons(updatedButtons);
    //     }
    // };

    const downPaymentLov = [
        {
            name: 'Yes',
            lovId: 'Y'
        },
        {
            name: 'No',
            lovId: 'N'
        }
    ];

    // const frequencyBodyTemplate = (rowData) => {
    //     const singleFrequency = frequencyPeriodData?.filter((item) => item?.lovId == rowData?.frequencyId);
    //     return <p>{singleFrequency[0]?.name}</p>;
    // };

    //////////////////////////new UI code /////////////////////
    const loanTenureNOD = [
        { name: '90 Days', lovId: '90' },
        { name: '180 Days', lovId: '180' },
        { name: '270 Days', lovId: '270' },
        { name: '360 Days', lovId: '360' }
    ];

    const typeLov = [
        { name: 'FIXED', lovId: 'F' },
        { name: 'PERCENTAGE', lovId: 'P' }
    ];

    const paymentOnLov = [
        { name: 'Outstanding Principal', lovId: 'O' },
        { name: 'Markup', lovId: 'M' }
    ];

    /////////////////////////////////////////////////////////////
    const actionBodyTemplate = (rowData, rowIndex) => {
        return (
            <>
                <Button disabled tooltip="Edit" tooltipOptions={{ position: 'top' }} icon="pi pi-pencil" onClick={(e) => handledEditData(e, rowData, rowIndex)} className="p-button-rounded p-button-primary mr-1" style={{ border: 'none' }} />
                <Button disabled tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteData(e, rowData, rowIndex)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
            </>
        );
    };

    const handledDeleteData = (e, rowData, rowIndex) => {
        e.preventDefault();
        const newFilterData = termLoanTableData?.filter((value, index) => index !== rowIndex?.rowIndex);
        setTermLoanTableData(newFilterData);
    };

    const handledEditData = (e, rowData, rowIndex) => {
        e.preventDefault();
        const newFilterData = termLoanTableData?.filter((value, index) => index !== rowIndex?.rowIndex);
        setTermLoanData(newFilterData);
    };

    const handledAdd = (e) => {
        e.preventDefault();

        const errors = {
            noOfDays: '',
            markupValue: '',
            apr: '',
            salaryMultiplier: '',

            gracePeriod: '',
            noOfDaysGracePeriod: '',

            latePayment: '',
            latePaymentType: '',
            latePaymentCharges: '',
            latePaymentOn: '',

            frequencyId: '',

            earlyPayment: '',
            earlyPaymentType: '',
            earlyPaymentCharges: '',
            earlyPaymentOn: '',

            downPayment: '',
            downPaymentCharges: '',

            seqPrincipalAmount: '',
            seqMarkup: '',
            seqLateFee: ''
        };

        if (termLoanData?.noOfDays === '') {
            errors.noOfDays = 'This field is required.';
        }

        if (termLoanData?.markupValue === '') {
            errors.markupValue = 'This field is required.';
        }

        if (termLoanData?.apr === '') {
            errors.apr = 'This field is required.';
        }

        if (termLoanData?.salaryMultiplier === '') {
            errors.salaryMultiplier = 'This field is required.';
        }

        if (termLoanData?.gracePeriod === true) {
            if (termLoanData?.noOfDaysGracePeriod === '') {
                errors.noOfDaysGracePeriod = 'This field is required';
            }
        }

        if (termLoanData?.latePayment === true) {
            if (termLoanData?.latePaymentType === '') {
                errors.latePaymentType = 'This field is required';
            }

            if (termLoanData?.latePaymentCharges === '') {
                errors.latePaymentCharges = 'This field is required';
            }

            if (termLoanData?.latePaymentOn === '') {
                errors.latePaymentOn = 'This field is required';
            }
        }

        if (termLoanData?.frequencyId === '') {
            errors.frequencyId = 'This field is required';
        }

        if (termLoanData?.earlyPayment === true) {
            if (termLoanData?.earlyPaymentType === '') {
                errors.earlyPaymentType = 'This field is required';
            }

            if (termLoanData?.earlyPaymentCharges === '') {
                errors.earlyPaymentCharges = 'This field is required';
            }

            if (termLoanData?.earlyPaymentOn === '') {
                errors.earlyPaymentOn = 'This field is required';
            }
        }

        if (termLoanData?.downPayment === 'Y') {
            if (termLoanData?.downPaymentCharges === '') {
                errors.downPaymentCharges = 'This field is required';
            }
        }

        setTermLoanError(errors);

        const sequenceObject = buttons?.reduce((result, item) => {
            const label = item.label.replace(/\s+/g, ''); // Remove spaces from label
            const seqKey = `seq${label}`;
            result[seqKey] = item.sequence;
            return result;
        }, {});

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            const newTermLoanTableData = {
                noOfDays: termLoanData?.noOfDays,
                markupValue: termLoanData?.markupValue,
                apr: termLoanData?.apr,
                salaryMultiplier: termLoanData?.salaryMultiplier,

                gracePeriod: termLoanData?.gracePeriod,
                noOfDaysGracePeriod: termLoanData?.noOfDaysGracePeriod,

                latePayment: termLoanData?.latePayment,
                latePaymentType: termLoanData?.latePaymentType,
                latePaymentCharges: termLoanData?.latePaymentCharges,
                latePaymentOn: termLoanData?.latePaymentOn,

                frequencyId: termLoanData?.frequencyId,

                earlyPayment: termLoanData?.earlyPayment,
                earlyPaymentType: termLoanData?.earlyPaymentType,
                earlyPaymentCharges: termLoanData?.earlyPaymentCharges,
                earlyPaymentOn: termLoanData?.earlyPaymentOn,

                downPayment: termLoanData?.downPayment,
                downPaymentCharges: termLoanData?.downPaymentCharges,

                repaymentTypeCode: lendingData?.lkpLmsFacilityType?.lmsFacilityTypeCode
            };

            const combinedObject = { ...newTermLoanTableData, ...sequenceObject };
            setTermLoanTableData([...termLoanTableData, combinedObject]);

            setTermLoanData({
                noOfDays: '',
                markupValue: '',
                apr: '',
                salaryMultiplier: '',

                gracePeriod: '',
                noOfDaysGracePeriod: '',

                latePayment: '',
                latePaymentType: '',
                latePaymentCharges: '',
                latePaymentOn: '',

                frequencyId: '',

                earlyPayment: '',
                earlyPaymentType: '',
                earlyPaymentCharges: '',
                earlyPaymentOn: '',

                downPayment: '',
                downPaymentCharges: ''
            });

            setButtons([
                { label: 'Principal Amount', sequence: 1 },
                { label: 'Markup', sequence: 2 },
                { label: 'Fees', sequence: 3 }
            ]);
        }
    };

    const handledData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        const checked = e.target.checked;
        const type = e.target.type;

        setTermLoanData({ ...termLoanData, [name]: type === 'checkbox' ? checked : value });

        // Clear the error message for the field being edited
        setTermLoanError({
            ...termLoanError,
            [name]: ''
        });
    };
    //////////////////////////////////////////////////////////

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>REPAYMENT METHOD/ADD NEW</h1>
            </div>
            <div className="card Card__Round">
                <div className="Form__Header flex align-items-center justify-content-between">
                    <h1>LOAN TENURE </h1>
                    <Button icon="pi pi-plus" className="p-button-rounded" aria-label="Filter" tooltip="Add New Repayment" disabled onClick={(e) => handledAdd(e)} />
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="noOfDays" className={classNames({ 'p-error': isFormFieldValid('noOfDays') }, 'Label__Text')}>
                                    No. Of Days<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="noOfDays"
                                    placeholder="Select No. Of Days"
                                    options={loanTenureNOD}
                                    optionLabel="name"
                                    name="noOfDays"
                                    disabled={true}
                                    filter
                                    optionValue="lovId"
                                    value={termLoanData?.noOfDays || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('noOfDays') }, 'Dropdown__Round')}
                                />
                                <small className="p-error">{termLoanError?.noOfDays}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="markupValue" className={classNames({ 'p-error': isFormFieldValid('markupValue') }, 'Label__Text')}>
                                    Markup value <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={4}
                                    id="markupValue"
                                    placeholder="Enter Markup Value"
                                    name="markupValue"
                                    disabled={true}
                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                    value={termLoanData?.markupValue || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('markupValue') }, 'Input__Round')}
                                />
                                <small className="p-error">{termLoanError?.markupValue}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="apr" className={classNames({ 'p-error': isFormFieldValid('apr') }, 'Label__Text')}>
                                    APR <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="apr"
                                    placeholder="Enter APR"
                                    name="apr"
                                    maxLength={4}
                                    disabled={true}
                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                    value={termLoanData?.apr || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('apr') }, 'Input__Round')}
                                />
                                <small className="p-error">{termLoanError?.apr}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="salaryMultiplier" className={classNames({ 'p-error': isFormFieldValid('salaryMultiplier') }, 'Label__Text')}>
                                    Salary Multiplier <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="salaryMultiplier"
                                    placeholder="Enter Salary Multiplier"
                                    name="salaryMultiplier"
                                    maxLength={4}
                                    disabled={true}
                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                    value={termLoanData?.salaryMultiplier || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('salaryMultiplier') }, 'Input__Round')}
                                />
                                <small className="p-error">{termLoanError?.salaryMultiplier}</small>
                            </div>
                        </div>
                    </div>
                    <div className="Form__Header col-12">
                        <h1>GRACE PERIOD </h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="gracePeriod" name="gracePeriod" value={termLoanData?.gracePeriod} checked={termLoanData?.gracePeriod} onChange={handledData} style={{ marginTop: '10px' }} disabled />
                                    <label htmlFor="gracePeriod" className={classNames({ 'p-error': isFormFieldValid('gracePeriod') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Grace Period
                                    </label>
                                </div>
                                <small className="p-error">{termLoanError?.gracePeriod}</small>
                            </div>
                        </div>
                        {termLoanData?.gracePeriod === true && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="noOfDaysGracePeriod" className={classNames({ 'p-error': isFormFieldValid('noOfDaysGracePeriod') }, 'Label__Text')}>
                                        Number of Days <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={4}
                                        id="noOfDaysGracePeriod"
                                        placeholder="Enter Number of Days"
                                        name="noOfDaysGracePeriod"
                                        disabled={true}
                                        keyfilter={'int'}
                                        value={formik?.values?.noOfDaysGracePeriod || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('noOfDaysGracePeriod') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{termLoanError?.noOfDaysGracePeriod}</small>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="Form__Header col-12">
                        <h1>LATE PAYMENT </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="latePayment" name="latePayment" value={termLoanData?.latePayment} checked={termLoanData?.latePayment} onChange={handledData} disabled style={{ marginTop: '10px' }} />
                                    <label htmlFor="latePayment" className={classNames({ 'p-error': isFormFieldValid('latePayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Late payment
                                    </label>
                                </div>
                                <small className="p-error">{termLoanError?.latePayment}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        {termLoanData?.latePayment === true && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentType" className={classNames({ 'p-error': isFormFieldValid('latePaymentType') }, 'Label__Text')}>
                                            Type<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="latePaymentType"
                                            placeholder="Select Type"
                                            options={typeLov}
                                            optionLabel="name"
                                            name="latePaymentType"
                                            filter
                                            disabled={true}
                                            optionValue="lovId"
                                            value={termLoanData?.latePaymentType || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('latePaymentType') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{termLoanError?.latePaymentType}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentCharges" className={classNames({ 'p-error': isFormFieldValid('latePaymentCharges') }, 'Label__Text')}>
                                            Charges <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="latePaymentCharges"
                                            placeholder="Enter charges"
                                            name="latePaymentCharges"
                                            maxLength={8}
                                            disabled={true}
                                            keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                            value={termLoanData?.latePaymentCharges || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('latePaymentCharges') }, 'Input__Round')}
                                        />
                                        <small className="p-error">{termLoanError?.latePaymentCharges}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentOn" className={classNames({ 'p-error': isFormFieldValid('latePaymentOn') }, 'Label__Text')}>
                                            Late Payment On<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="latePaymentOn"
                                            placeholder="Select Late Payment On"
                                            options={paymentOnLov}
                                            optionLabel="name"
                                            name="latePaymentOn"
                                            disabled={true}
                                            filter
                                            optionValue="lovId"
                                            value={termLoanData?.latePaymentOn || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('latePaymentOn') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{termLoanError?.latePaymentOn}</small>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {/* //////////////////////////////////////////////////// */}
                    <div className="Form__Header col-12">
                        <h1>REPAYMENT SCHEDULE </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="frequencyId" className={classNames({ 'p-error': isFormFieldValid('frequencyId') }, 'Label__Text')}>
                                    Frequency Of Payment<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="frequencyId"
                                    placeholder="Select Frequency Of Payment"
                                    options={frequencyPeriodData}
                                    optionLabel="name"
                                    name="frequencyId"
                                    disabled={true}
                                    filter
                                    optionValue="lovId"
                                    value={termLoanData?.frequencyId || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('frequencyId') }, 'Dropdown__Round')}
                                />
                                <small className="p-error">{termLoanError?.frequencyId}</small>
                            </div>
                        </div>
                    </div>
                    {/* ///////////////////////////////////////////////////// */}

                    <div className="Form__Header col-12">
                        <h1>ALLOW EARLY REPAYMENT </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="earlyPayment" name="earlyPayment" value={termLoanData?.earlyPayment} checked={termLoanData?.earlyPayment} onChange={handledData} disabled style={{ marginTop: '10px' }} />
                                    <label htmlFor="earlyPayment" className={classNames({ 'p-error': isFormFieldValid('earlyPayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Early Payment
                                    </label>
                                </div>
                                <small className="p-error">{termLoanError?.earlyPayment}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        {termLoanData?.earlyPayment === true && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="earlyPaymentType" className={classNames({ 'p-error': isFormFieldValid('earlyPaymentType') }, 'Label__Text')}>
                                            Type<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="earlyPaymentType"
                                            placeholder="Select Type"
                                            options={typeLov}
                                            optionLabel="name"
                                            disabled={true}
                                            name="earlyPaymentType"
                                            filter
                                            optionValue="lovId"
                                            value={termLoanData?.earlyPaymentType || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('earlyPaymentType') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{termLoanError?.earlyPaymentType}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="earlyPaymentCharges" className={classNames({ 'p-error': isFormFieldValid('earlyPaymentCharges') }, 'Label__Text')}>
                                            Charges <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="earlyPaymentCharges"
                                            placeholder="Enter charges"
                                            name="earlyPaymentCharges"
                                            maxLength={8}
                                            disabled={true}
                                            keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                            value={termLoanData?.earlyPaymentCharges || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('earlyPaymentCharges') }, 'Input__Round')}
                                        />
                                        <small className="p-error">{termLoanError?.earlyPaymentCharges}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="earlyPaymentOn" className={classNames({ 'p-error': isFormFieldValid('earlyPaymentOn') }, 'Label__Text')}>
                                            Early Payment On<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="earlyPaymentOn"
                                            placeholder="Select Early Payment On"
                                            options={paymentOnLov}
                                            optionLabel="name"
                                            name="earlyPaymentOn"
                                            filter
                                            disabled={true}
                                            optionValue="lovId"
                                            value={termLoanData?.earlyPaymentOn || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('earlyPaymentOn') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{termLoanError?.earlyPaymentOn}</small>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="Form__Header col-12">
                        <h1>DOWN PAYMENT </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="downPayment" className={classNames({ 'p-error': isFormFieldValid('downPayment') }, 'Label__Text')}>
                                    Select Down Payment<span className="Label__Required"></span>
                                </label>
                                <Dropdown
                                    id="downPayment"
                                    placeholder="Select Down Payment"
                                    options={downPaymentLov}
                                    optionLabel="name"
                                    name="downPayment"
                                    filter
                                    disabled={true}
                                    optionValue="lovId"
                                    value={termLoanData?.downPayment || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('downPayment') }, 'Dropdown__Round')}
                                />
                                <small className="p-error">{termLoanError?.downPayment}</small>
                            </div>
                        </div>
                        {termLoanData?.downPayment === 'Y' && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="downPaymentCharges" className={classNames({ 'p-error': isFormFieldValid('downPaymentCharges') }, 'Label__Text')}>
                                        Charges <span className="Label__Required"></span>
                                    </label>
                                    <InputText
                                        id="downPaymentCharges"
                                        placeholder="Enter Down Payment Charges"
                                        name="downPaymentCharges"
                                        maxLength={8}
                                        disabled={true}
                                        keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                        value={termLoanData?.downPaymentCharges || ''}
                                        onChange={handledData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('downPaymentCharges') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{termLoanError?.downPaymentCharges}</small>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="Form__Header">
                        <h1>DEFINE REPAYMENT SEQUENCE </h1>
                    </div>
                    <div className="Centre_Aligned_Buttons" onDragOver={handleDragOver}>
                        {buttons?.map((button) => (
                            <Button
                                key={button.label}
                                className="DraggableButton Light_Button"
                                onClick={(e) => e.preventDefault()}
                                label={`${button.label}`}
                                disabled
                                // draggable
                                // onDragStart={(e) => handleDragStart(e, button.label)}
                                // onDrop={(e) => handleDrop(e, button.label)}
                            />
                        ))}
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className="card mt-6">
                                <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={termLoanTableData}>
                                    <Column field="noOfDays" header="No. Of Days" />
                                    <Column field="markupValue" header="markup value" />
                                    <Column field="apr" header="APR" />
                                    <Column header="Actions" body={actionBodyTemplate} style={{ textAlign: 'center' }} className="Table__ActionHeading__CentredAlign" />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    {/* <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} style={{ margin: '20px' }} label={lendingData?.currentStep > 2 ? 'Edit' : 'Save'} className="Btn__Dark" />
                    </div> */}
                </form>
            </div>
        </>
    );
}
export default ViewTermLoanChecker;

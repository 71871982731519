import React, { useState, useEffect, useRef } from 'react';
import { handleGetRequest } from '../../../services/GetTemplate';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import '../../../../src/components/DataTable.css';
import AddEditModule from './AddEditModule';
import { InputText } from 'primereact/inputtext';
import ViewModule from './ViewModule';
import { FilterMatchMode } from 'primereact/api';

function Module() {
    const [module, setModule] = useState([]);
    const [loading, setloading] = useState([]);
    const [displayModal, setDisplayModal] = useState(false);
    const [displayViewModal, setDisplayViewModal] = useState(false);
    const [showModuleData, setShowModuleData] = useState({});
    const [editable, setEditable] = useState(false);
    const [moduleRowData, setmoduleRowData] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        limitProfileName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const dataTableRef = useRef(null);

    const editHandler = (rowData) => {
        setDisplayModal(true);
        setmoduleRowData(rowData);
        setEditable(true);
    };

    const getModule = async () => {
        setloading(true);
        dataTableRef.current.reset();
        const response = await handleGetRequest('/mfs_user_management/v1/management/getAllModule');
        setModule(response?.payLoad);
        setloading(false);
    };

    useEffect(() => {
        getModule();
    }, []);

    const showModule = (rowData) => {
        setDisplayViewModal(true);
        setShowModuleData(rowData);
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="Module__Actions">
                <Button tooltip="Edit" icon="pi pi-pencil" tooltipOptions={{ position: 'top' }} onClick={() => editHandler(rowData)} className="p-button-rounded p-button-primary mr-2" />
                <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => showModule(rowData)} className="p-button-rounded p-mr-2" style={{ backgroundColor: '#F2AC57', border: 'none' }} />
            </div>
        );
    };

    const onHide = () => {
        setDisplayModal(false);
        setEditable(false);
    };

    const onViewHide = () => {
        setDisplayViewModal(false);
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ''));
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">Module</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." maxLength={25} />
                </span>
            </div>
        );
    };

    const header = renderHeader();

    return (
        <div>
            <div className="Top__Btn">
                <div>
                    <Button label="New" onClick={() => setDisplayModal(true)} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>
            <Dialog header={editable ? 'Edit Module' : 'Add Module'} visible={displayModal} draggable={false} resizable={false} style={{ width: '50vw' }} onHide={() => onHide()}>
                <AddEditModule onHide={() => onHide()} editable={editable} moduleRowData={moduleRowData} getModule={getModule} />
            </Dialog>
            <Dialog header={'View Details'} visible={displayViewModal} draggable={false} resizable={false} style={{ width: '50vw' }} onHide={() => onViewHide()}>
                <ViewModule onViewHide={() => onViewHide()} showModuleData={showModuleData} />
            </Dialog>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            header={header}
                            filters={filters}
                            globalFilterFields={['moduleDescr']}
                            value={module}
                            emptyMessage="No data found."
                            ref={dataTableRef}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            paginator
                            rows={5}
                            disabled={loading}
                            rowsPerPageOptions={[5, 10, 25]}
                            responsiveLayout="scroll"
                        >
                            <Column field="moduleDescr" header="Description" />
                            <Column body={actionTemplate} header="Actions" className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Module;

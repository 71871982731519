import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import T24AccountLink from './T24AccountLink';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { useDispatch } from 'react-redux';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function MakerApis({ type }) {
    const [tableData, setTableData] = useState([]);
    const [historyTable, setHistoryTable] = useState([]);
    const dispatch = useDispatch();
    const location = useLocation();
    const t24AccountLinkId = location?.state?.t24AccountLinkId;
    const accountId = location?.state?.accountId;

    const getDatabyId = async () => {
        const resp = await handleGetRequest(`/t24connectivity/v1/accountlinking/t24account/${t24AccountLinkId}`);
        const keyData = resp?.payLoad;
        setTableData(keyData);
    };

    const getHistoryTable = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    accountId: accountId?.toString(),
                    mobileNo: '',
                    accountClassificationId: '',
                    cnic: '',
                    coreAccountNo: '',
                    createdBy: '',
                    updatedBy: '',
                    dateFrom: '',
                    dateTo: '',
                    statusId: '',
                    linkStatus: ''
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const LKP_STATUS = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const TBL_USER = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        const getCoreLinkedAccounts = await dispatch(handlePostRequest(newData, '/t24connectivity/v1/accountlinking/getcorelinkedaccounts', true, true));
        if (getCoreLinkedAccounts?.payLoad) {
            const updatedPayload = getCoreLinkedAccounts.payLoad.map((payloadItem) => {
                const statusName = LKP_STATUS?.payLoad?.find((item) => item?.lovId === payloadItem?.statusId)?.name;
                payloadItem.statusName = statusName;

                const createdUser = TBL_USER?.payLoad?.find((item) => item?.lovId === payloadItem?.createdBy)?.name || 'N/A';
                payloadItem.createdByUser = createdUser;

                const updatedUser = TBL_USER?.payLoad?.find((item) => item?.lovId === payloadItem?.updatedBy)?.name || 'N/A';
                payloadItem.updatedByUser = updatedUser;

                const fullNameParts = payloadItem.fullName.split(' ');
                if (fullNameParts.length > 1) {
                    payloadItem.firstName = fullNameParts.slice(0, -1).join(' ');
                    payloadItem.lastName = fullNameParts[fullNameParts.length - 1];
                } else {
                    payloadItem.firstName = fullNameParts[0];
                }

                return payloadItem;
            });
            setHistoryTable(updatedPayload);
        }
    };

    useEffect(() => {
        if (accountId) {
            getHistoryTable();
        }
    }, [accountId]);

    useEffect(() => {
        if (t24AccountLinkId) {
            getDatabyId();
        }
    }, [t24AccountLinkId]);

    return (
        <React.Fragment>
            <T24AccountLink type={type} dataForTable={tableData} historyTableView={historyTable} />
            {/* Action Authorization History */}
            {type === 'view' && t24AccountLinkId && <AuthorizationHistoryTable refTableId={t24AccountLinkId} tableName={'TBL_T24_ACCOUNT_LINK'} />}
        </React.Fragment>
    );
}
export default MakerApis;

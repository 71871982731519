import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function EditManageUpload({}) {
    const [loading, setloading] = useState(false);
   
    const location = useLocation();
    const { rowData } = location.state || {};
    const tableName = 'TBL_BULK_OPR_FILE';
 
 
    const [manageUploadData, setManageUploadData] = useState(null);

    const [batchDetails, setBatchDetails] = useState(null);

    const dt = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    const getBatchDetails = async () => {
        const resp = await handleGetRequest(`/batchprocessing/v1/batchprocessing/getbatchbyid/${rowData?.batchId}`);

        setBatchDetails(resp?.payLoad);
    };

    const getBatchRecordDetails = async () => {
        const resp = await handleGetRequest(`/batchprocessing/v1/batchprocessing/getfilerecordbyid/${rowData?.bulkOprFileId}`);
        setManageUploadData(resp?.payLoad);
    };

    useEffect(() => {
        getBatchDetails();
        getBatchRecordDetails();
    }, []);// eslint-disable-line

    const handleSubmit = async () => {
        const payLoad = {
            bulkOprFileId: rowData?.bulkOprFileId,
            batchType: 'MUF',
            fileStatusId: rowData?.fileStatusId?.fileStatusId
        };
        const data = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        try {
         await dispatch(handlePostRequest(data, '/batchprocessing/v1/batchprocessing/submit', true, false, 'BulkAccountClosure'));
   
            // if (res?.responseCode === '170000') {
            //   setManageUploadData(res?.payLoad);
            // }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const exportCSV = () => {
        dt.current.exportCSV();
    };
    const statusName = batchDetails?.fileStatusId?.fileStatusName;
    const isSubmitDisabled = batchDetails?.invalidRecords > 0;

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };
    return (
        <>
            <div className="Top__Btn Top__Btn__Border">
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <div>
                        <img src="/csv.png" alt="CSV" style={{ width: '70%', height: '60%' }} onClick={exportCSV} />
                    </div>
                </div>
            </div>

            <div className="card Card__Round">
                <div className="Form__Header">{statusName === 'Ready for Submit' ? <h1>MANAGE UPLOAD SUMMARY/ EDIT</h1> : <h1>MANAGE UPLOAD SUMMARY / VIEW</h1>}</div>
                <div className="Batch_Details_Card flex justify-content-center align-items-center ">
                    <div className="col-12 md:col-5 sm:col-12 flex md:flex-row flex-column">
                        <div className="col-12 md:col-4 sm:col-12">
                            <p className="line-height-1">Batch ID</p>
                            <p className="line-height-1 text-blue-700 font-bold">{batchDetails?.batchId}</p>
                        </div>
                        <div className="col-12 md:col-4 sm:col-12">
                            <p className="line-height-1">Date</p>
                            <p className="line-height-1">{createdDateAndTimeBodyTemplate(batchDetails)}</p>
                        </div>
                        <div className="col-12 md:col-4 sm:col-12">
                            <p className="line-height-1">Batch Status</p>
                            <p className="line-height-1 text-orange-500">{statusName}</p>
                        </div>
                    </div>
                    <div className="col-12 md:col-7 sm:col-12 flex md:flex-row flex-column">
                        <div className="col-12 md:col-4 sm:col-12">
                            <div className="flex  p-4 border-round justify-content-center align-items-center gap-3 card_lime">
                                <p className="mb-0 ">Valid Records</p> <p className="text-green-500 font-bold text-2xl">{batchDetails?.validRecords}</p>
                            </div>
                        </div>
                        <div className="col-12 md:col-4 sm:col-12">
                            <div className="flex  p-4 border-round fixed__height card_lime justify-content-center align-items-center gap-3 card_rose">
                                <p className="mb-0 ">Invalid Records</p> <p className="text-pink-600 font-bold text-2xl">{batchDetails?.invalidRecords}</p>
                            </div>
                        </div>
                        <div className="col-12 md:col-4 sm:col-12">
                            <div className="flex  p-4 border-round fixed__height justify-content-center align-items-center gap-3 card_sky">
                                <p className="mb-0 ">Total Records</p> <p className="text-blue-500 font-bold text-2xl">{batchDetails?.totalRecords}</p>
                            </div>
                        </div>
                     
                    </div>
                </div>
                <div className="Form__Header mt-5">
                    <h1>Manage Upload Data</h1>
                </div>

                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <DataTable
                                ref={dt}
                                paginator
                                rows={5}
                                rowsPerPageOptions={[5, 10, 25]}
                                className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} "
                                emptyMessage="No List found."
                                responsiveLayout="scroll"
                                value={manageUploadData && manageUploadData}
                            >
                                <Column field="customerReference" header="Customer Refrence" />
                                <Column field="customerName" header="Customer Name" />
                                <Column field="billingMonth" header="Billing Month" />
                                <Column field="billingYear" header="Billing Year" />
                                <Column field="amount" header="Amount" />
                                <Column field="dueDate" header="Due Date" />
                                <Column field="amountAfterDd" header="Amont After Due Date" />
                                <Column field="transactionCode" header="Transaction Code" />

                                <Column field="validationStatus" header="Status" />
                            </DataTable>
                        </div>
                    </div>
                    <div className="Usergroup__SearchBtn p-field flex flex-row  justify-content-center pt-2 col-12" style={{ textAlign: 'center' }}>
                        {statusName === 'Ready for Submit' ? (
                            <div className="Down__Btn">
                                <Button label="Submit" onClick={handleSubmit} className="Btn__Dark" disabled={loading || isSubmitDisabled} />
                                <Button disabled={loading} type="button" onClick={() => navigate('/manageuploadfile')} label="Cancel" className="Btn__Transparent" />
                            </div>
                        ) : (
                            <div className="Down__Btn">
                                <Button disabled={loading} label="Okay" onClick={() => navigate('/manageuploadfile')} className="Btn__Dark" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {statusName !== 'Ready for Submit' && <AuthorizationHistoryTable refTableId={rowData?.bulkOprFileId} tableName={tableName} />}
        </>
    );
}
export default EditManageUpload;

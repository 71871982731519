import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { Divider } from 'primereact/divider';
import { useNavigate } from 'react-router-dom';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { useDispatch } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { handleGetRequest } from '../../../../services/GetTemplate';

const ManageTransactions = () => {

    const [transactionsData, setTransactionsData] = useState([]);
    const [channelLov, setChannelLov] = useState([]);
    const [segmentLov, setSegmentLov] = useState([]);
    const [productLov, setProductLov] = useState([]);
    const [disabled, setDisabled] = useState(false);

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        transactionId: Yup.number().notRequired('This Field is notRequired.'),
        senderMobileNumber: Yup.number().notRequired('This Field is notRequired.'),
        receiverMobileNumber: Yup.number().notRequired('This Field is notRequired.'),
        senderCnic: Yup.number().notRequired('This Field is notRequired.'),
        receiverCnic: Yup.number().notRequired('This Field is notRequired.'),
        stan: Yup.number().notRequired('This Field is notRequired.'),
        rrn: Yup.number().notRequired('This Field is notRequired.'),
        segmentId: Yup.number().notRequired('This Field is notRequired.'),
        channelId: Yup.number().notRequired('This Field is notRequired.'),
        productId: Yup.number().notRequired('This Field is notRequired.'),
        fromDate: Yup.string().notRequired('This Field is notRequired.'),
        toDate: Yup.string().notRequired('This Field is notRequired.'),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            transactionId: '',
            senderMobileNumber: '',
            receiverMobileNumber: '',
            senderCnic: '',
            receiverCnic: '',
            stan: '',
            rrn: '',
            segmentId: '',
            channelId: '',
            productId: '',
            fromDate: '',
            toDate: '',
        },
        onSubmit: async (data) => {

            const jsonObj = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },

                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },

                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',

                    payLoad: data,

                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],

                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(jsonObj, '/transaction/v1/transactions/searchtransaction', true, true));
            if (res?.responseCode === "200101") {
                setTransactionsData(res?.payLoad);
            }

        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const actionTemplate = (rowData) => {
        return (
            <>
                <Button tooltip="View Transactions" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => handleCustomerView(rowData)} className="p-button-rounded p-button-primary" type='button' />
            </>
        )
    }

    const handleCustomerView = (rowData) => {
        navigate("/viewtransactions", {
            state: { rowData }
        })
    }

    useEffect(() => {
        const getChannel = async () => {
            const res = await handleGetRequest('/configuration/v1/lookups/getchannels/LKP_CHANNEL:*');
            setChannelLov(res?.payLoad);
        };
        getChannel()
    }, []);

    useEffect(() => {
        const getSegmentLov = async () => {
            const res = await handleGetRequest('/configuration/v1/lookups/getsegments/LKP_SEGMENT:*');
            setSegmentLov(res?.payLoad);
        };
        getSegmentLov()
    }, []);

    useEffect(() => {
        const getProductLov = async () => {
            const res = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
            setProductLov(res?.payLoad);
        };
        getProductLov()
    }, []);

    return (
        <>

            <div className='pt-2'>
                <h6 className='secondary-color'><b>TRANSACTIONS</b></h6>
            </div>

            <Divider />

            <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                <div className="p-fluid p-formgrid grid mb-5">

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Transaction ID</label>
                            <InputText
                                id="transactionId"
                                name="transactionId"
                                value={formik.values.transactionId}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('transactionId') }, 'Input__Round')}
                                keyfilter="int"
                                maxLength={11}
                            />
                            {getFormErrorMessage('transactionId')}
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Sender Mobile No.</label>
                            <InputText
                                id="senderMobileNumber"
                                name="senderMobileNumber"
                                value={formik.values.senderMobileNumber}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('senderMobileNumber') }, 'Input__Round')}
                                keyfilter="int"
                                maxLength={11}
                            />
                            {getFormErrorMessage('senderMobileNumber')}
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Receiver Mobile No.</label>
                            <InputText
                                id="receiverMobileNumber"
                                name="receiverMobileNumber"
                                value={formik.values.receiverMobileNumber}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('receiverMobileNumber') }, 'Input__Round')}
                                keyfilter="int"
                                maxLength={11}
                            />
                            {getFormErrorMessage('receiverMobileNumber')}
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Sender CNIC</label>
                            <InputText
                                id="senderCnic"
                                name="senderCnic"
                                value={formik.values.senderCnic}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('senderCnic') }, 'Input__Round')}
                                keyfilter="int"
                                maxLength={13}
                            />
                            {getFormErrorMessage('senderCnic')}
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Receiver CNIC</label>
                            <InputText
                                id="receiverCnic"
                                name="receiverCnic"
                                value={formik.values.receiverCnic}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('receiverCnic') }, 'Input__Round')}
                                keyfilter="int"
                                maxLength={13}
                            />
                            {getFormErrorMessage('receiverCnic')}
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">STAN</label>
                            <InputText
                                id="stan"
                                name="stan"
                                value={formik.values.stan}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('stan') }, 'Input__Round')}
                                keyfilter="int"
                            />
                            {getFormErrorMessage('stan')}
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">RRN</label>
                            <InputText
                                id="rrn"
                                name="rrn"
                                value={formik.values.rrn}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('rrn') }, 'Input__Round')}
                                keyfilter="int"
                            />
                            {getFormErrorMessage('rrn')}
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className='Label__Text'>
                                Segment
                            </label>
                            <Dropdown
                                id="segmentId"
                                name="segmentId"
                                placeholder="Select Filter"
                                options={segmentLov}
                                optionLabel="name"
                                optionValue="lovId"
                                value={formik.values.segmentId || ''}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('segmentId') }, 'Dropdown__Round')}
                                filter showClear filterBy="name"
                            />
                            {getFormErrorMessage('segmentId')}
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className='Label__Text'>
                                Channel
                            </label>
                            <Dropdown
                                id="channelId"
                                name="channelId"
                                placeholder="Select Filter"
                                options={channelLov}
                                optionLabel="name"
                                optionValue="lovId"
                                value={formik.values.channelId ?? ''}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('channelId') }, 'Dropdown__Round')}
                                filter showClear filterBy="name"
                            />
                            {getFormErrorMessage('channelId')}
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className='Label__Text'>
                                Product
                            </label>
                            <Dropdown
                                id="productId"
                                name="productId"
                                placeholder="Select Filter"
                                options={productLov}
                                optionLabel="name"
                                optionValue="lovId"
                                value={formik.values.productId || ''}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('productId') }, 'Dropdown__Round')}
                                filter showClear filterBy="name"
                            />
                            {getFormErrorMessage('productId')}
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className='Label__Text'>
                                Date From
                            </label>
                            <InputText
                                id="fromDate"
                                type="date"
                                name="fromDate"
                                value={formik?.values?.fromDate}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('fromDate') }, 'Input__Round')}
                            />
                            {getFormErrorMessage('fromDate')}
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className='Label__Text'>
                                Date To
                            </label>
                            <InputText
                                id="toDate"
                                type="date"
                                name="toDate"
                                value={formik?.values?.toDate}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('toDate') }, 'Input__Round')}
                            />
                            {getFormErrorMessage('toDate')}
                        </div>
                    </div>

                </div>

                <div className="p-field col-12 flex justify-content-center">
                    <div className="p-field">
                        <div className="Usergroup__SearchBtn">
                            <Button label="Search" disabled={disabled} iconPos="right" className="Btn__Dark__Search" />
                            <Button label="Reset" onClick={(e) => handleReset(e)} className="Btn__Dark__Search" />
                        </div>
                    </div>
                </div>

            </form>

            <div className="pt-5 p-datatable-sm custom-border">

                <div className="custom-datatable">
                    <DataTable
                        // header={header}
                        value={transactionsData}
                        emptyMessage="No data found."
                        scrollable
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25]}
                    >
                        <Column field="transactionId" header="Transaction ID" />
                        <Column field="senderMobileNumber" header="Sender Mobile" />
                        <Column field="receiverMobileNumber" header="Receiver Mobile" />
                        <Column field="senderCnic" header="Sender CNIC" />
                        <Column field="receiverCnic" header="Receiver CNIC" />
                        <Column field="rrn" header="RRN" />
                        <Column field="segmentName" header="Segment" />
                        <Column field="channelName" header="Channel" />
                        <Column field="productName" header="Product" />
                        <Column field="createdBy" header="Created By" />
                        <Column field="updatedBy" header="Updated By" />
                        <Column field="" header="Date From" />
                        <Column field="" header="Date To" />
                        <Column field="transSatusName" header="Status" />
                        <Column body={actionTemplate} header="Actions" />
                    </DataTable>
                </div>
            </div>

        </>
    )
}

export default ManageTransactions
import classNames from 'classnames';
import { useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useEffect, useState } from 'react';
import { handleGetRequest } from '../../../../services/GetTemplate';
import * as Yup from 'yup';
import { Button } from 'primereact/button';

function RemarksDailog({onHideRemarksDialog , remarksValue , statusValue, dialogData}) {
    const [accountStatus, setAccountStatus] = useState();

    const getaccountStatus = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getAccountStatus/LKP_ACCOUNT_STATUS:*');
        setAccountStatus(res?.payLoad);
    };

    useEffect(() => {
        getaccountStatus();
    }, []);

    const validationSchema = Yup.object({
        remarks: Yup.string().required('This Field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            remarks: '',
            accountStatus: ''
        },
        validate: (data) => {
            let errors = {}
            if (!data?.remarks) {
                errors.remarks='This field is required'
            }
            return errors
        },
        onSubmit:(data)=> {
            dialogData(data);
            onHideRemarksDialog();
        }
    });


    useEffect(() => {
        formik.setFieldValue('remarks', remarksValue)
        formik.setFieldValue('accountStatus', statusValue)
    }, [])

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
   
    return (
        <>
            <div className="card Card__Round">
                <form className="p-fluid p-mt-2" onSubmit={formik.handleSubmit}>
                    <div className="p-field col-12 md:col-12 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="accountStatus" className={classNames({ 'p-error': isFormFieldValid('accountStatus') }, 'Label__Text')}>
                                Status <span className="Label__Required">*</span>
                            </label>
                            <Dropdown
                                id="accountStatus"
                                placeholder="Select Status"
                                options={accountStatus}
                                optionLabel="accountStatusName"
                                name="accountStatus"
                                optionValue="accountStatusId"
                                value={formik.values.accountStatus || ''}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('accountStatus') }, 'Dropdown__Round')}
                            />
                            {getFormErrorMessage('accountStatus')}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="remarks" className={classNames({ 'p-error': isFormFieldValid('remarks') }, 'Label__Text')}>
                                Reason<span className="Label__Required">*</span>
                            </label>
                        </div>
                        <InputTextarea
                            maxLength={500}
                            rows={5}
                            cols={30}
                            id="remarks"
                            name="remarks"
                            value={formik.values.remarks || ''}
                            onChange={formik.handleChange}
                            className={classNames({ 'p-invalid': isFormFieldValid('remarks') }, 'TextArea__Round')}
                        />
                    </div>

                    {getFormErrorMessage('remarks')}
                     <div className="Down__Btn">
                        <Button type="submit" label='Ok' className="Btn__Add" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default RemarksDailog;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { handleGetRequest } from '../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ProductCatalogView() {
    const [listOfTransaction, setListOfTransaction] = useState([]);
    const location = useLocation();

    const { rowData } = location.state || {};
    const tableName = 'TBL_PRODUCT_CATALOG';
    
    const transactionListId = rowData && rowData.tblProductCatalogDetails.map((tblid) => {
        return {
            'lovId': tblid.tblProduct.productId.toString(),
        }
    } )
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
    const formik = useFormik({
        initialValues: {
            productCatalogId: '',
            productCatalogDescription: '',
            transactionList:[]
        },
        onSubmit: async (data) => {
            const arrayOfTransactionIds = formik.values.transactionList.map((item) => item?.lovId);
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        productCatalogId: data.productCatalogId,
                        productCatalogDescription: data.productCatalogDescription,
                        transactionList:arrayOfTransactionIds,
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
        }
    });
   
    const mapValues = () => {
        formik.setFieldValue('productCatalogId', rowData?.productCatalogId || '');
        formik.setFieldValue('transactionList', transactionListId);
    };
    //list of transactions API call
    const getTransactionListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setListOfTransaction(res?.payLoad);
    };
    useEffect(() => {
        mapValues();
        getTransactionListData();
    }, []);

    console.log("formik.values.transactionList",formik.values.transactionList)

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>View Detail</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productCatalogName" className="Label__Text">
                                    Name<span className="Label__Required">*</span>
                                </label>
                                <InputText id="productCatalogName" name="productCatalogName" placeholder={rowData?.productCatalogName} className="Input__Round" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productCatalogDescription" className="Label__Text">
                                    Description<span className="Label__Required">*</span>
                                </label>
                                <InputText id="productCatalogDescription" name="productCatalogDescription" placeholder={rowData?.description}  className="Input__Round" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="transactionList" className="Label__Text">
                                    List of transactions<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="transactionList"
                                    placeholder="Select Transaction List"
                                    options={listOfTransaction}
                                    optionLabel="name"
                                    name="transactionList"
                                    optionValue="lovId"
                                    // display="chip"
                                    filter
                                    value={formik.values.transactionList.map((trans) => trans.lovId)}
                                    // onChange={(e) => {
                                    //     formik.setFieldValue(
                                    //         'transactionList',
                                    //         e.value.map((lovId) => ({
                                    //             lovId
                                    //         }))
                                    //     );
                                    // }}
                                    className="Dropdown__Round"
                                  
                                />
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label="Okay" className="Btn__Dark" onClick={goBack} />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={rowData.productCatalogId} tableName={tableName} />
        </>
    );
}

export default ProductCatalogView;

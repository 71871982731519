import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';

function EditCustomerBusinessRiskChecker({ activeIndex, handleStepClick, handlecrpId, riskProfileData }) {

    const [customerType, setCustomerType] = useState();
    const [occupationLov, setOccupationLov] = useState();
    const [sourceOfIncomeLov, setSourceOfIncomeLov] = useState();
    const [accountPurposeLov, setAccountPurposeLov] = useState();
    const [rpCrpModelId, setRpCrpModelId] = useState([]);

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const requestType = query.get('requestType');
    const requestTypeDescr = query.get('requestTypeDescr');

    const dispatch = useDispatch();

    const getRpCrpModelId = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getcrpdata/TBL_RP_CRP_MODEL');
        setRpCrpModelId(response?.payLoad);
    };

    const getCustomerType = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getcrpdata/LKP_RP_CUSTOMER_TYPES');
        setCustomerType(response?.payLoad);
    };

    const getHraLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/gethraaccountdata');
        setOccupationLov(resp?.payLoad?.lkpOccupation);
        setSourceOfIncomeLov(resp?.payLoad?.lkpSourceOfIncome);
        setAccountPurposeLov(resp?.payLoad?.lkpAccountPurpose);
    };

    useEffect(() => {
        getCustomerType();
        // getPepLov();
        getHraLov();
        getRpCrpModelId();
    }, []);

    useEffect(() => {
        if (requestType === 'I' || (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request')) {
            if (riskProfileData) {
                formik.setFieldValue('crpCode', riskProfileData?.crpCode);
                formik.setFieldValue('crpName', riskProfileData?.crpName);
                formik.setFieldValue('crpDescription', riskProfileData?.description);
                formik.setFieldValue('rpCrpModelId', riskProfileData?.tblRpCrpModel?.rpCrpModelId?.toString());

                const CTDropdownData = riskProfileData?.tblRpCrpDetails;

                if (CTDropdownData) {
                    const CTHighId = [];
                    const CTMediumId = [];
                    const CTLowId = [];

                    const pepHighId = [];
                    const pepMediumId = [];
                    const pepLowId = [];

                    const occupationHighId = [];
                    const occupationMediumId = [];
                    const occupationLowId = [];

                    const customerLinkedHighId = [];
                    const customerLinkedMediumId = [];
                    const customerLinkedLowId = [];

                    const purposeOfACHighId = [];
                    const purposeOfACMediumId = [];
                    const purposeOfACLowId = [];

                    const sourceOfFundsHighId = [];
                    const sourceOfFundsMediumId = [];
                    const sourceOfFundsLowId = [];

                    CTDropdownData.forEach((value) => {
                        const referenceIdToString = value.refId?.toString();
                        const referenceId = value?.refId;
                        // Get Customer Type Data
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'CT') {
                            if (value?.rpRating === 'H') {
                                CTHighId.push(referenceIdToString);

                                formik.setFieldValue('CTHighRisk', CTHighId);
                                formik.setFieldValue('CToverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                CTMediumId.push(referenceIdToString);
                                formik.setFieldValue('CTMediumRisk', CTMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                CTLowId.push(referenceIdToString);
                                formik.setFieldValue('CTLowRisk', CTLowId);
                            }
                        }

                        // GET PEP Data
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'PEP') {
                            if (value?.rpRating === 'H') {
                                pepHighId.push(referenceIdToString);

                                formik.setFieldValue('pepHighRisk', pepHighId[0] === '0' ? 'N' : 'Y');
                                formik.setFieldValue('pepOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                pepMediumId.push(referenceIdToString);
                                formik.setFieldValue('pepMediumRisk', pepMediumId[0] === '0' ? 'N' : 'Y');
                            }
                            if (value?.rpRating === 'L') {
                                pepLowId.push(referenceIdToString);
                                formik.setFieldValue('pepLowRisk', pepLowId[0] === '0' ? 'N' : 'Y');
                            }
                        }

                        // GET occupation Data
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'OC') {
                            if (value?.rpRating === 'H') {
                                occupationHighId.push(referenceId);

                                formik.setFieldValue('occupationHighRisk', occupationHighId);
                                formik.setFieldValue('occupationOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                occupationMediumId.push(referenceId);

                                formik.setFieldValue('occupationMediumRisk', occupationMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                occupationLowId.push(referenceId);

                                formik.setFieldValue('occupationLowRisk', occupationLowId);
                            }
                        }

                        // GET Customer Linked Data
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'CCT') {
                            if (value?.rpRating === 'H') {
                                customerLinkedHighId.push(referenceIdToString);

                                formik.setFieldValue('customerLinkedHighRisk', customerLinkedHighId[0] === '0' ? 'N' : 'Y');
                                formik.setFieldValue('customerLinkedOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                customerLinkedMediumId.push(referenceIdToString);

                                formik.setFieldValue('customerLinkedMediumRisk', customerLinkedMediumId[0] === '0' ? 'N' : 'Y');
                            }
                            if (value?.rpRating === 'L') {
                                customerLinkedLowId.push(referenceIdToString);

                                formik.setFieldValue('customerLinkedLowRisk', customerLinkedLowId[0] === '0' ? 'N' : 'Y');
                            }
                        }

                        // GET Purpose of AC Data
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'PA') {
                            if (value?.rpRating === 'H') {
                                purposeOfACHighId.push(referenceId);

                                formik.setFieldValue('purposeOfACHighRisk', purposeOfACHighId);
                                formik.setFieldValue('purposeOfACOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                purposeOfACMediumId.push(referenceId);

                                formik.setFieldValue('purposeOfACMediumRisk', purposeOfACMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                purposeOfACLowId.push(referenceId);

                                formik.setFieldValue('purposeOfACLowRisk', purposeOfACLowId);
                            }
                        }

                        // GET Source of Funds Data
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'SF') {
                            if (value?.rpRating === 'H') {
                                sourceOfFundsHighId.push(referenceId);

                                formik.setFieldValue('sourceOfFundsHighRisk', sourceOfFundsHighId);
                                formik.setFieldValue('sourceOfFundsOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                sourceOfFundsMediumId.push(referenceId);

                                formik.setFieldValue('sourceOfFundsMediumRisk', sourceOfFundsMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                sourceOfFundsLowId.push(referenceId);

                                formik.setFieldValue('sourceOfFundsLowRisk', sourceOfFundsLowId);
                            }
                        }
                    });
                }
            }
        } else if (requestType === 'U') {
            formik.setFieldValue('crpCode', riskProfileData[0]?.crpCode);
            formik.setFieldValue('crpName', riskProfileData[0]?.crpName);
            formik.setFieldValue('crpDescription', riskProfileData[0]?.crpDescription);
            formik.setFieldValue('rpCrpModelId', riskProfileData[0]?.rpCrpModelId?.toString());

            // GET Customer Type Data
            formik.setFieldValue(
                'CTHighRisk',
                riskProfileData[0]?.reqMap[0]?.CT?.highRisk?.map((value) => value.toString())
            );
            formik.setFieldValue(
                'CTMediumRisk',
                riskProfileData[0]?.reqMap[0]?.CT?.medRisk?.map((value) => value.toString())
            );
            formik.setFieldValue(
                'CTLowRisk',
                riskProfileData[0]?.reqMap[0]?.CT?.lowRisk?.map((value) => value.toString())
            );
            formik.setFieldValue('CToverRideRule', riskProfileData[0]?.reqMap[0]?.CT?.overRideRule === true ? 'true' : 'false');

            // GET PEP Data
            formik.setFieldValue(
                'pepHighRisk',
                riskProfileData[0]?.reqMap[1]?.PEP?.highRisk?.toString() === 0 ? 'N' : 'Y'
            );
            formik.setFieldValue(
                'pepMediumRisk',
                riskProfileData[0]?.reqMap[1]?.PEP?.medRisk?.toString() === 0 ? 'N' : 'Y'
            );
            formik.setFieldValue(
                'pepLowRisk',
                riskProfileData[0]?.reqMap[1]?.PEP?.lowRisk?.toString() === 0 ? 'N' : 'Y'
            );
            formik.setFieldValue('pepOverRideRule', riskProfileData[0]?.reqMap[1]?.PEP?.overRideRule === true ? 'true' : 'false');

            // GET occupation Data
            formik.setFieldValue(
                'occupationHighRisk',
                riskProfileData[0]?.reqMap[2]?.OC?.highRisk?.map((value) => value)
            );
            formik.setFieldValue(
                'occupationMediumRisk',
                riskProfileData[0]?.reqMap[2]?.OC?.medRisk?.map((value) => value)
            );
            formik.setFieldValue(
                'occupationLowRisk',
                riskProfileData[0]?.reqMap[2]?.OC?.lowRisk?.map((value) => value)
            );
            formik.setFieldValue('occupationOverRideRule', riskProfileData[0]?.reqMap[2]?.OC?.overRideRule === true ? 'true' : 'false');

            // GET Customer Linked Data
            formik.setFieldValue(
                'customerLinkedHighRisk',
                riskProfileData[0]?.reqMap[5]?.CCT?.highRisk?.toString() === 0 ? 'N' : 'Y'
            );
            formik.setFieldValue(
                'customerLinkedMediumRisk',
                riskProfileData[0]?.reqMap[5]?.CCT?.medRisk?.toString() === 0 ? 'N' : 'Y'
            );
            formik.setFieldValue(
                'customerLinkedLowRisk',
                riskProfileData[0]?.reqMap[5]?.CCT?.lowRisk?.toString() === 0 ? 'N' : 'Y'
            );
            formik.setFieldValue('customerLinkedOverRideRule', riskProfileData[0]?.reqMap[5]?.CCT?.overRideRule === true ? 'true' : 'false');

            // GET Purpose of AC Data
            formik.setFieldValue(
                'purposeOfACHighRisk',
                riskProfileData[0]?.reqMap[3]?.PA?.highRisk?.map((value) => value)
            );
            formik.setFieldValue(
                'purposeOfACMediumRisk',
                riskProfileData[0]?.reqMap[3]?.PA?.medRisk?.map((value) => value)
            );
            formik.setFieldValue(
                'purposeOfACLowRisk',
                riskProfileData[0]?.reqMap[3]?.PA?.lowRisk?.map((value) => value)
            );
            formik.setFieldValue('purposeOfACOverRideRule', riskProfileData[0]?.reqMap[3]?.PA?.overRideRule === true ? 'true' : 'false');

            // GET Source of Funds Data
            formik.setFieldValue(
                'sourceOfFundsHighRisk',
                riskProfileData[0]?.reqMap[4]?.SF?.highRisk?.map((value) => value)
            );
            formik.setFieldValue(
                'sourceOfFundsMediumRisk',
                riskProfileData[0]?.reqMap[4]?.SF?.medRisk?.map((value) => value)
            );
            formik.setFieldValue(
                'sourceOfFundsLowRisk',
                riskProfileData[0]?.reqMap[4]?.SF?.lowRisk?.map((value) => value)
            );
            formik.setFieldValue('sourceOfFundsOverRideRule', riskProfileData[0]?.reqMap[4]?.SF?.overRideRule === true ? 'true' : 'false');
        }
    }, [riskProfileData]); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            stepNo: 1,
            crpCode: '',
            crpName: '',
            crpDescription: '',
            rpCrpModelId: '',
            CTHighRisk: [],
            CTMediumRisk: [],
            CTLowRisk: [],
            CToverRideRule: 'false',
            pepHighRisk: [],
            pepMediumRisk: [],
            pepLowRisk: [],
            pepOverRideRule: 'false',
            customerLinkedHighRisk: [],
            customerLinkedMediumRisk: [],
            customerLinkedLowRisk: [],
            customerLinkedOverRideRule: 'false',
            occupationHighRisk: [],
            occupationMediumRisk: [],
            occupationLowRisk: [],
            occupationOverRideRule: 'false',
            purposeOfACHighRisk: [],
            purposeOfACMediumRisk: [],
            purposeOfACLowRisk: [],
            purposeOfACOverRideRule: 'false',
            sourceOfFundsHighRisk: [],
            sourceOfFundsMediumRisk: [],
            sourceOfFundsLowRisk: [],
            sourceOfFundsOverRideRule: 'false',
            reqMap: []
        },

        onSubmit: async (data) => {
            data['reqMap'] = [
                {
                    CT: {
                        highRisk: data?.CTHighRisk,
                        medRisk: [data?.CTMediumRisk],
                        lowRisk: [data?.CTLowRisk],
                        overRideRule: data?.CToverRideRule
                    }
                },
                {
                    PEP: {
                        highRisk: data?.pepHighRisk,
                        medRisk: [data?.pepMediumRisk],
                        lowRisk: [data?.pepLowRisk],
                        overRideRule: data?.pepOverRideRule
                    }
                },
                {
                    OC: {
                        highRisk: data?.occupationHighRisk,
                        medRisk: data?.occupationMediumRisk,
                        lowRisk: data?.occupationLowRisk,
                        overRideRule: data?.occupationOverRideRule
                    }
                },
                {
                    PA: {
                        highRisk: data?.purposeOfACHighRisk,
                        medRisk: data?.purposeOfACMediumRisk,
                        lowRisk: data?.purposeOfACLowRisk,
                        overRideRule: data?.purposeOfACOverRideRule
                    }
                },
                {
                    SF: {
                        highRisk: data?.sourceOfFundsHighRisk,
                        medRisk: data?.sourceOfFundsMediumRisk,
                        lowRisk: data?.sourceOfFundsLowRisk,
                        overRideRule: data?.sourceOfFundsOverRideRule
                    }
                },
                {
                    CCT: {
                        highRisk: data?.customerLinkedHighRisk,
                        medRisk: [data?.customerLinkedMediumRisk],
                        lowRisk: [data?.customerLinkedLowRisk],
                        overRideRule: data?.customerLinkedOverRideRule
                    }
                }
            ];

            delete data['CTHighRisk'];
            delete data['CTMediumRisk'];
            delete data['CTLowRisk'];
            delete data['CToverRideRule'];
            delete data['pepHighRisk'];
            delete data['pepMediumRisk'];
            delete data['pepLowRisk'];
            delete data['pepOverRideRule'];
            delete data['occupationHighRisk'];
            delete data['occupationMediumRisk'];
            delete data['occupationLowRisk'];
            delete data['occupationOverRideRule'];
            delete data['purposeOfACHighRisk'];
            delete data['purposeOfACMediumRisk'];
            delete data['purposeOfACLowRisk'];
            delete data['purposeOfACOverRideRule'];
            delete data['sourceOfFundsHighRisk'];
            delete data['sourceOfFundsMediumRisk'];
            delete data['sourceOfFundsLowRisk'];
            delete data['sourceOfFundsOverRideRule'];
            delete data['customerLinkedHighRisk'];
            delete data['customerLinkedMediumRisk'];
            delete data['customerLinkedLowRisk'];
            delete data['customerLinkedOverRideRule'];

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/riskprofile/v1/crp/saveupdatecrp', true, false));
            handleStepClick(activeIndex + 1);
            handlecrpId(res?.payLoad?.rpCrpId);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const staticLov = [
        { name: 'Yes', value: 'Y' },
        { name: 'No', value: 'N' }
    ];

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="crpCode" className={classNames({ 'p-error': isFormFieldValid('crpCode') }, 'Label__Text')}>
                                    CRP Code
                                </label>
                                <InputText
                                    disabled
                                    id="crpCode"
                                    placeholder="Enter CRP Code"
                                    name="crpCode"
                                    maxLength={100}
                                    value={formik?.values?.crpCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('crpCode') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('crpCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="crpName" className={classNames({ 'p-error': isFormFieldValid('crpName') }, 'Label__Text')}>
                                    CRP Name
                                </label>
                                <InputText
                                    disabled
                                    id="crpName"
                                    placeholder="Enter Name"
                                    name="crpName"
                                    maxLength={100}
                                    value={formik?.values?.crpName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('crpName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('crpName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="rpCrpModelId" className={classNames({ 'p-error': isFormFieldValid('rpCrpModelId') }, 'Label__Text')}>
                                    CRP Model
                                </label>
                                <Dropdown
                                    disabled
                                    id="rpCrpModelId"
                                    placeholder="Select CRP Model"
                                    options={rpCrpModelId}
                                    optionLabel="name"
                                    name="rpCrpModelId"
                                    optionValue="lovId"
                                    value={formik.values.rpCrpModelId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('rpCrpModelId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('rpCrpModelId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="crpDescription" className={classNames({ 'p-error': isFormFieldValid('crpDescription') }, 'Label__Text')}>
                                    Description
                                </label>
                                <InputText
                                    disabled
                                    id="crpDescription"
                                    placeholder="Enter Description"
                                    name="crpDescription"
                                    maxLength={100}
                                    value={formik?.values?.crpDescription || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('crpDescription') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('crpDescription')}
                            </div>
                        </div>
                    </div>

                    {/* Customer Type */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>CUSTOMER TYPE</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="CTHighRisk" className={classNames({ 'p-error': isFormFieldValid('CTHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="CTHighRisk"
                                    placeholder="Select High Risk"
                                    options={customerType}
                                    optionLabel="name"
                                    name="CTHighRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.CTHighRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('CTHighRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="CTMediumRisk" className={classNames({ 'p-error': isFormFieldValid('CTMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="CTMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={customerType}
                                    optionLabel="name"
                                    name="CTMediumRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.CTMediumRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('CTMediumRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="CTLowRisk" className={classNames({ 'p-error': isFormFieldValid('CTLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="CTLowRisk"
                                    placeholder="Select Low Risk"
                                    options={customerType}
                                    optionLabel="name"
                                    name="CTLowRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.CTLowRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('CTLowRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="CToverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="CToverRideRule" disabled name="CToverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.CToverRideRule === 'false'} />
                                    <label htmlFor="CToverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="CToverRideRule" disabled name="CToverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.CToverRideRule === 'true'} />
                                    <label htmlFor="CToverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* PEP */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>PEP</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="pepHighRisk" className={classNames({ 'p-error': isFormFieldValid('pepHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <Dropdown
                                    id="pepHighRisk"
                                    placeholder="Select High Risk"
                                    options={staticLov}
                                    optionLabel="name"
                                    name="pepHighRisk"
                                    optionValue="value"
                                    value={formik?.values?.pepHighRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pepHighRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        {/* <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="pepMediumRisk" className={classNames({ 'p-error': isFormFieldValid('pepMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <Dropdown
                                    id="pepMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={staticLov}
                                    optionLabel="name"
                                    name="pepMediumRisk"
                                    optionValue="value"
                                    value={formik?.values?.pepMediumRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pepMediumRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div> */}
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="pepLowRisk" className={classNames({ 'p-error': isFormFieldValid('pepLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <Dropdown
                                    id="pepLowRisk"
                                    placeholder="Select Low Risk"
                                    options={staticLov}
                                    optionLabel="name"
                                    name="pepLowRisk"
                                    optionValue="value"
                                    value={formik?.values?.pepLowRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pepLowRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="pepOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="pepOverRideRule" disabled name="pepOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.pepOverRideRule === 'false'} />
                                    <label htmlFor="pepOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="pepOverRideRule" disabled name="pepOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.pepOverRideRule === 'true'} />
                                    <label htmlFor="pepOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* CUSTOMER IS LINKED TO NGO/NPO/CHARITIES/TRUSTS */}
                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>CUSTOMER IS LINKED TO NGO/NPO/CHARITIES/TRUSTS/CLUBS/SOCIETIES/ASSOCIATION/EXCHANGE CO</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="customerLinkedHighRisk" className={classNames({ 'p-error': isFormFieldValid('customerLinkedHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <Dropdown
                                    id="customerLinkedHighRisk"
                                    placeholder="Select High Risk"
                                    options={staticLov}
                                    optionLabel="name"
                                    name="customerLinkedHighRisk"
                                    optionValue="value"
                                    value={formik?.values?.customerLinkedHighRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('customerLinkedHighRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        {/* <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="customerLinkedMediumRisk" className={classNames({ 'p-error': isFormFieldValid('customerLinkedMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <Dropdown
                                    id="customerLinkedMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={staticLov}
                                    optionLabel="name"
                                    name="customerLinkedMediumRisk"
                                    optionValue="value"
                                    value={formik?.values?.customerLinkedMediumRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('customerLinkedMediumRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div> */}
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="customerLinkedLowRisk" className={classNames({ 'p-error': isFormFieldValid('customerLinkedLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <Dropdown
                                    id="customerLinkedLowRisk"
                                    placeholder="Select Low Risk"
                                    options={staticLov}
                                    optionLabel="name"
                                    name="customerLinkedLowRisk"
                                    optionValue="value"
                                    value={formik?.values?.customerLinkedLowRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('customerLinkedLowRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="customerLinkedOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="customerLinkedOverRideRule" disabled name="customerLinkedOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.customerLinkedOverRideRule === 'false'} />
                                    <label htmlFor="customerLinkedOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="customerLinkedOverRideRule" disabled name="customerLinkedOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.customerLinkedOverRideRule === 'true'} />
                                    <label htmlFor="customerLinkedOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* OCCUPATION */}
                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>OCCUPATION</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="occupationHighRisk" className={classNames({ 'p-error': isFormFieldValid('occupationHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="occupationHighRisk"
                                    placeholder="Select High Risk"
                                    options={occupationLov}
                                    optionLabel="occupationName"
                                    name="occupationHighRisk"
                                    optionValue="occupationId"
                                    value={formik?.values?.occupationHighRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('occupationHighRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="occupationMediumRisk" className={classNames({ 'p-error': isFormFieldValid('occupationMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="occupationMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={occupationLov}
                                    optionLabel="occupationName"
                                    name="occupationMediumRisk"
                                    optionValue="occupationId"
                                    value={formik?.values?.occupationMediumRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('occupationMediumRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="occupationLowRisk" className={classNames({ 'p-error': isFormFieldValid('occupationLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="occupationLowRisk"
                                    placeholder="Select Low Risk"
                                    options={occupationLov}
                                    optionLabel="occupationName"
                                    name="occupationLowRisk"
                                    optionValue="occupationId"
                                    value={formik?.values?.occupationLowRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('occupationLowRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="occupationOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="occupationOverRideRule" disabled name="occupationOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.occupationOverRideRule === 'false'} />
                                    <label htmlFor="occupationOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="occupationOverRideRule" disabled name="occupationOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.occupationOverRideRule === 'true'} />
                                    <label htmlFor="occupationOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* PURPOSE OF A/C */}
                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>PURPOSE OF A/C</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="purposeOfACHighRisk" className={classNames({ 'p-error': isFormFieldValid('purposeOfACHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="purposeOfACHighRisk"
                                    placeholder="Select High Risk"
                                    options={accountPurposeLov}
                                    optionLabel="accountPurposeName"
                                    name="purposeOfACHighRisk"
                                    optionValue="accountPurposeId"
                                    value={formik?.values?.purposeOfACHighRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('purposeOfACHighRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="purposeOfACMediumRisk" className={classNames({ 'p-error': isFormFieldValid('purposeOfACMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="purposeOfACMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={accountPurposeLov}
                                    optionLabel="accountPurposeName"
                                    name="purposeOfACMediumRisk"
                                    optionValue="accountPurposeId"
                                    value={formik?.values?.purposeOfACMediumRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('purposeOfACMediumRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="purposeOfACLowRisk" className={classNames({ 'p-error': isFormFieldValid('purposeOfACLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="purposeOfACLowRisk"
                                    placeholder="Select Low Risk"
                                    options={accountPurposeLov}
                                    optionLabel="accountPurposeName"
                                    name="purposeOfACLowRisk"
                                    optionValue="accountPurposeId"
                                    value={formik?.values?.purposeOfACLowRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('purposeOfACLowRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="purposeOfACOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="purposeOfACOverRideRule" disabled name="purposeOfACOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.purposeOfACOverRideRule === 'false'} />
                                    <label htmlFor="purposeOfACOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="purposeOfACOverRideRule" disabled name="purposeOfACOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.purposeOfACOverRideRule === 'true'} />
                                    <label htmlFor="purposeOfACOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* SOURCE OF FUNDS */}
                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>SOURCE OF FUNDS</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="sourceOfFundsHighRisk" className={classNames({ 'p-error': isFormFieldValid('sourceOfFundsHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="sourceOfFundsHighRisk"
                                    placeholder="Select High Risk"
                                    options={sourceOfIncomeLov}
                                    optionLabel="sourceOfIncomeName"
                                    name="sourceOfFundsHighRisk"
                                    optionValue="sourceOfIncomeId"
                                    value={formik?.values?.sourceOfFundsHighRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('sourceOfFundsHighRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="sourceOfFundsMediumRisk" className={classNames({ 'p-error': isFormFieldValid('sourceOfFundsMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="sourceOfFundsMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={sourceOfIncomeLov}
                                    optionLabel="sourceOfIncomeName"
                                    name="sourceOfFundsMediumRisk"
                                    optionValue="sourceOfIncomeId"
                                    value={formik?.values?.sourceOfFundsMediumRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('sourceOfFundsMediumRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="sourceOfFundsLowRisk" className={classNames({ 'p-error': isFormFieldValid('sourceOfFundsLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="sourceOfFundsLowRisk"
                                    placeholder="Select Low Risk"
                                    options={sourceOfIncomeLov}
                                    optionLabel="sourceOfIncomeName"
                                    name="sourceOfFundsLowRisk"
                                    optionValue="sourceOfIncomeId"
                                    value={formik?.values?.sourceOfFundsLowRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('sourceOfFundsLowRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="sourceOfFundsOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="sourceOfFundsOverRideRule" disabled name="sourceOfFundsOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.sourceOfFundsOverRideRule === 'false'} />
                                    <label htmlFor="sourceOfFundsOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="sourceOfFundsOverRideRule" disabled name="sourceOfFundsOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.sourceOfFundsOverRideRule === 'true'} />
                                    <label htmlFor="sourceOfFundsOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
export default EditCustomerBusinessRiskChecker;

import React from 'react';
import QRCode from 'qrcode.react';
import { saveAs } from 'file-saver';
import { Button } from 'primereact/button';

const QrCode = ({ qrCode }) => {
    const handleDownload = () => {
        const canvas = document.querySelector('canvas');
        canvas.toBlob((blob) => {
            saveAs(blob, 'qrcode.png');
        });
    };

    return (
        <div className="DmoMerchantQRCode text-center mt-5">
            <QRCode value={qrCode} size={250} />
            <div className="Down__Btn mt-4">
                <Button type="button" onClick={handleDownload} label="Download QR Code" className="Btn__Add" />
            </div>
        </div>
    );
};

export default QrCode;

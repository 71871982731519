import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
// import { Checkbox } from 'primereact/checkbox';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { handleGetRequest } from '../../services/GetTemplate';
import { handlePostRequest } from '../../services/PostTemplate';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';

function AddRegimeLevelDialog({ onHideRegimeLevel, setGetTaxProfileData, editable, regimeLevelRowData }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-check');
    const [regimeLevelValues, setRegimeLevelValues]=useState()

    const dispatch = useDispatch();

    const getRegimeLevelLov = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getRegimeCategory/LKP_REGIME_CATEGORY:*')
        setRegimeLevelValues(response?.payLoad)
    }
    
   
    useEffect(() => {
        getRegimeLevelLov()
    }, [])
    useEffect(() => {
        if (editable) {
            const rowData = JSON?.parse(JSON?.stringify(regimeLevelRowData));
            const keys = Object?.keys(rowData);
            keys.forEach((item) => {
                formik.setFieldValue(`${item}`, rowData[item]);
            });
            formik.setFieldValue('regimeCategory',rowData?.lkpRegimeCategory?.regimeCategoryId.toString())
        }
    }, [regimeLevelRowData]);// eslint-disable-line
    useEffect(() => {
        if (!editable) {
            formik.resetForm();
        }
    }, [editable]);// eslint-disable-line

    const validationSchema = Yup.object().shape({
        regimeCategory: Yup.mixed().required('This Field is required.'),
        regimeLevelCode: Yup.string().required('This Field is required.').test('valid-input', 'Only alphanumeric values are allowed', value => {
      return value && /^[a-zA-Z0-9\s]*[a-zA-Z0-9]$/.test(value.trim());
    }),
    //     regimeLevelName: Yup.string().required('This Field is required.').test('valid-input', 'Only alphabetic characters are allowed', value => {
    //   return value && /^[a-zA-Z\s]*[a-zA-Z]$/.test(value.trim());
    // }),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            regimeCategory: '',
            regimeLevelCode: '',
            regimeLevelName: '',
            regimeLevelDescr: ''
        },

        validate: (data) => {
            let errors = {};
            ////////////////////////regimeLevelName/////////////////////
            if (!data?.regimeLevelName) {
                errors.regimeLevelName = "This Field is required";
            } else if (data?.regimeLevelName === "") {
                errors.regimeLevelName = "This Field should not be empty";
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.regimeLevelName)) {
                errors.regimeLevelName = "Leading and trailing space is not allowed";
            } else if (!/^[a-zA-Z](?:[a-zA-Z0-9\s]*-?[a-zA-Z0-9\s]*)?$/.test(data?.regimeLevelName)) {
                errors.regimeLevelName = "Invalid Regime Level Name format";
            }

            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            delete data['lkpRegimeCategory']
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            if (editable) {
                data['regimeLevelId'] = regimeLevelRowData?.regimeLevelId;
                delete data['createdate'];
                delete data['createuser'];
                delete data['isActive'];
                delete data['lastupdatedate'];
                delete data['lastupdateuser'];
                delete data['updateindex'];
                delete data['filterType'];
                delete data['lkpStatus'];
                delete data['createdBy']
                delete data['updatedBy']

                const res = await dispatch(handlePostRequest(newData, '/tax/v1/updateRegimeLevel', true, false, 'taxprofilemaintenance'));
                if (res?.responseCode === '040000') {
                    formik.resetForm();
                    onHideRegimeLevel();
                    setGetTaxProfileData('');
                }
            } else {
                const res = await dispatch(handlePostRequest(newData, '/tax/v1/createregimelevel', true, false, 'taxprofilemaintenance'));
                if (res?.responseCode === '040000') {
                    formik.resetForm();
                    onHideRegimeLevel();
                    setGetTaxProfileData('');
                }
            }

            setloading(false);
            setloadingIcon('pi pi-save');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleCancel = (e) => {
        e.preventDefault();
        onHideRegimeLevel();
        formik.resetForm();
    };
    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">{/* <h1>{editable ? 'Update Kyc Sets' : 'Add Kyc Sets'}</h1> */}</div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="regimeCategory" className={classNames({ 'p-error': isFormFieldValid('regimeCategory') }, 'Label__Text')}>
                                    Regime Category<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="regimeCategory"
                                    placeholder="Select Regime Category"
                                    options={regimeLevelValues}
                                    optionLabel="name"
                                    name="regimeCategory"
                                    optionValue="lovId"
                                    value={formik.values.regimeCategory || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regimeCategory') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('regimeCategory')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="regimeLevelCode" className={classNames({ 'p-error': isFormFieldValid('regimeLevelCode') }, 'Label__Text')}>
                                    Regime Code<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={20}
                                    id="regimeLevelCode"
                                    placeholder="Enter Regime Code"
                                    name="regimeLevelCode"
                                    value={formik?.values?.regimeLevelCode?.trim()?.toString() || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regimeLevelCode') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('regimeLevelCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="regimeLevelName" className={classNames({ 'p-error': isFormFieldValid('regimeLevelName') }, 'Label__Text')}>
                                    Regime Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={20}
                                    id="regimeLevelName"
                                    placeholder="Enter Regime Name"
                                    name="regimeLevelName"
                                    value={formik?.values?.regimeLevelName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regimeLevelName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('regimeLevelName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="regimeLevelDescr" className={classNames({ 'p-error': isFormFieldValid('regimeLevelDescr') }, 'Label__Text')}>
                                    Description
                                </label>
                                <InputTextarea
                                    maxLength={500}
                                    autoResize="false"
                                    id="regimeLevelDescr"
                                    placeholder="Enter Description..."
                                    name="regimeLevelDescr"
                                    value={formik?.values?.regimeLevelDescr?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regimeLevelDescr') }, 'Input__Round')}
                                    rows={3}
                                    cols={30}
                                />
                                {getFormErrorMessage('regimeLevelDescr')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" disabled={loading} loadingIcon={loadingIcon}  label={editable ? 'Update' : 'Submit'} className="Btn__Add" />
                        <Button label="Cancel" className="Btn__cancel" onClick={(e) => handleCancel(e)} />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddRegimeLevelDialog;

import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../../../services/PostTemplate';

function AccountStatementDialogChecker({ onHideAccountStatement, mobileNo , agentId}) {
     const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [loading, setloading] = useState(false);

    const dispatch = useDispatch()

    
     const formik = useFormik({
         initialValues: {
             email: '',
             mobileNumber: mobileNo,
             fromDate: '',
             toDate: '',
             text: '',
             customerId:agentId
         },
           onSubmit: async (data) => {
            setloading(true);


            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data,
                    },
                    additionalInformation: [],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/account/v1/agentaccount/generateaccountstatement', true, false, 'accountsmanagement'));
               setloading(false);
               onHideAccountStatement()
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleCancel = (e) => {
         e.preventDefault();
        onHideAccountStatement();
        formik.resetForm();
    }

    return (
        <>
             <div className="card Card__Round">
            <form className="p-fluid p-mt-2" onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid grid mb-5">
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') }, 'Label__Text')}>
                                    Email<span className="Label__Required">*</span>
                                </label>
                            <InputText
                                type="email"
                                maxLength={20}
                                id="email"
                                placeholder="Please Enter Email"
                                name="email"
                                value={formik?.values?.email || ''}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('email') }, 'Input__Round')}
                            />

                            {getFormErrorMessage('email')}
                            </div>
                            
                        </div>
                         <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="fromDate" className={classNames({ 'p-error': isFormFieldValid('fromDate') }, 'Label__Text')}>
                                    Date From<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                type="date"
                                maxLength={20}
                                id="fromDate"
                                placeholder="Please Enter fromDate"
                                name="fromDate"
                                value={formik?.values?.fromDate || ''}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('fromDate') }, 'Input__Round')}
                            />

                            {getFormErrorMessage('fromDate')}
                            </div>
                            
                        </div>
                         <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="toDate" className={classNames({ 'p-error': isFormFieldValid('toDate') }, 'Label__Text')}>
                                    Date to<span className="Label__Required">*</span>
                                </label>
                            <InputText
                                type="date"
                                maxLength={20}
                                id="toDate"
                                placeholder="Please Enter toDate"
                                name="toDate"
                                value={formik?.values?.toDate || ''}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('toDate') }, 'Input__Round')}
                            />

                            {getFormErrorMessage('toDate')}
                            </div>
                            
                    </div>
                    </div>
                     <div className="Down__Btn">
                        <Button label="Ok" className="Btn__cancel" onClick={(e) => handleCancel(e)} />
                    </div>
                </form>
                </div>
        </>
    );
}

export default AccountStatementDialogChecker;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';

function AddCardInstallmentPlan() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [feeType, setFeeType] = useState([]);

    const validationSchema = Yup.object({
        installmentPlanName: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
            .required('This Field is required'),
        lkpDcFeeTypeId: Yup.string().required('This Field is required'),
        installmentPlan: Yup.string().required('This Field is required'),
        noOfInstallment: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[0-9]*$/, 'Only Numbers are allowed')
            .test('not-only-zeros', 'Value must be greater than 0', (value) => !(value && /^[0]*$/.test(value)))
            .required('This Field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            dcInstallmentPlanId: '',
            installmentPlanName: '',
            lkpDcFeeTypeId: '',
            installmentPlan: '',
            noOfInstallment: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(newData, '/debitcardmanagement/v1/cards/createinstallmentplan', true, false, 'cardinstallmentplan'));
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const getInstallmentPlanData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getinstallmentplandata');
        const feeTypeList = res?.payLoad?.feeType?.map((item) => {
            // save only those whose isActive is Y
            if (item.isActive === 'Y') {
                return item;
            }
        });
        setFeeType(feeTypeList);
    };

    useEffect(() => {
        getInstallmentPlanData();
    }, []);

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>ADD NEW</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="installmentPlanName" className={classNames({ 'p-error': isFormFieldValid('installmentPlanName') }, 'Label__Text')}>
                                    Installment Name
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={50}
                                    minLength={3}
                                    id="installmentPlanName"
                                    name="installmentPlanName"
                                    placeholder="Enter Installment Name"
                                    value={formik?.values?.installmentPlanName?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('installmentPlanName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('installmentPlanName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lkpDcFeeTypeId" className={classNames({ 'p-error': isFormFieldValid('lkpDcFeeTypeId') }, 'Label__Text')}>
                                    Fee Type
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="lkpDcFeeTypeId"
                                    options={feeType}
                                    optionLabel="name"
                                    name="lkpDcFeeTypeId"
                                    placeholder="Select Fee Type"
                                    optionValue="lovId"
                                    value={formik.values.lkpDcFeeTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpDcFeeTypeId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('lkpDcFeeTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="installmentPlan" className={classNames({ 'p-error': isFormFieldValid('installmentPlan') }, 'Label__Text')}>
                                    Installment Plan
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="installmentPlan"
                                    options={[
                                        { name: 'Weekly', lovId: 'W' },
                                        { name: 'Monthly', lovId: 'M' },
                                        { name: 'Yearly', lovId: 'Y' }
                                    ]}
                                    optionLabel="name"
                                    name="installmentPlan"
                                    placeholder="Select Installment Plan"
                                    optionValue="lovId"
                                    value={formik.values.installmentPlan || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('installmentPlan') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('installmentPlan')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="noOfInstallment" className={classNames({ 'p-error': isFormFieldValid('noOfInstallment') }, 'Label__Text')}>
                                    No. of Installment
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={2}
                                    id="noOfInstallment"
                                    name="noOfInstallment"
                                    placeholder="Enter No. of Installment"
                                    value={formik?.values?.noOfInstallment?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('noOfInstallment') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('noOfInstallment')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} label="Submit" type="submit" className="Btn__Dark" />
                        <Button onClick={() => navigate('/cardinstallmentplan')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default AddCardInstallmentPlan;

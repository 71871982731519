import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { toast } from 'react-toastify';

function EditDocument({ activeIndex, handleStepClick, lendingData, lendingIdData, setStepData4 }) {
    const [loading, setloading] = useState(false);
    const [documentTableData, setDocumentTableData] = useState([]);
    const [documentData, setDocumentData] = useState({
        documentName: '',
        documentExtension: '',
        documentSize: ''
    });
    const [documentError, setDocumentError] = useState({
        documentName: '',
        documentExtension: '',
        documentSize: ''
    });

    useEffect(() => {
        if (lendingData) {
            if (lendingData?.tblLmsDocuments) {
                const modifiedArray = lendingData?.tblLmsDocuments
                    ?.filter((item) => item.isActive === 'Y')
                    .map((item) => ({
                        // lmsDocumentId: item.lmsDocumentId,
                        documentExtension: item.allowedExtension,
                        documentSize: item.allowedSize,
                        documentName: item.documentName
                    }));
                setDocumentTableData(modifiedArray);
            }
        }
    }, [lendingData]); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            documentName: '',
            documentExtension: '',
            documentSize: ''
        },

        onSubmit: async (data) => {
            setloading(true);

            if (documentTableData.length <= 0) {
                toast.warn('Please add data for document');
                setloading(false);
                return;
            }

            const documentData = {
                lmsProductId: lendingIdData,
                currentStep: 4,
                documentInformationRequests: documentTableData
            };

            setStepData4(documentData);
            handleStepClick(activeIndex + 1);

            setloading(false);
        }
    });

    const handledDeleteFieldsData = (e, rowData, rowIndex) => {
        e.preventDefault();
        const newFilterData = documentTableData?.filter((value, index) => index !== rowIndex?.rowIndex);
        setDocumentTableData(newFilterData);
    };

    const actionFieldsBodyTemplate = (rowData, rowIndex) => {
        return (
            <>
                <div className="actions">
                    <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteFieldsData(e, rowData, rowIndex)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
                </div>
            </>
        );
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handledFieldInputdocumentData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        // const checked = e.target.checked;
        // const type = e.target.type;

        setDocumentData({ ...documentData, [name]: value });

        // Clear the error message for the field being edited
        setDocumentError({
            ...documentError,
            [name]: ''
        });
    };

    const handleDocumentAdd = (e) => {
        e.preventDefault();

        const errors = {
            documentName: '',
            documentExtension: '',
            documentSize: ''
        };

        if (documentData.documentName === '') {
            errors.documentName = 'This field is required';
        } else if (!/^[^\s]+(\s[^\s]+)*$/.test(documentData.documentName)) {
            errors.documentName = 'Leading and trailing space is not allowed';
        } else if (!/^[a-zA-Z0-9]+(?:[-_][a-zA-Z0-9]+)*$/.test(documentData.documentName)) {
            errors.documentName = 'Please enter alphanumeric data with hyphen or underscore';
        }

        if (documentData.documentExtension === '') {
            errors.documentExtension = 'This field is required';
        }
        if (documentData.documentSize === '') {
            errors.documentSize = 'This field is required';
        }

        setDocumentError(errors);

        if (Object.values(errors).every((error) => error === '')) {
            const newDocumentTableData = {
                documentName: documentData.documentName,
                documentExtension: documentData.documentExtension,
                documentSize: documentData.documentSize
            };
            setDocumentTableData([...documentTableData, newDocumentTableData]);
            setDocumentData({ documentName: '', documentExtension: '', documentSize: '' });
        }
    };

    const allowedExtensionLov = [
        { name: 'html', code: 'html' },
        { name: 'jpg', code: 'jpg' },
        { name: 'Png', code: 'Png' },
        { name: 'Pdf', code: 'Pdf' }
    ];

    const handleSkip = async (e) => {
        e.preventDefault();
        const skipData = {
            lmsProductId: lendingIdData,
            currentStep: 4,
            skip: 'true',
            documentInformationRequests: documentTableData || []
        };

        setStepData4(skipData);
        handleStepClick(activeIndex + 1);
    };

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>DOCUMENT/ADD NEW</h1>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="documentName" className={classNames({ 'p-error': isFormFieldValid('documentName') }, 'Label__Text')}>
                                    Document Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={15}
                                    id="documentName"
                                    placeholder="Enter Document Name"
                                    name="documentName"
                                    value={documentData.documentName || ''}
                                    onChange={handledFieldInputdocumentData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('documentName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('documentName')}
                                <small className="p-error">{documentError.documentName}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="documentExtension" className={classNames({ 'p-error': isFormFieldValid('documentExtension') }, 'Label__Text')}>
                                    Document Extension<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="documentExtension"
                                    placeholder="Select Document Extension"
                                    options={allowedExtensionLov}
                                    optionLabel="name"
                                    name="documentExtension"
                                    optionValue="code"
                                    value={documentData?.documentExtension || ''}
                                    onChange={handledFieldInputdocumentData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('documentExtension') }, 'Dropdown__Round')}
                                />
                                <small className="p-error">{documentError.documentExtension}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="documentSize" className={classNames({ 'p-error': isFormFieldValid('documentSize') }, 'Label__Text')}>
                                    Allowed Size(1Mb,2Mb) <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    keyfilter={'int'}
                                    maxLength={2}
                                    id="documentSize"
                                    placeholder=""
                                    name="documentSize"
                                    value={documentData.documentSize || ''}
                                    onChange={handledFieldInputdocumentData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('documentSize') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('documentSize')}
                                <small className="p-error">{documentError.documentSize}</small>
                            </div>
                        </div>

                        <div className="Down__Btn">
                            <Button disabled={loading} label="Add" onClick={handleDocumentAdd} className="Btn__Dark" style={{ marginTop: '15px' }} />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className="card">
                                <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={documentTableData}>
                                    <Column field="documentName" header="Document Name" />
                                    <Column field="documentExtension" header="Document Extension" />
                                    <Column field="documentSize" header="Allowed Size" />
                                    <Column header="Actions" body={actionFieldsBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label={lendingData?.currentStep > 3 ? 'Next' : 'Save'} className="Btn__Dark" />
                        <Button label={'Skip'} onClick={(e) => handleSkip(e)} className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default EditDocument;

import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { InputText } from 'primereact/inputtext';

export default function DynamicDialogFiles({ visible, onHide, node, header, handleParentChildRelation, fieldLov, isView, errors, setUseCaseError, expandButton, ExpandRule, expand, isEdit, updateDataTreeWithSelectedNode, dropdownOptions }) {
    const [type, setType] = useState();
    const comparisonOperators = [
        { label: 'equal to (=)', value: '=' },
        { label: 'not equal (!=)', value: '!=' },
        { label: 'greater than (>)', value: '>' },
        { label: 'less than (<)', value: '<' },
        { label: 'greater than or equal to (>=)', value: '>=' },
        { label: 'less than or equal to (<=)', value: '<=' }
    ];

    const validationSchema1 = Yup.object({
        fieldType: Yup.string().required('This Field is required'),
        logicalOperator: Yup.string().required('This Field is Required.'),
        values: Yup.mixed().when('selectedValues', {
            is: 'staticValues',
            then: Yup.string().required('This Field is Required.')
        }),
        values1: Yup.mixed().when('selectedValues', {
            is: 'dynamicValues',
            then: Yup.string().required('This Field is Required.')
        })
    });

    const formik1 = useFormik({
        initialValues: { fieldType: '', logicalOperator: '', values: '', values1: '', conditionOperator: '' },
        validationSchema1: validationSchema1
    });

    const handleInputChange = (e) => {
        let { name, value } = e.target;

        if (name === 'fieldType') {
            name = 'fieldType';

            const modifiedData = fieldLov
                .map((item) => {
                    if (item.lovId === value) {
                        const lastThreeDigits = item.name.slice(-3);
                        return { lastThreeDigits };
                    } else {
                        return;
                    }
                })
                .filter((item) => item !== undefined)[0];
            setType(modifiedData);
            formik1.handleChange(e);
        } else if (name === 'logicalOperator') {
            name = 'logicalOperator';
            formik1.handleChange(e);
        } else if (name === 'selectedValues') {
            name = 'selectedValues';
            formik1.handleChange(e);

            // Update the form field with the modified value

            // formik1.handleChange(e);
        } else if (name === 'values') {
            const modifiedString = e.target.value.replace(/\[|\]/g, '');
            name = 'values';
            formik1.setFieldValue(e.target.name, modifiedString);
            // formik1.handleChange(e);
        } else if (name === 'values1') {
            name = 'values1';
            formik1.handleChange(e);
        }
        // Create a new object with the updated error for the specific field
        const updatedErrors = { ...errors, [name]: '' };
        setUseCaseError(updatedErrors);

        //
    };

    const isFormFieldValid = (name) => !!(formik1.touched[name] && formik1.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik1.errors[name]}</small>;
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    useEffect(() => {
        if (node && expand === false && node.label !== 'true' && node.label !== 'false') {
            if (node?.values) {
                formik1.setFieldValue('values', node?.values?.replace(/\{|\}/g, '')?.replace(/\[|\]/g, ''));
                //formik1.setFieldValue('values', node.values);
            }
            if (node?.values1) {
                formik1.setFieldValue('values1', node?.values1);
            }
            formik1.setFieldValue('logicalOperator', node.logicalOperator);
            formik1.setFieldValue('fieldType', node.fieldType);

            formik1.setFieldValue('selectedValues', node.selectedValues);

            // Using regular expression to extract the value between '#' and '='
            const match = node.label?.match(/#([^=]+)=/);
            // Checking if a match is found
            if (match) {
                const extractedValue = match[1].split('#')[1];
                setType({ lastThreeDigits: extractedValue }); // This will log 'DAT'
            } else {
                setType(node?.fieldTypes);
            }

            if (node?.label && !node?.selectedValues) {
                if (node?.label?.includes('{{')) {
                    formik1.setFieldValue('selectedValues', 'dynamicValues');
                    const dynamicDropdownValue = node.values.replace(/\{|\}/g, '');
                    // const getDynamicDropdownValue = dropdownOptions.filter((item) => item.name === dynamicDropdownValue);
                    formik1.setFieldValue('values1', dynamicDropdownValue);
                } else if (node?.label?.includes('[[')) {
                    const dynamicInputValues = node.values.replace(/\[|\]/g, '');
                    formik1.setFieldValue('values', dynamicInputValues);
                    formik1.setFieldValue('selectedValues', 'staticValues');
                }
            }

            // setSelectedValues(node.selectedValues)
        } else {
            formik1.setFieldValue('values', '');
            formik1.setFieldValue('logicalOperator', '');
            formik1.setFieldValue('fieldType', '');

            formik1.setFieldValue('selectedValues', '');
            // setSelectedValues('')
        }
    }, [node]);

    return (
        <Dialog visible={visible} onHide={onHide} modal style={{ width: '40vw', maxHeight: 'auto', height: 'auto' }} blockScroll={true} draggable={false} resizable={false}>
            {node && (
                <div className="card Card__Round">
                    {/* {fields} */}

                    <div className="Form__Header Full__Width">
                        <h2>{header}</h2>
                    </div>
                    <div style={{ marginBottom: '6%' }}>{expandButton == true ? <p>{node.label}</p> : ''}</div>
                    <form onSubmit={formik1.handleSubmit} className="p-fluid">
                        <div className="p-fluid p-formgrid mb-5">
                            <div className="p-field ">
                                <label htmlFor="domain" className={classNames({ 'p-error': isFormFieldValid('fieldType') }, 'Label__Text')}>
                                    Field Type <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="fieldType"
                                    placeholder="Select Field Type"
                                    options={fieldLov}
                                    optionLabel="name"
                                    name="fieldType"
                                    filter
                                    disabled={isView}
                                    optionValue="lovId"
                                    value={formik1?.values.fieldType || ''}
                                    onChange={handleInputChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('fieldType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('domain')}

                                {errors.fieldType !== '' ? <small className="p-error">{errors.fieldType}</small> : getFormErrorMessage('domain')}
                            </div>

                            <div className="p-field mt-6">
                                <label htmlFor="domain" className={classNames({ 'p-error': isFormFieldValid('fieldType') }, 'Label__Text')}>
                                    Logical Operator <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="logicalOperator"
                                    placeholder="Select logicalOperator"
                                    filter
                                    options={comparisonOperators}
                                    optionLabel="label"
                                    disabled={isView}
                                    name="logicalOperator"
                                    optionValue="value"
                                    value={formik1?.values.logicalOperator || ''}
                                    onChange={handleInputChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('fieldType') }, 'Dropdown__Round')}
                                />

                                {errors.logicalOperator !== '' ? <small className="p-error">{errors.logicalOperator}</small> : getFormErrorMessage('domain')}
                            </div>

                            <div className="p-field mt-6">
                                <label htmlFor="selectDynamic" className="Label__Text">
                                    Select Values <span className="Label__Required">*</span>
                                </label>

                                <div className="flex">
                                    <div className="mt-2 mr-5">
                                        <RadioButton
                                            inputId="selectedValues"
                                            name="selectedValues"
                                            value={'dynamicValues' || ''}
                                            disabled={isView}
                                            onChange={handleInputChange}
                                            checked={formik1.values.selectedValues === 'dynamicValues' || formik1.values.selectedValues === 'dynamicValue'}
                                        />

                                        <label htmlFor="selectedValues" className="Label__Text">
                                            Dynamic
                                        </label>
                                    </div>

                                    <div className="mt-2">
                                        <RadioButton
                                            inputId="selectedValues"
                                            name="selectedValues"
                                            disabled={isView}
                                            value={'staticValues' || ''}
                                            onChange={handleInputChange}
                                            checked={formik1.values.selectedValues === 'staticValues' || formik1.values.selectedValues === 'staticValue'}
                                        />

                                        <label htmlFor="selectedValues" className="Label__Text">
                                            Static
                                        </label>
                                    </div>
                                </div>
                                {errors.selectedValues !== '' ? <small className="p-error">{errors.selectedValues}</small> : getFormErrorMessage('selectedValues')}
                            </div>

                            {formik1?.values?.selectedValues === 'staticValues' || formik1?.values?.selectedValues === 'staticValue' ? (
                                <>
                                    <div className="p-field mt-6">
                                        <label htmlFor="values" className={classNames({ 'p-error': isFormFieldValid('values') }, 'Label__Text')}>
                                            Values <span className="Label__Required">*</span>
                                        </label>
                                        {type?.lastThreeDigits === 'DAT' || type?.lastThreeDigits === 'DAT!' ? (
                                            <InputText
                                                // max={previousDate}
                                                disabled={isView}
                                                id="values"
                                                placeholder=""
                                                name="values"
                                                type="date"
                                                value={formik1?.values?.values?.replace(/\s\s+/g, ' ') || ''}
                                                onChange={formik1.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('values') }, 'Input__Round')}
                                            />
                                        ) : // <InputText
                                        //     maxLength={100}
                                        //     id="values"
                                        //     name="values"
                                        //     disabled={isView}
                                        //     value={formik1?.values?.values || ''}
                                        //     onChange={handleInputChange}
                                        //     className={classNames({ 'p-invalid': isFormFieldValid('values') }, 'Input__Round')}
                                        // />
                                        type?.lastThreeDigits === 'NUM' || type?.lastThreeDigits === 'NUM!' ? (
                                            <InputText
                                                maxLength={100}
                                                id="values"
                                                name="values"
                                                keyfilter="num"
                                                disabled={isView}
                                                value={formik1?.values?.values || ''}
                                                onChange={handleInputChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('values') }, 'Input__Round')}
                                            />
                                        ) : type?.lastThreeDigits === 'STR' ? (
                                            // Your STR component or JSX here
                                            <InputText
                                                maxLength={100}
                                                id="values"
                                                name="values"
                                                // keyfilter={!/[^\[\]]/}
                                                disabled={isView}
                                                value={formik1?.values?.values || ''}
                                                onChange={handleInputChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('values') }, 'Input__Round')}
                                            />
                                        ) : (
                                            // Default case, handle as needed
                                            <></>
                                        )}

                                        {errors.values !== '' ? <small className="p-error">{errors.values}</small> : getFormErrorMessage('domain')}
                                    </div>
                                </>
                            ) : formik1?.values?.selectedValues === 'dynamicValues' || formik1?.values?.selectedValues === 'dynamicValue' ? (
                                <>
                                    <div className="p-field mt-6">
                                        <label htmlFor="values1" className={classNames({ 'p-error': isFormFieldValid('values1') }, 'Label__Text')}>
                                            Values <span className="Label__Required">*</span>
                                        </label>
                                        {
                                            // Your STR component or JSX here
                                            <Dropdown
                                                disabled={isView}
                                                id="values1"
                                                placeholder="Select Rule Field"
                                                options={dropdownOptions}
                                                optionLabel="name"
                                                optionValue="name"
                                                name="values1"
                                                value={formik1?.values?.values1 || ''}
                                                onChange={handleInputChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('values1') }, 'Dropdown__Round')}
                                            />
                                            // <InputText
                                            //     maxLength={100}
                                            //     id="values1"
                                            //     name="values1"
                                            //     keyfilter="alpha"
                                            //     disabled={isView}
                                            //     value={formik1?.values?.values1 || ''}
                                            //     onChange={handleInputChange}
                                            //     className={classNames({ 'p-invalid': isFormFieldValid('values1') }, 'Input__Round')}
                                            // />
                                        }

                                        {errors.values1 !== '' ? <small className="p-error">{errors.values1}</small> : getFormErrorMessage('domain')}
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            <div className="Down__Btn mt-8">
                                {expandButton === true ? (
                                    <Button
                                        type="button"
                                        label="Expand Rule"
                                        onClick={() => {
                                            if ((formik1?.values?.selectedValues === 'staticValues' || formik1?.values?.selectedValues === 'staticValue') && formik1.values.values === '') {
                                                setUseCaseError({ values: 'This Field is Required.' });
                                                return;
                                            }
                                            if ((formik1?.values?.selectedValues === 'dynamicValues' || formik1?.values?.selectedValues === 'dynamicValue') && formik1.values.values1 === '') {
                                                setUseCaseError({ values1: 'This Field is Required.' });
                                                return;
                                            }
                                            ExpandRule(formik1);
                                        }}
                                        className="Btn__Dark"
                                    />
                                ) : isEdit && !isView ? (
                                    <Button
                                        type="button"
                                        label="Update Rule"
                                        disabled={isView}
                                        onClick={() => {
                                            if ((formik1?.values?.selectedValues === 'staticValues' || formik1?.values?.selectedValues === 'staticValue') && formik1.values.values === '') {
                                                setUseCaseError({ values: 'This Field is Required.' });
                                                return;
                                            }
                                            if ((formik1?.values?.selectedValues === 'dynamicValues' || formik1?.values?.selectedValues === 'dynamicValue') && formik1.values.values1 === '') {
                                                setUseCaseError({ values1: 'This Field is Required.' });
                                                return;
                                            }
                                            updateDataTreeWithSelectedNode(node, formik1);
                                        }}
                                        className="Btn__Dark"
                                    />
                                ) : isView ? (
                                    <Button
                                        type="button"
                                        label="Okay"
                                        onClick={() => {
                                            onHide();
                                        }}
                                        className="Btn__Dark"
                                    />
                                ) : (
                                    <Button
                                        type="button"
                                        label="Add Rule"
                                        onClick={() => {
                                            if ((formik1?.values?.selectedValues === 'staticValues' || formik1?.values?.selectedValues === 'staticValue') && formik1.values.values === '') {
                                                setUseCaseError({ values: 'This Field is Required.' });
                                                return;
                                            }
                                            if ((formik1?.values?.selectedValues === 'dynamicValues' || formik1?.values?.selectedValues === 'dynamicValue') && formik1.values.values1 === '') {
                                                setUseCaseError({ values1: 'This Field is Required.' });
                                                return;
                                            }
                                            handleParentChildRelation(formik1, type);
                                        }}
                                        className="Btn__Dark"
                                    />
                                )}
                            </div>
                        </div>
                    </form>

                    {/* Display other details of the selected node */}
                </div>
            )}
        </Dialog>
    );
}

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { Checkbox } from 'primereact/checkbox';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

const ViewCheckerLocation = () => {

    const [mcReqData, setMcReqData] = useState([]);

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const tableName = query.get('tableName');
    const requestTypeDescr = query.get('requestTypeDescr');

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('Action should not be empty'),
        checkerComments: Yup.string().required('This Field is required').notOneOf([''], 'This Field should not be empty').max(500, 'Maximum 500 characters needed')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            lkpBranchName: '',
            lkpBranchCode: '',
            lkpBranchDescription: '',
            coaCode: '',
            action: '',
            checkerComments: ''
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getCostCenterById = async () => {
        const resp = await handleGetRequest(`/account/v1/chartofaccount/getlkpbranchbyid/${refTableId}`);
        const keyData = resp?.payLoad;
        if (keyData !== null && typeof keyData !== 'undefined') {
            formik.setFieldValue('lkpBranchName', keyData?.branchName);
            formik.setFieldValue('lkpBranchCode', keyData?.branchCode);
            formik.setFieldValue('lkpBranchDescription', keyData?.branchDescription);
            formik.setFieldValue('coaCode', keyData?.coaCode);
        }
    };

    const getCostCenterMcRequestDataById = async () => {
        const resp = await handleGetRequest(`/account/v1/chartofaccount/getlkpbranchbymcrequestid/${mcRequestId}`);
        const keyData = resp?.payLoad;
        if (keyData !== null && typeof keyData !== 'undefined') {
            setMcReqData(keyData)
            if (requestType === 'U' && requestTypeDescr !== "Enable/Disable Request") {
                formik.setFieldValue('lkpBranchName', keyData?.lkpBranchName);
                formik.setFieldValue('lkpBranchCode', keyData?.lkpBranchCode);
                formik.setFieldValue('lkpBranchDescription', keyData?.lkpBranchDescription);
                formik.setFieldValue('coaCode', keyData?.coaCode);
            }
        }
    };

    useEffect(() => {
        if (requestType === 'I') {
            getCostCenterById();
        } else if (requestType === 'U' && requestTypeDescr !== "Enable/Disable Request") {
            getCostCenterMcRequestDataById();
        } else if (requestType === 'U' && requestTypeDescr === "Enable/Disable Request") {
            getCostCenterById();
            getCostCenterMcRequestDataById()
        }
    }, []);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>View Details</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Location Name</label>
                                <InputText id="lkpBranchName" value={formik.values.lkpBranchName || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('lkpBranchName') }, 'Input__Round')} maxLength={30} />
                                {getFormErrorMessage('lkpBranchName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Location Code</label>
                                <InputText id="lkpBranchCode" value={formik.values.lkpBranchCode || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('lkpBranchCode') }, 'Input__Round')} maxLength={30} />
                                {getFormErrorMessage('lkpBranchCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Location Description</label>
                                <InputText
                                    id="lkpBranchDescription"
                                    value={formik.values.lkpBranchDescription || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpBranchDescription') }, 'Input__Round')}
                                    maxLength={199}
                                />
                                {getFormErrorMessage('lkpBranchDescription')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Coa Code</label>
                                <InputText id="coaCode" value={formik.values.coaCode || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('coaCode') }, 'Input__Round')} maxLength={30} />
                                {getFormErrorMessage('coaCode')}
                            </div>
                        </div>

                        {
                            (requestType === 'U' && requestTypeDescr === "Enable/Disable Request") &&
                            (
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label className="Label__Text">Is Active</label>
                                        <Checkbox checked={mcReqData?.isActive === "Y"} disabled className='w-full' />
                                    </div>
                                </div>
                            )
                        }

                    </div>

                    <div className="Down__Btn">
                        <Button type="button" label="Okay" className="Btn__Dark" onClick={() => navigate(-1)} />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />

        </>
    );
};

export default ViewCheckerLocation;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { Checkbox } from 'primereact/checkbox';
import { InputSwitch } from 'primereact/inputswitch';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import ScrollMover from '../../../../components/ScrollMover';

function DefaultComponent({ type, getByIdData }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setloading] = useState(false);
    const [checkerDropdown, setCheckerDropdown] = useState([]);
    const [makerDropDown, setMakerDropDown] = useState([]);
    const [statusTableData, setStatusTableData] = useState([]);
    const [addpayload, setAddPayload] = useState([]);
    const [editPayload, setEditPayload] = useState([]);
    const [alreadyAssignedStatus, setAlreadyAssignedStatus] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        accountStatusName: { value: '', matchMode: FilterMatchMode.STARTS_WITH }
    });

    const funcCheckerDropdown = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getagentdatabyaccounttype/TBL_USER/${formik?.values?.roleId}`);
        setCheckerDropdown(resp?.payLoad);
    };

    const funcMakerDropDown = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getagentdatabyaccounttype/TBL_MC/${formik?.values?.roleId}`);
        setMakerDropDown(resp?.payLoad);
    };

    const AddEditInitialValues = {
        roleId: '',
        checkerUserId: '',
        makerUserId: ''
    };

    const AddEditValidationSchema = Yup.object().shape({
        roleId: Yup.string().required('This field is required'),
        checkerUserId: Yup.string()
            .required('This field is required')
            .notOneOf([Yup.ref('makerUserId')], 'Maker and checker cannot be the same'),
        makerUserId: Yup.string()
            .required('This field is required')
            .notOneOf([Yup.ref('checkerUserId')], 'Maker and checker cannot be the same')
    });

    const formik = useFormik({
        validationSchema: type === 'edit' || type === 'add' ? AddEditValidationSchema : null,
        initialValues: type === 'edit' || type === 'view' || type === 'add' ? AddEditInitialValues : null,

        onSubmit: async (data) => {
            setloading(true);
            const payloadAdd = {
                statusId: 2,
                isActive: 'Y',
                roleId: data?.roleId,
                checkerUserId: data?.checkerUserId,
                makerUserId: data?.makerUserId,
                accountStatusId: addpayload
            };
            const payloadEdit = {
                statusId: 2,
                roleId: data?.roleId,
                checkerUserId: data?.checkerUserId,
                makerUserId: data?.makerUserId,
                cxStatusConfigId: getByIdData?.getByRoleId?.cxStatusConfigId || 0,
                updateCxStatusConfigRequestChildren: editPayload
            };
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: type === 'add' ? payloadAdd : type === 'edit' ? payloadEdit : null
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            };
            if (type === 'add') {
                await dispatch(handlePostRequest(newData, '/account/v1/customeraccounts/savecxroleaccountstatus', true, false, 'cxrolemanagement'));
            }
            if (type === 'edit') {
                await dispatch(handlePostRequest(newData, '/account/v1/customeraccounts/updatecxroleaccountstatus', true, false, 'cxrolemanagement'));
            }
            setloading(false);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">Account Status</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const header = () => renderHeader();

    const actionCheckboxTemplate = (rowData) => {
        return (
            <>
                <div className="actions justify-content-start ml-3">
                    <Checkbox
                        checked={rowData.checkboxActive === 'Y' || rowData.checkboxActive === 'N' || rowData?.isActive === 'Y'}
                        disabled={type === 'view' || rowData.checkboxActive === 'Y' || rowData.checkboxActive === 'N'}
                        onChange={() => {
                            handleCheckboxIsActive(rowData?.accountStatusId);
                        }}
                    />
                </div>
            </>
        );
    };

    const handleCheckboxIsActive = (accountId) => {
        setStatusTableData((prevData) => {
            return prevData.map((item) => {
                if (item.accountStatusId === accountId) {
                    return {
                        ...item,
                        isActive: (item.checkboxActive === 'Y' || item.checkboxActive === 'N') && item.isActive === 'Y' ? 'N' : item.checkboxActive === '' && item.isActive === 'Y' ? '' : 'Y',
                        toggleActive: item.toggleActive === 'Y' ? 'N' : 'Y'
                    };
                }
                return item;
            });
        });
    };

    const actionToggleTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <InputSwitch
                        checked={rowData.toggleActive === 'Y'}
                        onChange={() => {
                            handleToggleIsActive(rowData?.accountStatusId);
                        }}
                        disabled={type === 'view' || rowData?.isActive === '' || (rowData?.isActive === 'Y' && rowData?.checkboxActive === '')}
                    />
                </div>
            </>
        );
    };

    const handleToggleIsActive = (accountId) => {
        setStatusTableData((prevData) => {
            return prevData.map((item) => {
                if (item.accountStatusId === accountId) {
                    return {
                        ...item,
                        toggleActive: item.toggleActive === 'Y' ? 'N' : 'Y'
                    };
                }
                return item;
            });
        });
    };

    useEffect(() => {
        if (type === 'add' && formik?.values?.roleId) {
            funcCheckerDropdown();
            funcMakerDropDown();
            formik.setFieldValue('checkerUserId', '');
            formik.setFieldValue('makerUserId', '');
        }
    }, [formik?.values?.roleId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (getByIdData?.getByRoleId) {
            formik.setFieldValue('roleId', getByIdData?.getByRoleId?.roleId?.toString());
            formik.setFieldValue('checkerUserId', getByIdData?.getByRoleId?.checkerUserId?.toString());
            formik.setFieldValue('makerUserId', getByIdData?.getByRoleId?.makerUserId?.toString());
            setAlreadyAssignedStatus(getByIdData?.getByRoleId?.cxStatusConfigResponseChild || []);
        }
    }, [getByIdData?.getByRoleId]); // eslint-disable-line react-hooks/exhaustive-deps

    // set status table data
    useEffect(() => {
        if (getByIdData?.statusDropdown && alreadyAssignedStatus) {
            const updatedTableData = getByIdData?.statusDropdown?.map((item1) => {
                const matchedItem = alreadyAssignedStatus.find((item2) => item1.accountStatusId === String(item2.accountStatusId));
                return {
                    ...item1,
                    isActive: matchedItem?.isActive || '',
                    checkboxActive: matchedItem?.isActive || '',
                    toggleActive: matchedItem?.isActive || '',
                    cxStatusConfigDetailId: matchedItem?.cxStatusConfigDetailId || 0
                };
            });
            setStatusTableData(updatedTableData);
        }
    }, [getByIdData?.statusDropdown, alreadyAssignedStatus]); // eslint-disable-line react-hooks/exhaustive-deps

    // set payload for add
    useEffect(() => {
        if (statusTableData?.length > 0 && type === 'add') {
            const accountStatusId = [];
            statusTableData?.map((item) => {
                if (item?.isActive === 'Y') {
                    accountStatusId.push(item.accountStatusId);
                }
            });
            setAddPayload(accountStatusId);
        }
    }, [statusTableData]); // eslint-disable-line react-hooks/exhaustive-deps

    // set payload for edit
    useEffect(() => {
        if (statusTableData?.length > 0 && type === 'edit') {
            const updatedPayload = statusTableData
                ?.map((item) => {
                    if (item?.isActive !== '') {
                        return {
                            accountStatusId: item.accountStatusId,
                            cxStatusConfigDetailId: item.cxStatusConfigDetailId,
                            isActive: item?.checkboxActive === 'Y' || item?.checkboxActive === 'N' ? item?.toggleActive : item?.checkboxActive === '' ? item?.isActive : ''
                        };
                    }
                    return undefined;
                })
                .filter(Boolean);
            setEditPayload(updatedPayload);
        }
    }, [statusTableData, type]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (editPayload?.length > 0) {
        }
    }, [editPayload]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h5 className="uppercase">{type === 'add' ? 'Add New' : type === 'edit' ? 'Edit Detail' : type === 'view' ? 'View Detail' : null}</h5>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="roleId" className={classNames({ 'p-error': isFormFieldValid('roleId') }, 'Label__Text')}>
                                    Department <span className="Label__Required">*</span>
                                </label>
                                {type === 'add' ? (
                                    <Dropdown
                                        id="roleId"
                                        placeholder="Select Department"
                                        options={getByIdData?.roleDropdown || []}
                                        optionLabel="roleDescription"
                                        name="roleId"
                                        optionValue="roleId"
                                        value={formik?.values?.roleId || ''}
                                        onChange={formik.handleChange}
                                        disabled={type !== 'add'}
                                        className={classNames({ 'p-invalid': isFormFieldValid('roleId') }, 'Dropdown__Round')}
                                    />
                                ) : (
                                    <InputText
                                        id="roleId"
                                        name="roleId"
                                        placeholder="Enter Department Name"
                                        value={getByIdData?.getByRoleId?.roleDescription || ''}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('roleId') }, 'Input__Round')}
                                    />
                                )}
                                {getFormErrorMessage('roleId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="makerUserId" className={classNames({ 'p-error': isFormFieldValid('makerUserId') }, 'Label__Text')}>
                                    Maker <span className="Label__Required">*</span>
                                </label>
                                {type === 'add' ? (
                                    <Dropdown
                                        id="makerUserId"
                                        name="makerUserId"
                                        placeholder="Select Maker"
                                        options={makerDropDown || []}
                                        optionLabel="name"
                                        optionValue="lovId"
                                        value={formik?.values?.makerUserId || ''}
                                        onChange={formik.handleChange}
                                        disabled={type !== 'add'}
                                        className={classNames({ 'p-invalid': isFormFieldValid('makerUserId') }, 'Dropdown__Round')}
                                    />
                                ) : (
                                    <InputText
                                        id="makerUserId"
                                        name="makerUserId"
                                        placeholder="Enter Maker Name"
                                        value={getByIdData?.getByRoleId?.makerUserName || ''}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('makerUserId') }, 'Input__Round')}
                                    />
                                )}
                                {getFormErrorMessage('makerUserId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="checkerUserId" className={classNames({ 'p-error': isFormFieldValid('checkerUserId') }, 'Label__Text')}>
                                    Checker <span className="Label__Required">*</span>
                                </label>
                                {type === 'add' ? (
                                    <Dropdown
                                        id="checkerUserId"
                                        name="checkerUserId"
                                        placeholder="Select Checker"
                                        options={checkerDropdown || []}
                                        optionLabel="name"
                                        optionValue="lovId"
                                        value={formik?.values?.checkerUserId || ''}
                                        onChange={formik.handleChange}
                                        disabled={type !== 'add'}
                                        className={classNames({ 'p-invalid': isFormFieldValid('checkerUserId') }, 'Dropdown__Round')}
                                    />
                                ) : (
                                    <InputText
                                        id="checkerUserId"
                                        name="checkerUserId"
                                        placeholder="Enter Checker Name"
                                        value={getByIdData?.getByRoleId?.checkerUserName || ''}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('checkerUserId') }, 'Input__Round')}
                                    />
                                )}
                                {getFormErrorMessage('checkerUserId')}
                            </div>
                        </div>
                    </div>
                </form>
                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <DataTable
                                paginator
                                rows={5}
                                rowsPerPageOptions={[5, 10, 25]}
                                className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                                filters={filters}
                                globalFilterFields={['accountStatusName']}
                                emptyMessage="No List found."
                                header={header}
                                responsiveLayout="scroll"
                                value={statusTableData || []}
                            >
                                <Column header="Assign Status" body={actionCheckboxTemplate} />
                                <Column field="accountStatusName" header="Status Name" />
                                <Column header="Active/Inactive" body={actionToggleTemplate} className="Table__ActionHeading__CentredAlign" />
                            </DataTable>
                        </div>
                    </div>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="Down__Btn py-3">
                        {type === 'add' && (
                            <React.Fragment>
                                <Button className="Btn__Dark" disabled={loading} type="submit" onClick={() => ScrollMover()} label={'Submit'} />
                                <Button type="button" onClick={() => navigate(-1)} label="Cancel" className="Btn__Transparent" />
                            </React.Fragment>
                        )}
                        {type === 'edit' && (
                            <React.Fragment>
                                <Button className="Btn__Dark" disabled={loading} type="submit" onClick={() => ScrollMover()} label={'Update'} />
                                <Button type="button" onClick={() => navigate(-1)} label="Cancel" className="Btn__Transparent" />
                            </React.Fragment>
                        )}
                        {type === 'view' && <Button type="button" onClick={() => navigate(-1)} label="Okay" className="Btn__Dark" />}
                    </div>
                </form>
            </div>
        </>
    );
}
export default DefaultComponent;

import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { Divider } from 'primereact/divider';

const DebitCard = ({ type }) => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        clientEmail: Yup.string().matches('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$', 'Please enter valid email')
    });
    const formik = useFormik({
        // validationSchema: validationSchema,
        initialValues: {},
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            // const res = await dispatch(handlePostRequest(newData, '/account/v1/segments/createsegment', true, false, 'segment'));
            // if (res?.responseCode === '010000') {
            //     formik.resetForm();
            // }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="pt-2">
                <h6 className="secondary-color">
                    <b>DEBIT CARD</b>
                </h6>
            </div>

            <Divider />

            <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                <div className="p-fluid p-formgrid grid mb-5">
                    <div className="p-field col-12 md:col-6 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="cardTitleName" className={classNames({ 'p-error': isFormFieldValid('cardTitleName') }, 'Label__Text')}>
                                Card Title Name
                            </label>
                            <InputText
                                readOnly={type === 'ViewPage'}
                                maxLength={100}
                                id="cardTitleName"
                                placeholder="Enter Title Name"
                                name="cardTitleName"
                                value={formik?.values?.cardTitleName?.replace(/\s\s+/g, ' ') || ''}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('cardTitleName') }, 'Input__Round')}
                            />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-6 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="cardNo" className={classNames({ 'p-error': isFormFieldValid('cardNo') }, 'Label__Text')}>
                                Card Number
                            </label>
                            <InputText
                                readOnly={type === 'ViewPage'}
                                maxLength={100}
                                id="cardNo"
                                placeholder="Enter Card Number"
                                name="cardNo"
                                value={formik?.values?.cardNo?.trim()?.toString() || ''}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('cardNo') }, 'Input__Round')}
                            />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-6 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="expiryDate" className={classNames({ 'p-error': isFormFieldValid('expiryDate') }, 'Label__Text')}>
                                Expiry Date
                            </label>
                            <InputText
                                readOnly={type === 'ViewPage'}
                                id="expiryDate"
                                name="expiryDate"
                                type="date"
                                value={formik?.values?.expiryDate || ''}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('expiryDate') }, 'Input__Round')}
                            />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-6 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="issuanceDate" className={classNames({ 'p-error': isFormFieldValid('issuanceDate') }, 'Label__Text')}>
                                Issuance Date
                            </label>
                            <InputText
                                readOnly={type === 'ViewPage'}
                                id="issuanceDate"
                                name="issuanceDate"
                                type="date"
                                value={formik?.values?.issuanceDate || ''}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('issuanceDate') }, 'Input__Round')}
                            />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-6 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="accountNo" className={classNames({ 'p-error': isFormFieldValid('accountNo') }, 'Label__Text')}>
                                Account Number
                            </label>
                            <InputText
                                readOnly={type === 'ViewPage'}
                                id="accountNo"
                                name="accountNo"
                                placeholder="Enter account number"
                                value={formik?.values?.accountNo?.trim()?.toString() || ''}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('accountNo') }, 'Input__Round')}
                            />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-6 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="productId" className={classNames({ 'p-error': isFormFieldValid('productId') }, 'Label__Text')}>
                                Product
                            </label>
                            <Dropdown
                                readOnly={type === 'ViewPage'}
                                id="productId"
                                placeholder="Select Product"
                                // options={productData}
                                optionLabel="name"
                                name="productId"
                                optionValue="lovId"
                                value={formik.values.productId || ''}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('productId') }, 'Dropdown__Round')}
                            />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-6 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="cardStatus" className={classNames({ 'p-error': isFormFieldValid('cardStatus') }, 'Label__Text')}>
                                Card Status
                            </label>
                            <div className="flex align-items-center">
                                <Dropdown
                                    readOnly={type === 'ViewPage'}
                                    id="cardStatus"
                                    placeholder="Select Card Status"
                                    // options={cardStatusData}
                                    optionLabel="name"
                                    name="cardStatus"
                                    optionValue="lovId"
                                    value={formik.values.cardStatus || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cardStatus') }, 'Dropdown__Round mr-2')}
                                />
                                <i className={`field-edit-icon pi pi-pencil ${type === 'ViewPage' ? 'p-disabled' : ''}`} onClick={() => {}} />
                            </div>
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-6 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="deliveryStatus" className={classNames({ 'p-error': isFormFieldValid('deliveryStatus') }, 'Label__Text')}>
                                Debit Card Delivery Status
                            </label>
                            <div className="flex align-items-center">
                                <InputText
                                    readOnly={type === 'ViewPage'}
                                    maxLength={100}
                                    id="deliveryStatus"
                                    placeholder="Enter Delivery Status"
                                    name="deliveryStatus"
                                    value={formik?.values?.deliveryStatus?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('deliveryStatus') }, 'Input__Round mr-2')}
                                />
                                <i className="field-eye-icon pi pi-eye" onClick=""></i>
                            </div>
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-6 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="reasonForBlocking" className={classNames({ 'p-error': isFormFieldValid('reasonForBlocking') }, 'Label__Text')}>
                                Reason For Blocking (Blocked By)
                            </label>
                            <InputText
                                readOnly={type === 'ViewPage'}
                                maxLength={11}
                                id="reasonForBlocking"
                                placeholder="Enter Reasons"
                                name="reasonForBlocking"
                                value={formik?.values?.reasonForBlocking?.replace(/\D/g, '') || ''}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('reasonForBlocking') }, 'Input__Round')}
                            />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-6 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="branchName" className={classNames({ 'p-error': isFormFieldValid('branchName') }, 'Label__Text')}>
                                Branch Name
                            </label>
                            <InputText
                                readOnly={type === 'ViewPage'}
                                maxLength={100}
                                id="branchName"
                                placeholder="Enter Branch Name"
                                name="branchName"
                                value={formik?.values?.branchName?.replace(/\s\s+/g, ' ') || ''}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('branchName') }, 'Input__Round')}
                            />
                        </div>
                    </div>
                </div>

                <div className="Down__Btn">
                    <Button label="View More" onClick={() => navigate('/editzindigicarddetails')} className="Btn__Dark" />
                </div>
            </form>
        </>
    );
};

export default DebitCard;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handleGetRequest } from '../../../../../services/GetTemplate';
import { InputNumber } from 'primereact/inputnumber';
import { toast } from 'react-toastify';

function EditPricing({ activeIndex, handleStepClick, lendingData, lendingIdData, setStepData2 }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');

    const [variantTypeLov, setVariantTypeLov] = useState([]);
    const [pricingLov, setPricingLov] = useState([]);
    const [scoreCardLov, setScoreCardLov] = useState([]);

    const [valueBasedTableData, setValueBasedTableData] = useState([]);
    const [valueBasedData, setValueBasedData] = useState({
        type: '',
        startSlab: '',
        endSlab: '',
        value: '',
        kiborRate: '',
        variantName: ''
    });
    const [valueBasedError, setValueBasedError] = useState({
        type: '',
        startSlab: '',
        endSlab: '',
        value: '',
        kiborRate: '',
        variantName: ''
    });
    ///////////////////////end Value based states///////////////

    const [riskBasedTableData, setRiskBasedTableData] = useState([]);
    const [riskBasedData, setRiskBasedData] = useState({
        condition: '',
        value: '',
        startSlab: '',
        endSlab: '',
        variantName: ''
    });
    const [riskBasedError, setRiskBasedError] = useState({
        condition: '',
        value: '',
        startSlab: '',
        endSlab: '',
        variantName: ''
    });
    ///////////////////////end Risk based states///////////////

    const [loanBasedTableData, setLoanBasedTableData] = useState([]);
    const [loanBasedData, setLoanBasedData] = useState({
        numbOfCycle: '',
        value: '',
        variantName: ''
    });
    const [loanBasedError, setLoanBasedError] = useState({
        numbOfCycle: '',
        value: '',
        variantName: ''
    });
    ///////////////////////end Loan based states///////////////

    const [variantData, setVariantData] = useState({
        variantName: '',
        variantTypeCode: lendingData?.tblLmsVariants?.filter((item) => item?.isActive === 'Y')[0]?.lkpLmsVariantType?.lmsVariantTypeCode.toString() || '',
        pricingId: '',
        scoreCardId: '',
        variantDetailRequests: []
    });
    const [variantError, setVariantError] = useState({
        variantName: '',
        variantTypeCode: '',
        pricingId: ''
    });
    ///////////////////////end variant states///////////////

    const [valueBasedVariantTableData, setValueBasedVariantTableData] = useState([]);
    const [riskBasedVariantTableData, setRiskBasedVariantTableData] = useState([]);
    const [loanBasedVariantTableData, setLoanBasedVariantTableData] = useState([]);

    const getLmsVariantTypeLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/lending/LKP_LMS_VARIANT_TYPE');
        setVariantTypeLov(res?.payLoad);
    };

    const getPricingLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getpricingprofile/TBL_PRICING_PROFILE');
        setPricingLov(res?.payLoad);
    };

    useEffect(() => {
        getLmsVariantTypeLov();
        getPricingLov();
    }, []);

    useEffect(() => {
        if (lendingData) {
            const variantArray = lendingData?.tblLmsVariants
                ?.filter((item) => item?.isActive === 'Y')
                ?.map((variantsItem) => {
                    return {
                        variantName: variantsItem?.variantName,
                        variantTypeCode: variantsItem?.lkpLmsVariantType?.lmsVariantTypeCode,
                        pricingId: variantsItem?.tblPricingProfile?.pricingProfileId,
                        scoreCardId: variantsItem?.tblLmsCseCard?.lmsCseCardId
                    };
                });

            if (lendingData?.tblLmsVariants?.some((item) => item.isActive === 'Y' && item.lkpLmsVariantType?.lmsVariantTypeCode?.toString() === 'VB')) {
                setValueBasedVariantTableData(variantArray);
            } else if (lendingData?.tblLmsVariants?.some((item) => item.isActive === 'Y' && item.lkpLmsVariantType?.lmsVariantTypeCode?.toString() === 'RB')) {
                setRiskBasedVariantTableData(variantArray);
            } else if (lendingData?.tblLmsVariants?.some((item) => item.isActive === 'Y' && item.lkpLmsVariantType?.lmsVariantTypeCode?.toString() === 'LB')) {
                setLoanBasedVariantTableData(variantArray);
            }
            // setVariantTableData(variantArray);

            const valueBasedArray = lendingData?.tblLmsVariants
                ?.filter((item) => item?.isActive === 'Y')
                ?.map((tblLmsitem) => {
                    if (tblLmsitem.lkpLmsVariantType?.lmsVariantTypeCode === 'VB') {
                        return tblLmsitem?.tblLmsVariantDetails
                            ?.filter((item) => item.isActive === 'Y')
                            ?.map((item) => ({
                                variantName: tblLmsitem?.variantName,
                                type: item.type,
                                startSlab: item.startSlab,
                                endSlab: item.endSlab,
                                value: item.percentageValue,
                                kiborRate: item.kiborRate
                            }));
                    }
                });

            const filteredValueBasedArray = valueBasedArray?.filter((value) => value !== undefined && value !== null);
            const flatValueBasedArray = filteredValueBasedArray?.flat();
            setValueBasedTableData(flatValueBasedArray);
            //////////////////////////////////////////////////////////////////////////////////////////////////////////////
            const riskBasedArray = lendingData?.tblLmsVariants
                ?.filter((item) => item?.isActive === 'Y')
                ?.map((tblLmsitem) => {
                    if (tblLmsitem.lkpLmsVariantType?.lmsVariantTypeCode === 'RB') {
                        return tblLmsitem?.tblLmsVariantDetails
                            ?.filter((item) => item.isActive === 'Y')
                            ?.map((item) => ({
                                variantName: tblLmsitem?.variantName,
                                condition: item.conditionalValue,
                                value: item.percentageValue,
                                startSlab: item.startSlab,
                                endSlab: item.endSlab
                            }));
                    }
                });

            const filteredRiskBasedArray = riskBasedArray?.filter((value) => value !== undefined && value !== null);
            const flatRiskBasedArray = filteredRiskBasedArray?.flat();
            setRiskBasedTableData(flatRiskBasedArray);

            const loanBasedArray = lendingData?.tblLmsVariants
                ?.filter((item) => item?.isActive === 'Y')
                ?.map((tblLmsitem) => {
                    if (tblLmsitem.lkpLmsVariantType?.lmsVariantTypeCode === 'LB') {
                        return tblLmsitem?.tblLmsVariantDetails
                            ?.filter((item) => item.isActive === 'Y')
                            ?.map((item) => ({
                                variantName: tblLmsitem?.variantName,
                                numbOfCycle: item.noOfCycles.toString(),
                                value: item.percentageValue
                            }));
                    }
                });

            const filteredLoanBasedArray = loanBasedArray?.filter((value) => value !== undefined && value !== null);
            const flatLoanBasedArray = filteredLoanBasedArray?.flat();
            setLoanBasedTableData(flatLoanBasedArray);
            /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        }
    }, [lendingData]);

    const formik = useFormik({
        initialValues: {
            variantName: '',
            variantTypeCode: '',
            pricingId: ''
            // scoreCard:'',
        },

        onSubmit: async () => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newValueBasedVariantTableData = valueBasedVariantTableData?.map((valueBasedVariant) => {
                const filterSubType = valueBasedTableData
                    ?.filter((item) => item?.variantName === valueBasedVariant?.variantName)
                    ?.map((filteredItem) => {
                        return {
                            type: filteredItem?.type,
                            startSlab: filteredItem?.startSlab,
                            endSlab: filteredItem?.endSlab,
                            value: filteredItem?.value,
                            kiborRate: filteredItem?.kiborRate
                        };
                    });

                return {
                    variantName: valueBasedVariant?.variantName,
                    variantTypeCode: valueBasedVariant?.variantTypeCode,
                    pricingId: valueBasedVariant?.pricingId,
                    variantDetailRequests: filterSubType
                };
            });
            ////////////////////////////////////////////////////

            const newRiskBasedVariantTableData = riskBasedVariantTableData?.map((riskBasedVariant) => {
                const filterSubType = riskBasedTableData
                    ?.filter((item) => item?.variantName === riskBasedVariant?.variantName)
                    ?.map((filteredItem) => {
                        return {
                            condition: filteredItem?.condition,
                            value: filteredItem?.value,
                            startSlab: filteredItem.startSlab,
                            endSlab: filteredItem.endSlab
                        };
                    });

                return {
                    variantName: riskBasedVariant?.variantName,
                    variantTypeCode: riskBasedVariant?.variantTypeCode,
                    pricingId: riskBasedVariant?.pricingId,
                    scoreCardId: riskBasedVariant?.scoreCardId,
                    variantDetailRequests: filterSubType
                };
            });
            /////////////////////////////////////////////////////

            const newLoanBasedVariantTableData = loanBasedVariantTableData?.map((loanBasedVariant) => {
                const filterSubType = loanBasedTableData
                    ?.filter((item) => item?.variantName === loanBasedVariant?.variantName)
                    ?.map((filteredItem) => {
                        return {
                            numbOfCycle: filteredItem?.numbOfCycle,
                            value: filteredItem?.value
                        };
                    });

                return {
                    variantName: loanBasedVariant?.variantName,
                    variantTypeCode: loanBasedVariant?.variantTypeCode,
                    pricingId: loanBasedVariant?.pricingId,
                    variantDetailRequests: filterSubType
                };
            });

            if (variantData?.variantTypeCode === 'VB') {
                if (newValueBasedVariantTableData.length <= 0) {
                    toast.warn('Please Add Variant');
                    setloading(false);
                    setloadingIcon('');
                    return;
                }
            }

            if (variantData?.variantTypeCode === 'VB') {
                if (newValueBasedVariantTableData[0]?.variantDetailRequests?.length <= 0) {
                    toast.warn('Please Add Sub Variant For Value Based');
                    setloading(false);
                    setloadingIcon('');
                    return;
                }
            }
            ////////////////////////////////////////////////////////////////////////////////////

            if (variantData?.variantTypeCode === 'RB') {
                if (newRiskBasedVariantTableData.length <= 0) {
                    toast.warn('Please Add Variant');
                    setloading(false);
                    setloadingIcon('');
                    return;
                }
            }

            if (variantData?.variantTypeCode === 'RB') {
                if (newRiskBasedVariantTableData[0]?.variantDetailRequests?.length <= 0) {
                    toast.warn('Please Add Sub Variant For Risk Based');
                    setloading(false);
                    setloadingIcon('');
                    return;
                }
            }
            ///////////////////////////////////////////////////////////////////////////////

            if (variantData?.variantTypeCode === 'LB') {
                if (newLoanBasedVariantTableData.length <= 0) {
                    toast.warn('Please Add Variant');
                    setloading(false);
                    setloadingIcon('');
                    return;
                }
            }

            if (variantData?.variantTypeCode === 'LB') {
                if (newLoanBasedVariantTableData[0]?.variantDetailRequests?.length <= 0) {
                    toast.warn('Please Add Sub Variant For Loan Based');
                    setloading(false);
                    setloadingIcon('');
                    return;
                }
            }

            const pricingData = {
                lmsProductId: lendingIdData,
                currentStep: 2,
                variantRequests: variantData?.variantTypeCode === 'VB' ? newValueBasedVariantTableData : variantData?.variantTypeCode === 'RB' ? newRiskBasedVariantTableData : variantData?.variantTypeCode === 'LB' ? newLoanBasedVariantTableData : []
            };

            if (pricingData?.variantRequests?.length <= 0) {
                toast.warn('Please Add Variant');
                setloading(false);
                setloadingIcon('');
                return;
            }

            setStepData2(pricingData);
            handleStepClick(activeIndex + 1);

            setloading(false);
            setloadingIcon('');
        }
    });

    const isFormFieldValid = (name) => !!formik.errors[name];

    /////////////////////////////////////////////////////////////
    const actionLoanBasedBodyTemplate = (rowData, rowIndex) => {
        return (
            <>
                <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteLoanBasedData(e, rowData, rowIndex)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
            </>
        );
    };

    const handledDeleteLoanBasedData = (e, rowData, rowIndex) => {
        e.preventDefault();
        const newFilterData = loanBasedTableData?.filter((value, index) => index !== rowIndex?.rowIndex);
        setLoanBasedTableData(newFilterData);
    };

    ////////////////////value based/////////////////////////
    const actionValueBasedBodyTemplate = (rowData, rowIndex) => {
        return (
            <>
                <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteValueBasedData(e, rowData, rowIndex)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
            </>
        );
    };

    const handledDeleteValueBasedData = (e, rowData, rowIndex) => {
        e.preventDefault();
        const newFilterData = valueBasedTableData?.filter((value, index) => index !== rowIndex?.rowIndex);
        setValueBasedTableData(newFilterData);
    };

    /////////////////////risk based////////////////////////
    const actionRiskBasedBodyTemplate = (rowData, rowIndex) => {
        return (
            <>
                <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteRiskBasedData(e, rowData, rowIndex)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
            </>
        );
    };

    const handledDeleteRiskBasedData = (e, rowData, rowIndex) => {
        e.preventDefault();
        const newFilterData = riskBasedTableData?.filter((value, index) => index !== rowIndex?.rowIndex);
        setRiskBasedTableData(newFilterData);
    };

    const handledValueBasedAdd = (e) => {
        e.preventDefault();

        const errors = {
            type: '',
            startSlab: '',
            endSlab: '',
            value: '',
            variantName: '',
            kiborRate: ''
        };

        if (valueBasedData.type === '') {
            errors.type = 'This field is required.';
        }

        if (valueBasedData.type === 'V') {
            if (valueBasedData.kiborRate === '') {
                errors.kiborRate = 'This field is required.';
            }

            if (isNaN(valueBasedData.kiborRate) || valueBasedData.kiborRate < 1 || valueBasedData.kiborRate > 100) {
                errors.kiborRate = 'Please enter a valid value between 1 and 100';
            }
        }

        if (valueBasedData.variantName === '') {
            errors.variantName = 'This field is required.';
        }

        if (valueBasedData.startSlab === '') {
            errors.startSlab = 'This field is required.';
        } else if (valueBasedData.startSlab < 0) {
            errors.startSlab = 'Please enter a non-negative number.';
        } else if (valueBasedData.startSlab === 0) {
            errors.startSlab = 'Please enter a valid slab';
        }

        if (valueBasedData.endSlab === '' || valueBasedData.endSlab === null) {
            errors.endSlab = 'This field is required.';
        } else if (valueBasedData.endSlab < 0) {
            errors.endSlab = 'Please enter a non-negative number.';
        } else if (valueBasedData.endSlab === 0) {
            errors.endSlab = 'Please enter a valid slab';
        } else if (parseInt(valueBasedData.endSlab) < parseInt(valueBasedData.startSlab)) {
            errors.endSlab = 'Please enter end slab greater than start slab.';
        }

        if (valueBasedData.type === 'V') {
            if (!valueBasedData?.value) {
                errors.value = 'This field is required';
            } else if (isNaN(valueBasedData?.value) || valueBasedData?.value < 1 || valueBasedData?.value > 100) {
                errors.value = 'Please enter a valid percentage between 1 and 100';
            }
        }
        if (valueBasedData.type === 'F') {
            if (!valueBasedData?.value) {
                errors.value = 'This field is required';
            } else if (valueBasedData?.value === '0') {
                errors.value = 'Value must be greater than 0';
            }
        }

        setValueBasedError(errors);

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            // Check if the startSlab and endSlab combination already exists
            if (valueBasedTableData.some((item) => item.startSlab === valueBasedData?.startSlab && item.endSlab === valueBasedData?.endSlab)) {
                errors.startSlab = 'This start and end slab combination already exists';
                errors.endSlab = 'This start and end slab combination already exists';
                setValueBasedError(errors);
                return; // Don't proceed if there is an error
            }

            const newValueBasedTableData = {
                type: valueBasedData?.type,
                startSlab: valueBasedData?.startSlab,
                endSlab: valueBasedData?.endSlab,
                value: valueBasedData?.value,
                kiborRate: valueBasedData?.kiborRate,
                variantName: valueBasedData?.variantName
            };
            setValueBasedTableData([...valueBasedTableData, newValueBasedTableData]);

            setValueBasedData({
                type: '',
                startSlab: '',
                endSlab: '',
                value: '',
                kiborRate: '',
                variantName: ''
            });
        }
    };

    const handledValueBasedData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;

        setValueBasedData({ ...valueBasedData, [name]: value });

        // Clear the error message for the field being edited
        setValueBasedError({
            ...valueBasedError,
            [name]: ''
        });
    };

    const handledRiskBasedAdd = (e) => {
        e.preventDefault();

        const errors = {
            condition: '',
            value: '',
            startSlab: '',
            endSlab: '',
            variantName: ''
        };

        if (!riskBasedData?.variantName) {
            errors.variantName = 'This field is required';
        }

        if (!riskBasedData?.condition) {
            errors.condition = 'This field is required';
        } else if (riskBasedData?.condition === '') {
            errors.condition = 'This Field should not be empty';
        } else if (riskBasedData?.condition === '0') {
            errors.condition = 'Value must be greater than 0';
        }

        if (!riskBasedData?.value) {
            errors.value = 'This field is required';
        } else if (riskBasedData?.value === '') {
            errors.value = 'This Field should not be empty';
        } else if (parseInt(riskBasedData.value) === 0) {
            errors.value = 'Please enter a valid value';
        }

        if (riskBasedData.startSlab === '' || riskBasedData.startSlab === null) {
            errors.startSlab = 'This field is required.';
        } else if (riskBasedData.startSlab < 0) {
            errors.startSlab = 'Please enter a non-negative number.';
        } else if (riskBasedData.startSlab === 0) {
            errors.startSlab = 'Please enter a valid slab';
        }

        if (riskBasedData.endSlab === '' || riskBasedData.endSlab === null) {
            errors.endSlab = 'This field is required.';
        } else if (riskBasedData.endSlab < 0) {
            errors.endSlab = 'Please enter a non-negative number.';
        } else if (riskBasedData.endSlab === 0) {
            errors.endSlab = 'Please enter a valid slab';
        } else if (parseInt(riskBasedData.endSlab) < parseInt(riskBasedData.startSlab)) {
            errors.endSlab = 'Please enter end slab greater than start slab.';
        }

        setRiskBasedError(errors);

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            if (riskBasedTableData.some((item) => item.condition === riskBasedData?.condition)) {
                errors.condition = 'This condition already exists';
                setRiskBasedError(errors);
                return; // Don't proceed if there is an error
            }

            // Check if the startSlab and endSlab combination already exists
            if (riskBasedTableData.some((item) => item.startSlab === riskBasedData?.startSlab && item.endSlab === riskBasedData?.endSlab)) {
                errors.startSlab = 'This start and end slab combination already exists';
                errors.endSlab = 'This start and end slab combination already exists';
                setRiskBasedError(errors);
                return; // Don't proceed if there is an error
            }

            const newRiskBasedTableData = {
                condition: riskBasedData?.condition,
                value: riskBasedData?.value,
                variantName: riskBasedData?.variantName,
                startSlab: riskBasedData?.startSlab,
                endSlab: riskBasedData?.endSlab
            };
            setRiskBasedTableData([...riskBasedTableData, newRiskBasedTableData]);

            setRiskBasedData({
                condition: '',
                value: '',
                variantName: '',
                startSlab: '',
                endSlab: ''
            });
        }
    };

    const handledRiskBasedData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        // const checked = e.target.checked;
        // const type = e.target.type;

        setRiskBasedData({ ...riskBasedData, [name]: value });

        // Clear the error message for the field being edited
        setRiskBasedError({
            ...riskBasedError,
            [name]: ''
        });
    };

    const handledLoanBasedAdd = (e) => {
        e.preventDefault();

        const errors = {
            numbOfCycle: '',
            value: '',
            variantName: ''
        };

        if (!loanBasedData?.variantName) {
            errors.variantName = 'This field is required';
        }

        if (!loanBasedData?.numbOfCycle) {
            errors.numbOfCycle = 'This field is required';
        } else if (loanBasedData?.numbOfCycle === '') {
            errors.numbOfCycle = 'This Field should not be empty';
        } else if (!/^[^\s]+(\s[^\s]+)*$/.test(loanBasedData?.numbOfCycle)) {
            errors.numbOfCycle = 'Leading and trailing space is not allowed';
        } else if (!/^[a-zA-Z0-9\s]*$/.test(loanBasedData?.numbOfCycle)) {
            errors.numbOfCycle = 'Please enter numeric data';
        } else if (loanBasedData.numbOfCycle === '0') {
            errors.numbOfCycle = 'This field should be greater than 0';
        }

        if (!loanBasedData?.value) {
            errors.value = 'This field is required';
        } else if (loanBasedData?.value === '') {
            errors.value = 'This Field should not be empty';
        } else if (!/^[^\s]+(\s[^\s]+)*$/.test(loanBasedData?.value)) {
            errors.value = 'Leading and trailing space is not allowed';
        } else if (!/^[0-9]+(?:\.[0-9]+)?$/.test(loanBasedData?.value)) {
            errors.value = 'Please enter numeric data';
        } else if (loanBasedData.value === '0') {
            errors.value = 'This field should be greater than 0';
        }

        // // Check for duplicate CategoryName
        // if (categoryTableData.some((item) => item.categoryName === categoryData.categoryName)) {
        //     errors.categoryName = 'This Category is already in use.';
        // }

        setLoanBasedError(errors);

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            const newLoanBasedTableData = {
                numbOfCycle: loanBasedData?.numbOfCycle,
                value: loanBasedData?.value,
                variantName: loanBasedData?.variantName
            };
            setLoanBasedTableData([...loanBasedTableData, newLoanBasedTableData]);

            setLoanBasedData({
                numbOfCycle: '',
                value: '',
                variantName: ''
            });
        }
    };

    const handledLoanBasedData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        // const checked = e.target.checked;
        // const type = e.target.type;

        setLoanBasedData({ ...loanBasedData, [name]: value });

        // Clear the error message for the field being edited
        setLoanBasedError({
            ...loanBasedError,
            [name]: ''
        });
    };

    const valueBasedType = [
        {
            name: 'Fixed',
            lovId: 'F'
        },
        {
            name: 'Variable',
            lovId: 'V'
        }
    ];

    const getLmsScoreCardLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/lending/TBL_LMS_CSE_CARD');
        setScoreCardLov(res?.payLoad);
    };

    useEffect(() => {
        if (variantData?.variantTypeCode === 'RB') {
            getLmsScoreCardLov();
        }
    }, [variantData?.variantTypeCode]);

    const pricingVariantTypeBodyTemplate = (rowData) => {
        return <p>{rowData?.type === 'V' ? 'Variable' : 'Fixed'}</p>;
    };

    const handledVariantAdd = (e) => {
        e.preventDefault();

        const errors = {
            variantName: '',
            variantTypeCode: '',
            pricingId: '',
            scoreCardId: ''
        };

        if (variantData?.variantName === '') {
            errors.variantName = 'This field is required.';
        } else if (!/^[^\s]+(\s[^\s]+)*$/.test(variantData?.variantName)) {
            errors.variantName = 'Leading and trailing space is not allowed';
        } else if (!/^[a-zA-Z](?:[a-zA-Z0-9\s]*-?[a-zA-Z0-9\s]*)?$/.test(variantData?.variantName)) {
            errors.variantName = 'Please enter alpha numeric data';
        }

        if (variantData?.variantTypeCode === '') {
            errors.variantTypeCode = 'This field is required.';
        }

        if (variantData?.pricingId === '') {
            errors.pricingId = 'This field is required.';
        }

        // if(variantData?.variantTypeCode === 'RB') {
        //     if (variantData?.scoreCardId === '') {
        //         errors.scoreCardId = 'This field is required.';
        //     }
        // }

        setVariantError(errors);

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            if (valueBasedVariantTableData.some((item) => item.variantName === variantData?.variantName)) {
                errors.variantName = 'This Variant already exists';
                setVariantError(errors);
                return; // Don't proceed if there is an error
            }
            if (riskBasedVariantTableData.some((item) => item.variantName === variantData?.variantName)) {
                errors.variantName = 'This Variant already exists';
                setVariantError(errors);
                return; // Don't proceed if there is an error
            }
            if (loanBasedVariantTableData.some((item) => item.variantName === variantData?.variantName)) {
                errors.variantName = 'This Variant already exists';
                setVariantError(errors);
                return; // Don't proceed if there is an error
            }

            const newVariantTableData = {
                variantName: variantData?.variantName,
                variantTypeCode: variantData?.variantTypeCode,
                pricingId: variantData?.pricingId,
                scoreCardId: variantData?.variantTypeCode === 'RB' ? variantData?.scoreCardId : ''
            };

            if (variantData?.variantTypeCode === 'VB') {
                setValueBasedVariantTableData([...valueBasedVariantTableData, newVariantTableData]);
                setRiskBasedVariantTableData([]);
                setLoanBasedVariantTableData([]);
            } else if (variantData?.variantTypeCode === 'RB') {
                setValueBasedVariantTableData([]);
                setRiskBasedVariantTableData([...riskBasedVariantTableData, newVariantTableData]);
                setLoanBasedVariantTableData([]);
            } else if (variantData?.variantTypeCode === 'LB') {
                setValueBasedVariantTableData([]);
                setRiskBasedVariantTableData([]);
                setLoanBasedVariantTableData([...loanBasedVariantTableData, newVariantTableData]);
            }
            // setVariantTableData([...variantTableData, newVariantTableData]);

            setVariantData({
                variantName: '',
                variantTypeCode: variantData?.variantTypeCode,
                pricingId: ''
            });
        }
    };

    const handledVariantData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;

        setVariantData({ ...variantData, [name]: value });

        // Clear the error message for the field being edited
        setVariantError({
            ...variantError,
            [name]: ''
        });
    };

    const variantTypeCodeBodyTemplate = (rowData) => {
        return <p>{rowData?.variantTypeCode === 'VB' ? 'Value Based' : rowData?.variantTypeCode === 'RB' ? 'Risk Based' : rowData?.variantTypeCode === 'LB' ? 'Loan Cycle Based' : null}</p>;
    };

    const actionValueBasedVariantBodyTemplate = (rowData, rowIndex) => {
        return (
            <>
                <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteValueVariantData(e, rowData, rowIndex)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
            </>
        );
    };

    const handledDeleteValueVariantData = (e, rowData, rowIndex) => {
        e.preventDefault();
        const newFilterData = valueBasedVariantTableData?.filter((value, index) => index !== rowIndex?.rowIndex);
        setValueBasedVariantTableData(newFilterData);

        setValueBasedTableData([]);
        setRiskBasedTableData([]);
        setLoanBasedTableData([]);
    };
    //////////////////////////////////////////////////////////////////////////////

    const actionRiskBasedVariantBodyTemplate = (rowData, rowIndex) => {
        return (
            <>
                <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteRiskVariantData(e, rowData, rowIndex)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
            </>
        );
    };

    const handledDeleteRiskVariantData = (e, rowData, rowIndex) => {
        e.preventDefault();
        const newFilterData = riskBasedVariantTableData?.filter((value, index) => index !== rowIndex?.rowIndex);
        setRiskBasedVariantTableData(newFilterData);

        setValueBasedTableData([]);
        setRiskBasedTableData([]);
        setLoanBasedTableData([]);
    };
    ////////////////////////////////////////////////////////////////////////////

    const actionLoanBasedVariantBodyTemplate = (rowData, rowIndex) => {
        return (
            <>
                <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteLoanVariantData(e, rowData, rowIndex)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
            </>
        );
    };

    const handledDeleteLoanVariantData = (e, rowData, rowIndex) => {
        e.preventDefault();
        const newFilterData = loanBasedVariantTableData?.filter((value, index) => index !== rowIndex?.rowIndex);
        setLoanBasedVariantTableData(newFilterData);

        setValueBasedTableData([]);
        setRiskBasedTableData([]);
        setLoanBasedTableData([]);
    };

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>PRICING/ADD NEW</h1>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="variantName" className={classNames({ 'p-error': isFormFieldValid('variantName') }, 'Label__Text')}>
                                    Variant Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="variantName"
                                    placeholder="Enter Variant Name"
                                    name="variantName"
                                    maxLength={15}
                                    value={variantData?.variantName || ''}
                                    onChange={handledVariantData}
                                    className={classNames({ 'p-error': isFormFieldValid('variantName') }, 'Input__Round')}
                                />
                                {/* {getFormErrorMessage('variantName')} */}
                                <small className="p-error">{variantError?.variantName}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="variantTypeCode" className={classNames({ 'p-error': isFormFieldValid('variantTypeCode') }, 'Label__Text')}>
                                    Select Type <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="variantTypeCode"
                                    placeholder="Select Variant Type Code"
                                    options={variantTypeLov}
                                    optionLabel="name"
                                    name="variantTypeCode"
                                    optionValue="code"
                                    value={variantData?.variantTypeCode || ''}
                                    onChange={handledVariantData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('variantTypeCode') }, 'Dropdown__Round')}
                                />
                                {/* {getFormErrorMessage('variantTypeCode')} */}
                                <small className="p-error">{variantError?.variantTypeCode}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="pricingId" className={classNames({ 'p-error': isFormFieldValid('pricingId') }, 'Label__Text')}>
                                    Pricing <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="pricingId"
                                    placeholder="Select Pricing"
                                    options={pricingLov}
                                    optionLabel="name"
                                    name="pricingId"
                                    optionValue="lovId"
                                    value={variantData?.pricingId || ''}
                                    onChange={handledVariantData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pricingId') }, 'Dropdown__Round')}
                                />
                                {/* {getFormErrorMessage('pricingId')} */}
                                <small className="p-error">{variantError?.pricingId}</small>
                            </div>
                        </div>
                        {variantData?.variantTypeCode === 'RB' && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="scoreCardId" className={classNames({ 'p-error': isFormFieldValid('scoreCardId') }, 'Label__Text')}>
                                        Score Card<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="scoreCardId"
                                        placeholder="Select Score Card"
                                        options={scoreCardLov}
                                        optionLabel="name"
                                        name="scoreCardId"
                                        filter
                                        optionValue="lovId"
                                        value={variantData?.scoreCardId || ''}
                                        onChange={handledVariantData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('scoreCardId') }, 'Dropdown__Round')}
                                    />
                                    {/* {getFormErrorMessage('scoreCardId')} */}
                                    <small className="p-error">{variantData?.variantTypeCode === 'RB' && variantError?.scoreCardId}</small>
                                </div>
                            </div>
                        )}
                        <div className="Down__Btn">
                            <Button label="Add" className="Btn__Dark" style={{ marginTop: '15px' }} onClick={handledVariantAdd} />
                        </div>
                        <div style={{ backgroundColor: '#edf0f5', padding: '2rem', margin: '2rem 0rem' }} className=" col-12 card ">
                            {variantData?.variantTypeCode === 'VB' ? (
                                <>
                                    <div className="p-fluid p-formgrid grid mb-5">
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="type" className={classNames({ 'p-error': isFormFieldValid('type') }, 'Label__Text')}>
                                                    Type<span className="Label__Required">*</span>
                                                </label>
                                                <Dropdown
                                                    id="type"
                                                    placeholder="Select Type"
                                                    options={valueBasedType}
                                                    optionLabel="name"
                                                    name="type"
                                                    filter
                                                    optionValue="lovId"
                                                    value={valueBasedData.type || ''}
                                                    onChange={handledValueBasedData}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('type') }, 'Dropdown__Round')}
                                                />
                                                <small className="p-error">{valueBasedError.type}</small>
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="startSlab" className={classNames({ 'p-error': isFormFieldValid('startSlab') }, 'Label__Text')}>
                                                    Start Slab<span className="Label__Required">*</span>
                                                </label>
                                                <InputNumber
                                                    inputId="startSlab"
                                                    placeholder="Enter Start Slab"
                                                    name="startSlab"
                                                    maxLength={9}
                                                    value={valueBasedData.startSlab || ''}
                                                    onValueChange={handledValueBasedData}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('startSlab') }, 'Input__Number')}
                                                />
                                                <small className="p-error">{valueBasedError.startSlab}</small>
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="endSlab" className={classNames({ 'p-error': isFormFieldValid('endSlab') }, 'Label__Text')}>
                                                    End Slab<span className="Label__Required">*</span>
                                                </label>
                                                <InputNumber
                                                    inputId="endSlab"
                                                    placeholder="Enter End Slab"
                                                    name="endSlab"
                                                    maxLength={9}
                                                    value={valueBasedData.endSlab || ''}
                                                    onValueChange={handledValueBasedData}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('endSlab') }, 'Input__Number')}
                                                />
                                                <small className="p-error">{valueBasedError.endSlab}</small>
                                            </div>
                                        </div>
                                        {valueBasedData?.type === 'F' && (
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor="value" className={classNames({ 'p-error': isFormFieldValid('value') }, 'Label__Text')}>
                                                        Absolute Value<span className="Label__Required">*</span>
                                                    </label>
                                                    <InputText
                                                        id="value"
                                                        placeholder="Enter Absolute Value"
                                                        name="value"
                                                        keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                        maxLength={10}
                                                        value={valueBasedData.value?.replace(/\s\s+/g, ' ') || ''}
                                                        onChange={handledValueBasedData}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('value') }, 'Input__Round')}
                                                    />
                                                    <small className="p-error">{valueBasedError.value}</small>
                                                </div>
                                            </div>
                                        )}
                                        {valueBasedData?.type === 'V' && (
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor="value" className={classNames({ 'p-error': isFormFieldValid('value') }, 'Label__Text')}>
                                                        Percentage Value<span className="Label__Required">*</span>
                                                    </label>
                                                    <InputText
                                                        id="value"
                                                        placeholder="Enter Percentage Value"
                                                        name="value"
                                                        keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                        maxLength={5}
                                                        value={valueBasedData.value?.replace(/\s\s+/g, ' ') || ''}
                                                        onChange={handledValueBasedData}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('value') }, 'Input__Round')}
                                                    />
                                                    <small className="p-error">{valueBasedError.value}</small>
                                                </div>
                                            </div>
                                        )}
                                        {valueBasedData?.type === 'V' && (
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor="kiborRate" className={classNames({ 'p-error': isFormFieldValid('kiborRate') }, 'Label__Text')}>
                                                        Kibor<span className="Label__Required">*</span>
                                                    </label>
                                                    <InputText
                                                        id="kiborRate"
                                                        placeholder="Enter Kibor"
                                                        name="kiborRate"
                                                        keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                        maxLength={10}
                                                        value={valueBasedData.kiborRate?.replace(/\s\s+/g, ' ') || ''}
                                                        onChange={handledValueBasedData}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('kiborRate') }, 'Input__Round')}
                                                    />
                                                    <small className="p-error">{valueBasedError.kiborRate}</small>
                                                </div>
                                            </div>
                                        )}
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="variantName" className={classNames({ 'p-error': isFormFieldValid('variantName') }, 'Label__Text')}>
                                                    Variant Name<span className="Label__Required">*</span>
                                                </label>
                                                <Dropdown
                                                    maxLength={100}
                                                    id="variantName"
                                                    options={valueBasedVariantTableData}
                                                    placeholder="Select Variant Name"
                                                    name="variantName"
                                                    optionLabel="variantName"
                                                    optionValue="variantName"
                                                    value={valueBasedData?.variantName || ''}
                                                    onChange={handledValueBasedData}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('variantName') }, 'Dropdown__Round')}
                                                />
                                                {/* {getFormErrorMessage('variantName')} */}
                                                <small className="p-error">{valueBasedError.variantName}</small>
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <div className="Down__Btn" style={{ justifyContent: 'flex-start' }}>
                                                    <Button label="Add" className="Btn__Dark" style={{ marginTop: '15px' }} onClick={handledValueBasedAdd} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div className="col-12">
                                            <div className="card">
                                                <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={valueBasedTableData}>
                                                    <Column field="variantName" header="Variant Name" />
                                                    <Column header="Variant Type" body={pricingVariantTypeBodyTemplate} />
                                                    <Column field="startSlab" header="Start Slab" />
                                                    <Column field="endSlab" header="End Slab" />
                                                    <Column field="kiborRate" header="Kibor" />
                                                    <Column field="value" header="Value" />
                                                    <Column header="Actions" body={actionValueBasedBodyTemplate} style={{ textAlign: 'center' }} className="Table__ActionHeading__CentredAlign" />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : variantData?.variantTypeCode === 'RB' ? (
                                <>
                                    <div className="p-fluid p-formgrid grid mb-5">
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="condition" className={classNames({ 'p-error': isFormFieldValid('condition') }, 'Label__Text')}>
                                                    Condition<span className="Label__Required">*</span>
                                                </label>
                                                <InputText
                                                    maxLength={8}
                                                    id="condition"
                                                    placeholder="Enter Condition"
                                                    name="condition"
                                                    value={riskBasedData.condition?.replace(/\s\s+/g, ' ') || ''}
                                                    onChange={handledRiskBasedData}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('condition') }, 'Input__Round')}
                                                />
                                                <small className="p-error">{riskBasedError.condition}</small>
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="value" className={classNames({ 'p-error': isFormFieldValid('value') }, 'Label__Text')}>
                                                    Value<span className="Label__Required">*</span>
                                                </label>
                                                <InputText
                                                    keyfilter="float"
                                                    maxLength={8}
                                                    id="value"
                                                    placeholder="Enter Value"
                                                    name="value"
                                                    value={riskBasedData.value?.replace(/\s\s+/g, ' ') || ''}
                                                    onChange={handledRiskBasedData}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('value') }, 'Input__Round')}
                                                />
                                                <small className="p-error">{riskBasedError.value}</small>
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="startSlab" className={classNames({ 'p-error': isFormFieldValid('startSlab') }, 'Label__Text')}>
                                                    Start Slab<span className="Label__Required">*</span>
                                                </label>
                                                <InputNumber
                                                    inputId="startSlab"
                                                    placeholder="Enter Start Slab"
                                                    name="startSlab"
                                                    maxLength={9}
                                                    value={riskBasedData.startSlab || ''}
                                                    onValueChange={handledRiskBasedData}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('startSlab') }, 'Input__Number')}
                                                />
                                                <small className="p-error">{valueBasedError.startSlab}</small>
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="endSlab" className={classNames({ 'p-error': isFormFieldValid('endSlab') }, 'Label__Text')}>
                                                    End Slab<span className="Label__Required">*</span>
                                                </label>
                                                <InputNumber
                                                    inputId="endSlab"
                                                    placeholder="Enter End Slab"
                                                    name="endSlab"
                                                    maxLength={9}
                                                    value={riskBasedData.endSlab || ''}
                                                    onValueChange={handledRiskBasedData}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('endSlab') }, 'Input__Number')}
                                                />
                                                <small className="p-error">{valueBasedError.endSlab}</small>
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="variantName" className={classNames({ 'p-error': isFormFieldValid('variantName') }, 'Label__Text')}>
                                                    Variant Name<span className="Label__Required">*</span>
                                                </label>
                                                <Dropdown
                                                    maxLength={100}
                                                    id="variantName"
                                                    options={riskBasedVariantTableData}
                                                    placeholder="Select Variant Name"
                                                    name="variantName"
                                                    optionLabel="variantName"
                                                    optionValue="variantName"
                                                    value={riskBasedData?.variantName || ''}
                                                    onChange={handledRiskBasedData}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('variantName') }, 'Dropdown__Round')}
                                                />
                                                {/* {getFormErrorMessage('variantName')} */}
                                                <small className="p-error">{riskBasedError.variantName}</small>
                                            </div>
                                        </div>

                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <div className="Down__Btn" style={{ justifyContent: 'flex-start' }}>
                                                    <Button label="Add" className="Btn__Dark" style={{ marginTop: '15px' }} onClick={handledRiskBasedAdd} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div className="col-12">
                                            <div className="card">
                                                <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={riskBasedTableData}>
                                                    <Column field="variantName" header="Variant Name" />
                                                    <Column field="condition" header="Condition Value" />
                                                    <Column field="value" header="Value" />
                                                    <Column field="startSlab" header="Start Slab" />
                                                    <Column field="endSlab" header="End Slab" />
                                                    <Column header="Actions" body={actionRiskBasedBodyTemplate} style={{ textAlign: 'center' }} className="Table__ActionHeading__CentredAlign" />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : variantData?.variantTypeCode === 'LB' ? (
                                <>
                                    <div className="p-fluid p-formgrid grid mb-5">
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="numbOfCycle" className={classNames({ 'p-error': isFormFieldValid('numbOfCycle') }, 'Label__Text')}>
                                                    No. of Cycles<span className="Label__Required">*</span>
                                                </label>
                                                <InputText
                                                    keyfilter={'int'}
                                                    maxLength={8}
                                                    id="numbOfCycle"
                                                    placeholder="Enter Number of Cycles"
                                                    name="numbOfCycle"
                                                    value={loanBasedData.numbOfCycle?.replace(/\s\s+/g, ' ') || ''}
                                                    onChange={handledLoanBasedData}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('numbOfCycle') }, 'Input__Round')}
                                                />

                                                <small className="p-error">{loanBasedError.numbOfCycle}</small>
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="value" className={classNames({ 'p-error': isFormFieldValid('value') }, 'Label__Text')}>
                                                    Price<span className="Label__Required">*</span>
                                                </label>
                                                <InputText
                                                    keyfilter="float"
                                                    maxLength={8}
                                                    id="value"
                                                    placeholder="Enter value"
                                                    name="value"
                                                    value={loanBasedData.value?.replace(/\s\s+/g, ' ') || ''}
                                                    onChange={handledLoanBasedData}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('value') }, 'Input__Round')}
                                                />

                                                <small className="p-error">{loanBasedError.value}</small>
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="variantName" className={classNames({ 'p-error': isFormFieldValid('variantName') }, 'Label__Text')}>
                                                    Variant Name<span className="Label__Required">*</span>
                                                </label>
                                                <Dropdown
                                                    maxLength={100}
                                                    id="variantName"
                                                    options={loanBasedVariantTableData}
                                                    placeholder="Select Variant Name"
                                                    name="variantName"
                                                    optionLabel="variantName"
                                                    optionValue="variantName"
                                                    value={loanBasedData?.variantName || ''}
                                                    onChange={handledLoanBasedData}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('variantName') }, 'Dropdown__Round')}
                                                />
                                                {/* {getFormErrorMessage('variantName')} */}
                                                <small className="p-error">{loanBasedError.variantName}</small>
                                            </div>
                                        </div>

                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <div className="Down__Btn" style={{ justifyContent: 'flex-start' }}>
                                                    <Button label="Add" className="Btn__Dark" style={{ marginTop: '15px' }} onClick={handledLoanBasedAdd} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div className="col-12">
                                            <div className="card">
                                                <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={loanBasedTableData}>
                                                    <Column field="variantName" header="Variant Name" />
                                                    <Column field="numbOfCycle" header="No of Cycle" />
                                                    <Column field="value" header="Price" />
                                                    <Column header="Actions" body={actionLoanBasedBodyTemplate} style={{ textAlign: 'center' }} className="Table__ActionHeading__CentredAlign" />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </div>
                        {valueBasedVariantTableData?.length > 0 ? (
                            <>
                                <div className="grid col-12">
                                    <div className="col-12">
                                        <div className="card">
                                            <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={valueBasedVariantTableData}>
                                                <Column field="variantName" header="Variant Name" />
                                                <Column header="Type" body={variantTypeCodeBodyTemplate} />
                                                {/* <Column field="status" header="Status" /> */}
                                                <Column header="Actions" body={actionValueBasedVariantBodyTemplate} className="Table__ActionHeading__CentredAlign" style={{ textAlign: 'center' }} />
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : riskBasedVariantTableData?.length > 0 ? (
                            <>
                                <div className="grid col-12">
                                    <div className="col-12">
                                        <div className="card">
                                            <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={riskBasedVariantTableData}>
                                                <Column field="variantName" header="Variant Name" />
                                                <Column header="Type" body={variantTypeCodeBodyTemplate} />
                                                {/* <Column field="status" header="Status" /> */}
                                                <Column header="Actions" body={actionRiskBasedVariantBodyTemplate} className="Table__ActionHeading__CentredAlign" style={{ textAlign: 'center' }} />
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : loanBasedVariantTableData?.length > 0 ? (
                            <>
                                <div className="grid col-12">
                                    <div className="col-12">
                                        <div className="card">
                                            <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={loanBasedVariantTableData}>
                                                <Column field="variantName" header="Variant Name" />
                                                <Column header="Type" body={variantTypeCodeBodyTemplate} />
                                                {/* <Column field="status" header="Status" /> */}
                                                <Column header="Actions" body={actionLoanBasedVariantBodyTemplate} className="Table__ActionHeading__CentredAlign" style={{ textAlign: 'center' }} />
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" loadingIcon={loadingIcon || ''} disabled={loading} label={'Next'} className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default EditPricing;

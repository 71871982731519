import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../services/PostTemplate';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../services/GetTemplate';

function AddEditValidators({ editable, onHideValidatorDialog, setEditable, validatorRowData }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');
    const [validatorTypeLov, setValidatorTypeLov] = useState([]);

    const dispatch = useDispatch();

    const getValidatorData = async () => {
        setloading(true);
        const keyData = validatorRowData;
        setloading(false);

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
        }
        formik.setFieldValue('validatorTypeId', keyData?.lkpValidatorType?.validatorTypeId.toString());
    };

    useEffect(() => {
        if (editable) {
            if (validatorRowData !== undefined || validatorRowData !== null) {
                getValidatorData();
            }
        }
    }, [validatorRowData]); // eslint-disable-line

    const getValidatorTypeLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getvalidatortypes/LKP_VALIDATOR_TYPE:*');
        setValidatorTypeLov(resp?.payLoad);
    };

    useEffect(() => {
        if (!editable) {
            formik.resetForm();
        }
        getValidatorTypeLov();
    }, [editable]); // eslint-disable-line

    const handleCancel = (e) => {
        e.preventDefault();
        onHideValidatorDialog();
        setEditable(false);
    };

    const validationSchema = Yup.object().shape({
        validatorTypeId: Yup.string().required('This Field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            validatorName: '',
            validatorTypeId: '',
            argumentName: '',
            argumentValue: ''
        },

        validate: (data) => {
            let errors = {};

            ////////////////////////validatorName/////////////////////
            if (!data?.validatorName) {
                errors.validatorName = 'This field is required';
            } else if (data?.validatorName === '') {
                errors.validatorName = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.validatorName)) {
                errors.validatorName = 'Leading and trailing space is not allowed';
            } else if (!/^[a-zA-Z](?:[a-zA-Z0-9\s]*-?[a-zA-Z0-9\s]*)?$/.test(data?.validatorName)) {
                errors.validatorName = 'Invalid Validator Name format';
            }

            ////////////////////////argumentName/////////////////////
            if (!data?.argumentName) {
                errors.argumentName = 'This field is required';
            } else if (data?.argumentName === '') {
                errors.argumentName = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.argumentName)) {
                errors.argumentName = 'Leading and trailing space is not allowed';
            } else if (!/^[a-zA-Z](?:[a-zA-Z0-9\s]*-?[a-zA-Z0-9\s]*)?$/.test(data?.argumentName)) {
                errors.argumentName = 'Invalid Argument Name format';
            }

            //////////////////////////argumentValue////////////////////////
            if (!data?.argumentValue) {
                errors.argumentValue = 'This field is required';
            } else if (data?.argumentValue === '') {
                errors.argumentValue = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.argumentValue)) {
                errors.argumentValue = 'Leading and trailing space is not allowed';
            }

            return errors;
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            if (editable) {
                data['validatorId'] = parseInt(validatorRowData?.validatorId);
                const res = await dispatch(handlePostRequest(newData, '/account/v1/validators/updatevalidator', true, false));
                if (res?.responseCode === '010000') {
                    formik.resetForm();
                    onHideValidatorDialog();
                }
            } else {
                const res = await dispatch(handlePostRequest(newData, '/account/v1/validators/createvalidator', true, false));
                if (res?.responseCode === '010000') {
                    formik.resetForm();
                    onHideValidatorDialog();
                }
            }

            setloading(false);
            setloadingIcon('');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="validatorName" className={classNames({ 'p-error': isFormFieldValid('validatorName') }, 'Label__Text')}>
                                    Validator Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="validatorName"
                                    placeholder="Enter Validator Name"
                                    name="validatorName"
                                    value={formik?.values?.validatorName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('validatorName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('validatorName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="validatorTypeId" className={classNames({ 'p-error': isFormFieldValid('validatorTypeId') }, 'Label__Text')}>
                                    Validator Type<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="validatorTypeId"
                                    placeholder="Select Validator Type"
                                    options={validatorTypeLov}
                                    optionLabel="name"
                                    name="validatorTypeId"
                                    optionValue="lovId"
                                    disabled={editable ? true : false}
                                    value={formik.values.validatorTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('validatorTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('validatorTypeId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="argumentName" className={classNames({ 'p-error': isFormFieldValid('argumentName') }, 'Label__Text')}>
                                    Argument Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="argumentName"
                                    placeholder="Enter Argument Name"
                                    name="argumentName"
                                    value={formik?.values?.argumentName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('argumentName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('argumentName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="argumentValue" className={classNames({ 'p-error': isFormFieldValid('argumentValue') }, 'Label__Text')}>
                                    Argument Value<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="argumentValue"
                                    placeholder="Enter Argument Value"
                                    name="argumentValue"
                                    value={formik?.values?.argumentValue?.trim()?.toString() || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('argumentValue') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('argumentValue')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label={editable ? 'Update' : 'Submit'} className="Btn__Dark" />
                        <Button label="Cancel" disabled={loading} loadingIcon={loadingIcon || ''} className="Btn__Transparent" onClick={(e) => handleCancel(e)} />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddEditValidators;

import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { handlePostRequest } from '../../services/PostTemplate';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';

/* eslint-disable */

const BulkDiscardDialog = ({ showModal, handleShowModal, handleSwitchChange, val, rowDataProp, schedule }) => {
    const dispatch = useDispatch();
    const [loading, setloading] = useState(false);

    const validationSchema = Yup.object().shape({
        // Define the validation rules for your fields here
        reason: Yup.string().required('Reason should not be empty')
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const formik = useFormik({
        initialValues: {
            reason: ''
        },
        validationSchema: validationSchema,

        onSubmit: async (data1) => {
            console.log(rowDataProp, 'data1');
            setloading(true);
            handleShowModal();
            let payLoad;
            if (schedule) {
                payLoad = {
                    id: rowDataProp?.id
                };
                const data = {
                    data: {
                        security: {
                            userName: '',
                            password: '',
                            securityToken: ''
                        },
                        account: {
                            msidn: '',
                            iban: '',
                            bban: '',
                            pan: '',
                            currency: ''
                        },
                        channel: '',
                        terminal: '',
                        reterivalReferenceNumber: '',
                        payLoad,
                        additionalInformation: [
                            {
                                infoKey: '',
                                infoValue: ''
                            }
                        ],
                        checkSum: ''
                    }
                };
                try {
                    const res = await dispatch(handlePostRequest(data, '/batchprocessing/v1/deleterecord', true, false));
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            } else {
                payLoad = {
                    bulkOprFileId: rowDataProp?.bulkOprFileId,
                    batchId: rowDataProp?.batchID || rowDataProp?.batchId,
                    fileStatusId: rowDataProp?.fileStatusId?.fileStatusId,
                    remarks: data1?.reason
                };
                const data = {
                    data: {
                        security: {
                            userName: '',
                            password: '',
                            securityToken: ''
                        },
                        account: {
                            msidn: '',
                            iban: '',
                            bban: '',
                            pan: '',
                            currency: ''
                        },
                        channel: '',
                        terminal: '',
                        reterivalReferenceNumber: '',
                        payLoad,
                        additionalInformation: [
                            {
                                infoKey: '',
                                infoValue: ''
                            }
                        ],
                        checkSum: ''
                    }
                };
                try {
                    const res = await dispatch(handlePostRequest(data, '/batchprocessing/v1/batchprocessing/deleterecord', true, false));
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            formik.setFieldValue('reason', '');

            setloading(false);
        }
    });

    return (
        <>
            <Dialog focusOnShow={true} draggable={false} visible={showModal} onHide={handleShowModal} style={{ width: '35vw', borderRadius: '2px' }} className="border-noround">
                <div className="card Card__Round mt-0 p-mt-2 pt-0 mb-0 pb-1">
                    <form className="p-fluid p-mt-2" onSubmit={formik.handleSubmit}>
                        <div className="p-field col-12 md:col-12 flex justify-content-center flex-column align-items-center">
                            <div className="p-field ">
                                <img src="assets/layout/images/successful.png" alt="Delete__Icon" className="Delete__Icon" />
                            </div>
                            <div className="DeleteLabel__Text pt-1">
                                <label className="Label__Text">Do you want to Discard the Batch ID {rowDataProp?.batchID || rowDataProp?.batchId}?</label>
                            </div>
                        </div>
                        
                        <div className="card flex flex-column justify-content-center mt-4">
                                    <div className="desc_Text_Area">
                                        <label htmlFor="reason" className={classNames({ 'p-error': isFormFieldValid('reason') }, 'Label__Text')}>
                                            Reasons <span className="Label__Required">*</span>
                                        </label>
                                        <InputTextarea
                                            placeholder="-"
                                            inputid="reason"
                                            name="reason"
                                            rows={4}
                                            cols={30}
                                            onChange={formik.handleChange}
                                           
                                            className={classNames({ 'p-invalid': isFormFieldValid('reason') }, 'Input__Round')}
                                            value={formik?.values?.reason}
                                        />
                                        {getFormErrorMessage('reason')}
                                    </div>
                                </div>

                        <div className="Down__Btn">
                            <Button label="Submit" type="submit" className="Btn__Dark" />
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    );
};

export default BulkDiscardDialog;

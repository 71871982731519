import React, { useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import classNames from 'classnames';

import { handlePostRequest } from '../../../../services/PostTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function BulkOperationsReport() {
    const [loading, setloading] = useState(false);


    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        userTypeId: Yup.mixed().required('This field is required.'),
        partner: Yup.mixed().required('This field is required.'),
        partnerGroup: Yup.mixed().required('This field is required.')
    });
    const dt = useRef();

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            product: '',
            filePath: '',
            totalRecords: '',
            fileStatus: '',
            validRecords: '',
            invalidRecords: '',
            disbursementTo: '',
            dateTime: '',
            uploadedBy: '',
            disbursementAmount: '',
            totalCharges: '',
            totalAmount: '',
            totalFed: '',
            totalSettled: '',
            firstSettledOn: '',
            lastSettledOn: '',

            isActive: false
        },

        onSubmit: async (data) => {
            setloading(true);
            // setloadingIcon('pi pi-spin pi-spinner');

            data['isActive'] = data?.isActive === true ? 'Y' : 'N';
            data['authentication'] = '';

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/mfs_user_management/v1/management/createuser', true, false, 'usermanagement'));
            if (res?.responseCode === '030000') {
                formik.resetForm();
            } else {
                formik.setFieldValue('isActive', data?.isActive === 'Y' ? true : false);
            }
            setloading(false);
            // setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

   
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>Disbursment Upload Page</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="product" className={classNames({ 'p-error': isFormFieldValid('product') }, 'Label__Text')}>
                                    Product
                                </label>
                                <InputText id="product" placeholder="" name="fileName" value={formik?.values?.product || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('product') }, 'Input__Round')} />

                                {getFormErrorMessage('product')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountType" className={classNames({ 'p-error': isFormFieldValid('accountType') }, 'Label__Text')}>
                                    Transaction Initiated On
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="dateTime"
                                    type="date"
                                    max={previousDate}
                                    name="dateTime"
                                    value={formik?.values?.dateTime}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateTime') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('accountType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountType" className={classNames({ 'p-error': isFormFieldValid('accountType') }, 'Label__Text')}>
                                    Transaction Completed On
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="dateTime"
                                    type="date"
                                    max={previousDate}
                                    name="dateTime"
                                    value={formik?.values?.dateTime}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateTime') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('accountType')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="agentId" className={classNames({ 'p-error': isFormFieldValid('agentId') }, 'Label__Text')}>
                                    Agent ID<span className="Label__Required">*</span>
                                </label>

                                <InputText id="agentId" placeholder="" name="agentId" value={formik?.values?.agentId || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('agentId') }, 'Input__Round')} />
                                {getFormErrorMessage('agentId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="senderId" className={classNames({ 'p-error': isFormFieldValid('senderId') }, 'Label__Text')}>
                                    Sender ID<span className="Label__Required">*</span>
                                </label>
                                <InputText id="senderId" placeholder="" name="senderId" value={formik?.values?.senderId || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('senderId') }, 'Input__Round')} />

                                {getFormErrorMessage('senderId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="senderCnic" className={classNames({ 'p-error': isFormFieldValid('senderCnic') }, 'Label__Text')}>
                                    Sender CNIC<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="senderCnic"
                                    placeholder=""
                                    name="senderCnic"
                                    value={formik?.values?.senderCnic || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('senderCnic') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('senderCnic')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="senderMobileNo" className={classNames({ 'p-error': isFormFieldValid('senderMobileNo') }, 'Label__Text')}>
                                    Sender Mobile Number<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="senderMobileNo"
                                    placeholder=""
                                    name="senderMobileNo"
                                    value={formik?.values?.senderMobileNo || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('senderMobileNo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('senderMobileNo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="recipientId" className={classNames({ 'p-error': isFormFieldValid('recipientId') }, 'Label__Text')}>
                                    Recipient ID<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="recipientId"
                                    placeholder=""
                                    name="recipientId"
                                    value={formik?.values?.recipientId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('recipientId') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('recipientId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="recipientCnic" className={classNames({ 'p-error': isFormFieldValid('recipientCnic') }, 'Label__Text')}>
                                    Recipient CNIC<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="recipientCnic"
                                    placeholder=""
                                    name="recipientCnic"
                                    value={formik?.values?.recipientCnic || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('recipientCnic') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('recipientCnic')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="recipientMobileNo" className={classNames({ 'p-error': isFormFieldValid('recipientMobileNo') }, 'Label__Text')}>
                                    Recipient Mobile Number<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="recipientMobileNo"
                                    placeholder=""
                                    name="recipientMobileNo"
                                    value={formik?.values?.recipientMobileNo || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('recipientMobileNo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('recipientMobileNo')}
                            </div>
                        </div>
                        <div className="col-12 p-3" style={{ border: '1px solid #509793', backgroundColor: '#509793' }}>
                            <div className="flex flex-row justify-content-center text-align-cente">
                                <b className="text-white "> TAX FEE, INCOME AND COMMISSION DISTRIBUTION</b>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className="card">
                                <DataTable
                                    ref={dt}
                                    paginator
                                    rows={5}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    className="datatable-responsive"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} "
                                    // header={header}
                                    emptyMessage="No List found."
                                    responsiveLayout="scroll"
                                    value={[]}
                                >
                                    <Column field="transactionId" header="Transaction ID" />
                                    <Column field="product" header="Product" />
                                    <Column field="commissionStakeHolder" header="Commission Stake Holder" />
                                    <Column field="commissionAmount" header="Commission Amount" />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 p-3" style={{ border: '1px solid #509793', backgroundColor: '#509793' }}>
                        <div className="flex flex-row justify-content-center text-align-cente">
                            <b className="text-white "> POSTING IN MICROBANK</b>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className="card">
                                <DataTable
                                    ref={dt}
                                    paginator
                                    rows={5}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    className="datatable-responsive"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} "
                                    // header={header}
                                    emptyMessage="No List found."
                                    responsiveLayout="scroll"
                                    value={[]}
                                >
                                    <Column field="transactionId" header="Transaction ID" />
                                    <Column field="transactionDate" header="Transaction Date" />
                                    <Column field="accountTitle" header="Account Title" />
                                    <Column field="accountNumber" header="Account Number" />
                                    <Column field="debit" header="Debit" />
                                    <Column field="credit" header="Credit" />
                                    <Column field="balance" header="Balance" />
                                    <Column field="leg" header="Leg" />
                                    <Column field="summary" header="Summary" />
                                    <Column field="isProcessedBySaf" header="Is Processed By SAF" />
                                    <Column field="SafStatus" header="SAF Status" />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 p-3" style={{ border: '1px solid #509793', backgroundColor: '#509793' }}>
                        <div className="flex flex-row justify-content-center text-align-cente">
                            <b className="text-white "> POSTING IN T24</b>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className="card">
                                <DataTable
                                    ref={dt}
                                    paginator
                                    rows={5}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    className="datatable-responsive"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} "
                                    // header={header}
                                    emptyMessage="No List found."
                                    responsiveLayout="scroll"
                                    value={[]}
                                >
                                    <Column field="transactionId" header="Transaction ID" />
                                    <Column field="transactionType" header="Transaction Type" />
                                    <Column field="formAccountNo" header="From Account No" />
                                    <Column field="toAccountNumber" header=" To Account Number" />
                                    <Column field="amount" header="Amount" />
                                    <Column field="responseCode" header="Response Code" />
                                    <Column field="stan" header="Stan" />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 p-3" style={{ border: '1px solid #509793', backgroundColor: '#509793' }}>
                        <div className="flex flex-row justify-content-center text-align-cente">
                            <b className="text-white "> POSTING IN ORACLE FINANCIAL</b>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className="card">
                                <DataTable
                                    ref={dt}
                                    paginator
                                    rows={5}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    className="datatable-responsive"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} "
                                    // header={header}
                                    emptyMessage="No List found."
                                    responsiveLayout="scroll"
                                    value={[]}
                                >
                                    <Column field="transactionId" header="Transaction ID" />
                                    <Column field="transactionDate" header="Transaction Date" />
                                    <Column field="accountNumber" header="Account Number" />
                                    <Column field="accountTitle" header="Account Title" />
                                    <Column field="debitAmount" header="Debit Amount" />
                                    <Column field="creditAmount" header="Credit Amount" />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} onClick={() => navigate('/usermanagement')} label="Close" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default BulkOperationsReport;

import { baseURL } from '../Config';
import axios from 'axios';
import { toast } from 'react-toastify';

export const handleGetRequest = async (url, isShowToast = false, responseCode = '') => {
    try {
        const response = await axios.get(`${baseURL + url}`, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        });
        if (isShowToast && (responseCode === '' || responseCode === response?.data?.responseCode)) {
            toast.success(response?.data?.messages || response?.data?.message);
        } else if (isShowToast) {
            toast.error(response?.data?.messages || response?.data?.message);
        }
        return response.data;
    } catch (error) {
        const id = toast.loading('Please wait...');
        if (error?.response?.status === 500) {
            setTimeout(() => {
                toast.update(id, {
                    render: error?.response?.data?.message || error?.response?.data?.message || error?.response?.data?.error || 'Something went wrong !!',
                    type: 'error',
                    isLoading: false,
                    autoClose: 3000
                });
            }, 100);
        } else {
            setTimeout(() => {
                toast.update(id, {
                    render: error?.response?.data?.message || error?.response?.data?.message || error?.response?.data?.error || 'Something went wrong !!',
                    type: 'warn',
                    isLoading: false,
                    autoClose: 3000
                });
            }, 100);
        }
    }
};

import React, { useEffect } from 'react'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { useState } from 'react';
import { Button } from 'primereact/button';

const ViewSegment = ({ rowData, onHide, userTabsFieldData, type }) => {

    const [segmentData, setSegmentData] = useState([])

    const segmentOptions = [{ segmentName: rowData?.segmentName || rowData?.[0]?.segmentName }]

    const getSegmentById = async () => {
        const response = await handleGetRequest(`/account/v1/segment/getSegmentById/${rowData?.segmentId || rowData?.[0]?.segmentId}`);
        if (response?.responseCode === "010000") {
            setSegmentData(response?.payLoad)
        }
    }

    useEffect(() => {
        getSegmentById()
    }, []);

    const checkFieldPermissions = (fieldName) => {
        const field = type !== "fromChecker" && userTabsFieldData.find(f => f.fieldName === fieldName);
        return {
            viewAllowed: field?.viewAllowed === "Y",
            updateAllowed: field?.updateAllowed === "Y"
        };
    };

    const segmentNameFieldPermissions = checkFieldPermissions("segmentName");

    return (
        <>
            <div className='Card__Round'>

                {type !== "fromChecker" ?
                    segmentNameFieldPermissions.viewAllowed && (

                        <div className='mb-2'>
                            <label className='Label__Text'>
                                Segment
                            </label>
                            <Dropdown
                                options={segmentOptions}
                                optionLabel="segmentName"
                                optionValue='segmentName'
                                value={segmentData?.segmentName ?? ""}
                                className='Dropdown__Round mr-2'
                                placeholder="--Select--"
                                disabled
                            />
                        </div>
                    )
                    :
                    <div className='mb-2'>
                        <label className='Label__Text'>
                            Segment
                        </label>
                        <Dropdown
                            options={segmentOptions}
                            optionLabel="segmentName"
                            optionValue='segmentName'
                            value={segmentData?.segmentName ?? ""}
                            className='Dropdown__Round mr-2'
                            placeholder="--Select--"
                            disabled
                        />
                    </div>
                }
                <div className='mb-2'>
                    <label className='Label__Text'>
                        Card Charges
                    </label>
                    <InputText
                        value={segmentData?.segmentId ?? ""}
                        className='Input__Round'
                        disabled
                    />
                </div>

                <div className='mb-2'>
                    <label className='Label__Text'>
                        IBFT Charges
                    </label>
                    <InputText
                        value={segmentData?.segmentId ?? ""}
                        className='Input__Round'
                        disabled
                    />
                </div>

                <div>
                    <label className='Label__Text'>
                        FED
                    </label>
                    <InputText
                        value="-"
                        className='Input__Round'
                        disabled
                    />
                </div>

                <div className='Down__Btn mt-4'>
                    <Button label="Cancel" iconPos="right" className="Btn__cancel" type="button" onClick={onHide} />
                </div>

            </div>
        </>
    )
}

export default ViewSegment
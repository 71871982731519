import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { useDispatch } from "react-redux";
import TierRangeDialog from "./TierRangeDialog";
import { Dropdown } from "primereact/dropdown";
import { handleGetRequest } from "../../../services/GetTemplate";

function TierRange() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState("pi pi-search");
    const [globalFilter, setGlobalFilter] = useState("");
    const [showDialog, setShowDialog] = useState(false);
    const [tierRangeData, setTierRangeData] = useState([]);
    const [tierData, setTierData] = useState([]);
    const [editable, setEditable] = useState(false);
    const [tierRangeRowData, setTierRangeRowData] = useState();

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            tier: "",
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon("pi pi-spin pi-spinner");
            const res = await handleGetRequest(`/zconfig/v1/zmiles/getTierRange/${formik.values.tier}`, true);
            if (res?.responseCode === '330000') {
                formik.resetForm();
                setTierRangeData(res?.payLoad);
            }
            setloading(false);
            setloadingIcon("pi pi-search");
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getTierData = async () => {
        const res = await handleGetRequest("/configuration/v1/zmiles/getAllZmilesTier");
        setTierData(res?.payLoad);
    };

    useEffect(() => {
        getTierData();
    }, []);

    const handledClicked = async (e) => {
        e.preventDefault();
        setShowDialog(true);
        setEditable(false);
    };

    const header = (
        <div className="Global__Header">
            <h5 className="m-0">Tier List</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const onHideTierRange = () => {
        setShowDialog(false);
        setEditable(false);
    };

    const EditTierRangeHandled = (rowData) => {
        setShowDialog(true);
        setEditable(true);
        setTierRangeRowData(rowData);
    };

    const actionBodyTemplate = (rowData) => {
        return <div className="actions">
                <Button icon="pi pi-pencil" tooltip="Edit" onClick={() => EditTierRangeHandled(rowData)} tooltipOptions={{ position: "top" }} className="p-button-rounded mr-2" />
            </div>
    };

    const activeHandled = (rowData) => {
        return rowData?.isActive === 1 ? "Yes" : "No";
    };

       const handleReset = (e) => {
        e.preventDefault()
        formik.resetForm()
    }

    return (
        <>
           
             <div className="Top__Btn Top__Btn__Border">
                <div>
                     <Button label="Add Tier Range" onClick={handledClicked} className="Btn__Add__" icon="pi pi-plus" iconPos="left" style={{ width: 'auto' }} />
                </div>
            </div>

            {/* Add Tier Range Dialog */}
            <Dialog visible={showDialog} header="Add Tier Range Form" style={{ width: "750px" }} onHide={() => setShowDialog(false)}>
                <TierRangeDialog onHideTierRange={onHideTierRange} editable={editable} tierRangeRowData={tierRangeRowData} />
            </Dialog>

            {/* Base Factor Form */}
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>Tier Range</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="tier" className={classNames({ "p-error": isFormFieldValid("tier") }, "Label__Text")}>
                                    Tier
                                </label>
                                <Dropdown
                                    id="tier"
                                    placeholder="Select Tier"
                                    options={tierData}
                                    optionLabel="tier"
                                    name="tier"
                                    optionValue="zmilesTierId"
                                    value={formik.values.tier}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": isFormFieldValid("tier") }, "Dropdown__Round")} />

                                {getFormErrorMessage("tier")}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button label="Search" className="Btn__Dark" />
                        <Button disabled={loading} onClick={(e)=>handleReset(e)}  label="Reset" className=" Btn__Transparent" />
                    </div>
                </form>
            </div>

            {/* Tier Range DataTable */}

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            globalFilter={globalFilter}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={tierRangeData}
                        >
                            {/* <Column body={rowIndexTemplate} header="S.No" /> */}
                            <Column field="tblZmilesTier.tier" header="Tier" />
                            <Column field="points" header="Points" />
                            <Column body={activeHandled} header="Active" />
                            <Column header="Action" body={actionBodyTemplate} />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TierRange;

import React from 'react';
import CommissionHierarchy from '../Components/CommissionHierarchy';

function AddCommissionHierarchy() {
    return (
        <React.Fragment>
            <CommissionHierarchy type="add" />
        </React.Fragment>
    );
}
export default AddCommissionHierarchy;

import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { FilterMatchMode } from 'primereact/api';
import { handleGetRequest } from '../../../src/services/GetTemplate';
import { handlePostRequest } from '../../../src/services/PostTemplate';

function DebitCardManagement() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [formDisabled, setFormDisabled] = useState(true);
    const [rowClick, setRowClick] = useState(true);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [statusLovData, setStatusLovData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [requestTypeLov, setRequestTypeLov] = useState([]);
    const [debitCard, setDebitCard] = useState([]);
    const [useCaseError, setUseCaseError] = useState({});
    const printingSite = [
        {
            name: 'ISLAMABAD'
        },
        {
            name: 'LAHORE'
        },
        {
            name: 'KARACHi'
        }
    ];

    const dataTableRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        productName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const handleCheckboxChange = (e) => {
        setSelectedProducts(e.value);
        setFormDisabled(e.value.length === 0);
    };

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {},
        validate: (data) => {
            let errors = {};
            if (data.dateTo && !data.dateFrom) {
                errors.dateFrom = 'Date From is required when Date To is provided';
            }

            if (data.dateFrom && data.dateTo && data.dateFrom > data.dateTo) {
                errors.dateTo = 'Date To must be greater than or equal to Date From';
            }
            return errors;
        },
        onSubmit: async (data) => {
       
            delete data.printingSite;
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        channel: data.channel,
                        accountNumber: data.accountNumber,
                        requestType: data.requestType,
                        dateFrom: data.dataFrom,
                        dateTo: data.dataTo,
                        createdBy: data.createdBy,
                        status: data.status
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/debitcardmanagement/v1/cards/getcardsrequest', true, true));
            if (res?.responseCode === '220000') {
                setDebitCard(res?.payLoad);
            } else {
                setDebitCard('');
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.lkpStatus?.statusDescr === 'Approved' ? '#14A38B' : rowData?.lkpStatus?.statusDescr === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.lkpStatus?.statusDescr}</b>
            </p>
        );
    };

    const handleApproveAll = async () => {
        let result = selectedProducts.map((result) => result.dcRequestId);

        let newData = {
            printingSite: formik.values.printingSite
        };

        const schema = Yup.object().shape({
            printingSite: Yup.string().required('This Field is Required.')
        });

        schema
            .validate(newData, { abortEarly: false }) // Abort early ensures that all errors are collected
            .then(async () => {
                const newData = {
                    data: {
                        security: {
                            userName: '',
                            password: '',
                            securityToken: ''
                        },
                        account: {
                            msidn: '',
                            iban: '',
                            bban: '',
                            pan: '',
                            currency: ''
                        },
                        channel: '',
                        terminal: '',
                        reterivalReferenceNumber: '',
                        payLoad: { dcRequestId: result },
                        additionalInformation: [
                            {
                                infoKey: '',
                                infoValue: ''
                            }
                        ],
                        checkSum: ''
                    }
                };
                const res = await dispatch(handlePostRequest(newData, '/debitcardmanagement/v1/cards/generatecardrequest', true, false));
            })
            .catch((error) => {
                const errors = {};
                error.inner.forEach((validationError) => {
                    errors[validationError.path] = validationError.message;
                });
                formik.setErrors(errors);
                const newDataFields = Object.keys(newData);
                const touchedFields = newDataFields.reduce((acc, field) => {
                    acc[field] = true;
                    return acc;
                }, {});
                setUseCaseError(errors);

                formik.setTouched(
                    {
                        ...formik.touched,
                        ...touchedFields
                    },
                    true
                );
            });

        // if (res?.responseCode === '220000') {
        //     setDebitCard(res?.payLoad);
        // } else {
        //     setDebitCard('');
        // }
        setloading(false);
        setloadingIcon('pi pi-search');
    };
    const EditRule = (rowData) => {
        navigate(`/editdebitcard`, { state: { viewId: rowData.dcRequestId } });
        // navigate(editUrl, { state: [rowData.id] });
    };
    const ViewRule = (rowData) => {
        navigate(`/viewdebitcard`, { state: { viewId: rowData.dcRequestId } });
        // navigate(editUrl, { state: [rowData.id] });
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        tooltip="Edit"
                        icon="pi pi-pencil"
                        disabled={['Approved', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                        tooltipOptions={{ position: 'top' }}
                        onClick={() => EditRule(rowData)}
                        className="p-button-rounded p-button-primary"
                    />
                    <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => ViewRule(rowData)} className="p-button-rounded" style={{ backgroundColor: '#F2AC57', border: 'none' }} />
                </div>
            </>
        );
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];
    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(res?.payLoad);
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };
    const getRequestType = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getdcfeetype');
        setRequestTypeLov(resp?.payLoad);
    };
    const handleInputChange = (e) => {
        let { name, value } = e.target;

        if (name === 'printingSite') {
            name = 'printingSite';
            formik.handleChange(e);
        }
        // Create a new object with the updated error for the specific field
        const updatedErrors = { ...useCaseError, [name]: '' };
        setUseCaseError(updatedErrors);

        //
    };

    useEffect(() => {
        getStatusListData();
        getUserLov();
        getRequestType();
    }, []);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="channel" className={classNames({ 'p-error': isFormFieldValid('channel') }, 'Label__Text')}>
                                    Channel<span className="Label__Required"></span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="channel"
                                    placeholder="Enter Channel"
                                    name="channel"
                                    value={formik?.values?.channel?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channel') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('channel')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountNumber" className={classNames({ 'p-error': isFormFieldValid('accountNumber') }, 'Label__Text')}>
                                    Account ID<span className="Label__Required"></span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="accountNumber"
                                    placeholder="Enter Account ID"
                                    name="accountNumber"
                                    value={formik?.values?.accountNumber?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountNumber') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('accountNumber')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="requestType" className={classNames({ 'p-error': isFormFieldValid('requestType') }, 'Label__Text')}>
                                    Request Type
                                </label>
                                <Dropdown
                                    id="requestType"
                                    placeholder="Select Request Type"
                                    options={requestTypeLov}
                                    optionLabel="name"
                                    name="requestType"
                                    optionValue="lovId"
                                    value={formik.values.requestType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('requestType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('requestType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pb-3">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        {/* <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Updated By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div> */}
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>

                                <InputText
                                    max={previousDate}
                                    id="dateFrom"
                                    placeholder=""
                                    name="dateFrom"
                                    type="date"
                                    value={formik?.values?.dateFrom?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>

                                <InputText
                                    max={previousDate}
                                    id="dateTo"
                                    placeholder=""
                                    name="dateTo"
                                    type="date"
                                    value={formik?.values?.dateTo?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('status') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="status"
                                    placeholder="Select Status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="status"
                                    optionValue="lovId"
                                    value={formik.values.status || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('status') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('status')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Search" icon={loadingIcon || ''} iconPos="right" className="Btn__Dark" />
                        <Button label="Reset" type="button" iconPos="right" className="Btn__Dark" onClick={() => formik.resetForm()} />
                    </div>
                </form>
                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <DataTable
                                paginator
                                rows={5}
                                rowsPerPageOptions={[5, 10, 25]}
                                ref={dataTableRef}
                                className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                                filters={filters}
                                globalFilterFields={['productId', 'productName', 'productCategory', 'createdDate&Time', 'category', 'maxLimit', 'minLimit']}
                                emptyMessage="No List found."
                                responsiveLayout="scroll"
                                value={debitCard}
                                selectionMode={rowClick ? null : 'checkbox'}
                                selection={selectedProducts}
                                //onSelectionChange={(e) => setSelectedProducts(e.value)}
                                onSelectionChange={handleCheckboxChange}
                            >
                                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                                <Column field="accountNumber" header="Account Number" />
                                <Column field="lkpDcFeeType.dcFeeTypeName" header="Request Type" />
                                <Column field="cnic" header="CNIC" />
                                <Column field="embosingName" header="Embossing Name" />
                                <Column field="address" header="Address" />
                                <Column field="createdate" header="Created On" />
                                <Column field="createdBy" header="Created By" />
                                <Column header="Status" body={statusBodyTemplate} />
                                <Column header="Actions" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                            </DataTable>
                            <form>
                                <div className="p-fluid p-formgrid grid mb-5">
                                    <div className="p-field col-12 md:col-4 mt-6">
                                        <div className="p-field">
                                            <label htmlFor="printingSite" className={classNames({ 'p-error': isFormFieldValid('printingSite') }, 'Label__Text')}>
                                                Printing Site
                                            </label>
                                            <Dropdown
                                                id="printingSite"
                                                //placeholder="Select Status"
                                                options={printingSite}
                                                optionLabel="name"
                                                name="printingSite"
                                                optionValue="name"
                                                value={formik.values.printingSite || ''}
                                                onChange={handleInputChange}
                                                disabled={formDisabled}
                                                className={classNames({ 'p-invalid': isFormFieldValid('printingSite') }, 'Dropdown__Round')}
                                            />

                                            {useCaseError ? <small className="p-error">{useCaseError?.printingSite}</small> : getFormErrorMessage('printingSite')}
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-4 mt-7">
                                        <div className="Down__Btn" style={{ justifyContent: 'start' }}>
                                            <Button label="Approve All" type="button" onClick={() => handleApproveAll()} disabled={formDisabled} iconPos="right" className="Btn__Dark" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DebitCardManagement;

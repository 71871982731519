import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../services/GetTemplate';

function ManageCustomers() {
    const [loading, setloading] = useState(false);
    const [globalFilter, setGlobalFilter] = useState('');
    // const [ultraAccountData, setUltraAccountData] = useState([]);

    const ManageCustomersJson = [
        {
            customerName: 'ali',
            cnic: '3456523478642',
            fatherHusbandName: 'arshad',
            motherName: 'fatima',
            placeOfBirth: '20-02-1992',
            email: 'ali@gmail.com',
            purposeOfAccount: 'Others',
            sourceOfIncome: 'not define',
            status: 'P'
        },
        {
            customerName: 'wali',
            cnic: '3456523478642',
            fatherHusbandName: 'tauqir',
            motherName: 'fatima',
            placeOfBirth: '20-02-1992',
            email: 'ali@gmail.com',
            purposeOfAccount: 'Others',
            sourceOfIncome: 'not define',
            status: 'N'
        },
        {
            customerName: 'faizan',
            cnic: '3456523478642',
            fatherHusbandName: 'ahmed',
            motherName: 'fatima',
            placeOfBirth: '20-02-1992',
            email: 'ali@gmail.com',
            purposeOfAccount: 'Others',
            sourceOfIncome: 'not define',
            status: 'D'
        }
    ];

    let navigate = useNavigate();

    const getUltraAccount = async () => {
        setloading(true);
        const res = await handleGetRequest('/account_upgrade/au/getUltraCustomers');
        if (res?.responsecode === 1) {
            setloading(false);
            // setUltraAccountData(res?.data);
        }
    };

    useEffect(() => {
        getUltraAccount();
    }, []); // eslint-disable-line

    const header = (
        <div className="Global__Header">
            <h5 className="m-0">Manage Customer</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const actionViewTemplate = (rowData) => {
        return (
            <>
                <div
                    className="actions"
                    style={{
                        display: 'flex'
                    }}
                >
                    <Button
                        icon="pi pi-pencil"
                        disabled={rowData?.status === 'U' || rowData?.status === 'P' || rowData?.status === 'CP' || rowData?.status === 'CR' || rowData?.status === 'MR' ? true : false}
                        tooltip="Action"
                        tooltipOptions={{ position: 'top' }}
                        onClick={() => navigate(`/viewmanagecustomers?cid=${rowData?.ultraCustomerId}`)}
                        className="p-button-rounded p-button-warning mr-2"
                    />
                    <Button icon="pi pi-eye" tooltip="View" tooltipOptions={{ position: 'top' }} onClick={() => navigate(`/viewmanagecustomers?cid=${rowData?.ultraCustomerId}`)} className="p-button-rounded mr-2" />
                </div>
            </>
        );
    };

    const statusTemplate = (rowData) => {
        return (
            <div>
                {rowData?.status === 'C'
                    ? 'Checked'
                    : rowData?.status === 'D'
                    ? 'Descripency'
                    : rowData?.status === 'N'
                    ? 'New Request'
                    : rowData?.status === 'P'
                    ? 'Pending'
                    : rowData?.status === 'R'
                    ? 'Rejected'
                    : rowData?.status === 'U'
                    ? 'Ultra Account Upgraded'
                    : rowData?.status === 'CP'
                    ? 'Compliance Pending'
                    : rowData?.status === 'CA'
                    ? 'Compliance Approved'
                    : rowData?.status === 'CR'
                    ? 'Compliance Rejected'
                    : rowData?.status === 'MA'
                    ? 'Compliance Manual Approved'
                    : rowData?.status === 'MR'
                    ? 'Compliance Manual Rejected'
                    : null}
            </div>
        );
    };

    return (
        <>
            {/* Manage Customers DataTable */}

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            globalFilter={globalFilter}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={ManageCustomersJson}
                            disabled={loading}
                        >
                            <Column field="customerName" header="Customer Name" />
                            <Column field="cnic" header="CNIC" />
                            <Column field="fatherHusbandName" header="Father Husband Name" />
                            <Column field="motherName" header="Mother Name" />
                            <Column field="placeOfBirth" header="Place Of Birth" />
                            <Column field="email" header="Email" />
                            <Column field="purposeOfAccount" header="Purpose Of Account" />
                            <Column field="sourceOfIncome" header="Source Of Income" />
                            <Column field="status" body={statusTemplate} header="Status" />
                            <Column header="Action" body={actionViewTemplate} />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageCustomers;

import React, { useEffect, useState } from 'react'
import { Chart } from 'primereact/chart';

const CustomerUbps = ({ customerUbps }) => {

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {

        const cutomerUbpsArr = customerUbps && customerUbps.flatMap((ubps) => [].concat(ubps?.count, ubps?.transectionPerCount, ubps?.transectionAmount));

        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: ['Count', 'Amount per Count', "Total Amount"],
            datasets: [
                {
                    data: cutomerUbpsArr,
                    backgroundColor: [
                        "rgb(240,192,31)",
                        "rgb(118,189,225)",
                        "rgb(240,158,31)",
                    ],
                    hoverBackgroundColor: [
                        "rgb(240,192,31)",
                        "rgb(118,189,225)",
                        "rgb(240,158,31)",
                    ]
                }
            ]
        }
        const options = {
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [customerUbps]);

    return (
        <div className="col-12 md:col-3">
            <div className="card Card__Round">
                <div className="cardBarCharts">
                    <h5>Customer Ubps</h5>
                    <div className="pt-3">
                        <Chart type="pie" data={chartData} options={chartOptions} className="w-full" height='35rem' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerUbps
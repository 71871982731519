import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import { Paginator } from 'primereact/paginator';
import { PaginationCall, PaginationTemplate } from '../../../components/Pagination';

const Organization = () => {
    const [statusData, setStatusData] = useState([]);
    const [userLovData, setUserLovData] = useState([]);
    const [organizationData, setOrganizationData] = useState([]);

    const [customFirst1, setCustomFirst1] = useState(0);
    const [customRows1, setCustomRows1] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [render, setRender] = useState(false);

    const dataTableRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        lkpOrganizationName: Yup.string()
            .notRequired()
            .min(3, 'field must be at least 3 characters long')
            .test({
                name: 'leadingAndTrailingSpace',
                message: 'Leading and trailing space is not allowed',
                test: (value) => {
                    return !/^\s|\s$/.test(value);
                }
            })
            .test({
                name: 'onlyAlphanumericAndSpaces',
                message: 'Only alphanumeric characters and spaces are allowed',
                test: (value) => {
                    return /^[a-zA-Z0-9\s]+$/.test(value);
                }
            }),
        lkpOrganizationCode: Yup.string()
            .notRequired()
            .test({
                name: 'leadingAndTrailingSpace',
                message: 'Leading and trailing space is not allowed',
                test: (value) => {
                    return !/^\s|\s$/.test(value);
                }
            })
            .test({
                name: 'onlyAlphanumericAndSpaces',
                message: 'Only alphanumeric characters and spaces are allowed',
                test: (value) => {
                    return /^[a-zA-Z0-9\s]+$/.test(value);
                }
            }),
        coaCode: Yup.number()
            .notRequired()
            .test({
                name: 'leadingAndTrailingSpace',
                message: 'Leading and trailing space is not allowed',
                test: (value) => {
                    return !/^\s|\s$/.test(value);
                }
            }),

        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            lkpOrganizationId: '',

            lkpOrganizationName: '',
            lkpOrganizationDescription: '',
            lkpOrganizationCode: '',
            coaCode: '',
            isActive: '',
            createdBy: '',
            updatedBy: '',
            statusId: '2',
            dateFrom: '',
            dateTo: '',
            rowStart: customFirst1,
            rowEnd: customRows1 + customFirst1
        },

        onSubmit: async (data) => {
            const jsonObj = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },

                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },

                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',

                    payLoad: {
                        ...data
                    },

                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],

                    checkSum: ''
                }
            };

            if (organizationData) {
                setOrganizationData('');
            }

            const res = await dispatch(handlePostRequest(jsonObj, '/account/v1/chartofaccount/getlkporganizationbysearch', true, true));
            if (res?.responseCode === '010000') {
                setOrganizationData(res?.payLoad);
            }
        }
    });

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getStatusLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusData(res?.payLoad);
    };

    const getUserLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLovData(res?.payLoad);
    };

    const editHandler = (rowData) => {
        navigate('/editorganization', {
            state: { rowData }
        });
    };
    const viewHandler = (rowData) => {
        navigate('/vieworganization', {
            state: { rowData }
        });
    };

    const addOrganization = () => {
        navigate('/addorganization');
    };

    const statusBodyTemplate = (rowData) => {
        const filteredStatus = statusData?.filter((item) => item.lovId == rowData?.statusId);
        const statusColor = filteredStatus[0]?.name === 'Approved' ? '#14A38B' : filteredStatus[0]?.name === 'Pending' ? '#F2AC57' : '#FF7171';

        return (
            <p style={{ color: statusColor }}>
                <b>{filteredStatus[0]?.name}</b>
            </p>
        );
    };

    const actionTemplate = (rowData) => {
        return (
            <div>
                <InputSwitch
                    id={`isActive-${rowData.branchId}`}
                    checked={rowData.isActive === 'Y'}
                    onChange={(e) => handleIsActiveSwitch(e, rowData)}
                    className="mr-3"
                    disabled={rowData?.lkpStatus?.statusDescr === 'Pending' || rowData?.lkpStatus?.statusDescr === 'Rejected' ? true : false}
                />
                <Button
                    tooltip="Edit"
                    icon="pi pi-pencil"
                    tooltipOptions={{ position: 'top' }}
                    onClick={() => editHandler(rowData)}
                    className="p-button-rounded p-button-warning mr-3"
                    disabled={rowData?.lkpStatus?.statusDescr === 'Pending' || rowData?.lkpStatus?.statusDescr === 'Rejected' || (rowData?.lkpStatus?.statusDescr === 'Approved' && rowData.isActive === 'N') ? true : false}
                />
                <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => viewHandler(rowData)} className="p-button-rounded p-button-primary" />
            </div>
        );
    };

    const handleIsActiveSwitch = async (e, rowData) => {
        //update
        const newIsActive = e.value === true ? 'Y' : 'N';
        const jsonObj = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',

                payLoad: {
                    lkpOrganizationId: rowData?.organizationId,
                    isActive: newIsActive
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(jsonObj, '/account/v1/chartofaccount/inactivelkporganization', true, false));
        if (res?.responseCode === '010000') {
            // Update the isActive property of the rowData directly
            rowData.isActive = newIsActive;
        }
    };

    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
        setOrganizationData('');
    };

    useEffect(() => {
        getStatusLov();
        getUserLov();
    }, []);

    const onCustomPageChange1 = (event) => {
        setCustomFirst1(event.first);
        setCustomRows1(event.rows);
        setCurrentPage(event.page + 1);
        setRender(true);
    };

    const RequestData = {
        lkpOrganizationName: formik.values.lkpOrganizationName,
        lkpOrganizationCode: formik.values.lkpOrganizationCode,
        coaCode: formik.values.coaCode,
        dateFrom: formik.values.dateFrom,
        dateTo: formik.values.dateTo,
        statusId: formik.values.statusId,
        createdBy: formik.values.createdBy,
        updatedBy: formik.values.updatedBy,
        rowStart: customFirst1,
        rowEnd: customRows1 + customFirst1
    };

    return (
        <div>
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={addOrganization} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>
            <PaginationCall
                render={render}
                customFirst1={customFirst1}
                customRows1={customRows1}
                currentPage={currentPage}
                dataTableRef={dataTableRef}
                setData={setOrganizationData}
                RequestData={RequestData}
                url={'/account/v1/chartofaccount/getlkporganizationbysearch'}
                responseCode={'010000'}
            />
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Organization Name</label>
                                <InputText
                                    id="lkpOrganizationName"
                                    value={formik.values.lkpOrganizationName}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpOrganizationName') }, 'Input__Round')}
                                    maxLength={50}
                                />
                                {getFormErrorMessage('lkpOrganizationName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Organization Code</label>
                                <InputText
                                    id="lkpOrganizationCode"
                                    value={formik.values.lkpOrganizationCode}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpOrganizationCode') }, 'Input__Round')}
                                    maxLength={30}
                                />
                                {getFormErrorMessage('lkpOrganizationCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">COA Code</label>
                                <InputText id="coaCode" value={formik.values.coaCode} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('coaCode') }, 'Input__Round')} keyfilter="int" maxLength={2} />
                                {getFormErrorMessage('coaCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Date From</label>

                                <InputText max={previousDate} id="dateFrom" type="date" value={formik.values.dateFrom} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')} />
                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Date To</label>
                                <InputText max={previousDate} id="dateTo" type="date" value={formik.values.dateTo} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')} />
                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLovData}
                                    optionLabel="name"
                                    optionValue="lovId"
                                    value={formik.values.createdBy}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Updated By"
                                    options={userLovData}
                                    optionLabel="name"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Status</label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select Filter"
                                    options={statusData}
                                    optionLabel="name"
                                    optionValue="lovId"
                                    value={formik.values.statusId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>
                    </div>
                    <div className="Usergroup__SearchBtn p-field col-12  flex justify-content-center">
                        <div className="p-field col-12 md:col-4 flex align-items-end">
                            <div className="p-field">
                                <div className="Down__Btn">
                                    <Button label="Search" iconPos="right" className="Btn__Dark" />
                                    <Button label="Reset" onClick={(e) => handleReset(e)} className="Btn__Transparent" />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div className="card p-datatable-sm custom-border">
                <div>
                    <h4>Organization Details</h4>
                </div>
                <div className="custom-datatable">
                    <DataTable ref={dataTableRef} value={organizationData} emptyMessage="No data found." scrollable responsiveLayout="scroll">
                        <Column field="organizationName" header="Organization Name" />
                        <Column field="organizationCode" header="Organization Code" />
                        <Column field="organizationDescr" header="Organization Description" />
                        <Column field="coaCode" header="COA Code" />
                        <Column body={statusBodyTemplate} header="Status" />
                        <Column body={actionTemplate} header="Actions" />
                    </DataTable>
                    <Paginator template={PaginationTemplate(false)} first={customFirst1} rows={customRows1} totalRecords={organizationData?.[0]?.totalCount} onPageChange={onCustomPageChange1}></Paginator>
                </div>
            </div>
        </div>
    );
};

export default Organization;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import ImageDataModal from './ImageDataModal';
import { handleGetRequest } from '../../../src/services/GetTemplate';
import { handlePostRequest } from '../../services/PostTemplate';
import AuthorizationHistoryTable from '../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import QrCode from './components/QrCode';

function ViewDmoMerchant() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [cnicFrontActive, setCnicFrontActive] = useState(false);
    const [cnicBackActive, setCnicBackActive] = useState(false);
    const [selfieActive, setSelfieActive] = useState(false);
    const [proofOfBusinessActive, setProofOfBusinessActive] = useState(false);
    const [merchantData, setMerchantData] = useState();
    const [statusLovData, setStatusLovData] = useState([]);
    const [qrCode, setQrCode] = useState('');

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const merchantId = query.get('merchantId');
    const tableName = 'TBL_MERCHANT';

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getDatabyId = async () => {
        const resp = await handleGetRequest(`/account/v1/customeraccounts/getmerchantpendingrequestbyid/${merchantId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik?.setFieldValue(key, keyData[key]);
                }
            });

            formik.setFieldValue('cnicFrontAction', keyData?.cnicFrontStatus?.toString());
            formik.setFieldValue('cnicBackAction', keyData?.cnicBackStatus?.toString());
            formik.setFieldValue('selfieAction', keyData?.selfieStatus?.toString());
            formik.setFieldValue('proofOfBusinessAction', keyData?.proofOfBusinessStatus?.toString());
            setQrCode(keyData?.qr);
            setMerchantData(keyData);
        }
    };

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getDocStatus/LKP_DOC_STATUS');
        const filterLovData = resp?.payLoad.filter((item) => item?.name != 'Pending');
        setStatusLovData(filterLovData);
    };

    useEffect(() => {
        getDatabyId();
        getStatusLov();
    }, []); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            accountTitle: '',
            accountNumber: '',
            address: '',
            cnic: '',
            businessName: '',
            businessType: '',
            businessAddress: '',
            city: '',
            monthlySalesExpected: '',
            cnicFront: '',
            cnicBack: '',
            selfie: '',
            proofOfBusiness: '',
            cnicFrontAction: '',
            cnicBackAction: '',
            selfieActive: '',
            proofOfBusinessAction: ''
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        userRecord: [
                            {
                                fieldName: 'cnicFront',
                                fieldValue: data?.cnicFront,
                                status: data?.cnicFrontAction,
                                comment: ''
                            },
                            {
                                fieldName: 'cnicBack',
                                fieldValue: data?.cnicBack,
                                status: data?.cnicBackAction,
                                comment: ''
                            },
                            {
                                fieldName: 'selfie',
                                fieldValue: data?.selfie,
                                status: data?.selfieAction,
                                comment: ''
                            },
                            {
                                fieldName: 'proofOfBusiness',
                                fieldValue: data?.proofOfBusiness,
                                status: data?.proofOfBusinessAction,
                                comment: ''
                            }
                        ],
                        merchantId: merchantId
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            await dispatch(handlePostRequest(newData, '/account/v1/customeraccounts/updatemerchantaccountapprovalstat', true, false, 'dmomerchant'));

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const onHideDialog = () => {
        setShowDialog(false);
    };

    const handledCnicFrontDialog = (e) => {
        e?.preventDefault();
        setShowDialog(true);
        setCnicBackActive(false);
        setCnicFrontActive(true);
        setProofOfBusinessActive(false);
        setSelfieActive(false);
    };

    const handledCnicBackDialog = (e) => {
        e?.preventDefault();
        setShowDialog(true);
        setCnicBackActive(true);
        setCnicFrontActive(false);
        setProofOfBusinessActive(false);
        setSelfieActive(false);
    };

    const handledSelfieDialog = (e) => {
        e?.preventDefault();
        setShowDialog(true);
        setSelfieActive(true);
        setCnicFrontActive(false);
        setCnicBackActive(false);
        setProofOfBusinessActive(false);
    };

    const handledProofOfBusinessDialog = (e) => {
        e?.preventDefault();
        setShowDialog(true);
        setProofOfBusinessActive(true);
        setCnicFrontActive(false);
        setCnicBackActive(false);
        setSelfieActive(false);
    };

    return (
        <>
            <Dialog visible={showDialog} onHide={() => onHideDialog()} header={cnicFrontActive ? 'CNIC FRONT' : cnicBackActive ? 'CNIC BACK' : selfieActive ? 'SELFIE' : proofOfBusinessActive ? 'PROOF OF BUSINESS' : null}>
                <ImageDataModal cnicFrontActive={cnicFrontActive} onHideDialog={onHideDialog} merchantData={merchantData} cnicBackActive={cnicBackActive} selfieActive={selfieActive} proofOfBusinessActive={proofOfBusinessActive} />
            </Dialog>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="Form__Header Full__Width">
                        <h2>VIEW DETAILS</h2>
                    </div>
                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h4 style={{ color: '#fff', marginLeft: '10px' }}>PERSONAL INFORMATION</h4>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountTitle" className={classNames({ 'p-error': isFormFieldValid('accountTitle') }, 'Label__Text')}>
                                    Account Title
                                </label>
                                <InputText
                                    disabled
                                    id="accountTitle"
                                    placeholder="Select Category"
                                    name="accountTitle"
                                    value={formik?.values?.accountTitle || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountTitle') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('accountTitle')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountNumber" className={classNames({ 'p-error': isFormFieldValid('accountNumber') }, 'Label__Text')}>
                                    Account Number
                                </label>
                                <InputText
                                    disabled
                                    id="accountNumber"
                                    placeholder="Select Product Category"
                                    name="accountNumber"
                                    value={formik?.values?.accountNumber || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountNumber') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('accountNumber')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="address" className={classNames({ 'p-error': isFormFieldValid('address') }, 'Label__Text')}>
                                    Address
                                </label>
                                <InputTextarea
                                    disabled
                                    id="address"
                                    placeholder="Enter Address"
                                    name="address"
                                    value={formik?.values?.address || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('address') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('address')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="cnic" className={classNames({ 'p-error': isFormFieldValid('cnic') }, 'Label__Text')}>
                                    CNIC Number
                                </label>
                                <InputText
                                    disabled
                                    keyfilter={'int'}
                                    id="cnic"
                                    placeholder="Enter CNIC"
                                    name="cnic"
                                    value={formik?.values?.cnic || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cnic') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('cnic')}
                            </div>
                        </div>
                    </div>
                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h4 style={{ color: '#fff', marginLeft: '10px' }}>BUSINESS INFORMATION </h4>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="businessName" className={classNames({ 'p-error': isFormFieldValid('businessName') }, 'Label__Text')}>
                                    Business Name
                                </label>
                                <InputText
                                    disabled
                                    id="businessName"
                                    placeholder="Enter Business Name"
                                    name="businessName"
                                    value={formik?.values?.businessName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('businessName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('businessName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="businessType" className={classNames({ 'p-error': isFormFieldValid('businessType') }, 'Label__Text')}>
                                    Business Type
                                </label>
                                <InputText
                                    disabled
                                    id="businessType"
                                    placeholder="Enter Business Type"
                                    name="businessType"
                                    value={formik?.values?.businessType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('businessType') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('businessType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="businessAddress" className={classNames({ 'p-error': isFormFieldValid('businessAddress') }, 'Label__Text')}>
                                    Business Adress
                                </label>
                                <InputTextarea
                                    disabled
                                    id="businessAddress"
                                    placeholder="Enter Business Address"
                                    name="businessAddress"
                                    value={formik?.values?.businessAddress || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('businessAddress') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('businessAddress')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="city" className={classNames({ 'p-error': isFormFieldValid('city') }, 'Label__Text')}>
                                    City
                                </label>
                                <InputText disabled id="city" placeholder="Enter City" name="city" value={formik?.values?.city || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('city') }, 'Input__Round')} />

                                {getFormErrorMessage('city')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="monthlySalesExpected" className={classNames({ 'p-error': isFormFieldValid('monthlySalesExpected') }, 'Label__Text')}>
                                    Monthly Sale Expense
                                </label>
                                <InputText
                                    disabled
                                    id="monthlySalesExpected"
                                    placeholder="Enter Monthly Sale"
                                    name="monthlySalesExpected"
                                    value={formik?.values?.monthlySalesExpected || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('monthlySalesExpected') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('monthlySalesExpected')}
                            </div>
                        </div>
                    </div>
                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h4 style={{ color: '#fff', marginLeft: '10px' }}>UPLOADED DOCUMENTS</h4>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-8">
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-6">
                                    <div className="p-field">
                                        <label htmlFor="cnicFront" className={classNames({ 'p-error': isFormFieldValid('cnicFront') }, 'Label__Text')}>
                                            CNIC Front
                                        </label>
                                        <div className="Image_div">
                                            <p>{formik?.values?.cnicFront?.slice(14)}</p>
                                            <Button label="View Image" className="Btn__Dark" onClick={handledCnicFrontDialog} />
                                        </div>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-6">
                                    <div className="p-field">
                                        <label htmlFor="cnicFrontAction" className={classNames({ 'p-error': isFormFieldValid('cnicFrontAction') }, 'Label__Text')}>
                                            Action
                                        </label>
                                        <Dropdown
                                            disabled
                                            id="cnicFrontAction"
                                            placeholder="Select Action"
                                            name="cnicFrontAction"
                                            options={statusLovData}
                                            optionLabel="name"
                                            optionValue="lovId"
                                            value={formik?.values?.cnicFrontAction || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('cnicFrontAction') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('cnicFrontAction')}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-6">
                                    <div className="p-field">
                                        <label htmlFor="cnicBack" className={classNames({ 'p-error': isFormFieldValid('cnicBack') }, 'Label__Text')}>
                                            CNIC Back
                                        </label>
                                        <div className="Image_div">
                                            <p>{formik?.values?.cnicBack?.slice(14)}</p>
                                            <Button label="View Image" className="Btn__Dark" onClick={handledCnicBackDialog} />
                                        </div>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-6">
                                    <div className="p-field">
                                        <label htmlFor="cnicBackAction" className={classNames({ 'p-error': isFormFieldValid('cnicBackAction') }, 'Label__Text')}>
                                            Action
                                        </label>
                                        <Dropdown
                                            disabled
                                            id="cnicBackAction"
                                            placeholder="Select Action"
                                            name="cnicBackAction"
                                            options={statusLovData}
                                            optionLabel="name"
                                            optionValue="lovId"
                                            value={formik?.values?.cnicBackAction || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('cnicBackAction') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('cnicBackAction')}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-6">
                                    <div className="p-field">
                                        <label htmlFor="selfie" className={classNames({ 'p-error': isFormFieldValid('selfie') }, 'Label__Text')}>
                                            Selfie
                                        </label>
                                        <div className="Image_div">
                                            <p>{formik?.values?.selfie?.slice(14)}</p>
                                            <Button label="View Image" className="Btn__Dark" onClick={handledSelfieDialog} />
                                        </div>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-6">
                                    <div className="p-field">
                                        <label htmlFor="selfieAction" className={classNames({ 'p-error': isFormFieldValid('selfieAction') }, 'Label__Text')}>
                                            Action
                                        </label>
                                        <Dropdown
                                            disabled
                                            id="selfieAction"
                                            placeholder="Select Action"
                                            name="selfieAction"
                                            options={statusLovData}
                                            optionLabel="name"
                                            optionValue="lovId"
                                            value={formik?.values?.selfieAction || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('selfieAction') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('selfieAction')}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-6">
                                    <div className="p-field">
                                        <label htmlFor="proofOfBusiness" className={classNames({ 'p-error': isFormFieldValid('proofOfBusiness') }, 'Label__Text')}>
                                            Proof of Business
                                        </label>
                                        <div className="Image_div">
                                            <p>{formik?.values?.proofOfBusiness?.slice(14)}</p>
                                            <Button label="View Image" className="Btn__Dark" onClick={handledProofOfBusinessDialog} />
                                        </div>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-6">
                                    <div className="p-field">
                                        <label htmlFor="proofOfBusinessAction" className={classNames({ 'p-error': isFormFieldValid('proofOfBusinessAction') }, 'Label__Text')}>
                                            Action
                                        </label>
                                        <Dropdown
                                            disabled
                                            id="proofOfBusinessAction"
                                            placeholder="Select Action"
                                            name="proofOfBusinessAction"
                                            options={statusLovData}
                                            optionLabel="name"
                                            optionValue="lovId"
                                            value={formik?.values?.proofOfBusinessAction || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('proofOfBusinessAction') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('proofOfBusinessAction')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {qrCode && merchantData?.status?.toLowerCase() === 'approved' && (
                            <div className="p-field col-12 md:col-4">
                                <QrCode qrCode={qrCode} />
                            </div>
                        )}
                    </div>
                    <div className="Down__Btn">
                        <Button type="button" onClick={() => navigate('/dmomerchant')} loadingIcon={loadingIcon || ''} label="Okay" className="Btn__Add" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={merchantId} tableName={tableName} />
        </>
    );
}

export default ViewDmoMerchant;

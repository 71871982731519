import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { useDispatch } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';

function DialogDLink({ onHideDLINK, rowData }) {
    const [isLoading, setIsLoading] = useState(false);
    const [currentStatus, setCurrentStatus] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(false);

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            raastAmmaCurrentStatus: ''
        },

        onSubmit: async (data) => {
            setIsLoading(true);

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        raastDeLinkingRequest: {
                            aliasValue: rowData?.mobileNo,
                            isdeLinking: rowData?.isRaastLinked === 'Y' ? true : false,
                            type: 'C',
                            mobileNumber: rowData?.mobileNo,
                            accountLevelName: rowData?.accountLevelName,
                            customerId: rowData?.customerId
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/account/v1/customeraccounts/updatecustomerkyc', true, false, 'accountsmanagement'));
            if (res?.responseCode === '010000') {
                formik.resetForm();
                onHideDLINK();
            }

            setIsLoading(false);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const raastAmmaData = [
        { name: 'Link', value: '0' },
        { name: 'DeLink', value: '1' }
    ];

    useEffect(() => {
        if (rowData?.isRaastLinked === 'Y') {
            formik.setFieldValue('raastAmmaCurrentStatus', '0');
            setCurrentStatus('0');
        } else if (rowData?.isRaastLinked === 'N') {
            formik.setFieldValue('raastAmmaCurrentStatus', '1');
            setCurrentStatus('1');
        }
    }, [rowData]);

    useEffect(() => {
        if (formik.values.raastAmmaCurrentStatus === currentStatus) {
            setBtnDisabled(true);
        } else {
            setBtnDisabled(false);
        }
    }, [formik.values.raastAmmaCurrentStatus, currentStatus]);

    return (
        <>
            <LoadingOverlay active={isLoading} spinner text="Please Wait ...">
                <div className="card Card__Round">
                    <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="raastAmmaCurrentStatus" className={classNames({ 'p-error': isFormFieldValid('raastAmmaCurrentStatus') }, 'Label__Text')}>
                                        Raast Current Status
                                    </label>
                                    <Dropdown
                                        id="raastAmmaCurrentStatus"
                                        placeholder="Select Status"
                                        options={raastAmmaData}
                                        optionLabel="name"
                                        name="raastAmmaCurrentStatus"
                                        optionValue="value"
                                        value={formik.values.raastAmmaCurrentStatus || ''}
                                        onChange={(e) => {
                                            formik.setFieldValue('raastAmmaCurrentStatus', e.value);
                                        }}
                                        className={classNames({ 'p-invalid': isFormFieldValid('raastAmmaCurrentStatus') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('raastAmmaCurrentStatus')}
                                </div>
                            </div>
                        </div>

                        <div className="Down__Btn">
                            <Button label="Send" type="Delink" className="Btn__Dark" disabled={btnDisabled} />
                            <Button
                                label="Cancel"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onHideDLINK();
                                }}
                                className="Btn__Transparent"
                            />
                        </div>
                    </form>
                </div>
            </LoadingOverlay>
        </>
    );
}

export default DialogDLink;

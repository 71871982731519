import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

const RoleTable = ({ roles, dataAction, actionType, readOnlyMode }) => {
    const removeRow = (rowData) => {
        dataAction(rowData);
    };
    const actionTemplate = (rowData) => {
        // if (actionType === 'delete') {
        return <Button disabled={readOnlyMode} type="button" tooltip="Delete Row" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={() => removeRow(rowData)} className="p-button-rounded p-button-danger" />;
        // }
    };

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate ?? new Date()?.toLocaleString('en-US');
        const date = new Date(isoDate);
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };
    return (
        <div>
            <div className="card">
                <DataTable value={roles} responsiveLayout="scroll">
                    <Column field="roleName" header="Role Name"></Column>
                    <Column header="Created Date & Time" body={createdDateAndTimeBodyTemplate} />
                    <Column header="Action" body={actionTemplate} />
                </DataTable>
            </div>
        </div>
    );
};

export default RoleTable;

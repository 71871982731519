import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import EditBannerChecker from './EditBannerChecker';
import EditRedirectSplashChecker from '../../RedirectSplash/EditRedirectSplashChecker';
import EditInputSplashChecker from '../../InputSplash/EditInuputSplashChecker';
import EditInformationSplashChecker from '../../InformationSplash/EditInformationSplashChecker';
import EditPromotionChecker from '../../Promotions/EditPromotionChecker';
import EditTickerChecker from '../../Ticker/EditTickerChecker';

const EditBannerCheckerMain = () => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const [bannerType, setBannerType] = useState();

    const getMainCategoryData = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/getbannerbyid/${refTableId}`);
        const keyData = res?.payLoad;
     
        if (keyData) {
            setBannerType(keyData?.cmsBannerTypeId || '');
        }
    };

    const getMcRequestDataById = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/getmcrequestbyid/${mcRequestId}`);
        const unParsedkeyData = res?.payLoad?.updateJson;
        const keyData = JSON.parse(unParsedkeyData);

        if (keyData) {
            setBannerType(keyData?.cmsBannerTypeId || '');
        }
    };

    useEffect(() => {
        if (requestType === 'I') {
            getMainCategoryData();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, [requestType]);

    

    return (
        <>
            {(() => {
                switch (bannerType) {
                    case 1:
                        return <EditBannerChecker />;
                    case 2:
                        return <EditRedirectSplashChecker />;
                    case 3:
                        return <EditInputSplashChecker />;
                    case 4:
                        return <EditInformationSplashChecker />;
                    case 5:
                        return <EditPromotionChecker />;
                    case 6:
                        return <EditTickerChecker />;
                    default:
                        return null;
                }
            })()}
        </>
    );
};

export default EditBannerCheckerMain;

import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handleGetForNotificationRequest } from '../../services/GetForNotification';

const AuthorizationHistoryTable = ({ refTableId, tableName }) => {
    const [historyData, setHistoryData] = useState([]);

    const getAuthorizationHistoryData = async () => {
        const resp = await handleGetForNotificationRequest(`/workflow/v1/management/getActionAuthorizationHistory/${refTableId}/${tableName}`);
        setHistoryData(resp?.payLoad);
    };

    useEffect(() => {
        if (refTableId !== undefined && refTableId !== null) {
            getAuthorizationHistoryData();
        }
    }, [refTableId, tableName]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <DataTable
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                        emptyMessage="No List found."
                        header="Action Authorization History"
                        responsiveLayout="scroll"
                        value={historyData}
                    >
                        <Column field="escalationLevel" header="Escalation Level" />
                        <Column field="maker" header="Maker" />
                        <Column field="intimationTo" header="Intimation To" />
                        <Column field="intimationOn" header="Intimation On" />
                        <Column field="checkedBy" header="Checked By" />
                        <Column field="checkedOn" header="Checked On" />
                        <Column field="authorizerComments" header="Authorizer Comments" />
                        <Column field="authorizationStatus" header="Authorization Status" />
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

export default AuthorizationHistoryTable;

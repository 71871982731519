import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { PickList } from 'primereact/picklist';
import { Checkbox } from 'primereact/checkbox';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function EditKycSetsChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-check');
    const [sourceData, setSourceData] = useState([]);
    const [targetData, setTargetData] = useState([]);
    const [clientTypeLovData, setClientTypeLovData] = useState([]);
    const [isPickListDisabled] = useState(true);
    const [statusLovData, setStatusLovData] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const checkerComments = query.get('checkerComments');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        getStatusLov();
    }, []); // eslint-disable-line

    const getKycSetsById = async () => {
        const res = await handleGetRequest(`/account/v1/kycsets/getKycSetById/${refTableId}`);
        const keyData = res?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });

            formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
            formik.setFieldValue('clientRoleType', keyData?.lkpAccountClassification?.accountClassificationId.toString());
            setSourceData(keyData?.tblKycSetDetails);
            setTargetData(keyData?.excludeKycAttributes);

            formik.setFieldValue('checkerComments', checkerComments);
        }
    };

    const getMcRequestDataById = async () => {
        const resp = await handleGetRequest(`/account/v1/kycsets/getKycSetsUpdateCheckerById/${mcRequestId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });

            formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
            formik.setFieldValue('clientRoleType', keyData?.accountClassificationId.toString());
            setSourceData(keyData?.tblKycSetDetails);
            setTargetData(keyData?.excludeKycAttributes);

            formik.setFieldValue('checkerComments', checkerComments);
        }
    };

    const getClientTypeLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getclientroles/LKP_ACCOUNT_CLASSIFICATION:*');
        setClientTypeLovData(resp?.payLoad);
    };

    useEffect(() => {
        getClientTypeLov();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (requestType === 'I') {
            getKycSetsById();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, []); // eslint-disable-line

    // const unselectKycSetsListData = sourceData?.map((item) => ({
    //     kycAttributesId: item.lovId,
    //     isMandatory: item?.isMandatory === false ? 'N' : 'Y'
    // }));

    const onPicklistChange = (event) => {
        setSourceData(event.source);
        setTargetData(event.target);
    };

    const pickListIdsWithMandatory = sourceData?.map((item) => ({
        kycAttributesId: item?.tblKycAttribute?.kycAttributesId,
        isMandatory: item?.isMandatory
    }));

    const handleCheckboxClickSource = (item) => {
        const updatedSourceData = [...sourceData];
        const selectedItem = updatedSourceData.find((data) => data.lovId === item.lovId);
        selectedItem.isMandatory = !selectedItem.isMandatory;
        setSourceData(updatedSourceData);
    };

    const handleCheckboxClickTarget = (item) => {
        const updatedTargetData = [...targetData];
        const selectedItem = updatedTargetData.find((data) => data.lovId === item.lovId);
        selectedItem.isMandatory = !selectedItem.isMandatory;
        setTargetData(updatedTargetData);
    };

    const sourceItemTemplate = (item) => {
        return (
            <div className="Picklist__Item">
                <h5>{item?.tblKycAttribute?.attributeName}</h5>
                <Checkbox checked={item.isMandatory === 'Y' ? true : false} disabled={true} onChange={() => handleCheckboxClickSource(item)} className="Picklist__Checkbox" />
            </div>
        );
    };

    const targetItemTemplate = (item) => {
        return (
            <div className="Picklist__Item">
                <h5>{item?.attributeName}</h5>
                <Checkbox checked={item.isMandatory} disabled={true} onChange={() => handleCheckboxClickTarget(item)} className="Picklist__Checkbox" />
            </div>
        );
    };

    const sourceHeaderTemplate = () => {
        return (
            <div className="Kyc__Picklist__Header">
                <h5>Attributes</h5>
                <h5>Mandatory</h5>
            </div>
        );
    };

    const targetHeaderTemplate = () => {
        return (
            <div className="Kyc__Picklist__Header">
                <h5>Exclude Attributes</h5>
            </div>
        );
    };

    const validationSchema = Yup.object().shape({
        checkerComments: Yup.string().required('This Field is required.').nullable(),
        action: Yup.string().required('This Field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            kycSetName: '',
            clientRoleType: '',
            checkerComments: '',
            action: '',
            isActive: ''
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['isActive'] = data?.isActive === false ? 'N' : 'Y';
            if (requestTypeDescr !== 'Enable/Disable Request') {
                delete data['isActive'];
            }

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        tblKycSetHeadRequest: {
                            kycSetHeadId: parseInt(refTableId),
                            accountClassificationId: parseInt(data?.clientRoleType),
                            kycSetName: data?.kycSetName,
                            tblKycSetDetails: pickListIdsWithMandatory,
                            isActive: data['isActive']
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/account/v1/kycsets/kycSetsCheckerAction', true, false, 'requestchecker'));
            setloading(false);
            setloadingIcon('pi pi-save');
            if (res?.responseCode === '010000') {
                formik.resetForm();
            }
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-4">
                        <div className="p-field col-12 md:col-6">
                            <div className="p-field">
                                <label htmlFor="kycSetName" className={classNames({ 'p-error': isFormFieldValid('kycSetName') }, 'Label__Text')}>
                                    Kyc Set Name
                                    {/* <span className="Label__Required">*</span> */}
                                </label>
                                <InputText
                                    id="kycSetName"
                                    placeholder="Enter Kyc set Name"
                                    name="kycSetName"
                                    disabled={true}
                                    value={formik?.values?.kycSetName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('kycSetName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('kycSetName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-6">
                            <div className="p-field">
                                <label htmlFor="clientRoleType" className={classNames({ 'p-error': isFormFieldValid('clientRoleType') }, 'Label__Text')}>
                                    Client Role Type
                                    {/* <span className="Label__Required">*</span> */}
                                </label>
                                <Dropdown
                                    id="clientRoleType"
                                    placeholder="Select Client Role Type"
                                    options={clientTypeLovData}
                                    optionLabel="name"
                                    name="clientRoleType"
                                    optionValue="lovId"
                                    disabled={true}
                                    value={formik.values.clientRoleType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('clientRoleType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('clientRoleType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-6">
                            <PickList
                                source={sourceData}
                                target={targetData}
                                sourceHeader={sourceHeaderTemplate}
                                targetHeader={targetHeaderTemplate}
                                sourceItemTemplate={sourceItemTemplate}
                                targetItemTemplate={targetItemTemplate}
                                onToggle={(e) => setTargetData(e.target)}
                                onChange={onPicklistChange}
                                onMoveToTarget={(e) => setTargetData(e.target)}
                                onMoveToSource={(e) => setSourceData(e.source)}
                                disabled={isPickListDisabled}
                                className={isPickListDisabled ? 'Picklist__Disabled' : 'KycSets__Picklist'}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="requestTypeDescr" className={classNames({ 'p-error': isFormFieldValid('requestTypeDescr') }, 'Label__Text')}>
                                    Request Type Description
                                </label>
                                <InputText
                                    disabled={true}
                                    id="requestTypeDescr"
                                    placeholder="Enter Name Of Account"
                                    name="requestTypeDescr"
                                    value={requestTypeDescr}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('requestTypeDescr') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('requestTypeDescr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                    isActive
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox disabled={true} inputId="isActive" name="isActive" value={formik?.values?.isActive} checked={formik?.values?.isActive} onChange={formik.handleChange} />
                                </div>
                                {getFormErrorMessage('isActive')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    rows={5}
                                    cols={30}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon || ''} label="Update" className="Btn__Dark" />
                        <Button onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default EditKycSetsChecker;

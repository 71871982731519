import { useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React from 'react';

const DualNationalityFields = () => {
    const formik = useFormik({
        initialValues: {
            countrySelect: '',
            usCitizen: '',
            usBorn: '',
            usTelephone: '',
            usSignatory: '',
            usLinks: ''
        }
    });
    const country = [
        { name: 'US', value: 'us' },
        { name: 'UK', value: 'uk' },
        { name: 'Austrailia', value: 'aus' }
    ];
    const citizen = [
        { name: 'Yes', value: 'yes' },
        { name: 'No', value: 'no' }
    ];
    const born = [
        { name: 'Yes', value: 'yes' },
        { name: 'No', value: 'no' }
    ];
    const telephone = [
        { name: 'Yes', value: 'yes' },
        { name: 'No', value: 'no' }
    ];
    const signatory = [
        { name: 'Yes', value: 'yes' },
        { name: 'No', value: 'no' }
    ];
    const links = [
        { name: 'Yes', value: 'yes' },
        { name: 'No', value: 'no' }
    ];

    const handleCitizenChange = (event) => {
        const newCitizenValue = event.target.value;
        formik.handleChange(event);
        if (newCitizenValue === 'no') {
            formik.setFieldValue('usBorn', 'no');
            formik.setFieldValue('usTelephone', 'no');
            formik.setFieldValue('usSignatory', 'no');
            formik.setFieldValue('usLinks', 'no');
        }
    };
    // const handleCountryChange = (event) => {
    //     const newCountryValue = event.target.value;
    // };
    return (
        <>
            {formik.values.countrySelect == '' || formik.values.countrySelect == 'us' ? (
                <>
                    {' '}
                    <div className="p-fluid p-formgrid grid mb-5 custom-card ">
                        <div className="p-field col-10 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Country</label>
                                <Dropdown
                                    id="countrySelect"
                                    name="countrySelect"
                                    placeholder="--Select--"
                                    options={country}
                                    optionLabel="name"
                                    optionValue="value"
                                    value={formik.values.countrySelect}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round custom-dropdown"
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">US Citizen/ US Resident/ or Holding a U.S Permanent Resident Card (Green Card)</label>
                                <Dropdown
                                    id="usCitizen"
                                    name="usCitizen"
                                    placeholder="--Select--"
                                    options={citizen}
                                    optionLabel="name"
                                    optionValue="value"
                                    value={formik.values.usCitizen}
                                    onChange={handleCitizenChange}
                                    className="Dropdown__Round custom-dropdown"
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Born in US</label>
                                <Dropdown
                                    id="usBorn"
                                    name="usBorn"
                                    placeholder="--Select--"
                                    options={born}
                                    optionLabel="name"
                                    optionValue="value"
                                    value={formik.values.usBorn}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round custom-dropdown"
                                    disabled={formik.values.usCitizen == 'no'}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">US Telephone Number or Address</label>
                                <Dropdown
                                    id="usTelephone"
                                    name="usTelephone"
                                    placeholder="--Select--"
                                    options={telephone}
                                    optionLabel="name"
                                    optionValue="value"
                                    value={formik.values.usTelephone}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round custom-dropdown"
                                    disabled={formik.values.usCitizen == 'no'}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Assigning Signatory Authority/ Mandate to a Person with US Address</label>
                                <Dropdown
                                    id="usSignatory"
                                    name="usSignatory"
                                    placeholder="--Select--"
                                    options={signatory}
                                    optionLabel="name"
                                    optionValue="value"
                                    value={formik.values.usSignatory}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round custom-dropdown"
                                    disabled={formik.values.usCitizen == 'no'}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">U.S. Links</label>
                                <Dropdown
                                    id="usLinks"
                                    name="usLinks"
                                    placeholder="--Select--"
                                    options={links}
                                    optionLabel="name"
                                    optionValue="value"
                                    value={formik.values.usLinks}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round custom-dropdown"
                                    disabled={formik.values.usCitizen == 'no'}
                                />
                            </div>
                        </div>
                        {formik.values.countrySelect === 'us' && formik.values.usCitizen === 'yes' && formik.values.usBorn === 'yes' && formik.values.usTelephone === 'yes' && formik.values.usSignatory === 'yes' && formik.values.usLinks === 'yes' ? (
                            <>
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label className="Label__Text">Federal tax Classification</label>
                                        <Dropdown
                                            // id="birthPlace"
                                            // name="birthPlace"
                                            placeholder="--Select--"
                                            // options={getBirthPlaceData}
                                            optionLabel="label"
                                            optionValue="label"
                                            // value={formik.values.birthPlace}
                                            // onChange={formik.handleChange}
                                            className="Dropdown__Round custom-dropdown"
                                        />
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label className="Label__Text">Address</label>
                                        <div className="flex align-items-center">
                                            <InputText
                                                // id="fullName"
                                                // name="fullName"
                                                // value={formik.values.fullName}
                                                // onChange={formik.handleChange}
                                                // onChange={formik.handleChange}

                                                className="Input__Round"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label className="Label__Text">Tax Identification Number</label>
                                        <div className="flex align-items-center">
                                            <InputText
                                                // id="fullName"
                                                // name="fullName"
                                                // value={formik.values.fullName}
                                                // onChange={formik.handleChange}
                                                // onChange={formik.handleChange}

                                                className="Input__Round"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <div className="p-fluid p-formgrid grid mb-5 custom-card ">
                        <div className="p-field col-10 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Country</label>
                                <Dropdown
                                    id="countrySelect"
                                    name="countrySelect"
                                    placeholder="--Select--"
                                    options={country}
                                    optionLabel="name"
                                    optionValue="value"
                                    value={formik.values.countrySelect}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round custom-dropdown"
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Country/Jurisdiction of Tax Residence</label>
                                <Dropdown id="usCitizen" name="usCitizen" placeholder="--Select--" value={formik.values.usCitizen} onChange={formik.handleChange} className="Dropdown__Round custom-dropdown" />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Tax Identification Number</label>
                                <InputText id="usBorn" name="usBorn" placeholder="--Select--" value={formik.values.usBorn} onChange={formik.handleChange} className="Dropdown__Round" />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Residence Address</label>
                                <InputText id="usTelephone" name="usTelephone" placeholder="--Select--" value={formik.values.usTelephone} onChange={formik.handleChange} className="Dropdown__Round" />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Country of Birth</label>
                                <Dropdown id="usSignatory" name="usSignatory" placeholder="--Select--" value={formik.values.usSignatory} onChange={formik.handleChange} className="Dropdown__Round custom-dropdown" />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default DualNationalityFields;

import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import SuccessfulLogo from '../../assets/icons/Successfull.png';

/* eslint-disable */
const ActiveInActiveDialog = ({ showModal, handleShowModal, handleSwitchChange, isT24, isRetry }) => {
    return (
        <>
            <Dialog
                focusOnShow={true}
                draggable={false}
                visible={showModal}
                onHide={handleShowModal}
                showHeader={false}
                style={{ width: '40vw' }}
                contentStyle={{
                    borderRadius: '5px'
                }}
                className="Custom__MessageDialog"
            >
                <div className="card Card__Round">
                    <form className="p-fluid p-mt-2">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field text-center">
                                <div className="">
                                    <img src={SuccessfulLogo} alt="Success__Icon" className="Delete__Icon" style={{ height: '60px' }} />
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12 mt-3">
                            <div className="p-field">
                                <div className="DeleteLabel__Text">
                                    <label className="Label__Text">{`Are you sure you want to ${isT24 ? 'Link/Delink' : isRetry ? 'Retry' : 'Active/InActive'} this ?`}</label>
                                </div>
                            </div>
                        </div>

                        <div className="DownDelete__Btn">
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleShowModal();
                                    handleSwitchChange();
                                }}
                                iconPos="right"
                                label="Yes"
                                className="Btn__Dark"
                            />
                            <Button
                                iconPos="right"
                                label="No"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleShowModal();
                                }}
                                className="Btn__Transparent"
                            />
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    );
};

export default ActiveInActiveDialog;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { handleGetRequest } from '../../../../services/GetTemplate';

function CxRoleMgmt() {
    const navigate = useNavigate();
    const dataTableRef = useRef(null);
    const [searchData, setSearchData] = useState([]);

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        roleDescription: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        checkerUsername: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        makerUsername: { value: '', matchMode: FilterMatchMode.STARTS_WITH }
    });

    const getSearchData = async () => {
        const res = await handleGetRequest('/account/v1/customeraccounts/getAllCxStatuses', true);
        setSearchData(res?.payLoad);
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">CX Role Management</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const header = () => renderHeader();

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        tooltip="Edit Detail"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: 'top' }}
                        disabled={['Pending', 'Rejected'].includes(rowData?.statusName) || (rowData?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                        onClick={() => navigate(`/editcxrolemanagement`, { state: rowData.cxStatusConfigId })}
                        className="p-button-rounded p-button-primary"
                    />
                    <Button
                        tooltip="View Detail"
                        tooltipOptions={{ position: 'top' }}
                        icon="pi pi-eye"
                        onClick={() => navigate(`/viewcxrolemanagement`, { state: rowData.cxStatusConfigId })}
                        className="p-button-rounded"
                        style={{ backgroundColor: '#F2AC57', border: 'none' }}
                    />
                </div>
            </>
        );
    };

    useEffect(() => {
        getSearchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={() => navigate('/addcxrolemanagement')} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            filters={filters}
                            globalFilterFields={['roleDescription', 'checkerUsername', 'makerUsername']}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={searchData}
                        >
                            <Column field="roleDescription" header="Role" />
                            <Column field="makerUsername" header="Maker" />
                            <Column field="checkerUsername" header="Checker" />
                            <Column header="Action" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default CxRoleMgmt;

import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { handlePostRequest } from '../../../../services/PostTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

const ViewCurrency = () => {
    const location = useLocation();
    const { rowData } = location.state || {};

    const currencyId = rowData?.currencyId;
    const tableName = 'LKP_CURRENCY';

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        lkpCurrencyName: Yup.string().required('This field is required'),
        lkpCurrencyCode: Yup.string().required('This field is required'),
        lkpCurrencyDescription: Yup.string().required('This field is required'),
        coaCode: Yup.string().required('This field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            lkpCurrencyName: '',
            lkpCurrencyCode: '',
            lkpCurrencyDescription: '',
            coaCode: ''
        },

        onSubmit: async (data) => {
            const coaPayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        lkpCurrencyId: rowData?.currencyId,
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(coaPayload, '/account/v1/chartofaccount/updatelkpcurrency', true, true));
            if (res?.responseCode === '010000') {
                navigate(-1);
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        if (rowData) {
            formik.setFieldValue('lkpCurrencyName', rowData?.currencyName);
            formik.setFieldValue('lkpCurrencyDescription', rowData?.currencyDescr);
            formik.setFieldValue('lkpCurrencyCode', rowData?.currencyCode);
            formik.setFieldValue('coaCode', rowData?.coaCode);
        }
    }, [rowData]);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>View Details</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Currency Name</label>
                                <InputText
                                    id="lkpCurrencyName"
                                    value={formik.values.lkpCurrencyName || ''}
                                    onChange={formik.handleChange}
                                    disabled={true}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpCurrencyName') }, 'Input__Round')}
                                    maxLength={30}
                                />
                                {getFormErrorMessage('lkpCurrencyName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Currency Description</label>
                                <InputText
                                    id="lkpCurrencyDescription"
                                    value={formik.values.lkpCurrencyDescription || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpCurrencyDescription') }, 'Input__Round')}
                                    maxLength={199}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpCurrencyDescription')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Currency Code</label>
                                <InputText
                                    id="lkpCurrencyCode"
                                    value={formik.values.lkpCurrencyCode || ''}
                                    onChange={formik.handleChange}
                                    disabled={true}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpCurrencyCode') }, 'Input__Round')}
                                    maxLength={30}
                                />
                                {getFormErrorMessage('lkpCurrencyCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Coa Code</label>
                                <InputText id="coaCode" value={formik.values.coaCode || ''} onChange={formik.handleChange} disabled={true} className={classNames({ 'p-invalid': isFormFieldValid('coaCode') }, 'Input__Round')} maxLength={30} />
                                {getFormErrorMessage('coaCode')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" onClick={() => navigate('/currency')} label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={currencyId} tableName={tableName} />
        </>
    );
};

export default ViewCurrency;

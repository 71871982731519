export const EditInitialValues = {
    accountTitle: '',
    accountTitleComment: '',
    accountTitleStatus: '',
    selfiePic: '',
    selfiePicComment: '',
    selfiePicStatus: '',
    cnicFrontPic: '',
    cnicFrontPicComment: '',
    cnicFrontPicStatus: '',
    cnicBackPic: '',
    cnicBackPicComment: '',
    cnicBackPicStatus: '',
    fatherName: '',
    fatherNameComment: '',
    fatherNameStatus: '',
    birthPlace: '',
    birthPlaceComment: '',
    birthPlaceStatus: '',
    motherName: '',
    motherNameComment: '',
    motherNameStatus: '',
    professionName: '',
    professionNameComment: '',
    professionNameStatus: '',
    dualNational: '',
    dualNationalComment: '',
    dualNationalStatus: '',
    usBorn: '',
    usBornComment: '',
    usBornStatus: '',
    country: '',
    countryComment: '',
    countryStatus: '',
    countryOfBirth: '',
    countryOfBirthComment: '',
    countryOfBirthStatus: '',
    countryOfTaxResidence: '',
    countryOfTaxResidenceComment: '',
    countryOfTaxResidenceStatus: '',
    usGreenCardHolder: '',
    usGreenCardHolderComment: '',
    usGreenCardHolderStatus: '',
    usTelePhoneNumber: '',
    usTelePhoneNumberComment: '',
    usTelePhoneNumberStatus: '',
    usSignAuth: '',
    usSignAuthComment: '',
    usSignAuthStatus: '',
    usOtherInfo: '',
    usOtherInfoComment: '',
    usOtherInfoStatus: '',
    fedralTaxClassificationName: '',
    fedralTaxClassificationNameComment: '',
    fedralTaxClassificationNameStatus: '',
    residenceAddress: '',
    residenceAddressComment: '',
    residenceAddressStatus: '',
    taxIdentificationNumber: '',
    taxIdentificationNumberComment: '',
    taxIdentificationNumberStatus: '',
    geoLocation: '',
    geoLocationComment: '',
    geoLocationStatus: '',
    cnicExpiryDate: '',
    cnicExpiryDateComment: '',
    cnicExpiryDateStatus: '',
    signaturePic: '',
    signaturePicComment: '',
    signaturePicStatus: '',
    bvsVerified: '',
    bvsVerifiedComment: '',
    bvsVerifiedStatus: '',
    email: '',
    emailComment: '',
    emailStatus: '',
    dateOfBirth: '',
    dateOfBirthComment: '',
    dateOfBirthStatus: '',
    customerVideo: '',
    customerVideoComment: '',
    customerVideoStatus: '',
    customerVideoKyc: '',
    customerVideoKycComment: '',
    customerVideoKycStatus: '',
    deviceId: '',
    deviceIdComment: '',
    deviceIdStatus: '',
    purposeOfAccount: '',
    purposeOfAccountComment: '',
    purposeOfAccountStatus: '',
    gender: '',
    genderComment: '',
    genderStatus: '',
    currencyName: '',
    currencyNameComment: '',
    currencyNameStatus: '',
    mailingAddress: '',
    mailingAddressComment: '',
    mailingAddressStatus: '',
    proofOfIncomePic: '',
    proofOfIncomePicComment: '',
    proofOfIncomePicStatus: '',
    bFormPic: '',
    bFormPicComment: '',
    bFormPicStatus: '',
    parentCnicFrontPic: '',
    parentCnicFrontPicComment: '',
    parentCnicFrontPicStatus: '',
    parentCnicBackPic: '',
    parentCnicBackPicComment: '',
    parentCnicBackPicStatus: '',
    status: '',
    comments: ''
};

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../services/GetTemplate';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewUserManageChecker() {
    const [userTypeLovData, setUserTypeLovData] = useState([]);
    const [partnerLovData, setPartnerLovData] = useState([]);
    const [partnerGroupLovData, setPartnerGroupLovData] = useState([]);
    const navigate = useNavigate();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const getUserData = async () => {
        const res = await handleGetRequest(`/mfs_user_management/v1/management/getuserbyid/${refTableId}`);
        const keyData = res?.payLoad;

        keyData.forEach((obj) => {
            Object.keys(obj).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key) && obj[key] !== null) {
                    formik.setFieldValue(key, obj[key]);
                }
            });

            if (obj.hasOwnProperty('userType') && obj.userType !== null) {
                formik.setFieldValue('userTypeId', obj.userType.toString());
            }

            if (obj.hasOwnProperty('partnerId') && obj.partnerId !== null) {
                formik.setFieldValue('partner', obj.partnerId.toString());
            }

            if (obj.hasOwnProperty('partnerGroup') && obj.partnerGroup !== null) {
                formik.setFieldValue('partnerGroup', obj.partnerGroup.toString());
            }

            if (obj.hasOwnProperty('dob') && obj.dob !== null) {
                const date = new Date(obj.dob);
                const formattedDate = date.toISOString().split('T')[0];
                formik.setFieldValue('dob', formattedDate);
            }
        });

        formik.setFieldValue('isActive', keyData[0]['isActive'] === 'Y' ? true : false);
    };

    const getMcRequestDataById = async () => {
        const res = await handleGetRequest(`/mfs_user_management/v1/management/getUserByMcRequestId/${mcRequestId}`);
        const keyData = res?.payLoad;

        Object.keys(keyData).forEach((key) => {
            if (formik.initialValues.hasOwnProperty(key) && keyData[key] !== null) {
                formik.setFieldValue(key, keyData[key]);
            }
        });

        if (keyData.hasOwnProperty('userType') && keyData.userType !== null) {
            formik.setFieldValue('userTypeId', keyData.userType.toString());
        }

        if (keyData.hasOwnProperty('partnerId') && keyData.partnerId !== null) {
            formik.setFieldValue('partner', keyData.partnerId.toString());
        }

        if (keyData.hasOwnProperty('partnerGroup') && keyData.partnerGroup !== null) {
            formik.setFieldValue('partnerGroup', keyData.partnerGroup.toString());
        }

        formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getUserData();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
        getUserTypeLov();
    }, []); // eslint-disable-line

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1); // Subtract 1 day
    const previousDate = currentDate.toISOString().split('T')[0];

    const formik = useFormik({
        initialValues: {
            authentication: '',
            userTypeId: '',
            partner: '',
            partnerGroup: '',
            employeeId: '',
            firstName: '',
            lastName: '',
            userName: '',
            password: '',
            confirmPassword: '',
            email: '',
            dob: '',
            mobileNo: '',
            isActive: false,
            checkerComments: '',
            action: ''
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getUserTypeLov = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getappusertypes/LKP_APP_USER_TYPE:*`);
        setUserTypeLovData(res?.payLoad);
    };

    const getPartnerLov = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getpartners/${formik.values.userTypeId}`);
        setPartnerLovData(resp?.payLoad);
    };

    const getPartnerGroupLov = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getpartnerGroup/${formik.values.partner}`);
        setPartnerGroupLovData(resp?.payLoad);
    };

    useEffect(() => {
        if (['', null, undefined].includes(formik?.values?.userTypeId)) {
            return;
        } else {
            getPartnerLov();
        }
    }, [formik?.values?.userTypeId]); // eslint-disable-line

    useEffect(() => {
        if (['', null, undefined].includes(formik?.values?.partner)) {
            return;
        } else {
            getPartnerGroupLov();
        }
    }, [formik?.values?.partner]); // eslint-disable-line

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="authentication" className={classNames({ 'p-error': isFormFieldValid('authentication') }, 'Label__Text')}>
                                    Authentication
                                </label>
                                <Dropdown
                                    id="authentication"
                                    placeholder="Select Authentication"
                                    options={[]}
                                    optionLabel="description"
                                    name="authentication"
                                    optionValue="description"
                                    value={formik.values.authentication || ''}
                                    disabled={true}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('authentication') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('authentication')}
                            </div>
                        </div> */}
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="userTypeId" className={classNames({ 'p-error': isFormFieldValid('userTypeId') }, 'Label__Text')}>
                                    User Type<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="userTypeId"
                                    placeholder="Select User Type"
                                    options={userTypeLovData}
                                    optionLabel="name"
                                    name="userTypeId"
                                    optionValue="lovId"
                                    value={formik.values.userTypeId || ''}
                                    disabled={true}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('userTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('userTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="partner" className={classNames({ 'p-error': isFormFieldValid('partner') }, 'Label__Text')}>
                                    Partner<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="partner"
                                    placeholder="Select Partner"
                                    options={partnerLovData}
                                    optionLabel="name"
                                    name="partner"
                                    optionValue="lovId"
                                    value={formik.values.partner || ''}
                                    disabled={true}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('partner') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('partner')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="partnerGroup" className={classNames({ 'p-error': isFormFieldValid('partnerGroup') }, 'Label__Text')}>
                                    partner Group
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="partnerGroup"
                                    placeholder="Select Partner Group"
                                    options={partnerGroupLovData}
                                    optionLabel="code"
                                    name="partnerGroup"
                                    optionValue="lovId"
                                    value={formik.values.partnerGroup || ''}
                                    disabled={true}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('partnerGroup') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('partnerGroup')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="employeeId" className={classNames({ 'p-error': isFormFieldValid('employeeId') }, 'Label__Text')}>
                                    Employee ID <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="employeeId"
                                    placeholder="Enter Employee ID"
                                    name="employeeId"
                                    value={formik?.values?.employeeId || ''}
                                    disabled={true}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('employeeId') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('employeeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="firstName" className={classNames({ 'p-error': isFormFieldValid('firstName') }, 'Label__Text')}>
                                    First Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="firstName"
                                    placeholder="Enter First Name"
                                    name="firstName"
                                    value={formik?.values?.firstName || ''}
                                    disabled={true}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('firstName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('firstName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lastName" className={classNames({ 'p-error': isFormFieldValid('lastName') }, 'Label__Text')}>
                                    Last Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="lastName"
                                    placeholder="Enter Last Name"
                                    name="lastName"
                                    value={formik?.values?.lastName || ''}
                                    disabled={true}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lastName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('lastName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="userName" className={classNames({ 'p-error': isFormFieldValid('userName') }, 'Label__Text')}>
                                    User Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="userName"
                                    placeholder="Enter User Name"
                                    name="userName"
                                    value={formik?.values?.userName || ''}
                                    disabled={true}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('userName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('userName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') }, 'Label__Text')}>
                                    Email
                                </label>
                                <InputText
                                    id="email"
                                    placeholder="Enter email"
                                    name="email"
                                    value={formik?.values?.email || ''}
                                    disabled={true}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('email') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('email')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dob" className={classNames({ 'p-error': isFormFieldValid('dob') }, 'Label__Text')}>
                                    DOB
                                </label>
                                <InputText
                                    id="dob"
                                    type="date"
                                    max={previousDate}
                                    name="dob"
                                    value={formik?.values?.dob || ''}
                                    disabled={true}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dob') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dob')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="mobileNo" className={classNames({ 'p-error': isFormFieldValid('mobileNo') }, 'Label__Text')}>
                                    Mobile No.
                                </label>
                                <InputText
                                    id="mobileNo"
                                    placeholder="Enter Mobile No."
                                    name="mobileNo"
                                    value={formik?.values?.mobileNo || ''}
                                    disabled={true}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('mobileNo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('mobileNo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                    isActive
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isActive" name="isActive" value={formik.values.isActive} disabled={true} checked={formik.values.isActive} onChange={formik.handleChange} />
                                </div>
                                {getFormErrorMessage('isActive')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="requestTypeDescr" className={classNames({ 'p-error': isFormFieldValid('requestTypeDescr') }, 'Label__Text')}>
                                    Request Type Description
                                </label>
                                <InputText
                                    disabled={true}
                                    id="requestTypeDescr"
                                    placeholder="Enter Name Of Account"
                                    name="requestTypeDescr"
                                    value={requestTypeDescr}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('requestTypeDescr') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('requestTypeDescr')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    disabled={true}
                                    rows={5}
                                    cols={30}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button onClick={() => navigate('/requestchecker')} label="Okay" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default ViewUserManageChecker;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from 'primereact/multiselect';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { useLocation } from 'react-router-dom';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { toast } from 'react-toastify';

function UpdateQualifyingCriteriaCampaign({ activeIndex, handleStepClick, campaignData, setStepData3 }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [transactionsLov, setTransactionsLov] = useState([]);
    const [channelLov, setChannelLov] = useState([]);

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const campaignId = query.get('campaignId');

    const getTransactionsLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setTransactionsLov(res?.payLoad);
    };

    const getChannel = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchannels/LKP_CHANNEL:*');
        setChannelLov(res?.payLoad);
    };

    useEffect(() => {
        getTransactionsLov();
        getChannel();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (campaignData) {
            formik.setFieldValue('transactionMinAmount', campaignData?.qcMinTransAmount?.toString());
            formik.setFieldValue('maintainMinAmount', campaignData?.qcMinBalance?.toString());

            const selectedTransactionsType = campaignData?.qcTransaction?.map((item) => {
                return { lovId: item.toString() };
            });

            const selectedChannel = campaignData?.qcChannel?.map((item) => {
                return { lovId: item.toString() };
            });

            formik.setFieldValue('transactionTypeIds', selectedTransactionsType);
            formik.setFieldValue('channelIds', selectedChannel);

            if (selectedTransactionsType?.length > 0) {
                formik.setFieldValue('isQcTransaction', true);
            } else {
                formik.setFieldValue('isQcTransaction', false);
            }

            if (campaignData?.qcChannel.length > 0) {
                formik.setFieldValue('isChannel', true);
            } else {
                formik.setFieldValue('isChannel', false);
            }

            if (campaignData?.qcMinBalance != undefined || campaignData?.qcMinBalance != null) {
                formik.setFieldValue('isMaintainMinBalance', true);
            } else {
                formik.setFieldValue('isMaintainMinBalance', false);
            }
            formik.setFieldValue('merchantIds', campaignData?.merchantId);
            formik.setFieldValue('merchantCodeCategory', campaignData?.merchantCategoryId);
            formik.setFieldValue('isMerchantId', campaignData?.merchantId === '' || campaignData?.merchantId === null ? false : true);
            formik.setFieldValue('isMerchantCategoryCode', campaignData?.merchantCategoryId === '' || campaignData?.merchantCategoryId === null ? false : true);
        }
    }, []); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        transactionTypeIds: Yup.mixed().required('This field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            isQcTransaction: false,
            transactionTypeIds: [],
            transactionMinAmount: '',
            isMaintainMinBalance: false,
            maintainMinAmount: '',

            isChannel: false,
            channelIds: [],

            isMerchantId: false,
            merchantIds: '',

            isMerchantCategoryCode: false,
            merchantCodeCategory: ''
        },
        validate: (data) => {
            let errors = {};

            // if (data?.transactionTypeIds.length === 0) {
            //     errors.transactionTypeIds = 'This field is required';
            // }

            if (data?.isMaintainMinBalance === true) {
                if (data?.maintainMinAmount === null || data?.maintainMinAmount === '' || data?.maintainMinAmount === 0 || !/^[0-9]*$/.test(data.maintainMinAmount)) {
                    errors.maintainMinAmount = 'Please enter min balance of transaction';
                }
            }

            if (data?.isChannel === true) {
                if (data?.channelIds.length === 0) {
                    errors.channelIds = 'This field is required';
                }
            }

            if (data?.isMerchantId === true) {
                if (!data?.merchantIds) {
                    errors.merchantIds = 'This field is required';
                }
            }

            if (data?.isMerchantCategoryCode === true) {
                if (!data?.merchantCodeCategory) {
                    errors.merchantCodeCategory = 'This field is required';
                }
            }

            if (data?.isQcTransaction === true) {
                if (data?.transactionMinAmount === null || data?.transactionMinAmount === '' || data?.transactionMinAmount === 0 || !/^[0-9]*$/.test(data.transactionMinAmount)) {
                    errors.transactionMinAmount = 'Please enter minimum amount of transaction';
                }
            }

            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            if (data?.isQcTransaction === false && data?.isMaintainMinBalance === false && data?.isChannel === false && data?.isMerchantId === false && data?.isMerchantCategoryCode === false) {
                toast.warn('Please select at least one qualifying criteria');
                return;
            }

            data['campaignId'] = campaignId;
            data['stepNo'] = '2';

            data['transactionMinAmount'] = parseInt(data?.transactionMinAmount);
            data['maintainMinAmount'] = parseInt(data?.maintainMinAmount);

            const arrayOfChannelIds = formik?.values?.channelIds.map((item) => parseInt(item?.lovId, 10));
            data['channelIds'] = arrayOfChannelIds;

            const arrayOfTransactionTypeIds = formik?.values?.transactionTypeIds.map((item) => parseInt(item?.lovId, 10));
            data['transactionTypeIds'] = arrayOfTransactionTypeIds;

            if (data?.isQcTransaction === false && arrayOfTransactionTypeIds?.length < 1) {
                data['transactionTypeIds'] = [];
                data['transactionMinAmount'] = '';
            }

            setStepData3(data);
            handleStepClick(activeIndex + 1);

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const panelTransactionsTypeFooterTemplate = () => {
        const length = formik?.values?.transactionTypeIds ? formik?.values?.transactionTypeIds.length : 0;

        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    const panelChannelsTransactionFooterTemplate = () => {
        const length = formik?.values?.channelIds ? formik?.values?.channelIds.length : 0;

        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>Edit Qualifying Criteria</h1>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="transactionTypeIds" className={classNames({ 'p-error': isFormFieldValid('transactionTypeIds') }, 'Label__Text')}>
                                    Transactions Type
                                    {/* <span className="Label__Required">*</span> */}
                                </label>
                                <div className="flex">
                                    <Checkbox
                                        inputId="isQcTransaction"
                                        name="isQcTransaction"
                                        value={formik?.values?.isQcTransaction}
                                        checked={formik?.values?.isQcTransaction}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            if (!e.target.checked) {
                                                formik.setFieldValue('transactionTypeIds', []);
                                                formik.setFieldValue('transactionMinAmount', 0);
                                            }
                                        }}
                                        style={{ marginTop: '10px' }}
                                    />
                                    <MultiSelect
                                        id="transactionTypeIds"
                                        placeholder="Select Transaction Type"
                                        options={transactionsLov}
                                        optionLabel="name"
                                        name="transactionTypeIds"
                                        optionValue="lovId"
                                        disabled={formik?.values?.isQcTransaction === true ? false : true}
                                        panelFooterTemplate={panelTransactionsTypeFooterTemplate}
                                        value={formik?.values?.transactionTypeIds.map((trans) => trans.lovId || '')}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'transactionTypeIds',
                                                e.value.map((lovId) => ({
                                                    lovId
                                                }))
                                            );
                                        }}
                                        className={classNames({ 'p-invalid': isFormFieldValid('transactionTypeIds') }, 'Dropdown__Round')}
                                        style={{ marginLeft: '20px', maxWidth: '90%' }}
                                    />
                                    {getFormErrorMessage('transactionTypeIds')}
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="transactionMinAmount" className={classNames({ 'p-error': isFormFieldValid('transactionMinAmount') }, 'Label__Text')}>
                                    Minimum amount of Transaction <span className="Label__Required">*</span>
                                </label>
                                <InputNumber
                                    inputId="transactionMinAmount"
                                    name="transactionMinAmount"
                                    maxLength={9}
                                    disabled={formik?.values?.isQcTransaction === true ? false : true}
                                    placeholder="Enter Min amount of transaction"
                                    value={formik?.values?.transactionMinAmount || ''}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transactionMinAmount') }, 'Input_Number')}
                                />

                                <small className="p-error">{formik.errors.transactionMinAmount}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="maintainMinAmount" className={classNames({ 'p-error': isFormFieldValid('maintainMinAmount') }, 'Label__Text')}>
                                    Minimum Balance Maintained
                                </label>
                                <div className="flex">
                                    <Checkbox
                                        inputId="isMaintainMinBalance"
                                        name="isMaintainMinBalance"
                                        value={formik?.values?.isMaintainMinBalance}
                                        checked={formik?.values?.isMaintainMinBalance}
                                        onChange={formik.handleChange}
                                        style={{ marginTop: '10px' }}
                                    />
                                    <div className="Inputfield__Fulllength">
                                        <InputNumber
                                            inputId="maintainMinAmount"
                                            name="maintainMinAmount"
                                            maxLength={9}
                                            disabled={formik?.values?.isMaintainMinBalance === true ? false : true}
                                            placeholder="Enter Min Balance Maintained"
                                            value={formik?.values?.maintainMinAmount || ''}
                                            onValueChange={formik.handleChange}
                                            style={{ marginLeft: '20px' }}
                                            className={classNames({ 'p-invalid': isFormFieldValid('maintainMinAmount') }, 'Input_Number')}
                                        />
                                    </div>
                                    {/* {formik?.values?.isMaintainMinBalance &&
                                        <>
                                            <InputText
                                                id="maintainMinAmount"
                                                placeholder="Enter Minimum Balance Maintained"
                                                name="maintainMinAmount"
                                                value={formik?.values?.maintainMinAmount || ''}
                                                onChange={formik.handleChange}
                                                style={{ marginLeft: "20px" }}
                                                className={classNames({ 'p-invalid': isFormFieldValid('maintainMinAmount') }, 'Input__Round')}
                                            />
                                            {getFormErrorMessage('maintainMinAmount')}
                                        </>
                                    } */}
                                </div>
                                <small className="p-error">{formik.errors.maintainMinAmount}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="channelTransaction" className={classNames({ 'p-error': isFormFieldValid('channelTransaction') }, 'Label__Text')}>
                                    Channel of Transaction
                                </label>
                                <div className="flex">
                                    <Checkbox inputId="isChannel" name="isChannel" value={formik?.values?.isChannel} checked={formik?.values?.isChannel} onChange={formik.handleChange} style={{ marginTop: '10px' }} />
                                    <div className="Inputfield__Fulllength">
                                        <MultiSelect
                                            id="channelIds"
                                            placeholder="Select Channel Of Transaction"
                                            options={channelLov}
                                            optionLabel="name"
                                            name="channelIds"
                                            optionValue="lovId"
                                            panelFooterTemplate={panelChannelsTransactionFooterTemplate}
                                            disabled={formik?.values?.isChannel === true ? false : true}
                                            value={formik?.values?.channelIds.map((trans) => trans.lovId || '')}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    'channelIds',
                                                    e.value.map((lovId) => ({
                                                        lovId
                                                    }))
                                                );
                                            }}
                                            style={{ marginLeft: '20px', maxWidth: '91%' }}
                                            className={classNames({ 'p-invalid': isFormFieldValid('channelIds') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('channelIds')}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="merchantIds" className={classNames({ 'p-error': isFormFieldValid('merchantIds') }, 'Label__Text')}>
                                    Merchant ID
                                </label>
                                <div className="flex">
                                    <Checkbox inputId="isMerchantId" name="isMerchantId" value={formik?.values?.isMerchantId} checked={formik?.values?.isMerchantId} onChange={formik.handleChange} style={{ marginTop: '10px' }} />
                                    <div className="Inputfield__Fulllength">
                                        <InputText
                                            id="merchantIds"
                                            placeholder="Enter Campaign Merchant"
                                            name="merchantIds"
                                            maxLength={500}
                                            disabled={formik?.values?.isMerchantId === true ? false : true}
                                            value={formik?.values?.merchantIds || ''}
                                            onChange={formik.handleChange}
                                            style={{ marginLeft: '20px', maxWidth: '90%' }}
                                            className={classNames({ 'p-invalid': isFormFieldValid('merchantIds') }, 'Input__Round')}
                                        />
                                    </div>
                                </div>
                                <small className="p-error">{formik.errors.merchantIds}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="merchantCodeCategory" className={classNames({ 'p-error': isFormFieldValid('merchantCodeCategory') }, 'Label__Text')}>
                                    Merchant Category Id
                                </label>
                                <div className="flex">
                                    <Checkbox
                                        inputId="isMerchantCategoryCode"
                                        name="isMerchantCategoryCode"
                                        value={formik?.values?.isMerchantCategoryCode}
                                        checked={formik?.values?.isMerchantCategoryCode}
                                        onChange={formik.handleChange}
                                        style={{ marginTop: '10px' }}
                                    />
                                    <div className="Inputfield__Fulllength">
                                        <InputText
                                            id="merchantCodeCategory"
                                            placeholder="Enter Campaign Merchant Category"
                                            name="merchantCodeCategory"
                                            maxLength={500}
                                            disabled={formik?.values?.isMerchantCategoryCode === true ? false : true}
                                            value={formik?.values?.merchantCodeCategory || ''}
                                            onChange={formik.handleChange}
                                            style={{ marginLeft: '20px', maxWidth: '90%' }}
                                            className={classNames({ 'p-invalid': isFormFieldValid('merchantCodeCategory') }, 'Input__Round')}
                                        />
                                    </div>
                                </div>
                                <small className="p-error">{formik.errors.merchantCodeCategory}</small>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Next" className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default UpdateQualifyingCriteriaCampaign;

import React from 'react';
import MakerApis from '../Components/MakerApis';

function ViewPmd() {
    return (
        <React.Fragment>
            <MakerApis type="view" />
        </React.Fragment>
    );
}
export default ViewPmd;

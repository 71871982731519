import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../../../services/PostTemplate';

function AccountSettelmentDialogChecker({onHidesettleAccount, agentId, accountNo, data}) {
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [loading, setloading] = useState(false);
    
    const dispatch = useDispatch()

       useEffect(() => {
        formik.setFieldValue('remarks', data)
        formik.setFieldValue('accountNumber', accountNo)
    }, [])
    
     const formik = useFormik({
        initialValues: {
             accountNumber: '',
             status: 'CLS',
             remarks: '',
             agentId: agentId,
            settle:true
         },
           onSubmit: async (data) => {
            setloading(true);


            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data,
                    },
                    additionalInformation: [],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/account/v1/agentaccount/updateagentaccountstatus', true, false, 'accountsmanagement'));
               setloading(false);
               onHidesettleAccount()
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleCancel = (e) => {
         e.preventDefault();
        onHidesettleAccount();
        formik.resetForm();
    }
  return (
     <>
             <div className="card Card__Round">
            <form className="p-fluid p-mt-2" onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid grid mb-5">
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="accountNumber" className={classNames({ 'p-error': isFormFieldValid('accountNumber') }, 'Label__Text')}>
                                    Account No.<span className="Label__Required">*</span>
                                </label>
                              <InputText
                                  disabled
                                maxLength={20}
                                id="accountNumber"
                                placeholder="Please Enter account number"
                                name="accountNumber"
                                value={formik?.values?.accountNumber || ''}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('accountNumber') }, 'Input__Round')}
                            />

                            {getFormErrorMessage('accountNumber')}
                            </div>
                            
                        </div>
                         <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="remarks" className={classNames({ 'p-error': isFormFieldValid('remarks') }, 'Label__Text')}>
                                    Reason<span className="Label__Required">*</span>
                                </label>
                              <InputTextarea
                                  disabled
                                id="remarks"
                                placeholder="Please Enter Reason"
                                name="remarks"
                                value={formik?.values?.remarks || ''}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('remarks') }, 'Input__Round')}
                            />

                            {getFormErrorMessage('remarks')}
                            </div>
                            
                        </div>
                        
                    </div>
                     <div className="Down__Btn">
                        <Button label="Ok" className="Btn__cancel" onClick={(e) => handleCancel(e)} />
                    </div>
                </form>
                </div>
        </>
  )
}

export default AccountSettelmentDialogChecker
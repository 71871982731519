import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { handlePostRequest } from '../../services/PostTemplate';
import { Column } from 'primereact/column';
import { useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';


function ViewUseCaseManagement() {
    const [loading, setloading] = useState(false);
    // const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [dataShowToTable, setDataShowToTable] = useState([]);
    const [processNameData, setProcessNameData] = useState([]);
    const [roleData, setRoleData] = useState([]);
    const [useCaseData, setUseCaseData] = useState([]);
    const [tableNameoptions, setTableNameoptions] = useState([]);

    const [usecaseManagementData, setUsecaseManagementData] = useState([]);
    const [userLov, setUserLov] = useState([]);
 


    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const mcConfigId = query.get('mcConfigId');

    // const dataTableRef = useRef(null);

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });
    const getUseCaseById = async () => {
        const resp = await handleGetRequest(`/mfs_user_management/v1/management/getUseCaseById/${mcConfigId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik?.setFieldValue(key, keyData[key]);
                }
            });
          

            setUseCaseData(resp?.payLoad);

            formik.setFieldValue('useCaseName', keyData?.configName);
            formik.setFieldValue('useCaseDescr', keyData?.configDescription);
            formik.setFieldValue('useCaseComments', keyData?.comments);
            formik.setFieldValue('processName', keyData?.formName.toString());


            // Extract and format the data for the DataTable
            const tableData = keyData?.tblMcConfigDetails.map((item) => {
                return {
                    // emailTemplateId: item.tblEmailTemplate?.templateName || '',
                    approvalType: item.approvalType || '',
                    userId: item?.tblUser?.firstName || '',
                    roleId: item?.tblRole?.roleDescr || '',
                    intimateOnly: item.intimateOnly || '',
                    approvalCriteria: item.approvalCriteria || ''
                    //
                };
            });

            // Set the formatted data in your state
            setDataShowToTable(tableData);

          
        }
    };

    useEffect(() => {
        getUseCaseById();
    }, []);// eslint-disable-line


    const dt = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formik = useFormik({
        validationSchema,
        initialValues: {
            useCaseName: '',
            useCaseDescr: '',
            processName: {
                tableName: '',
                formName: '',
                viewDetailUrl: '',
                editDetailUrl: ''
            },
            useCaseComments: '',
            useCaseDetailRequests: [
                {
                    approvalType: '',
                    intimateOnly: '',
                    approvalCriteria: '',
                    userId: '',
                    roleId: '',
                    // emailTemplateId: ''
                }
            ]
        },

        onSubmit: async (data) => {
            setloading(true);
            setloading(true);
            // setloadingIcon('pi pi-spin pi-spinner');
            const foundItem = processNameData.find((item) => item.formName === formik.values.processName);

            const payloadData = {
                mcConfigId: mcConfigId,
                useCaseName: data.useCaseName,
                useCaseDescr: data.useCaseDescr,
                useCaseComments: data.useCaseComments,
                processName: {
                    tableName: foundItem.tableName,
                    formName: foundItem.formName,
                    viewDetailUrl: foundItem.viewDetailUrl,
                    editDetailUrl: foundItem.editDetailUrl,
                    requestType: foundItem.requestType
                },
                useCaseDetailRequests: usecaseManagementData
            };

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: payloadData,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/mfs_user_management/v1/management/updateusecase', true, false, 'useCaseManagement'));
            if (res?.responseCode === '030000') {
                formik.resetForm();
            }
            setloading(false);
            // setloadingIcon('pi pi-search');
        }
    });

    // const handleAdd = (event) => {
    //     event.preventDefault();

    //     const data = {
    //         approvalCriteria: formik.values.approvalCriteria,
    //         userId: formik.values.userId,
    //         roleId: formik.values.roleId,
       
    //         intimateOnly: formik.values.intimateOnly ? 'Y' : 'N',
    //         approvalType: formik.values.approvalType
    //     };
    //     let matchApproval = formik.values.approvalCriteria;
    //     let matchUser = userLov.find((dataItem) => dataItem.lovId == formik.values.userId);
    //     let matchRole = roleData.find((dataItem) => dataItem.lovId == formik.values.roleId);

    //     let matchCriteria = formik.values.approvalType;
  
    //     let dataForTable = {
    //         approvalCriteria: matchApproval ? matchApproval : '',
    //         userId: matchUser ? matchUser?.name : '',
    //         roleId: matchRole ? matchRole?.name : '',
        
    //         intimateOnly: formik.values.intimateOnly ? 'Y' : 'N',
    //         approvalType: matchCriteria ? matchCriteria : ''
    //     };
    //     setDataShowToTable([...dataShowToTable, dataForTable]);
        // setUsecaseManagementData([...usecaseManagementData, data]);
    // };


    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
            </div>
        );
    };

    const header = renderHeader();

    //dropdown data

    const statusData = [
        { label: 'Role Based ', value: 'R' },
        { label: 'User Based ', value: 'U' }
    ];

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };
    const getProcess = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getprocessname/LKP_MC_FORM_NAME:*');
        const data = resp?.payLoad;
        const tableNames = data.map((item) => {
            return item?.formName;
        });
 

        setProcessNameData(data);
        setTableNameoptions(tableNames);
    };

  

    const getRole = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getUserGroup/TBL_ROLE:*');
        setRoleData(resp?.payLoad);
    };
    useEffect(() => {
        getRole();
      
        getUserLov();
        getProcess();
    }, []);// eslint-disable-line


    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    // const previousDate = currentDate.toISOString().split('T')[0];

    const handledAprrovalTypeBodyTemplate = (rowData) => {
        if (rowData) {
            return <div>{rowData?.approvalCriteria === 'R' ? 'Role Based' : rowData?.approvalCriteria === 'U' ? 'User Based' : null}</div>;
        }
    };
    const handledIntimateOnlyBodyTemplate = (rowData) => {
        if (rowData) {
            return <div>{rowData?.intimateOnly === 'Y' ? 'Yes' : rowData?.intimateOnly === 'N' ? 'No' : null}</div>;
        }
    };

    const handledApprovalCrieteriaBodyTemplate = (rowData) => {
        if (rowData) {
            return <div>{rowData?.approvalType === 'N' ? 'Any One' : rowData?.approvalType === 'A' ? 'All' : null}</div>;
        }
    };
    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>View Detail</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="useCaseName" className={classNames({ 'p-error': isFormFieldValid('useCaseName') }, 'Label__Text')}>
                                    Use Case Name
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="useCaseName"
                                    placeholder="Enter Use Case Name"
                                    name="useCaseName"
                                    value={formik?.values?.useCaseName}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('useCaseName') }, 'Input__Round')}
                                    disabled={formik.values.useCaseName !== ' ' ? true : false}
                                />
                                {getFormErrorMessage('useCaseName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="useCaseComments" className={classNames({ 'p-error': isFormFieldValid('useCaseComments') }, 'Label__Text')}>
                                    Comment
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="useCaseComments"
                                    placeholder="Add Comment"
                                    name="useCaseComments"
                                    value={formik?.values?.useCaseComments?.replace(/\s\s+/g, '')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('useCaseComments') }, 'Input__Round')}
                                    disabled={formik.values.useCaseComments !== ' ' ? true : false}
                                />

                                {getFormErrorMessage('useCaseComments')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="approval" className={classNames({ 'p-error': isFormFieldValid('approval') }, 'Label__Text')}>
                                    Process Name
                                </label>
                                <Dropdown
                                    id="processName"
                                    // placeholder="Select process Name"
                                    options={tableNameoptions}
                                    // optionLabel="tableName"
                                    name="processName"
                                    // optionValue="mcFormNameId"
                                    value={formik.values.processName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('processName') }, 'Dropdown__Round')}
                                    disabled={formik.values.processName !== ' ' ? true : false}
                                />
                                {getFormErrorMessage('processName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="useCaseDescr" className={classNames({ 'p-error': isFormFieldValid('useCaseDescr') }, 'Label__Text')}>
                                    Description
                                </label>
                                <InputTextarea
                                  
                                    id="useCaseDescr"
                                    name="useCaseDescr"
                                    value={formik?.values?.useCaseDescr}
                                    rows={5}
                                    cols={10}
                                    autoResize="false"
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('useCaseDescr') }, 'TextArea__Round')}
                                    disabled={formik.values.useCaseDescr !== ' ' ? true : false}
                                />

                                {getFormErrorMessage('useCaseDescr')}
                            </div>
                        </div>

                        <div className="col-12 md:col-8 Form__Header Full__Width"></div>
                    </div>
                    <div className="card p-fluid p-formgrid grid  " style={{ backgroundColor: 'rgb(237, 240, 245)' }}>
                        <div className="p-field col-12 md:col-3">
                            <div className="p-field">
                                <label htmlFor="approvalCriteria" className={classNames({ 'p-error': isFormFieldValid('approvalCriteria') }, 'Label__Text')}>
                                    Define Approval
                                </label>
                                <Dropdown
                                    id="approvalCriteria"
                                
                                    options={statusData}
                                    optionLabel="label"
                                    name="approvalCriteria"
                                    optionValue="value"
                                    value={formik.values.approvalCriteria || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('approvalCriteria') }, 'Dropdown__Round')}
                                    disabled={formik.values.approvalCriteria !== ' ' ? true : false}
                                />
                                {getFormErrorMessage('approvalCriteria')}
                            </div>
                        </div>
                        {formik.values.approvalCriteria === 'R' ? (
                            <div className="p-field col-12 md:col-3">
                                <div className="p-field">
                                    <label htmlFor="roleId" className={classNames({ 'p-error': isFormFieldValid('roleId') }, 'Label__Text')}>
                                        Role
                                    </label>
                                    <Dropdown
                                        id="roleId"
                                  
                                        options={roleData}
                                        optionLabel="name"
                                        name="roleId"
                                        optionValue="lovId"
                                        value={formik.values.roleId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('roleId') }, 'Dropdown__Round')}
                                        disabled={formik.values.roleId !== ' ' ? true : false}
                                    />
                                    {getFormErrorMessage('roleId')}
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {formik.values.approvalCriteria === 'U' ? (
                            <div className="p-field col-12 md:col-3">
                                <div className="p-field">
                                    <label htmlFor="userId" className={classNames({ 'p-error': isFormFieldValid('userId') }, 'Label__Text')}>
                                        User
                                    </label>
                                    <Dropdown
                                        id="userId"
                                      
                                        options={userLov}
                                        optionLabel="name"
                                        name="userId"
                                        optionValue="lovId"
                                        value={formik.values.userId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('userId') }, 'Dropdown__Round')}
                                        disabled={formik.values.userId !== ' ' ? true : false}
                                    />
                                    {getFormErrorMessage('userId')}
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {formik.values.approvalCriteria === 'R' ? (
                            <div className="col-12 lg:col-6 xl:col-4 md:col-4 pt-5 pb-5 flex">
                                <div className="flex justify-content-center gap-2">
                                    <div className="flex ">
                                        <div className="">
                                            <label htmlFor="velocity" className={classNames({ 'p-error': isFormFieldValid('velocity') }, 'Label__Text')}>
                                                Approval Criteria
                                            </label>
                                        </div>
                                        <div className="flex">
                                            <div className="flex ml-3">
                                                <RadioButton
                                                    inputId="approvalType"
                                                    name="approvalType"
                                                    value="N"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.approvalType === 'N'}
                                                    disabled={formik.values.approvalType !== ' ' ? true : false}
                                                />
                                                <label htmlFor="approvalCriteria" className="ml-2">
                                                Any One
                                                </label>
                                            </div>
                                            <div style={{ marginLeft: '5rem' }} className="flex mr-3">
                                                <RadioButton
                                                    inputId="approvalType"
                                                    name="approvalType"
                                                    value="A"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.approvalType === 'A'}
                                                    disabled={formik.values.approvalType !== ' ' ? true : false}
                                                />
                                                <label htmlFor="approvalType" className="ml-2">
                                                    All
                                                </label>
                                            </div>
                                        </div>
                                        {getFormErrorMessage('isSearchable')}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        <div className="">
                            <div className="p-field col-12 md:col-2 pt-5 pb-5 flex">
                                <div className="p-field">
                                    <div className="field-checkbox">
                                        <label htmlFor="intimateOnly" className={classNames({ 'p-error': isFormFieldValid('intimateOnly') }, 'Label__Text')}>
                                            Intimate Only
                                        </label>
                                        <Checkbox
                                            inputId="intimateOnly"
                                            name="intimateOnly"
                                            value={formik.values.intimateOnly}
                                            checked={formik.values.intimateOnly}
                                            onChange={formik.handleChange}
                                            className=" ml-3"
                                            disabled={formik.values.intimateOnly !== ' ' ? true : false}
                                        />
                                    </div>
                                    {getFormErrorMessage('intimateOnly')}
                                </div>
                            </div>
                        </div>


                        <div className="Usergroup__SearchBtn p-field flex flex-row  justify-content-center pt-2 col-12" style={{ textAlign: 'center' }}>
                            <div className="">{/* <Button onClick={(event) => handleAdd(event)} label="Update Record" className="Btn__Dark__Search" /> */}</div>
                        </div>
                    </div>

                    {/* </div> */}
                    <div className="col-12">
                        <div className="card">
                            <DataTable
                                ref={dt}
                                paginator
                                rows={5}
                                rowsPerPageOptions={[5, 10, 25]}
                                className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} "
                                header={header}
                                emptyMessage="No List found."
                                responsiveLayout="scroll"
                                value={dataShowToTable}
                            >
                            
                                <Column body={(data, props) => <div>{props.rowIndex + 1}</div>}  header="Sr" />
                                <Column field="approvalCriteria" body={handledAprrovalTypeBodyTemplate} header="Define Approval" />
                               

                                <Column field="userId" header="User" />
                                <Column field="roleId" header="Role" />
                                <Column field="intimateOnly" header="Intimate Only" body={handledIntimateOnlyBodyTemplate} />
                                <Column field="approvalType" body={handledApprovalCrieteriaBodyTemplate} header="Approval Criteria" />
                            </DataTable>
                            <div className="Usergroup__SearchBtn p-field flex flex-row  justify-content-center pt-2 col-12" style={{ textAlign: 'center' }}>
                                <div className="Down__Btn">
                                    <Button disabled={loading} onClick={() => navigate('/useCaseManagement')} label="Okay" className="p-button p-component Btn__Dark__Search" />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
export default ViewUseCaseManagement;

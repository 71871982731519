import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import { FilterMatchMode } from 'primereact/api';
import ActiveInActiveDialog from '../../../../components/ActiveInActiveDialog/ActiveInActiveDialog';

function SignUpLimits() {
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [switchData, setSwitchData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [signupLimitData, setSignupLimitData] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [userLovData, setUserLovData] = useState([]);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        firstName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const dataTableRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cmsSignupLimitId: '',
            statusId: '2',
            isPermanentBlock:'',
            isTemporaryBlock:'',
            breachLimit:'',
            noOfAttempts:'',
            createDate: '',
            lastUpdateDate: '',
            createdBy: ''
        },

        onSubmit: async (data) => {
            setloadingIcon('pi pi-spin pi-spinner');

            const jsonObj = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },

                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },

                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',

                    payLoad: {
                        ...data
                    },

                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],

                    checkSum: ''
                }
            };
            delete jsonObj?.data?.payLoad?.breachLimit;

            const res = await dispatch(handlePostRequest(jsonObj, '/cms/v1/cms/searchallsignuplimits', true, true));

            if (res?.responseCode === '220000') {
                setSignupLimitData(res?.payLoad);
                formik.resetForm();
            }
            setloadingIcon('pi pi-search');
        }
    });

    const EditUserHandled = (rowData) => {
        navigate('/editSignUpLimits', { state: { rowData } });
    };

    const ViewUserHandled = (rowData) => {
        navigate('/viewSignUpLimits', { state: { rowData } });
    };

    const getStatusLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(res?.payLoad);
    };
    const getUserLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLovData(res?.payLoad);
    };

    const handleSwitchChange = async () => {
        const updatedData = signupLimitData?.map((item) => {
            if (item?.cmsSignupLimitId === switchData?.cmsSignupLimitId) {
                const isActive = item?.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setSignupLimitData(updatedData);
        let isactive = '';
        if (switchData?.isActive === 'Y') {
            isactive = 'N';
        } else if (switchData?.isActive === 'N') {
            isactive = 'Y';
        }

        const previousValue = switchData?.isActive;
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    id: switchData?.cmsSignupLimitId,
                    // isActive: isactive
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newData, '/cms/v1/cms/inactivesignuplimits', true, false));
        if (res?.responseCode == '220000') {
            const revertedData = signupLimitData?.map((item) => {
                if (item.cmsSignupLimitId == switchData?.cmsSignupLimitId) {
                    const isActive = isactive;
                    return { ...item, isActive };
                }
                return item;
            });
            setSignupLimitData(revertedData);
        } else {
            const revertedData = signupLimitData?.map((item) => {
                if (item.cmsSignupLimitId == switchData?.cmsSignupLimitId) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setSignupLimitData(revertedData);
        }
    };

    useEffect(() => {
        getStatusLov();
        getUserLov();
    }, []); // eslint-disable-line

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                <InputSwitch
                    checked={rowData.isActive === 'Y'}
                    disabled={['Pending', 'Rejected', 'Assign Back'].includes(rowData?.statusName) ? true : false}
                    onChange={() => {
                        setSwitchData(rowData);
                        setShowModal(true);
                    }}
                />
                    <Button
                        tooltip="Edit"
                        icon="pi pi-pencil"
                        disabled={['Pending', 'Rejected'].includes(rowData?.statusName) || (rowData?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                        tooltipOptions={{ position: 'top' }}
                        onClick={() => EditUserHandled(rowData)}
                        className="p-button-rounded p-button-warning mr-3"
                    />
                    <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => ViewUserHandled(rowData)} className="p-button-rounded p-button-primary" />
                </div>
            </>
        );
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };
    const handledClicked = () => {
        navigate('/AddSignUpLimits');
    };
    const handleShowModal = () => {
        setShowModal(false);
    };
    const props = {
        showModal,
        handleShowModal,
        handleSwitchChange
    };
    const breachLimitsData = [
        { label: 'Temporary Block ', value: 'isTemporaryBlock' },
        { label: 'Permanent Block ', value: 'isPermanentBlock' }
    ];
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };
    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.statusName === 'Approved' ? '#14A38B' : rowData?.statusName === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.statusName}</b>
            </p>
        );
    };
    return (
        <>
            <ActiveInActiveDialog {...props} />

            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={handledClicked} className="Btn__Add__" icon="pi pi-plus" iconPos="left" style={{ width: 'auto' }} />
                </div>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                    <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="breachLimit" className={classNames({ 'p-error': isFormFieldValid('breachLimit') }, 'Label__Text')}>
                                    Breach Limits
                                </label>

                                <Dropdown
                                    id="breachLimit"
                                    options={breachLimitsData}
                                    optionLabel="label"
                                placeholder="select Value"

                                    name="breachLimit"
                                    optionValue="value"
                                    value={formik.values.breachLimit || ''}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        formik.handleChange(e);

                                        if (value === 'isPermanentBlock') {
                                            formik.setFieldValue('isPermanentBlock', 'Y');
                                            formik.setFieldValue('isTemporaryBlock', 'N');
                                        } else if (value === 'isTemporaryBlock') {
                                            formik.setFieldValue('isPermanentBlock', 'N');
                                            formik.setFieldValue('isTemporaryBlock', 'Y');
                                        }
                                    }}
                                 
                                    className={classNames({ 'p-invalid': isFormFieldValid('breachLimit') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('breachLimit')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Attempts</label>
                                <InputText
                                    id="noOfAttempts"
                                    name="noOfAttempts"
                                    placeholder="Enter Attempts"
                                    value={formik.values.noOfAttempts}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('noOfAttempts') }, 'Input__Round')}
                                    keyfilter="int"
                                />
                                {getFormErrorMessage('noOfAttempts')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                               
                                   <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLovData}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>{' '}
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createDate" className={classNames({ 'p-error': isFormFieldValid('createDate') }, 'Label__Text')}>
                                    Created Date
                                </label>
                                <InputText
                                    id="createDate"
                                    type="date"
                                    name="createDate"
                                    max={previousDate}
                                    value={formik?.values?.createDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createDate') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('createDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="lastUpdateDate" className={classNames({ 'p-error': isFormFieldValid('lastUpdateDate') }, 'Label__Text')}>
                                    Last Updated Date
                                </label>
                                <InputText
                                    id="lastUpdateDate"
                                    type="date"
                                    name="lastUpdateDate"
                                    max={previousDate}
                                    value={formik?.values?.lastUpdateDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lastUpdateDate') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('lastUpdateDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="statusId" className={classNames({ 'p-error': isFormFieldValid('statusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select statusId"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label="Search" disabled={!Object.values(formik.values).some((value) => value !== null && value !== '')} icon={loadingIcon || ''} iconPos="left" className="Btn__Dark" />
                        <Button type="button" label="Reset" iconPos="right" className="Btn__Transparent" onClick={(e) => handleReset(e)} />
                    </div>
                </form>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            filters={filters}
                            globalFilterFields={['accountLevelName', 'createuser', 'createdate', 'lkpStatus.statusName']}
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={signupLimitData}
                        >
                         
                            <Column field="noOfAttempts" header="Attempts" />
                            {/* <Column field="createdate" header="Block Date & Time" /> */}
                            <Column header="Block Date & Time" body={createdDateAndTimeBodyTemplate} />

                            <Column header="Status" body={statusBodyTemplate} />

                            <Column header="Action" body={actionBodyTemplate} />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
export default SignUpLimits;

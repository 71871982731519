import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import BaseFactorDialog from './BaseFactorDialog';

function BaseFactor() {

    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [globalFilter, setGlobalFilter] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [baseFactorData, setBaseFactorData] = useState([]);
    const [editable, setEditable] = useState(false);
    const [baseFactorRowData, setBaseFactorRowData] = useState();

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            baseFactor: '',
            dateFrom: '',
            dateTo: ''
        },

        onSubmit: async (data) => {
             const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            const res = await dispatch(handlePostRequest(newData, '/zconfig/v1/zmiles/getBaseFactor', true, true));
            setBaseFactorData(res?.payLoad)
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handledClicked = async (e) => {
        e.preventDefault();
        setEditable(false);
        setShowDialog(true)
        // const res = await handleGetRequest('/microbank_zmiles_config/zmiles/getBaseFactorByDateTo');
        // if (res?.responsecode === 1) {
        //     setShowDialog(true);
        // }
    };

    const header = (
        <div className="Global__Header">
            <h5 className="m-0">Base Factor List</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const onHideBaseFactor = () => {
        setShowDialog(false);
    };

    const EditBaseFactorHandled = (rowData) => {
        setShowDialog(true);
        setEditable(true);
        setBaseFactorRowData(rowData);
    };

    const actionBodyTemplate = (rowData) => {
        return rowData?.dateTo === null || rowData?.dataTo === '' ? (
            <div className="actions">
               
            </div>
        ) : (
                <div className="actions">
                     <Button icon="pi pi-pencil" onClick={() => EditBaseFactorHandled(rowData)} tooltip="Edit" tooltipOptions={{ position: 'top' }} className="p-button-rounded" />
                {/* <Button icon="pi pi-eye" tooltip="View" tooltipOptions={{ position: 'top' }} onClick={() => EditBaseFactorHandled(rowData)} className="p-button-rounded" /> */}
            </div>
        );
    };
    const handleReset = (e) => {
        e.preventDefault()
        formik.resetForm()
    }

    return (
        <>
            <Dialog visible={showDialog} header={editable ? "Update Base Factor" : "Add Base Factor"} style={{ width: "750px" }} onHide={() => setShowDialog(false)}>
                <BaseFactorDialog onHideBaseFactor={onHideBaseFactor} baseFactorRowData={baseFactorRowData} editable={editable} />
            </Dialog>
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={handledClicked} className="Btn__Add__" icon="pi pi-plus" iconPos="left" style={{ width: 'auto' }} />
                </div>
            </div>

            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>Base Factor</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="baseFactor" className={classNames({ 'p-error': isFormFieldValid('baseFactor') }, 'Label__Text')}>
                                    Base Factor
                                </label>
                                <InputText
                                    id="baseFactor"
                                    placeholder="Enter Base Factor"
                                    name="baseFactor"
                                    value={formik?.values?.baseFactor?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('baseFactor') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('baseFactor')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Effective From
                                </label>
                                <InputText id="dateFrom" type="date" name="dateFrom" value={formik?.values?.dateFrom} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')} />

                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Effective To
                                </label>
                                <InputText id="dateTo" type="date" name="dateTo" value={formik?.values?.dateTo} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')} />
                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button label="Search" iconPos="right" className=" Btn__Dark" />
                        <Button disabled={loading}
                            onClick={(e)=>handleReset(e)}
                            iconPos="right" label="Reset" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            {/* Base Factor DataTable */}

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            globalFilter={globalFilter}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={baseFactorData}
                        >
                            {/* <Column body={rowIndexTemplate} header="S.No" /> */}
                            <Column field="baseFactor" header="Base Factor" />
                            <Column field="dateFrom" header="Date From" />
                            <Column field="dateTo" header="Date To" />
                            <Column header="Action" body={actionBodyTemplate} />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BaseFactor;



import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handlePostRequest } from '../../../services/PostTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import { handleGetRequest } from '../../../services/GetTemplate';
import { FilterMatchMode } from 'primereact/api';

function RequestChecker() {
    const [loading, setloading] = useState(false);
    const [searchBtn, setSearchBtn] = useState(true);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [items, setItems] = useState();
    const [requestCheckerData, setRequestCheckerData] = useState([]);
    const [statusData, setStatusData] = useState([]);
    const [userLovData, setUserLovData] = useState([]);
    const [requestTypeData, setRequestTypeData] = useState([]);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        formName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const dataTableRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getStatusLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusData(res?.payLoad);
    };

    const getUserLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLovData(res?.payLoad);
    };

    const getRequestTypeData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getRequestTypes/TBL_MC_CONFIG:*');
        setRequestTypeData(res?.payLoad);
    };

    useEffect(() => {
        getStatusLov();
        getUserLov();
        getRequestTypeData();
    }, []);

    const validationSchema = Yup.object().shape({
        fromDate: Yup.date().nullable()
        // toDate: Yup.date().min(Yup.ref('fromDate'), 'Date To has to be more than Date From').nullable()
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            actionId: '',
            formName: '',
            fromDate: '',
            toDate: '',
            statusId: '',
            createdBy: '',
            updatedBy: ''
        },
        validate: (data) => {
            let errors = {};
            if (data.toDate && !data.fromDate) {
                errors.fromDate = 'Date From is required when Date To is provided';
            }

            if (data.fromDate && data.toDate && data.fromDate > data.toDate) {
                errors.toDate = 'Date To must be greater than or equal to Date From';
            }
            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            dataTableRef.current.reset();
            setRequestCheckerData('');

            const res = await dispatch(handlePostRequest(newData, '/workflow/v1/management/getallmyauthorizationsrequests', true, true));
            if (res?.responseCode === '0000') {
                setRequestCheckerData(res?.payLoad);
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ''));
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">
                    My Request {'('} Checker {')'}
                </h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const header = renderHeader();

    // const EditRequestChecker = (rowData) => {
    //     if (rowData?.tableName === 'TBL_COMMISSION_PROFILE') {
    //         navigate(
    //             `${rowData?.commFFEditDetailUrl || rowData?.commPFEditDetailUrl || rowData?.commSFEditDetailUrl || rowData?.commSPEditDetailUrl || rowData?.commSHEditDetailUrl}?refTableId=${rowData?.refTableId}&requestType=${rowData?.requestType
    //             }&mcRequestId=${rowData?.mcRequestId}&mcPendingRequest=${rowData?.mcPendingRequest}&requestTypeDescr=${rowData?.requestTypeDescr}&tableName=${rowData?.tableName}`
    //         );
    //     } else {
    //         navigate(
    //             `${rowData?.editDetailUrl}?refTableId=${rowData?.refTableId}&requestType=${rowData?.requestType}&mcRequestId=${rowData?.mcRequestId}&mcPendingRequest=${rowData?.mcPendingRequest}&requestTypeDescr=${rowData?.requestTypeDescr}&tableName=${rowData?.tableName}`
    //         );
    //     }
    // };

    const EditRequestChecker = (rowData) => {
        if (rowData?.tableName === 'TBL_COMMISSION_PROFILE') {
            navigate(
                `${rowData?.commFFEditDetailUrl || rowData?.commPFEditDetailUrl || rowData?.commSFEditDetailUrl || rowData?.commSPEditDetailUrl || rowData?.commSHEditDetailUrl}?refTableId=${rowData?.refTableId}&requestType=${
                    rowData?.requestType
                }&mcRequestId=${rowData?.mcRequestId}&mcPendingRequest=${rowData?.mcPendingRequest}&requestTypeDescr=${rowData?.requestTypeDescr}&tableName=${rowData?.tableName}`
            );
        } else if (rowData?.tableName === 'TBL_PRICING_PROFILE') {
            navigate(
                `${rowData?.pricFFEditDetailUrl || rowData?.pricPFEditDetailUrl || rowData?.pricSFEditDetailUrl || rowData?.pricSPEditDetailUrl || rowData?.pricCFEditDetailUrl || rowData?.pricSHEditDetailUrl}?refTableId=${
                    rowData?.refTableId
                }&requestType=${rowData?.requestType}&mcRequestId=${rowData?.mcRequestId}&mcPendingRequest=${rowData?.mcPendingRequest}&requestTypeDescr=${rowData?.requestTypeDescr}&tableName=${rowData?.tableName}`
            );
        } else if (rowData?.tableName === 'TBL_PRICING_PROFILE') {
            navigate(
                `${rowData?.pricFFEditDetailUrl || rowData?.pricPFEditDetailUrl || rowData?.pricSFEditDetailUrl || rowData?.pricSPEditDetailUrl || rowData?.pricCFEditDetailUrl || rowData?.pricSHEditDetailUrl}?refTableId=${
                    rowData?.refTableId
                }&requestType=${rowData?.requestType}&mcRequestId=${rowData?.mcRequestId}&mcPendingRequest=${rowData?.mcPendingRequest}&requestTypeDescr=${rowData?.requestTypeDescr}&tableName=${rowData?.tableName}`
            );
        } else {
            navigate(
                `${rowData?.editDetailUrl}?refTableId=${rowData?.refTableId}&requestType=${rowData?.requestType}&mcRequestId=${rowData?.mcRequestId}&mcPendingRequest=${rowData?.mcPendingRequest}&requestTypeDescr=${rowData?.requestTypeDescr}&tableName=${rowData?.tableName}`
            );
        }
    };

    const handleSwitchChange = (id) => {
        const updatedItems = [...items];
        const itemIndex = updatedItems.findIndex((item) => item.id === id);
        if (itemIndex !== -1) {
            updatedItems[itemIndex].enabled = !updatedItems[itemIndex].enabled;
            setItems(updatedItems);
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <InputSwitch checked={rowData.enabled} disabled={rowData.status === 'Pending' ? true : false} onChange={() => handleSwitchChange(rowData.id)} />
                    <Button
                        tooltip="Edit"
                        icon="pi pi-pencil"
                        disabled={rowData?.actionTaken === 'Y' || (rowData?.actionTaken === 'N' && rowData?.seq === 0) ? true : false}
                        tooltipOptions={{ position: 'top' }}
                        onClick={() => EditRequestChecker(rowData)}
                        className="p-button-rounded p-button-primary"
                    />

                    <Button
                        tooltip="View Details"
                        tooltipOptions={{ position: 'top' }}
                        icon="pi pi-eye"
                        onClick={() => {
                            if (rowData?.tableName === 'TBL_COMMISSION_PROFILE') {
                                navigate(
                                    `${rowData?.commPFViewDetailUrl || rowData?.commSFViewDetailUrl || rowData?.commSPViewDetailUrl || rowData?.commSHViewDetailUrl || rowData?.commFFViewDetailUrl}?refTableId=${rowData?.refTableId}&requestType=${
                                        rowData?.requestType
                                    }&mcRequestId=${rowData?.mcRequestId}&mcPendingRequest=${rowData?.mcPendingRequest}&requestTypeDescr=${rowData?.requestTypeDescr}&tableName=${rowData?.tableName}&checkerComments=${rowData?.checkerComments}`
                                );
                            } else if (rowData?.tableName === 'TBL_PRICING_PROFILE') {
                                navigate(
                                    `${rowData?.pricFFViewDetailUrl || rowData?.pricPFViewDetailUrl || rowData?.pricSFViewDetailUrl || rowData?.pricSPViewDetailUrl || rowData?.pricCFViewDetailUrl || rowData?.pricSHViewDetailUrl}?refTableId=${
                                        rowData?.refTableId
                                    }&requestType=${rowData?.requestType}&mcRequestId=${rowData?.mcRequestId}&mcPendingRequest=${rowData?.mcPendingRequest}&requestTypeDescr=${rowData?.requestTypeDescr}&tableName=${
                                        rowData?.tableName
                                    }&checkerComments=${rowData?.checkerComments}`
                                );
                            } else {
                                navigate(
                                    `${rowData?.viewDetailUrl}?refTableId=${rowData?.refTableId}&requestType=${rowData?.requestType}&mcRequestId=${rowData?.mcRequestId}&mcPendingRequest=${rowData?.mcPendingRequest}&checkerComments=${rowData?.checkerComments}&requestTypeDescr=${rowData?.requestTypeDescr}&tableName=${rowData?.tableName}`
                                );
                            }
                        }}
                        className="p-button-rounded"
                        style={{ backgroundColor: '#F2AC57', border: 'none' }}
                    />
                </div>
            </>
        );
    };

    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
        setRequestCheckerData('');
    };

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.makeDate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.statusDescr === 'Approved' ? '#14A38B' : rowData?.statusDescr === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.statusDescr}</b>
            </p>
        );
    };

    function getCurrentDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    useEffect(() => {
        if (formik.values.actionId || formik.values.formName || formik.values.fromDate || formik.values.toDate || formik.values.statusId || formik.values.createdBy || formik.values.updatedBy) {
            setSearchBtn(false);
        } else {
            setSearchBtn(true);
        }
    }, [formik.values.actionId, formik.values.formName, formik.values.fromDate, formik.values.toDate, formik.values.statusId, formik.values.createdBy, formik.values.updatedBy]); // eslint-disable-line

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="actionId" className={classNames({ 'p-error': isFormFieldValid('actionId') }, 'Label__Text')}>
                                    Action ID
                                </label>
                                <InputText
                                    id="actionId"
                                    name="actionId"
                                    placeholder="Enter Action ID"
                                    value={formik?.values?.actionId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('actionId') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('actionId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="formName" className={classNames({ 'p-error': isFormFieldValid('formName') }, 'Label__Text')}>
                                    Request Type
                                </label>
                                <Dropdown
                                    filter
                                    id="formName"
                                    placeholder="Select Request Type"
                                    options={requestTypeData}
                                    optionLabel="name"
                                    name="formName"
                                    optionValue="name"
                                    value={formik.values.formName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('formName') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('formName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="fromDate" className={classNames({ 'p-error': isFormFieldValid('fromDate') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText
                                    id="fromDate"
                                    type="date"
                                    name="fromDate"
                                    max={getCurrentDate()}
                                    value={formik?.values?.fromDate}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('fromDate') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('fromDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="toDate" className={classNames({ 'p-error': isFormFieldValid('toDate') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText
                                    id="toDate"
                                    type="date"
                                    name="toDate"
                                    max={getCurrentDate()}
                                    value={formik?.values?.toDate}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('toDate') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('toDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="statusId" className={classNames({ 'p-error': isFormFieldValid('statusId') }, 'Label__Text')}>
                                    Filter
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select Filter"
                                    options={statusData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLovData}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Updated By"
                                    options={userLovData}
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label="Search" icon={loadingIcon || ''} iconPos="right" className="Btn__Dark" disabled={searchBtn} />
                        <Button disabled={loading} label="Reset" iconPos="right" className="Btn__Dark" onClick={(e) => handleReset(e)} />
                    </div>
                </form>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            filters={filters}
                            globalFilterFields={['actionId', 'formName', 'userName', 'makeDate', 'statusDescr']}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={requestCheckerData}
                        >
                            <Column field="actionId" header="Action ID" />
                            <Column field="formName" header="Request Type" />
                            <Column field="requestTypeDescr" header="Request Type Description" />
                            <Column field="userName" header="Initiated By" />
                            <Column header="Created Date & Time" body={createdDateAndTimeBodyTemplate} />
                            <Column header="Status" body={statusBodyTemplate} />
                            <Column header="Actions" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
export default RequestChecker;

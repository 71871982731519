import React from 'react';
import { Button } from 'primereact/button'; // Import Button from your UI library
 
// Search button component
const SearchButton = () => (
<Button label="Search" icon={'pi pi-search' || ''} iconPos="left" className='Btn__Dark'/>
);
 
// Reset button component
const ResetButton = ({ onClick }) => (
<Button label="Reset" onClick={onClick} className={'Btn__Transparent' || ''}/>
);
 
export { SearchButton, ResetButton };
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { handleGetRequest } from '../../../services/GetTemplate';
import { MultiSelect } from 'primereact/multiselect';

const PointFactorDialog = ({ onHidePointFactor, editable, pointsFactorRowData }) => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-save');

    const [accountTypeData, setAccountTypeData] = useState([]);
    const [segmentData, setSegmentData] = useState([]);
    const [transactionTypeData, setTransactionTypeData] = useState([]);

    useEffect(() => {
        formik.setFieldValue('pointsFactor', pointsFactorRowData?.pointsFactor?.toString());
        formik.setFieldValue('pointsFactorName', pointsFactorRowData?.pointsFactorName?.toString());
        formik.setFieldValue('accType', pointsFactorRowData?.accountLevelId);

        formik.setFieldValue('dateFrom', pointsFactorRowData?.dateFrom?.slice(0, 10));
        formik.setFieldValue('dateTo', pointsFactorRowData?.dateTo?.slice(0, 10));
        formik.setFieldValue('expiry', pointsFactorRowData?.expiry?.toString());

        const transTypeData = pointsFactorRowData?.productId?.map((value) => {
            return value?.toString();
        });
        const segmentData = pointsFactorRowData?.segmentId?.map((value) => {
            return value?.toString();
        });

        formik.setFieldValue('transType', transTypeData);
        formik.setFieldValue('segmentId', segmentData);
    }, [pointsFactorRowData]); // eslint-disable-line

    useEffect(() => {
        if (!editable) {
            formik.resetForm();
        }
    }, [editable]); // eslint-disable-line

    const getAccountTypeData = async () => {
        const res = await handleGetRequest('/configuration/v1/accountype/getAllAccounttypes');
        setAccountTypeData(res?.payLoad);
    };

    const getSegmentData = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getsegments/LKP_SEGMENT:*`);
        setSegmentData(res?.payLoad);
    };
    const getTransactionTypeData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setTransactionTypeData(res?.payLoad);
    };

    useEffect(() => {
        getAccountTypeData();
        getSegmentData();
        getTransactionTypeData();
    }, []); // eslint-disable-line

    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        pointsFactorName: Yup.string().required('This field is required.').nullable(),
        // pointsFactor: Yup.string().required('This field is required.').matches('^[0-9]*$', 'Please enter numeric data').nullable(),
        // pointsFactor: Yup.string()
        //     .required('This field is required.')
        //     .matches(/^[0-9]*$/, 'Please enter numeric data')
        //     .test('is-greater-than-zero', 'Please enter a value greater than 0', (value) => value > 0)
        //     .nullable(),
        pointsFactor: Yup.number().required('This field is required.').typeError('Please enter numeric data').positive('Please enter a value greater than 0').nullable(),
        accType: Yup.mixed().required('This field is required.'),
        dateFrom: Yup.date().required('This field is required.'),
        dateTo: Yup.date().min(Yup.ref('dateFrom'), 'Date To has to be more than Date From'),
        expiry: Yup.number().required('This field is required.').max(365, 'Value cannot exceed 365').nullable()
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            pointsFactor: '',
            pointsFactorName: '',
            accType: '',
            segmentId: [],
            transType: [],
            dateFrom: '',
            dateTo: '',
            expiry: '',
            isActive: false,
            statusId: 0
        },
        validate: (data) => {
            let errors = {};

            if (data.transType?.length === 0 || ['', null, undefined].includes(data.transType)) {
                errors.transType = 'Transaction Type is required.';
            }

            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['expiry'] = parseInt(data?.expiry);
            data['pointsFactor'] = parseInt(data?.pointsFactor);
            data['accType'] = parseInt(data?.accType);

            if (editable) {
                data['zmilesPointsFactorId'] = pointsFactorRowData?.zmilesPointsFactorId;
            }

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            if (editable) {
                const res = await dispatch(handlePostRequest(newData, `/zconfig/v1/zmiles/updatePointsFactor`, true, false));
                if (res?.responseCode === '330000') {
                    formik.resetForm();
                    onHidePointFactor();
                }
            } else {
                const res = await dispatch(handlePostRequest(newData, `/zconfig/v1/zmiles/savePointsFactor`, true, false));
                if (res?.responseCode === '330000') {
                    formik.resetForm();
                    onHidePointFactor();
                }
            }
            setloading(false);
            setloadingIcon('pi pi-save');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const panelFooterSegmentTemplate = () => {
        const length = formik.values.segmentId ? formik.values.segmentId.length : 0;

        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    const panelFooterTransTypeTemplate = () => {
        const length = formik.values.transType ? formik.values.transType.length : 0;

        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="pointsFactorName" className={classNames({ 'p-error': isFormFieldValid('pointsFactorName') }, 'Label__Text')}>
                                    Point Factor Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={25}
                                    id="pointsFactorName"
                                    placeholder="Enter Point Factor"
                                    name="pointsFactorName"
                                    value={formik?.values?.pointsFactorName}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pointsFactorName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('pointsFactorName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="pointsFactor" className={classNames({ 'p-error': isFormFieldValid('pointsFactor') }, 'Label__Text')}>
                                    Point Factor<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={9}
                                    id="pointsFactor"
                                    placeholder="Enter Point Factor"
                                    name="pointsFactor"
                                    value={formik?.values?.pointsFactor}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pointsFactor') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('pointsFactor')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accType" className={classNames({ 'p-error': isFormFieldValid('accType') }, 'Label__Text')}>
                                    Customer Account Type <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="accType"
                                    placeholder="Select Account Type"
                                    options={accountTypeData}
                                    optionLabel="accountLevelName"
                                    name="accType"
                                    filter
                                    optionValue="accountLevelId"
                                    value={formik?.values?.accType}
                                    onChange={formik?.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accType') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('accType')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="segmentId" className={classNames({ 'p-error': isFormFieldValid('segmentId') }, 'Label__Text')}>
                                    Segment
                                </label>
                                <MultiSelect
                                    id="segmentId"
                                    placeholder="Select Segment"
                                    options={segmentData}
                                    optionLabel="name"
                                    name="segmentId"
                                    filter
                                    panelFooterTemplate={panelFooterSegmentTemplate}
                                    optionValue="lovId"
                                    value={formik?.values?.segmentId}
                                    onChange={formik?.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('segmentId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('segmentId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transType" className={classNames({ 'p-error': isFormFieldValid('transType') }, 'Label__Text')}>
                                    Transaction Type<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="transType"
                                    placeholder="Select Transaction Type"
                                    options={transactionTypeData}
                                    optionLabel="name"
                                    name="transType"
                                    filter
                                    panelFooterTemplate={panelFooterTransTypeTemplate}
                                    optionValue="lovId"
                                    value={formik.values.transType}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('transType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From<span className="Label__Required">*</span>
                                </label>
                                <InputText id="dateFrom" type="date" name="dateFrom" value={formik?.values?.dateFrom} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')} />

                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText id="dateTo" type="date" name="dateTo" value={formik?.values?.dateTo} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')} />

                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expiry" className={classNames({ 'p-error': isFormFieldValid('expiry') }, 'Label__Text')}>
                                    Expiry<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={3}
                                    id="expiry"
                                    placeholder="Enter Expiry"
                                    name="expiry"
                                    value={formik?.values?.expiry}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expiry') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('expiry')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button disabled={loading} type="submit" label={editable ? 'Update' : 'Add'} className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
};

export default PointFactorDialog;

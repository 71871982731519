import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import CardInstallmentPlanOldNewValues from './CardInstallmentPlanOldNewValues';
import { Sidebar } from 'primereact/sidebar';

function EditCardInstallmentPlanChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [statusLovData, setStatusLovData] = useState([]);
    const [dataById, setDataById] = useState([]);
    const [dataByMcRequestId, setDataByMcRequestId] = useState([]);
    const [feeType, setFeeType] = useState([]);
    const [dataId, setDataId] = useState('');
    const [payLoadSet, setPayLoadSet] = useState([]);

    const [oldNewData, setOldNewData] = useState([]);
    const [highlightedKeys, setHighlightedKeys] = useState([]);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [cardInstallmentPlanRowData, setCardInstallmentPlanRowData] = useState();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Getting params from URL using hash router

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const validationSchema = Yup.object({
        action: Yup.string().required('This Field is required.'),
        checkerComments: Yup.string().required('This Field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            installmentPlanName: '',
            feeType: '',
            installmentPlan: '',
            noOfInstallment: '',
            action: '',
            checkerComments: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        tblDcInstallmentPlanRequest: {
                            ...payLoadSet
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: formik?.values?.checkerComments,
                            action: formik?.values?.action,
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(newData, '/debitcardmanagement/v1/cards/cardinstallmentplancheckeraction', true, false, 'requestchecker'));
            // if (res?.responseCode === '010000') {
            //     setRegionData(res?.payLoad);
            // }
            setloading(false);
            setloadingIcon('pi pi-search');

            // dataTableRef.current.reset();
        }
    });

    const getDatabyId = async () => {
        // getRoleWiseModule
        const resp = await handleGetRequest(`/debitcardmanagement/v1/cards/getinstallmentplanbyid/${refTableId}`);
        const keyData = resp?.payLoad;
        setDataById(keyData);
        const newPayLoad = {
            dcInstallmentPlanId: resp?.payLoad?.dcInstallmentPlanId,
            installmentPlan: resp?.payLoad?.installmentPlan,
            installmentPlanName: resp?.payLoad?.installmentPlanName,
            noOfInstallment: resp?.payLoad?.noOfInstallment,
            lkpDcFeeTypeId: resp?.payLoad?.lkpDcFeeType?.dcFeeTypeId
        };
        setPayLoadSet(newPayLoad);
        setDataId(keyData?.dcInstallmentPlanId);
        formik.setFieldValue('installmentPlanName', keyData?.installmentPlanName);
        formik.setFieldValue('feeType', keyData?.lkpDcFeeType?.dcFeeTypeId?.toString());
        formik.setFieldValue('installmentPlan', keyData?.installmentPlan);
        formik.setFieldValue('noOfInstallment', keyData?.noOfInstallment);
    };

    const getMcRequestDataById = async () => {
        const resp = await handleGetRequest(`/debitcardmanagement/v1/cards/getmcrequestbyid/${mcRequestId}`);
        const keyData = resp?.payLoad;
        setPayLoadSet(resp?.payLoad);
        setDataByMcRequestId(keyData);
        setDataId(keyData?.dcInstallmentPlanId);
        formik.setFieldValue('installmentPlanName', keyData?.installmentPlanName);
        formik.setFieldValue('feeType', keyData?.lkpDcFeeTypeId?.toString());
        formik.setFieldValue('installmentPlan', keyData?.installmentPlan);
        formik.setFieldValue('noOfInstallment', keyData?.noOfInstallment);
    };

    const getInstallmentPlanData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getinstallmentplandata');
        const feeTypeList = res?.payLoad?.feeType?.map((item) => {
            // save only those whose isActive is Y
            if (item.isActive === 'Y') {
                return item;
            }
        });
        setFeeType(feeTypeList);
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        getStatusLov();
        getInstallmentPlanData();
    }, []);

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, [requestType]);

    const getOldNewData = async () => {
        const resp = await handleGetRequest(`/workflow/v1/management/compareValues/${mcRequestId}`);
        setOldNewData(resp?.payLoad);
    };

    useEffect(() => {
        if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getOldNewData();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        const keys = oldNewData?.map((item) => item.key);
        setHighlightedKeys(keys);
    }, [oldNewData]); // eslint-disable-line

    const HandledOldValue = async (e) => {
        e.preventDefault();
        setDisplayDialog(true);
        const resp = await handleGetRequest(`/debitcardmanagement/v1/cards/getinstallmentplanbyid/${refTableId}`);
        setCardInstallmentPlanRowData(resp?.payLoad);
    };

    return (
        <>
            <Sidebar header="View Card Installment Plan" position="right" style={{ width: '70vw' }} visible={displayDialog} onHide={() => setDisplayDialog(false)}>
                <CardInstallmentPlanOldNewValues cardInstallmentPlanRowData={cardInstallmentPlanRowData} />
            </Sidebar>
            <div className="card Card__Round">
                <div className="Form__Header Full__Width">
                    <h1>EDIT DETAIL</h1>
                    {requestType === 'U' && <Button label="View Old Details" className="Btn__Dark__OldValue" onClick={HandledOldValue} />}
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="installmentPlanName" className={classNames({ 'p-error': isFormFieldValid('installmentPlanName') }, 'Label__Text')}>
                                    Installment Name
                                </label>
                                <InputText
                                    maxLength={50}
                                    minLength={3}
                                    id="installmentPlanName"
                                    disabled
                                    name="installmentPlanName"
                                    value={formik?.values?.installmentPlanName}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('installmentPlanName')
                                            ? classNames({ 'p-invalid': isFormFieldValid('installmentPlanName') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('installmentPlanName') }, 'Input__Round')
                                    }
                                />
                                {getFormErrorMessage('installmentPlanName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="feeType" className={classNames({ 'p-error': isFormFieldValid('feeType') }, 'Label__Text')}>
                                    Fee Type
                                </label>
                                <Dropdown
                                    id="feeType"
                                    disabled
                                    options={feeType}
                                    optionLabel="name"
                                    name="feeType"
                                    optionValue="lovId"
                                    value={formik.values.feeType || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('feeType') ? classNames({ 'p-invalid': isFormFieldValid('feeType') }, 'Dropdown__Round__Highlighted') : classNames({ 'p-invalid': isFormFieldValid('feeType') }, 'Dropdown__Round')
                                    }
                                />
                                {getFormErrorMessage('feeType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="installmentPlan" className={classNames({ 'p-error': isFormFieldValid('installmentPlan') }, 'Label__Text')}>
                                    Installment Plan
                                </label>
                                <Dropdown
                                    id="installmentPlan"
                                    disabled
                                    options={[
                                        { name: 'Weekly', lovId: 'W' },
                                        { name: 'Monthly', lovId: 'M' },
                                        { name: 'Yearly', lovId: 'Y' }
                                    ]}
                                    optionLabel="name"
                                    name="installmentPlan"
                                    optionValue="lovId"
                                    value={formik.values.installmentPlan || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('installmentPlan')
                                            ? classNames({ 'p-invalid': isFormFieldValid('installmentPlan') }, 'Dropdown__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('installmentPlan') }, 'Dropdown__Round')
                                    }
                                />
                                {getFormErrorMessage('installmentPlan')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="noOfInstallment" className={classNames({ 'p-error': isFormFieldValid('noOfInstallment') }, 'Label__Text')}>
                                    No. of Installment
                                </label>
                                <InputText
                                    maxLength={50}
                                    minLength={3}
                                    id="noOfInstallment"
                                    disabled
                                    name="noOfInstallment"
                                    value={formik?.values?.noOfInstallment}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('noOfInstallment')
                                            ? classNames({ 'p-invalid': isFormFieldValid('noOfInstallment') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('noOfInstallment') }, 'Input__Round')
                                    }
                                />
                                {getFormErrorMessage('noOfInstallment')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    maxLength={500}
                                    rows={5}
                                    cols={30}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} label="Submit" type="submit" className="Btn__Dark" />
                        <Button onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default EditCardInstallmentPlanChecker;

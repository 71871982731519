import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';

const RegistrationChannel = ({ registerationChannelData }) => {
    const [chartData, setChartData] = useState(null);
    const [lightOptions] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            r: {
                grid: {
                    color: '#ebedef'
                }
            }
        }
    });

    useEffect(() => {
        const countArr =
            registerationChannelData &&
            registerationChannelData.map((item) => {
                return item?.parameterValue;
            });
        const labelArr =
            registerationChannelData &&
            registerationChannelData.map((item) => {
                return item?.parameterName;
            });

        const data = {
            labels: labelArr,
            datasets: [
                {
                    data: countArr,
                    backgroundColor: ['#f8b242', '#4f6585', '#49e4c5', '#b8d84b', '#12aec8'],
                    hoverBackgroundColor: ['#f8b242', '#4f6585', '#49e4c5', '#b8d84b', '#12aec8']
                }
            ]
        };

        setChartData(data);
    }, [registerationChannelData]);
    return (
        <div className="col-12 md:col-4">
            <div className="card Card__Round">
                <div className="card p-d-flex p-jc-center">
                    <h5>Registration Channel</h5>
                    <Chart type="polarArea" data={chartData} options={lightOptions} className="w-full" style={{ height: '35rem' }} />
                </div>
            </div>
        </div>
    );
};

export default RegistrationChannel;

import { handlePostRequest } from "../../services/PostTemplate";


export const handleRequest=async (payload,requestType,apiUrl,additionalInformation, dispatch,modalResult,popUpResult)=>{
       if(requestType=='POST'){
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: payload,

                additionalInformation: additionalInformation,
                checkSum: ''
            }


        };
        
        const res = await dispatch(handlePostRequest(newData, apiUrl,modalResult,popUpResult));
        return res
   
   }
   
}



import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';
import { PaginationCall, PaginationTemplate } from '../../../components/Pagination';
import { Paginator } from 'primereact/paginator';
import PointRedemptionDialog from './PointRedemptionDialog';
import PointRedemptionDialogView from './PointRedemptionDialogView';
import { InputSwitch } from 'primereact/inputswitch';
import ActiveInActiveDialog from '../../../components/ActiveInActiveDialog/ActiveInActiveDialog';

function PointRedemption() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [globalFilter, setGlobalFilter] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [showDialogView, setShowDialogView] = useState(false);
    const [pointRedemptionData, setPointRedemptionData] = useState([]);

    const [editable, setEditable] = useState(false);
    const [pointRedemptionRowData, setPointRedemptionRowData] = useState({});
    const [statusLovData, setStatusLovData] = useState([]);
    const [userLov, setUserLov] = useState([]);

    const [customFirst1, setCustomFirst1] = useState(0);
    const [customRows1, setCustomRows1] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [render, setRender] = useState(false);

    const [pointRedemptionIdData, setPointRedemptionIdData] = useState();
    const [isActiveData, setIsActiveData] = useState();

    const [showModal, setShowModal] = useState(false);

    const dataTableRef = useRef(null);

    const dispatch = useDispatch();

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(res?.payLoad);
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };

    useEffect(() => {
        getStatusListData();
        getUserLov();
    }, []); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        }),
        expiry: Yup.number().max(365, 'Value cannot exceed 365').nullable()
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            voucherName: '',
            voucherValue: '',
            pointValue: '',
            createdBy: '',
            updatedBy: '',
            dateFrom: '',
            dateTo: '',
            status: '2',
            expiry: '',
            rowStart: customFirst1,
            rowEnd: customRows1 + customFirst1
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/zconfig/v1/zmiles/voucherSearch', true, true));
            if (res?.responseCode === '330000') {
                formik.resetForm();
                setPointRedemptionData(res?.payLoad);
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handledClicked = async (e) => {
        e.preventDefault();
        setShowDialog(true);
        setEditable(false);
    };

    const header = (
        <div className="Global__Header">
            <h5 className="m-0">Existing Details</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const onHidePointRedemption = () => {
        setShowDialog(false);
        setEditable(false);
    };

    const onHidePointRedemptionView = () => {
        setShowDialogView(false);
    };

    const EditPointFactorHandled = (rowData) => {
        setShowDialog(true);
        setEditable(true);
        setPointRedemptionRowData(rowData);
    };

    const handlePointFactorView = (rowData) => {
        setShowDialogView(true);
        setPointRedemptionRowData(rowData);
    };

    const handleSwitchChange = async () => {
        const updatedData = pointRedemptionData.map((item) => {
            if (item.zmilesVoucherId === pointRedemptionIdData) {
                const isActive = item.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setPointRedemptionData(updatedData);
        let isactive = '';
        if (isActiveData === 'Y') {
            isactive = 'N';
        } else if (isActiveData === 'N') {
            isactive = 'Y';
        }
        const previousValue = isActiveData;

        const data = {
            isActive: isactive,
            zmilesVoucherId: pointRedemptionIdData,
            voucherName: pointRedemptionRowData?.voucherName,
            voucherValue: pointRedemptionRowData?.voucherValue,
            pointValue: pointRedemptionRowData?.pointValue,
            transType: pointRedemptionRowData?.productId,
            expiry: pointRedemptionRowData?.expiry,
            statusId: 0
        };
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/zconfig/v1/zmiles/inactiveVoucher', true, false));
        if (res?.responseCode !== '020000') {
            const revertedData = pointRedemptionData.map((item) => {
                if (item.zmilesVoucherId === pointRedemptionIdData) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setPointRedemptionData(revertedData);
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <InputSwitch
                    checked={rowData.isActive === 'Y'}
                    disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                    onChange={() => {
                        setShowModal(true);
                        setPointRedemptionIdData(rowData?.zmilesVoucherId);
                        setIsActiveData(rowData?.isActive);
                        setPointRedemptionRowData(rowData);
                    }}
                />
                <Button
                    icon="pi pi-pencil"
                    tooltip="Edit"
                    onClick={() => EditPointFactorHandled(rowData)}
                    tooltipOptions={{ position: 'top' }}
                    className="p-button-rounded mr-2"
                    disabled={['Pending', 'Rejected'].includes(rowData?.status) || (rowData?.status === 'Approved' && rowData?.isActive === 'N') ? true : false}
                />
                <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => handlePointFactorView(rowData)} className="p-button-rounded" style={{ backgroundColor: '#F2AC57', border: 'none' }} />
            </div>
        );
    };

    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createDate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const onCustomPageChange1 = (event) => {
        setCustomFirst1(event.first);
        setCustomRows1(event.rows);
        setCurrentPage(event.page + 1);
        setRender(true);
    };

    const RequestData = {
        voucherName: formik.values.voucherName,
        voucherValue: formik.values.voucherValue,
        pointValue: formik.values.pointValue,
        dateFrom: formik.values.dateFrom,
        dateTo: formik.values.dateTo,
        status: formik.values.status,
        createdBy: formik.values.createdBy,
        updatedBy: formik.values.updatedBy,
        rowStart: customFirst1,
        rowEnd: customRows1 + customFirst1
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const handleShowModal = () => {
        setShowModal(false);
    };

    const props = {
        showModal,
        handleShowModal,
        handleSwitchChange
    };

    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.status === 'Approved' ? '#14A38B' : rowData?.status === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.status}</b>
            </p>
        );
    };

    return (
        <>
            <ActiveInActiveDialog {...props} />

            {/* Add/Update Point Redemption Dialog */}
            <Dialog draggable={false} resizable={false} visible={showDialog} header={editable ? 'Update Points Redemption' : 'Add Points Redemption'} style={{ width: '850px' }} onHide={() => setShowDialog(false)}>
                <PointRedemptionDialog onHidePointRedemption={onHidePointRedemption} editable={editable} pointRedemptionRowData={pointRedemptionRowData} />
            </Dialog>
            {/* View Point Redemption Dialog */}
            <Dialog draggable={false} resizable={false} visible={showDialogView} header="View Details" style={{ width: '850px' }} onHide={() => setShowDialogView(false)}>
                <PointRedemptionDialogView onHidePointRedemptionView={onHidePointRedemptionView} pointRedemptionRowData={pointRedemptionRowData} />
            </Dialog>

            <PaginationCall
                render={render}
                customFirst1={customFirst1}
                customRows1={customRows1}
                currentPage={currentPage}
                dataTableRef={dataTableRef}
                setData={setPointRedemptionData}
                RequestData={RequestData}
                url={'/zconfig/v1/zmiles/voucherSearch'}
                responseCode={'330000'}
            />

            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={handledClicked} className="Btn__Add__" icon="pi pi-plus" iconPos="left" style={{ width: 'auto' }} />
                </div>
            </div>

            {/* Point Redemption Form */}
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="voucherName" className={classNames({ 'p-error': isFormFieldValid('voucherName') }, 'Label__Text')}>
                                    Voucher Name
                                </label>
                                <InputText
                                    maxLength={25}
                                    id="voucherName"
                                    placeholder="Enter Name"
                                    name="voucherName"
                                    value={formik?.values?.voucherName?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('voucherName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('voucherName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="voucherValue" className={classNames({ 'p-error': isFormFieldValid('voucherValue') }, 'Label__Text')}>
                                    Voucher Value
                                </label>
                                <InputText
                                    maxLength={25}
                                    id="voucherValue"
                                    placeholder="Enter Voucher Value"
                                    name="voucherValue"
                                    value={formik?.values?.voucherValue?.trim()?.toString()}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('voucherValue') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('voucherValue')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="pointValue" className={classNames({ 'p-error': isFormFieldValid('pointValue') }, 'Label__Text')}>
                                    Point Value
                                </label>
                                <InputText
                                    maxLength={25}
                                    id="pointValue"
                                    placeholder="Enter Point Value"
                                    name="pointValue"
                                    value={formik?.values?.pointValue?.trim()?.toString()}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pointValue') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('pointValue')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText
                                    max={previousDate}
                                    id="dateFrom"
                                    type="date"
                                    name="dateFrom"
                                    value={formik?.values?.dateFrom}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText max={previousDate} id="dateTo" type="date" name="dateTo" value={formik?.values?.dateTo} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')} />

                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Updated By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('status') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="status"
                                    placeholder="Select Status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="status"
                                    optionValue="lovId"
                                    value={formik.values.status || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('status') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('status')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expiry" className={classNames({ 'p-error': isFormFieldValid('expiry') }, 'Label__Text')}>
                                    Expiry
                                </label>
                                <InputText
                                    maxLength={3}
                                    id="expiry"
                                    placeholder="Enter Expiry"
                                    name="expiry"
                                    value={formik?.values?.expiry}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expiry') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('expiry')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button label="Search" className="Btn__Dark" type="submit" />
                        <Button disabled={loading} onClick={(e) => handleReset(e)} label="Reset" className=" Btn__Transparent" />
                    </div>
                </form>
            </div>

            {/* Poimt Factor DataTable */}

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable globalFilter={globalFilter} emptyMessage="No List found." header={header} responsiveLayout="scroll" value={pointRedemptionData} ref={dataTableRef}>
                            <Column field="voucherName" header="Voucher Name" />
                            <Column field="voucherValue" header="Voucher Value" />
                            <Column field="pointValue" header="Point Value" />
                            <Column field="expiry" header="Expiry" />
                            <Column field="createdBy" header="Created By" />
                            <Column field="updatedBy" header="Updated By" />
                            <Column body={createdDateAndTimeBodyTemplate} header="Created Date & Time" />
                            <Column header="Status" body={statusBodyTemplate} />
                            <Column header="Action" body={actionBodyTemplate} />
                        </DataTable>
                        <Paginator template={PaginationTemplate(false)} first={customFirst1} rows={customRows1} totalRecords={pointRedemptionData?.[0]?.totalCount} onPageChange={onCustomPageChange1}></Paginator>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PointRedemption;

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { useLocation } from 'react-router-dom';
import ViewFranchise from '../Components/ViewFranchise';
import ViewRetailer from '../Components/ViewRetailer';
import ViewHandler from '../Components/ViewHandler';
import ViewCorporate from '../Components/ViewCorporate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { useDispatch } from 'react-redux';
function Checkeragentonboardingedit() {
    let { search } = useLocation();
    const dispatch = useDispatch();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');
    const [apiData, setApiData] = useState([]);
    const [apiDataDoc, setApiDataDoc] = useState([]);
    const [mcRequestData, setMcRequestData] = useState([]);
    const [newPayLoad, setNewPayLoad] = useState([]);
    const [kycView, setKycView] = useState([]);
    const [accountClassification, setAccountClassification] = useState([]);

    const formik = useFormik({
        initialValues: {
            accountclassification: ''
        }
    });

    const getDocData = async (agentId) => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    agentId: agentId
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newData, '/documents/v1/documents/getagentdocuments', true, true));
        if (res?.payLoad) {
            setApiDataDoc(res?.payLoad);
        }
    };

    const getAccountClassification = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getclientroles/LKP_ACCOUNT_CLASSIFICATION:*');
        setAccountClassification(res?.payLoad);
    };

    const getDatabyId = async () => {
        // getRoleWiseModule
        const resp = await handleGetRequest(`/account/v1/agentaccount/getcxagentbyid/${refTableId}`);
        const keyData = resp?.payLoad;
        setApiData(keyData);
        if (resp?.payLoad && (resp?.payLoad?.lkpAccountClassification?.accountClassificationCode === 'FRN' || resp?.payLoad?.lkpAccountClassification?.accountClassificationCode === 'RTL')) {
            getDocData(resp?.payLoad?.agentId);
        }
    };

    const getMcRequestDataById = async () => {
        const resp = await handleGetRequest(`/account/v1/agentaccount/getagentbymcrequestid/${mcRequestId}`);
        const keyData = resp?.payLoad;
        setMcRequestData(keyData);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U') {
            getDatabyId();
            getMcRequestDataById();
        }
    }, [requestType]);

    useEffect(() => {
        formik.setFieldValue('accountclassification', apiData?.lkpAccountClassification?.accountClassificationId.toString());
    }, [apiData]);

    useEffect(() => {
        getAccountClassification();
    }, []);

    useEffect(() => {
        if (apiData?.kycView) {
            const kyc1 = JSON?.parse(apiData?.kycView);
            const kyc2 = mcRequestData?.additionalInformations;
            const finalKyc = { ...kyc1 };
            kyc2?.forEach((item) => {
                const { infoKey, infoValue } = item;
                if (kyc1?.hasOwnProperty(infoKey)) {
                    kyc1[infoKey] = infoValue;
                }
            });

            setKycView(finalKyc);
        }
    }, [apiData, mcRequestData]);

    useEffect(() => {
        if (apiData && mcRequestData && kycView) {
            setNewPayLoad({
                ...mcRequestData,
                lkpAccountClassification: apiData?.lkpAccountClassification,
                lkpBranch: apiData?.lkpBranch,
                tblAgentChannelList: apiData?.tblAgentChannelList,
                tblCommissionProfile: apiData?.tblCommissionProfile,
                tblPricingProfile: apiData?.tblPricingProfile,
                tblSalesForce: apiData?.tblSalesForce,
                tblSalesHierarchy: apiData?.tblSalesHierarchy,
                tblTransLimit: apiData?.tblTransLimit,
                kycView: JSON.stringify(kycView),
                tblEcibResponse: apiData?.tblEcibResponse,
                createdate: apiData?.createdate,
                isFiler: apiData?.isFiler,
                crpRating: apiData?.crpRating,
                ecib: apiData?.ecib,
                crpScore: apiData?.crpScore?.toString(),
                clsVerified: apiData?.clsVerified
            });
        }
    }, [apiData, mcRequestData, kycView]);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="m-3 Form__Header Full__Width">
                            <h2>EDIT DETAIL</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountclassification" className="Label__Text">
                                    Account Classification
                                </label>
                                <Dropdown
                                    id="accountclassification"
                                    placeholder="Select Account Classification"
                                    options={accountClassification}
                                    optionLabel="name"
                                    name="accountclassification"
                                    optionValue="lovId"
                                    value={formik.values.accountclassification || ''}
                                    className="Dropdown__Round"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </form>
                {newPayLoad && (
                    <>
                        {newPayLoad?.lkpAccountClassification?.accountClassificationCode === 'FRN' ? (
                            <ViewFranchise
                                databyId={apiData}
                                apiData={newPayLoad}
                                docData={apiDataDoc}
                                isEditCase={true}
                                mcRequestData={mcRequestData}
                                mcRequestId={mcRequestId}
                                mcPeindingRequestId={mcPeindingRequestId}
                                refTableId={refTableId}
                                tableName={tableName}
                                requestType={requestType}
                                requestTypeDescr={requestTypeDescr}
                            />
                        ) : newPayLoad?.lkpAccountClassification?.accountClassificationCode === 'RTL' ? (
                            <ViewRetailer
                                databyId={apiData}
                                apiData={newPayLoad}
                                docData={apiDataDoc}
                                isEditCase={true}
                                mcRequestData={mcRequestData}
                                mcRequestId={mcRequestId}
                                mcPeindingRequestId={mcPeindingRequestId}
                                refTableId={refTableId}
                                tableName={tableName}
                                requestType={requestType}
                                requestTypeDescr={requestTypeDescr}
                            />
                        ) : newPayLoad?.lkpAccountClassification?.accountClassificationCode === 'HND' ? (
                            <ViewHandler
                                databyId={apiData}
                                apiData={newPayLoad}
                                isEditCase={true}
                                mcRequestData={mcRequestData}
                                mcRequestId={mcRequestId}
                                mcPeindingRequestId={mcPeindingRequestId}
                                refTableId={refTableId}
                                tableName={tableName}
                                requestType={requestType}
                                requestTypeDescr={requestTypeDescr}
                            />
                        ) : newPayLoad?.lkpAccountClassification?.accountClassificationCode === 'CPT' ? (
                            <ViewCorporate
                                databyId={apiData}
                                apiData={newPayLoad}
                                isEditCase={true}
                                mcRequestData={mcRequestData}
                                mcRequestId={mcRequestId}
                                mcPeindingRequestId={mcPeindingRequestId}
                                refTableId={refTableId}
                                tableName={tableName}
                                requestType={requestType}
                                requestTypeDescr={requestTypeDescr}
                            />
                        ) : null}
                    </>
                )}
            </div>
        </>
    );
}

export default Checkeragentonboardingedit;

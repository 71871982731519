import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';

const TransactionLimitValidator = () => {
    const [transactionLimitValidatorData, setTransactionLimitValidatorData] = useState();
    const [globalFilter, setGlobalFilter] = useState('');

    const navigate = useNavigate();

    const transactionLimitValidatorJson = [
        {
            accountLevelCode: 'L0',
            accountLevelName: 'LEVEL 0',
            dailyAmountLimitCr: '25000',
            dailyAmountLimitDr: '25000',
            dailyTransactionLimitCr: '',
            dailyTransactionLimitDr: '',
            maxAmountLimit: '50000',
            maxAmountPerTransaction: '25000',
            monthlyAmountLimitCr: '50000',
            monthlyAmountLimitDr: '50000',
            monthlyTransactionLimitCr: '',
            monthlyTransactionLimitDr: '',
            yearlyAmountLimitCr: '200000',
            yearlyAmountLimitDr: '200000',
            yearlyTransactionLimitCr: '',
            yearlyTransactionLimitDr: '',
            isActive: 'Y',
            accountLevelDescription: 'LEVEL 0'
        },
        {
            accountLevelCode: 'L1',
            accountLevelName: 'LEVEL 1',
            dailyAmountLimitCr: '50000',
            dailyAmountLimitDr: '50000',
            dailyTransactionLimitCr: '',
            dailyTransactionLimitDr: '',
            maxAmountLimit: '400000',
            maxAmountPerTransaction: '50000',
            monthlyAmountLimitCr: '200000',
            monthlyAmountLimitDr: '200000',
            monthlyTransactionLimitCr: '',
            monthlyTransactionLimitDr: '',
            yearlyAmountLimitCr: '2400000',
            yearlyAmountLimitDr: '2400000',
            yearlyTransactionLimitCr: '',
            yearlyTransactionLimitDr: '',
            isActive: 'Y',
            accountLevelDescription: 'LEVEL 1'
        },
        {
            accountLevelCode: 'ULB',
            accountLevelName: 'ULTRA BASIC',
            dailyAmountLimitCr: '100000',
            dailyAmountLimitDr: '100000',
            dailyTransactionLimitCr: '',
            dailyTransactionLimitDr: '',
            maxAmountLimit: '5000000',
            maxAmountPerTransaction: '100000',
            monthlyAmountLimitCr: '500000',
            monthlyAmountLimitDr: '500000',
            monthlyTransactionLimitCr: '',
            monthlyTransactionLimitDr: '',
            yearlyAmountLimitCr: '5000000',
            yearlyAmountLimitDr: '5000000',
            yearlyTransactionLimitCr: '',
            yearlyTransactionLimitDr: '',
            isActive: 'Y',
            accountLevelDescription: 'ULTRA BASIC'
        },
        {
            accountLevelCode: 'L2',
            accountLevelName: 'LEVEL 2',
            dailyAmountLimitCr: '50000',
            dailyAmountLimitDr: '50000',
            dailyTransactionLimitCr: '',
            dailyTransactionLimitDr: '',
            maxAmountLimit: '400000',
            maxAmountPerTransaction: '50000',
            monthlyAmountLimitCr: '200000',
            monthlyAmountLimitDr: '200000',
            monthlyTransactionLimitCr: '',
            monthlyTransactionLimitDr: '',
            yearlyAmountLimitCr: '2400000',
            yearlyAmountLimitDr: '2400000',
            yearlyTransactionLimitCr: '',
            yearlyTransactionLimitDr: '',
            isActive: 'Y',
            accountLevelDescription: 'LEVEL 2'
        },
        {
            accountLevelCode: 'RET',
            accountLevelName: 'RETAILER',
            dailyAmountLimitCr: '5000000',
            dailyAmountLimitDr: '5000000',
            dailyTransactionLimitCr: '',
            dailyTransactionLimitDr: '',
            maxAmountLimit: '400000',
            maxAmountPerTransaction: '50000',
            monthlyAmountLimitCr: '2000000',
            monthlyAmountLimitDr: '2000000',
            monthlyTransactionLimitCr: '',
            monthlyTransactionLimitDr: '',
            yearlyAmountLimitCr: '2400000',
            yearlyAmountLimitDr: '2400000',
            yearlyTransactionLimitCr: '',
            yearlyTransactionLimitDr: '',
            isActive: 'Y',
            accountLevelDescription: 'RETAILER'
        }
    ];

    const handledClicked = () => {
        return navigate('/addtransactionlimitvalidator');
    };

    const getTransactionLimitValidator = async () => {
        const res = await handleGetRequest('/offline_Biller/company/getAllUbpCompany');
        if (res?.responsecode === 1) {
            setTransactionLimitValidatorData(res?.data);
        }
    };

    useEffect(() => {
        getTransactionLimitValidator();
    }, []); // eslint-disable-line

    const header = (
        <div className="Global__Header">
            <h5 className="m-0">Transaction Limit Validator</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    // const handledEditClicked = (rowData) => {
    //     navigate(`/addofflinebiller?editable=true&ubpId=${rowData?.ubpCompanyId}`);
    // };

    // const actionBodyTemplate = (rowData) => {
    //     return (
    //         <div>
    //             <Button icon="pi pi-pencil" className="p-button-rounded p-button-info mr-2" onClick={() => handledEditClicked(rowData)} />
    //         </div>
    //     );
    // };

    return (
        <>
            <div className="Top__Btn">
                <div>
                    <Button label="New" onClick={handledClicked} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            globalFilter={globalFilter}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={transactionLimitValidatorJson}
                        >
                            <Column field="accountLevelCode" header="Account Level Code" />
                            <Column field="accountLevelName" header="Account Level Name" />
                            <Column field="dailyAmountLimitCr" header="Daily Amount Limit Cr" />
                            <Column field="dailyAmountLimitDr" header="Daily Amount Limit Dr" />
                            <Column field="dailyTransactionLimitCr" header="Daily Transaction Limit Cr" />
                            <Column field="dailyTransactionLimitDr" header="Daily Transaction Limit Dr" />
                            <Column field="maxAmountLimit" header="Max Amount Limit" />
                            <Column field="maxAmountPerTransaction" header="Max Amount Per Transaction" />
                            <Column field="monthlyAmountLimitCr" header="Monthly Amount Limit Cr" />
                            <Column field="monthlyAmountLimitDr" header="Monthly Amount Limit Dr" />
                            <Column field="monthlyTransactionLimitCr" header="Monthly Transaction Limit Cr" />
                            <Column field="monthlyTransactionLimitDr" header="Monthly Transaction Limit Dr" />
                            <Column field="yearlyAmountLimitCr" header="Yearly Amount Limit Cr" />
                            <Column field="yearlyAmountLimitDr" header="Yearly Amount Limit Dr" />
                            <Column field="yearlyTransactionLimitCr" header="Yearly Transaction Limit Cr" />
                            <Column field="yearlyTransactionLimitDr" header="Yearly Transaction Limit Dr" />
                            <Column field="isActive" header="Active Status" />
                            <Column field="accountLevelDescription" header="Account Level Description" />
                            {/* <Column header="Action" body={actionBodyTemplate} /> */}
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TransactionLimitValidator;

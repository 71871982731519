import * as Yup from 'yup';

export const EditValidationSchemaBasic = Yup.object().shape({
    accountTitleComment: Yup.string().required('This field is required'),
    accountTitleStatus: Yup.string().required('This field is required'),
    selfiePicComment: Yup.string().required('This field is required'),
    selfiePicStatus: Yup.string().required('This field is required'),
    cnicFrontPicComment: Yup.string().required('This field is required'),
    cnicFrontPicStatus: Yup.string().required('This field is required'),
    cnicBackPicComment: Yup.string().required('This field is required'),
    cnicBackPicStatus: Yup.string().required('This field is required'),
    fatherNameComment: Yup.string().required('This field is required'),
    fatherNameStatus: Yup.string().required('This field is required'),
    birthPlaceComment: Yup.string().required('This field is required'),
    birthPlaceStatus: Yup.string().required('This field is required'),
    motherNameComment: Yup.string().required('This field is required'),
    motherNameStatus: Yup.string().required('This field is required'),
    professionNameComment: Yup.string().required('This field is required'),
    professionNameStatus: Yup.string().required('This field is required'),
    dualNationalComment: Yup.string().required('This field is required'),
    dualNationalStatus: Yup.string().required('This field is required'),
    usBornComment: Yup.string().required('This field is required'),
    usBornStatus: Yup.string().required('This field is required'),
    countryComment: Yup.string().required('This field is required'),
    countryStatus: Yup.string().required('This field is required'),
    countryOfBirthComment: Yup.string().required('This field is required'),
    countryOfBirthStatus: Yup.string().required('This field is required'),
    countryOfTaxResidenceComment: Yup.string().required('This field is required'),
    countryOfTaxResidenceStatus: Yup.string().required('This field is required'),
    usGreenCardHolderComment: Yup.string().required('This field is required'),
    usGreenCardHolderStatus: Yup.string().required('This field is required'),
    usTelePhoneNumberComment: Yup.string().required('This field is required'),
    usTelePhoneNumberStatus: Yup.string().required('This field is required'),
    usSignAuthComment: Yup.string().required('This field is required'),
    usSignAuthStatus: Yup.string().required('This field is required'),
    usOtherInfoComment: Yup.string().required('This field is required'),
    usOtherInfoStatus: Yup.string().required('This field is required'),
    fedralTaxClassificationNameComment: Yup.string().required('This field is required'),
    fedralTaxClassificationNameStatus: Yup.string().required('This field is required'),
    residenceAddressComment: Yup.string().required('This field is required'),
    residenceAddressStatus: Yup.string().required('This field is required'),
    taxIdentificationNumberComment: Yup.string().required('This field is required'),
    taxIdentificationNumberStatus: Yup.string().required('This field is required'),
    geoLocationComment: Yup.string().required('This field is required'),
    geoLocationStatus: Yup.string().required('This field is required'),
    cnicExpiryDateComment: Yup.string().required('This field is required'),
    cnicExpiryDateStatus: Yup.string().required('This field is required'),
    signaturePicComment: Yup.string().required('This field is required'),
    signaturePicStatus: Yup.string().required('This field is required'),
    bvsVerifiedComment: Yup.string().required('This field is required'),
    bvsVerifiedStatus: Yup.string().required('This field is required'),
    emailComment: Yup.string().required('This field is required'),
    emailStatus: Yup.string().required('This field is required'),
    dateOfBirthComment: Yup.string().required('This field is required'),
    dateOfBirthStatus: Yup.string().required('This field is required'),
    customerVideoComment: Yup.string().required('This field is required'),
    customerVideoStatus: Yup.string().required('This field is required'),
    customerVideoKycComment: Yup.string().required('This field is required'),
    customerVideoKycStatus: Yup.string().required('This field is required'),
    deviceIdComment: Yup.string().required('This field is required'),
    deviceIdStatus: Yup.string().required('This field is required'),
    purposeOfAccountComment: Yup.string().required('This field is required'),
    purposeOfAccountStatus: Yup.string().required('This field is required'),
    genderComment: Yup.string().required('This field is required'),
    genderStatus: Yup.string().required('This field is required'),
    status: Yup.string().required('This field is required'),
    comments: Yup.string().required('This field is required')
});

export const EditValidationSchemaFreelance = Yup.object().shape({
    accountTitleComment: Yup.string().required('This field is required'),
    accountTitleStatus: Yup.string().required('This field is required'),
    selfiePicComment: Yup.string().required('This field is required'),
    selfiePicStatus: Yup.string().required('This field is required'),
    cnicFrontPicComment: Yup.string().required('This field is required'),
    cnicFrontPicStatus: Yup.string().required('This field is required'),
    cnicBackPicComment: Yup.string().required('This field is required'),
    cnicBackPicStatus: Yup.string().required('This field is required'),
    fatherNameComment: Yup.string().required('This field is required'),
    fatherNameStatus: Yup.string().required('This field is required'),
    birthPlaceComment: Yup.string().required('This field is required'),
    birthPlaceStatus: Yup.string().required('This field is required'),
    motherNameComment: Yup.string().required('This field is required'),
    motherNameStatus: Yup.string().required('This field is required'),
    professionNameComment: Yup.string().required('This field is required'),
    professionNameStatus: Yup.string().required('This field is required'),
    dualNationalComment: Yup.string().required('This field is required'),
    dualNationalStatus: Yup.string().required('This field is required'),
    usBornComment: Yup.string().required('This field is required'),
    usBornStatus: Yup.string().required('This field is required'),
    countryComment: Yup.string().required('This field is required'),
    countryStatus: Yup.string().required('This field is required'),
    countryOfBirthComment: Yup.string().required('This field is required'),
    countryOfBirthStatus: Yup.string().required('This field is required'),
    usGreenCardHolderComment: Yup.string().required('This field is required'),
    usGreenCardHolderStatus: Yup.string().required('This field is required'),
    usTelePhoneNumberComment: Yup.string().required('This field is required'),
    usTelePhoneNumberStatus: Yup.string().required('This field is required'),
    usSignAuthComment: Yup.string().required('This field is required'),
    usSignAuthStatus: Yup.string().required('This field is required'),
    usOtherInfoComment: Yup.string().required('This field is required'),
    usOtherInfoStatus: Yup.string().required('This field is required'),
    fedralTaxClassificationNameComment: Yup.string().required('This field is required'),
    fedralTaxClassificationNameStatus: Yup.string().required('This field is required'),
    residenceAddressComment: Yup.string().required('This field is required'),
    residenceAddressStatus: Yup.string().required('This field is required'),
    taxIdentificationNumberComment: Yup.string().required('This field is required'),
    taxIdentificationNumberStatus: Yup.string().required('This field is required'),
    geoLocationComment: Yup.string().required('This field is required'),
    geoLocationStatus: Yup.string().required('This field is required'),
    cnicExpiryDateComment: Yup.string().required('This field is required'),
    cnicExpiryDateStatus: Yup.string().required('This field is required'),
    signaturePicComment: Yup.string().required('This field is required'),
    signaturePicStatus: Yup.string().required('This field is required'),
    bvsVerifiedComment: Yup.string().required('This field is required'),
    bvsVerifiedStatus: Yup.string().required('This field is required'),
    emailComment: Yup.string().required('This field is required'),
    emailStatus: Yup.string().required('This field is required'),
    dateOfBirthComment: Yup.string().required('This field is required'),
    dateOfBirthStatus: Yup.string().required('This field is required'),
    customerVideoComment: Yup.string().required('This field is required'),
    customerVideoStatus: Yup.string().required('This field is required'),
    customerVideoKycComment: Yup.string().required('This field is required'),
    customerVideoKycStatus: Yup.string().required('This field is required'),
    deviceIdComment: Yup.string().required('This field is required'),
    deviceIdStatus: Yup.string().required('This field is required'),
    purposeOfAccountComment: Yup.string().required('This field is required'),
    purposeOfAccountStatus: Yup.string().required('This field is required'),
    genderComment: Yup.string().required('This field is required'),
    genderStatus: Yup.string().required('This field is required'),
    currencyNameComment: Yup.string().required('This field is required'),
    currencyNameStatus: Yup.string().required('This field is required'),
    status: Yup.string().required('This field is required'),
    comments: Yup.string().required('This field is required')
});

export const EditValidationSchemaSignature = Yup.object().shape({
    accountTitleComment: Yup.string().required('This field is required'),
    accountTitleStatus: Yup.string().required('This field is required'),
    selfiePicComment: Yup.string().required('This field is required'),
    selfiePicStatus: Yup.string().required('This field is required'),
    cnicFrontPicComment: Yup.string().required('This field is required'),
    cnicFrontPicStatus: Yup.string().required('This field is required'),
    cnicBackPicComment: Yup.string().required('This field is required'),
    cnicBackPicStatus: Yup.string().required('This field is required'),
    fatherNameComment: Yup.string().required('This field is required'),
    fatherNameStatus: Yup.string().required('This field is required'),
    birthPlaceComment: Yup.string().required('This field is required'),
    birthPlaceStatus: Yup.string().required('This field is required'),
    motherNameComment: Yup.string().required('This field is required'),
    motherNameStatus: Yup.string().required('This field is required'),
    professionNameComment: Yup.string().required('This field is required'),
    professionNameStatus: Yup.string().required('This field is required'),
    dualNationalComment: Yup.string().required('This field is required'),
    dualNationalStatus: Yup.string().required('This field is required'),
    usBornComment: Yup.string().required('This field is required'),
    usBornStatus: Yup.string().required('This field is required'),
    countryComment: Yup.string().required('This field is required'),
    countryStatus: Yup.string().required('This field is required'),
    countryOfBirthComment: Yup.string().required('This field is required'),
    countryOfBirthStatus: Yup.string().required('This field is required'),
    usGreenCardHolderComment: Yup.string().required('This field is required'),
    usGreenCardHolderStatus: Yup.string().required('This field is required'),
    usTelePhoneNumberComment: Yup.string().required('This field is required'),
    usTelePhoneNumberStatus: Yup.string().required('This field is required'),
    usSignAuthComment: Yup.string().required('This field is required'),
    usSignAuthStatus: Yup.string().required('This field is required'),
    usOtherInfoComment: Yup.string().required('This field is required'),
    usOtherInfoStatus: Yup.string().required('This field is required'),
    fedralTaxClassificationNameComment: Yup.string().required('This field is required'),
    fedralTaxClassificationNameStatus: Yup.string().required('This field is required'),
    residenceAddressComment: Yup.string().required('This field is required'),
    residenceAddressStatus: Yup.string().required('This field is required'),
    taxIdentificationNumberComment: Yup.string().required('This field is required'),
    taxIdentificationNumberStatus: Yup.string().required('This field is required'),
    geoLocationComment: Yup.string().required('This field is required'),
    geoLocationStatus: Yup.string().required('This field is required'),
    cnicExpiryDateComment: Yup.string().required('This field is required'),
    cnicExpiryDateStatus: Yup.string().required('This field is required'),
    signaturePicComment: Yup.string().required('This field is required'),
    signaturePicStatus: Yup.string().required('This field is required'),
    bvsVerifiedComment: Yup.string().required('This field is required'),
    bvsVerifiedStatus: Yup.string().required('This field is required'),
    emailComment: Yup.string().required('This field is required'),
    emailStatus: Yup.string().required('This field is required'),
    dateOfBirthComment: Yup.string().required('This field is required'),
    dateOfBirthStatus: Yup.string().required('This field is required'),
    customerVideoComment: Yup.string().required('This field is required'),
    customerVideoStatus: Yup.string().required('This field is required'),
    customerVideoKycComment: Yup.string().required('This field is required'),
    customerVideoKycStatus: Yup.string().required('This field is required'),
    deviceIdComment: Yup.string().required('This field is required'),
    deviceIdStatus: Yup.string().required('This field is required'),
    purposeOfAccountComment: Yup.string().required('This field is required'),
    purposeOfAccountStatus: Yup.string().required('This field is required'),
    genderComment: Yup.string().required('This field is required'),
    genderStatus: Yup.string().required('This field is required'),
    mailingAddressComment: Yup.string().required('This field is required'),
    mailingAddressStatus: Yup.string().required('This field is required'),
    proofOfIncomePicComment: Yup.string().required('This field is required'),
    proofOfIncomePicStatus: Yup.string().required('This field is required'),
    status: Yup.string().required('This field is required'),
    comments: Yup.string().required('This field is required')
});

export const EditValidationSchemaMinor = Yup.object().shape({
    accountTitleComment: Yup.string().required('This field is required'),
    accountTitleStatus: Yup.string().required('This field is required'),
    selfiePicComment: Yup.string().required('This field is required'),
    selfiePicStatus: Yup.string().required('This field is required'),
    bFormPic: Yup.string(),
    cnicFrontPicComment: Yup.string().when('bFormPic', {
        is: (bFormPic) => !bFormPic,
        then: Yup.string().required('This field is required'),
        otherwise: Yup.string()
    }),
    cnicFrontPicStatus: Yup.string().when('bFormPic', {
        is: (bFormPic) => !bFormPic,
        then: Yup.string().required('This field is required'),
        otherwise: Yup.string()
    }),
    cnicBackPicComment: Yup.string().when('bFormPic', {
        is: (bFormPic) => !bFormPic,
        then: Yup.string().required('This field is required'),
        otherwise: Yup.string()
    }),
    cnicBackPicStatus: Yup.string().when('bFormPic', {
        is: (bFormPic) => !bFormPic,
        then: Yup.string().required('This field is required'),
        otherwise: Yup.string()
    }),
    bFormPicComment: Yup.string().when('bFormPic', {
        is: (bFormPic) => bFormPic,
        then: Yup.string().required('This field is required'),
        otherwise: Yup.string()
    }),
    bFormPicStatus: Yup.string().when('bFormPic', {
        is: (bFormPic) => bFormPic,
        then: Yup.string().required('This field is required'),
        otherwise: Yup.string()
    }),
    parentCnicFrontPicComment: Yup.string().required('This field is required'),
    parentCnicFrontPicStatus: Yup.string().required('This field is required'),
    parentCnicBackPicComment: Yup.string().required('This field is required'),
    parentCnicBackPicStatus: Yup.string().required('This field is required'),
    bvsVerifiedComment: Yup.string().required('This field is required'),
    bvsVerifiedStatus: Yup.string().required('This field is required'),
    status: Yup.string().required('This field is required'),
    comments: Yup.string().required('This field is required')
});

import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handlePostRequest } from '../../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../../services/GetTemplate';
import classNames from 'classnames';
import { MultiSelect } from 'primereact/multiselect';
import * as Yup from 'yup';
import { loadingAction } from '../../../../../redux/actions/loadingAction';
import { toast } from 'react-toastify';
import ScrollMover from '../../../../../components/ScrollMover';
import AuthorizationHistoryTable from '../../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewCorporate({ isEditable, apiData }) {
    const [loading, setloading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [pricingProfile, setPricingProfile] = useState([]);

    const [limitProfile, setLimitProfile] = useState([]);
    const [channelOption, setChannelOption] = useState([]);

    const [productcatalog, setProductcatalog] = useState([]);

    const [modeofoperations, setOperationMode] = useState([]);
    const accountClassificationName = apiData?.lkpAccountClassification?.accountClassificationName;
    const accountClassificationId = apiData?.lkpAccountClassification?.accountClassificationId;
    const [tblKycSetDetails, setTblKycSetDetails] = useState([]);

    const [dFormValues, setDFormValues] = useState([]);
    const searchEmpId = useRef(null);
    const searchParentData = useRef(null);

    const [useCaseError, setUseCaseError] = useState({
        dobCNIC: '',
        cnic: '',
        mobileNumber: ''
    });
    const [accountStatus, setAccountStatus] = useState();

    const getaccountStatus = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getAccountStatus/LKP_ACCOUNT_STATUS:*');
        setAccountStatus(res?.payLoad);
    };

    useEffect(() => {
        getaccountStatus();
    }, []);

    const chequeBookLeaves = [
        { name: '25', value: '25' },
        { name: '50', value: '50' },
        { name: '100', value: '100' },
        { name: '250', value: '250' }
    ];
    const kycFixed = ['name', 'address', 'ntn', 'contactPersonCnic', 'contactPersonMobileNumber', 'contactPersonCnicIssuanceDate'];

    const handleInputChangeNumber = (e, type) => {
        formik.setFieldValue(type, e.target.value);
    };
    function getMaxRegexLength(regex) {
        // Remove leading and trailing slashes from the regular expression.
        const regexPattern = regex.toString().slice(1, -1);

        // Use a regular expression to find the maximum length.
        const lengthRegex = /\{(\d+)(,(\d+))?\}/g;
        let maxLength = 0;

        let match;
        while ((match = lengthRegex.exec(regexPattern)) !== null) {
            const min = parseInt(match[1], 10);
            const max = match[3] ? parseInt(match[3], 10) : min;
            if (max > maxLength) {
                maxLength = max;
            }
        }

        return maxLength;
    }

    useEffect(() => {
        const getKycFields = async () => {
            const res = await handleGetRequest(`/account/v1/agentaccount/getkycbyclassificationname/${accountClassificationName}`);

            let tblkyc = res?.payLoad[0].tblKycSetDetails;

            setTblKycSetDetails(tblkyc);
        };
        getKycFields();
    }, [accountClassificationName]);

    const initialValues = {
        pricingProfileId: '',
        commissionId: '',
        transLimitId: '',
        parentAgentId: '',
        channelIdList: [],
        empId: '',
        productCatalogId: '',
        chequeBook: false,
        noOfLeaves: '',
        chequeWaiver: 'N',
        employeeName: '',
        addressInfos: [
            {
                addressId: '',
                fullAddress: ''
            }
        ],
        accountNumber: '',
        accountTitle: ''
    };

    const validationSchema = Yup.object({
        channelIdList: Yup.array().required('This Field is required').min(1, 'This Field is required'),
        empId: Yup.string().required('This Field is required'),
        pricingProfileId: Yup.string().required('This Field is required'),
        transLimitId: Yup.string().required('This Field is required'),
        productCatalogId: Yup.string().required('This Field is required'),
        noOfLeaves: Yup.string().when('chequeBook', {
            is: true,
            then: Yup.string().required('This field is Required'),
            otherwise: Yup.string().notRequired()
        }),
        ...tblKycSetDetails.reduce((fieldValidations, result) => {
            const attributeName = result?.tblKycAttribute?.attributeName;
            const regexExpression = result?.tblKycAttribute;
            const date = result?.tblKycAttribute?.attributeName.toLowerCase().includes('date');
            const regExp = new RegExp(regexExpression.tblValidator.argumentValue);
            const maxLength = getMaxRegexLength(regExp);

            if (kycFixed?.includes(result?.tblKycAttribute?.attributeName) || (result.isMandatory === 'Y' && result?.isActive === 'Y')) {
                // Validate that the field is not empty
                fieldValidations[attributeName] = Yup.string().test('dynamicValidation', 'Please Enter valid Length', function (value) {
                    // Check if a value is entered and apply regular expression validation
                    if (value) {
                        if (value.length > maxLength && !date) {
                            if (maxLength > 0) {
                                return this.createError({
                                    path: this.path,
                                    message: `Value exceeds the maximum length of ${maxLength}`
                                });
                            }
                        }

                        return regExp.test(value);
                    } else {
                        // If no value is entered and the field is mandatory, show it as required
                        if (kycFixed?.includes(result?.tblKycAttribute?.attributeName) || result.isMandatory === 'Y') {
                            return this.createError({
                                path: this.path,
                                message: 'This Field is required'
                            });
                        }

                        // If no value is entered and the field is not mandatory, no error is shown.
                        return true;
                    }
                });
            } else if (result?.isActive === 'Y' && !date) {
                // If the item is not mandatory but active, only apply the regex validation
                fieldValidations[attributeName] = Yup.string().test('dynamicValidation', 'Please Enter valid Length', function (value) {
                    if (value) {
                        // Check if value is not empty
                        if (value.length > maxLength && !date) {
                            if (maxLength > 0) {
                                return this.createError({
                                    path: this.path,
                                    message: `Value exceeds the maximum length of ${maxLength}`
                                });
                            }
                        }

                        return regExp.test(value);
                    }
                    // If the value is empty, no error is shown.
                    return true;
                });
            }

            return fieldValidations;
        }, {})
        //   dobCNIC:Yup.string().max(200,'Maximum length is 200'),
    });

    const formik = useFormik({
        initialValues: initialValues,

        validationSchema,

        onSubmit: async (data, actions) => {
            delete data['birthPlace'];
            delete data['ecib'];
            delete data['plus30'];
            delete data['plus60'];
            delete data['plus90'];
            delete data['plus120'];
            delete data['plus150'];
            delete data['plus180'];
            const { dobCNIC, dCnic, dMobileNumber, dName, channelIdList, modeofoperations, employeeName, ...rest } = data;
            let nonRequiredFields = [];

            let transformedArr = dFormValues
                .map((item) => {
                    // Destructure the object to remove 'name' and 'identifier'

                    if (item.isActive === 'Y') {
                        const { name, identifier, dobCNIC, isActive, cnic, ...rest } = item;
                        // Rename 'dobCNIC' key to 'cnicissuancedate' and create a new object

                        return {
                            ...rest,
                            cnic: cnic,
                            cnicIssuanceDate: dobCNIC
                        };
                    }
                })
                .filter((isActive) => isActive !== null);

                const newArray = transformedArr?.filter(item => item !== null && item !== undefined);

            tblKycSetDetails?.forEach((item) => {
                if (kycFixed?.includes(item?.tblKycAttribute?.attributeName) && item?.isActive === 'Y') {
                    const attributeName = item?.tblKycAttribute?.attributeName;
                    data[attributeName] = formik.values[attributeName];
                    delete rest[attributeName];
                } else {
                    // If isMandatory is "N", add to nonRequiredFields
                    nonRequiredFields.push({
                        infoKey: item?.tblKycAttribute?.attributeName,
                        infoValue: formik.values[item?.tblKycAttribute?.attributeName]
                    });
                    delete rest[item?.tblKycAttribute?.attributeName];
                }
            });

            let payload = {
                ...rest,
                channelIdList: data.channelIdList.map((res) => res.lovId),
                agentId: apiData?.agentId,
                agentDirectorRequestList: newArray,
                accountClassificationName: accountClassificationName,
                contact: false
            };
            setloading(true);
            payload['chequeBook'] = formik?.values?.chequeBook ? 'Y' : 'N';

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: payload,
                    additionalInformation:
                        nonRequiredFields.length > 0
                            ? nonRequiredFields
                            : [
                                  {
                                      infoKey: '',
                                      infoValue: ''
                                  }
                              ],

                    checkSum: ''
                }
            };

            setloading(false);
            if (transformedArr?.length <= 0) {
                toast.warn('Please enter cnic Number and Cnic issuance');
            } else {
                const res = await dispatch(handlePostRequest(newData, '/account/v1/agentaccount/updateagentaccount', true, false, 'accountonboarding'));
                if (res.responseCode === '010000') {
                    actions.resetForm();
                } else {
                }
            }
            formik.setFieldValue('chequeBook', data['chequeBook'] === 'Y' ? true : false);
        }
    });

    const searchEmployeeId = async () => {
        setUseCaseError({ empId: '' });
        if (formik.values.empId === '') {
            setUseCaseError({ empId: 'This field is required' });
        }
        const id = formik.values.empId;
        if (id) {
            dispatch(loadingAction(true));
            const res = await handleGetRequest(`/account/v1/sales/getemployeebyid/${id}`);

            if (res === undefined) {
                setTimeout(() => dispatch(loadingAction(false)), 1000);
                formik.setFieldValue('employeeName', '');
            } else if (res) {
                setTimeout(() => dispatch(loadingAction(false)), 1000);
                setTimeout(() => formik.setFieldValue('employeeName', res?.payLoad[0]?.salesHierarchyName));
            }
        }
    };

    const panelFooterTemplate = () => {
        const length = formik.values.channelIdList ? formik.values.channelIdList.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    //pricing profile dropdown options API
    const getPricingProfile = async () => {
        const res = await handleGetRequest(`/account/v1/agentaccount/getpricingprofilebyclassification/${accountClassificationId}`);
        setPricingProfile(res?.payLoad);
    };

    const getProductCatalog = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/productCatalog/TBL_PRODUCT_CATALOG:*');
        setProductcatalog(res?.payLoad);
    };
    //limit profile dropdown options API
    const getLimitProfile = async () => {
        const res = await handleGetRequest(`/account/v1/agentaccount/gettranslimitbyclassification/${accountClassificationId}`);
        setLimitProfile(res?.payLoad);
    };
    const getChannel = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchannels/LKP_CHANNEL:*');
        setChannelOption(res?.payLoad);
    };

    const getCoperateEditData = () => {
        Object.keys(apiData).forEach((key) => {
            if (formik.initialValues.hasOwnProperty(key)) {
                formik.setFieldValue(key, apiData[key]);
            }
            if (apiData?.noOfLeaves == null) {
                formik.setFieldValue('noOfLeaves', '');
            }
        });

        // setCommissionProfile([{ commissionProfileId: apiData?.tblCommissionProfile?.commissionProfileId, commissionProfileName: apiData?.tblCommissionProfile?.commissionProfileName }]);
        setPricingProfile([{ pricingProfileId: apiData?.tblPricingProfile?.pricingProfileId, pricingProfileName: apiData?.tblPricingProfile?.pricingProfileName.toString() }]);
        setLimitProfile([{ transLimitId: apiData?.tblTransLimit?.transLimitId, limitProfileName: apiData?.tblTransLimit?.limitProfileName }]);
        formik.setFieldValue('employeeName', apiData?.tblSalesHierarchy.hierarchyName);

        const channelOptions = apiData?.tblAgentChannelList
            ?.filter((entry) => entry?.isActive === 'Y')
            .map((entry) => ({
                lovId: entry?.lkpChannel?.channelId?.toString()
            }));

        setProductcatalog([{ lovId: apiData?.tblAgentChannelList[0]?.tblProductCatalog?.productCatalogId.toString(), name: apiData?.tblAgentChannelList[0]?.tblProductCatalog?.projectCatalogName }]);

        setChannelOption(channelOptions);

        let directorList = apiData?.tblAgentDirectorList.map((res) => {
            return { name: res.name, cnic: res.cnic, dobCNIC: res.cnicIssuanceDate, mobileNumber: res.mobileNo, isActive: res.isActive };
        });
        setDFormValues(directorList);
        if (directorList.length >= 2) {
            // setOperationMode([{ name: 'Multiple', value: '2' }]);
            formik.setFieldValue('modeofoperations', '2');
        } else {
            // setOperationMode([{ name: 'Single', value: '1' }]);
            formik.setFieldValue('modeofoperations', '1');
        }
        //       setNearestBranch([{lovId:apiData.lkpBranch.branchId.toString(),name:apiData.lkpBranch.branchName}])

        if (apiData?.kycView) {
            let kycView = JSON.parse(apiData?.kycView);

            Object.keys(kycView).forEach((key) => {
                formik.setFieldValue(key, kycView[key]);
            });
        }

        formik.setFieldValue('dName', apiData?.tblAgentDirectorList[0]?.name);
        formik.setFieldValue('pricingProfileId', apiData?.tblPricingProfile?.pricingProfileId);
        formik.setFieldValue('commissionId', apiData?.tblCommissionProfile?.commissionProfileId);
        formik.setFieldValue('transLimitId', apiData?.tblTransLimit?.transLimitId);
        formik.setFieldValue('chequeBook', apiData?.chequeBook === 'Y');
        formik.setFieldValue('channelIdList', channelOptions);
        formik.setFieldValue('parentAgentId', '1');
        formik.setFieldValue('empId', apiData?.tblSalesForce?.employeeId);
        formik.setFieldValue('productCatalogId', apiData?.tblAgentChannelList[0]?.tblProductCatalog?.productCatalogId?.toString());
        formik.setFieldValue('accountNumber', apiData?.accountNumber);
        formik.setFieldValue('addressInfos', apiData?.addressInfos);
        formik.setFieldValue('accountTitle', apiData?.accountTitle);
        formik.setFieldValue('plus30', apiData?.tblEcibResponse?.plus30);
        formik.setFieldValue('plus60', apiData?.tblEcibResponse?.plus60);
        formik.setFieldValue('plus90', apiData?.tblEcibResponse?.plus90);
        formik.setFieldValue('plus120', apiData?.tblEcibResponse?.plus120);
        formik.setFieldValue('plus150', apiData?.tblEcibResponse?.plus150);
        formik.setFieldValue('plus180', apiData?.tblEcibResponse?.plus180);
        formik.setFieldValue('birthPlace', apiData?.birthPlace);
    };

    const handleInputChange = (e) => {
        let { name, value } = e.target;

        if (name === 'dMobileNumber') {
            if (/^[0-9]*$/.test(value)) {
                if (value.length === 0) {
                    value = '';
                } else if (value.charAt(0) !== '0') {
                    value = '0' + value; // Prepend '0' to the value
                }
            }

            // Update the form field with the modified value
            formik.setFieldValue(name.toString(), value);

            name = 'mobileNumber';
        } else if (name === 'dCnic') {
            name = 'cnic';
            formik.handleChange(e);
        } else if (name === 'dobCNIC') {
            name = 'dobCNIC';
            formik.handleChange(e);
        } else if (name === 'empId') {
            name = 'empId';
            const inputText = value.replace(/[^0-9]/g, '').slice(0, 7);

            // Update the form field with the modified value
            formik.setFieldValue(name.toString(), inputText);
            // formik.handleChange(e);
        }
        // Create a new object with the updated error for the specific field
        const updatedErrors = { ...useCaseError, [name]: '' };
        setUseCaseError(updatedErrors);

        //
    };

    const handleTableSubmit = (event, yup) => {
        // Create a new object representing the form data
        const newFormData = {
            // name: formik.values.dName,
            cnic: formik.values.dCnic,
            dobCNIC: formik.values.dobCNIC,
            mobileNumber: formik.values.dMobileNumber,
            identifier: Date.now(),
            isActive: 'Y'
        };

        const isDuplicateCNIC = dFormValues.some((data) => data.cnic === newFormData.cnic);
        const isDuplicateMobileNumber = dFormValues.some((data) => data.mobileNumber === newFormData.mobileNumber);

        // Check if the same data already exists in dFormValues

        if (isDuplicateCNIC || isDuplicateMobileNumber) {
            toast.warn('Duplicate cnic and mobile not allowed');
        } else {
            const schema = yup.object().shape({
                cnic: yup
                    .string()
                    .required('This Field is Required.')
                    .test('length', 'Value exceeds the maximum length of 13', function (value) {
                        if (value !== undefined && value?.length > 13) {
                            return false;
                        }
                        return true;
                    })
                    .test('pattern', 'Please Enter a valid CNIC', function (value) {
                        if (value) {
                            return /\d{13}/.test(value);
                        }
                        return true;
                    }),
                mobileNumber: yup
                    .string()
                    .required('This Field is Required.')
                    .test('length', 'Mobile Number exceeds the maximum length of 11', function (value) {
                        let newValue = value;

                        if (newValue !== undefined && newValue?.length > 11) {
                            return false;
                        }
                        return true;
                    })
                    .test('length', 'Mobile Number length is less than 11', function (value) {
                        let newValue = value;

                        if (newValue !== undefined && newValue?.length < 11) {
                            return false;
                        }
                        return true;
                    })
                    .test('startsWithZero', 'Mobile number must start with zero', function (value) {
                        if (value !== undefined && value.charAt(0) !== '0') {
                            return false;
                        }
                        return true;
                    }),
                dobCNIC: yup.string().required('This Field is Required.')
            });

            schema
                .validate(newFormData, { abortEarly: false }) // Abort early ensures that all errors are collected
                .then(() => {
                    // Validation passed

                    setDFormValues((prevDFormValues) => [...prevDFormValues, newFormData]);
                    formik.setFieldValue('dMobileNumber', '');
                    formik.setFieldValue('dCnic', '');
                    formik.setFieldValue('dobCNIC', '');
                    //
                })
                .catch((error) => {
                    // Validation failed
                    const errors = {};
                    error.inner.forEach((validationError) => {
                        errors[validationError.path] = validationError.message;
                    });
                    setUseCaseError(errors);
                });
        }
    };

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    useEffect(() => {
        if (dFormValues?.length >= 2) {
            setOperationMode([{ name: 'Multiple', value: '2' }]);
            formik.setFieldValue('modeofoperations', '2');
        } else {
            setOperationMode([{ name: 'Single', value: '1' }]);
            formik.setFieldValue('modeofoperations', '1');
        }
    }, [dFormValues]);
    useEffect(() => {
        setOperationMode([
            { name: 'Single', value: '1' },
            { name: 'Multiple', value: '2' }
        ]);
        getCoperateEditData();
        getProductCatalog();
        getLimitProfile();
        getPricingProfile();
        getChannel();
        // if (!isEditable) {
        // } else {
        //     setOperationMode([
        //         { name: 'Single', value: '1' },
        //         { name: 'Multiple', value: '2' }
        //     ]);
        //     getCoperateEditData();
        //     getProductCatalog();
        //     getLimitProfile();
        //     getPricingProfile();
        //     getChannel();
        // }
    }, [apiData]);

    const handleDelete = (cnic) => {
        const updatedDFormValues = dFormValues.filter((rowData) => rowData.cnic !== cnic);
        setDFormValues(updatedDFormValues);
    };

    function convertToTitleCase(inputString) {
        const words = inputString.split(/(?=[A-Z])/);
        const titleCaseString = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

        return titleCaseString;
    }

    const remarksValue = formik.values.remarks;
    const statusValue = formik.values.accountStatus;
    const [remarksDialog, setRemarksDialog] = useState(false);

    const dialogData = (data) => {
        formik.setFieldValue('accountStatus', data?.accountStatus);
        formik.setFieldValue('remarks', data?.remarks);
    };

    const handleRemarksDialog = (e) => {
        e.preventDefault();
        setRemarksDialog(true);
    };

    const onHideRemarksDialog = () => {
        setRemarksDialog(false);
    };
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (e.target.name === 'empId') {
                searchEmpId.current.click();
            }
            if (e.target.name === 'dCnic' || e.target.name === 'ParentCnic' || e.target.name === 'dMobileNumber') {
                searchParentData.current.click();
            }
            e.preventDefault();
        }
    };

    return (
        <>
            <div className="my-3">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="custom-card mb-5">
                        <div className="mx-5 mt-0 Form__Header Full__Width">
                            <p>CORPORATE KYC SETS</p>
                        </div>

                        <div className="p-fluid p-formgrid grid mb-5 mx-5">
                            {tblKycSetDetails?.map((result, index) => (
                                <React.Fragment key={index}>
                                    {result?.isActive === 'Y' && (
                                        <div key={index} className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field withoutArrow">
                                                <label htmlFor={result?.tblKycAttribute?.attributeName} className="Label__Text">
                                                    {convertToTitleCase(result?.tblKycAttribute?.attributeName)}
                                                    {(kycFixed?.includes(result?.tblKycAttribute?.attributeName) || result?.isMandatory === 'Y') && <span className="Label__Required">*</span>}
                                                </label>

                                                <InputText
                                                    id={result?.tblKycAttribute?.attributeName}
                                                    name={result?.tblKycAttribute?.attributeName}
                                                    value={formik.values[result?.tblKycAttribute?.attributeName]}
                                                    onChange={(e) => {
                                                        if (result?.tblKycAttribute?.attributeName.toLowerCase().includes('number')) {
                                                            handleInputChangeNumber(e, result?.tblKycAttribute?.attributeName);
                                                        } else {
                                                            formik.handleChange(e);
                                                        }
                                                    }}
                                                    className={classNames({ 'p-invalid': isFormFieldValid(result?.tblKycAttribute?.attributeName) }, 'Input__Round')}
                                                    maxLength={30}
                                                    disabled={kycFixed?.includes(result?.tblKycAttribute?.attributeName) || result?.tblKycAttribute?.isEditable === 'N' || !isEditable}
                                                    type={result?.tblKycAttribute?.attributeName.includes('Date') ? 'date' : 'text'}
                                                    onWheel={(e) => {
                                                        if (result?.tblKycAttribute?.attributeName.toLowerCase().includes('number') || result?.tblKycAttribute?.attributeName.toLowerCase().includes('cnic')) {
                                                            e.preventDefault(); // Prevent scroll wheel actions for number and cnic inputs.
                                                        }
                                                    }}
                                                    onFocus={(e) =>
                                                        e.target.addEventListener(
                                                            'wheel',
                                                            function (e) {
                                                                e.preventDefault();
                                                            },
                                                            { passive: false }
                                                        )
                                                    }
                                                    onKeyUp={(e) => {
                                                        if (result?.tblKycAttribute?.attributeName.toLowerCase().includes('number') || result?.tblKycAttribute?.attributeName.toLowerCase().includes('cnic')) {
                                                            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                                e.preventDefault(); // Prevent up and down arrow keys for number and cnic inputs.
                                                            }
                                                        }
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (result?.tblKycAttribute?.attributeName.toLowerCase().includes('number') || result?.tblKycAttribute?.attributeName.toLowerCase().includes('cnic')) {
                                                            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                                e.preventDefault(); // Prevent up and down arrow keys for number and cnic inputs.
                                                            }
                                                        }
                                                    }}
                                                />

                                                {getFormErrorMessage(result.tblKycAttribute.attributeName)}
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>

                    <div className="custom-card mb-5">
                        <div className="mx-5 mt-0 Form__Header Full__Width">
                            <p>DIRECTOR KYC SETS</p>
                        </div>

                        <div className="p-fluid p-formgrid grid mb-5 mx-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field withoutArrow">
                                    <label htmlFor="dCnic" className="Label__Text">
                                        CNIC
                                        <span className="Label__Required">*</span>
                                    </label>

                                    <InputText
                                        id="dCnic"
                                        name="dCnic"
                                        placeholder="Enter CNIC"
                                        value={formik.values.dCnic || ''}
                                        onChange={handleInputChange}
                                        className="Input__Round"
                                        maxLength={30}
                                        disabled={!isEditable}
                                        type="number"
                                        onWheel={(e) => {
                                            e.preventDefault(); // Prevent scroll wheel actions for number and cnic inputs.
                                        }}
                                        onFocus={(e) =>
                                            e.target.addEventListener(
                                                'wheel',
                                                function (e) {
                                                    e.preventDefault();
                                                },
                                                { passive: false }
                                            )
                                        }
                                        onKeyUp={(e) => {
                                            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                e.preventDefault(); // Prevent up and down arrow keys for number and cnic inputs.
                                            }
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                e.preventDefault(); // Prevent up and down arrow keys for number and cnic inputs.
                                            }
                                            handleKeyPress(e);
                                        }}
                                    />

                                    {/* {getFormErrorMessage('dCnic')} */}
                                    {useCaseError.cnic !== '' ? <small className="p-error">{useCaseError.cnic}</small> : getFormErrorMessage('dCnic')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field withoutArrow">
                                    <label htmlFor="dMobileNumber" className="Label__Text">
                                        Mobile Number
                                        <span className="Label__Required">*</span>
                                    </label>

                                    <InputText
                                        id="dMobileNumber"
                                        name="dMobileNumber"
                                        placeholder="Enter Mobile Number"
                                        value={formik.values.dMobileNumber || ''}
                                        onChange={handleInputChange}
                                        className="Input__Round"
                                        maxLength={30}
                                        disabled={!isEditable}
                                        type="number"
                                        onWheel={(e) => {
                                            e.preventDefault(); // Prevent scroll wheel actions for number and cnic inputs.
                                        }}
                                        onFocus={(e) =>
                                            e.target.addEventListener(
                                                'wheel',
                                                function (e) {
                                                    e.preventDefault();
                                                },
                                                { passive: false }
                                            )
                                        }
                                        onKeyUp={(e) => {
                                            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                e.preventDefault(); // Prevent up and down arrow keys for number and cnic inputs.
                                            }
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                e.preventDefault(); // Prevent up and down arrow keys for number and cnic inputs.
                                            }
                                            handleKeyPress(e);
                                        }}
                                    />

                                    {useCaseError.mobileNumber !== '' ? <small className="p-error">{useCaseError.mobileNumber}</small> : getFormErrorMessage('dMobileNumber')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="dobCNIC" className="Label__Text">
                                        CNIC ISSUANCE DATE
                                        <span className="Label__Required">*</span>
                                    </label>

                                    <InputText
                                        id="dobCNIC"
                                        name="dobCNIC"
                                        type="date"
                                        onKeyDown={(e) => handleKeyPress(e)}
                                        value={formik.values.dobCNIC || ''}
                                        onChange={handleInputChange}
                                        className="Input__Round"
                                        maxLength={30}
                                        disabled={!isEditable}
                                    />
                                    {useCaseError.dobCNIC !== '' ? <small className="p-error">{useCaseError.dobCNIC}</small> : getFormErrorMessage('dobCNIC')}
                                    {/* {getFormErrorMessage('dobCNIC')} */}
                                </div>
                            </div>

                            <div className="Usergroup__SearchBtn p-field col-12 md:col-4 pt-3 pb-3 ">
                                <Button type="button" label="Add" className="Btn__Dark__Search" ref={searchParentData} onClick={(e) => handleTableSubmit(e, Yup)} disabled={!isEditable} />
                            </div>
                        </div>

                        <div className="grid mx-5">
                            <div className="col-12">
                                <div className="card">
                                    <DataTable className="datatable-responsive" value={dFormValues.filter((result) => result.isActive !== 'N')} emptyMessage="No List found." responsiveLayout="scroll">
                                        <Column field="name" header="Name" />
                                        <Column field="cnic" header="CNIC" />
                                        <Column field="dobCNIC" header="CNIC ISSUANCE DATE" />
                                        <Column field="mobileNumber" header="Mobile Number" />
                                        <Column
                                            header="Action"
                                            body={(rowData) => (
                                                <Button
                                                    type="button"
                                                    disabled={!isEditable}
                                                    onClick={() => handleDelete(rowData.cnic)}
                                                    tooltip="Delete"
                                                    tooltipOptions={{ position: 'top' }}
                                                    style={{ marginLeft: '37%' }}
                                                    icon="pi pi-trash"
                                                    className="p-button-rounded p-button-danger"
                                                />
                                            )}
                                            className="Table__ActionHeading__CentredAlign"
                                        />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="pricingProfileId" className="Label__Text">
                                    Pricing Profile
                                    <span className="Label__Required">*</span>
                                </label>

                                <Dropdown
                                    id="pricingProfileId"
                                    placeholder="Select Pricing Profile"
                                    options={pricingProfile}
                                    optionLabel="pricingProfileName"
                                    name="pricingProfileId"
                                    optionValue="pricingProfileId"
                                    value={formik.values.pricingProfileId || ''}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round"
                                    disabled={!isEditable}
                                />
                                {getFormErrorMessage('pricingProfileId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="newmodeofoperations" className="Label__Text">
                                    Mode of Operations
                                </label>
                                <Dropdown
                                    id="modeofoperations"
                                    placeholder="Mode of Operations"
                                    options={modeofoperations}
                                    optionLabel="name"
                                    name="modeofoperations"
                                    optionValue="value"
                                    value={formik.values.modeofoperations || ''}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round"
                                    disabled
                                />
                                {/* {getFormErrorMessage('channelName')} */}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="transLimitId" className="Label__Text">
                                    Limit Profile
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="transLimitId"
                                    placeholder="Select Limit Profile"
                                    options={limitProfile}
                                    optionLabel="limitProfileName"
                                    name="transLimitId"
                                    optionValue="transLimitId"
                                    value={formik.values.transLimitId || ''}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round"
                                    disabled={!isEditable}
                                />

                                {getFormErrorMessage('transLimitId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="channelIdList" className={classNames({ 'p-error': isFormFieldValid('channelIdList') }, 'Label__Text')}>
                                    Channel <span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="channelIdList"
                                    options={channelOption}
                                    placeholder="Select Channel"
                                    name="channelIdList"
                                    optionLabel="name"
                                    optionValue="lovId"
                                    filter
                                    panelFooterTemplate={panelFooterTemplate}
                                    value={formik?.values?.channelIdList?.map((trans) => trans.lovId || '')}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            'channelIdList',
                                            e.value.map((lovId) => ({ lovId }))
                                        );
                                    }}
                                    disabled={!isEditable}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelIdList') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('channelIdList')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field withoutArrow">
                                <label htmlFor="empId" className="Label__Text">
                                    Employee ID<span className="Label__Required">*</span>
                                </label>
                                <div className="flex">
                                    <InputText
                                        id="empId"
                                        name="empId"
                                        onKeyDown={(e) => handleKeyPress(e)}
                                        placeholder="Enter Employee ID"
                                        value={formik.values.empId}
                                        onChange={formik.handleChange}
                                        className="Input__Round"
                                        maxLength={30}
                                        disabled={!isEditable}
                                    />
                                    <Button
                                        icon={loadingIcon || ''}
                                        onClick={() => searchEmployeeId()}
                                        ref={searchEmpId}
                                        type="button"
                                        className="Btn__Dark border-circle"
                                        style={{ height: '35px', width: '35px', marginLeft: '1rem' }}
                                        disabled={!isEditable}
                                    />
                                </div>
                                {useCaseError.empId !== '' ? <small className="p-error">{useCaseError.empId}</small> : ''}
                                {getFormErrorMessage('empId')}
                            </div>
                        </div>
                    </div>

                    <div className="custom-card mb-5">
                        <div className="mx-5 mt-0 Form__Header Full__Width">
                            <p>EMPLOYEE ID</p>
                        </div>

                        <div className="p-fluid p-formgrid grid mb-5 mx-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="channelName" className="Label__Text">
                                        Sales Hierarchy Name
                                        <span className="Label__Required">*</span>
                                    </label>
                                    <InputText id="employeeName" name="employeeName" value={formik.values.employeeName} onChange={formik.handleChange} className="Input__Round" maxLength={30} disabled />
                                    {getFormErrorMessage('employeeName')}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productCatalogId" className="Label__Text">
                                    Product catalog
                                    <span className="Label__Required">*</span>
                                </label>

                                <Dropdown
                                    id="productCatalogId"
                                    placeholder="Select Product Catalog"
                                    options={productcatalog}
                                    optionLabel="name"
                                    name="productCatalogId"
                                    optionValue="lovId"
                                    value={formik.values.productCatalogId || ''}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round"
                                    disabled={!isEditable}
                                />
                                {getFormErrorMessage('productCatalogId')}
                            </div>
                        </div>
                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="accountTitle" className={classNames({ 'p-error': isFormFieldValid('accountTitle') }, 'Label__Text')}>
                                Account Title <span className="Label__Required">*</span>
                            </label>
                            <InputText
                                disabled
                                id="accountTitle"
                                name="accountTitle"
                                placeholder="Enter Account Title"
                                value={formik.values.accountTitle || ''}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('accountTitle') }, 'Input__Round')}
                            />
                            {getFormErrorMessage('accountTitle')}
                        </div>
                    </div>
                    {formik.values.addressInfos?.map((item, index) => {
                        return (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3" key={index}>
                                <div className="p-field">
                                    <label htmlFor={`addressInfos.${index}.fullAddress`} className={classNames({ 'p-error': isFormFieldValid(`addressInfos.${index}.fullAddress`) }, 'Label__Text')}>
                                        Address <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        disabled
                                        id={`addressInfos.${index}.fullAddress`}
                                        name={`addressInfos.${index}.fullAddress`}
                                        placeholder="Enter Adds"
                                        value={item?.fullAddress || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid(`addressInfos.${index}.fullAddress`) }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage(`addressInfos.${index}.fullAddress`)}
                                </div>
                            </div>
                        );
                    })}

                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="accountNumber" className={classNames({ 'p-error': isFormFieldValid('accountNumber') }, 'Label__Text')}>
                                Account Number <span className="Label__Required">*</span>
                            </label>
                            <InputText
                                id="accountNumber"
                                name="accountNumber"
                                placeholder="Select Account Number"
                                value={formik.values.accountNumber || ''}
                                onChange={formik.handleChange}
                                disabled
                                onKeyDown={(e) => handleKeyPress(e)}
                                className={classNames({ 'p-invalid': isFormFieldValid('accountNumber') }, 'Input__Round')}
                            />
                            {getFormErrorMessage('accountNumber')}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="plus30" className={classNames({ 'p-error': isFormFieldValid('plus30') }, 'Label__Text')}>
                                ECIB Plus 30 <span className="Label__Required">*</span>
                            </label>
                            <InputText
                                id="plus30"
                                name="plus30"
                                placeholder="Select ECIB Response"
                                value={formik.values.plus30 || ''}
                                onChange={formik.handleChange}
                                disabled
                                onKeyDown={(e) => handleKeyPress(e)}
                                className={classNames({ 'p-invalid': isFormFieldValid('plus30') }, 'Input__Round')}
                            />
                            {getFormErrorMessage('plus30')}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="plus60" className={classNames({ 'p-error': isFormFieldValid('plus60') }, 'Label__Text')}>
                                ECIB Plus 60 <span className="Label__Required">*</span>
                            </label>
                            <InputText
                                id="plus60"
                                name="plus60"
                                placeholder="Select ECIB Response"
                                value={formik.values.plus60 || ''}
                                onChange={formik.handleChange}
                                disabled
                                onKeyDown={(e) => handleKeyPress(e)}
                                className={classNames({ 'p-invalid': isFormFieldValid('plus60') }, 'Input__Round')}
                            />
                            {getFormErrorMessage('plus60')}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="plus90" className={classNames({ 'p-error': isFormFieldValid('plus90') }, 'Label__Text')}>
                                ECIB Plus 90 <span className="Label__Required">*</span>
                            </label>
                            <InputText
                                id="plus90"
                                name="plus90"
                                placeholder="Select ECIB Response"
                                value={formik.values.plus90 || ''}
                                onChange={formik.handleChange}
                                disabled
                                onKeyDown={(e) => handleKeyPress(e)}
                                className={classNames({ 'p-invalid': isFormFieldValid('plus90') }, 'Input__Round')}
                            />
                            {getFormErrorMessage('plus90')}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="plus120" className={classNames({ 'p-error': isFormFieldValid('plus120') }, 'Label__Text')}>
                                ECIB Plus 120 <span className="Label__Required">*</span>
                            </label>
                            <InputText
                                id="plus120"
                                name="plus120"
                                placeholder="Select ECIB Response"
                                value={formik.values.plus120 || ''}
                                onChange={formik.handleChange}
                                disabled
                                onKeyDown={(e) => handleKeyPress(e)}
                                className={classNames({ 'p-invalid': isFormFieldValid('plus120') }, 'Input__Round')}
                            />
                            {getFormErrorMessage('plus120')}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="plus150" className={classNames({ 'p-error': isFormFieldValid('plus150') }, 'Label__Text')}>
                                ECIB Plus 150 <span className="Label__Required">*</span>
                            </label>
                            <InputText
                                id="plus150"
                                name="plus150"
                                placeholder="Select ECIB Response"
                                value={formik.values.plus150 || ''}
                                onChange={formik.handleChange}
                                disabled
                                onKeyDown={(e) => handleKeyPress(e)}
                                className={classNames({ 'p-invalid': isFormFieldValid('plus150') }, 'Input__Round')}
                            />
                            {getFormErrorMessage('plus150')}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="plus180" className={classNames({ 'p-error': isFormFieldValid('plus180') }, 'Label__Text')}>
                                ECIB Plus 180 <span className="Label__Required">*</span>
                            </label>
                            <InputText
                                id="plus180"
                                name="plus180"
                                placeholder="Select ECIB Response"
                                value={formik.values.plus180 || ''}
                                onChange={formik.handleChange}
                                disabled
                                onKeyDown={(e) => handleKeyPress(e)}
                                className={classNames({ 'p-invalid': isFormFieldValid('plus180') }, 'Input__Round')}
                            />
                            {getFormErrorMessage('plus180')}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="birthPlace" className={classNames({ 'p-error': isFormFieldValid('birthPlace') }, 'Label__Text')}>
                                Place of birth <span className="Label__Required">*</span>
                            </label>
                            <InputText
                                id="birthPlace"
                                name="birthPlace"
                                placeholder="Enter place of birth"
                                value={formik.values.birthPlace || ''}
                                onChange={formik.handleChange}
                                disabled
                                onKeyDown={(e) => handleKeyPress(e)}
                                className={classNames({ 'p-invalid': isFormFieldValid('birthPlace') }, 'Input__Round')}
                            />
                            {getFormErrorMessage('birthPlace')}
                        </div>
                    </div>

                        {/* <div className="p-field col-12 md:col-4 pt-3 pb-3 mt-5">
                            <div className="p-field">
                                <Checkbox
                                    inputId="chequeBook"
                                    name="chequeBook"
                                    checked={formik.values.chequeBook}
                                    onChange={() => {
                                        // formik.handleChange();
                                        formik.setFieldValue('chequeBook', !formik.values.chequeBook);
                                        formik.setFieldValue('noOfLeaves', '');
                                        formik.setFieldValue('chequeWaiver', '');
                                    }}
                                    // disabled={!isEditable}
                                    disabled
                                />
                                <label htmlFor="chequeBook" className="Label__Text">
                                    Cheque Book
                                </label>
                            </div>
                        </div> */}
                    </div>
                   

                    {/* {formik.values.chequeBook === true ? (
                        <div className="custom-card mb-5">
                            <div className="mx-5 mt-0 Form__Header Full__Width">
                                <p>Cheque Book Details</p>
                            </div>

                            <div className="p-fluid p-formgrid grid mb-5 mx-5">
                                {formik.values.chequeBook === true && (
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="noOfLeaves" className={classNames({ 'p-error': isFormFieldValid('noOfLeaves') }, 'Label__Text')}>
                                                No of leaves
                                                <span className="Label__Required">*</span>
                                            </label>
                                            <Dropdown
                                                id="noOfLeaves"
                                                options={chequeBookLeaves}
                                                name="noOfLeaves"
                                                placeholder="Select Number of Leaves"
                                                optionLabel="name"
                                                optionValue="value"
                                                value={formik.values.noOfLeaves}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('noOfLeaves') }, 'Dropdown__Round')}
                                                disabled={!isEditable}
                                            />
                                            {getFormErrorMessage('noOfLeaves')}
                                        </div>
                                    </div>
                                )}

                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="channelName" className="Label__Text">
                                            Waiver
                                        </label>

                                        <div className="flex">
                                            <div className="mt-2 mr-5">
                                                <RadioButton inputId="city1" name="chequeWaiver" value="Y" onChange={formik.handleChange} checked={formik.values.chequeWaiver === 'Y'} disabled={!isEditable} />

                                                <label htmlFor="city1" className="Label__Text">
                                                    Applicable
                                                </label>
                                            </div>

                                            <div className="mt-2">
                                                <RadioButton inputId="city1" name="chequeWaiver" value="N" onChange={formik.handleChange} checked={formik.values.chequeWaiver === 'N'} disabled={!isEditable} />

                                                <label htmlFor="city1" className="Label__Text">
                                                    Not Applicable
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )} */}

                    {isEditable ? (
                        <>
                            <div className="Down__Btn my-5">
                                <Button type="submit" label="Update" className="Btn__Dark" onClick={() => ScrollMover()} />
                                <Button disabled={loading} onClick={() => navigate('/accountonboarding')} label="Cancel" className="Btn__Transparent" />
                            </div>
                        </>
                    ) : (
                        <React.Fragment>
                            <div className="Down__Btn my-5">
                                <Button type="button" label="Okay" className="Btn__Dark" onClick={() => navigate('/accountonboarding')} />
                            </div>
                            <AuthorizationHistoryTable refTableId={apiData?.agentId} tableName={'TBL_AGENT'} />
                        </React.Fragment>
                    )}
                </form>
            </div>
        </>
    );
}

export default ViewCorporate;

import React, { useState, useEffect, useRef } from 'react';
import { handleGetRequest } from '../../../services/GetTemplate';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import '../../../../src/components/DataTable.css';
import AddEditMenu from './AddEditMenu';
import { InputText } from 'primereact/inputtext';
import ViewMenu from './ViewMenu';
import { FilterMatchMode } from 'primereact/api';

/* eslint-disable */
function Menu() {
    const [menu, setMenu] = useState([]);
    const [loading, setloading] = useState([]);
    const [displayModal, setDisplayModal] = useState(false);
    const [displayViewModal, setDisplayViewModal] = useState(false);
    const [showMenuData, setShowMenuData] = useState({});
    const [editable, setEditable] = useState(false);
    const [menuRowData, setMenuRowData] = useState({});
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        menuCode: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const dataTableRef = useRef(null);

    const editHandler = (rowData) => {
        setDisplayModal(true);
        setMenuRowData(rowData);
        setEditable(true);
    };

    const getMenu = async () => {
        setloading(true);
        dataTableRef.current.reset();
        const response = await handleGetRequest(`/mfs_user_management/v1/management/getAllMenu`);
        setMenu(response?.payLoad);
        setloading(false);
    };

    useEffect(() => {
        getMenu();
    }, []);

    const showMenu = (rowData) => {
        setDisplayViewModal(true);
        setShowMenuData(rowData);
    };

    const actionTemplate = (rowData) => {
        return (
            <div className='Menu__Actions'>
                <Button tooltip="Edit" icon="pi pi-pencil" tooltipOptions={{ position: 'top' }} onClick={() => editHandler(rowData)} className="p-button-rounded p-button-primary p-mr-2" />
                <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => showMenu(rowData)} className="p-button-rounded p-mr-2" style={{backgroundColor:'#F2AC57', border:'none'}}/>
            </div>
        );
    };

    const onHide = () => {
        setDisplayModal(false);
        setEditable(false);
    };

    const onViewHide = () => {
        setDisplayViewModal(false);
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ''));
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">Menu</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." maxLength={25}/>
                </span>
            </div>
        );
    };

    const header = renderHeader();

    const activeBody = (rowData) => {
        return <React.Fragment>{rowData?.isActive === 'Y' ? 'Yes' : rowData?.isActive === 'N' ? 'No' : null}</React.Fragment>;
    };

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    return (
        <div>
            <div className="Top__Btn">
                <div>
                    <Button label="New" onClick={() => setDisplayModal(true)} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>
            <Dialog header={editable ? 'Edit Menu' : 'Add Menu'} visible={displayModal} draggable={false} resizable={false} style={{ width: '50vw' }} onHide={() => onHide()}>
                <AddEditMenu onHide={onHide} editable={editable} menuRowData={menuRowData} getMenu={getMenu} />
            </Dialog>
            <Dialog header={'View Menu'} visible={displayViewModal} draggable={false} resizable={false} style={{ width: '50vw' }} onHide={() => onViewHide()}>
                <ViewMenu onViewHide={() => onViewHide()} menuRowData={showMenuData} />
            </Dialog>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable 
                            header={header}
                            filters={filters}
                            globalFilterFields={['menuCode', 'createdate', 'menuDescr', 'isActive']}
                            value={menu}
                            emptyMessage="No data found."
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            responsiveLayout='scroll'
                        >
                            {/* <Column field="iconName" header="Icon Name" /> */}
                            <Column field="menuCode" header="Menu Code" />
                            <Column header="Created Date & Time" body={createdDateAndTimeBodyTemplate} />
                            <Column field="menuDescr" header="Description" />
                            <Column field="isActive" header="Active" body={activeBody} />
                            <Column body={actionTemplate} header="Actions" className='Table__ActionHeading__CentredAlign'/>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Menu;

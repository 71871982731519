import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { handleGetRequest } from '../../../services/GetTemplate';

const AddNewMerchant = ({ handleMerchant, hideDialogue }) => {
    const [merchantLov, setMerchantLov] = useState();

    const formik = useFormik({
        initialValues: {
            merchant: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.merchant) {
                errors.merchant = 'merchant is required.';
            }

            return errors;
        },
        onSubmit: async (data) => {
            handleMerchant(data?.merchant);
            hideDialogue();
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getMerchants = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/lending/TBL_MERCHANT');
        setMerchantLov(response?.payLoad);
    };
    useEffect(() => {
        getMerchants();
    }, []);

    return (
        <div className="card Card__Round">
            <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                <div className="p-field col-12 md:col-12">
                    <div className="p-field">
                        <label htmlFor="merchant" className={classNames({ 'p-error': isFormFieldValid('merchant') }, 'Label__Text')}>
                            Select Merchant
                        </label>
                        <Dropdown
                            id="merchant"
                            placeholder="Select Merchant"
                            options={merchantLov}
                            optionLabel="name"
                            name="merchant"
                            //   optionValue="lovId"
                            value={formik.values.merchant || ''}
                            onChange={formik.handleChange}
                            className={classNames({ 'p-invalid': isFormFieldValid('merchant') }, 'Dropdown__Round')}
                        />
                        {getFormErrorMessage('merchant')}
                    </div>
                </div>
                <div className="p-field col-12 md:col-12 mr-5 mt-4">
                    <div className="Down__Btn">
                        <Button type="submit" label="Add" className="Btn__Add" />
                    </div>
                </div>
            </form>
        </div>
    );
};
export default AddNewMerchant;

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { useLocation } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';

const SubCategoryOldNewValues = ({ subCategoryRowData }) => {
    const [mcReqData, setMcReqData] = useState([]);

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const requestType = query.get('requestType');
    const requestTypeDescr = query.get('requestTypeDescr');

    const formik = useFormik({
        initialValues: {
            lkpSubCategoryName: '',
            lkpSubCategoryCode: '',
            lkpSubCategoryDescription: '',
            coaCode: '',
            action: '',
            checkerComments: ''
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getSubCategoryDataById = async () => {
        if (subCategoryRowData !== null && typeof subCategoryRowData !== 'undefined') {
            formik.setFieldValue('lkpSubCategoryName', subCategoryRowData?.subCategoryName);
            formik.setFieldValue('lkpSubCategoryCode', subCategoryRowData?.subCategoryCode);
            formik.setFieldValue('lkpSubCategoryDescription', subCategoryRowData?.subCategoryDescr);
            formik.setFieldValue('coaCode', subCategoryRowData?.coaCode);
        }
    };

    useEffect(() => {
        getSubCategoryDataById();
    }, []); // eslint-disable-line

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>Old Details</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Sub Category Name</label>
                                <InputText
                                    id="lkpSubCategoryName"
                                    value={formik.values.lkpSubCategoryName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpSubCategoryName') }, 'Input__Round')}
                                    maxLength={30}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpSubCategoryName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Sub Category Code</label>
                                <InputText
                                    id="lkpSubCategoryCode"
                                    value={formik.values.lkpSubCategoryCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpSubCategoryCode') }, 'Input__Round')}
                                    maxLength={30}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpSubCategoryCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Sub Category Description</label>
                                <InputText
                                    id="lkpSubCategoryDescription"
                                    value={formik.values.lkpSubCategoryDescription || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpSubCategoryDescription') }, 'Input__Round')}
                                    maxLength={199}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpSubCategoryDescription')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Coa Code</label>
                                <InputText id="coaCode" value={formik.values.coaCode || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('coaCode') }, 'Input__Round')} maxLength={30} disabled={true} />
                                {getFormErrorMessage('coaCode')}
                            </div>
                        </div>

                        {requestType === 'U' && requestTypeDescr === 'Enable/Disable Request' && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label className="Label__Text">Is Active</label>
                                    <Checkbox checked={mcReqData?.isActive === 'Y'} disabled className="w-full" />
                                </div>
                            </div>
                        )}
                    </div>
                </form>
            </div>
        </>
    );
};

export default SubCategoryOldNewValues;

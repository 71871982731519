import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../services/PostTemplate';
import { handleGetRequest } from '../../services/GetTemplate';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { baseURL } from '../../Config';
import AuthorizationHistoryTable from './../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import { InputTextarea } from 'primereact/inputtextarea';

function ViewSegment() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [businessTypeData, setBusinessTypeData] = useState([]);
    const [reasonData, setReasonData] = useState([]);
    const [kamData, setKamData] = useState([]);
    const [fileLink, setFileLink] = useState('');

    const proofOfProfessionRef = useRef(null);
    const [proofOfProfessionFileName, setProofOfProfessionFileName] = useState('');

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const segmentId = query.get('segmentId');
    const tableName = 'LKP_SEGMENT';

    const getDatabyId = async () => {
        const resp = await handleGetRequest(`/account/v1/segment/getSegmentById/${segmentId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik?.setFieldValue(key, keyData[key]);
                }
            });
            const date = new Date(keyData?.agreementSigningDate);
            setProofOfProfessionFileName(keyData?.uploadAgreement?.slice(34));
            const formattedDate = date.toISOString().split('T')[0];
            formik.setFieldValue('agreementSigningDate', formattedDate);
            formik.setFieldValue('businessTypeId', keyData?.lkpBusinessType?.businessTypeId?.toString());
            formik.setFieldValue('segmentName', keyData?.segmentName?.split('-')[0]);
            formik.setFieldValue('salesRoleDetailId', keyData?.tblSalesRoleDetail?.salesRoleDetailId?.toString());
            formik.setFieldValue('regionId', keyData?.lkpRegion?.regionId?.toString());
            formik.setFieldValue('salaryStatus', keyData?.salaryStatus === 'Y' ? true : false);
            formik.setFieldValue('agreementSigningDate', keyData?.agreementSigningDate);
            setFileLink(`${baseURL}/document/${resp?.payLoad?.uploadAgreement?.split('/documents/')[1]}`);
        }
    };

    useEffect(() => {
        getDatabyId();
    }, []); // eslint-disable-line

    const getBusinessTypeData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getbusinesstypes/LKP_BUSINESS_TYPE:*');
        setBusinessTypeData(res?.payLoad);
    };

    const getReasonData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getallregions/LKP_REGION:*');
        setReasonData(resp?.payLoad);
    };

    useEffect(() => {
        getBusinessTypeData();
        getReasonData();
    }, []);

    const formik = useFormik({
        initialValues: {
            businessTypeId: '',
            segmentName: '',
            salesRoleDetailId: '',
            regionId: '',
            agreementSigningDate: '',
            uploadAgreement: '',
            clientPocName: '',
            clientMobileNo: '',
            clientEmail: '',
            companyAddress: '',
            segmentDescr: '',
            salaryStatus: false
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(newData, '/tax/v1/saveTaxRegime', true, false, 'taxprofile'));
            if (res?.responseCode === '0000') {
                formik.resetForm();
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const splitProofOfProfessionFileName = '';
    const handleProofOfProfessionClick = () => {
        proofOfProfessionRef.current.click();
    };

    const handleProofOfProfessionChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            setProofOfProfessionFileName(selectedFile.name); // Update the selected file name
        }
    };

    const getKamData = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getkamByBusinessType/${formik.values.businessTypeId}`);
        setKamData(resp?.payLoad);
    };

    useEffect(() => {
        if (formik.values.businessTypeId !== '' || formik.values.businessType !== undefined) {
            getKamData();
        }
    }, [formik.values.businessTypeId]); // eslint-disable-line
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>VIEW DETAILS</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="businessTypeId" className={classNames({ 'p-error': isFormFieldValid('businessTypeId') }, 'Label__Text')}>
                                    Business Type
                                </label>
                                <Dropdown
                                    disabled
                                    id="businessTypeId"
                                    placeholder="Select Business Type"
                                    options={businessTypeData}
                                    optionLabel="name"
                                    name="businessTypeId"
                                    optionValue="lovId"
                                    value={formik.values.businessTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('businessTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('businessTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="segmentName" className={classNames({ 'p-error': isFormFieldValid('segmentName') }, 'Label__Text')}>
                                    Client Profile Name
                                </label>
                                <InputText
                                    disabled
                                    id="segmentName"
                                    placeholder="Enter profile name"
                                    name="segmentName"
                                    value={formik?.values?.segmentName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('segmentName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('segmentName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="salesRoleDetailId" className={classNames({ 'p-error': isFormFieldValid('salesRoleDetailId') }, 'Label__Text')}>
                                    KAM
                                </label>
                                <Dropdown
                                    disabled
                                    id="salesRoleDetailId"
                                    placeholder="Select Kam"
                                    options={kamData}
                                    optionLabel="name"
                                    name="salesRoleDetailId"
                                    optionValue="lovId"
                                    value={formik.values.salesRoleDetailId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('salesRoleDetailId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('salesRoleDetailId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="regionId" className={classNames({ 'p-error': isFormFieldValid('regionId') }, 'Label__Text')}>
                                    Region
                                </label>
                                <Dropdown
                                    disabled
                                    id="regionId"
                                    placeholder="Select Region"
                                    options={reasonData}
                                    optionLabel="name"
                                    name="regionId"
                                    optionValue="lovId"
                                    value={formik.values.regionId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regionId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('regionId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="agreementSigningDate" className={classNames({ 'p-error': isFormFieldValid('agreementSigningDate') }, 'Label__Text')}>
                                    Agreement Signing date
                                </label>
                                <InputText
                                    disabled={true}
                                    id="agreementSigningDate"
                                    placeholder="Enter date"
                                    name="agreementSigningDate"
                                    type="date"
                                    value={formik?.values?.agreementSigningDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('agreementSigningDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('agreementSigningDate')}
                            </div>
                        </div>
                        {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="uploadAgreement" className={classNames({ 'p-error': isFormFieldValid('uploadAgreement') }, 'Label__Text')}>
                                    Upload Agreement
                                </label>
                                <InputText
                                    disabled
                                    id="uploadAgreement"
                                    placeholder="Please Upload Agreement"
                                    name="uploadAgreement"
                                    type="file"
                                    value={formik?.values?.uploadAgreement || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('uploadAgreement') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('uploadAgreement')}
                            </div>
                        </div> */}
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Upload Agreement</label>
                                <div className="file-input-container w-100" onClick={handleProofOfProfessionClick}>
                                    <input disabled={true} type="file" style={{ display: 'none' }} ref={proofOfProfessionRef} onChange={handleProofOfProfessionChange} accept=".pdf" />
                                    <input disabled={true} type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={proofOfProfessionFileName ? proofOfProfessionFileName : splitProofOfProfessionFileName} readOnly />
                                    <span className="file-upload-icon">
                                        <i className="pi pi-paperclip" />
                                    </span>
                                </div>
                            </div>
                            {proofOfProfessionFileName && (
                                <div className="Down__Btn mt-3">
                                    <Button
                                        type="button"
                                        label="View Document"
                                        className="Btn__Dark"
                                        onClick={() => {
                                            window.open(fileLink, '_blank');
                                        }}
                                        disabled={!proofOfProfessionFileName}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="clientPocName" className={classNames({ 'p-error': isFormFieldValid('clientPocName') }, 'Label__Text')}>
                                    Client POC Name
                                </label>
                                <InputText
                                    disabled
                                    id="clientPocName"
                                    placeholder="Enter Poc Name"
                                    name="clientPocName"
                                    value={formik?.values?.clientPocName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('clientPocName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('clientPocName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="clientMobileNo" className={classNames({ 'p-error': isFormFieldValid('clientMobileNo') }, 'Label__Text')}>
                                    Client Mobile Number
                                </label>
                                <InputText
                                    disabled
                                    id="clientMobileNo"
                                    placeholder="Enter Mobile No"
                                    name="clientMobileNo"
                                    value={formik?.values?.clientMobileNo?.replace(/\D/g, '') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('clientMobileNo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('clientMobileNo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="clientEmail" className={classNames({ 'p-error': isFormFieldValid('clientEmail') }, 'Label__Text')}>
                                    Client Email
                                </label>
                                <InputText
                                    disabled
                                    id="clientEmail"
                                    placeholder="Enter client clientEmail"
                                    name="clientEmail"
                                    type="email"
                                    value={formik?.values?.clientEmail?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('clientEmail') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('clientEmail')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="companyAddress" className={classNames({ 'p-error': isFormFieldValid('companyAddress') }, 'Label__Text')}>
                                    Company Address
                                </label>
                                <InputTextarea
                                    disabled
                                    id="companyAddress"
                                    placeholder="Enter company address"
                                    name="companyAddress"
                                    value={formik?.values?.companyAddress?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('companyAddress') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('companyAddress')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="salaryStatus" className={classNames({ 'p-error': isFormFieldValid('salaryStatus') }, 'Label__Text')}>
                                    Salary Status
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="salaryStatus" disabled name="salaryStatus" value={formik?.values?.salaryStatus} checked={formik?.values?.salaryStatus} onChange={formik.handleChange} style={{ marginTop: '10px' }} />
                                    <label htmlFor="salaryStatus" className={classNames({ 'p-error': isFormFieldValid('salaryStatus') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        active
                                    </label>
                                </div>
                                {getFormErrorMessage('salaryStatus')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="segmentDescr" className={classNames({ 'p-error': isFormFieldValid('segmentDescr') }, 'Label__Text')}>
                                    Description
                                </label>
                                <InputText
                                    disabled
                                    id="segmentDescr"
                                    placeholder="Enter Description"
                                    name="segmentDescr"
                                    value={formik?.values?.segmentDescr?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('segmentDescr') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('segmentDescr')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} onClick={() => navigate('/segment')} label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={segmentId} tableName={tableName} />
        </>
    );
}

export default ViewSegment;

import React from 'react';
import { Dropdown } from 'primereact/dropdown';

const HraAccountDetails = ({ rowData, userTabsFieldData }) => {
    const checkFieldPermissions = (fieldName) => {
        const field = userTabsFieldData && userTabsFieldData.find((f) => f.fieldName === fieldName);
        return {
            viewAllowed: field?.viewAllowed === 'Y',
            updateAllowed: field?.updateAllowed === 'Y'
        };
    };

    const hraPurposeOfAccountFieldPermissions = checkFieldPermissions('hraPurposeOfAccount');
    const hraOccupationFieldPermissions = checkFieldPermissions('hraOccupation');
    const hraSourceOfIncomeFieldPermissions = checkFieldPermissions('hraSourceOfIncome');
    const hraOriginatorRelationshipNameFieldPermissions = checkFieldPermissions('hraOriginatorRelationshipName');

    return (
        <>
            <div>
                <div className="hra_head">
                    <h5 className="secondary-color">
                        <b>HRA DETAILS</b>
                    </h5>
                </div>

                <div className="p-fluid p-formgrid grid mb-3">
                    {hraPurposeOfAccountFieldPermissions.viewAllowed && (
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Purpose of Account</label>
                                <div className="flex align-items-center">
                                    <Dropdown placeholder={rowData?.hraPurposeOfAccount ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                </div>
                            </div>
                        </div>
                    )}
                    {hraOccupationFieldPermissions.viewAllowed && (
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Occupation</label>
                                <div className="flex align-items-center">
                                    <Dropdown placeholder={rowData?.hraOccupation ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                </div>
                            </div>
                        </div>
                    )}
                    {hraSourceOfIncomeFieldPermissions.viewAllowed && (
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Source of Income</label>
                                <div className="flex align-items-center">
                                    <Dropdown placeholder={rowData?.hraSourceOfIncome ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                </div>
                            </div>
                        </div>
                    )}
                    {/* {hraInternationalRemittanceLocationFieldPermissions.viewAllowed && (
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">International Remittance Location</label>
                                <div className="flex align-items-center">
                                    <Dropdown
                                        placeholder={rowData?.hraInternationalRemittanceLocation ?? ""}
                                        className="Dropdown__Round mr-2"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    )} */}
                    {hraOriginatorRelationshipNameFieldPermissions.viewAllowed && (
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Relationship with Originator</label>
                                <div className="flex align-items-center">
                                    <Dropdown placeholder={rowData?.hraOriginatorRelationshipName} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default HraAccountDetails;

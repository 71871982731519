import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DefaultComponent from './DefaultComponent';
import { handleGetRequest } from '../../../../services/GetTemplate';

function CheckerApis({ type }) {
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');
    const [getByMCRequestIdData, setGetByMCRequestIdData] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [urlData, setUrlData] = useState([]);
    const [discrepantStatusList, setDiscrepantStatusList] = useState([]);

    const funcGetByMCRequestIdData = async () => {
        const resp = await handleGetRequest(`/account/v1/customeraccounts/getcustomerbymcrequestid/${mcRequestId}`);
        const keyData = resp?.payLoad;
        setGetByMCRequestIdData(keyData);
    };

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };

    const getDiscrepantStatusList = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getDocStatus/LKP_DOC_STATUS:*');
        setDiscrepantStatusList(res?.payLoad);
    };

    useEffect(() => {
        setUrlData({ refTableId, requestType, mcRequestId, mcPeindingRequestId, requestTypeDescr, tableName });
        funcGetByMCRequestIdData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getDiscrepantStatusList();
        getStatusLov();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setAllData({ getByMCRequestIdData, statusLovData, urlData });
    }, [getByMCRequestIdData, statusLovData, urlData]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <DefaultComponent type={type} responseData={allData} statusLovData={statusLovData} discrepantStatusList={discrepantStatusList} />
        </React.Fragment>
    );
}

export default CheckerApis;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

function ViewSms() {
    const location = useLocation();

    const { rowData } = location.state || {};
    const tableName = 'TBL_CMS_SMS_CONFIG';
    const [loading, setloading] = useState(false);
    // const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [useCaseData, setUseCaseData] = useState([]);
    const [SMSType, setSMSType] = useState([]);

    const getUseCase = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_USECASE');
        setUseCaseData(resp?.payLoad);
    };

    const getSMSType = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_SMS_TYPE');
        setSMSType(resp?.payLoad);
    };

    useEffect(() => {
        getSMSType();
        getUseCase();
    }, []); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            cmsSmsId: '',
            cmsSmsTypeId: '',
            smsTitle: '',
            smsText: '',
            smsDatetime: '',
            cmsUsecaseId: '',
            isActive: ''
        }
    });

    const getMainCategoryData = async () => {
        setloading(true);
        const res = await handleGetRequest(`/cms/v1/cms/getsmsbyid/${rowData.cmsSmsId}`);
        const keyData = res?.payLoad;
        if (keyData) {
            formik.setFieldValue('smsTitle', keyData?.smsTitle || '');
            formik.setFieldValue('smsText', keyData?.smsText || '');
            formik.setFieldValue('smsContent', keyData?.smsContent || '');
            formik.setFieldValue('cmsSmsId', keyData?.cmsSmsId || '');

            const formattedTime = (keyData?.smsDatetime && formattedDate(keyData?.smsDatetime)) || '';
            formik.setFieldValue('smsDatetime', formattedTime || '');

            const okiiProduct = keyData?.cmsUsecaseId;
            const newProduct = useCaseData?.find((option) => option?.lovId == okiiProduct);
            formik.setFieldValue('cmsUsecaseId', newProduct?.lovId || '');

            const okicmsSmsTypeId = keyData?.cmsSmsTypeId;
            const necmsSmsTypeId = SMSType?.find((option) => option?.lovId == okicmsSmsTypeId);
            formik.setFieldValue('cmsSmsTypeId', necmsSmsTypeId?.lovId || '');
        }
        setloading(false);
    };

    useEffect(() => {
        getMainCategoryData();
    }, [rowData, useCaseData]); // eslint-disable-line

    const formattedDate = (dateString) => {
        return moment(dateString).format('YYYY-MM-DDTHH:mm');
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>VIEW DETAILS</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsSmsTypeId" className={classNames({ 'p-error': isFormFieldValid('cmsSmsTypeId') }, 'Label__Text')}>
                                    SMS Type <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="cmsSmsTypeId"
                                    placeholder="Promotions"
                                    options={SMSType}
                                    optionLabel="name"
                                    name="cmsSmsTypeId"
                                    optionValue="lovId"
                                    value={formik.values.cmsSmsTypeId || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsSmsTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsSmsTypeId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="smsTitle" className={classNames({ 'p-error': isFormFieldValid('smsTitle') }, 'Label__Text')}>
                                    Title <span className="Label__Required">*</span>
                                </label>
                                <InputText maxLength={100} id="smsTitle" placeholder="" name="smsTitle" value={formik?.values?.smsTitle || ''} disabled className={classNames({ 'p-invalid': isFormFieldValid('smsTitle') }, 'Input__Round')} />
                                {getFormErrorMessage('smsTitle')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="smsDatetime" className={classNames({ 'p-error': isFormFieldValid('smsDatetime') }, 'Label__Text')}>
                                    Date & Time
                                </label>

                                <input
                                    id="smsDatetime"
                                    placeholder="Enter Start Date & Time"
                                    name="smsDatetime"
                                    disabled
                                    type="datetime-local"
                                    min={moment().format('YYYY-MM-DDTHH:mm')}
                                    value={formik.values.smsDatetime}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('smsDatetime') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('smsDatetime')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsUsecaseId" className={classNames({ 'p-error': isFormFieldValid('cmsUsecaseId') }, 'Label__Text')}>
                                    Use Case<span className="Label__Required"></span>
                                </label>
                                <Dropdown
                                    id="cmsUsecaseId"
                                    placeholder="Select Use Case"
                                    options={useCaseData}
                                    optionLabel="name"
                                    name="cmsUsecaseId"
                                    optionValue="lovId"
                                    value={formik.values.cmsUsecaseId || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsUsecaseId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsUsecaseId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="smsText" className={classNames({ 'p-error': isFormFieldValid('smsText') }, 'Label__Text')}>
                                    SMS Text <span className="Label__Required">*</span>
                                </label>
                                <InputText maxLength={100} id="smsText" placeholder="Enter SMS Text" name="smsText" value={formik?.values?.smsText || ''} disabled className={classNames({ 'p-invalid': isFormFieldValid('smsText') }, 'Input__Round')} />
                                {getFormErrorMessage('smsText')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button disabled={loading} onClick={() => navigate('/sms')} type="submit" label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={rowData.cmsSmsId} tableName={tableName} />
        </>
    );
}

export default ViewSms;

import React from 'react';
import MakerApis from '../Components/MakerApis';

function EditCxRoleMgmt() {
    return (
        <React.Fragment>
            <MakerApis type="edit" />
        </React.Fragment>
    );
}
export default EditCxRoleMgmt;

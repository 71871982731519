import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const EditNadra = () => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const { rowData } = location.state || {};

    const validationSchema = Yup.object().shape({
        attempts: Yup.string().required('This field is required'),

        idLimit: Yup.string().required('This field is required'),
        bvsLimit: Yup.string().required('This field is required'),
        blockTime: Yup.number()
            .nullable()
            .when('isTemporaryBlock', {
                is: 'Y',
                then: Yup.number()
                    .typeError('Input must be a number')
                    // .max(24, 'Input must be less than or equal to 24 hours')

                    .transform((value, originalValue) => (/^\d+(\.\d)?$/.test(originalValue) ? parseFloat(originalValue) : value))

                    .required('This field is required')
                    .test('is-one-decimal', 'Input must have only one digit after decimal point', (value) => /^\d+(\.\d)?$/.test(value))
            }),
        attemptsTime: Yup.number()
            .nullable()
            .typeError('Input must be a number')

            // .max(24, 'Input must be less than or equal to 24 hours')

            .transform((value, originalValue) => (/^\d+(\.\d)?$/.test(originalValue) ? parseFloat(originalValue) : value))
            .required('This field is required')
            .test('is-one-decimal', 'Input must have only one digit after decimal point', (value) => /^\d+(\.\d)?$/.test(value))
    });
    const formik = useFormik({
        validationSchema: validationSchema,

        initialValues: {
            attempts: '',
            bvsLimit: '',
            idLimit: '',
            blockTime: '',
            attemptsTime: '',
            isTemporaryBlock: '',
            isPermanentBlock: false,
            isActive: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        cmsNadraConfigId: rowData?.cmsNadraConfigId,
                        attempts: data?.attempts,
                        bvsLimit: data?.bvsLimit,
                        idLimit: data?.idLimit,
                        blockTime: data?.blockTime,
                        attemptsTime: data?.attemptsTime,
                        isTemporaryBlock: formik?.values?.isTemporaryBlock === true ? 'Y' : 'N',
                        isPermanentBlock: formik?.values?.isPermanentBlock === true ? 'Y' : 'N',
                        // isActive: data?.isActive,
                        isActive: null
                    },

                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            await dispatch(handlePostRequest(newData, `/cms/v1/cms/updatenadraconfig/`, false, false, 'nadra'));
            setloadingIcon('pi pi-search');
        }
    });

    const getRequestTypeData = async () => {
        setloading(true);
        const res = await handleGetRequest(`/cms/v1/cms/getnadraconfigbyid/${rowData?.cmsNadraConfigId}`, true);

        const keyData = res?.payLoad;
        formik.setFieldValue('attempts', keyData?.attempts);
        formik.setFieldValue('attemptsTime', keyData?.attemptsTime);

        formik.setFieldValue('isTemporaryBlock', keyData?.isTemporaryBlock === 'Y' ? true : false);
        formik.setFieldValue('isPermanentBlock', keyData?.isPermanentBlock === 'Y' ? true : false);
        formik.setFieldValue('bvsLimit', keyData?.bvsLimit);
        formik.setFieldValue('idLimit', keyData?.idLimit);
        formik.setFieldValue('blockTime', keyData?.blockTime);
        formik.setFieldValue('isActive', keyData?.isActive);

        setloading(false);
    };

    useEffect(() => {
        getRequestTypeData();
    }, []); // eslint-disable-line

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>EDIT DETAILS</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="bvsLimit" className={classNames({ 'p-error': isFormFieldValid('bvsLimit') }, 'Label__Text')}>
                                    Bvs Limit
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="bvsLimit"
                                    placeholder="Enter bvs Limit"
                                    name="bvsLimit"
                                    value={formik?.values.bvsLimit}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('bvsLimit') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('bvsLimit')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="idLimit" className={classNames({ 'p-error': isFormFieldValid('idLimit') }, 'Label__Text')}>
                                    ID Limit
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    type="text"
                                    id="email"
                                    placeholder="Enter Id Limit"
                                    name="idLimit"
                                    value={formik?.values?.idLimit}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('idLimit') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('idLimit')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') }, 'Label__Text')}>
                                    Attempts
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    type="text"
                                    id="attempts"
                                    placeholder="Enter attempts"
                                    name="attempts"
                                    value={formik?.values?.attempts}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('attempts') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('attempts')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4  pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="attemptsTime" className={classNames({ 'p-error': isFormFieldValid('attemptsTime') }, 'Label__Text')}>
                                    Enter Time<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="attemptsTime"
                                    keyfilter="int"
                                    placeholder="Enter Time In Minutes"
                                    type="text"
                                    name="attemptsTime"
                                    value={formik?.values?.attemptsTime}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('time') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('attemptsTime')}
                            </div>
                        </div>

                        <div className="Form__Header Full__Width">
                            <h1>Breach Limits</h1>
                        </div>

                        <div className="p-field col-12 md:col-8 pt-3 pb-3 flex flex-row justify-content-space-between">
                            <div className="p-field col-12 md:col-4">
                                <div className="field-checkbox flex mb-2">
                                    <Checkbox
                                        inputId="isTemporaryBlock"
                                        name="isTemporaryBlock"
                                        value={formik?.values?.isTemporaryBlock}
                                        checked={formik?.values?.isTemporaryBlock}
                                        onChange={() => {
                                            formik.setFieldValue('isTemporaryBlock', !formik?.values?.isTemporaryBlock);
                                            formik.setFieldValue('isPermanentBlock', !formik?.values?.isPermanentBlock);
                                            formik.setFieldValue('blockTime', '');
                                        }}
                                        style={{ marginTop: '10px' }}
                                    />
                                    <label htmlFor="isTemporaryBlock" className={classNames({ 'p-error': isFormFieldValid('isTemporaryBlock') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Temporary Block
                                    </label>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4">
                                <label htmlFor="blockTime" className={classNames({ 'p-error': isFormFieldValid('blockTime') }, 'Label__Text')}>
                                    Time
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    type="text"
                                    id="blockTime"
                                    placeholder="Enter Time In Minutes"
                                    name="blockTime"
                                    keyfilter="int"
                                    value={formik?.values?.blockTime}
                                    disabled={formik.values.isPermanentBlock}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('blockTime') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('blockTime')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>

                        <div className="p-field col-12 md:col-4">
                            <div className="field-checkbox flex mb-2">
                                <Checkbox
                                    inputId="isPermanentBlock"
                                    name="isPermanentBlock"
                                    value={formik?.values?.isPermanentBlock}
                                    checked={formik?.values?.isPermanentBlock}
                                    onChange={() => {
                                        formik.setFieldValue('isTemporaryBlock', !formik?.values?.isTemporaryBlock);
                                        formik.setFieldValue('isPermanentBlock', !formik?.values?.isPermanentBlock);
                                        formik.setFieldValue('blockTime', '');
                                    }}
                                    style={{ marginTop: '10px' }}
                                />
                                <label htmlFor="isPermanentBlock" className={classNames({ 'p-error': isFormFieldValid('isPermanentBlock') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                    Permanent Block
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon || ''} type="submit" label="Update" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/nadra')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
};

export default EditNadra;

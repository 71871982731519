import React, { useState } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { handlePostRequest } from '../../../../services/PostTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';

function TierChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-save');
    const [statusLovData, setStatusLovData] = useState([]);
    const [dataById, setDataById] = useState();
    const [reqTypeUData, setReqTypeUData] = useState();

    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const tableName = query.get('tableName');
    const reqType = query.get('requestType');
    const mcReqId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');

    const getDatabyId = async () => {
        const response = await handleGetRequest(`/zconfig/v1/zmiles/getTier/${refTableId}`);
        setDataById(response?.payLoad);

        formik.setFieldValue('tier', response?.payLoad?.tier);
        formik.setFieldValue('tierRange', response?.payLoad?.maxTierRange);
        formik.setFieldValue('multiplier', response?.payLoad?.multiplier);
        formik.setFieldValue('zmilesTierId', response?.payLoad?.zmilesTierId);
        formik.setFieldValue('isActive', response?.payLoad?.isActive);
    };

    const getDatabyIdTypeU = async () => {
        const response = await handleGetRequest(`/zconfig/v1/zmiles/getTiersUpdateCheckerById/${mcReqId}`);
        setReqTypeUData(response?.payLoad);

        formik.setFieldValue('tier', response?.payLoad?.tier);
        formik.setFieldValue('tierRange', response?.payLoad?.maxTierRange);
        formik.setFieldValue('multiplier', response?.payLoad?.multiplier);
        formik.setFieldValue('isActive', response?.payLoad?.isActive);
        formik.setFieldValue('zmilesTierId', response?.payLoad?.zmilesTierId);
    };

    useEffect(() => {
        if (reqType === 'I') {
            getDatabyId();
        } else if (reqType === 'U') {
            getDatabyIdTypeU();
        }
    }, []); // eslint-disable-line

    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('This field is required.'),
        checkerComments: Yup.string().required('This field is required.')
    });

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        getStatusLov();
    }, []);

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            tier: '',
            tierRange: '',
            multiplier: '',
            isActive: '',
            statusId: 0,
            appUserId: 1,
            action: '',
            checkerComments: '',
            zmilesTierId: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        zmilesTierRequest: {
                            tier: data['tier'],
                            isActive: 'Y',
                            statusId: 0,
                            tierRange: data['tierRange'],
                            multiplier: data['multiplier'],
                            zmilesTierId: data['zmilesTierId']
                        },
                        mcActionRequest: {
                            mcRequestId: mcReqId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, `/zconfig/v1/zmiles/tierCheckerAction`, true, false));
            if (res?.responseCode === '330000') {
                formik.resetForm();
                navigate('/requestchecker');
            }
            setloading(false);
            setloadingIcon('pi pi-save');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="tier" className={classNames({ 'p-error': isFormFieldValid('tier') }, 'Label__Text')}>
                                    Tier<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    id="tier"
                                    placeholder="Enter Tier"
                                    name="tier"
                                    value={formik?.values?.tier?.trim()?.toString()}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('tier') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('tier')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="tierRange" className={classNames({ 'p-error': isFormFieldValid('tierRange') }, 'Label__Text')}>
                                    Max Tier Range<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    id="tierRange"
                                    placeholder="Enter Max Tier Range"
                                    name="tierRange"
                                    value={formik?.values?.tierRange || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('tierRange') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('tierRange')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="multiplier" className={classNames({ 'p-error': isFormFieldValid('multiplier') }, 'Label__Text')}>
                                    Multiplier<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    id="multiplier"
                                    placeholder="Enter Tier"
                                    name="multiplier"
                                    value={formik?.values?.multiplier || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('multiplier') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('multiplier')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    optionDisabled={(option) => option?.name === 'Pending'}
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    maxLength={200}
                                    rows={5}
                                    cols={30}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} label="Update" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default TierChecker;

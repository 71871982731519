import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewCardInstallmentPlan() {
    const location = useLocation();
    const { rowData } = location?.state;
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [feeType, setFeeType] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const tableName = 'TBL_DC_INSTALLMENT_PLAN';
    const dcInstallmentPlanId = rowData?.dcInstallmentPlanId;

    const formik = useFormik({
        initialValues: {
            installmentPlanName: '',
            lkpDcFeeTypeId: '',
            installmentPlan: '',
            noOfInstallment: '',
            dcInstallmentPlanId: rowData?.dcInstallmentPlanId
        }
    });

    const getInstallmentPlanData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getinstallmentplandata');
        const feeTypeList = res?.payLoad?.feeType?.map((item) => {
            // save only those whose isActive is Y
            if (item.isActive === 'Y') {
                return item;
            }
        });
        setFeeType(feeTypeList);
    };

    useEffect(() => {
        getInstallmentPlanData();
    }, []);

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        if (rowData) {
            formik.setFieldValue('installmentPlanName', rowData?.installmentPlanName);
            formik.setFieldValue('lkpDcFeeTypeId', rowData?.dcFeeTypeId?.toString());
            formik.setFieldValue('installmentPlan', rowData?.installmentPlan);
            formik.setFieldValue('noOfInstallment', rowData?.noOfInstallments);
        }
    }, [rowData]);

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>VIEW DETAIL</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="installmentPlanName" className={classNames({ 'p-error': isFormFieldValid('installmentPlanName') }, 'Label__Text')}>
                                    Installment Name
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={50}
                                    minLength={3}
                                    id="installmentPlanName"
                                    name="installmentPlanName"
                                    placeholder="Enter Installment Name"
                                    value={formik?.values?.installmentPlanName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('installmentPlanName') }, 'Input__Round')}
                                    disabled
                                />
                                {getFormErrorMessage('installmentPlanName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lkpDcFeeTypeId" className={classNames({ 'p-error': isFormFieldValid('lkpDcFeeTypeId') }, 'Label__Text')}>
                                    Fee Type
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="lkpDcFeeTypeId"
                                    options={feeType}
                                    optionLabel="name"
                                    name="lkpDcFeeTypeId"
                                    placeholder="Select Fee Type"
                                    optionValue="lovId"
                                    value={formik.values.lkpDcFeeTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpDcFeeTypeId') }, 'Dropdown__Round')}
                                    disabled
                                />
                                {getFormErrorMessage('lkpDcFeeTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="installmentPlan" className={classNames({ 'p-error': isFormFieldValid('installmentPlan') }, 'Label__Text')}>
                                    Installment Plan
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="installmentPlan"
                                    options={[
                                        { name: 'Weekly', lovId: 'W' },
                                        { name: 'Monthly', lovId: 'M' },
                                        { name: 'Yearly', lovId: 'Y' }
                                    ]}
                                    optionLabel="name"
                                    name="installmentPlan"
                                    placeholder="Select Installment Plan"
                                    optionValue="lovId"
                                    value={formik.values.installmentPlan || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('installmentPlan') }, 'Dropdown__Round')}
                                    disabled
                                />
                                {getFormErrorMessage('installmentPlan')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="noOfInstallment" className={classNames({ 'p-error': isFormFieldValid('noOfInstallment') }, 'Label__Text')}>
                                    No. of Installment
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={3}
                                    id="noOfInstallment"
                                    name="noOfInstallment"
                                    placeholder="Enter No. of Installment"
                                    value={formik?.values?.noOfInstallment || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('noOfInstallment') }, 'Input__Round')}
                                    disabled
                                />
                                {getFormErrorMessage('noOfInstallment')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} onClick={() => navigate('/cardinstallmentplan')} label="Okay" type="submit" className="Btn__Dark" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={dcInstallmentPlanId} tableName={tableName} />
        </>
    );
}
export default ViewCardInstallmentPlan;

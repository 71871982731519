import React from 'react';

const dateFormat = (rowData) => {
    const dateStr = rowData;
    if (!dateStr || dateStr.length !== 6) {
        return <p>Invalid Date</p>;
    }
    const dd = dateStr.slice(0, 2);
    const mm = dateStr.slice(2, 4);
    const yy = dateStr.slice(4, 6);
    const formattedDate = `${dd}/${mm}/${yy}`;
    return <p>{formattedDate}</p>;
};

const createdDateAndTimeBodyTemplate = (rowData) => {
    const isoDate = rowData;
    const date = new Date(isoDate);

    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    const yyyy = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');

    let ampm = 'AM';
    if (hours >= 12) {
        ampm = 'PM';
        hours -= 12;
    }
    if (hours === 0) {
        hours = 12;
    }

    const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
    return <p>{formattedDateTime}</p>;
};

export { dateFormat, createdDateAndTimeBodyTemplate };

import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';

const ManyToOneAccFT = ({ manyToOneAccFT }) => {

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});


    useEffect(() => {

        const tohfaArr = manyToOneAccFT && manyToOneAccFT.map((mtoaft) => { return mtoaft?.tohfa })
        const ibftArr = manyToOneAccFT && manyToOneAccFT.map((mtoaft) => { return mtoaft?.ibft })
        const fundTransferArr = manyToOneAccFT && manyToOneAccFT.map((mtoaft) => { return mtoaft?.foundTransfer })
        const dateArr = manyToOneAccFT && manyToOneAccFT.map((mtoaft) => { return mtoaft?.transectionDate.split(" ")[0] })

        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: dateArr,
            datasets: [
                {
                    type: 'bar',
                    label: 'Tohfa',
                    backgroundColor: "rgb(200,246,247)",
                    data: tohfaArr
                },
                {
                    type: 'bar',
                    label: 'IBFT',
                    backgroundColor: "rgb(216,239,170)",
                    data: ibftArr
                },
                {
                    type: 'bar',
                    label: 'Fund Transfer',
                    backgroundColor: "rgb(247,218,123)",
                    data: fundTransferArr
                }
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);

    }, [manyToOneAccFT]);

    return (
        <div className="col-12 md:col-4">
            <div className="card Card__Round">
                <div className="cardBarCharts">
                    <h5>Many to 1 Account Fund Transfer</h5>
                    <div className="pt-3">
                        <Chart type="bar" data={chartData} options={chartOptions} className="w-full" style={{ "height": "35rem" }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManyToOneAccFT
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CommissionHierarchy from './CommissionHierarchy';
import { handleGetRequest } from '../../../services/GetTemplate';

function CheckerApis({ type }) {
    // Getting params from URL using hash router
    const dispatch = useDispatch();
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');
    const [dataFromId, setDataFromId] = useState([]);
    const [dataFromApi, setDataFromApi] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [urlData, setUrlData] = useState([]);

    const getDatabyId = async () => {
        const resp = await handleGetRequest(`/pricingprofile/v1/commissionprofile/getcommissionhierarchybyid/${refTableId}`);
        const keyData = resp?.payLoad;
        setDataFromId(keyData);
    };

    const getMcRequestDataById = async () => {
        const resp = await handleGetRequest(`/pricingprofile/v1/commissionprofile/getcommissionhierarchybymcrequestid/${mcRequestId}`);
        const resp2 = await handleGetRequest(`/pricingprofile/v1/commissionprofile/getcommissionhierarchybyid/${refTableId}`);
        const keyData = resp?.payLoad;
        const updatedArray = resp2?.payLoad?.hierarchyName;
        setDataFromApi({ ...keyData, hierarchyName: updatedArray });
    };

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        setUrlData({ refTableId, requestType, mcRequestId, mcPeindingRequestId, requestTypeDescr, tableName });
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestTypeDescr === 'Enable/Disable Request') {
            getMcRequestDataById();
            getDatabyId();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, [requestType]);

    useEffect(() => {
        getStatusLov();
    }, []);

    useEffect(() => {
        setTableData({ dataFromId, dataFromApi, statusLovData, urlData });
    }, [dataFromId, dataFromApi, statusLovData, urlData]);

    return (
        <React.Fragment>
            <CommissionHierarchy type={type} dataForTable={tableData} />
        </React.Fragment>
    );
}

export default CheckerApis;

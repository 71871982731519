import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { MultiSelect } from 'primereact/multiselect';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import { Sidebar } from 'primereact/sidebar';
import FixedCommissionOldNewValues from './FixedCommissionOldNewValues';

function EditFixedCommissionChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [statusLovData, setStatusLovData] = useState([]);
    const [allLovs, setAllLovs] = useState([]);
    const [requestedDataById, setRequestedDataByID] = useState();
    // const [productListData, setProductListData] = useState([]);
    const [transactions, setTransactions] = useState([]);

    const [oldNewData, setOldNewData] = useState([]);
    const [highlightedKeys, setHighlightedKeys] = useState([]);
    const [fixedCommissionRowData, setFixedCommissionRowData] = useState();
    const [displayDialog, setDisplayDialog] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1); // Subtract 1 day
    // const previousDate = currentDate.toISOString().split('T')[0];

    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };

    const getAllLovsForCommission = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcommissionprofiledata');
        setAllLovs(resp?.payLoad);
        setTransactions(resp?.payLoad?.transactions);
    };

    useEffect(() => {
        getStatusLov();
        getAllLovsForCommission();
    }, []); // eslint-disable-line

    const getDatabyId = async () => {
        const resp = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getcommissionprofilebyid/${refTableId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik?.setFieldValue(key, keyData[key]);
                }
            });
            const multiselectDataProduct = keyData?.tblCommissionProducts?.map((item) => {
                return item?.tblProduct?.productId.toString(); // Convert to string
            });
            formik.setFieldValue('disbursementMethodName', keyData?.lkpDisbursementMethod?.disbursementMethodName?.toString());
            formik.setFieldValue('accountClassificationId', keyData?.lkpAccountClassification?.accountClassificationId?.toString());
            formik.setFieldValue('commissionGlAccountId', keyData?.tblAccount1.accountId.toString());
            // formik.setFieldValue('statusId', keyData?.lkpStatus?.statusId.toString());
            formik.setFieldValue('whtGlAccountId', keyData?.tblAccount2.accountId.toString());
            formik.setFieldValue('startDate', keyData?.startDate?.slice(0, 10));
            formik.setFieldValue('endDate', keyData?.endDate?.slice(0, 10));

            if (multiselectDataProduct && multiselectDataProduct.length > 0) {
                formik.setFieldValue('products', multiselectDataProduct);
            }
        }
    };

    const getMcRequestDataById = async () => {
        setloading(true);

        const res = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getCommissionProfileCheckerById/${mcRequestId}`);
        const keyData = res?.payLoad;

        setloading(false);

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            const multiselectDataProduct = keyData?.products?.map((item) => {
                return item?.productId.toString(); // Convert to string
            });
            formik.setFieldValue('disbursementMethodName', keyData?.disbursementMethodName?.toString());
            formik.setFieldValue('accountClassificationId', keyData?.accountClassificationId.toString());
            formik.setFieldValue('commissionGlAccountId', keyData?.commissionGlAccountId.toString());
            formik.setFieldValue('whtGlAccountId', keyData?.whtGlAccountId?.toString());
            formik.setFieldValue('startDate', keyData?.startDate?.slice(0, 10));
            formik.setFieldValue('endDate', keyData?.endDate?.slice(0, 10));

            if (multiselectDataProduct && multiselectDataProduct.length > 0) {
                formik.setFieldValue('products', multiselectDataProduct);
            }
        }

        const multiselectDataProduct = keyData?.products?.map((item) => {
            return item?.productId.toString(); // Convert to string
        });

        formik.setFieldValue('disbursementMethodName', keyData?.disbursementMethodName?.toString());
        formik.setFieldValue('accountClassificationId', keyData?.accountClassificationId.toString());
        formik.setFieldValue('commissionGlAccountId', keyData?.commissionGlAccountId.toString());
        formik.setFieldValue('whtGlAccountId', keyData?.whtGlAccountId?.toString());
        formik.setFieldValue('commissionPercentage', keyData?.commissionPercentage);
        formik.setFieldValue('startDate', keyData?.startDate?.slice(0, 10));
        formik.setFieldValue('endDate', keyData?.endDate?.slice(0, 10));

        if (multiselectDataProduct && multiselectDataProduct.length > 0) {
            formik.setFieldValue('products', multiselectDataProduct);
        }
    };

    const getMcRequestDataByIdU = async () => {
        setloading(true);

        const res = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getCommissionProfileCheckerById/${mcRequestId}`);
        const keyData = res?.payLoad;

        setloading(false);

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
        }
        setRequestedDataByID(res?.payLoad);
    };

    const getRequestedFixedCommissionData = async () => {
        const resp = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getcommissionprofilebyid/${requestedDataById?.commissionProfileId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik?.setFieldValue(key, keyData[key]);
                }
            });

            const multiselectDataProduct = keyData?.tblCommissionProducts?.map((item) => {
                return item?.tblProduct?.productId.toString(); // Convert to string
            });
            formik.setFieldValue('disbursementMethodName', keyData?.lkpDisbursementMethod?.disbursementMethodName?.toString());
            formik.setFieldValue('accountClassificationId', keyData?.lkpAccountClassification?.accountClassificationId?.toString());
            formik.setFieldValue('commissionGlAccountId', keyData?.tblAccount1.accountId.toString());
            // formik.setFieldValue('statusId', keyData?.lkpStatus?.statusId.toString());
            formik.setFieldValue('whtGlAccountId', keyData?.tblAccount2.accountId.toString());
            if (multiselectDataProduct && multiselectDataProduct.length > 0) {
                formik.setFieldValue('products', multiselectDataProduct);
            }
        }
    };

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getMcRequestDataById();
        } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
            getMcRequestDataByIdU();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        if (requestTypeDescr === 'Enable/Disable Request' && requestType === 'U' && requestedDataById?.commissionProfileId !== undefined) {
            getRequestedFixedCommissionData();
        }
    }, [requestedDataById?.commissionProfileId]); // eslint-disable-line

    const accounttype = allLovs?.accounttype?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });
    const glAccounts = allLovs?.glAccounts?.map((data) => {
        return {
            accountId: data?.accountId,
            accountNo: `${data?.accountNo}-${data?.accountTitle}`
        };
    });
    const disbursementMethod = allLovs?.disbursementMethod?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });

    const validationSchema = Yup.object().shape({
        action: Yup.mixed().required('This field is required.'),
        checkerComments: Yup.mixed().required('This field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            commissionProfileId: refTableId,
            accountClassificationId: '',
            commissionProfileName: '',
            commissionGlAccountId: '',
            products: [],
            commissionAmount: '',
            disbursementMethodName: '',
            disbursementTime: '',
            whtCalculationType: '',
            startDate: '',
            endDate: '',
            isActive: false,
            action: '',
            checkerComments: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['feeTypeCode'] = 'FF';

            const productData = data?.products?.map((value) => value);

            formik.setFieldValue('products', productData);
            data['products'] = data.products.map((productId) => ({
                productId: productId
            }));

            data['disbursementTime'] = '';

            data['isActive'] = requestedDataById?.isActive;
            if (requestTypeDescr !== 'Enable/Disable Request') {
                delete data['isActive'];
            }

            // data["products"] = productListData;
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        commissionProfileRequest: {
                            accountClassificationId: data['accountClassificationId'],
                            commissionAmount: data['commissionAmount'],
                            commissionGlAccountId: data['commissionGlAccountId'],
                            commissionProfileId: data['commissionProfileId'],
                            commissionProfileName: data['commissionProfileName'],
                            disbursementMethodName: data['disbursementMethodName'],
                            disbursementTime: data['disbursementTime'],
                            endDate: data['endDate'],
                            feeTypeCode: data['feeTypeCode'],
                            products: data['products'],
                            startDate: data['startDate'],
                            whtCalculationType: data['whtCalculationType'],
                            whtGlAccountId: data['whtGlAccountId'],
                            isActive: data['isActive']
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(newData, '/pricingprofile/v1/pricingprofile/commissionprofileCheckerAction', true, false, 'requestchecker'));
            if (res?.responseCode === '110000') {
                formik.resetForm();
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const addCommissionFee = () => {
        formik.setFieldValue('fixedCommision', [
            ...formik.values.fixedCommision,
            {
                comfee: ''
            }
        ]);
    };

    const removeCommissionFee = (index) => {
        if (index > 0) {
            const updatedData = [...formik.values.fixedCommision];
            updatedData.splice(index, 1);
            formik.setFieldValue('fixedCommision', updatedData);
        }
    };
    const WhtOption = [
        { name: 'INCLUSIVE', value: 'I' },
        { name: 'EXCLUSIVE', value: 'E' }
    ];

    const getOldNewData = async () => {
        const resp = await handleGetRequest(`/workflow/v1/management/compareValues/${mcRequestId}`);
        setOldNewData(resp?.payLoad);
    };

    useEffect(() => {
        if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getOldNewData();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        const keys = oldNewData?.map((item) => item.key);
        setHighlightedKeys(keys);
    }, [oldNewData]); // eslint-disable-line

    const HandledOldValue = async (e) => {
        e.preventDefault();
        setDisplayDialog(true);
        const response = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getcommissionprofilebyid/${refTableId}`);
        setFixedCommissionRowData(response?.payLoad);
    };

    return (
        <>
            <Sidebar header="View Pricing Fixed" position="right" style={{ width: '80vw' }} visible={displayDialog} onHide={() => setDisplayDialog(false)}>
                <FixedCommissionOldNewValues fixedCommissionRowData={fixedCommissionRowData} />
            </Sidebar>

            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>EDIT DETAILS</h1>
                    {requestType === 'U' && <Button label="View Old Details" className="Btn__Dark__OldValue" onClick={HandledOldValue} />}
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                    Account Type
                                </label>
                                <Dropdown
                                    disabled
                                    id="accountClassificationId"
                                    placeholder="Select Account Type"
                                    options={accounttype}
                                    optionLabel="name"
                                    name="accountClassificationId"
                                    optionValue="lovId"
                                    value={formik.values.accountClassificationId || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('accountClassificationId')
                                            ? classNames({ 'p-invalid': isFormFieldValid('accountClassificationId') }, 'Dropdown__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('accountClassificationId') }, 'Dropdown__Round')
                                    }
                                />

                                {getFormErrorMessage('accountClassificationId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="profilename" className={classNames({ 'p-error': isFormFieldValid('profilename') }, 'Label__Text')}>
                                    Profile Name <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled
                                    id="commissionProfileName"
                                    placeholder="Enter Profile Name"
                                    name="commissionProfileName"
                                    value={formik?.values?.commissionProfileName || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('commissionProfileName')
                                            ? classNames({ 'p-invalid': isFormFieldValid('commissionProfileName') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('commissionProfileName') }, 'Input__Round')
                                    }
                                />
                                {getFormErrorMessage('commissionProfileName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="products" className={classNames({ 'p-error': isFormFieldValid('products') }, 'Label__Text')}>
                                    Transactions
                                </label>
                                <MultiSelect
                                    filter
                                    // disabled={true}
                                    id="products"
                                    placeholder="Select Transaction"
                                    options={transactions}
                                    optionLabel="name"
                                    name="products"
                                    optionValue="lovId"
                                    value={formik.values.products || []}
                                    // onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('products') ? classNames({ 'p-invalid': isFormFieldValid('products') }, 'Dropdown__Round__Highlighted') : classNames({ 'p-invalid': isFormFieldValid('products') }, 'Dropdown__Round')
                                    }
                                />
                                {getFormErrorMessage('products')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="whtCalculationType" className={classNames({ 'p-error': isFormFieldValid('whtCalculationType') }, 'Label__Text')}>
                                    Type of WHT Calculation
                                    <span className="Label__Required"></span>
                                </label>
                                <Dropdown
                                    disabled
                                    id="whtCalculationType"
                                    placeholder="Select Type of WHT Calculation"
                                    options={WhtOption}
                                    optionLabel="name"
                                    name="whtCalculationType"
                                    optionValue="value"
                                    value={formik.values.whtCalculationType}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('whtCalculationType')
                                            ? classNames({ 'p-invalid': isFormFieldValid('whtCalculationType') }, 'Dropdown__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('whtCalculationType') }, 'Dropdown__Round')
                                    }
                                />

                                {getFormErrorMessage('whtCalculationType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3">
                            <div className="p-field">
                                <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') }, 'Label__Text')}>
                                    Start Date
                                </label>
                                <InputText
                                    disabled
                                    id="startDate"
                                    placeholder=""
                                    name="startDate"
                                    type="date"
                                    value={formik?.values?.startDate?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('startDate') ? classNames({ 'p-invalid': isFormFieldValid('startDate') }, 'Input__Round__Highlighted') : classNames({ 'p-invalid': isFormFieldValid('startDate') }, 'Input__Round')
                                    }
                                />
                                {getFormErrorMessage('startDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3">
                            <div className="p-field">
                                <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') }, 'Label__Text')}>
                                    End Date
                                </label>
                                <InputText
                                    disabled
                                    id="endDate"
                                    placeholder=""
                                    name="endDate"
                                    type="date"
                                    // max={currentDate}
                                    value={formik?.values?.endDate?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={highlightedKeys?.includes('endDate') ? classNames({ 'p-invalid': isFormFieldValid('endDate') }, 'Input__Round__Highlighted') : classNames({ 'p-invalid': isFormFieldValid('endDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('endDate')}
                            </div>
                        </div>
                        <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card">
                            <div className="Form__Header">
                                <h1>FIXED COMMISSION</h1>
                            </div>

                            <div style={{ backgroundColor: '#FFFFFF', padding: '3rem' }} className=" col-12 card">
                                <div className="grid">
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="commissionAmount" className={classNames({ 'p-error': isFormFieldValid('commissionAmount') }, 'Label__Text')}>
                                                Enter Commission Fee <span className="Label__Required"></span>
                                            </label>
                                            <InputText
                                                disabled
                                                id="commissionAmount"
                                                placeholder="Enter Commission Fee"
                                                name="commissionAmount"
                                                value={formik?.values?.commissionAmount || ''}
                                                onChange={formik.handleChange}
                                                className={
                                                    highlightedKeys?.includes('commissionAmount')
                                                        ? classNames({ 'p-invalid': isFormFieldValid('commissionAmount') }, 'Input__Round__Highlighted')
                                                        : classNames({ 'p-invalid': isFormFieldValid('commissionAmount') }, 'Input__Round')
                                                }
                                            />
                                            {getFormErrorMessage('commissionAmount')}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row">
                                <div className="col-6 md:col-3">
                                    <div className="p-field pt-3 pb-3">
                                        <label htmlFor="commissionGlAccountId" className={classNames({ 'p-error': isFormFieldValid('commissionGlAccountId') }, 'Label__Text')}>
                                            Select Commission GL
                                            <span className="Label__Required"></span>
                                        </label>
                                        <Dropdown
                                            disabled
                                            id="commissionGlAccountId"
                                            placeholder="Select Commission GL"
                                            options={glAccounts}
                                            filter
                                            optionLabel="accountNo"
                                            name="commissionGlAccountId"
                                            optionValue="accountId"
                                            value={formik.values.commissionGlAccountId || ''}
                                            onChange={formik.handleChange}
                                            className={
                                                highlightedKeys?.includes('commissionGlAccountId')
                                                    ? classNames({ 'p-invalid': isFormFieldValid('commissionGlAccountId') }, 'Dropdown__Round__Highlighted')
                                                    : classNames({ 'p-invalid': isFormFieldValid('commissionGlAccountId') }, 'Dropdown__Round')
                                            }
                                        />

                                        {getFormErrorMessage('commissionGlAccountId')}
                                    </div>
                                </div>
                                <div className="col-6 md:col-3">
                                    <div className="p-field pt-3 pb-3">
                                        <label htmlFor="whtGlAccountId" className={classNames({ 'p-error': isFormFieldValid('whtGlAccountId') }, 'Label__Text')}>
                                            Select WHT GL
                                            <span className="Label__Required"></span>
                                        </label>
                                        <Dropdown
                                            disabled
                                            id="whtGlAccountId"
                                            placeholder="Select WHT GL"
                                            options={glAccounts}
                                            optionLabel="accountNo"
                                            name="whtGlAccountId"
                                            filter
                                            optionValue="accountId"
                                            value={formik.values.whtGlAccountId || ''}
                                            onChange={formik.handleChange}
                                            className={
                                                highlightedKeys?.includes('whtGlAccountId')
                                                    ? classNames({ 'p-invalid': isFormFieldValid('whtGlAccountId') }, 'Dropdown__Round__Highlighted')
                                                    : classNames({ 'p-invalid': isFormFieldValid('whtGlAccountId') }, 'Dropdown__Round')
                                            }
                                        />

                                        {getFormErrorMessage('whtGlAccountId')}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="disbursementMethodName" className={classNames({ 'p-error': isFormFieldValid('disbursementMethodName') }, 'Label__Text')}>
                                    Select Disbursement Method
                                    <span className="Label__Required"></span>
                                </label>
                                <Dropdown
                                    disabled
                                    id="disbursementMethodName"
                                    placeholder=" Select Disbursement Method"
                                    options={disbursementMethod}
                                    optionLabel="name"
                                    name="disbursementMethodName"
                                    optionValue="name"
                                    value={formik.values.disbursementMethodName || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('disbursementMethodName')
                                            ? classNames({ 'p-invalid': isFormFieldValid('disbursementMethodName') }, 'Dropdown__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('disbursementMethodName') }, 'Dropdown__Round')
                                    }
                                />

                                {getFormErrorMessage('disbursementMethodName')}
                            </div>
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                Is Active
                            </label>
                            <div className="field-checkbox">
                                <Checkbox inputId="isActive" disabled={true} name="isActive" value={requestedDataById?.isActive === 'Y' ? true : false} checked={requestedDataById?.isActive === 'Y' ? true : false} onChange={formik.handleChange} />
                            </div>
                            {getFormErrorMessage('isActive')}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    rows={5}
                                    cols={30}
                                    maxLength={200}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} label="Update" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default EditFixedCommissionChecker;

import React, { useState, useRef } from 'react';

import { useFormik } from 'formik';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import * as Yup from 'yup';


function BulkAccountStatusDownload({}) {
   
    const validationSchema = Yup.object().shape({
   
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const dt = useRef();
 
    const formik = useFormik({
        validationSchema,
        initialValues: {
            services: '',
            product: '',
            dateTo: '',
            batchNumber: '',
            dateFrom: '',
            updatedBy: '',
            createdBy: '',
            statusId: ''
        },

        onSubmit: async (data) => {
            // setloading(true);
            // setloadingIcon('pi pi-spin pi-spinner');
            // const newData = {
            //     data: {
            //         security: {
            //             userName: '',
            //             password: '',
            //             securityToken: ''
            //         },
            //         account: {
            //             msidn: '',
            //             iban: '',
            //             bban: '',
            //             pan: '',
            //             currency: ''
            //         },
            //         channel: '',
            //         terminal: '',
            //         reterivalReferenceNumber: '',
            //         payLoad: data,
            //         additionalInformation: [
            //             {
            //                 infoKey: '',
            //                 infoValue: ''
            //             }
            //         ]
            //         // checkSum: hash
            //     }
            // };

            dt.current.reset();

        }
    });


    return (
        <>
            <div className="Top__Btn Top__Btn__Border">
         
                <div>
                
                   
                </div>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dt}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Account Types"
                     
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={[]}
                        >
                         
                            <Column field="sr" header="Sr #" />

                            <Column field="name" header="Name" />
                            <Column field="cnic" header="CNIC" />
                            <Column field="mobile" header="Mobile" />

                            <Column field="reason" header="Reason" />
                        
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
export default BulkAccountStatusDownload;

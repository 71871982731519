import React, { useState, useEffect } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { useFormik } from 'formik';
import { handleGetRequest } from '../../../services/GetTemplate';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

/* eslint-disable */
const ViewMenu = ({ onViewHide, menuRowData, getMenu }) => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-save');
    const [moduleLovData, setModuleLovData] = useState([]);

    const dispatch = useDispatch();

    const menuId = `${menuRowData?.menuId}`;

    const getModuleLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getallmodules/TBL_MODULE:*');
        setModuleLovData(resp?.payLoad);
    };

    const getMenuByMenuId = async () => {
        const res = await handleGetRequest(`/mfs_user_management/v1/management/getMenuById/${menuId}`);
        const keyData = res?.payLoad;
        keyData.forEach((data) => {
            Object.keys(data).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, data[key]);
                }
            });
        });
        formik.setFieldValue('moduleId', keyData[0]?.moduleId?.toString());
    };

    useEffect(() => {
        getMenuByMenuId();
    }, []);

    useEffect(() => {
        getModuleLov();
    }, []);

    const menuTypeOptions = [
        { label: 'form', value: 'F' },
        { label: 'page', value: 'P' }
    ];

    const validationSchema = Yup.object().shape({
        menuDescr: Yup.string().required('This field is required.'),
        iconName: Yup.string().required('This field is required.'),
        // iconPath: Yup.string().required('This field is required.'),
        menuCode: Yup.string().required('This field is required.'),
        menuUrl: Yup.string().required('This field is required.'),
        menuType: Yup.mixed().required('This field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            menuDescr: '',
            iconName: '',
            // iconPath: '',
            menuCode: '',
            menuUrl: '',
            menuType: ''
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        {/* <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="iconName" className={classNames({ 'p-error': isFormFieldValid('iconName') }, 'Label__Text')}>
                                    Icon Name
                                </label>
                                <InputText
                                    id="iconName"
                                    placeholder="Enter Icon Name"
                                    name="iconName"
                                    disabled={true}
                                    value={formik.values.iconName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('iconName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('iconName')}
                            </div>
                        </div> */}
                        {/* <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="iconPath" className={classNames({ 'p-error': isFormFieldValid('iconPath') }, 'Label__Text')}>
                                    Icon Path
                                </label>
                                <InputText
                                    id="iconPath"
                                    placeholder="Enter Icon Path"
                                    name="iconPath"
                                    disabled={true}
                                    value={formik.values.iconPath || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('iconPath') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('iconPath')}
                            </div>
                        </div> */}
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="menuCode" className={classNames({ 'p-error': isFormFieldValid('menuCode') }, 'Label__Text')}>
                                    Menu Code
                                </label>
                                <InputText
                                    id="menuCode"
                                    placeholder="Enter Menu Code"
                                    name="menuCode"
                                    disabled={true}
                                    value={formik.values.menuCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('menuCode') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('menuCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="menuUrl" className={classNames({ 'p-error': isFormFieldValid('menuUrl') }, 'Label__Text')}>
                                    Menu Path
                                </label>
                                <InputText
                                    id="menuUrl"
                                    placeholder="Enter Menu Path"
                                    name="menuUrl"
                                    disabled={true}
                                    value={formik.values.menuUrl || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('menuUrl') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('menuUrl')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="menuType" className={classNames({ 'p-error': isFormFieldValid('menuType') }, 'Label__Text')}>
                                    Menu Type
                                </label>
                                <Dropdown
                                    id="menuType"
                                    placeholder="Enter Menu Type"
                                    options={menuTypeOptions}
                                    optionLabel="label"
                                    name="menuType"
                                    disabled={true}
                                    value={formik.values.menuType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('menuType') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('menuType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="moduleId" className={classNames({ 'p-error': isFormFieldValid('moduleId') }, 'Label__Text')}>
                                    Module
                                </label>
                                <Dropdown
                                    id="moduleId"
                                    placeholder="Select Module Id"
                                    options={moduleLovData}
                                    optionLabel="name"
                                    name="moduleId"
                                    optionValue="lovId"
                                    disabled={true}
                                    value={formik.values.moduleId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('moduleId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('moduleId')}
                            </div>
                        </div>
                    </div>
                    <div className="p-field">
                        <label htmlFor="menuDescr" className={classNames({ 'p-error': isFormFieldValid('menuDescr') }, 'Label__Text')}>
                            Description
                        </label>
                        <InputTextarea
                            placeholder="Add Descr"
                            id="menuDescr"
                            name="menuDescr"
                            disabled={true}
                            value={formik?.values?.menuDescr?.replace(/\s\s+/g, ' ') || ''}
                            rows={5}
                            cols={10}
                            autoResize="false"
                            onChange={formik.handleChange}
                            className={classNames({ 'p-invalid': isFormFieldValid('menuDescr') }, 'TextArea__Round')}
                        />
                        {getFormErrorMessage('menuDescr')}
                    </div>

                    <div className="Down__Btn__End">
                        <Button disabled={loading} label={'Okay'} onClick={() => onViewHide()} className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
};

export default ViewMenu;

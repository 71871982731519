import { trim } from 'lodash';

function trimData(data) {
    const trimData = data?.data?.payLoad;
    if (trimData) {
        Object.keys(trimData).forEach((item) => {
            if (!Array.isArray(trimData[item]) && typeof trimData[item] !== 'object') {
                if (typeof trimData[item] === 'number' && trimData[item] !== null && trimData[item] !== '') {
                    trimData[item] = parseInt(trim(trimData[item]));
                } else trimData[item] = trim(trimData[item]);
            }
        });
    }
    return data;
}

export { trimData };

import CryptoJS from 'crypto-js';

export const handleEncryptData = (data) => {
    const CryptoJS = require('crypto-js');

    const CRYPTKEY = 'jsagentmateais12';
    const CRYPTIV = 'jsagentmateais12';

    const cryptkey = CryptoJS.enc.Utf8.parse(CRYPTKEY);
    const cryptiv = CryptoJS.enc.Utf8.parse(CRYPTIV);

    var encrypt = CryptoJS.AES.encrypt(JSON.stringify(data), cryptkey, {
        iv: cryptiv,
        mode: CryptoJS.mode.CBC
    });
    const text = encrypt.toString();
    return text;
};

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handleGetRequest } from '../../../../../services/GetTemplate';
import { toast } from 'react-toastify';

function EditRunningFinance({ activeIndex, handleStepClick, lendingData, lendingIdData, stepData1, setStepData3 }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [frequencyPeriodData, setFrequencyPeriodData] = useState([]);

    const [noOfCyclesTableData, setNoOfCyclesTableData] = useState([]);
    const [noOfCyclesData, setNoOfCyclesData] = useState({
        noOfCycle: '',
        type: '',
        price: ''
    });

    const [noOfCyclesError, setNoOfCyclesError] = useState({
        noOfCycle: '',
        type: '',
        price: ''
    });

    /////////////////////////////////////////
    const [runningFinanceTableData, setRunningFinanceTableData] = useState([]);
    const [runningFinanceData, setRunningFinanceData] = useState({
        markupValue: '',
        apr: '',

        gracePeriod: '',
        noOfDaysGracePeriod: '',

        latePayment: '',
        latePaymentType: '',
        latePaymentCharges: '',
        latePaymentOn: '',

        frequencyId: ''
    });
    const [runningFinanceError, setRunningFinanceError] = useState({
        markupValue: '',
        apr: '',

        gracePeriod: '',
        noOfDaysGracePeriod: '',

        latePayment: '',
        latePaymentType: '',
        latePaymentCharges: '',
        latePaymentOn: '',

        frequencyId: ''
    });
    //////////////////////////////

    const [buttons, setButtons] = useState([
        { label: 'Principal Amount', sequence: 1 },
        { label: 'Markup', sequence: 2 },
        { label: 'Fees', sequence: 3 }
    ]);

    const getFrequencyPeriodData = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getdatalov/LKP_FREQUENCY_PERIOD');
        setFrequencyPeriodData(response?.payLoad);
    };

    useEffect(() => {
        getFrequencyPeriodData();
    }, []); // eslint-disable-line

    useEffect(() => {
        let runningFinance = [];
        if (lendingData) {
            if (lendingData?.tblLmsRepaymentMethods?.length > 0) {
                runningFinance = lendingData?.tblLmsRepaymentMethods
                    ?.filter((itm) => itm?.isActive === 'Y')
                    ?.map((item) => {
                        return {
                            markupValue: item?.markupValue,
                            apr: item?.apr,

                            gracePeriod: item?.gracePeriod === 'Y' ? true : false,
                            noOfDaysGracePeriod: item?.noOfGraceDays,

                            latePayment: item?.latePayment === 'Y' ? true : false,
                            latePaymentType: item?.latePaymentType?.toString(),
                            latePaymentCharges: item?.latePaymentCharges,
                            latePaymentOn: item?.latePaymentOn?.toString(),

                            // earlyPayment: item?.earlyPayment === "Y" ? true : false,
                            // earlyPaymentType: item?.earlyPaymentType?.toString(),
                            // earlyPaymentCharges: item?.earlyPaymentCharges,
                            // earlyPaymentOn: item?.earlyPaymentOn?.toString(),

                            frequencyId: item?.lkpFrequencyPeriod?.frequencyPeriodId?.toString(),

                            tblLmsRepaymentCycles: item?.tblLmsRepaymentCycles

                            // downPayment: item?.downPayment === "Y" ? "Y" : "N",
                            // downPaymentCharges: item?.downPaymentCharges,
                        };
                    });

                if (lendingData?.lkpLmsFacilityType?.lmsFacilityTypeCode === 'RF') {
                    setRunningFinanceData(runningFinance[0]);
                } else {
                    setRunningFinanceData([]);
                }
                const noOfCycle = runningFinance[0]?.tblLmsRepaymentCycles?.map((item) => {
                    return {
                        noOfCycle: item?.noOfCycles,
                        type: item?.priceType,
                        price: item?.priceValue
                    };
                });
                setNoOfCyclesTableData(noOfCycle);
            }

            let pA = [];
            if (lendingData?.tblLmsRepaymentMethods?.length > 0) {
                Object.keys(lendingData?.tblLmsRepaymentMethods[0]).forEach((key) => {
                    if (key === 'seqPrincipalAmount') {
                        pA.push({ label: key, value: lendingData?.tblLmsRepaymentMethods[0][key] });
                    }

                    if (key === 'seqMarkup') {
                        pA.push({ label: key, value: lendingData?.tblLmsRepaymentMethods[0][key] });
                    }

                    if (key === 'seqLateFee') {
                        pA.push({ label: 'seqFees', value: lendingData?.tblLmsRepaymentMethods[0][key] });
                    }
                });

                const updatedDefinePayment = pA?.map((item) => {
                    return {
                        label: item.label.replace('seq', ''),
                        sequence: item.value
                    };
                });

                updatedDefinePayment?.sort((a, b) => a.sequence - b.sequence);
                setButtons(updatedDefinePayment);
            }
        }
    }, [lendingData]); // eslint-disable-line

    const validationSchema = Yup.object().shape({});

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {},

        validate: (data) => {},

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            if (lendingData?.currentStep > 2) {
                data['edit'] = true;
            }

            if (!runningFinanceTableData || runningFinanceTableData?.length === 0) {
                setloading(false);
                setloadingIcon('');
                return;
            }

            if (noOfCyclesTableData?.length <= 0) {
                toast.warn('Please define atleast 01 no of cycle');
                setloading(false);
                setloadingIcon('');
                return;
            }

            const runningFinanceData = {
                lmsProductId: lendingIdData,
                currentStep: 3,
                lmsProductRepaymentRequests: runningFinanceTableData
            };

            setStepData3(runningFinanceData);
            handleStepClick(activeIndex + 1);

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const handleDragStart = (e, label) => {
        e.dataTransfer.setData('text/plain', label);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e, targetLabel) => {
        e.preventDefault();

        const draggedLabel = e.dataTransfer.getData('text/plain');
        const updatedButtons = [...buttons];

        const draggedButton = updatedButtons.find((button) => button.label === draggedLabel);
        const targetButton = updatedButtons.find((button) => button.label === targetLabel);

        if (draggedButton && targetButton) {
            // Swap the sequence numbers
            const tempSequence = draggedButton.sequence;
            draggedButton.sequence = targetButton.sequence;
            targetButton.sequence = tempSequence;

            // Sort buttons based on sequence
            updatedButtons.sort((a, b) => a.sequence - b.sequence);

            setButtons(updatedButtons);
        }
    };

    const typeLov = [
        { name: 'FIXED', lovId: 'F' },
        { name: 'PERCENTAGE', lovId: 'P' }
    ];

    const paymentOnLov = [
        { name: 'Outstanding Principal', lovId: 'O' },
        { name: 'Markup', lovId: 'M' }
    ];

    const actionBodyForNoOfCyclesTemplate = (rowData, rowIndex) => {
        return (
            <>
                <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteNoOfCyclesData(e, rowData, rowIndex)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
            </>
        );
    };

    const handledDeleteNoOfCyclesData = (e, rowData, rowIndex) => {
        e.preventDefault();
        const newFilterData = noOfCyclesTableData?.filter((value, index) => index !== rowIndex?.rowIndex);
        setNoOfCyclesTableData(newFilterData);
    };

    const handledAdd = (e) => {
        const errors = {
            markupValue: '',
            apr: '',

            gracePeriod: '',
            noOfDaysGracePeriod: '',

            latePayment: '',
            latePaymentType: '',
            latePaymentCharges: '',
            latePaymentOn: '',

            frequencyId: '',

            seqPrincipalAmount: '',
            seqMarkup: '',
            seqFees: ''
        };

        if (runningFinanceData?.markupValue === '' || runningFinanceData?.markupValue === undefined || runningFinanceData?.markupValue === null) {
            errors.markupValue = 'This field is required.';
        }

        if (runningFinanceData?.apr === '' || runningFinanceData?.apr === undefined || runningFinanceData?.apr === null) {
            errors.apr = 'This field is required.';
        }

        if (runningFinanceData?.gracePeriod === true) {
            if (runningFinanceData?.noOfDaysGracePeriod === '') {
                errors.noOfDaysGracePeriod = 'This field is required';
            }
        }

        if (runningFinanceData?.latePayment === true) {
            if (runningFinanceData?.latePaymentType === '') {
                errors.latePaymentType = 'This field is required';
            }

            if (runningFinanceData?.latePaymentCharges === '') {
                errors.latePaymentCharges = 'This field is required';
            } else if (runningFinanceData?.latePaymentCharges < 1 || runningFinanceData?.latePaymentCharges > 100) {
                errors.latePaymentCharges = 'Please enter a valid percentage between 1 and 100';
            }

            if (runningFinanceData?.latePaymentOn === '') {
                errors.latePaymentOn = 'This field is required';
            }
        }

        if (runningFinanceData?.frequencyId === '' || runningFinanceData?.frequencyId === undefined || runningFinanceData?.frequencyId === null) {
            errors.frequencyId = 'This field is required';
        }

        setRunningFinanceError(errors);

        const sequenceObject = buttons?.reduce((result, item) => {
            const label = item.label.replace(/\s+/g, ''); // Remove spaces from label
            const seqKey = `seq${label}`;
            result[seqKey] = item.sequence;
            return result;
        }, {});

        if (runningFinanceData?.gracePeriod === false || runningFinanceData?.gracePeriod === '') {
            runningFinanceData.noOfDaysGracePeriod = '';
        }

        if (runningFinanceData?.latePayment === false || runningFinanceData?.latePayment === '') {
            runningFinanceData.latePaymentType = '';
            runningFinanceData.latePaymentCharges = '';
            runningFinanceData.latePaymentOn = '';
        }

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            const newRunningFinanceTableData = {
                markupValue: runningFinanceData?.markupValue,
                apr: runningFinanceData?.apr,

                gracePeriod: runningFinanceData?.gracePeriod,
                noOfDaysGracePeriod: runningFinanceData?.noOfDaysGracePeriod,

                latePayment: runningFinanceData?.latePayment,
                latePaymentType: runningFinanceData?.latePaymentType,
                latePaymentCharges: runningFinanceData?.latePaymentCharges,
                latePaymentOn: runningFinanceData?.latePaymentOn,

                frequencyId: runningFinanceData?.frequencyId,

                repaymentTypeCode: stepData1?.facilityCode,

                lmsProductRepaymentCycles: noOfCyclesTableData
            };

            const combinedObject = { ...newRunningFinanceTableData, ...sequenceObject };
            setRunningFinanceTableData([...runningFinanceTableData, combinedObject]);

            setButtons([
                { label: 'Principal Amount', sequence: 1 },
                { label: 'Markup', sequence: 2 },
                { label: 'Fees', sequence: 3 }
            ]);
        }
    };

    const handledData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        const checked = e.target.checked;
        const type = e.target.type;

        if (name === 'gracePeriod' && checked === false) {
            runningFinanceData.noOfDaysGracePeriod = '';
        }

        if (name === 'latePayment' && checked === false) {
            runningFinanceData.latePaymentType = '';
            runningFinanceData.latePaymentCharges = '';
            runningFinanceData.latePaymentOn = '';
        }

        // if(name === 'earlyPayment' && checked === false) {
        //     termLoanData.earlyPaymentType = '';
        //     termLoanData.earlyPaymentCharges = '';
        //     termLoanData.earlyPaymentOn = '';
        // }

        setRunningFinanceData({ ...runningFinanceData, [name]: type === 'checkbox' ? checked : value });

        // Clear the error message for the field being edited
        setRunningFinanceError({
            ...runningFinanceError,
            [name]: ''
        });
    };
    //////////////////////////////////////////////////////////

    const handledNoOfCyclesAdd = (e) => {
        e.preventDefault();

        const errors = {
            noOfCycle: '',
            type: '',
            price: ''
        };

        if (noOfCyclesData?.noOfCycle === '') {
            errors.noOfCycle = 'This field is required.';
        }

        if (noOfCyclesData?.type === '' || noOfCyclesData?.type === undefined) {
            errors.type = 'This field is required.';
        }

        if (noOfCyclesData?.price === '' || noOfCyclesData?.price === undefined) {
            errors.price = 'This field is required.';
        } else if (isNaN(noOfCyclesData?.price)) {
            errors.price = 'Please enter a valid numeric value.';
        } else if (parseInt(noOfCyclesData?.price) < 1) {
            errors.price = 'Please enter a valid value greater than 0';
        }

        setNoOfCyclesError(errors);

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            const newNoOfCyclesTableData = {
                noOfCycle: noOfCyclesData?.noOfCycle,
                type: noOfCyclesData?.type,
                price: noOfCyclesData?.price
                // repaymentTypeCode: lendingData?.lkpLmsFacilityType?.lmsFacilityTypeCode,
            };

            setNoOfCyclesTableData([...noOfCyclesTableData, newNoOfCyclesTableData]);
            setNoOfCyclesData({
                noOfCycle: '',
                type: '',
                price: ''
            });
        }
    };

    const handledNoOfCyclesData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        const checked = e.target.checked;
        const type = e.target.type;

        setNoOfCyclesData({ ...noOfCyclesData, [name]: type === 'checkbox' ? checked : value });

        // Clear the error message for the field being edited
        setNoOfCyclesError({
            ...noOfCyclesError,
            [name]: ''
        });
    };

    const actionBodyTypeTemplate = (rowData) => {
        return <p>{rowData?.type === 'F' ? 'Fixed' : 'Percentage'}</p>;
    };

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>REPAYMENT METHOD/EDIT</h1>
            </div>
            <div className="card Card__Round">
                <div className="Form__Header flex align-items-center justify-content-between">
                    <h1>MARKUP CALCULATION </h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="markupValue" className={classNames({ 'p-error': isFormFieldValid('markupValue') }, 'Label__Text')}>
                                    Markup value <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={4}
                                    id="markupValue"
                                    placeholder="Enter Markup Value"
                                    name="markupValue"
                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                    value={runningFinanceData?.markupValue || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('markupValue') }, 'Input__Round')}
                                />
                                <small className="p-error">{runningFinanceError?.markupValue}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="apr" className={classNames({ 'p-error': isFormFieldValid('apr') }, 'Label__Text')}>
                                    APR <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="apr"
                                    placeholder="Enter APR"
                                    name="apr"
                                    maxLength={4}
                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                    value={runningFinanceData?.apr || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('apr') }, 'Input__Round')}
                                />
                                <small className="p-error">{runningFinanceError?.apr}</small>
                            </div>
                        </div>
                    </div>

                    <div style={{ backgroundColor: '#edf0f5', padding: '2rem' }} className=" col-12 card">
                        <div className="grid">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="noOfCycle" className={classNames({ 'p-error': isFormFieldValid('noOfCycle') }, 'Label__Text')}>
                                        No Of Cycles<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={4}
                                        id="noOfCycle"
                                        placeholder="Enter No. Of Cycles"
                                        name="noOfCycle"
                                        keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                        value={noOfCyclesData?.noOfCycle || ''}
                                        onChange={handledNoOfCyclesData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('noOfCycle') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{noOfCyclesError?.noOfCycle}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="type" className={classNames({ 'p-error': isFormFieldValid('type') }, 'Label__Text')}>
                                        Type<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="type"
                                        placeholder="Select Type"
                                        options={typeLov}
                                        optionLabel="name"
                                        name="type"
                                        filter
                                        optionValue="lovId"
                                        value={noOfCyclesData?.type || ''}
                                        onChange={handledNoOfCyclesData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('type') }, 'Dropdown__Round')}
                                    />
                                    <small className="p-error">{noOfCyclesError?.type}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="price" className={classNames({ 'p-error': isFormFieldValid('price') }, 'Label__Text')}>
                                        Price <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="price"
                                        placeholder="Enter Price"
                                        name="price"
                                        maxLength={8}
                                        keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                        value={noOfCyclesData?.price || ''}
                                        onChange={handledNoOfCyclesData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('price') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{noOfCyclesError?.price}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <div className="Down__Btn" style={{ justifyContent: 'flex-start' }}>
                                        <Button label="Add" className="Btn__Dark" style={{ marginTop: '15px' }} onClick={handledNoOfCyclesAdd} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12">
                                <div className="card mt-6">
                                    <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={noOfCyclesTableData}>
                                        <Column field="noOfCycle" header="No. Of Cycles" />
                                        <Column field="type" header="Type" body={actionBodyTypeTemplate} />
                                        <Column field="price" header="Price" />
                                        <Column header="Actions" body={actionBodyForNoOfCyclesTemplate} style={{ textAlign: 'center' }} className="Table__ActionHeading__CentredAlign" />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Form__Header col-12">
                        <h1>GRACE PERIOD </h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="gracePeriod" name="gracePeriod" value={runningFinanceData?.gracePeriod} checked={runningFinanceData?.gracePeriod} onChange={handledData} style={{ marginTop: '10px' }} />
                                    <label htmlFor="gracePeriod" className={classNames({ 'p-error': isFormFieldValid('gracePeriod') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Grace Period
                                    </label>
                                </div>
                                <small className="p-error">{runningFinanceError?.gracePeriod}</small>
                            </div>
                        </div>
                        {runningFinanceData?.gracePeriod === true && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="noOfDaysGracePeriod" className={classNames({ 'p-error': isFormFieldValid('noOfDaysGracePeriod') }, 'Label__Text')}>
                                        Number of Days <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={4}
                                        id="noOfDaysGracePeriod"
                                        placeholder="Enter Number of Days"
                                        name="noOfDaysGracePeriod"
                                        keyfilter={'int'}
                                        value={runningFinanceData?.noOfDaysGracePeriod || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('noOfDaysGracePeriod') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{runningFinanceError?.noOfDaysGracePeriod}</small>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="Form__Header col-12">
                        <h1>LATE PAYMENT </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="latePayment" name="latePayment" value={runningFinanceData?.latePayment} checked={runningFinanceData?.latePayment} onChange={handledData} style={{ marginTop: '10px' }} />
                                    <label htmlFor="latePayment" className={classNames({ 'p-error': isFormFieldValid('latePayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Late payment
                                    </label>
                                </div>
                                <small className="p-error">{runningFinanceError?.latePayment}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        {runningFinanceData?.latePayment === true && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentType" className={classNames({ 'p-error': isFormFieldValid('latePaymentType') }, 'Label__Text')}>
                                            Type<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="latePaymentType"
                                            placeholder="Select Type"
                                            options={typeLov}
                                            optionLabel="name"
                                            name="latePaymentType"
                                            filter
                                            optionValue="lovId"
                                            value={runningFinanceData?.latePaymentType || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('latePaymentType') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{runningFinanceError?.latePaymentType}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentCharges" className={classNames({ 'p-error': isFormFieldValid('latePaymentCharges') }, 'Label__Text')}>
                                            Charges <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="latePaymentCharges"
                                            placeholder="Enter charges"
                                            name="latePaymentCharges"
                                            maxLength={8}
                                            keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                            value={runningFinanceData?.latePaymentCharges || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('latePaymentCharges') }, 'Input__Round')}
                                        />
                                        <small className="p-error">{runningFinanceError?.latePaymentCharges}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentOn" className={classNames({ 'p-error': isFormFieldValid('latePaymentOn') }, 'Label__Text')}>
                                            Late Payment On<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="latePaymentOn"
                                            placeholder="Select Late Payment On"
                                            options={paymentOnLov}
                                            optionLabel="name"
                                            name="latePaymentOn"
                                            filter
                                            optionValue="lovId"
                                            value={runningFinanceData?.latePaymentOn || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('latePaymentOn') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{runningFinanceError?.latePaymentOn}</small>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {/* //////////////////////////////////////////////////// */}
                    <div className="Form__Header col-12">
                        <h1>REPAYMENT SCHEDULE </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="frequencyId" className={classNames({ 'p-error': isFormFieldValid('frequencyId') }, 'Label__Text')}>
                                    Frequency Of Payment<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="frequencyId"
                                    placeholder="Select Frequency Of Payment"
                                    options={frequencyPeriodData}
                                    optionLabel="name"
                                    name="frequencyId"
                                    filter
                                    optionValue="lovId"
                                    value={runningFinanceData?.frequencyId || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('frequencyId') }, 'Dropdown__Round')}
                                />
                                <small className="p-error">{runningFinanceError?.frequencyId}</small>
                            </div>
                        </div>
                    </div>
                    {/* ///////////////////////////////////////////////////// */}

                    <div className="Form__Header">
                        <h1>DEFINE REPAYMENT SEQUENCE </h1>
                    </div>
                    <div className="Centre_Aligned_Buttons" onDragOver={handleDragOver}>
                        {buttons?.map((button) => (
                            <Button
                                key={button.label}
                                className="DraggableButton Light_Button"
                                onClick={(e) => e.preventDefault()}
                                label={`${button.label}`}
                                draggable
                                onDragStart={(e) => handleDragStart(e, button.label)}
                                onDrop={(e) => handleDrop(e, button.label)}
                            />
                        ))}
                    </div>

                    <div className="Down__Btn">
                        <Button loadingIcon={loadingIcon} style={{ margin: '20px' }} onClick={() => handledAdd()} label={'Next'} className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default EditRunningFinance;

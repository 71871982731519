import React, { useEffect, useState } from 'react';
import { handleGetRequest } from '../../../services/GetTemplate';
import TransactionType from '../Components/RetailerCustomerTransaction/TransactionType';
import CashInOut from '../Components/RetailerCustomerTransaction/CashInOut';
import P2P from '../Components/RetailerCustomerTransaction/P2P';
import Ubp from '../Components/RetailerCustomerTransaction/Ubp';
import IbftInOut from '../Components/RetailerCustomerTransaction/IbftInOut';
import Eload from '../Components/RetailerCustomerTransaction/Eload';
import BalanceBrackets from '../Components/RetailerCustomerTransaction/BalanceBrackets';
import RetailerCommission from '../Components/RetailerCustomerTransaction/RetailerCommission';
import getPercentageValue from '../Components/GetPercentage';

function RetailerCustomerTransactionSection() {
    const [transectionTypeWiseData, setTransectionTypeWiseData] = useState([]);
    const [cashInOutDatas, setCashInOutDatas] = useState([]);
    const [p2pData, setP2pData] = useState([]);
    const [ubpData, setUbpData] = useState([]);
    const [eloadData, setEloadData] = useState([]);
    const [balanceBracketsData, setBalanceBracketsData] = useState([]);
    const [retailerCommission, setRetailerCommission] = useState([]);
    const [ibftInOutData, setIbftInOutData] = useState([]);

    const getTransactionTypeData = async () => {
        const response = await handleGetRequest('/transaction/v1/riskdashboard/gettransactiontype');
        setTransectionTypeWiseData(response?.payLoad);
    };

    const getCashInOutData = async () => {
        const response = await handleGetRequest('/transaction/v1/riskdashboard/getcashinoutdata');
        setCashInOutDatas(response?.payLoad);
    };

    const getP2PData = async () => {
        const response = await handleGetRequest('/transaction/v1/riskdashboard/getptwopdata');
        const newPayLoad = response?.payLoad?.flatMap((item) => [
            {
                parameterName: 'Count',
                parameterValue: item?.parameterValue
            },
            {
                parameterName: 'Amount',
                parameterValue: item?.parameterValueTwo
            }
        ]);
        setP2pData(getPercentageValue(newPayLoad));
    };

    const getUbpData = async () => {
        const response = await handleGetRequest('/transaction/v1/riskdashboard/getubpdata');
        setUbpData(response?.payLoad);
    };

    const getEloadData = async () => {
        const response = await handleGetRequest('/transaction/v1/riskdashboard/geteloaddata');
        const newPayLoad = response?.payLoad?.flatMap((item) => [
            {
                parameterName: 'Telco',
                parameterValue: item?.parameterValue
            },
            {
                parameterName: 'Amount',
                parameterValue: item?.parameterValueTwo
            }
        ]);
        setEloadData(getPercentageValue(newPayLoad));
    };

    const getBalanceBracketsData = async () => {
        const response = await handleGetRequest('/transaction/v1/riskdashboard/getbalancebrackets');
        setBalanceBracketsData(response?.payLoad);
    };

    const getRetailerCommission = async () => {
        const response = await handleGetRequest('/transaction/v1/riskdashboard/getretailercommission');
        setRetailerCommission(response?.payLoad);
    };

    const getIbftInOutData = async () => {
        const response = await handleGetRequest('/transaction/v1/riskdashboard/getibftinoutdata');
        setIbftInOutData(response?.payLoad);
    };

    useEffect(() => {
        getTransactionTypeData();
        getCashInOutData();
        getP2PData();
        getUbpData();
        getEloadData();
        getBalanceBracketsData();
        getRetailerCommission();
        getIbftInOutData();
    }, []);

    return (
        <>
            <div className="grid">
                <div className="title__header mb-4 uppercase">Retailer and Customer Transaction Section</div>
                <TransactionType transactionTypeData={transectionTypeWiseData} />
                <CashInOut cashInOutData={cashInOutDatas} />
                <P2P p2pData={p2pData} />
                <Ubp ubpData={ubpData} />
                <Eload eLoadData={eloadData} />
                <BalanceBrackets balanceBrackets={balanceBracketsData} />
                <RetailerCommission retailerCommission={retailerCommission} />
                <IbftInOut ibftInOutData={ibftInOutData} />
            </div>
        </>
    );
}
export default RetailerCustomerTransactionSection;

import React, { useEffect, useState } from 'react'
import { Chart } from 'primereact/chart';

const OneToManyAccFT = ({ oneToManyAccFT }) => {

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {

        const tohfaArr = oneToManyAccFT && oneToManyAccFT.map((otmaft) => { return otmaft?.tohfa })
        const ibftArr = oneToManyAccFT && oneToManyAccFT.map((otmaft) => { return otmaft?.ibft })
        const fundTransferArr = oneToManyAccFT && oneToManyAccFT.map((otmaft) => { return otmaft?.foundTransfer })
        const dateArr = oneToManyAccFT && oneToManyAccFT.map((otmaft) => { return otmaft?.transectionDate.split(" ")[0] })

        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: dateArr,
            datasets: [
                {
                    label: 'Tohfa',
                    data: tohfaArr,
                    fill: false,
                    borderColor: "rgb(227,224,183)",
                    tension: 0.4
                },
                {
                    label: 'IBFT',
                    data: ibftArr,
                    fill: false,
                    borderColor: "rgb(211,183,227)",
                    tension: 0.4
                },
                {
                    label: 'Fund Transfer',
                    data: fundTransferArr,
                    fill: false,
                    borderColor: "rgb(163,225,225)",
                    tension: 0.4
                }
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [oneToManyAccFT]);

    return (
        <div className="col-12 md:col-5">
            <div className="card Card__Round">
                <div className="cardBarCharts">
                    <h5>1 to Many Account Fund Transfer</h5>
                    <div className="pt-3">
                        <Chart type="line" data={chartData} options={chartOptions} className="w-full" height='35rem' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OneToManyAccFT
import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { MultiSelect } from 'primereact/multiselect';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import classNames from 'classnames';
import * as Yup from 'yup';
import { handleGetRequest } from '../../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../../services/PostTemplate';
import { InputTextarea } from 'primereact/inputtextarea';
import DynamicKYCFields from '../../DynamicKYC/DynamicKYCFields';
import { baseURL } from '../../../../../Config';
import { Dialog } from 'primereact/dialog';
import AccountSettelmentDialogChecker from './AccountSettleDialogChecker';
import AccountStatementDialogChecker from './AccontStatementDialogChecker';
import CloseAccountDialogChecker from './CloseAccountDialogChecker';

function FranchiseChecker({ databyId, apiData, docData, isEditCase, mcRequestData, requestType, requestTypeDescr, mcRequestId, mcPeindingRequestId, refTableId, tableName }) {
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [loading, setloading] = useState(false);
    const [pricingProfile, setPricingProfile] = useState([]);
    const [commissionProfile, setCommissionProfile] = useState([]);
    const [limitProfile, setLimitProfile] = useState([]);
    const [kycFields, setKycFields] = useState([]);
    const [employeeName, setEmployeeName] = useState('');
    const [productcatalog, setProductcatalog] = useState([]);
    const [nearestBranch, setNearestBranch] = useState([]);
    const [channelOption, setChannelOption] = useState([]);
    const [historyData, setHistoryData] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [checkerPayload, setCheckerPayload] = useState([]);
    const [kfsInput, setKfsInput] = useState([]);
    const [digitalSigningInput, setDigitalSigningInput] = useState([]);
    const [kfsLink, setKfsLink] = useState([]);
    const [digitalSigningLink, setDigitalSigningLink] = useState([]);
    const [accountStatus, setAccountStatus] = useState();
    const [accountStatementDialogOpen, setAccountStatementDialogOpen] = useState(false);
    const [accountSettleDialogOpen, setAccountSettleDialogOpen] = useState(false);
    const [closeAccountDialog, setCloseAccountDialog] = useState(false);
    const [newPayload, setNewPayload] = useState();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const lovId = apiData?.lkpAccountClassification?.accountClassificationId;
    const name = apiData?.lkpAccountClassification?.accountClassificationName;
    const accountClassificationId = lovId;
    const validationSchema = Yup.object().shape({
        action: Yup.string().required('This Field is required.'),
        checkerComments: Yup.string().required('This Field is required.')
    });
    const kycFixed = ['cnic', 'mobileNumber', 'cnicIssuanceDate', 'address', 'name'];

    useEffect(() => {
        if (requestTypeDescr == 'Enable/Disable Request') {
            setCheckerPayload({
                agentId: apiData?.agentId,
                isActive: mcRequestData?.isActive == 'Y' ? true : false
            });
        } else if (requestType == 'I') {
            setCheckerPayload({
                agentId: databyId?.agentId,
                isActive: true
            });
        } else {
            setCheckerPayload(mcRequestData);
        }
    }, [requestTypeDescr, requestType, apiData, mcRequestData]);

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            action: '',
            checkerComments: '',
            pricingProfileId: '',
            commissionId: '',
            transLimitId: '',
            parentAgentId: '1',
            channelIdList: [],
            hierarchyName: '',
            empId: '',
            productCatalogId: '',
            branchId: '',
            chequeBook: false,
            debitCard: false,
            noOfLeaves: '',
            debitCardAddress: '',
            debitCardName: '',
            atmWaiver: 'N',
            chequeWaiver: 'N',
            bvs: '',
            addressInfos: [
                {
                    addressId: '',
                    fullAddress: ''
                }
            ],
            motherName: '',
            fatherName: '',
            accountStatus: '',
            accountTitle: '',
            accountNumber: ''
        },
        onSubmit: async (data) => {
            setloading(true);

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        // agentOnBoardingRequest: {
                        //     ...checkerPayload
                        // },
                        ...(apiData?.hasOwnProperty('updateAgentAccountStatusRequest')
                            ? mcRequestData
                            : apiData?.hasOwnProperty('regenerateAgentLoginPinRequest')
                            ? mcRequestData
                            : apiData?.hasOwnProperty('sendAttachmentRequest')
                            ? mcRequestData
                            : {
                                  agentOnBoardingRequest: {
                                      ...checkerPayload
                                  }
                              }),
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data?.checkerComments,
                            action: data?.action,
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            await dispatch(handlePostRequest(newData, '/account/v1/customeraccounts/agentMaintenanceCheckerAction', true, false, 'requestchecker'));
        }
    });

    useEffect(() => {
        if (requestTypeDescr == 'Enable/Disable Request' || requestType == 'I') {
            formik.setFieldValue('pricingProfileId', databyId?.tblPricingProfile?.pricingProfileId || '');
            formik.setFieldValue('commissionId', databyId?.tblCommissionProfile?.commissionProfileId || '');
            formik.setFieldValue('transLimitId', databyId?.tblTransLimit?.transLimitId || '');
            formik.setFieldValue('hierarchyName', databyId?.tblSalesHierarchy?.hierarchyName || '');
            formik.setFieldValue('empId', databyId?.tblSalesForce?.employeeId || '');
            formik.setFieldValue('productCatalogId', databyId?.tblAgentChannelList[0]?.tblProductCatalog?.productCatalogId.toString() || '');
            formik.setFieldValue('branchId', databyId?.lkpBranch?.branchId.toString() || '');
            formik.setFieldValue('chequeBook', databyId?.chequeBook === 'Y');
            formik.setFieldValue('debitCard', databyId?.debitCard === 'Y');
            formik.setFieldValue('noOfLeaves', databyId?.noOfLeaves || '');
            formik.setFieldValue('debitCardAddress', databyId?.debitCardAddress || '');
            formik.setFieldValue('debitCardName', databyId?.debitCardName || '');
            formik.setFieldValue('chequeWaiver', databyId?.chequeWaiver || '');
            formik.setFieldValue('atmWaiver', databyId?.atmWaiver || '');
            formik.setFieldValue('bvs', (databyId?.bvs === 'N' || databyId?.bvs == null ? 'N' : 'Y') || '');
            formik.setFieldValue('addressInfos', databyId?.addressInfos);
            formik.setFieldValue('motherName', databyId?.motherName);
            formik.setFieldValue('fatherName', databyId?.fatherName);
            formik.setFieldValue('accountTitle', databyId?.accountTitle);
            formik.setFieldValue('accountNumber', databyId?.accountNumber);
            formik.setFieldValue('accountStatus', databyId?.accountStatus);
            formik.setFieldValue('crpScore', databyId?.crpScore);
            formik.setFieldValue('crpRating', databyId?.crpRating);
            formik.setFieldValue('birthPlace', databyId?.birthPlace);
            formik.setFieldValue('plus30', databyId?.tblEcibResponse?.plus30);
            formik.setFieldValue('plus60', databyId?.tblEcibResponse?.plus60);
            formik.setFieldValue('plus90', databyId?.tblEcibResponse?.plus90);
            formik.setFieldValue('plus120', databyId?.tblEcibResponse?.plus120);
            formik.setFieldValue('plus150', databyId?.tblEcibResponse?.plus150);
            formik.setFieldValue('plus180', databyId?.tblEcibResponse?.plus180);
        } else {
            formik.setFieldValue('pricingProfileId', apiData?.pricingProfileId || '');
            formik.setFieldValue('commissionId', apiData?.tblCommissionProfile?.commissionProfileId || '');
            formik.setFieldValue('transLimitId', apiData?.transLimitId || '');
            formik.setFieldValue('hierarchyName', apiData?.tblSalesHierarchy?.hierarchyName || '');
            formik.setFieldValue('empId', apiData?.empId || '');
            formik.setFieldValue('productCatalogId', apiData?.productCatalogId?.toString() || '');
            formik.setFieldValue('branchId', apiData?.branchId?.toString() || '');
            formik.setFieldValue('chequeBook', apiData?.chequeBook === 'Y');
            formik.setFieldValue('debitCard', apiData?.debitCard === 'Y');
            formik.setFieldValue('noOfLeaves', apiData?.noOfLeaves || '');
            formik.setFieldValue('debitCardAddress', apiData?.debitCardAddress || '');
            formik.setFieldValue('debitCardName', apiData?.debitCardName || '');
            formik.setFieldValue('chequeWaiver', apiData?.chequeWaiver || '');
            formik.setFieldValue('atmWaiver', apiData?.atmWaiver || '');
            formik.setFieldValue('bvs', (apiData?.bvs === 'N' || apiData?.bvs == null ? 'N' : 'Y') || '');
            formik.setFieldValue('addressInfos', apiData?.addressInfos);
            formik.setFieldValue('motherName', apiData?.motherName);
            formik.setFieldValue('fatherName', apiData?.fatherName);
            formik.setFieldValue('accountTitle', apiData?.accountTitle);
            formik.setFieldValue('accountNumber', apiData?.accountNumber);
            formik.setFieldValue('accountStatus', apiData?.accountStatus);
            formik.setFieldValue('crpScore', apiData?.crpScore);
            formik.setFieldValue('crpRating', apiData?.crpRating);
            formik.setFieldValue('birthPlace', apiData?.birthPlace);
            formik.setFieldValue('plus30', apiData?.tblEcibResponse?.plus30);
            formik.setFieldValue('plus60', apiData?.tblEcibResponse?.plus60);
            formik.setFieldValue('plus90', apiData?.tblEcibResponse?.plus90);
            formik.setFieldValue('plus120', apiData?.tblEcibResponse?.plus120);
            formik.setFieldValue('plus150', apiData?.tblEcibResponse?.plus150);
            formik.setFieldValue('plus180', apiData?.tblEcibResponse?.plus180);
            // formik.setFieldValue('mobileNumber', apiData?.mobileNumber)
        }
    }, [apiData, requestTypeDescr, requestType]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const getaccountStatus = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getAccountStatus/LKP_ACCOUNT_STATUS:*');
        setAccountStatus(res?.payLoad);
    };

    useEffect(() => {
        getaccountStatus();
    }, []);

    useEffect(() => {
        if (kycFields && apiData?.kycView) {
            kycFields[0]?.tblKycSetDetails?.forEach((item) => {
                if (requestTypeDescr == 'Enable/Disable Request' || requestType == 'I') {
                    formik.setFieldValue(item?.tblKycAttribute?.attributeName, JSON.parse(apiData?.kycView)[item?.tblKycAttribute?.attributeName]);
                } else {
                    if (kycFixed?.includes(item?.tblKycAttribute?.attributeName) || (item?.isMandatory === 'Y' && item?.isActive === 'Y')) {
                        formik.setFieldValue(item?.tblKycAttribute?.attributeName, JSON.parse(apiData?.kycView)[item?.tblKycAttribute?.attributeName]);
                    }
                    if (mcRequestData?.additionalInformations) {
                        const kyc1 = mcRequestData?.additionalInformations;
                        kyc1?.forEach((item) => {
                            formik?.setFieldValue(item?.infoKey, item?.infoValue);
                        });
                    }
                }
            });
        }
    }, [kycFields, apiData?.kycView]);

    const getKycFields = async () => {
        const res = await handleGetRequest(`/account/v1/agentaccount/getkycbyclassificationname/${name}`);
        setKycFields(res?.payLoad);
    };

    useEffect(() => {
        if (apiData) {
            getKycFields();
            getCommissionProfile();
            getLimitProfile();
            getPricingProfile();
            if (requestTypeDescr == 'Enable/Disable Request' || requestType == 'I') {
                const extractedChannelIds = databyId?.tblAgentChannelList
                    ?.filter((entry) => entry?.isActive === 'Y')
                    .map((entry) => ({
                        lovId: entry?.lkpChannel?.channelId.toString()
                    }));
                formik.setFieldValue('channelIdList', extractedChannelIds);
            } else {
                const extractedChannelIds = apiData?.channelIdList?.map((entry) => {
                    return { lovId: entry?.toString() };
                });
                formik.setFieldValue('channelIdList', extractedChannelIds);
            }
        }
    }, [apiData]);

    //parent name dropdown
    const parentDropdown = [{ name: 'Zindagi', value: '1' }];

    //cheque book leaves dropdown
    const chequeBookLeaves = [
        { name: '25', value: '25' },
        { name: '50', value: '50' },
        { name: '100', value: '100' },
        { name: '250', value: '250' }
    ];

    const panelFooterTemplate = () => {
        const length = formik.values.channelIdList ? formik.values.channelIdList.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = res?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    //pricing profile dropdown options API
    const getChannel = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchannels/LKP_CHANNEL:*');
        setChannelOption(res?.payLoad);
    };

    //commission profile dropdown options API
    const getCommissionProfile = async () => {
        const res = await handleGetRequest(`/account/v1/agentaccount/getcommissionprofilebyclassification/${accountClassificationId}`);
        setCommissionProfile(res?.payLoad);
    };

    //pricing profile dropdown options API
    const getPricingProfile = async () => {
        const res = await handleGetRequest(`/account/v1/agentaccount/getpricingprofilebyclassification/${accountClassificationId}`);
        setPricingProfile(res?.payLoad);
    };

    //limit profile dropdown options API
    const getLimitProfile = async () => {
        const res = await handleGetRequest(`/account/v1/agentaccount/gettranslimitbyclassification/${accountClassificationId}`);
        setLimitProfile(res?.payLoad);
    };

    //product catalog dropdown options API
    const getProductCatalog = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/productCatalog/TBL_PRODUCT_CATALOG:*');
        setProductcatalog(res?.payLoad);
    };

    //nearest/linked dropdown options API
    const getNearest = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getallbranches/LKP_BRANCH:*');
        setNearestBranch(res?.payLoad);
    };

    const getAuthorizationHistoryData = async () => {
        const resp = await handleGetRequest(`/workflow/v1/management/getActionAuthorizationHistory/${refTableId}/${tableName}`);
        setHistoryData(resp?.payLoad);
    };

    useEffect(() => {
        getStatusListData();
        getProductCatalog();
        getNearest();
        getChannel();
        getAuthorizationHistoryData();
    }, []);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const createFileFromBase64 = (apiResponse, type) => {
        const base64Data = apiResponse?.docBase64;
        const fileExtension = apiResponse?.documentExt;
        if (base64Data && fileExtension) {
            const fileName = `${type}${fileExtension}`;

            if (type === 'KEY_FACT') {
                setKfsInput(fileName);
            } else if (type === 'DIGITAL_SIGNING') {
                setDigitalSigningInput(fileName);
            }
        }
    };

    useEffect(() => {
        if (docData?.length > 0) {
            var KEY_FACT = docData?.filter((item) => item?.lkpDocumentType?.documentTypeDescr === 'KEY_FACT')[0];
            var DIGITAL_SIGNING = docData?.filter((item) => item?.lkpDocumentType?.documentTypeDescr === 'DIGITAL_SIGNING')[0];
            if (KEY_FACT) {
                createFileFromBase64(KEY_FACT, 'KEY_FACT');
                setKfsLink(`${baseURL}/document/${docData[0]?.documentPath.split('/documents/')[1]}`);
            }
            if (DIGITAL_SIGNING) {
                createFileFromBase64(DIGITAL_SIGNING, 'DIGITAL_SIGNING');
                setDigitalSigningLink(`${baseURL}/document/${docData[1]?.documentPath.split('/documents/')[1]}`);
            }
        }
    }, [docData]);

    const handleSetleAccount = () => {
        setAccountSettleDialogOpen(true);
    };
    const onHidesettleAccount = () => {
        setAccountSettleDialogOpen(false);
    };
    const handleCloseAccount = () => {
        setCloseAccountDialog(true);
    };
    const onHideCloseAccount = () => {
        setCloseAccountDialog(false);
    };

    const handleAccountStatement = () => {
        setAccountStatementDialogOpen(true);
    };
    const onHideAccountStatement = () => {
        setAccountStatementDialogOpen(false);
    };

    return (
        <>
            <Dialog visible={accountSettleDialogOpen} onHide={onHidesettleAccount}>
                <AccountSettelmentDialogChecker onHidesettleAccount={onHidesettleAccount} data={apiData?.updateAgentAccountStatusRequest?.remarks} accountNo={apiData?.updateAgentAccountStatusRequest?.accountNumber} />
            </Dialog>
            <Dialog visible={accountStatementDialogOpen} onHide={onHideAccountStatement}>
                <AccountStatementDialogChecker onHideAccountStatement={onHideAccountStatement} />
            </Dialog>
            <Dialog visible={closeAccountDialog} onHide={onHideCloseAccount}>
                <CloseAccountDialogChecker onHideCloseAccount={onHideCloseAccount} data={apiData?.updateAgentAccountStatusRequest?.remarks} accountNo={apiData?.updateAgentAccountStatusRequest?.accountNumber} />
            </Dialog>
            <div className="my-3">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {kycFields && <DynamicKYCFields kycFields={kycFields} formik={formik} kycFixed={kycFixed} isEditable={false} getFormErrorMessage={getFormErrorMessage} />}
                    <div className="p-fluid p-formgrid grid mb-3">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="pricingProfileId" className={classNames({ 'p-error': isFormFieldValid('pricingProfileId') }, 'Label__Text')}>
                                    Pricing Profile
                                </label>
                                <Dropdown
                                    id="pricingProfileId"
                                    placeholder="Select Pricing Profile"
                                    options={pricingProfile}
                                    optionLabel="pricingProfileName"
                                    name="pricingProfileId"
                                    optionValue="pricingProfileId"
                                    value={formik.values.pricingProfileId || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('pricingProfileId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="commissionId" className={classNames({ 'p-error': isFormFieldValid('commissionId') }, 'Label__Text')}>
                                    Commission Profile
                                </label>
                                <Dropdown
                                    id="commissionId"
                                    placeholder="Select Pricing Profile"
                                    options={commissionProfile}
                                    optionLabel="commissionProfileName"
                                    name="commissionId"
                                    optionValue="commissionProfileId"
                                    value={formik.values.commissionId || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('commissionId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('commissionId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="transLimitId" className={classNames({ 'p-error': isFormFieldValid('transLimitId') }, 'Label__Text')}>
                                    Limit Profile
                                </label>
                                <Dropdown
                                    id="transLimitId"
                                    placeholder="Select Limit Profile"
                                    options={limitProfile}
                                    optionLabel="limitProfileName"
                                    name="transLimitId"
                                    optionValue="transLimitId"
                                    value={formik.values.transLimitId || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('transLimitId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('transLimitId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="parentAgentId" className={classNames({ 'p-error': isFormFieldValid('parentAgentId') }, 'Label__Text')}>
                                    Parent Name
                                </label>
                                <Dropdown
                                    id="parentAgentId"
                                    name="parentAgentId"
                                    placeholder="Select Parent Name"
                                    options={parentDropdown}
                                    value={formik.values.parentAgentId}
                                    onChange={formik.handleChange}
                                    disabled
                                    optionLabel="name"
                                    optionValue="value"
                                    className={classNames({ 'p-invalid': isFormFieldValid('parentAgentId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('parentAgentId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="channelIdList" className={classNames({ 'p-error': isFormFieldValid('channelIdList') }, 'Label__Text')}>
                                    Channel<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="channelIdList"
                                    options={channelOption}
                                    name="channelIdList"
                                    optionLabel="name"
                                    optionValue="lovId"
                                    panelFooterTemplate={panelFooterTemplate}
                                    value={formik?.values?.channelIdList?.map((trans) => trans.lovId)}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            'channelIdList',
                                            e.value.map((lovId) => ({ lovId }))
                                        );
                                    }}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelIdList') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('channelIdList')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="empId" className={classNames({ 'p-error': isFormFieldValid('empId') }, 'Label__Text')}>
                                    Employee ID<span className="Label__Required">*</span>
                                </label>
                                <div className="flex">
                                    <InputText id="empId" name="empId" value={formik.values.empId} onChange={formik.handleChange} disabled className={classNames({ 'p-invalid': isFormFieldValid('empId') }, 'Input__Round')} />
                                    {/* {isEditCase && <Button type="button" icon={loadingIcon || ''} onClick={() => searchEmployeeId()} className="Btn__Dark border-circle" style={{ height: '35px', width: '35px', marginLeft: '1rem' }} />} */}
                                </div>
                                {getFormErrorMessage('empId')}
                            </div>
                        </div>
                    </div>
                    <div className="custom-card p-3 my-3">
                        <div className="m-3 Form__Header Full__Width">
                            <p>EMPLOYEE ID</p>
                        </div>
                        <div className="p-fluid p-formgrid grid mb-2 mx-3">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="hierarchyName" className={classNames({ 'p-error': isFormFieldValid('hierarchyName') }, 'Label__Text')}>
                                        Sales Hierarchy Name
                                    </label>
                                    <InputText
                                        id="hierarchyName"
                                        name="hierarchyName"
                                        value={formik.values.hierarchyName}
                                        onChange={formik.handleChange}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('hierarchyName') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('hierarchyName')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-3">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productCatalogId" className={classNames({ 'p-error': isFormFieldValid('productCatalogId') }, 'Label__Text')}>
                                    Product catalog
                                </label>
                                <Dropdown
                                    id="productCatalogId"
                                    placeholder="Select Product Catalog"
                                    options={productcatalog}
                                    optionLabel="name"
                                    name="productCatalogId"
                                    optionValue="lovId"
                                    value={formik.values.productCatalogId || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('productCatalogId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('productCatalogId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="branchId" className={classNames({ 'p-error': isFormFieldValid('branchId') }, 'Label__Text')}>
                                    Nearest/linked branch
                                </label>
                                <Dropdown
                                    id="branchId"
                                    placeholder="Select KYC Sets"
                                    options={nearestBranch}
                                    optionLabel="name"
                                    name="branchId"
                                    optionValue="lovId"
                                    value={formik.values.branchId || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('branchId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('branchId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 flex align-items-end mb-2 pt-3 pb-3">
                            <div className="p-field">
                                <Checkbox
                                    inputId="chequeBook"
                                    name="chequeBook"
                                    checked={formik.values.chequeBook}
                                    onChange={formik.handleChange}
                                    disabled
                                    // onChange={(e) => {
                                    //     formik.handleChange(e);
                                    //     formik.setFieldValue('debitCard', '');
                                    // }}
                                />
                                <label htmlFor="chequeBook" className="Label__Text">
                                    Cheque Book
                                </label>
                            </div>
                            <div className="p-field ml-3">
                                <Checkbox
                                    inputId="debitCard"
                                    name="debitCard"
                                    checked={formik.values.debitCard}
                                    onChange={formik.handleChange}
                                    disabled
                                    // onChange={(e) => {
                                    //     formik.handleChange(e);
                                    //     formik.setFieldValue('chequeBook', '');
                                    // }}
                                />
                                <label htmlFor="debitCard" className="Label__Text">
                                    ATM/Debit Card
                                </label>
                            </div>
                        </div>
                    </div>
                    {(formik.values.chequeBook || formik.values.debitCard) && (
                        <div className="custom-card p-3 my-3">
                            <div className="p-fluid p-formgrid grid mb-2 mx-3">
                                {formik.values.chequeBook && (
                                    <>
                                        <div className="m-3 Form__Header Full__Width">
                                            <p>CHEQUE BOOK DETAILS</p>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="noOfLeaves" className={classNames({ 'p-error': isFormFieldValid('noOfLeaves') }, 'Label__Text')}>
                                                    No of leaves
                                                </label>
                                                <Dropdown
                                                    id="noOfLeaves"
                                                    options={chequeBookLeaves}
                                                    name="noOfLeaves"
                                                    placeholder="Select Number of Leaves"
                                                    optionLabel="name"
                                                    optionValue="value"
                                                    value={formik.values.noOfLeaves}
                                                    onChange={formik.handleChange}
                                                    disabled
                                                    className={classNames({ 'p-invalid': isFormFieldValid('noOfLeaves') }, 'Dropdown__Round')}
                                                />
                                                {getFormErrorMessage('noOfLeaves')}
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="channelName" className={classNames({ 'p-error': isFormFieldValid('channelName') }, 'Label__Text')}>
                                                    Waiver
                                                </label>
                                                <div className="flex">
                                                    <div className="mt-2 mr-5">
                                                        <RadioButton inputId="chequeApplicable" name="chequeWaiver" value="Y" onChange={formik.handleChange} disabled checked={formik.values.chequeWaiver === 'Y'} />
                                                        <label htmlFor="chequeApplicable" className={classNames({ 'p-error': isFormFieldValid('applicable') }, 'Label__Text')}>
                                                            Applicable
                                                        </label>
                                                    </div>
                                                    <div className="mt-2">
                                                        <RadioButton inputId="chequeNotApplicable" name="chequeWaiver" value="N" onChange={formik.handleChange} disabled checked={formik.values.chequeWaiver === 'N'} />
                                                        <label htmlFor="chequeNotApplicable" className={classNames({ 'p-error': isFormFieldValid('notApplicable') }, 'Label__Text')}>
                                                            Not Applicable
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {formik.values.debitCard && (
                                    <>
                                        <div className="m-3 Form__Header Full__Width">
                                            <p>ATM / DEBIT CARD DEATILS</p>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="debitCardName" className={classNames({ 'p-error': isFormFieldValid('debitCardName') }, 'Label__Text')}>
                                                    Name
                                                </label>
                                                <InputText
                                                    name="debitCardName"
                                                    id="debitCardName"
                                                    value={formik.values.debitCardName}
                                                    onChange={formik.handleChange}
                                                    disabled
                                                    className={classNames({ 'p-invalid': isFormFieldValid('debitCardName') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('debitCardName')}
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="debitCardAddress" className={classNames({ 'p-error': isFormFieldValid('debitCardAddress') }, 'Label__Text')}>
                                                    Postal Address
                                                </label>
                                                <InputText
                                                    name="debitCardAddress"
                                                    id="debitCardAddress"
                                                    value={formik.values.debitCardAddress}
                                                    onChange={formik.handleChange}
                                                    disabled
                                                    className={classNames({ 'p-invalid': isFormFieldValid('debitCardAddress') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('debitCardAddress')}
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="channelName" className={classNames({ 'p-error': isFormFieldValid('channelName') }, 'Label__Text')}>
                                                    Waiver
                                                </label>
                                                <div className="flex">
                                                    <div className="mt-2 mr-5">
                                                        <RadioButton inputId="applicable" name="atmWaiver" value="Y" onChange={formik.handleChange} disabled checked={formik.values.atmWaiver === 'Y'} />
                                                        <label htmlFor="applicable" className={classNames({ 'p-error': isFormFieldValid('applicable') }, 'Label__Text')}>
                                                            Applicable
                                                        </label>
                                                    </div>
                                                    <div className="mt-2">
                                                        <RadioButton inputId="notApplicable" name="atmWaiver" value="N" onChange={formik.handleChange} disabled checked={formik.values.atmWaiver === 'N'} />
                                                        <label htmlFor="notApplicable" className={classNames({ 'p-error': isFormFieldValid('notApplicable') }, 'Label__Text')}>
                                                            Not Applicable
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    <div className="p-fluid p-formgrid grid mb-3">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="pricingProfileId" className={classNames({ 'p-error': isFormFieldValid('pricingProfileId') }, 'Label__Text')}>
                                    Compliance Link Screening <span className="Label__Required">*</span>
                                </label>
                                <InputText id="pricingProfileId" placeholder="Select Compliance Link Screening" name="pricingProfileId" disabled className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileId') }, 'Input__Round')} />
                                {getFormErrorMessage('pricingProfileId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="blackListing" className={classNames({ 'p-error': isFormFieldValid('blackListing') }, 'Label__Text')}>
                                    Blacklisting Agent Checks <span className="Label__Required">*</span>
                                </label>
                                <InputText id="blackListing" placeholder="N" name="blackListing" disabled className={classNames({ 'p-invalid': isFormFieldValid('blackListing') }, 'Input__Round')} />
                                {getFormErrorMessage('blackListing')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="ecib" className={classNames({ 'p-error': isFormFieldValid('ecib') }, 'Label__Text')}>
                                    ECIB <span className="Label__Required">*</span>
                                </label>
                                <InputText id="ecib" placeholder="Mock" name="ecib" disabled className={classNames({ 'p-invalid': isFormFieldValid('ecib') }, 'Input__Round')} />
                                {getFormErrorMessage('ecib')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="bvs" className={classNames({ 'p-error': isFormFieldValid('bvs') }, 'Label__Text')}>
                                    Biometric Verification <span className="Label__Required">*</span>
                                </label>
                                <InputText id="bvs" name="bvs" placeholder="Select BVS" value={formik.values.bvs} disabled className={classNames({ 'p-invalid': isFormFieldValid('bvs') }, 'Input__Round')} />
                                {getFormErrorMessage('bvs')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-3">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <div className="mb-2">
                                    <label className="Label__Text">Key Fact Statement</label>
                                    <div className="file-input-container w-100">
                                        <input type="file" style={{ display: 'none' }} disabled />
                                        <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={kfsInput} readOnly disabled />
                                        <span className="file-upload-icon">
                                            <i className="pi pi-paperclip" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {docData && docData?.length > 0 && (
                                <React.Fragment>
                                    {docData[0] && (
                                        <div className="Down__Btn">
                                            <Button
                                                type="button"
                                                label="View Document"
                                                className="Btn__Dark"
                                                onClick={() => {
                                                    window.open(kfsLink, '_blank');
                                                }}
                                                disabled={!kfsInput}
                                            />
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <div className="mb-2">
                                    <label className="Label__Text">Digital Signing</label>
                                    <div className="file-input-container w-100">
                                        <input type="file" style={{ display: 'none' }} disabled />
                                        <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={digitalSigningInput} readOnly disabled />
                                        <span className="file-upload-icon">
                                            <i className="pi pi-paperclip" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {docData && docData?.length > 0 && (
                                <React.Fragment>
                                    {docData[1] && (
                                        <div className="Down__Btn">
                                            <Button
                                                type="button"
                                                label="View Document"
                                                className="Btn__Dark"
                                                onClick={() => {
                                                    window.open(digitalSigningLink, '_blank');
                                                }}
                                                disabled={!digitalSigningInput}
                                            />
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                        {formik.values.addressInfos?.map((item, index) => {
                            return (
                                <div className="p-field col-12 md:col-4 pt-3 pb-3" key={index}>
                                    <div className="p-field">
                                        <label htmlFor={`addressInfos.${index}.fullAddress`} className={classNames({ 'p-error': isFormFieldValid(`addressInfos.${index}.fullAddress`) }, 'Label__Text')}>
                                            Address <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id={`addressInfos.${index}.fullAddress`}
                                            name={`addressInfos.${index}.fullAddress`}
                                            placeholder="Enter Adds"
                                            value={item?.fullAddress || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid(`addressInfos.${index}.fullAddress`) }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage(`addressInfos.${index}.fullAddress`)}
                                    </div>
                                </div>
                            );
                        })}

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="fatherName" className={classNames({ 'p-error': isFormFieldValid('fatherName') }, 'Label__Text')}>
                                    Father Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="fatherName"
                                    name="fatherName"
                                    placeholder="Enter Father Name"
                                    value={formik.values.fatherName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('fatherName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('fatherName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="motherName" className={classNames({ 'p-error': isFormFieldValid('motherName') }, 'Label__Text')}>
                                    Mother Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="motherName"
                                    name="motherName"
                                    placeholder="Enter Mother Name"
                                    value={formik.values.motherName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('motherName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('motherName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountStatus" className={classNames({ 'p-error': isFormFieldValid('accountStatus') }, 'Label__Text')}>
                                    Status <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="accountStatus"
                                    placeholder="Select Status"
                                    options={accountStatus}
                                    optionLabel="accountStatusName"
                                    name="accountStatus"
                                    optionValue="accountStatusId"
                                    value={formik.values.accountStatus || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountStatus') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('accountStatus')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountNumber" className={classNames({ 'p-error': isFormFieldValid('accountNumber') }, 'Label__Text')}>
                                    Account Number <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="accountNumber"
                                    name="accountNumber"
                                    placeholder="Select Account Number"
                                    value={formik.values.accountNumber || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountNumber') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('accountNumber')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountTitle" className={classNames({ 'p-error': isFormFieldValid('accountTitle') }, 'Label__Text')}>
                                    Account Title <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="accountTitle"
                                    name="accountTitle"
                                    placeholder="Select Account Number"
                                    value={formik.values.accountTitle || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountTitle') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('accountTitle')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="crpScore" className={classNames({ 'p-error': isFormFieldValid('crpScore') }, 'Label__Text')}>
                                    CRP Score <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="crpScore"
                                    name="crpScore"
                                    placeholder="Please Enter Crp Score"
                                    value={formik.values.crpScore || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('crpScore') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('crpScore')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="crpRating" className={classNames({ 'p-error': isFormFieldValid('crpRating') }, 'Label__Text')}>
                                    CRP Rating <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="crpRating"
                                    name="crpRating"
                                    placeholder="Please Enter Crp Rating"
                                    value={formik.values.crpRating || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('crpRating') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('crpRating')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="birthPlace" className={classNames({ 'p-error': isFormFieldValid('birthPlace') }, 'Label__Text')}>
                                    Place of Birth <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    id="birthPlace"
                                    name="birthPlace"
                                    placeholder="Enter Birth Place"
                                    value={formik.values.birthPlace || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('birthPlace') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('birthPlace')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="ecib" className="Label__Text">
                                    ECIB Scores
                                </label>
                                <InputText id="ecib" name="ecib" disabled className="Input__Round" placeholder="Please Enter Ecib Score" value={formik.values.ecib} onChange={formik.handleChange} />
                                {getFormErrorMessage('ecib')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="plus30" className={classNames({ 'p-error': isFormFieldValid('plus30') }, 'Label__Text')}>
                                    ECIB Plus 30 <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="plus30"
                                    name="plus30"
                                    placeholder="Select ECIB Response"
                                    value={formik.values.plus30 || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('plus30') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('plus30')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="plus60" className={classNames({ 'p-error': isFormFieldValid('plus60') }, 'Label__Text')}>
                                    ECIB Plus 60 <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="plus60"
                                    name="plus60"
                                    placeholder="Select ECIB Response"
                                    value={formik.values.plus60 || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('plus60') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('plus60')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="plus90" className={classNames({ 'p-error': isFormFieldValid('plus90') }, 'Label__Text')}>
                                    ECIB Plus 90 <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="plus90"
                                    name="plus90"
                                    placeholder="Select ECIB Response"
                                    value={formik.values.plus90 || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('plus90') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('plus90')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="plus120" className={classNames({ 'p-error': isFormFieldValid('plus120') }, 'Label__Text')}>
                                    ECIB Plus 120 <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="plus120"
                                    name="plus120"
                                    placeholder="Select ECIB Response"
                                    value={formik.values.plus120 || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('plus120') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('plus120')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="plus150" className={classNames({ 'p-error': isFormFieldValid('plus150') }, 'Label__Text')}>
                                    ECIB Plus 150 <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="plus150"
                                    name="plus150"
                                    placeholder="Select ECIB Response"
                                    value={formik.values.plus150 || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('plus150') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('plus150')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="plus180" className={classNames({ 'p-error': isFormFieldValid('plus180') }, 'Label__Text')}>
                                    ECIB Plus 180 <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="plus180"
                                    name="plus180"
                                    placeholder="Select ECIB Response"
                                    value={formik.values.plus180 || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('plus180') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('plus180')}
                            </div>
                        </div>
                    </div>

                    {isEditCase && (
                        <React.Fragment>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                            Action
                                            <span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="action"
                                            placeholder="Select Action"
                                            options={statusLovData}
                                            optionLabel="name"
                                            name="action"
                                            optionValue="lovId"
                                            value={formik.values.action || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('action')}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                            Comments
                                            <span className="Label__Required">*</span>
                                        </label>
                                        <InputTextarea
                                            maxLength={500}
                                            rows={5}
                                            cols={30}
                                            id="checkerComments"
                                            name="checkerComments"
                                            value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                        />

                                        {getFormErrorMessage('checkerComments')}
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 justify-content-center pt-4 gap-4" style={{ marginBottom: '30px' }}>
                        <div
                            className={apiData?.updateAgentAccountStatusRequest?.settle === false ? 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons bg_dim' : 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons'}
                            onClick={apiData?.updateAgentAccountStatusRequest?.settle === false ? handleCloseAccount : null}
                        >
                            <div>Close Account</div>
                        </div>
                        <div
                            className={apiData?.updateAgentAccountStatusRequest?.settle === true ? 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons bg_dim' : 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons'}
                            onClick={apiData?.updateAgentAccountStatusRequest?.settle === true ? handleSetleAccount : null}
                        >
                            <div>Close Settle Account</div>
                        </div>
                        <div className={apiData && apiData.hasOwnProperty('regenerateAgentLoginPinRequest') ? 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons bg_dim' : 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons'}>
                            <div>Regenerate Login Pin</div>
                        </div>
                        <div
                            className={apiData && apiData.hasOwnProperty('system aur nizam') ? 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons bg_dim' : 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons'}
                            onClick={apiData && apiData.hasOwnProperty('asjkhdsjahdksha') ? handleAccountStatement : null}
                        >
                            <div>Account Statement</div>
                        </div>
                    </div>
                    {!isEditCase ? (
                        <div className="Down__Btn my-5">
                            <Button type="button" label="Okay" className="Btn__Dark" onClick={() => navigate('/requestchecker')} />
                        </div>
                    ) : (
                        <div className="Down__Btn">
                            <Button disabled={loading} label="Update" className="Btn__Dark" />
                            <Button type="button" onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                        </div>
                    )}
                </form>
            </div>
            {/* Action Authorization History */}

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            emptyMessage="No List found."
                            header="Action Authorization History"
                            responsiveLayout="scroll"
                            value={historyData}
                        >
                            <Column field="escalationLevel" header="Excalation Level" />
                            <Column field="checkedBy" header="Checked By" />
                            <Column field="checkedOn" header="Checked On" />
                            <Column field="authorizerComments" header="Authorizor Comments" />
                            <Column field="intimationOn" header="Intimation On" />
                            <Column field="intimationTo" header="Intimation To" />
                            <Column field="authorizationStatus" header="Authorization Status" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FranchiseChecker;

import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { handlePostRequest } from '../../../../services/PostTemplate';

const EditLocation = () => {
    const location = useLocation();
    const { rowData } = location.state || {};

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        lkpBranchName: Yup.string().required('This field is required'),
        lkpBranchDescription: Yup.string().required('This field is required'),
        lkpBranchCode: Yup.string().required('This field is required'),
        coaCode: Yup.string().required('This field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            lkpBranchName: '',
            lkpBranchDescription: '',
            lkpBranchCode: '',
            coaCode: ''
        },
        validate: (data) => {
            let errors = {};
            //////////////////////////lkpBranchName////////////////////////
            if (!data?.lkpBranchName) {
                errors.lkpBranchName = 'This field is required';
            } else if (data?.lkpBranchName === '') {
                errors.lkpBranchName = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.lkpBranchName)) {
                errors.lkpBranchName = 'Leading and trailing space is not allowed';
            } else if (!/^[a-zA-Z0-9\s]+$/.test(data?.lkpBranchName)) {
                errors.lkpBranchName = 'Only alphanumeric characters and spaces are allowed';
            }

            //////////////////////////lkpBranchCode////////////////////////
            if (!data?.lkpBranchCode) {
                errors.lkpBranchCode = 'This field is required';
            } else if (data?.lkpBranchCode === '') {
                errors.lkpBranchCode = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.lkpBranchCode)) {
                errors.lkpBranchCode = 'Leading and trailing space is not allowed';
            } else if (!/^[a-zA-Z0-9\s]+$/.test(data?.lkpBranchCode)) {
                errors.lkpBranchCode = 'Only alphanumeric characters are allowed';
            }

            //////////////////////////coaCode////////////////////////
            if (!data?.coaCode) {
                errors.coaCode = 'This field is required';
            } else if (data?.coaCode === '') {
                errors.coaCode = 'This Field should not be empty';
            } else if (!/^[0-9]+$/.test(data?.coaCode)) {
                errors.coaCode = 'Only numeric characters are allowed';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.coaCode)) {
                errors.coaCode = 'Leading and trailing space is not allowed';
            } else if (/\s/.test(data?.coaCode)) {
                errors.coaCode = 'Spaces in between digits are not allowed';
            } else if (!/^[0-9]{5}$/.test(data?.coaCode)) {
                errors.coaCode = 'Numbers should be in between 00000 to 99999';
            }

            return errors;
        },

        onSubmit: async (data) => {
            const coaPayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        lkpBranchId: rowData?.branchId,
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(coaPayload, '/account/v1/chartofaccount/updatelkpbranch', true, false));
            if (res?.responseCode === '010000') {
                navigate(-1);
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        if (rowData) {
            formik.setFieldValue('lkpBranchName', rowData?.branchName);
            formik.setFieldValue('lkpBranchDescription', rowData?.branchDescr);
            formik.setFieldValue('lkpBranchCode', rowData?.branchCode);
            formik.setFieldValue('coaCode', rowData?.coaCode);
        }
    }, [rowData]);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>Edit Details</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Location Name</label>
                                <InputText id="lkpBranchName" value={formik.values.lkpBranchName || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('lkpBranchName') }, 'Input__Round')} maxLength={30} />
                                {getFormErrorMessage('lkpBranchName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Location Code</label>
                                <InputText id="lkpBranchCode" value={formik.values.lkpBranchCode || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('lkpBranchCode') }, 'Input__Round')} maxLength={20} />
                                {getFormErrorMessage('lkpBranchCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Location Description</label>
                                <InputText
                                    id="lkpBranchDescription"
                                    value={formik.values.lkpBranchDescription || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpBranchDescription') }, 'Input__Round')}
                                    maxLength={199}
                                />
                                {getFormErrorMessage('lkpBranchDescription')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Coa Code</label>
                                <InputText id="coaCode" value={formik.values.coaCode || ''} onChange={formik.handleChange} disabled={true} className={classNames({ 'p-invalid': isFormFieldValid('coaCode') }, 'Input__Round')} maxLength={30} />
                                {getFormErrorMessage('coaCode')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Submit" className="Btn__Dark" />
                        <Button onClick={() => navigate('/location')} label="Cancel" className="Btn__Transparent" type="button" />
                    </div>
                </form>
            </div>
        </>
    );
};

export default EditLocation;

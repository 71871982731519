import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { InputTextarea } from 'primereact/inputtextarea';
import classNames from 'classnames';
import { MultiSelect } from 'primereact/multiselect';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { loadingAction } from '../../../../redux/actions/loadingAction';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import DynamicKYCFields from '../DynamicKYC/DynamicKYCFields';
import ScrollMover from '../../../../components/ScrollMover';
import AccountStatementDialog from './AccountStatementDialog';
import CloseAccountDialog from './CloseAccountDialog';
import AccountSettelmentDialog from './AccountSettelmentDialog';
import { Dialog } from 'primereact/dialog';
import RemarksDailog from './RemarksDailog';

function EditViewCorporate({ isEditable, apiData }) {
    const [loading, setloading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    const [pricingProfile, setPricingProfile] = useState([]);
    const [commissionProfile, setCommissionProfile] = useState([]);
    const [employeeName, setEmployeeName] = useState('');
    const [limitProfile, setLimitProfile] = useState([]);
    const [channelOption, setChannelOption] = useState([]);
    const [parentAgentData, setParentAgentData] = useState([]);
    const [productcatalog, setProductcatalog] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [modeofoperations, setOperationMode] = useState([]);
    const [kycFields, setKycFields] = useState([]);
    const accountClassificationName = apiData?.lkpAccountClassification?.accountClassificationName;
    const accountClassificationId = apiData?.lkpAccountClassification?.accountClassificationId;
    const [tblKycSetDetails, setTblKycSetDetails] = useState([]);
    const [nearestBranch, setNearestBranch] = useState([]);
    const [dFormValues, setDFormValues] = useState([]);
    const [isRequired, setIsRequired] = useState([]);
    const [show, setShow] = useState(false);
    const [showBtn, setShowBtn] = useState(true);
    const [kycSetHeadId, setKycSetHeadId] = useState('');
    const [useCaseError, setUseCaseError] = useState({
        dobCNIC: '',
        cnic: '',
        mobileNumber: ''
    });
    const [accountStatus, setAccountStatus] = useState();
    const [accountStatementDialogOpen, setAccountStatementDialogOpen] = useState(false);
    const [accountSettleDialogOpen, setAccountSettleDialogOpen] = useState(false);
    const [closeAccountDialog, setCloseAccountDialog] = useState(false);

    const kycFixed = ['name', 'address', 'ntn', 'contactPersonCnic', 'contactPersonMobileNumber', 'contactPersonCnicIssuanceDate'];

    const chequeBookLeaves = [
        { name: '25', value: '25' },
        { name: '50', value: '50' },
        { name: '100', value: '100' },
        { name: '250', value: '250' }
    ];

    const handleInputChangeNumber = (e, type) => {
        formik.setFieldValue(type, e.target.value);
    };
    function getMaxRegexLength(regex) {
        // Remove leading and trailing slashes from the regular expression.
        const regexPattern = regex.toString().slice(1, -1);

        // Use a regular expression to find the maximum length.
        const lengthRegex = /\{(\d+)(,(\d+))?\}/g;
        let maxLength = 0;

        let match;
        while ((match = lengthRegex.exec(regexPattern)) !== null) {
            const min = parseInt(match[1], 10);
            const max = match[3] ? parseInt(match[3], 10) : min;
            if (max > maxLength) {
                maxLength = max;
            }
        }

        return maxLength;
    }

    useEffect(() => {
        const getKycFields = async () => {
            const res = await handleGetRequest(`/account/v1/agentaccount/getkycbyclassificationname/${accountClassificationName}`);
            setKycFields(res?.payLoad);

            setKycSetHeadId(res.payLoad[0].kycSetHeadId);
            let tblkyc = res?.payLoad[0].tblKycSetDetails;

            setTblKycSetDetails(tblkyc);
        };
        getKycFields();
    }, [accountClassificationName]);

    const initialValues = {
        // pricingProfileId: '',
        commissionId: '',
        transLimitId: '',
        parentAgentId: '',
        channelIdList: [],
        empId: '',
        productCatalogId: '',
        chequeBook: false,
        noOfLeaves: '',
        chequeWaiver: 'N',
        employeeName: '',
        bvs: '',
        motherName: '',
        fatherName: '',
        addressInfos: [
            {
                addressId: '',
                fullAddress: ''
            }
        ],
        accountStatus: '',
        accountNumber: '',
        accountTitle: '',
        remarks: ''
    };

    const validationSchema = Yup.object({
        channelIdList: Yup.array().required('This Field is required').min(1, 'This Field is required'),
        empId: Yup.string().required('This Field is required'),
        // pricingProfileId: Yup.string().required('This Field is required'),
        transLimitId: Yup.string().required('This Field is required'),
        productCatalogId: Yup.string().required('This Field is required'),
        ...tblKycSetDetails.reduce((fieldValidations, result) => {
            const attributeName = result?.tblKycAttribute?.attributeName;
            const regexExpression = result?.tblKycAttribute;
            const date = result?.tblKycAttribute?.attributeName.toLowerCase().includes('date');
            const regExp = new RegExp(regexExpression.tblValidator.argumentValue);
            const maxLength = getMaxRegexLength(regExp);

            if (kycFixed?.includes(result?.tblKycAttribute?.attributeName) || (result.isMandatory === 'Y' && result?.isActive === 'Y')) {
                // Validate that the field is not empty
                fieldValidations[attributeName] = Yup.string().test('dynamicValidation', 'Please Enter valid Length', function (value) {
                    // Check if a value is entered and apply regular expression validation
                    if (value) {
                        if (value.length > maxLength && !date) {
                            if (maxLength > 0) {
                                return this.createError({
                                    path: this.path,
                                    message: `Value exceeds the maximum length of ${maxLength}`
                                });
                            }
                        }

                        return regExp.test(value);
                    } else {
                        // If no value is entered and the field is mandatory, show it as required
                        if (kycFixed?.includes(result?.tblKycAttribute?.attributeName) || result.isMandatory === 'Y') {
                            return this.createError({
                                path: this.path,
                                message: 'This Field is required'
                            });
                        }

                        // If no value is entered and the field is not mandatory, no error is shown.
                        return true;
                    }
                });
            } else if (result?.isActive === 'Y' && !date) {
                // If the item is not mandatory but active, only apply the regex validation
                fieldValidations[attributeName] = Yup.string().test('dynamicValidation', 'Please Enter valid Length', function (value) {
                    if (value) {
                        // Check if value is not empty
                        if (value.length > maxLength && !date) {
                            if (maxLength > 0) {
                                return this.createError({
                                    path: this.path,
                                    message: `Value exceeds the maximum length of ${maxLength}`
                                });
                            }
                        }

                        return regExp.test(value);
                    }
                    // If the value is empty, no error is shown.
                    return true;
                });
            }

            return fieldValidations;
        }, {})
        //   dobCNIC:Yup.string().max(200,'Maximum length is 200'),
    });

    const formik = useFormik({
        initialValues: initialValues,

        validationSchema,

        onSubmit: async (data, actions) => {
            delete data['crpScore'];
            delete data['crpRating'];
            delete data['ecib'];
            delete data['birthPlace'];
            delete data['d k'];

            const { dobCNIC, dCnic, dMobileNumber, dName, channelIdList, modeofoperations, employeeName, ...rest } = data;
            let nonRequiredFields = [];
            // let transformedArr = dFormValues
            //     .map((item) => {
            //         // Destructure the object to remove 'name' and 'identifier'
            //         if (item.isActive == 'Y') {
            //             const { name, identifier, dobCNIC, isActive, cnic, ...rest } = item;
            //             // Rename 'dobCNIC' key to 'cnicissuancedate' and create a new object

            //             return {
            //                 ...rest,
            //                 cnic: cnic,
            //                 cnicIssuanceDate: dobCNIC
            //             };
            //         } else {
            //         }
            //     })
            //     .filter((isActive) => isActive != null);

            tblKycSetDetails?.forEach((item) => {
                if (!kycFixed?.includes(item?.tblKycAttribute?.attributeName) && item?.isActive === 'Y') {
                    // If isMandatory is "N", add to nonRequiredFields
                    nonRequiredFields.push({
                        infoKey: item?.tblKycAttribute?.attributeName,
                        infoValue: formik.values[item?.tblKycAttribute?.attributeName]
                    });
                    delete rest[item?.tblKycAttribute?.attributeName];
                }
            });
            // let { kycFixed, ...newRest } = rest;
            let { contactPersonCnic, contactPersonCnicIssuanceDate, address, name, ntn, contactPersonMobileNumber, ...newRest } = rest;

            let payload = {
                ...newRest,
                channelIdList: data.channelIdList.map((res) => res.lovId),
                agentId: apiData?.agentId,
                // agentDirectorRequestList: transformedArr,
                accountClassificationName: accountClassificationName,
                contact: false
            };

            delete data['crpScore'];
            delete data['crpRating'];
            delete data['ecib'];
            delete data['birthPlace'];
            delete payload['d k'];
            delete payload['contactPersonEmail'];

            setloading(true);
            payload['chequeBook'] = formik?.values?.chequeBook ? 'Y' : 'N';
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: payload,
                    additionalInformation:
                        nonRequiredFields.length > 0
                            ? nonRequiredFields
                            : [
                                  {
                                      infoKey: '',
                                      infoValue: ''
                                  }
                              ],

                    checkSum: ''
                }
            };
            // delete newData?.data?.payLoad?.pricingProfileId;
            delete newData?.data?.payLoad?.parentAgentId;
            delete newData?.data?.payLoad?.noOfLeaves;
            delete newData?.data?.payLoad?.chequeBook;
            delete newData?.data?.payLoad?.chequeWaiver;
            delete newData?.data?.payLoad?.bvs;

            setloading(false);
            // if (transformedArr?.length <= 0) {
            //     toast.warn('Please enter cnic Number and Cnic issuance');
            // } else {
            //     const res = await dispatch(handlePostRequest(newData, '/account/v1/customeraccounts/agentmaintenance', true, false, 'accountsmanagement'));
            //     if (res.responseCode === '010000') {
            //         actions.resetForm();
            //     } else {
            //     }
            // }
            await dispatch(handlePostRequest(newData, '/account/v1/customeraccounts/agentmaintenance', true, false, 'accountsmanagement'));

            formik.setFieldValue('chequeBook', data['chequeBook'] === 'Y' ? true : false);
        }
    });

    const searchEmployeeId = async () => {
        setEmployeeName('');
        setUseCaseError({ empId: '' });
        if (formik.values.empId === '') {
            setUseCaseError({ empId: 'This field is required' });
        }
        const id = formik.values.empId;
        if (id) {
            dispatch(loadingAction(true));
            const res = await handleGetRequest(`/account/v1/sales/getemployeebyid/${id}`);

            if (res == undefined) {
                setTimeout(() => dispatch(loadingAction(false)), 1000);
                formik.setFieldValue('employeeName', '');
            } else if (res) {
                setTimeout(() => dispatch(loadingAction(false)), 1000);
                setTimeout(() => formik.setFieldValue('employeeName', res?.payLoad[0]?.salesHierarchyName));
            }
        }
    };

    const panelFooterTemplate = () => {
        const length = formik.values.channelIdList ? formik.values.channelIdList.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = res?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    //pricing profile dropdown options API
    const getPricingProfile = async () => {
        const res = await handleGetRequest(`/account/v1/agentaccount/getpricingprofilebyclassification/${accountClassificationId}`);
        setPricingProfile(res?.payLoad);
    };

    const getProductCatalog = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/productCatalog/TBL_PRODUCT_CATALOG:*');
        setProductcatalog(res?.payLoad);
    };
    //limit profile dropdown options API
    const getLimitProfile = async () => {
        const res = await handleGetRequest(`/account/v1/agentaccount/gettranslimitbyclassification/${accountClassificationId}`);
        setLimitProfile(res?.payLoad);
    };
    const getChannel = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchannels/LKP_CHANNEL:*');
        setChannelOption(res?.payLoad);
    };
    const getNearest = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getallbranches/LKP_BRANCH:*');
        setNearestBranch(res?.payLoad);
    };

    const getCoperateEditData = () => {
        Object.keys(apiData).forEach((key) => {
            if (formik.initialValues.hasOwnProperty(key)) {
                formik.setFieldValue(key, apiData[key]);
            }
        });

        setCommissionProfile([{ commissionProfileId: apiData?.tblCommissionProfile?.commissionProfileId, commissionProfileName: apiData?.tblCommissionProfile?.commissionProfileName }]);
        // // setPricingProfile([{ pricingProfileId: apiData?.tblPricingProfile?.pricingProfileId, pricingProfileName: apiData?.tblPricingProfile?.pricingProfileName.toString() }]);
        setLimitProfile([{ transLimitId: apiData?.tblTransLimit?.transLimitId, limitProfileName: apiData?.tblTransLimit?.limitProfileName }]);
        formik.setFieldValue('employeeName', apiData?.tblSalesHierarchy.hierarchyName);

        const channelOptions = apiData?.tblAgentChannelList
            ?.filter((entry) => entry?.isActive === 'Y')
            .map((entry) => ({
                lovId: entry?.lkpChannel?.channelId?.toString()
            }));

        setProductcatalog([{ lovId: apiData?.tblAgentChannelList[0]?.tblProductCatalog?.productCatalogId.toString(), name: apiData?.tblAgentChannelList[0]?.tblProductCatalog?.projectCatalogName }]);

        setChannelOption(channelOptions);

        let directorList = apiData?.tblAgentDirectorList.map((res) => {
            return { name: res.name, cnic: res.cnic, dobCNIC: res.cnicIssuanceDate, mobileNumber: res.mobileNo, isActive: res.isActive };
        });
        setDFormValues(directorList);
        if (directorList.length >= 2) {
            // setOperationMode([{ name: 'Multiple', value: '2' }]);
            formik.setFieldValue('modeofoperations', '2');
        } else {
            // setOperationMode([{ name: 'Single', value: '1' }]);
            formik.setFieldValue('modeofoperations', '1');
        }
        //       setNearestBranch([{lovId:apiData.lkpBranch.branchId.toString(),name:apiData.lkpBranch.branchName}])
        setParentAgentData([{ name: 'Zindagi', value: '1' }]);
        if (apiData?.kycView) {
            let kycView = JSON.parse(apiData?.kycView);

            Object.keys(kycView).forEach((key) => {
                formik.setFieldValue(key, kycView[key]);
            });
        }
        formik.setFieldValue('accountStatus', apiData?.accountStatus?.toString());
        formik.setFieldValue('motherName', apiData?.motherName);
        formik.setFieldValue('accountNumber', apiData?.accountNumber);
        formik.setFieldValue('crpScore', apiData?.crpScore);
        formik.setFieldValue('crpRating', apiData?.crpRating);
        formik.setFieldValue('addressInfos', apiData?.addressInfos);
        formik.setFieldValue('birthPlace', apiData?.birthPlace);
        formik.setFieldValue('dName', apiData?.tblAgentDirectorList[0]?.name);
        formik.setFieldValue('accountTitle', apiData?.accountTitle);
        // // formik.setFieldValue('pricingProfileId', apiData?.tblPricingProfile?.pricingProfileId);
        formik.setFieldValue('commissionId', apiData?.tblCommissionProfile?.commissionProfileId);
        formik.setFieldValue('transLimitId', apiData?.tblTransLimit?.transLimitId);
        formik.setFieldValue('chequeBook', apiData?.chequeBook === 'Y');
        formik.setFieldValue('channelIdList', channelOptions);
        formik.setFieldValue('parentAgentId', '1');
        formik.setFieldValue('empId', apiData?.tblSalesForce?.employeeId);
        formik.setFieldValue('productCatalogId', apiData?.tblAgentChannelList[0]?.tblProductCatalog?.productCatalogId?.toString());
        formik.setFieldValue('bvs', (apiData?.bvs === 'N' || apiData?.bvs == null ? 'N' : 'Y') || '');
        formik.setFieldValue('ecib', apiData?.ecib);
    };

    const handleInputChange = (e) => {
        let { name, value } = e.target;

        if (name === 'dMobileNumber') {
            if (/^[0-9]*$/.test(value)) {
                if (value.length === 0) {
                    value = '0';
                } else if (value.charAt(0) !== '0') {
                    value = '0' + value; // Prepend '0' to the value
                }
            }

            // Update the form field with the modified value
            formik.setFieldValue(name.toString(), value);

            name = 'mobileNumber';
        } else if (name === 'dCnic') {
            name = 'cnic';
            formik.handleChange(e);
        } else if (name === 'dobCNIC') {
            name = 'dobCNIC';
            formik.handleChange(e);
        } else if (name === 'empId') {
            name = 'empId';
            formik.handleChange(e);
        }
        // Create a new object with the updated error for the specific field
        const updatedErrors = { ...useCaseError, [name]: '' };
        setUseCaseError(updatedErrors);

        //
    };
    const resetFormFields = () => {
        // Replace these with the initial values of your form fields
        const initialFormValues = {
            dCnic: '',
            dobCNIC: '',
            dMobileNumber: ''
        };

        // Use the Formik setValues or similar method to reset the form fields
        formik.setValues(initialFormValues);
    };

    const handleTableSubmit = (event, yup) => {
        // Create a new object representing the form data
        const newFormData = {
            // name: formik.values.dName,
            cnic: formik.values.dCnic,
            dobCNIC: formik.values.dobCNIC,
            mobileNumber: formik.values.dMobileNumber,
            identifier: Date.now(),
            isActive: 'Y'
        };

        const isDuplicateCNIC = dFormValues.some((data) => data.cnic === newFormData.cnic);
        const isDuplicateMobileNumber = dFormValues.some((data) => data.mobileNumber === newFormData.mobileNumber);

        // Check if the same data already exists in dFormValues
        const isDuplicate = dFormValues.some((data) => {
            return data.cnic === newFormData.cnic && data.dobCNIC === newFormData.dobCNIC && data.mobileNumber === newFormData.mobileNumber;
        });

        if (isDuplicateCNIC || isDuplicateMobileNumber) {
            toast.warn('Duplicate cnic and mobile not allowed');
        } else {
            const schema = yup.object().shape({
                cnic: yup
                    .string()
                    .required('This Field is Required.')
                    .test('length', 'Value exceeds the maximum length of 13', function (value) {
                        if (value != undefined && value?.length > 13) {
                            return false;
                        }
                        return true;
                    })
                    .test('pattern', 'Please Enter a valid CNIC', function (value) {
                        if (value) {
                            return /\d{13}/.test(value);
                        }
                        return true;
                    }),
                mobileNumber: yup
                    .string()
                    .required('This Field is Required.')
                    .test('length', 'Mobile Number exceeds the maximum length of 11', function (value) {
                        let newValue = value;

                        if (newValue != undefined && newValue?.length > 11) {
                            return false;
                        }
                        return true;
                    })
                    .test('length', 'Mobile Number length is less than 11', function (value) {
                        let newValue = value;

                        if (newValue != undefined && newValue?.length < 11) {
                            return false;
                        }
                        return true;
                    })
                    .test('startsWithZero', 'Mobile number must start with zero', function (value) {
                        if (value != undefined && value.charAt(0) !== '0') {
                            return false;
                        }
                        return true;
                    }),
                dobCNIC: yup.string().required('This Field is Required.')
            });

            schema
                .validate(newFormData, { abortEarly: false }) // Abort early ensures that all errors are collected
                .then(() => {
                    // Validation passed

                    setDFormValues((prevDFormValues) => [...prevDFormValues, newFormData]);
                    formik.setFieldValue('dMobileNumber', '');
                    formik.setFieldValue('dCnic', '');
                    formik.setFieldValue('dobCNIC', '');
                    //
                })
                .catch((error) => {
                    // Validation failed
                    const errors = {};
                    error.inner.forEach((validationError) => {
                        errors[validationError.path] = validationError.message;
                    });
                    setUseCaseError(errors);
                });
        }
    };

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    useEffect(() => {
        if (!isEditable) {
            setOperationMode([
                { name: 'Single', value: '1' },
                { name: 'Multiple', value: '2' }
            ]);

            getStatusListData();
            getCoperateEditData();
            // getCoperateEditData();
            getProductCatalog();
            // getCommissionProfile();
            getLimitProfile();
            getPricingProfile();
            getChannel();
        } else {
            setOperationMode([
                { name: 'Single', value: '1' },
                { name: 'Multiple', value: '2' }
            ]);
            getCoperateEditData();
            getProductCatalog();
            // getCommissionProfile();
            getLimitProfile();
            getPricingProfile();
            getChannel();
        }
    }, [apiData]);

    const handleDelete = (cnic) => {
        const updatedDFormValues = dFormValues.filter((rowData) => rowData.cnic !== cnic);
        setDFormValues(updatedDFormValues);
    };
    const onHide = () => {
        setShow(true);
        setShowBtn(false);
    };

    const getaccountStatus = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getAccountStatus/LKP_ACCOUNT_STATUS:*');
        setAccountStatus(res?.payLoad);
    };

    useEffect(() => {
        getaccountStatus();
    }, []);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const handleSetleAccount = () => {
        setAccountSettleDialogOpen(true);
    };
    const onHidesettleAccount = () => {
        setAccountSettleDialogOpen(false);
    };
    const handleCloseAccount = () => {
        setCloseAccountDialog(true);
    };
    const onHideCloseAccount = () => {
        setCloseAccountDialog(false);
    };

    const handleAccountStatement = () => {
        setAccountStatementDialogOpen(true);
    };
    const onHideAccountStatement = () => {
        setAccountStatementDialogOpen(false);
    };

    const handleGeneratePin = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    agentId: apiData?.agentId
                },
                additionalInformation: [],
                checkSum: ''
            }
        };

        setloading(true);
        const response = await dispatch(handlePostRequest(newData, '/account/v1/agentaccount/regenerateloginpin', true, false, 'accountsmanagement'));
        setloading(false);
    };

    const [remarksDialog, setRemarksDialog] = useState(false);

    const handleRemarksDialog = (e) => {
        e.preventDefault();
        setRemarksDialog(true);
    };

    const onHideRemarksDialog = () => {
        setRemarksDialog(false);
    };
    const remarksValue = formik.values.remarks;
    const statusValue = formik.values.accountStatus;

    const dialogData = (data) => {
        formik.setFieldValue('accountStatus', data?.accountStatus);
        formik.setFieldValue('remarks', data?.remarks);
    };

    return (
        <>
            <Dialog resizable={false} draggable={false} visible={remarksDialog} style={{ width: '30vw' }} onHide={onHideRemarksDialog}>
                <RemarksDailog dialogData={dialogData} onHideRemarksDialog={onHideRemarksDialog} remarksValue={remarksValue} statusValue={statusValue} />
            </Dialog>
            <Dialog resizable={false} draggable={false} visible={accountStatementDialogOpen} onHide={() => setAccountStatementDialogOpen(false)} header="Account Statement" style={{ width: '30vw' }}>
                <AccountStatementDialog onHideAccountStatement={onHideAccountStatement} cnic={apiData?.cnic} mobileNo={apiData?.agentPhoneNo} agentId={apiData?.agentId} accountNumber={apiData?.accountNumber} />
            </Dialog>
            <Dialog resizable={false} draggable={false} visible={closeAccountDialog} onHide={() => setCloseAccountDialog(false)} header="Close Account" style={{ width: '30vw' }}>
                <CloseAccountDialog onHideCloseAccount={onHideCloseAccount} agentId={apiData?.agentId} accountNo={apiData?.accountNumber} />
            </Dialog>
            <Dialog resizable={false} draggable={false} visible={accountSettleDialogOpen} onHide={() => setAccountStatementDialogOpen(false)} header="Account Settelment" style={{ width: '30vw' }}>
                <AccountSettelmentDialog onHidesettleAccount={onHidesettleAccount} agentId={apiData?.agentId} accountNo={apiData?.accountNumber} />
            </Dialog>
            <div className="my-3">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="custom-card p-3">
                        <div className="m-3 Form__Header Full__Width">
                            <p>CORPORATE KYC SETS</p>
                        </div>
                        {kycFields && <DynamicKYCFields kycFields={kycFields} formik={formik} kycFixed={kycFixed} isEditable={isEditable} getFormErrorMessage={getFormErrorMessage} />}
                    </div>

                    {showBtn && isEditable && (
                        <div className="text-center mt-2 mb-2 flex justify-content-center">
                            <Button label="View more" type="button" className="view-button p-button-rounded" onClick={() => onHide()} />
                        </div>
                    )}
                    {(!isEditable || show) && (
                        <>
                            <div className="custom-card mb-5">
                                <div className="mx-5 mt-0 Form__Header Full__Width">
                                    <p>DIRECTOR KYC SETS</p>
                                </div>

                                <div className="p-fluid p-formgrid grid mb-5 mx-5">
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field withoutArrow">
                                            <label htmlFor="dCnic" className="Label__Text">
                                                CNIC
                                                <span className="Label__Required">*</span>
                                            </label>

                                            <InputText
                                                id="dCnic"
                                                name="dCnic"
                                                value={formik.values.dCnic || ''}
                                                onChange={handleInputChange}
                                                className="Input__Round"
                                                maxLength={30}
                                                disabled
                                                type="number"
                                                onWheel={(e) => {
                                                    e.preventDefault(); // Prevent scroll wheel actions for number and cnic inputs.
                                                }}
                                                onFocus={(e) =>
                                                    e.target.addEventListener(
                                                        'wheel',
                                                        function (e) {
                                                            e.preventDefault();
                                                        },
                                                        { passive: false }
                                                    )
                                                }
                                                onKeyUp={(e) => {
                                                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                        e.preventDefault(); // Prevent up and down arrow keys for number and cnic inputs.
                                                    }
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                        e.preventDefault(); // Prevent up and down arrow keys for number and cnic inputs.
                                                    }
                                                }}
                                            />

                                            {/* {getFormErrorMessage('dCnic')} */}
                                            {useCaseError.cnic !== '' ? <small className="p-error">{useCaseError.cnic}</small> : getFormErrorMessage('dCnic')}
                                        </div>
                                    </div>

                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field withoutArrow">
                                            <label htmlFor="dMobileNumber" className="Label__Text">
                                                Mobile Number
                                                <span className="Label__Required">*</span>
                                            </label>

                                            <InputText
                                                id="dMobileNumber"
                                                name="dMobileNumber"
                                                value={formik.values.dMobileNumber || ''}
                                                onChange={handleInputChange}
                                                className="Input__Round"
                                                maxLength={30}
                                                disabled
                                                type="number"
                                                onWheel={(e) => {
                                                    e.preventDefault(); // Prevent scroll wheel actions for number and cnic inputs.
                                                }}
                                                onFocus={(e) =>
                                                    e.target.addEventListener(
                                                        'wheel',
                                                        function (e) {
                                                            e.preventDefault();
                                                        },
                                                        { passive: false }
                                                    )
                                                }
                                                onKeyUp={(e) => {
                                                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                        e.preventDefault(); // Prevent up and down arrow keys for number and cnic inputs.
                                                    }
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                        e.preventDefault(); // Prevent up and down arrow keys for number and cnic inputs.
                                                    }
                                                }}
                                            />

                                            {useCaseError.mobileNumber !== '' ? <small className="p-error">{useCaseError.mobileNumber}</small> : getFormErrorMessage('dMobileNumber')}
                                        </div>
                                    </div>

                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="dobCNIC" className="Label__Text">
                                                CNIC ISSUANCE DATE
                                                <span className="Label__Required">*</span>
                                            </label>

                                            <InputText id="dobCNIC" name="dobCNIC" type="date" value={formik.values.dobCNIC || ''} onChange={handleInputChange} className="Input__Round" maxLength={30} disabled />
                                            {useCaseError.dobCNIC !== '' ? <small className="p-error">{useCaseError.dobCNIC}</small> : getFormErrorMessage('dobCNIC')}
                                            {/* {getFormErrorMessage('dobCNIC')} */}
                                        </div>
                                    </div>

                                    <div className="Usergroup__SearchBtn p-field col-12 md:col-4 pt-3 pb-3 ">
                                        <Button type="button" label="Add" className="Btn__Dark__Search" onClick={(e) => handleTableSubmit(e, Yup)} disabled />
                                    </div>
                                </div>

                                <div className="grid mx-5">
                                    <div className="col-12">
                                        <div className="card">
                                            <DataTable className="datatable-responsive" value={dFormValues.filter((result) => result.isActive != 'N')} emptyMessage="No List found." responsiveLayout="scroll">
                                                <Column field="cnic" header="CNIC" />
                                                {/* <Column field="name" header="Name" /> */}
                                                <Column field="dobCNIC" header="CNIC ISSUANCE DATE" />
                                                <Column field="mobileNumber" header="Mobile Number" />
                                                <Column
                                                    header="Action"
                                                    body={(rowData) => (
                                                        <Button
                                                            type="button"
                                                            disabled
                                                            onClick={() => handleDelete(rowData.cnic)}
                                                            tooltip="Delete"
                                                            tooltipOptions={{ position: 'top' }}
                                                            style={{ marginLeft: '37%' }}
                                                            icon="pi pi-trash"
                                                            className="p-button-rounded p-button-danger"
                                                        />
                                                    )}
                                                    className="Table__ActionHeading__CentredAlign"
                                                />
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-fluid p-formgrid grid mb-5">
                                {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="pricingProfileId" className="Label__Text">
                                            Pricing Profile
                                            <span className="Label__Required">*</span>
                                        </label>

                                        <Dropdown
                                            id="pricingProfileId"
                                            placeholder="Select Pricing Profile"
                                            options={pricingProfile}
                                            optionLabel="pricingProfileName"
                                            name="pricingProfileId"
                                            optionValue="pricingProfileId"
                                            value={formik.values.pricingProfileId || ''}
                                            onChange={formik.handleChange}
                                            className="Dropdown__Round"
                                            disabled
                                        />
                                        {getFormErrorMessage('pricingProfileId')}
                                    </div>
                                </div> */}
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="newmodeofoperations" className="Label__Text">
                                            Mode of Operations
                                        </label>
                                        <Dropdown
                                            id="modeofoperations"
                                            placeholder="Mode of Operations"
                                            options={modeofoperations}
                                            optionLabel="name"
                                            name="modeofoperations"
                                            optionValue="value"
                                            value={formik.values.modeofoperations || ''}
                                            onChange={formik.handleChange}
                                            className="Dropdown__Round"
                                            disabled
                                        />
                                        {/* {getFormErrorMessage('channelName')} */}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="transLimitId" className="Label__Text">
                                            Limit Profile
                                            <span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="transLimitId"
                                            placeholder="Select Limit Profile"
                                            options={limitProfile}
                                            optionLabel="limitProfileName"
                                            name="transLimitId"
                                            optionValue="transLimitId"
                                            value={formik.values.transLimitId || ''}
                                            onChange={formik.handleChange}
                                            className="Dropdown__Round"
                                            disabled={!isEditable}
                                        />

                                        {getFormErrorMessage('transLimitId')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="channelIdList" className={classNames({ 'p-error': isFormFieldValid('channelIdList') }, 'Label__Text')}>
                                            Channel <span className="Label__Required">*</span>
                                        </label>
                                        <MultiSelect
                                            id="channelIdList"
                                            options={channelOption}
                                            name="channelIdList"
                                            optionLabel="name"
                                            optionValue="lovId"
                                            filter
                                            panelFooterTemplate={panelFooterTemplate}
                                            value={formik?.values?.channelIdList?.map((trans) => trans.lovId || '')}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    'channelIdList',
                                                    e.value.map((lovId) => ({ lovId }))
                                                );
                                            }}
                                            disabled={!isEditable}
                                            className={classNames({ 'p-invalid': isFormFieldValid('channelIdList') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('channelIdList')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field withoutArrow">
                                        <label htmlFor="empId" className="Label__Text">
                                            Employee ID<span className="Label__Required">*</span>
                                        </label>
                                        <div className="flex">
                                            <InputText id="empId" name="empId" value={formik.values.empId} onChange={formik.handleChange} className="Input__Round" maxLength={30} disabled={!isEditable} />
                                            <Button icon={loadingIcon || ''} onClick={() => searchEmployeeId()} type="button" className="Btn__Dark border-circle" style={{ height: '35px', width: '35px', marginLeft: '1rem' }} disabled={!isEditable} />
                                        </div>
                                        {useCaseError.empId !== '' ? <small className="p-error">{useCaseError.empId}</small> : ''}
                                        {getFormErrorMessage('empId')}
                                    </div>
                                </div>
                            </div>

                            <div className="custom-card mb-5">
                                <div className="mx-5 mt-0 Form__Header Full__Width">
                                    <p>EMPLOYEE ID</p>
                                </div>

                                <div className="p-fluid p-formgrid grid mb-5 mx-5">
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="channelName" className="Label__Text">
                                                Sales Hierarchy Name
                                                <span className="Label__Required">*</span>
                                            </label>

                                            <InputText id="employeeName" name="employeeName" value={formik.values.employeeName} onChange={formik.handleChange} className="Input__Round" maxLength={30} disabled />

                                            {getFormErrorMessage('employeeName')}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="productCatalogId" className="Label__Text">
                                            Product catalog
                                            <span className="Label__Required">*</span>
                                        </label>

                                        <Dropdown
                                            id="productCatalogId"
                                            placeholder="Select Product Catalog"
                                            options={productcatalog}
                                            optionLabel="name"
                                            name="productCatalogId"
                                            optionValue="lovId"
                                            value={formik.values.productCatalogId || ''}
                                            onChange={formik.handleChange}
                                            className="Dropdown__Round"
                                            disabled={!isEditable}
                                        />
                                        {getFormErrorMessage('productCatalogId')}
                                    </div>
                                </div>

                                <div className="p-field col-12 md:col-4 pt-3 pb-3 mt-5">
                                    <div className="p-field">
                                        <Checkbox inputId="chequeBook" name="chequeBook" checked={formik.values.chequeBook} onChange={formik.handleChange} disabled />
                                        <label htmlFor="chequeBook" className="Label__Text">
                                            Cheque Book
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {formik.values.chequeBook == true ? (
                                <div className="custom-card mb-5">
                                    <div className="mx-5 mt-0 Form__Header Full__Width">
                                        <p>Cheque Book Details</p>
                                    </div>

                                    <div className="p-fluid p-formgrid grid mb-5 mx-5">
                                        {formik.values.chequeBook == true && (
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor="noOfLeaves" className={classNames({ 'p-error': isFormFieldValid('noOfLeaves') }, 'Label__Text')}>
                                                        No of leaves
                                                        <span className="Label__Required">*</span>
                                                    </label>
                                                    <Dropdown
                                                        id="noOfLeaves"
                                                        options={chequeBookLeaves}
                                                        name="noOfLeaves"
                                                        placeholder="Select Number of Leaves"
                                                        optionLabel="name"
                                                        optionValue="value"
                                                        value={formik.values.noOfLeaves}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('noOfLeaves') }, 'Dropdown__Round')}
                                                        disabled
                                                    />
                                                    {getFormErrorMessage('noOfLeaves')}
                                                </div>
                                            </div>
                                        )}

                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="channelName" className="Label__Text">
                                                    Waiver
                                                </label>

                                                <div className="flex">
                                                    <div className="mt-2 mr-5">
                                                        <RadioButton inputId="city1" name="chequeWaiver" value="Y" onChange={formik.handleChange} checked={formik.values.chequeWaiver === 'Y'} disabled />

                                                        <label htmlFor="city1" className="Label__Text">
                                                            Applicable
                                                        </label>
                                                    </div>

                                                    <div className="mt-2">
                                                        <RadioButton inputId="city1" name="chequeWaiver" value="N" onChange={formik.handleChange} checked={formik.values.chequeWaiver === 'N'} disabled />

                                                        <label htmlFor="city1" className="Label__Text">
                                                            Not Applicable
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="complianceLink" className={classNames({ 'p-error': isFormFieldValid('complianceLink') }, 'Label__Text')}>
                                            Compliance Link Screening <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="complianceLink"
                                            placeholder="Select Compliance Link Screening"
                                            name="complianceLink"
                                            value={apiData?.clsVerified === 'Y' ? 'Yes' : apiData?.clsVerified === 'N' ? 'No' : apiData?.clsVerified === null ? 'null' : apiData?.clsVerified || ''}
                                            // onChange={formik.handleChange}
                                            disabled
                                            className={classNames({ 'p-invalid': isFormFieldValid('complianceLink') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('complianceLink')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="ecib" className={classNames({ 'p-error': isFormFieldValid('ecib') }, 'Label__Text')}>
                                            ECIB Score<span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="ecib"
                                            placeholder="Ecib Score"
                                            name="ecib"
                                            value={formik.values.ecib || ''}
                                            onChange={formik.handleChange}
                                            disabled
                                            className={classNames({ 'p-invalid': isFormFieldValid('ecib') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('ecib')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="bvs" className={classNames({ 'p-error': isFormFieldValid('bvs') }, 'Label__Text')}>
                                            Biometric Verification <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="bvs"
                                            name="bvs"
                                            placeholder="Select Parent Name"
                                            value={formik.values.bvs}
                                            onChange={formik.handleChange}
                                            disabled
                                            className={classNames({ 'p-invalid': isFormFieldValid('bvs') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('bvs')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="accountTitle" className={classNames({ 'p-error': isFormFieldValid('accountTitle') }, 'Label__Text')}>
                                            Account Title <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            disabled
                                            id="accountTitle"
                                            name="accountTitle"
                                            placeholder="Enter Account Title"
                                            value={formik.values.accountTitle || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('accountTitle') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('accountTitle')}
                                    </div>
                                </div>
                                {formik.values.addressInfos?.map((item, index) => {
                                    return (
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3" key={index}>
                                            <div className="p-field">
                                                <label htmlFor={`addressInfos.${index}.fullAddress`} className={classNames({ 'p-error': isFormFieldValid(`addressInfos.${index}.fullAddress`) }, 'Label__Text')}>
                                                    Address <span className="Label__Required">*</span>
                                                </label>
                                                <InputText
                                                    disabled
                                                    id={`addressInfos.${index}.fullAddress`}
                                                    name={`addressInfos.${index}.fullAddress`}
                                                    placeholder="Enter Adds"
                                                    value={item?.fullAddress || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid(`addressInfos.${index}.fullAddress`) }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage(`addressInfos.${index}.fullAddress`)}
                                            </div>
                                        </div>
                                    );
                                })}

                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="fatherName" className={classNames({ 'p-error': isFormFieldValid('fatherName') }, 'Label__Text')}>
                                            Father Name <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            disabled
                                            id="fatherName"
                                            name="fatherName"
                                            placeholder="Enter Father Name"
                                            value={formik.values.fatherName || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('fatherName') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('fatherName')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="motherName" className={classNames({ 'p-error': isFormFieldValid('motherName') }, 'Label__Text')}>
                                            Mother Name <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            disabled
                                            id="motherName"
                                            name="motherName"
                                            placeholder="Enter Mother Name"
                                            value={formik.values.motherName || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('motherName') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('motherName')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="accountStatus" className={classNames({ 'p-error': isFormFieldValid('accountStatus') }, 'Label__Text')}>
                                            Status <span className="Label__Required">*</span>
                                        </label>
                                        <div className="flex withoutArrow">
                                            <Dropdown
                                                disabled
                                                id="accountStatus"
                                                placeholder="Select Status"
                                                options={accountStatus}
                                                optionLabel="accountStatusName"
                                                name="accountStatus"
                                                optionValue="accountStatusId"
                                                value={formik.values.accountStatus || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('accountStatus') }, 'Dropdown__Round')}
                                            />
                                            {getFormErrorMessage('accountStatus')}
                                            <Button
                                                icon="pi pi-pencil"
                                                onClick={(e) => handleRemarksDialog(e)}
                                                className="Btn__Dark border-circle"
                                                style={{
                                                    height: '32px',
                                                    width: '35px',
                                                    marginLeft: '1rem'
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="remarks" className={classNames({ 'p-error': isFormFieldValid('remarks') }, 'Label__Text')}>
                                            Remarks <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="remarks"
                                            name="remarks"
                                            placeholder="Enter Remarks"
                                            value={formik.values.remarks || ''}
                                            onChange={formik.handleChange}
                                            disabled
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('remarks') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('remarks')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="accountNumber" className={classNames({ 'p-error': isFormFieldValid('accountNumber') }, 'Label__Text')}>
                                            Account Number <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="accountNumber"
                                            name="accountNumber"
                                            placeholder="Select Account Number"
                                            value={formik.values.accountNumber || ''}
                                            onChange={formik.handleChange}
                                            disabled
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('accountNumber') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('accountNumber')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="crpScore" className={classNames({ 'p-error': isFormFieldValid('crpScore') }, 'Label__Text')}>
                                            CRP Score <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="crpScore"
                                            name="crpScore"
                                            placeholder="Please Enter Crp Score"
                                            value={formik.values.crpScore || ''}
                                            onChange={formik.handleChange}
                                            disabled
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('crpScore') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('crpScore')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="crpRating" className={classNames({ 'p-error': isFormFieldValid('crpRating') }, 'Label__Text')}>
                                            CRP Rating <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="crpRating"
                                            name="crpRating"
                                            placeholder="Please Enter Crp Rating"
                                            value={formik.values.crpRating || ''}
                                            onChange={formik.handleChange}
                                            disabled
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('crpRating') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('crpRating')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="birthPlace" className={classNames({ 'p-error': isFormFieldValid('birthPlace') }, 'Label__Text')}>
                                            Place of Birth <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="birthPlace"
                                            name="birthPlace"
                                            placeholder="Place of Birth"
                                            value={formik.values.birthPlace || ''}
                                            onChange={formik.handleChange}
                                            disabled
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('birthPlace') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('birthPlace')}
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 justify-content-center pt-4 gap-4" style={{ marginBottom: '30px' }}>
                                <div
                                    className={accountStatus?.accountStatusName === 'CLOSED' ? 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons bg_dim' : 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons'}
                                    onClick={apiData?.accountStatus === 4 ? null : !isEditable ? null : handleCloseAccount}
                                >
                                    <div>Close Account</div>
                                </div>
                                <div
                                    className={accountStatus?.accountStatusName === 'CLOSED' ? 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons bg_dim' : 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons'}
                                    onClick={apiData?.accountStatus === 4 ? null : !isEditable ? null : handleSetleAccount}
                                >
                                    <div>Close Settle Account</div>
                                </div>
                                <div
                                    className={accountStatus?.accountStatusName === 'CLOSED' ? 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons bg_dim' : 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons'}
                                    onClick={apiData?.accountStatus === 4 ? null : !isEditable ? null : handleGeneratePin}
                                >
                                    <div>Regenerate Login Pin</div>
                                </div>
                                <div
                                    className={accountStatus?.accountStatusName === 'CLOSED' ? 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons bg_dim' : 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons'}
                                    onClick={apiData?.accountStatus === 4 ? null : !isEditable ? null : handleAccountStatement}
                                >
                                    <div>Account Statement</div>
                                </div>
                            </div>
                            <div className="Down__Btn my-5">
                                {isEditable ? (
                                    <>
                                        {/* <Button type="submit" label="Update" className="Btn__Dark" onClick={() => ScrollMover()} /> */}
                                        {apiData?.accountStatus === 4 ? (
                                            <Button type="submit" label="Ok" className="Btn__Dark" onClick={() => navigate('/accountsmanagement')} />
                                        ) : (
                                            <Button type="submit" label="Update" className="Btn__Dark" onClick={() => ScrollMover()} />
                                        )}
                                        <Button disabled={loading} onClick={() => navigate('/accountsmanagement')} label="Cancel" className="Btn__Transparent" />
                                    </>
                                ) : (
                                    <Button type="button" label={'Okay'} onClick={() => navigate('/accountsmanagement')} className="Btn__Dark" />
                                )}
                            </div>
                        </>
                    )}
                </form>
            </div>
        </>
    );
}

export default EditViewCorporate;

import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import { FilterMatchMode } from 'primereact/api';
import { handlePostRequest } from '../../services/PostTemplate';
import { handleGetRequest } from '../../services/GetTemplate';
import ActiveInActiveDialog from '../../components/ActiveInActiveDialog/ActiveInActiveDialog';
import { ResetButton, SearchButton } from '../../components/SearchButton';

function Commission() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [statusLovData, setStatusLovData] = useState([]);
    const [comissionData, setComissionData] = useState([]);
    const [typeOfCommissionData, setTypeOfCommissionData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [allLovs, setAllLovs] = useState([]);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        accountLevelName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [showModal, setShowModal] = useState(false);

    const [commissionProfileIdData, setCommissionProfileIdData] = useState();
    const [isActiveData, setIsActiveData] = useState();

    const dataTableRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getAllLovsForCommission = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcommissionprofiledata');
        setAllLovs(resp?.payLoad);
        const updatedArrayWithoutPending = resp?.payLoad?.typeOfCommission?.filter((item) => item.name !== 'Conditional Fee' && item.name !== 'Slab Hybrid Fixed Percentage');
        const updatedArrayWithoutPendingData = updatedArrayWithoutPending?.map((item) => {
            if (item.name === 'Fixed') {
                return { ...item, name: 'Fixed Commission' };
            }
            return item;
        });

        setTypeOfCommissionData(updatedArrayWithoutPendingData);
    };

    useEffect(() => {
        getAllLovsForCommission();
    }, []);

    const accounttype = allLovs?.accounttype?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(res?.payLoad);
    };
    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };
    useEffect(() => {
        getStatusListData();
        getUserLov();
    }, []);

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            profileName: '',
            accountClassificationId: '',
            statusId: '2',
            dateFrom: '',
            dateTo: '',
            createdBy: '',
            updatedBy: '',
            commissionTypeId: ''
        },
        validate: (data) => {
            let errors = {};
            if (data.dateTo && !data.dateFrom) {
                errors.dateFrom = 'Date From is required when Date To is provided';
            }

            if (data.dateFrom && data.dateTo && data.dateFrom > data.dateTo) {
                errors.dateTo = 'Date To must be greater than or equal to Date From';
            }
            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            dataTableRef.current.reset();

            const res = await dispatch(handlePostRequest(newData, '/pricingprofile/v1/pricingprofile/getallcommissionprofiles', true, true));
            if (res?.responseCode === '110000') {
                if (formik.values.commissionTypeId === '8') {
                    const result = res?.payLoad?.map((item) => ({
                        ...item,
                        commissionProfileId: item?.commissionHierarchyId,
                        commissionProfileName: item?.hierarchyName,
                        lkpFeeType: {
                            feeTypeName: 'Commission Hierarchy'
                        },
                        tblAccount2: {
                            accountTitle: 'N/A'
                        }
                    }));
                    setComissionData(result);
                } else {
                    setComissionData(res?.payLoad);
                }
            } else {
                setComissionData([]);
            }
            setloading(false);
            setloadingIcon('pi pi-search');

            dataTableRef.current.reset();
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    // const onGlobalFilterChange = (e) => {
    //     const value = e.target.value;
    //     let _filters = { ...filters };

    //     _filters['global'].value = value;

    //     setFilters(_filters);
    //     setGlobalFilterValue(value);
    // };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                {/* <h5 className="m-0">Type of Account List</h5> */}
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    {/* <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." /> */}
                </span>
            </div>
        );
    };

    const handleSwitchChange = async () => {
        let commissionTypeName = '';
        const updatedData = comissionData.map((item) => {
            if (item.commissionProfileId === commissionProfileIdData) {
                commissionTypeName = item?.lkpFeeType?.feeTypeName;
                const isActive = item.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setComissionData(updatedData);
        let isactive = '';
        if (isActiveData === 'Y') {
            isactive = 'N';
        } else if (isActiveData === 'N') {
            isactive = 'Y';
        }

        const previousValue = isActiveData;

        const data = {
            isActive: isactive,
            commissionProfileId: commissionProfileIdData
        };

        if (commissionTypeName === 'Commission Hierarchy') {
            data.commissionHierarchyId = commissionProfileIdData;
            delete data.commissionProfileId;
        }

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        let res = '';
        if (commissionTypeName === 'Commission Hierarchy') {
            res = await dispatch(handlePostRequest(newData, '/pricingprofile/v1/commissionprofile/inactivecommissionhierarchy', true, false));
        } else {
            res = await dispatch(handlePostRequest(newData, '/pricingprofile/v1/pricingprofile/inactivecommission', true, false));
        }
        if (res?.responseCode !== '010000') {
            const revertedData = comissionData.map((item) => {
                if (item.commissionProfileId === commissionProfileIdData) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setComissionData(revertedData);
        }
    };

    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.lkpStatus?.statusDescr === 'Approved' ? '#14A38B' : rowData?.lkpStatus?.statusDescr === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.lkpStatus?.statusDescr}</b>
            </p>
        );
    };
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    const previousDate = currentDate.toISOString().split('T')[0];

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const EditFixedCommission = (rowData) => {
        navigate(`/editfixedcommission?commissionId=${rowData.commissionProfileId}`);
    };

    const ViewFixedCommission = (rowData) => {
        navigate(`/viewfixedcommission?commissionId=${rowData.commissionProfileId}`);
    };

    const EditPercentageOfTransactionAmount = (rowData) => {
        navigate(`/editpercentageoftransactionamount?commissionId=${rowData.commissionProfileId}`);
    };
    const ViewPercentageOfTransactionAmount = (rowData) => {
        navigate(`/viewpercentageoftransactionamount?commissionId=${rowData.commissionProfileId}`);
    };

    const EditSlabWiseFixed = (rowData) => {
        navigate(`/editslabwisefixed?commissionId=${rowData.commissionProfileId}`);
    };
    const ViewSlabWiseFixed = (rowData) => {
        navigate(`/viewslabwisefixed?commissionId=${rowData.commissionProfileId}`);
    };

    const EditSlabWiseHybrid = (rowData) => {
        navigate(`/editslabwisehybridfixedandpercentage?commissionId=${rowData.commissionProfileId}`);
    };
    const ViewSlabWiseHybrid = (rowData) => {
        navigate(`/viewslabwisehybridfixedandpercentage?commissionId=${rowData.commissionProfileId}`);
    };

    const EditSlabWisePercentage = (rowData) => {
        navigate(`/editslabwisepercentage?commissionId=${rowData.commissionProfileId}`);
    };
    const ViewSlabWisePercentage = (rowData) => {
        navigate(`/viewslabwisepercentage?commissionId=${rowData.commissionProfileId}`);
    };
    const EditCommissionHierarchy = (rowData) => {
        navigate(`/editcommissionhierarchy?commissionId=${rowData.commissionProfileId}`);
    };
    const ViewCommissionHierarchy = (rowData) => {
        navigate(`/viewcommissionhierarchy?commissionId=${rowData.commissionProfileId}`);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    {rowData?.lkpFeeType?.feeTypeName === 'Fixed' ? (
                        <>
                            <InputSwitch
                                checked={rowData.isActive === 'Y'}
                                disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                                onChange={() => {
                                    setShowModal(true);
                                    setCommissionProfileIdData(rowData?.commissionProfileId);
                                    setIsActiveData(rowData?.isActive);
                                }}
                            />
                            <Button
                                tooltip="Edit"
                                icon="pi pi-pencil"
                                tooltipOptions={{ position: 'top' }}
                                disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                                onClick={() => EditFixedCommission(rowData)}
                                // onClick={() => navigate('/editfixedcom')}
                                // className="p-button-rounded p-button-warning"
                                className="p-button-rounded p-button-primary mr-2"
                            />
                            <Button
                                tooltip="View Details"
                                tooltipOptions={{ position: 'top' }}
                                icon="pi pi-eye"
                                onClick={() => {
                                    ViewFixedCommission(rowData);
                                }}
                                // className="p-button-rounded p-button-primary"
                                className="p-button-rounded p-mr-2"
                                style={{ backgroundColor: '#F2AC57', border: 'none' }}
                            />
                        </>
                    ) : rowData?.lkpFeeType?.feeTypeName === 'Percentage of Transaction Amount' ? (
                        <>
                            <InputSwitch
                                checked={rowData.isActive === 'Y'}
                                disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                                onChange={() => {
                                    setShowModal(true);
                                    setCommissionProfileIdData(rowData?.commissionProfileId);
                                    setIsActiveData(rowData?.isActive);
                                }}
                            />
                            <Button
                                tooltip="Edit"
                                icon="pi pi-pencil"
                                tooltipOptions={{ position: 'top' }}
                                disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                                onClick={() => EditPercentageOfTransactionAmount(rowData)}
                                className="p-button-rounded p-button-primary mr-2"
                            />
                            <Button
                                tooltip="View Details"
                                tooltipOptions={{ position: 'top' }}
                                icon="pi pi-eye"
                                onClick={() => ViewPercentageOfTransactionAmount(rowData)}
                                className="p-button-rounded"
                                style={{ backgroundColor: '#F2AC57', border: 'none' }}
                            />
                        </>
                    ) : rowData?.lkpFeeType?.feeTypeName === 'Slab wise Fixed' ? (
                        <>
                            <InputSwitch
                                checked={rowData.isActive === 'Y'}
                                disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                                onChange={() => {
                                    setShowModal(true);
                                    setCommissionProfileIdData(rowData?.commissionProfileId);
                                    setIsActiveData(rowData?.isActive);
                                }}
                            />
                            <Button
                                tooltip="Edit"
                                icon="pi pi-pencil"
                                tooltipOptions={{ position: 'top' }}
                                disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                                onClick={() => EditSlabWiseFixed(rowData)}
                                className="p-button-rounded p-button-primary mr-2"
                            />
                            <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => ViewSlabWiseFixed(rowData)} className="p-button-rounded" style={{ backgroundColor: '#F2AC57', border: 'none' }} />
                        </>
                    ) : rowData?.lkpFeeType?.feeTypeName === 'Slab Hybrid Fixed Percentage' ? (
                        <>
                            <InputSwitch
                                checked={rowData.isActive === 'Y'}
                                disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                                onChange={() => {
                                    setShowModal(true);
                                    setCommissionProfileIdData(rowData?.commissionProfileId);
                                    setIsActiveData(rowData?.isActive);
                                }}
                            />
                            <Button
                                tooltip="Edit"
                                icon="pi pi-pencil"
                                tooltipOptions={{ position: 'top' }}
                                disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                                onClick={() => EditSlabWiseHybrid(rowData)}
                                className="p-button-rounded p-button-primary mr-2"
                            />
                            <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => ViewSlabWiseHybrid(rowData)} className="p-button-rounded" style={{ backgroundColor: '#F2AC57', border: 'none' }} />
                        </>
                    ) : rowData?.lkpFeeType?.feeTypeName === 'Slab wise Percentage' ? (
                        <>
                            <InputSwitch
                                checked={rowData.isActive === 'Y'}
                                disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                                onChange={() => {
                                    setShowModal(true);
                                    setCommissionProfileIdData(rowData?.commissionProfileId);
                                    setIsActiveData(rowData?.isActive);
                                }}
                            />
                            <Button
                                tooltip="Edit"
                                icon="pi pi-pencil"
                                tooltipOptions={{ position: 'top' }}
                                disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                                onClick={() => EditSlabWisePercentage(rowData)}
                                className="p-button-rounded p-button-primary mr-2"
                            />
                            <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => ViewSlabWisePercentage(rowData)} className="p-button-rounded" style={{ backgroundColor: '#F2AC57', border: 'none' }} />
                        </>
                    ) : rowData?.lkpFeeType?.feeTypeName === 'Commission Hierarchy' ? (
                        <>
                            <InputSwitch
                                checked={rowData.isActive === 'Y'}
                                disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                                onChange={() => {
                                    setShowModal(true);
                                    setCommissionProfileIdData(rowData?.commissionProfileId);
                                    setIsActiveData(rowData?.isActive);
                                }}
                            />
                            <Button
                                tooltip="Edit"
                                icon="pi pi-pencil"
                                tooltipOptions={{ position: 'top' }}
                                disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) || (rowData?.lkpStatus?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                                onClick={() => EditCommissionHierarchy(rowData)}
                                className="p-button-rounded p-button-primary mr-2"
                            />
                            <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => ViewCommissionHierarchy(rowData)} className="p-button-rounded" style={{ backgroundColor: '#F2AC57', border: 'none' }} />
                        </>
                    ) : (
                        <>
                            <InputSwitch
                                checked={rowData.isActive === 'Y'}
                                disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                                onChange={() => {
                                    setShowModal(true);
                                    setCommissionProfileIdData(rowData?.commissionProfileId);
                                    setIsActiveData(rowData?.isActive);
                                }}
                            />
                            <Button
                                tooltip="Edit"
                                icon="pi pi-pencil"
                                tooltipOptions={{ position: 'top' }}
                                disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                                onClick={() => navigate('/editfixedcommission')}
                                className="p-button-rounded p-button-primary mr-2"
                            />
                            <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => navigate('/viewfixedcommission')} className="p-button-rounded" style={{ backgroundColor: '#F2AC57', border: 'none' }} />
                        </>
                    )}
                </div>
            </>
        );
    };
    const addFixedCommission = () => {
        navigate('/addfixedcommission');
    };

    const addPercentagetrxAmount = () => {
        navigate('/addpercentageoftransactionamount');
    };
    const addSlabWiseFixed = () => {
        navigate('/addslabwisefixed');
    };
    const addSlabWisePercentage = () => {
        navigate('/addslabwisepercentage');
    };
    const addSlabWiseHybridFixPer = () => {
        navigate('/addslabwisehybridfixedandpercentage');
    };
    const addCommissionHierarchy = () => {
        navigate('/addcommissionhierarchy');
    };
    const header = renderHeader();

    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const handleShowModal = () => {
        setShowModal(false);
    };

    const props = {
        showModal,
        handleShowModal,
        handleSwitchChange
    };

    return (
        <>
            <ActiveInActiveDialog {...props} />

            <div className="Top__Btn__Commission Top__Btn__Border">
                <div>
                    <Button label="Fixed Commission" onClick={() => addFixedCommission()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                    <Button label="Percentage of Transaction Amount" onClick={() => addPercentagetrxAmount()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                    <Button label="Slab wise Fixed" onClick={() => addSlabWiseFixed()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                    <Button label="Slab wise Percentage" onClick={() => addSlabWisePercentage()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                    <Button label="Slab Wise Hybrid of Fixed & Percentage" onClick={() => addSlabWiseHybridFixPer()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                    <Button label="Commission Hierarchy" onClick={() => addCommissionHierarchy()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                    Account Type
                                </label>
                                <Dropdown
                                    id="accountClassificationId"
                                    placeholder="Select Account Type"
                                    options={accounttype}
                                    optionLabel="name"
                                    name="accountClassificationId"
                                    optionValue="lovId"
                                    value={formik.values.accountClassificationId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountClassificationId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('accountClassificationId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="profileName" className={classNames({ 'p-error': isFormFieldValid('profileName') }, 'Label__Text')}>
                                    Profile Name
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="profileName"
                                    placeholder="Enter profile name"
                                    name="profileName"
                                    value={formik?.values?.profileName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('profileName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('profileName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText
                                    max={previousDate || '9999-12-31'}
                                    id="dateFrom"
                                    placeholder=""
                                    name="dateFrom"
                                    type="date"
                                    value={formik?.values?.dateFrom || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText
                                    max={previousDate || '9999-12-31'}
                                    id="dateTo"
                                    placeholder=""
                                    name="dateTo"
                                    type="date"
                                    value={formik?.values?.dateTo || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Updated By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="statusId" className={classNames({ 'p-error': isFormFieldValid('statusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select Status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="commissionTypeId" className={classNames({ 'p-error': isFormFieldValid('commissionTypeId') }, 'Label__Text')}>
                                    Select Type of Commission
                                    <span className="Label__Required"></span>
                                </label>
                                <Dropdown
                                    id="commissionTypeId"
                                    placeholder="Select Type of Commission"
                                    options={typeOfCommissionData}
                                    optionLabel="name"
                                    name="commissionTypeId"
                                    optionValue="lovId"
                                    value={formik.values.commissionTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('commissionTypeId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('commissionTypeId')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <SearchButton />
                        <ResetButton onClick={handleReset} />
                    </div>
                </form>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            filters={filters}
                            globalFilterFields={['accountLevelName', 'createuser', 'createdate', 'lkpStatus.statusName']}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={comissionData}
                        >
                            <Column field="commissionProfileName" header="Profile Name" />
                            <Column field="tblAccount2.accountTitle" header="Account Title" />
                            <Column field="lkpFeeType.feeTypeName" header="Comission Type" />
                            <Column field="createdBy" header="Created By" />
                            <Column body={createdDateAndTimeBodyTemplate} header="Created Date & Time" />
                            <Column body={statusBodyTemplate} header="Status" />
                            <Column header="Action" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Commission;

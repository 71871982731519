import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function EditBulkTransactionReversalChecker({}) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [statusLovData, setStatusLovData] = useState([]);
    const { search } = useLocation();
    const [batchData, setBatchData] = useState();
    const [bulkTransactionReversalData, setBulkTransactionReversalData] = useState();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const tableName = query.get('tableName');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');

    const validationSchema = Yup.object({
        action: Yup.string().required('Action should not be empty'),
        checkerComments: Yup.string().required('This Field is required').notOneOf([''], 'This Field should not be empty').max(500, 'Maximum 500 characters needed')
    });

    const dt = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            checkerComments: '',
            action: ''
        },

        onSubmit: async (data1) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            const payLoad = {
                tblBulkOprFileId: batchData?.bulkOprFileId,
                fileStatusId: batchData?.fileStatusId?.fileStatusId,
                bulkOperationType: 'BTR',
                batchId: batchData?.batchId,
                mcActionRequest: {
                    mcRequestId: mcRequestId,
                    mcPeindingRequestId: mcPeindingRequestId,
                    checkerId: '1',
                    checkerComments: data1.checkerComments,
                    action: data1.action,
                    updatedIndex: null
                }
            };

            const data = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            try {
                const res = await dispatch(handlePostRequest(data, '/batchprocessing/v1/batchprocessing/checkeraction', true, false, 'requestchecker'));
            } catch (error) {}
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getBatchInformation = async () => {
        const res = await handleGetRequest(`/batchprocessing/v1/batchprocessing/getbatchcheckerbyid/${mcRequestId}`);
        const res1 = await handleGetRequest(`/batchprocessing/v1/batchprocessing/getbatchbyid/${res.payLoad.batchId}`);

        setBatchData(res1.payLoad);
        getBatchRecordDetails(res1.payLoad);
    };

    const getBatchRecordDetails = async (data) => {
        const resp = await handleGetRequest(`/batchprocessing/v1/batchprocessing/getfilerecordbyid/${data?.bulkOprFileId}`);
        setBulkTransactionReversalData(resp?.payLoad);
    };

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = res?.payLoad.filter((item) => item.name !== 'Pending' && item.lovId !== '4');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        getBatchInformation();
    }, [mcRequestId]); // eslint-disable-line

    useEffect(() => {
        getStatusListData();
    }, []); // eslint-disable-line
    const exportCSV = () => {
        dt.current.exportCSV();
    };
    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };
    return (
        <>
            <div className="Top__Btn Top__Btn__Border">
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <div>
                        <img src="/csv.png" alt="CSV" style={{ width: '70%', height: '60%' }} onClick={exportCSV} />
                    </div>
                </div>
            </div>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1> Bulk Transaction Reversal Summary/ Edit</h1>
                </div>

                <div className="Batch_Details_Card flex justify-content-center align-items-center ">
                    <div className="col-12 md:col-5 sm:col-12 flex md:flex-row flex-column">
                        <div className="col-12 md:col-4 sm:col-12">
                            <p className="line-height-1">Batch ID</p>
                            <p className="line-height-1 text-blue-700 font-bold">{batchData?.batchId}</p>
                        </div>
                        <div className="col-12 md:col-4 sm:col-12">
                            <p className="line-height-1">Date</p>
                            <p className="line-height-1">{createdDateAndTimeBodyTemplate(batchData)}</p>
                        </div>
                        <div className="col-12 md:col-4 sm:col-12">
                            <p className="line-height-1">Batch Status</p>
                            <p className="line-height-1" style={{ color: batchData?.fileStatusId?.fileStatusName === 'Ready for Submit' ? 'orange' : 'green' }}>
                                {batchData?.fileStatusId?.fileStatusName}
                            </p>
                        </div>
                    </div>
                    <div className="col-12 md:col-7 sm:col-12 flex md:flex-row flex-column">
                        <div className="col-12 md:col-4 sm:col-12">
                            <div className="flex  p-4 border-round justify-content-center align-items-center gap-3 card_lime">
                                <p className="mb-0 ">Valid Records</p> <p className="text-green-500 font-bold text-2xl">{batchData?.validRecords}</p>
                            </div>
                        </div>
                        <div className="col-12 md:col-4 sm:col-12">
                            <div className="flex  p-4 border-round fixed__height card_lime justify-content-center align-items-center gap-3 card_rose">
                                <p className="mb-0 ">Invalid Records</p> <p className="text-pink-600 font-bold text-2xl">{batchData?.invalidRecords}</p>
                            </div>
                        </div>
                        <div className="col-12 md:col-4 sm:col-12">
                            <div className="flex bg-cyan-100 p-4 border-round fixed__height justify-content-center align-items-center gap-3 card_sky">
                                <p className="mb-0 ">Total Records</p> <p className="text-blue-500 font-bold text-2xl">{batchData?.totalRecords}</p>
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                </div>
                <div className="Form__Header mt-5">
                    <h1>BULK TRANSACTION REVERSAL</h1>
                </div>

                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <DataTable
                                ref={dt}
                                paginator
                                rows={5}
                                rowsPerPageOptions={[5, 10, 25]}
                                className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} "
                                emptyMessage="No List found."
                                responsiveLayout="scroll"
                                value={bulkTransactionReversalData}
                            >
                                <Column field="serialNo" header="Sr #" />
                                <Column field="transactionId" header="Transaction ID" />
                                <Column field="validationStatus" header="Status" />
                            </DataTable>
                        </div>
                    </div>
                </div>
                {/* <div className="my-3"> */}
                <div className="card Card__Round">
                    <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                        Action
                                        <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="action"
                                        placeholder="Select Action"
                                        options={statusLovData}
                                        optionLabel="name"
                                        name="action"
                                        optionValue="lovId"
                                        value={formik.values.action || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('action')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                        Comments
                                        <span className="Label__Required">*</span>
                                    </label>
                                    <InputTextarea
                                        maxLength={500}
                                        rows={5}
                                        cols={30}
                                        id="checkerComments"
                                        name="checkerComments"
                                        value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                    />

                                    {getFormErrorMessage('checkerComments')}
                                </div>
                            </div>
                        </div>
                        <div className="Usergroup__SearchBtn p-field flex flex-row  justify-content-center pt-2 col-12" style={{ textAlign: 'center' }}>
                            <div className="Down__Btn">
                                <Button disabled={loading} loadingIcon={loadingIcon || ''} label="Submit" type="submit" className="Btn__Dark" />
                                <Button disabled={loading} type="button" onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default EditBulkTransactionReversalChecker;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { InputTextarea } from 'primereact/inputtextarea';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function EditSalesForceChecker() {
    const [loading, setloading] = useState(false);
    const [statusLovData, setStatusLovData] = useState([]);
    const [salesStructureData, setSalesStructureData] = useState([]);
    const [kamData, setKamData] = useState([]);
    const [dataFromApi, setDataFromApi] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('This Field is required'),
        checkerComments: Yup.string().required('This Field is required')
    });

    const formik = useFormik({
        validationSchema,
        initialValues: {
            salesStructureId: '',
            salesRoleDetailId: '',
            employeeCnic: '',
            employeeId: '',
            employeeMobileNo: '',
            employeeEmail: '',
            employeeName: '',
            essDepartment: '',
            isActive: '',
            salesRoleId: '',
            action: '',
            checkerComments: ''
        },

        onSubmit: async (data) => {
            setloading(true);

            data['isActive'] = data?.isActive ? 'Y' : 'N';
            if (requestTypeDescr !== 'Enable/Disable Request') {
                delete data['isActive'];
            }
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        saleForceRequest: {
                            isActive: data['isActive'],
                            salesForceId: dataFromApi?.salesForceId,
                            salesRoleId: dataFromApi?.salesRoleId,
                            salesStructureId: parseInt(formik.values.salesStructureId),
                            salesRoleDetailId: parseInt(formik.values.salesRoleDetailId),
                            employeeCnic: formik.values.employeeCnic,
                            employeeId: formik.values.employeeId,
                            employeeMobileNo: formik.values.employeeMobileNo,
                            employeeEmail: formik.values.employeeEmail,
                            employeeName: formik.values.employeeName,
                            essDepartment: formik.values.essDepartment
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/account/v1/sales/salesForceCheckerAction', true, false, 'requestchecker'));
            if (res?.responseCode === '030000') {
                formik.resetForm();
            }
            setloading(false);
        }
    });

    const getDatabyId = async () => {
        // getRoleWiseModule
        const resp = await handleGetRequest(`/account/v1/sales/getSalesForceById/${refTableId}`);
        const keyData = resp?.payLoad;
        setDataFromApi(keyData);

        formik.setFieldValue('salesStructureId', keyData?.salesStructureId?.toString());
        formik.setFieldValue('salesRoleDetailId', keyData?.salesRoleDetailId?.toString());
        formik.setFieldValue('salesRoleId', keyData?.salesRoleId?.toString());
        formik.setFieldValue('employeeCnic', keyData?.employeeCnic);
        formik.setFieldValue('employeeId', keyData?.employeeId);
        formik.setFieldValue('employeeMobileNo', keyData?.employeeMobileNo);
        formik.setFieldValue('employeeEmail', keyData?.employeeEmail);
        formik.setFieldValue('employeeName', keyData?.employeeName);
        formik.setFieldValue('essDepartment', keyData?.essDepartment);
    };

    const getMcRequestDataById = async () => {
        const resp = await handleGetRequest(`/account/v1/sales/getSalesForceUpdateCheckerById/${mcRequestId}`);
        const keyData = resp?.payLoad;
        setDataFromApi(keyData);
        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
        }
        formik.setFieldValue('salesStructureId', keyData?.salesStructureId?.toString());
        formik.setFieldValue('salesRoleDetailId', keyData?.salesRoleDetailId?.toString());
        formik.setFieldValue('salesRoleId', keyData?.salesRoleId?.toString());
        formik.setFieldValue('employeeCnic', keyData?.employeeCnic);
        formik.setFieldValue('employeeId', keyData?.employeeId);
        formik.setFieldValue('employeeMobileNo', keyData?.employeeMobileNo);
        formik.setFieldValue('employeeEmail', keyData?.employeeEmail);
        formik.setFieldValue('employeeName', keyData?.employeeName);
        formik.setFieldValue('essDepartment', keyData?.essDepartment);
        formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
    };

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };

    const getSaleStructureData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getSaleStructureList/LKP_SALES_STRUCTURE:*');
        setSalesStructureData(res?.payLoad);
    };
    const getKamData = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getEmployeeRoleList/${formik.values?.salesStructureId}`);
        const filteredList = resp?.payLoad.filter((item) => item.isActive === 'Y');
        setKamData(filteredList);
    };

    useEffect(() => {
        if (formik?.values?.salesStructureId) {
            getKamData();
        }
    }, [formik?.values?.salesStructureId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getStatusLov();
        getSaleStructureData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, [requestType]); // eslint-disable-line react-hooks/exhaustive-deps

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>EDIT DETAIL</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                    Sale Structure
                                </label>
                                <Dropdown
                                    id="lovId"
                                    placeholder="Select Sale Structure"
                                    options={salesStructureData}
                                    optionLabel="name"
                                    name="salesStructureId"
                                    optionValue="lovId"
                                    value={formik.values.salesStructureId || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('name')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="salesRoleDetailId" className={classNames({ 'p-error': isFormFieldValid('salesRoleDetailId') }, 'Label__Text')}>
                                    Sales Role
                                </label>
                                <Dropdown
                                    id="salesRoleDetailId"
                                    placeholder="Select Sale Role"
                                    options={kamData}
                                    optionLabel="name"
                                    name="salesRoleDetailId"
                                    optionValue="lovId"
                                    value={formik.values.salesRoleDetailId || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('salesRoleDetailId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('salesRoleDetailId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="employeeName" className={classNames({ 'p-error': isFormFieldValid('employeeName') }, 'Label__Text')}>
                                    Employee Name
                                </label>
                                <InputText
                                    placeholder="Enter Employee Name"
                                    id="employeeName"
                                    name="employeeName"
                                    value={formik?.values?.employeeName}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('employeeName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('employeeName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="employeeId" className={classNames({ 'p-error': isFormFieldValid('employeeId') }, 'Label__Text')}>
                                    Employee Id
                                </label>
                                <InputText placeholder="Enter Employee Id" id="employeeId" name="employeeId" value={formik?.values?.employeeId} disabled className={classNames({ 'p-invalid': isFormFieldValid('employeeId') }, 'Input__Round')} />

                                {getFormErrorMessage('employeeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('saleStructure') }, 'Label__Text')}>
                                    Ess Department
                                </label>

                                <InputText
                                    id="essDepartment"
                                    name="essDepartment"
                                    placeholder="Enter Ess Department"
                                    value={formik?.values?.essDepartment}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('employeeId') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('employeeId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('saleStructure') }, 'Label__Text')}>
                                    Employee CNIC Number
                                </label>

                                <InputText
                                    id="employeeCnic"
                                    name="employeeCnic"
                                    placeholder="Enter Employee CNIC Number"
                                    value={formik?.values?.employeeCnic}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('employeeId') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('employeeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('saleStructure') }, 'Label__Text')}>
                                    Employee Mobile Number
                                </label>

                                <InputText
                                    id="employeeMobileNo"
                                    name="employeeMobileNo"
                                    placeholder="Enter Employee Mobile Number"
                                    value={formik?.values?.employeeMobileNo}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('employeeId') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('employeeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('saleStructure') }, 'Label__Text')}>
                                    Employee Email Address
                                </label>
                                <InputText
                                    id="employeeEmail"
                                    name="employeeEmail"
                                    placeholder="Enter Employee Email Address"
                                    value={formik?.values?.employeeEmail}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('employeeId') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('employeeId')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    maxLength={500}
                                    rows={5}
                                    cols={30}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} label="Update" className="Btn__Dark" />
                        <Button onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default EditSalesForceChecker;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../services/PostTemplate';
import { handleGetRequest } from '../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';
import { InputNumber } from 'primereact/inputnumber';
function EditTaxProfile() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [currencyIdLov, setcurrencyIdLov] = useState();
    const [applicableLov, setApplicableLov] = useState();
    const [requestedDataById, setRequestedDataByID] = useState();
    const [indicateValues, setIndicateValues] = useState();
    const [regimeLevelValues, setRegimeLevelValues] = useState();
    const [regimeLovValues, setregimeLovValues] = useState();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const requestedID = query.get('reqID');
    const taxId = query.get('slabId');
    const requestType = query.get('requestType');
    const getRequestedData = async () => {
        const response = await handleGetRequest(`/tax/v1/getTaxRegimeById/${requestedID}`);
        const keyData = response?.payLoad;

        formik.setFieldValue('taxTypeId', keyData?.lkpTaxType?.taxTypeId?.toString());
        formik.setFieldValue('regimeCategoryId', keyData?.lkpRegimeCategory?.regimeCategoryId?.toString());
        formik.setFieldValue('currencyId', keyData?.lkpCurrency?.currencyId?.toString());
        formik.setFieldValue('isFiler', keyData?.isFiler?.toString());
        formik.setFieldValue('regimeLevelIds', keyData?.taxRateRequest?.regimeLevelIds);
        formik.setFieldValue('accountId', keyData?.tblAccount?.accountId.toString());
        formik.setFieldValue('taxRateType', keyData?.taxRateRequest?.taxRateType);
        formik.setFieldValue('taxPercentage', keyData?.taxRateRequest?.taxRate);
        formik.setFieldValue('thresholdAmount', keyData?.taxRateRequest?.thresholdAmount);
        formik.setFieldValue('effectiveFrom', keyData?.taxRateRequest?.effectiveFrom);
        formik.setFieldValue('slabthresholdAmount', keyData?.taxRateRequest?.slabthresholdAmount);
        formik.setFieldValue('slabType', keyData?.taxRateRequest?.slabType);
        formik.setFieldValue('taxRate', keyData?.taxRateRequest?.taxRate);
        formik.setFieldValue('fromAmount', keyData?.taxRateRequest?.fromAmount);
        formik.setFieldValue('toAmount', keyData?.taxRateRequest?.toAmount);
        formik.setFieldValue('taxSlabPercentage', keyData?.taxRateRequest?.taxSlabPercentage);
        formik.setFieldValue('taxSlabAmount', keyData?.taxRateRequest?.taxSlabAmount);

        if (keyData?.taxRateRequest?.thresholdAmount !== null) {
            formik.setFieldValue('isActive', true);
        }
        if (keyData?.taxRateRequest?.thresholdAmount !== null) {
            formik.setFieldValue('isActivePercentage', true);
        }
        if (keyData?.taxRateRequest?.slabthresholdAmount !== null) {
            formik.setFieldValue('isActiveSlab', true);
        }
        if (keyData?.taxRateRequest?.slabthresholdAmount !== null) {
            formik.setFieldValue('isActiveTier', true);
        }

        setRequestedDataByID(response?.payLoad);
    };

    useEffect(() => {
        getRequestedData();
    }, []); // eslint-disable-line
    const getcurrencyIdLov = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getAllCurrency/LKP_CURRENCY:*');
        setcurrencyIdLov(response?.payLoad);
    };
    const getTaxTypeLovValues = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getAllTaxType/LKP_TAX_TYPE:*');
        setApplicableLov(response?.payLoad);
    };
    const getIndicateGlValues = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getGlAccounts/TBL_ACCOUNT:*');
        setIndicateValues(response?.payLoad);
    };
    const getRegimeLevelLovValues = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getRegimeCategory/LKP_REGIME_CATEGORY:*');
        setRegimeLevelValues(response?.payLoad);
    };

    useEffect(() => {
        getcurrencyIdLov();
        getTaxTypeLovValues();
        getIndicateGlValues();
        getRegimeLevelLovValues();
    }, []);

    const calculationMethodJson = [
        {
            label: 'Fixed',
            description: 'F'
        },
        {
            label: 'Percentage',
            description: 'P'
        },
        {
            label: 'Slab Based',
            description: 'S'
        },
        {
            label: 'Tier Based',
            description: 'T'
        }
    ];
    const validationSchema = Yup.object().shape({
        accountId: Yup.string().required('This field is required').matches('^[0-9]*$', 'Please enter numeric data')
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            taxTypeId: '',
            isFiler: '',
            currencyId: '',
            accountId: '',
            taxRegimeName: '',
            taxAmount: '',
            taxPercentage: '',
            taxRateType: '',
            fromAmount: '',
            toAmount: '',
            slabType: '',
            thresholdAmount: '',
            effectiveFrom: '',
            taxRateRequest: {},
            taxRate: '',
            slabthresholdAmount: '',
            fixedThreshold: false,
            percentageThreshold: false,
            slabThreshold: false,
            tierThreshold: false,
            isActive: false,
            isActivePercentage: false,
            isActiveSlab: false,
            isActiveTier: false,
            taxRateId: '',
            taxSlabAmount: '',
            taxSlabPercentage: '',
            regimeCategoryId: '',
            regimeLevelIds: [],
            taxSlabId: ''
        },
        validate: (data) => {
            let errors = {};
            if (data?.regimeLevelIds === '' || data?.regimeLevelIds === null) {
                errors.regimeLevelIds = 'This field is required';
            }

            if (data?.taxRateType === '' || data?.taxRateType === null || data?.taxRateType === undefined) {
                errors.taxRateType = 'This field is required';
            }
            if (data?.taxRateType === 'F') {
                if (data?.isActive) {
                    if (data?.thresholdAmount === null || data?.thresholdAmount === '' || !/^[0-9]*$/.test(data.thresholdAmount)) {
                        errors.thresholdAmount = 'Please enter numeric data';
                    }
                }
                if (data.taxRateType === 'F' && (!data.taxRate || !/^[0-9]*$/.test(data.taxRate))) {
                    errors.taxRate = 'Please enter numeric data';
                }
            }
            if (data?.taxRateType === 'P') {
                const percentageValue = parseInt(data?.taxPercentage);
                if (data.isActivePercentage) {
                    if (data?.thresholdAmount === null || data?.thresholdAmount === '' || !/^[0-9]*$/.test(data?.thresholdAmount)) {
                        errors.thresholdAmount = 'Please enter numeric data';
                    }
                }
                if (data?.taxPercentage === null || data?.taxPercentage === '' || !/^\d+(\.\d+)?$/.test(data?.taxPercentage)) {
                    errors.taxPercentage = 'Please enter numeric data';
                }
                if (percentageValue <= 0 || percentageValue > 100) {
                    errors.taxPercentage = 'Tax percentage must be between 1 and 100';
                }
            }
            if (data?.taxRateType === 'S') {
                if (data?.slabType === '' || data?.slabType === null) {
                    errors.slabType = 'This field is required';
                }
                if (data?.slabType === 'F') {
                    if (data?.taxSlabAmount === '' || data?.taxSlabAmount === null || !/^[0-9]*$/.test(data.taxSlabAmount)) {
                        errors.taxSlabAmount = 'This field is numeric and is required';
                    }
                }
                if (data?.slabType === 'P') {
                    if (data?.taxSlabPercentage === '' || data?.taxSlabPercentage === null) {
                        errors.taxSlabPercentage = 'This field is required';
                    }
                    if (data?.taxSlabPercentage > 100 || data?.taxSlabPercentage === '0' || !/^\d+(\.\d+)?$/.test(data.taxSlabPercentage)) {
                        errors.taxSlabPercentage = 'Value should be numeric or fraction value and should be between 1 and 100';
                    }
                }
                if (data?.isActiveSlab) {
                    if (data?.slabthresholdAmount === null || data?.slabthresholdAmount === '' || !/^[0-9]*$/.test(data.slabthresholdAmount)) {
                        errors.slabthresholdAmount = 'Please enter numeric data';
                    }
                }

                if (data?.toAmount <= 0 || !/^[0-9]*$/.test(data?.toAmount)) {
                    errors.toAmount = 'Value must be numeric and greater than 0';
                }
                if (data?.fromAmount <= 0 || !/^[0-9]*$/.test(data?.fromAmount)) {
                    errors.fromAmount = 'Value must be numeric and greater than 0';
                }
                if (data?.fromAmount) {
                    if (parseInt(data?.fromAmount) > parseInt(data?.toAmount)) {
                        errors.toAmount = 'End Slab value should be greater than Start Slab';
                    }
                }
            }
            if (data?.taxRateType === 'T') {
                if (data?.slabType === '' || data?.slabType === null) {
                    errors.slabType = 'This field is required';
                }
                if (data?.slabType === 'F') {
                    if (data?.taxSlabAmount === '' || data?.taxSlabAmount === null || !/^[0-9]*$/.test(data.taxSlabAmount)) {
                        errors.taxSlabAmount = 'This field is numeric and is required';
                    }
                }
                if (data?.slabType === 'P') {
                    if (data?.taxSlabPercentage === '' || data?.taxSlabPercentage === null) {
                        errors.taxSlabPercentage = 'This field is required';
                    }
                    if (data?.taxSlabPercentage > 100 || data?.taxSlabPercentage === '0' || !/^\d+(\.\d+)?$/.test(data.taxSlabPercentage)) {
                        errors.taxSlabPercentage = 'Value should be numeric or fractional value and should be between 1 and 100';
                    }
                }
                if (data?.isActiveTier) {
                    if (data?.slabthresholdAmount === null || data?.slabthresholdAmount === '' || !/^[0-9]*$/.test(data.slabthresholdAmount)) {
                        errors.slabthresholdAmount = 'Please enter numeric data';
                    }
                }

                if (data?.toAmount <= 0 || !/^[0-9]*$/.test(data?.toAmount)) {
                    errors.toAmount = 'Value must be greater than 0';
                }
                if (data?.fromAmount <= 0 || !/^[0-9]*$/.test(data?.fromAmount)) {
                    errors.fromAmount = 'Value must be greater than 0';
                }
                if (data?.fromAmount) {
                    if (parseInt(data?.fromAmount) > parseInt(data?.toAmount)) {
                        errors.toAmount = 'End Slab value should be greater than Start Slab';
                    }
                }
            }
            if (data?.effectiveFrom === null || data?.effectiveFrom === '') {
                errors.effectiveFrom = 'Date is required';
            }
            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            if (data['taxRegimeName'] === '') {
                delete data.taxRegimeName;
            }

            if (data['isActive'] === false || data['isActivePercentage'] === false) {
                data['thresholdAmount'] = '';
            }
            if (data['isActiveSlab'] === false || data['isActiveTier'] === false) {
                data['slabthresholdAmount'] = '';
            }

            data['taxRateRequest'] = {
                taxAmount: data?.taxAmount,
                taxPercentage: formik.values.taxRateType === 'F' ? '' : data?.taxPercentage,
                fromAmount: data?.fromAmount || '',
                toAmount: data?.toAmount || '',
                slabType: data?.slabType,
                thresholdAmount: data?.taxRateType === 'F' || data?.taxRateType === 'P' ? data?.thresholdAmount : '',
                effectiveFrom: data?.effectiveFrom,
                taxRateType: data?.taxRateType,
                taxRate: formik.values.taxRateType === 'P' ? '' : data?.taxRate,
                slabthresholdAmount: data?.taxRateType === 'S' || data?.taxRateType === 'T' ? data?.slabthresholdAmount : '',
                taxRateId: requestedDataById?.tblTaxRates[0]?.taxRateId,
                taxSlabId: taxId || '',
                taxSlabAmount: data?.taxSlabAmount || '',
                taxSlabPercentage: data?.taxSlabPercentage || '',
                regimeLevelIds: data?.regimeLevelIds
            };

            delete data['taxAmount'];
            delete data['taxPercentage'];
            delete data['fromAmount'];
            delete data['toAmount'];
            delete data['slabType'];
            delete data['thresholdAmount'];
            delete data['effectiveFrom'];
            // delete data['taxRateType'];
            // delete data['taxRate'];
            delete data['slabthresholdAmount'];
            delete data['fixedThreshold'];
            delete data['percentageThreshold'];
            delete data['slabThreshold'];
            delete data['tierThreshold'];
            delete data['taxRegimeName'];
            delete data['undefined'];
            delete data['isActive'];
            delete data['isActivePercentage'];
            delete data['isActiveSlab'];
            delete data['isActiveTier'];
            delete data['taxRateId'];
            delete data['taxRateId'];
            delete data['taxSlabAmount'];
            delete data['taxSlabPercentage'];
            delete data['regimeLevelIds'];
            delete data['taxSlabId'];
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        taxRegimeId: requestedID,
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            delete newData?.data?.payLoad['taxRateType'];
            delete newData?.data?.payLoad['taxRate'];
            const res = await dispatch(handlePostRequest(newData, `/tax/v1/updatetaxprofile`, true, false, 'taxprofilemaintenance'));
            if (res?.responseCode === '040000') {
                formik.resetForm();
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const getRegimeNameLovs = async () => {
        const response = await handleGetRequest(`/configuration/v1/lookups/getAllRegimeLevel/LKP_REGIME_LEVEL:*/${formik?.values?.regimeCategoryId}`);
        setregimeLovValues(response?.payLoad);
    };

    useEffect(() => {
        if (formik?.values?.regimeCategoryId) {
            getRegimeNameLovs();
        }
    }, [formik?.values?.regimeCategoryId]); // eslint-disable-line

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const applicabledropDown = [
        {
            value: 'Y',
            label: 'Filer'
        },
        {
            value: 'N',
            label: 'Non filer'
        },
        {
            value: 'B',
            label: 'Both'
        }
    ];

    const slabTypeJson = [
        {
            label: 'Fixed',
            value: 'F'
        },
        {
            label: 'Percentage',
            value: 'P'
        }
    ];
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>EDIT TAX PROFILE</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="taxTypeId" className={classNames({ 'p-error': isFormFieldValid('taxTypeId') }, 'Label__Text')}>
                                    Type Of Tax
                                </label>
                                <Dropdown
                                    id="taxTypeId"
                                    placeholder="Select Type Of Tax"
                                    options={applicableLov}
                                    optionLabel="name"
                                    name="taxTypeId"
                                    optionValue="lovId"
                                    value={formik.values.taxTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('taxTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('taxTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="regimeCategoryId" className={classNames({ 'p-error': isFormFieldValid('regimeCategoryId') }, 'Label__Text')}>
                                    Regime Category
                                </label>
                                <Dropdown
                                    id="regimeCategoryId"
                                    placeholder="Select Regime Level"
                                    options={regimeLevelValues}
                                    optionLabel="name"
                                    name="regimeCategoryId"
                                    optionValue="lovId"
                                    value={formik.values.regimeCategoryId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regimeCategoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('regimeCategoryId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="regimeLevelIds" className={classNames({ 'p-error': isFormFieldValid('regimeLevelIds') }, 'Label__Text')}>
                                    Regime Name
                                </label>
                                <MultiSelect
                                    id="regimeLevelIds"
                                    placeholder="Select Regime Name"
                                    options={regimeLovValues}
                                    optionLabel="name"
                                    name="regimeLevelIds"
                                    optionValue="lovId"
                                    value={formik.values.regimeLevelIds || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regimeLevelIds') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('regimeLevelIds')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="currencyId" className={classNames({ 'p-error': isFormFieldValid('currencyId') }, 'Label__Text')}>
                                    Currency
                                </label>
                                <Dropdown
                                    id="currencyId"
                                    placeholder="Select currencyId"
                                    options={currencyIdLov}
                                    optionLabel="name"
                                    name="currencyId"
                                    optionValue="lovId"
                                    value={formik.values.currencyId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('currencyId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('currencyId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isFiler" className={classNames({ 'p-error': isFormFieldValid('isFiler') }, 'Label__Text')}>
                                    Apply On
                                </label>
                                <Dropdown
                                    id="isFiler"
                                    placeholder="Select Apply On"
                                    options={applicabledropDown}
                                    optionLabel="label"
                                    name="isFiler"
                                    optionValue="value"
                                    value={formik.values.isFiler || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('isFiler') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('isFiler')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountId" className={classNames({ 'p-error': isFormFieldValid('accountId') }, 'Label__Text')}>
                                    Indicate GL <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="accountId"
                                    placeholder="Select Indicate GL"
                                    options={indicateValues}
                                    optionLabel="accountNo"
                                    name="accountId"
                                    optionValue="accountId"
                                    value={formik.values.accountId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('accountId')}
                            </div>
                        </div>
                        <div className="Form__Header Full__Width">
                            <h2>DEFINE RATE CALCULATION</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="taxRateType" className={classNames({ 'p-error': isFormFieldValid('taxRateType') }, 'Label__Text')}>
                                    Calculation Method <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="taxRateType"
                                    placeholder="Select Calculation Method"
                                    options={calculationMethodJson}
                                    optionLabel="label"
                                    name="taxRateType"
                                    optionValue="description"
                                    value={formik.values.taxRateType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('taxRateType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('taxRateType')}
                            </div>
                        </div>
                        {formik.values.taxRateType !== 'P' && formik.values.taxRateType !== 'F' && formik.values.taxRateType !== 'S' && formik.values.taxRateType !== 'T' && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="taxAmount" className={classNames({ 'p-error': isFormFieldValid('taxAmount') }, 'Label__Text')}>
                                        Tax Amount
                                    </label>
                                    <InputText
                                        id="taxAmount"
                                        placeholder="Enter Tax Amount"
                                        name="taxAmount"
                                        value={formik?.values?.taxAmount || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('taxAmount') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('taxAmount')}
                                </div>
                            </div>
                        )}
                        {formik.values.taxRateType === 'F' ? (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="taxRate" className={classNames({ 'p-error': isFormFieldValid('taxRate') }, 'Label__Text')}>
                                            Tax Amount <span className="Label__Required">*</span>
                                        </label>
                                        <InputNumber
                                            maxLength={9}
                                            inputId="taxRate"
                                            placeholder="Enter Tax Amount"
                                            name="taxRate"
                                            value={formik?.values?.taxRate || ''}
                                            onValueChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('taxRate') }, 'Input__Number')}
                                        />
                                        {getFormErrorMessage('taxRate')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                            <Checkbox className="mr-2 mb-2" inputId="isActive" name="isActive" value={formik.values.isActive} checked={formik.values.isActive} onChange={formik.handleChange} />
                                            Threshold
                                        </label>
                                    </div>
                                    <InputNumber
                                        maxLength={9}
                                        inputId="thresholdAmount"
                                        name="thresholdAmount"
                                        value={formik?.values?.thresholdAmount || ''}
                                        onValueChange={formik.handleChange}
                                        disabled={formik.values.isActive === true ? false : true}
                                        className={classNames({ 'p-invalid': isFormFieldValid('thresholdAmount') }, 'Input__Number')}
                                    />
                                    {getFormErrorMessage('thresholdAmount')}
                                </div>
                            </>
                        ) : null}
                        {formik.values.taxRateType === 'P' && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="taxPercentage" className={classNames({ 'p-error': isFormFieldValid('taxPercentage') }, 'Label__Text')}>
                                            Percentage(%)<span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="taxPercentage"
                                            placeholder="Enter taxPercentage"
                                            name="taxPercentage"
                                            value={formik?.values?.taxPercentage || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('taxPercentage') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('taxPercentage')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="thresholdAmount" className={classNames({ 'p-error': isFormFieldValid('thresholdAmount') }, 'Label__Text')}>
                                            <Checkbox className="mr-2 mb-2" inputId="isActivePercentage" name="isActivePercentage" value={formik.values.isActivePercentage} checked={formik.values.isActivePercentage} onChange={formik.handleChange} />
                                            Threshold
                                        </label>
                                    </div>
                                    <InputNumber
                                        maxLength={9}
                                        inputId="thresholdAmount"
                                        name="thresholdAmount"
                                        value={formik?.values?.thresholdAmount || ''}
                                        onValueChange={formik.handleChange}
                                        disabled={formik.values.isActivePercentage === true ? false : true}
                                        className={classNames({ 'p-invalid': isFormFieldValid('thresholdAmount') }, 'Input__Number')}
                                    />
                                    {getFormErrorMessage('thresholdAmount')}
                                </div>
                            </>
                        )}
                        {formik.values.taxRateType === 'S' ? (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="slabType" className={classNames({ 'p-error': isFormFieldValid('slabType') }, 'Label__Text')}>
                                            Slab Type <span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="slabType"
                                            placeholder="Select Calculation Method"
                                            options={slabTypeJson}
                                            optionLabel="label"
                                            name="slabType"
                                            optionValue="value"
                                            value={formik.values.slabType || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('slabType') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('slabType')}
                                    </div>
                                </div>
                                {formik.values.slabType === 'F' ? (
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="taxSlabAmount" className={classNames({ 'p-error': isFormFieldValid('taxSlabAmount') }, 'Label__Text')}>
                                                Tax Amount
                                            </label>
                                            <InputNumber
                                                maxLength={9}
                                                inputId="taxSlabAmount"
                                                placeholder="Enter Tax Amount"
                                                name="taxSlabAmount"
                                                value={formik?.values?.taxSlabAmount || ''}
                                                onValueChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('taxSlabAmount') }, 'Input__Number')}
                                            />
                                            {getFormErrorMessage('taxSlabAmount')}
                                        </div>
                                    </div>
                                ) : formik.values.slabType === 'P' ? (
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="taxSlabPercentage" className={classNames({ 'p-error': isFormFieldValid('taxSlabPercentage') }, 'Label__Text')}>
                                                Percentage on Slab
                                            </label>
                                            <InputText
                                                id="taxSlabPercentage"
                                                placeholder="Enter Tax Percentage"
                                                name="taxSlabPercentage"
                                                value={formik?.values?.taxSlabPercentage || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('taxSlabPercentage') }, 'Input__Round')}
                                            />
                                            {getFormErrorMessage('taxSlabPercentage')}
                                        </div>
                                    </div>
                                ) : null}
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="fromAmount" className={classNames({ 'p-error': isFormFieldValid('fromAmount') }, 'Label__Text')}>
                                            Start Slab <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="fromAmount"
                                            placeholder="Enter Start Slab"
                                            name="fromAmount"
                                            value={formik?.values?.fromAmount || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('fromAmount') }, 'Input__Round')}
                                        />
                                    </div>
                                    {getFormErrorMessage('fromAmount')}
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="toAmount" className={classNames({ 'p-error': isFormFieldValid('toAmount') }, 'Label__Text')}>
                                            End Slab <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="toAmount"
                                            placeholder="Enter End Slab"
                                            name="toAmount"
                                            value={formik?.values?.toAmount || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('toAmount') }, 'Input__Round')}
                                        />
                                    </div>
                                    {getFormErrorMessage('toAmount')}
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="slabthresholdAmount" className={classNames({ 'p-error': isFormFieldValid('slabthresholdAmount') }, 'Label__Text')}>
                                            <Checkbox className="mr-2 mb-2" inputId="isActiveSlab" name="isActiveSlab" value={formik.values.isActiveSlab} checked={formik.values.isActiveSlab} onChange={formik.handleChange} />
                                            Threshold
                                        </label>
                                    </div>
                                    <InputNumber
                                        maxLength={9}
                                        inputId="slabthresholdAmount"
                                        name="slabthresholdAmount"
                                        value={formik?.values?.slabthresholdAmount || ''}
                                        onValueChange={formik.handleChange}
                                        disabled={formik.values.isActiveSlab === true ? false : true}
                                        className={classNames({ 'p-invalid': isFormFieldValid('slabthresholdAmount') }, 'Input__Number')}
                                    />
                                    {getFormErrorMessage('slabthresholdAmount')}
                                </div>
                            </>
                        ) : null}
                        {formik.values.taxRateType === 'T' ? (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="slabType" className={classNames({ 'p-error': isFormFieldValid('slabType') }, 'Label__Text')}>
                                            Slab Type
                                        </label>
                                        <Dropdown
                                            id="slabType"
                                            placeholder="Select Calculation Method"
                                            options={slabTypeJson}
                                            optionLabel="label"
                                            name="slabType"
                                            optionValue="value"
                                            value={formik.values.slabType || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('slabType') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('slabType')}
                                    </div>
                                </div>
                                {formik.values.slabType === 'F' ? (
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="taxSlabAmount" className={classNames({ 'p-error': isFormFieldValid('taxSlabAmount') }, 'Label__Text')}>
                                                Tax Amount
                                            </label>
                                            <InputNumber
                                                maxLength={9}
                                                id="taxSlabAmount"
                                                placeholder="Enter Tax Amount"
                                                name="taxSlabAmount"
                                                value={formik?.values?.taxSlabAmount || ''}
                                                onValueChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('taxSlabAmount') }, 'Input__Number')}
                                            />
                                            {getFormErrorMessage('taxSlabAmount')}
                                        </div>
                                    </div>
                                ) : formik.values.slabType === 'P' ? (
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="taxSlabPercentage" className={classNames({ 'p-error': isFormFieldValid('taxSlabPercentage') }, 'Label__Text')}>
                                                Percentage on Slab
                                            </label>
                                            <InputText
                                                id="taxSlabPercentage"
                                                placeholder="Enter Tax Percentage"
                                                name="taxSlabPercentage"
                                                value={formik?.values?.taxSlabPercentage || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('taxSlabPercentage') }, 'Input__Round')}
                                            />
                                            {getFormErrorMessage('taxSlabPercentage')}
                                        </div>
                                    </div>
                                ) : null}
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="fromAmount" className={classNames({ 'p-error': isFormFieldValid('fromAmount') }, 'Label__Text')}>
                                            Start Slab <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="fromAmount"
                                            placeholder="Enter Start Slab"
                                            name="fromAmount"
                                            value={formik?.values?.fromAmount || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('fromAmount') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('fromAmount')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="toAmount" className={classNames({ 'p-error': isFormFieldValid('toAmount') }, 'Label__Text')}>
                                            End Slab <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="toAmount"
                                            placeholder="Enter End Slab"
                                            name="toAmount"
                                            value={formik?.values?.toAmount || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('toAmount') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('toAmount')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="slabthresholdAmount" className={classNames({ 'p-error': isFormFieldValid('slabthresholdAmount') }, 'Label__Text')}>
                                            <Checkbox className="mr-2 mb-2" inputId="isActiveTier" name="isActiveTier" value={formik.values.isActiveTier} checked={formik.values.isActiveTier} onChange={formik.handleChange} />
                                            Threshold
                                        </label>
                                    </div>
                                    <InputNumber
                                        maxLength={9}
                                        inputId="slabthresholdAmount"
                                        name="slabthresholdAmount"
                                        value={formik?.values?.slabthresholdAmount || ''}
                                        onValueChange={formik.handleChange}
                                        disabled={formik.values.isActiveTier === true ? false : true}
                                        className={classNames({ 'p-invalid': isFormFieldValid('slabthresholdAmount') }, 'Input__Number')}
                                    />
                                    {getFormErrorMessage('slabthresholdAmount')}
                                </div>
                            </>
                        ) : null}
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="effectiveFrom" className={classNames({ 'p-error': isFormFieldValid('effectiveFrom') }, 'Label__Text')}>
                                    Effective From <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    // min={currentDate}
                                    id="effectiveFrom"
                                    type="date"
                                    name="effectiveFrom"
                                    value={formik?.values?.effectiveFrom || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('effectiveFrom') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('effectiveFrom')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} label="Update" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/taxprofilemaintenance')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default EditTaxProfile;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../services/PostTemplate';
import { handleGetRequest } from '../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { InputNumber } from 'primereact/inputnumber';

function AddTypeOfAccount() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [accountClassificationData, setAccountClassificationData] = useState([]);
    const [kycSetsData, setKycSetsData] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getAccountClassificationData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getclientroles/LKP_ACCOUNT_CLASSIFICATION:*');
        setAccountClassificationData(res?.payLoad);
    };

    useEffect(() => {
        getAccountClassificationData();
    }, []); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        accountLevelName: Yup.string()
            .required('This field is required.')
            .matches(/^(?=.*[A-Za-z])[A-Za-z0-9 ]+$/, 'Invalid account name type'),
        lkpAccountClassificationId: Yup.mixed().required('This field is required.'),
        kycSetHeadId: Yup.mixed().required('This field is required.'),
        dailyTransLimitDr: Yup.number().required('This field is required.').moreThan(0, 'Value must be greater than 0').nullable(),
        dailyAmtLimitDr: Yup.number().required('This field is required.').moreThan(0, 'Value must be greater than 0').nullable(),
        monthlyTransLimitDr: Yup.number().required('This field is required.').moreThan(0, 'Value must be greater than 0').nullable(),
        monthlyAmtLimitDr: Yup.number().required('This field is required.').moreThan(0, 'Value must be greater than 0').nullable(),
        yearlyTransLimitDr: Yup.number().required('This field is required.').moreThan(0, 'Value must be greater than 0').nullable(),
        yearlyAmtLimitDr: Yup.number().required('This field is required.').moreThan(0, 'Value must be greater than 0').nullable(),
        dailyTransLimitCr: Yup.number().required('This field is required.').moreThan(0, 'Value must be greater than 0').nullable(),
        dailyAmtLimitCr: Yup.number().required('This field is required.').moreThan(0, 'Value must be greater than 0').nullable(),
        monthlyTransLimitCr: Yup.number().required('This field is required.').moreThan(0, 'Value must be greater than 0').nullable(),
        monthlyAmtLimitCr: Yup.number().required('This field is required.').moreThan(0, 'Value must be greater than 0').nullable(),
        yearlyTransLimitCr: Yup.number().required('This field is required.').moreThan(0, 'Value must be greater than 0').nullable(),
        yearlyAmtLimitCr: Yup.number().required('This field is required.').moreThan(0, 'Value must be greater than 0').nullable()
    });

    const formik = useFormik({
        validationSchema,
        initialValues: {
            lkpAccountClassificationId: '',
            accountLevelName: '',
            kycSetHeadId: '',
            dailyTransLimitDr: null,
            dailyAmtLimitDr: null,
            monthlyTransLimitDr: null,
            monthlyAmtLimitDr: null,
            yearlyTransLimitDr: null,
            yearlyAmtLimitDr: null,
            dailyTransLimitCr: null,
            dailyAmtLimitCr: null,
            monthlyTransLimitCr: null,
            monthlyAmtLimitCr: null,
            yearlyTransLimitCr: null,
            yearlyAmtLimitCr: null,
            maxAmtLimit: null
        },
        validate: (data) => {
            let errors = {};

            if (['2', '3'].includes(formik.values.lkpAccountClassificationId)) {
                if (!data?.maxAmtLimit) {
                    errors.maxAmtLimit = 'This field is required';
                }
                if (data?.maxAmtLimit <= 0) {
                    errors.maxAmtLimit = 'Maximum available balance should be greater than 0';
                }
                if (data?.maxAmtLimit === /^\d+$/.test(data?.maxAmtLimit)) {
                    errors.maxAmtLimit = 'Special characters are not allowed';
                }

                if (parseInt(data?.maxAmtLimit) < parseInt(data?.yearlyAmtLimitDr) || parseInt(data?.maxAmtLimit) < parseInt(data?.yearlyAmtLimitCr)) {
                    errors.maxAmtLimit = 'Maximum Amount Limit should be less than or equal to Yearly Amount Limit';
                }
            }

            if (data?.dailyTransLimitDr === '' || data?.dailyTransLimitDr <= 0) {
                errors.dailyTransLimitDr = 'This field is required and value must be greater than 0';
            }
            if (data?.monthlyTransLimitDr === '' || data?.monthlyTransLimitDr <= 0) {
                errors.monthlyTransLimitDr = 'This field is required and value must be greater than 0';
            }
            if (data.yearlyTransLimitDr === '' || data.yearlyTransLimitDr <= 0) {
                errors.yearlyTransLimitDr = 'This field is required and value must be greater than 0';
            }

            if (data.dailyTransLimitCr === '' || data.dailyTransLimitCr <= 0) {
                errors.dailyTransLimitCr = 'This field is required and value must be greater than 0';
            }

            if (data.monthlyTransLimitCr === '' || data.monthlyTransLimitCr <= 0) {
                errors.monthlyTransLimitCr = 'This field is required and value must be greater than 0';
            }

            if (data.yearlyTransLimitCr === '' || data.yearlyTransLimitCr <= 0) {
                errors.yearlyTransLimitCr = 'This field is required and value must be greater than 0';
            }
            // Debit Amount less/greater than Amount Validation
            if (data?.dailyAmtLimitDr) {
                if (parseInt(data?.dailyAmtLimitDr) > parseInt(data?.monthlyAmtLimitDr)) {
                    errors.monthlyAmtLimitDr = 'Monthly Amount should be greater than Daily Amount';
                }
            }
            if (data?.monthlyAmtLimitDr) {
                if (parseInt(data?.monthlyAmtLimitDr) > parseInt(data?.yearlyAmtLimitDr)) {
                    errors.yearlyAmtLimitDr = 'Yearly Amount should be greater than Monthly Amount';
                }
            }

            // Debit Count less/greater than Count Validation
            if (data?.dailyTransLimitDr) {
                if (parseInt(data?.dailyTransLimitDr) > parseInt(data?.monthlyTransLimitDr)) {
                    errors.monthlyTransLimitDr = 'Monthly Count should be greater than Daily Count';
                }
            }
            if (data?.monthlyTransLimitDr) {
                if (parseInt(data?.monthlyTransLimitDr) > parseInt(data?.yearlyTransLimitDr)) {
                    errors.yearlyTransLimitDr = 'Yearly Count should be greater than Monthly Count';
                }
            }

            // Credit Amount less/greater than Amount Validation
            if (data?.dailyAmtLimitCr) {
                if (parseInt(data?.dailyAmtLimitCr) > parseInt(data?.monthlyAmtLimitCr)) {
                    errors.monthlyAmtLimitCr = 'Monthly Amount should be greater than Daily Amount';
                }
            }
            if (data?.monthlyAmtLimitCr) {
                if (parseInt(data?.monthlyAmtLimitCr) > parseInt(data?.yearlyAmtLimitCr)) {
                    errors.yearlyAmtLimitCr = 'Yearly Amount should be greater than Monthly Amount';
                }
            }

            // Credit Count less/greater than Count Validation
            if (data?.dailyTransLimitCr) {
                if (parseInt(data?.dailyTransLimitCr) > parseInt(data?.monthlyTransLimitCr)) {
                    errors.monthlyTransLimitCr = 'Monthly Count should be greater than Daily Count';
                }
            }
            if (data?.monthlyTransLimitCr) {
                if (parseInt(data?.monthlyTransLimitCr) > parseInt(data?.yearlyTransLimitCr)) {
                    errors.yearlyTransLimitCr = 'Yearly Count should be greater than Monthly Count';
                }
            }

            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/account/v1/accountype/createnewaccounttype', true, false, 'typeofaccount'));

            if (res?.responseCode === '010000') {
                formik.resetForm();
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });
    const getUserLov = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getkycsets/TBL_KYC_SET_HEAD:*/${formik?.values?.lkpAccountClassificationId}`);
        setKycSetsData(resp?.payLoad);
    };

    useEffect(() => {
        if (formik.values.lkpAccountClassificationId !== '') {
            getUserLov();
        }
    }, [formik?.values?.lkpAccountClassificationId]); // eslint-disable-line

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleAccountTypeChange = (event) => {
        // Reset the KYC set value when the account type changes
        formik.setFieldValue('kycSetHeadId', ''); // Set KYC set value to empty string

        // Update the account type value
        formik.handleChange(event);
    };

    console.log(formik.values, 'jjjjjjjjjjjjjjjjjjjjjjjjjjj');

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>ADD NEW</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lkpAccountClassificationId" className={classNames({ 'p-error': isFormFieldValid('lkpAccountClassificationId') }, 'Label__Text')}>
                                    Select Type Of Account<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="lkpAccountClassificationId"
                                    placeholder="Select Type Of Account"
                                    options={accountClassificationData}
                                    optionLabel="name"
                                    name="lkpAccountClassificationId"
                                    optionValue="lovId"
                                    value={formik.values.lkpAccountClassificationId || ''}
                                    onChange={handleAccountTypeChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpAccountClassificationId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('lkpAccountClassificationId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountLevelName" className={classNames({ 'p-error': isFormFieldValid('accountLevelName') }, 'Label__Text')}>
                                    Name Of Account <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="accountLevelName"
                                    placeholder="Enter Name Of Account"
                                    name="accountLevelName"
                                    value={formik?.values?.accountLevelName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountLevelName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('accountLevelName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="kycSetHeadId" className={classNames({ 'p-error': isFormFieldValid('kycSetHeadId') }, 'Label__Text')}>
                                    Select KYC Set<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="kycSetHeadId"
                                    placeholder="Select KYC Set"
                                    options={kycSetsData}
                                    optionLabel="name"
                                    name="kycSetHeadId"
                                    optionValue="lovId"
                                    value={formik.values.kycSetHeadId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('kycSetHeadId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('kycSetHeadId')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-6 pt-3 pb-3">
                            <h3>
                                Debit <span className="Label__Required">*</span>
                            </h3>

                            <div className="Debit__Heading">
                                <div>
                                    <p>Particular</p>
                                </div>
                                <div>
                                    <p>Count</p>
                                </div>
                                <div>
                                    <p>Amount</p>
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Daily</p>
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="dailyTransLimitDr"
                                        placeholder="1"
                                        name="dailyTransLimitDr"
                                        maxLength={6}
                                        value={formik?.values?.dailyTransLimitDr || ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('dailyTransLimitDr') }, 'Input__Number')}
                                    />
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="dailyAmtLimitDr"
                                        placeholder="1000.00"
                                        name="dailyAmtLimitDr"
                                        maxLength={17}
                                        value={formik?.values?.dailyAmtLimitDr || ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('dailyAmtLimitDr') }, 'Input__Number')}
                                    />
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Monthly</p>
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="monthlyTransLimitDr"
                                        placeholder="1"
                                        name="monthlyTransLimitDr"
                                        maxLength={6}
                                        value={formik?.values?.monthlyTransLimitDr || ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('monthlyTransLimitDr') }, 'Input__Number')}
                                    />
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="monthlyAmtLimitDr"
                                        placeholder="500.00"
                                        name="monthlyAmtLimitDr"
                                        maxLength={17}
                                        value={formik?.values?.monthlyAmtLimitDr || ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('monthlyAmtLimitDr') }, 'Input__Number')}
                                    />
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Yearly</p>
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="yearlyTransLimitDr"
                                        placeholder="2"
                                        name="yearlyTransLimitDr"
                                        maxLength={6}
                                        value={formik?.values?.yearlyTransLimitDr || ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitDr') }, 'Input__Number')}
                                    />
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="yearlyAmtLimitDr"
                                        placeholder="100.00"
                                        name="yearlyAmtLimitDr"
                                        maxLength={17}
                                        value={formik?.values?.yearlyAmtLimitDr || ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('yearlyAmtLimitDr') }, 'Input__Number')}
                                    />
                                </div>
                            </div>
                            {['', '0', null].includes(formik.values.dailyTransLimitDr)
                                ? getFormErrorMessage('dailyTransLimitDr')
                                : ['', '0', null].includes(formik.values.dailyAmtLimitDr)
                                ? getFormErrorMessage('dailyAmtLimitDr')
                                : ['', '0', null].includes(formik.values.monthlyTransLimitDr)
                                ? getFormErrorMessage('monthlyTransLimitDr')
                                : ['', '0', null].includes(formik.values.monthlyAmtLimitDr)
                                ? getFormErrorMessage('monthlyAmtLimitDr')
                                : ['', '0', null].includes(formik.values.yearlyTransLimitDr)
                                ? getFormErrorMessage('yearlyTransLimitDr')
                                : ['', '0', null].includes(formik.values.yearlyAmtLimitDr)
                                ? getFormErrorMessage('yearlyAmtLimitDr')
                                : null}
                        </div>
                        <div className="p-field col-12 md:col-6 pt-3 pb-3">
                            <h3>
                                Credit <span className="Label__Required">*</span>
                            </h3>
                            <div className="Debit__Heading">
                                <div>
                                    <p>Particular</p>
                                </div>
                                <div>
                                    <p>Count</p>
                                </div>
                                <div>
                                    <p>Amount</p>
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Daily</p>
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="dailyTransLimitCr"
                                        placeholder="1"
                                        name="dailyTransLimitCr"
                                        maxLength={6}
                                        value={formik?.values?.dailyTransLimitCr || ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('dailyTransLimitCr') }, 'Input__Number')}
                                    />
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="dailyAmtLimitCr"
                                        placeholder="1000.00"
                                        name="dailyAmtLimitCr"
                                        maxLength={17}
                                        value={formik?.values?.dailyAmtLimitCr || ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('dailyAmtLimitCr') }, 'Input__Number')}
                                    />
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Monthly</p>
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="monthlyTransLimitCr"
                                        placeholder="1"
                                        name="monthlyTransLimitCr"
                                        maxLength={6}
                                        value={formik?.values?.monthlyTransLimitCr || ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('monthlyTransLimitCr') }, 'Input__Number')}
                                    />
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="monthlyAmtLimitCr"
                                        placeholder="500.00"
                                        name="monthlyAmtLimitCr"
                                        maxLength={17}
                                        value={formik?.values?.monthlyAmtLimitCr || ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('monthlyAmtLimitCr') }, 'Input__Number')}
                                    />
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Yearly</p>
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="yearlyTransLimitCr"
                                        placeholder="2"
                                        name="yearlyTransLimitCr"
                                        maxLength={6}
                                        value={formik?.values?.yearlyTransLimitCr || ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitCr') }, 'Input__Number')}
                                    />
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="yearlyAmtLimitCr"
                                        placeholder="100.00"
                                        name="yearlyAmtLimitCr"
                                        maxLength={17}
                                        value={formik?.values?.yearlyAmtLimitCr || ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('yearlyAmtLimitCr') }, 'Input__Number')}
                                    />
                                </div>
                            </div>
                            {['', '0', null].includes(formik.values.dailyTransLimitCr)
                                ? getFormErrorMessage('dailyTransLimitCr')
                                : ['', '0', null].includes(formik.values.dailyAmtLimitCr)
                                ? getFormErrorMessage('dailyAmtLimitCr')
                                : ['', '0', null].includes(formik.values.monthlyTransLimitCr)
                                ? getFormErrorMessage('monthlyTransLimitCr')
                                : ['', '0', null].includes(formik.values.monthlyAmtLimitCr)
                                ? getFormErrorMessage('monthlyAmtLimitCr')
                                : ['', '0', null].includes(formik.values.yearlyTransLimitCr)
                                ? getFormErrorMessage('yearlyTransLimitCr')
                                : ['', '0', null].includes(formik.values.yearlyAmtLimitCr)
                                ? getFormErrorMessage('yearlyAmtLimitCr')
                                : null}
                        </div>
                    </div>
                    {(formik.values.lkpAccountClassificationId === '2' || formik.values.lkpAccountClassificationId === '3') && (
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="maxAmtLimit">
                                    <label htmlFor="maxAmtLimit" className={classNames({ 'p-error': isFormFieldValid('maxAmtLimit') }, 'Label__Text__MaxBal')}>
                                        Maximum Balance<span className="Label__Required">*</span>
                                    </label>
                                    <InputNumber
                                        inputId="maxAmtLimit"
                                        placeholder="Enter Maximum Balance"
                                        name="maxAmtLimit"
                                        maxLength={11}
                                        value={formik?.values?.maxAmtLimit || ''}
                                        onValueChange={formik.handleChange}
                                        // className={classNames({ 'p-invalid': isFormFieldValid('maxAmtLimit') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('maxAmtLimit')}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} label="Submit" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/typeofaccount')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default AddTypeOfAccount;

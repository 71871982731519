import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useRef } from 'react';

import { MultiSelect } from 'primereact/multiselect';
import SecuredFileChange from '../../../components/SecuredFileChange';
import MobileView from './mobileView';

function AddPromotion() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [visibleData, setVisibleData] = useState([]);
    const [targAud, setTargAud] = useState([]);
    const [mainData, setMainData] = useState([]);
    const [subData, setSubData] = useState([]);
    const [secSubData, setSecSubData] = useState([]);
    const [segmentsData, setSegmentsData] = useState([]);
    const [prodLkp, seTProdLkp] = useState([]);
    const promotionIconRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [iconLink, setIconLink] = useState('');

    const [rankingData, setRankingData] = useState([]);
    const [categoryIconError, setCategoryIconError] = useState('');

    const location = useLocation();
    const { bannerId } = location.state || {};
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
            .required('This field is required'),
        text: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
            .required('This field is required'),

        description: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
            .required('This field is required'),

        cmsVisibleId: Yup.string().required('This field is required'),
        image: Yup.string().required('This field is required'),

        tblCmsBannerSegments: Yup.array().required('This Field is required').min(1, 'This Field is required'),

        startDate: Yup.date().required('Start Date is required'),

        endDate: Yup.date()
            .when('startDate', (startDate, schema) => (startDate ? schema.min(startDate, 'End Date must be greater than Start Date').required('End Date is required') : schema))
            .required('End Date is required when Start Date is specified'),
        ranking: Yup.string().required('This field is required'),
        tblCmsBannerAccountLevels: Yup.array().required('This Field is required').min(1, 'This Field is required'),

        redirectionUrl:
            Yup.string()
            .url('Invalid URL format')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cmsBannerId: '',
            cmsBannerTypeId: bannerId,
            name: '',
            cmsVisibleId: '',
            redirection: 'Y',
            redirectionUrl: '',
            cmsProductId: '',
            cmsMainCategoryId: '',
            cmsSubCategoryId: '',
            cmsSecondSubCategoryId: '',
            text: '',
            description: '',
            image: '',
            addButton: 'N',
            noOfButtons: '',
            tblCmsBannerAccountLevels: [],
            frequency: '',
            startDate: '',
            endDate: '',
            ranking: '',
            isHide: 'Y',
            tblCmsBannerButtons: [],
            tblCmsBannerSegments: []
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const formData = new FormData();
            formData.append('request', JSON.stringify(newData));
            if (selectedFile) {
                formData.append('file', selectedFile);
            }
            await dispatch(handlePostRequest(formData, '/cms/v1/cms/savebanner', true, false, 'banners'));

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const getVisibleData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_VISIBLE');
        setVisibleData(resp?.payLoad);
    };

    const getCategoryDataLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcategorydatalov');
        setMainData(res?.payLoad);
    };

    const getSubCategoryDataLov = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getsubcategorydatalov/${formik?.values?.cmsMainCategoryId}`);
        setSubData(res?.payLoad);
    };

    const getSecSubCategoryDataLov = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getsecondsubcategorydatalov/${formik.values.cmsMainCategoryId}/${formik.values.cmsSubCategoryId}`);
        setSecSubData(res?.payLoad);
    };

    const getSegmentsData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getsegments/LKP_SEGMENT:*');
        const updated = res?.payLoad?.map((item) => ({
            ...item,
            segmentId: item.lovId
        }));
        setSegmentsData(updated);
    };

    const geTProdLkp = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_PRODUCT');
        seTProdLkp(res?.payLoad);
    };

    const getTargetAud = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getaccounttypes/LKP_ACCOUNT_LEVEL:*');
        setTargAud(res?.payLoad);
    };

    const getRankingData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_RANKING');
        setRankingData(res?.payLoad);
    };
    useEffect(() => {
        getRankingData();
        getTargetAud();
        getSegmentsData();
        geTProdLkp();
        getVisibleData();
        getCategoryDataLov();
        if (formik?.values?.cmsMainCategoryId != '') {
            getSubCategoryDataLov();
        }
        if (formik?.values?.cmsMainCategoryId != '' && formik?.values?.cmsSubCategoryId != '') {
            getSecSubCategoryDataLov();
        }
    }, [formik?.values?.cmsMainCategoryId, formik?.values?.cmsSubCategoryId]);

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const handlePromotionIcon = () => {
        promotionIconRef.current.click();
    };

    const handlePromotionChange = (event) => {
        setIconLink('');
        setCategoryIconError('');
        formik.setFieldValue('image', '');
        setSelectedFile(null);
        const selectedFile = event.target.files[0];
        const fileTypes = ['image'];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setCategoryIconError(error);
            setSelectedFile(null);
        } else if (newFile) {
            setSelectedFile(selectedFile);
            formik.setFieldValue('image', selectedFile.name);
            setIconLink(URL.createObjectURL(selectedFile));

            // setSecondSubCategoryIconFileName(selectedFile?.name);
        }
    };
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="Form__Header Full__Width">
                        <h2>ADD NEW PROMOTIONS</h2>
                    </div>
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-fluid p-formgrid grid mb-5 col-12 md:col-9">
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                        Name <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="name"
                                        placeholder="Enter product name"
                                        name="name"
                                        value={formik?.values?.name || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('name')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsVisibleId" className={classNames({ 'p-error': isFormFieldValid('cmsVisibleId') }, 'Label__Text')}>
                                        Visible On<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="cmsVisibleId"
                                        placeholder="Select Visible On"
                                        options={visibleData}
                                        optionLabel="name"
                                        name="cmsVisibleId"
                                        optionValue="lovId"
                                        value={formik.values.cmsVisibleId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsVisibleId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('cmsVisibleId')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="redirection" className={classNames({ 'p-error': isFormFieldValid('redirection') }, 'Label__Text')}>
                                        Redirection
                                    </label>
                                    <div className="flex">
                                        <div className="mt-2 mr-5">
                                            <RadioButton inputId="applicable" name="redirection" value="Y" onChange={formik.handleChange} checked={formik.values.redirection === 'Y'} />
                                            <label htmlFor="applicable" className={classNames({ 'p-error': isFormFieldValid('applicable') }, 'Label__Text')}>
                                                Yes
                                            </label>
                                        </div>
                                        <div className="mt-2">
                                            <RadioButton inputId="notApplicable" name="redirection" value="N" onChange={formik.handleChange} checked={formik.values.redirection === 'N'} />
                                            <label htmlFor="notApplicable" className={classNames({ 'p-error': isFormFieldValid('notApplicable') }, 'Label__Text')}>
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsProductId" className={classNames({ 'p-error': isFormFieldValid('cmsProductId') }, 'Label__Text')}>
                                        Product
                                    </label>
                                    <Dropdown
                                        id="cmsProductId"
                                        placeholder="Select Product"
                                        options={prodLkp}
                                        optionLabel="name"
                                        name="cmsProductId"
                                        optionValue="lovId"
                                        value={formik.values.cmsProductId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsProductId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('cmsProductId')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsMainCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsMainCategoryId') }, 'Label__Text')}>
                                        Main Category
                                    </label>
                                    <Dropdown
                                        id="cmsMainCategoryId"
                                        placeholder="Select Main Category"
                                        options={mainData}
                                        optionLabel="name"
                                        name="cmsMainCategoryId"
                                        optionValue="lovId"
                                        value={formik.values.cmsMainCategoryId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsMainCategoryId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('cmsMainCategoryId')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsSubCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsSubCategoryId') }, 'Label__Text')}>
                                        Sub Category
                                    </label>
                                    <Dropdown
                                        id="cmsSubCategoryId"
                                        placeholder="Select Sub Category"
                                        options={subData}
                                        optionLabel="name"
                                        name="cmsSubCategoryId"
                                        optionValue="lovId"
                                        value={formik.values.cmsSubCategoryId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsSubCategoryId') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('cmsSubCategoryId')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsSecondSubCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsSecondSubCategoryId') }, 'Label__Text')}>
                                        2nd Sub Category
                                    </label>
                                    <Dropdown
                                        id="cmsSecondSubCategoryId"
                                        placeholder="Select 2nd Sub Category"
                                        options={secSubData}
                                        optionLabel="name"
                                        name="cmsSecondSubCategoryId"
                                        optionValue="lovId"
                                        value={formik.values.cmsSecondSubCategoryId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsSecondSubCategoryId') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('cmsSecondSubCategoryId')}
                                </div>
                            </div>
                           
                            <div className="p-field col-12 md:col-12 w-full pt-3 pb-3 p-field-addbanner-width">
                                <div className="desc_Text_Area">
                                    <label htmlFor="redirectionUrl" className={classNames({ 'p-error': isFormFieldValid('addText') }, 'Label__Text')}>
                                        Redirection URL Path
                                    </label>
                                    <InputTextarea
                                        placeholder="Enter Redirection URL Path"
                                        inputid="redirectionUrl"
                                        name="redirectionUrl"
                                        rows={2}
                                        value={formik?.values?.redirectionUrl}
                                        cols={30}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('addText') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('redirectionUrl')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="text" className={classNames({ 'p-error': isFormFieldValid('text') }, 'Label__Text')}>
                                        Title<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="text"
                                        placeholder="Add Text"
                                        name="text"
                                        value={formik?.values?.text || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('text') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('text')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <div className="mb-2">
                                        <label className={classNames({ 'p-error': isFormFieldValid('image') }, 'Label__Text')}>
                                            Upload Image
                                            <span className="Label__Required">*</span>
                                        </label>
                                        <div className="file-input-container w-100" onClick={handlePromotionIcon}>
                                            <input type="file" accept="image/*" style={{ display: 'none' }} ref={promotionIconRef} onChange={handlePromotionChange} />
                                            <input
                                                type="text"
                                                className={classNames({ 'p-inputtext p-component p-invalid': isFormFieldValid('image') }, 'file-input-field Input__Round')}
                                                placeholder="Choose a file..."
                                                value={formik?.values?.image}
                                                readOnly
                                            />
                                            <span className="file-upload-icon">
                                                <i className="pi pi-paperclip" />
                                            </span>
                                        </div>
                                        {categoryIconError ? <small className="p-error">{categoryIconError}</small> : getFormErrorMessage('image')}
                                    </div>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="desc_Text_Area">
                                    <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') }, 'Label__Text')}>
                                        Description <span className="Label__Required">*</span>
                                    </label>
                                    <InputTextarea
                                        placeholder="--"
                                        inputid="description"
                                        name="description"
                                        rows={4}
                                        cols={30}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('description') }, 'Input__Round')}
                                        value={formik?.values?.description}
                                    />
                                    {getFormErrorMessage('description')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="ranking" className={classNames({ 'p-error': isFormFieldValid('ranking') }, 'Label__Text')}>
                                        Ranking<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="ranking"
                                        placeholder="Select Ranking"
                                        options={rankingData}
                                        optionLabel="name"
                                        name="ranking"
                                        optionValue="lovId"
                                        value={formik.values.ranking || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('ranking') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('ranking')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') }, 'Label__Text')}>
                                        Start Date<span className="Label__Required">*</span>
                                    </label>
                                    <InputText id="startDate" type="date" name="startDate" value={formik?.values?.startDate} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('startDate') }, 'Input__Round')} />

                                    {getFormErrorMessage('startDate')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') }, 'Label__Text')}>
                                        End Date <span className="Label__Required">*</span>
                                    </label>
                                    <InputText id="endDate" type="date" name="endDate" value={formik?.values?.endDate} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('endDate') }, 'Input__Round')} />

                                    {getFormErrorMessage('endDate')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field customMultiselect">
                                    <label htmlFor="tblCmsBannerAccountLevels" className={classNames({ 'p-error': isFormFieldValid('tblCmsBannerAccountLevels') }, 'Label__Text')}>
                                    Target Audience (Account Level)<span className="Label__Required">*</span>
                                    </label>
                                    <MultiSelect
                                        id="tblCmsBannerAccountLevels"
                                        placeholder="Select Target Audience"
                                        options={targAud}
                                        optionLabel="name"
                                        display="chip"
                                        name="tblCmsBannerAccountLevels"
                                        optionValue="lovId"
                                        value={formik?.values?.tblCmsBannerAccountLevels?.map((item) => item.accountLevelId || '')}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'tblCmsBannerAccountLevels',
                                                e?.value?.map((lovId) => ({
                                                    accountLevelId: lovId,
                                                  

                                                    isActive: 'Y'
                                                }))
                                            );
                                        }}
                                        className={classNames({ 'p-invalid': isFormFieldValid('tblCmsBannerAccountLevels') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('tblCmsBannerAccountLevels')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="tblCmsBannerSegments" className={classNames({ 'p-error': isFormFieldValid('tblCmsBannerSegments') }, 'Label__Text')}>
                                        Segments<span className="Label__Required">*</span>
                                    </label>
                                    <MultiSelect
                                        id="tblCmsBannerSegments"
                                        placeholder="Select Segments"
                                        options={segmentsData}
                                        optionLabel="name"
                                        display="chip"
                                        name="tblCmsBannerSegments"
                                        optionValue="segmentId"
                                        value={formik?.values?.tblCmsBannerSegments.map((segment) => segment.segmentId || '')}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'tblCmsBannerSegments',
                                                e?.value?.map((lovId) => ({
                                                    segmentId: lovId
                                                }))
                                            );
                                        }}
                                        className={classNames({ 'p-invalid': isFormFieldValid('tblCmsBannerSegments') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('tblCmsBannerSegments')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 flex align-items-end justify-content-start">
                                <div className="field-checkbox flex mb-2 p-field-addbanner-width">
                                    <div className="mr-5">
                                        <Checkbox
                                            inputId="show"
                                            name="isHide"
                                            value="Y"
                                            checked={formik?.values?.isHide === 'Y'}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                formik.setFieldValue('isHide', e.checked ? 'Y' : 'N');
                                            }}
                                            style={{ marginTop: '10px' }}
                                        />
                                        <label htmlFor="show" className={classNames({ 'p-error': isFormFieldValid('enablePartialPayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                            Show
                                        </label>
                                    </div>
                                    <div>
                                        <Checkbox
                                            inputId="hide"
                                            name="isHide"
                                            value="N"
                                            checked={formik?.values?.isHide === 'N'}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                formik.setFieldValue('isHide', e.checked ? 'N' : 'Y');
                                            }}
                                            style={{ marginTop: '10px' }}
                                        />
                                        <label htmlFor="hide" className={classNames({ 'p-error': isFormFieldValid('enablePartialPayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                            Hide
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-12 pt-3 pb-3"></div>
                        </div>

                        <div className="p-field col-12 md:col-3 pt-3 pb-3 col-4 flex align-items-start justify-content-center">
                            <MobileView icon={iconLink} title={formik?.values?.text} descrition={formik?.values?.description} date={formik?.values?.endDate} />
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="submit" label="Submit" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/banners')} type="button" label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddPromotion;

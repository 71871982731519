import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';

const RegistrationCityByLocation = ({ cityWiseRegisteration }) => {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        const countArr =
            cityWiseRegisteration &&
            cityWiseRegisteration.map((item) => {
                return item?.parameterValue;
            });
        const labelArr =
            cityWiseRegisteration &&
            cityWiseRegisteration.map((item) => {
                return item?.parameterName;
            });
        const data = {
            labels: labelArr,
            datasets: [
                {
                    label: 'Value',
                    data: countArr,
                    backgroundColor: ['#d7ecfb', '#ebe0ff', '#8debc2', '#b4eff1', '#eeca50', '#dee176', '#26A69A'],
                    yAxisID: 'y'
                }
            ]
        };

        setChartData(data);
    }, [cityWiseRegisteration]);
    const options = {
        plugins: {
            legend: {
                display: false
            }
        }
    };

    return (
        <div className="col-12 md:col-6">
            <div className="card Card__Round">
                <div className="card">
                    <h5>Registration Location By City</h5>
                    <Chart type="bar" data={chartData} options={options} className="w-full" style={{ height: '35rem' }} />
                </div>
            </div>
        </div>
    );
};

export default RegistrationCityByLocation;

import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../../services/PostTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function EditaggregatorConfigChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');
    const [statusLovData, setStatusLovData] = useState([]);
    const [dataById, setDatabyId] = useState();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPendingRequest = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    const getConfigDatabyId = async () => {
        const response = await handleGetRequest(`/lending/v1/lending/getaggregatorconfigbyid/${refTableId}`);
        setDatabyId(response);
        formik.setFieldValue('name', response?.payLoad?.name);
        formik.setFieldValue('contactNo', response?.payLoad?.contactNo);
        formik.setFieldValue('email', response?.payLoad?.email);
        formik.setFieldValue('businessAddress', response?.payLoad?.businessAddress);
        formik.setFieldValue('isActive', response?.payLoad?.isActive);
        formik.setFieldValue('lmsAggregatorId', response?.payLoad?.lmsAggregatorId);
        formik.setFieldValue('updateIndex', response?.payLoad?.updateindex);
    };

    const getConfigDatabyIdU = async () => {
        const response = await handleGetRequest(`/lending/v1/lending/getmcrequestaggregatorconfigbyid/${mcRequestId}`);
        setDatabyId(response?.payLoad);
        formik.setFieldValue('name', response?.payLoad?.name);
        formik.setFieldValue('contactNo', response?.payLoad?.contactNo);
        formik.setFieldValue('email', response?.payLoad?.email);
        formik.setFieldValue('businessAddress', response?.payLoad?.businessAddress);
        formik.setFieldValue('isActive', response?.payLoad?.isActive);
        formik.setFieldValue('lmsAggregatorId', response?.payLoad?.lmsAggregatorId);
        formik.setFieldValue('updateIndex', response?.payLoad?.updateindex);
    };

    const getConfigDatabyIdUIsActive = async () => {
        const response = await handleGetRequest(`/lending/v1/lending/getmcrequestaggregatorconfigbyid/${mcRequestId}`);
        setDatabyId(response?.payLoad);
    };

    useEffect(() => {
        getStatusLov();
        if (requestType === 'I') {
            getConfigDatabyId();
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getConfigDatabyIdU();
        } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
            getConfigDatabyId();
            getConfigDatabyIdUIsActive();
        }
    }, []); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            name: '',
            businessAddress: '',
            email: '',
            contactNo: '',
            action: '',
            checkerComments: '',
            isActive: '',
            lmsAggregatorId: '',
            mcPeindingRequestId: mcPendingRequest,
            aggregatorConfigRequest: {},
            mcActionRequest: {},
            checkerRequestType: '',
            updatedIndex: '',
            mcRequestId: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        aggregatorConfigRequest: {
                            lmsAggregatorId: data?.lmsAggregatorId,
                            name: data?.name,
                            businessAddress: data?.businessAddress,
                            email: data?.email,
                            contactNo: data?.contactNo,
                            isActive: data?.isActive,
                            checkerRequestType: requestType
                        },
                        mcActionRequest: {
                            checkerComments: data?.checkerComments,
                            action: data?.action,
                            updatedIndex: data?.updatedIndex,
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPendingRequest,
                            checkerId: '1'
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const newDataU = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        aggregatorConfigRequest: dataById,
                        mcActionRequest: {
                            checkerComments: data?.checkerComments,
                            action: data?.action,
                            updatedIndex: data?.updatedIndex,
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPendingRequest,
                            checkerId: '1'
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            await dispatch(handlePostRequest(requestType === 'I' ? newData : newDataU, '/lending/v1/lending/aggregatorconfigcheckeraction', true, false, 'requestchecker'));
            setloading(false);
            setloadingIcon('');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>Edit AGGREGATOR CONFIGURATION</h2>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                    Aggregator Name
                                </label>
                                <InputText
                                    disabled={true}
                                    maxLength={100}
                                    id="name"
                                    placeholder="Enter Aggregator name"
                                    name="name"
                                    value={formik.values.name || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('discount') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('name')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="businessAddress" className={classNames({ 'p-error': isFormFieldValid('businessAddress') }, 'Label__Text')}>
                                    Business Address
                                </label>
                                <InputText
                                    disabled={true}
                                    maxLength={100}
                                    id="businessAddress"
                                    placeholder="Enter business address"
                                    name="businessAddress"
                                    value={formik.values.businessAddress || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('discount') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('businessAddress')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') }, 'Label__Text')}>
                                    Email
                                </label>
                                <InputText
                                    disabled={true}
                                    maxLength={100}
                                    id="email"
                                    placeholder="Enter email"
                                    name="email"
                                    value={formik.values.email || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('discount') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('email')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="contactNo" className={classNames({ 'p-error': isFormFieldValid('contactNo') }, 'Label__Text')}>
                                    Contact Number
                                </label>
                                <InputText
                                    disabled={true}
                                    maxLength={11}
                                    id="contactNo"
                                    placeholder="Enter Contact Number"
                                    name="contactNo"
                                    value={formik.values.contactNo || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('discount') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('contactNo')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    optionDisabled={(option) => option?.name === 'Pending'}
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    maxLength={200}
                                    autoResize
                                    rows={5}
                                    cols={30}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" loadingIcon={loadingIcon || ''} label="Update" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default EditaggregatorConfigChecker;

import * as Yup from 'yup';

const FixedCommissionInitialValues = {
    commissionProfileName: '',
    accountClassificationId: '',
    commissionGlAccountId: '',
    disbursementMethodName: '',
    disbursementTime: '',
    whtGlAccountId: '',
    whtCalculationType: '',
    products: [],
    commissionAmount: '',
    feeTypeCode: 'FF',
    startDate: '',
    endDate: ''
};

const validationSchema = Yup.object().shape({
    commissionProfileName: Yup.string()
        // .test('valid-input', 'Pre and Post spaces are not allowed', value => {
        //     return value && /^[a-zA-Z0-9\s]*[a-zA-Z0-9]$/.test(value)
        // })
        .required('This field is required')
        .matches(/^[a-zA-Z0-9\s]*$/, 'Only alpha numeric characters are allowed')
        .max(100, 'Maximum 100 digits allowed'),
    accountClassificationId: Yup.mixed().required('This field is required'),
    commissionGlAccountId: Yup.mixed().required('This field is required'),
    disbursementMethodName: Yup.mixed().required('This field is required'),
    whtGlAccountId: Yup.mixed().required('This field is required'),
    whtCalculationType: Yup.mixed().required('This field is required'),
    commissionAmount: Yup.string()
        .required('This field is required')
        .test('Is positive?', 'The number must be greater than 0', (value) => value > 0)
        .nullable(),
    startDate: Yup.date().required('This field is required').nullable(),
    endDate: Yup.date().when('startDate', {
        is: (startDate) => !!startDate,
        then: Yup.date().required('End Date is required').min(Yup.ref('startDate'), 'End Date has to be more than Start Date')
    })
});

export { FixedCommissionInitialValues, validationSchema };

import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { baseURL, serverURL } from '../../../../Config';
import { Button } from 'primereact/button';

function ViewTargetBaseSelectionCampaignChecker({ activeIndex, handleStepClick, compaignIdData, campaignData }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    const [cnicFile, setCnicFile] = useState('');
    const [mSISDNFile, setMSISDNFile] = useState('');

    const [agentTypeLov, setAgentTypeLov] = useState([]);
    const [accountTypeLov, setAccountTypeLov] = useState([]);
    const [transactionsLov, setTransactionsLov] = useState([]);
    const [segmentLov, setSegmentLov] = useState([]);

    const [mSFilePathData, setMSFilePathData] = useState();
    const [cnicFilePathData, setCnicFilePathData] = useState();

    const [fileContent, setFileContent] = useState(null);
    const [fileCustomizedMobileContent, setFileCustomizedMobileContent] = useState(null);
    const [fileCustomizedCnicContent, setFileCustomizedCnicContent] = useState(null);

    ///////////////////////////////////////////////
    const [kfsFileError, setKfsFileError] = useState('');

    const dispatch = useDispatch();
    const msdRef = useRef(null);
    const cnicRef = useRef(null);

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const requestType = query.get('requestType');

    const getAgentTypeLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getdatalov/TYPE');

        const payload = resp?.payLoad || [];

        // Filter and set for code "C" Segments
        const filteredSegments = payload?.filter((item) => item.code === 'C');
        setSegmentLov(filteredSegments);

        const filteredOthers = payload?.filter((item) => item.code !== 'C');
        setAgentTypeLov(filteredOthers);
    };

    const getAccountTypeLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getAccountStatus/LKP_ACCOUNT_STATUS:*');
        setAccountTypeLov(res?.payLoad);
    };

    const getTransactionsLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setTransactionsLov(res?.payLoad);
    };

    useEffect(() => {
        getAgentTypeLov();
        getAccountTypeLov();
        getTransactionsLov();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (requestType === 'I') {
            if (campaignData) {
                formik.setFieldValue('tbsType', campaignData?.tbsType?.toString());

                if (campaignData?.tbsType === 'C') {
                    const agentCustomerTypeList = campaignData?.agentCustomerType || [];
                    const selectedAgentCustomerType = agentCustomerTypeList
                        .filter((item) => !item.includes(':C')) // Filter out items that do not contain ":C"
                        .map((item) => {
                            return { lovId: item };
                        });

                    const segmentType = agentCustomerTypeList
                        .filter((item) => item.includes(':C')) // Filter out items that contain ":C"
                        .map((item) => {
                            return { lovId: item };
                        });

                    const selectedAccountStatus = campaignData?.accountStatus?.map((item) => {
                        return { accountStatusId: item.toString() };
                    });

                    const selectedTbsTransaction = campaignData?.tbsTransaction?.map((item) => {
                        return { lovId: item.toString() };
                    });

                    formik.setFieldValue('agentCustomerTypeList', selectedAgentCustomerType);
                    formik.setFieldValue('segment', segmentType);
                    formik.setFieldValue('accountStatusList', selectedAccountStatus);
                    formik.setFieldValue('tbsTransactionTypeIds', selectedTbsTransaction);

                    formik.setFieldValue('registrationConsideration', campaignData?.tbsRegistrationDateConsideration?.toString());
                    if (campaignData?.tbsRegistrationStartDate != null) {
                        formik.setFieldValue('registrationStartDate', new Date(campaignData?.tbsRegistrationStartDate).toISOString().slice(0, 16));
                    }
                    if (campaignData?.tbsRegistrationDateConsideration != null) {
                        formik.setFieldValue('isRegistration', true);
                        formik.setFieldValue('registrationEndDate', new Date(campaignData?.tbsRegistrationEndDate).toISOString().slice(0, 16));
                    } else {
                        formik.setFieldValue('isRegistration', false);
                    }

                    formik.setFieldValue('activityConsideration', campaignData?.tbsLastActivityDateConsideration?.toString());
                    if (campaignData?.tbsLastActivityStartDate != null) {
                        formik.setFieldValue('activityStartDate', new Date(campaignData?.tbsLastActivityStartDate).toISOString().slice(0, 16));
                    }
                    if (campaignData?.tbsLastActivityDateConsideration !== null) {
                        formik.setFieldValue('isActivity', true);
                        if (campaignData?.tbsLastActivityEndDate !== null) {
                            formik.setFieldValue('activityEndDate', new Date(campaignData?.tbsLastActivityEndDate).toISOString().slice(0, 16));
                        }
                    } else {
                        formik.setFieldValue('isActivity', false);
                    }

                    // if (campaignData?.tbsTransaction.length > 0) {
                    //     formik.setFieldValue('isTransaction', true);
                    // } else {
                    //     formik.setFieldValue('isTransaction', false);
                    // }

                    if (campaignData?.tbsTransaction.length > 0) {
                        formik.setFieldValue('isTransaction', true);
                        /////////////////
                        formik.setFieldValue('transConsideration', campaignData?.tbsLastTransDateConsideration?.toString());
                        if (campaignData?.tbsLastTransStartDate != null) {
                            formik.setFieldValue('transStartDate', new Date(campaignData?.tbsLastTransStartDate).toISOString().slice(0, 16));
                        }
                        if (campaignData?.tbsLastTransDateConsideration !== null) {
                            formik.setFieldValue('transEndDate', new Date(campaignData?.tbsLastTransEndDate).toISOString().slice(0, 16));
                        }
                        /////////////////
                    } else {
                        formik.setFieldValue('isTransaction', false);
                    }

                    if (campaignData?.tbsExclMsisdnFilename) {
                        formik.setFieldValue('isExcludeNumber', true);
                        // formik.setFieldValue('mobileNoFile', campaignData?.tbsExclMsisdnFilename);
                        let fileName = '';
                        setFileCustomizedMobileContent(campaignData?.tbsExclMsisdnFilename);
                        if (campaignData?.tbsExclMsisdnFilename) {
                            const filePath = campaignData?.tbsExclMsisdnFilename;
                            // Use the split method to split the string by "/"
                            const parts = filePath.split('/');

                            // Get the last part of the array, which is the filename
                            fileName = parts[parts.length - 1];
                            const filedata = `${baseURL}${filePath}`;
                            const updatedPath = new File([filedata], fileName);
                            setMSFilePathData(updatedPath);
                        }
                        formik.setFieldValue('mobileNoFile', fileName);

                        const segmentTypeList = campaignData?.agentCustomerType || [];

                        const segmentType = segmentTypeList
                            .filter((item) => item.includes(':C')) // Filter out items that contain ":C"
                            .map((item) => {
                                return { lovId: item };
                            });
                        formik.setFieldValue('segment', segmentType);

                        /////////////////////////////////////////////////////
                    }

                    if (campaignData?.tbsExclCnicFilename) {
                        formik.setFieldValue('isExcludeCnic', true);
                        // formik.setFieldValue('mobileNoFile', campaignData?.tbsExclMsisdnFilename);
                        let fileName = '';
                        setFileCustomizedCnicContent(campaignData?.tbsExclCnicFilename);
                        if (campaignData?.tbsExclCnicFilename) {
                            const filePath = campaignData?.tbsExclCnicFilename;
                            // Use the split method to split the string by "/"
                            const parts = filePath.split('/');

                            // Get the last part of the array, which is the filename
                            fileName = parts[parts.length - 1];
                            const filedata = `${baseURL}${filePath}`;
                            const updatedPath = new File([filedata], fileName);
                            setCnicFilePathData(updatedPath);
                        }
                        formik.setFieldValue('cnicFile', fileName);
                        //////////////////////////////////////////////////////////////////////////
                    }

                    formik.setFieldValue('targetBaseCount', campaignData?.tbsCount?.toString());
                } else {
                    let fileName = '';
                    setFileContent(campaignData?.tbsWhitelistedFilename);
                    if (campaignData?.tbsWhitelistedFilename) {
                        const filePath = campaignData?.tbsWhitelistedFilename;
                        // Use the split method to split the string by "/"
                        const parts = filePath.split('/');

                        // Get the last part of the array, which is the filename
                        fileName = parts[parts.length - 1];
                        const filedata = `${baseURL}${filePath}`;
                        const updatedPath = new File([filedata], fileName);
                        setMSFilePathData(updatedPath);
                    }
                    formik.setFieldValue('mobileNoFile', fileName);

                    const segmentTypeList = campaignData?.agentCustomerType || [];

                    const segmentType = segmentTypeList
                        .filter((item) => item.includes(':C')) // Filter out items that contain ":C"
                        .map((item) => {
                            return { lovId: item };
                        });
                    formik.setFieldValue('segment', segmentType);
                }
            }
        } else if (requestType === 'U') {
            if (campaignData) {
                formik.setFieldValue('tbsType', campaignData[1]?.tbsType?.toString());

                if (campaignData[1]?.tbsType === 'C') {
                    const agentCustomerTypeList = campaignData[1]?.agentCustomerTypeList || [];
                    const selectedAgentCustomerType = agentCustomerTypeList
                        .filter((item) => !item.includes(':C')) // Filter out items that do not contain ":C"
                        .map((item) => {
                            return { lovId: item };
                        });

                    const segmentType = agentCustomerTypeList
                        .filter((item) => item.includes(':C')) // Filter out items that contain ":C"
                        .map((item) => {
                            return { lovId: item };
                        });

                    const selectedAccountStatus = campaignData[1]?.accountStatusList?.map((item) => {
                        return { accountStatusId: item.toString() };
                    });

                    const selectedTbsTransaction = campaignData[1]?.tbsTransactionTypeIds?.map((item) => {
                        return { lovId: item.toString() };
                    });

                    formik.setFieldValue('agentCustomerTypeList', selectedAgentCustomerType);
                    formik.setFieldValue('segment', segmentType);
                    formik.setFieldValue('accountStatusList', selectedAccountStatus);
                    formik.setFieldValue('tbsTransactionTypeIds', selectedTbsTransaction);

                    formik.setFieldValue('registrationConsideration', campaignData[1]?.registrationConsideration?.toString());
                    // Function to convert and format the date
                    function formatDateString(dateString) {
                        const [datePart, timePart] = dateString?.split(', '); // Separate date and time parts
                        const [day, month, year] = datePart?.split('-').map(Number);
                        const [time, ampm] = timePart?.split(' ');
                        let [hours, minutes] = time?.split(':').map(Number);

                        // Adjust hours for PM
                        if (ampm === 'PM' && hours < 12) {
                            hours += 12;
                        }

                        // Format the date as ISO 8601 string
                        return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
                    }

                    if (campaignData[1]?.registrationStartDate !== '') {
                        const registrationStartDateFormat = formatDateString(campaignData[1]?.registrationStartDate);
                        formik.setFieldValue('registrationStartDate', registrationStartDateFormat);
                    }

                    if (campaignData[1]?.tbsRegistrationEndDate && campaignData[1]?.registrationConsideration === 'D') {
                        formik.setFieldValue('isRegistration', campaignData[1]?.isRegistration);
                        if (campaignData[1]?.registrationEndDate !== '') {
                            const registrationEndDateFormat = formatDateString(campaignData[1]?.registrationEndDate);
                            formik.setFieldValue('registrationEndDate', registrationEndDateFormat);
                        }
                    } else {
                        formik.setFieldValue('isRegistration', campaignData[1]?.isRegistration);
                    }

                    formik.setFieldValue('activityConsideration', campaignData[1]?.activityConsideration?.toString());
                    if (campaignData[1]?.activityStartDate !== '') {
                        const activityStartDateFormat = formatDateString(campaignData[1]?.activityStartDate);
                        formik.setFieldValue('activityStartDate', activityStartDateFormat);
                    }
                    if (campaignData[1]?.tbsRegistrationEndDate && campaignData[1]?.activityConsideration === 'D') {
                        formik.setFieldValue('isActivity', campaignData[1]?.isActivity);
                        if (campaignData[1]?.activityEndDate !== '') {
                            const activityEndDateFormat = formatDateString(campaignData[1]?.activityEndDate);
                            formik.setFieldValue('activityEndDate', activityEndDateFormat);
                        }
                    } else {
                        formik.setFieldValue('isActivity', campaignData[1]?.isActivity);
                    }

                    if (campaignData[1]?.tbsTransactionTypeIds.length > 0) {
                        formik.setFieldValue('isTransaction', campaignData[1]?.isTransaction);
                        /////////////////
                        formik.setFieldValue('transConsideration', campaignData[1]?.transConsideration?.toString());
                        if (campaignData[1]?.transStartDate !== '') {
                            const transStartDateFormat = formatDateString(campaignData[1]?.transStartDate);
                            formik.setFieldValue('transStartDate', transStartDateFormat);
                        }
                        if (campaignData[1]?.transConsideration === 'D') {
                            formik.setFieldValue('isTransaction', campaignData[1]?.isTransaction);
                            if (campaignData[1]?.transEndDate !== '') {
                                const transEndDateFormat = formatDateString(campaignData[1]?.transEndDate);
                                formik.setFieldValue('transEndDate', transEndDateFormat);
                            }
                        } else {
                            formik.setFieldValue('isTransaction', campaignData[1]?.isTransaction);
                        }
                        ////////////////
                    } else {
                        formik.setFieldValue('isTransaction', campaignData[1]?.isTransaction);
                    }

                    if (campaignData[1]?.mobileNoBase64) {
                        formik.setFieldValue('isExcludeNumber', campaignData[1]?.excludeNumber);
                        // formik.setFieldValue('mobileNoFile', campaignData?.mobileNoBase64);
                        let fileName = '';
                        setFileCustomizedMobileContent(campaignData[1]?.mobileNoBase64);
                        if (campaignData[1]?.mobileNoBase64) {
                            const filePath = campaignData[1]?.mobileNoBase64;
                            // Use the split method to split the string by "/"
                            const parts = filePath.split('/');

                            // Get the last part of the array, which is the filename
                            fileName = parts[parts.length - 1];
                            const filedata = `${baseURL}${filePath}`;
                            const updatedPath = new File([filedata], fileName);
                            setMSFilePathData(updatedPath);
                        }
                        formik.setFieldValue('mobileNoFile', fileName);

                        /////////////////////////////////////////////////////
                    } else {
                        if (campaignData[1]?.excludeNumber) formik.setFieldValue('mobileNoFile', 'file.csv');
                    }

                    if (campaignData[1]?.cnicBase64) {
                        formik.setFieldValue('isExcludeCnic', campaignData[1]?.excludeCnic);
                        // formik.setFieldValue('mobileNoFile', campaignData[1]?.tbsExclMsisdnFilename);
                        let fileName = '';
                        setFileCustomizedCnicContent(campaignData[1]?.cnicBase64);
                        if (campaignData[1]?.cnicBase64) {
                            const filePath = campaignData[1]?.cnicBase64;
                            // Use the split method to split the string by "/"
                            const parts = filePath.split('/');

                            // Get the last part of the array, which is the filename
                            fileName = parts[parts.length - 1];
                            const filedata = `${baseURL}${filePath}`;
                            const updatedPath = new File([filedata], fileName);
                            setCnicFilePathData(updatedPath);
                        }
                        formik.setFieldValue('cnicFile', fileName);
                        //////////////////////////////////////////////////////////////////////////
                    } else {
                        if (campaignData[1]?.excludeCnic) formik.setFieldValue('cnicFile', 'file.csv');
                    }

                    formik.setFieldValue('targetBaseCount', campaignData[1]?.targetBaseCount?.toString());
                } else {
                    let fileName = '';
                    setFileContent(campaignData[1]?.mobileNoBase64);
                    if (campaignData[1]?.mobileNoBase64) {
                        const filePath = campaignData[1]?.mobileNoBase64;
                        // Use the split method to split the string by "/"
                        const parts = filePath.split('/');

                        // Get the last part of the array, which is the filename
                        fileName = parts[parts.length - 1];
                        const filedata = `${baseURL}${filePath}`;
                        const updatedPath = new File([filedata], fileName);
                        setMSFilePathData(updatedPath);
                    }
                    formik.setFieldValue('mobileNoFile', fileName);

                    const segmentTypeList = campaignData[1]?.agentCustomerTypeList || [];

                    const segmentType = segmentTypeList
                        .filter((item) => item.includes(':C')) // Filter out items that contain ":C"
                        .map((item) => {
                            return { lovId: item };
                        });
                    formik.setFieldValue('segment', segmentType);
                }
            }
            // if(campaignData) {
            //     formik.setFieldValue('tbsType', campaignData[1]?.tbsType?.toString());

            //     if(campaignData[1]?.tbsType === "C") {
            //         const selectedAgentCustomerType = campaignData[1]?.agentCustomerTypeList?.map(item => {
            //             return { lovId: item };
            //         });

            //         const selectedAccountStatus = campaignData[1]?.accountStatusList?.map(item => {
            //             return { accountStatusId: item.toString() };
            //         });

            //         const selectedTbsTransaction = campaignData[1]?.tbsTransactionTypeIds?.map(item => {
            //             return { lovId: item.toString() };
            //         });

            //         formik.setFieldValue('agentCustomerTypeList', selectedAgentCustomerType);
            //         formik.setFieldValue('accountStatusList', selectedAccountStatus);
            //         formik.setFieldValue('tbsTransactionTypeIds', selectedTbsTransaction);

            //         formik.setFieldValue('registrationConsideration', campaignData[1]?.registrationConsideration?.toString());
            //         // Function to convert and format the date
            //         function formatDateString(dateString) {
            //             const [datePart, timePart] = dateString.split(', '); // Separate date and time parts
            //             const [day, month, year] = datePart.split('-').map(Number);
            //             const [time, ampm] = timePart.split(' ');
            //             let [hours, minutes] = time.split(':').map(Number);

            //             // Adjust hours for PM
            //             if (ampm === 'PM' && hours < 12) {
            //             hours += 12;
            //             }

            //             // Format the date as ISO 8601 string
            //             return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
            //         }

            //         const registrationStartDateFormat = formatDateString(campaignData[1]?.registrationStartDate);
            //         formik.setFieldValue('registrationStartDate', registrationStartDateFormat);

            //         if(campaignData[1]?.tbsRegistrationEndDate && campaignData[1]?.registrationConsideration === "D") {
            //             formik.setFieldValue('isRegistration', campaignData[1]?.isRegistration);
            //             const registrationEndDateFormat = formatDateString(campaignData[1]?.registrationEndDate);
            //             formik.setFieldValue('registrationEndDate', registrationEndDateFormat);
            //         } else {
            //             formik.setFieldValue('isRegistration', campaignData[1]?.isRegistration);
            //         }

            //         formik.setFieldValue('activityConsideration', campaignData[1]?.activityConsideration?.toString());
            //         const activityStartDateFormat = formatDateString(campaignData[1]?.activityStartDate);
            //         formik.setFieldValue('activityStartDate', activityStartDateFormat);
            //         if(campaignData[1]?.tbsRegistrationEndDate && campaignData[1]?.activityConsideration === "D") {
            //             formik.setFieldValue('isActivity', campaignData[1]?.isActivity);
            //             const activityEndDateFormat = formatDateString(campaignData[1]?.activityEndDate);
            //             formik.setFieldValue('activityEndDate', activityEndDateFormat);
            //         } else {
            //             formik.setFieldValue('isActivity', campaignData[1]?.isActivity);
            //         }

            //         if(campaignData[1]?.tbsTransactionTypeIds.length > 0) {
            //             formik.setFieldValue('isTransaction', true);
            //         } else {
            //             formik.setFieldValue('isTransaction', false);
            //         }

            //         if(campaignData[1]?.tbsExclMsisdnFilename) {
            //             formik.setFieldValue('isExcludeNumber', true);
            //             formik.setFieldValue('mobileNoFile', campaignData[1]?.tbsExclMsisdnFilename);
            //         }

            //         if(campaignData[1]?.tbsExclCnicFilename) {
            //             formik.setFieldValue('isExcludeCnic', true);
            //             formik.setFieldValue('mobileNoFile', campaignData[1]?.tbsExclMsisdnFilename);
            //         }

            //         formik.setFieldValue('targetBaseCount', campaignData[1]?.targetBaseCount);
            //     } else {
            //         formik.setFieldValue('mobileNoFile', campaignData[1]?.tbsWhitelistedFilename);
            //     }
            // }
        }
    }, []);

    const validationSchema = Yup.object().shape({
        tbsType: Yup.mixed().required('This field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            mobileNoFile: '',
            tbsType: 'W',
            agentCustomerTypeList: [],
            accountStatusList: [],
            segment: [],

            isRegistration: false,
            registrationConsideration: '',
            registrationStartDate: '',
            registrationEndDate: '',

            isActivity: false,
            activityConsideration: '',
            activityStartDate: '',
            activityEndDate: '',

            isTransaction: false,
            tbsTransactionTypeIds: [],

            isExcludeNumber: false,
            isExcludeCnic: false,
            targetBaseCount: ''
        },

        // validate: (data) => {
        //     let errors = {};
        //     return errors;
        // },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['campaignId'] = compaignIdData;
            data['stepNo'] = '1';

            const arrayOfAgentCustomerTypeIds = formik?.values?.agentCustomerTypeList.map((item) => item?.lovId);
            data['agentCustomerTypeList'] = arrayOfAgentCustomerTypeIds;

            const arrayOfTransactionIds = formik?.values?.tbsTransactionTypeIds.map((item) => parseInt(item?.lovId, 10));
            data['tbsTransactionTypeIds'] = arrayOfTransactionIds;

            const arrayOfAccountStatusIds = formik?.values?.accountStatusList.map((item) => parseInt(item?.accountStatusId, 10));
            data['accountStatusList'] = arrayOfAccountStatusIds;

            if (data?.registrationStartDate) {
                data['registrationStartDate'] = formatDate(data?.registrationStartDate);
                data['registrationEndDate'] = formatDate(data?.registrationEndDate);
            }

            if (data?.activityStartDate) {
                data['activityStartDate'] = formatDate(data?.activityStartDate);
                data['activityEndDate'] = formatDate(data?.activityEndDate);
            }

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            if (compaignIdData === 'undefined') {
                toast.warn('Please Add Properly from Step 1 CampaignId is Undefined');
            } else {
                if (data?.tbsType === 'W') {
                    delete data['tbsType'];
                    delete data['mobileNoFile'];

                    const formData = new FormData();
                    formData.append('request', JSON.stringify(newData));
                    formData.append('mobileNoFile', mSISDNFile);

                    if (parseInt(campaignData?.currentStep) >= 1) {
                        const res = await dispatch(handlePostRequest(formData, '/customerloyalitypromotions/v1/customerloyalitypromotion/editwhitelistedcustomer', true, false));
                        if (res?.responseCode === '150000') {
                            formik.resetForm();
                            handleStepClick(activeIndex + 1);
                        }
                    } else {
                        const res = await dispatch(handlePostRequest(formData, '/customerloyalitypromotions/v1/customerloyalitypromotion/whitelistedcustomer', true, false));
                        if (res?.responseCode === '150000') {
                            formik.resetForm();
                            handleStepClick(activeIndex + 1);
                        }
                    }

                    setloading(false);
                    setloadingIcon('pi pi-search');
                } else {
                    delete data['mobileNoFile'];
                    delete data['cnicFile'];

                    const formData = new FormData();
                    formData.append('request', JSON.stringify(newData));
                    formData.append('cnicFile', cnicFile);
                    formData.append('mobileNoFile', mSISDNFile);

                    if (parseInt(campaignData?.currentStep) >= 1) {
                        const res = await dispatch(handlePostRequest(formData, '/customerloyalitypromotions/v1/customerloyalitypromotion/editcampaign', true, false));
                        if (res?.responseCode === '150000') {
                            formik.resetForm();
                            handleStepClick(activeIndex + 1);
                        }
                    } else {
                        const res = await dispatch(handlePostRequest(formData, '/customerloyalitypromotions/v1/customerloyalitypromotion/savecampaign', true, false));
                        if (res?.responseCode === '150000') {
                            formik.resetForm();
                            handleStepClick(activeIndex + 1);
                        }
                    }

                    setloading(false);
                    setloadingIcon('pi pi-search');
                }
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const tbsTypeLov = [
        {
            name: 'Whitelisted',
            lovId: 'W'
        },
        {
            name: 'Customized',
            lovId: 'C'
        }
    ];

    const handleFileChangeForMSISDN = (event) => {
        const file = event.target.files[0];
        formik.setFieldValue('mobileNoFile', file);
        setMSISDNFile(file);
    };

    const handleFileChangeForCnic = (event) => {
        const file = event.target.files[0];
        formik.setFieldValue('cnicFile', file);
        setCnicFile(file);
    };

    const panelFooterTemplate = () => {
        const length = formik?.values?.tblAgentCustomerType ? formik?.values?.tblAgentCustomerType.length : 0;

        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    const dateOfConsideration = [
        {
            name: 'Before',
            lovId: 'B'
        },
        {
            name: 'After',
            lovId: 'A'
        },
        {
            name: 'During',
            lovId: 'D'
        }
    ];

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours from 24-hour to 12-hour format
        const formattedHours = hours % 12 || 12;

        const formattedDate = `${day}-${month}-${year}, ${formattedHours}:${minutes} ${ampm}`;
        return formattedDate;
    };

    const handleMSDINInput = () => {
        msdRef.current.click();
    };

    const handleCnicInput = () => {
        cnicRef.current.click();
    };

    const msisdnChange = (event) => {
        const file = event.target.files[0];
        formik.setFieldValue('mobileNoFile', file?.name);
        setMSISDNFile(file);
    };

    const cnicChange = (event) => {
        const file = event.target.files[0];
        formik.setFieldValue('cnicFile', file?.name);
        setCnicFile(file);
    };

    const handleViewWhiteListClick = async () => {
        const newFile = fileContent?.slice('34');
        const filePath = `${baseURL}/document/${newFile}`;
        window.open(filePath, '_blank');
    };

    const handleViewCustomizedMobileListClick = async () => {
        const newFile = fileCustomizedMobileContent?.slice('34');
        const filePath = `${baseURL}/document/${newFile}`;
        window.open(filePath, '_blank');
    };

    const handleViewCustomizedCnicListClick = async () => {
        const newFile = fileCustomizedCnicContent?.slice('34');
        const filePath = `${baseURL}/document/${newFile}`;
        window.open(filePath, '_blank');
    };

    const panelSegmentsFooterTemplate = () => {
        const length = formik?.values?.segment ? formik?.values?.segment.length : 0;

        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>View Target Base Selection</h1>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="tbsType" className={classNames({ 'p-error': isFormFieldValid('tbsType') }, 'Label__Text')}>
                                    Selection Type
                                </label>
                                <Dropdown
                                    id="tbsType"
                                    placeholder="Select Limit Duration"
                                    options={tbsTypeLov}
                                    optionLabel="name"
                                    name="tbsType"
                                    optionValue="lovId"
                                    disabled={true}
                                    value={formik?.values?.tbsType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('tbsType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('tbsType')}
                            </div>
                        </div>
                        {formik?.values?.tbsType === 'W' && (
                            <>
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <div className="mb-2">
                                            <label htmlFor="mobileNoFile" className={'Label__Text'}>
                                                Upload List Of MSISDN{' '}
                                                <a download={true} href={`${serverURL}/document/mobile.csv`} target="_blank" className="Download__Link">
                                                    - DownLoad Sample File
                                                </a>
                                            </label>
                                            <div className="file-input-container w-100" onClick={handleMSDINInput}>
                                                <input type="file" accept=".csv" style={{ display: 'none' }} ref={msdRef} onChange={msisdnChange} disabled={true} />
                                                <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={formik?.values?.mobileNoFile || ''} name="mobileNoFile" readOnly />
                                                <span className="file-upload-icon">
                                                    <i className="pi pi-paperclip" />
                                                </span>
                                            </div>
                                            {kfsFileError ? <small className="p-error">{kfsFileError}</small> : getFormErrorMessage('kfsInput')}
                                        </div>
                                        <Button disabled={fileContent === null || fileContent === '' ? true : false} type="button" label="View File" className="Btn__Dark" target="_blank" onClick={() => handleViewWhiteListClick()} />
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="segment" className={classNames({ 'p-error': isFormFieldValid('segment') }, 'Label__Text')}>
                                            Segment
                                            {/* <span className="Label__Required">*</span> */}
                                        </label>
                                        <MultiSelect
                                            id="segment"
                                            placeholder="Select Segment"
                                            options={segmentLov}
                                            optionLabel="name"
                                            name="segment"
                                            optionValue="lovId"
                                            filter
                                            readOnly
                                            panelFooterTemplate={panelSegmentsFooterTemplate}
                                            value={formik?.values?.segment?.map((segmentType) => segmentType?.lovId || [])}
                                            // onChange={(e) => {
                                            //     formik.setFieldValue(
                                            //         'segment',
                                            //         e?.value?.map((lovId) => ({
                                            //             lovId: lovId
                                            //         }))
                                            //     );
                                            // }}
                                            className={classNames({ 'p-invalid': isFormFieldValid('segment') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('segment')}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {formik?.values?.tbsType === 'C' && (
                        <>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="agentCustomerTypeList" className={classNames({ 'p-error': isFormFieldValid('agentCustomerTypeList') }, 'Label__Text')}>
                                            Select Agent/Customer Type<span className="Label__Required">*</span>
                                        </label>
                                        <MultiSelect
                                            id="agentCustomerTypeList"
                                            placeholder="Select Agent/Customer Type"
                                            options={agentTypeLov}
                                            optionLabel="name"
                                            name="agentCustomerTypeList"
                                            optionValue="lovId"
                                            // display="chip"
                                            filter
                                            panelFooterTemplate={panelFooterTemplate}
                                            value={formik?.values?.agentCustomerTypeList.map((agentType) => agentType.lovId || '')}
                                            // onChange={(e) => {
                                            //     formik.setFieldValue(
                                            //         'agentCustomerTypeList',
                                            //         e?.value?.map((lovId) => ({
                                            //             lovId: lovId
                                            //         }))
                                            //     );
                                            // }}
                                            className={classNames({ 'p-invalid': isFormFieldValid('agentCustomerTypeList') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('agentCustomerTypeList')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="accountStatusList" className={classNames({ 'p-error': isFormFieldValid('accountStatusList') }, 'Label__Text')}>
                                            Select Account Status<span className="Label__Required">*</span>
                                        </label>
                                        <MultiSelect
                                            id="accountStatusList"
                                            placeholder="Select Account Type"
                                            options={accountTypeLov}
                                            optionLabel="accountStatusName"
                                            name="accountStatusList"
                                            optionValue="accountStatusId"
                                            // display="chip"
                                            filter
                                            panelFooterTemplate={panelFooterTemplate}
                                            value={formik?.values?.accountStatusList.map((agentType) => agentType.accountStatusId || '')}
                                            // onChange={(e) => {
                                            //     formik.setFieldValue(
                                            //         'accountStatusList',
                                            //         e.value.map((accountStatusId) => ({
                                            //             accountStatusId
                                            //         }))
                                            //     );
                                            // }}
                                            className={classNames({ 'p-invalid': isFormFieldValid('accountStatusList') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('accountStatusList')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="segment" className={classNames({ 'p-error': isFormFieldValid('segment') }, 'Label__Text')}>
                                            Segment
                                            {/* <span className="Label__Required">*</span> */}
                                        </label>
                                        <MultiSelect
                                            id="segment"
                                            placeholder="Select Segment"
                                            options={segmentLov}
                                            optionLabel="name"
                                            name="segment"
                                            optionValue="lovId"
                                            filter
                                            panelFooterTemplate={panelSegmentsFooterTemplate}
                                            value={formik?.values?.segment?.map((segmentType) => segmentType?.lovId || [])}
                                            // onChange={(e) => {
                                            //     formik.setFieldValue(
                                            //         'segment',
                                            //         e?.value?.map((lovId) => ({
                                            //             lovId: lovId
                                            //         }))
                                            //     );
                                            // }}
                                            className={classNames({ 'p-invalid': isFormFieldValid('segment') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('segment')}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {formik?.values?.tbsType === 'C' && (
                        <>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-3 pt-3 flex align-items-end">
                                    <div className="p-field">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="isRegistration"
                                                name="isRegistration"
                                                disabled
                                                value={formik?.values?.isRegistration}
                                                checked={formik?.values?.isRegistration}
                                                onChange={formik.handleChange}
                                                style={{ marginTop: '10px' }}
                                            />
                                            <label htmlFor="isRegistration" className={classNames({ 'p-error': isFormFieldValid('isRegistration') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                                Registration Date
                                            </label>
                                        </div>
                                        {getFormErrorMessage('isRegistration')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-3">
                                    <div className="p-field">
                                        <label htmlFor="registrationConsideration" className={classNames({ 'p-error': isFormFieldValid('registrationConsideration') }, 'Label__Text')}>
                                            Date of Consideration
                                        </label>
                                        <Dropdown
                                            id="registrationConsideration"
                                            placeholder="Select Date of Consideration"
                                            options={dateOfConsideration}
                                            optionLabel="name"
                                            name="registrationConsideration"
                                            optionValue="lovId"
                                            disabled={formik?.values?.isRegistration === true ? true : true}
                                            value={formik?.values?.registrationConsideration || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('registrationConsideration') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('registrationConsideration')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="registrationStartDate" className={classNames({ 'p-error': isFormFieldValid('registrationStartDate') }, 'Label__Text')}>
                                            {formik?.values?.isRegistration && formik?.values?.registrationConsideration === 'D' ? 'Start Date' : 'Date'}
                                        </label>
                                        <InputText
                                            id="registrationStartDate"
                                            placeholder="Enter Date"
                                            name="registrationStartDate"
                                            type="datetime-local"
                                            disabled={formik?.values?.isRegistration === true ? true : true}
                                            value={formik?.values?.registrationStartDate || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('registrationStartDate') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('registrationStartDate')}
                                    </div>
                                </div>
                                {formik?.values?.registrationConsideration === 'D' && formik?.values?.isRegistration && (
                                    <>
                                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="registrationEndDate" className={classNames({ 'p-error': isFormFieldValid('registrationEndDate') }, 'Label__Text')}>
                                                    End Date
                                                </label>
                                                <InputText
                                                    id="registrationEndDate"
                                                    placeholder="Enter End Date"
                                                    name="registrationEndDate"
                                                    // type="date"
                                                    type="datetime-local"
                                                    disabled={formik?.values?.isRegistration === true ? true : true}
                                                    value={formik?.values?.registrationEndDate || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('registrationEndDate') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('registrationEndDate')}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>

                            {/* /////////////////////// */}
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-3 pt-3 flex align-items-end">
                                    <div className="p-field">
                                        <div className="field-checkbox">
                                            <Checkbox inputId="isActivity" name="isActivity" value={formik?.values?.isActivity} checked={formik?.values?.isActivity} onChange={formik.handleChange} style={{ marginTop: '10px' }} disabled />
                                            <label htmlFor="isActivity" className={classNames({ 'p-error': isFormFieldValid('isActivity') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                                Last Activity Date
                                            </label>
                                        </div>
                                        {getFormErrorMessage('isActivity')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-3">
                                    <div className="p-field">
                                        <label htmlFor="activityConsideration" className={classNames({ 'p-error': isFormFieldValid('activityConsideration') }, 'Label__Text')}>
                                            Date of Consideration
                                        </label>
                                        <Dropdown
                                            id="activityConsideration"
                                            placeholder="Select Date of Consideration"
                                            options={dateOfConsideration}
                                            optionLabel="name"
                                            name="activityConsideration"
                                            optionValue="lovId"
                                            disabled={formik?.values?.isActivity === true ? true : true}
                                            value={formik?.values?.activityConsideration || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('activityConsideration') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('activityConsideration')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="activityStartDate" className={classNames({ 'p-error': isFormFieldValid('activityStartDate') }, 'Label__Text')}>
                                            {formik?.values?.isActivity && formik?.values?.activityConsideration === 'D' ? 'Start Date' : 'Date'}
                                        </label>
                                        <InputText
                                            id="activityStartDate"
                                            placeholder="Enter Date"
                                            name="activityStartDate"
                                            // type="date"
                                            type="datetime-local"
                                            disabled={formik?.values?.isActivity === true ? true : true}
                                            value={formik?.values?.activityStartDate || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('activityStartDate') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('activityStartDate')}
                                    </div>
                                </div>
                                {formik?.values?.activityConsideration === 'D' && formik?.values?.isActivity && (
                                    <>
                                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="activityEndDate" className={classNames({ 'p-error': isFormFieldValid('activityEndDate') }, 'Label__Text')}>
                                                    End Date
                                                </label>
                                                <InputText
                                                    id="activityEndDate"
                                                    placeholder="Enter Date"
                                                    name="activityEndDate"
                                                    // type="date"
                                                    type="datetime-local"
                                                    disabled={formik?.values?.isActivity === true ? true : true}
                                                    value={formik?.values?.activityEndDate || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('activityEndDate') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('activityEndDate')}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-3 pt-3 pb-3">
                                    <div className="p-field">
                                        <div className="field-checkbox">
                                            <Checkbox inputId="isTransaction" name="isTransaction" value={formik?.values?.isTransaction} checked={formik?.values?.isTransaction} onChange={formik.handleChange} style={{ marginTop: '10px' }} disabled />
                                            <label htmlFor="isTransaction" className={classNames({ 'p-error': isFormFieldValid('isTransaction') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                                Transaction Type
                                            </label>
                                        </div>
                                        {getFormErrorMessage('isTransaction')}
                                    </div>
                                </div>
                                {/* ///////////////////////////////// */}
                                <div className="p-field col-12 md:col-3 pt-3">
                                    <div className="p-field">
                                        <label htmlFor="transConsideration" className={classNames({ 'p-error': isFormFieldValid('transConsideration') }, 'Label__Text')}>
                                            Date of Consideration
                                        </label>
                                        <Dropdown
                                            id="transConsideration"
                                            placeholder="Select Date of Consideration"
                                            options={dateOfConsideration}
                                            optionLabel="name"
                                            name="transConsideration"
                                            optionValue="lovId"
                                            // disabled={formik?.values?.isActivity === true ? false : true}
                                            disabled={true}
                                            value={formik?.values?.transConsideration || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('transConsideration') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('transConsideration')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="transStartDate" className={classNames({ 'p-error': isFormFieldValid('transStartDate') }, 'Label__Text')}>
                                            {formik?.values?.isTransaction && formik?.values?.transConsideration === 'D' ? 'Start Date & Time' : 'Date & Time'}
                                        </label>
                                        <InputText
                                            id="transStartDate"
                                            placeholder="Enter Date"
                                            name="transStartDate"
                                            type="datetime-local"
                                            // min={formik.values.activityConsideration === 'A' ? getCurrentDateTime() : undefined}
                                            // max={formik.values.activityConsideration === 'B' ? getCurrentDateTime() : undefined}
                                            disabled={true}
                                            value={formik?.values?.transStartDate || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('transStartDate') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('transStartDate')}
                                    </div>
                                </div>
                                {formik?.values?.transConsideration === 'D' && formik?.values?.isTransaction && (
                                    <>
                                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="transEndDate" className={classNames({ 'p-error': isFormFieldValid('transEndDate') }, 'Label__Text')}>
                                                    End Date & Time
                                                </label>
                                                <InputText
                                                    id="transEndDate"
                                                    placeholder="Enter Date"
                                                    name="transEndDate"
                                                    type="datetime-local"
                                                    disabled={true}
                                                    value={formik?.values?.transEndDate || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('transEndDate') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('transEndDate')}
                                            </div>
                                        </div>
                                    </>
                                )}
                                {/* ///////////////////////////////// */}
                            </div>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="tbsTransactionTypeIds" className={classNames({ 'p-error': isFormFieldValid('tbsTransactionTypeIds') }, 'Label__Text')}>
                                            Select Transactions<span className="Label__Required">*</span>
                                        </label>
                                        <MultiSelect
                                            id="tbsTransactionTypeIds"
                                            placeholder="Select Transactions Type"
                                            options={transactionsLov}
                                            optionLabel="name"
                                            name="tbsTransactionTypeIds"
                                            optionValue="lovId"
                                            // display="chip"
                                            filter
                                            disabled={formik?.values?.isTransaction === true ? true : true}
                                            panelFooterTemplate={panelFooterTemplate}
                                            value={formik?.values?.tbsTransactionTypeIds.map((trans) => trans.lovId || '')}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    'tbsTransactionTypeIds',
                                                    e.value.map((lovId) => ({
                                                        lovId
                                                    }))
                                                );
                                            }}
                                            className={classNames({ 'p-invalid': isFormFieldValid('tbsTransactionTypeIds') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('tbsTransactionTypeIds')}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="mobileNoFile" className={classNames({ 'p-error': isFormFieldValid('mobileNoFile') }, 'Label__Text')}>
                                            Exclude MSISDN{' '}
                                            <a download={true} href={`${serverURL}/document/mobile.csv`} className="Download__Link">
                                                - DownLoad Sample File
                                            </a>
                                        </label>
                                        <div style={{ display: 'flex' }}>
                                            <Checkbox
                                                inputId="isExcludeNumber"
                                                name="isExcludeNumber"
                                                value={formik?.values?.isExcludeNumber}
                                                checked={formik?.values?.isExcludeNumber}
                                                onChange={formik.handleChange}
                                                disabled
                                                style={{ marginTop: '10px', marginRight: '20px' }}
                                            />
                                            <div className="p-field col-12 md:col-10">
                                                <div className="p-field">
                                                    <div className="mb-2">
                                                        {/* <label htmlFor="mobileNoFile" className={'Label__Text'}>
                                                            Upload List Of MSISDN{' '}
                                                            <a download={true} href={`${serverURL}/document/mobile.csv`}  target='_blank' className="Download__Link">
                                                                - DownLoad Sample File
                                                            </a>
                                                        </label> */}
                                                        <div className="file-input-container w-100" onClick={handleMSDINInput}>
                                                            <input type="file" accept=".csv" style={{ display: 'none' }} ref={msdRef} onChange={msisdnChange} disabled={true} />
                                                            <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={formik?.values?.mobileNoFile || ''} name="mobileNoFile" readOnly />
                                                            <span className="file-upload-icon">
                                                                <i className="pi pi-paperclip" />
                                                            </span>
                                                        </div>
                                                        {kfsFileError ? <small className="p-error">{kfsFileError}</small> : getFormErrorMessage('kfsInput')}
                                                    </div>
                                                    <Button
                                                        disabled={fileCustomizedMobileContent === null || fileCustomizedMobileContent === '' ? true : false}
                                                        type="button"
                                                        label="View File"
                                                        className="Btn__Dark"
                                                        target="_blank"
                                                        onClick={() => handleViewCustomizedMobileListClick()}
                                                    />
                                                </div>
                                            </div>
                                            {/* <InputText
                                                disabled={formik?.values?.isExcludeNumber && formik?.values?.isTransaction === true ? true : true}
                                                id="mobileNoFile"
                                                placeholder="Please Upload List"
                                                name="mobileNoFile"
                                                type="file"
                                                accept=".csv"
                                                // value={formik?.values?.mobileNoFile || ''}
                                                onChange={handleFileChangeForMSISDN}
                                                className={classNames({ 'p-invalid': isFormFieldValid('mobileNoFile') }, 'Input__Round')}
                                            />
                                            {getFormErrorMessage('mobileNoFile')} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="cnicFile" className={classNames({ 'p-error': isFormFieldValid('cnicFile') }, 'Label__Text')}>
                                            Exclude CNIC{' '}
                                            <a download={true} href={`${serverURL}/document/cnic.csv`} className="Download__Link">
                                                - DownLoad Sample File
                                            </a>
                                        </label>
                                        <div style={{ display: 'flex' }}>
                                            <Checkbox
                                                inputId="isExcludeCnic"
                                                name="isExcludeCnic"
                                                value={formik?.values?.isExcludeCnic}
                                                checked={formik?.values?.isExcludeCnic}
                                                onChange={formik.handleChange}
                                                disabled
                                                style={{ marginTop: '10px', marginRight: '20px' }}
                                            />
                                            <div className="p-field col-12 md:col-10">
                                                <div className="p-field">
                                                    <div className="mb-2">
                                                        {/* <label htmlFor="mobileNoFile" className={'Label__Text'}>
                                                            Upload List Of MSISDN{' '}
                                                            <a download={true} href={`${serverURL}/document/mobile.csv`}  target='_blank' className="Download__Link">
                                                                - DownLoad Sample File
                                                            </a>
                                                        </label> */}
                                                        <div className="file-input-container w-100" onClick={handleCnicInput}>
                                                            <input type="file" accept=".csv" style={{ display: 'none' }} ref={cnicRef} onChange={cnicChange} disabled={true} />
                                                            <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={formik?.values?.cnicFile || ''} name="cnicFile" readOnly />
                                                            <span className="file-upload-icon">
                                                                <i className="pi pi-paperclip" />
                                                            </span>
                                                        </div>
                                                        {kfsFileError ? <small className="p-error">{kfsFileError}</small> : getFormErrorMessage('kfsInput')}
                                                    </div>
                                                    <Button
                                                        disabled={fileCustomizedCnicContent === null || fileCustomizedCnicContent === '' ? true : false}
                                                        type="button"
                                                        label="View File"
                                                        className="Btn__Dark"
                                                        target="_blank"
                                                        onClick={() => handleViewCustomizedCnicListClick()}
                                                    />
                                                </div>
                                            </div>
                                            {/* <InputText
                                                disabled={formik?.values?.isExcludeCnic ? true : true}
                                                id="cnicFile"
                                                placeholder="Please Upload List"
                                                name="cnicFile"
                                                type="file"
                                                accept=".csv"
                                                // value={formik?.values?.cnicFile || ''}
                                                onChange={handleFileChangeForCnic}
                                                className={classNames({ 'p-invalid': isFormFieldValid('cnicFile') }, 'Input__Round')}
                                            />
                                            {getFormErrorMessage('cnicFile')} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="targetBaseCount" className={classNames({ 'p-error': isFormFieldValid('targetBaseCount') }, 'Label__Text')}>
                                            Target Base Count
                                        </label>
                                        <InputText
                                            id="targetBaseCount"
                                            placeholder="Enter Target Base Count"
                                            name="targetBaseCount"
                                            disabled={true}
                                            value={formik?.values?.targetBaseCount || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('targetBaseCount') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('targetBaseCount')}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </form>
            </div>
        </>
    );
}
export default ViewTargetBaseSelectionCampaignChecker;

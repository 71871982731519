import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { InputTextarea } from 'primereact/inputtextarea';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewPointRedemptionChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-save');
    const [transactionTypeData, setTransactionTypeData] = useState([]);

    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const tableName = query.get('tableName');
    const reqType = query.get('requestType');
    const mcReqId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');

    const navigate = useNavigate();

    const getTransactionTypeData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setTransactionTypeData(res?.payLoad);
    };

    const getDataById = async () => {
        const response = await handleGetRequest(`/zconfig/v1/zmiles/getVoucher/${refTableId}`);

        formik.setFieldValue('voucherValue', response?.payLoad?.voucherValue?.toString());
        formik.setFieldValue('voucherName', response?.payLoad?.voucherName?.toString());
        formik.setFieldValue('pointValue', response?.payLoad?.pointValue?.toString());

        formik.setFieldValue('expiry', response?.payLoad?.expiry);

        formik.setFieldValue('zmilesVoucherId', response?.payLoad?.zmilesVoucherId);

        const transTypeData = response?.payLoad?.tblZmilesVoucherDetails?.map((value) => {
            return value?.productId?.toString();
        });

        formik.setFieldValue('transType', transTypeData);
    };

    const getDataByIdUType = async () => {
        const response = await handleGetRequest(`/zconfig/v1/zmiles/getVoucherUpdateCheckerById/${mcReqId}`);

        formik.setFieldValue('voucherValue', response?.payLoad?.voucherValue?.toString());
        formik.setFieldValue('voucherName', response?.payLoad?.voucherName?.toString());
        formik.setFieldValue('pointValue', response?.payLoad?.pointValue?.toString());

        formik.setFieldValue('expiry', response?.payLoad?.expiry);

        formik.setFieldValue('zmilesVoucherId', response?.payLoad?.zmilesVoucherId);

        const transTypeData = response?.payLoad?.tblZmilesVoucherDetails?.map((value) => {
            return value?.productId?.toString();
        });

        formik.setFieldValue('transType', transTypeData);
    };

    useEffect(() => {
        getTransactionTypeData();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (reqType === 'I') {
            getDataById();
        } else if (reqType === 'U') {
            getDataByIdUType();
        }
    }, []); // eslint-disable-line

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            voucherName: '',
            voucherValue: '',
            pointValue: '',
            transType: [],
            expiry: '',
            isActive: '',
            action: '',
            checkerComments: '',
            zmilesVoucherId: null
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            data['appUserId'] = '1';

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        updateVoucherRequest: {
                            isActive: 'Y',
                            statusId: 0,
                            voucherValue: parseInt(data['voucherValue']),
                            voucherName: data['voucherName'],
                            pointValue: data['pointValue'],
                            transType: data['transType'],
                            expiry: parseInt(data['expiry']),
                            zmilesVoucherId: data['zmilesVoucherId']
                        },
                        mcActionRequest: {
                            mcRequestId: mcReqId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(newData, `/zconfig/v1/zmiles/voucherCheckerAction`, true, false));
            if (res?.responseCode === '330000') {
                formik.resetForm();
                navigate('/requestchecker');
            }
            setloading(false);
            setloadingIcon('pi pi-save');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="voucherName" className={classNames({ 'p-error': isFormFieldValid('voucherName') }, 'Label__Text')}>
                                    Voucher Name
                                </label>
                                <InputText
                                    disabled
                                    id="voucherName"
                                    placeholder="Enter Voucher Name"
                                    name="voucherName"
                                    value={formik?.values?.voucherName}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('voucherName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('voucherName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="voucherValue" className={classNames({ 'p-error': isFormFieldValid('voucherValue') }, 'Label__Text')}>
                                    Voucher Value
                                </label>
                                <InputText
                                    disabled={true}
                                    id="voucherValue"
                                    placeholder="Enter Voucher Value"
                                    name="voucherValue"
                                    value={formik?.values?.voucherValue}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('voucherValue') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('voucherValue')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="pointValue" className={classNames({ 'p-error': isFormFieldValid('pointValue') }, 'Label__Text')}>
                                    Point Value
                                </label>
                                <InputText
                                    disabled
                                    id="pointValue"
                                    placeholder="Enter Point Value"
                                    name="pointValue"
                                    value={formik?.values?.pointValue}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pointValue') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('pointValue')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transType" className={classNames({ 'p-error': isFormFieldValid('transType') }, 'Label__Text')}>
                                    Transaction Type
                                </label>
                                <MultiSelect
                                    id="transType"
                                    placeholder="Select Transaction Type"
                                    options={transactionTypeData}
                                    optionLabel="name"
                                    name="transType"
                                    optionValue="lovId"
                                    value={formik.values.transType}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('transType')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expiry" className={classNames({ 'p-error': isFormFieldValid('expiry') }, 'Label__Text')}>
                                    Expiry
                                </label>
                                <InputText
                                    disabled
                                    id="expiry"
                                    placeholder="Enter Expiry"
                                    name="expiry"
                                    value={formik?.values?.expiry}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expiry') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('expiry')}
                            </div>
                        </div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    {/* <span className="Label__Required">*</span> */}
                                </label>
                                <InputTextarea
                                    maxLength={200}
                                    rows={5}
                                    cols={30}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Ok" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default ViewPointRedemptionChecker;

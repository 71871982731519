import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { MultiSelect } from 'primereact/multiselect';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import MobileView from '../mobileView';
import { baseURL } from '../../../../Config';
import { InputTextarea } from 'primereact/inputtextarea';

function EditBannerChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [productIconFileName, setProductIconFileName] = useState();
    const [visibleData, setVisibleData] = useState([]);
    const [mainData, setMainData] = useState([]);
    const [subData, setSubData] = useState([]);
    const [secSubData, setSecSubData] = useState([]);
    const [rankingData, setRankingData] = useState([]);
    const [targetAud, setTargetAud] = useState([]);
    const [segmentsData, setSegmentsData] = useState([]);
    const [prodLkp, seTProdLkp] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [iconLink, setIconLink] = useState();
    const [accountLevelData, setAccountLevelData] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const tableName = query.get('tableName');

    const frequencyData = [
        { lovId: 'A', name: 'All' },
        { lovId: 'C', name: 'Custom' }
    ];

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('This field is required'),
        checkerComments: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
            .required('This field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cmsBannerId: '',
            cmsBannerTypeId: '',
            name: '',
            cmsVisibleId: '',
            redirection: '',
            redirectionUrl: '',
            cmsProductId: '',
            cmsMainCategoryId: '',
            cmsSubCategoryId: '',
            cmsSecondSubCategoryId: '',
            text: '',
            description: '',
            image: '',
            addButton: '',
            noOfButtons: '',
            tblCmsBannerAccountLevels: [],
            frequency: '',
            startDate: '',
            endDate: '',
            ranking: '',
            isHide: 'Y',
            tblCmsBannerButtons: [],
            tblCmsBannerSegments: []
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            const addPayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        objectBody: {
                            cmsBannerId: formik?.values?.cmsBannerId,
                            isActive: requestType === 'I' ? (formik?.values?.action == '2' ? 'Y' : 'N') : formik?.values?.isActive
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: ''
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const updatePayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        objectBody: {
                            cmsBannerId: data?.cmsBannerId,
                            cmsBannerTypeId: data?.cmsBannerTypeId,
                            name: data?.name,
                            cmsVisibleId: data?.cmsVisibleId,
                            redirection: data?.redirection,
                            redirectionUrl: data?.redirectionUrl,
                            cmsProductId: data?.cmsProductId,
                            cmsMainCategoryId: data?.cmsMainCategoryId,
                            cmsSubCategoryId: data?.cmsSubCategoryId,
                            cmsSecondSubCategoryId: data?.cmsSecondSubCategoryId,
                            text: data?.text,
                            description: data?.description,
                            image: data?.image,
                            addButton: data?.addButton,
                            noOfButtons: data?.noOfButtons,
                            tblCmsBannerAccountLevels: accountLevelData,
                            frequency: data?.frequency,
                            startDate: data?.startDate,
                            endDate: data?.endDate,
                            ranking: data?.ranking,
                            isHide: data?.isHide,
                            tblCmsBannerSegments: data?.tblCmsBannerSegments,
                            isActive: data?.isActive
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: ''
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const formData = new FormData();
            if (requestType === 'I') {
                formData.append('request', JSON.stringify(addPayload));
            } else if (requestType === 'U') {
                formData.append('request', JSON.stringify(updatePayload));
            }
            await dispatch(handlePostRequest(formData, '/cms/v1/cms/bannervalidatorcheckeraction', true, false, 'requestchecker'));

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };

    const getVisibleData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_VISIBLE');
        setVisibleData(resp?.payLoad);
    };

    const getCategoryDataLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcategorydatalov');
        setMainData(res?.payLoad);
    };

    const getSubCategoryDataLov = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getsubcategorydatalov/${formik?.values?.cmsMainCategoryId}`);
        setSubData(res?.payLoad);
    };

    const getSecSubCategoryDataLov = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getsecondsubcategorydatalov/${formik.values.cmsMainCategoryId}/${formik.values.cmsSubCategoryId}`);
        setSecSubData(res?.payLoad);
    };

    const getTargetAud = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getaccounttypes/LKP_ACCOUNT_LEVEL:*');
        setTargetAud(res?.payLoad);
    };

    const getSegmentsData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getsegments/LKP_SEGMENT:*');
        const updated = res?.payLoad?.map((item) => ({
            ...item,
            segmentId: item.lovId
        }));
        setSegmentsData(updated);
    };

    const geTProdLkp = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_PRODUCT');
        seTProdLkp(res?.payLoad);
    };

    const getRankingData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_RANKING');
        setRankingData(res?.payLoad);
    };
    useEffect(() => {
        getRankingData();
        getStatusLov();
        getSegmentsData();
        geTProdLkp();
        getVisibleData();
        getCategoryDataLov();
        if (formik?.values?.cmsMainCategoryId != '') {
            getSubCategoryDataLov();
        }
        if (formik?.values?.cmsSubCategoryId != '' && formik?.values?.cmsMainCategoryId != '') {
            getSecSubCategoryDataLov();
        }
        getTargetAud();
    }, [formik.values.cmsMainCategoryId, formik.values.cmsSubCategoryId]);

    const getMainCategoryData = async () => {
        setloading(true);
        const res = await handleGetRequest(`/cms/v1/cms/getbannerbyid/${refTableId}`);
        const keyData = res?.payLoad;
        if (keyData) {
            formik.setFieldValue('name', keyData?.name || '');
            formik.setFieldValue('cmsBannerId', keyData?.cmsBannerId || '');
            formik.setFieldValue('cmsBannerTypeId', keyData?.cmsBannerTypeId || '');
            formik.setFieldValue('cmsVisibleId', keyData?.cmsVisibleId?.toString() || '');
            formik.setFieldValue('redirection', keyData?.redirection);
            formik.setFieldValue('cmsProductId', keyData?.cmsProductId?.toString() || '');
            formik.setFieldValue('cmsMainCategoryId', keyData?.cmsMainCategoryId?.toString() || '');
            formik.setFieldValue('cmsSubCategoryId', keyData?.cmsSubCategoryId?.toString() || '');
            formik.setFieldValue('cmsSecondSubCategoryId', keyData?.cmsSecondSubCategoryId?.toString() || '');
            formik.setFieldValue('themeIcon', keyData.themeIcon);
            formik.setFieldValue('isActive', keyData?.isActive);
            formik.setFieldValue('text', keyData?.text || '');
            formik.setFieldValue('image', keyData?.image || '');
            formik.setFieldValue('addButton', keyData?.addButton || 'N');
            // formik.setFieldValue('cmsTargetAudienceId', keyData?.cmsTargetAudienceId?.toString() || '');
            const selectedSegments = keyData?.tblCmsBannerSegments || [];
            const initialSegments = selectedSegments?.map((segment) => ({
                segmentId: segment?.segmentId.toString(),
                cmsBannerSegmentId: segment?.cmsBannerSegmentId || 0
            }));
            const targetUser = keyData?.tblCmsBannerAccountLevels?.map((entry) => ({
                lovId: entry?.accountLevelId.toString(),
                cmsBannerAccountLevelId: entry?.cmsBannerAccountLevelId,
                isActive: entry?.isActive
            }));
            formik.setFieldValue('tblCmsBannerAccountLevels', targetUser || '');
            formik.setFieldValue('tblCmsBannerSegments', initialSegments || '');
            formik.setFieldValue('frequency', keyData?.frequency || '');
            const correctStartDate = (keyData?.startDate && formattedDate(keyData?.startDate)) || '';
            formik.setFieldValue('startDate', (correctStartDate && correctStartDate) || '');
            const correctEndDate = (keyData?.endDate && formattedDate(keyData?.endDate)) || '';
            formik.setFieldValue('endDate', (correctEndDate && correctEndDate) || '');
            formik.setFieldValue('isHide', keyData?.isHide || '');
            formik.setFieldValue('tblCmsBannerButtons', keyData?.tblCmsBannerButtons || '');
            // setIconLink(`${baseURL}/document/${keyData?.image?.substring(keyData?.image?.indexOf('/') + 1)}`);
            setIconLink(`${baseURL}/document/${keyData?.image?.split('/documents/')[1]}`);
            const pathParts = keyData?.image?.split('/');
            const bannerimage = pathParts[pathParts?.length - 1];
            setProductIconFileName(bannerimage);

            formik.setFieldValue('ranking', keyData?.ranking?.toString() || '');
        }
        setloading(false);
    };

    const getMcRequestDataById = async () => {
        setloading(true);
        const res = await handleGetRequest(`/cms/v1/cms/getmcrequestbyid/${mcRequestId}`);
        const unParsedkeyData = res?.payLoad?.updateJson;
        const keyData = JSON.parse(unParsedkeyData);

        if (keyData) {
            formik.setFieldValue('name', keyData?.name || '');
            formik.setFieldValue('cmsBannerId', keyData?.cmsBannerId || '');
            formik.setFieldValue('cmsBannerTypeId', keyData?.cmsBannerTypeId || '');
            formik.setFieldValue('cmsVisibleId', keyData?.cmsVisibleId?.toString() || '');
            formik.setFieldValue('redirection', keyData?.redirection);
            formik.setFieldValue('cmsProductId', keyData?.cmsProductId?.toString() || '');
            formik.setFieldValue('cmsMainCategoryId', keyData?.cmsMainCategoryId?.toString() || '');
            formik.setFieldValue('cmsSubCategoryId', keyData?.cmsSubCategoryId?.toString() || '');
            formik.setFieldValue('cmsSecondSubCategoryId', keyData?.cmsSecondSubCategoryId?.toString() || '');
            formik.setFieldValue('themeIcon', keyData.themeIcon);
            formik.setFieldValue('isActive', keyData?.isActive);
            formik.setFieldValue('text', keyData?.text || '');
            formik.setFieldValue('image', keyData?.image || '');
            formik.setFieldValue('addButton', keyData?.addButton || 'N');
            // formik.setFieldValue('cmsTargetAudienceId', keyData?.cmsTargetAudienceId?.toString() || '');
            setAccountLevelData(keyData?.tblCmsBannerAccountLevels)

            const selectedSegments = keyData?.tblCmsBannerSegments || [];
            const initialSegments = selectedSegments?.map((segment) => ({
                segmentId: segment?.segmentId.toString(),
                cmsBannerSegmentId: segment?.cmsBannerSegmentId || 0
            }));
            const targetUser = keyData?.tblCmsBannerAccountLevels?.map((entry) => ({
                lovId: entry?.accountLevelId.toString(),
                
            }));
            formik.setFieldValue('tblCmsBannerAccountLevels', targetUser || '');
            formik.setFieldValue('tblCmsBannerSegments', initialSegments || '');
            formik.setFieldValue('frequency', keyData?.frequency || '');
            const correctStartDate = (keyData?.startDate && formattedDate(keyData?.startDate)) || '';
            formik.setFieldValue('startDate', (correctStartDate && correctStartDate) || '');
            const correctEndDate = (keyData?.endDate && formattedDate(keyData?.endDate)) || '';
            formik.setFieldValue('endDate', (correctEndDate && correctEndDate) || '');
            formik.setFieldValue('isHide', keyData?.isHide || '');
            formik.setFieldValue('tblCmsBannerButtons', keyData?.tblCmsBannerButtons || '');
            // setIconLink(`${baseURL}/document/${keyData?.image?.substring(keyData?.image?.indexOf('/') + 1)}`);
            setIconLink(`${baseURL}/document/${keyData?.image?.split('/documents/')[1]}`);
            const pathParts = keyData?.image?.split('/');
            const bannerimage = pathParts[pathParts?.length - 1];
            setProductIconFileName(bannerimage);
            formik.setFieldValue('ranking', keyData?.ranking?.toString() || '');
        }
        setloading(false);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getMainCategoryData();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, [requestType]);

    function formattedDate(originalDate) {
        const createDate = new Date(originalDate);

        const year = createDate.getFullYear();
        const month = (createDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const day = createDate.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="Form__Header Full__Width">
                        <h2>EDIT DETAILS</h2>
                    </div>
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-fluid p-formgrid grid col-12 md:col-9 ">
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                        Name <span className="Label__Required">*</span>
                                    </label>
                                    <InputText maxLength={100} id="name" placeholder="Enter Name" name="name" value={formik?.values?.name || ''} disabled className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'Input__Round')} />
                                    {getFormErrorMessage('name')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsVisibleId" className={classNames({ 'p-error': isFormFieldValid('cmsVisibleId') }, 'Label__Text')}>
                                        Visible On<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="cmsVisibleId"
                                        placeholder="Select Visibility"
                                        options={visibleData}
                                        optionLabel="name"
                                        name="cmsVisibleId"
                                        optionValue="lovId"
                                        value={formik.values.cmsVisibleId || ''}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsVisibleId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('cmsVisibleId')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="redirection" className={classNames({ 'p-error': isFormFieldValid('redirection') }, 'Label__Text')}>
                                        Redirection
                                    </label>
                                    <div className="flex">
                                        <div className="mt-2 mr-5">
                                            <RadioButton inputId="applicable" disabled name="redirection" value="Y" checked={formik.values.redirection === 'Y'} />
                                            <label htmlFor="applicable" className={classNames({ 'p-error': isFormFieldValid('applicable') }, 'Label__Text')}>
                                                Yes
                                            </label>
                                        </div>
                                        <div className="mt-2">
                                            <RadioButton inputId="notApplicable" name="redirection" value="N" disabled checked={formik.values.redirection === 'N'} />
                                            <label htmlFor="notApplicable" className={classNames({ 'p-error': isFormFieldValid('notApplicable') }, 'Label__Text')}>
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsProductId" className={classNames({ 'p-error': isFormFieldValid('cmsProductId') }, 'Label__Text')}>
                                        Product
                                    </label>
                                    <Dropdown
                                        id="cmsProductId"
                                        placeholder="Select Product"
                                        options={prodLkp}
                                        optionLabel="name"
                                        name="cmsProductId"
                                        optionValue="lovId"
                                        value={formik.values.cmsProductId || ''}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsProductId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('cmsProductId')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsMainCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsMainCategoryId') }, 'Label__Text')}>
                                        Main Category
                                    </label>
                                    <Dropdown
                                        id="cmsMainCategoryId"
                                        placeholder="Select Main Category"
                                        options={mainData}
                                        optionLabel="name"
                                        name="cmsMainCategoryId"
                                        optionValue="lovId"
                                        value={formik.values.cmsMainCategoryId || ''}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsMainCategoryId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('cmsMainCategoryId')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsSubCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsSubCategoryId') }, 'Label__Text')}>
                                        Sub Category
                                    </label>
                                    <Dropdown
                                        id="cmsSubCategoryId"
                                        placeholder="Select Sub Category"
                                        options={subData}
                                        optionLabel="name"
                                        name="cmsSubCategoryId"
                                        optionValue="lovId"
                                        value={formik.values.cmsSubCategoryId || ''}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsSubCategoryId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('cmsSubCategoryId')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsSecondSubCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsSecondSubCategoryId') }, 'Label__Text')}>
                                        2nd Sub Category
                                    </label>
                                    <Dropdown
                                        id="cmsSecondSubCategoryId"
                                        placeholder="Select 2nd Sub Category"
                                        options={secSubData}
                                        optionLabel="name"
                                        name="cmsSecondSubCategoryId"
                                        optionValue="lovId"
                                        value={formik.values.cmsSecondSubCategoryId || ''}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsSecondSubCategoryId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('cmsSecondSubCategoryId')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="text" className={classNames({ 'p-error': isFormFieldValid('text') }, 'Label__Text')}>
                                        Add Text <span className="Label__Required">*</span>
                                    </label>
                                    <InputText maxLength={100} id="text" placeholder="Enter Add Text" name="text" value={formik?.values?.text || ''} disabled className={classNames({ 'p-invalid': isFormFieldValid('text') }, 'Input__Round')} />
                                    {getFormErrorMessage('text')}
                                </div>
                            </div>
                            {/* <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <div className="mb-2">
                                        <label className="Label__Text">Theme Icon</label>
                                        <div className="file-upload-container w-100 Input__Round_Upload flex file-input-field-upload flex justify-content-between">
                                            <input type="file" style={{ display: 'none' }} accept="image/*" />
                                            {productIconFileName ? productIconFileName : 'Choose a file...'}
                                            <button label="Choose File" className="Btn__Add__Upload border-round-md" iconPos="left" style={{ width: 'auto' }}>
                                                Choose File
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <div className="mb-2">
                                        <label className="Label__Text">
                                            Upload Image<span className="Label__Required">*</span>
                                        </label>
                                        <div className="file-input-container w-100">
                                            <input type="file" style={{ display: 'none' }} accept="image/*" />
                                            <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={productIconFileName} disabled readOnly />
                                            <span className="file-upload-icon">
                                                <i className="pi pi-paperclip" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="addButton" className={classNames({ 'p-error': isFormFieldValid('channelName') }, 'Label__Text')}>
                                        Add Button
                                    </label>
                                    <div className="flex">
                                        <div className="mt-2 mr-5">
                                            <RadioButton inputId="Yes" disabled name="addButton" value="Y" checked={formik.values.addButton === 'Y'} />
                                            <label htmlFor="yes" className={classNames({ 'p-error': isFormFieldValid('Yes') }, 'Label__Text')}>
                                                Yes
                                            </label>
                                        </div>
                                        <div className="mt-2">
                                            <RadioButton inputId="no" name="addButton" value="N" disabled checked={formik.values.addButton === 'N'} />
                                            <label htmlFor="no" className={classNames({ 'p-error': isFormFieldValid('no') }, 'Label__Text')}>
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field customMultiselect">
                                    <label htmlFor="tblCmsBannerAccountLevels" className={classNames({ 'p-error': isFormFieldValid('tblCmsBannerAccountLevels') }, 'Label__Text')}>
                                    Target Audience (Account Level)<span className="Label__Required">*</span>
                                    </label>
                                    <MultiSelect
                                        id="tblCmsBannerAccountLevels"
                                        placeholder="Select Target User Base"
                                        options={targetAud}
                                        optionLabel="name"
                                        display="chip"
                                        disabled
                                        name="tblCmsBannerAccountLevels"
                                        optionValue="lovId"
                                       
                                        value={formik?.values?.tblCmsBannerAccountLevels?.map((data) => data.lovId)}
                                        onChange={formik?.handleChange}

                                        className={classNames({ 'p-invalid': isFormFieldValid('tblCmsBannerAccountLevels') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('tblCmsBannerAccountLevels')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field customMultiselect">
                                    <label htmlFor="tblCmsBannerSegments" className={classNames({ 'p-error': isFormFieldValid('tblCmsBannerSegments') }, 'Label__Text')}>
                                        Segments<span className="Label__Required">*</span>
                                    </label>
                                    <MultiSelect
                                        id="tblCmsBannerSegments"
                                        placeholder="Select Segments"
                                        options={segmentsData}
                                        optionLabel="name"
                                        display="chip"
                                        disabled
                                        name="tblCmsBannerSegments"
                                        optionValue="lovId"
                                        value={formik?.values?.tblCmsBannerSegments.map((segment) => segment.segmentId || '')}
                                        className={classNames({ 'p-invalid': isFormFieldValid('tblCmsBannerSegments') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('tblCmsBannerSegments')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="frequency" className={classNames({ 'p-error': isFormFieldValid('frequency') }, 'Label__Text')}>
                                        Frequency
                                    </label>

                                    <Dropdown
                                        id="frequency"
                                        placeholder="Select Visibility"
                                        options={frequencyData}
                                        optionLabel="name"
                                        name="frequency"
                                        optionValue="lovId"
                                        value={formik.values.frequency || ''}
                                        onChange={formik.handleChange}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('frequency') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('frequency')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 flex align-items-end justify-content-start">
                                <div className="field-checkbox flex mb-2 p-field-addbanner-width">
                                    <div className="mr-5">
                                        <Checkbox inputId="show" name="isHide" value="Y" checked={formik?.values?.isHide === 'Y'} disabled style={{ marginTop: '10px' }} />
                                        <label htmlFor="show" className={classNames({ 'p-error': isFormFieldValid('enablePartialPayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                            Show
                                        </label>
                                    </div>
                                    <div>
                                        <Checkbox inputId="hide" name="isHide" value="N" checked={formik?.values?.isHide === 'N'} disabled style={{ marginTop: '10px' }} />
                                        <label htmlFor="hide" className={classNames({ 'p-error': isFormFieldValid('enablePartialPayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                            Hide
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') }, 'Label__Text')}>
                                        Start Date Time
                                    </label>
                                    <InputText id="startDate" type="date" name="startDate" value={formik?.values?.startDate} disabled className={classNames({ 'p-invalid': isFormFieldValid('startDate') }, 'Input__Round')} />

                                    {getFormErrorMessage('startDate')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') }, 'Label__Text')}>
                                        End Date Time
                                    </label>
                                    <InputText id="endDate" type="date" name="endDate" value={formik?.values?.endDate} disabled className={classNames({ 'p-invalid': isFormFieldValid('endDate') }, 'Input__Round')} />

                                    {getFormErrorMessage('endDate')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="ranking" className={classNames({ 'p-error': isFormFieldValid('ranking') }, 'Label__Text')}>
                                        Ranking
                                    </label>
                                    <Dropdown
                                        id="ranking"
                                        placeholder="Select Ranking"
                                        options={rankingData}
                                        optionLabel="name"
                                        name="ranking"
                                        optionValue="lovId"
                                        value={formik.values.ranking || ''}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('ranking') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('ranking')}
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-3 pb-3 col-4 flex align-items-start justify-content-center">
                            <MobileView icon={iconLink} title={formik?.values?.text} />
                        </div>
                    </div>
                    <div className="card Card__Bg__Change col-12 md:col-12 flex">
                        <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Select Action <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    value={formik?.values?.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                            <div className="desc_Text_Area">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    placeholder="-"
                                    inputid="checkerComments"
                                    name="checkerComments"
                                    rows={4}
                                    cols={30}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'Input__Round')}
                                    value={formik?.values?.checkerComments}
                                />
                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button disabled={loading} type="submit" label="Update" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default EditBannerChecker;

import React from 'react';
import cmsPromotions from '../../../../assets/cms/cmsPromotions.png';
import moment from 'moment';

function MobileView({ icon, title, descrition, date }) {
    return (
        <div className="cmsMobileView__main relative">
            <div className="cmsMobileView__promotion">
                {title && <p className="cmsMobileView__title">{title}</p>}
                {descrition && <p className="cmsMobileView__description">{descrition}</p>}
                {date && <p className="cmsMobileView__date">Valid by: {moment(date)?.format('DD-MMM-yyyy')}</p>}
                {icon && <img src={icon} className="cmsMobileView__icon" alt="icon" />}
            </div>
            <img src={cmsPromotions} className="blurMobile w-100 h-100" alt="cmsPromotions" />
        </div>
    );
}

export default MobileView;

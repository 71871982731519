import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import T24AccountLink from './T24AccountLink';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function CheckerApis({ type }) {
    // Getting params from URL using hash router
    const dispatch = useDispatch();
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');
    const [dataFromId, setDataFromId] = useState([]);
    const [dataFromApi, setDataFromApi] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [urlData, setUrlData] = useState([]);
    const [historyTable, setHistoryTable] = useState([]);

    const getDatabyId = async () => {
        const resp = await handleGetRequest(`/t24connectivity/v1/accountlinking/t24account/${refTableId}`);
        const keyData = resp?.payLoad;
        if (type === 'checkerView') {
            getHistoryTable(keyData?.accountId);
        }
        setDataFromId(keyData);
    };

    const getMcRequestDataById = async () => {
        const resp = await handleGetRequest(`/account/v1/t24/get24accountlinkupdatecheckerbyid/${mcRequestId}`);
        // const resp2 = await handleGetRequest(`/t24connectivity/v1/accountlinking/t24account/${refTableId}`);
        // if (type === 'checkerView') {
        //     getHistoryTable(resp2?.payLoad?.accountId);
        // }
        const keyData = resp?.payLoad;
        setDataFromApi(keyData);
    };

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };

    const getHistoryTable = async (accountId) => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    accountId: accountId?.toString(),
                    mobileNo: '',
                    accountClassificationId: '',
                    cnic: '',
                    coreAccountNo: '',
                    createdBy: '',
                    updatedBy: '',
                    dateFrom: '',
                    dateTo: '',
                    statusId: '',
                    linkStatus: ''
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const LKP_STATUS = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const TBL_USER = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        const getCoreLinkedAccounts = await dispatch(handlePostRequest(newData, '/t24connectivity/v1/accountlinking/getcorelinkedaccounts', true, true));
        if (getCoreLinkedAccounts?.payLoad) {
            const updatedPayload = getCoreLinkedAccounts.payLoad.map((payloadItem) => {
                const statusName = LKP_STATUS?.payLoad?.find((item) => item?.lovId === payloadItem?.statusId)?.name;
                payloadItem.statusName = statusName;

                const createdUser = TBL_USER?.payLoad?.find((item) => item?.lovId === payloadItem?.createdBy)?.name || 'N/A';
                payloadItem.createdByUser = createdUser;

                const updatedUser = TBL_USER?.payLoad?.find((item) => item?.lovId === payloadItem?.updatedBy)?.name || 'N/A';
                payloadItem.updatedByUser = updatedUser;

                const fullNameParts = payloadItem.fullName.split(' ');
                if (fullNameParts.length > 1) {
                    payloadItem.firstName = fullNameParts.slice(0, -1).join(' ');
                    payloadItem.lastName = fullNameParts[fullNameParts.length - 1];
                } else {
                    payloadItem.firstName = fullNameParts[0];
                }

                return payloadItem;
            });
            setHistoryTable(updatedPayload);
        }
    };

    useEffect(() => {
        setUrlData({ refTableId, requestType, mcRequestId, mcPeindingRequestId, requestTypeDescr, tableName });
        getDatabyId();
        if (requestType !== 'I') {
            getMcRequestDataById();
        }
    }, [requestType]);

    useEffect(() => {
        getStatusLov();
    }, []);

    useEffect(() => {
        setTableData({ dataFromId, dataFromApi, statusLovData, urlData });
    }, [dataFromId, dataFromApi, statusLovData, urlData]);

    return (
        <React.Fragment>
            <T24AccountLink type={type} dataForTable={tableData} historyTableView={historyTable} />
            {/* Action Authorization History */}
            {refTableId && tableName && <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />}
        </React.Fragment>
    );
}

export default CheckerApis;

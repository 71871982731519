import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { handlePostRequest } from '../../services/PostTemplate';
import { handleGetRequest } from '../../services/GetTemplate';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Steps } from 'primereact/steps';
import AddCreateCampaignManagement from './Components/CreateCampaign/AddCampaign';
import AddTargetBaseSelectionCampaign from './Components/TargetBaseSelection/AddTargetBase';
import AddQualifyingCriteria from './Components/QualifyingCriteria/AddQualifyingCriteria';
import AddRewardConfiguration from './Components/RewardConfigurations/AddRewardConfigurations';
import AddCustomerCommunicationCampaign from './Components/CustomerCommunication/AddCustomerCommunication';
import { useLocation } from 'react-router-dom';

function AddCampaignManagement() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [activeIndex, setActiveIndex] = useState(0);
    const [allStepsComplete, setAllStepsComplete] = useState(false);
    const [compaignIdData, setCompaignIdData] = useState();
    const [campaignData, setCampaignData] = useState();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const stateCampaignId = location.state?.campaignId;

    useEffect(() => {
        if (stateCampaignId) {
            getCampaignByIdInCaseOfDraft();
        }
    }, []); // eslint-disable-line

    const getCampaignByIdInCaseOfDraft = async () => {
        const response = await handleGetRequest(`/customerloyalitypromotions/v1/customerloyalitypromotion/getcampaignbyid/${stateCampaignId}`);
        setCampaignData(response?.payLoad);
        setActiveIndex(parseInt(response?.payLoad?.currentStep));
        setCompaignIdData(response?.payLoad?.campaignId);
    };

    const getCampaignById = async () => {
        const response = await handleGetRequest(`/customerloyalitypromotions/v1/customerloyalitypromotion/getcampaignbyid/${compaignIdData}`);
        setCampaignData(response?.payLoad);
    };

    useEffect(() => {
        if (compaignIdData) {
            getCampaignById();
        }
    }, [activeIndex]); // eslint-disable-line

    const formik = useFormik({
        initialValues: {},

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['campaignId'] = compaignIdData;

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: [data],
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/customerloyalitypromotions/v1/customerloyalitypromotion/submitcampaign', true, false, 'campaignmanagement'));
            if (res?.responseCode === '150000') {
                formik.resetForm();
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const items = [
        {
            label: 'Add Campaign',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(campaignData?.currentStep) >= 0 ? false : true
        },
        {
            label: 'Target Base Selection',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(campaignData?.currentStep) >= 1 ? false : true
        },
        {
            label: 'Qualifying criteria',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(campaignData?.currentStep) >= 2 ? false : true
        },
        {
            label: 'Reward Configurations',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(campaignData?.currentStep) >= 3 ? false : true
        },
        {
            label: 'Customer Communication',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(campaignData?.currentStep) >= 4 ? false : true
        }
    ];

    const handleStepClick = (index) => {
        setActiveIndex(index);
    };

    const getCompaignId = (data) => {
        setCompaignIdData(data);
    };

    return (
        <>
            <div className="card Card__Round">
                <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} style={{ margin: '50px 0px', padding: '50px 0px' }} className="Campaign__Steps" />

                {activeIndex === 0 && <AddCreateCampaignManagement activeIndex={activeIndex} handleStepClick={handleStepClick} getCompaignId={getCompaignId} campaignData={campaignData} />}
                {activeIndex === 1 && <AddTargetBaseSelectionCampaign activeIndex={activeIndex} handleStepClick={handleStepClick} compaignIdData={compaignIdData} campaignData={campaignData} getCompaignId={getCompaignId} />}
                {activeIndex === 2 && <AddQualifyingCriteria activeIndex={activeIndex} handleStepClick={handleStepClick} compaignIdData={compaignIdData} campaignData={campaignData} getCompaignId={getCompaignId} />}
                {activeIndex === 3 && <AddRewardConfiguration activeIndex={activeIndex} handleStepClick={handleStepClick} compaignIdData={compaignIdData} campaignData={campaignData} getCompaignId={getCompaignId} />}
                {activeIndex === 4 && (
                    <AddCustomerCommunicationCampaign activeIndex={activeIndex} handleStepClick={handleStepClick} setAllStepsComplete={setAllStepsComplete} compaignIdData={compaignIdData} campaignData={campaignData} getCompaignId={getCompaignId} />
                )}

                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {activeIndex === 5 && allStepsComplete && (
                        <div className="Down__Btn">
                            <Button disabled={loading} label="Submit" className="Btn__Dark" />
                            <Button disabled={loading} onClick={() => navigate('/campaignmanagement')} label="Cancel" className="Btn__Transparent" />
                        </div>
                    )}
                </form>
            </div>
        </>
    );
}
export default AddCampaignManagement;

import React, { useEffect, useState } from 'react'
import { Tree } from 'primereact/tree'
import { ScrollPanel } from 'primereact/scrollpanel'
import { useNavigate } from 'react-router-dom'
import { handleGetRequest } from '../../../services/GetTemplate'
import { handlePostRequest } from '../../../services/PostTemplate'
import { Button } from 'primereact/button'
import { useDispatch } from 'react-redux'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputSwitch } from 'primereact/inputswitch'

const ViewCOA = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState(null);

    const [chartOfAccountsData, setChartOfAccountsData] = useState([])

    const [segments, setSegments] = useState([
        {
            key: "1",
            label: 'Organization',
            children: []
        },
        {
            key: "2",
            label: 'Location',
            children: []
        },
        {
            key: "3",
            label: 'Cost Center',
            children: []
        },
        {
            key: "4",
            label: 'Category',
            children: []
        },
        {
            key: "5",
            label: 'Sub Category',
            children: []
        },
        {
            key: "6",
            label: 'Currency',
            children: []
        },
        {
            key: "7",
            label: 'MRP',
            children: []
        },
    ]);

    const updateSegmentChildren = (segmentName, segmentValues) => {
        setSegments((prevSegments) => {
            const updatedSegments = [...prevSegments];
            const segmentNode = updatedSegments.find((node) => node.label === segmentName);
            segmentNode.children = segmentValues;
            return updatedSegments;
        });
    };

    const getSegmentData = async (segmentName, url) => {
        setLoading(true);
        const response = await handleGetRequest(url);
        const segmentValues = response?.payLoad.map((item, ind) => ({
            id: item?.lovId,
            key: item?.lovId + "-" + ind + "-" + segmentName,
            label: item?.name,
        }));
        updateSegmentChildren(segmentName, segmentValues);
        setLoading(false);
    };

    const getOrganizationLov = () => {
        getSegmentData('Organization', '/configuration/v1/lookups/getchartofaccountdata/LKP_ORGANIZATION');
    };

    const getLocationLov = () => {
        getSegmentData('Location', '/configuration/v1/lookups/getchartofaccountdata/LKP_BRANCH');
    };

    const getCostCenterLov = async () => {
        getSegmentData('Cost Center', '/configuration/v1/lookups/getchartofaccountdata/LKP_COST_CENTER');
    };

    const getCategoryLov = async () => {
        getSegmentData('Category', '/configuration/v1/lookups/getchartofaccountdata/LKP_GL_TYPE');
    };

    const getSubCategoryLov = async () => {
        getSegmentData('Sub Category', '/configuration/v1/lookups/getchartofaccountdata/LKP_SUB_CATEGORY');
    };

    const getCurrencyLov = async () => {
        getSegmentData('Currency', '/configuration/v1/lookups/getchartofaccountdata/LKP_CURRENCY');
    };

    const getMrpLov = async () => {
        getSegmentData('MRP', '/configuration/v1/lookups/getchartofaccountdata/LKP_MRP_CODE');
    };

    const onSubmit = async () => {

        if (selectedKeys) {
            // Extract segment names and indexes
            const extractedData = Object.keys(selectedKeys).reduce((result, key) => {
                const segments = key.split('-');
                const segment = segments.slice(2).join('-'); // Extract the segment name (everything after the last hyphen)
                const index = parseInt(segments[0]);

                if (!result[segment]) {
                    result[segment] = {
                        segment,
                        arr: [index]
                    };
                } else {
                    if (!result[segment].arr.includes(index)) {
                        result[segment].arr.push(index);
                    }
                }
                return result;
            }, {});

            const resultArray = Object.values(extractedData);

            // Initialize the coaPayload
            const coaPayload = {
                data: {
                    security: {
                        userName: "",
                        password: "",
                        securityToken: ""
                    },
                    account: {
                        msidn: "",
                        iban: "",
                        bban: "",
                        pan: "",
                        currency: ""
                    },
                    channel: "",
                    terminal: "",
                    reterivalReferenceNumber: "",
                    payLoad: {
                        organizationID: [],
                        branchID: [],
                        costCenterID: [],
                        glTypeID: [],
                        subCategoryID: [],
                        currencyID: [],
                        mrpCodeID: []
                    },
                    additionalInformation: [
                        {
                            infoKey: "",
                            infoValue: ""
                        }
                    ],
                    checkSum: ""
                }
            };

            // Loop through resultArray to populate coaPayload
            resultArray.forEach((item) => {
                const segmentName = item.segment;
                const arr = item.arr;

                switch (segmentName) {
                    case "Organization":
                        coaPayload.data.payLoad.organizationID = arr;
                        break;
                    case "Location":
                        coaPayload.data.payLoad.branchID = arr;
                        break;
                    case "Cost Center":
                        coaPayload.data.payLoad.costCenterID = arr;
                        break;
                    case "Category":
                        coaPayload.data.payLoad.glTypeID = arr;
                        break;
                    case "Sub Category":
                        coaPayload.data.payLoad.subCategoryID = arr;
                        break;
                    case "Currency":
                        coaPayload.data.payLoad.currencyID = arr;
                        break;
                    case "MRP":
                        coaPayload.data.payLoad.mrpCodeID = arr;
                        break;
                    default:
                        break;
                }
            });

            const res = await dispatch(handlePostRequest(coaPayload, '/account/v1/chartofaccount/getallchartofaccounts', true, true));
            if (res?.responseCode === '010000') {
                setChartOfAccountsData(res?.payLoad);
            }
            else setChartOfAccountsData("")

        }
    }

    const addCoa = () => {
        navigate("/addchartofaccounts")
    }

    const actions = (rowData) => {
        return (
            <div className='flex align-items-center'>
                <InputSwitch
                    id={`isActive-${rowData.branchId}`}
                    checked={rowData.isActive === 'Y'}
                    onChange={(e) => handleIsActiveSwitch(e, rowData)}
                    className="mr-3"
                    disabled={rowData?.lkpStatus?.statusDescr === "Pending" || rowData?.lkpStatus?.statusDescr === "Rejected" ? true : false}
                />
                <Button tooltip="Edit" icon="pi pi-pencil" tooltipOptions={{ position: 'top' }} onClick={() => editHandler(rowData)} className="p-button-rounded p-button-warning mr-3" disabled={(rowData?.lkpStatus?.statusDescr === "Pending" || rowData?.lkpStatus?.statusDescr === "Rejected") || (rowData?.lkpStatus?.statusDescr === "Approved" && rowData.isActive === 'N') ? true : false} />
                <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => viewHandler(rowData)} className="p-button-rounded p-button-primary" />
            </div>
        )
    }

    const handleIsActiveSwitch = async (e, rowData) => {

        const newIsActive = e.value === true ? 'Y' : 'N';

        const jsonObj = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',

                payLoad: {
                    glCodeCombinationID: rowData?.glCodeCombinationId,
                    isActive: newIsActive
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(jsonObj, '/account/v1/chartofaccount/inactivechartofaccount', true, false));
        if (res?.responseCode === '010000') {

        }
    };

    const editHandler = (rowData) => {
        navigate('/editchartofaccounts', { state: { rowData } })
    }
    const viewHandler = (rowData) => {
        navigate('/viewchartofaccounts', {
            state: { rowData }
        });
    };

    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.lkpStatus?.statusName === 'Approved' ? '#14A38B' : rowData?.lkpStatus?.statusName === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.lkpStatus?.statusName}</b>
            </p>
        );
    };

    useEffect(() => {
        getOrganizationLov()
        getLocationLov()
        getCostCenterLov()
        getCategoryLov()
        getSubCategoryLov()
        getCurrencyLov()
        getMrpLov()
    }, []);

    return (

        <>

            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={addCoa} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>

            <div className='grid'>

                <div className='col-12 md:col-3'>
                    <div className="card">

                        <ScrollPanel className="custombar1">
                            <div >
                                <Tree
                                    value={segments}
                                    selectionMode="checkbox"
                                    selectionKeys={selectedKeys}
                                    onSelectionChange={(e) => { setSelectedKeys(e.value) }}
                                    loading={loading}
                                    className="w-full md:w-28rem"
                                    showHeader={true}
                                    header={
                                        <>
                                            <h4 className='tree_head'>Chart Of Accounts</h4>
                                        </>
                                    }
                                />
                            </div>
                        </ScrollPanel>

                        <div className="text-left pt-2">
                            <Button type="submit" label="Search" className="view-button Btn__Dark" onClick={onSubmit} />
                        </div>

                    </div>
                </div>

                <div className='col-12 md:col-9'>
                    <div className='card'>
                        <DataTable
                            // header={header}
                            value={chartOfAccountsData}
                            emptyMessage={
                                <>
                                    <div className='traingle_main'>
                                        <span className="pi pi-exclamation-triangle"></span>
                                        <h5>No records found</h5>
                                    </div>
                                </>
                            }
                            scrollable
                            paginator
                            responsiveLayout="scroll"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            tableStyle={{ height: '70vh' }}
                        >
                            <Column field="glCodeCombination" header="GL Code" />
                            <Column field="glCodeCombinationName" header="GL Code Name" />
                            <Column body={(item) => item?.glCodeCombinationName.split("-")[0]} header="Organization" />
                            <Column body={(item) => item?.glCodeCombinationName.split("-")[1]} header="Location" />
                            <Column body={(item) => item?.glCodeCombinationName.split("-")[2]} header="Cost Center" />
                            <Column body={(item) => item?.glCodeCombinationName.split("-")[3]} header="Category" />
                            <Column body={(item) => item?.glCodeCombinationName.split("-")[4]} header="Sub Category" />
                            <Column body={(item) => item?.glCodeCombinationName.split("-")[5]} header="Currency" />
                            <Column body={(item) => item?.glCodeCombinationName.split("-")[6]} header="MRP" />
                            <Column body={statusBodyTemplate} header="Status" />
                            <Column body={actions} header="Actions" />
                        </DataTable>
                    </div>
                </div>

            </div>

        </>

    );
};

export default ViewCOA
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputMask } from 'primereact/inputmask';
import { InputTextarea } from 'primereact/inputtextarea';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../../services/PostTemplate';
import * as Yup from 'yup';
import LoadingOverlay from 'react-loading-overlay';

function DialogCloseAccount({ onHideCloseAccount, customerCnic, accountNumber, reason, checkerEditable, mobile }) {
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        reason: Yup.string().min(10).max(200).required('This field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cnic: '',
            reason: ''
        },

        onSubmit: async (data) => {
            setIsLoading(true);

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        closeAccountRequestByCnic: {
                            cnic: customerCnic,
                            accountNumber: accountNumber,
                            reason: data?.reason,
                            mobileNumber: mobile
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/account/v1/customeraccount/updateaccountstatus', true, false, 'accountsmanagement'));
            if (res?.responseCode === '010000') {
                formik.resetForm();
                onHideCloseAccount();
            }
            setIsLoading(false);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <LoadingOverlay active={isLoading} spinner text="Please Wait ...">
                <div className="card Card__Round">
                    <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="cnic" className={classNames({ 'p-error': isFormFieldValid('cnic') }, 'Label__Text')}>
                                        CNIC Number
                                    </label>
                                    <InputMask
                                        mask="99999-9999999-9"
                                        id="cnic"
                                        placeholder="Enter CNIC Number"
                                        name="cnic"
                                        disabled
                                        value={customerCnic || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cnic') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('cnic')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-12">
                                <div className="p-field">
                                    <label htmlFor="reason" className={classNames({ 'p-error': isFormFieldValid('reason') }, 'Label__Text')}>
                                        Reason
                                    </label>
                                    <InputTextarea
                                        disabled={checkerEditable ? true : false}
                                        autoResize
                                        placeholder="Enter Reason for Close Account"
                                        rows={5}
                                        cols={30}
                                        id="reason"
                                        name="reason"
                                        value={formik?.values?.reason?.replace(/\s\s+/g, ' ') || reason}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('reason') }, 'TextArea__Round')}
                                    />

                                    {getFormErrorMessage('reason')}
                                </div>
                            </div>
                        </div>

                        <div className="Down__Btn">
                            <Button label="Close" type="submit" disabled={checkerEditable ? true : false} className="Btn__Dark" />
                            <Button
                                label="Cancel"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onHideCloseAccount();
                                }}
                                // disabled={loading}
                                className="Btn__Transparent"
                            />
                        </div>
                    </form>
                </div>
            </LoadingOverlay>
        </>
    );
}

export default DialogCloseAccount;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { MultiSelect } from 'primereact/multiselect';
import { InputNumber } from 'primereact/inputnumber';

function UpdateRewardConfigurationCampaign({ activeIndex, handleStepClick, compaignIdData, campaignData, getCompaignId, setStepData4, stepData1 }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [rewardConfigurationLov, setRewardConfigurationLov] = useState([]);
    const [frequencyPeriodLov, setFrequencyPeriodLov] = useState([]);
    const [incentiveTypeLov, setIncentiveTypeLov] = useState([]);
    const [glIdData, setGlIdData] = useState('');
    const [promoCode, setPromoCode] = useState();

    const [loadingIconCashback, setloadingIconCashback] = useState('');
    const [loadingCashback, setloadingCashback] = useState(false);

    const dispatch = useDispatch();

    const getRewardConfigurationLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getdatalov/LKP_CAMPAIGN_REWARD_TYPE');
        setRewardConfigurationLov(resp?.payLoad);
    };

    const getFrequencyPeriodLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getdatalov/LKP_FREQUENCY_PERIOD');
        setFrequencyPeriodLov(resp?.payLoad);
    };

    const getIncentiveTypeLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getdatalov/LKP_CAMPAIGN_INCENTIVE_TYPE');
        setIncentiveTypeLov(resp?.payLoad);
    };

    const getGlId = async () => {
        setloadingCashback(true);
        setloadingIconCashback('pi pi-spin pi-spinner');
        const data = {
            accountNumber: ''
        };

        data['accountNumber'] = formik.values.glId;

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/account/v1/fetchglbyaccountno', true, false));
        if (res?.responseCode === '010000') {
            setGlIdData(res?.payLoad);
        }
        setloadingIconCashback('');
        setloadingCashback(false);
    };

    const generatePromoCode = async () => {
        const data = {
            cashBackType: '',
            rcAmount: '',
            campaignId: '',
            rcMaxDiscountAmount: '',
            rcPromoDisc: ''
        };

        data['campaignId'] = compaignIdData || campaignData?.campaignId;
        data['cashBackType'] = formik?.values?.cashBackType;
        data['rcAmount'] = formik?.values?.rcAmount;
        data['rcMaxDiscountAmount'] = formik?.values?.rcMaxDiscountAmount;
        data['rcPromoDisc'] = formik.values.rcPromoDisc;

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/customerloyalitypromotions/v1/customerloyalitypromotion/generatepromocode', true, false));
        if (res?.responseCode === '150000') {
            setPromoCode(res?.payLoad?.rcPromoCode);
            // formik.resetForm();
        }
    };

    useEffect(() => {
        getRewardConfigurationLov();
        getFrequencyPeriodLov();
    }, []); // eslint-disable-line

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);

        const day = date.getDate()?.toString().padStart(2, '0');
        const month = (date.getMonth() + 1)?.toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours()?.toString().padStart(2, '0');
        const minutes = date.getMinutes()?.toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours from 24-hour to 12-hour format
        const formattedHours = hours % 12 || 12;

        const formattedDate = `${day}-${month}-${year}, ${formattedHours}:${minutes} ${ampm}`;
        return formattedDate;
    };

    useEffect(() => {
        if (campaignData) {
            formik.setFieldValue('rewardTypeCode', campaignData?.lkpCampaignRewardType?.campaignRewardTypeCode?.toString());

            if (campaignData?.rcCashbackType === 'P') {
                formik.setFieldValue('rcAmount', campaignData?.rcPercentage);
            } else if (campaignData?.rcAmount === null) {
                formik.setFieldValue('rcAmount', campaignData?.budget);
            } else {
                formik.setFieldValue('rcAmount', campaignData?.rcAmount);
            }
            // formik.setFieldValue('rcIncentives', campaignData?.lkpCampaignIncentiveType?.campaignIncentiveTypeCode?.toString());
            const campaignIncentiveTypeIds = campaignData?.tblCampaignRcIncentiveTypes?.filter((item) => item.isActive === 'Y')?.map((item) => item.lkpCampaignIncentiveType.campaignIncentiveTypeId.toString());

            formik.setFieldValue('rcIncentives', campaignIncentiveTypeIds);

            // formik.setFieldValue('cashBackType', campaignData?.rcCashbackType?.cashbackTypeCode?.toString());

            formik.setFieldValue('rcPromoDisc', campaignData?.rcDescription);

            formik.setFieldValue('rcFrequency', campaignData?.rcFrequency?.toString());
            formik.setFieldValue('rcFrequencyPeriodCode', campaignData?.lkpRCFrequencyPeriod?.frequencyPeriodCode?.toString());

            formik.setFieldValue('rcMaxDiscountAmount', campaignData?.rcMaxCashbackAmount);
            // formik.setFieldValue('rcPercentage', campaignData?.rcPercentage);

            setPromoCode(campaignData?.rcPromoCode);

            formik.setFieldValue('glId', campaignData?.tblAccount?.accountNo);
            formik.setFieldValue('glName', campaignData?.tblAccount?.accountTitle);
            formik.setFieldValue('availableBalance', campaignData?.tblAccount?.availableBalance);

            formik.setFieldValue('cashBackType', campaignData?.rcCashbackType);

            formik.setFieldValue('rcFrequencyMonth', campaignData?.rcFrequencyMonth?.slice(1, -1).split(', '));
            formik.setFieldValue('rcFrequencyDay', campaignData?.rcFrequencyDay?.slice(1, -1).split(', '));
            formik.setFieldValue('rcFrequencyDate', campaignData?.rcFrequencyDate?.slice(1, -1).split(', '));

            formik.setFieldValue('voucherTitle', campaignData?.voucherTitle);
            formik.setFieldValue('expiryDateTime', new Date(campaignData?.voucherExpiryDate).toISOString().slice(0, 16));
            formik.setFieldValue('voucherDescription', campaignData?.voucherDescr);

            if (campaignData?.tblCampaignRcTimes?.length !== 0) {
                const rcTimeListData = campaignData?.tblCampaignRcTimes
                    ?.filter((item) => item?.isActive === 'Y')
                    ?.map((item) => {
                        return {
                            startTime: item?.startTime,
                            endTime: item?.endTime
                        };
                    });
                formik.setFieldValue('frequencyRcTimeList', rcTimeListData);
            }
        }
    }, []); // eslint-disable-line

    const frequencyRcTimeListSchema = Yup.array().when('rcFrequencyPeriodCode', (rcFrequencyPeriodCode, schema) =>
        rcFrequencyPeriodCode === '10'
            ? Yup.array().of(
                  Yup.object().shape({
                      startTime: Yup.string().required('Start Time is required'),
                      endTime: Yup.string()
                          .required('End Time is required')
                          .test('greater-than-start', 'End Time must be greater than Start Time', function (value) {
                              const { startTime } = this.parent;
                              return !startTime || !value || value > startTime;
                          })
                  })
              )
            : rcFrequencyPeriodCode === '20'
            ? Yup.array().of(
                  Yup.object().shape({
                      startTime: Yup.string().required('Start Time is required'),
                      endTime: Yup.string()
                          .required('End Time is required')
                          .test('greater-than-start', 'End Time must be greater than Start Time', function (value) {
                              const { startTime } = this.parent;
                              return !startTime || !value || value > startTime;
                          })
                  })
              )
            : rcFrequencyPeriodCode === '30'
            ? Yup.array().of(
                  Yup.object().shape({
                      startTime: Yup.string().required('Start Time is required'),
                      endTime: Yup.string()
                          .required('End Time is required')
                          .test('greater-than-start', 'End Time must be greater than Start Time', function (value) {
                              const { startTime } = this.parent;
                              return !startTime || !value || value > startTime;
                          })
                  })
              )
            : rcFrequencyPeriodCode === '40'
            ? Yup.array().of(
                  Yup.object().shape({
                      startTime: Yup.string().required('Start Time is required'),
                      endTime: Yup.string()
                          .required('End Time is required')
                          .test('greater-than-start', 'End Time must be greater than Start Time', function (value) {
                              const { startTime } = this.parent;
                              return !startTime || !value || value > startTime;
                          })
                  })
              )
            : schema
    );

    const validationSchema = Yup.object().shape({
        rewardTypeCode: Yup.mixed().required('This field is required.'),
        frequencyRcTimeList: frequencyRcTimeListSchema
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            rewardTypeCode: '',
            rcAmount: '',
            rcFrequencyPeriodCode: '',
            rcFrequency: '',
            // rcPercentage: '',

            availableBalance: '',
            glName: '',
            glId: '',
            cashBackType: '',
            rcPromoDisc: '',
            rcMaxDiscountAmount: '',

            rcIncentives: [],

            rcFrequencyMonth: [],
            rcFrequencyDay: '',
            rcFrequencyDate: [],

            voucherTitle: '',
            expiryDateTime: '',
            voucherDescription: '',

            frequencyRcTimeList: [
                {
                    startTime: '',
                    endTime: ''
                }
            ]
        },

        validate: (data) => {
            let errors = {};
            if (!data.rewardTypeCode) {
                errors.rewardTypeCode = 'This field is required';
            }
            if (data?.rewardTypeCode === '10') {
                if (data.rcFrequencyPeriodCode === '20') {
                    if (!data.rcFrequencyDay) {
                        errors.rcFrequencyDay = 'Please select the days.';
                    }
                }
                if (data?.rcFrequencyPeriodCode === '30') {
                    if (data.rcFrequencyDate?.length === 0 || data?.rcFrequencyDate === '[]' || data?.rcFrequencyDate === null || data?.rcFrequencyDate === undefined) {
                        errors.rcFrequencyDate = 'Please select the date.';
                    }
                }
                if (data?.rcFrequencyPeriodCode === '40') {
                    if (data.rcFrequencyDate?.length === 0 || data?.rcFrequencyDate === '[]' || data?.rcFrequencyDate === null || data?.rcFrequencyDate === undefined) {
                        errors.rcFrequencyDate = 'Please select the date.';
                    }
                    if (data.rcFrequencyMonth?.length === 0 || data?.rcFrequencyMonth === null || data?.rcFrequencyMonth === undefined) {
                        errors.rcFrequencyMonth = 'Please select Month.';
                    }
                }
                if (!data?.glId) {
                    errors.glId = 'This field is required';
                }

                if (!data?.cashBackType) {
                    errors.cashBackType = 'This field is required';
                }

                if (data?.cashBackType === 'P') {
                    if (data?.rcMaxDiscountAmount === '' || data?.rcMaxDiscountAmount === null || data?.rcMaxDiscountAmount === undefined) {
                        errors.rcMaxDiscountAmount = 'This field is required';
                    }
                    if (data?.rcAmount === null || data?.rcAmount === '' || data?.rcAmount === 0 || !/^[0-9]*$/.test(data.rcAmount)) {
                        errors.rcAmount = 'This field is required';
                    }
                    if (data?.rcAmount > 100) {
                        errors.rcAmount = 'Percentage must be 1 to 100';
                    }
                }

                if (['', null, undefined].includes(data?.rcFrequencyPeriodCode)) {
                    errors.rcFrequencyPeriodCode = 'This field is required';
                }

                if (data?.cashBackType === 'F') {
                    if (data?.rcAmount === null || data?.rcAmount === '' || data?.rcAmount === 0 || !/^[0-9]*$/.test(data.rcAmount)) {
                        errors.rcAmount = 'This field is required';
                    }
                }
            }

            if (data?.rewardTypeCode === '20') {
                if (data.rcFrequencyPeriodCode === '20') {
                    if (!data.rcFrequencyDay) {
                        errors.rcFrequencyDay = 'Please select the days.';
                    }
                }
                if (data?.rcFrequencyPeriodCode === '30') {
                    if (data.rcFrequencyDate?.length === 0 || data?.rcFrequencyDate === '[]' || data?.rcFrequencyDate === null || data?.rcFrequencyDate === undefined) {
                        errors.rcFrequencyDate = 'Please select the date.';
                    }
                }
                if (data?.rcFrequencyPeriodCode === '40') {
                    if (data.rcFrequencyDate?.length === 0 || data?.rcFrequencyDate === '[]' || data?.rcFrequencyDate === null || data?.rcFrequencyDate === undefined) {
                        errors.rcFrequencyDate = 'Please select the date.';
                    }
                    if (data.rcFrequencyMonth?.length === 0 || data?.rcFrequencyMonth === null || data?.rcFrequencyMonth === undefined) {
                        errors.rcFrequencyMonth = 'Please select Month.';
                    }
                }
                if (!data.rcFrequencyPeriodCode) {
                    errors.rcFrequencyPeriodCode = 'This field is required';
                }
                if (data?.glId === '') {
                    errors.glId = 'This field is required';
                }

                if (data?.cashBackType === '') {
                    errors.cashBackType = 'This field is required';
                }
                if (!data.cashBackType) {
                    errors.cashBackType = 'This field is required';
                }
                if (data?.cashBackType === 'P') {
                    if (data?.rcMaxDiscountAmount === '' || data?.rcMaxDiscountAmount === null || data?.rcMaxDiscountAmount === undefined) {
                        errors.rcMaxDiscountAmount = 'This field is required';
                    }
                    if (data?.rcAmount === null || data?.rcAmount === '' || data?.rcAmount === 0 || !/^[0-9]*$/.test(data.rcAmount)) {
                        errors.rcAmount = 'This field is required';
                    }
                    if (data?.rcAmount > 100) {
                        errors.rcAmount = 'Percentage must be 1 to 100';
                    }
                }

                if (data?.rcFrequencyPeriodCode === '') {
                    errors.rcFrequencyPeriodCode = 'This field is required';
                }

                if (data?.cashBackType === 'F') {
                    if (data?.rcAmount === null || data?.rcAmount === '' || data?.rcAmount === 0 || !/^[0-9]*$/.test(data.rcAmount)) {
                        errors.rcAmount = 'This field is required';
                    }
                }
            }

            if (data?.rewardTypeCode === '30') {
                if (data.rcFrequencyPeriodCode === '20') {
                    if (!data.rcFrequencyDay) {
                        errors.rcFrequencyDay = 'Please select the days.';
                    }
                }
                if (data?.rcFrequencyPeriodCode === '30') {
                    if (data.rcFrequencyDate?.length === 0 || data?.rcFrequencyDate === '[]' || data?.rcFrequencyDate === null || data?.rcFrequencyDate === undefined) {
                        errors.rcFrequencyDate = 'Please select the date.';
                    }
                }
                if (data?.rcFrequencyPeriodCode === '40') {
                    if (data.rcFrequencyDate?.length === 0 || data?.rcFrequencyDate === '[]' || data?.rcFrequencyDate === null || data?.rcFrequencyDate === undefined) {
                        errors.rcFrequencyDate = 'Please select the date.';
                    }
                    if (data.rcFrequencyMonth?.length === 0 || data?.rcFrequencyMonth === null || data?.rcFrequencyMonth === undefined) {
                        errors.rcFrequencyMonth = 'Please select Month.';
                    }
                }
                if (!data?.rcPromoDisc) {
                    errors.rcPromoDisc = 'This field is required';
                }

                if (!data?.cashBackType) {
                    errors.cashBackType = 'This field is required';
                }

                if (data?.cashBackType === 'F') {
                    if (data?.rcAmount === null || data?.rcAmount === '' || data?.rcAmount === 0 || !/^[0-9]*$/.test(data.rcAmount)) {
                        errors.rcAmount = 'This field is required';
                    }
                }

                if (data?.cashBackType === 'P') {
                    if (data?.rcMaxDiscountAmount === '' || data?.rcMaxDiscountAmount === null || data?.rcMaxDiscountAmount === undefined) {
                        errors.rcMaxDiscountAmount = 'This field is required';
                    }
                }

                if (['', null, undefined].includes(data?.rcFrequencyPeriodCode)) {
                    errors.rcFrequencyPeriodCode = 'This field is required';
                }

                if (data?.cashBackType === 'P') {
                    if (data?.rcAmount === null || data?.rcAmount === '' || data?.rcAmount === 0 || data?.rcAmount === undefined || !/^[0-9]*$/.test(data.rcAmount)) {
                        errors.rcAmount = 'This field is required';
                    }
                    if (data?.rcAmount > 100) {
                        errors.rcAmount = 'Percentage must be 1 to 100';
                    }
                }
            }

            if (data?.rewardTypeCode === '40') {
                if (data.rcFrequencyPeriodCode === '20') {
                    if (!data.rcFrequencyDay) {
                        errors.rcFrequencyDay = 'Please select the days.';
                    }
                }
                if (data?.rcFrequencyPeriodCode === '30') {
                    if (data.rcFrequencyDate?.length === 0 || data?.rcFrequencyDate === '[]' || data?.rcFrequencyDate === null || data?.rcFrequencyDate === undefined) {
                        errors.rcFrequencyDate = 'Please select the date.';
                    }
                }
                if (data?.rcFrequencyPeriodCode === '40') {
                    if (data.rcFrequencyDate?.length === 0 || data?.rcFrequencyDate === '[]' || data?.rcFrequencyDate === null || data?.rcFrequencyDate === undefined) {
                        errors.rcFrequencyDate = 'Please select the date.';
                    }
                    if (data.rcFrequencyMonth?.length === 0 || data?.rcFrequencyMonth === null || data?.rcFrequencyMonth === undefined) {
                        errors.rcFrequencyMonth = 'Please select Month.';
                    }
                }
                if (data?.rcIncentives?.length === 0) {
                    errors.rcIncentives = 'This field is required';
                }
                if (!data.rcAmount) {
                    errors.rcAmount = 'This field is required';
                }
                if (['', null, undefined].includes(data?.rcFrequencyPeriodCode)) {
                    errors.rcFrequencyPeriodCode = 'This field is required';
                }

                if (data?.rcFrequencyPeriodCode === '10') {
                    // if (data?.frequencyRcTimeList.length === 0) {
                    //     toast.warning('Please add Start and End Time');
                    // }
                } else if (data?.rcFrequencyPeriodCode === '20') {
                    if (data?.rcFrequencyDay === '') {
                        errors.rcFrequencyDay = 'This field is required';
                    }
                    // if (data?.frequencyRcTimeList.length === 0) {
                    //     toast.warning('Please add Start and End Time');
                    // }
                } else if (data?.rcFrequencyPeriodCode === '30') {
                    if (data?.rcFrequencyDay === '') {
                        errors.rcFrequencyDay = 'This field is required';
                    }
                    // if (data?.frequencyRcTimeList.length === 0) {
                    //     toast.warning('Please add Start and End Time');
                    // }
                } else {
                    if (data?.rcFrequencyDay === '') {
                        errors.rcFrequencyDay = 'This field is required';
                    }

                    if (data.rcFrequencyMonth?.length === 0) {
                        errors.rcFrequencyMonth = 'Please select Month.';
                    }
                    // if (data?.frequencyRcTimeList.length === 0) {
                    //     toast.warning('Please add Start and End Time');
                    // }
                }
            }
            if (data?.rewardTypeCode === '50') {
                if (!data?.glId) {
                    errors.glId = 'This field is required';
                }
                if (data?.expiryDateTime === '' || data?.expiryDateTime === null || data?.expiryDateTime === undefined) {
                    errors.expiryDateTime = 'This field is required';
                }
                if (data?.voucherTitle === '' || data?.voucherTitle === null || data?.voucherTitle === undefined) {
                    errors.voucherTitle = 'This field is required';
                }
                if (data?.voucherDescription === '' || data?.voucherDescription === null || data?.voucherDescription === undefined) {
                    errors.voucherDescription = 'This field is required';
                }
                if (!data?.cashBackType) {
                    errors.cashBackType = 'This field is required';
                }
                if (data?.cashBackType === 'P') {
                    if (data?.rcMaxDiscountAmount === '' || data?.rcMaxDiscountAmount === null || data?.rcMaxDiscountAmount === undefined) {
                        errors.rcMaxDiscountAmount = 'This field is required';
                    }
                    if (data?.rcAmount === null || data?.rcAmount === '' || data?.rcAmount === 0 || !/^[0-9]*$/.test(data.rcAmount)) {
                        errors.rcAmount = 'This field is required';
                    }
                    if (data?.rcAmount > 100) {
                        errors.rcAmount = 'Percentage must be 1 to 100';
                    }
                }
                if (data?.cashBackType === 'F') {
                    if (data?.rcAmount === null || data?.rcAmount === '' || data?.rcAmount === 0 || !/^[0-9]*$/.test(data.rcAmount)) {
                        errors.rcAmount = 'This field is required';
                    }
                }
            }
            if (data?.rewardTypeCode !== '50') {
                if (data?.rcFrequency === null || data?.rcFrequency === '' || data?.rcFrequency === 0 || !/^[0-9]*$/.test(data.rcFrequency)) {
                    errors.rcFrequency = 'Please enter frequency';
                }
            }
            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['campaignId'] = compaignIdData || campaignData?.campaignId;
            data['stepNo'] = '3';
            data['rcFrequency'] = data?.rcFrequency?.toString();

            if (data?.rewardTypeCode === '30') {
                data['rcPromoCode'] = promoCode;
            }

            if (data?.cashBackType === 'P') {
                data['rcMaxDiscountAmount'] = data?.rcMaxDiscountAmount;
            }

            if (data?.cashBackType !== 'P') {
                delete data['rcMaxDiscountAmount'];
            }

            if (data?.rewardTypeCode === '10' || data?.rewardTypeCode === '20' || data?.rewardTypeCode === '30') {
                // delete data['availableBalance'];
                // delete data['glName'];

                delete data['rcIncentives'];
                // delete data['rcFrequencyMonth'];
                // delete data['rcFrequencyDay'];
                // delete data['rcFrequencyDate'];

                if (data?.rcFrequencyPeriodCode === '10') {
                    delete data['rcFrequencyDate'];
                    delete data['rcFrequencyMonth'];
                    delete data['rcFrequencyDay'];
                } else if (data?.rcFrequencyPeriodCode === '20') {
                    delete data['rcFrequencyDate'];
                    delete data['rcFrequencyMonth'];
                }

                // delete data['frequencyRcTimeList'];
            } else if (data?.rewardTypeCode === '40') {
                delete data['availableBalance'];
                delete data['glName'];
                delete data['glId'];
                delete data['cashBackType'];
                delete data['rcPromoDisc'];
                delete data['rcMaxDiscountAmount'];

                data['rcIncentives'] = data?.rcIncentives?.map((itm) => {
                    return parseInt(itm);
                });

                if (data?.rcFrequencyPeriodCode === '10') {
                    delete data['rcFrequencyDate'];
                    delete data['rcFrequencyMonth'];
                    delete data['rcFrequencyDay'];
                } else if (data?.rcFrequencyPeriodCode === '20') {
                    delete data['rcFrequencyDate'];
                    delete data['rcFrequencyMonth'];
                }
            }

            data['glBalance'] = formik?.values?.availableBalance;

            data['expiryDateTime'] = formatDate(data?.expiryDateTime);

            delete data['availableBalance'];

            if (data?.rewardTypeCode != '30') {
                data['glId'] = glIdData?.accountId?.toString() || campaignData?.tblAccount?.accountId;
                delete data['rcPromoDisc'];
            }

            if (data?.rewardTypeCode !== '50') {
                delete data['expiryDateTime'];
                delete data['voucherDescription'];
                delete data['voucherTitle'];
            }
            if (data?.rewardTypeCode === '50') {
                delete data['frequencyRcTimeList'];
            }

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            if (parseInt(campaignData?.currentStep) >= 3) {
                const newArray = data?.frequencyRcTimeList?.map((item) => ({
                    startTime: item.startTime,
                    endTime: item.endTime
                }));

                data['frequencyRcTimeList'] = newArray;
            }

            setStepData4(data);
            handleStepClick(activeIndex + 1);

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    useEffect(() => {
        if (formik?.values?.rewardTypeCode === '40') {
            getIncentiveTypeLov();
        }
    }, [formik?.values?.rewardTypeCode]); // eslint-disable-line

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const addfrequencyRcTimeList = () => {
        formik.setFieldValue('frequencyRcTimeList', [
            ...formik.values.frequencyRcTimeList,
            {
                startTime: '',
                endTime: ''
            }
        ]);
    };

    const removefrequencyRcTimeList = (index) => {
        if (index > 0) {
            const updatedData = [...formik.values.frequencyRcTimeList];
            updatedData.splice(index, 1);
            formik.setFieldValue('frequencyRcTimeList', updatedData);
        }
        if (index === 0) {
            const updatedData = [...formik.values.frequencyRcTimeList];
            formik.setFieldValue('frequencyRcTimeList', updatedData);
        }
    };

    const cashbackTypeLov = [
        {
            name: 'Fixed Amount',
            lovId: 'F'
        },
        {
            name: 'Percentage Amount',
            lovId: 'P'
        }
    ];

    const discountTypeLov = [
        {
            name: 'Fixed Amount',
            lovId: 'F'
        },
        {
            name: 'Percentage Amount',
            lovId: 'P'
        }
    ];

    const weekDays = [
        { name: 'Monday', lovId: 'Monday' },
        { name: 'Tuesday', lovId: 'Tuesday' },
        { name: 'Wednesday', lovId: 'Wednesday' },
        { name: 'Thursday', lovId: 'Thursday' },
        { name: 'Friday', lovId: 'Friday' },
        { name: 'Saturday', lovId: 'Saturday' },
        { name: 'Sunday', lovId: 'Sunday' }
    ];

    const Dates = [
        { name: '1', lovId: '1' },
        { name: '2', lovId: '2' },
        { name: '3', lovId: '3' },
        { name: '4', lovId: '4' },
        { name: '5', lovId: '5' },
        { name: '6', lovId: '6' },
        { name: '7', lovId: '7' },
        { name: '8', lovId: '8' },
        { name: '9', lovId: '9' },
        { name: '10', lovId: '10' },
        { name: '11', lovId: '11' },
        { name: '12', lovId: '12' },
        { name: '13', lovId: '13' },
        { name: '14', lovId: '14' },
        { name: '15', lovId: '15' },
        { name: '16', lovId: '16' },
        { name: '17', lovId: '17' },
        { name: '18', lovId: '18' },
        { name: '19', lovId: '19' },
        { name: '20', lovId: '20' },
        { name: '21', lovId: '21' },
        { name: '22', lovId: '22' },
        { name: '23', lovId: '23' },
        { name: '24', lovId: '24' },
        { name: '25', lovId: '25' },
        { name: '26', lovId: '26' },
        { name: '27', lovId: '27' },
        { name: '28', lovId: '28' },
        { name: '29', lovId: '29' },
        { name: '30', lovId: '30' },
        { name: '31', lovId: '31' }
    ];

    const monthsLov = [
        { name: 'January', lovId: 'January' },
        { name: 'February', lovId: 'February' },
        { name: 'March', lovId: 'March' },
        { name: 'April', lovId: 'April' },
        { name: 'May', lovId: 'May' },
        { name: 'June', lovId: 'June' },
        { name: 'July', lovId: 'July' },
        { name: 'August', lovId: 'August' },
        { name: 'September', lovId: 'September' },
        { name: 'October', lovId: 'October' },
        { name: 'November', lovId: 'November' },
        { name: 'December', lovId: 'December' }
    ];

    const getCurrentDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const day = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>Reward Configurations</h1>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="rewardTypeCode" className={classNames({ 'p-error': isFormFieldValid('rewardTypeCode') }, 'Label__Text')}>
                                    Reward Type<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="rewardTypeCode"
                                    placeholder="Select Reward Type"
                                    options={rewardConfigurationLov}
                                    optionLabel="name"
                                    name="rewardTypeCode"
                                    optionValue="code"
                                    value={formik?.values?.rewardTypeCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('rewardTypeCode') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('rewardTypeCode')}
                            </div>
                        </div>
                    </div>
                    {formik?.values?.rewardTypeCode === '10' && (
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-2 pb-2">
                                <div className="flex">
                                    <div className="col-8">
                                        <label htmlFor="glId" className={classNames({ 'p-error': isFormFieldValid('glId') }, 'Label__Text')}>
                                            GL Account<span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="glId"
                                            placeholder="Enter GL Account"
                                            name="glId"
                                            value={formik?.values?.glId || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('glId') }, 'Input__Round')}
                                        />
                                        {/* {getFormErrorMessage('glId')} */}
                                        <small className="p-error">{formik.errors.glId}</small>
                                    </div>
                                    <div className="col-4 Fetch__Btn">
                                        <Button
                                            disabled={loadingCashback}
                                            icon={loadingIconCashback || ''}
                                            label="Fetch"
                                            className="Btn__Fetch"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                getGlId();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="glName" className={classNames({ 'p-error': isFormFieldValid('glName') }, 'Label__Text')}>
                                        GL Name
                                    </label>
                                    <InputText
                                        id="glName"
                                        placeholder="Enter GL Name"
                                        name="glName"
                                        disabled={true}
                                        value={glIdData?.accountTitle || formik?.values?.glName}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('glName') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('glName')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="availableBalance" className={classNames({ 'p-error': isFormFieldValid('availableBalance') }, 'Label__Text')}>
                                        Available Balance
                                    </label>
                                </div>
                                <InputNumber
                                    inputId="availableBalance"
                                    name="availableBalance"
                                    maxLength={9}
                                    placeholder="Enter Available Balanace"
                                    disabled={true}
                                    value={glIdData?.actualBalance || formik?.values?.availableBalance || 0}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('availableBalance') }, 'Input_Number')}
                                />
                                {getFormErrorMessage('availableBalance')}
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="cashBackType" className={classNames({ 'p-error': isFormFieldValid('cashBackType') }, 'Label__Text')}>
                                        Cashback Type<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="cashBackType"
                                        placeholder="Select Cashback Type"
                                        options={cashbackTypeLov}
                                        optionLabel="name"
                                        name="cashBackType"
                                        optionValue="lovId"
                                        value={formik?.values?.cashBackType || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cashBackType') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('cashBackType')}
                                </div>
                            </div>
                            {formik?.values?.cashBackType === 'F' && (
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="rcAmount" className={classNames({ 'p-error': isFormFieldValid('rcAmount') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                    </div>
                                    <InputNumber
                                        inputId="rcAmount"
                                        name="rcAmount"
                                        maxLength={9}
                                        placeholder="Enter Amount"
                                        value={formik?.values?.rcAmount || ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('rcAmount') }, 'Input_Number')}
                                    />
                                    {/* {getFormErrorMessage('rcAmount')} */}
                                    <small className="p-error">{formik.errors.rcAmount}</small>
                                </div>
                            )}
                            {formik?.values?.cashBackType === 'P' && (
                                <>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="rcAmount" className={classNames({ 'p-error': isFormFieldValid('rcAmount') }, 'Label__Text')}>
                                                Discount Percentage
                                            </label>
                                        </div>
                                        <InputNumber
                                            inputId="rcAmount"
                                            name="rcAmount"
                                            maxLength={3}
                                            placeholder="Enter Amount"
                                            value={formik?.values?.rcAmount || ''}
                                            onValueChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rcAmount') }, 'Input_Number')}
                                        />
                                        {/* {getFormErrorMessage('rcAmount')} */}
                                        <small className="p-error">{formik.errors.rcAmount}</small>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="rcMaxDiscountAmount" className={classNames({ 'p-error': isFormFieldValid('rcMaxDiscountAmount') }, 'Label__Text')}>
                                                Maximum Discount Amount
                                            </label>
                                            <InputText
                                                id="rcMaxDiscountAmount"
                                                placeholder="Enter Maximum Discount Amount"
                                                name="rcMaxDiscountAmount"
                                                value={formik?.values?.rcMaxDiscountAmount || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('rcMaxDiscountAmount') }, 'Input__Round')}
                                            />
                                            {/* {getFormErrorMessage('rcMaxDiscountAmount')} */}
                                            <small className="p-error">{formik.errors.rcMaxDiscountAmount}</small>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="rcFrequencyPeriodCode" className={classNames({ 'p-error': isFormFieldValid('rcFrequencyPeriodCode') }, 'Label__Text')}>
                                        Frequency Period<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="rcFrequencyPeriodCode"
                                        placeholder="Select Frequency Period"
                                        options={frequencyPeriodLov}
                                        optionLabel="name"
                                        name="rcFrequencyPeriodCode"
                                        optionValue="code"
                                        value={formik?.values?.rcFrequencyPeriodCode || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('rcFrequencyPeriodCode') }, 'Dropdown__Round')}
                                    />
                                    {/* {getFormErrorMessage('rcFrequencyPeriodCode')} */}
                                    <small className="p-error">{formik.errors.rcFrequencyPeriodCode}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="rcFrequency" className={classNames({ 'p-error': isFormFieldValid('rcFrequency') }, 'Label__Text')}>
                                        Frequency
                                    </label>
                                </div>
                                <InputNumber
                                    inputId="rcFrequency"
                                    name="rcFrequency"
                                    maxLength={9}
                                    placeholder="Enter Frequency"
                                    value={formik?.values?.rcFrequency || ''}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('rcFrequency') }, 'Input_Number')}
                                />
                                {getFormErrorMessage('rcFrequency')}
                            </div>
                        </div>
                    )}

                    {formik?.values?.rewardTypeCode === '20' && (
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-2 pb-2">
                                <div className="flex">
                                    <div className="col-8">
                                        <label htmlFor="glId" className={classNames({ 'p-error': isFormFieldValid('glId') }, 'Label__Text')}>
                                            GL Account<span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="glId"
                                            placeholder="Enter GL Account"
                                            name="glId"
                                            value={formik?.values?.glId || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('glId') }, 'Input__Round')}
                                        />
                                        <small className="p-error">{formik.errors.glId}</small>
                                    </div>
                                    <div className="col-4 Fetch__Btn">
                                        <Button
                                            label="Fetch"
                                            className="Btn__Fetch"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                getGlId();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="glName" className={classNames({ 'p-error': isFormFieldValid('glName') }, 'Label__Text')}>
                                        GL Name
                                    </label>
                                    <InputText
                                        id="glName"
                                        placeholder="Enter GL Name"
                                        name="glName"
                                        disabled={true}
                                        // value={glIdData?.accountTitle || ''}
                                        value={glIdData?.accountTitle || formik?.values?.glName}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('glName') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('glName')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="availableBalance" className={classNames({ 'p-error': isFormFieldValid('availableBalance') }, 'Label__Text')}>
                                        Available Balance
                                    </label>
                                </div>
                                <InputNumber
                                    inputId="availableBalance"
                                    name="availableBalance"
                                    maxLength={9}
                                    placeholder="Enter Available Balance"
                                    disabled={true}
                                    // value={glIdData?.actualBalance || ''}
                                    value={glIdData?.availableBalance || formik?.values?.availableBalance}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('availableBalance') }, 'Input_Number')}
                                />
                                {getFormErrorMessage('availableBalance')}
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="cashBackType" className={classNames({ 'p-error': isFormFieldValid('cashBackType') }, 'Label__Text')}>
                                        Discount Type
                                    </label>
                                    <Dropdown
                                        id="cashBackType"
                                        placeholder="Select Discount Type"
                                        options={discountTypeLov}
                                        optionLabel="name"
                                        name="cashBackType"
                                        optionValue="lovId"
                                        value={formik?.values?.cashBackType || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cashBackType') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('cashBackType')}
                                </div>
                            </div>
                            {formik?.values?.cashBackType === 'F' && (
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="rcAmount" className={classNames({ 'p-error': isFormFieldValid('rcAmount') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                    </div>
                                    <InputNumber
                                        inputId="rcAmount"
                                        name="rcAmount"
                                        maxLength={9}
                                        placeholder="Enter Amount"
                                        value={formik?.values?.rcAmount || ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('rcAmount') }, 'Input_Number')}
                                    />
                                    {/* {getFormErrorMessage('rcAmount')} */}
                                    <small className="p-error">{formik.errors.rcAmount}</small>
                                </div>
                            )}
                            {formik?.values?.cashBackType === 'P' && (
                                <>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="rcAmount" className={classNames({ 'p-error': isFormFieldValid('rcAmount') }, 'Label__Text')}>
                                                Discount Percentage
                                            </label>
                                        </div>
                                        <InputNumber
                                            inputId="rcAmount"
                                            name="rcAmount"
                                            maxLength={3}
                                            placeholder="Enter Amount"
                                            value={formik?.values?.rcAmount || ''}
                                            onValueChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rcAmount') }, 'Input_Number')}
                                        />
                                        {/* {getFormErrorMessage('rcAmount')} */}
                                        <small className="p-error">{formik.errors.rcAmount}</small>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="rcMaxDiscountAmount" className={classNames({ 'p-error': isFormFieldValid('rcMaxDiscountAmount') }, 'Label__Text')}>
                                                Maximum Discount Amount
                                            </label>
                                            <InputText
                                                id="rcMaxDiscountAmount"
                                                placeholder="Enter Maximum Discount Amount"
                                                name="rcMaxDiscountAmount"
                                                value={formik?.values?.rcMaxDiscountAmount || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('rcMaxDiscountAmount') }, 'Input__Round')}
                                            />
                                            {/* {getFormErrorMessage('rcMaxDiscountAmount')} */}
                                            <small className="p-error">{formik.errors.rcMaxDiscountAmount}</small>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="rcFrequencyPeriodCode" className={classNames({ 'p-error': isFormFieldValid('rcFrequencyPeriodCode') }, 'Label__Text')}>
                                        Frequency Period
                                    </label>
                                    <Dropdown
                                        id="rcFrequencyPeriodCode"
                                        placeholder="Select Frequency Period"
                                        options={frequencyPeriodLov}
                                        optionLabel="name"
                                        name="rcFrequencyPeriodCode"
                                        optionValue="code"
                                        value={formik?.values?.rcFrequencyPeriodCode || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('rcFrequencyPeriodCode') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('rcFrequencyPeriodCode')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="rcFrequency" className={classNames({ 'p-error': isFormFieldValid('rcFrequency') }, 'Label__Text')}>
                                        Frequency
                                    </label>
                                </div>
                                <InputNumber
                                    inputId="rcFrequency"
                                    name="rcFrequency"
                                    maxLength={9}
                                    placeholder="Enter Frequency"
                                    value={formik?.values?.rcFrequency || ''}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('rcFrequency') }, 'Input_Number')}
                                />
                                {getFormErrorMessage('rcFrequency')}
                            </div>
                        </div>
                    )}
                    {formik?.values?.rewardTypeCode === '30' && (
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="rcPromoDisc" className={classNames({ 'p-error': isFormFieldValid('rcPromoDisc') }, 'Label__Text')}>
                                        Description
                                    </label>
                                    <InputText
                                        id="rcPromoDisc"
                                        placeholder="Enter Description"
                                        name="rcPromoDisc"
                                        value={formik?.values?.rcPromoDisc || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('rcPromoDisc') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('rcPromoDisc')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="cashBackType" className={classNames({ 'p-error': isFormFieldValid('cashBackType') }, 'Label__Text')}>
                                        Discount Type
                                    </label>
                                    <Dropdown
                                        id="cashBackType"
                                        placeholder="Select Discount Type"
                                        options={discountTypeLov}
                                        optionLabel="name"
                                        name="cashBackType"
                                        optionValue="lovId"
                                        disabled={promoCode && formik?.values?.rewardTypeCode === '30' ? true : false}
                                        value={formik?.values?.cashBackType || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cashBackType') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('cashBackType')}
                                </div>
                            </div>
                            {formik?.values?.cashBackType === 'F' && (
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="rcAmount" className={classNames({ 'p-error': isFormFieldValid('rcAmount') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                    </div>
                                    <InputNumber
                                        inputId="rcAmount"
                                        name="rcAmount"
                                        maxLength={9}
                                        placeholder="Enter Amount"
                                        disabled={promoCode && formik?.values?.rewardTypeCode === '30' ? true : false}
                                        value={formik?.values?.rcAmount || ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('rcAmount') }, 'Input_Number')}
                                    />
                                    {/* {getFormErrorMessage('rcAmount')} */}
                                </div>
                            )}
                            {formik?.values?.cashBackType === 'P' && (
                                <>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="rcAmount" className={classNames({ 'p-error': isFormFieldValid('rcAmount') }, 'Label__Text')}>
                                                Discount Percentage
                                            </label>
                                        </div>
                                        <InputNumber
                                            inputId="rcAmount"
                                            name="rcAmount"
                                            maxLength={3}
                                            placeholder="Enter Amount"
                                            value={formik?.values?.rcAmount || ''}
                                            onValueChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rcAmount') }, 'Input_Number')}
                                        />
                                        <small className="p-error">{formik.errors.rcAmount}</small>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <div htmlFor="rcMaxDiscountAmount" className={classNames({ 'p-error': isFormFieldValid('rcMaxDiscountAmount') }, 'Label__Text')}>
                                                Maximum Discount Amount
                                            </div>
                                            <InputText
                                                id="rcMaxDiscountAmount"
                                                placeholder="Enter Maximum Discount Amount"
                                                name="rcMaxDiscountAmount"
                                                disabled={promoCode && formik?.values?.rewardTypeCode === '30' ? true : false}
                                                value={formik?.values?.rcMaxDiscountAmount || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('rcMaxDiscountAmount') }, 'Input__Round')}
                                            />
                                            {/* {getFormErrorMessage('rcAmount')} */}
                                            <small className="p-error">{formik.errors.rcMaxDiscountAmount}</small>
                                        </div>
                                    </div>
                                </>
                            )}
                            {promoCode && (
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="promoCode" className={classNames({ 'p-error': isFormFieldValid('promoCode') }, 'Label__Text')}>
                                            Promo Code
                                        </label>
                                        <InputText id="promoCode" name="promoCode" disabled={true} value={promoCode || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('promoCode') }, 'Input__Round')} />
                                        {getFormErrorMessage('promoCode')}
                                    </div>
                                </div>
                            )}
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="">
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            generatePromoCode();
                                        }}
                                        disabled={promoCode ? true : false}
                                        label="Generate Promo Code"
                                        className="Btn__PromoCode"
                                    />
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="rcFrequencyPeriodCode" className={classNames({ 'p-error': isFormFieldValid('rcFrequencyPeriodCode') }, 'Label__Text')}>
                                        Frequency Period
                                    </label>
                                    <Dropdown
                                        id="rcFrequencyPeriodCode"
                                        placeholder="Select Frequency Period"
                                        options={frequencyPeriodLov}
                                        optionLabel="name"
                                        name="rcFrequencyPeriodCode"
                                        optionValue="code"
                                        value={formik?.values?.rcFrequencyPeriodCode || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('rcFrequencyPeriodCode') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('rcFrequencyPeriodCode')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="rcFrequency" className={classNames({ 'p-error': isFormFieldValid('rcFrequency') }, 'Label__Text')}>
                                        Frequency
                                    </label>
                                </div>
                                <InputNumber
                                    inputId="rcFrequency"
                                    name="rcFrequency"
                                    maxLength={9}
                                    placeholder="Enter Frequency"
                                    value={formik?.values?.rcFrequency || ''}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('rcFrequency') }, 'Input_Number')}
                                />
                                {getFormErrorMessage('rcFrequency')}
                            </div>
                        </div>
                    )}
                    {formik?.values?.rewardTypeCode === '40' && (
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="rcIncentives" className={classNames({ 'p-error': isFormFieldValid('rcIncentives') }, 'Label__Text')}>
                                        Select Incentive <span className="Label__Required">*</span>
                                    </label>
                                    <MultiSelect
                                        id="rcIncentives"
                                        placeholder="Select Incentive"
                                        options={incentiveTypeLov}
                                        optionLabel="name"
                                        name="rcIncentives"
                                        optionValue="lovId"
                                        // panelFooterTemplate={panelTransactionsTypeFooterTemplate}
                                        // value={formik?.values?.rcIncentives.map((trans) => trans.lovId || '')}
                                        // onChange={(e) => {
                                        //     formik.setFieldValue(
                                        //         'rcIncentives',
                                        //         e.value.map((lovId) => ({
                                        //             lovId
                                        //         }))
                                        //     );
                                        // }}
                                        value={formik?.values?.rcIncentives || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('rcIncentives') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('rcIncentives')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="rcAmount" className={classNames({ 'p-error': isFormFieldValid('rcAmount') }, 'Label__Text')}>
                                        Amount
                                    </label>
                                </div>
                                <InputNumber
                                    inputId="rcAmount"
                                    name="rcAmount"
                                    maxLength={9}
                                    placeholder="Enter Amount"
                                    value={formik?.values?.rcAmount || ''}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('rcAmount') }, 'Input_Number')}
                                />
                                {/* {getFormErrorMessage('rcAmount')} */}
                                <small className="p-error">{formik.errors.rcAmount}</small>
                            </div>
                            {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="rcIncentiveCode" className={classNames({ 'p-error': isFormFieldValid('rcIncentiveCode') }, 'Label__Text')}>
                                            Select Incentive
                                        </label>
                                        <Dropdown
                                            id="rcIncentiveCode"
                                            placeholder="Select Incentive"
                                            options={incentiveTypeLov}
                                            optionLabel="name"
                                            name="rcIncentiveCode"
                                            optionValue="code"
                                            value={formik?.values?.rcIncentiveCode || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rcIncentiveCode') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('rcIncentiveCode')}
                                    </div>
                                </div> */}

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="rcFrequencyPeriodCode" className={classNames({ 'p-error': isFormFieldValid('rcFrequencyPeriodCode') }, 'Label__Text')}>
                                        Frequency Period
                                    </label>
                                    <Dropdown
                                        id="rcFrequencyPeriodCode"
                                        placeholder="Select Frequency Period"
                                        options={frequencyPeriodLov}
                                        optionLabel="name"
                                        name="rcFrequencyPeriodCode"
                                        optionValue="code"
                                        value={formik?.values?.rcFrequencyPeriodCode || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('rcFrequencyPeriodCode') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('rcFrequencyPeriodCode')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="rcFrequency" className={classNames({ 'p-error': isFormFieldValid('rcFrequency') }, 'Label__Text')}>
                                        Frequency
                                    </label>
                                </div>
                                <InputNumber
                                    inputId="rcFrequency"
                                    name="rcFrequency"
                                    maxLength={9}
                                    placeholder="Enter Frequency"
                                    value={formik?.values?.rcFrequency || ''}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('rcFrequency') }, 'Input_Number')}
                                />
                                {getFormErrorMessage('rcFrequency')}
                            </div>
                        </div>
                    )}
                    {formik?.values?.rewardTypeCode === '50' && (
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-2 pb-2">
                                <div className="flex">
                                    <div className="col-8">
                                        <label htmlFor="glId" className={classNames({ 'p-error': isFormFieldValid('glId') }, 'Label__Text')}>
                                            GL Account<span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="glId"
                                            placeholder="Enter GL Account"
                                            name="glId"
                                            value={formik?.values?.glId || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('glId') }, 'Input__Round')}
                                        />
                                        <small className="p-error">{formik.errors.glId}</small>
                                    </div>
                                    <div className="col-4 Fetch__Btn">
                                        <Button
                                            disabled={loadingCashback}
                                            icon={loadingIconCashback || ''}
                                            label="Fetch"
                                            className="Btn__Fetch"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                getGlId();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="glName" className={classNames({ 'p-error': isFormFieldValid('glName') }, 'Label__Text')}>
                                        GL Name
                                    </label>
                                    <InputText
                                        id="glName"
                                        placeholder="Enter GL Name"
                                        name="glName"
                                        disabled={true}
                                        // value={glIdData?.accountTitle || ''}
                                        value={glIdData?.accountTitle || formik?.values?.glName}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('glName') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('glName')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="availableBalance" className={classNames({ 'p-error': isFormFieldValid('availableBalance') }, 'Label__Text')}>
                                        Available Balance
                                    </label>
                                </div>
                                <InputNumber
                                    inputId="availableBalance"
                                    name="availableBalance"
                                    maxLength={9}
                                    placeholder="Enter Available Balanace"
                                    disabled={true}
                                    // value={glIdData?.actualBalance || ''}
                                    value={glIdData?.availableBalance || formik?.values?.availableBalance}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('availableBalance') }, 'Input_Number')}
                                />
                                {getFormErrorMessage('availableBalance')}
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="voucherTitle" className={classNames({ 'p-error': isFormFieldValid('voucherTitle') }, 'Label__Text')}>
                                        Voucher Title
                                    </label>
                                    <InputText
                                        id="voucherTitle"
                                        placeholder="Enter Voucher Title"
                                        name="voucherTitle"
                                        value={formik?.values?.voucherTitle || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('voucherTitle') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{formik.errors.voucherTitle}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="expiryDateTime" className={classNames({ 'p-error': isFormFieldValid('expiryDateTime') }, 'Label__Text')}>
                                        Expiry Date & Time <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="expiryDateTime"
                                        placeholder="Enter Campaign Start Date & Time"
                                        name="expiryDateTime"
                                        type="datetime-local"
                                        min={getCurrentDateTime()}
                                        value={formik?.values?.expiryDateTime || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('expiryDateTime') }, 'Input__Round')}
                                    />

                                    <small className="p-error">{formik.errors.expiryDateTime}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="voucherDescription" className={classNames({ 'p-error': isFormFieldValid('voucherDescription') }, 'Label__Text')}>
                                        Voucher Description
                                    </label>
                                    <InputText
                                        id="voucherDescription"
                                        placeholder="Enter Voucher Description"
                                        name="voucherDescription"
                                        value={formik?.values?.voucherDescription || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('voucherDescription') }, 'Input__Round')}
                                    />

                                    <small className="p-error">{formik.errors.voucherDescription}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="cashBackType" className={classNames({ 'p-error': isFormFieldValid('cashBackType') }, 'Label__Text')}>
                                        Discount Type<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="cashBackType"
                                        placeholder="Select Discount Type"
                                        options={cashbackTypeLov}
                                        optionLabel="name"
                                        name="cashBackType"
                                        optionValue="lovId"
                                        value={formik?.values?.cashBackType || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cashBackType') }, 'Dropdown__Round')}
                                    />

                                    <small className="p-error">{formik.errors.cashBackType}</small>
                                </div>
                            </div>
                            {formik?.values?.cashBackType === 'F' && (
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="rcAmount" className={classNames({ 'p-error': isFormFieldValid('rcAmount') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                    </div>
                                    <InputNumber
                                        inputId="rcAmount"
                                        name="rcAmount"
                                        maxLength={9}
                                        placeholder="Enter Amount"
                                        value={formik?.values?.rcAmount || ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('rcAmount') }, 'Input_Number')}
                                    />
                                    <small className="p-error">{formik.errors.rcAmount}</small>
                                </div>
                            )}
                            {formik?.values?.cashBackType === 'P' && (
                                <>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="rcAmount" className={classNames({ 'p-error': isFormFieldValid('rcAmount') }, 'Label__Text')}>
                                                Discount Percentage
                                            </label>
                                        </div>
                                        <InputNumber
                                            inputId="rcAmount"
                                            name="rcAmount"
                                            maxLength={9}
                                            placeholder="Enter Amount"
                                            value={formik?.values?.rcAmount || ''}
                                            onValueChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rcAmount') }, 'Input_Number')}
                                        />
                                        <small className="p-error">{formik.errors.rcAmount}</small>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="rcMaxDiscountAmount" className={classNames({ 'p-error': isFormFieldValid('rcMaxDiscountAmount') }, 'Label__Text')}>
                                                Maximum Discount Amount
                                            </label>
                                            <InputText
                                                id="rcMaxDiscountAmount"
                                                placeholder="Enter Maximum Discount Amount"
                                                name="rcMaxDiscountAmount"
                                                value={formik?.values?.rcMaxDiscountAmount || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('rcMaxDiscountAmount') }, 'Input__Round')}
                                            />
                                            <small className="p-error">{formik.errors.rcMaxDiscountAmount}</small>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                    {formik?.values?.rcFrequencyPeriodCode === '10' && formik?.values?.rewardTypeCode !== '50' && (
                        <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card flex">
                            <div className="p-fluid p-formgrid grid mb-5 w-100">
                                {formik?.values?.frequencyRcTimeList?.map((startEndTimeDaily, index) => {
                                    return (
                                        <div className="col-12 md:col-6 flex-direction-col md:flex" key={`rcd-${index}`}>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor={`frequencyRcTimeList[${index}].startTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyRcTimeList[${index}].startTime`) }, 'Label__Text')}>
                                                        Start Time
                                                    </label>
                                                    <InputText
                                                        id={`frequencyRcTimeList[${index}].startTime`}
                                                        type="time"
                                                        name={`frequencyRcTimeList[${index}].startTime`}
                                                        value={startEndTimeDaily?.startTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`frequencyRcTimeList[${index}].startTime`) }, 'Input__Round')}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    <small className="p-error">{formik?.errors?.frequencyRcTimeList !== undefined ? formik?.errors?.frequencyRcTimeList[index]?.startTime : null}</small>
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor={`frequencyRcTimeList[${index}].endTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyRcTimeList[${index}].endTime`) }, 'Label__Text')}>
                                                        End Time
                                                    </label>
                                                    <InputText
                                                        id={`frequencyRcTimeList[${index}].endTime`}
                                                        type="time"
                                                        name={`frequencyRcTimeList[${index}].endTime`}
                                                        value={startEndTimeDaily?.endTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`frequencyRcTimeList[${index}].endTime`) }, 'Input__Round')}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    <small className="p-error">{formik?.errors?.frequencyRcTimeList !== undefined ? formik?.errors?.frequencyRcTimeList[index]?.endTime : null}</small>
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-2 flex align-items-center pt-3 pb-3">
                                                <Button
                                                    icon="pi pi-plus"
                                                    className="p-button-rounded mt-2"
                                                    aria-label="Filter"
                                                    tooltip="Add New Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addfrequencyRcTimeList();
                                                    }}
                                                />
                                                <Button
                                                    icon="pi pi-minus"
                                                    className="p-button-rounded mt-2 p-button-danger"
                                                    aria-label="Filter"
                                                    tooltip="Remove Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        removefrequencyRcTimeList(index);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    {formik?.values?.rcFrequencyPeriodCode === '20' && formik?.values?.rewardTypeCode !== '50' && (
                        <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card flex">
                            <div className="p-fluid p-formgrid grid mb-5 w-100">
                                {formik?.values?.frequencyRcTimeList?.map((startEndTimeWeekly, index) => {
                                    return (
                                        <div className="col-12 md:col-6 flex-direction-col md:flex" key={`rcw-${index}`}>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor={`frequencyRcTimeList[${index}].startTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyRcTimeList[${index}].startTime`) }, 'Label__Text')}>
                                                        Start Time
                                                    </label>
                                                    <InputText
                                                        id={`frequencyRcTimeList[${index}].startTime`}
                                                        type="time"
                                                        name={`frequencyRcTimeList[${index}].startTime`}
                                                        value={startEndTimeWeekly?.startTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`frequencyRcTimeList[${index}].startTime`) }, 'Input__Round')}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    <small className="p-error">{formik?.errors?.frequencyRcTimeList !== undefined ? formik?.errors?.frequencyRcTimeList[index]?.startTime : null}</small>
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor={`frequencyRcTimeList[${index}].endTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyRcTimeList[${index}].endTime`) }, 'Label__Text')}>
                                                        End Time
                                                    </label>
                                                    <InputText
                                                        id={`frequencyRcTimeList[${index}].endTime`}
                                                        type="time"
                                                        name={`frequencyRcTimeList[${index}].endTime`}
                                                        value={startEndTimeWeekly?.endTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`frequencyRcTimeList[${index}].endTime`) }, 'Input__Round')}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    <small className="p-error">{formik?.errors?.frequencyRcTimeList !== undefined ? formik?.errors?.frequencyRcTimeList[index]?.endTime : null}</small>
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-2 flex align-items-center pt-3 pb-3">
                                                <Button
                                                    icon="pi pi-plus"
                                                    className="p-button-rounded mt-2"
                                                    aria-label="Filter"
                                                    tooltip="Add New Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addfrequencyRcTimeList();
                                                    }}
                                                />
                                                <Button
                                                    icon="pi pi-minus"
                                                    className="p-button-rounded mt-2 p-button-danger"
                                                    aria-label="Filter"
                                                    tooltip="Remove Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        removefrequencyRcTimeList(index);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="rcFrequencyDay" className={classNames({ 'p-error': isFormFieldValid('rcFrequencyDay') }, 'Label__Text')}>
                                            Days
                                        </label>
                                        <MultiSelect
                                            id="rcFrequencyDay"
                                            placeholder="Select Days"
                                            options={weekDays}
                                            optionLabel="name"
                                            name="rcFrequencyDay"
                                            optionValue="lovId"
                                            value={formik.values.rcFrequencyDay || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rcFrequencyDay') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{formik?.errors?.rcFrequencyDay}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {formik?.values?.rcFrequencyPeriodCode === '30' && formik?.values?.rewardTypeCode !== '50' && (
                        <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card flex">
                            <div className="p-fluid p-formgrid grid mb-5 w-100">
                                {formik?.values?.frequencyRcTimeList?.map((startEndTimeMonthly, index) => {
                                    return (
                                        <div className="col-12 md:col-6 flex-direction-col md:flex" key={`rcm-${index}`}>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor={`frequencyRcTimeList[${index}].startTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyRcTimeList[${index}].startTime`) }, 'Label__Text')}>
                                                        Start Time
                                                    </label>
                                                    <InputText
                                                        id={`frequencyRcTimeList[${index}].startTime`}
                                                        type="time"
                                                        name={`frequencyRcTimeList[${index}].startTime`}
                                                        value={startEndTimeMonthly?.startTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`frequencyRcTimeList[${index}].startTime`) }, 'Input__Round')}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    <small className="p-error">{formik?.errors?.frequencyRcTimeList !== undefined ? formik?.errors?.frequencyRcTimeList[index]?.startTime : null}</small>
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor={`frequencyRcTimeList[${index}].endTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyRcTimeList[${index}].endTime`) }, 'Label__Text')}>
                                                        End Time
                                                    </label>
                                                    <InputText
                                                        id={`frequencyRcTimeList[${index}].endTime`}
                                                        type="time"
                                                        name={`frequencyRcTimeList[${index}].endTime`}
                                                        value={startEndTimeMonthly?.endTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`frequencyRcTimeList[${index}].endTime`) }, 'Input__Round')}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    <small className="p-error">{formik?.errors?.frequencyRcTimeList !== undefined ? formik?.errors?.frequencyRcTimeList[index]?.endTime : null}</small>
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-2 flex align-items-center pt-3 pb-3">
                                                <Button
                                                    icon="pi pi-plus"
                                                    className="p-button-rounded mt-2"
                                                    aria-label="Filter"
                                                    tooltip="Add New Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addfrequencyRcTimeList();
                                                    }}
                                                />
                                                <Button
                                                    icon="pi pi-minus"
                                                    className="p-button-rounded mt-2 p-button-danger"
                                                    aria-label="Filter"
                                                    tooltip="Remove Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        removefrequencyRcTimeList(index);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="rcFrequencyDate" className={classNames({ 'p-error': isFormFieldValid('rcFrequencyDate') }, 'Label__Text')}>
                                            Date Of Month
                                        </label>
                                        <MultiSelect
                                            id="rcFrequencyDate"
                                            placeholder="Select Dates"
                                            options={Dates}
                                            optionLabel="name"
                                            name="rcFrequencyDate"
                                            optionValue="lovId"
                                            value={formik.values.rcFrequencyDate || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rcFrequencyDate') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{formik?.errors?.rcFrequencyDate}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {formik?.values?.rcFrequencyPeriodCode === '40' && formik?.values?.rewardTypeCode !== '50' && (
                        <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card flex">
                            <div className="p-fluid p-formgrid grid mb-5 w-100">
                                <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="rcFrequencyMonth" className={classNames({ 'p-error': isFormFieldValid('rcFrequencyMonth') }, 'Label__Text')}>
                                            Month
                                        </label>
                                        <MultiSelect
                                            id="rcFrequencyMonth"
                                            placeholder="Select Month"
                                            options={monthsLov}
                                            optionLabel="name"
                                            name="rcFrequencyMonth"
                                            optionValue="lovId"
                                            value={formik.values.rcFrequencyMonth || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rcFrequencyMonth') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{formik?.errors?.rcFrequencyMonth}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="rcFrequencyDate" className={classNames({ 'p-error': isFormFieldValid('rcFrequencyDate') }, 'Label__Text')}>
                                            Date Of Month
                                        </label>
                                        <MultiSelect
                                            id="rcFrequencyDate"
                                            placeholder="Select Dates"
                                            options={Dates}
                                            optionLabel="name"
                                            name="rcFrequencyDate"
                                            optionValue="lovId"
                                            value={formik.values.rcFrequencyDate || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('rcFrequencyDate') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{formik?.errors?.rcFrequencyDate}</small>
                                    </div>
                                </div>
                                {formik?.values?.frequencyRcTimeList?.map((startEndTimeMonthly, index) => {
                                    return (
                                        <div className="col-12 md:col-4 flex-direction-col md:flex" key={`rcy-${index}`}>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor={`frequencyRcTimeList[${index}].startTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyRcTimeList[${index}].startTime`) }, 'Label__Text')}>
                                                        Start Time
                                                    </label>
                                                    <InputText
                                                        id={`frequencyRcTimeList[${index}].startTime`}
                                                        type="time"
                                                        name={`frequencyRcTimeList[${index}].startTime`}
                                                        value={startEndTimeMonthly?.startTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`frequencyRcTimeList[${index}].startTime`) }, 'Input__Round')}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    <small className="p-error">{formik?.errors?.frequencyRcTimeList !== undefined ? formik?.errors?.frequencyRcTimeList[index]?.startTime : null}</small>
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor={`frequencyRcTimeList[${index}].endTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyRcTimeList[${index}].endTime`) }, 'Label__Text')}>
                                                        End Time
                                                    </label>
                                                    <InputText
                                                        id={`frequencyRcTimeList[${index}].endTime`}
                                                        type="time"
                                                        name={`frequencyRcTimeList[${index}].endTime`}
                                                        value={startEndTimeMonthly?.endTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`frequencyRcTimeList[${index}].endTime`) }, 'Input__Round')}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    <small className="p-error">{formik?.errors?.frequencyRcTimeList !== undefined ? formik?.errors?.frequencyRcTimeList[index]?.endTime : null}</small>
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4 flex align-items-center justify-content-evenly pt-3 pb-3">
                                                <Button
                                                    icon="pi pi-plus"
                                                    className="p-button-rounded mt-2"
                                                    aria-label="Filter"
                                                    tooltip="Add New Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addfrequencyRcTimeList();
                                                    }}
                                                />
                                                <Button
                                                    icon="pi pi-minus"
                                                    className="p-button-rounded mt-2 p-button-danger"
                                                    aria-label="Filter"
                                                    tooltip="Remove Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        removefrequencyRcTimeList(index);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    <div className="Down__Btn">
                        <Button label="Next" className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default UpdateRewardConfigurationCampaign;

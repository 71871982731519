import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { SlabWiseFixedInitialValues, validationSchema } from '../Components/SlabWiseFixedInitialValues';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import { MultiSelect } from 'primereact/multiselect';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewSlabWiseFixed() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');
    const [allLovs, setAllLovs] = useState('');
    const [transactions, setTransactions] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate()); // Subtract 1 day
    const previousDate = currentDate.toISOString().split('T')[0];

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const commissionId = query.get('commissionId');
    const tableName = 'TBL_COMMISSION_PROFILE';

    const getCommissionDataById = async () => {
        const res = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getcommissionprofilebyid/${commissionId}`);
        const keyData = res?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik?.setFieldValue(key, keyData[key]);
                }
            });

            const multiselectDataProduct = keyData?.tblCommissionProducts?.map((item) => {
                return item?.tblProduct?.productId.toString();
            });

            const commissionSlabRequests = keyData?.tblCommissionSlabs?.map((commissionData) => {
                return {
                    startSlab: commissionData?.startSlab?.toString() || '',
                    endSlab: commissionData?.endSlab?.toString() || '',
                    commissionAmount: commissionData?.commissionAmount?.toString() || ''
                };
            });

            formik.setFieldValue('disbursementMethodName', keyData?.lkpDisbursementMethod?.disbursementMethodName?.toString());
            formik.setFieldValue('accountClassificationId', keyData?.lkpAccountClassification?.accountClassificationId.toString());
            formik.setFieldValue('commissionGlAccountId', keyData?.tblAccount1.accountId.toString());
            formik.setFieldValue('whtGlAccountId', keyData?.tblAccount2?.accountId?.toString());
            formik.setFieldValue('startDate', keyData?.startDate?.slice(0, 10));
            formik.setFieldValue('endDate', keyData?.endDate?.slice(0, 10));

            if (commissionSlabRequests && commissionSlabRequests.length > 0) {
                formik.setFieldValue('commissionSlabRequests', commissionSlabRequests || []);
            }
            if (multiselectDataProduct && multiselectDataProduct.length > 0) {
                formik.setFieldValue('products', multiselectDataProduct);
            }
        }
    };
    useEffect(() => {
        getCommissionDataById();
    }, []); // eslint-disable-line

    const getAllLovsForCommission = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcommissionprofiledata');
        setAllLovs(res?.payLoad);
        setTransactions(res?.payLoad?.transactions);
    };

    useEffect(() => {
        getAllLovsForCommission();
    }, []); // eslint-disable-line

    const accountTypeLov = allLovs?.accounttype?.map((data) => {
        return {
            name: data?.name,
            lovId: data?.lovId
        };
    });

    const glAccountsLov = allLovs?.glAccounts?.map((data) => {
        return {
            accountNo: `${data?.accountNo}-${data?.accountTitle}`,
            accountId: data?.accountId
        };
    });

    const disbursementMethodLov = allLovs?.disbursementMethod?.map((data) => {
        return {
            name: data?.name,
            lovId: data?.lovId
        };
    });

    const WhtOption = [
        { name: 'INCLUSIVE', value: 'I' },
        { name: 'EXCLUSIVE', value: 'E' }
    ];

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: SlabWiseFixedInitialValues,

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['commissionProfileId'] = commissionId;
            data['feeTypeCode'] = 'SF';
            data['products'] = formik.values.products?.map((productId) => ({
                productId
            }));

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(newData, '/pricingprofile/v1/pricingprofile/updatecommission', true, false, 'commission'));
            if (res?.responseCode === '111301') {
                formik.resetForm();
            }
            setloading(false);
            setloadingIcon('');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const addSlabWiseFixed = () => {
        formik.setFieldValue('commissionSlabRequests', [
            ...formik.values.commissionSlabRequests,
            {
                startSlab: '',
                endSlab: '',
                commissionAmount: ''
            }
        ]);
    };
    const removeSlabWiseFixed = (index) => {
        const updatedData = [...formik.values.commissionSlabRequests];
        updatedData.splice(index, 1);
        formik.setFieldValue('commissionSlabRequests', updatedData);
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>VIEW DETAIL</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                    Account Type
                                </label>
                                <Dropdown
                                    id="accountClassificationId"
                                    placeholder="Select Account Type"
                                    options={accountTypeLov}
                                    optionLabel="name"
                                    name="accountClassificationId"
                                    optionValue="lovId"
                                    value={formik.values.accountClassificationId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountClassificationId') }, 'Dropdown__Round')}
                                    disabled={true}
                                />

                                {getFormErrorMessage('accountClassificationId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="commissionProfileName" className={classNames({ 'p-error': isFormFieldValid('commissionProfileName') }, 'Label__Text')}>
                                    Profile Name <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    id="commissionProfileName"
                                    placeholder="Enter Profile Name"
                                    name="commissionProfileName"
                                    value={formik?.values?.commissionProfileName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('commissionProfileName') }, 'Input__Round')}
                                    disabled={true}
                                />
                                {getFormErrorMessage('commissionProfileName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="products" className={classNames({ 'p-error': isFormFieldValid('products') }, 'Label__Text')}>
                                    Transactions
                                </label>
                                <MultiSelect
                                    filter
                                    id="products"
                                    placeholder="Select Transaction"
                                    options={transactions}
                                    optionLabel="name"
                                    name="products"
                                    optionValue="lovId"
                                    value={formik.values.products || []}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('products') }, 'Dropdown__Round')}
                                    // disabled={true}
                                />
                                {getFormErrorMessage('products')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="whtCalculationType" className={classNames({ 'p-error': isFormFieldValid('whtCalculationType') }, 'Label__Text')}>
                                    Type of WHT Calculation
                                    <span className="Label__Required"></span>
                                </label>
                                <Dropdown
                                    id="whtCalculationType"
                                    placeholder="Select Type of Fee Calculation"
                                    options={WhtOption}
                                    optionLabel="name"
                                    name="whtCalculationType"
                                    optionValue="value"
                                    value={formik.values.whtCalculationType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('whtCalculationType') }, 'Dropdown__Round')}
                                    disabled={true}
                                />
                                {getFormErrorMessage('whtCalculationType')}
                            </div>
                        </div>
                        <div className=" col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor={'startDate'} className={classNames({ 'p-error': isFormFieldValid('startDate') }, 'Label__Text')}>
                                    Start Date
                                </label>
                                <InputText
                                    min={previousDate}
                                    max="9999-12-31"
                                    type="date"
                                    id="startDate"
                                    name="startDate"
                                    value={formik.values.startDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('startDate') }, 'Input__Round')}
                                    disabled={true}
                                />
                                {getFormErrorMessage('startDate')}
                            </div>
                        </div>
                        <div className=" col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor={'endDate'} className={classNames({ 'p-error': isFormFieldValid('endDate') }, 'Label__Text')}>
                                    End Date
                                </label>
                                <InputText
                                    min={previousDate}
                                    max="9999-12-31"
                                    type="date"
                                    id="endDate"
                                    name="endDate"
                                    value={formik.values.endDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('endDate') }, 'Input__Round')}
                                    disabled={true}
                                />
                                {getFormErrorMessage('endDate')}
                            </div>
                        </div>
                        <>
                            <div id="card" style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card">
                                <div className="Form__Header">
                                    <h1>SLAB WISE FIXED </h1>
                                </div>
                                {formik?.values?.commissionSlabRequests?.map((id, index) => (
                                    <div key={`id-${index}`} style={{ backgroundColor: '#FFFFFF', padding: '3rem' }} className="card">
                                        <div className="grid">
                                            <div className="col-3">
                                                <div className="p-field pt-3 pb-3">
                                                    <label htmlFor={`startSlab${index}`} className={classNames({ 'p-error': isFormFieldValid(`[commissionSlabRequests${index}].startSlab`) }, 'Label__Text')}>
                                                        Start slab <span className="Label__Required"></span>
                                                    </label>
                                                    <InputText
                                                        id={`startSlab${index}`}
                                                        placeholder="Enter"
                                                        name={`commissionSlabRequests[${index}].startSlab`}
                                                        value={id.startSlab || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`commissionSlabRequests[${index}].startSlab`) }, 'Input__Round')}
                                                        disabled={true}
                                                    />
                                                    {getFormErrorMessage(`commissionSlabRequests[${index}].startSlab`)}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="p-field pt-3 pb-3">
                                                    <label htmlFor={`endSlab${index}`} className={classNames({ 'p-error': isFormFieldValid(`[commissionSlabRequests${index}].endSlab`) }, 'Label__Text')}>
                                                        End slab <span className="Label__Required"></span>
                                                    </label>
                                                    <InputText
                                                        id={`endSlab${index}`}
                                                        placeholder="Enter"
                                                        name={`commissionSlabRequests[${index}].endSlab`}
                                                        value={id.endSlab || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`commissionSlabRequests[${index}].endSlab`) }, 'Input__Round')}
                                                        disabled={true}
                                                    />
                                                    {getFormErrorMessage(`commissionSlabRequests[${index}].endSlab`)}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="p-field pt-3 pb-3">
                                                    <label htmlFor={`commissionAmount${index}`} className={classNames({ 'p-error': isFormFieldValid(`[commissionSlabRequests${index}].commissionAmount`) }, 'Label__Text')}>
                                                        commissionAmount <span className="Label__Required"></span>
                                                    </label>
                                                    <InputText
                                                        id={`commissionAmount${index}`}
                                                        placeholder="Enter"
                                                        name={`commissionSlabRequests[${index}].commissionAmount`}
                                                        value={id.commissionAmount || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`commissionSlabRequests[${index}].commissionAmount`) }, 'Input__Round')}
                                                        disabled={true}
                                                    />
                                                    {getFormErrorMessage(`commissionSlabRequests[${index}].commissionAmount`)}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="flex flex-column align-items-center justify-content-center">
                                                    <Button
                                                        icon="pi pi-plus"
                                                        className="p-button-rounded"
                                                        aria-label="Filter"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            addSlabWiseFixed();
                                                        }}
                                                    />
                                                    <Button
                                                        icon="pi pi-minus"
                                                        className="p-button-rounded mt-2 p-button-danger"
                                                        aria-label="Filter"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            removeSlabWiseFixed(index);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="flex flex-row">
                                    <div className="col-6 md:col-3">
                                        <div className="p-field pt-3 pb-3">
                                            <label htmlFor="commissionGlAccountId" className={classNames({ 'p-error': isFormFieldValid('commissionGlAccountId') }, 'Label__Text')}>
                                                Select Commission GL
                                                <span className="Label__Required"></span>
                                            </label>
                                            <Dropdown
                                                id="commissionGlAccountId"
                                                placeholder="Select Commission GL"
                                                options={glAccountsLov}
                                                optionLabel="accountNo"
                                                name="commissionGlAccountId"
                                                optionValue="accountId"
                                                value={formik.values.commissionGlAccountId || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('commissionGlAccountId') }, 'Dropdown__Round')}
                                                disabled={true}
                                            />
                                            {getFormErrorMessage('commissionGlAccountId')}
                                        </div>
                                    </div>
                                    <div className="col-6 md:col-3">
                                        <div className="p-field pt-3 pb-3">
                                            <label htmlFor="whtGlAccountId" className={classNames({ 'p-error': isFormFieldValid('whtGlAccountId') }, 'Label__Text')}>
                                                Select WHT GL
                                                <span className="Label__Required"></span>
                                            </label>
                                            <Dropdown
                                                id="whtGlAccountId"
                                                placeholder="Select WHT GL"
                                                options={glAccountsLov}
                                                optionLabel="accountNo"
                                                name="whtGlAccountId"
                                                optionValue="accountId"
                                                value={formik.values.whtGlAccountId || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('whtGlAccountId') }, 'Dropdown__Round')}
                                                disabled={true}
                                            />
                                            {getFormErrorMessage('whtGlAccountId')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="disbursementMethodName" className={classNames({ 'p-error': isFormFieldValid('disbursementMethodName') }, 'Label__Text')}>
                                    Select Disbursement Method
                                    <span className="Label__Required"></span>
                                </label>
                                <Dropdown
                                    id="disbursementMethodName"
                                    placeholder=" Select Disbursement Method"
                                    options={disbursementMethodLov}
                                    optionLabel="name"
                                    name="disbursementMethodName"
                                    optionValue="name"
                                    value={formik.values.disbursementMethodName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('disbursementMethodName') }, 'Dropdown__Round')}
                                    disabled={true}
                                />
                                {getFormErrorMessage('disbursementMethodName')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon || ''} label="Okay" onClick={() => navigate('/aggregator')} className="Btn__Dark" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={commissionId} tableName={tableName} />
        </>
    );
}
export default ViewSlabWiseFixed;

import { Chart } from 'primereact/chart';
import React, { useEffect, useState } from 'react';

const OsType = ({ osTypeWiseRegisteration }) => {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        const uniqueDates = {};
        const dateArr =
            osTypeWiseRegisteration &&
            osTypeWiseRegisteration.reduce((acc, item) => {
                const date = item?.parameterValueTwo;
                if (date && !uniqueDates[date]) {
                    uniqueDates[date] = true;
                    acc.push(date);
                }
                return acc;
            }, []);

        const IOSArr = osTypeWiseRegisteration && osTypeWiseRegisteration.filter((item) => item.parameterName.toLowerCase() === 'ios');

        const iosCountArr =
            IOSArr &&
            IOSArr.map((item) => {
                return item?.parameterValue;
            });

        const AndroidArr = osTypeWiseRegisteration && osTypeWiseRegisteration.filter((item) => item.parameterName.toLowerCase() === 'android');
        const androidCountArr =
            AndroidArr &&
            AndroidArr.map((item) => {
                return item?.parameterValue;
            });

        const LinesData = {
            labels: dateArr,
            datasets: [
                {
                    label: 'IOS',
                    data: iosCountArr,
                    fill: false,
                    borderColor: '#d3b7e3',
                    tension: 0.4
                },
                {
                    label: 'Android',
                    data: androidCountArr,
                    fill: false,
                    borderColor: '#e3e0b7',
                    tension: 0.4
                }
            ]
        };
        setChartData(LinesData);
    }, [osTypeWiseRegisteration]);

    return (
        <div className="col-12 md:col-6">
            <div className="card Card__Round">
                <div className="card">
                    <h5>OS Type - Android / OS</h5>
                    <Chart type="line" data={chartData} options={''} className="w-full" style={{ height: '35rem' }} />
                </div>
            </div>
        </div>
    );
};

export default OsType;

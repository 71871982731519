import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
// import { Captcha } from 'primereact/captcha';
import { baseURL } from '../Config';
import { loginAction } from '../redux/actions/authAction';
import { handleEncryptData } from '../services/EncryptionService';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import jsbank from '../assets/images/jsbank.svg';
import zindigi from '../assets/images/zindigi.svg';

const Login = () => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');
    const [checked, setChecked] = useState();
    // const [captchaValue, setCaptchaValue] = useState('');
    const [captchaCode, setCaptchaCode] = useState('');
    const [captchaError, setCaptchaError] = useState('');
    const [firstTimeLogin, setFirstTimeLogin] = useState('');
    const [remainingTime, setRemainingTime] = useState(0);
    const [fullUserDetails, setFullUserDetails] = useState(sessionStorage.getItem('fullUserDetails') ? JSON.parse(sessionStorage.getItem('fullUserDetails')) : '');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleUrl = () => {
        navigate('/forgetpassword');
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },

        validate: (data) => {
            let errors = {};

            if (!data.email) {
                errors.email = 'Email is required.';
            }
            if (!data.password) {
                errors.password = 'Password is required.';
            }

            return errors;
        },

        onSubmit: async (data) => {
            sessionStorage.clear();
            localStorage.clear();
            if (baseURL?.includes('backoffice') && validateCaptcha(captchaCode) === false) {
                setCaptchaError('Captcha is not valid');
                setCaptchaCode('');
                return;
            } else {
                const userPassword = handleEncryptData(data?.password);
                setloading(true);
                setloadingIcon('pi pi-spin pi-spinner');

                const newData = {
                    data: {
                        security: {
                            userName: '',
                            password: '',
                            securityToken: ''
                        },
                        account: {
                            msidn: '',
                            iban: '',
                            bban: '',
                            pan: '',
                            currency: ''
                        },
                        channel: '',
                        terminal: '',
                        reterivalReferenceNumber: '',
                        payLoad: {
                            userName: data?.email,
                            userPassword: userPassword
                        },
                        additionalInformation: [
                            {
                                infoKey: '',
                                infoValue: ''
                            }
                        ],
                        checkSum: ''
                    }
                };
                const res = await dispatch(loginAction(newData));
                if (res?.responseCode === '0000') {
                    setFullUserDetails(JSON.stringify(res?.payLoad));
                    setloadingIcon('');

                    if (res?.payLoad?.login) {
                        setFirstTimeLogin('first');
                        sessionStorage.setItem('isNewUser', 'O');
                        navigate('/VerifyOtp');
                    }
                } else {
                    setloading(false);
                    setloadingIcon('');
                }
            }
        }
    });

    useEffect(() => {
        if (firstTimeLogin) {
            expiryTiming(JSON.parse(sessionStorage.getItem('fullUserDetails')));
        }
    }, [firstTimeLogin]); // eslint-disable-line react-hooks/exhaustive-deps

    const expiryTiming = (fullUserDetails) => {
        let expiryTime = 0;
        if (firstTimeLogin) {
            expiryTime = fullUserDetails?.expiryTime * 60000;
            sessionStorage.setItem('expiryTime', expiryTiming);
        } else if (sessionStorage.getItem('expiryTime')) {
            expiryTime = sessionStorage.getItem('expiryTime') * 1000;
        }
        const endTime = Date.now() + expiryTime;
        const timer = setInterval(() => {
            const timeLeft = endTime - Date.now();
            setRemainingTime(Math.max(0, Math.round(timeLeft / 1000)));
            sessionStorage.setItem('expiryTime', Math.max(0, Math.round(timeLeft / 1000)));
            if (timeLeft <= 0) {
                clearInterval(timer);
                setloading(false);
                setRemainingTime(0);
            }
        }, 1000);
    };

    useEffect(() => {
        if (fullUserDetails) {
            if (fullUserDetails?.expiryTime > 0) {
                expiryTiming();
                setTimeout(() => {
                    setloading(false);
                }, remainingTime);
            } else {
                setloading(false);
            }
        }
    }, [fullUserDetails, remainingTime]); // eslint-disable-line react-hooks/exhaustive-deps

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    // const showResponse = (response) => {
    //     setCaptchaValue(response);
    // };

    useEffect(() => {
        if (baseURL?.includes('backoffice')) loadCaptchaEnginge(6, 'transparent', 'white', 'lower');
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="login-body flex-column">
            <div className="mb-3">
                <img src={zindigi} alt="zindigi logo" className="object-contain" />
            </div>
            <div className="login-panel p-fluid centered__login">
                <form onSubmit={formik.handleSubmit} className="">
                    <div className="flex flex-column">
                        <div className="flex align-items-center justify-content-center my-5 logo-container">
                            <p className="Signin__Heading">Login</p>
                        </div>
                        <div className="form-container">
                            <div className="flex flex-column w-12">
                                <span className="p-input-icon-left">
                                    <i className="pi pi-envelope"></i>
                                    <InputText
                                        id="email"
                                        className={classNames({ 'p-invalid': isFormFieldValid('email') }, 'Login__Input mb-0')}
                                        name="email"
                                        value={formik.values.email}
                                        placeholder="Enter User Name"
                                        disabled={remainingTime > 0}
                                        onChange={formik.handleChange}
                                        autoFocus
                                    />
                                    {getFormErrorMessage('email')}
                                </span>
                            </div>
                            <div className="flex flex-column w-12 mt-3">
                                <span className="p-input-icon-left">
                                    <i className="pi pi-key"></i>
                                    <InputText
                                        className={classNames({ 'p-invalid': isFormFieldValid('password') }, 'Login__Input mb-0')}
                                        name="password"
                                        placeholder="Enter Password"
                                        value={formik.values.password}
                                        disabled={remainingTime > 0}
                                        onChange={formik.handleChange}
                                        type="password"
                                    />
                                    {getFormErrorMessage('password')}
                                </span>
                            </div>
                            <div className="Remember__Forget flex flex-wrap w-12">
                                <div className="field-checkbox Margin__Top">
                                    <Checkbox inputId="binary" checked={checked} onChange={(e) => setChecked(e.checked)} className="checkbox_login" />
                                    <label style={{ cursor: 'pointer' }} htmlFor="binary" className="Remember_label">
                                        Remember Me
                                    </label>
                                </div>
                                <div className="ForgotPassword__Alignment">
                                    <label style={{ cursor: 'pointer' }} onClick={handleUrl} htmlFor="" className="forgot_label">
                                        Forgot Password
                                    </label>
                                </div>
                            </div>
                            {baseURL?.includes('backoffice') && (
                                <React.Fragment>
                                    <div className="flex justify-content-start w-12 my-3">
                                        <label className="Remember_label">Captcha</label>
                                    </div>
                                    <div className="text-captcha w-12">
                                        <LoadCanvasTemplate />
                                    </div>
                                    <div className="flex flex-column align-items-start justify-content-start w-12 my-3">
                                        <label className="Remember_label font-bold mb-2" htmlFor="captchaCode">
                                            What code is in the image?
                                        </label>
                                        <InputText
                                            maxLength={6}
                                            className={classNames({ 'p-invalid': isFormFieldValid('captchaCode') }, 'Login__Input mb-0')}
                                            name="captchaCode"
                                            placeholder="Enter Captcha Code"
                                            value={captchaCode}
                                            disabled={remainingTime > 0}
                                            onChange={(e) => {
                                                setCaptchaCode(e.target.value);
                                                setCaptchaError('');
                                            }}
                                        />
                                        {captchaError && <small className="p-error">{captchaError}</small>}
                                    </div>
                                </React.Fragment>
                            )}
                            {/* <div className="card captcha-color">
                                <Captcha name="captcha" siteKey="6LcejzolAAAAAPK__3EunWLhvkaLLkuis-uBsHMf" onResponse={showResponse} />
                            </div> */}
                        </div>
                        <div className={`button-container flex justify-content-center align-items-center mt-4 mb-5 ${remainingTime ? 'cursor-wait' : ''}`}>
                            <Button
                                type={remainingTime ? 'button' : !captchaCode && baseURL?.includes('backoffice') ? 'button' : 'submit'}
                                disabled={remainingTime || loading || (!captchaCode && baseURL?.includes('backoffice'))}
                                label="Login"
                                icon={loadingIcon || ''}
                                iconPos="right"
                                className={`Login__Button ${remainingTime ? 'cursor-wait' : ''}`}
                            />
                            {remainingTime > 0 && <p className={`mt-3 clockTimer text-white`}>{remainingTime}</p>}
                        </div>
                    </div>
                </form>
            </div>
            <div className="mt-3">
                <img src={jsbank} alt="zindigi logo" className="object-contain" />
            </div>
        </div>
    );
};

export default Login;

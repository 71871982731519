import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

const ViewNadraChecker = () => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const tableName = query.get('tableName');

    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        action: Yup.string().required('This field is required'),
        checkerComments: Yup.string().required('This field is required')
    });

    const formik = useFormik({
        validattionSchema: validationSchema,
        initialValues: {
            attempts: '',
            bvsLimit: '',
            idLimit: '',
            isActive: false,
            active: false,
            blockTime: '',
            attemptsTime: '',
            isTemporaryBlock: '',
            isPermanentBlock: '',
            action: '',
            checkerComments: '',
            cmsNadraConfigId: ''
        }
    });

    const getRequestTypeData = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/getnadraconfigbyid/${refTableId}`, true);
        const keyData = res?.payLoad;
        if (keyData) {
            formik.setFieldValue('cmsNadraConfigId', keyData?.cmsNadraConfigId);
            formik.setFieldValue('attempts', keyData?.attempts);
            formik.setFieldValue('attemptsTime', keyData?.attemptsTime);
            formik.setFieldValue('isTemporaryBlock', keyData?.isTemporaryBlock );
            formik.setFieldValue('isPermanentBlock', keyData?.isPermanentBlock );
            formik.setFieldValue('bvsLimit', keyData?.bvsLimit);
            formik.setFieldValue('idLimit', keyData?.idLimit);
            formik.setFieldValue('blockTime', keyData?.blockTime);
        }
    };

    const getMcRequestDataById = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/getmcrequestbyid/${mcRequestId}`);
        const unParsedkeyData = res?.payLoad?.updateJson;
        const keyData = JSON.parse(unParsedkeyData);
        if (keyData) {
            formik.setFieldValue('cmsNadraConfigId', keyData?.cmsNadraConfigId);
            formik.setFieldValue('attempts', keyData?.attempts);
            formik.setFieldValue('attemptsTime', keyData?.attemptsTime);
            formik.setFieldValue('isTemporaryBlock', keyData?.isTemporaryBlock );
            formik.setFieldValue('isPermanentBlock', keyData?.isPermanentBlock );
            formik.setFieldValue('bvsLimit', keyData?.bvsLimit);
            formik.setFieldValue('idLimit', keyData?.idLimit);
            formik.setFieldValue('blockTime', keyData?.blockTime);
        }
    };

    useEffect(() => {
        if (refTableId && requestType === 'I') {
            getRequestTypeData();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, []); // eslint-disable-line

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>View Details</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="bvsLimit" className={classNames({ 'p-error': isFormFieldValid('bvsLimit') }, 'Label__Text')}>
                                    Bvs Limit
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="bvsLimit"
                                    placeholder="Enter bvs Limit"
                                    name="bvsLimit"
                                    value={formik?.values.bvsLimit}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('bvsLimit') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('bvsLimit')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="idLimit" className={classNames({ 'p-error': isFormFieldValid('idLimit') }, 'Label__Text')}>
                                    ID Limit
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    type="text"
                                    id="email"
                                    placeholder="Enter Id Limit"
                                    name="idLimit"
                                    value={formik?.values?.idLimit}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('idLimit') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('idLimit')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="attempts" className={classNames({ 'p-error': isFormFieldValid('attempts') }, 'Label__Text')}>
                                    Attempts
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    type="text"
                                    id="attempts"
                                    placeholder="Enter attempts"
                                    name="attempts"
                                    value={formik?.values?.attempts}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('attempts') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('attempts')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4  pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="attemptsTime" className={classNames({ 'p-error': isFormFieldValid('attemptsTime') }, 'Label__Text')}>
                                    Enter Time<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="attemptsTime"
                                    type="text"
                                    name="attemptsTime"
                                    placeholder="Enter Time In Minutes"

                                    value={formik?.values?.attemptsTime}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('time') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('attemptsTime')}
                            </div>
                        </div>
                        <div className="Form__Header Full__Width">
                            <h1>Breach Limits</h1>
                            </div>
                            <div className="p-field col-12 md:col-8 pt-3 pb-3 flex flex-row justify-content-space-between">
                                
                            <div className="p-field col-12 md:col-4 pt-5 pb-3">
                                <div className="p-field">
                                    <div className="field-checkbox">
                                        <Checkbox
                                            inputId="isTemporaryBlock"
                                            name="isTemporaryBlock"
                                            checked={formik.values.isTemporaryBlock === 'Y'}
                                            onChange={(e) => {
                                                const newValue = e.checked ? 'Y' : 'N';
                                                formik.setFieldValue('isTemporaryBlock', newValue);
                                                if (e.checked) {
                                                    formik.setFieldValue('isPermanentBlock', 'N');
                                                }
                                            }}
                                            style={{ marginTop: '10px' }}
                                            disabled
                                        />
                                        <label htmlFor="isTemporaryBlock" className={classNames({ 'p-error': isFormFieldValid('isTemporaryBlock') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                            Temporary Block
                                        </label>
                                    </div>
                                    {getFormErrorMessage('isTemporaryBlock')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4">
                            <label htmlFor="blockTime" className={classNames({ 'p-error': isFormFieldValid('email') }, 'Label__Text')}>
                                Time
                                <span className="Label__Required">*</span>
                            </label>
                            <InputText
                                maxLength={100}
                                type="text"
                                id="blockTime"
                                placeholder="Enter Time In Minutes"
                                name="blockTime"
                                value={formik?.values?.blockTime}
                                onChange={formik.handleChange}
                                disabled
                                className={classNames({ 'p-invalid': isFormFieldValid('blockTime') }, 'Input__Round')}
                            />
                            {getFormErrorMessage('blockTime')}
                        </div>

                                
                            </div>
                      
                            <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <div className="field-checkbox">
                                        <Checkbox
                                            inputId="isPermanentBlock"
                                            name="isPermanentBlock"
                                            checked={formik.values.isPermanentBlock === 'Y'}
                                            onChange={(e) => {
                                                const newValue = e.checked ? 'Y' : 'N';
                                                formik.setFieldValue('isPermanentBlock', newValue);
                                                if (e.checked) {
                                                    formik.setFieldValue('isTemporaryBlock', 'N');
                                                }
                                            }}
                                            style={{ marginTop: '10px' }}
                                            disabled
                                        />
                                        <label htmlFor="isPermanentBlock" className={classNames({ 'p-error': isFormFieldValid('isPermanentBlock') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                            Permanent Block
                                        </label>
                                    </div>
                                    {getFormErrorMessage('isPermanentBlock')}
                                </div>
                            </div>
                      
                       
                    </div>

                    <div className="Usergroup__SearchBtn p-field col-12  flex justify-content-center">
                        <Button
                            label="Okay"
                            type="button"
                            onClick={() => {
                                navigate('/requestchecker');
                            }}
                            className="Btn__Dark__Search"
                        />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />

        </>
    );
};

export default ViewNadraChecker;

import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import * as Yup from 'yup';
import ActiveInActiveDialog from '../../../components/ActiveInActiveDialog/ActiveInActiveDialog';

const AgentMateBank = () => {
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [statusData, setStatusData] = useState([]);
    const [userLovData, setUserLovData] = useState([]);
    const [bank, setBank] = useState([]);
    const [switchData, setSwitchData] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        bankName: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z0-9\s]*$/, 'Bank Name must be alpha numeric'),
        bankCode: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[0-9]+$/, 'Bank code must contain only numeric values'),
        bankId: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[0-9]+$/, 'Bank Id must contain only numeric values')
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            bankName: '',
            bankId: '',
            bankCode: '',
            statusId: '2',
            createDate: '',
            createdBy: ''
        },
        onSubmit: async (data) => {
            setloadingIcon('pi pi-spin pi-spinner');

            const jsonObj = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },

                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },

                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',

                    payLoad: data,

                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],

                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(jsonObj, '/agentmatecms/v1/agentmatecms/searchallbank', true, true));
            if (res?.responseCode === '210000') {
                setBank(res?.payLoad);
                formik.resetForm();
            }
            setloadingIcon('pi pi-search');
        }
    });

    const editHandler = (rowData) => {
        navigate(`/editagentmatebank`, { state: { rowData } });
    };
    const viewHandler = (rowData) => {
        navigate(`/viewagentmatebank`, { state: { rowData } });
    };
    const handledClicked = () => {
        navigate('/addagentmatebank');
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="actions">
                <InputSwitch
                    checked={rowData.isActive === 'Y'}
                    disabled={['Pending', 'Rejected', 'Assign Back'].includes(rowData?.statusName) ? true : false}
                    onChange={() => {
                        setSwitchData(rowData);
                        setShowModal(true);
                    }}
                />
                <Button
                    tooltip="Edit"
                    icon="pi pi-pencil"
                    tooltipOptions={{ position: 'top' }}
                    disabled={['Pending', 'Rejected'].includes(rowData?.statusName) || (rowData?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                    onClick={() => editHandler(rowData)}
                    className="p-button-rounded p-button-warning"
                />
                <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => viewHandler(rowData)} className="p-button-rounded p-button-primary" />
            </div>
        );
    };

    const handleSwitchChange = async () => {
        const updatedData = bank?.map((item) => {
            if (item?.cmsBankId === switchData?.cmsBankId) {
                const isActive = item?.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setBank(updatedData);
        let isactive = '';
        if (switchData?.isActive === 'Y') {
            isactive = 'N';
        } else if (switchData?.isActive === 'N') {
            isactive = 'Y';
        }

        const previousValue = switchData?.isActive;
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    id: switchData?.cmsBankId
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newData, '/agentmatecms/v1/agentmatecms/inactivebank', true, false));
        if (res?.responseCode == '210000') {
            const revertedData = bank?.map((item) => {
                if (item.cmsBankId == switchData?.cmsBankId) {
                    const isActive = isactive;
                    return { ...item, isActive };
                }
                return item;
            });
            setBank(revertedData);
        } else {
            const revertedData = bank?.map((item) => {
                if (item.cmsBankId == switchData?.cmsBankId) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setBank(revertedData);
        }
    };

    const getStatusLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusData(res?.payLoad);
    };

    const getUserLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLovData(res?.payLoad);
    };

    useEffect(() => {
        getStatusLov();
        getUserLov();
    }, []); // eslint-disable-line

    const handleShowModal = () => {
        setShowModal(false);
    };

    const props = {
        showModal,
        handleShowModal,
        handleSwitchChange
    };
    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };
    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.statusName === 'Approved' ? '#14A38B' : rowData?.statusName === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.statusName}</b>
            </p>
        );
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];
    return (
        <>
            <ActiveInActiveDialog {...props} />
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={handledClicked} className="Btn__Add__" icon="pi pi-plus" iconPos="left" style={{ width: 'auto' }} />
                </div>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Bank ID</label>
                                <InputText id="bankId" placeholder="Enter Bank ID" name="bankId" value={formik.values.bankId} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('bankId') }, 'Input__Round')} />
                                {getFormErrorMessage('bankId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Bank Code</label>
                                <InputText
                                    id="bankCode"
                                    placeholder="Enter Bank Code"
                                    name="bankCode"
                                    value={formik.values.bankCode}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('bankCode') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('bankCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Bank Name</label>
                                <InputText
                                    id="bankName"
                                    placeholder="Enter Bank Name"
                                    name="bankName"
                                    value={formik.values.bankName}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('bankName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('bankName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLovData}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createDate" className={classNames({ 'p-error': isFormFieldValid('createDate') }, 'Label__Text')}>
                                    Created Date
                                </label>
                                <InputText
                                    max={previousDate}
                                    id="createDate"
                                    type="date"
                                    name="createDate"
                                    value={formik?.values?.createDate}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createDate') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('createDate')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="statusId" className={classNames({ 'p-error': isFormFieldValid('statusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select Status"
                                    options={statusData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label="Search" disabled={!Object.values(formik.values).some((value) => value !== null && value !== '')} icon={loadingIcon || ''} iconPos="left" className="Btn__Dark" />
                        <Button type="button" label="Reset" iconPos="right" className="Btn__Transparent" onClick={(e) => handleReset(e)} />
                    </div>
                </form>
            </div>
            <div />

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <div>
                            <h4>Existing Details</h4>
                        </div>
                        <DataTable
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            globalFilterFields={['validatorName', 'validatorType', 'createdate', 'createdBy', 'updatedBy', 'lkpStatus.statusDescr']}
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={bank}
                        >
                            <Column
                                field="categoryIcon"
                                header="Bank Icon"
                                body={(rowData) => {
                                    const iconPathParts = rowData?.bankIcon?.split('/');
                                    const bankIcon = iconPathParts ? iconPathParts[iconPathParts?.length - 1] : '';
                                    if (bankIcon != null) {
                                        return <span>{bankIcon}</span>;
                                    }
                                }}
                            />
                            <Column field="cmsBankId" header="Bank Id" />
                            <Column field="bankCode" header="Bank Code" />
                            <Column field="bankName" header="Bank Name" />
                            <Column header="Created Date" body={createdDateAndTimeBodyTemplate} />
                            <Column field="createdBy" header="Created By" />
                            <Column header="Status" body={statusBodyTemplate} />
                            <Column body={actionTemplate} header="Action" className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AgentMateBank;

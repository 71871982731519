import React from "react";

function ModifiedState ({oldUTypeData}){
    return(
        <>
        <div className="p-field col-12 md:col-6">
            <table className="Checker__Data__Table">
                <thead>
                    <tr>
                        <th colSpan='2' className="Checker__ModifiedTable__Head">
                            <b>
                                Modified State/ Previous State
                            </b>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='Checker__Table__Data'>
                            <b>
                                Tax Code
                            </b>
                        </td>
                        <td className='Checker__Table__Data'>
                            <b>
                                {oldUTypeData?.taxTypeCode}
                            </b>
                        </td>
                    </tr>
                    <tr>
                        <td className='Checker__Table__Data'>
                            <b>
                                Tax Name
                            </b>
                        </td>
                        <td className='Checker__Table__Data'>
                            <b>
                                {oldUTypeData?.taxTypeName}
                            </b>
                        </td>
                    </tr>
                    <tr>
                        <td className='Checker__Table__Data'>
                            <b>
                                Applicability
                            </b>
                        </td>
                        <td className='Checker__Table__Data'>
                            <b>
                                {oldUTypeData?.applicableOn === "I" ? "Apply on Fee Inclusive" : oldUTypeData?.applicableOn === "E" ? "Apply on Fee Exclusive" : oldUTypeData?.applicableOn === "C" ? "Apply on Commision" : "Apply on Transaction Amount" }
                            </b>
                        </td>
                    </tr>
                    <tr>
                        <td className='Checker__Table__Data'>
                            <b>
                                Description
                            </b>
                        </td>
                        <td className='Checker__Table__Data'>
                            <b>
                                {oldUTypeData?.taxTypeDescr}
                            </b>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="p-field col-12 md:col-6">
            {/* <table className="Checker__Data__Table">
                <thead>
                    <tr>
                        <th colSpan='2' className="Checker__Applicant__Detail">
                            <b>
                                Applicant Details
                            </b>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='Checker__Table__Data'>
                            <b>
                                Tax Code
                            </b>
                        </td>
                        <td className='Checker__Table__Data'>
                            <b>
                                {oldUTypeData?.taxTypeCode}
                            </b>
                        </td>
                    </tr>
                    <tr>
                        <td className='Checker__Table__Data'>
                            <b>
                                Tax Name
                            </b>
                        </td>
                        <td className='Checker__Table__Data'>
                            <b>
                                {oldUTypeData?.taxTypeName}
                            </b>
                        </td>
                    </tr>
                    <tr>
                        <td className='Checker__Table__Data'>
                            <b>
                                Applicability
                            </b>
                        </td>
                        <td className='Checker__Table__Data'>
                            <b>
                                {oldUTypeData?.applicableOn === "I" ? "Apply on Fee Inclusive" : oldUTypeData?.applicableOn === "E" ? "Apply on Fee Exclusive" : oldUTypeData?.applicableOn === "C" ? "Apply on Commision" : "Apply on Transaction Amount" }
                            </b>
                        </td>
                    </tr>
                    <tr>
                        <td className='Checker__Table__Data'>
                            <b>
                                Description
                            </b>
                        </td>
                        <td className='Checker__Table__Data'>
                            <b>
                                {oldUTypeData?.taxTypeDescr}
                            </b>
                        </td>
                    </tr>
                </tbody>
            </table> */}
        </div>
        </>
    );
}

export default ModifiedState;

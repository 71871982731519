import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { useDispatch } from 'react-redux';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';
import { useLocation } from 'react-router';

function EditTransProfProductChannelChecker({ activeIndex, handleStepClick, riskProfileData }) {

    const [productLov, setProductLov] = useState();
    const [channelOption, setChannelOption] = useState();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const requestType = query.get('requestType');
    const requestTypeDescr = query.get('requestTypeDescr');

    const dispatch = useDispatch();

    const getProductsLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setProductLov(resp?.payLoad);
    };
    useEffect(() => {
        getProductsLov();
    }, []);

    useEffect(() => {
        if (requestType === 'I' || (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request')) {
            if (riskProfileData) {
                const CTDropdownData = riskProfileData?.tblRpCrpDetails;

                if (CTDropdownData) {
                    const CHHighId = [];
                    const CHMediumId = [];
                    const CHLowId = [];

                    const PSHighId = [];
                    const PSMediumId = [];
                    const PSLowId = [];

                    CTDropdownData.forEach((value, index) => {
                        const referenceIdToString = value.refId?.toString();

                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'PS') {
                            // GET Channel Data
                            if (value?.rpRating === 'H') {
                                CHHighId.push(referenceIdToString);

                                formik.setFieldValue('productServicesHighRisk', CHHighId);
                                formik.setFieldValue('productServicesOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                CHMediumId.push(referenceIdToString);

                                formik.setFieldValue('productServicesMediumRisk', CHMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                CHLowId.push(referenceIdToString);

                                formik.setFieldValue('productServicesLowRisk', CHLowId);
                            }
                        }
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'CH') {
                            // GET Product/Services Data
                            if (value?.rpRating === 'H') {
                                PSHighId.push(referenceIdToString);

                                formik.setFieldValue('channelHighRisk', PSHighId);
                                formik.setFieldValue('channelOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                PSMediumId.push(referenceIdToString);

                                formik.setFieldValue('channelMediumRisk', PSMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                PSLowId.push(referenceIdToString);

                                formik.setFieldValue('channelLowRisk', PSLowId);
                            }
                        }
                    });
                }
            }
        } else if (requestType === 'U') {
            // GET Channel Data
            formik.setFieldValue(
                'channelHighRisk',
                riskProfileData[2]?.reqMap[1]?.CH?.highRisk?.map((value) => value?.toString())
            );
            formik.setFieldValue(
                'channelMediumRisk',
                riskProfileData[2]?.reqMap[1]?.CH?.medRisk?.map((value) => value?.toString())
            );
            formik.setFieldValue(
                'channelLowRisk',
                riskProfileData[2]?.reqMap[1]?.CH?.lowRisk?.map((value) => value?.toString())
            );
            formik.setFieldValue('channelOverRideRule', riskProfileData[2]?.reqMap[1]?.CH?.overRideRule === true ? 'true' : 'false');

            // GET Product/Services Data
            formik.setFieldValue(
                'productServicesHighRisk',
                riskProfileData[2]?.reqMap[0]?.PS?.highRisk?.map((value) => value?.toString())
            );
            formik.setFieldValue(
                'productServicesMediumRisk',
                riskProfileData[2]?.reqMap[0]?.PS?.medRisk?.map((value) => value?.toString())
            );
            formik.setFieldValue(
                'productServicesLowRisk',
                riskProfileData[2]?.reqMap[0]?.PS?.lowRisk?.map((value) => value?.toString())
            );
            formik.setFieldValue('productServicesOverRideRule', riskProfileData[2]?.reqMap[0]?.PS?.overRideRule === true ? 'true' : 'false');
        }
    }, [riskProfileData]); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            stepNo: 3,
            productServicesHighRisk: [],
            productServicesMediumRisk: [],
            productServicesLowRisk: [],
            productServicesOverRideRule: 'false',
            channelHighRisk: [],
            channelMediumRisk: [],
            channelLowRisk: [],
            channelOverRideRule: 'false',
            reqMap: []
        },

        onSubmit: async (data) => {
            data['reqMap'] = [
                {
                    PS: {
                        highRisk: [data?.productServicesHighRisk],
                        medRisk: [data?.productServicesMediumRisk],
                        lowRisk: [data?.productServicesLowRisk],
                        overRideRule: [data?.productServicesOverRideRule]
                    }
                },
                {
                    CH: {
                        highRisk: [data?.channelHighRisk],
                        medRisk: [data?.channelMediumRisk],
                        lowRisk: [data?.channelLowRisk],
                        overRideRule: [data?.channelOverRideRule]
                    }
                }
            ];

            delete data['productServicesHighRisk'];
            delete data['productServicesMediumRisk'];
            delete data['productServicesLowRisk'];
            delete data['productServicesOverRideRule'];
            delete data['channelHighRisk'];
            delete data['channelMediumRisk'];
            delete data['channelLowRisk'];
            delete data['channelOverRideRule'];

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            await dispatch(handlePostRequest(newData, '/riskprofile/v1/crp/saveupdatecrp', true, false));
            handleStepClick(activeIndex + 1);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        const getChannel = async () => {
            const res = await handleGetRequest('/configuration/v1/lookups/getchannels/LKP_CHANNEL:*');
            setChannelOption(res?.payLoad);
        };
        getChannel();
    }, []);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {/* PRODUCTS / SERVICES */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>PRODUCTS / SERVICES</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="productServicesHighRisk" className={classNames({ 'p-error': isFormFieldValid('productServicesHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="productServicesHighRisk"
                                    placeholder="Select High Risk"
                                    options={productLov}
                                    optionLabel="name"
                                    name="productServicesHighRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.productServicesHighRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productServicesHighRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('productServicesHighRisk')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="productServicesMediumRisk" className={classNames({ 'p-error': isFormFieldValid('productServicesMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="productServicesMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={productLov}
                                    optionLabel="name"
                                    name="productServicesMediumRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.productServicesMediumRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productServicesMediumRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('productServicesMediumRisk')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="productServicesLowRisk" className={classNames({ 'p-error': isFormFieldValid('productServicesLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="productServicesLowRisk"
                                    placeholder="Select Low Risk"
                                    options={productLov}
                                    optionLabel="name"
                                    name="productServicesLowRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.productServicesLowRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productServicesLowRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('productServicesLowRisk')}
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="productServicesOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="productServicesOverRideRule" disabled name="productServicesOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.productServicesOverRideRule === 'false'} />
                                    <label htmlFor="productServicesOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="productServicesOverRideRule" disabled name="productServicesOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.productServicesOverRideRule === 'true'} />
                                    <label htmlFor="productServicesOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* CHANNEL */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>CHANNEL</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="channelHighRisk" className={classNames({ 'p-error': isFormFieldValid('channelHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="channelHighRisk"
                                    placeholder="Select High Risk"
                                    options={channelOption}
                                    optionLabel="name"
                                    name="channelHighRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.channelHighRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelHighRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('channelHighRisk')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="channelMediumRisk" className={classNames({ 'p-error': isFormFieldValid('channelMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="channelMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={channelOption}
                                    optionLabel="name"
                                    name="channelMediumRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.channelMediumRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelMediumRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('channelMediumRisk')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="channelLowRisk" className={classNames({ 'p-error': isFormFieldValid('channelLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="channelLowRisk"
                                    placeholder="Select Low Risk"
                                    options={channelOption}
                                    optionLabel="name"
                                    name="channelLowRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.channelLowRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelLowRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('channelLowRisk')}
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="channelOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="channelOverRideRule" disabled name="channelOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.channelOverRideRule === 'false'} />
                                    <label htmlFor="channelOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="channelOverRideRule" disabled name="channelOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.channelOverRideRule === 'true'} />
                                    <label htmlFor="channelOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
export default EditTransProfProductChannelChecker;

import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';

const BalanceBrackets = ({ balanceBrackets }) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const countArr =
            balanceBrackets &&
            balanceBrackets.map((item) => {
                return item?.parameterValue;
            });
        const transactionBracketsArr =
            balanceBrackets &&
            balanceBrackets.map((item) => {
                return item?.parameterName;
            });

        const data = {
            labels: transactionBracketsArr,
            datasets: [
                {
                    label: 'Balance Brackets',
                    data: countArr,
                    backgroundColor: ['rgb(79,179,245)']
                }
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    display: false
                }
            },
            scales: {
                y: {
                    beginAtZero: true
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [balanceBrackets]);

    return (
        <div className="col-12 md:col-4">
            <div className="card Card__Round">
                <div className="cardBarCharts">
                    <h5>Balance Brackets</h5>
                    <div className="pt-3">
                        <Chart type="bar" data={chartData} options={chartOptions} className="w-full" style={{ height: '35rem' }} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BalanceBrackets;

import { Chart } from 'primereact/chart';
import React, { useEffect, useState } from 'react';

const ChannelForOnboardingRetailer = ({ onboardingRetailerChannelData }) => {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        const countArr =
            onboardingRetailerChannelData &&
            onboardingRetailerChannelData.map((item) => {
                return item?.parameterValue;
            });
        const labelArr =
            onboardingRetailerChannelData &&
            onboardingRetailerChannelData.map((item) => {
                return item?.parameterName;
            });
        const data = {
            labels: labelArr,
            datasets: [
                {
                    label: 'Value',
                    data: countArr,
                    backgroundColor: ['#ac8deb', '#8d92eb', '#b4eff1', '#8debc2', '#66BB6A', '#FFCA28', '#26A69A'],
                    yAxisID: 'y'
                }
            ]
        };

        setChartData(data);
    }, [onboardingRetailerChannelData]);
    const options = {
        plugins: {
            legend: {
                display: false
            }
        }
    };
    return (
        <div className="col-12 md:col-6">
            <div className="card Card__Round">
                <div className="card">
                    <h5>Channel For Onboarding Retailer</h5>
                    <Chart type="bar" data={chartData} options={options} className="w-full" style={{ height: '35rem' }} />
                </div>
            </div>
        </div>
    );
};

export default ChannelForOnboardingRetailer;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { handlePostRequest } from '../../services/PostTemplate';
import { handleGetRequest } from '../../services/GetTemplate';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Steps } from 'primereact/steps';
import UpdateCreateCampaignManagement from './Components/CreateCampaign/EditCampaign';
import UpdateTargetBaseSelectionCampaign from './Components/TargetBaseSelection/EditTargetBase';
import UpdateQualifyingCriteriaCampaign from './Components/QualifyingCriteria/EditQualifyingCriteria';
import UpdateRewardConfigurationCampaign from './Components/RewardConfigurations/EditRewardConfigurations';
import UpdateCustomerCommunicationCampaign from './Components/CustomerCommunication/EditCustomerCommunication';

function EditCampaignManagement() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [activeIndex, setActiveIndex] = useState(0);
    const [allStepsComplete, setAllStepsComplete] = useState(false);
    const [compaignIdData, setCompaignIdData] = useState();
    const [campaignData, setCampaignData] = useState();
    const [stepData1, setStepData1] = useState();
    const [stepData2, setStepData2] = useState();
    const [stepData3, setStepData3] = useState();
    const [stepData4, setStepData4] = useState();
    const [stepData5, setStepData5] = useState();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const campaignId = query.get('campaignId');

    const getCampaignById = async () => {
        const response = await handleGetRequest(`/customerloyalitypromotions/v1/customerloyalitypromotion/getcampaignbyid/${campaignId}`);
        setCampaignData(response?.payLoad);
        setActiveIndex(0);
    };

    useEffect(() => {
        getCampaignById();
    }, []); // eslint-disable-line

    const formik = useFormik({
        initialValues: {},

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            // data['campaignId'] = campaignId;
            let step2Data;

            if (stepData2?.tbsType === 'W') {
                step2Data = {
                    tbsType: stepData2?.tbsType,
                    campaignId: stepData2?.campaignId,
                    agentCustomerTypeList: stepData2?.agentCustomerTypeList,
                    stepNo: stepData2?.stepNo
                };
            } else {
                step2Data = {
                    tbsType: stepData2?.tbsType,
                    campaignId: stepData2?.campaignId,
                    stepNo: stepData2?.stepNo,

                    accountStatusList: stepData2?.accountStatusList,
                    activityConsideration: stepData2?.activityConsideration,
                    activityEndDate: stepData2?.activityEndDate,
                    activityStartDate: stepData2?.activityStartDate,
                    agentCustomerTypeList: stepData2?.agentCustomerTypeList,
                    isActivity: stepData2?.isActivity,
                    isExcludeCnic: stepData2?.isExcludeCnic,
                    isExcludeNumber: stepData2?.isExcludeNumber,
                    isRegistration: stepData2?.isRegistration,
                    isTransaction: stepData2?.isTransaction,
                    registrationConsideration: stepData2?.registrationConsideration,
                    registrationEndDate: stepData2?.registrationEndDate,
                    registrationStartDate: stepData2?.registrationStartDate,
                    targetBaseCount: stepData2?.targetBaseCount,
                    transConsideration: stepData2?.transConsideration,
                    transStartDate: stepData2?.transStartDate,
                    transEndDate: stepData2?.transEndDate,
                    tbsTransactionTypeIds: stepData2?.tbsTransactionTypeIds
                };
            }

            const arrayStep = [stepData1, step2Data, stepData3, stepData4, stepData5]?.filter((item) => {
                return item !== undefined;
            });

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: arrayStep,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const formData = new FormData();

            if (stepData2?.tbsType === 'W') {
                if (stepData2?.mobileNoFile) {
                    formData.append('mobileNoFile', stepData2?.mobileNoFile);
                } else {
                    step2Data['mobileNoBase64'] = stepData2?.mobileNoBase64 || '';
                }
            } else {
                if (stepData2?.mobileNoFile) {
                    formData.append('mobileNoFile', stepData2?.mobileNoFile);
                } else {
                    step2Data['mobileNoBase64'] = stepData2?.mobileNoBase64 || '';
                }
                if (stepData2?.cnicFile) {
                    formData.append('cnicFile', stepData2?.cnicFile);
                } else {
                    step2Data['cnicBase64'] = stepData2?.cnicBase64;
                }
            }
            formData.append('request', JSON.stringify(newData));

            // const res = await dispatch(handlePostRequest(formData, '/customerloyalitypromotions/v1/customerloyalitypromotion/editcampaign', true, false));

            const res = await dispatch(handlePostRequest(formData, '/customerloyalitypromotions/v1/customerloyalitypromotion/updatecampaign', true, false, 'campaignmanagement'));
            if (res?.responseCode === '150000') {
                formik.resetForm();
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const items = [
        {
            label: 'Add Campaign',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(activeIndex) >= 0 ? false : true
        },
        {
            label: 'Target Base Selection',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(activeIndex) >= 1 ? false : true
        },
        {
            label: 'Qualifying criteria',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(activeIndex) >= 2 ? false : true
        },
        {
            label: 'Reward Configurations',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(activeIndex) >= 3 ? false : true
        },
        {
            label: 'Customer Communication',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(activeIndex) >= 4 ? false : true
        }
    ];

    const handleStepClick = (index) => {
        setActiveIndex(index);
    };

    const getCompaignId = (data) => {
        setCompaignIdData(data);
    };

    return (
        <>
            <div className="card Card__Round">
                <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} style={{ margin: '50px 0px', padding: '50px 0px' }} className="Campaign__Steps" />
                {/* <div className="Form__Header">
                    <h1>EDIT CAMPAIGN</h1>
                </div> */}

                {activeIndex === 0 && <UpdateCreateCampaignManagement activeIndex={activeIndex} handleStepClick={handleStepClick} getCompaignId={getCompaignId} campaignData={campaignData} setStepData1={setStepData1} />}
                {activeIndex === 1 && <UpdateTargetBaseSelectionCampaign activeIndex={activeIndex} handleStepClick={handleStepClick} compaignIdData={compaignIdData} campaignData={campaignData} setStepData2={setStepData2} />}
                {activeIndex === 2 && <UpdateQualifyingCriteriaCampaign activeIndex={activeIndex} handleStepClick={handleStepClick} compaignIdData={compaignIdData} campaignData={campaignData} setStepData3={setStepData3} />}
                {activeIndex === 3 && <UpdateRewardConfigurationCampaign activeIndex={activeIndex} handleStepClick={handleStepClick} compaignIdData={compaignIdData} campaignData={campaignData} setStepData4={setStepData4} />}
                {activeIndex === 4 && (
                    <UpdateCustomerCommunicationCampaign activeIndex={activeIndex} handleStepClick={handleStepClick} setAllStepsComplete={setAllStepsComplete} compaignIdData={compaignIdData} campaignData={campaignData} setStepData5={setStepData5} />
                )}

                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {activeIndex === 5 && allStepsComplete && (
                        <div className="Down__Btn">
                            <Button disabled={loading} label="Update" className="Btn__Dark" />
                            <Button disabled={loading} onClick={() => navigate('/campaignmanagement')} label="Cancel" className="Btn__Transparent" />
                        </div>
                    )}
                </form>
            </div>
        </>
    );
}
export default EditCampaignManagement;

import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import ViewSegment from '../../../pages/CX-1/AccountsManagement/AccountDetailsBasicDialogs/ViewSegment';
import ViewBvs from '../../../pages/CX-1/AccountsManagement/AccountDetailsBasicDialogs/ViewBvs';
import { loadingAction } from '../../../redux/actions/loadingAction';
import DialogCloseAccountMobileNo from '../AccountsManagement/AccountMaintenanceDialogs/DialogCloseAccountMobileNo';
import DialogCloseAccount from '../AccountsManagement/AccountMaintenanceDialogs/DialogCloseAccount';
import ViewAppVersionFromMcView from '../AccountsManagement/AccountDetailsBasicDialogs/ViewAppVersionFromMcView';
import ViewDiscrepantDocs from './Dialogs/ViewDiscrepantDocs';
import DebitCredit from '../AccountsManagement/HraAccount/Components/DebitCredit';
import Transaction from '../AccountsManagement/HraAccount/Components/Transaction';
import { getLkpAccountStatus } from '../../../redux/slices/LkpAccountStatusSlice';
import { getLkpAccountLevel } from '../../../redux/slices/LkpAccountLevelSlice';
import { getLkpCity } from '../../../redux/slices/LkpCitySlice';
import EditPetroPocket from '../AccountsManagement/Components/EditPetroPocket';

const AccountDetailsCheckerEdit = () => {

    const lkpAccountStatusLov = useSelector((state) => state?.LkpAccountStatusSlice?.data);
    const lkpAccountLevelLov = useSelector((state) => state?.LkpAccountLevelSlice?.data);
    const lkpCityLov = useSelector((state) => state?.LkpCitySlice?.data);

    const [statusLovData, setStatusLovData] = useState([]);
    const [statusAction, setStatusAction] = useState('');
    const [comments, setComments] = useState('');
    const [objectName, setObjectName] = useState('');

    const [updatedData, setUpdatedData] = useState([]);
    const [customerId, setCustomerId] = useState('');
    const [previousData, setPreviousData] = useState([]);
    const [isKycData, setIsKycData] = useState(false);

    const [displaySegmentView, setDisplaySegmentView] = useState(false);
    const [displayBvsView, setDisplayBvsView] = useState(false);
    const [displayViewAppVersion, setDisplayViewAppVersion] = useState(false);
    const [displayDiscrepantDocsView, setDisplayDiscrepantDocsView] = useState(false);

    const [displayModalCloseAccountMobileNo, setDisplayModalCloseAccountMobileNo] = useState(false);
    const [displayModalCloseAccount, setDisplayModalCloseAccount] = useState(false);

    const [closeRequestData, setCloseRequestData] = useState('');
    const [closeRequestDataByCNIC, setCloseRequestDataByCNIC] = useState('');
    const [attachmentRequestData, setAttachmentRequestData] = useState('');
    const [checkerEditable, setCheckerEditable] = useState(false);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [viewedIds, setViewedIds] = useState([]);

    // const openModal = () => {
    //     setIsModalVisible(true);
    // };

    // const closeModal = () => {
    //     setIsModalVisible(false);
    // }

    const pinGenerationLov = [
        // { label: "Ama", id: 1 },
        { label: 'App', id: 2 }
    ];

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const IsLevel0 = previousData?.[0]?.accountLevelName.toLowerCase().includes('level 0');
    const IsLevel1 = previousData?.[0]?.accountLevelName.toLowerCase().includes('level 1');
    const IsUltraAccount = previousData?.[0]?.accountLevelName.toLowerCase().includes('ultra basic');
    const IsMinorAccount = previousData?.[0]?.accountLevelName.toLowerCase().includes('minor 0');
    const IsMinor1Account = previousData?.[0]?.accountLevelName.toLowerCase().includes('minor 1');
    const IsUltraFreelanceAccount = previousData?.[0]?.freelanceAccount && previousData?.[0]?.freelanceAccount.includes('Y');
    const IsUltraSignatureAccount = previousData?.[0]?.accountLevelName.toLowerCase().includes('ultra signature');
    const lowerCasedCountryName = previousData?.[0]?.countryName.toLowerCase();

    // Go back to the previous page
    const goBack = () => {
        navigate(-1);
    };

    const handleSubmit = async () => {
        if (objectName === 'updateCustomerKyc') {
            const updatedDataClone = [...updatedData];

            if (!updatedDataClone[0]?.fullName) {
                updatedDataClone[0].fullName = previousData?.[0]?.fullName;
            }
            if (!updatedDataClone[0]?.fullAddress) {
                updatedDataClone[0].fullAddress = previousData?.[0]?.fullAddress;
            }
            if (!updatedDataClone[0]?.email) {
                updatedDataClone[0].email = previousData?.[0]?.email;
            }

            setUpdatedData(updatedDataClone);
        }

        const customerKyc = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    [objectName]: updatedData[0],
                    mcActionRequest: {
                        mcRequestId: mcRequestId,
                        mcPeindingRequestId: mcPeindingRequestId,
                        checkerId: '1',
                        checkerComments: comments,
                        action: statusAction,
                        updatedIndex: null
                    }
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(customerKyc, '/account/v1/customeraccounts/accountDetailValidatorCheckerAction', true, false, 'requestchecker'));
        if (res?.responseCode === '010000') {
            // formik.resetForm();
        }
    };

    const getAccountTypeMcRequestDataById = async () => {
        dispatch(loadingAction(true));
        const resp = await handleGetRequest(`/account/v1/customeraccounts/getCXUpdateCheckerById/${mcRequestId}`, true);
        const objectName = resp?.payLoad && Object?.keys(resp?.payLoad)?.[0];
        const updatedObject = resp?.payLoad;
        const getDynamicObj = updatedObject && Object?.values(updatedObject);
        const storeCusId = resp?.payLoad?.updateCustomerKyc?.customerId || Object?.values(updatedObject)?.[0]?.customerId;
        if (objectName === 'updateCustomerKyc') {
            if (storeCusId) {
                const resp = await handleGetRequest(`/account/v1/customeraccounts/getDataByCustomerId/${storeCusId}`);
                setPreviousData(resp?.payLoad);
            }
            setIsKycData(true);
        } else {
            if (storeCusId) {
                const resp = await handleGetRequest(`/account/v1/customeraccounts/getDataByCustomerId/${storeCusId}`);
                setPreviousData(resp?.payLoad);
            }
            setIsKycData(false);
        }

        setObjectName(objectName);
        setCustomerId(storeCusId);
        setUpdatedData(getDynamicObj);
        dispatch(loadingAction(false));

        setCloseRequestData(resp?.payLoad?.closeAccountRequest);
        setCloseRequestDataByCNIC(resp?.payLoad?.closeAccountRequestByCnic);
        setAttachmentRequestData(resp?.payLoad?.sendAttachmentRequest);
    };

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    const onHideCloseAccountMobileNo = () => {
        setDisplayModalCloseAccountMobileNo(false);
    };

    const handledCloseAccountMobileNo = (e) => {
        e.preventDefault();
        setDisplayModalCloseAccountMobileNo(true);
        setCheckerEditable(true);
    };

    const onHideCloseAccount = () => {
        setDisplayModalCloseAccount(false);
    };

    const handledCloseAccountCNIC = (e) => {
        e.preventDefault();
        setDisplayModalCloseAccount(true);
        setCheckerEditable(true);
    };

    const onHide = () => {
        setDisplayViewAppVersion(false);
        setDisplaySegmentView(false);
        setDisplayBvsView(false);
        setDisplayDiscrepantDocsView(false);
    };

    // Handle Logs

    const handleLogSubmit = async (e) => {
        e.preventDefault();

        try {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    payload: {
                        serviceName: previousData[0]?.customerId,
                        endpoint: 'Cx-1',
                        dateTime: new Date(),
                        payloadService: [e.target.id] + ' : ' + e.target.value,
                        message: 'CX-1 Logs',
                        loggingLevel: 'INFO',
                        packageName: 'abcservice.ais',
                        loggerID: '(abcservice--0.0.0-8009-3)'
                    },
                    indexName: 'cx'
                }
            };

            if (!viewedIds.includes(e.target.id)) {
                await dispatch(handlePostRequest(newData, '/producer/kafka/logs/publish', true, true));
                const updatedViewedIds = [...viewedIds, e.target.id];
                setViewedIds(updatedViewedIds);
            }
        } catch (error) { }
    };

    const splitCnicFrontFileName = previousData?.[0]?.cnicFrontPic ? previousData?.[0]?.cnicFrontPic.split('/').pop() : '';
    const splitCnicBackFileName = previousData?.[0]?.cnicBankPic ? previousData?.[0]?.cnicBankPic.split('/').pop() : '';
    const splitCustomerVideoFileName = previousData?.[0]?.customerVideo ? previousData?.[0]?.customerVideo.split('/').pop() : '';
    const splitProofOfIncomePicFileName = previousData?.[0]?.proofOfIncomePic ? previousData?.[0]?.proofOfIncomePic.split('/').pop() : '';
    const splitUtilityBillFileName = previousData?.[0]?.utilityBill ? previousData?.[0]?.utilityBill.split('/').pop() : '';

    useEffect(() => {
        if (lkpAccountStatusLov === undefined || lkpAccountStatusLov.length === 0) dispatch(getLkpAccountStatus());
        if (lkpAccountLevelLov === undefined || lkpAccountLevelLov.length === 0) dispatch(getLkpAccountLevel());
        if (lkpCityLov === undefined || lkpCityLov.length === 0) dispatch(getLkpCity());
    }, []);

    useEffect(() => {
        if (requestType === 'U') {
            getAccountTypeMcRequestDataById();
        }
        getStatusLov();
    }, []);

    return (
        <>
            {/* View Segment */}
            <Dialog header="VIEW DETAILS" visible={displaySegmentView} style={{ width: '30vw' }} onHide={onHide}>
                <ViewSegment rowData={previousData} onHide={onHide} type="fromChecker" />
            </Dialog>

            {/* View Bvs */}
            <Dialog header="VIEW DETAILS" visible={displayBvsView} style={{ width: '30vw' }} onHide={onHide}>
                <ViewBvs rowData={previousData} onHide={onHide} type="fromChecker" />
            </Dialog>

            {/* View Discrepant Documents */}
            <Dialog header="VIEW DETAILS" visible={displayDiscrepantDocsView} style={{ width: '30vw' }} onHide={onHide}>
                <ViewDiscrepantDocs updatedData={updatedData} IsMinorAccount={IsMinorAccount} onHide={onHide} />
            </Dialog>

            {/* View App Version */}
            <Dialog header="VIEW DETAILS" visible={displayViewAppVersion} style={{ width: '30vw' }} onHide={onHide}>
                <ViewAppVersionFromMcView rowData={previousData} onHide={onHide} type="fromChecker" />
            </Dialog>

            {/* Close Account Via Mobile Number Dialog */}
            <Dialog header="Close Account Via Mobile Number" blockScroll draggable={false} visible={displayModalCloseAccountMobileNo} style={{ width: '30vw' }} onHide={() => onHideCloseAccountMobileNo()}>
                <DialogCloseAccountMobileNo onHideCloseAccountMobileNo={onHideCloseAccountMobileNo} customerMobile={closeRequestData?.mobileNumber} reason={closeRequestData?.reason} checkerEditable={checkerEditable} />
            </Dialog>

            {/* Close Account DISOWN CNIC Dialog */}
            <Dialog header="Close Account Disown CNIC" blockScroll draggable={false} visible={displayModalCloseAccount} style={{ width: '30vw' }} onHide={() => onHideCloseAccount()}>
                <DialogCloseAccount onHideCloseAccount={onHideCloseAccount} customerCnic={closeRequestDataByCNIC?.cnic} reason={closeRequestDataByCNIC?.reason} checkerEditable={checkerEditable} />
            </Dialog>

            <div className="card Card__Round">
                {/* FORM */}

                <div>
                    <h6 className="secondary-color">
                        <b>EDIT DETAILS</b>
                    </h6>

                    <Divider />

                    <div className="p-fluid p-formgrid grid mb-2">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Name</label>
                                <div className="flex align-items-center">
                                    <InputText
                                        // placeholder={isKycData ? updatedData?.[0]?.fullName : previousData?.[0]?.fullName}
                                        placeholder={updatedData?.[0]?.fullName ? updatedData?.[0]?.fullName : previousData?.[0]?.fullName}
                                        onClick={(e) => {
                                            handleLogSubmit(e);
                                            const inputElement = e.target;
                                            inputElement.classList.remove('blur');
                                        }}
                                        className="Input__Round mr-2 blur"
                                        readOnly
                                        id="fullName"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Customer ID</label>
                                <InputText placeholder={previousData?.[0]?.customerId} className="Input__Round" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Mobile No.</label>
                                <InputText
                                    placeholder={previousData?.[0]?.mobileNo}
                                    onClick={(e) => {
                                        handleLogSubmit(e);
                                        const inputElement = e.target;
                                        inputElement.classList.remove('blur');
                                    }}
                                    className="Input__Round blur"
                                    readOnly
                                    id="mobileNo"
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">IBAN</label>
                                <InputText placeholder={previousData?.[0]?.iban} className="Input__Round" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Account Type</label>
                                <div className="flex align-items-center">
                                    <Dropdown
                                        id="accountTypeId"
                                        name="accountTypeId"
                                        placeholder="--Select--"
                                        options={lkpAccountLevelLov}
                                        optionLabel="name"
                                        optionValue="lovId"
                                        value={updatedData?.[0]?.accountLevelId ? updatedData?.[0]?.accountLevelId.toString() : previousData?.[0]?.accountLevelId.toString()}
                                        className="Dropdown__Round mr-2 custom-dropdown"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Nadra Verification</label>
                                <Dropdown
                                    id="nadraVerified"
                                    name="nadraVerified"
                                    placeholder={previousData?.[0]?.nadraVerified === 'Y' ? 'Yes' : previousData?.[0]?.nadraVerified === 'N' ? 'No' : ''}
                                    className="Dropdown__Round  custom-dropdown"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">A/C Status</label>
                                <div className="flex align-items-center">
                                    <Dropdown
                                        id="accountStatusId"
                                        name="accountStatusId"
                                        placeholder="--Select--"
                                        options={lkpAccountStatusLov}
                                        optionLabel="accountStatusName"
                                        optionValue="accountStatusId"
                                        // value={isKycData ? updatedData?.[0]?.accountStatusId.toString() : previousData?.[0]?.accountStatusId.toString()}
                                        value={updatedData?.[0]?.accountStatusId?.toString() ? updatedData?.[0]?.accountStatusId?.toString() : previousData?.[0]?.accountStatusId?.toString()}
                                        //  onChange={(e) => setAccountTypeId(e.target.value)}
                                        className="Dropdown__Round mr-2 custom-dropdown"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Remarks</label>
                                <InputText placeholder={updatedData?.[0]?.remarks ? updatedData?.[0]?.remarks : previousData?.[0]?.remarks} className="Input__Round" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Address</label>
                                <div className="flex align-items-center">
                                    <InputText placeholder={updatedData?.[0]?.fullAddress ? updatedData?.[0]?.fullAddress : previousData?.[0]?.fullAddress} className="Input__Round mr-2" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Email Address</label>
                                <div className="flex align-items-center">
                                    <InputText placeholder={updatedData?.[0]?.email ? updatedData?.[0]?.email : previousData?.[0]?.email} className="Input__Round mr-2" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">City</label>
                                <Dropdown placeholder={previousData?.[0]?.cityName} className="Dropdown__Round custom-dropdown" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">DOB</label>
                                <InputText
                                    placeholder={previousData?.[0]?.dob}
                                    onClick={(e) => {
                                        handleLogSubmit(e);
                                        const inputElement = e.target;
                                        inputElement.classList.remove('blur');
                                    }}
                                    className="Dropdown__Round blur"
                                    readOnly
                                    id="dob"
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">CNIC</label>
                                <InputText
                                    placeholder={previousData?.[0]?.cnic}
                                    onClick={(e) => {
                                        handleLogSubmit(e);
                                        const inputElement = e.target;
                                        inputElement.classList.remove('blur');
                                    }}
                                    className="Input__Round blur"
                                    readOnly
                                    id="cnic"
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">CNIC Issuance Date</label>
                                <div className="flex align-items-center">
                                    <InputText placeholder={updatedData?.[0]?.cnicIssuanceDate ? updatedData?.[0]?.cnicIssuanceDate : previousData?.[0]?.cnicIssuanceDate} className="Input__Round mr-2" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">CNIC Expiry</label>
                                <div className="flex align-items-center">
                                    <InputText
                                        placeholder={updatedData?.[0]?.cnicExpiryDate ? updatedData?.[0]?.cnicExpiryDate : previousData?.[0]?.cnicExpiryDate}
                                        onClick={(e) => {
                                            handleLogSubmit(e);
                                            const inputElement = e.target;
                                            inputElement.classList.remove('blur');
                                        }}
                                        className="Input__Round mr-2 blur"
                                        readOnly
                                        id="cnicExpiryDate"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Segment</label>
                                <div className="flex align-items-center">
                                    <InputText placeholder={previousData?.[0]?.segmentName} className="Input__Round mr-2" disabled />
                                    <i className="field-eye-icon pi pi-eye" onClick={() => setDisplaySegmentView(true)}></i>
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">A/C Opening Channel</label>
                                <InputText placeholder={previousData?.[0]?.channelName} className="Input__Round mr-2" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">A/C Opening Date</label>
                                <InputText placeholder={previousData?.[0]?.accountOpeningDate.split(' ')[0]} className="Input__Round mr-2" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Device Details</label>
                                <div className="flex align-items-center">
                                    <InputText placeholder={previousData?.[0]?.appVersionName} className="Input__Round mr-2" disabled />
                                    <i className="field-eye-icon pi pi-eye" onClick={() => setDisplayViewAppVersion(true)}></i>
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Level 1 Updated On</label>
                                <InputText placeholder={previousData?.[0]?.level1UpdatedOn} className="Input__Round mr-2" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Ultra A/C Opening Date</label>
                                <InputText placeholder={previousData?.[0]?.ultraAcOpeningDate.split(' ')[0]} className="Input__Round" disabled />
                            </div>
                        </div>
                        {previousData?.[0]?.accountLevelName && IsLevel0 && previousData?.[0]?.hra === 'N' ? (
                            ''
                        ) : previousData?.[0]?.accountLevelName && IsLevel1 && previousData?.[0]?.hra === 'N' ? (
                            ''
                        ) : (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Discrepant Documents</label>
                                    <div className="flex align-items-center">
                                        <Dropdown placeholder="If Any" className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                        <i className="field-eye-icon pi pi-eye" onClick={() => setDisplayDiscrepantDocsView(true)}></i>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">CLS Status</label>
                                <Dropdown placeholder={previousData?.[0]?.clsVerified === 'Y' ? 'Yes' : previousData?.[0]?.clsVerified === 'N' ? 'No' : ''} className="Dropdown__Round custom-dropdown" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">AML Status</label>
                                <Dropdown placeholder={previousData?.[0]?.aml === 'Y' ? 'Yes' : previousData?.[0]?.aml === 'N' ? 'No' : ''} className="Dropdown__Round custom-dropdown" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">BVS Details</label>
                                <div className="flex align-items-center">
                                    <Dropdown placeholder={previousData?.[0]?.bvs === 'Y' ? 'Yes' : previousData?.[0]?.bvs === 'N' ? 'No' : ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                    <i className="field-eye-icon pi pi-eye" onClick={() => setDisplayBvsView(true)}></i>
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Available Balance</label>
                                <InputText placeholder={previousData?.[0]?.actualBalance} className="Input__Round" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">HRA</label>
                                <Dropdown placeholder={previousData?.[0]?.hra === 'Y' ? 'Yes' : previousData?.[0]?.hra === 'N' ? 'No' : ''} className="Dropdown__Round custom-dropdown" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Pin Generation</label>
                                <Dropdown
                                    id="pinGeneration"
                                    name="pinGeneration"
                                    placeholder="--Select--"
                                    options={pinGenerationLov}
                                    optionLabel="label"
                                    optionValue="id"
                                    value={updatedData?.[0]?.pinGenerationId ? updatedData?.[0]?.pinGenerationId : previousData?.[0]?.pinGeneration}
                                    // onChange={formik.handleChange}
                                    className="Dropdown__Round mr-2 custom-dropdown"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Mother Name</label>
                                <InputText
                                    placeholder={previousData?.[0]?.motherMaidenName}
                                    onClick={(e) => {
                                        handleLogSubmit(e);
                                        const inputElement = e.target;
                                        inputElement.classList.remove('blur');
                                    }}
                                    className="Input__Round blur"
                                    readOnly
                                    id="motherMaidenName"
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Birth City</label>
                                <InputText
                                    id="birthPlace"
                                    name="birthPlace"
                                    onClick={(e) => {
                                        handleLogSubmit(e);
                                        const inputElement = e.target;
                                        inputElement.classList.remove('blur');
                                    }}
                                    placeholder={previousData?.[0]?.birthPlace}
                                    className="Input__Round blur"
                                    readOnly
                                />
                            </div>
                        </div>

                        {(previousData?.[0]?.accountLevelName && IsMinorAccount) || IsMinor1Account ? (
                            <>
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label className="Label__Text">Parent CNIC</label>
                                        <InputText placeholder={previousData?.[0]?.parentCnic} className="Input__Round" disabled />
                                    </div>
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                    </div>

                    {/* Conditional Render Ultra Basic Fields */}

                    {IsUltraAccount || IsUltraFreelanceAccount || IsUltraSignatureAccount ? (
                        <div className="p-fluid p-formgrid grid mb-3">
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">CNIC Front</label>
                                    <div className="flex align-items-center">
                                        <InputText id="cnicFrontPic" value={splitCnicFrontFileName ?? ''} className="Input__Round mr-2" disabled />
                                        {/* <Image className='img-viewer-ultra' src={eyeIcon} zoomSrc={cnicFront ? cnicFront : "No Image Available"} alt="Image" width="80" height="60" preview /> */}
                                    </div>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">CNIC Back</label>
                                    <div className="flex align-items-center">
                                        <InputText id="cnicBankPic" value={splitCnicBackFileName ?? ''} className="Input__Round mr-2" disabled />
                                        {/* <Image className='img-viewer-ultra' src={eyeIcon} zoomSrc={cnicBack ? cnicBack : "No Image Available"} alt="Image" width="80" height="60" preview /> */}
                                    </div>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Video</label>
                                    <div className="flex align-items-center">
                                        <InputText id="customerVideo" value={splitCustomerVideoFileName ?? ''} className="Input__Round mr-2" disabled />
                                    </div>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Dual Nationality</label>
                                    <Dropdown placeholder={previousData?.[0]?.dualNationality === 'Y' ? 'Yes' : previousData?.[0]?.dualNationality === 'N' ? 'No' : ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}

                    {/* US DETAILS */}
                    {previousData?.[0] && previousData?.[0]?.dualNationality === 'Y' ? (
                        <div className="custom-card p-5 mb-3">
                            <div className="p-fluid p-formgrid grid">
                                {/* ///////////////////////////////////// */}
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label className="Label__Text">Country</label>
                                        <Dropdown placeholder={previousData?.[0]?.countryName ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                    </div>
                                </div>
                                {lowerCasedCountryName === 'us' ? (
                                    <>
                                        <div className="p-field col-12 md:col-4">
                                            <div className="p-field">
                                                <label className="Label__Text">US Citizen/ US Resident/ or Holding a U.S Permanent Resident Card (Green Card)</label>
                                                <Dropdown placeholder={previousData?.[0]?.greenCardHolder === 'Y' ? 'Yes' : previousData?.[0]?.greenCardHolder === 'N' ? 'No' : ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4">
                                            <div className="p-field">
                                                <label className="Label__Text">Born in US</label>
                                                <Dropdown placeholder={previousData?.[0]?.usBorn === 'Y' ? 'Yes' : previousData?.[0]?.usBorn === 'N' ? 'No' : ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4">
                                            <div className="p-field">
                                                <label className="Label__Text">US Telephone Number or Address</label>
                                                <Dropdown placeholder={previousData?.[0]?.usTelNo === 'Y' ? 'Yes' : previousData?.[0]?.usTelNo} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4">
                                            <div className="p-field">
                                                <label className="Label__Text">Assigning Signatory Authority/ Mandate to a Person with US Address</label>
                                                <Dropdown placeholder={previousData?.[0]?.usSignAuth === 'Y' ? 'Yes' : previousData?.[0]?.usSignAuth === 'N' ? 'No' : ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4">
                                            <div className="p-field">
                                                <label className="Label__Text">U.S. Links</label>
                                                <Dropdown placeholder={previousData?.[0]?.usLinks === 'Y' ? 'Yes' : previousData?.[0]?.usLinks === 'N' ? 'No' : ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                            </div>
                                        </div>
                                        {previousData?.[0]?.greenCardHolder === 'N' && previousData?.[0]?.usBorn === 'N' ? (
                                            ''
                                        ) : (
                                            <>
                                                {previousData?.[0]?.usTelNo !== 'Y' ? (
                                                    <>
                                                        <div className="p-field col-12 md:col-4">
                                                            <div className="p-field">
                                                                <label className="Label__Text">Permanent Address</label>
                                                                <Dropdown placeholder={previousData?.[0]?.fullAddress ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                                            </div>
                                                        </div>
                                                        <div className="p-field col-12 md:col-4">
                                                            <div className="p-field">
                                                                <label className="Label__Text">Mailing Address</label>
                                                                <InputText placeholder={previousData?.[0]?.mailingAddress ?? ''} className="Input__Round mr-2 custom-dropdown" disabled />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="p-field col-12 md:col-4">
                                                            <div className="p-field">
                                                                <label className="Label__Text">Federal tax Classification</label>
                                                                <Dropdown
                                                                    placeholder={previousData?.[0]?.federalTaxClassificationName === 'Y' ? 'Yes' : previousData?.[0]?.federalTaxClassificationName === 'N' ? 'No' : ''}
                                                                    className="Dropdown__Round mr-2 custom-dropdown"
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="p-field col-12 md:col-4">
                                                            <div className="p-field">
                                                                <label className="Label__Text">Address</label>
                                                                <InputText placeholder={previousData?.[0]?.fullAddress ?? ''} className="Input__Round mr-2" disabled />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                <div className="p-field col-12 md:col-4">
                                                    <div className="p-field">
                                                        <label className="Label__Text">Tax Identification Number</label>
                                                        <InputText placeholder={previousData?.[0]?.taxIdentificationNumber ?? ''} className="Input__Round mr-2" disabled />
                                                    </div>
                                                </div>
                                                {previousData?.[0]?.usTelNo !== 'Y' ? (
                                                    <div className="p-field col-12 md:col-4">
                                                        <div className="p-field">
                                                            <label className="Label__Text">Reference Number</label>
                                                            <InputText placeholder="" className="Input__Round mr-2" disabled />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <div className="p-field col-12 md:col-4">
                                            <div className="p-field">
                                                <label className="Label__Text">Country/Jurisdiction of Tax Residence</label>
                                                <Dropdown placeholder={previousData?.[0]?.jurisdictionOfTaxResidenceName ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4">
                                            <div className="p-field">
                                                <label className="Label__Text">Tax Identification Number</label>
                                                <InputText placeholder={previousData?.[0]?.taxIdentificationNumber ?? ''} className="Input__Round mr-2" disabled />
                                            </div>
                                        </div>

                                        <div className="p-field col-12 md:col-4">
                                            <div className="p-field">
                                                <label className="Label__Text">Residence Address</label>
                                                <InputText placeholder={previousData?.[0]?.residenceAddress ?? ''} className="Input__Round mr-2" disabled />
                                            </div>
                                        </div>

                                        <div className="p-field col-12 md:col-4">
                                            <div className="p-field">
                                                <label className="Label__Text">Country of Birth</label>
                                                <Dropdown placeholder={previousData?.[0]?.birthPlace ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    ) : (
                        ''
                    )}

                    <div className="p-fluid p-formgrid grid mb-3">
                        {IsUltraAccount || IsUltraFreelanceAccount || IsUltraSignatureAccount ? (
                            <>
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label className="Label__Text">Occupation</label>
                                        <Dropdown placeholder={previousData?.[0]?.professionName ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                    </div>
                                </div>

                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label className="Label__Text">Purpose</label>
                                        <Dropdown placeholder={previousData?.[0]?.ultraUsageName ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                    </div>
                                </div>

                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label className="Label__Text">Monthly Spending</label>
                                        <Dropdown placeholder={previousData?.[0]?.monthlySpending ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                    </div>
                                </div>

                                {IsUltraFreelanceAccount && IsUltraFreelanceAccount && (
                                    <div className="p-field col-12 md:col-4">
                                        <div className="p-field">
                                            <label className="Label__Text">Account Currency</label>
                                            <Dropdown placeholder={previousData?.[0]?.currencyName ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                        </div>
                                    </div>
                                )}

                                {IsUltraSignatureAccount && IsUltraSignatureAccount && (
                                    <div className="p-field col-12 md:col-4">
                                        <div className="p-field">
                                            <label className="Label__Text">Proof of Profession</label>
                                            <Dropdown placeholder={splitProofOfIncomePicFileName ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                        </div>
                                    </div>
                                )}

                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label className="Label__Text">Cheque Book</label>
                                        <div className="flex align-items-center">
                                            <Dropdown placeholder={previousData?.[0]?.chequeBook === 'Y' ? 'Yes' : previousData?.[0]?.chequeBook === 'N' ? 'No' : ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                            {/* {(
                                                    previousData?.[0] && previousData?.[0]?.chequeBook === "Y" ?
                                                        <i className="field-eye-icon pi pi-eye"></i>
                                                        :
                                                        ""
                                                )} */}
                                        </div>
                                    </div>
                                </div>

                                {previousData?.[0] && previousData?.[0]?.chequeBook === 'Y' ? (
                                    <div className="p-field col-12 md:col-4">
                                        <div className="p-field">
                                            <label className="Label__Text">Signature</label>
                                            <div className="flex align-items-center">
                                                <InputText id="signaturePic" value={previousData?.[0]?.signaturePic ?? ''} className="Input__Round mr-2" disabled />
                                                {/* <Image className='img-viewer-ultra' src={eyeIcon} zoomSrc={previousData?.[0]?.signaturePic ? `${baseURL}/document` + previousData?.[0]?.signaturePic?.substring(33) : "No Image Available"} alt="Image" width="80" height="60" preview /> */}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </>
                        ) : (
                            ''
                        )}
                    </div>

                    {/* IsUltraSignatureAccount */}
                    {IsUltraSignatureAccount && IsUltraSignatureAccount ? (
                        <>
                            <div>
                                <h4>ADDRESS DETAIL</h4>
                            </div>

                            <Divider />

                            <div className="p-fluid p-formgrid grid mb-3">
                                <div className="p-field col-12 md:col-4">
                                    <label className="Label__Text">City</label>
                                    <Dropdown placeholder={previousData?.[0]?.cityName ?? ''} className="Dropdown__Round custom-dropdown" disabled />
                                </div>
                                <div className="p-field col-12 md:col-4">
                                    <label className="Label__Text">Area</label>
                                    <Dropdown placeholder={previousData?.[0]?.areaName ?? ''} className="Dropdown__Round custom-dropdown" disabled />
                                </div>
                                <div className="p-field col-12 md:col-4">
                                    <label className="Label__Text">Street Number</label>
                                    <InputText placeholder={previousData?.[0]?.streetNo ?? ''} className="Dropdown__Round custom-dropdown" disabled />
                                </div>
                                <div className="p-field col-12 md:col-4">
                                    <label className="Label__Text">House Number</label>
                                    <InputText placeholder={previousData?.[0]?.houseNo ?? ''} className="Dropdown__Round custom-dropdown" disabled />
                                </div>
                            </div>
                        </>
                    ) : (
                        ''
                    )}

                    {/* PETRO POCKET */}

                    <EditPetroPocket rowData={previousData?.[0]} />

                    {/* ACCOUNT MAINTENANCE LOGS */}

                    <div className="card custom-card">
                        <div className="flex align-items-center justify-content-between">
                            <h5 className="dim-heading">Account Maintenance Logs</h5>
                            {/* <i className="field-eye-icon pi pi-eye"></i> */}
                        </div>

                        <div className="custom-table text-center">
                            <table>
                                <tbody>
                                    <tr className="custom-t-head">
                                        <th className="dim-heading">Limit</th>
                                        <th className="dim-heading">Consumed</th>
                                        <th className="dim-heading">Remaining</th>
                                    </tr>
                                    <tr>
                                        <td>Daily Limit</td>
                                        <td>{previousData?.[0]?.dailyDebitConsumed || '-'}</td>
                                        <td>{previousData?.[0]?.dailyDebitRemaining || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Monthly Limit</td>
                                        <td>{previousData?.[0]?.monthlyDebitConsumed || '-'}</td>
                                        <td>{previousData?.[0]?.monthlyDebitRemaining || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Yearly Limit</td>
                                        <td>{previousData?.[0]?.yearlyDebitConsumed || '-'}</td>
                                        <td>{previousData?.[0]?.yearlyDebitRemaining || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>HRA Daily Limit</td>
                                        <td>{previousData?.[0]?.hraDailyDebitConsumed || '-'}</td>
                                        <td>{previousData?.[0]?.hraDailyDebitRemaining || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>HRA Monthly Limit</td>
                                        <td>{previousData?.[0]?.hraMonthlyDebitConsumed || '-'}</td>
                                        <td>{previousData?.[0]?.hraMonthlyDebitRemaining || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>HRA Yearly Limit</td>
                                        <td>{previousData?.[0]?.hraYearlyDebitConsumed || '-'}</td>
                                        <td>{previousData?.[0]?.hraYearlyDebitRemaining || '-'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {previousData?.[0] && previousData?.[0]?.hra === 'Y' ? (
                        <>
                            {/* HRA DETAILS */}
                            <div>
                                <div className="hra_head">
                                    <h5 className="secondary-color">
                                        <b>HRA DETAILS</b>
                                    </h5>
                                </div>

                                <div className="p-fluid p-formgrid grid mb-3">
                                    <div className="p-field col-12 md:col-4">
                                        <div className="p-field">
                                            <label className="Label__Text">Purpose of Account</label>
                                            <div className="flex align-items-center">
                                                <Dropdown placeholder={previousData?.[0]?.hraPurposeOfAccount ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4">
                                        <div className="p-field">
                                            <label className="Label__Text">Occupation</label>
                                            <div className="flex align-items-center">
                                                <Dropdown placeholder={previousData?.[0]?.hraOccupation ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4">
                                        <div className="p-field">
                                            <label className="Label__Text">Source of Income</label>
                                            <div className="flex align-items-center">
                                                <Dropdown placeholder={previousData?.[0]?.hraSourceOfIncome ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-field col-12 md:col-4">
                                        <div className="p-field">
                                            <label className="Label__Text">Relationship with Originator</label>
                                            <div className="flex align-items-center">
                                                <Dropdown placeholder={previousData?.[0]?.hraOriginatorRelationshipName} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* HRA NEXT OF KIN */}
                            <section>
                                <div className="mt-6">
                                    <h5 className="secondary-color">
                                        <b>NEXT OF KIN DETAILS</b>
                                    </h5>
                                    <Divider />
                                </div>

                                <div className="p-fluid p-formgrid grid">
                                    <div className="p-field col-12 md:col-4">
                                        <div className="p-field">
                                            <label className="Label__Text">Name</label>
                                            <InputText id="hraNokName" placeholder={updatedData?.[0]?.hraNokName ? updatedData?.[0]?.hraNokName : previousData?.[0]?.hraNokName} className="Input__Round mr-2" disabled />
                                        </div>
                                    </div>

                                    <div className="p-field col-12 md:col-4">
                                        <label className="Label__Text"> CNIC</label>
                                        <InputText id="hraNokCnic" placeholder={updatedData?.[0]?.hraNokCnic ? updatedData?.[0]?.hraNokCnic : previousData?.[0]?.hraNokCnic} className="Input__Round mr-2" disabled />
                                    </div>

                                    <div className="p-field col-12 md:col-4">
                                        <label className="Label__Text">Mobile Number</label>
                                        <InputText id="hraNokMobileNo" placeholder={updatedData?.[0]?.hraNokMobileNo ? updatedData?.[0]?.hraNokMobileNo : previousData?.[0]?.hraNokMobileNo} className="Input__Round mr-2" disabled />
                                    </div>

                                    <div className="p-field col-12 md:col-4">
                                        <label className="Label__Text">Relationship</label>
                                        <InputText
                                            id="hraNokRelationshipName"
                                            placeholder={updatedData?.[0]?.hraNokRelationshipName ? updatedData?.[0]?.hraNokRelationshipName : previousData?.[0]?.hraNokRelationshipName}
                                            className="Input__Round mr-2"
                                            disabled
                                        />
                                    </div>

                                    <div className="p-field col-12 md:col-4">
                                        <label className="Label__Text">International Remittance Location</label>
                                        <InputText id="hraInternationalRemittanceLocation" placeholder={previousData?.[0]?.internationalRemittanceLocation} className="Input__Round mr-2" disabled />
                                    </div>

                                    <div className="p-field col-12 md:col-4">
                                        <label className="Label__Text">Relationship with Originator</label>
                                        <InputText
                                            id="hraOriginatorRelationshipName"
                                            placeholder={updatedData?.[0]?.hraOriginatorRelationshipName ? updatedData?.[0]?.hraOriginatorRelationshipName : previousData?.[0]?.hraOriginatorRelationshipName}
                                            className="Input__Round mr-2"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </section>

                            {/* HRA LIMITS */}
                            <div className="p-fluid p-formgrid grid mb-3">
                                <div className="p-field col-12 md:col-4">
                                    <label className="Label__Text">Maximum Balance</label>
                                    <InputText placeholder={previousData?.[0]?.maximumBalance} className="Input__Round" disabled />
                                </div>
                            </div>

                            {/* DEBIT CREDIT*/}
                            <DebitCredit rowData={previousData?.[0]} />

                            {/* TRANSACTION*/}
                            <Transaction rowData={previousData?.[0]} />
                        </>
                    ) : (
                        ''
                    )}
                </div>

                <div className="mt-6">
                    <h5 className="secondary-color">
                        <b>ACCOUNT MAINTENANCE</b>
                    </h5>
                    <Divider />
                </div>

                {/* Dialog data */}

                <div className="flex justify-content-center">
                    <div className={closeRequestDataByCNIC === '' || closeRequestDataByCNIC === undefined ? 'edit-ac-buttons Disabled__AccountMaintenance' : 'edit-ac-buttons'} onClick={handledCloseAccountCNIC}>
                        <div>Close Account Disown CNIC</div>
                    </div>

                    <div className={closeRequestData === '' || closeRequestData === undefined ? 'edit-ac-buttons Disabled__AccountMaintenance' : 'edit-ac-buttons'} onClick={handledCloseAccountMobileNo}>
                        <div>Close Account Via Mobile Number</div>
                    </div>

                    <div className={attachmentRequestData === '' || attachmentRequestData === undefined ? 'edit-ac-buttons Disabled__AccountMaintenance' : 'edit-ac-buttons'}>
                        <div>Email Document to Customer</div>
                    </div>

                    <div className={objectName === 'raastLinkingRequest' ? 'edit-ac-buttons' : 'edit-ac-buttons Disabled__AccountMaintenance'}>
                        <div>Delink Raast AMAA</div>
                    </div>

                    <div className={objectName === 'regenerateLoginPinRequest' ? 'edit-ac-buttons' : 'edit-ac-buttons Disabled__AccountMaintenance'}>
                        <div>Regenerate Login Pin</div>
                    </div>

                    <div className="edit-ac-buttons Disabled__AccountMaintenance">
                        <div>View Customer Documents</div>
                    </div>

                    <div className="edit-ac-buttons Disabled__AccountMaintenance">
                        <div>Download Account Forms</div>
                    </div>
                </div>

                <div className="custom-card mt-6">
                    <div className="p-fluid p-formgrid grid mb-2">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Status</label>
                                <span className="steric">*</span>
                                <Dropdown id="action" placeholder="Select Action" options={statusLovData} optionLabel="name" optionValue="lovId" value={statusAction} onChange={(e) => setStatusAction(e.target.value)} className="Dropdown__Round" />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-8">
                            <div className="p-field">
                                <label className="Label__Text">Comments</label>
                                <span className="steric">*</span>
                                <InputText id="checkerComments" value={comments} onChange={(e) => setComments(e.target.value)} className="Input__Round" min={10} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="Down__Btn mt-6 pt-6">
                    <Button label="Update" iconPos="right" className="Btn__Dark" onClick={handleSubmit} />
                    <Button label="Cancel" iconPos="right" className="Btn__cancel" onClick={goBack} />
                </div>
            </div>
        </>
    );
};

export default AccountDetailsCheckerEdit;

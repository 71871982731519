import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { serverURL } from '../../../Config';

function AddDeviceFinancing() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');
    const [productLov, setProductLov] = useState([]);
    const [fileSizeError, setFileSizeError] = useState('');

    const [tncFile, setTncFile] = useState('');
    const [tncFilePathData, setTncFilePathData] = useState('');

    const tncFileRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getAllProducts = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/lending/TBL_LMS_PRODUCT');
        setProductLov(res?.payLoad);
    };

    useEffect(() => {
        getAllProducts();
    }, []); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            productId: '',
            isLoanDisburse: false,
            description: '',
            disbursementType: '',
            disbursementDateTime: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const unformatDisbursementDateTime = data?.disbursementDateTime ? data?.disbursementDateTime : '';

            delete data['file'];

            if (data?.disbursementType === 'N') {
                delete data['disbursementDateTime'];
            } else {
                data['disbursementDateTime'] = formatDate(data?.disbursementDateTime);
            }

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const formData = new FormData();
            // formData.append('request', JSON.stringify(newData));

            const blob = new Blob([JSON.stringify(newData)], { type: 'application/json' });
            formData.append('request', blob);
            // Append the file to formData
            formData.append('file', tncFile || tncFilePathData);

            const res = await dispatch(handlePostRequest(formData, '/lending/v1/lending/createbulkloan', true, false, '', process.env.REACT_APP_LENDING_SUCCESS_CODE));

            if (res.responseCode === process.env.REACT_APP_LENDING_SUCCESS_CODE) {
                formik.resetForm();
                navigate('/devicefinancing');
            } else {
                formik.setFieldValue('disbursementDateTime', unformatDisbursementDateTime);
                formik.setFieldValue('file', tncFile?.name);
            }

            setloading(false);
            setloadingIcon('');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleTncFileInput = () => {
        tncFileRef.current.click();
    };

    const tncFileChange = (event) => {
        const file = event.target.files[0];
        if (file?.size > 2 * 1024 * 1024) {
            setFileSizeError('File size should be less than 2mb');
        } else {
            setFileSizeError('');
        }
        formik.setFieldValue('file', file?.name);
        setTncFile(file);
    };

    const disbursementTypeLov = [
        { lovId: 'S', name: 'Schedule' },
        { lovId: 'N', name: 'Normal' }
    ];

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);

        const day = date.getDate()?.toString().padStart(2, '0');
        const month = (date.getMonth() + 1)?.toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours()?.toString().padStart(2, '0');
        const minutes = date.getMinutes()?.toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours from 24-hour to 12-hour format
        const formattedHours = hours % 12 || 12;

        const formattedDate = `${day}-${month}-${year}, ${formattedHours}:${minutes} ${ampm}`;
        return formattedDate;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>ADD NEW</h2>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="productId" className={classNames({ 'p-error': isFormFieldValid('productId') }, 'Label__Text')}>
                                    Loan Product
                                </label>
                                <Dropdown
                                    id="productId"
                                    placeholder="Select Loan Product"
                                    options={productLov}
                                    optionLabel="name"
                                    name="productId"
                                    optionValue="lovId"
                                    value={formik.values.productId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('productId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <div className="mb-2">
                                    <label htmlFor="file" className={'Label__Text'}>
                                        Source File
                                        <a download={true} href={`${serverURL}/document/DeviceSample.csv`} target="_blank" className="Download__Link">
                                            - DownLoad Sample File
                                        </a>
                                    </label>
                                    <div className="file-input-container w-100" onClick={handleTncFileInput}>
                                        <input type="file" accept=".xls,.xlsx,.csv" style={{ display: 'none' }} ref={tncFileRef} onChange={tncFileChange} />
                                        <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={formik?.values?.file || ''} name="file" readOnly />
                                        <span className="file-upload-icon">
                                            <i className="pi pi-paperclip" />
                                        </span>
                                    </div>
                                    {fileSizeError ? <small className="p-error">{fileSizeError}</small> : getFormErrorMessage('file')}
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="isLoanDisburse" className={classNames({ 'p-error': isFormFieldValid('isLoanDisburse') }, 'Label__Text')}>
                                    Disburse Loan Amount
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isLoanDisburse" name="isLoanDisburse" checked={formik?.values?.isLoanDisburse} onChange={formik.handleChange} style={{ marginTop: '10px' }} />{' '}
                                </div>
                                {getFormErrorMessage('isLoanDisburse')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') }, 'Label__Text')}>
                                    Description
                                </label>
                                <InputTextarea
                                    id="description"
                                    placeholder="Enter Description"
                                    name="description"
                                    rows={10}
                                    value={formik.values.description || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('description') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('description')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="disbursementType" className={classNames({ 'p-error': isFormFieldValid('disbursementType') }, 'Label__Text')}>
                                    Disbursement Type
                                </label>
                                <Dropdown
                                    id="disbursementType"
                                    placeholder="Select Disbursement Type"
                                    options={disbursementTypeLov}
                                    optionLabel="name"
                                    name="disbursementType"
                                    optionValue="lovId"
                                    value={formik.values.disbursementType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('disbursementType') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('disbursementType')}
                            </div>
                        </div>
                    </div>

                    {formik?.values?.disbursementType === 'S' && (
                        <div className="card pt-3" style={{ background: '#f8f8f8' }}>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="disbursementDateTime" className={classNames({ 'p-error': isFormFieldValid('disbursementDateTime') }, 'Label__Text')}>
                                            Date Time
                                            {/* <span className="Label__Required">*</span> */}
                                        </label>
                                        {/* <Calendar value={date} onChange={(e) => setDate(e.value)} /> */}
                                        <InputText
                                            id="disbursementDateTime"
                                            type="datetime-local"
                                            name="disbursementDateTime"
                                            value={formik.values.disbursementDateTime || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('disbursementDateTime') }, 'Dropdown__Round')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="Down__Btn">
                        <Button type="submit" label="Submit" className="Btn__Dark" loadingIcon={loadingIcon || ''} />
                        <Button disabled={loading} onClick={() => navigate('/devicefinancing')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddDeviceFinancing;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../services/GetTemplate';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useLocation } from 'react-router-dom';
import { handlePostRequest } from '../../services/PostTemplate';

function EditUsergroup() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [accordionData, setAccordionData] = useState([]);
    const [partnerLovData, setPartnerLovData] = useState([]);
    const [partnerTypeLovData, setPartnerTypeLovData] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const roleId = query.get('roleId');

    const getRoleData = async () => {
        const res = await handleGetRequest(`/mfs_user_management/v1/management/getRoleWiseModule/${roleId}`);
        const keyData = res?.payLoad;

        setAccordionData(keyData);
    };

    const getUserGroupData = async () => {
        const res = await handleGetRequest(`/mfs_user_management/v1/management/getRole/${roleId}`);
        const keyData = res?.payLoad;

        Object.keys(keyData).forEach((key) => {
            if (formik.initialValues.hasOwnProperty(key)) {
                formik.setFieldValue(key, keyData[key]);
            }
        });

        formik.setFieldValue('partnerId', keyData?.lkpPartner?.partnerId?.toString());
        formik.setFieldValue('partnerType', keyData?.lkpPartner?.lkpAppUserType?.appUserTypeId.toString());
        formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
    };

    useEffect(() => {
        if (roleId !== null || roleId !== 'undefined') {
            getRoleData();
            getUserGroupData();
        }
        getPartnerTypeLov();
    }, []); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            roleCode: '',
            partnerId: '',
            partnerType: '',
            email: '',
            roleDescr: '',
            isActive: ''
        },

        validate: (data) => {
            let errors = {};

            if (!data.roleCode) {
                errors.roleCode = 'This Field is required.';
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
            if (!data.partnerId) {
                errors.partnerId = 'This Field is required.';
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
            if (!data.partnerType) {
                errors.partnerType = 'This Field is required.';
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
            if (!data.email) {
                errors.email = 'This Field is required.';
                window.scrollTo({ top: 0, behavior: 'smooth' });
            } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data.email)) {
                errors.email = 'Please enter a valid email address';
            }
            if (!data.roleDescr) {
                errors.roleDescr = 'This Field is required.';
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }

            if (!data?.isActive) {
                errors.isActive = 'Checkbox is required.';
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }

            return errors;
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['isActive'] = data?.isActive ? 'Y' : 'N';
            data['partnerId'] = parseInt(data?.partnerId);
            data['roleId'] = parseInt(roleId);
            data['partnerType'] = parseInt(data?.partnerType);
            data['accordionArrays'] = accordionData;

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/mfs_user_management/v1/management/updateusergroup', true, false, 'usergroup'));
            if (res?.responsecode === '030000') {
                formik.resetForm();
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getPartnerTypeLov = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getappusertypes/LKP_APP_USER_TYPE:*`);
        setPartnerTypeLovData(resp?.payLoad);
    };

    const getPartnerLov = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getpartners/${formik.values.partnerType}`);
        setPartnerLovData(resp?.payLoad);
    };

    useEffect(() => {
        if (['', null, undefined].includes(formik?.values?.partnerType)) {
            return;
        } else {
            getPartnerLov();
        }
    }, [formik?.values?.partnerType]); // eslint-disable-line

    const toggleCheckbox = (moduleId, rowIndex, columnName) => {
        const updatedAccordionData = accordionData.map((accordionItem) => {
            if (accordionItem.moduleId === moduleId) {
                const updatedRows = accordionItem.tblMenus.map((row) => {
                    if (row.menuId === rowIndex) {
                        const updatedRoleRights = {
                            ...row.tblRoleRights[0],
                            [columnName]: row.tblRoleRights[0][columnName] === 'Y' ? 'N' : 'Y'
                        };
                        return { ...row, tblRoleRights: [updatedRoleRights] };
                    }
                    return row;
                });
                return { ...accordionItem, tblMenus: updatedRows };
            }
            return accordionItem;
        });

        setAccordionData(updatedAccordionData);
    };

    const checkboxBodyTemplate = (rowData, column) => {
        return <Checkbox checked={rowData?.tblRoleRights[0][column] === 'Y'} onChange={() => toggleCheckbox(rowData.moduleId, rowData.menuId, column)} />;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>EDIT DETAILS</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="roleCode" className={classNames({ 'p-error': isFormFieldValid('roleCode') }, 'Label__Text')}>
                                    Group Name
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="roleCode"
                                    placeholder="Enter Group Name"
                                    name="roleCode"
                                    value={formik?.values?.roleCode?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('roleCode') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('roleCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="partnerType" className={classNames({ 'p-error': isFormFieldValid('partnerType') }, 'Label__Text')}>
                                    Partner Type
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="partnerType"
                                    placeholder="Select Partner Type"
                                    options={partnerTypeLovData}
                                    optionLabel="name"
                                    name="partnerType"
                                    optionValue="lovId"
                                    value={formik.values.partnerType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('partnerType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('partnerType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="partnerId" className={classNames({ 'p-error': isFormFieldValid('partnerId') }, 'Label__Text')}>
                                    Partners
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="partnerId"
                                    placeholder="Select Partners"
                                    options={partnerLovData}
                                    optionLabel="name"
                                    name="partnerId"
                                    optionValue="lovId"
                                    value={formik.values.partnerId || ''}
                                    disabled={formik.values.partnerType ? false : true}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('partnerId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('partnerId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') }, 'Label__Text')}>
                                    Email
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    type="email"
                                    id="email"
                                    placeholder="Enter email"
                                    name="email"
                                    value={formik?.values?.email}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('email') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('email')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="roleDescr" className={classNames({ 'p-error': isFormFieldValid('roleDescr') }, 'Label__Text')}>
                                    Description
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={500}
                                    id="roleDescr"
                                    placeholder="Enter Description"
                                    name="roleDescr"
                                    value={formik?.values?.roleDescr?.replace(/\s\s+/g, '')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('roleDescr') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('roleDescr')}
                            </div>
                        </div>
                        {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                    Active <span className="Label__Required">*</span>
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isActive" name="isActive" value={formik.values.isActive} checked={formik.values.isActive} onChange={formik.handleChange} />
                                </div>
                                {getFormErrorMessage('isActive')}
                            </div>
                        </div> */}
                        <div className="p-field col-12 md:col-4 pt-3 pb-3 flex align-items-end">
                            <div className="p-field" style={{ display: 'flex' }}>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isActive" name="isActive" value={formik.values.isActive} checked={formik.values.isActive} onChange={formik.handleChange} />
                                </div>
                                <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                    Active<span className="Label__Required">*</span>
                                </label>
                                {getFormErrorMessage('isActive')}
                            </div>
                        </div>
                        <div className="Form__Header Full__Width">
                            <h1>PERMISSION</h1>
                        </div>
                        <div className="Full__Width">
                            <div className="Full__Length">
                                <Accordion>
                                    {accordionData?.map((accordionItem) => (
                                        <AccordionTab key={accordionItem?.moduleId} header={accordionItem?.moduleDescr}>
                                            <DataTable value={accordionItem.tblMenus} showGridlines>
                                                <Column field="menuDescr" header="Menu Name" style={{ textAlign: 'center' }} className="Table__Checkboxalign"></Column>
                                                <Column
                                                    field="tblRoleRights[0].inserAllowed"
                                                    header="Create"
                                                    body={(rowData) => checkboxBodyTemplate({ ...rowData, moduleId: accordionItem.moduleId }, 'inserAllowed')}
                                                    style={{ textAlign: 'center' }}
                                                    className="Table__Checkboxalign"
                                                ></Column>
                                                <Column
                                                    field="tblRoleRights[0].viewAllowed"
                                                    header="Read"
                                                    body={(rowData) => checkboxBodyTemplate({ ...rowData, moduleId: accordionItem.moduleId }, 'viewAllowed')}
                                                    style={{ textAlign: 'center' }}
                                                    className="Table__Checkboxalign"
                                                ></Column>
                                                <Column
                                                    field="tblRoleRights[0].updateAllowed"
                                                    header="Update"
                                                    body={(rowData) => checkboxBodyTemplate({ ...rowData, moduleId: accordionItem.moduleId }, 'updateAllowed')}
                                                    style={{ textAlign: 'center' }}
                                                    className="Table__Checkboxalign"
                                                ></Column>
                                                <Column
                                                    field="tblRoleRights[0].deleteAllowed"
                                                    header="Delete"
                                                    body={(rowData) => checkboxBodyTemplate({ ...rowData, moduleId: accordionItem.moduleId }, 'deleteAllowed')}
                                                    style={{ textAlign: 'center' }}
                                                    className="Table__Checkboxalign"
                                                ></Column>
                                            </DataTable>
                                        </AccordionTab>
                                    ))}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon || ''} label="Update" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/usergroup')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default EditUsergroup;

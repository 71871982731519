import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { Button } from 'primereact/button';

const ViewAppVersion = ({ rowData, onHide, userTabsFieldData }) => {

    const [appVersion, setAppVersion] = useState([])

    const getAppVersionById = async () => {
        const response = await handleGetRequest(`/account/v1/customeraccounts/getDataByAppVersionName/${rowData?.customerId || rowData?.[0]?.customerId}`);
        if (response?.responseCode === '010000') {
            setAppVersion(response?.payLoad);
        }
    };

    useEffect(() => {
        getAppVersionById();
    }, []);

    const checkFieldPermissions = (fieldName) => {
        const field = userTabsFieldData.find(f => f.fieldName === fieldName);
        return {
            viewAllowed: field?.viewAllowed === "Y",
            updateAllowed: field?.updateAllowed === "Y"
        };
    };

    const appVersionNameFieldPermissions = checkFieldPermissions("appVersionName");
    const deviceModelFieldPermissions = checkFieldPermissions("deviceModel");
    const deviceTypeFieldPermissions = checkFieldPermissions("deviceType");
    const lastupdatedateFieldPermissions = checkFieldPermissions("lastupdatedate");
    const osVersionFieldPermissions = checkFieldPermissions("osVersion");

    const handleCancelSubmit = () => {
        onHide()
    }

    return (
        <>

            {/* View Whitelist Popup */}
            {/* <Dialog header="EDIT DETAILS" visible={whitelistDialog} style={{ width: '25vw' }} draggable={false} resizable={false}>
                <EditWhitelistDevice
                    rowData={rowData}
                    formikValuees={formikValuees}
                />
            </Dialog> */}

            <div className='Card__Round'>

                {appVersionNameFieldPermissions.viewAllowed && (
                    <div className='mb-2'>
                        <label className='Label__Text'>
                            App Version
                        </label>
                        <InputText
                            value={appVersion?.appVersionName ?? ""}
                            className='Input__Round'
                            disabled
                        />
                    </div>
                )}
                {deviceModelFieldPermissions.viewAllowed && (
                    <div className="mb-2">
                        <label className="Label__Text">Handset Model</label>
                        <InputText value={appVersion?.deviceModel ?? ''} className="Input__Round" disabled />
                    </div>
                )}
                {deviceTypeFieldPermissions.viewAllowed && (
                    <div className="mb-2">
                        <label className="Label__Text">Operating System</label>
                        <InputText value={appVersion?.deviceType ?? ''} className="Input__Round" disabled />
                    </div>
                )}
                <div className="mb-2">
                    <label className="Label__Text">Last Screen Visual</label>
                    <InputText value="" className="Input__Round" disabled />
                </div>
                <div className="mb-2">
                    <label className="Label__Text">Last Error Visibility</label>
                    <InputText value="" className="Input__Round" disabled />
                </div>
                <div className="mb-2">
                    <label className="Label__Text">Date of download</label>
                    <InputText value="" className="Input__Round" disabled />
                </div>
                {lastupdatedateFieldPermissions.viewAllowed && (
                    <div className="mb-2">
                        <label className="Label__Text">Last Updated Date</label>
                        <InputText value={appVersion?.lastupdatedate ?? ''} className="Input__Round" disabled />
                    </div>
                )}
                {osVersionFieldPermissions.viewAllowed && (
                    <div className="mb-2">
                        <label className="Label__Text">Current Version</label>
                        <InputText value={appVersion?.osVersion ?? ''} className="Input__Round" disabled />
                    </div>
                )}
                <div className="mb-2">
                    <label className="Label__Text">Rooted Device</label>
                    <InputText value="" className="Input__Round" disabled />
                </div>

                <div className='mb-2'>
                    <label className='Label__Text'>
                        Whitelisted Device
                    </label>
                    <div className='flex align-items-center'>
                        <InputText
                            value="-"
                            className='Input__Round mr-2'
                            disabled
                        />
                        {/* <i className={`field-edit-icon pi ${accountStatusName === 'CLOSED' ? "pi-ban" : "pi-pencil"}`} onClick={accountStatusName === 'CLOSED' ? "" : handleWhitelistDialog} /> */}
                    </div>
                </div>

                <div className="mb-2">
                    <label className="Label__Text">App Simulator</label>
                    <InputText value="" className="Input__Round" disabled />
                </div>

                <div className="mb-2">
                    <label className="Label__Text">Terms & Condition</label>
                    <InputText value="" className="Input__Round" disabled />
                </div>

                <div className="Down__Btn mt-4">
                    <Button label="Okay" iconPos="right" className="Btn__cancel" type="button" onClick={handleCancelSubmit} />
                </div>
            </div>
        </>
    );
};

export default ViewAppVersion;

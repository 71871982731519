import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../services/PostTemplate';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';

const ChangePasswordOTP = ({ userDetails }) => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            newPassword: '',
            confirmPassword: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.newPassword) {
                errors.newPassword = 'New Password is required.';
            }
            if (!data.confirmPassword) {
                errors.confirmPassword = 'Confirm New Password is required.';
            }
            if (data.newPassword !== data.confirmPassword) {
                errors.confirmPassword = 'Passwords are not matched';
            }

            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            delete data['confirmPassword'];

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        email: userDetails?.email,
                        newPass: data?.newPassword
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/mfs_user_management/v1/management/forgotpassword', true, true));
            if (res?.responseCode === '0000') {
                formik.resetForm();
                navigate('/');
                window.location.reload();
            }
            setloading(false);
            setloadingIcon('pi pi-save');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <div className="Forget__Body">
                <div className="Container__ChangePassword" id="container">
                    <div className="form-container sign-in-container">
                        <form action="#" className="Login__Form" onSubmit={formik.handleSubmit}>
                            <div className="p-mb-4">
                                <h3 className="forgetHeading">CREATE NEW PASSWORD</h3>
                            </div>
                            <div className="p-mt-4">
                                <div className="p-field p-text-left">
                                    <label htmlFor="newPassword" className={classNames({ 'p-error': isFormFieldValid('newPassword') })}>
                                        New Password
                                    </label>
                                    <InputText
                                        id="newPassword"
                                        className={classNames({ 'p-invalid': isFormFieldValid('newPassword') }, 'Forget__Input')}
                                        name="newPassword"
                                        value={formik.values.newPassword || ''}
                                        placeholder="Enter New Password"
                                        onChange={formik.handleChange}
                                        type="password"
                                    />
                                    {getFormErrorMessage('newPassword')}
                                </div>
                                <div className="p-field p-text-lef mt-5">
                                    <label htmlFor="confirmPassword" className={classNames({ 'p-error': isFormFieldValid('confirmPassword') })}>
                                        Confirm Password
                                    </label>
                                    <InputText
                                        className={classNames({ 'p-invalid': isFormFieldValid('confirmPassword') }, 'Forget__Input')}
                                        name="confirmPassword"
                                        placeholder="Enter Confirm Password"
                                        value={formik.values.confirmPassword || ''}
                                        onChange={formik.handleChange}
                                        type="password"
                                    />
                                    {getFormErrorMessage('confirmPassword')}
                                </div>

                                <div className="p-mt-4">
                                    <Button className="Login__Button" label="Submit" icon={loadingIcon || ''} iconPos="right" disabled={loading} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChangePasswordOTP;

import React from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

const EditPinGeneration = ({ onAccountPinGenerationChange, formikValuees, onHide }) => {

    const pinGenerationLov = [
        // { label: "Ama", id: 1 },
        { label: "App", id: 2 }
    ]

    const validationSchema = Yup.object().shape({
        pinGeneration: Yup.string().required('This Field is required.'),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            pinGeneration: formikValuees?.pinGeneration ? formikValuees?.pinGeneration : "",
        },

        onSubmit: async () => {
            onAccountPinGenerationChange({ pinGeneration: formik.values.pinGeneration })
            onHide();
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className='Card__Round'>

                    <div className="p-fluid p-formgrid grid">
                        <div className="p-field col-12">

                            <div>
                                <label className='Label__Text'>
                                    Channel
                                </label>
                                <Dropdown
                                    id="pinGeneration"
                                    name="pinGeneration"
                                    options={pinGenerationLov}
                                    optionLabel='label'
                                    optionValue='id'
                                    value={formik.values.pinGeneration}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pinGeneration') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage("pinGeneration")}
                            </div>

                        </div>
                    </div>

                    <div className='Down__Btn mt-4'>
                        <Button label="Confirm" iconPos="right" className="Btn__Dark" type="submit" />
                        <Button label="Cancel" iconPos="right" className="Btn__cancel" type="button" onClick={onHide} />
                    </div>

                </div>
            </form>
        </>
    )

}

export default EditPinGeneration
import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';

import { InputTextarea } from 'primereact/inputtextarea';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';

import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function EditScheduledBulkPaymentsChecker({}) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    let { search } = useLocation();

    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const tableName = query.get('tableName');

    const [statusLovData, setStatusLovData] = useState([]);

    const [batchData, setBatchData] = useState({});
    const [ProductLovData, setProductLovData] = useState([]);
    const [accountStatus, setAccountStatus] = useState([]);

    const mcRequestId = query.get('mcRequestId');

    const mcPeindingRequestId = query.get('mcPendingRequest');

    //get data by ref table id

    const validationSchema = Yup.object({
        action: Yup.string().required('Action should not be empty'),

        checkerComments: Yup.string().required('This Field is required').notOneOf([''], 'This Field should not be empty').max(500, 'Maximum 500 characters needed')
    });

    const dt = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            checkerComments: '',
            action: '',
            registrationNo: '',
            customerID: '',
            name: '',
            cnic: '',
            productID: '',
            accountStatusID: '',
            chequeNo: '',
            amount: '',
            paymentDateTo: '',
            transactionCode: '',
            batchID: ''
        },

        onSubmit: async (data1) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            const payLoad = {
                tblBulkOprFileId: batchData?.bulkOprFileId,
                fileStatusId: batchData?.id,
                bulkOperationType: 'BSP',
                batchId: data1?.batchID,
                mcActionRequest: {
                    mcRequestId: mcRequestId,
                    mcPeindingRequestId: mcPeindingRequestId,
                    checkerId: '1',
                    checkerComments: data1.checkerComments,
                    action: data1.action,
                    updatedIndex: null
                }
            };
            const data = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            try {
                const res = await dispatch(handlePostRequest(data, '/batchprocessing/v1/batchprocessing/checkeraction', true, false, 'requestChecker'));
            } catch (error) {}
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const getBatchInformation = async () => {
        const res = await handleGetRequest(`/batchprocessing/v1/getbulkrecordbyid/${refTableId}`);

        setBatchData(res.payLoad);
        formik.setFieldValue('registrationNo', res.payLoad.empoyeeRegNumber);
        formik.setFieldValue('name', res.payLoad.name);
        formik.setFieldValue('amount', res.payLoad.amount);
        formik.setFieldValue('cnic', res.payLoad.cnic);
        formik.setFieldValue('mobileNo', res.payLoad.mobileNumber);
        formik.setFieldValue('chequeNo', res.payLoad.chequeNumber);
        formik.setFieldValue('accountStatusID', res.payLoad?.statusId?.toString());
        formik.setFieldValue('transactionCode', res.payLoad.transactionId);
        formik.setFieldValue('batchID', res.payLoad.batchNumber);
        formik.setFieldValue('customerID', res.payLoad.customerId);
        formik.setFieldValue('productID', res.payLoad.productId.toString());
        formik.setFieldValue('paymentDateTo', res.payLoad?.paymentDate ? new Date(res.payLoad?.paymentDate)?.toISOString()?.split('T')[0] : new Date());
    };

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = res?.payLoad.filter((item) => item.name !== 'Pending' && item.lovId !== '4');
        setStatusLovData(updatedArrayWithoutPending);
    };
    const getAccountStatus = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getAccountStatus/LKP_ACCOUNT_STATUS:*');

        setAccountStatus(res.payLoad);
    };

    const product = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setProductLovData(res?.payLoad);
    };

    useEffect(() => {
        getBatchInformation();
        getAccountStatus();
        product();
    }, [mcRequestId]); // eslint-disable-line

    useEffect(() => {
        getStatusListData();
    }, []); // eslint-disable-line

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1> Dissbursement Checker Review</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="grid">
                        <div className="col-12">
                            <div className="card">
                                <div className="p-formgrid grid">
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="registrationNo" className={classNames({ 'p-error': isFormFieldValid('registrationNo') }, 'Label__Text')}>
                                                EMP/Reg No.
                                            </label>
                                            <InputText
                                                id="registrationNo"
                                                placeholder="Enter  EMP/Reg No."
                                                name="registrationNo"
                                                value={formik?.values?.registrationNo || ''}
                                                disabled
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('registrationNo') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('registrationNo')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="customerID" className={classNames({ 'p-error': isFormFieldValid('customerID') }, 'Label__Text')}>
                                                Customer ID
                                            </label>
                                            <InputText
                                                id="customerID"
                                                placeholder="Enter  EMP/Reg No."
                                                name="customerID"
                                                disabled
                                                value={formik?.values?.customerID || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('customerID') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('customerID')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                                Name
                                            </label>
                                            <InputText
                                                id="name"
                                                placeholder="Enter Namet"
                                                name="name"
                                                disabled
                                                value={formik?.values?.name || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('name')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="cnic" className={classNames({ 'p-error': isFormFieldValid('cnic') }, 'Label__Text')}>
                                                CNIC
                                            </label>
                                            <InputText
                                                id="cnic"
                                                placeholder="Enter CNIC"
                                                name="cnic"
                                                disabled
                                                value={formik?.values?.cnic || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('cnic') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('cnic')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="mobileNo" className={classNames({ 'p-error': isFormFieldValid('mobileNo') }, 'Label__Text')}>
                                                Mobile Number
                                            </label>
                                            <InputText
                                                id="mobileNo"
                                                placeholder="Enter Mobile Number"
                                                name="mobileNo"
                                                disabled
                                                value={formik?.values?.mobileNo || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('mobileNo') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('mobileNo')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="productID" className={classNames({ 'p-error': isFormFieldValid('productID') }, 'Label__Text')}>
                                                Product
                                            </label>

                                            <Dropdown
                                                id="productID"
                                                placeholder="Select productID"
                                                options={ProductLovData}
                                                disabled
                                                optionLabel="name"
                                                name="productID"
                                                optionValue="lovId"
                                                value={formik.values.productID || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('productID') }, 'Dropdown__Round')}
                                            />

                                            {getFormErrorMessage('productID')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="accountType" className={classNames({ 'p-error': isFormFieldValid('acCreationStatus') }, 'Label__Text')}>
                                                A/C Creation Status
                                            </label>
                                            <Dropdown
                                                id="accountStatusID"
                                                placeholder="Select A/C Creation Status"
                                                options={accountStatus}
                                                disabled
                                                optionLabel="accountStatusName"
                                                name="accountStatusID"
                                                optionValue="accountStatusId"
                                                value={formik.values.accountStatusID || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('accountStatusID') }, 'Dropdown__Round')}
                                            />

                                            {getFormErrorMessage('accountStatusID')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="chequeNo" className={classNames({ 'p-error': isFormFieldValid('chequeNo') }, 'Label__Text')}>
                                                Cheque Number
                                            </label>
                                            <InputText
                                                id="chequeNo"
                                                placeholder="Enter Caheque Number"
                                                name="chequeNo"
                                                disabled
                                                value={formik?.values?.chequeNo || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('chequeNo') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('chequeNo')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="amount" className={classNames({ 'p-error': isFormFieldValid('amount') }, 'Label__Text')}>
                                                Amount
                                            </label>
                                            <InputText
                                                id="amount"
                                                placeholder="Enter Caheque Number"
                                                name="amount"
                                                disabled
                                                value={formik?.values?.amount || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('amount') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('amount')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4">
                                        <div className="p-field">
                                            <label htmlFor="paymentDateTo" className={classNames({ 'p-error': isFormFieldValid('paymentDateTo') }, 'Label__Text')}>
                                                Payment Date
                                            </label>
                                            <InputText
                                                id="paymentDateTo"
                                                type="date"
                                                max={previousDate}
                                                name="paymentDateTo"
                                                disabled
                                                value={formik?.values?.paymentDateTo}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('paymentDateTo') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('paymentDateTo')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="transactionCode" className={classNames({ 'p-error': isFormFieldValid('transactionCode') }, 'Label__Text')}>
                                                Transaction Id<span className="Label__Required">*</span>
                                            </label>
                                            <InputText
                                                id="transactionCode"
                                                placeholder="Enter Transaction Id"
                                                name="transactionCode"
                                                disabled
                                                value={formik?.values?.transactionCode || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('transactionCode') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('transactionCode')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="batchID" className={classNames({ 'p-error': isFormFieldValid('batchID') }, 'Label__Text')}>
                                                Batch Number
                                            </label>
                                            <InputText
                                                id="batchID"
                                                placeholder="Enter Batch Number"
                                                name="batchID"
                                                value={formik?.values?.batchID || ''}
                                                disabled
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('batchID') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('batchID')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                                Select Action
                                                <span className="Label__Required">*</span>
                                            </label>
                                            <Dropdown
                                                id="action"
                                                placeholder="Select Action"
                                                options={statusLovData}
                                                optionLabel="name"
                                                name="action"
                                                optionValue="lovId"
                                                value={formik.values.action || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                            />

                                            {getFormErrorMessage('action')}
                                        </div>
                                    </div>

                                    <div className="p-field col-12 md:col-6">
                                        <div className="p-field">
                                            <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                                Comments
                                                <span className="Label__Required">*</span>
                                            </label>
                                            <InputTextarea
                                                rows={5}
                                                cols={30}
                                                id="checkerComments"
                                                name="checkerComments"
                                                value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                            />

                                            {getFormErrorMessage('checkerComments')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Usergroup__SearchBtn p-field flex flex-row  justify-content-center pt-2 col-12" style={{ textAlign: 'center' }}>
                            <div className="Down__Btn">
                                <Button disabled={loading} loadingIcon={loadingIcon || ''} label="Submit" className="Btn__Dark" />
                                <Button disabled={loading} type="button" onClick={() => navigate('/requestChecker')} label="Cancel" className="Btn__Transparent" />
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default EditScheduledBulkPaymentsChecker;

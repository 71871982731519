import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useRef } from 'react';

function ViewBiller() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [businessTypeData, setBusinessTypeData] = useState([]);
    const [reasonData, setReasonData] = useState([]);
    const [kamData, setKamData] = useState([]);
    const [checkboxDisabled, setCheckboxDisabled] = useState(false);

    const [productIconFileName, setProductIconFileName] = useState();
    const [productIconBase64, setProductIconBase64] = useState('');

    const productIconRef = useRef(null);

    const getBusinessTypeData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getbusinesstypes/LKP_BUSINESS_TYPE:*');
        setBusinessTypeData(res?.payLoad);
    };

    const getReasonData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getallregions/LKP_REGION:*');
        setReasonData(resp?.payLoad);
    };

    const getKamData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getkamdata/TBL_SALES_ROLE_DETAIL:*');
        setKamData(resp?.payLoad);
    };

    useEffect(() => {
        getBusinessTypeData();
        getReasonData();
        getKamData();
    }, []);

    const validationSchema = Yup.object().shape({
        clientEmail: Yup.string().matches('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$', 'Please enter valid email'),
        segmentName: Yup.string().required('This field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            productName: '',
            displayNameEnglish: '',
            displayNameUrdu: '',
            productId: '',
            addTag: false,
            tagLabel: '',
            tagTextColor: '',
            tagExpiryDate: '',
            category: '',
            subCategory: '',
            enablePartialPayment: '',
            enable: '',
            disable: '',
            fields: false,
            button: false,
            selectButton: '',
            text: '',
            selectField: '',
            title: '',
            characterLimit: '',
            placeholderValue: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(newData, '/account/v1/segments/createsegment', true, false, 'segment'));
            if (res?.responseCode === '010000') {
                formik.resetForm();
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    // Product Icon file code
    const handledProductIconClick = () => {
        productIconRef.current.click();
    };

    const handleCnicChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            setProductIconFileName(selectedFile.name); // Update the selected file name
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                setProductIconBase64(base64Data);
          
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>ADD NEW</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productName" className={classNames({ 'p-error': isFormFieldValid('productName') }, 'Label__Text')}>
                                    Code <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="productName"
                                    placeholder=""
                                    name="productName"
                                    value={formik?.values?.productName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('productName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="displayNameEnglish" className={classNames({ 'p-error': isFormFieldValid('displayNameEnglish') }, 'Label__Text')}>
                                    Email <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="displayNameEnglish"
                                    placeholder=""
                                    name="displayNameEnglish"
                                    value={formik?.values?.displayNameEnglish?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('displayNameEnglish') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('displayNameEnglish')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="displayNameEnglish" className={classNames({ 'p-error': isFormFieldValid('displayNameEnglish') }, 'Label__Text')}>
                                    Phone number <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="displayNameEnglish"
                                    placeholder=""
                                    name="displayNameEnglish"
                                    value={formik?.values?.displayNameEnglish?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('displayNameEnglish') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('displayNameEnglish')}
                            </div>
                        </div>


                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="displayNameEnglish" className={classNames({ 'p-error': isFormFieldValid('displayNameEnglish') }, 'Label__Text')}>
                                    Product Code <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="displayNameEnglish"
                                    placeholder=""
                                    name="displayNameEnglish"
                                    value={formik?.values?.displayNameEnglish?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('displayNameEnglish') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('displayNameEnglish')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="displayNameEnglish" className={classNames({ 'p-error': isFormFieldValid('displayNameEnglish') }, 'Label__Text')}>
                                    Description <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="displayNameEnglish"
                                    placeholder=""
                                    name="displayNameEnglish"
                                    value={formik?.values?.displayNameEnglish?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('displayNameEnglish') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('displayNameEnglish')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="displayNameEnglish" className={classNames({ 'p-error': isFormFieldValid('displayNameEnglish') }, 'Label__Text')}>
                                    Parser Head <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="displayNameEnglish"
                                    placeholder=""
                                    name="displayNameEnglish"
                                    value={formik?.values?.displayNameEnglish?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('displayNameEnglish') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('displayNameEnglish')}
                            </div>
                        </div>




                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="enablePartialPayment" className={classNames({ 'p-error': isFormFieldValid('enablePartialPayment') }, 'Label__Text')}>
                                    Status <span className="Label__Required">*</span>
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox
                                        inputId="enablePartialPayment"
                                        name="enablePartialPayment"
                                        defaultChecked={true}
                                        value={formik?.values?.enablePartialPayment}
                                        checked={formik?.values?.enablePartialPayment}
                                        onChange={formik.handleChange}
                                        style={{ marginTop: '10px' }}
                                    />
                                    <label htmlFor="enablePartialPayment" className={classNames({ 'p-error': isFormFieldValid('enablePartialPayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        active
                                    </label>
                                </div>
                                {getFormErrorMessage('enablePartialPayment')}
                            </div>
                        </div>



                    </div>

                    {formik?.values?.button && (
                        <div className="card Card__Bg__Change">
                            <h6 className="secondary-color">
                                <b>Button</b>
                            </h6>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="selectButton" className={classNames({ 'p-error': isFormFieldValid('selectButton') }, 'Label__Text')}>
                                            Select Button
                                        </label>
                                        <Dropdown
                                            id="selectButton"
                                            placeholder="Select Button"
                                            options={reasonData}
                                            optionLabel="name"
                                            name="selectButton"
                                            optionValue="lovId"
                                            value={formik.values.selectButton || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('selectButton') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('selectButton')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="text" className={classNames({ 'p-error': isFormFieldValid('text') }, 'Label__Text')}>
                                            Text
                                        </label>
                                        <InputText placeholder="Enter text" id="text" name="text" value={formik?.values?.text || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('text') }, 'Input__Round')} />
                                        {getFormErrorMessage('text')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {formik?.values?.fields && (
                        <div className="card Card__Bg__Change">
                            <h6 className="secondary-color">
                                <b>Fields</b>
                            </h6>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="selectField" className={classNames({ 'p-error': isFormFieldValid('selectField') }, 'Label__Text')}>
                                            Select Field
                                        </label>
                                        <Dropdown
                                            id="selectField"
                                            placeholder="Select Field"
                                            options={reasonData}
                                            optionLabel="name"
                                            name="selectField"
                                            optionValue="lovId"
                                            value={formik.values.selectField || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('selectField') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('selectField')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="title" className={classNames({ 'p-error': isFormFieldValid('title') }, 'Label__Text')}>
                                            Title
                                        </label>
                                        <InputText
                                            placeholder="Enter title"
                                            id="title"
                                            name="title"
                                            value={formik?.values?.title || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('title') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('title')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="characterLimit" className={classNames({ 'p-error': isFormFieldValid('characterLimit') }, 'Label__Text')}>
                                            Character Limit
                                        </label>
                                        <InputText
                                            placeholder="Enter character Limit"
                                            id="characterLimit"
                                            name="characterLimit"
                                            value={formik?.values?.characterLimit || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('characterLimit') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('characterLimit')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="placeholderValue" className={classNames({ 'p-error': isFormFieldValid('placeholderValue') }, 'Label__Text')}>
                                            Placeholder Value
                                        </label>
                                        <InputText
                                            placeholder="Enter placeholder Value"
                                            id="placeholderValue"
                                            name="placeholderValue"
                                            value={formik?.values?.placeholderValue || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('placeholderValue') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('placeholderValue')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="Down__Btn">
                        <Button type="submit" label="okay" className="Btn__Dark" />

                    </div>

                </form>
            </div>
        </>
    );
}

export default ViewBiller;

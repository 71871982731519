import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DefaultComponent from './DefaultComponent';
import { handleGetRequest } from '../../../../services/GetTemplate';

function CheckerApis({ type }) {
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');
    const [getByIdData, setGetByIdData] = useState([]);
    const [getByMCRequestIdData, setGetByMCRequestIdData] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [urlData, setUrlData] = useState([]);

    const funcGetByMCRequestIdData = async () => {
        const resp = await handleGetRequest(`/cms/v1/cms/getmcrequestbyid/${mcRequestId}`);
        const keyData = resp?.payLoad?.updateJson;
        setGetByMCRequestIdData(JSON.parse(keyData));
    };

    const funcGetByIdData = async () => {
        const resp = await handleGetRequest(`/cms/v1/cms/getsmsconfigbyid/${refTableId}`);
        setGetByIdData(resp?.payLoad);
    };

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        setUrlData({ refTableId, requestType, mcRequestId, mcPeindingRequestId, requestTypeDescr, tableName });
    }, []);

    useEffect(() => {
        if (requestType === 'I') {
            funcGetByIdData();
        } else if (requestType === 'U') {
            funcGetByMCRequestIdData();
        }
    }, [requestType]);

    useEffect(() => {
        getStatusLov();
    }, []);

    useEffect(() => {
        setAllData({ getByMCRequestIdData, statusLovData, urlData });
    }, [getByMCRequestIdData, statusLovData, urlData]);

    return (
        <React.Fragment>
            <DefaultComponent type={type} statusLovData={statusLovData} urlData={urlData} getByMCRequestIdData={getByMCRequestIdData} getByIdData={getByIdData} />
        </React.Fragment>
    );
}

export default CheckerApis;

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';

const AddSalesHierarchy = () => {
    const [loading, setloading] = useState(false);
    const [saleStructureData, setSaleStructureData] = useState([{}]);
    const [employeeRoleList, setEmployeeRoleList] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [tblSalesForcesData, setTblSalesForcesData] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        }),
        salesStructureId: Yup.string().required('This Field is required'),
        hierarchyName: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[A-Za-z0-9 ]*$/, 'Special characters are not allowed')
            .matches(/^[a-zA-Z](?:[a-zA-Z0-9\s]*-?[a-zA-Z0-9\s]*)?$/, 'Invalid hierarchy name')
            .required('This Field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            salesStructureId: '',
            hierarchyName: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data,
                        tblSalesHierarchyDetails: tblSalesForcesData
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/account/v1/sales/createsalehierarchy', true, false, 'createsaleshierarchy'));
            setloading(false);
            formik.resetForm();
        }
    });

    const getSaleStructureData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getsaleshierarchiesdata/LKP_SALES_STRUCTURE:*');
        setSaleStructureData(resp?.payLoad);
    };

    const getEmployeeRoleList = async () => {
        setEmployeeRoleList([]);
        setSelectedOptions([]);
        const resp = await handleGetRequest(`/account/v1/sales/getSaleRoleAndSaleForceAgainstSaleStructureId/${formik.values?.salesStructureId}`);
        setEmployeeRoleList(resp?.payLoad);
    };

    useEffect(() => {
        getSaleStructureData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (formik?.values?.salesStructureId) {
            getEmployeeRoleList();
        }
    }, [formik?.values?.salesStructureId]); // eslint-disable-line react-hooks/exhaustive-deps

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        const updatedEmployeeRoleList = employeeRoleList.map((item, key) => {
            const selectedOption = selectedOptions.find((option) => option.fieldKey === key);
            if (selectedOption) {
                item.selectedValue = selectedOption.value;
            }
            return item;
        });

        setEmployeeRoleList(updatedEmployeeRoleList);

        const newPayLoad = selectedOptions.map((option) => ({
            salesForceId: option.salesForceId,
            salesRoleDetailId: option.salesRoleDetailId
        }));

        setTblSalesForcesData(newPayLoad);
    }, [selectedOptions]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOptionChange = (key, selectedValue, salesForceId, salesRoleDetailId) => {
        const existingOptionIndex = selectedOptions.findIndex((option) => option.salesRoleDetailId === salesRoleDetailId);

        if (existingOptionIndex !== -1) {
            const updatedSelectedOptions = [...selectedOptions];
            updatedSelectedOptions[existingOptionIndex] = {
                fieldKey: key,
                value: selectedValue,
                salesForceId: salesForceId,
                salesRoleDetailId: salesRoleDetailId
            };
            setSelectedOptions(updatedSelectedOptions);
        } else {
            const newSelectedOption = {
                fieldKey: key,
                value: selectedValue,
                salesForceId: salesForceId,
                salesRoleDetailId: salesRoleDetailId
            };
            setSelectedOptions((prevSelectedOptions) => [...prevSelectedOptions, newSelectedOption]);
        }
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>ADD NEW</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="hierarchyName" className={classNames({ 'p-error': isFormFieldValid('hierarchyName') }, 'Label__Text')}>
                                    Hierarchy Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={50}
                                    minLength={3}
                                    placeholder={'Enter Hierarchy Name'}
                                    id="hierarchyName"
                                    name="hierarchyName"
                                    value={formik?.values?.hierarchyName}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('hierarchyName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('hierarchyName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="salesStructureId" className={classNames({ 'p-error': isFormFieldValid('salesStructureId') }, 'Label__Text')}>
                                    Sale Structure <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="salesStructureId"
                                    placeholder="Select Sale Structure"
                                    options={saleStructureData}
                                    optionLabel="name"
                                    name="salesStructureId"
                                    optionValue="lovId"
                                    value={formik.values.salesStructureId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('salesStructureId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('salesStructureId')}
                            </div>
                        </div>
                    </div>
                    {employeeRoleList?.length > 0 && (
                        <React.Fragment>
                            <div className="Form__Header">
                                <h1>ADD SALES HIERARCHY</h1>
                            </div>
                            <div className="p-fluid p-formgrid grid mb-5">
                                {employeeRoleList?.map((item, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor={item.roleName} className={classNames({ 'p-error': isFormFieldValid('employeeList') }, 'Label__Text')}>
                                                        {item?.roleName}
                                                    </label>
                                                    <Dropdown
                                                        filter
                                                        // showClear
                                                        filterBy="label"
                                                        inputId={`employeeName-${key}`}
                                                        name={`employeeName-${key}`}
                                                        value={item.selectedValue || ''}
                                                        options={item.tblSalesForces.map((inner) => ({
                                                            label: inner.employeeName,
                                                            salesForceId: inner.salesForceId,
                                                            salesRoleDetailId: item.salesRoleDetailId
                                                        }))}
                                                        optionLabel="label"
                                                        onChange={(e) => {
                                                            handleOptionChange(key, e.value, e.value.salesForceId, item.salesRoleDetailId);
                                                        }}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('employeeList') }, 'Dropdown__Round')}
                                                    />
                                                    {getFormErrorMessage('employeeList')}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </React.Fragment>
                    )}
                    <div className="Down__Btn">
                        <Button disabled={loading || !(formik?.values?.salesStructureId && selectedOptions.length > 0 && formik?.values?.hierarchyName)} label="Submit" className="Btn__Dark" />
                        <Button onClick={() => navigate('/createsaleshierarchy')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
};
export default AddSalesHierarchy;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../services/GetTemplate';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewUsergroupChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [accordionData, setAccordionData] = useState([]);
    const [partnerLovData, setPartnerLovData] = useState([]);
    const [partnerTypeLovData, setPartnerTypeLovData] = useState([]);

    const navigate = useNavigate();

    //Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const roleId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const tableName = query.get('tableName');

    const validationSchema = Yup.object().shape({
        roleCode: Yup.string().required('This Field is required.'),
        partnerId: Yup.string().required('This Field is required.'),
        partnerType: Yup.string().required('This Field is required.'),
        email: Yup.string().email('Please Add Valid email address'),
        roleDescr: Yup.string().required('This Field is required.')
    });

    const formik = useFormik({
        validationSchema,
        initialValues: {
            roleCode: '',
            partnerId: '',
            partnerType: '',
            email: '',
            roleDescr: '',
            isActive: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getDatabyId = async () => {
        // getRoleWiseModule
        const resp = await handleGetRequest(`/mfs_user_management/v1/management/getRoleWiseModule/${roleId}`);
        const keyData = resp?.payLoad;
        setAccordionData(keyData);

        const res = await handleGetRequest(`/mfs_user_management/v1/management/getRole/${roleId}`);
        const keyRoleData = res?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyRoleData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyRoleData[key]);
                }
            });
        }

        formik.setFieldValue('partnerId', keyRoleData?.lkpPartner?.partnerId?.toString());
        formik.setFieldValue('partnerType', keyRoleData?.lkpPartner?.lkpAppUserType?.appUserTypeId.toString());
        formik.setFieldValue('isActive', keyRoleData?.isActive === 'Y' ? true : false);
    };

    const getMcRequestDataById = async () => {
        // getRoleWiseModule
        const res = await handleGetRequest(`/mfs_user_management/v1/management/getRoleWiseModule/${roleId}`);
        const keyDataRole = res?.payLoad;
        setAccordionData(keyDataRole);

        const resp = await handleGetRequest(`/mfs_user_management/v1/management/getRoleByMcRequestId/${mcRequestId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
        }
        formik.setFieldValue('partnerId', keyData?.partnerId?.toString());
        formik.setFieldValue('partnerType', keyData?.partnerType?.toString());
        formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
    };

    const getPartnerTypeLov = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getappusertypes/LKP_APP_USER_TYPE:*`);
        setPartnerTypeLovData(resp?.payLoad);
    };

    const getPartnerLov = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getpartners/${formik.values.partnerType}`);
        setPartnerLovData(resp?.payLoad);
    };

    useEffect(() => {
        if (['', null, undefined].includes(formik?.values?.partnerType)) {
            return;
        } else {
            getPartnerLov();
        }
    }, [formik?.values?.partnerType]); // eslint-disable-line

    useEffect(() => {
        getPartnerTypeLov();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, []); // eslint-disable-line

    const toggleCheckbox = (moduleId, rowIndex, columnName) => {
        const updatedAccordionData = accordionData.map((accordionItem) => {
            if (accordionItem.moduleId === moduleId) {
                const updatedRows = accordionItem.tblMenus.map((row) => {
                    if (row.menuId === rowIndex) {
                        return { ...row, [columnName]: row[columnName] === 'Y' ? '' : 'Y' };
                    }
                    if ([columnName]) {
                    }
                    return row;
                });
                return { ...accordionItem, tblMenus: updatedRows };
            }
            return accordionItem;
        });

        setAccordionData(updatedAccordionData);
    };

    const checkboxBodyTemplate = (rowData, column) => {
        return <Checkbox checked={rowData?.tblRoleRights[0][column] === 'Y'} onChange={() => toggleCheckbox(rowData.moduleId, rowData.menuId, column)} disabled />;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>VIEW DETAIL</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="roleCode" className={classNames({ 'p-error': isFormFieldValid('roleCode') }, 'Label__Text')}>
                                    Group Name
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="roleCode"
                                    placeholder="Enter Group Name"
                                    name="roleCode"
                                    disabled={true}
                                    value={formik?.values?.roleCode?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('roleCode') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('roleCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="partnerType" className={classNames({ 'p-error': isFormFieldValid('partnerType') }, 'Label__Text')}>
                                    Partner Type
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="partnerType"
                                    placeholder="Select Partner Type"
                                    options={partnerTypeLovData}
                                    optionLabel="name"
                                    name="partnerType"
                                    optionValue="lovId"
                                    disabled={true}
                                    value={formik.values.partnerType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('partnerType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('partnerType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="partnerId" className={classNames({ 'p-error': isFormFieldValid('partnerId') }, 'Label__Text')}>
                                    Partners
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="partnerId"
                                    placeholder="Select Partners"
                                    options={partnerLovData}
                                    optionLabel="name"
                                    name="partnerId"
                                    optionValue="lovId"
                                    value={formik.values.partnerId || ''}
                                    disabled={true}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('partnerId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('partnerId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') }, 'Label__Text')}>
                                    Email
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="email"
                                    placeholder="Enter email"
                                    name="email"
                                    disabled={true}
                                    value={formik?.values?.email?.replace(/\s\s+/g, '')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('email') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('email')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="roleDescr" className={classNames({ 'p-error': isFormFieldValid('roleDescr') }, 'Label__Text')}>
                                    Description
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="roleDescr"
                                    placeholder="Enter Description"
                                    name="roleDescr"
                                    disabled={true}
                                    value={formik?.values?.roleDescr?.replace(/\s\s+/g, '')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('roleDescr') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('roleDescr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                    status
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isActive" name="isActive" value={formik.values.isActive} checked={formik.values.isActive} onChange={formik.handleChange} disabled />
                                </div>
                                {getFormErrorMessage('isActive')}
                            </div>
                        </div>
                        <div className="Form__Header Full__Width">
                            <h1>PERMISSION</h1>
                        </div>
                        <div className="Full__Width">
                            <div className="Full__Length">
                                <Accordion
                                // activeIndex={0} //to open specific Accordion tab use tab index
                                >
                                    {accordionData?.map((accordionItem) => (
                                        <AccordionTab key={accordionItem.moduleId} header={accordionItem.moduleDescr}>
                                            <DataTable value={accordionItem.tblMenus} showGridlines>
                                                <Column field="menuDescr" header="Menu Name" style={{ textAlign: 'center' }} className="Table__Checkboxalign"></Column>
                                                <Column
                                                    field="inserAllowed"
                                                    header="Create"
                                                    body={(rowData) => checkboxBodyTemplate({ ...rowData, moduleId: accordionItem.moduleId }, 'inserAllowed')}
                                                    style={{ textAlign: 'center' }}
                                                    className="Table__Checkboxalign"
                                                ></Column>
                                                <Column
                                                    field="viewAllowed"
                                                    header="Read"
                                                    body={(rowData) => checkboxBodyTemplate({ ...rowData, moduleId: accordionItem.moduleId }, 'viewAllowed')}
                                                    style={{ textAlign: 'center' }}
                                                    className="Table__Checkboxalign"
                                                ></Column>
                                                <Column
                                                    field="updateAllowed"
                                                    header="Update"
                                                    body={(rowData) => checkboxBodyTemplate({ ...rowData, moduleId: accordionItem.moduleId }, 'updateAllowed')}
                                                    style={{ textAlign: 'center' }}
                                                    className="Table__Checkboxalign"
                                                ></Column>
                                                <Column
                                                    field="deleteAllowed"
                                                    header="Delete"
                                                    body={(rowData) => checkboxBodyTemplate({ ...rowData, moduleId: accordionItem.moduleId }, 'deleteAllowed')}
                                                    style={{ textAlign: 'center' }}
                                                    className="Table__Checkboxalign"
                                                ></Column>
                                            </DataTable>
                                        </AccordionTab>
                                    ))}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} onClick={() => navigate('/requestchecker')} label="Okay" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={roleId} tableName={tableName} />
        </>
    );
}
export default ViewUsergroupChecker;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { InputTextarea } from 'primereact/inputtextarea';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function EditKycAttributesChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-save');
    const [validatorLov, setValidatorLov] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        getStatusLov();
    }, []); // eslint-disable-line

    const getDatabyId = async () => {
        setloading(true);

        const res = await handleGetRequest(`/account/v1/kycattribute/getKycAttributeById/${refTableId}`);
        const keyData = res?.payLoad;

        setloading(false);

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
            formik.setFieldValue('isUnique', keyData?.isUnique === 'Y' ? true : false);
            formik.setFieldValue('isSearchable', keyData?.isSearchable === 'Y' ? true : false);
            formik.setFieldValue('isEditable', keyData?.isEditable === 'Y' ? true : false);
            formik.setFieldValue('validatorId', keyData?.tblValidator?.validatorId.toString());
        }
    };

    const getMcRequestDataById = async () => {
        const resp = await handleGetRequest(`/account/v1/kycattribute/getKycAttributesUpdateCheckerById/${mcRequestId}`);
        const keyData = resp?.payLoad;

        setloading(false);

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
            formik.setFieldValue('isUnique', keyData?.isUnique === 'Y' ? true : false);
            formik.setFieldValue('isSearchable', keyData?.isSearchable === 'Y' ? true : false);
            formik.setFieldValue('isEditable', keyData?.isEditable === 'Y' ? true : false);
            formik.setFieldValue('validatorId', keyData?.tblValidator?.validatorId.toString());
        }
    };

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, []); // eslint-disable-line

    const getValidatorLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getvalidator/TBL_VALIDATOR:*');
        setValidatorLov(resp?.payLoad);
    };

    useEffect(() => {
        getValidatorLov();
    }, []); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        // attributeName: Yup.string().required('This Field is required.'),
        // validatorId: Yup.string().required('This Field is required.'),
        // attributeCode: Yup.string().required('This Field is required.'),
        checkerComments: Yup.string().required('This Field is required.'),
        action: Yup.string().required('This Field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            attributeName: '',
            attributeCode: '',
            validatorId: '',
            isEditable: '',
            isSearchable: '',
            isUnique: '',
            checkerComments: '',
            action: '',
            isActive: ''
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['isActive'] = data?.isActive ? 'Y' : 'N';
            if (requestTypeDescr !== 'Enable/Disable Request') {
                delete data['isActive'];
            }

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        tblKycAttributeRequest: {
                            validatorId: parseInt(data?.validatorId),
                            attributeName: data?.attributeName,
                            attributeCode: data?.attributeCode,
                            isEditable: data?.isEditable ? 'Y' : 'N',
                            isSearchable: data?.isSearchable ? 'Y' : 'N',
                            isUnique: data?.isUnique ? 'Y' : 'N',
                            kycAttributesId: parseInt(refTableId),
                            isActive: data['isActive']
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/account/v1/kycattribute/kycAttributeCheckerAction', true, false, 'requestchecker'));
            setloading(false);
            setloadingIcon('pi pi-save');
            if (res?.responseCode === '010000') {
                formik.resetForm();
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="attributeName" className={classNames({ 'p-error': isFormFieldValid('attributeName') }, 'Label__Text')}>
                                    Attribute Name
                                    {/* <span className="Label__Required">*</span> */}
                                </label>
                                <InputText
                                    id="attributeName"
                                    placeholder="Enter Attribute Name"
                                    name="attributeName"
                                    disabled={true}
                                    value={formik?.values?.attributeName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('attributeName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('attributeName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="validatorId" className={classNames({ 'p-error': isFormFieldValid('validatorId') }, 'Label__Text')}>
                                    Validator
                                    {/* <span className="Label__Required">*</span> */}
                                </label>
                                <Dropdown
                                    id="validatorId"
                                    placeholder="Select a value"
                                    options={validatorLov}
                                    optionLabel="name"
                                    name="validatorId"
                                    optionValue="lovId"
                                    disabled={true}
                                    value={formik.values.validatorId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('validatorId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('validatorId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="attributeCode" className={classNames({ 'p-error': isFormFieldValid('attributeCode') }, 'Label__Text')}>
                                    Attribute Code
                                    {/* <span className="Label__Required">*</span> */}
                                </label>
                                <InputText
                                    id="attributeCode"
                                    placeholder="Enter Attribute Code"
                                    name="attributeCode"
                                    disabled={true}
                                    value={formik?.values?.attributeCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('attributeCode') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('attributeCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="isSearchable" className={classNames({ 'p-error': isFormFieldValid('isSearchable') }, 'Label__Text')}>
                                    Searchable
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isSearchable" name="isSearchable" value={formik.values.isSearchable} checked={formik.values.isSearchable} onChange={formik.handleChange} disabled={true} />
                                </div>
                                {getFormErrorMessage('isSearchable')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="isEditable" className={classNames({ 'p-error': isFormFieldValid('isEditable') }, 'Label__Text')}>
                                    Editable
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isEditable" name="isEditable" value={formik.values.isEditable} checked={formik.values.isEditable} onChange={formik.handleChange} disabled={true} />
                                </div>
                                {getFormErrorMessage('isEditable')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="isUnique" className={classNames({ 'p-error': isFormFieldValid('isUnique') }, 'Label__Text')}>
                                    Unique
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isUnique" name="isUnique" value={formik.values.isUnique} checked={formik.values.isUnique} onChange={formik.handleChange} disabled={true} />
                                </div>
                                {getFormErrorMessage('isUnique')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="requestTypeDescr" className={classNames({ 'p-error': isFormFieldValid('requestTypeDescr') }, 'Label__Text')}>
                                    Request Type Description
                                </label>
                                <InputText
                                    disabled={true}
                                    id="requestTypeDescr"
                                    placeholder="Enter Name Of Account"
                                    name="requestTypeDescr"
                                    value={requestTypeDescr}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('requestTypeDescr') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('requestTypeDescr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                    isActive
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox disabled={true} inputId="isActive" name="isActive" value={formik?.values?.isActive} checked={formik?.values?.isActive} onChange={formik.handleChange} />
                                </div>
                                {getFormErrorMessage('isActive')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    rows={5}
                                    cols={30}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon || ''} label="Update" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default EditKycAttributesChecker;

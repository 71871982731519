import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useRef } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import SecuredFileChange from '../../../components/SecuredFileChange';
import MobileView from './mobileView';
import ColorPicker from 'react-pick-color';

function AddBank() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [categoryIconError, setCategoryIconError] = useState('');
    const [iconLink, setIconLink] = useState('');
    const [showColorPicker, setShowColorPicker] = useState({ textColor: false, bgColor: false });

    const bankImageRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        bankName: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/, 'Only alphanumeric characters are allowed')
            .required('This field is required'),
        bankCode: Yup.string().required('This field is required'),
        priority: Yup.string().required('This field is required'),
        bankIcon: Yup.string().required('This field is required'),
        bankImd: Yup.string().required('This field is required'),
        description: Yup.string().required('This field is required'),
        cmsTagLabel: Yup.string().when('addTag', {
            is: true,
            then: Yup.string().required('This field is required'),
            otherwise: Yup.string().notRequired()
        }),
        cmsTagTextColor: Yup.string().when('addTag', {
            is: true,
            then: Yup.string().required('This field is required'),
            otherwise: Yup.string().notRequired()
        }),
        cmsTagBgColor: Yup.string().when('addTag', {
            is: true,
            then: Yup.string().required('This field is required'),
            otherwise: Yup.string().notRequired()
        })
    });

    let formData = new FormData();

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            bankName: '',
            bankCode: '',
            priority: '',
   
            displayNameUrdu: '',
            bankImd: '',
            status: '',
            addTag: true,
            cmsTagLabel: '',
            cmsTagTextColor: '',
            cmsTagBgColor: '',
            expiryDate: '',
            description: '',
            isHide: 'Y',
            copyEngToUrdu:false,
            bankIcon: ''
        },

        onSubmit: async (data) => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        bankName: data?.bankName,
                        bankCode: data?.bankCode,
                        priority: data?.priority,
                        // displayNameEnglish: data?.displayNameEnglish,
                        displayNameUrdu: data?.displayNameUrdu,
                        bankImd: data?.bankImd,
                        description: data?.description,
                        cmsTagLabel: data?.addTag ? data?.cmsTagLabel : '',
                        cmsTagTextColor: data?.addTag ? data?.cmsTagTextColor : '',
                        cmsTagBgColor: data?.addTag ? data?.cmsTagBgColor : '',
                        expiryDate: data?.expiryDate,
                        isHide: data?.isHide
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            formData.append('request', JSON.stringify(newData));
            if (selectedFile) {
                formData.append('file', selectedFile);
            }

            await dispatch(handlePostRequest(formData, '/cms/v1/cms/savebank', true, false, '/bank'));
        }
    });
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    // Product Icon file code
    const handledProductIconClick = () => {
        bankImageRef.current.click();
    };

    // const handleBankImageUpload = (event) => {
    //     const selectedFile = event.target.files[0];

    //     if (!selectedFile) {
    //         formik.setFieldError('files', 'Please choose a file.');
    //         setSelectedFile('');
    //     }
    //     if (selectedFile) {
    //         setSelectedFile(selectedFile);
    //         setBankImage(selectedFile?.name);
    //     }
    // };
    const handleBankImageUpload = (event) => {
        setIconLink('');
        setCategoryIconError('');
        formik.setFieldValue('bankIcon', '');

        setSelectedFile(null);
        const selectedFile = event.target.files[0];
        const fileTypes = ['image'];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setCategoryIconError(error);
            setSelectedFile(null);
        } else if (newFile) {
            setSelectedFile(selectedFile);
            formik.setFieldValue('bankIcon', selectedFile.name);
            setIconLink(URL.createObjectURL(selectedFile));
        }
    };
    const getContrastColor = (hexColor) => {
        const r = parseInt(hexColor.substr(1, 2), 16);
        const g = parseInt(hexColor.substr(3, 2), 16);
        const b = parseInt(hexColor.substr(5, 2), 16);
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    };
    const handleAddTagChange = (e) => {
        formik.handleChange(e);
        formik.setFieldValue('cmsTagLabel', '');
        formik.setFieldValue('cmsTagTextColor', '');
        formik.setFieldValue('cmsTagBgColor', '');
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="Form__Header Full__Width">
                        <h2>ADD DETAILS</h2>
                    </div>
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-fluid p-formgrid grid mb-5 col-12 md:col-9 ">
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="bankName" className={classNames({ 'p-error': isFormFieldValid('bankName') }, 'Label__Text')}>
                                        Bank Name <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="bankName"
                                        placeholder="Enter name"
                                        name="bankName"
                                        value={formik?.values?.bankName?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            if (formik.values.copyEngToUrdu) {
                                                formik.setFieldValue('displayNameUrdu', e.target.value);
                                            }
                                        }}
                                        className={classNames({ 'p-invalid': isFormFieldValid('bankName') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('bankName')}
                                </div>
                            </div>
                            {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <div className="mb-2">
                                        <label className="Label__Text">
                                            Upload Image<span className="Label__Required">*</span>
                                        </label>
                                        <div className="file-input-container w-100" onClick={handledProductIconClick}>
                                            <input type="file" style={{ display: 'none' }} ref={bankImageRef} onChange={handleBankImageUpload} accept="image/*" />
                                            <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={bankImage} readOnly />
                                            <span className="file-upload-icon">
                                                <i className="pi pi-paperclip" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <div className="mb-2">
                                        <label className={classNames({ 'p-error': isFormFieldValid('bankIcon') }, 'Label__Text')}>
                                            Bank Icon<span className="Label__Required">*</span>
                                        </label>
                                        <div className="file-input-container w-100" onClick={handledProductIconClick}>
                                            <input type="file" accept="image/*" style={{ display: 'none' }} ref={bankImageRef} onChange={handleBankImageUpload} />
                                            <input
                                                type="text"
                                                className={classNames({ 'p-inputtext p-component p-invalid': isFormFieldValid('bankIcon') }, 'file-input-field Input__Round')}
                                                placeholder="Choose a file..."
                                                value={formik?.values?.bankIcon}
                                                readOnly
                                            />
                                            <span className="file-upload-icon">
                                                <i className="pi pi-paperclip" />
                                            </span>
                                        </div>
                                        {categoryIconError ? <small className="p-error">{categoryIconError}</small> : getFormErrorMessage('bankIcon')}
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="bankCode" className={classNames({ 'p-error': isFormFieldValid('bankCode') }, 'Label__Text')}>
                                        Bank Code <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="bankCode"
                                        placeholder="Enter Bank Code"
                                        name="bankCode"
                                        value={formik?.values?.bankCode?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('bankCode') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('bankCode')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="priority" className={classNames({ 'p-error': isFormFieldValid('priority') }, 'Label__Text')}>
                                        Priority <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="priority"
                                        keyfilter="int"
                                        placeholder="Enter Priority"
                                        name="priority"
                                        value={formik?.values?.priority?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('priority') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('priority')}
                                </div>
                            </div>
                           
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                <div className="p-field-checkbox flex justify-content-between">
                                   
                                   <label htmlFor="cmsSecondSubCategoryName" className={classNames({ 'p-error': isFormFieldValid('cmsSecondSubCategoryName') }, 'Label__Text')}>
                                   Display Name (Urdu)<span className="Label__Required">*</span>
                                     
                                   </label>
                                 <div className='flex align-items-center ml-auto'>
                                   <label htmlFor="cmsSecondSubCategoryName" className={classNames({ 'p-error': isFormFieldValid('cmsSecondSubCategoryName') }, 'Label__Text')}>
                                      Same AS (English)
                                     
                                   </label>
                                   </div>
                                   <Checkbox
                                       inputId="copyEngToUrdu"
                                       name="copyEngToUrdu"
                                       checked={formik.values.copyEngToUrdu}
                                       onChange={(e) => {
                                           formik.setFieldValue('copyEngToUrdu', e.checked);
                                           if (e.checked) {
                                               formik.setFieldValue('displayNameUrdu', formik.values.bankName);
                                           }
                                       }}
                                   />
                                 
                               </div>
                                   
                                    <InputText
                                        maxLength={100}
                                        id="displayNameUrdu"
                                        placeholder="Enter Display Name (Urdu) "
                                        name="displayNameUrdu"
                                        value={formik?.values?.displayNameUrdu || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('displayNameUrdu') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('displayNameUrdu')}
                                </div>
                            </div>
                            
                        
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="bankImd" className={classNames({ 'p-error': isFormFieldValid('bankImd') }, 'Label__Text')}>
                                        Bank IMD <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="bankImd"
                                        placeholder="Enter Bank IMD "
                                        name="bankImd"
                                        value={formik?.values?.bankImd || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('bankImd') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('bankImd')}
                                </div>
                            </div>

                            <div className="card Card__Bg__Change col-12 md:col-12">
                                <h6 className="secondary-color">
                                    <b>TAG</b>
                                </h6>
                                <div className="p-field col-12 md:col-6 pt-3 pb-3 flex align-items-end justify-content-start">
                                    <div className="field-checkbox flex mb-2 p-field-addbanner-width ">
                                        <div className="mr-5">
                                            {/* <Checkbox inputId="addTag" name="addTag" checked={formik?.values?.addTag} onChange={formik.handleChange} style={{ marginTop: '10px' }} />  */}
                                            <Checkbox inputId="addTag" name="addTag" checked={formik.values.addTag} onChange={handleAddTagChange} style={{ marginTop: '10px' }} disabled />
                                            <label htmlFor="addTag" className={classNames({ 'p-error': isFormFieldValid('enablePartialPayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                                Add Tag
                                            </label>
                                            <span className="Label__Required">*</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-fluid p-formgrid grid mb-5">
                                    <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                        <div className="p-field">
                                            <label htmlFor="cmsTagLabel" className={classNames({ 'p-error': isFormFieldValid('cmsTagLabel') }, 'Label__Text')}>
                                                Tag Label
                                            </label>

                                            <InputText
                                                disabled={!formik?.values?.addTag}
                                                maxLength={100}
                                                id="cmsTagLabel"
                                                placeholder="Enter Tag Label"
                                                name="cmsTagLabel"
                                                value={formik?.values?.cmsTagLabel || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagLabel') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('cmsTagLabel')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                        <div className="p-field relative">
                                            <label htmlFor="cmsTagTextColor" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextColor') }, 'Label__Text')}>
                                                Tag Text Color
                                            </label>
                                            <InputText
                                                value={formik?.values?.cmsTagTextColor || '#FFFFFF'}
                                                onChange={(e) => {
                                                    formik.setFieldValue('cmsTagTextColor', e.target.value);
                                                }}
                                                onClick={() => setShowColorPicker({ textColor: true, bgColor: false })}
                                                disabled={!formik?.values?.addTag}
                                                readOnly
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextColor') }, 'Input__Round')}
                                                style={{
                                                    backgroundColor: formik?.values?.cmsTagTextColor || '#FFFFFF',
                                                    color: getContrastColor(formik?.values?.cmsTagTextColor || '#FFFFFF')
                                                }}
                                            />
                                            {getFormErrorMessage('cmsTagTextColor')}
                                            {showColorPicker?.textColor && (
                                                <div className="colorPickerForInput">
                                                    <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                                    <ColorPicker color={formik?.values?.cmsTagTextColor || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagTextColor', e?.hex)} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                        <div className="p-field">
                                            <label htmlFor="cmsTagBgColor" className={classNames({ 'p-error': isFormFieldValid('cmsTagBgColor') }, 'Label__Text')}>
                                                Tag Background Color
                                            </label>
                                            <InputText
                                                value={formik?.values?.cmsTagBgColor || '#FFFFFF'}
                                                onChange={(e) => {
                                                    formik.setFieldValue('cmsTagBgColor', e.target.value);
                                                }}
                                                onClick={() => setShowColorPicker({ textColor: false, bgColor: true })}
                                                disabled={!formik?.values?.addTag}
                                                readOnly
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagBgColor') }, 'Input__Round')}
                                                style={{
                                                    backgroundColor: formik?.values?.cmsTagBgColor || '#FFFFFF',
                                                    color: getContrastColor(formik?.values?.cmsTagBgColor || '#FFFFFF')
                                                }}
                                            />
                                            {getFormErrorMessage('cmsTagBgColor')}
                                            {showColorPicker?.bgColor && (
                                                <div className="colorPickerForInput">
                                                    <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                                    <ColorPicker color={formik?.values?.cmsTagBgColor || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagBgColor', e?.hex)} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="expiryDate" className={classNames({ 'p-error': isFormFieldValid('expiryDate') }, 'Label__Text')}>
                                        Expiry Date
                                    </label>
                                    <InputText
                                        min={previousDate}
                                        id="expiryDate"
                                        type="date"
                                        name="expiryDate"
                                        value={formik?.values?.expiryDate}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('expiryDate') }, 'Input__Round')}
                                    />

                                    {getFormErrorMessage('expiryDate')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="isHide" className={classNames({ 'p-error': isFormFieldValid('isHide') }, 'Label__Text')}>
                                        Status
                                    </label>
                                    <div className="flex ml-2">
                                        <div className="mt-1 mr-5">
                                            <Checkbox inputId="show" name="isHide" value="Y" checked={formik?.values?.isHide === 'Y'} onChange={(e) => formik.setFieldValue('isHide', 'Y')} style={{ marginTop: '10px' }} />
                                            <label htmlFor="show" className={classNames({ 'p-error': isFormFieldValid('show') }, 'Label__Text')}>
                                                Show
                                            </label>
                                        </div>
                                        <div className="mt-1">
                                            <Checkbox inputId="hide" name="isHide" value="N" checked={formik?.values?.isHide === 'N'} onChange={(e) => formik.setFieldValue('isHide', 'N')} style={{ marginTop: '10px' }} />
                                            <label htmlFor="hide" className={classNames({ 'p-error': isFormFieldValid('hide') }, 'Label__Text')}>
                                                Hide
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-12 w-full pt-3 pb-3 p-field-addbanner-width">
                                <div className="desc_Text_Area">
                                    <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') }, 'Label__Text')}>
                                        Description <span className="Label__Required">*</span>
                                    </label>
                                    <InputTextarea placeholder="-" inputid="description" name="description" rows={4} cols={30} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('description') }, 'Input__Round')} />
                                    {getFormErrorMessage('description')}
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-3 pt-3 pb-3 col-4 flex align-items-start justify-content-center">
                            <MobileView icon={iconLink} title={formik?.values?.bankName} />
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Add" className="Btn__Dark" />
                        <Button onClick={() => navigate('/bank')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddBank;

import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const P2P = ({ p2pData }) => {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        const countArr =
            p2pData &&
            p2pData.map((item) => {
                return item?.parameterValue;
            });
        const labelArr =
            p2pData &&
            p2pData.map((item) => {
                return item?.parameterName;
            });

        const data = {
            labels: labelArr,
            datasets: [
                {
                    data: countArr,
                    backgroundColor: ['#dee176', '#eeca50'],
                    hoverBackgroundColor: ['#dee176', '#eeca50']
                }
            ]
        };

        setChartData(data);
    }, [p2pData]);

    const options = {
        plugins: {
            datalabels: {
                color: '#fff',
                formatter: (value) => {
                    return `${value}%`;
                }
            }
        }
    };

    return (
        <div className="col-12 md:col-3">
            <div className="card Card__Round">
                <div className="cardBarCharts">
                    <h5>P2P</h5>
                    <div className="pt-3">
                        <Chart type="pie" data={chartData} options={options} plugins={[ChartDataLabels]} className="w-full" style={{ height: '35rem' }} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default P2P;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import * as Yup from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../../services/PostTemplate';
import { useRef } from 'react';
import AuthorizationHistoryTable from '../../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import ColorPicker from 'react-pick-color';
import { InputTextarea } from 'primereact/inputtextarea';

function EditSecondSubCategoryChecker() {
    const [secondSubCategoryIconFileName, setSecondSubCategoryIconFileName] = useState();
    const [mainData, setMainData] = useState([]);
    const [filePath, setFilePath] = useState(null);
    const [showColorPicker, setShowColorPicker] = useState({ textColor: false, bgColor: false });

    const [subData, setSubData] = useState([]);
    const subCategoryRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const tableName = query.get('tableName');

    const [statusLovData, setStatusLovData] = useState([]);

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };
    const validationSchema = Yup.object().shape({
        action: Yup.string().required('This field is required'),
        checkerComments: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
            .required('This field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cmsSecondSubCategoryId: '',
            cmsSecondSubCategoryName: '',
            displayNameEnglist: '',
            displayNameUrdu: '',
            cmsMainCategoryId: '',
            cmsSubCategoryId: '',
            secondSubCategoryNumber: '',
            secondSubCategoryIcon: '',
            secondSubCategorySeq: '',
            addCheck: '',
            cmsTagLabel: '',
            cmsTagTextColor: '',
            cmsTagTextBgColor: '',
            tagExpiryDate:'',
            isHide: '',
            isActive: '',
            copyEngToUrdu:'',
            action: '',
            checkerComments: ''
        },

        onSubmit: async (data) => {
            const addPayLoad = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        objectBody: {
                            cmsSecondSubCategoryId: formik.values.cmsSecondSubCategoryId,
                            isActive: requestType === 'I' ? (formik?.values?.action == '2' ? 'Y' : 'N') : formik?.values?.isActive
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: data?.checkerComments,
                            action: data?.action,
                            updatedIndex: ''
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const updatePayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        objectBody: {
                            cmsSecondSubCategoryId: data?.cmsSecondSubCategoryId,
                            cmsSecondSubCategoryName: data?.cmsSecondSubCategoryName,
                            // displayNameEnglist: data?.displayNameEnglist,
                            displayNameUrdu: data?.displayNameUrdu,
                            cmsMainCategoryId: data?.cmsMainCategoryId,
                            cmsSubCategoryId: data?.cmsSubCategoryId,
                            secondSubCategoryNumber: data?.secondSubCategoryNumber,
                            secondSubCategoryIcon: filePath,
                            secondSubCategorySeq: data?.secondSubCategorySeq,
                            cmsTagLabel: data?.cmsTagLabel,
                            cmsTagTextColor: data?.cmsTagTextColor,
                            cmsTagTextBgColor: data?.cmsTagTextBgColor,
                            tagExpiryDate: data?.tagExpiryDate,

                            isHide: data?.isHide,
                            isActive: data?.isActive
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: data?.checkerComments,
                            action: data?.action,
                            updatedIndex: ''
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const formData = new FormData();
            if (requestType === 'I') {
                formData.append('request', JSON.stringify(addPayLoad));
            } else if (requestType === 'U') {
                formData.append('request', JSON.stringify(updatePayload));
            }

            await dispatch(handlePostRequest(formData, '/cms/v1/cms/secondsubcategoryvalidatorcheckeraction', true, false, 'requestchecker'));
        }
    });

    const getCategoryDataLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcategorydatalov');
        setMainData(res?.payLoad);
    };

    const getSubCategoryDataLov = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getsubcategorydatalov/${formik?.values?.cmsMainCategoryId}`);
        setSubData(res?.payLoad);
    };

    useEffect(() => {
        getStatusLov();

        getCategoryDataLov();
        if (formik.values.cmsMainCategoryId != '') {
            getSubCategoryDataLov();
        }
    }, [formik.values.cmsMainCategoryId]); // eslint-disable-line

    const getMainCategoryData = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/getsecondsubcategorybyid/${refTableId}`);
        const keyData = res?.payLoad;
        if (keyData) {
            formik.setFieldValue('cmsSecondSubCategoryName', keyData?.cmsSecondSubCategoryName);
            formik.setFieldValue('displayNameEnglist', keyData?.displayNameEnglist);
            formik.setFieldValue('displayNameUrdu', keyData?.displayNameUrdu);
            formik.setFieldValue('cmsMainCategoryId', keyData?.cmsMainCategoryId?.toString());
            formik.setFieldValue('cmsSubCategoryId', keyData?.cmsSubCategoryId?.toString());
            formik.setFieldValue('secondSubCategoryNumber', keyData?.secondSubCategoryNumber);
            formik.setFieldValue('secondSubCategorySeq', keyData?.secondSubCategorySeq);
            formik.setFieldValue('cmsSecondSubCategoryId', keyData?.cmsSecondSubCategoryId);
            formik.setFieldValue('secondSubCategoryIcon', keyData?.secondSubCategoryIcon);
            formik.setFieldValue('addCheck', keyData?.cmsTagLabel || keyData?.cmsTagTextColor || keyData?.cmsTagTextBgColor ? true : false);
            formik.setFieldValue('cmsTagLabel', keyData?.cmsTagLabel);
            formik.setFieldValue('cmsTagTextColor', keyData?.cmsTagTextColor);
            formik.setFieldValue('cmsTagTextBgColor', keyData?.cmsTagTextBgColor);
            const correctEndDate = (keyData?.tagExpiryDate && formattedDate(keyData?.tagExpiryDate)) || '';

            formik.setFieldValue('tagExpiryDate', correctEndDate);
            formik.setFieldValue('isHide', keyData.isHide);
            formik.setFieldValue('isActive', keyData.isActive);
            const pathParts = keyData?.secondSubCategoryIcon.split('/');
            const categoryIcon = pathParts[pathParts.length - 1];
            setSecondSubCategoryIconFileName(categoryIcon);
        }
    };

    const getMcRequestDataById = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/getmcrequestbyid/${mcRequestId}`);
        const unParsedkeyData = res?.payLoad?.updateJson;
        const keyData = JSON.parse(unParsedkeyData);

        if (keyData) {
            formik.setFieldValue('cmsSecondSubCategoryId', keyData?.cmsSecondSubCategoryId);
            formik.setFieldValue('cmsSecondSubCategoryName', keyData?.cmsSecondSubCategoryName);
            formik.setFieldValue('displayNameEnglist', keyData?.displayNameEnglist);
            formik.setFieldValue('displayNameUrdu', keyData?.displayNameUrdu);
            formik.setFieldValue('cmsMainCategoryId', keyData?.cmsMainCategoryId?.toString());
            formik.setFieldValue('cmsSubCategoryId', keyData?.cmsSubCategoryId?.toString());
            formik.setFieldValue('secondSubCategoryNumber', keyData?.secondSubCategoryNumber);
            formik.setFieldValue('secondSubCategorySeq', keyData?.secondSubCategorySeq);
            formik.setFieldValue('secondSubCategoryIcon', keyData?.secondSubCategoryIcon);
            formik.setFieldValue('addCheck', keyData?.cmsTagLabel || keyData?.cmsTagTextColor || keyData?.cmsTagTextBgColor ? true : false);
            formik.setFieldValue('cmsTagLabel', keyData?.cmsTagLabel);
            formik.setFieldValue('cmsTagTextColor', keyData?.cmsTagTextColor);
            formik.setFieldValue('cmsTagTextBgColor', keyData?.cmsTagTextBgColor);
            const correctEndDate = (keyData?.tagExpiryDate && formattedDate(keyData?.tagExpiryDate)) || '';
            formik.setFieldValue('tagExpiryDate', correctEndDate);
            formik.setFieldValue('isActive', keyData.isActive);
            formik.setFieldValue('isHide', keyData.isHide);
            formik.setFieldValue('isActive', keyData.isActive);

            setFilePath(keyData?.secondSubCategoryIcon);
            const pathParts = keyData?.secondSubCategoryIcon?.split('/');
            const categoryIcon = pathParts ? pathParts[pathParts?.length - 1] : '';

            setSecondSubCategoryIconFileName(categoryIcon);
        }
    };

    useEffect(() => {
        if (requestType === 'I') {
            getMainCategoryData();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, [requestType]); // eslint-disable-line

    function formattedDate(originalDate) {
        const createDate = new Date(originalDate);

        const year = createDate.getFullYear();
        const month = (createDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const day = createDate.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const handledSubCategoryIcon = () => {
        subCategoryRef.current.click();
    };
    const getContrastColor = (hexColor) => {
        const r = parseInt(hexColor.substr(1, 2), 16);
        const g = parseInt(hexColor.substr(3, 2), 16);
        const b = parseInt(hexColor.substr(5, 2), 16);
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    };
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>EDIT DETAILS</h2>
                        </div>
                       
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field" >
                              
                            <label htmlFor="cmsSecondSubCategoryName" className={classNames({ 'p-error': isFormFieldValid('cmsSecondSubCategoryName') }, 'Label__Text')}>
                                    2nd Sub Category Name <span className="Label__Required">*</span>
                                  
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="cmsSecondSubCategoryName"
                                    disabled
                                    placeholder="Enter 2nd Sub Category Name"
                                    name="cmsSecondSubCategoryName"
                                    value={formik?.values?.cmsSecondSubCategoryName || ''}
                                
                                      onChange={(e) => {
                                        formik.handleChange(e);
                                        if (formik.values.copyEngToUrdu) {
                                            formik.setFieldValue('displayNameUrdu', e.target.value);
                                        }
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsSecondSubCategoryName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('cmsSecondSubCategoryName')}
                            </div>
                        </div>
                     

                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                            <div className="p-field-checkbox flex justify-content-between">
                                   
                                   <label htmlFor="cmsSecondSubCategoryName" className={classNames({ 'p-error': isFormFieldValid('cmsSecondSubCategoryName') }, 'Label__Text')}>
                                   Display Name (Urdu)<span className="Label__Required">*</span>
                                     
                                   </label>
                                 <div className='flex align-items-center ml-auto'>
                                   <label htmlFor="cmsSecondSubCategoryName" className={classNames({ 'p-error': isFormFieldValid('cmsSecondSubCategoryName') }, 'Label__Text')}>
                                      Same AS (English)
                                     
                                   </label>
                                   </div>
                                   <Checkbox
                                       inputId="copyEngToUrdu"
                                       name="copyEngToUrdu"
                                       disabled
                                       checked={formik.values.copyEngToUrdu}
                                       onChange={(e) => {
                                           formik.setFieldValue('copyEngToUrdu', e.checked);
                                           if (e.checked) {
                                               formik.setFieldValue('displayNameUrdu', formik.values.cmsSecondSubCategoryName);
                                           }
                                       }}
                                   />
                                 
                               </div>
                                <InputText
                                    maxLength={100}
                                    id="displayNameUrdu"
                                    disabled
                                    placeholder="Enter Display Name In Urdu"
                                    name="displayNameUrdu"
                                    value={formik?.values?.displayNameUrdu || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('displayNameUrdu') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('displayNameUrdu')}
                            </div>
                        </div>
                       

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsMainCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsMainCategoryId') }, 'Label__Text')}>
                                    Main Category
                                </label>
                                <Dropdown
                                    id="cmsMainCategoryId"
                                    placeholder="Select Main Category"
                                    options={mainData}
                                    optionLabel="name"
                                    name="cmsMainCategoryId"
                                    optionValue="lovId"
                                    value={formik.values.cmsMainCategoryId || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsMainCategoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsMainCategoryId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsSubCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsSubCategoryId') }, 'Label__Text')}>
                                    Sub Category<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="cmsSubCategoryId"
                                    placeholder="Select Main Category"
                                    options={subData}
                                    optionLabel="name"
                                    name="cmsSubCategoryId"
                                    optionValue="lovId"
                                    value={formik.values.cmsSubCategoryId || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsSubCategoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsSubCategoryId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="secondSubCategoryNumber" className={classNames({ 'p-error': isFormFieldValid('secondSubCategoryNumber') }, 'Label__Text')}>
                                    2nd Sub Category Number<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="secondSubCategoryNumber"
                                    placeholder="Enter Second Sub Category Number"
                                    name="secondSubCategoryNumber"
                                    value={formik?.values?.secondSubCategoryNumber || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('secondSubCategoryNumber') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('secondSubCategoryNumber')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <div className="mb-2">
                                    <label className="Label__Text">
                                        2nd Sub Category Icon<span className="Label__Required">*</span>
                                    </label>
                                    <div className="file-input-container w-100" onClick={handledSubCategoryIcon}>
                                        <input type="file" style={{ display: 'none' }} ref={subCategoryRef} disabled accept="image/*" />
                                        <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={secondSubCategoryIconFileName} readOnly />
                                        <span className="file-upload-icon">
                                            <i className="pi pi-paperclip" />
                                        </span>
                                        {getFormErrorMessage('secondSubCategoryIcon')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="secondSubCategorySeq" className={classNames({ 'p-error': isFormFieldValid('secondSubCategorySeq') }, 'Label__Text')}>
                                  Priority<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="secondSubCategorySeq"
                                    placeholder="Enter Priority"
                                    name="secondSubCategorySeq"
                                    value={formik?.values?.secondSubCategorySeq || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('secondSubCategorySeq') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('secondSubCategorySeq')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-1 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="addCheck" className={classNames({ 'p-error': isFormFieldValid('addCheck') }, 'Label__Text')}>
                                    Add Tag
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="addCheck" name="addCheck" checked={formik?.values?.addCheck} onChange={formik.handleChange} disabled style={{ marginTop: '10px' }} />{' '}
                                </div>
                                {getFormErrorMessage('addCheck')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsTagLabel" className={classNames({ 'p-error': isFormFieldValid('cmsTagLabel') }, 'Label__Text')}>
                                    Tag Label
                                </label>

                                <InputText
                                    maxLength={100}
                                    id="cmsTagLabel"
                                    placeholder="Enter Tag Label"
                                    name="cmsTagLabel"
                                    disabled
                                    value={formik?.values?.cmsTagLabel || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagLabel') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('cmsTagLabel')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field relative">
                                <label htmlFor="cmsTagTextColor" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextColor') }, 'Label__Text')}>
                                    Tag Text Color
                                </label>
                                <InputText
                                    value={formik?.values?.cmsTagTextColor || '#FFFFFF'}
                                    onChange={(e) => {
                                        formik.setFieldValue('cmsTagTextColor', e.target.value);
                                    }}
                                    onClick={() => setShowColorPicker({ textColor: true, bgColor: false })}
                                    disabled
                                    readOnly
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextColor') }, 'Input__Round')}
                                    style={{
                                        backgroundColor: formik?.values?.cmsTagTextColor || '#FFFFFF',
                                        color: getContrastColor(formik?.values?.cmsTagTextColor || '#FFFFFF')
                                    }}
                                />
                                {getFormErrorMessage('cmsTagTextColor')}
                                {showColorPicker?.textColor && (
                                    <div className="colorPickerForInput">
                                        <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                        <ColorPicker color={formik?.values?.cmsTagTextColor || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagTextColor', e?.hex)} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsTagTextBgColor" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextBgColor') }, 'Label__Text')}>
                                    Tag Background Color
                                </label>
                                <InputText
                                    value={formik?.values?.cmsTagTextBgColor || '#FFFFFF'}
                                    onChange={(e) => {
                                        formik.setFieldValue('cmsTagTextBgColor', e.target.value);
                                    }}
                                    onClick={() => setShowColorPicker({ textColor: false, bgColor: true })}
                                    disabled
                                    readOnly
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextBgColor') }, 'Input__Round')}
                                    style={{
                                        backgroundColor: formik?.values?.cmsTagTextBgColor || '#FFFFFF',
                                        color: getContrastColor(formik?.values?.cmsTagTextBgColor || '#FFFFFF')
                                    }}
                                />
                                {getFormErrorMessage('cmsTagTextBgColor')}
                                {showColorPicker?.bgColor && (
                                    <div className="colorPickerForInput">
                                        <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                        <ColorPicker color={formik?.values?.cmsTagTextBgColor || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagTextBgColor', e?.hex)} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="tagExpiryDate" className={classNames({ 'p-error': isFormFieldValid('tagExpiryDate') }, 'Label__Text')}>
                                    Tag Expiry Date
                                </label>
                                <InputText
                                    type="date"
                                    id="tagExpiryDate"
                                    min={previousDate}
                                    name="tagExpiryDate"
                                    value={formik?.values?.tagExpiryDate || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('tagExpiryDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('tagExpiryDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-2 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isHide" className={classNames({ 'p-error': isFormFieldValid('isHide') }, 'Label__Text')}>
                                    Status
                                </label>
                                <div className="flex ml-2">
                                    <div className="mt-1 mr-5">
                                        <Checkbox inputId="show" name="isHide" value="Y" checked={formik?.values?.isHide === 'Y'} onChange={(e) => formik.setFieldValue('isHide', 'Y')} disabled style={{ marginTop: '10px' }} />
                                        <label htmlFor="show" className={classNames({ 'p-error': isFormFieldValid('show') }, 'Label__Text')}>
                                            Show
                                        </label>
                                    </div>
                                    <div className="mt-1">
                                        <Checkbox inputId="hide" name="isHide" value="N" checked={formik?.values?.isHide === 'N'} onChange={(e) => formik.setFieldValue('isHide', 'N')} disabled style={{ marginTop: '10px' }} />
                                        <label htmlFor="hide" className={classNames({ 'p-error': isFormFieldValid('hide') }, 'Label__Text')}>
                                            Hide
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid grid col-12 p-3 bg-bluegray-50 border-round-sm mt-3">
                            <div className="p-fluid p-formgrid grid ml-5 col-10">
                                <div className="p-field col-4 md:col-4">
                                    <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                        Select Action
                                    </label>
                                    <Dropdown
                                        id="action"
                                        placeholder="Select Action"
                                        options={statusLovData}
                                        optionLabel="name"
                                        name="action"
                                        optionValue="lovId"
                                        value={formik.values.action || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('action')}
                                </div>
                                <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                    <div className="desc_Text_Area">
                                        <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                            Comments <span className="Label__Required">*</span>
                                        </label>
                                        <InputTextarea
                                            placeholder="-"
                                            inputid="checkerComments"
                                            name="checkerComments"
                                            rows={4}
                                            cols={30}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'Input__Round')}
                                            value={formik?.values?.checkerComments}
                                        />
                                        {getFormErrorMessage('checkerComments')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="submit" label="Update" className="Btn__Dark" />
                        <Button onClick={() => navigate('/requestchecker')} type="button" label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default EditSecondSubCategoryChecker;

import React, { useState, useEffect } from 'react';
import { handleGetRequest } from '../../../services/GetTemplate';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import '../../../../src/components/DataTable.css';
import AddUserDialog from './AddUserDialog';
import UpdateUserDialog from './UpdateUserDialog';
import { InputText } from 'primereact/inputtext';

function Users() {
    const [usersData, setUsersData] = useState([]);
    const [loading, setloading] = useState([]);
    const [displayModal, setDisplayModal] = useState(false);
    const [displayModalUpdate, setDisplayModalUpdate] = useState(false);
    const [editable, setEditable] = useState(false);
    const [userRowData, setUserRowData] = useState('');
    const [globalFilter, setGlobalFilter] = useState('');

    const addHandler = (rowData) => {
        setDisplayModal(true);
        setUserRowData(rowData);
        setEditable(true);
    };
    const editHandler = (rowData) => {
        setDisplayModalUpdate(true);
        setUserRowData(rowData);
        setEditable(true);
    };

    const getUsers = async () => {
        setloading(true);
        const response = await handleGetRequest('/mfs_user_management/v1/management/getAllUsers');
        if (response.responseCode === '0000') {
            setUsersData(response?.payLoad);
            setloading(false);
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    const actionTemplate = (rowData) => {
        return (
            <div>
                <Button tooltip="Add User Roles" tooltipOptions={{ position: 'top' }} icon="pi pi-plus" onClick={() => addHandler(rowData)} className="p-button-rounded p-button-primary mr-2" />
                <Button tooltip="Update Status" icon="pi pi-pencil" tooltipOptions={{ position: 'top' }} onClick={() => editHandler(rowData)} className="p-button-rounded p-button-warning mr-2" />
            </div>
        );
    };

    const onHide = () => {
        setDisplayModal(false);
    };

    const onHideUpdate = () => {
        setDisplayModalUpdate(false);
    };

    const activeBody = (rowData) => {
        return <React.Fragment>{rowData?.isActive === 'Y' ? 'Yes' : rowData?.isActive === 'N' ? 'No' : null}</React.Fragment>;
    };

    const header = (
        <div className="Global__Header">
            <h5 className="m-0">Users List</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    return (
        <div>
            <Dialog visible={displayModal} header="Add User Roles" style={{ width: '50vw' }} onHide={() => setDisplayModal(false)}>
                <AddUserDialog onHide={onHide} editable={editable} userRowData={userRowData} />
            </Dialog>

            <Dialog visible={displayModalUpdate} header="Update User Status" style={{ width: '50vw' }} onHide={() => setDisplayModalUpdate(false)}>
                <UpdateUserDialog onHideUpdate={onHideUpdate} editable={editable} userRowData={userRowData} getUsers={getUsers} />
            </Dialog>

            <div className="card p-datatable-sm">
                <DataTable header={header} globalFilter={globalFilter} disabled={loading} paginator rows={10} value={usersData} emptyMessage="No data found.">
                    <Column field="userId" header="userId" />
                    <Column field="createdate" header="createdate" />
                    <Column field="createuser" header="createuser" />
                    <Column field="email" header="email" />
                    <Column field="firstName" header="firstName" />
                    <Column field="lastName" header="lastName" />
                    <Column field="middleName" header="middleName" />
                    <Column field="mobileNo" header="mobileNo" />
                    <Column field="username" header="username" />
                    <Column field="isActive" header="Is Active" body={activeBody} />
                    <Column body={actionTemplate} header="Action" />
                </DataTable>
            </div>
        </div>
    );
}

export default Users;

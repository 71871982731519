import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import ScrollMover from '../../../../components/ScrollMover';

function DefaultComponent({ type, getByIdData }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setloading] = useState(false);
    const [activeIndex, setActiveIndex] = useState([]);
    const [checkerDropdown, setCheckerDropdown] = useState([]);
    const [allMenuList, setAllMenuList] = useState([]);
    const [addPayLoad, setAddPayLoad] = useState([]);
    const [payload, setPayload] = useState([]);
    const [allSelected, setAllSelected] = useState([]);

    const EditInitialValues = {
        role: '',
        userId: ''
    };

    const EditValidationSchema = Yup.object().shape({
        role: Yup.string().required('This field is required'),
        userId: Yup.string().required('This field is required')
    });

    const formik = useFormik({
        validationSchema: EditValidationSchema,
        initialValues: EditInitialValues,

        onSubmit: async () => {
            setloading(true);
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: payload,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            if (type === 'add') {
                await dispatch(handlePostRequest(newData, '/account/v1/customeraccounts/savemenufiledsrights', true, false, 'cxfieldmanagement'));
            }
            if (type === 'edit') {
                await dispatch(handlePostRequest(newData, '/account/v1/customeraccounts/updatemenufiledsrights', true, false, 'cxfieldmanagement'));
            }
            setloading(false);
        }
    });

    const funcCheckerDropdown = async (id) => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getagentdatabyaccounttype/TBL_CX/${id}`);
        setCheckerDropdown(resp?.payLoad);
    };

    useEffect(() => {
        if (type === 'add' && formik?.values?.role) {
            funcCheckerDropdown(formik?.values?.role);
            formik.setFieldValue('userId', '');
        }
        // else if (getByIdData?.selectedRole?.roleId) funcCheckerDropdown(getByIdData?.selectedRole?.roleId);
    }, [type, formik?.values?.role]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (getByIdData?.allMenuList?.length > 0) {
            setAllMenuList(getByIdData?.allMenuList);
        } else if (getByIdData?.getByRoleId?.length > 0) {
            getByIdData?.getByRoleId.map((tab, index) => {
                if (tab.tabPresent === 'Y') {
                    setActiveIndex((prev) => [...prev, index]);
                }
            });
            const modifiedPayload = getByIdData?.getByRoleId.map((tab) => ({
                ...tab,
                menuTabResponse: tab.menuTabResponse.map((field) => {
                    const fieldName = field?.fieldName || '';
                    return {
                        ...field,
                        [fieldName]: field.viewAllowed === 'Y' && field.editAllowed === 'Y' && field.mcAllowed === 'Y' ? 'Y' : 'N',
                        View: field.viewAllowed || 'N',
                        Edit: field.editAllowed || 'N',
                        'Maker/Checker': field.mcAllowed || 'N'
                    };
                })
            }));

            setAllMenuList(modifiedPayload);
        }
    }, [getByIdData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if ((type === 'edit' || type === 'view') && getByIdData) {
            formik.setFieldValue('role', getByIdData?.selectedRole?.roleCode);
            formik.setFieldValue('userId', getByIdData?.selectedRole?.userName?.toString() || '');
        }
    }, [getByIdData]); // eslint-disable-line react-hooks/exhaustive-deps

    const selectAllFields = (tabId) => {
        const updatedMenuList = allMenuList.map((mainList) => {
            if (mainList.tabId === tabId) {
                const updatedRows = mainList?.menuTabResponse?.map((row) => {
                    return { ...row, [row?.fieldName]: 'Y', ['View']: 'Y', ['Edit']: 'Y', ['Maker/Checker']: 'Y' };
                });
                return { ...mainList, menuTabResponse: updatedRows };
            }
            return mainList;
        });
        setAllMenuList(updatedMenuList);
    };
    const unSelectAllFields = (tabId) => {
        const updatedMenuList = allMenuList.map((mainList) => {
            if (mainList.tabId === tabId) {
                const updatedRows = mainList?.menuTabResponse?.map((row) => {
                    return { ...row, [row?.fieldName]: 'N', ['View']: 'N', ['Edit']: 'N', ['Maker/Checker']: 'N' };
                });
                return { ...mainList, menuTabResponse: updatedRows };
            }
            return mainList;
        });
        setAllMenuList(updatedMenuList);
    };

    const toggleBodyCheckBox = (rowData, tabId, fieldId, columnName) => {
        const updatedMenuList = allMenuList.map((mainList) => {
            if (mainList.tabId === tabId) {
                const updatedRows = mainList?.menuTabResponse?.map((row) => {
                    if (row.fieldId === fieldId) {
                        return { ...row, [columnName]: row?.[columnName] === 'Y' ? 'N' : 'Y' };
                    }
                    return row;
                });
                return { ...mainList, menuTabResponse: updatedRows };
            }
            return mainList;
        });

        setAllMenuList(updatedMenuList);

        if (columnName !== 'View' && columnName !== 'Edit' && columnName !== 'Maker/Checker') {
            const checked = updatedMenuList.some((mainList) => mainList.menuTabResponse.some((row) => row?.[columnName] === 'Y'));
            const updatedRowsWithColumns = updatedMenuList.map((mainList) => {
                const updatedRows = mainList?.menuTabResponse?.map((row) => {
                    if (row.fieldId === fieldId) {
                        return { ...row, View: checked ? 'Y' : 'N', Edit: checked ? 'Y' : 'N', 'Maker/Checker': checked ? 'Y' : 'N' };
                    }
                    return row;
                });
                return { ...mainList, menuTabResponse: updatedRows };
            });

            setAllMenuList(updatedRowsWithColumns);
        } else if (columnName === 'View' || columnName === 'Edit' || columnName === 'Maker/Checker') {
            const updatedRowsWithColumns = updatedMenuList.map((mainList) => {
                const updatedRows = mainList?.menuTabResponse?.map((row) => {
                    if (row.fieldId === fieldId) {
                        const allChecked = row.View === 'Y' && row.Edit === 'Y' && row['Maker/Checker'] === 'Y';
                        return { ...row, [row.fieldName]: allChecked ? 'Y' : 'N' };
                    }
                    return row;
                });
                return { ...mainList, menuTabResponse: updatedRows };
            });

            setAllMenuList(updatedRowsWithColumns);
        }
    };

    const checkboxBodyTemplate = (rowData, tabId, fieldId, columnName) => {
        return (
            <div className="flex justify-content-start align-items-center ml-5">
                <div className="m-0">
                    <Checkbox disabled={type === 'view'} name={columnName + fieldId} checked={rowData[columnName] === 'Y'} onChange={() => toggleBodyCheckBox(rowData, tabId, fieldId, columnName)} />
                </div>
                <div className="m-0">
                    <span className="ml-2">{columnName}</span>
                </div>
            </div>
        );
    };

    const checkboxHeaderTemplate = (list, index) => {
        return (
            <div className="flex justify-content-start align-items-center">
                {/* <div className="m-0">
                    <Checkbox name={list?.tabId} checked={activeIndex?.includes(index)} onChange={(e) => setActiveIndex(e.index)} />
                </div> */}
                <div className="m-0">
                    <span className="ml-3">{list.tabName}</span>
                </div>
                <div className="m-0">
                    <span className={`ml-2 toggleIcon pi ${activeIndex?.includes(index) ? 'pi-chevron-down' : 'pi-chevron-right'}`} />
                </div>
            </div>
        );
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        allMenuList?.map((list) => {
            if (list?.menuTabResponse?.length > 0) {
                if (list?.menuTabResponse?.every((row) => row?.View === 'Y' && row?.Edit === 'Y' && row['Maker/Checker'] === 'Y')) {
                    setAllSelected((prevState) => ({ ...prevState, [list?.tabId]: true }));
                }
            }
        });
        const payload = allMenuList?.reduce((acc, mainList) => {
            const fieldRequests = mainList?.menuTabResponse.flatMap((row) => {
                const hasPermission = row.View === 'Y' || row.Edit === 'Y' || row['Maker/Checker'] === 'Y';
                const permissions = {
                    menuFieldRightId: mainList?.menuFieldRightId || 0,
                    fieldId: row.fieldId || 0,
                    viewAllowed: row.View || 'N',
                    updateAllowed: row.Edit || 'N',
                    mcAllowed: row['Maker/Checker'] || 'N'
                };
                const tabId = { fieldId: mainList?.tabId || 0, menuFieldRightId: mainList?.menuFieldRightId || 0, viewAllowed: 'Y', updateAllowed: '', mcAllowed: '' || '' };
                if (hasPermission) {
                    return [permissions, tabId];
                }
                return [permissions];
            });
            return acc.concat(fieldRequests);
        }, []); // eslint-disable-line react-hooks/exhaustive-deps

        const outerValues = {
            roleId: type === 'add' ? formik?.values?.role : type === 'edit' ? getByIdData?.selectedRole?.roleId : '',
            isActive: 'Y',
            statusId: 1,
            userId: type === 'add' ? formik?.values?.userId : type === 'edit' ? getByIdData?.selectedRole?.userId : '',
            cxMenuFieldRequests: payload
        };
        setAddPayLoad(outerValues);
    }, [allMenuList, formik?.values?.role, formik?.values?.userId]); // eslint-disable-line react-hooks/exhaustive-deps

    const updatePayload = () => {
        const copyPayload = [...(payload?.cxMenuFieldRequests || [])];
        let updatedArray = [...copyPayload];
        addPayLoad?.cxMenuFieldRequests?.forEach((itemA) => {
            const foundIndex = updatedArray.findIndex((itemB) => itemB.fieldId === itemA.fieldId);
            if (foundIndex !== -1) {
                updatedArray[foundIndex] = itemA;
            } else {
                updatedArray.push(itemA);
            }
        });
        updatedArray = updatedArray.filter((itemA) => {
            return addPayLoad.cxMenuFieldRequests.some((itemB) => itemA.fieldId === itemB.fieldId);
        });
        const outerValues = {
            roleId: type === 'add' ? formik?.values?.role : type === 'edit' ? getByIdData?.selectedRole?.roleId : '',
            isActive: 'Y',
            statusId: 1,
            userId: type === 'add' ? formik?.values?.userId : type === 'edit' ? getByIdData?.selectedRole?.userId : '',
            cxMenuFieldRequests: updatedArray
        };
        setPayload(outerValues);
    };

    useEffect(() => {
        if (addPayLoad) updatePayload();
    }, [addPayLoad]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h5 className="uppercase">{type === 'add' ? 'Add New' : type === 'edit' ? 'Edit Detail' : type === 'view' ? 'View Detail' : null}</h5>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="role" className={classNames({ 'p-error': isFormFieldValid('role') }, 'Label__Text')}>
                                    Department <span className="Label__Required">*</span>
                                </label>
                                {type === 'add' ? (
                                    <Dropdown
                                        id="role"
                                        placeholder="Select Department"
                                        options={getByIdData?.addRoleDropdown || []}
                                        optionLabel="roleDescription"
                                        name="role"
                                        optionValue="roleId"
                                        value={formik?.values?.role || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('role') }, 'Dropdown__Round')}
                                    />
                                ) : (
                                    <InputText
                                        placeholder="Enter Role"
                                        id="role"
                                        name="role"
                                        value={formik?.values?.role || ''}
                                        disabled
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('role') }, 'Input__Round')}
                                    />
                                )}

                                {getFormErrorMessage('role')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="userId" className={classNames({ 'p-error': isFormFieldValid('userId') }, 'Label__Text')}>
                                    User <span className="Label__Required">*</span>
                                </label>
                                {type === 'add' ? (
                                    <Dropdown
                                        id="userId"
                                        name="userId"
                                        placeholder="Select User"
                                        options={checkerDropdown || []}
                                        optionLabel="name"
                                        optionValue="lovId"
                                        value={formik?.values?.userId || ''}
                                        onChange={formik.handleChange}
                                        disabled={type !== 'add'}
                                        className={classNames({ 'p-invalid': isFormFieldValid('userId') }, 'Dropdown__Round')}
                                    />
                                ) : (
                                    <InputText
                                        placeholder="Enter User"
                                        id="userId"
                                        name="userId"
                                        value={formik?.values?.userId || ''}
                                        disabled
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('userId') }, 'Input__Round')}
                                    />
                                )}
                                {getFormErrorMessage('userId')}
                            </div>
                        </div>
                    </div>
                    <div className="Form__Header">
                        <h5 className="uppercase">Permission</h5>
                    </div>
                    {allMenuList?.length > 0 && (
                        <div className="menuPermissionList">
                            <Accordion multiple activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                                {allMenuList?.map((list, index) => (
                                    <AccordionTab key={index} header={checkboxHeaderTemplate(list, index)}>
                                        <React.Fragment>
                                            {list?.menuTabResponse?.length > 0 && type !== 'view' && (
                                                <div className="Down__Btn justify-content-end pb-3">
                                                    {allSelected[list?.tabId] ? (
                                                        <Button
                                                            className="Btn__Dark"
                                                            type="button"
                                                            label={'Un Select All'}
                                                            disabled={type === 'view'}
                                                            onClick={() => {
                                                                unSelectAllFields(list?.tabId);
                                                                setAllSelected((prevState) => ({ ...prevState, [list?.tabId]: false }));
                                                            }}
                                                        />
                                                    ) : (
                                                        <Button
                                                            className="Btn__Dark"
                                                            type="button"
                                                            label={'Select All'}
                                                            disabled={type === 'view'}
                                                            onClick={() => {
                                                                selectAllFields(list?.tabId);
                                                                setAllSelected((prevState) => ({ ...prevState, [list?.tabId]: true }));
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </React.Fragment>
                                        {list?.menuTabResponse && list?.menuTabResponse?.length > 0 && (
                                            <DataTable value={list?.menuTabResponse}>
                                                <Column field="fieldId" body={(rowData) => checkboxBodyTemplate({ ...rowData }, list?.tabId, rowData?.fieldId, rowData?.fieldName)} className="Table__Checkboxalign"></Column>
                                                <Column field="viewAllowed" body={(rowData) => checkboxBodyTemplate({ ...rowData }, list?.tabId, rowData?.fieldId, 'View')} className="Table__Checkboxalign"></Column>
                                                <Column field="updateAllowed" body={(rowData) => checkboxBodyTemplate({ ...rowData }, list?.tabId, rowData?.fieldId, 'Edit')} className="Table__Checkboxalign"></Column>
                                                <Column field="mcAllowed" body={(rowData) => checkboxBodyTemplate({ ...rowData }, list?.tabId, rowData?.fieldId, 'Maker/Checker')} className="Table__Checkboxalign"></Column>
                                            </DataTable>
                                        )}
                                    </AccordionTab>
                                ))}
                            </Accordion>
                        </div>
                    )}
                    <div className="Down__Btn py-3">
                        {type === 'add' && (
                            <React.Fragment>
                                <Button type="submit" label="Submit" className="Btn__Dark" onClick={() => ScrollMover()} disabled={loading} />
                                <Button type="button" label="Cancel" className="Btn__Transparent" onClick={() => navigate(-1)} />
                            </React.Fragment>
                        )}
                        {type === 'edit' && (
                            <React.Fragment>
                                <Button type="submit" label="Update" className="Btn__Dark" onClick={() => ScrollMover()} disabled={loading} />
                                <Button type="button" label="Cancel" className="Btn__Transparent" onClick={() => navigate(-1)} />
                            </React.Fragment>
                        )}
                        {type === 'view' && <Button type="button" label="Okay" className="Btn__Dark" onClick={() => navigate(-1)} />}
                    </div>
                </form>
            </div>
        </>
    );
}
export default DefaultComponent;

import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewCheckerSignUpLimit() {
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const tableName = query.get('tableName');

    const formik = useFormik({
        initialValues: {
            noOfAttempts: '',
            time: '',
            isTemporaryBlock: '',
            isPermanentBlock: '',
            blockTime: '',
            cmsSignupLimitId: '',
            isActive: '',
            checkerComments: '',
            action: ''
        }
    });

    const getMainCategoryData = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/getsignuplimitsbyid/${refTableId}`, true);
        const keyData = res?.payLoad;
        formik.setFieldValue('noOfAttempts', keyData?.noOfAttempts);
        formik.setFieldValue('time', keyData?.time || '');

        formik.setFieldValue('cmsSignupLimitId', keyData?.cmsSignupLimitId);
        formik.setFieldValue('isActive', keyData?.isActive);
        formik.setFieldValue('blockTime', keyData?.blockTime);
        formik.setFieldValue('isTemporaryBlock', keyData?.isTemporaryBlock);
        formik.setFieldValue('isPermanentBlock', keyData?.isPermanentBlock);
    };

    const getMcRequestDataById = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/getmcrequestbyid/${mcRequestId}`);
        const unParsedkeyData = res?.payLoad?.updateJson;
        const keyData = JSON.parse(unParsedkeyData);
        formik.setFieldValue('noOfAttempts', keyData?.noOfAttempts);
        formik.setFieldValue('time', keyData?.time || '');

        formik.setFieldValue('cmsSignupLimitId', keyData?.cmsSignupLimitId);
        formik.setFieldValue('isActive', keyData?.isActive);
        formik.setFieldValue('blockTime', keyData?.blockTime);
        formik.setFieldValue('isTemporaryBlock', keyData?.isTemporaryBlock);
        formik.setFieldValue('isPermanentBlock', keyData?.isPermanentBlock);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getMainCategoryData();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, [requestType]); // eslint-disable-line

   

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>VIEW DETAIL</h2>
                        </div>
                        <div className="p-field col-12 md:col-6 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="noOfAttempts" className={classNames({ 'p-error': isFormFieldValid('noOfAttempts') }, 'Label__Text')}>
                                    Attempts
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="noOfAttempts"
                                    placeholder="Enter Attempts"
                                    name="noOfAttempts"
                                    value={formik?.values?.noOfAttempts || ''}
                                    disabled
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('noOfAttempts') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('noOfAttempts')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-6  pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="time" className={classNames({ 'p-error': isFormFieldValid('time') }, 'Label__Text')}>
                                    Enter Time
                                </label>
                                <InputText disabled id="time"placeholder='Enter Time In Minutes' name="time" value={formik?.values?.time} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('time') }, 'Input__Round')} />

                                {getFormErrorMessage('time')}
                            </div>
                        </div>

                        <div className="Form__Header Full__Width">
                            <h2>BREACH LIMITS</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-5 pb-3">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox
                                        inputId="isTemporaryBlock"
                                        name="isTemporaryBlock"
                                        checked={formik.values.isTemporaryBlock === 'Y'}
                                        onChange={(e) => {
                                            const newValue = e.checked ? 'Y' : 'N';
                                            formik.setFieldValue('isTemporaryBlock', newValue);
                                            if (e.checked) {
                                                formik.setFieldValue('isPermanentBlock', 'N');
                                            }
                                        }}
                                        style={{ marginTop: '10px' }}
                                        disabled
                                    />{' '}
                                    <label htmlFor="isTemporaryBlock" className={classNames({ 'p-error': isFormFieldValid('isTemporaryBlock') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Temporary Block
                                    </label>
                                </div>
                                {getFormErrorMessage('isTemporaryBlock')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="blockTime" className={classNames({ 'p-error': isFormFieldValid('blockTime') }, 'Label__Text')}>
                                    Time 
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="blockTime"
                                    placeholder="Enter Time In Minutes"
                                    name="blockTime"
                                    value={formik?.values?.blockTime || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('blockTime') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('blockTime')}
                            </div>
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-2 pt-3 pb-3">
                        <div className="p-field">
                            <div className="field-checkbox">
                                <Checkbox
                                    inputId="isPermanentBlock"
                                    name="isPermanentBlock"
                                    checked={formik.values.isPermanentBlock === 'Y'}
                                    onChange={(e) => {
                                        const newValue = e.checked ? 'Y' : 'N';
                                        formik.setFieldValue('isPermanentBlock', newValue);
                                        if (e.checked) {
                                            formik.setFieldValue('isTemporaryBlock', 'N');
                                        }
                                    }}
                                    style={{ marginTop: '10px' }}
                                    disabled
                                />{' '}
                                <label htmlFor="isPermanentBlock" className={classNames({ 'p-error': isFormFieldValid('isPermanentBlock') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                    Permanent Block
                                </label>
                            </div>
                            {getFormErrorMessage('isPermanentBlock')}
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="button" label="Okay" onClick={() => navigate('/requestchecker')} className="Btn__Dark" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default ViewCheckerSignUpLimit;

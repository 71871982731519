import React from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handlePostRequest } from '../../../../services/PostTemplate';

const AddCurrency = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        lkpCurrencyName: Yup.string().required('This field is required'),
        lkpCurrencyCode: Yup.string().required('This field is required'),
        lkpCurrencyDescription: Yup.string().required('This field is required'),
        coaCode: Yup.string().required('This field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            lkpCurrencyName: '',
            lkpCurrencyCode: '',
            lkpCurrencyDescription: '',
            coaCode: ''
        },
        validate: (data) => {
            let errors = {};
            //////////////////////////lkpCurrencyName////////////////////////
            if (!data?.lkpCurrencyName) {
                errors.lkpCurrencyName = 'This field is required';
            } else if (data?.lkpCurrencyName === '') {
                errors.lkpCurrencyName = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.lkpCurrencyName)) {
                errors.lkpCurrencyName = 'Leading and trailing space is not allowed';
            } else if (!/^[a-zA-Z0-9\s]+$/.test(data?.lkpCurrencyName)) {
                errors.lkpCurrencyName = 'Only alphanumeric characters and spaces are allowed';
            }

            //////////////////////////lkpCurrencyCode////////////////////////
            if (!data?.lkpCurrencyCode) {
                errors.lkpCurrencyCode = 'This field is required';
            } else if (data?.lkpCurrencyCode === '') {
                errors.lkpCurrencyCode = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.lkpCurrencyCode)) {
                errors.lkpCurrencyCode = 'Leading and trailing space is not allowed';
            } else if (/\s/.test(data?.lkpCurrencyCode)) {
                errors.lkpCurrencyCode = 'Spaces in between digits are not allowed';
            } else if (!/^[a-zA-Z0-9\s]+$/.test(data?.lkpCurrencyCode)) {
                errors.lkpCurrencyCode = 'Only alphanumeric characters are allowed';
            }

            //////////////////////////coaCode////////////////////////
            if (!data?.coaCode) {
                errors.coaCode = 'This field is required';
            } else if (data?.coaCode === '') {
                errors.coaCode = 'This Field should not be empty';
            } else if (!/^[0-9]+$/.test(data?.coaCode)) {
                errors.coaCode = 'Only numeric characters are allowed';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.coaCode)) {
                errors.lkpCurrencyName = 'Leading and trailing space is not allowed';
            } else if (/\s/.test(data?.coaCode)) {
                errors.coaCode = 'Spaces in between digits are not allowed';
            } else if (!/^[0-9]{3}$/.test(data?.coaCode)) {
                errors.coaCode = 'Numbers should be in between 000 to 999';
            }

            return errors;
        },

        onSubmit: async (data) => {
            const coaPayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(coaPayload, '/account/v1/chartofaccount/createlkpcurrency', true, false));
            if (res?.responseCode === '010000') {
                navigate(-1);
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>Add Details</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Currency Name</label>
                                <InputText id="lkpCurrencyName" value={formik.values.lkpCurrencyName || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('lkpCurrencyName') }, 'Input__Round')} maxLength={30} />
                                {getFormErrorMessage('lkpCurrencyName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Currency Code</label>
                                <InputText id="lkpCurrencyCode" value={formik.values.lkpCurrencyCode || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('lkpCurrencyCode') }, 'Input__Round')} maxLength={20} />
                                {getFormErrorMessage('lkpCurrencyCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Currency Description</label>
                                <InputText
                                    id="lkpCurrencyDescription"
                                    value={formik.values.lkpCurrencyDescription || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpCurrencyDescription') }, 'Input__Round')}
                                    maxLength={199}
                                />
                                {getFormErrorMessage('lkpCurrencyDescription')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Coa Code</label>
                                <InputText id="coaCode" value={formik.values.coaCode || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('coaCode') }, 'Input__Round')} maxLength={30} />
                                {getFormErrorMessage('coaCode')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Submit" className="Btn__Dark" />
                        <Button onClick={() => navigate('/currency')} label="Cancel" className="Btn__Transparent" type="button" />
                    </div>
                </form>
            </div>
        </>
    );
};

export default AddCurrency;

import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';

function TaxTypeOldNewValuesView({ taxTypeRowData }) {

    useEffect(() => {
        const rowData = JSON?.parse(JSON?.stringify(taxTypeRowData));
        const keys = Object?.keys(rowData);
        keys.forEach((item) => {
            formik.setFieldValue(`${item}`, rowData[item]);
        });
    }, [taxTypeRowData]);// eslint-disable-line

    const applicabledropDown = [
       {
            value: 'I',
            label: 'Apply on Fee Inclusive'
        },
        {
            value: 'E',
            label: 'Apply on Fee Exclusive'
        },
        {
            value: 'C',
            label: 'Apply on Commision'
        },
        {
            value: 'T',
            label: 'Apply on Transaction Amount'
        }
    ];

    const formik = useFormik({
        initialValues: {
            taxTypeCode: '',
            taxTypeName: '',
            applicableOn: '',
            taxTypeDescr: ''
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header"></div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="taxTypeCode" className={classNames({ 'p-error': isFormFieldValid('taxTypeCode') }, 'Label__Text')}>
                                    Tax Code
                                </label>
                                <InputText
                                    id="taxTypeCode"
                                    placeholder="Enter Tax Code"
                                    name="taxTypeCode"
                                    disabled={true}
                                    value={formik?.values?.taxTypeCode?.trim()?.toString() || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('taxTypeCode') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('taxTypeCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="taxTypeName" className={classNames({ 'p-error': isFormFieldValid('taxTypeName') }, 'Label__Text')}>
                                    Tax Name
                                </label>
                                <InputText
                                    id="taxTypeName"
                                    placeholder="Enter Tax Name"
                                    name="taxTypeName"
                                    disabled={true}
                                    value={formik?.values?.taxTypeName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('taxTypeName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('taxTypeName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="applicableOn" className={classNames({ 'p-error': isFormFieldValid('applicableOn') }, 'Label__Text')}>
                                    Applicability
                                </label>
                                <Dropdown
                                    id="applicableOn"
                                    placeholder="Select Applicability"
                                    options={applicabledropDown}
                                    optionLabel="label"
                                    name="applicableOn"
                                    optionValue="value"
                                    disabled={true}
                                    value={formik.values.applicableOn || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('applicableOn') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('applicableOn')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="taxTypeDescr" className={classNames({ 'p-error': isFormFieldValid('taxTypeDescr') }, 'Label__Text')}>
                                    Description
                                </label>
                                <InputTextarea
                                    autoResize="false"
                                    id="taxTypeDescr"
                                    placeholder="Enter Description"
                                    name="taxTypeDescr"
                                    disabled={true}
                                    value={formik?.values?.taxTypeDescr?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('taxTypeDescr') }, 'Input__Round')}
                                    rows={3}
                                    cols={30}
                                />
                                {getFormErrorMessage('taxTypeDescr')}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default TaxTypeOldNewValuesView;

import React, { useState, useEffect } from 'react';
// import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
// import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
// import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';

function CardInstallmentPlanOldNewValues({ cardInstallmentPlanRowData }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [dataById, setDataById] = useState([]);
    const [dataByMcRequestId, setDataByMcRequestId] = useState([]);
    const [feeType, setFeeType] = useState([]);
    const [dataId, setDataId] = useState('');
    const [payLoadSet, setPayLoadSet] = useState([]);

    const formik = useFormik({
        initialValues: {
            installmentPlanName: '',
            feeType: '',
            installmentPlan: '',
            noOfInstallment: '',
            action: '',
            checkerComments: ''
        }
    });

    useEffect(() => {
        if (cardInstallmentPlanRowData !== null && typeof cardInstallmentPlanRowData !== 'undefined' && typeof cardInstallmentPlanRowData !== undefined) {
            // getRoleWiseModule
            // const resp = await handleGetRequest(`/debitcardmanagement/v1/cards/getinstallmentplanbyid/${refTableId}`);
            // const keyData = resp?.payLoad;
            setDataById(cardInstallmentPlanRowData);
            const newPayLoad = {
                dcInstallmentPlanId: cardInstallmentPlanRowData?.dcInstallmentPlanId,
                installmentPlan: cardInstallmentPlanRowData?.installmentPlan,
                installmentPlanName: cardInstallmentPlanRowData?.installmentPlanName,
                noOfInstallment: cardInstallmentPlanRowData?.noOfInstallment,
                lkpDcFeeTypeId: cardInstallmentPlanRowData?.lkpDcFeeType?.dcFeeTypeId
            };
            setPayLoadSet(newPayLoad);
            setDataId(cardInstallmentPlanRowData?.dcInstallmentPlanId);
            formik.setFieldValue('installmentPlanName', cardInstallmentPlanRowData?.installmentPlanName);
            formik.setFieldValue('feeType', cardInstallmentPlanRowData?.lkpDcFeeType?.dcFeeTypeId?.toString());
            formik.setFieldValue('installmentPlan', cardInstallmentPlanRowData?.installmentPlan);
            formik.setFieldValue('noOfInstallment', cardInstallmentPlanRowData?.noOfInstallment);
        }
    }, [cardInstallmentPlanRowData]);

    const getInstallmentPlanData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getinstallmentplandata');
        const feeTypeList = res?.payLoad?.feeType?.map((item) => {
            // save only those whose isActive is Y
            if (item.isActive === 'Y') {
                return item;
            }
        });
        setFeeType(feeTypeList);
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        getInstallmentPlanData();
    }, []);

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>VIEW DETAIL</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="installmentPlanName" className={classNames({ 'p-error': isFormFieldValid('installmentPlanName') }, 'Label__Text')}>
                                    Installment Name
                                </label>
                                <InputText
                                    maxLength={50}
                                    minLength={3}
                                    id="installmentPlanName"
                                    disabled
                                    name="installmentPlanName"
                                    value={formik?.values?.installmentPlanName}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('installmentPlanName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('installmentPlanName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="feeType" className={classNames({ 'p-error': isFormFieldValid('feeType') }, 'Label__Text')}>
                                    Fee Type
                                </label>
                                <Dropdown
                                    id="feeType"
                                    disabled
                                    options={feeType}
                                    optionLabel="name"
                                    name="feeType"
                                    optionValue="lovId"
                                    value={formik.values.feeType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('feeType') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('feeType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="installmentPlan" className={classNames({ 'p-error': isFormFieldValid('installmentPlan') }, 'Label__Text')}>
                                    Installment Plan
                                </label>
                                <Dropdown
                                    id="installmentPlan"
                                    disabled
                                    options={[
                                        { name: 'Weekly', lovId: 'W' },
                                        { name: 'Monthly', lovId: 'M' },
                                        { name: 'Yearly', lovId: 'Y' }
                                    ]}
                                    optionLabel="name"
                                    name="installmentPlan"
                                    optionValue="lovId"
                                    value={formik.values.installmentPlan || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('installmentPlan') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('installmentPlan')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="noOfInstallment" className={classNames({ 'p-error': isFormFieldValid('noOfInstallment') }, 'Label__Text')}>
                                    No. of Installment
                                </label>
                                <InputText
                                    maxLength={50}
                                    minLength={3}
                                    id="noOfInstallment"
                                    disabled
                                    name="noOfInstallment"
                                    value={formik?.values?.noOfInstallment}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('noOfInstallment') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('noOfInstallment')}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
export default CardInstallmentPlanOldNewValues;

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
// import { Image } from 'primereact/image';
import { TabView, TabPanel } from 'primereact/tabview';
import { handleGetRequest } from '../../services/GetTemplate';

function ViewManageCustomers() {
    // const [customerImageData, setCustomerImageData] = useState([]);

    //Getting params from URL using hash router

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const cid = query.get('cid');

    //Getting ubpcompany data using ubpcompanyId

    const getCustomerDataById = async () => {
        const res = await handleGetRequest(`/account_upgrade/au/getUltraCustomersById/${cid}`);
        if (res?.responsecode === 1) {
            const keyData = res?.data?.ultraCustomer;
            const keyDataNadra = res?.data?.nadraTranslatedData;
            // const keyDataComments = res?.data?.docCommentsLists;

            // setCustomerImageData(keyData);
            // setNationalityQuesion(keyData?.ultraCustomerFatcas);
            // setCustomerDocuments(keyData?.ultraCustomerDocs);
            // setDocCommentsListsData(keyDataComments);
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            Object.keys(keyDataNadra).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyDataNadra[key]);
                }
            });
        }
    };

    useEffect(() => {
        getCustomerDataById();
    }, []); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            firstName: '',
            middleName: '',
            lastName: '',
            cnic: '',
            mobileNo: '',
            fatherHusbandName: '',
            gender: '',
            city: '',
            dob: '',
            cnicIssuanceDate: '',
            cnicExpiry: '',
            motherName: '',
            placeOfBirth: '',
            email: '',
            occupation: '',
            segment: '',
            registrationDate: '',
            currentStatus: '',
            purposeOfAccount: '',
            expectedMonthlyCashflow: '',
            dualNationality: '',
            mailingAddress: '',
            permanentAddress: '',
            chequeBook: '',
            country: '',
            federalTaxClassification: '',
            taxIdentificationNumber: '',
            foreignTaxNumber: '',
            referenceNumber: '',
            countryOfBirth: '',
            countryOfTaxResidence: '',
            residenceAddress: '',
            declaration: '',
            nameNadra: '',
            cnicNadra: '',
            motherMaidenNameNadra: '',
            placeOfBirthNadra: '',
            currentAddressNadra: '',
            permanentAddressNadra: '',
            status: '',
            accountTitle: '',
            accountNumber: '',
            accountType: '',
            channel: '',
            classification: '',
            iban: '',
            accountStatus: '',
            accountLevel: '',
            currency: ''
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            {/* {loading !== true ? ( */}
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>Manage Customers</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="tabview-demo">
                        <div className="card">
                            {/* <h5>Default</h5> */}
                            <TabView>
                                <TabPanel header="Personal Details">
                                    <div className="p-fluid p-formgrid grid mb-5">
                                        {/* First Grid 4 coloums */}
                                        <div className="p-field col-12 md:col-6 p-formgrid grid border-right-1 border-purple-100">
                                            <div className="Form__Header p-field col-12 md:col-12">
                                                <h1>Personal Details</h1>
                                            </div>
                                            <div className="p-field col-12 md:col-4">
                                                <div className="p-field">
                                                    <label htmlFor="firstName" className={classNames({ 'p-error': isFormFieldValid('firstName') }, 'Label__Text__Ultra')}>
                                                        First Name
                                                    </label>
                                                    <InputText
                                                        id="firstName"
                                                        disabled={true}
                                                        placeholder="First Name"
                                                        name="firstName"
                                                        value={formik?.values?.firstName}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('firstName') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('firstName')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4">
                                                <div className="p-field">
                                                    <label htmlFor="middleName" className={classNames({ 'p-error': isFormFieldValid('middleName') }, 'Label__Text__Ultra')}>
                                                        Middle Name
                                                    </label>
                                                    <InputText
                                                        id="middleName"
                                                        disabled={true}
                                                        placeholder="Middle Name"
                                                        name="middleName"
                                                        value={formik?.values?.middleName}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('middleName') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('middleName')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4">
                                                <div className="p-field">
                                                    <label htmlFor="lastName" className={classNames({ 'p-error': isFormFieldValid('lastName') }, 'Label__Text__Ultra')}>
                                                        Last Name
                                                    </label>
                                                    <InputText
                                                        id="lastName"
                                                        disabled={true}
                                                        placeholder="Last Name"
                                                        name="lastName"
                                                        value={formik?.values?.lastName}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('lastName') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('lastName')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4">
                                                <div className="p-field">
                                                    <label htmlFor="cnic" className={classNames({ 'p-error': isFormFieldValid('cnic') }, 'Label__Text__Ultra')}>
                                                        CNIC
                                                    </label>
                                                    <InputText
                                                        id="cnic"
                                                        disabled={true}
                                                        placeholder="cnic"
                                                        name="cnic"
                                                        value={formik?.values?.cnic}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('cnic') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('cnic')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4">
                                                <div className="p-field">
                                                    <label htmlFor="mobileNo" className={classNames({ 'p-error': isFormFieldValid('mobileNo') }, 'Label__Text__Ultra')}>
                                                        Mobile Number
                                                    </label>
                                                    <InputText
                                                        id="mobileNo"
                                                        disabled={true}
                                                        placeholder="Mobile Number"
                                                        name="mobileNo"
                                                        value={formik?.values?.mobileNo}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('mobileNo') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('mobileNo')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4">
                                                <div className="p-field">
                                                    <label htmlFor="fatherHusbandName" className={classNames({ 'p-error': isFormFieldValid('fatherHusbandName') }, 'Label__Text__Ultra')}>
                                                        Father/Husband Name
                                                    </label>
                                                    <InputText
                                                        id="fatherHusbandName"
                                                        placeholder="Father Name"
                                                        disabled={true}
                                                        name="fatherHusbandName"
                                                        value={formik?.values?.fatherHusbandName?.replace(/\s\s+/g, ' ')}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('fatherHusbandName') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('fatherHusbandName')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4">
                                                <div className="p-field">
                                                    <label htmlFor="gender" className={classNames({ 'p-error': isFormFieldValid('gender') }, 'Label__Text__Ultra')}>
                                                        Gender
                                                    </label>
                                                    <InputText
                                                        id="gender"
                                                        disabled={true}
                                                        placeholder="Gender"
                                                        name="gender"
                                                        value={formik?.values?.gender?.replace(/\s\s+/g, ' ')}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('gender') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('gender')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4">
                                                <div className="p-field">
                                                    <label htmlFor="city" className={classNames({ 'p-error': isFormFieldValid('city') }, 'Label__Text__Ultra')}>
                                                        City
                                                    </label>
                                                    <InputText
                                                        id="city"
                                                        disabled={true}
                                                        placeholder="City"
                                                        name="city"
                                                        value={formik?.values?.city?.replace(/\s\s+/g, ' ')}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('city') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('city')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4">
                                                <div className="p-field">
                                                    <label htmlFor="dob" className={classNames({ 'p-error': isFormFieldValid('dob') }, 'Label__Text__Ultra')}>
                                                        Date of Birth
                                                    </label>
                                                    <InputText
                                                        id="dob"
                                                        type="date"
                                                        disabled={true}
                                                        placeholder="Date of Birth"
                                                        name="dob"
                                                        value={formik?.values?.dob}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('dob') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('dob')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4">
                                                <div className="p-field">
                                                    <label htmlFor="cnicIssuanceDate" className={classNames({ 'p-error': isFormFieldValid('cnicIssuanceDate') }, 'Label__Text__Ultra')}>
                                                        Date of issuance
                                                    </label>
                                                    <InputText
                                                        id="cnicIssuanceDate"
                                                        type="date"
                                                        placeholder="Issuance Date"
                                                        disabled={true}
                                                        name="cnicIssuanceDate"
                                                        value={formik?.values?.cnicIssuanceDate}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('cnicIssuanceDate') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('cnicIssuanceDate')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4">
                                                <div className="p-field">
                                                    <label htmlFor="cnicExpiry" className={classNames({ 'p-error': isFormFieldValid('cnicExpiry') }, 'Label__Text__Ultra')}>
                                                        CNIC Expiry
                                                    </label>
                                                    <InputText
                                                        id="cnicExpiry"
                                                        type="date"
                                                        placeholder="Issuance Date"
                                                        disabled={true}
                                                        name="cnicExpiry"
                                                        value={formik?.values?.cnicExpiry}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('cnicExpiry') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('cnicExpiry')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4">
                                                <div className="p-field">
                                                    <label htmlFor="motherName" className={classNames({ 'p-error': isFormFieldValid('motherName') }, 'Label__Text__Ultra')}>
                                                        Mother Name
                                                    </label>
                                                    <InputText
                                                        id="motherName"
                                                        disabled={true}
                                                        placeholder="Mother Name"
                                                        name="motherName"
                                                        value={formik?.values?.motherName?.replace(/\s\s+/g, ' ')}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('motherName') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('motherName')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4">
                                                <div className="p-field">
                                                    <label htmlFor="placeOfBirth" className={classNames({ 'p-error': isFormFieldValid('placeOfBirth') }, 'Label__Text__Ultra')}>
                                                        Place of Birth
                                                    </label>
                                                    <InputText
                                                        id="placeOfBirth"
                                                        disabled={true}
                                                        placeholder="Place of Birth"
                                                        name="placeOfBirth"
                                                        value={formik?.values?.placeOfBirth}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('placeOfBirth') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('placeOfBirth')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4">
                                                <div className="p-field">
                                                    <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') }, 'Label__Text__Ultra')}>
                                                        Email Address
                                                    </label>
                                                    <InputText
                                                        id="email"
                                                        disabled={true}
                                                        placeholder="Email Address"
                                                        name="email"
                                                        value={formik?.values?.email}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('email') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('email')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4">
                                                <div className="p-field">
                                                    <label htmlFor="occupation" className={classNames({ 'p-error': isFormFieldValid('occupation') }, 'Label__Text__Ultra')}>
                                                        Occupation
                                                    </label>
                                                    <InputText
                                                        id="occupation"
                                                        disabled={true}
                                                        placeholder="Occupation"
                                                        name="occupation"
                                                        value={formik?.values?.occupation}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('occupation') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('occupation')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4">
                                                <div className="p-field">
                                                    <label htmlFor="segment" className={classNames({ 'p-error': isFormFieldValid('segment') }, 'Label__Text__Ultra')}>
                                                        Segment
                                                    </label>
                                                    <InputText
                                                        id="segment"
                                                        disabled={true}
                                                        placeholder="Segment"
                                                        name="segment"
                                                        value={formik?.values?.segment}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('segment') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('segment')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4">
                                                <div className="p-field">
                                                    <label htmlFor="registrationDate" className={classNames({ 'p-error': isFormFieldValid('registrationDate') }, 'Label__Text__Ultra')}>
                                                        Registration Date
                                                    </label>
                                                    <InputText
                                                        id="registrationDate"
                                                        type="date"
                                                        disabled={true}
                                                        name="registrationDate"
                                                        value={formik?.values?.registrationDate}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('registrationDate') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('registrationDate')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4">
                                                <div className="p-field">
                                                    <label htmlFor="currentStatus" className={classNames({ 'p-error': isFormFieldValid('currentStatus') }, 'Label__Text__Ultra')}>
                                                        Current Status
                                                    </label>
                                                    <InputText
                                                        id="currentStatus"
                                                        disabled={true}
                                                        placeholder="Current Status"
                                                        name="currentStatus"
                                                        value={formik?.values?.currentStatus?.replace(/\s\s+/g, ' ')}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('currentStatus') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('currentStatus')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4">
                                                <div className="p-field">
                                                    <label htmlFor="purposeOfAccount" className={classNames({ 'p-error': isFormFieldValid('purposeOfAccount') }, 'Label__Text__Ultra')}>
                                                        Purpose Of Account
                                                    </label>
                                                    <InputText
                                                        id="purposeOfAccount"
                                                        placeholder="Purpose Of Account"
                                                        disabled={true}
                                                        name="purposeOfAccount"
                                                        value={formik?.values?.purposeOfAccount}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('purposeOfAccount') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('purposeOfAccount')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-8">
                                                <div className="p-field">
                                                    <label htmlFor="expectedMonthlyCashflow" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyCashflow') }, 'Label__Text__Ultra')}>
                                                        Expected Monthly Cash Flow
                                                    </label>
                                                    <InputText
                                                        id="expectedMonthlyCashflow"
                                                        disabled={true}
                                                        placeholder="Cash Flow"
                                                        name="expectedMonthlyCashflow"
                                                        value={formik?.values?.expectedMonthlyCashflow}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyCashflow') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('expectedMonthlyCashflow')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4">
                                                <label htmlFor="chequeBook" className={classNames({ 'p-error': isFormFieldValid('chequeBook') }, 'Label__Text__Ultra')}>
                                                    Cheque Book
                                                </label>
                                                <InputText
                                                    id="chequeBook"
                                                    disabled={true}
                                                    placeholder="Cheque Book"
                                                    name="chequeBook"
                                                    value={formik?.values?.chequeBook}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('chequeBook') }, 'Input__Round__Ultra')}
                                                />

                                                {getFormErrorMessage('chequeBook')}
                                            </div>
                                            <div className="p-field col-12 md:col-12">
                                                <div className="p-field">
                                                    <label htmlFor="dualNationality" className={classNames({ 'p-error': isFormFieldValid('dualNationality') }, 'Label__Text__Ultra')}>
                                                        Dual Nationality
                                                    </label>
                                                    <InputText
                                                        id="dualNationality"
                                                        disabled={true}
                                                        placeholder="Dual Nationality"
                                                        name="dualNationality"
                                                        value={formik?.values?.dualNationality}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('dualNationality') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('dualNationality')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-12">
                                                <div className="p-field">
                                                    <label htmlFor="mailingAddress" className={classNames({ 'p-error': isFormFieldValid('mailingAddress') }, 'Label__Text__Ultra')}>
                                                        Mailing Address
                                                    </label>
                                                    <InputText
                                                        id="mailingAddress"
                                                        disabled={true}
                                                        placeholder="Current Mail Address"
                                                        name="mailingAddress"
                                                        value={formik?.values?.mailingAddress}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('mailingAddress') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('mailingAddress')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-12">
                                                <div className="p-field">
                                                    <label htmlFor="permanentAddress" className={classNames({ 'p-error': isFormFieldValid('permanentAddress') }, 'Label__Text__Ultra')}>
                                                        Permanent Address
                                                    </label>
                                                    <InputText
                                                        id="permanentAddress"
                                                        disabled={true}
                                                        placeholder="Permanent Address"
                                                        name="permanentAddress"
                                                        value={formik?.values?.permanentAddress}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('permanentAddress') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('permanentAddress')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-6">
                                                <div className="p-field">
                                                    <label htmlFor="cnicFront" className={classNames({ 'p-error': isFormFieldValid('cnicFront') }, 'Label__Text__Ultra')}>
                                                        CNIC Front
                                                    </label>
                                                    <div>
                                                        {/* <Image src={`data:image/png;base64,${customerImageData?.cnicFront}`} zoomSrc={`data:image/png;base64,${customerImageData?.cnicFront}`} alt="Image" width="100" height="100" preview /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-6">
                                                <div className="p-field">
                                                    <label htmlFor="cnicBack" className={classNames({ 'p-error': isFormFieldValid('cnicBack') }, 'Label__Text__Ultra')}>
                                                        CNIC Back
                                                    </label>
                                                    <div>
                                                        {/* <Image src={`data:image/png;base64,${customerImageData?.cnicBack}`} zoomSrc={`data:image/png;base64,${customerImageData?.cnicBack}`} alt="Image" width="100" height="100" preview /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Second Grid 4 Columns */}
                                        <div className="p-field col-12 md:col-6 border-purple-100 ml-2">
                                            <div className="Form__Header md:col-12">
                                                <h1>NADRA Details</h1>
                                            </div>
                                            <div className="p-fluid p-formgrid grid">
                                                <div className="p-field col-12 md:col-12">
                                                    <div className="p-field">
                                                        <label htmlFor="nameNadra" className={classNames({ 'p-error': isFormFieldValid('nameNadra') }, 'Label__Text__Ultra')}>
                                                            Name
                                                        </label>
                                                        <InputText
                                                            id="nameNadra"
                                                            disabled={true}
                                                            placeholder="name"
                                                            name="nameNadra"
                                                            value={formik?.values?.nameNadra?.trim()?.toString()}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid('nameNadra') }, 'Input__Round__Ultra')}
                                                        />

                                                        {getFormErrorMessage('nameNadra')}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-12">
                                                    <div className="p-field">
                                                        <label htmlFor="cnicNadra" className={classNames({ 'p-error': isFormFieldValid('cnicNadra') }, 'Label__Text__Ultra')}>
                                                            Citizen Number
                                                        </label>
                                                        <InputText
                                                            id="cnicNadra"
                                                            disabled={true}
                                                            placeholder="Citizen Number"
                                                            name="cnicNadra"
                                                            value={formik?.values?.cnicNadra?.trim()?.toString()}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid('cnicNadra') }, 'Input__Round__Ultra')}
                                                        />

                                                        {getFormErrorMessage('cnicNadra')}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-12">
                                                    <div className="p-field">
                                                        <label htmlFor="motherMaidenNameNadra" className={classNames({ 'p-error': isFormFieldValid('motherMaidenNameNadra') }, 'Label__Text__Ultra')}>
                                                            Mother Name
                                                        </label>
                                                        <InputText
                                                            id="motherMaidenNameNadra"
                                                            disabled={true}
                                                            placeholder="Mother Name"
                                                            name="motherMaidenNameNadra"
                                                            value={formik?.values?.motherMaidenNameNadra}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid('motherMaidenNameNadra') }, 'Input__Round__Ultra')}
                                                        />

                                                        {getFormErrorMessage('motherMaidenNameNadra')}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-12">
                                                    <div className="p-field">
                                                        <label htmlFor="placeOfBirthNadra" className={classNames({ 'p-error': isFormFieldValid('placeOfBirthNadra') }, 'Label__Text__Ultra')}>
                                                            Place of Birth
                                                        </label>
                                                        <InputText
                                                            id="placeOfBirthNadra"
                                                            disabled={true}
                                                            placeholder="Place of Birth"
                                                            name="placeOfBirthNadra"
                                                            value={formik?.values?.placeOfBirthNadra}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid('placeOfBirthNadra') }, 'Input__Round__Ultra')}
                                                        />

                                                        {getFormErrorMessage('placeOfBirthNadra')}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-12">
                                                    <div className="p-field">
                                                        <label htmlFor="currentAddressNadra" className={classNames({ 'p-error': isFormFieldValid('currentAddressNadra') }, 'Label__Text__Ultra')}>
                                                            Present Address
                                                        </label>
                                                        <InputText
                                                            id="currentAddressNadra"
                                                            disabled={true}
                                                            placeholder="Current Address"
                                                            name="currentAddressNadra"
                                                            value={formik?.values?.currentAddressNadra}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid('currentAddressNadra') }, 'Input__Round__Ultra')}
                                                        />

                                                        {getFormErrorMessage('currentAddressNadra')}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-12">
                                                    <div className="p-field">
                                                        <label htmlFor="permanentAddressNadra" className={classNames({ 'p-error': isFormFieldValid('permanentAddressNadra') }, 'Label__Text__Ultra')}>
                                                            Permanent Address
                                                        </label>
                                                        <InputText
                                                            id="permanentAddressNadra"
                                                            disabled={true}
                                                            placeholder="Present Address"
                                                            name="permanentAddressNadra"
                                                            value={formik?.values?.permanentAddressNadra}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid('permanentAddressNadra') }, 'Input__Round__Ultra')}
                                                        />

                                                        {getFormErrorMessage('permanentAddressNadra')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel header="Accounts Details">
                                    <div className="p-fluid p-formgrid grid mb-5">
                                        {/* Third Gird 12 Columns */}
                                        <div className="p-field col-12 md:col-12 border-purple-100 ml-2">
                                            <div className="Form__Header md:col-12">
                                                <h1>Account Details</h1>
                                            </div>
                                            <div className="p-fluid p-formgrid grid">
                                                <div className="p-field col-12 md:col-6">
                                                    <div className="p-field">
                                                        <label htmlFor="accountTitle" className={classNames({ 'p-error': isFormFieldValid('accountTitle') }, 'Label__Text__Ultra')}>
                                                            Account Title
                                                        </label>
                                                        <InputText
                                                            id="accountTitle"
                                                            disabled={true}
                                                            placeholder="Account Title"
                                                            name="accountTitle"
                                                            value={formik?.values?.accountTitle?.replace(/\s\s+/g, ' ')}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid('accountTitle') }, 'Input__Round__Ultra')}
                                                        />

                                                        {getFormErrorMessage('accountTitle')}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-6">
                                                    <div className="p-field">
                                                        <label htmlFor="accountNumber" className={classNames({ 'p-error': isFormFieldValid('accountNumber') }, 'Label__Text__Ultra')}>
                                                            Account Number
                                                        </label>
                                                        <InputText
                                                            id="accountNumber"
                                                            disabled={true}
                                                            placeholder="Account Number"
                                                            name="accountNumber"
                                                            value={formik?.values?.accountNumber?.replace(/\s\s+/g, ' ')}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid('accountNumber') }, 'Input__Round__Ultra')}
                                                        />

                                                        {getFormErrorMessage('accountNumber')}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-6">
                                                    <div className="p-field">
                                                        <label htmlFor="accountType" className={classNames({ 'p-error': isFormFieldValid('accountType') }, 'Label__Text__Ultra')}>
                                                            Account Type
                                                        </label>
                                                        <InputText
                                                            id="accountType"
                                                            disabled={true}
                                                            placeholder="Account Type"
                                                            name="accountType"
                                                            value={formik?.values?.accountType?.replace(/\s\s+/g, ' ')}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid('accountType') }, 'Input__Round__Ultra')}
                                                        />

                                                        {getFormErrorMessage('accountType')}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-6">
                                                    <div className="p-field">
                                                        <label htmlFor="channel" className={classNames({ 'p-error': isFormFieldValid('channel') }, 'Label__Text__Ultra')}>
                                                            Channel
                                                        </label>
                                                        <InputText
                                                            id="channel"
                                                            disabled={true}
                                                            placeholder="Channel"
                                                            name="channel"
                                                            value={formik?.values?.channel?.replace(/\s\s+/g, ' ')}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid('channel') }, 'Input__Round__Ultra')}
                                                        />

                                                        {getFormErrorMessage('channel')}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-6">
                                                    <div className="p-field">
                                                        <label htmlFor="classification" className={classNames({ 'p-error': isFormFieldValid('classification') }, 'Label__Text__Ultra')}>
                                                            Classification
                                                        </label>
                                                        <InputText
                                                            id="classification"
                                                            disabled={true}
                                                            placeholder="Classification"
                                                            name="classification"
                                                            value={formik?.values?.classification?.replace(/\s\s+/g, ' ')}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid('classification') }, 'Input__Round__Ultra')}
                                                        />

                                                        {getFormErrorMessage('classification')}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-6">
                                                    <div className="p-field">
                                                        <label htmlFor="iban" className={classNames({ 'p-error': isFormFieldValid('iban') }, 'Label__Text__Ultra')}>
                                                            IBAN
                                                        </label>
                                                        <InputText
                                                            id="iban"
                                                            disabled={true}
                                                            placeholder="IBAN"
                                                            name="iban"
                                                            value={formik?.values?.iban?.trim()?.toString()}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid('iban') }, 'Input__Round__Ultra')}
                                                        />

                                                        {getFormErrorMessage('iban')}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-6">
                                                    <div className="p-field">
                                                        <label htmlFor="accountStatus" className={classNames({ 'p-error': isFormFieldValid('accountStatus') }, 'Label__Text__Ultra')}>
                                                            Account Status
                                                        </label>
                                                        <InputText
                                                            id="accountStatus"
                                                            disabled={true}
                                                            placeholder="Account Status"
                                                            name="accountStatus"
                                                            value={formik?.values?.accountStatus?.replace(/\s\s+/g, ' ')}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid('accountStatus') }, 'Input__Round__Ultra')}
                                                        />

                                                        {getFormErrorMessage('accountStatus')}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-6">
                                                    <div className="p-field">
                                                        <label htmlFor="accountLevel" className={classNames({ 'p-error': isFormFieldValid('accountLevel') }, 'Label__Text__Ultra')}>
                                                            Account Level
                                                        </label>
                                                        <InputText
                                                            id="accountLevel"
                                                            disabled={true}
                                                            placeholder="Account Level"
                                                            name="accountLevel"
                                                            value={formik?.values?.accountLevel?.replace(/\s\s+/g, ' ')}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid('accountLevel') }, 'Input__Round__Ultra')}
                                                        />

                                                        {getFormErrorMessage('accountLevel')}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-6">
                                                    <div className="p-field">
                                                        <label htmlFor="currency" className={classNames({ 'p-error': isFormFieldValid('currency') }, 'Label__Text__Ultra')}>
                                                            Currency
                                                        </label>
                                                        <InputText
                                                            id="currency"
                                                            disabled={true}
                                                            placeholder="Currency"
                                                            name="currency"
                                                            value={formik?.values?.currency?.trim()?.toString()}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid('currency') }, 'Input__Round__Ultra')}
                                                        />

                                                        {getFormErrorMessage('currency')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            </TabView>
                        </div>
                    </div>
                </form>

                {/* 
                        {formik.values.dualNationality === 'Yes' ? (
                            <Accordion activeIndex={0} style={{ width: '100%' }}>
                                <AccordionTab header="Dual Nationality Yes/No">
                                    <div className="p-field col-12 md:col-12 p-formgrid grid">
                                        <div className="p-field col-12 md:col-6 p-formgrid grid border-right-1 border-purple-100">
                                            <div className="p-field col-12 md:col-6">
                                                <div className="p-field">
                                                    <label htmlFor="country" className={classNames({ 'p-error': isFormFieldValid('country') }, 'Label__Text__Ultra')}>
                                                        Country
                                                    </label>
                                                    <InputText
                                                        id="country"
                                                        disabled={true}
                                                        placeholder="Country"
                                                        name="country"
                                                        value={formik?.values?.country?.trim()?.toString()}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('country') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('country')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-6">
                                                <div className="p-field">
                                                    <label htmlFor="federalTaxClassification" className={classNames({ 'p-error': isFormFieldValid('federalTaxClassification') }, 'Label__Text__Ultra')}>
                                                        Federal Tax Classification
                                                    </label>
                                                    <InputText
                                                        id="federalTaxClassification"
                                                        disabled={true}
                                                        placeholder="Federal Tax"
                                                        name="federalTaxClassification"
                                                        value={formik?.values?.federalTaxClassification?.trim()?.toString()}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('federalTaxClassification') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('federalTaxClassification')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-6">
                                                <div className="p-field">
                                                    <label htmlFor="taxIdentificationNumber" className={classNames({ 'p-error': isFormFieldValid('taxIdentificationNumber') }, 'Label__Text__Ultra')}>
                                                        Tax Identification Number
                                                    </label>
                                                    <InputText
                                                        id="taxIdentificationNumber"
                                                        disabled={true}
                                                        placeholder="Tax Identification No"
                                                        name="taxIdentificationNumber"
                                                        value={formik?.values?.taxIdentificationNumber?.trim()?.toString()}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('taxIdentificationNumber') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('taxIdentificationNumber')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-6">
                                                <div className="p-field">
                                                    <label htmlFor="foreignTaxNumber" className={classNames({ 'p-error': isFormFieldValid('foreignTaxNumber') }, 'Label__Text__Ultra')}>
                                                        Foreign Tax Number
                                                    </label>
                                                    <InputText
                                                        id="foreignTaxNumber"
                                                        disabled={true}
                                                        placeholder="Foreign Tax Number"
                                                        name="foreignTaxNumber"
                                                        value={formik?.values?.foreignTaxNumber?.trim()?.toString()}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('foreignTaxNumber') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('foreignTaxNumber')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-6">
                                                <div className="p-field">
                                                    <label htmlFor="referenceNumber" className={classNames({ 'p-error': isFormFieldValid('referenceNumber') }, 'Label__Text__Ultra')}>
                                                        Reference Number
                                                    </label>
                                                    <InputText
                                                        id="referenceNumber"
                                                        disabled={true}
                                                        placeholder="Reference Number"
                                                        name="referenceNumber"
                                                        value={formik?.values?.referenceNumber?.trim()?.toString()}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('referenceNumber') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('referenceNumber')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-6">
                                                <div className="p-field">
                                                    <label htmlFor="countryOfBirth" className={classNames({ 'p-error': isFormFieldValid('countryOfBirth') }, 'Label__Text__Ultra')}>
                                                        Country Of Birth
                                                    </label>
                                                    <InputText
                                                        id="countryOfBirth"
                                                        disabled={true}
                                                        placeholder="Reference Number"
                                                        name="countryOfBirth"
                                                        value={formik?.values?.countryOfBirth?.trim()?.toString()}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('countryOfBirth') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('countryOfBirth')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-6">
                                                <div className="p-field">
                                                    <label htmlFor="countryOfTaxResidence" className={classNames({ 'p-error': isFormFieldValid('countryOfTaxResidence') }, 'Label__Text__Ultra')}>
                                                        Country Of Tax Residence
                                                    </label>
                                                    <InputText
                                                        id="countryOfTaxResidence"
                                                        disabled={true}
                                                        placeholder="Reference Number"
                                                        name="countryOfTaxResidence"
                                                        value={formik?.values?.countryOfTaxResidence?.trim()?.toString()}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('countryOfTaxResidence') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('countryOfTaxResidence')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-6">
                                                <div className="p-field">
                                                    <label htmlFor="residenceAddress" className={classNames({ 'p-error': isFormFieldValid('residenceAddress') }, 'Label__Text__Ultra')}>
                                                        Residence Address
                                                    </label>
                                                    <InputText
                                                        id="residenceAddress"
                                                        disabled={true}
                                                        placeholder="Residence Address"
                                                        name="residenceAddress"
                                                        value={formik?.values?.residenceAddress?.trim()?.toString()}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('residenceAddress') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('residenceAddress')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-12">
                                                <div className="p-field">
                                                    <label htmlFor="permanentAddress" className={classNames({ 'p-error': isFormFieldValid('permanentAddress') }, 'Label__Text__Ultra')}>
                                                        Permanent Address
                                                    </label>
                                                    <InputText
                                                        id="permanentAddress"
                                                        disabled={true}
                                                        placeholder="Permanent Address"
                                                        name="permanentAddress"
                                                        value={formik?.values?.permanentAddress?.trim()?.toString()}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('permanentAddress') }, 'Input__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('permanentAddress')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-12">
                                                <div className="p-field">
                                                    <label htmlFor="declaration" className={classNames({ 'p-error': isFormFieldValid('declaration') }, 'Label__Text__Ultra')}>
                                                        Declaration
                                                    </label>
                                                    <InputTextarea
                                                        autoResize="false"
                                                        disabled={true}
                                                        id="declaration"
                                                        placeholder="Declaration"
                                                        name="declaration"
                                                        value={formik?.values?.declaration?.trim()?.toString()}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('declaration') }, 'TextArea__Round__Ultra')}
                                                    />

                                                    {getFormErrorMessage('declaration')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-6">
                                            <div className="grid View__Ultra__DataTable">
                                                <div className="col-12">
                                                    <div className="card">
                                                        <OrderList
                                                            dragdrop={true}
                                                            value={nationalityQuestion}
                                                            header="Dual Nationality Questions"
                                                            listStyle={{ height: 'auto' }}
                                                            dataKey="ultraCustomerFatcaId"
                                                            itemTemplate={itemTemplate}
                                                            onChange={(e) => setNationalityQuesion(e.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionTab>
                            </Accordion>
                        ) : null} */}

                {/* Fourth Grid 4 coloums  */}
                {/* <div className="p-field col-12 md:col-12 flex flex-wrap">
                            <div className="Form__Header md:col-12">
                                <h1>Active File Thumbnails</h1>
                            </div>
                            <div className="p-field col-12 md:col-12 pl-5">
                                <div className="grid View__Ultra__DataTable">
                                    <div className="col-12">
                                        <div className="card">
                                            <DataTable scrollable rows={10} className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={customersDocuments} rowClassName={RowClassTemplate}>
                                                <Column field="fieldName" header="Field Name" />
                                                <Column field="fieldValue" body={ImageTemplate} header="Field Value" />
                                                <Column field="status" body={statusTemplate} header="Status" />
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
            </div>

            {/* ) : (
                <ProgressSpinner style={{ display: "flex", justifyContent: "center", alignItem: "center", height: "100vh" }} />
            )} */}
        </>
    );
}

export default ViewManageCustomers;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../services/PostTemplate';
import { handleGetRequest } from '../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { PickList } from 'primereact/picklist';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { InputNumber } from 'primereact/inputnumber';

function EditTransactionwiseLimit() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [source, setSource] = useState([]);
    const [target, setTarget] = useState([]);
    const [accountLevelData, setAccountLevelData] = useState([]);
    const [productPickList, setProductPickList] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const reqID = query.get('reqID');

    const getAccLevelLov = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getaccounttypes/LKP_ACCOUNT_LEVEL:*');
        setAccountLevelData(response?.payLoad);
    };

    const getDatabyId = async () => {
        const resp = await handleGetRequest(`/limits/v1/limitsprofiles/getTransLimitsById/${reqID}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            formik.setFieldValue('lkpAccountLevelId', keyData?.lkpAccountLevel?.accountLevelId?.toString());

            if (keyData?.dailyTransLimitDr === null || keyData?.dailyTransLimitDr === '') {
                formik.setFieldValue('activeDailyTransLimitDr', true);
            }
            if (keyData?.monthlyTransLimitDr === null || keyData?.monthlyTransLimitDr === '') {
                formik.setFieldValue('activeMonthlyTransLimitDr', true);
            }
            if (keyData?.yearlyTransLimitDr === null || keyData?.yearlyTransLimitDr === '') {
                formik.setFieldValue('activeYearlyTransLimitDr', true);
            }
            if (keyData?.dailyTransLimitCr === null || keyData?.dailyTransLimitCr === '') {
                formik.setFieldValue('activeDailyTransLimitCr', true);
            }
            if (keyData?.monthlyTransLimitCr === null || keyData?.monthlyTransLimitCr === '') {
                formik.setFieldValue('activeMonthlyTransLimitCr', true);
            }
            if (keyData?.yearlyTransLimitCr === null || keyData?.yearlyTransLimitCr === '') {
                formik.setFieldValue('activeYearlyTransLimitCr', true);
            }

            setSource(
                resp?.payLoad?.tblTransLimitDetails?.sort((a, b) => {
                    if (a.tblProduct.productName?.toLowerCase() > b.tblProduct.productName?.toLowerCase()) {
                        return 1;
                    }
                    if (a.tblProduct.productName?.toLowerCase() < b.tblProduct.productName?.toLowerCase()) {
                        return -1;
                    }
                    return 0;
                })
            );
            setTarget(
                resp?.payLoad?.tblProduct?.sort((a, b) => {
                    if (a.productName?.toLowerCase() > b.productName?.toLowerCase()) {
                        return 1;
                    }
                    if (a.productName?.toLowerCase() < b.productName?.toLowerCase()) {
                        return -1;
                    }
                    return 0;
                })
            );

            // setAccountLevelData([resp.payLoad.lkpAccountLevel]);
        }
    };
    useEffect(() => {
        getDatabyId();
        getAccLevelLov();
    }, []); //eslint-disable-line

    const unselectPickListData = source?.map((item) => ({
        tblProductId: item?.tblProduct?.productId
    }));

    const onChange = (event) => {
        const pickListIDs = event.source.map((item) => item);
        const pickListIdsData = pickListIDs.map((item) => ({
            tblProductId: item?.productId || item?.tblProduct?.productId
        }));

        setProductPickList(pickListIdsData);
        setSource(event.source);
        setTarget(event.target);
    };

    const sourceItemTemplate = (itemData) => {
        const item = itemData.tblProduct;
        return (
            <div className="product-item">
                <div className="product-list-detail">
                    <h5 className="mb-2">{item?.productName || itemData.productName}</h5>
                </div>
            </div>
        );
    };

    const targetItemTemplate = (item) => {
        const itemData = item?.tblProduct;
        return (
            <div className="product-item">
                <div className="product-list-detail">
                    <h5 className="mb-2">{item?.productName || itemData?.productName}</h5>
                </div>
            </div>
        );
    };

    const validationSchema = Yup.object().shape({
        limitProfileName: Yup.string().required('This field is required.').max(200, 'Max length 200 characters'),
        lkpAccountLevelId: Yup.mixed().required('This field is required.'),
        dailyTransLimitDr: Yup.string()
            .when('activeDailyTransLimitDr', {
                is: false,
                then: Yup.string()
                    .required('This field is required.')
                    .matches(/^[1-9][0-9]*$/, 'Please enter a numeric value greater than 0')
                    .nullable()
            })
            .nullable(),
        dailyAmtLimitDr: Yup.string()
            .required('This field is required.')
            .matches(/^[1-9][0-9]*$/, 'Please enter a numeric value greater than 0')
            .nullable(),
        monthlyTransLimitDr: Yup.string()
            .when('activeMonthlyTransLimitDr', {
                is: false,
                then: Yup.string()
                    .required('This field is required.')
                    .matches(/^[1-9][0-9]*$/, 'Please enter a numeric value greater than 0')
                    .nullable()
            })
            .nullable(),
        monthlyAmtLimitDr: Yup.string()
            .required('This field is required.')
            .matches(/^[1-9][0-9]*$/, 'Please enter a numeric value greater than 0')
            .nullable(),
        yearlyTransLimitDr: Yup.string()
            .when('activeYearlyTransLimitDr', {
                is: false,
                then: Yup.string()
                    .required('This field is required.')
                    .matches(/^[1-9][0-9]*$/, 'Please enter a numeric value greater than 0')
                    .nullable()
            })
            .nullable(),
        yearlyAmtLimitDr: Yup.string()
            .required('This field is required.')
            .matches(/^[1-9][0-9]*$/, 'Please enter a numeric value greater than 0')
            .nullable(),
        dailyTransLimitCr: Yup.string()
            .when('activeDailyTransLimitCr', {
                is: false,
                then: Yup.string()
                    .required('This field is required.')
                    .matches(/^[1-9][0-9]*$/, 'Please enter a numeric value greater than 0')
                    .nullable()
            })
            .nullable(),
        dailyAmtLimitCr: Yup.string()
            .required('This field is required.')
            .matches(/^[1-9][0-9]*$/, 'Please enter a numeric value greater than 0')
            .nullable(),
        monthlyTransLimitCr: Yup.string()
            .when('activeMonthlyTransLimitCr', {
                is: false,
                then: Yup.string()
                    .required('This field is required.')
                    .matches(/^[1-9][0-9]*$/, 'Please enter a numeric value greater than 0')
                    .nullable()
            })
            .nullable(),
        monthlyAmtLimitCr: Yup.string()
            .required('This field is required.')
            .matches(/^[1-9][0-9]*$/, 'Please enter a numeric value greater than 0')
            .nullable(),
        yearlyTransLimitCr: Yup.string()
            .when('activeYearlyTransLimitCr', {
                is: false,
                then: Yup.string()
                    .required('This field is required.')
                    .matches(/^[1-9][0-9]*$/, 'Please enter a numeric value greater than 0')
                    .nullable()
            })
            .nullable(),
        yearlyAmtLimitCr: Yup.string()
            .required('This field is required.')
            .matches(/^[1-9][0-9]*$/, 'Please enter a numeric value greater than 0')
            .nullable()
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            limitProfileName: '',
            lkpAccountLevelId: '',
            activeDailyTransLimitDr: false,
            dailyTransLimitDr: '',
            dailyAmtLimitDr: '',
            activeMonthlyTransLimitDr: false,
            monthlyTransLimitDr: '',
            monthlyAmtLimitDr: '',
            activeYearlyTransLimitDr: false,
            yearlyTransLimitDr: '',
            yearlyAmtLimitDr: '',
            activeDailyTransLimitCr: false,
            dailyTransLimitCr: '',
            dailyAmtLimitCr: '',
            activeMonthlyTransLimitCr: false,
            monthlyTransLimitCr: '',
            monthlyAmtLimitCr: '',
            activeYearlyTransLimitCr: false,
            yearlyTransLimitCr: '',
            yearlyAmtLimitCr: ''
        },
        validate: (data) => {
            let errors = {};

            // Debit Amount less/greater than Amount Validation
            if (data?.dailyAmtLimitDr) {
                if (parseInt(data?.dailyAmtLimitDr) > parseInt(data?.monthlyAmtLimitDr)) {
                    errors.monthlyAmtLimitDr = 'Monthly Amount should be greater than Daily Amount';
                }
            }
            if (data?.monthlyAmtLimitDr) {
                if (parseInt(data?.monthlyAmtLimitDr) > parseInt(data?.yearlyAmtLimitDr)) {
                    errors.yearlyAmtLimitDr = 'Yearly Amount should be greater than Monthly Amount';
                }
            }

            // Debit Count less/greater than Count Validation
            if (data?.dailyTransLimitDr) {
                if (parseInt(data?.dailyTransLimitDr) > parseInt(data?.monthlyTransLimitDr)) {
                    errors.monthlyTransLimitDr = 'Monthly Count should be greater than Daily Count';
                }
            }
            if (data?.monthlyTransLimitDr) {
                if (parseInt(data?.monthlyTransLimitDr) > parseInt(data?.yearlyTransLimitDr)) {
                    errors.yearlyTransLimitDr = 'Yearly Count should be greater than Monthly Count';
                }
            }

            // Credit Amount less/greater than Amount Validation
            if (data?.dailyAmtLimitCr) {
                if (parseInt(data?.dailyAmtLimitCr) > parseInt(data?.monthlyAmtLimitCr)) {
                    errors.monthlyAmtLimitCr = 'Monthly Amount should be greater than Daily Amount';
                }
            }
            if (data?.monthlyAmtLimitCr) {
                if (parseInt(data?.monthlyAmtLimitCr) > parseInt(data?.yearlyAmtLimitCr)) {
                    errors.yearlyAmtLimitCr = 'Yearly Amount should be greater than Monthly Amount';
                }
            }

            // Credit Count less/greater than Count Validation
            if (data?.dailyTransLimitCr) {
                if (parseInt(data?.dailyTransLimitCr) > parseInt(data?.monthlyTransLimitCr)) {
                    errors.monthlyTransLimitCr = 'Monthly Count should be greater than Daily Count';
                }
            }
            if (data?.monthlyTransLimitCr) {
                if (parseInt(data?.monthlyTransLimitCr) > parseInt(data?.yearlyTransLimitCr)) {
                    errors.yearlyTransLimitCr = 'Yearly Count should be greater than Monthly Count';
                }
            }

            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['transLimitId'] = reqID;
            data['excludeLimit'] = '';

            formik.setFieldValue('activeDailyTransLimitDr', data?.activeDailyTransLimitDr === true ? true : false);
            formik.setFieldValue('activeMonthlyTransLimitDr', data?.activeMonthlyTransLimitDr === true ? true : false);
            formik.setFieldValue('activeYearlyTransLimitDr', data?.activeYearlyTransLimitDr === true ? true : false);
            formik.setFieldValue('activeDailyTransLimitCr', data?.activeDailyTransLimitCr === true ? true : false);
            formik.setFieldValue('activeMonthlyTransLimitCr', data?.activeMonthlyTransLimitCr === true ? true : false);
            formik.setFieldValue('activeYearlyTransLimitCr', data?.activeYearlyTransLimitCr === true ? true : false);

            // Empty Value Based on checkbox checked

            if (data['activeDailyTransLimitDr']) {
                data['dailyTransLimitDr'] = '';
            }
            if (data['activeMonthlyTransLimitDr']) {
                data['monthlyTransLimitDr'] = '';
            }
            if (data['activeYearlyTransLimitDr']) {
                data['yearlyTransLimitDr'] = '';
            }

            if (data['activeDailyTransLimitCr']) {
                data['dailyTransLimitCr'] = '';
            }
            if (data['activeMonthlyTransLimitCr']) {
                data['monthlyTransLimitCr'] = '';
            }
            if (data['activeYearlyTransLimitCr']) {
                data['yearlyTransLimitCr'] = '';
            }

            if (source.length !== 0) {
                delete data['activeDailyTransLimitDr'];
                delete data['activeMonthlyTransLimitDr'];
                delete data['activeYearlyTransLimitDr'];
                delete data['activeDailyTransLimitCr'];
                delete data['activeMonthlyTransLimitCr'];
                delete data['activeYearlyTransLimitCr'];
            }

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data,
                        tblTransLimitDetails: productPickList.length > 0 ? productPickList : unselectPickListData
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            if (source.length === 0) {
                toast.warn('Please Select Products');
            } else {
                const res = await dispatch(handlePostRequest(newData, '/limits/v1/limitsprofiles/updatetransactionwiselimitprofile', true, false, 'transactionwiselimit'));
                if (res?.responseCode === '020000') {
                    formik.resetForm();
                }
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <p className="__heading_">EDIT DETAILS</p>
                </div>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="limitProfileName" className={classNames({ 'p-error': isFormFieldValid('limitProfileName') }, 'Label__Text')}>
                                    Limit Profile<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="limitProfileName"
                                    placeholder=""
                                    name="limitProfileName"
                                    value={formik?.values?.limitProfileName?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('limitProfileName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('limitProfileName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="lkpAccountLevelId" className={classNames({ 'p-error': isFormFieldValid('lkpAccountLevelId') }, 'Label__Text')}>
                                    Limit<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="lkpAccountLevelId"
                                    placeholder="Select Account Level"
                                    options={accountLevelData}
                                    optionLabel="name"
                                    name="lkpAccountLevelId"
                                    optionValue="lovId"
                                    value={formik.values.lkpAccountLevelId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpAccountLevelId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('lkpAccountLevelId')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <PickList
                                source={source}
                                target={target}
                                sourceItemTemplate={sourceItemTemplate}
                                targetItemTemplate={targetItemTemplate}
                                sourceHeader="Include"
                                targetHeader="Exclude"
                                sourceStyle={{ height: 'auto' }}
                                targetStyle={{ height: 'auto' }}
                                onChange={onChange}
                                filterBy={'tblProduct.productName,productName'}
                                sourceFilterPlaceholder="Search by name"
                                targetFilterPlaceholder="Search by name"
                            />
                        </div>
                    </div>
                    <div className="cards_section___">
                        <div className="Debit_card">
                            <p className="card_heading__">Debit</p>
                            <div className="ca_rds">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__">
                                        <p>Daily</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox inputId="activeDailyTransLimitDr" name="activeDailyTransLimitDr" value={formik.values.activeDailyTransLimitDr} checked={formik.values.activeDailyTransLimitDr} onChange={formik.handleChange} />
                                            </div>
                                            <p htmlFor="dailyTransLimitDr" className={classNames({ 'p-error': isFormFieldValid('dailyTransLimitDr') }, 'Label__Text')}>
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>
                                        <InputText
                                            maxLength={5}
                                            disabled={formik.values.activeDailyTransLimitDr ? true : false}
                                            id="dailyTransLimitDr"
                                            placeholder="Enter Count"
                                            name="dailyTransLimitDr"
                                            value={formik?.values?.dailyTransLimitDr !== null && formik?.values?.dailyTransLimitDr !== undefined ? String(formik.values.dailyTransLimitDr).replace(/[^0-9]/g, '') : ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('dailyTransLimitDr') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('dailyTransLimitDr')}
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="dailyAmtLimitDr" className={classNames({ 'p-error': isFormFieldValid('dailyAmtLimitDr') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber
                                            maxLength={9}
                                            inputId="dailyAmtLimitDr"
                                            placeholder=""
                                            name="dailyAmtLimitDr"
                                            value={formik?.values?.dailyAmtLimitDr || ''}
                                            onValueChange={formik.handleChange}
                                            // className={classNames({ 'p-invalid': isFormFieldValid('dailyAmtLimitDr') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('dailyAmtLimitDr')}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__">
                                        <p>Monthly</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox
                                                    inputId="activeMonthlyTransLimitDr"
                                                    name="activeMonthlyTransLimitDr"
                                                    value={formik.values.activeMonthlyTransLimitDr}
                                                    checked={formik.values.activeMonthlyTransLimitDr}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                            <p htmlFor="monthlyTransLimitDr" className={classNames({ 'p-error': isFormFieldValid('monthlyTransLimitDr') }, 'Label__Text')}>
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>
                                        <InputText
                                            maxLength={5}
                                            disabled={formik.values.activeMonthlyTransLimitDr ? true : false}
                                            id="monthlyTransLimitDr"
                                            placeholder="Enter Count"
                                            name="monthlyTransLimitDr"
                                            value={formik?.values?.monthlyTransLimitDr !== null && formik?.values?.monthlyTransLimitDr !== undefined ? String(formik.values.monthlyTransLimitDr).replace(/[^0-9]/g, '') : ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('monthlyTransLimitDr') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('monthlyTransLimitDr')}
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="monthlyAmtLimitDr" className={classNames({ 'p-error': isFormFieldValid('monthlyAmtLimitDr') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber
                                            maxLength={9}
                                            inputId="monthlyAmtLimitDr"
                                            placeholder=""
                                            name="monthlyAmtLimitDr"
                                            value={formik?.values?.monthlyAmtLimitDr || ''}
                                            onValueChange={formik.handleChange}
                                            // className={classNames({ 'p-invalid': isFormFieldValid('monthlyAmtLimitDr') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('monthlyAmtLimitDr')}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__">
                                        <p>Yearly</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox
                                                    inputId="activeYearlyTransLimitDr"
                                                    name="activeYearlyTransLimitDr"
                                                    value={formik.values.activeYearlyTransLimitDr || ''}
                                                    checked={formik.values.activeYearlyTransLimitDr}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                            <p htmlFor="yearlyTransLimitDr" className={classNames({ 'p-error': isFormFieldValid('yearlyTransLimitDr') }, 'Label__Text')}>
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>
                                        <InputText
                                            maxLength={5}
                                            disabled={formik.values.activeYearlyTransLimitDr ? true : false}
                                            id="yearlyTransLimitDr"
                                            placeholder="Enter Count"
                                            name="yearlyTransLimitDr"
                                            value={formik?.values?.yearlyTransLimitDr !== null && formik?.values?.yearlyTransLimitDr !== undefined ? String(formik.values.yearlyTransLimitDr).replace(/[^0-9]/g, '') : ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitDr') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('yearlyTransLimitDr')}
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber maxLength={9} inputId="yearlyAmtLimitDr" placeholder="" name="yearlyAmtLimitDr" value={formik?.values?.yearlyAmtLimitDr || ''} onValueChange={formik.handleChange} />
                                        {getFormErrorMessage('yearlyAmtLimitDr')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="credit_card">
                            <p className="card_heading__">Credit</p>
                            <div className="ca_rds">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__">
                                        <p>Daily</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox
                                                    inputId="activeDailyTransLimitCr"
                                                    name="activeDailyTransLimitCr"
                                                    value={formik.values.activeDailyTransLimitCr || ''}
                                                    checked={formik.values.activeDailyTransLimitCr}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                            <p htmlFor="dailyAmtLimitCr" className={classNames({ 'p-invalid': isFormFieldValid('dailyAmtLimitCr') }, 'Label__Text')}>
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>
                                        <InputText
                                            maxLength={5}
                                            disabled={formik.values.activeDailyTransLimitCr ? true : false}
                                            id="dailyTransLimitCr"
                                            placeholder="Enter Count"
                                            name="dailyTransLimitCr"
                                            value={formik?.values?.dailyTransLimitCr !== null && formik?.values?.dailyTransLimitCr !== undefined ? String(formik.values.dailyTransLimitCr).replace(/[^0-9]/g, '') : ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('dailyTransLimitCr') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('dailyTransLimitCr')}
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="name" className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber
                                            maxLength={9}
                                            inputId="dailyAmtLimitCr"
                                            placeholder=""
                                            name="dailyAmtLimitCr"
                                            value={formik?.values?.dailyAmtLimitCr || ''}
                                            onValueChange={formik.handleChange}
                                            // className={classNames({ 'p-invalid': isFormFieldValid('dailyAmtLimitCr') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('dailyAmtLimitCr')}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__">
                                        <p>Monthly</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox
                                                    inputId="activeMonthlyTransLimitCr"
                                                    name="activeMonthlyTransLimitCr"
                                                    value={formik.values.activeMonthlyTransLimitCr || ''}
                                                    checked={formik.values.activeMonthlyTransLimitCr}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                            <p htmlFor="monthlyTransLimitCr" className={classNames({ 'p-invalid': isFormFieldValid('monthlyTransLimitCr') }, 'Label__Text')}>
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>
                                        <InputText
                                            maxLength={5}
                                            disabled={formik.values.activeMonthlyTransLimitCr ? true : false}
                                            id="monthlyTransLimitCr"
                                            placeholder="Enter Count"
                                            name="monthlyTransLimitCr"
                                            value={formik?.values?.monthlyTransLimitCr !== null && formik?.values?.monthlyTransLimitCr !== undefined ? String(formik.values.monthlyTransLimitCr).replace(/[^0-9]/g, '') : ''}
                                            onChange={formik.handleChange}
                                            className={classNames(
                                                {
                                                    'p-invalid': isFormFieldValid('monthlyTransLimitCr')
                                                },
                                                'Input__Round'
                                            )}
                                        />
                                        {getFormErrorMessage('monthlyTransLimitCr')}
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="monthlyAmtLimitCr" className={classNames({ 'p-invalid': isFormFieldValid('monthlyAmtLimitCr') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber
                                            maxLength={9}
                                            inputId="monthlyAmtLimitCr"
                                            placeholder=""
                                            name="monthlyAmtLimitCr"
                                            value={formik?.values?.monthlyAmtLimitCr || ''}
                                            onValueChange={formik.handleChange}
                                            // className={classNames({ 'p-invalid': isFormFieldValid('monthlyAmtLimitCr') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('monthlyAmtLimitCr')}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__">
                                        <p>Yearly</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox
                                                    inputId="activeYearlyTransLimitCr"
                                                    name="activeYearlyTransLimitCr"
                                                    value={formik.values.activeYearlyTransLimitCr || ''}
                                                    checked={formik.values.activeYearlyTransLimitCr}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                            <p htmlFor="yearlyTransLimitCr" className={classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitCr') }, 'Label__Text')}>
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>
                                        <InputText
                                            maxLength={5}
                                            disabled={formik.values.activeYearlyTransLimitCr ? true : false}
                                            id="yearlyTransLimitCr"
                                            placeholder="Enter Count"
                                            name="yearlyTransLimitCr"
                                            value={formik?.values?.yearlyTransLimitCr !== null && formik?.values?.yearlyTransLimitCr !== undefined ? String(formik.values.yearlyTransLimitCr).replace(/[^0-9]/g, '') : ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitCr') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('yearlyTransLimitCr')}
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="yearlyAmtLimitCr" className={classNames({ 'p-invalid': isFormFieldValid('yearlyAmtLimitCr') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber
                                            maxLength={9}
                                            inputId="yearlyAmtLimitCr"
                                            placeholder=""
                                            name="yearlyAmtLimitCr"
                                            value={formik?.values?.yearlyAmtLimitCr || ''}
                                            onValueChange={formik.handleChange}
                                            // className={classNames({ 'p-invalid': isFormFieldValid('yearlyAmtLimitCr') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('yearlyAmtLimitCr')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn mt-5">
                        <Button disabled={loading} label="Update" className="Btn__Dark" />
                        <Button disabled={loading} loadingIcon={loadingIcon} onClick={() => navigate('/transactionwiselimit')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default EditTransactionwiseLimit;

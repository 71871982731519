import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';

import classNames from 'classnames';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import SecuredFileChange from '../../../components/SecuredFileChange';

function ClawBackUploadPage() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    const [ftpFileData, setFtpFileData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [dsFileError, setDsFileError] = useState('');

    const [products, setProduct] = useState([]);

    const [distrubanceOccurence, setDistrubanceOccurence] = useState([
        {
            label: 'NORMAL',
            lovId: 'N'
        },
        {
            label: 'SCHEDULED',
            lovId: 'S'
        }
    ]);

    const [useCaseError, setUseCaseError] = useState({});

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        product: Yup.mixed().required('This field is required.'),

        digitalSigningInput: Yup.mixed().when('fileSource', {
            is: 'upload',
            then: Yup.mixed().required('Please choose a file.')
        }),

        isActive: Yup.mixed().when('fileSource', {
            is: 'upload',
            then: Yup.boolean().oneOf([true], 'This field is required')
        }),
        clawBackOccurence: Yup.mixed().when('fileSource', {
            is: 'upload',
            then: Yup.mixed().required('This field is required.')
        }),
        clawbackOccurences: Yup.mixed().when('fileSource', {
            is: 'ftp',
            then: Yup.mixed().required('This field is required.')
        }),

        ftpFileName: Yup.mixed().when('fileSource', {
            is: 'ftp',
            then: Yup.mixed().required('This field is required.')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            digitalSigningInput: '',
            fileSource: 'upload',
            product: '',
            clawBackDescr: '',
            clawBackOccurence: '',
            clawBackOccurences: '',
            dateTime: '',
            uploadedBy: '',
            isActive: '',
            ftpFileName: '',
            dataTimeCsv: '',
            isActiveCsv: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const formData = new FormData();

            if (data?.fileSource === 'ftp') {
                const filePath = data?.ftpFileName;

                let onlyDate = '';
                if (data?.dateTime) {
                    onlyDate = data?.dateTime?.split('T')[0];
                } else {
                    onlyDate = new Date();
                }

                let payload = {
                    ftpFileName: filePath,
                    bulkOperationName: 'CB',
                    uploadMethod: 'ftp',
                    description: data?.clawBackDescr,
                    productId: data?.product,
                    disbursementOccurrenceId: data?.clawBackOccurences,
                    disbursementDate: onlyDate,
                    recursive: data?.isActive === true ? 'Y' : 'N'
                };
                const newData = {
                    data: {
                        security: {
                            userName: '',
                            password: '',
                            securityToken: ''
                        },
                        account: {
                            msidn: '',
                            iban: '',
                            bban: '',
                            pan: '',
                            currency: ''
                        },
                        channel: '',
                        terminal: '',
                        reterivalReferenceNumber: '',
                        payLoad: payload,
                        additionalInformation: [
                            {
                                infoKey: '',
                                infoValue: ''
                            }
                        ],
                        checkSum: ''
                    }
                };

                // Append request data if uploadMethod is not ftp
                formData.append('request', JSON.stringify(newData));
                const res = await dispatch(handlePostRequest(formData, '/batchprocessing/v1/batchprocessing/uploadbulkfile', true, false, 'disbursementFile'));
            } else {
                let onlyDate = '';
                if (data?.dataTimeCsv) {
                    onlyDate = data?.dateTimeCsv?.split('T')[0];
                } else {
                    onlyDate = new Date();
                }

                let payload = {
                    bulkOperationName: 'CB',
                    uploadMethod: 'csv',
                    description: data?.clawBackDescr,
                    productId: data?.product,
                    disbursementOccurrenceId: data?.clawBackOccurences,
                    disbursementDate: onlyDate,
                    recursive: data?.isActive === true ? 'Y' : 'N'
                };
                const newData = {
                    data: {
                        security: {
                            userName: '',
                            password: '',
                            securityToken: ''
                        },
                        account: {
                            msidn: '',
                            iban: '',
                            bban: '',
                            pan: '',
                            currency: ''
                        },
                        channel: '',
                        terminal: '',
                        reterivalReferenceNumber: '',
                        payLoad: payload,
                        additionalInformation: [
                            {
                                infoKey: '',
                                infoValue: ''
                            }
                        ],
                        checkSum: ''
                    }
                };

                formData.append('file', selectedFile);

                // Append request data if uploadMethod is not ftp
                formData.append('request', JSON.stringify(newData));
                const res = await dispatch(handlePostRequest(formData, '/batchprocessing/v1/batchprocessing/uploadbulkfile', true, false, 'disbursementFile'));
            }

            // // Append file data

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleInputChange = (e) => {
        let { name, value } = e.target;

        if (name === 'sourceAccount') {
            name = 'sourceAccount';
            formik.handleChange(e);
        } else if (name === 'accountType') {
            name = 'accountType';
            formik.handleChange(e);
        }
        // Create a new object with the updated error for the specific field
        const updatedErrors = { ...useCaseError, [name]: '' };
        setUseCaseError(updatedErrors);

        //
    };

    const downloadSample = async () => {
        if (formik.values.service) {
            handleGetRequest(`/batchprocessing/v1/batchprocessing/downloadfile?bulkOperationName=${formik?.values?.service}`).then((response) => {
                // Convert the data to CSV format using PapaParse

                const textData = response;

                const blob = new Blob([textData], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'BTR.csv');

                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            });
        }
    };

    // file upload
    const handleDigitalSigningInput = () => {
        digitalSigningRef.current.click();
    };
    const digitalSigningRef = React.useRef(null);

    const digitalSigningChange = (event) => {
        formik.setFieldValue('digitalSigningInput', '');
        formik.setFieldValue('digitalSigningInputBase64', '');
        setDsFileError('');
        const selectedFile = event.target.files[0];
        const fileTypes = ['csv'];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setDsFileError(error);
        } else if (newFile) {
            setSelectedFile(selectedFile);
            formik.setFieldValue('digitalSigningInput', selectedFile?.name);
            const reader = new FileReader();

            reader.onload = () => {
                const base64Data = reader.result;
                formik.setFieldValue('digitalSigningInputBase64', base64Data);
            };

            reader.readAsDataURL(selectedFile);
        }
    };

    const product = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setProduct(res?.payLoad);
    };

    const getFtpFile = async (value) => {
        const resp = await handleGetRequest(`/batchprocessing/v1/batchprocessing/getftpfiles/CB`);
        setFtpFileData(resp?.payLoad);
    };

    const getCurrentDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const day = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    useEffect(() => {
        product();
        getFtpFile();
    }, []);

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>Claw Back Upload Page</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="product" className={classNames({ 'p-error': isFormFieldValid('product') }, 'Label__Text')}>
                                    Product
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="product"
                                    placeholder="Select product"
                                    options={products}
                                    optionLabel="name"
                                    name="product"
                                    optionValue="lovId"
                                    value={formik.values.product || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('product') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('product')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-8 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="clawBackDescr" className={classNames({ 'p-error': isFormFieldValid('clawBackDescr') }, 'Label__Text')}>
                                    Description
                                </label>
                                <InputTextarea
                                    id="clawBackDescr"
                                    name="clawBackDescr"
                                    value={formik?.values?.clawBackDescr}
                                    rows={5}
                                    cols={10}
                                    autoResize="false"
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('clawBackDescr') }, 'TextArea__Round')}
                                />
                                {getFormErrorMessage('clawBackDescr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3 " style={{ marginTop: '-4%' }}>
                            <label htmlFor="useCaseDescr" className={classNames({ 'p-error': isFormFieldValid('useCaseDescr') }, 'Label__Text')}>
                                File Source
                            </label>
                            <div className="flex" style={{ marginLeft: '1%' }}>
                                <div className="flex mr-5">
                                    <RadioButton inputId="fileSourceUpload" name="fileSource" value="upload" onChange={formik.handleChange} checked={formik.values.fileSource === 'upload'} />
                                    <label htmlFor="fileSource" className="ml-2">
                                        CSV
                                    </label>
                                </div>
                                <div style={{ marginLeft: '5rem' }} className="flex mr-3">
                                    <RadioButton inputId="fileSourceFtp" name="fileSource" value="ftp" onChange={formik.handleChange} checked={formik.values.fileSource === 'ftp'} />
                                    <label htmlFor="fileSource" className="ml-2">
                                        FTP
                                    </label>
                                </div>
                            </div>
                            {getFormErrorMessage('isSearchable')}
                        </div>
                        {formik.values.fileSource === 'upload' && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <div className="mb-2">
                                            <label className={classNames({ 'p-error': isFormFieldValid('digitalSigningInput') }, 'Label__Text')}>
                                                Claw Back File Location-
                                                <a className="cursor-pointer ml-2" onClick={() => downloadSample()}>
                                                    Download Sample File
                                                </a>
                                                <span className="Label__Required">*</span>
                                            </label>
                                            <div className="file-input-container w-100" onClick={handleDigitalSigningInput}>
                                                <input type="file" accept=".csv" style={{ display: 'none' }} ref={digitalSigningRef} onChange={digitalSigningChange} />
                                                <input
                                                    type="text"
                                                    className={classNames({ 'p-inputtext p-component p-invalid': isFormFieldValid('digitalSigningInput') }, 'file-input-field Input__Round')}
                                                    placeholder="Choose a file..."
                                                    value={formik.values.digitalSigningInput}
                                                    readOnly
                                                />
                                                <span className="file-upload-icon">
                                                    <i className="pi pi-paperclip" />
                                                </span>
                                            </div>
                                            {dsFileError ? <small className="p-error">{dsFileError}</small> : getFormErrorMessage('digitalSigningInput')}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {formik.values.fileSource === 'upload' && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="clawBackOccurence" className={classNames({ 'p-error': isFormFieldValid('clawBackOccurence') }, 'Label__Text')}>
                                            Claw Back Occurence<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="partner"
                                            placeholder="Select Disbursement Occurence"
                                            options={distrubanceOccurence}
                                            optionLabel="label"
                                            name="clawBackOccurence"
                                            optionValue="lovId"
                                            value={formik.values.clawBackOccurence || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('clawBackOccurence') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('clawBackOccurence')}
                                    </div>
                                </div>
                                {formik.values.clawBackOccurence === 'S' && (
                                    <>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="dateTime" className={classNames({ 'p-error': isFormFieldValid('dateTimeCsv') }, 'Label__Text')}>
                                                    Date Time Picker
                                                </label>

                                                <InputText
                                                    id="dateTimeCsv"
                                                    placeholder="Enter  Start Date & Time"
                                                    name="dateTimeCsv"
                                                    type="datetime-local"
                                                    min={getCurrentDateTime()}
                                                    value={formik?.values?.dateTimeCsv || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('dateTimeCsv') }, 'Input__Round')}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="isActiveCsv" className={classNames({ 'p-error': isFormFieldValid('isActiveCsv') }, 'Label__Text')}>
                                                    Recursive
                                                </label>
                                                <div className="field-checkbox" style={{ marginLeft: '4px' }}>
                                                    <Checkbox inputId="isActiveCsv" name="isActiveCsv" value={formik.values.isActiveCsv} checked={formik.values.isActiveCsv} onChange={formik.handleChange} />
                                                </div>
                                                {formik.values.isActiveCsv === false ? getFormErrorMessage('isActiveCsv') : ''}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}

                        {formik.values.fileSource === 'ftp' && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="ftpFileName" className={classNames({ 'p-error': isFormFieldValid('ftpFileName') }, 'Label__Text')}>
                                            File<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="ftpFileName"
                                            placeholder="Select Claw Back Occurence"
                                            options={ftpFileData}
                                            optionLabel="ftpFileName"
                                            name="ftpFileName"
                                            optionValue="ftpFilePath"
                                            value={formik.values.ftpFileName || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('ftpFileName') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('ftpFileName')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="clawBackOccurences" className={classNames({ 'p-error': isFormFieldValid('clawBackOccurences') }, 'Label__Text')}>
                                            Claw Back Occurence<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="partner"
                                            placeholder="Select Claw Back Occurence"
                                            options={distrubanceOccurence}
                                            optionLabel="label"
                                            name="clawBackOccurences"
                                            optionValue="lovId"
                                            value={formik.values.clawBackOccurences || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('clawBackOccurences') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('clawBackOccurences')}
                                    </div>
                                </div>
                                {formik.values.clawBackOccurences === 'S' && (
                                    <>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="dateTime" className={classNames({ 'p-error': isFormFieldValid('dateTime') }, 'Label__Text')}>
                                                    Date Time Picker
                                                </label>

                                                <InputText
                                                    id="dateTime"
                                                    placeholder="Enter  Start Date & Time"
                                                    name="dateTime"
                                                    type="datetime-local"
                                                    min={getCurrentDateTime()}
                                                    value={formik?.values?.dateTime || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('dateTime') }, 'Input__Round')}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                                    Recursive <span className="Label__Required">*</span>
                                                </label>
                                                <div className="field-checkbox" style={{ marginLeft: '4px' }}>
                                                    <Checkbox inputId="isActive" name="isActive" value={formik.values.isActive} checked={formik.values.isActive} onChange={formik.handleChange} />
                                                </div>
                                                {formik.values.isActive === false ? getFormErrorMessage('isActive') : ''}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" loadingIcon={loadingIcon} label="Submit" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/disbursementFile')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default ClawBackUploadPage;

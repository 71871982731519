import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../../services/PostTemplate';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handleGetRequest } from '../../../services/GetTemplate';
import * as Yup from 'yup';

function AddCRPModel() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');
    const [factorRiskLovData, setFactorRiskLovData] = useState([]);

    const getRpFactorRiskData = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getcrpdata/LKP_RP_FACTOR_RISK');
        setFactorRiskLovData(response?.payLoad);
    };

    useEffect(() => {
        getRpFactorRiskData();
    }, []);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const riskCategoryCriteria = [
        {
            riskScore: '>0 & <= 5',
            rating: 'low',
            inTerms: 'Less than or Equal to 50%'
        },
        {
            riskScore: '>5 & <= 7',
            rating: 'medium',
            inTerms: 'Above 50% Less than or Equal to 70%'
        },
        {
            riskScore: '>7 & <= 10',
            rating: 'high',
            inTerms: 'Above 70%'
        }
    ];
    const validationSchema = Yup.object().shape({
        // crpModelCode: Yup.string().required('This field is required').max(50, 'field cannot exceed 50 characters').matches('^[0-9]*$', 'Please enter numeric data').nullable(),
        // crpModelName: Yup.string()
        //     .required('This field is required')
        //     .max(100, 'field cannot exceed 100 characters')
        //     .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
        //     .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
        //     .nullable(),
        // crpModelDescription: Yup.string()
        //     .required('This field is required')
        //     .max(200, 'field cannot exceed 200 characters')
        //     .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
        //     .nullable(),
        customerTypeSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        occupationSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        beneficialCustomerSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        sourceOfFundSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        purposeOfAcSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        adverseMediaSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        customerTheDirectorSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        nationalitiesSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        customerPepSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        customerType: Yup.string().required('This field is required'),
        productsAndServicesSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        productsAndServices: Yup.string().required('This field is required'),
        channelSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        channel: Yup.string().required('This field is required'),
        tradingRiskFactoryType: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        mailingAddressFactoryType: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        permanentAddressFactoryType: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        geographies: Yup.string().required('This field is required'),
        aggregateDebitAmount: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        aggregateCreditAmount: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        aggregateDebitCount: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        aggregateCreditCount: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        transactionProfile: Yup.string().required('This field is required')
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            crpModelCode: '',
            crpModelName: '',
            crpModelDescription: '',
            customerTypeSubSection: '',
            occupationSubSection: '',
            beneficialCustomerSubSection: '',
            sourceOfFundSubSection: '',
            purposeOfAcSubSection: '',
            adverseMediaSubSection: '',
            customerTheDirectorSubSection: '',
            nationalitiesSubSection: '',
            customerPepSubSection: '',
            customerType: '',
            customerTypeRiskOverRideRule: false,
            beneficialCustomerRiskOverRideRule: false,
            occupationRiskOverRideRule: false,
            sourceOfFundRiskOverRideRule: false,
            purposeOfAcRiskOverRideRule: false,
            adverseMediaRiskOverRideRule: false,
            customerTheDirectorRiskOverRideRule: false,
            nationalitiesRiskOverRideRule: false,
            customerPepRiskOverRideRule: false,
            productsAndServicesSubSection: '',
            productsAndServices: '',
            productsAndServicesRiskOverRideRule: false,
            channelSubSection: '',
            channel: '',
            channelRiskOverRideRule: false,
            tradingRiskFactoryType: '',
            mailingAddressFactoryType: '',
            permanentAddressFactoryType: '',
            geographies: '',
            tradingRiskOverRideRule: false,
            permanentAddressRiskOverRideRule: false,
            mailingAddressRiskOverRideRule: false,
            aggregateDebitAmount: '',
            aggregateCreditAmount: '',
            aggregateDebitCount: '',
            aggregateCreditCount: '',
            transactionProfile: '',
            aggregateDebitAmountRiskOverRideRule: false,
            aggregateCreditAmountRiskOverRideRule: false,
            aggregateDebitCountRiskOverRideRule: false,
            aggregateCreditCountRiskOverRideRule: false
        },

        validate: (data) => {
            let errors = {};

            if (!data.crpModelCode) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                errors.crpModelCode = 'This field is required.';
            }
            if (!/^[a-zA-Z0-9_-]+$/.test(data?.crpModelCode)) {
                errors.crpModelCode = 'Invalid Input';
            }

            if (!data?.crpModelName) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                errors.crpModelName = 'This field is required';
            } else if (data?.crpModelName === '') {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                errors.crpModelName = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.crpModelName)) {
                errors.crpModelName = 'Leading and trailing space is not allowed';
            } else if (!/^[a-zA-Z0-9\s]+$/.test(data?.crpModelName)) {
                errors.crpModelName = 'Only alphanumeric characters and spaces are allowed';
            }
            if (data.customerTypeSubSection === '0' || data.customerTypeSubSection > 100) {
                errors.customerTypeSubSection = 'Fee percentage must be between 1 and 100';
            }

            if (!data?.crpModelDescription) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                errors.crpModelDescription = 'This field is required';
            } else if (data?.crpModelDescription === '') {
                errors.crpModelDescription = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.crpModelDescription)) {
                errors.crpModelDescription = 'Leading and trailing space is not allowed';
            } else if (!/^[a-zA-Z0-9\s]+$/.test(data?.crpModelDescription)) {
                errors.crpModelDescription = 'Only alphanumeric characters and spaces are allowed';
            }

            if (data?.customerType > 100 || data?.customerType == 0) {
                errors.customerType = 'Value must be less than or equal to 100';
            }

            if (data?.productsAndServices > 100 || data?.productsAndServices == 0) {
                errors.productsAndServices = 'Value must be less than or equal to 100';
            }
            if (data?.channel > 100 || data?.channel == 0) {
                errors.channel = 'Value must be less than or equal to 100';
            }
            if (data?.geographies > 100 || data?.geographies == 0) {
                errors.geographies = 'Value must be less than or equal to 100';
            }
            if (data?.transactionProfile > 100 || data?.transactionProfile == 0) {
                errors.transactionProfile = 'Value must be less than or equal to 100';
            }
            return errors;
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        crpModelName: data['crpModelName'],
                        crpModelCode: data['crpModelCode'],
                        crpModelDescription: data['crpModelDescription'],
                        rpFactorDetailRequestList: [
                            {
                                riskFactorTypeId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'CU')
                                    ?.map((value) => value?.lovId)
                                    ?.toString(),
                                subSectionWeightage: sumOfCustomerTypeFields?.toFixed(4),
                                total: data['customerType']
                            },
                            {
                                riskFactorTypeId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'CT')
                                    ?.map((value) => value?.lovId)
                                    ?.toString(),
                                subSectionWeightage: data['customerTypeSubSection'],
                                riskOverRideRule: data['customerTypeRiskOverRideRule'],
                                parentId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'CT')
                                    ?.map((value) => value?.partnerId)
                                    ?.toString()
                            },
                            {
                                riskFactorTypeId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'OC')
                                    ?.map((value) => value?.lovId)
                                    ?.toString(),
                                subSectionWeightage: data['occupationSubSection'],
                                riskOverRideRule: data['occupationRiskOverRideRule'],
                                parentId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'OC')
                                    ?.map((value) => value?.partnerId)
                                    ?.toString()
                            },
                            {
                                riskFactorTypeId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'IB')
                                    ?.map((value) => value?.lovId)
                                    ?.toString(),
                                subSectionWeightage: data['beneficialCustomerSubSection'],
                                riskOverRideRule: data['beneficialCustomerRiskOverRideRule'],
                                parentId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'IB')
                                    ?.map((value) => value?.partnerId)
                                    ?.toString()
                            },

                            {
                                riskFactorTypeId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'SF')
                                    ?.map((value) => value?.lovId)
                                    ?.toString(),
                                subSectionWeightage: data['sourceOfFundSubSection'],
                                riskOverRideRule: data['sourceOfFundRiskOverRideRule'],
                                parentId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'SF')
                                    ?.map((value) => value?.partnerId)
                                    ?.toString()
                            },
                            {
                                riskFactorTypeId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'PA')
                                    ?.map((value) => value?.lovId)
                                    ?.toString(),
                                subSectionWeightage: data['purposeOfAcSubSection'],
                                riskOverRideRule: data['purposeOfAcRiskOverRideRule'],
                                parentId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'PA')
                                    ?.map((value) => value?.partnerId)
                                    ?.toString()
                            },
                            {
                                riskFactorTypeId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'IS')
                                    ?.map((value) => value?.lovId)
                                    ?.toString(),
                                subSectionWeightage: data['adverseMediaSubSection'],
                                riskOverRideRule: data['adverseMediaRiskOverRideRule'],
                                parentId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'IS')
                                    ?.map((value) => value?.partnerId)
                                    ?.toString()
                            },
                            {
                                riskFactorTypeId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'CCT')
                                    ?.map((value) => value?.lovId)
                                    ?.toString(),
                                subSectionWeightage: data['customerTheDirectorSubSection'],
                                riskOverRideRule: data['customerTheDirectorRiskOverRideRule'],
                                parentId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'CCT')
                                    ?.map((value) => value?.partnerId)
                                    ?.toString()
                            },
                            {
                                riskFactorTypeId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'ON')
                                    ?.map((value) => value?.lovId)
                                    ?.toString(),
                                subSectionWeightage: data['nationalitiesSubSection'],
                                riskOverRideRule: data['nationalitiesRiskOverRideRule'],
                                parentId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'ON')
                                    ?.map((value) => value?.partnerId)
                                    ?.toString()
                            },
                            {
                                riskFactorTypeId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'PEP')
                                    ?.map((value) => value?.lovId)
                                    ?.toString(),
                                subSectionWeightage: data['customerPepSubSection'],
                                riskOverRideRule: data['customerPepRiskOverRideRule'],
                                parentId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'PEP')
                                    ?.map((value) => value?.partnerId)
                                    ?.toString()
                            },
                            {
                                riskFactorTypeId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'PS')
                                    ?.map((value) => value?.lovId)
                                    ?.toString(),
                                subSectionWeightage: data['productsAndServicesSubSection'],
                                riskOverRideRule: data['productsAndServicesRiskOverRideRule'],
                                total: data['productsAndServices']
                            },
                            {
                                riskFactorTypeId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'CH')
                                    ?.map((value) => value?.lovId)
                                    ?.toString(),
                                subSectionWeightage: data['channelSubSection'],
                                riskOverRideRule: data['channelRiskOverRideRule'],
                                total: data['channel']
                            },
                            {
                                riskFactorTypeId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'GE')
                                    ?.map((value) => value?.lovId)
                                    ?.toString(),
                                subSectionWeightage: sumOfGeographies?.toFixed(4),
                                total: data['geographies']
                            },
                            {
                                riskFactorTypeId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'LC')
                                    ?.map((value) => value?.lovId)
                                    ?.toString(),
                                subSectionWeightage: data['tradingRiskFactoryType'],
                                riskOverRideRule: data['tradingRiskOverRideRule'],
                                parentId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'LC')
                                    ?.map((value) => value?.partnerId)
                                    ?.toString()
                            },
                            {
                                riskFactorTypeId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'PTA')
                                    ?.map((value) => value?.lovId)
                                    ?.toString(),
                                subSectionWeightage: data['permanentAddressFactoryType'],
                                riskOverRideRule: data['permanentAddressRiskOverRideRule'],
                                parentId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'PTA')
                                    ?.map((value) => value?.partnerId)
                                    ?.toString()
                            },
                            {
                                riskFactorTypeId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'MA')
                                    ?.map((value) => value?.lovId)
                                    ?.toString(),
                                subSectionWeightage: data['mailingAddressFactoryType'],
                                riskOverRideRule: data['mailingAddressRiskOverRideRule'],
                                parentId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'MA')
                                    ?.map((value) => value?.partnerId)
                                    ?.toString()
                            },
                            {
                                riskFactorTypeId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'ATP')
                                    ?.map((value) => value?.lovId)
                                    ?.toString(),
                                subSectionWeightage: sumOfTransactionProfile?.toFixed(4),
                                total: data['transactionProfile']
                            },
                            {
                                riskFactorTypeId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'DM')
                                    ?.map((value) => value?.lovId)
                                    ?.toString(),
                                subSectionWeightage: data['aggregateDebitAmount'],
                                riskOverRideRule: data['aggregateDebitAmountRiskOverRideRule'],
                                parentId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'DM')
                                    ?.map((value) => value?.partnerId)
                                    ?.toString()
                            },
                            {
                                riskFactorTypeId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'CM')
                                    ?.map((value) => value?.lovId)
                                    ?.toString(),
                                subSectionWeightage: data['aggregateCreditAmount'],
                                riskOverRideRule: data['aggregateCreditAmountRiskOverRideRule'],
                                parentId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'CM')
                                    ?.map((value) => value?.partnerId)
                                    ?.toString()
                            },
                            {
                                riskFactorTypeId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'DC')
                                    ?.map((value) => value?.lovId)
                                    ?.toString(),
                                subSectionWeightage: data['aggregateDebitCount'],
                                riskOverRideRule: data['aggregateDebitCountRiskOverRideRule'],
                                parentId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'DC')
                                    ?.map((value) => value?.partnerId)
                                    ?.toString()
                            },
                            {
                                riskFactorTypeId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'CC')
                                    ?.map((value) => value?.lovId)
                                    ?.toString(),
                                subSectionWeightage: data['aggregateCreditCount'],
                                riskOverRideRule: data['aggregateCreditCountRiskOverRideRule'],
                                parentId: factorRiskLovData
                                    ?.filter((value) => value?.code === 'CC')
                                    ?.map((value) => value?.partnerId)
                                    ?.toString()
                            }
                        ]
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/riskprofile/v1/crpmodel/saveupdatecrp', true, false, 'crpmodel'));
            if (res?.responseCode === '170000') {
                formik.resetForm();
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const sumOfCustomerTypeFields =
        (parseFloat(formik?.values?.customerTypeSubSection) || 0) +
        (parseFloat(formik?.values?.occupationSubSection) || 0) +
        (parseFloat(formik?.values?.beneficialCustomerSubSection) || 0) +
        (parseFloat(formik?.values?.sourceOfFundSubSection) || 0) +
        (parseFloat(formik?.values?.purposeOfAcSubSection) || 0) +
        (parseFloat(formik?.values?.adverseMediaSubSection) || 0) +
        (parseFloat(formik?.values?.customerPepSubSection) || 0) +
        (parseFloat(formik?.values?.nationalitiesSubSection) || 0) +
        (parseFloat(formik?.values?.customerTheDirectorSubSection) || 0);
    const sumOfGeographies = parseFloat(formik?.values?.tradingRiskFactoryType || 0) + parseFloat(formik?.values?.permanentAddressFactoryType || 0) + parseFloat(formik?.values?.mailingAddressFactoryType || 0);
    const sumOfTransactionProfile =
        parseFloat(formik?.values?.aggregateDebitAmount || 0) + parseFloat(formik?.values?.aggregateCreditAmount || 0) + parseFloat(formik?.values?.aggregateDebitCount || 0) + parseFloat(formik?.values?.aggregateCreditCount || 0);

    const productsAndServicesSubSection = parseFloat(formik?.values?.productsAndServicesSubSection || 0);
    const channelSubSection = parseFloat(formik?.values?.channelSubSection || 0);

    const totalOfAllFields = sumOfCustomerTypeFields + sumOfGeographies + sumOfTransactionProfile + productsAndServicesSubSection + channelSubSection;

    const sumOfSectionWeigtage =
        parseFloat(formik?.values?.customerType || 0) +
        parseFloat(formik?.values?.productsAndServices || 0) +
        parseFloat(formik?.values?.channel || 0) +
        parseFloat(formik?.values?.geographies || 0) +
        parseFloat(formik?.values?.transactionProfile || 0);

    const handleRatingBodyTemplate = (rowData) => {
        const textColor = rowData?.rating === 'low' ? 'rgb(20, 163, 139)' : rowData?.rating === 'medium' ? 'rgb(242, 172, 87)' : rowData?.rating === 'high' ? 'red' : null;
        return <p style={{ color: textColor, textTransform: 'capitalize' }}>{rowData?.rating}</p>;
    };
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>ADD NEW</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="crpModelCode" className={classNames({ 'p-error': isFormFieldValid('crpModelCode') }, 'Label__Text')}>
                                    CRP Model Code
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="crpModelCode"
                                    placeholder="Enter CRP Code"
                                    name="crpModelCode"
                                    maxLength={20}
                                    value={formik?.values?.crpModelCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('crpModelCode') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('crpModelCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="crpModelName" className={classNames({ 'p-error': isFormFieldValid('crpModelName') }, 'Label__Text')}>
                                    CRP Model Name
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="crpModelName"
                                    placeholder="Enter Name"
                                    name="crpModelName"
                                    maxLength={50}
                                    value={formik?.values?.crpModelName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('crpModelName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('crpModelName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="crpModelDescription" className={classNames({ 'p-error': isFormFieldValid('crpModelDescription') }, 'Label__Text')}>
                                    Description
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    maxLength={200}
                                    autoResize
                                    rows={5}
                                    cols={30}
                                    id="crpModelDescription"
                                    name="crpModelDescription"
                                    value={formik?.values?.crpModelDescription || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('crpModelDescription') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('crpModelDescription')}
                            </div>
                        </div>
                    </div>

                    {/* Customer Type */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h2 style={{ color: '#fff', marginLeft: '10px' }}>CUSTOMER TYPE</h2>
                    </div>
                    <div className="Crp__Model__Fields" style={{ background: '#f8f8f8' }}>
                        <div className="grid Crp__Model__Heading">
                            <div className="col-3">
                                <p className="heading___">RISK FACTOR TYPE</p>
                            </div>
                            <div className="col-3">
                                <p className="heading___">SUB SECTION WEIGHTAGE</p>
                            </div>
                            <div className="col-3">
                                <p className="heading___">SECTION WEIGHTAGE</p>
                            </div>
                            <div className="col-3">
                                <p className="heading___">RISK OVER-RIDE RULE</p>
                            </div>
                        </div>
                        <div className="grid nested-grid">
                            <div className="col-6">
                                <div className="grid">
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Customer Type`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="customerTypeSubSection"
                                                    placeholder="Enter Sub Section Weightage"
                                                    name="customerTypeSubSection"
                                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                    maxLength={6}
                                                    value={formik?.values?.customerTypeSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('customerTypeSubSection') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('customerTypeSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-3 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Occupation`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="occupationSubSection"
                                                    placeholder="Enter Sub Section Weightage"
                                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                    name="occupationSubSection"
                                                    maxLength={6}
                                                    value={formik?.values?.occupationSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('occupationSubSection') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('occupationSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Is Beneficial owner other than customer (yes / no)`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="beneficialCustomerSubSection"
                                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                    placeholder="Enter Sub Section Weightage"
                                                    name="beneficialCustomerSubSection"
                                                    maxLength={6}
                                                    value={formik?.values?.beneficialCustomerSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('beneficialCustomerSubSection') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('beneficialCustomerSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Source of funds`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="sourceOfFundSubSection"
                                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                    placeholder="Enter Sub Section Weightage"
                                                    name="sourceOfFundSubSection"
                                                    maxLength={6}
                                                    value={formik?.values?.sourceOfFundSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('sourceOfFundSubSection') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('sourceOfFundSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Purpose of A/C`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="purposeOfAcSubSection"
                                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                    placeholder="Enter Sub Section Weightage"
                                                    name="purposeOfAcSubSection"
                                                    maxLength={6}
                                                    value={formik?.values?.purposeOfAcSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('purposeOfAcSubSection') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('purposeOfAcSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Is Adverse media /sanction list match/ internal risk assessment/LEAs inquiry found? (yes or no) `}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="adverseMediaSubSection"
                                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                    placeholder="Enter Sub Section Weightage"
                                                    name="adverseMediaSubSection"
                                                    maxLength={6}
                                                    value={formik?.values?.adverseMediaSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('adverseMediaSubSection') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('adverseMediaSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">
                                                    {`Is customer  the director/ member of governing body/ trustee of NGO/ NPO/ charities/ clubs/ trusts/ societies/ Associations/ Exchange Co/ other high risk segment (yes / no)`}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="customerTheDirectorSubSection"
                                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                    placeholder="Enter Sub Section Weightage"
                                                    name="customerTheDirectorSubSection"
                                                    maxLength={6}
                                                    value={formik?.values?.customerTheDirectorSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('customerTheDirectorSubSection') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('customerTheDirectorSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Other nationalities/ residencies (yes / no)`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="nationalitiesSubSection"
                                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                    placeholder="Enter Sub Section Weightage"
                                                    name="nationalitiesSubSection"
                                                    maxLength={6}
                                                    value={formik?.values?.nationalitiesSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('nationalitiesSubSection') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('nationalitiesSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Is customer a PEP  (if PEP selected as Yes then LOV  selection is mandatory)`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="customerPepSubSection"
                                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                    placeholder="Enter Sub Section Weightage"
                                                    name="customerPepSubSection"
                                                    maxLength={6}
                                                    value={formik?.values?.customerPepSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('customerPepSubSection') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('customerPepSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                    <div className="p-field">
                                        <InputTextarea
                                            keyfilter={/^[0-9]+$/}
                                            maxLength={3}
                                            rows={32}
                                            cols={30}
                                            id="customerType"
                                            name="customerType"
                                            autoResize
                                            value={formik?.values?.customerType?.replace(/\s\s+/g, ' ') || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('customerType') }, 'TextArea__Round Crp__Model__Textarea Crp__Model__Textarea_Size')}
                                        />

                                        {getFormErrorMessage('customerType')}
                                    </div>
                                </div>
                            </div>
                            <div className="col-3 ">
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="customerTypeRiskOverRideRule"
                                                name="customerTypeRiskOverRideRule"
                                                value={formik.values.customerTypeRiskOverRideRule}
                                                checked={formik.values.customerTypeRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="customerTypeRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('customerTypeRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('customerTypeRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="occupationRiskOverRideRule"
                                                name="occupationRiskOverRideRule"
                                                value={formik.values.occupationRiskOverRideRule}
                                                checked={formik.values.occupationRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="occupationRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('occupationRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('occupationRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="beneficialCustomerRiskOverRideRule"
                                                name="beneficialCustomerRiskOverRideRule"
                                                value={formik.values.beneficialCustomerRiskOverRideRule}
                                                checked={formik.values.beneficialCustomerRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="beneficialCustomerRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('beneficialCustomerRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('beneficialCustomerRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="sourceOfFundRiskOverRideRule"
                                                name="sourceOfFundRiskOverRideRule"
                                                value={formik.values.sourceOfFundRiskOverRideRule}
                                                checked={formik.values.sourceOfFundRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="sourceOfFundRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('sourceOfFundRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('sourceOfFundRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="purposeOfAcRiskOverRideRule"
                                                name="purposeOfAcRiskOverRideRule"
                                                value={formik.values.purposeOfAcRiskOverRideRule}
                                                checked={formik.values.purposeOfAcRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="purposeOfAcRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('purposeOfAcRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('purposeOfAcRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="adverseMediaRiskOverRideRule"
                                                name="adverseMediaRiskOverRideRule"
                                                value={formik.values.adverseMediaRiskOverRideRule}
                                                checked={formik.values.adverseMediaRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="adverseMediaRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('adverseMediaRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('adverseMediaRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="customerTheDirectorRiskOverRideRule"
                                                name="customerTheDirectorRiskOverRideRule"
                                                value={formik.values.customerTheDirectorRiskOverRideRule}
                                                checked={formik.values.customerTheDirectorRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="customerTheDirectorRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('customerTheDirectorRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('customerTheDirectorRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="nationalitiesRiskOverRideRule"
                                                name="nationalitiesRiskOverRideRule"
                                                value={formik.values.nationalitiesRiskOverRideRule}
                                                checked={formik.values.nationalitiesRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="nationalitiesRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('nationalitiesRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('nationalitiesRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="customerPepRiskOverRideRule"
                                                name="customerPepRiskOverRideRule"
                                                value={formik.values.customerPepRiskOverRideRule}
                                                checked={formik.values.customerPepRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="customerPepRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('customerPepRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('customerPepRiskOverRideRule')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="col-12 flex">
                            <div className="col-3">
                                <h4>Total</h4>
                            </div>
                            <div className="col-3">
                                <h4>{isNaN(sumOfCustomerTypeFields) ? ' ' : sumOfCustomerTypeFields?.toFixed(4)}</h4>
                            </div>
                            <div className="col-6"></div>
                        </div>
                    </div>

                    {/* Products and Services */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h2 style={{ color: '#fff', marginLeft: '10px' }}>PRODUCTS AND SERVICES</h2>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5 Crp__Model__Fields" style={{ background: '#f8f8f8' }}>
                        <div className="p-field col-12 md:col-3 flex align-items-center justify-content-left">
                            <div className="p-field">
                                <p className="Label__Text">{`Products and Services`}</p>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                            <div className="p-field">
                                <InputText
                                    id="productsAndServicesSubSection"
                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                    placeholder="Enter Sub Section Weightage"
                                    name="productsAndServicesSubSection"
                                    maxLength={6}
                                    value={formik?.values?.productsAndServicesSubSection || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productsAndServicesSubSection') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('productsAndServicesSubSection')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                            <div className="p-field">
                                <InputTextarea
                                    keyfilter={/^[0-9]+$/}
                                    maxLength={3}
                                    rows={1}
                                    cols={30}
                                    id="productsAndServices"
                                    name="productsAndServices"
                                    autoResize
                                    value={formik?.values?.productsAndServices?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productsAndServices') }, 'TextArea__Round Crp__Model__Textarea')}
                                />

                                {getFormErrorMessage('productsAndServices')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                            <div className="p-field flex">
                                <div className="field-checkbox">
                                    <Checkbox
                                        inputId="productsAndServicesRiskOverRideRule"
                                        name="productsAndServicesRiskOverRideRule"
                                        value={formik.values.productsAndServicesRiskOverRideRule}
                                        checked={formik.values.productsAndServicesRiskOverRideRule}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <label htmlFor="productsAndServicesRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('productsAndServicesRiskOverRideRule') }, 'Label__Text')}>
                                    Yes
                                </label>
                                {getFormErrorMessage('productsAndServicesRiskOverRideRule')}
                            </div>
                        </div>
                    </div>

                    {/* Channels */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h2 style={{ color: '#fff', marginLeft: '10px' }}>CHANNELS</h2>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5 Crp__Model__Fields" style={{ background: '#f8f8f8' }}>
                        <div className="p-field col-12 md:col-3 flex align-items-center justify-content-left">
                            <div className="p-field">
                                <p className="Label__Text">{`Channels`}</p>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                            <div className="p-field">
                                <InputText
                                    id="channelSubSection"
                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                    placeholder="Enter Sub Section Weightage"
                                    name="channelSubSection"
                                    maxLength={6}
                                    value={formik?.values?.channelSubSection || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelSubSection') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('channelSubSection')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                            <div className="p-field">
                                <InputTextarea
                                    keyfilter={/^[0-9]+$/}
                                    maxLength={3}
                                    rows={1}
                                    cols={30}
                                    id="channel"
                                    name="channel"
                                    autoResize
                                    value={formik?.values?.channel?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channel') }, 'TextArea__Round Crp__Model__Textarea')}
                                />

                                {getFormErrorMessage('channel')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                            <div className="p-field flex">
                                <div className="field-checkbox">
                                    <Checkbox inputId="channelRiskOverRideRule" name="channelRiskOverRideRule" value={formik.values.channelRiskOverRideRule} checked={formik.values.channelRiskOverRideRule} onChange={formik.handleChange} />
                                </div>
                                <label htmlFor="channelRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('channelRiskOverRideRule') }, 'Label__Text')}>
                                    Yes
                                </label>
                                {getFormErrorMessage('channelRiskOverRideRule')}
                            </div>
                        </div>
                    </div>

                    {/* Geographies both local and international */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h2 style={{ color: '#fff', marginLeft: '10px' }}>GEOGRAPHIES: BOTH LOCAL AND INTERNATIONAL</h2>
                    </div>
                    <div className="Crp__Model__Fields" style={{ background: '#f8f8f8' }}>
                        <div className="grid Crp__Model__Heading">
                            <div className="col-3">
                                <p className="heading___">RISK FACTOR TYPE</p>
                            </div>
                            <div className="col-3">
                                <p className="heading___">SUB SECTION WEIGHTAGE</p>
                            </div>
                            <div className="col-3">
                                <p className="heading___">SECTION WEIGHTAGE</p>
                            </div>
                            <div className="col-3">
                                <p className="heading___">RISK OBER RIDE RULE</p>
                            </div>
                        </div>
                        <div className="grid nested-grid" style={{ background: '#f8f8f8', width: '100%', margin: '0 auto' }}>
                            <div className="col-6">
                                <div className="grid">
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Trading / counter parties countries: Pakistan or other country`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                    id="tradingRiskFactoryType"
                                                    placeholder="Enter Sub Section Weightage"
                                                    name="tradingRiskFactoryType"
                                                    maxLength={6}
                                                    value={formik?.values?.tradingRiskFactoryType || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('tradingRiskFactoryType') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('tradingRiskFactoryType')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Permanent Address`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="permanentAddressFactoryType"
                                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                    placeholder="Enter Sub Section Weightage"
                                                    name="permanentAddressFactoryType"
                                                    maxLength={6}
                                                    value={formik?.values?.permanentAddressFactoryType || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('permanentAddressFactoryType') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('permanentAddressFactoryType')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Mailing Address/ Geo-Location`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="mailingAddressFactoryType"
                                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                    placeholder="Enter Sub Section Weightage"
                                                    name="mailingAddressFactoryType"
                                                    maxLength={6}
                                                    value={formik?.values?.mailingAddressFactoryType || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('mailingAddressFactoryType') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('mailingAddressFactoryType')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                    <div className="p-field">
                                        <InputTextarea
                                            keyfilter={/^[0-9]+$/}
                                            maxLength={3}
                                            rows={8}
                                            cols={30}
                                            id="geographies"
                                            name="geographies"
                                            autoResize
                                            value={formik?.values?.geographies?.replace(/\s\s+/g, ' ') || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('geographies') }, 'TextArea__Round Crp__Model__Textarea Crp__Model__Geographies__TextArea')}
                                        />

                                        {getFormErrorMessage('geographies')}
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox inputId="tradingRiskOverRideRule" name="tradingRiskOverRideRule" value={formik.values.tradingRiskOverRideRule} checked={formik.values.tradingRiskOverRideRule} onChange={formik.handleChange} />
                                        </div>
                                        <label htmlFor="tradingRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('tradingRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('tradingRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="permanentAddressRiskOverRideRule"
                                                name="permanentAddressRiskOverRideRule"
                                                value={formik.values.permanentAddressRiskOverRideRule}
                                                checked={formik.values.permanentAddressRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="permanentAddressRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('permanentAddressRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('permanentAddressRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="mailingAddressRiskOverRideRule"
                                                name="mailingAddressRiskOverRideRule"
                                                value={formik.values.mailingAddressRiskOverRideRule}
                                                checked={formik.values.mailingAddressRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="mailingAddressRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('mailingAddressRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('mailingAddressRiskOverRideRule')}{' '}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="col-12 flex">
                            <div className="col-3">
                                <h4>Total</h4>
                            </div>
                            <div className="col-3">
                                <h4>{isNaN(sumOfGeographies) ? ' ' : sumOfGeographies?.toFixed(4)}</h4>
                            </div>
                            <div className="col-6"></div>
                        </div>
                    </div>

                    {/* Anticipated Transactions Profile */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h2 style={{ color: '#fff', marginLeft: '10px' }}>ANTICIPATED TRANSACTIONS PROFILE</h2>
                    </div>
                    <div className="Crp__Model__Fields" style={{ background: '#f8f8f8' }}>
                        <div className="grid Crp__Model__Heading">
                            <div className="col-3">
                                <p className="heading___">RISK FACTOR TYPE</p>
                            </div>
                            <div className="col-3">
                                <p className="heading___">SUB SECTION WEIGHTAGE</p>
                            </div>
                            <div className="col-3">
                                <p className="heading___">SECTION WEIGHTAGE</p>
                            </div>
                            <div className="col-3">
                                <p className="heading___">RISK OBER RIDE RULE</p>
                            </div>
                        </div>
                        <div className="grid nested-grid" style={{ background: '#f8f8f8', width: '100%', margin: '0 auto' }}>
                            <div className="col-6">
                                <div className="grid">
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Expected aggregate Debit amount per Month`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="aggregateDebitAmount"
                                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                    placeholder="Enter Sub Section Weightage"
                                                    name="aggregateDebitAmount"
                                                    maxLength={6}
                                                    value={formik?.values?.aggregateDebitAmount || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('aggregateDebitAmount') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('aggregateDebitAmount')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Expected aggregate Credit amount per Month`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="aggregateCreditAmount"
                                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                    placeholder="Enter Sub Section Weightage"
                                                    name="aggregateCreditAmount"
                                                    maxLength={6}
                                                    value={formik?.values?.aggregateCreditAmount || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('aggregateCreditAmount') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('aggregateCreditAmount')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Expected Debit Count per month`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="aggregateDebitCount"
                                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                    placeholder="Enter Sub Section Weightage"
                                                    name="aggregateDebitCount"
                                                    maxLength={6}
                                                    value={formik?.values?.aggregateDebitCount || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('aggregateDebitCount') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('aggregateDebitCount')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Expected Credit Count per Month`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="aggregateCreditCount"
                                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                    placeholder="Enter Sub Section Weightage"
                                                    name="aggregateCreditCount"
                                                    maxLength={6}
                                                    value={formik?.values?.aggregateCreditCount || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('aggregateCreditCount') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('aggregateCreditCount')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                    <div className="p-field">
                                        <InputTextarea
                                            keyfilter={/^[0-9]+$/}
                                            maxLength={3}
                                            rows={12}
                                            cols={30}
                                            id="transactionProfile"
                                            name="transactionProfile"
                                            autoResize
                                            value={formik?.values?.transactionProfile?.replace(/\s\s+/g, ' ') || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('transactionProfile') }, 'TextArea__Round Crp__Model__Textarea Crp__Model__Transactions__TextArea')}
                                        />

                                        {getFormErrorMessage('transactionProfile')}
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="aggregateDebitAmountRiskOverRideRule"
                                                name="aggregateDebitAmountRiskOverRideRule"
                                                value={formik.values.aggregateDebitAmountRiskOverRideRule}
                                                checked={formik.values.aggregateDebitAmountRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="aggregateDebitAmountRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('aggregateDebitAmountRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('aggregateDebitAmountRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="aggregateCreditAmountRiskOverRideRule"
                                                name="aggregateCreditAmountRiskOverRideRule"
                                                value={formik.values.aggregateCreditAmountRiskOverRideRule}
                                                checked={formik.values.aggregateCreditAmountRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="aggregateCreditAmountRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('aggregateCreditAmountRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('aggregateCreditAmountRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="aggregateDebitCountRiskOverRideRule"
                                                name="aggregateDebitCountRiskOverRideRule"
                                                value={formik.values.aggregateDebitCountRiskOverRideRule}
                                                checked={formik.values.aggregateDebitCountRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="aggregateDebitCountRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('aggregateDebitCountRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('aggregateDebitCountRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="aggregateCreditCountRiskOverRideRule"
                                                name="aggregateCreditCountRiskOverRideRule"
                                                value={formik.values.aggregateCreditCountRiskOverRideRule}
                                                checked={formik.values.aggregateCreditCountRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="aggregateCreditCountRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('aggregateCreditCountRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('aggregateCreditCountRiskOverRideRule')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="col-12 flex">
                            <div className="col-3">
                                <h4>Total</h4>
                            </div>
                            <div className="col-3">
                                <h4>{isNaN(sumOfTransactionProfile) ? ' ' : sumOfTransactionProfile?.toFixed(4)}</h4>
                            </div>
                            <div className="col-6"></div>
                        </div>
                    </div>

                    {/* TOTAL RISK SCORE */}

                    <div className="Form__Header" style={{ background: '#979c9b', borderRadius: '5px', paddingTop: '12px' }}>
                        <div className="p-fluid p-formgrid grid w-full">
                            <div className="p-field col-12 md:col-3 flex align-items-center">
                                <h2 style={{ color: '#fff', margin: '0px 0px 0px 10px' }}>TOTAL RISK SCORE</h2>
                            </div>
                            <div className="p-field col-12 md:col-3 flex align-items-center">
                                <p style={{ color: 'white' }}>{totalOfAllFields || 0}</p>
                            </div>
                            <div className="p-field col-12 md:col-3 flex align-items-center">
                                <p style={{ color: 'white' }}>{sumOfSectionWeigtage + '%'}</p>
                            </div>
                            <div className="p-field col-12 md:col-3 flex align-items-center"></div>
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-3 flex align-items-center">
                        <h5 style={{ color: '#979c9b', margin: '0px 0px 0px 10px' }}>Risk Category Criteria</h5>
                    </div>

                    <div className="grid">
                        <div className="col-12">
                            <div className="card" style={{ background: '#f8f8f8' }}>
                                <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={riskCategoryCriteria}>
                                    <Column field="riskScore" header="Risk Score" style={{ width: '33%' }} />
                                    <Column header="Rating" body={(rowData) => handleRatingBodyTemplate(rowData)} style={{ width: '33%' }} />
                                    <Column field="inTerms" header="InTerms of % of Risk Scores" style={{ width: '33%' }} />
                                </DataTable>
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button disabled={loading} label="Submit" className="Btn__Dark" />
                        <Button disabled={loading} loadingIcon={loadingIcon || ''} onClick={() => navigate('/crpmodel')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default AddCRPModel;

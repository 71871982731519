import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const InputWithCommentAction = ({ inputType, inputName, label, inputValue, commentName, commentValue, actionName, actionValue, formik, isFormFieldValid, getFormErrorMessage, handleKeyPress, innerActionList, type, classNames, setActionStatus }) => {
    const [visible, setVisible] = useState(false);

    return (
        <div className="p-fluid p-formgrid grid mt-4">
            <div className={`p-field col-12 md:col-4 pt-3 pb-3 ${inputType === 'checkbox' ? 'flex align-items-center' : ''}`}>
                <div className="p-field">
                    {inputType === 'checkbox' && (
                        <Checkbox inputId={inputName} name={inputName} value={inputValue} disabled={true} className={classNames({ 'p-invalid': isFormFieldValid(inputName) }, '')} checked={inputValue === 'Yes'} onKeyDown={(e) => handleKeyPress(e)} />
                    )}
                    <label htmlFor={inputName} className={classNames({ 'p-error': isFormFieldValid(inputName) }, 'Label__Text')}>
                        {label}
                    </label>
                    {inputType === 'image' ? (
                        <div className="card accountApprovalImage">
                            {inputValue && inputValue !== 'N/A' ? (
                                <Image alt={inputName} src={inputValue} preview width="500" height="200" loading="lazy" className="w-100" />
                            ) : (
                                <Image alt={inputName} src={'https://placehold.co/600x400?text=No+Image+Available'} width="500" height="200" loading="lazy" className="w-100" />
                            )}
                        </div>
                    ) : inputType === 'button' ? (
                        <React.Fragment>
                            {inputValue && inputValue !== 'N/A' ? (
                                <div className="Down__Btn justify-content-start">
                                    <Button className="Btn__Dark w-auto px-4" type="button" label={'Open'} icon="pi pi-image" iconPos="right" onClick={() => window.open(inputValue, '_blank')} />
                                </div>
                            ) : (
                                <div className="card accountApprovalImage">
                                    <Image alt={inputName} src={'https://placehold.co/600x400?text=No+Image+Available'} width="500" height="200" loading="lazy" className="w-100" />
                                </div>
                            )}
                        </React.Fragment>
                    ) : inputType === 'radio' ? (
                        <div className="flex flex-wrap gap-3 mt-2">
                            <div className="flex">
                                <RadioButton
                                    inputId={'No'}
                                    name={inputName}
                                    value={inputValue}
                                    disabled={true}
                                    className={classNames({ 'p-invalid': isFormFieldValid(inputName) }, '')}
                                    checked={inputValue === 'No'}
                                    onKeyDown={(e) => handleKeyPress(e)}
                                />
                                <label htmlFor={'No'} className="ml-2">
                                    No
                                </label>
                            </div>
                            <div className="flex ml-5">
                                <RadioButton
                                    inputId={'Yes'}
                                    name={inputName}
                                    value={inputValue}
                                    disabled={true}
                                    className={classNames({ 'p-invalid': isFormFieldValid(inputName) }, '')}
                                    checked={inputValue === 'Yes'}
                                    onKeyDown={(e) => handleKeyPress(e)}
                                />
                                <label htmlFor={'Yes'} className="ml-2">
                                    Yes
                                </label>
                            </div>
                        </div>
                    ) : inputType === 'video' ? (
                        <React.Fragment>
                            {inputValue ? (
                                <div className="flex justify-content-start">
                                    <div className="Top__Btn w-auto">
                                        <div>
                                            <Button label="Show" type="button" onClick={() => setVisible(true)} className="Btn__Add__ m-0 px-5 w-auto" icon="pi pi-video" iconPos="right" />
                                        </div>
                                    </div>
                                    <Dialog header={label} visible={visible} style={{ width: '35w' }} onHide={() => setVisible(false)}>
                                        {inputValue && inputValue ? (
                                            <video width="500" height="100%" controls>
                                                <source id={inputName} name={inputName} src={inputValue} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        ) : (
                                            <div>
                                                <h5 className="pt-3 text-center">No Preview Available!</h5>
                                            </div>
                                        )}
                                    </Dialog>
                                </div>
                            ) : (
                                <div className="card accountApprovalImage">
                                    <Image alt={inputName} src={'https://placehold.co/600x400?text=No+Video+Available'} width="500" height="200" loading="lazy" className="w-100" />
                                </div>
                            )}
                        </React.Fragment>
                    ) : inputType === 'checkbox' ? (
                        <></>
                    ) : (
                        <InputText id={inputName} name={inputName} value={inputValue || ''} disabled={true} className={classNames({ 'p-invalid': isFormFieldValid(inputName) }, 'Input__Round')} onKeyDown={(e) => handleKeyPress(e)} />
                    )}
                </div>
            </div>
            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                <div className="p-field">
                    <label htmlFor={commentName} className={classNames({ 'p-error': isFormFieldValid(commentName) }, 'Label__Text')}>
                        Comment
                        <span className="Label__Required">*</span>
                    </label>
                    <InputText
                        id={commentName}
                        name={commentName}
                        placeholder="Enter Comment"
                        value={commentValue || ''}
                        onChange={formik.handleChange}
                        disabled={type !== 'edit'}
                        className={classNames({ 'p-invalid': isFormFieldValid(commentName) }, 'Input__Round')}
                        onKeyDown={(e) => handleKeyPress(e)}
                    />
                    {getFormErrorMessage(commentName)}
                </div>
            </div>
            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                <div className="p-field">
                    <label htmlFor={actionName} className={classNames({ 'p-error': isFormFieldValid(actionName) }, 'Label__Text')}>
                        Action
                        <span className="Label__Required">*</span>
                    </label>
                    <Dropdown
                        id={actionName}
                        placeholder="Select Action"
                        options={innerActionList || []}
                        optionLabel="name"
                        name={actionName}
                        optionValue="lovId"
                        value={actionValue || ''}
                        onChange={(e) => {
                            formik.handleChange(e);
                            setActionStatus((prevValues) => ({
                                ...prevValues,
                                [actionName]: e.target.value
                            }));
                        }}
                        disabled={type !== 'edit'}
                        className={classNames({ 'p-invalid': isFormFieldValid(actionName) }, 'Dropdown__Round')}
                    />

                    {getFormErrorMessage(actionName)}
                </div>
            </div>
        </div>
    );
};

export default InputWithCommentAction;

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';

function ViewTransProfProductChannel({ activeIndex, handleStepClick, riskProfileData, setStepData3, crpModelIdData }) {

    const [productLov, setProductLov] = useState();
    const [channelOption, setChannelOption] = useState();

    const getProductsLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setProductLov(resp?.payLoad);
    };
    useEffect(() => {
        getProductsLov();
    }, []);

    useEffect(() => {
        if (riskProfileData) {
            const CTDropdownData = riskProfileData?.tblRpCrpDetails;

            if (CTDropdownData) {
                const CHHighId = [];
                const CHMediumId = [];
                const CHLowId = [];

                const PSHighId = [];
                const PSMediumId = [];
                const PSLowId = [];

                CTDropdownData.forEach((value) => {
                    if (value?.isActive === 'Y') {
                        const referenceIdToString = value?.refId?.toString() || value?.refId;

                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'PS') {
                            if (value?.rpRating === 'H') {
                                CHHighId.push(referenceIdToString);

                                formik.setFieldValue('productServicesHighRisk', CHHighId);
                                formik.setFieldValue('productServicesOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                CHMediumId.push(referenceIdToString);
                                formik.setFieldValue('productServicesMediumRisk', CHMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                CHLowId.push(referenceIdToString);
                                formik.setFieldValue('productServicesLowRisk', CHLowId);
                            }
                        }
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'CH') {
                            if (value?.rpRating === 'H') {
                                PSHighId.push(referenceIdToString);
                                formik.setFieldValue('channelHighRisk', PSHighId);
                                formik.setFieldValue('channelOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                PSMediumId.push(referenceIdToString);
                                formik.setFieldValue('channelMediumRisk', PSMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                PSLowId.push(referenceIdToString);
                                formik.setFieldValue('channelLowRisk', PSLowId);
                            }
                        }
                    }
                });
            }
        }
    }, [riskProfileData]); //eslint-disable-line


    const formik = useFormik({
        initialValues: {
            stepNo: 3,
            productServicesHighRisk: [],
            productServicesMediumRisk: [],
            productServicesLowRisk: [],
            productServicesOverRideRule: 'false',
            channelHighRisk: [],
            channelMediumRisk: [],
            channelLowRisk: [],
            channelOverRideRule: 'false',
            reqMap: []
        },
        validate: (data) => {
            let errors = {};
            if (data?.productServicesHighRisk.length === 0) {
                errors.productServicesHighRisk = 'This field is required';
            }
            if (data?.productServicesMediumRisk.length === 0) {
                errors.productServicesMediumRisk = 'This field is required';
            }
            if (data?.productServicesLowRisk.length === 0) {
                errors.productServicesLowRisk = 'This field is required';
            }
            if (data?.channelHighRisk.length === 0) {
                errors.channelHighRisk = 'This field is required';
            }
            if (data?.channelMediumRisk.length === 0) {
                errors.channelMediumRisk = 'This field is required';
            }
            if (data?.channelLowRisk.length === 0) {
                errors.channelLowRisk = 'This field is required';
            }
            return errors;
        },
        onSubmit: async (data) => {

            data['crpId'] = riskProfileData?.rpCrpId;
            data['rpCrpModelId'] = crpModelIdData;

            data['reqMap'] = [
                {
                    CH: {
                        highRisk: data?.productServicesHighRisk,
                        medRisk: data?.productServicesMediumRisk,
                        lowRisk: data?.productServicesLowRisk,
                        overRideRule: data?.productServicesOverRideRule
                    }
                },
                {
                    PS: {
                        highRisk: data?.channelHighRisk,
                        medRisk: data?.channelMediumRisk,
                        lowRisk: data?.channelLowRisk,
                        overRideRule: data?.channelOverRideRule
                    }
                }
            ];

            delete data['productServicesHighRisk'];
            delete data['productServicesMediumRisk'];
            delete data['productServicesLowRisk'];
            delete data['productServicesOverRideRule'];
            delete data['channelHighRisk'];
            delete data['channelMediumRisk'];
            delete data['channelLowRisk'];
            delete data['channelOverRideRule'];

            handleStepClick(activeIndex + 1);
            setStepData3(data);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    useEffect(() => {
        const getChannel = async () => {
            const res = await handleGetRequest('/configuration/v1/lookups/getchannels/LKP_CHANNEL:*');
            setChannelOption(res?.payLoad);
        };
        getChannel()
    }, []);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {/* PRODUCTS / SERVICES */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>PRODUCTS / SERVICES</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="productServicesHighRisk" className={classNames({ 'p-error': isFormFieldValid('productServicesHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="productServicesHighRisk"
                                    placeholder="Select High Risk"
                                    options={productLov}
                                    readOnly
                                    optionLabel="name"
                                    name="productServicesHighRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.productServicesHighRisk || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productServicesHighRisk') }, 'Dropdown__Round')}
                                />
                                {/* {getFormErrorMessage('productServicesHighRisk')} */}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="productServicesMediumRisk" className={classNames({ 'p-error': isFormFieldValid('productServicesMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="productServicesMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={productLov}
                                    optionLabel="name"
                                    readOnly
                                    name="productServicesMediumRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.productServicesMediumRisk || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productServicesMediumRisk') }, 'Dropdown__Round')}
                                />
                                {/* {getFormErrorMessage('productServicesMediumRisk')} */}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="productServicesLowRisk" className={classNames({ 'p-error': isFormFieldValid('productServicesLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="productServicesLowRisk"
                                    placeholder="Select Low Risk"
                                    options={productLov}
                                    optionLabel="name"
                                    readOnly
                                    name="productServicesLowRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.productServicesLowRisk || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productServicesLowRisk') }, 'Dropdown__Round')}
                                />
                                {/* {getFormErrorMessage('productServicesLowRisk')} */}
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="productServicesOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton disabled={true} inputId="productServicesOverRideRule" name="productServicesOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.productServicesOverRideRule === 'false'} />
                                    <label htmlFor="productServicesOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton disabled={true} inputId="productServicesOverRideRule" name="productServicesOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.productServicesOverRideRule === 'true'} />
                                    <label htmlFor="productServicesOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* CHANNEL */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>CHANNEL</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="channelHighRisk" className={classNames({ 'p-error': isFormFieldValid('channelHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="channelHighRisk"
                                    placeholder="Select High Risk"
                                    readOnly
                                    optionLabel="name"
                                    options={channelOption}
                                    name="channelHighRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.channelHighRisk || ''}
                                    //onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelHighRisk') }, 'Dropdown__Round')}
                                />
                                {/* {getFormErrorMessage('channelHighRisk')} */}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="channelMediumRisk" className={classNames({ 'p-error': isFormFieldValid('channelMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="channelMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={channelOption}
                                    optionLabel="name"
                                    readOnly
                                    name="channelMediumRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.channelMediumRisk || ''}
                                    //onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelMediumRisk') }, 'Dropdown__Round')}
                                />
                                {/* {getFormErrorMessage('channelMediumRisk')} */}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="channelLowRisk" className={classNames({ 'p-error': isFormFieldValid('channelLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="channelLowRisk"
                                    placeholder="Select Low Risk"
                                    options={channelOption}
                                    optionLabel="name"
                                    readOnly
                                    name="channelLowRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.channelLowRisk || ''}
                                    //onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelLowRisk') }, 'Dropdown__Round')}
                                />
                                {/* {getFormErrorMessage('channelLowRisk')} */}
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="channelOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton disabled={true} inputId="channelOverRideRule" name="channelOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.channelOverRideRule === 'false'} />
                                    <label htmlFor="channelOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton disabled={true} inputId="channelOverRideRule" name="channelOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.channelOverRideRule === 'true'} />
                                    <label htmlFor="channelOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="Down__Btn">
                        <Button disabled={loading} label="Next" className="Btn__Dark" />
                    </div> */}
                </form>
            </div>
        </>
    );
}
export default ViewTransProfProductChannel;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../../../services/GetTemplate';

function EditBulletLoan({ activeIndex, handleStepClick, lendingData, lendingIdData, setStepData3, stepData1 }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [frequencyPeriodData, setFrequencyPeriodData] = useState([]);

    /////////////////////////////////////////
    const [bulletLoanTableData, setBulletLoanTableData] = useState([]);
    const [bulletLoanData, setBulletLoanData] = useState({
        noOfDays: '',
        markupValue: '',
        apr: '',

        gracePeriod: '',
        noOfDaysGracePeriod: '',

        latePayment: '',
        latePaymentType: '',
        latePaymentCharges: '',
        latePaymentOn: '',

        frequencyId: '',

        earlyPayment: '',
        earlyPaymentType: '',
        earlyPaymentCharges: '',
        earlyPaymentOn: '',

        downPayment: '',
        downPaymentCharges: ''
    });
    const [bulletLoanError, setBulletLoanError] = useState({
        noOfDays: '',
        markupValue: '',
        apr: '',

        gracePeriod: '',
        noOfDaysGracePeriod: '',

        latePayment: '',
        latePaymentType: '',
        latePaymentCharges: '',
        latePaymentOn: '',

        frequencyId: '',

        earlyPayment: '',
        earlyPaymentType: '',
        earlyPaymentCharges: '',
        earlyPaymentOn: '',

        downPayment: '',
        downPaymentCharges: ''
    });
    //////////////////////////////

    const [buttons, setButtons] = useState([
        { label: 'Principal Amount', sequence: 1 },
        { label: 'Markup', sequence: 2 },
        { label: 'Fees', sequence: 3 }
    ]);

    const getFrequencyPeriodData = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getdatalov/LKP_FREQUENCY_PERIOD');
        setFrequencyPeriodData(response?.payLoad);
    };

    useEffect(() => {
        getFrequencyPeriodData();
    }, []); // eslint-disable-line

    useEffect(() => {
        let bulletLoan = [];
        if (lendingData) {
            if (lendingData?.tblLmsRepaymentMethods?.length > 0) {
                bulletLoan = lendingData?.tblLmsRepaymentMethods
                    ?.filter((itm) => itm?.isActive === 'Y')
                    ?.map((item) => {
                        return {
                            noOfDays: item?.noOfDays?.toString(),
                            markupValue: item?.markupValue,
                            apr: item?.apr,

                            gracePeriod: item?.gracePeriod === 'Y' ? true : false,
                            noOfDaysGracePeriod: item?.noOfGraceDays,

                            latePayment: item?.latePayment === 'Y' ? true : false,
                            latePaymentType: item?.latePaymentType?.toString(),
                            latePaymentCharges: item?.latePaymentCharges,
                            latePaymentOn: item?.latePaymentOn?.toString(),

                            earlyPayment: item?.earlyPayment === 'Y' ? true : false,
                            earlyPaymentType: item?.earlyPaymentType?.toString(),
                            earlyPaymentCharges: item?.earlyPaymentCharges,
                            earlyPaymentOn: item?.earlyPaymentOn?.toString(),

                            frequencyId: item?.lkpFrequencyPeriod?.frequencyPeriodId?.toString(),

                            downPayment: item?.downPayment === 'Y' ? 'Y' : 'N',
                            downPaymentCharges: item?.downPaymentCharges
                            // seqFees: item?.seqLateFee
                        };
                    });
                if (lendingData?.lkpLmsFacilityType?.lmsFacilityTypeCode === 'BL') {
                    setBulletLoanData(bulletLoan[0]);
                } else {
                    setBulletLoanData([]);
                }
            }

            let pA = [];
            if (lendingData?.tblLmsRepaymentMethods?.length > 0) {
                Object.keys(lendingData?.tblLmsRepaymentMethods[0]).forEach((key) => {
                    if (key === 'seqPrincipalAmount') {
                        pA.push({ label: key, value: lendingData?.tblLmsRepaymentMethods[0][key] });
                    }

                    if (key === 'seqMarkup') {
                        pA.push({ label: key, value: lendingData?.tblLmsRepaymentMethods[0][key] });
                    }

                    if (key === 'seqLateFee') {
                        pA.push({ label: 'seqFees', value: lendingData?.tblLmsRepaymentMethods[0][key] });
                    }
                });

                const updatedDefinePayment = pA?.map((item) => {
                    return {
                        label: item.label.replace('seq', ''),
                        sequence: item.value
                    };
                });

                updatedDefinePayment?.sort((a, b) => a.sequence - b.sequence);
                setButtons(updatedDefinePayment);
            }
        }
    }, [lendingData]); // eslint-disable-line

    const validationSchema = Yup.object().shape({});

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {},

        validate: (data) => {},

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            // if (lendingData?. > 3) {
            //     data['edit'] = true;
            // }

            if (!bulletLoanTableData || bulletLoanTableData?.length === 0) {
                setloading(false);
                setloadingIcon('pi pi-search');
                return;
            }

            const bulletData = {
                lmsProductId: lendingIdData,
                currentStep: 3,
                lmsProductRepaymentRequests: bulletLoanTableData
            };

            setStepData3(bulletData);
            handleStepClick(activeIndex + 1);

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const handleDragStart = (e, label) => {
        e.dataTransfer.setData('text/plain', label);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e, targetLabel) => {
        e.preventDefault();

        const draggedLabel = e.dataTransfer.getData('text/plain');
        const updatedButtons = [...buttons];

        const draggedButton = updatedButtons.find((button) => button.label === draggedLabel);
        const targetButton = updatedButtons.find((button) => button.label === targetLabel);

        if (draggedButton && targetButton) {
            // Swap the sequence numbers
            const tempSequence = draggedButton.sequence;
            draggedButton.sequence = targetButton.sequence;
            targetButton.sequence = tempSequence;

            // Sort buttons based on sequence
            updatedButtons.sort((a, b) => a.sequence - b.sequence);

            setButtons(updatedButtons);
        }
    };

    const downPaymentLov = [
        {
            name: 'Yes',
            lovId: 'Y'
        },
        {
            name: 'No',
            lovId: 'N'
        }
    ];

    //////////////////////////new UI code /////////////////////
    const loanTenureNOD = [
        { name: '90 Days', lovId: '90' },
        { name: '180 Days', lovId: '180' },
        { name: '270 Days', lovId: '270' },
        { name: '360 Days', lovId: '360' }
    ];

    const typeLov = [
        { name: 'FIXED', lovId: 'F' },
        { name: 'PERCENTAGE', lovId: 'P' }
    ];

    const paymentOnLov = [
        { name: 'Outstanding Principal', lovId: 'O' },
        { name: 'Markup', lovId: 'M' }
    ];

    const handledAdd = (e) => {
        // e.preventDefault();

        const errors = {
            noOfDays: '',
            markupValue: '',
            apr: '',

            gracePeriod: '',
            noOfDaysGracePeriod: '',

            latePayment: '',
            latePaymentType: '',
            latePaymentCharges: '',
            latePaymentOn: '',

            frequencyId: '',

            earlyPayment: '',
            earlyPaymentType: '',
            earlyPaymentCharges: '',
            earlyPaymentOn: '',

            downPayment: '',
            downPaymentCharges: '',

            seqPrincipalAmount: '',
            seqMarkup: '',
            seqFees: ''
        };

        if (bulletLoanData?.noOfDays === '' || bulletLoanData?.noOfDays === undefined || bulletLoanData?.noOfDays === null) {
            errors.noOfDays = 'This field is required.';
        }

        if (bulletLoanData?.markupValue === '' || bulletLoanData?.markupValue === undefined || bulletLoanData?.markupValue === null) {
            errors.markupValue = 'This field is required.';
        } else if (parseFloat(bulletLoanData?.markupValue) == 0 || parseFloat(bulletLoanData?.markupValue) > 100) {
            errors.markupValue = 'Value must be greater than 0 or equal to or less than 100';
        }

        if (bulletLoanData?.apr === '' || bulletLoanData?.apr === undefined || bulletLoanData?.apr === null) {
            errors.apr = 'This field is required.';
        } else if (parseFloat(bulletLoanData?.apr) == 0 || parseFloat(bulletLoanData?.apr) > 100) {
            errors.apr = 'Value must be greater than 0 or equal to or less than 100';
        }

        if (bulletLoanData?.gracePeriod === true) {
            if (bulletLoanData?.noOfDaysGracePeriod === '') {
                errors.noOfDaysGracePeriod = 'This field is required';
            } else if (parseInt(bulletLoanData?.noOfDaysGracePeriod) < 1) {
                errors.noOfDaysGracePeriod = 'Please enter a valid value greater than 0';
            }
        }

        if (bulletLoanData?.latePayment === true) {
            if (bulletLoanData?.latePaymentType === '') {
                errors.latePaymentType = 'This field is required';
            }

            if (bulletLoanData?.latePaymentType === 'F') {
                if (bulletLoanData?.latePaymentCharges === '') {
                    errors.latePaymentCharges = 'This field is required';
                } else if (parseInt(bulletLoanData?.latePaymentCharges) < 1) {
                    errors.latePaymentCharges = 'Please enter a valid value greater than 0';
                }
            }
            if (bulletLoanData?.latePaymentType === 'P') {
                if (bulletLoanData?.latePaymentCharges === '') {
                    errors.latePaymentCharges = 'This field is required';
                } else if (bulletLoanData?.latePaymentCharges < 1 || bulletLoanData?.latePaymentCharges > 100) {
                    errors.latePaymentCharges = 'Please enter a valid percentage between 1 and 100';
                }
            }

            if (bulletLoanData?.latePaymentOn === '') {
                errors.latePaymentOn = 'This field is required';
            }
        }

        if (bulletLoanData?.frequencyId === '' || bulletLoanData?.frequencyId === undefined || bulletLoanData?.frequencyId === null) {
            errors.frequencyId = 'This field is required';
        }

        if (bulletLoanData?.earlyPayment === true) {
            if (bulletLoanData?.earlyPaymentType === '') {
                errors.earlyPaymentType = 'This field is required';
            }

            if (bulletLoanData?.earlyPaymentType === 'F') {
                if (bulletLoanData?.earlyPaymentCharges === '') {
                    errors.earlyPaymentCharges = 'This field is required';
                } else if (parseInt(bulletLoanData?.earlyPaymentCharges) < 1) {
                    errors.earlyPaymentCharges = 'Please enter a valid value greater than 0';
                }
            }
            if (bulletLoanData?.earlyPaymentType === 'P') {
                if (bulletLoanData?.earlyPaymentCharges === '') {
                    errors.earlyPaymentCharges = 'This field is required';
                } else if (bulletLoanData?.earlyPaymentCharges < 1 || bulletLoanData?.earlyPaymentCharges > 100) {
                    errors.earlyPaymentCharges = 'Please enter a valid percentage between 1 and 100';
                }
            }

            if (bulletLoanData?.earlyPaymentOn === '') {
                errors.earlyPaymentOn = 'This field is required';
            }
        }

        if (bulletLoanData?.downPayment === 'Y') {
            if (bulletLoanData?.downPaymentCharges === '') {
                errors.downPaymentCharges = 'This field is required';
            }
        }

        setBulletLoanError(errors);

        const sequenceObject = buttons?.reduce((result, item) => {
            const label = item.label.replace(/\s+/g, ''); // Remove spaces from label
            const seqKey = `seq${label}`;
            result[seqKey] = item.sequence;
            return result;
        }, {});

        if (bulletLoanData?.gracePeriod === false || bulletLoanData?.gracePeriod === '') {
            bulletLoanData.noOfDaysGracePeriod = '';
        }

        if (bulletLoanData?.latePayment === false || bulletLoanData?.latePayment === '') {
            bulletLoanData.latePaymentType = '';
            bulletLoanData.latePaymentCharges = '';
            bulletLoanData.latePaymentOn = '';
        }

        if (bulletLoanData?.earlyPayment === false || bulletLoanData?.earlyPayment === '') {
            bulletLoanData.earlyPaymentType = '';
            bulletLoanData.earlyPaymentCharges = '';
            bulletLoanData.earlyPaymentOn = '';
        }

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            const newBulletLoanTableData = {
                noOfDays: bulletLoanData?.noOfDays,
                markupValue: bulletLoanData?.markupValue,
                apr: bulletLoanData?.apr,

                gracePeriod: bulletLoanData?.gracePeriod,
                noOfDaysGracePeriod: bulletLoanData?.noOfDaysGracePeriod,

                latePayment: bulletLoanData?.latePayment,
                latePaymentType: bulletLoanData?.latePaymentType,
                latePaymentCharges: bulletLoanData?.latePaymentCharges,
                latePaymentOn: bulletLoanData?.latePaymentOn,

                frequencyId: bulletLoanData?.frequencyId || '',

                earlyPayment: bulletLoanData?.earlyPayment,
                earlyPaymentType: bulletLoanData?.earlyPaymentType,
                earlyPaymentCharges: bulletLoanData?.earlyPaymentCharges,
                earlyPaymentOn: bulletLoanData?.earlyPaymentOn,

                downPayment: bulletLoanData?.downPayment === 'Y' ? true : false,
                downPaymentCharges: bulletLoanData?.downPaymentCharges,

                repaymentTypeCode: stepData1?.facilityCode
            };

            const combinedObject = { ...newBulletLoanTableData, ...sequenceObject };
            setBulletLoanTableData([...bulletLoanTableData, combinedObject]);

            // setBulletLoanData({
            //     noOfDays: '',
            //     markupValue: '',
            //     apr: '',

            //     gracePeriod: '',
            //     noOfDaysGracePeriod: '',

            //     latePayment: '',
            //     latePaymentType: '',
            //     latePaymentCharges: '',
            //     latePaymentOn: '',

            //     frequencyId: '',

            //     earlyPayment: '',
            //     earlyPaymentType: '',
            //     earlyPaymentCharges: '',
            //     earlyPaymentOn: '',

            //     downPayment: '',
            //     downPaymentCharges: ''
            // });

            setButtons([
                { label: 'Principal Amount', sequence: 1 },
                { label: 'Markup', sequence: 2 },
                { label: 'Fees', sequence: 3 }
            ]);
        }
    };

    const handledData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        const checked = e.target.checked;
        const type = e.target.type;

        if (name === 'gracePeriod' && checked === false) {
            bulletLoanData.noOfDaysGracePeriod = '';
        }

        if (name === 'latePayment' && checked === false) {
            bulletLoanData.latePaymentType = '';
            bulletLoanData.latePaymentCharges = '';
            bulletLoanData.latePaymentOn = '';
        }

        if (name === 'earlyPayment' && checked === false) {
            bulletLoanData.earlyPaymentType = '';
            bulletLoanData.earlyPaymentCharges = '';
            bulletLoanData.earlyPaymentOn = '';
        }

        setBulletLoanData({ ...bulletLoanData, [name]: type === 'checkbox' ? checked : value });

        // Clear the error message for the field being edited
        setBulletLoanError({
            ...bulletLoanError,
            [name]: ''
        });
    };
    //////////////////////////////////////////////////////////

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>REPAYMENT METHOD/EDIT</h1>
            </div>
            <div className="card Card__Round">
                <div className="Form__Header flex align-items-center justify-content-between">
                    <h1>LOAN TENURE </h1>
                    {/* <Button
                        icon="pi pi-plus"
                        className="p-button-rounded"
                        aria-label="Filter"
                        tooltip="Add New Repayment"
                        onClick={(e) => handledAdd(e)}
                    /> */}
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="noOfDays" className={classNames({ 'p-error': isFormFieldValid('noOfDays') }, 'Label__Text')}>
                                    No. Of Days<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="noOfDays"
                                    placeholder="Select No. Of Days"
                                    options={loanTenureNOD}
                                    optionLabel="name"
                                    name="noOfDays"
                                    filter
                                    optionValue="lovId"
                                    value={bulletLoanData?.noOfDays || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('noOfDays') }, 'Dropdown__Round')}
                                />
                                <small className="p-error">{bulletLoanError?.noOfDays}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="markupValue" className={classNames({ 'p-error': isFormFieldValid('markupValue') }, 'Label__Text')}>
                                    Markup value <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={4}
                                    id="markupValue"
                                    placeholder="Enter Markup Value"
                                    name="markupValue"
                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                    value={bulletLoanData?.markupValue || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('markupValue') }, 'Input__Round')}
                                />
                                <small className="p-error">{bulletLoanError?.markupValue}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="apr" className={classNames({ 'p-error': isFormFieldValid('apr') }, 'Label__Text')}>
                                    APR <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="apr"
                                    placeholder="Enter APR"
                                    name="apr"
                                    maxLength={4}
                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                    value={bulletLoanData?.apr || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('apr') }, 'Input__Round')}
                                />
                                <small className="p-error">{bulletLoanError?.apr}</small>
                            </div>
                        </div>
                    </div>
                    <div className="Form__Header col-12">
                        <h1>GRACE PERIOD </h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="gracePeriod" name="gracePeriod" value={bulletLoanData?.gracePeriod || ''} checked={bulletLoanData?.gracePeriod} onChange={handledData} style={{ marginTop: '10px' }} />
                                    <label htmlFor="gracePeriod" className={classNames({ 'p-error': isFormFieldValid('gracePeriod') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Grace Period
                                    </label>
                                </div>
                                <small className="p-error">{bulletLoanError?.gracePeriod}</small>
                            </div>
                        </div>
                        {bulletLoanData?.gracePeriod === true && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="noOfDaysGracePeriod" className={classNames({ 'p-error': isFormFieldValid('noOfDaysGracePeriod') }, 'Label__Text')}>
                                        Number of Days <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={4}
                                        id="noOfDaysGracePeriod"
                                        placeholder="Enter Number of Days"
                                        name="noOfDaysGracePeriod"
                                        keyfilter={'int'}
                                        value={bulletLoanData?.noOfDaysGracePeriod || ''}
                                        onChange={handledData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('noOfDaysGracePeriod') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{bulletLoanError?.noOfDaysGracePeriod}</small>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="Form__Header col-12">
                        <h1>LATE PAYMENT </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="latePayment" name="latePayment" value={bulletLoanData?.latePayment || ''} checked={bulletLoanData?.latePayment} onChange={handledData} style={{ marginTop: '10px' }} />
                                    <label htmlFor="latePayment" className={classNames({ 'p-error': isFormFieldValid('latePayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Late payment
                                    </label>
                                </div>
                                <small className="p-error">{bulletLoanError?.latePayment}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        {bulletLoanData?.latePayment === true && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentType" className={classNames({ 'p-error': isFormFieldValid('latePaymentType') }, 'Label__Text')}>
                                            Type<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="latePaymentType"
                                            placeholder="Select Type"
                                            options={typeLov}
                                            optionLabel="name"
                                            name="latePaymentType"
                                            filter
                                            optionValue="lovId"
                                            value={bulletLoanData?.latePaymentType || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('latePaymentType') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{bulletLoanError?.latePaymentType}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentCharges" className={classNames({ 'p-error': isFormFieldValid('latePaymentCharges') }, 'Label__Text')}>
                                            Charges <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="latePaymentCharges"
                                            placeholder="Enter charges"
                                            name="latePaymentCharges"
                                            maxLength={8}
                                            keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                            value={bulletLoanData?.latePaymentCharges || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('latePaymentCharges') }, 'Input__Round')}
                                        />
                                        <small className="p-error">{bulletLoanError?.latePaymentCharges}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentOn" className={classNames({ 'p-error': isFormFieldValid('latePaymentOn') }, 'Label__Text')}>
                                            Late Payment On<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="latePaymentOn"
                                            placeholder="Select Late Payment On"
                                            options={paymentOnLov}
                                            optionLabel="name"
                                            name="latePaymentOn"
                                            filter
                                            optionValue="lovId"
                                            value={bulletLoanData?.latePaymentOn || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('latePaymentOn') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{bulletLoanError?.latePaymentOn}</small>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {/* //////////////////////////////////////////////////// */}
                    <div className="Form__Header col-12">
                        <h1>REPAYMENT SCHEDULE </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="frequencyId" className={classNames({ 'p-error': isFormFieldValid('frequencyId') }, 'Label__Text')}>
                                    Frequency Of Payment<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="frequencyId"
                                    placeholder="Select Frequency Of Payment"
                                    options={frequencyPeriodData}
                                    optionLabel="name"
                                    name="frequencyId"
                                    filter
                                    optionValue="lovId"
                                    value={bulletLoanData?.frequencyId || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('frequencyId') }, 'Dropdown__Round')}
                                />
                                <small className="p-error">{bulletLoanError?.frequencyId}</small>
                            </div>
                        </div>
                    </div>
                    {/* ///////////////////////////////////////////////////// */}

                    <div className="Form__Header col-12">
                        <h1>ALLOW EARLY REPAYMENT </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="earlyPayment" name="earlyPayment" value={bulletLoanData?.earlyPayment || ''} checked={bulletLoanData?.earlyPayment} onChange={handledData} style={{ marginTop: '10px' }} />
                                    <label htmlFor="earlyPayment" className={classNames({ 'p-error': isFormFieldValid('earlyPayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Early Payment
                                    </label>
                                </div>
                                <small className="p-error">{bulletLoanError?.earlyPayment}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        {bulletLoanData?.earlyPayment === true && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="earlyPaymentType" className={classNames({ 'p-error': isFormFieldValid('earlyPaymentType') }, 'Label__Text')}>
                                            Type<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="earlyPaymentType"
                                            placeholder="Select Type"
                                            options={typeLov}
                                            optionLabel="name"
                                            name="earlyPaymentType"
                                            filter
                                            optionValue="lovId"
                                            value={bulletLoanData?.earlyPaymentType || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('earlyPaymentType') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{bulletLoanError?.earlyPaymentType}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="earlyPaymentCharges" className={classNames({ 'p-error': isFormFieldValid('earlyPaymentCharges') }, 'Label__Text')}>
                                            Charges <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="earlyPaymentCharges"
                                            placeholder="Enter charges"
                                            name="earlyPaymentCharges"
                                            maxLength={8}
                                            keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                            value={bulletLoanData?.earlyPaymentCharges || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('earlyPaymentCharges') }, 'Input__Round')}
                                        />
                                        <small className="p-error">{bulletLoanError?.earlyPaymentCharges}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="earlyPaymentOn" className={classNames({ 'p-error': isFormFieldValid('earlyPaymentOn') }, 'Label__Text')}>
                                            Early Payment On<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="earlyPaymentOn"
                                            placeholder="Select Early Payment On"
                                            options={paymentOnLov}
                                            optionLabel="name"
                                            name="earlyPaymentOn"
                                            filter
                                            optionValue="lovId"
                                            value={bulletLoanData?.earlyPaymentOn || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('earlyPaymentOn') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{bulletLoanError?.earlyPaymentOn}</small>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="Form__Header col-12">
                        <h1>DOWN PAYMENT </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="downPayment" className={classNames({ 'p-error': isFormFieldValid('downPayment') }, 'Label__Text')}>
                                    Select Down Payment<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="downPayment"
                                    placeholder="Select Down Payment"
                                    options={downPaymentLov}
                                    optionLabel="name"
                                    name="downPayment"
                                    filter
                                    optionValue="lovId"
                                    value={bulletLoanData?.downPayment || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('downPayment') }, 'Dropdown__Round')}
                                />
                                <small className="p-error">{bulletLoanError?.downPayment}</small>
                            </div>
                        </div>
                        {bulletLoanData?.downPayment === 'Y' && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="downPaymentCharges" className={classNames({ 'p-error': isFormFieldValid('downPaymentCharges') }, 'Label__Text')}>
                                        Charges <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="downPaymentCharges"
                                        placeholder="Enter Down Payment Charges"
                                        name="downPaymentCharges"
                                        maxLength={8}
                                        keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                        value={bulletLoanData?.downPaymentCharges || ''}
                                        onChange={handledData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('downPaymentCharges') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{bulletLoanError?.downPaymentCharges}</small>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="Form__Header">
                        <h1>DEFINE REPAYMENT SEQUENCE </h1>
                    </div>
                    <div className="Centre_Aligned_Buttons" onDragOver={handleDragOver}>
                        {buttons?.map((button) => (
                            <Button
                                key={button.label}
                                className="DraggableButton Light_Button"
                                onClick={(e) => e.preventDefault()}
                                label={`${button.label}`}
                                draggable
                                onDragStart={(e) => handleDragStart(e, button.label)}
                                onDrop={(e) => handleDrop(e, button.label)}
                            />
                        ))}
                    </div>
                    {/* <div className="grid">
                        <div className="col-12">
                            <div className="card mt-6">
                                <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={bulletLoanTableData}>
                                    <Column field="noOfDays" header="No. Of Days" />
                                    <Column field="markupValue" header="markup value" />
                                    <Column field="apr" header="APR" />
                                    <Column field="status" header="Status" />
                                    <Column header="Actions" body={actionBodyTemplate} style={{ textAlign: 'center' }} className="Table__ActionHeading__CentredAlign" />
                                </DataTable>
                            </div>
                        </div>
                    </div> */}
                    <div className="Down__Btn">
                        <Button loadingIcon={loadingIcon} style={{ margin: '20px' }} onClick={() => handledAdd()} label={'Next'} className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default EditBulletLoan;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { useDispatch } from 'react-redux';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';

function AddTransactionProfiling({ activeIndex, handleStepClick, responseId, customerRiskProfileData, handledAllStepsComplete, crpModelIdData, handlecrpId, getcustomerRiskProfileId }) {

    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');
    const [amountTransactionProfile, setAmountTransactionProfile] = useState([]);
    const [countTransactionProfile, setCountTransactionProfile] = useState([]);

    // Expected Monthly Credit Amount
    const [selectedCreditAmountHighRisk, setSelectedCreditAmountHighRisk] = useState([]);
    const [selectedCreditAmountMediumRisk, setSelectedCreditAmountMediumRisk] = useState([]);
    // const [selectedCreditAmountLowRisk, setSelectedCreditAmountLowRisk] = useState([]);

    const combinedCreditAmountMediumLowRisk = [...selectedCreditAmountMediumRisk];
    const combinedCreditAmountHighLowRisk = [...selectedCreditAmountHighRisk];
    const combinedCreditAmountHighMediumRisk = [...selectedCreditAmountHighRisk, ...selectedCreditAmountMediumRisk];

    // Expected Monthly Debit Amount
    const [selectedDebitAmountHighRisk, setSelectedDebitAmountHighRisk] = useState([]);
    const [selectedDebitAmountMediumRisk, setSelectedDebitAmountMediumRisk] = useState([]);
    // const [selectedDebitAmountLowRisk, setSelectedDebitAmountLowRisk] = useState([]);

    const combinedDebitAmountMediumLowRisk = [...selectedDebitAmountMediumRisk];
    const combinedDebitAmountHighLowRisk = [...selectedDebitAmountHighRisk];
    const combinedDebitAmountHighMediumRisk = [...selectedDebitAmountHighRisk, ...selectedDebitAmountMediumRisk];

    // Expected Monthly Credit Count
    const [selectedCreditCountHighRisk, setSelectedCreditCountHighRisk] = useState([]);
    const [selectedCreditCountMediumRisk, setSelectedCreditCountMediumRisk] = useState([]);
    // const [selectedCreditCountLowRisk, setSelectedCreditCountLowRisk] = useState([]);

    const combinedCreditCountMediumLowRisk = [...selectedCreditCountMediumRisk];
    const combinedCreditCountHighLowRisk = [...selectedCreditCountHighRisk];
    const combinedCreditCountHighMediumRisk = [...selectedCreditCountHighRisk, ...selectedCreditCountMediumRisk];

    // Expected Monthly Debit Amount
    const [selectedDebitCountHighRisk, setSelectedDebitCountHighRisk] = useState([]);
    const [selectedDebitCountMediumRisk, setSelectedDebitCountMediumRisk] = useState([]);
    // const [selectedDebitCountLowRisk, setSelectedDebitCountLowRisk] = useState([]);

    const combinedDebitCountMediumLowRisk = [...selectedDebitCountMediumRisk];
    const combinedDebitCountHighLowRisk = [...selectedDebitCountHighRisk];
    const combinedDebitCountHighMediumRisk = [...selectedDebitCountHighRisk, ...selectedDebitCountMediumRisk];

    const [high, setHigh] = useState(null);
    const [med, setMed] = useState(null);
    const [lov, setLov] = useState([]);

    const dispatch = useDispatch();

    const getTransactionProfileLov = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getcrpdata/LKP_RP_TRANS_PROFILE');
        const amountLov = response.payLoad?.filter((item) => item.name.includes('Million'));
        const countLov = response.payLoad?.filter((item) => !item.name.includes('Million'));
        setAmountTransactionProfile(amountLov);
        setCountTransactionProfile(countLov);
    };

    const formik = useFormik({
        initialValues: {
            stepNo: 4,
            crpId: responseId || customerRiskProfileData?.rpCrpId,
            expectedMonthlyCrHighRisk: [],
            expectedMonthlyCrMediumRisk: [],
            expectedMonthlyCrLowRisk: [],
            expectedMonthlyCrOverRideRule: 'false',
            expectedMonthlyDrHighRisk: [],
            expectedMonthlyDrMediumRisk: [],
            expectedMonthlyDrLowRisk: [],
            expectedMonthlyDrOverRideRule: 'false',
            expectedMonthlyCrCountHighRisk: [],
            expectedMonthlyCrCountMediumRisk: [],
            expectedMonthlyCrCountLowRisk: [],
            expectedMonthlyCrCountOverRideRule: 'false',
            expectedMonthlyDrCountHighRisk: [],
            expectedMonthlyDrCountMediumRisk: [],
            expectedMonthlyDrCountLowRisk: [],
            expectedMonthlyDrCountOverRideRule: 'false'
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            if (customerRiskProfileData?.currentStep >= 4) {
                data['edit'] = true;
            }

            data['rpCrpModelId'] = crpModelIdData || customerRiskProfileData?.tblRpCrpModel?.rpCrpModelId;

            data['reqMap'] = [
                {
                    DM: {
                        highRisk: data?.expectedMonthlyDrHighRisk,
                        medRisk: data?.expectedMonthlyDrMediumRisk,
                        lowRisk: data?.expectedMonthlyDrLowRisk,
                        overRideRule: data?.expectedMonthlyDrOverRideRule
                    }
                },
                {
                    CM: {
                        highRisk: data?.expectedMonthlyCrHighRisk,
                        medRisk: data?.expectedMonthlyCrMediumRisk,
                        lowRisk: data?.expectedMonthlyCrLowRisk,
                        overRideRule: data?.expectedMonthlyCrOverRideRule
                    }
                },
                {
                    DC: {
                        highRisk: data?.expectedMonthlyDrCountHighRisk,
                        medRisk: data?.expectedMonthlyDrCountMediumRisk,
                        lowRisk: data?.expectedMonthlyDrCountLowRisk,
                        overRideRule: data?.expectedMonthlyDrCountOverRideRule
                    }
                },
                {
                    CC: {
                        highRisk: data?.expectedMonthlyCrCountHighRisk,
                        medRisk: data?.expectedMonthlyCrCountMediumRisk,
                        lowRisk: data?.expectedMonthlyCrCountLowRisk,
                        overRideRule: data?.expectedMonthlyCrCountOverRideRule
                    }
                }
            ];
            delete data['expectedMonthlyDrHighRisk'];
            delete data['expectedMonthlyDrMediumRisk'];
            delete data['expectedMonthlyDrLowRisk'];
            delete data['expectedMonthlyDrOverRideRule'];
            delete data['expectedMonthlyCrHighRisk'];
            delete data['expectedMonthlyCrMediumRisk'];
            delete data['expectedMonthlyCrLowRisk'];
            delete data['expectedMonthlyCrOverRideRule'];
            delete data['expectedMonthlyDrCountHighRisk'];
            delete data['expectedMonthlyDrCountMediumRisk'];
            delete data['expectedMonthlyDrCountLowRisk'];
            delete data['expectedMonthlyDrCountOverRideRule'];
            delete data['expectedMonthlyCrCountHighRisk'];
            delete data['expectedMonthlyCrCountMediumRisk'];
            delete data['expectedMonthlyCrCountLowRisk'];
            delete data['expectedMonthlyCrCountOverRideRule'];

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            // handleStepClick(activeIndex + 1);
            handledAllStepsComplete(true);

            if (customerRiskProfileData?.currentStep >= 4) {
                const res = await dispatch(handlePostRequest(newData, '/riskprofile/v1/crp/saveupdatecrp', true, false));
                if (res?.responseCode === '170000') {
                    handleStepClick(activeIndex + 1);
                    handledAllStepsComplete(true);
                    handlecrpId(res?.payLoad?.rpCrpId);
                    getcustomerRiskProfileId(res?.payLoad?.rpCrpId);
                }
            } else {
                const res = await dispatch(handlePostRequest(newData, '/riskprofile/v1/crp/saveupdatecrp', true, false));
                if (res?.responseCode === '170000') {
                    handleStepClick(activeIndex + 1);
                    handledAllStepsComplete(true);
                    handlecrpId(res?.payLoad?.rpCrpId);
                    getcustomerRiskProfileId(res?.payLoad?.rpCrpId);
                }
            }

            setloading(false);
            setloadingIcon('');

            formik.setFieldValue('expectedMonthlyDrHighRisk', data?.reqMap[0]?.DM?.highRisk);
            formik.setFieldValue('expectedMonthlyDrMediumRisk', data?.reqMap[0]?.DM?.medRisk);
            formik.setFieldValue('expectedMonthlyDrLowRisk', data?.reqMap[0]?.DM?.lowRisk);
            formik.setFieldValue('expectedMonthlyDrOverRideRule', data?.reqMap[0]?.DM?.overRideRule);

            formik.setFieldValue('expectedMonthlyCrHighRisk', data?.reqMap[1]?.CM?.highRisk);
            formik.setFieldValue('expectedMonthlyCrMediumRisk', data?.reqMap[1]?.CM?.medRisk);
            formik.setFieldValue('expectedMonthlyCrLowRisk', data?.reqMap[1]?.CM?.lowRisk);
            formik.setFieldValue('expectedMonthlyCrOverRideRule', data?.reqMap[1]?.CM?.overRideRule);

            formik.setFieldValue('expectedMonthlyDrCountHighRisk', data?.reqMap[2]?.DC?.highRisk);
            formik.setFieldValue('expectedMonthlyDrCountMediumRisk', data?.reqMap[2]?.DC?.medRisk);
            formik.setFieldValue('expectedMonthlyDrCountLowRisk', data?.reqMap[2]?.DC?.lowRisk);
            formik.setFieldValue('expectedMonthlyDrCountOverRideRule', data?.reqMap[2]?.DC?.overRideRule);

            formik.setFieldValue('expectedMonthlyCrCountHighRisk', data?.reqMap[3]?.CC?.highRisk);
            formik.setFieldValue('expectedMonthlyCrCountMediumRisk', data?.reqMap[3]?.CC?.medRisk);
            formik.setFieldValue('expectedMonthlyCrCountLowRisk', data?.reqMap[3]?.CC?.lowRisk);
            formik.setFieldValue('expectedMonthlyCrCountOverRideRule', data?.reqMap[3]?.CC?.overRideRule);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const setHighValue = (highValue) => {
        setHigh(highValue);
    };

    const setMedValue = (medValue) => {
        setMed(medValue);
    };

    const setLovValues = (lov) => {
        setLov(lov);
    };

    useEffect(() => {
        getTransactionProfileLov();
    }, []);

    useEffect(() => {
        if (customerRiskProfileData) {
            const CTDropdownData = customerRiskProfileData?.tblRpCrpDetails;

            if (CTDropdownData) {
                const CMHighId = [];
                const CMMediumId = [];
                const CMLowId = [];

                const DMHighId = [];
                const DMMediumId = [];
                const DMLowId = [];

                const CCHighId = [];
                const CCMediumId = [];
                const CCLowId = [];

                const DCHighId = [];
                const DCMediumId = [];
                const DCLowId = [];

                CTDropdownData.forEach((value, index) => {
                    const referenceIdToString = value.refId?.toString();

                    if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'CM') {
                        if (value?.rpRating === 'H') {
                            CMHighId.push(referenceIdToString);

                            formik.setFieldValue('expectedMonthlyCrHighRisk', CMHighId);
                            setSelectedCreditAmountHighRisk(CMHighId)
                            formik.setFieldValue('expectedMonthlyCrOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                        }
                        if (value?.rpRating === 'M') {
                            CMMediumId.push(referenceIdToString);

                            formik.setFieldValue('expectedMonthlyCrMediumRisk', CMMediumId);
                            setSelectedCreditAmountMediumRisk(CMMediumId)
                        }
                        if (value?.rpRating === 'L') {
                            CMLowId.push(referenceIdToString);

                            formik.setFieldValue('expectedMonthlyCrLowRisk', CMLowId);
                        }
                    }
                    if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'DM') {
                        if (value?.rpRating === 'H') {
                            DMHighId.push(referenceIdToString);

                            formik.setFieldValue('expectedMonthlyDrHighRisk', DMHighId);
                            setSelectedDebitAmountHighRisk(DMHighId)
                            formik.setFieldValue('expectedMonthlyDrOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                        }
                        if (value?.rpRating === 'M') {
                            DMMediumId.push(referenceIdToString);

                            formik.setFieldValue('expectedMonthlyDrMediumRisk', DMMediumId);
                            setSelectedDebitAmountMediumRisk(DMMediumId)
                        }
                        if (value?.rpRating === 'L') {
                            DMLowId.push(referenceIdToString);

                            formik.setFieldValue('expectedMonthlyDrLowRisk', DMLowId);
                        }
                    }
                    if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'CC') {
                        if (value?.rpRating === 'H') {
                            CCHighId.push(referenceIdToString);

                            formik.setFieldValue('expectedMonthlyCrCountHighRisk', CCHighId);
                            setSelectedCreditCountHighRisk(CCHighId)
                            formik.setFieldValue('expectedMonthlyCrCountOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                        }
                        if (value?.rpRating === 'M') {
                            CCMediumId.push(referenceIdToString);

                            formik.setFieldValue('expectedMonthlyCrCountMediumRisk', CCMediumId);
                            setSelectedCreditCountMediumRisk(CCMediumId)
                        }
                        if (value?.rpRating === 'L') {
                            CCLowId.push(referenceIdToString);

                            formik.setFieldValue('expectedMonthlyCrCountLowRisk', CCLowId);
                        }
                    }
                    if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'DC') {
                        if (value?.rpRating === 'H') {
                            DCHighId.push(referenceIdToString);

                            formik.setFieldValue('expectedMonthlyDrCountHighRisk', DCHighId);
                            setSelectedDebitCountHighRisk(DCHighId)
                            formik.setFieldValue('expectedMonthlyDrCountOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                        }
                        if (value?.rpRating === 'M') {
                            DCMediumId.push(referenceIdToString);

                            formik.setFieldValue('expectedMonthlyDrCountMediumRisk', DCMediumId);
                            setSelectedDebitCountMediumRisk(DCMediumId)
                        }
                        if (value?.rpRating === 'L') {
                            DCLowId.push(referenceIdToString);

                            formik.setFieldValue('expectedMonthlyDrCountLowRisk', DCLowId);
                        }
                    }
                });
            }
        }
    }, []);

    useEffect(() => {
        // Helper function to handle the logic for setting Low dropdown values
        const updateLowDropdown = (high, med, lov, formik) => {
            // Selected values from high and medium
            const selectedHighValues = formik?.values?.[high] || [];
            const selectedMedValues = formik?.values?.[med] || [];

            // Combine selected high and medium values
            const selectedCtHighMedValues = [...selectedHighValues, ...selectedMedValues];

            // Extract all values from lov (dropdown options)
            const allValuesArr = lov.map((values) =>
                values.lovId
            );

            // Remaining values for Low by excluding selected High and Medium values
            const remainingValues = allValuesArr.filter((value) => !selectedCtHighMedValues.includes(value));

            // Determine the formik field for the Low dropdown
            const formikLowField = high ? high.replace('High', 'Low') : med && med.replace('Medium', 'Low');
            formik.setFieldValue(formikLowField, remainingValues);
        };

        if (lov) {
            if (high) {
                const med = high.replace('High', 'Medium');
                updateLowDropdown(high, med, lov, formik);
                setHigh(null);
            } else if (med) {
                const high = med.replace('Medium', 'High');
                updateLowDropdown(high, med, lov, formik);
                setMed(null);
            }
        }
    }, [high, med, formik?.values?.[high], formik?.values?.[med], lov]);

    useEffect(() => {
        // if (!customerRiskProfileData) {
        formik.setFieldValue("expectedMonthlyCrLowRisk", amountTransactionProfile.map((item) => item?.lovId))
        formik.setFieldValue("expectedMonthlyDrLowRisk", amountTransactionProfile.map((item) => item?.lovId))
        formik.setFieldValue("expectedMonthlyCrCountLowRisk", countTransactionProfile.map((item) => item?.lovId))
        formik.setFieldValue("expectedMonthlyDrCountLowRisk", countTransactionProfile.map((item) => item?.lovId))
        // }
    }, [amountTransactionProfile, countTransactionProfile]);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {/* EXPECTED MONTHLY CREDIT AMOUNT */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>EXPECTED MONTHLY CREDIT AMOUNT</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyCrHighRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyCrHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyCrHighRisk"
                                    placeholder="Select High Risk"
                                    options={amountTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyCrHighRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyCrHighRisk || ''}
                                    onChange={(e) => {
                                        setSelectedCreditAmountHighRisk(e.value);
                                        formik.setFieldValue('expectedMonthlyCrHighRisk', e.value);
                                        // formik.setFieldValue('expectedMonthlyCrOverRideRule', e.value.length > 0 ? 'true' : "false");
                                        setHighValue("expectedMonthlyCrHighRisk");
                                        setLovValues(amountTransactionProfile)
                                    }}
                                    optionDisabled={(option) => combinedCreditAmountMediumLowRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyCrHighRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyCrMediumRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyCrMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyCrMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={amountTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyCrMediumRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyCrMediumRisk || ''}
                                    onChange={(e) => {
                                        setSelectedCreditAmountMediumRisk(e.value);
                                        formik.setFieldValue('expectedMonthlyCrMediumRisk', e.value);
                                        setMedValue("expectedMonthlyCrMediumRisk");
                                        setLovValues(amountTransactionProfile)
                                    }}
                                    optionDisabled={(option) => combinedCreditAmountHighLowRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyCrMediumRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyCrLowRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyCrLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyCrLowRisk"
                                    placeholder="Select Low Risk"
                                    options={amountTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyCrLowRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyCrLowRisk || ''}
                                    onChange={(e) => {
                                        // setSelectedCreditAmountLowRisk(e.value);
                                        formik.setFieldValue('expectedMonthlyCrLowRisk', e.value);
                                    }}
                                    optionDisabled={(option) => combinedCreditAmountHighMediumRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyCrLowRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="expectedMonthlyCrOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="expectedMonthlyCrOverRideRule" name="expectedMonthlyCrOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.expectedMonthlyCrOverRideRule === 'false'} />
                                    <label htmlFor="expectedMonthlyCrOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="expectedMonthlyCrOverRideRule" name="expectedMonthlyCrOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.expectedMonthlyCrOverRideRule === 'true'} />
                                    <label htmlFor="expectedMonthlyCrOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* EXPECTED MONTHLY DEBIT AMOUNT */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>EXPECTED MONTHLY DEBIT AMOUNT</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyDrHighRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyDrHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyDrHighRisk"
                                    placeholder="Select High Risk"
                                    options={amountTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyDrHighRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyDrHighRisk || ''}
                                    onChange={(e) => {
                                        setSelectedDebitAmountHighRisk(e.value);
                                        formik.setFieldValue('expectedMonthlyDrHighRisk', e.value);
                                        // formik.setFieldValue('expectedMonthlyDrOverRideRule', e.value.length > 0 ? 'true' : "false");
                                        setHighValue("expectedMonthlyDrHighRisk");
                                        setLovValues(amountTransactionProfile)
                                    }}
                                    optionDisabled={(option) => combinedDebitAmountMediumLowRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyDrHighRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyDrMediumRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyDrMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyDrMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={amountTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyDrMediumRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyDrMediumRisk || ''}
                                    onChange={(e) => {
                                        setSelectedDebitAmountMediumRisk(e.value);
                                        formik.setFieldValue('expectedMonthlyDrMediumRisk', e.value);
                                        setMedValue("expectedMonthlyDrMediumRisk");
                                        setLovValues(amountTransactionProfile)
                                    }}
                                    optionDisabled={(option) => combinedDebitAmountHighLowRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyDrMediumRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyDrLowRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyDrLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyDrLowRisk"
                                    placeholder="Select Low Risk"
                                    options={amountTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyDrLowRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyDrLowRisk || ''}
                                    onChange={(e) => {
                                        // setSelectedDebitAmountLowRisk(e.value);
                                        formik.setFieldValue('expectedMonthlyDrLowRisk', e.value);
                                    }}
                                    optionDisabled={(option) => combinedDebitAmountHighMediumRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyDrLowRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="expectedMonthlyDrOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="expectedMonthlyDrOverRideRule" name="expectedMonthlyDrOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.expectedMonthlyDrOverRideRule === 'false'} />
                                    <label htmlFor="expectedMonthlyDrOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="expectedMonthlyDrOverRideRule" name="expectedMonthlyDrOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.expectedMonthlyDrOverRideRule === 'true'} />
                                    <label htmlFor="expectedMonthlyDrOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* EXPECTED MONTHLY CREDIT COUNT */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>EXPECTED MONTHLY CREDIT COUNT</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyCrCountHighRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyCrCountHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyCrCountHighRisk"
                                    placeholder="Select High Risk"
                                    options={countTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyCrCountHighRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyCrCountHighRisk || ''}
                                    onChange={(e) => {
                                        setSelectedCreditCountHighRisk(e.value);
                                        formik.setFieldValue('expectedMonthlyCrCountHighRisk', e.value);
                                        // formik.setFieldValue('expectedMonthlyCrCountOverRideRule', e.value.length > 0 ? 'true' : "false");
                                        setHighValue("expectedMonthlyCrCountHighRisk");
                                        setLovValues(countTransactionProfile)
                                    }}
                                    optionDisabled={(option) => combinedCreditCountMediumLowRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyCrCountHighRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyCrCountMediumRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyCrCountMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyCrCountMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={countTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyCrCountMediumRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyCrCountMediumRisk || ''}
                                    onChange={(e) => {
                                        setSelectedCreditCountMediumRisk(e.value);
                                        formik.setFieldValue('expectedMonthlyCrCountMediumRisk', e.value);
                                        setMedValue("expectedMonthlyCrCountMediumRisk");
                                        setLovValues(countTransactionProfile)
                                    }}
                                    optionDisabled={(option) => combinedCreditCountHighLowRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyCrCountMediumRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyCrCountLowRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyCrCountLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyCrCountLowRisk"
                                    placeholder="Select Low Risk"
                                    options={countTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyCrCountLowRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyCrCountLowRisk || ''}
                                    onChange={(e) => {
                                        // setSelectedCreditCountLowRisk(e.value);
                                        formik.setFieldValue('expectedMonthlyCrCountLowRisk', e.value);
                                    }}
                                    optionDisabled={(option) => combinedCreditCountHighMediumRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyCrCountLowRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="expectedMonthlyCrCountOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton
                                        inputId="expectedMonthlyCrCountOverRideRule"
                                        name="expectedMonthlyCrCountOverRideRule"
                                        value={'false'}

                                        onChange={formik.handleChange}
                                        checked={formik.values.expectedMonthlyCrCountOverRideRule === 'false'}
                                    />
                                    <label htmlFor="expectedMonthlyCrCountOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton
                                        inputId="expectedMonthlyCrCountOverRideRule"
                                        name="expectedMonthlyCrCountOverRideRule"
                                        value={'true'}

                                        onChange={formik.handleChange}
                                        checked={formik.values.expectedMonthlyCrCountOverRideRule === 'true'}
                                    />
                                    <label htmlFor="expectedMonthlyCrCountOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* EXPECTED MONTHLY DEBIT COUNT */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>EXPECTED MONTHLY DEBIT COUNT</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyDrCountHighRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyDrCountHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyDrCountHighRisk"
                                    placeholder="Select High Risk"
                                    options={countTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyDrCountHighRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyDrCountHighRisk || ''}
                                    onChange={(e) => {
                                        setSelectedDebitCountHighRisk(e.value);
                                        formik.setFieldValue('expectedMonthlyDrCountHighRisk', e.value);
                                        // formik.setFieldValue('expectedMonthlyDrCountOverRideRule', e.value.length > 0 ? 'true' : "false");
                                        setHighValue("expectedMonthlyDrCountHighRisk");
                                        setLovValues(countTransactionProfile)
                                    }}
                                    optionDisabled={(option) => combinedDebitCountMediumLowRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyDrCountHighRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyDrCountMediumRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyDrCountMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyDrCountMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={countTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyDrCountMediumRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyDrCountMediumRisk || ''}
                                    onChange={(e) => {
                                        setSelectedDebitCountMediumRisk(e.value);
                                        formik.setFieldValue('expectedMonthlyDrCountMediumRisk', e.value);
                                        setMedValue("expectedMonthlyDrCountMediumRisk");
                                        setLovValues(countTransactionProfile)
                                    }}
                                    optionDisabled={(option) => combinedDebitCountHighLowRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyDrCountMediumRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyDrCountLowRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyDrCountLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyDrCountLowRisk"
                                    placeholder="Select Low Risk"
                                    options={countTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyDrCountLowRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyDrCountLowRisk || ''}
                                    onChange={(e) => {
                                        // setSelectedDebitCountLowRisk(e.value);
                                        formik.setFieldValue('expectedMonthlyDrCountLowRisk', e.value);
                                    }}
                                    optionDisabled={(option) => combinedDebitCountHighMediumRisk.includes(option.lovId)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyDrCountLowRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="expectedMonthlyDrCountOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton
                                        inputId="expectedMonthlyDrCountOverRideRule"
                                        name="expectedMonthlyDrCountOverRideRule"
                                        value={'false'}
                                        onChange={formik.handleChange}
                                        checked={formik.values.expectedMonthlyDrCountOverRideRule === 'false'}
                                    />
                                    <label htmlFor="expectedMonthlyDrCountOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton
                                        inputId="expectedMonthlyDrCountOverRideRule"
                                        name="expectedMonthlyDrCountOverRideRule"
                                        value={'true'}
                                        onChange={formik.handleChange}
                                        checked={formik.values.expectedMonthlyDrCountOverRideRule === 'true'}
                                    />
                                    <label htmlFor="expectedMonthlyDrCountOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button disabled={loading} icon={loadingIcon || ''} label={customerRiskProfileData?.currentStep >= 4 ? 'Edit' : 'Save'} className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default AddTransactionProfiling;

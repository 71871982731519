import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import { FilterMatchMode } from 'primereact/api';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import { Paginator } from 'primereact/paginator';
import AllowedActionOnPage from '../../../components/AllowedActionOnpage';
import { SearchButton, ResetButton } from '../../../components/SearchButton';
import { PaginationCall, PaginationTemplate } from '../../../components/Pagination';

function LoanAccountManagement() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [loanAccData, setLoanAccData] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [productId, setProductId] = useState([]);
    const [customFirst1, setCustomFirst1] = useState(0);
    const [customFirst2, setCustomFirst2] = useState(0);
    const [customRows1, setCustomRows1] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const [render, setRender] = useState(false);
    const [showDownload, setShowDownload] = useState(false);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        campaignName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const dataTableRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const convertArrayToCSV = (dataArray) => {
        const header = Object.keys(dataArray[0]).join(',') + '\n';
        const csvData = dataArray.map((item) => Object.values(item).join(',')).join('\n');
        return header + csvData;
    };

    const downloadCSV = (dataArray, fileName) => {
        const csvContent = convertArrayToCSV(dataArray);

        const blob = new Blob([csvContent], { type: 'text/csv' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    };

    const handleDownload = () => {
        // Function to convert camelCase to spaced format
        const convertToSpacedFormat = (str) => {
            return str
                .replace(/([A-Z])/g, ' $1')
                .replace(/^./, (match) => match.toUpperCase())
                .trim();
        };

        // Map and convert keys to spaced format
        const dataWithoutTotalCount = loanAccData?.map((item) => {
            const { totalCount, lmsDisbursementId, ...rest } = item;

            const convertedObject = {};
            for (const key in rest) {
                convertedObject[convertToSpacedFormat(key)] = rest[key];
            }

            return convertedObject;
        });

        downloadCSV(dataWithoutTotalCount, 'data.csv');
    };

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/lending/LKP_LMS_STATUS');
        setStatusLovData(res?.payLoad);
    };

    const getProductIds = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/lending/TBL_LMS_PRODUCT');
        setProductId(res?.payLoad);
    };
    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };

    useEffect(() => {
        getStatusListData();
        getUserLov();
        getProductIds();
    }, []);

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            lmsProductId: '',
            lmsStatusId: '',
            mobileNo: '',
            dateFrom: '',
            dateTo: '',
            rowStart: '0',
            rowEnd: '25'
        },
        validate: (data) => {
            let errors = {};
            if (data.dateTo && !data.dateFrom) {
                errors.dateFrom = 'Date From is required when Date To is provided';
            }

            if (data.dateFrom && data.dateTo && data.dateFrom > data.dateTo) {
                errors.dateTo = 'Date To must be greater than or equal to Date From';
            }
            return errors;
        },
        onSubmit: async (data) => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            dataTableRef.current.reset();

            const res = await dispatch(handlePostRequest(newData, '/lending/v1/lending/getallloanaccount', true, true));
            if (res?.responseCode === process.env.REACT_APP_LENDING_SUCCESS_CODE) {
                setLoanAccData(res?.payLoad);
                setShowDownload(true);
            } else {
                setLoanAccData([]);
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ''));
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        tooltip="View Details"
                        tooltipOptions={{ position: 'top' }}
                        icon="pi pi-eye"
                        onClick={() => navigate(`/viewloanaccount?reqID=${rowData?.lmsDisbursementId}`)}
                        className="p-button-rounded"
                        style={{ backgroundColor: '#F2AC57', border: 'none' }}
                    />
                </div>
            </>
        );
    };
    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const StartDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.loanDate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const EndDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.endDate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const statusTemplate = (rowData) => {
        // const statusColor = rowData?.transactionStatus === 'Approved' ? '#14A38B' : rowData?.transactionStatus === 'Pending' ? '#F2AC57' : '#FF7171';

        return (
            <p
            //  style={{ color: statusColor }}
            >
                <b>{rowData?.currentStatusOfLoan}</b>
            </p>
        );
    };

    const onCustomPageChange1 = (event) => {
        setCustomFirst1(event.first);
        setCustomFirst2(event.first + 1);
        setCustomRows1(event.rows);
        setCurrentPage(event.page + 1);
        setRender(true);
    };

    //*
    //this code is used to check allowed action on page to check whether user has right to create, update or delete
    // const consoleAction = AllowedActionOnPage({ path: window.location.href?.split('/#')?.pop(), userMenu: JSON.parse(sessionStorage.getItem('menu')) });
    // console.log(consoleAction, 'asas');
    //*

    const RequestData = {
        lmsProductId: formik.values.lmsProductId,
        lmsStatusId: formik.values.lmsStatusId,
        mobileNo: formik.values.mobileNo,
        dateFrom: formik.values.dateFrom,
        dateTo: formik.values.dateTo,
        rowStart: customFirst2,
        rowEnd: customRows1 + customFirst1
    };

    return (
        <>
            <PaginationCall
                render={render}
                customFirst1={customFirst1}
                customRows1={customRows1}
                currentPage={currentPage}
                dataTableRef={dataTableRef}
                setData={setLoanAccData}
                RequestData={RequestData}
                url={'/lending/v1/lending/getallloanaccount'}
                responseCode={process.env.REACT_APP_LENDING_SUCCESS_CODE}
            />
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="lmsProductId" className={classNames({ 'p-error': isFormFieldValid('lmsProductId') }, 'Label__Text')}>
                                    Product Id
                                </label>
                                <Dropdown
                                    id="lmsProductId"
                                    placeholder="Select Product Id"
                                    options={productId}
                                    optionLabel="name"
                                    name="lmsProductId"
                                    optionValue="lovId"
                                    value={formik.values.lmsProductId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lmsProductId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('lmsProductId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="mobileNo" className={classNames({ 'p-error': isFormFieldValid('mobileNo') }, 'Label__Text')}>
                                    Mobile Number
                                </label>
                                <InputText
                                    keyfilter="int"
                                    maxLength={11}
                                    id="mobileNo"
                                    placeholder="Enter Account ID"
                                    name="mobileNo"
                                    value={formik?.values?.mobileNo}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('mobileNo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('mobileNo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText
                                    type="date"
                                    id="dateFrom"
                                    placeholder="Enter Account ID"
                                    name="dateFrom"
                                    value={formik?.values?.dateFrom}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText
                                    type="date"
                                    id="dateTo"
                                    placeholder="Enter Account ID"
                                    name="dateTo"
                                    value={formik?.values?.dateTo}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="lmsStatusId" className={classNames({ 'p-error': isFormFieldValid('lmsStatusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="lmsStatusId"
                                    placeholder="Select Status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="lmsStatusId"
                                    optionValue="lovId"
                                    value={formik.values.lmsStatusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lmsStatusId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('lmsStatusId')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <SearchButton />
                        <ResetButton onClick={handleReset} />
                        {showDownload && <Button disabled={loading} label="Download Excel" className="Btn__Dark" onClick={handleDownload} />}
                        {/* <Button label="Reset" iconPos="right" className="Btn__Dark" onClick={(e) => handleReset(e)} /> */}
                    </div>
                </form>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            className="datatable-responsive"
                            filters={filters}
                            globalFilterFields={['campaignName', 'campaignCode', 'budget', 'createdOn', 'createdBy', 'startDate', 'endDate', 'statusDescr']}
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={loanAccData}
                        >
                            <Column field="productName" header="Loan Profile" />
                            <Column field="mobileNo" header="Mobile Number" />
                            <Column body={StartDateAndTimeBodyTemplate} header="Created Date and Time" />
                            <Column header="Status" body={statusTemplate} />
                            <Column header="Actions" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                        <Paginator template={PaginationTemplate(true)} first={customFirst1} rows={customRows1} totalRecords={loanAccData?.[0]?.totalCount} onPageChange={onCustomPageChange1}></Paginator>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LoanAccountManagement;

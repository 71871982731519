import React, { useState } from 'react';
//import { handlePostRequest } from '../../../services/PostTemplate';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';

function EditCustomerLoanProfileChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            accountClassificationId: '',
            segmentId: '',
            pricingProfileName: '',
            channelId: '',
            chargingParty: '',
            taxCalculationType: '',
            feeCalculationType: '',
            feeAmount: '',
            startDate: '',
            endDate: '',
            feeTypeCode: 'FF',
            incomeGlAccountId: '',
            velocity: 'No',
            incomeSharing: 'No',
            tblProduct: [],
            payeeGlAccountId: '',
            pricingProfileId: '',
            noOfPartners: '',
            tblPricingVelocities: [
                {
                    noOfFreeTrxn: '',
                    frequency: '',
                    velocityRule: '',
                    velocityAmount: '',
                    chargeOnDifferential: ''
                }
            ],
            tblPricingIncomeSharings: [
                {
                    sharingType: '',
                    incomeGlAccountId: '',
                    sharingDetails: '',
                    sharingPercentage: '',
                    taxStatus: '',
                    whtGlAccountId: ''
                }
            ]
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            formik.setFieldValue('tblProduct', data?.tblProduct);

            const productArray = data?.tblProduct.map((prdctData) => {
                return {
                    productId: prdctData
                };
            });

            data['tblProduct'] = productArray;
            data['velocity'] = data?.velocity === 'No' ? 'N' : 'Y';
            data['incomeSharing'] = data?.incomeSharing === 'No' ? 'N' : 'Y';
            if (data.velocity === 'N') {
                delete data['tblPricingVelocities'];
            }
            if (data.incomeSharing === 'N') {
                delete data['tblPricingIncomeSharings'];
            }
            if (data?.velocity === 'Y') {
                data?.tblPricingVelocities?.forEach((item) => {
                    delete item.lastupdatedate;
                    delete item.lastupdateuser;
                    delete item.updateindex;
                    delete item.createdate;
                    delete item.createuser;
                });
            }
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            // await dispatch(handlePostRequest(newData, '/pricingprofile/v1/pricingprofile/updatepricingprofile', true, false, 'pricing'));
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>Z-CONNECT CUSTOMER KC ACTIVE LOAN / EDIT DETAILS</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                    Customer Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={30}
                                    id="pricingProfileName"
                                    placeholder="Enter Customer Name"
                                    name="pricingProfileName"
                                    value={formik?.values?.pricingProfileName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('accountClassificationId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="segmentId" className={classNames({ 'p-error': isFormFieldValid('segmentId') }, 'Label__Text')}>
                                    Mobile Number<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={11}
                                    id="pricingProfileName"
                                    placeholder="Enter Mobile Number"
                                    name="pricingProfileName"
                                    value={formik?.values?.pricingProfileName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('segmentId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="pricingProfileName" className={classNames({ 'p-error': isFormFieldValid('pricingProfileName') }, 'Label__Text')}>
                                    CNIC<span className="Label__Required">*</span>
                                </label>
                                <InputText

                                    maxLength={30}
                                    id="pricingProfileName"
                                    placeholder="Enter CNIC"
                                    name="pricingProfileName"
                                    value={formik?.values?.pricingProfileName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('pricingProfileName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="channelId" className={classNames({ 'p-error': isFormFieldValid('channelId') }, 'Label__Text')}>
                                    Email<span className="Label__Required">*</span>
                                </label>
                                <InputText

                                    maxLength={30}
                                    id="pricingProfileName"
                                    placeholder="Enter Email"
                                    name="pricingProfileName"
                                    value={formik?.values?.pricingProfileName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('channelId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="tblProduct" className={classNames({ 'p-error': isFormFieldValid('tblProduct') }, 'Label__Text')}>
                                    Segment<span className="Label__Required">*</span>
                                </label>
                                <InputText

                                    maxLength={30}
                                    id="pricingProfileName"
                                    placeholder="Enter Segment Name"
                                    name="pricingProfileName"
                                    value={formik?.values?.pricingProfileName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('tblProduct')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="chargingParty" className={classNames({ 'p-error': isFormFieldValid('chargingParty') }, 'Label__Text')}>
                                    Activation Date<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    type="date"
                                    id="pricingProfileName"
                                    name="pricingProfileName"
                                    value={formik?.values?.pricingProfileName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('chargingParty')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') }, 'Label__Text')}>
                                    Loan ID<span className="Label__Required">*</span>
                                </label>
                                {/* <InputText
                                    max="9999-12-31"
                                    id="startDate"
                                    placeholder="Select Define Duration"
                                    type="date"
                                    optionLabel="name"
                                    name="startDate"
                                    optionValue="lovId"
                                    value={formik.values.startDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('startDate') }, 'Input__Round')}
                                /> */}
                                <InputText
                                    maxLength={30}
                                    id="pricingProfileName"
                                    placeholder="Enter Loan ID"
                                    name="pricingProfileName"
                                    value={formik?.values?.pricingProfileName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('defineduration')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') }, 'Label__Text')}>
                                    Loan Amount<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={30}
                                    id="pricingProfileName"
                                    placeholder="Enter Loan Amount"
                                    name="pricingProfileName"
                                    value={formik?.values?.pricingProfileName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('endDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="feeCalculationType" className={classNames({ 'p-error': isFormFieldValid('feeCalculationType') }, 'Label__Text')}>
                                    Product Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={30}
                                    id="pricingProfileName"
                                    placeholder="Enter Product Name"
                                    name="pricingProfileName"
                                    value={formik?.values?.pricingProfileName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('feeCalculationType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="taxCalculationType" className={classNames({ 'p-error': isFormFieldValid('taxCalculationType') }, 'Label__Text')}>
                                    Loan Tenure<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="taxCalculationType"
                                    placeholder="Select Loan Tenure"
                                    options={[]}
                                    optionLabel="label"
                                    name="taxCalculationType"
                                    optionValue="value"
                                    value={formik.values.taxCalculationType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('taxCalculationType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('taxCalculationType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="taxCalculationType" className={classNames({ 'p-error': isFormFieldValid('taxCalculationType') }, 'Label__Text')}>
                                    Account Status<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="taxCalculationType"
                                    placeholder="Select Account Status"
                                    options={[]}
                                    optionLabel="label"
                                    name="taxCalculationType"
                                    optionValue="value"
                                    value={formik.values.taxCalculationType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('taxCalculationType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('taxCalculationType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="taxCalculationType" className={classNames({ 'p-error': isFormFieldValid('taxCalculationType') }, 'Label__Text')}>
                                    Issuance Date<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    max="9999-12-31"
                                    id="startDate"
                                    type="date"
                                    optionLabel="name"
                                    name="startDate"
                                    optionValue="lovId"
                                    value={formik.values.startDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('startDate') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('taxCalculationType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="taxCalculationType" className={classNames({ 'p-error': isFormFieldValid('taxCalculationType') }, 'Label__Text')}>
                                    Pricing<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="taxCalculationType"
                                    placeholder="Select Pricing"
                                    options={[]}
                                    optionLabel="label"
                                    name="taxCalculationType"
                                    optionValue="value"
                                    value={formik.values.taxCalculationType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('taxCalculationType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('taxCalculationType')}
                            </div>
                        </div>
                    </div>
                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>REPYAMNET METHODS</h1>
                    </div>
                    <div style={{ backgroundColor: '#edf0f5', padding: '2rem' }} className=" col-12 card ">
                        <div className="Form__Header" style={{ borderBottom: '1px solid #cecece' }}>
                            <h1>LOAN TENURE</h1>
                        </div>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                        Start Value<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={30}
                                        id="pricingProfileName"
                                        placeholder="Enter start value"
                                        name="pricingProfileName"
                                        value={formik?.values?.pricingProfileName || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round')}
                                    />

                                    {getFormErrorMessage('accountClassificationId')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                        End Value<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={30}
                                        id="pricingProfileName"
                                        placeholder="Enter end value"
                                        name="pricingProfileName"
                                        value={formik?.values?.pricingProfileName || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round')}
                                    />

                                    {getFormErrorMessage('accountClassificationId')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                        Start Date<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        type="date"
                                        id="pricingProfileName"
                                        name="pricingProfileName"
                                        value={formik?.values?.pricingProfileName || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round')}
                                    />

                                    {getFormErrorMessage('accountClassificationId')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                        End Date<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        type="date"
                                        id="pricingProfileName"
                                        name="pricingProfileName"
                                        value={formik?.values?.pricingProfileName || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round')}
                                    />

                                    {getFormErrorMessage('accountClassificationId')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" col-12 card ">
                        <div className="Form__Header" style={{ borderBottom: '1px solid #cecece' }}>
                            <h1>LATE PAYMENT</h1>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', marginTop: '20px' }}>
                            <Checkbox />
                            <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                Late Payment
                            </label>
                        </div>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                        Start Date<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        type="date"
                                        id="pricingProfileName"
                                        placeholder="Enter start value"
                                        name="pricingProfileName"
                                        value={formik?.values?.pricingProfileName || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round')}
                                    />

                                    {getFormErrorMessage('accountClassificationId')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                        End Date<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        type="date"
                                        id="pricingProfileName"
                                        placeholder="Enter start value"
                                        name="pricingProfileName"
                                        value={formik?.values?.pricingProfileName || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round')}
                                    />

                                    {getFormErrorMessage('accountClassificationId')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                        Charges<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={15}
                                        id="pricingProfileName"
                                        placeholder="Enter Charges"
                                        name="pricingProfileName"
                                        value={formik?.values?.pricingProfileName || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round')}
                                    />

                                    {getFormErrorMessage('accountClassificationId')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" col-12 card ">
                        <div className="Form__Header" style={{ borderBottom: '1px solid #cecece' }}>
                            <h1>ALLOW EARLY PAYMENT</h1>
                        </div>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                        Early repayment<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="taxCalculationType"
                                        placeholder="Define Charges"
                                        options={[]}
                                        optionLabel="label"
                                        name="taxCalculationType"
                                        optionValue="value"
                                        value={formik.values.taxCalculationType || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('taxCalculationType') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('accountClassificationId')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                        Charges<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={15}
                                        id="pricingProfileName"
                                        placeholder="Enter Charges"
                                        name="pricingProfileName"
                                        value={formik?.values?.pricingProfileName || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round')}
                                    />

                                    {getFormErrorMessage('accountClassificationId')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" col-12 card">
                        <div className="Form__Header" style={{ borderBottom: '1px solid #cecece' }}>
                            <h1>DEFINE REPAYMENT SEQUNCE</h1>
                        </div>
                        <div className="Centre_Aligned_Buttons">
                            <Button className="Light_Button" label="Principal Amount" style={{ cursor: 'grabbing' }} />
                            <Button className="DarkGray_Button" label="Markup" style={{ cursor: 'grabbing' }} />
                            <Button className="DarkGray_Button" label="Fees" style={{ cursor: 'grabbing' }} />
                        </div>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                        Frquency<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="taxCalculationType"
                                        placeholder="Enter frequency"
                                        name="taxCalculationType"
                                        value={formik.values.taxCalculationType || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('taxCalculationType') }, 'Input__Round')}
                                    />

                                    {getFormErrorMessage('accountClassificationId')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                        Date & Time<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        type="date"
                                        id="pricingProfileName"
                                        placeholder="Enter Charges"
                                        name="pricingProfileName"
                                        value={formik?.values?.pricingProfileName || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round')}
                                    />

                                    {getFormErrorMessage('accountClassificationId')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                        Repayment Panelty<span className="Label__Required">*</span>
                                    </label>
                                    <div style={{ display: 'flex' }}>
                                        <Checkbox style={{ marginTop: '8px', marginRight: '10px' }} />
                                        <InputText
                                            id="pricingProfileName"
                                            placeholder="Enter Panelty Charges"
                                            name="pricingProfileName"
                                            value={formik?.values?.pricingProfileName || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round')}
                                        />
                                    </div>


                                    {getFormErrorMessage('accountClassificationId')}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default EditCustomerLoanProfileChecker;

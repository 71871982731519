import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';

function ViewTransactionProfiling({ activeIndex, handleStepClick, riskProfileData, handledAllStepsComplete, setStepData4, crpModelIdData }) {

    const [amountTransactionProfile, setAmountTransactionProfile] = useState();
    const [countTransactionProfile, setCountTransactionProfile] = useState();

    const getTransactionProfileLov = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getcrpdata/LKP_RP_TRANS_PROFILE');
        const amountLov = response.payLoad?.filter((item) => item.name.includes("Million"))
        const countLov = response.payLoad?.filter((item) => !item.name.includes("Million"))
        setAmountTransactionProfile(amountLov);
        setCountTransactionProfile(countLov);
    };
    useEffect(() => {
        getTransactionProfileLov();
    }, []); //eslint-disable-line

    useEffect(() => {
        if (riskProfileData) {
            const CTDropdownData = riskProfileData?.tblRpCrpDetails;

            if (CTDropdownData) {
                const CMHighId = [];
                const CMMediumId = [];
                const CMLowId = [];

                const DMHighId = [];
                const DMMediumId = [];
                const DMLowId = [];

                const CCHighId = [];
                const CCMediumId = [];
                const CCLowId = [];

                const DCHighId = [];
                const DCMediumId = [];
                const DCLowId = [];

                CTDropdownData.forEach((value) => {
                    if (value?.isActive === 'Y') {
                        const referenceIdToString = value.refId?.toString() || value?.refId;

                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'CM') {
                            if (value?.rpRating === 'H') {
                                CMHighId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyCrHighRisk', CMHighId);
                                formik.setFieldValue('expectedMonthlyCrOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                CMMediumId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyCrMediumRisk', CMMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                CMLowId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyCrLowRisk', CMLowId);
                            }
                        }
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'DM') {
                            if (value?.rpRating === 'H') {
                                DMHighId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyDrHighRisk', DMHighId);
                                formik.setFieldValue('expectedMonthlyDrOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                DMMediumId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyDrMediumRisk', DMMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                DMLowId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyDrLowRisk', DMLowId);
                            }
                        }
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'CC') {
                            if (value?.rpRating === 'H') {
                                CCHighId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyCrCountHighRisk', CCHighId);
                                formik.setFieldValue('expectedMonthlyCrCountOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                CCMediumId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyCrCountMediumRisk', CCMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                CCLowId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyCrCountLowRisk', CCLowId);
                            }
                        }
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'DC') {
                            if (value?.rpRating === 'H') {
                                DCHighId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyDrCountHighRisk', DCHighId);
                                formik.setFieldValue('expectedMonthlyDrCountOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                DCMediumId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyDrCountMediumRisk', DCMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                DCLowId.push(referenceIdToString);

                                formik.setFieldValue('expectedMonthlyDrCountLowRisk', DCLowId);
                            }
                        }
                    }
                });
            }
        }
    }, [riskProfileData]); //eslint-disable-line

    const formik = useFormik({
        initialValues: {
            stepNo: 4,
            expectedMonthlyCrHighRisk: [],
            expectedMonthlyCrMediumRisk: [],
            expectedMonthlyCrLowRisk: [],
            expectedMonthlyCrOverRideRule: 'false',
            expectedMonthlyDrHighRisk: [],
            expectedMonthlyDrMediumRisk: [],
            expectedMonthlyDrLowRisk: [],
            expectedMonthlyDrOverRideRule: 'false',
            expectedMonthlyCrCountHighRisk: [],
            expectedMonthlyCrCountMediumRisk: [],
            expectedMonthlyCrCountLowRisk: [],
            expectedMonthlyCrCountOverRideRule: 'false',
            expectedMonthlyDrCountHighRisk: [],
            expectedMonthlyDrCountMediumRisk: [],
            expectedMonthlyDrCountLowRisk: [],
            expectedMonthlyDrCountOverRideRule: 'false'
        },
        validate: (data) => {
            let errors = {};
            if (data?.expectedMonthlyCrHighRisk.length === 0) {
                errors.expectedMonthlyCrHighRisk = 'This field is required';
            }
            if (data?.expectedMonthlyCrMediumRisk.length === 0) {
                errors.expectedMonthlyCrMediumRisk = 'This field is required';
            }
            if (data?.expectedMonthlyCrLowRisk.length === 0) {
                errors.expectedMonthlyCrLowRisk = 'This field is required';
            }
            if (data?.expectedMonthlyDrHighRisk.length === 0) {
                errors.expectedMonthlyDrHighRisk = 'This field is required';
            }
            if (data?.expectedMonthlyDrMediumRisk.length === 0) {
                errors.expectedMonthlyDrMediumRisk = 'This field is required';
            }
            if (data?.expectedMonthlyDrLowRisk.length === 0) {
                errors.expectedMonthlyDrLowRisk = 'This field is required';
            }

            if (data?.expectedMonthlyCrCountHighRisk.length === 0) {
                errors.expectedMonthlyCrCountHighRisk = 'This field is required';
            }
            if (data?.expectedMonthlyCrCountMediumRisk.length === 0) {
                errors.expectedMonthlyCrCountMediumRisk = 'This field is required';
            }
            if (data?.expectedMonthlyCrCountLowRisk.length === 0) {
                errors.expectedMonthlyCrCountLowRisk = 'This field is required';
            }
            if (data?.expectedMonthlyDrCountHighRisk.length === 0) {
                errors.expectedMonthlyDrCountHighRisk = 'This field is required';
            }
            if (data?.expectedMonthlyDrCountMediumRisk.length === 0) {
                errors.expectedMonthlyDrCountMediumRisk = 'This field is required';
            }
            if (data?.expectedMonthlyDrCountLowRisk.length === 0) {
                errors.expectedMonthlyDrCountLowRisk = 'This field is required';
            }
            return errors;
        },
        onSubmit: async (data) => {

            data['crpId'] = riskProfileData?.rpCrpId;
            data['rpCrpModelId'] = crpModelIdData;

            data['reqMap'] = [
                {
                    DM: {
                        highRisk: data?.expectedMonthlyDrHighRisk,
                        medRisk: data?.expectedMonthlyDrMediumRisk,
                        lowRisk: data?.expectedMonthlyDrLowRisk,
                        overRideRule: data?.expectedMonthlyDrOverRideRule
                    }
                },
                {
                    CM: {
                        highRisk: data?.expectedMonthlyCrHighRisk,
                        medRisk: data?.expectedMonthlyCrMediumRisk,
                        lowRisk: data?.expectedMonthlyCrLowRisk,
                        overRideRule: data?.expectedMonthlyCrOverRideRule
                    }
                },
                {
                    DC: {
                        highRisk: data?.expectedMonthlyDrCountHighRisk,
                        medRisk: data?.expectedMonthlyDrCountMediumRisk,
                        lowRisk: data?.expectedMonthlyDrCountLowRisk,
                        overRideRule: data?.expectedMonthlyDrCountOverRideRule
                    }
                },
                {
                    CC: {
                        highRisk: data?.expectedMonthlyCrCountHighRisk,
                        medRisk: data?.expectedMonthlyCrCountMediumRisk,
                        lowRisk: data?.expectedMonthlyCrCountLowRisk,
                        overRideRule: data?.expectedMonthlyCrCountOverRideRule
                    }
                }
            ];
            delete data['expectedMonthlyDrHighRisk'];
            delete data['expectedMonthlyDrMediumRisk'];
            delete data['expectedMonthlyDrLowRisk'];
            delete data['expectedMonthlyDrOverRideRule'];
            delete data['expectedMonthlyCrHighRisk'];
            delete data['expectedMonthlyCrMediumRisk'];
            delete data['expectedMonthlyCrLowRisk'];
            delete data['expectedMonthlyCrOverRideRule'];
            delete data['expectedMonthlyDrCountHighRisk'];
            delete data['expectedMonthlyDrCountMediumRisk'];
            delete data['expectedMonthlyDrCountLowRisk'];
            delete data['expectedMonthlyDrCountOverRideRule'];
            delete data['expectedMonthlyCrCountHighRisk'];
            delete data['expectedMonthlyCrCountMediumRisk'];
            delete data['expectedMonthlyCrCountLowRisk'];
            delete data['expectedMonthlyCrCountOverRideRule'];

            handledAllStepsComplete(true);
            setStepData4(data);
            handleStepClick(activeIndex + 1);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    // const getFormErrorMessage = (name) => {
    //     return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    // };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {/* EXPECTED MONTHLY CREDIT AMOUNT */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>EXPECTED MONTHLY CREDIT AMOUNT</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyCrHighRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyCrHighRisk') }, 'Label__Text')}>
                                    High Risk<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyCrHighRisk"
                                    placeholder="Select High Risk"
                                    options={amountTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyCrHighRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyCrHighRisk || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyCrHighRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyCrMediumRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyCrMediumRisk') }, 'Label__Text')}>
                                    Medium Risk<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyCrMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={amountTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyCrMediumRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyCrMediumRisk || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyCrMediumRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyCrLowRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyCrLowRisk') }, 'Label__Text')}>
                                    Low Risk<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyCrLowRisk"
                                    placeholder="Select Low Risk"
                                    options={amountTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyCrLowRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyCrLowRisk || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyCrLowRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="expectedMonthlyCrOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="expectedMonthlyCrOverRideRule" name="expectedMonthlyCrOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.expectedMonthlyCrOverRideRule === 'false'} disabled />
                                    <label htmlFor="expectedMonthlyCrOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="expectedMonthlyCrOverRideRule" name="expectedMonthlyCrOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.expectedMonthlyCrOverRideRule === 'true'} disabled />
                                    <label htmlFor="expectedMonthlyCrOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* EXPECTED MONTHLY DEBIT AMOUNT */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>EXPECTED MONTHLY DEBIT AMOUNT</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyDrHighRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyDrHighRisk') }, 'Label__Text')}>
                                    High Risk<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyDrHighRisk"
                                    placeholder="Select High Risk"
                                    options={amountTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyDrHighRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyDrHighRisk || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyDrHighRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyDrMediumRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyDrMediumRisk') }, 'Label__Text')}>
                                    Medium Risk<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyDrMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={amountTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyDrMediumRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyDrMediumRisk || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyDrMediumRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyDrLowRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyDrLowRisk') }, 'Label__Text')}>
                                    Low Risk<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyDrLowRisk"
                                    placeholder="Select Low Risk"
                                    options={amountTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyDrLowRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyDrLowRisk || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyDrLowRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="expectedMonthlyDrOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="expectedMonthlyDrOverRideRule" name="expectedMonthlyDrOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.expectedMonthlyDrOverRideRule === 'false'} disabled />
                                    <label htmlFor="expectedMonthlyDrOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="expectedMonthlyDrOverRideRule" name="expectedMonthlyDrOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.expectedMonthlyDrOverRideRule === 'true'} disabled />
                                    <label htmlFor="expectedMonthlyDrOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* EXPECTED MONTHLY CREDIT COUNT */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>EXPECTED MONTHLY CREDIT COUNT</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyCrCountHighRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyCrCountHighRisk') }, 'Label__Text')}>
                                    High Risk<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyCrCountHighRisk"
                                    placeholder="Select High Risk"
                                    options={countTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyCrCountHighRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyCrCountHighRisk || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyCrCountHighRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyCrCountMediumRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyCrCountMediumRisk') }, 'Label__Text')}>
                                    Medium Risk<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyCrCountMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={countTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyCrCountMediumRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyCrCountMediumRisk || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyCrCountMediumRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyCrCountLowRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyCrCountLowRisk') }, 'Label__Text')}>
                                    Low Risk<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyCrCountLowRisk"
                                    placeholder="Select Low Risk"
                                    options={countTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyCrCountLowRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyCrCountLowRisk || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyCrCountLowRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="expectedMonthlyCrCountOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton
                                        inputId="expectedMonthlyCrCountOverRideRule"
                                        name="expectedMonthlyCrCountOverRideRule"
                                        value={'false'}
                                        onChange={formik.handleChange}
                                        checked={formik.values.expectedMonthlyCrCountOverRideRule === 'false'}
                                        disabled
                                    />
                                    <label htmlFor="expectedMonthlyCrCountOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton
                                        inputId="expectedMonthlyCrCountOverRideRule"
                                        name="expectedMonthlyCrCountOverRideRule"
                                        value={'true'}
                                        onChange={formik.handleChange}
                                        checked={formik.values.expectedMonthlyCrCountOverRideRule === 'true'}
                                        disabled
                                    />
                                    <label htmlFor="expectedMonthlyCrCountOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* EXPECTED MONTHLY DEBIT COUNT */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>EXPECTED MONTHLY DEBIT COUNT</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyDrCountHighRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyDrCountHighRisk') }, 'Label__Text')}>
                                    High Risk<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyDrCountHighRisk"
                                    placeholder="Select High Risk"
                                    options={countTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyDrCountHighRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyDrCountHighRisk || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyDrCountHighRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyDrCountMediumRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyDrCountMediumRisk') }, 'Label__Text')}>
                                    Medium Risk<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyDrCountMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={countTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyDrCountMediumRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyDrCountMediumRisk || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyDrCountMediumRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expectedMonthlyDrCountLowRisk" className={classNames({ 'p-error': isFormFieldValid('expectedMonthlyDrCountLowRisk') }, 'Label__Text')}>
                                    Low Risk<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="expectedMonthlyDrCountLowRisk"
                                    placeholder="Select Low Risk"
                                    options={countTransactionProfile}
                                    optionLabel="name"
                                    name="expectedMonthlyDrCountLowRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.expectedMonthlyDrCountLowRisk || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expectedMonthlyDrCountLowRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="expectedMonthlyDrCountOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton
                                        inputId="expectedMonthlyDrCountOverRideRule"
                                        name="expectedMonthlyDrCountOverRideRule"
                                        value={'false'}
                                        onChange={formik.handleChange}
                                        checked={formik.values.expectedMonthlyDrCountOverRideRule === 'false'}
                                        disabled
                                    />
                                    <label htmlFor="expectedMonthlyDrCountOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton
                                        inputId="expectedMonthlyDrCountOverRideRule"
                                        name="expectedMonthlyDrCountOverRideRule"
                                        value={'true'}
                                        onChange={formik.handleChange}
                                        checked={formik.values.expectedMonthlyDrCountOverRideRule === 'true'}
                                        disabled
                                    />
                                    <label htmlFor="expectedMonthlyDrCountOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 
                    <div className="Down__Btn">
                        <Button disabled={loading} label="Next" className="Btn__Dark" />
                    </div> */}
                </form>
            </div>
        </>
    );
}
export default ViewTransactionProfiling;

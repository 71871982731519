import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { baseURL } from '../../../../Config';
import { toast } from 'react-toastify';

function AccountStatementDialog({ onHideAccountStatement, mobileNo, agentId, cnic, accountNumber }) {
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [loading, setloading] = useState(false);

    const dispatch = useDispatch();

    const date = new Date();

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('This field is required')
            .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email format'),
        fromDate: Yup.date().required('This field is required').nullable(),
        toDate: Yup.date().required('This field is required').min(Yup.ref('fromDate'), 'Date To has to be more than Date From').nullable()
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            email: '',
            mobileNumber: mobileNo,
            fromDate: '',
            toDate: '',
            text: 'test',
            customerId: agentId,
            cnic: cnic,
            accountNumber: accountNumber,
            download: '',
            subject: 'test'
        },

        onSubmit: async (data) => {
            data['download'] = false;

            setloading(true);

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/account/v1/agentaccount/generateaccountstatement', true, false, 'accountsmanagement'));
            setloading(false);
            onHideAccountStatement();
        }
    });

    const handleDownload = async (e) => {
        e.preventDefault();

        const data = {
            email: formik.values.email,
            mobileNumber: mobileNo,
            fromDate: formik.values.fromDate,
            toDate: formik.values.toDate,
            download: '',
            text: 'test',
            customerId: agentId,
            cnic: cnic,
            accountNumber: accountNumber,
            download: '',
            subject: 'test'
        };

        if (formik.values.email === '') {
            toast.error('Email is required');
            return;
        }
        if (formik.values.fromDate === '') {
            toast.error('FromDate is required');
            return;
        }
        if (formik.values.toDate === '') {
            toast.error('ToDate is required');
            return;
        }

        data['download'] = 'true';
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    ...data
                },
                additionalInformation: [],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/account/v1/agentaccount/generateaccountstatement', true, false, 'accountsmanagement'));

        //       const downloadURL = `${baseURL}:8080${res?.payLoad?.path?.replace("/opt/wildfly/standalone/documents/","/document/")}`;

        // // Create a link element
        // const link = document.createElement('a');
        // link.href = downloadURL;
        // link.setAttribute('download', 'filename.pdf');

        // document.body.appendChild(link);
        // link.click();

        // // Clean up
        // document.body.removeChild(link);
        window.open(`${baseURL}${res?.payLoad?.path?.replace('/opt/wildfly/standalone/documents/', '/document/')}`);
        setloading(false);
        onHideAccountStatement();
    };
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleCancel = (e) => {
        e.preventDefault();
        onHideAccountStatement();
        formik.resetForm();
    };

    return (
        <>
            <div className="card Card__Round">
                <form className="p-fluid p-mt-2" onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') }, 'Label__Text')}>
                                    Email<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    type="email"
                                    maxLength={100}
                                    id="email"
                                    placeholder="Please Enter Email"
                                    name="email"
                                    value={formik?.values?.email || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('email') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('email')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="fromDate" className={classNames({ 'p-error': isFormFieldValid('fromDate') }, 'Label__Text')}>
                                    Date From<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    type="date"
                                    maxLength={20}
                                    id="fromDate"
                                    placeholder="Please Enter fromDate"
                                    name="fromDate"
                                    value={formik?.values?.fromDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('fromDate') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('fromDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="toDate" className={classNames({ 'p-error': isFormFieldValid('toDate') }, 'Label__Text')}>
                                    Date to<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    type="date"
                                    maxLength={20}
                                    id="toDate"
                                    placeholder="Please Enter toDate"
                                    name="toDate"
                                    value={formik?.values?.toDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('toDate') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('toDate')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Submit" className="Btn__Add" />
                        <Button label="Download" className="Btn__Add" onClick={(e) => handleDownload(e)} />
                        <Button label="Cancel" className="Btn__cancel" onClick={(e) => handleCancel(e)} />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AccountStatementDialog;

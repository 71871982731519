import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../../../services/GetTemplate';

function ViewBulletLoan({ lendingData }) {
    const [frequencyPeriodData, setFrequencyPeriodData] = useState([]);

    /////////////////////////////////////////
    // const [bulletLoanTableData, setBulletLoanTableData] = useState([]);
    const [bulletLoanData, setBulletLoanData] = useState({
        noOfDays: '',
        markupValue: '',
        apr: '',

        gracePeriod: '',
        noOfDaysGracePeriod: '',

        latePayment: '',
        latePaymentType: '',
        latePaymentCharges: '',
        latePaymentOn: '',

        frequencyId: '',

        earlyPayment: '',
        earlyPaymentType: '',
        earlyPaymentCharges: '',
        earlyPaymentOn: '',

        downPayment: '',
        downPaymentCharges: ''
    });
    const [bulletLoanError, setBulletLoanError] = useState({
        noOfDays: '',
        markupValue: '',
        apr: '',

        gracePeriod: '',
        noOfDaysGracePeriod: '',

        latePayment: '',
        latePaymentType: '',
        latePaymentCharges: '',
        latePaymentOn: '',

        frequencyId: '',

        earlyPayment: '',
        earlyPaymentType: '',
        earlyPaymentCharges: '',
        earlyPaymentOn: '',

        downPayment: '',
        downPaymentCharges: ''
    });
    //////////////////////////////

    const [buttons, setButtons] = useState([
        { label: 'Principal Amount', sequence: 1 },
        { label: 'Markup', sequence: 2 },
        { label: 'Fees', sequence: 3 }
    ]);

    const getFrequencyPeriodData = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getdatalov/LKP_FREQUENCY_PERIOD');
        setFrequencyPeriodData(response?.payLoad);
    };

    useEffect(() => {
        getFrequencyPeriodData();
    }, []); // eslint-disable-line

    useEffect(() => {
        let bulletLoan = [];
        if (lendingData) {
            if (lendingData?.tblLmsRepaymentMethods?.length > 0) {
                bulletLoan = lendingData?.tblLmsRepaymentMethods
                    ?.filter((itm) => itm?.isActive === 'Y')
                    ?.map((item) => {
                        return {
                            noOfDays: item?.noOfDays?.toString(),
                            markupValue: item?.markupValue,
                            apr: item?.apr,

                            gracePeriod: item?.gracePeriod === 'Y' ? true : false,
                            noOfDaysGracePeriod: item?.noOfGraceDays,

                            latePayment: item?.latePayment === 'Y' ? true : false,
                            latePaymentType: item?.latePaymentType?.toString(),
                            latePaymentCharges: item?.latePaymentCharges,
                            latePaymentOn: item?.latePaymentOn?.toString(),

                            earlyPayment: item?.earlyPayment === 'Y' ? true : false,
                            earlyPaymentType: item?.earlyPaymentType?.toString(),
                            earlyPaymentCharges: item?.earlyPaymentCharges,
                            earlyPaymentOn: item?.earlyPaymentOn?.toString(),

                            frequencyId: item?.lkpFrequencyPeriod?.frequencyPeriodId?.toString(),

                            downPayment: item?.downPayment === 'Y' ? 'Y' : 'N',
                            downPaymentCharges: item?.downPaymentCharges
                        };
                    });
                setBulletLoanData(bulletLoan[0]);
            }

            let pA = [];
            if (lendingData?.tblLmsRepaymentMethods?.length > 0) {
                Object.keys(lendingData?.tblLmsRepaymentMethods[0]).forEach((key) => {
                    if (key === 'seqPrincipalAmount') {
                        pA.push({ label: key, value: lendingData?.tblLmsRepaymentMethods[0][key] });
                    }

                    if (key === 'seqMarkup') {
                        pA.push({ label: key, value: lendingData?.tblLmsRepaymentMethods[0][key] });
                    }

                    if (key === 'seqLateFee') {
                        pA.push({ label: key, value: lendingData?.tblLmsRepaymentMethods[0][key] });
                    }
                });

                const updatedDefinePayment = pA?.map((item) => {
                    return {
                        label: item.label.replace('seq', ''),
                        sequence: item.value
                    };
                });

                updatedDefinePayment?.sort((a, b) => a.sequence - b.sequence);
                setButtons(updatedDefinePayment);
            }
        }
    }, [lendingData]); // eslint-disable-line

    const validationSchema = Yup.object().shape({});

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {}
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    // const handleDrop = (e, targetLabel) => {
    //     e.preventDefault();

    //     const draggedLabel = e.dataTransfer.getData('text/plain');
    //     const updatedButtons = [...buttons];

    //     const draggedButton = updatedButtons.find((button) => button.label === draggedLabel);
    //     const targetButton = updatedButtons.find((button) => button.label === targetLabel);

    //     if (draggedButton && targetButton) {
    //         // Swap the sequence numbers
    //         const tempSequence = draggedButton.sequence;
    //         draggedButton.sequence = targetButton.sequence;
    //         targetButton.sequence = tempSequence;

    //         // Sort buttons based on sequence
    //         updatedButtons.sort((a, b) => a.sequence - b.sequence);

    //         setButtons(updatedButtons);
    //     }
    // };

    const downPaymentLov = [
        {
            name: 'Yes',
            lovId: 'Y'
        },
        {
            name: 'No',
            lovId: 'N'
        }
    ];

    //////////////////////////new UI code /////////////////////
    const loanTenureNOD = [
        { name: '90 Days', lovId: '90' },
        { name: '180 Days', lovId: '180' },
        { name: '270 Days', lovId: '270' },
        { name: '360 Days', lovId: '360' }
    ];

    const typeLov = [
        { name: 'FIXED', lovId: 'F' },
        { name: 'PERCENTAGE', lovId: 'P' }
    ];

    const paymentOnLov = [
        { name: 'Outstanding Principal', lovId: 'O' },
        { name: 'Markup', lovId: 'M' }
    ];

    // /////////////////////////////////////////////////////////////
    // const actionBodyTemplate = (rowData, rowIndex) => {
    //     return (
    //         <>
    //             <Button tooltip="Edit" tooltipOptions={{ position: 'top' }} icon="pi pi-pencil" onClick={(e) => handledEditData(e, rowData, rowIndex)} className="p-button-rounded p-button-primary" style={{ border: 'none' }} />
    //             <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteData(e, rowData, rowIndex)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
    //         </>
    //     );
    // };

    // const handledDeleteData = (e, rowData, rowIndex) => {
    //     e.preventDefault();
    //     const newFilterData = bulletLoanTableData?.filter((value, index) => index !== rowIndex?.rowIndex);
    //     setBulletLoanTableData(newFilterData);
    // };

    // const handledEditData = (e, rowData, rowIndex) => {
    //     e.preventDefault();
    //     const newFilterData = bulletLoanTableData?.filter((value, index) => index !== rowIndex?.rowIndex);
    //     setBulletLoanData(newFilterData);
    // };

    // const handledAdd = (e) => {
    //     // e.preventDefault();

    //     const errors = {
    //         noOfDays: '',
    //         markupValue: '',
    //         apr: '',

    //         gracePeriod: '',
    //         noOfDaysGracePeriod: '',

    //         latePayment: '',
    //         latePaymentType: '',
    //         latePaymentCharges: '',
    //         latePaymentOn: '',

    //         frequencyId: '',

    //         earlyPayment: '',
    //         earlyPaymentType: '',
    //         earlyPaymentCharges: '',
    //         earlyPaymentOn: '',

    //         downPayment: '',
    //         downPaymentCharges: '',

    //         seqPrincipalAmount: '',
    //         seqMarkup: '',
    //         seqLateFee: ''
    //     };

    //     if (bulletLoanData?.noOfDays === '') {
    //         errors.noOfDays = 'This field is required.';
    //     }

    //     if (bulletLoanData?.markupValue === '') {
    //         errors.markupValue = 'This field is required.';
    //     }

    //     if (bulletLoanData?.apr === '') {
    //         errors.apr = 'This field is required.';
    //     }

    //     if (bulletLoanData?.gracePeriod === true) {
    //         if (bulletLoanData?.noOfDaysGracePeriod === '') {
    //             errors.noOfDaysGracePeriod = 'This field is required';
    //         }
    //     }

    //     if (bulletLoanData?.latePayment === true) {
    //         if (bulletLoanData?.latePaymentType === '') {
    //             errors.latePaymentType = 'This field is required';
    //         }

    //         if (bulletLoanData?.latePaymentCharges === '') {
    //             errors.latePaymentCharges = 'This field is required';
    //         }

    //         if (bulletLoanData?.latePaymentOn === '') {
    //             errors.latePaymentOn = 'This field is required';
    //         }
    //     }

    //     if (bulletLoanData?.frequencyId === '') {
    //         errors.frequencyId = 'This field is required';
    //     }

    //     if (bulletLoanData?.earlyPayment === true) {
    //         if (bulletLoanData?.earlyPaymentType === '') {
    //             errors.earlyPaymentType = 'This field is required';
    //         }

    //         if (bulletLoanData?.earlyPaymentCharges === '') {
    //             errors.earlyPaymentCharges = 'This field is required';
    //         }

    //         if (bulletLoanData?.earlyPaymentOn === '') {
    //             errors.earlyPaymentOn = 'This field is required';
    //         }
    //     }

    //     if (bulletLoanData?.downPayment === 'Y') {
    //         if (bulletLoanData?.downPaymentCharges === '') {
    //             errors.downPaymentCharges = 'This field is required';
    //         }
    //     }

    //     setBulletLoanError(errors);

    //     const sequenceObject = buttons?.reduce((result, item) => {
    //         const label = item.label.replace(/\s+/g, ''); // Remove spaces from label
    //         const seqKey = `seq${label}`;
    //         result[seqKey] = item.sequence;
    //         return result;
    //     }, {});

    //     // If there are no errors, you can submit the form or perform further actions.
    //     if (Object.values(errors).every((error) => error === '')) {
    //         const newBulletLoanTableData = {
    //             noOfDays: bulletLoanData?.noOfDays,
    //             markupValue: bulletLoanData?.markupValue,
    //             apr: bulletLoanData?.apr,

    //             gracePeriod: bulletLoanData?.gracePeriod,
    //             noOfDaysGracePeriod: bulletLoanData?.noOfDaysGracePeriod,

    //             latePayment: bulletLoanData?.latePayment,
    //             latePaymentType: bulletLoanData?.latePaymentType,
    //             latePaymentCharges: bulletLoanData?.latePaymentCharges,
    //             latePaymentOn: bulletLoanData?.latePaymentOn,

    //             frequencyId: bulletLoanData?.frequencyId || '',

    //             earlyPayment: bulletLoanData?.earlyPayment,
    //             earlyPaymentType: bulletLoanData?.earlyPaymentType,
    //             earlyPaymentCharges: bulletLoanData?.earlyPaymentCharges,
    //             earlyPaymentOn: bulletLoanData?.earlyPaymentOn,

    //             downPayment: bulletLoanData?.downPayment === 'Y' ? true : false,
    //             downPaymentCharges: bulletLoanData?.downPaymentCharges,

    //             repaymentTypeCode: stepData1?.facilityCode
    //         };

    //         const combinedObject = { ...newBulletLoanTableData, ...sequenceObject };
    //         setBulletLoanTableData([...bulletLoanTableData, combinedObject]);

    //         // setBulletLoanData({
    //         //     noOfDays: '',
    //         //     markupValue: '',
    //         //     apr: '',

    //         //     gracePeriod: '',
    //         //     noOfDaysGracePeriod: '',

    //         //     latePayment: '',
    //         //     latePaymentType: '',
    //         //     latePaymentCharges: '',
    //         //     latePaymentOn: '',

    //         //     frequencyId: '',

    //         //     earlyPayment: '',
    //         //     earlyPaymentType: '',
    //         //     earlyPaymentCharges: '',
    //         //     earlyPaymentOn: '',

    //         //     downPayment: '',
    //         //     downPaymentCharges: ''
    //         // });

    //         setButtons([
    //             { label: 'Principal Amount', sequence: 1 },
    //             { label: 'Markup', sequence: 2 },
    //             { label: 'Fees', sequence: 3 }
    //         ]);
    //     }
    // };

    const handledData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        const checked = e.target.checked;
        const type = e.target.type;

        setBulletLoanData({ ...bulletLoanData, [name]: type === 'checkbox' ? checked : value });

        // Clear the error message for the field being edited
        setBulletLoanError({
            ...bulletLoanError,
            [name]: ''
        });
    };
    //////////////////////////////////////////////////////////

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>REPAYMENT METHOD/EDIT</h1>
            </div>
            <div className="card Card__Round">
                <div className="Form__Header flex align-items-center justify-content-between">
                    <h1>LOAN TENURE </h1>
                    {/* <Button
                        icon="pi pi-plus"
                        className="p-button-rounded"
                        aria-label="Filter"
                        tooltip="Add New Repayment"
                        onClick={(e) => handledAdd(e)}
                    /> */}
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="noOfDays" className={classNames({ 'p-error': isFormFieldValid('noOfDays') }, 'Label__Text')}>
                                    No. Of Days<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="noOfDays"
                                    placeholder="Select No. Of Days"
                                    options={loanTenureNOD}
                                    optionLabel="name"
                                    name="noOfDays"
                                    filter
                                    disabled={true}
                                    optionValue="lovId"
                                    value={bulletLoanData?.noOfDays || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('noOfDays') }, 'Dropdown__Round')}
                                />
                                <small className="p-error">{bulletLoanError?.noOfDays}</small>
                                {getFormErrorMessage('noOfDays')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="markupValue" className={classNames({ 'p-error': isFormFieldValid('markupValue') }, 'Label__Text')}>
                                    Markup value <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={4}
                                    id="markupValue"
                                    placeholder="Enter Markup Value"
                                    name="markupValue"
                                    disabled={true}
                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                    value={bulletLoanData?.markupValue || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('markupValue') }, 'Input__Round')}
                                />
                                <small className="p-error">{bulletLoanError?.markupValue}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="apr" className={classNames({ 'p-error': isFormFieldValid('apr') }, 'Label__Text')}>
                                    APR <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="apr"
                                    placeholder="Enter APR"
                                    name="apr"
                                    maxLength={4}
                                    disabled={true}
                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                    value={bulletLoanData?.apr || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('apr') }, 'Input__Round')}
                                />
                                <small className="p-error">{bulletLoanError?.apr}</small>
                            </div>
                        </div>
                    </div>
                    <div className="Form__Header col-12">
                        <h1>GRACE PERIOD </h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="gracePeriod" name="gracePeriod" value={bulletLoanData?.gracePeriod || ''} checked={bulletLoanData?.gracePeriod} onChange={handledData} style={{ marginTop: '10px' }} disabled />
                                    <label htmlFor="gracePeriod" className={classNames({ 'p-error': isFormFieldValid('gracePeriod') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Grace Period
                                    </label>
                                </div>
                                <small className="p-error">{bulletLoanError?.gracePeriod}</small>
                            </div>
                        </div>
                        {bulletLoanData?.gracePeriod === true && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="noOfDaysGracePeriod" className={classNames({ 'p-error': isFormFieldValid('noOfDaysGracePeriod') }, 'Label__Text')}>
                                        Number of Days <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={4}
                                        id="noOfDaysGracePeriod"
                                        placeholder="Enter Number of Days"
                                        name="noOfDaysGracePeriod"
                                        keyfilter={'int'}
                                        disabled={true}
                                        value={bulletLoanData?.noOfDaysGracePeriod || ''}
                                        onChange={handledData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('noOfDaysGracePeriod') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{bulletLoanError?.noOfDaysGracePeriod}</small>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="Form__Header col-12">
                        <h1>LATE PAYMENT </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="latePayment" name="latePayment" value={bulletLoanData?.latePayment || ''} checked={bulletLoanData?.latePayment} onChange={handledData} style={{ marginTop: '10px' }} disabled />
                                    <label htmlFor="latePayment" className={classNames({ 'p-error': isFormFieldValid('latePayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Late payment
                                    </label>
                                </div>
                                <small className="p-error">{bulletLoanError?.latePayment}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        {bulletLoanData?.latePayment === true && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentType" className={classNames({ 'p-error': isFormFieldValid('latePaymentType') }, 'Label__Text')}>
                                            Type<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="latePaymentType"
                                            placeholder="Select Type"
                                            options={typeLov}
                                            optionLabel="name"
                                            name="latePaymentType"
                                            filter
                                            optionValue="lovId"
                                            disabled={true}
                                            value={bulletLoanData?.latePaymentType || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('latePaymentType') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{bulletLoanError?.latePaymentType}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentCharges" className={classNames({ 'p-error': isFormFieldValid('latePaymentCharges') }, 'Label__Text')}>
                                            Charges <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="latePaymentCharges"
                                            placeholder="Enter charges"
                                            name="latePaymentCharges"
                                            maxLength={8}
                                            keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                            disabled={true}
                                            value={bulletLoanData?.latePaymentCharges || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('latePaymentCharges') }, 'Input__Round')}
                                        />
                                        <small className="p-error">{bulletLoanError?.latePaymentCharges}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentOn" className={classNames({ 'p-error': isFormFieldValid('latePaymentOn') }, 'Label__Text')}>
                                            Late Payment On<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="latePaymentOn"
                                            placeholder="Select Late Payment On"
                                            options={paymentOnLov}
                                            optionLabel="name"
                                            name="latePaymentOn"
                                            filter
                                            optionValue="lovId"
                                            disabled={true}
                                            value={bulletLoanData?.latePaymentOn || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('latePaymentOn') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{bulletLoanError?.latePaymentOn}</small>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {/* //////////////////////////////////////////////////// */}
                    <div className="Form__Header col-12">
                        <h1>REPAYMENT SCHEDULE </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="frequencyId" className={classNames({ 'p-error': isFormFieldValid('frequencyId') }, 'Label__Text')}>
                                    Frequency Of Payment<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="frequencyId"
                                    placeholder="Select Frequency Of Payment"
                                    options={frequencyPeriodData}
                                    optionLabel="name"
                                    name="frequencyId"
                                    disabled={true}
                                    filter
                                    optionValue="lovId"
                                    value={bulletLoanData?.frequencyId || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('frequencyId') }, 'Dropdown__Round')}
                                />
                                <small className="p-error">{bulletLoanError?.frequencyId}</small>
                            </div>
                        </div>
                    </div>
                    {/* ///////////////////////////////////////////////////// */}

                    <div className="Form__Header col-12">
                        <h1>ALLOW EARLY REPAYMENT </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="earlyPayment" name="earlyPayment" value={bulletLoanData?.earlyPayment || ''} checked={bulletLoanData?.earlyPayment} onChange={handledData} style={{ marginTop: '10px' }} disabled />
                                    <label htmlFor="earlyPayment" className={classNames({ 'p-error': isFormFieldValid('earlyPayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Early Payment
                                    </label>
                                </div>
                                <small className="p-error">{bulletLoanError?.earlyPayment}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        {bulletLoanData?.earlyPayment === true && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="earlyPaymentType" className={classNames({ 'p-error': isFormFieldValid('earlyPaymentType') }, 'Label__Text')}>
                                            Type<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="earlyPaymentType"
                                            placeholder="Select Type"
                                            options={typeLov}
                                            optionLabel="name"
                                            name="earlyPaymentType"
                                            disabled={true}
                                            filter
                                            optionValue="lovId"
                                            value={bulletLoanData?.earlyPaymentType || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('earlyPaymentType') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{bulletLoanError?.earlyPaymentType}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="earlyPaymentCharges" className={classNames({ 'p-error': isFormFieldValid('earlyPaymentCharges') }, 'Label__Text')}>
                                            Charges <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="earlyPaymentCharges"
                                            placeholder="Enter charges"
                                            name="earlyPaymentCharges"
                                            maxLength={8}
                                            disabled={true}
                                            keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                            value={bulletLoanData?.earlyPaymentCharges || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('earlyPaymentCharges') }, 'Input__Round')}
                                        />
                                        <small className="p-error">{bulletLoanError?.earlyPaymentCharges}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="earlyPaymentOn" className={classNames({ 'p-error': isFormFieldValid('earlyPaymentOn') }, 'Label__Text')}>
                                            Early Payment On<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="earlyPaymentOn"
                                            placeholder="Select Early Payment On"
                                            options={paymentOnLov}
                                            optionLabel="name"
                                            name="earlyPaymentOn"
                                            filter
                                            optionValue="lovId"
                                            disabled={true}
                                            value={bulletLoanData?.earlyPaymentOn || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('earlyPaymentOn') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{bulletLoanError?.earlyPaymentOn}</small>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="Form__Header col-12">
                        <h1>DOWN PAYMENT </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="downPayment" className={classNames({ 'p-error': isFormFieldValid('downPayment') }, 'Label__Text')}>
                                    Select Down Payment<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="downPayment"
                                    placeholder="Select Down Payment"
                                    options={downPaymentLov}
                                    optionLabel="name"
                                    name="downPayment"
                                    filter
                                    optionValue="lovId"
                                    disabled={true}
                                    value={bulletLoanData?.downPayment || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('downPayment') }, 'Dropdown__Round')}
                                />
                                <small className="p-error">{bulletLoanError?.downPayment}</small>
                            </div>
                        </div>
                        {bulletLoanData?.downPayment === 'Y' && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="downPaymentCharges" className={classNames({ 'p-error': isFormFieldValid('downPaymentCharges') }, 'Label__Text')}>
                                        Charges <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="downPaymentCharges"
                                        placeholder="Enter Down Payment Charges"
                                        name="downPaymentCharges"
                                        disabled={true}
                                        maxLength={8}
                                        keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                        value={bulletLoanData?.downPaymentCharges || ''}
                                        onChange={handledData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('downPaymentCharges') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{bulletLoanError?.downPaymentCharges}</small>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="Form__Header">
                        <h1>DEFINE REPAYMENT SEQUENCE </h1>
                    </div>
                    <div className="Centre_Aligned_Buttons" onDragOver={handleDragOver}>
                        {buttons?.map((button) => (
                            <Button
                                key={button.label}
                                className="DraggableButton Light_Button"
                                onClick={(e) => e.preventDefault()}
                                label={`${button.label}`}
                                disabled
                                // draggable
                                // onDragStart={(e) => handleDragStart(e, button.label)}
                                // onDrop={(e) => handleDrop(e, button.label)}
                            />
                        ))}
                    </div>
                    {/* <div className="grid">
                        <div className="col-12">
                            <div className="card mt-6">
                                <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={bulletLoanTableData}>
                                    <Column field="noOfDays" header="No. Of Days" />
                                    <Column field="markupValue" header="markup value" />
                                    <Column field="apr" header="APR" />
                                    <Column field="status" header="Status" />
                                    <Column header="Actions" body={actionBodyTemplate} style={{ textAlign: 'center' }} className="Table__ActionHeading__CentredAlign" />
                                </DataTable>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} style={{ margin: '20px' }} onClick={() => handledAdd()} label={'Next'} className="Btn__Dark" />
                    </div> */}
                </form>
            </div>
        </>
    );
}
export default ViewBulletLoan;

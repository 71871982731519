import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../services/PostTemplate';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../services/GetTemplate';

function AddEditkycAttribute({ onHideAttributeDialog, editable, setEditable, kycAttributeRowData }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-save');
    const [validatorLov, setValidatorLov] = useState([]);

    const dispatch = useDispatch();

    const getKycAttributeData = async () => {
        setloading(true);
        const keyData = kycAttributeRowData;
        setloading(false);

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
        }

        formik.setFieldValue('isUnique', keyData?.isUnique === 'Y' ? true : false);
        formik.setFieldValue('isSearchable', keyData?.isSearchable === 'Y' ? true : false);
        formik.setFieldValue('isEditable', keyData?.isEditable === 'Y' ? true : false);
        formik.setFieldValue('validatorId', keyData?.tblValidator?.validatorId.toString());
    };

    useEffect(() => {
        if (editable) {
            if (kycAttributeRowData !== undefined || kycAttributeRowData !== null) {
                getKycAttributeData();
            }
        }
    }, [kycAttributeRowData]); // eslint-disable-line

    useEffect(() => {
        if (!editable) {
            formik.resetForm();
        }
    }, [editable]); // eslint-disable-line

    const handleCancel = (e) => {
        e.preventDefault();
        onHideAttributeDialog();
        setEditable(false);
    };

    const validationSchema = Yup.object().shape({
        // attributeName: Yup.string().required('This field is required').matches(/^[a-zA-Z][0-9a-zA-Z]*$/, 'Only alphanumeric characters are allowed and the first character must be a letter'),
        validatorId: Yup.string().required('This Field is required.')
        // attributeCode: Yup.string().required('This field is required').matches(/^[a-zA-Z][0-9a-zA-Z]*$/, 'Only alphanumeric characters are allowed and the first character must be a letter')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            attributeName: '',
            attributeCode: '',
            validatorId: '',
            isEditable: '',
            isSearchable: '',
            isUnique: ''
        },

        validate: (data) => {
            let errors = {};

            ////////////////////////attributeName/////////////////////
            if (!data?.attributeName) {
                errors.attributeName = 'This field is required';
            } else if (data?.attributeName === '') {
                errors.attributeName = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.attributeName)) {
                errors.attributeName = 'Leading and trailing space is not allowed';
            } else if (!/^[a-zA-Z](?:[a-zA-Z0-9\s]*-?[a-zA-Z0-9\s]*)?$/.test(data?.attributeName)) {
                errors.attributeName = 'Invalid Attribute Name format';
            } 

            //////////////////////////attributeCode////////////////////////
            if (!data?.attributeCode) {
                errors.attributeCode = 'This field is required';
            } else if (data?.attributeCode === '') {
                errors.attributeCode = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.attributeCode)) {
                errors.attributeCode = 'Leading and trailing space is not allowed';
            } if (!/^[a-zA-Z0-9\s]*$/.test(data?.attributeCode)) {
                errors.attributeCode = 'Special characters are not allowed';
            }

            return errors;
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['validatorId'] = parseInt(data?.validatorId);
            data['isEditable'] = data.isEditable ? 'Y' : 'N';
            data['isSearchable'] = data.isSearchable ? 'Y' : 'N';
            data['isUnique'] = data.isUnique ? 'Y' : 'N';

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            if (editable === true) {
                data['kycAttributesId'] = parseInt(kycAttributeRowData?.kycAttributesId);
                const res = await dispatch(handlePostRequest(newData, '/account/v1/kycattribute/updatekycattribute', true, false));
                if (res?.responseCode === '010000') {
                    formik.resetForm();
                    onHideAttributeDialog();
                }
            } else {
                const res = await dispatch(handlePostRequest(newData, '/account/v1/kycattribute/createkycattribute', true, false));
                if (res?.responseCode === '010000') {
                    formik.resetForm();
                    onHideAttributeDialog();
                } else {
                    formik.setFieldValue('validatorId', data?.validatorId.toString());
                    formik.setFieldValue('isEditable', data.isEditable === 'Y' ? true : false);
                    formik.setFieldValue('isSearchable', data.isSearchable === 'Y' ? true : false);
                    formik.setFieldValue('isUnique', data.isUnique === 'Y' ? true : false);
                }
            }
            setloading(false);
            setloadingIcon('pi pi-save');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getValidatorLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getvalidator/TBL_VALIDATOR:*');
        setValidatorLov(resp?.payLoad);
    };

    useEffect(() => {
        getValidatorLov();
    }, []);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="attributeName" className={classNames({ 'p-error': isFormFieldValid('attributeName') }, 'Label__Text')}>
                                    Attribute Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="attributeName"
                                    placeholder="Enter Attribute Name"
                                    name="attributeName"
                                    value={formik?.values?.attributeName?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('attributeName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('attributeName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="validatorId" className={classNames({ 'p-error': isFormFieldValid('validatorId') }, 'Label__Text')}>
                                    Validator <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    filter
                                    // showClear
                                    filterBy="name"
                                    id="validatorId"
                                    placeholder="Select a value"
                                    options={validatorLov}
                                    optionLabel="name"
                                    name="validatorId"
                                    optionValue="lovId"
                                    value={formik.values.validatorId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('validatorId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('validatorId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="attributeCode" className={classNames({ 'p-error': isFormFieldValid('attributeCode') }, 'Label__Text')}>
                                    Attribute Code<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="attributeCode"
                                    placeholder="Enter Attribute Code"
                                    name="attributeCode"
                                    disabled={editable ? true : false}
                                    value={formik?.values?.attributeCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('attributeCode') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('attributeCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="isSearchable" className={classNames({ 'p-error': isFormFieldValid('isSearchable') }, 'Label__Text')}>
                                    Searchable
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isSearchable" name="isSearchable" value={formik.values.isSearchable} checked={formik.values.isSearchable} onChange={formik.handleChange} />
                                </div>
                                {getFormErrorMessage('isSearchable')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="isEditable" className={classNames({ 'p-error': isFormFieldValid('isEditable') }, 'Label__Text')}>
                                    Editable
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isEditable" name="isEditable" value={formik.values.isEditable} checked={formik.values.isEditable} onChange={formik.handleChange} />
                                </div>
                                {getFormErrorMessage('isEditable')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="isUnique" className={classNames({ 'p-error': isFormFieldValid('isUnique') }, 'Label__Text')}>
                                    Unique
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isUnique" name="isUnique" value={formik.values.isUnique} checked={formik.values.isUnique} onChange={formik.handleChange} />
                                </div>
                                {getFormErrorMessage('isUnique')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" loadingIcon={loadingIcon || ''} disabled={loading} label={editable ? 'Update' : 'Submit'} className="Btn__Add" />
                        <Button label="Cancel" className="Btn__cancel" onClick={(e) => handleCancel(e)} />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddEditkycAttribute;

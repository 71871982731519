import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { baseURL } from '../../../../../Config';

function ViewTermsAndConditions({ activeIndex, handleStepClick, lendingData, lendingIdData, setStepData5, setAllStepsComplete }) {
    const [languageUrdu, setLanguageUrdu] = useState('');
    const [languageEnglish, setLanguageEnglish] = useState('');

    // const [tncFile, setTncFile] = useState('');
    const [tncFilePathData, setTncFilePathData] = useState('');

    ///////////////////////////////////////////////
    // const [tncFileError, setTncFileError] = useState('');
    const tncFileRef = useRef(null);

    useEffect(() => {
        if (lendingData && lendingData?.tblLmsTncs && lendingData?.tblLmsTncs[0]) {
            formik.setFieldValue('tncText', lendingData?.tblLmsTncs[0]?.tncTextbox);
            formik.setFieldValue('textType', lendingData?.tblLmsTncs[0]?.describeTnc);
            if (lendingData?.tblLmsTncs[0]?.language === 'E') {
                setLanguageUrdu('');
                setLanguageEnglish('E');
            } else {
                setLanguageUrdu('U');
                setLanguageEnglish('');
            }

            if (lendingData?.tblLmsTncs[0]?.describeTnc === 'F') {
                // lendingData?.tblLmsTncs[0]?.filePath
                let fileName = '';
                if (lendingData?.tblLmsTncs[0]?.filePath) {
                    const filePath = lendingData?.tblLmsTncs[0]?.filePath;
                    const modifiedPath = filePath.substring(0, filePath.indexOf('documents/') + 'documents'.length);
                    // Use the split method to split the string by "/"
                    const parts = modifiedPath.split('/');

                    // Get the last part of the array, which is the filename
                    fileName = parts[parts.length - 1];
                    const filedata = `${baseURL}${modifiedPath}/${lendingData?.tblLmsTncs[0]?.fileName}`;
                    const updatedPath = new File([filedata], lendingData?.tblLmsTncs[0]?.fileName);
                    setTncFilePathData(updatedPath);
                }
                formik.setFieldValue('file', lendingData?.tblLmsTncs[0]?.fileName);
            }
        }
    }, [lendingData]); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            language: '',
            textType: '',
            tncText: '',
            file: ''
        },

        onSubmit: async (data) => {
            const termsData = {
                lmsProductId: lendingIdData,
                currentStep: 5,
                language: languageUrdu ? languageUrdu : languageEnglish,
                textType: data?.textType,
                tncText: data?.tncText,
                file: tncFilePathData
            };

            setStepData5(termsData);
            handleStepClick(activeIndex + 1);
            setAllStepsComplete(true);
        }
    });

    const termsLov = [
        {
            label: 'Text',
            value: 'T'
        },
        {
            label: 'File',
            value: 'F'
        }
    ];

    const handleLanguageForU = () => {
        setLanguageUrdu('U');
        setLanguageEnglish('');
    };

    const handleLanguageForE = () => {
        setLanguageUrdu('');
        setLanguageEnglish('E');
    };

    const handleTncFileInput = () => {
        tncFileRef.current.click();
    };

    const tncFileChange = (event) => {
        const file = event.target.files[0];
        formik.setFieldValue('file', file?.name);
        // setTncFile(file);
    };

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>TERMS & CONDITIONS/ADD NEW</h1>
            </div>
            <div className="Centre_Aligned_Buttons">
                <Button id="language" name="language" disabled value={formik.values.language} className={languageEnglish ? 'Light_Button' : 'DarkGray_Button'} label="English" onClick={handleLanguageForE} />
                <Button className={languageUrdu ? 'Light_Button' : 'DarkGray_Button'} label="Urdu" onClick={handleLanguageForU} />
            </div>
            <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                <div className="card Card__Round">
                    <>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="textType" className="Label__Text">
                                        Describe Terms and Condition as<span className="Label__Required"></span>
                                    </label>
                                    <Dropdown
                                        id="textType"
                                        disabled={true}
                                        placeholder="Select"
                                        name="textType"
                                        options={termsLov}
                                        value={formik.values.textType || ''}
                                        onChange={formik.handleChange}
                                        optionLabel="label"
                                        optionValue="value"
                                        className="Dropdown__Round"
                                    />
                                </div>
                            </div>
                            {formik.values.textType === 'T' ? (
                                <div className="p-field col-12 md:col-8 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="tncText" className="Label__Text">
                                            Text
                                        </label>
                                        <InputTextarea
                                            autoResize
                                            id="tncText"
                                            rows={5}
                                            cols={30}
                                            disabled={true}
                                            placeholder="Property Address, make and model of a vehicle"
                                            value={formik.values.tncText || ''}
                                            onChange={formik.handleChange}
                                            name="tncText"
                                            className="Input__Round"
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        {formik.values.textType === 'F' ? (
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <div className="mb-2">
                                            <label htmlFor="file" className={'Label__Text'}>
                                                Upload File
                                            </label>
                                            <div className="file-input-container w-100" onClick={handleTncFileInput}>
                                                <input type="file" accept="csv" style={{ display: 'none' }} ref={tncFileRef} onChange={tncFileChange} disabled />
                                                <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={formik?.values?.file || ''} name="file" readOnly disabled />
                                                <span className="file-upload-icon">
                                                    <i className="pi pi-paperclip" />
                                                </span>
                                            </div>
                                            {/* {tncFileError ? <small className="p-error">{tncFileError}</small> : getFormErrorMessage('file')} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </>
                </div>
            </form>
        </>
    );
}

export default ViewTermsAndConditions;

import React from 'react';
import cmsTicker from '../../../../assets/cms/cmsTicker.png';

function MobileView({ icon, title, description }) {
    return (
        <div className="cmsMobileView__main relative">
            <div className="cmsMobileView__ticker">{title && <span className="cmsMobileView__title">{title}</span>}</div>
            <img src={cmsTicker} className="blurMobile w-100 h-100" alt="cmsTicker" />
        </div>
    );
}

export default MobileView;

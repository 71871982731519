import React from 'react';
import EditBulletLoan from './EditBulletLoan';
import EditRunningFinance from './EditRunningFinance';
import EditTermLoan from './EditTermLoan';

function EditRepaymentMethod({ activeIndex, handleStepClick, lendingData, lendingIdData, setStepData3, stepData1 }) {
    return (
        <>
            {
                stepData1?.facilityCode === 'BL' ? <EditBulletLoan handleStepClick={handleStepClick} activeIndex={activeIndex} lendingIdData={lendingIdData} lendingData={lendingData} setStepData3={setStepData3} stepData1={stepData1}/> 
                : stepData1?.facilityCode === 'TL' ? <EditTermLoan handleStepClick={handleStepClick} activeIndex={activeIndex}  lendingIdData={lendingIdData} lendingData={lendingData} setStepData3={setStepData3} stepData1={stepData1}/> 
                : stepData1?.facilityCode === 'RF' ? <EditRunningFinance handleStepClick={handleStepClick} activeIndex={activeIndex} lendingIdData={lendingIdData} lendingData={lendingData} setStepData3={setStepData3} stepData1={stepData1}/> 
                : null
            }
        </>
    );
}
export default EditRepaymentMethod;


import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { InputTextarea } from 'primereact/inputtextarea';
import { OverlayPanel } from 'primereact/overlaypanel';
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';
import { useLocation } from 'react-router-dom';
import { InputNumber } from 'primereact/inputnumber';
import * as Yup from 'yup';

function ViewCustomerCommunication({ activeIndex, handleStepClick, setAllStepsComplete, campaignData, setStepData5 }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
    const [visible, setVisible] = useState(false);
    const [frequencyPeriodData, setFrequencyPeriodData] = useState([]);

    const op = useRef(null);

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const campaignId = query.get('campaignId');

    const handleTextareaChange = (e) => {
        formik.setFieldValue('ccSmsContent', e.target.value);
    };

    const onClickEmoji = (emojiData) => {
        formik.setFieldValue('ccSmsContent', formik?.values?.ccSmsContent + (emojiData.isCustom ? emojiData.unified : emojiData.emoji));
        toggleEmojiPicker();
        toggleVisible();
    };

    const toggleEmojiPicker = () => {
        setEmojiPickerVisible(!emojiPickerVisible);
    };

    const toggleVisible = () => {
        setVisible(!visible);
    };

    const getFrequencyPeriodData = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getdatalov/LKP_FREQUENCY_PERIOD');
        setFrequencyPeriodData(response?.payLoad);
    };

    useEffect(() => {
        getFrequencyPeriodData();
    }, []);

    useEffect(() => {
        if (campaignData) {
            formik.setFieldValue('ccCommunicationMedium', campaignData?.ccCommunicationMedium);
            formik.setFieldValue('ccFrequency', campaignData?.ccFrequency?.toString());
            formik.setFieldValue('ccFrequencyPeriodCode', campaignData?.lkpCCFrequencyPeriod?.frequencyPeriodCode?.toString());

            formik.setFieldValue('ccMessageType', campaignData?.ccMessageType?.toString());
            formik.setFieldValue('ccNotificationContent', campaignData?.ccNotificationContent?.toString());
            formik.setFieldValue('ccSmsContent', campaignData?.ccSmsContent?.toString());

            if (['', null, undefined].includes(campaignData?.ccNotificationContent) || ['', null, undefined].includes(campaignData?.ccSmsContent)) {
                formik.setFieldValue('isMessageContent', false);
            } else {
                formik.setFieldValue('isMessageContent', true);
            }

            if (campaignData?.ccMessageType === 'O') {
                formik.setFieldValue('ccFrequencyMonth1', campaignData?.ccFrequencyMonth?.slice(1, -1));
                formik.setFieldValue('ccFrequencyDate', campaignData?.ccFrequencyDate?.slice(1, -1)?.split(', ')?.toString());
            } else {
                formik.setFieldValue('ccFrequencyMonth', campaignData?.ccFrequencyMonth?.slice(1, -1).split(', '));
                formik.setFieldValue('ccFrequencyDay', campaignData?.ccFrequencyDay?.slice(1, -1).split(', '));
                formik.setFieldValue('ccFrequencyDate1', campaignData?.ccFrequencyDate?.slice(1, -1)?.split(', '));
            }
            if (campaignData?.tblCampaignCcTimes?.length !== 0) {
                const ccTimeListData = campaignData?.tblCampaignCcTimes
                    ?.filter((item) => item?.isActive === 'Y')
                    ?.map((item) => {
                        return {
                            startTime: item?.startTime || '',
                            endTime: item?.endTime || ''
                        };
                    });
                formik.setFieldValue('frequencyCcTimeList', ccTimeListData);
            }
        }
    }, []); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        frequencyCcTimeList: Yup.array().when('ccFrequencyPeriodCode', (ccFrequencyPeriodCode, schema) => {
            return ccFrequencyPeriodCode === 'Yes'
                ? Yup.array().of(
                      Yup.object().shape({
                          startTime: Yup.string().required('This field is required'),
                          endTime: Yup.string().required('This field is required')
                      })
                  )
                : schema;
        })
    });

    const formik = useFormik({
        // validationSchema: validationSchema,
        initialValues: {
            ccCommunicationMedium: '',
            isMessageContent: false,
            ccSmsContent: '',
            ccNotificationContent: '',
            ccMessageType: 'O',
            ccFrequencyPeriodCode: '',

            ccFrequencyDate1: [],
            ccFrequencyMonth1: '',

            ccFrequencyDay: [],
            ccFrequencyDate: '',
            ccFrequencyMonth: [],
            ccFrequency: '',

            frequencyCcTimeList: [
                {
                    startTime: '',
                    endTime: ''
                }
            ]
        },

        validate: (data) => {
            let errors = {};

            if (data?.ccCommunicationMedium === 'S') {
                if (data?.ccSmsContent === '') {
                    errors.ccSmsContent = 'This field is required';
                }
            }

            if (data?.ccCommunicationMedium === 'A') {
                if (data?.ccNotificationContent === '') {
                    errors.ccNotificationContent = 'This field is required';
                }
            }

            if (data?.ccCommunicationMedium === 'B') {
                if (data?.ccNotificationContent === '') {
                    errors.ccNotificationContent = 'This field is required';
                }

                if (data?.ccSmsContent === '') {
                    errors.ccSmsContent = 'This field is required';
                }
            }

            if (data?.ccMessageType === '') {
                errors.ccMessageType = 'This Field is required';
            }

            if (data?.ccFrequencyPeriodCode === '') {
                errors.ccFrequencyPeriodCode = 'This field is required';
            }

            if (data?.ccFrequencyPeriodCode === '10') {
                // if (data?.frequencyCcTimeList.length === 0) {
                //     toast.warning('Please add Start and End Time');
                // }
            } else if (data?.ccFrequencyPeriodCode === '20') {
                if (data?.ccFrequencyDay === '') {
                    errors.ccFrequencyDay = 'This field is required';
                }
                // if (data?.frequencyCcTimeList.length === 0) {
                //     toast.warning('Please add Start and End Time');
                // }
            } else if (data?.ccFrequencyPeriodCode === '30') {
                if (data?.ccFrequencyDay === '') {
                    errors.ccFrequencyDay = 'This field is required';
                }
                // if (data?.frequencyCcTimeList.length === 0) {
                //     toast.warning('Please add Start and End Time');
                // }
            } else {
                if (data?.ccFrequencyDay === '') {
                    errors.ccFrequencyDay = 'This field is required';
                }
                if (data?.ccFrequencyMonth === '') {
                    errors.ccFrequencyMonth = 'This field is required';
                }
                // if (data?.frequencyCcTimeList.length === 0) {
                //     toast.warning('Please add Start and End Time');
                // }
            }

            if (data?.ccFrequency === null || data?.ccFrequency === '' || data?.ccFrequency === 0 || !/^[0-9]*$/.test(data.ccFrequency)) {
                errors.ccFrequency = 'Please enter frequency';
            }

            return errors;
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['campaignId'] = campaignId || '';
            data['stepNo'] = '4';

            if (data?.isMessageContent === false) {
                data['ccNotificationContent'] = '';
                data['ccSmsContent'] = '';
            }

            if (data?.ccCommunicationMedium === 'S') {
                delete data['ccNotificationContent'];
            } else if (data?.ccCommunicationMedium === 'A') {
                delete data['ccSmsContent'];
            }

            if (data?.ccFrequencyPeriodCode === '10') {
                delete data['ccFrequencyDate'];
                delete data['ccFrequencyMonth'];
                delete data['ccFrequencyDay'];
            } else if (data?.ccFrequencyPeriodCode === '20') {
                delete data['ccFrequencyDate'];
                delete data['ccFrequencyMonth'];
            }

            if (data?.ccMessageType === 'O') {
                data['ccFrequencyMonth'] = [data?.ccFrequencyMonth1];
                data['ccFrequencyDate'] = [data?.ccFrequencyDate];
            } else {
                data['ccFrequencyDate'] = data?.ccFrequencyDate1;
            }

            delete data['ccFrequencyMonth1'];
            delete data['ccFrequencyDate1'];

            if (parseInt(campaignData?.currentStep) >= 4) {
                const newArray = data?.frequencyCcTimeList?.map((item) => ({
                    startTime: item.startTime,
                    endTime: item.endTime
                }));

                data['frequencyCcTimeList'] = newArray;
            }

            setStepData5(data);
            handleStepClick(activeIndex + 1);
            setAllStepsComplete(true);

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const addStartEndTime = () => {
        formik.setFieldValue('frequencyCcTimeList', [
            ...formik.values.frequencyCcTimeList,
            {
                startTime: '',
                endTime: ''
            }
        ]);
    };

    const removeStartEndTime = (index) => {
        if (index > 0) {
            const updatedData = [...formik.values.frequencyCcTimeList];
            updatedData.splice(index, 1);
            formik.setFieldValue('frequencyCcTimeList', updatedData);
        }
        if (index === 0) {
            const updatedData = [...formik.values.frequencyCcTimeList];
            formik.setFieldValue('frequencyCcTimeList', updatedData);
        }
    };

    const getCommunicationMediumData = [
        { name: 'SMS', value: 'S' },
        { name: 'Application Notification', value: 'N' },
        { name: 'Both', value: 'B' }
    ];

    const getMessageTypeLov = [
        {
            name: 'One Time',
            lovId: 'O'
        },

        {
            name: 'Recurring',
            lovId: 'R'
        }
    ];

    const weekDays = [
        { name: 'Monday', lovId: 'Monday' },
        { name: 'Tuesday', lovId: 'Tuesday' },
        { name: 'Wednesday', lovId: 'Wednesday' },
        { name: 'Thursday', lovId: 'Thursday' },
        { name: 'Friday', lovId: 'Friday' },
        { name: 'Saturday', lovId: 'Saturday' },
        { name: 'Sunday', lovId: 'Sunday' }
    ];

    const Dates = [
        { name: '1', lovId: '1' },
        { name: '2', lovId: '2' },
        { name: '3', lovId: '3' },
        { name: '4', lovId: '4' },
        { name: '5', lovId: '5' },
        { name: '6', lovId: '6' },
        { name: '7', lovId: '7' },
        { name: '8', lovId: '8' },
        { name: '9', lovId: '9' },
        { name: '10', lovId: '10' },
        { name: '11', lovId: '11' },
        { name: '12', lovId: '12' },
        { name: '13', lovId: '13' },
        { name: '14', lovId: '14' },
        { name: '15', lovId: '15' },
        { name: '16', lovId: '16' },
        { name: '17', lovId: '17' },
        { name: '18', lovId: '18' },
        { name: '19', lovId: '19' },
        { name: '20', lovId: '20' },
        { name: '21', lovId: '21' },
        { name: '22', lovId: '22' },
        { name: '23', lovId: '23' },
        { name: '24', lovId: '24' },
        { name: '25', lovId: '25' },
        { name: '26', lovId: '26' },
        { name: '27', lovId: '27' },
        { name: '28', lovId: '28' },
        { name: '29', lovId: '29' },
        { name: '30', lovId: '30' },
        { name: '31', lovId: '31' }
    ];

    const monthsLov = [
        { name: 'January', lovId: 'January' },
        { name: 'February', lovId: 'February' },
        { name: 'March', lovId: 'March' },
        { name: 'April', lovId: 'April' },
        { name: 'May', lovId: 'May' },
        { name: 'June', lovId: 'June' },
        { name: 'July', lovId: 'July' },
        { name: 'August', lovId: 'August' },
        { name: 'September', lovId: 'September' },
        { name: 'October', lovId: 'October' },
        { name: 'November', lovId: 'November' },
        { name: 'December', lovId: 'December' }
    ];

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>Edit Customer Communication</h1>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="ccCommunicationMedium" className={classNames({ 'p-error': isFormFieldValid('ccCommunicationMedium') }, 'Label__Text')}>
                                    Communication Medium
                                </label>
                                <Dropdown
                                    disabled
                                    id="ccCommunicationMedium"
                                    placeholder="Select Limit Duration"
                                    options={getCommunicationMediumData}
                                    optionLabel="name"
                                    name="ccCommunicationMedium"
                                    optionValue="value"
                                    value={formik?.values?.ccCommunicationMedium || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('ccCommunicationMedium') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('ccCommunicationMedium')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-center">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox
                                        disabled
                                        inputId="isMessageContent"
                                        name="isMessageContent"
                                        value={formik?.values?.isMessageContent}
                                        checked={formik?.values?.isMessageContent}
                                        onChange={formik.handleChange}
                                        style={{ marginTop: '10px' }}
                                    />
                                    <label htmlFor="isMessageContent" className={classNames({ 'p-error': isFormFieldValid('isMessageContent') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Message Content
                                    </label>
                                </div>
                                {getFormErrorMessage('isMessageContent')}
                            </div>
                        </div>
                        {(formik?.values?.ccCommunicationMedium === 'S' || formik?.values?.ccCommunicationMedium === 'B') && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field" style={{ position: 'relative' }}>
                                        <label htmlFor="ccSmsContent" className={classNames({ 'p-error': isFormFieldValid('ccSmsContent') }, 'Label__Text')}>
                                            SMS
                                        </label>
                                        <InputTextarea
                                            disabled
                                            id="ccSmsContent"
                                            placeholder="Enter SMS"
                                            name="ccSmsContent"
                                            rows={5}
                                            cols={30}
                                            autoResize
                                            value={formik?.values?.ccSmsContent || ''}
                                            onChange={handleTextareaChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('ccSmsContent') }, 'Input__Round')}
                                        />
                                        <p
                                            onClick={(e) => {
                                                toggleEmojiPicker();
                                                op.current.toggle(e);
                                                toggleVisible();
                                            }}
                                            style={{ cursor: 'pointer', position: 'absolute', top: '25px', right: '10px' }}
                                        >
                                            {'\u{1F600}'}
                                        </p>
                                        <OverlayPanel ref={op} onHide={() => toggleVisible()}>
                                            <EmojiPicker disabled onEmojiClick={onClickEmoji} autoFocusSearch={false} emojiStyle={EmojiStyle.NATIVE} />
                                        </OverlayPanel>
                                        {getFormErrorMessage('ccSmsContent')}
                                    </div>
                                </div>
                            </>
                        )}
                        {(formik?.values?.ccCommunicationMedium === 'B' || formik?.values?.ccCommunicationMedium === 'N') && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="ccNotificationContent" className={classNames({ 'p-error': isFormFieldValid('ccNotificationContent') }, 'Label__Text')}>
                                            Application Notification
                                        </label>
                                        <InputTextarea
                                            disabled
                                            id="ccNotificationContent"
                                            placeholder="Enter Application Notification"
                                            name="ccNotificationContent"
                                            rows={5}
                                            cols={30}
                                            autoResize
                                            value={formik?.values?.ccNotificationContent || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('ccNotificationContent') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('ccNotificationContent')}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="ccMessageType" className={classNames({ 'p-error': isFormFieldValid('ccMessageType') }, 'Label__Text')}>
                                    Message Type
                                </label>
                                <Dropdown
                                    disabled
                                    id="ccMessageType"
                                    placeholder="Select Message Type"
                                    options={getMessageTypeLov}
                                    optionLabel="name"
                                    name="ccMessageType"
                                    optionValue="lovId"
                                    value={formik?.values?.ccMessageType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('ccMessageType') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('ccMessageType')}
                            </div>
                        </div>
                        {formik?.values?.ccMessageType === 'O' && (
                            <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card flex">
                                <div className="p-fluid p-formgrid grid mb-5 w-100">
                                    <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="ccFrequencyMonth1" className={classNames({ 'p-error': isFormFieldValid('ccFrequencyMonth1') }, 'Label__Text')}>
                                                Month
                                            </label>
                                            <Dropdown
                                                disabled
                                                id="ccFrequencyMonth1"
                                                placeholder="Select Dates"
                                                options={monthsLov}
                                                optionLabel="name"
                                                name="ccFrequencyMonth1"
                                                optionValue="lovId"
                                                value={formik.values.ccFrequencyMonth1 || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('ccFrequencyMonth1') }, 'Dropdown__Round')}
                                            />
                                            {getFormErrorMessage('ccFrequencyMonth1')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="ccFrequencyDate" className={classNames({ 'p-error': isFormFieldValid('ccFrequencyDate') }, 'Label__Text')}>
                                                Date Of Month
                                            </label>
                                            <Dropdown
                                                disabled
                                                id="ccFrequencyDate"
                                                placeholder="Select Dates"
                                                options={Dates}
                                                optionLabel="name"
                                                name="ccFrequencyDate"
                                                optionValue="lovId"
                                                value={formik.values.ccFrequencyDate || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('ccFrequencyDate') }, 'Dropdown__Round')}
                                            />
                                            {getFormErrorMessage('ccFrequencyDate')}
                                        </div>
                                    </div>
                                    {formik?.values?.frequencyCcTimeList?.map((startEndTimeMonthly, index) => {
                                        return (
                                            <div className="col-12 md:col-4 flex-direction-col md:flex" key={index}>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor={`frequencyCcTimeList[${index}].startTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`) }, 'Label__Text')}>
                                                            Start Time
                                                        </label>
                                                        <InputText
                                                            disabled
                                                            id={`frequencyCcTimeList[${index}].startTime`}
                                                            type="time"
                                                            name={`frequencyCcTimeList[${index}].startTime`}
                                                            value={startEndTimeMonthly?.startTime || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`) }, 'Input__Round')}
                                                        />
                                                        <div style={{ width: '100%', padding: '5px' }} />
                                                        {getFormErrorMessage(`frequencyCcTimeList[${index}].startTime`)}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor={`frequencyCcTimeList[${index}].endTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`) }, 'Label__Text')}>
                                                            End Time
                                                        </label>
                                                        <InputText
                                                            disabled
                                                            id={`frequencyCcTimeList[${index}].endTime`}
                                                            type="time"
                                                            name={`frequencyCcTimeList[${index}].endTime`}
                                                            value={startEndTimeMonthly?.endTime || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`) }, 'Input__Round')}
                                                        />
                                                        <div style={{ width: '100%', padding: '5px' }} />
                                                        {getFormErrorMessage(`frequencyCcTimeList[${index}].endTime`)}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                        {formik?.values?.ccMessageType === 'R' && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="ccFrequencyPeriodCode" className={classNames({ 'p-error': isFormFieldValid('ccFrequencyPeriodCode') }, 'Label__Text')}>
                                            Frequency Period
                                        </label>
                                        <Dropdown
                                            disabled
                                            id="ccFrequencyPeriodCode"
                                            placeholder="Select Frequency Period"
                                            options={frequencyPeriodData}
                                            optionLabel="name"
                                            name="ccFrequencyPeriodCode"
                                            optionValue="code"
                                            value={formik?.values?.ccFrequencyPeriodCode || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('ccFrequencyPeriodCode') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('ccFrequencyPeriodCode')}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {formik?.values?.ccFrequencyPeriodCode === '10' && formik?.values?.ccMessageType === 'R' && (
                        <>
                            <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card flex">
                                <div className="p-fluid p-formgrid grid mb-5 w-100">
                                    {formik?.values?.frequencyCcTimeList?.map((startEndTimeDaily, index) => {
                                        return (
                                            <div className="col-12 md:col-6 flex-direction-col md:flex" key={index}>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor={`frequencyCcTimeList[${index}].startTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`) }, 'Label__Text')}>
                                                            Start Time
                                                        </label>
                                                        <InputText
                                                            disabled
                                                            id={`frequencyCcTimeList[${index}].startTime`}
                                                            type="time"
                                                            name={`frequencyCcTimeList[${index}].startTime`}
                                                            value={startEndTimeDaily?.startTime || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`) }, 'Input__Round')}
                                                        />
                                                        <div style={{ width: '100%', padding: '5px' }} />
                                                        {getFormErrorMessage(`frequencyCcTimeList[${index}].startTime`)}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor={`frequencyCcTimeList[${index}].endTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`) }, 'Label__Text')}>
                                                            End Time
                                                        </label>
                                                        <InputText
                                                            disabled
                                                            id={`frequencyCcTimeList[${index}].endTime`}
                                                            type="time"
                                                            name={`frequencyCcTimeList[${index}].endTime`}
                                                            value={startEndTimeDaily?.endTime || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`) }, 'Input__Round')}
                                                        />
                                                        <div style={{ width: '100%', padding: '5px' }} />
                                                        {getFormErrorMessage(`frequencyCcTimeList[${index}].endTime`)}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-2 flex align-items-center pt-3 pb-3">
                                                    <Button
                                                        disabled
                                                        icon="pi pi-plus"
                                                        className="p-button-rounded mt-2"
                                                        aria-label="Filter"
                                                        tooltip="Add New Start & End Time"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            addStartEndTime();
                                                        }}
                                                    />
                                                    <Button
                                                        disabled
                                                        icon="pi pi-minus"
                                                        className="p-button-rounded mt-2 p-button-danger"
                                                        aria-label="Filter"
                                                        tooltip="Remove Start & End Time"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            removeStartEndTime(index);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </>
                    )}
                    {formik?.values?.ccFrequencyPeriodCode === '20' && formik?.values?.ccMessageType === 'R' && (
                        <>
                            <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card flex">
                                <div className="p-fluid p-formgrid grid mb-5 w-100">
                                    {formik?.values?.frequencyCcTimeList?.map((startEndTimeWeekly, index) => {
                                        return (
                                            <div className="col-12 md:col-6 flex-direction-col md:flex" key={index}>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor={`frequencyCcTimeList[${index}].startTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`) }, 'Label__Text')}>
                                                            Start Time
                                                        </label>
                                                        <InputText
                                                            disabled
                                                            id={`frequencyCcTimeList[${index}].startTime`}
                                                            type="time"
                                                            name={`frequencyCcTimeList[${index}].startTime`}
                                                            value={startEndTimeWeekly?.startTime || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`) }, 'Input__Round')}
                                                        />
                                                        <div style={{ width: '100%', padding: '5px' }} />
                                                        {getFormErrorMessage(`frequencyCcTimeList[${index}].startTime`)}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor={`frequencyCcTimeList[${index}].endTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`) }, 'Label__Text')}>
                                                            End Time
                                                        </label>
                                                        <InputText
                                                            disabled
                                                            id={`frequencyCcTimeList[${index}].endTime`}
                                                            type="time"
                                                            name={`frequencyCcTimeList[${index}].endTime`}
                                                            value={startEndTimeWeekly?.endTime || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`) }, 'Input__Round')}
                                                        />
                                                        <div style={{ width: '100%', padding: '5px' }} />
                                                        {getFormErrorMessage(`frequencyCcTimeList[${index}].endTime`)}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-2 flex align-items-center pt-3 pb-3">
                                                    <Button
                                                        disabled
                                                        icon="pi pi-plus"
                                                        className="p-button-rounded mt-2"
                                                        aria-label="Filter"
                                                        tooltip="Add New Start & End Time"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            addStartEndTime();
                                                        }}
                                                    />
                                                    <Button
                                                        disabled
                                                        icon="pi pi-minus"
                                                        className="p-button-rounded mt-2 p-button-danger"
                                                        aria-label="Filter"
                                                        tooltip="Remove Start & End Time"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            removeStartEndTime(index);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="ccFrequencyDay" className={classNames({ 'p-error': isFormFieldValid('ccFrequencyDay') }, 'Label__Text')}>
                                                Days
                                            </label>
                                            <MultiSelect
                                                id="ccFrequencyDay"
                                                placeholder="Select Days"
                                                options={weekDays}
                                                optionLabel="name"
                                                name="ccFrequencyDay"
                                                optionValue="lovId"
                                                value={formik?.values?.ccFrequencyDay || []}
                                                className={classNames({ 'p-invalid': isFormFieldValid('ccFrequencyDay') }, 'Dropdown__Round')}
                                            />
                                            {getFormErrorMessage('ccFrequencyDay')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {formik?.values?.ccFrequencyPeriodCode === '30' && formik?.values?.ccMessageType === 'R' && (
                        <>
                            <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card flex">
                                <div className="p-fluid p-formgrid grid mb-5 w-100">
                                    {formik?.values?.frequencyCcTimeList?.map((startEndTimeMonthly, index) => {
                                        return (
                                            <div className="col-12 md:col-6 flex-direction-col md:flex" key={index}>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor={`frequencyCcTimeList[${index}].startTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`) }, 'Label__Text')}>
                                                            Start Time
                                                        </label>
                                                        <InputText
                                                            disabled
                                                            id={`frequencyCcTimeList[${index}].startTime`}
                                                            type="time"
                                                            name={`frequencyCcTimeList[${index}].startTime`}
                                                            value={startEndTimeMonthly?.startTime || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`) }, 'Input__Round')}
                                                        />
                                                        <div style={{ width: '100%', padding: '5px' }} />
                                                        {getFormErrorMessage(`frequencyCcTimeList[${index}].startTime`)}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor={`frequencyCcTimeList[${index}].endTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`) }, 'Label__Text')}>
                                                            End Time
                                                        </label>
                                                        <InputText
                                                            disabled
                                                            id={`frequencyCcTimeList[${index}].endTime`}
                                                            type="time"
                                                            name={`frequencyCcTimeList[${index}].endTime`}
                                                            value={startEndTimeMonthly?.endTime || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`) }, 'Input__Round')}
                                                        />
                                                        <div style={{ width: '100%', padding: '5px' }} />
                                                        {getFormErrorMessage(`frequencyCcTimeList[${index}].endTime`)}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-2 flex align-items-center pt-3 pb-3">
                                                    <Button
                                                        disabled
                                                        icon="pi pi-plus"
                                                        className="p-button-rounded mt-2"
                                                        aria-label="Filter"
                                                        tooltip="Add New Start & End Time"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            addStartEndTime();
                                                        }}
                                                    />
                                                    <Button
                                                        disabled
                                                        icon="pi pi-minus"
                                                        className="p-button-rounded mt-2 p-button-danger"
                                                        aria-label="Filter"
                                                        tooltip="Remove Start & End Time"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            removeStartEndTime(index);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="ccFrequencyDate1" className={classNames({ 'p-error': isFormFieldValid('ccFrequencyDate1') }, 'Label__Text')}>
                                                Date Of Month
                                            </label>
                                            <MultiSelect
                                                id="ccFrequencyDate1"
                                                placeholder="Select Dates"
                                                options={Dates}
                                                optionLabel="name"
                                                name="ccFrequencyDate1"
                                                optionValue="lovId"
                                                value={formik?.values?.ccFrequencyDate1 || ''}
                                                className={classNames({ 'p-invalid': isFormFieldValid('ccFrequencyDate1') }, 'Dropdown__Round')}
                                            />
                                            {getFormErrorMessage('ccFrequencyDate1')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {formik?.values?.ccFrequencyPeriodCode === '40' && formik?.values?.ccMessageType === 'R' && (
                        <>
                            <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card flex">
                                <div className="p-fluid p-formgrid grid mb-5 w-100">
                                    <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="ccFrequencyMonth" className={classNames({ 'p-error': isFormFieldValid('ccFrequencyMonth') }, 'Label__Text')}>
                                                Month
                                            </label>
                                            <MultiSelect
                                                id="ccFrequencyMonth"
                                                placeholder="Select Dates"
                                                options={monthsLov}
                                                optionLabel="name"
                                                name="ccFrequencyMonth"
                                                optionValue="lovId"
                                                value={formik?.values?.ccFrequencyMonth || ''}
                                                className={classNames({ 'p-invalid': isFormFieldValid('ccFrequencyMonth') }, 'Dropdown__Round')}
                                            />
                                            {getFormErrorMessage('ccFrequencyMonth')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="ccFrequencyDate1" className={classNames({ 'p-error': isFormFieldValid('ccFrequencyDate1') }, 'Label__Text')}>
                                                Date Of Month
                                            </label>
                                            <MultiSelect
                                                id="ccFrequencyDate1"
                                                placeholder="Select Dates"
                                                options={Dates}
                                                optionLabel="name"
                                                name="ccFrequencyDate1"
                                                optionValue="lovId"
                                                value={formik?.values?.ccFrequencyDate1 || ''}
                                                className={classNames({ 'p-invalid': isFormFieldValid('ccFrequencyDate1') }, 'Dropdown__Round')}
                                            />
                                            {getFormErrorMessage('ccFrequencyDate1')}
                                        </div>
                                    </div>
                                    {formik?.values?.frequencyCcTimeList?.map((startEndTimeMonthly, index) => {
                                        return (
                                            <div className="col-12 md:col-4 flex-direction-col md:flex" key={index}>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor={`frequencyCcTimeList[${index}].startTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`) }, 'Label__Text')}>
                                                            Start Time
                                                        </label>
                                                        <InputText
                                                            disabled
                                                            id={`frequencyCcTimeList[${index}].startTime`}
                                                            type="time"
                                                            name={`frequencyCcTimeList[${index}].startTime`}
                                                            value={startEndTimeMonthly?.startTime || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`) }, 'Input__Round')}
                                                        />
                                                        <div style={{ width: '100%', padding: '5px' }} />
                                                        {/* {getFormErrorMessage(`frequencyCcTimeList[${index}].startTime`)} */}
                                                        <small className="p-error">{formik.touched.frequencyCcTimeList?.[index]?.startTime && formik.errors.frequencyCcTimeList?.[index]?.startTime}</small>
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor={`frequencyCcTimeList[${index}].endTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`) }, 'Label__Text')}>
                                                            End Time
                                                        </label>
                                                        <InputText
                                                            disabled
                                                            id={`frequencyCcTimeList[${index}].endTime`}
                                                            type="time"
                                                            name={`frequencyCcTimeList[${index}].endTime`}
                                                            value={startEndTimeMonthly?.endTime || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`) }, 'Input__Round')}
                                                        />
                                                        <div style={{ width: '100%', padding: '5px' }} />
                                                        {/* {getFormErrorMessage(`frequencyCcTimeList[${index}].endTime`)} */}
                                                        <small className="p-error">{formik.touched.frequencyCcTimeList?.[index]?.endTime && formik.errors.frequencyCcTimeList?.[index]?.endTime}</small>
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-4 flex align-items-center justify-content-evenly pt-3 pb-3">
                                                    <Button
                                                        disabled
                                                        icon="pi pi-plus"
                                                        className="p-button-rounded mt-2"
                                                        aria-label="Filter"
                                                        tooltip="Add New Start & End Time"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            addStartEndTime();
                                                        }}
                                                    />
                                                    <Button
                                                        disabled
                                                        icon="pi pi-minus"
                                                        className="p-button-rounded mt-2 p-button-danger"
                                                        aria-label="Filter"
                                                        tooltip="Remove Start & End Time"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            removeStartEndTime(index);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </>
                    )}
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="ccFrequency" className={classNames({ 'p-error': isFormFieldValid('ccFrequency') }, 'Label__Text')}>
                                    Frequency
                                </label>
                            </div>
                            <InputNumber
                                disabled
                                inputId="ccFrequency"
                                name="ccFrequency"
                                maxLength={9}
                                placeholder="Enter Frequency"
                                value={formik?.values?.ccFrequency || ''}
                                onValueChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('ccFrequency') }, 'Input_Number')}
                            />
                            {getFormErrorMessage('ccFrequency')}
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
export default ViewCustomerCommunication;

import { Chart } from 'primereact/chart';
import React, { useEffect, useState } from 'react';

const NumberOfAccounts = ({ numberOfAccounts }) => {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        const countArr =
            numberOfAccounts &&
            numberOfAccounts.map((item) => {
                return item?.parameterValue;
            });
        const labelArr =
            numberOfAccounts &&
            numberOfAccounts.map((item) => {
                return item?.parameterName;
            });

        const data = {
            labels: labelArr,
            datasets: [
                {
                    data: countArr,
                    backgroundColor: ['#d4e6f3', '#dee176', '#ebe0ff', '#b4eff1', '#eeca50'],
                    hoverBackgroundColor: ['#d4e6f3', '#dee176', '#ebe0ff', '#b4eff1', '#eeca50']
                }
            ]
        };

        setChartData(data);
    }, [numberOfAccounts]);

    let horizontalOptions = {
        indexAxis: 'y',
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };
    return (
        <div className="col-12 md:col-6">
            <div className="card Card__Round">
                <div className="card">
                    <h5>Number Of Account</h5>
                    <Chart type="bar" data={chartData} options={horizontalOptions} className="w-full" style={{ height: '35rem' }} />
                </div>
            </div>
        </div>
    );
};

export default NumberOfAccounts;

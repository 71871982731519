import React from 'react';
import { InputText } from 'primereact/inputtext';

function ChannelOldNewValuesView({ channelRowData }) {
    return (
        <>
            <div className="card Card__Round">
                <form className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>View Detail</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="businessTypeId" className="Label__Text">
                                    Name<span className="Label__Required">*</span>
                                </label>
                                <InputText id="businessTypeId" name="businessTypeId" value={channelRowData?.channelName} className="Input__Round" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="segmentName" className="Label__Text">
                                    Description<span className="Label__Required">*</span>
                                </label>
                                <InputText id="segmentName" name="segmentName" value={channelRowData?.channelDescr} className="Input__Round" disabled />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default ChannelOldNewValuesView;

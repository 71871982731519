import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { handlePostRequest } from '../../services/PostTemplate';
import { handlePostForReportingRequest } from '../../services/PostForReporting';
import { handleGetRequest } from '../../services/GetTemplate';
import DynamicReportFields from './DynamicFields/DynamicReportFields';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import moment from 'moment';

const Reports = () => {
    const [reportNames, setReportNames] = useState([]);
    const [pdfReportNames, setPdfReportNames] = useState([]);
    const [jsonResult, setJsonResult] = useState(null);
    const [searchFilters, setSearchFilters] = useState([]);
    const [newLookUp, setNewLookUp] = useState([]);
    const [jsonData, setJsonData] = useState(null);
    const [columns, setColumn] = useState([]);
    const [subReport, setSubReport] = useState([]);
    const [item, setItem] = useState([]);
    const [updatedInitialValues, setUpdatedInitialValues] = useState({});

    const dispatch = useDispatch();

    function convertToTitleCase(inputString) {
        if (inputString == null) {
            return '';
        }
        // Replace underscores with spaces
        inputString = inputString.replace(/_/g, ' ');
        // Split the string into words based on spaces
        const words = inputString.split(/\s+/);
        // Capitalize the first letter of each word
        const titleCaseString = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        return titleCaseString;
    }

    //formik
    const formik = useFormik({
        initialValues: {
            reportName: ''
        },

        onSubmit: async (data) => {
            let singleReportName = reportNames.filter((result) => result.lovId === data.reportName)[0];
            setPdfReportNames(singleReportName?.name);
            for (let key in updatedInitialValues) {
                if (!data[key]) {
                    data[key] = updatedInitialValues[key];
                }
            }
            for (let key in data) {
                if (key?.includes('date') && data[key]) {
                    data[key] = moment(data[key]).format('DD-MMM-YYYY');
                }
            }

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        reportId: singleReportName?.lovId,
                        reportName: singleReportName?.name || '',
                        reportParams: {
                            ...data
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            delete newData.data.payLoad.reportParams.reportName;

            const res = await dispatch(handlePostRequest(newData, '/agreementAndPartnership/v1/kmsjson/create', true, true));
            setJsonData(res?.payLoad?.jsonResult);
            const columns = Object.keys(res?.payLoad?.jsonResult[0])?.map((key, index) => ({
                field: key,
                header: convertToTitleCase(key),
                key: index
            }));
            setColumn(columns);
            setJsonResult(res?.payLoad?.jsonResult);
        }
    });

    const handleTabData = async (item) => {
        setItem(item?.reportName);
        setJsonResult(null);
        const data = { ...updatedInitialValues, ...formik.values };

        for (let key in data) {
            if (key?.includes('date') && data[key]) {
                data[key] = moment(data[key]).format('DD-MMM-YYYY');
            }
        }
        delete data.reportName;

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    reportId: item?.reportId.toString() || '',
                    reportName: item?.reportName || '',
                    reportParams: {
                        ...data
                    }
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/agreementAndPartnership/v1/kmsjson/create', true, true));
        if (res?.message.includes('Success')) {
            setJsonData(res?.payLoad?.jsonResult);
            const columns = Object.keys(res?.payLoad?.jsonResult[0])?.map((key, index) => ({
                field: key,
                header: convertToTitleCase(key),
                key: index
            }));
            setColumn(columns);
            setJsonResult(res?.payLoad?.jsonResult);
        }
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">Report Data</h5>
                <div className="Top__Btn mb-0 w-auto">
                    <div>
                        <Button label="Generate Pdf" onClick={() => getPdfFile()} className="Btn__Add__" icon="pi pi-download" iconPos="left" />
                    </div>
                </div>
            </div>
        );
    };
    const header = () => renderHeader();

    const getPdfFile = async () => {
        const newData = {
            data: {
                account: {
                    bban: '',
                    currency: '',
                    iban: '',
                    msidn: '',
                    pan: ''
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                channel: '',
                checkSum: '',
                payLoad: {
                    reportName: subReport && subReport.length == 0 ? pdfReportNames.replace(/\s/g, '').toUpperCase() : item.replace(/\s/g, '').toUpperCase(),
                    dateFrom: formik?.values?.dateFrom || '',
                    dateTo: formik?.values?.dateTo || '',
                    json: jsonData
                },
                reterivalReferenceNumber: '',
                security: {
                    password: '',
                    securityToken: '',
                    userName: ''
                },
                terminal: ''
            }
        };
        const res = await dispatch(handlePostForReportingRequest(newData, '/reports/v1/reports/generateReport', true, true, 'blob'));
        if (res) {
            openPdf(res);
        }
    };

    //function to generate the pdf
    const openPdf = async (blob) => {
        const blobb = await new Response(blob).blob(); // For fetch
        const url = window.URL.createObjectURL(blobb);

        const link = document.createElement('a');
        link.href = url;
        link.download = subReport && subReport.length == 0 ? `${pdfReportNames}.pdf` || `Download.pdf` : `${item}.pdf` || `Download.pdf`;

        document.body.appendChild(link);
        link.click();
    };

    const getSearchFilters = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    reportId: formik.values.reportName
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newData, '/agreementAndPartnership/v1/kmsjson/reportfilter', true, true));
        setSearchFilters(res?.payLoad);
    };

    const resetSearchFilters = () => {
        formik.resetForm();
        setJsonResult(null);
    };

    //API call to get report names
    const getReportName = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getReportsName');
        setReportNames(res?.payLoad);
    };

    //calling getReportname
    useEffect(() => {
        getReportName();
    }, []);

    const getNewSearch = async () => {
        try {
            const updatedSearchFilters = await Promise.all(
                searchFilters?.reportFilterList?.map(async (item) => {
                    const isLookup = item?.reportFilterType?.toLowerCase()?.includes('lookup');
                    if (isLookup && item?.reportFilterUrl) {
                        const lookUpRes = await handleGetRequest(`${item?.reportFilterUrl}`);
                        item['lookUpData'] = lookUpRes?.payLoad || [];
                    }
                    return item;
                })
            );

            dispatch({ type: 'SET_SEARCH_FILTERS', payload: updatedSearchFilters });
            setNewLookUp(updatedSearchFilters);
        } catch (error) {}
    };

    useEffect(() => {
        if (searchFilters) {
            getNewSearch();
            setSubReport(searchFilters?.subReports);
            const apiInitialValues = {};
            searchFilters?.reportFilterList?.map((item) => {
                if (item?.mappedFilterName) {
                    apiInitialValues[item?.mappedFilterName] = '';
                }
            });
            setUpdatedInitialValues({ ...apiInitialValues });
        }
    }, [searchFilters]);

    return (
        <>
            <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                <div className="card Card__Round">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="m-3 Form__Header Full__Width">
                            <h2>Generate Report</h2>
                        </div>
                        <div className="p-field col-12 md:col-6 pt-3 pb-3 ml-2">
                            <div className="p-field">
                                <label htmlFor="reportName">Report Name</label>

                                <Dropdown
                                    id="accountclassification"
                                    placeholder="Select Account Classification"
                                    options={reportNames}
                                    optionLabel="name"
                                    name="reportName"
                                    optionValue="lovId"
                                    value={formik.values.reportName || ''}
                                    // onChange={formik.handleChange}
                                    onChange={(e) => {
                                        formik.resetForm();
                                        formik.handleChange(e);
                                    }}
                                    className="Dropdown__Round"
                                    onHide={() => {
                                        if (formik.values.reportName) {
                                            getSearchFilters();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {newLookUp.length > 0 && <DynamicReportFields searchFilters={newLookUp} subReport={subReport} formik={formik} resetSearchFilters={resetSearchFilters} handleTabData={handleTabData} />}
            </form>
            {jsonResult && (
                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <DataTable
                                paginator
                                rows={5}
                                header={header}
                                rowsPerPageOptions={[5, 10, 25]}
                                className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                                emptyMessage="No List found."
                                responsiveLayout="scroll"
                                value={jsonResult}
                            >
                                {columns.map((result) => (
                                    <Column key={result.key} field={result.field} header={result.header}></Column>
                                ))}
                            </DataTable>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default Reports;

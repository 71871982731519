import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { handlePostRequest } from "../../../services/PostTemplate";

const BaseFactorDialog = ({ onHideBaseFactor, editable, baseFactorRowData , view }) => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState("pi pi-save");

    useEffect(() => {
        if (baseFactorRowData !== undefined || baseFactorRowData !== null) {
            formik.setFieldValue("baseFactor", baseFactorRowData?.baseFactor?.toString());
            formik.setFieldValue("dateFrom", baseFactorRowData?.dateFrom?.toString());
            formik.setFieldValue("dateTo", baseFactorRowData?.dateTo?.toString());
        }
    }, [baseFactorRowData]);

    useEffect(() => {
        if (!editable) {
            formik.resetForm();
        }
    }, [editable]);

    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        baseFactor: Yup.string().required("This field is required.").max(100, "Maximum length 100 allowed").matches("^[0-9]*$", "Please enter numeric data"),
        dateFrom: Yup.date().required("This field is required."),
        dateTo: Yup.date().required("This field is required.").min(Yup.ref("dateFrom"), "Effective To has to be more than Effective From"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            baseFactor: "",
            dateFrom: "",
            dateTo: "",
            isActive: 'false',
            statusId: 0,
            zmilesBaseFactorId:baseFactorRowData?.zmilesBaseFactorId
        },

        onSubmit: async (data) => {
            if (editable) {
                data ['appUserId'] = 0
            }
            if (!editable) {
                delete data['zmilesBaseFactorId']
            }
              const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            setloading(true);
            setloadingIcon("pi pi-spin pi-spinner");

            if (editable) {
                const res = await dispatch(handlePostRequest(newData, `/zconfig/v1/zmiles/updateBaseFactor`, true, false));
                if (res?.responseCode === '330000') {
                    formik.resetForm();
                    onHideBaseFactor();
                }
            } else {
                const res = await dispatch(handlePostRequest(newData, `/zconfig/v1/zmiles/saveBaseFactor`, true, false));
                if (res?.responseCode === '330000') {
                    onHideBaseFactor();
                }
            }

            setloading(false);
            setloadingIcon("pi pi-save");
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="baseFactor" className={classNames({ "p-error": isFormFieldValid("baseFactor") }, "Label__Text")}>
                                    Base Factor<span className="Label__Required">*</span>
                                </label>
                                <InputText  id="baseFactor" placeholder="Enter Base Factor" name="baseFactor" value={formik.values.baseFactor} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("baseFactor") }, "Input__Round")} />

                                {getFormErrorMessage("baseFactor")}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ "p-error": isFormFieldValid("dateFrom") }, "Label__Text")}>
                                    Effective From<span className="Label__Required">*</span>
                                </label>
                                <InputText  id="dateFrom" type="date" name="dateFrom" value={formik.values.dateFrom} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("dateFrom") }, "Input__Round")} />

                                {getFormErrorMessage("dateFrom")}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ "p-error": isFormFieldValid("dateTo") }, "Label__Text")}>
                                    Effective To<span className="Label__Required">*</span>
                                </label>
                                <InputText id="dateTo" type="date" name="dateTo" value={formik.values.dateTo} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("dateTo") }, "Input__Round")} />
                                {getFormErrorMessage("dateTo")}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading}  label={editable ? 'Update' : 'Submit'} className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
};

export default BaseFactorDialog;

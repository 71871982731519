import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../../services/GetTemplate';
import AddFranchise from '../Components/Franchise/AddFranchise';
import AddRetailer from '../Components/Retailer/AddRetailer';
import AddHandler from '../Components/Handler/AddHandler';
import AddCorporate from '../Components/Corporate/AddCorporate';

function AddNewAgentOnboarding() {
    // const [loading, setloading] = useState(false);
    const [accountClassification, setAccountClassification] = useState([]);
    const [classificationOptions, setClassificationOptions] = useState([]);
    const [classificationObj, setClassificationObj] = useState({});

    useEffect(() => {
        let arrayofwords = [];
        accountClassification?.map((item) => {
            if (item.code === 'FRN' || item.code === 'RTL' || item.code === 'HND' || item.code === 'CPT') {
                arrayofwords.push(item);
            }
        });
        setClassificationOptions(arrayofwords);
    }, [accountClassification]);

    const formik = useFormik({
        initialValues: {
            accountclassification: {}
        }
    });

    //get Account Classification API
    const getAccountClassification = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getclientroles/LKP_ACCOUNT_CLASSIFICATION:*');
        setAccountClassification(res?.payLoad);
    };

    const handleChange = async (e) => {
        const selectedOption = classificationOptions.find((option) => option.lovId === e.target.value);
        if (selectedOption) {
            formik.setValues({ accountclassification: selectedOption.lovId });
            setClassificationObj(selectedOption);
        }
    };

    useEffect(() => {
        getAccountClassification();
    }, []);
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="m-3 Form__Header Full__Width">
                            <h2>ADD NEW</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountclassification" className="Label__Text">
                                    Account Classification
                                </label>
                                <Dropdown
                                    id="accountclassification"
                                    placeholder="Select Account Classification"
                                    options={classificationOptions}
                                    optionLabel="name"
                                    name="accountclassification"
                                    optionValue="lovId"
                                    value={formik.values.accountclassification || ''}
                                    onChange={handleChange}
                                    className="Dropdown__Round"
                                />
                            </div>
                        </div>
                    </div>
                </form>

                {classificationObj.code === 'FRN' ? (
                    <AddFranchise parentData={classificationObj} />
                ) : classificationObj.code === 'RTL' ? (
                    <AddRetailer parentData={classificationObj} />
                ) : classificationObj.code === 'HND' ? (
                    <AddHandler parentData={classificationObj} />
                ) : classificationObj.code === 'CPT' ? (
                    <AddCorporate parentData={classificationObj} />
                ) : (
                    <></>
                )}
            </div>
        </>
    );
}

export default AddNewAgentOnboarding;

import { Button } from 'primereact/button';
import React from 'react';
import { baseURL } from '../../Config';

function ImageDataModal({ merchantData, onHideDialog, cnicFrontActive, cnicBackActive, selfieActive, proofOfBusinessActive }) {
    return (
        <>
            {cnicFrontActive && <img alt="image" style={{ height: '200px', width: '30vw' }} src={`${baseURL}${merchantData?.cnicFront}`} />}
            {cnicBackActive && <img alt="image" style={{ height: '200px', width: '30vw' }} src={`${baseURL}${merchantData?.cnicBack}`} />}
            {selfieActive && <img alt="image" style={{ height: '200px', width: '30vw' }} src={`${baseURL}${merchantData?.selfie}`} />}
            {proofOfBusinessActive && <img alt="image" style={{ height: '200px', width: '30vw' }} src={`${baseURL}${merchantData?.proofOfBusiness}`} />}
            <div className="Down__Btn">
                <Button label="Ok" className="Btn__Add" onClick={onHideDialog} />
            </div>
        </>
    );
}

export default ImageDataModal;

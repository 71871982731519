import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function SwitchConfigurationCheckerView() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const tableName = query.get('tableName');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const requestTypeDescr = query.get('requestTypeDescr');

    const socketTypeOptions = [
        {
            name: 'Server',
            value: 'S'
        },
        {
            name: 'Client',
            value: 'C'
        }
    ];
    const statusOptions = [
        {
            name: 'Active',
            value: 'Y'
        },
        {
            name: 'Inactive',
            value: 'N'
        }
    ];

    const formik = useFormik({
        initialValues: {
            clientCode: '',
            clientName: '',
            safRetries: '',
            safRetriesTime: '',
            socketName: '',
            socketIp: '',
            socketPort: '',
            socketType: '',
            socketUrl: '',
            socketStatus: '',
            queueExpiryTime: '',
            queueSize: '',
            queueStatus: '',
            action: '',
            checkerComments: ''
        }
    });

    //get Data by table Id
    const getDatabyId = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    client: { id: refTableId }
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/communication-service/client/'));

        formik.setFieldValue('clientCode', res?.payLoad?.clientCode);
        formik.setFieldValue('clientName', res?.payLoad?.clientName);
        formik.setFieldValue('safRetries', res?.payLoad?.safConfig?.safEntries);
        formik.setFieldValue('safRetriesTime', res?.payLoad?.safConfig?.safEntriesTime);
        formik.setFieldValue('socketName', res?.payLoad?.socket?.socketName);
        formik.setFieldValue('socketIp', res?.payLoad?.socket?.socketIp);
        formik.setFieldValue('socketPort', res?.payLoad?.socket?.socketPort);
        formik.setFieldValue('socketType', res?.payLoad?.socket?.socketType);
        formik.setFieldValue('socketUrl', res?.payLoad?.socket?.socketUrl);
        formik.setFieldValue('socketStatus', res?.payLoad?.socket?.isActive);
        formik.setFieldValue('queueExpiryTime', res?.payLoad?.queue?.queueExpityTime);
        formik.setFieldValue('queueSize', res?.payLoad?.queue?.queueLimitSize);
        formik.setFieldValue('queueStatus', res?.payLoad?.queue?.isActive);
    };

    //Get data by mc request Id
    const getMcRequestDataById = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    id: mcRequestId
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/communication-service/client/checker'));

        if (requestTypeDescr !== 'Enable/Disable Request') {
            formik.setFieldValue('clientCode', res?.payLoad?.client?.clientCode);
            formik.setFieldValue('clientName', res?.payLoad?.client?.clientName);
            formik.setFieldValue('safRetries', res?.payLoad?.safConfig?.safEntries);
            formik.setFieldValue('safRetriesTime', res?.payLoad?.safConfig?.safEntriesTime);
            formik.setFieldValue('socketName', res?.payLoad?.socket?.name);
            formik.setFieldValue('socketIp', res?.payLoad?.socket?.ip);
            formik.setFieldValue('socketPort', res?.payLoad?.socket?.port);
            formik.setFieldValue('socketType', res?.payLoad?.socket?.type);
            formik.setFieldValue('socketUrl', res?.payLoad?.socket?.url);
            formik.setFieldValue('socketStatus', res?.payLoad?.socket?.status);
            formik.setFieldValue('queueExpiryTime', res?.payLoad?.queue?.expiryTime);
            formik.setFieldValue('queueSize', res?.payLoad?.queue?.size);
            formik.setFieldValue('queueStatus', res?.payLoad?.queue?.status);
        }
    };

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getMcRequestDataById();
        } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
            getDatabyId();
            getMcRequestDataById();
        }
    }, []); // eslint-disable-line

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round riskDashboardPage">
                <form className="p-fluid p-mt-2">
                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">ADD CLIENT</div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="clientCode" className={classNames({ 'p-error': isFormFieldValid('clientCode') }, 'Label__Text')}>
                                    Client Code
                                </label>
                                <InputText
                                    id="clientCode"
                                    name="clientCode"
                                    placeholder="Enter Client Code"
                                    value={formik?.values?.clientCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('clientCode') }, 'Input__Round')}
                                    aria-required
                                    disabled
                                />
                                {getFormErrorMessage('clientCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="clientName" className={classNames({ 'p-error': isFormFieldValid('clientName') }, 'Label__Text')}>
                                    Client Name
                                </label>
                                <InputText
                                    id="clientName"
                                    name="clientName"
                                    placeholder="Enter Client Name"
                                    value={formik?.values?.clientName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('clientName') }, 'Input__Round')}
                                    aria-required
                                    disabled
                                />
                                {getFormErrorMessage('clientName')}
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">SAF RETRIES</div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="safRetries" className={classNames({ 'p-error': isFormFieldValid('safRetries') }, 'Label__Text')}>
                                    SAF Retries
                                </label>
                                <InputText
                                    id="safRetries"
                                    name="safRetries"
                                    placeholder="Enter SAF Retries"
                                    value={formik?.values?.safRetries || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('safRetries') }, 'Input__Round')}
                                    aria-required
                                    disabled
                                />
                                {getFormErrorMessage('safRetries')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="safRetriesTime" className={classNames({ 'p-error': isFormFieldValid('safRetriesTime') }, 'Label__Text')}>
                                    SAF Retries Time (in milli's)
                                </label>
                                <InputText
                                    id="safRetriesTime"
                                    name="safRetriesTime"
                                    placeholder="Enter SAF Retries Time"
                                    value={formik?.values?.safRetriesTime || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('safRetriesTime') }, 'Input__Round')}
                                    aria-required
                                    disabled
                                />
                                {getFormErrorMessage('safRetriesTime')}
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">SOCKET CONFIGURATION</div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketName" className={classNames({ 'p-error': isFormFieldValid('socketName') }, 'Label__Text')}>
                                    Socket Name
                                </label>
                                <InputText
                                    id="socketName"
                                    name="socketName"
                                    placeholder="Enter Socket Name"
                                    value={formik?.values?.socketName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('socketName') }, 'Input__Round')}
                                    aria-required
                                    disabled
                                />
                                {getFormErrorMessage('socketName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketIp" className={classNames({ 'p-error': isFormFieldValid('socketIp') }, 'Label__Text')}>
                                    Socket IP
                                </label>
                                <InputText
                                    id="socketIp"
                                    name="socketIp"
                                    placeholder="Enter Socket IP"
                                    value={formik?.values?.socketIp || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('socketIp') }, 'Input__Round')}
                                    aria-required
                                    disabled
                                />
                                {getFormErrorMessage('socketIp')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketPort" className={classNames({ 'p-error': isFormFieldValid('socketPort') }, 'Label__Text')}>
                                    Port
                                </label>
                                <InputText
                                    id="socketPort"
                                    placeholder="Enter Port Number"
                                    name="socketPort"
                                    value={formik.values.socketPort}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('socketPort') }, 'Input__Round')}
                                    disabled
                                />
                                {getFormErrorMessage('socketPort')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketType" className={classNames({ 'p-error': isFormFieldValid('socketType') }, 'Label__Text')}>
                                    Socket Type
                                </label>
                                <Dropdown
                                    id="socketType"
                                    placeholder="Select Socket Type"
                                    options={socketTypeOptions}
                                    optionLabel="name"
                                    optionValue="value"
                                    name="socketType"
                                    value={formik.values.socketType}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('socketType') }, 'Dropdown__Round')}
                                    disabled
                                />
                                {getFormErrorMessage('socketType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-8">
                            <div className="p-field">
                                <label htmlFor="socketUrl" className={classNames({ 'p-error': isFormFieldValid('socketUrl') }, 'Label__Text')}>
                                    Socket URL
                                </label>
                                <InputText
                                    id="socketUrl"
                                    name="socketUrl"
                                    placeholder="Enter Socket URL"
                                    value={formik?.values?.socketUrl || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('socketUrl') }, 'Input__Round')}
                                    aria-required
                                    disabled
                                />
                                {getFormErrorMessage('socketUrl')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketStatus" className={classNames({ 'p-error': isFormFieldValid('socketStatus') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="socketStatus"
                                    placeholder="Select Socket Status"
                                    options={statusOptions}
                                    optionLabel="name"
                                    optionValue="value"
                                    name="socketStatus"
                                    value={formik.values.socketStatus}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('socketStatus') }, 'Dropdown__Round')}
                                    disabled
                                />
                                {getFormErrorMessage('socketStatus')}
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">QUEUE CONFIGURATION</div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="queueExpiryTime" className={classNames({ 'p-error': isFormFieldValid('queueExpiryTime') }, 'Label__Text')}>
                                    Expiry Time
                                </label>
                                <InputText
                                    id="queueExpiryTime"
                                    name="queueExpiryTime"
                                    type="time"
                                    placeholder="Enter Expiry Time"
                                    value={formik?.values?.queueExpiryTime || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('queueExpiryTime') }, 'Input__Round')}
                                    aria-required
                                    disabled
                                />
                                {getFormErrorMessage('queueExpiryTime')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="queueSize" className={classNames({ 'p-error': isFormFieldValid('queueSize') }, 'Label__Text')}>
                                    Queue Size
                                </label>
                                <InputText
                                    id="queueSize"
                                    name="queueSize"
                                    placeholder="Enter Queue Size"
                                    value={formik?.values?.queueSize || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('queueSize') }, 'Input__Round')}
                                    aria-required
                                    disabled
                                />
                                {getFormErrorMessage('queueSize')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="queueStatus" className={classNames({ 'p-error': isFormFieldValid('queueStatus') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="queueStatus"
                                    placeholder="Select Queue Status"
                                    options={statusOptions}
                                    optionLabel="name"
                                    optionValue="value"
                                    name="queueStatus"
                                    value={formik.values.queueStatus}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('queueStatus') }, 'Dropdown__Round')}
                                    disabled
                                />

                                {getFormErrorMessage('queueStatus')}
                            </div>
                        </div>
                    </div>

                    <div className="Usergroup__SearchBtn p-field col-12  flex justify-content-center">
                        <Button
                            label="Okay"
                            type="button"
                            onClick={() => {
                                navigate('/requestchecker');
                            }}
                            className="Btn__Dark__Search"
                        />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default SwitchConfigurationCheckerView;

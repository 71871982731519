import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Steps } from 'primereact/steps';
import EditProductsChecker from './Components/Product/EditProductChecker';
import EditPricingchecker from './Components/Pricing/EditPricingChecker';
import EditRepaymentMethodChecker from './Components/RepaymentMethod/EditRepaymentMethodChecker';
import EditDocumentChecker from './Components/Document/EditDocumentChecker';
import EditTermsAndConditionsChecker from './Components/Terms&Conditiom/EditTermsAndConditionChecker';
import { useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import * as Yup from 'yup';
import { loadingAction } from '../../../../redux/actions/loadingAction';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function EditLendingManagementChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    const [statusLovData, setStatusLovData] = useState([]);
    const [lendingData, setLendingData] = useState({});
    const [activeIndex, setActiveIndex] = useState(4);
    const [lendingDataU, setLendingDataU] = useState([]);

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    const getLendingDataById = async () => {
        setloading(true);
        dispatch(loadingAction(true));
        const response = await handleGetRequest(`/lending/v1/lending/getlmsproductbyid/${refTableId}`);

        setLendingData(response?.payLoad);
        dispatch(loadingAction(false));
        setloading(false);
    };

    const getMcRequestDataById = async () => {
        setloading(true);
        dispatch(loadingAction(true));
        const res = await handleGetRequest(`/lending/v1/lending/getmcrequestLmsProductbyid/${mcRequestId}`);
        setLendingData(res?.payLoad);
        dispatch(loadingAction(false));
        setloading(false);
    };

    const getMcRequestDataByIdUByInActive = async () => {
        setloading(true);
        dispatch(loadingAction(true));
        const res = await handleGetRequest(`/lending/v1/lending/getmcrequestLmsProductbyid/${mcRequestId}`);
        setLendingDataU(res?.payLoad);
        dispatch(loadingAction(false));
        setloading(false);
    };

    useEffect(() => {
        getStatusLov();
    }, []);

    useEffect(() => {
        if (requestType === 'I') {
            getLendingDataById();
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getMcRequestDataById();
        } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
            getLendingDataById();
            getMcRequestDataByIdUByInActive();
        }
    }, []); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        action: Yup.mixed().required('This field is required.'),
        checkerComments: Yup.mixed().required('This field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            lmsProductId: '',
            action: '',
            checkerComments: ''
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newDataForI = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        lmsProductRequestList: [
                            {
                                lmsProductId: parseInt(refTableId),
                                isActive: 'Y',
                                checkerRequestType: requestType
                            }
                        ],
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const newDataForU = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        lmsProductRequestList: requestType === 'U' && requestTypeDescr === 'Enable/Disable Request' ? lendingDataU : lendingData,
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(requestType === 'I' ? newDataForI : newDataForU, '/lending/v1/lending/lmsproductcheckeraction', true, false, 'requestchecker'));
            if (res?.responseCode === process.env.REACT_APP_LENDING_SUCCESS_CODE) {
                formik.resetForm();
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const items = [
        {
            label: 'Product',
            command: (e) => handleStepClick(e.index)
            // disabled: requestType === 'I' ? lendingData?.currentStep >= 0 ? false : true : lendingData[0]?.stepNo >= 0 ? false : true
        },
        {
            label: 'Pricing',
            command: (e) => handleStepClick(e.index)
            // disabled: requestType === 'I' ? lendingData?.currentStep >= 0 ? false : true : lendingData[0]?.stepNo >= 0 ? false : true
        },
        {
            label: 'Repayment Methods',
            command: (e) => handleStepClick(e.index)
            // disabled: requestType === 'I' ? lendingData?.currentStep >= 0 ? false : true : lendingData[0]?.stepNo >= 0 ? false : true
        },
        {
            label: 'Document',
            command: (e) => handleStepClick(e.index)
            // disabled: requestType === 'I' ? lendingData?.currentStep >= 0 ? false : true : lendingData[0]?.stepNo >= 0 ? false : true
        },
        {
            label: 'Terms & Condition',
            command: (e) => handleStepClick(e.index)
            // disabled: requestType === 'I' ? lendingData?.currentStep >= 0 ? false : true : lendingData[0]?.stepNo >= 0 ? false : true
        }
    ];

    const handleStepClick = (index) => {
        setActiveIndex(index);
    };

    return (
        <>
            <div className="card Card__Round">
                <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} style={{ margin: '50px 0px', padding: '50px 0px' }} className="CustomerRisk__Steps" />
                <div className="Form__Header">
                    <h1>EDIT LENDING PRODUCT CHECKER</h1>
                </div>
                {activeIndex === 0 && <EditProductsChecker lendingData={lendingData} />}
                {activeIndex === 1 && <EditPricingchecker lendingData={lendingData} />}
                {activeIndex === 2 && <EditRepaymentMethodChecker lendingData={lendingData} requestType={requestType} />}
                {activeIndex === 3 && <EditDocumentChecker lendingData={lendingData} />}
                {activeIndex === 4 && <EditTermsAndConditionsChecker lendingData={lendingData} />}

                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    optionDisabled={(option) => option?.name === 'Pending'}
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    autoResize={false}
                                    rows={5}
                                    cols={30}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} label="Update" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default EditLendingManagementChecker;

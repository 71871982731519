import React, { useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';

function ViewReporting() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    const dataTableRef = useRef(null);

    const validationSchema = Yup.object().shape({});

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            domain: '',
            createdBy: '',
            createdOn: '',
            statusId: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            dataTableRef.current.reset();

            setloading(false);
            setloadingIcon('pi pi-search');

            dataTableRef.current.reset();
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">Device Financing Reporting Format</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    {/* <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." /> */}
                </span>
            </div>
        );
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    // const previousDate = currentDate.toISOString().split('T')[0];

    const header = renderHeader();

    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const reportingDataTable = [
        { id: 1, name: 'Mutual Fund Loan' },
        { id: 2, name: 'Advance Salary' },
        { id: 3, name: 'Device Fianacing' },
        { id: 4, name: 'Advance Cash' },
        { id: 3, name: 'ECIB Reporting' }
    ];

    const genderLov = [
        { id: 1, gender: 'Male' },
        { id: 2, gender: 'Female' }
    ];
    return (
        <>
            {/* <ActiveInActiveDialog {...props} /> */}
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="col-12">
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="domain" className={classNames({ 'p-error': isFormFieldValid('domain') }, 'Label__Text')}>
                                        Domain
                                    </label>
                                    <Dropdown
                                        id="domain"
                                        placeholder="Select Domain"
                                        options={reportingDataTable}
                                        optionLabel="name"
                                        name="domain"
                                        optionValue="name"
                                        value={formik.values.domain || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('domain') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('domain')}
                                </div>
                            </div>
                        </div>
                        {formik.values.domain === 'Mutual Fund Loan' && (
                            <>
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="bussinessDate" className={classNames({ 'p-error': isFormFieldValid('bussinessDate') }, 'Label__Text')}>
                                            Bussiness Date
                                        </label>
                                        <InputText
                                            id="bussinessDate"
                                            placeholder=""
                                            name="bussinessDate"
                                            type="date"
                                            // max={currentDate}
                                            value={formik?.values?.bussinessDate?.replace(/\s\s+/g, ' ') || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('bussinessDate') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('bussinessDate')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="gender" className={classNames({ 'p-error': isFormFieldValid('gender') }, 'Label__Text')}>
                                            Gender
                                        </label>
                                        <Dropdown
                                            id="gender"
                                            placeholder="Select Gender"
                                            options={genderLov}
                                            optionLabel="gender"
                                            name="gender"
                                            optionValue="id"
                                            value={formik.values.gender || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('gender') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('gender')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="age" className={classNames({ 'p-error': isFormFieldValid('age') }, 'Label__Text')}>
                                            Age
                                        </label>
                                        <InputText
                                            maxLength={100}
                                            id="age"
                                            placeholder="Enter Age"
                                            name="age"
                                            value={formik?.values?.age?.replace(/\s\s+/g, ' ') || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('age') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('age')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="customerName" className={classNames({ 'p-error': isFormFieldValid('customerName') }, 'Label__Text')}>
                                            Customer Name
                                        </label>
                                        <InputText
                                            maxLength={100}
                                            id="customerName"
                                            placeholder="Enter Customer Name"
                                            name="customerName"
                                            value={formik?.values?.customerName?.replace(/\s\s+/g, ' ') || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('customerName') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('customerName')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="loanToValue" className={classNames({ 'p-error': isFormFieldValid('loanToValue') }, 'Label__Text')}>
                                            Loan To Value%
                                        </label>
                                        <InputText
                                            maxLength={15}
                                            id="loanToValue"
                                            placeholder="Enter Loan To Value"
                                            name="loanToValue"
                                            value={formik?.values?.loanToValue || ''}
                                            onChange={formik.handleChange}
                                            keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                            className={classNames({ 'p-invalid': isFormFieldValid('loanToValue') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('loanToValue')}
                                    </div>
                                </div>
                            </>
                        )}

                        {formik.values.domain === 'Advance Salary' && (
                            <>
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="bussinessDate" className={classNames({ 'p-error': isFormFieldValid('bussinessDate') }, 'Label__Text')}>
                                            Bussiness Date
                                        </label>
                                        <InputText
                                            id="bussinessDate"
                                            placeholder=""
                                            name="bussinessDate"
                                            type="date"
                                            // max={currentDate}
                                            value={formik?.values?.bussinessDate?.replace(/\s\s+/g, ' ') || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('bussinessDate') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('bussinessDate')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="transactionDate" className={classNames({ 'p-error': isFormFieldValid('transactionDate') }, 'Label__Text')}>
                                            Transaction Date
                                        </label>
                                        <Dropdown
                                            id="transactionDate"
                                            placeholder="Select Transaction Date"
                                            options={[]}
                                            optionLabel="transactionDate"
                                            name="transactionDate"
                                            optionValue="id"
                                            value={formik.values.transactionDate || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('transactionDate') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('transactionDate')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="dateOfJoining" className={classNames({ 'p-error': isFormFieldValid('dateOfJoining') }, 'Label__Text')}>
                                            Date Of Joining
                                        </label>
                                        <InputText
                                            id="dateOfJoining"
                                            placeholder="Select Date Of Joining"
                                            name="dateOfJoining"
                                            type="date"
                                            value={formik?.values?.dateOfJoining || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('dateOfJoining') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('dateOfJoining')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="customerName" className={classNames({ 'p-error': isFormFieldValid('customerName') }, 'Label__Text')}>
                                            Customer Name
                                        </label>
                                        <InputText
                                            maxLength={100}
                                            id="customerName"
                                            placeholder="Enter Customer Name"
                                            name="customerName"
                                            value={formik?.values?.customerName?.replace(/\s\s+/g, ' ')?.toString() || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('customerName') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('customerName')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="segment" className={classNames({ 'p-error': isFormFieldValid('segment') }, 'Label__Text')}>
                                            Segment
                                        </label>
                                        <MultiSelect
                                            filter
                                            id="segment"
                                            placeholder="Select Segment"
                                            options={[]}
                                            optionLabel="name"
                                            name="segment"
                                            optionValue="lovId"
                                            value={formik.values.segment || []}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('segment') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('segment')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="loanDueDate" className={classNames({ 'p-error': isFormFieldValid('loanDueDate') }, 'Label__Text')}>
                                            Loan Due Date
                                        </label>
                                        <InputText
                                            id="loanDueDate"
                                            placeholder="Select Loan Due Date"
                                            name="loanDueDate"
                                            type="date"
                                            value={formik?.values?.loanDueDate || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('loanDueDate') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('loanDueDate')}
                                    </div>
                                </div>
                            </>
                        )}

                        {formik.values.domain === 'Device Fianacing' && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="customerCnic" className={classNames({ 'p-error': isFormFieldValid('customerCnic') }, 'Label__Text')}>
                                            Customer CNIC
                                        </label>
                                        <InputText
                                            maxLength={100}
                                            id="customerCnic"
                                            placeholder="Enter Customer CNIC"
                                            name="customerCnic"
                                            value={formik?.values?.customerCnic?.replace(/\s\s+/g, ' ')?.toString() || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('customerCnic') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('customerCnic')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="customerName" className={classNames({ 'p-error': isFormFieldValid('customerName') }, 'Label__Text')}>
                                            Customer Name
                                        </label>
                                        <InputText
                                            maxLength={100}
                                            id="customerName"
                                            placeholder="Enter Customer Name"
                                            name="customerName"
                                            value={formik?.values?.customerName?.replace(/\s\s+/g, ' ')?.toString() || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('customerName') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('customerName')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="loanAmount" className={classNames({ 'p-error': isFormFieldValid('loanAmount') }, 'Label__Text')}>
                                            Loan Amount
                                        </label>
                                        <InputText
                                            maxLength={100}
                                            id="loanAmount"
                                            placeholder="Enter Loan Amount"
                                            name="loanAmount"
                                            value={formik?.values?.loanAmount?.replace(/\s\s+/g, ' ')?.toString() || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('loanAmount') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('loanAmount')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="loanTenureMonths" className={classNames({ 'p-error': isFormFieldValid('loanTenureMonths') }, 'Label__Text')}>
                                            Loan Tenure-months
                                        </label>
                                        <InputText
                                            maxLength={100}
                                            id="loanTenureMonths"
                                            placeholder="Enter Loan Tenure Months"
                                            name="loanTenureMonths"
                                            value={formik?.values?.loanTenureMonths?.replace(/\s\s+/g, ' ')?.toString() || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('loanTenureMonths') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('loanTenureMonths')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="monthlyInstallmentAmount" className={classNames({ 'p-error': isFormFieldValid('monthlyInstallmentAmount') }, 'Label__Text')}>
                                            Monthly Installment Amount
                                        </label>
                                        <InputText
                                            maxLength={100}
                                            id="monthlyInstallmentAmount"
                                            placeholder="Enter Monthly Installment Amount"
                                            name="monthlyInstallmentAmount"
                                            value={formik?.values?.monthlyInstallmentAmount?.replace(/\s\s+/g, ' ')?.toString() || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('monthlyInstallmentAmount') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('monthlyInstallmentAmount')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="loanStatus" className={classNames({ 'p-error': isFormFieldValid('loanStatus') }, 'Label__Text')}>
                                            Loan Status
                                        </label>
                                        <Dropdown
                                            id="loanStatus"
                                            placeholder="Select Loan Status"
                                            options={[]}
                                            optionLabel="loanStatus"
                                            name="loanStatus"
                                            optionValue="id"
                                            value={formik.values.loanStatus || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('loanStatus') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('loanStatus')}
                                    </div>
                                </div>
                            </>
                        )}

                        {formik.values.domain === 'Advance Cash' && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="customerCnic" className={classNames({ 'p-error': isFormFieldValid('customerCnic') }, 'Label__Text')}>
                                            Customer CNIC
                                        </label>
                                        <InputText
                                            maxLength={100}
                                            id="customerCnic"
                                            placeholder="Enter Customer CNIC"
                                            name="customerCnic"
                                            value={formik?.values?.customerCnic?.replace(/\s\s+/g, ' ')?.toString() || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('customerCnic') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('customerCnic')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="customerName" className={classNames({ 'p-error': isFormFieldValid('customerName') }, 'Label__Text')}>
                                            Customer Name
                                        </label>
                                        <InputText
                                            maxLength={100}
                                            id="customerName"
                                            placeholder="Enter Customer Name"
                                            name="customerName"
                                            value={formik?.values?.customerName?.replace(/\s\s+/g, ' ')?.toString() || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('customerName') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('customerName')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="loanAmount" className={classNames({ 'p-error': isFormFieldValid('loanAmount') }, 'Label__Text')}>
                                            Loan Amount
                                        </label>
                                        <InputText
                                            maxLength={100}
                                            id="loanAmount"
                                            placeholder="Enter Loan Amount"
                                            name="loanAmount"
                                            value={formik?.values?.loanAmount?.replace(/\s\s+/g, ' ')?.toString() || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('loanAmount') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('loanAmount')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="loanId" className={classNames({ 'p-error': isFormFieldValid('loanId') }, 'Label__Text')}>
                                            Loan Id
                                        </label>
                                        <InputText
                                            maxLength={100}
                                            id="loanId"
                                            placeholder="Enter Loan Id"
                                            name="loanId"
                                            value={formik?.values?.loanId?.replace(/\s\s+/g, ' ')?.toString() || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('loanId') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('loanId')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="setupFee" className={classNames({ 'p-error': isFormFieldValid('setupFee') }, 'Label__Text')}>
                                            Set-up Fee
                                        </label>
                                        <InputText
                                            maxLength={100}
                                            id="setupFee"
                                            placeholder="Enter Set-up Fee"
                                            name="setupFee"
                                            value={formik?.values?.setupFee?.replace(/\s\s+/g, ' ')?.toString() || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('setupFee') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('setupFee')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="recoverdPrincipal" className={classNames({ 'p-error': isFormFieldValid('recoverdPrincipal') }, 'Label__Text')}>
                                            Recovered Principal
                                        </label>
                                        <Dropdown
                                            id="recoverdPrincipal"
                                            placeholder="Select Recovered Principal"
                                            options={[]}
                                            optionLabel="recoverdPrincipal"
                                            name="recoverdPrincipal"
                                            optionValue="id"
                                            value={formik.values.recoverdPrincipal || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('recoverdPrincipal') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('recoverdPrincipal')}
                                    </div>
                                </div>
                            </>
                        )}

                        {formik.values.domain === 'ECIB Reporting' && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="bankCode" className={classNames({ 'p-error': isFormFieldValid('BankCode') }, 'Label__Text')}>
                                            Bank Code
                                        </label>
                                        <InputText
                                            maxLength={100}
                                            id="bankCode"
                                            placeholder="Enter Bank Code"
                                            name="bankCode"
                                            value={formik?.values?.bankCode?.replace(/\s\s+/g, ' ')?.toString() || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('bankCode') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('bankCode')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="borrowerCnic" className={classNames({ 'p-error': isFormFieldValid('borrowerCnic') }, 'Label__Text')}>
                                            Borrower CNIC
                                        </label>
                                        <InputText
                                            maxLength={100}
                                            id="borrowerCnic"
                                            placeholder="Enter Borrower CNIC"
                                            name="borrowerCnic"
                                            value={formik?.values?.borrowerCnic?.replace(/\s\s+/g, ' ')?.toString() || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('borrowerCnic') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('borrowerCnic')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="nameOfBorrower" className={classNames({ 'p-error': isFormFieldValid('nameOfBorrower') }, 'Label__Text')}>
                                            Name of Borrower
                                        </label>
                                        <InputText
                                            maxLength={100}
                                            id="nameOfBorrower"
                                            placeholder="Enter Name of Borrower"
                                            name="nameOfBorrower"
                                            value={formik?.values?.nameOfBorrower?.replace(/\s\s+/g, ' ')?.toString() || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('nameOfBorrower') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('nameOfBorrower')}
                                    </div>
                                </div>

                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="borroerDOB" className={classNames({ 'p-error': isFormFieldValid('borroerDOB') }, 'Label__Text')}>
                                            Borrower DOB
                                        </label>
                                        <InputText
                                            id="borroerDOB"
                                            placeholder=""
                                            name="borroerDOB"
                                            type="date"
                                            // max={currentDate}
                                            value={formik?.values?.borroerDOB?.replace(/\s\s+/g, ' ') || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('borroerDOB') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('borroerDOB')}
                                    </div>
                                </div>

                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="phoneNumber" className={classNames({ 'p-error': isFormFieldValid('phoneNumber') }, 'Label__Text')}>
                                            Phone Number
                                        </label>
                                        <InputText
                                            maxLength={100}
                                            id="phoneNumber"
                                            placeholder="Enter Phone Number"
                                            name="phoneNumber"
                                            value={formik?.values?.phoneNumber?.replace(/\s\s+/g, ' ')?.toString() || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('phoneNumber') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('phoneNumber')}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="Down__Btn">
                        <Button label="Search" disabled={loading} type="submit" icon={loadingIcon || ''} iconPos="right" className="Btn__Dark" />
                        <Button label="Reset" type="button" iconPos="right" className="Btn__Dark" onClick={(e) => handleReset(e)} />
                    </div>
                </form>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            globalFilterFields={['accountLevelName', 'createuser', 'createdate', 'lkpStatus.statusName']}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={[]}
                        >
                            <Column field="bussinessDate" header="Bussiness Date" />
                            <Column field="customerMsisdn" header="Customer MSISDN" />
                            <Column field="customerName" header="Customer Name" />
                            <Column field="age" header="Age" />
                            <Column field="mutualFundPortfolioAmount" header="Mutual Fund Portfolio Amount" />
                            <Column field="loanToValue" header="Loan To Value" />
                            <Column field="approvedLoanAmount" header="Approved Loan Amount" />
                            <Column field="principalAmount" header="Principal Amount" />
                            <Column field="serviceCharges" header="Service Charges" />
                            <Column field="outstandingLoanAmount" header="Outstanding Loan Amount" />
                            <Column field="principalAmount" header="Principal Amount" />
                            <Column field="recoverdAmount" header="Recovered Amount" />
                            <Column field="purposeOfLoan" header="Purpose Of Loan" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ViewReporting;

import * as Yup from 'yup';

const SlabWiseFixedInitialValues = {
    commissionProfileName: '',
    accountClassificationId: '',
    commissionGlAccountId: '',
    disbursementMethodName: '',
    disbursementTime: null,
    whtCalculationType: '',
    whtGlAccountId: '',
    feeTypeCode: 'SF',
    startDate: '',
    endDate: '',
    products: [],
    commissionSlabRequests: [
        {
            commissionSlabId: null,
            startSlab: '',
            endSlab: '',
            commissionAmount: ''
        }
    ]
};

const validationSchema = Yup.object().shape({
    commissionProfileName: Yup.string()
        .required('This field is required')
        .matches(/^[a-zA-Z0-9\s]*$/, 'Only alpha numeric characters are allowed')
        .max(100, 'Maximum 100 digits allowed'),

    accountClassificationId: Yup.mixed().required('This field is required'),
    commissionGlAccountId: Yup.mixed().required('This field is required'),
    disbursementMethodName: Yup.mixed().required('This field is required'),
    whtCalculationType: Yup.mixed().required('This field is required'),
    whtGlAccountId: Yup.mixed().required('This field is required'),
    startDate: Yup.date().required('This field is required').nullable(),
    endDate: Yup.date().when('startDate', {
        is: (startDate) => !!startDate,
        then: Yup.date().required('End Date is required').min(Yup.ref('startDate'), 'End Date has to be more than Start Date')
    }),
    commissionSlabRequests: Yup.array().of(
        Yup.object().shape({
            startSlab: Yup.number()
                .required('This field is required')
                .test('Is positive?', 'The number must be greater than 0', (value) => value > 0)
                .nullable(),
            endSlab: Yup.number()
                .transform((value, originalValue) => {
                    if (isNaN(originalValue)) {
                        return null;
                    }
                    return value;
                })
                .required('This field is required')
                .test('Is positive?', 'The number must be greater than 0', (value) => value > 0)
                .when('startSlab', {
                    is: (startSlab) => !!startSlab,
                    then: Yup.number().min(Yup.ref('startSlab'), 'End Slab has to be more than Start Slab').nullable()
                })
                .nullable(),
            commissionAmount: Yup.number()
                .required('This field is required')
                .test('Is positive?', 'The number must be greater than 0', (value) => value > 0)
                .nullable()
        })
    )
});

export { SlabWiseFixedInitialValues, validationSchema };

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';

const HistoryDialog = ({ switchData }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData([switchData]);
    }, [switchData]); // eslint-disable-line
    //status color function
    const statusBodyTemplate = (rowData) => {
        // const statusColor = rowData?.statusDesc === 'Approved' ? '#14A38B' : rowData?.statusDesc === 'Pending' ? '#F2AC57' : '#FF7171';

        return (
            <p style={{ color: '#14A38B' }}>
                <b>{rowData?.statusdescr}</b>
            </p>
        );
    };
    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable rows={5} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={data}>
                            <Column field="rrn" header="Trx Time" />
                            <Column field="noofretries" header="Number of Retry" />
                            <Column field="transactionamount" header="Amount" />
                            <Column field="stan" header="STAN" />
                            <Column field="rrn" header="RRN" />
                            <Column field="productname" header="Product Name" />
                            <Column field="transactioncode" header="Transaction ID" />
                            <Column body={statusBodyTemplate} header="Status" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HistoryDialog;

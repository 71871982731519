import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';

import { handlePostRequest } from '../../../../services/PostTemplate';
import { useDispatch } from 'react-redux';
import { handlePostFileRequest } from '../../../../services/PostFileTemplate';
import { Image } from 'primereact/image';

function DialogViewCustomerDoc({ onHideViewCustomerDoc, customerCnic, customerId }) {
    const [viewCustomerDocData, setViewCustomerDocData] = useState([]);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const getCustomerAccountDocs = async () => {
        setLoading(true);
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    customerId: customerId
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newData, '/documents/v1/customeraccounts/getcustomeraccountdocs', true, true, 'accountsmanagement'));
        if (res?.responseCode === '070000') {
            setViewCustomerDocData(res?.payLoad);
        }
        setLoading(false);
    };

    useEffect(() => {
        getCustomerAccountDocs();
    }, []);

    const handledDownload = async (e, documentTypeId, documentName, documentType) => {
        e.preventDefault();

        // Define your request data
        const requestData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    cnic: customerCnic,
                    documentType: documentTypeId
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        try {
            // Make the API request to get the blob data
            const response = await dispatch(handlePostFileRequest(requestData, '/documents/v1/customerdoc/downloadcustomerdoc', true, true));

            // Create a Blob from the response data
            const blob = await new Response(response).blob(); // Use response.blob() directly

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob); // Use URL.createObjectURL directly

            // Create a link element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.download = `${documentName}${documentType}`; // Set the desired file name for the download

            // Append the link element to the DOM and trigger the download
            document.body.appendChild(link);
            link.click();

            // Clean up the URL and remove the link element
            URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {}
    };

    return (
        <>
            <div className="">
                <div className="grid">
                    {!loading && viewCustomerDocData ? (
                        viewCustomerDocData.map((document) => (
                            <div className="col-12 md:col-6 lg:col-6" key={document?.documentId}>
                                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                    <div>{document?.lkpDocumentType?.documentTypeName}</div>
                                    <div className="mb-3 text-center">
                                        <Image src={`data:image/png;base64,${document?.documentPath}`} alt="Image" preview imageStyle={{ height: '125px', width: '100%', objectFit: 'cover' }} />
                                    </div>
                                    <div className="Down__Btn">
                                        <Button
                                            label="Download"
                                            iconPos="right"
                                            className="Btn__Dark"
                                            onClick={(e) => handledDownload(e, document?.lkpDocumentType?.documentTypeId, document?.lkpDocumentType?.documentTypeName, document?.documentExt)}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : loading ? (
                        <div style={{ padding: '25px' }} className="w-full text-center mt-4">
                            <i className="pi pi-spin pi-spinner" style={{ fontSize: '2em' }}></i>
                        </div>
                    ) : (
                        <div>No records found!</div>
                    )}
                </div>

                <div className="Down__Btn">
                    <Button
                        label="Cancel"
                        onClick={(e) => {
                            e.preventDefault();
                            onHideViewCustomerDoc();
                        }}
                        className={`Btn__Transparent ${!loading ? 'mt-4' : ''}`}
                    />
                </div>
            </div>
        </>
    );
}

export default DialogViewCustomerDoc;

import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import ActiveInActiveDialog from '../../../../components/ActiveInActiveDialog/ActiveInActiveDialog';

const NadraScreen = () => {
    const [statusLovData, setStatusLovData] = useState([]);
    const [DeviceType, SetDeviceType] = useState([]);
    const [statusData, setStatusData] = useState([]);
    const [switchData, setSwitchData] = useState([]);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    const [nadraTableData, setNadraTableData] = useState([]);
    const [callTypeLovData, setCallTypeLovData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const dataTableRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        }),
        attemptsTime: Yup.number()
            .nullable()
            .typeError('Input must be a number')

            // .max(24, 'Input must be less than or equal to 24 hours')

            .test('is-one-decimal', 'Input must have only one digit after decimal point', (value) => {
                // If the field is empty, it's valid (unless required).
                if (!value) return true;
                // Check if the value matches the pattern for one decimal place.
                return /^\d+(\.\d)?$/.test(value);
            })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            bvsLimit: '',
            idLimit: '',
            createdBy: '',

            attempts: '',
            attemptsTime: '',

            createDate: '',
            createdBy: '',
            statusId: '2'
        },

        onSubmit: async (data) => {
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(newData, '/cms/v1/cms/searchallnadraconfig', true, true, 'nadra'));

            if (res?.responseCode === '220000') {
                setNadraTableData(res?.payLoad);
                formik.resetForm();
            }
            setloadingIcon('pi pi-search');
        }
    });

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <InputSwitch
                        className="mr-3"
                        checked={rowData.isActive === 'Y'}
                        disabled={['Pending', 'Rejected'].includes(rowData?.statusName) ? true : false}
                        onChange={() => {
                            setSwitchData(rowData);
                            setShowModal(true);
                        }}
                    />

                    <Button
                        tooltip="Edit"
                        icon="pi pi-pencil"
                        disabled={['Pending', 'Rejected'].includes(rowData?.statusName) || (rowData?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                        tooltipOptions={{ position: 'top' }}
                        onClick={() => editNadra(rowData)}
                        className="p-button-rounded p-button-warning mr-3"
                    />
                    <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => viewNadra(rowData)} className="p-button-rounded p-button-primary" />
                </div>
            </>
        );
    };
    const handleSwitchChange = async () => {
        let prodId = '';
        prodId = switchData?.cmsNadraConfigId;
        const IdJsonObj = {
            data: {
                payLoad: {
                    id: prodId
                }
            }
        };
        const res = await dispatch(handlePostRequest(IdJsonObj, '/cms/v1/cms/inactivenadraconfig', true, false));
    };
    const addNewNadra = () => {
        navigate('/addNadra');
    };
    const editNadra = (rowData) => {
        navigate(`/editNadra`, { state: { rowData } });
    };
    const viewNadra = (rowData) => {
        navigate(`/viewNadra`, { state: { rowData } });
    };

    const getUserLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');

        setStatusLovData(res?.payLoad);
    };

    const getStatusLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusData(res?.payLoad);
    };

    useEffect(() => {
        getUserLov();

        getStatusLov();
    }, []);

    const statusBodyTemplate = (rowData) => {
        const statusName = statusData?.find((option) => option.lovId == rowData.statusId)?.name;
        const statusColor = statusName === 'Approved' ? '#14A38B' : statusName === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{statusName}</b>
            </p>
        );
    };
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const resetHandle = (e) => {
        e.preventDefault();
        formik.resetForm();
    };
    const handleShowModal = () => {
        setShowModal(false);
    };
    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };
    const lastUpdateDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.lastupdatedate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const props = {
        showModal,
        handleShowModal,
        handleSwitchChange
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <ActiveInActiveDialog {...props} />
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={() => addNewNadra()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>

            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="bvsLimit" className={classNames({ 'p-error': isFormFieldValid('bvsLimit') }, 'Label__Text')}>
                                    BVS Limit
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="bvsLimit"
                                    placeholder="Enter BVS Limit"
                                    name="bvsLimit"
                                    value={formik?.values?.bvsLimit}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('bvsLimit') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('bvsLimit')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="idLimit" className={classNames({ 'p-error': isFormFieldValid('idLimit') }, 'Label__Text')}>
                                    ID Limit
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="idLimit"
                                    placeholder="Enter ID Limit"
                                    name="idLimit"
                                    value={formik?.values?.idLimit}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('bvsLimit') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('idLimit')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="attempts" className={classNames({ 'p-error': isFormFieldValid('attempts') }, 'Label__Text')}>
                                    Attempts
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="attempts"
                                    placeholder="Enter Attempts"
                                    name="attempts"
                                    value={formik?.values?.attempts}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('attempts') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('attempts')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="attemptsTime" className={classNames({ 'p-error': isFormFieldValid('attemptsTime') }, 'Label__Text')}>
                                    Time
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="attemptsTime"
                                    placeholder="Enter Time In Minutes"
                                    name="attemptsTime"
                                    value={formik?.values?.attemptsTime}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('attemptsTime') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('attemptsTime')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createDate" className={classNames({ 'p-error': isFormFieldValid('createDate') }, 'Label__Text')}>
                                    Created on
                                </label>
                                <InputText
                                    id="createDate"
                                    type="date"
                                    name="createDate"
                                    max={previousDate}
                                    value={formik?.values?.createDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createDate') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('createDate')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="statusId" className={classNames({ 'p-error': isFormFieldValid('statusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select Status"
                                    options={statusData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>

                        <div className="Down__Btn">
                            <Button loadingIcon={loadingIcon} label="Search" className="Btn__Dark" />
                            <Button label="Reset" onClick={(e) => resetHandle(e)} className="Btn__Transparent" />
                        </div>
                    </div>
                </form>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            globalFilterFields={['accountLevelName', 'createuser', 'createdate', 'lkpStatus.statusName']}
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={nadraTableData}
                        >
                            <Column field="bvsLimit" header="BVS Limit" />
                            <Column field="idLimit" header="ID Limit" />
                            <Column align={'center'} field="attempts" header="Number Of Attempts" />
                            <Column field="createdBy" header="Created By" />
                            <Column field="updatedBy" header="updated By" />

                            <Column header="Created date" body={createdDateAndTimeBodyTemplate} />
                            <Column header="Last Updated" body={lastUpdateDateAndTimeBodyTemplate} />

                            <Column header="Status" body={statusBodyTemplate} />

                            <Column header="Action" body={actionBodyTemplate} />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NadraScreen;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { useFormik } from 'formik';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useDispatch } from 'react-redux';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import classNames from 'classnames';
import { Sidebar } from 'primereact/sidebar';
import ProductCatalogOldNewValues from './ProductCatalogOldNewValues';

function ProductCatalogCheckerEdit() {
    const [listOfTransaction, setListOfTransaction] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [loading, setloading] = useState(false);
    const [oldNewData, setOldNewData] = useState([]);
    const [highlightedKeys, setHighlightedKeys] = useState([]);
    const [productCatalogRowData, setProductCatalogRowData] = useState();
    const [displayDialog, setDisplayDialog] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    //get all data from the url
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const formik = useFormik({
        initialValues: {
            productCatalogId: '',
            productCatalogName: '',
            productCatalogDescription: '',
            transactionList: [],
            action: '',
            checkerComments: '',
            active: ''
        },
        validate: (data) => {
            let errors = {};
            ////////////////////////action/////////////////////

            if (data?.action.length === 0) {
                errors.action = 'Action should not be empty';
            }
            ////////////////////////checkerComments/////////////////////

            if (!data?.checkerComments) {
                errors.checkerComments = 'This Field is required';
            } else if (data?.checkerComments === '') {
                errors.checkerComments = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.checkerComments)) {
                errors.checkerComments = 'Leading and trailing space is not allowed';
            } else if (!/^[a-zA-Z](?:[a-zA-Z0-9\s]*-?[a-zA-Z0-9\s]*)?$/.test(data?.checkerComments)) {
                errors.checkerComments = 'Invalid Channel Name format';
            }

            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);

            const arrayOfTransactionIds = formik.values.transactionList.map((item) => parseInt(item?.lovId));
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        productCatalogRequest: {
                            productCatalogName: data.productCatalogName,
                            productCatalogDescription: data.productCatalogDescription,
                            productCatalogId: data.productCatalogId,
                            active: data?.active,
                            transactionList: arrayOfTransactionIds
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: 1,
                            checkerComments: data.checkerComments,
                            action: data.action,
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            await dispatch(handlePostRequest(newData, '/productcatalog/v1/products/productValidatorCheckerAction', true, false, 'requestchecker'));
        }
    });

    //get status list
    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = res?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    const getTransactionListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setListOfTransaction(res?.payLoad);
    };

    //get Data by table Id
    const getDatabyId = async () => {
        const resp = await handleGetRequest(`/productcatalog/v1/products/getproductbyid/${refTableId}`);
        const keyData = resp?.payLoad;
        formik.setFieldValue('productCatalogId', keyData?.productCatalogId);
        formik.setFieldValue('productCatalogName', keyData?.productCatalogName);
        formik.setFieldValue('productCatalogDescription', keyData?.description);
        formik.setFieldValue('active', true);
        if (keyData) {
            let transactionListId =
                keyData &&
                keyData?.tblProductCatalogDetails.map((tblid) => {
                    return {
                        lovId: tblid.tblProduct.productId.toString()
                    };
                });
            formik.setFieldValue('transactionList', transactionListId);
        }
    };

    //Get data by mc request Id
    const getMcRequestDataById = async () => {
        const res = await handleGetRequest(`/productcatalog/v1/products/getproductbymcrequestid/${mcRequestId}`);
        const keyData = res?.payLoad;
        console.log('MCrequest', keyData);
        formik.setFieldValue('productCatalogId', keyData?.productCatalogId);
        formik.setFieldValue('productCatalogName', keyData?.productCatalogName);
        formik.setFieldValue('productCatalogDescription', keyData?.productCatalogDescription);
        formik.setFieldValue('active', keyData?.active);
        if (keyData) {
            let transactionListId =
                keyData &&
                keyData?.transactionList?.map((tblid) => {
                    return {
                        lovId: tblid.toString()
                    };
                });
            formik.setFieldValue('transactionList', transactionListId);
        }
    };

    useEffect(() => {
        getTransactionListData();
        getStatusListData();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, [listOfTransaction]); // eslint-disable-line

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getOldNewData = async () => {
        const resp = await handleGetRequest(`/workflow/v1/management/compareValues/${mcRequestId}`);
        setOldNewData(resp?.payLoad);
    };

    useEffect(() => {
        if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getOldNewData();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        const keys = oldNewData?.map((item) => item.key);
        setHighlightedKeys(keys);
    }, [oldNewData]); // eslint-disable-line

    const HandledOldValue = async (e) => {
        e.preventDefault();
        setDisplayDialog(true);
        const resp = await handleGetRequest(`/productcatalog/v1/products/getproductbyid/${refTableId}`);
        setProductCatalogRowData(resp?.payLoad);
    };

    const onHideProductCatalog = () => {
        setDisplayDialog(false);
    };

    return (
        <>
            <Sidebar header="View Product Catalog" position="right" style={{ width: '50vw' }} visible={displayDialog} onHide={() => setDisplayDialog(false)}>
                <ProductCatalogOldNewValues onHideProductCatalog={onHideProductCatalog} productCatalogRowData={productCatalogRowData} />
            </Sidebar>

            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-2">
                        <div className="Form__Header Full__Width">
                            <h2>Edit Detail</h2>
                            {requestType === 'U' && <Button label="View Old Details" className="Btn__Dark__OldValue" onClick={HandledOldValue} />}
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productCatalogName" className="Label__Text">
                                    Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="productCatalogName"
                                    name="productCatalogName"
                                    placeholder="Please Enter Productcatalog Name"
                                    value={formik.values.productCatalogName}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('productCatalogName')
                                            ? classNames({ 'p-invalid': isFormFieldValid('productCatalogName') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('productCatalogName') }, 'Input__Round')
                                    }
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productCatalogDescription" className="Label__Text">
                                    Description<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="productCatalogDescription"
                                    name="productCatalogDescription"
                                    placeholder="Please Enter Product catalog Description"
                                    value={formik.values.productCatalogDescription}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('productCatalogDescription')
                                            ? classNames({ 'p-invalid': isFormFieldValid('productCatalogDescription') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('productCatalogDescription') }, 'Input__Round')
                                    }
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="transactionList" className="Label__Text">
                                    List of transactions<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="transactionList"
                                    placeholder="Select Transaction List"
                                    options={listOfTransaction}
                                    optionLabel="name"
                                    name="transactionList"
                                    optionValue="lovId"
                                    filter
                                    value={formik.values.transactionList?.map((trans) => trans.lovId)}
                                    className={
                                        highlightedKeys?.includes('transactionList')
                                            ? classNames({ 'p-invalid': isFormFieldValid('transactionList') }, 'Dropdown__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('transactionList') }, 'Dropdown__Round')
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="p-fluid p-formgrid grid ">
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="action" className="Label__Text">
                                        Action <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="action"
                                        placeholder="Select Action"
                                        options={statusLovData}
                                        optionLabel="name"
                                        name="action"
                                        optionValue="lovId"
                                        value={formik.values.action || ''}
                                        onChange={formik.handleChange}
                                        className="Dropdown__Round"
                                    />

                                    {getFormErrorMessage('action')}
                                </div>
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid grid">
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="checkerComments" className="Label__Text">
                                        Comments
                                        <span className="Label__Required">*</span>
                                    </label>
                                    <InputTextarea
                                        autoResize="false"
                                        rows={5}
                                        cols={30}
                                        maxLength={500}
                                        id="checkerComments"
                                        name="checkerComments"
                                        value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ')}
                                        onChange={formik.handleChange}
                                        className="TextArea__Round"
                                    />

                                    {getFormErrorMessage('checkerComments')}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button disabled={loading} type="submit" label="Update" className="Btn__Dark" />
                        <Button onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default ProductCatalogCheckerEdit;

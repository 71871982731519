import { login } from '../../services/Auth';
import { LOGIN_SUCCESS, LOGIN_ERROR } from '../slices/authenticationSlice';

export const loginAction = (authData) => async (dispatch) => {
    // const res = authData;
    const res = await login(authData);
    if (res?.payLoad?.login === true) {
        // sessionStorage.setItem('token', res?.payLoad?.token);
        // sessionStorage.setItem("login", true);

        // sorting side menu
        if (res?.payLoad?.menu) {
            res?.payLoad?.menu?.sort((a, b) => a?.label?.localeCompare(b?.label));
            res?.payLoad?.menu?.forEach((obj) => {
                if (obj?.items) {
                    obj?.items?.sort((a, b) => a?.label?.localeCompare(b?.label));
                }
            });
            sessionStorage.setItem('menu', JSON.stringify(res?.payLoad?.menu));
        }
        if (res?.payLoad?.login) {
            sessionStorage.setItem('isNewUser', 'O');
        } else {
            sessionStorage.setItem('isNewUser', res?.payLoad?.tblAppUser?.passwordUpdateFlag);
        }
        sessionStorage.setItem('userId', res?.payLoad?.tblUser?.userId);
        sessionStorage.setItem('loginAttempts', '1');
        sessionStorage.setItem('expiryTime', res?.payLoad?.expiryTime * 60);
        sessionStorage.setItem('fullUserDetails', JSON.stringify(res?.payLoad));
        sessionStorage.setItem('userDetails', JSON.stringify(res?.payLoad?.tblUser));
        dispatch(LOGIN_SUCCESS(res?.payLoad));
        return res;
    } else {
        dispatch(LOGIN_ERROR('Error'));
        return res;
    }
};

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { handlePostRequest } from '../../../services/PostTemplate';

function SettleLoanDialog({ setShowModal, mobileNumber, disbursmentId }) {
    const [loading, setloading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-save');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [controlProceed, setControlProceed] = useState(false);

    const validationSchema = Yup.object().shape({});

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            mobileNumber: '',
            principalAmount: '',
            markupAmount: '',
            lateFee: '',
            total: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
        }
    });

    const dispatch = useDispatch();

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getSettleLoanData = async () => {
        const data = {};
        data['mobileNumber'] = mobileNumber;
        data['lmsDisbursementId'] = disbursmentId;

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/lending/v1/lending/getloansettleinfo', true, true, '', process.env.REACT_APP_LENDING_SUCCESS_CODE));
        const keyData = res?.payLoad;
        if (res?.responseCode !== process.env.REACT_APP_LENDING_SUCCESS_CODE) {
            setButtonDisabled(true);
        }

        formik.setFieldValue('principalAmount', keyData?.principalAmountOutstanding?.toString());
        formik.setFieldValue('markupAmount', keyData?.markupAmountOutstanding?.toString());
        formik.setFieldValue('lateFee', keyData?.lpChargesAmountOutstanding?.toString());
        let summed = parseFloat(keyData?.principalAmountOutstanding) + parseFloat(keyData?.markupAmountOutstanding) + parseFloat(keyData?.lpChargesAmountOutstanding) || 0;
        formik.setFieldValue('total', summed.toFixed(4)?.toString());
    };

    useEffect(() => {
        getSettleLoanData();
    }, [mobileNumber]); // eslint-disable-line

    const handleConfirmation = async () => {
        const data = {};
        data['mobileNumber'] = mobileNumber;
        data['lmsDisbursementId'] = disbursmentId;

        setControlProceed(true);

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/lending/v1/lending/settleloan', true, false, '', process.env.REACT_APP_LENDING_SUCCESS_CODE));
        if (res?.responseCode === process.env.REACT_APP_LENDING_SUCCESS_CODE) {
            setVisible(false);
            setShowModal(false);
            setControlProceed(false);
        }
    };

    return (
        <>
            <div className="card_GL Card__Round">
                <div className="Form__Header"></div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="mobileNumber" className={classNames({ 'p-error': isFormFieldValid('mobileNumber') }, 'Label__Text')}>
                                    Mobile Number
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="mobileNumber"
                                    placeholder="-"
                                    name="mobileNumber"
                                    disabled={true}
                                    value={mobileNumber}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('mobileNumber') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('mobileNumber')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="principalAmount" className={classNames({ 'p-error': isFormFieldValid('principalAmount') }, 'Label__Text')}>
                                    Principal Amount
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="principalAmount"
                                    placeholder="-"
                                    name="principalAmount"
                                    disabled={true}
                                    value={formik?.values?.principalAmount}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('principalAmount') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('principalAmount')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="markupAmount" className={classNames({ 'p-error': isFormFieldValid('markupAmount') }, 'Label__Text')}>
                                    Markup Amount
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="markupAmount"
                                    placeholder="-"
                                    name="markupAmount"
                                    disabled={true}
                                    value={formik?.values?.markupAmount}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('markupAmount') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('markupAmount')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="lateFee" className={classNames({ 'p-error': isFormFieldValid('lateFee') }, 'Label__Text')}>
                                    Late Fee
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="lateFee"
                                    placeholder="-"
                                    name="lateFee"
                                    disabled={true}
                                    value={formik?.values?.lateFee}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lateFee') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('lateFee')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="total" className={classNames({ 'p-error': isFormFieldValid('total') }, 'Label__Text')}>
                                    Total
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="total"
                                    placeholder="-"
                                    name="total"
                                    disabled={true}
                                    value={formik?.values?.total}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('total') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('total')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={buttonDisabled === true ? true : false} onClick={() => setVisible(true)} label="Settle" className="Btn__Dark" />
                        <ConfirmDialog
                            message="Are you sure you want to proceed?"
                            header="Confirmation"
                            draggable={false}
                            resizable={false}
                            visible={visible}
                            onHide={() => setVisible(false)}
                            footer={
                                <div>
                                    <Button onClick={handleConfirmation} className="Btn__Dark" style={{ borderRadius: '5px' }} label="Yes" disabled={controlProceed === true ? true : false} />
                                    <Button
                                        onClick={() => {
                                            setVisible(false);
                                            setShowModal(false);
                                        }}
                                        className="Btn__Transparent"
                                        style={{ borderRadius: '5px', width: '40px' }}
                                        label="No"
                                    />
                                </div>
                            }
                        />
                    </div>
                </form>
            </div>
        </>
    );
}

export default SettleLoanDialog;

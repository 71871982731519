import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';

const Ubp = ({ ubpData }) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const transAmount =
            ubpData &&
            ubpData.map((ubp) => {
                return ubp?.parameterValueTwo;
            });
        const countArr =
            ubpData &&
            ubpData.map((ubp) => {
                return ubp?.parameterValue;
            });
        const dateArr =
            ubpData &&
            ubpData.map((ubp) => {
                return ubp?.parameterName;
            });
        // const productArr =
        //     ubpData &&
        //     ubpData.map((ubp) => {
        //         return ubp?.product;
        //     });

        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: dateArr,
            datasets: [
                {
                    label: 'Count',
                    data: countArr,
                    fill: false,
                    borderColor: 'rgb(227,224,183)',
                    tension: 0.4
                },
                {
                    label: 'Amount',
                    data: transAmount,
                    fill: false,
                    borderColor: 'rgb(211,183,227)',
                    tension: 0.4
                }
                // {
                //     label: 'Product',
                //     data: productArr,
                //     fill: false,
                //     borderColor: 'rgb(163,225,225)',
                //     tension: 0.4
                // }
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [ubpData]);

    return (
        <div className="col-12 md:col-5">
            <div className="card Card__Round">
                <div className="cardBarCharts">
                    <h5>UBP</h5>
                    <div className="pt-3">
                        <Chart type="line" data={chartData} options={chartOptions} className="w-full" height="35rem" />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Ubp;

import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { RadioButton } from 'primereact/radiobutton';

function UploadParserHead() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [accordionData, setAccordionData] = useState([]);
    const [partnerLovData, setPartnerLovData] = useState([]);
    const [partnerTypeLovData, setPartnerTypeLovData] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            roleCode: '',
            partnerId: '',
            partnerType: '',
            email: '',
            time: '',
            roleDescr: '',
            isActive: false,
            active: false
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['isActive'] = data?.isActive ? 'Y' : 'N';
            formik.setFieldValue('isActive', data?.isActive === 'Y' ? true : false);


            data['active'] = data?.active ? 'Y' : 'N';
            formik.setFieldValue('active', data?.active === 'Y' ? true : false);



            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };


    const productIconRef = useRef(null);

    const [productIconFileName, setProductIconFileName] = useState();
    const [productIconBase64, setProductIconBase64] = useState('');

    // Product Icon file code
    const handledProductIconClick = () => {
        productIconRef.current.click();
    };

    const handleCnicChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            setProductIconFileName(selectedFile.name); // Update the selected file name
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                setProductIconBase64(base64Data);
               
            };
            reader.readAsDataURL(selectedFile);
        }
    };


    // const checkboxBodyTemplate = (rowData, column) => {
    //     return <Checkbox checked={rowData[column] === 'Y'} onChange={() => toggleCheckbox(rowData.moduleId, rowData.menuId, column)} />;
    // };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>Add New</h1>
                </div>


                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">




                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="attempts" className={classNames({ 'p-error': isFormFieldValid('attempts') }, 'Label__Text')}>
                                    Column Name
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="attempts"
                                    // placeholder="Enter attempts"
                                    name="attempts"
                                    value={formik?.values?.attempts?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('attempts') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('attempts')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                            <div className="p-field">
                                <div className="mb-2">
                                    <label className="Label__Text">Upload Image<span className="Label__Required">*</span></label>
                                    <div className="file-upload-container w-100 Input__Round_Upload flex file-input-field-upload" onClick={handledProductIconClick}>
                                        <input type="file" style={{ display: 'none' }} ref={productIconRef} onChange={handleCnicChange} accept="image/*" />
                                        {/* <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={productIconFileName} readOnly /> */}
                                        <button label="Choose File" className="Btn__Add__Upload border-round-md" iconPos="left" style={{ width: 'auto' }}>
                                            Choose File
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4  pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('status') }, 'Label__Text')}>
                                    Column Type
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder=""
                                    options={[]}
                                    optionLabel="name"
                                    name="status"
                                    optionValue="lovId"
                                    value={formik.values.status || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('status') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('status')}
                            </div>
                        </div>



                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="attempts" className={classNames({ 'p-error': isFormFieldValid('attempts') }, 'Label__Text')}>
                                    Min Length
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="attempts"
                                    // placeholder="Enter attempts"
                                    name="attempts"
                                    value={formik?.values?.attempts?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('attempts') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('attempts')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="attempts" className={classNames({ 'p-error': isFormFieldValid('attempts') }, 'Label__Text')}>
                                    Max Length
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="attempts"
                                    // placeholder="Enter attempts"
                                    name="attempts"
                                    value={formik?.values?.attempts?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('attempts') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('attempts')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="attempts" className={classNames({ 'p-error': isFormFieldValid('attempts') }, 'Label__Text')}>
                                    Sequence
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="attempts"
                                    // placeholder="Enter attempts"
                                    name="attempts"
                                    value={formik?.values?.attempts?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('attempts') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('attempts')}
                            </div>
                        </div>

                        {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="attempts" className={classNames({ 'p-error': isFormFieldValid('attempts') }, 'Label__Text')}>
                                    Mandatory
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="attempts"
                                    placeholder="Enter attempts"
                                    name="attempts"
                                    value={formik?.values?.attempts?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('attempts') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('attempts')}
                            </div>
                        </div> */}


                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="channelName" className={classNames({ 'p-error': isFormFieldValid('channelName') }, 'Label__Text')}>
                                    Mandatory
                                </label>

                                <div className="flex">
                                    <div className="mt-2 mr-5">
                                        <RadioButton inputId="applicable" name="redirection" value="Y" onChange={formik.handleChange} checked={formik.values.redirection === 'Y'} />

                                        <label htmlFor="applicable" className={classNames({ 'p-error': isFormFieldValid('applicable') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                    </div>

                                    <div className="mt-2">
                                        <RadioButton inputId="notApplicable" name="redirection" value="N" onChange={formik.handleChange} checked={formik.values.redirection === 'N'} />

                                        <label htmlFor="notApplicable" className={classNames({ 'p-error': isFormFieldValid('notApplicable') }, 'Label__Text')}>
                                            No
                                        </label>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="enablePartialPayment" className={classNames({ 'p-error': isFormFieldValid('enablePartialPayment') }, 'Label__Text')}>
                                    Length
                                </label>
                                <div className="field-checkbox flex">
                                    <div className="mr-5">
                                        <Checkbox inputId="enable" name="enable" value={formik?.values?.enable} checked={formik?.values?.enable} onChange={formik.handleChange} style={{ marginTop: '10px' }} />
                                        <label htmlFor="enable" className={classNames({ 'p-error': isFormFieldValid('enablePartialPayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                            Fixed Length
                                        </label>
                                    </div>
                                    <div>
                                        <Checkbox inputId="disable" name="disable" value={formik?.values?.disable} checked={formik?.values?.disable} onChange={formik.handleChange} style={{ marginTop: '10px' }} />
                                        <label htmlFor="disable" className={classNames({ 'p-error': isFormFieldValid('enablePartialPayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                            Default
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-5 pb-3 ">
                            <div className="p-field">
                                <div className="Down__Btn ">
                                    <Button disabled={loading} loadingIcon={loadingIcon || ''} type="submit" label="Add" onClick={() => navigate('/uploadparserhead')} className="Btn__Dark" />
                                    <Button disabled={loading} onClick={() => navigate('/usergroup')} label="Cancel" className="Btn__Transparent" />
                                </div>
                                {getFormErrorMessage('enablePartialPayment')}
                            </div>
                        </div>


                    </div>



                </form>
            </div>
        </>
    );
}
export default UploadParserHead;

import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handlePostRequest } from '../../services/PostTemplate';
import { handleGetRequest } from '../../services/GetTemplate';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import { FilterMatchMode } from 'primereact/api';
import ActiveInActiveDialog from '../../components/ActiveInActiveDialog/ActiveInActiveDialog';
import { Paginator } from 'primereact/paginator';
import { PaginationCall, PaginationTemplate } from '../../components/Pagination';

function Segments() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [statusLovData, setStatusLovData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [segmentData, setSegmentData] = useState([]);
    const [kamData, setKamData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [switchData, setSwitchData] = useState([]);

    const [customFirst1, setCustomFirst1] = useState(0);
    const [customRows1, setCustomRows1] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [render, setRender] = useState(false);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        taxRegimeName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const dataTableRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(res?.payLoad);
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };

    const getKamData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getkamdata/TBL_SALES_ROLE_DETAIL:*');
        setKamData(resp?.payLoad);
    };

    useEffect(() => {
        getStatusListData();
        getUserLov();
        getKamData();
    }, []);

    // const validationSchema = Yup.object().shape({
    //     dateFrom: Yup.date().nullable(),
    // });

    const formik = useFormik({
        // validationSchema: validationSchema,
        initialValues: {
            segmentName: '',
            salesRoleDetailId: '',
            dateFrom: '',
            dateTo: '',
            statusId: '2',
            createdBy: '',
            updatedBy: '',
            rowStart: customFirst1,
            rowEnd: customRows1 + customFirst1
        },
        validate: (data) => {
            let errors = {};
            if (data.dateTo && !data.dateFrom) {
                errors.dateFrom = 'Date From is required when Date To is provided';
            }

            if (data.dateFrom && data.dateTo && data.dateFrom > data.dateTo) {
                errors.dateTo = 'Date To must be greater than or equal to Date From';
            }
            return errors;
        },
        onSubmit: async (data) => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            dataTableRef.current.reset();
            setSegmentData('');

            const res = await dispatch(handlePostRequest(newData, '/account/v1/segments/getallsegments', true, true));
            if (res?.responseCode === '010000') {
                setSegmentData(res?.payLoad);
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">Segments List</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const header = () => renderHeader();

    const handleSwitchChange = async () => {
        const updatedData = segmentData.map((item) => {
            if (item?.segmentId === switchData?.id) {
                const isActive = item?.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setSegmentData(updatedData);

        let isactive = '';
        if (switchData?.isActive === 'Y') {
            isactive = 'N';
        } else if (switchData?.isActive === 'N') {
            isactive = 'Y';
        }
        const previousValue = switchData?.isActive;

        const data = {
            isActive: isactive,
            segmentId: switchData?.segmentId,
            businessTypeId: switchData?.lkpBusinessType?.businessTypeId,
            segmentName: switchData?.segmentName,
            salesRoleDetailId: switchData?.tblSalesRoleDetail?.salesRoleDetailId,
            regionId: switchData?.lkpRegion?.regionId,
            agreementSigningDate: switchData?.agreementSigningDate,
            uploadAgreement: switchData?.uploadAgreement,
            clientPocName: switchData?.clientPocName,
            clientMobileNo: switchData?.clientMobileNo,
            clientEmail: switchData?.clientEmail,
            companyAddress: switchData?.companyAddress,
            segmentDescr: switchData?.segmentDescr
        };

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/account/v1/segments/inactivesegment', true, false));
        if (res?.responseCode !== '010000') {
            const revertedData = segmentData.map((item) => {
                if (item.segmentId === switchData?.id) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setSegmentData(revertedData);
        }
    };

    const EditSegment = (rowData) => {
        navigate(`/editsegment?segmentId=${rowData?.segmentId}`);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <InputSwitch
                        checked={rowData.isActive === 'Y'}
                        disabled={['Pending', 'Rejected'].includes(rowData?.status) ? true : false}
                        onChange={() => {
                            setSwitchData(rowData);
                            setShowModal(true);
                            // handleSwitchChange(rowData?.segmentId, rowData?.isActive, rowData);
                        }}
                    />
                    <Button
                        tooltip="Edit"
                        icon="pi pi-pencil"
                        disabled={['Pending', 'Rejected'].includes(rowData?.status) || (rowData?.status === 'Approved' && rowData?.isActive === 'N') ? true : false}
                        tooltipOptions={{ position: 'top' }}
                        onClick={() => EditSegment(rowData)}
                        className="p-button-rounded p-button-primary"
                    />
                    <Button
                        tooltip="View Details"
                        onClick={() => navigate(`/viewsegment?segmentId=${rowData?.segmentId}`)}
                        tooltipOptions={{ position: 'top' }}
                        icon="pi pi-eye"
                        className="p-button-rounded"
                        style={{ backgroundColor: '#F2AC57', border: 'none' }}
                    />
                </div>
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.status === 'Approved' ? '#14A38B' : rowData?.status === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.status}</b>
            </p>
        );
    };
    const addSegments = () => {
        navigate('/addsegment');
    };

    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];
    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createDate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const handleShowModal = () => {
        setShowModal(false);
    };

    const props = {
        showModal,
        handleShowModal,
        handleSwitchChange
    };

    const onCustomPageChange1 = (event) => {
        setCustomFirst1(event.first);
        setCustomRows1(event.rows);
        setCurrentPage(event.page + 1);
        setRender(true);
    };

    const RequestData = {
        salesRoleDetailId: formik.values.salesRoleDetailId,
        segmentName: formik.values.segmentName,
        dateFrom: formik.values.dateFrom,
        dateTo: formik.values.dateTo,
        statusId: formik.values.statusId,
        createdBy: formik.values.createdBy,
        updatedBy: formik.values.updatedBy,
        rowStart: customFirst1,
        rowEnd: customRows1 + customFirst1
    };

    return (
        <>
            <ActiveInActiveDialog {...props} />
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={() => addSegments()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>
            <PaginationCall
                render={render}
                customFirst1={customFirst1}
                customRows1={customRows1}
                currentPage={currentPage}
                dataTableRef={dataTableRef}
                setData={setSegmentData}
                RequestData={RequestData}
                url={'/account/v1/segments/getallsegments'}
                responseCode={'010000'}
            />
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="segmentName" className={classNames({ 'p-error': isFormFieldValid('segmentName') }, 'Label__Text')}>
                                    Segment Name
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="segmentName"
                                    name="segmentName"
                                    value={formik?.values?.segmentName?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('segmentName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('segmentName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pb-3">
                            <div className="p-field">
                                <label htmlFor="salesRoleDetailId" className={classNames({ 'p-error': isFormFieldValid('salesRoleDetailId') }, 'Label__Text')}>
                                    KAM
                                </label>
                                <Dropdown
                                    id="salesRoleDetailId"
                                    placeholder="Select Kam"
                                    options={kamData}
                                    optionLabel="name"
                                    name="salesRoleDetailId"
                                    optionValue="lovId"
                                    value={formik.values.salesRoleDetailId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('salesRoleDetailId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('salesRoleDetailId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="statusId" className={classNames({ 'p-error': isFormFieldValid('statusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select Status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText
                                    max={previousDate}
                                    id="dateFrom"
                                    placeholder=""
                                    name="dateFrom"
                                    type="date"
                                    value={formik?.values?.dateFrom?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText
                                    max={previousDate}
                                    id="dateTo"
                                    placeholder=""
                                    name="dateTo"
                                    type="date"
                                    // max={currentDate}
                                    value={formik?.values?.dateTo?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Updated By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div>
                        <div className="Usergroup__SearchBtn p-field col-12 md:col-4">
                            <Button disabled={loading} label="Search" loadingIcon={loadingIcon} icon={loadingIcon} iconPos="left" className="Btn__Dark__Search" />
                            <Button disabled={loading} label="Reset" onClick={(e) => handleReset(e)} className="Btn__Transparent__Reset" />
                        </div>
                    </div>
                </form>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            className="datatable-responsive"
                            globalFilterFields={['segmentName', 'salesRoleName', 'createuser', 'createdate', 'lkpStatus.statusName']}
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={segmentData}
                        >
                            <Column field="segmentName" header="Segment Name" />
                            <Column field="salesRoleName" header="KAM" />
                            <Column field="createdBy" header="Created By" />
                            <Column field="updatedBy" header="Updated By" />
                            <Column body={createdDateAndTimeBodyTemplate} header="Created Date & Time" />
                            {/* <Column field="lkpStatus.statusName" header="Status" /> */}
                            <Column header="Status" body={statusBodyTemplate} />
                            <Column header="Action" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                        <Paginator template={PaginationTemplate(false)} first={customFirst1} rows={customRows1} totalRecords={segmentData?.[0]?.totalCount} onPageChange={onCustomPageChange1}></Paginator>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Segments;

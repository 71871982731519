import { baseURL } from '../Config';
import axios from 'axios';
import { toast } from 'react-toastify';
import { trimData } from '../utilities/TrimPayloadData';
import { loadingAction } from '../redux/actions/loadingAction';
import { showMessageAction } from '../redux/actions/showMessageAction';
import store from '../redux/store';

export const handlePostForNotificationRequest =
    (data, url, isShowLoad = false, isShowToast = true, nextPageToLoad = '') =>
    async (dispatch) => {
        data = await trimData(data);
        try {
            if (isShowLoad) dispatch(loadingAction(true));
            const response = await axios({
                method: 'post',
                url: `${baseURL + url}`,
                data: data,
                headers: {
                    Authorization: sessionStorage.getItem('token')
                }
            });
            if (response?.data?.message === 'Invalid Token' || response?.data?.message === 'Session Expired') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.href = '/';
                }, 1000);
            }

            if (isShowToast) {
                if (response?.data?.message === 'Validations Failed') {
                    // Display a warning toast with validation errors
                    toast.warn(
                        response?.data?.errors
                            ?.filter((value) => value?.errorDescr === 'Select Any One Option')
                            ?.map((filteredError) => filteredError?.errorDescr)
                            .join(', '),
                        { autoClose: 2000 }
                    );
                } else if (response?.data?.message === 'This record is already pending for approval' || response?.data?.message === 'Record Not Found' || response?.data?.message === 'Record Already Exist') {
                    toast.warn(response?.data?.message || response?.data?.messages || response?.data?.error || 'Something went wrong !!', { autoClose: 2000 });
                } else {
                    // Display a success toast
                    toast.success(response?.data?.message || response?.data?.messages || response?.data?.error || 'Something went wrong !!', { autoClose: 2000 });
                }
            }

            if (isShowLoad) dispatch(loadingAction(false));

            return response?.data;
        } catch (error) {
            if (error?.response?.data?.message === 'Invalid Token' || error?.response?.data?.message === 'Session Expired') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.href = '/';
                }, 1000);
            }
            if (isShowLoad) dispatch(loadingAction(false));
            // if (isShowToast === false) {
            //     store.dispatch(
            //         showMessageAction({
            //             isShowModal: true,
            //             message: (error?.response?.data?.message === 'Validations Failed' ? error?.response?.data?.errors?.map((value) => value?.errorDescr) : error?.response?.data?.message) || 'Something went wrong !!',
            //             isError: true
            //         })
            //     );
            // }
            // else if (error?.response?.status === 500 && isShowToast) {
            //     toast.warn({
            //         render: error?.response?.data?.message || error?.response?.data?.messages || error?.response?.data?.error || 'Something went wrong !!',
            //         type: 'error',
            //         isLoading: false,
            //         autoClose: 3000
            //     });
            // } else if (error?.response?.status === 400 && isShowToast)
            //     toast.warn({
            //         render: error?.response?.data?.message || error?.response?.data?.messages || error?.response?.data?.error || 'Something went wrong !!',
            //         type: 'error',
            //         isLoading: false,
            //         autoClose: 3000
            //     });
            // else if (isShowToast)
            //     toast.warn({
            //         render: error?.response?.data?.message || error?.response?.data?.messages || error?.response?.data?.error || 'Something went wrong !!',
            //         type: 'error',
            //         isLoading: false,
            //         autoClose: 3000
            //     });
            else if (error?.response?.status === 500 && isShowToast) {
                toast.warn(error?.response?.data?.message || error?.response?.data?.messages || error?.response?.data?.error || 'Something went wrong !!', { autoClose: 2000 });
            } else if (error?.response?.status === 400 && isShowToast)
                toast.warn(
                    error?.response?.data?.message === 'Validations Failed'
                        ? error?.response?.data?.errors
                              ?.filter((value) => value?.errorDescr === 'Select Any One Option')
                              ?.map((filteredError) => filteredError?.errorDescr)
                              .join(', ') || error?.response?.data?.errors?.map((filteredError) => filteredError?.errorDescr).join(', ')
                        : error?.response?.data?.message || error?.response?.data?.messages || error?.response?.data?.error || 'Something went wrong !!',
                    { autoClose: 2000 }
                );
            else toast.warn(error?.response?.data?.message || 'Something went wrong !!');
            return error?.response;
        }
    };

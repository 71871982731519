import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Steps } from 'primereact/steps';
import { useLocation } from 'react-router-dom';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import ViewCreateCampaignManagementChecker from './CreateCampaginChecker/ViewCreateCampaignChecker';
import ViewTargetBaseSelectionCampaignChecker from './TargetBaseSelectionChecker/ViewTargetBaseSelectionChecker';
import ViewQualifyingCriteriaChecker from './QualifyingCriteriaChecker/ViewQualifyingCriteriaChecker';
import ViewRewardConfigurationChecker from './RewardConfigurationsChecker/ViewRewardConfigurationsChecker';
import ViewCustomerCommunicationCampaignChecker from './CustomerCommunicationChecker/ViewCustomerCommunicationChecker';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewCampignManagementChecker({}) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [activeIndex, setActiveIndex] = useState(1);
    const [campaignData, setCampaignData] = useState([]);

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getCampaignById = async () => {
        const response = await handleGetRequest(`/customerloyalitypromotions/v1/customerloyalitypromotion/getcampaignbyid/${refTableId}`);
        setCampaignData(response?.payLoad);
        setActiveIndex(parseInt(response?.payLoad?.currentStep));
    };

    const getMcRequestDataById = async () => {
        setloading(true);

        const res = await handleGetRequest(`/customerloyalitypromotions/v1/customerloyalitypromotion/getcampaignmcrequestbyid/${mcRequestId}`);
        setCampaignData(res?.payLoad);
        setActiveIndex(4);

        setloading(false);
    };

    // useEffect(() => {
    //     getCampaignById();
    // }, []);

    useEffect(() => {
        if (requestType === 'I') {
            getCampaignById();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, []); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        // campaignName: Yup.mixed().required('This field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            checkerComments: ''
        },

        // validate: (data) => {
        //     let errors = {};
        //     return errors;
        // },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        campaignRequest: {
                            campaignId: parseInt(refTableId),
                            isActive: 'Y'
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/customerloyalitypromotions/v1/customerloyalitypromotion/campaignValidatorCheckerAction', true, false, 'requestchecker'));
            if (res?.responseCode === '000000') {
                formik.resetForm();
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const items = [
        {
            label: 'Add Campaign',
            command: (e) => handleStepClick(e.index),
            disabled: requestType === 'I' ? (parseInt(campaignData?.currentStep) >= 0 ? false : true) : parseInt(campaignData[0]?.stepNo) >= 0 ? false : true
        },
        {
            label: 'Target Base Selection',
            command: (e) => handleStepClick(e.index),
            disabled: requestType === 'I' ? (parseInt(campaignData?.currentStep) >= 1 ? false : true) : parseInt(campaignData[1]?.stepNo) >= 1 ? false : true
        },
        {
            label: 'Qualifying criteria',
            command: (e) => handleStepClick(e.index),
            disabled: requestType === 'I' ? (parseInt(campaignData?.currentStep) >= 2 ? false : true) : parseInt(campaignData[2]?.stepNo) >= 2 ? false : true
        },
        {
            label: 'Reward Configurations',
            command: (e) => handleStepClick(e.index),
            disabled: requestType === 'I' ? (parseInt(campaignData?.currentStep) >= 3 ? false : true) : parseInt(campaignData[3]?.stepNo) >= 3 ? false : true
        },
        {
            label: 'Customer Communication',
            command: (e) => handleStepClick(e.index),
            disabled: requestType === 'I' ? (parseInt(campaignData?.currentStep) >= 4 ? false : true) : parseInt(campaignData[4]?.stepNo) >= 4 ? false : true
        }
    ];

    const handleStepClick = (index) => {
        setActiveIndex(index);
    };

    return (
        <>
            <div className="card Card__Round">
                <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} style={{ margin: '50px 0px', padding: '50px 0px' }} className="Campaign__Steps" />
                {/* <div className="Form__Header">
                    <h1>VIEW CAMPAIGN CHECKER</h1>
                </div> */}
                {activeIndex === 0 && <ViewCreateCampaignManagementChecker campaignData={campaignData} />}
                {activeIndex === 1 && <ViewTargetBaseSelectionCampaignChecker campaignData={campaignData} />}
                {activeIndex === 2 && <ViewQualifyingCriteriaChecker campaignData={campaignData} />}
                {activeIndex === 3 && <ViewRewardConfigurationChecker campaignData={campaignData} />}
                {activeIndex === 4 && <ViewCustomerCommunicationCampaignChecker campaignData={campaignData} />}
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {/* {activeIndex >= 4 && (
                        <> */}
                    {/* <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-12">
                                    <div className="p-field">
                                        <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                            Comments
                                            <span className="Label__Required">*</span>
                                        </label>
                                        <InputTextarea
                                            autoResize={false}
                                            rows={5}
                                            cols={30}
                                            id="checkerComments"
                                            name="checkerComments"
                                            disabled={true}
                                            value={formik?.values?.checkerComments || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                        />
                                        {getFormErrorMessage('checkerComments')}
                                    </div>
                                </div>
                            </div> */}
                    {/* </>
                    )} */}
                    {/* {activeIndex >= 4 && ( */}
                    <div className="Down__Btn">
                        <Button disabled={loading} label="Okay" className="Btn__Dark" onClick={() => navigate('/requestchecker')} />
                    </div>
                    {/* )} */}
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default ViewCampignManagementChecker;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { handlePostRequest } from '../../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../../services/GetTemplate';
import { Dropdown } from 'primereact/dropdown';
import AuthorizationHistoryTable from '../../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import { InputTextarea } from 'primereact/inputtextarea';

function EditLoginSetChecker() {
    const [statusLovData, setStatusLovData] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const tableName = query.get('tableName');

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        getStatusLov();
        if (requestType === 'I') {
            getMainCategoryData();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, [requestType]); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('This field is required'),
        checkerComments: Yup.string().required('This field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cmsLoginLimitId: '',
            noOfAttempts: '',
            time: '',
            isTemporaryBlock: false,
            isPermanentBlock: false,
            blockTime: '',
            action: '',
            checkerComments: ''
        },

        onSubmit: async (data) => {
            const addPayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        objectBody: {
                            cmsLoginLimitId: formik.values.cmsLoginLimitId,
                            isActive: requestType === 'I' ? (formik?.values?.action == '2' ? 'Y' : 'N') : formik?.values?.isActive
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: data?.checkerComments,
                            action: data?.action,
                            updatedIndex: ''
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const updatePayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        objectBody: {
                            cmsLoginLimitId: data?.cmsLoginLimitId,
                            noOfAttempts: data?.noOfAttempts,
                            time: data?.time,
                            isTemporaryBlock: data?.isTemporaryBlock ? 'Y' : 'N',
                            isPermanentBlock: data?.isPermanentBlock ? 'Y' : 'N',
                            blockTime: data?.blockTime,
                            isActive: data?.isActive
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: data?.checkerComments,
                            action: data?.action,
                            updatedIndex: ''
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            if (requestType === 'I') {
                await dispatch(handlePostRequest(addPayload, '/cms/v1/cms/loginlimitsvalidatorcheckeraction', true, false, 'requestchecker'));
            } else if (requestType === 'U') {
                await dispatch(handlePostRequest(updatePayload, '/cms/v1/cms/loginlimitsvalidatorcheckeraction', true, false, 'requestchecker'));
            }
        }
    });

    const getMainCategoryData = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/getloginlimitsbyid/${refTableId}`);
        const keyData = res?.payLoad;
        formik.setFieldValue('noOfAttempts', keyData?.noOfAttempts || '');
        formik.setFieldValue('cmsLoginLimitId', keyData?.cmsLoginLimitId || '');
        formik.setFieldValue('time', keyData?.time || '');

        formik.setFieldValue('isTemporaryBlock', keyData?.isTemporaryBlock === 'Y' ? true : false);
        formik.setFieldValue('isPermanentBlock', keyData?.isPermanentBlock === 'Y' ? true : false);
        formik.setFieldValue('blockTime', keyData?.blockTime || '');
    };

    const getMcRequestDataById = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/getmcrequestbyid/${mcRequestId}`);
        const unParsedkeyData = res?.payLoad?.updateJson;
        const keyData = JSON.parse(unParsedkeyData);
        formik.setFieldValue('cmsLoginLimitId', keyData?.cmsLoginLimitId || '');
        formik.setFieldValue('noOfAttempts', keyData?.noOfAttempts || '');
        // const formattedTime = convertTo24HourFormat(keyData?.time || '');
        // formik.setFieldValue('time', formattedTime || '');
        formik.setFieldValue('isTemporaryBlock', keyData?.isTemporaryBlock === 'Y' ? true : false);
        formik.setFieldValue('isPermanentBlock', keyData?.isPermanentBlock === 'Y' ? true : false);
        formik.setFieldValue('blockTime', keyData?.blockTime || '');
        formik.setFieldValue('time', keyData?.time || '');

        formik.setFieldValue('isActive', keyData?.isActive || '');
    };

  

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>EDIT DETAIL</h2>
                        </div>
                        <div className="p-field col-12 md:col-6 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="noOfAttempts" className={classNames({ 'p-error': isFormFieldValid('noOfAttempts') }, 'Label__Text')}>
                                    Attempts<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="noOfAttempts"
                                    placeholder="Enter Attempts"
                                    name="noOfAttempts"
                                    value={formik?.values?.noOfAttempts || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('noOfAttempts') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('noOfAttempts')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-6  pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="time" className={classNames({ 'p-error': isFormFieldValid('time') }, 'Label__Text')}>
                                    Enter Time<span className="Label__Required">*</span>
                                </label>
                                {/* <InputText id="time" name="time" value={formik?.values?.time} disabled className={classNames({ 'p-invalid': isFormFieldValid('time') }, 'Input__Round')} /> */}
                                <InputText
                                    maxLength={100}
                                    id="time"
                                    placeholder="Enter Time In Minutes"
                                    name="time"
                                    value={formik?.values?.time || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('time') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('time')}
                            </div>
                        </div>

                        <div className="Form__Header Full__Width">
                            <h2>BREACH LIMITS</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-5 pb-3">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="isTemporaryBlock" name="isTemporaryBlock" checked={formik?.values?.isTemporaryBlock} onChange={formik.handleChange} disabled style={{ marginTop: '10px' }} />{' '}
                                    <label htmlFor="isTemporaryBlock" className={classNames({ 'p-error': isFormFieldValid('isTemporaryBlock') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Temporary Block
                                    </label>
                                </div>
                                {getFormErrorMessage('isTemporaryBlock')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="blockTime" className={classNames({ 'p-error': isFormFieldValid('blockTime') }, 'Label__Text')}>
                                Enter  Time

                                </label>
                                <InputText
                                    maxLength={100}
                                    id="blockTime"
                                    placeholder="Enter Time In Minutes"
                                    name="blockTime"
                                    value={formik?.values?.blockTime || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('blockTime') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('blockTime')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>
             

                        <div className="p-field col-12 md:col-2 pt-3 pb-3">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="isPermanentBlock" name="isPermanentBlock" checked={formik?.values?.isPermanentBlock} onChange={formik.handleChange} disabled style={{ marginTop: '10px' }} />{' '}
                                    <label htmlFor="isPermanentBlock" className={classNames({ 'p-error': isFormFieldValid('isPermanentBlock') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Permanent Block
                                    </label>
                                </div>
                                {getFormErrorMessage('isPermanentBlock')}
                            </div>
                        </div>

                        <div className="p-fluid p-formgrid grid col-12 p-3 bg-bluegray-50 border-round-sm mt-3">
                            <div className="p-fluid p-formgrid grid ml-5 col-10">
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                    <div className="p-field">
                                        <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                            Select Action <span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="action"
                                            placeholder="Select Action"
                                            options={statusLovData}
                                            optionLabel="name"
                                            name="action"
                                            optionValue="lovId"
                                            value={formik?.values?.action || ''}
                                            onChange={formik.handleChange}
                                           
                                            className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('action')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                    <div className="desc_Text_Area">
                                        <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                            Comments <span className="Label__Required">*</span>
                                        </label>
                                        <InputTextarea
                                            placeholder="-"
                                            inputid="checkerComments"
                                            name="checkerComments"
                                            rows={4}
                                            cols={30}
                                            onChange={formik.handleChange}
                                        
                                            className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'Input__Round')}
                                            value={formik?.values?.checkerComments}
                                        />
                                        {getFormErrorMessage('checkerComments')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="submit" label="Save" className="Btn__Dark" />
                        <Button onClick={() => navigate('/requestchecker')} type="button" label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default EditLoginSetChecker;

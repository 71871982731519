import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import classNames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../services/PostTemplate';

import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { handleGetRequest } from '../../../services/GetTemplate';

const Biller = () => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    const [productData, setProductData] = useState([]);

    const dataTableRef = useRef(null);

    const [statusData, setStatusData] = useState([]);
    // const [userLovData, setUserLovData] = useState([]);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            createdBy: '',
            updatedBy: '',
            cmsFileHeadId: '',
            cmsCompanyId: '',
            fileName: '',
            serialNo: '',
            totalRecords: '',
            createuser: '',
            createdate: '',
            lastupdateuser: null,
            lastupdatedate: null,
            updateindex: null,
            isActive: 'Y',
            statusId: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            const newData = {
                data: {
                    channel: null,
                    terminal: null,
                    payLoad: {}
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/cms/v1/cms/searchallfile', true, true, 'biller'));
      

            if (res?.responseCode === '190000') {
                setProductData(res?.payLoad);
                formik.resetForm();
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const editHandler = (rowData) => {
        navigate('/editBiller');
    };
    const viewHandler = (rowData) => {
        navigate('/viewBiller');
    };

    const actionTemplate = (rowData) => {
        return (
            <div>
                <Button tooltip="Edit" icon="pi pi-pencil" tooltipOptions={{ position: 'top' }} onClick={() => editHandler(rowData)} className="p-button-rounded p-button-warning mr-3" />
                <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => viewHandler(rowData)} className="p-button-rounded p-button-primary" />
            </div>
        );
    };

    const getStatusLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setStatusData(res?.payLoad);
    };

    // const getUserLov = async () => {
    //     const res = await handleGetRequest("/configuration/v1/lookups/getusers/TBL_USER:*");
    //     setUserLovData(res?.payLoad);
    // };

    useEffect(() => {
        getStatusLov();
        // getUserLov();
    }, []);

    // const productData = [
    //     {
    //         productPriority: "1",
    //         productName: "IBFT",
    //         iconImage: "image",
    //         category: "Money Transfer",
    //         subCategory: "Js Bank",
    //         createdOn: "29/10/2022 9:00PM",
    //         : "Essa Ahmed,
    //         ttus: "Enabl",
    //     },    //     {
    //        productPrioity: "1",
    //        productame: "IBFTnImae: "image",
    //        category: "oney Transfer",
    //        subCategoy: "Js Bank",
    //        createdOn "29/10/2022 9:00PM",
    //        createdBy: "Esa Ahmed",
    //        status: "Enabe",
    //     },    //
    //        productriority: "1",
    //        productame: "IBFT",
    //         iconImage: "image",
    //         category: "Money Transfer",
    //         subCategory: "Js Bank",
    //         createdOn: "29/10/2022 9:00PM",
    //         createdBy: "Essa Ahmed",
    //         status: "Enable",
    //     },
    // ];

    const handledClicked = () => {
        navigate('/addBiller');
    };
    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };
    return (
        <>
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add Offline Biller" onClick={handledClicked} className="Btn__Add__" icon="pi pi-plus" iconPos="left" style={{ width: 'auto' }} />
                </div>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        {/* <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Code
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLovData}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div> */}

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Code</label>
                                <InputText id="statusId" name="statusId" value={formik.values.statusId} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Input__Round')} keyfilter="int" />
                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Email</label>
                                <InputText
                                    placeholder=""
                                    id="productId"
                                    name="productId"
                                    value={formik.values.productId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productId') }, 'Input__Round')}
                                    keyfilter="int"
                                />
                                {getFormErrorMessage('productId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Phone Number</label>
                                <InputText
                                    placeholder="-"
                                    id="productId"
                                    name="productId"
                                    value={formik.values.productId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productId') }, 'Input__Round')}
                                    keyfilter="int"
                                />
                                {getFormErrorMessage('productId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Product Code</label>
                                <InputText id="productId" name="productId" value={formik.values.productId} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('productId') }, 'Input__Round')} keyfilter="int" />
                                {getFormErrorMessage('productId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="tagExpiryDate" className={classNames({ 'p-error': isFormFieldValid('tagExpiryDate') }, 'Label__Text')}>
                                    Created Date & Time
                                </label>
                                <InputText
                                    type="date"
                                    id="tagExpiryDate"
                                    name="tagExpiryDate"
                                    value={formik?.values?.tagExpiryDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('tagExpiryDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('tagExpiryDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="categoryId" className={classNames({ 'p-error': isFormFieldValid('categoryId') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="categoryId"
                                    placeholder=""
                                    options={statusData}
                                    optionLabel="name"
                                    name="categoryId"
                                    optionValue="lovId"
                                    value={formik.values.categoryId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('categoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('categoryId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 ">
                            <div className="p-field">
                                <label htmlFor="categoryId" className={classNames({ 'p-error': isFormFieldValid('categoryId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="categoryId"
                                    placeholder=""
                                    options={statusData}
                                    optionLabel="name"
                                    name="categoryId"
                                    optionValue="lovId"
                                    value={formik.values.categoryId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('categoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('categoryId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="Down__Btn__End ">
                                <Button label="Search" disabled={loading} iconPos="right" className="Btn__Dark" />
                                <Button type="button" disabled={loading} label="Reset" iconPos="right" className="Btn__Dark" onClick={(e) => handleReset(e)} />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div />

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <div>
                            <h4>Existing Details</h4>
                        </div>
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            globalFilterFields={['validatorName', 'validatorType', 'createdate', 'createdBy', 'updatedBy', 'lkpStatus.statusDescr']}
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={productData}
                        >
                            <Column field="statusId" header="Code" />
                            <Column field="email" header="Email" />
                            <Column field="phoneNumber" header="Phone Number" />
                            <Column field="productCode" header="Product Code" />
                            <Column field="minimumLimit" header="Minimum Limit" />
                            <Column field="createdBy" header="Created By" />
                            <Column field="createdOn" header="Created Date and Time" />
                            <Column field="status" header="Status" />
                            <Column body={actionTemplate} header="Action" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Biller;

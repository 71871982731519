import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { InputTextarea } from 'primereact/inputtextarea';
import { OverlayPanel } from 'primereact/overlaypanel';
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';
import { useLocation } from 'react-router-dom';

function ViewCustomerCommunicationCampaignChecker({ activeIndex, handleStepClick, setAllStepsComplete, compaignIdData, campaignData }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
    const [visible, setVisible] = useState(false);
    const [frequencyPeriodData, setFrequencyPeriodData] = useState([]);

    const op = useRef(null);

    const dispatch = useDispatch();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const requestType = query.get('requestType');

    const handleTextareaChange = (e) => {
        formik.setFieldValue('ccSmsContent', e.target.value);
    };

    const onClickEmoji = (emojiData) => {
        formik.setFieldValue('ccSmsContent', formik?.values?.ccSmsContent + (emojiData.isCustom ? emojiData.unified : emojiData.emoji));
        toggleEmojiPicker();
        toggleVisible();
    };

    const toggleEmojiPicker = () => {
        setEmojiPickerVisible(!emojiPickerVisible);
    };

    const toggleVisible = () => {
        setVisible(!visible);
    };

    const getFrequencyPeriodData = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getdatalov/LKP_FREQUENCY_PERIOD');
        setFrequencyPeriodData(response?.payLoad);
    };

    useEffect(() => {
        getFrequencyPeriodData();
    }, []);

    useEffect(() => {
        if (requestType === 'I') {
            if (campaignData) {
                formik.setFieldValue('ccCommunicationMedium', campaignData?.ccCommunicationMedium);
                formik.setFieldValue('ccFrequency', campaignData?.ccFrequency?.toString());
                formik.setFieldValue('ccFrequencyPeriodCode', campaignData?.lkpCCFrequencyPeriod?.frequencyPeriodCode?.toString());

                formik.setFieldValue('ccMessageType', campaignData?.ccMessageType?.toString());
                formik.setFieldValue('ccNotificationContent', campaignData?.ccNotificationContent?.toString());
                formik.setFieldValue('ccSmsContent', campaignData?.ccSmsContent?.toString());

                if (['', null, undefined].includes(campaignData?.ccNotificationContent) || ['', null, undefined].includes(campaignData?.ccSmsContent)) {
                    formik.setFieldValue('isMessageContent', false);
                } else {
                    formik.setFieldValue('isMessageContent', true);
                }

                if (campaignData?.ccMessageType === 'O') {
                    formik.setFieldValue('ccFrequencyMonth', campaignData?.ccFrequencyMonth?.slice(1, -1));
                    formik.setFieldValue('ccFrequencyDate', campaignData?.ccFrequencyDate?.slice(1, -1).split(', ')?.toString());
                } else {
                    formik.setFieldValue('ccFrequencyMonth', campaignData?.ccFrequencyMonth?.slice(1, -1).split(', '));
                    formik.setFieldValue('ccFrequencyDay', campaignData?.ccFrequencyDay?.slice(1, -1).split(', '));
                    formik.setFieldValue('ccFrequencyDate', campaignData?.ccFrequencyDate?.slice(1, -1).split(', '));
                }

                formik.setFieldValue(
                    'frequencyCcTimeList',
                    campaignData?.tblCampaignCcTimes?.filter((item) => item?.isActive === 'Y')
                );
            }
        } else if (requestType === 'U') {
            if (campaignData) {
                formik.setFieldValue('ccCommunicationMedium', campaignData[4]?.ccCommunicationMedium?.toString());
                formik.setFieldValue('ccFrequency', campaignData[4]?.ccFrequency?.toString());
                formik.setFieldValue('ccFrequencyPeriodCode', campaignData[4]?.ccFrequencyPeriodCode?.toString());

                formik.setFieldValue('ccMessageType', campaignData[4]?.ccMessageType?.toString());
                formik.setFieldValue('ccNotificationContent', campaignData[4]?.ccNotificationContent?.toString());
                formik.setFieldValue('ccSmsContent', campaignData[4]?.ccSmsContent?.toString());

                if (campaignData[4]?.ccMessageType === 'O') {
                    formik.setFieldValue('ccFrequencyMonth', campaignData[4]?.ccFrequencyMonth[0].toString());
                    formik.setFieldValue('ccFrequencyDate', campaignData[4]?.ccFrequencyDate[0].toString());
                } else {
                    formik.setFieldValue('ccFrequencyMonth', campaignData[4]?.ccFrequencyMonth);
                    formik.setFieldValue('ccFrequencyDate', campaignData[4]?.ccFrequencyDate);
                    formik.setFieldValue('ccFrequencyDay', campaignData[4]?.ccFrequencyDay);
                }

                formik.setFieldValue('frequencyCcTimeList', campaignData[4]?.frequencyCcTimeList);
                formik.setFieldValue('isMessageContent', campaignData[4]?.messageContent);
            }
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            ccCommunicationMedium: '',
            isMessageContent: false,
            ccSmsContent: '',
            ccNotificationContent: '',
            ccMessageType: 'O',
            ccFrequencyPeriodCode: '',

            ccFrequencyDay: '',
            ccFrequencyDate: [],
            ccFrequencyMonth: '',
            ccFrequency: '',

            frequencyCcTimeList: [
                {
                    startTime: '',
                    endTime: ''
                }
            ]
        },

        // validate: (data) => {
        //     let errors = {};
        //     return errors;
        // },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['campaignId'] = compaignIdData || '';
            data['stepNo'] = '4';

            if (data?.ccCommunicationMedium === 'S') {
                delete data['ccNotificationContent'];
            } else if (data?.ccCommunicationMedium === 'A') {
                delete data['ccSmsContent'];
            }

            if (data?.ccFrequencyPeriodCode === '10') {
                delete data['ccFrequencyDate'];
                delete data['ccFrequencyMonth'];
                delete data['ccFrequencyDay'];
            } else if (data?.ccFrequencyPeriodCode === '20') {
                delete data['ccFrequencyDate'];
                delete data['ccFrequencyMonth'];
            }

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const formData = new FormData();
            formData.append('request', JSON.stringify(newData));

            if (parseInt(campaignData?.currentStep) >= 4) {
                const res = await dispatch(handlePostRequest(formData, '/customerloyalitypromotions/v1/customerloyalitypromotion/editcampaign', true, false));
                if (res?.responseCode === '150000') {
                    formik.resetForm();
                    handleStepClick(activeIndex + 1);
                    setAllStepsComplete(true);
                }
            } else {
                const res = await dispatch(handlePostRequest(formData, '/customerloyalitypromotions/v1/customerloyalitypromotion/savecampaign', true, false));
                if (res?.responseCode === '150000') {
                    formik.resetForm();
                    handleStepClick(activeIndex + 1);
                    setAllStepsComplete(true);
                }
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const addStartEndTime = () => {
        formik.setFieldValue('frequencyCcTimeList', [
            ...formik.values.frequencyCcTimeList,
            {
                startTime: '',
                endTime: ''
            }
        ]);
    };

    const removeStartEndTime = (index) => {
        if (index > 0) {
            const updatedData = [...formik.values.frequencyCcTimeList];
            updatedData.splice(index, 1);
            formik.setFieldValue('frequencyCcTimeList', updatedData);
        }
        if (index === 0) {
            const updatedData = [...formik.values.frequencyCcTimeList];
            formik.setFieldValue('frequencyCcTimeList', updatedData);
        }
    };

    const getCommunicationMediumData = [
        { name: 'SMS', value: 'S' },
        { name: 'Application Notification', value: 'N' },
        { name: 'Both', value: 'B' }
    ];

    const getMessageTypeLov = [
        {
            name: 'One Time',
            lovId: 'O'
        },

        {
            name: 'Recurring',
            lovId: 'R'
        }
    ];

    const weekDays = [
        { name: 'Monday', lovId: 'Monday' },
        { name: 'Tuesday', lovId: 'Tuesday' },
        { name: 'Wednesday', lovId: 'Wednesday' },
        { name: 'Thursday', lovId: 'Thursday' },
        { name: 'Friday', lovId: 'Friday' },
        { name: 'Saturday', lovId: 'Saturday' },
        { name: 'Sunday', lovId: 'Sunday' }
    ];

    const Dates = [
        { name: '1', lovId: '1' },
        { name: '2', lovId: '2' },
        { name: '3', lovId: '3' },
        { name: '4', lovId: '4' },
        { name: '5', lovId: '5' },
        { name: '6', lovId: '6' },
        { name: '7', lovId: '7' },
        { name: '8', lovId: '8' },
        { name: '9', lovId: '9' },
        { name: '10', lovId: '10' },
        { name: '11', lovId: '11' },
        { name: '12', lovId: '12' },
        { name: '13', lovId: '13' },
        { name: '14', lovId: '14' },
        { name: '15', lovId: '15' },
        { name: '16', lovId: '16' },
        { name: '17', lovId: '17' },
        { name: '18', lovId: '18' },
        { name: '19', lovId: '19' },
        { name: '20', lovId: '20' },
        { name: '21', lovId: '21' },
        { name: '22', lovId: '22' },
        { name: '23', lovId: '23' },
        { name: '24', lovId: '24' },
        { name: '25', lovId: '25' },
        { name: '26', lovId: '26' },
        { name: '27', lovId: '27' },
        { name: '28', lovId: '28' },
        { name: '29', lovId: '29' },
        { name: '30', lovId: '30' },
        { name: '31', lovId: '31' }
    ];

    const monthsLov = [
        { name: 'January', lovId: 'January' },
        { name: 'February', lovId: 'February' },
        { name: 'March', lovId: 'March' },
        { name: 'April', lovId: 'April' },
        { name: 'May', lovId: 'May' },
        { name: 'June', lovId: 'June' },
        { name: 'July', lovId: 'July' },
        { name: 'August', lovId: 'August' },
        { name: 'September', lovId: 'September' },
        { name: 'October', lovId: 'October' },
        { name: 'November', lovId: 'November' },
        { name: 'December', lovId: 'December' }
    ];

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>Edit Customer Communication</h1>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="ccCommunicationMedium" className={classNames({ 'p-error': isFormFieldValid('ccCommunicationMedium') }, 'Label__Text')}>
                                    Communication Medium
                                </label>
                                <Dropdown
                                    id="ccCommunicationMedium"
                                    placeholder="Select Limit Duration"
                                    options={getCommunicationMediumData}
                                    optionLabel="name"
                                    name="ccCommunicationMedium"
                                    optionValue="value"
                                    disabled={true}
                                    value={formik?.values?.ccCommunicationMedium || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('ccCommunicationMedium') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('ccCommunicationMedium')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-center">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox
                                        inputId="isMessageContent"
                                        name="isMessageContent"
                                        value={formik?.values?.isMessageContent}
                                        checked={formik?.values?.isMessageContent}
                                        onChange={formik.handleChange}
                                        style={{ marginTop: '10px' }}
                                        disabled
                                    />
                                    <label htmlFor="isMessageContent" className={classNames({ 'p-error': isFormFieldValid('isMessageContent') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Message Content
                                    </label>
                                </div>
                                {getFormErrorMessage('isMessageContent')}
                            </div>
                        </div>
                        {(formik?.values?.ccCommunicationMedium === 'S' || formik?.values?.ccCommunicationMedium === 'B') && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field" style={{ position: 'relative' }}>
                                        <label htmlFor="ccSmsContent" className={classNames({ 'p-error': isFormFieldValid('ccSmsContent') }, 'Label__Text')}>
                                            SMS
                                        </label>
                                        <InputTextarea
                                            id="ccSmsContent"
                                            placeholder="Enter SMS"
                                            name="ccSmsContent"
                                            rows={5}
                                            cols={30}
                                            autoResize
                                            disabled={true}
                                            value={formik?.values?.ccSmsContent || ''}
                                            onChange={handleTextareaChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('ccSmsContent') }, 'Input__Round')}
                                        />
                                        <p
                                            disabled={true}
                                            onClick={(e) => {
                                                toggleEmojiPicker();
                                                op.current.toggle(e);
                                                toggleVisible();
                                            }}
                                            style={{ cursor: 'pointer', position: 'absolute', top: '25px', right: '10px' }}
                                        >
                                            {'\u{1F600}'}
                                        </p>
                                        <OverlayPanel ref={op} onHide={() => toggleVisible()}>
                                            <EmojiPicker onEmojiClick={onClickEmoji} autoFocusSearch={false} emojiStyle={EmojiStyle.NATIVE} />
                                        </OverlayPanel>
                                        {getFormErrorMessage('ccSmsContent')}
                                    </div>
                                </div>
                            </>
                        )}
                        {(formik?.values?.ccCommunicationMedium === 'B' || formik?.values?.ccCommunicationMedium === 'N') && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="ccNotificationContent" className={classNames({ 'p-error': isFormFieldValid('ccNotificationContent') }, 'Label__Text')}>
                                            Application Notification
                                        </label>
                                        <InputTextarea
                                            id="ccNotificationContent"
                                            placeholder="Enter Application Notification"
                                            name="ccNotificationContent"
                                            rows={5}
                                            cols={30}
                                            autoResize
                                            disabled={true}
                                            value={formik?.values?.ccNotificationContent || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('ccNotificationContent') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('ccNotificationContent')}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="ccMessageType" className={classNames({ 'p-error': isFormFieldValid('ccMessageType') }, 'Label__Text')}>
                                    Message Type
                                </label>
                                <Dropdown
                                    id="ccMessageType"
                                    placeholder="Select Message Type"
                                    options={getMessageTypeLov}
                                    optionLabel="name"
                                    name="ccMessageType"
                                    optionValue="lovId"
                                    disabled={true}
                                    value={formik?.values?.ccMessageType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('ccMessageType') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('ccMessageType')}
                            </div>
                        </div>
                        {formik?.values?.ccMessageType === 'O' && (
                            <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card flex">
                                <div className="p-fluid p-formgrid grid mb-5 w-100">
                                    <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="ccFrequencyMonth" className={classNames({ 'p-error': isFormFieldValid('ccFrequencyMonth') }, 'Label__Text')}>
                                                Month
                                            </label>
                                            <Dropdown
                                                id="ccFrequencyMonth"
                                                placeholder="Select Dates"
                                                options={monthsLov}
                                                optionLabel="name"
                                                name="ccFrequencyMonth"
                                                optionValue="lovId"
                                                disabled={true}
                                                value={formik.values.ccFrequencyMonth || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('ccFrequencyMonth') }, 'Dropdown__Round')}
                                            />
                                            {getFormErrorMessage('ccFrequencyMonth')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="ccFrequencyDate" className={classNames({ 'p-error': isFormFieldValid('ccFrequencyDate') }, 'Label__Text')}>
                                                Date Of Month
                                            </label>
                                            <Dropdown
                                                id="ccFrequencyDate"
                                                placeholder="Select Dates"
                                                options={Dates}
                                                optionLabel="name"
                                                name="ccFrequencyDate"
                                                optionValue="lovId"
                                                disabled={true}
                                                value={formik.values.ccFrequencyDate || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('ccFrequencyDate') }, 'Dropdown__Round')}
                                            />
                                            {getFormErrorMessage('ccFrequencyDate')}
                                        </div>
                                    </div>
                                    {formik?.values?.frequencyCcTimeList?.map((startEndTimeMonthly, index) => {
                                        return (
                                            <div className="col-12 md:col-4 flex-direction-col md:flex" key={index}>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor={`frequencyCcTimeList[${index}].startTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`) }, 'Label__Text')}>
                                                            Start Time
                                                        </label>
                                                        <InputText
                                                            id={`frequencyCcTimeList[${index}].startTime`}
                                                            type="time"
                                                            disabled={true}
                                                            name={`frequencyCcTimeList[${index}].startTime`}
                                                            value={startEndTimeMonthly?.startTime || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`) }, 'Input__Round')}
                                                        />
                                                        <div style={{ width: '100%', padding: '5px' }} />
                                                        {getFormErrorMessage(`frequencyCcTimeList[${index}].startTime`)}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor={`frequencyCcTimeList[${index}].endTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`) }, 'Label__Text')}>
                                                            End Time
                                                        </label>
                                                        <InputText
                                                            id={`frequencyCcTimeList[${index}].endTime`}
                                                            type="time"
                                                            disabled={true}
                                                            name={`frequencyCcTimeList[${index}].endTime`}
                                                            value={startEndTimeMonthly?.endTime || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`) }, 'Input__Round')}
                                                        />
                                                        <div style={{ width: '100%', padding: '5px' }} />
                                                        {getFormErrorMessage(`frequencyCcTimeList[${index}].endTime`)}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                        {formik?.values?.ccMessageType === 'R' && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="ccFrequencyPeriodCode" className={classNames({ 'p-error': isFormFieldValid('ccFrequencyPeriodCode') }, 'Label__Text')}>
                                            Frequency Period
                                        </label>
                                        <Dropdown
                                            id="ccFrequencyPeriodCode"
                                            placeholder="Select Frequency Period"
                                            options={frequencyPeriodData}
                                            optionLabel="name"
                                            name="ccFrequencyPeriodCode"
                                            optionValue="code"
                                            disabled={true}
                                            value={formik?.values?.ccFrequencyPeriodCode || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('ccFrequencyPeriodCode') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('ccFrequencyPeriodCode')}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {formik?.values?.ccMessageType === 'R' && formik?.values?.ccFrequencyPeriodCode === '10' && (
                        <>
                            <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card flex">
                                <div className="p-fluid p-formgrid grid mb-5 w-100">
                                    {formik?.values?.frequencyCcTimeList?.map((startEndTimeDaily, index) => {
                                        return (
                                            <div className="col-12 md:col-6 flex-direction-col md:flex" key={index}>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor={`frequencyCcTimeList[${index}].startTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`) }, 'Label__Text')}>
                                                            Start Time
                                                        </label>
                                                        <InputText
                                                            id={`frequencyCcTimeList[${index}].startTime`}
                                                            type="time"
                                                            disabled={true}
                                                            name={`frequencyCcTimeList[${index}].startTime`}
                                                            value={startEndTimeDaily?.startTime || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`) }, 'Input__Round')}
                                                        />
                                                        <div style={{ width: '100%', padding: '5px' }} />
                                                        {getFormErrorMessage(`frequencyCcTimeList[${index}].startTime`)}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor={`frequencyCcTimeList[${index}].endTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`) }, 'Label__Text')}>
                                                            End Time
                                                        </label>
                                                        <InputText
                                                            id={`frequencyCcTimeList[${index}].endTime`}
                                                            type="time"
                                                            disabled={true}
                                                            name={`frequencyCcTimeList[${index}].endTime`}
                                                            value={startEndTimeDaily?.endTime || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`) }, 'Input__Round')}
                                                        />
                                                        <div style={{ width: '100%', padding: '5px' }} />
                                                        {getFormErrorMessage(`frequencyCcTimeList[${index}].endTime`)}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-2 flex align-items-center pt-3 pb-3">
                                                    <Button
                                                        disabled={true}
                                                        icon="pi pi-plus"
                                                        className="p-button-rounded mt-2"
                                                        aria-label="Filter"
                                                        tooltip="Add New Start & End Time"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            addStartEndTime();
                                                        }}
                                                    />
                                                    <Button
                                                        disabled={true}
                                                        icon="pi pi-minus"
                                                        className="p-button-rounded mt-2 p-button-danger"
                                                        aria-label="Filter"
                                                        tooltip="Remove Start & End Time"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            removeStartEndTime(index);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </>
                    )}
                    {formik?.values?.ccMessageType === 'R' && formik?.values?.ccFrequencyPeriodCode === '20' && (
                        <>
                            <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card flex">
                                <div className="p-fluid p-formgrid grid mb-5 w-100">
                                    {formik?.values?.frequencyCcTimeList?.map((startEndTimeWeekly, index) => {
                                        return (
                                            <div className="col-12 md:col-6 flex-direction-col md:flex" key={index}>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor={`frequencyCcTimeList[${index}].startTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`) }, 'Label__Text')}>
                                                            Start Time
                                                        </label>
                                                        <InputText
                                                            id={`frequencyCcTimeList[${index}].startTime`}
                                                            type="time"
                                                            disabled={true}
                                                            name={`frequencyCcTimeList[${index}].startTime`}
                                                            value={startEndTimeWeekly?.startTime || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`) }, 'Input__Round')}
                                                        />
                                                        <div style={{ width: '100%', padding: '5px' }} />
                                                        {getFormErrorMessage(`frequencyCcTimeList[${index}].startTime`)}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor={`frequencyCcTimeList[${index}].endTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`) }, 'Label__Text')}>
                                                            End Time
                                                        </label>
                                                        <InputText
                                                            id={`frequencyCcTimeList[${index}].endTime`}
                                                            type="time"
                                                            disabled={true}
                                                            name={`frequencyCcTimeList[${index}].endTime`}
                                                            value={startEndTimeWeekly?.endTime || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`) }, 'Input__Round')}
                                                        />
                                                        <div style={{ width: '100%', padding: '5px' }} />
                                                        {getFormErrorMessage(`frequencyCcTimeList[${index}].endTime`)}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-2 flex align-items-center pt-3 pb-3">
                                                    <Button
                                                        disabled={true}
                                                        icon="pi pi-plus"
                                                        className="p-button-rounded mt-2"
                                                        aria-label="Filter"
                                                        tooltip="Add New Start & End Time"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            addStartEndTime();
                                                        }}
                                                    />
                                                    <Button
                                                        disabled={true}
                                                        icon="pi pi-minus"
                                                        className="p-button-rounded mt-2 p-button-danger"
                                                        aria-label="Filter"
                                                        tooltip="Remove Start & End Time"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            removeStartEndTime(index);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="ccFrequencyDay" className={classNames({ 'p-error': isFormFieldValid('ccFrequencyDay') }, 'Label__Text')}>
                                                Days
                                            </label>
                                            <MultiSelect
                                                id="ccFrequencyDay"
                                                placeholder="Select Days"
                                                options={weekDays}
                                                optionLabel="name"
                                                name="ccFrequencyDay"
                                                optionValue="lovId"
                                                value={formik.values.ccFrequencyDay || ''}
                                                className={classNames({ 'p-invalid': isFormFieldValid('ccFrequencyDay') }, 'Dropdown__Round')}
                                            />
                                            {getFormErrorMessage('ccFrequencyDay')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {formik?.values?.ccMessageType === 'R' && formik?.values?.ccFrequencyPeriodCode === '30' && (
                        <>
                            <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card flex">
                                <div className="p-fluid p-formgrid grid mb-5 w-100">
                                    {formik?.values?.frequencyCcTimeList?.map((startEndTimeMonthly, index) => {
                                        return (
                                            <div className="col-12 md:col-6 flex-direction-col md:flex" key={index}>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor={`frequencyCcTimeList[${index}].startTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`) }, 'Label__Text')}>
                                                            Start Time
                                                        </label>
                                                        <InputText
                                                            id={`frequencyCcTimeList[${index}].startTime`}
                                                            type="time"
                                                            disabled={true}
                                                            name={`frequencyCcTimeList[${index}].startTime`}
                                                            value={startEndTimeMonthly?.startTime || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`) }, 'Input__Round')}
                                                        />
                                                        <div style={{ width: '100%', padding: '5px' }} />
                                                        {getFormErrorMessage(`frequencyCcTimeList[${index}].startTime`)}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor={`frequencyCcTimeList[${index}].endTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`) }, 'Label__Text')}>
                                                            End Time
                                                        </label>
                                                        <InputText
                                                            id={`frequencyCcTimeList[${index}].endTime`}
                                                            type="time"
                                                            disabled={true}
                                                            name={`frequencyCcTimeList[${index}].endTime`}
                                                            value={startEndTimeMonthly?.endTime || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`) }, 'Input__Round')}
                                                        />
                                                        <div style={{ width: '100%', padding: '5px' }} />
                                                        {getFormErrorMessage(`frequencyCcTimeList[${index}].endTime`)}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-2 flex align-items-center pt-3 pb-3">
                                                    <Button
                                                        disabled={true}
                                                        icon="pi pi-plus"
                                                        className="p-button-rounded mt-2"
                                                        aria-label="Filter"
                                                        tooltip="Add New Start & End Time"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            addStartEndTime();
                                                        }}
                                                    />
                                                    <Button
                                                        disabled={true}
                                                        icon="pi pi-minus"
                                                        className="p-button-rounded mt-2 p-button-danger"
                                                        aria-label="Filter"
                                                        tooltip="Remove Start & End Time"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            removeStartEndTime(index);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="ccFrequencyDate" className={classNames({ 'p-error': isFormFieldValid('ccFrequencyDate') }, 'Label__Text')}>
                                                Date Of Month
                                            </label>
                                            <MultiSelect
                                                id="ccFrequencyDate"
                                                placeholder="Select Dates"
                                                options={Dates}
                                                optionLabel="name"
                                                name="ccFrequencyDate"
                                                optionValue="lovId"
                                                value={formik.values.ccFrequencyDate || ''}
                                                className={classNames({ 'p-invalid': isFormFieldValid('ccFrequencyDate') }, 'Dropdown__Round')}
                                            />
                                            {getFormErrorMessage('ccFrequencyDate')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {formik?.values?.ccMessageType === 'R' && formik?.values?.ccFrequencyPeriodCode === '40' && (
                        <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card flex">
                            <div className="p-fluid p-formgrid grid mb-5 w-100">
                                <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="ccFrequencyMonth" className={classNames({ 'p-error': isFormFieldValid('ccFrequencyMonth') }, 'Label__Text')}>
                                            Month
                                        </label>
                                        <MultiSelect
                                            id="ccFrequencyMonth"
                                            placeholder="Select Dates"
                                            options={monthsLov}
                                            optionLabel="name"
                                            name="ccFrequencyMonth"
                                            optionValue="lovId"
                                            value={formik.values.ccFrequencyMonth || ''}
                                            className={classNames({ 'p-invalid': isFormFieldValid('ccFrequencyMonth') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('ccFrequencyMonth')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="ccFrequencyDate" className={classNames({ 'p-error': isFormFieldValid('ccFrequencyDate') }, 'Label__Text')}>
                                            Date Of Month
                                        </label>
                                        <MultiSelect
                                            id="ccFrequencyDate"
                                            placeholder="Select Dates"
                                            options={Dates}
                                            optionLabel="name"
                                            name="ccFrequencyDate"
                                            optionValue="lovId"
                                            value={formik.values.ccFrequencyDate || ''}
                                            className={classNames({ 'p-invalid': isFormFieldValid('ccFrequencyDate') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('ccFrequencyDate')}
                                    </div>
                                </div>
                                {formik?.values?.frequencyCcTimeList?.map((startEndTimeMonthly, index) => {
                                    return (
                                        <div className="col-12 md:col-4 flex-direction-col md:flex" key={index}>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor={`frequencyCcTimeList[${index}].startTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`) }, 'Label__Text')}>
                                                        Start Time
                                                    </label>
                                                    <InputText
                                                        id={`frequencyCcTimeList[${index}].startTime`}
                                                        type="time"
                                                        disabled={true}
                                                        name={`frequencyCcTimeList[${index}].startTime`}
                                                        value={startEndTimeMonthly?.startTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`) }, 'Input__Round')}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    {getFormErrorMessage(`frequencyCcTimeList[${index}].startTime`)}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor={`frequencyCcTimeList[${index}].endTime`} className={classNames({ 'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`) }, 'Label__Text')}>
                                                        End Time
                                                    </label>
                                                    <InputText
                                                        id={`frequencyCcTimeList[${index}].endTime`}
                                                        type="time"
                                                        disabled={true}
                                                        name={`frequencyCcTimeList[${index}].endTime`}
                                                        value={startEndTimeMonthly?.endTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`) }, 'Input__Round')}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    {getFormErrorMessage(`frequencyCcTimeList[${index}].endTime`)}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4 flex align-items-center justify-content-evenly pt-3 pb-3">
                                                <Button
                                                    disabled={true}
                                                    icon="pi pi-plus"
                                                    className="p-button-rounded mt-2"
                                                    aria-label="Filter"
                                                    tooltip="Add New Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addStartEndTime();
                                                    }}
                                                />
                                                <Button
                                                    disabled={true}
                                                    icon="pi pi-minus"
                                                    className="p-button-rounded mt-2 p-button-danger"
                                                    aria-label="Filter"
                                                    tooltip="Remove Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        removeStartEndTime(index);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="ccFrequency" className={classNames({ 'p-error': isFormFieldValid('ccFrequency') }, 'Label__Text')}>
                                    Frequency
                                </label>
                                <InputText
                                    id="ccFrequency"
                                    placeholder="Enter Frequency"
                                    name="ccFrequency"
                                    disabled={true}
                                    value={formik?.values?.ccFrequency || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('ccFrequency') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('ccFrequency')}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
export default ViewCustomerCommunicationCampaignChecker;

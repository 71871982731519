import React, { useState, useEffect } from 'react';

import { handleGetRequest } from '../../../services/GetTemplate';
import { handleRequest } from '../../../utils/HandleRequest/handleRequest';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { useLocation } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';

import { Column } from 'primereact/column';

const ViewRuleDomainsChecker = () => {
    const initialValues = { name: '', domainCode: '', friendlyName: '', tables: [], action: '', checkerComments: '' };
    const [userLov, setUserLov] = useState([]);
    let { search } = useLocation();
    const [historyData, setHistoryData] = useState();
    const [statusLovData, setStatusLovData] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setloading] = useState(false);
    const [useCaseError, setUseCaseError] = useState({});
    const query = new URLSearchParams(search);
    const [activeRule, setActiveRule] = useState();
    const tableName = query.get('tableName');
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');

    const handleReset = (e) => {
        formik.resetForm();
    };

    const validationSchema = Yup.object({
        tables: Yup.array().required('This Field is required').min(1, 'This Field is required'),
        name: Yup.string()
            .matches(/^[a-zA-Z\s]+[0-9]*$/, 'Only characters are allowed')
            .required('This Field is required'),
        friendlyName: Yup.string()
            .matches(/^[a-zA-Z\s]+[0-9]*$/, 'Only characters are allowed')
            .required('This Field is required'),
        action: Yup.string().required('Action should not be empty'),
        checkerComments: Yup.string().required('This Field is required').notOneOf([''], 'This Field should not be empty').max(500, 'Maximum 500 characters needed')
    });

    const formik = useFormik({
        initialValues,
        validationSchema,

        onSubmit: async (data) => {
            setloading(true);
            let { domainCode, ...rest } = data;
            // rest.id = viewId;
            if (rest.tables.length > 0) {
                const transformedArray = rest.tables.map((result) => ({
                    dictionaryTablesId: result.dictionaryTablesId
                }));
                // Update the `rest` object with the transformed array
                rest.tables = transformedArray;
            }

            let requestType = 'POST';
            let apiUrl = '/ruleengine/v1/domain/domainCheckerAction';
            let additionalInformation = [
                {
                    infoKey: '',
                    infoValue: ''
                }
            ];
            let mcActionRequest = {
                mcRequestId: mcRequestId,
                mcPeindingRequestId: mcPeindingRequestId,
                checkerId: 1,
                checkerComments: rest?.checkerComments,
                action: rest?.action,
                updatedIndex: null
            };
            delete rest.checkerComments;
            delete rest.action;

            let payload = { domainDTO: { ...rest, id: refTableId, active: activeRule }, mcActionRequest: mcActionRequest };

            let res = await handleRequest(payload, requestType, apiUrl, additionalInformation, dispatch, true, false);

            if (res) {
                navigate('/requestchecker');
            }
            setloading(false);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const panelFooterTemplate = () => {
        const length = formik.values.tables ? formik.values.tables.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/ruleengine/v1/table/view');

        setUserLov(resp?.payLoad);
    };

    function getFriendlyTableNameForId(id) {
        const item = userLov.find((item) => item.dictionaryTablesId === id);
        return item ? item.friendlyTableName : '';
    }
    const getDatabyId = async (apiUrl, requestMCType) => {
        let requestType = 'POST';

        let additionalInformation = [
            {
                infoKey: '',
                infoValue: ''
            }
        ];

        let payLoad;
        if (requestMCType === 'I') {
            payLoad = { id: refTableId };
            let res = await handleRequest(payLoad, requestType, apiUrl, additionalInformation, dispatch, false, true);

            let { code, name, friendlyName, domainTablesList } = res.payLoad;
            formik?.setFieldValue('domainCode', code);
            formik?.setFieldValue('name', name);
            formik?.setFieldValue('friendlyName', friendlyName);

            const filteredTables = domainTablesList.filter((result) => result.dictionaryTables && result.dictionaryTables.friendlyTableName !== null); // Check for null values

            const tableArray = filteredTables.map((result) => ({
                friendlyTableName: result.dictionaryTables.friendlyTableName || '', // Handle null values here
                dictionaryTablesId: result.dictionaryTables.dictionaryTablesId || ''
            }));

            setUserLov(tableArray);
            formik?.setFieldValue('tables', tableArray);
        } else if (requestMCType === 'U') {
            // getDatabyId();
            payLoad = { id: mcRequestId };
            let res = await handleRequest(payLoad, requestType, apiUrl, additionalInformation, dispatch, false, true);

            let { code, name, friendlyName, tables, active } = res.payLoad;

            formik?.setFieldValue('domainCode', code);
            formik?.setFieldValue('name', name);
            formik?.setFieldValue('friendlyName', friendlyName);
            if (active) {
                setActiveRule(active);
            }

            // Check for null values

            const tableArray = tables.map((result) => ({
                friendlyTableName: getFriendlyTableNameForId(result.dictionaryTablesId), // Handle null values here
                dictionaryTablesId: result.dictionaryTablesId || ''
            }));

            setUserLov(tableArray);
            formik?.setFieldValue('tables', tableArray);
            // getMcRequestDataById();
        }
    };
    const getAuthorizationHistoryData = async () => {
        const resp = await handleGetRequest(`/workflow/v1/management/getActionAuthorizationHistory/${refTableId}/${tableName}`);
        setHistoryData(resp?.payLoad);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId('/ruleengine/v1/domain/', requestType);
        } else if (requestType === 'U') {
            getDatabyId('/ruleengine/v1/domain/checker/', requestType);
        }
        getAuthorizationHistoryData();
    }, []);

    useEffect(() => {
        getUserLov();
    }, []);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <div className="title__header">VIEW DOMAIN DETAIL</div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="domainName" className={classNames({ 'p-error': isFormFieldValid('domainName') }, 'Label__Text')}>
                                    Domain Name
                                </label>

                                <InputText
                                    disabled
                                    maxLength={100}
                                    id="dmainName"
                                    name="name"
                                    value={formik?.values?.name?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('name')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="friendlyNameId" className={classNames({ 'p-error': isFormFieldValid('friendlyNameId') }, 'Label__Text')}>
                                    Friendly Name
                                </label>

                                <InputText
                                    disabled
                                    maxLength={100}
                                    id="friendlyName"
                                    name="friendlyName"
                                    value={formik?.values?.friendlyName?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('friendlyName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('friendlyName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="domainCodeId" className={classNames({ 'p-error': isFormFieldValid('domainCodeId') }, 'Label__Text')}>
                                    Domain Code
                                </label>

                                <InputText
                                    maxLength={100}
                                    id="domainCode"
                                    name="domainCode"
                                    disabled
                                    value={formik?.values?.domainCode?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('domainCode') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('domainCodeId')}
                            </div>
                        </div>
                        <div className="Form__Header Full__Width">
                            <div className="title__header">LINKING</div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field customMultiselect">
                                <label htmlFor="tablesId" className={classNames({ 'p-error': isFormFieldValid('tablesId') }, 'Label__Text')}>
                                    Tables <span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="tables"
                                    options={userLov}
                                    placeholder="Select Tables"
                                    name="tables"
                                    display="chip"
                                    optionLabel="friendlyTableName"
                                    optionValue="dictionaryTablesId"
                                    filter
                                    panelFooterTemplate={panelFooterTemplate}
                                    value={formik?.values?.tables?.map((trans) => trans.dictionaryTablesId || '')}
                                    // onChange={(e) => {
                                    //     formik.setFieldValue(
                                    //         'tables',
                                    //         e.value.map((lovId) => ({ dictionaryTablesId:lovId }))
                                    //     );
                                    // }}
                                    className={classNames({ 'p-invalid': isFormFieldValid('tables') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('tables')}
                            </div>
                        </div>

                        <div className="Down__Btn my-5">
                            <Button type="submit" label={'Okay'} onClick={() => navigate('/requestchecker')} className="Btn__Dark" />

                            {/* <Button disabled={loading} onClick={() => navigate('/requestChecker')} label="Cancel" className="Btn__Transparent" /> */}
                        </div>
                    </div>
                </form>

                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <DataTable
                                paginator
                                rows={5}
                                rowsPerPageOptions={[5, 10, 25]}
                                className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                                emptyMessage="No List found."
                                header="Action Authorization History"
                                responsiveLayout="scroll"
                                value={historyData}
                            >
                                <Column field="escalationLevel" header="Excalation Level" />
                                <Column field="checkedBy" header="Checked By" />
                                <Column field="checkedOn" header="Checked On" />
                                <Column field="authorizerComments" header="Authorizor Comments" />
                                <Column field="intimationOn" header="Intimation On" />
                                <Column field="intimationTo" header="Intimation To" />
                                <Column field="authorizationStatus" header="Authorization Status" />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewRuleDomainsChecker;

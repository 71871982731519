import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { handlePostRequest } from '../../../../services/PostTemplate';

const EditMrp = () => {
    const location = useLocation();
    const { rowData } = location.state || {};

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        lkpMrpCodeName: Yup.string().required('This field is required'),
        lkpMrpCodeCode: Yup.string().required('This field is required'),
        lkpMrpCodeDescription: Yup.string().required('This field is required'),
        coaCode: Yup.string().required('This field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            lkpMrpCodeName: '',
            lkpMrpCodeCode: '',
            lkpMrpCodeDescription: '',
            coaCode: ''
        },

        validate: (data) => {
            let errors = {};

            //////////////////////////lkpMrpCodeName////////////////////////
            if (!data?.lkpMrpCodeName) {
                errors.lkpMrpCodeName = 'This field is required';
            } else if (data?.lkpMrpCodeName === '') {
                errors.lkpMrpCodeName = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.lkpMrpCodeName)) {
                errors.lkpMrpCodeName = 'Leading and trailing space is not allowed';
            } else if (!/^[a-zA-Z0-9\s]+$/.test(data?.lkpMrpCodeName)) {
                errors.lkpMrpCodeName = 'Only alphanumeric characters and spaces are allowed';
            }
            //////////////////////////lkpMrpCodeCode////////////////////////
            if (!data?.lkpMrpCodeCode) {
                errors.lkpMrpCodeCode = 'This field is required';
            } else if (data?.lkpMrpCodeCode === '') {
                errors.lkpMrpCodeCode = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.lkpMrpCodeCode)) {
                errors.lkpMrpCodeCode = 'Leading and trailing space is not allowed';
            } else if (/\s/.test(data?.lkpMrpCodeCode)) {
                errors.lkpMrpCodeCode = 'Spaces in between digits are not allowed';
            } else if (!/^[a-zA-Z0-9\s]+$/.test(data?.lkpMrpCodeCode)) {
                errors.lkpMrpCodeCode = 'Only alphanumeric characters are allowed';
            }

            //////////////////////////coaCode////////////////////////
            if (!data?.coaCode) {
                errors.coaCode = 'This field is required';
            } else if (data?.coaCode === '') {
                errors.coaCode = 'This Field should not be empty';
            } else if (!/^[0-9]+$/.test(data?.coaCode)) {
                errors.coaCode = 'Only numeric characters are allowed';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.coaCode)) {
                errors.coaCode = 'Leading and trailing space is not allowed';
            } else if (/\s/.test(data?.coaCode)) {
                errors.coaCode = 'Spaces in between digits are not allowed';
            } else if (!/^[0-9]{4}$/.test(data?.coaCode)) {
                errors.coaCode = 'Numbers should be in between 0000 to 9999';
            }

            return errors;
        },

        onSubmit: async (data) => {
            const coaPayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        lkpMrpCodeId: rowData?.mrpCodeId,
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(coaPayload, '/account/v1/chartofaccount/updatelkpmrpcode', true, false));
            if (res?.responseCode === '010000') {
                navigate(-1);
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        if (rowData) {
            formik.setFieldValue('lkpMrpCodeName', rowData?.mrpCodeName);
            formik.setFieldValue('lkpMrpCodeDescription', rowData?.mrpCodeDescr);
            formik.setFieldValue('lkpMrpCodeCode', rowData?.mrpCodeCode);
            formik.setFieldValue('coaCode', rowData?.coaCode);
        }
    }, [rowData]);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>Edit Details</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">MRP Name</label>
                                <InputText id="lkpMrpCodeName" value={formik.values.lkpMrpCodeName || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('lkpMrpCodeName') }, 'Input__Round')} maxLength={30} />
                                {getFormErrorMessage('lkpMrpCodeName')}
                            </div>
                        </div>


                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">MRP Code</label>
                                <InputText id="lkpMrpCodeCode" value={formik.values.lkpMrpCodeCode || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('lkpMrpCodeCode') }, 'Input__Round')} maxLength={20} />
                                {getFormErrorMessage('lkpMrpCodeCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">MRP Description</label>
                                <InputText
                                    id="lkpMrpCodeDescription"
                                    value={formik.values.lkpMrpCodeDescription || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpMrpCodeDescription') }, 'Input__Round')}
                                    maxLength={199}
                                />
                                {getFormErrorMessage('lkpMrpCodeDescription')}
                            </div>
                        </div>


                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Coa Code</label>
                                <InputText id="coaCode" value={formik.values.coaCode || ''} onChange={formik.handleChange} disabled={true} className={classNames({ 'p-invalid': isFormFieldValid('coaCode') }, 'Input__Round')} maxLength={30} />
                                {getFormErrorMessage('coaCode')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Submit" className="Btn__Dark" />
                        <Button onClick={() => navigate('/mrpcode')} label="Cancel" className="Btn__Transparent" type="button" />
                    </div>
                </form>
            </div>
        </>
    );
};

export default EditMrp;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import * as Yup from 'yup';
import { handlePostRequest } from '../../services/PostTemplate';
import { handleGetRequest } from '../../services/GetTemplate';
import { Dialog } from 'primereact/dialog';

function EditDebitCard() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [requestTypeLov, setRequestTypeLov] = useState([]);
    const [debitRecord, setDebitRecord] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [comments, setComments] = useState('');

    const { viewId } = location.state || {};

    const handleShowModal = () => {
        setShowModal(!showModal);
    };

    const printingSite = [
        {
            name: 'ISLAMABAD'
        },
        {
            name: 'LAHORE'
        },
        {
            name: 'KARACHI'
        }
    ];
    //getting rowData through useLocation

    const validationSchema = Yup.object({
        status: Yup.string().required('This field is required.'),
        embosingName: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
            .required('This field is required.'),
        address: Yup.string().required('This field is required.'),
        cardType: Yup.string().required('This field is required.'),
        printingSite: Yup.string().required('This field is required.')
    });

    const formik = useFormik({
        initialValues: {
            status: '',
            embosingName: '',
            address: '',
            cardType: '',
            printingSite: '',
            accountNumber: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (data) => {
            // setloading(true);
            let payLoad = {
                debitCardRequestId: parseInt(viewId),
                accountNumber: data.accountNumber,
                embosingName: data.embosingName,
                address: data.address,
                cardTypeId: data.cardType,
                printingSite: data.printingSite,
                status: comments ? 'Rejected' : 'Approved',
                comments: comments || ''
            };
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: payLoad,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
           
            const res = await dispatch(handlePostRequest(newData, '/debitcardmanagement/v1/cards/updatecardrequest', true, false, 'debitCardManagement'));
            formik.resetForm();
            setComments('');
            setShowModal(false);
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    //Functions to handle validations error
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getDebitRecord = async () => {
        const resp = await handleGetRequest(`/debitcardmanagement/v1/cards/getcardbyid/${viewId}`);
        const {
            accountNumber,
            cardStatus,
            embosingName,
            address,
            lkpDcFeeType: { dcFeeTypeId },
            printingSite
        } = resp.payLoad;

        formik.setFieldValue('status', cardStatus);
        formik.setFieldValue('accountNumber', accountNumber);
        formik.setFieldValue('embosingName', embosingName);
        formik.setFieldValue('address', address);
        formik.setFieldValue('cardType', dcFeeTypeId.toString());
        formik.setFieldValue('status', cardStatus);
        formik.setFieldValue('printingSite', printingSite);
    };

    const getRequestType = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getdcfeetype');
        setRequestTypeLov(resp?.payLoad);
    };

    useEffect(() => {
        getDebitRecord();
        getRequestType();
    }, []);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>Edit Detail</h2>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('status') }, 'Label__Text')}>
                                    Card Status<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    // keyfilter={'alpha'}
                                    id="status"
                                    disabled={true}
                                    //placeholder="Enter Card Details"
                                    name="status"
                                    value={formik?.values?.status?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('status') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('status')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountNumber" className={classNames({ 'p-error': isFormFieldValid('accountNumber') }, 'Label__Text')}>
                                    Account Details<span className="Label__Required"></span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="accountNumber"
                                    // placeholder="Enter Card Details"
                                    disabled
                                    name="accountNumber"
                                    value={formik?.values?.accountNumber?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountNumber') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('accountNumber')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="embosingName" className={classNames({ 'p-error': isFormFieldValid('embosingName') }, 'Label__Text')}>
                                    Embossing Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="embosingName"
                                    // keyfilter={'alpha'}
                                    // placeholder="Enter Card Details"
                                    name="embosingName"
                                    value={formik?.values?.embosingName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('embosingName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('embosingName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="address" className={classNames({ 'p-error': isFormFieldValid('address') }, 'Label__Text')}>
                                    Address<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="address"
                                    //placeholder="Enter Card Details"
                                    name="address"
                                    value={formik?.values?.address?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('address') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('address')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="cardType" className={classNames({ 'p-error': isFormFieldValid('cardType') }, 'Label__Text')}>
                                    Card Type<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="cardType"
                                    //placeholder="Select Request Type"
                                    options={requestTypeLov}
                                    optionLabel="name"
                                    name="cardType"
                                    optionValue="lovId"
                                    value={formik.values.cardType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cardType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cardType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="printingSite" className={classNames({ 'p-error': isFormFieldValid('printingSite') }, 'Label__Text')}>
                                    Printing Site<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="printingSite"
                                    // placeholder="Select Request Type"
                                    options={printingSite}
                                    optionLabel="name"
                                    name="printingSite"
                                    optionValue="name"
                                    value={formik.values.printingSite || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('printingSite') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('printingSite')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="button" label="Reject" className="Btn__Transparent" onClick={() => handleShowModal()} />
                        <Button type="submit" label="Update" className="Btn__Dark mx-3" />
                        <Button type="button" label="Cancel" className="Btn__Transparent" onClick={() => navigate('/debitcardmanagement')} />
                    </div>
                    <Dialog focusOnShow={true} draggable={false} visible={showModal} onHide={handleShowModal} style={{ width: '40vw' }}>
                        <div className="card Card__Round">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="p-fluid p-formgrid grid mb-5">
                                    <div className="Form__Header Full__Width">
                                        <h2>Reason For Rejection</h2>
                                    </div>
                                    <div className="p-field col-12">
                                        <div className="p-field">
                                            <label htmlFor="comments" className={classNames({ 'p-error': isFormFieldValid('comments') }, 'Label__Text')}>
                                                Reason <span className="Label__Required">*</span>
                                            </label>
                                            <InputTextarea
                                                maxLength={200}
                                                autoResize={false}
                                                rows={5}
                                                cols={30}
                                                id="comments"
                                                name="comments"
                                                value={comments}
                                                onChange={(e) => setComments(e.target.value)}
                                                className={classNames({ 'p-invalid': isFormFieldValid('comments') }, 'TextArea__Round')}
                                            />
                                            {getFormErrorMessage('comments')}
                                        </div>
                                    </div>
                                </div>
                                <div className="Down__Btn">
                                    <Button type="submit" disabled={!comments} label="Okay" className="Btn__Dark mx-3" />
                                </div>
                            </form>
                        </div>
                    </Dialog>
                </form>
            </div>
        </>
    );
}

export default EditDebitCard;

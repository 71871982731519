import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import * as Yup from 'yup';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { InputTextarea } from 'primereact/inputtextarea';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewValidatorChecker() {
    const [loading, setloading] = useState(false);
    const [validatorTypeLov, setValidatorTypeLov] = useState([]);

    const navigate = useNavigate();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const requestTypeDescr = query.get('requestTypeDescr');
    const checkerComments = query.get('checkerComments');
    const tableName = query.get('tableName');

    const getValidatorTypeLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getvalidatortypes/LKP_VALIDATOR_TYPE:*');
        setValidatorTypeLov(resp?.payLoad);
    };

    useEffect(() => {
        getValidatorTypeLov();
    }, []); // eslint-disable-line

    const getDatabyId = async () => {
        setloading(true);

        const res = await handleGetRequest(`/account/v1/validator/getKycValidatorById/${refTableId}`);
        const keyData = res?.payLoad;

        setloading(false);

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            formik.setFieldValue('validatorTypeId', keyData?.lkpValidatorType?.validatorTypeId.toString());
            formik.setFieldValue('checkerComments', checkerComments);
        }
    };

    const getMcRequestDataById = async () => {
        setloading(true);

        const resp = await handleGetRequest(`/account/v1/validator/getKycValidatorUpdateCheckerById/${mcRequestId}`);
        const keyData = resp?.payLoad;

        setloading(false);

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
            formik.setFieldValue('validatorTypeId', keyData?.lkpValidatorType?.validatorTypeId.toString());
            formik.setFieldValue('checkerComments', checkerComments);
        }
    };

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, []); // eslint-disable-line

    // const getValidatorLov = async () => {
    //     const resp = await handleGetRequest('/configuration/v1/lookups/getvalidator/TBL_VALIDATOR:*');
    //     setValidatorLov(resp?.payLoad);
    // };

    // useEffect(() => {
    //     getValidatorLov();
    // }, []);

    const validationSchema = Yup.object().shape({
        checkerComments: Yup.string().required('This Field is required.'),
        action: Yup.string().required('This Field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            validatorName: '',
            validatorTypeId: '',
            argumentName: '',
            argumentValue: '',
            checkerComments: ''
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="validatorName" className={classNames({ 'p-error': isFormFieldValid('validatorName') }, 'Label__Text')}>
                                    Validator Name
                                </label>
                                <InputText
                                    id="validatorName"
                                    placeholder="Enter Validator Name"
                                    name="validatorName"
                                    disabled={true}
                                    value={formik?.values?.validatorName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('validatorName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('validatorName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="validatorTypeId" className={classNames({ 'p-error': isFormFieldValid('validatorTypeId') }, 'Label__Text')}>
                                    Validator Type
                                </label>
                                <Dropdown
                                    id="validatorTypeId"
                                    placeholder="Select a Validator Type"
                                    options={validatorTypeLov}
                                    optionLabel="name"
                                    name="validatorTypeId"
                                    optionValue="lovId"
                                    disabled={true}
                                    value={formik.values.validatorTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('validatorTypeId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('validatorTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="argumentName" className={classNames({ 'p-error': isFormFieldValid('argumentName') }, 'Label__Text')}>
                                    Argument Name
                                </label>
                                <InputText
                                    id="argumentName"
                                    placeholder="Enter Argument Name"
                                    name="argumentName"
                                    disabled={true}
                                    value={formik?.values?.argumentName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('argumentName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('argumentName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="argumentValue" className={classNames({ 'p-error': isFormFieldValid('argumentValue') }, 'Label__Text')}>
                                    Argument Value
                                </label>
                                <InputText
                                    id="argumentValue"
                                    placeholder="Enter Argument Value"
                                    name="argumentValue"
                                    disabled={true}
                                    value={formik?.values?.argumentValue || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('argumentValue') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('argumentValue')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="requestTypeDescr" className={classNames({ 'p-error': isFormFieldValid('requestTypeDescr') }, 'Label__Text')}>
                                    Request Type Description
                                    {/* <span className="Label__Required">*</span> */}
                                </label>
                                <InputText
                                    disabled={true}
                                    id="requestTypeDescr"
                                    placeholder="Enter Name Of Account"
                                    name="requestTypeDescr"
                                    value={requestTypeDescr}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('requestTypeDescr') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('requestTypeDescr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                    isActive
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox disabled={true} inputId="isActive" name="isActive" value={formik?.values?.isActive} checked={formik?.values?.isActive} onChange={formik.handleChange} />
                                </div>
                                {getFormErrorMessage('isActive')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                </label>
                                <InputTextarea
                                    rows={5}
                                    cols={30}
                                    id="checkerComments"
                                    name="checkerComments"
                                    disabled={true}
                                    value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default ViewValidatorChecker;

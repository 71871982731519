import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import ActiveInActiveDialog from '../../../components/ActiveInActiveDialog/ActiveInActiveDialog';

const Company = () => {
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [statusData, setStatusData] = useState([]);
    const [userLovData, setUserLovData] = useState([]);
    const [company, setCompany] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [switchData, setSwitchData] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            companyName: '',
            companyCode:'',
            companyId: '',
            statusId: '2',
            createDate: '',
            createdBy: ''
        },
        onSubmit: async (data) => {
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/cms/v1/cms/searchallcompany', true, true));

            if (res?.responseCode === '220000') {
                setCompany(res?.payLoad);
                formik.resetForm();
            }
            setloadingIcon('pi pi-search');
        }
    });

    const editHandler = (rowData) => {
        navigate('/editcompany', { state: { rowData } });
    };
    const viewHandler = (rowData) => {
        navigate('/viewcompany', { state: { rowData } });
    };

    const handleSwitchChange = async () => {
        const updatedData = company?.map((item) => {
            if (item?.cmsCompanyId === switchData?.cmsCompanyId) {
                const isActive = item?.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setCompany(updatedData);
        let isactive = '';
        if (switchData?.isActive === 'Y') {
            isactive = 'N';
        } else if (switchData?.isActive === 'N') {
            isactive = 'Y';
        }

        const previousValue = switchData?.isActive;
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    id: switchData?.cmsCompanyId,
                    isActive: isactive
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newData, '/cms/v1/cms/inactivecompany', true, false));
        if (res?.responseCode == '220000') {
            const revertedData = company?.map((item) => {
                if (item.cmsCompanyId == switchData?.cmsCompanyId) {
                    const isActive = isactive;
                    return { ...item, isActive };
                }
                return item;
            });
            setCompany(revertedData);
        } else {
            const revertedData = company?.map((item) => {
                if (item.cmsCompanyId == switchData?.cmsCompanyId) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setCompany(revertedData);
        }
    };

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }
        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const statusBodyTemplate = (rowData) => {
        const statusName = statusData?.find((option) => option.lovId == rowData.statusId)?.name;
        const statusColor = statusName === 'Approved' ? '#14A38B' : statusName === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{statusName}</b>
            </p>
        );
    };

    const actionBodyTemplate = (rowData) => {
        const statusName = statusData?.find((option) => option.lovId == rowData.statusId)?.name;
        return (
            <div>
                <InputSwitch
                    className="mr-3"
                    checked={rowData.isActive === 'Y'}
                    disabled={['Pending', 'Rejected'].includes(statusName) ? true : false}
                    onChange={() => {
                        setSwitchData(rowData);
                        setShowModal(true);
                    }}
                />
                <Button tooltip="Edit" icon="pi pi-pencil" tooltipOptions={{ position: 'top' }} onClick={() => editHandler(rowData)} className="p-button-rounded p-button-warning mr-3" />
                <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => viewHandler(rowData)} className="p-button-rounded p-button-primary" />
            </div>
        );
    };

    const getStatusLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusData(res?.payLoad);
    };

    const getUserLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLovData(res?.payLoad);
    };

    useEffect(() => {
        getStatusLov();
        getUserLov();
        // getAllCompanies();
    }, []);

    const handledClicked = () => {
        navigate('/addNewCompany');
    };

    const handleShowModal = () => {
        setShowModal(false);
    };
    const props = {
        showModal,
        handleShowModal,
        handleSwitchChange
    };
    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <ActiveInActiveDialog {...props} />
            <div className="Top__Btn TopBtn__Border">
                <div>
                    <Button label="Add New" onClick={handledClicked} className="Btn__Add__" icon="pi pi-plus" iconPos="left" style={{ width: 'auto' }} />
                </div>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                    <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Company Code</label>
                                <InputText
                                    id="companyCode"
                                    placeholder="Enter Company Code"
                                    name="companyCode"
                                    value={formik.values.companyCode}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('companyCode') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('companyCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Company ID</label>
                                <InputText
                                    id="companyId"
                                    placeholder="Enter Company ID"
                                    name="companyId"
                                    value={formik.values.companyId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('companyId') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('companyId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Company Name</label>
                                <InputText
                                    id="companyName"
                                    placeholder="Enter Company Name"
                                    name="companyName"
                                    value={formik.values.companyName}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('companyName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('companyName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLovData}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createDate" className={classNames({ 'p-error': isFormFieldValid('createDate') }, 'Label__Text')}>
                                    Created Date
                                </label>
                                <InputText id="createDate" type="date" name="createDate" value={formik?.values?.createDate} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('createDate') }, 'Input__Round')} />

                                {getFormErrorMessage('createDate')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="statusId" className={classNames({ 'p-error': isFormFieldValid('statusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select Status"
                                    options={statusData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label="Search" type="submit" disabled={!Object.values(formik.values).some((value) => value !== null && value !== '')} icon={loadingIcon || ''} iconPos="left" className="Btn__Dark" />
                        <Button type="button" label="Reset" iconPos="right" className="Btn__Transparent" onClick={(e) => handleReset(e)} />
                    </div>
                </form>
            </div>
            <div />

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <div>
                            <h4>Existing Details</h4>
                        </div>
                        <DataTable
                            // ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            globalFilterFields={['validatorName', 'validatorType', 'createdate', 'createdBy', 'updatedBy', 'lkpStatus.statusDescr']}
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={company}
                        >
                            <Column  field="cmsCompanyId" header="Company ID" />
                            <Column field="nameEnglish" header="Company Name" />
                            <Column field="cmsMainCategoryName" header="Category" />
                            <Column field="cmsSubCategoryName" header="Sub Category" />
                            <Column field="createdBy" header="Created By" />
                            <Column body={createdDateAndTimeBodyTemplate} header="Created Date & Time" />
                            <Column header="Status" body={statusBodyTemplate} />
                            <Column header="Action" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Company;

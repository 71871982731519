import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { handlePostRequest } from '../../services/PostTemplate';
import { Column } from 'primereact/column';
import { useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
function EditUseCaseManagement() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [dataShowToTable, setDataShowToTable] = useState([]);
    const [activeStates, setActiveStates] = useState({});
    const [selectedRowData, setSelectedRowData] = useState(null);

    const [processNameData, setProcessNameData] = useState([]);
    const [roleData, setRoleData] = useState([]);
    const [useCaseData, setUseCaseData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [tableNameoptions, setTableNameoptions] = useState([]);
    const [payloadDataNew, setPayloadDataNew] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [useCaseError, setUseCaseError] = useState({
        roleId: '',
        approvalCriteria: '',
        userId: ''
    });
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const mcConfigId = query.get('mcConfigId');
    const validationSchema = Yup.object().shape({
        processName: Yup.mixed().required('This field is required.')
    });
    const getUseCaseById = async () => {
        const resp = await handleGetRequest(`/mfs_user_management/v1/management/getUseCaseById/${mcConfigId}`);
        const keyData = resp?.payLoad;
        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik?.setFieldValue(key, keyData[key]);
                }
            });
            setUseCaseData(resp?.payLoad);
            formik.setFieldValue('useCaseName', keyData?.configName);
            formik.setFieldValue('useCaseDescr', keyData?.configDescription);
            formik.setFieldValue('useCaseComments', keyData?.comments);
            formik.setFieldValue('processName', keyData?.formName);
            // Extract and format the data for the DataTable
            const tableData = keyData?.tblMcConfigDetails.map((item) => {
                return {
                    approvalType: item.approvalCriteria === 'R' ? item.approvalType || '' : '',
                    userId:
                        {
                            id: item?.tblUser?.userId,
                            name: item?.tblUser?.firstName
                        } || '',
                    roleId:
                        {
                            id: item?.tblRole?.roleId,
                            name: item?.tblRole?.roleDescr
                        } || '',
                    intimateOnly: item.intimateOnly || '',
                    approvalCriteria: item.approvalCriteria || '',
                    mcConfigDetailId: item.mcConfigDetailId,
                    isActive: item.isActive === 'Y' ? true : false
                };
            });
            setDataShowToTable(tableData);
            setActiveStates(Array(tableData.length).fill(false));
        }
    };

    useEffect(() => {
        getUseCaseById();
    }, []); // eslint-disable-line

    const dt = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formik = useFormik({
        validationSchema,
        initialValues: {
            useCaseName: '',
            useCaseDescr: '',
            processName: '',
            useCaseComments: '',
            useCaseDetailRequests: [
                {
                    approvalType: 'A',
                    intimateOnly: '',
                    approvalCriteria: '',
                    userId: '',
                    roleId: ''
                }
            ]
        },
        validate: (data) => {
            let errors = {};

            //////////////////////////useCaseName////////////////////////
            if (!data?.useCaseName) {
                errors.useCaseName = 'This field is required';
            } else if (data?.useCaseName === '') {
                errors.useCaseName = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.useCaseName)) {
                errors.useCaseName = 'Leading and trailing space is not allowed';
            } else if (!/^[a-zA-Z0-9\s]*$/.test(data?.useCaseName)) {
                errors.useCaseName = 'Special characters are not allowed';
            }
            // //////////////////////////useCaseComments////////////////////////
            if (!data?.useCaseComments) {
                errors.useCaseComments = 'This field is required';
            } else if (data?.useCaseComments === '') {
                errors.useCaseComments = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.useCaseComments)) {
                errors.useCaseComments = 'Leading and trailing space is not allowed';
            }
            // //////////////////////////useCaseDescr////////////////////////
            if (!data?.useCaseDescr) {
                errors.useCaseDescr = 'This field is required';
            } else if (data?.useCaseDescr === '') {
                errors.useCaseDescr = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.useCaseDescr)) {
                errors.useCaseDescr = 'Leading and trailing space is not allowed';
            }

            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            const foundItem = processNameData.find((item) => item.formName === formik.values.processName);
            const payloadData = {
                mcConfigId: mcConfigId,
                useCaseName: data.useCaseName,
                useCaseDescr: data.useCaseDescr,
                useCaseComments: data.useCaseComments,
                processName: {
                    tableName: foundItem.tableName,
                    formName: foundItem.formName,
                    viewDetailUrl: foundItem.viewDetailUrl,
                    editDetailUrl: foundItem.editDetailUrl,
                    requestType: foundItem.requestType
                },
                useCaseDetailRequests: payloadDataNew[0]
            };
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: payloadData,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            if (payloadDataNew.length <= 0) {
                toast.warning('Please  fill Define Approval');
            } else {
                const res = await dispatch(handlePostRequest(newData, '/mfs_user_management/v1/management/updateusecase', true, false, 'useCaseManagement'));
                if (res?.responseCode === '030000') {
                }
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });
    useEffect(() => {
        let newdataTable = [];
        if (dataShowToTable?.length) {
            const mappedData = dataShowToTable?.map((item, key) => ({
                approvalType: item?.approvalType,
                approvalCriteria: item?.approvalCriteria,
                intimateOnly: item?.intimateOnly === 'Y' ? 'Y' : 'N',
                userId: item?.userId?.id?.toString() || '',
                roleId: item?.roleId?.id?.toString() || '',
                mcConfigDetailId: useCaseData?.tblMcConfigDetails[key]?.mcConfigDetailId.toString(),
                isActive: item?.isActive ? 'Y' : 'N'
            }));
            newdataTable.push(mappedData);
        }
        setPayloadDataNew(newdataTable);
    }, [dataShowToTable]); // eslint-disable-line

    const getUserName = (id) => {
        const userOption = userLov.find((option) => option.lovId === id);
        return userOption ? userOption.name : '';
    };
    const getRoleName = (id) => {
        const roleOption = roleData.find((option) => option.lovId === id);
        return roleOption ? roleOption.name : '';
    };
    const handleAddOrUpdate = (event) => {
        event.preventDefault();

        if (formik.values.approvalCriteria !== undefined) {
            if (selectedRowData) {
                // when user populate the screen onclick of edit button and user select role id then execute this payload
                if (formik.values.roleId) {
                    const updatedDataShowToTable = dataShowToTable.map((rowData) => {
                        if (rowData === selectedRowData) {
                            const updatedRowData = {
                                ...rowData,

                                approvalCriteria: formik.values.approvalCriteria,
                                userId: {
                                    id: formik.values.userId,
                                    name: getUserName(formik.values.userId)
                                },
                                roleId: {
                                    id: formik.values.roleId,
                                    name: getRoleName(formik.values.roleId)
                                },
                                intimateOnly: formik.values.intimateOnly ? 'Y' : 'N',
                                approvalType: formik.values.useCaseDetailRequests[0].approvalType
                                // isActive
                            };

                            return updatedRowData;
                        }
                        return rowData;
                    });

                    setDataShowToTable(updatedDataShowToTable);
                    formik.setFieldValue('approvalCriteria', undefined);
                    formik.setFieldValue('intimateOnly', false);
                    formik.setFieldValue('useCaseDetailRequests[0].approvalType', 'A');

                    setSelectedRowData(null);
                    // user id selection scenario
                } else if (formik.values.userId) {
                    const updatedDataShowToTable = dataShowToTable.map((rowData) => {
                        if (rowData === selectedRowData) {
                            const updatedRowData = {
                                ...rowData,

                                approvalCriteria: formik.values.approvalCriteria,
                                userId: {
                                    id: formik.values.userId,
                                    name: getUserName(formik.values.userId)
                                },
                                roleId: {
                                    id: formik.values.roleId,
                                    name: getRoleName(formik.values.roleId)
                                },
                                intimateOnly: formik.values.intimateOnly ? 'Y' : 'N',
                                approvalType: ''
                                // isActive
                            };

                            return updatedRowData;
                        }
                        return rowData;
                    });

                    setDataShowToTable(updatedDataShowToTable);
                    formik.setFieldValue('approvalCriteria', undefined);
                    formik.setFieldValue('intimateOnly', false);

                    setSelectedRowData(null);
                }
                formik.validateForm().then((errors) => {
                    if ((formik.values.approvalCriteria === 'R' && !formik.values.roleId) || formik.values.roleId === '') {
                        setUseCaseError({ roleId: 'This Feild is Required.' });
                        return;
                    } else if ((formik.values.approvalCriteria === 'U' && !formik.values.userId) || formik.values.userId === '') {
                        setUseCaseError({ userId: 'This Feild is required' });

                        return;
                    }
                    formik.setErrors(errors);
                });
            } else {
                // Add a new row to the DataTable
                // Role id selection crieteria
                if (formik.values.roleId) {
                    const newRowData = {
                        approvalCriteria: formik.values.approvalCriteria,
                        userId: {
                            id: formik.values.userId,
                            name: getUserName(formik.values.userId)
                        },
                        roleId: {
                            id: formik.values.roleId,
                            name: getRoleName(formik.values.roleId)
                        },
                        intimateOnly: formik.values.intimateOnly ? 'Y' : 'N',
                        approvalType: formik.values.useCaseDetailRequests[0].approvalType,
                        isActive: true
                    };

                    setDataShowToTable([...dataShowToTable, newRowData]);
                    formik.setFieldValue('approvalCriteria', undefined);
                    formik.setFieldValue('intimateOnly', false);
                    formik.setFieldValue('useCaseDetailRequests[0].approvalType', 'A');
                    // user id selection criteria
                } else if (formik.values.userId) {
                    const newRowData = {
                        approvalCriteria: formik.values.approvalCriteria,
                        userId: {
                            id: formik.values.userId,
                            name: getUserName(formik.values.userId)
                        },
                        roleId: {
                            id: formik.values.roleId,
                            name: getRoleName(formik.values.roleId)
                        },
                        intimateOnly: formik.values.intimateOnly ? 'Y' : 'N',

                        isActive: true
                    };
                    setDataShowToTable([...dataShowToTable, newRowData]);
                    formik.setFieldValue('approvalCriteria', undefined);
                    formik.setFieldValue('intimateOnly', false);
                }
                // validation
                formik.validateForm().then((errors) => {
                    if ((formik.values.approvalCriteria === 'R' && !formik.values.roleId) || formik.values.roleId === '') {
                        setUseCaseError({ roleId: 'This Feild is Required.' });
                        return;
                    } else if ((formik.values.approvalCriteria === 'U' && !formik.values.userId) || formik.values.userId === '') {
                        setUseCaseError({ userId: 'This Feild is required' });

                        return;
                    }
                    formik.setErrors(errors);
                });
            }
            // formik.setFieldValue('emailTemplateId', undefined);

            // formik.setFieldValue('approvalCriteria', undefined);
            // formik.setFieldValue('roleId', undefined);
            // formik.setFieldValue('userId', undefined);
            // formik.setFieldValue('approvalType', '');
            // formik.setFieldValue('intimateOnly', false);
            // setEditMode(false);
            // setIsEdit(false);
        }
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const statusData = [
        { label: 'Role Based ', value: 'R' },
        { label: 'User Based ', value: 'U' }
    ];
    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };
    const getProcess = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getprocessname/LKP_MC_FORM_NAME:*');
        const data = resp?.payLoad;
        const tableNames = data.map((item) => {
            return item?.formName;
        });
        setProcessNameData(data);
        setTableNameoptions(tableNames);
    };

    const getRole = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getUserGroup/TBL_ROLE:*');
        setRoleData(resp?.payLoad);
    };
    useEffect(() => {
        getRole();

        getUserLov();
        getProcess();
    }, []); // eslint-disable-line

    const handledAprrovalTypeBodyTemplate = (rowData) => {
        if (rowData) {
            return <div>{rowData?.approvalCriteria === 'R' ? 'Role Based' : rowData?.approvalCriteria === 'U' ? 'User Based' : null}</div>;
        }
    };
    const handledIntimateOnlyBodyTemplate = (rowData) => {
        if (rowData) {
            return <div>{rowData?.intimateOnly === 'Y' ? 'Yes' : rowData?.intimateOnly === 'N' ? 'No' : null}</div>;
        }
    };
    const handledApprovalCrieteriaBodyTemplate = (rowData) => {
        if (rowData) {
            return <div>{rowData?.approvalType === 'N' ? 'Any One' : rowData?.approvalType === 'A' ? 'All' : ''}</div>;
        }
    };
    const actionBodyTemplate = (rowData) => {
        const handleEdit = (rowData) => {
            setIsEdit(true);
            setSelectedRowData(rowData);
        };

        const handleChange = (dataId) => {
            const updatedDataShowToTable = dataShowToTable.map((item) => {
                if (item.mcConfigDetailId === dataId) {
                    // Toggle the isActive property for the specific item
                    return {
                        ...item,
                        isActive: !item.isActive
                    };
                }
                return item;
            });

            setDataShowToTable(updatedDataShowToTable);
        };

        return (
            <>
                <div className="flex">
                    <div className="actions">
                        <Button type="button" tooltip="edit" tooltipOptions={{ position: 'top' }} icon="pi pi-pencil" onClick={() => handleEdit(rowData)} className="p-button-rounded p-button-warning" />
                    </div>
                    <div className="actions">
                        <InputSwitch checked={rowData.isActive} onChange={() => handleChange(rowData.mcConfigDetailId)} style={{ marginLeft: '10px' }} />
                    </div>
                </div>
            </>
        );
    };

    useEffect(() => {
        if (selectedRowData) {
            // update the formik form fields as follows:

            formik.setFieldValue('approvalCriteria', selectedRowData.approvalCriteria);
            formik.setFieldValue('userId', selectedRowData.userId.id?.toString());
            formik.setFieldValue('roleId', selectedRowData.roleId.id?.toString());
            formik.setFieldValue('intimateOnly', selectedRowData.intimateOnly === 'Y');
            formik.setFieldValue('approvalType', selectedRowData.approvalType);
        }
    }, [selectedRowData]); // eslint-disable-line

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>Edit Detail</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="useCaseName" className={classNames({ 'p-error': isFormFieldValid('useCaseName') }, 'Label__Text')}>
                                    Use Case Name
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="useCaseName"
                                    placeholder="Enter Use Case Name"
                                    name="useCaseName"
                                    value={formik?.values?.useCaseName}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('useCaseName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('useCaseName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="useCaseComments" className={classNames({ 'p-error': isFormFieldValid('useCaseComments') }, 'Label__Text')}>
                                    Comment
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="useCaseComments"
                                    placeholder="Add Comment"
                                    name="useCaseComments"
                                    value={formik?.values?.useCaseComments?.replace(/\s\s+/g, '')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('useCaseComments') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('useCaseComments')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="approval" className={classNames({ 'p-error': isFormFieldValid('approval') }, 'Label__Text')}>
                                    Process Name
                                </label>
                                <Dropdown
                                    id="processName"
                                    options={tableNameoptions}
                                    // optionLabel="formName"
                                    name="processName"
                                    // optionValue="mcFormNameId"
                                    value={formik.values.processName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('processName') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('processName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="useCaseDescr" className={classNames({ 'p-error': isFormFieldValid('useCaseDescr') }, 'Label__Text')}>
                                    Description
                                </label>
                                <InputTextarea
                                    // placeholder="Add Description"
                                    id="useCaseDescr"
                                    name="useCaseDescr"
                                    value={formik?.values?.useCaseDescr}
                                    rows={5}
                                    cols={10}
                                    autoResize="false"
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('useCaseDescr') }, 'TextArea__Round')}
                                />
                                {getFormErrorMessage('useCaseDescr')}
                            </div>
                        </div>

                        <div className="col-12 md:col-8 Form__Header Full__Width"></div>
                    </div>
                    <div className="card p-fluid p-formgrid grid  " style={{ backgroundColor: 'rgb(237, 240, 245)' }}>
                        <div className="p-field col-12 md:col-3">
                            <div className="p-field">
                                <label htmlFor="approvalCriteria" className={classNames({ 'p-error': isFormFieldValid('approvalCriteria') }, 'Label__Text')}>
                                    Define Approval
                                </label>
                                <Dropdown
                                    id="approvalCriteria"
                                    // placeholder=""
                                    options={statusData}
                                    optionLabel="label"
                                    name="approvalCriteria"
                                    optionValue="value"
                                    value={formik.values.approvalCriteria || ''}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        formik.setFieldValue('roleId', undefined);
                                        formik.setFieldValue('approvalType', '');
                                        // formik.setFieldValue('useCaseDetailRequests[0].approvalType', '');
                                        formik.setFieldValue('userId', undefined);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldValid('approvalCriteria') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('approvalCriteria')}
                            </div>
                        </div>
                        {formik.values.approvalCriteria === 'R' ? (
                            <div className="p-field col-12 md:col-3">
                                <div className="p-field">
                                    <label htmlFor="roleId" className={classNames({ 'p-error': isFormFieldValid('roleId') }, 'Label__Text')}>
                                        Role
                                    </label>
                                    <Dropdown
                                        id="roleId"
                                        // placeholder="Select Role"
                                        options={roleData}
                                        optionLabel="name"
                                        name="roleId"
                                        optionValue="lovId"
                                        value={formik.values.roleId || ''}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            // handledFieldInputData()
                                            setUseCaseError({ roleId: '' });
                                        }}
                                        className={classNames({ 'p-invalid': isFormFieldValid('roleId') }, 'Dropdown__Round')}
                                    />
                                    {useCaseError.roleId !== '' ? <small className="p-error">{useCaseError.roleId}</small> : ''}
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        {formik.values.approvalCriteria === 'U' ? (
                            <div className="p-field col-12 md:col-3">
                                <div className="p-field">
                                    <label htmlFor="userId" className={classNames({ 'p-error': isFormFieldValid('userId') }, 'Label__Text')}>
                                        User
                                    </label>
                                    <Dropdown
                                        id="userId"
                                        options={userLov}
                                        optionLabel="name"
                                        name="userId"
                                        optionValue="lovId"
                                        value={formik.values.userId || ''}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            // handledFieldInputData()
                                            setUseCaseError({ userId: '' });
                                        }}
                                        className={classNames({ 'p-invalid': isFormFieldValid('userId') }, 'Dropdown__Round')}
                                    />
                                    <small className="p-error">{useCaseError.userId}</small>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        {formik.values.approvalCriteria === 'R' ? (
                            <div className="col-12 lg:col-6 xl:col-4 md:col-4 pt-5 pb-5 flex">
                                <div className="flex justify-content-center gap-2">
                                    <div className="flex ">
                                        <div className="">
                                            <label htmlFor="approvalType" className={classNames({ 'p-error': isFormFieldValid('velocity') }, 'Label__Text')}>
                                                Approval Criteria
                                            </label>
                                        </div>

                                        <div className="flex">
                                            <div className="flex ml-3">
                                                <RadioButton
                                                    inputId="approvalTypeAnyone"
                                                    name="approvalType"
                                                    value="N"
                                                    onChange={(e) => {
                                                        formik.setFieldValue('useCaseDetailRequests[0].approvalType', e.target.value);
                                                    }}
                                                    checked={formik.values.useCaseDetailRequests[0].approvalType === 'N'}
                                                />
                                                <label htmlFor="approvalType" className="ml-2">
                                                    Any One
                                                </label>
                                            </div>
                                            <div style={{ marginLeft: '5rem' }} className="flex mr-3">
                                                <RadioButton
                                                    inputId="approvalType"
                                                    name="approvalType"
                                                    value="A"
                                                    onChange={(e) => {
                                                        formik.setFieldValue('useCaseDetailRequests[0].approvalType', e.target.value);
                                                    }}
                                                    checked={formik.values.useCaseDetailRequests[0].approvalType === 'A'}
                                                />
                                                <label htmlFor="approvalType" className="ml-2">
                                                    All
                                                </label>
                                            </div>
                                        </div>
                                        {getFormErrorMessage('approvalType')}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        <div className="">
                            <div className="p-field col-12 md:col-2 pt-5 pb-5 flex">
                                <div className="p-field">
                                    <div className="field-checkbox">
                                        <label htmlFor="intimateOnly" className={classNames({ 'p-error': isFormFieldValid('intimateOnly') }, 'Label__Text')}>
                                            Intimate Only
                                        </label>
                                        <Checkbox inputId="intimateOnly" name="intimateOnly" value={formik.values.intimateOnly} checked={formik.values.intimateOnly} onChange={formik.handleChange} className=" ml-3" />
                                    </div>
                                    {getFormErrorMessage('intimateOnly')}
                                </div>
                            </div>
                        </div>

                        <div className="Usergroup__SearchBtn p-field flex flex-row  justify-content-center pt-2 col-12" style={{ textAlign: 'center' }}>
                            <div className="">
                                <Button type="button" onClick={handleAddOrUpdate} label={`${isEdit ? 'Update' : 'Add'}`} className="Btn__Dark__Search" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card">
                            <DataTable
                                ref={dt}
                                paginator
                                rows={5}
                                rowsPerPageOptions={[5, 10, 25]}
                                className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} "
                                emptyMessage="No List found."
                                responsiveLayout="scroll"
                                value={dataShowToTable}
                                // selectionMode="single" // Allow selecting a single row
                                // selection={selectedRowData}
                                onSelectionChange={(e) => setSelectedRowData(e.value)}
                            >
                                <Column body={(data, props) => <div>{props.rowIndex + 1}</div>} header="Sr" />

                                <Column field="approvalCriteria" body={handledAprrovalTypeBodyTemplate} header="Define Approval" />
                                <Column field="userId.name" header="User" />
                                <Column field="roleId.name" header="Role" />
                                <Column header="Intimate Only" body={handledIntimateOnlyBodyTemplate} />
                                <Column header="Approval Criteria" body={handledApprovalCrieteriaBodyTemplate} />
                                <Column header="Action" body={actionBodyTemplate} />
                            </DataTable>
                            <div className="Usergroup__SearchBtn p-field flex flex-row  justify-content-center pt-2 col-12" style={{ textAlign: 'center' }}>
                                <div className="Down__Btn">
                                    <Button disabled={loading} loadingIcon={loadingIcon} label="Update" className="Btn__Dark" />
                                    <Button disabled={loading} type="button" onClick={() => navigate('/useCaseManagement')} label="Cancel" className="Btn__Transparent" />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
export default EditUseCaseManagement;

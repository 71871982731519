import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function EditErrorChecker() {
    const [useCaseData, setUseCaseData] = useState([]);
    const [errorResp, setErorResp] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');
    const [statusLovData, setStatusLovData] = useState([]);

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };

    const getUseCase = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_USECASE');
        setUseCaseData(resp?.payLoad);
    };

    const getErorResp = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/RESPONSE_MESSAGE_TYPE');
        setErorResp(resp?.payLoad);
    };

    useEffect(() => {
        getErorResp();
        getUseCase();
        getStatusLov();
    }, []);

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('This field is required'),
        checkerComments: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
            .required('This field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            responseMessageId: '',
            responseMessageCode: '',
            responseMessageDescr: '',
            updateindex: '',
            responseMessageTypeId: '',
            responseMessageTitle: '',
            responseMessageUrduDesc: '',
            isActive: '',
            cmsUsecaseId: ''
        },

        onSubmit: async (data) => {
            const updatePayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        objectBody: {
                            responseMessageId: data?.responseMessageId,
                            responseMessageCode: data?.responseMessageCode,
                            responseMessageDescr: data?.responseMessageDescr,
                            updateindex: data?.updateindex,
                            responseMessageTypeId: data?.responseMessageTypeId,
                            responseMessageTitle: data?.responseMessageTitle,
                            responseMessageUrduDesc: data?.responseMessageUrduDesc,
                            // moduleId: data?.moduleId,
                            cmsUsecaseId: data?.cmsUsecaseId
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: data?.checkerComments,
                            action: data?.action,
                            updatedIndex: ''
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const activeInactivePayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        objectBody: {
                            responseMessageId: data?.responseMessageId,
                            responseMessageCode: data?.responseMessageCode,
                            responseMessageDescr: data?.responseMessageDescr,
                            updateindex: data?.updateindex,
                            responseMessageTypeId: data?.responseMessageTypeId,
                            responseMessageTitle: data?.responseMessageTitle,
                            responseMessageUrduDesc: data?.responseMessageUrduDesc,

                            isActive: data?.isActive,
                            // moduleId: data?.moduleId,
                            cmsUsecaseId: data?.cmsUsecaseId
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: data?.checkerComments,
                            action: data?.action,
                            updatedIndex: ''
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
                await dispatch(handlePostRequest(updatePayload, '/cms/v1/cms/errorvalidatorcheckeraction', true, false, 'requestchecker'));
            } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
                await dispatch(handlePostRequest(activeInactivePayload, '/cms/v1/cms/errorvalidatorcheckeraction', true, false, 'requestchecker'));
            }
        }
    });

    const getMcRequestDataById = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/getmcrequestbyid/${mcRequestId}`);
        const unParsedkeyData = res?.payLoad?.updateJson;
        const keyData = JSON.parse(unParsedkeyData);
        formik.setFieldValue('responseMessageId', keyData?.responseMessageId);
        formik.setFieldValue('responseMessageUrduDesc', keyData?.responseMessageUrduDesc);
        formik.setFieldValue('responseMessageCode', keyData?.responseMessageCode);
        formik.setFieldValue('responseMessageDescr', keyData?.responseMessageDescr);
        formik.setFieldValue('responseMessageTitle', keyData?.responseMessageTitle || '');
        formik.setFieldValue('cmsUsecaseId', keyData?.cmsUsecaseId?.toString());
        formik.setFieldValue('responseMessageTypeId', keyData?.responseMessageTypeId?.toString());
        formik.setFieldValue('isActive', keyData?.isActive);
    };

    useEffect(() => {
        if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, [requestType]);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>EDIT DETAIL</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="responseMessageCode" className={classNames({ 'p-error': isFormFieldValid('responseMessageCode') }, 'Label__Text')}>
                                    Error Code <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="responseMessageCode"
                                    placeholder="Enter Error Code"
                                    name="responseMessageCode"
                                    value={formik?.values?.responseMessageCode || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('responseMessageCode') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('responseMessageCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="responseMessageTypeId" className={classNames({ 'p-error': isFormFieldValid('responseMessageTypeId') }, 'Label__Text')}>
                                    Error Category
                                </label>
                                <Dropdown
                                    id="responseMessageTypeId"
                                    placeholder="Enter Error Category"
                                    options={errorResp}
                                    optionLabel="name"
                                    name="responseMessageTypeId"
                                    optionValue="lovId"
                                    value={formik.values.responseMessageTypeId || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('responseMessageTypeId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('responseMessageTypeId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="responseMessageDescr" className={classNames({ 'p-error': isFormFieldValid('responseMessageDescr') }, 'Label__Text')}>
                                    Error Details <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="responseMessageDescr"
                                    placeholder="Enter Error Details"
                                    name="responseMessageDescr"
                                    value={formik?.values?.responseMessageDescr || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('responseMessageDescr') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('responseMessageDescr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="responseMessageUrduDesc" className={classNames({ 'p-error': isFormFieldValid('responseMessageUrduDesc') }, 'Label__Text')}>
                                    Urdu Description<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="responseMessageUrduDesc"
                                    placeholder="Enter  Details"
                                    name="responseMessageUrduDesc"
                                    value={formik?.values?.responseMessageUrduDesc || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('responseMessageUrduDesc') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('responseMessageUrduDesc')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="responseMessageTitle" className={classNames({ 'p-error': isFormFieldValid('responseMessageTitle') }, 'Label__Text')}>
                                   English  Description<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="responseMessageTitle"
                                    placeholder="Enter  Details"
                                    name="responseMessageTitle"
                                    value={formik?.values?.responseMessageTitle || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('responseMessageTitle') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('responseMessageTitle')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsUsecaseId" className={classNames({ 'p-error': isFormFieldValid('cmsUsecaseId') }, 'Label__Text')}>
                                    Use Case<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="cmsUsecaseId"
                                    placeholder="Select Use Case"
                                    options={useCaseData}
                                    optionLabel="name"
                                    name="cmsUsecaseId"
                                    optionValue="lovId"
                                    value={formik.values.cmsUsecaseId || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsUsecaseId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsUsecaseId')}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid grid col-12 p-3 bg-bluegray-50 border-round-sm mt-3">
                            <div className="p-fluid p-formgrid grid ml-5 col-10">
                                <div className="p-field col-4 md:col-4">
                                    <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                        Select Action
                                    </label>
                                    <Dropdown
                                        id="action"
                                        placeholder="Select Action"
                                        options={statusLovData}
                                        optionLabel="name"
                                        name="action"
                                        optionValue="lovId"
                                        value={formik.values.action || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('action')}
                                </div>
                                <div className="col-8 md:col-8 ">
                                    <div className="p-field">
                                        <label htmlFor="checkerComments" className="Label__Text">
                                            Comment
                                        </label>
                                        <br />
                                        <textarea
                                            placeholder="Enter Comment"
                                            id="checkerComments"
                                            name="checkerComments"
                                            value={formik.values.checkerComments || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'Dropdown__Round outline-none')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="submit" label="Update" className="Btn__Dark" />
                        <Button onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default EditErrorChecker;

import React, { useState, useEffect } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { useFormik } from 'formik';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

/* eslint-disable */
const AddEditMenu = ({ onHide, editable, menuRowData, getMenu }) => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-save');
    const [moduleLovData, setModuleLovData] = useState([]);

    const dispatch = useDispatch();

    const menuId = `${menuRowData?.menuId}`;

    const getModuleLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getallmodules/TBL_MODULE:*');
        setModuleLovData(resp?.payLoad);
    };

    const getMenuByMenuId = async () => {
        const res = await handleGetRequest(`/mfs_user_management/v1/management/getMenuById/${menuId}`);
        const keyData = res?.payLoad;
        keyData.forEach((data) => {
            Object.keys(data).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, data[key]);
                }
            });
        });
        formik.setFieldValue('moduleId', keyData[0]?.moduleId?.toString());
        formik.setFieldValue('menuPath', keyData[0]?.menuUrl);
    };

    useEffect(() => {
        if (editable) {
            getMenuByMenuId();
        }
    }, []);

    useEffect(() => {
        if (!editable) {
            formik.resetForm();
        }
    }, [editable]);

    useEffect(() => {
        getModuleLov();
    }, []);

    const menuTypeOptions = [
        // { label: 'form', value: 'F' },
        { label: 'page', value: 'P' }
    ];

    const validationSchema = Yup.object().shape({
        menuDescr: Yup.string().required('This field is required.'),
        menuType: Yup.mixed().required('This field is required.'),
        moduleId: Yup.mixed().required('This field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            menuDescr: '',
            iconName: '',
            iconPath: '',
            menuCode: '',
            menuPath: '',
            menuType: '',
            moduleId: ''
        },

        validate: (data) => {
            let errors = {};

            // ////////////////////////iconName/////////////////////
            // if (!data?.iconName) {
            //   errors.iconName = "This field is required";
            // } else if (data?.iconName === "") {
            //   errors.iconName = "This Field should not be empty";
            // } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.iconName)) {
            //   errors.iconName = "Leading and trailing space is not allowed";
            // } else if (!/^[a-zA-Z0-9\s]*$/.test(data?.iconName)) {
            //     errors.iconName = "Special characters are not allowed";
            // }

            // //////////////////////////iconPath////////////////////////
            // if (!data?.iconPath) {
            //     errors.iconPath = "This field is required";
            // } else if (data?.iconPath === "") {
            //     errors.iconPath = "This Field should not be empty";
            // } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.iconPath)) {
            //     errors.iconPath = "Leading and trailing space is not allowed";
            // }

            //////////////////////////menuCode////////////////////////
            if (!data?.menuCode) {
                errors.menuCode = 'This field is required';
            } else if (data?.menuCode === '') {
                errors.menuCode = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.menuCode)) {
                errors.menuCode = 'Leading and trailing space is not allowed';
            } else if (!/^[a-zA-Z0-9\s]*$/.test(data?.menuCode)) {
                errors.menuCode = 'Special characters are not allowed';
            }

            //////////////////////////menuPath////////////////////////
            if (!data?.menuPath) {
                errors.menuPath = 'This field is required';
            } else if (data?.menuPath === '') {
                errors.menuPath = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.menuPath)) {
                errors.menuPath = 'Leading and trailing space is not allowed';
            } else if (!data?.menuPath?.startsWith('/')) {
                errors.menuPath = 'Menu Path should start with /';
            }

            //////////////////////////menuDescr////////////////////////
            // if (!data?.menuDescr) {
            //     errors.menuDescr = "This field is required";
            // } else if (data?.menuDescr === "") {
            //     errors.menuDescr = "This Field should not be empty";
            // } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.menuDescr)) {
            //     errors.menuDescr = "Leading and trailing space is not allowed";
            // } else if (!/^[a-zA-Z0-9\s]*$/.test(data?.menuDescr)) {
            //     errors.menuDescr = "Special characters are not allowed";
            // }

            if (!data?.menuDescr) {
                errors.menuDescr = 'This field is required';
            } else if (data?.menuDescr.length < 3) {
                errors.menuDescr = 'Minimum length should be 3 characters';
            } else if (data?.menuDescr === '') {
                errors.menuDescr = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.menuDescr)) {
                errors.menuDescr = 'Leading and trailing space is not allowed';
            } else if (!/^[a-zA-Z0-9\s]*$/.test(data?.menuDescr)) {
                errors.menuDescr = 'Special characters are not allowed';
            }

            return errors;
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['menuType'] = data?.menuType;

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            if (editable === true) {
                data['moduleId'] = parseInt(data?.moduleId);
                data['menuId'] = parseInt(menuId);

                // delete data["menuId"]
                delete data['createdate'];
                delete data['createuser'];
                delete data['isActive'];
                delete data['isParentMenu'];
                delete data['lastupdatedate'];
                delete data['lastupdateuser'];
                delete data['menuDescription'];
                delete data['parentMenuId'];
                delete data['updateindex'];
                delete data['deleteAllowed'];
                delete data['inserAllowed'];
                delete data['updateAllowed'];
                delete data['viewAllowed'];

                delete data['tblModule'];
                const res = await dispatch(handlePostRequest(newData, '/mfs_user_management/v1/management/updateMenu', true, true));
                if (res?.responseCode === '030000') {
                    await getMenu();
                    onHide();
                    formik.resetForm();
                }
            } else {
                data['moduleId'] = parseInt(data?.moduleId);
                const res = await dispatch(handlePostRequest(newData, '/mfs_user_management/v1/management/saveMenu', true, true));
                if (res?.responseCode === '030000') {
                    await getMenu();
                    onHide();
                    formik.resetForm();
                }
            }

            setloading(false);
            setloadingIcon('pi pi-save');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        {/* <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="iconName" className={classNames({ 'p-error': isFormFieldValid('iconName') }, 'Label__Text')}>
                                    Icon Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="iconName"
                                    placeholder="Enter Icon Name"
                                    name="iconName"
                                    value={formik.values.iconName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('iconName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('iconName')}
                            </div>
                        </div> */}
                        {/* <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="iconPath" className={classNames({ 'p-error': isFormFieldValid('iconPath') }, 'Label__Text')}>
                                    Icon Path<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="iconPath"
                                    placeholder="Enter Icon Path"
                                    name="iconPath"
                                    value={formik.values.iconPath || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('iconPath') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('iconPath')}
                            </div>
                        </div> */}
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="menuCode" className={classNames({ 'p-error': isFormFieldValid('menuCode') }, 'Label__Text')}>
                                    Menu Code<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="menuCode"
                                    placeholder="Enter Menu Code"
                                    name="menuCode"
                                    maxLength={20}
                                    value={formik.values.menuCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('menuCode') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('menuCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="menuPath" className={classNames({ 'p-error': isFormFieldValid('menuPath') }, 'Label__Text')}>
                                    Menu Path<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="menuPath"
                                    placeholder="Enter Menu Path"
                                    name="menuPath"
                                    maxLength={50}
                                    value={formik.values.menuPath || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('menuPath') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('menuPath')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="menuType" className={classNames({ 'p-error': isFormFieldValid('menuType') }, 'Label__Text')}>
                                    Menu Type<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="menuType"
                                    placeholder="Enter Menu Type"
                                    options={menuTypeOptions}
                                    optionLabel="label"
                                    name="menuType"
                                    value={formik.values.menuType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('menuType') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('menuType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="moduleId" className={classNames({ 'p-error': isFormFieldValid('moduleId') }, 'Label__Text')}>
                                    Module <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="moduleId"
                                    placeholder="Select Module Id"
                                    options={moduleLovData}
                                    optionLabel="name"
                                    name="moduleId"
                                    optionValue="lovId"
                                    value={formik.values.moduleId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('moduleId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('moduleId')}
                            </div>
                        </div>
                    </div>
                    <div className="p-field">
                        <label htmlFor="menuDescr" className={classNames({ 'p-error': isFormFieldValid('menuDescr') }, 'Label__Text')}>
                            Description<span className="Label__Required">*</span>
                        </label>
                        <InputTextarea
                            placeholder="Enter Description"
                            id="menuDescr"
                            name="menuDescr"
                            value={formik?.values?.menuDescr || ''}
                            rows={5}
                            cols={10}
                            maxLength={100}
                            autoResize="false"
                            // onChange={formik.handleChange}
                            onChange={(e) => {
                                const value = e.target.value;
                                const menuDescr = value.replace(/\s+/g, ' ');
                                formik.handleChange({ target: { name: e.target.name, value: menuDescr } });
                            }}
                            className={classNames({ 'p-invalid': isFormFieldValid('menuDescr') }, 'TextArea__Round')}
                        />
                        {getFormErrorMessage('menuDescr')}
                    </div>

                    <div className="Down__Btn__End">
                        <Button disabled={loading} label={editable ? 'Update' : 'Add'} className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
};

export default AddEditMenu;

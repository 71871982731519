import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handleGetRequest } from '../../../services/GetTemplate';
import EditViewRetailer from './Components/EditViewRetailer';
import EditViewHandler from './Components/EditViewHandler';
import EditViewCorporate from './Components/EditViewCorporate';
import EditViewFranchise from './Components/EditViewFranchise';
import { handlePostRequest } from '../../../services/PostTemplate';

function AgentAccountMaintenanceView() {
    const location = useLocation();
    const dispatch = useDispatch();
    const stateData = location.state || {};
    const id = stateData.rowData.customerId;
    const [apiData, setApiData] = useState([]);
    const [apiDataDoc, setApiDataDoc] = useState([]);
    const [accountClassification, setAccountClassification] = useState([]);

    const formik = useFormik({
        initialValues: {
            accountclassification: ''
        }
    });

    const getDocData = async (agentId) => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    agentId: agentId
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newData, '/documents/v1/documents/getagentdocuments', true, true));
        if (res?.payLoad) {
            setApiDataDoc(res?.payLoad);
        }
    };

    const getAccountClassification = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getclientroles/LKP_ACCOUNT_CLASSIFICATION:*');
        setAccountClassification(res?.payLoad);
    };

    const getDatabyId = async () => {
        const res = await handleGetRequest(`/account/v1/agentaccount/getcxagentbyid/${id}`);
        setApiData(res?.payLoad);
        if (res?.payLoad && (res?.payLoad?.lkpAccountClassification?.accountClassificationCode === 'FRN' || res?.payLoad?.lkpAccountClassification?.accountClassificationCode === 'RTL')) {
            getDocData(res?.payLoad?.agentId);
        }
    };

    useEffect(() => {
        getDatabyId();
    }, [id]);

    useEffect(() => {
        formik.setFieldValue('accountclassification', apiData?.lkpAccountClassification?.accountClassificationId.toString());
    }, [apiData]);

    useEffect(() => {
        getAccountClassification();
    }, []);
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="m-3 Form__Header Full__Width">
                            <h2>VIEW DETAILS</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountclassification" className="Label__Text">
                                    Account Classification
                                </label>
                                <Dropdown
                                    id="accountclassification"
                                    placeholder="Select Account Classification"
                                    options={accountClassification}
                                    optionLabel="name"
                                    name="accountclassification"
                                    optionValue="lovId"
                                    value={formik.values.accountclassification || ''}
                                    disabled
                                    // onChange={handleChange}
                                    className="Dropdown__Round"
                                />
                            </div>
                        </div>
                    </div>
                </form>
                {apiData && apiData?.lkpAccountClassification?.accountClassificationCode === 'FRN' ? (
                    <EditViewFranchise isEditable={false} apiData={apiData} docData={apiDataDoc} />
                ) : apiData && apiData?.lkpAccountClassification?.accountClassificationCode === 'RTL' ? (
                    <EditViewRetailer isEditable={false} apiData={apiData} docData={apiDataDoc} />
                ) : apiData && apiData?.lkpAccountClassification?.accountClassificationCode === 'HND' ? (
                    <EditViewHandler isEditable={false} apiData={apiData} />
                ) : apiData && apiData?.lkpAccountClassification?.accountClassificationCode === 'CPT' ? (
                    <EditViewCorporate isEditable={false} apiData={apiData} />
                ) : (
                    <></>
                )}
            </div>
        </>
    );
}

export default AgentAccountMaintenanceView;

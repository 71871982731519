import React, { useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getLkpAccountStatus } from '../../../../redux/slices/LkpAccountStatusSlice';
import { getLkpAccountLevel } from '../../../../redux/slices/LkpAccountLevelSlice';
import { getLkpCity } from '../../../../redux/slices/LkpCitySlice';

const AccountDetailsView = ({ userTabsFieldData, type }) => {

    const dispatch = useDispatch();
    const lkpAccountStatusLov = useSelector((state) => state?.LkpAccountStatusSlice?.data);
    const lkpAccountLevelLov = useSelector((state) => state?.LkpAccountLevelSlice?.data);
    const lkpCityLov = useSelector((state) => state?.LkpCitySlice?.data);

    const location = useLocation();
    const { rowData } = location.state || {};

    const navigate = useNavigate();

    const redirectToeditAccountDetails = () => {
        navigate('/editaccountdetails', {
            state: { rowData, lkpAccountLevelLov, lkpAccountStatusLov, lkpCityLov, userTabsFieldData, type: type }
        });
    };

    const checkFieldPermissions = (fieldName) => {
        const field = userTabsFieldData.find((f) => f.fieldName === fieldName);
        return {
            viewAllowed: field?.viewAllowed === 'Y',
            updateAllowed: field?.updateAllowed === 'Y'
        };
    };

    // Go back to the previous page
    const goBack = () => {
        navigate('/accountsmanagement', { state: { prevPath: '/viewaccountdetails' } });
    };

    const nameFieldPermissions = checkFieldPermissions('fullName');
    const customerIdFieldPermissions = checkFieldPermissions('customerId');
    const mobileFieldPermissions = checkFieldPermissions('mobileNo');
    const ibanFieldPermissions = checkFieldPermissions('iban');
    const accTypeFieldPermissions = checkFieldPermissions('accountTypeId');
    const nadraVeriFieldPermissions = checkFieldPermissions('nadraVerified');
    const acStatusFieldPermissions = checkFieldPermissions('accountStatusId');
    const remarksBlckByFieldPermissions = checkFieldPermissions('remarks');
    const addressFieldPermissions = checkFieldPermissions('fullAddress');
    const emailAddressFieldPermissions = checkFieldPermissions('email');
    const cityFieldPermissions = checkFieldPermissions('cityName');
    const dobFieldPermissions = checkFieldPermissions('dob');

    useEffect(() => {
        if (lkpAccountStatusLov === undefined || lkpAccountStatusLov.length === 0) dispatch(getLkpAccountStatus());
        if (lkpAccountLevelLov === undefined || lkpAccountLevelLov.length === 0) dispatch(getLkpAccountLevel());
        if (lkpCityLov === undefined || lkpCityLov.length === 0) dispatch(getLkpCity());
    }, []);

    return (
        <>
            <div className="pt-2">
                <h6 className="secondary-color">
                    <b>ACCOUNT DETAILS</b>
                </h6>
            </div>

            <Divider />

            <form className="p-fluid p-mt-2">
                <div className="p-fluid p-formgrid grid mb-5">
                    {nameFieldPermissions.viewAllowed && (
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Name</label>
                                <InputText placeholder={rowData?.fullName} className="Input__Round" disabled />
                            </div>
                        </div>
                    )}
                    {customerIdFieldPermissions.viewAllowed && (
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Customer ID</label>
                                <InputText placeholder={rowData?.customerId} className="Input__Round" disabled />
                            </div>
                        </div>
                    )}
                    {mobileFieldPermissions.viewAllowed && (
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Mobile No.</label>
                                <InputText placeholder={rowData?.mobileNo} className="Input__Round" disabled />
                            </div>
                        </div>
                    )}
                    {ibanFieldPermissions.viewAllowed && (
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">IBAN</label>
                                <InputText placeholder={rowData?.iban} className="Input__Round" disabled />
                            </div>
                        </div>
                    )}
                    {accTypeFieldPermissions.viewAllowed && (
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Account Type</label>
                                <Dropdown
                                    id="accountTypeId"
                                    name="accountTypeId"
                                    placeholder="--Select--"
                                    options={lkpAccountLevelLov}
                                    optionLabel="name"
                                    optionValue="lovId"
                                    value={rowData?.accountLevelId?.toString()}
                                    //   onChange={(e) => setAccountTypeId(e.target.value)}
                                    className="Dropdown__Round mr-2"
                                    disabled
                                />
                            </div>
                        </div>
                    )}
                    {nadraVeriFieldPermissions.viewAllowed && (
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Nadra Verification</label>
                                <Dropdown id="nadraVerified" name="nadraVerified" placeholder={rowData?.nadraVerified === 'Y' ? 'Yes' : rowData?.nadraVerified === 'N' ? 'No' : ''} className="Dropdown__Round custom-dropdown" disabled />
                            </div>
                        </div>
                    )}
                    {acStatusFieldPermissions.viewAllowed && (
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">A/C Status</label>
                                <Dropdown
                                    id="accountStatusId"
                                    name="accountStatusId"
                                    placeholder="--Select--"
                                    options={lkpAccountStatusLov}
                                    optionLabel="accountStatusName"
                                    optionValue="accountStatusId"
                                    value={rowData?.accountStatusId?.toString()}
                                    //  onChange={(e) => setAccountTypeId(e.target.value)}
                                    className="Dropdown__Round mr-2 custom-dropdown"
                                    disabled
                                />
                            </div>
                        </div>
                    )}
                    {remarksBlckByFieldPermissions.viewAllowed && (
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Remarks</label>
                                <InputText placeholder={rowData?.remarks} className="Input__Round" disabled />
                            </div>
                        </div>
                    )}
                    {addressFieldPermissions.viewAllowed && (
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Address</label>
                                <InputText placeholder={rowData?.fullAddress} className="Input__Round" disabled />
                            </div>
                        </div>
                    )}
                    {emailAddressFieldPermissions.viewAllowed && (
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Email Address</label>
                                <InputText placeholder={rowData?.email} className="Input__Round" disabled />
                            </div>
                        </div>
                    )}
                    {cityFieldPermissions.viewAllowed && (
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">City</label>
                                <Dropdown placeholder={rowData?.cityName} className="Dropdown__Round custom-dropdown" disabled />
                            </div>
                        </div>
                    )}
                    {dobFieldPermissions.viewAllowed && (
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">DOB</label>
                                <InputText placeholder={rowData?.dob} className="Input__Round" disabled />
                            </div>
                        </div>
                    )}
                </div>
            </form>

            <div className="text-center mt-2 mb-2">
                <Button label="View more" className="view-button p-button-rounded mr-2" onClick={redirectToeditAccountDetails} />
                <Button label="Back" iconPos="right" className="view-button p-button-rounded Btn__cancel" onClick={goBack} />
            </div>
        </>
    );
};

export default AccountDetailsView;

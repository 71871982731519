import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handleGetRequest } from '../../../../../services/GetTemplate';

function ViewRunningFinance({ lendingData, stepData1 }) {
    const [frequencyPeriodData, setFrequencyPeriodData] = useState([]);

    const [noOfCyclesTableData, setNoOfCyclesTableData] = useState([]);
    const [noOfCyclesData, setNoOfCyclesData] = useState({
        noOfCycle: '',
        type: '',
        price: ''
    });

    const [noOfCyclesError, setNoOfCyclesError] = useState({
        noOfCycle: '',
        type: '',
        price: ''
    });

    /////////////////////////////////////////
    // const [runningFinanceTableData, setRunningFinanceTableData] = useState([]);
    const [runningFinanceData, setRunningFinanceData] = useState({
        markupValue: '',
        apr: '',

        gracePeriod: '',
        noOfDaysGracePeriod: '',

        latePayment: '',
        latePaymentType: '',
        latePaymentCharges: '',
        latePaymentOn: '',

        frequencyId: ''

        // earlyPayment: '',
        // earlyPaymentType: '',
        // earlyPaymentCharges: '',
        // earlyPaymentOn: '',

        // downPayment: '',
        // downPaymentCharges: '',
    });
    const [runningFinanceError, setRunningFinanceError] = useState({
        markupValue: '',
        apr: '',

        gracePeriod: '',
        noOfDaysGracePeriod: '',

        latePayment: '',
        latePaymentType: '',
        latePaymentCharges: '',
        latePaymentOn: '',

        frequencyId: ''

        // earlyPayment: '',
        // earlyPaymentType: '',
        // earlyPaymentCharges: '',
        // earlyPaymentOn: '',

        // downPayment: '',
        // downPaymentCharges: '',
    });
    //////////////////////////////

    const [buttons, setButtons] = useState([
        { label: 'Principal Amount', sequence: 1 },
        { label: 'Markup', sequence: 2 },
        { label: 'Fees', sequence: 3 }
    ]);

    const getFrequencyPeriodData = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getdatalov/LKP_FREQUENCY_PERIOD');
        setFrequencyPeriodData(response?.payLoad);
    };

    useEffect(() => {
        getFrequencyPeriodData();
    }, []); // eslint-disable-line

    useEffect(() => {
        let runningFinance = [];
        if (lendingData) {
            if (lendingData?.tblLmsRepaymentMethods?.length > 0) {
                runningFinance = lendingData?.tblLmsRepaymentMethods
                    ?.filter((itm) => itm?.isActive === 'Y')
                    ?.map((item) => {
                        return {
                            markupValue: item?.markupValue,
                            apr: item?.apr,

                            gracePeriod: item?.gracePeriod === 'Y' ? true : false,
                            noOfDaysGracePeriod: item?.noOfGraceDays,

                            latePayment: item?.latePayment === 'Y' ? true : false,
                            latePaymentType: item?.latePaymentType?.toString(),
                            latePaymentCharges: item?.latePaymentCharges,
                            latePaymentOn: item?.latePaymentOn?.toString(),

                            // earlyPayment: item?.earlyPayment === "Y" ? true : false,
                            // earlyPaymentType: item?.earlyPaymentType?.toString(),
                            // earlyPaymentCharges: item?.earlyPaymentCharges,
                            // earlyPaymentOn: item?.earlyPaymentOn?.toString(),

                            frequencyId: item?.lkpFrequencyPeriod?.frequencyPeriodId?.toString(),

                            tblLmsRepaymentCycles: item?.tblLmsRepaymentCycles

                            // downPayment: item?.downPayment === "Y" ? "Y" : "N",
                            // downPaymentCharges: item?.downPaymentCharges,
                        };
                    });
                setRunningFinanceData(runningFinance[0]);
                const noOfCycle = runningFinance[0]?.tblLmsRepaymentCycles?.map((item) => {
                    return {
                        noOfCycle: item?.noOfCycles,
                        type: item?.priceType,
                        price: item?.priceValue
                    };
                });
                setNoOfCyclesTableData(noOfCycle);
            }

            let pA = [];
            if (lendingData?.tblLmsRepaymentMethods?.length > 0) {
                Object.keys(lendingData?.tblLmsRepaymentMethods[0]).forEach((key) => {
                    if (key === 'seqPrincipalAmount') {
                        pA.push({ label: key, value: lendingData?.tblLmsRepaymentMethods[0][key] });
                    }

                    if (key === 'seqMarkup') {
                        pA.push({ label: key, value: lendingData?.tblLmsRepaymentMethods[0][key] });
                    }

                    if (key === 'seqLateFee') {
                        pA.push({ label: key, value: lendingData?.tblLmsRepaymentMethods[0][key] });
                    }
                });

                const updatedDefinePayment = pA?.map((item) => {
                    return {
                        label: item.label.replace('seq', ''),
                        sequence: item.value
                    };
                });

                updatedDefinePayment?.sort((a, b) => a.sequence - b.sequence);
                setButtons(updatedDefinePayment);
            }
        }
    }, [lendingData]); // eslint-disable-line

    const validationSchema = Yup.object().shape({});

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {}
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    // const downPaymentLov = [
    //     {
    //         name: 'Yes',
    //         lovId: 'Y'
    //     },
    //     {
    //         name: 'No',
    //         lovId: 'N'
    //     }
    // ];

    // const frequencyBodyTemplate = (rowData) => {
    //     const singleFrequency = frequencyPeriodData?.filter((item) => item?.lovId == rowData?.frequencyId);
    //     return <p>{singleFrequency[0]?.name}</p>;
    // };

    //////////////////////////new UI code /////////////////////
    // const loanTenureNOD = [
    //     {name: "90 Days", lovId: "90"},
    //     {name: "180 Days", lovId: "180"},
    //     {name: "270 Days", lovId: "270"},
    //     {name: "360 Days", lovId: "360"},
    // ];

    const typeLov = [
        { name: 'FIXED', lovId: 'F' },
        { name: 'PERCENTAGE', lovId: 'P' }
    ];

    const actionBodyTypeTemplate = (rowData) => {
        return <p>{rowData?.type === 'F' ? 'Fixed' : 'Percentage'}</p>;
    };

    const paymentOnLov = [
        { name: 'Outstanding Principal', lovId: 'O' },
        { name: 'Markup', lovId: 'M' }
    ];

    const actionBodyForNoOfCyclesTemplate = (rowData, rowIndex) => {
        return (
            <>
                {/* <Button tooltip="Edit" tooltipOptions={{ position: 'top' }} icon="pi pi-pencil" onClick={(e) => handledEditData(e, rowData, rowIndex)} className="p-button-rounded p-button-primary" style={{ border: 'none' }} /> */}
                <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteNoOfCyclesData(e, rowData, rowIndex)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
            </>
        );
    };

    const handledDeleteNoOfCyclesData = (e, rowData, rowIndex) => {
        e.preventDefault();
        const newFilterData = noOfCyclesTableData?.filter((value, index) => index !== rowIndex?.rowIndex);
        setNoOfCyclesTableData(newFilterData);
    };

    // const handledDeleteData = (e, rowData, rowIndex) => {
    //     e.preventDefault();
    //     const newFilterData = runningFinanceTableData?.filter((value, index) => index !== rowIndex?.rowIndex);
    //     setRunningFinanceTableData(newFilterData);
    // };

    // const handledEditData = (e, rowData, rowIndex) => {
    //     e.preventDefault();
    //     const newFilterData = runningFinanceTableData?.filter((value, index) => index !== rowIndex?.rowIndex);
    //     setRunningFinanceData(newFilterData);
    // };

    // const handledAdd = (e) => {
    //     const errors = {
    //         markupValue: '',
    //         apr: '',

    //         gracePeriod: '',
    //         noOfDaysGracePeriod: '',

    //         latePayment: '',
    //         latePaymentType: '',
    //         latePaymentCharges: '',
    //         latePaymentOn: '',

    //         frequencyId: '',

    //         // earlyPayment: '',
    //         // earlyPaymentType: '',
    //         // earlyPaymentCharges: '',
    //         // earlyPaymentOn: '',

    //         // downPayment: '',
    //         // downPaymentCharges: '',

    //         seqPrincipalAmount: '',
    //         seqMarkup: '',
    //         seqLateFee: ''
    //     };

    //     if (runningFinanceData?.markupValue === '') {
    //         errors.markupValue = 'This field is required.';
    //     }

    //     if (runningFinanceData?.apr === '') {
    //         errors.apr = 'This field is required.';
    //     }

    //     if (runningFinanceData?.gracePeriod === true) {
    //         if (runningFinanceData?.noOfDaysGracePeriod === '') {
    //             errors.noOfDaysGracePeriod = 'This field is required';
    //         }
    //     }

    //     if (runningFinanceData?.latePayment === true) {
    //         if (runningFinanceData?.latePaymentType === '') {
    //             errors.latePaymentType = 'This field is required';
    //         }

    //         if (runningFinanceData?.latePaymentCharges === '') {
    //             errors.latePaymentCharges = 'This field is required';
    //         }

    //         if (runningFinanceData?.latePaymentOn === '') {
    //             errors.latePaymentOn = 'This field is required';
    //         }
    //     }

    //     if (runningFinanceData?.frequencyId === '') {
    //         errors.frequencyId = 'This field is required';
    //     }

    //     // if (runningFinanceData?.earlyPayment === true) {
    //     //     if (runningFinanceData?.earlyPaymentType === '') {
    //     //         errors.earlyPaymentType = 'This field is required';
    //     //     }

    //     //     if (runningFinanceData?.earlyPaymentCharges === '') {
    //     //         errors.earlyPaymentCharges = 'This field is required';
    //     //     }

    //     //     if (runningFinanceData?.earlyPaymentOn === '') {
    //     //         errors.earlyPaymentOn = 'This field is required';
    //     //     }
    //     // }

    //     // if(runningFinanceData?.downPayment === 'Y') {
    //     //     if (runningFinanceData?.downPaymentCharges === '') {
    //     //         errors.downPaymentCharges = 'This field is required';
    //     //     }
    //     // }

    //     setRunningFinanceError(errors);

    //     const sequenceObject = buttons?.reduce((result, item) => {
    //         const label = item.label.replace(/\s+/g, ''); // Remove spaces from label
    //         const seqKey = `seq${label}`;
    //         result[seqKey] = item.sequence;
    //         return result;
    //     }, {});

    //     // If there are no errors, you can submit the form or perform further actions.
    //     if (Object.values(errors).every((error) => error === '')) {
    //         const newRunningFinanceTableData = {
    //             markupValue: runningFinanceData?.markupValue,
    //             apr: runningFinanceData?.apr,

    //             gracePeriod: runningFinanceData?.gracePeriod,
    //             noOfDaysGracePeriod: runningFinanceData?.noOfDaysGracePeriod,

    //             latePayment: runningFinanceData?.latePayment,
    //             latePaymentType: runningFinanceData?.latePaymentType,
    //             latePaymentCharges: runningFinanceData?.latePaymentCharges,
    //             latePaymentOn: runningFinanceData?.latePaymentOn,

    //             frequencyId: runningFinanceData?.frequencyId,

    //             // earlyPayment: runningFinanceData?.earlyPayment,
    //             // earlyPaymentType: runningFinanceData?.earlyPaymentType,
    //             // earlyPaymentCharges: runningFinanceData?.earlyPaymentCharges,
    //             // earlyPaymentOn: runningFinanceData?.earlyPaymentOn,

    //             // downPayment: runningFinanceData?.downPayment,
    //             // downPaymentCharges: runningFinanceData?.downPaymentCharges,

    //             repaymentTypeCode: stepData1?.facilityCode,

    //             lmsProductRepaymentCycles: noOfCyclesTableData
    //         };

    //         const combinedObject = { ...newRunningFinanceTableData, ...sequenceObject };
    //         setRunningFinanceTableData([...runningFinanceTableData, combinedObject]);

    //         // setRunningFinanceData({
    //         //     markupValue: '',
    //         //     apr: '',

    //         //     gracePeriod: '',
    //         //     noOfDaysGracePeriod: '',

    //         //     latePayment: '',
    //         //     latePaymentType: '',
    //         //     latePaymentCharges: '',
    //         //     latePaymentOn: '',

    //         //     frequencyId: '',

    //         //     // earlyPayment: '',
    //         //     // earlyPaymentType: '',
    //         //     // earlyPaymentCharges: '',
    //         //     // earlyPaymentOn: '',

    //         //     // downPayment: '',
    //         //     // downPaymentCharges: '',
    //         // });

    //         setButtons([
    //             { label: 'Principal Amount', sequence: 1 },
    //             { label: 'Markup', sequence: 2 },
    //             { label: 'Fees', sequence: 3 }
    //         ]);
    //     }
    // };

    const handledData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        const checked = e.target.checked;
        const type = e.target.type;

        setRunningFinanceData({ ...runningFinanceData, [name]: type === 'checkbox' ? checked : value });

        // Clear the error message for the field being edited
        setRunningFinanceError({
            ...runningFinanceError,
            [name]: ''
        });
    };
    //////////////////////////////////////////////////////////

    const handledNoOfCyclesAdd = (e) => {
        e.preventDefault();

        const errors = {
            noOfCycle: '',
            type: '',
            price: ''
        };

        if (noOfCyclesData?.noOfCycle === '') {
            errors.noOfCycle = 'This field is required.';
        }

        if (noOfCyclesData?.price === '') {
            errors.price = 'This field is required.';
        }

        if (noOfCyclesData?.price === '') {
            errors.price = 'This field is required.';
        }

        setNoOfCyclesError(errors);

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            const newNoOfCyclesTableData = {
                noOfCycle: noOfCyclesData?.noOfCycle,
                type: noOfCyclesData?.type,
                price: noOfCyclesData?.price
                // repaymentTypeCode: lendingData?.lkpLmsFacilityType?.lmsFacilityTypeCode,
            };

            setNoOfCyclesTableData([...noOfCyclesTableData, newNoOfCyclesTableData]);
            setNoOfCyclesData({
                noOfCycle: '',
                type: '',
                price: ''
            });
        }
    };

    const handledNoOfCyclesData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        const checked = e.target.checked;
        const type = e.target.type;

        setNoOfCyclesData({ ...noOfCyclesData, [name]: type === 'checkbox' ? checked : value });

        // Clear the error message for the field being edited
        setNoOfCyclesError({
            ...noOfCyclesError,
            [name]: ''
        });
    };

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>REPAYMENT METHOD/EDIT</h1>
            </div>
            <div className="card Card__Round">
                <div className="Form__Header flex align-items-center justify-content-between">
                    <h1>MARKUP CALCULATION </h1>
                    {/* <Button
                        icon="pi pi-plus"
                        className="p-button-rounded"
                        aria-label="Filter"
                        tooltip="Add New Repayment"
                        onClick={(e) => handledAdd(e)}
                    /> */}
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="markupValue" className={classNames({ 'p-error': isFormFieldValid('markupValue') }, 'Label__Text')}>
                                    Markup value <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={4}
                                    id="markupValue"
                                    placeholder="Enter Markup Value"
                                    name="markupValue"
                                    disabled={true}
                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                    value={runningFinanceData?.markupValue || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('markupValue') }, 'Input__Round')}
                                />
                                <small className="p-error">{runningFinanceError?.markupValue}</small>
                                {getFormErrorMessage('markupValue')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="apr" className={classNames({ 'p-error': isFormFieldValid('apr') }, 'Label__Text')}>
                                    APR <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="apr"
                                    placeholder="Enter APR"
                                    name="apr"
                                    disabled={true}
                                    maxLength={4}
                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                    value={runningFinanceData?.apr || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('apr') }, 'Input__Round')}
                                />
                                <small className="p-error">{runningFinanceError?.apr}</small>
                            </div>
                        </div>
                    </div>

                    <div style={{ backgroundColor: '#edf0f5', padding: '2rem' }} className=" col-12 card">
                        <div className="grid">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="noOfCycle" className={classNames({ 'p-error': isFormFieldValid('noOfCycle') }, 'Label__Text')}>
                                        No Of Cycles<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={4}
                                        id="noOfCycle"
                                        placeholder="Enter No. Of Cycles"
                                        name="noOfCycle"
                                        disabled={true}
                                        keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                        value={noOfCyclesData?.noOfCycle || ''}
                                        onChange={handledNoOfCyclesData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('noOfCycle') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{noOfCyclesError?.noOfCycle}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="type" className={classNames({ 'p-error': isFormFieldValid('type') }, 'Label__Text')}>
                                        Type<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="type"
                                        placeholder="Select Type"
                                        options={typeLov}
                                        optionLabel="name"
                                        name="type"
                                        disabled={true}
                                        filter
                                        optionValue="lovId"
                                        value={noOfCyclesData?.type || ''}
                                        onChange={handledNoOfCyclesData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('type') }, 'Dropdown__Round')}
                                    />
                                    <small className="p-error">{noOfCyclesError?.type}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="price" className={classNames({ 'p-error': isFormFieldValid('price') }, 'Label__Text')}>
                                        Price <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="price"
                                        placeholder="Enter Price"
                                        name="price"
                                        maxLength={8}
                                        disabled={true}
                                        keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                        value={noOfCyclesData?.price || ''}
                                        onChange={handledNoOfCyclesData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('price') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{noOfCyclesError?.price}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <div className="Down__Btn" style={{ justifyContent: 'flex-start' }}>
                                        <Button label="Add" className="Btn__Dark" style={{ marginTop: '15px' }} onClick={handledNoOfCyclesAdd} disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12">
                                <div className="card mt-6">
                                    <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={noOfCyclesTableData}>
                                        <Column field="noOfCycle" header="No. Of Cycles" />
                                        <Column field="type" header="Type" body={actionBodyTypeTemplate} />
                                        <Column field="price" header="Price" />
                                        <Column header="Actions" body={actionBodyForNoOfCyclesTemplate} style={{ textAlign: 'center' }} className="Table__ActionHeading__CentredAlign" />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Form__Header col-12">
                        <h1>GRACE PERIOD </h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="gracePeriod" name="gracePeriod" value={runningFinanceData?.gracePeriod} checked={runningFinanceData?.gracePeriod} onChange={handledData} style={{ marginTop: '10px' }} disabled />
                                    <label htmlFor="gracePeriod" className={classNames({ 'p-error': isFormFieldValid('gracePeriod') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Grace Period
                                    </label>
                                </div>
                                <small className="p-error">{runningFinanceError?.gracePeriod}</small>
                            </div>
                        </div>
                        {runningFinanceData?.gracePeriod === true && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="noOfDaysGracePeriod" className={classNames({ 'p-error': isFormFieldValid('noOfDaysGracePeriod') }, 'Label__Text')}>
                                        Number of Days <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={4}
                                        id="noOfDaysGracePeriod"
                                        placeholder="Enter Number of Days"
                                        name="noOfDaysGracePeriod"
                                        keyfilter={'int'}
                                        disabled={true}
                                        value={runningFinanceData?.noOfDaysGracePeriod || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('noOfDaysGracePeriod') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{runningFinanceError?.noOfDaysGracePeriod}</small>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="Form__Header col-12">
                        <h1>LATE PAYMENT </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="latePayment" name="latePayment" value={runningFinanceData?.latePayment} checked={runningFinanceData?.latePayment} onChange={handledData} disabled style={{ marginTop: '10px' }} />
                                    <label htmlFor="latePayment" className={classNames({ 'p-error': isFormFieldValid('latePayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Late payment
                                    </label>
                                </div>
                                <small className="p-error">{runningFinanceError?.latePayment}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        {runningFinanceData?.latePayment === true && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentType" className={classNames({ 'p-error': isFormFieldValid('latePaymentType') }, 'Label__Text')}>
                                            Type<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="latePaymentType"
                                            placeholder="Select Type"
                                            options={typeLov}
                                            optionLabel="name"
                                            name="latePaymentType"
                                            filter
                                            disabled={true}
                                            optionValue="lovId"
                                            value={runningFinanceData?.latePaymentType || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('latePaymentType') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{runningFinanceError?.latePaymentType}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentCharges" className={classNames({ 'p-error': isFormFieldValid('latePaymentCharges') }, 'Label__Text')}>
                                            Charges <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="latePaymentCharges"
                                            placeholder="Enter charges"
                                            name="latePaymentCharges"
                                            maxLength={8}
                                            disabled={true}
                                            keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                            value={runningFinanceData?.latePaymentCharges || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('latePaymentCharges') }, 'Input__Round')}
                                        />
                                        <small className="p-error">{runningFinanceError?.latePaymentCharges}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentOn" className={classNames({ 'p-error': isFormFieldValid('latePaymentOn') }, 'Label__Text')}>
                                            Late Payment On<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="latePaymentOn"
                                            placeholder="Select Late Payment On"
                                            options={paymentOnLov}
                                            optionLabel="name"
                                            name="latePaymentOn"
                                            filter
                                            disabled={true}
                                            optionValue="lovId"
                                            value={runningFinanceData?.latePaymentOn || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('latePaymentOn') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{runningFinanceError?.latePaymentOn}</small>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {/* //////////////////////////////////////////////////// */}
                    <div className="Form__Header col-12">
                        <h1>REPAYMENT SCHEDULE </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="frequencyId" className={classNames({ 'p-error': isFormFieldValid('frequencyId') }, 'Label__Text')}>
                                    Frequency Of Payment<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="frequencyId"
                                    placeholder="Select Frequency Of Payment"
                                    options={frequencyPeriodData}
                                    optionLabel="name"
                                    name="frequencyId"
                                    disabled={true}
                                    filter
                                    optionValue="lovId"
                                    value={runningFinanceData?.frequencyId || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('frequencyId') }, 'Dropdown__Round')}
                                />
                                <small className="p-error">{runningFinanceError?.frequencyId}</small>
                            </div>
                        </div>
                    </div>
                    {/* ///////////////////////////////////////////////////// */}

                    {/* <div className="Form__Header col-12">
                        <h1>ALLOW EARLY REPAYMENT </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="earlyPayment" name="earlyPayment" value={runningFinanceData?.earlyPayment} checked={runningFinanceData?.earlyPayment}
                                        onChange={handledData}
                                        style={{ marginTop: '10px' }} />
                                    <label htmlFor="earlyPayment" className={classNames({ 'p-error': isFormFieldValid('earlyPayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Early Payment
                                    </label>
                                </div>
                                <small className="p-error">{runningFinanceError?.earlyPayment}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        {runningFinanceData?.earlyPayment === true && 
                        <>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="earlyPaymentType" className={classNames({ 'p-error': isFormFieldValid('earlyPaymentType') }, 'Label__Text')}>
                                        Type<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="earlyPaymentType"
                                        placeholder="Select Type"
                                        options={typeLov}
                                        optionLabel="name"
                                        name="earlyPaymentType"
                                        filter
                                        optionValue="lovId"
                                        value={runningFinanceData?.earlyPaymentType || ''}
                                        onChange={handledData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('earlyPaymentType') }, 'Dropdown__Round')}
                                    />
                                    <small className="p-error">{runningFinanceError?.earlyPaymentType}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="earlyPaymentCharges" className={classNames({ 'p-error': isFormFieldValid('earlyPaymentCharges') }, 'Label__Text')}>
                                        Charges <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="earlyPaymentCharges"
                                        placeholder="Enter charges"
                                        name="earlyPaymentCharges"
                                        maxLength={8}
                                        keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                        value={runningFinanceData?.earlyPaymentCharges || ''}
                                        onChange={handledData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('earlyPaymentCharges') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{runningFinanceError?.earlyPaymentCharges}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="earlyPaymentOn" className={classNames({ 'p-error': isFormFieldValid('earlyPaymentOn') }, 'Label__Text')}>
                                        Early Payment On<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="earlyPaymentOn"
                                        placeholder="Select Early Payment On"
                                        options={paymentOnLov}
                                        optionLabel="name"
                                        name="earlyPaymentOn"
                                        filter
                                        optionValue="lovId"
                                        value={runningFinanceData?.earlyPaymentOn || ''}
                                        onChange={handledData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('earlyPaymentOn') }, 'Dropdown__Round')}
                                    />
                                    <small className="p-error">{runningFinanceError?.earlyPaymentOn}</small>
                                </div>
                            </div>
                        </>}
                    </div>

                    <div className="Form__Header col-12">
                        <h1>DOWN PAYMENT </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="downPayment" className={classNames({ 'p-error': isFormFieldValid('downPayment') }, 'Label__Text')}>
                                    Select Down Payment<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="downPayment"
                                    placeholder="Select Down Payment"
                                    options={downPaymentLov}
                                    optionLabel="name"
                                    name="downPayment"
                                    filter
                                    optionValue="lovId"
                                    value={runningFinanceData?.downPayment || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('downPayment') }, 'Dropdown__Round')}
                                />
                                <small className="p-error">{runningFinanceError?.downPayment}</small>
                            </div>
                        </div>
                        {runningFinanceData?.downPayment === 'Y' &&
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="downPaymentCharges" className={classNames({ 'p-error': isFormFieldValid('downPaymentCharges') }, 'Label__Text')}>
                                        Charges <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="downPaymentCharges"
                                        placeholder="Enter Down Payment Charges"
                                        name="downPaymentCharges"
                                        maxLength={8}
                                        keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                        value={runningFinanceData?.downPaymentCharges || ''}
                                        onChange={handledData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('downPaymentCharges') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{runningFinanceError?.downPaymentCharges}</small>
                                </div>
                            </div>
                        }
                    </div> */}

                    <div className="Form__Header">
                        <h1>DEFINE REPAYMENT SEQUENCE </h1>
                    </div>
                    <div className="Centre_Aligned_Buttons" onDragOver={handleDragOver}>
                        {buttons?.map((button) => (
                            <Button
                                key={button.label}
                                className="DraggableButton Light_Button"
                                onClick={(e) => e.preventDefault()}
                                label={`${button.label}`}
                                disabled
                                // draggable
                                // onDragStart={(e) => handleDragStart(e, button.label)}
                                // onDrop={(e) => handleDrop(e, button.label)}
                            />
                        ))}
                    </div>
                    {/* <div className="grid">
                        <div className="col-12">
                            <div className="card mt-6">
                                <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={runningFinanceTableData}>
                                    <Column field="noOfDays" header="No. Of Days" />
                                    <Column field="markupValue" header="markup value" />
                                    <Column field="apr" header="APR" />
                                    <Column field="status" header="Status" />
                                    <Column header="Actions" body={actionBodyTemplate} style={{ textAlign: 'center' }} className="Table__ActionHeading__CentredAlign" />
                                </DataTable>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} style={{ margin: '20px' }} onClick={() => handledAdd()} label={'Next'} className="Btn__Dark" />
                    </div> */}
                </form>
            </div>
        </>
    );
}
export default ViewRunningFinance;

import React from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handlePostRequest } from '../../../../services/PostTemplate';

const AddCostCenter = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        lkpCostCenterName: Yup.string()
            .required('This field is required')
            .matches(/^(?=.*[a-zA-Z])([a-zA-Z0-9]+( [a-zA-Z0-9]+)*)\S(.*\S)?$/, 'Field must contain alphanumeric data'),

        lkpCostCenterCode: Yup.string().required('This field is required'),

        lkpCostCenterDescription: Yup.string().required('This field is required'),
        coaCode: Yup.string().required('This field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            lkpCostCenterName: '',
            lkpCostCenterCode: '',
            lkpCostCenterDescription: '',
            coaCode: ''
        },

        validate: (data) => {
            let errors = {};

            //////////////////////////lkpCostCenterName////////////////////////
            if (!data?.lkpCostCenterName) {
                errors.lkpCostCenterName = 'This field is required';
            } else if (data?.lkpCostCenterName === '') {
                errors.lkpCostCenterName = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.lkpCostCenterName)) {
                errors.lkpCostCenterName = 'Leading and trailing space is not allowed';
            } else if (!/^[a-zA-Z0-9\s]+$/.test(data?.lkpCostCenterName)) {
                errors.lkpCostCenterName = 'Only alphanumeric characters and spaces are allowed';
            }

            //////////////////////////lkpCostCenterCode////////////////////////
            if (!data?.lkpCostCenterCode) {
                errors.lkpCostCenterCode = 'This field is required';
            } else if (data?.lkpCostCenterCode === '') {
                errors.lkpCostCenterCode = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.lkpCostCenterCode)) {
                errors.lkpCostCenterCode = 'Leading and trailing space is not allowed';
            } else if (!/^[a-zA-Z0-9\s]+$/.test(data?.lkpCostCenterCode)) {
                errors.lkpCostCenterCode = 'Only alphanumeric characters are allowed';
            }

            //////////////////////////coaCode////////////////////////
            if (!data?.coaCode) {
                errors.coaCode = 'This field is required';
            } else if (data?.coaCode === '') {
                errors.coaCode = 'This Field should not be empty';
            } else if (!/^[0-9]+$/.test(data?.coaCode)) {
                errors.coaCode = 'Only numeric characters are allowed';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.coaCode)) {
                errors.coaCode = 'Leading and trailing space is not allowed';
            } else if (/\s/.test(data?.coaCode)) {
                errors.coaCode = 'Spaces in between digits are not allowed';
            } else if (!/^[0-9]{3}$/.test(data?.coaCode)) {
                errors.coaCode = 'Numbers should be in between 000 to 999';
            }

            return errors;
        },

        onSubmit: async (data) => {
            const coaPayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(coaPayload, '/account/v1/chartofaccount/createlkpcostcenter', true, false));
            if (res?.responseCode === '010000') {
                navigate(-1);
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>Add Details</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Cost Center Name</label>
                                <InputText
                                    id="lkpCostCenterName"
                                    value={formik.values.lkpCostCenterName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpCostCenterName') }, 'Input__Round')}
                                    maxLength={30}
                                />
                                {getFormErrorMessage('lkpCostCenterName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Cost Center Code</label>
                                <InputText
                                    id="lkpCostCenterCode"
                                    value={formik.values.lkpCostCenterCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpCostCenterCode') }, 'Input__Round')}
                                    maxLength={20}
                                />
                                {getFormErrorMessage('lkpCostCenterCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Cost Center Description</label>
                                <InputText
                                    id="lkpCostCenterDescription"
                                    value={formik.values.lkpCostCenterDescription || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpCostCenterDescription') }, 'Input__Round')}
                                    maxLength={199}
                                />
                                {getFormErrorMessage('lkpCostCenterDescription')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Coa Code</label>
                                <InputText id="coaCode" value={formik.values.coaCode || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('coaCode') }, 'Input__Round')} maxLength={30} />
                                {getFormErrorMessage('coaCode')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Submit" className="Btn__Dark" />
                        <Button onClick={() => navigate('/costcenter')} label="Cancel" className="Btn__Transparent" type="button" />
                    </div>
                </form>
            </div>
        </>
    );
};

export default AddCostCenter;

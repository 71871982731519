import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';

import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';
import { handleGetRequest } from '../../../services/GetTemplate';
import { Dialog } from 'primereact/dialog';
import SettleLoanDialog from './SettleLoanDialog';

function ViewLoanAccount({}) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [dataById, setDataById] = useState();
    const [showModal, setShowModal] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const disbursmentId = query.get('reqID');

    const validationSchema = Yup.object().shape({});
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            customerName: '',
            mobileNo: '',
            cnic: '',
            email: '',
            segment: '',
            activationDate: '',
            loanNo: '',
            loanAmount: '',
            productName: '',
            loanTenure: '',
            accountStatus: '',
            issuanceDate: ''
        }
    });

    const getDatabyId = async () => {
        const response = await handleGetRequest(`/lending/v1/lending/getloanaccountbyid/${disbursmentId}`);
        if (response?.responseCode === process.env.REACT_APP_LENDING_SUCCESS_CODE) {
            setDataById(response?.payLoad);
        }

        const keyData = response?.payLoad;
        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik?.setFieldValue(key, keyData[key]);
                }
            });
        }
        formik.setFieldValue('issuanceDate', keyData?.issuanceDate?.slice(0, 10));
        formik.setFieldValue('activationDate', keyData?.activationDate?.slice(0, 10));
    };
    useEffect(() => {
        getDatabyId();
    }, [disbursmentId]);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours from 24-hour to 12-hour format
        const formattedHours = hours % 12 || 12;

        const formattedDate = `${day}-${month}-${year}, ${formattedHours}:${minutes} ${ampm}`;
        return formattedDate;
    };

    const handleNavigate = () => {
        navigate(`/viewloanbook?reqId=${dataById?.cnic}`);
    };

    const handleButtonModal = () => {
        setShowModal(true);
    };

    return (
        <>
            <Dialog resizable={false} header={'LOAN ACCOUNT DETAILS'} visible={showModal} blockScroll style={{ width: '55vw' }} onHide={() => setShowModal(false)} draggable={false}>
                <SettleLoanDialog setShowModal={setShowModal} mobileNumber={formik?.values?.mobileNo} disbursmentId={disbursmentId} />
            </Dialog>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>Z-CONNECT CUSTOMER KYC VIEW DETAIL </h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="customerName" className={classNames({ 'p-error': isFormFieldValid('customerName') }, 'Label__Text')}>
                                    Customer Name <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="customerName"
                                    placeholder="Customer Name"
                                    name="customerName"
                                    maxLength={100}
                                    value={formik?.values?.customerName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('customerName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('customerName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="mobileNo" className={classNames({ 'p-error': isFormFieldValid('mobileNo') }, 'Label__Text')}>
                                    Mobile Number <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="mobileNo"
                                    placeholder="Mobile Number"
                                    name="mobileNo"
                                    value={formik?.values?.mobileNo || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('mobileNo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('mobileNo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cnic" className={classNames({ 'p-error': isFormFieldValid('cnic') }, 'Label__Text')}>
                                    CNIC <span className="Label__Required"></span>
                                </label>
                                <InputText disabled={true} id="cnic" placeholder="CNIC" name="cnic" value={formik?.values?.cnic || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('cnic') }, 'Input__Round')} />
                                {getFormErrorMessage('cnic')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') }, 'Label__Text')}>
                                    Email <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="email"
                                    placeholder="Email"
                                    name="email"
                                    value={formik?.values?.email || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('email') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('email')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="segment" className={classNames({ 'p-error': isFormFieldValid('segment') }, 'Label__Text')}>
                                    Segment <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="segment"
                                    placeholder="Segment"
                                    name="segment"
                                    value={formik?.values?.segment || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('segment') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('segment')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="activationDate" className={classNames({ 'p-error': isFormFieldValid('activationDate') }, 'Label__Text')}>
                                    Activation Date<span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="activationDate"
                                    placeholder=""
                                    name="activationDate"
                                    type="Date"
                                    value={formik?.values?.activationDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('activationDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('activationDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="loanNo" className={classNames({ 'p-error': isFormFieldValid('loanNo') }, 'Label__Text')}>
                                    Loan ID <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="loanNo"
                                    placeholder="Loan ID"
                                    name="loanNo"
                                    value={formik?.values?.loanNo || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('loanNo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('loanNo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="loanAmount" className={classNames({ 'p-error': isFormFieldValid('loanAmount') }, 'Label__Text')}>
                                    Loan Amount <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="loanAmount"
                                    placeholder="Loan Amount"
                                    name="loanAmount"
                                    value={formik?.values?.loanAmount || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('loanAmount') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('loanAmount')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productName" className={classNames({ 'p-error': isFormFieldValid('productName') }, 'Label__Text')}>
                                    Product Name <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="productName"
                                    placeholder="Product Name"
                                    name="productName"
                                    value={formik?.values?.productName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('productName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="loanTenure" className={classNames({ 'p-error': isFormFieldValid('loanTenure') }, 'Label__Text')}>
                                    Loan Tenure
                                </label>
                                <InputText
                                    disabled={true}
                                    placeholder="Loan Tenure"
                                    id="loanTenure"
                                    name="loanTenure"
                                    value={formik?.values?.loanTenure || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('loanTenure') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('loanTenure')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountStatus" className={classNames({ 'p-error': isFormFieldValid('accountStatus') }, 'Label__Text')}>
                                    Account Status
                                </label>
                                <InputText
                                    disabled={true}
                                    placeholder="Account Status"
                                    id="accountStatus"
                                    name="accountStatus"
                                    value={formik?.values?.accountStatus || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountStatus') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('accountStatus')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 ">
                            <div className="p-field">
                                <label htmlFor="issuanceDate" className={classNames({ 'p-error': isFormFieldValid('issuanceDate') }, 'Label__Text')}>
                                    Issuance Date<span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="issuanceDate"
                                    placeholder=""
                                    name="issuanceDate"
                                    type="Date"
                                    value={formik?.values?.issuanceDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('issuanceDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('issuanceDate')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} label="View Loan Book" className="Btn__Dark" onClick={handleNavigate} />
                        <Button disabled={true} label="Issue NOC" className="Btn__Transparent" style={{ marginLeft: '10px', marginRight: '10px' }} />
                        <Button disabled={loading} label="Settle Loan" className="Btn__Dark" style={{ marginLeft: '10px' }} onClick={handleButtonModal} />
                    </div>
                </form>
            </div>
        </>
    );
}
export default ViewLoanAccount;

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import * as Yup from 'yup';

function ViewCustomerBusinessRisk({ activeIndex, handleStepClick, riskProfileData, setStepData1, handledCrpModelId }) {

    const [customerType, setCustomerType] = useState();
    const [occupationLov, setOccupationLov] = useState();
    const [sourceOfIncomeLov, setSourceOfIncomeLov] = useState();
    const [accountPurposeLov, setAccountPurposeLov] = useState();
    const [rpCrpModelId, setRpCrpModelId] = useState([]);
    const [pep, setPep] = useState();

    const getRpCrpModelId = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getcrpdata/TBL_RP_CRP_MODEL');
        setRpCrpModelId(response?.payLoad);
    };
    const getCustomerType = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getcrpdata/LKP_RP_CUSTOMER_TYPES');
        setCustomerType(response?.payLoad);
    };

    const getPepLov = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getcrpdata/LKP_RP_PEP');
        setPep(response?.payLoad);
    };

    const getHraLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/gethraaccountdata');
        setOccupationLov(resp?.payLoad?.lkpOccupation);
        setSourceOfIncomeLov(resp?.payLoad?.lkpSourceOfIncome);
        setAccountPurposeLov(resp?.payLoad?.lkpAccountPurpose);
    };

    useEffect(() => {
        getRpCrpModelId();
        getCustomerType();
        getPepLov();
        getHraLov();
    }, []);

    useEffect(() => {
        if (riskProfileData) {
            formik.setFieldValue('crpCode', riskProfileData?.crpCode);
            formik.setFieldValue('crpName', riskProfileData?.crpName);
            formik.setFieldValue('crpDescription', riskProfileData?.description);
            formik.setFieldValue('rpCrpModelId', riskProfileData?.tblRpCrpModel?.rpCrpModelId?.toString());

            const CTDropdownData = riskProfileData?.tblRpCrpDetails;

            if (CTDropdownData) {
                const CTHighId = [];
                const CTMediumId = [];
                const CTLowId = [];

                const pepHighId = [];
                const pepMediumId = [];
                const pepLowId = [];

                const occupationHighId = [];
                const occupationMediumId = [];
                const occupationLowId = [];

                const customerLinkedHighId = [];
                const customerLinkedMediumId = [];
                const customerLinkedLowId = [];

                const purposeOfACHighId = [];
                const purposeOfACMediumId = [];
                const purposeOfACLowId = [];

                const sourceOfFundsHighId = [];
                const sourceOfFundsMediumId = [];
                const sourceOfFundsLowId = [];

                CTDropdownData.forEach((value) => {
                    if (value?.isActive === 'Y') {
                        const referenceIdToString = value.refId?.toString() || value?.refId;
                        const referenceId = value?.refId || value.refId?.toString();
                        // Get Customer Type Data
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'CT') {
                            if (value?.rpRating === 'H') {
                                CTHighId.push(referenceIdToString);

                                formik.setFieldValue('CTHighRisk', CTHighId);
                                formik.setFieldValue('CToverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                CTMediumId.push(referenceIdToString);
                                formik.setFieldValue('CTMediumRisk', CTMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                CTLowId.push(referenceIdToString);
                                formik.setFieldValue('CTLowRisk', CTLowId);
                            }
                        }

                        // GET PEP Data
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'PEP') {
                            if (value?.rpRating === 'H') {
                                pepHighId.push(referenceIdToString);

                                formik.setFieldValue('pepHighRisk', pepHighId[0] === "0" ? "N" : "Y");
                                formik.setFieldValue('pepOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                pepMediumId.push(referenceIdToString);
                                formik.setFieldValue('pepMediumRisk', pepMediumId[0] === "0" ? "N" : "Y");
                            }
                            if (value?.rpRating === 'L') {
                                pepLowId.push(referenceIdToString);
                                formik.setFieldValue('pepLowRisk', pepLowId[0] === "0" ? "N" : "Y");
                            }
                        }

                        // GET occupation Data
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'OC') {
                            if (value?.rpRating === 'H') {
                                occupationHighId.push(referenceId);

                                formik.setFieldValue('occupationHighRisk', occupationHighId);
                                formik.setFieldValue('occupationOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                occupationMediumId.push(referenceId);

                                formik.setFieldValue('occupationMediumRisk', occupationMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                occupationLowId.push(referenceId);

                                formik.setFieldValue('occupationLowRisk', occupationLowId);
                            }
                        }

                        // GET Customer Linked Data
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'CCT') {
                            if (value?.rpRating === 'H') {
                                customerLinkedHighId.push(referenceIdToString);

                                formik.setFieldValue('customerLinkedHighRisk', customerLinkedHighId[0] === "0" ? "N" : "Y");
                                formik.setFieldValue('customerLinkedOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                customerLinkedMediumId.push(referenceIdToString);

                                formik.setFieldValue('customerLinkedMediumRisk', customerLinkedMediumId[0] === "0" ? "N" : "Y");
                            }
                            if (value?.rpRating === 'L') {
                                customerLinkedLowId.push(referenceIdToString);

                                formik.setFieldValue('customerLinkedLowRisk', customerLinkedLowId[0] === "0" ? "N" : "Y");
                            }
                        }

                        // GET Purpose of AC Data
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'PA') {
                            if (value?.rpRating === 'H') {
                                purposeOfACHighId.push(referenceId);

                                formik.setFieldValue('purposeOfACHighRisk', purposeOfACHighId);
                                formik.setFieldValue('purposeOfACOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                purposeOfACMediumId.push(referenceId);

                                formik.setFieldValue('purposeOfACMediumRisk', purposeOfACMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                purposeOfACLowId.push(referenceId);

                                formik.setFieldValue('purposeOfACLowRisk', purposeOfACLowId);
                            }
                        }

                        // GET Source of Funds Data
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'SF') {
                            if (value?.rpRating === 'H') {
                                sourceOfFundsHighId.push(referenceId);

                                formik.setFieldValue('sourceOfFundsHighRisk', sourceOfFundsHighId);
                                formik.setFieldValue('sourceOfFundsOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                sourceOfFundsMediumId.push(referenceId);

                                formik.setFieldValue('sourceOfFundsMediumRisk', sourceOfFundsMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                sourceOfFundsLowId.push(referenceId);

                                formik.setFieldValue('sourceOfFundsLowRisk', sourceOfFundsLowId);
                            }
                        }
                    }
                });
            }
        }
    }, [riskProfileData]); //eslint-disable-line

    const validationSchema = Yup.object().shape({
        crpCode: Yup.string().required('This field is required').max(50, 'field cannot exceed 50 characters').nullable(),
        crpName: Yup.string()
            .required('This field is required')
            .max(100, 'field cannot exceed 100 characters')
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
            .nullable(),
        crpDescription: Yup.string()
            .required('This field is required')
            .max(200, 'field cannot exceed 200 characters')
            .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
            .nullable()
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            stepNo: 1,
            crpCode: '',
            crpName: '',
            rpCrpModelId: '',
            crpDescription: '',
            CTHighRisk: [],
            CTMediumRisk: [],
            CTLowRisk: [],
            CToverRideRule: 'false',
            pepHighRisk: [],
            pepMediumRisk: [],
            pepLowRisk: [],
            pepOverRideRule: 'false',
            customerLinkedHighRisk: [],
            customerLinkedMediumRisk: [],
            customerLinkedLowRisk: [],
            customerLinkedOverRideRule: 'false',
            occupationHighRisk: [],
            occupationMediumRisk: [],
            occupationLowRisk: [],
            occupationOverRideRule: 'false',
            purposeOfACHighRisk: [],
            purposeOfACMediumRisk: [],
            purposeOfACLowRisk: [],
            purposeOfACOverRideRule: 'false',
            sourceOfFundsHighRisk: [],
            sourceOfFundsMediumRisk: [],
            sourceOfFundsLowRisk: [],
            sourceOfFundsOverRideRule: 'false',
            reqMap: []
        },
        validate: (data) => {
            let errors = {};

            if (!data.rpCrpModelId) {
                errors.rpCrpModelId = 'This field is required.';
            }

            if (data?.CTHighRisk.length === 0) {
                errors.CTHighRisk = 'This field is required';
            }
            if (data?.CTMediumRisk.length === 0) {
                errors.CTMediumRisk = 'This field is required';
            }
            if (data?.CTLowRisk.length === 0) {
                errors.CTLowRisk = 'This field is required';
            }
            if (data?.pepHighRisk.length === 0) {
                errors.pepHighRisk = 'This field is required';
            }
            if (data?.pepMediumRisk.length === 0) {
                errors.pepMediumRisk = 'This field is required';
            }
            if (data?.pepLowRisk.length === 0) {
                errors.pepLowRisk = 'This field is required';
            }
            if (data?.customerLinkedHighRisk.length === 0) {
                errors.customerLinkedHighRisk = 'This field is required';
            }
            if (data?.customerLinkedMediumRisk.length === 0) {
                errors.customerLinkedMediumRisk = 'This field is required';
            }
            if (data?.customerLinkedLowRisk.length === 0) {
                errors.customerLinkedLowRisk = 'This field is required';
            }

            if (data?.occupationHighRisk.length === 0) {
                errors.occupationHighRisk = 'This field is required';
            }
            if (data?.occupationMediumRisk.length === 0) {
                errors.occupationMediumRisk = 'This field is required';
            }
            if (data?.occupationLowRisk.length === 0) {
                errors.occupationLowRisk = 'This field is required';
            }

            if (data?.purposeOfACHighRisk.length === 0) {
                errors.purposeOfACHighRisk = 'This field is required';
            }
            if (data?.purposeOfACMediumRisk.length === 0) {
                errors.purposeOfACMediumRisk = 'This field is required';
            }

            if (data?.purposeOfACLowRisk.length === 0) {
                errors.purposeOfACLowRisk = 'This field is required';
            }

            if (data?.sourceOfFundsHighRisk.length === 0) {
                errors.sourceOfFundsHighRisk = 'This field is required';
            }

            if (data?.sourceOfFundsMediumRisk.length === 0) {
                errors.sourceOfFundsMediumRisk = 'This field is required';
            }

            if (data?.sourceOfFundsLowRisk.length === 0) {
                errors.sourceOfFundsLowRisk = 'This field is required';
            }
            return errors;
        },
        onSubmit: async (data) => {

            data['crpId'] = riskProfileData?.rpCrpId;

            delete data['crpName'];
            delete data['crpCode'];

            handledCrpModelId(data?.rpCrpModelId);

            data['reqMap'] = [
                {
                    CT: {
                        highRisk: data?.CTHighRisk,
                        medRisk: data?.CTMediumRisk,
                        lowRisk: data?.CTLowRisk,
                        overRideRule: data?.CToverRideRule
                    }
                },
                {
                    PEP: {
                        highRisk: data?.pepHighRisk,
                        medRisk: data?.pepMediumRisk,
                        lowRisk: data?.pepLowRisk,
                        overRideRule: data?.pepOverRideRule
                    }
                },
                {
                    OC: {
                        highRisk: data?.occupationHighRisk,
                        medRisk: data?.occupationMediumRisk,
                        lowRisk: data?.occupationLowRisk,
                        overRideRule: data?.occupationOverRideRule
                    }
                },
                {
                    PA: {
                        highRisk: data?.purposeOfACHighRisk,
                        medRisk: data?.purposeOfACMediumRisk,
                        lowRisk: data?.purposeOfACLowRisk,
                        overRideRule: data?.purposeOfACOverRideRule
                    }
                },
                {
                    SF: {
                        highRisk: data?.sourceOfFundsHighRisk,
                        medRisk: data?.sourceOfFundsMediumRisk,
                        lowRisk: data?.sourceOfFundsLowRisk,
                        overRideRule: data?.sourceOfFundsOverRideRule
                    }
                },
                {
                    CCT: {
                        highRisk: data?.customerLinkedHighRisk,
                        medRisk: data?.customerLinkedMediumRisk,
                        lowRisk: data?.customerLinkedLowRisk,
                        overRideRule: data?.customerLinkedOverRideRule
                    }
                }
            ];

            delete data['CTHighRisk'];
            delete data['CTMediumRisk'];
            delete data['CTLowRisk'];
            delete data['CToverRideRule'];
            delete data['pepHighRisk'];
            delete data['pepMediumRisk'];
            delete data['pepLowRisk'];
            delete data['pepOverRideRule'];
            delete data['occupationHighRisk'];
            delete data['occupationMediumRisk'];
            delete data['occupationLowRisk'];
            delete data['occupationOverRideRule'];
            delete data['purposeOfACHighRisk'];
            delete data['purposeOfACMediumRisk'];
            delete data['purposeOfACLowRisk'];
            delete data['purposeOfACOverRideRule'];
            delete data['sourceOfFundsHighRisk'];
            delete data['sourceOfFundsMediumRisk'];
            delete data['sourceOfFundsLowRisk'];
            delete data['sourceOfFundsOverRideRule'];
            delete data['customerLinkedHighRisk'];
            delete data['customerLinkedMediumRisk'];
            delete data['customerLinkedLowRisk'];
            delete data['customerLinkedOverRideRule'];

            handleStepClick(activeIndex + 1);
            setStepData1(data);
        }
    });

    const staticLov = [{ name: "Yes", value: "Y" }, { name: "No", value: "N" }]

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="crpCode" className={classNames({ 'p-error': isFormFieldValid('crpCode') }, 'Label__Text')}>
                                    CRP Code <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    keyfilter={'int'}
                                    id="crpCode"
                                    placeholder="Enter CRP Code"
                                    name="crpCode"
                                    disabled
                                    maxLength={20}
                                    value={formik?.values?.crpCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('crpCode') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('crpCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="crpName" className={classNames({ 'p-error': isFormFieldValid('crpName') }, 'Label__Text')}>
                                    CRP Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="crpName"
                                    placeholder="Enter Name"
                                    name="crpName"
                                    maxLength={50}
                                    disabled
                                    value={formik?.values?.crpName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('crpName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('crpName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="rpCrpModelId" className={classNames({ 'p-error': isFormFieldValid('rpCrpModelId') }, 'Label__Text')}>
                                    CRP Model
                                </label>
                                <Dropdown
                                    id="rpCrpModelId"
                                    placeholder="Select CRP Model"
                                    options={rpCrpModelId}
                                    optionLabel="name"
                                    name="rpCrpModelId"
                                    optionValue="lovId"
                                    disabled
                                    value={formik.values.rpCrpModelId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('rpCrpModelId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('rpCrpModelId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="crpDescription" className={classNames({ 'p-error': isFormFieldValid('crpDescription') }, 'Label__Text')}>
                                    Description<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="crpDescription"
                                    placeholder="Enter Description"
                                    name="crpDescription"
                                    maxLength={200}
                                    disabled
                                    value={formik?.values?.crpDescription || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('crpDescription') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('crpDescription')}
                            </div>
                        </div>
                    </div>

                    {/* Customer Type */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>CUSTOMER TYPE</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="CTHighRisk" className={classNames({ 'p-error': isFormFieldValid('CTHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="CTHighRisk"
                                    placeholder="Select High Risk"
                                    options={customerType}
                                    optionLabel="name"
                                    name="CTHighRisk"
                                    readOnly
                                    optionValue="lovId"
                                    value={formik?.values?.CTHighRisk || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('CTHighRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="CTMediumRisk" className={classNames({ 'p-error': isFormFieldValid('CTMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="CTMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={customerType}
                                    optionLabel="name"
                                    name="CTMediumRisk"
                                    readOnly
                                    optionValue="lovId"
                                    value={formik?.values?.CTMediumRisk || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('CTMediumRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="CTLowRisk" className={classNames({ 'p-error': isFormFieldValid('CTLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="CTLowRisk"
                                    placeholder="Select Low Risk"
                                    options={customerType}
                                    optionLabel="name"
                                    name="CTLowRisk"
                                    readOnly
                                    optionValue="lovId"
                                    value={formik?.values?.CTLowRisk || ''}
                                    //onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('CTLowRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>

                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="CToverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton disabled={true} inputId="CToverRideRule" name="CToverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.CToverRideRule === 'false'} />
                                    <label htmlFor="CToverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton disabled={true} inputId="CToverRideRule" name="CToverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.CToverRideRule === 'true'} />
                                    <label htmlFor="CToverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* PEP */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>PEP</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="pepHighRisk" className={classNames({ 'p-error': isFormFieldValid('pepHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <Dropdown
                                    id="pepHighRisk"
                                    placeholder="Select High Risk"
                                    options={staticLov}
                                    name="pepHighRisk"
                                    value={formik?.values?.pepHighRisk || ''}
                                    optionValue='value'
                                    optionLabel='name'
                                    className={classNames({ 'p-invalid': isFormFieldValid('pepHighRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        {/* <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="pepMediumRisk" className={classNames({ 'p-error': isFormFieldValid('pepMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <Dropdown
                                    id="pepMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={staticLov}
                                    name="pepMediumRisk"
                                    optionValue='value'
                                    optionLabel='name'
                                    value={formik?.values?.pepMediumRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pepMediumRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div> */}
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="pepLowRisk" className={classNames({ 'p-error': isFormFieldValid('pepLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <Dropdown
                                    id="pepLowRisk"
                                    placeholder="Select Low Risk"
                                    options={staticLov}
                                    name="pepLowRisk"
                                    optionValue='value'
                                    optionLabel='name'
                                    value={formik?.values?.pepLowRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pepLowRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="pepOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="pepOverRideRule" name="pepOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.pepOverRideRule === 'false'} disabled={true} />
                                    <label htmlFor="pepOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="pepOverRideRule" name="pepOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.pepOverRideRule === 'true'} disabled={true} />
                                    <label htmlFor="pepOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* CUSTOMER IS LINKED TO NGO/NPO/CHARITIES/TRUSTS */}
                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>CUSTOMER IS LINKED TO NGO/NPO/CHARITIES/TRUSTS/CLUBS/SOCIETIES/ASSOCIATION/EXCHANGE CO</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="customerLinkedHighRisk" className={classNames({ 'p-error': isFormFieldValid('customerLinkedHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <Dropdown
                                    id="customerLinkedHighRisk"
                                    placeholder="Select High Risk"
                                    options={staticLov}
                                    optionLabel="name"
                                    name="customerLinkedHighRisk"
                                    optionValue="value"
                                    readOnly
                                    value={formik?.values?.customerLinkedHighRisk || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('customerLinkedHighRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        {/* <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="customerLinkedMediumRisk" className={classNames({ 'p-error': isFormFieldValid('customerLinkedMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <Dropdown
                                    id="customerLinkedMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={staticLov}
                                    optionLabel="name"
                                    name="customerLinkedMediumRisk"
                                    optionValue="value"
                                    readOnly
                                    value={formik?.values?.customerLinkedMediumRisk || ''}
                                    //onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('customerLinkedMediumRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div> */}
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="customerLinkedLowRisk" className={classNames({ 'p-error': isFormFieldValid('customerLinkedLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <Dropdown
                                    id="customerLinkedLowRisk"
                                    placeholder="Select Low Risk"
                                    options={staticLov}
                                    optionLabel="name"
                                    name="customerLinkedLowRisk"
                                    optionValue="value"
                                    readOnly
                                    value={formik?.values?.customerLinkedLowRisk || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('customerLinkedLowRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="customerLinkedOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton disabled={true} inputId="customerLinkedOverRideRule" name="customerLinkedOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.customerLinkedOverRideRule === 'false'} />
                                    <label htmlFor="customerLinkedOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton disabled={true} inputId="customerLinkedOverRideRule" name="customerLinkedOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.customerLinkedOverRideRule === 'true'} />
                                    <label htmlFor="customerLinkedOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* OCCUPATION */}
                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>OCCUPATION</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="occupationHighRisk" className={classNames({ 'p-error': isFormFieldValid('occupationHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="occupationHighRisk"
                                    placeholder="Select High Risk"
                                    options={occupationLov}
                                    optionLabel="occupationName"
                                    name="occupationHighRisk"
                                    readOnly
                                    optionValue="occupationId"
                                    value={formik?.values?.occupationHighRisk || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('occupationHighRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="occupationMediumRisk" className={classNames({ 'p-error': isFormFieldValid('occupationMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="occupationMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={occupationLov}
                                    optionLabel="occupationName"
                                    readOnly
                                    name="occupationMediumRisk"
                                    optionValue="occupationId"
                                    value={formik?.values?.occupationMediumRisk || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('occupationMediumRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="occupationLowRisk" className={classNames({ 'p-error': isFormFieldValid('occupationLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="occupationLowRisk"
                                    placeholder="Select Low Risk"
                                    options={occupationLov}
                                    optionLabel="occupationName"
                                    readOnly
                                    name="occupationLowRisk"
                                    optionValue="occupationId"
                                    value={formik?.values?.occupationLowRisk || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('occupationLowRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="occupationOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton disabled={true} inputId="occupationOverRideRule" name="occupationOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.occupationOverRideRule === 'false'} />
                                    <label htmlFor="occupationOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton disabled={true} inputId="occupationOverRideRule" name="occupationOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.occupationOverRideRule === 'true'} />
                                    <label htmlFor="occupationOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* PURPOSE OF A/C */}
                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>PURPOSE OF A/C</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="purposeOfACHighRisk" className={classNames({ 'p-error': isFormFieldValid('purposeOfACHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="purposeOfACHighRisk"
                                    placeholder="Select High Risk"
                                    options={accountPurposeLov}
                                    readOnly
                                    optionLabel="accountPurposeName"
                                    name="purposeOfACHighRisk"
                                    optionValue="accountPurposeId"
                                    value={formik?.values?.purposeOfACHighRisk || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('purposeOfACHighRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="purposeOfACMediumRisk" className={classNames({ 'p-error': isFormFieldValid('purposeOfACMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="purposeOfACMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={accountPurposeLov}
                                    readOnly
                                    optionLabel="accountPurposeName"
                                    name="purposeOfACMediumRisk"
                                    optionValue="accountPurposeId"
                                    value={formik?.values?.purposeOfACMediumRisk || ''}
                                    //onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('purposeOfACMediumRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="purposeOfACLowRisk" className={classNames({ 'p-error': isFormFieldValid('purposeOfACLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="purposeOfACLowRisk"
                                    placeholder="Select Low Risk"
                                    options={accountPurposeLov}
                                    readOnly
                                    optionLabel="accountPurposeName"
                                    name="purposeOfACLowRisk"
                                    optionValue="accountPurposeId"
                                    value={formik?.values?.purposeOfACLowRisk || ''}
                                    //onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('purposeOfACLowRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="purposeOfACOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton disabled={true} inputId="purposeOfACOverRideRule" name="purposeOfACOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.purposeOfACOverRideRule === 'false'} />
                                    <label htmlFor="purposeOfACOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton disabled={true} inputId="purposeOfACOverRideRule" name="purposeOfACOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.purposeOfACOverRideRule === 'true'} />
                                    <label htmlFor="purposeOfACOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* SOURCE OF FUNDS */}
                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>SOURCE OF FUNDS</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="sourceOfFundsHighRisk" className={classNames({ 'p-error': isFormFieldValid('sourceOfFundsHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="sourceOfFundsHighRisk"
                                    placeholder="Select High Risk"
                                    readOnly
                                    options={sourceOfIncomeLov}
                                    optionLabel="sourceOfIncomeName"
                                    name="sourceOfFundsHighRisk"
                                    optionValue="sourceOfIncomeId"
                                    value={formik?.values?.sourceOfFundsHighRisk || ''}
                                    //onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('sourceOfFundsHighRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="sourceOfFundsMediumRisk" className={classNames({ 'p-error': isFormFieldValid('sourceOfFundsMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="sourceOfFundsMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={sourceOfIncomeLov}
                                    readOnly
                                    optionLabel="sourceOfIncomeName"
                                    name="sourceOfFundsMediumRisk"
                                    optionValue="sourceOfIncomeId"
                                    value={formik?.values?.sourceOfFundsMediumRisk || ''}
                                    //onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('sourceOfFundsMediumRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="sourceOfFundsLowRisk" className={classNames({ 'p-error': isFormFieldValid('sourceOfFundsLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="sourceOfFundsLowRisk"
                                    placeholder="Select Low Risk"
                                    options={sourceOfIncomeLov}
                                    readOnly
                                    optionLabel="sourceOfIncomeName"
                                    name="sourceOfFundsLowRisk"
                                    optionValue="sourceOfIncomeId"
                                    value={formik?.values?.sourceOfFundsLowRisk || ''}
                                    //onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('sourceOfFundsLowRisk') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="sourceOfFundsOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton disabled={true} inputId="sourceOfFundsOverRideRule" name="sourceOfFundsOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.sourceOfFundsOverRideRule === 'false'} />
                                    <label htmlFor="sourceOfFundsOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton disabled={true} inputId="sourceOfFundsOverRideRule" name="sourceOfFundsOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.sourceOfFundsOverRideRule === 'true'} />
                                    <label htmlFor="sourceOfFundsOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 
                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} label="Next" className="Btn__Dark" />
                    </div> */}
                </form>
            </div>
        </>
    );
}
export default ViewCustomerBusinessRisk;

import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';

const EditCardStatus = ({ onHide, participantDTO, handleCardStatusCallBack, cardStatusLov }) => {
    const [disableSubmit, setDisableSubmit] = useState(true);

    const formik = useFormik({
        initialValues: {
            // status: participantDTO?.activeInactive === 'A' ? '*ACTIVE' : '*TMPBLOCK'
            status: participantDTO?.statusReason
        },

        onSubmit: async () => {
            const isCardStatusUpdateReq = !disableSubmit;
            const newValues = {
                ...formik.values,
                isCardStatusUpdateReq: isCardStatusUpdateReq
            };
            handleCardStatusCallBack(newValues);
            onHide();
        }
    });

    useEffect(() => {
        const hasChanges = Object.keys(formik.values).some((key) => formik.values[key] !== participantDTO[key]);
        setDisableSubmit(!hasChanges);
    }, [formik.values, participantDTO]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="Card__Round">
                <div className="p-field col-12 pt-3 pb-3">
                    <div className="p-field">
                        <label>Card Status</label>
                        <Dropdown
                            id="status"
                            name="status"
                            placeholder="--Select--"
                            options={cardStatusLov}
                            value={formik.values.status}
                            optionLabel="name"
                            // optionValue="lovId"
                            optionValue="code"
                            onChange={formik.handleChange}
                            className="Dropdown__Round"
                            appendTo="self"
                        />
                    </div>
                </div>
                <div className="Down__Btn mt-4">
                    <Button label="Update" iconPos="right" className="Btn__Dark" type="submit" disabled={disableSubmit} />
                    <Button label="Cancel" iconPos="right" className="Btn__cancel" type="button" onClick={onHide} />
                </div>
            </div>
        </form>
    );
};

export default EditCardStatus;

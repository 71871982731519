import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import * as Yup from 'yup';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewValidators({ onHideViewValidatorDialog, validatorViewRowData }) {
    const tableName = 'TBL_VALIDATOR';

    const [validatorTypeLov, setValidatorTypeLov] = useState([]);

    const handleCancel = (e) => {
        e.preventDefault();
        onHideViewValidatorDialog();
    };

    const validatorId = parseInt(validatorViewRowData?.validatorId);

    const getKycValidatorData = async () => {
        const res = await handleGetRequest(`/account/v1/validator/getKycValidatorById/${validatorId}`);
        const keyData = res?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            formik.setFieldValue('validatorTypeId', keyData?.lkpValidatorType?.validatorTypeId.toString());
        }
    };

    const getValidatorTypeLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getvalidatortypes/LKP_VALIDATOR_TYPE:*');
        setValidatorTypeLov(resp?.payLoad);
    };

    useEffect(() => {
        getKycValidatorData();
        getValidatorTypeLov();
    }, []); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        validatorName: Yup.string().required('This Field is required.'),
        validatorTypeId: Yup.string().required('This Field is required.'),
        argumentName: Yup.string().required('This Field is required.'),
        argumentValue: Yup.string().required('This Field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            validatorName: '',
            validatorTypeId: '',
            argumentName: '',
            argumentValue: ''
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="validatorName" className={classNames({ 'p-error': isFormFieldValid('validatorName') }, 'Label__Text')}>
                                    Validator Name
                                </label>
                                <InputText
                                    id="validatorName"
                                    placeholder="Enter Validator Name"
                                    name="validatorName"
                                    disabled={true}
                                    value={formik?.values?.validatorName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('validatorName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('validatorName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="validatorTypeId" className={classNames({ 'p-error': isFormFieldValid('validatorTypeId') }, 'Label__Text')}>
                                    Validator Type
                                </label>
                                <Dropdown
                                    id="validatorTypeId"
                                    placeholder="Select Validator Type"
                                    options={validatorTypeLov}
                                    optionLabel="name"
                                    name="validatorTypeId"
                                    optionValue="lovId"
                                    disabled={true}
                                    value={formik.values.validatorTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('validatorTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('validatorTypeId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="argumentName" className={classNames({ 'p-error': isFormFieldValid('argumentName') }, 'Label__Text')}>
                                    Argument Name
                                </label>
                                <InputText
                                    id="argumentName"
                                    placeholder="Enter Argument Name"
                                    name="argumentName"
                                    disabled={true}
                                    value={formik?.values?.argumentName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('argumentName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('argumentName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="argumentValue" className={classNames({ 'p-error': isFormFieldValid('argumentValue') }, 'Label__Text')}>
                                    Argument Value
                                </label>
                                <InputText
                                    id="argumentValue"
                                    placeholder="Enter Argument Value"
                                    name="argumentValue"
                                    disabled={true}
                                    value={formik?.values?.argumentValue?.trim()?.toString() || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('argumentValue') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('argumentValue')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label="Okay" className="Btn__Dark" onClick={(e) => handleCancel(e)} />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={validatorId} tableName={tableName} />
        </>
    );
}

export default ViewValidators;

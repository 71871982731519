import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { handleGetRequest } from '../../../services/GetTemplate';
import { MultiSelect } from 'primereact/multiselect';

const PointRedemptionDialogView = ({ onHidePointRedemptionView, editable, pointRedemptionRowData }) => {
    const [transactionTypeData, setTransactionTypeData] = useState([]);

    useEffect(() => {
        formik.setFieldValue('voucherValue', pointRedemptionRowData?.voucherValue?.toString());
        formik.setFieldValue('voucherName', pointRedemptionRowData?.voucherName?.toString());
        formik.setFieldValue('pointValue', pointRedemptionRowData?.pointValue?.toString());

        formik.setFieldValue('dateFrom', pointRedemptionRowData?.dateFrom?.slice(0, 10));
        formik.setFieldValue('dateTo', pointRedemptionRowData?.dateTo?.slice(0, 10));
        formik.setFieldValue('expiry', pointRedemptionRowData?.expiry?.toString());

        const transTypeData = pointRedemptionRowData?.productId?.map((value) => {
            return value?.toString();
        });

        formik.setFieldValue('transType', transTypeData);
    }, [pointRedemptionRowData]); // eslint-disable-line

    const getTransactionTypeData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setTransactionTypeData(res?.payLoad);
    };

    useEffect(() => {
        getTransactionTypeData();
    }, []); // eslint-disable-line

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            voucherValue: '',
            voucherName: '',
            pointValue: '',
            transType: [],
            expiry: '',
            isActive: false,
            statusId: 0
        },

        onSubmit: async (data) => {
            onHidePointRedemptionView();
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="voucherName" className={classNames({ 'p-error': isFormFieldValid('voucherName') }, 'Label__Text')}>
                                    Voucher Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="voucherName"
                                    placeholder="Enter Voucher Name"
                                    name="voucherName"
                                    disabled={true}
                                    value={formik?.values?.voucherName}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('voucherName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('voucherName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="voucherValue" className={classNames({ 'p-error': isFormFieldValid('voucherValue') }, 'Label__Text')}>
                                    Voucher Value<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="voucherValue"
                                    placeholder="Enter Voucher Value"
                                    name="voucherValue"
                                    disabled={true}
                                    value={formik?.values?.voucherValue}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('voucherValue') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('voucherValue')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="pointValue" className={classNames({ 'p-error': isFormFieldValid('pointValue') }, 'Label__Text')}>
                                    Point Value<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="pointValue"
                                    placeholder="Enter Point Value"
                                    name="pointValue"
                                    disabled={true}
                                    value={formik?.values?.pointValue}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pointValue') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('pointValue')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transType" className={classNames({ 'p-error': isFormFieldValid('transType') }, 'Label__Text')}>
                                    Transaction Type
                                </label>
                                <MultiSelect
                                    id="transType"
                                    placeholder="Select Transaction Type"
                                    options={transactionTypeData}
                                    optionLabel="name"
                                    name="transType"
                                    readOnly={true}
                                    optionValue="lovId"
                                    value={formik.values.transType}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('transType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expiry" className={classNames({ 'p-error': isFormFieldValid('expiry') }, 'Label__Text')}>
                                    Expiry
                                    {/* <span className="Label__Required">*</span> */}
                                </label>
                                <InputText
                                    id="expiry"
                                    placeholder="Enter Expiry"
                                    name="expiry"
                                    disabled={true}
                                    value={formik?.values?.expiry}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expiry') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('expiry')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="submit" label="Okay" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
};

export default PointRedemptionDialogView;

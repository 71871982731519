import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

const ViewCheckerChartOfAccounts = () => {

    const [organizationLov, setOrganizationLov] = useState([]);
    const [locationLov, setLocationLov] = useState([]);
    const [costCenterLov, setCostCenterLov] = useState([]);
    const [categoryLov, setCategoryLov] = useState([]);
    const [subCategoryLov, setSubCategoryLov] = useState([]);
    const [currencyLov, setCurrencyLov] = useState([]);
    const [mrpLov, setMrpLov] = useState([]);
    const [mcReqData, setMcReqData] = useState([]);
    const [createData, setCreateData] = useState([]);

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const tableName = query.get('tableName');
    const requestTypeDescr = query.get('requestTypeDescr');

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('Action should not be empty'),
        checkerComments: Yup.string().required('This Field is required').notOneOf([''], 'This Field should not be empty').max(500, 'Maximum 500 characters needed')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            lkpOrganization: '',
            lkpBranch: '',
            lkpCostCenter: '',
            lkpGlType: '',
            lkpSubCategory: '',
            lkpCurrency: '',
            lkpMrpCode: '',
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    // Segment LOVs

    const getOrganizationLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_ORGANIZATION');
        setOrganizationLov(res?.payLoad);
    };

    const getLocationLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_BRANCH');
        setLocationLov(res?.payLoad);
    };

    const getCostCenterLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_COST_CENTER');
        setCostCenterLov(res?.payLoad);
    };

    const getCategoryLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_GL_TYPE');
        setCategoryLov(res?.payLoad);
    };

    const getSubCategoryLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_SUB_CATEGORY');
        setSubCategoryLov(res?.payLoad);
    };

    const getCurrencyLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_CURRENCY');
        setCurrencyLov(res?.payLoad);
    };

    const getMrpLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_MRP_CODE');
        setMrpLov(res?.payLoad);
    };

    const getCostCenterById = async () => {
        if (refTableId) {
            const resp = await handleGetRequest(`/account/v1/chartofaccount/getchartofaccountbyid/${refTableId}`);
            const keyData = resp?.payLoad;
            if (keyData !== null && typeof keyData !== 'undefined') {
                setCreateData(keyData)
            }
        }
    };

    const getCostCenterMcRequestDataById = async () => {
        if (mcRequestId) {
            const resp = await handleGetRequest(`/account/v1/chartofaccount/getchartofaccountbymcrequestid/${mcRequestId}`);
            const keyData = resp?.payLoad;
            if (keyData !== null && typeof keyData !== 'undefined') {
                setMcReqData(keyData)
            }
        }
    };

    useEffect(() => {
        getOrganizationLov()
        getLocationLov()
        getCostCenterLov()
        getCategoryLov()
        getSubCategoryLov()
        getCurrencyLov()
        getMrpLov()
        if (requestType === 'I') {
            getCostCenterById();
        } else if (requestType === 'U' && requestTypeDescr !== "Enable/Disable Request") {
            getCostCenterMcRequestDataById();
        } else if (requestType === 'U' && requestTypeDescr === "Enable/Disable Request") {
            getCostCenterById();
            getCostCenterMcRequestDataById()
        }
    }, []);

    useEffect(() => {
        if (requestType === 'I') {
            const orgLovId = organizationLov.find((item) => item?.code === createData?.glSegmentCode1);
            formik.setFieldValue("lkpOrganization", orgLovId && orgLovId?.lovId)
            const locLovId = locationLov.find((item) => item?.code === createData?.glSegmentCode2);
            formik.setFieldValue("lkpBranch", locLovId && locLovId?.lovId)
            const costCenterLovId = costCenterLov.find((item) => item?.code === createData?.glSegmentCode3);
            formik.setFieldValue("lkpCostCenter", costCenterLovId && costCenterLovId?.lovId)
            const categoryLovId = categoryLov.find((item) => item?.code === createData?.glSegmentCode4);
            formik.setFieldValue("lkpGlType", categoryLovId && categoryLovId?.lovId)
            const subCategoryLovId = subCategoryLov.find((item) => item?.code === createData?.glSegmentCode5);
            formik.setFieldValue("lkpSubCategory", subCategoryLovId && subCategoryLovId?.lovId)
            const currencyLovId = currencyLov.find((item) => item?.code === createData?.glSegmentCode6);
            formik.setFieldValue("lkpCurrency", currencyLovId && currencyLovId?.lovId)
            const mrpLovId = mrpLov.find((item) => item?.code === createData?.glSegmentCode7);
            formik.setFieldValue("lkpMrpCode", mrpLovId && mrpLovId?.lovId)
        } else if (requestType === 'U' && requestTypeDescr !== "Enable/Disable Request") {
            const orgLovId = organizationLov.find((item) => item?.lovId == mcReqData?.lkpOrganization?.organizationId);
            formik.setFieldValue("lkpOrganization", orgLovId && orgLovId?.lovId)
            const locLovId = locationLov.find((item) => item?.lovId == mcReqData?.lkpBranch?.branchId);
            formik.setFieldValue("lkpBranch", locLovId && locLovId?.lovId)
            const costCenterLovId = costCenterLov.find((item) => item?.lovId == mcReqData?.lkpCostCenter?.costCenterId);
            formik.setFieldValue("lkpCostCenter", costCenterLovId && costCenterLovId?.lovId)
            const categoryLovId = categoryLov.find((item) => item?.lovId == mcReqData?.lkpGlType?.glTypeId);
            formik.setFieldValue("lkpGlType", categoryLovId && categoryLovId?.lovId)
            const subCategoryLovId = subCategoryLov.find((item) => item?.lovId == mcReqData?.lkpSubCategory?.subCategoryId);
            formik.setFieldValue("lkpSubCategory", subCategoryLovId && subCategoryLovId?.lovId)
            const currencyLovId = currencyLov.find((item) => item?.lovId == mcReqData?.lkpCurrency?.currencyId);
            formik.setFieldValue("lkpCurrency", currencyLovId && currencyLovId?.lovId)
            const mrpLovId = mrpLov.find((item) => item?.lovId == mcReqData?.lkpMrpCode?.mrpCodeId);
            formik.setFieldValue("lkpMrpCode", mrpLovId && mrpLovId?.lovId)
        }
        else if (requestType === 'U' && requestTypeDescr === "Enable/Disable Request") {
            const orgLovId = organizationLov.find((item) => item?.code === createData?.glSegmentCode1);
            formik.setFieldValue("lkpOrganization", orgLovId && orgLovId?.lovId)
            const locLovId = locationLov.find((item) => item?.code === createData?.glSegmentCode2);
            formik.setFieldValue("lkpBranch", locLovId && locLovId?.lovId)
            const costCenterLovId = costCenterLov.find((item) => item?.code === createData?.glSegmentCode3);
            formik.setFieldValue("lkpCostCenter", costCenterLovId && costCenterLovId?.lovId)
            const categoryLovId = categoryLov.find((item) => item?.code === createData?.glSegmentCode4);
            formik.setFieldValue("lkpGlType", categoryLovId && categoryLovId?.lovId)
            const subCategoryLovId = subCategoryLov.find((item) => item?.code === createData?.glSegmentCode5);
            formik.setFieldValue("lkpSubCategory", subCategoryLovId && subCategoryLovId?.lovId)
            const currencyLovId = currencyLov.find((item) => item?.code === createData?.glSegmentCode6);
            formik.setFieldValue("lkpCurrency", currencyLovId && currencyLovId?.lovId)
            const mrpLovId = mrpLov.find((item) => item?.code === createData?.glSegmentCode7);
            formik.setFieldValue("lkpMrpCode", mrpLovId && mrpLovId?.lovId)
        }
    }, [requestType, requestTypeDescr, createData, mcReqData, organizationLov, locationLov, costCenterLov, categoryLov, subCategoryLov, currencyLov, mrpLov]);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>View Details</h2>
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className="Label__Text">Organization</label>
                            <Dropdown id="lkpOrganization" placeholder="Select Filter" options={organizationLov} optionLabel="name" optionValue="lovId" value={formik.values.lkpOrganization ?? ''} onChange={formik.handleChange} className="Dropdown__Round" />
                            {getFormErrorMessage('lkpOrganization')}
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className="Label__Text">Location</label>
                            <Dropdown id="lkpBranch" placeholder="Select Filter" options={locationLov} optionLabel="name" optionValue="lovId" value={formik.values.lkpBranch ?? ''} onChange={formik.handleChange} className="Dropdown__Round" />
                            {getFormErrorMessage('lkpBranch')}
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className="Label__Text">Cost Center</label>
                            <Dropdown id="lkpCostCenter" placeholder="Select Filter" options={costCenterLov} optionLabel="name" optionValue="lovId" value={formik.values.lkpCostCenter ?? ''} onChange={formik.handleChange} className="Dropdown__Round" />
                            {getFormErrorMessage('lkpCostCenter')}
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className="Label__Text">Category</label>
                            <Dropdown id="lkpGlType" placeholder="Select Filter" options={categoryLov} optionLabel="name" optionValue="lovId" value={formik.values.lkpGlType ?? ''} onChange={formik.handleChange} className="Dropdown__Round" />
                            {getFormErrorMessage('lkpGlType')}
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className="Label__Text">Sub Category</label>
                            <Dropdown id="lkpSubCategory" placeholder="Select Filter" options={subCategoryLov} optionLabel="name" optionValue="lovId" value={formik.values.lkpSubCategory ?? ''} onChange={formik.handleChange} className="Dropdown__Round" />
                            {getFormErrorMessage('lkpSubCategory')}
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className="Label__Text">Currency</label>
                            <Dropdown id="lkpCurrency" placeholder="Select Filter" options={currencyLov} optionLabel="name" optionValue="lovId" value={formik.values.lkpCurrency ?? ''} onChange={formik.handleChange} className="Dropdown__Round" />

                            {getFormErrorMessage('lkpCurrency')}
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className="Label__Text">MRP Code</label>
                            <Dropdown id="lkpMrpCode" placeholder="Select Filter" options={mrpLov} optionLabel="name" optionValue="lovId" value={formik.values.lkpMrpCode ?? ''} onChange={formik.handleChange} className="Dropdown__Round" />
                            {getFormErrorMessage('lkpMrpCode')}
                        </div>

                        {
                            (requestType === 'U' && requestTypeDescr === "Enable/Disable Request") &&
                            (
                                <div className="p-field col-12 md:col-3">
                                    <div className="p-field">
                                        <label className="Label__Text">Is Active</label>
                                        <Checkbox checked={mcReqData?.isActive === "Y"} disabled className='w-full' />
                                    </div>
                                </div>
                            )
                        }

                    </div>
                    <div className="Down__Btn">
                        <Button type="button" label="Okay" className="Btn__Dark" onClick={() => navigate(-1)} />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />

        </>
    );
};

export default ViewCheckerChartOfAccounts
import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewSalesRegionChecker() {
    const [loading, setloading] = useState(false);
    const [districtLov, setDistrictLov] = useState([]);
    const navigate = useNavigate();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const tableName = query.get('tableName');

    const formik = useFormik({
        initialValues: {
            regionName: '',
            districtId: ''
        }
    });

    const getDatabyId = async () => {
        // getRoleWiseModule
        const resp = await handleGetRequest(`/account/v1/sales/getRegionById/${refTableId}`);
        const keyData = resp?.payLoad;

        formik.setFieldValue('regionName', keyData?.regionName);
        formik.setFieldValue('districtId', keyData?.districtId?.toString());
    };

    const getMcRequestDataById = async () => {
        const resp = await handleGetRequest(`/account/v1/sales/getRegionsUpdateCheckerById/${mcRequestId}`);
        const keyData = resp?.payLoad;
        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
        }
        formik.setFieldValue('regionName', keyData?.regionName);
        formik.setFieldValue('districtId', keyData?.districtId?.toString());
        formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
    };

    const getDistrictData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getAllDistrict/LKP_DISTRICT:*');
        setDistrictLov(res?.payLoad);
    };

    useEffect(() => {
        getDistrictData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>VIEW DETAIL</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="regionName" className={classNames({ 'p-error': isFormFieldValid('regionName') }, 'Label__Text')}>
                                    Region Name
                                </label>
                                <InputText id="regionName" name="regionName" value={formik?.values?.regionName?.replace(/\s\s+/g, ' ')} disabled className={classNames({ 'p-invalid': isFormFieldValid('regionName') }, 'Input__Round')} />
                                {getFormErrorMessage('regionName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="districtId" className={classNames({ 'p-error': isFormFieldValid('districtId') }, 'Label__Text')}>
                                    District
                                </label>
                                <Dropdown
                                    id="districtId"
                                    placeholder="Select District"
                                    options={districtLov}
                                    optionLabel="name"
                                    name="districtId"
                                    optionValue="lovId"
                                    value={formik.values.districtId}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('districtId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('districtId')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default ViewSalesRegionChecker;

import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { useDispatch } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import { baseURL } from '../../../../Config';
import { toast } from 'react-toastify';

const AccountMaintenanceLogsDialog = ({ rowData, onHide }) => {
    const [accMaintenaceLogs, setAccMaintenaceLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        gte: Yup.string().required('This Field is required.'),
        lt: Yup.string().min(10).max(200).required('This Field is required.')
    });

    const minDate = () => {
        const date = new Date();
        date.setMonth(date.getMonth() - 3);
        const formatedDate = date;
        return formatedDate;
    };
    const maxDate = () => {
        const date = new Date();
        const formatedDate = date;
        return formatedDate;
    };
    const [toDateMin, setToDateMin] = useState(minDate);

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            gte: '',
            lt: ''
        }
    });

    useEffect(() => {
        if (formik.values.gte) {
            setToDateMin(formik.values.gte);
        }
    }, [formik.values.gte]);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleSubmit = async () => {
        const gte = formik.values.gte;
        const lt = formik.values.lt;

        if (formik.values.gte && formik.values.lt) {
            const data = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        gte: gte + ' ' + '00:00:00.000',
                        lt: lt + ' ' + '23:59:59.000',
                        id: rowData?.customerId
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            try {
                setLoading(true);
                const response = await dispatch(handlePostRequest(data, '/account/v1/search', false));
                const responseData = [response?.payLoad?.body].flatMap((item) => {
                    const parsedBody = JSON.parse(item);
                    const payloadServices = parsedBody?.hits?.hits?.map((item2) => {
                        const source = JSON.parse(item2?._source?.payload?.payloadService);
                        source.dateTime = item2?._source?.payload?.dateTime;
                        return source;
                    });
                    return payloadServices;
                });

                setAccMaintenaceLogs(responseData);
                setLoading(false);
            } catch (error) {
            } finally {
                setLoading(false);
            }
        }
    };

    const actionReqTypeTemplate = (rowData) => {
        // Keys to exclude
        const excludeKeys = ['reqType', 'userId', 'userName', 'accountNumber', 'dateTime'];

        // Filter the keys
        const keysToShow = Object.keys(rowData).filter((key) => !excludeKeys.includes(key));
        return (
            <>
                {keysToShow.map((key) => (
                    <div key={key}>
                        {rowData?.reqType} {key}
                    </div>
                ))}
            </>
        );
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-5">
                            <div className="p-field">
                                <label htmlFor="gte" className={classNames({ 'p-error': isFormFieldValid('gte') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText id="gte" type="date" name="gte" min={minDate()} max={maxDate()} value={formik.values.gte} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('gte') }, 'Input__Round')} />
                                {getFormErrorMessage('gte')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-5">
                            <div className="p-field">
                                <label htmlFor="lt" className={classNames({ 'p-error': isFormFieldValid('toDate') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText
                                    id="lt"
                                    type="date"
                                    name="lt"
                                    min={toDateMin}
                                    max={maxDate()}
                                    value={formik.values.lt}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lt') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('lt')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-2 flex align-items-center">
                            <div className="p-field">
                                <div className="Down__Btn mt-4">
                                    <Button label="Search" type="submit" className="Btn__Dark" onClick={handleSubmit} />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                {/* Maintenance logs */}
                <div className="custom-table custom-card">
                    <DataTable
                        value={accMaintenaceLogs}
                        showGridlines
                        emptyMessage="No data found."
                        scrollable
                        paginator
                        responsiveLayout="scroll"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50]}
                        loading={loading}
                    >
                        <Column field="userId" header="User Id" />
                        <Column field="userName" header="User Name" />
                        <Column body={actionReqTypeTemplate} header="Action Type" />
                        <Column field="dateTime" header="Date and Time" />
                        <Column field="accountNumber" header="A/C #" />
                    </DataTable>
                </div>
                <div className=" flex justify-content-center mt-4">
                    <div className="Down__Btn mt-4">
                        <Button label="Cancel" className="Btn__Dark" onClick={onHide} />
                    </div>
                </div>
            </div>
        </>
    );
};
export default AccountMaintenanceLogsDialog;

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import { handleGetRequest } from '../../../services/GetTemplate';

function RegimeLevelOldNewValuesView({ regimeLevelRowData }) {
    const [regimeLevelValues, setRegimeLevelValues] = useState();

    useEffect(() => {
        const rowData = JSON?.parse(JSON?.stringify(regimeLevelRowData));
        const keys = Object?.keys(rowData);
        keys.forEach((item) => {
            formik.setFieldValue(`${item}`, rowData[item]);
        });
        formik.setFieldValue('regimeCategory', rowData?.lkpRegimeCategory?.regimeCategoryId.toString());
    }, [regimeLevelRowData]); // eslint-disable-line

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const requestTypeDescr = query.get('requestTypeDescr');

    const getCategoryLovValues = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getRegimeCategory/LKP_REGIME_CATEGORY:*');
        setRegimeLevelValues(response?.payLoad);
    };
    useEffect(() => {
        getCategoryLovValues();
    }, []); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        checkerComments: Yup.string().required('This Field is required.'),
        action: Yup.string().required('This Field is required.')
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            regimeCategory: '',
            regimeLevelCode: '',
            regimeLevelName: '',
            regimeLevelDescr: '',
            checkerComments: '',
            action: ''
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>EDIT REGIME LEVEL CHECKER</h2>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="regimeCategory" className={classNames({ 'p-error': isFormFieldValid('regimeCategory') }, 'Label__Text')}>
                                    Regime Category
                                </label>
                                <Dropdown
                                    disabled
                                    id="regimeCategory"
                                    placeholder="Select Regime Category"
                                    options={regimeLevelValues}
                                    optionLabel="name"
                                    name="regimeCategory"
                                    optionValue="lovId"
                                    value={formik.values.regimeCategory || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regimeCategory') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('regimeCategory')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="regimeLevelCode" className={classNames({ 'p-error': isFormFieldValid('regimeLevelCode') }, 'Label__Text')}>
                                    Regime Code<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    id="regimeLevelCode"
                                    placeholder="Enter Regime Code"
                                    name="regimeLevelCode"
                                    value={formik?.values?.regimeLevelCode?.trim()?.toString() || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regimeLevelCode') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('regimeLevelCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="regimeLevelName" className={classNames({ 'p-error': isFormFieldValid('regimeLevelName') }, 'Label__Text')}>
                                    Regime Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    id="regimeLevelName"
                                    placeholder="Enter Regime Name"
                                    name="regimeLevelName"
                                    value={formik?.values?.regimeLevelName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regimeLevelName') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('regimeLevelName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="regimeLevelDescr" className={classNames({ 'p-error': isFormFieldValid('regimeLevelDescr') }, 'Label__Text')}>
                                    Description
                                </label>
                                <InputTextarea
                                    disabled
                                    autoResize="false"
                                    id="regimeLevelDescr"
                                    placeholder="Enter Description..."
                                    name="regimeLevelDescr"
                                    value={formik?.values?.regimeLevelDescr?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regimeLevelDescr') }, 'Dropdown__Round')}
                                    rows={3}
                                    cols={30}
                                />
                                {getFormErrorMessage('regimeLevelDescr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                    isActive
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox disabled={true} inputId="isActive" name="isActive" value={formik?.values?.isActive} checked={formik?.values?.isActive} onChange={formik.handleChange} />
                                </div>
                                {getFormErrorMessage('isActive')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="requestTypeDescr" className={classNames({ 'p-error': isFormFieldValid('requestTypeDescr') }, 'Label__Text')}>
                                    Request Type Description
                                </label>
                                <InputText
                                    disabled={true}
                                    id="requestTypeDescr"
                                    name="requestTypeDescr"
                                    value={requestTypeDescr}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('requestTypeDescr') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('requestTypeDescr')}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default RegimeLevelOldNewValuesView;

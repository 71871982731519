import React, { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import { classNames } from 'primereact/utils';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Badge } from 'primereact/badge';
// import { Button } from 'primereact/button';
// import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useDispatch, useSelector } from 'react-redux';
import { notificationRemoveAction } from './redux/actions/notificationAction';
import { handlePostForNotificationRequest } from './services/PostForNotification';

const AppBreadcrumb = (props) => {
    const location = useLocation();
    const dispatch = useDispatch();

    const op = useRef(null);

    // const userId = JSON.parse(sessionStorage.getItem('userDetails'));
    const notificationData = useSelector((state) => state?.notificationSlice?.notificationData);

    // const getNotificationData = async () => {
    //     const res = await handleGetForNotificationRequest(`/workflow/v1/notification/getnotification/${userId?.userId}`);
    //     // setNotificationValue(res?.payLoad);
    //     // Assuming res?.payLoad is an array of objects
    //     res?.payLoad.forEach((object) => {
    //         dispatch(NOTIFICATION_SUCCESS(object));
    //     });
    // };

    // useEffect(() => {
    //     getNotificationData();
    // }, []); // eslint-disable-line

    const activeRoute = props.routes.filter((route) => {
        return route.label.replace(/\s/g, '').toLowerCase() === location.pathname.toLowerCase().replace(/\s/g, '').slice(1);
    });

    let items;
    if (location.pathname === '/') {
        items = [{ label: 'Dashboard' }];
    } else if (!activeRoute.length) {
        items = [{ label: '' }, { label: '' }];
    } else {
        items = [{ label: activeRoute[0].parent }, { label: activeRoute[0].label }];
    }

    const isStatic = () => {
        return props.menuMode === 'static';
    };

    const navigate = useNavigate();

    const logoutHandled = () => {
        navigate('/');
        sessionStorage.clear();
        localStorage.clear();
        window.location.reload();
    };

    const userInfo = sessionStorage.getItem('userDetails');
    const userParsed = JSON.parse(userInfo);

    const handledNotificationRoutes = (e, rowData) => {
        dispatch(notificationRemoveAction(rowData?.actionId));

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    mcRequestId: rowData?.mcPendingRequest
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        dispatch(handlePostForNotificationRequest(newData, `/workflow/v1/notification/notificationviewed`, false, false));

        op.current.toggle(e);

        e.preventDefault();
        if (rowData?.tableName === 'TBL_COMMISSION_PROFILE') {
            navigate(
                `${rowData?.commFFEditDetailUrl || rowData?.commPFEditDetailUrl || rowData?.commSFEditDetailUrl || rowData?.commSPEditDetailUrl || rowData?.commSHEditDetailUrl}?refTableId=${rowData?.refTableId}&requestType=${
                    rowData?.requestType
                }&mcRequestId=${rowData?.mcRequestId}&mcPendingRequest=${rowData?.mcPendingRequest}&requestTypeDescr=${rowData?.requestTypeDescr}&tableName=${rowData?.tableName}`
            );
        } else if (rowData?.tableName === 'TBL_PRICING_PROFILE') {
            navigate(
                `${rowData?.pricFFEditDetailUrl || rowData?.pricPFEditDetailUrl || rowData?.pricSFEditDetailUrl || rowData?.pricSPEditDetailUrl || rowData?.pricCFEditDetailUrl || rowData?.pricSHEditDetailUrl}?refTableId=${
                    rowData?.refTableId
                }&requestType=${rowData?.requestType}&mcRequestId=${rowData?.mcRequestId}&mcPendingRequest=${rowData?.mcPendingRequest}&requestTypeDescr=${rowData?.requestTypeDescr}&tableName=${rowData?.tableName}`
            );
        } else {
            navigate(
                `${rowData?.editDetailUrl}?refTableId=${rowData?.refTableId}&requestType=${rowData?.requestType}&mcRequestId=${rowData?.mcRequestId}&mcPendingRequest=${rowData?.mcPendingRequest}&requestTypeDescr=${rowData?.requestTypeDescr}&tableName=${rowData?.tableName}`
            );
        }
    };
    return (
        <>
            <div className="layout-breadcrumb-container">
                <div className="layout-breadcrumb-left-items">
                    {isStatic() && (
                        <button className="menu-button p-link" onClick={props.onMenuButtonClick}>
                            <i className="pi pi-bars"></i>
                        </button>
                    )}
                    <BreadCrumb model={items} className="layout-breadcrumb" />
                </div>
                <div className="Header_items">
                    <p style={{ fontWeight: '500', marginTop: '0px', marginBottom: '0px' }}>
                        {userParsed?.firstName} {userParsed?.lastName}
                    </p>
                    {/* <button tabIndex="0" className="search-icon p-link" onClick={props.breadcrumbClick}>
                    <i className="pi pi-search"></i>
                </button> */}
                    {/* <div
                    className={classNames('search-wrapper', {
                        'active-search-wrapper': props.searchActive
                    })}
                >
                    <span className="p-input-icon-left">
                        <i className="pi pi-search"></i>
                        <InputText placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} onClick={props.onInputClick} />
                    </span>
                </div> */}
                    {/* <span className="layout-rightmenu-button-desktop">
                    <Button label="Today" icon="pi pi-bookmark" className="layout-rightmenu-button" onClick={props.onRightMenuButtonClick}></Button>
                </span> */}
                    {/* <div className="search_section">
                        <i className="pi pi-search"></i>
                        <input placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
                    </div> */}
                    <div className="details_section">
                        <i className="pi pi-bell p-overlay-badge" style={{ cursor: 'pointer' }} onClick={(e) => op.current.toggle(e)}>
                            {/* onClick={(e) => op.current.toggle(e)} */}
                            {notificationData?.length > 0 && notificationData?.map((value, index) => <Badge key={index} value={index + 1} style={{ fontSize: '0.8rem', backgroundColor: '#ff7170' }} />)}

                            {/* {notificationData?.length === 0 && notificationValue?.map((value, index) => <Badge key={index} value={index + 1} style={{ fontSize: '0.8rem', backgroundColor: '#ff7170' }} />)} */}
                        </i>
                        {/* <img src="assets/layout/images/profile-image.png" alt="avatar" style={{ width: '35px', height: '35px' }} /> */}
                        <i className="pi pi-power-off" onClick={logoutHandled}></i>
                    </div>
                    {/* <span className="layout-rightmenu-button-mobile">
                        <Button icon="pi pi-bookmark" className="layout-rightmenu-button" onClick={props.onRightMenuButtonClick}></Button>
                    </span> */}
                </div>
            </div>
            <OverlayPanel ref={op} id="overlay_panel" style={{ width: '30rem', backgroundColor: 'gray', left: '-20' }} className="overlaypanel-demo">
                <div
                    style={{
                        maxHeight: '300px',
                        overflowY: 'auto',
                        scrollbarWidth: 'thin' /* For Firefox */,
                        scrollbarColor: '#fff gray' /* For Firefox */
                    }}
                >
                    {notificationData?.length > 0 &&
                        notificationData?.map((value, index) => {
                            return (
                                <div className="card flex" key={index} onClick={(e) => handledNotificationRoutes(e, value)} style={{ backgroundColor: '#509793', color: '#fff', cursor: 'pointer', marginRight: '5px' }}>
                                    <label>
                                        <span className="textColor">
                                            {value?.userName} have received a new {value?.requestTypeDescr} against action id: {value?.actionId}
                                        </span>
                                        {/* <span className="text-sm">finally approved your code successfully!</span> */}
                                    </label>
                                </div>
                            );
                        })}
                </div>
                {/* {notificationData?.length === 0 &&
                    notificationValue?.map((value, index) => {
                        return (
                            <div className="card flex" key={index} onClick={(e) => handledNotificationRoutes(e, value)} style={{ backgroundColor: '#ab6b0f', color: '#fff', cursor: 'pointer' }}>
                                <label>
                                    <span className="textColor">
                                        {value?.userName} have received a new {value?.requestTypeDescr} against action id: {value?.actionId}
                                    </span>
                                </label>
                            </div>
                        );
                    })} */}

                {/* <div className="card flex " style={{ backgroundColor: '#ab6b0f' }}>
                    <label htmlFor="">
                        <span className="textColor">Mareena </span>
                        <span className="text-sm">finally approved your code successfully!</span>
                    </label>
                </div>
                <div className="card flex ">
                    <label htmlFor="">
                        <span className="textColor">Mareena </span>
                        <span className="text-sm">finally approved your code successfully!</span>
                    </label>
                </div> */}
            </OverlayPanel>
        </>
    );
};
export default AppBreadcrumb;

import React, { useState } from 'react';
import ChangePasswordOTP from './ChangePasswordOTP';
import GenerateOTP from './GenerateOTP';
import SubmitOTP from './SubmitOTP';

const ForgetPasswordOTP = () => {
    const [submitOtpFlag, setsubmitOtpFlag] = useState(false);
    const [changepassotpFlag, setchangepassotpFlag] = useState(false);
    const [userDetails, setuserDetails] = useState('');

    const handleGenerateOtp = (data) => {
        setuserDetails(data);
        setsubmitOtpFlag(true);
    };

    const handleSubmitOtp = (data) => {
        setuserDetails(data);
        setchangepassotpFlag(true);
    };

    return (
        <>
            {submitOtpFlag === false ? <GenerateOTP handleGenerateOtp={handleGenerateOtp} /> : ''}
            {submitOtpFlag === true && changepassotpFlag === false ? <SubmitOTP handleSubmitOtp={handleSubmitOtp} userDetails={userDetails} /> : ''}
            {changepassotpFlag === true && submitOtpFlag === true ? <ChangePasswordOTP userDetails={userDetails} /> : ''}
        </>
    );
};

export default ForgetPasswordOTP;

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useRef, useState } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';

function CustomerLoanProfile() {
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        accountLevelName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
     const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const dataTableRef = useRef(null);
    const dispatch= useDispatch()
      const formik = useFormik({
        initialValues: {
            profileName: '',
            accountClassificationId: '',
            statusId: '',
            dateFrom: '',
            dateTo: '',
            createdBy: '',
            updatedBy: '',
            feeTypeId: ''
        },
        validate: (data) => {
            let errors = {};
            if (data.dateTo && !data.dateFrom) {
                errors.dateFrom = 'Date From is required when Date To is provided';
            }

            if (data.dateFrom && data.dateTo && data.dateFrom > data.dateTo) {
                errors.dateTo = 'Date To must be greater than or equal to Date From';
            }
            return errors;
        },

        onSubmit: async (data) => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            // const res = await dispatch(handlePostRequest(newData, '/pricingprofile/v1/pricingprofile/getallpricingprofiles', true, true));
            // if (res?.responseCode === '110000') {
            //     setPricingData(res?.payLoad);
            // }
            setloading(true);
            setloadingIcon('pi pi-search');
            dataTableRef.current.reset();
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const renderHeader = () => {
        return (
            <div className="Global__Header">
                {/* <h5 className="m-0">Type of Account List</h5> */}
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    {/* <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." /> */}
                </span>
            </div>
        );
    };
    const header = renderHeader();

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button tooltip="Edit" icon="pi pi-pencil" tooltipOptions={{ position: 'top' }} className="p-button-rounded p-button-primary" />
                    <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" className="p-button-rounded" style={{ backgroundColor: '#F2AC57', border: 'none' }} />
                </div>
            </>
        );
    };

    return (
        <>
             <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                         <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                   CNIC Number
                                </label>
                                <InputText
                                    id="profileName"
                                    placeholder="Enter profile name"
                                    name="profileName"
                                    value={formik?.values?.profileName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('profileName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('profileName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                    Borrower Type
                                </label>
                                <Dropdown
                                    id="accountClassificationId"
                                    placeholder="Select Account Type"
                                    options={[]}
                                    optionLabel="label"
                                    name="accountClassificationId"
                                    optionValue="lovId"
                                    value={formik.values.accountClassificationId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountClassificationId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('accountClassificationId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Account Created On
                                </label>
                                <InputText
                                    type='date'
                                    id="profileName"
                                    placeholder="Enter profile name"
                                    name="profileName"
                                    value={formik?.values?.profileName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('profileName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('profileName')}
                            </div>
                        </div>
                         <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="accountClassificationId"
                                    placeholder="Select Account Type"
                                    options={[]}
                                    optionLabel="label"
                                    name="accountClassificationId"
                                    optionValue="lovId"
                                    value={formik.values.accountClassificationId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountClassificationId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('accountClassificationId')}
                            </div>
                        </div>
                         <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="accountClassificationId"
                                    placeholder="Select Account Type"
                                    options={[]}
                                    optionLabel="label"
                                    name="accountClassificationId"
                                    optionValue="lovId"
                                    value={formik.values.accountClassificationId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountClassificationId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('accountClassificationId')}
                            </div>
                        </div>
                         <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                              <div className="Down__Btn" style={{justifyContent:'flex-start'}}>
                        <Button label="Search" icon={loadingIcon || ''} iconPos="left" className="Btn__Dark" style={{marginTop:'15px'}}/>
                    </div>
                            </div>
                        </div>
                    </div>
                    </form></div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            filters={filters}
                            globalFilterFields={['accountLevelName', 'createuser', 'createdate', 'lkpStatus.statusName']}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            // value={pricingData}
                        >
                            <Column field="pricingProfileName" header="Loan ID" />
                            <Column field="lkpFeeType.feeTypeDescr" header="Borrower Type" />
                            <Column field="createdBy" header="Loan Type" />
                            <Column field="pricingProfileName" header="Loan Product Category" />
                            <Column field="lkpFeeType.feeTypeDescr" header="Borrower Name" />
                            <Column field="createdBy" header="Max Limit" />
                            <Column field="pricingProfileName" header="Create Date & Time" />
                            <Column field="lkpFeeType.feeTypeDescr" header="Status" />
                            <Column field="createdBy" header="Created By" />
                            <Column header="Actions" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CustomerLoanProfile;

import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import { FilterMatchMode } from 'primereact/api';
import { handleGetRequest } from '../../services/GetTemplate';
import { handlePostRequest } from '../../services/PostTemplate';
import { Paginator } from 'primereact/paginator';
import ActiveInActiveDialog from '../../components/ActiveInActiveDialog/ActiveInActiveDialog';
import { SearchButton, ResetButton } from '../../components/SearchButton';
import { PaginationCall, PaginationTemplate } from '../../components/Pagination';

function LendingManagement() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [lendingData, setLendingData] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [categoryLov, setCategoryLov] = useState([]);
    const [productCategoryLov, setProductCategoryLov] = useState([]);
    const [customFirst1, setCustomFirst1] = useState(0);
    const [customRows1, setCustomRows1] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [render, setRender] = useState(false);

    const [showModal, setShowModal] = useState(false);

    const [lmsProductIdData, setLmsProductIdData] = useState();
    const [isActiveData, setIsActiveData] = useState();

    const dataTableRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(resp?.payLoad);
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };

    const getCategoryLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/lending/LKP_LMS_CATEGORY');
        setCategoryLov(res?.payLoad);
    };

    const getProductCategoryLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/lending/LKP_LMS_PRODUCT_CATEGORY');
        setProductCategoryLov(res?.payLoad);
    };

    useEffect(() => {
        getStatusLov();
        getUserLov();
        getCategoryLov();
        getProductCategoryLov();
    }, []);

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            productId: '',
            dateFrom: '',
            dateTo: '',
            statusId: '2',
            createdBy: '',
            updatedBy: '',
            productName: '',
            category: '',
            productCategory: '',
            rowStart: customFirst1,
            rowEnd: customRows1 + customFirst1
        },
        validate: (data) => {
            let errors = {};
            if (data.dateTo && !data.dateFrom) {
                errors.dateFrom = 'Date From is required when Date To is provided';
            }

            if (data.dateFrom && data.dateTo && data.dateFrom > data.dateTo) {
                errors.dateTo = 'Date To must be greater than or equal to Date From';
            }
            return errors;
        },
        onSubmit: async (data) => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            dataTableRef.current.reset();

            const res = await dispatch(handlePostRequest(newData, '/lending/v1/lending/getallproducts', true, true));

            if (res?.responseCode === process.env.REACT_APP_LENDING_SUCCESS_CODE) {
                setLendingData(res?.payLoad);
            } else {
                setLendingData([]);
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const EditLending = (rowData) => {
        if (rowData.isDraft === 'Y') {
            navigate('/addlendingmanagement', { state: { lmsProductId: rowData?.lmsProductId } });
        } else {
            navigate(`/editlendingmanagement?lmsProductId=${rowData?.lmsProductId}`);
        }
    };

    const ViewLending = (rowData) => {
        navigate(`/viewlendingmanagement?lmsProductId=${rowData?.lmsProductId}`);
    };

    const handleSwitchChange = async () => {
        const updatedData = lendingData.map((item) => {
            if (item.lmsProductId === lmsProductIdData) {
                const isActive = item.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setLendingData(updatedData);
        let isactive = '';
        if (isActiveData === 'Y') {
            isactive = 'N';
        } else if (isActiveData === 'N') {
            isactive = 'Y';
        }
        const previousValue = isActiveData;

        const data = {
            isActive: isactive,
            lmsProductId: lmsProductIdData
        };
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/lending/v1/lending/inactiveproduct', true, false));
        if (res?.responseCode !== process.env.REACT_APP_LENDING_SUCCESS_CODE) {
            const revertedData = lendingData.map((item) => {
                if (item.lmsProductId === lmsProductIdData) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setLendingData(revertedData);
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <InputSwitch
                        checked={rowData.isActive === 'Y'}
                        disabled={['Pending', 'Rejected'].includes(rowData?.statusDesc) ? true : false}
                        onChange={() => {
                            setShowModal(true);
                            setLmsProductIdData(rowData?.lmsProductId);
                            setIsActiveData(rowData?.isActive);
                        }}
                    />
                    <Button
                        tooltip="Edit"
                        icon="pi pi-pencil"
                        disabled={(['Pending', 'Rejected'].includes(rowData?.statusDesc) && rowData.isDraft === 'N') || (rowData?.statusDesc === 'Approved' && rowData?.isActive === 'N') ? true : false}
                        tooltipOptions={{ position: 'top' }}
                        onClick={() => EditLending(rowData)}
                        className="p-button-rounded p-button-primary"
                    />
                    <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => ViewLending(rowData)} className="p-button-rounded" style={{ backgroundColor: '#F2AC57', border: 'none' }} />
                </div>
            </>
        );
    };
    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const statusTemplate = (rowData) => {
        const statusColor = rowData?.statusDesc === 'Approved' ? '#14A38B' : rowData?.statusDesc === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.isDraft === 'Y' ? 'Draft' : rowData?.statusDesc}</b>
            </p>
        );
    };

    const onCustomPageChange1 = (event) => {
        setCustomFirst1(event.first);
        setCustomRows1(event.rows);
        setCurrentPage(event.page + 1);
        setRender(true);
    };

    const handleShowModal = () => {
        setShowModal(false);
    };

    const props = {
        showModal,
        handleShowModal,
        handleSwitchChange
    };

    const RequestData = {
        productId: formik.values.productId,
        dateFrom: formik.values.dateFrom,
        dateTo: formik.values.dateTo,
        statusId: formik.values.statusId,
        createdBy: formik.values.createdBy,
        updatedBy: formik.values.updatedBy,
        productName: formik.values.productName,
        category: formik.values.category,
        productCategory: formik.values.productCategory,
        rowStart: customFirst1,
        rowEnd: customRows1 + customFirst1
    };

    return (
        <>
            <ActiveInActiveDialog {...props} />
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={() => navigate('/addlendingmanagement')} className="Btn__Add__" icon="pi pi-plus" iconPos="left" style={{ width: 'auto' }} />
                </div>
            </div>
            <PaginationCall
                render={render}
                customFirst1={customFirst1}
                customRows1={customRows1}
                currentPage={currentPage}
                dataTableRef={dataTableRef}
                setData={setLendingData}
                RequestData={RequestData}
                url={'/lending/v1/lending/getallproducts'}
                responseCode={process.env.REACT_APP_LENDING_SUCCESS_CODE}
            />
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="productId" className={classNames({ 'p-error': isFormFieldValid('productId') }, 'Label__Text')}>
                                    Product ID<span className="Label__Required"></span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="productId"
                                    placeholder="Enter Product ID"
                                    name="productId"
                                    keyfilter="int"
                                    value={formik?.values?.productId?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productId') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('productId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="productName" className={classNames({ 'p-error': isFormFieldValid('productName') }, 'Label__Text')}>
                                    Product Name<span className="Label__Required"></span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="productName"
                                    placeholder="Enter Product Name"
                                    name="productName"
                                    value={formik?.values?.productName?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('productName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="category" className={classNames({ 'p-error': isFormFieldValid('category') }, 'Label__Text')}>
                                    Category
                                </label>
                                <Dropdown
                                    id="category"
                                    placeholder="Select Category"
                                    options={categoryLov}
                                    optionLabel="name"
                                    name="category"
                                    optionValue="lovId"
                                    value={formik.values.category || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('category') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="productCategory" className={classNames({ 'p-error': isFormFieldValid('productCategory') }, 'Label__Text')}>
                                    Product Category
                                </label>
                                <Dropdown
                                    id="productCategory"
                                    placeholder="Select Product Category"
                                    options={productCategoryLov}
                                    optionLabel="name"
                                    name="productCategory"
                                    optionValue="lovId"
                                    value={formik.values.productCategory || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productCategory') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('productCategory')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Updated By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText
                                    max={previousDate}
                                    id="dateFrom"
                                    placeholder=""
                                    name="dateFrom"
                                    type="date"
                                    value={formik?.values?.dateFrom?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText
                                    max={previousDate}
                                    id="dateTo"
                                    placeholder=""
                                    name="dateTo"
                                    type="date"
                                    value={formik?.values?.dateTo?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="statusId" className={classNames({ 'p-error': isFormFieldValid('statusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select Status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <SearchButton />
                        <ResetButton onClick={handleReset} />
                    </div>
                </form>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <div>
                            <h4 style={{ color: '#8A8EA6' }}>Existing Detail</h4>
                        </div>
                        <DataTable
                            ref={dataTableRef}
                            className="datatable-responsive"
                            globalFilterFields={['productId', 'productName', 'productCategory', 'createdDate&Time', 'category', 'maxLimit', 'minLimit']}
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={lendingData}
                        >
                            <Column field="lmsProductId" header="Product ID" />
                            <Column field="lmsProductName" header="Product Name" />
                            <Column field="lmsProductCategoryName" header="Product Category" />
                            <Column header="Created Date & Time" body={createdDateAndTimeBodyTemplate} />
                            <Column field="lmsCategoryName" header="Category" />
                            <Column field="updateBy" header="Updated By" />
                            <Column field="maxAmount" header="Max Limit" />
                            <Column field="minAmount" header="Min Limit" />
                            <Column header="Status" body={statusTemplate} />
                            <Column header="Actions" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                        <Paginator template={PaginationTemplate(false)} first={customFirst1} rows={customRows1} totalRecords={lendingData?.[0]?.totalCount} onPageChange={onCustomPageChange1}></Paginator>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LendingManagement;

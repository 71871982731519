import React from 'react'
import { Divider } from 'primereact/divider'
import { InputText } from 'primereact/inputtext'

const HraLimits = ({ rowData, userTabsFieldData }) => {

    const checkFieldPermissions = (fieldName) => {
        const field = userTabsFieldData && userTabsFieldData.find(f => f.fieldName === fieldName);
        return {
            viewAllowed: field?.viewAllowed === "Y",
            updateAllowed: field?.updateAllowed === "Y"
        };
    };

    const maximumBalanceFieldPermissions = checkFieldPermissions("maximumBalance");

    return (
        <>
            <div className='mt-6'>
                <h5 className="secondary-color">
                    <b>HRA LIMITS</b>
                </h5>
                <Divider />
            </div>

            <div>
                <div className="p-fluid p-formgrid grid">

                    {/* <div className="p-field col-12 md:col-4">
                        <label className='Label__Text'>
                            Limits
                        </label>
                        <InputText
                            id="fullName"
                            // value={formik.values.fullName ?? ""}
                            // onChange={formik.handleChange}
                            className="Input__Round mr-2"
                            disabled
                        />
                    </div> */}
                    {maximumBalanceFieldPermissions.viewAllowed && (
                        <div className="p-field col-12 md:col-4">
                            <label className='Label__Text'>
                                Maximum Balance
                            </label>
                            <InputText
                                placeholder={rowData?.maximumBalance}
                                className="Input__Round"
                                disabled
                            />
                        </div>
                    )}
                </div>
            </div>

        </>
    )
}

export default HraLimits
import React, { useState, useEffect } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { useFormik } from 'formik';
import { handlePostRequest } from '../../../services/PostTemplate';
import { Button } from 'primereact/button';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

const AddEditModule = ({ onHide, editable, moduleRowData, getModule }) => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-save');
    const moduleId = moduleRowData.moduleId;

    const dispatch = useDispatch();

    useEffect(() => {
        if (moduleRowData !== undefined || moduleRowData !== null) {
            formik.setFieldValue('moduleDescr', moduleRowData.moduleDescr);
        }
    }, [moduleRowData]); // eslint-disable-line

    useEffect(() => {
        if (!editable) {
            formik.resetForm();
        }
    }, [editable]); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        moduleDescr: Yup.string().required('This field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            moduleDescr: ''
        },

        validate: (data) => {
            let errors = {};

            ////////////////////////ModuleDescription/////////////////////
            if (!data?.moduleDescr) {
                errors.moduleDescr = 'This field is required';
            } else if (data?.moduleDescr.length < 3) {
                errors.moduleDescr = 'Minimum length should be 3 characters';
            } else if (data?.moduleDescr === '') {
                errors.moduleDescr = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.moduleDescr)) {
                errors.moduleDescr = 'Leading and trailing space is not allowed';
            } else if (!/^[a-zA-Z0-9\s]*$/.test(data?.moduleDescr)) {
                errors.moduleDescr = 'Special characters are not allowed';
            }

            return errors;
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            if (editable === true) {
                data['moduleId'] = moduleId;
                const res = await dispatch(handlePostRequest(newData, '/mfs_user_management/v1/management/updateModule', true, true));
                if (res?.responseCode === '030000') {
                    await getModule();
                    onHide();
                    formik.resetForm();
                }
            } else {
                const res = await dispatch(handlePostRequest(newData, '/mfs_user_management/v1/management/saveModule', true, true));
                if (res?.responseCode === '030000') {
                    onHide();
                    await getModule();
                    formik.resetForm();
                }
            }
            setloading(false);
            setloadingIcon('pi pi-save');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="moduleDescr" className={classNames({ 'p-error': isFormFieldValid('moduleDescr') }, 'Label__Text')}>
                                Description<span className="Label__Required">*</span>
                            </label>
                            <InputTextarea
                                placeholder="Add Description"
                                id="moduleDescr"
                                name="moduleDescr"
                                value={formik?.values?.moduleDescr || ''}
                                rows={5}
                                cols={10}
                                maxLength={50}
                                autoResize="false"
                                // onChange={formik.handleChange}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const moduleDescr = value.replace(/\s+/g, ' ');
                                    formik.handleChange({ target: { name: e.target.name, value: moduleDescr } });
                                }}
                                className={classNames({ 'p-invalid': isFormFieldValid('moduleDescr') }, 'TextArea__Round')}
                            />
                            {getFormErrorMessage('moduleDescr')}
                        </div>
                    </div>
                    <div className="Down__Btn__End">
                        <Button disabled={loading} loadingIcon={loadingIcon} label={editable ? 'Update' : 'Add'} className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
};

export default AddEditModule;

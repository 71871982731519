import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import * as Yup from 'yup';
import { useFormik } from 'formik';

const EditCardLimits = ({ onHide, indLimits, handleCardLimitsCallBack }) => {
    const [disableSubmit, setDisableSubmit] = useState(true);

    const borderedBox = {
        border: '1px solid #e6e6e6',
        padding: '20px'
    };

    const ValidationSchema = Yup.object().shape({
        // maxOnCount: Yup.string().nullable().required('This field is required'),
        // onLowerLimit: Yup.string().nullable().required('This field is required'),
        // onUpperLimit: Yup.string().nullable().required('This field is required'),
        onTrxCount: Yup.string().nullable().required('This field is required'),
        onTrxAmount: Yup.string().nullable().required('This field is required')
        // maxOffCount: Yup.string().nullable().required('This field is required'),
        // offLowerLimit: Yup.string().nullable().required('This field is required'),
        // offUpperLimit: Yup.string().nullable().required('This field is required'),
        // offTrxCount: Yup.string().nullable().required('This field is required'),
        // offTrxAmount: Yup.string().nullable().required('This field is required')
    });

    const formik = useFormik({
        validationSchema: ValidationSchema,
        initialValues: {
            // maxOnCount: indLimits?.maxOffCount,
            // onLowerLimit: indLimits?.offLowerLimit,
            // onUpperLimit: indLimits?.onUpperLimit,
            maxOnCount: '0',
            onLowerLimit: '0',
            onUpperLimit: '0',
            onTrxCount: indLimits?.onTrxCount,
            onTrxAmount: indLimits?.onTrxAmount,
            maxOffCount: '0',
            offLowerLimit: '0',
            offUpperLimit: '0',
            offTrxCount: '0',
            offTrxAmount: '0'
            // maxOffCount: indLimits?.maxOffCount,
            // offLowerLimit: indLimits?.offLowerLimit,
            // offUpperLimit: indLimits?.offUpperLimit,
            // offTrxCount: indLimits?.offTrxCount,
            // offTrxAmount: indLimits?.offTrxAmount
        },

        onSubmit: async () => {
            const isCardLimitsUpdateReq = !disableSubmit;
            const newValues = {
                ...formik.values,
                isCardLimitsUpdateReq: isCardLimitsUpdateReq
            };
            handleCardLimitsCallBack(newValues);
            onHide();
        }
    });

    useEffect(() => {
        const hasChanges = Object.keys(formik.values).some((key) => formik.values[key] !== indLimits[key]);
        setDisableSubmit(!hasChanges);
    }, [formik.values, indLimits]);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <section className="Card__Round pt-5">
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid grid">
                    <div className="col-12 md:col-6">
                        <div style={borderedBox}>
                            <h6 className="text-center">On Us Transaction Limit</h6>
                            <Divider />
                            <div className="p-fluid p-formgrid grid">
                                <div className="p-field col-12 md:col-6">
                                    <label className="Label__Text">Max - On-Count</label>
                                    {/* <span className="Label__Required">*</span> */}
                                    <InputText disabled maxLength={5} id="maxOnCount" value={formik.values.maxOnCount} onChange={formik.handleChange} className="Input__Round" keyfilter={/^[0-9]+$/} />
                                    {getFormErrorMessage('maxOnCount')}
                                </div>
                                <div className="p-field col-12 md:col-6">
                                    <label className="Label__Text">On Lower Limit</label>
                                    {/* <span className="Label__Required">*</span> */}
                                    <InputText disabled maxLength={15} id="onLowerLimit" value={formik.values.onLowerLimit} onChange={formik.handleChange} className="Input__Round" keyfilter={/^[0-9]+$/} />
                                    {getFormErrorMessage('onLowerLimit')}
                                </div>
                                <div className="p-field col-12 md:col-6">
                                    <label className="Label__Text">On Upper Limit</label>
                                    {/* <span className="Label__Required">*</span> */}
                                    <InputText disabled maxLength={15} id="onUpperLimit" value={formik.values.onUpperLimit} onChange={formik.handleChange} className="Input__Round" keyfilter={/^[0-9]+$/} />
                                    {getFormErrorMessage('onUpperLimit')}
                                </div>
                                <div className="p-field col-12 md:col-6">
                                    <label className="Label__Text">On Trx Count</label> <span className="Label__Required">*</span>
                                    <InputText maxLength={10} id="onTrxCount" value={formik.values.onTrxCount} onChange={formik.handleChange} className="Input__Round" keyfilter={/^[0-9]+$/} />
                                    {getFormErrorMessage('onTrxCount')}
                                </div>
                                <div className="p-field col-12 md:col-6">
                                    <label className="Label__Text">On Trx Amount</label> <span className="Label__Required">*</span>
                                    <InputText maxLength={15} id="onTrxAmount" value={formik.values.onTrxAmount} onChange={formik.handleChange} className="Input__Round" keyfilter={/^[0-9]+$/} />
                                    {getFormErrorMessage('onTrxAmount')}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 md:col-6">
                        <div style={borderedBox}>
                            <h6 className="text-center">Off Us Transaction Limit</h6>
                            <Divider />
                            <div className="p-fluid p-formgrid grid">
                                <div className="p-field col-12 md:col-6">
                                    <label className="Label__Text">Max - Off-Count</label>
                                    {/* <span className="Label__Required">*</span> */}
                                    <InputText disabled maxLength={5} id="maxOffCount" value={formik.values.maxOffCount} onChange={formik.handleChange} className="Input__Round" keyfilter={/^[0-9]+$/} />
                                    {getFormErrorMessage('maxOffCount')}
                                </div>
                                <div className="p-field col-12 md:col-6">
                                    <label className="Label__Text">Off Lower Limit</label>
                                    {/* <span className="Label__Required">*</span> */}
                                    <InputText disabled maxLength={15} id="offLowerLimit" value={formik.values.offLowerLimit} onChange={formik.handleChange} className="Input__Round" keyfilter={/^[0-9]+$/} />
                                    {getFormErrorMessage('offLowerLimit')}
                                </div>
                                <div className="p-field col-12 md:col-6">
                                    <label className="Label__Text">Off Upper Limit</label>
                                    {/* <span className="Label__Required">*</span> */}
                                    <InputText disabled maxLength={15} id="offUpperLimit" value={formik.values.offUpperLimit} onChange={formik.handleChange} className="Input__Round" keyfilter={/^[0-9]+$/} />
                                    {getFormErrorMessage('offUpperLimit')}
                                </div>
                                <div className="p-field col-12 md:col-6">
                                    <label className="Label__Text">Off Trx Count</label>
                                    {/* <span className="Label__Required">*</span> */}
                                    <InputText disabled maxLength={10} id="offTrxCount" value={formik.values.offTrxCount} onChange={formik.handleChange} className="Input__Round" keyfilter={/^[0-9]+$/} />
                                    {getFormErrorMessage('offTrxCount')}
                                </div>
                                <div className="p-field col-12 md:col-6">
                                    <label className="Label__Text">Off Trx Amount</label>
                                    {/* <span className="Label__Required">*</span> */}
                                    <InputText disabled maxLength={15} id="offTrxAmount" value={formik.values.offTrxAmount} onChange={formik.handleChange} className="Input__Round" keyfilter={/^[0-9]+$/} />
                                    {getFormErrorMessage('offTrxAmount')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Down__Btn mt-4">
                    <Button label="Update" iconPos="right" className="Btn__Dark" type="submit" disabled={disableSubmit} />
                    <Button label="Cancel" iconPos="right" className="Btn__cancel" type="button" onClick={onHide} />
                </div>
            </form>
        </section>
    );
};

export default EditCardLimits;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { MultiSelect } from 'primereact/multiselect';
import { SlabWisePercentageInitialValues, validationSchema } from '../Components/SlabWisePercentageIntialValues';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewSlabWisePercentage() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [allLovs, setAllLovs] = useState('');

    const [transactions, setTransactions] = useState([]);
    const [productListData, setProductListData] = useState([]);

    const navigate = useNavigate();

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate()); // Subtract 1 day
    const previousDate = currentDate.toISOString().split('T')[0];

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const commissionId = query.get('commissionId');
    const tableName = 'TBL_COMMISSION_PROFILE';

    const getDatabyId = async () => {
        const res = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getcommissionprofilebyid/${commissionId}`);
        const keyData = res?.payLoad;
        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik?.setFieldValue(key, keyData[key]);
                }
            });

            const multiselectDataProduct = keyData?.tblCommissionProducts?.map((item) => {
                return item?.tblProduct?.productId.toString(); // Convert to string
            });
            formik.setFieldValue('commissionSlabRequests', keyData?.tblCommissionSlabs);
            formik.setFieldValue('disbursementMethodName', keyData?.lkpDisbursementMethod?.disbursementMethodName?.toString());
            formik.setFieldValue('accountClassificationId', keyData?.lkpAccountClassification?.accountClassificationId.toString());
            formik.setFieldValue('commissionGlAccountId', keyData?.tblAccount1.accountId.toString());
            formik.setFieldValue('whtGlAccountId', keyData?.tblAccount2.accountId.toString());
            formik.setFieldValue('startDate', keyData?.startDate?.slice(0, 10));
            formik.setFieldValue('endDate', keyData?.endDate?.slice(0, 10));

            if (multiselectDataProduct && multiselectDataProduct.length > 0) {
                formik.setFieldValue('products', multiselectDataProduct);
            }
        }
    };
    useEffect(() => {
        getDatabyId();
    }, []); // eslint-disable-line

    const getAllLovsForCommission = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcommissionprofiledata');
        setAllLovs(resp?.payLoad);
        setTransactions(resp?.payLoad?.transactions);
    };

    useEffect(() => {
        getAllLovsForCommission();
    }, []); // eslint-disable-line

    const accounttype = allLovs?.accounttype?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });

    const glAccounts = allLovs?.glAccounts?.map((data) => {
        return {
            accountId: data?.accountId,
            accountNo: `${data?.accountNo}-${data?.accountTitle}`
        };
    });
    const disbursementMethod = allLovs?.disbursementMethod?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: SlabWisePercentageInitialValues,

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['commissionProfileId'] = commissionId;
            data['feeTypeCode'] = 'SP';
            data['products'] = productListData;

            const commissionSlabRequestsArray = data?.commissionSlabRequests.map((item) => ({
                commissionPercentage: item.commissionPercentage,
                commissionSlabId: item.commissionSlabId,
                endSlab: item.endSlab,
                startSlab: item.startSlab
            }));
            data['commissionSlabRequests'] = commissionSlabRequestsArray;

            setloading(false);
            setloadingIcon('');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const addSlabWisePercentage = () => {
        formik.setFieldValue('commissionSlabRequests', [
            ...formik.values.commissionSlabRequests,
            {
                startSlab: '',
                endSlab: '',
                commissionPercentage: ''
            }
        ]);
    };
    const removeSlabWisePercentage = (index) => {
        const updatedData = [...formik.values.commissionSlabRequests];
        updatedData.splice(index, 1);
        formik.setFieldValue('commissionSlabRequests', updatedData);
    };
    const WhtOption = [
        { name: 'INCLUSIVE', value: 'I' },
        { name: 'EXCLUSIVE', value: 'E' }
    ];

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>EDIT DETAIL</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                    Account Type
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="accountClassificationId"
                                    placeholder="Select Account Type"
                                    options={accounttype}
                                    optionLabel="name"
                                    name="accountClassificationId"
                                    optionValue="lovId"
                                    value={formik.values.accountClassificationId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountClassificationId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('accountClassificationId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="commissionProfileName" className={classNames({ 'p-error': isFormFieldValid('commissionProfileName') }, 'Label__Text')}>
                                    Profile Name <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="commissionProfileName"
                                    placeholder="Enter Profile Name"
                                    name="commissionProfileName"
                                    optionLabel="name"
                                    value={formik?.values?.commissionProfileName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('commissionProfileName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('commissionProfileName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="products" className={classNames({ 'p-error': isFormFieldValid('products') }, 'Label__Text')}>
                                    Transactions
                                </label>
                                <MultiSelect
                                    filter
                                    // disabled={true}
                                    id="products"
                                    placeholder="Select Transaction"
                                    options={transactions}
                                    optionLabel="name"
                                    name="products"
                                    optionValue="lovId"
                                    value={formik.values.products || []}
                                    // onChange={(e) => {
                                    //     formik.handleChange(e);
                                    //     newProductData(e);
                                    // }}
                                    className={classNames({ 'p-invalid': isFormFieldValid('products') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('products')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="whtCalculationType" className={classNames({ 'p-error': isFormFieldValid('whtCalculationType') }, 'Label__Text')}>
                                    Type of WHT Calculation
                                    <span className="Label__Required"></span>
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="whtCalculationType"
                                    placeholder="Select Type of Fee Calculation"
                                    options={WhtOption}
                                    optionLabel="name"
                                    name="whtCalculationType"
                                    optionValue="value"
                                    value={formik.values.whtCalculationType}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('whtCalculationType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('whtCalculationType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3">
                            <div className="p-field">
                                <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') }, 'Label__Text')}>
                                    Start Date
                                </label>
                                <InputText
                                    max="9999-12-31"
                                    min={previousDate}
                                    disabled={true}
                                    id="startDate"
                                    placeholder=""
                                    name="startDate"
                                    type="date"
                                    value={formik?.values?.startDate?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('startDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('startDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3">
                            <div className="p-field">
                                <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') }, 'Label__Text')}>
                                    End Date
                                </label>
                                <InputText
                                    min={previousDate}
                                    max="9999-12-31"
                                    disabled={true}
                                    id="endDate"
                                    placeholder=""
                                    name="endDate"
                                    type="date"
                                    // max={currentDate}
                                    value={formik?.values?.endDate?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('endDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('endDate')}
                            </div>
                        </div>
                        <>
                            <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card">
                                <div className="Form__Header">
                                    <h1>SLAB WISE PERCENTAGE </h1>
                                </div>
                                {formik?.values?.commissionSlabRequests?.map((id, index) => (
                                    <div key={`id-${index}`} style={{ backgroundColor: '#FFFFFF', padding: '3rem' }} className="card">
                                        <div className="grid">
                                            <div className="col-3">
                                                <div className="p-field pt-3 pb-3">
                                                    <label htmlFor={`startSlab${index}`} className={classNames({ 'p-error': isFormFieldValid(`[commissionSlabRequests${index}].startSlab`) }, 'Label__Text')}>
                                                        Start slab <span className="Label__Required"></span>
                                                    </label>
                                                    <InputText
                                                        disabled={true}
                                                        id={`startSlab${index}`}
                                                        placeholder="Enter"
                                                        name={`commissionSlabRequests[${index}].startSlab`}
                                                        value={id.startSlab || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`commissionSlabRequests[${index}].startslab`) }, 'Input__Round')}
                                                    />
                                                    {getFormErrorMessage(`commissionSlabRequests[${index}].startslab`)}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="p-field pt-3 pb-3">
                                                    <label htmlFor={`endslab${index}`} className={classNames({ 'p-error': isFormFieldValid(`[commissionSlabRequests${index}].endslab`) }, 'Label__Text')}>
                                                        End slab <span className="Label__Required"></span>
                                                    </label>
                                                    <InputText
                                                        disabled={true}
                                                        id={`endSlab${index}`}
                                                        placeholder="Enter"
                                                        name={`commissionSlabRequests[${index}].endSlab`}
                                                        // value={formik?.values?.fixedCommision[index]?.percentageoftransaction || ''}
                                                        value={id.endSlab || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`commissionSlabRequests[${index}].endSlab`) }, 'Input__Round')}
                                                    />
                                                    {getFormErrorMessage(`commissionSlabRequests[${index}].endSlab`)}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="p-field pt-3 pb-3">
                                                    <label htmlFor={`commissionPercentage${index}`} className={classNames({ 'p-error': isFormFieldValid(`[commissionSlabRequests${index}].commissionpercentage`) }, 'Label__Text')}>
                                                        Commission Percentage <span className="Label__Required"></span>
                                                    </label>
                                                    <InputText
                                                        disabled={true}
                                                        id={`commissionPercentage${index}`}
                                                        placeholder="Enter"
                                                        name={`commissionSlabRequests[${index}].commissionPercentage`}
                                                        // value={formik?.values?.fixedCommision[index]?.percentageoftransaction || ''}
                                                        value={id.commissionPercentage || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid(`commissionSlabRequests[${index}].commissionPercentage`) }, 'Input__Round')}
                                                    />
                                                    {getFormErrorMessage(`commissionSlabRequests[${index}].commissionPercentage`)}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="flex flex-column  align-items-center justify-content-center">
                                                    <Button
                                                        disabled={true}
                                                        icon="pi pi-plus"
                                                        className="p-button-rounded"
                                                        aria-label="Filter"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            addSlabWisePercentage();
                                                        }}
                                                    />
                                                    <Button
                                                        disabled={true}
                                                        icon="pi pi-minus"
                                                        className="p-button-rounded mt-2 p-button-danger"
                                                        aria-label="Filter"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            removeSlabWisePercentage(index);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="flex flex-row">
                                    <div className="col-6 md:col-3">
                                        <div className="p-field pt-3 pb-3">
                                            <label htmlFor="commissionGlAccountId" className={classNames({ 'p-error': isFormFieldValid('commissionGlAccountId') }, 'Label__Text')}>
                                                Select Commission GL
                                                <span className="Label__Required"></span>
                                            </label>
                                            <Dropdown
                                                disabled={true}
                                                id="commissionGlAccountId"
                                                placeholder="Select Commission GL"
                                                options={glAccounts}
                                                optionLabel="accountNo"
                                                filter
                                                name="commissionGlAccountId"
                                                optionValue="accountId"
                                                value={formik.values.commissionGlAccountId || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('commissionGlAccountId') }, 'Dropdown__Round')}
                                            />

                                            {getFormErrorMessage('commissionGlAccountId')}
                                        </div>
                                    </div>
                                    <div className="col-6 md:col-3">
                                        <div className="p-field pt-3 pb-3">
                                            <label htmlFor="whtGlAccountId" className={classNames({ 'p-error': isFormFieldValid('whtGlAccountId') }, 'Label__Text')}>
                                                Select WHT GL
                                                <span className="Label__Required"></span>
                                            </label>
                                            <Dropdown
                                                disabled={true}
                                                id="whtGlAccountId"
                                                placeholder="Select WHT GL"
                                                options={glAccounts}
                                                optionLabel="accountNo"
                                                name="whtGlAccountId"
                                                filter
                                                optionValue="accountId"
                                                value={formik.values.whtGlAccountId || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('whtGlAccountId') }, 'Dropdown__Round')}
                                            />

                                            {getFormErrorMessage('whtGlAccountId')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="disbursementMethodName" className={classNames({ 'p-error': isFormFieldValid('disbursementMethodName') }, 'Label__Text')}>
                                    Select Disbursement Method
                                    <span className="Label__Required"></span>
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="disbursementMethodName"
                                    placeholder=" Select Disbursement Method"
                                    options={disbursementMethod}
                                    optionLabel="name"
                                    name="disbursementMethodName"
                                    optionValue="name"
                                    value={formik.values.disbursementMethodName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('disbursementMethodName') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('disbursementMethodName')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon || ''} onClick={() => navigate('/commission')} label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={commissionId} tableName={tableName} />
        </>
    );
}
export default ViewSlabWisePercentage;

import React from 'react';
import GenericComponent from '../Component/GenericComponent';

const AddDynamicRule = () => {
    return (
        <React.Fragment>
            <GenericComponent type="add" />
        </React.Fragment>
    );
};

export default AddDynamicRule;

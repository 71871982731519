import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputTextarea } from 'primereact/inputtextarea';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import classNames from 'classnames';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewCorporate({ databyId, apiData, isEditCase, mcRequestData, mcRequestId, mcPeindingRequestId, refTableId, tableName, requestType, requestTypeDescr }) {
    const [loading, setloading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [tableData, setTableData] = useState([]);
    const [pricingProfile, setPricingProfile] = useState([]);
    const [active, setActive] = useState(false);
    const [commissionProfile, setCommissionProfile] = useState([]);
    const [employeeName, setEmployeeName] = useState('');
    const [limitProfile, setLimitProfile] = useState([]);
    const [channelOption, setChannelOption] = useState([]);
    const [parentAgentData, setParentAgentData] = useState([]);
    const [productcatalog, setProductcatalog] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [modeofoperations, setOperationMode] = useState([]);
    // const accountClassificationId = apiData.lovId;
    const accountClassificationName = apiData?.lkpAccountClassification?.accountClassificationName;
    const accountClassificationId = apiData?.lkpAccountClassification?.accountClassificationId;
    const [tblKycSetDetails, setTblKycSetDetails] = useState([]);
    const [nearestBranch, setNearestBranch] = useState([]);
    const [dFormValues, setDFormValues] = useState([]);
    const [isRequired, setIsRequired] = useState([]);
    const [kycSetHeadId, setKycSetHeadId] = useState();
    const [checkerPayload, setCheckerPayload] = useState([]);
    const [accountStatus, setAccountStatus] = useState();
    const chequeBookLeaves = [
        { name: '25', value: '25' },
        { name: '50', value: '50' },
        { name: '100', value: '100' },
        { name: '250', value: '250' }
    ];
    const kycFixed = ['name', 'address', 'ntn', 'contactPersonCnic', 'contactPersonMobileNumber', 'contactPersonCnicIssuanceDate'];

    useEffect(() => {
        if (requestTypeDescr == 'Enable/Disable Request' || requestType == 'I') {
            getCoperateEditData(databyId);
            if (requestType == 'I') {
                setActive(databyId?.isActive === 'Y' ? true : false);
            } else {
                setActive(mcRequestData?.isActive === 'Y' ? true : false);
            }
        } else {
            getCoperateEditData1(apiData);
            setActive(apiData?.isActive === 'Y' ? true : false);
        }
    }, [apiData, requestTypeDescr, requestType]);
    const validationSchema = Yup.object().shape({
        // Define the validation rules for your fields here
        action: Yup.string().required('Action should not be empty'),
        checkerComments: Yup.string().required('This Field is required').notOneOf([''], 'This Field should not be empty').max(500, 'Maximum 500 characters needed') // Additional check for not being empty
        // ... Add validation for other fields as needed
    });

    const formik = useFormik({
        initialValues: {
            pricingProfileId: '',
            commissionId: '',
            transLimitId: '',
            parentAgentId: '',

            channelIdList: [],

            empId: '',
            productCatalogId: '',
            chequeBook: false,
            noOfLeaves: '',
            waiver: 'Y',
            checkerComments: '',
            action: '',
            addressInfos: [
                {
                    addressId: '',
                    fullAddress: ''
                }
            ],
            accountTitle: '',
            accountNumber: '',
            clsVerified: '',
            createdate: '',
            isFiler: ''
        },
        validationSchema,

        onSubmit: async (data) => {
            setloading(true);
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        agentOnBoardingRequest: {
                            ...checkerPayload
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data?.checkerComments,
                            action: data?.action,
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            await dispatch(handlePostRequest(newData, '/account/v1/agentaccount/agentValidatorCheckerAction', true, false, 'requestchecker'));
        }
    });
    const formValues = formik.values;

    // You can access individual form field values like this:
    const dName = formValues.dName;
    const dCnic = formValues.dCnic;
    const dMobileNumber = formValues.dMobileNumber;
    const dobCNIC = formValues.dobCNIC;
    const searchEmployeeId = async () => {
        setEmployeeName('');
        const id = formik.values.empId;
        const res = await handleGetRequest(`/account/v1/sales/getemployeebyid/${id}`);
        setEmployeeName(res?.payLoad[0]?.employeeName);
    };

    const panelFooterTemplate = () => {
        const length = formik.values.channelIdList ? formik.values.channelIdList.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = res?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    //pricing profile dropdown options API
    const getPricingProfile = async () => {
        const res = await handleGetRequest(`/account/v1/agentaccount/getpricingprofilebyclassification/${accountClassificationId}`);

        setPricingProfile(res?.payLoad);
    };

    const getProductCatalog = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/productCatalog/TBL_PRODUCT_CATALOG:*');
        setProductcatalog(res?.payLoad);
    };
    //limit profile dropdown options API
    const getLimitProfile = async () => {
        const res = await handleGetRequest(`/account/v1/agentaccount/gettranslimitbyclassification/${accountClassificationId}`);
        setLimitProfile(res?.payLoad);
    };
    const getChannel = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchannels/LKP_CHANNEL:*');
        setChannelOption(res?.payLoad);
    };
    const getNearest = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getallbranches/LKP_BRANCH:*');
        setNearestBranch(res?.payLoad);
    };

    useEffect(() => {
        if (tblKycSetDetails && apiData?.kycView) {
            tblKycSetDetails?.forEach((item) => {
                if (requestTypeDescr == 'Enable/Disable Request' || requestType == 'I') {
                    formik.setFieldValue(item?.tblKycAttribute?.attributeName, JSON.parse(apiData?.kycView)[item?.tblKycAttribute?.attributeName]);
                } else {
                    if (kycFixed?.includes(item?.tblKycAttribute?.attributeName) || (item?.isMandatory === 'Y' && item?.isActive === 'Y')) {
                        formik.setFieldValue(item?.tblKycAttribute?.attributeName, apiData?.[item?.tblKycAttribute?.attributeName]);
                    }
                    if (mcRequestData?.additionalInformations) {
                        const kyc1 = mcRequestData?.additionalInformations;
                        kyc1?.forEach((item) => {
                            formik?.setFieldValue(item?.infoKey, item?.infoValue);
                        });
                    }
                }
            });
        }
    }, [tblKycSetDetails, apiData?.kycView]);

    const getKycFields = async () => {
        const res = await handleGetRequest(`/account/v1/agentaccount/getkycbyclassificationname/${accountClassificationName}`);

        setKycSetHeadId(res.payLoad[0].kycSetHeadId);
        let tblkyc = res?.payLoad[0].tblKycSetDetails;

        setTblKycSetDetails(tblkyc);
    };

    const getCoperateEditData = (apiData) => {
        Object.keys(apiData).forEach((key) => {
            if (formik.initialValues.hasOwnProperty(key)) {
                formik.setFieldValue(key, apiData[key]);
            }
        });

        // setCommissionProfile([{ commissionProfileId: apiData?.tblCommissionProfile?.commissionProfileId, commissionProfileName: apiData?.tblCommissionProfile?.commissionProfileName }]);
        // setPricingProfile([{ pricingProfileId: apiData?.tblPricingProfile.pricingProfileId, pricingProfileName: apiData?.tblPricingProfile.pricingProfileName.toString() }]);
        // setLimitProfile([{ transLimitId: apiData?.tblTransLimit.transLimitId, limitProfileName: apiData?.tblTransLimit.limitProfileName }]);
        setEmployeeName(apiData?.tblSalesHierarchy.hierarchyName);

        const channelOptions = apiData?.tblAgentChannelList.map((channel) => ({
            lovId: channel.lkpChannel.channelId.toString(),
            name: channel.lkpChannel.channelName
        }));

        // setProductcatalog([{ lovId: apiData?.tblAgentChannelList[0].tblProductCatalog.productCatalogId.toString(), name: apiData?.tblAgentChannelList[0].tblProductCatalog.projectCatalogName }]);

        // setChannelOption(channelOptions);
        let directorList = [];

        if (apiData.tblAgentDirectorList) {
            directorList = apiData.tblAgentDirectorList.map((res) => ({
                name: res.name,
                cnic: res.cnic,
                dobCNIC: res.cnicIssuanceDate,
                mobileNumber: res.mobileNo
            }));
        } else if (apiData.agentDirectorRequestList) {
            directorList = apiData.agentDirectorRequestList.map((res) => ({
                name: res?.name,
                cnic: res.cnic,
                dobCNIC: res.cnicIssuanceDate,
                mobileNumber: res.mobileNumber
            }));
        }
        setDFormValues(directorList);
        if (directorList?.length >= 2) {
            setOperationMode([{ name: 'Multiple', value: '2' }]);
            formik.setFieldValue('modeofoperations', '2');
        } else {
            setOperationMode([{ name: 'Single', value: '1' }]);
            formik.setFieldValue('modeofoperations', '1');
        }
        //       setNearestBranch([{lovId:apiData.lkpBranch.branchId.toString(),name:apiData.lkpBranch.branchName}])
        setParentAgentData([{ name: 'Zindagi', value: '1' }]);
        if (apiData?.kycView) {
            let kycView = JSON.parse(apiData?.kycView);

            Object.keys(kycView).forEach((key) => {
                formik.setFieldValue(key, kycView[key]);
            });
        }

        formik.setFieldValue('pricingProfileId', apiData?.tblPricingProfile?.pricingProfileId);
        formik.setFieldValue('commissionId', apiData?.tblCommissionProfile?.commissionProfileId);
        formik.setFieldValue('transLimitId', apiData?.tblTransLimit?.transLimitId);
        formik.setFieldValue('channelIdList', channelOptions);
        formik.setFieldValue('chequeBook', apiData?.chequeBook === 'Y');
        formik.setFieldValue('parentAgentId', '1');
        formik.setFieldValue('empId', apiData?.tblSalesForce?.employeeId);
        formik.setFieldValue('productCatalogId', apiData?.tblAgentChannelList[0]?.tblProductCatalog?.productCatalogId.toString());
        formik.setFieldValue('accountTitle', apiData?.accountTitle || '');
        formik.setFieldValue('accountNumber', apiData?.accountNumber || '');
        formik.setFieldValue('addressInfos', apiData?.addressInfos || '');
        formik.setFieldValue('clsVerified', apiData?.clsVerified);
        formik.setFieldValue('createdate', apiData?.createdate);
        formik.setFieldValue('isFiler', apiData?.isFiler);
        formik.setFieldValue('plus30', apiData?.tblEcibResponse?.plus30);
        formik.setFieldValue('plus60', apiData?.tblEcibResponse?.plus60);
        formik.setFieldValue('plus90', apiData?.tblEcibResponse?.plus90);
        formik.setFieldValue('plus120', apiData?.tblEcibResponse?.plus120);
        formik.setFieldValue('plus150', apiData?.tblEcibResponse?.plus150);
        formik.setFieldValue('plus180', apiData?.tblEcibResponse?.plus180);
        formik.setFieldValue('crpScore', apiData?.crpScore);
        formik.setFieldValue('crpRating', apiData?.crpRating);
    };
    //Irfan
    const getCoperateEditData1 = (apiData) => {
        Object.keys(apiData).forEach((key) => {
            if (formik.initialValues.hasOwnProperty(key)) {
                formik.setFieldValue(key, apiData[key]);
            }
        });

        let directorList = [];

        if (apiData.tblAgentDirectorList) {
            directorList = apiData.tblAgentDirectorList.map((res) => ({
                name: res.name,
                cnic: res.cnic,
                dobCNIC: res.cnicIssuanceDate,
                mobileNumber: res.mobileNo
            }));
        } else if (apiData.agentDirectorRequestList) {
            directorList = apiData.agentDirectorRequestList.map((res) => ({
                name: res?.name,
                cnic: res.cnic,
                dobCNIC: res.cnicIssuanceDate,
                mobileNumber: res.mobileNumber
            }));
        }
        setDFormValues(directorList);
        if (directorList?.length >= 2) {
            setOperationMode([{ name: 'Multiple', value: '2' }]);
            formik.setFieldValue('modeofoperations', '2');
        } else {
            setOperationMode([{ name: 'Single', value: '1' }]);
            formik.setFieldValue('modeofoperations', '1');
        }
        setEmployeeName(apiData?.tblSalesHierarchy.hierarchyName);
        //       setNearestBranch([{lovId:apiData.lkpBranch.branchId.toString(),name:apiData.lkpBranch.branchName}])
        setParentAgentData([{ name: 'Zindagi', value: '1' }]);
        if (apiData?.kycView) {
            let kycView = JSON.parse(apiData?.kycView);

            Object.keys(kycView).forEach((key) => {
                formik.setFieldValue(key, kycView[key]);
            });
        }

        formik.setFieldValue('pricingProfileId', apiData?.pricingProfileId);
        formik.setFieldValue('commissionId', apiData?.tblCommissionProfile?.commissionProfileId);
        formik.setFieldValue('transLimitId', apiData?.tblTransLimit?.transLimitId);
        const extractedChannelIds = apiData?.channelIdList?.map((entry) => {
            return { lovId: entry?.toString() };
        });
        formik.setFieldValue('channelIdList', extractedChannelIds);
        formik.setFieldValue('chequeBook', apiData?.chequeBook === 'Y');
        formik.setFieldValue('parentAgentId', '1');
        formik.setFieldValue('empId', apiData?.tblSalesForce?.employeeId);
        formik.setFieldValue('productCatalogId', apiData?.productCatalogId?.toString());
        formik.setFieldValue('accountTitle', apiData?.accountTitle || '');
        formik.setFieldValue('accountNumber', apiData?.accountNumber || '');
        formik.setFieldValue('addressInfos', apiData?.addressInfos || '');
        formik.setFieldValue('clsVerified', apiData?.clsVerified);
        formik.setFieldValue('createdate', apiData?.createdate);
        formik.setFieldValue('isFiler', apiData?.isFiler);
        formik.setFieldValue('plus30', apiData?.tblEcibResponse?.plus30);
        formik.setFieldValue('plus60', apiData?.tblEcibResponse?.plus60);
        formik.setFieldValue('plus90', apiData?.tblEcibResponse?.plus90);
        formik.setFieldValue('plus120', apiData?.tblEcibResponse?.plus120);
        formik.setFieldValue('plus150', apiData?.tblEcibResponse?.plus150);
        formik.setFieldValue('plus180', apiData?.tblEcibResponse?.plus180);
        formik.setFieldValue('crpScore', apiData?.crpScore);
        formik.setFieldValue('crpRating', apiData?.crpRating);
    };
    const handleTableSubmit = (event) => {
        // Create a new object representing the form data
        const newFormData = {
            // name: formik.values.dName,
            cnic: formik.values.dCnic,
            dobCNIC: formik.values.dobCNIC,
            mobileNumber: formik.values.dMobileNumber,
            identifier: Date.now()
        };

        // Append the new form data to the existing dFormValues array
        setDFormValues((prevDFormValues) => [...prevDFormValues, newFormData]);
    };

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    useEffect(() => {
        if (requestTypeDescr === 'Enable/Disable Request') {
            setCheckerPayload({
                agentId: apiData?.agentId,
                isActive: mcRequestData?.isActive === 'Y' ? true : false
            });
        } else if (requestType === 'I') {
            setCheckerPayload({
                agentId: databyId?.agentId,
                isActive: true
            });
        } else {
            setCheckerPayload(mcRequestData);
        }
    }, [mcRequestData]);
    useEffect(() => {
        if (isEditCase) {
            setOperationMode([
                { name: 'Single', value: '1' },
                { name: 'Multiple', value: '2' }
            ]);
            getStatusListData();
            getKycFields();
            getProductCatalog();
            getLimitProfile();
            getPricingProfile();
            getChannel();
        } else {
            setOperationMode([
                { name: 'Single', value: '1' },
                { name: 'Multiple', value: '2' }
            ]);
            getKycFields();
            getProductCatalog();
            getLimitProfile();
            getPricingProfile();
            getChannel();
        }
    }, [apiData]);

    const handleDelete = (identifier) => {
        const updatedDFormValues = dFormValues.filter((rowData) => rowData.identifier !== identifier);
        setDFormValues(updatedDFormValues);
    };

    function convertToTitleCase(inputString) {
        const words = inputString.split(/(?=[A-Z])/);
        const titleCaseString = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

        return titleCaseString;
    }

    const getaccountStatus = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getAccountStatus/LKP_ACCOUNT_STATUS:*');
        setAccountStatus(res?.payLoad);
    };

    useEffect(() => {
        getaccountStatus();
    }, []);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    return (
        <>
            <div className="my-3">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="custom-card mb-5">
                        <div className="mx-5 mt-0 Form__Header Full__Width">
                            <p>CORPORATE KYC SETS</p>
                        </div>

                        <div className="p-fluid p-formgrid grid mb-5 mx-5">
                            {tblKycSetDetails?.map((result, index) => (
                                <React.Fragment key={index}>
                                    {result?.isActive === 'Y' && (
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3" key={index}>
                                            <div className="p-field">
                                                <label htmlFor={result?.tblKycAttribute?.attributeName} className="Label__Text">
                                                    {convertToTitleCase(result?.tblKycAttribute?.attributeName)}
                                                    {(kycFixed?.includes(result?.tblKycAttribute?.attributeName) || result?.isMandatory === 'Y') && <span className="Label__Required">*</span>}
                                                </label>

                                                <InputText
                                                    id={result?.tblKycAttribute?.attributeName}
                                                    name={result?.tblKycAttribute?.attributeName}
                                                    value={formik.values[result?.tblKycAttribute?.attributeName]}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid(result?.tblKycAttribute?.attributeName) }, 'Input__Round')}
                                                    maxLength={30}
                                                    disabled={true}
                                                    type={result?.tblKycAttribute?.attributeName.includes('Date') ? 'date' : 'text'}
                                                />

                                                {getFormErrorMessage(result?.tblKycAttribute?.attributeName)}
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>

                    <div className="custom-card mb-5">
                        <div className="mx-5 mt-0 Form__Header Full__Width">
                            <p>DIRECTOR KYC SETS</p>
                        </div>
                        <form>
                            <div className="p-fluid p-formgrid grid mb-5 mx-5">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="dCnic" className="Label__Text">
                                            CNIC
                                            <span className="Label__Required">*</span>
                                        </label>

                                        <InputText id="dCnic" name="dCnic" value={formik.values.dCnic} onChange={formik.handleChange} className="Input__Round" maxLength={30} disabled />

                                        {getFormErrorMessage('dCnic')}
                                    </div>
                                </div>

                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="dMobileNumber" className="Label__Text">
                                            Mobile Number
                                            <span className="Label__Required">*</span>
                                        </label>

                                        <InputText id="dMobileNumber" name="dMobileNumber" value={formik.values.dMobileNumber} onChange={formik.handleChange} className="Input__Round" maxLength={30} disabled />

                                        {getFormErrorMessage('dMobileNumber')}
                                    </div>
                                </div>

                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="dobCNIC" className="Label__Text">
                                            CNIC ISSUANCE DATE
                                            <span className="Label__Required">*</span>
                                        </label>

                                        <InputText id="dobCNIC" name="dobCNIC" type="date" value={formik.values.dobCNIC} onChange={formik.handleChange} className="Input__Round" maxLength={30} disabled />

                                        {getFormErrorMessage('dobCNIC')}
                                    </div>
                                </div>

                                <div className="Usergroup__SearchBtn p-field col-12 md:col-4 pt-3 pb-3 ">
                                    <Button type="button" label="Add" className="Btn__Dark__Search" onClick={(e) => handleTableSubmit(e)} disabled />
                                </div>
                            </div>
                        </form>

                        <div className="grid mx-5">
                            <div className="col-12">
                                <div className="card">
                                    <DataTable className="datatable-responsive" value={dFormValues} emptyMessage="No List found." responsiveLayout="scroll">
                                        <Column field="name" header="Name" />
                                        <Column field="cnic" header="CNIC" />
                                        <Column field="dobCNIC" header="CNIC ISSUANCE DATE" />
                                        <Column field="mobileNumber" header="Mobile Number" />
                                        <Column
                                            header="Action"
                                            body={(rowData) => (
                                                <Button
                                                    type="button"
                                                    style={{ marginLeft: '37%' }}
                                                    disabled
                                                    onClick={() => handleDelete(rowData.identifier)}
                                                    tooltip="Delete"
                                                    tooltipOptions={{ position: 'top' }}
                                                    icon="pi pi-trash"
                                                    className="p-button-rounded p-button-danger"
                                                />
                                            )}
                                            className="Table__ActionHeading__CentredAlign"
                                        />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="pricingProfileId" className={classNames({ 'p-error': isFormFieldValid('pricingProfileId') }, 'Label__Text')}>
                                    Pricing Profile
                                </label>
                                <Dropdown
                                    id="pricingProfileId"
                                    placeholder="Select Pricing Profile"
                                    options={pricingProfile}
                                    optionLabel="pricingProfileName"
                                    name="pricingProfileId"
                                    optionValue="pricingProfileId"
                                    value={formik.values.pricingProfileId || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('pricingProfileId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="newmodeofoperations" className="Label__Text">
                                    Mode of Operations
                                </label>
                                <Dropdown
                                    id="modeofoperations"
                                    placeholder="Mode of Operations"
                                    options={modeofoperations}
                                    optionLabel="name"
                                    name="modeofoperations"
                                    optionValue="value"
                                    value={formik.values.modeofoperations || ''}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round"
                                    disabled={isEditCase}
                                />
                                {/* {getFormErrorMessage('channelName')} */}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="transLimitId" className="Label__Text">
                                    Limit Profile
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="transLimitId"
                                    placeholder="Select Limit Profile"
                                    options={limitProfile}
                                    optionLabel="limitProfileName"
                                    name="transLimitId"
                                    optionValue="transLimitId"
                                    value={formik.values.transLimitId || ''}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round"
                                    disabled
                                />

                                {/* {getFormErrorMessage('channelDescr')} */}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="channelIdList" className={classNames({ 'p-error': isFormFieldValid('channelIdList') }, 'Label__Text')}>
                                    Channel<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="channelIdList"
                                    options={channelOption}
                                    name="channelIdList"
                                    optionLabel="name"
                                    optionValue="lovId"
                                    panelFooterTemplate={panelFooterTemplate}
                                    value={formik?.values?.channelIdList?.map((trans) => trans.lovId)}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            'channelIdList',
                                            e.value.map((lovId) => ({ lovId }))
                                        );
                                    }}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelIdList') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('channelIdList')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="empId" className="Label__Text">
                                    Employee ID<span className="Label__Required">*</span>
                                </label>
                                <div className="flex">
                                    <InputText id="empId" name="empId" value={formik.values.empId} onChange={formik.handleChange} className="Input__Round" maxLength={30} disabled />
                                    <Button icon={loadingIcon || ''} onClick={() => searchEmployeeId()} type="button" className="Btn__Dark border-circle" style={{ height: '35px', width: '35px', marginLeft: '1rem' }} disabled />
                                </div>
                                {/* {useCaseError.empId !== ''  ? <small className="p-error">{useCaseError.empId}</small> : ''} */}
                                {getFormErrorMessage('empId')}
                            </div>
                        </div>
                    </div>

                    <div className="custom-card mb-5">
                        <div className="mx-5 mt-0 Form__Header Full__Width">
                            <p>EMPLOYEE ID</p>
                        </div>

                        <div className="p-fluid p-formgrid grid mb-5 mx-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="channelName" className="Label__Text">
                                        Sales Hierarchy Name
                                        <span className="Label__Required">*</span>
                                    </label>

                                    <InputText id="employeeName" name="employeeName" value={employeeName} onChange={formik.handleChange} className="Input__Round" maxLength={30} disabled />

                                    {getFormErrorMessage('employeeName')}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productCatalogId" className={classNames({ 'p-error': isFormFieldValid('productCatalogId') }, 'Label__Text')}>
                                    Product catalog
                                </label>
                                <Dropdown
                                    id="productCatalogId"
                                    placeholder="Select Product Catalog"
                                    options={productcatalog}
                                    optionLabel="name"
                                    name="productCatalogId"
                                    optionValue="lovId"
                                    value={formik.values.productCatalogId || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('productCatalogId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('productCatalogId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="clsVerified" className={classNames({ 'p-error': isFormFieldValid('clsVerified') }, 'Label__Text')}>
                                    CLS Status <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    id="clsVerified"
                                    name="clsVerified"
                                    placeholder="Enter Mother Name"
                                    value={formik.values.clsVerified || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('clsVerified') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('clsVerified')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isFiler" className={classNames({ 'p-error': isFormFieldValid('isFiler') }, 'Label__Text')}>
                                    Filer/Non-Filer <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    id="isFiler"
                                    name="isFiler"
                                    placeholder="Enter Mother Name"
                                    value={formik.values.isFiler || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('isFiler') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('isFiler')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="createdate" className={classNames({ 'p-error': isFormFieldValid('createdate') }, 'Label__Text')}>
                                    Account Created Date <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    type="date"
                                    disabled
                                    id="createdate"
                                    name="createdate"
                                    placeholder="Enter Mother Name"
                                    value={formik.values.createdate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('createdate')}
                            </div>
                        </div>
                        {formik.values.addressInfos?.map((item, index) => {
                            return (
                                <div className="p-field col-12 md:col-4 pt-3 pb-3" key={index}>
                                    <div className="p-field">
                                        <label htmlFor={`addressInfos.${index}.fullAddress`} className={classNames({ 'p-error': isFormFieldValid(`addressInfos.${index}.fullAddress`) }, 'Label__Text')}>
                                            Address <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            disabled
                                            id={`addressInfos.${index}.fullAddress`}
                                            name={`addressInfos.${index}.fullAddress`}
                                            placeholder="Enter Adds"
                                            value={item?.fullAddress || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid(`addressInfos.${index}.fullAddress`) }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage(`addressInfos.${index}.fullAddress`)}
                                    </div>
                                </div>
                            );
                        })}
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountNumber" className={classNames({ 'p-error': isFormFieldValid('accountNumber') }, 'Label__Text')}>
                                    Account Number <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="accountNumber"
                                    name="accountNumber"
                                    placeholder="Select Account Number"
                                    value={formik.values.accountNumber || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountNumber') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('accountNumber')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountTitle" className={classNames({ 'p-error': isFormFieldValid('accountTitle') }, 'Label__Text')}>
                                    Account Title <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="accountTitle"
                                    name="accountTitle"
                                    placeholder="Select Account Number"
                                    value={formik.values.accountTitle || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountTitle') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('accountTitle')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="plus30" className={classNames({ 'p-error': isFormFieldValid('plus30') }, 'Label__Text')}>
                                    ECIB Plus 30 <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="plus30"
                                    name="plus30"
                                    placeholder="Select ECIB Response"
                                    value={formik.values.plus30 || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('plus30') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('plus30')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="plus60" className={classNames({ 'p-error': isFormFieldValid('plus60') }, 'Label__Text')}>
                                    ECIB Plus 60 <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="plus60"
                                    name="plus60"
                                    placeholder="Select ECIB Response"
                                    value={formik.values.plus60 || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('plus60') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('plus60')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="plus90" className={classNames({ 'p-error': isFormFieldValid('plus90') }, 'Label__Text')}>
                                    ECIB Plus 90 <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="plus90"
                                    name="plus90"
                                    placeholder="Select ECIB Response"
                                    value={formik.values.plus90 || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('plus90') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('plus90')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="plus120" className={classNames({ 'p-error': isFormFieldValid('plus120') }, 'Label__Text')}>
                                    ECIB Plus 120 <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="plus120"
                                    name="plus120"
                                    placeholder="Select ECIB Response"
                                    value={formik.values.plus120 || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('plus120') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('plus120')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="plus150" className={classNames({ 'p-error': isFormFieldValid('plus150') }, 'Label__Text')}>
                                    ECIB Plus 150 <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="plus150"
                                    name="plus150"
                                    placeholder="Select ECIB Response"
                                    value={formik.values.plus150 || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('plus150') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('plus150')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="plus180" className={classNames({ 'p-error': isFormFieldValid('plus180') }, 'Label__Text')}>
                                    ECIB Plus 180 <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="plus180"
                                    name="plus180"
                                    placeholder="Select ECIB Response"
                                    value={formik.values.plus180 || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('plus180') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('plus180')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="crpScore" className={classNames({ 'p-error': isFormFieldValid('crpScore') }, 'Label__Text')}>
                                    Crp Score <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="crpScore"
                                    name="crpScore"
                                    placeholder="Crp Score"
                                    value={formik.values.crpScore || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('crpScore') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('crpScore')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="crpRating" className={classNames({ 'p-error': isFormFieldValid('crpRating') }, 'Label__Text')}>
                                    Crp Rating <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="crpRating"
                                    name="crpRating"
                                    placeholder="Crp Rating"
                                    value={formik.values.crpRating || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('crpRating') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('crpRating')}
                            </div>
                        </div>

                        {/* <div className="p-field col-12 md:col-4 pt-3 pb-3 mt-5">
                            <div className="p-field">
                                <Checkbox inputId="binary" name="chequeBook" onChange={formik.handleChange} checked={formik.values.chequeBook} disabled />
                                <label htmlFor="binary" className="Label__Text">
                                    Cheque Book
                                </label>
                            </div>
                        </div> */}
                    </div>

                    {/* {formik.values.chequeBook ? (
                        <div className="custom-card mb-5">
                            <div className="mx-5 mt-0 Form__Header Full__Width">
                                <p>Cheque Book Details</p>
                            </div>

                            <div className="p-fluid p-formgrid grid mb-5 mx-5">
                                {formik.values.chequeBook && (
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="noOfLeaves" className={classNames({ 'p-error': isFormFieldValid('noOfLeaves') }, 'Label__Text')}>
                                                No of leaves
                                                <span className="Label__Required">*</span>
                                            </label>
                                            <Dropdown
                                                id="noOfLeaves"
                                                options={chequeBookLeaves}
                                                name="noOfLeaves"
                                                placeholder="Select Number of Leaves"
                                                optionLabel="name"
                                                optionValue="value"
                                                value={formik.values.noOfLeaves}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('noOfLeaves') }, 'Dropdown__Round')}
                                                disabled
                                            />
                                            {getFormErrorMessage('noOfLeaves')}
                                        </div>
                                    </div>
                                )}

                                <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="channelName" className="Label__Text">
                                            Waiver
                                        </label>

                                        <div className="flex">
                                            <div className="mt-2 mr-5">
                                                <RadioButton inputId="city1" name="waiver" value="Y" onChange={formik.handleChange} checked={formik.values.waiver === 'Y'} disabled />

                                                <label htmlFor="city1" className="Label__Text">
                                                    Applicable
                                                </label>
                                            </div>

                                            <div className="mt-2">
                                                <RadioButton inputId="city1" name="waiver" value="N" onChange={formik.handleChange} checked={formik.values.waiver === 'N'} disabled />

                                                <label htmlFor="city1" className="Label__Text">
                                                    Not Applicable
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )} */}
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                Is Active
                            </label>
                            <div className="field-checkbox mt-2">
                                <Checkbox inputId="isActive" disabled={true} name="isActive" value={active} checked={active} />
                                <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                    active
                                </label>
                            </div>
                        </div>
                    </div>
                    {isEditCase && (
                        <React.Fragment>
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                        Action
                                        <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="action"
                                        placeholder="Select Action"
                                        options={statusLovData}
                                        optionLabel="name"
                                        name="action"
                                        optionValue="lovId"
                                        value={formik.values.action || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('action')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                        Comments
                                        <span className="Label__Required">*</span>
                                    </label>
                                    <InputTextarea
                                        maxLength={500}
                                        rows={5}
                                        cols={30}
                                        id="checkerComments"
                                        name="checkerComments"
                                        value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                    />

                                    {getFormErrorMessage('checkerComments')}
                                </div>
                            </div>
                        </React.Fragment>
                    )}

                    <div className="Down__Btn my-5">
                        {isEditCase ? (
                            <>
                                <Button type="submit" label="Update" className="Btn__Dark" />
                                <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                            </>
                        ) : (
                            <Button type="submit" label={'Okay'} onClick={() => navigate('/requestchecker')} className="Btn__Dark" />
                        )}
                    </div>
                </form>
            </div>
            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default ViewCorporate;

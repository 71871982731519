import React, { useState } from 'react';
import MakerApis from '../Components/MakerApis';

function ViewT24AccountLink() {
    const [tableData, setTableData] = useState([]);
    return (
        <React.Fragment>
            <MakerApis type="view" />
        </React.Fragment>
    );
}
export default ViewT24AccountLink;

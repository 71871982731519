import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import moment from 'moment';

function ViewSmsChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [useCaseData, setUseCaseData] = useState([]);
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const checkerComments = query.get('checkerComments');
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const tableName = query.get('tableName');

    const [statusLovData, setStatusLovData] = useState([]);
    const [SMSType, setSMSType] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };
    const getUseCase = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_USECASE');
        setUseCaseData(resp?.payLoad);
    };
    const getSMSType = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_SMS_TYPE');
        setSMSType(resp?.payLoad);
    };

    useEffect(() => {
        getSMSType();
        getStatusLov();
        getUseCase();
    }, []);

    const validationSchema = Yup.object().shape({
        clientEmail: Yup.string().matches('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$', 'Please enter valid email'),
        segmentName: Yup.string().required('This field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cmsSmsId: '',
            cmsSmsTypeId: '',
            smsTitle: '',
            smsText: '',
            smsDatetime: '',
            cmsUsecaseId: '',
            isActive: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        objectBody: {
                            cmsSmsId: formik.values.cmsSmsId,
                            isActive: formik.values.isActive
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: ''
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(newData, '/cms/v1/cms/smsvalidatorcheckeraction', true, false, 'requestchecker'));
            if (res?.responseCode === '190000') {
                formik.resetForm();
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const getMainCategoryData = async () => {
        setloading(true);
        const res = await handleGetRequest(`/cms/v1/cms/getsmsbyid/${refTableId}`);
        const keyData = res?.payLoad;

        if (keyData) {
            formik.setFieldValue('smsTitle', keyData?.smsTitle || '');
            formik.setFieldValue('smsText', keyData?.smsText || '');
            formik.setFieldValue('smsContent', keyData?.smsContent || '');

            const formattedTime = (keyData?.smsDatetime && formattedDate(keyData?.smsDatetime)) || '';
            formik.setFieldValue('smsDatetime', formattedTime || '');
            formik.setFieldValue('cmsSmsTypeId', keyData?.cmsSmsTypeId?.toString());

            const okiiProduct = keyData?.cmsUsecaseId;
            const newProduct = useCaseData.find((option) => option?.lovId == okiiProduct);
            formik.setFieldValue('cmsUsecaseId', newProduct?.lovId || '');
            formik.setFieldValue('checkerComments', checkerComments);

            const okiistatusName = keyData?.statusName;
            const newstatusName = statusLovData.find((option) => option?.lovId == okiistatusName);
            formik.setFieldValue('action', newstatusName?.lovId || '');
        }
        setloading(false);
    };

    const getMcRequestDataById = async () => {
        setloading(true);
        const res = await handleGetRequest(`/cms/v1/cms/getmcrequestbyid/${mcRequestId}`);
        const unParsedkeyData = res?.payLoad?.updateJson;
        const keyData = JSON.parse(unParsedkeyData);

        if (keyData) {
            if (keyData) {
                formik.setFieldValue('smsTitle', keyData?.smsTitle || '');
                formik.setFieldValue('smsText', keyData?.smsText || '');
                formik.setFieldValue('smsContent', keyData?.smsContent || '');

                const formattedTime = (keyData?.smsDatetime && formattedDate(keyData?.smsDatetime)) || '';
                formik.setFieldValue('smsDatetime', formattedTime || '');

                formik.setFieldValue('cmsUsecaseId', keyData?.cmsUsecaseId?.toString());

                formik.setFieldValue('cmsSmsTypeId', keyData?.cmsSmsTypeId?.toString());

                formik.setFieldValue('checkerComments', checkerComments);

                const okiistatusName = keyData?.statusName;
                const newstatusName = statusLovData.find((option) => option?.lovId == okiistatusName);
                formik.setFieldValue('action', newstatusName?.lovId || '');
            }
        }
        setloading(false);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getMainCategoryData();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, [useCaseData]); // eslint-disable-line

    const formattedDate = (dateString) => {
        return moment(dateString).format('YYYY-MM-DDTHH:mm');
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>VIEW DETAILS</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsSmsTypeId" className={classNames({ 'p-error': isFormFieldValid('cmsSmsTypeId') }, 'Label__Text')}>
                                    SMS Type <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="cmsSmsTypeId"
                                    placeholder="Enter SMS Type"
                                    options={SMSType}
                                    optionLabel="name"
                                    name="cmsSmsTypeId"
                                    optionValue="lovId"
                                    value={formik.values.cmsSmsTypeId || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsSmsTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsSmsTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="smsTitle" className={classNames({ 'p-error': isFormFieldValid('smsTitle') }, 'Label__Text')}>
                                    Title <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="smsTitle"
                                    placeholder="Enter Title"
                                    name="smsTitle"
                                    value={formik?.values?.smsTitle || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('smsTitle') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('smsTitle')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="smsDatetime" className={classNames({ 'p-error': isFormFieldValid('smsDatetime') }, 'Label__Text')}>
                                    Date & Time
                                </label>

                                <input
                                    id="smsDatetime"
                                    placeholder="Enter Start Date & Time"
                                    name="smsDatetime"
                                    disabled
                                    type="datetime-local"
                                    min={moment().format('YYYY-MM-DDTHH:mm')}
                                    value={formik.values.smsDatetime}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('smsDatetime') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('smsDatetime')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsUsecaseId" className={classNames({ 'p-error': isFormFieldValid('cmsUsecaseId') }, 'Label__Text')}>
                                    Use Case<span className="Label__Required"></span>
                                </label>
                                <Dropdown
                                    id="cmsUsecaseId"
                                    placeholder="Select Use Case"
                                    options={useCaseData}
                                    optionLabel="name"
                                    name="cmsUsecaseId"
                                    optionValue="lovId"
                                    value={formik.values.cmsUsecaseId || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsUsecaseId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsUsecaseId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="smsText" className={classNames({ 'p-error': isFormFieldValid('smsText') }, 'Label__Text')}>
                                    SMS Text <span className="Label__Required">*</span>
                                </label>
                                <InputText maxLength={100} id="smsText" placeholder="-" name="smsText" value={formik?.values?.smsText || ''} disabled className={classNames({ 'p-invalid': isFormFieldValid('smsText') }, 'Input__Round')} />
                                {getFormErrorMessage('smsText')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon || ''} onClick={() => navigate('/requestchecker')} type="submit" label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default ViewSmsChecker;

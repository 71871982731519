import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../services/PostTemplate';
import { handleGetRequest } from '../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RadioButton } from 'primereact/radiobutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { toast } from 'react-toastify';

function AddEnterpriseServiceBus({}) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [segmentsLov, setSegmentsLov] = useState([]);
    const [headerKeyValuePair, setHeaderKeyValuePair] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const dataTableRef = useRef(null);

    const getSegmentsLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getsegments/LKP_SEGMENT:*');
        setSegmentsLov(resp?.payLoad);
    }

    useEffect(() => {
        getSegmentsLov();
    }, []);

    const handleRadioChange = (event) => {

        if (event.target.name === "targetUrl1Status") {
            formik.setFieldValue("targetUrl2Status", "N");
            formik.setFieldValue("targetUrl1Status", "Y");
        } else {
            formik.setFieldValue("targetUrl2Status", "Y");
            formik.setFieldValue("targetUrl1Status", "N");
        }
    };

    const addHeaderKeyValue = () => {
        if((formik.values.headerKey && formik.values.headerValue) === "") {
            toast.warn('Please Enter Header Name & Value');
        } else if(formik.values.headerKey.trim().length === 0 || formik.values.headerValue.trim().length === 0) {
            toast.warn('Please Remove Leading and Trailing Header Name & Value Space');
        } else {

            const newKeyValuePair = {
                apiReqHeaderId: "0",
                headerKey: formik.values.headerKey,
                headerValue: formik.values.headerValue
            };
            
            setHeaderKeyValuePair([...headerKeyValuePair, newKeyValuePair]);
            
            // Clear the input fields after adding the data.
            formik.setFieldValue("headerKey", "");
            formik.setFieldValue("headerValue", "");
        }
    };

    const validationSchema = Yup.object().shape({
        apiName: Yup.mixed().required('This field is required.'),
        endPoint: Yup.mixed().required('This field is required.'),
        httpMethod: Yup.mixed().required('This field is required.'),
        tblApiSegments: Yup.mixed().required('This field is required.'),
        dailyLimit: Yup.string().required('This field is required.').matches(/^[0-9]+$/, 'Only numeric values are allowed'),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            apiName: "",
            dailyLimit: "",
            endPoint: "",
            httpMethod: "GET",
            targetUrl1: "",
            targetUrl2: "",
            sampleApiRequest: "",
            sampleApiResponse: "",
            targetUrl1Status: "Y",
            targetUrl2Status: "N",
            noLimits: false,
            tblApiSegments: [],
            tblApiReqHeaders: [],
            headerKey: "",
            headerValue: "",
        },

        validate: (data) => {
            let errors = {};

            ////////////////////////apiName/////////////////////
            if (!data?.apiName) {
                errors.apiName = "This field is required";
            } else if (data?.apiName === "") {
                errors.apiName = "This Field should not be empty";
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.apiName)) {
                errors.apiName = "Leading and trailing space is not allowed";
            } else if (!/^[a-zA-Z](?:[a-zA-Z0-9\s]*-?[a-zA-Z0-9\s]*)?$/.test(data?.apiName)) {
                errors.apiName = "Invalid Api Name format";
            }

            if (data?.targetUrl2Status === "N") {
                if (data?.targetUrl1Status === "Y" && !data?.targetUrl1) {
                    errors.targetUrl1 = "This field is required";
                }
            }

            if (data?.targetUrl2Status === "Y") {  
                if (data?.targetUrl1Status === "N") {
                    if(!data?.targetUrl1) {
                        errors.targetUrl1 = "This field is required";
                    }
                    if(!data?.targetUrl2) {
                        errors.targetUrl2 = "This field is required";
                    }
                }   
            }
            
            if (data?.tblApiSegments.length === 0) {
                errors.tblApiSegments = 'This field is required';
            }

            if (data?.httpMethod === 'POST') {
                if (!data?.sampleApiRequest) {
                    errors.sampleApiRequest = "This field is required";
                } else {
                    try {
                      JSON.parse(data?.sampleApiRequest);
                    } catch (e) {
                      errors.sampleApiRequest = "Invalid JSON format";
                    }
                }
            }

            if (!data?.sampleApiResponse) {
                errors.sampleApiResponse = "This field is required";
            } else {
                try {
                  JSON.parse(data?.sampleApiResponse);
                } catch (e) {
                  errors.sampleApiResponse = "Invalid JSON format";
                }
            }

            return errors;
        },

        onSubmit: async (data) => {
            data['tblApiReqHeaders'] = headerKeyValuePair;
            data['noLimits'] = data?.noLimits === true ? 'Y' : 'N';

            formik.setFieldValue("targetUrl1Status",data?.targetUrl1Status);
            formik.setFieldValue("targetUrl2Status",data?.targetUrl2Status);

            delete data['headerKey'];
            delete data['headerValue'];

            window.scrollTo({ top: 0, behavior: 'smooth' });

            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            if (headerKeyValuePair.length === 0) {
                toast.warn('Please Add Header Name & Value');
                formik.setFieldValue('noLimits', data?.noLimits === "Y" ? true : false);
            } else {
                data['sampleApiRequest'] = data?.httpMethod === 'POST' ? JSON.parse(data?.sampleApiRequest) : {};
                data['sampleApiResponse'] = JSON.parse(data?.sampleApiResponse);
                const res = await dispatch(handlePostRequest(newData, '/apigateway/v1/esb/createapisignature', true, false, 'esb'));
                if (res?.responseCode === '000000') {
                    formik.resetForm();
                } else {
                    formik.setFieldValue('dailyLimit', data?.dailyLimit);
                    formik.setFieldValue('tblApiSegments', data?.tblApiSegments);
                    formik.setFieldValue('noLimits', data?.noLimits === "Y" ? true : false);
                }
            }
            setloading(false);
            setloadingIcon('pi pi-search');
            
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const removeHeaderKeyValue = (rowData, column) => {
        // Find the index of the row in the array
        const rowIndex = headerKeyValuePair.indexOf(rowData);
      
        if (rowIndex !== -1) {
          // Create a copy of the array without the selected row
          const updatedHeaderKeyValuePair = [...headerKeyValuePair];
          updatedHeaderKeyValuePair.splice(rowIndex, 1);
      
          // Update the state with the modified array
          setHeaderKeyValuePair(updatedHeaderKeyValuePair);
        }
    };

    const panelFooterTemplate = () => {
        const length = formik.values.tblApiSegments ? formik.values.tblApiSegments.length : 0;

        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    const handleNoLimitChange = (e) => {
        if (e.target.checked) {
            formik.setFieldValue('dailyLimit', '0');
        } else {
            formik.setFieldValue('dailyLimit', '');
        }
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>ADD NEW</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="apiName" className={classNames({ 'p-error': isFormFieldValid('apiName') }, 'Label__Text')}>
                                    API Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="apiName"
                                    placeholder="Enter API Name"
                                    name="apiName"
                                    maxLength={100}
                                    value={formik?.values?.apiName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('apiName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('apiName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="endPoint" className={classNames({ 'p-error': isFormFieldValid('endPoint') }, 'Label__Text')}>
                                    Gateway End Point <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="endPoint"
                                    placeholder="Enter Gateway End Point"
                                    name="endPoint"
                                    maxLength={100}
                                    value={formik?.values?.endPoint || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('endPoint') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('endPoint')}
                            </div>
                        </div>
                        <div className="col-12 lg:col-6 xl:col-4 md:col-4 flex">
                            <div className="flex flex-column justify-content-center gap-2">
                                <div className="flex flex-column">
                                    <div className=''>
                                        <label htmlFor="httpMethod"
                                            className={classNames({ 'p-error': isFormFieldValid('httpMethod') }, 'Label__Text')}
                                        >
                                            HTTP Request Type<span className="Label__Required">*</span>
                                        </label>
                                    </div>
                                    <div className='flex'>
                                        <div className="flex mr-3">
                                            <RadioButton
                                                inputId="httpMethod"
                                                name="httpMethod"
                                                value="GET"
                                                onChange={formik.handleChange}
                                                checked={formik.values.httpMethod === "GET"}
                                            />
                                            <label htmlFor="httpMethod" className="ml-2">GET</label>
                                        </div>
                                        <div style={{ marginLeft: "10rem" }} className="flex mr-3">
                                            <RadioButton
                                                inputId="httpMethod"
                                                name="httpMethod"
                                                value="POST"
                                                onChange={formik.handleChange}
                                                checked={formik.values.httpMethod === "POST"}
                                            />
                                            <label htmlFor="httpMethod" className="ml-2">POST</label>
                                        </div>
                                    </div>
                                    {getFormErrorMessage('httpMethod')}
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                            <div className="p-field flex align-items-center">
                                <RadioButton
                                    inputId="targetUrl1Status"
                                    name="targetUrl1Status"
                                    value="Y"
                                    onChange={handleRadioChange}
                                    checked={formik.values.targetUrl1Status === "Y"}
                                />
                                <label htmlFor="targetUrl1" className={classNames({ 'p-error': isFormFieldValid('targetUrl1') }, 'Label__Text Label__Extra__Text__Padding')}>
                                    Target URL 1 <span className="Label__Required">*</span>
                                </label>
                                <div className='Inputfield__Fulllength'>
                                    <InputText
                                        id="targetUrl1"
                                        placeholder="Enter Target URL 1"
                                        name="targetUrl1"
                                        maxLength={200}
                                        value={formik?.values?.targetUrl1 || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('targetUrl1') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('targetUrl1')}
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                            <div className="p-field flex align-items-center">
                                <RadioButton
                                    inputId="targetUrl2Status"
                                    name="targetUrl2Status"
                                    value="Y"
                                    onChange={handleRadioChange}
                                    checked={formik.values.targetUrl2Status === "Y"}
                                />
                                <label htmlFor="targetUrl2" className={classNames({ 'p-error': isFormFieldValid('targetUrl2') }, 'Label__Text Label__Extra__Text__Padding')}>
                                    Target URL 2 {formik.values.targetUrl2Status === "Y" && <span className="Label__Required">*</span>}
                                </label>
                                <div className='Inputfield__Fulllength'>
                                    <InputText
                                        id="targetUrl2"
                                        placeholder="Enter Target URL 2"
                                        name="targetUrl2"
                                        maxLength={200}
                                        value={formik?.values?.targetUrl2 || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('targetUrl2') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('targetUrl2')}
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="tblApiSegments" className={classNames({ 'p-error': isFormFieldValid('tblApiSegments') }, 'Label__Text')}>
                                    Segments <span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="tblApiSegments"
                                    placeholder="Select Segments"
                                    options={segmentsLov}
                                    optionLabel="name"
                                    name="tblApiSegments"
                                    optionValue="lovId"
                                    // display="chip"
                                    filter
                                    panelFooterTemplate={panelFooterTemplate}
                                    value={formik.values.tblApiSegments.map(segment => segment.segmentId || "")}
                                    onChange={(e) => {
                                        formik.setFieldValue("tblApiSegments", e.value.map(lovId => ({
                                            segmentId: lovId,
                                        })));
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldValid('tblApiSegments') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('tblApiSegments')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end justify-content-center">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="noLimits" name="noLimits" value={formik?.values?.noLimits} checked={formik?.values?.noLimits}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            handleNoLimitChange(e);
                                        }}
                                        style={{ marginTop: '10px' }} />
                                    <label htmlFor="noLimits" className={classNames({ 'p-error': isFormFieldValid('noLimits') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        No Limits
                                    </label>
                                </div>
                                {getFormErrorMessage('noLimits')}
                            </div>
                        </div>  
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="dailyLimit" className={classNames({ 'p-error': isFormFieldValid('dailyLimit') }, 'Label__Text')}>
                                    API Daily Limit
                                    {formik.values.noLimits === true ?  "" : <span className="Label__Required">*</span>}
                                </label>
                                <InputText
                                    id="dailyLimit"
                                    placeholder="Enter API Daily Limit"
                                    name="dailyLimit"
                                    maxLength={15}
                                    value={formik?.values?.dailyLimit || ''}
                                    onChange={formik.handleChange}
                                    disabled={formik.values.noLimits === true ? true : false}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dailyLimit') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dailyLimit')}
                            </div>
                        </div>
                        <div style={{ backgroundColor: "#edf0f5", padding: "2rem" }} className=" col-12 card">
                            <div>
                                <div className="Form__Header">
                                    <h1>ADD API Header Name & Value</h1>
                                </div>
                                <div className='grid'>
                                    <div className="p-field col-12 md:col-5 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="headerKey" className={classNames({ 'p-error': isFormFieldValid('headerKey') }, 'Label__Text')}>
                                                API Header<span className="Label__Required">*</span>
                                            </label>
                                            <InputText
                                                id="headerKey"
                                                placeholder="Enter API Header"
                                                name="headerKey"
                                                maxLength={200}
                                                value={formik?.values?.headerKey || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('headerKey') }, 'Input__Round')}
                                            />
                                            {getFormErrorMessage('headerKey')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-5 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="headerValue" className={classNames({ 'p-error': isFormFieldValid('headerValue') }, 'Label__Text')}>
                                                API Header Description<span className="Label__Required">*</span>
                                            </label>
                                            <InputText
                                                id="headerValue"
                                                placeholder="Enter API Header Description"
                                                name="headerValue"
                                                maxLength={500}
                                                value={formik?.values?.headerValue || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('headerValue') }, 'Input__Round')}
                                            />
                                            {getFormErrorMessage('headerValue')}
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-2 pt-3 pb-3 DataTable__Add__Btn">
                                        <div className="Down__Btn">
                                            <Button disabled={loading} label="Add" className="Btn__Dark" 
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    addHeaderKeyValue();
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='Api__Header__Table'>
                                    <DataTable
                                        ref={dataTableRef}
                                        paginator
                                        rows={5}
                                        rowsPerPageOptions={[5, 10]}
                                        className="datatable-responsive"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                                        emptyMessage="No List found."
                                        responsiveLayout="scroll"
                                        value={headerKeyValuePair}
                                    >
                                        <Column field="headerKey" header="Header Name" />
                                        <Column field="headerValue" header="Header Description" />
                                        <Column
                                            header="Actions"
                                            headerStyle={{ width: '10%', minWidth: '8rem' }}
                                            bodyStyle={{ textAlign: 'left' }}
                                            body={(rowData, column) => { 
                                            return (
                                                <Button
                                                    tooltip="Remove"
                                                    icon="pi pi-minus-circle "
                                                    tooltipOptions={{ position: 'top' }}
                                                    onClick={() => removeHeaderKeyValue(rowData, column)}
                                                    className="p-button-rounded p-button-danger"
                                                />                                     
                                            ) 
                                            }}
                                        />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-6 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="sampleApiRequest" className={classNames({ 'p-error': isFormFieldValid('sampleApiRequest') }, 'Label__Text')}>
                                    Request {formik.values.httpMethod === "POST" && <span className="Label__Required">*</span>}
                                </label>
                                <InputTextarea
                                    id="sampleApiRequest"
                                    placeholder={formik.values.httpMethod === 'POST' ? "Enter Request": "This Field is Disabled in case OF GET Request Type"}
                                    name="sampleApiRequest"
                                    style={formik.values.httpMethod === 'GET' ? { background: "#44486412" } : {}}
                                    disabled={formik.values.httpMethod === 'POST' ? false : true}
                                    value={formik?.values?.sampleApiRequest || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('sampleApiRequest') }, 'Input__Round Vertical__ResizeTextArea')}
                                    rows={10}
                                    cols={30}
                                />
                                {getFormErrorMessage('sampleApiRequest')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-6 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="sampleApiResponse" className={classNames({ 'p-error': isFormFieldValid('sampleApiResponse') }, 'Label__Text')}>
                                    Response<span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    id="sampleApiResponse"
                                    placeholder="Enter Response"
                                    name="sampleApiResponse"
                                    value={formik?.values?.sampleApiResponse || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('sampleApiResponse') }, 'Input__Round Vertical__ResizeTextArea')}
                                    rows={10}
                                    cols={30}
                                />
                                {getFormErrorMessage('sampleApiResponse')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} label="Submit" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/esb')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default AddEnterpriseServiceBus;

import React, { useState, useEffect } from 'react';
import NumberOfDevicesPerAccount from '../Components/DeviceSection/NumberOfDevicesPerAccount';
import { handleGetRequest } from '../../../services/GetTemplate';
import NumberOfAccounts from '../Components/DeviceSection/NumberOfAccounts';
import ListOfSuspectedIps from '../Components/DeviceSection/ListOfSuspectedIps';
import DeviceLocationIdentification from '../Components/DeviceSection/DeviceLocationIdentification';
import SuspectedAgentActivations from '../Components/DeviceSection/SuspectedAgentActivations';
import SuspectedBVs from '../Components/DeviceSection/SuspectedBVs';

function DeviceSection() {
    const [dsGraphData, setDsGraphData] = useState([]);

    const getDSGraphData = async () => {
        const res = await handleGetRequest('/account/v1/riskdashboard/dsgraph');
        setDsGraphData(res?.payLoad);
    };

    useEffect(() => {
        getDSGraphData();
    }, []);

    return (
        <>
            <div className="grid">
                <NumberOfDevicesPerAccount numberOfDevicesPerAccountsData={dsGraphData?.numberOfDevicesPerAccountsData} />
                <NumberOfAccounts numberOfAccountData={dsGraphData?.numberOfAccountData} />
                <ListOfSuspectedIps />
                <DeviceLocationIdentification />
                <SuspectedAgentActivations />
                <SuspectedBVs />
            </div>
        </>
    );
}
export default DeviceSection;

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { useLocation } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';

const OrganizationOldNewValues = ({ organizationRowData }) => {
    const [mcReqData, setMcReqData] = useState([]);

    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const requestType = query.get('requestType');

    const requestTypeDescr = query.get('requestTypeDescr');

    const formik = useFormik({
        initialValues: {
            lkpOrganizationName: '',
            lkpOrganizationCode: '',
            lkpOrganizationDescription: '',
            coaCode: '',
            action: '',
            checkerComments: ''
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getCostCenterById = async () => {
        if (organizationRowData !== null && typeof organizationRowData !== 'undefined') {
            formik.setFieldValue('lkpOrganizationName', organizationRowData?.organizationName);
            formik.setFieldValue('lkpOrganizationCode', organizationRowData?.organizationCode);
            formik.setFieldValue('lkpOrganizationDescription', organizationRowData?.organizationDescr);
            formik.setFieldValue('coaCode', organizationRowData?.coaCode);
        }
    };

    useEffect(() => {
        getCostCenterById();
    }, [organizationRowData]); // eslint-disable-line

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>Old Details</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Organization Name</label>
                                <InputText
                                    id="lkpOrganizationName"
                                    value={formik.values.lkpOrganizationName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpOrganizationName') }, 'Input__Round')}
                                    maxLength={30}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpOrganizationName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Organization Code</label>
                                <InputText
                                    id="lkpOrganizationCode"
                                    value={formik.values.lkpOrganizationCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpOrganizationCode') }, 'Input__Round')}
                                    maxLength={30}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpOrganizationCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Organization Description</label>
                                <InputText
                                    id="lkpOrganizationDescription"
                                    value={formik.values.lkpOrganizationDescription || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpOrganizationDescription') }, 'Input__Round')}
                                    maxLength={199}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpOrganizationDescription')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Coa Code</label>
                                <InputText id="coaCode" value={formik.values.coaCode || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('coaCode') }, 'Input__Round')} maxLength={30} disabled={true} />
                                {getFormErrorMessage('coaCode')}
                            </div>
                        </div>

                        {requestType === 'U' && requestTypeDescr === 'Enable/Disable Request' && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label className="Label__Text">Is Active</label>
                                    <Checkbox checked={mcReqData?.isActive === 'Y'} disabled className="w-full" />
                                </div>
                            </div>
                        )}
                    </div>
                </form>
            </div>
        </>
    );
};

export default OrganizationOldNewValues;

import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { Dropdown } from 'primereact/dropdown';

const MenuIcon = () => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    const [statusData, setStatusData] = useState([]);
    const [userLovData, setUserLovData] = useState([]);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            name: '',
            categoryNo: '',
            createdOn: '',
            dateFrom: '',
            dateTo: '',
            statusId: '',
            createdBy: '',
            updatedBy: ''
        },
        onSubmit: async (data) => {
            const jsonObj = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },

                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },

                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',

                    payLoad: data,

                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],

                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(jsonObj, '/account/v1/customeraccounts/getallcustomers', true, true));
            if (res?.responseCode === '010000') {
                formik.resetForm();
            }
        }
    });

    const editHandler = (rowData) => {
        navigate('/editmenuicon');
    };
    const viewHandler = (rowData) => {
        navigate('/viewmenuicon');
    };

    const actionTemplate = (rowData) => {
        return (
            <div>
                <Button tooltip="Edit" icon="pi pi-pencil" tooltipOptions={{ position: 'top' }} onClick={() => editHandler(rowData)} className="p-button-rounded p-button-warning mr-3" />
                <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => viewHandler(rowData)} className="p-button-rounded p-button-primary" />
            </div>
        );
    };

    const getStatusLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusData(res?.payLoad);
    };

    const getUserLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLovData(res?.payLoad);
    };

    useEffect(() => {
        getStatusLov();
        getUserLov();
    }, []);

    const menuIconData = [
        {
            iconNo: '1',
            name: 'IBFT',
            iconImage: 'icon image',
            category: 'new data',
            subCategory: 'sub categories data',
            createdOn: '29/10/2022 9:00PM',
            createdBy: 'Essa Ahmed',
            lastUpdate: 'yesterday',
            status: 'Enable'
        },
        {
            iconNo: '1',
            name: 'IBFT',
            iconImage: 'icon image',
            category: 'new data',
            subCategory: 'sub categories data',
            createdOn: '29/10/2022 9:00PM',
            createdBy: 'Essa Ahmed',
            lastUpdate: 'yesterday',
            status: 'Enable'
        },
        {
            iconNo: '1',
            name: 'IBFT',
            iconImage: 'icon image',
            category: 'new data',
            subCategory: 'sub categories data',
            createdOn: '29/10/2022 9:00PM',
            createdBy: 'Essa Ahmed',
            lastUpdate: 'yesterday',
            status: 'Enable'
        }
    ];

    const handledClicked = () => {
        navigate('/addmenuicon');
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={handledClicked} className="Btn__Add__" icon="pi pi-plus" iconPos="left" style={{ width: 'auto' }} />
                </div>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Name</label>
                                <InputText id="name" name="name" value={formik.values.name} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'Input__Round')} />
                                {getFormErrorMessage('name')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Category No.</label>
                                <InputText id="categoryNo" name="categoryNo" value={formik.values.categoryNo} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('categoryNo') }, 'Input__Round')} keyfilter="int" />
                                {getFormErrorMessage('categoryNo')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdOn" className={classNames({ 'p-error': isFormFieldValid('createdOn') }, 'Label__Text')}>
                                    Created On
                                </label>
                                <Dropdown
                                    id="createdOn"
                                    placeholder="Select Created On"
                                    options={statusData}
                                    optionLabel="name"
                                    name="createdOn"
                                    optionValue="lovId"
                                    value={formik.values.createdOn || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdOn') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdOn')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="fromDate" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText id="dateFrom" type="date" name="dateFrom" value={formik?.values?.dateFrom} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')} />

                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="toDate" className={classNames({ 'p-error': isFormFieldValid('toDate') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText id="dateTo" type="date" name="dateTo" value={formik?.values?.dateTo} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')} />

                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLovData}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Updated By"
                                    options={userLovData}
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="statusId" className={classNames({ 'p-error': isFormFieldValid('statusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select Status"
                                    options={statusData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <div className="Down__Btn__End">
                                    <Button label="Search" disabled={loading} icon={loadingIcon || ''} iconPos="left" className="Btn__Dark" />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div />

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <div>
                            <h4>Menu/Icon Placement</h4>
                        </div>
                        <DataTable
                            // ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            globalFilterFields={['validatorName', 'validatorType', 'createdate', 'createdBy', 'updatedBy', 'lkpStatus.statusDescr']}
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={menuIconData}
                        >
                            <Column field="iconNo" header="Icon Number" />
                            <Column field="name" header="Icon Name" />
                            <Column field="iconImage" header="Icon Image" />
                            <Column field="category" header="Category" />
                            <Column field="subCategory" header="Sub Category" />
                            <Column field="createdOn" header="Created On" />
                            <Column field="createdBy" header="Created By" />
                            <Column field="lastUpdate" header="Last Updated" />
                            <Column field="status" header="Status" />
                            <Column body={actionTemplate} header="Action" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MenuIcon;

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation } from 'react-router-dom';

function EditCreateCampaignChecker({activeIndex, handleStepClick, getCompaignId, campaignData}) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [frequencyPeriodLov, setFrequencyPeriodLov] = useState([]);

    const dispatch = useDispatch();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const requestType = query.get('requestType');

    const getFrequencyPeriodLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getdatalov/LKP_FREQUENCY_PERIOD');
        setFrequencyPeriodLov(resp?.payLoad);
    }

    useEffect(() => {
        getFrequencyPeriodLov();
    }, []);

    useEffect(() => {
        if (requestType === 'I') {
            if(campaignData) {
                formik.setFieldValue('campaignBudget', campaignData?.budget);
    
                formik.setFieldValue('campaignName', campaignData?.campaignName);
                formik.setFieldValue('startDateTime', new Date(campaignData?.startDate).toISOString().slice(0, 16));
                formik.setFieldValue('endDateTime', new Date(campaignData?.endDate).toISOString().slice(0, 16));
    
                if(campaignData?.amount != undefined || campaignData?.amount != null) {
                    formik.setFieldValue('isSubLimits', true);
                    formik.setFieldValue('frequencyPeriodId', campaignData?.lkpFrequencyPeriod?.frequencyPeriodId?.toString());
                    formik.setFieldValue('amount', campaignData?.amount);
                } else {
                    formik.setFieldValue('isSubLimits', false);
                }
            }
        } else if (requestType === 'U') {
            if(campaignData) {
                formik.setFieldValue('campaignBudget', campaignData[0]?.campaignBudget);

                formik.setFieldValue('campaignName', campaignData[0]?.campaignName);

               // Function to convert and format the date
                function formatDateString(dateString) {
                    const [datePart, timePart] = dateString.split(', '); // Separate date and time parts
                    const [day, month, year] = datePart.split('-').map(Number);
                    const [time, ampm] = timePart.split(' ');
                    let [hours, minutes] = time.split(':').map(Number);

                    // Adjust hours for PM
                    if (ampm === 'PM' && hours < 12) {
                    hours += 12;
                    }

                    // Format the date as ISO 8601 string
                    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
                }
                  
                
                const startDateTimeFormat = formatDateString(campaignData[0]?.startDateTime);
                formik.setFieldValue('startDateTime', startDateTimeFormat);

                const endDateTimeFormat = formatDateString(campaignData[0]?.endDateTime);
                formik.setFieldValue('endDateTime', endDateTimeFormat);
    
                if(campaignData[0]?.amount != undefined || campaignData[0]?.amount != null) {
                    formik.setFieldValue('isSubLimits', true);
                    formik.setFieldValue('frequencyPeriodId', campaignData[0]?.frequencyPeriodId?.toString());
                    formik.setFieldValue('amount', campaignData[0]?.amount);
                } else {
                    formik.setFieldValue('isSubLimits', campaignData[0]?.isSubLimits);
                }

            }
        }
    }, []);

    const validationSchema = Yup.object().shape({
        campaignName: Yup.mixed().required('This field is required.'),
    });


    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            campaignName: "",
            startDateTime: "",
            endDateTime: "",
            campaignBudget: "",
            isSubLimits: false,
            frequencyPeriodId: "",
            amount: ""
            
        },

        // validate: (data) => {
        //     let errors = {};
        //     return errors;
        // },

        onSubmit: async (data) => {

            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['startDateTime'] = formatDate(data?.startDateTime);
            data['endDateTime'] = formatDate(data?.endDateTime);
            data['stepNo'] = "0";

            if(data.isSubLimits === false) {
                delete data['amount'];
                delete data['frequencyPeriodId'];
            }

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            if(parseInt(campaignData?.currentStep) >= 0) {
                data['campaignId'] = campaignData?.campaignId;
                const formData = new FormData();
                formData.append("request", JSON.stringify(newData));

                const res = await dispatch(handlePostRequest(formData, '/customerloyalitypromotions/v1/customerloyalitypromotion/editcampaign', true, false));
                if (res?.responseCode === '150000') {
                    formik.resetForm();
                    getCompaignId(res?.payLoad?.campaignId)
                    handleStepClick(activeIndex + 1);
                }
            } else {
                const formData = new FormData();
                formData.append("request", JSON.stringify(newData));

                const res = await dispatch(handlePostRequest(formData, '/customerloyalitypromotions/v1/customerloyalitypromotion/savecampaign', true, false));
                if (res?.responseCode === '150000') {
                    formik.resetForm();
                    getCompaignId(res?.payLoad?.campaignId)
                    handleStepClick(activeIndex + 1);
                }
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);
      
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
      
        // Convert hours from 24-hour to 12-hour format
        const formattedHours = hours % 12 || 12;
      
        const formattedDate = `${day}-${month}-${year}, ${formattedHours}:${minutes} ${ampm}`;
        return formattedDate;
    }

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>Edit Campaign Details</h1>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="campaignName" className={classNames({ 'p-error': isFormFieldValid('campaignName') }, 'Label__Text')}>
                                    Campaign Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="campaignName"
                                    placeholder="Enter Campaign Name"
                                    name="campaignName"
                                    maxLength={100}
                                    disabled={true}
                                    value={formik?.values?.campaignName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('campaignName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('campaignName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="startDateTime" className={classNames({ 'p-error': isFormFieldValid('startDateTime') }, 'Label__Text')}>
                                    Campaign Start Date & Time <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="startDateTime"
                                    placeholder="Enter Campaign Start Date & Time"
                                    name="startDateTime"
                                    type="datetime-local"
                                    disabled={true}
                                    value={formik?.values?.startDateTime || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('startDateTime') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('startDateTime')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="endDateTime" className={classNames({ 'p-error': isFormFieldValid('endDateTime') }, 'Label__Text')}>
                                    Campaign End Date & Time <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="endDateTime"
                                    placeholder="Enter Campaign End Date & Time"
                                    name="endDateTime"
                                    type="datetime-local"
                                    disabled={true}
                                    value={formik?.values?.endDateTime || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('endDateTime') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('endDateTime')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="campaignBudget" className={classNames({ 'p-error': isFormFieldValid('campaignBudget') }, 'Label__Text')}>
                                    Campaign Budget <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="campaignBudget"
                                    placeholder="Enter Campaign Budget"
                                    name="campaignBudget"
                                    disabled={true}
                                    value={formik?.values?.campaignBudget || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('campaignBudget') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('campaignBudget')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                <Checkbox inputId="isSubLimits" name="isSubLimits" value={formik?.values?.isSubLimits} checked={formik?.values?.isSubLimits}
                                    onChange={formik.handleChange}
                                    disabled
                                    style={{ marginTop: '10px' }} />
                                    <label htmlFor="isSubLimits" className={classNames({ 'p-error': isFormFieldValid('isSubLimits') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Define Sub Limits
                                    </label>
                                </div>
                                {getFormErrorMessage('noLimits')}
                            </div>
                        </div>
                        {formik?.values?.isSubLimits && 
                        <>
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="frequencyPeriodId" className={classNames({ 'p-error': isFormFieldValid('frequencyPeriodId') }, 'Label__Text')}>
                                        Limit Duration
                                    </label>
                                    <Dropdown
                                        id="frequencyPeriodId"
                                        placeholder="Select Limit Duration"
                                        options={frequencyPeriodLov}
                                        optionLabel="name"
                                        name="frequencyPeriodId"
                                        optionValue="lovId"
                                        disabled={true}
                                        value={formik?.values?.frequencyPeriodId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('frequencyPeriodId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('frequencyPeriodId')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="amount" className={classNames({ 'p-error': isFormFieldValid('amount') }, 'Label__Text')}>
                                        Amount<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="amount"
                                        placeholder="Enter Campaign Budget"
                                        name="amount"
                                        disabled={true}
                                        value={formik?.values?.amount || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('amount') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('amount')}
                                </div>
                            </div>
                        </>
                    }
                    </div>
                </form>
            </div>
        </>
    );
}
export default EditCreateCampaignChecker;

import React from 'react';
import cmsCompany from '../../../../assets/cms/cmsCompany.png';

function MobileView({ icon, title }) {
    return (
        <div className="cmsMobileView__main relative">
            <div className="cmsMobileView__company">
                {icon && <img src={icon} className="cmsMobileView__icon" alt="icon" />}
                {title && <span className="cmsMobileView__title ml-3">{title}</span>}
            </div>
            <img src={cmsCompany} className="blurMobile w-100 h-100" alt="cmsCompany" />
        </div>
    );
}

export default MobileView;

import React, { useState, useEffect } from 'react';

import { handleGetRequest } from '../../../services/GetTemplate';
import { handleRequest } from '../../../utils/HandleRequest/handleRequest';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { useLocation } from 'react-router-dom';

const ViewRuleDomains = () => {
    const initialValues = { name: '', domainCode: '', friendlyName: '', tables: [] };
    const [userLov, setUserLov] = useState([]);
    let { search } = useLocation();
    const [statusLovData, setStatusLovData] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setloading] = useState(false);
    const [useCaseError, setUseCaseError] = useState({});
    const location = useLocation();

    const [viewId] = location.state || {};

    const handleReset = (e) => {
        formik.resetForm();
    };

    const validationSchema = Yup.object({
        tables: Yup.array().required('This Field is required').min(1, 'This Field is required'),
        name: Yup.string()
            .matches(/^[a-zA-Z\s]+[0-9]*$/, 'Only characters are allowed')
            .required('This Field is required'),
        friendlyName: Yup.string()
            .matches(/^[a-zA-Z\s]+[0-9]*$/, 'Only characters are allowed')
            .required('This Field is required')
    });

    const formik = useFormik({
        initialValues,
        validationSchema,

        onSubmit: async (data) => {
            setloading(true);
            let { domainCode, ...rest } = data;
            rest.id = viewId;
            if (rest.tables.length > 0) {
                const transformedArray = rest.tables.map((result) => ({
                    dictionaryTablesId: result.dictionaryTablesId
                }));
                // Update the `rest` object with the transformed array
                rest.tables = transformedArray;
            }

            let requestType = 'POST';
            let apiUrl = '/ruleengine/v1/domain/update';
            let additionalInformation = [{}];
            let res = await handleRequest(rest, requestType, apiUrl, additionalInformation, dispatch, true, false);

            if (res) {
                navigate('/domainConfiguration');
            }

            setloading(false);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const panelFooterTemplate = () => {
        const length = formik.values.tables ? formik.values.tables.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/ruleengine/v1/table/view');

        setUserLov(resp?.payLoad);
    };

    const getUpdatedDomain = async (id) => {
        let requestType = 'POST';
        let apiUrl = '/ruleengine/v1/domain/';
        let additionalInformation = [{}];
        let payLoad = { id };
        let res = await handleRequest(payLoad, requestType, apiUrl, additionalInformation, dispatch, false, true);

        let { code, name, friendlyName, domainTablesList } = res.payLoad;
        formik.setFieldValue('domainCode', code);
        formik.setFieldValue('name', name);
        formik.setFieldValue('friendlyName', friendlyName);

        const filteredTables = domainTablesList.filter((result) => result.dictionaryTables && result.dictionaryTables.friendlyTableName !== null); // Check for null values

        const tableArray = filteredTables.map((result) => ({
            friendlyTableName: result.dictionaryTables.friendlyTableName || '', // Handle null values here
            dictionaryTablesId: result.dictionaryTables.dictionaryTablesId || ''
        }));

        setUserLov(tableArray);
        formik.setFieldValue('tables', tableArray);
    };

    useEffect(() => {
        getUserLov();

        if (formik) {
            getUpdatedDomain(viewId);
        }
    }, []);

    useEffect(() => {
        getUserLov();
    }, []);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>View DETAIL </h2>
                        </div>
                        <div className="Form__Header Full__Width">
                            <div className="title__header">DOMAIN</div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="domainName" className={classNames({ 'p-error': isFormFieldValid('domainName') }, 'Label__Text')}>
                                    Domain Name<span className="Label__Required">*</span>
                                </label>

                                <InputText
                                    maxLength={100}
                                    id="dmainName"
                                    name="name"
                                    disabled
                                    value={formik?.values?.name?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('name')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="domainCodeId" className={classNames({ 'p-error': isFormFieldValid('domainCodeId') }, 'Label__Text')}>
                                    Domain Code
                                </label>

                                <InputText
                                    maxLength={100}
                                    id="domainCode"
                                    name="domainCode"
                                    keyfilter={/^[a-zA-Z\s]+[0-9]*$/}
                                    value={formik?.values?.domainCode?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('domainCode') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('domainCodeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="friendlyNameId" className={classNames({ 'p-error': isFormFieldValid('friendlyNameId') }, 'Label__Text')}>
                                    Friendly Name<span className="Label__Required">*</span>
                                </label>

                                <InputText
                                    maxLength={100}
                                    id="friendlyName"
                                    name="friendlyName"
                                    keyfilter={/^[a-zA-Z\s]+[0-9]*$/}
                                    disabled
                                    value={formik?.values?.friendlyName?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('friendlyName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('friendlyName')}
                            </div>
                        </div>
                        <div className="Form__Header Full__Width">
                            <div className="title__header">LINKING</div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field customMultiselect">
                                <label htmlFor="tablesId" className={classNames({ 'p-error': isFormFieldValid('tablesId') }, 'Label__Text')}>
                                    Tables <span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="tables"
                                    options={userLov}
                                    placeholder="Select Tables"
                                    name="tables"
                                    optionLabel="friendlyTableName"
                                    optionValue="dictionaryTablesId"
                                    display="chip"
                                    filter
                                    panelFooterTemplate={panelFooterTemplate}
                                    value={formik?.values?.tables?.map((trans) => trans.dictionaryTablesId || '')}
                                    // onChange={(e) => {
                                    //     formik.setFieldValue(
                                    //         'tables',
                                    //         e.value.map((lovId) => ({ dictionaryTablesId: lovId }))
                                    //     );
                                    // }}
                                    className={classNames({ 'p-invalid': isFormFieldValid('tables') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('tables')}
                            </div>
                        </div>

                        <div className="Down__Btn my-5">
                            <Button type="submit" label={'Okay'} onClick={() => navigate('/domainConfiguration')} className="Btn__Dark" />
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default ViewRuleDomains;

import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewSignUpLimit() {
    const location = useLocation();
    const { rowData } = location.state || {};
    const tableName = 'TBL_CMS_SIGNUP_LIMIT';

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            cmsSignupLimitId: '',
            noOfAttempts: '',
            time: '',
            isTemporaryBlock: '',
            isPermanentBlock: '',
            blockTime: '',
            isActive: ''
        }
    });

    const getMainCategoryData = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/getsignuplimitsbyid/${rowData?.cmsSignupLimitId}`, true);
        const keyData = res?.payLoad;
        formik.setFieldValue('cmsSignupLimitId', keyData?.cmsSignupLimitId);
        formik.setFieldValue('noOfAttempts', keyData?.noOfAttempts);
        formik.setFieldValue('time', keyData?.time || '');

        formik.setFieldValue('blockTime', keyData?.blockTime);
        formik.setFieldValue('isTemporaryBlock', keyData?.isTemporaryBlock);
        formik.setFieldValue('isPermanentBlock', keyData?.isPermanentBlock);
        formik.setFieldValue('isActive', keyData?.isActive);
    };
    function convertTo24HourFormat(time12Hour) {
        const [time, period] = time12Hour?.split(' ');
        const [hours, minutes] = time?.split(':');
        let formattedHours = hours;
        if (period?.toUpperCase() === 'PM' && hours !== '12') {
            formattedHours = String(Number(hours) + 12);
        } else if (period?.toUpperCase() === 'AM' && hours === '12') {
            formattedHours = '00';
        }

        return `${formattedHours}:${minutes}`;
    }

    useEffect(() => {
        getMainCategoryData();
    }, [rowData]); // eslint-disable-line

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>VIEW DETAIL</h2>
                        </div>
                        <div className="p-field col-12 md:col-6 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="noOfAttempts" className={classNames({ 'p-error': isFormFieldValid('noOfAttempts') }, 'Label__Text')}>
                                    Attempts
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="noOfAttempts"
                                    placeholder="Enter noOfAttempts"
                                    name="noOfAttempts"
                                    value={formik?.values?.noOfAttempts || ''}
                                    disabled
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('noOfAttempts') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('noOfAttempts')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-6  pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="time" className={classNames({ 'p-error': isFormFieldValid('time') }, 'Label__Text')}>
                                    Enter Time 
                                </label>
                                <InputText id="time" name="time" placeholder='Enter Time In Minutes' value={formik?.values?.time} onChange={formik.handleChange} disabled className={classNames({ 'p-invalid': isFormFieldValid('time') }, 'Input__Round')} />

                                {getFormErrorMessage('time')}
                            </div>
                        </div>

                        <div className="Form__Header Full__Width">
                            <h2>BREACH LIMITS</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox
                                        inputId="isTemporaryBlock"
                                        name="isTemporaryBlock"
                                        checked={formik.values.isTemporaryBlock === 'Y'}
                                        onChange={(e) => {
                                            const newValue = e.checked ? 'Y' : 'N';
                                            formik.setFieldValue('isTemporaryBlock', newValue);
                                            if (e.checked) {
                                                formik.setFieldValue('isPermanentBlock', 'N');
                                            }
                                        }}
                                        style={{ marginTop: '10px' }}
                                        disabled
                                    />{' '}
                                    <label htmlFor="isTemporaryBlock" className={classNames({ 'p-error': isFormFieldValid('isTemporaryBlock') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Temporary Block
                                    </label>
                                </div>
                                {getFormErrorMessage('isTemporaryBlock')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="blockTime" className={classNames({ 'p-error': isFormFieldValid('blockTime') }, 'Label__Text')}>
                                    Time 
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="blockTime"
                                    placeholder="Enter Time In Minutes"
                                    name="blockTime"
                                    value={formik?.values?.blockTime || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('blockTime') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('blockTime')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox
                                        inputId="isPermanentBlock"
                                        name="isPermanentBlock"
                                        checked={formik.values.isPermanentBlock === 'Y'}
                                        onChange={(e) => {
                                            const newValue = e.checked ? 'Y' : 'N';
                                            formik.setFieldValue('isPermanentBlock', newValue);
                                            if (e.checked) {
                                                formik.setFieldValue('isTemporaryBlock', 'N');
                                            }
                                        }}
                                        style={{ marginTop: '10px' }}
                                        disabled
                                    />{' '}
                                    <label htmlFor="isPermanentBlock" className={classNames({ 'p-error': isFormFieldValid('isPermanentBlock') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Permanent Block
                                    </label>
                                </div>
                                {getFormErrorMessage('isPermanentBlock')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="button" label="Okay" onClick={() => navigate('/signuplimits')} className="Btn__Dark" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={rowData?.cmsSignupLimitId} tableName={tableName} />
        </>
    );
}

export default ViewSignUpLimit;

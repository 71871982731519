import React, { useState } from 'react'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import EditPetroPocketStatement from '../AccountDetailsBasicDialogs/EditPetroPocketStatement'

const EditPetroPocket = ({ rowData }) => {

    const [displayDialog, setDisplayDialog] = useState(false)

    const handleDialog = () => {
        setDisplayDialog(true)
    }

    return (
        <div>

            <Dialog header="Close Account Disown CNIC" blockScroll draggable={false} visible={displayDialog} style={{ width: '30vw' }} onHide={() => setDisplayDialog(false)}>
                <EditPetroPocketStatement rowData={rowData} onHide={setDisplayDialog} />
            </Dialog>

            <div>
                <h5 className="secondary-color mt-6">
                    <b>PETRO POCKET</b>
                </h5>
            </div>

            <div className="p-fluid p-formgrid grid mb-3">
                <div className="p-field col-12 md:col-4">
                    <div className="p-field">
                        <label className="Label__Text">Account Type</label>
                        <div className="flex align-items-center">
                            <InputText
                                id="accountTypeId"
                                value={rowData?.petroAccountType}
                                className="Input__Round"
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className="p-field col-12 md:col-4">
                    <div className="p-field">
                        <label className="Label__Text">Account Opening Mode</label>
                        <div className="flex align-items-center">
                            <InputText
                                value={rowData?.channelName}
                                className='Input__Round'
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className="p-field col-12 md:col-4">
                    <div className="p-field">
                        <label className="Label__Text">Account Status</label>
                        <div className="flex align-items-center">
                            <InputText
                                value={rowData?.petroAccountStatus}
                                className="Input__Round"
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className="p-field col-12 md:col-4">
                    <div className="p-field">
                        <label className="Label__Text">Terms & Condition Accepted Date</label>
                        <div className="flex align-items-center">
                            <InputText
                                value={rowData?.petroTncDate && rowData?.petroTncDate.split(" ")[0]}
                                className="Input__Round"
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className="p-field col-12 md:col-4">
                    <div className="p-field">
                        <label className="Label__Text">Current Balance</label>
                        <div className="flex align-items-center">
                            <InputText
                                value={rowData?.petroBalance}
                                className="Input__Round"
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className="p-field col-12 md:col-4">
                    <div className="p-field">
                        <label className="Label__Text">Location</label>
                        <div className="flex align-items-center">
                            <InputText
                                value={rowData?.latitude && rowData?.latitude ? rowData?.latitude + ", " + rowData?.lognitude : ""}
                                className="Input__Round"
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex justify-content-center'>
                <div className='Down__Btn' style={{ width: "300px", marginBottom: "25px" }}>
                    <Button label='Petro Pocket Statement' className='Btn__Dark w-100' onClick={handleDialog} />
                </div>
            </div>

        </div>
    )
}

export default EditPetroPocket
import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../services/GetTemplate';
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import { FilterMatchMode } from 'primereact/api';
import { handlePostRequest } from '../../../services/PostTemplate';
import ActiveInActiveDialog from '../../../components/ActiveInActiveDialog/ActiveInActiveDialog';
import { Paginator } from 'primereact/paginator';
import { PaginationCall, PaginationTemplate } from '../../../components/Pagination';

function CardPricingProfile() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [showModal, setShowModal] = useState(false);
    const [switchData, setSwitchData] = useState([]);
    const [cardTypeData, setCardTypeData] = useState([]);
    const [cardType, setCardType] = useState([]);
    const [feeType, setFeeType] = useState([]);
    const [accountType, setAccountType] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [districtLov, setDistrictLov] = useState([]);
    const [cardPricingData, setCardPricingData] = useState([]);

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [customFirst1, setCustomFirst1] = useState(0);
    const [customRows1, setCustomRows1] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [render, setRender] = useState(false);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        regionName: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        districtId: { value: '', matchMode: FilterMatchMode.EQUALS },
        statusId: { value: '', matchMode: FilterMatchMode.EQUALS },
        dateFrom: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        dateTo: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        createdBy: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        updatedBy: { value: '', matchMode: FilterMatchMode.STARTS_WITH }
    });

    const dataTableRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cardPricingProfileName: '',
            cardTypeId: '',
            accountLevelId: '',
            feeTypeId: '',
            dateFrom: '',
            dateTo: '',
            createdBy: '',
            updatedBy: '',
            statusId: '2',
            rowStart: customFirst1,
            rowEnd: customRows1 + customFirst1
        },

        validate: (data) => {
            let errors = {};
            if (data.dateTo && !data.dateFrom) {
                errors.dateFrom = 'Date From is required when Date To is provided';
            }

            if (data.dateFrom && data.dateTo && data.dateFrom > data.dateTo) {
                errors.dateTo = 'Date To must be greater than or equal to Date From';
            }
            return errors;
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            dataTableRef.current.reset();
            const res = await dispatch(handlePostRequest(newData, '/debitcardmanagement/v1/cards/getallcardpricingprofiles', true, true));
            setCardPricingData(res?.payLoad);
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">Fee Pricing Existing Detail</h5>
            </div>
        );
    };
    //
    const header = () => renderHeader();

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const handleSwitchChange = async (id, isActiveData, rowData) => {
        const updatedData = cardTypeData?.map((item) => {
            if (item?.dcPricingProfileId === switchData?.dcPricingProfileId) {
                const isActive = item?.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setCardTypeData(updatedData);
        let isactive = '';
        if (switchData?.isActive === 'Y') {
            isactive = 'N';
        } else if (switchData?.isActive === 'N') {
            isactive = 'Y';
        }
        const previousValue = switchData?.isActive;
        const data = {
            isActive: isactive,
            dcPricingProfileId: switchData?.dcPricingProfileId,
            agentId: switchData?.tblAgent?.tblAgent,
            amount: switchData.amount,
            chargingProfile: switchData?.chargingProfile,
            chargingProfileType: switchData?.chargingProfileType,
            dcPricingProfileName: switchData?.dcPricingProfileName,
            salaryTransactionType: switchData?.salaryTransactionType,
            waiver: switchData?.waiver,

            lkpAccountClassificationId: switchData?.lkpAccountClassification?.accountClassificationId,
            lkpAccountLevelId: switchData?.lkpAccountLevel?.accountLevelId,
            dcCardProductType: switchData?.dcCardProductType,
            lkpDcCardTypeId: switchData?.lkpDcCardType?.dcCardTypeId,
            lkpDcFeeTypeId: switchData?.lkpDcFeeType?.dcFeeTypeId,
            lkpDcInstanceRequestId: switchData?.lkpDcInstanceRequest?.dcInstanceRequestId,
            lkpDcRecoveryId: switchData?.lkpDcRecovery?.dcRecoveryId,
            lkpDcWaiverId: switchData?.lkpDcWaiver?.dcWaiverId,
            lkpSegmentId: switchData?.lkpSegment?.segmentId,
            tblDcInstallmentPlanId: switchData?.tblDcInstallmentPlan?.dcInstallmentPlanId
        };
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/debitcardmanagement/v1/cards/inactivecardpricingprofile', true, true));
        if (res?.responseCode !== '030000') {
            const revertedData = cardTypeData.map((item) => {
                if (item.dcPricingProfileId === id) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setCardTypeData(revertedData);
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <InputSwitch
                        checked={rowData.isActive === 'Y'}
                        disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                        onChange={() => {
                            setSwitchData(rowData);
                            setShowModal(true);
                            // handleSwitchChange(rowData?.dcInstallmentPlanId, rowData?.isActive, rowData);
                        }}
                    />
                    <Button
                        tooltip="Edit Detail"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: 'top' }}
                        disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) || (rowData?.lkpStatus?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                        //onClick={() => EditTypeOfAccountHandled(rowData)}
                        onClick={() => navigate(`/editcardpricingprofile?dcPricingProfileId=${rowData?.dcPricingProfileId}`)}
                        className="p-button-rounded p-button-primary"
                    />
                    <Button
                        tooltip="View Detail"
                        tooltipOptions={{ position: 'top' }}
                        // disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                        icon="pi pi-eye"
                        onClick={() => navigate(`/viewcardpricingprofile?dcPricingProfileId=${rowData?.dcPricingProfileId}`)}
                        className="p-button-rounded"
                        style={{ backgroundColor: '#F2AC57', border: 'none' }}
                    />
                </div>
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        const filteredStatus = statusLovData.filter((item) => item.lovId == rowData?.statusId);
        const statusColor = filteredStatus[0]?.name === 'Approved' ? '#14A38B' : filteredStatus[0]?.name === 'Pending' ? '#F2AC57' : '#FF7171';

        return (
            <p style={{ color: statusColor }}>
                <b>{filteredStatus[0]?.name}</b>
            </p>
        );
    };

    const AddCardPricingProfile = () => {
        navigate('/addcardpricingprofile');
    };

    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };
    const getcardpricingdata = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcardpricingdata');
        setCardType(res?.payLoad?.cardType);

        setAccountType(res?.payLoad?.accountType);
        setFeeType(res?.payLoad?.feeType);
    };
    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(resp?.payLoad);
    };
    const resetHandle = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    useEffect(() => {
        getcardpricingdata();
        getStatusLov();
    }, []);
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];
    const handleShowModal = () => {
        setShowModal(false);
    };

    const props = {
        showModal,
        handleShowModal,
        handleSwitchChange
    };

    const onCustomPageChange1 = (event) => {
        setCustomFirst1(event.first);
        setCustomRows1(event.rows);
        setCurrentPage(event.page + 1);
        setRender(true);
    };

    const RequestData = {
        cardPricingProfileName: formik.values.cardPricingProfileName,
        cardTypeId: formik.values.cardTypeId,
        accountLevelId: formik.values.accountLevelId,
        accountLevelId: formik.values.accountLevelId,
        feeTypeId: formik.values.feeTypeId,
        dateFrom: formik.values.dateFrom,
        dateTo: formik.values.dateTo,
        statusId: formik.values.statusId,
        createdBy: formik.values.createdBy,
        updatedBy: formik.values.updatedBy,

        rowStart: customFirst1,
        rowEnd: customRows1 + customFirst1
    };

    return (
        <>
            <ActiveInActiveDialog {...props} />

            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={() => AddCardPricingProfile()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>
            <PaginationCall
                render={render}
                customFirst1={customFirst1}
                customRows1={customRows1}
                currentPage={currentPage}
                dataTableRef={dataTableRef}
                setData={setCardPricingData}
                RequestData={RequestData}
                url={'/debitcardmanagement/v1/cards/getallcardpricingprofiles'}
                responseCode={'220000'}
            />
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="cardPricingProfileName" className={classNames({ 'p-error': isFormFieldValid('cardPricingProfileName') }, 'Label__Text')}>
                                    Profile Name
                                </label>
                                <InputText
                                    id="cardPricingProfileName"
                                    name="cardPricingProfileName"
                                    value={formik?.values?.cardPricingProfileName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cardPricingProfileName') }, 'Input__Round')}
                                    aria-required
                                />
                                {getFormErrorMessage('cardPricingProfileName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="cardTypeId" className={classNames({ 'p-error': isFormFieldValid('cardTypeId') }, 'Label__Text')}>
                                    Card Type
                                </label>
                                <Dropdown
                                    id="cardTypeId"
                                    placeholder="Select"
                                    options={cardType}
                                    optionLabel="name"
                                    name="cardTypeId"
                                    optionValue="lovId"
                                    value={formik.values.cardTypeId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cardTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cardTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountLevelId" className={classNames({ 'p-error': isFormFieldValid('accountLevelId') }, 'Label__Text')}>
                                    Account Type
                                </label>
                                <Dropdown
                                    id="accountLevelId"
                                    placeholder="Select"
                                    options={accountType}
                                    optionLabel="name"
                                    name="accountLevelId"
                                    optionValue="lovId"
                                    value={formik.values.accountLevelId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountLevelId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('accountLevelId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="feeTypeId" className={classNames({ 'p-error': isFormFieldValid('feeTypeId') }, 'Label__Text')}>
                                    Fee Type
                                </label>
                                <Dropdown
                                    id="feeTypeId"
                                    placeholder="Select"
                                    options={feeType}
                                    optionLabel="name"
                                    name="feeTypeId"
                                    optionValue="lovId"
                                    value={formik.values.feeTypeId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('feeTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('feeTypeId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText
                                    max={previousDate}
                                    id="dateFrom"
                                    placeholder=""
                                    name=""
                                    type="date"
                                    value={formik?.values?.dateFrom?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText id="dateTo" type="date" max={previousDate} name="dateTo" value={formik?.values?.dateTo} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')} />

                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="statusId" className={classNames({ 'p-error': isFormFieldValid('statusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select statusId"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>
                        <div className="Down__Btn">
                            <Button disabled={loading} loadingIcon={loadingIcon} label="Search" className="Btn__Dark" />
                            <Button disabled={loading} label="Reset" onClick={(e) => resetHandle(e)} className="Btn__Transparent" />
                        </div>
                    </div>
                </form>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            className="datatable-responsive"
                            globalFilterFields={['accountLevelName', 'createuser', 'createdate', 'lkpStatus.statusName']}
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={cardPricingData}
                        >
                            <Column field="dcPricingProfileName" header="Profile Name" />
                            <Column field="dcCardTypeName" header="Card Type" />
                            <Column field="accountLevelName" header="Account Type" />
                            <Column field="dcFeeTypeName" header="Fee Type" />
                            <Column field="chargingProfile" header="Charging Profile" />
                            <Column field="amount" header="Amount" />
                            <Column field="createdBy" header="Created By" />
                            {/* <Column field="createdate" header="Created On" /> */}
                            <Column header="Created On" body={createdDateAndTimeBodyTemplate} />

                            <Column field="status" header="Status" body={statusBodyTemplate} />
                            <Column header="Actions" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                        <Paginator template={PaginationTemplate(false)} first={customFirst1} rows={customRows1} totalRecords={cardPricingData?.[0]?.totalCount} onPageChange={onCustomPageChange1}></Paginator>
                    </div>
                </div>
            </div>
        </>
    );
}
export default CardPricingProfile;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import * as Yup from 'yup';

import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';

function EditDebitCardChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [statusLovData, setStatusLovData] = useState([]);
    const [requestTypeLov, setRequestTypeLov] = useState([]);
    const [debitRecord, setDebitRecord] = useState([]);
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const tableName = query.get('tableName');
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');

    const printingSite = [
        {
            name: 'ISLAMABAD'
        },
        {
            name: 'LAHORE'
        },
        {
            name: 'KARACHI'
        }
    ];
    //getting rowData through useLocation

    const validationSchema = Yup.object({
        action: Yup.string().required('Action should not be empty'),
        checkerComments: Yup.string().required('This Field is required').notOneOf([''], 'This Field should not be empty').max(500, 'Maximum 500 characters needed')
    });
    //formik
    const formik = useFormik({
        initialValues: {
            cardStatus: '',
            action: '',
            checkerComments: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (data) => {
            // setloading(true);
            let payLoad = {
                debitCardUpdateRequest: {
                    debitCardRequestId: parseInt(refTableId),
                    accountNumber: data.accountNumber,
                    embosingName: data.embosingName,
                    address: data.address,
                    cardTypeId: data.cardType,
                    printingSite: data.printingSite,
                    status: formik.values.cardStatus,
                    comments: formik.values.comments
                },
                mcActionRequest: {
                    mcRequestId: mcRequestId,
                    mcPeindingRequestId: mcPeindingRequestId,
                    checkerId: '18',
                    checkerComments: data.checkerComments,
                    action: data.action,
                    updatedIndex: null
                }
            };
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: payLoad,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(newData, '/debitcardmanagement/v1/cards/cardrequestcheckeraction', true, false, 'requestchecker'));

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    //Functions to handle validations error
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getDebitRecord = async () => {
        let resp;
        if (requestType === 'I') {
            resp = await handleGetRequest(`/debitcardmanagement/v1/cards/getcardbyid/${refTableId}`);
            const {
                accountNumber,
                cardStatus,
                embosingName,
                address,
                lkpDcFeeType: { dcFeeTypeId },
                printingSite
            } = resp.payLoad;

            formik.setFieldValue('cardStatus', cardStatus);
            formik.setFieldValue('accountNumber', accountNumber);
            formik.setFieldValue('embosingName', embosingName);
            formik.setFieldValue('address', address);
            formik.setFieldValue('cardType', dcFeeTypeId.toString());
            formik.setFieldValue('cardStatus', cardStatus);
            formik.setFieldValue('printingSite', printingSite);
        } else {
            resp = await handleGetRequest(`/debitcardmanagement/v1/cards/getmcrequestbyid/${mcRequestId}`);
            let resp1 = await handleGetRequest(`/debitcardmanagement/v1/cards/getcardbyid/${refTableId}`);
            // const { cardStatus } = resp1.payLoad;
            const { accountNumber, embosingName, address, cardTypeId, printingSite, status, comments } = resp.payLoad;

            formik.setFieldValue('cardStatus', status);
            formik.setFieldValue('comments', comments);
            formik.setFieldValue('accountNumber', accountNumber);
            formik.setFieldValue('embosingName', embosingName);
            formik.setFieldValue('address', address);
            formik.setFieldValue('cardType', cardTypeId.toString());
            // formik.setFieldValue('cardStatus', cardStatus);
            formik.setFieldValue('printingSite', printingSite);
        }
    };

    const getRequestType = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getdcfeetype');
        setRequestTypeLov(resp?.payLoad);
    };

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = res?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        getStatusListData();
        getDebitRecord();
        getRequestType();
    }, []);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>Edit Detail</h2>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="cardStatus" className={classNames({ 'p-error': isFormFieldValid('cardStatus') }, 'Label__Text')}>
                                    Card Status<span className="Label__Required"></span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="cardStatus"
                                    //placeholder="Enter Card Details"
                                    name="cardStatus"
                                    value={formik?.values?.cardStatus?.replace(/\s\s+/g, ' ') || ''}
                                    disabled
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cardStatus') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('cardStatus')}
                            </div>
                        </div>
                        {formik?.values?.cardStatus === 'Rejected' && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="comments" className={classNames({ 'p-error': isFormFieldValid('comments') }, 'Label__Text')}>
                                        Reason <span className="Label__Required"></span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="comments"
                                        //placeholder="Enter Card Details"
                                        name="comments"
                                        value={formik?.values?.comments?.replace(/\s\s+/g, ' ') || ''}
                                        disabled
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('comments') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('comments')}
                                </div>
                            </div>
                        )}
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountNumber" className={classNames({ 'p-error': isFormFieldValid('accountNumber') }, 'Label__Text')}>
                                    Account Details<span className="Label__Required"></span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="accountNumber"
                                    // placeholder="Enter Card Details"
                                    name="accountNumber"
                                    disabled
                                    value={formik?.values?.accountNumber?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountNumber') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('accountNumber')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="embosingName" className={classNames({ 'p-error': isFormFieldValid('embosingName') }, 'Label__Text')}>
                                    Embossing Name<span className="Label__Required"></span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="embosingName"
                                    // placeholder="Enter Card Details"
                                    name="embosingName"
                                    value={formik?.values?.embosingName?.replace(/\s\s+/g, ' ') || ''}
                                    disabled
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('embosingName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('embosingName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="address" className={classNames({ 'p-error': isFormFieldValid('address') }, 'Label__Text')}>
                                    Address<span className="Label__Required"></span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="address"
                                    //placeholder="Enter Card Details"
                                    name="address"
                                    value={formik?.values?.address?.replace(/\s\s+/g, ' ') || ''}
                                    disabled
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('address') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('address')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="cardType" className={classNames({ 'p-error': isFormFieldValid('cardType') }, 'Label__Text')}>
                                    Card Type
                                </label>
                                <Dropdown
                                    id="cardType"
                                    //placeholder="Select Request Type"
                                    options={requestTypeLov}
                                    optionLabel="name"
                                    name="cardType"
                                    optionValue="lovId"
                                    value={formik.values.cardType || ''}
                                    disabled
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cardType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cardType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="printingSite" className={classNames({ 'p-error': isFormFieldValid('printingSite') }, 'Label__Text')}>
                                    Printing Site
                                </label>
                                <Dropdown
                                    id="printingSite"
                                    // placeholder="Select Request Type"
                                    options={printingSite}
                                    optionLabel="name"
                                    name="printingSite"
                                    optionValue="name"
                                    value={formik.values.printingSite || ''}
                                    disabled
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('printingSite') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('printingSite')}
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                            Action
                                            <span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="action"
                                            placeholder="Select Action"
                                            options={statusLovData}
                                            optionLabel="name"
                                            name="action"
                                            optionValue="lovId"
                                            optionDisabled={(option) => option?.name === 'Pending'}
                                            value={formik.values.action || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('action')}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-12">
                                    <div className="p-field">
                                        <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                            Comments
                                            <span className="Label__Required">*</span>
                                        </label>
                                        <InputTextarea
                                            maxLength={200}
                                            autoResize={false}
                                            rows={5}
                                            cols={30}
                                            id="checkerComments"
                                            name="checkerComments"
                                            value={formik?.values?.checkerComments || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                        />

                                        {getFormErrorMessage('checkerComments')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="submit" label="Update" className="Btn__Dark" />
                        <Button label="Cancel" type="button" onClick={() => navigate('/requestChecker')} className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default EditDebitCardChecker;

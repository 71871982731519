import React from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

const RetailerCommission = ({ retailerCommission }) => {
    return (
        <div className="col-12 md:col-6">
            <div className="card Card__Round">
                <div className="cardBarCharts">
                    <h5>Retailer Commission</h5>
                    <div className="pt-3">
                        <DataTable value={retailerCommission && retailerCommission} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} showGridlines>
                            <Column field="parameterName" header="Product" style={{ width: '25%' }} />
                            <Column field="parameterValue" header="Tax" style={{ width: '25%' }} />
                            <Column field="parameterValueTwo" header="Amount" style={{ width: '25%' }} />
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RetailerCommission;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useLocation } from 'react-router-dom';

function EditDocumentChecker({ lendingData }) {
    const [fieldTableData, setFieldTableData] = useState([]);
    const [documentTableData, setDocumentTableData] = useState([]);
    const [documentData, setDocumentData] = useState({
        documentName: '',
        allowedExtension: '',
        allowedSize: ''
    });
    const [documentError, setDocumentError] = useState({
        documentName: '',
        allowedExtension: '',
        allowedSize: ''
    });

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const requestType = query.get('requestType');
    const requestTypeDescr = query.get('requestTypeDescr');

    useEffect(() => {
        if (requestType === 'I' || (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request')) {
            if (lendingData) {
                if (lendingData?.tblLmsDocuments) {
                    setDocumentTableData(lendingData?.tblLmsDocuments);
                }
            }
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            if (lendingData[3]) {
                if (lendingData[3]?.documentInformationRequests) {
                    const modifiedArray = lendingData[3]?.documentInformationRequests?.map((item) => ({
                        documentName: item.documentName,
                        allowedExtension: item.documentExtension,
                        allowedSize: item.documentSize
                    }));
                    setDocumentTableData(modifiedArray);
                }
            }
        }
    }, [lendingData]); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            documentName: '',
            allowedExtension: '',
            allowedSize: ''
        }
    });

    const handledDeleteFieldsData = (e, rowData) => {
        e.preventDefault();
        const newFilterData = fieldTableData.filter((value) => value.fieldName !== rowData.fieldName);
        setFieldTableData(newFilterData);
    };

    const actionFieldsBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button tooltip="Delete" disabled tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteFieldsData(e, rowData)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
                </div>
            </>
        );
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handledFieldInputdocumentData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        // const checked = e.target.checked;
        // const type = e.target.type;

        setDocumentData({ ...documentData, [name]: value });

        // Clear the error message for the field being edited
        setDocumentError({
            ...documentError,
            [name]: ''
        });
    };

    const handleDocumentAdd = (e) => {
        e.preventDefault();

        const errors = {
            documentName: '',
            allowedExtension: '',
            allowedSize: ''
        };
        if (documentData.documentName === '') {
            errors.documentName = 'This field is required';
        }
        if (documentData.allowedExtension === '') {
            errors.allowedExtension = 'This field is required';
        }
        if (documentData.allowedSize === '') {
            errors.allowedSize = 'This field is required';
        }

        setDocumentError(errors);

        if (Object.values(errors).every((error) => error === '')) {
            const newDocumentTableData = {
                documentName: documentData.documentName,
                allowedExtension: documentData.allowedExtension,
                allowedSize: documentData.allowedSize
            };
            setDocumentTableData([...documentTableData, newDocumentTableData]);
            setDocumentData({ documentName: '', allowedExtension: '', allowedSize: '' });
        }
    };

    const allowedExtensionLov = [
        { name: 'html', code: 'html' },
        { name: 'jpg', code: 'jpg' },
        { name: 'Png', code: 'Png' },
        { name: 'Pfd', code: 'Pfd' }
    ];

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>DOCUMENT/ EDIT DETAILS</h1>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="documentName" className={classNames({ 'p-error': isFormFieldValid('documentName') }, 'Label__Text')}>
                                    Document Name <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    id="documentName"
                                    disabled
                                    placeholder="Enter Document Name"
                                    name="documentName"
                                    value={documentData.documentName || ''}
                                    onChange={handledFieldInputdocumentData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('documentName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('documentName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="documentExtension" className={classNames({ 'p-error': isFormFieldValid('documentExtension') }, 'Label__Text')}>
                                    Document Extension<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    disabled
                                    id="documentExtension"
                                    placeholder="Select Allowed Extension"
                                    options={allowedExtensionLov}
                                    optionLabel="name"
                                    name="documentExtension"
                                    optionValue="code"
                                    value={documentData?.documentExtension || ''}
                                    onChange={handledFieldInputdocumentData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('documentExtension') }, 'Dropdown__Round')}
                                />
                                <small className="p-error">{documentError.documentExtension}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="allowedSize" className={classNames({ 'p-error': isFormFieldValid('allowedSize') }, 'Label__Text')}>
                                    Allowed Size(1Mb,2Mb) <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    id="allowedSize"
                                    placeholder=""
                                    disabled
                                    name="allowedSize"
                                    value={documentData.allowedSize || ''}
                                    onChange={handledFieldInputdocumentData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('allowedSize') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('allowedSize')}
                            </div>
                        </div>

                        <div className="Down__Btn">
                            <Button disabled label="Add" onClick={handleDocumentAdd} className="Btn__Dark" style={{ marginTop: '15px' }} />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className="card">
                                <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={documentTableData}>
                                    <Column field="documentName" header="Document Name" />
                                    <Column field="allowedExtension" header="Allowed Extension" />
                                    <Column field="allowedSize" header="Allowed Size" />
                                    <Column header="Actions" body={actionFieldsBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    {/* <div className="Down__Btn">
                        <Button disabled={loading} label={parseInt(lendingData?.currentStep) >= 3 ? "Edit" : "Save"} className="Btn__Dark" />
                    </div> */}
                </form>
            </div>
        </>
    );
}
export default EditDocumentChecker;

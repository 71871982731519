import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import classNames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { handlePostRequest } from '../../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../../services/GetTemplate';
import { loadingAction } from '../../../../../redux/actions/loadingAction';
import ScrollMover from '../../../../../components/ScrollMover';
import SecuredFileChange from '../../../../../components/SecuredFileChange';

function AddFranchise({ parentData }) {
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [loading, setloading] = useState(false);
    const [pricingProfile, setPricingProfile] = useState([]);
    const [commissionProfile, setCommissionProfile] = useState([]);
    const [limitProfile, setLimitProfile] = useState([]);
    const [productcatalog, setProductcatalog] = useState([]);
    const [nearestBranch, setNearestBranch] = useState([]);
    const [channelOption, setChannelOption] = useState([]);
    const [kycFields, setKycFields] = useState([]);
    const kfsRef = useRef(null);
    const digitalSigningRef = useRef(null);
    const searchEmpId = useRef(null);
    const [kfsInput, setKfsInput] = useState('');
    const [kfsInputFile, setKfsInputFile] = useState('');
    const [kfsInputBase64, setKfsInputBase64] = useState('');
    const [kfsLink, setKfsLink] = useState('');
    const [digitalSigningInput, setDigitalSigningInput] = useState('');
    const [digitalSigningFile, setDigitalSigningFile] = useState('');
    const [digitalSigningInputBase64, setDigitalSigningInputBase64] = useState('');
    const [digitalSigningLink, setDigitalSigningLink] = useState('');
    const [kycSetHeadId, setKycSetHeadId] = useState();
    const [tblKycSetDetails, setTblKycSetDetails] = useState([]);
    const [empIdData, setEmpIdData] = useState([]);
    const [agentId, setAgentId] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accountClassificationId = parentData.lovId;
    const [useCaseError, setUseCaseError] = useState({
        empId: null
    });
    const [kfsFileError, setKfsFileError] = useState('');
    const [dsFileError, setDsFileError] = useState('');

    const kycFixed = ['cnic', 'mobileNumber', 'cnicIssuanceDate', 'address', 'name'];

    useEffect(() => {
        const getKycFields = async () => {
            const res = await handleGetRequest(`/account/v1/agentaccount/getkycbyclassificationname/${parentData.name}`);

            setKycSetHeadId(res.payLoad[0].kycSetHeadId);
            let tblkyc = res?.payLoad[0].tblKycSetDetails;

            let dynamicFields = {};
            tblkyc.map((result) => {
                dynamicFields[result?.tblKycAttribute?.attributeName] = '';
            });
            // setDynamicFields([dynamicFields])

            const dynamicInitialValues = {
                ...initialValues,
                ...dynamicFields
            };

            // Set the initial values for the form
            formik.setValues(dynamicInitialValues);

            setTblKycSetDetails(tblkyc);
        };
        getKycFields();
    }, []);

    const initialValues = {
        pricingProfileId: '',
        commissionId: '',
        transLimitId: '',
        parentAgentId: '',
        channelIdList: [],
        empId: '',
        hierarchyName: '',
        productCatalogId: '',
        branchId: '',
        chequeBook: '',
        debitCard: '',
        noOfLeaves: '',
        debitCardAddress: '',
        debitCardName: '',
        atmWaiver: 'N',
        chequeWaiver: 'N',
        kfsInput: '',
        digitalSigningInput: ''
    };

    function getMaxRegexLength(regex) {
        // Remove leading and trailing slashes from the regular expression.
        const regexPattern = regex.toString().slice(1, -1);

        // Use a regular expression to find the maximum length.
        const lengthRegex = /\{(\d+)(,(\d+))?\}/g;
        let maxLength = 0;

        let match;
        while ((match = lengthRegex.exec(regexPattern)) !== null) {
            const min = parseInt(match[1], 10);
            const max = match[3] ? parseInt(match[3], 10) : min;
            if (max > maxLength) {
                maxLength = max;
            }
        }

        return maxLength;
    }

    const validationSchema = Yup.object({
        pricingProfileId: Yup.string().required('This Field is required'),
        commissionId: Yup.string().required('This Field is required'),
        transLimitId: Yup.string().required('This Field is required'),
        parentAgentId: Yup.string().required('This Field is required'),
        channelIdList: Yup.array().required('This Field is required').min(1, 'This Field is required'),
        empId: Yup.string().required('This Field is required'),
        hierarchyName: Yup.string().required('This Field is required'),
        productCatalogId: Yup.string().required('This Field is required'),
        branchId: Yup.string().required('This Field is required'),
        chequeBook: Yup.boolean(),
        debitCard: Yup.boolean(),
        kfsInput: Yup.string().required('This Field is required'),
        digitalSigningInput: Yup.string().when('chequeBook', {
            is: true,
            then: Yup.string().required('This Field is required'),
            otherwise: Yup.string().notRequired()
        }),
        noOfLeaves: Yup.string().when('chequeBook', {
            is: true,
            then: Yup.string().required('This Field is required'),
            otherwise: Yup.string().notRequired()
        }),
        debitCardName: Yup.string().when('debitCard', {
            is: true,
            then: Yup.string()
                .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
                .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
                .required('This Field is required'),
            otherwise: Yup.string().notRequired()
        }),
        debitCardAddress: Yup.string().when('debitCard', {
            is: true,
            then: Yup.string().required('This Field is required'),
            otherwise: Yup.string().notRequired()
        }),

        ...tblKycSetDetails.reduce((fieldValidations, result) => {
            const attributeName = result?.tblKycAttribute?.attributeName;
            const regexExpression = result?.tblKycAttribute;
            const date = result?.tblKycAttribute?.attributeName.toLowerCase().includes('date');
            const regExp = new RegExp(regexExpression.tblValidator.argumentValue);
            const maxLength = getMaxRegexLength(regExp);

            if (kycFixed?.includes(result?.tblKycAttribute?.attributeName) || (result.isMandatory === 'Y' && result?.isActive === 'Y')) {
                // Validate that the field is not empty
                fieldValidations[attributeName] = Yup.string().test('dynamicValidation', 'Invalid format', function (value) {
                    // Check if a value is entered and apply regular expression validation
                    if (value) {
                        if (value.length > maxLength && !date) {
                            if (maxLength > 0) {
                                return this.createError({
                                    path: this.path,
                                    message: `Value exceeds the maximum length of ${maxLength}`
                                });
                            }
                        }

                        return regExp.test(value);
                    } else {
                        // If no value is entered and the field is mandatory, show it as required
                        if (kycFixed?.includes(result?.tblKycAttribute?.attributeName) || result.isMandatory === 'Y') {
                            return this.createError({
                                path: this.path,
                                message: 'This Field is required'
                            });
                        }

                        // If no value is entered and the field is not mandatory, no error is shown.
                        return true;
                    }
                });
            } else if (result?.isActive === 'Y') {
                // If the item is not mandatory but active, only apply the regex validation
                fieldValidations[attributeName] = Yup.string().test('dynamicValidation', 'Invalid Format', function (value) {
                    if (value) {
                        // Check if value is not empty
                        if (value.length > maxLength && !date) {
                            if (maxLength > 0) {
                                return this.createError({
                                    path: this.path,
                                    message: `Value exceeds the maximum length of ${maxLength}`
                                });
                            }
                        }

                        return regExp.test(value);
                    }
                    // If the value is empty, no error is shown.
                    return true;
                });
            }

            return fieldValidations;
        }, {})
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: initialValues,

        onSubmit: async (data, actions) => {
            setloading(true);
            const arrayOfChannelIds = formik?.values?.channelIdList?.map((item) => {
                return parseInt(item?.lovId, 10) || 0;
            });
            data['debitCard'] = formik?.values?.debitCard ? 'Y' : 'N';
            data['chequeBook'] = formik?.values?.chequeBook ? 'Y' : 'N';
            data['empId'] = empIdData;
            const nonRequiredFields = [];
            kycFields[0]?.tblKycSetDetails?.forEach((item) => {
                if (kycFixed?.includes(item?.tblKycAttribute?.attributeName) && item?.isActive === 'Y') {
                    const attributeName = item?.tblKycAttribute?.attributeName;
                    data[attributeName] = formik.values[item?.tblKycAttribute?.attributeName];
                } else {
                    if (formik.values[item?.tblKycAttribute?.attributeName] && item?.isActive === 'Y') {
                        nonRequiredFields.push({
                            infoKey: item?.tblKycAttribute?.attributeName,
                            infoValue: formik.values[item?.tblKycAttribute?.attributeName]
                        });
                    }
                }
            });

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data,
                        contact: false,
                        accountClassificationName: parentData?.name,
                        channelIdList: arrayOfChannelIds
                    },
                    additionalInformation: nonRequiredFields,
                    checkSum: ''
                }
            };
            kycFields[0]?.tblKycSetDetails?.forEach((item) => {
                if (kycFixed?.includes(item?.tblKycAttribute?.attributeName) && item?.isActive === 'Y') {
                } else {
                    delete newData?.data?.payLoad[item?.tblKycAttribute?.attributeName];
                }
            });
            delete newData?.data?.payLoad?.kfsInput;
            delete newData?.data?.payLoad?.digitalSigningInput;
            delete newData?.data?.payLoad?.hierarchyName;
            formik.setFieldValue('debitCard', data['debitCard'] === 'Y' ? true : false);
            formik.setFieldValue('chequeBook', data['chequeBook'] === 'Y' ? true : false);

            const res = await dispatch(handlePostRequest(newData, '/account/v1/agentaccount/createagentaccount', true, false, 'accountonboarding'));
            if (res?.responseCode == '010000') {
                setAgentId(res?.payLoad);

                addImages(res?.payLoad);
            } else {
            }
            setloading(false);
        }
    });

    const getKycFields = async () => {
        const res = await handleGetRequest(`/account/v1/agentaccount/getkycbyclassificationname/${parentData.name}`);
        setKycFields(res?.payLoad);
    };
    useEffect(() => {
        getKycFields();
        getCommissionProfile();
        getLimitProfile();
        getPricingProfile();
    }, [parentData]);

    //parent name dropdown
    const parentDropdown = [{ name: 'Zindagi', value: '1' }];

    //cheque book leaves dropdown
    const chequeBookLeaves = [
        { name: '25', value: '25' },
        { name: '50', value: '50' },
        { name: '100', value: '100' },
        { name: '250', value: '250' }
    ];

    //search on the basis of employee ID
    const searchEmployeeId = async () => {
        setUseCaseError({ empId: null });
        if (formik.values.empId === '') {
            setUseCaseError({ empId: 'This field is required' });
        } else {
            formik.setFieldValue('hierarchyName', '');
            const id = formik.values.empId;
            if (id) {
                dispatch(loadingAction(true));
                const res = await handleGetRequest(`/account/v1/sales/getemployeebyid/${id}`);
                if (res == undefined || res) setTimeout(() => dispatch(loadingAction(false)), 1000);
                if (res?.payLoad?.length) {
                    setEmpIdData(res?.payLoad[0]?.employeeId);
                    formik.setFieldValue('hierarchyName', res?.payLoad[0]?.salesHierarchyName);
                } else {
                    formik.setFieldValue('hierarchyName', '');
                }
            }
        }
    };

    const panelFooterTemplate = () => {
        const length = formik?.values?.channelIdList ? formik?.values?.channelIdList?.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (e.target.name === 'empId') {
                searchEmpId.current.click();
            }
            e.preventDefault();
        }
    };

    //pricing profile dropdown options API
    const getChannel = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchannels/LKP_CHANNEL:*');
        setChannelOption(res?.payLoad);
    };

    //commission profile dropdown options API
    const getCommissionProfile = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getagentdatabyaccounttype/TBL_COMMISSION_PROFILE/${accountClassificationId}`);
        setCommissionProfile(res?.payLoad);
    };

    //pricing profile dropdown options API
    const getPricingProfile = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getagentdatabyaccounttype/TBL_PRICING_PROFILE/${accountClassificationId}`);
        setPricingProfile(res?.payLoad);
    };

    //limit profile dropdown options API
    const getLimitProfile = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getagentdatabyaccounttype/TBL_TRANS_LIMIT/${accountClassificationId}`);
        setLimitProfile(res?.payLoad);
    };

    //product catalog dropdown options API
    const getProductCatalog = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/productCatalog/TBL_PRODUCT_CATALOG:*');
        setProductcatalog(res?.payLoad);
    };

    //nearest/linked dropdown options API
    const getNearest = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getallbranches/LKP_BRANCH:*');
        setNearestBranch(res?.payLoad);
    };
    useEffect(() => {
        getProductCatalog();
        getNearest();
        getChannel();
        // setAgentName(parentData.name);
    }, []);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleKfsInput = () => {
        kfsRef.current.click();
    };

    const handleDigitalSigningInput = () => {
        digitalSigningRef.current.click();
    };

    const kfsChange = (event) => {
        setKfsInputFile('');
        formik.setFieldValue('kfsInput', '');
        setKfsFileError('');
        const selectedFile = event.target.files[0];
        const fileTypes = ['pdf', 'image', 'document', 'msword'];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setKfsFileError(error);
        } else if (newFile) {
            setKfsLink(URL.createObjectURL(selectedFile));
            setKfsInput(selectedFile.name);
            formik.setFieldValue('kfsInput', selectedFile.name);
            setKfsInputFile(selectedFile);
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                setKfsInputBase64(base64Data);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const digitalSigningChange = (event) => {
        setDigitalSigningFile('');
        formik.setFieldValue('digitalSigningInput', '');
        setDsFileError('');
        const selectedFile = event.target.files[0];
        const fileTypes = ['pdf', 'image', 'document', 'msword'];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setDsFileError(error);
        } else if (newFile) {
            setDigitalSigningLink(URL.createObjectURL(selectedFile));
            setDigitalSigningInput(selectedFile.name);
            formik.setFieldValue('digitalSigningInput', selectedFile.name);
            setDigitalSigningFile(selectedFile);
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                setDigitalSigningInputBase64(base64Data);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const addImages = async (agentId) => {
        let KEY_FACT = '';
        let DIGITAL_SIGNING = '';
        if (kfsInputFile) {
            const originalFileName = kfsInputFile?.name;
            const fileExtension = originalFileName?.split('.')?.pop();
            const newFileNameWithoutExtension = 'KEY_FACT';
            const newFileName = `${newFileNameWithoutExtension}.${fileExtension}`;
            KEY_FACT = new File([kfsInputFile], newFileName);
        }
        if (digitalSigningFile) {
            const originalFileName2 = digitalSigningFile?.name;
            const fileExtension2 = originalFileName2?.split('.')?.pop();
            const newFileNameWithoutExtension2 = 'DIGITAL_SIGNING';
            const newFileName2 = `${newFileNameWithoutExtension2}.${fileExtension2}`;
            DIGITAL_SIGNING = new File([digitalSigningFile], newFileName2);
        }
        const documentsRequest = {
            data: {
                security: {
                    userName: 'string',
                    password: 'string',
                    securityToken: 'string'
                },
                account: {
                    msidn: 'string',
                    iban: 'string',
                    bban: 'string',
                    pan: 'string',
                    currency: 'string'
                },
                channel: 'string',
                terminal: 'string',
                reterivalReferenceNumber: 'string',
                payLoad: {
                    agentId: agentId || '',
                    accountClassificationName: parentData?.name,
                    levelName: kycFields[0]?.accountLevel || ''
                },
                additionalInformation: [
                    {
                        infoKey: 'string',
                        infoValue: 'string'
                    }
                ],
                checkSum: 'string'
            }
        };
        const formData = new FormData();
        formData.append('documentsRequest', JSON.stringify(documentsRequest));
        if (KEY_FACT) formData.append('files', KEY_FACT);
        if (DIGITAL_SIGNING) formData.append('files', DIGITAL_SIGNING);
        const res = await dispatch(handlePostRequest(formData, '/documents/v1/documents/uploadagentdocs', true, true));
    };

    function convertToTitleCase(inputString) {
        const words = inputString.split(/(?=[A-Z])/);
        const titleCaseString = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

        return titleCaseString;
    }

    const handleInputChange = (e) => {
        const inputValue = e.target.value.replace(/[^a-zA-Z\s]+/g, '').toUpperCase();
        formik.setFieldValue('debitCardName', inputValue);
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const handleEmployeeIdChange = (e) => {
        // Ensure it's a numeric value and limit to 4 characters
        const inputText = e.target.value.replace(/[^0-9]/g, '').slice(0, 7);
        formik.setFieldValue('empId', inputText);
    };

    return (
        <>
            <div className="my-3">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {kycFields && (
                        <div className="custom-card p-3 my-3">
                            <div className="m-3 Form__Header Full__Width">
                                <p>FRANCHISE KYC SETS</p>
                            </div>
                            <div className="p-fluid p-formgrid grid mb-2 mx-3">
                                {kycFields[0]?.tblKycSetDetails?.map((item, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            {item?.isActive === 'Y' && (
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3" key={key}>
                                                    <div className="p-field withoutArrow">
                                                        <label htmlFor={item?.tblKycAttribute?.attributeName} className={classNames({ 'p-error': isFormFieldValid(item?.lovId) }, 'Label__Text')}>
                                                            {convertToTitleCase(item?.tblKycAttribute?.attributeName)}
                                                            {(kycFixed?.includes(item?.tblKycAttribute?.attributeName) || item?.isMandatory === 'Y') && <span className="Label__Required">*</span>}
                                                        </label>
                                                        <InputText
                                                            id={item?.tblKycAttribute?.attributeName}
                                                            name={item?.tblKycAttribute?.attributeName}
                                                            value={formik.values[item?.tblKycAttribute?.attributeName]}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(item?.tblKycAttribute?.attributeName) }, 'Input__Round')}
                                                            type={item?.tblKycAttribute?.attributeName.toLowerCase().includes('date') ? 'date' : 'text'}
                                                            max={item?.tblKycAttribute?.attributeName.toLowerCase().includes('date') ? previousDate : null}
                                                        />
                                                        {getFormErrorMessage(item?.tblKycAttribute?.attributeName)}
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    <div className="p-fluid p-formgrid grid mb-3">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="pricingProfileId" className={classNames({ 'p-error': isFormFieldValid('pricingProfileId') }, 'Label__Text')}>
                                    Pricing Profile <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="pricingProfileId"
                                    placeholder="Select Pricing Profile"
                                    options={pricingProfile}
                                    optionLabel="name"
                                    name="pricingProfileId"
                                    optionValue="lovId"
                                    value={formik.values.pricingProfileId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('pricingProfileId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="commissionId" className={classNames({ 'p-error': isFormFieldValid('commissionId') }, 'Label__Text')}>
                                    Commission Profile <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="commissionId"
                                    placeholder="Select Commission Profile"
                                    options={commissionProfile}
                                    optionLabel="name"
                                    name="commissionId"
                                    optionValue="lovId"
                                    value={formik.values.commissionId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('commissionId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('commissionId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="transLimitId" className={classNames({ 'p-error': isFormFieldValid('transLimitId') }, 'Label__Text')}>
                                    Limit Profile <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="transLimitId"
                                    placeholder="Select Limit Profile"
                                    options={limitProfile}
                                    optionLabel="name"
                                    name="transLimitId"
                                    optionValue="lovId"
                                    value={formik.values.transLimitId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transLimitId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('transLimitId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="parentAgentId" className={classNames({ 'p-error': isFormFieldValid('parentAgentId') }, 'Label__Text')}>
                                    Parent Name <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="parentAgentId"
                                    name="parentAgentId"
                                    placeholder="Select Parent Name"
                                    options={parentDropdown}
                                    value={formik.values.parentAgentId}
                                    onChange={formik.handleChange}
                                    optionLabel="name"
                                    optionValue="value"
                                    className={classNames({ 'p-invalid': isFormFieldValid('parentAgentId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('parentAgentId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="channelIdList" className={classNames({ 'p-error': isFormFieldValid('channelIdList') }, 'Label__Text')}>
                                    Channel <span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="channelIdList"
                                    options={channelOption}
                                    placeholder="Select Channel"
                                    name="channelIdList"
                                    optionLabel="name"
                                    optionValue="lovId"
                                    filter
                                    panelFooterTemplate={panelFooterTemplate}
                                    value={formik?.values?.channelIdList?.map((trans) => trans.lovId || '')}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            'channelIdList',
                                            e.value.map((lovId) => ({ lovId }))
                                        );
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelIdList') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('channelIdList')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="empId" className={classNames({ 'p-error': isFormFieldValid('empId') }, 'Label__Text')}>
                                    Employee ID <span className="Label__Required">*</span>
                                </label>
                                <div className="flex withoutArrow">
                                    <InputText
                                        id="empId"
                                        name="empId"
                                        value={formik.values.empId}
                                        placeholder="Enter Employee ID"
                                        onChange={(e) => {
                                            setUseCaseError({ empId: null });
                                            handleEmployeeIdChange(e);
                                            if (!e.target.value) {
                                                formik.setFieldValue('hierarchyName', '');
                                            }
                                        }}
                                        onKeyDown={(e) => handleKeyPress(e)}
                                        className={classNames({ 'p-invalid': useCaseError.empId || isFormFieldValid('empId') }, 'Input__Round')}
                                        type="number"
                                    />
                                    <Button icon={loadingIcon || ''} ref={searchEmpId} onClick={() => searchEmployeeId()} type="button" className="Btn__Dark border-circle" style={{ height: '35px', width: '35px', marginLeft: '1rem' }} />
                                </div>

                                {useCaseError.empId ? <small className="p-error">{formik?.errors?.empId}</small> : getFormErrorMessage('empId')}
                            </div>
                        </div>
                    </div>
                    <div className="custom-card p-3 my-3">
                        <div className="m-3 Form__Header Full__Width">
                            <p>EMPLOYEE ID</p>
                        </div>
                        <div className="p-fluid p-formgrid grid mb-2 mx-3">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="hierarchyName" className={classNames({ 'p-error': isFormFieldValid('hierarchyName') }, 'Label__Text')}>
                                        Sales Hierarchy Name <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="hierarchyName"
                                        name="hierarchyName"
                                        value={formik.values.hierarchyName}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('hierarchyName') }, 'Input__Round')}
                                        disabled
                                    />
                                    {getFormErrorMessage('hierarchyName')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-3">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productCatalogId" className={classNames({ 'p-error': isFormFieldValid('productCatalogId') }, 'Label__Text')}>
                                    Product catalog <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="productCatalogId"
                                    placeholder="Select Product Catalog"
                                    options={productcatalog}
                                    optionLabel="name"
                                    name="productCatalogId"
                                    optionValue="lovId"
                                    value={formik.values.productCatalogId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productCatalogId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('productCatalogId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="branchId" className={classNames({ 'p-error': isFormFieldValid('branchId') }, 'Label__Text')}>
                                    Nearest/linked branch <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="branchId"
                                    placeholder="Select Branch"
                                    options={nearestBranch}
                                    optionLabel="name"
                                    name="branchId"
                                    optionValue="lovId"
                                    value={formik.values.branchId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('branchId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('branchId')}
                            </div>
                        </div>
                        {/* <div className="p-field col-12 md:col-4 flex align-items-end mb-2 pt-3 pb-3">
                            <div className="p-field">
                                <div>
                                    <Checkbox
                                        inputId="chequeBook"
                                        name="chequeBook"
                                        checked={formik.values.chequeBook === true}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            formik.setFieldValue('noOfLeaves', '');
                                            setDigitalSigningFile('');
                                            formik.setFieldValue('digitalSigningInput', '');
                                            setDigitalSigningInput('');
                                            setDigitalSigningInputBase64('');
                                            setDigitalSigningLink('');
                                        }}
                                        className={classNames({ 'p-invalid': isFormFieldValid('chequeBook') }, '')}
                                        disabled
                                    />
                                    <label htmlFor="chequeBook" className={classNames({ 'p-error': isFormFieldValid('chequeBook') }, 'Label__Text')}>
                                        Cheque Book
                                    </label>
                                </div>
                            </div>
                            <div className="p-field ml-3">
                                <div>
                                    <Checkbox
                                        inputId="debitCard"
                                        name="debitCard"
                                        checked={formik.values.debitCard === true}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            formik.setFieldValue('debitCardName', '');
                                            formik.setFieldValue('debitCardAddress', '');
                                        }}
                                        className={classNames({ 'p-invalid': isFormFieldValid('debitCard') }, '')}
                                        disabled
                                    />
                                    <label htmlFor="debitCard" className={classNames({ 'p-error': isFormFieldValid('debitCard') }, 'Label__Text')}>
                                        ATM/Debit Card
                                    </label>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    {/* {(formik?.values?.chequeBook === true || formik?.values?.debitCard === true) && (
                        <div className="custom-card p-3 my-3">
                            <div className="p-fluid p-formgrid grid mb-2 mx-3">
                                {formik?.values?.chequeBook === true && (
                                    <>
                                        <div className="m-3 Form__Header Full__Width">
                                            <p>CHEQUE BOOK DETAILS</p>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="noOfLeaves" className={classNames({ 'p-error': isFormFieldValid('noOfLeaves') }, 'Label__Text')}>
                                                    No of leaves<span className="Label__Required">*</span>
                                                </label>
                                                <Dropdown
                                                    id="noOfLeaves"
                                                    options={chequeBookLeaves}
                                                    name="noOfLeaves"
                                                    placeholder="Select Number of Leaves"
                                                    optionLabel="name"
                                                    optionValue="value"
                                                    value={formik.values.noOfLeaves}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('noOfLeaves') }, 'Dropdown__Round')}
                                                />
                                                {getFormErrorMessage('noOfLeaves')}
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="channelName" className={classNames({ 'p-error': isFormFieldValid('channelName') }, 'Label__Text')}>
                                                    Waiver
                                                </label>
                                                <div className="flex">
                                                    <div className="mt-2 mr-5">
                                                        <RadioButton inputId="chequeApplicable" name="chequeWaiver" value="Y" onChange={formik.handleChange} checked={formik.values.chequeWaiver === 'Y'} />
                                                        <label htmlFor="chequeApplicable" className={classNames({ 'p-error': isFormFieldValid('chequeApplicable') }, 'Label__Text')}>
                                                            Applicable
                                                        </label>
                                                    </div>
                                                    <div className="mt-2">
                                                        <RadioButton inputId="chequeNotApplicable" name="chequeWaiver" value="N" onChange={formik.handleChange} checked={formik.values.chequeWaiver === 'N'} />
                                                        <label htmlFor="chequeNotApplicable" className={classNames({ 'p-error': isFormFieldValid('chequeNotApplicable') }, 'Label__Text')}>
                                                            Not Applicable
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {formik?.values?.debitCard === true && (
                                    <>
                                        <div className="m-3 Form__Header Full__Width">
                                            <p>ATM / DEBIT CARD DEATILS</p>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="debitCardName" className={classNames({ 'p-error': isFormFieldValid('debitCardName') }, 'Label__Text')}>
                                                    Name<span className="Label__Required">*</span>
                                                </label>
                                                <InputText
                                                    name="debitCardName"
                                                    id="debitCardName"
                                                    placeholder="Enter Name"
                                                    value={formik.values.debitCardName}
                                                    onInput={handleInputChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('debitCardName') }, 'Input__Round')}
                                                    maxLength={30}
                                                    type="text"
                                                />
                                                {getFormErrorMessage('debitCardName')}
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="debitCardAddress" className={classNames({ 'p-error': isFormFieldValid('debitCardAddress') }, 'Label__Text')}>
                                                    Postal Address<span className="Label__Required">*</span>
                                                </label>
                                                <InputText
                                                    name="debitCardAddress"
                                                    id="debitCardAddress"
                                                    placeholder="Enter Postal Address"
                                                    value={formik.values.debitCardAddress}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('debitCardAddress') }, 'Input__Round')}
                                                    maxLength={200}
                                                />
                                                {getFormErrorMessage('debitCardAddress')}
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="channelName" className={classNames({ 'p-error': isFormFieldValid('channelName') }, 'Label__Text')}>
                                                    Waiver
                                                </label>
                                                <div className="flex">
                                                    <div className="mt-2 mr-5">
                                                        <RadioButton inputId="applicable" name="atmWaiver" value="Y" onChange={formik.handleChange} checked={formik.values.atmWaiver === 'Y'} />
                                                        <label htmlFor="applicable" className={classNames({ 'p-error': isFormFieldValid('applicable') }, 'Label__Text')}>
                                                            Applicable
                                                        </label>
                                                    </div>
                                                    <div className="mt-2">
                                                        <RadioButton inputId="notApplicable" name="atmWaiver" value="N" onChange={formik.handleChange} checked={formik.values.atmWaiver === 'N'} />
                                                        <label htmlFor="notApplicable" className={classNames({ 'p-error': isFormFieldValid('notApplicable') }, 'Label__Text')}>
                                                            Not Applicable
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )} */}

                    <div className="p-fluid p-formgrid grid mb-3">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <div className="mb-2">
                                    <label className={classNames({ 'p-error': isFormFieldValid('kfsInput') }, 'Label__Text')}>
                                        Key Fact Statement <span className="Label__Required">*</span>
                                    </label>
                                    <div className="file-input-container w-100" onClick={handleKfsInput}>
                                        <input type="file" accept="image/*, .pdf, .doc, .docx" style={{ display: 'none' }} ref={kfsRef} onChange={kfsChange} />
                                        <input
                                            type="text"
                                            className={classNames({ 'p-inputtext p-component p-invalid': isFormFieldValid('kfsInput') }, 'file-input-field Input__Round')}
                                            placeholder="Choose a file..."
                                            value={formik.values.kfsInput}
                                            readOnly
                                        />
                                        <span className="file-upload-icon">
                                            <i className="pi pi-paperclip" />
                                        </span>
                                    </div>
                                    {kfsFileError ? <small className="p-error">{kfsFileError}</small> : getFormErrorMessage('kfsInput')}
                                </div>
                            </div>
                            {formik?.values?.kfsInput && (
                                <div className="Down__Btn">
                                    <Button
                                        type="button"
                                        label="View Document"
                                        className="Btn__Dark"
                                        onClick={() => {
                                            window.open(kfsLink, '_blank');
                                        }}
                                        disabled={!formik?.values?.kfsInput}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <div className="mb-2">
                                    <label className={classNames({ 'p-error': isFormFieldValid('digitalSigningInput') }, 'Label__Text')}>
                                        Digital Signing
                                        {formik?.values?.chequeBook && <span className="Label__Required">*</span>}
                                    </label>
                                    <div className="file-input-container w-100" onClick={handleDigitalSigningInput}>
                                        <input type="file" accept="image/*, .pdf, .doc, .docx" style={{ display: 'none' }} ref={digitalSigningRef} onChange={digitalSigningChange} />
                                        <input
                                            type="text"
                                            className={classNames({ 'p-inputtext p-component p-invalid': isFormFieldValid('digitalSigningInput') }, 'file-input-field Input__Round')}
                                            placeholder="Choose a file..."
                                            value={formik.values.digitalSigningInput}
                                            readOnly
                                        />
                                        <span className="file-upload-icon">
                                            <i className="pi pi-paperclip" />
                                        </span>
                                    </div>
                                    {dsFileError ? <small className="p-error">{dsFileError}</small> : getFormErrorMessage('digitalSigningInput')}
                                </div>
                            </div>
                            {formik?.values?.digitalSigningInput && (
                                <div className="Down__Btn">
                                    <Button
                                        type="button"
                                        label="View Document"
                                        className="Btn__Dark"
                                        onClick={() => {
                                            window.open(digitalSigningLink, '_blank');
                                        }}
                                        disabled={!formik?.values?.digitalSigningInput}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="Down__Btn my-5">
                        <Button type="submit" label="Submit" className="Btn__Dark" onClick={() => ScrollMover()} />
                        <Button onClick={() => navigate('/accountonboarding')} label="Cancel" className="Btn__Transparent" />
                        {/* disabled={loading} */}
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddFranchise;

import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { baseURL } from '../../../../../../Config';
import { Checkbox } from 'primereact/checkbox';

function EditTermsAndConditionsChecker({ lendingData }) {
    const [languageUrdu, setLanguageUrdu] = useState('');
    const [languageEnglish, setLanguageEnglish] = useState('');

    const [termFile, setTermFile] = useState('');

    const [tncFile, setTncFile] = useState('');
    const [tncFilePathData, setTncFilePathData] = useState('');

    ///////////////////////////////////////////////
    const [tncFileError, setTncFileError] = useState('');
    const tncFileRef = useRef(null);

    const dispatch = useDispatch();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const requestType = query.get('requestType');
    const requestTypeDescr = query.get('requestTypeDescr');

    useEffect(() => {
        if (requestType === 'I' || (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request')) {
            if (lendingData && lendingData?.tblLmsTncs && lendingData?.tblLmsTncs[0]) {
                formik.setFieldValue('tncText', lendingData?.tblLmsTncs[0]?.tncTextbox);
                formik.setFieldValue('textType', lendingData?.tblLmsTncs[0]?.describeTnc);
                formik.setFieldValue('isActive', lendingData?.isActive === 'Y' ? true : false);

                if (lendingData?.tblLmsTncs[0]?.language === 'E') {
                    setLanguageUrdu('');
                    setLanguageEnglish('E');
                } else {
                    setLanguageUrdu('U');
                    setLanguageEnglish('');
                }

                if (lendingData?.tblLmsTncs[0]?.describeTnc === 'F') {
                    // lendingData?.tblLmsTncs[0]?.filePath
                    let fileName = '';
                    if (lendingData?.tblLmsTncs[0]?.filePath) {
                        const filePath = lendingData?.tblLmsTncs[0]?.filePath;
                        // Use the split method to split the string by "/"
                        const parts = filePath.split('/');

                        // Get the last part of the array, which is the filename
                        fileName = parts[parts.length - 1];
                        const filedata = `${baseURL}${filePath}/${lendingData?.tblLmsTncs[0]?.fileName}`;
                        const updatedPath = new File([filedata], fileName);
                        setTncFilePathData(updatedPath);
                    }
                    formik.setFieldValue('file', lendingData?.tblLmsTncs[0]?.fileName);
                }
            }
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            if (lendingData[4]) {
                formik.setFieldValue('tncText', lendingData[4]?.tncText);
                formik.setFieldValue('textType', lendingData[4]?.textType);
                if (lendingData[4]?.language === 'E') {
                    setLanguageUrdu('');
                    setLanguageEnglish('E');
                } else {
                    setLanguageUrdu('U');
                    setLanguageEnglish('');
                }

                if (lendingData[4]?.textType === 'F') {
                    // lendingData?.tblLmsTncs[0]?.filePath
                    let fileName = '';
                    if (lendingData[4]?.filePath) {
                        const filePath = lendingData[4]?.filePath;
                        // Use the split method to split the string by "/"
                        const parts = filePath.split('/');

                        // Get the last part of the array, which is the filename
                        fileName = parts[parts.length - 1];
                        const filedata = `${baseURL}${filePath}/${lendingData[4]?.fileName}`;
                        const updatedPath = new File([filedata], fileName);
                        setTncFilePathData(updatedPath);
                    }
                    formik.setFieldValue('file', lendingData[4]?.fileName);
                }
            }
        }
    }, [lendingData]);

    const formik = useFormik({
        initialValues: {
            language: '',
            textType: '',
            tncText: '',
            file: '',
            isActive: false
        },

        onSubmit: async (data) => {}
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const termsLov = [
        {
            label: 'Text',
            value: 'T'
        },
        {
            label: 'File',
            value: 'F'
        }
    ];

    const handleLanguageForU = () => {
        setLanguageUrdu('U');
        setLanguageEnglish('');
    };

    const handleLanguageForE = () => {
        setLanguageUrdu('');
        setLanguageEnglish('E');
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        formik.setFieldValue('file', file);
        setTermFile(file);
    };

    const handleTncFileInput = () => {
        tncFileRef.current.click();
    };

    const tncFileChange = (event) => {
        const file = event.target.files[0];
        formik.setFieldValue('file', file?.name);
        setTncFile(file);
    };

    const handleViewDocument = () => {
        if (requestType === 'I' || (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request')) {
            const fileName = baseURL + lendingData?.tblLmsTncs[0]?.filePath?.slice(23).replace('/documents/', '/document/') + '/' + lendingData?.tblLmsTncs[0]?.fileName;
            window.open(fileName, '_blank');
        } else {
            const fileName = baseURL + lendingData[4]?.filePath?.slice(23).replace('/documents/', '/document/') + '/' + lendingData[4]?.fileName;
            window.open(fileName, '_blank');
        }
    };

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>TERMS & CONDITIONS/ EDIT DETAILS</h1>
            </div>
            <div className="Centre_Aligned_Buttons">
                <Button id="language" name="language" value={formik.values.language} className={languageEnglish ? 'Light_Button' : 'DarkGray_Button'} label="English" disabled onClick={handleLanguageForE} />
                <Button className={languageUrdu ? 'Light_Button' : 'DarkGray_Button'} disabled label="Urdu" onClick={handleLanguageForU} />
            </div>
            <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                <div className="card Card__Round">
                    <>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="textType" className="Label__Text">
                                        Describe Terms and Condition as<span className="Label__Required"></span>
                                    </label>
                                    <Dropdown
                                        id="textType"
                                        placeholder="Select"
                                        name="textType"
                                        options={termsLov}
                                        value={formik.values.textType || ''}
                                        disabled={true}
                                        onChange={formik.handleChange}
                                        optionLabel="label"
                                        optionValue="value"
                                        className="Dropdown__Round"
                                    />
                                </div>
                            </div>
                            {formik.values.textType === 'T' ? (
                                <div className="p-field col-12 md:col-8 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="tncText" className="Label__Text">
                                            Text
                                        </label>
                                        <InputTextarea
                                            autoResize
                                            id="tncText"
                                            rows={5}
                                            cols={30}
                                            readOnly
                                            placeholder="Property Address, make and model of a vehicle"
                                            value={formik.values.tncText || ''}
                                            onChange={formik.handleChange}
                                            name="tncText"
                                            className="Input__Round"
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        {formik.values.textType === 'F' ? (
                            <div className="p-fluid p-formgrid grid mb-5">
                                {/* <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="file" className={'Label__Text'}>
                                            Upload File <a download={true} href='' className='Download__Link'>- DownLoad Sample File</a>
                                        </label>
                                        <InputText
                                            id="file"
                                            placeholder="Please Upload File"
                                            name="file"
                                            type="file"
                                            disabled={true}
                                            // accept=".csv"
                                            onChange={handleFileChange}
                                            // className={classNames({ 'p-invalid': isFormFieldValid('file') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('file')}
                                    </div>
                                </div> */}
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <div className="mb-2">
                                            <label htmlFor="file" className={'Label__Text'}>
                                                Upload File
                                            </label>
                                            <div className="file-input-container w-100" onClick={handleTncFileInput}>
                                                <input type="file" accept="csv" style={{ display: 'none' }} ref={tncFileRef} onChange={tncFileChange} disabled />
                                                <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={formik?.values?.file || ''} name="file" readOnly disabled />
                                                <span className="file-upload-icon">
                                                    <i className="pi pi-paperclip" />
                                                </span>
                                            </div>
                                            {tncFileError ? <small className="p-error">{tncFileError}</small> : getFormErrorMessage('file')}
                                        </div>
                                    </div>
                                    <Button label="View Document" className="Btn__Dark" onClick={handleViewDocument} />
                                </div>
                                {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="name" className="Label__Text">
                                            Describe Terms and Condition as
                                            <span>
                                                - <a>Download sample file</a>
                                            </span>
                                        </label>
                                        <div style={{ display: 'flex' }}>
                                            <InputText id="name" placeholder="Enter  Name" name="name" maxLength={100}  optionLabel="label" optionValue="value" className="Dropdown__Round" />
                                            <Button label="Choose File" className="Btn__Dark" style={{ borderRadius: '5px', width: '150px' }} />
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        ) : null}
                    </>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                isActive
                            </label>
                            <div className="field-checkbox">
                                <Checkbox disabled={true} inputId="isActive" name="isActive" value={formik?.values?.isActive} checked={formik?.values?.isActive} onChange={formik.handleChange} />
                            </div>
                            {getFormErrorMessage('isActive')}
                        </div>
                    </div>

                    {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
                                <Button label="Submit" className="Btn__Dark" style={{ borderRadius: '5px', width: '100px' }} />
                                <Button label="Close" className="Black__Btn" style={{ borderRadius: '5px', width: '100px' }} />
                            </div>
                        </div>
                    </div> */}
                </div>
            </form>
        </>
    );
}

export default EditTermsAndConditionsChecker;

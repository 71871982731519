import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import SuccessfulLogo from '../assets/icons/Successfull.png';
import UnsuccessfulLogo from '../assets/icons/unsuccessful.png';
/* eslint-disable */
const ShowMessageDialog = ({ showModal, message, isError, handleShowModal, loadUrl, url }) => {
    return (
        <>
            <Dialog
                focusOnShow={true}
                draggable={false}
                resizable={false}
                visible={showModal}
                closable={false}
                showHeader={false}
                contentStyle={{
                    borderRadius: '5px'
                }}
                className="Custom__MessageDialog"
                style={{ width: '35vw' }}
                onHide={handleShowModal}
            >
                <div className="card" style={{ paddingTop: '1.5rem' }}>
                    <div className="">
                        {url === '' || url === undefined || url === null ? (
                            <img
                                src="assets/layout/images/x-square.png"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleShowModal();
                                }}
                                alt="Delete__Icon"
                                style={{ float: 'right', marginRight: '-25px', marginTop: '-10px', cursor: 'pointer' }}
                            />
                        ) : (
                            <img
                                src="assets/layout/images/x-square.png"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleShowModal();
                                    loadUrl(url);
                                }}
                                alt="Delete__Icon"
                                style={{ float: 'right', marginRight: '-25px', marginTop: '-10px', cursor: 'pointer' }}
                            />
                        )}
                    </div>
                    <form className="p-fluid p-mt-2">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field text-center">
                                {!isError ? (
                                    <div className="">
                                        <img src={SuccessfulLogo} alt="Delete__Icon" className="Delete__Icon" style={{ height: '60px' }} />
                                    </div>
                                ) : (
                                    <div className="">
                                        <img src={UnsuccessfulLogo} alt="Delete__Icon" className="Delete__Icon" style={{ height: '60px' }} />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <div className="DeleteLabel__Text" style={{ textAlign: 'center' }}>
                                    <label htmlFor="moduleDescr" className={'Label__Text'}>
                                        {Array?.isArray(message)
                                            ? message?.map((message, index) => {
                                                  const parts = message?.split(' ');
                                                  return (
                                                      <div key={index}>
                                                          {parts?.map((part, partIndex) => (
                                                              <span key={partIndex}>
                                                                  {partIndex > 0 && ' '}
                                                                  {part}
                                                              </span>
                                                          ))}
                                                      </div>
                                                  );
                                              })
                                            : message}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="DownDelete__Btn mt-3">
                            {url === '' || url === undefined || url === null ? (
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleShowModal();
                                    }}
                                    iconPos="right"
                                    label="Okay"
                                    className="Btn__Dark__Ok"
                                />
                            ) : (
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleShowModal();
                                        loadUrl(url);
                                    }}
                                    iconPos="right"
                                    label="Okay"
                                    className="Btn__Dark__Ok"
                                />
                            )}
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    );
};

export default ShowMessageDialog;

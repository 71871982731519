import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';

function EditSignUpLimit() {
    const location = useLocation();
    const { rowData } = location.state || {};

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        noOfAttempts: Yup.string().required('This field is required'),
        time: Yup.number()
            .nullable()
            .typeError('Input must be a number')

            // .max(24, 'Input must be less than or equal to 24 hours')

            .transform((value, originalValue) => (/^\d+(\.\d)?$/.test(originalValue) ? parseFloat(originalValue) : value))
            .required('This field is required')
            .test('is-one-decimal', 'Input must have only one digit after decimal point', (value) => /^\d+(\.\d)?$/.test(value)),

        blockTime: Yup.number()
            .nullable()
            .when('isTemporaryBlock', {
                is: true,
                then: Yup.number()
                    .typeError('Input must be a number')
                    // .max(24, 'Input must be less than or equal to 24 hours')

                    .transform((value, originalValue) => (/^\d+(\.\d)?$/.test(originalValue) ? parseFloat(originalValue) : value))

                    .required('This field is required')
                    .test('is-one-decimal', 'Input must have only one digit after decimal point', (value) => /^\d+(\.\d)?$/.test(value))
            })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cmsSignupLimitId: '',
            noOfAttempts: '',
            time: '',
            isTemporaryBlock: true,
            isPermanentBlock: false,
            blockTime: '',
            isActive: ''
        },

        onSubmit: async (data) => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        cmsSignupLimitId: data?.cmsSignupLimitId,
                        noOfAttempts: data?.noOfAttempts,
                        time: data?.time,
                        isTemporaryBlock: formik?.values?.isTemporaryBlock === true ? 'Y' : 'N',
                        isPermanentBlock: formik?.values?.isPermanentBlock === true ? 'Y' : 'N',
                        blockTime: data?.blockTime,
                        // isActive: data?.isActive,
                        isActive: null
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            await dispatch(handlePostRequest(newData, '/cms/v1/cms/updatesignuplimits', true, false, 'SignUpLimits'));
        }
    });

    const getMainCategoryData = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/getsignuplimitsbyid/${rowData?.cmsSignupLimitId}`, true);
        const keyData = res?.payLoad;
        formik.setFieldValue('cmsSignupLimitId', keyData?.cmsSignupLimitId);
        formik.setFieldValue('noOfAttempts', keyData?.noOfAttempts);
        formik.setFieldValue('time', keyData?.time || '');

        formik.setFieldValue('blockTime', keyData?.blockTime);

        formik.setFieldValue('isTemporaryBlock', keyData?.isTemporaryBlock === 'Y' ? true : false);
        formik.setFieldValue('isPermanentBlock', keyData?.isPermanentBlock === 'Y' ? true : false);
        formik.setFieldValue('isActive', keyData?.isActive);
    };

    useEffect(() => {
        getMainCategoryData();
    }, [rowData]); // eslint-disable-line

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>Edit DETAIL</h2>
                        </div>
                        <div className="p-field col-12 md:col-6 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="noOfAttempts" className={classNames({ 'p-error': isFormFieldValid('noOfAttempts') }, 'Label__Text')}>
                                    Attempts
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="noOfAttempts"
                                    placeholder="Enter noOfAttempts"
                                    name="noOfAttempts"
                                    value={formik?.values?.noOfAttempts || ''}
                                    disabled={false}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('noOfAttempts') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('noOfAttempts')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="time" className={classNames({ 'p-error': isFormFieldValid('time') }, 'Label__Text')}>
                                    Time <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="time"
                                    placeholder="Enter Time In Minutes"
                                    name="time"
                                    keyfilter="int"
                                    value={formik?.values?.time || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('time') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('time')}
                            </div>
                        </div>

                        <div className="Form__Header Full__Width">
                            <h2>BREACH LIMITS</h2>
                        </div>
                        <div className="p-field col-12 md:col-8 pt-3 pb-3 flex flex-row justify-content-space-between">
                            <div className="p-field col-12 md:col-4">
                                <div className="field-checkbox flex mb-2">
                                    <Checkbox
                                        inputId="isTemporaryBlock"
                                        name="isTemporaryBlock"
                                        value={formik?.values?.isTemporaryBlock}
                                        checked={formik?.values?.isTemporaryBlock}
                                        onChange={() => {
                                            formik.setFieldValue('isTemporaryBlock', !formik?.values?.isTemporaryBlock);
                                            formik.setFieldValue('isPermanentBlock', !formik?.values?.isPermanentBlock);
                                            formik.setFieldValue('blockTime', '');
                                        }}
                                        style={{ marginTop: '10px' }}
                                    />
                                    <label htmlFor="isTemporaryBlock" className={classNames({ 'p-error': isFormFieldValid('isTemporaryBlock') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Temporary Block
                                    </label>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4">
                                <label htmlFor="blockTime" className={classNames({ 'p-error': isFormFieldValid('blockTime') }, 'Label__Text')}>
                                    Time
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    type="text"
                                    id="blockTime"
                                    placeholder="Enter Time In Minutes"
                                    name="blockTime"
                                    keyfilter="int"
                                    value={formik?.values?.blockTime}
                                    disabled={formik.values.isPermanentBlock}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('blockTime') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('blockTime')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>

                        <div className="p-field col-12 md:col-4">
                            <div className="field-checkbox flex mb-2">
                                <Checkbox
                                    inputId="isPermanentBlock"
                                    name="isPermanentBlock"
                                    value={formik?.values?.isPermanentBlock}
                                    checked={formik?.values?.isPermanentBlock}
                                    onChange={() => {
                                        formik.setFieldValue('isTemporaryBlock', !formik?.values?.isTemporaryBlock);
                                        formik.setFieldValue('isPermanentBlock', !formik?.values?.isPermanentBlock);
                                        formik.setFieldValue('blockTime', '');
                                    }}
                                    style={{ marginTop: '10px' }}
                                />
                                <label htmlFor="isPermanentBlock" className={classNames({ 'p-error': isFormFieldValid('isPermanentBlock') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                    Permanent Block
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="submit" label="Update" className="Btn__Dark" />
                        <Button onClick={() => navigate('/SignUpLimits')} type="button" label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default EditSignUpLimit;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import { handleGetRequest } from '../../../services/GetTemplate';

function ViewActiveLoan({}) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [dataById, setDataById] = useState();
    const [sequencearray, setSequnceArray] = useState();

    // rawaha

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const disbursmentId = query.get('reqId');

    const formik = useFormik({
        initialValues: {
            customerName: '',
            mobileNo: '',
            cnic: '',
            email: '',
            segment: '',
            activationDate: '',
            loanNo: '',
            loanAmount: '',
            productName: '',
            loanTenure: '',
            accountStatus: '',
            issuanceDate: '',
            pricing: '',
            noOfDays: '',
            noOfGracePeriods: '',
            charges: '',
            gracePeriodCheckBox: false,
            latePayment: false
        }
    });

    const getDatabyId = async () => {
        const response = await handleGetRequest(`/lending/v1/lending/getloanaccountbyid/${disbursmentId}`);
        if (response?.responseCode === process.env.REACT_APP_LENDING_SUCCESS_CODE) {
            setDataById(response?.payLoad);
        }

        const keyData = response?.payLoad;
        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik?.setFieldValue(key, keyData[key]);
                }
            });
        }
        formik.setFieldValue('issuanceDate', keyData?.issuanceDate?.slice(0, 10));
        formik.setFieldValue('activationDate', keyData?.activationDate?.slice(0, 10));

        var seqArray = [];
        Object.keys(keyData).forEach((key) => {
            if (key === 'feesSeq') {
                seqArray.push({ label: key, value: keyData?.feesSeq });
            }
            if (key === 'serviceChargesSeq') {
                seqArray.push({ label: key, value: keyData?.serviceChargesSeq });
            }
            if (key === 'principalAmountSeq') {
                seqArray.push({ label: key, value: keyData?.principalAmountSeq });
            }
        });
        setSequnceArray(seqArray);
    };
    useEffect(() => {
        getDatabyId();
    }, [disbursmentId]);
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours from 24-hour to 12-hour format
        const formattedHours = hours % 12 || 12;

        const formattedDate = `${day}-${month}-${year}, ${formattedHours}:${minutes} ${ampm}`;
        return formattedDate;
    };

    const customSort = (a, b) => {
        if (a.value === b.value) {
            return 0;
        }
        if (a.value === 1) {
            return -1;
        }
        if (b.value === 2) {
            return -1;
        }
        if (a.value === 3) {
            return 1;
        }
        return a.value - b.value;
    };

    const sortedArray = sequencearray?.sort(customSort);

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>Z-CONNECT CUSTOMER KYC ACTIVE LOAN </h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="customerName" className={classNames({ 'p-error': isFormFieldValid('customerName') }, 'Label__Text')}>
                                    Customer Name <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="customerName"
                                    placeholder="Enter Customer Name"
                                    name="customerName"
                                    maxLength={100}
                                    value={formik?.values?.customerName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('customerName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('customerName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="mobileNo" className={classNames({ 'p-error': isFormFieldValid('mobileNo') }, 'Label__Text')}>
                                    Mobile Number <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="mobileNo"
                                    placeholder="Enter Mobile Number"
                                    name="mobileNo"
                                    value={formik?.values?.mobileNo || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('mobileNo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('mobileNo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cnic" className={classNames({ 'p-error': isFormFieldValid('cnic') }, 'Label__Text')}>
                                    CNIC <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="cnic"
                                    placeholder="Enter CNIC"
                                    name="cnic"
                                    value={formik?.values?.cnic || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cnic') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('cnic')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') }, 'Label__Text')}>
                                    Email <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="email"
                                    placeholder="Enter Email"
                                    name="email"
                                    value={formik?.values?.email || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('email') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('email')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="segment" className={classNames({ 'p-error': isFormFieldValid('segment') }, 'Label__Text')}>
                                    Segment <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="segment"
                                    placeholder="Enter Segment"
                                    name="segment"
                                    value={formik?.values?.segment || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('segment') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('segment')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="activationDate" className={classNames({ 'p-error': isFormFieldValid('activationDate') }, 'Label__Text')}>
                                    Activation Date<span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="activationDate"
                                    placeholder=""
                                    name="activationDate"
                                    type="Date"
                                    value={formik?.values?.activationDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('activationDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('activationDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="loanNo" className={classNames({ 'p-error': isFormFieldValid('loanNo') }, 'Label__Text')}>
                                    Loan ID <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="loanNo"
                                    placeholder="Enter Loan ID"
                                    name="loanNo"
                                    value={formik?.values?.loanNo || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('loanNo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('loanNo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="loanAmount" className={classNames({ 'p-error': isFormFieldValid('loanAmount') }, 'Label__Text')}>
                                    Loan Amount <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="loanAmount"
                                    placeholder="Enter Loan Amount"
                                    name="loanAmount"
                                    value={formik?.values?.loanAmount || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('loanAmount') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('loanAmount')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productName" className={classNames({ 'p-error': isFormFieldValid('productName') }, 'Label__Text')}>
                                    Product Name <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="productName"
                                    placeholder="Enter Product Name"
                                    name="productName"
                                    value={formik?.values?.productName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('productName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="loanTenure" className={classNames({ 'p-error': isFormFieldValid('loanTenure') }, 'Label__Text')}>
                                    Loan Tenure
                                </label>
                                <InputText
                                    disabled={true}
                                    id="loanTenure"
                                    name="loanTenure"
                                    value={formik?.values?.loanTenure || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('loanTenure') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('loanTenure')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountStatus" className={classNames({ 'p-error': isFormFieldValid('accountStatus') }, 'Label__Text')}>
                                    Account Status
                                </label>
                                <InputText
                                    disabled={true}
                                    id="accountStatus"
                                    name="accountStatus"
                                    value={formik?.values?.accountStatus || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountStatus') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('accountStatus')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 ">
                            <div className="p-field">
                                <label htmlFor="issuanceDate" className={classNames({ 'p-error': isFormFieldValid('issuanceDate') }, 'Label__Text')}>
                                    Issuance Date<span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="issuanceDate"
                                    placeholder=""
                                    name="issuanceDate"
                                    type="Date"
                                    value={formik?.values?.issuanceDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('issuanceDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('issuanceDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="pricing" className={classNames({ 'p-error': isFormFieldValid('pricing') }, 'Label__Text')}>
                                    Pricing
                                </label>
                                <InputText disabled={true} id="pricing" name="pricing" value={formik?.values?.pricing || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('pricing') }, 'Input__Round')} />

                                {getFormErrorMessage('pricing')}
                            </div>
                        </div>
                    </div>
                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>REPAYMENT METHOD</h1>
                    </div>
                    <div className="Form__Header">
                        <h1>LOAN TENURE </h1>
                    </div>
                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="noOfDays" className={classNames({ 'p-error': isFormFieldValid('noOfDays') }, 'Label__Text')}>
                                    Number of Days <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="noOfDays"
                                    placeholder="Enter Number of Days"
                                    name="noOfDays"
                                    value={formik?.values?.noOfDays || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('noOfDays') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('noOfDays')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox
                                        disabled={true}
                                        inputId="gracePeriodCheckBox"
                                        name="gracePeriodCheckBox"
                                        value={formik?.values?.gracePeriodCheckBox}
                                        checked={formik?.values?.noOfGracePeriods != null ? true : false}
                                        onChange={formik.handleChange}
                                        style={{ marginTop: '10px' }}
                                    />
                                    <label htmlFor="gracePeriodCheckBox" className={classNames({ 'p-error': isFormFieldValid('gracePeriodCheckBox') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Grace Period
                                    </label>
                                </div>
                                {getFormErrorMessage('gracePeriodCheckBox')}
                            </div>
                        </div>
                    </div>
                    <div style={{ backgroundColor: '#edf0f5', padding: '2rem' }} className=" col-12 card">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="noOfGracePeriods" className={classNames({ 'p-error': isFormFieldValid('noOfGracePeriods') }, 'Label__Text')}>
                                    Number of Days <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    disabled={true}
                                    id="noOfGracePeriods"
                                    placeholder="Enter Number of Days"
                                    name="noOfGracePeriods"
                                    value={formik?.values?.noOfGracePeriods || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('noOfGracePeriods') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('noOfGracePeriods')}
                            </div>
                        </div>
                    </div>
                    <div className="Form__Header">
                        <h1>LATE PAYMENT </h1>
                    </div>
                    <div className="p-field">
                        <div className="field-checkbox">
                            <Checkbox
                                disabled={true}
                                inputId="latePayment"
                                name="latePayment"
                                value={formik?.values?.latePayment}
                                checked={formik?.values?.charges != null ? true : false}
                                onChange={formik.handleChange}
                                style={{ marginTop: '10px' }}
                            />
                            <label htmlFor="latePayment" className={classNames({ 'p-error': isFormFieldValid('latePayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                Late Payment
                            </label>
                        </div>
                        {getFormErrorMessage('latePayment')}
                    </div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="charges" className={classNames({ 'p-error': isFormFieldValid('charges') }, 'Label__Text')}>
                                Charges <span className="Label__Required"></span>
                            </label>
                            <InputText
                                disabled={true}
                                id="charges"
                                placeholder="Enter Charges"
                                name="charges"
                                value={formik?.values?.charges || ''}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('charges') }, 'Input__Round')}
                            />
                            {getFormErrorMessage('charges')}
                        </div>
                    </div>
                    <div className="Form__Header">
                        <h1>REPAYMENT SEQUENCE </h1>
                    </div>
                    <div className="Centre_Aligned_Buttons">
                        {sortedArray?.map((item, index) => {
                            return <Button key={index} label={item?.label === 'serviceChargesSeq' ? 'Markup' : item?.label === 'feesSeq' ? 'Fees' : item?.label === 'principalAmountSeq' ? 'Principal Amount' : null} className="Light_Button" />;
                        })}
                    </div>
                    <div className="Down__Btn" style={{ marginTop: '40px' }}>
                        <Button label="Okay" className="Btn__Dark" onClick={() => navigate('/loanaccountmanagement')} />
                    </div>
                </form>
            </div>
        </>
    );
}
export default ViewActiveLoan;

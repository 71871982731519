import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { RadioButton } from 'primereact/radiobutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewParserChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [columnData, setColumnData] = useState([]);

    let { search } = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const tableName = query.get('tableName');

    const getColumnTypeLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_COLUMN_TYPE');
        setColumnData(res?.payLoad);
    };

    useEffect(() => {
        getColumnTypeLov();
    }, []);

    const formik = useFormik({
        initialValues: {
            cmsParserHeadId: '',
            code: '',
            isActive: '',
            description: '',
            tblCmsParserDetails: [],
            action: '',
            checkerComments: ''
        }
    });

    const getThemeById = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/getparserbyid/${refTableId}`);
        const keyData = res?.payLoad;
        formik.setFieldValue('cmsParserHeadId', keyData?.cmsParserHeadId);
        formik.setFieldValue('code', keyData?.code);
        formik.setFieldValue('description', keyData?.description);
        formik.setFieldValue('tblCmsParserDetails', keyData?.tblCmsParserDetails);
    };

    const getMcRequestDataById = async () => {
        setloading(true);
        const res = await handleGetRequest(`/cms/v1/cms/getmcrequestbyid/${mcRequestId}`);
        const unParsedkeyData = res?.payLoad?.updateJson;
        const keyData = JSON.parse(unParsedkeyData);
        formik.setFieldValue('cmsParserHeadId', keyData?.cmsParserHeadId);
        formik.setFieldValue('code', keyData.code);
        formik.setFieldValue('isActive', keyData.isActive);
        formik.setFieldValue('description', keyData.description);
        formik.setFieldValue('tblCmsParserDetails', keyData.tblCmsParserDetails);
        setloading(false);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getThemeById();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, [requestType]); // eslint-disable-line

    const actionTemplate = () => {
        return (
            <div>
                <Button tooltip="Edit" disabled icon="pi pi-trash" tooltipOptions={{ position: 'top' }} type="button" className="p-button-rounded p-button-warning mr-3" />
            </div>
        );
    };
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="Form__Header Full__Width">
                        <h2>VIEW DETAILS</h2>
                    </div>
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-fluid p-formgrid grid mb-5 col-12 md:col-12 ">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="code" className={classNames({ 'p-error': isFormFieldValid('code') }, 'Label__Text')}>
                                        Code<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="code"
                                        placeholder="Enter Voucher Code"
                                        name="code"
                                        value={formik?.values?.code || ''}
                                        onChange={formik.handleChange}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('code') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('code')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="desc_Text_Area">
                                    <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') }, 'Label__Text')}>
                                        Description<span className="Label__Required">*</span>
                                    </label>
                                    <InputTextarea
                                        placeholder="-"
                                        inputid="description"
                                        name="description"
                                        rows={4}
                                        cols={30}
                                        onChange={formik.handleChange}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('description') }, 'Input__Round')}
                                        value={formik?.values?.description}
                                    />
                                    {getFormErrorMessage('description')}
                                </div>
                            </div>
                            <div className="card Card__Bg__Change col-12 md:col-12 px-3">
                                <h6 className="secondary-color pt-1">
                                    <b>PSRSER HEAD DETAIL</b>
                                </h6>
                                <div className="flex">
                                    <div className="p-fluid p-formgrid grid mb-5 col-10 md:col-10 ">
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="columnName" className={classNames({ 'p-error': isFormFieldValid('columnName') }, 'Label__Text')}>
                                                    Column Name<span className="Label__Required">*</span>
                                                </label>
                                                <InputText
                                                    maxLength={100}
                                                    placeholder="Enter Column Name"
                                                    id="columnName"
                                                    name="columnName"
                                                    value={formik?.values?.columnName || ''}
                                                    onChange={formik.handleChange}
                                                    disabled
                                                    className={classNames({ 'p-invalid': isFormFieldValid('columnName') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('columnName')}
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="columnType" className={classNames({ 'p-error': isFormFieldValid('typeOfColumn') }, 'Label__Text')}>
                                                    Column Type<span className="Label__Required">*</span>
                                                </label>
                                                <Dropdown
                                                    id="columnType"
                                                    name="columnType"
                                                    placeholder="Select Sequence"
                                                    options={columnData}
                                                    optionLabel="name"
                                                    optionValue="lovId"
                                                    value={formik?.values?.columnType || ''}
                                                    onChange={formik?.handleChange}
                                                    disabled
                                                    className={classNames({ 'p-invalid': isFormFieldValid('columnType') }, 'Dropdown__Round')}
                                                />

                                                {getFormErrorMessage('columnType')}
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="minLength" className={classNames({ 'p-error': isFormFieldValid('minLength') }, 'Label__Text')}>
                                                    Min Length<span className="Label__Required">*</span>
                                                </label>
                                                <InputText
                                                    placeholder="Enter Min Length"
                                                    maxLength={100}
                                                    id="minLength"
                                                    name="minLength"
                                                    value={formik?.values?.minLength || ''}
                                                    onChange={formik.handleChange}
                                                    disabled
                                                    className={classNames({ 'p-invalid': isFormFieldValid('minLength') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('minLength')}
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                            <div className="p-field">
                                                <label htmlFor="maxLength" className={classNames({ 'p-error': isFormFieldValid('maxLength') }, 'Label__Text')}>
                                                    Max Length<span className="Label__Required">*</span>
                                                </label>
                                                <InputText
                                                    placeholder="Enter max length"
                                                    maxLength={100}
                                                    id="maxLength"
                                                    name="maxLength"
                                                    value={formik?.values?.maxLength || ''}
                                                    onChange={formik.handleChange}
                                                    disabled
                                                    className={classNames({ 'p-invalid': isFormFieldValid('maxLength') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('maxLength')}
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                            <div className="p-field">
                                                <label htmlFor="sequence" className={classNames({ 'p-error': isFormFieldValid('sequence') }, 'Label__Text')}>
                                                    Sequence<span className="Label__Required">*</span>
                                                </label>
                                                <InputText
                                                    placeholder="Enter sequence"
                                                    maxLength={100}
                                                    id="sequence"
                                                    name="sequence"
                                                    value={formik?.values?.sequence || ''}
                                                    onChange={formik.handleChange}
                                                    disabled
                                                    className={classNames({ 'p-invalid': isFormFieldValid('sequence') }, 'Input__Round')}
                                                />

                                                {getFormErrorMessage('sequence')}
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="isMandatory" className={classNames({ 'p-error': isFormFieldValid('isMandatory') }, 'Label__Text')}>
                                                    Mandatory
                                                </label>
                                                <div className="flex">
                                                    <div className="mt-2 mr-5">
                                                        <RadioButton inputId="Yes" value="Y" name="isMandatory" onChange={formik.handleChange} checked={formik?.values?.isMandatory === 'Y'} disabled />
                                                        <label htmlFor="yes" className={classNames({ 'p-error': isFormFieldValid('Yes') }, 'Label__Text')}>
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div className="mt-2">
                                                        <RadioButton inputId="no" name="isMandatory" value="N" onChange={formik.handleChange} checked={formik?.values?.isMandatory === 'N'} disabled />
                                                        <label htmlFor="no" className={classNames({ 'p-error': isFormFieldValid('no') }, 'Label__Text')}>
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="isDefault" className={classNames({ 'p-error': isFormFieldValid('isDefault') }, 'Label__Text')}>
                                                    Length
                                                </label>
                                                <div className="flex">
                                                    <div className="mt-2 mr-5">
                                                        <Checkbox
                                                            inputId="fixedLength"
                                                            name="fixedLength"
                                                            checked={formik?.values?.fixedLength === 'Y'}
                                                            onChange={() => {
                                                                formik.setFieldValue('fixedLength', 'Y');
                                                                formik.setFieldValue('isDefault', 'N');
                                                            }}
                                                            style={{ marginTop: '10px' }}
                                                            disabled
                                                        />
                                                        <label htmlFor="fixedLength" className={classNames({ 'p-error': isFormFieldValid('fixedLength') }, 'Label__Text')}>
                                                            Fixed
                                                        </label>
                                                    </div>
                                                    <div className="mt-2">
                                                        <Checkbox
                                                            inputId="isDefault"
                                                            name="isDefault"
                                                            checked={formik?.values?.isDefault === 'Y'}
                                                            onChange={() => {
                                                                formik.setFieldValue('isDefault', 'Y');
                                                                formik.setFieldValue('fixedLength', 'N');
                                                            }}
                                                            style={{ marginTop: '10px' }}
                                                            disabled
                                                        />
                                                        <label htmlFor="isDefault" className={classNames({ 'p-error': isFormFieldValid('isDefault') }, 'Label__Text')}>
                                                            Is Default
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-2 md:col-2 flex flex-column align-items-center justify-content-center mb-3">
                                        <button className="addition_Button" type="button">
                                            +
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className="card">
                                <DataTable
                                    // ref={dataTableRef}
                                    paginator
                                    rows={5}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    className="datatable-responsive"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                                    globalFilterFields={['validatorName', 'validatorType', 'createdate', 'createdBy', 'updatedBy', 'lkpStatus.statusDescr']}
                                    emptyMessage="No List found."
                                    responsiveLayout="scroll"
                                    value={formik.values.tblCmsParserDetails}
                                >
                                    <Column field="columnName" header="Column Name" />
                                    <Column field="columnType" header="Column Type" />
                                    <Column field="minLength" header="Min Length" />
                                    <Column field="maxLength" header="Max Length" />
                                    <Column field="sequence" header="Sequence" />
                                    <Column field="isMandatory" header="Mandatory" />
                                    <Column field="isDefault" header="Length" />
                                    <Column body={actionTemplate} header="Action" />
                                </DataTable>
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button disabled={loading} type="button" onClick={() => navigate('/requestChecker')} label="Okay" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default ViewParserChecker;

import React, { useEffect, useState } from 'react';
// import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { Divider } from 'primereact/divider';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import classNames from 'classnames';

const EditChannelDetails = ({ channelBlockingResponse, onHide, handleChannelDetailsCallBack }) => {
    const [disableSubmit, setDisableSubmit] = useState(true);

    const formik = useFormik({
        initialValues: {
            outPosDom: channelBlockingResponse?.outPosDom,
            outPosInt: channelBlockingResponse?.outPosInt,
            outEcomm: channelBlockingResponse?.outEcomm || channelBlockingResponse?.ecomm,
            outEcommInt: channelBlockingResponse?.outEcommInt || channelBlockingResponse?.intEcommerceAllowed,
            outAtmDom: channelBlockingResponse?.outAtmDom,
            outAtmInt: channelBlockingResponse?.outAtmInt,
            comment: channelBlockingResponse?.comment
        },

        onSubmit: async () => {
            const isChannelDetailsUpdateReq = !disableSubmit;
            const newValues = {
                ...formik.values,
                isChannelDetailsUpdateReq: isChannelDetailsUpdateReq
            };
            handleChannelDetailsCallBack(newValues);
            onHide();
        }
    });

    useEffect(() => {
        const hasChanges = Object.keys(formik.values).some((key) => formik.values[key] !== channelBlockingResponse[key]);
        setDisableSubmit(!hasChanges);
    }, [formik.values, channelBlockingResponse]);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <section>
            <Divider />
            <form onSubmit={formik.handleSubmit}>
                <div className="Card__Round p-fluid p-formgrid grid">
                    <div className="p-field col-12 md:col-6 pt-4">
                        <label>POS</label>
                        <div>
                            <div className="flex pt-3">
                                <div className="mr-8 flex">
                                    <Checkbox
                                        name="outPosInt"
                                        value="N"
                                        onChange={() => {
                                            if (formik.values.outPosInt === 'N') {
                                                formik.setFieldValue('outPosInt', 'Y');
                                            } else {
                                                formik.setFieldValue('outPosInt', 'N');
                                            }
                                        }}
                                        checked={formik.values.outPosInt === 'Y'}
                                    />
                                    <label className="ml-2">International POS Allowed</label>
                                </div>
                                <div className="flex">
                                    <Checkbox
                                        name="outPosDom"
                                        value="Y"
                                        onChange={() => {
                                            if (formik.values.outPosDom === 'N') {
                                                formik.setFieldValue('outPosDom', 'Y');
                                            } else {
                                                formik.setFieldValue('outPosDom', 'N');
                                            }
                                        }}
                                        checked={formik.values.outPosDom === 'Y'}
                                    />
                                    <label className="ml-2">Local POS Allowed</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-6 pt-4">
                        <label>ATM</label>
                        <div>
                            <div className="flex pt-3">
                                <div className="mr-8 flex">
                                    <Checkbox
                                        name="outAtmInt"
                                        value="N"
                                        onChange={() => {
                                            if (formik.values.outAtmInt === 'N') {
                                                formik.setFieldValue('outAtmInt', 'Y');
                                            } else {
                                                formik.setFieldValue('outAtmInt', 'N');
                                            }
                                        }}
                                        checked={formik.values.outAtmInt === 'Y'}
                                    />
                                    <label className="ml-2">International ATM Allowed</label>
                                </div>
                                <div className="flex">
                                    <Checkbox
                                        name="outAtmDom"
                                        value="Y"
                                        onChange={() => {
                                            if (formik.values.outAtmDom === 'N') {
                                                formik.setFieldValue('outAtmDom', 'Y');
                                            } else {
                                                formik.setFieldValue('outAtmDom', 'N');
                                            }
                                        }}
                                        checked={formik.values.outAtmDom === 'Y'}
                                    />
                                    <label className="ml-2">Local ATM Allowed</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-6 pt-4">
                        <label>Ecommerce</label>
                        <div>
                            <div className="flex pt-3">
                                <div className="mr-8 flex">
                                    <Checkbox
                                        name="outEcommInt"
                                        value="N"
                                        onChange={() => {
                                            if (formik.values.outEcommInt === 'N') {
                                                formik.setFieldValue('outEcommInt', 'Y');
                                            } else {
                                                formik.setFieldValue('outEcommInt', 'N');
                                            }
                                        }}
                                        checked={formik.values.outEcommInt === 'Y'}
                                    />
                                    <label className="ml-2">International Ecommerce Allowed</label>
                                </div>
                                <div className="flex">
                                    <Checkbox
                                        name="outEcomm"
                                        value="Y"
                                        onChange={() => {
                                            if (formik.values.outEcomm === 'N') {
                                                formik.setFieldValue('outEcomm', 'Y');
                                            } else {
                                                formik.setFieldValue('outEcomm', 'N');
                                            }
                                        }}
                                        checked={formik.values.outEcomm === 'Y'}
                                    />
                                    <label className="ml-2">Local Ecommerce Allowed</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Card__Round p-fluid p-formgrid grid">
                    <div className="p-field col-12 mt-5 pb-3">
                        <label htmlFor="comment" className={classNames({ 'p-error': isFormFieldValid('comment') }, 'Label__Text')}>
                            Comment
                        </label>
                        <InputTextarea
                            placeholder="-"
                            inputid="comment"
                            name="comment"
                            rows={5}
                            cols={30}
                            onChange={formik.handleChange}
                            // onKeyDown={(e) => handleKeyPress(e)}
                            className={classNames({ 'p-invalid': isFormFieldValid('comment') }, 'Input__Round resizeNone')}
                            value={formik?.values?.comment}
                        />
                        {getFormErrorMessage('comment')}
                    </div>
                </div>
                <div className="Down__Btn mt-4">
                    <Button label="Update" iconPos="right" className="Btn__Dark" type="submit" disabled={disableSubmit} />
                    <Button label="Cancel" iconPos="right" className="Btn__cancel" type="button" onClick={onHide} />
                </div>
            </form>
        </section>
    );
};

export default EditChannelDetails;

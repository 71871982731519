import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';

const Fees = () => {
    const [loading, setloading] = useState(false);
    // const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    // const [feeData, setFeeData] = useState();
    const [globalFilter, setGlobalFilter] = useState('');
    // const [editable, setEditable] = useState(false);

    const navigate = useNavigate();

    const FeesJson = [
        {
            product: 'Cash In',
            agentNetwork: 'BranchTellerNetwork',
            segment: 'BLB payroll',
            channel: 'Customer Mobile App',
            rangeStart: '0',
            rangeEnd: '1000',
            exclusiveFix: '-',
            exclusivePercentage: '2',
            inclusiveFix: '-',
            inclusivePercentage: '-',
            fedInclExcl: 'Inclusive'
        },
        {
            product: 'Cash out',
            agentNetwork: 'JSBL LSA network',
            segment: 'DFS payroll',
            channel: 'ATM',
            rangeStart: '0',
            rangeEnd: '1000',
            exclusiveFix: '5',
            exclusivePercentage: '-',
            inclusiveFix: '-',
            inclusivePercentage: '-',
            fedInclExcl: 'Exclusive'
        },
        {
            product: 'Wallet to Cnic',
            agentNetwork: 'JSBL internal agent network',
            segment: 'Non DFS payroll',
            channel: 'Web',
            rangeStart: '0',
            rangeEnd: '1000',
            exclusiveFix: '-',
            exclusivePercentage: '-',
            inclusiveFix: '-',
            inclusivePercentage: '0.5',
            fedInclExcl: 'Exclusive'
        },
        {
            product: 'Send Money',
            agentNetwork: 'Default Network',
            segment: 'EB payroll',
            channel: 'Kannel',
            rangeStart: '0',
            rangeEnd: '1000',
            exclusiveFix: '-',
            exclusivePercentage: '1',
            inclusiveFix: '-',
            inclusivePercentage: '-',
            fedInclExcl: 'Inclusive'
        },
        {
            product: 'Wallet to JS Bank Account',
            agentNetwork: 'Bayfikr',
            segment: 'EB and DFS payroll',
            channel: 'Mobile App',
            rangeStart: '0',
            rangeEnd: '1000',
            exclusiveFix: '-',
            exclusivePercentage: '-',
            inclusiveFix: '10',
            inclusivePercentage: '-',
            fedInclExcl: 'Inclusive'
        },
        {
            product: 'Cnic to Wallet',
            agentNetwork: 'WebService Agent Network',
            segment: 'EB and DFS payroll',
            channel: 'USSD',
            rangeStart: '0',
            rangeEnd: '1000',
            exclusiveFix: '-',
            exclusivePercentage: '0.8',
            inclusiveFix: '-',
            inclusivePercentage: '-',
            fedInclExcl: 'Inclusive'
        },
        {
            product: 'Retail Payment',
            agentNetwork: 'SCO AJK',
            segment: 'EB and DFS payroll',
            channel: 'Backend Services',
            rangeStart: '0',
            rangeEnd: '1000',
            exclusiveFix: '-',
            exclusivePercentage: '0.8',
            inclusiveFix: '-',
            inclusivePercentage: '-',
            fedInclExcl: 'Exclusive'
        }
    ];

    const handledClicked = () => {
        return navigate('/addfee');
    };

    const getFees = async () => {
        setloading(true);
        const res = await handleGetRequest('/offline_Biller/company/getAllUbpCompany');
        if (res?.responsecode === 1) {
            setloading(false);
            // setFeeData(res?.data);
        }
    };

    useEffect(() => {
        getFees();
    }, []);

    const header = (
        <div className="Global__Header">
            <h5 className="m-0">Fees</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    // const handledEditClicked = (rowData) => {
    //     setEditable(true);
    //     navigate(`/addofflinebiller?editable=true&ubpId=${rowData?.ubpCompanyId}`);
    // };

    // const actionBodyTemplate = (rowData) => {
    //     return (
    //         <div>
    //             <Button icon="pi pi-pencil" className="p-button-rounded p-button-info mr-2" onClick={() => handledEditClicked(rowData)} />
    //         </div>
    //     );
    // };

    return (
        <>
            <div className="Top__Btn">
                <div>
                    <Button label="New" onClick={handledClicked} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            globalFilter={globalFilter}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={FeesJson}
                            loading={loading}
                        >
                            <Column field="product" header="Product" />
                            <Column field="agentNetwork" header="Agent Network" />
                            <Column field="segment" header="Segment" />
                            <Column field="channel" header="Channel" />
                            <Column field="rangeStart" header="Range Start" />
                            <Column field="rangeEnd" header="Range End" />
                            <Column field="exclusiveFix" header="Exclusive Fix" />
                            <Column field="exclusivePercentage" header="Exclusive Percentage" />
                            <Column field="inclusiveFix" header="Inclusive Fix" />
                            <Column field="inclusivePercentage" header="Inclusive Percentage" />
                            <Column field="fedInclExcl" header="FED Incl/Excl" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Fees;

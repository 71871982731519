import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CommissionHierarchy from './CommissionHierarchy';
import { handleGetRequest } from '../../../services/GetTemplate';

function MakerApis({ type }) {
    const [tableData, setTableData] = useState([]);
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const commissionId = query.get('commissionId');

    const getDatabyId = async () => {
        const resp = await handleGetRequest(`/pricingprofile/v1/commissionprofile/getcommissionhierarchybyid/${commissionId}`);
        const keyData = resp?.payLoad;
        setTableData(keyData);
    };

    useEffect(() => {
        if (commissionId) {
            getDatabyId();
        }
    }, [commissionId]);

    return (
        <React.Fragment>
            <CommissionHierarchy type={type} dataForTable={tableData} />
        </React.Fragment>
    );
}
export default MakerApis;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { ColorPicker } from 'primereact/colorpicker';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';

function AddSms() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [useCaseData, setUseCaseData] = useState([]);
    const [SMSType, setSMSType] = useState([]);
    const [color, setColor] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const getUseCase = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_USECASE');
        setUseCaseData(resp?.payLoad);
    };

    const getSMSType = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_SMS_TYPE');
        setSMSType(resp?.payLoad);
    };

    useEffect(() => {
        getSMSType();
        getUseCase();
    }, []); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        // smsTitle: Yup.string()
        // .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
        // .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
        // .required('This field is required'),
        smsTitle: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/, 'Only alphanumeric characters and spaces are allowed')

            .required('This Field is required'),
           
        cmsSmsTypeId: Yup.string().required('This field is required'),
        cmsUsecaseId: Yup.string().required('This field is required'),
        smsText: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
            .required('This field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cmsSmsId: '',
            cmsSmsTypeId: '',
            smsTitle: '',
            smsText: '',
            smsDatetime: '',
            cmsUsecaseId: '',
            isActive: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(newData, '/cms/v1/cms/savesms', true, false, 'sms'));
            if (res?.responseCode === '190000') {
                formik.resetForm();
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getCurrentDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const day = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>ADD NEW</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsSmsTypeId" className={classNames({ 'p-error': isFormFieldValid('cmsSmsTypeId') }, 'Label__Text')}>
                                    SMS Type <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="cmsSmsTypeId"
                                    placeholder="Enter SMS Type"
                                    options={SMSType}
                                    optionLabel="name"
                                    name="cmsSmsTypeId"
                                    optionValue="lovId"
                                    value={formik.values.cmsSmsTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsSmsTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsSmsTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="smsTitle" className={classNames({ 'p-error': isFormFieldValid('smsTitle') }, 'Label__Text')}>
                                    Title <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="smsTitle"
                                    // keyfilter={/^[A-Za-z0-9\s]+$/}
                                    placeholder="Enter Title"
                                    name="smsTitle"
                                    value={formik?.values?.smsTitle || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('smsTitle') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('smsTitle')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="smsDatetime" className={classNames({ 'p-error': isFormFieldValid('smsDatetime') }, 'Label__Text')}>
                                    Date & Time
                                </label>
                                {/* <InputText
                                    placeholder="Enter Date & Time"
                                    type="date"
                                    id="smsDatetime"
                                    name="smsDatetime"
                                    value={formik?.values?.smsDatetime || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('smsDatetime') }, 'Input__Round')}
                                /> */}
                                 <InputText
                                                    id="smsDatetime"
                                                    placeholder="Enter  Start Date & Time"
                                                    name="smsDatetime"
                                                    type="datetime-local"
                                                    min={getCurrentDateTime()}
                                                    value={formik?.values?.smsDatetime || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('smsDatetime') }, 'Input__Round')}
                                                />
                                {getFormErrorMessage('smsDatetime')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsUsecaseId" className={classNames({ 'p-error': isFormFieldValid('cmsUsecaseId') }, 'Label__Text')}>
                                    Use Case<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="cmsUsecaseId"
                                    placeholder="Select Use Case"
                                    options={useCaseData}
                                    optionLabel="name"
                                    name="cmsUsecaseId"
                                    optionValue="lovId"
                                    value={formik.values.cmsUsecaseId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsUsecaseId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsUsecaseId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="smsText" className={classNames({ 'p-error': isFormFieldValid('smsText') }, 'Label__Text')}>
                                    SMS Text <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    placeholder="Enter SMS Text"
                                    maxLength={100}
                                    id="smsText"
                                    name="smsText"
                                    keyfilter={/^[A-Za-z0-9\s]+$/}
                                    value={formik?.values?.smsText || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('smsText') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('smsText')}
                            </div>
                            {/* <div className="card flex justify-content-center">
                                <ColorPicker value={color} onChange={(e) => setColor(e.value)} />
                            </div> */}
                        </div>
                    </div>
                    <div className="Down__Btn pt-7">
                        <Button disabled={loading} loadingIcon={loadingIcon || ''} type="submit" label="Submit" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/sms')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddSms;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';

import classNames from 'classnames';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import * as Yup from 'yup';

import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import SecuredFileChange from '../../../../components/SecuredFileChange';

function CustomerAcUploadFile({}) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    const [selectedFile, setSelectedFile] = useState(null);
    const [ftpFileData, setFtpFileData] = useState([]);
    const [accountNatureData, setAccountNatureData] = useState([]);
    const [dsFileError, setDsFileError] = useState('');

    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        description: Yup.mixed().required('This field is required.'),
        uploadMethod: Yup.mixed().required('This field is required.'),
        accountNatureId: Yup.mixed().required('This field is required.'),
        digitalSigningInput: Yup.mixed().when('uploadMethod', {
            is: 'CSV',
            then: Yup.mixed().required('Please choose a file.')
        }),
        ftpFileName: Yup.mixed().when('uploadMethod', {
            is: 'FTP',
            then: Yup.mixed().required('This field is required.')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            bulkOperationName: 'BAN',
            uploadMethod: '',
            description: '',
            ftpFileName: '',
            accountNatureId: '',
            digitalSigningInput: ''
        },

        onSubmit: async (data) => {
            let { digitalSigningInput, ...rest } = data;

            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: rest,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const formData = new FormData();
            formData.append('request', JSON.stringify(newData));
            if (selectedFile) {
                formData.append('file', selectedFile);
            }

            const res = await dispatch(handlePostRequest(formData, '/batchprocessing/v1/batchprocessing/uploadbulkfile', true, false, 'CustomerAcNatureMarkingBulkUpload'));
            if (res?.responseCode === '170000') {
                formik.resetForm();
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getFtpFile = async () => {
        const resp = await handleGetRequest('/batchprocessing/v1/batchprocessing/getftpfiles/BAN');
        setFtpFileData(resp?.payLoad);
    };
    const getAccountNature = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getaccountnatures/LKP_ACCOUNT_NATURE:*');
        setAccountNatureData(resp?.payLoad);
    };

    useEffect(() => {
        getFtpFile();
        getAccountNature();
    }, []); // eslint-disable-line

    const handleDigitalSigningInput = () => {
        digitalSigningRef.current.click();
    };
    const digitalSigningRef = React.useRef(null);

    const digitalSigningChange = (event) => {
        formik.setFieldValue('digitalSigningInput', '');
        setDsFileError('');
        const selectedFile = event.target.files[0];
        const fileTypes = ['csv'];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setDsFileError(error);
        } else if (newFile) {
            setSelectedFile(selectedFile);
            formik.setFieldValue('digitalSigningInput', selectedFile?.name);
            const reader = new FileReader();

            reader.onload = () => {
                const base64Data = reader.result;
            };

            reader.readAsDataURL(selectedFile);
        }
    };

    const downloadSample = async () => {
        handleGetRequest('/batchprocessing/v1/batchprocessing/downloadfile?bulkOperationName=BAN').then((response) => {
            // Convert the data to CSV format using PapaParse

            const textData = response;

            const blob = new Blob([textData], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'BAN.csv');

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        });
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>CUSTOMER AC NATURE MARKING BULK UPLOAD</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountNatureId" className={classNames({ 'p-error': isFormFieldValid('accountNatureId') }, 'Label__Text')}>
                                    Account Nature
                                </label>
                                <span className="Label__Required">*</span>
                                <Dropdown
                                    id="accountNatureId"
                                    placeholder="Select Updated By"
                                    options={accountNatureData}
                                    optionLabel="name"
                                    name="accountNatureId"
                                    optionValue="lovId"
                                    value={formik.values.accountNatureId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountNatureId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('accountNatureId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="uploadMethod" className={classNames({ 'p-error': isFormFieldValid('useCaseDescr') }, 'Label__Text')}>
                                File Location
                            </label>
                            <span className="Label__Required">*</span>
                            <div className="flex">
                                <div className="flex mr-3">
                                    <RadioButton inputId="uploadMethod" name="uploadMethod" value="CSV" onChange={formik.handleChange} checked={formik.values.uploadMethod === 'CSV'} />
                                    <label htmlFor="uploadMethod" className="ml-2">
                                        CSV
                                    </label>
                                </div>
                                <div style={{ marginLeft: '5rem' }} className="flex mr-3">
                                    <RadioButton inputId="uploadMethod" name="uploadMethod" value="FTP" onChange={formik.handleChange} checked={formik.values.uploadMethod === 'FTP'} />
                                    <label htmlFor="uploadMethod" className="ml-2">
                                        FTP
                                    </label>
                                </div>
                            </div>
                            {getFormErrorMessage('uploadMethod')}
                        </div>

                        {formik.values.uploadMethod == 'CSV' && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <div className="mb-2">
                                            <label className={classNames({ 'p-error': isFormFieldValid('digitalSigningInput') }, 'Label__Text')}>
                                                CSV File
                                                <a className="cursor-pointer ml-2" onClick={() => downloadSample()}>
                                                    Download Sample File
                                                </a>
                                                <span className="Label__Required">*</span>
                                            </label>
                                            <div className="file-input-container w-100" onClick={handleDigitalSigningInput}>
                                                <input type="file" accept=".csv" style={{ display: 'none' }} ref={digitalSigningRef} onChange={digitalSigningChange} />
                                                <input
                                                    type="text"
                                                    className={classNames({ 'p-inputtext p-component p-invalid': isFormFieldValid('digitalSigningInput') }, 'file-input-field Input__Round')}
                                                    placeholder="Choose a file..."
                                                    value={formik.values.digitalSigningInput}
                                                    readOnly
                                                />
                                                <span className="file-upload-icon">
                                                    <i className="pi pi-paperclip" />
                                                </span>
                                            </div>
                                            {dsFileError ? <small className="p-error">{dsFileError}</small> : getFormErrorMessage('digitalSigningInput')}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {formik.values.uploadMethod == 'FTP' && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="ftpFileName" className={classNames({ 'p-error': isFormFieldValid('ftpFileName') }, 'Label__Text')}>
                                            File
                                        </label>
                                        <span className="Label__Required">*</span>
                                        <Dropdown
                                            id="ftpFileName"
                                            placeholder="Select Disbursement Occurence"
                                            options={ftpFileData}
                                            optionLabel="ftpFileName"
                                            name="ftpFileName"
                                            optionValue="ftpFilePath"
                                            value={formik.values.ftpFileName || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('ftpFileName') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('ftpFileName')}
                                    </div>
                                    <div className="p-field col-12 md:col-4"></div>
                                </div>
                            </>
                        )}
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') }, 'Label__Text')}>
                                    Description
                                </label>
                                <span className="Label__Required">*</span>
                                <InputTextarea
                                    // placeholder="Add Description"
                                    id="description"
                                    name="description"
                                    value={formik?.values?.description}
                                    rows={5}
                                    cols={5}
                                    autoResize="false"
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('description') }, 'TextArea__Round')}
                                />
                                {getFormErrorMessage('description')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon || ''} label="Submit" className="Btn__Dark mt-5" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default CustomerAcUploadFile;

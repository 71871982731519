import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import AddNewMerchant from './AddMerchantDialog';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';

function EditAggregator() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');
    const [aggregatorTableData, setAggregatorTableData] = useState([]);
    const [productLov, setProductLov] = useState();
    const [varientLov, setvarientLov] = useState();
    const [aggregatorLov, setAggregatorLov] = useState();
    const [show, setShow] = useState(false);
    const [merchantData, setMerchantData] = useState([]);
    const [aggregatorTypeData, setAggregatorTypeData] = useState({
        productId: '',
        varientId: '',
        discount: ''
    });
    const [aggregatorError, setAggregatorError] = useState({
        productId: '',
        varientId: '',
        discount: ''
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const aggregatorId = query.get('aggregatorId');

    const getAllProducts = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/lending/TBL_LMS_PRODUCT');
        setProductLov(res?.payLoad);
    };
    const getVarientLov = async () => {
        const response = await handleGetRequest(`/configuration/v1/lookups/lending/TBL_LMS_VARIANT_AGGREGATOR/${aggregatorTypeData?.productId}`);
        setvarientLov(response?.payLoad);
    };
    const getAggregatorLov = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/lending/TBL_LMS_AGGREGATOR');
        setAggregatorLov(response?.payLoad);
    };

    useEffect(() => {
        getAggregatorLov();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (aggregatorTypeData?.productId === null || aggregatorTypeData?.productId === undefined || aggregatorTypeData?.productId === '') {
            getAllProducts();
        } else {
            getVarientLov();
        }
    }, [aggregatorTypeData?.productId]); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            aggregatorId: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        aggregatorHeadId: aggregatorId,
                        aggregatorId: data?.aggregatorId,
                        aggregatorProductRequestList: aggregatorTableData,
                        aggregatorMerchantRequests: aggregatorMerchantRequest
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            await dispatch(handlePostRequest(newData, '/lending/v1/lending/updateaggregator', true, false, 'aggregator'));
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const getDatabyId = async () => {
        const response = await handleGetRequest(`/lending/v1/lending/getaggregatorbyid/${aggregatorId}`);

        formik.setFieldValue('aggregatorId', response?.payLoad?.tblLmsAggregator?.lmsAggregatorId.toString());

        const getProductData = response?.payLoad?.tblLmsAgrProducts?.map((item) => {
            return {
                discount: item?.discount,
                productId: item?.tblLmsProduct?.lmsProductId,
                varientId: item?.tblLmsVariant?.lmsVariantId
            };
        });
        setAggregatorTableData(getProductData);

        const getMerchantName = response?.payLoad?.tblLmsAgrMerchants?.map((item) => {
            return item?.tblMerchant;
        });

        setMerchantData(getMerchantName);
    };
    useEffect(() => {
        getDatabyId();
    }, [aggregatorId]); // eslint-disable-line

    const aggregatorMerchantRequest = merchantData?.map((item) => {
        return {
            merchantId: item?.lovId || item?.merchantId
        };
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handledAccountTypeAdd = (e) => {
        e.preventDefault();

        const errors = {
            productId: '',
            varientId: '',
            discount: ''
        };

        if (aggregatorTypeData.productId === '') {
            errors.productId = 'This field is required.';
        }
        if (aggregatorTypeData.varientId === '') {
            errors.varientId = 'This field is required.';
        }
        if (aggregatorTypeData.discount === '') {
            errors.discount = 'This field is required.';
        } else if (!/^[^\s]+(\s[^\s]+)*$/.test(aggregatorTypeData?.discount)) {
            errors.discount = 'Space is not allowed';
        }

        // Check for duplicate productId
        if (aggregatorTableData.some((item) => item.productId === aggregatorTypeData.productId)) {
            errors.productId = 'This account Type is already in use.';
        }

        setAggregatorError(errors);

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            const accountTypeObjData = {
                productId: aggregatorTypeData.productId,
                varientId: aggregatorTypeData.varientId,
                discount: aggregatorTypeData.discount
            };
            setAggregatorTableData([...aggregatorTableData, accountTypeObjData]);

            setAggregatorTypeData({
                productId: '',
                varientId: '',
                discount: ''
            });
        }
    };

    const handledFieldInputaggregatorTypeData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;

        setAggregatorTypeData({ ...aggregatorTypeData, [name]: value });

        // Clear the error message for the field being edited
        setAggregatorError({
            ...aggregatorError,
            [name]: ''
        });
    };

    const handledDeleteAggregatorTypeData = (e, rowData, rowIndex) => {
        e.preventDefault();
        const newFilterData = aggregatorTableData.filter((value, index) => index !== rowIndex?.rowIndex);
        setAggregatorTableData(newFilterData);
    };

    const handledDeleteMerchantData = (e, rowData, rowIndex) => {
        e.preventDefault();
        const newData = merchantData.filter((value, index) => index !== rowIndex?.rowIndex);
        setMerchantData(newData);
    };

    const actionAggregatorBodyTemplate = (rowData, rowIndex) => {
        return (
            <>
                <div className="actions">
                    <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteAggregatorTypeData(e, rowData, rowIndex)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
                </div>
            </>
        );
    };

    const actionMerchantBodyTemplate = (rowData, rowIndex) => {
        return (
            <>
                <div className="actions">
                    <div className="actions">
                        <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteMerchantData(e, rowData, rowIndex)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
                    </div>
                </div>
            </>
        );
    };

    const hideDialogue = () => {
        setShow(false);
    };
    const handleModal = (e) => {
        e.preventDefault();
        setShow(true);
    };
    const handleMerchant = (data) => {
        const newdata = {
            businessName: data.name,
            lovId: data.lovId
        };
        setMerchantData([...merchantData, newdata]);
    };

    const aggregatorProductBodyTemplate = (rowData) => {
        const singleProduct = productLov?.filter((item) => item?.lovId == rowData?.productId);
        return <p>{singleProduct[0]?.name}</p>;
    };

    return (
        <>
            <Dialog visible={show} draggable={false} style={{ width: '30vw' }} onHide={() => hideDialogue()}>
                <AddNewMerchant handleMerchant={handleMerchant} hideDialogue={hideDialogue} />
            </Dialog>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>Edit Detail</h2>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="aggregatorId" className={classNames({ 'p-error': isFormFieldValid('aggregatorId') }, 'Label__Text')}>
                                    Aggregator
                                </label>
                                <Dropdown
                                    id="aggregatorId"
                                    placeholder="Select Aggregator"
                                    options={aggregatorLov}
                                    optionLabel="name"
                                    name="aggregatorId"
                                    optionValue="lovId"
                                    value={formik.values.aggregatorId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('aggregatorId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('aggregatorId')}
                            </div>
                        </div>
                    </div>

                    <div className="card pt-3" style={{ background: '#f8f8f8' }}>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="productId" className={classNames({ 'p-error': isFormFieldValid('productId') }, 'Label__Text')}>
                                        Product <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="productId"
                                        placeholder="Select Product"
                                        options={productLov}
                                        optionLabel="name"
                                        name="productId"
                                        optionValue="lovId"
                                        value={aggregatorTypeData.productId || ''}
                                        onChange={handledFieldInputaggregatorTypeData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('productId') }, 'Dropdown__Round')}
                                    />

                                    <small className="p-error">{aggregatorError.productId}</small>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="varientId" className={classNames({ 'p-error': isFormFieldValid('varientId') }, 'Label__Text')}>
                                        Varient <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="varientId"
                                        placeholder="Select Varient"
                                        options={varientLov}
                                        optionLabel="name"
                                        name="varientId"
                                        optionValue="lovId"
                                        value={aggregatorTypeData.varientId || ''}
                                        onChange={handledFieldInputaggregatorTypeData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('varientId') }, 'Dropdown__Round')}
                                    />

                                    <small className="p-error">{aggregatorError.varient}</small>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="discount" className={classNames({ 'p-error': isFormFieldValid('discount') }, 'Label__Text')}>
                                        Discount<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="discount"
                                        placeholder="Enter Discount"
                                        name="discount"
                                        value={aggregatorTypeData?.discount?.trim()?.toString() || ''}
                                        onChange={handledFieldInputaggregatorTypeData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('discount') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{aggregatorError.discount}</small>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-2 mr-5 mt-4">
                                <div className="Down__Btn__Configuration">
                                    <Button type="submit" label="Update" onClick={handledAccountTypeAdd} className="Btn__Add" />
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12">
                                <div className="card">
                                    <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={aggregatorTableData}>
                                        <Column header="Product" body={aggregatorProductBodyTemplate} />
                                        <Column header="Varient" field="varientId" />
                                        <Column header="Discount" field="discount" />
                                        <Column header="Actions" body={actionAggregatorBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid">
                        <div className="col-12">
                            <div className="card">
                                <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={merchantData}>
                                    <Column header="Merchant Name" field="businessName" />
                                    <Column header="Actions" body={actionMerchantBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label="Add New Merchant" onClick={(e) => handleModal(e)} className="Btn__Merchant" />
                        <Button type="submit" label="Update" className="Btn__Dark" />
                        <Button disabled={loading} loadingIcon={loadingIcon || ''} onClick={() => navigate('/aggregator')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default EditAggregator;

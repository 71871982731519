import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { handleGetRequest } from '../../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../../services/PostTemplate';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';

function AddProduct({ activeIndex, handleStepClick, getLendingId, lendingData }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');
    const [categoryLov, setCategoryLov] = useState([]);
    const [lmsCollateralLov, setCollateralLov] = useState([]);
    const [lmsFacilityTypeLov, setlmsFacilityTypeLov] = useState([]);
    const [lmsProductCategoryLov, setlmsProductCategoryLov] = useState([]);

    const [channelLov, setChannelLov] = useState([]);
    const [segmentLov, setSegmentLov] = useState([]);
    const [ruleSetLov, setRuleSetLov] = useState([]);

    const dispatch = useDispatch();

    const getLmsCategoryLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/lending/LKP_LMS_CATEGORY');
        setCategoryLov(res?.payLoad);
    };

    const getLmsCollateralLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/lending/LKP_LMS_COLLATERAL');
        setCollateralLov(res?.payLoad);
    };

    const getLmsFacilityTypeLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/lending/LKP_LMS_FACILITY_TYPE');
        setlmsFacilityTypeLov(res?.payLoad);
    };

    const getLmsProductCategoryLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/lending/LKP_LMS_PRODUCT_CATEGORY');
        setlmsProductCategoryLov(res?.payLoad);
    };

    const getLmsChannelLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchannels/LKP_CHANNEL:*');
        setChannelLov(res?.payLoad);
    };

    const getLmsSegmentLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getsegments/LKP_SEGMENT:*');
        setSegmentLov(res?.payLoad);
    };

    const getLmsRuleSetLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/lending/TBL_RULE');
        setRuleSetLov(res?.payLoad);
    };

    useEffect(() => {
        getLmsCategoryLov();
        getLmsCollateralLov();
        getLmsFacilityTypeLov();
        getLmsProductCategoryLov();
        getLmsChannelLov();
        getLmsSegmentLov();
        getLmsRuleSetLov();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (lendingData) {
            formik.setFieldValue('name', lendingData?.lmsProductName);

            formik.setFieldValue('description', lendingData?.description);
            formik.setFieldValue('categoryId', lendingData?.lkpLmsCategory?.lmsCategoryId?.toString());
            formik.setFieldValue('productCategoryId', lendingData?.lkpLmsProductCategory?.lmsProductCategoryId?.toString());
            formik.setFieldValue('ruleSetId', lendingData?.tblRule?.ruleId?.toString());
            if (lendingData?.salaryCycleDate !== null) {
                formik.setFieldValue('salaryDisbursmentDate', true);
            } else {
                formik.setFieldValue('salaryDisbursmentDate', false);
            }
            formik.setFieldValue('salaryCycleDate', new Date(2024, 0, lendingData?.salaryCycleDate || 1));
            formik.setFieldValue('dbrCaping', lendingData?.dbrCaping?.toString());

            const selectedProductChannels = lendingData?.tblLmsProductChannels
                ?.filter((itm) => itm?.isActive === 'Y')
                ?.map((item) => {
                    return { lovId: item?.lkpChannel?.channelId?.toString() };
                });

            const selectedSegments = lendingData?.tblLmsProductSegments
                ?.filter((itm) => itm?.isActive === 'Y')
                ?.map((item) => {
                    return { lovId: item?.lkpSegment?.segmentId?.toString() };
                });

            formik.setFieldValue('channelIds', selectedProductChannels);
            formik.setFieldValue('segmentIds', selectedSegments);

            formik.setFieldValue('maxAmount', lendingData?.maxAmount);
            formik.setFieldValue('minAmount', lendingData?.minAmount);

            formik.setFieldValue('serviceChargesType', lendingData?.processingFeeType);
            formik.setFieldValue('serviceChargesValue', lendingData?.processingFeeValue);

            // formik.setFieldValue('serviceChargesType', lendingData?.serviceChargesType);
            // formik.setFieldValue('serviceChargesValue', lendingData?.serviceChargesValue);

            formik.setFieldValue('facilityCode', lendingData?.lkpLmsFacilityType?.lmsFacilityTypeCode);
            formik.setFieldValue('collateralId', lendingData?.lkpLmsCollateral?.lmsCollateralId?.toString());

            formik.setFieldValue('balance', lendingData?.balance);
            formik.setFieldValue('unitValue', lendingData?.unitValue);
            formik.setFieldValue('unitPrice', lendingData?.unitPrice);
        }
    }, [lendingData]); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        categoryId: Yup.mixed().required('This field is required.'),
        productCategoryId: Yup.mixed().required('This field is required.'),
        ruleSetId: Yup.mixed().required('This field is required.'),
        facilityCode: Yup.mixed().required('This field is required.'),
        serviceChargesType: Yup.mixed().required('This field is required.'),

        serviceChargesValue: Yup.string().when(['serviceChargesType'], {
            is: 'F',
            then: Yup.string()
                .required('This field is required')
                .matches(/^\d+$/, 'Please enter numeric data')
                .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                    if (!value) {
                        return false;
                    }
                    const numericValue = parseFloat(value);
                    return numericValue > 0;
                })
                .nullable(),
            otherwise: Yup.string()
                .required('This field is required')
                .when(['serviceChargesType'], {
                    is: 'P',
                    then: Yup.string()
                        .required('This field is required')
                        .matches(/^-?\d+(\.\d+)?$/, 'Please enter numeric data')
                        .test('within-range', 'Value must be greater than 0 and equal to or lesser than 100', (value) => {
                            const numericValue = parseFloat(value);
                            return numericValue > 0 && numericValue <= 100;
                        })
                })
                .nullable()
        }),

        collateralId: Yup.mixed().required('This field is required.')
        // maxAmount: Yup.mixed().required('This field is required.'),
        // minAmount: Yup.mixed().required('This field is required.'),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: '',
            description: '',
            categoryId: '',
            productCategoryId: '',
            ruleSetId: '',
            channelIds: [],
            segmentIds: [],
            maxAmount: '',
            minAmount: '',
            serviceChargesType: '',
            serviceChargesValue: '',
            facilityCode: '',
            collateralId: '',
            balance: '',
            unitValue: '',
            unitPrice: '',

            salaryCycleDate: new Date(2024, 0, 1),
            salaryDisbursmentDate: false,
            dbrCaping: ''
        },

        validate: (data) => {
            let errors = {};
            if (!data?.name) {
                errors.name = 'This field is required';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.name)) {
                errors.name = 'Leading and trailing space is not allowed';
            } else if (!/^[a-zA-Z](?:[a-zA-Z0-9\s]*-?[a-zA-Z0-9\s]*)?$/.test(data?.name)) {
                errors.name = 'Please enter alpha numeric data';
            } else if (data?.name?.length < 5) {
                errors.name = 'Miniumum length must be at least 5 characters';
            }
            if (data?.channelIds.length === 0) {
                errors.channelIds = 'This field is required';
            }
            if (data?.segmentIds.length === 0) {
                errors.segmentIds = 'This field is required';
            }
            if (data?.maxAmount === '' || data?.maxAmount === null) {
                errors.maxAmount = 'This field is required.';
            }
            if (data?.minAmount === '' || data?.minAmount === null) {
                errors.minAmount = 'This field is required.';
            } else if (parseInt(data?.minAmount) > parseInt(data?.maxAmount)) {
                errors.minAmount = 'Please enter min amount less than max amount';
            } else if (parseInt(data?.minAmount) < 1) {
                errors.minAmount = 'Please enter a valid min amount greater than 1';
            }

            if (data?.description && !/^[a-zA-Z0-9\s]+$/.test(data.description)) {
                errors.description = 'Only alphanumeric characters and spaces are allowed';
            }

            if (data?.collateralId === 1 || data?.collateralId === '1') {
                if (data?.unitPrice === '' || data?.unitPrice === null) {
                    errors.unitPrice = 'This field is required';
                } else if (data?.unitPrice === '0') {
                    errors.unitPrice = 'Value must be greater than 0';
                }

                if (data?.unitValue === '' || data?.unitValue === null) {
                    errors.unitValue = 'This field is required';
                } else if (data?.unitValue === '0') {
                    errors.unitValue = 'Value must be greater than 0';
                }
            }
            if (data?.collateralId === '3') {
                if (data?.balance === '' || data?.balance === null) {
                    errors.balance = 'This field is required';
                } else if (data?.balance === '0') {
                    errors.balance = 'Value must be greater than 0';
                }
            }

            if (data?.salaryDisbursmentDate === true) {
                if (data?.salaryCycleDate === '' || data?.salaryCycleDate === null) {
                    errors.salaryCycleDate = 'This field is required';
                }
            }

            if (data?.dbrCaping === '' || data?.dbrCaping === null) {
                errors.dbrCaping = 'This field is required';
            } else {
                const dbrCapingValue = parseFloat(data?.dbrCaping);

                if (isNaN(dbrCapingValue) || dbrCapingValue < 1 || dbrCapingValue > 100) {
                    errors.dbrCaping = 'Please enter a valid percentage between 1 and 100';
                }
            }

            return errors;
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['currentStep'] = '1';
            data['balance'] = parseInt(data?.balance);

            formik.setFieldValue('salaryDisbursementDate', data?.salaryDisbursmentDate);

            if (data['salaryDisbursmentDate'] === false) {
                data['salaryCycleDate'] = '';
            } else {
                data['salaryCycleDate'] = data?.salaryCycleDate.getDate();
            }

            delete data['salaryDisbursmentDate'];

            const arrayOfChannelIds = formik?.values?.channelIds.map((item) => parseInt(item?.lovId));
            data['channelIds'] = arrayOfChannelIds;

            const arrayOfSegmentsIds = formik?.values?.segmentIds.map((item) => parseInt(item?.lovId));
            data['segmentIds'] = arrayOfSegmentsIds;

            if (data['collateralId'] === '1') {
                delete data['balance'];
            }

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            if (parseInt(lendingData?.currentStep) >= 0) {
                // data['campaignId'] = lendingData?.campaignId;
                // const formData = new FormData();
                // formData.append("request", JSON.stringify(newData));

                // /lending/v1/lending/savelmsproduct

                // handleStepClick(activeIndex + 1);
                data['lmsProductId'] = lendingData?.lmsProductId;
                data['edit'] = true;
                delete newData?.data?.payLoad?.salaryDisbursementDate;
                const formData = new FormData();
                const blob = new Blob([JSON.stringify(newData)], { type: 'application/json' });
                formData.append('request', blob);
                // formData.append("request", JSON.stringify(newData));
                const res = await dispatch(handlePostRequest(formData, '/lending/v1/lending/savelmsproduct', true, false));
                if (res?.responseCode === process.env.REACT_APP_LENDING_SUCCESS_CODE) {
                    formik.resetForm();
                    getLendingId(res?.payLoad?.lmsProductId);
                    handleStepClick(activeIndex + 1);
                }
            } else {
                delete newData?.data?.payLoad?.salaryDisbursementDate;
                const formData = new FormData();
                // formData.append("request", JSON.stringify(newData));
                const blob = new Blob([JSON.stringify(newData)], { type: 'application/json' });
                formData.append('request', blob);

                const res = await dispatch(handlePostRequest(formData, '/lending/v1/lending/savelmsproduct', true, false));
                if (res?.responseCode === process.env.REACT_APP_LENDING_SUCCESS_CODE) {
                    formik.resetForm();
                    getLendingId(res?.payLoad?.lmsProductId);
                    handleStepClick(activeIndex + 1);
                } else {
                    if (formik?.values.salaryCycleDate === '') {
                        data['salaryDisbursmentDate'] = false;
                        formik.setFieldValue('salaryCycleDate', new Date(2024, 0, 1));
                    }
                    const selectedProductChannels = formik?.values?.channelIds?.map((item) => {
                        return { lovId: item?.toString() };
                    });

                    const selectedSegments = formik?.values?.segmentIds?.map((item) => {
                        return { lovId: item?.toString() };
                    });

                    formik.setFieldValue('channelIds', selectedProductChannels);
                    formik.setFieldValue('segmentIds', selectedSegments);
                }
            }
            setloading(false);
            setloadingIcon('');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const serviceChargesTypeLov = [
        { name: 'AMOUNT', value: 'F' },
        { name: 'PERCENTAGE', value: 'P' }
    ];

    const handleCheckboxChange = (e) => {
        const isChecked = e.checked;

        formik.setFieldValue('salaryDisbursmentDate', isChecked);

        if (isChecked) {
            //   formik.setFieldValue('salaryCycleDate', new Date());
        } else {
            formik.setFieldValue('salaryCycleDate', new Date(2024, 0, 1)); // Set to null or any other default value you prefer
        }
    };

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>PRODUCT/ADD NEW</h1>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                    Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="name"
                                    placeholder="Enter Name"
                                    name="name"
                                    maxLength={20}
                                    value={formik?.values?.name || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('name')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-8 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') }, 'Label__Text')}>
                                    Description <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    id="description"
                                    maxLength={200}
                                    placeholder="Enter Description"
                                    name="description"
                                    value={formik?.values?.description || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('description') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('description')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="categoryId" className={classNames({ 'p-error': isFormFieldValid('categoryId') }, 'Label__Text')}>
                                    Category<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="categoryId"
                                    placeholder="Select Category"
                                    options={categoryLov}
                                    optionLabel="name"
                                    name="categoryId"
                                    filter
                                    optionValue="lovId"
                                    value={formik?.values?.categoryId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('categoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('categoryId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="productCategoryId" className={classNames({ 'p-error': isFormFieldValid('productCategoryId') }, 'Label__Text')}>
                                    Product Category<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="productCategoryId"
                                    placeholder="Select Product Category"
                                    options={lmsProductCategoryLov}
                                    optionLabel="name"
                                    name="productCategoryId"
                                    optionValue="lovId"
                                    filter
                                    value={formik?.values?.productCategoryId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productCategoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('productCategoryId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="ruleSetId" className={classNames({ 'p-error': isFormFieldValid('ruleSetId') }, 'Label__Text')}>
                                    Rule Set<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="ruleSetId"
                                    placeholder="Select Rule Set"
                                    options={ruleSetLov}
                                    optionLabel="name"
                                    name="ruleSetId"
                                    filter
                                    optionValue="lovId"
                                    value={formik?.values?.ruleSetId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('ruleSetId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('ruleSetId')}
                            </div>
                        </div>
                        <div style={{ backgroundColor: '#edf0f5', padding: '2rem' }} className=" col-12 card ">
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="channelIds" className={classNames({ 'p-error': isFormFieldValid('channelIds') }, 'Label__Text')}>
                                            Channel Based<span className="Label__Required">*</span>
                                        </label>
                                        <MultiSelect
                                            id="channelIds"
                                            placeholder="Select Channels"
                                            options={channelLov}
                                            optionLabel="name"
                                            name="channelIds"
                                            filter
                                            optionValue="lovId"
                                            // value={formik?.values?.channelIds || ''}
                                            // onChange={formik.handleChange}
                                            value={formik?.values?.channelIds.map((trans) => trans.lovId || '')}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    'channelIds',
                                                    e.value.map((lovId) => ({
                                                        lovId
                                                    }))
                                                );
                                            }}
                                            className={classNames({ 'p-invalid': isFormFieldValid('channelIds') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('channelIds')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="segmentIds" className={classNames({ 'p-error': isFormFieldValid('segmentIds') }, 'Label__Text')}>
                                            Segment Based<span className="Label__Required">*</span>
                                        </label>
                                        <MultiSelect
                                            id="segmentIds"
                                            placeholder="Select Segments"
                                            options={segmentLov}
                                            optionLabel="name"
                                            name="segmentIds"
                                            filter
                                            optionValue="lovId"
                                            // value={formik?.values?.segmentIds || ''}
                                            // onChange={formik.handleChange}
                                            value={formik?.values?.segmentIds.map((trans) => trans.lovId || '')}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    'segmentIds',
                                                    e.value.map((lovId) => ({
                                                        lovId
                                                    }))
                                                );
                                            }}
                                            className={classNames({ 'p-invalid': isFormFieldValid('segmentIds') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('segmentIds')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="maxAmount" className={classNames({ 'p-error': isFormFieldValid('maxAmount') }, 'Label__Text')}>
                                            Max Amount<span className="Label__Required">*</span>
                                        </label>
                                        <InputNumber
                                            inputId="maxAmount"
                                            placeholder="Enter Max Amount"
                                            name="maxAmount"
                                            value={formik?.values?.maxAmount || ''}
                                            onValueChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('maxAmount') }, 'Input__Number')}
                                            maxLength={9}
                                        />
                                        {getFormErrorMessage('maxAmount')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="minAmount" className={classNames({ 'p-error': isFormFieldValid('minAmount') }, 'Label__Text')}>
                                            Min Amount<span className="Label__Required">*</span>
                                        </label>
                                        <InputNumber
                                            inputId="minAmount"
                                            placeholder="Enter Min Amount"
                                            name="minAmount"
                                            maxLength={9}
                                            value={formik?.values?.minAmount || ''}
                                            onValueChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('minAmount') }, 'Input__Number')}
                                        />

                                        {getFormErrorMessage('minAmount')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="serviceChargesType" className={classNames({ 'p-error': isFormFieldValid('serviceChargesType') }, 'Label__Text')}>
                                            Service Charges<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="serviceChargesType"
                                            placeholder="Select Service Charges Type"
                                            options={serviceChargesTypeLov}
                                            optionLabel="name"
                                            name="serviceChargesType"
                                            optionValue="value"
                                            value={formik.values.serviceChargesType}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('serviceChargesType') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('serviceChargesType')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="serviceChargesValue" className={classNames({ 'p-error': isFormFieldValid('serviceChargesValue') }, 'Label__Text')}>
                                            Service Charges Value<span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="serviceChargesValue"
                                            placeholder="Enter Service Charges Value"
                                            name="serviceChargesValue"
                                            maxLength={8}
                                            keyfilter={formik.values.serviceChargesType === 'P' ? 'float' : 'int'}
                                            value={formik?.values?.serviceChargesValue || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('serviceChargesValue') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('serviceChargesValue')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field flex">
                                <div className="field-checkbox">
                                    <Checkbox inputId="salaryDisbursmentDate" name="salaryDisbursmentDate" value={formik.values.salaryDisbursmentDate} checked={formik.values.salaryDisbursmentDate} onChange={handleCheckboxChange} />
                                </div>
                                <label htmlFor="salaryDisbursmentDate" className={classNames({ 'p-error': isFormFieldValid('salaryDisbursmentDate') }, 'Label__Text')}>
                                    Salary Disbursement Date
                                </label>
                                {getFormErrorMessage('salaryDisbursmentDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>
                        <div className="p-fluid p-formgrid grid mb-5 col-12">
                            {formik?.values?.salaryDisbursmentDate === true && (
                                <div style={{ backgroundColor: '#edf0f5', padding: '1rem' }} className=" col-12 card ">
                                    <div className="p-field col-12 md:col-4 pt-3">
                                        <div className="p-field lendingProductCalendar">
                                            <label htmlFor="salaryCycleDate" className={classNames({ 'p-error': isFormFieldValid('salaryCycleDate') }, 'Label__Text')}>
                                                Date
                                            </label>
                                            {/* <Dropdown
                                                id="salaryCycleDate"
                                                placeholder="Select Disbursment Date"
                                                options={disbursmentDate}
                                                optionLabel="name"
                                                name="salaryCycleDate"
                                                optionValue="lovId"
                                                value={formik.values.salaryCycleDate}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('salaryCycleDate') }, 'Dropdown__Round')}
                                            /> */}

                                            <Calendar
                                                id={'salaryCycleDate'}
                                                name={'salaryCycleDate'}
                                                minDate={new Date(2024, 0, 1)} // January 1, 2024
                                                maxDate={new Date(2024, 0, 28)} // January 28, 2024
                                                readOnlyInput
                                                value={formik?.values?.salaryCycleDate}
                                                // view="day"
                                                dateFormat="dd"
                                                onChange={formik.handleChange}
                                                // onChange={(e) => {
                                                //     setDate(e.value);
                                                // }}
                                                panelClassName={'lendingProductCalendarPanel'}
                                                className={classNames({ 'p-invalid': isFormFieldValid('salaryCycleDate') }, 'Dropdown__Round')}
                                            />

                                            {getFormErrorMessage('salaryCycleDate')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3"></div>
                                    <div className="p-field col-12 md:col-4 pt-3"></div>
                                </div>
                            )}
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="facilityCode" className={classNames({ 'p-error': isFormFieldValid('facilityCode') }, 'Label__Text')}>
                                    Type of Facility<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="facilityCode"
                                    placeholder="Select Facility"
                                    options={lmsFacilityTypeLov}
                                    optionLabel="name"
                                    name="facilityCode"
                                    optionValue="code"
                                    value={formik.values.facilityCode}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('facilityCode') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('facilityCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dbrCaping" className={classNames({ 'p-error': isFormFieldValid('dbrCaping') }, 'Label__Text')}>
                                    DBR Percentage<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="dbrCaping"
                                    placeholder="Enter DBR Percentage"
                                    name="dbrCaping"
                                    keyfilter="float"
                                    maxLength={5}
                                    value={formik?.values?.dbrCaping || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dbrCaping') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dbrCaping')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="collateralId" className={classNames({ 'p-error': isFormFieldValid('collateralId') }, 'Label__Text')}>
                                    Security/Collateral<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="collateralId"
                                    placeholder="Select Collateral"
                                    options={lmsCollateralLov}
                                    optionLabel="name"
                                    name="collateralId"
                                    optionValue="lovId"
                                    value={formik?.values?.collateralId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('collateralId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('collateralId')}
                            </div>
                        </div>

                        {formik.values.collateralId === '1' && (
                            <div style={{ backgroundColor: '#edf0f5', padding: '2rem' }} className=" col-12 card">
                                <div className="p-fluid p-formgrid grid mb-5">
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="unitValue" className={classNames({ 'p-error': isFormFieldValid('unitValue') }, 'Label__Text')}>
                                                Unit Value
                                            </label>
                                            <InputText
                                                id="unitValue"
                                                placeholder="Enter Unit Value"
                                                name="unitValue"
                                                maxLength={8}
                                                keyfilter="int"
                                                value={formik?.values?.unitValue || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('unitValue') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('unitValue')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="unitPrice" className={classNames({ 'p-error': isFormFieldValid('unitPrice') }, 'Label__Text')}>
                                                Unit Price
                                            </label>
                                            <InputText
                                                id="unitPrice"
                                                placeholder="Enter Unit Price"
                                                name="unitPrice"
                                                maxLength={8}
                                                keyfilter="int"
                                                value={formik?.values?.unitPrice || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('unitPrice') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('unitPrice')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {formik.values.collateralId === '3' && (
                            <>
                                <div style={{ backgroundColor: '#edf0f5', padding: '2rem' }} className=" col-12 card">
                                    <div className="p-fluid p-formgrid grid mb-5">
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="balance" className={classNames({ 'p-error': isFormFieldValid('balance') }, 'Label__Text')}>
                                                    Balance
                                                </label>
                                                <InputText
                                                    id="balance"
                                                    placeholder="Enter Balance"
                                                    name="balance"
                                                    maxLength={8}
                                                    keyfilter="int"
                                                    value={formik?.values?.balance || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('balance') }, 'Input__Round')}
                                                />

                                                {getFormErrorMessage('balance')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="Down__Btn">
                        <Button loadingIcon={loadingIcon || ''} label={lendingData?.currentStep >= 0 ? 'Edit' : 'Save'} className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default AddProduct;

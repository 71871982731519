import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import { FilterMatchMode } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import AddEditValidators from './AddEditValidators';
import ViewValidators from './ViewValidators';

const Validators = () => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [validatorsData, setValidatorsData] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [validatorTypeLov, setValidatorTypeLov] = useState([]);
    const [validatorRowData, setValidatorRowData] = useState();
    const [validatorViewRowData, setValidatorViewRowData] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [showViewDialog, setShowViewDialog] = useState(false);
    const [editable, setEditable] = useState(false);
    const [userLov, setUserLov] = useState([]);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        kycSetName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const dataTableRef = useRef(null);

    const dispatch = useDispatch();

    const currentDate = new Date()?.toISOString()?.split('T')[0];

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(resp?.payLoad);
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(
            resp?.payLoad?.sort((a, b) => {
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                return 0;
            })
        );
    };

    const getValidatorLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getvalidatortypes/LKP_VALIDATOR_TYPE:*');
        setValidatorTypeLov(resp?.payLoad);
    };

    useEffect(() => {
        getStatusLov();
        getUserLov();
        getValidatorLov();
    }, []);

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            validatorName: '',
            validatorType: '',
            dateFrom: '',
            dateTo: '',
            status: '2',
            createdBy: '',
            updatedBy: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            dataTableRef.current.reset();

            const res = await dispatch(handlePostRequest(newData, '/account/v1/validators/getallvalidators', true, true));
            if (res?.responseCode === '010000') {
                setValidatorsData(res?.payLoad);
            } else {
                setValidatorsData([]);
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ''));
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">Validators List</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const header = renderHeader();

    const onHideValidatorDialog = () => {
        setShowDialog(false);
    };

    const EditValidator = (rowData) => {
        setEditable(true);
        setShowDialog(true);
        setValidatorRowData(rowData);
    };

    const ViewValidator = (rowData) => {
        setValidatorViewRowData(rowData);
        setShowViewDialog(true);
    };

    const handleSwitchChange = async (id, isActiveData, rowData) => {
        const updatedData = validatorsData?.map((item) => {
            if (item.validatorId === id) {
                const isActive = item.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });

        setValidatorsData(updatedData);

        let isactive = '';
        if (isActiveData === 'Y') {
            isactive = 'N';
        } else if (isActiveData === 'N') {
            isactive = 'Y';
        }

        const previousValue = isActiveData;

        const data = {
            validatorName: rowData?.validatorName,
            argumentName: rowData?.argumentName,
            argumentValue: rowData?.argumentValue,
            validatorTypeId: rowData?.lkpValidatorType?.validatorTypeId.toString(),

            isActive: isactive,
            validatorId: id
        };

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/account/v1/validator/inactivevalidator', true, false));
        if (res?.responseCode !== '010000') {
            const revertedData = validatorsData?.map((item) => {
                if (item?.validatorId === id) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setValidatorsData(revertedData);
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <InputSwitch
                        checked={rowData.isActive === 'Y'}
                        // disabled={true}
                        // this code is disabled for now after discussing with Solution-architecture team
                        disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                        onChange={() => handleSwitchChange(rowData?.validatorId, rowData?.isActive, rowData)}
                    />
                    <Button
                        tooltip="Edit"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: 'top' }}
                        disabled={true}
                        // this code is disabled for now after discussing with Solution-architecture team
                        // disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) || (rowData?.lkpStatus?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                        onClick={() => EditValidator(rowData)}
                        className="p-button-rounded p-button-primary"
                    />
                    <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => ViewValidator(rowData)} className="p-button-rounded" style={{ backgroundColor: '#F2AC57', border: 'none' }} />
                </div>
            </>
        );
    };

    const handledClicked = () => {
        setShowDialog(true);
    };

    const onHideViewValidatorDialog = () => {
        setShowViewDialog(false);
    };

    const resetHandle = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.lkpStatus?.statusDescr === 'Approved' ? '#14A38B' : rowData?.lkpStatus?.statusDescr === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.lkpStatus?.statusDescr}</b>
            </p>
        );
    };

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };
    return (
        <>
            <Dialog visible={showDialog} blockScroll resizable={false} draggable={false} header={editable ? 'Update Validator' : 'Add New Validator'} style={{ width: '500px' }} onHide={() => setShowDialog(false)}>
                <AddEditValidators onHideValidatorDialog={onHideValidatorDialog} editable={editable} setEditable={setEditable} validatorRowData={validatorRowData} />
            </Dialog>
            <Dialog visible={showViewDialog} blockScroll resizable={false} draggable={false} header={'View Validator'} style={{ width: '500px' }} onHide={() => setShowViewDialog(false)}>
                <ViewValidators onHideViewValidatorDialog={onHideViewValidatorDialog} validatorViewRowData={validatorViewRowData} />
            </Dialog>

            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={handledClicked} className="Btn__Add__" icon="pi pi-plus" iconPos="left" style={{ width: 'auto' }} />
                </div>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="validatorName" className={classNames({ 'p-error': isFormFieldValid('validatorName') }, 'Label__Text')}>
                                    Validator Name
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="validatorName"
                                    placeholder="Enter Validator Name"
                                    name="validatorName"
                                    value={formik?.values?.validatorName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('validatorName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('validatorName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="validatorType" className={classNames({ 'p-error': isFormFieldValid('validatorType') }, 'Label__Text')}>
                                    Validator Type
                                </label>
                                <Dropdown
                                    id="validatorType"
                                    placeholder="Select Validator Type"
                                    options={validatorTypeLov}
                                    optionLabel="name"
                                    name="validatorType"
                                    optionValue="lovId"
                                    value={formik.values.validatorType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('validatorType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('validatorType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText
                                    id="dateFrom"
                                    placeholder=""
                                    name="dateFrom"
                                    type="date"
                                    max={currentDate}
                                    value={formik?.values?.dateFrom?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText
                                    id="dateTo"
                                    placeholder=""
                                    name="dateTo"
                                    type="date"
                                    max={currentDate}
                                    value={formik?.values?.dateTo?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('status') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="status"
                                    placeholder="Select status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="status"
                                    optionValue="lovId"
                                    value={formik.values.status || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('status') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Updated By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div>
                        <div className="Usergroup__SearchBtn p-field col-12 md:col-4">
                            <Button disabled={loading} label="Search" icon={loadingIcon} iconPos="left" className="Btn__Dark__Search" />
                            <Button disabled={loading} label="Reset" onClick={(e) => resetHandle(e)} className="Btn__Transparent__Reset" />
                        </div>
                    </div>
                </form>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            filters={filters}
                            globalFilterFields={['validatorName', 'lkpValidatorType.validatorTypeDescr', 'createdate', 'createdBy', 'updatedBy', 'lkpStatus.statusDescr']}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={validatorsData}
                        >
                            <Column field="validatorName" header="Validator Name" />
                            <Column field="lkpValidatorType.validatorTypeDescr" header="Validator Type" />
                            <Column header="Created Date & Time" body={createdDateAndTimeBodyTemplate} />
                            <Column field="createdBy" header="Created By" />
                            <Column field="updatedBy" header="Updated By" />
                            <Column header="Status" body={statusBodyTemplate} />
                            <Column header="Actions" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Validators;

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { FilterMatchMode } from 'primereact/api';
import ActiveInActiveDialog from '../../../../components/ActiveInActiveDialog/ActiveInActiveDialog';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';

function T24accountlink() {
    const [showModal, setShowModal] = useState(false);
    const [switchData, setSwitchData] = useState([]);
    const [accountTypeValues, setAccountTypeValues] = useState([]);
    const [accountData, setAccountData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [linkStatusOptions, setLinkStatusOptions] = useState([{ name: 'Linked' }, { name: 'Delinked' }]);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        accountId: { value: '', matchMode: FilterMatchMode.CONTAINS },
        accountType: { value: '', matchMode: FilterMatchMode.CONTAINS },
        firstName: { value: '', matchMode: FilterMatchMode.CONTAINS },
        lastName: { value: '', matchMode: FilterMatchMode.CONTAINS },
        cnic: { value: '', matchMode: FilterMatchMode.CONTAINS },
        mobileNo: { value: '', matchMode: FilterMatchMode.CONTAINS },
        coreAccountNo: { value: '', matchMode: FilterMatchMode.CONTAINS },
        createdUserName: { value: '', matchMode: FilterMatchMode.CONTAINS },
        updatedUserName: { value: '', matchMode: FilterMatchMode.CONTAINS },
        linkStatus: { value: '', matchMode: FilterMatchMode.CONTAINS },
        statusName: { value: '', matchMode: FilterMatchMode.CONTAINS },
        createdDate: { value: '', matchMode: FilterMatchMode.CONTAINS }
    });

    const dataTableRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        accountId: Yup.number().notRequired('This Field is notRequired.'),
        cnic: Yup.number().notRequired('This Field is notRequired.'),
        mobileNo: Yup.number().notRequired('This Field is notRequired.'),
        coreAccountNo: Yup.string()
            .matches(/^[A-Za-z]{2}[A-Za-z0-9]{20}[0-9]{2}$/, 'Invalid IBAN')
            .notRequired('This Field is notRequired.'),
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            accountId: '',
            mobileNo: '',
            accountClassificationId: '',
            cnic: '',
            coreAccountNo: '',
            createdBy: '',
            updatedBy: '',
            dateFrom: '',
            dateTo: '',
            statusId: '2',
            linkStatus: ''
        },

        validate: (data) => {
            let errors = {};
            if (data.dateTo && !data.dateFrom) {
                errors.dateFrom = 'Date From is required when Date To is provided';
            }

            if (data.dateFrom && data.dateTo && data.dateFrom > data.dateTo) {
                errors.dateTo = 'Date To must be greater than or equal to Date From';
            }
            return errors;
        },

        onSubmit: async (data) => {
            setloadingIcon('pi pi-spin pi-spinner');
            setAccountData([]);
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            dataTableRef.current.reset();
            const res = await dispatch(handlePostRequest(newData, '/t24connectivity/v1/accountlinking/getcorelinkedaccounts', true, true));
            if (res?.payLoad) {
                const updatedPayload = res.payLoad.map((payloadItem) => {
                    const matchingItem = accountTypeValues.find((item) => item?.lovId === payloadItem.accountClassificationId);
                    if (matchingItem) {
                        payloadItem.accountType = matchingItem.name;
                    }

                    const createdUserName = userLov.find((item) => item?.lovId === payloadItem.createdBy)?.name;
                    const updatedUserName = userLov.find((item) => item?.lovId === payloadItem.updatedBy)?.name;
                    payloadItem.createdUserName = createdUserName;
                    payloadItem.updatedUserName = updatedUserName;

                    const statusName = statusLovData.find((item) => item?.lovId === payloadItem.statusId)?.name;
                    payloadItem.statusName = statusName;

                    const fullNameParts = payloadItem.fullName.split(' ');
                    if (fullNameParts.length > 1) {
                        payloadItem.firstName = fullNameParts.slice(0, -1).join(' ');
                        payloadItem.lastName = fullNameParts[fullNameParts.length - 1];
                    } else {
                        payloadItem.firstName = fullNameParts[0];
                    }

                    return payloadItem;
                });
                setAccountData(updatedPayload);
                setloadingIcon('pi pi-search');
            }
        }
    });

    const getaccountTypeValues = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getAccountClassification/LKP_ACCOUNT_CLASSIFICATION:*');
        const updatedRes = res?.payLoad?.filter((item) => item?.code !== 'HND');
        setAccountTypeValues(updatedRes);
    };

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(res?.payLoad);
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };

    useEffect(() => {
        getaccountTypeValues();
        getStatusListData();
        getUserLov();
    }, []);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">T24 Account Linking</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const header = () => renderHeader();

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const handleSwitchChange = async () => {
        const updatedData = accountData?.map((item) => {
            if (item?.t24AccountLinkId === switchData?.t24AccountLinkId) {
                const linkStatus = item?.linkStatus === 'Linked' ? 'Delinked' : 'Linked';
                return { ...item, linkStatus };
            }
            return item;
        });
        setAccountData(updatedData);
        let linkstatusNew = '';
        if (switchData?.linkStatus === 'Linked') {
            linkstatusNew = 'Delinked';
        } else if (switchData?.linkStatus === 'Delinked') {
            linkstatusNew = 'Linked';
        }
        const previousValue = switchData?.linkStatus;

        const data = {
            t24AccountLinkId: switchData?.t24AccountLinkId,
            linkStatus: linkstatusNew,
            isActive: switchData?.isActive,
            accountId: switchData?.accountId,
            cnicHash: switchData?.cnicHash
        };

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newData, '/account/v1/customeraccounts/inactivecoreaccount', true, false));
        if (res?.responseCode == '010000') {
            const revertedData = accountData?.map((item) => {
                if (item.t24AccountLinkId == switchData?.t24AccountLinkId) {
                    const linkStatus = linkstatusNew;
                    return { ...item, linkStatus };
                }
                return item;
            });
            setAccountData(revertedData);
        } else {
            const revertedData = accountData?.map((item) => {
                if (item.t24AccountLinkId == switchData?.t24AccountLinkId) {
                    const linkStatus = previousValue;
                    return { ...item, linkStatus };
                }
                return item;
            });
            setAccountData(revertedData);
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <InputSwitch
                        tooltip={rowData.linkStatus === 'Linked' ? 'Delink Account' : 'Link Account'}
                        tooltipOptions={{ position: 'top' }}
                        checked={rowData.linkStatus === 'Linked'}
                        disabled={['Pending', 'Rejected'].includes(rowData?.statusName) ? true : false}
                        onChange={() => {
                            if (rowData?.linkStatus === 'Linked') {
                                setSwitchData(rowData);
                                setShowModal(true);
                            } else {
                                navigate(`/addt24accountlink`, { state: { getClassificationId: rowData.accountClassificationId, getMobileNo: rowData.mobileNo, getCnic: rowData.cnic, getCoreAccountNo: rowData.coreAccountNo } });
                            }
                        }}
                    />
                    <Button
                        tooltip="Edit Detail"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: 'top' }}
                        disabled={['Pending', 'Rejected'].includes(rowData?.statusName) || (rowData?.statusName === 'Approved' && rowData?.linkStatus === 'Delinked') ? true : false}
                        // onClick={() => navigate(`/editt24accountlink?t24AccountLinkId=${rowData?.t24AccountLinkId}`)}
                        onClick={() => {
                            navigate(`/editt24accountlink`, { state: { t24AccountLinkId: rowData.t24AccountLinkId } });
                        }}
                        className="p-button-rounded p-button-primary"
                    />
                    <Button
                        tooltip="View Detail"
                        tooltipOptions={{ position: 'top' }}
                        // disabled={['Pending', 'Rejected'].includes(rowData?.statusName) ? true : false}
                        icon="pi pi-eye"
                        // onClick={() => navigate(`/viewt24accountlink?t24AccountLinkId=${rowData?.t24AccountLinkId}&&accountId=${rowData?.accountId}`)}
                        onClick={() => {
                            navigate(`/viewt24accountlink`, { state: { t24AccountLinkId: rowData.t24AccountLinkId, accountId: rowData?.accountId } });
                        }}
                        className="p-button-rounded"
                        style={{ backgroundColor: '#F2AC57', border: 'none' }}
                    />
                </div>
            </>
        );
    };

    const linkStatusBodyTemplate = (rowData) => {
        const statusColor = rowData?.linkStatus === 'Linked' ? '#14A38B' : rowData?.linkStatus === 'Delinked' ? '#FF7171' : '#F2AC57';
        return (
            <p
                style={{
                    color: statusColor,
                    textAlign: 'center'
                }}
            >
                <b>{rowData?.linkStatus}</b>
            </p>
        );
    };
    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.statusName === 'Approved' ? '#14A38B' : rowData?.statusName === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.statusName}</b>
            </p>
        );
    };

    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdDate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const handleShowModal = () => {
        setShowModal(false);
    };

    const props = {
        showModal,
        handleShowModal,
        handleSwitchChange,
        isT24: true
    };

    return (
        <>
            <ActiveInActiveDialog {...props} />
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="New Link Account" onClick={() => navigate('/addt24accountlink')} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountId" className={classNames({ 'p-error': isFormFieldValid('accountId') }, 'Label__Text')}>
                                    Account ID
                                </label>
                                <InputText
                                    maxLength={18}
                                    keyfilter="int"
                                    placeholder="Enter Account ID"
                                    id="accountId"
                                    name="accountId"
                                    value={formik?.values?.accountId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountId') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('accountId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="cnic" className={classNames({ 'p-error': isFormFieldValid('cnic') }, 'Label__Text')}>
                                    CNIC
                                </label>
                                <InputText
                                    maxLength={13}
                                    keyfilter="int"
                                    placeholder="Enter CNIC"
                                    id="cnic"
                                    name="cnic"
                                    value={formik?.values?.cnic || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cnic') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('cnic')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="mobileNo" className={classNames({ 'p-error': isFormFieldValid('mobileNo') }, 'Label__Text')}>
                                    Mobile Number
                                </label>
                                <InputText
                                    maxLength={11}
                                    keyfilter="int"
                                    placeholder="Enter Mobile Number"
                                    id="mobileNo"
                                    name="mobileNo"
                                    value={formik?.values?.mobileNo || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('mobileNo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('mobileNo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                    Account Type
                                </label>
                                <Dropdown
                                    id="accountClassificationId"
                                    placeholder="Select Account Type"
                                    options={accountTypeValues}
                                    optionLabel="name"
                                    name="accountClassificationId"
                                    optionValue="lovId"
                                    value={formik.values.accountClassificationId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountClassificationId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('accountClassificationId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="coreAccountNo" className={classNames({ 'p-error': isFormFieldValid('coreAccountNo') }, 'Label__Text')}>
                                    Core Account IBAN
                                </label>
                                <InputText
                                    maxLength={24}
                                    placeholder="Enter Core Account IBAN"
                                    id="coreAccountNo"
                                    name="coreAccountNo"
                                    value={formik?.values?.coreAccountNo || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('coreAccountNo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('coreAccountNo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText
                                    id="dateFrom"
                                    type="date"
                                    max={previousDate}
                                    name="dateFrom"
                                    value={formik?.values?.dateFrom?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText
                                    id="dateTo"
                                    type="date"
                                    max={previousDate}
                                    name="dateTo"
                                    value={formik?.values?.dateTo?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Name"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Name"
                                    options={userLov}
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="linkStatus" className={classNames({ 'p-error': isFormFieldValid('linkStatus') }, 'Label__Text')}>
                                    Link Status
                                </label>
                                <Dropdown
                                    id="linkStatus"
                                    placeholder="Select Link Status"
                                    options={linkStatusOptions}
                                    optionLabel="name"
                                    name="linkStatus"
                                    optionValue="name"
                                    value={formik.values.linkStatus || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('linkStatus') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('linkStatus')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="statusId" className={classNames({ 'p-error': isFormFieldValid('statusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select Status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label="Search" icon={loadingIcon || ''} iconPos="left" className="Btn__Dark" />
                        <Button type="button" label="Reset" iconPos="right" className="Btn__Transparent" onClick={(e) => handleReset(e)} />
                    </div>
                </form>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            filters={filters}
                            globalFilterFields={['accountId', 'accountType', 'firstName', 'lastName', 'cnic', 'mobileNo', 'coreAccountNo', 'createdUserName', 'updatedUserName', 'linkStatus', 'statusName', 'createdDate']}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={accountData}
                        >
                            <Column field="accountId" header="Account ID" />
                            <Column field="accountType" header="Account Type" />
                            <Column field="firstName" header="First Name" />
                            <Column field="lastName" header="Last Name" />
                            <Column field="cnic" header="CNIC" />
                            <Column field="mobileNo" header="Mobile Number" />
                            <Column field="coreAccountNo" header="Core Account IBAN" />
                            <Column field="createdUserName" header="Created By" />
                            <Column field="updatedUserName" header="Updated By" />
                            <Column header="Created Date & Time" body={createdDateAndTimeBodyTemplate} />
                            <Column field="linkStatus" header="Link Status" body={linkStatusBodyTemplate} />
                            <Column field="statusName" header="Status" body={statusBodyTemplate} />
                            <Column header="Action" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
export default T24accountlink;

import React from 'react';
import CheckerApis from '../Components/CheckerApis';

function CheckerViewCommissionHierarchy() {
    return (
        <React.Fragment>
            <CheckerApis type="checkerView" />
        </React.Fragment>
    );
}
export default CheckerViewCommissionHierarchy;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { InputTextarea } from 'primereact/inputtextarea';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function EditSalesRegionChecker() {
    const [loading, setloading] = useState(false);
    const [statusLovData, setStatusLovData] = useState([]);
    const [districtLov, setDistrictLov] = useState([]);
    const [dataFromApi, setDataFromApi] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const validationSchema = Yup.object().shape({
        // regionName: Yup.string().required('This Field is required.'),
        // districtId: Yup.string().required('This Field is required.'),
        action: Yup.string().required('This Field is required.'),
        checkerComments: Yup.string().required('This Field is required.')
    });

    const formik = useFormik({
        validationSchema,
        initialValues: {
            regionName: '',
            districtId: '',
            isActive: '',
            action: '',
            checkerComments: ''
        },

        onSubmit: async (data) => {
            setloading(true);

            data['isActive'] = data?.isActive ? 'Y' : 'N';
            if (requestTypeDescr !== 'Enable/Disable Request') {
                delete data['isActive'];
            }
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        regionRequest: {
                            isActive: data['isActive'],
                            regionId: dataFromApi?.regionId,
                            regionName: formik.values.regionName,
                            districtId: parseInt(formik.values.districtId)
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: ''
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/account/v1/sales/salesRegionCheckerAction', true, false, 'requestchecker'));
            if (res?.responseCode === '030000') {
                formik.resetForm();
            }
            setloading(false);
        }
    });

    const getDatabyId = async () => {
        // getRoleWiseModule
        const resp = await handleGetRequest(`/account/v1/sales/getRegionById/${refTableId}`);
        const keyData = resp?.payLoad;
        setDataFromApi(keyData);
        formik.setFieldValue('regionName', keyData?.regionName);
        formik.setFieldValue('districtId', keyData?.districtId?.toString());
    };

    const getMcRequestDataById = async () => {
        const resp = await handleGetRequest(`/account/v1/sales/getRegionsUpdateCheckerById/${mcRequestId}`);
        const keyData = resp?.payLoad;
        setDataFromApi(keyData);
        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
        }
        formik.setFieldValue('regionName', keyData?.regionName);
        formik.setFieldValue('districtId', keyData?.districtId?.toString());
        formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
    };

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };

    const getDistrictData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getAllDistrict/LKP_DISTRICT:*');
        setDistrictLov(res?.payLoad);
    };

    useEffect(() => {
        getStatusLov();
        getDistrictData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>EDIT DETAIL</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="regionName" className={classNames({ 'p-error': isFormFieldValid('regionName') }, 'Label__Text')}>
                                    Region Name
                                </label>
                                <InputText
                                    id="regionName"
                                    name="regionName"
                                    placeholder="Enter Region Name"
                                    value={formik?.values?.regionName?.replace(/\s\s+/g, ' ')}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('regionName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('regionName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="districtId" className={classNames({ 'p-error': isFormFieldValid('districtId') }, 'Label__Text')}>
                                    District
                                </label>
                                <Dropdown
                                    id="districtId"
                                    placeholder="Select District"
                                    options={districtLov}
                                    optionLabel="name"
                                    name="districtId"
                                    optionValue="lovId"
                                    value={formik.values.districtId}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('districtId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('districtId')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    maxLength={500}
                                    rows={5}
                                    cols={30}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} label="Update" className="Btn__Dark" />
                        <Button type="button" onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default EditSalesRegionChecker;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';
import { handlePostRequest } from '../../../../services/PostTemplate';
import InputWithCommentAction from './InputWithCommentAction';
import { EditValidationSchemaBasic, EditValidationSchemaFreelance, EditValidationSchemaMinor, EditValidationSchemaSignature } from './EditValidationSchema';
import { EditInitialValues } from './EditInitialValues';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function DefaultComponent({ type, responseData, statusLovData, discrepantStatusList }) {
    const [loading, setloading] = useState(false);
    const [actionStatus, setActionStatus] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const IsUltraBasicAccount = responseData?.accountLevelName && responseData?.accountLevelName.toLowerCase().includes('ultra basic');
    const IsUltraFreelanceAccount = responseData?.accountLevelName && responseData?.accountLevelName.toLowerCase().includes('ultra freelance');
    const IsUltraSignatureAccount = responseData?.accountLevelName && responseData?.accountLevelName.toLowerCase().includes('ultra signature');
    const IsMinorAccount = responseData?.accountLevelName && responseData?.accountLevelName.toLowerCase().includes('minor');

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const checkerValidationSchema = Yup.object().shape({
        action: Yup.string().required('This field is required'),
        checkerComments: Yup.string().required('This field is required')
    });

    const formik = useFormik({
        validationSchema:
            type === 'edit' && IsUltraBasicAccount === true
                ? EditValidationSchemaBasic
                : type === 'edit' && IsUltraFreelanceAccount === true
                ? EditValidationSchemaFreelance
                : type === 'edit' && IsUltraSignatureAccount === true
                ? EditValidationSchemaSignature
                : type === 'edit' && IsMinorAccount === true
                ? EditValidationSchemaMinor
                : type === 'checkerEdit'
                ? checkerValidationSchema
                : null,
        initialValues: type === 'edit' || type === 'view' ? EditInitialValues : type === 'checkerEdit' || type === 'checkerView' ? { ...EditInitialValues, action: '', checkerComments: '' } : null,

        onSubmit: async (e) => {
            setloading(true);
            if (type === 'edit') {
                const userRecord = [
                    { fieldName: 'accountTitle', fieldValue: formik.values.accountTitle, status: formik.values.accountTitleStatus, comment: formik.values.accountTitleComment },
                    { fieldName: 'selfiePic', fieldValue: formik.values.selfiePic, status: formik.values.selfiePicStatus, comment: formik.values.selfiePicComment },
                    { fieldName: 'cnicFrontPic', fieldValue: formik.values.cnicFrontPic, status: formik.values.cnicFrontPicStatus, comment: formik.values.cnicFrontPicComment },
                    { fieldName: 'cnicBackPic', fieldValue: formik.values.cnicBackPic, status: formik.values.cnicBackPicStatus, comment: formik.values.cnicBackPicComment },
                    { fieldName: 'fatherName', fieldValue: formik.values.fatherName, status: formik.values.fatherNameStatus, comment: formik.values.fatherNameComment },
                    { fieldName: 'birthPlace', fieldValue: formik.values.birthPlace, status: formik.values.birthPlaceStatus, comment: formik.values.birthPlaceComment },
                    { fieldName: 'motherName', fieldValue: formik.values.motherName, status: formik.values.motherNameStatus, comment: formik.values.motherNameComment },
                    { fieldName: 'professionName', fieldValue: formik.values.professionName, status: formik.values.professionNameStatus, comment: formik.values.professionNameComment },
                    { fieldName: 'dualNational', fieldValue: formik.values.dualNational, status: formik.values.dualNationalStatus, comment: formik.values.dualNationalComment },
                    { fieldName: 'usBorn', fieldValue: formik.values.usBorn, status: formik.values.usBornStatus, comment: formik.values.usBornComment },
                    { fieldName: 'country', fieldValue: formik.values.country, status: formik.values.countryStatus, comment: formik.values.countryComment },
                    { fieldName: 'countryOfBirth', fieldValue: formik.values.countryOfBirth, status: formik.values.countryOfBirthStatus, comment: formik.values.countryOfBirthComment },
                    { fieldName: 'countryOfTaxResidence', fieldValue: formik.values.countryOfTaxResidence, status: formik.values.countryOfTaxResidenceStatus, comment: formik.values.countryOfTaxResidenceComment },
                    { fieldName: 'usGreenCardHolder', fieldValue: formik.values.usGreenCardHolder, status: formik.values.usGreenCardHolderStatus, comment: formik.values.usGreenCardHolderComment },
                    { fieldName: 'usTelePhoneNumber', fieldValue: formik.values.usTelePhoneNumber, status: formik.values.usTelePhoneNumberStatus, comment: formik.values.usTelePhoneNumberComment },
                    { fieldName: 'usSignAuth', fieldValue: formik.values.usSignAuth, status: formik.values.usSignAuthStatus, comment: formik.values.usSignAuthComment },
                    { fieldName: 'usOtherInfo', fieldValue: formik.values.usOtherInfo, status: formik.values.usOtherInfoStatus, comment: formik.values.usOtherInfoComment },
                    { fieldName: 'fedralTaxClassificationName', fieldValue: formik.values.fedralTaxClassificationName, status: formik.values.fedralTaxClassificationNameStatus, comment: formik.values.fedralTaxClassificationNameComment },
                    { fieldName: 'residenceAddress', fieldValue: formik.values.residenceAddress, status: formik.values.residenceAddressStatus, comment: formik.values.residenceAddressComment },
                    { fieldName: 'taxIdentificationNumber', fieldValue: formik.values.taxIdentificationNumber, status: formik.values.taxIdentificationNumberStatus, comment: formik.values.taxIdentificationNumberComment },
                    { fieldName: 'geoLocation', fieldValue: formik.values.geoLocation, status: formik.values.geoLocationStatus, comment: formik.values.geoLocationComment },
                    { fieldName: 'cnicExpiryDate', fieldValue: formik.values.cnicExpiryDate, status: formik.values.cnicExpiryDateStatus, comment: formik.values.cnicExpiryDateComment },
                    { fieldName: 'signaturePic', fieldValue: formik.values.signaturePic, status: formik.values.signaturePicStatus, comment: formik.values.signaturePicComment },
                    { fieldName: 'bvsVerified', fieldValue: formik.values.bvsVerified, status: formik.values.bvsVerifiedStatus, comment: formik.values.bvsVerifiedComment },
                    { fieldName: 'email', fieldValue: formik.values.email, status: formik.values.emailStatus, comment: formik.values.emailComment },
                    { fieldName: 'dateOfBirth', fieldValue: formik.values.dateOfBirth, status: formik.values.dateOfBirthStatus, comment: formik.values.dateOfBirthComment },
                    { fieldName: 'customerVideo', fieldValue: formik.values.customerVideo, status: formik.values.customerVideoStatus, comment: formik.values.customerVideoComment },
                    { fieldName: 'customerVideoKyc', fieldValue: formik.values.customerVideoKyc, status: formik.values.customerVideoKycStatus, comment: formik.values.customerVideoKycComment },
                    { fieldName: 'deviceId', fieldValue: formik.values.deviceId, status: formik.values.deviceIdStatus, comment: formik.values.deviceIdComment },
                    { fieldName: 'purposeOfAccount', fieldValue: formik.values.purposeOfAccount, status: formik.values.purposeOfAccountStatus, comment: formik.values.purposeOfAccountComment },
                    { fieldName: 'gender', fieldValue: formik.values.gender, status: formik.values.genderStatus, comment: formik.values.genderComment },
                    { fieldName: 'currencyName', fieldValue: formik.values.currencyName, status: formik.values.currencyNameStatus, comment: formik.values.currencyNameComment },
                    { fieldName: 'mailingAddress', fieldValue: formik.values.mailingAddress, status: formik.values.mailingAddressStatus, comment: formik.values.mailingAddressComment },
                    { fieldName: 'proofOfIncomePic', fieldValue: formik.values.proofOfIncomePic, status: formik.values.proofOfIncomePicStatus, comment: formik.values.proofOfIncomePicComment },
                    { fieldName: 'bFormPic', fieldValue: formik.values.bFormPic, status: formik.values.bFormPicStatus, comment: formik.values.bFormPicComment },
                    { fieldName: 'parentCnicFrontPic', fieldValue: formik.values.parentCnicFrontPic, status: formik.values.parentCnicFrontPicStatus, comment: formik.values.parentCnicFrontPicComment },
                    { fieldName: 'parentCnicBackPic', fieldValue: formik.values.parentCnicBackPic, status: formik.values.parentCnicBackPicStatus, comment: formik.values.parentCnicBackPicComment }
                ];

                const processedUserRecord = userRecord.filter((item) => {
                    if (item?.status !== '') {
                        return item;
                    }
                });

                const newData = {
                    data: {
                        security: null,
                        account: null,
                        channel: null,
                        terminal: null,
                        reterivalReferenceNumber: null,
                        payLoad: {
                            userRecord: processedUserRecord,
                            mobileNumber: responseData?.mobileNumber,
                            cnic: responseData?.cnic,
                            accountLevelName: responseData?.accountLevelName,
                            ultraCustomerId: responseData?.ultraCustomerId,
                            message: 'Static Text',
                            makerComment: formik.values.comments,
                            makerStatus: formik.values.status
                        },
                        additionalInformation: [
                            {
                                infoKey: '',
                                infoValue: ''
                            }
                        ],
                        checkSum: null
                    }
                };
                await dispatch(handlePostRequest(newData, '/account/v1/customeraccounts/updateaccountapprovalstatus', true, false, 'walletaccountapproval'));
            }
            if (type === 'checkerEdit') {
                const newData = {
                    data: {
                        security: null,
                        account: null,
                        channel: null,
                        terminal: null,
                        reterivalReferenceNumber: null,
                        payLoad: {
                            updateAccountApprovalRequest: {
                                userRecord: responseData?.getByMCRequestIdData?.userRecord,
                                mobileNumber: responseData?.getByMCRequestIdData?.mobileNumber,
                                cnic: responseData?.getByMCRequestIdData?.cnic,
                                accountLevelName: responseData?.getByMCRequestIdData?.accountLevelName,
                                accountClassificationName: responseData?.getByMCRequestIdData?.accountClassificationName,
                                ultraCustomerId: responseData?.getByMCRequestIdData?.ultraCustomerId,
                                message: formik.values.checkerComments,
                                makerStatus: responseData?.getByMCRequestIdData?.makerStatus,
                                makerComment: responseData?.getByMCRequestIdData?.makerComment
                            },
                            mcActionRequest: {
                                mcRequestId: responseData?.urlData?.mcRequestId,
                                mcPeindingRequestId: responseData?.urlData?.mcPeindingRequestId,
                                checkerComments: formik.values.checkerComments,
                                checkerId: 1,
                                action: formik.values.action,
                                updatedIndex: 1
                            }
                        },
                        additionalInformation: [
                            {
                                infoKey: '',
                                infoValue: ''
                            }
                        ],
                        checkSum: null
                    }
                };
                await dispatch(handlePostRequest(newData, '/account/v1/customeraccounts/ultracheckeraction', true, false, 'requestchecker'));
            }
            setloading(false);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        if ((type === 'checkerEdit' || type === 'checkerView') && responseData) {
            // Full Name
            formik.setFieldValue('accountTitle', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'accountTitle')?.fieldValue);
            formik.setFieldValue('accountTitleComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'accountTitle')?.comment);
            formik.setFieldValue('accountTitleStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'accountTitle')?.status);

            // Selfie
            formik.setFieldValue('selfiePic', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'selfiePic')?.fieldValue);
            formik.setFieldValue('selfiePicComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'selfiePic')?.comment);
            formik.setFieldValue('selfiePicStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'selfiePic')?.status);

            // Cnic Front
            formik.setFieldValue('cnicFrontPic', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'cnicFrontPic')?.fieldValue);
            formik.setFieldValue('cnicFrontPicComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'cnicFrontPic')?.comment);
            formik.setFieldValue('cnicFrontPicStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'cnicFrontPic')?.status);

            // Cnic Back
            formik.setFieldValue('cnicBackPic', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'cnicBackPic')?.fieldValue);
            formik.setFieldValue('cnicBackPicComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'cnicBackPic')?.comment);
            formik.setFieldValue('cnicBackPicStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'cnicBackPic')?.status);

            // Birth Place
            formik.setFieldValue('birthPlace', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'birthPlace')?.fieldValue);
            formik.setFieldValue('birthPlaceComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'birthPlace')?.comment);
            formik.setFieldValue('birthPlaceStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'birthPlace')?.status);

            // Mother maiden name
            formik.setFieldValue('motherName', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'motherName')?.fieldValue);
            formik.setFieldValue('motherNameComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'motherName')?.comment);
            formik.setFieldValue('motherNameStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'motherName')?.status);

            // Source of income
            formik.setFieldValue('professionName', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'professionName')?.fieldValue);
            formik.setFieldValue('professionNameComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'professionName')?.comment);
            formik.setFieldValue('professionNameStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'professionName')?.status);

            // Dual Nationality
            formik.setFieldValue('dualNational', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'dualNational')?.fieldValue);
            formik.setFieldValue('dualNationalComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'dualNational')?.comment);
            formik.setFieldValue('dualNationalStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'dualNational')?.status);

            // Us Born
            formik.setFieldValue('usBorn', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'usBorn')?.fieldValue);
            formik.setFieldValue('usBornComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'usBorn')?.comment);
            formik.setFieldValue('usBornStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'usBorn')?.status);

            // Country
            formik.setFieldValue('country', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'country')?.fieldValue);
            formik.setFieldValue('countryComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'country')?.comment);
            formik.setFieldValue('countryStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'country')?.status);

            // Country of Birth
            formik.setFieldValue('countryOfBirth', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'countryOfBirth')?.fieldValue);
            formik.setFieldValue('countryOfBirthComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'countryOfBirth')?.comment);
            formik.setFieldValue('countryOfBirthStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'countryOfBirth')?.status);

            // Country of Tax Residence
            formik.setFieldValue('countryOfTaxResidence', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'countryOfTaxResidence')?.fieldValue);
            formik.setFieldValue('countryOfTaxResidenceComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'countryOfTaxResidence')?.comment);
            formik.setFieldValue('countryOfTaxResidenceStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'countryOfTaxResidence')?.status);

            // US Green Card Holder
            formik.setFieldValue('usGreenCardHolder', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'usGreenCardHolder')?.fieldValue);
            formik.setFieldValue('usGreenCardHolderComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'usGreenCardHolder')?.comment);
            formik.setFieldValue('usGreenCardHolderStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'usGreenCardHolder')?.status);

            // US Telephone Number
            formik.setFieldValue('usTelePhoneNumber', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'usTelePhoneNumber')?.fieldValue);
            formik.setFieldValue('usTelePhoneNumberComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'usTelePhoneNumber')?.comment);
            formik.setFieldValue('usTelePhoneNumberStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'usTelePhoneNumber')?.status);

            // US Sign Auth
            formik.setFieldValue('usSignAuth', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'usSignAuth')?.fieldValue);
            formik.setFieldValue('usSignAuthComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'usSignAuth')?.comment);
            formik.setFieldValue('usSignAuthStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'usSignAuth')?.status);

            // US Other Info
            formik.setFieldValue('usOtherInfo', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'usOtherInfo')?.fieldValue);
            formik.setFieldValue('usOtherInfoComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'usOtherInfo')?.comment);
            formik.setFieldValue('usOtherInfoStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'usOtherInfo')?.status);

            // Fedral Tax Classification Name
            formik.setFieldValue('fedralTaxClassificationName', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'fedralTaxClassificationName')?.fieldValue);
            formik.setFieldValue('fedralTaxClassificationNameComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'fedralTaxClassificationName')?.comment);
            formik.setFieldValue('fedralTaxClassificationNameStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'fedralTaxClassificationName')?.status);

            // Residence Address
            formik.setFieldValue('residenceAddress', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'residenceAddress')?.fieldValue);
            formik.setFieldValue('residenceAddressComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'residenceAddress')?.comment);
            formik.setFieldValue('residenceAddressStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'residenceAddress')?.status);

            // Tax Identification Number
            formik.setFieldValue('taxIdentificationNumber', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'taxIdentificationNumber')?.fieldValue);
            formik.setFieldValue('taxIdentificationNumberComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'taxIdentificationNumber')?.comment);
            formik.setFieldValue('taxIdentificationNumberStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'taxIdentificationNumber')?.status);

            // Geo Location
            formik.setFieldValue('geoLocation', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'geoLocation')?.fieldValue);
            formik.setFieldValue('geoLocationComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'geoLocation')?.comment);
            formik.setFieldValue('geoLocationStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'geoLocation')?.status);

            // Cnic Expiry
            formik.setFieldValue('cnicExpiryDate', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'cnicExpiryDate')?.fieldValue);
            formik.setFieldValue('cnicExpiryDateComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'cnicExpiryDate')?.comment);
            formik.setFieldValue('cnicExpiryDateStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'cnicExpiryDate')?.status);

            // Signature Pic
            formik.setFieldValue('signaturePic', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'signaturePic')?.fieldValue);
            formik.setFieldValue('signaturePicComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'signaturePic')?.comment);
            formik.setFieldValue('signaturePicStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'signaturePic')?.status);

            // bvsVerified
            formik.setFieldValue('bvsVerified', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'bvsVerified')?.fieldValue);
            formik.setFieldValue('bvsVerifiedComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'bvsVerified')?.comment);
            formik.setFieldValue('bvsVerifiedStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'bvsVerified')?.status);

            // Email Address
            formik.setFieldValue('email', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'email')?.fieldValue);
            formik.setFieldValue('emailComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'email')?.comment);
            formik.setFieldValue('emailStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'email')?.status);

            // Date of Birth
            formik.setFieldValue('dateOfBirth', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'dateOfBirth')?.fieldValue);
            formik.setFieldValue('dateOfBirthComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'dateOfBirth')?.comment);
            formik.setFieldValue('dateOfBirthStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'dateOfBirth')?.status);

            // Customer Video
            formik.setFieldValue('customerVideo', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'customerVideo')?.fieldValue);
            formik.setFieldValue('customerVideoComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'customerVideo')?.comment);
            formik.setFieldValue('customerVideoStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'customerVideo')?.status);

            // Customer Video Kyc
            formik.setFieldValue('customerVideoKyc', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'customerVideoKyc')?.fieldValue);
            formik.setFieldValue('customerVideoKycComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'customerVideoKyc')?.comment);
            formik.setFieldValue('customerVideoKycStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'customerVideoKyc')?.status);

            // Device Id
            formik.setFieldValue('deviceId', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'deviceId')?.fieldValue);
            formik.setFieldValue('deviceIdComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'deviceId')?.comment);
            formik.setFieldValue('deviceIdStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'deviceId')?.status);

            // Account Purpose
            formik.setFieldValue('purposeOfAccount', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'purposeOfAccount')?.fieldValue);
            formik.setFieldValue('purposeOfAccountComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'purposeOfAccount')?.comment);
            formik.setFieldValue('purposeOfAccountStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'purposeOfAccount')?.status);

            // Gender
            formik.setFieldValue('gender', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'gender')?.fieldValue);
            formik.setFieldValue('genderComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'gender')?.comment);
            formik.setFieldValue('genderStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'gender')?.status);

            // Currency
            formik.setFieldValue('currencyName', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'currencyName')?.fieldValue);
            formik.setFieldValue('currencyNameComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'currencyName')?.comment);
            formik.setFieldValue('currencyNameStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'currencyName')?.status);

            // Mailing Address
            formik.setFieldValue('mailingAddress', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'mailingAddress')?.fieldValue);
            formik.setFieldValue('mailingAddressComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'mailingAddress')?.comment);
            formik.setFieldValue('mailingAddressStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'mailingAddress')?.status);

            // Proof of income
            formik.setFieldValue('proofOfIncomePic', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'proofOfIncomePic')?.fieldValue);
            formik.setFieldValue('proofOfIncomePicComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'proofOfIncomePic')?.comment);
            formik.setFieldValue('proofOfIncomePicStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'proofOfIncomePic')?.status);

            // B Form Pic
            formik.setFieldValue('bFormPic', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'bFormPic')?.fieldValue);
            formik.setFieldValue('bFormPicComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'bFormPic')?.comment);
            formik.setFieldValue('bFormPicStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'bFormPic')?.status);

            // Parent Cnic Front Pic
            formik.setFieldValue('parentCnicFrontPic', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'parentCnicFrontPic')?.fieldValue);
            formik.setFieldValue('parentCnicFrontPicComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'parentCnicFrontPic')?.comment);
            formik.setFieldValue('parentCnicFrontPicStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'parentCnicFrontPic')?.status);

            // Parent Cnic Back Pic
            formik.setFieldValue('parentCnicBackPic', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'parentCnicBackPic')?.fieldValue);
            formik.setFieldValue('parentCnicBackPicComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'parentCnicBackPic')?.comment);
            formik.setFieldValue('parentCnicBackPicStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'parentCnicBackPic')?.status);

            // Father Name
            formik.setFieldValue('fatherName', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'fatherName')?.fieldValue);
            formik.setFieldValue('fatherNameComment', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'fatherName')?.comment);
            formik.setFieldValue('fatherNameStatus', responseData?.getByMCRequestIdData?.userRecord && responseData?.getByMCRequestIdData?.userRecord.find((item) => item?.fieldName === 'fatherName')?.status);

            // Maker comment & status
            formik.setFieldValue('comments', responseData?.getByMCRequestIdData && responseData?.getByMCRequestIdData?.makerComment);
            formik.setFieldValue('status', responseData?.getByMCRequestIdData && responseData?.getByMCRequestIdData?.makerStatus);
        }
        if ((type === 'edit' || type === 'view') && responseData) {
            formik.setFieldValue('accountTitle', responseData?.accountTitle);
            formik.setFieldValue('selfiePic', responseData?.selfiePic ? responseData.selfiePic : 'N/A');
            formik.setFieldValue('cnicFrontPic', responseData?.cnicFrontPic ? responseData?.cnicFrontPic : 'N/A');
            formik.setFieldValue('cnicBackPic', responseData?.cnicBackPic ? responseData?.cnicBackPic : 'N/A');
            formik.setFieldValue('fatherName', responseData?.fatherName ? responseData?.fatherName : 'N/A');
            formik.setFieldValue('birthPlace', responseData?.birthPlace ? responseData?.birthPlace : 'N/A');
            formik.setFieldValue('motherName', responseData?.motherName ? responseData?.motherName : 'N/A');
            formik.setFieldValue('professionName', responseData?.professionName ? responseData?.professionName : 'N/A');
            formik.setFieldValue('dualNational', responseData?.dualNational === 'Y' ? 'Yes' : responseData?.dualNational === 'N' ? 'No' : 'N/A');
            formik.setFieldValue('usBorn', responseData?.usBorn === 'Y' ? 'Yes' : responseData?.usBorn === 'N' ? 'No' : 'N/A');
            formik.setFieldValue('country', responseData?.country ? responseData?.country : 'N/A');
            formik.setFieldValue('countryOfBirth', responseData?.countryOfBirth ? responseData?.countryOfBirth : 'N/A');
            formik.setFieldValue('countryOfTaxResidence', responseData?.countryOfTaxResidence ? responseData?.countryOfTaxResidence : 'N/A');
            formik.setFieldValue('usGreenCardHolder', responseData?.usGreenCardHolder === 'Y' ? 'Yes' : responseData?.usGreenCardHolder === 'N' ? 'No' : 'N/A');
            formik.setFieldValue('usTelePhoneNumber', responseData?.usTelePhoneNumber ? responseData?.usTelePhoneNumber : 'N/A');
            formik.setFieldValue('usSignAuth', responseData?.usSignAuth === 'Y' ? 'Yes' : responseData?.usSignAuth === 'N' ? 'No' : 'N/A');
            formik.setFieldValue('usOtherInfo', responseData?.usOtherInfo ? responseData?.usOtherInfo : 'N/A');
            formik.setFieldValue('fedralTaxClassificationName', responseData?.fedralTaxClassificationName ? responseData?.fedralTaxClassificationName : 'N/A');
            formik.setFieldValue('residenceAddress', responseData?.residenceAddress ? responseData?.residenceAddress : 'N/A');
            formik.setFieldValue('taxIdentificationNumber', responseData?.taxIdentificationNumber ? responseData?.taxIdentificationNumber : 'N/A');
            formik.setFieldValue('geoLocation', responseData?.latitude + ' ' + responseData?.logitude);
            formik.setFieldValue('cnicExpiryDate', responseData?.cnicExpiryDate ? moment(responseData?.cnicExpiryDate)?.format('DD-MM-YYYY') : '');
            formik.setFieldValue('signaturePic', responseData?.signaturePic ? responseData?.signaturePic : 'N/A');
            formik.setFieldValue('bvsVerified', responseData?.bvsVerified === 'Y' ? 'Yes' : responseData?.bvsVerified === 'N' ? 'No' : 'N/A');
            formik.setFieldValue('email', responseData?.email ? responseData?.email : 'N/A');
            formik.setFieldValue('dateOfBirth', responseData?.dateOfBirth ? moment(responseData?.dateOfBirth)?.format('DD-MM-YYYY') : 'N/A');
            formik.setFieldValue('customerVideo', responseData?.customerVideo ? responseData?.customerVideo : 'N/A');
            formik.setFieldValue('customerVideoKyc', responseData?.customerVideoKyc ? responseData?.customerVideoKyc : 'N/A');
            formik.setFieldValue('deviceId', responseData?.deviceId ? responseData?.deviceId?.toString() : 'N/A');
            formik.setFieldValue('purposeOfAccount', responseData?.purposeOfAccount ? responseData?.purposeOfAccount : 'N/A');
            formik.setFieldValue('gender', responseData?.gender ? responseData?.gender : 'N/A');
            formik.setFieldValue('currencyName', responseData?.currencyName ? responseData?.currencyName : 'N/A');
            formik.setFieldValue('mailingAddress', responseData?.mailingAddress ? responseData?.mailingAddress : 'N/A');
            formik.setFieldValue('proofOfIncomePic', responseData?.proofOfIncomePic ? responseData?.proofOfIncomePic : 'N/A');
            formik.setFieldValue('bFormPic', responseData?.bFormPic ? responseData?.bFormPic : 'N/A');
            formik.setFieldValue('parentCnicFrontPic', responseData?.parentCnicFrontPic ? responseData?.parentCnicFrontPic : 'N/A');
            formik.setFieldValue('parentCnicBackPic', responseData?.parentCnicBackPic ? responseData?.parentCnicBackPic : 'N/A');
            formik.setFieldValue('status', responseData?.makerStatus);
            formik.setFieldValue('comments', responseData?.makerComments);
        }
    }, [responseData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // All Criteria logic
        if (Object.values(actionStatus).every((value) => value === '2')) {
            formik.setFieldValue('status', '2');
        } else if (Object.values(actionStatus).every((value) => value === '3') || Object.values(actionStatus).every((value) => value === '4')) {
            formik.setFieldValue('status', '3');
        } else if (
            (Object.values(actionStatus).includes('2') && Object.values(actionStatus).includes('3') && Object.values(actionStatus).includes('4')) ||
            (Object.values(actionStatus).includes('2') && Object.values(actionStatus).includes('3')) ||
            (Object.values(actionStatus).includes('2') && Object.values(actionStatus).includes('4')) ||
            (Object.values(actionStatus).includes('3') && Object.values(actionStatus).includes('4'))
        ) {
            formik.setFieldValue('status', '4');
        } else {
            formik.setFieldValue('status', '');
        }
    }, [actionStatus]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h5 className="uppercase">{type === 'edit' || type === 'checkerEdit' ? 'Edit Detail' : type === 'view' || type === 'checkerView' ? 'View Detail' : null}</h5>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <InputWithCommentAction
                        setActionStatus={setActionStatus}
                        actionStatus={actionStatus}
                        inputName="accountTitle"
                        label="Customer Account Title as per CNIC"
                        inputValue={formik?.values?.accountTitle}
                        commentName="accountTitleComment"
                        commentValue={formik?.values?.accountTitleComment}
                        actionName="accountTitleStatus"
                        actionValue={formik?.values?.accountTitleStatus}
                        formik={formik}
                        isFormFieldValid={isFormFieldValid}
                        getFormErrorMessage={getFormErrorMessage}
                        handleKeyPress={handleKeyPress}
                        classNames={classNames}
                        innerActionList={discrepantStatusList}
                        type={type}
                    />
                    {/* <div className="p-fluid p-formgrid grid mt-4">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountTitle" className={classNames({ 'p-error': isFormFieldValid('accountTitle') }, 'Label__Text')}>
                                    Customer Account Title as per CNIC
                                </label>
                                <InputText
                                    id="accountTitle"
                                    name="accountTitle"
                                    value={formik?.values?.accountTitle || ''}
                                    disabled={true}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountTitle') }, 'Input__Round')}
                                    onKeyDown={(e) => handleKeyPress(e)}
                                />
                            </div>
                        </div>
                    </div> */}
                    <InputWithCommentAction
                        setActionStatus={setActionStatus}
                        actionStatus={actionStatus}
                        inputName="selfiePic"
                        inputType="button"
                        label="Customer Selfie Picture"
                        inputValue={formik?.values?.selfiePic}
                        commentName="selfiePicComment"
                        commentValue={formik?.values?.selfiePicComment}
                        actionName="selfiePicStatus"
                        actionValue={formik?.values?.selfiePicStatus}
                        formik={formik}
                        isFormFieldValid={isFormFieldValid}
                        getFormErrorMessage={getFormErrorMessage}
                        handleKeyPress={handleKeyPress}
                        classNames={classNames}
                        innerActionList={discrepantStatusList}
                        type={type}
                    />
                    {(responseData?.accountLevelName?.includes('MINOR') || responseData?.getByMCRequestIdData?.accountLevelName?.includes('MINOR')) && formik?.values?.bFormPic ? (
                        <InputWithCommentAction
                            setActionStatus={setActionStatus}
                            actionStatus={actionStatus}
                            inputName="bFormPic"
                            inputType="button"
                            label="Customer Form-B Picture"
                            inputValue={formik?.values?.bFormPic}
                            commentName="bFormPicComment"
                            commentValue={formik?.values?.bFormPicComment}
                            actionName="bFormPicStatus"
                            actionValue={formik?.values?.bFormPicStatus}
                            formik={formik}
                            isFormFieldValid={isFormFieldValid}
                            getFormErrorMessage={getFormErrorMessage}
                            handleKeyPress={handleKeyPress}
                            classNames={classNames}
                            innerActionList={discrepantStatusList}
                            type={type}
                        />
                    ) : (
                        <React.Fragment>
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="cnicFrontPic"
                                inputType="button"
                                label="Customer CNIC Front Picture"
                                inputValue={formik?.values?.cnicFrontPic}
                                commentName="cnicFrontPicComment"
                                commentValue={formik?.values?.cnicFrontPicComment}
                                actionName="cnicFrontPicStatus"
                                actionValue={formik?.values?.cnicFrontPicStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="cnicBackPic"
                                inputType="button"
                                label="Customer CNIC Back Picture"
                                inputValue={formik?.values?.cnicBackPic}
                                commentName="cnicBackPicComment"
                                commentValue={formik?.values?.cnicBackPicComment}
                                actionName="cnicBackPicStatus"
                                actionValue={formik?.values?.cnicBackPicStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                        </React.Fragment>
                    )}
                    {responseData?.accountLevelName?.includes('MINOR') || responseData?.getByMCRequestIdData?.accountLevelName?.includes('MINOR') ? (
                        <React.Fragment>
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="parentCnicFrontPic"
                                inputType="button"
                                label="Parent CNIC Front Picture"
                                inputValue={formik?.values?.parentCnicFrontPic}
                                commentName="parentCnicFrontPicComment"
                                commentValue={formik?.values?.parentCnicFrontPicComment}
                                actionName="parentCnicFrontPicStatus"
                                actionValue={formik?.values?.parentCnicFrontPicStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="parentCnicBackPic"
                                inputType="button"
                                label="Parent CNIC Back Picture"
                                inputValue={formik?.values?.parentCnicBackPic}
                                commentName="parentCnicBackPicComment"
                                commentValue={formik?.values?.parentCnicBackPicComment}
                                actionName="parentCnicBackPicStatus"
                                actionValue={formik?.values?.parentCnicBackPicStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="bvsVerified"
                                inputType="radio"
                                label="Bio Verification"
                                inputValue={formik?.values?.bvsVerified}
                                commentName="bvsVerifiedComment"
                                commentValue={formik?.values?.bvsVerifiedComment}
                                actionName="bvsVerifiedStatus"
                                actionValue={formik?.values?.bvsVerifiedStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="fatherName"
                                label="Father Name of Customer as per CNIC"
                                inputValue={formik?.values?.fatherName}
                                commentName="fatherNameComment"
                                commentValue={formik?.values?.fatherNameComment}
                                actionName="fatherNameStatus"
                                actionValue={formik?.values?.fatherNameStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="birthPlace"
                                label="Customer Place of Birth (as per Nadra)"
                                inputValue={formik?.values?.birthPlace}
                                commentName="birthPlaceComment"
                                commentValue={formik?.values?.birthPlaceComment}
                                actionName="birthPlaceStatus"
                                actionValue={formik?.values?.birthPlaceStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="motherName"
                                label="Mother Maiden Name (as per Nadra)"
                                inputValue={formik?.values?.motherName}
                                commentName="motherNameComment"
                                commentValue={formik?.values?.motherNameComment}
                                actionName="motherNameStatus"
                                actionValue={formik?.values?.motherNameStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="professionName"
                                label="Source of Income"
                                inputValue={formik?.values?.professionName}
                                commentName="professionNameComment"
                                commentValue={formik?.values?.professionNameComment}
                                actionName="professionNameStatus"
                                actionValue={formik?.values?.professionNameStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="dualNational"
                                label="Dual Nationality Details"
                                inputValue={formik?.values?.dualNational}
                                commentName="dualNationalComment"
                                commentValue={formik?.values?.dualNationalComment}
                                actionName="dualNationalStatus"
                                actionValue={formik?.values?.dualNationalStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="country"
                                label="Country"
                                inputValue={formik?.values?.country}
                                commentName="countryComment"
                                commentValue={formik?.values?.countryComment}
                                actionName="countryStatus"
                                actionValue={formik?.values?.countryStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="usBorn"
                                label="US Citizen Details"
                                inputValue={formik?.values?.usBorn}
                                commentName="usBornComment"
                                commentValue={formik?.values?.usBornComment}
                                actionName="usBornStatus"
                                actionValue={formik?.values?.usBornStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="countryOfBirth"
                                label="Country of Birth"
                                inputValue={formik?.values?.countryOfBirth}
                                commentName="countryOfBirthComment"
                                commentValue={formik?.values?.countryOfBirthComment}
                                actionName="countryOfBirthStatus"
                                actionValue={formik?.values?.countryOfBirthStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="countryOfTaxResidence"
                                label="Country of Tax Residence"
                                inputValue={formik?.values?.countryOfTaxResidence}
                                commentName="countryOfTaxResidenceComment"
                                commentValue={formik?.values?.countryOfTaxResidenceComment}
                                actionName="countryOfTaxResidenceStatus"
                                actionValue={formik?.values?.countryOfTaxResidenceStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="usGreenCardHolder"
                                label="US Green Card Holder Details"
                                inputValue={formik?.values?.usGreenCardHolder}
                                commentName="usGreenCardHolderComment"
                                commentValue={formik?.values?.usGreenCardHolderComment}
                                actionName="usGreenCardHolderStatus"
                                actionValue={formik?.values?.usGreenCardHolderStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="usTelePhoneNumber"
                                label="US Telephone Number"
                                inputValue={formik?.values?.usTelePhoneNumber}
                                commentName="usTelePhoneNumberComment"
                                commentValue={formik?.values?.usTelePhoneNumberComment}
                                actionName="usTelePhoneNumberStatus"
                                actionValue={formik?.values?.usTelePhoneNumberStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="usSignAuth"
                                label="US Signatory Authority Details"
                                inputValue={formik?.values?.usSignAuth}
                                commentName="usSignAuthComment"
                                commentValue={formik?.values?.usSignAuthComment}
                                actionName="usSignAuthStatus"
                                actionValue={formik?.values?.usSignAuthStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="usOtherInfo"
                                label="US Other Information"
                                inputValue={formik?.values?.usOtherInfo}
                                commentName="usOtherInfoComment"
                                commentValue={formik?.values?.usOtherInfoComment}
                                actionName="usOtherInfoStatus"
                                actionValue={formik?.values?.usOtherInfoStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="fedralTaxClassificationName"
                                label="Federal Tax Classification"
                                inputValue={formik?.values?.fedralTaxClassificationName}
                                commentName="fedralTaxClassificationNameComment"
                                commentValue={formik?.values?.fedralTaxClassificationNameComment}
                                actionName="fedralTaxClassificationNameStatus"
                                actionValue={formik?.values?.fedralTaxClassificationNameStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="residenceAddress"
                                label="Residence Address"
                                inputValue={formik?.values?.residenceAddress}
                                commentName="residenceAddressComment"
                                commentValue={formik?.values?.residenceAddressComment}
                                actionName="residenceAddressStatus"
                                actionValue={formik?.values?.residenceAddressStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="taxIdentificationNumber"
                                label="Tax Identification Number"
                                inputValue={formik?.values?.taxIdentificationNumber}
                                commentName="taxIdentificationNumberComment"
                                commentValue={formik?.values?.taxIdentificationNumberComment}
                                actionName="taxIdentificationNumberStatus"
                                actionValue={formik?.values?.taxIdentificationNumberStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="geoLocation"
                                label="Geo Location Details"
                                inputValue={formik?.values?.geoLocation}
                                commentName="geoLocationComment"
                                commentValue={formik?.values?.geoLocationComment}
                                actionName="geoLocationStatus"
                                actionValue={formik?.values?.geoLocationStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="cnicExpiryDate"
                                label="CNIC Expiry Date"
                                inputValue={formik?.values?.cnicExpiryDate}
                                commentName="cnicExpiryDateComment"
                                commentValue={formik?.values?.cnicExpiryDateComment}
                                actionName="cnicExpiryDateStatus"
                                actionValue={formik?.values?.cnicExpiryDateStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="signaturePic"
                                inputType="button"
                                label="Customer Signature"
                                inputValue={formik?.values?.signaturePic}
                                commentName="signaturePicComment"
                                commentValue={formik?.values?.signaturePicComment}
                                actionName="signaturePicStatus"
                                actionValue={formik?.values?.signaturePicStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="bvsVerified"
                                inputType="radio"
                                label="Bio Verification"
                                inputValue={formik?.values?.bvsVerified}
                                commentName="bvsVerifiedComment"
                                commentValue={formik?.values?.bvsVerifiedComment}
                                actionName="bvsVerifiedStatus"
                                actionValue={formik?.values?.bvsVerifiedStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="email"
                                label="Email Address"
                                inputValue={formik?.values?.email}
                                commentName="emailComment"
                                commentValue={formik?.values?.emailComment}
                                actionName="emailStatus"
                                actionValue={formik?.values?.emailStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="dateOfBirth"
                                label="Date of Birth"
                                inputValue={formik?.values?.dateOfBirth}
                                commentName="dateOfBirthComment"
                                commentValue={formik?.values?.dateOfBirthComment}
                                actionName="dateOfBirthStatus"
                                actionValue={formik?.values?.dateOfBirthStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="customerVideo"
                                inputType="video"
                                label="Liveness Check video"
                                inputValue={formik?.values?.customerVideo}
                                commentName="customerVideoComment"
                                commentValue={formik?.values?.customerVideoComment}
                                actionName="customerVideoStatus"
                                actionValue={formik?.values?.customerVideoStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="customerVideoKyc"
                                inputType="video"
                                label="Video KYC (High risk Customer)"
                                inputValue={formik?.values?.customerVideoKyc}
                                commentName="customerVideoKycComment"
                                commentValue={formik?.values?.customerVideoKycComment}
                                actionName="customerVideoKycStatus"
                                actionValue={formik?.values?.customerVideoKycStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="deviceId"
                                label="Device ID"
                                inputValue={formik?.values?.deviceId}
                                commentName="deviceIdComment"
                                commentValue={formik?.values?.deviceIdComment}
                                actionName="deviceIdStatus"
                                actionValue={formik?.values?.deviceIdStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="purposeOfAccount"
                                label="Purpose of Account"
                                inputValue={formik?.values?.purposeOfAccount}
                                commentName="purposeOfAccountComment"
                                commentValue={formik?.values?.purposeOfAccountComment}
                                actionName="purposeOfAccountStatus"
                                actionValue={formik?.values?.purposeOfAccountStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            <InputWithCommentAction
                                setActionStatus={setActionStatus}
                                actionStatus={actionStatus}
                                inputName="gender"
                                label="Gender"
                                inputValue={formik?.values?.gender}
                                commentName="genderComment"
                                commentValue={formik?.values?.genderComment}
                                actionName="genderStatus"
                                actionValue={formik?.values?.genderStatus}
                                formik={formik}
                                isFormFieldValid={isFormFieldValid}
                                getFormErrorMessage={getFormErrorMessage}
                                handleKeyPress={handleKeyPress}
                                classNames={classNames}
                                innerActionList={discrepantStatusList}
                                type={type}
                            />
                            {(responseData?.accountLevelName?.includes('FREELANCE') || responseData?.getByMCRequestIdData?.accountLevelName?.includes('FREELANCE')) && (
                                <InputWithCommentAction
                                    setActionStatus={setActionStatus}
                                    actionStatus={actionStatus}
                                    inputName="currencyName"
                                    label="Currency"
                                    inputValue={formik?.values?.currencyName}
                                    commentName="currencyNameComment"
                                    commentValue={formik?.values?.currencyNameComment}
                                    actionName="currencyNameStatus"
                                    actionValue={formik?.values?.currencyNameStatus}
                                    formik={formik}
                                    isFormFieldValid={isFormFieldValid}
                                    getFormErrorMessage={getFormErrorMessage}
                                    handleKeyPress={handleKeyPress}
                                    classNames={classNames}
                                    innerActionList={discrepantStatusList}
                                    type={type}
                                />
                            )}
                            {(responseData?.accountLevelName?.includes('SIGNATURE') || responseData?.getByMCRequestIdData?.accountLevelName?.includes('SIGNATURE')) && (
                                <React.Fragment>
                                    <InputWithCommentAction
                                        setActionStatus={setActionStatus}
                                        actionStatus={actionStatus}
                                        inputName="mailingAddress"
                                        label="Mailing Address"
                                        inputValue={formik?.values?.mailingAddress}
                                        commentName="mailingAddressComment"
                                        commentValue={formik?.values?.mailingAddressComment}
                                        actionName="mailingAddressStatus"
                                        actionValue={formik?.values?.mailingAddressStatus}
                                        formik={formik}
                                        isFormFieldValid={isFormFieldValid}
                                        getFormErrorMessage={getFormErrorMessage}
                                        handleKeyPress={handleKeyPress}
                                        classNames={classNames}
                                        innerActionList={discrepantStatusList}
                                        type={type}
                                    />
                                    <InputWithCommentAction
                                        setActionStatus={setActionStatus}
                                        actionStatus={actionStatus}
                                        inputName="proofOfIncomePic"
                                        inputType="button"
                                        label="Proof of Income"
                                        inputValue={formik?.values?.proofOfIncomePic}
                                        commentName="proofOfIncomePicComment"
                                        commentValue={formik?.values?.proofOfIncomePicComment}
                                        actionName="proofOfIncomePicStatus"
                                        actionValue={formik?.values?.proofOfIncomePicStatus}
                                        formik={formik}
                                        isFormFieldValid={isFormFieldValid}
                                        getFormErrorMessage={getFormErrorMessage}
                                        handleKeyPress={handleKeyPress}
                                        classNames={classNames}
                                        innerActionList={discrepantStatusList}
                                        type={type}
                                    />
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}

                    {/* Maker Action */}
                    <div className="custom-card p-3 my-3">
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('status') }, 'Label__Text')}>
                                        Status
                                        <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="status"
                                        placeholder="Select Status"
                                        options={discrepantStatusList}
                                        optionLabel="name"
                                        name="status"
                                        optionValue="lovId"
                                        value={formik.values.status || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('status') }, 'Dropdown__Round')}
                                        disabled
                                    />

                                    {getFormErrorMessage('status')}
                                </div>
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="comments" className={classNames({ 'p-error': isFormFieldValid('comments') }, 'Label__Text')}>
                                        Comments
                                        <span className="Label__Required">*</span>
                                    </label>
                                    <InputTextarea
                                        maxLength={500}
                                        rows={5}
                                        cols={30}
                                        id="comments"
                                        name="comments"
                                        value={formik.values.comments || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('comments') }, 'TextArea__Round')}
                                        disabled={type !== 'edit'}
                                    />

                                    {getFormErrorMessage('comments')}
                                </div>
                            </div>
                        </div>
                    </div>
                    {type === 'edit' && (
                        <div className="Down__Btn py-3">
                            <Button className="Btn__Dark" disabled={loading} type="submit" label={'Update'} />
                            <Button type="button" onClick={() => navigate(-1)} label="Cancel" className="Btn__Transparent" />
                        </div>
                    )}
                    {type === 'view' && (
                        <React.Fragment>
                            <div className="Down__Btn py-3">
                                <Button type="button" onClick={() => navigate(-1)} label="Okay" className="Btn__Dark" />
                            </div>
                            {responseData?.ultraCustomerId && <AuthorizationHistoryTable refTableId={responseData?.ultraCustomerId} tableName={'TBL_ULTRA_CUSTOMER'} />}
                        </React.Fragment>
                    )}

                    {/* Checker Action */}
                    {type === 'checkerEdit' && (
                        <React.Fragment>
                            <div className="flex mt-5">
                                <div className="title__header riskDashboardHeader ">CHECKER REVIEW</div>
                            </div>
                            <div className="custom-card p-3 my-4">
                                <div className="p-fluid p-formgrid grid mt-3 mb-5">
                                    <div className="p-field col-12 md:col-4">
                                        <div className="p-field">
                                            <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                                Action
                                                <span className="Label__Required">*</span>
                                            </label>
                                            <Dropdown
                                                id="action"
                                                placeholder="Select Action"
                                                options={statusLovData || []}
                                                optionLabel="name"
                                                name="action"
                                                optionValue="lovId"
                                                value={formik.values.action || ''}
                                                onChange={formik.handleChange}
                                                // onChange={(e) => {
                                                //     formik.handleChange(e);
                                                //     setCheckerEditPayLoad({
                                                //         ...checkerEditPayLoad,
                                                //         mcActionRequest: {
                                                //             ...checkerEditPayLoad?.mcActionRequest,
                                                //             action: e.target.value
                                                //         }
                                                //     });
                                                // }}
                                                className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                            />

                                            {getFormErrorMessage('action')}
                                        </div>
                                    </div>
                                </div>
                                <div className="p-fluid p-formgrid grid mb-5">
                                    <div className="p-field col-12 md:col-4">
                                        <div className="p-field">
                                            <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                                Comments
                                                <span className="Label__Required">*</span>
                                            </label>
                                            <InputTextarea
                                                maxLength={500}
                                                rows={5}
                                                cols={30}
                                                id="checkerComments"
                                                name="checkerComments"
                                                value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') || ''}
                                                onChange={formik.handleChange}
                                                // onChange={(e) => {
                                                //     formik.handleChange(e);
                                                //     setCheckerEditPayLoad({
                                                //         ...checkerEditPayLoad,
                                                //         mcActionRequest: {
                                                //             ...checkerEditPayLoad?.mcActionRequest,
                                                //             checkerComments: e.target.value
                                                //         }
                                                //     });
                                                // }}
                                                className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                            />

                                            {getFormErrorMessage('checkerComments')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Down__Btn py-3">
                                <Button disabled={loading} label="Update" className="Btn__Dark" />
                                <Button type="button" onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                            </div>
                            {responseData?.urlData?.refTableId && responseData?.urlData?.tableName && <AuthorizationHistoryTable refTableId={responseData?.urlData?.refTableId} tableName={responseData?.urlData?.tableName} />}
                        </React.Fragment>
                    )}
                    {type === 'checkerView' && (
                        <React.Fragment>
                            <div className="Down__Btn">
                                <Button type="button" onClick={() => navigate('/requestchecker')} label="Okay" className="Btn__Dark" />
                            </div>

                            {/* Action Authorization History */}
                            {responseData?.urlData?.refTableId && responseData?.urlData?.tableName && <AuthorizationHistoryTable refTableId={responseData?.urlData?.refTableId} tableName={responseData?.urlData?.tableName} />}
                        </React.Fragment>
                    )}
                </form>
            </div>
        </>
    );
}
export default DefaultComponent;

import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';

const BlockedSuspendedBlacklistedDormantAcc = ({ bsbDormantAccounts }) => {

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {

        const accLevelArr = bsbDormantAccounts && bsbDormantAccounts.map((bsbda) => { return bsbda?.accountLavelName })
        const totalAmountArr = bsbDormantAccounts && bsbDormantAccounts.map((bsbda) => { return bsbda?.totalBalance })
        const countArr = bsbDormantAccounts && bsbDormantAccounts.map((bsbda) => { return bsbda?.count })

        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: accLevelArr,
            datasets: [
                {
                    label: 'Count',
                    backgroundColor: "rgb(212,230,243)",
                    data: countArr
                }, {
                    label: 'Total Amount',
                    backgroundColor: "rgb(235,224,255)",
                    data: totalAmountArr
                }
            ]
        };
        const options = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        fontColor: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                        font: {
                            weight: 500
                        }
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                }
            }
        };

        setChartData(data)
        setChartOptions(options);
    }, [bsbDormantAccounts]);

    return (
        <div className="col-12 md:col-5">
            <div className="card Card__Round">
                <div className="cardBarCharts">
                    <h5>Blocked/Suspended/Blacklisted/Dormant Accounts</h5>
                    <div className="pt-3">
                        <Chart type="bar" data={chartData} options={chartOptions} className="w-full" height='35rem' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlockedSuspendedBlacklistedDormantAcc
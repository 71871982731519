import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';

const TransactionType = ({ transactionTypeData }) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const productNameArr =
            transactionTypeData &&
            transactionTypeData.map((product) => {
                return product?.parameterName;
            });
        const countArr =
            transactionTypeData &&
            transactionTypeData.map((product) => {
                return product?.parameterValue;
            });

        const data = {
            labels: productNameArr,
            datasets: [
                {
                    label: 'Transaction Type',
                    data: countArr,
                    backgroundColor: ['#d7ecfb', '#ebe0ff'],
                    hoverBackgroundColor: ['#d7ecfb', '#ebe0ff']
                }
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    display: false
                }
            },
            scales: {
                y: {
                    beginAtZero: true
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [transactionTypeData]);

    return (
        <div className="col-12 md:col-4">
            <div className="card Card__Round">
                <div className="cardBarCharts">
                    <h5>Transaction Type</h5>
                    <div className="pt-3">
                        <Chart type="bar" data={chartData} options={chartOptions} className="w-full" style={{ height: '35rem' }} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionType;

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { InputTextarea } from 'primereact/inputtextarea';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewBaseFactorChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-save');
    const [statusLovData, setStatusLovData] = useState([]);
    const [dataById, setDataById] = useState();
    const [reqTypeUData, setReqTypeUData] = useState();

    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const tableName = query.get('tableName');
    const reqType = query.get('requestType');
    const mcReqId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    
    const checkerComment =query.get('checkerComments')


    const navigate = useNavigate();

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        getStatusLov();
    }, []);

    const getDatabyId = async () => {
        const response = await handleGetRequest(`/zconfig/v1/zmiles/getBaseFactor/${refTableId}`);
        setDataById(response?.payLoad);

        formik.setFieldValue('baseFactor', response?.payLoad?.baseFactor);
        formik.setFieldValue('dateFrom',  response?.payLoad?.dateFrom);
        formik.setFieldValue('dateTo', response?.payLoad?.dateTo);
        formik.setFieldValue('zmilesBaseFactorId', response?.payLoad?.zmilesBaseFactorId);
        formik.setFieldValue('isActive', response?.payLoad?.isActive);
         formik.setFieldValue('checkerComments', checkerComment)
        
    };

    const getDatabyIdTypeU = async () => {
        const response = await handleGetRequest(`/zconfig/v1/zmiles/getBaseFactorUpdateCheckerById/${mcReqId}`);
        setReqTypeUData(response?.payLoad);

          console.log('kjhklkjh',response?.payLoad?.dateFrom?.slice(0,10))
        formik.setFieldValue('baseFactor', response?.payLoad?.baseFactor);
        formik.setFieldValue('dateFrom', response?.payLoad?.dateFrom?.slice(0,10));
        formik.setFieldValue('dateTo', response?.payLoad?.dateTo?.slice(0,10));
        formik.setFieldValue('isActive', response?.payLoad?.isActive);
        formik.setFieldValue('zmilesBaseFactorId', response?.payLoad?.zmilesBaseFactorId);
         formik.setFieldValue('checkerComments', checkerComment)
    };

    useEffect(() => {
        if (reqType === 'I') {
            getDatabyId();
        } else if (reqType === 'U') {
            getDatabyIdTypeU();
        }
    }, []); // eslint-disable-line
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            baseFactor: '',
            dateFrom: '',
            dateTo: '',
            action: '',
            checkerComments: '',
            isActive: '',
            statusId: 0,
            zmilesBaseFactorId: null
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        zmilesBaseFactorRequest: {
                            baseFactor: parseInt(data['baseFactor']),
                            dateFrom: data['dateFrom'],
                            dateTo: data['dateTo'],
                            isActive: data['isActive'] === 'true' ? 'Y' : 'N',
                            statusId: 0, 
                            appUserId: 0,
                            zmilesBaseFactorId:data['zmilesBaseFactorId']
                        },
                        mcActionRequest: {
                            mcRequestId: mcReqId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, `/zconfig/v1/zmiles/baseFactorCheckerAction`, true, true));
            if (res?.responseCode === '330000') {
                formik.resetForm();
                navigate('/requestchecker')
            }

            // if (editable) {
            //     delete data["baseFactor"];
            //     delete data["dateFrom"];
            //     data["zmilesBaseFactorId"] = baseFactorRowData?.zmilesBaseFactorId;
            //     const res = await dispatch(handlePostRequest(data, `/microbank_zmiles_config/nonFin/updateBaseFactor`, true, true));
            //     if (res?.responsecode === 1) {
            //         formik.resetForm();
            //     }
            // } else {
            //     const res = await dispatch(handlePostRequest(data, `/microbank_zmiles_config/zmiles/saveBaseFactor`, true, true));
            //     if (res?.responsecode === 1) {
            //         formik.resetForm();
            //     }
            // }

            setloading(false);
            setloadingIcon('pi pi-save');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="baseFactor" className={classNames({ 'p-error': isFormFieldValid('baseFactor') }, 'Label__Text')}>
                                    Base Factor<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    id="baseFactor"
                                    placeholder="Enter Base Factor"
                                    name="baseFactor"
                                    value={formik.values.baseFactor}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('baseFactor') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('baseFactor')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Effective From<span className="Label__Required">*</span>
                                </label>
                                <InputText disabled id="dateFrom" type="date" name="dateFrom" value={formik.values.dateFrom} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')} />

                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Effective To<span className="Label__Required">*</span>
                                </label>
                                <InputText disabled id="dateTo" type="date" name="dateTo" value={formik.values.dateTo} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')} />
                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    disabled
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    optionDisabled={(option) => option?.name === 'Pending'}
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    disabled
                                    maxLength={200}
                                    rows={5}
                                    cols={30}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Ok" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default ViewBaseFactorChecker;

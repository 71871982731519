import { Chart } from 'primereact/chart';
import React, { useEffect, useState } from 'react';

const CashInOut = ({ cashInOutData }) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const cityArr =
            cashInOutData &&
            cashInOutData.map((cio) => {
                return cio?.parameterName;
            });
        const countArr =
            cashInOutData &&
            cashInOutData.map((cio) => {
                return cio?.parameterValue;
            });
        const amountArr =
            cashInOutData &&
            cashInOutData.map((cio) => {
                return cio?.parameterValueTwo;
            });

        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: cityArr,
            datasets: [
                {
                    type: 'bar',
                    label: 'Count',
                    backgroundColor: 'rgb(200,246,247)',
                    data: countArr
                },
                {
                    type: 'bar',
                    label: 'Amount',
                    backgroundColor: 'rgb(216,239,170)',
                    data: amountArr
                }
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                        // color: surfaceBorder
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [cashInOutData]);

    return (
        <div className="col-12 md:col-5">
            <div className="card Card__Round">
                <div className="cardBarCharts">
                    <h5>Cash In / Out</h5>
                    <div className="pt-3">
                        <Chart type="bar" data={chartData} options={chartOptions} className="w-full" height="35rem" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CashInOut;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../services/PostTemplate';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../services/GetTemplate';
import { PickList } from 'primereact/picklist';
import { Checkbox } from 'primereact/checkbox';
import { toast } from 'react-toastify';

function AddKycSetsDialog({ onHideAddKycSetsDialog }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-check');
    const [sourceData, setSourceData] = useState([]);
    const [targetData, setTargetData] = useState([]);
    const [clientTypeLovData, setClientTypeLovData] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        formik.resetForm();
    }, []); // eslint-disable-line

    const handleCancel = (e) => {
        e.preventDefault();
        onHideAddKycSetsDialog();
    };

    const getClientTypeLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getclientroles/LKP_ACCOUNT_CLASSIFICATION:*');
        setClientTypeLovData(resp?.payLoad);
    };

    const getKycAttributesLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getallkycvattributes/TBL_KYC_ATTRIBUTES:*');
        // setSourceData(resp?.payLoad);
        setTargetData(resp?.payLoad);
    };

    useEffect(() => {
        getClientTypeLov();
        getKycAttributesLov();
    }, []);

    const unselectKycSetsListData = sourceData?.map((item) => ({
        kycAttributesId: item.lovId,
        isMandatory: item?.isMandatory === false ? 'N' : 'Y'
    }));

    const onPicklistChange = (event) => {
        setSourceData(event.source);
        setTargetData(event.target);
    };

    const pickListIdsWithMandatory = sourceData?.map((item) => ({
        kycAttributesId: item.lovId,
        isMandatory: item?.isMandatory === false ? 'N' : 'Y'
    }));

    const handleCheckboxClickSource = (item) => {
        const updatedSourceData = [...sourceData];
        const selectedItem = updatedSourceData.find((data) => data.lovId === item.lovId);
        selectedItem.isMandatory = !selectedItem.isMandatory;
        setSourceData(updatedSourceData);
    };

    const handleCheckboxClickTarget = (item) => {
        const updatedTargetData = [...targetData];
        const selectedItem = updatedTargetData.find((data) => data.lovId === item.lovId);
        selectedItem.isMandatory = !selectedItem.isMandatory;
        setTargetData(updatedTargetData);
    };

    const sourceItemTemplate = (item) => {
        return (
            <div className="Picklist__Item">
                <h5>{item.name}</h5>
                <Checkbox checked={item?.isMandatory} onChange={() => handleCheckboxClickSource(item)} className="Picklist__Checkbox" />
            </div>
        );
    };

    const targetItemTemplate = (item) => {
        return (
            <div className="Picklist__Item">
                <h5>{item.name}</h5>
                <Checkbox checked={item?.isMandatory} onChange={() => handleCheckboxClickTarget(item)} className="Picklist__Checkbox" />
            </div>
        );
    };

    const sourceHeaderTemplate = () => {
        return (
            <div className="Kyc__Picklist__Header">
                <h5>Attributes</h5>
                <h5>Mandatory</h5>
            </div>
        );
    };

    const targetHeaderTemplate = () => {
        return (
            <div className="Kyc__Picklist__Header">
                <h5>Exclude Attributes</h5>
            </div>
        );
    };

    const validationSchema = Yup.object().shape({
        clientRoleType: Yup.string().required('This Field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            kycSetName: '',
            clientRoleType: ''
        },

        validate: (data) => {
            let errors = {};

            ////////////////////////kycSetName/////////////////////
            if (!data?.kycSetName) {
                errors.kycSetName = 'This field is required';
            } else if (data?.kycSetName === '') {
                errors.kycSetName = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.kycSetName)) {
                errors.kycSetName = 'Leading and trailing space is not allowed';
            } else if (!/^[a-zA-Z](?:[a-zA-Z0-9\s]*-?[a-zA-Z0-9\s]*)?$/.test(data?.kycSetName)) {
                errors.kycSetName = 'Invalid KycSet Name format';
            }

            return errors;
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            formik.setFieldValue('clientRoleType', data?.clientRoleType);

            data['accountClassificationId'] = data?.clientRoleType;
            delete data['clientRoleType'];

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data,
                        tblKycSetDetails: pickListIdsWithMandatory.length > 0 ? pickListIdsWithMandatory : unselectKycSetsListData
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            if (pickListIdsWithMandatory.length === 0) {
                toast.warn('Please Select Attributes');
            } else {
                const res = await dispatch(handlePostRequest(newData, '/account/v1/kycsets/createkycset', true, false));
                if (res?.responseCode === '010000') {
                    formik.resetForm();
                    onHideAddKycSetsDialog();
                }
            }

            setloading(false);
            setloadingIcon('pi pi-save');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-4">
                        <div className="p-field col-12 md:col-6">
                            <div className="p-field">
                                <label htmlFor="kycSetName" className={classNames({ 'p-error': isFormFieldValid('kycSetName') }, 'Label__Text')}>
                                    Kyc Set Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="kycSetName"
                                    placeholder="Enter Kyc set Name"
                                    name="kycSetName"
                                    value={formik?.values?.kycSetName?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('kycSetName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('kycSetName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-6">
                            <div className="p-field">
                                <label htmlFor="clientRoleType" className={classNames({ 'p-error': isFormFieldValid('clientRoleType') }, 'Label__Text')}>
                                    Client Role Type<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="clientRoleType"
                                    placeholder="Select Client Role Type"
                                    options={clientTypeLovData}
                                    optionLabel="name"
                                    name="clientRoleType"
                                    optionValue="lovId"
                                    value={formik.values.clientRoleType}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('clientRoleType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('clientRoleType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <PickList
                                className="KycSets__Picklist"
                                source={sourceData}
                                target={targetData}
                                sourceHeader={sourceHeaderTemplate}
                                targetHeader={targetHeaderTemplate}
                                sourceItemTemplate={sourceItemTemplate}
                                targetItemTemplate={targetItemTemplate}
                                onToggle={(e) => setTargetData(e.target)}
                                filterBy="name"
                                sourceFilterPlaceholder="Search by name"
                                targetFilterPlaceholder="Search by name"
                                sourceStyle={{ height: '35vh' }}
                                targetStyle={{ height: '35vh' }}
                                onChange={onPicklistChange}
                                onMoveToTarget={(e) => setTargetData(e.target)}
                                onMoveToSource={(e) => setSourceData(e.source)}
                            />
                        </div>
                    </div>
                    <div className="Down__Btn CenteredAlign__Btn">
                        <Button type="submit" disabled={loading} loadingIcon={loadingIcon || ''} label={'Submit'} className="Btn__Add" />
                        <Button label="Cancel" className="Btn__cancel" onClick={(e) => handleCancel(e)} />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddKycSetsDialog;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useRef } from 'react';

import { MultiSelect } from 'primereact/multiselect';
import moment from 'moment';
import SecuredFileChange from '../../../components/SecuredFileChange';
import MobileView from './mobileView';

function AddNotifications() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [useCaseData, setUseCaseData] = useState([]);
    const productIconRef = useRef(null);
    const [segmentsData, setSegmentsData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [targetAud, setTargetAud] = useState([]);
    const [timePeriodData, setTimePeriodData] = useState([]);
    const [categoryIconError, setCategoryIconError] = useState('');
    const [iconLink, setIconLink] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getUseCase = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_USECASE');
        setUseCaseData(resp?.payLoad);
    };

    const getSegmentsData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getsegments/LKP_SEGMENT:*');
        const updated = res?.payLoad?.map((item) => ({
            ...item,
            segmentId: item.lovId
        }));
        setSegmentsData(updated);
    };

    const getTargetAud = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getaccounttypes/LKP_ACCOUNT_LEVEL:*');
        setTargetAud(res?.payLoad);
    };

    const getTimePeriodData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getdatalov/LKP_FREQUENCY_PERIOD');
        setTimePeriodData(res?.payLoad);
    };

    useEffect(() => {
        getTimePeriodData();
        getTargetAud();
        getSegmentsData();
        getUseCase();
    }, []); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        notificationName: Yup.string()
            .matches(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/, 'Only alphanumeric characters and spaces are allowed')
            .required('This field is required'),
        notificationTitle: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
            .required('This field is required'),

        notificationText: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
            .required('This field is required'),

        startDate: Yup.date().required('Start Date is required'),

        endDate: Yup.date()
            .when('startDate', (startDate, schema) => (startDate ? schema.min(startDate, 'End Date must be greater than Start Date').required('End Date is required') : schema))
            .required('End Date is required when Start Date is specified'),
        repitition: Yup.boolean(),
        frequency: Yup.string().when('repitition', {
            is: true,
            then: Yup.string().required('Frequency is required'),
            otherwise: Yup.string()
        }),
        timePeriod: Yup.string().when('repitition', {
            is: true,
            then: Yup.string().required('Time period is required'),
            otherwise: Yup.string()
        }),
  
        tblCmsNotificationAccountLevels: Yup.array().required('This field is required').min(1, 'This field is required'),

        categoryIcon: Yup.string().required('This field is required'),
        // notificationTime: Yup.number()
        //     .nullable()
        //     .typeError('Input must be a number')

        //     // .max(24, 'Input must be less than or equal to 24 hours')

        //     .transform((value, originalValue) => (/^\d+(\.\d)?$/.test(originalValue) ? parseFloat(originalValue) : value))
        //     .required('This field is required')
        //     .test('is-one-decimal', 'Input must have only one digit after decimal point', (value) => /^\d+(\.\d)?$/.test(value)),

        tblCmsNotificationSegments: Yup.array().required('This field is required').min(1, 'This field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            notificationName: '',
            notificationTitle: '',
            notificationText: '',
            image: '',
            notificationTime: '',
            redirection: 'Y',
            categoryIcon: '',
            cmsUsecaseId: '',
            repitition: false,
            timePeriod: '',
            frequency: '',
            startDate: '',
            endDate: '',
            tblCmsNotificationAccountLevels: [],
            isHide: 'Y',
            tblCmsNotificationSegments: []
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            // data['notificationTime'] = moment(data?.notificationTime, 'HH:mm').format('hh:mm A');
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        notificationName: data?.notificationName,
                        notificationTitle: data?.notificationTitle,
                        notificationText: data?.notificationText,
                        image: data?.image,
                        notificationTime: data?.notificationTime,
                        redirection: data?.redirection,
                        cmsUsecaseId: data?.cmsUsecaseId,
                        repitition: data?.repitition ? 'Y' : 'N',
                        timePeriod: data?.timePeriod,
                        frequency: data?.frequency,
                        startDate: data?.startDate,
                        endDate: data?.endDate,
                        tblCmsNotificationAccountLevels: data?.tblCmsNotificationAccountLevels,
                        isHide: data?.isHide,
                        tblCmsNotificationSegments: data?.tblCmsNotificationSegments
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const formData = new FormData();
            formData.append('request', JSON.stringify(newData));
            if (selectedFile) {
                formData.append('file', selectedFile);
            }

            await dispatch(handlePostRequest(formData, '/cms/v1/cms/savenotification', true, false, 'notifications'));

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());

    const handledProductIconClick = () => {
        productIconRef.current.click();
    };

    const handlePromotionChange = (event) => {
        setIconLink('');
        setCategoryIconError('');
        formik.setFieldValue('categoryIcon', '');
        setSelectedFile(null);
        const selectedFile = event.target.files[0];
        const fileTypes = ['image'];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setCategoryIconError(error);
            setSelectedFile(null);
        } else if (newFile) {
            setSelectedFile(selectedFile);
            formik.setFieldValue('categoryIcon', selectedFile.name);
            setIconLink(URL.createObjectURL(selectedFile));

            // setSecondSubCategoryIconFileName(selectedFile?.name);
        }
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };



    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="Form__Header Full__Width">
                        <h2>ADD NEW</h2>
                    </div>
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-fluid p-formgrid grid mb-5 col-8">
                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="notificationName" className={classNames({ 'p-error': isFormFieldValid('notificationName') }, 'Label__Text')}>
                                        Name<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="notificationName"
                                        placeholder="Enter name"
                                        name="notificationName"
                                        value={formik?.values?.notificationName || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('notificationName') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('notificationName')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <div className="mb-2">
                                        <label className={classNames({ 'p-error': isFormFieldValid('categoryIcon') }, 'Label__Text')}>
                                            Upload Image
                                            <span className="Label__Required">*</span>
                                        </label>
                                        <div className="file-input-container w-100" onClick={handledProductIconClick}>
                                            <input type="file" accept="image/*" style={{ display: 'none' }} ref={productIconRef} onChange={handlePromotionChange} />
                                            <input
                                                type="text"
                                                className={classNames({ 'p-inputtext p-component p-invalid': isFormFieldValid('categoryIcon') }, 'file-input-field Input__Round')}
                                                placeholder="Choose a file..."
                                                value={formik?.values?.categoryIcon}
                                                readOnly
                                            />
                                            <span className="file-upload-icon">
                                                <i className="pi pi-paperclip" />
                                            </span>
                                        </div>
                                        {categoryIconError ? <small className="p-error">{categoryIconError}</small> : getFormErrorMessage('categoryIcon')}
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="notificationTitle" className={classNames({ 'p-error': isFormFieldValid('notificationTitle') }, 'Label__Text')}>
                                        Notification Title<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="notificationTitle"
                                        placeholder="Enter Notification Title"
                                        name="notificationTitle"
                                        value={formik?.values?.notificationTitle || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('notificationTitle') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('notificationTitle')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="notificationText" className={classNames({ 'p-error': isFormFieldValid('notificationText') }, 'Label__Text')}>
                                        Notification Text<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="notificationText"
                                        placeholder="Enter Notification Text"
                                        name="notificationText"
                                        value={formik?.values?.notificationText || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('notificationText') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('notificationText')}
                                </div>
                            </div>

                            {/* <div className="p-field col-12 md:col-6  pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="notificationTime" className={classNames({ 'p-error': isFormFieldValid('notificationTime') }, 'Label__Text')}>
                                        Time <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="notificationTime"
                                        
                                        placeholder='Enter Time In Minutes'
                                        name="notificationTime"
                                        value={formik?.values?.notificationTime}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('notificationTime') }, 'Input__Round')}
                                    />

                                    {getFormErrorMessage('notificationTime')}
                                </div>
                            </div> */}
                                   <div className="p-field col-12 md:col-6  pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="notificationTime" className={classNames({ 'p-error': isFormFieldValid('notificationTime') }, 'Label__Text')}>
                                        Time
                                    </label>
                                    <InputText id="notificationTime" type="time"
                                        name="notificationTime" value={formik?.values?.notificationTime}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('notificationTime') }, 'Input__Round')} />

                                    {getFormErrorMessage('notificationTime')}
                                </div>
                            </div>
 

                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field ml-3">
                                    <label htmlFor="redirection" className={classNames({ 'p-error': isFormFieldValid('redirection') }, 'Label__Text')}>
                                        Redirection
                                    </label>

                                    <div className="flex">
                                        <div className="mt-2 mr-5">
                                            <RadioButton inputId="applicable" name="redirection" value="Y" onChange={formik.handleChange} checked={formik.values.redirection === 'Y'} />

                                            <label htmlFor="applicable" className={classNames({ 'p-error': isFormFieldValid('applicable') }, 'Label__Text')}>
                                                Yes
                                            </label>
                                        </div>

                                        <div className="mt-2">
                                            <RadioButton inputId="notApplicable" name="redirection" value="N" onChange={formik.handleChange} checked={formik.values.redirection === 'N'} />

                                            <label htmlFor="notApplicable" className={classNames({ 'p-error': isFormFieldValid('notApplicable') }, 'Label__Text')}>
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="cmsUsecaseId" className={classNames({ 'p-error': isFormFieldValid('cmsUsecaseId') }, 'Label__Text')}>
                                        Use Case
                                    </label>
                                    <Dropdown
                                        id="cmsUsecaseId"
                                        placeholder="Select Use Case"
                                        options={useCaseData}
                                        optionLabel="name"
                                        name="cmsUsecaseId"
                                        optionValue="lovId"
                                        value={formik.values.cmsUsecaseId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsUsecaseId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('cmsUsecaseId')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pb-3">
                                <div className="field-checkbox col-12 -mt-4">
                                    <div className="mr-5 flex flex-column col-2">
                                        <label htmlFor="repitition" className={classNames({ 'p-error': isFormFieldValid('repitition') }, 'Label__Text')} style={{}}>
                                            Repetition
                                        </label>
                                        <Checkbox inputId="repitition" name="repitition" checked={formik?.values?.repitition} onChange={formik.handleChange} style={{ marginTop: '10px' }} />{' '}
                                    </div>
                                    <div className="p-field col-12 md:col-9 mt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="timePeriod" className={classNames({ 'p-error': isFormFieldValid('timePeriod') }, 'Label__Text')}>
                                                Time Period
                                            </label>
                                            <Dropdown
                                                id="timePeriod"
                                                placeholder="Select Product Priority"
                                                options={timePeriodData}
                                                optionLabel="name"
                                                name="timePeriod"
                                                optionValue="lovId"
                                                value={formik.values.timePeriod || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('timePeriod') }, 'Dropdown__Round')}
                                            />

                                            {getFormErrorMessage('timePeriod')}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="frequency" className={classNames({ 'p-error': isFormFieldValid('frequency') }, 'Label__Text')}>
                                        Frequency
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="frequency"
                                        placeholder="Enter Frequency"
                                        name="frequency"
                                        value={formik?.values?.frequency?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('frequency') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('frequency')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6">
                                <div className="p-field">
                                    <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') }, 'Label__Text')}>
                                        Start Date Time<span className="Label__Required">*</span>
                                    </label>
                                    <InputText id="startDate" type="date" name="startDate" value={formik?.values?.startDate} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('startDate') }, 'Input__Round')} />

                                    {getFormErrorMessage('startDate')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6">
                                <div className="p-field">
                                    <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') }, 'Label__Text')}>
                                        End Date Time<span className="Label__Required">*</span>
                                    </label>
                                    <InputText id="endDate" type="date" name="endDate" value={formik?.values?.endDate} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('endDate') }, 'Input__Round')} />

                                    {getFormErrorMessage('endDate')}
                                </div>
                            </div>

                            {/* <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="cmsTargetAudienceId" className={classNames({ 'p-error': isFormFieldValid('cmsTargetAudienceId') }, 'Label__Text')}>
                                        Target User Base<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="cmsTargetAudienceId"
                                        placeholder="Select Target User Base"
                                        options={targetAud}
                                        optionLabel="name"
                                        name="cmsTargetAudienceId"
                                        optionValue="lovId"
                                        value={formik.values.cmsTargetAudienceId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsTargetAudienceId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('cmsTargetAudienceId')}
                                </div>
                            </div> */}
                               <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field customMultiselect">
                                    <label htmlFor="tblCmsNotificationAccountLevels" className={classNames({ 'p-error': isFormFieldValid('tblCmsNotificationAccountLevels') }, 'Label__Text')}>
                                        Target User Base<span className="Label__Required">*</span>
                                    </label>
                                    <MultiSelect
                                        id="tblCmsNotificationAccountLevels"
                                        placeholder="Select Target User Base"
                                        options={targetAud}
                                        optionLabel="name"
                                        display="chip"
                                        name="tblCmsNotificationAccountLevels"
                                        optionValue="lovId"
                                        value={formik?.values?.tblCmsNotificationAccountLevels?.map((item) => item.accountLevelId || '')}
                                        onChange={(e) => {
                                       
                                            formik.setFieldValue(
                                                'tblCmsNotificationAccountLevels',
                                                e?.value?.map((lovId) => ({
                                                    accountLevelId: lovId,
                                                    isActive: 'Y'
                                                }))
                                            );
                                        }}
                                        //  value={formik?.values?.tblCmsNotificationAccountLevels}
                                        //  onChange={formik.handleChange}

                                        className={classNames({ 'p-invalid': isFormFieldValid('tblCmsNotificationAccountLevels') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('tblCmsNotificationAccountLevels')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field customMultiselect">
                                    <label htmlFor="tblCmsNotificationSegments" className={classNames({ 'p-error': isFormFieldValid('tblCmsNotificationSegments') }, 'Label__Text')}>
                                        Segments<span className="Label__Required">*</span>
                                    </label>
                                    <MultiSelect
                                        id="tblCmsNotificationSegments"
                                        placeholder="Select Segments"
                                        options={segmentsData}
                                        optionLabel="name"
                                        display="chip"
                                        name="tblCmsNotificationSegments"
                                        optionValue="segmentId"
                                        value={formik?.values?.tblCmsNotificationSegments?.map((segment) => segment.segmentId || '')}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'tblCmsNotificationSegments',
                                                e?.value?.map((lovId) => ({
                                                    segmentId: lovId,
                                                    isActive: 'Y'
                                                }))
                                            );
                                        }}
                                        className={classNames({ 'p-invalid': isFormFieldValid('tblCmsNotificationSegments') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('tblCmsNotificationSegments')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="isHide" className={classNames({ 'p-error': isFormFieldValid('isHide') }, 'Label__Text')}>
                                        Status
                                    </label>
                                    <div className="flex ml-2">
                                        <div className="mt-0 mr-5">
                                            <Checkbox inputId="show" name="isHide" value="Y" checked={formik?.values?.isHide === 'Y'} onChange={(e) => formik.setFieldValue('isHide', 'Y')} style={{ marginTop: '10px' }} />
                                            <label htmlFor="show" className={classNames({ 'p-error': isFormFieldValid('show') }, 'Label__Text')}>
                                                Show
                                            </label>
                                        </div>
                                        <div className="mt-0">
                                            <Checkbox inputId="hide" name="isHide" value="N" checked={formik?.values?.isHide === 'N'} onChange={(e) => formik.setFieldValue('isHide', 'N')} style={{ marginTop: '10px' }} />
                                            <label htmlFor="hide" className={classNames({ 'p-error': isFormFieldValid('hide') }, 'Label__Text')}>
                                                Hide
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3 col-4 flex align-items-start justify-content-center">
                            <MobileView icon={iconLink} title={formik?.values?.notificationTitle} descrition={formik?.values?.notificationText} />
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Submit" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/notifications')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddNotifications;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePutRequest } from '../../../services/PutTemplate';

function AddEditTransactionLimitValidator() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-save');
    // const [parserHeadData, setParserHeadData] = useState([]);

    const dispatch = useDispatch();
    // const navigate = useNavigate();

    //Getting params from URL using hash router

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const ubpId = query.get('ubpId');
    const editable = query.get('editable');

    //Getting ubpcompany data using ubpcompanyId

    const getUbpCompanyById = async () => {
        setloading(true);
        const res = await handleGetRequest(`/offline_Biller/company/getUbpCompanyById/${ubpId}`);
        const keyData = res?.data;
        setloading(false);

        Object.keys(keyData).forEach((key) => {
            if (formik.initialValues.hasOwnProperty(key)) {
                formik.setFieldValue(key, keyData[key]);
            }
        });
        formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
    };

    useEffect(() => {
        if (editable) {
            getUbpCompanyById();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        if (!editable) {
            formik.resetForm();
        }
    }, [editable]); // eslint-disable-line

    const handleCancel = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    // const getParserHeadData = async () => {
    //     const res = await handleGetRequest('/offline_Biller/lov/getAllParserHead');
    //     setParserHeadData(res?.data);
    // };

    // useEffect(() => {
    //     getParserHeadData();
    // }, []);

    const validationSchema = Yup.object().shape({
        accountLevelCode: Yup.string()
            .required('This field is required.')
            .matches(/^[A-Za-z0-9 ]+$/, 'Special Character are not allowed'),
        accountLevelName: Yup.string().required('This Field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            accountLevelCode: '',
            accountLevelName: '',
            accountLevelDescr: '',
            dailyAmtLimitCr: '',
            dailyAmtLimitDr: '',
            dailyTransLimitCr: '',
            dailyTransLimitDr: '',
            maxAmtLimit: '',
            maxAmtPerTxn: '',
            monthlyAmtLimitCr: '',
            monthlyAmtLimitDr: '',
            monthlyTransLimitCr: '',
            monthlyTransLimitDr: '',
            yearlyAmtLimitCr: '',
            yearlyAmtLimitDr: '',
            yearlyTransLimitCr: '',
            yearlyTransLimitDr: '',
            isActive: ''
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            data['userId'] = 1;
            data['isActive'] = data?.isActive ? 'Y' : 'N';
            // formik.setFieldValue('isActive', data?.isActive === 'Y' ? true : false);

            const newData = {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            };

            if (editable) {
                data['ubpCompanyId'] = ubpId;
                const res = await dispatch(handlePutRequest(newData, '/offline_Biller/company/updateUbpCompany', true, true));
                if (res?.responsecode === 1) {
                    formik.resetForm();
                }
            } else {
                const res = await dispatch(handlePostRequest(newData, '/offline_Biller/company/saveUbpCompany', true, true));
                if (res?.responsecode === 1) {
                    formik.resetForm();
                }
            }

            setloading(false);
            setloadingIcon('pi pi-save');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>{editable ? 'Update Transaction Limit Validator' : 'Add Transaction Limit Validator'}</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountLevelCode" className={classNames({ 'p-error': isFormFieldValid('accountLevelCode') }, 'Label__Text')}>
                                    Account Level Code<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="accountLevelCode"
                                    placeholder="Enter Account Level Code"
                                    name="accountLevelCode"
                                    value={formik?.values?.accountLevelCode?.trim()?.toString()}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountLevelCode') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('accountLevelCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountLevelName" className={classNames({ 'p-error': isFormFieldValid('accountLevelName') }, 'Label__Text')}>
                                    Account Level Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="accountLevelName"
                                    placeholder="Enter Limit Description"
                                    name="accountLevelName"
                                    value={formik?.values?.accountLevelName?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountLevelName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('accountLevelName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountLevelDescr" className={classNames({ 'p-error': isFormFieldValid('accountLevelDescr') }, 'Label__Text')}>
                                    Account Level Description<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={500}
                                    id="accountLevelDescr"
                                    placeholder="Enter Limit Description"
                                    name="accountLevelDescr"
                                    value={formik?.values?.accountLevelDescr?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountLevelDescr') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('accountLevelDescr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dailyAmtLimitCr" className={classNames({ 'p-error': isFormFieldValid('dailyAmtLimitCr') }, 'Label__Text')}>
                                    Daily Amount Limit Cr
                                </label>
                                <InputText
                                    id="dailyAmtLimitCr"
                                    maxLength="11"
                                    placeholder="Enter Amount"
                                    name="dailyAmtLimitCr"
                                    value={formik?.values?.dailyAmtLimitCr?.replace(/\D/g, '')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dailyAmtLimitCr') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dailyAmtLimitCr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dailyAmtLimitDr" className={classNames({ 'p-error': isFormFieldValid('dailyAmtLimitDr') }, 'Label__Text')}>
                                    Daily Amount Limit Dr
                                </label>
                                <InputText
                                    id="dailyAmtLimitDr"
                                    maxLength="11"
                                    placeholder="Enter Amount"
                                    name="dailyAmtLimitDr"
                                    value={formik?.values?.dailyAmtLimitDr?.replace(/\D/g, '')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dailyAmtLimitDr') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dailyAmtLimitDr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dailyTransLimitCr" className={classNames({ 'p-error': isFormFieldValid('dailyTransLimitCr') }, 'Label__Text')}>
                                    Daily Transaction Limit Cr
                                </label>
                                <InputText
                                    id="dailyTransLimitCr"
                                    maxLength="11"
                                    placeholder="Enter Transaction"
                                    name="dailyTransLimitCr"
                                    value={formik?.values?.dailyTransLimitCr?.replace(/\D/g, '')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dailyTransLimitCr') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dailyTransLimitCr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dailyTransLimitDr" className={classNames({ 'p-error': isFormFieldValid('dailyTransLimitDr') }, 'Label__Text')}>
                                    Daily Transaction Limit Dr
                                </label>
                                <InputText
                                    id="dailyTransLimitDr"
                                    maxLength="11"
                                    placeholder="Enter Transaction"
                                    name="dailyTransLimitDr"
                                    value={formik?.values?.dailyTransLimitDr?.replace(/\D/g, '')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dailyTransLimitDr') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dailyTransLimitDr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="maxAmtLimit" className={classNames({ 'p-error': isFormFieldValid('maxAmtLimit') }, 'Label__Text')}>
                                    Maximum Amount Limit
                                </label>
                                <InputText
                                    id="maxAmtLimit"
                                    maxLength="11"
                                    placeholder="Enter Maximum Amount Limit"
                                    name="maxAmtLimit"
                                    value={formik?.values?.maxAmtLimit?.replace(/\D/g, '')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('maxAmtLimit') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('maxAmtLimit')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="maxAmtPerTxn" className={classNames({ 'p-error': isFormFieldValid('maxAmtPerTxn') }, 'Label__Text')}>
                                    Maximum Amount Per Transaction
                                </label>
                                <InputText
                                    id="maxAmtPerTxn"
                                    maxLength="11"
                                    placeholder="Enter Maximum Amount"
                                    name="maxAmtPerTxn"
                                    value={formik?.values?.maxAmtPerTxn?.replace(/\D/g, '')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('maxAmtPerTxn') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('maxAmtPerTxn')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="monthlyAmtLimitCr" className={classNames({ 'p-error': isFormFieldValid('monthlyAmtLimitCr') }, 'Label__Text')}>
                                    Monthly Amount Limit Cr
                                </label>
                                <InputText
                                    id="monthlyAmtLimitCr"
                                    maxLength="11"
                                    placeholder="Enter Monthly Amount Limit"
                                    name="monthlyAmtLimitCr"
                                    value={formik?.values?.monthlyAmtLimitCr?.replace(/\D/g, '')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('monthlyAmtLimitCr') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('monthlyAmtLimitCr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="monthlyAmtLimitDr" className={classNames({ 'p-error': isFormFieldValid('monthlyAmtLimitDr') }, 'Label__Text')}>
                                    Monthly Amount Limit Dr
                                </label>
                                <InputText
                                    id="monthlyAmtLimitDr"
                                    maxLength="11"
                                    placeholder="Enter Monthly Amount Limit"
                                    name="monthlyAmtLimitDr"
                                    value={formik?.values?.monthlyAmtLimitDr?.replace(/\D/g, '')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('monthlyAmtLimitDr') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('monthlyAmtLimitDr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="monthlyTransLimitCr" className={classNames({ 'p-error': isFormFieldValid('monthlyTransLimitCr') }, 'Label__Text')}>
                                    Monthly Transaction Limit Cr
                                </label>
                                <InputText
                                    id="monthlyTransLimitCr"
                                    maxLength="11"
                                    placeholder="Enter Transaction Limit"
                                    name="monthlyTransLimitCr"
                                    value={formik?.values?.monthlyTransLimitCr?.replace(/\D/g, '')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('monthlyTransLimitCr') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('monthlyTransLimitCr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="monthlyTransLimitDr" className={classNames({ 'p-error': isFormFieldValid('monthlyTransLimitDr') }, 'Label__Text')}>
                                    Monthly Transaction Limit Dr
                                </label>
                                <InputText
                                    id="monthlyTransLimitDr"
                                    maxLength="11"
                                    placeholder="Enter Transaction Limit"
                                    name="monthlyTransLimitDr"
                                    value={formik?.values?.monthlyTransLimitDr?.replace(/\D/g, '')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('monthlyTransLimitDr') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('monthlyTransLimitDr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="yearlyAmtLimitCr" className={classNames({ 'p-error': isFormFieldValid('yearlyAmtLimitCr') }, 'Label__Text')}>
                                    Yearly Amount Limit Cr
                                </label>
                                <InputText
                                    id="yearlyAmtLimitCr"
                                    maxLength="11"
                                    placeholder="Enter Yearly Amount Limit"
                                    name="yearlyAmtLimitCr"
                                    value={formik?.values?.yearlyAmtLimitCr?.replace(/\D/g, '')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('yearlyAmtLimitCr') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('yearlyAmtLimitCr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="yearlyAmtLimitDr" className={classNames({ 'p-error': isFormFieldValid('yearlyAmtLimitDr') }, 'Label__Text')}>
                                    Yearly Amount Limit Dr
                                </label>
                                <InputText
                                    id="yearlyAmtLimitDr"
                                    maxLength="11"
                                    placeholder="Enter Yearly Amount Limit"
                                    name="yearlyAmtLimitDr"
                                    value={formik?.values?.yearlyAmtLimitDr?.replace(/\D/g, '')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('yearlyAmtLimitDr') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('yearlyAmtLimitDr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="yearlyTransLimitCr" className={classNames({ 'p-error': isFormFieldValid('yearlyTransLimitCr') }, 'Label__Text')}>
                                    Yearly Transaction Limit Cr
                                </label>
                                <InputText
                                    id="yearlyTransLimitCr"
                                    maxLength="11"
                                    placeholder="Enter Yearly Amount Limit"
                                    name="yearlyTransLimitCr"
                                    value={formik?.values?.yearlyTransLimitCr?.replace(/\D/g, '')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitCr') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('yearlyTransLimitCr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="yearlyTransLimitDr" className={classNames({ 'p-error': isFormFieldValid('yearlyTransLimitDr') }, 'Label__Text')}>
                                    Yearly Transaction Limit Dr
                                </label>
                                <InputText
                                    id="yearlyTransLimitDr"
                                    maxLength="11"
                                    placeholder="Enter Yearly Amount Limit"
                                    name="yearlyTransLimitDr"
                                    value={formik?.values?.yearlyTransLimitDr?.replace(/\D/g, '')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitDr') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('yearlyTransLimitDr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                    Is Active
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isActive" name="isActive" value={formik.values.isActive} checked={formik.values.isActive} onChange={formik.handleChange} />
                                </div>
                                {getFormErrorMessage('isActive')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label="Cancel" disabled={loading} className="Btn__Transparent" onClick={(e) => handleCancel(e)} />
                        <Button label={editable ? 'Update' : 'Add'} icon={loadingIcon || ''} iconPos="right" className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddEditTransactionLimitValidator;

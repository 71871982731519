import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import SecuredFileChange from '../../../components/SecuredFileChange';
import ColorPicker from 'react-pick-color';

function EditAgentMateMainCategory() {
    const [showColorPicker, setShowColorPicker] = useState({ textColor: false, bgColor: false });
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileError, setSelectedFileError] = useState(null);
    const [filePath, setFilePath] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const mainCategoryRef = useRef(null);

    const location = useLocation();
    const { rowData } = location.state || {};

    const validationSchema = Yup.object().shape({
        categoryName: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .required('This field is required')
            .matches(/^[a-zA-Z0-9\s]*$/, 'Category Name must be alphanumeric'),

        displayNameEnglist: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .required('This field is required')
            .matches(/^[a-zA-Z0-9\s]*$/, 'Display Name English must be alphanumeric'),
        displayNameUrdu: Yup.string()
            .required('This field is required')
            .matches(/^[a-zA-Z0-9\s]*$/, 'Display Name Urdu must be alphanumeric')
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed'),
        categoryNumber: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .required('This field is required')
            .matches(/^[0-9]+$/, 'Category Number must contain only numeric values'),
        categoryIcon: Yup.string().required('This field is required'),
        cmsTagLabelId: Yup.string().when('addCheck', {
            is: true,
            then: Yup.string()
                .required('This field is required')
                .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
                .matches(/^[a-zA-Z0-9]+$/, 'Tag Label must be alphanumeric')
        }),
        cmsTagTextColorId: Yup.string().when('addCheck', {
            is: true,
            then: Yup.string().required('This field is required')
        }),

        cmsTagTextBgColorId: Yup.string().when('addCheck', {
            is: true,
            then: Yup.string().required('This field is required')
        })
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cmsMainCategoryId: '',
            categoryName: '',
            displayNameEnglist: '',
            displayNameUrdu: '',
            categoryNumber: '',
            categoryIcon: '',
            cmsTagLabelId: '',
            cmsTagTextColorId: '',
            cmsTagTextBgColorId: '',
            tagExpiryDate: '',
            addCheck: false,
            isHide: '',
            isActive: ''
        },

        onSubmit: async (data) => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        cmsMainCategoryId: data?.cmsMainCategoryId,
                        categoryName: data?.categoryName,
                        displayNameEnglist: data?.displayNameEnglist,
                        displayNameUrdu: data?.displayNameUrdu,
                        categoryNumber: data?.categoryNumber,
                        categoryIcon: filePath || '',
                        cmsTagLabelId: data?.addCheck ? data?.cmsTagLabelId : '',
                        cmsTagTextColorId: data?.addCheck ? data?.cmsTagTextColorId : '',
                        cmsTagTextBgColorId: data?.addCheck ? data?.cmsTagTextBgColorId : '',
                        tagExpiryDate: data?.tagExpiryDate,
                        isHide: data?.isHide,
                        isActive: data?.isActive
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const formData = new FormData();

            if (selectedFile) {
                delete newData?.payLoad?.categoryIcon;
                formData.append('file', selectedFile);
            }
            formData.append('request', JSON.stringify(newData));
            await dispatch(handlePostRequest(formData, '/agentmatecms/v1/agentmatecms/updatecategory', true, false, 'agentmatemaincategory'));
        }
    });

    const getMainCategoryData = async () => {
        const res = await handleGetRequest(`/agentmatecms/v1/agentmatecms/getcategorybyid/${rowData?.cmsMainCategoryId}`);
        const keyData = res?.payLoad;

        formik.setFieldValue('cmsMainCategoryId', rowData?.cmsMainCategoryId || '');
        formik.setFieldValue('categoryName', keyData?.categoryName || '');
        formik.setFieldValue('displayNameEnglist', keyData?.displayNameEnglist || '');
        formik.setFieldValue('displayNameUrdu', keyData?.displayNameUrdu || '');
        formik.setFieldValue('categoryNumber', keyData?.categoryNumber || '');
        formik.setFieldValue('addCheck', keyData?.cmsTagLabelId || keyData?.cmsTagTextColorId || keyData?.cmsTagTextBgColorId ? true : false);
        formik.setFieldValue('cmsTagLabelId', keyData?.cmsTagLabelId || '');
        formik.setFieldValue('cmsTagTextColorId', keyData?.cmsTagTextColorId || '');
        formik.setFieldValue('cmsTagTextBgColorId', keyData?.cmsTagTextBgColorId || '');
        const correctEndDate = formattedDate(keyData.tagExpiryDate || '');
        formik.setFieldValue('tagExpiryDate', correctEndDate || '');
        formik.setFieldValue('isHide', keyData?.isHide || '');
        formik.setFieldValue('isActive', keyData?.isActive || '');
        const pathParts = keyData?.categoryIcon?.split('/');
        const categoryIcon = pathParts[pathParts?.length - 1];
        formik.setFieldValue('categoryIcon', categoryIcon);
        setFilePath(keyData?.categoryIcon);
    };

    useEffect(() => {
        if (rowData) getMainCategoryData();
    }, [rowData]); // eslint-disable-line

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const handleMainCategoryIcon = () => {
        mainCategoryRef.current.click();
    };

    const handleMainCategoryChange = (event) => {
        setSelectedFile(null);
        formik.setFieldValue('categoryIcon', '');
        setSelectedFileError('');
        const selectedFile = event.target.files[0];
        const fileTypes = ['image'];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setSelectedFileError(error);
        } else if (newFile) {
            setSelectedFile(newFile);
            formik.setFieldValue('categoryIcon', newFile?.name);
        }
    };

    function formattedDate(originalDate) {
        const createDate = new Date(originalDate);

        const year = createDate.getFullYear();
        const month = (createDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const day = createDate.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const getContrastColor = (hexColor) => {
        const r = parseInt(hexColor.substr(1, 2), 16);
        const g = parseInt(hexColor.substr(3, 2), 16);
        const b = parseInt(hexColor.substr(5, 2), 16);
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    };
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>EDIT DETAIL</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="categoryName" className={classNames({ 'p-error': isFormFieldValid('categoryName') }, 'Label__Text')}>
                                    Category Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="categoryName"
                                    placeholder="Enter category name"
                                    name="categoryName"
                                    value={formik?.values?.categoryName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('categoryName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('categoryName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="displayNameEnglist" className={classNames({ 'p-error': isFormFieldValid('displayNameEnglist') }, 'Label__Text')}>
                                    Display Name (English)<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="displayNameEnglist"
                                    placeholder="Enter display name"
                                    name="displayNameEnglist"
                                    value={formik?.values?.displayNameEnglist || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('displayNameEnglist') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('displayNameEnglist')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="displayNameUrdu" className={classNames({ 'p-error': isFormFieldValid('displayNameUrdu') }, 'Label__Text')}>
                                    Display Name (Urdu)<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="displayNameUrdu"
                                    placeholder="Enter display name"
                                    name="displayNameUrdu"
                                    value={formik?.values?.displayNameUrdu || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('displayNameUrdu') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('displayNameUrdu')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="categoryNumber" className={classNames({ 'p-error': isFormFieldValid('categoryNumber') }, 'Label__Text')}>
                                    Category Number <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="categoryNumber"
                                    placeholder="Enter product ID"
                                    name="categoryNumber"
                                    keyfilter="int"
                                    value={formik?.values?.categoryNumber || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('categoryNumber') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('categoryNumber')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <div className="mb-2">
                                    <label className={classNames({ 'p-error': isFormFieldValid('categoryIcon') }, 'Label__Text')}>
                                        Category Icon <span className="Label__Required">*</span>
                                    </label>
                                    <div className="file-input-container w-100" onClick={handleMainCategoryIcon}>
                                        <input type="file" accept="image/*" style={{ display: 'none' }} ref={mainCategoryRef} onChange={handleMainCategoryChange} />
                                        <input
                                            type="text"
                                            className={classNames({ 'p-inputtext p-component p-invalid': isFormFieldValid('categoryIcon') }, 'file-input-field Input__Round')}
                                            placeholder="Choose a file..."
                                            value={formik.values.categoryIcon}
                                            readOnly
                                        />
                                        <span className="file-upload-icon">
                                            <i className="pi pi-paperclip" />
                                        </span>
                                    </div>
                                    {selectedFileError ? <small className="p-error">{selectedFileError}</small> : getFormErrorMessage('categoryIcon')}
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-1 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="addCheck" className={classNames({ 'p-error': isFormFieldValid('addCheck') }, 'Label__Text')}>
                                    Add Tag
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="addCheck" name="addCheck" checked={formik?.values?.addCheck} onChange={formik.handleChange} style={{ marginTop: '10px' }} />{' '}
                                </div>
                                {getFormErrorMessage('addCheck')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsTagLabelId" className={classNames({ 'p-error': isFormFieldValid('cmsTagLabelId') }, 'Label__Text')}>
                                    Tag Label
                                </label>
                                <InputText
                                    id="cmsTagLabelId"
                                    placeholder="Enter Tag Label"
                                    name="cmsTagLabelId"
                                    disabled={!formik?.values?.addCheck}
                                    value={formik?.values?.cmsTagLabelId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagLabelId') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('cmsTagLabelId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsTagTextColorId" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextColorId') }, 'Label__Text')}>
                                    Tag Text Color
                                </label>
                                <InputText
                                    value={formik?.values?.cmsTagTextColorId || '#FFFFFF'}
                                    onChange={(e) => {
                                        formik.setFieldValue('cmsTagTextColorId', e.target.value);
                                    }}
                                    onClick={() => setShowColorPicker({ textColor: true, bgColor: false })}
                                    disabled={!formik?.values?.addCheck}
                                    readOnly
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextColorId') }, 'Input__Round')}
                                    style={{
                                        backgroundColor: formik?.values?.cmsTagTextColorId || '#FFFFFF',
                                        color: getContrastColor(formik?.values?.cmsTagTextColorId || '#FFFFFF')
                                    }}
                                />

                                {getFormErrorMessage('cmsTagTextColorId')}
                                {showColorPicker?.textColor && (
                                    <div className="colorPickerForInput">
                                        <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                        <ColorPicker color={formik?.values?.cmsTagTextColorId || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagTextColorId', e?.hex)} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsTagTextBgColorId" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextBgColorId') }, 'Label__Text')}>
                                    Tag Back Ground Color
                                </label>
                                <InputText
                                    value={formik?.values?.cmsTagTextBgColorId || '#FFFFFF'}
                                    onChange={(e) => {
                                        formik.setFieldValue('cmsTagTextBgColorId', e.target.value);
                                    }}
                                    onClick={() => setShowColorPicker({ textColor: false, bgColor: true })}
                                    disabled={!formik?.values?.addCheck}
                                    readOnly
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextBgColorId') }, 'Input__Round')}
                                    style={{
                                        backgroundColor: formik?.values?.cmsTagTextBgColorId || '#FFFFFF',
                                        color: getContrastColor(formik?.values?.cmsTagTextBgColorId || '#FFFFFF')
                                    }}
                                />

                                {getFormErrorMessage('cmsTagTextBgColorId')}
                                {showColorPicker?.bgColor && (
                                    <div className="colorPickerForInput">
                                        <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                        <ColorPicker color={formik?.values?.cmsTagTextBgColorId || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagTextBgColorId', e?.hex)} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="tagExpiryDate" className={classNames({ 'p-error': isFormFieldValid('tagExpiryDate') }, 'Label__Text')}>
                                    Tag Expiry Date
                                </label>
                                <InputText
                                    min={previousDate}
                                    type="date"
                                    id="tagExpiryDate"
                                    name="tagExpiryDate"
                                    value={formik?.values?.tagExpiryDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('tagExpiryDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('tagExpiryDate')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-2 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isHide" className={classNames({ 'p-error': isFormFieldValid('isHide') }, 'Label__Text')}>
                                    Status
                                </label>
                                <div className="flex ml-2">
                                    <div className="mt-4 mr-5">
                                        <Checkbox inputId="show" name="isHide" value="Y" checked={formik?.values?.isHide === 'Y'} onChange={(e) => formik.setFieldValue('isHide', 'Y')} style={{ marginTop: '10px' }} />
                                        <label htmlFor="show" className={classNames({ 'p-error': isFormFieldValid('show') }, 'Label__Text')}>
                                            Show
                                        </label>
                                    </div>
                                    <div className="mt-4">
                                        <Checkbox inputId="hide" name="isHide" value="N" checked={formik?.values?.isHide === 'N'} onChange={(e) => formik.setFieldValue('isHide', 'N')} style={{ marginTop: '10px' }} />
                                        <label htmlFor="hide" className={classNames({ 'p-error': isFormFieldValid('hide') }, 'Label__Text')}>
                                            Hide
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="submit" label="Update" className="Btn__Dark" />
                        <Button onClick={() => navigate('/agentmatemaincategory')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default EditAgentMateMainCategory;

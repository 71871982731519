import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

const ViewChartOfAccounts = () => {
    const location = useLocation()
    const { rowData } = location.state || {};

    const glCodeCombinationId = rowData?.glCodeCombinationId;
    const tableName = 'TBL_GL_CODE_COMBINATIONS';

    const [organizationLov, setOrganizationLov] = useState([])
    const [locationLov, setLocationLov] = useState([])
    const [costCenterLov, setCostCenterLov] = useState([])
    const [categoryLov, setCategoryLov] = useState([])
    const [subCategoryLov, setSubCategoryLov] = useState([])
    const [currencyLov, setCurrencyLov] = useState([])
    const [mrpLov, setMrpLov] = useState([])

    const navigate = useNavigate()

    function navBack() {
        navigate(-1)
    }

    const formik = useFormik({
        initialValues: {
            organization: '',
            location: '',
            costCenter: '',
            category: '',
            subCategory: '',
            currency: '',
            mrp: '',
        }
    });

    const getOrganizationLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_ORGANIZATION');
        setOrganizationLov(res?.payLoad);
    };

    const getLocationLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_BRANCH');
        setLocationLov(res?.payLoad);
    };

    const getCostCenterLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_COST_CENTER');
        setCostCenterLov(res?.payLoad);
    };

    const getCategoryLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_GL_TYPE');
        setCategoryLov(res?.payLoad);
    };

    const getSubCategoryLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_SUB_CATEGORY');
        setSubCategoryLov(res?.payLoad);
    };

    const getCurrencyLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_CURRENCY');
        setCurrencyLov(res?.payLoad);
    };

    const getMrpLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchartofaccountdata/LKP_MRP_CODE');
        setMrpLov(res?.payLoad);
    };

    useEffect(() => {
        getOrganizationLov()
        getLocationLov()
        getCostCenterLov()
        getCategoryLov()
        getSubCategoryLov()
        getCurrencyLov()
        getMrpLov()
    }, []);

    useEffect(() => {
        if (rowData && organizationLov && locationLov && costCenterLov && categoryLov && subCategoryLov && currencyLov && mrpLov) {
            const orgLovId = organizationLov?.find((item) => item?.code === rowData?.glSegmentCode1);
            formik.setFieldValue("organization", orgLovId && orgLovId?.lovId)
            const locLovId = locationLov?.find((item) => item?.code === rowData?.glSegmentCode2);
            formik.setFieldValue("location", locLovId && locLovId?.lovId)
            const costCenterLovId = costCenterLov?.find((item) => item?.code === rowData?.glSegmentCode3);
            formik.setFieldValue("costCenter", costCenterLovId && costCenterLovId?.lovId)
            const categoryLovId = categoryLov?.find((item) => item?.code === rowData?.glSegmentCode4);
            formik.setFieldValue("category", categoryLovId && categoryLovId?.lovId)
            const subCategoryLovId = subCategoryLov?.find((item) => item?.code === rowData?.glSegmentCode5);
            formik.setFieldValue("subCategory", subCategoryLovId && subCategoryLovId?.lovId)
            const currencyLovId = currencyLov?.find((item) => item?.code === rowData?.glSegmentCode6);
            formik.setFieldValue("currency", currencyLovId && currencyLovId?.lovId)
            const mrpLovId = mrpLov.find((item) => item?.code === rowData?.glSegmentCode7);
            formik.setFieldValue("mrp", mrpLovId && mrpLovId?.lovId)
        }
    }, [rowData, organizationLov, locationLov, costCenterLov, categoryLov, subCategoryLov, currencyLov, mrpLov]);

    return (
        <>
            <div className='card Card__Round'>

                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid grid mb-5">

                        <div className="p-field col-12 md:col-3">
                            <label className='Label__Text'>
                                Organization
                            </label>
                            <Dropdown
                                id="organization"
                                placeholder="Select Filter"
                                options={organizationLov}
                                optionLabel="name"
                                optionValue="lovId"
                                value={formik.values.organization ?? ''}
                                onChange={formik.handleChange}
                                className='Dropdown__Round'
                            />
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className='Label__Text'>
                                Location
                            </label>
                            <Dropdown
                                id="location"
                                placeholder="Select Filter"
                                options={locationLov}
                                optionLabel="name"
                                optionValue="lovId"
                                value={formik.values.location ?? ''}
                                onChange={formik.handleChange}
                                className='Dropdown__Round'
                            />
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className='Label__Text'>
                                Cost Center
                            </label>
                            <Dropdown
                                id="costCenter"
                                placeholder="Select Filter"
                                options={costCenterLov}
                                optionLabel="name"
                                optionValue="lovId"
                                value={formik.values.costCenter ?? ''}
                                onChange={formik.handleChange}
                                className='Dropdown__Round'
                            />
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className='Label__Text'>
                                Category
                            </label>
                            <Dropdown
                                id="category"
                                placeholder="Select Filter"
                                options={categoryLov}
                                optionLabel="name"
                                optionValue="lovId"
                                value={formik.values.category ?? ''}
                                onChange={formik.handleChange}
                                className='Dropdown__Round'
                            />
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className='Label__Text'>
                                Sub Category
                            </label>
                            <Dropdown
                                id="subCategory"
                                placeholder="Select Filter"
                                options={subCategoryLov}
                                optionLabel="name"
                                optionValue="lovId"
                                value={formik.values.subCategory ?? ''}
                                onChange={formik.handleChange}
                                className='Dropdown__Round'
                            />
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className='Label__Text'>
                                Currency
                            </label>
                            <Dropdown
                                id="currency"
                                placeholder="Select Filter"
                                options={currencyLov}
                                optionLabel="name"
                                optionValue="lovId"
                                value={formik.values.currency ?? ''}
                                onChange={formik.handleChange}
                                className='Dropdown__Round'
                            />
                        </div>

                        <div className="p-field col-12 md:col-3">
                            <label className='Label__Text'>
                                MRP Code
                            </label>
                            <Dropdown
                                id="mrp"
                                placeholder="Select Filter"
                                options={mrpLov}
                                optionLabel="name"
                                optionValue="lovId"
                                value={formik.values.mrp ?? ''}
                                onChange={formik.handleChange}
                                className='Dropdown__Round'
                            />
                        </div>

                    </div>

                    <div className="Down__Btn">
                        <Button type="submit" onClick={navBack} label="Okay" className="Btn__Dark" />
                    </div>

                </form>

            </div>

            <AuthorizationHistoryTable refTableId={glCodeCombinationId} tableName={tableName} />
        </>
    )
}

export default ViewChartOfAccounts
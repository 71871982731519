import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Steps } from 'primereact/steps';
import ViewProductsChecker from './Components/Product/ViewProductChecker';
import ViewPricingChecker from './Components/Pricing/ViewPricingChecker';
import ViewDocumentChecker from './Components/Document/ViewDocumentChecker';
import ViewTermsAndConditionsChecker from './Components/Terms&Conditiom/ViewTermsAndCondition';
import ViewRepaymentChecker from './Components/RepaymentMethod/ViewRepaymentChecker';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { loadingAction } from '../../../../redux/actions/loadingAction';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewLendingManagementChecker() {
    const [loading, setloading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [lendingData, setLendingData] = useState();
    const [lendingDataU, setLendingDataU] = useState([]);

    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const requestTypeDescr = query.get('requestTypeDescr');
    const mcPeindingRequestId = query.get('mcPeindingRequestId');
    const tableName = query.get('tableName');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getLendingDataById = async () => {
        dispatch(loadingAction(true));
        const response = await handleGetRequest(`/lending/v1/lending/getlmsproductbyid/${refTableId}`);

        setLendingData(response?.payLoad);
        dispatch(loadingAction(false));
    };

    const getMcRequestDataById = async () => {
        setloading(true);
        dispatch(loadingAction(true));
        const res = await handleGetRequest(`/lending/v1/lending/getmcrequestLmsProductbyid/${mcRequestId}`);
        setLendingData(res?.payLoad);
        dispatch(loadingAction(false));
        setloading(false);
    };

    const getMcRequestDataByIdUByInActive = async () => {
        dispatch(loadingAction(true));
        setloading(true);
        const res = await handleGetRequest(`/lending/v1/lending/getmcrequestLmsProductbyid/${mcRequestId}`);
        setLendingDataU(res?.payLoad);
        setloading(false);
        dispatch(loadingAction(false));
    };

    useEffect(() => {
        if (requestType === 'I') {
            getLendingDataById();
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getMcRequestDataById();
        } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
            getLendingDataById();
            getMcRequestDataByIdUByInActive();
        }
    }, []); // eslint-disable-line

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (data) => {
            setloading(true);

            const newDataForI = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        lmsProductRequestList: [
                            {
                                lmsProductId: parseInt(refTableId),
                                isActive: 'Y',
                                checkerRequestType: requestType
                            }
                        ],
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const newDataForU = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        lmsProductRequestList: requestType === 'U' && requestTypeDescr === 'Enable/Disable Request' ? lendingDataU : lendingData,
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(requestType === 'I' ? newDataForI : newDataForU, '/lending/v1/lending/lmsproductcheckeraction', true, false, 'requestchecker'));
            if (res?.responseCode === process.env.REACT_APP_LENDING_SUCCESS_CODE) {
                formik.resetForm();
            }

            setloading(false);
        }
    });

    const items = [
        {
            label: 'Product',
            command: (e) => handleStepClick(e.index)
            // disabled: requestType === 'I' ? lendingData?.currentStep >= 0 ? false : true : lendingData[0]?.stepNo >= 0 ? false : true
        },
        {
            label: 'Pricing',
            command: (e) => handleStepClick(e.index)
            // disabled: requestType === 'I' ? lendingData?.currentStep >= 0 ? false : true : lendingData[0]?.stepNo >= 0 ? false : true
        },
        {
            label: 'Repayment Methods',
            command: (e) => handleStepClick(e.index)
            // disabled: requestType === 'I' ? lendingData?.currentStep >= 0 ? false : true : lendingData[0]?.stepNo >= 0 ? false : true
        },
        {
            label: 'Document',
            command: (e) => handleStepClick(e.index)
            // disabled: requestType === 'I' ? lendingData?.currentStep >= 0 ? false : true : lendingData[0]?.stepNo >= 0 ? false : true
        },
        {
            label: 'Terms & Condition',
            command: (e) => handleStepClick(e.index)
            // disabled: requestType === 'I' ? lendingData?.currentStep >= 0 ? false : true : lendingData[0]?.stepNo >= 0 ? false : true
        }
    ];

    const handleStepClick = (index) => {
        setActiveIndex(index);
    };

    return (
        <>
            <div className="card Card__Round">
                <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} style={{ margin: '50px 0px', padding: '50px 0px' }} className="CustomerRisk__Steps" />
                {/* {activeIndex === 0 && <ViewProductsChecker handleFacilityData={handleFacilityData} activeIndex={activeIndex} handleStepClick={handleStepClick} getLendingId={getLendingId} lendingData={lendingData} />} */}
                {activeIndex === 0 && <ViewProductsChecker lendingData={lendingData} />}
                {activeIndex === 1 && <ViewPricingChecker lendingData={lendingData} />}
                {activeIndex === 2 && <ViewRepaymentChecker lendingData={lendingData} requestType={requestType} />}
                {activeIndex === 3 && <ViewDocumentChecker lendingData={lendingData} />}
                {activeIndex === 4 && <ViewTermsAndConditionsChecker lendingData={lendingData} />}

                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {/* {activeIndex === 5 && allStepsComplete && ( */}
                    <div className="Down__Btn">
                        <Button disabled={loading} label="Okay" className="Btn__Dark" onClick={() => navigate('/requestchecker')} />
                        {/* <Button disabled={loading} onClick={() => navigate('/lendingmanagement')} label="Cancel" className="Btn__Transparent" /> */}
                    </div>
                    {/* )} */}
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default ViewLendingManagementChecker;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { handlePostRequest } from '../../../../services/PostTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

const ViewOrganization = () => {
    const [loading, setloading] = useState(false);
    const location = useLocation();
    const { rowData } = location.state || {};

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const organizationId = rowData?.organizationId;
    const tableName = 'LKP_ORGANIZATION';

    const validationSchema = Yup.object().shape({
        lkpOrganizationName: Yup.string().required('This field is required'),
        lkpOrganizationDescription: Yup.string().required('This field is required'),
        lkpOrganizationCode: Yup.string().required('This field is required'),
        coaCode: Yup.string().required('This field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            lkpOrganizationName: '',
            lkpOrganizationDescription: '',
            lkpOrganizationCode: '',
            coaCode: ''
        },

        onSubmit: async (data) => {
            const coaPayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        lkpOrganizationId: rowData?.organizationId,
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(coaPayload, '/account/v1/chartofaccount/updatelkporganization', true, false));
            if (res?.responseCode === '010000') {
                navigate(-1);
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        if (rowData) {
            formik.setFieldValue('lkpOrganizationName', rowData?.organizationName);
            formik.setFieldValue('lkpOrganizationDescription', rowData?.organizationDescr);
            formik.setFieldValue('lkpOrganizationCode', rowData?.organizationCode);
            formik.setFieldValue('coaCode', rowData?.coaCode);
        }
    }, [rowData]);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>View Details</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Organization Name</label>
                                <InputText
                                    id="lkpOrganizationName"
                                    value={formik.values.lkpOrganizationName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpOrganizationName') }, 'Input__Round')}
                                    maxLength={30}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpOrganizationName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Organization Description</label>
                                <InputText
                                    id="lkpOrganizationDescription"
                                    value={formik.values.lkpOrganizationDescription || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpOrganizationDescription') }, 'Input__Round')}
                                    maxLength={199}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpOrganizationDescription')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Organization Code</label>
                                <InputText
                                    id="lkpOrganizationCode"
                                    name="lkpOrganizationCode"
                                    value={formik.values.lkpOrganizationCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpOrganizationCode') }, 'Input__Round')}
                                    maxLength={30}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpOrganizationCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Coa Code</label>
                                <InputText id="coaCode" value={formik.values.coaCode || ''} onChange={formik.handleChange} disabled={true} className={classNames({ 'p-invalid': isFormFieldValid('coaCode') }, 'Input__Round')} maxLength={30} />
                                {getFormErrorMessage('coaCode')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} onClick={() => navigate('/organization')} label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={organizationId} tableName={tableName} />
        </>
    );
};

export default ViewOrganization;

import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
// import EditBulletLoanChecker from './EditBulletLoanChecker';
// import EditTermLoanChecker from './EditTermLoanChecker';
// import EditRunningFinanceChecker from './EditRunningFinanceChecker';

import ViewBulletLoanChecker from './ViewBulletLoanChecker';
import ViewTermLoanChecker from './ViewTermLoanChecker';
import ViewRunningFinanceChecker from './ViewRunningFinanceChecker';

function ViewRepaymentChecker({ activeIndex, handleStepClick, lendingData, requestType }) {
    // const [loading, setloading] = useState(false);
    // const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    // const [frequencyPeriodLov, setFrequencyPeriodLov] = useState([]);
    // const [fieldTableData, setFieldTableData] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const requestTypeDescr = query.get('requestTypeDescr');

    return (
        <>
            {requestType === 'I' || (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') ? (
                lendingData?.lkpLmsFacilityType?.lmsFacilityTypeCode === 'BL' ? (
                    <ViewBulletLoanChecker handleStepClick={handleStepClick} activeIndex={activeIndex} lendingData={lendingData} />
                ) : lendingData?.lkpLmsFacilityType?.lmsFacilityTypeCode === 'TL' ? (
                    <ViewTermLoanChecker handleStepClick={handleStepClick} activeIndex={activeIndex} lendingData={lendingData} />
                ) : lendingData?.lkpLmsFacilityType?.lmsFacilityTypeCode === 'RF' ? (
                    <ViewRunningFinanceChecker handleStepClick={handleStepClick} activeIndex={activeIndex} lendingData={lendingData} />
                ) : null
            ) : lendingData[0]?.facilityCode === 'BL' ? (
                <ViewBulletLoanChecker handleStepClick={handleStepClick} activeIndex={activeIndex} lendingData={lendingData} />
            ) : lendingData[0]?.facilityCode === 'TL' ? (
                <ViewTermLoanChecker handleStepClick={handleStepClick} activeIndex={activeIndex} lendingData={lendingData} />
            ) : lendingData[0]?.facilityCode === 'RF' ? (
                <ViewRunningFinanceChecker handleStepClick={handleStepClick} activeIndex={activeIndex} lendingData={lendingData} />
            ) : null}
        </>
    );
}
export default ViewRepaymentChecker;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
// import { Checkbox } from 'primereact/checkbox';
// import { InputNumber } from 'primereact/inputnumber';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePutRequest } from '../../../../services/PutTemplate';
import { Checkbox } from 'primereact/checkbox';

function AddFeeShareConfiguration() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-save');
    // const [parserHeadData, setParserHeadData] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    //Getting params from URL using hash router

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const ubpId = query.get('ubpId');
    const editable = query.get('editable');

    //Getting ubpcompany data using ubpcompanyId

    const getUbpCompanyById = async () => {
        setloading(true);
        const res = await handleGetRequest(`/offline_Biller/company/getUbpCompanyById/${ubpId}`);
        const keyData = res?.data;
        setloading(false);

        Object.keys(keyData).forEach((key) => {
            if (formik.initialValues.hasOwnProperty(key)) {
                formik.setFieldValue(key, keyData[key]);
            }
        });
        formik.setFieldValue('isFEDApplicable', keyData?.isFEDApplicable === 'Y' ? true : false);
    };

    useEffect(() => {
        if (editable) {
            getUbpCompanyById();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        if (!editable) {
            formik.resetForm();
        }
    }, [editable]); // eslint-disable-line

    const handleCancel = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    // const getParserHeadData = async () => {
    //     const res = await handleGetRequest('/offline_Biller/lov/getAllParserHead');
    //     setParserHeadData(res?.data);
    // };

    // useEffect(() => {
    //     getParserHeadData();
    // }, []);

    const productJson = [
        { description: 'Cash In' },
        { description: 'Cash out' },
        { description: 'Wallet to Cnic' },
        { description: 'Send Money' },
        { description: 'Wallet to JS Bank Account' },
        { description: 'Cnic to Wallet' },
        { description: 'Retail Payment' }
    ];

    const agentNetworkJson = [
        { description: 'Branch Teller Network' },
        { description: 'JSBL LSA Network' },
        { description: 'JSBL internal agent network' },
        { description: 'Default Network' },
        { description: 'Bayfir' },
        { description: 'WebService Agent Network' },
        { description: 'SCO AJK' }
    ];
    const segmentJson = [{ description: 'BLB payroll' }, { description: 'DFS payroll' }, { description: 'Non DFS payroll' }, { description: 'EB payroll' }, { description: 'EB and DFS payroll' }];
    const channelJson = [{ description: 'Customer Mobile App' }, { description: 'ATM' }, { description: 'Web' }, { description: 'Kannel' }, { description: 'Mobile App' }, { description: 'USSD' }, { description: 'Backend Services' }];

    const chargesJson = [{ description: 'Bank Fee' }, { description: 'Agent Commission' }, { description: 'Partner Charges' }];

    const validationSchema = Yup.object().shape({
        product: Yup.string().required('This Field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            product: '',
            agentNetwork: '',
            segment: '',
            channel: '',
            charges: '',
            chargesShare: '',
            isWHTApplicable: '',
            isFEDApplicable: ''
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            data['userId'] = 1;
            data['isWHTApplicable'] = data?.isWHTApplicable ? '1' : '0';
            formik.setFieldValue('isWHTApplicable', data?.isWHTApplicable === 'Y' ? true : false);

            data['isFEDApplicable'] = data?.isFEDApplicable ? '1' : '0';
            formik.setFieldValue('isFEDApplicable', data?.isFEDApplicable === 'Y' ? true : false);

            const newData = {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            };

            if (editable) {
                data['ubpCompanyId'] = ubpId;
                const res = await dispatch(handlePutRequest(newData, '/offline_Biller/company/updateUbpCompany', true, true));
                if (res?.responsecode === 1) {
                    formik.resetForm();
                }
            } else {
                const res = await dispatch(handlePostRequest(newData, '/offline_Biller/company/saveUbpCompany', true, true));
                if (res?.responsecode === 1) {
                    formik.resetForm();
                }
            }

            setloading(false);
            setloadingIcon('pi pi-save');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handledAgentCommission = () => {
        navigate('/agentcommissionshare');
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>{editable ? 'Update Fee Share Configuration' : 'Add Fee Share Configuration'}</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="product" className={classNames({ 'p-error': isFormFieldValid('product') }, 'Label__Text')}>
                                    Product<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="product"
                                    placeholder="Select Product"
                                    options={productJson}
                                    optionLabel="description"
                                    name="product"
                                    optionValue="description"
                                    value={formik.values.product}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('product') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('product')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="agentNetwork" className={classNames({ 'p-error': isFormFieldValid('agentNetwork') }, 'Label__Text')}>
                                    Agent Network<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="agentNetwork"
                                    placeholder="Select Agent Network Type"
                                    options={agentNetworkJson}
                                    optionLabel="description"
                                    name="agentNetwork"
                                    optionValue="description"
                                    value={formik.values.agentNetwork}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('agentNetwork') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('agentNetwork')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="segment" className={classNames({ 'p-error': isFormFieldValid('segment') }, 'Label__Text')}>
                                    Segment
                                </label>
                                <Dropdown
                                    id="segment"
                                    placeholder="Select segment Type"
                                    options={segmentJson}
                                    optionLabel="description"
                                    name="segment"
                                    optionValue="description"
                                    value={formik.values.segment}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('segment') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('segment')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="channel" className={classNames({ 'p-error': isFormFieldValid('channel') }, 'Label__Text')}>
                                    Channel
                                </label>
                                <Dropdown
                                    id="channel"
                                    placeholder="Select Channel Type"
                                    options={channelJson}
                                    optionLabel="description"
                                    name="channel"
                                    optionValue="description"
                                    value={formik.values.channel}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channel') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('channel')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="charges" className={classNames({ 'p-error': isFormFieldValid('charges') }, 'Label__Text')}>
                                    Charges<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="charges"
                                    placeholder="Select Charges"
                                    options={chargesJson}
                                    optionLabel="description"
                                    name="charges"
                                    optionValue="description"
                                    value={formik.values.charges}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('charges') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('charges')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="chargesShare" className={classNames({ 'p-error': isFormFieldValid('chargesShare') }, 'Label__Text')}>
                                    Charges Share
                                </label>
                                <InputText
                                    id="chargesShare"
                                    placeholder="Enter Charges Share"
                                    name="chargesShare"
                                    value={formik?.values?.chargesShare || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('chargesShare') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('chargesShare')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="isWHTApplicable" className={classNames({ 'p-error': isFormFieldValid('isWHTApplicable') }, 'Label__Text')}>
                                    Is WHT Applicable
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isWHTApplicable" name="isWHTApplicable" value={formik.values.isWHTApplicable} checked={formik.values.isWHTApplicable} onChange={formik.handleChange} />
                                </div>
                                {getFormErrorMessage('isWHTApplicable')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="isFEDApplicable" className={classNames({ 'p-error': isFormFieldValid('isFEDApplicable') }, 'Label__Text')}>
                                    Is FED Applicable
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isFEDApplicable" name="isFEDApplicable" value={formik.values.isFEDApplicable} checked={formik.values.isFEDApplicable} onChange={formik.handleChange} />
                                </div>
                                {getFormErrorMessage('isFEDApplicable')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label="Cancel" className="Btn__Transparent" disabled={loading} onClick={(e) => handleCancel(e)} />
                        <Button label={editable ? 'Update' : 'Add'} icon={loadingIcon || ''} iconPos="right" className="Btn__Dark" />
                        <Button
                            label="Add Commission Share"
                            style={{ fontSize: '12px' }}
                            onClick={handledAgentCommission}
                            disabled={formik.values.charges !== 'Agent Commission' ? true : false}
                            icon={loadingIcon || ''}
                            iconPos="right"
                            className="Btn__Dark"
                        />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddFeeShareConfiguration;

import React from 'react';

const CardLimits = ({ indLimits, mcReqData }) => {
    return (
        <>
            <section>
                <div className="hra_table">
                    <div className="grid">
                        <div className="col-12 md:col-6">
                            <table>
                                <tr>
                                    <th className="dim-heading" colSpan={5}>
                                        On Us Transaction Limits
                                    </th>
                                </tr>
                                <tr>
                                    <td>Max - On-Count</td>
                                    {/* <td>{mcReqData?.isLimitUpdate ? mcReqData?.indLimits?.maxOnCount : indLimits?.maxOnCount}</td> */}
                                    <td>{'0'}</td>
                                </tr>
                                <tr>
                                    <td>On Lower Limit</td>
                                    {/* <td>{mcReqData?.isLimitUpdate ? mcReqData?.indLimits?.onLowerLimit : indLimits?.onLowerLimit}</td> */}
                                    <td>{'0'}</td>
                                </tr>
                                <tr>
                                    <td>On Upper Limit</td>
                                    {/* <td>{mcReqData?.isLimitUpdate ? mcReqData?.indLimits?.onUpperLimit : indLimits?.onUpperLimit}</td> */}
                                    <td>{'0'}</td>
                                </tr>
                                <tr>
                                    <td>On Trx Count</td>
                                    <td>{mcReqData?.isLimitUpdate ? mcReqData?.indLimits?.onTrxCount : indLimits?.onTrxCount}</td>
                                </tr>
                                <tr>
                                    <td>On Trx Amount</td>
                                    <td>{mcReqData?.isLimitUpdate ? mcReqData?.indLimits?.onTrxAmount : indLimits?.onTrxAmount}</td>
                                </tr>
                            </table>
                        </div>
                        <div className="col-12 md:col-6">
                            <table>
                                <tr>
                                    <th className="dim-heading" colSpan={5}>
                                        Off Us Transaction Limits
                                    </th>
                                </tr>
                                <tr>
                                    <td>Max - Off-Count</td>
                                    {/* <td>{mcReqData?.isLimitUpdate ? mcReqData?.indLimits?.maxOffCount : indLimits?.maxOffCount}</td> */}
                                    <td>{'0'}</td>
                                </tr>
                                <tr>
                                    <td>Off Lower Limit</td>
                                    {/* <td>{mcReqData?.isLimitUpdate ? mcReqData?.indLimits?.offLowerLimit : indLimits?.offLowerLimit}</td> */}
                                    <td>{'0'}</td>
                                </tr>
                                <tr>
                                    <td>Off Upper Limit</td>
                                    {/* <td>{mcReqData?.isLimitUpdate ? mcReqData?.indLimits?.offUpperLimit : indLimits?.offUpperLimit}</td> */}
                                    <td>{'0'}</td>
                                </tr>
                                <tr>
                                    <td>Off Trx Count</td>
                                    {/* <td>{mcReqData?.isLimitUpdate ? mcReqData?.indLimits?.offTrxCount : indLimits?.offTrxCount}</td> */}
                                    <td>{'0'}</td>
                                </tr>
                                <tr>
                                    <td>Off Trx Amount</td>
                                    {/* <td>{mcReqData?.isLimitUpdate ? mcReqData?.indLimits?.offTrxAmount : indLimits?.offTrxAmount}</td> */}
                                    <td>{'0'}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CardLimits;

import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

const ViewCostCenter = () => {
    const location = useLocation();
    const { rowData } = location.state || {};

    const costCenterId = rowData?.costCenterId;
    const tableName = 'LKP_COST_CENTER';

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            lkpCostCenterName: '',
            lkpCostCenterCode: '',
            lkpCostCenterDescription: '',
            coaCode: ''
        }
    });

    useEffect(() => {
        if (rowData) {
            formik.setFieldValue('lkpCostCenterName', rowData?.costCenterName);
            formik.setFieldValue('lkpCostCenterDescription', rowData?.costCenterDescr);
            formik.setFieldValue('lkpCostCenterCode', rowData?.costCenterCode);
            formik.setFieldValue('coaCode', rowData?.coaCode);
        }
    }, [rowData]);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>View Details</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Cost Center Name</label>
                                <InputText
                                    id="lkpCostCenterName"
                                    value={formik.values.lkpCostCenterName || ''}
                                    onChange={formik.handleChange}
                                    className="Input__Round"
                                    maxLength={30}
                                    disabled={true}
                                />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Cost Center Description</label>
                                <InputText
                                    id="lkpCostCenterDescription"
                                    value={formik.values.lkpCostCenterDescription || ''}
                                    onChange={formik.handleChange}
                                    className="Input__Round"
                                    maxLength={199}
                                    disabled={true}
                                />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Cost Center Code</label>
                                <InputText
                                    id="lkpCostCenterCode"
                                    value={formik.values.lkpCostCenterCode || ''}
                                    onChange={formik.handleChange}
                                    className="Input__Round"
                                    maxLength={30}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Coa Code</label>
                                <InputText id="coaCode" value={formik.values.coaCode || ''} onChange={formik.handleChange} disabled={true} className="Input__Round" maxLength={30} />
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" onClick={() => navigate('/costcenter')} label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={costCenterId} tableName={tableName} />
        </>
    );
};

export default ViewCostCenter;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import OrganizationOldNewValues from './OrganizationOldNewValues';
import { Sidebar } from 'primereact/sidebar';

const EditCheckerOrganization = () => {
    const [statusLovData, setStatusLovData] = useState([]);
    const [mcReqData, setMcReqData] = useState([]);

    const [oldNewData, setOldNewData] = useState([]);
    const [highlightedKeys, setHighlightedKeys] = useState([]);
    const [organizationRowData, setOrganizationRowData] = useState();
    const [displayDialog, setDisplayDialog] = useState(false);

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const tableName = query.get('tableName');
    const requestTypeDescr = query.get('requestTypeDescr');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('Action should not be empty'),
        checkerComments: Yup.string().required('This Field is required').notOneOf([''], 'This Field should not be empty').max(500, 'Maximum 500 characters needed')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            lkpOrganizationName: '',
            lkpOrganizationCode: '',
            lkpOrganizationDescription: '',
            coaCode: '',
            action: '',
            checkerComments: ''
        },

        onSubmit: async (data) => {
            if (requestType && requestTypeDescr) {
                const coaPayload = {
                    data: {
                        security: {
                            userName: '',
                            password: '',
                            securityToken: ''
                        },
                        account: {
                            msidn: '',
                            iban: '',
                            bban: '',
                            pan: '',
                            currency: ''
                        },
                        channel: '',
                        terminal: '',
                        reterivalReferenceNumber: '',
                        payLoad: {
                            lkpOrganizationDTO:
                                requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request'
                                    ? mcReqData
                                    : requestType === 'U' && requestTypeDescr === 'Enable/Disable Request'
                                    ? { isActive: mcReqData?.isActive, lkpOrganizationId: refTableId }
                                    : { lkpOrganizationId: refTableId, isActive: 'Y' },
                            mcActionRequest: {
                                mcRequestId: mcRequestId,
                                mcPeindingRequestId: mcPeindingRequestId,
                                checkerId: '1',
                                checkerComments: data['checkerComments'],
                                action: data['action'],
                                updatedIndex: 1
                            }
                        },
                        additionalInformation: [
                            {
                                infoKey: '',
                                infoValue: ''
                            }
                        ],
                        checkSum: ''
                    }
                };

                const res = await dispatch(handlePostRequest(coaPayload, '/account/v1/chartofaccount/lkporganizationcheckeraction', true, false));
                if (res?.responseCode === '010000') {
                    navigate(-1);
                }
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getCostCenterById = async () => {
        const resp = await handleGetRequest(`/account/v1/chartofaccount/getlkporganizationbyid/${refTableId}`);
        const keyData = resp?.payLoad;
        if (keyData !== null && typeof keyData !== 'undefined') {
            formik.setFieldValue('isActive', keyData?.lkpStatus?.isActive);
            formik.setFieldValue('lkpOrganizationName', keyData?.organizationName);
            formik.setFieldValue('lkpOrganizationCode', keyData?.organizationCode);
            formik.setFieldValue('lkpOrganizationDescription', keyData?.organizationDescr);
            formik.setFieldValue('coaCode', keyData?.coaCode);
        }
    };

    const getCostCenterMcRequestDataById = async () => {
        const resp = await handleGetRequest(`/account/v1/chartofaccount/getlkporganizationbymcrequestid/${mcRequestId}`);
        const keyData = resp?.payLoad;
        if (keyData !== null && typeof keyData !== 'undefined') {
            setMcReqData(keyData);
            if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
                formik.setFieldValue('lkpOrganizationName', keyData?.lkpOrganizationName);
                formik.setFieldValue('lkpOrganizationCode', keyData?.lkpOrganizationCode);
                formik.setFieldValue('lkpOrganizationDescription', keyData?.lkpOrganizationDescription);
                formik.setFieldValue('coaCode', keyData?.coaCode);
            }
        }
    };

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = res?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getCostCenterById();
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getCostCenterMcRequestDataById();
        } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
            getCostCenterById();
            getCostCenterMcRequestDataById();
        }
        getStatusListData();
    }, []); // eslint-disable-line

    const getOldNewData = async () => {
        const resp = await handleGetRequest(`/workflow/v1/management/compareValues/${mcRequestId}`);
        setOldNewData(resp?.payLoad);
    };

    useEffect(() => {
        if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getOldNewData();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        const keys = oldNewData?.map((item) => item.key);
        setHighlightedKeys(keys);
    }, [oldNewData]); // eslint-disable-line

    const HandledOldValue = async (e) => {
        e.preventDefault();
        setDisplayDialog(true);
        const resp = await handleGetRequest(`/account/v1/chartofaccount/getlkporganizationbyid/${refTableId}`);
        setOrganizationRowData(resp?.payLoad);
    };

    return (
        <>
            <Sidebar header="View Mrp" position="right" style={{ width: '50vw' }} visible={displayDialog} onHide={() => setDisplayDialog(false)}>
                <OrganizationOldNewValues organizationRowData={organizationRowData} />
            </Sidebar>

            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>Edit Details</h2>
                            {requestType === 'U' && <Button label="View Old Details" className="Btn__Dark__OldValue" onClick={HandledOldValue} />}
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Organization Name</label>
                                <InputText
                                    id="lkpOrganizationName"
                                    value={formik.values.lkpOrganizationName || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('lkpOrganizationName')
                                            ? classNames({ 'p-invalid': isFormFieldValid('lkpOrganizationName') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('lkpOrganizationName') }, 'Input__Round')
                                    }
                                    maxLength={30}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpOrganizationName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Organization Code</label>
                                <InputText
                                    id="lkpOrganizationCode"
                                    value={formik.values.lkpOrganizationCode || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('lkpOrganizationCode')
                                            ? classNames({ 'p-invalid': isFormFieldValid('lkpOrganizationCode') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('lkpOrganizationCode') }, 'Input__Round')
                                    }
                                    maxLength={30}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpOrganizationCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Organization Description</label>
                                <InputText
                                    id="lkpOrganizationDescription"
                                    value={formik.values.lkpOrganizationDescription || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('lkpOrganizationDescription')
                                            ? classNames({ 'p-invalid': isFormFieldValid('lkpOrganizationDescription') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('lkpOrganizationDescription') }, 'Input__Round')
                                    }
                                    maxLength={199}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpOrganizationDescription')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Coa Code</label>
                                <InputText
                                    id="coaCode"
                                    value={formik.values.coaCode || ''}
                                    onChange={formik.handleChange}
                                    className={highlightedKeys?.includes('coaCode') ? classNames({ 'p-invalid': isFormFieldValid('coaCode') }, 'Input__Round__Highlighted') : classNames({ 'p-invalid': isFormFieldValid('coaCode') }, 'Input__Round')}
                                    maxLength={30}
                                    disabled={true}
                                />
                                {getFormErrorMessage('coaCode')}
                            </div>
                        </div>
                        {requestType === 'U' && requestTypeDescr === 'Enable/Disable Request' && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label className="Label__Text">Is Active</label>
                                    <Checkbox checked={mcReqData?.isActive === 'Y'} disabled className="w-full" />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="custom-card">
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>

                            <div className="p-field col-12 md:col-8">
                                <div className="p-field">
                                    <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                        Comments
                                        <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        rows={5}
                                        cols={30}
                                        id="checkerComments"
                                        name="checkerComments"
                                        value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'Input__Round')}
                                        maxLength={500}
                                    />

                                    {getFormErrorMessage('checkerComments')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Update" className="Btn__Dark" />
                        <Button onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" type="button" />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
};

export default EditCheckerOrganization;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewSalesForceChecker() {
    const [salesStructureData, setSalesStructureData] = useState([]);
    const [kamData, setKamData] = useState([]);
    const navigate = useNavigate();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const tableName = query.get('tableName');

    const formik = useFormik({
        initialValues: {
            salesStructureId: '',
            salesRoleDetailId: '',
            salesRoleId: '',
            employeeCnic: '',
            employeeId: '',
            employeeMobileNo: '',
            employeeEmail: '',
            employeeName: '',
            essDepartment: ''
        }
    });

    const getDatabyId = async () => {
        // getRoleWiseModule
        const resp = await handleGetRequest(`/account/v1/sales/getSalesForceById/${refTableId}`);
        const keyData = resp?.payLoad;

        formik.setFieldValue('salesStructureId', keyData?.salesStructureId?.toString());
        formik.setFieldValue('salesRoleDetailId', keyData?.salesRoleDetailId?.toString());
        formik.setFieldValue('salesRoleId', keyData?.salesRoleId?.toString());
        formik.setFieldValue('employeeCnic', keyData?.employeeCnic);
        formik.setFieldValue('employeeId', keyData?.employeeId);
        formik.setFieldValue('employeeMobileNo', keyData?.employeeMobileNo);
        formik.setFieldValue('employeeEmail', keyData?.employeeEmail);
        formik.setFieldValue('employeeName', keyData?.employeeName);
        formik.setFieldValue('essDepartment', keyData?.essDepartment);
    };

    const getMcRequestDataById = async () => {
        const resp = await handleGetRequest(`/account/v1/sales/getSalesForceUpdateCheckerById/${mcRequestId}`);
        const keyData = resp?.payLoad;
        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
        }
        formik.setFieldValue('salesStructureId', keyData?.salesStructureId?.toString());
        formik.setFieldValue('salesRoleDetailId', keyData?.salesRoleDetailId?.toString());
        formik.setFieldValue('salesRoleId', keyData?.salesRoleId?.toString());
        formik.setFieldValue('employeeCnic', keyData?.employeeCnic);
        formik.setFieldValue('employeeId', keyData?.employeeId);
        formik.setFieldValue('employeeMobileNo', keyData?.employeeMobileNo);
        formik.setFieldValue('employeeEmail', keyData?.employeeEmail);
        formik.setFieldValue('employeeName', keyData?.employeeName);
        formik.setFieldValue('essDepartment', keyData?.essDepartment);
        formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
    };

    const getSaleStructureData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getSaleStructureList/LKP_SALES_STRUCTURE:*');
        setSalesStructureData(res?.payLoad);
    };
    const getKamData = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getEmployeeRoleList/${formik.values?.salesStructureId}`);
        const filteredList = resp?.payLoad.filter((item) => item.isActive === 'Y');
        setKamData(filteredList);
    };

    useEffect(() => {
        if (formik?.values?.salesStructureId) {
            getKamData();
        }
    }, [formik?.values?.salesStructureId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getSaleStructureData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>VIEW DETAIL</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                    Sale Structure
                                </label>
                                <Dropdown
                                    id="lovId"
                                    options={salesStructureData}
                                    optionLabel="name"
                                    name="salesStructureId"
                                    optionValue="lovId"
                                    value={formik.values.salesStructureId || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('name')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="salesRoleDetailId" className={classNames({ 'p-error': isFormFieldValid('salesRoleDetailId') }, 'Label__Text')}>
                                    Sales Role
                                </label>
                                <Dropdown
                                    id="salesRoleDetailId"
                                    options={kamData}
                                    optionLabel="name"
                                    name="salesRoleDetailId"
                                    optionValue="lovId"
                                    value={formik.values.salesRoleDetailId || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('salesRoleDetailId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('salesRoleDetailId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="employeeName" className={classNames({ 'p-error': isFormFieldValid('employeeName') }, 'Label__Text')}>
                                    Employee Name
                                </label>

                                <InputText id="employeeName" name="employeeName" value={formik?.values?.employeeName} disabled className={classNames({ 'p-invalid': isFormFieldValid('employeeName') }, 'Input__Round')} />

                                {getFormErrorMessage('employeeName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="employeeId" className={classNames({ 'p-error': isFormFieldValid('employeeId') }, 'Label__Text')}>
                                    Employee Id
                                </label>

                                <InputText id="employeeId" name="employeeId" value={formik?.values?.employeeId} disabled className={classNames({ 'p-invalid': isFormFieldValid('employeeId') }, 'Input__Round')} />

                                {getFormErrorMessage('employeeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('saleStructure') }, 'Label__Text')}>
                                    Ess Department
                                </label>

                                <InputText id="essDepartment" name="essDepartment" value={formik?.values?.essDepartment} disabled className={classNames({ 'p-invalid': isFormFieldValid('employeeId') }, 'Input__Round')} />

                                {getFormErrorMessage('employeeId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('saleStructure') }, 'Label__Text')}>
                                    Employee CNIC Number
                                </label>

                                <InputText id="employeeCnic" name="employeeCnic" value={formik?.values?.employeeCnic} disabled className={classNames({ 'p-invalid': isFormFieldValid('employeeId') }, 'Input__Round')} />

                                {getFormErrorMessage('employeeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('saleStructure') }, 'Label__Text')}>
                                    Employee Mobile Number
                                </label>

                                <InputText id="employeeMobileNo" name="employeeMobileNo" value={formik?.values?.employeeMobileNo} disabled className={classNames({ 'p-invalid': isFormFieldValid('employeeId') }, 'Input__Round')} />

                                {getFormErrorMessage('employeeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('saleStructure') }, 'Label__Text')}>
                                    Employee Email Address
                                </label>

                                <InputText id="employeeEmail" name="employeeEmail" value={formik?.values?.employeeEmail} disabled className={classNames({ 'p-invalid': isFormFieldValid('employeeId') }, 'Input__Round')} />

                                {getFormErrorMessage('employeeId')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button onClick={() => navigate('/requestchecker')} label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default ViewSalesForceChecker;

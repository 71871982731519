import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';

function DynamicRuleCheckerView() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const requestTypeDescr = query.get('requestTypeDescr');

    //formik
    const formik = useFormik({
        initialValues: {
            name: '',
            description: ''
        }
    });

    //get Data by table Id
    const getDatabyId = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    id: refTableId
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/ruleengine/v1/rule/rulefield/'));

        formik.setFieldValue('name', res?.payLoad?.name);
        formik.setFieldValue('description', res?.payLoad?.description);
    };

    //Get data by mc request Id
    const getMcRequestDataById = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    id: mcRequestId
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/ruleengine/v1/rule/rulefield/checker'));
        if (requestTypeDescr !== 'Enable/Disable Request') {
            formik.setFieldValue('name', res?.payLoad?.name);
            formik.setFieldValue('description', res?.payLoad?.description);
        }
    };

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getMcRequestDataById();
        } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
            getDatabyId();
            getMcRequestDataById();
        }
    }, []); // eslint-disable-line

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid">
                        <div className="Form__Header Full__Width">
                            <h2>View Details</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="name" className="Label__Text">
                                    Name<span className="Label__Required">*</span>
                                </label>
                                <InputText id="name" name="name" placeholder="Please Enter Rule Name" value={formik.values.name} onChange={formik.handleChange} className="Input__Round" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="description" className="Label__Text">
                                    Description<span className="Label__Required">*</span>
                                </label>
                                <InputText id="description" name="description" placeholder="Please Enter Rule Description" value={formik.values.description} onChange={formik.handleChange} className="Input__Round" disabled />
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button onClick={() => navigate('/requestchecker')} type="button" label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default DynamicRuleCheckerView;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../../services/PostTemplate';
import AuthorizationHistoryTable from '../../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import { MultiSelect } from 'primereact/multiselect';

function DefaultComponent({ type, urlData, statusLovData, getByIdData, getByMCRequestIdData }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setloading] = useState(false);
    const [dataId, setDataId] = useState('');
    const [questionTypeData, setQuestionTypeData] = useState([]);
    const [segmentsData, setSegmentsData] = useState([]);
    const [accountLevelData, setAccountLevelData] = useState([]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const getQuestionTypeData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_QUESTION_TYPE');
        setQuestionTypeData(res?.payLoad);
    };

    const getSegmentsData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getsegments/LKP_SEGMENT:*');
        const updated = res?.payLoad?.map((item) => ({
            ...item,
            segmentId: item.lovId
        }));
        setSegmentsData(updated);
    };

    const getAccountLevelData = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getaccounttypes/LKP_ACCOUNT_LEVEL:*');
        setAccountLevelData(response?.payLoad);
    };

    const EditInitialValues = {
        cmsQuestionTypeId: '',
        questionText: '',
        tblCmsQuestionSegments: [],
        accountLevelId: '',
        noOfRetries: '',
        retryTime: '',
        isActive: true
    };

    const EditValidationSchema = Yup.object().shape({
        cmsQuestionTypeId: Yup.string().required('This field is required'),
        questionText: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            // .matches(/^[A-Za-z0-9 ]*$/, 'Special Characters are not allowed')
            .required('This field is required'),
        tblCmsQuestionSegments: Yup.array().min(1, 'This Field is required').required('This Field is required'),
        accountLevelId: Yup.string().required('This field is required'),
        noOfRetries: Yup.string().required('This field is required'),
        retryTime: Yup.string().required('This field is required'),
        isActive: Yup.boolean().required('This field is required')
    });

    const checkerValidationSchema = Yup.object().shape({
        action: Yup.string().required('This field is required'),
        checkerComments: Yup.string().required('This field is required')
    });

    const formik = useFormik({
        validationSchema: type === 'edit' || type === 'add' ? EditValidationSchema : type === 'checkerEdit' ? checkerValidationSchema : null,
        initialValues: type === 'edit' || type === 'view' || type === 'add' ? EditInitialValues : type === 'checkerEdit' || type === 'checkerView' ? { ...EditInitialValues, action: '', checkerComments: '' } : null,

        onSubmit: async (e) => {
            setloading(true);
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad:
                        type === 'add'
                            ? {
                                  cmsQuestionTypeId: formik?.values?.cmsQuestionTypeId,
                                  questionText: formik?.values?.questionText,
                                  tblCmsQuestionSegments: formik?.values?.tblCmsQuestionSegments,
                                  accountLevelId: formik?.values?.accountLevelId,
                                  noOfRetries: formik?.values?.noOfRetries,
                                  retryTime: formik?.values?.retryTime,
                                  isActive: formik?.values?.isActive === true ? 'Y' : 'N'
                              }
                            : type === 'edit'
                            ? {
                                  cmsQuestionConfigId: getByIdData?.cmsQuestionConfigId,
                                  cmsQuestionTypeId: formik?.values?.cmsQuestionTypeId,
                                  questionText: formik?.values?.questionText,
                                  tblCmsQuestionSegments: formik?.values?.tblCmsQuestionSegments,
                                  accountLevelId: formik?.values?.accountLevelId,
                                  noOfRetries: formik?.values?.noOfRetries,
                                  retryTime: formik?.values?.retryTime,
                                  //   isActive: formik?.values?.isActive === true ? 'Y' : 'N'
                                  isActive: null
                              }
                            : type === 'checkerEdit'
                            ? {
                                  objectBody: urlData?.requestType === 'I' ? { ...getByIdData, isActive: 'Y' } : getByMCRequestIdData,
                                  mcActionRequest: {
                                      mcRequestId: urlData?.mcRequestId,
                                      mcPeindingRequestId: urlData?.mcPeindingRequestId,
                                      checkerComments: formik?.values?.checkerComments,
                                      checkerId: 1,
                                      action: formik?.values?.action,
                                      updatedIndex: 1
                                  }
                              }
                            : null,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            if (type === 'add') {
                await dispatch(handlePostRequest(newData, '/cms/v1/cms/savequestionconfig', true, false, 'SecretQuestions'));
            }
            if (type === 'edit') {
                await dispatch(handlePostRequest(newData, '/cms/v1/cms/updatequestionconfig', true, false, 'SecretQuestions'));
            }
            if (type === 'checkerEdit') {
                await dispatch(handlePostRequest(newData, '/cms/v1/cms/questionconfigvalidatorcheckeraction', true, false, 'requestchecker'));
            }
            setloading(false);
        }
    });

    useEffect(() => {
        if ((type === 'edit' || type === 'view') && getByIdData) {
            formik.setFieldValue('cmsQuestionTypeId', getByIdData?.cmsQuestionTypeId?.toString());
            formik.setFieldValue('questionText', getByIdData?.questionText);
            const initialSegments =
                getByIdData?.tblCmsQuestionSegments &&
                getByIdData?.tblCmsQuestionSegments?.map((segment) => ({
                    segmentId: segment?.segmentId.toString(),
                    cmsQuestionSegmentId: segment?.cmsQuestionSegmentId || 0
                }));
            formik.setFieldValue('tblCmsQuestionSegments', initialSegments || []);
            formik.setFieldValue('accountLevelId', getByIdData?.accountLevelId?.toString());
            formik.setFieldValue('noOfRetries', getByIdData?.noOfRetries?.toString());
            formik.setFieldValue('retryTime', getByIdData?.retryTime?.toString());
            formik.setFieldValue('isActive', getByIdData?.isActive === 'Y' ? true : false);
        }
    }, [getByIdData]);

    useEffect(() => {
        if ((type === 'checkerEdit' || type === 'checkerView') && (getByMCRequestIdData || getByIdData)) {
            if (urlData?.requestType === 'U') {
                setDataId(getByMCRequestIdData?.cmsQuestionConfigId);
                formik.setFieldValue('cmsQuestionTypeId', getByMCRequestIdData?.cmsQuestionTypeId?.toString());
                formik.setFieldValue('questionText', getByMCRequestIdData?.questionText);
                const initialSegments =
                    getByMCRequestIdData?.tblCmsQuestionSegments &&
                    getByMCRequestIdData?.tblCmsQuestionSegments?.map((segment) => ({
                        segmentId: segment?.segmentId.toString(),
                        cmsQuestionSegmentId: segment?.cmsQuestionSegmentId || 0
                    }));
                formik.setFieldValue('tblCmsQuestionSegments', initialSegments || []);
                formik.setFieldValue('accountLevelId', getByMCRequestIdData?.accountLevelId?.toString());
                formik.setFieldValue('noOfRetries', getByMCRequestIdData?.noOfRetries?.toString());
                formik.setFieldValue('retryTime', getByMCRequestIdData?.retryTime?.toString());
                formik.setFieldValue('isActive', getByMCRequestIdData?.isActive === 'N' ? false : true);
            } else if (urlData?.requestType === 'I') {
                setDataId(getByIdData?.cmsQuestionConfigId);
                formik.setFieldValue('cmsQuestionTypeId', getByIdData?.cmsQuestionTypeId?.toString());
                formik.setFieldValue('questionText', getByIdData?.questionText);
                const initialSegments =
                    getByIdData?.tblCmsQuestionSegments &&
                    getByIdData?.tblCmsQuestionSegments?.map((segment) => ({
                        segmentId: segment?.segmentId.toString(),
                        cmsQuestionSegmentId: segment?.cmsQuestionSegmentId || 0
                    }));
                formik.setFieldValue('tblCmsQuestionSegments', initialSegments || []);
                formik.setFieldValue('accountLevelId', getByIdData?.accountLevelId?.toString());
                formik.setFieldValue('noOfRetries', getByIdData?.noOfRetries?.toString());
                formik.setFieldValue('retryTime', getByIdData?.retryTime?.toString());
                formik.setFieldValue('isActive', getByIdData?.isActive === 'Y' ? true : false);
            }
        }
    }, [type, getByMCRequestIdData, getByIdData]);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        getQuestionTypeData();
        getSegmentsData();
        getAccountLevelData();
    }, []);

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h5 className="uppercase">{type === 'add' ? 'Add Question' : type === 'edit' || type === 'checkerEdit' ? 'Edit Detail' : type === 'view' || type === 'checkerView' ? 'View Detail' : null}</h5>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="cmsQuestionTypeId" className={classNames({ 'p-error': isFormFieldValid('cmsQuestionTypeId') }, 'Label__Text')}>
                                    Question Type
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="cmsQuestionTypeId"
                                    placeholder="Select Question Type"
                                    options={questionTypeData}
                                    optionLabel="name"
                                    name="cmsQuestionTypeId"
                                    optionValue="lovId"
                                    value={formik?.values?.cmsQuestionTypeId || ''}
                                    onChange={formik.handleChange}
                                    disabled={type !== 'add' && type !== 'edit'}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsQuestionTypeId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('cmsQuestionTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="questionText" className={classNames({ 'p-error': isFormFieldValid('questionText') }, 'Label__Text')}>
                                    Question Text
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    placeholder="Enter Question Text"
                                    id="questionText"
                                    name="questionText"
                                    value={formik?.values?.questionText || ''}
                                    onChange={formik.handleChange}
                                    disabled={type !== 'add' && type !== 'edit'}
                                    className={classNames({ 'p-invalid': isFormFieldValid('questionText') }, 'Input__Round')}
                                    onKeyDown={(e) => handleKeyPress(e)}
                                />
                                {getFormErrorMessage('questionText')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 customMultiselect ">
                            <label htmlFor="tblCmsQuestionSegments" className={classNames({ 'p-error': isFormFieldValid('tblCmsQuestionSegments') }, 'Label__Text')}>
                                Segments<span className="Label__Required">*</span>
                            </label>
                            <MultiSelect
                                id="tblCmsQuestionSegments"
                                placeholder="Select Segments"
                                options={segmentsData}
                                optionLabel="name"
                                display="chip"
                                name="tblCmsQuestionSegments"
                                optionValue="segmentId"
                                value={formik?.values?.tblCmsQuestionSegments?.map((segment) => segment.segmentId || '')}
                                onChange={(e) => {
                                    if (type === 'add') {
                                        const segments =
                                            e?.value?.map((lovId) => ({
                                                segmentId: lovId
                                            })) || [];
                                        formik.setFieldValue('tblCmsQuestionSegments', segments);
                                    } else if (type === 'edit') {
                                        const segments =
                                            e?.value?.map((lovId) => {
                                                const matchedSegment = getByIdData?.tblCmsQuestionSegments?.find((segment) => segment?.segmentId?.toString() === lovId.toString());
                                                const cmsQuestionSegmentId = matchedSegment?.cmsQuestionSegmentId || 0;
                                                return {
                                                    segmentId: lovId,
                                                    cmsQuestionSegmentId: cmsQuestionSegmentId
                                                };
                                            }) || [];
                                        formik.setFieldValue('tblCmsQuestionSegments', segments);
                                    }
                                }}
                                // disabled={type !== 'add' && type !== 'edit'}
                                className={classNames({ 'p-invalid': isFormFieldValid('tblCmsQuestionSegments') }, 'Dropdown__Round')}
                            />
                            {getFormErrorMessage('tblCmsQuestionSegments')}
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountLevelId" className={classNames({ 'p-error': isFormFieldValid('accountLevelId') }, 'Label__Text')}>
                                    Account Level<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="accountLevelId"
                                    placeholder="Select Account Level"
                                    options={accountLevelData}
                                    optionLabel="name"
                                    name="accountLevelId"
                                    optionValue="lovId"
                                    value={formik.values.accountLevelId || ''}
                                    onChange={formik.handleChange}
                                    disabled={type !== 'add' && type !== 'edit'}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountLevelId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('accountLevelId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="noOfRetries" className={classNames({ 'p-error': isFormFieldValid('noOfRetries') }, 'Label__Text')}>
                                    Attempts
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    keyfilter={/^\d+$/}
                                    placeholder="Enter Attempts"
                                    id="noOfRetries"
                                    name="noOfRetries"
                                    value={formik?.values?.noOfRetries || ''}
                                    onChange={formik.handleChange}
                                    disabled={type !== 'add' && type !== 'edit'}
                                    className={classNames({ 'p-invalid': isFormFieldValid('noOfRetries') }, 'Input__Round')}
                                    onKeyDown={(e) => handleKeyPress(e)}
                                />
                                {getFormErrorMessage('noOfRetries')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="retryTime" className={classNames({ 'p-error': isFormFieldValid('retryTime') }, 'Label__Text')}>
                                    Time
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    // keyfilter={/^\d+$/}
                                    type="time"
                                    placeholder="Enter Time"
                                    id="retryTime"
                                    name="retryTime"
                                    value={formik?.values?.retryTime || ''}
                                    onChange={formik.handleChange}
                                    disabled={type !== 'add' && type !== 'edit'}
                                    className={classNames({ 'p-invalid': isFormFieldValid('retryTime') }, 'Input__Round')}
                                    onKeyDown={(e) => handleKeyPress(e)}
                                />
                                {getFormErrorMessage('retryTime')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="Status" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                    Status
                                    <span className="Label__Required">*</span>
                                </label>
                                <div className="field-checkbox flex mb-2">
                                    <div className="mr-5">
                                        <Checkbox
                                            inputId="Active"
                                            name="Active"
                                            value={formik?.values?.isActive}
                                            checked={formik?.values?.isActive}
                                            disabled={type !== 'add' && type !== 'edit'}
                                            onChange={() => {
                                                formik.setFieldValue('isActive', !formik?.values?.isActive);
                                            }}
                                            style={{ marginTop: '10px' }}
                                        />
                                        <label htmlFor="Active" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                            Show
                                        </label>
                                    </div>
                                    <div>
                                        <Checkbox
                                            inputId="InActive"
                                            name="InActive"
                                            value={!formik?.values?.isActive}
                                            checked={!formik?.values?.isActive}
                                            disabled={type !== 'add' && type !== 'edit'}
                                            onChange={() => {
                                                formik.setFieldValue('isActive', !formik?.values?.isActive);
                                            }}
                                            style={{ marginTop: '10px' }}
                                        />
                                        <label htmlFor="InActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                            Hide
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn py-3">
                        {type === 'add' && (
                            <React.Fragment>
                                <Button className="Btn__Dark" disabled={loading} type="submit" label={'Submit'} />
                                <Button type="button" onClick={() => navigate(-1)} label="Cancel" className="Btn__Transparent" />
                            </React.Fragment>
                        )}
                        {type === 'edit' && (
                            <React.Fragment>
                                <Button className="Btn__Dark" disabled={loading} type="submit" label={'Update'} />
                                <Button type="button" onClick={() => navigate(-1)} label="Cancel" className="Btn__Transparent" />
                            </React.Fragment>
                        )}
                        {type === 'view' && <Button type="button" onClick={() => navigate(-1)} label="Okay" className="Btn__Dark" />}
                    </div>
                    {type === 'view' && getByIdData?.cmsQuestionConfigId && <AuthorizationHistoryTable refTableId={getByIdData?.cmsQuestionConfigId} tableName={'TBL_CMS_QUESTION_CONFIG'} />}

                    {/* Checker Action */}
                    {type == 'checkerEdit' && (
                        <React.Fragment>
                            <div className="card Card__Bg__Change col-12 md:col-12 flex">
                                <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                    <div className="p-field">
                                        <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                            Select Action <span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="action"
                                            placeholder="Select Action"
                                            options={statusLovData}
                                            optionLabel="name"
                                            name="action"
                                            optionValue="lovId"
                                            value={formik?.values?.action || ''}
                                            onChange={formik.handleChange}
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('action')}
                                    </div>
                                </div>

                                <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                    <div className="desc_Text_Area">
                                        <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                            Comments <span className="Label__Required">*</span>
                                        </label>
                                        <InputTextarea
                                            placeholder="-"
                                            inputid="checkerComments"
                                            name="checkerComments"
                                            rows={4}
                                            cols={30}
                                            onChange={formik.handleChange}
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'Input__Round')}
                                            value={formik?.values?.checkerComments}
                                        />
                                        {getFormErrorMessage('checkerComments')}
                                    </div>
                                </div>
                            </div>
                            <div className="Down__Btn py-3">
                                <Button disabled={loading} label="Update" className="Btn__Dark" />
                                <Button type="button" onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                            </div>
                            {urlData?.refTableId && urlData?.tableName && <AuthorizationHistoryTable refTableId={urlData?.refTableId} tableName={urlData?.tableName} />}
                        </React.Fragment>
                    )}
                    {type == 'checkerView' && (
                        <React.Fragment>
                            <div className="Down__Btn">
                                <Button type="button" onClick={() => navigate('/requestchecker')} label="Okay" className="Btn__Dark" />
                            </div>

                            {/* Action Authorization History */}

                            {urlData?.refTableId && urlData?.tableName && <AuthorizationHistoryTable refTableId={urlData?.refTableId} tableName={urlData?.tableName} />}
                        </React.Fragment>
                    )}
                </form>
            </div>
        </>
    );
}
export default DefaultComponent;

import React from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useEffect } from 'react';

const TierDialogView = ({ onHideTierView, dialogData }) => {
    useEffect(() => {
        formik.setFieldValue('tier', dialogData?.tier);
        formik.setFieldValue('tierRange', dialogData?.maxTierRange);
        formik.setFieldValue('multiplier', dialogData?.multiplier);
        formik.setFieldValue('isActive', dialogData?.isActive === 'Y' ? 'true' : 'false');
    }, [dialogData]); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            tier: '',
            tierRange: '',
            multiplier: '',
            isActive: 'N',
            statusId: 0,
            zmilesTierId: dialogData?.zmilesTierId
        },

        onSubmit: async (data) => {
            onHideTierView();
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="tier" className={classNames({ 'p-error': isFormFieldValid('tier') }, 'Label__Text')}>
                                    Tier<span className="Label__Required">*</span>
                                </label>
                                <InputText disabled id="tier" placeholder="Enter Tier" name="tier" value={formik?.values?.tier || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('tier') }, 'Input__Round')} />

                                {getFormErrorMessage('tier')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="tierRange" className={classNames({ 'p-error': isFormFieldValid('tierRange') }, 'Label__Text')}>
                                    Max Tier Range<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    id="tierRange"
                                    placeholder="Enter Max Tier Range"
                                    name="tierRange"
                                    value={formik?.values?.tierRange || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('tierRange') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('tierRange')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="multiplier" className={classNames({ 'p-error': isFormFieldValid('multiplier') }, 'Label__Text')}>
                                    Multiplier<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    id="multiplier"
                                    placeholder="Enter Multiplier"
                                    name="multiplier"
                                    value={formik?.values?.multiplier || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('multiplier') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('multiplier')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="submit" label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
};

export default TierDialogView;

import React from 'react';
import cmsInformationSplash from '../../../../assets/cms/cmsInformationSplash.png';

function MobileView({ icon, title, description }) {
    return (
        <div className="cmsMobileView__main relative">
            <div className="cmsMobileView__informationSplash">
                {icon && <img src={icon} className="cmsMobileView__icon" alt="icon" />}
                {title && <span className="cmsMobileView__title">{title}</span>}
                {description && <span className="cmsMobileView__description">{description}</span>}
            </div>
            <img src={cmsInformationSplash} className="blurMobile w-100 h-100" alt="cmsInformationSplash" />
        </div>
    );
}

export default MobileView;

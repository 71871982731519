import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { Checkbox } from 'primereact/checkbox';

const CategoryOldNewValues = ({ categoryRowData }) => {
    const [mcReqData, setMcReqData] = useState([]);

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const tableName = query.get('tableName');
    const requestTypeDescr = query.get('requestTypeDescr');

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('Action should not be empty'),
        checkerComments: Yup.string().required('This Field is required').notOneOf([''], 'This Field should not be empty').max(500, 'Maximum 500 characters needed')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            lkpGlTypeName: '',
            lkpGlTypeCode: '',
            lkpGlTypeDescription: '',
            coaCode: '',
            action: '',
            checkerComments: ''
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getCostCenterById = async () => {
        if (categoryRowData !== null && typeof categoryRowData !== 'undefined') {
            formik.setFieldValue('lkpGlTypeName', categoryRowData?.glTypeName);
            formik.setFieldValue('lkpGlTypeCode', categoryRowData?.glTypeCode);
            formik.setFieldValue('lkpGlTypeDescription', categoryRowData?.glTypeDescr);
            formik.setFieldValue('coaCode', categoryRowData?.coaCode);
        }
    };

    useEffect(() => {
        getCostCenterById();
    }, [categoryRowData]); // eslint-disable-line

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>Old Details</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Category Name</label>
                                <InputText
                                    id="lkpGlTypeName"
                                    value={formik.values.lkpGlTypeName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpGlTypeName') }, 'Input__Round')}
                                    maxLength={30}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpGlTypeName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Category Code</label>
                                <InputText
                                    id="lkpGlTypeCode"
                                    value={formik.values.lkpGlTypeCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpGlTypeCode') }, 'Input__Round')}
                                    maxLength={30}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpGlTypeCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Category Description</label>
                                <InputText
                                    id="lkpGlTypeDescription"
                                    value={formik.values.lkpGlTypeDescription || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpGlTypeDescription') }, 'Input__Round')}
                                    maxLength={199}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpGlTypeDescription')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Coa Code</label>
                                <InputText id="coaCode" value={formik.values.coaCode || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('coaCode') }, 'Input__Round')} maxLength={30} disabled={true} />
                                {getFormErrorMessage('coaCode')}
                            </div>
                        </div>
                        {requestType === 'U' && requestTypeDescr === 'Enable/Disable Request' && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label className="Label__Text">Is Active</label>
                                    <Checkbox checked={mcReqData?.isActive === 'Y'} disabled className="w-full" />
                                </div>
                            </div>
                        )}
                    </div>
                </form>
            </div>
        </>
    );
};

export default CategoryOldNewValues;

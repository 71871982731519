import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handlePostRequest } from '../../../services/PostTemplate';

function ViewLoanBook() {
    const [loading, setloading] = useState(false);
    const [dataBycnic, setDatabyCnic] = useState();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const cnicNumber = query.get('reqId');

    const getDatabyCnic = async () => {
        const data = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    cnic: cnicNumber
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        setloading(true);
        const response = await dispatch(handlePostRequest(data, '/lending/v1/lending/getloanbook', true, true));
        if (response?.responseCode === process.env.REACT_APP_LENDING_SUCCESS_CODE) {
            setDatabyCnic(response?.payLoad);
        }
        setloading(false);
    };

    useEffect(() => {
        getDatabyCnic();
    }, []); // eslint-disable-line

    const formatDate = (rowData) => {
        const date = new Date(rowData?.loanDate);

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours from 24-hour to 12-hour format
        const formattedHours = hours % 12 || 12;

        const formattedDate = `${day}-${month}-${year}, ${formattedHours}:${minutes} ${ampm}`;
        return formattedDate;
    };

    const customerIdTemplate = () => {
        return <p>{cnicNumber}</p>;
    };
    const statusTemplate = (rowData) => {
        // const statusColor = rowData?.transactionStatus === 'Approved' ? '#14A38B' : rowData?.transactionStatus === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p
            // style={{ color: statusColor }}
            >
                <b>{rowData?.currentStatusOfLoan}</b>
            </p>
        );
    };
    const actionTemplate = (rowData) => {
        return (
            <div className="actions" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Button
                    tooltip="View Details"
                    tooltipOptions={{ position: 'top' }}
                    icon="pi pi-eye"
                    onClick={() => navigate(`/viewactiveloan?reqId=${rowData?.lmsDisbursementId}`)}
                    className="p-button-rounded"
                    style={{ backgroundColor: '#F2AC57', border: 'none' }}
                />
            </div>
        );
    };

    const convertArrayToCSV = (dataArray) => {
        const header = Object.keys(dataArray[0]).join(',') + '\n';
        const csvData = dataArray.map((item) => Object.values(item).join(',')).join('\n');
        return header + csvData;
    };

    const downloadCSV = (dataArray, fileName) => {
        const csvContent = convertArrayToCSV(dataArray);

        const blob = new Blob([csvContent], { type: 'text/csv' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    };

    const handleDownload = () => {
        downloadCSV(dataBycnic, 'data.csv');
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header Heading__With__Btn">
                    <h1>VIEW LOAN BOOK </h1>
                    <div className="Down__Btn_Excel_Loan_Management">
                        <Button disabled={loading} label="Download Excel" className="Btn__Dark Btn__Margin" onClick={handleDownload} />
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <DataTable
                                // ref={dt}
                                className="datatable-responsive"
                                globalFilterFields={['campaignName', 'campaignCode', 'budget', 'createdOn', 'createdBy', 'startDate', 'endDate', 'statusDescr']}
                                emptyMessage="No List found."
                                responsiveLayout="scroll"
                                value={dataBycnic}
                            >
                                <Column field="lmsDisbursementId" header="Loan ID" />
                                <Column field="productName" header="Product Name" />
                                <Column body={customerIdTemplate} header="Customer ID" />
                                <Column field="loanTenure" header="Tenure" />
                                <Column header="End Date" body={formatDate} />
                                <Column field="currentStatusOfLoan" header="Status" body={statusTemplate} />
                                <Column header="Action" body={actionTemplate} />
                            </DataTable>
                        </div>
                    </div>
                </div>
                <div className="Down__Btn">
                    <Button label="Okay" className="Btn__Dark" onClick={() => navigate('/loanaccountmanagement')} />
                </div>
            </div>
        </>
    );
}
export default ViewLoanBook;

import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';

const NumberOfDevicesPerAccount = ({ numberOfDevicesPerAccountsData }) => {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        // const countArr =
        //     numberOfDevicesPerAccountsData &&
        //     numberOfDevicesPerAccountsData.map((item) => {
        //         return item?.deviceType;
        //     });
        // const labelArr =
        //     numberOfDevicesPerAccountsData &&
        //     numberOfDevicesPerAccountsData.map((item) => {
        //         return item?.accounts;
        //     });
        const countArr = numberOfDevicesPerAccountsData && numberOfDevicesPerAccountsData.flatMap((item) => [].concat(item?.deviceType, item?.accounts));

        const data = {
            labels: ['Number Of Devices', 'Accounts'],
            datasets: [
                {
                    data: countArr,
                    backgroundColor: ['#dee176', '#eeca50'],
                    hoverBackgroundColor: ['#dee176', '#eeca50']
                }
            ]
        };

        setChartData(data);
    }, [numberOfDevicesPerAccountsData]);
    return (
        <div className="col-12 md:col-4">
            <div className="card Card__Round">
                <div className="card p-d-flex p-jc-center">
                    <h5>Number Of Devices Per Accounts</h5>
                    <Chart type="pie" data={chartData} options={''} className="w-full" style={{ height: '35rem' }} />
                </div>
            </div>
        </div>
    );
};

export default NumberOfDevicesPerAccount;

import React from 'react';
import { OrganizationChart } from 'primereact/organizationchart';
import 'primeicons/primeicons.css'; // Import PrimeIcons CSS
import { Button } from 'primereact/button';

// Other imports...

const expandButtonStyle = {
    background: '#F2AC57', // Adjust the colors as needed
    border: 'none',
    color: 'white',
    padding: '10px 20px', // Adjust the padding as needed
    cursor: 'pointer',
    fontSize: '4px',
    height: '2px',
    borderRadius: '5px',
    margin: '10px'
};

const deleteButtonStyle = {
    background: '#FF7171', // Adjust the colors as needed
    border: 'none',
    color: 'white',
    padding: '10px 20px', // Adjust the padding as needed
    cursor: 'pointer',
    fontSize: '4px',
    height: '2px',
    borderRadius: '5px',
    margin: '10px'
};
const addButtonStyle = {
    background: '#12A189', // Adjust the colors as needed
    border: 'none',
    color: 'white',
    padding: '10px 20px', // Adjust the padding as needed
    cursor: 'pointer',
    fontSize: '4px',
    height: '2px',
    borderRadius: '5px',
    margin: '10px'
};

// const containerStyle = {
//   padding: '10px'
// };

export default function DynamicOrganizationChartComponent({ data, onNodeClick, onExpandedClick, title, disabled, isEdit, onEdit, isEditChecker, isViewPage, onRemove, isAdd, ruleName, isExpanded }) {
    // Component code...

    const nodeTemplate = (node) => {
        const nodeColorClass = node.color === 'red' ? 'red-node' : 'green-node';

        return (
            <div className="p-organizationchart-table">
                <div className={`flex flex-column align-items-center  ${nodeColorClass}`}>
                    {/* Use PrimeIcons */}

                    <div className="mt-2 font-medium text-lg">{node.label && node.label.indexOf('#@#') !== -1 ? node.label.replaceAll('#@#', ' || ') : node.label}</div>

                    <div className="button-row">
                        {isEdit === true ? (
                            <Button style={addButtonStyle} onClick={() => onNodeClick(node)} disabled={node.isEdit ? isEdit : isEditChecker} title="Edit Rule">
                                <img src="/edit.png" alt="Edit"></img>
                            </Button>
                        ) : (
                            <>
                                <Button style={addButtonStyle} className="Btn__Dark__Search" onClick={() => onNodeClick(node)} disabled={node.isAdd ? true : isEditChecker} title="Add Rule">
                                    {/* <i className="pi pi-plus"></i>  */}
                                    <img src="/add.png" alt="Add"></img>
                                </Button>
                            </>
                        )}

                        <Button style={expandButtonStyle} className="Btn__Dark__Search" onClick={() => onExpandedClick(node)} disabled={node.isExpanded ? true : isEditChecker} title="Expand Rule">
                            {/* <i className="pi pi-file-export"></i>  */}
                            <img src="/expand.png" alt="Expand"></img>
                        </Button>

                        <Button style={deleteButtonStyle} className="Btn__Dark__Search" onClick={() => onRemove(node)} disabled={node.isRemove ? true : isEditChecker} title="Delete Rule">
                            {/* <i className="pi pi-trash"></i>                  */}

                            <img src="/delete.png" alt="Delete"></img>
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    return <div className="card overflow-x-auto skip-border">{data && <OrganizationChart value={data} nodeTemplate={nodeTemplate} disabled={isEditChecker} className={isEditChecker || isViewPage ? 'disable-arrows' : ''} />}</div>;
}

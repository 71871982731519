import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import moment from 'moment';

function DynamicReportFields({ searchFilters, formik, resetSearchFilters, subReport, handleTabData }) {
    console.log('formik', formik.values);

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    function convertToTitleCase(inputString) {
        if (inputString == null) {
            return '';
        }

        const words = inputString.split(/(?=[A-Z])/);
        const titleCaseString = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

        return titleCaseString;
    }

    return (
        <>
            <div className="card Card__Round">
                {searchFilters && (
                    <div className="custom-card p-3">
                        <div className="m-3 Form__Header Full__Width">
                            <p>SEARCH FILTERS</p>
                        </div>
                        <div className="p-fluid p-formgrid grid mb-2 ">
                            {searchFilters?.map((item, key) => (
                                <React.Fragment key={key}>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field withoutArrow">
                                            <label htmlFor={item?.mappedFilterName} className="Label__Text">
                                                {convertToTitleCase(item?.mappedFilterName)}
                                            </label>
                                            {item?.reportFilterType === 'LOOKUP' ? (
                                                <Dropdown
                                                    id={item?.mappedFilterName}
                                                    name={item?.mappedFilterName}
                                                    value={formik?.values[item?.mappedFilterName] || ''}
                                                    onChange={formik.handleChange}
                                                    className="Dropdown__Round"
                                                    options={item?.lookUpData}
                                                    optionLabel="name"
                                                    optionValue="name"
                                                />
                                            ) : (
                                                <InputText
                                                    id={item?.mappedFilterName}
                                                    name={item?.mappedFilterName}
                                                    value={formik?.values[item?.mappedFilterName] || ''}
                                                    onChange={(e) => {
                                                        if (item?.reportFilterType?.toLowerCase()?.includes('date')) {
                                                            formik?.setFieldValue(item?.mappedFilterName, e.target.value);
                                                        } else {
                                                            formik?.handleChange(e);
                                                        }
                                                    }}
                                                    type={item?.reportFilterType?.toLowerCase()?.includes('date') ? 'date' : 'text'}
                                                    className="Input__Round"
                                                    max={item?.reportFilterType?.includes('DATE') ? previousDate : null}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>

                        <div className="Down__Btn my-4">{subReport.length === 0 && <Button label="Search" iconPos="right" type="submit" className="Btn__Dark" />}</div>
                    </div>
                )}
                <div className="flex flex-wrap justify-content-center Reports_Tab mt-5">
                    {subReport?.map((item, key) => (
                        <TabView onTabChange={() => handleTabData(item)}>
                            <TabPanel key={key} header={item?.reportName}></TabPanel>
                        </TabView>
                    ))}
                </div>
            </div>
        </>
    );
}

export default DynamicReportFields;

import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import * as Yup from 'yup';
import { FilterMatchMode } from 'primereact/api';
import ActiveInActiveDialog from '../../../../components/ActiveInActiveDialog/ActiveInActiveDialog';

const AgentOnboarding = () => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [statusLovData, setStatusLovData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [switchData, setSwitchData] = useState([]);

    const [agentOnboardingData, setAgentOnboardingData] = useState([]);
    const [newDataArray, setNewDataArray] = useState([]);
    const [accountClassification, setAccountClassification] = useState([]);
    const [classificationOptions, setClassificationOptions] = useState([]);
    const [statusName, setStatusName] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [classificationObj, setClassificationObj] = useState(null);
    const [kycFieldOptions, setKycFieldOptions] = useState([]);

    const dataTableRef = useRef(null);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        channelName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ''));
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    //reset form
    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
        setNewDataArray('');
    };

    //validation Schema
    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,

            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be equal or more than Date From')
        })
    });

    //formik
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            accountClassificationId: '',
            agentId: '',
            cnic: '',
            mobileNumber: '',
            dateFrom: '',
            dateTo: '',
            statusId: '2',
            createdBy: '',
            updatedBy: ''
        },
        validate: (data) => {
            let errors = {};
            if (data.dateTo && !data.dateFrom) {
                errors.dateFrom = 'Date From is required when Date To is provided';
            }

            return errors;
        },

        onSubmit: async (data) => {
            setloadingIcon('pi pi-spin pi-spinner');
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            dataTableRef.current.reset();
            setAgentOnboardingData('');
            const res = await dispatch(handlePostRequest(newData, '/account/v1/agentaccount/getagentaccounts', true, true));
            if (res?.responseCode === '010000') {
                let apiData = res?.payLoad;
                setAgentOnboardingData(res?.payLoad);
                const updatedData = apiData?.map((item) => {
                    const statusName = statusLovData?.find((data) => data?.lovId === item?.lkpStatus?.statusId?.toString())?.name;
                    return { ...item, statusName };
                });
                setAgentOnboardingData(updatedData);
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(res?.payLoad);
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };

    //get Account Classification API
    const getAccountClassification = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getclientroles/LKP_ACCOUNT_CLASSIFICATION:*');
        setAccountClassification(res?.payLoad);
    };

    //GET KYC DROPDOWN VALUES
    const handleChange = async (e) => {
        const selectedOption = classificationOptions.find((option) => option.lovId === e.target.value);
        if (selectedOption) {
            formik.setFieldValue('accountClassificationId', selectedOption.lovId);
            setClassificationObj(selectedOption);
        }
    };
    const getKycFields = async () => {
        const res = await handleGetRequest(`/account/v1/agentaccount/getkycbyclassificationname/${classificationObj.name}`);
        setKycFieldOptions(res?.payLoad);
    };
    useEffect(() => {
        if (classificationObj) {
            // debugger
            getKycFields();
        }
    }, [classificationObj]);

    useEffect(() => {
        if (agentOnboardingData.length > 0) {
            const newArray = agentOnboardingData.map((parentItem) => {
                const classificationData = accountClassification.find((item) => item.lovId === parentItem?.lkpAccountClassification?.accountClassificationId?.toString());
                // const kycDataName = kycFieldOptions.find((item) => item.lovId === parentItem?.lkpAccountClassification?.accountClassificationId?.toString()?.name);
                const kycDataName = '';
                const parentName = parentItem?.parentName ?? 'Zindigi';
                // const kycData = accountClassification.find((item) => item.lovId === parentItem?.lkpAccountClassification?.accountClassificationId?.toString());
                return { ...parentItem, classificationData: classificationData, parentName: parentName, kycDataName: kycDataName };
            });
            setNewDataArray(newArray);
        }
    }, [agentOnboardingData]);

    useEffect(() => {
        let arrayofwords = [];
        accountClassification?.map((item) => {
            if (item.code === 'FRN' || item.code === 'RTL' || item.code === 'HND' || item.code === 'CPT') {
                arrayofwords.push(item);
            }
        });
        setClassificationOptions(arrayofwords);
    }, [accountClassification]);

    useEffect(() => {
        getStatusListData();
        getUserLov();
        getAccountClassification();
    }, []);

    //rendering header of datatable
    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0 ">Registration Initiation</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const header = () => renderHeader();

    //handle active inactive toggle button
    const handleSwitchChange = async () => {
        const updatedData = newDataArray?.map((item) => {
            if (item?.agentId === switchData?.id) {
                const isActive = item?.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setNewDataArray(updatedData);
        let isactive = '';
        if (switchData?.isActive === 'Y') {
            isactive = 'N';
        } else if (switchData?.isActive === 'N') {
            isactive = 'Y';
        }
        const previousValue = switchData?.isActive;

        const data = {
            agentId: switchData?.agentId,
            isActive: isactive
        };

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newData, '/account/v1/agentaccount/inactiveagentaccount', true, false));
        if (res?.responseCode == '010000') {
            const revertedData = newDataArray?.map((item) => {
                if (item.agentId === switchData?.agentId) {
                    const isActive = isactive;
                    return { ...item, isActive };
                }
                return item;
            });
            setNewDataArray(revertedData);
        } else {
            const revertedData = newDataArray?.map((item) => {
                if (item.agentId === switchData?.agentId) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setNewDataArray(revertedData);
        }
    };

    //navigating to add account screen
    const addAccountOnboarding = () => {
        navigate('/addaccountonboarding');
    };

    //navigating to edit account screen
    const EditAgent = (rowData) => {
        navigate('/editaccountonboarding', { state: [rowData.agentId] });
    };

    //navigating to view account screen
    const handleView = (rowData) => {
        navigate('/viewaccountonboarding', { state: [rowData.agentId] });
    };

    //getting the status name for the status column in data table
    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.lkpStatus?.statusId == '1' ? '#F2AC57' : rowData?.lkpStatus?.statusId == '2' ? '#14A38B' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{statusLovData?.find((item) => item?.lovId === rowData?.lkpStatus?.statusId?.toString())?.name}</b>
            </p>
        );
    };

    //Action column
    const actionBodyTemplate = (rowData) => {
        const statusName = statusLovData?.find((data) => data?.lovId === rowData?.lkpStatus?.statusId?.toString())?.name;
        return (
            <>
                <div className="actions">
                    <InputSwitch
                        checked={rowData.isActive === 'Y'}
                        disabled={['Pending', 'Rejected'].includes(statusName) ? true : false}
                        onChange={() => {
                            // handleSwitchChange(rowData?.agentId, rowData?.isActive, rowData);
                            setSwitchData(rowData);
                            setShowModal(true);
                        }}
                    />
                    <Button
                        tooltip="Edit"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: 'top' }}
                        disabled={['Pending', 'Rejected'].includes(statusName) || (statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                        onClick={() => EditAgent(rowData)}
                        className="p-button-rounded p-button-primary mr-2"
                    />
                    <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => handleView(rowData)} className="p-button-rounded p-mr-2" style={{ backgroundColor: '#F2AC57', border: 'none' }} />
                </div>
            </>
        );
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];
    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    //validation check functions
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleShowModal = () => {
        setShowModal(false);
    };

    const props = {
        showModal,
        handleShowModal,
        handleSwitchChange
    };

    return (
        <>
            <ActiveInActiveDialog {...props} />
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={() => addAccountOnboarding()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                    Account Classification
                                </label>
                                <Dropdown
                                    id="accountClassificationId"
                                    placeholder="Select Account Classification"
                                    options={classificationOptions}
                                    optionLabel="name"
                                    name="accountClassificationId"
                                    optionValue="lovId"
                                    value={formik.values.accountClassificationId || ''}
                                    // onChange={formik.handleChange}
                                    onChange={handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountClassificationId') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pb-3">
                            <div className="p-field">
                                <label htmlFor="agentId" className={classNames({ 'p-error': isFormFieldValid('kycSets') }, 'Label__Text')}>
                                    Agent Id
                                </label>
                                <InputText
                                    id="agentId"
                                    placeholder="Enter Agent Id"
                                    name="agentId"
                                    value={formik.values.agentId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('agentId') }, 'Input__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pb-3">
                            <div className="p-field">
                                <label htmlFor="cnic" className={classNames({ 'p-error': isFormFieldValid('cnic') }, 'Label__Text')}>
                                    CNIC
                                </label>
                                <InputText
                                    keyfilter="int"
                                    maxLength={13}
                                    id="cnic"
                                    placeholder="Enter CNIC"
                                    name="cnic"
                                    value={formik.values.cnic || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cnic') }, 'Input__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pb-3">
                            <div className="p-field">
                                <label htmlFor="mobileNumber" className={classNames({ 'p-error': isFormFieldValid('mobileNumber') }, 'Label__Text')}>
                                    Mobile Number
                                </label>
                                <InputText
                                    keyfilter="int"
                                    maxLength={11}
                                    id="mobileNumber"
                                    placeholder="Enter Mobile Number"
                                    name="mobileNumber"
                                    value={formik.values.mobileNumber || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('mobileNumber') }, 'Input__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pb-3">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Updated By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>

                                <InputText
                                    max={previousDate}
                                    id="dateFrom"
                                    type="date"
                                    name="dateFrom"
                                    value={formik?.values?.dateFrom}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>

                                <InputText max={previousDate} id="dateTo" type="date" name="dateTo" value={formik?.values?.dateTo} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')} />

                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="statusId" className={classNames({ 'p-error': isFormFieldValid('statusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select Status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />
                            </div>
                        </div>
                        <div className="Down__Btn mt-4">
                            <Button label="Search" icon={loadingIcon || ''} iconPos="left" className="Btn__Dark" />
                            <Button label="Reset" iconPos="right" className="Btn__Transparent" onClick={(e) => handleReset(e)} />
                        </div>
                    </div>
                </form>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Account Types"
                            filters={filters}
                            globalFilterFields={['classificationData.name', 'agentId', 'createdBy', 'updatedBy', 'createdate', 'lastupdatedate', 'Status']}
                            header={header}
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={newDataArray}
                        >
                            {/* <Column field="classificationData.name" header="Account Title" /> */}
                            <Column field="classificationData.name" header="Account Classification" />
                            <Column field="agentId" header="Agent Id" />
                            <Column field="parentName" header="Parent" />
                            <Column field="createdBy" header="Created By" />
                            <Column field="updatedBy" header="Updated By" />
                            <Column body={createdDateAndTimeBodyTemplate} header="Created Date & Time" />
                            <Column header="Status" body={statusBodyTemplate} />
                            <Column header="Action" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};
export default AgentOnboarding;

import React from 'react';
import { InputText } from 'primereact/inputtext';

function DynamicKYCFields({ kycFields, formik, kycFixed, isEditable, getFormErrorMessage }) {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    function convertToTitleCase(inputString) {
        const words = inputString.split(/(?=[A-Z])/);
        const titleCaseString = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

        return titleCaseString;
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    return (
        <>
            <div className="card Card__Round">
                {kycFields && (
                    <div className="p-fluid p-formgrid grid mb-2 ">
                        {kycFields[0]?.tblKycSetDetails?.map((item, key) => {
                            return (
                                <React.Fragment key={key}>
                                    {item?.isActive === 'Y' && (
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field withoutArrow">
                                                <label htmlFor={item?.tblKycAttribute?.attributeName} className="Label__Text">
                                                    {convertToTitleCase(item?.tblKycAttribute?.attributeName)}
                                                    {(kycFixed?.includes(item?.tblKycAttribute?.attributeName) || item?.isMandatory === 'Y') && <span className="Label__Required">*</span>}
                                                </label>
                                                <InputText
                                                    id={item?.tblKycAttribute?.attributeName}
                                                    name={item?.tblKycAttribute?.attributeName}
                                                    value={formik?.values[item?.tblKycAttribute?.attributeName] || ''}
                                                    onChange={formik.handleChange}
                                                    className="Input__Round"
                                                    type={item?.tblKycAttribute?.attributeName.toLowerCase().includes('date') ? 'date' : 'text'}
                                                    disabled={kycFixed?.includes(item?.tblKycAttribute?.attributeName) || item?.tblKycAttribute?.isEditable === 'N' || !isEditable}
                                                    max={item?.tblKycAttribute?.attributeName.toLowerCase().includes('date') ? previousDate : null}
                                                    onKeyDown={(e) => handleKeyPress(e)}
                                                />
                                                {getFormErrorMessage(item?.tblKycAttribute?.attributeName)}
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                )}
            </div>
        </>
    );
}

export default DynamicKYCFields;

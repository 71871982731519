import React from 'react';
// import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';

const ChannelDetails = ({ channelBlockingResponse, mcReqChannelDetailData }) => {
    return (
        <section>
            <div className="p-fluid p-formgrid grid">
                <div className="p-field col-12 md:col-4">
                    <label>International POS Allowed</label>
                    <div className="flex pt-3">
                        <div className="mr-8 flex">
                            <Checkbox checked={mcReqChannelDetailData?.isChannelUpdate ? mcReqChannelDetailData?.outPosInt === 'N' : channelBlockingResponse?.outPosInt === 'N'} />
                            <label className="ml-2">No</label>
                        </div>
                        <div className="flex">
                            <Checkbox checked={mcReqChannelDetailData?.isChannelUpdate ? mcReqChannelDetailData?.outPosInt === 'Y' : channelBlockingResponse?.outPosInt === 'Y'} />
                            <label className="ml-2">Yes</label>
                        </div>
                    </div>
                </div>
                <div className="p-field col-12 md:col-4">
                    <label>Local POS Allowed</label>
                    <div className="flex pt-3">
                        <div className="mr-8 flex">
                            <Checkbox checked={mcReqChannelDetailData?.isChannelUpdate ? mcReqChannelDetailData?.outPosDom === 'N' : channelBlockingResponse?.outPosDom === 'N'} />
                            <label className="ml-2">No</label>
                        </div>
                        <div className="flex">
                            <Checkbox checked={mcReqChannelDetailData?.isChannelUpdate ? mcReqChannelDetailData?.outPosDom === 'Y' : channelBlockingResponse?.outPosDom === 'Y'} />
                            <label className="ml-2">Yes</label>
                        </div>
                    </div>
                </div>
                <div className="p-field col-12 md:col-4">
                    <label>International ATM Allowed</label>
                    <div className="flex pt-3">
                        <div className="mr-8 flex">
                            <Checkbox checked={mcReqChannelDetailData?.isChannelUpdate ? mcReqChannelDetailData?.outAtmInt === 'N' : channelBlockingResponse?.outAtmInt === 'N'} />
                            <label className="ml-2">No</label>
                        </div>
                        <div className="flex">
                            <Checkbox checked={mcReqChannelDetailData?.isChannelUpdate ? mcReqChannelDetailData?.outAtmInt === 'Y' : channelBlockingResponse?.outAtmInt === 'Y'} />
                            <label className="ml-2">Yes</label>
                        </div>
                    </div>
                </div>
                <div className="p-field col-12 md:col-4">
                    <label>Local ATM Allowed</label>
                    <div className="flex pt-3">
                        <div className="mr-8 flex">
                            <Checkbox checked={mcReqChannelDetailData?.isChannelUpdate ? mcReqChannelDetailData?.outAtmDom === 'N' : channelBlockingResponse?.outAtmDom === 'N'} />
                            <label className="ml-2">No</label>
                        </div>
                        <div className="flex">
                            <Checkbox checked={mcReqChannelDetailData?.isChannelUpdate ? mcReqChannelDetailData?.outAtmDom === 'Y' : channelBlockingResponse?.outAtmDom === 'Y'} />
                            <label className="ml-2">Yes</label>
                        </div>
                    </div>
                </div>
                <div className="p-field col-12 md:col-4">
                    <label>International Ecommerce Allowed</label>
                    <div className="flex pt-3">
                        <div className="mr-8 flex">
                            <Checkbox checked={mcReqChannelDetailData?.isChannelUpdate ? mcReqChannelDetailData?.outEcommInt === 'N' : channelBlockingResponse?.intEcommerceAllowed === 'N'} />
                            <label className="ml-2">No</label>
                        </div>
                        <div className="flex">
                            <Checkbox checked={mcReqChannelDetailData?.isChannelUpdate ? mcReqChannelDetailData?.outEcommInt === 'Y' : channelBlockingResponse?.intEcommerceAllowed === 'Y'} />
                            <label className="ml-2">Yes</label>
                        </div>
                    </div>
                </div>
                <div className="p-field col-12 md:col-4">
                    <label>Local Ecommerce Allowed</label>
                    <div className="flex pt-3">
                        <div className="mr-8 flex">
                            <Checkbox checked={mcReqChannelDetailData?.isChannelUpdate ? mcReqChannelDetailData?.outEcomm === 'N' : channelBlockingResponse?.ecomm === 'N'} />
                            <label className="ml-2">No</label>
                        </div>
                        <div className="flex">
                            <Checkbox checked={mcReqChannelDetailData?.isChannelUpdate ? mcReqChannelDetailData?.outEcomm === 'Y' : channelBlockingResponse?.ecomm === 'Y'} />
                            <label className="ml-2">Yes</label>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ChannelDetails;

import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';

import { Column } from 'primereact/column';
import { handlePostRequest } from '../../services/PostTemplate';
import { handleGetRequest } from '../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function AdduseCaseManagement() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [processNameData, setProcessNameData] = useState([]);
    const [roleData, setRoleData] = useState([]);
    const [payloadData, setPayloadData] = useState([]);
    const [dataShowToTable, setDataShowToTable] = useState([]);
    const [userLov, setUserLov] = useState([]);

    const [useCaseError, setUseCaseError] = useState({
        roleId: '',
        approvalCriteria: '',
        userId: ''
    });

    const validationSchema = Yup.object().shape({
        processName: Yup.string().required('This Feild Is Required'),

        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const dt = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formik = useFormik({
        validationSchema,
        initialValues: {
            useCaseName: '',
            useCaseDescr: '',
            processName: '',
            useCaseComments: '',
            useCaseDetailRequests: [
                {
                    approvalType: 'A',
                    intimateOnly: '',
                    approvalCriteria: '',
                    userId: '',
                    roleId: ''
                }
            ]
        },
        validate: (data) => {
            let errors = {};

            //////////////////////////useCaseName////////////////////////
            if (!data?.useCaseName) {
                errors.useCaseName = 'This field is required';
            } else if (data?.useCaseName === '') {
                errors.useCaseName = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.useCaseName)) {
                errors.useCaseName = 'Leading and trailing space is not allowed';
            } else if (!/^[a-zA-Z0-9\s]*$/.test(data?.useCaseName)) {
                errors.useCaseName = 'Special characters are not allowed';
            }
            // //////////////////////////useCaseComments////////////////////////
            if (!data?.useCaseComments) {
                errors.useCaseComments = 'This field is required';
            } else if (data?.useCaseComments === '') {
                errors.useCaseComments = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.useCaseComments)) {
                errors.useCaseComments = 'Leading and trailing space is not allowed';
            }
            // //////////////////////////useCaseDescr////////////////////////
            if (!data?.useCaseDescr) {
                errors.useCaseDescr = 'This field is required';
            } else if (data?.useCaseDescr === '') {
                errors.useCaseDescr = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.useCaseDescr)) {
                errors.useCaseDescr = 'Leading and trailing space is not allowed';
            }

            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            const foundItem = processNameData.find((item) => item.mcFormNameId === data.processName);

            const payloadDataNew = {
                useCaseName: data.useCaseName,
                useCaseDescr: data.useCaseDescr,
                useCaseComments: data.useCaseComments,
                processName: {
                    tableName: foundItem.tableName,
                    formName: foundItem.formName,
                    viewDetailUrl: foundItem.viewDetailUrl,
                    editDetailUrl: foundItem.editDetailUrl,
                    requestType: foundItem.requestType
                },
                useCaseDetailRequests: payloadData
            };

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: payloadDataNew,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            if (payloadData.length <= 0) {
                toast.warning('Please  fill Define Approval ');
            } else {
                const res = await dispatch(handlePostRequest(newData, '/mfs_user_management/v1/management/createusecase', true, false, 'usecaseManagement'));
                if (res?.responseCode === '030000') {
                    formik.resetForm();
                }
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const handleAdd = (event) => {
        event.preventDefault();

        if (formik.values.approvalCriteria !== undefined) {
            // validation on Define approval section
            formik.validateForm().then((errors) => {
                if (formik.values.approvalCriteria === 'R' && !formik.values.roleId) {
                    setUseCaseError({ roleId: 'This Feild is Required.' });
                    return;
                } else if (formik.values.approvalCriteria === 'U' && !formik.values.userId) {
                    setUseCaseError({ userId: 'This Feild is required' });

                    return;
                }
                formik.setErrors(errors);
            });
            let matchApproval = statusData.find((dataItem) => dataItem.value === formik.values.approvalCriteria);
            let matchUser = userLov.find((dataItem) => dataItem.lovId === formik.values.userId);
            let matchRole = roleData.find((dataItem) => dataItem.lovId === formik.values.roleId);

            let matchCriteria = formik.values.useCaseDetailRequests[0].approvalType === 'N' ? 'Any One' : formik.values.useCaseDetailRequests[0].approvalType === 'A' ? 'All' : '';
            const data = {
                approvalCriteria: formik.values.approvalCriteria,
                userId: formik.values.userId,
                roleId: formik.values.roleId,

                intimateOnly: formik.values.intimateOnly ? 'Y' : 'N',
                approvalType: formik.values.useCaseDetailRequests[0].approvalType
            };
            if (formik.values.roleId) {
                let dataForTable = {
                    approvalCriteria: matchApproval ? matchApproval?.label : '',
                    userId: matchUser ? matchUser?.name : '',
                    roleId: matchRole ? matchRole?.name : '',

                    intimateOnly: formik.values.intimateOnly ? 'Yes' : 'No',
                    approvalType: matchCriteria ? matchCriteria : ''
                };

                setDataShowToTable([...dataShowToTable, dataForTable]);
                formik.setFieldValue('approvalCriteria', undefined);
                formik.setFieldValue('intimateOnly', false);
                formik.setFieldValue('useCaseDetailRequests[0].approvalType', 'A');
                setPayloadData([...payloadData, data]);
            } else if (formik.values.userId) {
                let dataForTable = {
                    approvalCriteria: matchApproval ? matchApproval?.label : '',
                    userId: matchUser ? matchUser?.name : '',
                    roleId: matchRole ? matchRole?.name : '',
                    // emailTemplateId: matchTemplate ? matchTemplate?.name : '',
                    intimateOnly: formik.values.intimateOnly ? 'Yes' : 'No'
                };
                setDataShowToTable([...dataShowToTable, dataForTable]);
                formik.setFieldValue('approvalCriteria', undefined);
                formik.setFieldValue('intimateOnly', false);
                formik.setFieldValue('useCaseDetailRequests[0].approvalType', 'A');

                setPayloadData([...payloadData, data]);
            }
        }
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const renderHeader = () => {
        return <div className="Global__Header"></div>;
    };

    const header = renderHeader();

    //dropdown data

    const statusData = [
        { label: 'Role Based ', value: 'R' },
        { label: 'User Based ', value: 'U' }
    ];

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };
    const getProcess = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getprocessname/LKP_MC_FORM_NAME:*');
        setProcessNameData(resp?.payLoad);
    };

    const getRole = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getUserGroup/TBL_ROLE:*');
        setRoleData(resp?.payLoad);
    };
    useEffect(() => {
        getRole();
        // getEmailTemplate();
        getUserLov();
        getProcess();
    }, []); // eslint-disable-line

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    // const previousDate = currentDate.toISOString().split('T')[0];

    const actionBodyTemplate = (rowData, rowIndex) => {
        const handleDelete = () => {
            // Create a copy of the dataShowToTable array
            const updatedData = [...dataShowToTable];
            const updatedPayloadData = [...payloadData];
            // Remove the selected row by index
            updatedData.splice(rowIndex, 1);
            updatedPayloadData.splice(rowIndex, 1);
            // Update the state with the modified data
            setDataShowToTable(updatedData);
            setPayloadData(updatedPayloadData);
        };

        return (
            <>
                <div className="actions">
                    <Button type="button" tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={handleDelete} className="p-button-rounded p-button-danger mr-5" />
                </div>
            </>
        );
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>ADD NEW</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="useCaseName" className={classNames({ 'p-error': isFormFieldValid('useCaseName') }, 'Label__Text')}>
                                    Use Case Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="useCaseName"
                                    placeholder="Enter Use Case Name"
                                    name="useCaseName"
                                    value={formik.values.useCaseName}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('useCaseName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('useCaseName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="useCaseComments" className={classNames({ 'p-error': isFormFieldValid('useCaseComments') }, 'Label__Text')}>
                                    Comment <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="useCaseComments"
                                    placeholder="Add Comment"
                                    name="useCaseComments"
                                    value={formik.values.useCaseComments?.replace(/\s\s+/g, '')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('useCaseComments') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('useCaseComments')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="approval" className={classNames({ 'p-error': isFormFieldValid('approval') }, 'Label__Text')}>
                                    Process Name <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="processName"
                                    options={processNameData}
                                    optionLabel="formName"
                                    name="processName"
                                    optionValue="mcFormNameId"
                                    value={formik.values.processName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('processName') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('processName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="useCaseDescr" className={classNames({ 'p-error': isFormFieldValid('useCaseDescr') }, 'Label__Text')}>
                                    Description <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    id="useCaseDescr"
                                    name="useCaseDescr"
                                    value={formik.values.useCaseDescr}
                                    rows={5}
                                    cols={10}
                                    autoResize="false"
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('useCaseDescr') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('useCaseDescr')}
                            </div>
                        </div>

                        <div className="col-12 md:col-8 Form__Header Full__Width"></div>
                    </div>
                    <div className="card p-fluid p-formgrid grid  " style={{ backgroundColor: 'rgb(237, 240, 245)' }}>
                        <div className="p-field col-12 md:col-3">
                            <div className="p-field">
                                <label htmlFor="approvalCriteria" className={classNames({ 'p-error': isFormFieldValid('approvalCriteria') }, 'Label__Text')}>
                                    Define Approval
                                </label>
                                <Dropdown
                                    id="approvalType"
                                    options={statusData}
                                    optionLabel="label"
                                    name="approvalCriteria"
                                    optionValue="value"
                                    value={formik.values.approvalCriteria || ''}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        formik.setFieldValue('roleId', undefined);
                                        formik.setFieldValue('approvalType', '');
                                        formik.setFieldValue('userId', undefined);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldValid('approvalCriteria') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('approvalCriteria')}
                            </div>
                        </div>
                        {formik.values.approvalCriteria === 'R' ? (
                            <div className="p-field col-12 md:col-3">
                                <div className="p-field">
                                    <label htmlFor="roleId" className={classNames({ 'p-error': isFormFieldValid('roleId') }, 'Label__Text')}>
                                        Role
                                    </label>
                                    <Dropdown
                                        id="roleId"
                                        options={roleData}
                                        optionLabel="name"
                                        name="roleId"
                                        optionValue="lovId"
                                        value={formik.values.roleId || ''}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            // handledFieldInputData()
                                            setUseCaseError({ roleId: '' });
                                        }}
                                        className={classNames({ 'p-invalid': isFormFieldValid('roleId') }, 'Dropdown__Round')}
                                    />
                                    {useCaseError.roleId && <small className="p-error">{useCaseError.roleId}</small>}
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {formik.values.approvalCriteria === 'U' ? (
                            <div className="p-field col-12 md:col-3">
                                <div className="p-field">
                                    <label htmlFor="userId" className={classNames({ 'p-error': isFormFieldValid('userId') }, 'Label__Text')}>
                                        User
                                    </label>
                                    <Dropdown
                                        id="userId"
                                        options={userLov}
                                        optionLabel="name"
                                        name="userId"
                                        optionValue="lovId"
                                        value={formik.values.userId || ''}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            // handledFieldInputData()
                                            setUseCaseError({ userId: '' });
                                        }}
                                        className={classNames({ 'p-invalid': isFormFieldValid('userId') }, 'Dropdown__Round')}
                                    />
                                    <small className="p-error">{useCaseError.userId}</small>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {formik.values.approvalCriteria === 'R' ? (
                            <div className="col-12 lg:col-6 xl:col-4 md:col-4 pt-5 pb-5 flex">
                                <div className="flex justify-content-center gap-2">
                                    <div className="flex ">
                                        <div className="">
                                            <label htmlFor="approvalType" className={classNames({ 'p-error': isFormFieldValid('velocity') }, 'Label__Text')}>
                                                Approval Criteria
                                            </label>
                                        </div>

                                        <div className="flex">
                                            <div className="flex ml-3">
                                                <RadioButton
                                                    inputId="approvalTypeAnyone"
                                                    name="approvalType"
                                                    value="N"
                                                    onChange={(e) => {
                                                        formik.setFieldValue('useCaseDetailRequests[0].approvalType', e.target.value);
                                                    }}
                                                    checked={formik.values.useCaseDetailRequests[0].approvalType === 'N'}
                                                />
                                                <label htmlFor="approvalType" className="ml-2">
                                                    Any One
                                                </label>
                                            </div>
                                            <div style={{ marginLeft: '5rem' }} className="flex mr-3">
                                                <RadioButton
                                                    inputId="approvalType"
                                                    name="approvalType"
                                                    value="A"
                                                    onChange={(e) => {
                                                        formik.setFieldValue('useCaseDetailRequests[0].approvalType', e.target.value);
                                                    }}
                                                    checked={formik.values.useCaseDetailRequests[0].approvalType === 'A'}
                                                />
                                                <label htmlFor="approvalType" className="ml-2">
                                                    All
                                                </label>
                                            </div>
                                        </div>
                                        {getFormErrorMessage('approvalType')}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        <div className="">
                            <div className="p-field col-12 md:col-2 pt-5 pb-5 flex">
                                <div className="p-field">
                                    <div className="field-checkbox">
                                        <label htmlFor="intimateOnly" className={classNames({ 'p-error': isFormFieldValid('intimateOnly') }, 'Label__Text')}>
                                            Intimate Only
                                        </label>
                                        <Checkbox inputId="intimateOnly" name="intimateOnly" value={formik.values.intimateOnly} checked={formik.values.intimateOnly} onChange={formik.handleChange} className=" ml-3" />
                                    </div>
                                    {getFormErrorMessage('intimateOnly')}
                                </div>
                            </div>
                        </div>

                        <div className="Usergroup__SearchBtn p-field flex flex-row  justify-content-center pt-2 col-12" style={{ textAlign: 'center' }}>
                            <div className="">
                                <Button
                                    onClick={(event) => handleAdd(event)}
                                    label="Add"
                                    // disabled={isButtonDisabled()}
                                    className="Btn__Dark__Search"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card">
                            <DataTable
                                ref={dt}
                                paginator
                                rows={5}
                                rowsPerPageOptions={[5, 10, 25]}
                                className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                header={header}
                                emptyMessage="No List found."
                                responsiveLayout="scroll"
                                value={dataShowToTable}
                            >
                                <Column body={(data, props) => <div>{props.rowIndex + 1}</div>} header="Sr" />

                                <Column field="approvalCriteria" header="Define Approval" />
                                <Column field="userId" header="User" />
                                <Column field="roleId" header="Role" />
                                <Column field="intimateOnly" header="Intimate Only" />
                                <Column field="approvalType" header="Approval Criteria" />
                                <Column header="Action" body={actionBodyTemplate} />
                            </DataTable>

                            <div className="Down__Btn pt-2">
                                <Button disabled={loading} loadingIcon={loadingIcon} label="Submit" type="submit" className="Btn__Dark" />
                                <Button disabled={loading} onClick={() => navigate('/useCaseManagement')} label="Cancel" className="Btn__Transparent" />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
export default AdduseCaseManagement;

import React from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

const EditAccountType = ({ onAccountTypeChange, formikValuees, accountTypeData, onHide }) => {
    const validationSchema = Yup.object().shape({
        accountTypeId: Yup.string().required('This Field is required.'),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            accountTypeId: formikValuees?.accountTypeId ? formikValuees?.accountTypeId : "",
        },

        onSubmit: async () => {
            onAccountTypeChange(formik.values.accountTypeId);
            onHide();
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className='Card__Round'>

                    <div className="p-fluid p-formgrid grid">
                        <div className="p-field col-12">
                            <label className='Label__Text'>
                                Name
                            </label>
                            <Dropdown
                                id="accountTypeId"
                                name="accountTypeId"
                                placeholder="--Select--"
                                options={accountTypeData}
                                optionLabel="name"
                                optionValue='lovId'
                                value={formik.values.accountTypeId}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('accountTypeId') }, 'Dropdown__Round')}
                            />
                            {getFormErrorMessage("accountTypeId")}
                        </div>
                    </div>

                    <div className='Down__Btn mt-4'>
                        <Button label="Confirm" iconPos="right" className="Btn__Dark" type="submit" />
                        <Button label="Cancel" iconPos="right" className="Btn__cancel" type="button" onClick={onHide} />
                    </div>

                </div>
            </form>
        </>
    )
}

export default EditAccountType
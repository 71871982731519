import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useRef } from 'react';

function AddBiller() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    const validationSchema = Yup.object().shape({
        clientEmail: Yup.string().matches('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$', 'Please enter valid email'),
        segmentName: Yup.string().required('This field is required')
    });

    const formik = useFormik({
        // validationSchema: validationSchema,
        initialValues: {
            productName: '',
            displayNameEnglish: '',
            displayNameUrdu: '',
            productId: '',
            addTag: false,
            tagLabel: '',
            tagTextColor: '',
            tagExpiryDate: '',
            category: '',
            subCategory: '',
            enablePartialPayment: '',
            enable: '',
            disable: '',
            fields: false,
            button: false,
            selectButton: '',
            text: '',
            selectField: '',
            title: '',
            characterLimit: '',
            placeholderValue: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        cmsCompanyId: 1,
                        fileName: 'Test File Name #3',
                        serialNo: 1,
                        totalRecords: 2,
                        tblCmsFileDetails: [
                            {
                                customerReference: 'No reference found',
                                customerName: 'Test Customer #1',
                                billingMonth: 'Nov',
                                billingYear: 2023,
                                amount: 20,
                                dueDate: '2024-10-19',
                                amountAfterDd: 10,
                                transactionCode: 'ins_code'
                            },
                            {
                                customerReference: 'No reference found',
                                customerName: 'Test Customer #3',
                                billingMonth: 'Aug',
                                billingYear: 2023,
                                amount: 20,
                                dueDate: '2024-10-19',
                                amountAfterDd: 10,
                                transactionCode: 'ins_code'
                            }
                        ]
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(newData, '/cms/v1/cms/savefile', true, false, 'biller'));
        
            if (res?.responseCode === '190000') {
                formik.resetForm();
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>ADD NEW</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productName" className={classNames({ 'p-error': isFormFieldValid('productName') }, 'Label__Text')}>
                                    Code <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="productName"
                                    placeholder=""
                                    name="productName"
                                    value={formik?.values?.productName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('productName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="displayNameEnglish" className={classNames({ 'p-error': isFormFieldValid('displayNameEnglish') }, 'Label__Text')}>
                                    Email <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="displayNameEnglish"
                                    placeholder=""
                                    name="displayNameEnglish"
                                    value={formik?.values?.displayNameEnglish?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('displayNameEnglish') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('displayNameEnglish')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="displayNameEnglish" className={classNames({ 'p-error': isFormFieldValid('displayNameEnglish') }, 'Label__Text')}>
                                    Phone number <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="displayNameEnglish"
                                    placeholder=""
                                    name="displayNameEnglish"
                                    value={formik?.values?.displayNameEnglish?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('displayNameEnglish') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('displayNameEnglish')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="displayNameEnglish" className={classNames({ 'p-error': isFormFieldValid('displayNameEnglish') }, 'Label__Text')}>
                                    Product Code <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="displayNameEnglish"
                                    placeholder=""
                                    name="displayNameEnglish"
                                    value={formik?.values?.displayNameEnglish?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('displayNameEnglish') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('displayNameEnglish')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="displayNameEnglish" className={classNames({ 'p-error': isFormFieldValid('displayNameEnglish') }, 'Label__Text')}>
                                    Description <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="displayNameEnglish"
                                    placeholder=""
                                    name="displayNameEnglish"
                                    value={formik?.values?.displayNameEnglish?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('displayNameEnglish') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('displayNameEnglish')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="displayNameEnglish" className={classNames({ 'p-error': isFormFieldValid('displayNameEnglish') }, 'Label__Text')}>
                                    Parser Head <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="displayNameEnglish"
                                    placeholder=""
                                    name="displayNameEnglish"
                                    value={formik?.values?.displayNameEnglish?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('displayNameEnglish') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('displayNameEnglish')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="enablePartialPayment" className={classNames({ 'p-error': isFormFieldValid('enablePartialPayment') }, 'Label__Text')}>
                                    Status <span className="Label__Required">*</span>
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox
                                        inputId="enablePartialPayment"
                                        name="enablePartialPayment"
                                        defaultChecked={true}
                                        value={formik?.values?.enablePartialPayment}
                                        checked={formik?.values?.enablePartialPayment}
                                        onChange={formik.handleChange}
                                        style={{ marginTop: '10px' }}
                                    />
                                    <label htmlFor="enablePartialPayment" className={classNames({ 'p-error': isFormFieldValid('enablePartialPayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        active
                                    </label>
                                </div>
                                {getFormErrorMessage('enablePartialPayment')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="submit" label="Add" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/product')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddBiller;

import React from 'react';
import CheckerApis from '../Components/CheckerApis';

function CheckerEditUav() {
    return (
        <React.Fragment>
            <CheckerApis type="checkerEdit" />
        </React.Fragment>
    );
}

export default CheckerEditUav;

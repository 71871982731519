import * as Yup from 'yup';

const PercentageOfTrxAmountInitialValues = {
    commissionProfileName: '',
    accountClassificationId: '',
    feeTypeCode: 'PF',
    whtCalculationType: '',
    commissionPercentage: '',
    commissionGlAccountId: '',
    whtGlAccountId: '',
    disbursementMethodName: '',
    disbursementTime: '',
    startDate: '',
    endDate: '',
    products: []
};

const validationSchema = Yup.object().shape({
    commissionProfileName: Yup.string()
        .required('This field is required')
        .matches(/^[a-zA-Z0-9\s]*$/, 'Only alpha numeric characters are allowed')
        .max(100, 'Maximum 100 digits allowed'),
    accountClassificationId: Yup.mixed().required('This field is required'),
    whtCalculationType: Yup.mixed().required('This field is required'),
    commissionPercentage: Yup.string()
        .required('This field is required')
        .test('Is positive?', 'The number must be greater than 0', (value) => value > 0)
        // .test('less-than-or-equal-to-100', 'Value must be less than or equal to 100', (value) => {
        //     if (!value) {
        //         return false;
        //     }
        //     const numericValue = parseFloat(value);
        //     return numericValue <= 100;
        // })
        .nullable(),
    commissionGlAccountId: Yup.mixed().required('This field is required'),
    whtGlAccountId: Yup.mixed().required('This field is required'),
    disbursementMethodName: Yup.mixed().required('This field is required'),
    startDate: Yup.date().required('This field is required').nullable(),
    endDate: Yup.date().when('startDate', {
        is: (startDate) => !!startDate,
        then: Yup.date().required('End Date is required').min(Yup.ref('startDate'), 'End Date has to be more than Start Date')
    })
});

export { PercentageOfTrxAmountInitialValues, validationSchema };

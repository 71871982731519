import React from 'react';
import cmsNotifications from '../../../../assets/cms/cmsNotifications.png';

function MobileView({ icon, title, descrition }) {
    return (
        <div className="cmsMobileView__main relative">
            <div className="cmsMobileView__notifications">
                {icon && <img src={icon} className="cmsMobileView__icon" alt="icon" />}
                {title && <p className="cmsMobileView__title">{title}</p>}
                {descrition && <p className="cmsMobileView__description">{descrition}</p>}
            </div>
            <img src={cmsNotifications} className="blurMobile w-100 h-100" alt="cmsNotifications" />
        </div>
    );
}

export default MobileView;

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';
import { getLkpCity } from '../../../../redux/slices/LkpCitySlice';

function EditGeographicJurisdictionChecker({ activeIndex, handleStepClick, responseId, riskProfileData }) {

    const [locationLov, setLocationLov] = useState([]);
    const [districtLov, setDistrictLov] = useState([]);

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const requestType = query.get('requestType');
    const requestTypeDescr = query.get('requestTypeDescr');

    const dispatch = useDispatch();

    const lkpCityLov = useSelector((state) => state?.LkpCitySlice?.data);

    const getHraLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/gethraaccountdata');
        setLocationLov(resp?.payLoad?.lkpCountry);
    };
    useEffect(() => {
        getHraLov();
    }, []);

    useEffect(() => {
        if (requestType === 'I' || (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request')) {
            if (riskProfileData) {
                const CTDropdownData = riskProfileData?.tblRpCrpDetails;

                if (CTDropdownData) {
                    const LCHighId = [];
                    const LCMediumId = [];
                    const LCLowId = [];

                    const PTAHighId = [];
                    const PTAMediumId = [];
                    const PTALowId = [];

                    const MAHighId = [];
                    const MAMediumId = [];
                    const MALowId = [];

                    CTDropdownData.forEach((value) => {
                        const referenceId = value?.refId;

                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'LC') {
                            // GET Counter Parties Data
                            if (value?.rpRating === 'H') {
                                LCHighId.push(referenceId);

                                formik.setFieldValue('counterPartiesHighRisk', LCHighId);
                                formik.setFieldValue('counterPartiesOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                LCMediumId.push(referenceId);

                                formik.setFieldValue('counterPartiesMediumRisk', LCMediumId);
                            }
                            if (value?.rpRating === 'L') {
                                LCLowId.push(referenceId);

                                formik.setFieldValue('counterPartiesLowRisk', LCLowId);
                            }
                        }
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'PTA') {
                            // GET Permanent Address Data
                            if (value?.rpRating === 'H') {
                                PTAHighId.push(referenceId);

                                formik.setFieldValue('permanentAddressHighRisk', PTAHighId.map(String));
                                formik.setFieldValue('permanentAddressOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                PTAMediumId.push(referenceId);

                                formik.setFieldValue('permanentAddressMediumRisk', PTAMediumId.map(String));
                            }
                            if (value?.rpRating === 'L') {
                                PTALowId.push(referenceId);

                                formik.setFieldValue('permanentAddressLowRisk', PTALowId.map(String));
                            }
                        }
                        if (value?.tblRpRiskFactor?.lkpRpFactorRisk?.riskFactorCode === 'MA') {
                            // GET Mailing Address Data
                            if (value?.rpRating === 'H') {
                                MAHighId.push(referenceId);

                                formik.setFieldValue('mailingAddressHighRisk', MAHighId.map(String));
                                formik.setFieldValue('mailingAddressOverRideRule', value?.overrideRule === 'N' ? 'false' : 'true');
                            }
                            if (value?.rpRating === 'M') {
                                MAMediumId.push(referenceId);

                                formik.setFieldValue('mailingAddressMediumRisk', MAMediumId.map(String));
                            }
                            if (value?.rpRating === 'L') {
                                MALowId.push(referenceId);

                                formik.setFieldValue('mailingAddressLowRisk', MALowId.map(String));
                            }
                        }
                    });
                }
            }
        } else if (requestType === 'U') {
            // GET Counter Parties Data
            formik.setFieldValue(
                'counterPartiesHighRisk',
                riskProfileData[1]?.reqMap[0]?.LC?.highRisk?.map((value) => value)
            );
            formik.setFieldValue(
                'counterPartiesMediumRisk',
                riskProfileData[1]?.reqMap[0]?.LC?.medRisk?.map((value) => value)
            );
            formik.setFieldValue(
                'counterPartiesLowRisk',
                riskProfileData[1]?.reqMap[0]?.LC?.lowRisk?.map((value) => value)
            );
            formik.setFieldValue('counterPartiesOverRideRule', riskProfileData[1]?.reqMap[0]?.LC?.overRideRule === true ? 'true' : 'false');

            // GET Permanent Address Data
            formik.setFieldValue(
                'permanentAddressHighRisk',
                riskProfileData[1]?.reqMap[1]?.PTA?.highRisk?.map((value) => value).map(String)
            );
            formik.setFieldValue(
                'permanentAddressMediumRisk',
                riskProfileData[1]?.reqMap[1]?.PTA?.medRisk?.map((value) => value).map(String)
            );
            formik.setFieldValue(
                'permanentAddressLowRisk',
                riskProfileData[1]?.reqMap[1]?.PTA?.lowRisk?.map((value) => value).map(String)
            );
            formik.setFieldValue('permanentAddressOverRideRule', riskProfileData[1]?.reqMap[1]?.PTA?.overRideRule === true ? 'true' : 'false');

            // GET Mailing Address Data
            formik.setFieldValue(
                'mailingAddressHighRisk',
                riskProfileData[1]?.reqMap[2]?.MA?.highRisk?.map((value) => value).map(String)
            );
            formik.setFieldValue(
                'mailingAddressMediumRisk',
                riskProfileData[1]?.reqMap[2]?.MA?.medRisk?.map((value) => value).map(String)
            );
            formik.setFieldValue(
                'mailingAddressLowRisk',
                riskProfileData[1]?.reqMap[2]?.MA?.lowRisk?.map((value) => value).map(String)
            );
            formik.setFieldValue('mailingAddressOverRideRule', riskProfileData[1]?.reqMap[2]?.MA?.overRideRule === true ? 'true' : 'false');
        }
    }, [riskProfileData]); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            stepNo: 2,
            crpId: responseId,
            counterPartiesHighRisk: [],
            counterPartiesMediumRisk: [],
            counterPartiesLowRisk: [],
            counterPartiesOverRideRule: 'false',
            permanentAddressHighRisk: [],
            permanentAddressMediumRisk: [],
            permanentAddressLowRisk: [],
            permanentAddressOverRideRule: 'false',
            mailingAddressHighRisk: [],
            mailingAddressMediumRisk: [],
            mailingAddressLowRisk: [],
            mailingAddressOverRideRule: 'false',
            reqMap: []
        },

        onSubmit: async (data) => {
            data['reqMap'] = [
                {
                    LC: {
                        highRisk: data?.counterPartiesHighRisk,
                        medRisk: [data?.counterPartiesMediumRisk],
                        lowRisk: [data?.counterPartiesLowRisk],
                        overRideRule: data?.counterPartiesOverRideRule
                    }
                },
                {
                    PTA: {
                        highRisk: data?.permanentAddressHighRisk,
                        medRisk: [data?.permanentAddressMediumRisk],
                        lowRisk: [data?.permanentAddressLowRisk],
                        overRideRule: data?.permanentAddressOverRideRule
                    }
                },
                {
                    MA: {
                        highRisk: data?.mailingAddressHighRisk,
                        medRisk: [data?.mailingAddressMediumRisk],
                        lowRisk: [data?.mailingAddressLowRisk],
                        overRideRule: data?.mailingAddressOverRideRule
                    }
                }
            ];
            delete data['counterPartiesHighRisk'];
            delete data['counterPartiesMediumRisk'];
            delete data['counterPartiesLowRisk'];
            delete data['counterPartiesOverRideRule'];
            delete data['permanentAddressHighRisk'];
            delete data['permanentAddressMediumRisk'];
            delete data['permanentAddressLowRisk'];
            delete data['permanentAddressOverRideRule'];
            delete data['mailingAddressHighRisk'];
            delete data['mailingAddressMediumRisk'];
            delete data['mailingAddressLowRisk'];
            delete data['mailingAddressOverRideRule'];

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            await dispatch(handlePostRequest(newData, '/riskprofile/v1/crp/saveupdatecrp', true, false));
            handleStepClick(activeIndex + 1);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        if (lkpCityLov === undefined || lkpCityLov.length === 0) dispatch(getLkpCity());
        const getDistrictData = async () => {
            const res = await handleGetRequest('/configuration/v1/lookups/getAllDistrict/LKP_DISTRICT:*');
            setDistrictLov(res?.payLoad);
        };
        getDistrictData();
    }, []);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {/* LOCATION OF COUNTER PARTIES */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>LOCATION OF COUNTER PARTIES</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="counterPartiesHighRisk" className={classNames({ 'p-error': isFormFieldValid('counterPartiesHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="counterPartiesHighRisk"
                                    placeholder="Select High Risk"
                                    options={locationLov}
                                    optionLabel="countryName"
                                    name="counterPartiesHighRisk"
                                    optionValue="countryId"
                                    value={formik?.values?.counterPartiesHighRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('counterPartiesHighRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('counterPartiesHighRisk')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="counterPartiesMediumRisk" className={classNames({ 'p-error': isFormFieldValid('counterPartiesMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="counterPartiesMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={locationLov}
                                    optionLabel="countryName"
                                    name="counterPartiesMediumRisk"
                                    optionValue="countryId"
                                    value={formik?.values?.counterPartiesMediumRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('counterPartiesMediumRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('counterPartiesMediumRisk')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="counterPartiesLowRisk" className={classNames({ 'p-error': isFormFieldValid('counterPartiesLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="counterPartiesLowRisk"
                                    placeholder="Select Low Risk"
                                    options={locationLov}
                                    optionLabel="countryName"
                                    name="counterPartiesLowRisk"
                                    optionValue="countryId"
                                    value={formik?.values?.counterPartiesLowRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('counterPartiesLowRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('counterPartiesLowRisk')}
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="counterPartiesOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="counterPartiesOverRideRule" disabled name="counterPartiesOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.counterPartiesOverRideRule === 'false'} />
                                    <label htmlFor="counterPartiesOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="counterPartiesOverRideRule" disabled name="counterPartiesOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.counterPartiesOverRideRule === 'true'} />
                                    <label htmlFor="counterPartiesOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* PERMANENT ADDRESS LOCATION */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>PERMANENT ADDRESS LOCATION</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="permanentAddressHighRisk" className={classNames({ 'p-error': isFormFieldValid('permanentAddressHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="permanentAddressHighRisk"
                                    placeholder="Select High Risk"
                                    options={lkpCityLov}
                                    name="permanentAddressHighRisk"
                                    optionLabel="name"
                                    optionValue="lovId"
                                    value={formik?.values?.permanentAddressHighRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('permanentAddressHighRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('permanentAddressHighRisk')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="permanentAddressMediumRisk" className={classNames({ 'p-error': isFormFieldValid('permanentAddressMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="permanentAddressMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={lkpCityLov}
                                    name="permanentAddressMediumRisk"
                                    optionLabel="name"
                                    optionValue="lovId"
                                    value={formik?.values?.permanentAddressMediumRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('permanentAddressMediumRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('permanentAddressMediumRisk')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="permanentAddressLowRisk" className={classNames({ 'p-error': isFormFieldValid('permanentAddressLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="permanentAddressLowRisk"
                                    placeholder="Select Low Risk"
                                    options={lkpCityLov}
                                    name="permanentAddressLowRisk"
                                    optionLabel="name"
                                    optionValue="lovId"
                                    value={formik?.values?.permanentAddressLowRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('permanentAddressLowRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('permanentAddressLowRisk')}
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="permanentAddressOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="permanentAddressOverRideRule" disabled name="permanentAddressOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.permanentAddressOverRideRule === 'false'} />
                                    <label htmlFor="permanentAddressOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="permanentAddressOverRideRule" disabled name="permanentAddressOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.permanentAddressOverRideRule === 'true'} />
                                    <label htmlFor="permanentAddressOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* MAILING ADDRESS/GEO-LOCATION COORDINATES OF DIGITAL GADGETS THROUGH WHICH, CUSTOMER HAS REQUESTED FOR DIGITAL ONBOARDING */}
                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h1 style={{ color: '#fff', marginLeft: '10px' }}>MAILING ADDRESS/GEO-LOCATION COORDINATES OF DIGITAL GADGETS THROUGH WHICH, CUSTOMER HAS REQUESTED FOR DIGITAL ONBOARDING</h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="mailingAddressHighRisk" className={classNames({ 'p-error': isFormFieldValid('mailingAddressHighRisk') }, 'Label__Text')}>
                                    High Risk
                                </label>
                                <MultiSelect
                                    id="mailingAddressHighRisk"
                                    placeholder="Select High Risk"
                                    options={districtLov}
                                    optionLabel="name"
                                    name="mailingAddressHighRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.mailingAddressHighRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('mailingAddressHighRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('mailingAddressHighRisk')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="mailingAddressMediumRisk" className={classNames({ 'p-error': isFormFieldValid('mailingAddressMediumRisk') }, 'Label__Text')}>
                                    Medium Risk
                                </label>
                                <MultiSelect
                                    id="mailingAddressMediumRisk"
                                    placeholder="Select Medium Risk"
                                    options={districtLov}
                                    optionLabel="name"
                                    name="mailingAddressMediumRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.mailingAddressMediumRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('mailingAddressMediumRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('mailingAddressMediumRisk')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="mailingAddressLowRisk" className={classNames({ 'p-error': isFormFieldValid('mailingAddressLowRisk') }, 'Label__Text')}>
                                    Low Risk
                                </label>
                                <MultiSelect
                                    id="mailingAddressLowRisk"
                                    placeholder="Select Low Risk"
                                    options={districtLov}
                                    optionLabel="name"
                                    name="mailingAddressLowRisk"
                                    optionValue="lovId"
                                    value={formik?.values?.mailingAddressLowRisk || ''}
                                    className={classNames({ 'p-invalid': isFormFieldValid('mailingAddressLowRisk') }, 'Dropdown__Round')}
                                    readOnly
                                />

                                {getFormErrorMessage('mailingAddressLowRisk')}
                            </div>
                        </div>
                        <div className="field col-12 md:col-4 pt-3 pb-3">
                            <label htmlFor="mailingAddressOverRideRule" className="Label__Text">
                                Risk Over-ride Rule
                            </label>
                            <div className="Radio__Btn">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="mailingAddressOverRideRule" disabled name="mailingAddressOverRideRule" value={'false'} onChange={formik.handleChange} checked={formik.values.mailingAddressOverRideRule === 'false'} />
                                    <label htmlFor="mailingAddressOverRideRule">No</label>
                                </div>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="mailingAddressOverRideRule" disabled name="mailingAddressOverRideRule" value={'true'} onChange={formik.handleChange} checked={formik.values.mailingAddressOverRideRule === 'true'} />
                                    <label htmlFor="mailingAddressOverRideRule">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
export default EditGeographicJurisdictionChecker;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
// import { Checkbox } from 'primereact/checkbox';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePutRequest } from '../../../../services/PutTemplate';

function AddEditFees() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-save');
    const [parserHeadData, setParserHeadData] = useState([]);

    const dispatch = useDispatch();

    //Getting params from URL using hash router

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const ubpId = query.get('ubpId');
    const editable = query.get('editable');

    //Getting ubpcompany data using ubpcompanyId

    const getUbpCompanyById = async () => {
        setloading(true);
        const res = await handleGetRequest(`/offline_Biller/company/getUbpCompanyById/${ubpId}`);
        const keyData = res?.data;
        setloading(false);

        Object.keys(keyData).forEach((key) => {
            if (formik.initialValues.hasOwnProperty(key)) {
                formik.setFieldValue(key, keyData[key]);
            }
        });
        formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
    };

    useEffect(() => {
        if (editable) {
            getUbpCompanyById();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        if (!editable) {
            formik.resetForm();
        }
    }, [editable]); // eslint-disable-line

    const handleCancel = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const getParserHeadData = async () => {
        const res = await handleGetRequest('/offline_Biller/lov/getAllParserHead');
        setParserHeadData(res?.data);
    };

    useEffect(() => {
        getParserHeadData();
    }, []);

    const productJson = [
        { description: 'Cash In' },
        { description: 'Cash out' },
        { description: 'Wallet to Cnic' },
        { description: 'Send Money' },
        { description: 'Wallet to JS Bank Account' },
        { description: 'Cnic to Wallet' },
        { description: 'Retail Payment' }
    ];

    const agentNetworkJson = [
        { description: 'Branch Teller Network' },
        { description: 'JSBL LSA Network' },
        { description: 'JSBL internal agent network' },
        { description: 'Default Network' },
        { description: 'Bayfir' },
        { description: 'WebService Agent Network' },
        { description: 'SCO AJK' }
    ];
    const segmentJson = [{ description: 'BLB payroll' }, { description: 'DFS payroll' }, { description: 'Non DFS payroll' }, { description: 'EB payroll' }, { description: 'EB and DFS payroll' }];
    const channelJson = [{ description: 'Customer Mobile App' }, { description: 'ATM' }, { description: 'Web' }, { description: 'Kannel' }, { description: 'Mobile App' }, { description: 'USSD' }, { description: 'Backend Services' }];

    const validationSchema = Yup.object().shape({
        feeName: Yup.string().required('This Field is required.'),
        description: Yup.string().required('This Field is required.'),
        currency: Yup.string().required('This Field is required.'),
        calculatorType: Yup.string().required('This Field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            product: '',
            agentNetwork: '',
            segment: '',
            channel: '',
            rangeStart: '',
            rangeEnd: '',
            exclusiveFix: '',
            exclusivePercentage: '',
            inclusiveFix: '',
            inclusivePercentage: '',
            fedIncExc: ''
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            data['userId'] = 1;

            const newData = {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            };

            if (editable) {
                data['ubpCompanyId'] = ubpId;
                const res = await dispatch(handlePutRequest(newData, '/offline_Biller/company/updateUbpCompany', true, true));
                if (res?.responsecode === 1) {
                    formik.resetForm();
                }
            } else {
                const res = await dispatch(handlePostRequest(newData, '/offline_Biller/company/saveUbpCompany', true, true));
                if (res?.responsecode === 1) {
                    formik.resetForm();
                }
            }

            setloading(false);
            setloadingIcon('pi pi-save');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>{editable ? 'Update Fee' : 'Add Fee'}</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="product" className={classNames({ 'p-error': isFormFieldValid('product') }, 'Label__Text')}>
                                    Product<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="product"
                                    placeholder="Select Product"
                                    options={productJson}
                                    optionLabel="description"
                                    name="product"
                                    optionValue="description"
                                    value={formik.values.product || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('product') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('product')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="agentNetwork" className={classNames({ 'p-error': isFormFieldValid('agentNetwork') }, 'Label__Text')}>
                                    Agent Network<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="agentNetwork"
                                    placeholder="Select Agent Network Type"
                                    options={agentNetworkJson}
                                    optionLabel="agentNetwork"
                                    name="agentNetwork"
                                    optionValue="description"
                                    value={formik.values.agentNetwork || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('agentNetwork') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('agentNetwork')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="segment" className={classNames({ 'p-error': isFormFieldValid('segment') }, 'Label__Text')}>
                                    Segment
                                </label>
                                <Dropdown
                                    id="segment"
                                    placeholder="Select segment Type"
                                    options={segmentJson}
                                    optionLabel="description"
                                    name="segment"
                                    optionValue="description"
                                    value={formik.values.segment || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('segment') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('segment')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="channel" className={classNames({ 'p-error': isFormFieldValid('channel') }, 'Label__Text')}>
                                    Channel
                                </label>
                                <Dropdown
                                    id="channel"
                                    placeholder="Select Channel Type"
                                    options={channelJson}
                                    optionLabel="description"
                                    name="channel"
                                    optionValue="description"
                                    value={formik.values.channel || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channel') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('channel')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="rangeStart" className={classNames({ 'p-error': isFormFieldValid('rangeStart') }, 'Label__Text')}>
                                    Range Start
                                </label>
                                <InputText
                                    id="rangeStart"
                                    placeholder="Enter Start Range"
                                    name="rangeStart"
                                    value={formik?.values?.rangeStart?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('rangeStart') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('rangeStart')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="rangeEnd" className={classNames({ 'p-error': isFormFieldValid('rangeEnd') }, 'Label__Text')}>
                                    Range End
                                </label>
                                <InputText
                                    id="rangeEnd"
                                    placeholder="Enter Range End"
                                    name="rangeEnd"
                                    value={formik?.values?.rangeEnd?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('rangeEnd') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('rangeEnd')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="exclusiveFix" className={classNames({ 'p-error': isFormFieldValid('exclusiveFix') }, 'Label__Text')}>
                                    Exclusive Fix
                                </label>
                                <InputText
                                    id="exclusiveFix"
                                    placeholder="Enter Exclusive Fix"
                                    name="exclusiveFix"
                                    value={formik?.values?.exclusiveFix?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('exclusiveFix') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('exclusiveFix')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="exclusivePercentage" className={classNames({ 'p-error': isFormFieldValid('exclusivePercentage') }, 'Label__Text')}>
                                    Exclusive Percentage
                                </label>
                                <InputText
                                    id="exclusivePercentage"
                                    placeholder="Enter Exclusive Percentage"
                                    name="exclusivePercentage"
                                    value={formik?.values?.exclusivePercentage?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('exclusivePercentage') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('exclusivePercentage')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="inclusiveFix" className={classNames({ 'p-error': isFormFieldValid('inclusiveFix') }, 'Label__Text')}>
                                    Inclusive Fix
                                </label>
                                <InputText
                                    id="inclusiveFix"
                                    placeholder="Enter Inclusive Fix"
                                    name="inclusiveFix"
                                    value={formik?.values?.inclusiveFix?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('inclusiveFix') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('inclusiveFix')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="inclusivePercentage" className={classNames({ 'p-error': isFormFieldValid('inclusivePercentage') }, 'Label__Text')}>
                                    Inclusive Percentage
                                </label>
                                <InputText
                                    id="inclusivePercentage"
                                    placeholder="Enter Inclusive Percentage"
                                    name="inclusivePercentage"
                                    value={formik?.values?.inclusivePercentage?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('inclusivePercentage') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('inclusivePercentage')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="fedIncExc" className={classNames({ 'p-error': isFormFieldValid('fedIncExc') }, 'Label__Text')}>
                                    FED Incl/Excl<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="fedIncExc"
                                    placeholder="Select FED Incl/Excl"
                                    options={parserHeadData}
                                    optionLabel="description"
                                    name="fedIncExc"
                                    optionValue="id"
                                    value={formik.values.fedIncExc || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('fedIncExc') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('fedIncExc')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label="Cancel" className="Btn__Transparent" onClick={(e) => handleCancel(e)} />
                        <Button label={editable ? 'Update' : 'Add'} disabled={loading} icon={loadingIcon || ''} iconPos="right" className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddEditFees;

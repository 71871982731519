import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import { FilterMatchMode } from 'primereact/api';

import * as Yup from 'yup';
// import DomainConfiguration from '../../RuleEngine/RuleEngineManagement/Pages/Configuration/DomainConfiguration';

const DomainConfigurations = () => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [statusLovData, setStatusLovData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [domainLov, setDomainLov] = useState([]);
    const [channelData, setChannelData] = useState([]);
    const dataTableRef = useRef(null);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        domain: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ''));
    };

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(res?.payLoad);
    };

    const getDomainLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getalldomain/TBL_DOMAIN:*');
        setDomainLov(resp?.payLoad);
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };
    useEffect(() => {
        getStatusListData();
        getUserLov();
        getDomainLov();
    }, []);

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),

        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,

            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            domain: '',
            code: '',
            dateFrom: '',
            dateTo: '',
            status: '2',
            createdBy: '',
            updatedBy: ''
        },
        validate: (data) => {
            let errors = {};
            if (data.dateTo && !data.dateFrom) {
                errors.dateFrom = 'Date From is required when Date To is provided';
            }

            if (data.dateFrom && data.dateTo && data.dateFrom > data.dateTo) {
                errors.dateTo = 'Date To must be greater than or equal to Date From';
            }
            return errors;
        },

        onSubmit: async (data) => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            dataTableRef.current.reset();
            setChannelData('');

            const res = await dispatch(handlePostRequest(newData, '/ruleengine/v1/domain/list', true, true));
            if (res) {
                // let newArr = res.payLoad.map((res) => {
                //     return {
                //         domain: res.name,
                //         id: res.domainId,
                //         friendlyName: res.friendlyName,
                //         tables: res.domainTablesList.map((item) => ({ dictionaryTablesId: item.dictionaryTables.dictionaryTablesId })),
                //         updatedBy: res.updatedBy,
                //         createdDateAndTime: res.createDate,
                //         code: res.code,
                //         createdBy: res.createdBy,
                //         statusId: res.statusId,
                //         isActive: res.isActive
                //     };
                // });

                setChannelData(res.payLoad);
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
        setChannelData('');
    };
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">Domain Configuration</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const header = () => renderHeader();

    const handleSwitchChange = async (id, isActiveData, rowData) => {
        const updatedData = channelData?.map((item) => {
            if (item?.domainId === id) {
                const isActive = item?.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setChannelData(updatedData);
        let isactive = '';
        if (isActiveData === 'Y') {
            isactive = 'N';
        } else if (isActiveData === 'N') {
            isactive = 'Y';
        }
        const previousValue = isActiveData;

        const data = {
            id: rowData?.domainId,
            code: rowData?.code,
            active: isactive,
            name: rowData.name,
            friendlyName: rowData.friendlyName,
            tables: rowData.domainTablesList.map((item) => ({ dictionaryTablesId: item.dictionaryTables.dictionaryTablesId }))
        };

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newData, '/ruleengine/v1/domain/inactive', true, false));
        if (res?.responseCode !== '000000') {
            const revertedData = channelData?.map((item) => {
                if (item.domainId == id) {
                    const isActive = isactive;

                    return { ...item, isActive };
                }
                return item;
            });

            setChannelData(revertedData);
        }
    };

    const EditRule = (rowData) => {
        navigate(`/editDomain`, { state: [rowData.domainId] });
        // navigate(editUrl, { state: [rowData.id] });
    };

    const handleView = (rowData) => {
        navigate(`/viewDomain`, {
            state: [rowData.domainId]
        });
    };
    const actionBodyTemplate = (rowData) => {
        const statusName = statusLovData?.find((data) => data?.lovId === rowData?.statusId?.toString())?.name;
        return (
            <>
                <div className="actions">
                    <InputSwitch checked={rowData.isActive === 'Y' ? true : false} disabled={['Pending', 'Rejected'].includes(statusName) ? true : false} onChange={() => handleSwitchChange(rowData?.domainId, rowData?.isActive, rowData)} />
                    <Button
                        tooltip="Edit"
                        icon="pi pi-pencil"
                        disabled={['Pending', 'Rejected'].includes(statusName) || (statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                        tooltipOptions={{ position: 'top' }}
                        onClick={() => EditRule(rowData)}
                        className="p-button-rounded p-button-primary"
                    />
                    <Button tooltip="View Details" onClick={() => handleView(rowData)} tooltipOptions={{ position: 'top' }} icon="pi pi-eye" className="p-button-rounded" style={{ backgroundColor: '#F2AC57', border: 'none' }} />
                </div>
            </>
        );
    };
    const addRuleEngine = () => {
        navigate('/addDomain');
    };

    //status color function
    const statusBodyTemplate = (rowData) => {
        const statusName = statusLovData?.find((data) => data?.lovId === rowData?.statusId?.toString())?.name;
        const statusColor = statusName === 'Approved' ? '#14A38B' : statusName === 'Pending' ? '#F2AC57' : '#FF7171';

        return (
            <p style={{ color: statusColor }}>
                <b>{statusName}</b>
            </p>
        );
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];
    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };
    const updatedDateAndTimeBodyTemplate = (rowData) => {
        if (rowData?.createDate) {
            const isoDate = rowData?.createDate;
            const date = new Date(isoDate);

            const mm = String(date.getMonth() + 1).padStart(2, '0');
            const dd = String(date.getDate()).padStart(2, '0');
            const yyyy = date.getFullYear();
            let hours = date.getHours();
            const minutes = String(date.getMinutes()).padStart(2, '0');

            let ampm = 'AM';
            if (hours >= 12) {
                ampm = 'PM';
                hours -= 12;
            }
            if (hours === 0) {
                hours = 12;
            }

            const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
            return <p>{formattedDateTime}</p>;
        }
    };
    return (
        <>
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New Configuration" onClick={() => addRuleEngine()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="domain" className={classNames({ 'p-error': isFormFieldValid('domain') }, 'Label__Text')}>
                                    Domain Name
                                </label>
                                <Dropdown
                                    id="domain"
                                    placeholder="Select Domain"
                                    options={domainLov}
                                    filter
                                    optionLabel="name"
                                    name="domain"
                                    optionValue="name"
                                    value={formik.values.domain || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />
                                {/* <InputText
                                    maxLength={100}
                                    id="domainId"
                                    name="domain"
                                    value={formik?.values?.domain?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('domain') }, 'Input__Round')}
                                /> */}
                                {getFormErrorMessage('domain')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="domain" className={classNames({ 'p-error': isFormFieldValid('domain') }, 'Label__Text')}>
                                    Domain Code
                                </label>

                                <InputText
                                    placeholder="Enter Domain Code"
                                    maxLength={100}
                                    id="ruleId"
                                    name="code"
                                    value={formik?.values?.code?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('code') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('code')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pb-3">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLov}
                                    // filter
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Updated By"
                                    options={userLov}
                                    // filter
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>

                                <InputText
                                    max={previousDate}
                                    id="dateFrom"
                                    placeholder=""
                                    name="dateFrom"
                                    type="date"
                                    value={formik?.values?.dateFrom?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>

                                <InputText
                                    max={previousDate}
                                    id="dateTo"
                                    placeholder=""
                                    name="dateTo"
                                    type="date"
                                    value={formik?.values?.dateTo?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('status') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="status"
                                    placeholder="Select Status"
                                    options={statusLovData}
                                    // filter
                                    optionLabel="name"
                                    name="status"
                                    optionValue="lovId"
                                    value={formik.values.status || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('status') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('status')}
                            </div>
                        </div>
                        <div className="Down__Btn mt-4">
                            <Button label="Search" iconPos="right" className="Btn__Dark" />
                            <Button label="Reset" iconPos="right" className="Btn__Transparent" onClick={(e) => handleReset(e)} />
                        </div>
                    </div>
                </form>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            filters={filters}
                            // globalFilterFields={['domain', 'createdBy', 'updatedBy', 'createdate', 'lastupdatedate', 'status']}
                            header={header}
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={channelData}
                        >
                            <Column field="name" header="Domain Name" />
                            <Column field="code" header="Domain Code" />
                            <Column field="createdBy" header="Created By" />
                            <Column field="updatedBy" header="Updated By" />
                            {/* <Column field="createdDateAndTime" header="Created Date & Time" /> */}
                            <Column body={updatedDateAndTimeBodyTemplate} header="Created Date & Time" />
                            <Column body={statusBodyTemplate} header="Status" />
                            <Column header="Action" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};
export default DomainConfigurations;

import * as Yup from 'yup';

const SlabWisePercentageInitialValues = {
    commissionProfileId: null,
    commissionProfileName: '',
    accountClassificationId: '',
    feeTypeCode: 'SP',
    whtCalculationType: '',
    commissionGlAccountId: '',
    whtGlAccountId: '',
    disbursementMethodName: '',
    disbursementTime: null,
    startDate: '',
    endDate: '',
    commissionSlabRequests: [
        {
            startSlab: '',
            endSlab: '',
            commissionPercentage: null
        }
    ],
    products: []
};

const validationSchema = Yup.object().shape({
    commissionProfileName: Yup.string()
        .required('This field is required')
        .matches(/^[a-zA-Z0-9\s]*$/, 'Only alpha numeric characters are allowed')
        .max(100, 'Maximum 100 digits allowed'),
    accountClassificationId: Yup.mixed().required('This field is required'),
    whtCalculationType: Yup.mixed().required('This field is required'),
    commissionGlAccountId: Yup.mixed().required('This field is required'),
    whtGlAccountId: Yup.mixed().required('This field is required'),
    disbursementMethodName: Yup.mixed().required('This field is required'),
    startDate: Yup.date().required('This field is required').nullable(),
    endDate: Yup.date().when('startDate', {
        is: (startDate) => !!startDate,
        then: Yup.date().required('End Date is required').min(Yup.ref('startDate'), 'End Date has to be more than Start Date')
    }),
    commissionSlabRequests: Yup.array().of(
        Yup.object().shape({
            startSlab: Yup.number()
                .required('This field is required')
                .test('Is positive?', 'The number must be greater than 0', (value) => value > 0)
                .nullable(),
            endSlab: Yup.number()
                .transform((value, originalValue) => {
                    if (isNaN(originalValue)) {
                        return null;
                    }
                    return value;
                })
                .required('This field is required')
                .test('Is positive?', 'The number must be greater than 0', (value) => value > 0)
                .when('startSlab', {
                    is: (startSlab) => !!startSlab,
                    then: Yup.number().min(Yup.ref('startSlab'), 'End Slab has to be more than Start Slab').nullable()
                })
                .nullable(),
            commissionPercentage: Yup.number()
                .required('This field is required')
                .test('Is positive?', 'The number must be greater than 0', (value) => value > 0)
                .test('less-than-or-equal-to-100', 'Value must be less than or equal to 100', (value) => {
                    if (!value) {
                        return false;
                    }
                    const numericValue = parseFloat(value);
                    return numericValue <= 100;
                })
                .nullable()
        })
    )
});

export { SlabWisePercentageInitialValues, validationSchema };

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import RoleTable from '../Components/roleTable';
import { handleGetRequest } from '../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewSalesRoles() {
    const navigate = useNavigate();
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const id = query.get('id');
    const [roles, setRoles] = useState([]);
    const [saleRoleData, setSaleRoleData] = useState([]);
    const [saleStructureData, setSaleStructureData] = useState([]);

    const formik = useFormik({
        initialValues: {
            salesRoleId: id,
            businessTypeId: '',
            regionId: '',
            salesStructureId: ''
            // roleName: ''
        }
    });

    const getSaleRoleDataById = async () => {
        // setloading(true);
        const res = await handleGetRequest(`/account/v1/sales/getSalesRoleById/${id}`);

        const keyData = res?.payLoad;
        if (keyData) {
            formik.setFieldValue('businessTypeId', keyData?.businessTypeId?.toString());
            formik.setFieldValue('regionId', keyData?.regionId?.toString());
            formik.setFieldValue('salesStructureId', keyData?.salesStructureId?.toString());
            formik.setFieldValue('salesRoleId', keyData?.salesRoleId?.toString());
        }
        let newRole = [];
        if (keyData?.tblSalesRoleDetails.length > 0) {
            keyData?.tblSalesRoleDetails?.forEach((item) => {
                if (item.isActive === 'Y') {
                    const role = {
                        roleName: item.roleName,
                        createdate: item.createdate,
                        salesRoleDetailId: item.salesRoleDetailId
                    };
                    newRole.push(role);
                }
            });
            setRoles([...roles, ...newRole]);
        }

        // setloading(false);
    };

    useEffect(() => {
        if (id) {
            getSaleRoleDataById();
        }
    }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

    const getSaleRoleData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getsalerolesdata');
        // if (resp?.responseCode === '010000') {
        setSaleRoleData(resp?.payLoad);
        // }
    };

    const getSaleStructureData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getSaleStructureList/LKP_SALES_STRUCTURE:*');
        // if (resp?.responseCode === '010000') {
        setSaleStructureData(resp?.payLoad);
        // }
    };

    useEffect(() => {
        getSaleRoleData();
        getSaleStructureData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    // useEffect(() => {
    //     if (roles?.length > 0) {
    //         const updatedPayload = roles.map((item) => {
    //             const updatedItem = { ...item };
    //             delete updatedItem.createdate;
    //             return updatedItem;
    //         });
    //         setNewPayLoad(updatedPayload);
    //     }
    // }, [roles]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>VIEW DETAILS</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="businessTypeId" className={classNames({ 'p-error': isFormFieldValid('businessTypeId') }, 'Label__Text')}>
                                    Business Type <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="businessTypeId"
                                    placeholder="Select Business Type"
                                    options={saleRoleData?.bussinessList}
                                    optionLabel="name"
                                    name="businessTypeId"
                                    optionValue="lovId"
                                    value={formik.values.businessTypeId || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('businessTypeId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('businessTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="regionId" className={classNames({ 'p-error': isFormFieldValid('regionId') }, 'Label__Text')}>
                                    Region <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="regionId"
                                    placeholder="Select Region"
                                    options={saleRoleData?.regionList}
                                    optionLabel="name"
                                    name="regionId"
                                    optionValue="lovId"
                                    value={formik.values.regionId || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('regionId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('regionId')}
                            </div>
                        </div>

                        {/* {formik.values.businessTypeId == '5' && (
                            <> */}
                        {formik.values.salesStructureId && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="salesStructureId" className={classNames({ 'p-error': isFormFieldValid('salesStructureId') }, 'Label__Text')}>
                                        Sales Structure
                                        {formik.values.businessTypeId === '5' && <span className="Label__Required">*</span>}
                                        {/* <span className="Label__Required">*</span> */}
                                    </label>
                                    <Dropdown
                                        id="salesStructureId"
                                        placeholder="Select Sales Structure"
                                        options={saleStructureData}
                                        optionLabel="name"
                                        name="salesStructureId"
                                        optionValue="lovId"
                                        value={formik.values.salesStructureId || ''}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('salesStructureId') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('salesStructureId')}
                                </div>
                            </div>
                        )}
                        {/* </>
                        )} */}
                    </div>
                    {roles.length > 0 && <RoleTable roles={roles} readOnlyMode />}

                    <div className="Down__Btn">
                        <Button onClick={() => navigate('/createsalesroles')} label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={id} tableName={'TBL_ROLE'} />
        </>
    );
}
export default ViewSalesRoles;

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../../../services/PostTemplate';
import AccountMaintenanceLogs from '../../../AccountsManagement/Components/AccountMaintenanceLogs';

const CheckBookNoView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [birthPlaceData, setBirthPlaceData] = useState();
    const [cnicFront, setCnicFront] = useState('');
    const [proofOfProfession, setProofOfProfession] = useState('');
    const [sourceOfIncome, setSourceOfIncome] = useState('');
    const [latestUpdatedValue, setLatestUpdatedValue] = useState([]);

    const location = useLocation();
    const { rowData, accountClassificationData, accountStatus, cityList } = location.state || {};

    const nadraStatus = ['Yes', 'No'];
    const clsStatus = ['Yes', 'No'];
    const hraStatus = ['Yes', 'No'];
    const amlStatus = ['Approved', 'Pending'];
    const bvsStatus = ['Yes', 'No'];

    const [displayDialog, setDisplayDialog] = useState(false);
    const [displaySegmentView, setDisplaySegmentView] = useState(false);
    const [displayBvsView, setDisplayBvsView] = useState(false);
    const [appVersionView, setAppVersionView] = useState(false);

    const [editingField, setEditingField] = useState('');

    //Validation Schema
    // const validationSchema = Yup.object().shape({
    //     fullName: Yup.string().required('This Field is required.'),
    //     customerId: Yup.string().required('This Field is required.'),
    //     mobileNo: Yup.string().required('This Field is required.'),
    //     iban: Yup.string().required('This Field is required.'),
    //     accountTypeId: Yup.string().required('This Field is required.'),
    //     accountStatusId: Yup.string().required('This Field is required.'),
    //     remarks: Yup.string().required('This Field is required.'),
    //     fullAddress: Yup.string().required('This Field is required.'),
    //     email: Yup.string().email().required('This Field is required.'),
    //     cityId: Yup.string().required('This Field is required.'),
    //     nadraVerified: Yup.string().required('This Field is required.'),
    //     dob: Yup.string().required('This Field is required.'),
    //     cnic: Yup.string().required('This Field is required.'),
    //     cnicExpiryDate: Yup.string().required('This Field is required.'),
    //     segmentName: Yup.string().required('This Field is required.'),
    //     channelName: Yup.string().required('This Field is required.'),
    //     accountOpeningDate: Yup.string().required('This Field is required.'),
    //     appVersionName: Yup.string().required('This Field is required.'),
    //     level1UpdatedOn: Yup.string().required('This Field is required.'),
    //     ultraAcOpeningDate: Yup.string().required('This Field is required.'),
    //     discrepantDocs: Yup.string().required('This Field is required.'),
    //     clsVerified: Yup.string().required('This Field is required.'),
    //     actualBalance: Yup.string().required('This Field is required.'),
    //     hra: Yup.string().required('This Field is required.'),
    //     motherMaidenName: Yup.string().required('This Field is required.'),
    //     birthPlace: Yup.string().required('This Field is required.'),
    //     aml: Yup.string().required('This Field is required.')
    // });

    const formik = useFormik({
        // validationSchema: validationSchema,
        initialValues: {
            fullName: '',
            customerId: '',
            mobileNo: '',
            iban: '',
            accountTypeId: '',
            accountStatusId: '',
            remarks: '',
            fullAddress: '',
            email: '',
            cityId: '',
            nadraVerified: '',
            dob: '',
            cnic: '',
            cnicExpiryDate: '',
            segmentName: '',
            channelName: '',
            accountOpeningDate: '',
            appVersionName: '',
            level1UpdatedOn: '',
            ultraAcOpeningDate: '',
            discrepantDocs: '',
            clsVerified: '',
            actualBalance: '',
            hra: '',
            motherMaidenName: '',
            birthPlace: '',
            aml: ''
        },
        onSubmit: async (data) => {}
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const mapKeyValues = () => {
        if (rowData) {
            formik.setFieldValue('fullName', rowData?.fullName);
            formik.setFieldValue('customerId', rowData?.customerId);
            formik.setFieldValue('mobileNo', rowData?.mobileNo);
            formik.setFieldValue('iban', rowData?.iban);
            formik.setFieldValue('accountTypeId', rowData?.accountTypeId.toString());
            formik.setFieldValue('nadraVerified', rowData?.nadraVerified === 'Y' ? 'Yes' : rowData?.nadraVerified === 'N' ? 'No' : '');
            formik.setFieldValue('accountStatusId', rowData?.accountStatusId);
            formik.setFieldValue('remarks', rowData?.remarks);
            formik.setFieldValue('fullAddress', rowData?.fullAddress);
            formik.setFieldValue('email', rowData?.email);
            formik.setFieldValue('cityId', rowData?.cityId.toString());
            formik.setFieldValue('dob', rowData?.dob);
            formik.setFieldValue('cnic', rowData?.cnic);
            formik.setFieldValue('cnicExpiryDate', rowData?.cnicExpiryDate);
            formik.setFieldValue('segmentName', rowData?.segmentName);
            formik.setFieldValue('channelName', rowData?.channelName);
            formik.setFieldValue('accountOpeningDate', rowData?.accountOpeningDate.split('T')[0]);
            formik.setFieldValue('appVersionName', rowData?.appVersionName);
            formik.setFieldValue('level1UpdatedOn', rowData?.level1UpdatedOn);
            formik.setFieldValue('ultraAcOpeningDate', rowData?.ultraAcOpeningDate);
            formik.setFieldValue('discrepantDocs', rowData?.discrepantDocs);
            formik.setFieldValue('clsVerified', rowData?.clsVerified === 'Y' ? 'Yes' : rowData?.clsVerified === 'N' ? 'No' : '');
            formik.setFieldValue('actualBalance', rowData?.actualBalance);
            formik.setFieldValue('hra', rowData?.hra === 'Y' ? 'Yes' : rowData?.hra === 'N' ? 'No' : '');
            formik.setFieldValue('motherMaidenName', rowData?.motherMaidenName);
            formik.setFieldValue('birthPlace', rowData?.birthPlace);
            formik.setFieldValue('aml', rowData?.aml === 'A' ? amlStatus[0] : rowData?.aml === 'P' ? amlStatus[1] : '');
            formik.setFieldValue('bvs', rowData?.bvs === 'Y' ? 'Yes' : rowData?.bvs === 'N' ? 'No' : '');
            setBirthPlaceData(rowData?.birthPlace);
        } else {
        }
    };

    const getBirthPlaceData = [{ label: birthPlaceData }];

    useEffect(() => {
        mapKeyValues();
    }, []);

    const handleDialog = (fieldName) => {
        setEditingField(fieldName);
        setDisplayDialog(true);
    };
    const handleViewDialog = () => {
        setDisplaySegmentView(true);
    };
    const handleBvsView = () => {
        setDisplayBvsView(true);
    };
    const handleAppVersionView = () => {
        setAppVersionView(true);
    };

    // Hide dialog boxes on cancel
    const onHide = () => {
        setDisplayDialog(false);
        setDisplaySegmentView(false);
        setDisplayBvsView(false);
        setAppVersionView(false);
    };

    // Go back to the previous page
    const goBack = () => {
        navigate(-1);
    };

    // Get updated values from editable fields
    const changedValues = (field, value) => {
        switch (field) {
            case 'fullName':
                formik.setFieldValue('fullName', value);
                break;
            case 'accountTypeId':
                formik.setFieldValue('accountTypeId', value);
                break;
            case 'accountStatusId':
                formik.setFieldValue('accountStatusId', value);
                break;
            case 'fullAddress':
                formik.setFieldValue('fullAddress', value);
                break;
            case 'email':
                formik.setFieldValue('email', value);
                break;
            case 'cnicExpiryDate':
                formik.setFieldValue('cnicExpiryDate', value);
                break;
            case 'discrepantDocs':
                setCnicFront(value?.cnicFrontBase64 && value?.cnicFrontBase64);
                formik.setFieldValue('cnic', value?.cnic);
                setProofOfProfession(value?.proofOfprofessionBase64 && value?.proofOfprofessionBase64);
                setSourceOfIncome(value?.sourceOfIncome && value?.sourceOfIncome);
                break;
        }
    };

    const handleSubmit = async () => {
        const customerKyc = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    updateCustomerKyc: {
                        customerId: formik.values.customerId,
                        accountId: '',
                        addressId: rowData?.addressId,
                        fullName: formik.values.fullName,
                        accountTypeId: formik.values.accountTypeId,
                        accountStatusId: formik.values.accountStatusId,
                        fullAddress: formik.values.fullAddress,
                        cnicExpiryDate: formik.values.cnicExpiryDate,
                        discrepentDocumentId: rowData?.channelId,
                        pinGenerationId: '',
                        statusId: 1,
                        isActive: '',
                        email: formik.values.email,
                        cnicFront: '',
                        proofOfProfession: '',
                        sourceOfIncome: '',
                        whitelistedDevice: ''
                    }
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const response = await dispatch(handlePostRequest(customerKyc, '/account/v1/customeraccounts/updatecustomerkyc', true, false));
        // Update the local storage
        sessionStorage.setItem('customerKyc', JSON.stringify(customerKyc?.data?.payLoad?.updateCustomerKyc));
        setLatestUpdatedValue(customerKyc?.data?.payLoad?.updateCustomerKyc);
    };

    const handledLoginPin = async (e) => {
        e.preventDefault();
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    regenerateLoginPinRequest: {
                        customerId: 141
                    }
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/account/v1/customeraccounts/updatecustomerkyc', true, true));
        if (res?.responseCode === '010000') {
            formik.resetForm();
            // onHideRegeneratePin();
        }
    };

    return (
        <>
            <div className="card Card__Round">
                {/* FORM */}

                <div>
                    <h6 className="secondary-color">
                        <b>VIEW DETAILS</b>
                    </h6>

                    <Divider />

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Name</label>
                                <div className="flex align-items-center">
                                    <InputText
                                        id="fullName"
                                        name="fullName"
                                        value={formik.values.fullName}
                                        onChange={formik.handleChange}
                                        // onChange={formik.handleChange}

                                        className="Input__Round"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Customer ID</label>
                                <InputText id="customerId" name="customerId" value={formik.values.customerId} onChange={formik.handleChange} className="Input__Round" disabled />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Mobile No.</label>
                                <InputText id="mobileNo" name="mobileNo" value={formik.values.mobileNo} onChange={formik.handleChange} className="Input__Round" disabled />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">IBAN</label>
                                <InputText id="iban" name="iban" value={formik.values.iban} onChange={formik.handleChange} className="Input__Round" disabled />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Account Type</label>
                                <div className="flex align-items-center">
                                    <Dropdown
                                        id="accountTypeId"
                                        name="accountTypeId"
                                        placeholder="--Select--"
                                        options={accountClassificationData}
                                        optionLabel="name"
                                        optionValue="lovId"
                                        value={formik.values.accountTypeId}
                                        onChange={formik.handleChange}
                                        className="Dropdown__Round "
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Nadra Verification</label>
                                <Dropdown id="nadraVerified" name="nadraVerified" placeholder="--Select--" options={nadraStatus} value={formik.values.nadraVerified} onChange={formik.handleChange} className="Dropdown__Round" disabled />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">A/C Status</label>
                                <div className="flex align-items-center">
                                    <Dropdown
                                        id="accountStatusId"
                                        name="accountStatusId"
                                        placeholder="--Select--"
                                        options={accountStatus}
                                        optionLabel="accountStatusName"
                                        optionValue="accountStatusId"
                                        value={formik.values.accountStatusId}
                                        onChange={formik.handleChange}
                                        className="Dropdown__Round"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Remarks & Blocked By</label>
                                <InputText id="remarks" name="remarks" value={formik.values.remarks} onChange={formik.handleChange} className="Input__Round" disabled />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Address</label>
                                <div className="flex align-items-center">
                                    <InputText id="fullAddress" name="fullAddress" value={formik.values.fullAddress} onChange={formik.handleChange} className="Input__Round" disabled />
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Email Address</label>
                                <div className="flex align-items-center">
                                    <InputText id="email" name="email" value={formik.values.email} onChange={formik.handleChange} className="Input__Round" disabled />
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">City</label>
                                <Dropdown id="cityId" name="cityId" placeholder="--Select--" options={cityList} optionLabel="name" optionValue="lovId" value={formik.values.cityId} onChange={formik.handleChange} className="Dropdown__Round" disabled />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">DOB</label>
                                <InputText id="dob" name="dob" placeholder="--Select--" value={formik.values.dob} onChange={formik.handleChange} className="Dropdown__Round" disabled />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">CNIC</label>
                                <InputText id="cnic" name="cnic" value={formik.values.cnic} onChange={formik.handleChange} className="Input__Round" disabled />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">CNIC Expiry</label>
                                <div className="flex align-items-center">
                                    <InputText id="cnicExpiryDate" name="cnicExpiryDate" placeholder="" type="date" value={formik?.values?.cnicExpiryDate} onChange={formik.handleChange} className="Input__Round" disabled />
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Segment</label>
                                <div className="flex align-items-center">
                                    <InputText id="segmentName" name="segmentName" value={formik.values.segmentName} onChange={formik.handleChange} className="Input__Round mr-2" disabled />
                                    <i className="field-eye-icon pi pi-eye" onClick={handleViewDialog}></i>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">A/C Opening Channel</label>
                                <InputText id="channelName" name="channelName" value={formik.values.channelName} onChange={formik.handleChange} className="Input__Round mr-2" disabled />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">A/C Opening Date</label>
                                <InputText id="accountOpeningDate" name="accountOpeningDate" type="date" value={formik?.values?.accountOpeningDate} onChange={formik.handleChange} className="Input__Round mr-2" disabled />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">App Version</label>
                                <div className="flex align-items-center">
                                    <InputText id="appVersionName" name="appVersionName" value={formik.values.appVersionName} onChange={formik.handleChange} className="Input__Round mr-2" disabled />
                                    <i className="field-eye-icon pi pi-eye" onClick={handleAppVersionView}></i>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Level 1 Updated On</label>
                                <InputText id="level1UpdatedOn" name="level1UpdatedOn" placeholder="" type="date" value={formik?.values?.level1UpdatedOn} onChange={formik.handleChange} className="Input__Round mr-2" disabled />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Ultra A/C Opening Date</label>
                                <InputText id="ultraAcOpeningDate" name="ultraAcOpeningDate" placeholder="" type="date" value={formik?.values?.ultraAcOpeningDate} onChange={formik.handleChange} className="Input__Round" disabled />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Discrepant Documents</label>
                                <div className="flex align-items-center">
                                    <Dropdown
                                        id="discrepantDocs"
                                        name="discrepantDocs"
                                        placeholder="--Update Documents--"
                                        // options={menuTypeOptions}
                                        optionLabel="label"
                                        value={formik.values.discrepantDocs}
                                        onChange={formik.handleChange}
                                        className="Dropdown__Round"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">CLS Status</label>
                                <Dropdown id="clsVerified" name="clsVerified" placeholder="--Select--" options={clsStatus} value={formik.values.clsVerified} onChange={formik.handleChange} className="Dropdown__Round" disabled />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">AML Status</label>
                                <Dropdown id="aml" name="aml" placeholder="--Select--" options={amlStatus} value={formik.values.aml} onChange={formik.handleChange} className="Dropdown__Round" disabled />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">BVS Details</label>
                                <div className="flex align-items-center">
                                    <Dropdown id="bvs" name="bvs" placeholder="--Select--" options={bvsStatus} value={formik.values.bvs} onChange={formik.handleChange} className="Dropdown__Round mr-2" disabled />
                                    <i className="field-eye-icon pi pi-eye" onClick={handleBvsView}></i>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Available Balance</label>
                                <InputText id="actualBalance" name="actualBalance" value={formik.values.actualBalance} onChange={formik.handleChange} className="Input__Round" disabled />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">HRA</label>
                                <Dropdown id="hra" name="hra" placeholder="--Select--" options={hraStatus} value={formik.values.hra} onChange={formik.handleChange} className="Dropdown__Round" disabled />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Pin Generation</label>
                                <div className="flex align-items-center">
                                    <Dropdown
                                        id="pinGeneration"
                                        name="pinGeneration"
                                        placeholder="--Select--"
                                        // options={menuTypeOptions}
                                        optionLabel="label"
                                        value={formik.values.pinGeneration}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('pinGeneration') }, 'Dropdown__Round')}
                                    />
                                </div>
                                {getFormErrorMessage('pinGeneration')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Mother Name</label>
                                <InputText id="motherMaidenName" name="motherMaidenName" value={formik.values.motherMaidenName} onChange={formik.handleChange} className="Input__Round" disabled />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Birth City</label>
                                <Dropdown
                                    id="birthPlace"
                                    name="birthPlace"
                                    placeholder="--Select--"
                                    options={getBirthPlaceData}
                                    optionLabel="label"
                                    optionValue="label"
                                    value={formik.values.birthPlace}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round"
                                    disabled
                                />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">DOB</label>
                                <InputText id="dob" name="dob" value={formik.values.dob} onChange={formik.handleChange} className="Input__Round" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">CNIC Front</label>
                                <div className="flex align-items-center">
                                    <InputText id="segmentName" name="segmentName" value={formik.values.segmentName} onChange={formik.handleChange} className="Input__Round mr-2" disabled />
                                    <i className="field-eye-icon pi pi-eye" onClick={handleViewDialog}></i>
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">CNIC back</label>
                                <div className="flex align-items-center">
                                    <InputText id="segmentName" name="segmentName" value={formik.values.segmentName} onChange={formik.handleChange} className="Input__Round mr-2" disabled />
                                    <i className="field-eye-icon pi pi-eye" onClick={handleViewDialog}></i>
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Video</label>
                                <div className="flex align-items-center">
                                    <InputText id="segmentName" name="segmentName" value={formik.values.segmentName} onChange={formik.handleChange} className="Input__Round mr-2" disabled />
                                    <i className="field-eye-icon pi pi-eye" onClick={handleViewDialog}></i>
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Dual Nationality</label>
                                <Dropdown
                                    id="birthPlace"
                                    name="birthPlace"
                                    placeholder="--Select--"
                                    options={getBirthPlaceData}
                                    optionLabel="label"
                                    optionValue="label"
                                    value={formik.values.birthPlace}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Occupation</label>
                                <Dropdown
                                    id="birthPlace"
                                    name="birthPlace"
                                    placeholder="--Select--"
                                    options={getBirthPlaceData}
                                    optionLabel="label"
                                    optionValue="label"
                                    value={formik.values.birthPlace}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Purpose</label>
                                <Dropdown
                                    id="birthPlace"
                                    name="birthPlace"
                                    placeholder="--Select--"
                                    options={getBirthPlaceData}
                                    optionLabel="label"
                                    optionValue="label"
                                    value={formik.values.birthPlace}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Monthly Spending</label>
                                <Dropdown
                                    id="birthPlace"
                                    name="birthPlace"
                                    placeholder="--Select--"
                                    options={getBirthPlaceData}
                                    optionLabel="label"
                                    optionValue="label"
                                    value={formik.values.birthPlace}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Check Book</label>
                                <Dropdown
                                    id="birthPlace"
                                    name="birthPlace"
                                    placeholder="--Select--"
                                    options={getBirthPlaceData}
                                    optionLabel="label"
                                    optionValue="label"
                                    value={formik.values.birthPlace}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* LOGS TABLE */}

                <AccountMaintenanceLogs rowData={rowData} />

                <div>
                    <h5 className="secondary-color">
                        <b>ACCOUNT MAINTENANCE</b>
                    </h5>
                    <Divider />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 justify-content-center pt-4">
                    <div className="col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons">
                        <div>Close Account Disown CNIC</div>
                    </div>

                    <div className="col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons">
                        <div>Close Account Via Mobile Number</div>
                    </div>

                    <div className="col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons">
                        <div>Email Document to Customer</div>
                    </div>

                    <div className="col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons">
                        <div>Delink Raast AMAA</div>
                    </div>

                    <div className="col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons">
                        <div>Regenerate Login Pin</div>
                    </div>

                    <div className="col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons">
                        <div>View Customer Documents</div>
                    </div>

                    <div className="col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons">
                        <div>Download Account Forms</div>
                    </div>
                </div>

                <div className="Down__Btn mt-6 pt-6">
                    <Button label="Ok" iconPos="right" className="Btn__Dark" onClick={handleSubmit} />
                </div>
            </div>
        </>
    );
};

export default CheckBookNoView;

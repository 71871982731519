import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

const HistoryTable = ({ tableData }) => {
    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.linkStatus === 'Linked' ? '#14A38B' : '#FF7171';
        return (
            <p
                style={{
                    color: statusColor,
                    textAlign: 'center'
                }}
            >
                <b>{rowData?.linkStatus}</b>
            </p>
        );
    };

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdDate;
        if (!isoDate) return <p>N/A</p>;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const updatedDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.updatedDate;
        if (!isoDate) return <p>N/A</p>;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    return (
        <div>
            <div className="card Card__Round pb-0 mb-0">
                <div className="Form__Header">
                    <h5 className="uppercase">History</h5>
                </div>
            </div>
            <div className="card">
                <DataTable value={tableData} responsiveLayout="scroll">
                    <Column field="accountId" header="Account ID" />
                    <Column field="firstName" header="Full Name" />
                    <Column field="mobileNo" header="Mobile Number" />
                    <Column field="cnic" header="CNIC" />
                    <Column header="Date From" body={createdDateAndTimeBodyTemplate} />
                    <Column header="Date To" body={updatedDateAndTimeBodyTemplate} />
                    <Column field="createdByUser" header="Created By" />
                    <Column field="updatedByUser" header="Updated By" />
                    <Column field="coreAccountNo" header="Link Account Number" />
                    <Column header="Status" body={statusBodyTemplate} />
                </DataTable>
            </div>
        </div>
    );
};

export default HistoryTable;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewCardInstallmentPlanChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [dataById, setDataById] = useState([]);
    const [dataByMcRequestId, setDataByMcRequestId] = useState([]);
    const [feeType, setFeeType] = useState([]);
    const [dataId, setDataId] = useState('');
    const [payLoadSet, setPayLoadSet] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Getting params from URL using hash router

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const formik = useFormik({
        initialValues: {
            installmentPlanName: '',
            feeType: '',
            installmentPlan: '',
            noOfInstallment: '',
            action: '',
            checkerComments: ''
        }
    });

    const getDatabyId = async () => {
        // getRoleWiseModule
        const resp = await handleGetRequest(`/debitcardmanagement/v1/cards/getinstallmentplanbyid/${refTableId}`);
        const keyData = resp?.payLoad;
        setDataById(keyData);
        const newPayLoad = {
            dcInstallmentPlanId: resp?.payLoad?.dcInstallmentPlanId,
            installmentPlan: resp?.payLoad?.installmentPlan,
            installmentPlanName: resp?.payLoad?.installmentPlanName,
            noOfInstallment: resp?.payLoad?.noOfInstallment,
            lkpDcFeeTypeId: resp?.payLoad?.lkpDcFeeType?.dcFeeTypeId
        };
        setPayLoadSet(newPayLoad);
        setDataId(keyData?.dcInstallmentPlanId);
        formik.setFieldValue('installmentPlanName', keyData?.installmentPlanName);
        formik.setFieldValue('feeType', keyData?.lkpDcFeeType?.dcFeeTypeId?.toString());
        formik.setFieldValue('installmentPlan', keyData?.installmentPlan);
        formik.setFieldValue('noOfInstallment', keyData?.noOfInstallment);
    };

    const getMcRequestDataById = async () => {
        const resp = await handleGetRequest(`/debitcardmanagement/v1/cards/getmcrequestbyid/${mcRequestId}`);
        const keyData = resp?.payLoad;
        setPayLoadSet(resp?.payLoad);
        setDataByMcRequestId(keyData);
        setDataId(keyData?.dcInstallmentPlanId);
        formik.setFieldValue('installmentPlanName', keyData?.installmentPlanName);
        formik.setFieldValue('feeType', keyData?.lkpDcFeeTypeId?.toString());
        formik.setFieldValue('installmentPlan', keyData?.installmentPlan);
        formik.setFieldValue('noOfInstallment', keyData?.noOfInstallment);
    };

    const getInstallmentPlanData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getinstallmentplandata');
        const feeTypeList = res?.payLoad?.feeType?.map((item) => {
            // save only those whose isActive is Y
            if (item.isActive === 'Y') {
                return item;
            }
        });
        setFeeType(feeTypeList);
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        getInstallmentPlanData();
    }, []);

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, [requestType]);

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>VIEW DETAIL</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="installmentPlanName" className={classNames({ 'p-error': isFormFieldValid('installmentPlanName') }, 'Label__Text')}>
                                    Installment Name
                                </label>
                                <InputText
                                    maxLength={50}
                                    minLength={3}
                                    id="installmentPlanName"
                                    disabled
                                    name="installmentPlanName"
                                    value={formik?.values?.installmentPlanName}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('installmentPlanName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('installmentPlanName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="feeType" className={classNames({ 'p-error': isFormFieldValid('feeType') }, 'Label__Text')}>
                                    Fee Type
                                </label>
                                <Dropdown
                                    id="feeType"
                                    disabled
                                    options={feeType}
                                    optionLabel="name"
                                    name="feeType"
                                    optionValue="lovId"
                                    value={formik.values.feeType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('feeType') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('feeType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="installmentPlan" className={classNames({ 'p-error': isFormFieldValid('installmentPlan') }, 'Label__Text')}>
                                    Installment Plan
                                </label>
                                <Dropdown
                                    id="installmentPlan"
                                    disabled
                                    options={[
                                        { name: 'Weekly', lovId: 'W' },
                                        { name: 'Monthly', lovId: 'M' },
                                        { name: 'Yearly', lovId: 'Y' }
                                    ]}
                                    optionLabel="name"
                                    name="installmentPlan"
                                    optionValue="lovId"
                                    value={formik.values.installmentPlan || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('installmentPlan') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('installmentPlan')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="noOfInstallment" className={classNames({ 'p-error': isFormFieldValid('noOfInstallment') }, 'Label__Text')}>
                                    No. of Installment
                                </label>
                                <InputText
                                    maxLength={50}
                                    minLength={3}
                                    id="noOfInstallment"
                                    disabled
                                    name="noOfInstallment"
                                    value={formik?.values?.noOfInstallment}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('noOfInstallment') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('noOfInstallment')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default ViewCardInstallmentPlanChecker;

import React from 'react';

const getPercentageValue = (data) => {
    const totalValue = data?.reduce((sum, item) => sum + parseInt(item.parameterValue, 10), 0);
    const updatedPayload = data?.map((item) => ({
        ...item,
        parameterValue: ((parseInt(item.parameterValue, 10) / totalValue) * 100).toFixed(0) // Keeping two decimal places
    }));
    return updatedPayload;
};

export default getPercentageValue;

import React, { useState, useEffect } from 'react';
import { handleGetRequest } from '../../../services/GetTemplate';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import '../../../../src/components/DataTable.css';
import AddEditRole from './AddEditRole';
import { InputText } from 'primereact/inputtext';

function Roles() {
    const [loading, setloading] = useState([]);
    const [displayModal, setDisplayModal] = useState(false);
    const [editable, setEditable] = useState(false);
    const [roleRowData, setroleRowData] = useState('');
    const [globalFilter, setGlobalFilter] = useState('');

    const users = [
        {
            roleDescription: 'Admin',
            role: 'Admin'
        },
        {
            roleDescription: 'Super Admin',
            role: 'Super Admin'
        }
    ];
    let navigate = useNavigate();

    const editHandler = (rowData) => {
        setDisplayModal(true);
        setroleRowData(rowData);
        setEditable(true);
    };
    const actionTemplate = (rowData) => {
        return (
            <div>
                <Button tooltip="Edit" icon="pi pi-pencil" tooltipOptions={{ position: 'top' }} onClick={() => editHandler(rowData)} className="p-button-rounded p-button-warning mr-2" />
                <Button tooltip="View Details" icon="pi pi-eye" tooltipOptions={{ position: 'top' }} onClick={() => navigate(`/rolerights?rid=${rowData?.umgtRoleId}`)} className="p-button-rounded p-button-primary p-mr-2" />
            </div>
        );
    };
    const getRoles = async () => {
        setloading(true);
        await handleGetRequest('/zmiles_user_management/zumngt/getAllRole');
        setloading(false);
    };

    useEffect(() => {
        getRoles();
    }, []);

    const onHide = () => {
        setDisplayModal(false);
        setEditable(false);
    };

    const header = (
        <div className="Global__Header">
            <h5 className="m-0">Role</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    return (
        <div>
            {/* <div className="Top__Btn">
                <Button label="Add New" className="Btn__Dark" onClick={() => setDisplayModal(true)} />
            </div> */}
            <div className="Top__Btn">
                <div>
                    <Button label="New" onClick={() => setDisplayModal(true)} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                    {/* <Button
                    label="Delete"
                    className="Btn__delete__"
                    icon="pi pi-trash"
                    iconPos="left"
                /> */}
                </div>
            </div>
            <Dialog header={editable ? 'Edit Role' : 'Add Role'} visible={displayModal} style={{ width: '50vw' }} onHide={() => onHide()}>
                <AddEditRole onHide={onHide} editable={editable} roleRowData={roleRowData} getRoles={getRoles} />
            </Dialog>
            <div className="card p-datatable-sm">
                <DataTable header={header} globalFilter={globalFilter} disabled={loading} paginator rows={10} value={users} emptyMessage="No data found.">
                    <Column className="Table__Alignment" field="roleDescription" header="Role Description" />
                    <Column className="Table__Alignment" body={actionTemplate} header="Action" />
                </DataTable>
            </div>
        </div>
    );
}

export default Roles;

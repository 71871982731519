import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';

import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { Checkbox } from 'primereact/checkbox';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewCardPricingProfileChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [applicationType, setApplicationType] = useState([]);
    const [segment, setSegment] = useState([]);
    const [franchise, setFranchise] = useState([]);
    const [cardType, setCardType] = useState([]);
    const [feeType, setFeeType] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);

    const [accountType, setAccountType] = useState([]);
    const [waiverData, setWaiverData] = useState([]);
    const [cardPricingData, setCardPricingData] = useState([]);

    const [recovery, setRecovery] = useState([]);
    const [instanceRequest, setInstanceRequest] = useState([]);

    const [installmentPlan, setInstallmentPlan] = useState([]);
    // const [showTypeOfCom, setShowTypeOfCom] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [districtLov, setDistrictLov] = useState([]);
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    // const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const mcPeindingRequestId = query.get('mcPendingRequest');
    const validationSchema = Yup.object({});
    const getUserData = async () => {
        const res = await handleGetRequest(`/debitcardmanagement/v1/cards/getcardpricingprofilebyid/${refTableId}`);
        const keyData = res?.payLoad;

        formik.setFieldValue('agentId', keyData?.tblAgent?.agentId?.toString());
        formik.setFieldValue('amount', keyData?.amount?.toString());
        formik.setFieldValue('chargingProfile', keyData?.chargingProfile.toString());
        formik.setFieldValue('dcPricingProfileName', keyData?.dcPricingProfileName.toString());
        formik.setFieldValue('salaryTransactionType', keyData?.salaryTransactionType?.toString());
        formik.setFieldValue('waiver', keyData?.waiver === 'Y' ? true : false);

        formik.setFieldValue('lkpAccountClassificationId', keyData?.lkpAccountClassification?.accountClassificationId?.toString());
        formik.setFieldValue('lkpAccountLevelId', keyData?.lkpAccountLevel?.accountLevelId?.toString());
        formik.setFieldValue('dcCardProductType', keyData?.dcCardProductType?.toString());
        formik.setFieldValue('lkpDcCardTypeId', keyData?.lkpDcCardType?.dcCardTypeId?.toString());
        formik.setFieldValue('lkpDcFeeTypeId', keyData?.lkpDcFeeType?.dcFeeTypeId?.toString());

        formik.setFieldValue('lkpDcInstanceRequestId', keyData?.lkpDcInstanceRequest?.dcInstanceRequestId.toString());
        formik.setFieldValue('lkpDcRecoveryId', keyData?.lkpDcRecovery?.dcRecoveryId.toString());
        formik.setFieldValue('lkpDcWaiverId', keyData?.lkpDcWaiver?.dcWaiverId.toString());
        formik.setFieldValue('lkpSegmentId', keyData?.lkpSegment?.segmentId.toString());
        formik.setFieldValue('tblDcInstallmentPlanId', keyData?.tblDcInstallmentPlan?.dcInstallmentPlanId.toString());
        formik.setFieldValue('chargingProfileType', keyData?.chargingProfileType?.toString());
    };
    const getMcRequestDataById = async () => {
        const res = await handleGetRequest(`/debitcardmanagement/v1/cards/getmcrequestbyid/${mcRequestId}`);
        const keyData = res?.payLoad;
        formik.setFieldValue('agentId', keyData?.tblAgent?.agentId?.toString());
        formik.setFieldValue('amount', keyData?.amount?.toString());
        formik.setFieldValue('chargingProfile', keyData?.chargingProfile.toString());
        formik.setFieldValue('dcPricingProfileName', keyData?.dcPricingProfileName.toString());
        formik.setFieldValue('salaryTransactionType', keyData?.salaryTransactionType?.toString());
        formik.setFieldValue('waiver', keyData?.waiver === 'Y' ? true : false);

        formik.setFieldValue('lkpAccountClassificationId', keyData?.lkpAccountClassification?.accountClassificationId?.toString());
        formik.setFieldValue('lkpAccountLevelId', keyData?.lkpAccountLevel?.accountLevelId?.toString());
        formik.setFieldValue('dcCardProductType', keyData?.dcCardProductType?.toString());
        formik.setFieldValue('lkpDcCardTypeId', keyData?.lkpDcCardType?.dcCardTypeId?.toString());
        formik.setFieldValue('lkpDcFeeTypeId', keyData?.lkpDcFeeType?.dcFeeTypeId?.toString());

        formik.setFieldValue('lkpDcInstanceRequestId', keyData?.lkpDcInstanceRequest?.dcInstanceRequestId.toString());
        formik.setFieldValue('lkpDcRecoveryId', keyData?.lkpDcRecovery?.dcRecoveryId.toString());
        formik.setFieldValue('lkpDcWaiverId', keyData?.lkpDcWaiver?.dcWaiverId.toString());
        formik.setFieldValue('lkpSegmentId', keyData?.lkpSegment?.segmentId.toString());
        formik.setFieldValue('tblDcInstallmentPlanId', keyData?.tblDcInstallmentPlan?.dcInstallmentPlanId.toString());
        formik.setFieldValue('chargingProfileType', keyData?.chargingProfileType?.toString());
        // formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getUserData();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, [requestType]);
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            dcPricingProfileId: '',
            agentId: '',
            amount: '',
            chargingProfile: '',
            dcPricingProfileName: '',
            salaryTransactionType: '',
            waiver: '',
            chargingProfileType: '',
            lkpAccountClassificationId: '',
            lkpAccountLevelId: '',
            dcCardProductType: '',
            lkpDcCardTypeId: '',
            lkpDcFeeTypeId: '',
            lkpDcInstanceRequestId: '',
            lkpDcRecoveryId: '',
            lkpDcWaiverId: '',
            lkpSegmentId: '',
            tblDcInstallmentPlanId: '',
            action: '',
            checkerComments: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        debitCardPricingProfileRequest: {
                            // isActive: data['isActive'],
                            agentId: formik.values.agentId,
                            amount: formik.values.amount,
                            chargingProfile: formik.values.chargingProfile,
                            chargingProfileType: formik.values.chargingProfileType,
                            dcPricingProfileName: formik.values.dcPricingProfileName,
                            salaryTransactionType: formik.values.salaryTransactionType,
                            waiver: formik.values.waiver,
                            lkpAccountClassificationId: formik.values.lkpAccountClassificationId,
                            lkpAccountLevelId: formik.values.lkpAccountLevelId,
                            dcCardProductType: formik.values.dcCardProductType,
                            lkpDcCardTypeId: formik.values.lkpDcCardTypeId,
                            lkpDcFeeTypeId: formik.values.lkpDcFeeTypeId,
                            lkpDcInstanceRequestId: formik.values.lkpDcInstanceRequestId,
                            lkpDcRecoveryId: formik.values.lkpDcRecoveryId,
                            lkpDcWaiverId: formik.values.lkpDcWaiverId,
                            lkpSegmentId: formik.values.lkpSegmentId,
                            tblDcInstallmentPlanId: formik.values.tblDcInstallmentPlanId
                            // statusId: data['statusId']
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: formik.values.checkerComments,
                            action: data['action'],
                            updatedIndex: null
                        }
                    },

                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(newData, '/debitcardmanagement/v1/cards/cardpricingprofilecheckeraction', true, false, 'cardpricingprofile'));
            if (res?.responseCode === '220000') {
                setCardPricingData(res?.payLoad);
            }
            setloading(false);
            setloadingIcon('pi pi-search');

            // dataTableRef.current.reset();
        }
    });

    const getcardpricingdata = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcardpricingdata');
        setCardType(res?.payLoad?.cardType);
        setApplicationType(res?.payLoad?.applicationType);
        setSegment(res?.payLoad?.segment);
        setFranchise(res?.payLoad?.franchise);
        setAccountType(res?.payLoad?.accountType);
        setFeeType(res?.payLoad?.feeType);
        setRecovery(res?.payLoad?.recovery);
        setInstanceRequest(res?.payLoad?.instanceRequest);
        setInstallmentPlan(res?.payLoad?.installmentPlan);
        setWaiverData(res?.payLoad?.waiver);
    };

    useEffect(() => {
        getcardpricingdata();
    }, []);
    const cardProductType = [
        { label: 'VISA ', value: 'VISA' },
        { label: 'Master Card ', value: 'Master Card' },
        { label: 'PayPak ', value: 'PayPak' },
        { label: 'Virtual Debit Card ', value: 'Virtual Debit Card' }
    ];
    const dcPricingProfileNameData = [
        { label: 'Wallet ', value: 'W' },
        { label: 'Core ', value: 'C' }
    ];
    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        getStatusLov();
    }, []); // eslint-disable-line
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>VIEW DETAIL</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="dcPricingProfileName" className={classNames({ 'p-error': isFormFieldValid('dcPricingProfileName') }, 'Label__Text')}>
                                    Profile Name
                                </label>
                                <InputText
                                    maxLength={50}
                                    disabled
                                    minLength={3}
                                    id="dcPricingProfileName"
                                    name="dcPricingProfileName"
                                    value={formik?.values?.dcPricingProfileName?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dcPricingProfileName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dcPricingProfileName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lkpDcCardTypeId" className={classNames({ 'p-error': isFormFieldValid('lkpDcCardTypeId') }, 'Label__Text')}>
                                    Card Type
                                </label>
                                <Dropdown
                                    id="lkpDcCardTypeId"
                                    options={cardType}
                                    optionLabel="name"
                                    disabled
                                    name="lkpDcCardTypeId"
                                    optionValue="lovId"
                                    value={formik.values.lkpDcCardTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpDcCardTypeId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('lkpDcCardTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="dcCardProductType" className={classNames({ 'p-error': isFormFieldValid('dcCardProductType') }, 'Label__Text')}>
                                    Card Product Type
                                </label>
                                <Dropdown
                                    id="dcCardProductType"
                                    options={cardProductType}
                                    disabled
                                    optionLabel="label"
                                    optionValue="value"
                                    name="dcCardProductType"
                                    value={formik.values.dcCardProductType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dcCardProductType') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('dcCardProductType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lkpAccountClassificationId" className={classNames({ 'p-error': isFormFieldValid('lkpAccountClassificationId') }, 'Label__Text')}>
                                    Application Type
                                </label>
                                <Dropdown
                                    id="lkpAccountClassificationId"
                                    options={applicationType}
                                    optionLabel="name"
                                    name="lkpAccountClassificationId"
                                    optionValue="lovId"
                                    disabled
                                    value={formik.values.lkpAccountClassificationId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpAccountClassificationId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('lkpAccountClassificationId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lkpSegmentId" className={classNames({ 'p-error': isFormFieldValid('lkpSegmentId') }, 'Label__Text')}>
                                    Segment
                                </label>
                                <Dropdown
                                    id="lkpSegmentId"
                                    options={segment}
                                    optionLabel="name"
                                    disabled
                                    name="lkpSegmentId"
                                    optionValue="lovId"
                                    value={formik.values.lkpSegmentId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpSegmentId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('lkpSegmentId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="agentId" className={classNames({ 'p-error': isFormFieldValid('agentId') }, 'Label__Text')}>
                                    Franchise
                                </label>
                                <Dropdown
                                    id="agentId"
                                    options={franchise}
                                    optionLabel="name"
                                    name="agentId"
                                    disabled
                                    optionValue="lovId"
                                    value={formik.values.agentId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('agentId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('agentId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lkpAccountLevelId" className={classNames({ 'p-error': isFormFieldValid('lkpAccountLevelId') }, 'Label__Text')}>
                                    Account Type
                                </label>
                                <Dropdown
                                    id="lkpAccountLevelId"
                                    options={accountType}
                                    disabled
                                    optionLabel="name"
                                    name="lkpAccountLevelId"
                                    optionValue="lovId"
                                    value={formik.values.lkpAccountLevelId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpAccountLevelId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('lkpAccountLevelId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="chargingProfileType" className={classNames({ 'p-error': isFormFieldValid('chargingProfileType') }, 'Label__Text')}>
                                    Charging Profile Type
                                </label>
                                <Dropdown
                                    id="chargingProfileType"
                                    options={dcPricingProfileNameData}
                                    optionLabel="label"
                                    name="chargingProfileType"
                                    optionValue="value"
                                    disabled
                                    value={formik.values.chargingProfileType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('chargingProfileType') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('chargingProfileType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="chargingProfile" className={classNames({ 'p-error': isFormFieldValid('chargingProfile') }, 'Label__Text')}>
                                    Charging Profile Account
                                </label>
                                <InputText
                                    maxLength={50}
                                    minLength={3}
                                    id="chargingProfile"
                                    name="chargingProfile"
                                    disabled
                                    value={formik?.values?.chargingProfile?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('chargingProfile') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('chargingProfile')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lkpDcFeeTypeId" className={classNames({ 'p-error': isFormFieldValid('lkpDcFeeTypeId') }, 'Label__Text')}>
                                    Fee Type
                                </label>
                                <Dropdown
                                    id="lkpDcFeeTypeId"
                                    options={feeType}
                                    optionLabel="name"
                                    name="lkpDcFeeTypeId"
                                    disabled
                                    optionValue="lovId"
                                    value={formik.values.lkpDcFeeTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpDcFeeTypeId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('lkpDcFeeTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="amount" className={classNames({ 'p-error': isFormFieldValid('amount') }, 'Label__Text')}>
                                    Amount
                                </label>
                                <InputText
                                    maxLength={50}
                                    minLength={3}
                                    id="amount"
                                    disabled
                                    name="amount"
                                    value={formik?.values?.amount?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('amount') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('amount')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lkpDcInstanceRequestId" className={classNames({ 'p-error': isFormFieldValid('lkpDcInstanceRequestId') }, 'Label__Text')}>
                                    Instance select request generation or salary credit (Pre or Post)
                                </label>
                                <Dropdown
                                    id="lkpDcInstanceRequestId"
                                    options={instanceRequest}
                                    disabled
                                    optionLabel="name"
                                    name="lkpDcInstanceRequestId"
                                    optionValue="lovId"
                                    value={formik.values.lkpDcInstanceRequestId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpDcInstanceRequestId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('lkpDcInstanceRequestId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 ">
                            <div className="field-checkbox">
                                <div className="mr-2 flex flex-column col-1">
                                    <label htmlFor="waiver" className={classNames({ 'p-error': isFormFieldValid('waiver') }, 'Label__Text')} style={{}}>
                                        Waiver
                                    </label>
                                    <Checkbox inputId="waiver" disabled name="waiver" value={formik?.values?.waiver} checked={formik?.values?.waiver} onChange={formik.handleChange} style={{ marginTop: '10px' }} disabeled />
                                </div>
                                <div className="p-field col-12 md:col-11">
                                    <div className="p-field">
                                        <label htmlFor="lkpDcWaiverId" className={classNames({ 'p-error': isFormFieldValid('lkpDcWaiverId') }, 'Label__Text')}></label>
                                        <Dropdown
                                            id="lkpDcWaiverId"
                                            placeholder="Select one option"
                                            options={waiverData}
                                            optionLabel="name"
                                            name="lkpDcWaiverId"
                                            optionValue="lovId"
                                            value={formik.values.lkpDcWaiverId || ''}
                                            onChange={formik.handleChange}
                                            disabled={!formik.values.waiver}
                                            // disabled={formik.values.waiver !== 'Y'}
                                            className={classNames({ 'p-invalid': isFormFieldValid('lkpDcWaiverId') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('lkpDcWaiverId')}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="salaryTransactionType" className={classNames({ 'p-error': isFormFieldValid('salaryTransactionType') }, 'Label__Text')}>
                                    Salary Transaction Type
                                </label>
                                <InputText
                                    id="salaryTransactionType"
                                    placeholder=""
                                    name="salaryTransactionType"
                                    value={formik?.values?.salaryTransactionType || ''}
                                    // keyfilter="num"
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('salaryTransactionType') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('salaryTransactionType')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-1 mt-4">
                            <div className="Down__Btn__Configuration">
                                <Button type="button" disabled label="Search" className="Btn__Add" onClick={() => search()} />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lkpDcRecoveryId" className={classNames({ 'p-error': isFormFieldValid('lkpDcRecoveryId') }, 'Label__Text')}>
                                    Recovery
                                </label>
                                <Dropdown
                                    id="lkpDcRecoveryId"
                                    options={recovery}
                                    optionLabel="name"
                                    disabled
                                    name="lkpDcRecoveryId"
                                    optionValue="lovId"
                                    value={formik.values.lkpDcRecoveryId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpDcRecoveryId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('lkpDcRecoveryId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="tblDcInstallmentPlanId" className={classNames({ 'p-error': isFormFieldValid('tblDcInstallmentPlanId') }, 'Label__Text')}>
                                    Installment Plan
                                </label>
                                <Dropdown
                                    id="tblDcInstallmentPlanId"
                                    options={installmentPlan}
                                    optionLabel="name"
                                    disabled
                                    name="tblDcInstallmentPlanId"
                                    optionValue="lovId"
                                    value={formik.values.tblDcInstallmentPlanId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('tblDcInstallmentPlanId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('tblDcInstallmentPlanId')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default ViewCardPricingProfileChecker;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../services/GetTemplate';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { PickList } from 'primereact/picklist';
import { useLocation, useNavigate } from 'react-router';
import { InputNumber } from 'primereact/inputnumber';
import AuthorizationHistoryTable from '../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewTransactionwiseLimit() {
    const [source, setSource] = useState([]);
    const [target, setTarget] = useState([]);
    const [accountLevelData, setAccountLevelData] = useState();
    const [isPickListDisabled] = useState(true);

    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const reqID = query.get('reqID');
    const tableName = 'TBL_TRANS_LIMIT';

    const getAccLevelLov = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getaccounttypes/LKP_ACCOUNT_LEVEL:*');
        setAccountLevelData(response?.payLoad);
    };

    const getDatabyId = async () => {
        const resp = await handleGetRequest(`/limits/v1/limitsprofiles/getTransLimitsById/${reqID}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            formik.setFieldValue('lkpAccountLevelId', keyData?.lkpAccountLevel?.accountLevelId?.toString());

            setSource(resp?.payLoad?.tblTransLimitDetails);
            setTarget(resp?.payLoad?.tblProduct);
            // setAccountLevelData([resp.payLoad.lkpAccountLevel]);
        }
    };

    useEffect(() => {
        getDatabyId();
        getAccLevelLov();
    }, []); //eslint-disable-line

    const sourceItemTemplate = (item) => {
        return (
            <div className="product-item">
                <div className="product-list-detail">
                    <h5 className="mb-2">{item.tblProduct.productName}</h5>
                </div>
            </div>
        );
    };

    const targetItemTemplate = (item) => {
        return (
            <div className="product-item">
                <div className="product-list-detail">
                    <h5 className="mb-2">{item.productName}</h5>
                </div>
            </div>
        );
    };

    const formik = useFormik({
        initialValues: {
            limitProfileName: '',
            dailyAmtLimitCr: '',
            dailyAmtLimitDr: '',
            dailyTransLimitCr: '',
            dailyTransLimitDr: '',
            monthlyAmtLimitCr: '',
            monthlyAmtLimitDr: '',
            monthlyTransLimitCr: '',
            monthlyTransLimitDr: '',
            yearlyAmtLimitCr: '',
            yearlyAmtLimitDr: '',
            yearlyTransLimitCr: '',
            yearlyTransLimitDr: '',
            lkpAccountLevelId: ''
        },

        onSubmit: async (data) => {}
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handledOkay = (e) => {
        e.preventDefault();
        navigate('/transactionwiselimit');
    };

    return (
        <>
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <p className="__heading_">VIEW DETAILS</p>
                </div>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="limitProfileName" className={classNames({ 'p-error': isFormFieldValid('limitProfileName') }, 'Label__Text')}>
                                    Limit Profile <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="limitProfileName"
                                    placeholder=""
                                    name="limitProfileName"
                                    disabled={true}
                                    value={formik?.values?.limitProfileName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('limitProfileName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('limitProfileName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="lkpAccountLevelId" className={classNames({ 'p-error': isFormFieldValid('lkpAccountLevelId') }, 'Label__Text')}>
                                    Limit<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="lkpAccountLevelId"
                                    placeholder="Select Account Level"
                                    options={accountLevelData}
                                    optionLabel="name"
                                    name="lkpAccountLevelId"
                                    optionValue="lovId"
                                    value={formik.values.lkpAccountLevelId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpAccountLevelId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('lkpAccountLevelId')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <PickList
                                source={source}
                                target={target}
                                sourceItemTemplate={sourceItemTemplate}
                                targetItemTemplate={targetItemTemplate}
                                sourceHeader="Include"
                                targetHeader="Exclude"
                                sourceStyle={{ height: 'auto' }}
                                targetStyle={{ height: 'auto' }}
                                disabled={isPickListDisabled}
                                className={isPickListDisabled ? 'picklist-disabled' : ''}
                            />
                        </div>
                    </div>
                    <div className="cards_section___">
                        <div className="Debit_card">
                            <p className="card_heading__">Debit</p>
                            <div className="ca_rds">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__">
                                        <p>Daily</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox inputId="active" name="active" value={formik.values.dailyTransLimitDr || ''} checked={formik.values.dailyTransLimitDr === null ? true : false} onChange={formik.handleChange} disabled={true} />
                                            </div>
                                            <p htmlFor="dailyTransLimitDr" className={classNames({ 'p-error': isFormFieldValid('dailyTransLimitDr') }, 'Label__Text')}>
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>

                                        <InputText
                                            disabled={true}
                                            id="dailyTransLimitDr"
                                            placeholder=""
                                            name="dailyTransLimitDr"
                                            value={formik?.values?.dailyTransLimitDr !== null && formik?.values?.dailyTransLimitDr !== undefined ? String(formik.values.dailyTransLimitDr).replace(/[^0-9]/g, '') : ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('dailyTransLimitDr') }, 'Input__Round')}
                                        />
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber
                                            disabled={true}
                                            inputId="dailyAmtLimitDr"
                                            placeholder="25000"
                                            name="dailyAmtLimitDr"
                                            value={formik?.values?.dailyAmtLimitDr || ''}
                                            onChange={formik.handleChange}
                                            // className={classNames({ 'p-invalid': isFormFieldValid('dailyAmtLimitDr') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('dailyAmtLimitDr')}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__">
                                        <p>Monthly</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox
                                                    inputId="active"
                                                    name="active"
                                                    value={formik.values.monthlyTransLimitDr || ''}
                                                    checked={formik.values.monthlyTransLimitDr === null ? true : false}
                                                    onChange={formik.handleChange}
                                                    disabled={true}
                                                />
                                            </div>
                                            <p
                                                htmlFor="monthlyTransLimitDr"
                                                className={classNames(
                                                    {
                                                        'p-error': isFormFieldValid('monthlyTransLimitDr')
                                                    },
                                                    'Label__Text'
                                                )}
                                            >
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>
                                        <InputText
                                            disabled={true}
                                            id="monthlyTransLimitDr"
                                            placeholder=""
                                            name="monthlyTransLimitDr"
                                            value={formik?.values?.monthlyTransLimitDr !== null && formik?.values?.monthlyTransLimitDr !== undefined ? String(formik.values.monthlyTransLimitDr).replace(/[^0-9]/g, '') : ''}
                                            onChange={formik.handleChange}
                                            className={classNames(
                                                {
                                                    'p-invalid': isFormFieldValid('monthlyTransLimitDr')
                                                },
                                                'Input__Round'
                                            )}
                                        />
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="monthlyAmtLimitDr" className={classNames({ 'p-error': isFormFieldValid('monthlyAmtLimitDr') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber
                                            disabled={true}
                                            inputId="monthlyAmtLimitDr"
                                            placeholder="25000"
                                            name="monthlyAmtLimitDr"
                                            value={formik?.values?.monthlyAmtLimitDr || ''}
                                            onChange={formik.handleChange}
                                            // className={classNames({ 'p-invalid': isFormFieldValid('monthlyAmtLimitDr') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('monthlyAmtLimitDr')}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__">
                                        <p>Yearly</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox
                                                    inputId="active"
                                                    name="active"
                                                    value={formik.values.yearlyTransLimitDr || ''}
                                                    checked={formik.values.yearlyTransLimitDr === null ? true : false}
                                                    onChange={formik.handleChange}
                                                    disabled={true}
                                                />
                                            </div>
                                            <p htmlFor="yearlyTransLimitDr" className={classNames({ 'p-error': isFormFieldValid('yearlyTransLimitDr') }, 'Label__Text')}>
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>
                                        <InputText
                                            disabled={true}
                                            id="yearlyTransLimitDr"
                                            placeholder=""
                                            name="yearlyTransLimitDr"
                                            value={formik?.values?.yearlyTransLimitDr !== null && formik?.values?.yearlyTransLimitDr !== undefined ? String(formik.values.yearlyTransLimitDr).replace(/[^0-9]/g, '') : ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitDr') }, 'Input__Round')}
                                        />
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber
                                            disabled={true}
                                            inputId="yearlyAmtLimitDr"
                                            placeholder="25000"
                                            name="yearlyAmtLimitDr"
                                            value={formik?.values?.yearlyAmtLimitDr || ''}
                                            onChange={formik.handleChange}
                                            // className={classNames({ 'p-invalid': isFormFieldValid('yearlyAmtLimitDr') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('yearlyAmtLimitDr')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="credit_card">
                            <p className="card_heading__">Credit</p>
                            <div className="ca_rds">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__">
                                        <p>Daily</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox inputId="active" name="active" value={formik.values.dailyTransLimitCr || ''} checked={formik.values.dailyTransLimitCr === null ? true : false} onChange={formik.handleChange} disabled={true} />
                                            </div>
                                            <p htmlFor="dailyAmtLimitCr" className={classNames({ 'p-error': isFormFieldValid('dailyAmtLimitCr') }, 'Label__Text')}>
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>
                                        <InputText
                                            disabled={true}
                                            id="dailyTransLimitCr"
                                            placeholder=""
                                            name="dailyTransLimitCr"
                                            value={formik?.values?.dailyTransLimitCr !== null && formik?.values?.dailyTransLimitCr !== undefined ? String(formik.values.dailyTransLimitCr).replace(/[^0-9]/g, '') : ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('dailyTransLimitCr') }, 'Input__Round')}
                                        />
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber
                                            disabled={true}
                                            inputId="dailyAmtLimitCr"
                                            placeholder="25000"
                                            name="dailyAmtLimitCr"
                                            value={formik?.values?.dailyAmtLimitCr || ''}
                                            onChange={formik.handleChange}
                                            // className={classNames({ 'p-invalid': isFormFieldValid('dailyAmtLimitCr') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('dailyAmtLimitCr')}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__">
                                        <p>Monthly</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox
                                                    inputId="active"
                                                    name="active"
                                                    value={formik.values.monthlyTransLimitCr || ''}
                                                    checked={formik.values.monthlyTransLimitCr === null ? true : false}
                                                    onChange={formik.handleChange}
                                                    disabled={true}
                                                />
                                            </div>
                                            <p
                                                htmlFor="monthlyTransLimitCr"
                                                className={classNames(
                                                    {
                                                        'p-error': isFormFieldValid('monthlyTransLimitCr')
                                                    },
                                                    'Label__Text'
                                                )}
                                            >
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>
                                        <InputText
                                            disabled={true}
                                            inputId="monthlyTransLimitCr"
                                            placeholder=""
                                            name="monthlyTransLimitCr"
                                            value={formik?.values?.monthlyTransLimitCr !== null && formik?.values?.monthlyTransLimitCr !== undefined ? String(formik.values.monthlyTransLimitCr).replace(/[^0-9]/g, '') : ''}
                                            onChange={formik.handleChange}
                                            className={classNames(
                                                {
                                                    'p-invalid': isFormFieldValid('monthlyTransLimitCr')
                                                },
                                                'Input__Round'
                                            )}
                                        />
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="monthlyAmtLimitCr" className={classNames({ 'p-error': isFormFieldValid('monthlyAmtLimitCr') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber
                                            disabled={true}
                                            inputId="monthlyAmtLimitCr"
                                            placeholder="25000"
                                            name="monthlyAmtLimitCr"
                                            value={formik?.values?.monthlyAmtLimitCr || ''}
                                            onChange={formik.handleChange}
                                            // className={classNames({ 'p-invalid': isFormFieldValid('monthlyAmtLimitCr') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('monthlyAmtLimitCr')}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="left_section__">
                                        <p>Yearly</p>
                                        <div style={{ display: 'flex' }}>
                                            <div className="field-checkbox">
                                                <Checkbox
                                                    inputId="active"
                                                    name="active"
                                                    value={formik.values.yearlyTransLimitCr || ''}
                                                    checked={formik.values.yearlyTransLimitCr === null ? true : false}
                                                    onChange={formik.handleChange}
                                                    disabled={true}
                                                />
                                            </div>
                                            <p htmlFor="yearlyTransLimitCr" className={classNames({ 'p-error': isFormFieldValid('yearlyTransLimitCr') }, 'Label__Text')}>
                                                Count <span>(Check for unlimited count)</span>
                                            </p>
                                        </div>
                                        <InputText
                                            disabled={true}
                                            id="yearlyTransLimitCr"
                                            placeholder=""
                                            name="yearlyTransLimitCr"
                                            value={formik?.values?.yearlyTransLimitCr !== null && formik?.values?.yearlyTransLimitCr !== undefined ? String(formik.values.yearlyTransLimitCr).replace(/[^0-9]/g, '') : ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitCr') }, 'Input__Round')}
                                        />
                                    </div>
                                    <div className="right_section__">
                                        <label htmlFor="yearlyAmtLimitCr" className={classNames({ 'p-error': isFormFieldValid('yearlyAmtLimitCr') }, 'Label__Text')}>
                                            Amount
                                        </label>
                                        <InputNumber
                                            disabled={true}
                                            inputId="yearlyAmtLimitCr"
                                            placeholder="25000"
                                            name="yearlyAmtLimitCr"
                                            value={formik?.values?.yearlyAmtLimitCr || ''}
                                            onChange={formik.handleChange}
                                            // className={classNames({ 'p-invalid': isFormFieldValid('yearlyAmtLimitCr') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('yearlyAmtLimitCr')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn mt-5">
                        <Button label="Okay" className="Btn__Dark" onClick={(e) => handledOkay(e)} iconPos="left" style={{ width: '100px' }} />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={reqID} tableName={tableName} />
        </>
    );
}

export default ViewTransactionwiseLimit;

import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { handlePostRequest } from '../../../../services/PostTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

const ViewSubCategoryCoa = () => {
    const location = useLocation();
    const { rowData } = location.state || {};

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const subCategoryId = rowData?.subCategoryId;
    const tableName = 'LKP_SUB_CATEGORY';

    const validationSchema = Yup.object().shape({
        lkpSubCategoryName: Yup.string().required('This field is required'),
        lkpSubCategoryCode: Yup.string().required('This field is required'),
        lkpSubCategoryDescription: Yup.string().required('This field is required'),
        coaCode: Yup.string().required('This field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            lkpSubCategoryName: '',
            lkpSubCategoryCode: '',
            lkpSubCategoryDescription: '',
            coaCode: ''
        },

        onSubmit: async (data) => {
            const coaPayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        lkpSubCategoryId: rowData?.subCategoryId,
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(coaPayload, '/account/v1/chartofaccount/updatelkpsubcategory', true, true));
            if (res?.responseCode === '010000') {
                navigate(-1);
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        if (rowData) {
            formik.setFieldValue('lkpSubCategoryName', rowData?.subCategoryName);
            formik.setFieldValue('lkpSubCategoryDescription', rowData?.subCategoryDescr);
            formik.setFieldValue('lkpSubCategoryCode', rowData?.subCategoryCode);
            formik.setFieldValue('coaCode', rowData?.coaCode);
        }
    }, [rowData]);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>View Details</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Sub Category Name</label>
                                <InputText
                                    id="lkpSubCategoryName"
                                    value={formik.values.lkpSubCategoryName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpSubCategoryName') }, 'Input__Round')}
                                    maxLength={30}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpSubCategoryName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Sub Category Description</label>
                                <InputText
                                    id="lkpSubCategoryDescription"
                                    value={formik.values.lkpSubCategoryDescription || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpSubCategoryDescription') }, 'Input__Round')}
                                    maxLength={199}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpSubCategoryDescription')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Sub Category Code</label>
                                <InputText
                                    id="lkpSubCategoryCode"
                                    value={formik.values.lkpSubCategoryCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpSubCategoryCode') }, 'Input__Round')}
                                    maxLength={30}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpSubCategoryCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Coa Code</label>
                                <InputText id="coaCode" value={formik.values.coaCode || ''} onChange={formik.handleChange} disabled={true} className={classNames({ 'p-invalid': isFormFieldValid('coaCode') }, 'Input__Round')} maxLength={30} />
                                {getFormErrorMessage('coaCode')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" onClick={() => navigate('/subcategorycoa')} label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={subCategoryId} tableName={tableName} />
        </>
    );
};

export default ViewSubCategoryCoa;

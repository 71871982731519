import React, { useEffect } from 'react';
import { handlePostRequest } from '../../services/PostTemplate';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';

const PaginationTemplate = (dropdownCheck) => ({
    layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
    PrevPageLink: (options) => {
        return (
            <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                <span className="p-3">Previous</span>
            </button>
        );
    },
    NextPageLink: (options) => {
        return (
            <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                <span className="p-3">Next</span>
            </button>
        );
    },
    PageLinks: (options) => {
        if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
            const className = classNames(options.className, { 'p-disabled': true });

            return (
                <span className={className} style={{ userSelect: 'none' }}>
                    ...
                </span>
            );
        }

        return (
            <button type="button" className={options.className} onClick={options.onClick}>
                {options.page + 1}
            </button>
        );
    },
    RowsPerPageDropdown: (options) => {
        const dropdownOptions = [
            { label: 10, value: 10 },
            { label: 20, value: 20 },
            { label: 30, value: 30 }
        ];

        const moreDropdownOptions = [
            { label: 50, value: 50 },
            { label: 100, value: 100 },
            { label: 300, value: 300 }
        ];

        return (
            <>
                <Dropdown value={options.value} options={dropdownCheck ? moreDropdownOptions : dropdownOptions} onChange={options.onChange} />
                <p style={{ marginTop: '15px', marginLeft: '15px' }}>Showing Records</p>
            </>
        );
    }
});

const PaginationCall = ({ render, customFirst1, customRows1, currentPage, dataTableRef, setData, RequestData, url, responseCode = '' }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (render) {
            getNextRecordOnPageChange();
        }
    }, [customFirst1, customRows1, currentPage]); // eslint-disable-line

    async function getNextRecordOnPageChange() {
        dataTableRef.current.reset();

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: RequestData,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, url, true, true));
        if (res?.responseCode === responseCode) {
            setData(res?.payLoad);
        } else {
            setData([]);
        }
    }
};

export { PaginationCall, PaginationTemplate };

import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewAggregatorConfiguration() {
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const configId = query.get('configId');
    const tableName = 'TBL_LMS_AGGREGATOR';

    const getDatabyId = async () => {
        const response = await handleGetRequest(`/lending/v1/lending/getaggregatorconfigbyid/${configId}`);
        formik.setFieldValue('name', response?.payLoad?.name);
        formik.setFieldValue('email', response?.payLoad?.email);
        formik.setFieldValue('businessAddress', response?.payLoad?.businessAddress);
        formik.setFieldValue('contactNo', response?.payLoad?.contactNo);
    };

    useEffect(() => {
        getDatabyId();
    }, [configId]); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            name: '',
            businessAddress: '',
            email: '',
            contactNo: ''
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>View AGGREGATOR CONFIGURATION</h2>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                    Aggregator Name
                                </label>
                                <InputText
                                    disabled={true}
                                    maxLength={100}
                                    id="name"
                                    placeholder="Enter Aggregator name"
                                    name="name"
                                    value={formik.values.name || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('discount') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('name')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="businessAddress" className={classNames({ 'p-error': isFormFieldValid('businessAddress') }, 'Label__Text')}>
                                    Business Address
                                </label>
                                <InputText
                                    disabled={true}
                                    maxLength={100}
                                    id="businessAddress"
                                    placeholder="Enter business address"
                                    name="businessAddress"
                                    value={formik.values.businessAddress || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('discount') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('businessAddress')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') }, 'Label__Text')}>
                                    Email
                                </label>
                                <InputText
                                    disabled={true}
                                    maxLength={100}
                                    id="email"
                                    placeholder="Enter email"
                                    name="email"
                                    value={formik.values.email || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('discount') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('email')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="contactNo" className={classNames({ 'p-error': isFormFieldValid('contactNo') }, 'Label__Text')}>
                                    Contact Number
                                </label>
                                <InputText
                                    disabled={true}
                                    maxLength={11}
                                    id="contactNo"
                                    placeholder="Enter Contact Number"
                                    name="contactNo"
                                    value={formik.values.contactNo || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('discount') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('contactNo')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button onClick={() => navigate('/aggregatorconfigurations')} label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={configId} tableName={tableName} />
        </>
    );
}

export default ViewAggregatorConfiguration;

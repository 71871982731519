// let baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_LIVE;
// export { baseURL };
let baseURL;
let serverURL;

// baseURL =
//     process.env.NODE_ENV === 'development'
//         ? process.env.REACT_APP_BASE_URL_DEV
//         : (baseURL =
//               process.env.NODE_ENV === 'production' && window.location.href.includes('http://10.0.1.4/')
//                   ? process.env.REACT_APP_BASE_URL_QA
//                   : process.env.NODE_ENV === 'production' && window.location.href.includes('http://backoffice.ermispk.com/')
//                   ? process.env.REACT_APP_BASE_URL_DFS
//                   : process.env.NODE_ENV === 'production' && window.location.href.includes('http://10.0.1.6/')
//                   ? process.env.REACT_APP_BASE_URL_UET
//                   : process.env.NODE_ENV === 'production' && window.location.href.includes('http://uatbackoffice.ermispk.com/')
//                   ? process.env.REACT_APP_BASE_URL_UET_DNS_PORT
//                   : process.env.REACT_APP_BASE_URL_LIVE);

// serverURL =
//     process.env.NODE_ENV === 'development'
//         ? process.env.REACT_APP_BASE_SERVER_URL_DEV
//         : (serverURL =
//               process.env.NODE_ENV === 'production' && window.location.href.includes('http://10.0.1.4/')
//                   ? process.env.REACT_APP_BASE_SERVER_URL_QA
//                   : process.env.NODE_ENV === 'production' && window.location.href.includes('http://backoffice.ermispk.com/')
//                   ? process.env.REACT_APP_BASE_SERVER_URL_DFS
//                   : process.env.NODE_ENV === 'production' && window.location.href.includes('http://10.0.1.6/')
//                   ? process.env.REACT_APP_BASE_SERVER_URL_UET
//                   : process.env.NODE_ENV === 'production' && window.location.href.includes('http://uatbackoffice.ermispk.com/')
//                   ? process.env.REACT_APP_BASE_SERVER_URL_UET_DNS_PORT
//                   : process.env.REACT_APP_BASE_SERVER_URL_LIVE);

baseURL = process.env.REACT_APP_BASE_URL;
serverURL = process.env.REACT_APP_BASE_SERVER_URL;
export { baseURL, serverURL };

import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { handleGetRequest } from "../../../services/GetTemplate";
import { handlePostRequest } from "../../../services/PostTemplate";

const TierRangeDialog = ({ onHideTierRange, editable, tierRangeRowData }) => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState("pi pi-save");
    const [tierData, setTierData] = useState([]);

    useEffect(() => {
        if (tierRangeRowData !== undefined || tierRangeRowData !== null) {
            formik.setFieldValue("tier", tierRangeRowData?.tblZmilesTier?.zmilesTierId);
            formik.setFieldValue("points", tierRangeRowData?.points?.toString());
            formik.setFieldValue("isActive", tierRangeRowData?.isActive === 1 ? true : false);
        }
    }, [tierRangeRowData]);

    useEffect(() => {
        if (!editable) {
            formik.resetForm();
        }
    }, [editable]);

    const getTierData = async () => {
        const res = await handleGetRequest("/configuration/v1/zmiles/getAllZmilesTier");
        setTierData(res?.payLoad);
    };

    useEffect(() => {
        getTierData();
    }, []);

    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        points: Yup.string().max(20, "Maximum length 20 allowed").matches("^[0-9]*$", "Please enter numeric data").nullable(),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            tier: "",
            points: "",
            isActive: 'false',
            statusId: 0,
            appUserId: '123',
            zmilesTierRangeId:tierRangeRowData?.zmilesTierRangeId
            
        },

        onSubmit: async (data) => {

            data['points'] = parseInt(data?.points)

            if (editable) {
                
                 delete data['tier']
                delete data['points']
                data['appUserId'] = parseInt(data?.appUserId)
                
            }
            if (!editable) {
                delete data['zmilesTierRangeId']
            }
              const newData = {
                  data: {
                        security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                   }
            };
            setloading(true);
            setloadingIcon("pi pi-spin pi-spinner");
            data["isActive"] = data?.isActive ? 1 : 0;



            formik.setFieldValue("isActive", data?.isActive === "1" ? 'true' : 'false');

            if (editable) {
                const res = await dispatch(handlePostRequest(newData, `/zconfig/v1/zmiles/updateTierRange`, true, false));
                if (res?.responseCode === '330000') {
                    formik.resetForm();
                    onHideTierRange();
                }
            } else {
               
                const res = await dispatch(handlePostRequest(newData, `/zconfig/v1/zmiles/updateTierRange`, true, false));
                if (res?.responseCode === '330000') {
                    formik.resetForm();
                    onHideTierRange();
                }
            }

            setloading(false);
            setloadingIcon("pi pi-save");
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="tier" className={classNames({ "p-error": isFormFieldValid("tier") }, "Label__Text")}>
                                    Tier
                                </label>
                                <Dropdown
                                    disabled={editable ? true : false}
                                    id="tier"
                                    placeholder="Select Tier"
                                    options={tierData}
                                    optionLabel="tier"
                                    name="tier"
                                    optionValue="zmilesTierId"
                                    value={formik.values.tier}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": isFormFieldValid("tier") }, "Dropdown__Round")}
                                />

                                {getFormErrorMessage("tier")}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="points" className={classNames({ "p-error": isFormFieldValid("points") }, "Label__Text")}>
                                    Points<span className="Label__Required">*</span>
                                </label>
                                <InputText disabled={editable ? true : false} id="points" placeholder="Enter Points" name="points" value={formik?.values?.points} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("points") }, "Input__Round")} />

                                {getFormErrorMessage("points")}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="isActive" className={classNames({ "p-error": isFormFieldValid("isActive") }, "Label__Text")}>
                                    Is Active
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isActive" name="isActive" value={formik.values.isActive} checked={formik.values.isActive} onChange={formik.handleChange} />
                                </div>
                                {getFormErrorMessage("isActive")} 
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button disabled={loading} label={editable ? "Edit Tier Range" : "Add Tier Range"} className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
};

export default TierRangeDialog;

import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import AuthorizationHistoryTable from "../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable";
import { handleGetRequest } from "../../../../services/GetTemplate";
import { useLocation, useNavigate } from "react-router-dom";
import { InputTextarea } from "primereact/inputtextarea";
import { handlePostRequest } from "../../../../services/PostTemplate";

function TierRangeChecker() {
     const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState("pi pi-save");
    const [tierData, setTierData] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);

    const navigate = useNavigate()
    
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const tableName = query.get('tableName');
    const reqType = query.get('requestType');
    const mcReqId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');


    const getTierData = async () => {
        const res = await handleGetRequest("/configuration/v1/zmiles/getAllZmilesTier");
        setTierData(res?.payLoad);
    };

    useEffect(() => {
        getTierData();
    }, []);

     const getDataById = async () => {
        const response = await handleGetRequest(`/zconfig/v1/zmiles/getTierRanges/${refTableId}`);

        formik.setFieldValue('tier', response?.payLoad?.tblZmilesTier?.zmilesTierId)
        formik.setFieldValue('points', response?.payLoad?.points?.toString())
        formik.setFieldValue('zmilesTierRangeId', response?.payLoad?.zmilesTierRangeId)
    };
     const getDataByIdUType = async () => {
        const response = await handleGetRequest(`/zconfig/v1/zmiles/getTierRanges/${refTableId}`);

        formik.setFieldValue('tier', response?.payLoad?.tblZmilesTier?.zmilesTierId)
        formik.setFieldValue('points', response?.payLoad?.points?.toString())
        formik.setFieldValue('zmilesTierRangeId', response?.payLoad?.zmilesTierRangeId)
    };


    useEffect(() => {
        if (reqType === 'I') {
            getDataById()    
        } else if (reqType === 'U') {
            getDataByIdUType()
        }
        
    }, [])

    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        points: Yup.string().max(20, "Maximum length 20 allowed").matches("^[0-9]*$", "Please enter numeric data").nullable(),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            tier: "",
            points: "",
            isActive: '',
            action: '',
            checkerComments: '',
            appUserId: '123',
            zmilesTierRangeId:null
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon("pi pi-spin pi-spinner");

             const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        zmilesTierRangeRequest: {
                            tier:data['tier'].toString(),
                            isActive: 'Y',
                            statusId: 0,
                            points: parseInt(data['points']),
                            appUserId: data['appUserId'],
                            zmilesTierRangeId: data['zmilesTierRangeId']
                        },
                        mcActionRequest: {
                            mcRequestId: mcReqId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, `/zconfig/v1/zmiles/tierRangeCheckerAction`, true, false));
            if (res?.responseCode === '330000') {
                formik.resetForm();
                navigate('/requestchecker')
            }

            setloading(false);
            setloadingIcon("pi pi-save");
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    
    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        getStatusLov();
    }, []);
  return (
    <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="tier" className={classNames({ "p-error": isFormFieldValid("tier") }, "Label__Text")}>
                                    Tier
                                </label>
                              <Dropdown
                                  disabled
                                    id="tier"
                                    placeholder="Select Tier"
                                    options={tierData}
                                    optionLabel="tier"
                                    name="tier"
                                    optionValue="zmilesTierId"
                                    value={formik.values.tier}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": isFormFieldValid("tier") }, "Dropdown__Round")}
                                />

                                {getFormErrorMessage("tier")}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="points" className={classNames({ "p-error": isFormFieldValid("points") }, "Label__Text")}>
                                    Points<span className="Label__Required">*</span>
                                </label>
                                <InputText disabled id="points" placeholder="Enter Points" name="points" value={formik?.values?.points?.trim()?.toString()} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("points") }, "Input__Round")} />

                                {getFormErrorMessage("points")}
                            </div>
                        </div>
                    </div>

                   <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    optionDisabled={(option) => option?.name === 'Pending'}
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    maxLength={200}
                                    rows={5}
                                    cols={30}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} label="Update" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
          </div>
           <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
  )
}

export default TierRangeChecker
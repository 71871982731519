import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handlePostRequest } from '../../services/PostTemplate';
import { handleGetRequest } from '../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import { FilterMatchMode } from 'primereact/api';
import ActiveInActiveDialog from '../../components/ActiveInActiveDialog/ActiveInActiveDialog';

function UserGroup() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [userGroupItems, setUserGroupItems] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [partnerTypeLovData, setPartnerTypeLovData] = useState([]);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    // const dataTableRef = useRef(null);

    const [userGroupIdData, setUserGroupIdData] = useState();
    const [isActiveData, setIsActiveData] = useState();
    const [userGroupRowData, setUserGroupRowData] = useState();
    const [showModal, setShowModal] = useState(false);

    const validationSchema = Yup.object().shape({
        // partnerName: Yup.mixed().required('This field is required.'),
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(resp?.payLoad);
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };

    const getPartnerTypeLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getappusertypes/LKP_APP_USER_TYPE:*');
        setPartnerTypeLovData(resp?.payLoad);
    };

    useEffect(() => {
        getStatusLov();
        getUserLov();
        getPartnerTypeLov();
    }, []); // eslint-disable-line

    const dt = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const formik = useFormik({
        validationSchema,
        initialValues: {
            partnerName: '',
            appUserTypeId: '',
            dateFrom: '',
            dateTo: '',
            createdBy: '',
            updatedBy: '',
            statusId: '2'
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            dt.current.reset();

            const res = await dispatch(handlePostRequest(newData, '/mfs_user_management/v1/management/getallusergroups', true, true));
            if (res?.responseCode === '030000') {
                setUserGroupItems(res?.payLoad);
            } else {
                setUserGroupItems([]);
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">User Group List</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const header = renderHeader();

    const EditUserGroupHandled = (rowData) => {
        navigate(`/editusergroup?roleId=${rowData?.roleId}`);
    };

    const ViewUserGroupHandled = (rowData) => {
        navigate(`/viewusergroup?roleId=${rowData?.roleId}`);
    };

    const handleSwitchChange = async () => {
        const updatedData = userGroupItems.map((item) => {
            if (item.roleId === userGroupIdData) {
                const isActive = item.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setUserGroupItems(updatedData);
        let isactive = '';
        if (isActiveData === 'Y') {
            isactive = 'N';
        } else if (isActiveData === 'N') {
            isactive = 'Y';
        }
        const previousValue = isActiveData;
        const data = {
            isActive: isactive,
            roleId: userGroupIdData,
            email: userGroupRowData?.email,
            partnerId: parseInt(userGroupRowData?.lkpPartner?.partnerId),
            partnerType: parseInt(userGroupRowData?.lkpPartner?.lkpAppUserType?.appUserTypeId),
            roleCode: userGroupRowData?.roleCode,
            roleDescr: userGroupRowData?.roleDescr
            // accordionArrays: rowData?.tblRoleRights,
        };
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/mfs_user_management/v1/management/inactiveusergroup', true, false));
        if (res?.responseCode !== '030000') {
            const revertedData = userGroupItems.map((item) => {
                if (item.roleId === userGroupIdData) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setUserGroupItems(revertedData);
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <InputSwitch 
                        checked={rowData.isActive === 'Y'} 
                        disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false} 
                        // onChange={() => handleSwitchChange(rowData.roleId, rowData.isActive, rowData)} 
                        onChange={() => {
                            setShowModal(true);

                            setUserGroupIdData(rowData?.roleId);
                            setIsActiveData(rowData?.isActive);
                            setUserGroupRowData(rowData);
                        }}
                        
                    />
                    <Button
                        tooltip="Edit"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: 'top' }}
                        // disabled={(['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) || rowData?.isActive === "N") ? true : false}
                        disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) || (rowData?.lkpStatus?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                        onClick={() => EditUserGroupHandled(rowData)}
                        className="p-button-rounded p-button-primary"
                    />
                    <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => ViewUserGroupHandled(rowData)} className="p-button-rounded" style={{ backgroundColor: '#F2AC57', border: 'none' }} />
                </div>
            </>
        );
    };

    const addNewUserGroup = () => {
        navigate('/addusergroup');
    };

    // const exportCSV = () => {
    //     dt.current.exportCSV();
    // };

    // const cols = [
    //     { field: 'lkpPartner.partnerName', header: 'Partner' },
    //     { field: 'lkpPartner.lkpAppUserType.appUserTypeName', header: 'Partner Type' },
    //     { field: "roleCode", header: "Name" },
    //     { field: 'createdate', header: 'Created Date' },
    //     { field: 'email', header: 'Email' },
    //     { field: 'createdBy', header: 'Created By' },
    //     { field: 'lkpStatus.statusDescr', header: 'Status' },
    // ];

    // const exportColumns = cols?.map((col) => ({
    //     title: col.header,
    //     dataKey: col.field
    // }));

    // const exportPDF = () => {
    //     import('jspdf').then((jsPDF) => {
    //         import('jspdf-autotable').then(() => {
    //             const doc = new jsPDF.default(0, 0);

    //             const processedData = userGroupItems.map(item => ({
    //                 'lkpPartner.partnerName': item.lkpPartner?.partnerName || '',
    //                 'lkpPartner.lkpAppUserType.appUserTypeName': item.lkpPartner?.lkpAppUserType?.appUserTypeName || '',
    //                 'lkpStatus.statusDescr': item.lkpStatus?.statusDescr || '',
    //                 'roleCode':item.roleCode || '',
    //                 'createdate':item.createdate || '',
    //                 'email':item.email || '',
    //                 'createdBy':item.createdBy || '',
    //                 // Add other fields as needed
    //             }));

    //             doc.autoTable(exportColumns,processedData, userGroupItems, {
    //                 margin: { top: 30 },
    //                 columnStyles: {
    //                     Plant: { cellWidth: 'wrap' },
    //                     Mine: { cellWidth: 'wrap' },
    //                     0: { textColor: [0, 105, 170] }
    //                 },
    //                 headStyles : {
    //                     overflow: 'linebreak',
    //                     cellWidth: 'auto',
    //                     halign: 'center'
    //                 },
    //                 styles: {
    //                     overflow: 'linebreak',
    //                     halign: 'center',
    //                     fontSize: 6
    //                 },
    //                 didDrawPage: function (data) {
    //                     // Header
    //                     doc.setFontSize(15);
    //                     doc.setTextColor(30);
    //                     doc.text('User Group Report List', data.settings.margin.left, 22);
    //                 }
    //             });

    //             doc.save('download.pdf');
    //         });
    //     });
    // };

    const resetHandle = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.lkpStatus?.statusDescr === 'Approved' ? '#14A38B' : rowData?.lkpStatus?.statusDescr === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.lkpStatus?.statusDescr}</b>
            </p>
        );
    };

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const handleShowModal = () => {
        setShowModal(false);
    };

    const props = {
        showModal,
        handleShowModal,
        handleSwitchChange
    };

    return (
        <>
            <ActiveInActiveDialog {...props} />
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="New User Role" onClick={() => addNewUserGroup()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
                {/* <div>
                    <Button icon="pi pi-file-pdf" label="PDF" tooltipOptions={{ position: 'top' }} tooltip="PDF" className="p-button-warning mr-2" data-pr-tooltip="PDF" onClick={exportPDF} />
                    <Button icon="pi pi-file-csv" label="CSV" tooltipOptions={{ position: 'top' }} tooltip="CSV" className="p-button-success p-button-info mr-2" onClick={exportCSV} />
                </div> */}
            </div>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>User Group</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="partnerName" className={classNames({ 'p-error': isFormFieldValid('partnerName') }, 'Label__Text')}>
                                    Name
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="partnerName"
                                    placeholder="Enter Name"
                                    name="partnerName"
                                    value={formik?.values?.partnerName?.replace(/\s\s+/g, '')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('partnerName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('partnerName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="appUserTypeId" className={classNames({ 'p-error': isFormFieldValid('appUserTypeId') }, 'Label__Text')}>
                                    Partner Type
                                </label>
                                <Dropdown
                                    id="appUserTypeId"
                                    placeholder="Select Partner Type"
                                    options={partnerTypeLovData}
                                    optionLabel="name"
                                    name="appUserTypeId"
                                    optionValue="lovId"
                                    value={formik.values.appUserTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('appUserTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('appUserTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText
                                    id="dateFrom"
                                    type="date"
                                    name="dateFrom"
                                    max={previousDate}
                                    value={formik?.values?.dateFrom || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText
                                    id="dateTo"
                                    type="date"
                                    name="dateTo"
                                    max={previousDate}
                                    value={formik?.values?.dateTo || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Updated By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('status') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('status')}
                            </div>
                        </div>
                        <div className="Usergroup__SearchBtn p-field col-12 md:col-4">
                            <Button disabled={loading} loadingIcon={loadingIcon} icon={loadingIcon} iconPos="left" label="Search" className="Btn__Dark__Search" />
                            <Button disabled={loading} label="Reset" onClick={(e) => resetHandle(e)} className="Btn__Transparent__Reset" />
                        </div>
                    </div>
                </form>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dt}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            filters={filters}
                            globalFilterFields={['lkpPartner.partnerName', 'lkpPartner.lkpAppUserType.appUserTypeName', 'roleCode', 'createdate', 'email', 'createdBy', 'lkpStatus.statusName']}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={userGroupItems}
                        >
                            <Column field="lkpPartner.partnerName" header="Partner" />
                            <Column field="lkpPartner.lkpAppUserType.appUserTypeName" header="Partner Type" />
                            <Column field="roleCode" header="Name" />
                            <Column header="Created Date & Time" body={createdDateAndTimeBodyTemplate} />
                            <Column field="email" header="Email" />
                            <Column field="createdBy" header="Created By" />
                            <Column header="Status" body={statusBodyTemplate} />
                            <Column header="Actions" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
export default UserGroup;

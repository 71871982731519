import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import ColorPicker from 'react-pick-color';
import MobileView from '../mobileView';
import { faL } from '@fortawesome/free-solid-svg-icons';

function ViewMobileBundleCategoriesChecker() {
    const [loading, setloading] = useState(false);

    const [companyData, setCompany] = useState([]);

    const [showColorPicker, setShowColorPicker] = useState({ textColor: false, bgColor: false });

    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const tableName = query.get('tableName');

    const getCompany = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getTblCMSCompany/TBL_CMS_COMPANY:*');
        setCompany(resp?.payLoad);
    };

    useEffect(() => {
        getCompany();
    }, []); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            cmsMobileBundleCategoryId: '',
            cmsCompanyId: '',
            nameEnglish: '',
            nameUrdu: '',
            priority: '',
            addTag: false,
            copyEngToUrdu:false,
            cmsTagLabel: '',
            cmsTagTextColor: '',
            cmsTagBgColor: '',
            expiryDate: '',
            isHide: '',
            isActive: ''
        }
    });

    const getMobBundleCatById = async () => {
        try {
            const res = await handleGetRequest(`/cms/v1/cms/getmobilebundlecategorybyid/${refTableId}`);
            const productDetails = res?.payLoad;
            if (productDetails) {
                formik.setFieldValue('cmsCompanyId', productDetails?.cmsCompanyId.toString());
                formik.setFieldValue('nameEnglish', productDetails?.nameEnglish);
                formik.setFieldValue('nameUrdu', productDetails?.nameUrdu);
                formik.setFieldValue('priority', productDetails?.priority);
                formik.setFieldValue('cmsTagLabel', productDetails?.cmsTagLabel);
                formik.setFieldValue('cmsTagTextColor', productDetails?.cmsTagTextColor);
                formik.setFieldValue('cmsTagBgColor', productDetails?.cmsTagBgColor);
                const correct = (productDetails?.expiryDate && formattedDate(productDetails?.expiryDate)) || '';
                formik.setFieldValue('expiryDate', correct || '');
                formik.setFieldValue('isHide', productDetails?.isHide);
                formik.setFieldValue('isActive', productDetails?.isActive);
                formik.setFieldValue('cmsMobileBundleCategoryId', productDetails?.cmsMobileBundleCategoryId);
            }
        } catch (error) {}
    };
    const getMcRequestDataById = async () => {
        setloading(true);
        const res = await handleGetRequest(`/cms/v1/cms/getmcrequestbyid/${mcRequestId}`);
        const unParsedkeyData = res?.payLoad?.updateJson;
        const productDetails = JSON.parse(unParsedkeyData);
        if (productDetails) {
            formik.setFieldValue('cmsCompanyId', productDetails?.cmsCompanyId.toString());
            formik.setFieldValue('nameEnglish', productDetails?.nameEnglish);
            formik.setFieldValue('nameUrdu', productDetails?.nameUrdu);
            formik.setFieldValue('priority', productDetails?.priority);
            formik.setFieldValue('cmsTagLabel', productDetails?.cmsTagLabel);
            formik.setFieldValue('cmsTagTextColor', productDetails?.cmsTagTextColor);
            formik.setFieldValue('cmsTagBgColor', productDetails?.cmsTagBgColor);
            const correct = (productDetails?.expiryDate && formattedDate(productDetails?.expiryDate)) || '';
            formik.setFieldValue('expiryDate', correct || '');
            formik.setFieldValue('isHide', productDetails?.isHide);
            formik.setFieldValue('isActive', productDetails?.isActive);
            formik.setFieldValue('cmsMobileBundleCategoryId', productDetails?.cmsMobileBundleCategoryId);
        }
        setloading(false);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getMobBundleCatById();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, []); // eslint-disable-line

    function formattedDate(originalDate) {
        const createDate = new Date(originalDate);

        const year = createDate.getFullYear();
        const month = (createDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const day = createDate.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }
    const getContrastColor = (hexColor) => {
        const r = parseInt(hexColor.substr(1, 2), 16);
        const g = parseInt(hexColor.substr(3, 2), 16);
        const b = parseInt(hexColor.substr(5, 2), 16);
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="Form__Header Full__Width">
                        <h2>VIEW DETAILS</h2>
                    </div>
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-fluid p-formgrid grid mb-5 col-12 md:col-9 ">
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsCompanyId" className={classNames({ 'p-error': isFormFieldValid('cmsCompanyId') }, 'Label__Text')}>
                                        Company Name<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="cmsCompanyId"
                                        placeholder="Select Company Name"
                                        options={companyData}
                                        optionLabel="name"
                                        name="cmsCompanyId"
                                        optionValue="lovId"
                                        value={formik.values.cmsCompanyId || ''}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsCompanyId') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('cmsCompanyId')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 ">
                                <div className="p-field">
                                    <label htmlFor="nameEnglish" className={classNames({ 'p-error': isFormFieldValid('nameEnglish') }, 'Label__Text')}>
                                        Display Name (English) <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="nameEnglish"
                                        placeholder="Enter Display Name (English)"
                                        name="nameEnglish"
                                        disabled
                                        value={formik?.values?.nameEnglish}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            if (formik.values.copyEngToUrdu) {
                                                formik.setFieldValue('nameUrdu', e.target.value);
                                            }
                                        }}
                                        className={classNames({ 'p-invalid': isFormFieldValid('nameEnglish') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('nameEnglish')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 ">
                                <div className="p-field">
                                    <div className="p-field-checkbox flex justify-content-between">
                                        <label htmlFor="nameUrdu" className={classNames({ 'p-error': isFormFieldValid('nameUrdu') }, 'Label__Text')}>
                                            Display Name (Urdu)<span className="Label__Required">*</span>
                                        </label>
                                        <div className="flex align-items-center ml-auto">
                                            <label htmlFor="nameUrdu" className={classNames({ 'p-error': isFormFieldValid('nameUrdu') }, 'Label__Text')}>
                                                Same AS (English)
                                            </label>
                                        </div>
                                        <Checkbox
                                            inputId="copyEngToUrdu"
                                            name="copyEngToUrdu"
                                            disabled
                                            checked={formik.values.copyEngToUrdu}
                                            onChange={(e) => {
                                                formik.setFieldValue('copyEngToUrdu', e.checked);
                                                if (e.checked) {
                                                    formik.setFieldValue('nameUrdu', formik.values.nameEnglish);
                                                }
                                            }}
                                        />
                                    </div>
                                    <InputText
                                        maxLength={100}
                                        id="nameUrdu"
                                        placeholder="Enter Display Name In Urdu"
                                        name="nameUrdu"
                                        disabled
                                        value={formik?.values?.nameUrdu || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('nameUrdu') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('nameUrdu')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="priority" className={classNames({ 'p-error': isFormFieldValid('priority') }, 'Label__Text')}>
                                        Priority<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="priority"
                                        placeholder="Enter Priority"
                                        name="priority"
                                        value={formik?.values?.priority || ''}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('priority') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('priority')}
                                </div>
                            </div>

                            <div className="card Card__Bg__Change col-12 md:col-12">
                                <h6 className="secondary-color">
                                    <b>TAG</b>
                                </h6>
                                <div className="p-field col-12 md:col-6 pt-3 pb-3 flex align-items-end justify-content-start">
                                    <div className="field-checkbox flex mb-2 p-field-addbanner-width ">
                                        <div className="mr-5">
                                            <Checkbox disabled inputId="addTag" name="addTag" checked={formik?.values?.addTag} onChange={formik.handleChange} style={{ marginTop: '10px' }} />{' '}
                                            <label htmlFor="addTag" className={classNames({ 'p-error': isFormFieldValid('enablePartialPayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                                Add Tag
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-fluid p-formgrid grid mb-5">
                                    <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                        <div className="p-field">
                                            <label htmlFor="cmsTagLabel" className={classNames({ 'p-error': isFormFieldValid('cmsTagLabel') }, 'Label__Text')}>
                                                Tag Label
                                            </label>

                                            <InputText
                                                maxLength={100}
                                                id="cmsTagLabel"
                                                placeholder="Enter Tag Label"
                                                name="cmsTagLabel"
                                                disabled
                                                value={formik?.values?.cmsTagLabel || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagLabel') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('cmsTagLabel')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                        <div className="p-field relative">
                                            <label htmlFor="cmsTagTextColor" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextColor') }, 'Label__Text')}>
                                                Tag Text Color
                                            </label>
                                            <InputText
                                                value={formik?.values?.cmsTagTextColor || '#FFFFFF'}
                                                onChange={(e) => {
                                                    formik.setFieldValue('cmsTagTextColor', e.target.value);
                                                }}
                                                onClick={() => setShowColorPicker({ textColor: true, bgColor: false })}
                                                disabled
                                                readOnly
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextColor') }, 'Input__Round')}
                                                style={{
                                                    backgroundColor: formik?.values?.cmsTagTextColor || '#FFFFFF',
                                                    color: getContrastColor(formik?.values?.cmsTagTextColor || '#FFFFFF')
                                                }}
                                            />
                                            {getFormErrorMessage('cmsTagTextColor')}
                                            {showColorPicker?.textColor && (
                                                <div className="colorPickerForInput">
                                                    <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                                    <ColorPicker color={formik?.values?.cmsTagTextColor || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagTextColor', e?.hex)} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                        <div className="p-field">
                                            <label htmlFor="cmsTagBgColor" className={classNames({ 'p-error': isFormFieldValid('cmsTagBgColor') }, 'Label__Text')}>
                                                Tag Background Color
                                            </label>
                                            <InputText
                                                value={formik?.values?.cmsTagBgColor || '#FFFFFF'}
                                                onChange={(e) => {
                                                    formik.setFieldValue('cmsTagBgColor', e.target.value);
                                                }}
                                                onClick={() => setShowColorPicker({ textColor: false, bgColor: true })}
                                                disabled
                                                readOnly
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagBgColor') }, 'Input__Round')}
                                                style={{
                                                    backgroundColor: formik?.values?.cmsTagBgColor || '#FFFFFF',
                                                    color: getContrastColor(formik?.values?.cmsTagBgColor || '#FFFFFF')
                                                }}
                                            />
                                            {getFormErrorMessage('cmsTagTexcmsTagBgColortBgColor')}
                                            {showColorPicker?.bgColor && (
                                                <div className="colorPickerForInput">
                                                    <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                                    <ColorPicker color={formik?.values?.cmsTagBgColor || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagBgColor', e?.hex)} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="expiryDate" className={classNames({ 'p-error': isFormFieldValid('expiryDate') }, 'Label__Text')}>
                                        Expiry Date
                                    </label>
                                    <InputText id="expiryDate" type="date" name="expiryDate" value={formik?.values?.expiryDate} disabled className={classNames({ 'p-invalid': isFormFieldValid('expiryDate') }, 'Input__Round')} />

                                    {getFormErrorMessage('expiryDate')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="isHide" className={classNames({ 'p-error': isFormFieldValid('isHide') }, 'Label__Text')}>
                                        Status
                                    </label>
                                    <div className="flex ml-2">
                                        <div className="mt-1 mr-5">
                                            <Checkbox inputId="show" name="isHide" value="Y" checked={formik?.values?.isHide === 'Y'} onChange={(e) => formik.setFieldValue('isHide', 'Y')} disabled style={{ marginTop: '10px' }} />
                                            <label htmlFor="show" className={classNames({ 'p-error': isFormFieldValid('show') }, 'Label__Text')}>
                                                Show
                                            </label>
                                        </div>
                                        <div className="mt-1">
                                            <Checkbox inputId="hide" name="isHide" value="N" checked={formik?.values?.isHide === 'N'} onChange={(e) => formik.setFieldValue('isHide', 'N')} disabled style={{ marginTop: '10px' }} />
                                            <label htmlFor="hide" className={classNames({ 'p-error': isFormFieldValid('hide') }, 'Label__Text')}>
                                                Hide
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-3 pt-3 pb-3 col-4 flex align-items-start justify-content-center">
                            <MobileView title={formik?.values?.nameEnglish} />
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" disabled={loading ? true : false} onClick={() => navigate('/requestchecker')} label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default ViewMobileBundleCategoriesChecker;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { Checkbox } from 'primereact/checkbox';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

const ViewCheckerCurrency = () => {

    const [mcReqData, setMcReqData] = useState([]);

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const tableName = query.get('tableName');
    const requestTypeDescr = query.get('requestTypeDescr');

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('Action should not be empty'),
        checkerComments: Yup.string().required('This Field is required').notOneOf([''], 'This Field should not be empty').max(500, 'Maximum 500 characters needed')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            lkpCurrencyName: '',
            lkpCurrencyCode: '',
            lkpCurrencyDescription: '',
            coaCode: '',
            action: '',
            checkerComments: ''
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getCostCenterById = async () => {
        const resp = await handleGetRequest(`/account/v1/chartofaccount/getlkpcurrencybyid/${refTableId}`);
        const keyData = resp?.payLoad;
        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            formik.setFieldValue('lkpCurrencyName', keyData?.currencyName);
            formik.setFieldValue('lkpCurrencyCode', keyData?.currencyCode);
            formik.setFieldValue('lkpCurrencyDescription', keyData?.currencyDescr);
            formik.setFieldValue('coaCode', keyData?.coaCode);
        }
    };

    const getCostCenterMcRequestDataById = async () => {
        const resp = await handleGetRequest(`/account/v1/chartofaccount/getlkpcurrencybymcrequestid/${mcRequestId}`);
        const keyData = resp?.payLoad;
        if (keyData !== null && typeof keyData !== 'undefined') {
            setMcReqData(keyData)
            formik.setFieldValue('lkpCurrencyName', keyData?.lkpCurrencyName);
            formik.setFieldValue('lkpCurrencyCode', keyData?.lkpCurrencyCode);
            formik.setFieldValue('lkpCurrencyDescription', keyData?.lkpCurrencyDescription);
            formik.setFieldValue('coaCode', keyData?.coaCode);
        }
    };

    useEffect(() => {
        if (requestType === 'I') {
            getCostCenterById();
        } else if (requestType === 'U' && requestTypeDescr !== "Enable/Disable Request") {
            getCostCenterMcRequestDataById();
        } else if (requestType === 'U' && requestTypeDescr === "Enable/Disable Request") {
            getCostCenterById();
            getCostCenterMcRequestDataById()
        }
    }, []);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>View Details</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Currency Name</label>
                                <InputText id="lkpCurrencyName" value={formik.values.lkpCurrencyName || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('lkpCurrencyName') }, 'Input__Round')} maxLength={30} disabled={true} />
                                {getFormErrorMessage('lkpCurrencyName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Currency Code</label>
                                <InputText id="lkpCurrencyCode" value={formik.values.lkpCurrencyCode || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('lkpCurrencyCode') }, 'Input__Round')} maxLength={30} disabled={true} />
                                {getFormErrorMessage('lkpCurrencyCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Currency Description</label>
                                <InputText
                                    id="lkpCurrencyDescription"
                                    value={formik.values.lkpCurrencyDescription || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpCurrencyDescription') }, 'Input__Round')}
                                    maxLength={199}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpCurrencyDescription')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Coa Code</label>
                                <InputText id="coaCode" value={formik.values.coaCode || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('coaCode') }, 'Input__Round')} maxLength={30} disabled={true} />
                                {getFormErrorMessage('coaCode')}
                            </div>
                        </div>

                        {
                            (requestType === 'U' && requestTypeDescr === "Enable/Disable Request") &&
                            (
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label className="Label__Text">Is Active</label>
                                        <Checkbox checked={mcReqData?.isActive === "Y"} disabled className='w-full' />
                                    </div>
                                </div>
                            )
                        }
                    </div>

                    <div className="Down__Btn">
                        <Button type="button" label="Okay" className="Btn__Dark" onClick={() => navigate(-1)} />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />

        </>
    );
};

export default ViewCheckerCurrency;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';

import classNames from 'classnames';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import * as Yup from 'yup';

import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';

function AddMobileBundleUpload({}) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    const [compnanyName, setCompnanyName] = useState([]);
    const [parserHead, setParserHead] = useState([]);

    const [selectedFile, setSelectedFile] = useState(null);
    const [ftpFileData, setFtpFileData] = useState([]);

    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        digitalSigningInput: Yup.mixed().required('This field is required.'),

        companyName: Yup.mixed().required('This field is required.'),
        productId: Yup.mixed().required('This field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            bulkOperationName: 'BMB',
            uploadMethod: '',

            digitalSigningInput: '',
            companyName: '',
            productId: ''
        },

        onSubmit: async (data) => {
            let { digitalSigningInput, ...rest } = data;
            const foundItem = compnanyName.find((item) => item.lovId === formik.values.companyName);

            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: rest,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            newData.data.payLoad['companyName'] = foundItem?.name;
            newData.data.payLoad['companyId'] = formik.values.companyName;

            const formData = new FormData();
            formData.append('request', JSON.stringify(newData));
            if (selectedFile) {
                formData.append('file', selectedFile);
            }

            const res = await dispatch(handlePostRequest(formData, '/batchprocessing/v1/batchprocessing/uploadbulkfile', true, false, 'uploadmobilebundle'));
            if (res?.responseCode === '170000') {
                formik.resetForm();
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const getFtpFile = async () => {
        const resp = await handleGetRequest('/batchprocessing/v1/batchprocessing/getftpfiles/BAC');
        setFtpFileData(resp?.payLoad);
    };

    const getCompnanyName = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getTblCMSCompany/TBL_CMS_COMPANY:*');
        setCompnanyName(resp?.payLoad);
    };
    const getParserHead = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_PARSER_HEAD');
        setParserHead(resp?.payLoad);
    };
    useEffect(() => {
        getFtpFile();
        getCompnanyName();
        getParserHead();
    }, []); // eslint-disable-line

    const handleDigitalSigningInput = () => {
        digitalSigningRef.current.click();
    };
    const digitalSigningRef = React.useRef(null);

    const digitalSigningChange = (event) => {
        const selectedFile = event.target.files[0];
        formik.setFieldValue('digitalSigningInput', selectedFile);
        setSelectedFile(selectedFile);

        if (!selectedFile) {
            formik.setFieldError('files', 'Please choose a file.');
            setSelectedFile('');
        }

        if (selectedFile) {
            const reader = new FileReader();

            reader.onload = () => {
                const base64Data = reader.result;
            };

            reader.readAsDataURL(selectedFile);
        }
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>Add Mobile Bundle Upload</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 ">
                            <label htmlFor="companyName" className={classNames({ 'p-error': isFormFieldValid('companyName') }, 'Label__Text')}>
                                Company Name<span className="Label__Required">*</span>
                            </label>
                            <Dropdown
                                id="companyName"
                                placeholder="Select Company Name"
                                options={compnanyName}
                                optionLabel="name"
                                name="companyName"
                                optionValue="lovId"
                                value={formik.values.companyName || ''}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('companyName') }, 'Dropdown__Round')}
                            />
                            {getFormErrorMessage('companyName')}
                        </div>

                        <div className="p-field col-12 md:col-4 ">
                            <div className="p-field">
                                <div className="mb-2">
                                    <label className="Label__Text">CSV File</label>
                                    <span className="Label__Required">*</span>

                                    <div className="file-input-container w-100" onClick={handleDigitalSigningInput}>
                                        <input type="file" style={{ display: 'none' }} ref={digitalSigningRef} onChange={digitalSigningChange} />

                                        <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={formik.values.digitalSigningInput ? formik.values.digitalSigningInput.name : ''} readOnly />

                                        <span className="file-upload-icon">
                                            <i className="pi pi-paperclip" />
                                        </span>
                                    </div>
                                    {getFormErrorMessage('digitalSigningInput')}
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 ">
                            <label htmlFor="productId" className={classNames({ 'p-error': isFormFieldValid('productId') }, 'Label__Text')}>
                                Parser Head<span className="Label__Required">*</span>
                            </label>
                            <Dropdown
                                id="productId"
                                placeholder="Select Parser Head"
                                options={parserHead}
                                optionLabel="name"
                                name="productId"
                                optionValue="lovId"
                                value={formik.values.productId || ''}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('productId') }, 'Dropdown__Round')}
                            />
                            {getFormErrorMessage('productId')}
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon || ''} label="Submit" className="Btn__Dark mt-5" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default AddMobileBundleUpload;

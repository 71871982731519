import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { handleGetRequest } from '../../../services/GetTemplate';
import { MultiSelect } from 'primereact/multiselect';

const PointFactorDialogView = ({ onHidePointFactorView, pointsFactorRowData }) => {
    const [accountTypeData, setAccountTypeData] = useState([]);
    const [segmentData, setSegmentData] = useState([]);
    const [transactionTypeData, setTransactionTypeData] = useState([]);

    useEffect(() => {
        formik.setFieldValue('pointsFactor', pointsFactorRowData?.pointsFactor?.toString());
        formik.setFieldValue('pointsFactorName', pointsFactorRowData?.pointsFactorName?.toString());
        formik.setFieldValue('accType', pointsFactorRowData?.accountLevelId);

        formik.setFieldValue('dateFrom', pointsFactorRowData?.dateFrom?.slice(0, 10));
        formik.setFieldValue('dateTo', pointsFactorRowData?.dateTo?.slice(0, 10));
        formik.setFieldValue('expiry', pointsFactorRowData?.expiry?.toString());

        const transTypeData = pointsFactorRowData?.productId?.map((value) => {
            return value?.toString();
        });
        const segmentData = pointsFactorRowData?.segmentId?.map((value) => {
            return value?.toString();
        });

        formik.setFieldValue('transType', transTypeData);
        formik.setFieldValue('segmentId', segmentData);
    }, [pointsFactorRowData]); // eslint-disable-line

    const getAccountTypeData = async () => {
        const res = await handleGetRequest('/configuration/v1/accountype/getAllAccounttypes');
        setAccountTypeData(res?.payLoad);
    };

    const getSegmentData = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getsegments/LKP_SEGMENT:*`);
        setSegmentData(res?.payLoad);
    };
    const getTransactionTypeData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setTransactionTypeData(res?.payLoad);
    };

    useEffect(() => {
        getAccountTypeData();
        getSegmentData();
        getTransactionTypeData();
    }, []); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            pointsFactor: '',
            pointsFactorName: '',
            accType: '',
            segmentId: [],
            transType: [],
            dateFrom: '',
            dateTo: '',
            expiry: '',
            isActive: false,
            statusId: 0
        },

        onSubmit: async (data) => {
            onHidePointFactorView();
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="pointsFactorName" className={classNames({ 'p-error': isFormFieldValid('pointsFactorName') }, 'Label__Text')}>
                                    Point Factor Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    id="pointsFactorName"
                                    placeholder="Enter Point Factor"
                                    name="pointsFactorName"
                                    value={formik?.values?.pointsFactorName}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pointsFactorName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('pointsFactorName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="pointsFactor" className={classNames({ 'p-error': isFormFieldValid('pointsFactor') }, 'Label__Text')}>
                                    Point Factor<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    id="pointsFactor"
                                    placeholder="Enter Point Factor"
                                    name="pointsFactor"
                                    value={formik?.values?.pointsFactor}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pointsFactor') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('pointsFactor')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accType" className={classNames({ 'p-error': isFormFieldValid('accType') }, 'Label__Text')}>
                                    Customer Account Type
                                </label>
                                <Dropdown
                                    disabled
                                    id="accType"
                                    placeholder="Select Account Type"
                                    options={accountTypeData}
                                    optionLabel="accountLevelName"
                                    name="accType"
                                    optionValue="accountLevelId"
                                    value={formik?.values?.accType}
                                    onChange={formik?.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accType') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('accType')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="segmentId" className={classNames({ 'p-error': isFormFieldValid('segmentId') }, 'Label__Text')}>
                                    Segment
                                </label>
                                <MultiSelect
                                    disabled
                                    id="segmentId"
                                    placeholder="Select Segment"
                                    options={segmentData}
                                    optionLabel="name"
                                    name="segmentId"
                                    optionValue="lovId"
                                    value={formik?.values?.segmentId}
                                    onChange={formik?.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('segmentId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('segmentId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transType" className={classNames({ 'p-error': isFormFieldValid('transType') }, 'Label__Text')}>
                                    Transaction Type<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    disabled
                                    id="transType"
                                    placeholder="Select Transaction Type"
                                    options={transactionTypeData}
                                    optionLabel="name"
                                    name="transType"
                                    optionValue="lovId"
                                    value={formik.values.transType}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('transType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From<span className="Label__Required">*</span>
                                </label>
                                <InputText disabled id="dateFrom" type="date" name="dateFrom" value={formik?.values?.dateFrom} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')} />

                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText disabled id="dateTo" type="date" name="dateTo" value={formik?.values?.dateTo} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')} />

                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expiry" className={classNames({ 'p-error': isFormFieldValid('expiry') }, 'Label__Text')}>
                                    Expiry<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    id="expiry"
                                    placeholder="Enter Expiry"
                                    name="expiry"
                                    value={formik?.values?.expiry}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expiry') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('expiry')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="submit" label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
};

export default PointFactorDialogView;

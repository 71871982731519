import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { FilterMatchMode } from 'primereact/api';
import { useDispatch } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import ViewGeneralLedger from './ViewGeneralLedger';
import AddEditGeneralLedger from './AddEditGeneralLedger';
import { handlePostRequest } from '../../services/PostTemplate';
import { handleGetRequest } from '../../services/GetTemplate';

function GeneralLedger() {
    const [loading, setLoading] = useState(false);
    const [editable, setEditable] = useState(false);
    const [displayModalGeneralLedger, setDisplayModalGeneralLedger] = useState(false);
    const [displayModalViewGeneralLedger, setDisplayModalViewGeneralLedger] = useState(false);
    const [GeneralLedgerRowData, setGeneralLedgerRowData] = useState();
    const [GeneralLedgerViewRowData, setGeneralLedgerViewRowData] = useState();

    const [loadingIcon, setLoadingIcon] = useState('pi pi-search');
    const [tableData, setTableData] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [accountTypeData, setAccountTypeData] = useState([]);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        accountLevelName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const dataTableRef = useRef(null);
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            // .required('This field is required')
            .min(3, 'field must be at least 3 characters long')
            .max(50, 'field cannot exceed 50 characters')
            .matches(/^[a-zA-Z0-9\s]+$/, 'field can contain alphabets and numeric characters'),

        dateFrom: Yup.date().nullable(),

        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(res?.payLoad);
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };

    const getAccountTypeData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getallaccounttype/LKP_ACCOUNT_TYPE:*');
        setAccountTypeData(res?.payLoad);
    };

    useEffect(() => {
        getStatusListData();
        getUserLov();
        getAccountTypeData();
    }, []); // eslint-disable-line

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: '',
            glAccountTypeId: '',
            status: '2',
            dateFrom: '',
            dateTo: '',
            createdBy: '',
            updatedBy: ''
        },

        onSubmit: async (data) => {
            setLoading(true);
            setLoadingIcon('pi pi-spin pi-spinner');
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/account/v1/getallglaccounts', true, true));
            if (res?.responseCode === '010000') {
                const apiData = res?.payLoad;
                formik.resetForm();
                setTableData(res?.payLoad);
                if (apiData.length > 0) {
                    const updatedData = apiData?.map((item) => {
                        const accountTypeData = accountTypeData?.find((data) => data?.lovId === item?.lkpAccountType?.accountTypeId?.toString())?.name;
                        return { ...item, accountTypeData };
                    });
                    setTableData(updatedData);
                }
            }

            setLoading(false);
            setLoadingIcon('pi pi-search');
        }
    });

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const handleSwitchChange = async (id, isActiveData, rowData) => {
        const updatedData = tableData.map((item) => {
            if (item?.accountId === id) {
                const isActive = isActiveData === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setTableData(updatedData);
        let isactive = '';
        if (isActiveData === 'Y') {
            isactive = 'N';
        } else if (isActiveData === 'N') {
            isactive = 'Y';
        }
        const previousValue = isActiveData;
        const data = {
            isActive: isactive,
            accountId: rowData?.accountId,
            currencyId: rowData?.lkpCurrency?.currencyId,
            channelId: rowData?.lkpChannel.channelId,
            glTitle: rowData?.accountTitle,
            glTypeId: rowData?.lkpGlType.glTypeId,
            accountTypeId: rowData?.lkpAccountType?.accountTypeId,
            daoCodeId: rowData?.lkpDaocode.daocodeId,
            oracleFinCode: rowData?.finAccountCode,
            chartOfAccount: rowData?.tblGlCodeCombination?.glCodeCombinationId,
            finCode: rowData?.cbsAccountCode,
            ntnNumber: rowData?.ntnNumber,
            whtApplicability: rowData?.whtApplicability,
            statusId: rowData?.lkpStatus?.statusId
        };

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/account/v1/inactiveglaccount', true, false));
        // if (res?.responseCode !== '010000') {
        //     const revertedData = tableData.map((item) => {
        //         if (item.productId === id) {
        //             const isActive = previousValue;
        //             return { ...item, isActive };
        //         }
        //         return item;
        //     });
        //     setAccountTypeData(revertedData);
        // }
    };

    // const handleSwitchChange = async (id, isActiveData, rowData) => {
    //     // rowData.isActive == 'Y' ? 'N' : 'Y'
    //     const updatedData = tableData.map((item) => {
    //         if (item.accountId === id) {
    //             const isActive = item.isActive === 'Y' ? 'N' : 'Y';
    //             return { ...item, isActive };
    //         }
    //         return item;
    //     });
    //     setTableData(updatedData);
    //     let isactive = '';
    //     if (isActiveData === 'Y') {
    //         isactive = 'N';
    //     } else if (isActiveData === 'N') {
    //         isactive = 'Y';
    //     }
    //     const previousValue = isActiveData;
    //     const data = {
    //         isActive: isactive,
    //         accountId: id,

    //     };
    //         const newData = {
    //             data: {
    //                 security: {
    //                     userName: '',
    //                     password: '',
    //                     securityToken: ''
    //                 },
    //                 account: {
    //                     msidn: '',
    //                     iban: '',
    //                     bban: '',
    //                     pan: '',
    //                     currency: ''
    //                 },
    //                 channel: '',
    //                 terminal: '',
    //                 reterivalReferenceNumber: '',
    //                 payLoad: data,
    //                 additionalInformation: [
    //                     {
    //                         infoKey: '',
    //                         infoValue: ''
    //                     }
    //                 ],
    //                 checkSum: ''
    //             }
    //         };
    //         const res = await dispatch(handlePostRequest(newData, '/account/v1/inactiveglaccount', true, false));
    //     if (res?.responseCode !== '030000') {
    //         const revertedData = tableData.map((item) => {
    //             if (item.accountId === id) {
    //                 const isActive = previousValue;
    //                 return { ...item, isActive };
    //             }
    //             return item;
    //         });
    //         setTableData(revertedData);
    //     }
    // };
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                {/* <h5 className="m-0">Type of Account List</h5> */}
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    {/* <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." /> */}
                </span>
            </div>
        );
    };

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const EditGeneralLedgerHandled = (rowData) => {
        setEditable(true);
        setDisplayModalGeneralLedger(true);
        setGeneralLedgerRowData(rowData);
    };

    const header = renderHeader();

    const resetHandle = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const onHideGeneralLedger = () => {
        setDisplayModalGeneralLedger(false);
    };
    const onHideViewGeneralLedger = () => {
        setDisplayModalViewGeneralLedger(false);
    };

    const addNewGeneralLedger = () => {
        setDisplayModalGeneralLedger(true);
        setEditable(false);
    };

    const ViewTaxGeneralLedger = (rowData) => {
        setDisplayModalViewGeneralLedger(true);
        setGeneralLedgerViewRowData(rowData);
    };

    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.lkpStatus?.statusDescr === 'Approved' ? '#14A38B' : rowData?.lkpStatus?.statusDescr === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.lkpStatus?.statusDescr}</b>
            </p>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <InputSwitch
                        checked={rowData.isActive === 'Y'}
                        // disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                        // onChange={() => handleSwitchChange(rowData.accountId)}

                        disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                        onChange={() => handleSwitchChange(rowData?.accountId, rowData?.isActive, rowData)}
                    />
                    <Button
                        tooltip="Edit"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: 'top' }}
                        disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                        onClick={() => EditGeneralLedgerHandled(rowData)}
                        className="p-button-rounded p-button-primary"
                    />
                    <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => ViewTaxGeneralLedger(rowData)} className="p-button-rounded" style={{ backgroundColor: '#F2AC57', border: 'none' }} />
                </div>
            </>
        );
    };

    return (
        <>
            <Dialog resizable={false} header={editable ? 'EDIT DETAILS' : 'ADD NEW GENERAL LEDGER'} visible={displayModalGeneralLedger} blockScroll style={{ width: '55vw' }} onHide={() => setDisplayModalGeneralLedger(false)} draggable={false}>
                <AddEditGeneralLedger onHideGeneralLedger={onHideGeneralLedger} GeneralLedgerRowData={GeneralLedgerRowData} editable={editable} />
            </Dialog>
            <Dialog resizable={false} header="VIEW DETAILS" visible={displayModalViewGeneralLedger} blockScroll style={{ width: '40vw' }} onHide={() => setDisplayModalViewGeneralLedger(false)} draggable={false}>
                <ViewGeneralLedger onHideViewGeneralLedger={onHideViewGeneralLedger} GeneralLedgerViewRowData={GeneralLedgerViewRowData} />
            </Dialog>

            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={() => addNewGeneralLedger()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>

            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                    Name
                                </label>
                                <InputText maxLength={22} id="name" name="name" value={formik?.values?.name || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'Input__Round')} aria-required />
                                {getFormErrorMessage('name')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="glAccountTypeId" className={classNames({ 'p-error': isFormFieldValid('glAccountTypeId') }, 'Label__Text')}>
                                    Ledger Type
                                </label>
                                <Dropdown
                                    id="glAccountTypeId"
                                    placeholder="Select"
                                    options={accountTypeData}
                                    optionLabel="name"
                                    name="glAccountTypeId"
                                    optionValue="lovId"
                                    value={formik.values.glAccountTypeId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('glAccountTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('glAccountTypeId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText
                                    max={previousDate}
                                    id="dateFrom"
                                    placeholder=""
                                    name="dateFrom"
                                    type="date"
                                    value={formik?.values?.dateFrom?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText
                                    max={previousDate}
                                    id="dateTo"
                                    placeholder=""
                                    name="dateTo"
                                    type="date"
                                    value={formik?.values?.dateTo?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Updated By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('status') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="status"
                                    placeholder="Select Status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="status"
                                    optionValue="lovId"
                                    value={formik.values.status || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('status') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('status')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} label="Search" className="Btn__Dark" />
                        <Button disabled={loading} label="Reset" onClick={(e) => resetHandle(e)} className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            filters={filters}
                            globalFilterFields={['accountLevelName', 'createuser', 'createdate', 'lkpStatus.statusName']}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={tableData}
                        >
                            <Column field="accountTitle" header="Ledger Title" />

                            <Column field={(item) => item?.lkpAccountType?.accountTypeName} header="Ledger Type" />
                            <Column field="createdBy" header="Created By" />
                            {/* <Column field={(item) => item?.createdate} header="Created Date and Time" /> */}
                            <Column header="Created Date and Time" body={createdDateAndTimeBodyTemplate} />

                            <Column field="status" header="Status" body={statusBodyTemplate} />
                            <Column header="Action" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
export default GeneralLedger;

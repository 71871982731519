import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { handleGetRequest } from '../../services/GetTemplate';
import AuthorizationHistoryTable from '../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewRegimeLevelDialog({ onHideViewRegimeLevelDialog, regimeLevelRowData }) {
    const tableName = 'LKP_REGIME_LEVEL';
    const [regimeLevelValues, setRegimeLevelValues] = useState();
    const getCategoryLovValues = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getRegimeCategory/LKP_REGIME_CATEGORY:*');
        setRegimeLevelValues(response?.payLoad);
    };
    useEffect(() => {
        getCategoryLovValues();
    }, []);
    useEffect(() => {
        const rowData = JSON?.parse(JSON?.stringify(regimeLevelRowData));
        const keys = Object?.keys(rowData);
        keys.forEach((item) => {
            formik.setFieldValue(`${item}`, rowData[item]);
        });
        formik.setFieldValue('regimeCategory', rowData?.lkpRegimeCategory?.regimeCategoryId.toString());
    }, [regimeLevelRowData]); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            taxTypeCode: '',
            taxTypeName: '',
            applicableOn: '',
            taxTypeDescr: ''
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleCancel = (e) => {
        e.preventDefault();
        onHideViewRegimeLevelDialog();
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header"></div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="regimeCategory" className={classNames({ 'p-error': isFormFieldValid('regimeCategory') }, 'Label__Text')}>
                                    Regime Category<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    disabled
                                    id="regimeCategory"
                                    placeholder="Select Regime Category"
                                    options={regimeLevelValues}
                                    optionLabel="name"
                                    name="regimeCategory"
                                    optionValue="lovId"
                                    value={formik.values.regimeCategory || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regimeCategory') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('regimeCategory')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="regimeLevelCode" className={classNames({ 'p-error': isFormFieldValid('regimeLevelCode') }, 'Label__Text')}>
                                    Regime Code<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    id="regimeLevelCode"
                                    placeholder="Enter Regime Code"
                                    name="regimeLevelCode"
                                    value={formik?.values?.regimeLevelCode?.trim()?.toString() || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regimeLevelCode') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('regimeLevelCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="regimeLevelName" className={classNames({ 'p-error': isFormFieldValid('regimeLevelName') }, 'Label__Text')}>
                                    Regime Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    id="regimeLevelName"
                                    placeholder="Enter Regime Name"
                                    name="regimeLevelName"
                                    value={formik?.values?.regimeLevelName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regimeLevelName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('regimeLevelName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="regimeLevelDescr" className={classNames({ 'p-error': isFormFieldValid('regimeLevelDescr') }, 'Label__Text')}>
                                    Description
                                </label>
                                <InputTextarea
                                    disabled
                                    autoResize="false"
                                    id="regimeLevelDescr"
                                    placeholder="Enter Description..."
                                    name="regimeLevelDescr"
                                    value={formik?.values?.regimeLevelDescr?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regimeLevelDescr') }, 'Input__Round')}
                                    rows={3}
                                    cols={30}
                                />
                                {getFormErrorMessage('regimeLevelDescr')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label="Okay" className="Btn__Add" onClick={(e) => handleCancel(e)} />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={regimeLevelRowData?.regimeLevelId} tableName={tableName} />
        </>
    );
}

export default ViewRegimeLevelDialog;

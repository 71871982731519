import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import { InputTextarea } from 'primereact/inputtextarea';
import SecuredFileChange from '../../../components/SecuredFileChange';
import ColorPicker from 'react-pick-color';

function AddAgentMateProduct() {
    const [mainData, setMainData] = useState([]);
    const [subData, setSubData] = useState([]);
    const [showColorPicker, setShowColorPicker] = useState({ textColor: false, bgColor: false });
    const [selectedFileError, setSelectedFileError] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [productIdError, setProductIdError] = useState('');
    const [productId, setProductId] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const productIconRef = useRef(null);

    const validationSchema = Yup.object().shape({
        priority: Yup.string()
            .required('This field is required')
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[0-9]+$/, 'Priority must contain only numeric values'),
        amCmsCategoryId: Yup.string().required('This field is required'),
        amCmsSubCategoryId: Yup.string().required('This field is required'),

        productName: Yup.string().required('This field is required'),
        nameEnglish: Yup.string()
            .required('This field is required')
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z ]+$/, 'Display Name English must contain only alphabetic characters'),
        nameUrdu: Yup.string()
            .required('This field is required')
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z0-9\s]*$/, 'Display Name Urdu must be alphanumeric'),
        smsText: Yup.string()
            .required('This field is required')
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z0-9\s]*$/, 'Sms Text must be alphanumeric'),
        productIcon: Yup.string().required('This field is required'),
        cmsTagLabelId: Yup.string().when('addCheck', {
            is: true,
            then: Yup.string()
                .required('This field is required')
                .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
                .matches(/^[a-zA-Z0-9\s]*$/, 'Tag Label must be alphanumeric')
        }),
        cmsTagTextColorId: Yup.string().when('addCheck', {
            is: true,
            then: Yup.string().required('This field is required')
        }),

        cmsTagBgColorId: Yup.string().when('addCheck', {
            is: true,
            then: Yup.string().required('This field is required')
        }),

        otpLength: Yup.string().when('otpFlow', {
            is: 'Y',
            then: Yup.string()
                .required('This field is required')
                .matches(/^[0-9]+$/, 'This field must contain only numeric values')
        }),

        otpAttempts: Yup.string().when('otpFlow', {
            is: 'Y',
            then: Yup.string()
                .typeError('OTP Attempts must be a number')
                .matches(/^[0-9]+$/, 'OTP attempts must contain only numeric values')
                .test('greater-than-zero', 'OTP attempts must be greater than 0', function (value) {
                    const otpAttempts = parseFloat(value);
                    return otpAttempts > 0;
                })
                .required('This field is required')
        }),

        otpTimer: Yup.string().when('otpFlow', {
            is: 'Y',
            then: Yup.string()
                .required('This field is required')
                .matches(/^[0-9]+(\.[0-9]+)?$/, 'Must be a numeric or float value')
        }),
        otpType: Yup.string().when('otpFlow', {
            is: 'Y',
            then: Yup.string().required('This field is required')
        }),
        timeHours: Yup.string().when('otpFlow', {
            is: 'Y',
            then: Yup.string()
                .required('This field is required')
                .matches(/^[0-9]+$/, 'This field must contain only numeric values')
        })
    });
    const otpTypeLookup = [
        { lovId: '1', name: 'Numeric' },
        { lovId: '2', name: 'Alpha Numeric' }
    ];
    const formik = useFormik({
        validationSchema: validationSchema,

        initialValues: {
            amCmsProductId: '',
            amCmsCategoryId: '',
            amCmsSubCategoryId: '',
            subCategoryName: '',
            biometricFlow: 'Y',
            addCheck: false,
            cmsTagBgColorId: '',
            cmsTagLabelId: '',
            cmsTagTextColorId: '',
            dailyCreditLimit: '',
            dailyDebitLimit: '',
            expiryDate: '',
            isHide: 'Y',
            monthlyCreditLimit: '',
            monthlyDebitLimit: '',
            nameEnglish: '',
            nameUrdu: '',
            otpFlow: 'Y',
            otpLength: '',
            otpAttempts: '',
            otpTimer: '',
            productIcon: '',
            otpType: '',
            timeHours: '',
            priority: '',
            productCode: '',
            productId: '',
            productName: '',
            agentRecepient: '',
            receiverRecepient: '',
            senderRecepient: '',
            smsResponse: 'Y',
            smsText: '',
            yearlyCreditLimit: '',
            yearlyDebitLimit: ''
        },
        onSubmit: async (data) => {
            const addPayload = {
                data: {
                    security: {
                        userName: 'string',
                        password: 'string',
                        securityToken: 'string'
                    },
                    account: {
                        msidn: 'string',
                        iban: 'string',
                        bban: 'string',
                        pan: 'string',
                        currency: 'string'
                    },
                    channel: 'string',
                    terminal: 'string',
                    reterivalReferenceNumber: 'string',
                    payLoad: {
                        amCmsProductId: data?.amCmsProductId,
                        amCmsCategoryId: data?.amCmsCategoryId,
                        amCmsSubCategoryId: data?.amCmsSubCategoryId,
                        biometricFlow: data?.biometricFlow,
                        cmsTagBgColorId: data?.addCheck ? data?.cmsTagBgColorId : '',
                        cmsTagLabelId: data?.addCheck ? data?.cmsTagLabelId : '',
                        cmsTagTextColorId: data?.addCheck ? data?.cmsTagTextColorId : '',
                        dailyCreditLimit: data?.dailyCreditLimit,
                        dailyDebitLimit: data?.dailyDebitLimit,
                        expiryDate: data?.expiryDate,
                        isHide: data?.isHide,
                        monthlyCreditLimit: data?.monthlyCreditLimit,
                        monthlyDebitLimit: data?.monthlyDebitLimit,
                        nameEnglish: data?.nameEnglish,
                        nameUrdu: data?.nameUrdu,
                        otpFlow: data?.otpFlow,
                        otpLength: data?.otpFlow ? data?.otpLength : '',
                        otpAttempts: data?.otpFlow ? data?.otpAttempts : '',
                        otpTimer: data?.otpFlow ? data?.otpTimer : '',
                        otpType: data?.otpFlow ? data?.otpType : '',
                        timeHours: data?.otpFlow ? data?.timeHours : '',
                        priority: data?.priority,
                        productCode: data?.productCode,
                        productId: productId,
                        productName: data?.productName,
                        agentRecepient: data?.smsResponse === 'Y' ? (data?.agentRecepient ? 'Y' : 'N') : 'N',
                        receiverRecepient: data?.smsResponse === 'Y' ? (data?.receiverRecepient ? 'Y' : 'N') : 'N',
                        senderRecepient: data?.smsResponse === 'Y' ? (data?.senderRecepient ? 'Y' : 'N') : 'N',
                        smsResponse: data?.smsResponse,
                        smsText: data?.smsResponse ? data?.smsText : '',
                        yearlyCreditLimit: data?.yearlyCreditLimit,
                        yearlyDebitLimit: data?.yearlyDebitLimit
                    },
                    additionalInformation: [
                        {
                            infoKey: 'string',
                            infoValue: 'string'
                        }
                    ],
                    checkSum: 'string'
                }
            };

            const formData = new FormData();
            formData.append('request', JSON.stringify(addPayload));
            if (selectedFile) {
                formData.append('file', selectedFile);
            }

            dispatch(handlePostRequest(formData, '/agentmatecms/v1/agentmatecms/saveproduct', true, false, 'agentmateproduct'));
        }
    });

    const getProductName = async () => {
        if (productId) {
            setProductIdError('');
            const res = await handleGetRequest(`/agentmatecms/v1/agentmatecms/getproduct/${productId}`, true, '210000');
            const productName = res?.payLoad;
            formik.setFieldValue('productName', productName?.productName);
            formik.setFieldValue('dailyCreditLimit', productName?.transLimit?.dailyAmtLimitCr);
            formik.setFieldValue('monthlyCreditLimit', productName?.transLimit?.monthlyAmtLimitCr);
            formik.setFieldValue('yearlyCreditLimit', productName?.transLimit?.yearlyAmtLimitCr);
            formik.setFieldValue('dailyDebitLimit', productName?.transLimit?.dailyAmtLimitDr);
            formik.setFieldValue('monthlyDebitLimit', productName?.transLimit?.monthlyAmtLimitDr);
            formik.setFieldValue('yearlyDebitLimit', productName?.transLimit?.yearlyAmtLimitDr);
        } else {
            setProductIdError('This field is required.');
            formik.setFieldValue('productName', '');
            formik.setFieldValue('dailyCreditLimit', '');
            formik.setFieldValue('monthlyCreditLimit', '');
            formik.setFieldValue('yearlyCreditLimit', '');
            formik.setFieldValue('dailyDebitLimit', '');
            formik.setFieldValue('monthlyDebitLimit', '');
            formik.setFieldValue('yearlyDebitLimit', '');
        }
    };

    const getCategoryDataLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getagentmatecategorydatalov');
        setMainData(res?.payLoad);
    };

    const getSubCategoryDataLov = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getagentmatesubcategorydatalov/${formik?.values?.amCmsCategoryId}`);
        setSubData(res?.payLoad);
    };

    useEffect(() => {
        getCategoryDataLov();
        if (formik?.values?.amCmsCategoryId) {
            getSubCategoryDataLov();
        }
    }, [formik?.values?.amCmsCategoryId, formik?.values?.amCmsSubCategoryId]);

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const handleProductIcon = () => {
        productIconRef.current.click();
    };

    const handleProductIconChange = (event) => {
        setSelectedFile(null);
        formik.setFieldValue('productIcon', '');
        setSelectedFileError('');
        const selectedFile = event.target.files[0];
        const fileTypes = ['image'];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setSelectedFileError(error);
        } else if (newFile) {
            setSelectedFile(newFile);
            formik.setFieldValue('productIcon', newFile?.name);
        }
    };

    const handleOtpReset = () => {
        formik.setFieldValue('otpLength', '');
        formik.setFieldValue('otpType', '');
        formik.setFieldValue('otpAttempts', '');
        formik.setFieldValue('otpTimer', '');
        formik.setFieldValue('timeHours', '');
    };

    const getContrastColor = (hexColor) => {
        const r = parseInt(hexColor.substr(1, 2), 16);
        const g = parseInt(hexColor.substr(3, 2), 16);
        const b = parseInt(hexColor.substr(5, 2), 16);
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header Full__Width">
                    <h2>ADD NEW</h2>
                </div>

                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productId" className={classNames({ 'p-error': productIdError }, 'Label__Text')}>
                                    Product ID <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    keyfilter={/^[0-9]+$/}
                                    maxLength={50}
                                    id="productId"
                                    placeholder="Enter product ID"
                                    name="productId"
                                    value={productId || ''}
                                    onChange={(e) => {
                                        setProductIdError('');
                                        setProductId(e.target.value);
                                        formik.setFieldValue('productName', '');
                                        formik.setFieldValue('dailyCreditLimit', '');
                                        formik.setFieldValue('monthlyCreditLimit', '');
                                        formik.setFieldValue('yearlyCreditLimit', '');
                                        formik.setFieldValue('dailyDebitLimit', '');
                                        formik.setFieldValue('monthlyDebitLimit', '');
                                        formik.setFieldValue('yearlyDebitLimit', '');
                                    }}
                                    className={classNames({ 'p-invalid': productIdError }, 'Input__Round')}
                                />
                                {productIdError && <small className="p-error">{productIdError}</small>}
                            </div>
                        </div>
                        <dvi className="p-field col-12 md:col-8 pt-5 pb-3">
                            <div className="Down__Btn justify-content-start mt-2">
                                <Button type="button" label="Get" onClick={() => getProductName()} className="Btn__Dark" />
                            </div>
                        </dvi>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productName" className={classNames({ 'p-error': isFormFieldValid('productName') }, 'Label__Text')}>
                                    Product Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="productName"
                                    placeholder="Enter product name"
                                    name="productName"
                                    value={formik?.values?.productName || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('productName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('productName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="nameEnglish" className={classNames({ 'p-error': isFormFieldValid('nameEnglish') }, 'Label__Text')}>
                                    Display Name (English)<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="nameEnglish"
                                    placeholder="Enter display name"
                                    name="nameEnglish"
                                    value={formik?.values?.nameEnglish || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('nameEnglish') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('nameEnglish')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="nameUrdu" className={classNames({ 'p-error': isFormFieldValid('nameUrdu') }, 'Label__Text')}>
                                    Display Name (Urdu)<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="nameUrdu"
                                    placeholder="Enter display name"
                                    name="nameUrdu"
                                    value={formik?.values?.nameUrdu || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('nameUrdu') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('nameUrdu')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="priority" className={classNames({ 'p-error': isFormFieldValid('priority') }, 'Label__Text')}>
                                    Product Priority<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={50}
                                    id="priority"
                                    name="priority"
                                    placeholder="Enter Product Priority"
                                    value={formik?.values?.priority || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('priority') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('priority')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <div className="mb-2">
                                    <label className={classNames({ 'p-error': isFormFieldValid('productIcon') }, 'Label__Text')}>
                                        Product Icon <span className="Label__Required">*</span>
                                    </label>
                                    <div className="file-input-container w-100" onClick={handleProductIcon}>
                                        <input type="file" accept="image/*" style={{ display: 'none' }} ref={productIconRef} onChange={handleProductIconChange} />
                                        <input
                                            type="text"
                                            className={classNames({ 'p-inputtext p-component p-invalid': isFormFieldValid('productIcon') }, 'file-input-field Input__Round')}
                                            placeholder="Choose a file..."
                                            value={formik.values.productIcon}
                                            readOnly
                                        />
                                        <span className="file-upload-icon">
                                            <i className="pi pi-paperclip" />
                                        </span>
                                    </div>
                                    {selectedFileError ? <small className="p-error">{selectedFileError}</small> : getFormErrorMessage('productIcon')}
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>
                        <div className="p-field col-12 md:col-1 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="addCheck" className={classNames({ 'p-error': isFormFieldValid('addCheck') }, 'Label__Text')}>
                                    Add Tag
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="addCheck" name="addCheck" checked={formik?.values?.addCheck} onChange={formik.handleChange} style={{ marginTop: '10px' }} />{' '}
                                </div>
                                {getFormErrorMessage('addCheck')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsTagLabelId" className={classNames({ 'p-error': isFormFieldValid('cmsTagLabelId') }, 'Label__Text')}>
                                    Tag Label
                                </label>
                                <InputText
                                    maxLength={25}
                                    id="cmsTagLabelId"
                                    placeholder="Enter Tag Label"
                                    name="cmsTagLabelId"
                                    disabled={!formik?.values?.addCheck}
                                    value={formik?.values?.cmsTagLabelId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagLabelId') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('cmsTagLabelId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsTagTextColorId" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextColorId') }, 'Label__Text')}>
                                    Tag Text Color
                                </label>
                                <InputText
                                    value={formik?.values?.cmsTagTextColorId || '#FFFFFF'}
                                    onChange={(e) => {
                                        formik.setFieldValue('cmsTagTextColorId', e.target.value);
                                    }}
                                    onClick={() => setShowColorPicker({ textColor: true, bgColor: false })}
                                    disabled={!formik?.values?.addCheck}
                                    readOnly
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextColorId') }, 'Input__Round')}
                                    style={{
                                        backgroundColor: formik?.values?.cmsTagTextColorId || '#FFFFFF',
                                        color: getContrastColor(formik?.values?.cmsTagTextColorId || '#FFFFFF')
                                    }}
                                />

                                {getFormErrorMessage('cmsTagTextColorId')}
                                {showColorPicker?.textColor && (
                                    <div className="colorPickerForInput">
                                        <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                        <ColorPicker color={formik?.values?.cmsTagTextColorId || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagTextColorId', e?.hex)} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsTagBgColorId" className={classNames({ 'p-error': isFormFieldValid('cmsTagBgColorId') }, 'Label__Text')}>
                                    Tag Background Color
                                </label>
                                <InputText
                                    value={formik?.values?.cmsTagBgColorId || '#FFFFFF'}
                                    onChange={(e) => {
                                        formik.setFieldValue('cmsTagBgColorId', e.target.value);
                                    }}
                                    onClick={() => setShowColorPicker({ textColor: false, bgColor: true })}
                                    disabled={!formik?.values?.addCheck}
                                    readOnly
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagBgColorId') }, 'Input__Round')}
                                    style={{
                                        backgroundColor: formik?.values?.cmsTagBgColorId || '#FFFFFF',
                                        color: getContrastColor(formik?.values?.cmsTagBgColorId || '#FFFFFF')
                                    }}
                                />
                                {getFormErrorMessage('cmsTagBgColorId')}
                                {showColorPicker?.bgColor && (
                                    <div className="colorPickerForInput">
                                        <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                        <ColorPicker color={formik?.values?.cmsTagBgColorId || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagBgColorId', e?.hex)} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="expiryDate" className={classNames({ 'p-error': isFormFieldValid('expiryDate') }, 'Label__Text')}>
                                    Expiry Date
                                </label>
                                <InputText
                                    min={previousDate}
                                    type="date"
                                    id="expiryDate"
                                    name="expiryDate"
                                    value={formik?.values?.expiryDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expiryDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('expiryDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="amCmsCategoryId" className={classNames({ 'p-error': isFormFieldValid('amCmsCategoryId') }, 'Label__Text')}>
                                    Main Category<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="amCmsCategoryId"
                                    placeholder="Select Category"
                                    options={mainData}
                                    optionLabel="name"
                                    name="amCmsCategoryId"
                                    optionValue="lovId"
                                    value={formik.values.amCmsCategoryId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('amCmsCategoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('amCmsCategoryId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="amCmsSubCategoryId" className={classNames({ 'p-error': isFormFieldValid('amCmsSubCategoryId') }, 'Label__Text')}>
                                    Sub Category<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="amCmsSubCategoryId"
                                    placeholder="Select Sub Category"
                                    options={subData}
                                    optionLabel="name"
                                    name="amCmsSubCategoryId"
                                    optionValue="lovId"
                                    value={formik.values.amCmsSubCategoryId || ''}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        const findSubcategory = subData?.filter((item) => item?.lovId === e.target.value)[0]?.name;
                                        formik?.setFieldValue('subCategoryName', findSubcategory);
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldValid('amCmsSubCategoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('amCmsSubCategoryId')}
                            </div>
                        </div>
                        {formik?.values?.subCategoryName === 'IBFT' && (
                            <div className="card Card__Bg__Change col-12 md:col-12 px-3">
                                <h6 className="secondary-color pt-1">
                                    <b>Transaction Limit</b>
                                </h6>
                                <div className="p-fluid p-formgrid grid mb-5">
                                    <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                        <h6 className="secondary-color pt-1 text-center">
                                            <b>Debit</b>
                                        </h6>
                                        <div className="p-field col-12 md:col-8 pt-3 pb-3 mx-auto">
                                            <div className="p-field">
                                                <label htmlFor="dailyDebitLimit" className={classNames({ 'p-error': isFormFieldValid('dailyDebitLimit') }, 'Label__Text')}>
                                                    Daily Limit
                                                </label>
                                                <InputText
                                                    disabled
                                                    id="dailyDebitLimit"
                                                    placeholder="-"
                                                    name="dailyDebitLimit"
                                                    value={formik?.values?.dailyDebitLimit || ''}
                                                    // onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('dailyDebitLimit') }, 'Input__Round')}
                                                />

                                                {getFormErrorMessage('dailyDebitLimit')}
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-8 pt-3 pb-3 mx-auto">
                                            <div className="p-field">
                                                <label htmlFor="monthlyDebitLimit" className={classNames({ 'p-error': isFormFieldValid('monthlyDebitLimit') }, 'Label__Text')}>
                                                    Monthly Limit
                                                </label>
                                                <InputText
                                                    disabled
                                                    id="monthlyDebitLimit"
                                                    placeholder="-"
                                                    name="monthlyDebitLimit"
                                                    value={formik?.values?.monthlyDebitLimit || ''}
                                                    // onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('monthlyDebitLimit') }, 'Input__Round')}
                                                />

                                                {getFormErrorMessage('monthlyDebitLimit')}
                                            </div>
                                        </div>{' '}
                                        <div className="p-field col-12 md:col-8 pt-3 pb-3 mx-auto">
                                            <div className="p-field">
                                                <label htmlFor="yearlyDebitLimit" className={classNames({ 'p-error': isFormFieldValid('yearlyDebitLimit') }, 'Label__Text')}>
                                                    Yearly Limit
                                                </label>
                                                <InputText
                                                    disabled
                                                    id="yearlyDebitLimit"
                                                    placeholder="-"
                                                    name="yearlyDebitLimit"
                                                    value={formik?.values?.yearlyDebitLimit || ''}
                                                    // onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('yearlyDebitLimit') }, 'Input__Round')}
                                                />

                                                {getFormErrorMessage('yearlyDebitLimit')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                        <h6 className="secondary-color pt-1 text-center">
                                            <b>Credit</b>
                                        </h6>
                                        <div className="p-field col-12 md:col-8 pt-3 pb-3 mx-auto">
                                            <div className="p-field">
                                                <label htmlFor="dailyCreditLimit" className={classNames({ 'p-error': isFormFieldValid('dailyCreditLimit') }, 'Label__Text')}>
                                                    Daily Limit
                                                </label>
                                                <InputText
                                                    disabled
                                                    id="dailyCreditLimit"
                                                    placeholder="-"
                                                    name="dailyCreditLimit"
                                                    value={formik?.values?.dailyCreditLimit || ''}
                                                    // onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('dailyCreditLimit') }, 'Input__Round')}
                                                />

                                                {getFormErrorMessage('dailyCreditLimit')}
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-8 pt-3 pb-3 mx-auto">
                                            <div className="p-field">
                                                <label htmlFor="monthlyCreditLimit" className={classNames({ 'p-error': isFormFieldValid('monthlyCreditLimit') }, 'Label__Text')}>
                                                    Monthly Limit
                                                </label>
                                                <InputText
                                                    disabled
                                                    id="monthlyCreditLimit"
                                                    placeholder="-"
                                                    name="monthlyCreditLimit"
                                                    value={formik?.values?.monthlyCreditLimit || ''}
                                                    // onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('monthlyCreditLimit') }, 'Input__Round')}
                                                />

                                                {getFormErrorMessage('monthlyCreditLimit')}
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-8 pt-3 pb-3 mx-auto">
                                            <div className="p-field">
                                                <label htmlFor="yearlyCreditLimit" className={classNames({ 'p-error': isFormFieldValid('yearlyCreditLimit') }, 'Label__Text')}>
                                                    Yearly Limit
                                                </label>
                                                <InputText
                                                    disabled
                                                    id="yearlyCreditLimit"
                                                    placeholder="-"
                                                    name="yearlyCreditLimit"
                                                    value={formik?.values?.yearlyCreditLimit || ''}
                                                    // onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('yearlyCreditLimit') }, 'Input__Round')}
                                                />

                                                {getFormErrorMessage('yearlyCreditLimit')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isHide" className={classNames({ 'p-error': isFormFieldValid('isHide') }, 'Label__Text')}>
                                    Status
                                </label>
                                <div className="flex ml-2">
                                    <div className="mt-0 mr-5">
                                        <Checkbox inputId="show" name="isHide" value="Y" checked={formik?.values?.isHide === 'Y'} onChange={(e) => formik.setFieldValue('isHide', 'Y')} style={{ marginTop: '10px' }} />
                                        <label htmlFor="show" className={classNames({ 'p-error': isFormFieldValid('show') }, 'Label__Text')}>
                                            Show
                                        </label>
                                    </div>
                                    <div className="mt-0">
                                        <Checkbox inputId="hide" name="isHide" value="N" checked={formik?.values?.isHide === 'N'} onChange={(e) => formik.setFieldValue('isHide', 'N')} style={{ marginTop: '10px' }} />
                                        <label htmlFor="hide" className={classNames({ 'p-error': isFormFieldValid('hide') }, 'Label__Text')}>
                                            Hide
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="biometricFlow" className={classNames({ 'p-error': isFormFieldValid('biometricFlow') }, 'Label__Text')}>
                                    Biometric Flow
                                </label>
                                <div className="flex ml-2">
                                    <div className="mt-0 mr-5">
                                        <Checkbox inputId="enable" name="biometricFlow" value="Y" checked={formik?.values?.biometricFlow === 'Y'} onChange={(e) => formik.setFieldValue('biometricFlow', 'Y')} style={{ marginTop: '10px' }} />
                                        <label htmlFor="enable" className={classNames({ 'p-error': isFormFieldValid('enable') }, 'Label__Text')}>
                                            Enable
                                        </label>
                                    </div>
                                    <div className="mt-0">
                                        <Checkbox inputId="disable" name="biometricFlow" value="N" checked={formik?.values?.biometricFlow === 'N'} onChange={(e) => formik.setFieldValue('biometricFlow', 'N')} style={{ marginTop: '10px' }} />
                                        <label htmlFor="disable" className={classNames({ 'p-error': isFormFieldValid('disable') }, 'Label__Text')}>
                                            Disable
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="otpFlow" className={classNames({ 'p-error': isFormFieldValid('otpFlow') }, 'Label__Text')}>
                                    OTP Flow
                                </label>
                                <div className="flex ml-2">
                                    <div className="mt-0 mr-5">
                                        <Checkbox
                                            inputId="enable"
                                            name="otpFlow"
                                            value="Y"
                                            checked={formik?.values?.otpFlow === 'Y'}
                                            onChange={(e) => {
                                                formik.setFieldValue('otpFlow', 'Y');
                                                handleOtpReset();
                                            }}
                                            style={{ marginTop: '10px' }}
                                        />
                                        <label htmlFor="enable" className={classNames({ 'p-error': isFormFieldValid('enable') }, 'Label__Text')}>
                                            Enable
                                        </label>
                                    </div>
                                    <div className="mt-0">
                                        <Checkbox
                                            inputId="disable"
                                            name="otpFlow"
                                            value="N"
                                            checked={formik?.values?.otpFlow === 'N'}
                                            onChange={(e) => {
                                                formik.setFieldValue('otpFlow', 'N');
                                                handleOtpReset();
                                            }}
                                            style={{ marginTop: '10px' }}
                                        />
                                        <label htmlFor="disable" className={classNames({ 'p-error': isFormFieldValid('disable') }, 'Label__Text')}>
                                            Disable
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="smsResponse" className={classNames({ 'p-error': isFormFieldValid('smsResponse') }, 'Label__Text')}>
                                    SMS Response
                                </label>
                                <div className="flex ml-2">
                                    <div className="mt-0 mr-5">
                                        <Checkbox inputId="enable" name="smsResponse" value="Y" checked={formik?.values?.smsResponse === 'Y'} onChange={(e) => formik.setFieldValue('smsResponse', 'Y')} style={{ marginTop: '10px' }} />
                                        <label htmlFor="enable" className={classNames({ 'p-error': isFormFieldValid('enable') }, 'Label__Text')}>
                                            Enable
                                        </label>
                                    </div>
                                    <div className="mt-0">
                                        <Checkbox inputId="disable" name="smsResponse" value="N" checked={formik?.values?.smsResponse === 'N'} onChange={(e) => formik.setFieldValue('smsResponse', 'N')} style={{ marginTop: '10px' }} />
                                        <label htmlFor="disable" className={classNames({ 'p-error': isFormFieldValid('disable') }, 'Label__Text')}>
                                            Disable
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-8 pt-3 pb-3 ">
                            <div className="desc_Text_Area">
                                <label htmlFor="smsText" className={classNames({ 'p-error': isFormFieldValid('smsText') }, 'Label__Text')}>
                                    SMS Text <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    maxLength={200}
                                    placeholder="-"
                                    inputid="smsText"
                                    name="smsText"
                                    rows={4}
                                    cols={30}
                                    onChange={formik.handleChange}
                                    value={formik?.values?.smsText}
                                    disabled={!formik?.values?.smsResponse}
                                    className={classNames({ 'p-invalid': isFormFieldValid('smsText') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('smsText')}
                            </div>
                        </div>
                        <div className="card Card__Bg__Change col-12 md:col-12 px-3">
                            <div className="grid">
                                <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                    <div className="p-field">
                                        <h6 className="secondary-color pt-1">
                                            <b>SMS Recipient</b>
                                        </h6>
                                        <div className="flex ml-2">
                                            <div className="mt-0 mr-5">
                                                <Checkbox
                                                    inputId="agentRecepient"
                                                    name="agentRecepient"
                                                    value={formik?.values?.agentRecepient}
                                                    checked={formik?.values?.agentRecepient}
                                                    onChange={formik.handleChange}
                                                    disabled={formik?.values?.smsResponse === 'N'}
                                                    style={{ marginTop: '10px' }}
                                                />{' '}
                                                <label htmlFor="agentRecepient" className={classNames({ 'p-error': isFormFieldValid('agentRecepient') }, 'Label__Text')}>
                                                    Agent Recipient
                                                </label>
                                            </div>
                                            <div className="mt-0 mr-5">
                                                <Checkbox
                                                    inputId="senderRecepient"
                                                    name="senderRecepient"
                                                    value={formik?.values?.senderRecepient}
                                                    checked={formik?.values?.senderRecepient}
                                                    onChange={formik.handleChange}
                                                    disabled={formik?.values?.smsResponse === 'N'}
                                                    style={{ marginTop: '10px' }}
                                                />

                                                <label htmlFor="senderRecepient" className={classNames({ 'p-error': isFormFieldValid('hide') }, 'Label__Text')}>
                                                    Sender Recipient
                                                </label>
                                            </div>
                                            <div className="mt-0">
                                                <Checkbox
                                                    inputId="receiverRecepient"
                                                    name="receiverRecepient"
                                                    value={formik?.values?.receiverRecepient}
                                                    checked={formik?.values?.receiverRecepient}
                                                    onChange={formik.handleChange}
                                                    disabled={formik?.values?.smsResponse === 'N'}
                                                    style={{ marginTop: '10px' }}
                                                />

                                                <label htmlFor="receiverRecepient" className={classNames({ 'p-error': isFormFieldValid('hide') }, 'Label__Text')}>
                                                    Reciever Recipient
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {formik.values.otpFlow === 'Y' && (
                            <div className="card Card__Bg__Change col-12 md:col-12 px-3">
                                <h6 className="secondary-color pt-1">
                                    <b>OTP</b>
                                </h6>
                                <div className="grid">
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="otpLength" className={classNames({ 'p-error': isFormFieldValid('otpLength') }, 'Label__Text')}>
                                                OTP Length
                                            </label>
                                            <InputText
                                                maxLength={5}
                                                id="otpLength"
                                                placeholder="Enter OTP Length"
                                                name="otpLength"
                                                value={formik?.values?.otpLength || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('otpLength') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('otpLength')}
                                        </div>
                                    </div>{' '}
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="otpType" className={classNames({ 'p-error': isFormFieldValid('otpType') }, 'Label__Text')}>
                                                OTP Type
                                            </label>
                                            <Dropdown
                                                id="otpType"
                                                placeholder="Select OTP Type"
                                                options={otpTypeLookup}
                                                optionLabel="name"
                                                name="otpType"
                                                optionValue="lovId"
                                                value={formik?.values?.otpType || ''}
                                                onChange={(e) => formik.setFieldValue('otpType', e.value)}
                                                className={classNames({ 'p-invalid': isFormFieldValid('otpType') }, 'Dropdown__Round')}
                                            />

                                            {getFormErrorMessage('otpType')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="otpAttempts" className={classNames({ 'p-error': isFormFieldValid('otpAttempts') }, 'Label__Text')}>
                                                OTP Attempts
                                            </label>
                                            <InputText
                                                maxLength={1}
                                                id="otpAttempts"
                                                placeholder="Enter OTP Attempts"
                                                name="otpAttempts"
                                                value={formik?.values?.otpAttempts || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('otpAttempts') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('otpAttempts')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="otpTimer" className={classNames({ 'p-error': isFormFieldValid('otpTimer') }, 'Label__Text')}>
                                                OTP Timer
                                            </label>
                                            <InputText
                                                maxLength={5}
                                                id="otpTimer"
                                                placeholder="Enter OTP Timer"
                                                name="otpTimer"
                                                value={formik?.values?.otpTimer || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('otpTimer') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('otpTimer')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="timeHours" className={classNames({ 'p-error': isFormFieldValid('timeHours') }, 'Label__Text')}>
                                                Time Hours
                                            </label>
                                            <InputText
                                                maxLength={5}
                                                id="timeHours"
                                                placeholder="Enter Time in Hours"
                                                name="timeHours"
                                                value={formik?.values?.timeHours || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('timeHours') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('timeHours')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="Down__Btn">
                        <Button type="submit" label="Submit" className="Btn__Dark" />
                        <Button onClick={() => navigate('/agentmateproduct')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddAgentMateProduct;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { useRef } from 'react';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import ColorPicker from 'react-pick-color';

function ViewSubCategory() {
    const [subCategoryIconFileName, setSubCategoryIconFileName] = useState();
    const [mainData, setMainData] = useState([]);
    const [showColorPicker, setShowColorPicker] = useState({ textColor: false, bgColor: false });

    const navigate = useNavigate();
    const location = useLocation();
    const { rowData } = location.state || {};
    const tableName = 'TBL_CMS_SUB_CATEGORY';
    const subCategoryRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            cmsSubCategoryId: '',
            cmsSubCategoryName: '',

            displayNameUrdu: '',
            cmsMainCategoryId: '',
            subCategoryNumber: '',
            subCategoryIcon: '',
            subCategorySeq: '',
            addCheck: false,
            cmsTagLabel: '',
            cmsTagTextColor: '',
            cmsTagTextBgColor: '',
            tagExpiryDate: '',
            isHide: '',
            copyEngToUrdu:'',
            isActive: ''
        }
    });

    const getCategoryDataLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcategorydatalov');
        setMainData(res?.payLoad);
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());

    const getSubCatDetailsById = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/getsubcategorybyid/${rowData?.cmsSubCategoryId}`);
        const productDetails = res?.payLoad;
        if (productDetails) {
            formik.setFieldValue('cmsSubCategoryId', productDetails.cmsSubCategoryId || '');
            formik.setFieldValue('cmsSubCategoryName', productDetails.cmsSubCategoryName || '');
            formik.setFieldValue('displayNameEnglist', productDetails.displayNameEnglist || '');
            formik.setFieldValue('displayNameUrdu', productDetails.displayNameUrdu || '');
            formik.setFieldValue('subCategoryNumber', productDetails.subCategoryNumber || '');
            // formik.setFieldValue('subCategoryIcon', productDetails.subCategoryIcon || '');
            formik.setFieldValue('subCategorySeq', productDetails.subCategorySeq || '');
            formik.setFieldValue('cmsMainCategoryId', productDetails?.cmsMainCategoryId?.toString());
            formik.setFieldValue('addCheck', productDetails?.cmsTagLabel ? true : false);
            formik.setFieldValue('cmsTagLabel', productDetails?.cmsTagLabel);
            formik.setFieldValue('cmsTagTextColor', productDetails?.cmsTagTextColor);
            formik.setFieldValue('cmsTagTextBgColor', productDetails?.cmsTagTextBgColor);
            const correctEndDate = (productDetails?.tagExpiryDate && formattedDate(productDetails?.tagExpiryDate)) || '';
            formik.setFieldValue('tagExpiryDate', correctEndDate || '');
            formik.setFieldValue('isHide', productDetails.isHide);
            formik.setFieldValue('isActive', productDetails.isActive);
            const pathParts = productDetails?.subCategoryIcon?.split('/');
            const subCategoryIcon = pathParts ? pathParts[pathParts?.length - 1] : '';
            setSubCategoryIconFileName(subCategoryIcon);
        }
    };
    useEffect(() => {
        getCategoryDataLov();

        if (rowData) getSubCatDetailsById();
    }, [rowData]); // eslint-disable-line

    function formattedDate(originalDate) {
        const createDate = new Date(originalDate);

        const year = createDate.getFullYear();
        const month = (createDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const day = createDate.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const handledSubCategoryIcon = () => {
        subCategoryRef.current.click();
    };
    const getContrastColor = (hexColor) => {
        const r = parseInt(hexColor.substr(1, 2), 16);
        const g = parseInt(hexColor.substr(3, 2), 16);
        const b = parseInt(hexColor.substr(5, 2), 16);
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>VIEW DETAILS</h2>
                        </div>
                        
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field" >
                              
                            <label htmlFor="cmsSubCategoryName" className={classNames({ 'p-error': isFormFieldValid('cmsSubCategoryName') }, 'Label__Text')}>
                                    Sub Category Name <span className="Label__Required">*</span>
                                  
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="cmsSubCategoryName"
                                    placeholder="Enter 2nd Sub Category Name"
                                    name="cmsSubCategoryName"
                                    value={formik?.values?.cmsSubCategoryName || ''}
                                disabled
                                      onChange={(e) => {
                                        formik.handleChange(e);
                                        if (formik.values.copyEngToUrdu) {
                                            formik.setFieldValue('displayNameUrdu', e.target.value);
                                        }
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsSubCategoryName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('cmsSubCategoryName')}
                            </div>
                        </div>
                     

                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                            <div className="p-field-checkbox flex justify-content-between">
                                   
                                   <label htmlFor="cmsSecondSubCategoryName" className={classNames({ 'p-error': isFormFieldValid('cmsSecondSubCategoryName') }, 'Label__Text')}>
                                   Display Name (Urdu)<span className="Label__Required">*</span>
                                     
                                   </label>
                                 <div className='flex align-items-center ml-auto'>
                                   <label htmlFor="cmsSecondSubCategoryName" className={classNames({ 'p-error': isFormFieldValid('cmsSecondSubCategoryName') }, 'Label__Text')}>
                                      Same AS (English)
                                     
                                   </label>
                                   </div>
                                   <Checkbox
                                       inputId="copyEngToUrdu"
                                       name="copyEngToUrdu"
                                       disabled
                                       checked={formik.values.copyEngToUrdu}
                                       onChange={(e) => {
                                           formik.setFieldValue('copyEngToUrdu', e.checked);
                                           if (e.checked) {
                                               formik.setFieldValue('displayNameUrdu', formik.values.cmsSubCategoryName);
                                           }
                                       }}
                                   />
                                 
                               </div>
                                <InputText
                                    maxLength={100}
                                    id="displayNameUrdu"
                                    disabled
                                    placeholder="Enter Display Name In Urdu"
                                    name="displayNameUrdu"
                                    value={formik?.values?.displayNameUrdu || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('displayNameUrdu') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('displayNameUrdu')}
                            </div>
                        </div>
                       

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsMainCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsMainCategoryId') }, 'Label__Text')}>
                                    Main Category<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="cmsMainCategoryId"
                                    placeholder="Select Main Category"
                                    options={mainData}
                                    optionLabel="name"
                                    name="cmsMainCategoryId"
                                    optionValue="lovId"
                                    value={formik.values.cmsMainCategoryId || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsMainCategoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsMainCategoryId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="subCategoryNumber" className={classNames({ 'p-error': isFormFieldValid('subcmsMainCategoryId') }, 'Label__Text')}>
                                    Sub Category Number <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="subCategoryNumber"
                                    placeholder="Enter Sub Category Number"
                                    name="subCategoryNumber"
                                    value={formik?.values?.subCategoryNumber || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('subCategoryNumber') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('subCategoryNumber')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <div className="mb-2">
                                    <label className="Label__Text">
                                        Sub Category Icon<span className="Label__Required">*</span>
                                    </label>
                                    <div className="file-input-container w-100" onClick={handledSubCategoryIcon}>
                                        <input type="file" style={{ display: 'none' }} ref={subCategoryRef} accept="image/*" disabled />
                                        <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={subCategoryIconFileName} readOnly />
                                        <span className="file-upload-icon">
                                            <i className="pi pi-paperclip" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="subCategorySeq" className={classNames({ 'p-error': isFormFieldValid('subCategorySeq') }, 'Label__Text')}>
                                Priority
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="subCategorySeq"
                                    placeholder="Enter Category Sequence"
                                    name="subCategorySeq"
                                    value={formik?.values?.subCategorySeq || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('subCategorySeq') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('subCategorySeq')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-1 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="addCheck" className={classNames({ 'p-error': isFormFieldValid('addCheck') }, 'Label__Text')}>
                                    Add Tag
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="addCheck" name="addCheck" checked={formik?.values?.addCheck} onChange={formik.handleChange} disabled style={{ marginTop: '10px' }} />{' '}
                                </div>
                                {getFormErrorMessage('addCheck')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsTagLabel" className={classNames({ 'p-error': isFormFieldValid('cmsTagLabel') }, 'Label__Text')}>
                                    Tag Label
                                </label>

                                <InputText
                                    maxLength={100}
                                    id="cmsTagLabel"
                                    placeholder="Enter Tag Label"
                                    name="cmsTagLabel"
                                    disabled
                                    value={formik?.values?.cmsTagLabel || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagLabel') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('cmsTagLabel')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field relative">
                                <label htmlFor="cmsTagTextColor" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextColor') }, 'Label__Text')}>
                                    Tag Text Color
                                </label>
                                <InputText
                                    value={formik?.values?.cmsTagTextColor || '#FFFFFF'}
                                    onChange={(e) => {
                                        formik.setFieldValue('cmsTagTextColor', e.target.value);
                                    }}
                                    onClick={() => setShowColorPicker({ textColor: true, bgColor: false })}
                                    disabled
                                    readOnly
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextColor') }, 'Input__Round')}
                                    style={{
                                        backgroundColor: formik?.values?.cmsTagTextColor || '#FFFFFF',
                                        color: getContrastColor(formik?.values?.cmsTagTextColor || '#FFFFFF')
                                    }}
                                />
                                {getFormErrorMessage('cmsTagTextColor')}
                                {showColorPicker?.textColor && (
                                    <div className="colorPickerForInput">
                                        <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                        <ColorPicker color={formik?.values?.cmsTagTextColor || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagTextColor', e?.hex)} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsTagTextBgColor" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextBgColor') }, 'Label__Text')}>
                                    Tag Background Color
                                </label>
                                <InputText
                                    value={formik?.values?.cmsTagTextBgColor || '#FFFFFF'}
                                    onChange={(e) => {
                                        formik.setFieldValue('cmsTagTextBgColor', e.target.value);
                                    }}
                                    onClick={() => setShowColorPicker({ textColor: false, bgColor: true })}
                                    disabled
                                    readOnly
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextBgColor') }, 'Input__Round')}
                                    style={{
                                        backgroundColor: formik?.values?.cmsTagTextBgColor || '#FFFFFF',
                                        color: getContrastColor(formik?.values?.cmsTagTextBgColor || '#FFFFFF')
                                    }}
                                />
                                {getFormErrorMessage('cmsTagTextBgColor')}
                                {showColorPicker?.bgColor && (
                                    <div className="colorPickerForInput">
                                        <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                        <ColorPicker color={formik?.values?.cmsTagTextBgColor || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagTextBgColor', e?.hex)} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="tagExpiryDate" className={classNames({ 'p-error': isFormFieldValid('tagExpiryDate') }, 'Label__Text')}>
                                    Tag Expiry Date
                                </label>
                                <InputText
                                    type="date"
                                    id="tagExpiryDate"
                                    name="tagExpiryDate"
                                    value={formik?.values?.tagExpiryDate || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('tagExpiryDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('tagExpiryDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-2 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isHide" className={classNames({ 'p-error': isFormFieldValid('isHide') }, 'Label__Text')}>
                                    Status
                                </label>
                                <div className="flex ml-2">
                                    <div className="mt-1 mr-5">
                                        <Checkbox inputId="show" name="isHide" value="Y" checked={formik?.values?.isHide === 'Y'} onChange={(e) => formik.setFieldValue('isHide', 'Y')} disabled style={{ marginTop: '10px' }} />
                                        <label htmlFor="show" className={classNames({ 'p-error': isFormFieldValid('show') }, 'Label__Text')}>
                                            Show
                                        </label>
                                    </div>
                                    <div className="mt-1">
                                        <Checkbox inputId="hide" name="isHide" value="N" checked={formik?.values?.isHide === 'N'} onChange={(e) => formik.setFieldValue('isHide', 'N')} disabled style={{ marginTop: '10px' }} />
                                        <label htmlFor="hide" className={classNames({ 'p-error': isFormFieldValid('hide') }, 'Label__Text')}>
                                            Hide
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="button" label="Okay" onClick={() => navigate('/subcategory')} className="Btn__Dark" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={rowData.cmsSubCategoryId} tableName={tableName} />
        </>
    );
}

export default ViewSubCategory;

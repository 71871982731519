import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { FilterMatchMode } from 'primereact/api';
import { handlePostRequest } from '../../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../../services/GetTemplate';
import ActiveInActiveDialog from '../../../../../components/ActiveInActiveDialog/ActiveInActiveDialog';

function Pmd() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dataTableRef = useRef(null);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [showModal, setShowModal] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [switchData, setSwitchData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        attempts: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        attemptsTime: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        blockTime: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        isPermanentBlock: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        isTemporaryBlock: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        createdate: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        dateFrom: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        dateTo: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        createdBy: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        updatedBy: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        status: { value: '', matchMode: FilterMatchMode.EQUALS }
    });

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            attempts: '',
            attemptsTime: '',
            breachLimit: '',
            temporaryBlock: '',
            permanentBlock: '',
            createDate: '',
            createdBy: '',
            lastUpdateDate: '',
            statusId: '2'
        },

        onSubmit: async (data) => {
            setloadingIcon('pi pi-spin pi-spinner');
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            delete newData.data.payLoad.breachLimit;
            dataTableRef.current.reset();
            const res = await dispatch(handlePostRequest(newData, '/cms/v1/cms/searchallpmdconfig', true, true));
            const updatedArray = res?.payLoad?.map((item) => {
                statusLovData?.map((status) => {
                    if (item?.statusId?.toString() === status?.lovId) {
                        item['statusName'] = status?.name;
                    }
                });
                if (item?.isTemporaryBlock === 'Y') {
                    item['BreachLimit'] = 'Temporary';
                }
                if (item?.isPermanentBlock === 'Y') {
                    item['BreachLimit'] = 'Permanent';
                }
                return { ...item, lastUpdatedOn: item?.lastUpdatedOn || 'N/A', lastUpdateUser: item?.lastUpdateUser || 'N/A', breachLimit: item?.BreachLimit || 'N/A' };
            });
            setSearchData(updatedArray);
            setloadingIcon('pi pi-search');
        }
    });

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(res?.payLoad);
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">PMD Configuration</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const header = () => renderHeader();

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const handleSwitchChange = async () => {
        const updatedData = searchData?.map((item) => {
            if (item?.cmsPmdConfigId === switchData?.cmsPmdConfigId) {
                const isActive = item?.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setSearchData(updatedData);
        let isactive = '';
        if (switchData?.isActive === 'Y') {
            isactive = 'N';
        } else if (switchData?.isActive === 'N') {
            isactive = 'Y';
        }
        const previousValue = switchData?.isActive;

        const data = {
            isActive: isactive,
            id: switchData?.cmsPmdConfigId
            // attempts: switchData?.attempts,
            // attemptsTime: switchData?.attemptsTime,
            // blockTime: switchData?.blockTime,
            // isPermanentBlock: switchData?.isPermanentBlock,
            // isTemporaryBlock: switchData?.isTemporaryBlock
        };

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newData, '/cms/v1/cms/inactivepmdconfig', true, false));
        if (res?.responseCode == '010000') {
            const revertedData = searchData?.map((item) => {
                if (item.cmsPmdConfigId == switchData?.cmsPmdConfigId) {
                    const isActive = isactive;
                    return { ...item, isActive };
                }
                return item;
            });
            setSearchData(revertedData);
        } else {
            const revertedData = searchData?.map((item) => {
                if (item.cmsPmdConfigId == switchData?.cmsPmdConfigId) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setSearchData(revertedData);
        }
    };

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }
        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <InputSwitch
                        checked={rowData.isActive === 'Y'}
                        disabled={['Pending', 'Rejected'].includes(rowData?.statusName) ? true : false}
                        onChange={() => {
                            setSwitchData(rowData);
                            setShowModal(true);
                        }}
                    />
                    <Button
                        tooltip="Edit Detail"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: 'top' }}
                        disabled={['Pending', 'Rejected'].includes(rowData?.statusName) || (rowData?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                        onClick={() => navigate(`/editpmd`, { state: { rowData } })}
                        className="p-button-rounded p-button-primary"
                    />
                    <Button
                        tooltip="View Detail"
                        tooltipOptions={{ position: 'top' }}
                        // disabled={['Pending', 'Rejected'].includes(rowData?.statusName) || (rowData?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                        icon="pi pi-eye"
                        onClick={() => navigate(`/viewpmd`, { state: { rowData } })}
                        className="p-button-rounded"
                        style={{ backgroundColor: '#F2AC57', border: 'none' }}
                    />
                </div>
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.statusName === 'Approved' ? '#14A38B' : rowData?.statusName === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.statusName}</b>
            </p>
        );
    };

    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const handleShowModal = () => {
        setShowModal(false);
    };

    const props = {
        showModal,
        handleShowModal,
        handleSwitchChange
    };

    useEffect(() => {
        getStatusListData();
        getUserLov();
    }, []);

    return (
        <>
            <ActiveInActiveDialog {...props} />
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={() => navigate('/addpmd')} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="attempts" className={classNames({ 'p-error': isFormFieldValid('attempts') }, 'Label__Text')}>
                                    Attempts
                                </label>
                                <InputText
                                    placeholder="Enter Attempts"
                                    id="attempts"
                                    name="attempts"
                                    value={formik?.values?.attempts || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('attempts') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('attempts')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="attemptsTime" className={classNames({ 'p-error': isFormFieldValid('attemptsTime') }, 'Label__Text')}>
                                    Time
                                </label>
                                <InputText
                                    placeholder="Enter Attempts Time"
                                    id="attemptsTime"
                                    name="attemptsTime"
                                    value={formik?.values?.attemptsTime || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('attemptsTime') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('attemptsTime')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="breachLimit" className={classNames({ 'p-error': isFormFieldValid('breachLimit') }, 'Label__Text')}>
                                    Breach Limit
                                </label>
                                <Dropdown
                                    id="breachLimit"
                                    placeholder="Choose Breach Limit"
                                    options={[
                                        { name: 'Permanent', lovId: 'Permanent' },
                                        { name: 'Temporary', lovId: 'Temporary' }
                                    ]}
                                    optionLabel="name"
                                    name="breachLimit"
                                    optionValue="lovId"
                                    value={formik?.values?.breachLimit || ''}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        if (e.target.value === 'Temporary') {
                                            formik.setFieldValue('temporaryBlock', 'Y');
                                            formik.setFieldValue('permanentBlock', '');
                                        } else {
                                            formik.setFieldValue('temporaryBlock', '');
                                            formik.setFieldValue('permanentBlock', 'Y');
                                        }
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldValid('breachLimit') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Name"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik?.values?.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createDate" className={classNames({ 'p-error': isFormFieldValid('createDate') }, 'Label__Text')}>
                                    Created On
                                </label>
                                <InputText id="createDate" type="date" name="createDate" value={formik?.values?.createDate} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('createDate') }, 'Input__Round')} />
                                {getFormErrorMessage('createDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="lastUpdateDate" className={classNames({ 'p-error': isFormFieldValid('lastUpdateDate') }, 'Label__Text')}>
                                    Last Updated
                                </label>
                                <InputText
                                    id="lastUpdateDate"
                                    type="date"
                                    name="lastUpdateDate"
                                    value={formik?.values?.lastUpdateDate}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lastUpdateDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('lastUpdateDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="statusId" className={classNames({ 'p-error': isFormFieldValid('statusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select Status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik?.values?.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label="Search" icon={loadingIcon || ''} iconPos="left" className="Btn__Dark" />
                        <Button type="button" label="Reset" iconPos="right" className="Btn__Transparent" onClick={(e) => handleReset(e)} />
                    </div>
                </form>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            filters={filters}
                            globalFilterFields={['attempts', 'attemptsTime', 'createdate', 'dateFrom', 'dateTo', 'createdBy', 'updatedBy', 'statusName']}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={searchData}
                        >
                            <Column field="attempts" header="Attempts" />
                            <Column field="attemptsTime" header="Time" />
                            <Column field="BreachLimit" header="Breach Limit" />
                            <Column field="createdBy" header="Created By" />
                            <Column field="updatedBy" header="Updated By" />
                            <Column body={createdDateAndTimeBodyTemplate} header="Created Date & Time" />
                            <Column header="Status" body={statusBodyTemplate} />
                            <Column header="Action" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Pmd;

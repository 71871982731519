import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import RoleTable from '../Components/roleTable';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';

function AddCreateSalesRoles() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [roleName, setRoleName] = useState('');
    const [roles, setRoles] = useState([]);
    const [saleRoleData, setSaleRoleData] = useState([]);
    const [saleStructureData, setSaleStructureData] = useState([]);
    const [newPayLoad, setNewPayLoad] = useState([]);

    const validationSchema = Yup.object({
        businessTypeId: Yup.string().required('This Field is required'),
        regionId: Yup.string().required('This Field is required'),
        salesStructureId: Yup.string().when('businessTypeId', {
            is: '5',
            then: Yup.string().required('This Field is required'),
            otherwise: Yup.string()
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            businessTypeId: '',
            regionId: '',
            salesStructureId: '',
            salesRoleId: '',
            isActive: ''
        },

        onSubmit: async (data) => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data,
                        tblSalesRoleDetails: newPayLoad
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            await dispatch(handlePostRequest(newData, '/account/v1/sales/createsaleroles', true, false, 'createsalesroles'));
        }
    });

    const getSaleRoleData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getsalerolesdata');
        // if (resp?.responseCode === '010000') {
        setSaleRoleData(resp?.payLoad);
        // }
    };

    const getSaleStructureData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getSaleStructureList/LKP_SALES_STRUCTURE:*');
        // if (resp?.responseCode === '010000') {
        setSaleStructureData(resp?.payLoad);
        // }
    };

    useEffect(() => {
        getSaleRoleData();
        getSaleStructureData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleRemove = (rowData) => {
        const updatedRoles = roles.filter((role) => role !== rowData);
        setRoles(updatedRoles);
    };

    const handleAddButtonClick = () => {
        if (roleName.trim() === '') {
            formik.setFieldError('roleName', 'This field is required');
            formik.errors.roleName = 'This field is required';
        } else if (roleName.trim() !== '') {
            if (!/^[^\s]+(\s[^\s]+)*$/.test(roleName)) {
                formik.setFieldError('roleName', 'Leading and trailing space is not allowed');
                formik.errors.roleName = 'Leading and trailing space is not allowed';
            } else if (!/^[A-Za-z0-9 ]*$/.test(roleName)) {
                formik.setFieldError('roleName', 'Special characters are not allowed');
                formik.errors.roleName = 'Special characters are not allowed';
            } else if (!/^[a-zA-Z](?:[a-zA-Z0-9\s]*-?[a-zA-Z0-9\s]*)?$/.test(roleName)) {
                formik.setFieldError('roleName', 'Invalid role name format');
                formik.errors.roleName = 'Invalid role name format';
            } else if (roles.some((role) => role.roleName.toLowerCase() === roleName.toLowerCase())) {
                // toast.info('Role with the same name already exists.');
                formik.setFieldError('roleName', 'Role with the same name already exists.');
                formik.errors.roleName = 'Role with the same name already exists.';
            } else {
                const newRole = {
                    roleName: roleName,
                    creationDateTime: new Date()?.toLocaleString('en-US'),
                    salesRoleDetailId: 0
                };
                setRoles([...roles, newRole]);
                setRoleName(''); // Clear the input field
                // formik.resetForm();
            }
        }
    };

    useEffect(() => {
        if (roles?.length > 0) {
            const updatedPayload = roles.map((item) => {
                const updatedItem = { ...item };
                delete updatedItem.creationDateTime;
                return updatedItem;
            });
            setNewPayLoad(updatedPayload);
        }
    }, [roles]); // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     if (formik.values.businessTypeId !== '5') {
    //         setRoleName('');
    //         setRoles([]);
    //         formik.setFieldValue('salesStructureId', '');
    //     }
    // }, [formik.values.businessTypeId]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>ADD NEW</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="businessTypeId" className={classNames({ 'p-error': isFormFieldValid('businessTypeId') }, 'Label__Text')}>
                                    Business Type <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="businessTypeId"
                                    placeholder="Select Business Type"
                                    options={saleRoleData?.bussinessList}
                                    optionLabel="name"
                                    name="businessTypeId"
                                    optionValue="lovId"
                                    value={formik.values.businessTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('businessTypeId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('businessTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="regionId" className={classNames({ 'p-error': isFormFieldValid('regionId') }, 'Label__Text')}>
                                    Region
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    filter
                                    // showClear
                                    filterBy="name"
                                    id="regionId"
                                    placeholder="Select Region"
                                    options={saleRoleData?.regionList}
                                    optionLabel="name"
                                    name="regionId"
                                    optionValue="lovId"
                                    value={formik.values.regionId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regionId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('regionId')}
                            </div>
                        </div>

                        {/* {formik.values.businessTypeId == '5' && (
                            <> */}
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="salesStructureId" className={classNames({ 'p-error': isFormFieldValid('salesStructureId') }, 'Label__Text')}>
                                    Sales Structure
                                    {formik.values.businessTypeId == '5' && <span className="Label__Required">*</span>}
                                    {/* <span className="Label__Required">*</span> */}
                                </label>
                                <Dropdown
                                    // showClear
                                    id="salesStructureId"
                                    placeholder="Select Sales Structure"
                                    options={saleStructureData}
                                    optionLabel="name"
                                    name="salesStructureId"
                                    optionValue="lovId"
                                    value={formik.values.salesStructureId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('salesStructureId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('salesStructureId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="roleName" className={classNames({ 'p-error': isFormFieldValid('roleName') }, 'Label__Text')}>
                                    Role Name
                                    {(formik.values.businessTypeId == '5' || formik.values.salesStructureId) && <span className="Label__Required">*</span>}
                                    {/* <span className="Label__Required">*</span> */}
                                </label>
                                <InputText
                                    maxLength={50}
                                    // minLength={3}
                                    placeholder="Enter Role Name"
                                    id="roleName"
                                    name="roleName"
                                    value={roleName}
                                    onChange={(e) => {
                                        setRoleName(e.target.value);
                                        formik.errors.roleName = '';
                                    }}
                                    className={classNames({ 'p-invalid': isFormFieldValid('roleName') }, 'Input__Round')}
                                    disabled={formik.values.salesStructureId ? false : true}
                                />
                                {formik?.errors?.roleName && <small className="p-error">{formik?.errors?.roleName}</small>}
                                {/* {getFormErrorMessage('roleName')} */}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-2 pt-3 pb-3 mt-4">
                            <div className="Down__Btn">
                                <Button onClick={() => handleAddButtonClick()} type="button" label="Add" className="Btn__Dark" disabled={formik.values.salesStructureId ? false : true} />
                            </div>
                        </div>
                        {/* </>
                        )} */}
                    </div>
                    {roles.length > 0 && <RoleTable roles={roles} dataAction={handleRemove} actionType={'delete'} />}

                    <div className="Down__Btn">
                        <Button
                            type="submit"
                            label="Submit"
                            disabled={
                                (formik.values.businessTypeId === '5' && roles.length > 0) ||
                                (formik.values.businessTypeId !== '5' && formik.values.salesStructureId && roles.length > 0) ||
                                (formik.values.businessTypeId !== '5' && !formik.values.salesStructureId)
                                    ? false
                                    : true
                            }
                            className="Btn__Dark"
                        />
                        <Button type="button" onClick={() => navigate('/createsalesroles')} label="Cancel" className="Btn__Transparent" />
                    </div>
                    {/* {formik.values.businessTypeId === '5' && roles.length > 0 ? (
                        <div className="Down__Btn">
                            <Button type="submit" label="Submit" className="Btn__Dark" />
                            <Button type="button" onClick={() => navigate('/createsalesroles')} label="Cancel" className="Btn__Transparent" />
                        </div>
                    ) : formik.values.businessTypeId !== '5' && formik.values.salesStructureId && roles.length > 0 ? (
                        <div className="Down__Btn">
                            <Button type="submit" label="Submit" className="Btn__Dark" />
                            <Button type="button" onClick={() => navigate('/createsalesroles')} label="Cancel" className="Btn__Transparent" />
                        </div>
                    ) : formik.values.businessTypeId !== '5' && !formik.values.salesStructureId ? (
                        <div className="Down__Btn">
                            <Button type="submit" label="Submit" className="Btn__Dark" />
                            <Button type="button" onClick={() => navigate('/createsalesroles')} label="Cancel" className="Btn__Transparent" />
                        </div>
                    ) : null} */}
                </form>
            </div>
        </>
    );
}
export default AddCreateSalesRoles;

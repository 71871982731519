import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const Disbursment = ({ accountDisbursmentTypeCount }) => {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        const countArr =
            accountDisbursmentTypeCount &&
            accountDisbursmentTypeCount.map((item) => {
                return item?.parameterValue;
            });
        const labelArr =
            accountDisbursmentTypeCount &&
            accountDisbursmentTypeCount.map((item) => {
                return item?.parameterName;
            });

        const data = {
            labels: labelArr,
            datasets: [
                {
                    data: countArr,
                    backgroundColor: ['#dee176', '#eeca50'],
                    hoverBackgroundColor: ['#dee176', '#eeca50']
                }
            ]
        };

        setChartData(data);
    }, [accountDisbursmentTypeCount]);

    const options = {
        plugins: {
            datalabels: {
                color: '#fff',
                formatter: (value, context) => {
                    const label = context.chart.data.labels[context.dataIndex];
                    return `${value}%`;
                }
            }
        }
    };

    return (
        <div className="col-12 md:col-4">
            <div className="card Card__Round">
                <div className="card p-d-flex p-jc-center">
                    <h5>Disbursment</h5>
                    <Chart type="pie" data={chartData} options={options} plugins={[ChartDataLabels]} className="w-full" style={{ height: '35rem' }} />
                </div>
            </div>
        </div>
    );
};

export default Disbursment;

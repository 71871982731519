import React, { useEffect } from 'react';
import CheckerApis from '../Components/CheckerApis';

function CheckerEditT24AccountLink() {
    return (
        <React.Fragment>
            <CheckerApis type="checkerEdit" />
        </React.Fragment>
    );
}

export default CheckerEditT24AccountLink;

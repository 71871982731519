import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useRef } from 'react';

import { InputTextarea } from 'primereact/inputtextarea';
import SecuredFileChange from '../../../components/SecuredFileChange';
import MobileView from './mobileView';
import ColorPicker from 'react-pick-color';

function AddManageMobileNumbers() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    const [tagLabel, setTagLabel] = useState([]);
    const [mobileBundle, setMobileBundle] = useState([]);
    const [companyName, setCompanyName] = useState([]);
    // const [bundleiconFileName, setBundleIconFileName] = useState('');
    const bundleIconRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [categoryIconError, setCategoryIconError] = useState('');
    const [showColorPicker, setShowColorPicker] = useState({ textColor: false, bgColor: false });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const netUnit = [
        { lovId: '1', name: 'GB' },
        { lovId: '2', name: 'MB' }
    ];

    const getCompnanyName = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getTblCMSCompany/TBL_CMS_COMPANY:*');
        setCompanyName(resp?.payLoad);
    };

    const getMobileBundleCategory = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_MOBILE_BUNDLE_CATEGORY');
        setMobileBundle(resp?.payLoad);
    };

    useEffect(() => {
        getCompnanyName();
        getMobileBundleCategory();
    }, []); // eslint-disable-line
    const validationSchema = Yup.object().shape({
        cmsCompanyId: Yup.string().required('This field is required'),
        cmsMobileBundleCategoryId: Yup.string().required('This field is required'),
        bundleCode: Yup.string().required('This field is required'),
        bundleName: Yup.string().required('This field is required'),

        bundleDetail: Yup.string().required('This field is required'),
        nameUrdu: Yup.string().required('This field is required'),
        // nameEnglish: Yup.string().required('This field is required'),

        priority: Yup.string().required('This field is required'),
        validity: Yup.string().required('This field is required'),
        // bundleIcon: Yup.string().required('This field is required'),
        amount: Yup.string().required('This field is required'),
        cmsTagLabel: Yup.string().when('addTag', {
            is: true,
            then: Yup.string().required('This field is required')
        }),
        cmsTagTextColor: Yup.string().when('addTag', {
            is: true,
            then: Yup.string().required('This field is required')
        }),
        cmsTagBgColor: Yup.string().when('addTag', {
            is: true,
            then: Yup.string().required('This field is required')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cmsMobileBundleId: '',
            cmsMobileBundleCategoryId: '',
            cmsCompanyId: '',
            bundleCode: '',
            bundleName: '',
            // nameEnglish: '',
            nameUrdu: '',
            bundleDetail: '',
            bundleSms: '',
            onnetSms: '',
            offnetSms: '',
            internetDataUnit: '',
            internetData: '',
            totalMinutes: '',
            onnetMinutes: '',
            offnetMinutes: '',
            bundleDescription: '',
            selfOnly: '',
            amount: '',
            discountAmount: '',
            validity: '',
            priority: '',
            terminationDate: '',
            addTag: true,
            cmsTagLabel: '',
            cmsTagTextColor: '',
            cmsTagBgColor: '',
            expiryDate: '',
            isHide: 'Y',
            isActive: '',
            bundleIcon: '',       
           copyEngToUrdu:false,

        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        cmsMobileBundleId: data?.cmsMobileBundleId || '',
                        cmsMobileBundleCategoryId: data?.cmsMobileBundleCategoryId,
                        cmsCompanyId: data?.cmsCompanyId,
                        bundleCode: data?.bundleCode,
                        bundleName: data?.bundleName,
                        // nameEnglish: data?.nameEnglish,
                        nameUrdu: data?.nameUrdu,
                        bundleDetail: data?.bundleDetail,
                        bundleSms: data?.bundleSms,
                        onnetSms: data?.onnetSms,
                        offnetSms: data?.offnetSms,
                        internetDataUnit: data?.internetDataUnit,
                        internetData: data?.internetData,
                        totalMinutes: data?.totalMinutes,
                        onnetMinutes: data?.onnetMinutes,
                        offnetMinutes: data?.offnetMinutes,
                        bundleDescription: data?.bundleDescription,
                        selfOnly: data?.selfOnly ? 'Y' : 'N',
                        amount: data?.amount,
                        discountAmount: data?.discountAmount,
                        validity: data?.validity,
                        priority: data?.priority,
                        terminationDate: data?.terminationDate,
                        cmsTagLabel: data?.addTag ? data?.cmsTagLabel : '',
                        cmsTagTextColor: data?.addTag ? data?.cmsTagTextColor : '',
                        cmsTagBgColor: data?.addTag ? data?.cmsTagBgColor : '',
                        isHide: data?.isHide,
                        expiryDate: data?.expiryDate
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const formData = new FormData();
            formData.append('request', JSON.stringify(newData));
            if (selectedFile) {
                formData.append('file', selectedFile);
            }
            await dispatch(handlePostRequest(formData, '/cms/v1/cms/savemobilebundle', true, false, 'managemobilenumbers'));

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const handledMobileNumberIcon = () => {
        bundleIconRef.current.click();
    };

    const handleMobileNumberChange = (event) => {
        setCategoryIconError('');
        formik.setFieldValue('bundleIcon', '');
        setSelectedFile(null);
        const selectedFile = event.target.files[0];
        const fileTypes = ['image'];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setCategoryIconError(error);
            setSelectedFile(null);
        } else if (newFile) {
            setSelectedFile(selectedFile);
            formik.setFieldValue('bundleIcon', selectedFile.name);

            // setSecondSubCategoryIconFileName(selectedFile?.name);
        }
    };
    const getContrastColor = (hexColor) => {
        const r = parseInt(hexColor.substr(1, 2), 16);
        const g = parseInt(hexColor.substr(3, 2), 16);
        const b = parseInt(hexColor.substr(5, 2), 16);
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    };
    const handleAddTagChange = (e) => {
        formik.handleChange(e);
        if (!e.target.checked) {
            formik.setFieldValue('cmsTagLabel', '');
            formik.setFieldValue('cmsTagTextColor', '#FFFFFF');
            formik.setFieldValue('cmsTagBgColor', '#FFFFFF');
        }
    };

    useEffect(() => {
        if (formik?.values?.onnetSms || formik?.values?.offnetSms) {
            formik.setFieldValue('bundleSms', parseInt(formik?.values?.onnetSms || 0) + parseInt(formik?.values?.offnetSms || 0));
        } else {
            formik.setFieldValue('bundleSms', '');
        }
    }, [formik?.values?.onnetSms, formik?.values?.offnetSms]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (formik?.values?.onnetMinutes || formik?.values?.offnetMinutes) {
            formik.setFieldValue('totalMinutes', parseInt(formik?.values?.onnetMinutes || 0) + parseInt(formik?.values?.offnetMinutes || 0));
        } else {
            formik.setFieldValue('totalMinutes', '');
        }
    }, [formik?.values?.onnetMinutes, formik?.values?.offnetMinutes]); // eslint-disable-line react-hooks/exhaustive-deps

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="Form__Header Full__Width">
                        <h2>ADD NEW</h2>
                    </div>
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-fluid p-formgrid grid mb-5 col-12 md:col-9 ">
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsCompanyId" className={classNames({ 'p-error': isFormFieldValid('cmsCompanyId') }, 'Label__Text')}>
                                        Telco Name<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="cmsCompanyId"
                                        placeholder="Select Telco Name"
                                        options={companyName}
                                        optionLabel="name"
                                        name="cmsCompanyId"
                                        optionValue="lovId"
                                        value={formik.values.cmsCompanyId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsCompanyId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('cmsCompanyId')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsMobileBundleCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsMobileBundleCategoryId') }, 'Label__Text')}>
                                        Bundle Category<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="cmsMobileBundleCategoryId"
                                        placeholder="Select Bundle Category"
                                        options={mobileBundle}
                                        optionLabel="name"
                                        name="cmsMobileBundleCategoryId"
                                        optionValue="lovId"
                                        value={formik.values.cmsMobileBundleCategoryId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsMobileBundleCategoryId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('cmsMobileBundleCategoryId')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="bundleCode" className={classNames({ 'p-error': isFormFieldValid('bundleCode') }, 'Label__Text')}>
                                        Bundle Code<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="bundleCode"
                                        placeholder="Enter Display Bundle Code"
                                        name="bundleCode"
                                        value={formik?.values?.bundleCode || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('bundleCode') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('bundleCode')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="bundleName" className={classNames({ 'p-error': isFormFieldValid('bundleName') }, 'Label__Text')}>
                                        Bundle Name<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="bundleName"
                                        placeholder="Enter Bundle Name"
                                        name="bundleName"
                                        value={formik?.values?.bundleName}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            if (formik.values.copyEngToUrdu) {
                                                formik.setFieldValue('nameUrdu', e.target.value);
                                            }
                                        }}
                                        className={classNames({ 'p-invalid': isFormFieldValid('bundleName') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('bundleName')}
                                </div>
                            </div>

                          
                            {/* <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="nameUrdu" className={classNames({ 'p-error': isFormFieldValid('nameUrdu') }, 'Label__Text')}>
                                        Display Name (Urdu)<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="nameUrdu"
                                        placeholder="Enter Display Name (Urdu) "
                                        name="nameUrdu"
                                        value={formik?.values?.nameUrdu || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('nameUrdu') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('nameUrdu')}
                                </div>
                            </div> */}
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                <div className="p-field-checkbox flex justify-content-between">
                                   
                                   <label htmlFor="nameUrdu" className={classNames({ 'p-error': isFormFieldValid('nameUrdu') }, 'Label__Text')}>
                                   Display Name (Urdu)<span className="Label__Required">*</span>
                                     
                                   </label>
                                 <div className='flex align-items-center ml-auto'>
                                   <label htmlFor="nameUrdu" className={classNames({ 'p-error': isFormFieldValid('nameUrdu') }, 'Label__Text')}>
                                      Same AS (English)
                                     
                                   </label>
                                   </div>
                                   <Checkbox
                                       inputId="copyEngToUrdu"
                                       name="copyEngToUrdu"
                                       checked={formik.values.copyEngToUrdu}
                                       onChange={(e) => {
                                           formik.setFieldValue('copyEngToUrdu', e.checked);
                                           if (e.checked) {
                                               formik.setFieldValue('nameUrdu', formik.values.bundleName);
                                           }
                                       }}
                                   />
                                 
                               </div>
                                   
                                    <InputText
                                        maxLength={100}
                                        id="nameUrdu"
                                        placeholder="Enter Display Name (Urdu) "
                                        name="nameUrdu"
                                        value={formik?.values?.nameUrdu || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('nameUrdu') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('nameUrdu')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="bundleDetail" className={classNames({ 'p-error': isFormFieldValid('priorbundleDetaility') }, 'Label__Text')}>
                                        Bundles Details<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="bundleDetail"
                                        placeholder="Enter Bundles Details"
                                        name="bundleDetail"
                                        value={formik?.values?.bundleDetail || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('bundleDetail') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('bundleDetail')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="bundleSms" className={classNames({ 'p-error': isFormFieldValid('bundleSms') }, 'Label__Text')}>
                                        Bundles SMS
                                    </label>
                                    <InputText
                                        readOnly
                                        maxLength={100}
                                        id="bundleSms"
                                        placeholder="Enter Bundles SMS"
                                        name="bundleSms"
                                        value={formik?.values?.bundleSms || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('bundleSms') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('bundleSms')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="onnetSms" className={classNames({ 'p-error': isFormFieldValid('onnetSms') }, 'Label__Text')}>
                                        OnNet SMS
                                    </label>
                                    <InputText
                                        keyfilter={/^\d+$/}
                                        maxLength={100}
                                        id="onnetSms"
                                        placeholder="Enter OnNet SMS"
                                        name="onnetSms"
                                        value={formik?.values?.onnetSms || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('onnetSms') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('onnetSms')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="offnetSms" className={classNames({ 'p-error': isFormFieldValid('offnetSms') }, 'Label__Text')}>
                                        OffNet SMS
                                    </label>
                                    <InputText
                                        keyfilter={/^\d+$/}
                                        maxLength={100}
                                        id="offnetSms"
                                        placeholder="Enter OffNet SMS"
                                        name="offnetSms"
                                        value={formik?.values?.offnetSms || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('offnetSms') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('offnetSms')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width flex">
                                <div className="p-field col-12 md:col-5 p-0 pr-1">
                                    <label htmlFor="internetDataUnit" className={classNames({ 'p-error': isFormFieldValid('internetDataUnit') }, 'Label__Text')}>
                                        Internet Data (MB/GB)
                                    </label>
                                    <Dropdown
                                        id="internetDataUnit"
                                        placeholder="Select Internet Data"
                                        options={netUnit}
                                        optionLabel="name"
                                        name="internetDataUnit"
                                        optionValue="lovId"
                                        value={formik.values.internetDataUnit || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('internetDataUnit') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('internetDataUnit')}
                                </div>
                                <div className="p-field col-12 md:col-7 p-0">
                                    <label htmlFor="internetData" className={classNames({ 'p-error': isFormFieldValid('internetData') }, 'Label__Text')}></label>
                                    <InputText
                                        maxLength={100}
                                        id="internetData"
                                        placeholder=""
                                        name="internetData"
                                        value={formik?.values?.internetData || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('internetData') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('internetData')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="totalMinutes" className={classNames({ 'p-error': isFormFieldValid('totalMinutes') }, 'Label__Text')}>
                                        Total Minutes
                                    </label>
                                    <InputText
                                        readOnly
                                        maxLength={100}
                                        id="totalMinutes"
                                        placeholder="Enter Total Minutes"
                                        name="totalMinutes"
                                        value={formik?.values?.totalMinutes || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('totalMinutes') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('totalMinutes')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="onnetMinutes" className={classNames({ 'p-error': isFormFieldValid('onnetMinutes') }, 'Label__Text')}>
                                        OnNet Minutes
                                    </label>
                                    <InputText
                                        keyfilter={/^\d+$/}
                                        maxLength={100}
                                        id="onnetMinutes"
                                        placeholder="Enter OnNet Minutes"
                                        name="onnetMinutes"
                                        value={formik?.values?.onnetMinutes || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('onnetMinutes') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('onnetMinutes')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="offnetMinutes" className={classNames({ 'p-error': isFormFieldValid('offnetMinutes') }, 'Label__Text')}>
                                        OffNet Minutes
                                    </label>
                                    <InputText
                                        keyfilter={/^\d+$/}
                                        maxLength={100}
                                        id="offnetMinutes"
                                        placeholder="Enter OffNet Minutes"
                                        name="offnetMinutes"
                                        value={formik?.values?.offnetMinutes || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('offnetMinutes') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('offnetMinutes')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-12 w-full pt-3 pb-3 p-field-addbanner-width">
                                <div className="desc_Text_Area">
                                    <label htmlFor="bundleDescription" className={classNames({ 'p-error': isFormFieldValid('bundleDescription') }, 'Label__Text')}>
                                        Description
                                    </label>
                                    <InputTextarea
                                        placeholder="-"
                                        inputid="bundleDescription"
                                        name="bundleDescription"
                                        rows={4}
                                        cols={30}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('bundleDescription') }, 'Input__Round')}
                                        value={formik?.values?.bundleDescription}
                                    />
                                    {getFormErrorMessage('bundleDescription')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width flex">
                                <div className="p-field col-12 md:col-3 p-0 pr- flex flex-column">
                                    <label htmlFor="selfOnly" className={classNames({ 'p-error': isFormFieldValid('selfOnly') }, 'Label__Text')}>
                                        Self Only
                                    </label>
                                    <Checkbox inputId="selfOnly" className="mt-2 ml-2" name="selfOnly" value={formik?.values?.selfOnly} checked={formik?.values?.selfOnly} onChange={formik.handleChange} />
                                </div>
                                <div className="p-field col-12 md:col-9 p-0">
                                    <label htmlFor="amount" className={classNames({ 'p-error': isFormFieldValid('amount') }, 'Label__Text')}>
                                        Amount <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        keyfilter={/^\d+$/}
                                        maxLength={100}
                                        id="amount"
                                        placeholder="Enter amount"
                                        name="amount"
                                        value={formik?.values?.amount || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('amount') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('amount')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="discountAmount" className={classNames({ 'p-error': isFormFieldValid('discountAmount') }, 'Label__Text')}>
                                        Discounted Amount
                                    </label>
                                    <InputText
                                        keyfilter={/^\d+$/}
                                        maxLength={100}
                                        id="discountAmount"
                                        placeholder="Enter Discounted Amount"
                                        name="discountAmount"
                                        value={formik?.values?.discountAmount || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('discountAmount') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('discountAmount')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="validity" className={classNames({ 'p-error': isFormFieldValid('validity') }, 'Label__Text')}>
                                        Validity<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="validity"
                                        placeholder="Enter Discounted Amount"
                                        name="validity"
                                        value={formik?.values?.validity || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('validity') }, 'Input__Round')}
                                    />

                                    {getFormErrorMessage('validity')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="priority" className={classNames({ 'p-error': isFormFieldValid('priority') }, 'Label__Text')}>
                                        Priority <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="priority"
                                        placeholder="Enter Priority"
                                        name="priority"
                                        keyfilter="int"
                                        value={formik?.values?.priority || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('priority') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('priority')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <div className="mb-2">
                                        <label className={classNames({ 'p-error': isFormFieldValid('bundleIcon') }, 'Label__Text')}>Bundle Icon</label>
                                        <div className="file-input-container w-100" onClick={handledMobileNumberIcon}>
                                            <input type="file" accept="image/*" style={{ display: 'none' }} ref={bundleIconRef} onChange={handleMobileNumberChange} />
                                            <input
                                                type="text"
                                                className={classNames({ 'p-inputtext p-component p-invalid': isFormFieldValid('bundleIcon') }, 'file-input-field Input__Round')}
                                                placeholder="Choose a file..."
                                                value={formik?.values?.bundleIcon}
                                                readOnly
                                            />
                                            <span className="file-upload-icon">
                                                <i className="pi pi-paperclip" />
                                            </span>
                                        </div>
                                        {categoryIconError ? <small className="p-error">{categoryIconError}</small> : getFormErrorMessage('bundleIcon')}
                                    </div>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="terminationDate" className={classNames({ 'p-error': isFormFieldValid('terminationDate') }, 'Label__Text')}>
                                        Termination Date & Time
                                    </label>
                                    <InputText
                                        id="terminationDate"
                                        type="date"
                                        name="terminationDate"
                                        value={formik?.values?.terminationDate}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('terminationDate') }, 'Input__Round')}
                                    />

                                    {getFormErrorMessage('terminationDate')}
                                </div>
                            </div>
                            <div className="card Card__Bg__Change col-12 md:col-12">
                                <h6 className="secondary-color">
                                    <b>TAG</b>
                                    <span className="Label__Required">*</span>
                                </h6>
                                <div className="p-field col-12 md:col-6 pt-3 pb-3 flex align-items-end justify-content-start">
                                    <div className="field-checkbox flex mb-2 p-field-addbanner-width ">
                                        <div className="mr-5">
                                            <Checkbox inputId="addTag" name="addTag" checked={formik.values.addTag} onChange={handleAddTagChange} style={{ marginTop: '10px' }} disabled/>
                                            <label htmlFor="addTag" className={classNames({ 'p-error': isFormFieldValid('enablePartialPayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                                Add Tag
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-fluid p-formgrid grid mb-5">
                                    <div className="p-field col-12 md:col-3 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="cmsTagLabel" className={classNames({ 'p-error': isFormFieldValid('cmsTagLabel') }, 'Label__Text')}>
                                                Tag Label
                                            </label>

                                            <InputText
                                                maxLength={100}
                                                id="cmsTagLabel"
                                                placeholder="Enter Tag Label"
                                                name="cmsTagLabel"
                                                value={formik?.values?.cmsTagLabel || ''}
                                                disabled={!formik?.values?.addTag}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagLabel') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('cmsTagLabel')}
                                        </div>
                                    </div>

                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field relative">
                                            <label htmlFor="cmsTagTextColor" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextColor') }, 'Label__Text')}>
                                                Tag Text Color
                                            </label>
                                            <InputText
                                                value={formik?.values?.cmsTagTextColor || '#FFFFFF'}
                                                onChange={(e) => {
                                                    formik.setFieldValue('cmsTagTextColor', e.target.value);
                                                }}
                                                onClick={() => setShowColorPicker({ textColor: true, bgColor: false })}
                                                disabled={!formik?.values?.addTag}
                                                readOnly
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextColor') }, 'Input__Round')}
                                                style={{
                                                    backgroundColor: formik?.values?.cmsTagTextColor || '#FFFFFF',
                                                    color: getContrastColor(formik?.values?.cmsTagTextColor || '#FFFFFF')
                                                }}
                                            />
                                            {getFormErrorMessage('cmsTagTextColor')}
                                            {showColorPicker?.textColor && (
                                                <div className="colorPickerForInput">
                                                    <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                                    <ColorPicker color={formik?.values?.cmsTagTextColor || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagTextColor', e?.hex)} />
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                        <div className="p-field">
                                            <label htmlFor="cmsTagBgColor" className={classNames({ 'p-error': isFormFieldValid('cmsTagBgColor') }, 'Label__Text')}>
                                                Tag Background Color
                                            </label>
                                            <InputText
                                                value={formik?.values?.cmsTagBgColor || '#FFFFFF'}
                                                onChange={(e) => {
                                                    formik.setFieldValue('cmsTagBgColor', e.target.value);
                                                }}
                                                onClick={() => setShowColorPicker({ textColor: false, bgColor: true })}
                                                disabled={!formik?.values?.addTag}
                                                readOnly
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagBgColor') }, 'Input__Round')}
                                                style={{
                                                    backgroundColor: formik?.values?.cmsTagBgColor || '#FFFFFF',
                                                    color: getContrastColor(formik?.values?.cmsTagBgColor || '#FFFFFF')
                                                }}
                                            />
                                            {getFormErrorMessage('cmsTagBgColor')}
                                            {showColorPicker?.bgColor && (
                                                <div className="colorPickerForInput">
                                                    <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                                    <ColorPicker color={formik?.values?.cmsTagBgColor || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagBgColor', e?.hex)} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="expiryDate" className={classNames({ 'p-error': isFormFieldValid('expiryDate') }, 'Label__Text')}>
                                        Expiry Date
                                    </label>
                                    <InputText
                                        min={previousDate}
                                        id="expiryDate"
                                        type="date"
                                        name="expiryDate"
                                        value={formik?.values?.expiryDate}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('expiryDate') }, 'Input__Round')}
                                    />

                                    {getFormErrorMessage('expiryDate')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="isHide" className={classNames({ 'p-error': isFormFieldValid('isHide') }, 'Label__Text')}>
                                        Status
                                    </label>
                                    <div className="flex ml-2">
                                        <div className="mt-1 mr-5">
                                            <Checkbox inputId="show" name="isHide" value="Y" checked={formik?.values?.isHide === 'Y'} onChange={(e) => formik.setFieldValue('isHide', 'Y')} style={{ marginTop: '10px' }} />
                                            <label htmlFor="show" className={classNames({ 'p-error': isFormFieldValid('show') }, 'Label__Text')}>
                                                Show
                                            </label>
                                        </div>
                                        <div className="mt-1">
                                            <Checkbox inputId="hide" name="isHide" value="N" checked={formik?.values?.isHide === 'N'} onChange={(e) => formik.setFieldValue('isHide', 'N')} style={{ marginTop: '10px' }} />
                                            <label htmlFor="hide" className={classNames({ 'p-error': isFormFieldValid('hide') }, 'Label__Text')}>
                                                Hide
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-3 pt-3 pb-3 col-4 flex align-items-start justify-content-center">
                            <MobileView
                                bundleName={formik?.values?.bundleName}
                                tagLabel={formik?.values?.cmsTagLabel}
                                tagStyle={{ backgroundColor: formik?.values?.cmsTagBgColor || '#000000', color: formik.values.cmsTagTextColor || '#FFFFFF' }}
                                discountAmount={formik?.values?.discountAmount}
                                amount={formik?.values?.amount}
                                onnetMinutes={formik?.values?.onnetMinutes}
                                offnetMinutes={formik?.values?.offnetMinutes}
                                bundleSms={formik?.values?.bundleSms}
                                internetData={formik?.values?.internetData}
                                internetDataLabel={netUnit?.find((item) => item.lovId === formik?.values?.internetDataUnit)?.name}
                                validity={formik?.values?.validity}
                                bundleDescription={formik?.values?.bundleDescription}
                            />
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Submit" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/managemobilenumbers')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddManageMobileNumbers;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../services/PostTemplate';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewSwitchConfiguration() {
    const [clientData, setClientData] = useState();

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { rowData } = location.state || {};
    const clientId = rowData?.clientId;
    const tableName = 'TBL_SWITCH_CLIENT';

    const socketTypeOptions = [
        {
            name: 'Server',
            value: 'S'
        },
        {
            name: 'Client',
            value: 'C'
        }
    ];
    const statusOptions = [
        {
            name: 'Active',
            value: 'Y'
        },
        {
            name: 'Inactive',
            value: 'N'
        }
    ];
    const formik = useFormik({
        initialValues: {
            clientCode: '',
            clientName: '',
            safRetries: '',
            safRetriesTime: '',
            socketName: '',
            socketIp: '',
            socketPort: '',
            socketType: '',
            socketUrl: '',
            socketStatus: '',
            queueExpiryTime: '',
            queueSize: '',
            queueStatus: ''
        }
    });

    const mapValues = () => {
        formik.setFieldValue('clientCode', clientData?.clientCode);
        formik.setFieldValue('clientName', clientData?.clientName);
        formik.setFieldValue('safRetries', clientData?.safConfig?.safEntries);
        formik.setFieldValue('safRetriesTime', clientData?.safConfig?.safEntriesTime);
        formik.setFieldValue('socketName', clientData?.socket?.socketName);
        formik.setFieldValue('socketIp', clientData?.socket?.socketIp);
        formik.setFieldValue('socketPort', clientData?.socket?.socketPort);
        formik.setFieldValue('socketType', clientData?.socket?.socketType);
        formik.setFieldValue('socketUrl', clientData?.socket?.socketUrl);
        formik.setFieldValue('socketStatus', clientData?.socket?.isActive);
        formik.setFieldValue('queueExpiryTime', clientData?.queue?.queueExpityTime);
        formik.setFieldValue('queueSize', clientData?.queue?.queueLimitSize);
        formik.setFieldValue('queueStatus', clientData?.queue?.isActive);
    };

    useEffect(() => {
        if (clientData) {
            mapValues();
        }
    }, [clientData]); // eslint-disable-line

    const getRecordById = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    client: { id: rowData?.clientId }
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newData, '/communication-service/client/'));
        setClientData(res?.payLoad);
    };

    useEffect(() => {
        if (rowData) {
            getRecordById();
        }
    }, [rowData]); // eslint-disable-line

    return (
        <>
            <div className="card Card__Round riskDashboardPage">
                <form className="p-fluid p-mt-2">
                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">ADD CLIENT</div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="clientCode" className="Label__Text">
                                    Client Code
                                </label>
                                <InputText id="clientCode" name="clientCode" placeholder="Enter Client Code" value={formik?.values?.clientCode || ''} onChange={formik.handleChange} className="Input__Round" aria-required disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="clientName" className="Label__Text">
                                    Client Name<span className="Label__Required">*</span>
                                </label>
                                <InputText id="clientName" name="clientName" placeholder="Enter Client Name" value={formik?.values?.clientName || ''} onChange={formik.handleChange} className="Input__Round" aria-required disabled />
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">SAF RETRIES</div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="safRetries" className="Label__Text">
                                    SAF Retries
                                </label>
                                <InputText id="safRetries" name="safRetries" placeholder="Enter SAF Retries" value={formik?.values?.safRetries || ''} onChange={formik.handleChange} className="Input__Round" aria-required disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="safRetriesTime" className="Label__Text">
                                    SAF Retries Time (in milli's)
                                </label>
                                <InputText id="safRetriesTime" name="safRetriesTime" placeholder="Enter SAF Retries Time" value={formik?.values?.safRetriesTime || ''} onChange={formik.handleChange} className="Input__Round" aria-required disabled />
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">SOCKET CONFIGURATION</div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketName" className="Label__Text">
                                    Socket Name <span className="Label__Required">*</span>
                                </label>
                                <InputText id="socketName" name="socketName" placeholder="Enter Socket Name" value={formik?.values?.socketName || ''} onChange={formik.handleChange} className="Input__Round" aria-required disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketIp" className="Label__Text">
                                    Socket IP <span className="Label__Required">*</span>
                                </label>
                                <InputText id="socketIp" name="socketIp" placeholder="Enter Socket IP" value={formik?.values?.socketIp || ''} onChange={formik.handleChange} className="Input__Round" aria-required disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketPort" className="Label__Text">
                                    Port
                                </label>
                                <InputText id="socketPort" placeholder="Enter Port Number" name="socketPort" value={formik.values.socketPort} onChange={formik.handleChange} className="Input__Round" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketType" className="Label__Text">
                                    Socket Type
                                </label>
                                <Dropdown
                                    id="socketType"
                                    placeholder="Select Socket Type"
                                    options={socketTypeOptions}
                                    optionLabel="name"
                                    optionValue="value"
                                    name="socketType"
                                    value={formik.values.socketType}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-8">
                            <div className="p-field">
                                <label htmlFor="socketUrl" className="Label__Text">
                                    Socket URL
                                </label>
                                <InputText id="socketUrl" name="socketUrl" placeholder="Enter Socket URL" value={formik?.values?.socketUrl || ''} onChange={formik.handleChange} className="Input__Round" aria-required disabled />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketStatus" className="Label__Text">
                                    Status
                                </label>
                                <Dropdown
                                    id="socketStatus"
                                    placeholder="Select Status"
                                    options={statusOptions}
                                    optionLabel="name"
                                    optionValue="value"
                                    name="socketStatus"
                                    value={formik.values.socketStatus}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">QUEUE CONFIGURATION</div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="queueExpiryTime" className="Label__Text">
                                    Expiry Time
                                </label>
                                <InputText
                                    id="queueExpiryTime"
                                    type="time"
                                    name="queueExpiryTime"
                                    placeholder="Enter Expiry Time"
                                    value={formik?.values?.queueExpiryTime || ''}
                                    onChange={formik.handleChange}
                                    className="Input__Round"
                                    aria-required
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="queueSize" className="Label__Text">
                                    Queue Size
                                </label>
                                <InputText id="queueSize" name="queueSize" placeholder="Enter Queue Size" value={formik?.values?.queueSize || ''} onChange={formik.handleChange} className="Input__Round" aria-required disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="queueStatus" className="Label__Text">
                                    Status
                                </label>
                                <Dropdown
                                    id="queueStatus"
                                    placeholder="Select Status"
                                    options={statusOptions}
                                    optionLabel="name"
                                    optionValue="value"
                                    name="queueStatus"
                                    value={formik.values.queueStatus}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <div className="Usergroup__SearchBtn p-field col-12  flex justify-content-center">
                        <Button
                            label="Okay"
                            type="Button"
                            onClick={() => {
                                navigate('/switchconfiguration');
                            }}
                            className="Btn__Dark__Search"
                        />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={clientId} tableName={tableName} />
        </>
    );
}
export default ViewSwitchConfiguration;

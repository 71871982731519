import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode } from 'primereact/api';
// import ActiveInActiveDialog from '../../../../components/ActiveInActiveDialog/ActiveInActiveDialog';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import moment from 'moment';

function UltraAccountVerification() {
    // const [showModal, setShowModal] = useState(false);
    // const [switchData, setSwitchData] = useState([]);
    // const [timeArray, setTimeArray] = useState([]);
    const [accountTypeValues, setAccountTypeValues] = useState([]);
    const [accountData, setAccountData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [discrepantStatusList, setDiscrepantStatusList] = useState([]);
    const [filteredStatus, setFilteredStatus] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        accountNo: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        accountLevelId: { value: '', matchMode: FilterMatchMode.EQUALS },
        createdOn: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        updatedOn: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        dateFrom: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        dateTo: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        createdBy: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        updatedBy: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        status: { value: '', matchMode: FilterMatchMode.EQUALS }
    });

    const dataTableRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            accountNo: '',
            accountLevelId: '',
            // turnAroundTime: '',
            dateFrom: '',
            dateTo: '',
            createdBy: '',
            updatedBy: '',
            status: '2'
        },

        validate: (data) => {
            let errors = {};
            if (data.dateTo && !data.dateFrom) {
                errors.dateFrom = 'Date From is required when Date To is provided';
            }

            if (data.dateFrom && data.dateTo && data.dateFrom > data.dateTo) {
                errors.dateTo = 'Date To must be greater than or equal to Date From';
            }
            return errors;
        },

        onSubmit: async (data) => {
            setloadingIcon('pi pi-spin pi-spinner');
            setAccountData([]);
            // data['status'] = statusLovData.find((item) => item?.lovId == data?.status)?.name;

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            dataTableRef.current.reset();
            const res = await dispatch(handlePostRequest(newData, '/account/v1/customeraccounts/getallpendingcustomers', true, true));
            const updatedArray = res?.payLoad?.map((item) => {
                discrepantStatusList?.map((status) => {
                    if (item?.makerStatus?.toString() === status?.lovId) {
                        return (item['discrepantStatus'] = status?.name);
                    }
                });
                statusLovData?.map((status) => {
                    if (item?.checkerStatus?.toString() === status?.lovId) {
                        return (item['checkerStatus'] = status?.name);
                    }
                });
                return { ...item, lastUpdatedOn: item?.lastUpdatedOn || 'N/A', lastUpdateUser: item?.lastUpdateUser || 'N/A' };
            });
            setAccountData(updatedArray);
            setloadingIcon('pi pi-search');
        }
    });

    const getaccountTypeValues = async () => {
        const res = await handleGetRequest('/configuration/v1/accountype/getAllAccounttypes');
        const filteredAccTypes = res?.payLoad.filter(
            (item) =>
                item.accountLevelName &&
                (item.accountLevelName.toUpperCase() === 'MINOR 0' ||
                    item.accountLevelName.toUpperCase() === 'MINOR 1' ||
                    item.accountLevelName.toUpperCase() === 'ULTRA BASIC' ||
                    item.accountLevelName.toUpperCase() === 'ULTRA FREELANCE' ||
                    item.accountLevelName.toUpperCase() === 'ULTRA SIGNATURE')
        );
        setAccountTypeValues(filteredAccTypes);
    };

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = res?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(res?.payLoad);
        setFilteredStatus(updatedArrayWithoutPending);
    };

    const getDiscrepantStatusList = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getDocStatus/LKP_DOC_STATUS:*');
        setDiscrepantStatusList(res?.payLoad);
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">Ultra Account Verification</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const header = () => renderHeader();

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    // const handleSwitchChange = async () => {
    //     const updatedData = accountData?.map((item) => {
    //         if (item?.t24AccountLinkId === switchData?.t24AccountLinkId) {
    //             const isActive = item?.isActive === 'Y' ? 'N' : 'Y';
    //             return { ...item, isActive };
    //         }
    //         return item;
    //     });
    //     setAccountData(updatedData);
    //     let isactive = '';
    //     if (switchData?.isActive === 'Y') {
    //         isactive = false;
    //     } else if (switchData?.isActive === 'N') {
    //         isactive = true;
    //     }
    //     const previousValue = switchData?.isActive;

    //     const data = {
    //         t24AccountLinkId: switchData?.t24AccountLinkId,
    //         isActive: isactive,
    //         isActive: switchData?.isActive,
    //         cnicHash: switchData?.cnicHash
    //     };

    //     const newData = {
    //         data: {
    //             security: {
    //                 userName: '',
    //                 password: '',
    //                 securityToken: ''
    //             },
    //             account: {
    //                 msidn: '',
    //                 iban: '',
    //                 bban: '',
    //                 pan: '',
    //                 currency: ''
    //             },
    //             channel: '',
    //             terminal: '',
    //             reterivalReferenceNumber: '',
    //             payLoad: data,
    //             additionalInformation: [
    //                 {
    //                     infoKey: '',
    //                     infoValue: ''
    //                 }
    //             ],
    //             checkSum: ''
    //         }
    //     };
    //     const res = await dispatch(handlePostRequest(newData, '/account/v1/customeraccounts/inactivecoreaccount', true, false));
    //     if (res?.responseCode == '010000') {
    //         const revertedData = accountData?.map((item) => {
    //             if (item.t24AccountLinkId == switchData?.t24AccountLinkId) {
    //                 const isActive = isactive;
    //                 return { ...item, isActive };
    //             }
    //             return item;
    //         });
    //         setAccountData(revertedData);
    //     } else {
    //         const revertedData = accountData?.map((item) => {
    //             if (item.t24AccountLinkId == switchData?.t24AccountLinkId) {
    //                 const isActive = previousValue;
    //                 return { ...item, isActive };
    //             }
    //             return item;
    //         });
    //         setAccountData(revertedData);
    //     }
    // };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    {/* <InputSwitch
                        checked={rowData.isActive === 'Y'}
                        disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                        onChange={() => {
                            setSwitchData(rowData);
                            setShowModal(true);
                        }}
                    /> */}
                    <Button
                        tooltip="Edit Detail"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: 'top' }}
                        disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) || (rowData?.lkpStatus?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                        onClick={() => navigate(`/editwalletaccountapproval`, { state: { rowData, filteredStatus, discrepantStatusList } })}
                        className="p-button-rounded p-button-primary"
                    />
                    <Button
                        tooltip="View Detail"
                        tooltipOptions={{ position: 'top' }}
                        // disabled={['Pending', 'Rejected'].includes(rowData?.statusName) ? true : false}
                        icon="pi pi-eye"
                        onClick={() => navigate(`/viewwalletaccountapproval`, { state: { rowData, filteredStatus, discrepantStatusList } })}
                        className="p-button-rounded"
                        style={{ backgroundColor: '#F2AC57', border: 'none' }}
                    />
                </div>
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.checkerStatus === 'Approved' ? '#14A38B' : rowData?.checkerStatus === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.checkerStatus}</b>
            </p>
        );
    };
    const makerStatusTemplate = (rowData) => {
        const statusColor = rowData?.discrepantStatus === 'Approved' ? '#14A38B' : rowData?.discrepantStatus === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.discrepantStatus}</b>
            </p>
        );
    };

    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    // const handleShowModal = () => {
    //     setShowModal(false);
    // };

    // const props = {
    //     showModal,
    //     handleShowModal,
    //     handleSwitchChange
    // };

    // const handleGenerateTimeArray = () => {
    //     const newArray = [];
    //     for (let i = 1; i <= 48; i++) {
    //         newArray.push({ time: `${i}hr`, id: i });
    //     }
    //     setTimeArray(newArray);
    // };

    useEffect(() => {
        getaccountTypeValues();
        getStatusListData();
        getDiscrepantStatusList();
        getUserLov();
        // handleGenerateTimeArray();
    }, []);

    return (
        <>
            {/* <ActiveInActiveDialog {...props} /> */}
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountNo" className={classNames({ 'p-error': isFormFieldValid('accountNo') }, 'Label__Text')}>
                                    Account No
                                </label>
                                <InputText
                                    placeholder="Enter Account No"
                                    id="accountNo"
                                    name="accountNo"
                                    value={formik?.values?.accountNo || ''}
                                    maxLength={50}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountNo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('accountNo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountLevelId" className={classNames({ 'p-error': isFormFieldValid('accountLevelId') }, 'Label__Text')}>
                                    Account Level
                                </label>
                                <Dropdown
                                    id="accountLevelId"
                                    placeholder="Select Account Type"
                                    options={accountTypeValues}
                                    optionLabel="accountLevelName"
                                    name="accountLevelId"
                                    optionValue="accountLevelId"
                                    value={formik.values.accountLevelId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountLevelId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('accountLevelId')}
                            </div>
                        </div>
                        {/* <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdOn" className={classNames({ 'p-error': isFormFieldValid('createdOn') }, 'Label__Text')}>
                                    Applied On
                                </label>
                                <InputText
                                    id="createdOn"
                                    type="date"
                                    max={previousDate}
                                    name="createdOn"
                                    value={formik?.values?.createdOn?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdOn') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('createdOn')}
                            </div>
                        </div> */}
                        {/* <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedOn" className={classNames({ 'p-error': isFormFieldValid('updatedOn') }, 'Label__Text')}>
                                    Updated On
                                </label>
                                <InputText
                                    id="updatedOn"
                                    type="date"
                                    max={previousDate}
                                    name="updatedOn"
                                    value={formik?.values?.updatedOn?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedOn') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('updatedOn')}
                            </div>
                        </div> */}
                        {/* <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="turnAroundTime" className={classNames({ 'p-error': isFormFieldValid('turnAroundTime') }, 'Label__Text')}>
                                    Turn Around Time
                                </label>
                                <Dropdown
                                    id="turnAroundTime"
                                    placeholder="Select Time"
                                    options={timeArray}
                                    optionLabel="time"
                                    name="turnAroundTime"
                                    optionValue="id"
                                    value={formik.values.turnAroundTime || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('turnAroundTime') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('turnAroundTime')}
                            </div>
                        </div> */}
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText
                                    id="dateFrom"
                                    type="date"
                                    max={previousDate}
                                    name="dateFrom"
                                    value={formik?.values?.dateFrom?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText
                                    id="dateTo"
                                    type="date"
                                    max={previousDate}
                                    name="dateTo"
                                    value={formik?.values?.dateTo?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Name"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Name"
                                    options={userLov}
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('status') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="status"
                                    placeholder="Select Status"
                                    options={discrepantStatusList}
                                    optionLabel="name"
                                    name="status"
                                    optionValue="lovId"
                                    value={formik.values.status || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('status') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('status')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label="Search" icon={loadingIcon || ''} iconPos="left" className="Btn__Dark" />
                        <Button type="button" label="Reset" iconPos="right" className="Btn__Transparent" onClick={(e) => handleReset(e)} />
                    </div>
                </form>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            filters={filters}
                            globalFilterFields={['ultraCustomerId', 'accountType', 'appliedOn', 'lastUpdatedOn', 'lastUpdateUser', 'createdate', 'statusName']}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={accountData}
                        >
                            <Column field="ultraCustomerId" header="Tracker ID" />
                            <Column field="accountLevelName" header="Account Type" />
                            <Column field={(item) => item?.appliedOn && moment(item?.appliedOn).format('DD/MM/YYYY hh:mm:ss a')} header="Applied On" />
                            <Column field={(item) => (item?.lastUpdatedOn !== 'N/A' ? moment(item?.lastUpdatedOn)?.format('DD/MM/YYYY hh:mm:ss a') : 'N/A')} header="Updated On" />
                            <Column field="lastUpdateUser" header="Updated By" />
                            <Column body={makerStatusTemplate} header="Status" />
                            <Column field="turnAroundTime" header="Turn Around Time" />
                            <Column header="Checker Status" body={statusBodyTemplate} />
                            <Column header="Action" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
export default UltraAccountVerification;

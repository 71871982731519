// import React, { useState, useRef, useEffect } from 'react';
// import { Button } from 'primereact/button';
// import { useFormik } from 'formik';
// import classNames from 'classnames';
// import * as Yup from 'yup';
// import { InputText } from 'primereact/inputtext';
// import { Checkbox } from 'primereact/checkbox';
// import { useDispatch } from 'react-redux';
// import { Dropdown } from 'primereact/dropdown';
// import { useNavigate } from 'react-router-dom';
// import { DataTable } from 'primereact/datatable';
// import { Column } from 'primereact/column';
// import { useLocation } from 'react-router-dom';

// function ViewRunningFinanceChecker({ activeIndex, handleStepClick, lendingData }) {
//     const [loading, setloading] = useState(false);
//     const [loadingIcon, setloadingIcon] = useState('pi pi-search');
//     const [frequencyPeriodLov, setFrequencyPeriodLov] = useState([]);
//     const [fieldTableData, setFieldTableData] = useState([]);

//     const [frequencyPeriodData, setFrequencyPeriodData] = useState([]);
//     const [repaymentScheduleTableData, setRepaymentScheduleTableData] = useState([]);
//     const [repaymentScheduleData, setRepaymentScheduleData] = useState({
//         frequencyId: '',
//         dueDate: '',
//     });

//     const [markupCalculationTableData, setMarkupCalculationTableData] = useState([]);
//     const [markupCalculationData, setMarkupCalculationData] = useState({
//         noOfCycle: '',
//         type: '',
//         price: '',
//     });

//     const [buttons, setButtons] = useState([
//         { label: 'Principal Amount', sequence: 1 },
//         { label: 'Markup', sequence: 2 },
//         { label: 'Fees', sequence: 3 },
//     ]);

//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     // Getting params from URL using hash router
//     let { search } = useLocation();
//     const query = new URLSearchParams(search);

//     const requestType = query.get('requestType');

//     useEffect(() => {
//         if (requestType === 'I') {
//             if(lendingData) {
//                 formik.setFieldValue('markupFrequencyId', lendingData?.tblLmsRepaymentMethods[0]?.lkpFrequencyPeriodMarkup?.frequencyPeriodId?.toString());
//                 formik.setFieldValue('markupValue', lendingData?.tblLmsRepaymentMethods[0]?.markupValue);

//                 formik.setFieldValue('gracePeriod', lendingData?.tblLmsRepaymentMethods[0]?.gracePeriod === 'Y' ? true : false);

//                 formik.setFieldValue('noOfDaysGracePeriod', lendingData?.tblLmsRepaymentMethods[0]?.noOfGraceDays);

//                 formik.setFieldValue('latePayment', lendingData?.tblLmsRepaymentMethods[0]?.lkpFrequencyPeriodLatePayment !=null ? true : false);
//                 formik.setFieldValue('pricing',lendingData?.tblLmsRepaymentMethods[0]?.latePayment);

//                 formik.setFieldValue('latePaymentFrequencyId', lendingData?.tblLmsRepaymentMethods[0]?.lkpFrequencyPeriodLatePayment?.frequencyPeriodId.toString());

//                 formik.setFieldValue('charges', lendingData?.tblLmsRepaymentMethods[0]?.latePaymentCharges);

//                 if(lendingData?.tblLmsRepaymentMethods[0]?.tblLmsRepaymentCycles) {
//                     const modifiedArray = lendingData?.tblLmsRepaymentMethods[0]?.tblLmsRepaymentCycles?.map(item => ({
//                         noOfCycle: item.noOfCycles,
//                         type: item.priceType,
//                         price: item.priceValue,

//                     }));

//                     setMarkupCalculationTableData(modifiedArray);
//                 }

//                 if(lendingData?.tblLmsRepaymentMethods[0]?.tblLmsRepaymentSequences) {
//                     const modifiedArray = lendingData?.tblLmsRepaymentMethods[0]?.tblLmsRepaymentSequences?.map(item => ({
//                         label: item.sequenceName,
//                         sequence: item.sequence,

//                     }));

//                     setButtons(modifiedArray);
//                 }

//                 if(lendingData?.tblLmsRepaymentMethods[0]?.tblLmsRepaymentSchedules) {
//                     const modifiedArray = lendingData?.tblLmsRepaymentMethods[0]?.tblLmsRepaymentSchedules?.map(item => ({
//                         dueDate: item.dueDate,
//                         frequencyId: item.lkpFrequencyPeriod.frequencyPeriodId,
//                     }));

//                     setRepaymentScheduleTableData(modifiedArray);
//                 }
//             }
//         } else if (requestType === 'U') {
//             if(lendingData[2]) {
//                 formik.setFieldValue('markupFrequencyId', lendingData[2]?.markupFrequencyId?.toString());
//                 formik.setFieldValue('markupValue', lendingData[2]?.markupValue);

//                 formik.setFieldValue('gracePeriod', lendingData[2]?.gracePeriod === true ? true : false);

//                 formik.setFieldValue('noOfDaysGracePeriod', lendingData[2]?.noOfDaysGracePeriod);

//                 formik.setFieldValue('latePayment', lendingData[2]?.latePayment === true ? true : false);

//                 formik.setFieldValue('latePaymentFrequencyId', lendingData[2]?.latePaymentFrequencyId.toString());

//                 formik.setFieldValue('pricing', lendingData[2]?.pricing);

//                 formik.setFieldValue('charges', lendingData[2]?.charges);

//                 setMarkupCalculationTableData(lendingData[2]?.lmsProductRepaymentCycles);
//                 // setButtons([lendingData[2]?.repaymentSeq]);
//                 if(lendingData[2]?.repaymentSeq) {
//                     const transformedArray = Object.entries(lendingData[2]?.repaymentSeq).map(([sequenceName, sequence]) => ({
//                         label: sequenceName,
//                         sequence: sequence
//                     }));
//                     setButtons(transformedArray);
//                 }
//                 setRepaymentScheduleTableData(lendingData[2]?.lmsProductScheduleRequests);
//             }
//         }
//     }, [lendingData]);

//     const validationSchema = Yup.object().shape({
//         // campaignName: Yup.mixed().required('This field is required.'),
//     });

//     const formik = useFormik({
//         validationSchema: validationSchema,
//         initialValues: {
//             // campaignName: '',
//             // startDateTime: '',
//             // endDateTime: '',
//             // campaignBudget: '',
//             // isSubLimits: false,
//             // frequencyPeriodId: '',
//             // amount: ''
//         },

//         // validate: (data) => {
//         //     let errors = {};
//         //     return errors;
//         // },

//         onSubmit: async (data) => {
//             setloading(true);
//             setloadingIcon('pi pi-spin pi-spinner');

//             // data['startDateTime'] = formatDate(data?.startDateTime);
//             // data['endDateTime'] = formatDate(data?.endDateTime);
//             // data['stepNo'] = "0";

//             // if (data.isSubLimits === false) {
//             //     delete data['amount'];
//             //     delete data['frequencyPeriodId'];
//             // }

//             const newData = {
//                 data: {
//                     security: {
//                         userName: '',
//                         password: '',
//                         securityToken: ''
//                     },
//                     account: {
//                         msidn: '',
//                         iban: '',
//                         bban: '',
//                         pan: '',
//                         currency: ''
//                     },
//                     channel: '',
//                     terminal: '',
//                     reterivalReferenceNumber: '',
//                     payLoad: data,
//                     additionalInformation: [
//                         {
//                             infoKey: '',
//                             infoValue: ''
//                         }
//                     ],
//                     checkSum: ''
//                 }
//             };

//             // if (parseInt(campaignData?.currentStep) >= 0) {
//             //     data['campaignId'] = campaignData?.campaignId;
//             //     const formData = new FormData();
//             //     formData.append("request", JSON.stringify(newData));

//             //     const res = await dispatch(handlePostRequest(formData, '/customerloyalitypromotions/v1/customerloyalitypromotion/editcampaign', true, false));
//             //     if (res?.responseCode === '150000') {
//             //         formik.resetForm();
//             //         // getCompaignId(res?.payLoad?.campaignId)
//             //         handleStepClick(activeIndex + 1);
//             //     }
//             // } else {
//             //     const formData = new FormData();
//             //     formData.append("request", JSON.stringify(newData));

//             //     const res = await dispatch(handlePostRequest(formData, '/customerloyalitypromotions/v1/customerloyalitypromotion/savecampaign', true, false));
//             //     if (res?.responseCode === '150000') {
//             //         formik.resetForm();
//             //         // getCompaignId(res?.payLoad?.campaignId)
//             //         handleStepClick(activeIndex + 2);
//             //     }
//             // }
//             handleStepClick(activeIndex + 1);
//             setloading(false);
//             setloadingIcon('pi pi-search');
//         }
//     });

//     const handledDeleteFieldsData = (e, rowData) => {
//         e.preventDefault();
//         const newFilterData = fieldTableData.filter((value) => value.fieldName !== rowData.fieldName);
//         setFieldTableData(newFilterData);
//     };
//     const actionFieldsBodyTemplate = (rowData) => {
//         return (
//             <>
//                 <div className="actions">
//                     <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteFieldsData(e, rowData)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
//                 </div>
//             </>
//         );
//     };
//     const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

//     const getFormErrorMessage = (name) => {
//         return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
//     };

//     const formatDate = (inputDate) => {
//         const date = new Date(inputDate);

//         const day = date.getDate().toString().padStart(2, '0');
//         const month = (date.getMonth() + 1).toString().padStart(2, '0');
//         const year = date.getFullYear();
//         const hours = date.getHours().toString().padStart(2, '0');
//         const minutes = date.getMinutes().toString().padStart(2, '0');
//         const ampm = hours >= 12 ? 'PM' : 'AM';

//         // Convert hours from 24-hour to 12-hour format
//         const formattedHours = hours % 12 || 12;

//         const formattedDate = `${day}-${month}-${year}, ${formattedHours}:${minutes} ${ampm}`;
//         return formattedDate;
//     };
//     // const frequencyBodyTemplate = (rowData) => {
//     //     const singleFrequency = frequencyPeriodData?.filter((item) => item?.lovId === rowData?.frequencyId);
//     //     return <p>{singleFrequency[0]?.name}</p>;
//     // };

//     return (
//         <>
//             <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
//                 <h1 style={{ color: '#fff', marginLeft: '10px' }}>REPAYMENT METHOD/VIEW DETAILS</h1>
//             </div>
//             <div className="card Card__Round">
//                 <div className="Form__Header">
//                     <h1>MARKUP CALCULATION </h1>
//                 </div>
//                 <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
//                     <div className="p-fluid p-formgrid grid mb-5">
//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <label htmlFor="markupFrequency" className={classNames({ 'p-error': isFormFieldValid('markupFrequency') }, 'Label__Text')}>
//                                     Markup Frequency<span className="Label__Required"></span>
//                                 </label>
//                                 <Dropdown
//                                     id="markupFrequency"
//                                     disabled={true}
//                                     placeholder="Select Markup Frequency"
//                                     options={[]}
//                                     optionLabel="label"
//                                     name="markupFrequency"
//                                     filter
//                                     optionValue="value"
//                                     value={formik?.values?.markupFrequency || ''}
//                                     onChange={formik.handleChange}
//                                     className={classNames({ 'p-invalid': isFormFieldValid('markupFrequency') }, 'Dropdown__Round')}
//                                 />

//                                 {getFormErrorMessage('markupFrequency')}
//                             </div>
//                         </div>
//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <label htmlFor="markupValue" className={classNames({ 'p-error': isFormFieldValid('markupValue') }, 'Label__Text')}>
//                                     Markup Value <span className="Label__Required"></span>
//                                 </label>
//                                 <InputText
//                                     id="markupValue"
//                                     disabled={true}
//                                     placeholder="Enter Markup Value"
//                                     name="markupValue"
//                                     value={formik?.values?.markupValue || ''}
//                                     onChange={formik.handleChange}
//                                     className={classNames({ 'p-invalid': isFormFieldValid('markupValue') }, 'Input__Round')}
//                                 />
//                                 {getFormErrorMessage('markupValue')}
//                             </div>
//                         </div>

//                         <div style={{ backgroundColor: '#edf0f5', padding: '2rem' }} className=" col-12 card">
//                             <div className="grid">
//                                 <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                                     <div className="p-field">
//                                         <label htmlFor="NoofCycle" className={classNames({ 'p-error': isFormFieldValid('NoofCycle') }, 'Label__Text')}>
//                                             No. of Cycle <span className="Label__Required"></span>
//                                         </label>
//                                         <InputText
//                                             id="NoofCycle"
//                                             disabled={true}
//                                             placeholder="Enter No. of Cycle"
//                                             name="NoofCycle"
//                                             value={formik?.values?.NoofCycle || ''}
//                                             onChange={formik.handleChange}
//                                             className={classNames({ 'p-invalid': isFormFieldValid('NoofCycle') }, 'Input__Round')}
//                                         />
//                                         {getFormErrorMessage('NoofCycle')}
//                                     </div>
//                                 </div>
//                                 <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                                     <div className="p-field">
//                                         <label htmlFor="price" className={classNames({ 'p-error': isFormFieldValid('price') }, 'Label__Text')}>
//                                             Price <span className="Label__Required"></span>
//                                         </label>
//                                         <InputText
//                                             id="price"
//                                             disabled={true}
//                                             placeholder="Enter Price"
//                                             name="price"
//                                             value={formik?.values?.price || ''}
//                                             onChange={formik.handleChange}
//                                             className={classNames({ 'p-invalid': isFormFieldValid('price') }, 'Input__Round')}
//                                         />
//                                         {getFormErrorMessage('price')}
//                                     </div>
//                                 </div>
//                                 <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                                     <div className="p-field">
//                                         <div className="Down__Btn" style={{ justifyContent: 'flex-start' }}>
//                                             ``
//                                             <Button disabled label="Add" className="Btn__Dark" style={{ marginTop: '15px' }} />
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="grid">
//                                 <div className="col-12">
//                                     <div className="card">
//                                         <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll">
//                                             <Column field="NoofCycle" header="No. of Cycle" />
//                                             <Column field="price" header="Price" />
//                                             <Column header="Actions" body={actionFieldsBodyTemplate} className="Table__ActionHeading__CentredAlign" />
//                                         </DataTable>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="p-field">
//                             <div className="field-checkbox">
//                                 <Checkbox inputId="gracePeriod" disabled={true} name="gracePeriod" value={formik?.values?.gracePeriod} checked={formik?.values?.gracePeriod} onChange={formik.handleChange} style={{ marginTop: '10px' }} />
//                                 <label htmlFor="gracePeriod" className={classNames({ 'p-error': isFormFieldValid('gracePeriod') }, 'Label__Text')} style={{ marginTop: '10px' }}>
//                                     Grace Period
//                                 </label>
//                             </div>
//                             {getFormErrorMessage('gracePeriod')}
//                         </div>

//                         <div style={{ backgroundColor: '#edf0f5', padding: '2rem' }} className=" col-12 card">
//                             <div className="Form__Header">
//                                 <h1>GRACE PERIOD </h1>
//                             </div>
//                             <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                                 <div className="p-field">
//                                     <label htmlFor="numberofDays" className={classNames({ 'p-error': isFormFieldValid('numberofDays') }, 'Label__Text')}>
//                                         Number of Days <span className="Label__Required"></span>
//                                     </label>
//                                     <InputText
//                                         id="numberofDays"
//                                         disabled={true}
//                                         placeholder="Enter Number of Days"
//                                         name="numberofDays"
//                                         value={formik?.values?.numberofDays || ''}
//                                         onChange={formik.handleChange}
//                                         className={classNames({ 'p-invalid': isFormFieldValid('numberofDays') }, 'Input__Round')}
//                                     />
//                                     {getFormErrorMessage('numberofDays')}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="Form__Header">
//                         <h1>LATE PAYMENT </h1>
//                     </div>

//                     <div className="p-field">
//                         <div className="field-checkbox">
//                             <Checkbox inputId="latePayment" disabled={true} name="latePayment" value={formik?.values?.latePayment} checked={formik?.values?.latePayment} onChange={formik.handleChange} style={{ marginTop: '10px' }} />
//                             <label htmlFor="latePayment" className={classNames({ 'p-error': isFormFieldValid('latePayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
//                                 Late Payment
//                             </label>
//                         </div>
//                         {getFormErrorMessage('latePayment')}
//                     </div>
//                     <div className="grid">
//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <label htmlFor="latePaymentFrequency" className={classNames({ 'p-error': isFormFieldValid('latePaymentFrequency') }, 'Label__Text')}>
//                                     Late Payment Frequency<span className="Label__Required"></span>
//                                 </label>
//                                 <Dropdown
//                                     id="latePaymentFrequency"
//                                     placeholder="Select Frequency of Payment"
//                                     options={[]}
//                                     optionLabel="label"
//                                     name="latePaymentFrequency"
//                                     filter
//                                     disabled={true}
//                                     optionValue="value"
//                                     value={formik?.values?.latePaymentFrequency || ''}
//                                     onChange={formik.handleChange}
//                                     className={classNames({ 'p-invalid': isFormFieldValid('latePaymentFrequency') }, 'Dropdown__Round')}
//                                 />

//                                 {getFormErrorMessage('latePaymentFrequency')}
//                             </div>
//                         </div>
//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <label htmlFor="latePaymentFrequency" className={classNames({ 'p-error': isFormFieldValid('latePaymentFrequency') }, 'Label__Text')}>
//                                     Late Payment<span className="Label__Required"></span>
//                                 </label>
//                                 <Dropdown
//                                     id="latePaymentFrequency"
//                                     placeholder="Select Frequency of Payment"
//                                     options={[]}
//                                     optionLabel="label"
//                                     name="latePaymentFrequency"
//                                     filter
//                                     disabled={true}
//                                     optionValue="value"
//                                     value={formik?.values?.latePaymentFrequency || ''}
//                                     onChange={formik.handleChange}
//                                     className={classNames({ 'p-invalid': isFormFieldValid('latePaymentFrequency') }, 'Dropdown__Round')}
//                                 />

//                                 {getFormErrorMessage('latePaymentFrequency')}
//                             </div>
//                         </div>
//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <label htmlFor="charges" className={classNames({ 'p-error': isFormFieldValid('charges') }, 'Label__Text')}>
//                                     Charges <span className="Label__Required"></span>
//                                 </label>
//                                 <InputText
//                                     id="charges"
//                                     placeholder="Enter Charges"
//                                     name="charges"
//                                     disabled={true}
//                                     value={formik?.values?.charges || ''}
//                                     onChange={formik.handleChange}
//                                     className={classNames({ 'p-invalid': isFormFieldValid('charges') }, 'Input__Round')}
//                                 />
//                                 {getFormErrorMessage('charges')}
//                             </div>
//                         </div>
//                     </div>
//                     <div className="Form__Header">
//                         <h1>REPAYMENT SCHEDULE </h1>
//                     </div>
//                     <div className="grid">
//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <label htmlFor="frequencyofPayment" className={classNames({ 'p-error': isFormFieldValid('frequencyofPayment') }, 'Label__Text')}>
//                                     Frequency of Payment<span className="Label__Required"></span>
//                                 </label>
//                                 <Dropdown
//                                     id="frequencyofPayment"
//                                     placeholder="Select Frequency of Payment"
//                                     options={[]}
//                                     disabled={true}
//                                     optionLabel="label"
//                                     name="frequencyofPayment"
//                                     filter
//                                     optionValue="value"
//                                     value={formik?.values?.frequencyofPayment || ''}
//                                     onChange={formik.handleChange}
//                                     className={classNames({ 'p-invalid': isFormFieldValid('frequencyofPayment') }, 'Dropdown__Round')}
//                                 />

//                                 {getFormErrorMessage('frequencyofPayment')}
//                             </div>
//                         </div>
//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <label htmlFor="numberofDays" className={classNames({ 'p-error': isFormFieldValid('numberofDays') }, 'Label__Text')}>
//                                     Due Date <span className="Label__Required"></span>
//                                 </label>
//                                 <InputText
//                                     id="numberofDays"
//                                     placeholder="Enter Number of Days"
//                                     type="Date"
//                                     disabled={true}
//                                     name="numberofDays"
//                                     value={formik?.values?.numberofDays || ''}
//                                     onChange={formik.handleChange}
//                                     className={classNames({ 'p-invalid': isFormFieldValid('numberofDays') }, 'Input__Round')}
//                                 />
//                                 {getFormErrorMessage('numberofDays')}
//                             </div>
//                         </div>
//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <div className="Down__Btn" style={{ justifyContent: 'flex-start' }}>
//                                     ``
//                                     <Button disabled label="Add" className="Btn__Dark" style={{ marginTop: '15px' }} />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="grid">
//                         <div className="col-12">
//                             <div className="card">
//                                 <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll">
//                                     {/* <Column body={frequencyBodyTemplate} header="Frequency of Payment" /> */}
//                                     <Column field="dueDate" header="Due Date" />
//                                     <Column header="Actions" body={actionFieldsBodyTemplate} className="Table__ActionHeading__CentredAlign" />
//                                 </DataTable>
//                             </div>
//                         </div>
//                     </div>

//                     <div className="Form__Header">
//                         <h1>DEFINE REPAYMENT SEQUENCE </h1>
//                     </div>
//                     <div className="Centre_Aligned_Buttons">
//                         <Button disabled className="Light_Button" label="Principal Amount" style={{ cursor: 'grabbing' }} />
//                         <Button disabled className="DarkGray_Button" label="Markup" style={{ cursor: 'grabbing' }} />
//                         <Button disabled className="DarkGray_Button" label="Fees" style={{ cursor: 'grabbing' }} />
//                     </div>
//                     {/* <div className="Down__Btn">
//                         <Button disabled={loading} style={{ margin: "20px" }} label={parseInt(campaignData?.currentStep) >= 2 ? "Edit" : "Next"} className="Btn__Dark" />
//                     </div> */}
//                 </form>
//             </div>
//         </>
//     );
// }
// export default ViewRunningFinanceChecker;

// import React, { useState, useRef, useEffect } from 'react';
// import { Button } from 'primereact/button';
// import { useFormik } from 'formik';
// import classNames from 'classnames';
// import * as Yup from 'yup';
// import { InputText } from 'primereact/inputtext';
// import { Checkbox } from 'primereact/checkbox';
// import { useDispatch } from 'react-redux';
// import { Dropdown } from 'primereact/dropdown';
// import { useNavigate } from 'react-router-dom';
// import { DataTable } from 'primereact/datatable';
// import { Column } from 'primereact/column';
// import { useLocation } from 'react-router-dom';
// import { handleGetRequest } from '../../../../../../services/GetTemplate';

// function ViewRunningFinanceChecker({ activeIndex, handleStepClick, lendingData }) {
//     const [loading, setloading] = useState(false);
//     const [loadingIcon, setloadingIcon] = useState('pi pi-search');
//     const [frequencyPeriodLov, setFrequencyPeriodLov] = useState([]);
//     const [fieldTableData, setFieldTableData] = useState([]);

//     const [frequencyPeriodData, setFrequencyPeriodData] = useState([]);
//     const [repaymentScheduleTableData, setRepaymentScheduleTableData] = useState([]);
//     const [repaymentScheduleData, setRepaymentScheduleData] = useState({
//         frequencyId: '',
//         dueDate: ''
//     });

//     const [markupCalculationTableData, setMarkupCalculationTableData] = useState([]);
//     const [markupCalculationData, setMarkupCalculationData] = useState({
//         noOfCycle: '',
//         type: '',
//         price: ''
//     });

//     const [buttons, setButtons] = useState([
//         { label: 'Principal Amount', sequence: 1 },
//         { label: 'Markup', sequence: 2 },
//         { label: 'Fees', sequence: 3 }
//     ]);

//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     const getFrequencyPeriodData = async () => {
//         const response = await handleGetRequest('/configuration/v1/lookups/getdatalov/LKP_FREQUENCY_PERIOD');
//         setFrequencyPeriodData(response?.payLoad);
//     };

//     useEffect(() => {
//         getFrequencyPeriodData();
//     }, []);

//     // Getting params from URL using hash router

//     let { search } = useLocation();
//     const query = new URLSearchParams(search);

//     const requestType = query.get('requestType');
//     const requestTypeDescr = query.get('requestTypeDescr');

//     useEffect(() => {
//         if (requestType === 'I' || (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request')) {
//             if (lendingData) {
//                 formik.setFieldValue('markupFrequencyId', lendingData?.tblLmsRepaymentMethods[0]?.lkpFrequencyPeriodMarkup?.frequencyPeriodId?.toString());
//                 formik.setFieldValue('markupValue', lendingData?.tblLmsRepaymentMethods[0]?.markupValue);

//                 formik.setFieldValue('gracePeriod', lendingData?.tblLmsRepaymentMethods[0]?.gracePeriod === 'Y' ? true : false);

//                 formik.setFieldValue('noOfDaysGracePeriod', lendingData?.tblLmsRepaymentMethods[0]?.noOfGraceDays);

//                 formik.setFieldValue('latePayment', lendingData?.tblLmsRepaymentMethods[0]?.lkpFrequencyPeriodLatePayment != null ? true : false);
//                 formik.setFieldValue('pricing', lendingData?.tblLmsRepaymentMethods[0]?.latePayment);

//                 formik.setFieldValue('latePaymentFrequencyId', lendingData?.tblLmsRepaymentMethods[0]?.lkpFrequencyPeriodLatePayment?.frequencyPeriodId.toString());

//                 formik.setFieldValue('charges', lendingData?.tblLmsRepaymentMethods[0]?.latePaymentCharges);

//                 if (lendingData?.tblLmsRepaymentMethods[0]?.tblLmsRepaymentCycles) {
//                     const modifiedArray = lendingData?.tblLmsRepaymentMethods[0]?.tblLmsRepaymentCycles?.map((item) => ({
//                         noOfCycle: item.noOfCycles,
//                         type: item.priceType,
//                         price: item.priceValue
//                     }));

//                     setMarkupCalculationTableData(modifiedArray);
//                 }

//                 if (lendingData?.tblLmsRepaymentMethods[0]?.tblLmsRepaymentSequences) {
//                     const modifiedArray = lendingData?.tblLmsRepaymentMethods[0]?.tblLmsRepaymentSequences?.map((item) => ({
//                         label: item.sequenceName,
//                         sequence: item.sequence
//                     }));

//                     setButtons(modifiedArray);
//                 }

//                 if (lendingData?.tblLmsRepaymentMethods[0]?.tblLmsRepaymentSchedules) {
//                     const modifiedArray = lendingData?.tblLmsRepaymentMethods[0]?.tblLmsRepaymentSchedules?.map((item) => ({
//                         dueDate: item.dueDate,
//                         frequencyId: item.lkpFrequencyPeriod.frequencyPeriodId
//                     }));

//                     setRepaymentScheduleTableData(modifiedArray);
//                 }
//             }
//         } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
//             if (lendingData[2]) {
//                 formik.setFieldValue('markupFrequencyId', lendingData[2]?.markupFrequencyId?.toString());
//                 formik.setFieldValue('markupValue', lendingData[2]?.markupValue);

//                 formik.setFieldValue('gracePeriod', lendingData[2]?.gracePeriod === true ? true : false);

//                 formik.setFieldValue('noOfDaysGracePeriod', lendingData[2]?.noOfDaysGracePeriod);

//                 formik.setFieldValue('latePayment', lendingData[2]?.latePayment === true ? true : false);

//                 formik.setFieldValue('latePaymentFrequencyId', lendingData[2]?.latePaymentFrequencyId.toString());

//                 formik.setFieldValue('pricing', lendingData[2]?.pricing);

//                 formik.setFieldValue('charges', lendingData[2]?.charges);

//                 setMarkupCalculationTableData(lendingData[2]?.lmsProductRepaymentCycles);
//                 // setButtons([lendingData[2]?.repaymentSeq]);
//                 if (lendingData[2]?.repaymentSeq) {
//                     const transformedArray = Object.entries(lendingData[2]?.repaymentSeq).map(([sequenceName, sequence]) => ({
//                         label: sequenceName,
//                         sequence: sequence
//                     }));
//                     setButtons(transformedArray);
//                 }
//                 setRepaymentScheduleTableData(lendingData[2]?.lmsProductScheduleRequests);
//             }
//         }
//     }, [lendingData]);

//     const validationSchema = Yup.object().shape({
//         // campaignName: Yup.mixed().required('This field is required.'),
//     });

//     const formik = useFormik({
//         validationSchema: validationSchema,
//         initialValues: {
//             // campaignName: "",
//             // startDateTime: "",
//             // endDateTime: "",
//             // campaignBudget: "",
//             // isSubLimits: false,
//             // frequencyPeriodId: "",
//             // amount: ""
//         },

//         // validate: (data) => {
//         //     let errors = {};
//         //     return errors;
//         // },

//         onSubmit: async (data) => {
//             setloading(true);
//             setloadingIcon('pi pi-spin pi-spinner');

//             const newData = {
//                 data: {
//                     security: {
//                         userName: '',
//                         password: '',
//                         securityToken: ''
//                     },
//                     account: {
//                         msidn: '',
//                         iban: '',
//                         bban: '',
//                         pan: '',
//                         currency: ''
//                     },
//                     channel: '',
//                     terminal: '',
//                     reterivalReferenceNumber: '',
//                     payLoad: data,
//                     additionalInformation: [
//                         {
//                             infoKey: '',
//                             infoValue: ''
//                         }
//                     ],
//                     checkSum: ''
//                 }
//             };

//             // if (parseInt(campaignData?.currentStep) >= 0) {
//             //     data['campaignId'] = campaignData?.campaignId;
//             //     const formData = new FormData();
//             //     formData.append("request", JSON.stringify(newData));

//             //     const res = await dispatch(handlePostRequest(formData, '/customerloyalitypromotions/v1/customerloyalitypromotion/editcampaign', true, false));
//             //     if (res?.responseCode === '150000') {
//             //         formik.resetForm();
//             //         // getCompaignId(res?.payLoad?.campaignId)
//             //         handleStepClick(activeIndex + 1);
//             //     }
//             // } else {
//             //     const formData = new FormData();
//             //     formData.append("request", JSON.stringify(newData));

//             //     const res = await dispatch(handlePostRequest(formData, '/customerloyalitypromotions/v1/customerloyalitypromotion/savecampaign', true, false));
//             //     if (res?.responseCode === '150000') {
//             //         formik.resetForm();
//             //         // getCompaignId(res?.payLoad?.campaignId)
//             //         handleStepClick(activeIndex + 2);
//             //     }
//             // }
//             handleStepClick(activeIndex + 1);
//             setloading(false);
//             setloadingIcon('pi pi-search');
//         }
//     });

//     const handledDeleteFieldsData = (e, rowData) => {
//         e.preventDefault();
//         const newFilterData = fieldTableData.filter((value) => value.fieldName !== rowData.fieldName);
//         setFieldTableData(newFilterData);
//     };
//     const actionFieldsBodyTemplate = (rowData) => {
//         return (
//             <>
//                 <div className="actions">
//                     <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteFieldsData(e, rowData)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
//                 </div>
//             </>
//         );
//     };
//     const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

//     const getFormErrorMessage = (name) => {
//         return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
//     };

//     const handleDragStart = (e, label) => {
//         e.dataTransfer.setData('text/plain', label);
//     };

//     const handleDragOver = (e) => {
//         e.preventDefault();
//     };

//     const handleDrop = (e, targetLabel) => {
//         e.preventDefault();

//         const draggedLabel = e.dataTransfer.getData('text/plain');
//         const updatedButtons = [...buttons];

//         const draggedButton = updatedButtons.find((button) => button.label === draggedLabel);
//         const targetButton = updatedButtons.find((button) => button.label === targetLabel);

//         if (draggedButton && targetButton) {
//             // Swap the sequence numbers
//             const tempSequence = draggedButton.sequence;
//             draggedButton.sequence = targetButton.sequence;
//             targetButton.sequence = tempSequence;

//             // Sort buttons based on sequence
//             updatedButtons.sort((a, b) => a.sequence - b.sequence);

//             setButtons(updatedButtons);
//         }
//     };

//     const handledRepaymentScheduleAdd = (e) => {
//         e.preventDefault();
//         const errors = {
//             frequencyId: '',
//             dueDate: ''
//         };
//         if (Object.values(errors).every((error) => error === '')) {
//             const newRepaymentScheduleTableData = {
//                 frequencyId: repaymentScheduleData?.frequencyId,
//                 dueDate: repaymentScheduleData?.dueDate
//             };
//             setRepaymentScheduleTableData([...repaymentScheduleTableData, newRepaymentScheduleTableData]);
//             setRepaymentScheduleData({
//                 frequencyId: '',
//                 dueDate: ''
//             });
//         }
//     };

//     const handledRepaymentScheduleData = (e) => {
//         e.preventDefault();
//         const name = e.target.name;
//         const value = e.target.value;

//         setRepaymentScheduleData({ ...repaymentScheduleData, [name]: value });
//     };

//     const handledMarkupCalculationAdd = (e) => {
//         e.preventDefault();
//         const errors = {
//             noOfCycle: '',
//             type: '',
//             price: ''
//         };
//         if (Object.values(errors).every((error) => error === '')) {
//             const newMarkupCalculationTableData = {
//                 noOfCycle: markupCalculationData?.noOfCycle,
//                 type: markupCalculationData?.type,
//                 price: markupCalculationData?.price
//             };
//             setMarkupCalculationTableData([...markupCalculationTableData, newMarkupCalculationTableData]);
//             setMarkupCalculationData({
//                 noOfCycle: '',
//                 type: '',
//                 price: ''
//             });
//         }
//     };

//     const handledMarkupCalculationData = (e) => {
//         e.preventDefault();
//         const name = e.target.name;
//         const value = e.target.value;

//         setMarkupCalculationData({ ...markupCalculationData, [name]: value });
//     };

//     const pricingLov = [
//         {
//             name: 'Principal Account',
//             lovId: '1'
//         },
//         {
//             name: 'Markup',
//             lovId: '2'
//         },
//         {
//             name: 'Fees',
//             lovId: '3'
//         }
//     ];

//     const typeLov = [
//         {
//             name: 'Fixed',
//             lovId: 'F'
//         },
//         {
//             name: 'Percentage',
//             lovId: 'P'
//         }
//     ];

//     const frequencyBodyTemplate = (rowData) => {
//         const singleFrequency = frequencyPeriodData?.filter((item) => item?.lovId == rowData?.frequencyId);
//         return <p>{singleFrequency[0]?.name}</p>;
//     };

//     return (
//         <>
//             <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
//                 <h1 style={{ color: '#fff', marginLeft: '10px' }}>REPAYMENT METHOD/ VIEW DETAILS</h1>
//             </div>
//             <div className="card Card__Round">
//                 <div className="Form__Header">
//                     <h1>MARKUP CALCULATION </h1>
//                 </div>
//                 <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
//                     <div className="p-fluid p-formgrid grid mb-5">
//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <label htmlFor="markupFrequencyId" className={classNames({ 'p-error': isFormFieldValid('markupFrequencyId') }, 'Label__Text')}>
//                                     Markup Frequency<span className="Label__Required"></span>
//                                 </label>
//                                 <Dropdown
//                                     id="markupFrequencyId"
//                                     placeholder="Select Markup Frequency"
//                                     disabled={true}
//                                     options={frequencyPeriodData}
//                                     optionLabel="name"
//                                     name="markupFrequencyId"
//                                     filter
//                                     optionValue="lovId"
//                                     value={formik?.values?.markupFrequencyId || ''}
//                                     onChange={formik.handleChange}
//                                     className={classNames({ 'p-invalid': isFormFieldValid('markupFrequencyId') }, 'Dropdown__Round')}
//                                 />

//                                 {getFormErrorMessage('markupFrequencyId')}
//                             </div>
//                         </div>
//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <label htmlFor="markupValue" className={classNames({ 'p-error': isFormFieldValid('markupValue') }, 'Label__Text')}>
//                                     Markup Value <span className="Label__Required"></span>
//                                 </label>
//                                 <InputText
//                                     id="markupValue"
//                                     disabled={true}
//                                     placeholder="Enter Markup Value"
//                                     name="markupValue"
//                                     value={formik?.values?.markupValue || ''}
//                                     onChange={formik.handleChange}
//                                     className={classNames({ 'p-invalid': isFormFieldValid('markupValue') }, 'Input__Round')}
//                                 />
//                                 {getFormErrorMessage('markupValue')}
//                             </div>
//                         </div>

//                         <div style={{ backgroundColor: '#edf0f5', padding: '2rem' }} className=" col-12 card">
//                             <div className="grid">
//                                 <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                                     <div className="p-field">
//                                         <label htmlFor="noOfCycle" className={classNames({ 'p-error': isFormFieldValid('noOfCycle') }, 'Label__Text')}>
//                                             No. of Cycle <span className="Label__Required"></span>
//                                         </label>
//                                         <InputText
//                                             id="noOfCycle"
//                                             disabled={true}
//                                             placeholder="Enter No. of Cycle"
//                                             name="noOfCycle"
//                                             value={markupCalculationData?.noOfCycle || ''}
//                                             onChange={handledMarkupCalculationData}
//                                             className={classNames({ 'p-invalid': isFormFieldValid('noOfCycle') }, 'Input__Round')}
//                                         />
//                                         {getFormErrorMessage('noOfCycle')}
//                                     </div>
//                                 </div>
//                                 <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                                     <div className="p-field">
//                                         <label htmlFor="type" className={classNames({ 'p-error': isFormFieldValid('type') }, 'Label__Text')}>
//                                             Type<span className="Label__Required"></span>
//                                         </label>
//                                         <Dropdown
//                                             id="type"
//                                             placeholder="Select Type"
//                                             options={typeLov}
//                                             optionLabel="name"
//                                             name="type"
//                                             disabled={true}
//                                             filter
//                                             optionValue="lovId"
//                                             value={markupCalculationData?.type || ''}
//                                             onChange={handledMarkupCalculationData}
//                                             className={classNames({ 'p-invalid': isFormFieldValid('type') }, 'Dropdown__Round')}
//                                         />

//                                         {getFormErrorMessage('type')}
//                                     </div>
//                                 </div>
//                                 <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                                     <div className="p-field">
//                                         <label htmlFor="price" className={classNames({ 'p-error': isFormFieldValid('price') }, 'Label__Text')}>
//                                             Price <span className="Label__Required"></span>
//                                         </label>
//                                         <InputText
//                                             id="price"
//                                             placeholder="Enter Price"
//                                             name="price"
//                                             disabled={true}
//                                             value={markupCalculationData.price || ''}
//                                             onChange={handledMarkupCalculationData}
//                                             className={classNames({ 'p-invalid': isFormFieldValid('price') }, 'Input__Round')}
//                                         />
//                                         {getFormErrorMessage('price')}
//                                     </div>
//                                 </div>
//                                 <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                                     <div className="p-field">
//                                         <div className="Down__Btn" style={{ justifyContent: 'flex-start' }}>
//                                             <Button disabled label="Add" className="Btn__Dark" style={{ marginTop: '15px' }} onClick={handledMarkupCalculationAdd} />
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="grid">
//                                 <div className="col-12">
//                                     <div className="card">
//                                         <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={markupCalculationTableData}>
//                                             <Column field="noOfCycle" header="No. of Cycle" />
//                                             <Column field="price" header="Price" />
//                                             <Column field="type" header="Type" />
//                                             <Column header="Actions" body={actionFieldsBodyTemplate} className="Table__ActionHeading__CentredAlign" />
//                                         </DataTable>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="p-field">
//                             <div className="field-checkbox">
//                                 <Checkbox inputId="gracePeriod" disabled={true} name="gracePeriod" value={formik?.values?.gracePeriod} checked={formik?.values?.gracePeriod} onChange={formik.handleChange} style={{ marginTop: '10px' }} />
//                                 <label htmlFor="gracePeriod" className={classNames({ 'p-error': isFormFieldValid('gracePeriod') }, 'Label__Text')} style={{ marginTop: '10px' }}>
//                                     Grace Period
//                                 </label>
//                             </div>
//                             {getFormErrorMessage('gracePeriod')}
//                         </div>

//                         {formik?.values?.gracePeriod === true && (
//                             <div style={{ backgroundColor: '#edf0f5', padding: '2rem' }} className=" col-12 card">
//                                 <div className="Form__Header">
//                                     <h1>GRACE PERIOD </h1>
//                                 </div>
//                                 <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                                     <div className="p-field">
//                                         <label htmlFor="noOfDaysGracePeriod" className={classNames({ 'p-error': isFormFieldValid('noOfDaysGracePeriod') }, 'Label__Text')}>
//                                             Number of Days <span className="Label__Required"></span>
//                                         </label>
//                                         <InputText
//                                             id="noOfDaysGracePeriod"
//                                             placeholder="Enter Number of Days"
//                                             name="noOfDaysGracePeriod"
//                                             value={formik?.values?.noOfDaysGracePeriod || ''}
//                                             disabled={true}
//                                             onChange={formik.handleChange}
//                                             className={classNames({ 'p-invalid': isFormFieldValid('noOfDaysGracePeriod') }, 'Input__Round')}
//                                         />
//                                         {getFormErrorMessage('noOfDaysGracePeriod')}
//                                     </div>
//                                 </div>
//                             </div>
//                         )}
//                     </div>
//                     <div className="Form__Header">
//                         <h1>LATE PAYMENT </h1>
//                     </div>

//                     <div className="p-field">
//                         <div className="field-checkbox">
//                             <Checkbox inputId="latePayment" disabled={true} name="latePayment" value={formik?.values?.latePayment} checked={formik?.values?.latePayment} onChange={formik.handleChange} style={{ marginTop: '10px' }} />
//                             <label htmlFor="latePayment" className={classNames({ 'p-error': isFormFieldValid('latePayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
//                                 Late Payment
//                             </label>
//                         </div>
//                         {getFormErrorMessage('latePayment')}
//                     </div>
//                     {formik?.values?.latePayment === true && (
//                         <div className="grid">
//                             <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                                 <div className="p-field">
//                                     <label htmlFor="latePaymentFrequencyId" className={classNames({ 'p-error': isFormFieldValid('latePaymentFrequencyId') }, 'Label__Text')}>
//                                         Late Payment Frequency<span className="Label__Required"></span>
//                                     </label>
//                                     <Dropdown
//                                         id="latePaymentFrequencyId"
//                                         placeholder="Select Frequency of Payment"
//                                         options={frequencyPeriodData}
//                                         optionLabel="name"
//                                         name="latePaymentFrequencyId"
//                                         disabled={true}
//                                         filter
//                                         optionValue="lovId"
//                                         value={formik?.values?.latePaymentFrequencyId || ''}
//                                         onChange={formik.handleChange}
//                                         className={classNames({ 'p-invalid': isFormFieldValid('latePaymentFrequencyId') }, 'Dropdown__Round')}
//                                     />

//                                     {getFormErrorMessage('latePaymentFrequencyId')}
//                                 </div>
//                             </div>
//                             <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                                 <div className="p-field">
//                                     <label htmlFor="pricing" className={classNames({ 'p-error': isFormFieldValid('pricing') }, 'Label__Text')}>
//                                         Late Payment<span className="Label__Required"></span>
//                                     </label>
//                                     <Dropdown
//                                         id="pricing"
//                                         placeholder="Select Payment"
//                                         options={pricingLov}
//                                         optionLabel="name"
//                                         name="pricing"
//                                         disabled={true}
//                                         filter
//                                         optionValue="lovId"
//                                         value={formik?.values?.pricing || ''}
//                                         onChange={formik.handleChange}
//                                         className={classNames({ 'p-invalid': isFormFieldValid('pricing') }, 'Dropdown__Round')}
//                                     />

//                                     {getFormErrorMessage('pricing')}
//                                 </div>
//                             </div>
//                             <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                                 <div className="p-field">
//                                     <label htmlFor="charges" className={classNames({ 'p-error': isFormFieldValid('charges') }, 'Label__Text')}>
//                                         Charges <span className="Label__Required"></span>
//                                     </label>
//                                     <InputText
//                                         id="charges"
//                                         placeholder="Enter Charges"
//                                         name="charges"
//                                         disabled={true}
//                                         value={formik?.values?.charges || ''}
//                                         onChange={formik.handleChange}
//                                         className={classNames({ 'p-invalid': isFormFieldValid('charges') }, 'Input__Round')}
//                                     />
//                                     {getFormErrorMessage('charges')}
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                     <div className="Form__Header">
//                         <h1>REPAYMENT SCHEDULE </h1>
//                     </div>
//                     <div className="grid">
//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <label htmlFor="frequencyId" className={classNames({ 'p-error': isFormFieldValid('frequencyId') }, 'Label__Text')}>
//                                     Frequency of Payment<span className="Label__Required"></span>
//                                 </label>
//                                 <Dropdown
//                                     id="frequencyId"
//                                     placeholder="Select Frequency of Payment"
//                                     options={frequencyPeriodData}
//                                     optionLabel="name"
//                                     name="frequencyId"
//                                     disabled={true}
//                                     filter
//                                     optionValue="lovId"
//                                     value={repaymentScheduleData?.frequencyId || ''}
//                                     onChange={handledRepaymentScheduleData}
//                                     className={classNames({ 'p-invalid': isFormFieldValid('frequencyId') }, 'Dropdown__Round')}
//                                 />

//                                 {getFormErrorMessage('frequencyId')}
//                             </div>
//                         </div>
//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <label htmlFor="dueDate" className={classNames({ 'p-error': isFormFieldValid('dueDate') }, 'Label__Text')}>
//                                     Due Date <span className="Label__Required"></span>
//                                 </label>
//                                 <InputText
//                                     id="dueDate"
//                                     type="Date"
//                                     name="dueDate"
//                                     disabled={true}
//                                     value={repaymentScheduleData?.dueDate || ''}
//                                     onChange={handledRepaymentScheduleData}
//                                     className={classNames({ 'p-invalid': isFormFieldValid('dueDate') }, 'Input__Round')}
//                                 />
//                                 {getFormErrorMessage('dueDate')}
//                             </div>
//                         </div>
//                         <div className="p-field col-12 md:col-4 pt-3 pb-3">
//                             <div className="p-field">
//                                 <div className="Down__Btn" style={{ justifyContent: 'flex-start' }}>
//                                     <Button disabled label="Add" className="Btn__Dark" style={{ marginTop: '15px' }} onClick={handledRepaymentScheduleAdd} />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="grid">
//                         <div className="col-12">
//                             <div className="card">
//                                 <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={repaymentScheduleTableData}>
//                                     <Column body={frequencyBodyTemplate} header="Frequency of Payment" />
//                                     <Column field="dueDate" header="Due Date" />
//                                     <Column header="Actions" body={actionFieldsBodyTemplate} className="Table__ActionHeading__CentredAlign" />
//                                 </DataTable>
//                             </div>
//                         </div>
//                     </div>

//                     <div className="Form__Header">
//                         <h1>DEFINE REPAYMENT SEQUENCE </h1>
//                     </div>
//                     <div className="Centre_Aligned_Buttons" onDragOver={handleDragOver}>
//                         {buttons?.map((button) => (
//                             <Button key={button.label} className="DraggableButton Light_Button" label={`${button.label}`} draggable disabled onDragStart={(e) => handleDragStart(e, button.label)} onDrop={(e) => handleDrop(e, button.label)} />
//                         ))}
//                     </div>
//                     {/* <div className="Down__Btn">
//                         <Button disabled={loading} style={{ margin: "20px" }} label={lendingData?.currentStep >= 2 ? "Edit" : "Save"} className="Btn__Dark" />
//                     </div> */}
//                 </form>
//             </div>
//         </>
//     );
// }
// export default ViewRunningFinanceChecker;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useDispatch } from 'react-redux';
import { handleGetRequest } from '../../../../../../services/GetTemplate';
import { useLocation } from 'react-router';

function ViewRunningFinanceChecker({ activeIndex, handleStepClick, lendingData, lendingIdData, getLendingId }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [frequencyPeriodData, setFrequencyPeriodData] = useState([]);

    const [noOfCyclesTableData, setNoOfCyclesTableData] = useState([]);
    const [noOfCyclesData, setNoOfCyclesData] = useState({
        noOfCycle: '',
        type: '',
        price: ''
    });

    const [noOfCyclesError, setNoOfCyclesError] = useState({
        noOfCycle: '',
        type: '',
        price: ''
    });

    /////////////////////////////////////////
    const [runningFinanceTableData, setRunningFinanceTableData] = useState([]);
    const [runningFinanceData, setRunningFinanceData] = useState({
        markupValue: '',
        apr: '',

        gracePeriod: '',
        noOfDaysGracePeriod: '',

        latePayment: '',
        latePaymentType: '',
        latePaymentCharges: '',
        latePaymentOn: '',

        frequencyId: ''

        // earlyPayment: '',
        // earlyPaymentType: '',
        // earlyPaymentCharges: '',
        // earlyPaymentOn: '',

        // downPayment: '',
        // downPaymentCharges: '',
    });
    const [runningFinanceError, setRunningFinanceError] = useState({
        markupValue: '',
        apr: '',

        gracePeriod: '',
        noOfDaysGracePeriod: '',

        latePayment: '',
        latePaymentType: '',
        latePaymentCharges: '',
        latePaymentOn: '',

        frequencyId: ''

        // earlyPayment: '',
        // earlyPaymentType: '',
        // earlyPaymentCharges: '',
        // earlyPaymentOn: '',

        // downPayment: '',
        // downPaymentCharges: '',
    });
    //////////////////////////////

    const [buttons, setButtons] = useState([
        { label: 'Principal Amount', sequence: 1 },
        { label: 'Markup', sequence: 2 },
        { label: 'Fees', sequence: 3 }
    ]);

    const dispatch = useDispatch();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const requestType = query.get('requestType');
    const requestTypeDescr = query.get('requestTypeDescr');

    useEffect(() => {
        if (requestType === 'I' || (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request')) {
            let runningFinance = [];
            if (lendingData) {
                if (lendingData?.tblLmsRepaymentMethods?.length > 0) {
                    runningFinance = lendingData?.tblLmsRepaymentMethods
                        ?.filter((itm) => itm?.isActive === 'Y')
                        ?.map((item) => {
                            return {
                                markupValue: item?.markupValue,
                                apr: item?.apr,

                                gracePeriod: item?.gracePeriod === 'Y' ? true : false,
                                noOfDaysGracePeriod: item?.noOfGraceDays,

                                latePayment: item?.latePayment === 'Y' ? true : false,
                                latePaymentType: item?.latePaymentType?.toString(),
                                latePaymentCharges: item?.latePaymentCharges,
                                latePaymentOn: item?.latePaymentOn?.toString(),

                                // earlyPayment: item?.earlyPayment === "Y" ? true : false,
                                // earlyPaymentType: item?.earlyPaymentType?.toString(),
                                // earlyPaymentCharges: item?.earlyPaymentCharges,
                                // earlyPaymentOn: item?.earlyPaymentOn?.toString(),

                                frequencyId: item?.lkpFrequencyPeriod?.frequencyPeriodId?.toString(),

                                tblLmsRepaymentCycles: item?.tblLmsRepaymentCycles

                                // downPayment: item?.downPayment === "Y" ? "Y" : "N",
                                // downPaymentCharges: item?.downPaymentCharges,
                            };
                        });
                    setRunningFinanceData(runningFinance[0]);
                    const noOfCycle = runningFinance[0]?.tblLmsRepaymentCycles?.map((item) => {
                        return {
                            noOfCycle: item?.noOfCycles,
                            type: item?.priceType,
                            price: item?.priceValue
                        };
                    });
                    setNoOfCyclesTableData(noOfCycle);
                }

                let pA = [];
                Object.keys(lendingData?.tblLmsRepaymentMethods[0]).forEach((key) => {
                    if (key === 'seqPrincipalAmount') {
                        pA.push({ label: key, value: lendingData?.tblLmsRepaymentMethods[0][key] });
                    }

                    if (key === 'seqMarkup') {
                        pA.push({ label: key, value: lendingData?.tblLmsRepaymentMethods[0][key] });
                    }

                    if (key === 'seqFees') {
                        pA.push({ label: key, value: lendingData?.tblLmsRepaymentMethods[0][key] });
                    }
                });

                const updatedDefinePayment = pA?.map((item) => {
                    return {
                        label: item.label.replace('seq', ''),
                        sequence: item.value
                    };
                });

                updatedDefinePayment?.sort((a, b) => a.sequence - b.sequence);
                setButtons(updatedDefinePayment);
            }
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            let runningFinance = [];
            if (lendingData[2]) {
                const item = lendingData[2]?.lmsProductRepaymentRequests[0];
                if (item) {
                    const runningFinance = {
                        markupValue: item?.markupValue?.toString(),
                        apr: item?.apr,
                        gracePeriod: item?.gracePeriod === 'Y',
                        noOfDaysGracePeriod: item?.noOfGraceDays,
                        latePayment: item?.latePayment === 'Y',
                        latePaymentType: item?.latePaymentType?.toString(),
                        latePaymentCharges: item?.latePaymentCharges,
                        latePaymentOn: item?.latePaymentOn?.toString(),
                        frequencyId: item?.frequencyId?.toString()
                        // tblLmsRepaymentCycles: item?.lmsProductRepaymentCycles
                    };

                    setRunningFinanceData(runningFinance);
                }
                runningFinance = lendingData[2]?.lmsProductRepaymentRequests?.map((item) => {
                    return {
                        tblLmsRepaymentCycles: item?.lmsProductRepaymentCycles
                    };
                });

                // setRunningFinanceData(runningFinance);
                const noOfCycle = runningFinance[0]?.tblLmsRepaymentCycles?.map((item) => {
                    return {
                        noOfCycle: item?.noOfCycle,
                        type: item?.type,
                        price: item?.price
                    };
                });
                setNoOfCyclesTableData(noOfCycle);

                let pA = [];
                Object.keys(lendingData[2]?.lmsProductRepaymentRequests[0]).forEach((key) => {
                    if (key === 'seqPrincipalAmount') {
                        pA.push({ label: key, value: lendingData[2]?.lmsProductRepaymentRequests[0][key] });
                    }

                    if (key === 'seqMarkup') {
                        pA.push({ label: key, value: lendingData[2]?.lmsProductRepaymentRequests[0][key] });
                    }

                    if (key === 'seqFees') {
                        pA.push({ label: key, value: lendingData[2]?.lmsProductRepaymentRequests[0][key] });
                    }
                });

                const updatedDefinePayment = pA?.map((item) => {
                    return {
                        label: item.label.replace('seq', ''),
                        sequence: item.value
                    };
                });

                updatedDefinePayment?.sort((a, b) => a.sequence - b.sequence);
                setButtons(updatedDefinePayment);
            }
        }
    }, [lendingData]); // eslint-disable-line

    const getFrequencyPeriodData = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getdatalov/LKP_FREQUENCY_PERIOD');
        setFrequencyPeriodData(response?.payLoad);
    };

    useEffect(() => {
        getFrequencyPeriodData();
    }, []);

    const validationSchema = Yup.object().shape({});

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {},

        validate: (data) => {},

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handlePricipalAmount = (e) => {
        e.preventDefault();
    };
    const handleMarkup = (e) => {
        e.preventDefault();
    };
    const handleFee = (e) => {
        e.preventDefault();
    };

    const handleDragStart = (e, label) => {
        e.dataTransfer.setData('text/plain', label);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e, targetLabel) => {
        e.preventDefault();

        const draggedLabel = e.dataTransfer.getData('text/plain');
        const updatedButtons = [...buttons];

        const draggedButton = updatedButtons.find((button) => button.label === draggedLabel);
        const targetButton = updatedButtons.find((button) => button.label === targetLabel);

        if (draggedButton && targetButton) {
            // Swap the sequence numbers
            const tempSequence = draggedButton.sequence;
            draggedButton.sequence = targetButton.sequence;
            targetButton.sequence = tempSequence;

            // Sort buttons based on sequence
            updatedButtons.sort((a, b) => a.sequence - b.sequence);

            setButtons(updatedButtons);
        }
    };

    // const downPaymentLov = [
    //     {
    //         name: 'Yes',
    //         lovId: 'Y'
    //     },
    //     {
    //         name: 'No',
    //         lovId: 'N'
    //     }
    // ];

    // const frequencyBodyTemplate = (rowData) => {
    //     const singleFrequency = frequencyPeriodData?.filter((item) => item?.lovId == rowData?.frequencyId);
    //     return <p>{singleFrequency[0]?.name}</p>;
    // };

    //////////////////////////new UI code /////////////////////
    // const loanTenureNOD = [
    //     {name: "90 Days", lovId: "90"},
    //     {name: "180 Days", lovId: "180"},
    //     {name: "270 Days", lovId: "270"},
    //     {name: "360 Days", lovId: "360"},
    // ];

    const typeLov = [
        { name: 'FIXED', lovId: 'F' },
        { name: 'PERCENTAGE', lovId: 'P' }
    ];

    const actionBodyTypeTemplate = (rowData) => {
        return <p>{rowData?.type === 'F' ? 'Fixed' : 'Percentage'}</p>;
    };

    const paymentOnLov = [
        { name: 'Outstanding Principal', lovId: 'O' },
        { name: 'Markup', lovId: 'M' }
    ];

    const actionBodyForNoOfCyclesTemplate = (rowData, rowIndex) => {
        return (
            <>
                {/* <Button tooltip="Edit" tooltipOptions={{ position: 'top' }} icon="pi pi-pencil" onClick={(e) => handledEditData(e, rowData, rowIndex)} className="p-button-rounded p-button-primary" style={{ border: 'none' }} /> */}
                <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteNoOfCyclesData(e, rowData, rowIndex)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
            </>
        );
    };

    const handledDeleteNoOfCyclesData = (e, rowData, rowIndex) => {
        e.preventDefault();
        const newFilterData = noOfCyclesTableData?.filter((value, index) => index !== rowIndex?.rowIndex);
        setNoOfCyclesTableData(newFilterData);
    };

    /////////////////////////////////////////////////////////////
    const actionBodyTemplate = (rowData, rowIndex) => {
        return (
            <>
                <Button tooltip="Edit" tooltipOptions={{ position: 'top' }} icon="pi pi-pencil" onClick={(e) => handledEditData(e, rowData, rowIndex)} className="p-button-rounded p-button-primary" style={{ border: 'none' }} />
                <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteData(e, rowData, rowIndex)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
            </>
        );
    };

    const handledDeleteData = (e, rowData, rowIndex) => {
        e.preventDefault();
        const newFilterData = runningFinanceTableData?.filter((value, index) => index !== rowIndex?.rowIndex);
        setRunningFinanceTableData(newFilterData);
    };

    const handledEditData = (e, rowData, rowIndex) => {
        e.preventDefault();
        const newFilterData = runningFinanceTableData?.filter((value, index) => index !== rowIndex?.rowIndex);
        setRunningFinanceData(newFilterData);
    };

    const handledAdd = (e) => {
        const errors = {
            markupValue: '',
            apr: '',

            gracePeriod: '',
            noOfDaysGracePeriod: '',

            latePayment: '',
            latePaymentType: '',
            latePaymentCharges: '',
            latePaymentOn: '',

            frequencyId: '',

            // earlyPayment: '',
            // earlyPaymentType: '',
            // earlyPaymentCharges: '',
            // earlyPaymentOn: '',

            // downPayment: '',
            // downPaymentCharges: '',

            seqPrincipalAmount: '',
            seqMarkup: '',
            seqLateFee: ''
        };

        if (runningFinanceData?.markupValue === '') {
            errors.markupValue = 'This field is required.';
        }

        if (runningFinanceData?.apr === '') {
            errors.apr = 'This field is required.';
        }

        if (runningFinanceData?.gracePeriod === true) {
            if (runningFinanceData?.noOfDaysGracePeriod === '') {
                errors.noOfDaysGracePeriod = 'This field is required';
            }
        }

        if (runningFinanceData?.latePayment === true) {
            if (runningFinanceData?.latePaymentType === '') {
                errors.latePaymentType = 'This field is required';
            }

            if (runningFinanceData?.latePaymentCharges === '') {
                errors.latePaymentCharges = 'This field is required';
            }

            if (runningFinanceData?.latePaymentOn === '') {
                errors.latePaymentOn = 'This field is required';
            }
        }

        if (runningFinanceData?.frequencyId === '') {
            errors.frequencyId = 'This field is required';
        }

        // if (runningFinanceData?.earlyPayment === true) {
        //     if (runningFinanceData?.earlyPaymentType === '') {
        //         errors.earlyPaymentType = 'This field is required';
        //     }

        //     if (runningFinanceData?.earlyPaymentCharges === '') {
        //         errors.earlyPaymentCharges = 'This field is required';
        //     }

        //     if (runningFinanceData?.earlyPaymentOn === '') {
        //         errors.earlyPaymentOn = 'This field is required';
        //     }
        // }

        // if(runningFinanceData?.downPayment === 'Y') {
        //     if (runningFinanceData?.downPaymentCharges === '') {
        //         errors.downPaymentCharges = 'This field is required';
        //     }
        // }

        setRunningFinanceError(errors);

        const sequenceObject = buttons?.reduce((result, item) => {
            const label = item.label.replace(/\s+/g, ''); // Remove spaces from label
            const seqKey = `seq${label}`;
            result[seqKey] = item.sequence;
            return result;
        }, {});

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            const newRunningFinanceTableData = {
                markupValue: runningFinanceData?.markupValue,
                apr: runningFinanceData?.apr,

                gracePeriod: runningFinanceData?.gracePeriod,
                noOfDaysGracePeriod: runningFinanceData?.noOfDaysGracePeriod,

                latePayment: runningFinanceData?.latePayment,
                latePaymentType: runningFinanceData?.latePaymentType,
                latePaymentCharges: runningFinanceData?.latePaymentCharges,
                latePaymentOn: runningFinanceData?.latePaymentOn,

                frequencyId: runningFinanceData?.frequencyId,

                // earlyPayment: runningFinanceData?.earlyPayment,
                // earlyPaymentType: runningFinanceData?.earlyPaymentType,
                // earlyPaymentCharges: runningFinanceData?.earlyPaymentCharges,
                // earlyPaymentOn: runningFinanceData?.earlyPaymentOn,

                // downPayment: runningFinanceData?.downPayment,
                // downPaymentCharges: runningFinanceData?.downPaymentCharges,

                repaymentTypeCode: lendingData?.lkpLmsFacilityType?.lmsFacilityTypeCode,

                lmsProductRepaymentCycles: noOfCyclesTableData
            };

            const combinedObject = { ...newRunningFinanceTableData, ...sequenceObject };
            setRunningFinanceTableData([...runningFinanceTableData, combinedObject]);

            // setRunningFinanceData({
            //     markupValue: '',
            //     apr: '',

            //     gracePeriod: '',
            //     noOfDaysGracePeriod: '',

            //     latePayment: '',
            //     latePaymentType: '',
            //     latePaymentCharges: '',
            //     latePaymentOn: '',

            //     frequencyId: '',

            //     // earlyPayment: '',
            //     // earlyPaymentType: '',
            //     // earlyPaymentCharges: '',
            //     // earlyPaymentOn: '',

            //     // downPayment: '',
            //     // downPaymentCharges: '',
            // });

            setButtons([
                { label: 'Principal Amount', sequence: 1 },
                { label: 'Markup', sequence: 2 },
                { label: 'Fees', sequence: 3 }
            ]);
        }
    };

    const handledData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        const checked = e.target.checked;
        const type = e.target.type;

        setRunningFinanceData({ ...runningFinanceData, [name]: type === 'checkbox' ? checked : value });

        // Clear the error message for the field being edited
        setRunningFinanceError({
            ...runningFinanceError,
            [name]: ''
        });
    };
    //////////////////////////////////////////////////////////

    const handledNoOfCyclesAdd = (e) => {
        e.preventDefault();

        const errors = {
            noOfCycle: '',
            type: '',
            price: ''
        };

        if (noOfCyclesData?.noOfCycle === '') {
            errors.noOfCycle = 'This field is required.';
        }

        if (noOfCyclesData?.price === '') {
            errors.price = 'This field is required.';
        }

        if (noOfCyclesData?.price === '') {
            errors.price = 'This field is required.';
        }

        setNoOfCyclesError(errors);

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            const newNoOfCyclesTableData = {
                noOfCycle: noOfCyclesData?.noOfCycle,
                type: noOfCyclesData?.type,
                price: noOfCyclesData?.price
                // repaymentTypeCode: lendingData?.lkpLmsFacilityType?.lmsFacilityTypeCode,
            };

            setNoOfCyclesTableData([...noOfCyclesTableData, newNoOfCyclesTableData]);
            setNoOfCyclesData({
                noOfCycle: '',
                type: '',
                price: ''
            });
        }
    };

    const handledNoOfCyclesData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        const checked = e.target.checked;
        const type = e.target.type;

        setNoOfCyclesData({ ...noOfCyclesData, [name]: type === 'checkbox' ? checked : value });

        // Clear the error message for the field being edited
        setNoOfCyclesError({
            ...noOfCyclesError,
            [name]: ''
        });
    };

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>REPAYMENT METHOD/ADD NEW</h1>
            </div>
            <div className="card Card__Round">
                <div className="Form__Header flex align-items-center justify-content-between">
                    <h1>MARKUP CALCULATION </h1>
                    {/* <Button
                        icon="pi pi-plus"
                        className="p-button-rounded"
                        aria-label="Filter"
                        tooltip="Add New Repayment"
                        onClick={(e) => handledAdd(e)}
                    /> */}
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="markupValue" className={classNames({ 'p-error': isFormFieldValid('markupValue') }, 'Label__Text')}>
                                    Markup value <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={4}
                                    id="markupValue"
                                    placeholder="Enter Markup Value"
                                    name="markupValue"
                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                    disabled={true}
                                    value={runningFinanceData?.markupValue || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('markupValue') }, 'Input__Round')}
                                />
                                <small className="p-error">{runningFinanceError?.markupValue}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="apr" className={classNames({ 'p-error': isFormFieldValid('apr') }, 'Label__Text')}>
                                    APR <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="apr"
                                    placeholder="Enter APR"
                                    name="apr"
                                    maxLength={4}
                                    disabled={true}
                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                    value={runningFinanceData?.apr || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('apr') }, 'Input__Round')}
                                />
                                <small className="p-error">{runningFinanceError?.apr}</small>
                            </div>
                        </div>
                    </div>

                    <div style={{ backgroundColor: '#edf0f5', padding: '2rem' }} className=" col-12 card">
                        <div className="grid">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="noOfCycle" className={classNames({ 'p-error': isFormFieldValid('noOfCycle') }, 'Label__Text')}>
                                        No Of Cycles<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={4}
                                        id="noOfCycle"
                                        placeholder="Enter No. Of Cycles"
                                        name="noOfCycle"
                                        disabled={true}
                                        keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                        value={noOfCyclesData?.noOfCycle || ''}
                                        onChange={handledNoOfCyclesData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('noOfCycle') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{noOfCyclesError?.noOfCycle}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="type" className={classNames({ 'p-error': isFormFieldValid('type') }, 'Label__Text')}>
                                        Type<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="type"
                                        placeholder="Select Type"
                                        options={typeLov}
                                        optionLabel="name"
                                        name="type"
                                        filter
                                        disabled={true}
                                        optionValue="lovId"
                                        value={noOfCyclesData?.type || ''}
                                        onChange={handledNoOfCyclesData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('type') }, 'Dropdown__Round')}
                                    />
                                    <small className="p-error">{noOfCyclesError?.type}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="price" className={classNames({ 'p-error': isFormFieldValid('price') }, 'Label__Text')}>
                                        Price <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="price"
                                        placeholder="Enter Price"
                                        name="price"
                                        maxLength={8}
                                        disabled={true}
                                        keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                        value={noOfCyclesData?.price || ''}
                                        onChange={handledNoOfCyclesData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('price') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{noOfCyclesError?.price}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <div className="Down__Btn" style={{ justifyContent: 'flex-start' }}>
                                        <Button label="Add" disabled className="Btn__Dark" style={{ marginTop: '15px' }} onClick={handledNoOfCyclesAdd} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12">
                                <div className="card mt-6">
                                    <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={noOfCyclesTableData}>
                                        <Column field="noOfCycle" header="No. Of Cycles" />
                                        <Column field="type" header="Type" body={actionBodyTypeTemplate} />
                                        <Column field="price" header="Price" />
                                        <Column header="Actions" body={actionBodyForNoOfCyclesTemplate} style={{ textAlign: 'center' }} className="Table__ActionHeading__CentredAlign" />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Form__Header col-12">
                        <h1>GRACE PERIOD </h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="gracePeriod" name="gracePeriod" value={runningFinanceData?.gracePeriod} checked={runningFinanceData?.gracePeriod} onChange={handledData} style={{ marginTop: '10px' }} disabled />
                                    <label htmlFor="gracePeriod" className={classNames({ 'p-error': isFormFieldValid('gracePeriod') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Grace Period
                                    </label>
                                </div>
                                <small className="p-error">{runningFinanceError?.gracePeriod}</small>
                            </div>
                        </div>
                        {runningFinanceData?.gracePeriod === true && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="noOfDaysGracePeriod" className={classNames({ 'p-error': isFormFieldValid('noOfDaysGracePeriod') }, 'Label__Text')}>
                                        Number of Days <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={4}
                                        id="noOfDaysGracePeriod"
                                        placeholder="Enter Number of Days"
                                        name="noOfDaysGracePeriod"
                                        keyfilter={'int'}
                                        disabled={true}
                                        value={runningFinanceData?.noOfDaysGracePeriod || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('noOfDaysGracePeriod') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{runningFinanceError?.noOfDaysGracePeriod}</small>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="Form__Header col-12">
                        <h1>LATE PAYMENT </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="latePayment" name="latePayment" value={runningFinanceData?.latePayment} checked={runningFinanceData?.latePayment} onChange={handledData} disabled style={{ marginTop: '10px' }} />
                                    <label htmlFor="latePayment" className={classNames({ 'p-error': isFormFieldValid('latePayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Late payment
                                    </label>
                                </div>
                                <small className="p-error">{runningFinanceError?.latePayment}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        {runningFinanceData?.latePayment === true && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentType" className={classNames({ 'p-error': isFormFieldValid('latePaymentType') }, 'Label__Text')}>
                                            Type<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="latePaymentType"
                                            placeholder="Select Type"
                                            options={typeLov}
                                            optionLabel="name"
                                            name="latePaymentType"
                                            filter
                                            disabled={true}
                                            optionValue="lovId"
                                            value={runningFinanceData?.latePaymentType || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('latePaymentType') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{runningFinanceError?.latePaymentType}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentCharges" className={classNames({ 'p-error': isFormFieldValid('latePaymentCharges') }, 'Label__Text')}>
                                            Charges <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="latePaymentCharges"
                                            placeholder="Enter charges"
                                            name="latePaymentCharges"
                                            maxLength={8}
                                            disabled={true}
                                            keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                            value={runningFinanceData?.latePaymentCharges || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('latePaymentCharges') }, 'Input__Round')}
                                        />
                                        <small className="p-error">{runningFinanceError?.latePaymentCharges}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentOn" className={classNames({ 'p-error': isFormFieldValid('latePaymentOn') }, 'Label__Text')}>
                                            Late Payment On<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="latePaymentOn"
                                            placeholder="Select Late Payment On"
                                            options={paymentOnLov}
                                            optionLabel="name"
                                            name="latePaymentOn"
                                            filter
                                            disabled={true}
                                            optionValue="lovId"
                                            value={runningFinanceData?.latePaymentOn || ''}
                                            onChange={handledData}
                                            className={classNames({ 'p-invalid': isFormFieldValid('latePaymentOn') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{runningFinanceError?.latePaymentOn}</small>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {/* //////////////////////////////////////////////////// */}
                    <div className="Form__Header col-12">
                        <h1>REPAYMENT SCHEDULE </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="frequencyId" className={classNames({ 'p-error': isFormFieldValid('frequencyId') }, 'Label__Text')}>
                                    Frequency Of Payment<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="frequencyId"
                                    placeholder="Select Frequency Of Payment"
                                    options={frequencyPeriodData}
                                    optionLabel="name"
                                    name="frequencyId"
                                    disabled={true}
                                    filter
                                    optionValue="lovId"
                                    value={runningFinanceData?.frequencyId || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('frequencyId') }, 'Dropdown__Round')}
                                />
                                <small className="p-error">{runningFinanceError?.frequencyId}</small>
                            </div>
                        </div>
                    </div>
                    {/* ///////////////////////////////////////////////////// */}

                    {/* <div className="Form__Header col-12">
                        <h1>ALLOW EARLY REPAYMENT </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="earlyPayment" name="earlyPayment" value={runningFinanceData?.earlyPayment} checked={runningFinanceData?.earlyPayment}
                                        onChange={handledData}
                                        style={{ marginTop: '10px' }} />
                                    <label htmlFor="earlyPayment" className={classNames({ 'p-error': isFormFieldValid('earlyPayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Early Payment
                                    </label>
                                </div>
                                <small className="p-error">{runningFinanceError?.earlyPayment}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        {runningFinanceData?.earlyPayment === true && 
                        <>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="earlyPaymentType" className={classNames({ 'p-error': isFormFieldValid('earlyPaymentType') }, 'Label__Text')}>
                                        Type<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="earlyPaymentType"
                                        placeholder="Select Type"
                                        options={typeLov}
                                        optionLabel="name"
                                        name="earlyPaymentType"
                                        filter
                                        optionValue="lovId"
                                        value={runningFinanceData?.earlyPaymentType || ''}
                                        onChange={handledData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('earlyPaymentType') }, 'Dropdown__Round')}
                                    />
                                    <small className="p-error">{runningFinanceError?.earlyPaymentType}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="earlyPaymentCharges" className={classNames({ 'p-error': isFormFieldValid('earlyPaymentCharges') }, 'Label__Text')}>
                                        Charges <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="earlyPaymentCharges"
                                        placeholder="Enter charges"
                                        name="earlyPaymentCharges"
                                        maxLength={8}
                                        keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                        value={runningFinanceData?.earlyPaymentCharges || ''}
                                        onChange={handledData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('earlyPaymentCharges') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{runningFinanceError?.earlyPaymentCharges}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="earlyPaymentOn" className={classNames({ 'p-error': isFormFieldValid('earlyPaymentOn') }, 'Label__Text')}>
                                        Early Payment On<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="earlyPaymentOn"
                                        placeholder="Select Early Payment On"
                                        options={paymentOnLov}
                                        optionLabel="name"
                                        name="earlyPaymentOn"
                                        filter
                                        optionValue="lovId"
                                        value={runningFinanceData?.earlyPaymentOn || ''}
                                        onChange={handledData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('earlyPaymentOn') }, 'Dropdown__Round')}
                                    />
                                    <small className="p-error">{runningFinanceError?.earlyPaymentOn}</small>
                                </div>
                            </div>
                        </>}
                    </div>

                    <div className="Form__Header col-12">
                        <h1>DOWN PAYMENT </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="downPayment" className={classNames({ 'p-error': isFormFieldValid('downPayment') }, 'Label__Text')}>
                                    Select Down Payment<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="downPayment"
                                    placeholder="Select Down Payment"
                                    options={downPaymentLov}
                                    optionLabel="name"
                                    name="downPayment"
                                    filter
                                    optionValue="lovId"
                                    value={runningFinanceData?.downPayment || ''}
                                    onChange={handledData}
                                    className={classNames({ 'p-invalid': isFormFieldValid('downPayment') }, 'Dropdown__Round')}
                                />
                                <small className="p-error">{runningFinanceError?.downPayment}</small>
                            </div>
                        </div>
                        {runningFinanceData?.downPayment === 'Y' &&
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="downPaymentCharges" className={classNames({ 'p-error': isFormFieldValid('downPaymentCharges') }, 'Label__Text')}>
                                        Charges <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="downPaymentCharges"
                                        placeholder="Enter Down Payment Charges"
                                        name="downPaymentCharges"
                                        maxLength={8}
                                        keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                        value={runningFinanceData?.downPaymentCharges || ''}
                                        onChange={handledData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('downPaymentCharges') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{runningFinanceError?.downPaymentCharges}</small>
                                </div>
                            </div>
                        }
                    </div> */}

                    <div className="Form__Header">
                        <h1>DEFINE REPAYMENT SEQUENCE </h1>
                    </div>
                    <div className="Centre_Aligned_Buttons" onDragOver={handleDragOver}>
                        {buttons?.map((button) => (
                            <Button
                                key={button.label}
                                className="DraggableButton Light_Button"
                                onClick={(e) => e.preventDefault()}
                                label={`${button.label}`}
                                disabled
                                // draggable
                                // onDragStart={(e) => handleDragStart(e, button.label)}
                                // onDrop={(e) => handleDrop(e, button.label)}
                            />
                        ))}
                    </div>
                    {/* <div className="grid">
                        <div className="col-12">
                            <div className="card mt-6">
                                <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={runningFinanceTableData}>
                                    <Column field="noOfDays" header="No. Of Days" />
                                    <Column field="markupValue" header="markup value" />
                                    <Column field="apr" header="APR" />
                                    <Column field="status" header="Status" />
                                    <Column header="Actions" body={actionBodyTemplate} style={{ textAlign: 'center' }} className="Table__ActionHeading__CentredAlign" />
                                </DataTable>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} style={{ margin: '20px' }} onClick={() => handledAdd()} label={lendingData?.currentStep > 2 ? 'Edit' : 'Save'} className="Btn__Dark" />
                    </div> */}
                </form>
            </div>
        </>
    );
}
export default ViewRunningFinanceChecker;

import React from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

const EditAddress = ({ onAccountAddressChange, cityList, formikValuees, onHide }) => {

    const validationSchema = Yup.object().shape({
        fullAddress: Yup.string().required('This Field is required.'),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            fullAddress: formikValuees?.fullAddress ? formikValuees?.fullAddress : "",
            cityId: formikValuees?.cityId ? formikValuees?.cityId : "",
        },

        onSubmit: async () => {
            onAccountAddressChange({ fullAddress: formik.values.fullAddress });
            onHide();
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className='Card__Round'>

                    <div className="p-fluid p-formgrid grid">
                        <div className="p-field col-12">
                            <div className='mb-4'>
                                <label className='Label__Text'>
                                    Address 1
                                </label>
                                <InputText
                                    id="fullAddress"
                                    name="fullAddress"
                                    value={formik.values.fullAddress}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('fullAddress') }, 'Input__Round')}
                                />
                                {getFormErrorMessage("fullAddress")}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid">
                        <div className="p-field col-12">
                            <div className='mb-4'>
                                <label className='Label__Text'>
                                    Address 2
                                </label>
                                <InputText
                                    value="-"
                                    className='Input__Round'
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <div className='Down__Btn mt-4'>
                        <Button label="Confirm" iconPos="right" className="Btn__Dark" type="submit" />
                        <Button label="Cancel" iconPos="right" className="Btn__cancel" type="button" onClick={onHide} />
                    </div>

                </div>
            </form>
        </>
    )
}

export default EditAddress
import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useDispatch } from 'react-redux';
import { handleGetRequest } from '../../../services/GetTemplate';

function ProductCatalogEdit() {
    const [listOfTransaction, setListOfTransaction] = useState([]);
    const location = useLocation();
    const dispatch = useDispatch();

    const { rowData } = location.state || {};
    console.log('row data', rowData);

    const transactionListId =
        rowData &&
        rowData.tblProductCatalogDetails.map((tblid) => {
            return {
                lovId: tblid.tblProduct.productId.toString()
            };
        });
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            productCatalogId: '',
            productCatalogDescription: '',
            transactionList: []
        },
        onSubmit: async (data) => {
            const arrayOfTransactionIds = formik.values.transactionList.map((item) => item?.lovId);
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        productCatalogId: data.productCatalogId,
                        productCatalogDescription: data.productCatalogDescription,
                        transactionList: arrayOfTransactionIds
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            //update product catalog API call
            await dispatch(handlePostRequest(newData, '/productcatalog/v1/products/updateproduct', true, false, 'productcatalog'));
        }
    });
    console.log('test', transactionListId.length);

    const mapValues = () => {
        formik.setFieldValue('productCatalogId', rowData?.productCatalogId || '');
        formik.setFieldValue('productCatalogDescription', rowData?.description || '');
        formik.setFieldValue('transactionList', transactionListId);
    };
    const panelFooterTemplate = () => {
        const length = formik.values.transactionList ? formik.values.transactionList.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };
    //list of transactions API call
    const getTransactionListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setListOfTransaction(res?.payLoad);
    };
    useEffect(() => {
        mapValues();
        getTransactionListData();
    }, []);

    console.log('formik.values.transactionList', formik.values.transactionList);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>Edit Detail</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productCatalogName" className="Label__Text">
                                    Name<span className="Label__Required">*</span>
                                </label>
                                <InputText id="productCatalogName" name="productCatalogName" placeholder={rowData?.productCatalogName} className="Input__Round" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productCatalogDescription" className="Label__Text">
                                    Description<span className="Label__Required">*</span>
                                </label>
                                <InputText id="productCatalogDescription" name="productCatalogDescription" value={formik.values.productCatalogDescription} onChange={formik.handleChange} className="Input__Round" maxLength={500} />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="transactionList" className="Label__Text">
                                    List of transactions<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="transactionList"
                                    placeholder="Select Transaction List"
                                    options={listOfTransaction}
                                    optionLabel="name"
                                    name="transactionList"
                                    optionValue="lovId"
                                    filter
                                    panelFooterTemplate={panelFooterTemplate}
                                    value={formik.values.transactionList.map((trans) => trans.lovId)}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            'transactionList',
                                            e.value.map((lovId) => ({
                                                lovId
                                            }))
                                        );
                                    }}
                                    className="Dropdown__Round"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Update" className="Btn__Dark" disabled={formik.values.productCatalogDescription == '' || formik.values.transactionList == ''} />
                        <Button onClick={() => navigate('/productcatalog')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default ProductCatalogEdit;

import React, { useEffect, useState } from 'react'
import { Divider } from 'primereact/divider'
import { InputText } from 'primereact/inputtext'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { handlePostRequest } from '../../../../services/PostTemplate'
import { useDispatch } from 'react-redux'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

const ViewTransactions = () => {

    const [ledgerDetails, setLedgerDetails] = useState([])

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const transactionData = location?.state?.rowData;

    // Go back to the previous page
    const goBack = () => {
        navigate(-1);
    };

    const getLedgerDetails = async () => {

        const transHeadId = {
            "data": {
                "security": {
                    "userName": "",
                    "password": "",
                    "securityToken": ""
                },
                "account": {
                    "msidn": "",
                    "iban": "",
                    "bban": "",
                    "pan": "",
                    "currency": ""
                },
                "channel": "",
                "terminal": "",
                "reterivalReferenceNumber": "",
                "payLoad": {
                    "transHeadId": transactionData?.transHeadId
                },
                "additionalInformation": [
                    {
                        "infoKey": "",
                        "infoValue": ""
                    }
                ],
                "checkSum": ""
            }
        }

        const res = await dispatch(handlePostRequest(transHeadId, '/transaction/v1/transactions/transactiondetail', true, true));
        if (res?.responseCode === "200101") {
            setLedgerDetails(res?.payLoad);
        }

    }

    useEffect(() => {
        getLedgerDetails()
    }, []);

    return (
        <>
            <div className='card Card__Round'>
                <div className='pt-2'>
                    <h6 className='secondary-color'><b>VIEW DETAIL</b></h6>
                </div>

                <Divider />

                <div className="p-fluid p-formgrid grid mb-5">

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Transaction Head ID</label>
                            <InputText
                                value={transactionData?.transHeadId ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Product ID</label>
                            <InputText
                                value={transactionData?.productId ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Product Name</label>
                            <InputText
                                value={transactionData?.productName ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                </div>

                <div className='hra_head'>
                    <h5 className="secondary-color">
                        <b>DEBIT PARTY DETAIL</b>
                    </h5>
                </div>

                <div className="p-fluid p-formgrid grid mb-5">

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">DR Account Number</label>
                            <InputText
                                value={transactionData?.senderAccountNumber ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">DR Account Title</label>
                            <InputText
                                value={transactionData?.senderAccountTitle ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">DR Account CNIC</label>
                            <InputText
                                value={transactionData?.senderCnic ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">DR Bank ID</label>
                            <InputText
                                value="-"
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">DR Bank Name</label>
                            <InputText
                                value={transactionData?.senderBankName ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">DR Account Type</label>
                            <InputText
                                value={transactionData?.senderAccountType ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                </div>

                <div className='hra_head'>
                    <h5 className="secondary-color">
                        <b>CREDIT PARTY DETAIL</b>
                    </h5>
                </div>

                <div className="p-fluid p-formgrid grid mb-5">

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">CR Account Number</label>
                            <InputText
                                value={transactionData?.receiverAccountNumber ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">CR Account Title</label>
                            <InputText
                                value={transactionData?.receiverAccountTitle ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">CR Account CNIC</label>
                            <InputText
                                value={transactionData?.receiverCnic ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">CR Bank ID</label>
                            <InputText
                                value="-"
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">CR Bank Name</label>
                            <InputText
                                value={transactionData?.receiverBankName ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Created By</label>
                            <InputText
                                value={transactionData?.createdBy ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Last Updated User</label>
                            <InputText
                                value={transactionData?.updatedBy ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Last Updated Date</label>
                            <InputText
                                value={transactionData?.lastUpdateDate ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Debit Card No.</label>
                            <InputText
                                value={transactionData?.debitCardNumber ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                </div>

                <div className='hra_head'>
                    <h5 className="secondary-color">
                        <b>FEE AND COMMISSION</b>
                    </h5>
                </div>

                <div className="p-fluid p-formgrid grid mb-5">

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">FED</label>
                            <InputText
                                value={transactionData?.fed ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">WHT</label>
                            <InputText
                                value={transactionData?.wht ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">FEE</label>
                            <InputText
                                value={transactionData?.fee ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Commission Amount</label>
                            <InputText
                                value={transactionData?.commissionAmount ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Total Amount</label>
                            <InputText
                                value={transactionData?.totalAmount ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Transaction Amount</label>
                            <InputText
                                value={transactionData?.transactionAmount ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Transaction Code</label>
                            <InputText
                                value={transactionData?.transactionId ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">STAN</label>
                            <InputText
                                value={transactionData?.stan ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">RRN</label>
                            <InputText
                                value={transactionData?.rrn ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Card Acceptor Terminal ID</label>
                            <InputText
                                value={transactionData?.cardAcceptorTerminalId ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Card Acceptor Terminal Location</label>
                            <InputText
                                value={transactionData?.cardAcceptorNameLocation ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Utility Consumer Code</label>
                            <InputText
                                value={transactionData?.utilityConsumerCode ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Utility Consumer Number</label>
                            <InputText
                                value={transactionData?.utilityConsumerNumber ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">DR Agent Name</label>
                            <InputText
                                value={transactionData?.senderAgentName ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">CR Agent Name</label>
                            <InputText
                                value={transactionData?.receiverAgentName ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Channel Name</label>
                            <InputText
                                value={transactionData?.channelName ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Currency ID</label>
                            <InputText
                                value={transactionData?.currency ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Transaction Date</label>
                            <InputText
                                value={transactionData?.transactionDate ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Transaction Status</label>
                            <InputText
                                value={transactionData?.transSatusName ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Transaction Inquiry ID</label>
                            <InputText
                                value={transactionData?.transInquiryId ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Terminal ID</label>
                            <InputText
                                value={transactionData?.terminalId ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Trans Reversal ID</label>
                            <InputText
                                value={transactionData?.transReversalId ?? ""}
                                className='Input__Round'
                                readOnly
                            />
                        </div>
                    </div>

                </div>

                <div className='hra_head'>
                    <h5 className="secondary-color">
                        <b>Ledger Details</b>
                    </h5>
                </div>

                <div className="card p-datatable-sm custom-border">
                    <div className='custom-datatable'>
                        <DataTable
                            // header={header}
                            value={ledgerDetails}
                            emptyMessage="No data found."
                            scrollable
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            showGridlines
                        >
                            <Column field="accountTitle" header="Account Title" />
                            <Column field="accountNo" header="Account No." />
                            <Column field="accountType" header="Account Type" />
                            <Column field="amountCr" header="Credit Amount" />
                            <Column field="amountDr" header="Debit Amount" />
                            <Column field="openingBalance" header="Opening Balance" />
                            <Column field="closingBalance" header="Closing Balance" />
                            <Column field="transType" header="transaction Type" />
                        </DataTable>
                    </div>
                </div>

                <div className="Down__Btn mt-6 pt-2">
                    <Button label="Ok" iconPos="right" className="Btn__Dark" onClick={goBack} />
                </div>

            </div>
        </>
    )
}

export default ViewTransactions
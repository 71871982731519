import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../../services/PostTemplate';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handleGetRequest } from '../../../services/GetTemplate';
import * as Yup from 'yup';

function EditCRPModel() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');
    const [factorRiskData, setFactorRiskData] = useState([]);

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const crpModelId = query.get('crpModelId');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getDatabyId = async () => {
        const resp = await handleGetRequest(`/riskprofile/v1/riskprofile/model/get/${crpModelId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            formik.setFieldValue('crpModelCode', keyData?.crpModelCode);
            formik.setFieldValue('crpModelName', keyData?.crpModelName);
            formik.setFieldValue('crpModelDescription', keyData?.crpModelDescr);

            const CTDropdownData = keyData?.tblRpRiskFactors;

            setFactorRiskData(CTDropdownData);

            if (CTDropdownData) {
                CTDropdownData.forEach((value) => {
                    // Customer Type

                    // Get Section Weigtage Customer Type
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'CU') {
                        formik.setFieldValue('customerType', value?.weigtage);
                    }

                    // Get Customer Type Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'CT') {
                        formik.setFieldValue('customerTypeSubSection', value?.riskFactorScore);
                        formik.setFieldValue('customerTypeRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Occupation Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'OC') {
                        formik.setFieldValue('occupationSubSection', value?.riskFactorScore);
                        formik.setFieldValue('occupationRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Is Beneficial Owner Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'IB') {
                        formik.setFieldValue('beneficialCustomerSubSection', value?.riskFactorScore);
                        formik.setFieldValue('beneficialCustomerRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Source of Funds Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'SF') {
                        formik.setFieldValue('sourceOfFundSubSection', value?.riskFactorScore);
                        formik.setFieldValue('sourceOfFundRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Is adverse media Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'IS') {
                        formik.setFieldValue('adverseMediaSubSection', value?.riskFactorScore);
                        formik.setFieldValue('adverseMediaRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Purpose of A/C Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'PA') {
                        formik.setFieldValue('purposeOfAcSubSection', value?.riskFactorScore);
                        formik.setFieldValue('purposeOfAcRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Is Customer the director Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'CCT') {
                        formik.setFieldValue('customerTheDirectorSubSection', value?.riskFactorScore);
                        formik.setFieldValue('customerTheDirectorRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Other Nationalities/residencies Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'ON') {
                        formik.setFieldValue('nationalitiesSubSection', value?.riskFactorScore);
                        formik.setFieldValue('nationalitiesRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Is customer is PEP? Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'PEP') {
                        formik.setFieldValue('customerPepSubSection', value?.riskFactorScore);
                        formik.setFieldValue('customerPepRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Product And Services Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'PS') {
                        formik.setFieldValue('productsAndServicesSubSection', value?.riskFactorScore);
                        formik.setFieldValue('productsAndServices', value?.weigtage);
                        formik.setFieldValue('productAndServicesRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Channels Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'CH') {
                        formik.setFieldValue('channelSubSection', value?.riskFactorScore);
                        formik.setFieldValue('channel', value?.weigtage);
                        formik.setFieldValue('channelRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Geographies (Both Local and International
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'GE') {
                        formik.setFieldValue('geographies', value?.weigtage);
                    }

                    // Get Trading /counter parties Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'LC') {
                        formik.setFieldValue('tradingRiskFactoryType', value?.riskFactorScore);
                        formik.setFieldValue('isActivetradingRisk', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Permanent Address Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'PTA') {
                        formik.setFieldValue('permanentAddressFactoryType', value?.riskFactorScore);
                        formik.setFieldValue('isActivepermanentAddress', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Mailing Address/geo-location Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'MA') {
                        formik.setFieldValue('mailingAddressFactoryType', value?.riskFactorScore);
                        formik.setFieldValue('isActivemailingAddress', value?.overrideRule === 'N' ? false : true);
                    }

                    // Anticipated Transactions Profile
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'ATP') {
                        formik.setFieldValue('transctionProfileWeigtage', value?.weigtage);
                    }

                    // Get Expected aggregate Debit amount per Month Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'DM') {
                        formik.setFieldValue('debitAmountSubSection', value?.riskFactorScore);
                        formik.setFieldValue('aggregateDebitAmountRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Expected aggregate Credit amount per Month Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'CM') {
                        formik.setFieldValue('creditAmountSubSection', value?.riskFactorScore);
                        formik.setFieldValue('aggregateCreditAmountRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Expected Debit count per Month Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'DC') {
                        formik.setFieldValue('debitCountSubSection', value?.riskFactorScore);
                        formik.setFieldValue('aggregateDebitCountRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }

                    // Get Expected Credit Count per Month Data
                    if (value?.lkpRpFactorRisk?.riskFactorCode === 'CC') {
                        formik.setFieldValue('creditCountSubSection', value?.riskFactorScore);
                        formik.setFieldValue('aggregateCreditCountRiskOverRideRule', value?.overrideRule === 'N' ? false : true);
                    }
                });
            }
            // formik.setFieldValue('salaryStatus', keyData?.salaryStatus === 'Y' ? true : false);
        }
    };

    useEffect(() => {
        getDatabyId();
    }, []); // eslint-disable-line

    const riskCategoryCriteria = [
        {
            riskScore: '>0 & <= 5',
            rating: 'low',
            inTerms: 'Less than or Equal to 50%'
        },
        {
            riskScore: '>5 & <= 7',
            rating: 'medium',
            inTerms: 'Above 50% Less than or Equal to 70%'
        },
        {
            riskScore: '>7 & <= 10',
            rating: 'high',
            inTerms: 'Above 70%'
        }
    ];
    const validationSchema = Yup.object().shape({
        customerTypeSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        occupationSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        beneficialCustomerSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        sourceOfFundSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        purposeOfAcSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        adverseMediaSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        customerTheDirectorSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        nationalitiesSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        customerPepSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        customerType: Yup.string().required('This field is required'),
        productsAndServicesSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        productsAndServices: Yup.string().required('This field is required'),
        channelSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        channel: Yup.string().required('This field is required'),
        tradingRiskFactoryType: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        mailingAddressFactoryType: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        permanentAddressFactoryType: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        geographies: Yup.string().required('This field is required'),
        debitAmountSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        creditAmountSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        debitCountSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable(),
        creditCountSubSection: Yup.string()
            .required('This field is required')
            .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                if (!value) {
                    return false;
                }
                const numericValue = parseFloat(value);
                return numericValue > 0;
            })
            .nullable()
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            crpModelCode: '',
            crpModelName: '',
            crpModelDescription: '',
            customerTypeSubSection: '',
            occupationSubSection: '',
            beneficialCustomerSubSection: '',
            sourceOfFundSubSection: '',
            purposeOfAcSubSection: '',
            adverseMediaSubSection: '',
            customerTheDirectorSubSection: '',
            nationalitiesSubSection: '',
            customerPepSubSection: '',
            customerType: '',
            customerTypeRiskOverRideRule: false,
            beneficialCustomerRiskOverRideRule: false,
            occupationRiskOverRideRule: false,
            sourceOfFundRiskOverRideRule: false,
            purposeOfAcRiskOverRideRule: false,
            adverseMediaRiskOverRideRule: false,
            customerTheDirectorRiskOverRideRule: false,
            nationalitiesRiskOverRideRule: false,
            customerPepRiskOverRideRule: false,
            productsAndServicesSubSection: '',
            productAndServicesRiskOverRideRule: false,
            productsAndServices: '',
            channel: '',
            channelSubSection: '',
            channelRiskOverRideRule: false,
            tradingRiskFactoryType: '',
            mailingAddressFactoryType: '',
            permanentAddressFactoryType: '',
            geographies: '',
            isActivetradingRisk: false,
            isActivepermanentAddress: false,
            isActivemailingAddress: false,
            debitAmountSubSection: '',
            creditAmountSubSection: '',
            debitCountSubSection: '',
            creditCountSubSection: '',
            transctionProfileWeigtage: '',
            aggregateDebitAmountRiskOverRideRule: false,
            aggregateCreditAmountRiskOverRideRule: false,
            aggregateDebitCountRiskOverRideRule: false,
            aggregateCreditCountRiskOverRideRule: false
        },
        validate: (data) => {
            let errors = {};
            if (!/^[a-zA-Z0-9_-]+$/.test(data?.crpModelCode)) {
                errors.crpModelCode = 'Invalid Input';
            }
            if (data?.customerType > 100 || data?.customerType == 0) {
                errors.customerType = 'Value must be less than or equal to 100';
            }
            if (data?.productsAndServices > 100 || data?.productsAndServices == 0) {
                errors.productsAndServices = 'Value must be less than or equal to 100';
            }
            if (data?.channel > 100 || data?.channel == 0) {
                errors.channel = 'Value must be less than or equal to 100';
            }
            if (data?.geographies > 100 || data?.geographies == 0) {
                errors.geographies = 'Value must be less than or equal to 100';
            }
            if (data?.transctionProfileWeigtage > 100 || data?.transctionProfileWeigtage == 0) {
                errors.transctionProfileWeigtage = 'Value must be less than or equal to 100';
            }
            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        crpModelId: crpModelId,
                        crpModelName: data['crpModelName'],
                        crpModelCode: data['crpModelCode'],
                        crpModelDescription: data['crpModelDescription'],
                        rpFactorDetailRequestList: [
                            {
                                riskFactorTypeId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'CU')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                subSectionWeightage: sumOfCustomerTypeFields?.toFixed(4),
                                total: data['customerType'],
                                riskFactorCode: 'CU'
                            },
                            {
                                riskFactorTypeId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'CT')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                subSectionWeightage: data['customerTypeSubSection'],
                                riskOverRideRule: data['customerTypeRiskOverRideRule'],
                                parentId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'CU')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                riskFactorCode: 'CT'
                            },
                            {
                                riskFactorTypeId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'OC')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                subSectionWeightage: data['occupationSubSection'],
                                riskOverRideRule: data['occupationRiskOverRideRule'],
                                parentId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'CU')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                riskFactorCode: 'OC'
                            },
                            {
                                riskFactorTypeId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'IB')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                subSectionWeightage: data['beneficialCustomerSubSection'],
                                riskOverRideRule: data['beneficialCustomerRiskOverRideRule'],
                                parentId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'CU')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                riskFactorCode: 'IB'
                            },
                            {
                                riskFactorTypeId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'SF')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                subSectionWeightage: data['sourceOfFundSubSection'],
                                riskOverRideRule: data['sourceOfFundRiskOverRideRule'],
                                parentId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'CU')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                riskFactorCode: 'SF'
                            },
                            {
                                riskFactorTypeId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'PA')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                subSectionWeightage: data['purposeOfAcSubSection'],
                                riskOverRideRule: data['purposeOfAcRiskOverRideRule'],
                                parentId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'CU')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                riskFactorCode: 'PA'
                            },
                            {
                                riskFactorTypeId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'IS')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                subSectionWeightage: data['adverseMediaSubSection'],
                                riskOverRideRule: data['adverseMediaRiskOverRideRule'],
                                parentId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'CU')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                riskFactorCode: 'IS'
                            },
                            {
                                riskFactorTypeId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'CCT')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                subSectionWeightage: data['customerTheDirectorSubSection'],
                                riskOverRideRule: data['customerTheDirectorRiskOverRideRule'],
                                parentId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'CU')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                riskFactorCode: 'CCT'
                            },
                            {
                                riskFactorTypeId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'ON')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                subSectionWeightage: data['nationalitiesSubSection'],
                                riskOverRideRule: data['nationalitiesRiskOverRideRule'],
                                parentId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'CU')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                riskFactorCode: 'ON'
                            },
                            {
                                riskFactorTypeId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'PEP')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                subSectionWeightage: data['customerPepSubSection'],
                                riskOverRideRule: data['customerPepRiskOverRideRule'],
                                parentId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'CU')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                riskFactorCode: 'PEP'
                            },
                            {
                                riskFactorTypeId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'PS')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                subSectionWeightage: data['productsAndServicesSubSection'],
                                riskOverRideRule: data['productAndServicesRiskOverRideRule'],
                                total: data['productsAndServices'],
                                riskFactorCode: 'PS'
                            },
                            {
                                riskFactorTypeId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'CH')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                subSectionWeightage: data['channelSubSection'],
                                riskOverRideRule: data['channelRiskOverRideRule'],
                                total: data['channel'],
                                riskFactorCode: 'CH'
                            },
                            {
                                riskFactorTypeId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'GE')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                subSectionWeightage: sumOfGeographies?.toFixed(4),
                                total: data['geographies'],
                                riskFactorCode: 'GE'
                            },
                            {
                                riskFactorTypeId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'LC')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                subSectionWeightage: data['tradingRiskFactoryType'],
                                riskOverRideRule: data['isActivetradingRisk'],
                                parentId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'GE')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                riskFactorCode: 'LC'
                            },
                            {
                                riskFactorTypeId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'PTA')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                subSectionWeightage: data['permanentAddressFactoryType'],
                                riskOverRideRule: data['isActivepermanentAddress'],
                                parentId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'GE')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                riskFactorCode: 'PTA'
                            },
                            {
                                riskFactorTypeId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'MA')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                subSectionWeightage: data['mailingAddressFactoryType'],
                                riskOverRideRule: data['isActivemailingAddress'],
                                parentId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'GE')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                riskFactorCode: 'MA'
                            },
                            {
                                riskFactorTypeId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'ATP')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                subSectionWeightage: sumOfTransactionProfile?.toFixed(4),
                                total: data['transctionProfileWeigtage'],
                                riskFactorCode: 'ATP'
                            },
                            {
                                riskFactorTypeId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'DM')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                subSectionWeightage: data['debitAmountSubSection'],
                                riskOverRideRule: data['aggregateDebitAmountRiskOverRideRule'],
                                parentId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'ATP')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                riskFactorCode: 'DM'
                            },
                            {
                                riskFactorTypeId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'CM')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                subSectionWeightage: data['creditAmountSubSection'],
                                riskOverRideRule: data['aggregateCreditAmountRiskOverRideRule'],
                                parentId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'ATP')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                riskFactorCode: 'CM'
                            },
                            {
                                riskFactorTypeId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'DC')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                subSectionWeightage: data['debitCountSubSection'],
                                riskOverRideRule: data['aggregateDebitCountRiskOverRideRule'],
                                parentId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'ATP')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                riskFactorCode: 'DC'
                            },
                            {
                                riskFactorTypeId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'CC')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                subSectionWeightage: data['creditCountSubSection'],
                                riskOverRideRule: data['aggregateCreditCountRiskOverRideRule'],
                                parentId: factorRiskData
                                    ?.filter((value) => value?.lkpRpFactorRisk?.riskFactorCode === 'ATP')
                                    ?.map((value) => value?.rpRiskFactorId)
                                    ?.toString(),
                                riskFactorCode: 'CC'
                            }
                        ]
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/riskprofile/v1/crpmodel/updatecrp', true, false, 'crpmodel'));
            if (res?.responseCode === '170000') {
                formik.resetForm();
            }

            setloading(false);
            setloadingIcon('');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const sumOfCustomerTypeFields =
        parseFloat(formik?.values?.customerTypeSubSection) +
        parseFloat(formik?.values?.occupationSubSection) +
        parseFloat(formik?.values?.beneficialCustomerSubSection) +
        parseFloat(formik?.values?.sourceOfFundSubSection) +
        parseFloat(formik?.values?.purposeOfAcSubSection) +
        parseFloat(formik?.values?.adverseMediaSubSection) +
        parseFloat(formik?.values?.customerPepSubSection) +
        parseFloat(formik?.values?.nationalitiesSubSection) +
        parseFloat(formik?.values?.customerTheDirectorSubSection);

    const sumOfGeographies = parseFloat(formik?.values?.tradingRiskFactoryType) + parseFloat(formik?.values?.permanentAddressFactoryType) + parseFloat(formik?.values?.mailingAddressFactoryType);

    const sumOfTransactionProfile = parseFloat(formik?.values?.debitAmountSubSection) + parseFloat(formik?.values?.creditAmountSubSection) + parseFloat(formik?.values?.debitCountSubSection) + parseFloat(formik?.values?.creditCountSubSection);
    const productsAndServicesSubSection = parseFloat(formik?.values?.productsAndServicesSubSection || 0);
    const channelSubSection = parseFloat(formik?.values?.channelSubSection || 0);

    const totalOfAllFields = sumOfCustomerTypeFields + sumOfGeographies + sumOfTransactionProfile + productsAndServicesSubSection + channelSubSection;

    const sumOfSectionWeigtage =
        parseFloat(formik?.values?.customerType || 0) +
        parseFloat(formik?.values?.productsAndServices || 0) +
        parseFloat(formik?.values?.channel || 0) +
        parseFloat(formik?.values?.geographies || 0) +
        parseFloat(formik?.values?.transctionProfileWeigtage || 0);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>EDIT DETAIL</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="crpModelCode" className={classNames({ 'p-error': isFormFieldValid('crpModelCode') }, 'Label__Text')}>
                                    CRP Model Code
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="crpModelCode"
                                    placeholder="Enter CRP Code"
                                    name="crpModelCode"
                                    maxLength={20}
                                    value={formik?.values?.crpModelCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('crpModelCode') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('crpModelCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="crpModelName" className={classNames({ 'p-error': isFormFieldValid('crpModelName') }, 'Label__Text')}>
                                    CRP Model Name
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="crpModelName"
                                    placeholder="Enter Name"
                                    name="crpModelName"
                                    maxLength={50}
                                    value={formik?.values?.crpModelName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('crpModelName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('crpModelName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="crpModelDescription" className={classNames({ 'p-error': isFormFieldValid('crpModelDescription') }, 'Label__Text')}>
                                    Description
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    autoResize
                                    rows={5}
                                    cols={30}
                                    maxLength={200}
                                    id="crpModelDescription"
                                    name="crpModelDescription"
                                    value={formik?.values?.crpModelDescription || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('crpModelDescription') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('crpModelDescription')}
                            </div>
                        </div>
                    </div>

                    {/* Customer Type */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h2 style={{ color: '#fff', marginLeft: '10px' }}>CUSTOMER TYPE</h2>
                    </div>
                    <div className="Crp__Model__Fields" style={{ background: '#f8f8f8' }}>
                        <div className="grid Crp__Model__Heading">
                            <div className="col-3">
                                <p className="heading___">RISK FACTOR TYPE</p>
                            </div>
                            <div className="col-3">
                                <p className="heading___">SUB SECTION WEIGHTAGE</p>
                            </div>
                            <div className="col-3">
                                <p className="heading___">SECTION WEIGHTAGE</p>
                            </div>
                            <div className="col-3">
                                <p className="heading___">RISK OVER-RIDE RULE</p>
                            </div>
                        </div>
                        <div className="grid nested-grid">
                            <div className="col-6">
                                <div className="grid">
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Customer Type`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="customerTypeSubSection"
                                                    placeholder="Enter Description"
                                                    name="customerTypeSubSection"
                                                    maxLength={6}
                                                    value={formik?.values?.customerTypeSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('customerTypeSubSection') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('customerTypeSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-3 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Occupation`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="occupationSubSection"
                                                    placeholder="Enter Description"
                                                    name="occupationSubSection"
                                                    maxLength={6}
                                                    value={formik?.values?.occupationSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('occupationSubSection') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('occupationSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Is Beneficial owner other than customer (yes / no)`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="beneficialCustomerSubSection"
                                                    placeholder="Enter Description"
                                                    name="beneficialCustomerSubSection"
                                                    maxLength={6}
                                                    value={formik?.values?.beneficialCustomerSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('beneficialCustomerSubSection') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('beneficialCustomerSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Source of funds`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="sourceOfFundSubSection"
                                                    placeholder="Enter Description"
                                                    name="sourceOfFundSubSection"
                                                    maxLength={6}
                                                    value={formik?.values?.sourceOfFundSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('sourceOfFundSubSection') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('sourceOfFundSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Purpose of A/C`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="purposeOfAcSubSection"
                                                    placeholder="Enter Description"
                                                    name="purposeOfAcSubSection"
                                                    maxLength={6}
                                                    value={formik?.values?.purposeOfAcSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('purposeOfAcSubSection') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('purposeOfAcSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Is Adverse media /sanction list match/ internal risk assessment/LEAs inquiry found? (yes or no) `}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="adverseMediaSubSection"
                                                    placeholder="Enter Description"
                                                    name="adverseMediaSubSection"
                                                    maxLength={6}
                                                    value={formik?.values?.adverseMediaSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('adverseMediaSubSection') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('adverseMediaSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">
                                                    {`Is customer  the director/ member of governing body/ trustee of NGO/ NPO/ charities/ clubs/ trusts/ societies/ Associations/ Exchange Co/ other high risk segment (yes / no)`}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="customerTheDirectorSubSection"
                                                    placeholder="Enter Description"
                                                    name="customerTheDirectorSubSection"
                                                    maxLength={6}
                                                    value={formik?.values?.customerTheDirectorSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('customerTheDirectorSubSection') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('customerTheDirectorSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Other nationalities/ residencies (yes / no)`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="nationalitiesSubSection"
                                                    placeholder="Enter Description"
                                                    name="nationalitiesSubSection"
                                                    maxLength={6}
                                                    value={formik?.values?.nationalitiesSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('nationalitiesSubSection') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('nationalitiesSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Is customer a PEP  (if PEP selected as Yes then LOV  selection is mandatory)`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="customerPepSubSection"
                                                    placeholder="Enter Description"
                                                    name="customerPepSubSection"
                                                    maxLength={6}
                                                    value={formik?.values?.customerPepSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('customerPepSubSection') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('customerPepSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                    <div className="p-field">
                                        <InputTextarea
                                            keyfilter={/^[0-9]+$/}
                                            maxLength={500}
                                            rows={32}
                                            cols={30}
                                            id="customerType"
                                            name="customerType"
                                            autoResize
                                            value={formik?.values?.customerType || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('customerType') }, 'TextArea__Round Crp__Model__Textarea_Size')}
                                        />

                                        {getFormErrorMessage('customerType')}
                                    </div>
                                </div>
                            </div>
                            <div className="col-3 ">
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="customerTypeRiskOverRideRule"
                                                name="customerTypeRiskOverRideRule"
                                                value={formik.values.customerTypeRiskOverRideRule}
                                                checked={formik.values.customerTypeRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="customerTypeRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('customerTypeRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('customerTypeRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="occupationRiskOverRideRule"
                                                name="occupationRiskOverRideRule"
                                                value={formik.values.occupationRiskOverRideRule}
                                                checked={formik.values.occupationRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="occupationRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('occupationRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('occupationRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="beneficialCustomerRiskOverRideRule"
                                                name="beneficialCustomerRiskOverRideRule"
                                                value={formik.values.beneficialCustomerRiskOverRideRule}
                                                checked={formik.values.beneficialCustomerRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="beneficialCustomerRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('beneficialCustomerRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('beneficialCustomerRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="sourceOfFundRiskOverRideRule"
                                                name="sourceOfFundRiskOverRideRule"
                                                value={formik.values.sourceOfFundRiskOverRideRule}
                                                checked={formik.values.sourceOfFundRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="sourceOfFundRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('sourceOfFundRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('sourceOfFundRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="purposeOfAcRiskOverRideRule"
                                                name="purposeOfAcRiskOverRideRule"
                                                value={formik.values.purposeOfAcRiskOverRideRule}
                                                checked={formik.values.purposeOfAcRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="purposeOfAcRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('purposeOfAcRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('purposeOfAcRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="adverseMediaRiskOverRideRule"
                                                name="adverseMediaRiskOverRideRule"
                                                value={formik.values.adverseMediaRiskOverRideRule}
                                                checked={formik.values.adverseMediaRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="adverseMediaRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('adverseMediaRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('adverseMediaRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="customerTheDirectorRiskOverRideRule"
                                                name="customerTheDirectorRiskOverRideRule"
                                                value={formik.values.customerTheDirectorRiskOverRideRule}
                                                checked={formik.values.customerTheDirectorRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="customerTheDirectorRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('customerTheDirectorRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('customerTheDirectorRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="nationalitiesRiskOverRideRule"
                                                name="nationalitiesRiskOverRideRule"
                                                value={formik.values.nationalitiesRiskOverRideRule}
                                                checked={formik.values.nationalitiesRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="nationalitiesRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('nationalitiesRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('nationalitiesRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="customerPepRiskOverRideRule"
                                                name="customerPepRiskOverRideRule"
                                                value={formik.values.customerPepRiskOverRideRule}
                                                checked={formik.values.customerPepRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="customerPepRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('customerPepRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('customerPepRiskOverRideRule')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="col-12 flex">
                            <div className="col-3">
                                <h4>Total</h4>
                            </div>
                            <div className="col-3">
                                <h4>{isNaN(sumOfCustomerTypeFields) ? ' ' : sumOfCustomerTypeFields?.toFixed(4)}</h4>
                            </div>
                            <div className="col-6"></div>
                        </div>
                    </div>

                    {/* Prodcuts and Services */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h2 style={{ color: '#fff', marginLeft: '10px' }}>PRODUCTS AND SERVICES</h2>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5 Crp__Model__Fields" style={{ background: '#f8f8f8' }}>
                        <div className="p-field col-12 md:col-3 flex align-items-center justify-content-left">
                            <div className="p-field">
                                <p className="Label__Text">{`Products and Services`}</p>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                            <div className="p-field">
                                <InputText
                                    id="productsAndServicesSubSection"
                                    placeholder="Enter Description"
                                    name="productsAndServicesSubSection"
                                    maxLength={6}
                                    value={formik?.values?.productsAndServicesSubSection || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productsAndServicesSubSection') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('productsAndServicesSubSection')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                            <div className="p-field">
                                <InputTextarea
                                    keyfilter={/^[0-9]+$/}
                                    maxLength={500}
                                    rows={1}
                                    cols={30}
                                    id="productsAndServices"
                                    name="productsAndServices"
                                    autoResize
                                    value={formik?.values?.productsAndServices || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productsAndServices') }, 'TextArea__Round Crp__Model__Textarea')}
                                />

                                {getFormErrorMessage('productsAndServices')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                            <div className="p-field flex">
                                <div className="field-checkbox">
                                    <Checkbox
                                        inputId="productAndServicesRiskOverRideRule"
                                        name="productAndServicesRiskOverRideRule"
                                        value={formik.values.productAndServicesRiskOverRideRule}
                                        checked={formik.values.productAndServicesRiskOverRideRule}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <label htmlFor="productAndServicesRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('productAndServicesRiskOverRideRule') }, 'Label__Text')}>
                                    Yes
                                </label>
                                {getFormErrorMessage('productAndServicesRiskOverRideRule')}
                            </div>
                        </div>
                    </div>

                    {/* Channels */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h2 style={{ color: '#fff', marginLeft: '10px' }}>CHANNELS</h2>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5 Crp__Model__Fields" style={{ background: '#f8f8f8' }}>
                        <div className="p-field col-12 md:col-3 flex align-items-center justify-content-left">
                            <div className="p-field">
                                <p className="Label__Text">{`Channels`}</p>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                            <div className="p-field">
                                <InputText
                                    id="channelSubSection"
                                    placeholder="Enter Description"
                                    name="channelSubSection"
                                    maxLength={6}
                                    value={formik?.values?.channelSubSection || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelSubSection') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('channelSubSection')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                            <div className="p-field">
                                <InputTextarea
                                    keyfilter={/^[0-9]+$/}
                                    maxLength={500}
                                    rows={1}
                                    cols={30}
                                    id="channel"
                                    name="channel"
                                    autoResize
                                    value={formik?.values?.channel || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channel') }, 'TextArea__Round Crp__Model__Textarea')}
                                />

                                {getFormErrorMessage('channel')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                            <div className="p-field flex">
                                <div className="field-checkbox">
                                    <Checkbox inputId="channelRiskOverRideRule" name="channelRiskOverRideRule" value={formik.values.channelRiskOverRideRule} checked={formik.values.channelRiskOverRideRule} onChange={formik.handleChange} />
                                </div>
                                <label htmlFor="channelRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('channelRiskOverRideRule') }, 'Label__Text')}>
                                    Yes
                                </label>
                                {getFormErrorMessage('channelRiskOverRideRule')}
                            </div>
                        </div>
                    </div>

                    {/* Geographies both local and international */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h2 style={{ color: '#fff', marginLeft: '10px' }}>GEOGRAPHIES: BOTH LOCAL AND INTERNATIONAL</h2>
                    </div>
                    <div className="Crp__Model__Fields" style={{ background: '#f8f8f8' }}>
                        <div className="grid Crp__Model__Heading">
                            <div className="col-3">
                                <p className="heading___">RISK FACTOR TYPE</p>
                            </div>
                            <div className="col-3">
                                <p className="heading___">SUB SECTION WEIGHTAGE</p>
                            </div>
                            <div className="col-3">
                                <p className="heading___">SECTION WEIGHTAGE</p>
                            </div>
                            <div className="col-3">
                                <p className="heading___">RISK OBER RIDE RULE</p>
                            </div>
                        </div>
                        <div className="grid nested-grid" style={{ background: '#f8f8f8', width: '100%', margin: '0 auto' }}>
                            <div className="col-6">
                                <div className="grid">
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Trading / counter parties countries: Pakistan or other country`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="tradingRiskFactoryType"
                                                    placeholder="Enter Description"
                                                    name="tradingRiskFactoryType"
                                                    maxLength={6}
                                                    value={formik?.values?.tradingRiskFactoryType || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('tradingRiskFactoryType') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('tradingRiskFactoryType')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Permanent Address`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="permanentAddressFactoryType"
                                                    placeholder="Enter Description"
                                                    name="permanentAddressFactoryType"
                                                    maxLength={6}
                                                    value={formik?.values?.permanentAddressFactoryType || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('permanentAddressFactoryType') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('permanentAddressFactoryType')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Mailing Address/ Geo-Location`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="mailingAddressFactoryType"
                                                    placeholder="Enter Description"
                                                    name="mailingAddressFactoryType"
                                                    maxLength={6}
                                                    value={formik?.values?.mailingAddressFactoryType || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('mailingAddressFactoryType') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('mailingAddressFactoryType')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                    <div className="p-field">
                                        <InputTextarea
                                            keyfilter={/^[0-9]+$/}
                                            maxLength={3}
                                            rows={8}
                                            cols={30}
                                            id="geographies"
                                            name="geographies"
                                            autoResize
                                            value={formik?.values?.geographies || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('geographies') }, 'TextArea__Round Crp__Model__Textarea Crp__Model__Geographies__TextArea')}
                                        />

                                        {getFormErrorMessage('geographies')}
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox inputId="isActivetradingRisk" name="isActivetradingRisk" value={formik.values.isActivetradingRisk} checked={formik.values.isActivetradingRisk} onChange={formik.handleChange} />
                                        </div>
                                        <label htmlFor="isActivetradingRisk" className={classNames({ 'p-error': isFormFieldValid('isActivetradingRisk') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('isActivetradingRisk')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox inputId="isActivepermanentAddress" name="isActivepermanentAddress" value={formik.values.isActivepermanentAddress} checked={formik.values.isActivepermanentAddress} onChange={formik.handleChange} />
                                        </div>
                                        <label htmlFor="isActivepermanentAddress" className={classNames({ 'p-error': isFormFieldValid('isActivepermanentAddress') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('isActivepermanentAddress')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox inputId="isActivemailingAddress" name="isActivemailingAddress" value={formik.values.isActivemailingAddress} checked={formik.values.isActivemailingAddress} onChange={formik.handleChange} />
                                        </div>
                                        <label htmlFor="isActivemailingAddress" className={classNames({ 'p-error': isFormFieldValid('isActivemailingAddress') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('isActivemailingAddress')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="col-12 flex">
                            <div className="col-3">
                                <h4>Total</h4>
                            </div>
                            <div className="col-3">
                                <h4>{isNaN(sumOfGeographies) ? ' ' : sumOfGeographies?.toFixed(4)}</h4>
                            </div>
                            <div className="col-6"></div>
                        </div>
                    </div>

                    {/* Anticipated Transactions Profile */}

                    <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                        <h2 style={{ color: '#fff', marginLeft: '10px' }}>ANTICIPATED TRANSACTIONS PROFILE</h2>
                    </div>
                    <div className="Crp__Model__Fields" style={{ background: '#f8f8f8' }}>
                        <div className="grid Crp__Model__Heading">
                            <div className="col-3">
                                <p className="heading___">RISK FACTOR TYPE</p>
                            </div>
                            <div className="col-3">
                                <p className="heading___">SUB SECTION WEIGHTAGE</p>
                            </div>
                            <div className="col-3">
                                <p className="heading___">SECTION WEIGHTAGE</p>
                            </div>
                            <div className="col-3">
                                <p className="heading___">RISK OBER RIDE RULE</p>
                            </div>
                        </div>
                        <div className="grid nested-grid" style={{ background: '#f8f8f8', width: '100%', margin: '0 auto' }}>
                            <div className="col-6">
                                <div className="grid">
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Expected aggregate Debit amount per Month`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="debitAmountSubSection"
                                                    placeholder="Enter Description"
                                                    name="debitAmountSubSection"
                                                    maxLength={6}
                                                    value={formik?.values?.debitAmountSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('debitAmountSubSection') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('debitAmountSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Expected aggregate Credit amount per Month`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="creditAmountSubSection"
                                                    placeholder="Enter Description"
                                                    name="creditAmountSubSection"
                                                    maxLength={6}
                                                    value={formik?.values?.creditAmountSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('creditAmountSubSection') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('creditAmountSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Expected Debit Count per month`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="debitCountSubSection"
                                                    placeholder="Enter Description"
                                                    name="debitCountSubSection"
                                                    maxLength={6}
                                                    value={formik?.values?.debitCountSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('debitCountSubSection') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('debitCountSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 flex align-items-center justify-content-left">
                                            <div className="p-field">
                                                <p className="Label__Text">{`Expected Credit Count per Month`}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                            <div className="p-field">
                                                <InputText
                                                    id="creditCountSubSection"
                                                    placeholder="Enter Description"
                                                    name="creditCountSubSection"
                                                    maxLength={6}
                                                    value={formik?.values?.creditCountSubSection || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('creditCountSubSection') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('creditCountSubSection')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                    <div className="p-field">
                                        <InputTextarea
                                            keyfilter={/^[0-9]+$/}
                                            maxLength={3}
                                            rows={12}
                                            cols={30}
                                            id="transctionProfileWeigtage"
                                            name="transctionProfileWeigtage"
                                            autoResize
                                            value={formik?.values?.transctionProfileWeigtage || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('transctionProfileWeigtage') }, 'TextArea__Round Crp__Model__Textarea Crp__Model__Transactions__TextArea')}
                                        />

                                        {getFormErrorMessage('transctionProfileWeigtage')}
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="aggregateDebitAmountRiskOverRideRule"
                                                name="aggregateDebitAmountRiskOverRideRule"
                                                value={formik.values.aggregateDebitAmountRiskOverRideRule}
                                                checked={formik.values.aggregateDebitAmountRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="aggregateDebitAmountRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('aggregateDebitAmountRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('aggregateDebitAmountRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="aggregateCreditAmountRiskOverRideRule"
                                                name="aggregateCreditAmountRiskOverRideRule"
                                                value={formik.values.aggregateCreditAmountRiskOverRideRule}
                                                checked={formik.values.aggregateCreditAmountRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="aggregateCreditAmountRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('aggregateCreditAmountRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('aggregateCreditAmountRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="aggregateDebitCountRiskOverRideRule"
                                                name="aggregateDebitCountRiskOverRideRule"
                                                value={formik.values.aggregateDebitCountRiskOverRideRule}
                                                checked={formik.values.aggregateDebitCountRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="aggregateDebitCountRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('aggregateDebitCountRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('aggregateDebitCountRiskOverRideRule')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-3 pt-4 pb-5 flex justify-content-center">
                                    <div className="p-field flex">
                                        <div className="field-checkbox">
                                            <Checkbox
                                                inputId="aggregateCreditCountRiskOverRideRule"
                                                name="aggregateCreditCountRiskOverRideRule"
                                                value={formik.values.aggregateCreditCountRiskOverRideRule}
                                                checked={formik.values.aggregateCreditCountRiskOverRideRule}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <label htmlFor="aggregateCreditCountRiskOverRideRule" className={classNames({ 'p-error': isFormFieldValid('aggregateCreditCountRiskOverRideRule') }, 'Label__Text')}>
                                            Yes
                                        </label>
                                        {getFormErrorMessage('aggregateCreditCountRiskOverRideRule')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="col-12 flex">
                            <div className="col-3">
                                <h4>Total</h4>
                            </div>
                            <div className="col-3">
                                <h4>{isNaN(sumOfTransactionProfile) ? ' ' : sumOfTransactionProfile?.toFixed(4)}</h4>
                            </div>
                            <div className="col-6"></div>
                        </div>
                    </div>

                    {/* TOTAL RISK SCORE */}

                    <div className="Form__Header" style={{ background: '#979c9b', borderRadius: '5px', paddingTop: '12px' }}>
                        <div className="p-fluid p-formgrid grid w-full">
                            <div className="p-field col-12 md:col-3 flex align-items-center">
                                <h2 style={{ color: '#fff', margin: '0px 0px 0px 10px' }}>TOTAL RISK SCORE</h2>
                            </div>
                            <div className="p-field col-12 md:col-3 flex align-items-center">
                                <p style={{ color: 'white' }}>{totalOfAllFields || 0}</p>
                            </div>
                            <div className="p-field col-12 md:col-3 flex align-items-center">
                                <p style={{ color: 'white' }}>{sumOfSectionWeigtage + '%'}</p>
                            </div>
                            <div className="p-field col-12 md:col-3 flex align-items-center"></div>
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-3 flex align-items-center">
                        <h5 style={{ color: '#979c9b', margin: '0px 0px 0px 10px' }}>Risk Category Criteria</h5>
                    </div>

                    <div className="grid">
                        <div className="col-12">
                            <div className="card" style={{ background: '#f8f8f8' }}>
                                <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={riskCategoryCriteria}>
                                    <Column field="riskScore" header="Risk Score" style={{ width: '33%' }} />
                                    <Column field="rating" header="Rating" style={{ width: '33%' }} />
                                    <Column field="inTerms" header="InTerms of % of Risk Scores" style={{ width: '33%' }} />
                                </DataTable>
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button disabled={loading} icon={loadingIcon || ''} label="Update" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/crpmodel')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default EditCRPModel;

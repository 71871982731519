import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import classNames from 'classnames';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { loadingAction } from '../../../../redux/actions/loadingAction';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import DynamicKYCFields from '../DynamicKYC/DynamicKYCFields';
import ScrollMover from '../../../../components/ScrollMover';
import { Dialog } from 'primereact/dialog';
import RemarksDailog from './RemarksDailog';

function EditViewHandler({ apiData, isEditable }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [limitProfile, setLimitProfile] = useState([]);
    const [channelOption, setChannelOption] = useState([]);
    const [productCatalog, setProductCatalog] = useState([]);
    const [parent, setParent] = useState([]);
    const [kycFields, setKycFields] = useState([]);
    const [parentData, setParentData] = useState([]);
    const [apiParentId, setApiParentId] = useState('');
    const [searchBtnDisable, setSearchBtnDisable] = useState(true);
    const [tblKycSetDetails, setTblKycSetDetails] = useState([]);
    const [empIdData, setEmpIdData] = useState('');
    const [accountStatus, setAccountStatus] = useState();

    const [useCaseError, setUseCaseError] = useState({
        empId: null
    });
    const [show, setShow] = useState(false);
    const [showBtn, setShowBtn] = useState(true);

    //getting lovId and name from props
    const lovId = apiData?.lkpAccountClassification?.accountClassificationId;
    const name = apiData?.lkpAccountClassification?.accountClassificationName;

    const kycFixed = ['cnic', 'mobileNumber', 'cnicIssuanceDate'];

    //uref to clear the Parent data fields
    const input1 = useRef(null);
    const input2 = useRef(null);
    const input3 = useRef(null);
    const input4 = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const accountClassificationId = lovId;

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button type="button" onClick={handleDelete} tooltip="Delete" disabled tooltipOptions={{ position: 'top' }} icon="pi pi-trash" className="p-button-rounded p-button-danger" />
            </div>
        );
    };
    // const agentid = parent?.agentId;

    function getMaxRegexLength(regex) {
        // Remove leading and trailing slashes from the regular expression.
        const regexPattern = regex.toString().slice(1, -1);

        // Use a regular expression to find the maximum length.
        const lengthRegex = /\{(\d+)(,(\d+))?\}/g;
        let maxLength = 0;

        let match;
        while ((match = lengthRegex.exec(regexPattern)) !== null) {
            const min = parseInt(match[1], 10);
            const max = match[3] ? parseInt(match[3], 10) : min;
            if (max > maxLength) {
                maxLength = max;
            }
        }

        return maxLength;
    }

    //validations
    const validationSchema = Yup.object({
        transLimitId: Yup.string().required('This Field is required'),
        channelIdList: Yup.array().required('This Field is required').min(1, 'This Field is required'),
        empId: Yup.string().required('This Field is required'),
        hierarchyName: Yup.string().required('This Field is required'),
        productCatalogId: Yup.string().required('This Field is required'),
        ...tblKycSetDetails.reduce((fieldValidations, result) => {
            const attributeName = result?.tblKycAttribute?.attributeName;
            const regexExpression = result?.tblKycAttribute;
            const date = result?.tblKycAttribute?.attributeName.toLowerCase().includes('date');
            const regExp = new RegExp(regexExpression.tblValidator.argumentValue);
            const maxLength = getMaxRegexLength(regExp);

            if (kycFixed?.includes(result?.tblKycAttribute?.attributeName) || (result.isMandatory === 'Y' && result?.isActive === 'Y')) {
                // Validate that the field is not empty
                fieldValidations[attributeName] = Yup.string().test('dynamicValidation', 'Invalid Format', function (value) {
                    // Check if a value is entered and apply regular expression validation
                    if (value) {
                        if (value.length > maxLength && !date) {
                            if (maxLength > 0) {
                                return this.createError({
                                    path: this.path,
                                    message: `Value exceeds the maximum length of ${maxLength}`
                                });
                            }
                        }

                        return regExp.test(value);
                    } else {
                        // If no value is entered and the field is mandatory, show it as required
                        if (kycFixed?.includes(result?.tblKycAttribute?.attributeName) || result.isMandatory === 'Y') {
                            return this.createError({
                                path: this.path,
                                message: 'This Field is required'
                            });
                        }

                        // If no value is entered and the field is not mandatory, no error is shown.
                        return true;
                    }
                });
            } else if (result?.isActive === 'Y') {
                // If the item is not mandatory but active, only apply the regex validation
                fieldValidations[attributeName] = Yup.string().test('dynamicValidation', 'Invalid Format', function (value) {
                    if (value) {
                        // Check if value is not empty
                        if (value.length > maxLength && !date) {
                            if (maxLength > 0) {
                                return this.createError({
                                    path: this.path,
                                    message: `Value exceeds the maximum length of ${maxLength}`
                                });
                            }
                        }

                        return regExp.test(value);
                    }
                    // If the value is empty, no error is shown.
                    return true;
                });
            }

            return fieldValidations;
        }, {})
    });

    const formik = useFormik({
        validationSchema: validationSchema,

        initialValues: {
            transLimitId: '',
            parentAgentId: '',
            parentMobileNumber: '',
            ParentCnic: '',
            parentNtn: '',
            channelIdList: [],
            hierarchyName: '',
            empId: '',
            productCatalogId: ''
        },

        onSubmit: async (data, actions) => {
            setloading(true);
            const arrayOfChannelIds = formik?.values?.channelIdList?.map((item) => {
                return parseInt(item?.lovId, 10) || 0;
            });
            if (!parent || parent?.length === 0 || parent == null) {
                return toast.warn('Please search parent data');
            }
            data['parentAgentId'] = parent?.agentId;
            data['empId'] = empIdData;

            delete data['crpScore'];
            delete data['crpRating'];
            delete data['ecib'];
            delete data['birthPlace'];
            delete data['plus30'];
            delete data['plus60'];
            delete data['plus90'];
            delete data['plus120'];
            delete data['plus150'];
            delete data['plus180'];

            const nonRequiredFields = [];
            kycFields[0]?.tblKycSetDetails?.forEach((item) => {
                if (!kycFixed?.includes(item?.tblKycAttribute?.attributeName) && item?.isActive === 'Y') {
                    nonRequiredFields.push({
                        infoKey: item?.tblKycAttribute?.attributeName,
                        infoValue: formik.values[item?.tblKycAttribute?.attributeName]
                    });
                }
            });

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data,
                        agentId: apiData?.agentId,
                        accountClassificationName: name,
                        contact: false,
                        channelIdList: arrayOfChannelIds
                    },
                    additionalInformation: nonRequiredFields,
                    checkSum: ''
                }
            };
            kycFields[0]?.tblKycSetDetails?.forEach((item) => {
                delete newData?.data?.payLoad[item?.tblKycAttribute?.attributeName];
            });
            delete newData?.data?.payLoad?.hierarchyName;
            delete newData?.data?.payLoad?.ParentCnic;
            delete newData?.data?.payLoad?.parentMobileNumber;
            delete newData?.data?.payLoad?.parentNtn;
            delete newData?.data?.payLoad?.parentAgentId;
            const res = await dispatch(handlePostRequest(newData, '/account/v1/customeraccounts/agentmaintenance', true, false, 'accountsmanagement'));
            if (res.responseCode === '010000') {
                // actions.resetForm();
            } else {
            }
            setloading(false);
        }
    });

    useEffect(() => {
        if (apiData) {
            formik.setFieldValue('transLimitId', apiData?.tblTransLimit?.transLimitId.toString() || '');
            formik.setFieldValue('hierarchyName', apiData?.tblSalesHierarchy?.hierarchyName || '');
            formik.setFieldValue('empId', apiData?.tblSalesForce?.employeeId || null);
            setEmpIdData(apiData?.tblSalesForce?.employeeId || '');
            formik.setFieldValue('productCatalogId', apiData?.tblAgentChannelList[0]?.tblProductCatalog?.productCatalogId.toString() || '');
            formik.setFieldValue('parentAgentId', apiData?.parentAgentId);
            setApiParentId(apiData?.parentAgentId);
            formik.setFieldValue('accountStatus', apiData?.accountStatus?.toString());
            formik.setFieldValue('motherName', apiData?.motherName);
            formik.setFieldValue('accountNumber', apiData?.accountNumber);
            formik.setFieldValue('crpScore', apiData?.crpScore);
            formik.setFieldValue('crpRating', apiData?.crpRating);
            formik.setFieldValue('addressInfos', apiData?.addressInfos);
            formik.setFieldValue('birthPlace', apiData?.birthPlace);
            formik.setFieldValue('ecib', apiData?.ecib);
            formik.setFieldValue('plus30', apiData?.tblEcibResponse?.plus30);
            formik.setFieldValue('plus60', apiData?.tblEcibResponse?.plus60);
            formik.setFieldValue('plus90', apiData?.tblEcibResponse?.plus90);
            formik.setFieldValue('plus120', apiData?.tblEcibResponse?.plus120);
            formik.setFieldValue('plus150', apiData?.tblEcibResponse?.plus150);
            formik.setFieldValue('plus180', apiData?.tblEcibResponse?.plus180);
        }
    }, [apiData]);
    useEffect(() => {
        if (kycFields) {
            kycFields[0]?.tblKycSetDetails?.forEach((item) => {
                formik.setFieldValue(item?.tblKycAttribute?.attributeName, JSON?.parse(apiData?.kycView)[item?.tblKycAttribute?.attributeName] || '');
            });
        }
    }, [kycFields]);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getKycFields = async () => {
        const res = await handleGetRequest(`/account/v1/agentaccount/getkycbyclassificationname/${name}`);
        setKycFields(res?.payLoad);
        let tblkyc = res?.payLoad[0].tblKycSetDetails;
        setTblKycSetDetails(tblkyc);
    };

    const getParentData = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    accountClassificationName: name,
                    cnic: formik.values.ParentCnic,
                    mobileNumber: formik.values.parentMobileNumber,
                    ntn: formik.values.parentNtn,
                    agentId: formik.values.parentAgentId
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newData, '/account/v1/agentaccount/getparentagent'));
        setParentData(res?.payLoad);
        setParent(res?.payLoad);

        formik.setFieldValue('parentAgentId', '');
        formik.setFieldValue('ParentCnic', '');
        formik.setFieldValue('parentMobileNumber', '');
        formik.setFieldValue('parentNtn', '');
    };

    //empty parent data table
    const handleDelete = () => {
        setParent([]);
        if (input1.current) {
            input1.current.value = '';
        }
        if (input2.current) {
            input2.current.value = '';
        }
        if (input3.current) {
            input3.current.value = '';
        }
        if (input4.current) {
            input4.current.value = '';
        }
    };

    const panelFooterTemplate = () => {
        const length = formik.values.channelIdList ? formik.values.channelIdList.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    //search on the basis of employee ID
    const searchEmployeeId = async () => {
        setUseCaseError({ empId: null });
        if (formik.values.empId === '') {
            setUseCaseError({ empId: 'This field is required' });
        } else {
            formik.setFieldValue('hierarchyName', '');
            const id = formik.values.empId;
            if (id) {
                dispatch(loadingAction(true));
                const res = await handleGetRequest(`/account/v1/sales/getemployeebyid/${id}`);
                if (res == undefined || res) setTimeout(() => dispatch(loadingAction(false)), 1000);
                if (res?.payLoad?.length) {
                    setEmpIdData(res?.payLoad[0]?.employeeId);
                    formik.setFieldValue('hierarchyName', res?.payLoad[0]?.salesHierarchyName);
                } else {
                    formik.setFieldValue('hierarchyName', '');
                }
            }
        }
    };

    //limit profile dropdown options API
    const getLimitProfile = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getagentdatabyaccounttype/TBL_TRANS_LIMIT/${accountClassificationId}`);
        setLimitProfile(res?.payLoad);
    };

    //product catalog dropdown options API
    const getProductCatalog = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/productCatalog/TBL_PRODUCT_CATALOG:*');
        setProductCatalog(res?.payLoad);
    };

    //channel dropdown options API
    const getChannel = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchannels/LKP_CHANNEL:*');
        setChannelOption(res?.payLoad);
    };
    useEffect(() => {
        if (apiParentId) {
            getParentData();
        }
    }, [apiParentId]);
    useEffect(() => {
        if (apiData) {
            // getParentData();
            getKycFields();
            const extractedChannelIds = apiData?.tblAgentChannelList
                ?.filter((entry) => entry?.isActive === 'Y')
                .map((entry) => ({
                    lovId: entry?.lkpChannel?.channelId.toString()
                }));
            formik.setFieldValue('channelIdList', extractedChannelIds);
        }
    }, [apiData]);

    function convertToTitleCase(inputString) {
        const words = inputString.split(/(?=[A-Z])/);
        const titleCaseString = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

        return titleCaseString;
    }

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    useEffect(() => {
        getProductCatalog();
        getChannel();
        getLimitProfile();
    }, []);

    const handleEmployeeIdChange = (e) => {
        // Ensure it's a numeric value and limit to 4 characters
        const inputText = e.target.value.replace(/[^0-9]/g, '').slice(0, 7);
        formik.setFieldValue('empId', inputText);
    };

    const handleParentAgentId = (e) => {
        // Ensure it's a numeric value and limit to 4 characters
        const inputText = e.target.value.replace(/[^0-9]/g, '').slice(0, 7);
        formik.setFieldValue('parentAgentId', inputText);
    };

    const handleParentCnic = (e) => {
        // Ensure it's a numeric value and limit to 4 characters
        const inputText = e.target.value.replace(/[^0-9]/g, '').slice(0, 13);
        formik.setFieldValue('ParentCnic', inputText);
    };

    const handleParentNumber = (e) => {
        // Ensure it's a numeric value and limit to 4 characters
        const inputText = e.target.value.replace(/[^0-9]/g, '').slice(0, 11);
        formik.setFieldValue('parentMobileNumber', inputText);
    };
    const handleParentNtn = (e) => {
        // Ensure it's a numeric value and limit to 4 characters
        const inputText = e.target.value.replace(/[^0-9]/g, '').slice(0, 7);
        formik.setFieldValue('parentNtn', inputText);
    };

    useEffect(() => {
        if (formik.values.parentAgentId || formik.values.ParentCnic || formik.values.parentMobileNumber || formik.values.parentNtn) {
            setSearchBtnDisable(false);
        } else if (!isEditable) {
            setSearchBtnDisable(true);
        } else {
            setSearchBtnDisable(true);
        }
    }, [formik.values.parentAgentId, formik.values.ParentCnic, formik.values.parentMobileNumber]);

    const onHide = () => {
        setShow(true);
        setShowBtn(false);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const [remarksDialog, setRemarksDialog] = useState(false);

    const handleRemarksDialog = (e) => {
        e.preventDefault();
        setRemarksDialog(true);
    };

    const onHideRemarksDialog = () => {
        setRemarksDialog(false);
    };
    const remarksValue = formik.values.remarks;
    const statusValue = formik.values.accountStatus;

    const dialogData = (data) => {
        formik.setFieldValue('accountStatus', data?.accountStatus);
        formik.setFieldValue('remarks', data?.remarks);
    };

    return (
        <>
            <Dialog visible={remarksDialog} style={{ width: '30vw' }} onHide={onHideRemarksDialog}>
                <RemarksDailog dialogData={dialogData} onHideRemarksDialog={onHideRemarksDialog} remarksValue={remarksValue} statusValue={statusValue} />
            </Dialog>
            <div className="my-3">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="custom-card p-3">
                        <div className="m-3 Form__Header Full__Width">
                            <p>HANDLER KYC SETS</p>
                        </div>
                        {kycFields && <DynamicKYCFields kycFields={kycFields} formik={formik} kycFixed={kycFixed} isEditable={isEditable} getFormErrorMessage={getFormErrorMessage} />}
                    </div>

                    {showBtn && isEditable && (
                        <div className="text-center mt-2 mb-2 flex justify-content-center">
                            <Button label="View more" type="button" className="view-button p-button-rounded" onClick={() => onHide()} />
                        </div>
                    )}

                    {(!isEditable || show) && (
                        <>
                            <div className="custom-card mb-5">
                                <div className="mx-5 mt-0 Form__Header Full__Width">
                                    <p>PARENT</p>
                                </div>
                                <div className="p-fluid p-formgrid grid mb-5 mx-5">
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="ParentCnic" className="Label__Text">
                                                CNIC
                                            </label>
                                            <InputText
                                                id="ParentCnic"
                                                name="ParentCnic"
                                                value={formik.values.ParentCnic}
                                                onChange={(e) => {
                                                    handleParentCnic(e);
                                                }}
                                                className="Input__Round"
                                                ref={input1}
                                                disabled
                                            />
                                            {getFormErrorMessage('ParentCnic')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="parentMobileNumber" className="Label__Text">
                                                Mobile Number
                                            </label>
                                            <InputText
                                                id="parentMobileNumber"
                                                name="parentMobileNumber"
                                                value={formik.values.parentMobileNumber}
                                                onChange={(e) => {
                                                    handleParentNumber(e);
                                                }}
                                                className="Input__Round"
                                                ref={input2}
                                                disabled
                                            />
                                            {getFormErrorMessage('parentMobileNumber')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="parentNtn" className="Label__Text">
                                                NTN
                                            </label>
                                            <InputText
                                                id="parentNtn"
                                                name="parentNtn"
                                                value={formik.values.parentNtn}
                                                onChange={(e) => {
                                                    handleParentNtn(e);
                                                }}
                                                className="Input__Round"
                                                ref={input3}
                                                disabled
                                            />
                                            {getFormErrorMessage('parentNtn')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="parentAgentId" className="Label__Text">
                                                Agent ID
                                            </label>
                                            <InputText
                                                id="parentAgentId"
                                                name="parentAgentId"
                                                value={formik.values.parentAgentId}
                                                onChange={(e) => {
                                                    handleParentAgentId(e);
                                                }}
                                                className="Input__Round"
                                                ref={input4}
                                                disabled
                                            />
                                            {getFormErrorMessage('parentAgentId')}
                                        </div>
                                    </div>
                                    <div className="Usergroup__SearchBtn p-field col-12 md:col-4 pt-3 pb-3 ">
                                        <Button label="Search" type="button" onClick={() => getParentData()} className="Btn__Dark__Search" disabled={searchBtnDisable} />
                                    </div>
                                </div>
                                <div className="grid mx-5">
                                    <div className="col-12">
                                        <div className="card">
                                            <DataTable className="datatable-responsive" value={parent ? (Array.isArray(parent) ? parent : [parent]) : ''} emptyMessage="No List found." responsiveLayout="scroll">
                                                <Column body={(item) => (item.franchiseName ? item.franchiseName : item.agentName)} header="Name" />
                                                <Column field="cnic" header="CNIC" />
                                                <Column field="ntn" header="NTN" />
                                                <Column field="agentPhoneNo" header="Mobile Number" />
                                                <Column field="agentId" header="Agent ID" />
                                                <Column header="Action" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="transLimitId" className="Label__Text">
                                            Limit Profile<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="transLimitId"
                                            placeholder="Select Limit Profile"
                                            options={limitProfile}
                                            optionLabel="name"
                                            name="transLimitId"
                                            optionValue="lovId"
                                            value={formik.values.transLimitId || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('transLimitId') }, 'Dropdown__Round')}
                                            disabled={!isEditable}
                                        />
                                        {getFormErrorMessage('transLimitId')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="channelList" className="Label__Text">
                                            Channel<span className="Label__Required">*</span>
                                        </label>
                                        <MultiSelect
                                            id="channelList"
                                            options={channelOption}
                                            name="channelList"
                                            optionLabel="name"
                                            optionValue="lovId"
                                            panelFooterTemplate={panelFooterTemplate}
                                            filter
                                            value={formik.values.channelIdList?.map((trans) => trans.lovId)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('channelIdList') }, 'Dropdown__Round')}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    'channelIdList',
                                                    e.value.map((lovId) => ({
                                                        lovId
                                                    }))
                                                );
                                            }}
                                            disabled={!isEditable}
                                        />
                                        {getFormErrorMessage('channelIdList')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="empId" className="Label__Text">
                                            Employee ID<span className="Label__Required">*</span>
                                        </label>
                                        <div className="flex">
                                            <InputText
                                                id="empId"
                                                name="empId"
                                                value={formik.values.empId}
                                                onChange={(e) => {
                                                    setUseCaseError({ empId: null });
                                                    handleEmployeeIdChange(e);
                                                    if (!e.target.value) {
                                                        formik.setFieldValue('hierarchyName', '');
                                                    }
                                                }}
                                                className={classNames({ 'p-invalid': useCaseError.empId || isFormFieldValid('empId') }, 'Input__Round')}
                                                maxLength={30}
                                                disabled={!isEditable}
                                            />
                                            <Button icon={loadingIcon || ''} onClick={() => searchEmployeeId()} type="button" className="Btn__Dark border-circle" style={{ height: '35px', width: '35px', marginLeft: '1rem' }} disabled={!isEditable} />
                                        </div>
                                        {useCaseError.empId ? <small className="p-error">{formik?.errors?.empId}</small> : getFormErrorMessage('empId')}{' '}
                                    </div>
                                </div>
                            </div>
                            <div className="custom-card p-3 my-3">
                                <div className="m-3 Form__Header Full__Width">
                                    <p>EMPLOYEE ID</p>
                                </div>
                                <div className="p-fluid p-formgrid grid mb-2 mx-3">
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="hierarchyName" className="Label__Text">
                                                Sales Hierarchy Name<span className="Label__Required">*</span>
                                            </label>
                                            <InputText
                                                id="hierarchyName"
                                                name="hierarchyName"
                                                value={formik.values.hierarchyName}
                                                onChange={formik.handleChange}
                                                disabled
                                                className={classNames({ 'p-invalid': isFormFieldValid('hierarchyName') }, 'Input__Round')}
                                                maxLength={30}
                                            />
                                            {getFormErrorMessage('hierarchyName')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="productCatalogId" className="Label__Text">
                                            Product catalog<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="productCatalogId"
                                            placeholder="Select Product Catalog"
                                            options={productCatalog}
                                            optionLabel="name"
                                            name="productCatalogId"
                                            optionValue="lovId"
                                            value={formik.values.productCatalogId || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('productCatalogId') }, 'Dropdown__Round')}
                                            disabled={!isEditable}
                                        />
                                        {getFormErrorMessage('productCatalogId')}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid grid mb-5">
                                {formik.values.addressInfos?.map((item, index) => {
                                    return (
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3" key={index}>
                                            <div className="p-field">
                                                <label htmlFor={`addressInfos.${index}.fullAddress`} className={classNames({ 'p-error': isFormFieldValid(`addressInfos.${index}.fullAddress`) }, 'Label__Text')}>
                                                    Address <span className="Label__Required">*</span>
                                                </label>
                                                <InputText
                                                    disabled
                                                    id={`addressInfos.${index}.fullAddress`}
                                                    name={`addressInfos.${index}.fullAddress`}
                                                    placeholder="Enter Adds"
                                                    value={item?.fullAddress || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid(`addressInfos.${index}.fullAddress`) }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage(`addressInfos.${index}.fullAddress`)}
                                            </div>
                                        </div>
                                    );
                                })}

                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="fatherName" className={classNames({ 'p-error': isFormFieldValid('fatherName') }, 'Label__Text')}>
                                            Father Name <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            disabled
                                            id="fatherName"
                                            name="fatherName"
                                            placeholder="Enter Father Name"
                                            value={formik.values.fatherName || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('fatherName') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('fatherName')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="motherName" className={classNames({ 'p-error': isFormFieldValid('motherName') }, 'Label__Text')}>
                                            Mother Name <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            disabled
                                            id="motherName"
                                            name="motherName"
                                            placeholder="Enter Mother Name"
                                            value={formik.values.motherName || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('motherName') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('motherName')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="crpScore" className={classNames({ 'p-error': isFormFieldValid('crpScore') }, 'Label__Text')}>
                                            CRP Score <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="crpScore"
                                            name="crpScore"
                                            placeholder="Please Enter Crp Score"
                                            value={formik.values.crpScore || ''}
                                            onChange={formik.handleChange}
                                            disabled
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('crpScore') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('crpScore')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="crpRating" className={classNames({ 'p-error': isFormFieldValid('crpRating') }, 'Label__Text')}>
                                            CRP Rating <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="crpRating"
                                            name="crpRating"
                                            placeholder="Please Enter Crp Rating"
                                            value={formik.values.crpRating || ''}
                                            onChange={formik.handleChange}
                                            disabled
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('crpRating') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('crpRating')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="ecib" className={classNames({ 'p-error': isFormFieldValid('ecib') }, 'Label__Text')}>
                                            ECIB Score <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="ecib"
                                            name="ecib"
                                            placeholder="Please Enter Crp Rating"
                                            value={formik.values.ecib || ''}
                                            onChange={formik.handleChange}
                                            disabled
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('ecib') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('ecib')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="birthPlace" className={classNames({ 'p-error': isFormFieldValid('birthPlace') }, 'Label__Text')}>
                                            Place of Birth <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="birthPlace"
                                            name="birthPlace"
                                            placeholder="Place of Birth"
                                            value={formik.values.birthPlace || ''}
                                            onChange={formik.handleChange}
                                            disabled
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('birthPlace') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('birthPlace')}
                                    </div>
                                </div>
                                {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="plus30" className={classNames({ 'p-error': isFormFieldValid('plus30') }, 'Label__Text')}>
                                            ECIB Plus 30 <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="plus30"
                                            name="plus30"
                                            placeholder="Select ECIB Response"
                                            value={formik.values.plus30 || ''}
                                            onChange={formik.handleChange}
                                            disabled
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('plus30') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('plus30')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="plus60" className={classNames({ 'p-error': isFormFieldValid('plus60') }, 'Label__Text')}>
                                            ECIB Plus 60 <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="plus60"
                                            name="plus60"
                                            placeholder="Select ECIB Response"
                                            value={formik.values.plus60 || ''}
                                            onChange={formik.handleChange}
                                            disabled
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('plus60') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('plus60')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="plus90" className={classNames({ 'p-error': isFormFieldValid('plus90') }, 'Label__Text')}>
                                            ECIB Plus 90 <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="plus90"
                                            name="plus90"
                                            placeholder="Select ECIB Response"
                                            value={formik.values.plus90 || ''}
                                            onChange={formik.handleChange}
                                            disabled
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('plus90') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('plus90')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="plus120" className={classNames({ 'p-error': isFormFieldValid('plus120') }, 'Label__Text')}>
                                            ECIB Plus 120 <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="plus120"
                                            name="plus120"
                                            placeholder="Select ECIB Response"
                                            value={formik.values.plus120 || ''}
                                            onChange={formik.handleChange}
                                            disabled
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('plus120') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('plus120')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="plus150" className={classNames({ 'p-error': isFormFieldValid('plus150') }, 'Label__Text')}>
                                            ECIB Plus 150 <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="plus150"
                                            name="plus150"
                                            placeholder="Select ECIB Response"
                                            value={formik.values.plus150 || ''}
                                            onChange={formik.handleChange}
                                            disabled
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('plus150') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('plus150')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="plus180" className={classNames({ 'p-error': isFormFieldValid('plus180') }, 'Label__Text')}>
                                            ECIB Plus 180 <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="plus180"
                                            name="plus180"
                                            placeholder="Select ECIB Response"
                                            value={formik.values.plus180 || ''}
                                            onChange={formik.handleChange}
                                            disabled
                                            onKeyDown={(e) => handleKeyPress(e)}
                                            className={classNames({ 'p-invalid': isFormFieldValid('plus180') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('plus180')}
                                    </div>
                                </div> */}
                            </div>
                            {/* <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="productCatalogId" className="Label__Text">
                                            Parent Name<span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="productCatalogId"
                                            placeholder="Select Product Catalog"
                                            options={productCatalog}
                                            optionLabel="name"
                                            name="productCatalogId"
                                            optionValue="lovId"
                                            value={formik.values.productCatalogId || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('productCatalogId') }, 'Input__Round')}
                                            disabled
                                        />
                                        {getFormErrorMessage('productCatalogId')}
                                    </div>
                                </div>
                            </div> */}
                            {!isEditable ? (
                                <div className="Down__Btn my-5">
                                    <Button type="button" label="Okay" className="Btn__Dark" onClick={() => navigate('/accountsmanagement')} />
                                </div>
                            ) : (
                                <div className="Down__Btn">
                                    {/* <Button type="submit" label="Update" className="Btn__Dark" onClick={() => ScrollMover()} /> */}
                                    {apiData?.accountStatus === 4 ? (
                                        <Button type="submit" label="Ok" className="Btn__Dark" onClick={() => navigate('/accountsmanagement')} />
                                    ) : (
                                        <Button type="submit" label="Update" className="Btn__Dark" onClick={() => ScrollMover()} />
                                    )}
                                    <Button type="button" disabled={loading} onClick={() => navigate('/accountsmanagement')} label="Cancel" className="Btn__Transparent" />
                                </div>
                            )}
                        </>
                    )}
                </form>
            </div>
        </>
    );
}

export default EditViewHandler;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewCreateReg() {
    const [districtLov, setDistrictLov] = useState([]);
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const id = query.get('id');

    const formik = useFormik({
        initialValues: {
            regionName: '',
            districtId: ''
        }
    });

    const getRegionData = async () => {
        const res = await handleGetRequest(`/account/v1/sales/getRegionById/${id}`);

        const keyData = res?.payLoad;
        if (keyData) {
            formik.setFieldValue('regionName', keyData?.regionName);
            formik.setFieldValue('districtId', keyData?.districtId.toString());
        }
    };

    useEffect(() => {
        if (id) {
            getRegionData();
        }
    }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

    const getDistrictData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getAllDistrict/LKP_DISTRICT:*');
        setDistrictLov(res?.payLoad);
    };

    useEffect(() => {
        getDistrictData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>VIEW DETAIL</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="regionName" className={classNames({ 'p-error': isFormFieldValid('regionName') }, 'Label__Text')}>
                                    Region Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="regionName"
                                    name="regionName"
                                    placeholder="Enter Region Name"
                                    value={formik?.values?.regionName?.replace(/\s\s+/g, ' ')}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('regionName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('regionName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="districtId" className={classNames({ 'p-error': isFormFieldValid('districtId') }, 'Label__Text')}>
                                    District <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="districtId"
                                    placeholder="Select District"
                                    options={districtLov}
                                    optionLabel="name"
                                    name="districtId"
                                    optionValue="lovId"
                                    value={formik.values.districtId}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('districtId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('districtId')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button onClick={() => navigate('/createregion')} label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={id} tableName={'LKP_REGION'} />
        </>
    );
}
export default ViewCreateReg;

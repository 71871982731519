import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Steps } from 'primereact/steps';
import ViewProduct from './Components/Product/ViewProduct';
import ViewPricing from './Components/Pricing/ViewPricing';
import ViewRepayment from './Components/RepaymentMethod/ViewRepayment';
import ViewDocumnet from './Components/Document/ViewDocument';
import ViewTermsAndConditions from './Components/TermsandConditions/ViewTermsAndConditions';
import { handleGetRequest } from '../../../services/GetTemplate';
import { loadingAction } from '../../../redux/actions/loadingAction';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewLendingManagement() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [activeIndex, setActiveIndex] = useState(0);
    // const [allStepsComplete, setAllStepsComplete] = useState(false);
    const [lengingIdData, setLendingIdData] = useState();
    const [lendingData, setLendingData] = useState();
    // const [facilityType, setFacilityType] = useState();

    // const [stepData1, setStepData1] = useState();
    // const [stepData2, setStepData2] = useState();
    // const [stepData3, setStepData3] = useState();
    // const [stepData4, setStepData4] = useState();
    // const [stepData5, setStepData5] = useState();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const lmsProductId = query.get('lmsProductId');
    const tableName = 'TBL_LMS_PRODUCT';

    const getLmsProductById = async () => {
        dispatch(loadingAction(true));
        const response = await handleGetRequest(`/lending/v1/lending/getlmsproductbyid/${lmsProductId}`);
        setLendingData(response?.payLoad);
        setLendingIdData(lmsProductId);
        dispatch(loadingAction(false));
    };

    useEffect(() => {
        getLmsProductById();
    }, []); // eslint-disable-line

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const items = [
        {
            label: 'Product',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(lendingData?.currentStep) >= 0 ? false : true
        },
        {
            label: 'Pricing',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(lendingData?.currentStep) >= 1 ? false : true
        },
        {
            label: 'Repayment',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(lendingData?.currentStep) >= 2 ? false : true
        },
        {
            label: 'Document',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(lendingData?.currentStep) >= 3 ? false : true
        },
        {
            label: 'Terms & Condition',
            command: (e) => handleStepClick(e.index),
            disabled: parseInt(lendingData?.currentStep) >= 4 ? false : true
        }
    ];

    const handleStepClick = (index) => {
        setActiveIndex(index);
    };

    // const getLendingId = (data) => {
    //     setLendingIdData(data);
    // };

    // const handleFacilityData = (data) => {
    //     setFacilityType(data)
    // }
    return (
        <>
            <div className="card Card__Round">
                <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} style={{ margin: '50px 0px', padding: '50px 0px' }} className="CustomerRisk__Steps" />

                {activeIndex === 0 && <ViewProduct activeIndex={activeIndex} handleStepClick={handleStepClick} lengingIdData={lengingIdData} lendingData={lendingData} />}
                {activeIndex === 1 && <ViewPricing activeIndex={activeIndex} handleStepClick={handleStepClick} lengingIdData={lengingIdData} lendingData={lendingData} />}
                {activeIndex === 2 && <ViewRepayment activeIndex={activeIndex} handleStepClick={handleStepClick} lengingIdData={lengingIdData} lendingData={lendingData} />}
                {activeIndex === 3 && <ViewDocumnet activeIndex={activeIndex} handleStepClick={handleStepClick} lengingIdData={lengingIdData} lendingData={lendingData} />}
                {activeIndex === 4 && <ViewTermsAndConditions activeIndex={activeIndex} handleStepClick={handleStepClick} lengingIdData={lengingIdData} lendingData={lendingData} />}

                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {/* {activeIndex === 5 && allStepsComplete && ( */}
                    <div className="Down__Btn">
                        <Button disabled={loading} onClick={() => navigate('/lendingmanagement')} loadingIcon={loadingIcon} label="Okay" className="Btn__Dark" />
                        {/* <Button disabled={loading}  label="Cancel" className="Btn__Transparent" /> */}
                    </div>
                    {/* )} */}
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={lmsProductId} tableName={tableName} />
        </>
    );
}
export default ViewLendingManagement;

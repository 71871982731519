import React from 'react'
import { InputText } from 'primereact/inputtext'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { Button } from 'primereact/button';

const EditHraCnic = ({ onHraCnicChange, formikValuees, onHide }) => {

    const validationSchema = Yup.object().shape({
        hraNokCnic: Yup.string().required('This field is required.'),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            hraNokCnic: formikValuees?.hraNokCnic ? formikValuees?.hraNokCnic : "",
        },

        onSubmit: async () => {
            onHraCnicChange({ hraNokCnic: formik.values.hraNokCnic });
            onHide();
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className='Card__Round'>

                <div className="p-fluid p-formgrid grid">
                    <div className="p-field col-12">
                        <label className='Label__Text'>
                            CNIC
                        </label>
                        <InputText
                            id="hraNokCnic"
                            value={formik.values.hraNokCnic}
                            onChange={formik.handleChange}
                            className={classNames({ 'p-invalid': isFormFieldValid('hraNokCnic') }, 'Input__Round')}
                            keyfilter="int"
                            maxLength={13} />
                        {getFormErrorMessage("hraNokCnic")}
                    </div>
                </div>

                <div className='Down__Btn mt-4'>
                    <Button label="Confirm" iconPos="right" className="Btn__Dark" type="submit" />
                    <Button label="Cancel" iconPos="right" className="Btn__cancel" type="button" onClick={onHide} />
                </div>

            </div>
        </form>
    )
}

export default EditHraCnic
import React from 'react';
import cmsMobileBundle from '../../../../assets/cms/cmsMobileBundle.png';

function MobileView({ bundleName, tagLabel, tagStyle, discountAmount, amount, onnetMinutes, offnetMinutes, bundleSms, internetData, internetDataLabel, validity, bundleDescription }) {
    return (
        <div className="cmsMobileView__main relative">
            <div className="cmsMobileView__bundle">
                {bundleName && <p className="mb-0 cmsMobileView__bundleName">{bundleName}</p>}
                {tagLabel && (
                    <p className="mb-0 cmsMobileView__tagLabel" style={tagStyle}>
                        {tagLabel}
                    </p>
                )}
                {discountAmount && <p className="mb-0 cmsMobileView__discountAmount">Rs. {discountAmount}</p>}
                <p className="mb-0 cmsMobileView__amount">Rs. {amount && amount}</p>
                <div className="cmsMobileView__numbers">
                    {onnetMinutes && <p className="mb-0 cmsMobileView__onnetMinutes">{onnetMinutes}</p>}
                    {offnetMinutes && <p className="mb-0 cmsMobileView__offnetMinutes">{offnetMinutes}</p>}
                    {bundleSms && <p className="mb-0 cmsMobileView__bundleSms">{bundleSms}</p>}
                    {internetData && <p className="mb-0 cmsMobileView__internetData">{internetData}</p>}
                    <p className="mb-0 cmsMobileView__validity">Validity: {validity && validity}</p>
                </div>
                <div className="cmsMobileView__labels">
                    <p className="mb-0 cmsMobileView__onnetMinutesLabel">On-Net</p>
                    <p className="mb-0 cmsMobileView__offnetMinutesLabel">Off-Net</p>
                    <p className="mb-0 cmsMobileView__bundleSmsLabel">sms</p>
                    <p className="mb-0 cmsMobileView__internetDataLabel">{internetDataLabel}</p>
                </div>
                {bundleDescription && <p className="mb-0 cmsMobileView__bundleDescription">{bundleDescription}</p>}
            </div>
            <img src={cmsMobileBundle} className="blurMobile w-100 h-100" alt="cmsMobileBundle" />
        </div>
    );
}

export default MobileView;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handlePostRequest } from '../../../services/PostTemplate';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function CommissionHierarchy({ type, dataForTable }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setloading] = useState(false);
    const [checkerEditPayLoad, setCheckerEditPayLoad] = useState([]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const addEditValidationSchema = Yup.object().shape({
        commissionName: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[A-Za-z0-9 ]*$/, 'Special characters are not allowed')
            .matches(/^[a-zA-Z](?:[a-zA-Z0-9\s]*-?[a-zA-Z0-9\s]*)?$/, 'Invalid hierarchy name')
            .required('This field is required'),
        parentShare: Yup.string()
            .required('This field is required')
            .matches(/^[0-9]+$/, 'Only numbers are allowed')
            .max(3, 'Maximum 3 characters required')
            .test('max', 'Number must be less than or equal to 100', (value) => {
                return parseInt(value) <= 100;
            }),
        childShare: Yup.string()
            .required('This field is required')
            .matches(/^[0-9]+$/, 'Only numbers are allowed')
            .max(3, 'Maximum 3 characters required')
            .test('max', 'Number must be less than or equal to 100', (value) => {
                return parseInt(value) <= 100;
            })
    });

    const checkerValidationSchema = Yup.object().shape({
        action: Yup.string().required('This field is required'),
        checkerComments: Yup.string().required('This field is required')
    });

    const addEditInitialValues = {
        commissionName: '',
        parentShare: '',
        childShare: ''
    };

    const checkerInitialValues = {
        commissionName: '',
        parentShare: '',
        childShare: '',
        action: '',
        checkerComments: ''
    };

    const formik = useFormik({
        validationSchema: type === 'add' || type === 'edit' ? addEditValidationSchema : type === 'checkerEdit' ? checkerValidationSchema : null,
        initialValues: type === 'add' || type === 'edit' || type === 'view' ? addEditInitialValues : type === 'checkerEdit' || type === 'checkerView' ? checkerInitialValues : null,

        onSubmit: async (data) => {
            const addPayload = [
                {
                    commissionHierarchyName: formik?.values?.commissionName,
                    parentShare: formik?.values?.parentShare?.toString(),
                    childShare: formik?.values?.childShare?.toString()
                }
            ];
            const editPayload = {
                commissionHierarchyId: dataForTable?.commissionHierarchyId,
                commissionHierarchyName: formik?.values?.commissionName,
                parentShare: formik?.values?.parentShare?.toString(),
                childShare: formik?.values?.childShare?.toString()
            };
            setloading(true);
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: type === 'add' ? addPayload : type === 'edit' ? editPayload : type === 'checkerEdit' ? checkerEditPayLoad : null,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            if (type === 'add') {
                await dispatch(handlePostRequest(newData, '/pricingprofile/v1/commissionprofile/createcommissionhierarchy', true, false, 'commission'));
            }
            if (type === 'edit') {
                await dispatch(handlePostRequest(newData, '/pricingprofile/v1/commissionprofile/updatecommissionhierarchy', true, false, 'commission'));
            }
            if (type === 'checkerEdit') {
                await dispatch(handlePostRequest(newData, '/pricingprofile/v1/commissionprofile/commissionhierarchycheckeraction', true, false, 'requestchecker'));
            }
            setloading(false);
            // navigate(-1);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        if ((type === 'checkerEdit' || type === 'checkerView') && (dataForTable?.dataFromId || dataForTable?.dataFromApi)) {
            formik.setFieldValue('commissionName', dataForTable?.dataFromId?.hierarchyName || dataForTable?.dataFromApi?.hierarchyName);
            formik.setFieldValue('parentShare', dataForTable?.dataFromId?.parentShare?.toString() || dataForTable?.dataFromApi?.parentShare?.toString());
            formik.setFieldValue('childShare', dataForTable?.dataFromId?.childShare?.toString() || dataForTable?.dataFromApi?.childShare?.toString());
            setCheckerEditPayLoad({
                commissionHierarchyDTO: {
                    isActive: dataForTable?.urlData?.requestType === 'I' ? 'Y' : dataForTable?.dataFromApi?.isActive || dataForTable?.dataFromId?.isActive,
                    parentShare: dataForTable?.dataFromApi?.parentShare?.toString() || dataForTable?.dataFromId?.parentShare?.toString(),
                    childShare: dataForTable?.dataFromApi?.childShare?.toString() || dataForTable?.dataFromId?.childShare?.toString(),
                    commissionHierarchyId: dataForTable?.dataFromApi?.commissionHierarchyId || dataForTable?.dataFromId?.commissionHierarchyId,
                    commissionHierarchyName: dataForTable?.dataFromApi?.hierarchyName || dataForTable?.dataFromId?.hierarchyName
                },
                mcActionRequest: {
                    mcRequestId: dataForTable?.urlData?.mcRequestId,
                    mcPeindingRequestId: dataForTable?.urlData?.mcPeindingRequestId,
                    checkerId: '1',
                    checkerComments: formik?.values?.checkerComments,
                    action: formik?.values?.action,
                    updatedIndex: ''
                }
            });
        }
        if ((type === 'edit' || type === 'view') && dataForTable) {
            formik.setFieldValue('commissionName', dataForTable?.hierarchyName);
            formik.setFieldValue('parentShare', dataForTable?.parentShare?.toString());
            formik.setFieldValue('childShare', dataForTable?.childShare?.toString());
        }
    }, [dataForTable]);

    return (
        <React.Fragment>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h5 className="uppercase">{type === 'add' ? 'Commission Hierarchy' : type === 'edit' || type === 'checkerEdit' ? 'Edit Detail' : type === 'view' || type === 'checkerView' ? 'View Detail' : null}</h5>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {/* {(type === 'add' || type === 'edit' || type === 'checkerEdit') && ( */}
                    <React.Fragment>
                        <div className="p-fluid p-formgrid grid ">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="commissionName" className={classNames({ 'p-error': isFormFieldValid('commissionName') }, 'Label__Text')}>
                                        Name <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={50}
                                        minLength={3}
                                        id="commissionName"
                                        name="commissionName"
                                        value={formik?.values?.commissionName?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={formik.handleChange}
                                        disabled={type !== 'add'}
                                        className={classNames({ 'p-invalid': isFormFieldValid('commissionName') }, 'Input__Round')}
                                        onKeyDown={(e) => handleKeyPress(e)}
                                    />
                                    {getFormErrorMessage('commissionName')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="parentShare" className={classNames({ 'p-error': isFormFieldValid('parentShare') }, 'Label__Text')}>
                                        Parent Share <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        keyfilter={/^\d+$/}
                                        maxLength={3}
                                        id="parentShare"
                                        name="parentShare"
                                        value={formik?.values?.parentShare || ''}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            if (e.target.value && parseInt(e.target.value) < 100) {
                                                formik.setFieldValue('childShare', (100 - parseInt(e.target.value)).toString());
                                            } else if (e.target.value && e.target.value == '100') {
                                                formik.setFieldValue('childShare', '0');
                                            } else {
                                                formik.setFieldValue('childShare', '0');
                                            }
                                        }}
                                        disabled={type !== 'add' && type !== 'edit'}
                                        className={classNames({ 'p-invalid': isFormFieldValid('parentShare') }, 'Input__Round')}
                                        onKeyDown={(e) => handleKeyPress(e)}
                                    />
                                    {getFormErrorMessage('parentShare')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="childShare" className={classNames({ 'p-error': isFormFieldValid('childShare') }, 'Label__Text')}>
                                        Child Share <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={3}
                                        keyfilter={/^\d+$/}
                                        id="childShare"
                                        name="childShare"
                                        value={formik?.values?.childShare || ''}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            if (e.target.value && parseInt(e.target.value) < 100) {
                                                formik.setFieldValue('parentShare', (100 - parseInt(e.target.value)).toString());
                                            } else if (e.target.value && e.target.value == '100') {
                                                formik.setFieldValue('parentShare', '0');
                                            } else {
                                                formik.setFieldValue('parentShare', '0');
                                            }
                                        }}
                                        disabled={type !== 'add' && type !== 'edit'}
                                        className={classNames({ 'p-invalid': isFormFieldValid('childShare') }, 'Input__Round')}
                                        onKeyDown={(e) => handleKeyPress(e)}
                                    />
                                    {getFormErrorMessage('childShare')}
                                </div>
                            </div>
                        </div>
                        <div className="Down__Btn">
                            {(type === 'edit' || type === 'add') && (
                                <React.Fragment>
                                    <Button className="Btn__Dark" type="submit" label={type === 'add' ? 'Submit' : 'Update'} />
                                    <Button type="button" onClick={() => navigate(-1)} label="Cancel" className="Btn__Transparent" />
                                </React.Fragment>
                            )}
                        </div>
                    </React.Fragment>
                    {/* )} */}
                    {type === 'view' && (
                        <React.Fragment>
                            <div className="Down__Btn mt-4">
                                <Button type="button" onClick={() => navigate(-1)} label="Okay" className="Btn__Dark" />
                            </div>
                            {dataForTable?.commissionHierarchyId && <AuthorizationHistoryTable refTableId={dataForTable?.commissionHierarchyId} tableName={'TBL_COMMISSION_HIERARCHY'} />}
                        </React.Fragment>
                    )}
                    {type == 'checkerEdit' && (
                        <React.Fragment>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                            Action
                                            <span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="action"
                                            placeholder="Select Action"
                                            options={dataForTable?.statusLovData || []}
                                            optionLabel="name"
                                            name="action"
                                            optionValue="lovId"
                                            value={formik.values.action || ''}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                setCheckerEditPayLoad({
                                                    ...checkerEditPayLoad,
                                                    mcActionRequest: {
                                                        ...checkerEditPayLoad?.mcActionRequest,
                                                        action: e.target.value
                                                    }
                                                });
                                            }}
                                            className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('action')}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                            Comments
                                            <span className="Label__Required">*</span>
                                        </label>
                                        <InputTextarea
                                            maxLength={500}
                                            rows={5}
                                            cols={30}
                                            id="checkerComments"
                                            name="checkerComments"
                                            value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') || ''}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                setCheckerEditPayLoad({
                                                    ...checkerEditPayLoad,
                                                    mcActionRequest: {
                                                        ...checkerEditPayLoad?.mcActionRequest,
                                                        checkerComments: e.target.value
                                                    }
                                                });
                                            }}
                                            className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                        />

                                        {getFormErrorMessage('checkerComments')}
                                    </div>
                                </div>
                            </div>
                            <div className="Down__Btn">
                                <Button disabled={loading} label="Update" className="Btn__Dark" />
                                <Button type="button" onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                            </div>
                            {dataForTable?.urlData?.refTableId && dataForTable?.urlData?.tableName && <AuthorizationHistoryTable refTableId={dataForTable?.urlData?.refTableId} tableName={dataForTable?.urlData?.tableName} />}
                        </React.Fragment>
                    )}
                    {type == 'checkerView' && (
                        <React.Fragment>
                            <div className="Down__Btn">
                                <Button type="button" onClick={() => navigate('/requestchecker')} label="Okay" className="Btn__Dark" />
                            </div>
                            {dataForTable?.urlData?.refTableId && dataForTable?.urlData?.tableName && <AuthorizationHistoryTable refTableId={dataForTable?.urlData?.refTableId} tableName={dataForTable?.urlData?.tableName} />}
                        </React.Fragment>
                    )}
                </form>
            </div>
            {/* Action Authorization History */}
        </React.Fragment>
    );
}
export default CommissionHierarchy;

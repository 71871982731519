import React, { useEffect, useState } from 'react';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';

const ViewDiscrepantDocs = ({ updatedData, IsMinorAccount, onHide }) => {
    const [emptyMessage, setEmptyMessage] = useState('');

    useEffect(() => {
        if (
            updatedData?.[0]?.cnicFront ||
            updatedData?.[0]?.cnicBack ||
            updatedData?.[0]?.proofOfProfession ||
            updatedData?.[0]?.sourceOfIncome ||
            updatedData?.[0]?.parentCnicFront ||
            updatedData?.[0]?.parentCnicBack ||
            updatedData?.[0]?.bForm ||
            updatedData?.[0]?.utilityBill
        ) {
        } else {
            setEmptyMessage('No Records Found!');
        }
    }, [
        updatedData?.[0]?.cnicFront,
        updatedData?.[0]?.cnicBack,
        updatedData?.[0]?.proofOfProfession,
        updatedData?.[0]?.sourceOfIncome,
        updatedData?.[0]?.parentCnicFront,
        updatedData?.[0]?.parentCnicBack,
        updatedData?.[0]?.bForm || updatedData?.[0]?.utilityBill
    ]);

    return (
        <div>
            <div className="main_holder">
                {!emptyMessage ? (
                    <div className="grid grid-nogutter">
                        {updatedData?.[0]?.cnicFront ? (
                            <div className="col-12 md:col-6">
                                <div className="img_card">
                                    <div className="doc_name">
                                        <h5>Cnic Front</h5>
                                    </div>
                                    <div className="doc_img">
                                        <Image src={updatedData?.[0]?.cnicFront} alt="Image" width="250" preview />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        {updatedData?.[0]?.cnicBack ? (
                            <div className="col-12 md:col-6">
                                <div className="img_card">
                                    <div className="doc_name">
                                        <h5>Cnic Back</h5>
                                    </div>
                                    <div className="doc_img">
                                        <Image src={updatedData?.[0]?.cnicBack} alt="Image" width="250" preview />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        {updatedData?.[0]?.proofOfProfession ? (
                            <div className="col-12 md:col-6">
                                <div className="img_card">
                                    <div className="doc_name">
                                        <h5>Proof of Profession</h5>
                                    </div>
                                    <div className="doc_img">
                                        <Image src={updatedData?.[0]?.proofOfProfession} alt="Image" width="250" preview />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        {updatedData?.[0]?.sourceOfIncome ? (
                            <div className="col-12 md:col-6">
                                <div className="img_card">
                                    <div className="doc_name">
                                        <h5>Source of Income</h5>
                                    </div>
                                    <div className="doc_img">
                                        <Image src={updatedData?.[0]?.sourceOfIncome} alt="Image" width="250" preview />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        {updatedData?.[0]?.utilityBill ? (
                            <div className="col-12 md:col-6">
                                <div className="img_card">
                                    <div className="doc_name">
                                        <h5>Utility Bill</h5>
                                    </div>
                                    <div className="doc_img">
                                        <Image src={updatedData?.[0]?.utilityBill} alt="Image" width="250" preview />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        {IsMinorAccount && IsMinorAccount ? (
                            <>
                                {updatedData?.[0]?.parentCnicFront ? (
                                    <div className="col-12 md:col-6">
                                        <div className="img_card">
                                            <div className="doc_name">
                                                <h5>Parent Cnic Front</h5>
                                            </div>
                                            <div className="doc_img">
                                                <Image src={updatedData?.[0]?.parentCnicFront} alt="Image" width="250" preview />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {updatedData?.[0]?.parentCnicBack ? (
                                    <div className="col-12 md:col-6">
                                        <div className="img_card">
                                            <div className="doc_name">
                                                <h5>Parent Cnic Back</h5>
                                            </div>
                                            <div className="doc_img">
                                                <Image src={updatedData?.[0]?.parentCnicBack} alt="Image" width="250" preview />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {updatedData?.[0]?.bForm ? (
                                    <div className="col-12 md:col-6">
                                        <div className="img_card">
                                            <div className="doc_name">
                                                <h5>B Form</h5>
                                            </div>
                                            <div className="doc_img">
                                                <Image src={updatedData?.[0]?.bForm} alt="Image" width="250" preview />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                ) : (
                    <div>
                        <h3 className="m-0">{emptyMessage}</h3>
                    </div>
                )}
            </div>

            <div className="Down__Btn pt-4">
                <Button label="Okay" iconPos="right" className="Btn__cancel" onClick={onHide} />
            </div>
        </div>
    );
};

export default ViewDiscrepantDocs;

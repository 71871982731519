import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { Steps } from 'primereact/steps';
import { useLocation } from 'react-router-dom';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import { InputTextarea } from 'primereact/inputtextarea';
import EditCustomerBusinessRiskChecker from './CustomerBusinessRisk/EditCustomerBusinessRiskChecker';
import EditGeographicJurisdictionChecker from './GeographicJurisdiction/EditGeographicJurisdictionChecker';
import EditTransProfProductChannelChecker from './TransProfProductChannel/EditTransProfProductChannelChecker';
import EditTransactionProfilingChecker from './TransactionProfiling/EditTransactionProfilingChecker';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function EditCustomerRiskProfileChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');
    const [statusLovData, setStatusLovData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(3);
    const [riskProfileData, setRiskProfileData] = useState([]);
    const [riskProfileDataU, setRiskProfileDataU] = useState([]);

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    const getRiskProfileDataById = async () => {
        const response = await handleGetRequest(`/riskprofile/v1/crp/getcrp/${refTableId}`);
        setRiskProfileData(response?.payLoad);
    };

    const getMcRequestDataById = async () => {
        setloading(true);

        const res = await handleGetRequest(`/riskprofile/v1/crp/getcrpmcrequestbyid/${mcRequestId}`);
        setRiskProfileData(res?.payLoad);
        setActiveIndex(3);

        setloading(false);
    };

    const getMcRequestDataByIdUByInActive = async () => {
        setloading(true);

        const res = await handleGetRequest(`/riskprofile/v1/crp/getcrpmcrequestbyid/${mcRequestId}`);
        setRiskProfileDataU(res?.payLoad);
        setActiveIndex(3);

        setloading(false);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getRiskProfileDataById();
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getMcRequestDataById();
        } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
            getRiskProfileDataById();
            getMcRequestDataByIdUByInActive();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        getStatusLov();
    }, []);

    const formik = useFormik({
        initialValues: {
            action: '',
            checkerComments: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newDataForI = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        riskProfileRequestList: [
                            {
                                crpId: parseInt(refTableId),
                                isActive: 'Y'
                            }
                        ],
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const newDataForU = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        riskProfileRequestList: requestType === 'U' && requestTypeDescr === 'Enable/Disable Request' ? riskProfileDataU : riskProfileData,
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(requestType === 'I' ? newDataForI : newDataForU, '/riskprofile/v1/crp/crpValidatorCheckerAction', true, false, 'requestchecker'));
            if (res?.responseCode === '000000') {
                formik.resetForm();
            }

            setloading(false);
            setloadingIcon('');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const items = [
        {
            label: 'Customer / Business Risk',
            command: (e) => handleStepClick(e.index)
            // disabled: requestType === 'I' ? (parseInt(riskProfileData?.currentStep) >= 1 ? false : true) : parseInt(riskProfileData[0]?.stepNo) >= 1 ? false : true
        },
        {
            label: 'Geographic / Jurisdiction',
            command: (e) => handleStepClick(e.index)
            // disabled: requestType === 'I' ? (parseInt(riskProfileData?.currentStep) >= 2 ? false : true) : parseInt(riskProfileData[0]?.stepNo) >= 2 ? false : true
        },
        {
            label: 'Transaction Profiling - Products / Channels',
            command: (e) => handleStepClick(e.index)
            // disabled: requestType === 'I' ? (parseInt(riskProfileData?.currentStep) >= 3 ? false : true) : parseInt(riskProfileData[0]?.stepNo) >= 3 ? false : true
        },
        {
            label: 'Transaction Profiling',
            command: (e) => handleStepClick(e.index)
            // disabled: requestType === 'I' ? (parseInt(riskProfileData?.currentStep) >= 4 ? false : true) : parseInt(riskProfileData[0]?.stepNo) >= 4 ? false : true
        }
    ];

    const handleStepClick = (index) => {
        setActiveIndex(index);
    };

    return (
        <>
            <div className="card Card__Round">
                <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} style={{ margin: '50px 0px', padding: '50px 0px' }} className="Campaign__Steps" />
                <div className="Form__Header">
                    <h1>EDIT CAMPAIGN CHECKER</h1>
                </div>
                {activeIndex === 0 && <EditCustomerBusinessRiskChecker riskProfileData={riskProfileData} />}
                {activeIndex === 1 && <EditGeographicJurisdictionChecker riskProfileData={riskProfileData} />}
                {activeIndex === 2 && <EditTransProfProductChannelChecker riskProfileData={riskProfileData} />}
                {activeIndex === 3 && <EditTransactionProfilingChecker riskProfileData={riskProfileData} />}

                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    {activeIndex >= 3 && (
                        <>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                            Action
                                            <span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="action"
                                            placeholder="Select Action"
                                            options={statusLovData}
                                            optionLabel="name"
                                            name="action"
                                            optionValue="lovId"
                                            optionDisabled={(option) => option?.name === 'Pending'}
                                            value={formik.values.action || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('action')}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-12">
                                    <div className="p-field">
                                        <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                            Comments
                                            <span className="Label__Required">*</span>
                                        </label>
                                        <InputTextarea
                                            autoResize={false}
                                            rows={5}
                                            cols={30}
                                            id="checkerComments"
                                            name="checkerComments"
                                            value={formik?.values?.checkerComments || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                        />
                                        {getFormErrorMessage('checkerComments')}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {activeIndex >= 3 && (
                        <div className="Down__Btn">
                            <Button disabled={((!formik.values.action || !formik.values.checkerComments) && true) || loading} icon={loadingIcon || ''} label="Update" className="Btn__Dark" />
                            <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                        </div>
                    )}
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default EditCustomerRiskProfileChecker;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handleGetRequest } from '../../../../../services/GetTemplate';
import { toast } from 'react-toastify';

function EditTermLoan({ activeIndex, handleStepClick, lendingData, lendingIdData, stepData1, setStepData3 }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [frequencyPeriodData, setFrequencyPeriodData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [rowIndex, setRowIndex] = useState(0);

    /////////////////////////////////////////
    const [termLoanTableData, setTermLoanTableData] = useState([]);
    const [termLoanData, setTermLoanData] = useState({
        noOfDays: '',
        markupValue: '',
        apr: '',
        salaryMultiplier: '',

        gracePeriod: '',
        noOfDaysGracePeriod: '',

        latePayment: '',
        latePaymentType: '',
        latePaymentCharges: '',
        latePaymentOn: '',

        frequencyId: '',

        earlyPayment: '',
        earlyPaymentType: '',
        earlyPaymentCharges: '',
        earlyPaymentOn: '',

        downPayment: '',
        downPaymentCharges: ''
    });
    const [termLoanError, setTermLoanError] = useState({
        noOfDays: '',
        markupValue: '',
        apr: '',
        salaryMultiplier: '',

        gracePeriod: '',
        noOfDaysGracePeriod: '',

        latePayment: '',
        latePaymentType: '',
        latePaymentCharges: '',
        latePaymentOn: '',

        frequencyId: '',

        earlyPayment: '',
        earlyPaymentType: '',
        earlyPaymentCharges: '',
        earlyPaymentOn: '',

        downPayment: '',
        downPaymentCharges: ''
    });
    //////////////////////////////

    const [buttons, setButtons] = useState([
        { label: 'Principal Amount', sequence: 1 },
        { label: 'Markup', sequence: 2 },
        { label: 'Fees', sequence: 3 }
    ]);

    const getFrequencyPeriodData = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getdatalov/LKP_FREQUENCY_PERIOD');
        setFrequencyPeriodData(response?.payLoad);
    };

    useEffect(() => {
        getFrequencyPeriodData();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (lendingData) {
            const termLoanData = lendingData?.tblLmsRepaymentMethods
                ?.filter((value) => value?.isActive === 'Y')
                ?.map((item) => {
                    return {
                        noOfDays: item?.noOfDays,
                        markupValue: item?.markupValue,
                        apr: item?.apr,
                        salaryMultiplier: item?.salaryMultiple,

                        gracePeriod: item?.gracePeriod === 'Y' ? true : false,
                        noOfDaysGracePeriod: item?.noOfGraceDays,

                        latePayment: item?.latePayment === 'Y' ? true : false,
                        latePaymentType: item?.latePaymentType,
                        latePaymentCharges: item?.latePaymentCharges,
                        latePaymentOn: item?.latePaymentOn,

                        frequencyId: item?.lkpFrequencyPeriod?.frequencyPeriodId.toString(),

                        earlyPayment: item?.earlyRepayment === 'Y' ? true : false,
                        earlyPaymentType: item?.earlyPaymentType,
                        earlyPaymentCharges: item?.earlyRepaymentCharges,
                        earlyPaymentOn: item?.earlyPaymentOn,

                        downPayment: item?.downPayment === 'Y' ? true : false,
                        downPaymentCharges: item?.downPaymentCharges,

                        repaymentTypeCode: stepData1?.facilityCode,

                        seqFees: item?.seqLateFee,
                        seqMarkup: item?.seqMarkup,
                        seqPrincipalAmount: item?.seqPrincipalAmount
                    };
                });
            if (lendingData?.lkpLmsFacilityType?.lmsFacilityTypeCode === 'TL') {
                setTermLoanTableData(termLoanData);
            } else {
                setTermLoanData([]);
            }
        }
    }, [lendingData]); // eslint-disable-line

    const validationSchema = Yup.object().shape({});

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {},

        validate: (data) => {},

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            if (termLoanTableData?.length <= 0) {
                toast.warn('Please add atleast 01 Loan Tenure');
                setloading(false);
                setloadingIcon('pi pi-search');
                return;
            }

            if (lendingData?.currentStep > 2) {
                data['edit'] = true;
            }

            // if(data?.downPayment === 'Y') {
            //     data['downPayment'] = true;
            // } else if(data?.downPayment === 'N' || data?.downPayment === ''){
            //     data['downPayment'] = false;
            // }

            const termLoanData = {
                lmsProductId: lendingIdData,
                currentStep: 3,
                lmsProductRepaymentRequests: termLoanTableData
            };

            setStepData3(termLoanData);
            handleStepClick(activeIndex + 1);

            // const newData = {
            //     data: {
            //         security: {
            //             userName: '',
            //             password: '',
            //             securityToken: ''
            //         },
            //         account: {
            //             msidn: '',
            //             iban: '',
            //             bban: '',
            //             pan: '',
            //             currency: ''
            //         },
            //         channel: '',
            //         terminal: '',
            //         reterivalReferenceNumber: '',
            //         payLoad: {
            //             lmsProductId: lendingIdData,
            //             currentStep: 3,
            //             lmsProductRepaymentRequests: termLoanTableData,
            //         },
            //         additionalInformation: [
            //             {
            //                 infoKey: '',
            //                 infoValue: ''
            //             }
            //         ],
            //         checkSum: ''
            //     }
            // };

            // const formData = new FormData();
            // const blob = new Blob([JSON.stringify(newData)], { type: 'application/json' });
            // formData.append('request', blob);

            // if (lendingData?.currentStep > 2) {
            //     const res = await dispatch(handlePostRequest(formData, '/lending/v1/lending/savelmsproduct', true, false));
            //     if (res?.responseCode === process.env.REACT_APP_LENDING_SUCCESS_CODE) {
            //         formik.resetForm();
            //         getLendingId(res?.payLoad?.lmsProductId);
            //         handleStepClick(activeIndex + 1);
            //     }
            // } else {
            //     const res = await dispatch(handlePostRequest(formData, '/lending/v1/lending/savelmsproduct', true, false));
            //     if (res?.responseCode === process.env.REACT_APP_LENDING_SUCCESS_CODE) {
            //         formik.resetForm();
            //         handleStepClick(activeIndex + 1);
            //     }
            // }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const handleDragStart = (e, label) => {
        e.dataTransfer.setData('text/plain', label);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e, targetLabel) => {
        e.preventDefault();

        const draggedLabel = e.dataTransfer.getData('text/plain');
        const updatedButtons = [...buttons];

        const draggedButton = updatedButtons.find((button) => button.label === draggedLabel);
        const targetButton = updatedButtons.find((button) => button.label === targetLabel);

        if (draggedButton && targetButton) {
            // Swap the sequence numbers
            const tempSequence = draggedButton.sequence;
            draggedButton.sequence = targetButton.sequence;
            targetButton.sequence = tempSequence;

            // Sort buttons based on sequence
            updatedButtons.sort((a, b) => a.sequence - b.sequence);

            setButtons(updatedButtons);
        }
    };

    const downPaymentLov = [
        {
            name: 'Yes',
            lovId: 'Y'
        },
        {
            name: 'No',
            lovId: 'N'
        }
    ];

    //////////////////////////new UI code /////////////////////
    const loanTenureNOD = [
        { name: '90 Days', lovId: '90' },
        { name: '180 Days', lovId: '180' },
        { name: '270 Days', lovId: '270' },
        { name: '360 Days', lovId: '360' }
    ];

    const typeLov = [
        { name: 'FIXED', lovId: 'F' },
        { name: 'PERCENTAGE', lovId: 'P' }
    ];

    const paymentOnLov = [
        { name: 'Outstanding Principal', lovId: 'O' },
        { name: 'Markup', lovId: 'M' }
    ];

    /////////////////////////////////////////////////////////////
    const actionBodyTemplate = (rowData, rowIndex) => {
        return (
            <>
                <Button tooltip="Edit" tooltipOptions={{ position: 'top' }} icon="pi pi-pencil" onClick={(e) => handledEditData(e, rowData, rowIndex)} className="p-button-rounded p-button-primary mr-1" style={{ border: 'none' }} />
                <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteData(e, rowData, rowIndex)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
            </>
        );
    };

    const handledDeleteData = (e, rowData, rowIndex) => {
        e.preventDefault();
        const newFilterData = termLoanTableData?.filter((value, index) => index !== rowIndex?.rowIndex);
        setTermLoanTableData(newFilterData);
    };

    const handledEditData = (e, rowData, rowIndex) => {
        setIsEdit(true);
        setRowIndex(rowIndex?.rowIndex);
        e.preventDefault();
        const newFilterData = termLoanTableData?.filter((value, index) => index === rowIndex?.rowIndex);
        const newData = {
            noOfDays: newFilterData[0]?.noOfDays?.toString(),
            markupValue: newFilterData[0]?.markupValue,
            apr: newFilterData[0]?.apr,
            salaryMultiplier: newFilterData[0]?.salaryMultiplier,
            gracePeriod: newFilterData[0]?.gracePeriod,
            noOfDaysGracePeriod: newFilterData[0]?.noOfDaysGracePeriod,
            latePayment: newFilterData[0]?.latePayment,
            latePaymentType: newFilterData[0]?.latePaymentType,
            latePaymentCharges: newFilterData[0]?.latePaymentCharges,
            latePaymentOn: newFilterData[0]?.latePaymentOn,
            frequencyId: newFilterData[0]?.frequencyId,
            earlyPayment: newFilterData[0]?.earlyPayment,
            earlyPaymentType: newFilterData[0]?.earlyPaymentType,
            earlyPaymentCharges: newFilterData[0]?.earlyPaymentCharges,
            earlyPaymentOn: newFilterData[0]?.earlyPaymentOn,
            downPayment: newFilterData[0]?.downPayment === true ? 'Y' : 'N',
            downPaymentCharges: newFilterData[0]?.downPaymentCharges,
            repaymentTypeCode: newFilterData[0]?.repaymentTypeCode
        };
        setTermLoanData(newData);

        //////////////////////////
        let pA = [];
        Object.keys(newFilterData[0]).forEach((key) => {
            if (key === 'seqPrincipalAmount') {
                pA.push({ label: key, value: newFilterData[0]?.seqPrincipalAmount });
            }

            if (key === 'seqMarkup') {
                pA.push({ label: key, value: newFilterData[0]?.seqMarkup });
            }

            if (key === 'seqFees') {
                pA.push({ label: key, value: newFilterData[0]?.seqFees });
            }
        });

        const updatedDefinePayment = pA?.map((item) => {
            return {
                label: item.label.replace('seq', ''),
                sequence: item.value
            };
        });

        updatedDefinePayment?.sort((a, b) => a.sequence - b.sequence);
        setButtons(updatedDefinePayment);
    };

    const handledAdd = (e) => {
        e.preventDefault();

        const errors = {
            noOfDays: '',
            markupValue: '',
            apr: '',
            salaryMultiplier: '',

            gracePeriod: '',
            noOfDaysGracePeriod: '',

            latePayment: '',
            latePaymentType: '',
            latePaymentCharges: '',
            latePaymentOn: '',

            frequencyId: '',

            earlyPayment: '',
            earlyPaymentType: '',
            earlyPaymentCharges: '',
            earlyPaymentOn: '',

            downPayment: '',
            downPaymentCharges: '',

            seqPrincipalAmount: '',
            seqMarkup: '',
            seqLateFee: ''
        };

        if (termLoanData?.noOfDays === '' || termLoanData?.noOfDays === undefined || termLoanData?.noOfDays === null) {
            errors.noOfDays = 'This field is required.';
        }

        if (termLoanData?.markupValue === '' || termLoanData?.markupValue === undefined || termLoanData?.markupValue === null) {
            errors.markupValue = 'This field is required.';
        } else if (parseFloat(termLoanData?.markupValue) == 0 || parseFloat(termLoanData?.markupValue) > 100) {
            errors.markupValue = 'Value must be greater than 0 and less than or equal to 100';
        }

        if (termLoanData?.apr === '' || termLoanData?.apr === undefined || termLoanData?.apr === null) {
            errors.apr = 'This field is required.';
        } else if (parseFloat(termLoanData?.apr) == 0 || parseFloat(termLoanData?.apr) > 100) {
            errors.apr = 'Value must be greater than 0 and less than or equal to 100';
        }

        if (termLoanData?.salaryMultiplier === '' || termLoanData?.salaryMultiplier === undefined || termLoanData?.salaryMultiplier === null) {
            errors.salaryMultiplier = 'This field is required.';
        } else if (parseInt(termLoanData?.salaryMultiplier) < 1) {
            errors.salaryMultiplier = 'Please enter a valid value greater than 1';
        }

        if (termLoanData?.gracePeriod === true) {
            if (termLoanData?.noOfDaysGracePeriod === '') {
                errors.noOfDaysGracePeriod = 'This field is required';
            } else if (parseInt(termLoanData?.noOfDaysGracePeriod) < 1) {
                errors.noOfDaysGracePeriod = 'Please enter a valid value greater than 1';
            }
        }

        if (termLoanData?.latePayment === true) {
            if (termLoanData?.latePaymentType === '') {
                errors.latePaymentType = 'This field is required';
            }

            if (termLoanData?.latePaymentCharges === '') {
                errors.latePaymentCharges = 'This field is required';
            } else if (parseInt(termLoanData?.latePaymentCharges) < 1) {
                errors.latePaymentCharges = 'Please enter a valid value greater than 1';
            }

            if (termLoanData?.latePaymentOn === '') {
                errors.latePaymentOn = 'This field is required';
            }
        }

        if (termLoanData?.frequencyId === '' || termLoanData?.frequencyId === undefined || termLoanData?.frequencyId === null) {
            errors.frequencyId = 'This field is required';
        }

        if (termLoanData?.earlyPayment === true) {
            if (termLoanData?.earlyPaymentType === '') {
                errors.earlyPaymentType = 'This field is required';
            }

            if (termLoanData?.earlyPaymentCharges === '') {
                errors.earlyPaymentCharges = 'This field is required';
            } else if (parseInt(termLoanData?.earlyPaymentCharges) < 1) {
                errors.earlyPaymentCharges = 'Please enter a valid value greater than 1';
            }

            if (termLoanData?.earlyPaymentOn === '') {
                errors.earlyPaymentOn = 'This field is required';
            }
        }

        if (termLoanData?.downPayment === 'Y') {
            if (termLoanData?.downPaymentCharges === '') {
                errors.downPaymentCharges = 'This field is required';
            } else if (parseInt(termLoanData?.downPaymentCharges) < 1) {
                errors.downPaymentCharges = 'Please enter a valid value greater than 1';
            }
        }

        setTermLoanError(errors);

        const sequenceObject = buttons?.reduce((result, item) => {
            const label = item.label.replace(/\s+/g, ''); // Remove spaces from label
            const seqKey = `seq${label}`;
            result[seqKey] = item.sequence;
            return result;
        }, {});

        if (termLoanData?.gracePeriod === false || termLoanData?.gracePeriod === '') {
            termLoanData.noOfDaysGracePeriod = '';
        }

        if (termLoanData?.latePayment === false || termLoanData?.latePayment === '') {
            termLoanData.latePaymentType = '';
            termLoanData.latePaymentCharges = '';
            termLoanData.latePaymentOn = '';
        }

        if (termLoanData?.earlyPayment === false || termLoanData?.earlyPayment === '') {
            termLoanData.earlyPaymentType = '';
            termLoanData.earlyPaymentCharges = '';
            termLoanData.earlyPaymentOn = '';
        }

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            if (termLoanTableData.some((item) => item.noOfDays === termLoanData?.noOfDays)) {
                toast.warning('This No of Days already exists');
                return; // Don't proceed if there is an error
            }
            if (isEdit) {
                const newFilterData = termLoanTableData?.map((rowData, index) => {
                    if (index === rowIndex) {
                        const updatedRowData = {
                            ...rowData,
                            noOfDays: termLoanData?.noOfDays,
                            markupValue: termLoanData?.markupValue,
                            apr: termLoanData?.apr,
                            salaryMultiplier: termLoanData?.salaryMultiplier,
                            gracePeriod: termLoanData?.gracePeriod,
                            noOfDaysGracePeriod: termLoanData?.noOfDaysGracePeriod,
                            latePayment: termLoanData?.latePayment,
                            latePaymentType: termLoanData?.latePaymentType,
                            latePaymentCharges: termLoanData?.latePaymentCharges,
                            latePaymentOn: termLoanData?.latePaymentOn,
                            frequencyId: termLoanData?.frequencyId,
                            earlyPayment: termLoanData?.earlyPayment,
                            earlyPaymentType: termLoanData?.earlyPaymentType,
                            earlyPaymentCharges: termLoanData?.earlyPaymentCharges,
                            earlyPaymentOn: termLoanData?.earlyPaymentOn,
                            downPayment: termLoanData?.downPayment === 'Y' ? true : false,
                            downPaymentCharges: termLoanData?.downPaymentCharges,
                            repaymentTypeCode: stepData1?.facilityCode,
                            ...sequenceObject
                        };

                        return updatedRowData;
                    }
                    return rowData;
                });
                setTermLoanTableData(newFilterData);
                setIsEdit(false);
            } else {
                const newTermLoanTableData = {
                    noOfDays: termLoanData?.noOfDays,
                    markupValue: termLoanData?.markupValue,
                    apr: termLoanData?.apr,
                    salaryMultiplier: termLoanData?.salaryMultiplier,

                    gracePeriod: termLoanData?.gracePeriod,
                    noOfDaysGracePeriod: termLoanData?.noOfDaysGracePeriod,

                    latePayment: termLoanData?.latePayment,
                    latePaymentType: termLoanData?.latePaymentType,
                    latePaymentCharges: termLoanData?.latePaymentCharges,
                    latePaymentOn: termLoanData?.latePaymentOn,

                    frequencyId: termLoanData?.frequencyId,

                    earlyPayment: termLoanData?.earlyPayment,
                    earlyPaymentType: termLoanData?.earlyPaymentType,
                    earlyPaymentCharges: termLoanData?.earlyPaymentCharges,
                    earlyPaymentOn: termLoanData?.earlyPaymentOn,

                    downPayment: termLoanData?.downPayment === 'Y' ? true : false,
                    downPaymentCharges: termLoanData?.downPaymentCharges,

                    repaymentTypeCode: stepData1?.facilityCode
                };

                const combinedObject = { ...newTermLoanTableData, ...sequenceObject };
                setTermLoanTableData([...termLoanTableData, combinedObject]);
            }
            setTermLoanData({
                noOfDays: '',
                markupValue: '',
                apr: '',
                salaryMultiplier: '',

                gracePeriod: '',
                noOfDaysGracePeriod: '',

                latePayment: '',
                latePaymentType: '',
                latePaymentCharges: '',
                latePaymentOn: '',

                frequencyId: '',

                earlyPayment: '',
                earlyPaymentType: '',
                earlyPaymentCharges: '',
                earlyPaymentOn: '',

                downPayment: '',
                downPaymentCharges: ''
            });

            setButtons([
                { label: 'Principal Amount', sequence: 1 },
                { label: 'Markup', sequence: 2 },
                { label: 'Fees', sequence: 3 }
            ]);
        }
    };

    const handledData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        const checked = e.target.checked;
        const type = e.target.type;

        if (name === 'gracePeriod' && checked === false) {
            termLoanData.noOfDaysGracePeriod = '';
        }

        if (name === 'latePayment' && checked === false) {
            termLoanData.latePaymentType = '';
            termLoanData.latePaymentCharges = '';
            termLoanData.latePaymentOn = '';
        }

        if (name === 'earlyPayment' && checked === false) {
            termLoanData.earlyPaymentType = '';
            termLoanData.earlyPaymentCharges = '';
            termLoanData.earlyPaymentOn = '';
        }

        setTermLoanData({ ...termLoanData, [name]: type === 'checkbox' ? checked : value });

        // Clear the error message for the field being edited
        setTermLoanError({
            ...termLoanError,
            [name]: ''
        });
    };
    //////////////////////////////////////////////////////////

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>REPAYMENT METHOD/EDIT</h1>
            </div>
            <div className="card Card__Round">
                <div className="Form__Header flex align-items-center justify-content-between">
                    <h1>LOAN TENURE </h1>
                    {/* <Button
                        icon="pi pi-plus"
                        className="p-button-rounded"
                        aria-label="Filter"
                        tooltip="Add New Repayment"
                        onClick={(e) => handledAdd(e)}
                    /> */}
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="noOfDays" className="Label__Text">
                                    No. Of Days<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="noOfDays"
                                    placeholder="Select No. Of Days"
                                    options={loanTenureNOD}
                                    optionLabel="name"
                                    name="noOfDays"
                                    filter
                                    optionValue="lovId"
                                    value={termLoanData?.noOfDays || ''}
                                    onChange={handledData}
                                    className="Dropdown__Round"
                                />
                                <small className="p-error">{termLoanError?.noOfDays}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="markupValue" className="Label__Text">
                                    Markup value <span className="Label__Required">*</span>
                                </label>
                                <InputText maxLength={4} id="markupValue" placeholder="Enter Markup Value" name="markupValue" keyfilter={/^[0-9]*(\.[0-9]*)?$/} value={termLoanData?.markupValue || ''} onChange={handledData} className="Input__Round" />
                                <small className="p-error">{termLoanError?.markupValue}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="apr" className="Label__Text">
                                    APR <span className="Label__Required">*</span>
                                </label>
                                <InputText id="apr" placeholder="Enter APR" name="apr" maxLength={4} keyfilter={/^[0-9]*(\.[0-9]*)?$/} value={termLoanData?.apr || ''} onChange={handledData} className="Input__Round" />
                                <small className="p-error">{termLoanError?.apr}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="salaryMultiplier" className="Label__Text">
                                    Salary Multiplier <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="salaryMultiplier"
                                    placeholder="Enter Salary Multiplier"
                                    name="salaryMultiplier"
                                    maxLength={4}
                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                    value={termLoanData?.salaryMultiplier || ''}
                                    onChange={handledData}
                                    className="Input__Round"
                                />
                                <small className="p-error">{termLoanError?.salaryMultiplier}</small>
                            </div>
                        </div>
                    </div>
                    <div className="Form__Header col-12">
                        <h1>GRACE PERIOD </h1>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="gracePeriod" name="gracePeriod" value={termLoanData?.gracePeriod} checked={termLoanData?.gracePeriod} onChange={handledData} style={{ marginTop: '10px' }} />
                                    <label htmlFor="gracePeriod" className="Label__Text" style={{ marginTop: '10px' }}>
                                        Grace Period
                                    </label>
                                </div>
                                <small className="p-error">{termLoanError?.gracePeriod}</small>
                            </div>
                        </div>
                        {termLoanData?.gracePeriod === true && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="noOfDaysGracePeriod" className="Label__Text">
                                        Number of Days <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={4}
                                        id="noOfDaysGracePeriod"
                                        placeholder="Enter Number of Days"
                                        name="noOfDaysGracePeriod"
                                        keyfilter={'int'}
                                        value={termLoanData?.noOfDaysGracePeriod || ''}
                                        onChange={handledData}
                                        className="Input__Round"
                                    />
                                    <small className="p-error">{termLoanError?.noOfDaysGracePeriod}</small>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="Form__Header col-12">
                        <h1>LATE PAYMENT </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="latePayment" name="latePayment" value={termLoanData?.latePayment} checked={termLoanData?.latePayment} onChange={handledData} style={{ marginTop: '10px' }} />
                                    <label htmlFor="latePayment" className="Label__Text" style={{ marginTop: '10px' }}>
                                        Late payment
                                    </label>
                                </div>
                                <small className="p-error">{termLoanError?.latePayment}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        {termLoanData?.latePayment === true && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentType" className="Label__Text">
                                            Type<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="latePaymentType"
                                            placeholder="Select Type"
                                            options={typeLov}
                                            optionLabel="name"
                                            name="latePaymentType"
                                            filter
                                            optionValue="lovId"
                                            value={termLoanData?.latePaymentType || ''}
                                            onChange={handledData}
                                            className="Dropdown__Round"
                                        />
                                        <small className="p-error">{termLoanError?.latePaymentType}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentCharges" className="Label__Text">
                                            Charges <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="latePaymentCharges"
                                            placeholder="Enter charges"
                                            name="latePaymentCharges"
                                            maxLength={8}
                                            keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                            value={termLoanData?.latePaymentCharges || ''}
                                            onChange={handledData}
                                            className="Input__Round"
                                        />
                                        <small className="p-error">{termLoanError?.latePaymentCharges}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="latePaymentOn" className="Label__Text">
                                            Late Payment On<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="latePaymentOn"
                                            placeholder="Select Late Payment On"
                                            options={paymentOnLov}
                                            optionLabel="name"
                                            name="latePaymentOn"
                                            filter
                                            optionValue="lovId"
                                            value={termLoanData?.latePaymentOn || ''}
                                            onChange={handledData}
                                            className="Dropdown__Round"
                                        />
                                        <small className="p-error">{termLoanError?.latePaymentOn}</small>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {/* //////////////////////////////////////////////////// */}
                    <div className="Form__Header col-12">
                        <h1>REPAYMENT SCHEDULE </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="frequencyId" className="Label__Text">
                                    Frequency Of Payment<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="frequencyId"
                                    placeholder="Select Frequency Of Payment"
                                    options={frequencyPeriodData}
                                    optionLabel="name"
                                    name="frequencyId"
                                    filter
                                    optionValue="lovId"
                                    value={termLoanData?.frequencyId || ''}
                                    onChange={handledData}
                                    className="Dropdown__Round"
                                />
                                <small className="p-error">{termLoanError?.frequencyId}</small>
                            </div>
                        </div>
                    </div>
                    {/* ///////////////////////////////////////////////////// */}

                    <div className="Form__Header col-12">
                        <h1>ALLOW EARLY REPAYMENT </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="earlyPayment" name="earlyPayment" value={termLoanData?.earlyPayment} checked={termLoanData?.earlyPayment} onChange={handledData} style={{ marginTop: '10px' }} />
                                    <label htmlFor="earlyPayment" className="Label__Text" style={{ marginTop: '10px' }}>
                                        Early Payment
                                    </label>
                                </div>
                                <small className="p-error">{termLoanError?.earlyPayment}</small>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        <div className="p-field col-12 md:col-4 pt-3"></div>
                        {termLoanData?.earlyPayment === true && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="earlyPaymentType" className="Label__Text">
                                            Type<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="earlyPaymentType"
                                            placeholder="Select Type"
                                            options={typeLov}
                                            optionLabel="name"
                                            name="earlyPaymentType"
                                            filter
                                            optionValue="lovId"
                                            value={termLoanData?.earlyPaymentType || ''}
                                            onChange={handledData}
                                            className="Dropdown__Round"
                                        />
                                        <small className="p-error">{termLoanError?.earlyPaymentType}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="earlyPaymentCharges" className="Label__Text">
                                            Charges <span className="Label__Required">*</span>
                                        </label>
                                        <InputText
                                            id="earlyPaymentCharges"
                                            placeholder="Enter charges"
                                            name="earlyPaymentCharges"
                                            maxLength={8}
                                            keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                            value={termLoanData?.earlyPaymentCharges || ''}
                                            onChange={handledData}
                                            className="Input__Round"
                                        />
                                        <small className="p-error">{termLoanError?.earlyPaymentCharges}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="earlyPaymentOn" className="Label__Text">
                                            Early Payment On<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="earlyPaymentOn"
                                            placeholder="Select Early Payment On"
                                            options={paymentOnLov}
                                            optionLabel="name"
                                            name="earlyPaymentOn"
                                            filter
                                            optionValue="lovId"
                                            value={termLoanData?.earlyPaymentOn || ''}
                                            onChange={handledData}
                                            className="Dropdown__Round"
                                        />
                                        <small className="p-error">{termLoanError?.earlyPaymentOn}</small>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="Form__Header col-12">
                        <h1>DOWN PAYMENT </h1>
                    </div>

                    <div className="grid">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="downPayment" className="Label__Text">
                                    Select Down Payment<span className="Label__Required"></span>
                                </label>
                                <Dropdown
                                    id="downPayment"
                                    placeholder="Select Down Payment"
                                    options={downPaymentLov}
                                    optionLabel="name"
                                    name="downPayment"
                                    filter
                                    optionValue="lovId"
                                    value={termLoanData?.downPayment || ''}
                                    onChange={handledData}
                                    className="Dropdown__Round"
                                />
                                <small className="p-error">{termLoanError?.downPayment}</small>
                            </div>
                        </div>
                        {termLoanData?.downPayment === 'Y' && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="downPaymentCharges" className="Label__Text">
                                        Charges <span className="Label__Required"></span>
                                    </label>
                                    <InputText
                                        id="downPaymentCharges"
                                        placeholder="Enter Down Payment Charges"
                                        name="downPaymentCharges"
                                        maxLength={8}
                                        keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                        value={termLoanData?.downPaymentCharges || ''}
                                        onChange={handledData}
                                        className="Input__Round"
                                    />
                                    <small className="p-error">{termLoanError?.downPaymentCharges}</small>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="Form__Header">
                        <h1>DEFINE REPAYMENT SEQUENCE </h1>
                    </div>
                    <div className="Centre_Aligned_Buttons" onDragOver={handleDragOver}>
                        {buttons?.map((button) => (
                            <Button
                                key={button.label}
                                className="DraggableButton Light_Button"
                                onClick={(e) => e.preventDefault()}
                                label={`${button.label}`}
                                draggable
                                onDragStart={(e) => handleDragStart(e, button.label)}
                                onDrop={(e) => handleDrop(e, button.label)}
                            />
                        ))}
                    </div>
                    <div className="grid flex align-items-center justify-content-end">
                        <Button icon="pi pi-plus" className="p-button-rounded" aria-label="Filter" tooltip="Add New Repayment" onClick={(e) => handledAdd(e)} />
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className="card mt-6">
                                <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={termLoanTableData}>
                                    <Column field="noOfDays" header="No. Of Days" />
                                    <Column field="markupValue" header="markup value" />
                                    <Column field="apr" header="APR" />
                                    <Column header="Actions" body={actionBodyTemplate} style={{ textAlign: 'center' }} className="Table__ActionHeading__CentredAlign" />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button loadingIcon={loadingIcon} style={{ margin: '20px' }} label={'Next'} className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default EditTermLoan;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import AuthorizationHistoryTable from '../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewUserManagement() {
    const [userTypeLovData, setUserTypeLovData] = useState([]);
    const [partnerLovData, setPartnerLovData] = useState([]);
    const [partnerGroupLovData, setPartnerGroupLovData] = useState([]);

    const navigate = useNavigate();

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const userId = query.get('userId');
    const tableName = 'TBL_USER';

    const validationSchema = Yup.object().shape({
        partner: Yup.mixed().required('This field is required.'),
        userTypeId: Yup.mixed().required('This field is required.'),
        firstName: Yup.string().required('This field is required.'),
        lastName: Yup.string().required('This field is required.')
        // password: Yup.string().required('This field is required.'),
        // confirmPassword: Yup.string()
        //     .required('This field is required.')
        //     .oneOf([Yup.ref('password')], 'Passwords must match.')
    });

    const formik = useFormik({
        validationSchema,
        initialValues: {
            userTypeId: '',
            partner: '',
            partnerGroup: '',
            employeeId: '',
            firstName: '',
            lastName: '',
            userId: '',
            password: '11111111@email.com',
            confirmPassword: '11111111@email.com',
            email: '',
            dob: '',
            mobileNo: '',
            isActive: ''
        },

        onSubmit: async () => {}
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getUserData = async () => {
        const res = await handleGetRequest(`/mfs_user_management/v1/management/getuserbyid/${userId}`);
        const keyData = res?.payLoad;

        keyData.forEach((obj) => {
            Object.keys(obj).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key) && obj[key] !== null) {
                    formik.setFieldValue(key, obj[key]);
                }
            });

            if (obj.hasOwnProperty('userType') && obj.userType !== null) {
                formik.setFieldValue('userTypeId', obj.userType.toString());
            }

            if (obj.hasOwnProperty('partnerId') && obj.partnerId !== null) {
                formik.setFieldValue('partner', obj.partnerId.toString());
            }

            if (obj.hasOwnProperty('partnerGroup') && obj.partnerGroup !== null) {
                formik.setFieldValue('partnerGroup', obj.partnerGroup.toString());
            }

            if (obj.hasOwnProperty('dob') && obj.dob !== null) {
                const date = new Date(obj.dob);
                const formattedDate = date.toISOString().split('T')[0];
                formik.setFieldValue('dob', formattedDate);
            }
        });

        formik.setFieldValue('isActive', keyData[0]['isActive'] === 'Y' ? true : false);
    };

    const getUserTypeLov = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getappusertypes/LKP_APP_USER_TYPE:*`);
        setUserTypeLovData(res?.payLoad);
    };

    const getPartnerLov = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getpartners/${formik.values.userTypeId}`);
        setPartnerLovData(resp?.payLoad);
    };

    const getPartnerGroupLov = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getpartnerGroup/${formik.values.partner}`);
        setPartnerGroupLovData(resp?.payLoad);
    };

    useEffect(() => {
        if (userId !== null || userId !== 'undefined') {
            getUserData();
        }
        getUserTypeLov();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (['', null, undefined].includes(formik.values.userTypeId)) {
            return;
        } else {
            getPartnerLov();
        }
    }, [formik.values.userTypeId]); // eslint-disable-line

    useEffect(() => {
        if (['', null, undefined].includes(formik.values.partner)) {
            return;
        } else {
            getPartnerGroupLov();
        }
    }, [formik.values.partner]); // eslint-disable-line

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>VIEW DETAILS</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="userTypeId" className={classNames({ 'p-error': isFormFieldValid('userTypeId') }, 'Label__Text')}>
                                    User Type
                                    {/* <span className="Label__Required">*</span> */}
                                </label>
                                <Dropdown
                                    id="userTypeId"
                                    placeholder="Select User Type"
                                    options={userTypeLovData}
                                    optionLabel="name"
                                    name="userTypeId"
                                    optionValue="lovId"
                                    disabled={true}
                                    value={formik.values.userTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('userTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('userTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="partner" className={classNames({ 'p-error': isFormFieldValid('partner') }, 'Label__Text')}>
                                    Partner
                                    {/* <span className="Label__Required">*</span> */}
                                </label>
                                <Dropdown
                                    id="partner"
                                    placeholder="Select Partner"
                                    options={partnerLovData}
                                    optionLabel="name"
                                    name="partner"
                                    optionValue="lovId"
                                    disabled={true}
                                    value={formik.values.partner || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('partner') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('partner')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="partnerGroup" className={classNames({ 'p-error': isFormFieldValid('partnerGroup') }, 'Label__Text')}>
                                    Partner Group
                                    {/* <span className="Label__Required">*</span> */}
                                </label>
                                <Dropdown
                                    id="partnerGroup"
                                    placeholder="Select Partner Group"
                                    options={partnerGroupLovData}
                                    optionLabel="code"
                                    name="partnerGroup"
                                    optionValue="lovId"
                                    disabled={true}
                                    value={formik.values.partnerGroup || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('partnerGroup') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('partnerGroup')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="employeeId" className={classNames({ 'p-error': isFormFieldValid('employeeId') }, 'Label__Text')}>
                                    Employee ID
                                </label>
                                <InputText
                                    id="employeeId"
                                    placeholder="Enter Employee ID"
                                    name="employeeId"
                                    disabled={true}
                                    value={formik?.values?.employeeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('employeeId') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('employeeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="firstName" className={classNames({ 'p-error': isFormFieldValid('firstName') }, 'Label__Text')}>
                                    First Name
                                </label>
                                <InputText
                                    id="firstName"
                                    placeholder="Enter First Name"
                                    name="firstName"
                                    disabled={true}
                                    value={formik?.values?.firstName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('firstName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('firstName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lastName" className={classNames({ 'p-error': isFormFieldValid('lastName') }, 'Label__Text')}>
                                    Last Name
                                </label>
                                <InputText
                                    id="lastName"
                                    placeholder="Enter Last Name"
                                    name="lastName"
                                    disabled={true}
                                    value={formik?.values?.lastName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lastName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('lastName')}
                            </div>
                        </div>
                        {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="password" className={classNames({ 'p-error': isFormFieldValid('password') }, 'Label__Text')}>
                                    Password
                                </label>
                                <InputText
                                    id="password"
                                    placeholder="Enter Password"
                                    name="password"
                                    type="password"
                                    disabled={true}
                                    value={formik?.values?.password || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('password') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('password')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="confirmPassword" className={classNames({ 'p-error': isFormFieldValid('confirmPassword') }, 'Label__Text')}>
                                    Confirm Password
                                </label>
                                <InputText
                                    id="confirmPassword"
                                    placeholder="Enter Confirm Password"
                                    name="confirmPassword"
                                    type="password"
                                    disabled={true}
                                    value={formik?.values?.confirmPassword || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('confirmPassword') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('confirmPassword')}
                            </div>
                        </div> */}
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') }, 'Label__Text')}>
                                    Email
                                </label>
                                <InputText
                                    id="email"
                                    placeholder="Enter email"
                                    name="email"
                                    disabled={true}
                                    value={formik?.values?.email || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('email') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('email')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dob" className={classNames({ 'p-error': isFormFieldValid('dob') }, 'Label__Text')}>
                                    dob<span className="Label__Required">*</span>
                                </label>
                                <InputText id="dob" type="date" name="dob" disabled={true} value={formik?.values?.dob || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dob') }, 'Input__Round')} />

                                {getFormErrorMessage('dob')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="mobileNo" className={classNames({ 'p-error': isFormFieldValid('mobileNo') }, 'Label__Text')}>
                                    Mobile No.
                                </label>
                                <InputText
                                    id="mobileNo"
                                    placeholder="Enter Mobile No."
                                    name="mobileNo"
                                    disabled={true}
                                    value={formik?.values?.mobileNo || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('mobileNo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('mobileNo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                    isActive
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isActive" name="isActive" value={formik?.values?.isActive} checked={formik?.values?.isActive} onChange={formik.handleChange} disabled />
                                </div>
                                {getFormErrorMessage('isActive')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="unlocked" className={classNames({ 'p-error': isFormFieldValid('unlocked') }, 'Label__Text')}>
                                    Unlocked
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="unlocked" name="unlocked" value={formik.values.unlocked} checked={formik.values.unlocked} onChange={formik.handleChange} disabled />
                                </div>
                                {getFormErrorMessage('unlocked')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button onClick={() => navigate('/usermanagement')} label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={userId} tableName={tableName} />
        </>
    );
}
export default ViewUserManagement;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../../services/PostTemplate';
import { useRef } from 'react';

function EditMenuIconChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [reasonData, setReasonData] = useState([]);

    const [iconImageFileName, setIconImageFileName] = useState();
    const [productIconBase64, setProductIconBase64] = useState('');

    const iconImageRef = useRef(null);

    const getReasonData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getallregions/LKP_REGION:*');
        setReasonData(resp?.payLoad);
    };

    useEffect(() => {
        getReasonData();
    }, []);

    const validationSchema = Yup.object().shape({
        clientEmail: Yup.string().matches('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$', 'Please enter valid email'),
        segmentName: Yup.string().required('This field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            iconName: '',
            iconNumber: '',
            category: '',
            subCategory: '',
            active: false,
            inActive: false
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(newData, '/account/v1/segments/createsegment', true, false, 'segment'));
            if (res?.responseCode === '010000') {
                formik.resetForm();
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    // Product Icon file code
    const handledIconImage = () => {
        iconImageRef.current.click();
    };

    const handledIconImageChanged = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            setIconImageFileName(selectedFile.name); // Update the selected file name
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                setProductIconBase64(base64Data);
                console.log(base64Data); // Base64-encoded data
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>EDIT DETAIL</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="iconName" className={classNames({ 'p-error': isFormFieldValid('iconName') }, 'Label__Text')}>
                                    Icon Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="iconName"
                                    placeholder="Enter Sub category"
                                    name="iconName"
                                    value={formik?.values?.iconName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('iconName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('iconName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="iconNumber" className={classNames({ 'p-error': isFormFieldValid('iconNumber') }, 'Label__Text')}>
                                    Icon Number <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="iconNumber"
                                    placeholder="Enter Icon Number"
                                    name="iconNumber"
                                    value={formik?.values?.iconNumber?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('iconNumber') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('iconNumber')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <div className="mb-2">
                                    <label className="Label__Text">Icon Image</label>
                                    <div className="file-input-container w-100" onClick={handledIconImage}>
                                        <input type="file" style={{ display: 'none' }} ref={iconImageRef} onChange={handledIconImageChanged} accept="image/*" />
                                        <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={iconImageFileName} readOnly />
                                        <span className="file-upload-icon">
                                            <i className="pi pi-paperclip" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="category" className={classNames({ 'p-error': isFormFieldValid('category') }, 'Label__Text')}>
                                    Category
                                </label>
                                <Dropdown
                                    id="category"
                                    placeholder="Select Category"
                                    options={reasonData}
                                    optionLabel="name"
                                    name="category"
                                    optionValue="lovId"
                                    value={formik.values.category || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('category') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('category')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="subCategory" className={classNames({ 'p-error': isFormFieldValid('subCategory') }, 'Label__Text')}>
                                    Sub Category
                                </label>
                                <Dropdown
                                    id="subCategory"
                                    placeholder="Select SubCategory"
                                    options={reasonData}
                                    optionLabel="name"
                                    name="subCategory"
                                    optionValue="lovId"
                                    value={formik.values.subCategory || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('subCategory') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('subCategory')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="secondSubCategory" className={classNames({ 'p-error': isFormFieldValid('secondSubCategory') }, 'Label__Text')}>
                                    2nd Sub Category
                                </label>
                                <Dropdown
                                    id="secondSubCategory"
                                    placeholder="Select Second Sub Category"
                                    options={reasonData}
                                    optionLabel="name"
                                    name="secondSubCategory"
                                    optionValue="lovId"
                                    value={formik.values.secondSubCategory || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('secondSubCategory') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('secondSubCategory')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('status') }, 'Label__Text')}>
                                    Status
                                </label>
                                <div className="field-checkbox flex">
                                    <div className="mr-5">
                                        <Checkbox inputId="active" name="active" value={formik?.values?.active} checked={formik?.values?.active} onChange={formik.handleChange} style={{ marginTop: '10px' }} />
                                        <label htmlFor="active" className={classNames({ 'p-error': isFormFieldValid('active') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                            Active
                                        </label>
                                    </div>
                                    <div>
                                        <Checkbox inputId="inActive" name="inActive" value={formik?.values?.inActive} checked={formik?.values?.inActive} onChange={formik.handleChange} style={{ marginTop: '10px' }} />
                                        <label htmlFor="inActive" className={classNames({ 'p-error': isFormFieldValid('inActive') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                            In Active
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="submit" label="Update" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default EditMenuIconChecker;

import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { useFormik } from 'formik';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ProductCatalogCheckerView() {
    const [iState, setIState] = useState(null);
    const [uState, setUState] = useState(null);
    const [listOfTransaction, setListOfTransaction] = useState([]);
    console.log("setustate", uState)
    
    const navigate = useNavigate();

    //get all data from the url
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const formik = useFormik({
        initialValues: {
            transactionList:[]
        },
        onSubmit: async (data) => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        productCatalogId: data.productCatalogId,
                        productCatalogDescription: data.productCatalogDescription,
                        // transactionList:arrayOfTransactionIds,
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
        }
    });

    const goBack = () => {
       navigate('/requestchecker')
   }  
   //getting the list of all transaction for the dropdown
    const getTransactionListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setListOfTransaction(res?.payLoad);
    };

    //get Data by table Id
    const getDatabyId = async () => {
        const resp = await handleGetRequest(`/productcatalog/v1/products/getproductbyid/${refTableId}`);
        const keyData = resp?.payLoad;
         setIState(keyData);
         if (keyData) {
             let transactionListId = keyData && keyData.tblProductCatalogDetails.map((tblid) => {
                 return {
                     'lovId': tblid.tblProduct.productId.toString(),
                 }
             })
             formik.setFieldValue('transactionList', transactionListId);
        }
        
    };

    //Get data by mc request Id
    const getMcRequestDataById = async () => {
        const res = await handleGetRequest(`/productcatalog/v1/products/getproductbymcrequestid/${mcRequestId}`);
        const keyData = res?.payLoad;
        setUState(keyData);
        if (keyData) {
            let transactionListId = keyData && keyData.transactionList.map((tblid) => {
                return {
                    'lovId': tblid.toString(),
                }
            })   
            formik.setFieldValue('transactionList', transactionListId);
        }
    };
    
    useEffect(() => {
        getTransactionListData();
    }, []);

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, [listOfTransaction])
    
    return (
        <>
            <div className="card Card__Round">
                <form className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>View Detail</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productCatalogName" className="Label__Text">
                                    Name<span className="Label__Required">*</span>
                                </label>
                                <InputText id="productCatalogName" name="productCatalogName"  placeholder={(iState && iState?.productCatalogName) || (uState && uState?.productCatalogName)} className="Input__Round" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productCatalogDescription" className="Label__Text">
                                    Description<span className="Label__Required">*</span>
                                </label>
                                <InputText id="productCatalogDescription" name="productCatalogDescription"  placeholder={requestType==='I'?(iState && iState?.description):(uState && uState?.productCatalogDescription)} className="Input__Round" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="transactionList" className="Label__Text">
                                    List of transactions<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="transactionList"
                                    placeholder="Select Transaction List"
                                    options={listOfTransaction}
                                    optionLabel="name"
                                    name="transactionList"
                                    optionValue="lovId"
                                    filter
                                    value={formik.values.transactionList.map((trans) => trans.lovId)}
                                   
                                    className="Dropdown__Round"
                                   
                                />
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button onClick={goBack} label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default ProductCatalogCheckerView;

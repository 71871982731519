import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { handleGetRequest } from '../../../../../services/GetTemplate';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';

function ViewProduct({ activeIndex, handleStepClick, lendingData, setStepData1, lendingIdData }) {
    const [categoryLov, setCategoryLov] = useState([]);
    const [lmsCollateralLov, setCollateralLov] = useState([]);
    const [lmsFacilityTypeLov, setlmsFacilityTypeLov] = useState([]);
    const [lmsProductCategoryLov, setlmsProductCategoryLov] = useState([]);

    const [channelLov, setChannelLov] = useState([]);
    const [segmentLov, setSegmentLov] = useState([]);
    const [ruleSetLov, setRuleSetLov] = useState([]);

    const getLmsCategoryLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/lending/LKP_LMS_CATEGORY');
        setCategoryLov(res?.payLoad);
    };

    const getLmsCollateralLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/lending/LKP_LMS_COLLATERAL');
        setCollateralLov(res?.payLoad);
    };

    const getLmsFacilityTypeLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/lending/LKP_LMS_FACILITY_TYPE');
        setlmsFacilityTypeLov(res?.payLoad);
    };

    const getLmsProductCategoryLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/lending/LKP_LMS_PRODUCT_CATEGORY');
        setlmsProductCategoryLov(res?.payLoad);
    };

    const getLmsChannelLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getchannels/LKP_CHANNEL:*');
        setChannelLov(res?.payLoad);
    };

    const getLmsSegmentLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getsegments/LKP_SEGMENT:*');
        setSegmentLov(res?.payLoad);
    };

    const getLmsRuleSetLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/lending/TBL_RULE');
        setRuleSetLov(res?.payLoad);
    };

    useEffect(() => {
        getLmsCategoryLov();
        getLmsCollateralLov();
        getLmsFacilityTypeLov();
        getLmsProductCategoryLov();
        getLmsChannelLov();
        getLmsSegmentLov();
        getLmsRuleSetLov();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (lendingData) {
            formik.setFieldValue('name', lendingData?.lmsProductName);

            formik.setFieldValue('description', lendingData?.description);
            formik.setFieldValue('categoryId', lendingData?.lkpLmsCategory?.lmsCategoryId?.toString());
            formik.setFieldValue('productCategoryId', lendingData?.lkpLmsProductCategory?.lmsProductCategoryId?.toString());
            formik.setFieldValue('ruleSetId', lendingData?.tblRule?.ruleId?.toString());

            if (lendingData?.salaryCycleDate !== null) {
                formik.setFieldValue('salaryDisbursmentDate', true);
            } else {
                formik.setFieldValue('salaryDisbursmentDate', false);
            }
            // formik.setFieldValue('salaryCycleDate', lendingData?.salaryCycleDate?.toString());
            formik.setFieldValue('salaryCycleDate', new Date(2024, 0, lendingData?.salaryCycleDate));
            formik.setFieldValue('dbrCaping', lendingData?.dbrCaping?.toString());

            const selectedProductChannels = lendingData?.tblLmsProductChannels
                ?.filter((itm) => itm?.isActive === 'Y')
                ?.map((item) => {
                    return { lovId: item?.lkpChannel?.channelId?.toString() };
                });

            const selectedSegments = lendingData?.tblLmsProductSegments
                ?.filter((itm) => itm?.isActive === 'Y')
                ?.map((item) => {
                    return { lovId: item?.lkpSegment?.segmentId?.toString() };
                });

            formik.setFieldValue('channelIds', selectedProductChannels);
            formik.setFieldValue('segmentIds', selectedSegments);

            formik.setFieldValue('maxAmount', lendingData?.maxAmount);
            formik.setFieldValue('minAmount', lendingData?.minAmount);

            formik.setFieldValue('serviceChargesType', lendingData?.processingFeeType);
            formik.setFieldValue('serviceChargesValue', lendingData?.processingFeeValue);

            formik.setFieldValue('facilityCode', lendingData?.lkpLmsFacilityType?.lmsFacilityTypeCode);
            formik.setFieldValue('collateralId', lendingData?.lkpLmsCollateral?.lmsCollateralId?.toString());

            formik.setFieldValue('balance', lendingData?.balance);
            formik.setFieldValue('unitValue', lendingData?.unitValue);
            formik.setFieldValue('unitPrice', lendingData?.unitPrice);
        }
    }, [lendingData]); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            categoryId: '',
            productCategoryId: '',
            ruleSetId: '',
            facilityCode: '',
            collateralId: '',
            balance: '',
            unitValue: '',
            unitPrice: '',
            channelIds: [],
            segmentIds: [],

            salaryCycleDate: new Date(2024, 0, 1),
            salaryDisbursmentDate: false,
            dbrCaping: ''
        },

        onSubmit: async (data) => {
            data['currentStep'] = '1';
            data['balance'] = parseInt(data?.balance);
            data['lmsProductId'] = lendingIdData;

            const arrayOfChannelIds = formik?.values?.channelIds.map((item) => parseInt(item?.lovId));
            data['channelIds'] = arrayOfChannelIds;

            const arrayOfSegmentsIds = formik?.values?.segmentIds.map((item) => parseInt(item?.lovId));
            data['segmentIds'] = arrayOfSegmentsIds;

            setStepData1(data);

            handleStepClick(activeIndex + 1);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const serviceChargesTypeLov = [
        { name: 'FIXED', value: 'F' },
        { name: 'PERCENTAGE', value: 'P' }
    ];

    return (
        <>
            <div className="Form__Header" style={{ background: '#13a28a', borderRadius: '5px', paddingTop: '12px' }}>
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>PRODUCT/EDIT</h1>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                    Name <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    id="name"
                                    placeholder="Enter  Name"
                                    name="name"
                                    disabled={true}
                                    maxLength={100}
                                    value={formik?.values?.name || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('name')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-8 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') }, 'Label__Text')}>
                                    Description <span className="Label__Required"></span>
                                </label>
                                <InputText
                                    id="description"
                                    placeholder="Enter Description"
                                    name="description"
                                    disabled={true}
                                    value={formik?.values?.description || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('description') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('description')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="categoryId" className={classNames({ 'p-error': isFormFieldValid('categoryId') }, 'Label__Text')}>
                                    Category
                                </label>
                                <Dropdown
                                    id="categoryId"
                                    placeholder="Select Category"
                                    options={categoryLov}
                                    optionLabel="name"
                                    disabled={true}
                                    name="categoryId"
                                    optionValue="lovId"
                                    filter
                                    value={formik?.values?.categoryId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('categoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('categoryId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="productCategoryId" className={classNames({ 'p-error': isFormFieldValid('productCategoryId') }, 'Label__Text')}>
                                    Product Category
                                </label>
                                <Dropdown
                                    id="productCategoryId"
                                    placeholder="Select Product Category"
                                    options={lmsProductCategoryLov}
                                    optionLabel="name"
                                    disabled={true}
                                    name="productCategoryId"
                                    optionValue="lovId"
                                    filter
                                    value={formik?.values?.productCategoryId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productCategoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('productCategoryId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="ruleSetId" className={classNames({ 'p-error': isFormFieldValid('ruleSetId') }, 'Label__Text')}>
                                    Rule Set
                                </label>
                                <Dropdown
                                    id="ruleSetId"
                                    placeholder="Select Rule Set"
                                    options={ruleSetLov}
                                    optionLabel="name"
                                    disabled={true}
                                    name="ruleSetId"
                                    optionValue="lovId"
                                    filter
                                    value={formik?.values?.ruleSetId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('ruleSetId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('ruleSetId')}
                            </div>
                        </div>
                        <div style={{ backgroundColor: '#edf0f5', padding: '2rem' }} className=" col-12 card ">
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="channelIds" className={classNames({ 'p-error': isFormFieldValid('channelIds') }, 'Label__Text')}>
                                            Channel Based
                                        </label>
                                        <MultiSelect
                                            id="channelIds"
                                            placeholder="Select Channels"
                                            options={channelLov}
                                            optionLabel="name"
                                            filter
                                            name="channelIds"
                                            optionValue="lovId"
                                            value={formik?.values?.channelIds.map((trans) => trans.lovId || '')}
                                            className={classNames({ 'p-invalid': isFormFieldValid('channelIds') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('channelIds')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="segmentIds" className={classNames({ 'p-error': isFormFieldValid('segmentIds') }, 'Label__Text')}>
                                            Segment Based
                                        </label>
                                        <MultiSelect
                                            id="segmentIds"
                                            placeholder="Select Segments"
                                            options={segmentLov}
                                            optionLabel="name"
                                            name="segmentIds"
                                            filter
                                            optionValue="lovId"
                                            value={formik?.values?.segmentIds.map((trans) => trans.lovId || '')}
                                            className={classNames({ 'p-invalid': isFormFieldValid('segmentIds') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('segmentIds')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="maxAmount" className={classNames({ 'p-error': isFormFieldValid('maxAmount') }, 'Label__Text')}>
                                            Max Amount
                                        </label>
                                        <InputText
                                            id="maxAmount"
                                            placeholder="Enter Max Amount"
                                            name="maxAmount"
                                            disabled={true}
                                            value={formik?.values?.maxAmount || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('maxAmount') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('maxAmount')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="minAmount" className={classNames({ 'p-error': isFormFieldValid('minAmount') }, 'Label__Text')}>
                                            Min Amount
                                        </label>
                                        <InputText
                                            id="minAmount"
                                            placeholder="Enter Min Amount"
                                            name="minAmount"
                                            disabled={true}
                                            value={formik?.values?.minAmount || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('minAmount') }, 'Input__Round')}
                                        />

                                        {getFormErrorMessage('minAmount')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="serviceChargesType" className={classNames({ 'p-error': isFormFieldValid('serviceChargesType') }, 'Label__Text')}>
                                            Service Charges Type
                                        </label>
                                        <Dropdown
                                            id="serviceChargesType"
                                            placeholder="Select Service Charges Type"
                                            options={serviceChargesTypeLov}
                                            optionLabel="name"
                                            disabled={true}
                                            name="serviceChargesType"
                                            optionValue="value"
                                            value={formik.values.serviceChargesType}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('serviceChargesType') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('serviceChargesType')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="serviceChargesValue" className={classNames({ 'p-error': isFormFieldValid('serviceChargesValue') }, 'Label__Text')}>
                                            Service Charges Value
                                        </label>
                                        <InputText
                                            id="serviceChargesValue"
                                            placeholder="Enter Service Charges Value"
                                            name="serviceChargesValue"
                                            disabled={true}
                                            value={formik?.values?.serviceChargesValue || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('serviceChargesValue') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('serviceChargesValue')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field flex">
                                <div className="field-checkbox">
                                    <Checkbox inputId="salaryDisbursmentDate" disabled name="salaryDisbursmentDate" value={formik.values.salaryDisbursmentDate} checked={formik.values.salaryDisbursmentDate} onChange={formik.handleChange} />
                                </div>
                                <label htmlFor="salaryDisbursmentDate" className={classNames({ 'p-error': isFormFieldValid('salaryDisbursmentDate') }, 'Label__Text')}>
                                    Salary Disbursement Date
                                </label>
                                {getFormErrorMessage('salaryDisbursmentDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>
                        <div className="p-fluid p-formgrid grid mb-5 col-12">
                            {formik?.values?.salaryDisbursmentDate === true && (
                                <div style={{ backgroundColor: '#edf0f5', padding: '1rem' }} className=" col-12 card ">
                                    <div className="p-field col-12 md:col-4 pt-3">
                                        <div className="p-field">
                                            <label htmlFor="salaryCycleDate" className={classNames({ 'p-error': isFormFieldValid('salaryCycleDate') }, 'Label__Text')}>
                                                Date
                                            </label>
                                            {/* <Dropdown
                                                disabled
                                                id="salaryCycleDate"
                                                placeholder="Select Disbursment Date"
                                                options={disbursmentDate}
                                                optionLabel="name"
                                                name="salaryCycleDate"
                                                optionValue="lovId"
                                                value={formik.values.salaryCycleDate}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('salaryCycleDate') }, 'Dropdown__Round')}
                                            /> */}
                                            <Calendar
                                                id={'salaryCycleDate'}
                                                name={'salaryCycleDate'}
                                                minDate={new Date(2024, 0, 1)} // January 1, 2024
                                                maxDate={new Date(2024, 0, 28)} // January 28, 2024
                                                readOnlyInput
                                                value={formik?.values?.salaryCycleDate}
                                                // view="day"
                                                disabled
                                                dateFormat="dd"
                                                onChange={formik.handleChange}
                                                // onChange={(e) => {
                                                //     setDate(e.value);
                                                // }}
                                                panelClassName={'lendingProductCalendarPanel'}
                                                className={classNames({ 'p-invalid': isFormFieldValid('salaryCycleDate') }, 'Dropdown__Round')}
                                            />
                                            {getFormErrorMessage('salaryCycleDate')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3"></div>
                                    <div className="p-field col-12 md:col-4 pt-3"></div>
                                </div>
                            )}
                        </div>
                        .
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="facilityCode" className={classNames({ 'p-error': isFormFieldValid('facilityCode') }, 'Label__Text')}>
                                    Type of Facility
                                </label>
                                <Dropdown
                                    id="facilityCode"
                                    placeholder="Select Facility"
                                    options={lmsFacilityTypeLov}
                                    optionLabel="name"
                                    disabled={true}
                                    name="facilityCode"
                                    optionValue="code"
                                    value={formik.values.facilityCode}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('facilityCode') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('facilityCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dbrCaping" className={classNames({ 'p-error': isFormFieldValid('dbrCaping') }, 'Label__Text')}>
                                    DBR Percentage<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    id="dbrCaping"
                                    placeholder="Enter DBR Percentage"
                                    name="dbrCaping"
                                    keyfilter={'int'}
                                    maxLength={20}
                                    value={formik?.values?.dbrCaping || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dbrCaping') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dbrCaping')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="collateralId" className={classNames({ 'p-error': isFormFieldValid('collateralId') }, 'Label__Text')}>
                                    Security/Collateral
                                </label>
                                <Dropdown
                                    id="collateralId"
                                    placeholder="Select Collateral"
                                    options={lmsCollateralLov}
                                    optionLabel="name"
                                    disabled={true}
                                    name="collateralId"
                                    optionValue="lovId"
                                    value={formik?.values?.collateralId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('collateralId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('collateralId')}
                            </div>
                        </div>
                        {formik.values.collateralId === '1' && (
                            <div style={{ backgroundColor: '#edf0f5', padding: '2rem' }} className=" col-12 card">
                                <div className="p-fluid p-formgrid grid mb-5">
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="unitValue" className={classNames({ 'p-error': isFormFieldValid('unitValue') }, 'Label__Text')}>
                                                Unit Value
                                            </label>
                                            <InputText
                                                id="unitValue"
                                                placeholder="Enter Unit Value"
                                                name="unitValue"
                                                disabled={true}
                                                value={formik?.values?.unitValue || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('unitValue') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('unitValue')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="unitPrice" className={classNames({ 'p-error': isFormFieldValid('unitPrice') }, 'Label__Text')}>
                                                Unit Price
                                            </label>
                                            <InputText
                                                id="unitPrice"
                                                placeholder="Enter Unit Price"
                                                name="unitPrice"
                                                disabled={true}
                                                value={formik?.values?.unitPrice || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('unitPrice') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('unitPrice')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {formik.values.collateralId === '3' && (
                            <>
                                <div style={{ backgroundColor: '#edf0f5', padding: '2rem' }} className=" col-12 card">
                                    <div className="p-fluid p-formgrid grid mb-5">
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="balance" className={classNames({ 'p-error': isFormFieldValid('balance') }, 'Label__Text')}>
                                                    Balance
                                                </label>
                                                <InputText
                                                    id="balance"
                                                    placeholder="Enter Balance"
                                                    name="balance"
                                                    disabled={true}
                                                    value={formik?.values?.balance || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('balance') }, 'Input__Round')}
                                                />

                                                {getFormErrorMessage('balance')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {/* <div className="Down__Btn">
                        <Button disabled={loading} label={lendingData?.currentStep >= 0 ? "Next" : "Save"} className="Btn__Dark" />
                    </div> */}
                </form>
            </div>
        </>
    );
}
export default ViewProduct;

import { baseURL } from '../Config';
import axios from 'axios';
import { toast } from 'react-toastify';
import { trimData } from '../utilities/TrimPayloadData';
import { loadingAction } from '../redux/actions/loadingAction';
import { showMessageAction } from '../redux/actions/showMessageAction';
import store from '../redux/store';

export const handlePutRequest =
    (data, url, isShowLoad = false, isShowToast = true, nextPageToLoad = '') =>
    async (dispatch) => {
        data = await trimData(data);
        try {
            if (isShowLoad) dispatch(loadingAction(true));

            const response = await axios({
                method: 'put',
                url: `${baseURL + url}`,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                    // Authorization: `Bearer oBMLUgzWWOl5xl4K0KZRZpxpVUTj9Wd8jlxjJEY+q3Ga1rBKVisip76IKq52cnKp/qRyecP4NIzs+zETO7izpvqN7pRIex/CMDLco63aVdMYNpe+E31KQTBeZ00uiCdCcWgE0CPRRupo6+BJhWiQKQ==`
                }
            });
            if (isShowToast) {
                toast.success(response?.data?.messages);
            } else {
                store.dispatch(showMessageAction({ isShowModal: true, message: response?.data?.messages || 'Success', isError: false, url: nextPageToLoad }));
            }
            if (isShowLoad) dispatch(loadingAction(false));
            return response?.data;
        } catch (error) {
            if (isShowLoad) dispatch(loadingAction(false));
            if (isShowToast === false) {
                store.dispatch(
                    showMessageAction({
                        isShowModal: true,
                        message: error?.response?.data?.message || 'Something went wrong !!',
                        isError: true
                    })
                );
            } else if (error?.response?.status === 500 && isShowToast) {
                toast.warn(error?.response?.data?.message || error?.response?.data?.messages || error?.response?.data?.error || 'Something went wrong !!');
            } else if (error?.response?.status === 400 && isShowToast) toast.warn(error?.response?.data?.message || error?.response?.data?.messages || error?.response?.data?.error || 'Something went wrong !!');
            else toast.warn(error?.response?.data?.message || 'Something went wrong !!');
            return error?.response;
        }
    };

import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewLoginSet() {
    const navigate = useNavigate();

    const location = useLocation();
    const { rowData } = location.state || {};
    const tableName = 'TBL_CMS_LOGIN_LIMIT';

    const validationSchema = Yup.object().shape({
        noOfAttempts: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[0-9]*$/, 'Only Numbers are allowed')
            .required('This field is required'),

        time: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .required('This field is required'),

        blockTime: Yup.string().required('This field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cmsLoginLimitId: '',
            noOfAttempts: '',
            time: '',
            isTemporaryBlock: false,
            isPermanentBlock: false,
            blockTime: '',
            isActive: ''
        }
    });

    const getMainCategoryData = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/getloginlimitsbyid/${rowData?.cmsLoginLimitId}`);
        const keyData = res?.payLoad;
        if (keyData) {
            formik.setFieldValue('cmsLoginLimitId', keyData?.cmsLoginLimitId || '');
            formik.setFieldValue('noOfAttempts', keyData?.noOfAttempts || '');
            const formattedTime = convertTo24HourFormat(keyData?.time || '');
            formik.setFieldValue('time', formattedTime || '');
            formik.setFieldValue('isTemporaryBlock', keyData?.isTemporaryBlock === 'Y' ? true : false);
            formik.setFieldValue('isPermanentBlock', keyData?.isPermanentBlock === 'Y' ? true : false);
            formik.setFieldValue('blockTime', keyData?.blockTime || '');
            formik.setFieldValue('isActive', keyData?.isActive || '');
        }
    };
    function convertTo24HourFormat(time12Hour) {
        const [time, period] = time12Hour?.split(' ');
        const [hours, minutes] = time?.split(':');
        let formattedHours = hours;
        if (period?.toUpperCase() === 'PM' && hours !== '12') {
            formattedHours = String(Number(hours) + 12);
        } else if (period?.toUpperCase() === 'AM' && hours === '12') {
            formattedHours = '00';
        }

        return `${formattedHours}:${minutes}`;
    }

    useEffect(() => {
        getMainCategoryData();
    }, [rowData]); // eslint-disable-line

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>VIEW DETAIL</h2>
                        </div>
                        <div className="p-field col-12 md:col-6 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="noOfAttempts" className="Label__Text">
                                    Attempts<span className="Label__Required">*</span>
                                </label>
                                <InputText maxLength={100} id="noOfAttempts" placeholder="Enter Attempts" name="noOfAttempts" value={formik?.values?.noOfAttempts || ''} disabled onChange={formik.handleChange} className="Input__Round" />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-6  pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="time" className="Label__Text">
                                    Enter Time<span className="Label__Required">*</span>
                                </label>
                                <InputText id="time" name="time"  placeholder="Enter Time In Minutes"  value={formik?.values?.time} onChange={formik.handleChange} disabled className="Input__Round" />
                            </div>
                        </div>

                        <div className="Form__Header Full__Width">
                            <h2>BREACH LIMITS</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-5 pb-3">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="isTemporaryBlock" name="isTemporaryBlock" checked={formik?.values?.isTemporaryBlock} onChange={formik.handleChange} style={{ marginTop: '10px' }} disabled />{' '}
                                    <label htmlFor="isTemporaryBlock" className="Label__Text" style={{ marginTop: '10px' }}>
                                        Temporary Block
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="blockTime" className="Label__Text">
                                Enter  Time 

                                </label>
                                <InputText maxLength={100} id="blockTime" placeholder="Enter Time In Minutes" name="blockTime" value={formik?.values?.blockTime || ''} onChange={formik.handleChange} className="Input__Round" disabled />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>
                        {/* <div className="p-field col-12 md:col-4 pt-3 pb-3"></div> */}

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="isPermanentBlock" name="isPermanentBlock" checked={formik?.values?.isPermanentBlock} onChange={formik.handleChange} style={{ marginTop: '10px' }} disabled />{' '}
                                    <label htmlFor="isPermanentBlock" className="Label__Text" style={{ marginTop: '10px' }}>
                                        Permanent Block
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="button" onClick={() => navigate('/loginset')} label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={rowData?.cmsLoginLimitId} tableName={tableName} />
        </>
    );
}

export default ViewLoginSet;

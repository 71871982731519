import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DefaultComponent from './DefaultComponent';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { useDispatch } from 'react-redux';

function MakerApis({ type }) {
    const [getByIdData, setGetByIdData] = useState([]);
    const [withoutPending, setWithoutPending] = useState([]);

    const dispatch = useDispatch();
    const location = useLocation();

    const { accountLevelName, ultraCustomerId } = location?.state?.rowData;
    const { filteredStatus, discrepantStatusList } = location?.state;
    const funcGetByIdData = async () => {
        const plane = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    ultraCustomerId: ultraCustomerId,
                    accountLevelName: accountLevelName
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const resp = await dispatch(handlePostRequest(plane, '/account/v1/customeraccounts/getpendingcustomerbyid'));
        const keyData = resp?.payLoad;
        setGetByIdData(keyData);
    };

    useEffect(() => {
        if (ultraCustomerId) {
            funcGetByIdData();
        }
    }, [ultraCustomerId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (discrepantStatusList) {
            const filterData = discrepantStatusList?.filter((item) => item.name !== 'Pending');
            setWithoutPending(filterData);
        }
    }, [discrepantStatusList]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <DefaultComponent type={type} responseData={getByIdData} statusLovData={filteredStatus} discrepantStatusList={withoutPending} />
        </React.Fragment>
    );
}
export default MakerApis;

import React, { useState } from 'react';
import { Button } from 'primereact/button';
import './../forgetpassword/Forget.scss';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../services/PostTemplate';
import { useNavigate } from 'react-router-dom';

const GenerateOTP = ({ handleGenerateOtp }) => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.email) {
                errors.email = 'Email is required.';
            }
            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        email: data?.email,
                        otpTypeCode: 'RPD'
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/mfs_user_management/v1/management/generateotp', true, true));

            setloading(false);
            setloadingIcon('pi pi-save');
            formik.resetForm();
            if (res?.responseCode === '0000') {
                handleGenerateOtp(res?.payLoad);
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <div className="Forget__Body">
            <div className="container" id="container">
                <div className="form-container sign-in-container">
                    <form action="#" className="Login__Form" onSubmit={formik.handleSubmit}>
                        <div className="p-mb-4">
                            <h3 className="forgetHeading">FORGOT PASSWORD</h3>
                        </div>
                        <div className="p-mt-4">
                            <div className="p-text-left">
                                <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') })}>
                                    Email
                                </label>
                                <InputText className={classNames({ 'p-invalid': isFormFieldValid('email') }, 'Forget__Input')} name="email" placeholder="Enter Email" value={formik.values.email} onChange={formik.handleChange} type="email" />
                                {getFormErrorMessage('email')}
                            </div>
                            <div className="p-mt-4">
                                <Button type="submit" loadingIcon={loadingIcon} disabled={loading} className="Login__Button" label="Submit" iconPos="right" />
                                <Button className="Cancel_Pass" label="Cancel" iconPos="right" onClick={() => navigate('/')} style={{ height: '45px' }} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default GenerateOTP;

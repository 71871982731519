import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import ColorPicker from 'react-pick-color';

function ViewProductChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [productIconFileName, setProductIconFileName] = useState();

    const [mediaElement, setMediaElement] = useState([]);
    const [btnType, setBtnType] = useState([]);
    const [mainData, setMainData] = useState([]);
    const [subData, setSubData] = useState([]);
    const [secSubData, setSecSubData] = useState([]);
    const [showColorPicker, setShowColorPicker] = useState({ textColor: false, bgColor: false });

    const [statusLovData, setStatusLovData] = useState([]);
    const [mediaElementType, setMediaElementType] = useState(null);

    let { search } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const query = new URLSearchParams(search);
    const checkerComments = query.get('checkerComments');
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    // const mediaElementData = [
    //     { name: 'Button', lovId: '1' },
    //     { name: 'Field', lovId: '2' }
    // ];

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('This field is required'),
        checkerComments: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
            .required('This field is required')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cmsProductId: '',
            cmsProductName: '',
            // displayNameEnglish: '',
            displayNameUrdu: '',
            productId: '',
            priority: '',
            productIcon: '',
            addCheck: false,
            cmsTagLabel: '',
            copyEngToUrdu:false,

            cmsTagTextColor: '',
            cmsTagTextBgColor: '',
            tagExpiryDate: '',
            cmsMainCategoryId: '',
            cmsSubCategoryId: '',
            cmsSecondSubCategoryId: '',
            partialPaymentEn: '',
            iconFileName: '',
            isHide: '',
            tblCmsProductFields: [
                {
                    cmsProductFieldsId: '',
                    cmsMediaElementId: '',
                    cmsMediaButtonTypeId: '',
                    cmsMediaButtonTypeText: '',
                    cmsMediaFieldTypeId: '',
                    cmsMediaFieldTypeTitle: '',
                    fieldCharacterLimit: '',
                    fieldPlaceholderValue: '',
                    fieldMandatory: 'Y'
                }
            ]
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const addPayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        objectBody: {
                            cmsProductId: formik.values.cmsProductId,
                            isActive: requestType === 'I' ? (formik?.values?.action == '2' ? 'Y' : 'N') : formik?.values?.isActive
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: ''
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const updatePayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        objectBody: {
                            cmsProductId: data?.cmsProductId,
                            cmsProductName: data?.cmsProductName,
                            // displayNameEnglish: data?.displayNameEnglish,
                            displayNameUrdu: data?.displayNameUrdu,
                            productId: data?.productId,
                            priority: data?.priority,
                            productIcon: data?.productIcon,
                            cmsTagLabel: data?.cmsTagLabel,
                            cmsTagTextColor: data?.cmsTagTextColor,
                            cmsTagTextBgColor: data?.cmsTagTextBgColor,
                            tagExpiryDate: data?.tagExpiryDate,
                            cmsMainCategoryId: data?.cmsMainCategoryId,
                            cmsSubCategoryId: data?.cmsSubCategoryId,
                            cmsSecondSubCategoryId: data?.cmsSecondSubCategoryId,
                            partialPaymentEn: data?.partialPaymentEn,
                            iconFileName: data?.iconFileName,
                            isHide: data?.isHide,
                            tblCmsProductFields: data?.tblCmsProductFields
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: ''
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const enableDisablePayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        objectBody: {
                            cmsProductId: data?.cmsProductId,
                            cmsProductName: data?.cmsProductName,
                            // displayNameEnglish: data?.displayNameEnglish,
                            displayNameUrdu: data?.displayNameUrdu,
                            productId: data?.productId,
                            priority: data?.priority,
                            productIcon: data?.productIcon,
                            cmsTagLabel: data?.cmsTagLabel,
                            cmsTagTextColor: data?.cmsTagTextColor,
                            cmsTagTextBgColor: data?.cmsTagTextBgColor,
                            tagExpiryDate: data?.tagExpiryDate,
                            cmsMainCategoryId: data?.cmsMainCategoryId,
                            cmsSubCategoryId: data?.cmsSubCategoryId,
                            cmsSecondSubCategoryId: data?.cmsSecondSubCategoryId,
                            partialPaymentEn: data?.partialPaymentEn,
                            iconFileName: data?.iconFileName,
                            isHide: data?.isHide,
                            isActive: data?.isActive,
                            tblCmsProductFields: data?.tblCmsProductFields
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: ''
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const formData = new FormData();

            if (requestType === 'I') {
                formData.append('request', JSON.stringify(addPayload));
            } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
                formData.append('request', JSON.stringify(updatePayload));
            } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
                formData.append('request', JSON.stringify(enableDisablePayload));
            }
            await dispatch(handlePostRequest(formData, '/cms/v1/cms/productvalidatorcheckeraction', true, false, 'requestchecker'));

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });
    const getMediaButtonElementId = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_MEDIA_ELEMENT');
        setMediaElementType(resp?.payLoad);
    };

    const getMediaElement = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_MEDIA_ELEMENT');
        setMediaElement(resp?.payLoad);
    };

    const getBtnType = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_MEDIA_BUTTON_TYPE');
        setBtnType(resp?.payLoad);
    };

    const getCategoryDataLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcategorydatalov');
        setMainData(res?.payLoad);
    };

    const getSubCategoryDataLov = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getsubcategorydatalov/${formik?.values?.cmsMainCategoryId}`);
        setSubData(res?.payLoad);
    };

    const getSecSubCategoryDataLov = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getsecondsubcategorydatalov/${formik.values.cmsMainCategoryId}/${formik.values.cmsSubCategoryId}`);
        setSecSubData(res?.payLoad);
    };

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        getStatusLov();

        getMediaElement();
        getBtnType();
        getCategoryDataLov();
        getMediaButtonElementId();
        if (formik?.values?.cmsMainCategoryId != '') {
            getSubCategoryDataLov();
        }
        if (formik?.values?.cmsSubCategoryId != '') {
            getSecSubCategoryDataLov();
        }
    }, [formik?.values?.cmsMainCategoryId, formik?.values?.cmsSubCategoryId]);

    const getProductDetailsById = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/getproductbyid/${refTableId}`);
        const productDetails = res?.payLoad;
        if (productDetails) {
            formik.setFieldValue('cmsProductId', productDetails.cmsProductId);
            formik.setFieldValue('cmsProductName', productDetails.cmsProductName);
            formik.setFieldValue('displayNameEnglish', productDetails.displayNameEnglish);
            formik.setFieldValue('displayNameUrdu', productDetails.displayNameUrdu);
            formik.setFieldValue('productId', productDetails?.productId);
            formik.setFieldValue('priority', productDetails?.priority);
            formik.setFieldValue('partialPaymentEn', productDetails.partialPaymentEn);
            formik.setFieldValue('isHide', productDetails?.isHide);
            formik.setFieldValue('cmsMainCategoryId', productDetails?.cmsMainCategoryId?.toString() || '');
            formik.setFieldValue('cmsSubCategoryId', productDetails?.cmsSubCategoryId?.toString() || '');
            formik.setFieldValue('cmsSecondSubCategoryId', productDetails?.cmsSecondSubCategoryId?.toString() || '');
            formik.setFieldValue('addCheck', productDetails?.cmsTagLabel || productDetails?.cmsTagTextColor || productDetails?.cmsTagTextBgColor ? true : false);
            formik.setFieldValue('cmsTagLabel', productDetails?.cmsTagLabel || '');
            formik.setFieldValue('cmsTagTextColor', productDetails?.cmsTagTextColor || '');
            formik.setFieldValue('cmsTagTextBgColor', productDetails?.cmsTagTextBgColor || '');
            const correctEndDate = (productDetails?.tagExpiryDate && formattedDate(productDetails?.tagExpiryDate)) || '';
            formik.setFieldValue('tagExpiryDate', correctEndDate);
            formik.setFieldValue('isActive', productDetails.isActive || '');
            formik.setFieldValue('cmsMediaElementId', productDetails?.tblCmsProductFields?.map((item) => item?.cmsMediaFieldTypeId)[0] !== null ? '1' : '2');

            const buttons = productDetails.tblCmsProductFields.map((item, index) => {
                return {
                    cmsProductFieldsId: item?.cmsProductFieldsId,
                    cmsMediaElementId: item?.cmsMediaElementId,
                    cmsMediaButtonTypeId: item?.cmsMediaButtonTypeId?.toString(),
                    cmsMediaButtonTypeText: item?.cmsMediaButtonTypeText,
                    cmsMediaFieldTypeId: item?.cmsMediaFieldTypeId?.toString(),
                    cmsMediaFieldTypeTitle: item?.cmsMediaFieldTypeTitle,
                    fieldCharacterLimit: item?.fieldCharacterLimit,
                    fieldPlaceholderValue: item?.fieldPlaceholderValue,
                    fieldMandatory: item?.fieldMandatory
                };
            });

            formik.setFieldValue('tblCmsProductFields', buttons);
            const pathParts = productDetails?.productIcon && productDetails?.productIcon?.split('/');
            const categoryIcon = pathParts && pathParts[pathParts?.length - 1];
            formik.setFieldValue('productIcon', categoryIcon);
            setProductIconFileName(categoryIcon);
        }
    };
    const getMcRequestDataById = async () => {
        setloading(true);
        const res = await handleGetRequest(`/cms/v1/cms/getmcrequestbyid/${mcRequestId}`);
        const unParsedkeyData = res?.payLoad?.updateJson;
        const productDetails = JSON.parse(unParsedkeyData);
        if (productDetails) {
            formik.setFieldValue('cmsProductId', productDetails.cmsProductId);
            formik.setFieldValue('cmsProductName', productDetails.cmsProductName);
            formik.setFieldValue('displayNameEnglish', productDetails.displayNameEnglish);
            formik.setFieldValue('displayNameUrdu', productDetails.displayNameUrdu);
            formik.setFieldValue('productId', productDetails.productId);
            formik.setFieldValue('priority', productDetails?.priority);
            formik.setFieldValue('partialPaymentEn', productDetails.partialPaymentEn);
            formik.setFieldValue('cmsMediaButtonTypeText', productDetails.cmsMediaButtonTypeText);
            formik.setFieldValue('statusId', productDetails.statusId);
            formik.setFieldValue('isHide', productDetails.isHide);
            formik.setFieldValue('productIcon', productDetails?.productIcon);
            formik.setFieldValue('cmsMainCategoryId', productDetails?.cmsMainCategoryId?.toString() || '');
            formik.setFieldValue('cmsSubCategoryId', productDetails?.cmsSubCategoryId?.toString() || '');
            formik.setFieldValue('cmsSecondSubCategoryId', productDetails?.cmsSecondSubCategoryId?.toString() || '');
            formik.setFieldValue('addCheck', productDetails?.cmsTagLabel || productDetails?.cmsTagTextColor || productDetails?.cmsTagTextBgColor ? true : false);
            formik.setFieldValue('cmsTagLabel', productDetails?.cmsTagLabel || '');
            formik.setFieldValue('cmsTagTextColor', productDetails?.cmsTagTextColor || '');
            formik.setFieldValue('cmsTagTextBgColor', productDetails?.cmsTagTextBgColor || '');
            const correctEndDate = (productDetails?.tagExpiryDate && formattedDate(productDetails?.tagExpiryDate)) || '';
            formik.setFieldValue('tagExpiryDate', correctEndDate);
            formik.setFieldValue('isActive', productDetails.isActive || '');
            formik.setFieldValue('cmsMediaElementId', productDetails?.tblCmsProductFields?.map((item) => item?.cmsMediaFieldTypeId)[0] !== null ? '1' : '2');

            const buttons = productDetails.tblCmsProductFields.map((item, index) => {
                return {
                    cmsProductFieldsId: item?.cmsProductFieldsId,
                    cmsMediaElementId: item?.cmsMediaElementId,
                    cmsMediaButtonTypeId: item?.cmsMediaButtonTypeId?.toString(),
                    cmsMediaButtonTypeText: item?.cmsMediaButtonTypeText,
                    cmsMediaFieldTypeId: item?.cmsMediaFieldTypeId?.toString(),
                    cmsMediaFieldTypeTitle: item?.cmsMediaFieldTypeTitle,
                    fieldCharacterLimit: item?.fieldCharacterLimit,
                    fieldPlaceholderValue: item?.fieldPlaceholderValue,
                    fieldMandatory: item?.fieldMandatory
                };
            });
            formik.setFieldValue('tblCmsProductFields', buttons);

            const pathParts = productDetails?.productIcon && productDetails?.productIcon?.split('/');
            const categoryIcon = pathParts && pathParts[pathParts?.length - 1];
            formik.setFieldValue('productIcon', categoryIcon);
            setProductIconFileName(categoryIcon);
        }

        setloading(false);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getProductDetailsById();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, [requestType]);

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    function formattedDate(originalDate) {
        const createDate = new Date(originalDate);

        const year = createDate.getFullYear();
        const month = (createDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const day = createDate.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }
    const getContrastColor = (hexColor) => {
        const r = parseInt(hexColor.substr(1, 2), 16);
        const g = parseInt(hexColor.substr(3, 2), 16);
        const b = parseInt(hexColor.substr(5, 2), 16);
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>View DETAILS</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productId" className={classNames({ 'p-error': isFormFieldValid('productId') }, 'Label__Text')}>
                                    Product ID <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="productId"
                                    placeholder="Enter product ID"
                                    disabled
                                    name="productId"
                                    value={formik?.values?.productId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productId') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('productId')}
                            </div>
                        </div>
                        <dvi className="p-field col-12 md:col-8 pt-5 pb-3">
                            <div className="Down__Btn justify-content-start mt-2">
                                <Button type="button" label="Get" className="Btn__Dark" disabled />
                            </div>
                        </dvi>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsProductName" className={classNames({ 'p-error': isFormFieldValid('cmsProductName') }, 'Label__Text')}>
                                    Product Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="cmsProductName"
                                    placeholder="Enter product name"
                                    name="cmsProductName"
                                    value={formik?.values?.cmsProductName || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsProductName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('cmsProductName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                <div className="p-field-checkbox flex justify-content-between">
                                   
                                   <label htmlFor="nameUrdu" className={classNames({ 'p-error': isFormFieldValid('nameUrdu') }, 'Label__Text')}>
                                   Display Name (Urdu)<span className="Label__Required">*</span>
                                     
                                   </label>
                                 <div className='flex align-items-center ml-auto'>
                                   <label htmlFor="nameUrdu" className={classNames({ 'p-error': isFormFieldValid('nameUrdu') }, 'Label__Text')}>
                                      Same AS (English)
                                     
                                   </label>
                                   </div>
                                   <Checkbox
                                       inputId="copyEngToUrdu"
                                       name="copyEngToUrdu"
                                       disabled
                                       checked={formik.values.copyEngToUrdu}
                                       onChange={(e) => {
                                           formik.setFieldValue('copyEngToUrdu', e.checked);
                                           if (e.checked) {
                                               formik.setFieldValue('displayNameUrdu', formik.values.cmsProductName);
                                           }
                                       }}
                                   />
                                 
                               </div>
                                   
                                    <InputText
                                        maxLength={100}
                                        id="displayNameUrdu"
                                        placeholder="Enter Display Name (Urdu) "
                                        name="displayNameUrdu"
                                        disabled
                                        value={formik?.values?.displayNameUrdu || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('displayNameUrdu') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('displayNameUrdu')}
                                </div>
                            </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="priority" className={classNames({ 'p-error': isFormFieldValid('priority') }, 'Label__Text')}>
                                    Product Priority<span className="Label__Required">*</span>
                                </label>

                                <InputText
                                    maxLength={100}
                                    id="priority"
                                    placeholder=""
                                    name="priority"
                                    keyfilter="int"
                                    disabled
                                    value={formik?.values?.priority}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('priority') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('priority')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <div className="mb-2">
                                    <label className="Label__Text">
                                        Product Icon<span className="Label__Required">*</span>
                                    </label>
                                    <div className="file-input-container w-100">
                                        <input type="file" style={{ display: 'none' }} accept="image/*" />
                                        <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={productIconFileName} disabled readOnly />
                                        <span className="file-upload-icon">
                                            <i className="pi pi-paperclip" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-1 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="addCheck" className={classNames({ 'p-error': isFormFieldValid('addCheck') }, 'Label__Text')}>
                                    Add Tag
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="addCheck" name="addCheck" checked={formik?.values?.addCheck} onChange={formik.handleChange} style={{ marginTop: '10px' }} disabled />
                                </div>
                                {getFormErrorMessage('addCheck')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsTagLabel" className={classNames({ 'p-error': isFormFieldValid('cmsTagLabel') }, 'Label__Text')}>
                                    Tag Label
                                </label>

                                <InputText
                                    maxLength={100}
                                    id="cmsTagLabel"
                                    placeholder="Enter Tag Label"
                                    name="cmsTagLabel"
                                    disabled
                                    value={formik?.values?.cmsTagLabel || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagLabel') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('cmsTagLabel')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field relative">
                                <label htmlFor="cmsTagTextColor" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextColor') }, 'Label__Text')}>
                                    Tag Text Color
                                </label>
                                <InputText
                                    value={formik?.values?.cmsTagTextColor || '#FFFFFF'}
                                    onChange={(e) => {
                                        formik.setFieldValue('cmsTagTextColor', e.target.value);
                                    }}
                                    onClick={() => setShowColorPicker({ textColor: true, bgColor: false })}
                                    disabled
                                    readOnly
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextColor') }, 'Input__Round')}
                                    style={{
                                        backgroundColor: formik?.values?.cmsTagTextColor || '#FFFFFF',
                                        color: getContrastColor(formik?.values?.cmsTagTextColor || '#FFFFFF')
                                    }}
                                />
                                {getFormErrorMessage('cmsTagTextColor')}
                                {showColorPicker?.textColor && (
                                    <div className="colorPickerForInput">
                                        <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                        <ColorPicker color={formik?.values?.cmsTagTextColor || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagTextColor', e?.hex)} />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                            <div className="p-field">
                                <label htmlFor="cmsTagTextBgColor" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextBgColor') }, 'Label__Text')}>
                                    Tag Background Color
                                </label>
                                <InputText
                                    value={formik?.values?.cmsTagTextBgColor || '#FFFFFF'}
                                    onChange={(e) => {
                                        formik.setFieldValue('cmsTagTextBgColor', e.target.value);
                                    }}
                                    onClick={() => setShowColorPicker({ textColor: false, bgColor: true })}
                                    disabled
                                    readOnly
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextBgColor') }, 'Input__Round')}
                                    style={{
                                        backgroundColor: formik?.values?.cmsTagTextBgColor || '#FFFFFF',
                                        color: getContrastColor(formik?.values?.cmsTagTextBgColor || '#FFFFFF')
                                    }}
                                />
                                {getFormErrorMessage('cmsTagTextBgColor')}
                                {showColorPicker?.bgColor && (
                                    <div className="colorPickerForInput">
                                        <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                        <ColorPicker color={formik?.values?.cmsTagTextBgColor || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagTextBgColor', e?.hex)} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="tagExpiryDate" className={classNames({ 'p-error': isFormFieldValid('tagExpiryDate') }, 'Label__Text')}>
                                    Expiry Date <span className="Label__Required">*</span>
                                </label>
                                <InputText type="date" id="tagExpiryDate" name="tagExpiryDate" value={formik?.values?.tagExpiryDate || ''} disabled className={classNames({ 'p-invalid': isFormFieldValid('tagExpiryDate') }, 'Input__Round')} />
                                {getFormErrorMessage('tagExpiryDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsMainCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsMainCategoryId') }, 'Label__Text')}>
                                    Category<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="cmsMainCategoryId"
                                    placeholder="Select Category"
                                    options={mainData}
                                    optionLabel="name"
                                    name="cmsMainCategoryId"
                                    optionValue="lovId"
                                    value={formik.values.cmsMainCategoryId || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsMainCategoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsMainCategoryId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsSubCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsSubCategoryId') }, 'Label__Text')}>
                                    Sub Category<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="cmsSubCategoryId"
                                    placeholder="Select Sub Category"
                                    options={subData}
                                    optionLabel="name"
                                    name="cmsSubCategoryId"
                                    optionValue="lovId"
                                    value={formik.values.cmsSubCategoryId || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsSubCategoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsSubCategoryId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="cmsSecondSubCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsSecondSubCategoryId') }, 'Label__Text')}>
                                    2nd Sub Category<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="cmsSecondSubCategoryId"
                                    placeholder="Select 2nd Sub Category"
                                    options={secSubData}
                                    optionLabel="name"
                                    name="cmsSecondSubCategoryId"
                                    optionValue="lovId"
                                    value={formik.values.cmsSecondSubCategoryId || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsSecondSubCategoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsSecondSubCategoryId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="partialPaymentEn" className={classNames({ 'p-error': isFormFieldValid('partialPaymentEn') }, 'Label__Text')}>
                                    Enable Partial Payment <span className="Label__Required">*</span>
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="partialPaymentEn" name="partialPaymentEn" value={formik?.values?.partialPaymentEn} checked={formik?.values?.partialPaymentEn === 'Y'} disabled style={{ marginTop: '10px' }} />

                                    <label htmlFor="partialPaymentEn" className={classNames({ 'p-error': isFormFieldValid('partialPaymentEn') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Active
                                    </label>
                                </div>
                                {getFormErrorMessage('partialPaymentEn')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isHide" className={classNames({ 'p-error': isFormFieldValid('isHide') }, 'Label__Text')}>
                                    Status
                                </label>
                                <div className="flex ml-2">
                                    <div className="mt-0 mr-5">
                                        <Checkbox inputId="show" name="isHide" value="Y" checked={formik?.values?.isHide === 'Y'} onChange={(e) => formik.setFieldValue('isHide', 'Y')} style={{ marginTop: '10px' }} disabled />
                                        <label htmlFor="show" className={classNames({ 'p-error': isFormFieldValid('show') }, 'Label__Text')}>
                                            Show
                                        </label>
                                    </div>
                                    <div className="mt-0">
                                        <Checkbox inputId="hide" name="isHide" value="N" checked={formik?.values?.isHide === 'N'} onChange={(e) => formik.setFieldValue('isHide', 'N')} style={{ marginTop: '10px' }} disabled />
                                        <label htmlFor="hide" className={classNames({ 'p-error': isFormFieldValid('hide') }, 'Label__Text')}>
                                            Hide
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsMediaElementId" className={classNames({ 'p-error': isFormFieldValid('cmsMediaElementId') }, 'Label__Text')}>
                                    Add Media Elements<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="cmsMediaElementId"
                                    placeholder="Select Media Elements"
                                    options={mediaElementType}
                                    optionLabel="name"
                                    name="cmsMediaElementId"
                                    optionValue="lovId"
                                    value={formik.values.cmsMediaElementId || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsMediaElementId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('cmsMediaElementId')}
                            </div>
                        </div>

                        {formik.values.cmsMediaElementId == '2' &&
                            formik?.values?.tblCmsProductFields?.map((_, index) => {
                                return (
                                    <div className="card Card__Bg__Change col-12 md:col-12 px-3" key={index}>
                                        <h6 className="secondary-color pt-1">
                                            <b>Button {index + 1}</b>
                                        </h6>
                                        <div className="flex">
                                            <div className="p-fluid p-formgrid grid mb-5 col-10 md:col-10">
                                                <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                                    <div className="p-field">
                                                        <label htmlFor={`tblCmsProductFields.${index}.cmsMediaButtonTypeId`} className={classNames({ 'p-error': isFormFieldValid(`tblCmsProductFields.${index}.cmsMediaButtonTypeId`) }, 'Label__Text')}>
                                                            Select Button <span className="Label__Required">*</span>
                                                        </label>
                                                        <Dropdown
                                                            id={`tblCmsProductFields${index}.cmsMediaButtonTypeId`}
                                                            placeholder="Select Type Of Button"
                                                            name={`tblCmsProductFields.${index}.cmsMediaButtonTypeId`}
                                                            optionLabel="name"
                                                            options={btnType}
                                                            disabled
                                                            optionValue="lovId"
                                                            value={formik.values.tblCmsProductFields[index]?.cmsMediaButtonTypeId || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames(
                                                                {
                                                                    'p-invalid': formik.touched.tblCmsProductFields?.[index]?.cmsMediaButtonTypeId && formik.errors.tblCmsProductFields?.[index]?.cmsMediaButtonTypeId
                                                                },
                                                                'Dropdown__Round'
                                                            )}
                                                        />
                                                        {formik.touched.tblCmsProductFields?.[index]?.cmsMediaButtonTypeId && formik.errors.tblCmsProductFields?.[index]?.cmsMediaButtonTypeId && (
                                                            <small className="p-error">{formik.errors.tblCmsProductFields?.[index]?.cmsMediaButtonTypeId}</small>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                                    <div className="p-field">
                                                        <label
                                                            htmlFor={`tblCmsProductFields.${index}.cmsMediaButtonTypeText`}
                                                            className={classNames({ 'p-error': isFormFieldValid(`tblCmsProductFields.${index}.cmsMediaButtonTypeText`) }, 'Label__Text')}
                                                        >
                                                            Text<span className="Label__Required">*</span>
                                                        </label>
                                                        <InputText
                                                            maxLength={100}
                                                            id={`tblCmsProductFields.${index}.cmsMediaButtonTypeText`}
                                                            placeholder="Enter Action Button Text"
                                                            name={`tblCmsProductFields.${index}.cmsMediaButtonTypeText`}
                                                            value={formik.values.tblCmsProductFields[index]?.cmsMediaButtonTypeText || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames(
                                                                {
                                                                    'p-invalid': formik.touched.tblCmsProductFields?.[index]?.cmsMediaButtonTypeText && formik.errors.tblCmsProductFields?.[index]?.cmsMediaButtonTypeText
                                                                },
                                                                'Input__Round'
                                                            )}
                                                            disabled
                                                        />
                                                        {formik.touched.tblCmsProductFields?.[index]?.cmsMediaButtonTypeText && formik.errors.tblCmsProductFields?.[index]?.cmsMediaButtonTypeText && (
                                                            <small className="p-error">{formik.errors.tblCmsProductFields?.[index]?.cmsMediaButtonTypeText}</small>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2 md:col-2 flex flex-column align-items-center justify-content-center mb-3">
                                                <button type="button" className="addition_Button " disabled>
                                                    +
                                                </button>
                                                <button type="button" className="reduction_Button" disabled>
                                                    -
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                        {formik.values.cmsMediaElementId == '1' &&
                            formik?.values?.tblCmsProductFields?.map((_, index) => {
                                return (
                                    <div className="card Card__Bg__Change col-12 md:col-12 px-3" key={index}>
                                        <h6 className="secondary-color pt-1">
                                            <b>Fields {index + 1}</b>
                                        </h6>
                                        <div className="flex">
                                            <div className="p-fluid p-formgrid grid mb-5 col-10 md:col-10">
                                                <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                                    <div className="p-field">
                                                        <label htmlFor={`tblCmsProductFields.${index}.cmsMediaFieldTypeId`} className={classNames({ 'p-error': isFormFieldValid(`tblCmsProductFields.${index}.cmsMediaFieldTypeId`) }, 'Label__Text')}>
                                                            Select Field
                                                        </label>
                                                        <Dropdown
                                                            id={`tblCmsProductFields${index}.cmsMediaFieldTypeId`}
                                                            placeholder="Select Type Of Field"
                                                            name={`tblCmsProductFields.${index}.cmsMediaFieldTypeId`}
                                                            optionLabel="name"
                                                            options={btnType}
                                                            disabled
                                                            optionValue="lovId"
                                                            value={formik.values.tblCmsProductFields[index]?.cmsMediaFieldTypeId || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames(
                                                                {
                                                                    'p-invalid': formik.touched.tblCmsProductFields?.[index]?.cmsMediaFieldTypeId && formik.errors.tblCmsProductFields?.[index]?.cmsMediaFieldTypeId
                                                                },
                                                                'Dropdown__Round'
                                                            )}
                                                        />
                                                        {formik.touched.tblCmsProductFields?.[index]?.cmsMediaFieldTypeId && formik.errors.tblCmsProductFields?.[index]?.cmsMediaFieldTypeId && (
                                                            <small className="p-error">{formik.errors.tblCmsProductFields?.[index]?.cmsMediaFieldTypeId}</small>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                                    <div className="p-field">
                                                        <label
                                                            htmlFor={`tblCmsProductFields.${index}.cmsMediaFieldTypeTitle`}
                                                            className={classNames({ 'p-error': isFormFieldValid(`tblCmsProductFields.${index}.cmsMediaFieldTypeTitle`) }, 'Label__Text')}
                                                        >
                                                            Title
                                                        </label>
                                                        <InputText
                                                            maxLength={100}
                                                            id={`tblCmsProductFields.${index}.cmsMediaFieldTypeTitle`}
                                                            placeholder="Enter Action Field Text"
                                                            name={`tblCmsProductFields.${index}.cmsMediaFieldTypeTitle`}
                                                            value={formik.values.tblCmsProductFields[index]?.cmsMediaFieldTypeTitle || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames(
                                                                {
                                                                    'p-invalid': formik.touched.tblCmsProductFields?.[index]?.cmsMediaFieldTypeTitle && formik.errors.tblCmsProductFields?.[index]?.cmsMediaFieldTypeTitle
                                                                },
                                                                'Input__Round'
                                                            )}
                                                            disabled
                                                        />
                                                        {formik.touched.tblCmsProductFields?.[index]?.cmsMediaFieldTypeTitle && formik.errors.tblCmsProductFields?.[index]?.cmsMediaFieldTypeTitle && (
                                                            <small className="p-error">{formik.errors.tblCmsProductFields?.[index]?.cmsMediaFieldTypeTitle}</small>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                                    <div className="p-field">
                                                        <label htmlFor={`tblCmsProductFields.${index}.fieldCharacterLimit`} className={classNames({ 'p-error': isFormFieldValid(`tblCmsProductFields.${index}.fieldCharacterLimit`) }, 'Label__Text')}>
                                                            Character Limit
                                                        </label>
                                                        <InputText
                                                            maxLength={100}
                                                            id={`tblCmsProductFields.${index}.fieldCharacterLimit`}
                                                            placeholder="Enter Action Field Text"
                                                            name={`tblCmsProductFields.${index}.fieldCharacterLimit`}
                                                            value={formik.values.tblCmsProductFields[index]?.fieldCharacterLimit || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames(
                                                                {
                                                                    'p-invalid': formik.touched.tblCmsProductFields?.[index]?.fieldCharacterLimit && formik.errors.tblCmsProductFields?.[index]?.fieldCharacterLimit
                                                                },
                                                                'Input__Round'
                                                            )}
                                                            disabled
                                                        />
                                                        {formik.touched.tblCmsProductFields?.[index]?.fieldCharacterLimit && formik.errors.tblCmsProductFields?.[index]?.fieldCharacterLimit && (
                                                            <small className="p-error">{formik.errors.tblCmsProductFields?.[index]?.fieldCharacterLimit}</small>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                                    <div className="p-field">
                                                        <label
                                                            htmlFor={`tblCmsProductFields.${index}.fieldPlaceholderValue`}
                                                            className={classNames({ 'p-error': isFormFieldValid(`tblCmsProductFields.${index}.fieldPlaceholderValue`) }, 'Label__Text')}
                                                        >
                                                            Placeholder Value
                                                        </label>
                                                        <InputText
                                                            maxLength={100}
                                                            id={`tblCmsProductFields.${index}.fieldPlaceholderValue`}
                                                            placeholder="Enter Action Field Text"
                                                            name={`tblCmsProductFields.${index}.fieldPlaceholderValue`}
                                                            value={formik.values.tblCmsProductFields[index]?.fieldPlaceholderValue || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames(
                                                                {
                                                                    'p-invalid': formik.touched.tblCmsProductFields?.[index]?.fieldPlaceholderValue && formik.errors.tblCmsProductFields?.[index]?.fieldPlaceholderValue
                                                                },
                                                                'Input__Round'
                                                            )}
                                                            disabled
                                                        />
                                                        {formik.touched.tblCmsProductFields?.[index]?.fieldPlaceholderValue && formik.errors.tblCmsProductFields?.[index]?.fieldPlaceholderValue && (
                                                            <small className="p-error">{formik.errors.tblCmsProductFields?.[index]?.fieldPlaceholderValue}</small>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label htmlFor="fieldMandatory" className={classNames({ 'p-error': isFormFieldValid('fieldMandatory') }, 'Label__Text')}></label>
                                                        <div className="field-checkbox">
                                                            <Checkbox
                                                                inputId="fieldMandatory"
                                                                name="fieldMandatory"
                                                                value={formik?.values?.fieldMandatory}
                                                                checked={formik?.values?.fieldMandatory === 'Y'}
                                                                onChange={(e) => formik.setFieldValue('fieldMandatory', e.target.checked ? 'Y' : 'N')}
                                                                style={{ marginTop: '10px' }}
                                                                disabled
                                                            />

                                                            <label htmlFor="fieldMandatory" className={classNames({ 'p-error': isFormFieldValid('fieldMandatory') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                                                Mandatory
                                                            </label>
                                                        </div>
                                                        {getFormErrorMessage('fieldMandatory')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2 md:col-2 flex flex-column align-items-center justify-content-center mb-3">
                                                <button type="button" className="addition_Button" disabled>
                                                    +
                                                </button>
                                                <button type="button" className="reduction_Button" disabled>
                                                    -
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                        {/* <div className="card Card__Bg__Change col-12 md:col-12 flex">
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                        Select Action <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="action"
                                        placeholder="Select Action"
                                        options={statusLovData}
                                        optionLabel="name"
                                        name="action"
                                        optionValue="lovId"
                                        value={formik?.values?.action || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('action')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="desc_Text_Area">
                                    <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                        Comments <span className="Label__Required">*</span>
                                    </label>
                                    <InputTextarea
                                        placeholder="-"
                                        inputid="checkerComments"
                                        name="checkerComments"
                                        rows={4}
                                        cols={30}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'Input__Round')}
                                        value={formik?.values?.checkerComments}
                                    />
                                    {getFormErrorMessage('checkerComments')}
                                </div>
                            </div>
                        </div> */}
                    </div>

                    <div className="Down__Btn">
                        <Button disabled={loading} type="button" onClick={() => navigate('/requestChecker')} label="Okay" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default ViewProductChecker;

import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';

const IbftInOut = ({ ibftInOutData }) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const bankArr =
            ibftInOutData &&
            ibftInOutData.map((ibft) => {
                return ibft?.parameterName;
            });
        const countArr =
            ibftInOutData &&
            ibftInOutData.map((ibft) => {
                return ibft?.parameterValueTwo;
            });

        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: bankArr,
            datasets: [
                {
                    label: 'Value',
                    backgroundColor: 'rgb(212,230,243)',
                    hoverBackgroundColor: 'rgb(212,230,243)',
                    data: countArr
                }
            ]
        };
        const options = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    display: false
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                        font: {
                            weight: 500
                        }
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [ibftInOutData]);

    return (
        <div className="col-12 md:col-6">
            <div className="card Card__Round">
                <div className="cardBarCharts">
                    <h5>IBFT In / Out</h5>
                    <div className="pt-3">
                        <Chart type="bar" data={chartData} options={chartOptions} className="w-full" height="35rem" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IbftInOut;

import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import { FilterMatchMode } from 'primereact/api';
import ActiveInActiveDialog from '../../../components/ActiveInActiveDialog/ActiveInActiveDialog';

function CreateSalesRoles() {
    const [showModal, setShowModal] = useState(false);
    const [switchData, setSwitchData] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [regionData, setRegionData] = useState([]);
    // const [newPayLoad, setNewPayLoad] = useState([]);
    const [saleRoleData, setSaleRoleData] = useState([]);
    const [saleStructureData, setSaleStructureData] = useState([]);
    const [parentArray, setParentArray] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        roleArrayData: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        salesStructureId: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        regionId: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        statusId: { value: '', matchMode: FilterMatchMode.EQUALS },
        dateFrom: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        dateTo: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        createdBy: { value: '', matchMode: FilterMatchMode.STARTS_WITH },
        updatedBy: { value: '', matchMode: FilterMatchMode.STARTS_WITH }
    });

    const dataTableRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            businessTypeId: '',
            salesStructureId: '',
            regionId: '',
            createdBy: '',
            updatedBy: '',
            dateFrom: '',
            dateTo: '',
            statusId: '2'
        },

        validate: (data) => {
            let errors = {};
            if (data.dateTo && !data.dateFrom) {
                errors.dateFrom = 'Date From is required when Date To is provided';
            }

            if (data.dateFrom && data.dateTo && data.dateFrom > data.dateTo) {
                errors.dateTo = 'Date To must be greater than or equal to Date From';
            }
            return errors;
        },

        onSubmit: async (data) => {
            setloadingIcon('pi pi-spin pi-spinner');
            setParentArray([]);
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            dataTableRef.current.reset();
            const res = await dispatch(handlePostRequest(newData, '/account/v1/sales/getallsaleroles', true, true));
            if (res?.responseCode === '010000') {
                setRegionData(res?.payLoad);
                setloadingIcon('pi pi-search');
            }
        }
    });

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(res?.payLoad);
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };

    const getSaleRoleData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getsalerolesdata');
        // if (resp?.responseCode === '010000') {
        setSaleRoleData(resp?.payLoad);
        // }
    };

    const getSaleStructureData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getSaleStructureList/LKP_SALES_STRUCTURE:*');
        // if (resp?.responseCode === '010000') {
        setSaleStructureData(resp?.payLoad);
        // }
    };

    useEffect(() => {
        getStatusListData();
        getUserLov();
        getSaleRoleData();
        getSaleStructureData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    // useEffect(() => {
    //     if (regionData.length > 0) {
    //         let currentId = null;
    //         let key = 0;

    //         const groupedParentArray = regionData.map((parentItem) => {
    //             const businessList = saleRoleData?.bussinessList.find((item) => item.lovId === parentItem?.businessTypeId?.toString());
    //             const regionList = saleRoleData?.regionList.find((item) => item.lovId === parentItem?.regionId?.toString());
    //             const saleStructureList = saleStructureData?.find((item) => item.lovId === parentItem?.salesStructureId?.toString());
    //             if (parentItem.salesRoleId !== currentId) {
    //                 key = 0;
    //                 currentId = parentItem.salesRoleId;
    //             }
    //             const childItem = parentItem.tblSalesRoleDetails[key];
    //             key++;
    //             return { ...parentItem, roleArrayData: childItem, businessList: businessList, regionList: regionList, saleStructureList: saleStructureList };
    //         });
    //         setParentArray(groupedParentArray);
    //     }
    // }, [regionData]);

    useEffect(() => {
        if (regionData.length > 0) {
            const parentArray = regionData.map((parentItem) => {
                const businessList = saleRoleData?.bussinessList.find((item) => item.lovId === parentItem?.businessTypeId?.toString());
                const regionList = saleRoleData?.regionList.find((item) => item.lovId === parentItem?.regionId?.toString())?.name ?? 'N/A';
                const saleStructureList = saleStructureData?.find((item) => item.lovId === parentItem?.salesStructureId?.toString())?.name ?? 'N/A';
                const roleNamesArray = parentItem.tblSalesRoleDetails.filter((childItem) => childItem.isActive === 'Y').map((activeChildItem) => activeChildItem.roleName);
                return {
                    ...parentItem,
                    roleArrayData: (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: roleNamesArray.join('<br/>')
                            }}
                        />
                    ),
                    businessList: businessList,
                    regionList: regionList,
                    saleStructureList: saleStructureList
                };
            });
            setParentArray(parentArray);
        }
    }, [regionData]); // eslint-disable-line react-hooks/exhaustive-deps

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.lkpStatus?.statusDescr === 'Approved' ? '#14A38B' : rowData?.lkpStatus?.statusDescr === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.lkpStatus?.statusDescr}</b>
            </p>
        );
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">Create/Maintain Sales Role</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const header = () => renderHeader();

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const handleSwitchChange = async () => {
        const updatedData = regionData?.map((item) => {
            if (item?.salesRoleId === switchData?.salesRoleId) {
                const isActive = item?.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setRegionData(updatedData);
        let isactive = '';
        if (switchData?.isActive === 'Y') {
            isactive = 'N';
        } else if (switchData?.isActive === 'N') {
            isactive = 'Y';
        }
        const previousValue = switchData?.isActive;

        const data = {
            isActive: isactive,
            salesRoleId: switchData?.salesRoleId,
            businessTypeId: switchData?.businessTypeId,
            regionId: switchData?.regionId,
            salesStructureId: switchData?.salesStructureId,
            tblSalesRoleDetails: switchData?.tblSalesRoleDetails
        };

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newData, '/account/v1/sales/inactivesaleroles', true, false));
        if (res?.responseCode == '010000') {
            const revertedData = regionData?.map((item) => {
                if (item.salesRoleId === switchData?.salesRoleId) {
                    const isActive = isactive;
                    return { ...item, isActive };
                }
                return item;
            });
            setRegionData(revertedData);
        } else {
            const revertedData = regionData?.map((item) => {
                if (item.salesRoleId === switchData?.salesRoleId) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setRegionData(revertedData);
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <InputSwitch
                        checked={rowData.isActive === 'Y'}
                        disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                        onChange={() => {
                            setSwitchData(rowData);
                            setShowModal(true);
                            // handleSwitchChange(rowData?.salesRoleId, rowData?.isActive, rowData);
                        }}
                    />
                    <Button
                        tooltip="Edit Detail"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: 'top' }}
                        disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) || (rowData?.lkpStatus?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                        //onClick={() => EditTypeOfAccountHandled(rowData)}
                        onClick={() => navigate(`/editsalesroles?id=${rowData?.salesRoleId}`)}
                        className="p-button-rounded p-button-primary"
                    />
                    <Button
                        tooltip="View Detail"
                        tooltipOptions={{ position: 'top' }}
                        // disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                        icon="pi pi-eye"
                        onClick={() => navigate(`/viewsalesroles?id=${rowData?.salesRoleId}`)}
                        className="p-button-rounded"
                        style={{ backgroundColor: '#F2AC57', border: 'none' }}
                    />
                </div>
            </>
        );
    };

    const AddCreateSalesRoles = () => {
        navigate('/addsalesroles');
    };
    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const handleShowModal = () => {
        setShowModal(false);
    };

    const props = {
        showModal,
        handleShowModal,
        handleSwitchChange
    };

    return (
        <>
            <ActiveInActiveDialog {...props} />
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={() => AddCreateSalesRoles()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="businessTypeId" className={classNames({ 'p-error': isFormFieldValid('businessTypeId') }, 'Label__Text')}>
                                    Business Type
                                </label>
                                <Dropdown
                                    id="businessTypeId"
                                    placeholder="Select Business Type"
                                    options={saleRoleData?.bussinessList}
                                    optionLabel="name"
                                    name="businessTypeId"
                                    optionValue="lovId"
                                    value={formik.values.businessTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('businessTypeId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('businessTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="regionId" className={classNames({ 'p-error': isFormFieldValid('regionId') }, 'Label__Text')}>
                                    Region
                                </label>
                                <Dropdown
                                    id="regionId"
                                    placeholder="Select Region"
                                    options={saleRoleData?.regionList}
                                    optionLabel="name"
                                    name="regionId"
                                    optionValue="lovId"
                                    value={formik.values.regionId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regionId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('regionId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="salesStructureId" className={classNames({ 'p-error': isFormFieldValid('salesStructureId') }, 'Label__Text')}>
                                    Sales Structure
                                </label>
                                <Dropdown
                                    id="salesStructureId"
                                    placeholder="Select Sales Structure"
                                    options={saleStructureData}
                                    optionLabel="name"
                                    name="salesStructureId"
                                    optionValue="lovId"
                                    value={formik.values.salesStructureId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('salesStructureId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('salesStructureId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText
                                    max={previousDate}
                                    id="dateFrom"
                                    type="date"
                                    name="dateFrom"
                                    value={formik?.values?.dateFrom?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText
                                    max={previousDate}
                                    id="dateTo"
                                    type="date"
                                    name="dateTo"
                                    value={formik?.values?.dateTo?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Updated By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="statusId" className={classNames({ 'p-error': isFormFieldValid('statusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select Status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label="Search" icon={loadingIcon || ''} iconPos="left" className="Btn__Dark" />
                        <Button type="button" label="Reset" iconPos="right" className="Btn__Transparent" onClick={(e) => handleReset(e)} />
                    </div>
                </form>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            filters={filters}
                            globalFilterFields={['saleStructureList', 'regionList', 'region', 'createdBy', 'createdate', 'lkpStatus.statusName']}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={parentArray}
                        >
                            {/* <Column field="roleArrayData.roleName" header="Role Name" /> */}
                            {/* <Column field="roleArrayData" header="Role Name" /> */}
                            <Column field="saleStructureList" header="Sale Structure" />
                            <Column field="regionList" header="Region" />
                            <Column field="createdBy" header="Created By" />
                            <Column header="Created Date & Time" body={createdDateAndTimeBodyTemplate} />
                            <Column field="statusName" header="Status" body={statusBodyTemplate} />
                            <Column header="Action" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
export default CreateSalesRoles;

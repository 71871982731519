import React, { useEffect, useState } from 'react';
import VirtualCardEditView from '../Maker/VirtualCardEditDetails';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { useLocation } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';

const CheckerVirtualCardView = () => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    // const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');

    const [virtualCardData, setVirtualCardData] = useState([]);
    // const [rowDataFromMaker, setRowDataFromMaker] = useState([]);
    const [mcReqData, setMcReqData] = useState([]);
    const [isLoading, setLoading] = useState([]);

    const [statusLovData, setStatusLovData] = useState([]);

    const getCustomerAndMcRequest = async () => {
        const resp = await handleGetRequest(`/debitcardmanagement/v1/vcards/getmcrequestbyid/${mcRequestId}`);
        setMcReqData(resp?.payLoad);
        const accountId = resp?.payLoad?.additionalParams?.acccountId;
        if (accountId) {
            setLoading(true);
            const resp = await handleGetRequest(`/debitcardmanagement/v1/vcards/getcustomervirtualcarddetails/${accountId}`);
            if (resp?.responseCode === '220000') {
                setVirtualCardData(resp?.payLoad);
            }
        }
        setLoading(false);
    };

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    // const getMcReqById = async () => {
    //     const resp = await handleGetRequest(`/debitcardmanagement/v1/vcards/getmcrequestbyid/${mcRequestId}`);
    //     setMcReqData(resp?.payLoad);
    // };

    useEffect(() => {
        getCustomerAndMcRequest();
        getStatusLov();
        // getMcReqById();
    }, []);

    return (
        <>
            <LoadingOverlay active={isLoading} spinner text="Please Wait ...">
                <VirtualCardEditView virtualCardData={virtualCardData} statusLovData={statusLovData} mcReqData={mcReqData} mcRequestId={mcRequestId} mcPeindingRequestId={mcPeindingRequestId} type="checkerView" />
            </LoadingOverlay>
        </>
    );
};

export default CheckerVirtualCardView;

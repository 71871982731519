import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DefaultComponent from './DefaultComponent';
import { useDispatch } from 'react-redux';
import { handleGetRequest } from '../../../../../services/GetTemplate';

function MakerApis({ type }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const [cmsQuestionConfigId, setCmsQuestionConfigId] = useState(location?.state?.rowData?.cmsQuestionConfigId || '');
    const [getByIdData, setGetByIdData] = useState([]);

    const funcGetByIdData = async () => {
        const resp = await handleGetRequest(`/cms/v1/cms/getquestionconfigbyid/${cmsQuestionConfigId}`);
        setGetByIdData(resp?.payLoad);
    };

    useEffect(() => {
        if (location?.state?.rowData) {
            funcGetByIdData();
        }
    }, [location?.state?.rowData]);

    return (
        <React.Fragment>
            <DefaultComponent type={type} getByIdData={getByIdData} />
        </React.Fragment>
    );
}
export default MakerApis;

const ScrollMover = () => {
    setTimeout(() => {
        const errorElement = document.querySelector('.p-error');
        if (errorElement) {
            const parentElement = errorElement.closest('.p-field');
            if (parentElement) {
                return parentElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            }
        }
    }, 500);
};

export default ScrollMover;

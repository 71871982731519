import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

const EditCheckerCurrency = () => {

    const [statusLovData, setStatusLovData] = useState([]);
    const [mcReqData, setMcReqData] = useState([]);

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const tableName = query.get('tableName');
    const requestTypeDescr = query.get('requestTypeDescr');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('Action should not be empty'),
        checkerComments: Yup.string().required('This Field is required').notOneOf([''], 'This Field should not be empty').max(500, 'Maximum 500 characters needed')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            lkpCurrencyName: '',
            lkpCurrencyCode: '',
            lkpCurrencyDescription: '',
            coaCode: '',
            action: '',
            checkerComments: ''
        },

        onSubmit: async (data) => {

            const coaPayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        lkpCurrencyDTO: (requestType === "U" && requestTypeDescr !== "Enable/Disable Request")
                            ? mcReqData
                            : (requestType === 'U' && requestTypeDescr === "Enable/Disable Request")
                                ? { isActive: mcReqData?.isActive, lkpCurrencyId: refTableId }
                                : { lkpCurrencyId: refTableId, isActive: "Y" },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: 1
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(coaPayload, '/account/v1/chartofaccount/lkpcurrencycheckeraction', true, false));
            if (res?.responseCode === '010000') {
                navigate(-1);
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getCostCenterById = async () => {
        const resp = await handleGetRequest(`/account/v1/chartofaccount/getlkpcurrencybyid/${refTableId}`);
        const keyData = resp?.payLoad;
        if (keyData !== null && typeof keyData !== 'undefined') {
            formik.setFieldValue('lkpCurrencyName', keyData?.currencyName);
            formik.setFieldValue('lkpCurrencyCode', keyData?.currencyCode);
            formik.setFieldValue('lkpCurrencyDescription', keyData?.currencyDescr);
            formik.setFieldValue('coaCode', keyData?.coaCode);
        }
    };

    const getCostCenterMcRequestDataById = async () => {
        const resp = await handleGetRequest(`/account/v1/chartofaccount/getlkpcurrencybymcrequestid/${mcRequestId}`);
        const keyData = resp?.payLoad;
        if (keyData !== null && typeof keyData !== 'undefined') {
            setMcReqData(keyData)
            if (requestType === 'U' && requestTypeDescr !== "Enable/Disable Request") {
                formik.setFieldValue('lkpCurrencyName', keyData?.lkpCurrencyName);
                formik.setFieldValue('lkpCurrencyCode', keyData?.lkpCurrencyCode);
                formik.setFieldValue('lkpCurrencyDescription', keyData?.lkpCurrencyDescription);
                formik.setFieldValue('coaCode', keyData?.coaCode);
            }
        }
    };

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = res?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getCostCenterById();
        } else if (requestType === 'U' && requestTypeDescr !== "Enable/Disable Request") {
            getCostCenterMcRequestDataById();
        } else if (requestType === 'U' && requestTypeDescr === "Enable/Disable Request") {
            getCostCenterById();
            getCostCenterMcRequestDataById()
        }
        getStatusListData();
    }, []);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>Edit Details</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Currency Name</label>
                                <InputText id="lkpCurrencyName" value={formik.values.lkpCurrencyName || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('lkpCurrencyName') }, 'Input__Round')} maxLength={30} disabled={true} />
                                {getFormErrorMessage('lkpCurrencyName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Currency Code</label>
                                <InputText id="lkpCurrencyCode" value={formik.values.lkpCurrencyCode || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('lkpCurrencyCode') }, 'Input__Round')} maxLength={30} disabled={true} />
                                {getFormErrorMessage('lkpCurrencyCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Currency Description</label>
                                <InputText
                                    id="lkpCurrencyDescription"
                                    value={formik.values.lkpCurrencyDescription || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpCurrencyDescription') }, 'Input__Round')}
                                    maxLength={199}
                                    disabled={true}
                                />
                                {getFormErrorMessage('lkpCurrencyDescription')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label className="Label__Text">Coa Code</label>
                                <InputText id="coaCode" value={formik.values.coaCode || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('coaCode') }, 'Input__Round')} maxLength={30} disabled={true} />
                                {getFormErrorMessage('coaCode')}
                            </div>
                        </div>

                        {
                            (requestType === 'U' && requestTypeDescr === "Enable/Disable Request") &&
                            (
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label className="Label__Text">Is Active</label>
                                        <Checkbox checked={mcReqData?.isActive === "Y"} disabled className='w-full' />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className='custom-card'>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>

                            <div className="p-field col-12 md:col-8">
                                <div className="p-field">
                                    <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                        Comments
                                        <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        rows={5}
                                        cols={30}
                                        id="checkerComments"
                                        name="checkerComments"
                                        value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'Input__Round')}
                                        maxLength={500}
                                    />

                                    {getFormErrorMessage('checkerComments')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Update" className="Btn__Dark" />
                        <Button onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" type="button" />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />

        </>
    );
};

export default EditCheckerCurrency;

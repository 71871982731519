import React from 'react';

// import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import ViewBulletLoan from './ViewBulletLoan';
import ViewTermLoan from './ViewTermLoan';
import ViewRunningFinance from './ViewRunningFinance';

function ViewRepayment({ activeIndex, handleStepClick, lendingData, lendingIdData, setStepData3 }) {
    //const [loading, setloading] = useState(false);
    //const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    //const [frequencyPeriodLov, setFrequencyPeriodLov] = useState([]);
    // const [fieldTableData, setFieldTableData] = useState([]);

    // const dispatch = useDispatch();
    // const navigate = useNavigate();

    // const handledDeleteFieldsData = (e, rowData) => {
    //     e.preventDefault();
    //     const newFilterData = fieldTableData.filter((value) => value.fieldName !== rowData.fieldName);
    //     setFieldTableData(newFilterData);
    // };
    // const actionFieldsBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <div className="actions">
    //                 <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteFieldsData(e, rowData)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
    //             </div>
    //         </>
    //     );
    // };

    // const formatDate = (inputDate) => {
    //     const date = new Date(inputDate);

    //     const day = date.getDate().toString().padStart(2, '0');
    //     const month = (date.getMonth() + 1).toString().padStart(2, '0');
    //     const year = date.getFullYear();
    //     const hours = date.getHours().toString().padStart(2, '0');
    //     const minutes = date.getMinutes().toString().padStart(2, '0');
    //     const ampm = hours >= 12 ? 'PM' : 'AM';

    //     // Convert hours from 24-hour to 12-hour format
    //     const formattedHours = hours % 12 || 12;

    //     const formattedDate = `${day}-${month}-${year}, ${formattedHours}:${minutes} ${ampm}`;
    //     return formattedDate;
    // };

    return (
        <>
            {lendingData?.lkpLmsFacilityType?.lmsFacilityTypeCode === 'BL' ? (
                <ViewBulletLoan handleStepClick={handleStepClick} activeIndex={activeIndex} lendingIdData={lendingIdData} lendingData={lendingData} setStepData3={setStepData3} />
            ) : lendingData?.lkpLmsFacilityType?.lmsFacilityTypeCode === 'TL' ? (
                <ViewTermLoan handleStepClick={handleStepClick} activeIndex={activeIndex} lendingIdData={lendingIdData} lendingData={lendingData} setStepData3={setStepData3} />
            ) : lendingData?.lkpLmsFacilityType?.lmsFacilityTypeCode === 'RF' ? (
                <ViewRunningFinance handleStepClick={handleStepClick} activeIndex={activeIndex} lendingIdData={lendingIdData} lendingData={lendingData} setStepData3={setStepData3} />
            ) : null}
        </>
    );
}
export default ViewRepayment;

import React from 'react';
import cmsMobileCategory from '../../../../assets/cms/cmsMobileCategory.png';

function MobileView({ title }) {
    return (
        <div className="cmsMobileView__main relative">
            <div className="cmsMobileView__category">{title && <span className="cmsMobileView__title">{title}</span>}</div>
            <img src={cmsMobileCategory} className="blurMobile w-100 h-100" alt="cmsMobileCategory" />
        </div>
    );
}

export default MobileView;

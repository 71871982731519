import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewLoginSetChecker() {
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const tableName = query.get('tableName');

    useEffect(() => {
        if (requestType === 'I') {
            getMainCategoryData();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, [requestType]); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            cmsLoginLimitId: '',
            noOfAttempts: '',
            time: '',
            isTemporaryBlock: false,
            isPermanentBlock: false,
            blockTime: '',
            action: '',
            checkerComments: ''
        }
    });

    const getMainCategoryData = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/getloginlimitsbyid/${refTableId}`);
        const keyData = res?.payLoad;
        formik.setFieldValue('noOfAttempts', keyData?.noOfAttempts || '');
        formik.setFieldValue('cmsLoginLimitId', keyData?.cmsLoginLimitId || '');
        formik.setFieldValue('time', keyData?.time || '');

        formik.setFieldValue('isTemporaryBlock', keyData?.isTemporaryBlock === 'Y' ? true : false);
        formik.setFieldValue('isPermanentBlock', keyData?.isPermanentBlock === 'Y' ? true : false);
        formik.setFieldValue('blockTime', keyData?.blockTime || '');
    };

    const getMcRequestDataById = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/getmcrequestbyid/${mcRequestId}`);
        const unParsedkeyData = res?.payLoad?.updateJson;
        const keyData = JSON.parse(unParsedkeyData);
        formik.setFieldValue('cmsLoginLimitId', keyData?.cmsLoginLimitId || '');
        formik.setFieldValue('noOfAttempts', keyData?.noOfAttempts || '');
        formik.setFieldValue('time', keyData?.time || '');

        formik.setFieldValue('isTemporaryBlock', keyData?.isTemporaryBlock === 'Y' ? true : false);
        formik.setFieldValue('isPermanentBlock', keyData?.isPermanentBlock === 'Y' ? true : false);
        formik.setFieldValue('blockTime', keyData?.blockTime || '');
        formik.setFieldValue('isActive', keyData?.isActive || '');
    };

  
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>VIEW DETAIL</h2>
                        </div>
                        <div className="p-field col-12 md:col-6 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="noOfAttempts" className="Label__Text">
                                    Attempts<span className="Label__Required">*</span>
                                </label>
                                <InputText maxLength={100} id="noOfAttempts" placeholder="Enter Attempts" name="noOfAttempts" value={formik?.values?.noOfAttempts || ''} disabled className="Input__Round" />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-6  pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="time" className="Label__Text">
                                    Enter Time<span className="Label__Required">*</span>
                                </label>
                                <InputText id="time" name="time" value={formik?.values?.time} placeholder="Enter Time In Minutes" disabled className="Input__Round" />
                            </div>
                        </div>

                        <div className="Form__Header Full__Width">
                            <h2>BREACH LIMITS</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="isTemporaryBlock" name="isTemporaryBlock" checked={formik?.values?.isTemporaryBlock} onChange={formik.handleChange} disabled style={{ marginTop: '10px' }} />{' '}
                                    <label htmlFor="isTemporaryBlock" className="Label__Text" style={{ marginTop: '10px' }}>
                                        Temporary Block
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="blockTime" className="Label__Text">
                                Enter  Time 

                                </label>
                                <InputText maxLength={100} id="blockTime" placeholder="Enter Time In Minutes " name="blockTime" value={formik?.values?.blockTime || ''} disabled className="Input__Round" />
                            </div>
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>

                    <div className="p-field col-12 md:col-2 pt-3 pb-3">
                        <div className="p-field">
                            <div className="field-checkbox">
                                <Checkbox inputId="isPermanentBlock" name="isPermanentBlock" checked={formik?.values?.isPermanentBlock} onChange={formik.handleChange} disabled style={{ marginTop: '10px' }} />{' '}
                                <label htmlFor="isPermanentBlock" className="Label__Text" style={{ marginTop: '10px' }}>
                                    Permanent Block
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="button" label="Okay" onClick={() => navigate('/requestchecker')} className="Btn__Dark" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default ViewLoginSetChecker;

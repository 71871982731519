import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { MultiSelect } from 'primereact/multiselect';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import { baseURL } from '../../../../Config';
import MobileView from '../mobileView';

function ViewNotificationsChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [notificationIconFileName, setNotificationIconFileName] = useState();
    const [useCaseData, setUseCaseData] = useState([]);
    const [segmentsData, setSegmentsData] = useState([]);
    const [timePeriodData, setTimePeriodData] = useState([]);
    const [targAud, setTargAud] = useState([]);
    const [iconLink, setIconLink] = useState('');

    const navigate = useNavigate();
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const tableName = query.get('tableName');

    const getUseCase = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_USECASE');
        setUseCaseData(resp?.payLoad);
    };

    const getSegmentsData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getsegments/LKP_SEGMENT:*');
        setSegmentsData(res?.payLoad);
    };

    const getTimePeriodData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getdatalov/LKP_FREQUENCY_PERIOD');
        setTimePeriodData(res?.payLoad);
    };

    const getTargetAud = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getaccounttypes/LKP_ACCOUNT_LEVEL:*');
        setTargAud(res?.payLoad);
    };

    const formik = useFormik({
        initialValues: {
            cmsNotificationId: '',
            notificationName: '',
            notificationTitle: '',
            notificationText: '',
            image: '',
            notificationTime: '',
            redirection: '',
            cmsUsecaseId: '',
            repitition: '',
            timePeriod: '',
            frequency: '',
            startDate: '',
            endDate: '',
            tblCmsNotificationAccountLevels: [],

            isHide: '',
            isActive: '',
            tblCmsNotificationSegments: []
        }
    });

    const getMainCategoryData = async () => {
        setloading(true);
        const res = await handleGetRequest(`/cms/v1/cms/getnotificationbyid/${refTableId}`);
        const keyData = res?.payLoad;
        formik.setFieldValue('notificationName', keyData?.notificationName || '');
        formik.setFieldValue('cmsNotificationId', keyData?.cmsNotificationId || '');
        formik.setFieldValue('notificationTitle', keyData?.notificationTitle || '');
        formik.setFieldValue('notificationText', keyData?.notificationText);
        formik.setFieldValue('notificationTime', keyData?.notificationTime || '');
        formik.setFieldValue('redirection', keyData?.redirection || '');
        formik.setFieldValue('cmsUsecaseId', keyData?.cmsUsecaseId?.toString());
        formik.setFieldValue('repitition', keyData?.repitition || '');
        formik.setFieldValue('timePeriod', keyData?.timePeriod?.toString());
        formik.setFieldValue('frequency', keyData?.frequency || '');
        const correctStartDate = (keyData?.startDate && formattedDate(keyData?.startDate)) || '';
        formik.setFieldValue('startDate', correctStartDate || '');
        const correct = (keyData?.endDate && formattedDate(keyData?.endDate)) || '';
        formik.setFieldValue('endDate', correct || '');
        // formik.setFieldValue('cmsTargetAudienceId', keyData?.cmsTargetAudienceId || '');
        formik.setFieldValue('isHide', keyData?.isHide);
        formik.setFieldValue('isActive', keyData?.isActive);
        // formik.setFieldValue('cmsTargetAudienceId', keyData?.cmsTargetAudienceId?.toString());
        const segmentId = keyData?.tblCmsNotificationSegments?.map((entry) => ({
            lovId: entry?.segmentId.toString()
        }));

        formik.setFieldValue('tblCmsNotificationSegments', segmentId);
        const targetUser = keyData?.tblCmsNotificationAccountLevels?.map((entry) => ({
            lovId: entry?.accountLevelId.toString(),
            
        }));
        formik.setFieldValue('tblCmsNotificationAccountLevels', targetUser || '');
        // setIconLink(`${baseURL}/document/${keyData?.image?.substring(keyData?.image?.indexOf('/') + 1)}`);
        setIconLink(`${baseURL}/document/${keyData?.image?.split('/documents/')[1]}`);
        const pathParts = keyData?.image.split('/');
        const categoryIcon = pathParts[pathParts.length - 1];
        setNotificationIconFileName(categoryIcon);
        setloading(false);
    };

    const getMcRequestDataById = async () => {
        setloading(true);
        const res = await handleGetRequest(`/cms/v1/cms/getmcrequestbyid/${mcRequestId}`);
        const unParsedkeyData = res?.payLoad?.updateJson;
        const keyData = JSON.parse(unParsedkeyData);
        if (keyData) {
            formik.setFieldValue('cmsNotificationId', keyData?.cmsNotificationId || '');
            formik.setFieldValue('notificationName', keyData?.notificationName || '');
            formik.setFieldValue('notificationTitle', keyData?.notificationTitle || '');
            formik.setFieldValue('notificationText', keyData?.notificationText);
            formik.setFieldValue('notificationTime', keyData?.notificationTime || '');
            formik.setFieldValue('redirection', keyData?.redirection || '');
            formik.setFieldValue('cmsUsecaseId', keyData?.cmsUsecaseId?.toString());
            formik.setFieldValue('repitition', keyData?.repitition || '');
            formik.setFieldValue('timePeriod', keyData?.timePeriod.toString());
            formik.setFieldValue('frequency', keyData?.frequency || '');
            const correctStartDate = (keyData?.startDate && formattedDate(keyData?.startDate)) || '';
            formik.setFieldValue('startDate', correctStartDate || '');
            const correct = (keyData?.endDate && formattedDate(keyData?.endDate)) || '';
            formik.setFieldValue('endDate', correct || '');
            // formik.setFieldValue('cmsTargetAudienceId', keyData?.cmsTargetAudienceId || '');
            formik.setFieldValue('isHide', keyData?.isHide);
            formik.setFieldValue('isActive', keyData?.isActive);
            // formik.setFieldValue('cmsTargetAudienceId', keyData?.cmsTargetAudienceId?.toString());
            const segmentId = keyData?.tblCmsNotificationSegments?.map((entry) => ({
                lovId: entry?.segmentId.toString()
            }));
            formik.setFieldValue('tblCmsNotificationSegments', segmentId);
            const targetUser = keyData?.tblCmsNotificationAccountLevels?.map((entry) => ({
                lovId: entry?.accountLevelId.toString(),
                
            }));
            formik.setFieldValue('tblCmsNotificationAccountLevels', targetUser || '');
            // setIconLink(`${baseURL}/document/${keyData?.image?.substring(keyData?.image?.indexOf('/') + 1)}`);
            setIconLink(`${baseURL}/document/${keyData?.image?.split('/documents/')[1]}`);
            const pathParts = keyData?.image.split('/');
            const categoryIcon = pathParts[pathParts.length - 1];
            setNotificationIconFileName(categoryIcon);
        }
        setloading(false);
    };

    useEffect(() => {
        getTargetAud();
        getTimePeriodData();
        getSegmentsData();
        getUseCase();
        if (requestType === 'I') {
            getMainCategoryData();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, []); // eslint-disable-line

    function formattedDate(originalDate) {
        const createDate = new Date(originalDate);

        const year = createDate.getFullYear();
        const month = (createDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const day = createDate.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="Form__Header Full__Width">
                        <h2>VIEW DETAILS</h2>
                    </div>
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-fluid p-formgrid grid col-8">
                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="notificationName" className={classNames({ 'p-error': isFormFieldValid('notificationName') }, 'Label__Text')}>
                                        Name<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="notificationName"
                                        placeholder="Enter name"
                                        name="notificationName"
                                        value={formik?.values?.notificationName || ''}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('notificationName') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('notificationName')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field">
                                    <div className="mb-2">
                                        <label className="Label__Text">
                                            Upload Image<span className="Label__Required">*</span>
                                        </label>
                                        <div className="file-input-container w-100">
                                            <input type="file" style={{ display: 'none' }} accept="image/*" />
                                            <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={notificationIconFileName} disabled readOnly />
                                            <span className="file-upload-icon">
                                                <i className="pi pi-paperclip" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="notificationTitle" className={classNames({ 'p-error': isFormFieldValid('notificationTitle') }, 'Label__Text')}>
                                        Notification Title<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="notificationTitle"
                                        placeholder="Enter Notification Title"
                                        name="notificationTitle"
                                        value={formik?.values?.notificationTitle || ''}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('notificationTitle') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('notificationTitle')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="notificationText" className={classNames({ 'p-error': isFormFieldValid('notificationText') }, 'Label__Text')}>
                                        Notification Text<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="notificationText"
                                        placeholder="Enter Notification Text"
                                        name="notificationText"
                                        value={formik?.values?.notificationText || ''}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('notificationText') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('notificationText')}
                                </div>
                            </div>
                            <div className="col-12 md:col-12 m-0 p-0">
                                <div className="p-field col-12 md:col-6 pt-3 pb-3 ">
                                    <div className="p-field">
                                        <label htmlFor="notificationTime" className={classNames({ 'p-error': isFormFieldValid('email') }, 'Label__Text')}>
                                            Time
                                        </label>
                                        <InputText
                                            maxLength={100}
                                            type="text"
                                            id="notificationTime"
                                            placeholder="Enter Time In Minutes"
                                            name="notificationTime"
                                            value={formik?.values?.notificationTime}
                                            disabled
                                            className={classNames({ 'p-invalid': isFormFieldValid('notificationTime') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('notificationTime')}
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field ml-3">
                                    <label htmlFor="redirection" className={classNames({ 'p-error': isFormFieldValid('redirection') }, 'Label__Text')}>
                                        Redirection
                                    </label>

                                    <div className="flex">
                                        <div className="mt-2 mr-5">
                                            <RadioButton inputId="applicable" name="redirection" value="Y" disabled checked={formik.values.redirection === 'Y'} />

                                            <label htmlFor="applicable" className={classNames({ 'p-error': isFormFieldValid('applicable') }, 'Label__Text')}>
                                                Yes
                                            </label>
                                        </div>

                                        <div className="mt-2">
                                            <RadioButton inputId="notApplicable" name="redirection" value="N" disabled checked={formik.values.redirection === 'N'} />

                                            <label htmlFor="notApplicable" className={classNames({ 'p-error': isFormFieldValid('notApplicable') }, 'Label__Text')}>
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="cmsUsecaseId" className={classNames({ 'p-error': isFormFieldValid('cmsUsecaseId') }, 'Label__Text')}>
                                        Use Case
                                    </label>
                                    <Dropdown
                                        id="cmsUsecaseId"
                                        placeholder="Select Use Case"
                                        options={useCaseData}
                                        optionLabel="name"
                                        name="cmsUsecaseId"
                                        optionValue="lovId"
                                        value={formik.values.cmsUsecaseId || ''}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsUsecaseId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('cmsUsecaseId')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pb-3">
                                <div className="field-checkbox col-12 -mt-4">
                                    <div className="mr-5 flex flex-column col-2">
                                        <label htmlFor="repitition" className={classNames({ 'p-error': isFormFieldValid('repitition') }, 'Label__Text')} style={{}}>
                                            Repetition
                                        </label>
                                        <Checkbox inputId="repitition" name="repitition" value={formik?.values?.repitition} checked={formik?.values?.repitition === 'Y'} disabled style={{ marginTop: '10px' }} />
                                    </div>
                                    <div className="p-field col-12 md:col-9 mt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="timePeriod" className={classNames({ 'p-error': isFormFieldValid('timePeriod') }, 'Label__Text')}>
                                                Time Period
                                            </label>
                                            <Dropdown
                                                id="timePeriod"
                                                placeholder="Select Product Priority"
                                                options={timePeriodData}
                                                optionLabel="name"
                                                name="timePeriod"
                                                optionValue="lovId"
                                                value={formik.values.timePeriod || ''}
                                                disabled
                                                className={classNames({ 'p-invalid': isFormFieldValid('timePeriod') }, 'Dropdown__Round')}
                                            />

                                            {getFormErrorMessage('timePeriod')}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="frequency" className={classNames({ 'p-error': isFormFieldValid('frequency') }, 'Label__Text')}>
                                        Frequency
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="frequency"
                                        placeholder="Enter Frequency"
                                        name="frequency"
                                        value={formik?.values?.frequency || ''}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('frequency') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('frequency')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6">
                                <div className="p-field">
                                    <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') }, 'Label__Text')}>
                                        Start Date Time<span className="Label__Required">*</span>
                                    </label>
                                    <InputText id="startDate" type="date" name="startDate" value={formik?.values?.startDate} disabled className={classNames({ 'p-invalid': isFormFieldValid('startDate') }, 'Input__Round')} />

                                    {getFormErrorMessage('startDate')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6">
                                <div className="p-field">
                                    <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') }, 'Label__Text')}>
                                        End Date Time<span className="Label__Required">*</span>
                                    </label>
                                    <InputText id="endDate" type="date" name="endDate" value={formik?.values?.endDate} disabled className={classNames({ 'p-invalid': isFormFieldValid('endDate') }, 'Input__Round')} />

                                    {getFormErrorMessage('endDate')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field customMultiselect">
                                    <label htmlFor="tblCmsNotificationAccountLevels" className={classNames({ 'p-error': isFormFieldValid('tblCmsNotificationAccountLevels') }, 'Label__Text')}>
                                        Target User Base<span className="Label__Required">*</span>
                                    </label>
                                    <MultiSelect
                                        id="tblCmsNotificationAccountLevels"
                                        placeholder="Select Target User Base"
                                        options={targAud}
                                        optionLabel="name"
                                        display="chip"
                                        disabled
                                        name="tblCmsNotificationAccountLevels"
                                        optionValue="lovId"
                                      
                                        value={formik?.values?.tblCmsNotificationAccountLevels?.map((data) => data.lovId)}
                                        onChange={formik?.handleChange}

                                        className={classNames({ 'p-invalid': isFormFieldValid('tblCmsNotificationAccountLevels') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('tblCmsNotificationAccountLevels')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 customMultiselect">
                                <div className="p-field customMultiselect">
                                    <label htmlFor="tblCmsNotificationSegments" className={classNames({ 'p-error': isFormFieldValid('tblCmsNotificationSegments') }, 'Label__Text')}>
                                        Segments<span className="Label__Required">*</span>
                                    </label>
                                    <MultiSelect
                                        id="tblCmsNotificationSegments"
                                        placeholder="Select Segments"
                                        options={segmentsData}
                                        optionLabel="name"
                                        display="chip"
                                        disabled
                                        name="tblCmsNotificationSegments"
                                        optionValue="lovId"
                                        value={formik?.values?.tblCmsNotificationSegments?.map((data) => data.lovId)}
                                        onChange={formik?.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('tblCmsNotificationSegments') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('tblCmsNotificationSegments')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="isHide" className={classNames({ 'p-error': isFormFieldValid('isHide') }, 'Label__Text')}>
                                        Status
                                    </label>
                                    <div className="flex ml-2">
                                        <div className="mt-0 mr-5">
                                            <Checkbox inputId="show" name="isHide" value="Y" checked={formik?.values?.isHide === 'Y'} onChange={(e) => formik.setFieldValue('isHide', 'Y')} disabled style={{ marginTop: '10px' }} />
                                            <label htmlFor="show" className={classNames({ 'p-error': isFormFieldValid('show') }, 'Label__Text')}>
                                                Show
                                            </label>
                                        </div>
                                        <div className="mt-0">
                                            <Checkbox inputId="hide" name="isHide" value="N" checked={formik?.values?.isHide === 'N'} onChange={(e) => formik.setFieldValue('isHide', 'N')} disabled style={{ marginTop: '10px' }} />
                                            <label htmlFor="hide" className={classNames({ 'p-error': isFormFieldValid('hide') }, 'Label__Text')}>
                                                Hide
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3 col-4 flex align-items-start justify-content-center">
                            <MobileView icon={iconLink} title={formik?.values?.notificationTitle} descrition={formik?.values?.notificationText} />
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button onClick={() => navigate('/requestchecker')} type="button" label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default ViewNotificationsChecker;

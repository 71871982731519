import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handlePostRequest } from '../../services/PostTemplate';
import { handleGetRequest } from '../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import { FilterMatchMode } from 'primereact/api';
import ActiveInActiveDialog from '../../components/ActiveInActiveDialog/ActiveInActiveDialog';

function UserManagement() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [userManagementData, setUserManagementData] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [userGroupLovData, setUserGroupLovData] = useState([]);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        firstName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [showModal, setShowModal] = useState(false);

    const [userIdData, setUserIdData] = useState();
    const [isActiveData, setIsActiveData] = useState();
    const [userRowData, setUserRowData] = useState();

    const dataTableRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            userId: '',
            employeeId: '',
            firstName: '',
            lastName: '',
            userGroup: '',
            status: '2',
            createdBy: '',
            updatedBy: '',
            dateFrom: '',
            dateTo: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            // const hash = CryptoJS.SHA256(mydata.security + mydata.account + mydata.channel + mydata.terminal + mydata.reterivalReferenceNumber + data + mydata.additionalInformation).toString();
            // console.log('hash: ' + hash);

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ]
                    // checkSum: hash
                }
            };

            dataTableRef.current.reset();

            const res = await dispatch(handlePostRequest(newData, '/mfs_user_management/v1/management/getallusers', true, true));
            if (res?.responseCode === '030000') {
                // formik.resetForm();
                setUserManagementData(res?.payLoad);
            } else {
                setUserManagementData([]);
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(resp?.payLoad);
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };

    const getUserGroupLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getUserGroup/TBL_ROLE:*');
        setUserGroupLovData(resp?.payLoad);
    };

    useEffect(() => {
        getStatusLov();
        getUserLov();
        getUserGroupLov();
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ''));
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">User Management</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const header = renderHeader();

    const EditUserHandled = (rowData) => {
        navigate(`/editusermanagement?userId=${rowData?.userId}`);
    };

    const ViewUserHandled = (rowData) => {
        navigate(`/viewusermanagement?userId=${rowData?.userId}`);
    };

    const handleSwitchChange = async () => {
        const updatedData = userManagementData.map((item) => {
            if (item.userId === userIdData) {
                const isActive = item.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setUserManagementData(updatedData);
        let isactive = '';
        if (isActiveData === 'Y') {
            isactive = 'N';
        } else if (isActiveData === 'N') {
            isactive = 'Y';
        }
        const previousValue = isActiveData;
        const data = {
            isActive: isactive,
            userId: userIdData,
            authentication: userRowData?.authentication,
            userTypeId: userRowData?.userType,
            partner: userRowData?.partnerId,
            partnerGroup: userRowData?.partnerGroup,
            employeeId: userRowData?.employeeId,
            firstName: userRowData?.firstName,
            lastName: userRowData?.lastName,
            userName: userRowData?.username,
            password: userRowData?.password,
            confirmPassword: userRowData?.confirmPassword,
            email: userRowData?.email,
            dob: userRowData?.dob,
            mobileNo: userRowData?.mobileNo
        };
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/mfs_user_management/v1/management/inactiveuser', true, false));
        if (res?.responseCode !== '030000') {
            const revertedData = userManagementData.map((item) => {
                if (item.userId === userIdData) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setUserManagementData(revertedData);
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <InputSwitch 
                        checked={rowData.isActive === 'Y'}
                        disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false} 
                        // onChange={() => handleSwitchChange(rowData.userId, rowData.isActive, rowData)} 
                        onChange={() => {
                            setShowModal(true);
                            // handleSwitchChange(rowData.transLimitId, rowData.isActive, rowData);
                            setUserIdData(rowData?.userId);
                            setIsActiveData(rowData?.isActive);
                            setUserRowData(rowData);
                        }}
                    />
                    <Button
                        tooltip="Edit"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: 'top' }}
                        // disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) || rowData?.isActive === 'N' ? true : false}
                        disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) || (rowData?.lkpStatus?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                        onClick={() => EditUserHandled(rowData)}
                        className="p-button-rounded p-button-primary"
                    />
                    <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => ViewUserHandled(rowData)} className="p-button-rounded" style={{ backgroundColor: '#F2AC57', border: 'none' }} />
                </div>
            </>
        );
    };

    const addNewUserManagement = () => {
        navigate('/addusermanagement');
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const resetHandle = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.lkpStatus?.statusDescr === 'Approved' ? '#14A38B' : rowData?.lkpStatus?.statusDescr === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.lkpStatus?.statusDescr}</b>
            </p>
        );
    };

    const partnerGroupBodyTemplate = (rowData) => {
        const partnerGroupId = rowData?.partnerGroup;
        const filteredItems = userGroupLovData?.filter((item) => item.lovId == partnerGroupId);
        return <p>{filteredItems[0]?.code}</p>;
    };

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const updateDateTemplate = (rowData) => {
        const formattedCreateDate = new Date(rowData?.lastupdatedate).toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });

        return <p>{formattedCreateDate}</p>;
    };

    const handleShowModal = () => {
        setShowModal(false);
    };

    const props = {
        showModal,
        handleShowModal,
        handleSwitchChange
    };

    return (
        <>
            <ActiveInActiveDialog {...props} />
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="New" onClick={() => addNewUserManagement()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="userId" className={classNames({ 'p-error': isFormFieldValid('userId') }, 'Label__Text')}>
                                    User Name
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="userId"
                                    placeholder="Enter User Name"
                                    name="userId"
                                    value={formik?.values?.userId?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('userId') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('userId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="employeeId" className={classNames({ 'p-error': isFormFieldValid('employeeId') }, 'Label__Text')}>
                                    Employee ID
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="employeeId"
                                    placeholder="Enter Employee ID"
                                    name="employeeId"
                                    value={formik?.values?.employeeId?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('employeeId') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('employeeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="firstName" className={classNames({ 'p-error': isFormFieldValid('firstName') }, 'Label__Text')}>
                                    First Name
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="firstName"
                                    placeholder="Enter First Name"
                                    name="firstName"
                                    value={formik?.values?.firstName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('firstName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('firstName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="lastName" className={classNames({ 'p-error': isFormFieldValid('lastName') }, 'Label__Text')}>
                                    Last Name
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="lastName"
                                    placeholder="Enter Last Name"
                                    name="lastName"
                                    value={formik?.values?.lastName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lastName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('lastName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="userGroup" className={classNames({ 'p-error': isFormFieldValid('userGroup') }, 'Label__Text')}>
                                    User Group
                                </label>
                                <Dropdown
                                    id="userGroup"
                                    placeholder="Select User Group"
                                    options={userGroupLovData}
                                    optionLabel="code"
                                    name="userGroup"
                                    optionValue="lovId"
                                    value={formik.values.userGroup || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('userGroup') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('userGroup')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('status') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="status"
                                    placeholder="Select Status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="status"
                                    optionValue="lovId"
                                    value={formik.values.status || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('status') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('status')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Updated By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText
                                    id="dateFrom"
                                    type="date"
                                    max={previousDate}
                                    name="dateFrom"
                                    value={formik?.values?.dateFrom}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText id="dateTo" type="date" max={previousDate} name="dateTo" value={formik?.values?.dateTo} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')} />

                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="Usergroup__SearchBtn p-field col-12 md:col-4">
                            <Button disabled={loading} loadingIcon={loadingIcon} icon={loadingIcon} iconPos="left" label="Search" className="Btn__Dark__Search" />
                            <Button disabled={loading} label="Reset" onClick={(e) => resetHandle(e)} className="Btn__Transparent__Reset" />
                        </div>
                    </div>
                </form>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            filters={filters}
                            globalFilterFields={['username', 'employeeId', 'firstName', 'lastName', 'userGroup', 'isActive', 'createdate', 'createuser', 'lastupdatedate', 'lastupdateuser']}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={userManagementData}
                        >
                            <Column field="username" header="User Name" />
                            <Column field="employeeId" header="Employee ID" />
                            <Column field="firstName" header="First Name" />
                            <Column field="lastName" header="Last Name" />
                            <Column
                                header="User Group"
                                body={partnerGroupBodyTemplate}
                                // field="partnerGroup"
                            />
                            <Column header="Created Date & Time" body={createdDateAndTimeBodyTemplate} />
                            <Column field="createdBy" header="Created By" />
                            <Column header="Updated Date" body={updateDateTemplate} />
                            <Column field="updatedBy" header="Updated By" />
                            <Column header="Status" body={statusBodyTemplate} />
                            <Column header="Actions" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
export default UserManagement;

import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { InputTextarea } from 'primereact/inputtextarea';
import { OverlayPanel } from 'primereact/overlaypanel';
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';
import { InputNumber } from 'primereact/inputnumber';
import { toast } from 'react-toastify';

function AddCustomerCommunicationCampaign({ activeIndex, handleStepClick, setAllStepsComplete, compaignIdData, campaignData, getCompaignId }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
    const [emojiPickerVisibleForNotification, setEmojiPickerVisibleForNotification] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visibleForNotification, setVisibleForNotification] = useState(false);
    const [frequencyPeriodData, setFrequencyPeriodData] = useState([]);

    const op = useRef(null);
    const np = useRef(null);

    const dispatch = useDispatch();

    const handleTextareaChange = (e) => {
        formik.setFieldValue('ccSmsContent', e.target.value);
    };

    const onClickEmoji = (emojiData, event) => {
        formik.setFieldValue('ccSmsContent', formik?.values?.ccSmsContent + (emojiData.isCustom ? emojiData.unified : emojiData.emoji));
        toggleEmojiPicker();
        toggleVisible();
    };

    const toggleEmojiPicker = () => {
        setEmojiPickerVisible(!emojiPickerVisible);
    };

    const toggleVisible = () => {
        setVisible(!visible);
    };

    const handleTextareaChangeForNotification = (e) => {
        formik.setFieldValue('ccNotificationContent', e.target.value);
    };

    const onClickEmojiForNotification = (emojiData, event) => {
        formik.setFieldValue('ccNotificationContent', formik?.values?.ccNotificationContent + (emojiData.isCustom ? emojiData.unified : emojiData.emoji));
        toggleEmojiPickerForNotification();
        toggleVisibleForNotification();
    };

    const toggleEmojiPickerForNotification = () => {
        setEmojiPickerVisibleForNotification(!emojiPickerVisibleForNotification);
    };

    const toggleVisibleForNotification = () => {
        setVisibleForNotification(!visibleForNotification);
    };

    const getFrequencyPeriodData = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getdatalov/LKP_FREQUENCY_PERIOD');
        setFrequencyPeriodData(response?.payLoad);
    };

    useEffect(() => {
        getFrequencyPeriodData();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (campaignData) {
            formik.setFieldValue('ccCommunicationMedium', campaignData?.ccCommunicationMedium?.toString());
            formik.setFieldValue('ccFrequency', campaignData?.ccFrequency);
            formik.setFieldValue('ccFrequencyPeriodCode', campaignData?.lkpCCFrequencyPeriod?.frequencyPeriodCode?.toString());

            formik.setFieldValue('ccMessageType', campaignData?.ccMessageType?.toString());
            formik.setFieldValue('ccNotificationContent', campaignData?.ccNotificationContent);
            formik.setFieldValue('ccSmsContent', campaignData?.ccSmsContent);

            if (campaignData?.ccNotificationContent === null || campaignData?.ccSmsContent === null) {
                formik.setFieldValue('isMessageContent', false);
            } else {
                formik.setFieldValue('isMessageContent', true);
            }

            if (campaignData?.ccMessageType === 'O') {
                formik.setFieldValue('ccFrequencyMonth1', campaignData?.ccFrequencyMonth?.slice(1, -1));
                formik.setFieldValue('ccFrequencyDate', campaignData?.ccFrequencyDate?.slice(1, -1).split(', ')?.toString());
            } else {
                formik.setFieldValue('ccFrequencyMonth', campaignData?.ccFrequencyMonth?.slice(1, -1).split(', '));
                formik.setFieldValue('ccFrequencyDay', campaignData?.ccFrequencyDay?.slice(1, -1).split(', '));
                formik.setFieldValue('ccFrequencyDate1', campaignData?.ccFrequencyDate?.slice(1, -1).split(', '));
            }

            // formik.setFieldValue('ccFrequencyMonth', campaignData?.ccFrequencyMonth?.slice(1, -1).split(', '));
            // formik.setFieldValue('ccFrequencyDay', campaignData?.ccFrequencyDay?.slice(1, -1).split(', '));

            if (campaignData?.tblCampaignCcTimes?.length !== 0) {
                const ccTimeListData = campaignData?.tblCampaignCcTimes
                    ?.filter((item) => item?.isActive === 'Y')
                    ?.map((item) => {
                        return {
                            startTime: item?.startTime || '',
                            endTime: item?.endTime || ''
                        };
                    });
                formik.setFieldValue('frequencyCcTimeList', ccTimeListData);
            }
        }
    }, []); // eslint-disable-line

    const startTimeEndTimeSchema = Yup.object().shape({
        startTime: Yup.string().required('Start Time is required'),
        endTime: Yup.string()
            .required('End Time is required')
            .test('greater-than-start', 'End Time must be greater than Start Time', function (value) {
                const { startTime } = this.parent;
                return !startTime || !value || value > startTime;
            })
    });

    const frequencyCcTimeListSchema = Yup.array().of(startTimeEndTimeSchema);

    const validationSchema = Yup.object().shape({
        ccFrequencyPeriodCode: Yup.string(),
        ccMessageType: Yup.string(),
        frequencyCcTimeList: Yup.array().when(['ccFrequencyPeriodCode', 'ccMessageType'], {
            is: (ccFrequencyPeriodCode, ccMessageType) => ccFrequencyPeriodCode === '10' || ccFrequencyPeriodCode === '20' || ccFrequencyPeriodCode === '30' || ccFrequencyPeriodCode === '40' || ccMessageType === 'O',
            then: frequencyCcTimeListSchema,
            otherwise: Yup.array().of(
                Yup.object().shape({
                    startTime: Yup.string(),
                    endTime: Yup.string()
                })
            )
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            ccCommunicationMedium: '',
            isMessageContent: false,
            ccSmsContent: '',
            ccNotificationContent: '',
            ccMessageType: '',
            ccFrequencyPeriodCode: '',

            ccFrequencyDate1: [],
            ccFrequencyMonth1: [],

            ccFrequencyDay: [],
            ccFrequencyDate: [],
            ccFrequencyMonth: [],
            ccFrequency: '',

            frequencyCcTimeList: [
                {
                    startTime: '',
                    endTime: ''
                }
            ]
        },

        validate: (data) => {
            let errors = {};

            if (data?.ccMessageType === 'O') {
                if (data?.ccFrequencyDate.length === 0) {
                    errors.ccFrequencyDate = 'This field is required';
                }
                if (data?.ccFrequencyMonth1.length === 0) {
                    errors.ccFrequencyMonth1 = 'This field is required.';
                }
            }

            if (!data?.ccCommunicationMedium) {
                errors.ccCommunicationMedium = 'This field is required';
            }
            if (!data?.ccMessageType) {
                errors.ccMessageType = 'This field is required';
            }
            if (['', null, undefined].includes(data?.ccCommunicationMedium)) {
                errors.ccCommunicationMedium = 'This field is required.';
            }

            if (data?.ccCommunicationMedium === 'S') {
                if (data?.isMessageContent) {
                    if (!data?.ccSmsContent) {
                        errors.ccSmsContent = 'This field is required.';
                    }
                }
            }

            if (data?.ccCommunicationMedium === 'N') {
                if (data?.isMessageContent) {
                    if (!data?.ccNotificationContent) {
                        errors.ccNotificationContent = 'This field is required.';
                    }
                }
            }

            if (data?.ccCommunicationMedium === 'B') {
                if (data?.isMessageContent) {
                    if (!data?.ccSmsContent) {
                        errors.ccSmsContent = 'This field is required.';
                    }
                    if (!data?.ccNotificationContent) {
                        errors.ccNotificationContent = 'This field is required.';
                    }
                }
            }

            if (data?.ccMessageType === 'R') {
                if (['', null, undefined].includes(data?.ccFrequencyPeriodCode)) {
                    errors.ccFrequencyPeriodCode = 'This field is required.';
                }
            }

            if (data?.ccFrequencyPeriodCode === '10') {
                // if (data?.frequencyCcTimeList?.length === 0) {
                //     if (!data?.startEndTimeDaily) {
                //         errors.startEndTimeDaily='Date is required'
                //     }
                // }
            } else if (data?.ccFrequencyPeriodCode === '20') {
                if (!data?.ccFrequencyDay) {
                    errors.ccFrequencyDay = 'This field is required';
                }
                // if (data?.frequencyCcTimeList?.length === 0) {
                //     toast.warning('Please add Start and End Time');
                // }
            } else if (data?.ccFrequencyPeriodCode === '30') {
                if (!data?.ccFrequencyDate1) {
                    errors.ccFrequencyDate1 = 'This field is required.';
                }
            } else if (data?.ccFrequencyPeriodCode === '40') {
                if (!data?.ccFrequencyMonth) {
                    errors.ccFrequencyMonth = 'This field is required.';
                }
                if (!data?.ccFrequencyDate1) {
                    errors.ccFrequencyDate1 = 'This field is required';
                }
            } else {
                if (data?.ccFrequencyDay === '') {
                    errors.ccFrequencyDay = 'This field is required.';
                }
                if (data?.ccFrequencyMonth === '') {
                    errors.ccFrequencyMonth = 'This field is required.';
                }
                // if (data?.frequencyCcTimeList?.length === 0) {
                //     toast.warning('Please add Start and End Time');
                // }
            }

            if (data?.ccFrequency === null || data?.ccFrequency === '' || data?.ccFrequency === 0 || !/^[0-9]*$/.test(data.ccFrequency)) {
                errors.ccFrequency = 'Please enter frequency';
            }

            return errors;
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['campaignId'] = compaignIdData || campaignData?.campaignId;
            data['stepNo'] = '4';
            data['ccFrequency'] = data?.ccFrequency.toString();

            if (data?.isMessageContent === false) {
                data['ccNotificationContent'] = '';
                data['ccSmsContent'] = '';
            }

            if (data?.ccCommunicationMedium === 'S') {
                delete data['ccNotificationContent'];
            } else if (data?.ccCommunicationMedium === 'N') {
                delete data['ccSmsContent'];
            }

            if (data?.ccFrequencyPeriodCode === '10') {
                delete data['ccFrequencyDate'];
                delete data['ccFrequencyMonth'];
                delete data['ccFrequencyDay'];
            } else if (data?.ccFrequencyPeriodCode === '20') {
                delete data['ccFrequencyDate'];
                delete data['ccFrequencyMonth'];
            }

            if (data?.ccMessageType === 'O') {
                data['ccFrequencyMonth'] = [data?.ccFrequencyMonth1];
                data['ccFrequencyDate'] = [data?.ccFrequencyDate];
            } else {
                data['ccFrequencyDate'] = data?.ccFrequencyDate1;
            }

            delete data['ccFrequencyMonth1'];
            delete data['ccFrequencyDate1'];

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const formData = new FormData();
            formData.append('request', JSON.stringify(newData));

            if (parseInt(campaignData?.currentStep) >= 4) {
                const res = await dispatch(handlePostRequest(formData, '/customerloyalitypromotions/v1/customerloyalitypromotion/editcampaign', true, false));
                if (res?.responseCode === '150000') {
                    formik.resetForm();
                    getCompaignId(res?.payLoad?.campaignId);
                    handleStepClick(activeIndex + 1);
                    setAllStepsComplete(true);
                }
            } else {
                const res = await dispatch(handlePostRequest(formData, '/customerloyalitypromotions/v1/customerloyalitypromotion/savecampaign', true, false));
                if (res?.responseCode === '150000') {
                    formik.resetForm();
                    handleStepClick(activeIndex + 1);
                    setAllStepsComplete(true);
                }
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const addStartEndTime = () => {
        formik.setFieldValue('frequencyCcTimeList', [
            ...formik.values.frequencyCcTimeList,
            {
                startTime: '',
                endTime: ''
            }
        ]);
    };

    const removeStartEndTime = (index) => {
        if (index > 0) {
            const updatedData = [...formik.values.frequencyCcTimeList];
            updatedData.splice(index, 1);
            formik.setFieldValue('frequencyCcTimeList', updatedData);
        }
        if (index === 0) {
            const updatedData = [...formik.values.frequencyCcTimeList];
            formik.setFieldValue('frequencyCcTimeList', updatedData);
        }
    };

    const handleMessageTypeChange = (e) => {
        const newMessageType = e.target.value;

        if (newMessageType === 'O') {
            formik.setFieldValue('ccFrequencyPeriodCode', '');
        }
        formik.handleChange(e);
    };

    const getCommunicationMediumData = [
        { name: 'SMS', value: 'S' },
        { name: 'Application Notification', value: 'N' },
        { name: 'Both', value: 'B' }
    ];

    const getMessageTypeLov = [
        {
            name: 'One Time',
            lovId: 'O'
        },

        {
            name: 'Recurring',
            lovId: 'R'
        }
    ];

    const weekDays = [
        { name: 'Monday', lovId: 'Monday' },
        { name: 'Tuesday', lovId: 'Tuesday' },
        { name: 'Wednesday', lovId: 'Wednesday' },
        { name: 'Thursday', lovId: 'Thursday' },
        { name: 'Friday', lovId: 'Friday' },
        { name: 'Saturday', lovId: 'Saturday' },
        { name: 'Sunday', lovId: 'Sunday' }
    ];

    const Dates = [
        { name: '1', lovId: '1' },
        { name: '2', lovId: '2' },
        { name: '3', lovId: '3' },
        { name: '4', lovId: '4' },
        { name: '5', lovId: '5' },
        { name: '6', lovId: '6' },
        { name: '7', lovId: '7' },
        { name: '8', lovId: '8' },
        { name: '9', lovId: '9' },
        { name: '10', lovId: '10' },
        { name: '11', lovId: '11' },
        { name: '12', lovId: '12' },
        { name: '13', lovId: '13' },
        { name: '14', lovId: '14' },
        { name: '15', lovId: '15' },
        { name: '16', lovId: '16' },
        { name: '17', lovId: '17' },
        { name: '18', lovId: '18' },
        { name: '19', lovId: '19' },
        { name: '20', lovId: '20' },
        { name: '21', lovId: '21' },
        { name: '22', lovId: '22' },
        { name: '23', lovId: '23' },
        { name: '24', lovId: '24' },
        { name: '25', lovId: '25' },
        { name: '26', lovId: '26' },
        { name: '27', lovId: '27' },
        { name: '28', lovId: '28' },
        { name: '29', lovId: '29' },
        { name: '30', lovId: '30' },
        { name: '31', lovId: '31' }
    ];

    const monthsLov = [
        { name: 'January', lovId: 'January' },
        { name: 'February', lovId: 'February' },
        { name: 'March', lovId: 'March' },
        { name: 'April', lovId: 'April' },
        { name: 'May', lovId: 'May' },
        { name: 'June', lovId: 'June' },
        { name: 'July', lovId: 'July' },
        { name: 'August', lovId: 'August' },
        { name: 'September', lovId: 'September' },
        { name: 'October', lovId: 'October' },
        { name: 'November', lovId: 'November' },
        { name: 'December', lovId: 'December' }
    ];

    return (
        <>
            <div
                className="Form__Header"
                style={{
                    background: '#13a28a',
                    borderRadius: '5px',
                    paddingTop: '12px'
                }}
            >
                <h1 style={{ color: '#fff', marginLeft: '10px' }}>Customer Communication</h1>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="ccCommunicationMedium" className={classNames({ 'p-error': isFormFieldValid('ccCommunicationMedium') }, 'Label__Text')}>
                                    Communication Medium
                                </label>
                                <Dropdown
                                    id="ccCommunicationMedium"
                                    placeholder="Select Communication Medium"
                                    options={getCommunicationMediumData}
                                    optionLabel="name"
                                    name="ccCommunicationMedium"
                                    optionValue="value"
                                    value={formik?.values?.ccCommunicationMedium || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('ccCommunicationMedium') }, 'Dropdown__Round')}
                                />
                                <small className="p-error">{formik.errors.ccCommunicationMedium}</small>
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-center">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="isMessageContent" name="isMessageContent" value={formik?.values?.isMessageContent} checked={formik?.values?.isMessageContent} onChange={formik.handleChange} style={{ marginTop: '10px' }} />
                                    <label htmlFor="isMessageContent" className={classNames({ 'p-error': isFormFieldValid('isMessageContent') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        Message Content
                                    </label>
                                </div>
                                {getFormErrorMessage('isMessageContent')}
                            </div>
                        </div>
                        {(formik?.values?.ccCommunicationMedium === 'S' || formik?.values?.ccCommunicationMedium === 'B') && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field" style={{ position: 'relative' }}>
                                    <label htmlFor="ccSmsContent" className={classNames({ 'p-error': isFormFieldValid('ccSmsContent') }, 'Label__Text')}>
                                        SMS
                                    </label>
                                    <InputTextarea
                                        id="ccSmsContent"
                                        placeholder="Enter SMS"
                                        name="ccSmsContent"
                                        rows={5}
                                        cols={30}
                                        autoResize
                                        disabled={formik?.values?.isMessageContent === false ? true : false}
                                        value={formik?.values?.ccSmsContent || ''}
                                        onChange={handleTextareaChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('ccSmsContent') }, 'Input__Round')}
                                    />
                                    <p
                                        onClick={(e) => {
                                            toggleEmojiPicker();
                                            op.current.toggle(e);
                                            toggleVisible();
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            position: 'absolute',
                                            top: '25px',
                                            right: '10px'
                                        }}
                                    >
                                        {'\u{1F600}'}
                                    </p>
                                    <OverlayPanel ref={op} onHide={() => toggleVisible()}>
                                        <EmojiPicker onEmojiClick={onClickEmoji} autoFocusSearch={false} emojiStyle={EmojiStyle.NATIVE} />
                                    </OverlayPanel>
                                </div>
                                {getFormErrorMessage('ccSmsContent')}
                            </div>
                        )}
                        {(formik?.values?.ccCommunicationMedium === 'B' || formik?.values?.ccCommunicationMedium === 'N') && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field" style={{ position: 'relative' }}>
                                    <label htmlFor="ccNotificationContent" className={classNames({ 'p-error': isFormFieldValid('ccNotificationContent') }, 'Label__Text')}>
                                        Application Notification
                                    </label>
                                    <InputTextarea
                                        id="ccNotificationContent"
                                        placeholder="Enter Application Notification"
                                        name="ccNotificationContent"
                                        rows={5}
                                        cols={30}
                                        autoResize
                                        disabled={formik?.values?.isMessageContent === false ? true : false}
                                        value={formik?.values?.ccNotificationContent || ''}
                                        onChange={handleTextareaChangeForNotification}
                                        className={classNames(
                                            {
                                                'p-invalid': isFormFieldValid('ccNotificationContent')
                                            },
                                            'Input__Round'
                                        )}
                                    />
                                    <p
                                        onClick={(e) => {
                                            toggleEmojiPickerForNotification();
                                            op.current.toggle(e);
                                            toggleVisibleForNotification();
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            position: 'absolute',
                                            top: '25px',
                                            right: '10px'
                                        }}
                                    >
                                        {'\u{1F600}'}
                                    </p>
                                    <OverlayPanel ref={np} onHide={() => toggleVisibleForNotification()}>
                                        <EmojiPicker onEmojiClick={onClickEmojiForNotification} autoFocusSearch={false} emojiStyle={EmojiStyle.NATIVE} />
                                    </OverlayPanel>
                                    {getFormErrorMessage('ccNotificationContent')}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="ccMessageType" className={classNames({ 'p-error': isFormFieldValid('ccMessageType') }, 'Label__Text')}>
                                    Message Type
                                </label>
                                <Dropdown
                                    id="ccMessageType"
                                    placeholder="Select Message Type"
                                    options={getMessageTypeLov}
                                    optionLabel="name"
                                    name="ccMessageType"
                                    optionValue="lovId"
                                    value={formik?.values?.ccMessageType || ''}
                                    onChange={handleMessageTypeChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('ccMessageType') }, 'Dropdown__Round')}
                                />
                                <small className="p-error">{formik.errors.ccMessageType}</small>
                            </div>
                        </div>
                        {formik?.values?.ccMessageType === 'O' && (
                            <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card flex">
                                <div className="p-fluid p-formgrid grid mb-5 w-100">
                                    <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="ccFrequencyMonth1" className={classNames({ 'p-error': isFormFieldValid('ccFrequencyMonth1') }, 'Label__Text')}>
                                                Month
                                            </label>
                                            <Dropdown
                                                id="ccFrequencyMonth1"
                                                placeholder="Select Month"
                                                options={monthsLov}
                                                optionLabel="name"
                                                name="ccFrequencyMonth1"
                                                optionValue="lovId"
                                                value={formik.values.ccFrequencyMonth1 || ''}
                                                onChange={formik.handleChange}
                                                className={classNames(
                                                    {
                                                        'p-invalid': isFormFieldValid('ccFrequencyMonth1')
                                                    },
                                                    'Dropdown__Round'
                                                )}
                                            />
                                            <small className="p-error">{formik.errors.ccFrequencyMonth1}</small>
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="ccFrequencyDate" className={classNames({ 'p-error': isFormFieldValid('ccFrequencyDate') }, 'Label__Text')}>
                                                Date Of Month
                                            </label>
                                            <Dropdown
                                                id="ccFrequencyDate"
                                                placeholder="Select Dates"
                                                options={Dates}
                                                optionLabel="name"
                                                name="ccFrequencyDate"
                                                optionValue="lovId"
                                                value={formik.values.ccFrequencyDate || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('ccFrequencyDate') }, 'Dropdown__Round')}
                                            />
                                            <small className="p-error">{formik.errors.ccFrequencyDate}</small>
                                        </div>
                                    </div>
                                    {formik?.values?.frequencyCcTimeList?.map((startEndTimeMonthly, index) => {
                                        return (
                                            <div className="col-12 md:col-4 flex-direction-col md:flex" key={index}>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label
                                                            htmlFor={`frequencyCcTimeList[${index}].startTime`}
                                                            className={classNames(
                                                                {
                                                                    'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`)
                                                                },
                                                                'Label__Text'
                                                            )}
                                                        >
                                                            Start Time
                                                        </label>
                                                        <InputText
                                                            id={`frequencyCcTimeList[${index}].startTime`}
                                                            type="time"
                                                            name={`frequencyCcTimeList[${index}].startTime`}
                                                            value={startEndTimeMonthly?.startTime || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames(
                                                                {
                                                                    'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`)
                                                                },
                                                                'Input__Round'
                                                            )}
                                                        />
                                                        <div style={{ width: '100%', padding: '5px' }} />
                                                        <small className="p-error">{formik?.errors?.frequencyCcTimeList !== undefined ? formik?.errors?.frequencyCcTimeList[index]?.startTime : null}</small>
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                    <div className="p-field">
                                                        <label
                                                            htmlFor={`frequencyCcTimeList[${index}].endTime`}
                                                            className={classNames(
                                                                {
                                                                    'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`)
                                                                },
                                                                'Label__Text'
                                                            )}
                                                        >
                                                            End Time
                                                        </label>
                                                        <InputText
                                                            id={`frequencyCcTimeList[${index}].endTime`}
                                                            type="time"
                                                            name={`frequencyCcTimeList[${index}].endTime`}
                                                            value={startEndTimeMonthly?.endTime || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames(
                                                                {
                                                                    'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`)
                                                                },
                                                                'Input__Round'
                                                            )}
                                                        />
                                                        <div style={{ width: '100%', padding: '5px' }} />
                                                        <small className="p-error">{formik?.errors?.frequencyCcTimeList !== undefined ? formik?.errors?.frequencyCcTimeList[index]?.endTime : null}</small>
                                                    </div>
                                                </div>
                                                {/* <div className="p-field col-12 md:col-2 flex align-items-center pt-3 pb-3">
                                                        <Button
                                                            icon="pi pi-plus"
                                                            className="p-button-rounded mt-2"
                                                            aria-label="Filter"
                                                            tooltip="Add New Start & End Time"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                addStartEndTime();
                                                            }}
                                                        />
                                                        <Button
                                                            icon="pi pi-minus"
                                                            className="p-button-rounded mt-2 p-button-danger"
                                                            aria-label="Filter"
                                                            tooltip="Remove Start & End Time"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                removeStartEndTime(index);
                                                            }}
                                                        />
                                                    </div> */}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                        {formik?.values?.ccMessageType === 'R' && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="ccFrequencyPeriodCode" className={classNames({ 'p-error': isFormFieldValid('ccFrequencyPeriodCode') }, 'Label__Text')}>
                                        Frequency Period
                                    </label>
                                    <Dropdown
                                        id="ccFrequencyPeriodCode"
                                        placeholder="Select Frequency Period"
                                        options={frequencyPeriodData}
                                        optionLabel="name"
                                        name="ccFrequencyPeriodCode"
                                        optionValue="code"
                                        value={formik?.values?.ccFrequencyPeriodCode || ''}
                                        onChange={formik.handleChange}
                                        className={classNames(
                                            {
                                                'p-invalid': isFormFieldValid('ccFrequencyPeriodCode')
                                            },
                                            'Dropdown__Round'
                                        )}
                                    />
                                    {getFormErrorMessage('ccFrequencyPeriodCode')}
                                </div>
                            </div>
                        )}
                    </div>
                    {formik?.values?.ccFrequencyPeriodCode === '10' && formik?.values?.ccMessageType === 'R' && (
                        <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card flex">
                            <div className="p-fluid p-formgrid grid mb-5 w-100">
                                {formik?.values?.frequencyCcTimeList?.map((startEndTimeDaily, index) => {
                                    return (
                                        <div className="col-12 md:col-6 flex-direction-col md:flex" key={index}>
                                            <div className="p-field col-12 md:col-4 mr-1 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label
                                                        htmlFor={`frequencyCcTimeList[${index}].startTime`}
                                                        className={classNames(
                                                            {
                                                                'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`)
                                                            },
                                                            'Label__Text'
                                                        )}
                                                    >
                                                        Start Time
                                                    </label>
                                                    <InputText
                                                        id={`frequencyCcTimeList[${index}].startTime`}
                                                        type="time"
                                                        name={`frequencyCcTimeList[${index}].startTime`}
                                                        value={startEndTimeDaily?.startTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames(
                                                            {
                                                                'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`)
                                                            },
                                                            'Input__Round'
                                                        )}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    <small className="p-error">{formik?.errors?.frequencyCcTimeList !== undefined ? formik?.errors?.frequencyCcTimeList[index]?.startTime : null}</small>
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label
                                                        htmlFor={`frequencyCcTimeList[${index}].endTime`}
                                                        className={classNames(
                                                            {
                                                                'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`)
                                                            },
                                                            'Label__Text'
                                                        )}
                                                    >
                                                        End Time
                                                    </label>
                                                    <InputText
                                                        id={`frequencyCcTimeList[${index}].endTime`}
                                                        type="time"
                                                        name={`frequencyCcTimeList[${index}].endTime`}
                                                        value={startEndTimeDaily?.endTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames(
                                                            {
                                                                'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`)
                                                            },
                                                            'Input__Round'
                                                        )}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    <small className="p-error">{formik?.errors?.frequencyCcTimeList !== undefined ? formik?.errors?.frequencyCcTimeList[index]?.endTime : null}</small>
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-2 flex align-items-center pt-3 pb-3">
                                                <Button
                                                    icon="pi pi-plus"
                                                    className="p-button-rounded mt-2"
                                                    aria-label="Filter"
                                                    tooltip="Add New Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addStartEndTime();
                                                    }}
                                                />
                                                <Button
                                                    icon="pi pi-minus"
                                                    className="p-button-rounded mt-2 p-button-danger"
                                                    aria-label="Filter"
                                                    tooltip="Remove Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        removeStartEndTime(index);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    {formik?.values?.ccFrequencyPeriodCode === '20' && formik?.values?.ccMessageType === 'R' && (
                        <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card flex">
                            <div className="p-fluid p-formgrid grid mb-5 w-100">
                                {formik?.values?.frequencyCcTimeList?.map((startEndTimeWeekly, index) => {
                                    return (
                                        <div className="col-12 md:col-6 flex-direction-col md:flex" key={index}>
                                            <div className="p-field col-12 md:col-4 mr-1 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label
                                                        htmlFor={`frequencyCcTimeList[${index}].startTime`}
                                                        className={classNames(
                                                            {
                                                                'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`)
                                                            },
                                                            'Label__Text'
                                                        )}
                                                    >
                                                        Start Time
                                                    </label>
                                                    <InputText
                                                        id={`frequencyCcTimeList[${index}].startTime`}
                                                        type="time"
                                                        name={`frequencyCcTimeList[${index}].startTime`}
                                                        value={startEndTimeWeekly?.startTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames(
                                                            {
                                                                'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`)
                                                            },
                                                            'Input__Round'
                                                        )}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    {/* {getFormErrorMessage(`frequencyCcTimeList[${index}].startTime`)} */}
                                                    <small className="p-error">{formik?.errors?.frequencyCcTimeList !== undefined ? formik?.errors?.frequencyCcTimeList[index]?.startTime : null}</small>
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label
                                                        htmlFor={`frequencyCcTimeList[${index}].endTime`}
                                                        className={classNames(
                                                            {
                                                                'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`)
                                                            },
                                                            'Label__Text'
                                                        )}
                                                    >
                                                        End Time
                                                    </label>
                                                    <InputText
                                                        id={`frequencyCcTimeList[${index}].endTime`}
                                                        type="time"
                                                        name={`frequencyCcTimeList[${index}].endTime`}
                                                        value={startEndTimeWeekly?.endTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames(
                                                            {
                                                                'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`)
                                                            },
                                                            'Input__Round'
                                                        )}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    {/* {getFormErrorMessage(`frequencyCcTimeList[${index}].endTime`)} */}
                                                    <small className="p-error">{formik?.errors?.frequencyCcTimeList !== undefined ? formik?.errors?.frequencyCcTimeList[index]?.endTime : null}</small>
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-2 flex align-items-center pt-3 pb-3">
                                                <Button
                                                    icon="pi pi-plus"
                                                    className="p-button-rounded mt-2"
                                                    aria-label="Filter"
                                                    tooltip="Add New Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addStartEndTime();
                                                    }}
                                                />
                                                <Button
                                                    icon="pi pi-minus"
                                                    className="p-button-rounded mt-2 p-button-danger"
                                                    aria-label="Filter"
                                                    tooltip="Remove Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        removeStartEndTime(index);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="ccFrequencyDay" className={classNames({ 'p-error': isFormFieldValid('ccFrequencyDay') }, 'Label__Text')}>
                                            Days
                                        </label>
                                        <MultiSelect
                                            id="ccFrequencyDay"
                                            placeholder="Select Days"
                                            options={weekDays}
                                            optionLabel="name"
                                            name="ccFrequencyDay"
                                            optionValue="lovId"
                                            value={formik.values.ccFrequencyDay || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('ccFrequencyDay') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{formik.errors.ccFrequencyDay}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {formik?.values?.ccFrequencyPeriodCode === '30' && formik?.values?.ccMessageType === 'R' && (
                        <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card flex">
                            <div className="p-fluid p-formgrid grid mb-5 w-100">
                                {formik?.values?.frequencyCcTimeList?.map((startEndTimeMonthly, index) => {
                                    return (
                                        <div className="col-12 md:col-6 flex-direction-col md:flex" key={index}>
                                            <div className="p-field col-12 md:col-4 mr-1 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label
                                                        htmlFor={`frequencyCcTimeList[${index}].startTime`}
                                                        className={classNames(
                                                            {
                                                                'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`)
                                                            },
                                                            'Label__Text'
                                                        )}
                                                    >
                                                        Start Time
                                                    </label>
                                                    <InputText
                                                        id={`frequencyCcTimeList[${index}].startTime`}
                                                        type="time"
                                                        name={`frequencyCcTimeList[${index}].startTime`}
                                                        value={startEndTimeMonthly?.startTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames(
                                                            {
                                                                'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`)
                                                            },
                                                            'Input__Round'
                                                        )}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    {/* {getFormErrorMessage(`frequencyCcTimeList[${index}].startTime`)} */}
                                                    <small className="p-error">{formik?.errors?.frequencyCcTimeList !== undefined ? formik?.errors?.frequencyCcTimeList[index]?.startTime : null}</small>
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label
                                                        htmlFor={`frequencyCcTimeList[${index}].endTime`}
                                                        className={classNames(
                                                            {
                                                                'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`)
                                                            },
                                                            'Label__Text'
                                                        )}
                                                    >
                                                        End Time
                                                    </label>
                                                    <InputText
                                                        id={`frequencyCcTimeList[${index}].endTime`}
                                                        type="time"
                                                        name={`frequencyCcTimeList[${index}].endTime`}
                                                        value={startEndTimeMonthly?.endTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames(
                                                            {
                                                                'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`)
                                                            },
                                                            'Input__Round'
                                                        )}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    {/* {getFormErrorMessage(`frequencyCcTimeList[${index}].endTime`)} */}
                                                    <small className="p-error">{formik?.errors?.frequencyCcTimeList !== undefined ? formik?.errors?.frequencyCcTimeList[index]?.endTime : null}</small>
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-2 flex align-items-center pt-3 pb-3">
                                                <Button
                                                    icon="pi pi-plus"
                                                    className="p-button-rounded mt-2"
                                                    aria-label="Filter"
                                                    tooltip="Add New Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addStartEndTime();
                                                    }}
                                                />
                                                <Button
                                                    icon="pi pi-minus"
                                                    className="p-button-rounded mt-2 p-button-danger"
                                                    aria-label="Filter"
                                                    tooltip="Remove Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        removeStartEndTime(index);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="ccFrequencyDate1" className={classNames({ 'p-error': isFormFieldValid('ccFrequencyDate1') }, 'Label__Text')}>
                                            Date Of Month
                                        </label>
                                        <MultiSelect
                                            id="ccFrequencyDate1"
                                            placeholder="Select Dates"
                                            options={Dates}
                                            optionLabel="name"
                                            name="ccFrequencyDate1"
                                            optionValue="lovId"
                                            value={formik.values.ccFrequencyDate1 || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('ccFrequencyDate1') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{formik.errors.ccFrequencyDate1}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {formik?.values?.ccFrequencyPeriodCode === '40' && formik?.values?.ccMessageType === 'R' && (
                        <div style={{ backgroundColor: '#edf0f5', padding: '3rem' }} className=" col-12 card flex">
                            <div className="p-fluid p-formgrid grid mb-5 w-100">
                                <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="ccFrequencyMonth" className={classNames({ 'p-error': isFormFieldValid('ccFrequencyMonth') }, 'Label__Text')}>
                                            Month
                                        </label>
                                        <MultiSelect
                                            id="ccFrequencyMonth"
                                            placeholder="Select Month"
                                            options={monthsLov}
                                            optionLabel="name"
                                            name="ccFrequencyMonth"
                                            optionValue="lovId"
                                            value={formik.values.ccFrequencyMonth || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('ccFrequencyMonth') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{formik.errors.ccFrequencyMonth}</small>
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-4 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="ccFrequencyDate1" className={classNames({ 'p-error': isFormFieldValid('ccFrequencyDate1') }, 'Label__Text')}>
                                            Date Of Month
                                        </label>
                                        <MultiSelect
                                            id="ccFrequencyDate1"
                                            placeholder="Select Dates"
                                            options={Dates}
                                            optionLabel="name"
                                            name="ccFrequencyDate1"
                                            optionValue="lovId"
                                            value={formik.values.ccFrequencyDate1 || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('ccFrequencyDate1') }, 'Dropdown__Round')}
                                        />
                                        <small className="p-error">{formik.errors.ccFrequencyDate1}</small>
                                    </div>
                                </div>
                                {formik?.values?.frequencyCcTimeList?.map((startEndTimeMonthly, index) => {
                                    return (
                                        <div className="col-12 md:col-4 flex-direction-col md:flex" key={index}>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label
                                                        htmlFor={`frequencyCcTimeList[${index}].startTime`}
                                                        className={classNames(
                                                            {
                                                                'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`)
                                                            },
                                                            'Label__Text'
                                                        )}
                                                    >
                                                        Start Time
                                                    </label>
                                                    <InputText
                                                        id={`frequencyCcTimeList[${index}].startTime`}
                                                        type="time"
                                                        name={`frequencyCcTimeList[${index}].startTime`}
                                                        value={startEndTimeMonthly?.startTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames(
                                                            {
                                                                'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].startTime`)
                                                            },
                                                            'Input__Round'
                                                        )}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    {/* {getFormErrorMessage(`frequencyCcTimeList[${index}].startTime`)} */}
                                                    <small className="p-error">{formik?.errors?.frequencyCcTimeList !== undefined ? formik?.errors?.frequencyCcTimeList[index]?.startTime : null}</small>
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label
                                                        htmlFor={`frequencyCcTimeList[${index}].endTime`}
                                                        className={classNames(
                                                            {
                                                                'p-error': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`)
                                                            },
                                                            'Label__Text'
                                                        )}
                                                    >
                                                        End Time
                                                    </label>
                                                    <InputText
                                                        id={`frequencyCcTimeList[${index}].endTime`}
                                                        type="time"
                                                        name={`frequencyCcTimeList[${index}].endTime`}
                                                        value={startEndTimeMonthly?.endTime || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames(
                                                            {
                                                                'p-invalid': isFormFieldValid(`frequencyCcTimeList[${index}].endTime`)
                                                            },
                                                            'Input__Round'
                                                        )}
                                                    />
                                                    <div style={{ width: '100%', padding: '5px' }} />
                                                    {/* {getFormErrorMessage(`frequencyCcTimeList[${index}].endTime`)} */}
                                                    <small className="p-error">{formik?.errors?.frequencyCcTimeList !== undefined ? formik?.errors?.frequencyCcTimeList[index]?.endTime : null}</small>
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4 flex align-items-center justify-content-evenly pt-3 pb-3">
                                                <Button
                                                    icon="pi pi-plus"
                                                    className="p-button-rounded mt-2"
                                                    aria-label="Filter"
                                                    tooltip="Add New Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addStartEndTime();
                                                    }}
                                                />
                                                <Button
                                                    icon="pi pi-minus"
                                                    className="p-button-rounded mt-2 p-button-danger"
                                                    aria-label="Filter"
                                                    tooltip="Remove Start & End Time"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        removeStartEndTime(index);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="ccFrequency" className={classNames({ 'p-error': isFormFieldValid('ccFrequency') }, 'Label__Text')}>
                                    Frequency
                                </label>
                            </div>
                            <InputNumber
                                inputId="ccFrequency"
                                name="ccFrequency"
                                maxLength={9}
                                placeholder="Enter Frequency"
                                value={formik?.values?.ccFrequency || ''}
                                onValueChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('ccFrequency') }, 'Input_Number')}
                            />
                            {getFormErrorMessage('ccFrequency')}
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label={parseInt(campaignData?.currentStep) >= 4 ? 'Edit' : 'Save'} className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default AddCustomerCommunicationCampaign;

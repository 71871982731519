import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react';

const NumberOfAccounts = ({ numberOfAccountData }) => {
    return (
        <div className="col-12 md:col-6">
            <div className="card Card__Round">
                <div className="cardBarCharts">
                    <h5>Number Of Accounts</h5>
                    <div className="pt-3">
                        <DataTable value={numberOfAccountData && numberOfAccountData} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} showGridlines>
                            <Column field="deviceType" header="Device Type" style={{ width: '25%', backgroundColor: '#efe9fa' }} />
                            <Column field="accounts" header="Number Of Accounts" style={{ width: '25%', backgroundColor: '#f5f1ca' }} />
                            <Column field="deviceModel" header="Device Model" style={{ width: '25%', backgroundColor: '#dce4f8' }} />
                        </DataTable>
                    </div>
                    {/* <div className="pt-3">
                        <table className="rc_table w-full">
                            <thead>
                                <tr>
                                    <th style={{ backgroundColor: '#efe9fa' }}>Device Type</th>
                                    <th style={{ backgroundColor: '#f5f1ca' }}>Number Of Accounts</th>
                                    <th style={{ backgroundColor: '#dce4f8' }}>Device Model</th>
                                </tr>
                            </thead>
                            <tbody className="text-center">
                                {numberOfAccountData &&
                                    numberOfAccountData.map((item) => (
                                        <tr>
                                            <td>{item?.deviceType}</td>
                                            <td>{item?.accounts}</td>
                                            <td>{item?.deviceModel}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default NumberOfAccounts;

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../services/GetTemplate';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';

function TaxProfileOldNewValuesView({ taxProfileRowData }) {
    const [currencyIdLov, setcurrencyIdLov] = useState();
    const [applicableLov, setApplicableLov] = useState();
    const [getRegimeLevelLov, setgetRegimeLevelLov] = useState();
    const [isActiveAmountData, setIsActiveAmountData] = useState(false);
    const [isActiveAmountSlabData, setIsActiveAmountSlabData] = useState(false);
    const [indicateValues, setIndicateValues] = useState();
    const [regimeLovValues, setregimeLovValues] = useState();

    useEffect(() => {
        const keyData = JSON?.parse(JSON?.stringify(taxProfileRowData));
        const keys = Object?.keys(keyData);
        keys.forEach((item) => {
            formik.setFieldValue(`${item}`, keyData[item]);
        });
        if (keyData !== null && typeof keyData !== 'undefined') {
            formik.setFieldValue('taxTypeId', keyData?.lkpTaxType?.taxTypeId?.toString());
            formik.setFieldValue('regimeLevelId', keyData?.regimeLevelId?.toString());
            formik.setFieldValue('currencyId', keyData?.lkpCurrency?.currencyId?.toString());
            formik.setFieldValue('regimeCategoryId', keyData?.lkpRegimeCategory?.regimeCategoryId?.toString());
            formik.setFieldValue('regimeLevelIds', keyData?.taxRateRequest?.regimeLevelIds);
            formik.setFieldValue('isFiler', keyData?.isFiler?.toString());
            formik.setFieldValue('accountId', keyData?.tblAccount?.accountId?.toString());
            formik.setFieldValue('taxRateType', keyData?.taxRateRequest?.taxRateType);
            formik.setFieldValue('taxPercentage', keyData?.taxRateRequest?.taxRate);
            formik.setFieldValue('thresholdAmount', keyData?.taxRateRequest?.thresholdAmount);
            formik.setFieldValue('effectiveFrom', keyData?.taxRateRequest?.effectiveFrom);
            formik.setFieldValue('slabthresholdAmount', keyData?.taxRateRequest?.slabthresholdAmount?.toString());
            formik.setFieldValue('slabType', keyData?.taxRateRequest?.slabType);
            formik.setFieldValue('taxRate', keyData?.taxRateRequest?.taxRate?.toString());
            formik.setFieldValue('fromAmount', keyData?.taxRateRequest?.fromAmount?.toString());
            formik.setFieldValue('toAmount', keyData?.taxRateRequest?.toAmount?.toString());

            formik.setFieldValue('taxAmount', keyData?.taxRateRequest?.taxAmount?.toString());
            formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);

            const firstTaxRateRequest = keyData.taxRateRequest;
            formik.setFieldValue('taxSlabPercentage', firstTaxRateRequest?.taxSlabPercentage);
            formik.setFieldValue('taxSlabAmount', firstTaxRateRequest?.taxSlabAmount);

            if (firstTaxRateRequest?.thresholdAmount === '' || firstTaxRateRequest?.thresholdAmount === null) {
                setIsActiveAmountData(false);
            } else {
                setIsActiveAmountData(true);
            }

            if (firstTaxRateRequest?.slabthresholdAmount === '' || firstTaxRateRequest?.slabthresholdAmount === null) {
                setIsActiveAmountSlabData(false);
            } else {
                setIsActiveAmountSlabData(true);
            }
        }
    }, [taxProfileRowData]); // eslint-disable-line

    const getcurrencyIdLov = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getAllCurrency/LKP_CURRENCY:*');
        setcurrencyIdLov(response?.payLoad);
    };
    const getTaxTypeLovValues = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getAllTaxType/LKP_TAX_TYPE:*');
        setApplicableLov(response?.payLoad);
    };

    const getIndicateGlValues = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getGlAccounts/TBL_ACCOUNT:*');
        setIndicateValues(response?.payLoad);
    };
    const getRegimeLevelLovValues = async () => {
        const response = await handleGetRequest('/configuration/v1/lookups/getRegimeCategory/LKP_REGIME_CATEGORY:*');
        setgetRegimeLevelLov(response?.payLoad);
    };

    useEffect(() => {
        getcurrencyIdLov();
        getTaxTypeLovValues();
        getIndicateGlValues();
        getRegimeLevelLovValues();
    }, []); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            taxTypeId: '',
            isFiler: '',
            regimeLevelIds: [],
            currencyId: '',
            accountId: '',
            taxAmount: '',
            taxPercentage: '',
            fromAmount: '',
            toAmount: '',
            slabType: '',
            thresholdAmount: '',
            effectiveFrom: '',
            taxRateRequests: {},
            taxRegimeRequest: {},
            taxRate: '',
            slabthresholdAmount: '',
            fixedThreshold: false,
            percentageThreshold: false,
            slabThreshold: false,
            tierThreshold: false,
            checkerComments: '',
            action: '',
            isActive: '',
            taxSlabAmount: '',
            taxSlabPercentage: '',
            taxRateId: '',
            taxSlabId: '',
            isActiveAmount: false,
            isActiveSlabAmount: false,
            regimeCategoryId: ''
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const calculationMethodJson = [
        {
            label: 'Fixed',
            description: 'F'
        },
        {
            label: 'Percentage',
            description: 'P'
        },
        {
            label: 'Slab Based',
            description: 'S'
        },
        {
            label: 'Tier Based',
            description: 'T'
        }
    ];
    const applicabledropDown = [
        {
            value: 'Y',
            label: 'Filer'
        },
        {
            value: 'N',
            label: 'Non filer'
        },
        {
            value: 'B',
            label: 'Both'
        }
    ];

    const slabTypeJson = [
        {
            label: 'Fixed',
            value: 'F'
        },
        {
            label: 'Percentage',
            value: 'P'
        }
    ];
    const getRegimeNameLovs = async () => {
        const response = await handleGetRequest(`/configuration/v1/lookups/getAllRegimeLevel/LKP_REGIME_LEVEL:*/${formik?.values?.regimeCategoryId}`);
        setregimeLovValues(response?.payLoad);
    };

    useEffect(() => {
        if (formik?.values?.regimeCategoryId) {
            getRegimeNameLovs();
        }
    }, [formik?.values?.regimeCategoryId]); // eslint-disable-line

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>EDIT TAX PROFILE CHECKER</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="taxTypeId" className={classNames({ 'p-error': isFormFieldValid('taxTypeId') }, 'Label__Text')}>
                                    Type Of Tax
                                </label>
                                <Dropdown
                                    id="taxTypeId"
                                    placeholder="Select Type Of Tax"
                                    options={applicableLov}
                                    optionLabel="name"
                                    name="taxTypeId"
                                    optionValue="lovId"
                                    value={formik.values.taxTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('taxTypeId') }, 'Dropdown__Round')}
                                    disabled={true}
                                />

                                {getFormErrorMessage('taxTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="regimeCategoryId" className={classNames({ 'p-error': isFormFieldValid('regimeCategoryId') }, 'Label__Text')}>
                                    Regime Category
                                </label>
                                <Dropdown
                                    id="regimeCategoryId"
                                    placeholder="Select Regime Level"
                                    options={getRegimeLevelLov}
                                    optionLabel="name"
                                    name="regimeCategoryId"
                                    optionValue="lovId"
                                    value={formik.values.regimeCategoryId || ''}
                                    onChange={formik.handleChange}
                                    disabled={true}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regimeCategoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('regimeCategoryId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="regimeLevelIds" className={classNames({ 'p-error': isFormFieldValid('regimeLevelIds') }, 'Label__Text')}>
                                    Regime Name
                                </label>
                                <MultiSelect
                                    disabled
                                    id="regimeLevelIds"
                                    placeholder="Select Regime Name"
                                    options={regimeLovValues}
                                    optionLabel="name"
                                    name="regimeLevelIds"
                                    optionValue="lovId"
                                    value={formik.values.regimeLevelIds || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('regimeLevelIds') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('regimeLevelIds')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="currencyId" className={classNames({ 'p-error': isFormFieldValid('currencyId') }, 'Label__Text')}>
                                    Currency
                                </label>
                                <Dropdown
                                    id="currencyId"
                                    placeholder="Select currencyId"
                                    options={currencyIdLov}
                                    optionLabel="name"
                                    name="currencyId"
                                    optionValue="lovId"
                                    disabled={true}
                                    value={formik.values.currencyId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('currencyId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('currencyId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isFiler" className={classNames({ 'p-error': isFormFieldValid('isFiler') }, 'Label__Text')}>
                                    Apply On
                                </label>
                                <Dropdown
                                    id="isFiler"
                                    placeholder="Select Apply On"
                                    options={applicabledropDown}
                                    optionLabel="label"
                                    name="isFiler"
                                    optionValue="value"
                                    disabled={true}
                                    value={formik.values.isFiler || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('isFiler') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('isFiler')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountId" className={classNames({ 'p-error': isFormFieldValid('accountId') }, 'Label__Text')}>
                                    Indicate GL
                                </label>
                                <Dropdown
                                    id="accountId"
                                    placeholder="Select Indicate GL"
                                    options={indicateValues}
                                    optionLabel="accountNo"
                                    name="accountId"
                                    optionValue="accountId"
                                    disabled={true}
                                    value={formik.values.accountId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('accountId')}
                            </div>
                        </div>
                        <div className="Form__Header Full__Width">
                            <h2>DEFINE RATE CALCULATION</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="taxRateType" className={classNames({ 'p-error': isFormFieldValid('taxRateType') }, 'Label__Text')}>
                                    Calculation Method
                                </label>
                                <Dropdown
                                    id="taxRateType"
                                    placeholder="Select Calculation Method"
                                    options={calculationMethodJson}
                                    optionLabel="label"
                                    name="taxRateType"
                                    optionValue="description"
                                    value={formik.values.taxRateType || ''}
                                    onChange={formik.handleChange}
                                    disabled={true}
                                    className={classNames({ 'p-invalid': isFormFieldValid('taxRateType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('taxRateType')}
                            </div>
                        </div>
                        {formik.values.taxRateType !== 'P' && formik.values.taxRateType !== 'F' && formik.values.taxRateType !== 'S' && formik.values.taxRateType !== 'T' && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="taxAmount" className={classNames({ 'p-error': isFormFieldValid('taxAmount') }, 'Label__Text')}>
                                        Tax Amount
                                    </label>
                                    <InputText
                                        id="taxAmount"
                                        placeholder="Enter Tax Amount"
                                        name="taxAmount"
                                        value={formik?.values?.taxAmount || ''}
                                        onChange={formik.handleChange}
                                        disabled={true}
                                        className={classNames({ 'p-invalid': isFormFieldValid('taxAmount') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('taxAmount')}
                                </div>
                            </div>
                        )}
                        {formik.values.taxRateType === 'F' && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="taxRate" className={classNames({ 'p-error': isFormFieldValid('taxRate') }, 'Label__Text')}>
                                            Tax Amount
                                        </label>
                                        <InputText
                                            id="taxRate"
                                            placeholder="Enter Tax Amount"
                                            name="taxRate"
                                            disabled={true}
                                            value={formik?.values?.taxRate || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('taxRate') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('taxRate')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="isActiveAmount" className={classNames({ 'p-error': isFormFieldValid('isActiveAmount') }, 'Label__Text')}>
                                            <Checkbox disabled={true} className="mr-2 mb-2" inputId="isActiveAmount" name="isActiveAmount" value={isActiveAmountData} checked={isActiveAmountData} onChange={formik.handleChange} />
                                            Threshold
                                        </label>

                                        {getFormErrorMessage('thresholdAmount')}
                                    </div>
                                    <InputText
                                        id="thresholdAmount"
                                        name="thresholdAmount"
                                        value={formik?.values?.thresholdAmount || ''}
                                        onChange={formik.handleChange}
                                        disabled={true}
                                        className={classNames({ 'p-invalid': isFormFieldValid('thresholdAmount') }, 'Input__Round')}
                                    />
                                </div>
                            </>
                        )}
                        {formik.values.taxRateType === 'P' && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="taxPercentage" className={classNames({ 'p-error': isFormFieldValid('taxPercentage') }, 'Label__Text')}>
                                            Percentage(%)
                                        </label>
                                        <InputText
                                            id="taxPercentage"
                                            placeholder="Enter taxPercentage"
                                            name="taxPercentage"
                                            disabled={true}
                                            value={formik?.values?.taxPercentage || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('taxPercentage') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('taxPercentage')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="isActiveAmount" className={classNames({ 'p-error': isFormFieldValid('isActiveAmount') }, 'Label__Text')}>
                                            <Checkbox disabled={true} className="mr-2 mb-2" inputId="isActiveAmount" name="isActiveAmount" value={isActiveAmountData || ''} checked={isActiveAmountData} onChange={formik.handleChange} />
                                            Threshold
                                        </label>

                                        {getFormErrorMessage('isActiveAmount')}
                                    </div>
                                    <InputText
                                        id="thresholdAmount"
                                        name="thresholdAmount"
                                        value={formik?.values?.thresholdAmount || ''}
                                        onChange={formik.handleChange}
                                        disabled={true}
                                        className={classNames({ 'p-invalid': isFormFieldValid('thresholdAmount') }, 'Input__Round')}
                                    />
                                </div>
                            </>
                        )}
                        {formik.values.taxRateType === 'S' && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="slabType" className={classNames({ 'p-error': isFormFieldValid('slabType') }, 'Label__Text')}>
                                            Slab Type
                                        </label>
                                        <Dropdown
                                            id="slabType"
                                            placeholder="Select Calculation Method"
                                            options={slabTypeJson}
                                            optionLabel="label"
                                            name="slabType"
                                            optionValue="value"
                                            value={formik.values.slabType || ''}
                                            disabled={true}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('slabType') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('slabType')}
                                    </div>
                                </div>
                                {formik.values.slabType === 'F' ? (
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="taxSlabAmount" className={classNames({ 'p-error': isFormFieldValid('taxSlabAmount') }, 'Label__Text')}>
                                                Tax Amount
                                            </label>
                                            <InputText
                                                disabled={true}
                                                id="taxSlabAmount"
                                                placeholder="Enter Tax Amount"
                                                name="taxSlabAmount"
                                                value={formik?.values?.taxSlabAmount || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('taxSlabAmount') }, 'Input__Round')}
                                            />
                                            {getFormErrorMessage('taxSlabAmount')}
                                        </div>
                                    </div>
                                ) : formik.values.slabType === 'P' ? (
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="taxSlabPercentage" className={classNames({ 'p-error': isFormFieldValid('taxSlabPercentage') }, 'Label__Text')}>
                                                Percentage on Slab
                                            </label>
                                            <InputText
                                                disabled={true}
                                                id="taxSlabPercentage"
                                                placeholder="Enter Tax Percentage"
                                                name="taxSlabPercentage"
                                                value={formik?.values?.taxSlabPercentage || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('taxSlabPercentage') }, 'Input__Round')}
                                            />
                                            {getFormErrorMessage('taxSlabPercentage')}
                                        </div>
                                    </div>
                                ) : null}
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="fromAmount" className={classNames({ 'p-error': isFormFieldValid('fromAmount') }, 'Label__Text')}>
                                            Start Slab
                                        </label>
                                        <InputText
                                            id="fromAmount"
                                            placeholder="Enter Start Slab"
                                            name="fromAmount"
                                            value={formik?.values?.fromAmount || ''}
                                            onChange={formik.handleChange}
                                            disabled={true}
                                            className={classNames({ 'p-invalid': isFormFieldValid('fromAmount') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('fromAmount')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="toAmount" className={classNames({ 'p-error': isFormFieldValid('toAmount') }, 'Label__Text')}>
                                            End Slab
                                        </label>
                                        <InputText
                                            id="toAmount"
                                            placeholder="Enter End Slab"
                                            name="toAmount"
                                            value={formik?.values?.toAmount || ''}
                                            onChange={formik.handleChange}
                                            disabled={true}
                                            className={classNames({ 'p-invalid': isFormFieldValid('toAmount') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('toAmount')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="slabthresholdAmount" className={classNames({ 'p-error': isFormFieldValid('slabthresholdAmount') }, 'Label__Text')}>
                                            <Checkbox disabled={true} className="mr-2 mb-2" inputId="isActiveSlabAmount" name="isActiveSlabAmount" value={isActiveAmountSlabData} checked={isActiveAmountSlabData} onChange={formik.handleChange} />
                                            Threshold
                                        </label>

                                        {getFormErrorMessage('slabthresholdAmount')}
                                    </div>
                                    <InputText
                                        id="slabthresholdAmount"
                                        name="slabthresholdAmount"
                                        value={formik?.values?.slabthresholdAmount || ''}
                                        onChange={formik.handleChange}
                                        disabled={true}
                                        className={classNames({ 'p-invalid': isFormFieldValid('slabthresholdAmount') }, 'Input__Round')}
                                    />
                                </div>
                            </>
                        )}
                        {formik.values.taxRateType === 'T' && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="slabType" className={classNames({ 'p-error': isFormFieldValid('slabType') }, 'Label__Text')}>
                                            Slab Type
                                        </label>
                                        <Dropdown
                                            id="slabType"
                                            placeholder="Select Calculation Method"
                                            options={slabTypeJson}
                                            optionLabel="label"
                                            name="slabType"
                                            optionValue="value"
                                            value={formik.values.slabType || ''}
                                            onChange={formik.handleChange}
                                            disabled={true}
                                            className={classNames({ 'p-invalid': isFormFieldValid('slabType') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('slabType')}
                                    </div>
                                </div>
                                {formik.values.slabType === 'F' ? (
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="taxSlabAmount" className={classNames({ 'p-error': isFormFieldValid('taxSlabAmount') }, 'Label__Text')}>
                                                Tax Amount
                                            </label>
                                            <InputText
                                                disabled={true}
                                                id="taxSlabAmount"
                                                placeholder="Enter Tax Amount"
                                                name="taxSlabAmount"
                                                value={formik?.values?.taxSlabAmount || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('taxSlabAmount') }, 'Input__Round')}
                                            />
                                            {getFormErrorMessage('taxSlabAmount')}
                                        </div>
                                    </div>
                                ) : formik.values.slabType === 'P' ? (
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="taxSlabPercentage" className={classNames({ 'p-error': isFormFieldValid('taxSlabPercentage') }, 'Label__Text')}>
                                                Percentage on Slab
                                            </label>
                                            <InputText
                                                disabled={true}
                                                id="taxSlabPercentage"
                                                placeholder="Enter Tax Percentage"
                                                name="taxSlabPercentage"
                                                value={formik?.values?.taxSlabPercentage || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('taxSlabPercentage') }, 'Input__Round')}
                                            />
                                            {getFormErrorMessage('taxSlabPercentage')}
                                        </div>
                                    </div>
                                ) : null}
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="fromAmount" className={classNames({ 'p-error': isFormFieldValid('fromAmount') }, 'Label__Text')}>
                                            Start Slab
                                        </label>
                                        <InputText
                                            id="fromAmount"
                                            placeholder="Enter Start Slab"
                                            name="fromAmount"
                                            value={formik?.values?.fromAmount || ''}
                                            onChange={formik.handleChange}
                                            disabled={true}
                                            className={classNames({ 'p-invalid': isFormFieldValid('fromAmount') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('fromAmount')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="toAmount" className={classNames({ 'p-error': isFormFieldValid('toAmount') }, 'Label__Text')}>
                                            End Slab
                                        </label>
                                        <InputText
                                            id="toAmount"
                                            placeholder="Enter End Slab"
                                            name="toAmount"
                                            value={formik?.values?.toAmount || ''}
                                            onChange={formik.handleChange}
                                            disabled={true}
                                            className={classNames({ 'p-invalid': isFormFieldValid('toAmount') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('toAmount')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="slabthresholdAmount" className={classNames({ 'p-error': isFormFieldValid('slabthresholdAmount') }, 'Label__Text')}>
                                            <Checkbox disabled={true} className="mr-2 mb-2" inputId="isActiveSlabAmount" name="isActiveSlabAmount" value={isActiveAmountSlabData} checked={isActiveAmountSlabData} onChange={formik.handleChange} />
                                            Threshold
                                        </label>

                                        {getFormErrorMessage('slabthresholdAmount')}
                                    </div>
                                    <InputText
                                        id="slabthresholdAmount"
                                        name="slabthresholdAmount"
                                        value={formik?.values?.slabthresholdAmount || ''}
                                        onChange={formik.handleChange}
                                        disabled={true}
                                        className={classNames({ 'p-invalid': isFormFieldValid('slabthresholdAmount') }, 'Input__Round')}
                                    />
                                </div>
                            </>
                        )}
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="effectiveFrom" className={classNames({ 'p-error': isFormFieldValid('effectiveFrom') }, 'Label__Text')}>
                                    Effective From
                                </label>
                                <InputText
                                    id="effectiveFrom"
                                    type="date"
                                    name="effectiveFrom"
                                    value={formik?.values?.effectiveFrom || ''}
                                    onChange={formik.handleChange}
                                    disabled={true}
                                    className={classNames({ 'p-invalid': isFormFieldValid('effectiveFrom') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('effectiveFrom')}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default TaxProfileOldNewValuesView;

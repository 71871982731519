import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import ViewBannerChecker from './ViewBannerChecker';
import ViewRedirectSplashChecker from '../../RedirectSplash/ViewRedirectSplashChecker';
import ViewInputSplashChecker from '../../InputSplash/ViewInputSplashChecker';
import ViewInformationSplashChecker from '../../InformationSplash/ViewInformationSplashChecker';
import ViewPromotionChecker from '../../Promotions/ViewPromotionChecker';
import ViewTickerChecker from '../../Ticker/ViewTickerChecker';

const ViewBannerCheckerMain = () => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const [bannerType, setBannerType] = useState();

    const getMainCategoryData = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/getbannerbyid/${refTableId}`);
        const keyData = res?.payLoad;
        if (keyData) {
            setBannerType(keyData?.cmsBannerTypeId || '');
        }
    };

    const getMcRequestDataById = async () => {
        const res = await handleGetRequest(`/cms/v1/cms/getmcrequestbyid/${mcRequestId}`);
        const unParsedkeyData = res?.payLoad?.updateJson;
        const keyData = JSON.parse(unParsedkeyData);

        if (keyData) {
            setBannerType(keyData?.cmsBannerTypeId || '');
        }
    };

    useEffect(() => {
        if (requestType === 'I') {
            getMainCategoryData();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, [requestType]);

    return (
        <>
            {(() => {
                switch (bannerType) {
                    case 1:
                        return <ViewBannerChecker />;
                    case 2:
                        return <ViewRedirectSplashChecker />;
                    case 3:
                        return <ViewInputSplashChecker />;
                    case 4:
                        return <ViewInformationSplashChecker />;
                    case 5:
                        return <ViewPromotionChecker />;
                    case 6:
                        return <ViewTickerChecker />;
                    default:
                        return null;
                }
            })()}
        </>
    );
};

export default ViewBannerCheckerMain;

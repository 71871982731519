import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useRef } from 'react';

import { MultiSelect } from 'primereact/multiselect';
import SecuredFileChange from '../../../components/SecuredFileChange';
import MobileView from './mobileView';
import ColorPicker from 'react-pick-color';

function AddRedirectSplash() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [buttonTypeLov, setButtonTypeLov] = useState([]);
    const [redirectionLov, setRedirectionLov] = useState([]);
    const [visibleData, setVisibleData] = useState([]);
    const [mainData, setMainData] = useState([]);
    const [subData, setSubData] = useState([]);
    const [secSubData, setSecSubData] = useState([]);
    const [segmentsData, setSegmentsData] = useState([]);
    const [prodLkp, seTProdLkp] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const redirectionRef = useRef(null);
    const [targetAud, setTargetAud] = useState([]);
    const [iconLink, setIconLink] = useState('');
    // const { bannerType } = useParams();

    const [categoryIconError, setCategoryIconError] = useState('');
    const [rankingData, setRankingData] = useState([]);
    // const [showColorPicker, setShowColorPicker] = useState({ textColor: false, bgColor: false });
    const [showColorPicker, setShowColorPicker] = useState({
        textColor: {
            0: false
        },
        bgColor: {
            0: false
        }
    });

    const location = useLocation();
    const { bannerId } = location.state || {};

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const noofbtns = [
        { name: '1', lovId: 1 },
        { name: ' 2', lovId: 2 }
        // { name: ' 3', lovId: 3 },
        // { name: ' 4', lovId: 4 },
        // { name: ' 5', lovId: 5 }
    ];

    const frequencyData = [
        { lovId: 'A', name: 'All' },
        { lovId: 'C', name: 'Custom' }
    ];

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z]+( [a-zA-Z]+)*$/, 'Only alphabets are allowed')
            .required('This field is required'),

        // addButton: Yup.string(),
        // tblCmsBannerButtons: Yup.array().when('addButton', {
        //     is: 'Y',
        //     then: Yup.array()
        //         .of(
        //             Yup.object().shape({
        //                 cmsMediaButtonTypeId: Yup.string().required('Type Of Button is required'),
        //                 buttonText: Yup.string().required('Action Button Text is required'),
        //                 buttonTextColor: Yup.string().required('Action Button Text Color is required'),
        //                 buttonBgColor: Yup.string().required('Action Button Background Color is required'),
        //                 cmsRedirectionId: Yup.string().required('Redirection is required'),
        //             })
        //         )
        //         .required('At least one button is required'),
        //     otherwise: Yup.array()
        // }),
        addButton: Yup.string(),
        // noOfButtons: Yup.number().required('Number of Buttons is required'),
        tblCmsBannerButtons: Yup.array().when('addButton', {
            is: 'Y',
            then: Yup.array()
                .of(
                    Yup.object().shape({
                        cmsMediaButtonTypeId: Yup.string().required('Type Of Button is required'),
                        buttonText: Yup.string().required('Action Button Text is required'),
                        buttonTextColor: Yup.string().required('Action Button Text Color is required'),
                        buttonBgColor: Yup.string().required('Action Button Background Color is required'),
                        cmsRedirectionId: Yup.string().required('Redirection is required')
                    })
                )
                .min(1, 'At least one button is required')
                .required('At least one button is required'),
            otherwise: Yup.array()
        }),
        text: Yup.string().required('This field is required'),
        tblCmsBannerAccountLevels: Yup.array().required('This Field is required').min(1, 'This Field is required'),

        image: Yup.string().required('This field is required'),

        cmsVisibleId: Yup.string().required('This field is required'),
        tblCmsBannerSegments: Yup.array().required('This Field is required').min(1, 'This Field is required'),

        frequency: Yup.string().required('This field is required'),
        startDate: Yup.date().when('frequency', {
            is: 'C',
            then: Yup.date().required('Start Date is required').nullable(),
            otherwise: Yup.date().nullable()
        }),
        endDate: Yup.date().when(['startDate', 'frequency'], {
            is: (startDate, frequency) => frequency == 'C',
            then: Yup.date().required('End Date is required').min(Yup.ref('startDate'), 'End Date must be greater than Start Date').nullable(),
            otherwise: Yup.date().nullable()
        })
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cmsBannerId: '',
            cmsBannerTypeId: bannerId,
            name: '',
            cmsVisibleId: '',
            redirection: 'Y',
            cmsProductId: '',
            cmsMainCategoryId: '',
            cmsSubCategoryId: '',
            cmsSecondSubCategoryId: '',
            isActive: '',
            text: '',
            image: '',
            addButton: 'N',
            noOfButtons: '',
            ranking: '',
            tblCmsBannerAccountLevels: [],
            tblCmsBannerButtons: [
                {
                    cmsBannerButtonId: '',
                    cmsMediaButtonTypeId: '',
                    buttonText: '',
                    buttonTextColor: '',
                    buttonBgColor: '',
                    cmsRedirectionId: ''
                }
            ],
            frequency: '',
            startDate: '',
            endDate: '',
            isHide: 'Y',
            tblCmsBannerSegments: []
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            if (data?.addButton === 'N') {
                data.tblCmsBannerButtons = [
                    {
                        cmsBannerButtonId: '',
                        cmsMediaButtonTypeId: '',
                        buttonText: '',
                        buttonTextColor: '',
                        buttonBgColor: '',
                        cmsRedirectionId: ''
                    }
                ];
                data.noOfButtons = 0;
            }
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const formData = new FormData();
            formData.append('request', JSON.stringify(newData));
            if (selectedFile) {
                formData.append('file', selectedFile);
            }
            await dispatch(handlePostRequest(formData, '/cms/v1/cms/savebanner', true, false, 'banners'));
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const getVisibleData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_VISIBLE');
        setVisibleData(resp?.payLoad);
    };

    const getButtonTypeLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_MEDIA_BUTTON_TYPE');
        setButtonTypeLov(res?.payLoad);
    };
    const getRedirectionLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_REDIRECTION');
        setRedirectionLov(res?.payLoad);
    };

    const geTProdLkp = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_PRODUCT');
        seTProdLkp(res?.payLoad);
    };
    const getSegmentsData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getsegments/LKP_SEGMENT:*');
        const updated = res?.payLoad?.map((item) => ({
            ...item,
            segmentId: item.lovId
        }));
        setSegmentsData(updated);
    };
    const getTargetAud = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getaccounttypes/LKP_ACCOUNT_LEVEL:*');
        setTargetAud(res?.payLoad);
    };

    const getCategoryDataLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcategorydatalov');
        setMainData(res?.payLoad);
    };

    const getSubCategoryDataLov = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getsubcategorydatalov/${formik?.values?.cmsMainCategoryId}`);
        setSubData(res?.payLoad);
    };

    const getSecSubCategoryDataLov = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getsecondsubcategorydatalov/${formik.values.cmsMainCategoryId}/${formik.values.cmsSubCategoryId}`);
        setSecSubData(res?.payLoad);
    };

    const getRankingData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_RANKING');
        setRankingData(res?.payLoad);
    };

    useEffect(() => {
        getRankingData();

        getCategoryDataLov();
        if (formik?.values?.cmsMainCategoryId != '') {
            getSubCategoryDataLov();
        }
        if (formik?.values?.cmsMainCategoryId != '' && formik?.values?.cmsSubCategoryId != '') {
            getSecSubCategoryDataLov();
        }
        getTargetAud();
        getSegmentsData();
        geTProdLkp();
        getRedirectionLov();
        getButtonTypeLov();
        getVisibleData();
    }, [formik.values.cmsMainCategoryId, formik.values.cmsSubCategoryId]);

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());

    const handleRedirectionIcon = () => {
        redirectionRef.current.click();
    };

    // const handleRedirectionChange = (event) => {
    //     const selectedFile = event.target.files[0];

    //     if (!selectedFile) {
    //         formik.setFieldError('files', 'Please choose a file.');
    //         setSelectedFile('');
    //     }
    //     if (selectedFile) {
    //         setSelectedFile(selectedFile);
    //         setRedirectionIconFileName(selectedFile.name);
    //     }
    // };
    const handleRedirectionChange = (event) => {
        setIconLink('');
        setCategoryIconError('');
        formik.setFieldValue('image', '');
        setSelectedFile(null);
        const selectedFile = event.target.files[0];
        const fileTypes = ['image'];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setCategoryIconError(error);
            setSelectedFile(null);
        } else if (newFile) {
            setSelectedFile(selectedFile);
            formik.setFieldValue('image', selectedFile.name);
            setIconLink(URL.createObjectURL(selectedFile));

            // setSecondSubCategoryIconFileName(selectedFile?.name);
        }
    };

    const handleAddButton = () => {
        const newButtons = [
            ...formik.values.tblCmsBannerButtons,
            {
                cmsBannerButtonId: '',
                cmsMediaButtonTypeId: '',
                buttonText: '',
                buttonTextColor: '',
                buttonBgColor: '',
                cmsRedirectionId: ''
            }
        ];

        formik.setValues({
            ...formik.values,
            tblCmsBannerButtons: newButtons,
            noOfButtons: newButtons.length
        });
        // formik.setFieldValue('cmsBannerButtonId', '');
        // formik.setFieldValue('cmsMediaButtonTypeId', '');
        // formik.setFieldValue('buttonText', '');
        // formik.setFieldValue('buttonTextColor', '');
        // formik.setFieldValue('buttonBgColor', '');
        // formik.setFieldValue('cmsRedirectionId', '');
    };

    const handleRemoveButton = (index) => {
        const updatedDetails = [...formik.values.tblCmsBannerButtons];
        if (formik.values.noOfButtons.length === 1) {
            return;
        }
        updatedDetails.splice(index, 1);
        formik.setValues({
            ...formik.values,
            tblCmsBannerButtons: updatedDetails,
            noOfButtons: updatedDetails.length
        });
    };
    const getContrastColor = (hexColor) => {
        const r = parseInt(hexColor.substr(1, 2), 16);
        const g = parseInt(hexColor.substr(3, 2), 16);
        const b = parseInt(hexColor.substr(5, 2), 16);
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    };
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const handleFrequencyChange = (e) => {
        const value = e.value;
        formik.setFieldValue('frequency', value);
        if (value === 'A') {
            formik.setFieldValue('startDate', '');
            formik.setFieldValue('endDate', '');
        }
    };
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="Form__Header Full__Width">
                        <h2>ADD NEW REDIRECT SPLASH </h2>
                    </div>
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-fluid p-formgrid grid mb-5 col-12 md:col-9">
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                        Name <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="name"
                                        placeholder="Enter name"
                                        name="name"
                                        value={formik?.values?.name || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('name')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsVisibleId" className={classNames({ 'p-error': isFormFieldValid('cmsVisibleId') }, 'Label__Text')}>
                                        Visible On<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="cmsVisibleId"
                                        placeholder="Select Visibility"
                                        options={visibleData}
                                        optionLabel="name"
                                        name="cmsVisibleId"
                                        optionValue="lovId"
                                        value={formik.values.cmsVisibleId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsVisibleId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('cmsVisibleId')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="redirection" className={classNames({ 'p-error': isFormFieldValid('redirection') }, 'Label__Text')}>
                                        Redirection
                                    </label>
                                    <div className="flex">
                                        <div className="mt-2 mr-5">
                                            <RadioButton inputId="yes" name="redirection" value="Y" onChange={formik.handleChange} checked={formik.values.redirection === 'Y'} />
                                            <label htmlFor="yes" className={classNames({ 'p-error': isFormFieldValid('yes') }, 'Label__Text')}>
                                                Yes
                                            </label>
                                        </div>
                                        <div className="mt-2">
                                            <RadioButton inputId="no" name="redirection" value="N" onChange={formik.handleChange} checked={formik.values.redirection === 'N'} />
                                            <label htmlFor="no" className={classNames({ 'p-error': isFormFieldValid('no') }, 'Label__Text')}>
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsProductId" className={classNames({ 'p-error': isFormFieldValid('cmsProductId') }, 'Label__Text')}>
                                        Product
                                    </label>
                                    <Dropdown
                                        id="cmsProductId"
                                        placeholder="Select Product"
                                        options={prodLkp}
                                        optionLabel="name"
                                        name="cmsProductId"
                                        optionValue="lovId"
                                        value={formik.values.cmsProductId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsProductId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('cmsProductId')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsMainCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsMainCategoryId') }, 'Label__Text')}>
                                        Main Category
                                    </label>
                                    <Dropdown
                                        id="cmsMainCategoryId"
                                        placeholder="Select Category"
                                        options={mainData}
                                        optionLabel="name"
                                        name="cmsMainCategoryId"
                                        optionValue="lovId"
                                        value={formik.values.cmsMainCategoryId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsMainCategoryId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('cmsMainCategoryId')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsSubCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsSubCategoryId') }, 'Label__Text')}>
                                        Sub Category
                                    </label>
                                    <Dropdown
                                        id="cmsSubCategoryId"
                                        placeholder="Select Sub Category"
                                        options={subData}
                                        optionLabel="name"
                                        name="cmsSubCategoryId"
                                        optionValue="lovId"
                                        value={formik.values.cmsSubCategoryId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsSubCategoryId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('cmsSubCategoryId')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsSecondSubCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsSecondSubCategoryId') }, 'Label__Text')}>
                                        2nd Sub Category
                                    </label>
                                    <Dropdown
                                        id="cmsSecondSubCategoryId"
                                        placeholder="Select 2nd Sub Category"
                                        options={secSubData}
                                        optionLabel="name"
                                        name="cmsSecondSubCategoryId"
                                        optionValue="lovId"
                                        value={formik.values.cmsSecondSubCategoryId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsSecondSubCategoryId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('cmsSecondSubCategoryId')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="text" className={classNames({ 'p-error': isFormFieldValid('text') }, 'Label__Text')}>
                                        Add Text <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="text"
                                        placeholder="Add Text"
                                        name="text"
                                        value={formik?.values?.text || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('text') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('text')}
                                </div>
                            </div>
                            {/* <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <div className="mb-2">
                                        <label className="Label__Text">Theme Icon</label>
                                        <div className="file-upload-container w-100 Input__Round_Upload flex file-input-field-upload flex justify-content-between" onClick={handleRedirectionIcon}>
                                            <input type="file" style={{ display: 'none' }} ref={redirectionRef} onChange={handleRedirectionChange} accept="image/*" />
                                            {redirectionIconFileName ? redirectionIconFileName : 'Choose a file...'}
                                            <button label="Choose File" className="Btn__Add__Upload border-round-md" type="button" style={{ width: 'auto' }}>
                                                Choose File
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <div className="mb-2">
                                        <label className={classNames({ 'p-error': isFormFieldValid('image') }, 'Label__Text')}>
                                            Upload Image
                                            <span className="Label__Required">*</span>
                                        </label>
                                        <div className="file-input-container w-100" onClick={handleRedirectionIcon}>
                                            <input type="file" accept="image/*" style={{ display: 'none' }} ref={redirectionRef} onChange={handleRedirectionChange} />
                                            <input
                                                type="text"
                                                className={classNames({ 'p-inputtext p-component p-invalid': isFormFieldValid('image') }, 'file-input-field Input__Round')}
                                                placeholder="Choose a file..."
                                                value={formik?.values?.image}
                                                readOnly
                                            />
                                            <span className="file-upload-icon">
                                                <i className="pi pi-paperclip" />
                                            </span>
                                        </div>
                                        {categoryIconError ? <small className="p-error">{categoryIconError}</small> : getFormErrorMessage('image')}
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 add_button_fournine">
                                <div className="p-field">
                                    <label htmlFor="addButton" className={classNames({ 'p-error': isFormFieldValid('channelName') }, 'Label__Text')}>
                                        Add Button<span className="Label__Required">*</span>
                                    </label>
                                    <div className="flex">
                                        <div className="mt-2 mr-5">
                                            <RadioButton
                                                inputId="Yes"
                                                name="addButton"
                                                value="Y"
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                    if (e.value === 'Y') {
                                                        formik.setFieldValue('noOfButtons', 1);
                                                    }
                                                }}
                                                checked={formik.values.addButton === 'Y'}
                                            />
                                            <label htmlFor="yes" className={classNames({ 'p-error': isFormFieldValid('Yes') }, 'Label__Text')}>
                                                Yes
                                            </label>
                                        </div>
                                        <div className="mt-2">
                                            <RadioButton
                                                inputId="no"
                                                name="addButton"
                                                value="N"
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                    if (e.value === 'N') {
                                                        formik.setFieldValue('noOfButtons', 0);
                                                    }
                                                }}
                                                checked={formik.values.addButton === 'N'}
                                            />
                                            <label htmlFor="no" className={classNames({ 'p-error': isFormFieldValid('no') }, 'Label__Text')}>
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="noOfButtons" className={classNames({ 'p-error': isFormFieldValid('noOfButtons') }, 'Label__Text')}>
                                        Number Of Buttons
                                    </label>
                                    <Dropdown
                                        id="noOfButtons"
                                        placeholder={formik.values.noOfButtons !== 0 ? formik.values.noOfButtons : 'Select Number Of Buttons'}
                                        options={noofbtns}
                                        optionLabel="name"
                                        name="noOfButtons"
                                        optionValue="lovId"
                                        value={formik.values.noOfButtons || ''}
                                        onChange={(e) => {
                                            formik.setFieldValue('noOfButtons', e.value);

                                            const newButtons = Array.from({ length: e.value }, () => ({
                                                cmsMediaButtonTypeId: '',
                                                buttonText: '',
                                                buttonTextColor: '',
                                                buttonBgColor: '',
                                                cmsRedirectionId: ''
                                            }));

                                            formik.setFieldValue('tblCmsBannerButtons', newButtons);

                                            if (formik.values.addButton === 'Y') {
                                                formik.setFieldTouched('tblCmsBannerButtons', true, true);
                                            }
                                        }}
                                        disabled={formik.values.addButton === 'N'}
                                        className={classNames({ 'p-invalid': isFormFieldValid('noOfButtons') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('noOfButtons')}
                                </div>
                            </div>

                            {formik.values.addButton === 'Y' &&
                                Array.from({ length: formik.values.noOfButtons }, (_, index) => (
                                    <div className="card Card__Bg__Change col-12 md:col-12 px-3" key={index}>
                                        <h6 className="secondary-color pt-1">
                                            <b>Button {index + 1}</b>
                                        </h6>
                                        <div className="flex">
                                            <div className="p-fluid p-formgrid grid mb-5 col-10 md:col-10">
                                                <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                                    <div className="p-field">
                                                        <label htmlFor={`tblCmsBannerButtons.${index}.cmsMediaButtonTypeId`} className={classNames({ 'p-error': isFormFieldValid(`tblCmsBannerButtons.${index}.cmsMediaButtonTypeId`) }, 'Label__Text')}>
                                                            Type Of Button <span className="Label__Required">*</span>
                                                        </label>
                                                        <Dropdown
                                                            id={`cmsMediaButtonTypeId_${index}.cmsMediaButtonTypeId`}
                                                            placeholder="Select Type Of Button"
                                                            name={`tblCmsBannerButtons.${index}.cmsMediaButtonTypeId`}
                                                            optionLabel="name"
                                                            options={buttonTypeLov}
                                                            optionValue="lovId"
                                                            value={formik.values.tblCmsBannerButtons[index]?.cmsMediaButtonTypeId || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames(
                                                                {
                                                                    'p-invalid': formik.touched.tblCmsBannerButtons?.[index]?.cmsMediaButtonTypeId && formik.errors.tblCmsBannerButtons?.[index]?.cmsMediaButtonTypeId
                                                                },
                                                                'Dropdown__Round'
                                                            )}
                                                        />
                                                        {formik.touched.tblCmsBannerButtons?.[index]?.cmsMediaButtonTypeId && formik.errors.tblCmsBannerButtons?.[index]?.cmsMediaButtonTypeId && (
                                                            <small className="p-error">{formik.errors.tblCmsBannerButtons?.[index]?.cmsMediaButtonTypeId}</small>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                                    <div className="p-field">
                                                        <label htmlFor={`tblCmsBannerButtons.${index}.buttonText`} className={classNames({ 'p-error': isFormFieldValid(`tblCmsBannerButtons.${index}.buttonText`) }, 'Label__Text')}>
                                                            Action Button Text<span className="Label__Required">*</span>
                                                        </label>
                                                        <InputText
                                                            maxLength={100}
                                                            id={`tblCmsBannerButtons.${index}.buttonText`}
                                                            placeholder="Enter Action Button Text"
                                                            name={`tblCmsBannerButtons.${index}.buttonText`}
                                                            value={formik.values.tblCmsBannerButtons[index]?.buttonText || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames(
                                                                {
                                                                    'p-invalid': formik.touched.tblCmsBannerButtons?.[index]?.buttonText && formik.errors.tblCmsBannerButtons?.[index]?.buttonText
                                                                },
                                                                'Input__Round'
                                                            )}
                                                        />
                                                        {formik.touched.tblCmsBannerButtons?.[index]?.buttonText && formik.errors.tblCmsBannerButtons?.[index]?.buttonText && (
                                                            <small className="p-error">{formik.errors.tblCmsBannerButtons?.[index]?.buttonText}</small>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                                    <div className="p-field">
                                                        <label htmlFor={`tblCmsBannerButtons.${index}.buttonTextColor`} className={classNames({ 'p-error': isFormFieldValid(`tblCmsBannerButtons.${index}.buttonTextColor`) }, 'Label__Text')}>
                                                            Action Button Text Color <span className="Label__Required">*</span>
                                                        </label>

                                                        <InputText
                                                            value={formik?.values?.tblCmsBannerButtons[index]?.buttonTextColor || '#FFFFFF'}
                                                            onChange={formik.handleChange}
                                                            onClick={() =>
                                                                setShowColorPicker({
                                                                    textColor: {
                                                                        [index]: true
                                                                    },
                                                                    bgColor: {
                                                                        [index]: false
                                                                    }
                                                                })
                                                            }
                                                            readOnly
                                                            className={classNames({ 'p-invalid': isFormFieldValid('buttonTextColor') }, 'Input__Round')}
                                                            style={{
                                                                backgroundColor: formik?.values?.tblCmsBannerButtons[index]?.buttonTextColor || '#FFFFFF',
                                                                color: getContrastColor(formik?.values?.tblCmsBannerButtons[index]?.buttonTextColor || '#FFFFFF')
                                                            }}
                                                        />
                                                        {getFormErrorMessage('buttonTextColor')}
                                                        {showColorPicker?.textColor[index] && (
                                                            <div className="colorPickerForInput">
                                                                <i
                                                                    className="pi pi-times closingIcon"
                                                                    onClick={() =>
                                                                        setShowColorPicker({
                                                                            textColor: {
                                                                                [index]: false
                                                                            },
                                                                            bgColor: {
                                                                                [index]: false
                                                                            }
                                                                        })
                                                                    }
                                                                />
                                                                <ColorPicker
                                                                    color={formik?.values?.tblCmsBannerButtons[index]?.buttonTextColor || '#FFFFFF'}
                                                                    onChange={(e) => formik.setFieldValue(`tblCmsBannerButtons[${[index]}].[buttonTextColor]`, e.hex)}
                                                                />
                                                            </div>
                                                        )}

                                                        {formik.touched.tblCmsBannerButtons?.[index]?.buttonTextColor && formik.errors.tblCmsBannerButtons?.[index]?.buttonTextColor && (
                                                            <small className="p-error">{formik.errors.tblCmsBannerButtons?.[index]?.buttonTextColor}</small>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                                    <div className="p-field">
                                                        <label htmlFor={`tblCmsBannerButtons.${index}.buttonBgColor`} className={classNames({ 'p-error': isFormFieldValid(`tblCmsBannerButtons.${index}.buttonBgColor`) }, 'Label__Text')}>
                                                            Action Button Background Color <span className="Label__Required">*</span>
                                                        </label>

                                                        <InputText
                                                            value={formik?.values?.tblCmsBannerButtons[index]?.buttonBgColor || '#FFFFFF'}
                                                            onChange={formik.handleChange}
                                                            onClick={() =>
                                                                setShowColorPicker({
                                                                    textColor: {
                                                                        [index]: false
                                                                    },
                                                                    bgColor: {
                                                                        [index]: true
                                                                    }
                                                                })
                                                            }
                                                            readOnly
                                                            className={classNames({ 'p-invalid': isFormFieldValid('buttonBgColor') }, 'Input__Round')}
                                                            style={{
                                                                backgroundColor: formik?.values?.tblCmsBannerButtons[index]?.buttonBgColor || '#FFFFFF',
                                                                color: getContrastColor(formik?.values?.tblCmsBannerButtons[index]?.buttonBgColor || '#FFFFFF')
                                                            }}
                                                        />

                                                        {getFormErrorMessage('buttonBgColor')}
                                                        {showColorPicker?.bgColor[index] && (
                                                            <div className="colorPickerForInput">
                                                                <i
                                                                    className="pi pi-times closingIcon"
                                                                    onClick={() =>
                                                                        setShowColorPicker({
                                                                            textColor: {
                                                                                [index]: false
                                                                            },
                                                                            bgColor: {
                                                                                [index]: false
                                                                            }
                                                                        })
                                                                    }
                                                                />
                                                                <ColorPicker
                                                                    color={formik?.values?.tblCmsBannerButtons[index]?.buttonBgColor || '#FFFFFF'}
                                                                    onChange={(e) => formik.setFieldValue(`tblCmsBannerButtons[${[index]}].[buttonBgColor]`, e.hex)}
                                                                />
                                                            </div>
                                                        )}
                                                        {formik.touched.tblCmsBannerButtons?.[index]?.buttonBgColor && formik.errors.tblCmsBannerButtons?.[index]?.buttonBgColor && (
                                                            <small className="p-error">{formik.errors.tblCmsBannerButtons?.[index]?.buttonBgColor}</small>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                                    <div className="p-field">
                                                        <label htmlFor={`tblCmsBannerButtons.${index}.cmsRedirectionId`} className={classNames({ 'p-error': isFormFieldValid(`tblCmsBannerButtons.${index}.cmsRedirectionId`) }, 'Label__Text')}>
                                                            Redirection<span className="Label__Required">*</span>
                                                        </label>
                                                        <Dropdown
                                                            id={`tblCmsBannerButtons.${index}.cmsRedirectionId`}
                                                            placeholder="Select Redirection"
                                                            options={redirectionLov}
                                                            name={`tblCmsBannerButtons.${index}.cmsRedirectionId`}
                                                            optionLabel="name"
                                                            optionValue="lovId"
                                                            value={formik.values.tblCmsBannerButtons[index]?.cmsRedirectionId || ''}
                                                            onChange={formik.handleChange}
                                                            className={classNames(
                                                                {
                                                                    'p-invalid': formik.touched.tblCmsBannerButtons?.[index]?.cmsRedirectionId && formik.errors.tblCmsBannerButtons?.[index]?.cmsRedirectionId
                                                                },
                                                                'Dropdown__Round'
                                                            )}
                                                        />
                                                        {formik.touched.tblCmsBannerButtons?.[index]?.cmsRedirectionId && formik.errors.tblCmsBannerButtons?.[index]?.cmsRedirectionId && (
                                                            <small className="p-error">{formik.errors.tblCmsBannerButtons?.[index]?.cmsRedirectionId}</small>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2 md:col-2 flex flex-column align-items-center justify-content-center mb-3">
                                                <button type="button" className="addition_Button" onClick={() => handleAddButton(index)}>
                                                    +
                                                </button>
                                                <button type="button" className="reduction_Button" onClick={() => handleRemoveButton(index)}>
                                                    -
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="tblCmsBannerAccountLevels" className={classNames({ 'p-error': isFormFieldValid('tblCmsBannerAccountLevels') }, 'Label__Text')}>
                                        Target Audience (Account Level)<span className="Label__Required">*</span>
                                    </label>
                                    <MultiSelect
                                        id="tblCmsBannerAccountLevels"
                                        placeholder="Select Target Audience"
                                        options={targetAud}
                                        optionLabel="name"
                                        display="chip"
                                        name="tblCmsBannerAccountLevels"
                                        optionValue="lovId"
                                        value={formik?.values?.tblCmsBannerAccountLevels?.map((item) => item.accountLevelId || '')}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'tblCmsBannerAccountLevels',
                                                e?.value?.map((lovId) => ({
                                                    accountLevelId: lovId,
                                                    isActive: 'Y'
                                                }))
                                            );
                                        }}
                                        className={classNames({ 'p-invalid': isFormFieldValid('tblCmsBannerAccountLevels') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('tblCmsBannerAccountLevels')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="tblCmsBannerSegments" className={classNames({ 'p-error': isFormFieldValid('tblCmsBannerSegments') }, 'Label__Text')}>
                                        Segments<span className="Label__Required">*</span>
                                    </label>

                                    <MultiSelect
                                        id="tblCmsBannerSegments"
                                        placeholder="Select Segments"
                                        options={segmentsData}
                                        optionLabel="name"
                                        display="chip"
                                        name="tblCmsBannerSegments"
                                        optionValue="segmentId"
                                        value={formik?.values?.tblCmsBannerSegments.map((segment) => segment.segmentId || '')}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'tblCmsBannerSegments',
                                                e?.value?.map((lovId) => ({
                                                    segmentId: lovId
                                                }))
                                            );
                                        }}
                                        className={classNames({ 'p-invalid': isFormFieldValid('tblCmsBannerSegments') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('tblCmsBannerSegments')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="frequency" className={classNames({ 'p-error': isFormFieldValid('frequency') }, 'Label__Text')}>
                                        Frequency <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="frequency"
                                        placeholder="Select Visibility"
                                        options={frequencyData}
                                        optionLabel="name"
                                        name="frequency"
                                        optionValue="lovId"
                                        value={formik.values.frequency || ''}
                                        // onChange={formik.handleChange}
                                        onChange={handleFrequencyChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('frequency') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('frequency')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') }, 'Label__Text')}>
                                        Start Date & Time
                                    </label>
                                    <InputText
                                        id="startDate"
                                        type="date"
                                        name="startDate"
                                        value={formik?.values?.startDate}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('startDate') }, 'Input__Round')}
                                        disabled={formik.values.frequency == 'A'}
                                    />
                                    {getFormErrorMessage('startDate')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') }, 'Label__Text')}>
                                        End Date & Time
                                    </label>
                                    <InputText
                                        id="endDate"
                                        type="date"
                                        name="endDate"
                                        value={formik?.values?.endDate}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('endDate') }, 'Input__Round')}
                                        disabled={formik.values.frequency == 'A'}
                                    />

                                    {getFormErrorMessage('endDate')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 flex align-items-end justify-content-start">
                                <div className="field-checkbox flex mb-2 p-field-addbanner-width">
                                    <div className="mr-5">
                                        <Checkbox
                                            inputId="show"
                                            name="isHide"
                                            value="Y"
                                            checked={formik?.values?.isHide === 'Y'}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                formik.setFieldValue('isHide', e.checked ? 'Y' : 'N');
                                            }}
                                            style={{ marginTop: '10px' }}
                                        />
                                        <label htmlFor="show" className={classNames({ 'p-error': isFormFieldValid('enablePartialPayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                            Show
                                        </label>
                                    </div>
                                    <div>
                                        <Checkbox
                                            inputId="hide"
                                            name="isHide"
                                            value="N"
                                            checked={formik?.values?.isHide === 'N'}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                formik.setFieldValue('isHide', e.checked ? 'N' : 'Y');
                                            }}
                                            style={{ marginTop: '10px' }}
                                        />
                                        <label htmlFor="hide" className={classNames({ 'p-error': isFormFieldValid('enablePartialPayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                            Hide
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="ranking" className={classNames({ 'p-error': isFormFieldValid('ranking') }, 'Label__Text')}>
                                        Ranking
                                    </label>
                                    <Dropdown
                                        id="ranking"
                                        placeholder="Select Ranking"
                                        options={rankingData}
                                        optionLabel="name"
                                        name="ranking"
                                        optionValue="lovId"
                                        value={formik.values.ranking || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('ranking') }, 'Dropdown__Round')}
                                    />
                                    {getFormErrorMessage('ranking')}
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-3 pt-3 pb-3 col-4 flex align-items-start justify-content-center">
                            <MobileView icon={iconLink} title={formik?.values?.text} />
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Submit" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/banners')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddRedirectSplash;

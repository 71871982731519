import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useRef } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import ColorPicker from 'react-pick-color';
import SecuredFileChange from '../../../components/SecuredFileChange';

function AddAgentMateBank() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileError, setSelectedFileError] = useState(null);
    const [showColorPicker, setShowColorPicker] = useState({ textColor: false, bgColor: false });
    const bankImageRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        bankName: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .required('This field is required')
            .matches(/^[a-zA-Z0-9\s]*$/, 'Bank Name must be alpha numeric'),
        bankCode: Yup.string()
            .required('This field is required')
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[0-9]+$/, 'Bank code must contain only numeric values'),
        priority: Yup.string()
            .required('This field is required')
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[0-9]+$/, 'Priority must contain only numeric values'),
        displayNameEnglish: Yup.string()
            .required('This field is required')
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z ]+$/, 'Display Name English must contain only alphabetic characters'),
        displayNameUrdu: Yup.string()
            .required('This field is required')
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z0-9\s]*$/, 'Display Name Urdu must be alphanumeric'),
        bankImd: Yup.string()
            .required('This field is required')
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[0-9]+$/, 'Bank IMD must contain only numeric values'),
        description: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z0-9\s]*$/, 'Description must be alphanumeric'),
        bankIcon: Yup.string().required('This field is required'),
        cmsTagLabelId: Yup.string().when('addTag', {
            is: true,
            then: Yup.string()
                .required('This field is required')
                .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
                .matches(/^[a-zA-Z0-9\s]*$/, 'Tag label must be alphanumeric')
        }),
        cmsTagTextColorId: Yup.string().when('addTag', {
            is: true,
            then: Yup.string().required('This field is required')
        }),

        cmsTagBgColorId: Yup.string().when('addTag', {
            is: true,
            then: Yup.string().required('This field is required')
        })
    });

    let formData = new FormData();

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            bankName: '',
            bankCode: '',
            priority: '',
            displayNameEnglish: '',
            displayNameUrdu: '',
            bankImd: '',
            status: '',
            bankIcon: '',
            addTag: false,
            cmsTagLabelId: '',
            cmsTagTextColorId: '',
            cmsTagBgColorId: '',
            expiryDate: '',
            description: '',
            isHide: 'Y'
        },

        onSubmit: async (data) => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        bankName: data?.bankName,
                        bankCode: data?.bankCode,
                        priority: data?.priority,
                        displayNameEnglish: data?.displayNameEnglish,
                        displayNameUrdu: data?.displayNameUrdu,
                        bankImd: data?.bankImd,
                        description: data?.description,
                        cmsTagLabelId: data?.addTag ? data?.cmsTagLabelId : '',
                        cmsTagTextColorId: data?.addTag ? data?.cmsTagTextColorId : '',
                        cmsTagBgColorId: data?.addTag ? data?.cmsTagBgColorId : '',
                        expiryDate: data?.expiryDate,
                        isHide: data?.isHide
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            formData.append('request', JSON.stringify(newData));
            if (selectedFile) {
                formData.append('file', selectedFile);
            }

            await dispatch(handlePostRequest(formData, '/agentmatecms/v1/agentmatecms/savebank', true, false, '/agentmatebank'));
        }
    });

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const handledBankIcon = () => {
        bankImageRef.current.click();
    };

    const handleBankIconChange = (event) => {
        setSelectedFile(null);
        formik.setFieldValue('bankIcon', '');
        setSelectedFileError('');
        const selectedFile = event.target.files[0];
        const fileTypes = ['image'];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setSelectedFileError(error);
        } else if (newFile) {
            setSelectedFile(newFile);
            formik.setFieldValue('bankIcon', newFile?.name);
        }
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getContrastColor = (hexColor) => {
        const r = parseInt(hexColor.substr(1, 2), 16);
        const g = parseInt(hexColor.substr(3, 2), 16);
        const b = parseInt(hexColor.substr(5, 2), 16);
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="Form__Header Full__Width">
                        <h2>ADD NEW</h2>
                    </div>
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-fluid p-formgrid grid mb-5 col-12 md:col-12 ">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                <div className="p-field">
                                    <label htmlFor="bankName" className={classNames({ 'p-error': isFormFieldValid('bankName') }, 'Label__Text')}>
                                        Name <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="bankName"
                                        placeholder="Enter name"
                                        name="bankName"
                                        value={formik?.values?.bankName?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('bankName') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('bankName')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <div className="mb-2">
                                        <label className={classNames({ 'p-error': isFormFieldValid('bankIcon') }, 'Label__Text')}>
                                            Bank Icon <span className="Label__Required">*</span>
                                        </label>
                                        <div className="file-input-container w-100" onClick={handledBankIcon}>
                                            <input type="file" accept="image/*" style={{ display: 'none' }} ref={bankImageRef} onChange={handleBankIconChange} />
                                            <input
                                                type="text"
                                                className={classNames({ 'p-inputtext p-component p-invalid': isFormFieldValid('bankIcon') }, 'file-input-field Input__Round')}
                                                placeholder="Choose a file..."
                                                value={formik.values.bankIcon}
                                                readOnly
                                            />
                                            <span className="file-upload-icon">
                                                <i className="pi pi-paperclip" />
                                            </span>
                                        </div>
                                        {selectedFileError ? <small className="p-error">{selectedFileError}</small> : getFormErrorMessage('bankIcon')}
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                <div className="p-field">
                                    <label htmlFor="bankCode" className={classNames({ 'p-error': isFormFieldValid('bankCode') }, 'Label__Text')}>
                                        Bank Code <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={50}
                                        id="bankCode"
                                        placeholder="Enter Bank Code"
                                        name="bankCode"
                                        value={formik?.values?.bankCode?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('bankCode') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('bankCode')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                <div className="p-field">
                                    <label htmlFor="priority" className={classNames({ 'p-error': isFormFieldValid('priority') }, 'Label__Text')}>
                                        Priority <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={50}
                                        id="priority"
                                        placeholder="Enter Priority"
                                        name="priority"
                                        value={formik?.values?.priority?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('priority') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('priority')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                <div className="p-field">
                                    <label htmlFor="displayNameEnglish" className={classNames({ 'p-error': isFormFieldValid('displayNameEnglish') }, 'Label__Text')}>
                                        Display Name (English) <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="displayNameEnglish"
                                        placeholder="Enter Display Name (English)"
                                        name="displayNameEnglish"
                                        value={formik?.values?.displayNameEnglish?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('displayNameEnglish') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('displayNameEnglish')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                <div className="p-field">
                                    <label htmlFor="displayNameUrdu" className={classNames({ 'p-error': isFormFieldValid('displayNameUrdu') }, 'Label__Text')}>
                                        Display Name (Urdu)<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="displayNameUrdu"
                                        placeholder="Enter Display Name (Urdu) "
                                        name="displayNameUrdu"
                                        value={formik?.values?.displayNameUrdu?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('displayNameUrdu') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('displayNameUrdu')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                <div className="p-field">
                                    <label htmlFor="bankImd" className={classNames({ 'p-error': isFormFieldValid('bankImd') }, 'Label__Text')}>
                                        Bank IMD <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={50}
                                        id="bankImd"
                                        placeholder="Enter Bank IMD "
                                        name="bankImd"
                                        value={formik?.values?.bankImd || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('bankImd') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('bankImd')}
                                </div>
                            </div>

                            <div className="card Card__Bg__Change col-12 md:col-12">
                                <h6 className="secondary-color">
                                    <b>TAG</b>
                                </h6>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3 flex align-items-end justify-content-start">
                                    <div className="field-checkbox flex mb-2  ">
                                        <div className="mr-5">
                                            <Checkbox inputId="addTag" name="addTag" checked={formik?.values?.addTag} onChange={formik.handleChange} style={{ marginTop: '10px' }} />

                                            <label htmlFor="addTag" className={classNames({ 'p-error': isFormFieldValid('enablePartialPayment') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                                Add Tag
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-fluid p-formgrid grid mb-5">
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                        <div className="p-field">
                                            <label htmlFor="cmsTagLabelId" className={classNames({ 'p-error': isFormFieldValid('cmsTagLabelId') }, 'Label__Text')}>
                                                Tag Label
                                            </label>
                                            <InputText
                                                maxlength={25}
                                                id="cmsTagLabelId"
                                                placeholder="Enter Tag Label"
                                                name="cmsTagLabelId"
                                                disabled={!formik?.values?.addTag}
                                                value={formik?.values?.cmsTagLabelId || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagLabelId') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('cmsTagLabelId')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field relative">
                                            <label htmlFor="cmsTagTextColorId" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextColorId') }, 'Label__Text')}>
                                                Tag Text Color
                                            </label>
                                            <InputText
                                                value={formik?.values?.cmsTagTextColorId || '#FFFFFF'}
                                                onChange={(e) => {
                                                    formik.setFieldValue('cmsTagTextColorId', e.target.value);
                                                }}
                                                onClick={() => setShowColorPicker({ textColor: true, bgColor: false })}
                                                disabled={!formik?.values?.addTag}
                                                readOnly
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextColorId') }, 'Input__Round')}
                                                style={{
                                                    backgroundColor: formik?.values?.cmsTagTextColorId || '#FFFFFF',
                                                    color: getContrastColor(formik?.values?.cmsTagTextColorId || '#FFFFFF')
                                                }}
                                            />
                                            {getFormErrorMessage('cmsTagTextColorId')}
                                            {showColorPicker?.textColor && (
                                                <div className="colorPickerForInput">
                                                    <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                                    <ColorPicker color={formik?.values?.cmsTagTextColorId || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagTextColorId', e?.hex)} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                        <div className="p-field">
                                            <label htmlFor="cmsTagBgColorId" className={classNames({ 'p-error': isFormFieldValid('cmsTagBgColorId') }, 'Label__Text')}>
                                                Tag Background Color
                                            </label>
                                            <InputText
                                                value={formik?.values?.cmsTagBgColorId || '#FFFFFF'}
                                                onChange={(e) => {
                                                    formik.setFieldValue('cmsTagBgColorId', e.target.value);
                                                }}
                                                onClick={() => setShowColorPicker({ textColor: false, bgColor: true })}
                                                disabled={!formik?.values?.addTag}
                                                readOnly
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagBgColorId') }, 'Input__Round')}
                                                style={{
                                                    backgroundColor: formik?.values?.cmsTagBgColorId || '#FFFFFF',
                                                    color: getContrastColor(formik?.values?.cmsTagBgColorId || '#FFFFFF')
                                                }}
                                            />
                                            {getFormErrorMessage('cmsTagBgColorId')}
                                            {showColorPicker?.bgColor && (
                                                <div className="colorPickerForInput">
                                                    <i className="pi pi-times closingIcon" onClick={() => setShowColorPicker({ textColor: false, bgColor: false })} />
                                                    <ColorPicker color={formik?.values?.cmsTagBgColorId || '#FFFFFF'} onChange={(e) => formik.setFieldValue('cmsTagBgColorId', e?.hex)} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                                <div className="p-field">
                                    <label htmlFor="expiryDate" className={classNames({ 'p-error': isFormFieldValid('expiryDate') }, 'Label__Text')}>
                                        Expiry Date
                                    </label>
                                    <InputText
                                        min={previousDate}
                                        id="expiryDate"
                                        type="date"
                                        name="expiryDate"
                                        value={formik?.values?.expiryDate}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('expiryDate') }, 'Input__Round')}
                                    />

                                    {getFormErrorMessage('expiryDate')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="isHide" className={classNames({ 'p-error': isFormFieldValid('isHide') }, 'Label__Text')}>
                                        Status
                                    </label>
                                    <div className="flex ml-2">
                                        <div className="mt-1 mr-5">
                                            <Checkbox inputId="show" name="isHide" value="Y" checked={formik?.values?.isHide === 'Y'} onChange={(e) => formik.setFieldValue('isHide', 'Y')} style={{ marginTop: '10px' }} />
                                            <label htmlFor="show" className={classNames({ 'p-error': isFormFieldValid('show') }, 'Label__Text')}>
                                                Show
                                            </label>
                                        </div>
                                        <div className="mt-1">
                                            <Checkbox inputId="hide" name="isHide" value="N" checked={formik?.values?.isHide === 'N'} onChange={(e) => formik.setFieldValue('isHide', 'N')} style={{ marginTop: '10px' }} />
                                            <label htmlFor="hide" className={classNames({ 'p-error': isFormFieldValid('hide') }, 'Label__Text')}>
                                                Hide
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-12 w-full pt-3 pb-3 ">
                                <div className="desc_Text_Area">
                                    <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') }, 'Label__Text')}>
                                        Description
                                    </label>
                                    <InputTextarea
                                        maxlength={200}
                                        placeholder="-"
                                        inputid="description"
                                        name="description"
                                        rows={4}
                                        cols={30}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('description') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('description')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Submit" className="Btn__Dark" />
                        <Button onClick={() => navigate('/agentmatebank')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddAgentMateBank;

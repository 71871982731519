import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import { handleGetRequest } from '../../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewAggregatorConfigChecker() {
    const [dataById, setDatabyId] = useState();

    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPendingRequest = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const checkerComments = query.get('checkerComments');
    const tableName = query.get('tableName');

    const getConfigDatabyId = async () => {
        const response = await handleGetRequest(`/lending/v1/lending/getaggregatorconfigbyid/${refTableId}`);
        setDatabyId(response);
        formik.setFieldValue('name', response?.payLoad?.name);
        formik.setFieldValue('contactNo', response?.payLoad?.contactNo);
        formik.setFieldValue('email', response?.payLoad?.email);
        formik.setFieldValue('businessAddress', response?.payLoad?.businessAddress);
        formik.setFieldValue('isActive', response?.payLoad?.isActive);
        formik.setFieldValue('lmsAggregatorId', response?.payLoad?.lmsAggregatorId);
        formik.setFieldValue('updateIndex', response?.payLoad?.updateindex);
    };

    const getConfigDatabyIdU = async () => {
        const response = await handleGetRequest(`/lending/v1/lending/getmcrequestaggregatorconfigbyid/${mcRequestId}`);
        setDatabyId(response?.payLoad);
        formik.setFieldValue('name', response?.payLoad?.name);
        formik.setFieldValue('contactNo', response?.payLoad?.contactNo);
        formik.setFieldValue('email', response?.payLoad?.email);
        formik.setFieldValue('businessAddress', response?.payLoad?.businessAddress);
        formik.setFieldValue('isActive', response?.payLoad?.isActive);
        formik.setFieldValue('lmsAggregatorId', response?.payLoad?.lmsAggregatorId);
        formik.setFieldValue('updateIndex', response?.payLoad?.updateindex);
    };

    const getConfigDatabyIdUIsActive = async () => {
        const response = await handleGetRequest(`/lending/v1/lending/getmcrequestaggregatorconfigbyid/${mcRequestId}`);
        setDatabyId(response?.payLoad);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getConfigDatabyId();
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getConfigDatabyIdU();
        } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
            getConfigDatabyId();
            getConfigDatabyIdUIsActive();
        }
    }, []); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            name: '',
            businessAddress: '',
            email: '',
            contactNo: '',
            action: '',
            checkerComments: '',
            isActive: '',
            lmsAggregatorId: '',
            mcPeindingRequestId: mcPendingRequest,
            aggregatorConfigRequest: {},
            mcActionRequest: {},
            checkerRequestType: '',
            updatedIndex: '',
            mcRequestId: ''
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>Edit AGGREGATOR CONFIGURATION</h2>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                    Aggregator Name
                                </label>
                                <InputText
                                    disabled={true}
                                    maxLength={100}
                                    id="name"
                                    placeholder="Enter Aggregator name"
                                    name="name"
                                    value={formik.values.name || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('discount') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('name')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="businessAddress" className={classNames({ 'p-error': isFormFieldValid('businessAddress') }, 'Label__Text')}>
                                    Business Address
                                </label>
                                <InputText
                                    disabled={true}
                                    maxLength={100}
                                    id="businessAddress"
                                    placeholder="Enter business address"
                                    name="businessAddress"
                                    value={formik.values.businessAddress || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('discount') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('businessAddress')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') }, 'Label__Text')}>
                                    Email
                                </label>
                                <InputText
                                    disabled={true}
                                    maxLength={100}
                                    id="email"
                                    placeholder="Enter email"
                                    name="email"
                                    value={formik.values.email || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('discount') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('email')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="contactNo" className={classNames({ 'p-error': isFormFieldValid('contactNo') }, 'Label__Text')}>
                                    Contact Number
                                </label>
                                <InputText
                                    disabled={true}
                                    maxLength={11}
                                    id="contactNo"
                                    placeholder="Enter Contact Number"
                                    name="contactNo"
                                    value={formik.values.contactNo || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('discount') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('contactNo')}
                            </div>
                        </div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    disabled
                                    maxLength={200}
                                    autoResize
                                    rows={5}
                                    cols={30}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={checkerComments || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button onClick={() => navigate('/requestchecker')} label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default ViewAggregatorConfigChecker;

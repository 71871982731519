import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function EditSettleLoanChecker() {
    const [loading, setloading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-save');
    const [statusLovData, setStatusLovData] = useState([]);
    const [mobileNumber, setMobileNumber] = useState('');

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        action: Yup.mixed().required('This field is required.'),
        checkerComments: Yup.mixed().required('This field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            action: '',
            checkerComments: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        settleLoanRequest: {
                            mobileNumber: mobileNumber,
                            lmsDisbursementId: refTableId
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/lending/v1/lending/disbursementcheckeraction', true, false, 'requestchecker'));
            if (res?.responseCode === process.env.REACT_APP_LENDING_SUCCESS_CODE) {
                formik.resetForm();
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        if (requestType === 'U') {
            getMcRequestDataById();
        }
        getStatusLov();
    }, []);

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    const getMcRequestDataById = async () => {
        const resp = await handleGetRequest(`/lending/v1/lending/getmcrequest/${mcRequestId}`);
        let keyDataMCRequestId = resp?.payLoad;
        setMobileNumber(keyDataMCRequestId?.mobileNumber);

        if (resp?.responseCode === process.env.REACT_APP_LENDING_SUCCESS_CODE) {
            const data = {};
            data['mobileNumber'] = keyDataMCRequestId?.mobileNumber;
            data['lmsDisbursementId'] = keyDataMCRequestId?.lmsDisbursementId;

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/lending/v1/lending/getloansettleinfo', true, true));
            const keyData = res?.payLoad;

            formik.setFieldValue('principalAmount', keyData?.principalAmountOutstanding?.toString());
            formik.setFieldValue('markupAmount', keyData?.markupAmountOutstanding?.toString());
            formik.setFieldValue('lateFee', keyData?.lpChargesAmountOutstanding?.toString());
            let summed = parseInt(keyData?.principalAmountOutstanding) + parseInt(keyData?.markupAmountOutstanding) + parseInt(keyData?.lpChargesAmountOutstanding);
            formik.setFieldValue('total', summed?.toString());
        }
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header"></div>
                <div className="p-fluid p-formgrid grid mb-5">
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="mobileNumber" className={classNames({ 'p-error': isFormFieldValid('mobileNumber') }, 'Label__Text')}>
                                Mobile Number
                            </label>
                            <InputText
                                maxLength={100}
                                id="mobileNumber"
                                placeholder="Enter"
                                name="mobileNumber"
                                disabled={true}
                                value={mobileNumber}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('mobileNumber') }, 'Input__Round')}
                            />

                            {getFormErrorMessage('mobileNumber')}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="principalAmount" className={classNames({ 'p-error': isFormFieldValid('principalAmount') }, 'Label__Text')}>
                                Principal Amount
                            </label>
                            <InputText
                                maxLength={100}
                                id="principalAmount"
                                placeholder="Enter"
                                name="principalAmount"
                                disabled={true}
                                value={formik?.values?.principalAmount}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('principalAmount') }, 'Input__Round')}
                            />

                            {getFormErrorMessage('principalAmount')}
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="markupAmount" className={classNames({ 'p-error': isFormFieldValid('markupAmount') }, 'Label__Text')}>
                                Markup Amount
                            </label>
                            <InputText
                                maxLength={100}
                                id="markupAmount"
                                placeholder="Enter"
                                name="markupAmount"
                                disabled={true}
                                value={formik?.values?.markupAmount}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('markupAmount') }, 'Input__Round')}
                            />

                            {getFormErrorMessage('markupAmount')}
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="lateFee" className={classNames({ 'p-error': isFormFieldValid('lateFee') }, 'Label__Text')}>
                                Late Fee
                            </label>
                            <InputText
                                maxLength={100}
                                id="lateFee"
                                placeholder="Enter"
                                name="lateFee"
                                disabled={true}
                                value={formik?.values?.lateFee}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('lateFee') }, 'Input__Round')}
                            />

                            {getFormErrorMessage('lateFee')}
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label htmlFor="total" className={classNames({ 'p-error': isFormFieldValid('total') }, 'Label__Text')}>
                                Total
                            </label>
                            <InputText
                                maxLength={100}
                                id="total"
                                placeholder="Enter"
                                name="total"
                                disabled={true}
                                value={formik?.values?.total}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('total') }, 'Input__Round')}
                            />

                            {getFormErrorMessage('total')}
                        </div>
                    </div>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    optionDisabled={(option) => option?.name === 'Pending'}
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    autoResize={false}
                                    rows={5}
                                    cols={30}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} label="Update" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default EditSettleLoanChecker;

import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../services/PostTemplate';
import { handleGetRequest } from '../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RadioButton } from 'primereact/radiobutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useLocation } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';
import AuthorizationHistoryTable from '../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewEnterpriseServiceBus({}) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [tblSegments, setTblSegments] = useState([]);
    const [segmentsLov, setSegmentsLov] = useState([]);
    const [headerKeyValuePair, setHeaderKeyValuePair] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const dataTableRef = useRef(null);

    // Getting params from URL using hash router
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const apiId = query.get('apiId');
    const tableName = 'TBL_API';

    const getSegmentsLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getsegments/LKP_SEGMENT:*');
        setSegmentsLov(resp?.payLoad);
    }

    const getESBDatabyId = async () => {
        setloading(true);

        const res = await handleGetRequest(`/apigateway/v1/esb/getapisignature/${apiId}`);
        const keyData = res?.payLoad;

        setloading(false);

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });

            formik.setFieldValue('noLimits', keyData?.dailyLimit === 0 ? true : false);
            formik.setFieldValue('dailyLimit', keyData?.dailyLimit.toString());

            // formik.setFieldValue('sampleApiRequest', JSON.stringify(keyData?.sampleApiRequest));
            // formik.setFieldValue('sampleApiResponse', JSON.stringify(keyData?.sampleApiResponse));

            formik.setFieldValue('sampleApiRequest', JSON.stringify(keyData?.sampleApiRequest, null, 2));
            formik.setFieldValue('sampleApiResponse', JSON.stringify(keyData?.sampleApiResponse, null, 2));

            const activeSegments = keyData?.tblApiSegments.filter((segment) => segment.isActive === 'Y');
            formik.setFieldValue('tblApiSegments', activeSegments.map(item => {return item.segmentId.toString()}));

            const selectedSegmentIds = activeSegments.map(detail => detail.segmentId.toString());

            formik.setFieldValue('tblApiSegments', selectedSegmentIds)

            setTblSegments(keyData?.tblApiSegments)

            const activeHeaders = keyData?.tblApiReqHeaders.filter((header) => header.isActive === 'Y');
            setHeaderKeyValuePair(activeHeaders);
            // setHeaderKeyValuePair(keyData?.tblApiReqHeaders);
        }
    };

    useEffect(() => {
        getESBDatabyId();
        getSegmentsLov();
    }, []);

    // const addHeaderKeyValue = () => {
    //     const newKeyValuePair = {
    //         headerKey: formik.values.headerKey,
    //         headerValue: formik.values.headerValue
    //     };
        
    //     setHeaderKeyValuePair([...headerKeyValuePair, newKeyValuePair]);
        
    //     // Clear the input fields after adding the data.
    //     formik.setFieldValue("headerKey", "");
    //     formik.setFieldValue("headerValue", "");
    // };

    const validationSchema = Yup.object().shape({
        apiName: Yup.mixed().required('This field is required.'),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            apiName: '',
            endPoint: '',
            httpMethod: '',
            targetUrl1: '',
            targetUrl2: '',
            targetUrl1Status: '',
            targetUrl2Status: '',
            dailyLimit: '',
            sampleApiRequest: '',
            sampleApiResponse: '',
            tblApiSegments: [],
            tblApiReqHeaders: [],
        },

    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    // const removeHeaderKeyValue = (rowData, column) => {
    //     // Find the index of the row in the array
    //     const rowIndex = headerKeyValuePair.indexOf(rowData);
      
    //     if (rowIndex !== -1) {
    //       // Create a copy of the array without the selected row
    //       const updatedHeaderKeyValuePair = [...headerKeyValuePair];
    //       updatedHeaderKeyValuePair.splice(rowIndex, 1);
      
    //       // Update the state with the modified array
    //       setHeaderKeyValuePair(updatedHeaderKeyValuePair);
    //     }
    // };

    const panelFooterTemplate = () => {
        const length = formik.values.tblApiSegments ? formik.values.tblApiSegments.length : 0;

        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>VIEW ESB</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="apiName" className={classNames({ 'p-error': isFormFieldValid('apiName') }, 'Label__Text')}>
                                    API Name 
                                </label>
                                <InputText
                                    id="apiName"
                                    placeholder="Enter API Name"
                                    name="apiName"
                                    disabled={true}
                                    value={formik?.values?.apiName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('apiName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('apiName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="endPoint" className={classNames({ 'p-error': isFormFieldValid('endPoint') }, 'Label__Text')}>
                                    Gateway End Point 
                                </label>
                                <InputText
                                    id="endPoint"
                                    placeholder="Enter Gateway End Point"
                                    name="endPoint"
                                    disabled={true}
                                    value={formik?.values?.endPoint || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('endPoint') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('endPoint')}
                            </div>
                        </div>
                        <div className="col-12 lg:col-6 xl:col-4 md:col-4 flex">
                            <div className="flex flex-column justify-content-center gap-2">
                                <div className="flex flex-column">
                                    <div className=''>
                                        <label htmlFor="httpMethod"
                                            className={classNames({ 'p-error': isFormFieldValid('httpMethod') }, 'Label__Text')}
                                        >
                                            HTTP Request Type
                                        </label>
                                    </div>
                                    <div className='flex'>
                                        <div className="flex mr-3">
                                            <RadioButton
                                                inputId="httpMethod"
                                                name="httpMethod"
                                                value="GET"
                                                disabled={true}
                                                onChange={formik.handleChange}
                                                checked={formik.values.httpMethod === "GET"}
                                            />
                                            <label htmlFor="httpMethod" className="ml-2">GET</label>
                                        </div>
                                        <div style={{ marginLeft: "10rem" }} className="flex mr-3">
                                            <RadioButton
                                                inputId="httpMethod"
                                                name="httpMethod"
                                                value="POST"
                                                disabled={true}
                                                onChange={formik.handleChange}
                                                checked={formik.values.httpMethod === "POST"}
                                            />
                                            <label htmlFor="httpMethod" className="ml-2">POST</label>
                                        </div>
                                    </div>
                                    {getFormErrorMessage('httpMethod')}
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                            <div className="p-field flex align-items-center">
                                <RadioButton
                                    inputId="targetUrl1Status"
                                    name="targetUrl1Status"
                                    value="Y"
                                    disabled={true}
                                    checked={formik.values.targetUrl1Status === "Y" ? true: false}
                                />
                                <label htmlFor="targetUrl1" className={classNames({ 'p-error': isFormFieldValid('targetUrl1') }, 'Label__Text Label__Extra__Text__Padding')}>
                                    Target URL 1
                                </label>
                                <InputText
                                    id="targetUrl1"
                                    placeholder="Enter Target URL 1"
                                    name="targetUrl1"
                                    disabled={true}
                                    value={formik?.values?.targetUrl1 || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('targetUrl1') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('targetUrl1')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12 pt-3 pb-3">
                            <div className="p-field flex align-items-center">
                                <RadioButton
                                    inputId="targetUrl2Status"
                                    name="targetUrl2Status"
                                    value={"N"}
                                    disabled={true}
                                    checked={formik.values.targetUrl2Status === "N" ? false: true}
                                />
                                <label htmlFor="targetUrl2" className={classNames({ 'p-error': isFormFieldValid('targetUrl2') }, 'Label__Text Label__Extra__Text__Padding')}>
                                    Target URL 2
                                </label>
                                <InputText
                                    id="targetUrl2"
                                    placeholder="Enter Target URL 2"
                                    name="targetUrl2"
                                    disabled={true}
                                    value={formik?.values?.targetUrl2 || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('targetUrl2') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('targetUrl2')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="tblApiSegments" className={classNames({ 'p-error': isFormFieldValid('tblApiSegments') }, 'Label__Text')}>
                                    Segments
                                </label>
                                <MultiSelect
                                    id="tblApiSegments"
                                    placeholder="Select Segments"
                                    options={segmentsLov}
                                    optionLabel="name"
                                    name="tblApiSegments"
                                    optionValue="lovId"
                                    // display="chip"
                                    filter
                                    panelFooterTemplate={panelFooterTemplate}
                                    value={formik.values.tblApiSegments}
                                    
                                    className={classNames({ 'p-invalid': isFormFieldValid('tblApiSegments') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('tblApiSegments')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 flex align-items-end justify-content-center">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox inputId="noLimits" name="noLimits" value={formik?.values?.noLimits} checked={formik?.values?.noLimits} disabled
                                         onChange={formik.handleChange}
                                        style={{ marginTop: '10px' }} />
                                    <label htmlFor="noLimits" className={classNames({ 'p-error': isFormFieldValid('noLimits') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                        No Limit
                                    </label>
                                </div>
                                {getFormErrorMessage('noLimits')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="dailyLimit" className={classNames({ 'p-error': isFormFieldValid('dailyLimit') }, 'Label__Text')}>
                                    API Daily Limit
                                </label>
                                <InputText
                                    id="dailyLimit"
                                    placeholder="Enter API Daily Limit"
                                    name="dailyLimit"
                                    disabled={true}
                                    value={formik?.values?.dailyLimit || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dailyLimit') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dailyLimit')}
                            </div>
                        </div>
                        {/* <div className="p-field col-12 md:col-1 pt-3 pb-3 flex align-items-center">
                            <div className="p-field">
                                <div className="field-checkbox" style={{margin: '20px 0px 0px'}}>
                                    <Checkbox inputId="apiDailyLimitCheckbox" name="apiDailyLimitCheckbox" value={formik.values.apiDailyLimitCheckbox} checked={formik.values.apiDailyLimitCheckbox} onChange={formik.handleChange} disabled/>
                                </div>
                            </div>
                        </div> */}
                        <div style={{ backgroundColor: "#edf0f5", padding: "2rem" }} className=" col-12 card">
                            <div>
                                <div className="Form__Header">
                                    <h1>API Header Name & Value</h1>
                                </div>
                                <div className='Api__Header__Table'>
                                    <DataTable
                                        ref={dataTableRef}
                                        paginator
                                        rows={5}
                                        rowsPerPageOptions={[5, 10]}
                                        className="datatable-responsive"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                                        emptyMessage="No List found."
                                        responsiveLayout="scroll"
                                        value={headerKeyValuePair}
                                    >
                                        <Column field="headerKey" header="Header Name" />
                                        <Column field="headerValue" header="Header Description" />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-6 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="sampleApiRequest" className={classNames({ 'p-error': isFormFieldValid('sampleApiRequest') }, 'Label__Text')}>
                                    Request
                                </label>
                                <InputTextarea
                                    id="sampleApiRequest"
                                    placeholder="Enter Request"
                                    name="sampleApiRequest"
                                    // autoResize
                                    value={formik?.values?.sampleApiRequest || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('sampleApiRequest') }, 'Input__Round Vertical__ResizeTextArea')}
                                    rows={10}
                                    cols={30}
                                    readOnly
                                />
                                {getFormErrorMessage('sampleApiRequest')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-6 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="sampleApiResponse" className={classNames({ 'p-error': isFormFieldValid('sampleApiResponse') }, 'Label__Text')}>
                                    Response
                                </label>
                                <InputTextarea
                                    id="sampleApiResponse"
                                    placeholder="Enter Response"
                                    name="sampleApiResponse"
                                    // autoResize
                                    value={formik?.values?.sampleApiResponse || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('sampleApiResponse') }, 'Input__Round Vertical__ResizeTextArea')}
                                    rows={10}
                                    cols={30}
                                    readOnly
                                />
                                {getFormErrorMessage('sampleApiResponse')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} label="Okay" onClick={() => navigate('/esb')} className="Btn__Dark" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={apiId} tableName={tableName} />
        </>
    );
}
export default ViewEnterpriseServiceBus;

import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import UltraDualNationalityFields from './UltraDualNationalityFields';
import eyeIcon from '../../../../../src/assets/images/view_icon_y.png';
import { Image } from 'primereact/image';
import { Dialog } from 'primereact/dialog';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { baseURL } from '../../../../Config';

const UltraFields = ({ rowData, updatedData, userTabsFieldData }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [viewCustomerDocData, setViewCustomerDocData] = useState([]);
    const [cnicFront, setCnicFront] = useState([]);
    const [cnicBack, setCnicBack] = useState([]);

    const dispatch = useDispatch();

    const openModal = () => {
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
    };

    const splitCnicFrontFileName = rowData?.cnicFrontPic ? rowData?.cnicFrontPic.split('/').pop() : '';
    const splitCnicBackFileName = rowData?.cnicBankPic ? rowData?.cnicBankPic.split('/').pop() : '';
    const splitCustomerVideoFileName = rowData?.customerVideo ? rowData?.customerVideo.split('/').pop() : '';

    const getCustomerAccountDocs = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    customerId: rowData?.customerId
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/documents/v1/customeraccounts/getcustomeraccountdocs', true, true, 'accountsmanagement'));
        if (res?.responseCode === '070000') {
            const documents = res?.payLoad;
            const cnicFrontObj = documents.filter((item) => item?.lkpDocumentType?.documentTypeName === 'CNIC_FRONT');
            const cnicBackObj = documents.filter((item) => item?.lkpDocumentType?.documentTypeName === 'CNIC_BACK');
            const cnicFrontBase64 = 'data:image/png;base64,' + cnicFrontObj[0]?.documentPath;
            const cnicBackBase64 = 'data:image/png;base64,' + cnicBackObj[0]?.documentPath;
            setCnicFront(cnicFrontBase64);
            setCnicBack(cnicBackBase64);
            setViewCustomerDocData(res?.payLoad);
        }
    };

    useEffect(() => {
        getCustomerAccountDocs();
    }, []);

    const checkFieldPermissions = (fieldName) => {
        const field = userTabsFieldData.find((f) => f.fieldName === fieldName);
        return {
            viewAllowed: field?.viewAllowed === 'Y',
            updateAllowed: field?.updateAllowed === 'Y'
        };
    };

    const cnicFrontPicFieldPermissions = checkFieldPermissions('cnicFrontPic');
    const cnicBankPicFieldPermissions = checkFieldPermissions('cnicBankPic');
    const customerVideoFieldPermissions = checkFieldPermissions('customerVideo');
    const dualNationalityFieldPermissions = checkFieldPermissions('dualNationality');
    const professionNameFieldPermissions = checkFieldPermissions('professionName');
    const ultraUsageNameFieldPermissions = checkFieldPermissions('ultraUsageName');
    const monthlySpendingFieldPermissions = checkFieldPermissions('monthlySpending');
    const chequeBookFieldPermissions = checkFieldPermissions('chequeBook');
    const signatureFieldPermissions = checkFieldPermissions('signature');

    return (
        <>
            {/* ROW */}

            <div className="p-fluid p-formgrid grid mb-3">
                {cnicFrontPicFieldPermissions.viewAllowed && (
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">CNIC Front</label>
                            <div className="flex align-items-center">
                                <InputText id="cnicFrontPic" value={splitCnicFrontFileName ?? ''} className="Input__Round mr-2" disabled />
                                <Image className="img-viewer-ultra" src={eyeIcon} zoomSrc={cnicFront ? cnicFront : 'No Image Available'} alt="Image" width="80" height="60" preview />
                            </div>
                        </div>
                    </div>
                )}
                {cnicBankPicFieldPermissions.viewAllowed && (
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">CNIC Back</label>
                            <div className="flex align-items-center">
                                <InputText id="cnicBankPic" value={splitCnicBackFileName ?? ''} className="Input__Round mr-2" disabled />
                                <Image className="img-viewer-ultra" src={eyeIcon} zoomSrc={cnicBack ? cnicBack : 'No Image Available'} alt="Image" width="80" height="60" preview />
                            </div>
                        </div>
                    </div>
                )}

                <div className="p-field col-12 md:col-4">
                    <div className="p-field">
                        <label className="Label__Text">Video</label>
                        <div className="flex align-items-center">
                            <InputText id="customerVideo" value={splitCustomerVideoFileName ?? ''} className="Input__Round mr-2" disabled />
                            {customerVideoFieldPermissions.viewAllowed && <Image className="img-viewer-ultra" src={eyeIcon} alt="Image" width="80" height="60" onClick={openModal} style={{ cursor: 'pointer' }} />}
                        </div>
                    </div>
                </div>

                {/* Customer Video Dialog */}
                <Dialog header="Customer Video" visible={isModalVisible} onHide={closeModal} resizable={false} draggable={false} style={{ width: '20vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                    <div className="modal">
                        <div className="modal-content text-center">
                            {rowData?.customerVideo ? (
                                <video controls width="320" height="240">
                                    <source src={`${baseURL}/document` + rowData?.customerVideo?.substring(33)} type="video/mp4" />
                                </video>
                            ) : (
                                'No Video Found'
                            )}
                        </div>
                    </div>
                </Dialog>

                {dualNationalityFieldPermissions.viewAllowed && (
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Dual Nationality</label>
                            <Dropdown placeholder={rowData?.dualNationality === 'Y' ? 'Yes' : rowData?.dualNationality === 'N' ? 'No' : ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                        </div>
                    </div>
                )}
                {rowData && rowData?.dualNationality === 'N' ? (
                    <>
                        {professionNameFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Occupation</label>
                                    <Dropdown placeholder={rowData?.professionName ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                </div>
                            </div>
                        )}
                        {ultraUsageNameFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Purpose</label>
                                    <Dropdown placeholder={rowData?.ultraUsageName ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                </div>
                            </div>
                        )}
                        {monthlySpendingFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Monthly Spending</label>
                                    <Dropdown placeholder={rowData?.monthlySpending ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                </div>
                            </div>
                        )}
                        {chequeBookFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Cheque Book</label>
                                    <div className="flex align-items-center">
                                        <Dropdown placeholder={rowData?.chequeBook === 'Y' ? 'Yes' : rowData?.chequeBook === 'N' ? 'No' : ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                        {chequeBookFieldPermissions.viewAllowed && (rowData && rowData?.chequeBook === 'Y' ? <i className="field-eye-icon pi pi-eye"></i> : '')}
                                    </div>
                                </div>
                            </div>
                        )}
                        {signatureFieldPermissions.viewAllowed &&
                            (rowData && rowData?.chequeBook === 'Y' ? (
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label className="Label__Text">Signature</label>
                                        <div className="flex align-items-center">
                                            <InputText id="signaturePic" value={rowData?.signaturePic ?? ''} className="Input__Round mr-2" disabled />
                                            {signatureFieldPermissions.viewAllowed && (
                                                <Image
                                                    className="img-viewer-ultra"
                                                    src={eyeIcon}
                                                    zoomSrc={rowData?.signaturePic ? `${baseURL}/document` + rowData?.signaturePic?.substring(33) : 'No Image Available'}
                                                    alt="Image"
                                                    width="80"
                                                    height="60"
                                                    preview
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                ''
                            ))}
                    </>
                ) : (
                    ''
                )}
            </div>
            {rowData && rowData?.dualNationality === 'Y' ? (
                <div className="custom-card p-5 mb-3">
                    <UltraDualNationalityFields rowData={rowData} userTabsFieldData={userTabsFieldData} />
                </div>
            ) : (
                ''
            )}

            {/* ROW */}
            <div className="p-fluid p-formgrid grid mb-3">
                {rowData && rowData?.dualNationality === 'Y' ? (
                    <>
                        {professionNameFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Occupation</label>
                                    <Dropdown placeholder={rowData?.professionName ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                </div>
                            </div>
                        )}
                        {ultraUsageNameFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Purpose</label>
                                    <Dropdown placeholder={rowData?.ultraUsageName ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                </div>
                            </div>
                        )}
                        {monthlySpendingFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Monthly Spending</label>
                                    <Dropdown placeholder={rowData?.monthlySpending ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                </div>
                            </div>
                        )}
                        {chequeBookFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Cheque Book</label>
                                    <div className="flex align-items-center">
                                        <Dropdown placeholder={rowData?.chequeBook === 'Y' ? 'Yes' : rowData?.chequeBook === 'N' ? 'No' : ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                        {chequeBookFieldPermissions.viewAllowed && (rowData && rowData?.chequeBook === 'Y' ? <i className="field-eye-icon pi pi-eye"></i> : '')}
                                    </div>
                                </div>
                            </div>
                        )}
                        {signatureFieldPermissions.viewAllowed &&
                            (rowData && rowData?.chequeBook === 'Y' ? (
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label className="Label__Text">Signature</label>
                                        <div className="flex align-items-center">
                                            <InputText id="signaturePic" value={rowData?.signaturePic ?? ''} className="Input__Round mr-2" disabled />
                                            {signatureFieldPermissions.viewAllowed && (
                                                <Image
                                                    className="img-viewer-ultra"
                                                    src={eyeIcon}
                                                    zoomSrc={rowData?.signaturePic ? `${baseURL}/document` + rowData?.signaturePic?.substring(33) : 'No Image Available'}
                                                    alt="Image"
                                                    width="80"
                                                    height="60"
                                                    preview
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                ''
                            ))}
                    </>
                ) : (
                    ''
                )}
            </div>
        </>
    );
};

export default UltraFields;

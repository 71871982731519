import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ViewFranchise from '../Components/Franchise/ViewFranchise';
import ViewRetailer from '../Components/Retailer/ViewRetailer';
import ViewHandler from '../Components/Handler/ViewHandler';
import ViewCorporate from '../Components/Corporate/ViewCorporate';
import { handlePostRequest } from '../../../../services/PostTemplate';

function EditAgentOnboarding() {
    const location = useLocation();
    const dispatch = useDispatch();
    const [viewId] = location.state || {};
    // const [loading, setloading] = useState(false);
    const [apiData, setApiData] = useState([]);
    const [apiDataDoc, setApiDataDoc] = useState([]);
    const [accountClassification, setAccountClassification] = useState([]);
    const [classificationOptions, setClassificationOptions] = useState([]);
    const [classificationObj, setClassificationObj] = useState({});
    const formik = useFormik({
        initialValues: {
            accountclassification: ''
        }
    });

    const getDocData = async (agentId) => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    agentId: agentId,
                    update:'true'
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequest(newData, '/documents/v1/documents/getagentdocuments', true, true));
        if (res?.payLoad) {
            setApiDataDoc(res?.payLoad);
        }
    };

    const getAccountClassification = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getclientroles/LKP_ACCOUNT_CLASSIFICATION:*');
        setAccountClassification(res?.payLoad);
    };

    const getDatabyId = async () => {
        const res = await handleGetRequest(`/account/v1/agentaccount/getcxagentbyid/${viewId}`);
        setApiData(res?.payLoad);
        if (res?.payLoad && (res?.payLoad?.lkpAccountClassification?.accountClassificationCode === 'FRN' || res?.payLoad?.lkpAccountClassification?.accountClassificationCode === 'RTL')) {
            getDocData(res?.payLoad?.agentId);
        }
    };

    useEffect(() => {
        getDatabyId();
    }, [viewId]);

    useEffect(() => {
        formik.setFieldValue('accountclassification', apiData?.lkpAccountClassification?.accountClassificationId.toString());
    }, [apiData]);

    const handleChange = async (e) => {
        const selectedOption = classificationOptions.find((option) => option.lovId === e.target.value);
        if (selectedOption) {
            formik.setValues({ accountclassification: selectedOption.lovId });
            setClassificationObj(selectedOption);
        }
    };

    useEffect(() => {
        getAccountClassification();
    }, []);

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="m-3 Form__Header Full__Width">
                            <h2>EDIT DETAILS</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountclassification" className="Label__Text">
                                    Account Classification
                                </label>
                                <Dropdown
                                    id="accountclassification"
                                    placeholder="Select Account Classification"
                                    options={accountClassification}
                                    optionLabel="name"
                                    name="accountclassification"
                                    optionValue="lovId"
                                    value={formik.values.accountclassification || ''}
                                    onChange={handleChange}
                                    className="Dropdown__Round"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </form>
                {apiData && (
                    <>
                        {apiData?.lkpAccountClassification?.accountClassificationCode === 'FRN' ? (
                            <ViewFranchise isEditable={true} apiData={apiData} docData={apiDataDoc} />
                        ) : apiData?.lkpAccountClassification?.accountClassificationCode === 'RTL' ? (
                            <ViewRetailer isEditable={true} apiData={apiData} docData={apiDataDoc} />
                        ) : apiData?.lkpAccountClassification?.accountClassificationCode === 'HND' ? (
                            <ViewHandler isEditable={true} apiData={apiData} />
                        ) : apiData?.lkpAccountClassification?.accountClassificationCode === 'CPT' ? (
                            <ViewCorporate isEditable={true} apiData={apiData} />
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

export default EditAgentOnboarding;

import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import { handleGetRequest } from '../../services/GetTemplate';
import { handlePostRequest } from '../../services/PostTemplate';
import ActiveInActiveDialog from '../../components/ActiveInActiveDialog/ActiveInActiveDialog';
import { ResetButton, SearchButton } from '../../components/SearchButton';

function Pricing() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [statusLovData, setStatusLovData] = useState([]);
    const [pricingData, setPricingData] = useState([]);
    const [allLovs, setAllLovs] = useState([]);
    const [userLov, setUserLov] = useState([]);

    const [showModal, setShowModal] = useState('');

    const [pricingProfileIdData, setPricingPropricingProfileIdData] = useState();
    const [isActiveData, setIsActiveData] = useState();

    const dataTableRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getAllLovsForPricing = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getfeeprofiledata');
        setAllLovs(resp?.payLoad);
    };

    useEffect(() => {
        getAllLovsForPricing();
    }, []);

    const accountTypeLov = allLovs?.accountype?.map((data) => {
        return {
            label: data?.name,
            lovId: data?.lovId
        };
    });

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    const previousDate = currentDate.toISOString().split('T')[0];

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(res?.payLoad);
    };
    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };

    useEffect(() => {
        getStatusListData();
        getUserLov();
    }, []);

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            profileName: '',
            accountClassificationId: '',
            statusId: '2',
            dateFrom: '',
            dateTo: '',
            createdBy: '',
            updatedBy: '',
            feeTypeId: ''
        },
        validate: (data) => {
            let errors = {};
            if (data.dateTo && !data.dateFrom) {
                errors.dateFrom = 'Date From is required when Date To is provided';
            }

            if (data.dateFrom && data.dateTo && data.dateFrom > data.dateTo) {
                errors.dateTo = 'Date To must be greater than or equal to Date From';
            }
            return errors;
        },

        onSubmit: async (data) => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const res = await dispatch(handlePostRequest(newData, '/pricingprofile/v1/pricingprofile/getallpricingprofiles', true, true));
            if (res?.responseCode === '110000') {
                setPricingData(res?.payLoad);
            }
            setloading(true);
            setloadingIcon('pi pi-search');
            dataTableRef.current.reset();
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                {/* <h5 className="m-0">Type of Account List</h5> */}
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    {/* <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." /> */}
                </span>
            </div>
        );
    };

    const handleSwitchChange = async () => {
        const updatedData = pricingData.map((item) => {
            if (item.pricingProfileId === pricingProfileIdData) {
                const isActive = item.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setPricingData(updatedData);
        let isactive = '';
        if (isActiveData === 'Y') {
            isactive = 'N';
        } else if (isActiveData === 'N') {
            isactive = 'Y';
        }

        const previousValue = isActiveData;

        const data = {
            isActive: isactive,
            pricingProfileId: pricingProfileIdData
        };

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/pricingprofile/v1/pricingprofile/inactiverpricingprofile', true, false));
        if (res?.responseCode !== '110000') {
            const revertedData = pricingData.map((item) => {
                if (item.pricingProfileId === pricingProfileIdData) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setPricingData(revertedData);
        }
    };

    const handleRoutes = (rowData) => {
        if (rowData?.lkpFeeType?.feeTypeCode === 'FF') {
            navigate(`/editpricingfixed?profileId=${rowData?.pricingProfileId}`);
            console.log('route', rowData?.lkpFeeType?.feeTypeCode);
        } else if (rowData?.lkpFeeType?.feeTypeCode === 'PF') {
            navigate(`/editpricingpercentageoftransactionamount?profileId=${rowData?.pricingProfileId}`);
        } else if (rowData?.lkpFeeType?.feeTypeCode === 'PF') {
            navigate(`/editpricingpercentageoftransactionamount?profileId=${rowData?.pricingProfileId}`);
        } else if (rowData?.lkpFeeType?.feeTypeCode === 'SF') {
            navigate(`/editpricingslabwisefixed?profileId=${rowData?.pricingProfileId}`);
        } else if (rowData?.lkpFeeType?.feeTypeCode === 'SP') {
            navigate(`/editpricingslabwisepercentage?profileId=${rowData?.pricingProfileId}`);
        } else if (rowData?.lkpFeeType?.feeTypeCode === 'CF') {
            navigate(`/editpricingconditionalfee?profileId=${rowData?.pricingProfileId}`);
        } else if (rowData?.lkpFeeType?.feeTypeCode === 'SH') {
            navigate(`/editpricingslabwisehybrid?profileId=${rowData?.pricingProfileId}`);
        }
    };
    const handleViewRoutes = (rowData) => {
        if (rowData?.lkpFeeType?.feeTypeCode === 'FF') {
            navigate(`/viewpricingfixed?profileId=${rowData?.pricingProfileId}`);
            console.log('route', rowData?.lkpFeeType?.feeTypeCode);
        } else if (rowData?.lkpFeeType?.feeTypeCode === 'PF') {
            navigate(`/viewpricingpercentageoftransactionamount?profileId=${rowData?.pricingProfileId}`);
        } else if (rowData?.lkpFeeType?.feeTypeCode === 'PF') {
            navigate(`/viewpricingpercentageoftransactionamount?profileId=${rowData?.pricingProfileId}`);
        } else if (rowData?.lkpFeeType?.feeTypeCode === 'SF') {
            navigate(`/viewpricingslabwisefixed?profileId=${rowData?.pricingProfileId}`);
        } else if (rowData?.lkpFeeType?.feeTypeCode === 'SP') {
            navigate(`/viewpricingslabwisepercentage?profileId=${rowData?.pricingProfileId}`);
        } else if (rowData?.lkpFeeType?.feeTypeCode === 'CF') {
            navigate(`/viewpricingconditionalfee?profileId=${rowData?.pricingProfileId}`);
        } else if (rowData?.lkpFeeType?.feeTypeCode === 'SH') {
            navigate(`/viewpricingslabwisehybrid?profileId=${rowData?.pricingProfileId}`);
        }
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <InputSwitch
                        checked={rowData.isActive === 'Y'}
                        disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                        onChange={() => {
                            setShowModal(true);
                            setPricingPropricingProfileIdData(rowData?.pricingProfileId);
                            setIsActiveData(rowData?.isActive);
                        }}
                        // onChange={() => handleSwitchChange(rowData.pricingProfileId, rowData.isActive, rowData)}
                    />
                    <Button
                        tooltip="Edit"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: 'top' }}
                        disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                        onClick={() => handleRoutes(rowData)}
                        className="p-button-rounded p-button-primary"
                    />
                    <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => handleViewRoutes(rowData)} className="p-button-rounded" style={{ backgroundColor: '#F2AC57', border: 'none' }} />
                </div>
            </>
        );
    };
    const typeoffee = allLovs?.feeType?.map((item) => {
        return {
            value: item?.lovId,
            label: item?.name
        };
    });

    const updatedArrayWithoutPending = typeoffee?.filter((item) => item.label !== 'Commission Heirarchy' && item.label !== 'Slab Hybrid Fixed Percentage');
    const updatedArrayWithoutPendingData = updatedArrayWithoutPending?.map((item) => {
        if (item.label === 'Slab Hybrid Fixed Percentage and Condition') {
            return { ...item, label: 'Slab Wise Hybrid' };
        }
        return item;
    });

    const addNewPricingFixed = () => {
        navigate('/addpricingfixed');
    };
    const addNewPricing = () => {
        navigate('/addpricingpercentageoftransactionamount ');
    };
    const addNewPricingSlabWiseFixed = () => {
        navigate('/addpricingslabwisefixed');
    };
    const addNewPricingSlabWisePercentage = () => {
        navigate('/addpricingslabwisepercentage');
    };
    const addNewPricingSlabWiseHybrid = () => {
        navigate('/addpricingslabwisehybrid');
    };
    const addNewPricingConditionalFee = () => {
        navigate('/addpricingconditionalfee');
    };
    // const handleFeeTypeChange = (e) => {
    //     setSelectedFeeType(e.value);
    // };

    const header = renderHeader();

    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const handleShowModal = () => {
        setShowModal(false);
    };

    const props = {
        showModal,
        handleShowModal,
        handleSwitchChange
    };
    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.lkpStatus?.statusName === 'Approved' ? '#14A38B' : rowData?.lkpStatus?.statusName === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.lkpStatus?.statusName}</b>
            </p>
        );
    };

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    return (
        <>
            <ActiveInActiveDialog {...props} />

            <div className="Top__Btn__Pricing Top__Btn__Border">
                <div>
                    <Button label="Fixed Fee" onClick={() => addNewPricingFixed()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                    <Button label="Percentage of Trx Amount" onClick={() => addNewPricing()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                    <Button label="Slab Wise Fixed" onClick={() => addNewPricingSlabWiseFixed()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                    <Button label="Slab Wise Percentage" onClick={() => addNewPricingSlabWisePercentage()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                    <Button label="Slab Wise Hybrid" onClick={() => addNewPricingSlabWiseHybrid()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                    <Button label="Conditional Fee" onClick={() => addNewPricingConditionalFee()} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                    Account Type
                                </label>
                                <Dropdown
                                    id="accountClassificationId"
                                    placeholder="Select Account Type"
                                    options={accountTypeLov}
                                    optionLabel="label"
                                    name="accountClassificationId"
                                    optionValue="lovId"
                                    value={formik.values.accountClassificationId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountClassificationId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('accountClassificationId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Profile Name
                                </label>
                                <InputText
                                    id="profileName"
                                    placeholder="Enter profile name"
                                    name="profileName"
                                    value={formik?.values?.profileName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('profileName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('profileName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText
                                    max={previousDate}
                                    id="dateFrom"
                                    placeholder=""
                                    name="dateFrom"
                                    type="date"
                                    value={formik?.values?.dateFrom?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText
                                    max={previousDate}
                                    id="dateTo"
                                    placeholder=""
                                    name="dateTo"
                                    type="date"
                                    // max={currentDate}
                                    value={formik?.values?.dateTo?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created by"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Updated By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="statusId" className={classNames({ 'p-error': isFormFieldValid('statusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select Status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="feeTypeId" className={classNames({ 'p-error': isFormFieldValid('feeTypeId') }, 'Label__Text')}>
                                    Type of Fee
                                </label>
                                <Dropdown
                                    id="feeTypeId"
                                    placeholder="Select Type of Fee "
                                    options={updatedArrayWithoutPendingData}
                                    optionLabel="label"
                                    name="feeTypeId"
                                    optionValue="value"
                                    value={formik.values.feeTypeId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('feeTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('feeTypeId')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <SearchButton/>
                        <ResetButton onClick={handleReset} />
                    </div>
                </form>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            globalFilterFields={['accountLevelName', 'createuser', 'createdate', 'lkpStatus.statusName']}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={pricingData}
                        >
                            <Column field="pricingProfileName" header="Profile Name" />
                            <Column field="lkpFeeType.feeTypeDescr" header="Fee Type" />
                            <Column field="createdBy" header="Created By" />
                            <Column field="createdate" header="Created Date & Time" body={createdDateAndTimeBodyTemplate} />
                            <Column header="Status" body={statusBodyTemplate} />
                            <Column header="Action" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Pricing;
